export const guGwangjuData = {
  type: 'FeatureCollection',
  features: [
    {
      id: '29110',
      type: 'Feature',
      properties: {
        SEC_SGG_CD: '29110',
        SEC_SGG_NM: ['광주광역시', '동구'],
        COL_SGG_CD: '29000',
        SEC_SGG_SIZE: '2',
        bbox: [126.90722, 35.07245, 127.00423, 35.16549],
        center: [126.94944, 35.11741],
        area: 48710915
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.93071, 35.16027],
            [126.93073, 35.16021],
            [126.93552, 35.16178],
            [126.93886, 35.16179],
            [126.93887, 35.16034],
            [126.93855, 35.16026],
            [126.93848, 35.16012],
            [126.93842, 35.15978],
            [126.93837, 35.15939],
            [126.93885, 35.15905],
            [126.93913, 35.15911],
            [126.93943, 35.159],
            [126.93986, 35.15867],
            [126.93993, 35.15863],
            [126.94018, 35.15858],
            [126.94047, 35.15857],
            [126.94103, 35.15864],
            [126.9414, 35.15856],
            [126.94198, 35.15883],
            [126.94242, 35.15877],
            [126.94272, 35.15867],
            [126.94308, 35.15869],
            [126.94356, 35.15879],
            [126.94436, 35.15874],
            [126.94443, 35.15872],
            [126.94473, 35.15836],
            [126.94512, 35.1581],
            [126.94529, 35.15763],
            [126.94529, 35.15747],
            [126.94564, 35.15702],
            [126.94573, 35.15654],
            [126.94567, 35.15607],
            [126.94576, 35.15556],
            [126.94614, 35.15532],
            [126.94706, 35.155],
            [126.9477, 35.15445],
            [126.94842, 35.15417],
            [126.94928, 35.15437],
            [126.94948, 35.15433],
            [126.95138, 35.15413],
            [126.95248, 35.15334],
            [126.95336, 35.15302],
            [126.95408, 35.15265],
            [126.95522, 35.15219],
            [126.95539, 35.15197],
            [126.95563, 35.15139],
            [126.95564, 35.15091],
            [126.95536, 35.15053],
            [126.95529, 35.15006],
            [126.9549, 35.14914],
            [126.95492, 35.14886],
            [126.9542, 35.14838],
            [126.95366, 35.14771],
            [126.95364, 35.14759],
            [126.95368, 35.14744],
            [126.95373, 35.14735],
            [126.95398, 35.1465],
            [126.95384, 35.14618],
            [126.95382, 35.14576],
            [126.95369, 35.14549],
            [126.95348, 35.14512],
            [126.95317, 35.14492],
            [126.95217, 35.14473],
            [126.95183, 35.14443],
            [126.95139, 35.14453],
            [126.95154, 35.14373],
            [126.95166, 35.14332],
            [126.9523, 35.14289],
            [126.95265, 35.14279],
            [126.95379, 35.14197],
            [126.95396, 35.14199],
            [126.95445, 35.14217],
            [126.95473, 35.14157],
            [126.95486, 35.14089],
            [126.95531, 35.14056],
            [126.95654, 35.14091],
            [126.95718, 35.14032],
            [126.95753, 35.14019],
            [126.958, 35.14012],
            [126.95821, 35.14042],
            [126.95869, 35.14068],
            [126.95953, 35.14054],
            [126.95995, 35.14028],
            [126.96096, 35.14045],
            [126.96186, 35.14034],
            [126.96309, 35.13987],
            [126.96368, 35.13942],
            [126.96437, 35.1391],
            [126.96495, 35.13899],
            [126.9655, 35.13872],
            [126.96657, 35.13774],
            [126.96737, 35.1374],
            [126.96776, 35.13693],
            [126.96802, 35.13683],
            [126.96843, 35.13674],
            [126.9689, 35.13672],
            [126.96937, 35.13687],
            [126.97077, 35.13658],
            [126.97101, 35.13658],
            [126.97158, 35.13755],
            [126.97194, 35.1379],
            [126.97273, 35.13842],
            [126.97325, 35.13841],
            [126.97375, 35.13798],
            [126.97439, 35.13799],
            [126.97501, 35.13786],
            [126.97558, 35.13786],
            [126.97716, 35.13825],
            [126.97814, 35.13844],
            [126.98064, 35.13779],
            [126.98154, 35.13779],
            [126.98241, 35.13759],
            [126.98245, 35.13753],
            [126.98354, 35.13696],
            [126.98403, 35.1361],
            [126.98415, 35.13517],
            [126.98469, 35.1348],
            [126.98519, 35.13435],
            [126.98515, 35.13414],
            [126.98547, 35.13383],
            [126.98561, 35.13318],
            [126.98587, 35.13288],
            [126.98603, 35.13241],
            [126.98616, 35.1321],
            [126.98622, 35.13175],
            [126.98689, 35.13126],
            [126.98699, 35.13052],
            [126.98739, 35.12996],
            [126.98775, 35.12909],
            [126.98776, 35.12825],
            [126.98881, 35.1274],
            [126.98926, 35.12715],
            [126.98957, 35.1269],
            [126.99067, 35.12551],
            [126.99094, 35.12526],
            [126.99108, 35.12481],
            [126.99178, 35.12418],
            [126.99214, 35.12336],
            [126.99236, 35.123],
            [126.99254, 35.12264],
            [126.99267, 35.12265],
            [126.99314, 35.12259],
            [126.99366, 35.12278],
            [126.99438, 35.12311],
            [126.9952, 35.12329],
            [126.99605, 35.12306],
            [126.99715, 35.12295],
            [127.00162, 35.12172],
            [127.00274, 35.12136],
            [127.00423, 35.12112],
            [127.00408, 35.12041],
            [127.00394, 35.11979],
            [127.00255, 35.11892],
            [127.00095, 35.11743],
            [127.00014, 35.11676],
            [126.99871, 35.11583],
            [126.99994, 35.11409],
            [126.99783, 35.11304],
            [126.99639, 35.11267],
            [126.99529, 35.11226],
            [126.99438, 35.11179],
            [126.99322, 35.11147],
            [126.99184, 35.1112],
            [126.99044, 35.11108],
            [126.98994, 35.11078],
            [126.9894, 35.11074],
            [126.98882, 35.10989],
            [126.98914, 35.10985],
            [126.9892, 35.1097],
            [126.98927, 35.10959],
            [126.98898, 35.10895],
            [126.98888, 35.10842],
            [126.98879, 35.10729],
            [126.98813, 35.10721],
            [126.98783, 35.10729],
            [126.98759, 35.10714],
            [126.98665, 35.10636],
            [126.98641, 35.10606],
            [126.98629, 35.10598],
            [126.98644, 35.10558],
            [126.98659, 35.10492],
            [126.9868, 35.10455],
            [126.98705, 35.10408],
            [126.98706, 35.10397],
            [126.98714, 35.10386],
            [126.98724, 35.1039],
            [126.98731, 35.10374],
            [126.9875, 35.10315],
            [126.98801, 35.10239],
            [126.98877, 35.10134],
            [126.98858, 35.10109],
            [126.98837, 35.10093],
            [126.98824, 35.10081],
            [126.98781, 35.10049],
            [126.9878, 35.10013],
            [126.9876, 35.09953],
            [126.98778, 35.09904],
            [126.98783, 35.09884],
            [126.98787, 35.09813],
            [126.98879, 35.097],
            [126.989, 35.09624],
            [126.98903, 35.09565],
            [126.98901, 35.09497],
            [126.98869, 35.09483],
            [126.98847, 35.09468],
            [126.98768, 35.09395],
            [126.98682, 35.09389],
            [126.98648, 35.09377],
            [126.98513, 35.09366],
            [126.98469, 35.09344],
            [126.9843, 35.09342],
            [126.98348, 35.09343],
            [126.98265, 35.09319],
            [126.98172, 35.0927],
            [126.98117, 35.09262],
            [126.98039, 35.09235],
            [126.97962, 35.09188],
            [126.97877, 35.09175],
            [126.97826, 35.09191],
            [126.97758, 35.09219],
            [126.97696, 35.09175],
            [126.97683, 35.09164],
            [126.97662, 35.09157],
            [126.97496, 35.0919],
            [126.97463, 35.09183],
            [126.97406, 35.09187],
            [126.9736, 35.09166],
            [126.97308, 35.09136],
            [126.97282, 35.09113],
            [126.9724, 35.09094],
            [126.97228, 35.09075],
            [126.97216, 35.09049],
            [126.97201, 35.09036],
            [126.97111, 35.09019],
            [126.9706, 35.09026],
            [126.96939, 35.09017],
            [126.96882, 35.08978],
            [126.96805, 35.08976],
            [126.96755, 35.08949],
            [126.96652, 35.0894],
            [126.96612, 35.08898],
            [126.96572, 35.08876],
            [126.96553, 35.08874],
            [126.96529, 35.08854],
            [126.96508, 35.08829],
            [126.96453, 35.08779],
            [126.964, 35.08739],
            [126.96354, 35.08729],
            [126.96302, 35.08731],
            [126.96285, 35.08722],
            [126.96262, 35.08695],
            [126.96242, 35.0869],
            [126.96222, 35.08669],
            [126.96153, 35.08569],
            [126.96141, 35.08558],
            [126.96131, 35.08546],
            [126.96092, 35.08521],
            [126.96063, 35.08511],
            [126.96039, 35.08475],
            [126.9602, 35.08452],
            [126.95999, 35.08395],
            [126.9597, 35.08365],
            [126.95952, 35.08344],
            [126.95941, 35.08321],
            [126.95926, 35.08297],
            [126.95859, 35.08257],
            [126.95855, 35.08246],
            [126.95853, 35.08224],
            [126.95855, 35.0821],
            [126.95862, 35.08192],
            [126.9588, 35.0817],
            [126.95937, 35.0808],
            [126.95957, 35.08044],
            [126.95964, 35.07983],
            [126.95946, 35.07911],
            [126.9589, 35.07892],
            [126.9584, 35.07878],
            [126.95815, 35.07861],
            [126.9573, 35.07789],
            [126.95714, 35.07764],
            [126.95652, 35.07697],
            [126.95643, 35.0769],
            [126.95568, 35.07585],
            [126.95558, 35.07585],
            [126.95495, 35.07492],
            [126.95432, 35.07449],
            [126.95387, 35.07416],
            [126.95314, 35.07409],
            [126.95248, 35.07351],
            [126.95184, 35.0732],
            [126.94936, 35.07278],
            [126.94855, 35.07288],
            [126.9471, 35.07272],
            [126.94671, 35.07276],
            [126.94628, 35.07293],
            [126.94528, 35.07323],
            [126.94374, 35.07355],
            [126.94274, 35.07296],
            [126.9423, 35.07245],
            [126.94139, 35.07342],
            [126.94099, 35.07365],
            [126.94042, 35.07372],
            [126.94028, 35.07379],
            [126.94004, 35.07388],
            [126.93946, 35.07395],
            [126.93903, 35.07405],
            [126.93855, 35.07394],
            [126.93713, 35.07431],
            [126.93644, 35.07438],
            [126.93595, 35.07455],
            [126.93539, 35.07448],
            [126.93487, 35.07447],
            [126.93416, 35.07451],
            [126.93364, 35.07449],
            [126.93316, 35.07457],
            [126.93261, 35.07453],
            [126.93227, 35.07447],
            [126.93235, 35.07489],
            [126.93237, 35.07524],
            [126.93236, 35.0755],
            [126.93231, 35.07573],
            [126.9323, 35.07589],
            [126.93231, 35.07637],
            [126.93254, 35.07673],
            [126.93262, 35.07688],
            [126.93263, 35.07694],
            [126.93257, 35.07733],
            [126.9326, 35.07761],
            [126.93251, 35.07781],
            [126.93188, 35.07809],
            [126.93124, 35.07845],
            [126.93071, 35.07864],
            [126.92936, 35.0794],
            [126.92913, 35.07958],
            [126.92873, 35.08019],
            [126.92859, 35.08032],
            [126.92765, 35.08047],
            [126.92713, 35.08115],
            [126.92682, 35.08208],
            [126.92613, 35.08241],
            [126.92613, 35.08274],
            [126.92619, 35.08326],
            [126.92619, 35.08339],
            [126.92635, 35.08441],
            [126.92599, 35.08466],
            [126.9257, 35.08509],
            [126.92507, 35.08561],
            [126.92478, 35.08624],
            [126.92447, 35.08686],
            [126.92473, 35.088],
            [126.92411, 35.08862],
            [126.92319, 35.08931],
            [126.92291, 35.08966],
            [126.92261, 35.08992],
            [126.92237, 35.09041],
            [126.92225, 35.09061],
            [126.92163, 35.09097],
            [126.92107, 35.09147],
            [126.92058, 35.0917],
            [126.91968, 35.09171],
            [126.91942, 35.09169],
            [126.91941, 35.09228],
            [126.91983, 35.09294],
            [126.91971, 35.09342],
            [126.92001, 35.09398],
            [126.92061, 35.0947],
            [126.92068, 35.09517],
            [126.92068, 35.09532],
            [126.92072, 35.0959],
            [126.92085, 35.0966],
            [126.92136, 35.09724],
            [126.92115, 35.09734],
            [126.92088, 35.09778],
            [126.92051, 35.09819],
            [126.92022, 35.09867],
            [126.91977, 35.09883],
            [126.91901, 35.09875],
            [126.91825, 35.09896],
            [126.91781, 35.09972],
            [126.91762, 35.10046],
            [126.9179, 35.10106],
            [126.91803, 35.10157],
            [126.91788, 35.10206],
            [126.91718, 35.10301],
            [126.91667, 35.10348],
            [126.91639, 35.1038],
            [126.91568, 35.10417],
            [126.91532, 35.10441],
            [126.91492, 35.10474],
            [126.91455, 35.10516],
            [126.91448, 35.10571],
            [126.91395, 35.10627],
            [126.91397, 35.10677],
            [126.91388, 35.10716],
            [126.91404, 35.10742],
            [126.91406, 35.10815],
            [126.91425, 35.10888],
            [126.91361, 35.1093],
            [126.91299, 35.10984],
            [126.91249, 35.11056],
            [126.91247, 35.11147],
            [126.91235, 35.11177],
            [126.91236, 35.11227],
            [126.91243, 35.11239],
            [126.91286, 35.11284],
            [126.91296, 35.11308],
            [126.91302, 35.11316],
            [126.91344, 35.11355],
            [126.91358, 35.11375],
            [126.91324, 35.11453],
            [126.9132, 35.11458],
            [126.91266, 35.11484],
            [126.91217, 35.11526],
            [126.91188, 35.11563],
            [126.91199, 35.11559],
            [126.91227, 35.11561],
            [126.91263, 35.11585],
            [126.91298, 35.11594],
            [126.91359, 35.11615],
            [126.91381, 35.11646],
            [126.9148, 35.11664],
            [126.91503, 35.11676],
            [126.91546, 35.11708],
            [126.9159, 35.11716],
            [126.91609, 35.11686],
            [126.91703, 35.1169],
            [126.91758, 35.11697],
            [126.91776, 35.11709],
            [126.91794, 35.11714],
            [126.91834, 35.11718],
            [126.9189, 35.11737],
            [126.91907, 35.11751],
            [126.91944, 35.11776],
            [126.91954, 35.11795],
            [126.91954, 35.11834],
            [126.91969, 35.11848],
            [126.9198, 35.11898],
            [126.92009, 35.11921],
            [126.92046, 35.11928],
            [126.92062, 35.1194],
            [126.92089, 35.11956],
            [126.92135, 35.11967],
            [126.92143, 35.11967],
            [126.9217, 35.11974],
            [126.92187, 35.11986],
            [126.9218, 35.12009],
            [126.92188, 35.12019],
            [126.92231, 35.12064],
            [126.92271, 35.12095],
            [126.92267, 35.12154],
            [126.92276, 35.1218],
            [126.92276, 35.12212],
            [126.92277, 35.12226],
            [126.92299, 35.12263],
            [126.92339, 35.123],
            [126.9236, 35.12313],
            [126.92429, 35.12365],
            [126.92448, 35.12364],
            [126.92446, 35.12422],
            [126.92437, 35.12458],
            [126.92429, 35.1247],
            [126.92553, 35.125],
            [126.9266, 35.1253],
            [126.92715, 35.12543],
            [126.92766, 35.12545],
            [126.9283, 35.1257],
            [126.92868, 35.12576],
            [126.92878, 35.12583],
            [126.9289, 35.12587],
            [126.92899, 35.12612],
            [126.92951, 35.12633],
            [126.92964, 35.12645],
            [126.92849, 35.12737],
            [126.92826, 35.1275],
            [126.92735, 35.12785],
            [126.92745, 35.12936],
            [126.92712, 35.12947],
            [126.92643, 35.12989],
            [126.92605, 35.13056],
            [126.92582, 35.13114],
            [126.92566, 35.13174],
            [126.92535, 35.13241],
            [126.92527, 35.1325],
            [126.92475, 35.13298],
            [126.92451, 35.13349],
            [126.924, 35.13394],
            [126.92327, 35.13431],
            [126.92252, 35.1349],
            [126.92174, 35.13538],
            [126.92112, 35.13645],
            [126.92098, 35.13681],
            [126.92097, 35.13709],
            [126.92083, 35.13747],
            [126.92056, 35.13808],
            [126.92031, 35.13797],
            [126.91989, 35.13839],
            [126.91956, 35.13885],
            [126.91949, 35.13893],
            [126.91948, 35.13913],
            [126.91944, 35.13932],
            [126.91921, 35.13955],
            [126.91901, 35.13982],
            [126.91865, 35.1402],
            [126.91794, 35.14107],
            [126.91767, 35.14138],
            [126.9166, 35.1425],
            [126.9163, 35.14271],
            [126.91598, 35.14287],
            [126.91489, 35.1438],
            [126.91442, 35.14414],
            [126.91361, 35.14481],
            [126.91312, 35.14533],
            [126.91264, 35.14589],
            [126.91154, 35.14713],
            [126.91132, 35.1474],
            [126.91089, 35.14787],
            [126.91025, 35.14861],
            [126.90895, 35.15007],
            [126.90819, 35.15072],
            [126.90722, 35.15141],
            [126.90743, 35.15166],
            [126.90775, 35.15194],
            [126.90801, 35.15212],
            [126.90843, 35.15247],
            [126.9086, 35.15255],
            [126.90926, 35.1531],
            [126.91131, 35.15477],
            [126.91155, 35.15498],
            [126.91233, 35.15559],
            [126.91254, 35.15572],
            [126.91248, 35.15579],
            [126.91632, 35.16549],
            [126.9196, 35.16476],
            [126.92272, 35.16402],
            [126.92821, 35.16248],
            [126.92866, 35.16229],
            [126.92894, 35.16215],
            [126.92922, 35.16199],
            [126.92975, 35.16159],
            [126.92997, 35.16137],
            [126.93018, 35.16114],
            [126.93038, 35.16087],
            [126.93059, 35.16053],
            [126.93071, 35.16027]
          ]
        ]
      }
    },
    {
      id: '29140',
      type: 'Feature',
      properties: {
        SEC_SGG_CD: '29140',
        SEC_SGG_NM: ['광주광역시', '서구'],
        COL_SGG_CD: '29000',
        SEC_SGG_SIZE: '2',
        bbox: [126.80066, 35.09093, 126.91055, 35.17944],
        center: [126.85072, 35.13567],
        area: 47564243
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.83873, 35.17841],
            [126.83909, 35.17837],
            [126.83933, 35.17838],
            [126.8394, 35.17832],
            [126.83953, 35.17835],
            [126.83956, 35.1784],
            [126.83973, 35.17849],
            [126.83986, 35.17848],
            [126.84003, 35.17854],
            [126.84009, 35.17861],
            [126.84026, 35.17858],
            [126.84038, 35.17853],
            [126.84043, 35.17848],
            [126.84054, 35.17845],
            [126.84063, 35.17837],
            [126.84134, 35.17809],
            [126.84144, 35.17802],
            [126.84144, 35.17787],
            [126.84189, 35.17771],
            [126.84236, 35.17746],
            [126.84241, 35.17741],
            [126.84244, 35.17728],
            [126.84247, 35.17723],
            [126.84251, 35.17701],
            [126.8426, 35.17692],
            [126.84277, 35.17686],
            [126.84295, 35.17691],
            [126.84329, 35.17689],
            [126.84287, 35.17619],
            [126.84788, 35.17508],
            [126.8583, 35.17265],
            [126.85986, 35.17231],
            [126.86073, 35.17222],
            [126.87022, 35.17171],
            [126.87565, 35.17137],
            [126.87562, 35.17057],
            [126.87584, 35.17063],
            [126.87739, 35.17095],
            [126.87807, 35.17097],
            [126.87932, 35.17096],
            [126.88024, 35.17085],
            [126.88145, 35.17062],
            [126.88209, 35.17046],
            [126.8835, 35.17004],
            [126.88417, 35.16973],
            [126.88402, 35.16852],
            [126.88485, 35.16758],
            [126.88482, 35.16752],
            [126.88487, 35.16737],
            [126.88503, 35.16718],
            [126.88534, 35.16693],
            [126.88553, 35.16688],
            [126.88576, 35.16686],
            [126.88576, 35.16672],
            [126.88571, 35.1667],
            [126.88563, 35.16663],
            [126.88563, 35.16655],
            [126.88538, 35.16631],
            [126.88556, 35.16615],
            [126.88575, 35.16585],
            [126.88589, 35.16598],
            [126.88608, 35.16586],
            [126.88636, 35.16517],
            [126.88658, 35.16473],
            [126.8867, 35.16427],
            [126.88672, 35.16405],
            [126.88681, 35.16406],
            [126.8869, 35.16394],
            [126.88702, 35.16323],
            [126.88709, 35.16314],
            [126.88711, 35.16308],
            [126.8871, 35.16279],
            [126.88717, 35.16278],
            [126.8872, 35.16274],
            [126.88716, 35.16262],
            [126.88771, 35.1616],
            [126.88779, 35.1614],
            [126.88806, 35.16143],
            [126.88845, 35.16143],
            [126.8892, 35.16091],
            [126.88998, 35.16078],
            [126.89104, 35.16074],
            [126.89186, 35.16082],
            [126.89276, 35.16072],
            [126.89361, 35.16037],
            [126.89467, 35.15978],
            [126.89561, 35.15933],
            [126.89577, 35.15924],
            [126.89668, 35.15894],
            [126.89726, 35.15871],
            [126.89779, 35.15855],
            [126.89821, 35.15841],
            [126.89877, 35.1581],
            [126.89943, 35.15766],
            [126.90027, 35.15705],
            [126.90076, 35.15659],
            [126.90167, 35.1557],
            [126.90203, 35.15538],
            [126.90286, 35.15488],
            [126.90269, 35.15471],
            [126.90448, 35.15341],
            [126.90693, 35.15161],
            [126.90819, 35.15072],
            [126.90895, 35.15007],
            [126.9097, 35.14917],
            [126.90945, 35.14902],
            [126.90927, 35.14906],
            [126.90921, 35.149],
            [126.90903, 35.14899],
            [126.90887, 35.14888],
            [126.90841, 35.14887],
            [126.90753, 35.1488],
            [126.90687, 35.14864],
            [126.90658, 35.14859],
            [126.9059, 35.14842],
            [126.90558, 35.14831],
            [126.90535, 35.14827],
            [126.90414, 35.14882],
            [126.90385, 35.14898],
            [126.90361, 35.14915],
            [126.90327, 35.14944],
            [126.90283, 35.14991],
            [126.90271, 35.15008],
            [126.90216, 35.15109],
            [126.90187, 35.15161],
            [126.90175, 35.15177],
            [126.9015, 35.15202],
            [126.90124, 35.15224],
            [126.90098, 35.1524],
            [126.9007, 35.15248],
            [126.90049, 35.15249],
            [126.89925, 35.15236],
            [126.89845, 35.15231],
            [126.89741, 35.15241],
            [126.89724, 35.15237],
            [126.89655, 35.15219],
            [126.89655, 35.15224],
            [126.89621, 35.15213],
            [126.89601, 35.1521],
            [126.89596, 35.15214],
            [126.89586, 35.15211],
            [126.89593, 35.15198],
            [126.8943, 35.15146],
            [126.89457, 35.15087],
            [126.89338, 35.15049],
            [126.89331, 35.15007],
            [126.89224, 35.1494],
            [126.8902, 35.14903],
            [126.88541, 35.14657],
            [126.88831, 35.14279],
            [126.88755, 35.1424],
            [126.88781, 35.14207],
            [126.88592, 35.1412],
            [126.88578, 35.14124],
            [126.88567, 35.14138],
            [126.88559, 35.14118],
            [126.8856, 35.1411],
            [126.88558, 35.14103],
            [126.88544, 35.14097],
            [126.88543, 35.14082],
            [126.88547, 35.14077],
            [126.88545, 35.1407],
            [126.88536, 35.14075],
            [126.88531, 35.1407],
            [126.88538, 35.1406],
            [126.88529, 35.14056],
            [126.88517, 35.14043],
            [126.88531, 35.14038],
            [126.88541, 35.14023],
            [126.88521, 35.14012],
            [126.88529, 35.13975],
            [126.88508, 35.13978],
            [126.88492, 35.13971],
            [126.88487, 35.13957],
            [126.88483, 35.13952],
            [126.88472, 35.13948],
            [126.88419, 35.13912],
            [126.88384, 35.13902],
            [126.88344, 35.13895],
            [126.88327, 35.13883],
            [126.8832, 35.13835],
            [126.88366, 35.13756],
            [126.8841, 35.13755],
            [126.88424, 35.13751],
            [126.88521, 35.13683],
            [126.88571, 35.13656],
            [126.8858, 35.13631],
            [126.886, 35.13555],
            [126.88613, 35.13515],
            [126.88627, 35.13486],
            [126.88642, 35.13468],
            [126.8867, 35.13442],
            [126.88733, 35.13447],
            [126.88756, 35.134],
            [126.88745, 35.13351],
            [126.88744, 35.13317],
            [126.88758, 35.13277],
            [126.88755, 35.13217],
            [126.88765, 35.13197],
            [126.88729, 35.13167],
            [126.88688, 35.1313],
            [126.88681, 35.13085],
            [126.88684, 35.13065],
            [126.88661, 35.13035],
            [126.88659, 35.13029],
            [126.88638, 35.13007],
            [126.88648, 35.12996],
            [126.88661, 35.1292],
            [126.88677, 35.12905],
            [126.88677, 35.12876],
            [126.88688, 35.12851],
            [126.88695, 35.1275],
            [126.88693, 35.12694],
            [126.88697, 35.12684],
            [126.88707, 35.12669],
            [126.88727, 35.12624],
            [126.8873, 35.12598],
            [126.88734, 35.1258],
            [126.8878, 35.12542],
            [126.88802, 35.12534],
            [126.88813, 35.12526],
            [126.8882, 35.12522],
            [126.88833, 35.12503],
            [126.88838, 35.12481],
            [126.88838, 35.12459],
            [126.88829, 35.12431],
            [126.88824, 35.12383],
            [126.88833, 35.12357],
            [126.88835, 35.1231],
            [126.88834, 35.12258],
            [126.88826, 35.12234],
            [126.8885, 35.12154],
            [126.88845, 35.12123],
            [126.88829, 35.12073],
            [126.88836, 35.12046],
            [126.88831, 35.12006],
            [126.88818, 35.11976],
            [126.8878, 35.11929],
            [126.88766, 35.11903],
            [126.88785, 35.11855],
            [126.88833, 35.11784],
            [126.88769, 35.11694],
            [126.88737, 35.11696],
            [126.88653, 35.11711],
            [126.88579, 35.11717],
            [126.88571, 35.11718],
            [126.88533, 35.11705],
            [126.88497, 35.11681],
            [126.88486, 35.11679],
            [126.88455, 35.11683],
            [126.88344, 35.11619],
            [126.88336, 35.11599],
            [126.88291, 35.11601],
            [126.88227, 35.1161],
            [126.88185, 35.11646],
            [126.88124, 35.11675],
            [126.88043, 35.11703],
            [126.87949, 35.11744],
            [126.87919, 35.11762],
            [126.87891, 35.11755],
            [126.87815, 35.11777],
            [126.87783, 35.11792],
            [126.87767, 35.1181],
            [126.877, 35.11845],
            [126.87688, 35.11861],
            [126.87618, 35.11894],
            [126.8754, 35.11908],
            [126.87502, 35.11921],
            [126.8747, 35.11968],
            [126.87436, 35.12003],
            [126.8733, 35.12003],
            [126.87336, 35.11973],
            [126.87345, 35.11959],
            [126.87359, 35.11942],
            [126.87341, 35.11902],
            [126.87323, 35.11886],
            [126.87302, 35.11858],
            [126.87272, 35.11838],
            [126.87266, 35.11803],
            [126.8722, 35.11779],
            [126.87179, 35.11766],
            [126.87178, 35.11724],
            [126.87103, 35.11641],
            [126.87064, 35.11627],
            [126.87053, 35.11618],
            [126.87038, 35.11613],
            [126.87007, 35.11598],
            [126.86974, 35.116],
            [126.86958, 35.11585],
            [126.86949, 35.11579],
            [126.86958, 35.11559],
            [126.8696, 35.11543],
            [126.86963, 35.11533],
            [126.86995, 35.11498],
            [126.87013, 35.11465],
            [126.87045, 35.11431],
            [126.8708, 35.11401],
            [126.87088, 35.11383],
            [126.87103, 35.11358],
            [126.87104, 35.11308],
            [126.87076, 35.11234],
            [126.8703, 35.11169],
            [126.87011, 35.11093],
            [126.8702, 35.11078],
            [126.87064, 35.11022],
            [126.87056, 35.11021],
            [126.87022, 35.11023],
            [126.8697, 35.11027],
            [126.8686, 35.11006],
            [126.8683, 35.11019],
            [126.86803, 35.11017],
            [126.86774, 35.11043],
            [126.86751, 35.11045],
            [126.86641, 35.11059],
            [126.866, 35.1106],
            [126.86596, 35.11043],
            [126.86575, 35.10986],
            [126.86552, 35.10932],
            [126.86493, 35.10936],
            [126.86442, 35.1092],
            [126.86425, 35.10914],
            [126.86417, 35.1091],
            [126.86402, 35.10908],
            [126.86387, 35.10917],
            [126.86369, 35.10915],
            [126.86325, 35.10952],
            [126.86207, 35.10917],
            [126.86172, 35.10908],
            [126.86153, 35.10901],
            [126.86142, 35.10894],
            [126.8613, 35.10898],
            [126.86117, 35.10905],
            [126.86109, 35.10913],
            [126.86083, 35.10915],
            [126.8606, 35.1091],
            [126.86047, 35.10908],
            [126.86036, 35.10902],
            [126.86021, 35.10898],
            [126.86003, 35.10885],
            [126.85993, 35.10872],
            [126.85982, 35.1087],
            [126.85951, 35.10835],
            [126.85967, 35.10827],
            [126.85999, 35.10819],
            [126.86085, 35.10805],
            [126.86123, 35.10759],
            [126.8613, 35.10727],
            [126.86098, 35.10676],
            [126.86073, 35.10656],
            [126.86063, 35.10636],
            [126.85989, 35.10631],
            [126.85928, 35.10624],
            [126.85897, 35.10624],
            [126.85876, 35.10617],
            [126.85789, 35.10622],
            [126.85758, 35.10621],
            [126.85719, 35.10584],
            [126.85687, 35.10606],
            [126.85664, 35.10625],
            [126.85621, 35.10678],
            [126.85586, 35.10711],
            [126.85527, 35.10719],
            [126.85479, 35.10704],
            [126.85476, 35.10701],
            [126.85447, 35.10662],
            [126.85433, 35.10659],
            [126.85366, 35.10636],
            [126.85305, 35.10641],
            [126.85223, 35.10614],
            [126.8515, 35.10597],
            [126.85109, 35.10592],
            [126.85068, 35.10592],
            [126.84977, 35.10555],
            [126.84921, 35.10558],
            [126.84898, 35.10555],
            [126.84855, 35.1058],
            [126.84778, 35.10576],
            [126.84742, 35.10559],
            [126.84678, 35.10523],
            [126.84666, 35.10505],
            [126.84643, 35.10448],
            [126.84594, 35.10439],
            [126.84559, 35.1044],
            [126.84402, 35.10401],
            [126.84382, 35.10351],
            [126.84316, 35.10294],
            [126.84252, 35.10262],
            [126.84247, 35.10201],
            [126.84225, 35.10179],
            [126.84105, 35.101],
            [126.84041, 35.1012],
            [126.83946, 35.10132],
            [126.8383, 35.10201],
            [126.83811, 35.10228],
            [126.83763, 35.10174],
            [126.83741, 35.10128],
            [126.83724, 35.10038],
            [126.83706, 35.10029],
            [126.83695, 35.10015],
            [126.83683, 35.09974],
            [126.83688, 35.09916],
            [126.83664, 35.09864],
            [126.83574, 35.09817],
            [126.83514, 35.09793],
            [126.83407, 35.09782],
            [126.83363, 35.09781],
            [126.83294, 35.09792],
            [126.83261, 35.09794],
            [126.83192, 35.09792],
            [126.83152, 35.09803],
            [126.83087, 35.09748],
            [126.83052, 35.09677],
            [126.83017, 35.09641],
            [126.82969, 35.09596],
            [126.82933, 35.09555],
            [126.82865, 35.09512],
            [126.8283, 35.09489],
            [126.82825, 35.09484],
            [126.8283, 35.09474],
            [126.82835, 35.09444],
            [126.82811, 35.09442],
            [126.82761, 35.09444],
            [126.82742, 35.09441],
            [126.82705, 35.09426],
            [126.82655, 35.09367],
            [126.82623, 35.09344],
            [126.82576, 35.09315],
            [126.82529, 35.09305],
            [126.82447, 35.09324],
            [126.82409, 35.09303],
            [126.82376, 35.09289],
            [126.82347, 35.09268],
            [126.82321, 35.09241],
            [126.82273, 35.09209],
            [126.82225, 35.09197],
            [126.82212, 35.09186],
            [126.82128, 35.09167],
            [126.82077, 35.09141],
            [126.82008, 35.09093],
            [126.81819, 35.09201],
            [126.81743, 35.09266],
            [126.81677, 35.09317],
            [126.81655, 35.09343],
            [126.81628, 35.09371],
            [126.81539, 35.09357],
            [126.81521, 35.09349],
            [126.81502, 35.0935],
            [126.81446, 35.09324],
            [126.81436, 35.09327],
            [126.81431, 35.0932],
            [126.81396, 35.09283],
            [126.81368, 35.09261],
            [126.81323, 35.0922],
            [126.81236, 35.09172],
            [126.81188, 35.09138],
            [126.81177, 35.09124],
            [126.81162, 35.09111],
            [126.81155, 35.09116],
            [126.81147, 35.09112],
            [126.81134, 35.09099],
            [126.81111, 35.09094],
            [126.81058, 35.09155],
            [126.81013, 35.09188],
            [126.80971, 35.09213],
            [126.80959, 35.09226],
            [126.80956, 35.0924],
            [126.80941, 35.09275],
            [126.8093, 35.09285],
            [126.8091, 35.09309],
            [126.80902, 35.0931],
            [126.80891, 35.09326],
            [126.8084, 35.09362],
            [126.80837, 35.09393],
            [126.80799, 35.09421],
            [126.80798, 35.09457],
            [126.80775, 35.09487],
            [126.80739, 35.09548],
            [126.8071, 35.09655],
            [126.80692, 35.09668],
            [126.80649, 35.09757],
            [126.80641, 35.09746],
            [126.80637, 35.0973],
            [126.80631, 35.09726],
            [126.80623, 35.09723],
            [126.8059, 35.09681],
            [126.80577, 35.09678],
            [126.80572, 35.09672],
            [126.8057, 35.09667],
            [126.80561, 35.09663],
            [126.80553, 35.09657],
            [126.8055, 35.09648],
            [126.80539, 35.09637],
            [126.80522, 35.09601],
            [126.80517, 35.09579],
            [126.80526, 35.09573],
            [126.80533, 35.09562],
            [126.80533, 35.0954],
            [126.80515, 35.09512],
            [126.80515, 35.09504],
            [126.805, 35.09489],
            [126.80458, 35.0947],
            [126.80445, 35.09461],
            [126.80445, 35.09439],
            [126.80441, 35.09434],
            [126.80423, 35.09428],
            [126.80406, 35.09417],
            [126.80395, 35.09402],
            [126.8037, 35.09381],
            [126.80364, 35.09381],
            [126.80359, 35.09391],
            [126.80349, 35.09387],
            [126.80339, 35.09357],
            [126.80317, 35.09355],
            [126.80253, 35.09349],
            [126.80252, 35.09372],
            [126.80245, 35.09403],
            [126.80214, 35.09464],
            [126.8016, 35.09532],
            [126.8011, 35.09607],
            [126.80078, 35.0967],
            [126.80067, 35.0972],
            [126.80066, 35.09778],
            [126.80068, 35.09804],
            [126.80067, 35.09869],
            [126.80075, 35.09918],
            [126.80084, 35.09995],
            [126.80093, 35.1009],
            [126.80162, 35.10171],
            [126.8025, 35.10217],
            [126.80339, 35.10282],
            [126.80437, 35.10235],
            [126.80531, 35.10225],
            [126.80595, 35.10243],
            [126.80633, 35.10287],
            [126.8062, 35.10342],
            [126.80583, 35.1039],
            [126.80555, 35.10451],
            [126.80555, 35.10497],
            [126.80527, 35.10517],
            [126.80508, 35.10545],
            [126.80495, 35.1058],
            [126.80475, 35.10643],
            [126.8047, 35.10675],
            [126.80472, 35.10708],
            [126.80488, 35.10752],
            [126.80473, 35.10782],
            [126.80431, 35.1082],
            [126.80407, 35.10849],
            [126.8038, 35.10899],
            [126.80314, 35.11067],
            [126.80299, 35.11101],
            [126.80286, 35.1115],
            [126.80299, 35.11172],
            [126.80286, 35.11208],
            [126.80281, 35.11225],
            [126.80304, 35.11238],
            [126.8033, 35.11252],
            [126.80333, 35.11258],
            [126.80333, 35.11265],
            [126.80351, 35.1127],
            [126.80388, 35.1127],
            [126.80441, 35.1124],
            [126.80447, 35.11249],
            [126.80457, 35.11255],
            [126.80477, 35.11249],
            [126.80482, 35.11243],
            [126.80492, 35.11239],
            [126.80515, 35.11237],
            [126.80531, 35.11224],
            [126.80565, 35.11207],
            [126.80581, 35.11193],
            [126.80593, 35.11191],
            [126.80609, 35.11193],
            [126.80628, 35.11198],
            [126.80656, 35.11213],
            [126.80667, 35.11213],
            [126.80672, 35.11218],
            [126.80677, 35.11228],
            [126.80684, 35.11228],
            [126.80691, 35.11252],
            [126.80706, 35.11251],
            [126.80708, 35.11256],
            [126.80746, 35.11257],
            [126.80774, 35.1126],
            [126.80784, 35.11262],
            [126.80797, 35.11257],
            [126.80803, 35.11254],
            [126.8083, 35.1125],
            [126.8084, 35.11244],
            [126.80846, 35.11242],
            [126.80878, 35.11247],
            [126.80895, 35.11246],
            [126.8092, 35.11234],
            [126.80927, 35.1123],
            [126.80942, 35.11239],
            [126.80939, 35.11285],
            [126.80946, 35.11312],
            [126.80964, 35.11351],
            [126.80965, 35.11374],
            [126.80964, 35.11381],
            [126.80952, 35.11404],
            [126.80942, 35.11409],
            [126.80937, 35.11416],
            [126.80937, 35.11424],
            [126.80943, 35.11434],
            [126.8095, 35.11442],
            [126.80948, 35.1145],
            [126.80946, 35.11464],
            [126.80946, 35.11495],
            [126.80944, 35.11508],
            [126.80948, 35.11523],
            [126.80951, 35.11529],
            [126.80957, 35.11533],
            [126.80958, 35.11538],
            [126.80946, 35.11569],
            [126.80957, 35.11581],
            [126.80971, 35.11607],
            [126.8097, 35.1162],
            [126.80959, 35.11629],
            [126.80964, 35.11636],
            [126.80974, 35.11635],
            [126.80982, 35.11646],
            [126.80983, 35.11657],
            [126.80993, 35.11673],
            [126.81033, 35.11662],
            [126.81098, 35.11669],
            [126.81174, 35.1167],
            [126.81204, 35.11667],
            [126.81218, 35.11676],
            [126.81229, 35.11691],
            [126.8126, 35.1169],
            [126.81313, 35.11704],
            [126.81344, 35.11701],
            [126.81394, 35.11679],
            [126.81408, 35.11677],
            [126.81432, 35.11676],
            [126.81503, 35.11689],
            [126.81538, 35.11685],
            [126.81563, 35.11676],
            [126.81596, 35.11681],
            [126.81645, 35.11659],
            [126.81675, 35.1166],
            [126.81704, 35.11679],
            [126.81751, 35.11723],
            [126.81792, 35.11783],
            [126.81847, 35.11829],
            [126.8181, 35.11815],
            [126.81774, 35.11813],
            [126.81736, 35.11816],
            [126.81722, 35.11822],
            [126.81703, 35.11835],
            [126.81686, 35.11853],
            [126.81687, 35.11895],
            [126.81711, 35.11979],
            [126.81695, 35.12074],
            [126.81723, 35.12148],
            [126.81767, 35.12207],
            [126.81828, 35.12264],
            [126.81977, 35.12358],
            [126.82042, 35.12389],
            [126.82102, 35.12429],
            [126.82152, 35.12507],
            [126.82179, 35.12568],
            [126.82205, 35.12618],
            [126.82225, 35.12666],
            [126.82204, 35.12713],
            [126.82158, 35.12783],
            [126.82092, 35.12836],
            [126.81978, 35.12895],
            [126.81903, 35.12977],
            [126.81891, 35.13004],
            [126.81877, 35.13056],
            [126.81869, 35.13116],
            [126.81873, 35.1317],
            [126.81886, 35.1323],
            [126.81901, 35.13282],
            [126.81923, 35.13315],
            [126.8203, 35.13405],
            [126.82063, 35.13427],
            [126.82146, 35.13466],
            [126.82228, 35.13497],
            [126.82321, 35.13511],
            [126.82373, 35.13523],
            [126.82425, 35.13562],
            [126.82512, 35.13657],
            [126.82602, 35.13739],
            [126.82653, 35.13793],
            [126.82743, 35.13863],
            [126.82787, 35.13908],
            [126.82817, 35.13934],
            [126.82877, 35.14014],
            [126.82946, 35.14084],
            [126.82969, 35.14148],
            [126.82958, 35.14257],
            [126.82931, 35.14334],
            [126.82919, 35.14357],
            [126.82913, 35.14421],
            [126.82913, 35.14445],
            [126.82926, 35.14533],
            [126.82913, 35.14561],
            [126.82917, 35.14609],
            [126.82925, 35.14661],
            [126.82927, 35.14717],
            [126.82913, 35.14862],
            [126.82902, 35.14905],
            [126.82893, 35.14924],
            [126.82884, 35.14931],
            [126.82853, 35.15019],
            [126.82846, 35.15035],
            [126.82817, 35.15073],
            [126.82769, 35.15119],
            [126.82754, 35.15142],
            [126.82708, 35.15192],
            [126.82611, 35.15274],
            [126.82528, 35.15365],
            [126.82524, 35.15452],
            [126.82535, 35.15493],
            [126.82543, 35.15515],
            [126.82528, 35.1558],
            [126.82527, 35.15588],
            [126.82548, 35.15697],
            [126.82554, 35.15713],
            [126.82573, 35.15742],
            [126.82635, 35.15814],
            [126.82651, 35.15835],
            [126.82653, 35.1584],
            [126.82645, 35.15878],
            [126.8264, 35.15913],
            [126.82638, 35.15982],
            [126.82639, 35.16038],
            [126.8261, 35.16046],
            [126.82594, 35.16076],
            [126.82556, 35.16113],
            [126.82521, 35.16135],
            [126.82499, 35.16172],
            [126.82466, 35.16222],
            [126.82449, 35.16272],
            [126.82448, 35.16393],
            [126.82453, 35.16415],
            [126.82473, 35.16469],
            [126.82483, 35.16523],
            [126.82489, 35.16539],
            [126.82508, 35.16573],
            [126.82519, 35.16587],
            [126.82629, 35.16676],
            [126.82694, 35.1673],
            [126.82793, 35.16801],
            [126.8284, 35.16817],
            [126.82907, 35.16842],
            [126.83045, 35.16922],
            [126.83087, 35.1694],
            [126.83189, 35.16963],
            [126.83279, 35.16989],
            [126.83398, 35.16988],
            [126.83615, 35.17008],
            [126.8369, 35.17045],
            [126.83742, 35.17082],
            [126.83762, 35.17121],
            [126.83765, 35.17153],
            [126.83755, 35.17213],
            [126.83742, 35.1725],
            [126.8374, 35.17306],
            [126.83755, 35.17329],
            [126.83759, 35.17378],
            [126.83773, 35.17491],
            [126.83774, 35.17499],
            [126.83768, 35.17547],
            [126.83767, 35.17603],
            [126.8377, 35.17667],
            [126.83806, 35.17743],
            [126.83873, 35.17841]
          ]
        ]
      }
    },
    {
      id: '29155',
      type: 'Feature',
      properties: {
        SEC_SGG_CD: '29155',
        SEC_SGG_NM: ['광주광역시', '남구'],
        COL_SGG_CD: '29000',
        SEC_SGG_SIZE: '2',
        bbox: [126.75314, 35.0509, 126.92964, 35.15249],
        center: [126.85679, 35.09407],
        area: 60522254
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.90108, 35.15234],
            [126.90124, 35.15224],
            [126.9015, 35.15202],
            [126.90175, 35.15177],
            [126.90187, 35.15161],
            [126.90216, 35.15109],
            [126.90271, 35.15008],
            [126.90283, 35.14991],
            [126.90327, 35.14944],
            [126.90361, 35.14915],
            [126.90385, 35.14898],
            [126.90414, 35.14882],
            [126.90535, 35.14827],
            [126.90558, 35.14831],
            [126.9059, 35.14842],
            [126.90753, 35.1488],
            [126.90841, 35.14887],
            [126.90887, 35.14888],
            [126.90903, 35.14899],
            [126.90921, 35.149],
            [126.90927, 35.14906],
            [126.90945, 35.14902],
            [126.9097, 35.14917],
            [126.9106, 35.14821],
            [126.91132, 35.1474],
            [126.91154, 35.14713],
            [126.91264, 35.14589],
            [126.91312, 35.14533],
            [126.91349, 35.14493],
            [126.91375, 35.14469],
            [126.91442, 35.14414],
            [126.91489, 35.1438],
            [126.91531, 35.14346],
            [126.91598, 35.14287],
            [126.9163, 35.14271],
            [126.9166, 35.1425],
            [126.91767, 35.14138],
            [126.91794, 35.14107],
            [126.91865, 35.1402],
            [126.91901, 35.13982],
            [126.91921, 35.13955],
            [126.91944, 35.13932],
            [126.91948, 35.13913],
            [126.91949, 35.13893],
            [126.91956, 35.13885],
            [126.91989, 35.13839],
            [126.92031, 35.13797],
            [126.92056, 35.13808],
            [126.92083, 35.13747],
            [126.92097, 35.13709],
            [126.92098, 35.13681],
            [126.92112, 35.13645],
            [126.92174, 35.13538],
            [126.92252, 35.1349],
            [126.92327, 35.13431],
            [126.924, 35.13394],
            [126.92451, 35.13349],
            [126.92475, 35.13298],
            [126.92527, 35.1325],
            [126.92535, 35.13241],
            [126.92566, 35.13174],
            [126.92582, 35.13114],
            [126.92605, 35.13056],
            [126.92643, 35.12989],
            [126.92712, 35.12947],
            [126.92745, 35.12936],
            [126.92735, 35.12785],
            [126.92826, 35.1275],
            [126.92849, 35.12737],
            [126.92964, 35.12645],
            [126.92951, 35.12633],
            [126.92899, 35.12612],
            [126.9289, 35.12587],
            [126.92878, 35.12583],
            [126.92868, 35.12576],
            [126.9283, 35.1257],
            [126.92766, 35.12545],
            [126.92715, 35.12543],
            [126.9266, 35.1253],
            [126.92553, 35.125],
            [126.92429, 35.1247],
            [126.92437, 35.12458],
            [126.92446, 35.12422],
            [126.92448, 35.12364],
            [126.92429, 35.12365],
            [126.9236, 35.12313],
            [126.92339, 35.123],
            [126.92299, 35.12263],
            [126.92277, 35.12226],
            [126.92276, 35.12212],
            [126.92276, 35.1218],
            [126.92267, 35.12154],
            [126.92271, 35.12095],
            [126.92231, 35.12064],
            [126.92188, 35.12019],
            [126.9218, 35.12009],
            [126.92187, 35.11986],
            [126.9217, 35.11974],
            [126.92143, 35.11967],
            [126.92135, 35.11967],
            [126.92089, 35.11956],
            [126.92062, 35.1194],
            [126.92046, 35.11928],
            [126.92009, 35.11921],
            [126.9198, 35.11898],
            [126.91969, 35.11848],
            [126.91954, 35.11834],
            [126.91954, 35.11795],
            [126.91944, 35.11776],
            [126.91907, 35.11751],
            [126.9189, 35.11737],
            [126.91834, 35.11718],
            [126.91794, 35.11714],
            [126.91776, 35.11709],
            [126.91758, 35.11697],
            [126.91703, 35.1169],
            [126.91609, 35.11686],
            [126.9159, 35.11716],
            [126.91546, 35.11708],
            [126.91503, 35.11676],
            [126.9148, 35.11664],
            [126.91381, 35.11646],
            [126.91359, 35.11615],
            [126.91298, 35.11594],
            [126.91263, 35.11585],
            [126.91227, 35.11561],
            [126.91199, 35.11559],
            [126.91188, 35.11563],
            [126.91217, 35.11526],
            [126.91266, 35.11484],
            [126.9132, 35.11458],
            [126.91324, 35.11453],
            [126.91358, 35.11375],
            [126.91344, 35.11355],
            [126.91302, 35.11316],
            [126.91296, 35.11308],
            [126.91286, 35.11284],
            [126.91243, 35.11239],
            [126.91236, 35.11227],
            [126.91235, 35.11177],
            [126.91247, 35.11147],
            [126.91249, 35.11056],
            [126.91299, 35.10984],
            [126.91361, 35.1093],
            [126.91425, 35.10888],
            [126.91406, 35.10815],
            [126.91404, 35.10742],
            [126.91388, 35.10716],
            [126.91397, 35.10677],
            [126.91395, 35.10627],
            [126.91448, 35.10571],
            [126.91455, 35.10516],
            [126.91492, 35.10474],
            [126.91532, 35.10441],
            [126.91568, 35.10417],
            [126.91639, 35.1038],
            [126.91667, 35.10348],
            [126.91718, 35.10301],
            [126.91788, 35.10206],
            [126.91803, 35.10157],
            [126.9179, 35.10106],
            [126.91762, 35.10046],
            [126.91781, 35.09972],
            [126.91825, 35.09896],
            [126.91901, 35.09875],
            [126.91977, 35.09883],
            [126.92022, 35.09867],
            [126.92051, 35.09819],
            [126.92088, 35.09778],
            [126.92115, 35.09734],
            [126.92136, 35.09724],
            [126.92085, 35.0966],
            [126.92072, 35.0959],
            [126.92068, 35.09532],
            [126.92068, 35.09517],
            [126.92061, 35.0947],
            [126.92001, 35.09398],
            [126.91971, 35.09342],
            [126.91982, 35.09289],
            [126.91941, 35.09228],
            [126.91942, 35.09169],
            [126.91856, 35.09162],
            [126.918, 35.09152],
            [126.91734, 35.09126],
            [126.91671, 35.09124],
            [126.91628, 35.09099],
            [126.916, 35.09078],
            [126.91539, 35.09036],
            [126.91452, 35.08991],
            [126.91388, 35.08967],
            [126.9134, 35.08946],
            [126.91243, 35.08924],
            [126.91196, 35.08895],
            [126.91059, 35.08865],
            [126.91069, 35.08742],
            [126.91027, 35.08713],
            [126.90988, 35.08679],
            [126.90971, 35.08632],
            [126.90956, 35.08617],
            [126.90899, 35.08576],
            [126.90851, 35.0852],
            [126.90772, 35.08468],
            [126.90687, 35.08405],
            [126.90675, 35.08389],
            [126.90621, 35.08325],
            [126.90604, 35.083],
            [126.90583, 35.08205],
            [126.90509, 35.08171],
            [126.90457, 35.08138],
            [126.90305, 35.08133],
            [126.90211, 35.08104],
            [126.90184, 35.08102],
            [126.90131, 35.0809],
            [126.90091, 35.0809],
            [126.90049, 35.08088],
            [126.89996, 35.08074],
            [126.89912, 35.08121],
            [126.89804, 35.08121],
            [126.89767, 35.08124],
            [126.89711, 35.08113],
            [126.89592, 35.08086],
            [126.89515, 35.08072],
            [126.89449, 35.08017],
            [126.89439, 35.08005],
            [126.89398, 35.07976],
            [126.89379, 35.07946],
            [126.89362, 35.0793],
            [126.89339, 35.07889],
            [126.89305, 35.07861],
            [126.89206, 35.0781],
            [126.89104, 35.07843],
            [126.89063, 35.07866],
            [126.88999, 35.07884],
            [126.88944, 35.07934],
            [126.88899, 35.07952],
            [126.88818, 35.07997],
            [126.8868, 35.08038],
            [126.88629, 35.08109],
            [126.88615, 35.08118],
            [126.88541, 35.08158],
            [126.88463, 35.08109],
            [126.88312, 35.08035],
            [126.88273, 35.07982],
            [126.88236, 35.07961],
            [126.88161, 35.07924],
            [126.88124, 35.07888],
            [126.88063, 35.07875],
            [126.87988, 35.07845],
            [126.87908, 35.07829],
            [126.87871, 35.07766],
            [126.87718, 35.07721],
            [126.87643, 35.07709],
            [126.87632, 35.07605],
            [126.87489, 35.07542],
            [126.87391, 35.07514],
            [126.87357, 35.07501],
            [126.87355, 35.07514],
            [126.87351, 35.07531],
            [126.87345, 35.0753],
            [126.87339, 35.07526],
            [126.87327, 35.07525],
            [126.87305, 35.07517],
            [126.87296, 35.07518],
            [126.87284, 35.07522],
            [126.87252, 35.07497],
            [126.87199, 35.07446],
            [126.87169, 35.07436],
            [126.87111, 35.07449],
            [126.87072, 35.07473],
            [126.86979, 35.07463],
            [126.86959, 35.07466],
            [126.86907, 35.07479],
            [126.86848, 35.07534],
            [126.8679, 35.07551],
            [126.86767, 35.07562],
            [126.86754, 35.07563],
            [126.86729, 35.0757],
            [126.86698, 35.0758],
            [126.86684, 35.0758],
            [126.86622, 35.07604],
            [126.866, 35.07574],
            [126.86548, 35.07532],
            [126.865, 35.07553],
            [126.86454, 35.07558],
            [126.86438, 35.07583],
            [126.86389, 35.07618],
            [126.86372, 35.07622],
            [126.86265, 35.07666],
            [126.86243, 35.07671],
            [126.86224, 35.07692],
            [126.86187, 35.07794],
            [126.86153, 35.0781],
            [126.8614, 35.07828],
            [126.86114, 35.07893],
            [126.86055, 35.07881],
            [126.85989, 35.07894],
            [126.85911, 35.07868],
            [126.85868, 35.07831],
            [126.85823, 35.07827],
            [126.85788, 35.07834],
            [126.85748, 35.07859],
            [126.85731, 35.07863],
            [126.85684, 35.07853],
            [126.85633, 35.07847],
            [126.85563, 35.07837],
            [126.85555, 35.07797],
            [126.85549, 35.07781],
            [126.85508, 35.07743],
            [126.85505, 35.07702],
            [126.85497, 35.07656],
            [126.85486, 35.07638],
            [126.85495, 35.07615],
            [126.85453, 35.07552],
            [126.85434, 35.07503],
            [126.85417, 35.07472],
            [126.85385, 35.07429],
            [126.85347, 35.07395],
            [126.85341, 35.0738],
            [126.8529, 35.07358],
            [126.85236, 35.0735],
            [126.85211, 35.07343],
            [126.85192, 35.07321],
            [126.85123, 35.073],
            [126.85116, 35.07296],
            [126.85083, 35.07289],
            [126.85015, 35.07287],
            [126.8497, 35.07252],
            [126.84927, 35.07237],
            [126.8484, 35.07221],
            [126.84817, 35.07194],
            [126.84814, 35.07135],
            [126.84819, 35.07102],
            [126.84757, 35.07035],
            [126.84685, 35.06984],
            [126.8466, 35.06904],
            [126.84665, 35.06855],
            [126.84579, 35.06827],
            [126.84589, 35.06791],
            [126.84589, 35.06762],
            [126.84585, 35.06742],
            [126.84591, 35.06705],
            [126.84564, 35.0668],
            [126.84555, 35.06678],
            [126.84549, 35.06674],
            [126.8454, 35.06667],
            [126.8453, 35.0666],
            [126.8452, 35.06653],
            [126.84523, 35.06648],
            [126.84476, 35.06629],
            [126.84461, 35.06611],
            [126.84452, 35.06605],
            [126.84432, 35.06576],
            [126.84429, 35.06565],
            [126.84413, 35.06553],
            [126.84412, 35.06544],
            [126.84398, 35.06538],
            [126.84375, 35.06535],
            [126.84366, 35.06537],
            [126.84356, 35.06552],
            [126.84347, 35.06554],
            [126.84333, 35.06542],
            [126.84324, 35.0654],
            [126.84306, 35.06538],
            [126.84296, 35.06539],
            [126.84286, 35.06545],
            [126.84267, 35.06548],
            [126.84251, 35.06548],
            [126.84235, 35.06553],
            [126.84206, 35.0657],
            [126.84196, 35.06572],
            [126.84195, 35.06582],
            [126.84156, 35.06583],
            [126.84129, 35.06586],
            [126.84106, 35.0659],
            [126.84081, 35.06592],
            [126.8406, 35.0659],
            [126.8405, 35.06585],
            [126.84041, 35.06565],
            [126.84028, 35.06569],
            [126.83963, 35.0658],
            [126.83876, 35.06585],
            [126.83874, 35.06594],
            [126.83835, 35.06589],
            [126.83817, 35.06589],
            [126.83809, 35.06592],
            [126.83795, 35.06587],
            [126.83782, 35.0659],
            [126.83692, 35.06623],
            [126.83683, 35.06622],
            [126.83685, 35.06617],
            [126.83685, 35.06606],
            [126.83682, 35.06563],
            [126.83667, 35.06521],
            [126.83634, 35.06528],
            [126.83629, 35.06524],
            [126.83628, 35.06512],
            [126.83618, 35.06469],
            [126.83612, 35.0646],
            [126.8362, 35.06452],
            [126.83622, 35.06441],
            [126.83614, 35.06414],
            [126.83602, 35.06392],
            [126.83597, 35.06386],
            [126.83572, 35.06381],
            [126.83562, 35.06379],
            [126.83544, 35.0638],
            [126.83509, 35.06375],
            [126.83479, 35.0636],
            [126.8347, 35.06354],
            [126.83444, 35.0634],
            [126.83438, 35.06335],
            [126.83378, 35.06318],
            [126.83325, 35.06311],
            [126.83266, 35.06308],
            [126.83243, 35.06304],
            [126.83231, 35.06306],
            [126.83144, 35.06284],
            [126.83117, 35.06265],
            [126.83115, 35.0626],
            [126.83115, 35.06254],
            [126.83118, 35.06247],
            [126.83114, 35.06243],
            [126.83096, 35.06237],
            [126.83082, 35.06242],
            [126.83066, 35.06236],
            [126.83061, 35.06232],
            [126.83059, 35.06224],
            [126.82994, 35.062],
            [126.82984, 35.06197],
            [126.82962, 35.06197],
            [126.82948, 35.06207],
            [126.82931, 35.06215],
            [126.82924, 35.06216],
            [126.82908, 35.06207],
            [126.82903, 35.06203],
            [126.82875, 35.06201],
            [126.8287, 35.06207],
            [126.82864, 35.06219],
            [126.82858, 35.0622],
            [126.82818, 35.06214],
            [126.82803, 35.06217],
            [126.82758, 35.06222],
            [126.82759, 35.06227],
            [126.82756, 35.06234],
            [126.82721, 35.06232],
            [126.82694, 35.06226],
            [126.82675, 35.06219],
            [126.82653, 35.06247],
            [126.82648, 35.06241],
            [126.82596, 35.06197],
            [126.82563, 35.06173],
            [126.82525, 35.06147],
            [126.82496, 35.06136],
            [126.82446, 35.06102],
            [126.82407, 35.06082],
            [126.82392, 35.06079],
            [126.82383, 35.06082],
            [126.82376, 35.06081],
            [126.82341, 35.06062],
            [126.82333, 35.06055],
            [126.82315, 35.06047],
            [126.8229, 35.0604],
            [126.8224, 35.0603],
            [126.82228, 35.06024],
            [126.8222, 35.06016],
            [126.82197, 35.06005],
            [126.82184, 35.05997],
            [126.82182, 35.0598],
            [126.82178, 35.05971],
            [126.82155, 35.05926],
            [126.82145, 35.05913],
            [126.82118, 35.05896],
            [126.82092, 35.05874],
            [126.82076, 35.05858],
            [126.82072, 35.05846],
            [126.82071, 35.05838],
            [126.82054, 35.05805],
            [126.82053, 35.05794],
            [126.8205, 35.05783],
            [126.82057, 35.05752],
            [126.82054, 35.05737],
            [126.82052, 35.05711],
            [126.82046, 35.05693],
            [126.82035, 35.05679],
            [126.82011, 35.05641],
            [126.82007, 35.05631],
            [126.81983, 35.05607],
            [126.81982, 35.05586],
            [126.8198, 35.05577],
            [126.81975, 35.05564],
            [126.81963, 35.05558],
            [126.81949, 35.0555],
            [126.81939, 35.05535],
            [126.81922, 35.05517],
            [126.81912, 35.05499],
            [126.81909, 35.0549],
            [126.81909, 35.05469],
            [126.819, 35.05438],
            [126.81893, 35.05422],
            [126.81878, 35.05406],
            [126.81872, 35.05395],
            [126.8187, 35.05374],
            [126.81879, 35.05333],
            [126.81878, 35.05324],
            [126.81871, 35.05317],
            [126.81865, 35.05302],
            [126.81861, 35.05283],
            [126.81857, 35.05275],
            [126.8185, 35.05273],
            [126.81819, 35.05269],
            [126.81779, 35.0527],
            [126.81769, 35.05272],
            [126.81738, 35.05288],
            [126.81707, 35.05287],
            [126.81692, 35.05297],
            [126.81672, 35.05295],
            [126.81654, 35.0528],
            [126.81639, 35.05289],
            [126.81609, 35.05301],
            [126.81594, 35.05311],
            [126.81571, 35.0532],
            [126.81558, 35.05327],
            [126.81536, 35.05341],
            [126.81526, 35.05332],
            [126.81513, 35.05311],
            [126.81492, 35.05319],
            [126.81456, 35.05329],
            [126.81459, 35.05337],
            [126.8143, 35.05336],
            [126.81385, 35.05323],
            [126.8137, 35.05317],
            [126.81334, 35.05291],
            [126.81326, 35.05291],
            [126.8129, 35.0527],
            [126.81288, 35.0526],
            [126.81273, 35.05258],
            [126.81247, 35.05262],
            [126.81216, 35.0528],
            [126.81179, 35.05308],
            [126.81152, 35.0532],
            [126.81137, 35.05323],
            [126.81135, 35.05308],
            [126.81138, 35.053],
            [126.81115, 35.05281],
            [126.81096, 35.0526],
            [126.81053, 35.05293],
            [126.81045, 35.05286],
            [126.8099, 35.05232],
            [126.80974, 35.05248],
            [126.8095, 35.05229],
            [126.80944, 35.05222],
            [126.80934, 35.05224],
            [126.809, 35.05238],
            [126.80886, 35.05241],
            [126.8088, 35.05233],
            [126.80857, 35.05225],
            [126.80852, 35.05221],
            [126.80841, 35.0521],
            [126.80831, 35.05194],
            [126.8081, 35.05177],
            [126.80796, 35.05162],
            [126.80787, 35.05146],
            [126.80775, 35.05114],
            [126.80767, 35.05104],
            [126.80762, 35.051],
            [126.80744, 35.0509],
            [126.80732, 35.05102],
            [126.80703, 35.05118],
            [126.80691, 35.05119],
            [126.80684, 35.05118],
            [126.80654, 35.05111],
            [126.80631, 35.05116],
            [126.80611, 35.05116],
            [126.80602, 35.05125],
            [126.80581, 35.0514],
            [126.80562, 35.0515],
            [126.80515, 35.05154],
            [126.80489, 35.05158],
            [126.8047, 35.05217],
            [126.80469, 35.05229],
            [126.80469, 35.05239],
            [126.80464, 35.05241],
            [126.80421, 35.05237],
            [126.80406, 35.05238],
            [126.80369, 35.05237],
            [126.80359, 35.05242],
            [126.80354, 35.05264],
            [126.80348, 35.05318],
            [126.80342, 35.05349],
            [126.80341, 35.05362],
            [126.80345, 35.05378],
            [126.80341, 35.05392],
            [126.80343, 35.05405],
            [126.80343, 35.05414],
            [126.80335, 35.05425],
            [126.80328, 35.05443],
            [126.80307, 35.05484],
            [126.80304, 35.05497],
            [126.80308, 35.05515],
            [126.80294, 35.05532],
            [126.80273, 35.05552],
            [126.80266, 35.05562],
            [126.8026, 35.05567],
            [126.80249, 35.05569],
            [126.80255, 35.05575],
            [126.80252, 35.0558],
            [126.80227, 35.05599],
            [126.80216, 35.05611],
            [126.80192, 35.05636],
            [126.80169, 35.05648],
            [126.80166, 35.05652],
            [126.80169, 35.05663],
            [126.80166, 35.0567],
            [126.80161, 35.05676],
            [126.80154, 35.05684],
            [126.80135, 35.05721],
            [126.8012, 35.05744],
            [126.8014, 35.05761],
            [126.80143, 35.05768],
            [126.80149, 35.05786],
            [126.8015, 35.05811],
            [126.80147, 35.05815],
            [126.80119, 35.05821],
            [126.80101, 35.05837],
            [126.8008, 35.05848],
            [126.80032, 35.05877],
            [126.79977, 35.05916],
            [126.79921, 35.05942],
            [126.79918, 35.05938],
            [126.79898, 35.05919],
            [126.79895, 35.05913],
            [126.7988, 35.05923],
            [126.79854, 35.05947],
            [126.79847, 35.05945],
            [126.79848, 35.05933],
            [126.79842, 35.05931],
            [126.79818, 35.05919],
            [126.79811, 35.05918],
            [126.79802, 35.05923],
            [126.79786, 35.05912],
            [126.79751, 35.05896],
            [126.79731, 35.05897],
            [126.79695, 35.05905],
            [126.79665, 35.05934],
            [126.79636, 35.05966],
            [126.79621, 35.05997],
            [126.79607, 35.06051],
            [126.79595, 35.06076],
            [126.79577, 35.06093],
            [126.79551, 35.06107],
            [126.79524, 35.06112],
            [126.79491, 35.06112],
            [126.79463, 35.06104],
            [126.79432, 35.06081],
            [126.79404, 35.06051],
            [126.7938, 35.06023],
            [126.7936, 35.06012],
            [126.79343, 35.06008],
            [126.79276, 35.06002],
            [126.79254, 35.06003],
            [126.7919, 35.06024],
            [126.79177, 35.06031],
            [126.79158, 35.06049],
            [126.7914, 35.06045],
            [126.79124, 35.06034],
            [126.79106, 35.06011],
            [126.79097, 35.05968],
            [126.7909, 35.05951],
            [126.79075, 35.05926],
            [126.79066, 35.0592],
            [126.79051, 35.05915],
            [126.79021, 35.05917],
            [126.78977, 35.05902],
            [126.78968, 35.05904],
            [126.78953, 35.05904],
            [126.78939, 35.05899],
            [126.78881, 35.0585],
            [126.78871, 35.05843],
            [126.78853, 35.05799],
            [126.78841, 35.05781],
            [126.78829, 35.05773],
            [126.78827, 35.05768],
            [126.78814, 35.05758],
            [126.788, 35.05753],
            [126.78781, 35.05742],
            [126.78775, 35.05746],
            [126.78758, 35.05748],
            [126.78744, 35.05741],
            [126.78737, 35.05743],
            [126.78706, 35.0574],
            [126.78706, 35.05728],
            [126.78677, 35.0571],
            [126.78654, 35.05702],
            [126.78599, 35.0566],
            [126.78579, 35.05647],
            [126.78572, 35.05621],
            [126.78572, 35.0561],
            [126.78567, 35.05597],
            [126.7855, 35.0558],
            [126.78507, 35.05556],
            [126.78471, 35.05548],
            [126.78452, 35.05548],
            [126.78423, 35.05534],
            [126.7838, 35.05517],
            [126.78368, 35.05516],
            [126.78355, 35.0552],
            [126.78346, 35.05528],
            [126.78333, 35.05531],
            [126.78316, 35.05527],
            [126.78295, 35.05492],
            [126.78268, 35.05464],
            [126.78247, 35.05439],
            [126.78159, 35.05385],
            [126.78093, 35.05399],
            [126.78055, 35.05415],
            [126.77979, 35.05427],
            [126.77889, 35.05424],
            [126.77805, 35.05399],
            [126.7761, 35.0528],
            [126.77551, 35.05272],
            [126.77489, 35.05267],
            [126.77279, 35.05291],
            [126.77209, 35.05309],
            [126.77062, 35.05368],
            [126.76921, 35.05397],
            [126.76834, 35.05456],
            [126.76794, 35.05513],
            [126.76748, 35.05569],
            [126.76741, 35.05597],
            [126.76734, 35.05636],
            [126.76738, 35.05671],
            [126.76755, 35.05757],
            [126.76776, 35.05854],
            [126.76787, 35.05888],
            [126.76828, 35.05971],
            [126.76863, 35.0602],
            [126.76863, 35.06066],
            [126.76854, 35.061],
            [126.76851, 35.06118],
            [126.76843, 35.06141],
            [126.76779, 35.06244],
            [126.76747, 35.06287],
            [126.76685, 35.06351],
            [126.76629, 35.06388],
            [126.7657, 35.0641],
            [126.76511, 35.06436],
            [126.76449, 35.06427],
            [126.76281, 35.06398],
            [126.7622, 35.06376],
            [126.76151, 35.06353],
            [126.76052, 35.06299],
            [126.75963, 35.06245],
            [126.75915, 35.06181],
            [126.75877, 35.06054],
            [126.7585, 35.05999],
            [126.75814, 35.05946],
            [126.75773, 35.059],
            [126.75674, 35.05825],
            [126.75585, 35.05909],
            [126.75582, 35.05937],
            [126.75581, 35.05972],
            [126.75586, 35.06116],
            [126.75535, 35.0626],
            [126.75433, 35.06376],
            [126.75397, 35.06403],
            [126.75333, 35.06445],
            [126.75318, 35.06476],
            [126.75314, 35.06524],
            [126.75359, 35.06625],
            [126.75414, 35.06685],
            [126.75536, 35.06745],
            [126.75632, 35.06802],
            [126.75699, 35.06837],
            [126.75827, 35.06863],
            [126.75896, 35.06865],
            [126.76004, 35.06871],
            [126.76065, 35.06871],
            [126.76191, 35.06858],
            [126.76391, 35.06825],
            [126.76515, 35.06819],
            [126.767, 35.06818],
            [126.76775, 35.06836],
            [126.76858, 35.06862],
            [126.76935, 35.06894],
            [126.77028, 35.06953],
            [126.77047, 35.06969],
            [126.77132, 35.07017],
            [126.77232, 35.0706],
            [126.77281, 35.07076],
            [126.77329, 35.07091],
            [126.77443, 35.07114],
            [126.77533, 35.07126],
            [126.77704, 35.07132],
            [126.7774, 35.07135],
            [126.77875, 35.07141],
            [126.78027, 35.0718],
            [126.78108, 35.07204],
            [126.78143, 35.07212],
            [126.78198, 35.07232],
            [126.78256, 35.07257],
            [126.78456, 35.07372],
            [126.78526, 35.07422],
            [126.78611, 35.07492],
            [126.78653, 35.07556],
            [126.787, 35.07633],
            [126.78771, 35.07704],
            [126.78805, 35.07781],
            [126.78809, 35.078],
            [126.78905, 35.07886],
            [126.78906, 35.07895],
            [126.78972, 35.08015],
            [126.79026, 35.07995],
            [126.79058, 35.08055],
            [126.7907, 35.0805],
            [126.79135, 35.08202],
            [126.7914, 35.08209],
            [126.79196, 35.08261],
            [126.79227, 35.08287],
            [126.79307, 35.08362],
            [126.7938, 35.08425],
            [126.79391, 35.08429],
            [126.7942, 35.08434],
            [126.79402, 35.0844],
            [126.79598, 35.08799],
            [126.79736, 35.08905],
            [126.79792, 35.0891],
            [126.79823, 35.08908],
            [126.79879, 35.08942],
            [126.79916, 35.08958],
            [126.79944, 35.08972],
            [126.79967, 35.08981],
            [126.79984, 35.08991],
            [126.80027, 35.09008],
            [126.8006, 35.09046],
            [126.80127, 35.09082],
            [126.80222, 35.09162],
            [126.80254, 35.09218],
            [126.80268, 35.09274],
            [126.80256, 35.09328],
            [126.80253, 35.09349],
            [126.80317, 35.09355],
            [126.80339, 35.09357],
            [126.80349, 35.09387],
            [126.80359, 35.09391],
            [126.80364, 35.09381],
            [126.8037, 35.09381],
            [126.80395, 35.09402],
            [126.80406, 35.09417],
            [126.80423, 35.09428],
            [126.80441, 35.09434],
            [126.80445, 35.09439],
            [126.80445, 35.09461],
            [126.80458, 35.0947],
            [126.805, 35.09489],
            [126.80515, 35.09504],
            [126.80515, 35.09512],
            [126.80533, 35.0954],
            [126.80533, 35.09562],
            [126.80526, 35.09573],
            [126.80517, 35.09579],
            [126.80522, 35.09601],
            [126.80539, 35.09637],
            [126.8055, 35.09648],
            [126.80553, 35.09657],
            [126.80561, 35.09663],
            [126.8057, 35.09667],
            [126.80572, 35.09672],
            [126.80577, 35.09678],
            [126.8059, 35.09681],
            [126.80623, 35.09723],
            [126.80631, 35.09726],
            [126.80637, 35.0973],
            [126.80641, 35.09746],
            [126.80649, 35.09757],
            [126.80692, 35.09668],
            [126.8071, 35.09655],
            [126.80739, 35.09548],
            [126.80775, 35.09487],
            [126.80798, 35.09457],
            [126.80799, 35.09421],
            [126.80837, 35.09393],
            [126.8084, 35.09362],
            [126.80891, 35.09326],
            [126.80902, 35.0931],
            [126.8091, 35.09309],
            [126.8093, 35.09285],
            [126.80941, 35.09275],
            [126.80956, 35.0924],
            [126.80959, 35.09226],
            [126.80971, 35.09213],
            [126.81013, 35.09188],
            [126.81058, 35.09155],
            [126.81111, 35.09094],
            [126.81134, 35.09099],
            [126.81147, 35.09112],
            [126.81155, 35.09116],
            [126.81162, 35.09111],
            [126.81177, 35.09124],
            [126.81188, 35.09138],
            [126.81236, 35.09172],
            [126.81323, 35.0922],
            [126.81368, 35.09261],
            [126.81396, 35.09283],
            [126.81431, 35.0932],
            [126.81436, 35.09327],
            [126.81446, 35.09324],
            [126.81502, 35.0935],
            [126.81521, 35.09349],
            [126.81539, 35.09357],
            [126.81628, 35.09371],
            [126.81655, 35.09343],
            [126.81677, 35.09317],
            [126.81743, 35.09266],
            [126.81819, 35.09201],
            [126.82008, 35.09093],
            [126.82077, 35.09141],
            [126.82128, 35.09167],
            [126.82212, 35.09186],
            [126.82225, 35.09197],
            [126.82273, 35.09209],
            [126.82321, 35.09241],
            [126.82347, 35.09268],
            [126.82376, 35.09289],
            [126.82409, 35.09303],
            [126.82447, 35.09324],
            [126.82529, 35.09305],
            [126.82576, 35.09315],
            [126.82623, 35.09344],
            [126.82655, 35.09367],
            [126.82705, 35.09426],
            [126.82742, 35.09441],
            [126.82761, 35.09444],
            [126.82811, 35.09442],
            [126.82835, 35.09444],
            [126.8283, 35.09474],
            [126.82825, 35.09484],
            [126.8283, 35.09489],
            [126.82865, 35.09512],
            [126.82933, 35.09555],
            [126.82969, 35.09596],
            [126.83017, 35.09641],
            [126.83052, 35.09677],
            [126.83087, 35.09748],
            [126.83152, 35.09803],
            [126.83192, 35.09792],
            [126.83261, 35.09794],
            [126.83294, 35.09792],
            [126.83363, 35.09781],
            [126.83407, 35.09782],
            [126.83514, 35.09793],
            [126.83574, 35.09817],
            [126.83664, 35.09864],
            [126.83688, 35.09916],
            [126.83683, 35.09974],
            [126.83695, 35.10015],
            [126.83706, 35.10029],
            [126.83724, 35.10038],
            [126.83741, 35.10128],
            [126.83763, 35.10174],
            [126.83811, 35.10228],
            [126.8383, 35.10201],
            [126.83946, 35.10132],
            [126.84041, 35.1012],
            [126.84105, 35.101],
            [126.84225, 35.10179],
            [126.84247, 35.10201],
            [126.84252, 35.10262],
            [126.84316, 35.10294],
            [126.84382, 35.10351],
            [126.84402, 35.10401],
            [126.84559, 35.1044],
            [126.84594, 35.10439],
            [126.84643, 35.10448],
            [126.84666, 35.10505],
            [126.84678, 35.10523],
            [126.84742, 35.10559],
            [126.84778, 35.10576],
            [126.84855, 35.1058],
            [126.84898, 35.10555],
            [126.84921, 35.10558],
            [126.84977, 35.10555],
            [126.85068, 35.10592],
            [126.85109, 35.10592],
            [126.8515, 35.10597],
            [126.85223, 35.10614],
            [126.85305, 35.10641],
            [126.85366, 35.10636],
            [126.85433, 35.10659],
            [126.85447, 35.10662],
            [126.85476, 35.10701],
            [126.85479, 35.10704],
            [126.85527, 35.10719],
            [126.85586, 35.10711],
            [126.85621, 35.10678],
            [126.85664, 35.10625],
            [126.85687, 35.10606],
            [126.85719, 35.10584],
            [126.85758, 35.10621],
            [126.85789, 35.10622],
            [126.85876, 35.10617],
            [126.85897, 35.10624],
            [126.85928, 35.10624],
            [126.85989, 35.10631],
            [126.86063, 35.10636],
            [126.86073, 35.10656],
            [126.86098, 35.10676],
            [126.8613, 35.10727],
            [126.86123, 35.10759],
            [126.86085, 35.10805],
            [126.85999, 35.10819],
            [126.85967, 35.10827],
            [126.85951, 35.10835],
            [126.85982, 35.1087],
            [126.85993, 35.10872],
            [126.86003, 35.10885],
            [126.86021, 35.10898],
            [126.86036, 35.10902],
            [126.86047, 35.10908],
            [126.8606, 35.1091],
            [126.86083, 35.10915],
            [126.86109, 35.10913],
            [126.86117, 35.10905],
            [126.86142, 35.10894],
            [126.86153, 35.10901],
            [126.86172, 35.10908],
            [126.86207, 35.10917],
            [126.86325, 35.10952],
            [126.86369, 35.10915],
            [126.86387, 35.10917],
            [126.86402, 35.10908],
            [126.86417, 35.1091],
            [126.86425, 35.10914],
            [126.86442, 35.1092],
            [126.86493, 35.10936],
            [126.86552, 35.10932],
            [126.86575, 35.10986],
            [126.86596, 35.11043],
            [126.866, 35.1106],
            [126.86641, 35.11059],
            [126.86751, 35.11045],
            [126.86774, 35.11043],
            [126.86803, 35.11017],
            [126.8683, 35.11019],
            [126.8686, 35.11006],
            [126.8697, 35.11027],
            [126.87022, 35.11023],
            [126.87056, 35.11021],
            [126.87064, 35.11022],
            [126.8702, 35.11078],
            [126.87011, 35.11093],
            [126.8703, 35.11169],
            [126.87076, 35.11234],
            [126.87104, 35.11308],
            [126.87103, 35.11358],
            [126.87088, 35.11383],
            [126.8708, 35.11401],
            [126.87045, 35.11431],
            [126.87013, 35.11465],
            [126.86995, 35.11498],
            [126.86963, 35.11533],
            [126.8696, 35.11543],
            [126.86958, 35.11559],
            [126.86949, 35.11579],
            [126.86958, 35.11585],
            [126.86974, 35.116],
            [126.87007, 35.11598],
            [126.87038, 35.11613],
            [126.87053, 35.11618],
            [126.87064, 35.11627],
            [126.87103, 35.11641],
            [126.87178, 35.11724],
            [126.87179, 35.11766],
            [126.8722, 35.11779],
            [126.87266, 35.11803],
            [126.87272, 35.11838],
            [126.87302, 35.11858],
            [126.87323, 35.11886],
            [126.87341, 35.11902],
            [126.87359, 35.11942],
            [126.87345, 35.11959],
            [126.87336, 35.11973],
            [126.8733, 35.12003],
            [126.87436, 35.12003],
            [126.8747, 35.11968],
            [126.87502, 35.11921],
            [126.8754, 35.11908],
            [126.87618, 35.11894],
            [126.87688, 35.11861],
            [126.877, 35.11845],
            [126.87767, 35.1181],
            [126.87783, 35.11792],
            [126.87815, 35.11777],
            [126.87891, 35.11755],
            [126.87919, 35.11762],
            [126.87949, 35.11744],
            [126.88043, 35.11703],
            [126.88124, 35.11675],
            [126.88185, 35.11646],
            [126.88227, 35.1161],
            [126.88291, 35.11601],
            [126.88336, 35.11599],
            [126.88344, 35.11619],
            [126.88455, 35.11683],
            [126.88486, 35.11679],
            [126.88497, 35.11681],
            [126.88533, 35.11705],
            [126.88571, 35.11718],
            [126.88579, 35.11717],
            [126.88653, 35.11711],
            [126.88737, 35.11696],
            [126.88769, 35.11694],
            [126.88833, 35.11784],
            [126.88785, 35.11855],
            [126.88766, 35.11903],
            [126.8878, 35.11929],
            [126.88818, 35.11976],
            [126.88831, 35.12006],
            [126.88836, 35.12046],
            [126.88829, 35.12073],
            [126.88845, 35.12123],
            [126.8885, 35.12154],
            [126.88826, 35.12234],
            [126.88834, 35.12258],
            [126.88835, 35.1231],
            [126.88833, 35.12357],
            [126.88824, 35.12383],
            [126.88829, 35.12431],
            [126.88838, 35.12459],
            [126.88838, 35.12481],
            [126.88833, 35.12503],
            [126.8882, 35.12522],
            [126.88813, 35.12526],
            [126.88802, 35.12534],
            [126.8878, 35.12542],
            [126.88734, 35.1258],
            [126.8873, 35.12598],
            [126.88727, 35.12624],
            [126.88707, 35.12669],
            [126.88697, 35.12684],
            [126.88693, 35.12694],
            [126.88695, 35.1275],
            [126.88688, 35.12851],
            [126.88677, 35.12876],
            [126.88677, 35.12905],
            [126.88661, 35.1292],
            [126.88648, 35.12996],
            [126.88638, 35.13007],
            [126.88659, 35.13029],
            [126.88661, 35.13035],
            [126.88684, 35.13065],
            [126.88681, 35.13085],
            [126.88688, 35.1313],
            [126.88729, 35.13167],
            [126.88765, 35.13197],
            [126.88755, 35.13217],
            [126.88758, 35.13277],
            [126.88744, 35.13317],
            [126.88745, 35.13351],
            [126.88756, 35.134],
            [126.88733, 35.13447],
            [126.8867, 35.13442],
            [126.88642, 35.13468],
            [126.88627, 35.13486],
            [126.88613, 35.13515],
            [126.886, 35.13555],
            [126.8858, 35.13631],
            [126.88571, 35.13656],
            [126.88521, 35.13683],
            [126.88424, 35.13751],
            [126.8841, 35.13755],
            [126.88366, 35.13756],
            [126.8832, 35.13835],
            [126.88327, 35.13883],
            [126.88344, 35.13895],
            [126.88384, 35.13902],
            [126.88419, 35.13912],
            [126.88472, 35.13948],
            [126.88483, 35.13952],
            [126.88487, 35.13957],
            [126.88492, 35.13971],
            [126.88508, 35.13978],
            [126.88529, 35.13975],
            [126.88521, 35.14012],
            [126.88541, 35.14023],
            [126.88531, 35.14038],
            [126.88517, 35.14043],
            [126.88529, 35.14056],
            [126.88538, 35.1406],
            [126.88531, 35.1407],
            [126.88536, 35.14075],
            [126.88545, 35.1407],
            [126.88547, 35.14077],
            [126.88543, 35.14082],
            [126.88544, 35.14097],
            [126.88558, 35.14103],
            [126.8856, 35.1411],
            [126.88559, 35.14118],
            [126.88567, 35.14138],
            [126.88578, 35.14124],
            [126.88592, 35.1412],
            [126.88781, 35.14207],
            [126.88755, 35.1424],
            [126.88831, 35.14279],
            [126.88541, 35.14657],
            [126.8902, 35.14903],
            [126.89224, 35.1494],
            [126.89331, 35.15007],
            [126.89338, 35.15049],
            [126.89457, 35.15087],
            [126.8943, 35.15146],
            [126.89593, 35.15198],
            [126.89586, 35.15211],
            [126.89596, 35.15214],
            [126.89601, 35.1521],
            [126.89621, 35.15213],
            [126.89655, 35.15224],
            [126.89655, 35.15219],
            [126.89724, 35.15237],
            [126.89741, 35.15241],
            [126.89845, 35.15231],
            [126.89925, 35.15236],
            [126.90049, 35.15249],
            [126.9007, 35.15248],
            [126.90084, 35.15245],
            [126.90098, 35.1524],
            [126.90108, 35.15234]
          ]
        ]
      }
    },
    {
      id: '29170',
      type: 'Feature',
      properties: {
        SEC_SGG_CD: '29170',
        SEC_SGG_NM: ['광주광역시', '북구'],
        COL_SGG_CD: '29000',
        SEC_SGG_SIZE: '2',
        bbox: [126.83486, 35.12112, 127.02224, 35.25846],
        center: [126.92544, 35.19325],
        area: 119421434
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.90949, 35.25846],
            [126.91112, 35.25846],
            [126.91227, 35.2583],
            [126.9129, 35.2583],
            [126.91397, 35.25824],
            [126.91467, 35.25817],
            [126.91498, 35.25732],
            [126.91559, 35.25704],
            [126.91585, 35.25698],
            [126.91609, 35.25689],
            [126.91642, 35.25688],
            [126.91649, 35.25699],
            [126.91657, 35.25699],
            [126.91699, 35.25678],
            [126.91809, 35.25617],
            [126.91844, 35.25578],
            [126.91865, 35.25559],
            [126.91886, 35.25547],
            [126.91903, 35.25544],
            [126.91924, 35.25531],
            [126.91911, 35.25515],
            [126.91902, 35.25508],
            [126.91899, 35.25472],
            [126.91927, 35.2546],
            [126.92049, 35.254],
            [126.92107, 35.25338],
            [126.9213, 35.25326],
            [126.9223, 35.253],
            [126.92288, 35.25296],
            [126.92338, 35.2529],
            [126.92396, 35.25261],
            [126.92402, 35.25263],
            [126.92408, 35.2526],
            [126.92419, 35.25268],
            [126.92496, 35.25298],
            [126.92519, 35.25297],
            [126.92595, 35.25286],
            [126.92625, 35.25266],
            [126.92654, 35.25257],
            [126.92668, 35.25248],
            [126.92676, 35.25236],
            [126.92696, 35.25224],
            [126.927, 35.25217],
            [126.92699, 35.25209],
            [126.92709, 35.25201],
            [126.92719, 35.25204],
            [126.92739, 35.25194],
            [126.92748, 35.25193],
            [126.92771, 35.25191],
            [126.92793, 35.25193],
            [126.92845, 35.25218],
            [126.92914, 35.25232],
            [126.92939, 35.25246],
            [126.92947, 35.25242],
            [126.93073, 35.25118],
            [126.93129, 35.25087],
            [126.93167, 35.25034],
            [126.93273, 35.24903],
            [126.9328, 35.24893],
            [126.93302, 35.24842],
            [126.93353, 35.24756],
            [126.9338, 35.2467],
            [126.93348, 35.24601],
            [126.93355, 35.24454],
            [126.93356, 35.24449],
            [126.93321, 35.24369],
            [126.93314, 35.24315],
            [126.93294, 35.24301],
            [126.93263, 35.24156],
            [126.93229, 35.24064],
            [126.9326, 35.24038],
            [126.93326, 35.24001],
            [126.93347, 35.23992],
            [126.93402, 35.23995],
            [126.93428, 35.23995],
            [126.93495, 35.23904],
            [126.93633, 35.23885],
            [126.93694, 35.23816],
            [126.93779, 35.23805],
            [126.93788, 35.23805],
            [126.93828, 35.23789],
            [126.93966, 35.23714],
            [126.94039, 35.23664],
            [126.94143, 35.23599],
            [126.94227, 35.23616],
            [126.94281, 35.23548],
            [126.94277, 35.23498],
            [126.94286, 35.23475],
            [126.94307, 35.23388],
            [126.94342, 35.23313],
            [126.94346, 35.23288],
            [126.94356, 35.23173],
            [126.94486, 35.23092],
            [126.9455, 35.23093],
            [126.94632, 35.2309],
            [126.9476, 35.23074],
            [126.94815, 35.22994],
            [126.94819, 35.22975],
            [126.9485, 35.22947],
            [126.94866, 35.22927],
            [126.94879, 35.22916],
            [126.9489, 35.2291],
            [126.94894, 35.22896],
            [126.94912, 35.22882],
            [126.9491, 35.22876],
            [126.94945, 35.22819],
            [126.94951, 35.22804],
            [126.94963, 35.22743],
            [126.95002, 35.22705],
            [126.95021, 35.22672],
            [126.95078, 35.2263],
            [126.95114, 35.22572],
            [126.95163, 35.22486],
            [126.95191, 35.22447],
            [126.95221, 35.22454],
            [126.95323, 35.22487],
            [126.95269, 35.22436],
            [126.95194, 35.22404],
            [126.95135, 35.22395],
            [126.95042, 35.22298],
            [126.94978, 35.22226],
            [126.94969, 35.22212],
            [126.94893, 35.22088],
            [126.94885, 35.22003],
            [126.9489, 35.21964],
            [126.9489, 35.21908],
            [126.94955, 35.21824],
            [126.94974, 35.21777],
            [126.94985, 35.21717],
            [126.95027, 35.21643],
            [126.95049, 35.21589],
            [126.95058, 35.21537],
            [126.95055, 35.21497],
            [126.95083, 35.21486],
            [126.95089, 35.21503],
            [126.95133, 35.21495],
            [126.95169, 35.21479],
            [126.95188, 35.21452],
            [126.95197, 35.21447],
            [126.95212, 35.21436],
            [126.95208, 35.21361],
            [126.95229, 35.21311],
            [126.95243, 35.21303],
            [126.95294, 35.21236],
            [126.95357, 35.2123],
            [126.95404, 35.21199],
            [126.95417, 35.21168],
            [126.95413, 35.21157],
            [126.95412, 35.21058],
            [126.95459, 35.21035],
            [126.95506, 35.20996],
            [126.95522, 35.20931],
            [126.95564, 35.20922],
            [126.95614, 35.2088],
            [126.95622, 35.20854],
            [126.95653, 35.20813],
            [126.95652, 35.20756],
            [126.95653, 35.20737],
            [126.95643, 35.20717],
            [126.95763, 35.20689],
            [126.95828, 35.2067],
            [126.95872, 35.20651],
            [126.95907, 35.20623],
            [126.95917, 35.20611],
            [126.95929, 35.20601],
            [126.95948, 35.20574],
            [126.95978, 35.20562],
            [126.96021, 35.20508],
            [126.96038, 35.20493],
            [126.96043, 35.2049],
            [126.96054, 35.20488],
            [126.96088, 35.20465],
            [126.96113, 35.20441],
            [126.96241, 35.20385],
            [126.96265, 35.2036],
            [126.96315, 35.20343],
            [126.96443, 35.20341],
            [126.96486, 35.20367],
            [126.96519, 35.20369],
            [126.96547, 35.20338],
            [126.96552, 35.2033],
            [126.96596, 35.2022],
            [126.9661, 35.20201],
            [126.96627, 35.20107],
            [126.9662, 35.2009],
            [126.9661, 35.2008],
            [126.96575, 35.20056],
            [126.96562, 35.20064],
            [126.96543, 35.20061],
            [126.96503, 35.2003],
            [126.9645, 35.20029],
            [126.96439, 35.20005],
            [126.96409, 35.1996],
            [126.96386, 35.19932],
            [126.9639, 35.19917],
            [126.96414, 35.19875],
            [126.96392, 35.19864],
            [126.96343, 35.19814],
            [126.96264, 35.1981],
            [126.96247, 35.19794],
            [126.96231, 35.19752],
            [126.96175, 35.1966],
            [126.96151, 35.19646],
            [126.9617, 35.19583],
            [126.96121, 35.1953],
            [126.96106, 35.19506],
            [126.9606, 35.19492],
            [126.96045, 35.19495],
            [126.95994, 35.19477],
            [126.95954, 35.19468],
            [126.95924, 35.19447],
            [126.9593, 35.19405],
            [126.95919, 35.19376],
            [126.95905, 35.19354],
            [126.95865, 35.19319],
            [126.95862, 35.19309],
            [126.9587, 35.19286],
            [126.95891, 35.19264],
            [126.95927, 35.19246],
            [126.95935, 35.19235],
            [126.95931, 35.19203],
            [126.95934, 35.19161],
            [126.95921, 35.19128],
            [126.95933, 35.19096],
            [126.95953, 35.19074],
            [126.95955, 35.19047],
            [126.95992, 35.19002],
            [126.96096, 35.18916],
            [126.96112, 35.18876],
            [126.96154, 35.18865],
            [126.96195, 35.18861],
            [126.96201, 35.18856],
            [126.96219, 35.18837],
            [126.96246, 35.18819],
            [126.96255, 35.18786],
            [126.96328, 35.18751],
            [126.96362, 35.18729],
            [126.96389, 35.18694],
            [126.96397, 35.18687],
            [126.96414, 35.18666],
            [126.96426, 35.1863],
            [126.96425, 35.18609],
            [126.96434, 35.18575],
            [126.96484, 35.18498],
            [126.9652, 35.18479],
            [126.96571, 35.18446],
            [126.96607, 35.18431],
            [126.96636, 35.18424],
            [126.967, 35.18346],
            [126.9671, 35.1825],
            [126.96757, 35.18183],
            [126.96787, 35.18141],
            [126.96815, 35.18108],
            [126.96889, 35.18062],
            [126.9696, 35.18045],
            [126.96992, 35.18031],
            [126.97066, 35.17994],
            [126.97115, 35.17989],
            [126.97172, 35.17978],
            [126.97185, 35.18058],
            [126.97181, 35.18114],
            [126.97195, 35.18217],
            [126.97238, 35.18354],
            [126.97241, 35.18406],
            [126.97234, 35.18438],
            [126.97222, 35.18455],
            [126.97213, 35.185],
            [126.9723, 35.18523],
            [126.97293, 35.18567],
            [126.97352, 35.18589],
            [126.97393, 35.18612],
            [126.97541, 35.18567],
            [126.97592, 35.18549],
            [126.97819, 35.18618],
            [126.97842, 35.18547],
            [126.97923, 35.18509],
            [126.97959, 35.18449],
            [126.98, 35.18428],
            [126.9813, 35.18437],
            [126.98156, 35.18429],
            [126.98197, 35.18393],
            [126.98277, 35.18401],
            [126.98324, 35.18436],
            [126.98375, 35.18506],
            [126.98398, 35.18536],
            [126.98442, 35.18573],
            [126.98478, 35.18609],
            [126.98434, 35.18661],
            [126.98387, 35.18704],
            [126.98434, 35.18742],
            [126.98539, 35.18833],
            [126.98548, 35.18836],
            [126.98563, 35.18846],
            [126.98576, 35.18856],
            [126.98585, 35.18864],
            [126.98611, 35.18858],
            [126.98639, 35.18856],
            [126.98657, 35.18861],
            [126.98665, 35.1886],
            [126.98677, 35.18864],
            [126.98719, 35.18887],
            [126.98782, 35.18844],
            [126.98793, 35.18832],
            [126.98809, 35.18805],
            [126.98834, 35.18776],
            [126.9886, 35.18759],
            [126.98879, 35.18739],
            [126.98895, 35.18711],
            [126.9895, 35.18687],
            [126.98983, 35.1867],
            [126.99013, 35.18662],
            [126.9906, 35.18647],
            [126.99111, 35.18639],
            [126.99189, 35.18644],
            [126.99218, 35.18641],
            [126.9926, 35.18641],
            [126.99315, 35.18646],
            [126.99375, 35.18674],
            [126.99421, 35.18697],
            [126.99475, 35.18759],
            [126.99488, 35.18781],
            [126.99509, 35.18801],
            [126.99532, 35.18811],
            [126.99582, 35.18856],
            [126.99632, 35.1889],
            [126.99666, 35.189],
            [126.99705, 35.18903],
            [126.99756, 35.189],
            [126.99815, 35.189],
            [126.9988, 35.18886],
            [126.99943, 35.18886],
            [126.99995, 35.18882],
            [127.00048, 35.1886],
            [127.00076, 35.1884],
            [127.00081, 35.18838],
            [127.0009, 35.1883],
            [127.00146, 35.18798],
            [127.0022, 35.18759],
            [127.00274, 35.1871],
            [127.00366, 35.18629],
            [127.00405, 35.18617],
            [127.00431, 35.18617],
            [127.00546, 35.18591],
            [127.00605, 35.18564],
            [127.0063, 35.18547],
            [127.00647, 35.1854],
            [127.00701, 35.18523],
            [127.00778, 35.18459],
            [127.00807, 35.18417],
            [127.00826, 35.18399],
            [127.00832, 35.18375],
            [127.00852, 35.18347],
            [127.00874, 35.1833],
            [127.0091, 35.18311],
            [127.00957, 35.18284],
            [127.00998, 35.18264],
            [127.01015, 35.18228],
            [127.01032, 35.18203],
            [127.01055, 35.18181],
            [127.01088, 35.18153],
            [127.01138, 35.18125],
            [127.01179, 35.18098],
            [127.01241, 35.18071],
            [127.01301, 35.18053],
            [127.01339, 35.18038],
            [127.0137, 35.18012],
            [127.01401, 35.1797],
            [127.01413, 35.17928],
            [127.01421, 35.17905],
            [127.01431, 35.17886],
            [127.0143, 35.17873],
            [127.01421, 35.17866],
            [127.01422, 35.17854],
            [127.01428, 35.1784],
            [127.01424, 35.17833],
            [127.01415, 35.17829],
            [127.01413, 35.17813],
            [127.01418, 35.1781],
            [127.01427, 35.1781],
            [127.01439, 35.17802],
            [127.01451, 35.17802],
            [127.01463, 35.17778],
            [127.01473, 35.17771],
            [127.0148, 35.17752],
            [127.01481, 35.17728],
            [127.01484, 35.17719],
            [127.01476, 35.17703],
            [127.01481, 35.1769],
            [127.0149, 35.17679],
            [127.01495, 35.17676],
            [127.01516, 35.17673],
            [127.01534, 35.17666],
            [127.01544, 35.17669],
            [127.01564, 35.17649],
            [127.01566, 35.17626],
            [127.0157, 35.17611],
            [127.01576, 35.17604],
            [127.01578, 35.17581],
            [127.01574, 35.1757],
            [127.01579, 35.17564],
            [127.01592, 35.17555],
            [127.01604, 35.1754],
            [127.01613, 35.17532],
            [127.01627, 35.1753],
            [127.01636, 35.17521],
            [127.01658, 35.17511],
            [127.01671, 35.17497],
            [127.01671, 35.17487],
            [127.01684, 35.17474],
            [127.01692, 35.17469],
            [127.01713, 35.1745],
            [127.01719, 35.17447],
            [127.01726, 35.17447],
            [127.01725, 35.17441],
            [127.01735, 35.17441],
            [127.0174, 35.17437],
            [127.01753, 35.17434],
            [127.01761, 35.17426],
            [127.01766, 35.17421],
            [127.01774, 35.17421],
            [127.01853, 35.17369],
            [127.02141, 35.17304],
            [127.02155, 35.172],
            [127.02224, 35.17133],
            [127.02196, 35.17046],
            [127.02086, 35.16725],
            [127.02032, 35.16738],
            [127.0182, 35.16627],
            [127.01728, 35.16568],
            [127.01677, 35.16533],
            [127.01475, 35.16562],
            [127.01382, 35.16607],
            [127.01249, 35.16626],
            [127.0113, 35.16617],
            [127.01104, 35.16588],
            [127.01091, 35.1649],
            [127.01094, 35.16348],
            [127.01143, 35.16269],
            [127.01147, 35.16232],
            [127.01145, 35.16221],
            [127.01113, 35.16141],
            [127.0107, 35.1596],
            [127.01071, 35.15905],
            [127.00972, 35.15791],
            [127.00928, 35.15735],
            [127.00729, 35.156],
            [127.0076, 35.15542],
            [127.00783, 35.1539],
            [127.00898, 35.15094],
            [127.00968, 35.14963],
            [127.00919, 35.14865],
            [127.00795, 35.14803],
            [127.00764, 35.14662],
            [127.00752, 35.14593],
            [127.00765, 35.14441],
            [127.00719, 35.14385],
            [127.00822, 35.14257],
            [127.00853, 35.14242],
            [127.0091, 35.14222],
            [127.0093, 35.14177],
            [127.00906, 35.14096],
            [127.00885, 35.13891],
            [127.00873, 35.13667],
            [127.00872, 35.1364],
            [127.00882, 35.13464],
            [127.00879, 35.13396],
            [127.00871, 35.13375],
            [127.00868, 35.13351],
            [127.00886, 35.13259],
            [127.00912, 35.13185],
            [127.0096, 35.13081],
            [127.01018, 35.13],
            [127.01101, 35.12912],
            [127.01139, 35.12841],
            [127.01181, 35.12782],
            [127.01153, 35.12623],
            [127.01128, 35.12556],
            [127.01072, 35.12483],
            [127.01059, 35.12432],
            [127.0095, 35.12409],
            [127.00786, 35.12398],
            [127.00679, 35.12309],
            [127.00633, 35.1226],
            [127.00518, 35.12223],
            [127.00454, 35.12184],
            [127.00423, 35.12112],
            [127.00274, 35.12136],
            [127.00162, 35.12172],
            [126.99715, 35.12295],
            [126.99605, 35.12306],
            [126.9952, 35.12329],
            [126.99438, 35.12311],
            [126.99366, 35.12278],
            [126.99314, 35.12259],
            [126.99267, 35.12265],
            [126.99254, 35.12264],
            [126.99236, 35.123],
            [126.99214, 35.12336],
            [126.99178, 35.12418],
            [126.99108, 35.12481],
            [126.99094, 35.12526],
            [126.99067, 35.12551],
            [126.98957, 35.1269],
            [126.98926, 35.12715],
            [126.98881, 35.1274],
            [126.98776, 35.12825],
            [126.98775, 35.12909],
            [126.98739, 35.12996],
            [126.98699, 35.13052],
            [126.98689, 35.13126],
            [126.98622, 35.13175],
            [126.98616, 35.1321],
            [126.98603, 35.13241],
            [126.98587, 35.13288],
            [126.98561, 35.13318],
            [126.98547, 35.13383],
            [126.98515, 35.13414],
            [126.98519, 35.13435],
            [126.98469, 35.1348],
            [126.98415, 35.13517],
            [126.98403, 35.1361],
            [126.98354, 35.13696],
            [126.98245, 35.13753],
            [126.98241, 35.13759],
            [126.98154, 35.13779],
            [126.98064, 35.13779],
            [126.97814, 35.13844],
            [126.97716, 35.13825],
            [126.97558, 35.13786],
            [126.97501, 35.13786],
            [126.97439, 35.13799],
            [126.97375, 35.13798],
            [126.97325, 35.13841],
            [126.97273, 35.13842],
            [126.97194, 35.1379],
            [126.97158, 35.13755],
            [126.97101, 35.13658],
            [126.97077, 35.13658],
            [126.96937, 35.13687],
            [126.9689, 35.13672],
            [126.96843, 35.13674],
            [126.96802, 35.13683],
            [126.96776, 35.13693],
            [126.96737, 35.1374],
            [126.96657, 35.13774],
            [126.9655, 35.13872],
            [126.96495, 35.13899],
            [126.96437, 35.1391],
            [126.96368, 35.13942],
            [126.96309, 35.13987],
            [126.96186, 35.14034],
            [126.96096, 35.14045],
            [126.95995, 35.14028],
            [126.95953, 35.14054],
            [126.95869, 35.14068],
            [126.95821, 35.14042],
            [126.958, 35.14012],
            [126.95753, 35.14019],
            [126.95718, 35.14032],
            [126.95654, 35.14091],
            [126.95531, 35.14056],
            [126.95486, 35.14089],
            [126.95473, 35.14157],
            [126.95445, 35.14217],
            [126.95396, 35.14199],
            [126.95379, 35.14197],
            [126.95265, 35.14279],
            [126.9523, 35.14289],
            [126.95166, 35.14332],
            [126.95154, 35.14373],
            [126.95139, 35.14453],
            [126.95183, 35.14443],
            [126.95217, 35.14473],
            [126.95317, 35.14492],
            [126.95348, 35.14512],
            [126.95369, 35.14549],
            [126.95382, 35.14576],
            [126.95384, 35.14618],
            [126.95398, 35.1465],
            [126.95373, 35.14735],
            [126.95368, 35.14744],
            [126.95364, 35.14759],
            [126.95366, 35.14771],
            [126.9542, 35.14838],
            [126.95492, 35.14886],
            [126.9549, 35.14914],
            [126.95529, 35.15006],
            [126.95536, 35.15053],
            [126.95564, 35.15091],
            [126.95563, 35.15139],
            [126.95539, 35.15197],
            [126.95522, 35.15219],
            [126.95408, 35.15265],
            [126.95336, 35.15302],
            [126.95248, 35.15334],
            [126.95138, 35.15413],
            [126.94948, 35.15433],
            [126.94928, 35.15437],
            [126.94842, 35.15417],
            [126.9477, 35.15445],
            [126.94706, 35.155],
            [126.94614, 35.15532],
            [126.94576, 35.15556],
            [126.94567, 35.15607],
            [126.94573, 35.15654],
            [126.94564, 35.15702],
            [126.94529, 35.15747],
            [126.94529, 35.15763],
            [126.94512, 35.1581],
            [126.94473, 35.15836],
            [126.94436, 35.15874],
            [126.94356, 35.15879],
            [126.94308, 35.15869],
            [126.94272, 35.15867],
            [126.94242, 35.15877],
            [126.94198, 35.15883],
            [126.9414, 35.15856],
            [126.94103, 35.15864],
            [126.94047, 35.15857],
            [126.94018, 35.15858],
            [126.93993, 35.15863],
            [126.93986, 35.15867],
            [126.93943, 35.159],
            [126.93913, 35.15911],
            [126.93885, 35.15905],
            [126.93837, 35.15939],
            [126.93842, 35.15978],
            [126.93848, 35.16012],
            [126.93855, 35.16026],
            [126.93887, 35.16034],
            [126.93886, 35.16179],
            [126.93552, 35.16178],
            [126.93073, 35.16021],
            [126.93059, 35.16053],
            [126.93038, 35.16087],
            [126.93018, 35.16114],
            [126.92997, 35.16137],
            [126.92975, 35.16159],
            [126.92922, 35.16199],
            [126.92894, 35.16215],
            [126.92866, 35.16229],
            [126.92821, 35.16248],
            [126.92272, 35.16402],
            [126.9196, 35.16476],
            [126.91632, 35.16549],
            [126.91248, 35.15579],
            [126.91254, 35.15572],
            [126.91233, 35.15559],
            [126.91155, 35.15498],
            [126.9113, 35.15472],
            [126.90926, 35.1531],
            [126.9086, 35.15255],
            [126.90843, 35.15247],
            [126.90801, 35.15212],
            [126.90775, 35.15194],
            [126.90762, 35.15184],
            [126.90743, 35.15166],
            [126.90722, 35.15141],
            [126.90269, 35.15471],
            [126.90286, 35.15488],
            [126.90203, 35.15538],
            [126.90167, 35.1557],
            [126.90076, 35.15659],
            [126.90027, 35.15705],
            [126.89943, 35.15766],
            [126.89877, 35.1581],
            [126.89821, 35.15841],
            [126.89779, 35.15855],
            [126.89726, 35.15871],
            [126.89668, 35.15894],
            [126.89577, 35.15924],
            [126.89561, 35.15933],
            [126.89467, 35.15978],
            [126.89361, 35.16037],
            [126.89276, 35.16072],
            [126.89186, 35.16082],
            [126.89104, 35.16074],
            [126.88998, 35.16078],
            [126.8892, 35.16091],
            [126.88845, 35.16143],
            [126.88806, 35.16143],
            [126.88779, 35.1614],
            [126.88771, 35.1616],
            [126.88716, 35.16262],
            [126.8872, 35.16274],
            [126.88717, 35.16278],
            [126.8871, 35.16279],
            [126.88711, 35.16308],
            [126.88709, 35.16314],
            [126.88702, 35.16323],
            [126.8869, 35.16394],
            [126.88681, 35.16406],
            [126.88672, 35.16405],
            [126.8867, 35.16427],
            [126.88658, 35.16473],
            [126.88636, 35.16517],
            [126.88608, 35.16586],
            [126.88589, 35.16598],
            [126.88575, 35.16585],
            [126.88562, 35.16615],
            [126.88538, 35.16631],
            [126.88563, 35.16655],
            [126.88563, 35.16663],
            [126.88571, 35.1667],
            [126.88576, 35.16672],
            [126.88576, 35.16686],
            [126.88553, 35.16688],
            [126.88534, 35.16693],
            [126.88503, 35.16718],
            [126.88487, 35.16737],
            [126.88482, 35.16752],
            [126.88485, 35.16758],
            [126.88402, 35.16852],
            [126.88417, 35.16973],
            [126.8835, 35.17004],
            [126.88196, 35.1705],
            [126.88145, 35.17062],
            [126.88024, 35.17085],
            [126.87932, 35.17096],
            [126.87807, 35.17097],
            [126.87739, 35.17095],
            [126.87584, 35.17063],
            [126.87562, 35.17057],
            [126.87565, 35.17137],
            [126.87022, 35.17171],
            [126.86073, 35.17222],
            [126.85986, 35.17231],
            [126.8583, 35.17265],
            [126.84788, 35.17508],
            [126.84287, 35.17619],
            [126.84329, 35.17689],
            [126.84295, 35.17691],
            [126.84277, 35.17686],
            [126.8426, 35.17692],
            [126.84251, 35.17701],
            [126.84247, 35.17723],
            [126.84244, 35.17728],
            [126.84241, 35.17741],
            [126.84236, 35.17746],
            [126.84189, 35.17771],
            [126.84144, 35.17787],
            [126.84144, 35.17802],
            [126.84134, 35.17809],
            [126.84063, 35.17837],
            [126.84054, 35.17845],
            [126.84043, 35.17848],
            [126.84038, 35.17853],
            [126.84026, 35.17858],
            [126.84009, 35.17861],
            [126.84003, 35.17854],
            [126.83986, 35.17848],
            [126.83973, 35.17849],
            [126.83956, 35.1784],
            [126.83953, 35.17835],
            [126.8394, 35.17832],
            [126.83933, 35.17838],
            [126.83909, 35.17837],
            [126.83873, 35.17841],
            [126.83898, 35.17891],
            [126.83915, 35.17944],
            [126.84046, 35.18081],
            [126.84138, 35.18185],
            [126.84477, 35.1822],
            [126.84581, 35.18064],
            [126.8461, 35.18025],
            [126.84648, 35.17986],
            [126.84711, 35.17957],
            [126.84817, 35.17964],
            [126.84934, 35.17955],
            [126.85024, 35.17963],
            [126.85086, 35.1798],
            [126.85134, 35.18041],
            [126.85144, 35.18118],
            [126.85194, 35.18179],
            [126.8526, 35.18222],
            [126.85325, 35.1824],
            [126.85408, 35.18238],
            [126.85473, 35.18219],
            [126.85541, 35.18203],
            [126.85603, 35.182],
            [126.85711, 35.18228],
            [126.85809, 35.1828],
            [126.85871, 35.18316],
            [126.85905, 35.1837],
            [126.85912, 35.18378],
            [126.85989, 35.18493],
            [126.86042, 35.18566],
            [126.86038, 35.18629],
            [126.86014, 35.1868],
            [126.85992, 35.18718],
            [126.85986, 35.18751],
            [126.85949, 35.18812],
            [126.8593, 35.18885],
            [126.85918, 35.18958],
            [126.85917, 35.19026],
            [126.85942, 35.19109],
            [126.85963, 35.1917],
            [126.85966, 35.19215],
            [126.8595, 35.19289],
            [126.85935, 35.19352],
            [126.85913, 35.19404],
            [126.85892, 35.19442],
            [126.85866, 35.19472],
            [126.85855, 35.19478],
            [126.85844, 35.1949],
            [126.85809, 35.19503],
            [126.8573, 35.1954],
            [126.85669, 35.19558],
            [126.85543, 35.19612],
            [126.85508, 35.19615],
            [126.85452, 35.19608],
            [126.854, 35.19616],
            [126.85324, 35.19642],
            [126.85278, 35.19663],
            [126.85221, 35.19708],
            [126.85213, 35.19725],
            [126.85197, 35.19831],
            [126.85312, 35.19884],
            [126.85361, 35.19903],
            [126.85422, 35.19946],
            [126.85478, 35.20007],
            [126.85511, 35.20063],
            [126.85534, 35.20135],
            [126.85535, 35.20147],
            [126.8553, 35.20217],
            [126.85522, 35.20308],
            [126.85511, 35.20387],
            [126.85505, 35.20448],
            [126.85512, 35.2051],
            [126.85512, 35.20582],
            [126.85487, 35.20678],
            [126.85455, 35.20786],
            [126.85448, 35.20804],
            [126.85409, 35.20889],
            [126.85388, 35.20914],
            [126.85362, 35.20954],
            [126.85338, 35.20977],
            [126.85295, 35.21029],
            [126.85266, 35.2107],
            [126.85258, 35.21102],
            [126.85263, 35.21181],
            [126.85273, 35.21233],
            [126.85291, 35.21274],
            [126.85312, 35.21364],
            [126.85348, 35.21413],
            [126.85365, 35.21439],
            [126.85381, 35.21446],
            [126.85376, 35.21456],
            [126.85451, 35.21534],
            [126.85515, 35.21589],
            [126.85515, 35.21589],
            [126.85582, 35.21652],
            [126.85673, 35.21731],
            [126.85683, 35.21732],
            [126.85691, 35.21738],
            [126.85694, 35.21743],
            [126.85701, 35.21749],
            [126.85665, 35.21764],
            [126.85625, 35.21778],
            [126.85556, 35.21792],
            [126.85503, 35.21796],
            [126.85312, 35.21797],
            [126.85302, 35.21805],
            [126.85305, 35.22194],
            [126.84619, 35.22197],
            [126.84612, 35.22203],
            [126.84611, 35.22278],
            [126.84606, 35.22307],
            [126.84597, 35.22336],
            [126.84589, 35.22355],
            [126.84473, 35.2259],
            [126.84174, 35.22492],
            [126.83817, 35.22393],
            [126.83814, 35.22401],
            [126.83805, 35.22399],
            [126.83754, 35.22497],
            [126.83727, 35.22554],
            [126.83714, 35.22573],
            [126.83673, 35.22658],
            [126.83651, 35.22695],
            [126.8363, 35.22734],
            [126.83595, 35.22739],
            [126.83582, 35.22736],
            [126.8358, 35.22746],
            [126.83583, 35.22756],
            [126.83567, 35.22779],
            [126.83561, 35.22792],
            [126.83547, 35.22811],
            [126.83525, 35.22858],
            [126.83513, 35.22872],
            [126.83505, 35.22876],
            [126.83486, 35.22904],
            [126.83488, 35.22919],
            [126.8351, 35.22957],
            [126.83518, 35.22975],
            [126.83529, 35.2299],
            [126.83535, 35.22993],
            [126.83573, 35.22992],
            [126.83618, 35.22986],
            [126.83627, 35.22981],
            [126.83639, 35.22982],
            [126.83682, 35.22998],
            [126.83725, 35.22997],
            [126.83743, 35.22995],
            [126.83763, 35.22991],
            [126.83791, 35.2299],
            [126.83838, 35.22981],
            [126.83848, 35.22982],
            [126.83914, 35.23024],
            [126.83967, 35.2306],
            [126.84008, 35.23073],
            [126.84002, 35.23089],
            [126.84002, 35.23111],
            [126.83997, 35.23127],
            [126.84007, 35.23128],
            [126.84017, 35.23132],
            [126.84024, 35.23136],
            [126.83965, 35.23191],
            [126.83966, 35.23204],
            [126.83975, 35.2322],
            [126.84, 35.23277],
            [126.84017, 35.2331],
            [126.84011, 35.2333],
            [126.84028, 35.2333],
            [126.84036, 35.23347],
            [126.84038, 35.23362],
            [126.84032, 35.23365],
            [126.84032, 35.23397],
            [126.84082, 35.2344],
            [126.84119, 35.23433],
            [126.84134, 35.23432],
            [126.84146, 35.23422],
            [126.84153, 35.23419],
            [126.8417, 35.23417],
            [126.84174, 35.2341],
            [126.84193, 35.23388],
            [126.84206, 35.23381],
            [126.84208, 35.23387],
            [126.84207, 35.23406],
            [126.84213, 35.2342],
            [126.84256, 35.23472],
            [126.84274, 35.23481],
            [126.84277, 35.23488],
            [126.84286, 35.23488],
            [126.84308, 35.23496],
            [126.84355, 35.23485],
            [126.84375, 35.23473],
            [126.84381, 35.23473],
            [126.84389, 35.23468],
            [126.84416, 35.23454],
            [126.84427, 35.23445],
            [126.84437, 35.23434],
            [126.84449, 35.23439],
            [126.84457, 35.23449],
            [126.84469, 35.23457],
            [126.84489, 35.23463],
            [126.84492, 35.23474],
            [126.84488, 35.23491],
            [126.84486, 35.23504],
            [126.84483, 35.23513],
            [126.84478, 35.23522],
            [126.84475, 35.23536],
            [126.84468, 35.23551],
            [126.84463, 35.23571],
            [126.84461, 35.23585],
            [126.84432, 35.23644],
            [126.84428, 35.23656],
            [126.84436, 35.23669],
            [126.8443, 35.23699],
            [126.84454, 35.23702],
            [126.8446, 35.23707],
            [126.84467, 35.23706],
            [126.84471, 35.23698],
            [126.84479, 35.23693],
            [126.8449, 35.23702],
            [126.84505, 35.23707],
            [126.84513, 35.23714],
            [126.84556, 35.23712],
            [126.84575, 35.23714],
            [126.846, 35.2372],
            [126.84613, 35.2372],
            [126.84667, 35.2374],
            [126.847, 35.2373],
            [126.84726, 35.23723],
            [126.84756, 35.23661],
            [126.84777, 35.23634],
            [126.84789, 35.23612],
            [126.84809, 35.23575],
            [126.84872, 35.23608],
            [126.84924, 35.23629],
            [126.84934, 35.23641],
            [126.84936, 35.23655],
            [126.84945, 35.23661],
            [126.84961, 35.2368],
            [126.84958, 35.23684],
            [126.84988, 35.23697],
            [126.85021, 35.23709],
            [126.85019, 35.23716],
            [126.85064, 35.23735],
            [126.85091, 35.23743],
            [126.85092, 35.23753],
            [126.8507, 35.23771],
            [126.85075, 35.23778],
            [126.85086, 35.23789],
            [126.85099, 35.23797],
            [126.85115, 35.23796],
            [126.85125, 35.23802],
            [126.85134, 35.23812],
            [126.8514, 35.23807],
            [126.8514, 35.23814],
            [126.85144, 35.23819],
            [126.85158, 35.23832],
            [126.8522, 35.23803],
            [126.85224, 35.2381],
            [126.85224, 35.2382],
            [126.85233, 35.2382],
            [126.85233, 35.2384],
            [126.85238, 35.2385],
            [126.85258, 35.23849],
            [126.85286, 35.2385],
            [126.8531, 35.23846],
            [126.85316, 35.23849],
            [126.85319, 35.23855],
            [126.85325, 35.23868],
            [126.85355, 35.23867],
            [126.85365, 35.23851],
            [126.85372, 35.23849],
            [126.85406, 35.23849],
            [126.85407, 35.23829],
            [126.85413, 35.23808],
            [126.85429, 35.23809],
            [126.85436, 35.23819],
            [126.85454, 35.23828],
            [126.85474, 35.23848],
            [126.85483, 35.23854],
            [126.85498, 35.23859],
            [126.85526, 35.23859],
            [126.85562, 35.2387],
            [126.85591, 35.23869],
            [126.85599, 35.23874],
            [126.85606, 35.23881],
            [126.85617, 35.23888],
            [126.85624, 35.23896],
            [126.85646, 35.23898],
            [126.85663, 35.23906],
            [126.85682, 35.23919],
            [126.85704, 35.23931],
            [126.85727, 35.23951],
            [126.85741, 35.23943],
            [126.85778, 35.23941],
            [126.85785, 35.23945],
            [126.85787, 35.23953],
            [126.85794, 35.23968],
            [126.85799, 35.23975],
            [126.8581, 35.23985],
            [126.85839, 35.24],
            [126.85848, 35.24001],
            [126.85861, 35.23997],
            [126.85868, 35.23996],
            [126.85889, 35.23988],
            [126.85906, 35.23988],
            [126.85926, 35.23996],
            [126.85941, 35.24],
            [126.85945, 35.24007],
            [126.85946, 35.24013],
            [126.85983, 35.24038],
            [126.8599, 35.24038],
            [126.86002, 35.24034],
            [126.86023, 35.24054],
            [126.86044, 35.24091],
            [126.86051, 35.241],
            [126.86078, 35.24122],
            [126.86089, 35.24146],
            [126.86097, 35.24151],
            [126.86111, 35.24153],
            [126.86113, 35.24159],
            [126.86114, 35.24172],
            [126.8612, 35.24201],
            [126.86119, 35.2426],
            [126.86117, 35.24267],
            [126.8611, 35.24272],
            [126.86107, 35.24282],
            [126.86108, 35.2429],
            [126.86113, 35.24302],
            [126.86113, 35.2432],
            [126.86122, 35.2434],
            [126.86125, 35.2435],
            [126.86125, 35.24361],
            [126.86127, 35.24372],
            [126.86135, 35.24386],
            [126.86137, 35.24394],
            [126.86143, 35.24407],
            [126.86158, 35.24424],
            [126.86194, 35.24441],
            [126.86234, 35.24457],
            [126.86242, 35.24467],
            [126.86256, 35.24478],
            [126.86256, 35.24496],
            [126.86249, 35.24504],
            [126.86249, 35.24515],
            [126.86253, 35.24519],
            [126.86254, 35.24525],
            [126.8626, 35.24529],
            [126.86265, 35.24526],
            [126.86315, 35.24555],
            [126.86322, 35.24557],
            [126.86338, 35.24556],
            [126.86345, 35.24562],
            [126.86342, 35.24572],
            [126.86352, 35.24573],
            [126.86386, 35.24561],
            [126.86391, 35.24563],
            [126.86393, 35.24581],
            [126.86427, 35.24578],
            [126.86438, 35.24565],
            [126.86455, 35.24543],
            [126.86489, 35.24556],
            [126.86494, 35.24564],
            [126.86496, 35.24579],
            [126.86503, 35.24588],
            [126.86531, 35.24594],
            [126.86598, 35.24635],
            [126.86606, 35.24638],
            [126.86621, 35.24636],
            [126.86625, 35.24632],
            [126.86675, 35.24619],
            [126.86728, 35.24616],
            [126.86874, 35.24613],
            [126.86911, 35.24617],
            [126.86952, 35.24637],
            [126.87027, 35.247],
            [126.87088, 35.24728],
            [126.87105, 35.24742],
            [126.87135, 35.24761],
            [126.8714, 35.24789],
            [126.87155, 35.24812],
            [126.87226, 35.24847],
            [126.87257, 35.24869],
            [126.87306, 35.24928],
            [126.87304, 35.24934],
            [126.8732, 35.24935],
            [126.87323, 35.24943],
            [126.87358, 35.24957],
            [126.87405, 35.24981],
            [126.87417, 35.24991],
            [126.87421, 35.25],
            [126.87421, 35.25005],
            [126.87445, 35.25004],
            [126.8745, 35.2501],
            [126.87492, 35.25012],
            [126.87523, 35.25011],
            [126.87545, 35.25016],
            [126.87597, 35.25035],
            [126.87636, 35.25041],
            [126.87659, 35.25048],
            [126.87669, 35.25053],
            [126.87685, 35.25065],
            [126.87711, 35.25077],
            [126.87764, 35.25095],
            [126.87783, 35.25105],
            [126.87812, 35.25123],
            [126.87823, 35.25127],
            [126.87855, 35.25134],
            [126.87867, 35.25133],
            [126.87906, 35.2514],
            [126.87938, 35.25144],
            [126.87952, 35.25148],
            [126.87946, 35.25111],
            [126.87928, 35.25054],
            [126.879, 35.25007],
            [126.87894, 35.24989],
            [126.87886, 35.24974],
            [126.87877, 35.2496],
            [126.87866, 35.24949],
            [126.87864, 35.24937],
            [126.87868, 35.24921],
            [126.87864, 35.24905],
            [126.87865, 35.24896],
            [126.8787, 35.24887],
            [126.87875, 35.24871],
            [126.8788, 35.2486],
            [126.87882, 35.2484],
            [126.87909, 35.24812],
            [126.8792, 35.24804],
            [126.87975, 35.24784],
            [126.87994, 35.24774],
            [126.8803, 35.24759],
            [126.88045, 35.24759],
            [126.88059, 35.24763],
            [126.8808, 35.24765],
            [126.88093, 35.24761],
            [126.8811, 35.24753],
            [126.88123, 35.24746],
            [126.88144, 35.24744],
            [126.8818, 35.24744],
            [126.88228, 35.24732],
            [126.8829, 35.2472],
            [126.88314, 35.24715],
            [126.88332, 35.24716],
            [126.88379, 35.2471],
            [126.884, 35.2471],
            [126.88434, 35.24707],
            [126.88447, 35.24701],
            [126.88455, 35.24699],
            [126.88464, 35.24699],
            [126.88488, 35.24689],
            [126.88517, 35.24665],
            [126.88524, 35.24662],
            [126.88545, 35.24659],
            [126.88566, 35.24665],
            [126.88587, 35.24674],
            [126.88596, 35.24671],
            [126.88605, 35.24672],
            [126.8861, 35.24667],
            [126.88608, 35.2466],
            [126.88621, 35.24636],
            [126.88633, 35.24624],
            [126.88644, 35.24622],
            [126.88687, 35.24629],
            [126.88727, 35.24634],
            [126.88784, 35.2465],
            [126.88824, 35.24659],
            [126.88863, 35.24672],
            [126.88872, 35.24687],
            [126.88892, 35.24676],
            [126.88962, 35.24666],
            [126.8898, 35.24731],
            [126.89009, 35.24786],
            [126.89068, 35.24884],
            [126.89096, 35.24944],
            [126.8914, 35.24986],
            [126.89272, 35.2506],
            [126.89408, 35.25113],
            [126.89541, 35.25176],
            [126.89642, 35.25231],
            [126.89685, 35.25245],
            [126.89788, 35.2527],
            [126.89871, 35.25296],
            [126.89934, 35.25337],
            [126.90016, 35.25382],
            [126.90097, 35.25462],
            [126.90157, 35.25532],
            [126.90191, 35.25569],
            [126.90277, 35.25676],
            [126.90343, 35.25742],
            [126.90409, 35.2578],
            [126.90482, 35.25809],
            [126.90549, 35.25824],
            [126.9063, 35.25822],
            [126.9074, 35.25823],
            [126.90861, 35.25838],
            [126.90949, 35.25846]
          ]
        ]
      }
    },
    {
      id: '29200',
      type: 'Feature',
      properties: {
        SEC_SGG_CD: '29200',
        SEC_SGG_NM: ['광주광역시', '광산구'],
        COL_SGG_CD: '29000',
        SEC_SGG_SIZE: '2',
        bbox: [126.6447, 35.07017, 126.86042, 35.25871],
        center: [126.7529, 35.16501],
        area: 222053810
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.76029, 35.25871],
            [126.76119, 35.25856],
            [126.76159, 35.25813],
            [126.7624, 35.25735],
            [126.76304, 35.25685],
            [126.76338, 35.25652],
            [126.76331, 35.2564],
            [126.76355, 35.25543],
            [126.76389, 35.25494],
            [126.76381, 35.25413],
            [126.76366, 35.2534],
            [126.76341, 35.25305],
            [126.76307, 35.25275],
            [126.76255, 35.25236],
            [126.76208, 35.25219],
            [126.76215, 35.25175],
            [126.76161, 35.2513],
            [126.76138, 35.25093],
            [126.76139, 35.24938],
            [126.76107, 35.24854],
            [126.76075, 35.24743],
            [126.76061, 35.24723],
            [126.76026, 35.24682],
            [126.75966, 35.24623],
            [126.75934, 35.24569],
            [126.75897, 35.2454],
            [126.75849, 35.24529],
            [126.75784, 35.24476],
            [126.7571, 35.24397],
            [126.75672, 35.24365],
            [126.75555, 35.24334],
            [126.75534, 35.24246],
            [126.75523, 35.24224],
            [126.75493, 35.24212],
            [126.75481, 35.24188],
            [126.75507, 35.24142],
            [126.75517, 35.24122],
            [126.75518, 35.24096],
            [126.75481, 35.24082],
            [126.75491, 35.24053],
            [126.75461, 35.23969],
            [126.75452, 35.23901],
            [126.75429, 35.23812],
            [126.75426, 35.23803],
            [126.75378, 35.2371],
            [126.75402, 35.23641],
            [126.75444, 35.23541],
            [126.75522, 35.2344],
            [126.75557, 35.23418],
            [126.75588, 35.23404],
            [126.75705, 35.23371],
            [126.75922, 35.23356],
            [126.76036, 35.23393],
            [126.76136, 35.23363],
            [126.76219, 35.23358],
            [126.76289, 35.23325],
            [126.76385, 35.23267],
            [126.76413, 35.2326],
            [126.76434, 35.23262],
            [126.76463, 35.23273],
            [126.76495, 35.23273],
            [126.76574, 35.23259],
            [126.76653, 35.23248],
            [126.76679, 35.23214],
            [126.76725, 35.23187],
            [126.76751, 35.23191],
            [126.76799, 35.23184],
            [126.76836, 35.23186],
            [126.76833, 35.23222],
            [126.76848, 35.23242],
            [126.77, 35.23273],
            [126.77031, 35.23282],
            [126.77068, 35.23233],
            [126.77084, 35.23207],
            [126.77159, 35.23114],
            [126.7719, 35.23089],
            [126.77203, 35.23085],
            [126.77277, 35.23083],
            [126.7732, 35.23072],
            [126.77355, 35.23047],
            [126.77496, 35.23049],
            [126.77507, 35.23053],
            [126.77535, 35.23057],
            [126.77607, 35.22988],
            [126.77628, 35.2296],
            [126.77642, 35.22945],
            [126.7765, 35.22922],
            [126.77677, 35.22877],
            [126.77714, 35.22831],
            [126.77748, 35.22807],
            [126.77758, 35.22795],
            [126.77779, 35.22776],
            [126.77782, 35.22762],
            [126.77844, 35.22719],
            [126.77861, 35.22704],
            [126.77876, 35.22685],
            [126.77896, 35.22677],
            [126.77951, 35.22637],
            [126.77958, 35.22625],
            [126.77972, 35.22604],
            [126.78002, 35.22579],
            [126.7802, 35.22568],
            [126.78044, 35.22559],
            [126.78056, 35.22551],
            [126.78061, 35.22544],
            [126.78069, 35.22537],
            [126.78129, 35.22501],
            [126.78144, 35.22488],
            [126.78164, 35.22478],
            [126.78183, 35.22474],
            [126.782, 35.22474],
            [126.78203, 35.22487],
            [126.78208, 35.22495],
            [126.78264, 35.22478],
            [126.78317, 35.22456],
            [126.7835, 35.22451],
            [126.78414, 35.22427],
            [126.78442, 35.22419],
            [126.78523, 35.22411],
            [126.78575, 35.22398],
            [126.7859, 35.22403],
            [126.78601, 35.22402],
            [126.78626, 35.22391],
            [126.7865, 35.22392],
            [126.78655, 35.22387],
            [126.78662, 35.2239],
            [126.78765, 35.22326],
            [126.78772, 35.22333],
            [126.7882, 35.22365],
            [126.78851, 35.22412],
            [126.78856, 35.22414],
            [126.7888, 35.22411],
            [126.78911, 35.224],
            [126.78943, 35.22397],
            [126.78995, 35.22371],
            [126.79018, 35.22354],
            [126.79025, 35.22346],
            [126.79074, 35.22316],
            [126.7908, 35.22314],
            [126.7914, 35.22246],
            [126.79269, 35.22188],
            [126.79296, 35.22166],
            [126.79341, 35.221],
            [126.79357, 35.22085],
            [126.79396, 35.22056],
            [126.79466, 35.22009],
            [126.79507, 35.21987],
            [126.79529, 35.2198],
            [126.79538, 35.21978],
            [126.79629, 35.21969],
            [126.7967, 35.22],
            [126.79686, 35.2201],
            [126.79709, 35.2202],
            [126.79736, 35.22026],
            [126.79761, 35.22022],
            [126.79771, 35.22017],
            [126.79781, 35.22009],
            [126.79787, 35.22011],
            [126.79805, 35.2201],
            [126.79851, 35.2201],
            [126.79868, 35.22007],
            [126.79878, 35.22001],
            [126.799, 35.21995],
            [126.80078, 35.21975],
            [126.80087, 35.21976],
            [126.80115, 35.21973],
            [126.80122, 35.21976],
            [126.80133, 35.21982],
            [126.80156, 35.22025],
            [126.80187, 35.2205],
            [126.80215, 35.2209],
            [126.80241, 35.22088],
            [126.80278, 35.22081],
            [126.80293, 35.22092],
            [126.80291, 35.22109],
            [126.8031, 35.22113],
            [126.80319, 35.22116],
            [126.80339, 35.22118],
            [126.80353, 35.22118],
            [126.80413, 35.22129],
            [126.80441, 35.22132],
            [126.80456, 35.22088],
            [126.80455, 35.22078],
            [126.80457, 35.2207],
            [126.80461, 35.22065],
            [126.80462, 35.21998],
            [126.80467, 35.21988],
            [126.80469, 35.21963],
            [126.8048, 35.21942],
            [126.8049, 35.21929],
            [126.80499, 35.21888],
            [126.80503, 35.21882],
            [126.80513, 35.21871],
            [126.80521, 35.21853],
            [126.80531, 35.21836],
            [126.80539, 35.2183],
            [126.80546, 35.21836],
            [126.80557, 35.21856],
            [126.80562, 35.21862],
            [126.80571, 35.2187],
            [126.806, 35.21888],
            [126.80606, 35.21896],
            [126.80598, 35.21935],
            [126.80606, 35.21932],
            [126.80621, 35.21931],
            [126.80653, 35.21939],
            [126.80664, 35.2194],
            [126.80675, 35.21939],
            [126.8069, 35.21941],
            [126.80702, 35.21964],
            [126.80717, 35.21976],
            [126.8074, 35.2198],
            [126.80762, 35.21993],
            [126.8078, 35.21994],
            [126.80801, 35.21994],
            [126.80819, 35.21999],
            [126.80834, 35.22013],
            [126.80841, 35.22022],
            [126.80851, 35.22041],
            [126.80855, 35.22062],
            [126.80873, 35.22105],
            [126.80883, 35.22101],
            [126.80894, 35.22106],
            [126.8092, 35.2211],
            [126.80947, 35.22106],
            [126.8097, 35.22109],
            [126.8098, 35.22099],
            [126.80989, 35.22108],
            [126.81001, 35.22111],
            [126.81014, 35.22124],
            [126.81032, 35.22116],
            [126.81053, 35.22109],
            [126.81062, 35.22109],
            [126.81086, 35.22113],
            [126.81086, 35.22134],
            [126.81096, 35.22146],
            [126.81107, 35.2217],
            [126.81137, 35.22213],
            [126.81151, 35.2224],
            [126.81158, 35.22251],
            [126.81169, 35.2226],
            [126.81177, 35.22266],
            [126.81211, 35.22257],
            [126.81213, 35.22268],
            [126.81218, 35.22273],
            [126.81225, 35.22275],
            [126.81242, 35.22276],
            [126.81263, 35.22269],
            [126.81292, 35.22267],
            [126.81321, 35.22271],
            [126.81315, 35.22293],
            [126.81315, 35.22298],
            [126.81319, 35.22304],
            [126.81345, 35.22322],
            [126.81355, 35.22344],
            [126.81372, 35.22353],
            [126.8138, 35.22353],
            [126.81425, 35.2237],
            [126.81437, 35.22375],
            [126.81447, 35.22377],
            [126.81472, 35.2239],
            [126.81481, 35.22397],
            [126.815, 35.22408],
            [126.81508, 35.22412],
            [126.81522, 35.22414],
            [126.81532, 35.2242],
            [126.81533, 35.22425],
            [126.81533, 35.22435],
            [126.81547, 35.22451],
            [126.81566, 35.2247],
            [126.81573, 35.2247],
            [126.8161, 35.22461],
            [126.81654, 35.22442],
            [126.8167, 35.22428],
            [126.81688, 35.22404],
            [126.81695, 35.22399],
            [126.8176, 35.22387],
            [126.81782, 35.22385],
            [126.81815, 35.22416],
            [126.81838, 35.22441],
            [126.81845, 35.22443],
            [126.81851, 35.22448],
            [126.81856, 35.22454],
            [126.81861, 35.22468],
            [126.8187, 35.22467],
            [126.81876, 35.2248],
            [126.81896, 35.22503],
            [126.81931, 35.2252],
            [126.81946, 35.22522],
            [126.81956, 35.22538],
            [126.81964, 35.22546],
            [126.81963, 35.22562],
            [126.81966, 35.22598],
            [126.81971, 35.22606],
            [126.8198, 35.22615],
            [126.82031, 35.2262],
            [126.8205, 35.2262],
            [126.82066, 35.22608],
            [126.82073, 35.22597],
            [126.82089, 35.2259],
            [126.82103, 35.22578],
            [126.82124, 35.22582],
            [126.82142, 35.22594],
            [126.82157, 35.226],
            [126.82167, 35.22608],
            [126.82179, 35.22611],
            [126.82191, 35.22605],
            [126.82205, 35.22601],
            [126.82225, 35.22603],
            [126.82238, 35.22596],
            [126.82249, 35.22597],
            [126.82288, 35.2259],
            [126.82296, 35.22589],
            [126.823, 35.22597],
            [126.82311, 35.22599],
            [126.82326, 35.22597],
            [126.8233, 35.22586],
            [126.82335, 35.22582],
            [126.8235, 35.2258],
            [126.82364, 35.22575],
            [126.82394, 35.22578],
            [126.82405, 35.22588],
            [126.82411, 35.22598],
            [126.82418, 35.22605],
            [126.82439, 35.22638],
            [126.82469, 35.22674],
            [126.82491, 35.22694],
            [126.82493, 35.22708],
            [126.82497, 35.22714],
            [126.82502, 35.22741],
            [126.82491, 35.22788],
            [126.8249, 35.2288],
            [126.82462, 35.2295],
            [126.82447, 35.23043],
            [126.82445, 35.23049],
            [126.82435, 35.23059],
            [126.82449, 35.23081],
            [126.82462, 35.23118],
            [126.82471, 35.2313],
            [126.82474, 35.23144],
            [126.82511, 35.23177],
            [126.82523, 35.23177],
            [126.82533, 35.2319],
            [126.82585, 35.23167],
            [126.82644, 35.23148],
            [126.82738, 35.23111],
            [126.82752, 35.23123],
            [126.82795, 35.23098],
            [126.82798, 35.23086],
            [126.82981, 35.2304],
            [126.83019, 35.23023],
            [126.83054, 35.22996],
            [126.83078, 35.22987],
            [126.83163, 35.22945],
            [126.8325, 35.2289],
            [126.83324, 35.22833],
            [126.83486, 35.22873],
            [126.83505, 35.22876],
            [126.83513, 35.22872],
            [126.83525, 35.22858],
            [126.83547, 35.22811],
            [126.83561, 35.22792],
            [126.83567, 35.22779],
            [126.83583, 35.22756],
            [126.8358, 35.22746],
            [126.83582, 35.22736],
            [126.83595, 35.22739],
            [126.8363, 35.22734],
            [126.83651, 35.22695],
            [126.83673, 35.22658],
            [126.83714, 35.22573],
            [126.83727, 35.22554],
            [126.83754, 35.22497],
            [126.83805, 35.22399],
            [126.83814, 35.22401],
            [126.83817, 35.22393],
            [126.84193, 35.22497],
            [126.84473, 35.2259],
            [126.84589, 35.22355],
            [126.84597, 35.22336],
            [126.84606, 35.22307],
            [126.84611, 35.22278],
            [126.84612, 35.22203],
            [126.84619, 35.22197],
            [126.85305, 35.22194],
            [126.85302, 35.21805],
            [126.85312, 35.21797],
            [126.85503, 35.21796],
            [126.85556, 35.21792],
            [126.85625, 35.21778],
            [126.85665, 35.21764],
            [126.85701, 35.21749],
            [126.85694, 35.21743],
            [126.85691, 35.21738],
            [126.85683, 35.21732],
            [126.85673, 35.21731],
            [126.85582, 35.21652],
            [126.85515, 35.21589],
            [126.85451, 35.21534],
            [126.85376, 35.21456],
            [126.85381, 35.21446],
            [126.85365, 35.21439],
            [126.85348, 35.21413],
            [126.85312, 35.21364],
            [126.85291, 35.21274],
            [126.85273, 35.21233],
            [126.85263, 35.21181],
            [126.85258, 35.21102],
            [126.85266, 35.2107],
            [126.85295, 35.21029],
            [126.85338, 35.20977],
            [126.85362, 35.20954],
            [126.85388, 35.20914],
            [126.85409, 35.20889],
            [126.85448, 35.20804],
            [126.85455, 35.20786],
            [126.85487, 35.20678],
            [126.85512, 35.20582],
            [126.85512, 35.2051],
            [126.85505, 35.20448],
            [126.85511, 35.20387],
            [126.85522, 35.20308],
            [126.8553, 35.20217],
            [126.85535, 35.20147],
            [126.85534, 35.20135],
            [126.85511, 35.20063],
            [126.85478, 35.20007],
            [126.85422, 35.19946],
            [126.85361, 35.19903],
            [126.85312, 35.19884],
            [126.85197, 35.19831],
            [126.85213, 35.19725],
            [126.85221, 35.19708],
            [126.85278, 35.19663],
            [126.85324, 35.19642],
            [126.854, 35.19616],
            [126.85452, 35.19608],
            [126.85508, 35.19615],
            [126.85543, 35.19612],
            [126.85669, 35.19558],
            [126.8573, 35.1954],
            [126.85809, 35.19503],
            [126.85844, 35.1949],
            [126.85855, 35.19478],
            [126.85866, 35.19472],
            [126.85892, 35.19442],
            [126.85913, 35.19404],
            [126.85935, 35.19352],
            [126.8595, 35.19289],
            [126.85966, 35.19215],
            [126.85963, 35.1917],
            [126.85942, 35.19109],
            [126.85917, 35.19026],
            [126.85918, 35.18958],
            [126.8593, 35.18885],
            [126.85949, 35.18812],
            [126.85986, 35.18751],
            [126.85992, 35.18718],
            [126.86014, 35.1868],
            [126.86038, 35.18629],
            [126.86042, 35.18566],
            [126.85989, 35.18493],
            [126.85912, 35.18378],
            [126.85905, 35.1837],
            [126.85871, 35.18316],
            [126.85809, 35.1828],
            [126.85711, 35.18228],
            [126.85603, 35.182],
            [126.85541, 35.18203],
            [126.85473, 35.18219],
            [126.85408, 35.18238],
            [126.85325, 35.1824],
            [126.8526, 35.18222],
            [126.85194, 35.18179],
            [126.85144, 35.18118],
            [126.85134, 35.18041],
            [126.85086, 35.1798],
            [126.85024, 35.17963],
            [126.84934, 35.17955],
            [126.84817, 35.17964],
            [126.84711, 35.17957],
            [126.84648, 35.17986],
            [126.8461, 35.18025],
            [126.84581, 35.18064],
            [126.84477, 35.1822],
            [126.84138, 35.18185],
            [126.84046, 35.18081],
            [126.83915, 35.17944],
            [126.83898, 35.17891],
            [126.83873, 35.17841],
            [126.83806, 35.17743],
            [126.8377, 35.17667],
            [126.83767, 35.17603],
            [126.83768, 35.17547],
            [126.83774, 35.17499],
            [126.83773, 35.17491],
            [126.83759, 35.17378],
            [126.83755, 35.17329],
            [126.8374, 35.17306],
            [126.83742, 35.1725],
            [126.83755, 35.17213],
            [126.83765, 35.17153],
            [126.83762, 35.17121],
            [126.83742, 35.17082],
            [126.8369, 35.17045],
            [126.83615, 35.17008],
            [126.83398, 35.16988],
            [126.83279, 35.16989],
            [126.83189, 35.16963],
            [126.83087, 35.1694],
            [126.83045, 35.16922],
            [126.82907, 35.16842],
            [126.8284, 35.16817],
            [126.82793, 35.16801],
            [126.82694, 35.1673],
            [126.82629, 35.16676],
            [126.82519, 35.16587],
            [126.82508, 35.16573],
            [126.82489, 35.16539],
            [126.82483, 35.16523],
            [126.82473, 35.16469],
            [126.82453, 35.16415],
            [126.82448, 35.16393],
            [126.82449, 35.16272],
            [126.82466, 35.16222],
            [126.82499, 35.16172],
            [126.82521, 35.16135],
            [126.82556, 35.16113],
            [126.82594, 35.16076],
            [126.8261, 35.16046],
            [126.82639, 35.16038],
            [126.82638, 35.15982],
            [126.8264, 35.15913],
            [126.82645, 35.15878],
            [126.82653, 35.1584],
            [126.82651, 35.15835],
            [126.82635, 35.15814],
            [126.82573, 35.15742],
            [126.82554, 35.15713],
            [126.82548, 35.15697],
            [126.82527, 35.15588],
            [126.82528, 35.1558],
            [126.82543, 35.15515],
            [126.82535, 35.15493],
            [126.82524, 35.15452],
            [126.82528, 35.15365],
            [126.82611, 35.15274],
            [126.82708, 35.15192],
            [126.82754, 35.15142],
            [126.82769, 35.15119],
            [126.82817, 35.15073],
            [126.82846, 35.15035],
            [126.82853, 35.15019],
            [126.82884, 35.14931],
            [126.82893, 35.14924],
            [126.82902, 35.14905],
            [126.82913, 35.14862],
            [126.82927, 35.14717],
            [126.82925, 35.14661],
            [126.82917, 35.14609],
            [126.82913, 35.14561],
            [126.82926, 35.14533],
            [126.82913, 35.14445],
            [126.82913, 35.14421],
            [126.82919, 35.14357],
            [126.82931, 35.14334],
            [126.82958, 35.14257],
            [126.82969, 35.14148],
            [126.82946, 35.14084],
            [126.82877, 35.14014],
            [126.82817, 35.13934],
            [126.82787, 35.13908],
            [126.82743, 35.13863],
            [126.82653, 35.13793],
            [126.82602, 35.13739],
            [126.82512, 35.13657],
            [126.82425, 35.13562],
            [126.82373, 35.13523],
            [126.82321, 35.13511],
            [126.82228, 35.13497],
            [126.82146, 35.13466],
            [126.82063, 35.13427],
            [126.8203, 35.13405],
            [126.81923, 35.13315],
            [126.81901, 35.13282],
            [126.81886, 35.1323],
            [126.81873, 35.1317],
            [126.81869, 35.13116],
            [126.81877, 35.13056],
            [126.81891, 35.13004],
            [126.81903, 35.12977],
            [126.81978, 35.12895],
            [126.82092, 35.12836],
            [126.82158, 35.12783],
            [126.82204, 35.12713],
            [126.82225, 35.12666],
            [126.82205, 35.12618],
            [126.82179, 35.12568],
            [126.82152, 35.12507],
            [126.82102, 35.12429],
            [126.82042, 35.12389],
            [126.81977, 35.12358],
            [126.81828, 35.12264],
            [126.81767, 35.12207],
            [126.81723, 35.12148],
            [126.81695, 35.12074],
            [126.81711, 35.11979],
            [126.81687, 35.11895],
            [126.81686, 35.11853],
            [126.81703, 35.11835],
            [126.81722, 35.11822],
            [126.81736, 35.11816],
            [126.81774, 35.11813],
            [126.8181, 35.11815],
            [126.81847, 35.11829],
            [126.81792, 35.11783],
            [126.81751, 35.11723],
            [126.81704, 35.11679],
            [126.81675, 35.1166],
            [126.81645, 35.11659],
            [126.81596, 35.11681],
            [126.81563, 35.11676],
            [126.81538, 35.11685],
            [126.81503, 35.11689],
            [126.81432, 35.11676],
            [126.81408, 35.11677],
            [126.81394, 35.11679],
            [126.81344, 35.11701],
            [126.81313, 35.11704],
            [126.8126, 35.1169],
            [126.81229, 35.11691],
            [126.81218, 35.11676],
            [126.81204, 35.11667],
            [126.81174, 35.1167],
            [126.81098, 35.11669],
            [126.81033, 35.11662],
            [126.80993, 35.11673],
            [126.80983, 35.11657],
            [126.80982, 35.11646],
            [126.80974, 35.11635],
            [126.80964, 35.11636],
            [126.80959, 35.11629],
            [126.8097, 35.1162],
            [126.80971, 35.11607],
            [126.80957, 35.11581],
            [126.80946, 35.11569],
            [126.80958, 35.11538],
            [126.80957, 35.11533],
            [126.80951, 35.11529],
            [126.80948, 35.11523],
            [126.80944, 35.11508],
            [126.80946, 35.11495],
            [126.80946, 35.11464],
            [126.8095, 35.11442],
            [126.80943, 35.11434],
            [126.80937, 35.11424],
            [126.80937, 35.11416],
            [126.80942, 35.11409],
            [126.80957, 35.11398],
            [126.80964, 35.11381],
            [126.80965, 35.11374],
            [126.80964, 35.11351],
            [126.80946, 35.11312],
            [126.80939, 35.11285],
            [126.80942, 35.11239],
            [126.80927, 35.1123],
            [126.8092, 35.11234],
            [126.80895, 35.11246],
            [126.80878, 35.11247],
            [126.80846, 35.11242],
            [126.8084, 35.11244],
            [126.8083, 35.1125],
            [126.80803, 35.11254],
            [126.80791, 35.11262],
            [126.80784, 35.11262],
            [126.80768, 35.11263],
            [126.80746, 35.11257],
            [126.80708, 35.11256],
            [126.80706, 35.11251],
            [126.80691, 35.11252],
            [126.80684, 35.11228],
            [126.80677, 35.11228],
            [126.80672, 35.11218],
            [126.80667, 35.11213],
            [126.80656, 35.11213],
            [126.80628, 35.11198],
            [126.80609, 35.11193],
            [126.80593, 35.11191],
            [126.80581, 35.11193],
            [126.80565, 35.11207],
            [126.80531, 35.11224],
            [126.80515, 35.11237],
            [126.80492, 35.11239],
            [126.80482, 35.11243],
            [126.80477, 35.11249],
            [126.80457, 35.11255],
            [126.80447, 35.11249],
            [126.80441, 35.1124],
            [126.80388, 35.1127],
            [126.80351, 35.1127],
            [126.80333, 35.11265],
            [126.80333, 35.11258],
            [126.8033, 35.11252],
            [126.80304, 35.11238],
            [126.80281, 35.11225],
            [126.80286, 35.11208],
            [126.80299, 35.11172],
            [126.80286, 35.1115],
            [126.80299, 35.11101],
            [126.80314, 35.11067],
            [126.8038, 35.10899],
            [126.80388, 35.10881],
            [126.80407, 35.10849],
            [126.80431, 35.1082],
            [126.80473, 35.10782],
            [126.80488, 35.10752],
            [126.80472, 35.10708],
            [126.8047, 35.10675],
            [126.80475, 35.10643],
            [126.80495, 35.1058],
            [126.80508, 35.10545],
            [126.80527, 35.10517],
            [126.80555, 35.10497],
            [126.80555, 35.10451],
            [126.80583, 35.1039],
            [126.8062, 35.10342],
            [126.80633, 35.10287],
            [126.80595, 35.10243],
            [126.80531, 35.10225],
            [126.80437, 35.10235],
            [126.80339, 35.10282],
            [126.8025, 35.10217],
            [126.80162, 35.10171],
            [126.80093, 35.1009],
            [126.80084, 35.09995],
            [126.80075, 35.09918],
            [126.80067, 35.09869],
            [126.80068, 35.09804],
            [126.80066, 35.09778],
            [126.80067, 35.0972],
            [126.80078, 35.0967],
            [126.8011, 35.09607],
            [126.8016, 35.09532],
            [126.80214, 35.09464],
            [126.80245, 35.09403],
            [126.80252, 35.09372],
            [126.80253, 35.09349],
            [126.80256, 35.09328],
            [126.80268, 35.09274],
            [126.80254, 35.09218],
            [126.80222, 35.09162],
            [126.80127, 35.09082],
            [126.8006, 35.09046],
            [126.80027, 35.09008],
            [126.79984, 35.08991],
            [126.79967, 35.08981],
            [126.79944, 35.08972],
            [126.79916, 35.08958],
            [126.79879, 35.08942],
            [126.79823, 35.08908],
            [126.79792, 35.0891],
            [126.79736, 35.08905],
            [126.79598, 35.08799],
            [126.79402, 35.0844],
            [126.7942, 35.08434],
            [126.79391, 35.08429],
            [126.7938, 35.08425],
            [126.79307, 35.08362],
            [126.79227, 35.08287],
            [126.79196, 35.08261],
            [126.7914, 35.08209],
            [126.79135, 35.08202],
            [126.7907, 35.0805],
            [126.79058, 35.08055],
            [126.79026, 35.07995],
            [126.78972, 35.08015],
            [126.78906, 35.07895],
            [126.78905, 35.07886],
            [126.78809, 35.078],
            [126.78805, 35.07781],
            [126.78771, 35.07704],
            [126.787, 35.07633],
            [126.78653, 35.07556],
            [126.78611, 35.07492],
            [126.78526, 35.07422],
            [126.78456, 35.07372],
            [126.78256, 35.07257],
            [126.78198, 35.07232],
            [126.78143, 35.07212],
            [126.78108, 35.07204],
            [126.78027, 35.0718],
            [126.77875, 35.07141],
            [126.7774, 35.07135],
            [126.77704, 35.07132],
            [126.77533, 35.07126],
            [126.77443, 35.07114],
            [126.77329, 35.07091],
            [126.77232, 35.0706],
            [126.77132, 35.07017],
            [126.77108, 35.07072],
            [126.77103, 35.07091],
            [126.77098, 35.07129],
            [126.77101, 35.07148],
            [126.77091, 35.07163],
            [126.77067, 35.07159],
            [126.77058, 35.07162],
            [126.77057, 35.07197],
            [126.77059, 35.07237],
            [126.77055, 35.07267],
            [126.77036, 35.07283],
            [126.76974, 35.07304],
            [126.76955, 35.07315],
            [126.76935, 35.07328],
            [126.76933, 35.07337],
            [126.76928, 35.07344],
            [126.76897, 35.07368],
            [126.76875, 35.07374],
            [126.76845, 35.07404],
            [126.76833, 35.07428],
            [126.76815, 35.07447],
            [126.76804, 35.07457],
            [126.76779, 35.07471],
            [126.76772, 35.07479],
            [126.76751, 35.07497],
            [126.76746, 35.07503],
            [126.76727, 35.07517],
            [126.76698, 35.07549],
            [126.76694, 35.0756],
            [126.76694, 35.07572],
            [126.76699, 35.07586],
            [126.76705, 35.07624],
            [126.767, 35.07629],
            [126.76691, 35.07634],
            [126.76677, 35.0763],
            [126.7667, 35.0763],
            [126.76667, 35.07635],
            [126.76666, 35.07641],
            [126.76661, 35.07648],
            [126.76647, 35.07657],
            [126.76639, 35.07665],
            [126.76635, 35.07676],
            [126.76603, 35.07706],
            [126.76585, 35.07734],
            [126.76575, 35.07758],
            [126.76568, 35.07772],
            [126.76557, 35.0778],
            [126.76542, 35.07808],
            [126.76525, 35.07828],
            [126.76485, 35.07871],
            [126.76483, 35.07876],
            [126.76496, 35.07884],
            [126.76519, 35.07911],
            [126.76515, 35.07943],
            [126.76562, 35.08068],
            [126.7656, 35.08074],
            [126.76564, 35.08095],
            [126.7657, 35.08189],
            [126.76559, 35.08227],
            [126.7656, 35.08281],
            [126.76553, 35.08313],
            [126.76546, 35.08331],
            [126.76522, 35.08374],
            [126.76509, 35.08377],
            [126.76487, 35.08371],
            [126.76446, 35.08378],
            [126.76402, 35.08398],
            [126.76375, 35.084],
            [126.76331, 35.08427],
            [126.76349, 35.08492],
            [126.7634, 35.08531],
            [126.76328, 35.08544],
            [126.76295, 35.08569],
            [126.76285, 35.08595],
            [126.7628, 35.08681],
            [126.76284, 35.08715],
            [126.76282, 35.08723],
            [126.76278, 35.08727],
            [126.76262, 35.08745],
            [126.76268, 35.08751],
            [126.76186, 35.08836],
            [126.76172, 35.08856],
            [126.76177, 35.08897],
            [126.76172, 35.0891],
            [126.76174, 35.08935],
            [126.76207, 35.08965],
            [126.76204, 35.09],
            [126.76189, 35.09101],
            [126.76184, 35.09103],
            [126.76139, 35.09104],
            [126.76108, 35.09103],
            [126.76092, 35.09106],
            [126.76014, 35.0913],
            [126.75977, 35.09145],
            [126.75961, 35.0915],
            [126.75869, 35.09163],
            [126.7578, 35.09159],
            [126.75761, 35.09155],
            [126.75736, 35.09126],
            [126.75591, 35.09169],
            [126.75546, 35.09159],
            [126.75499, 35.09152],
            [126.75473, 35.09164],
            [126.75448, 35.09168],
            [126.7543, 35.09169],
            [126.75398, 35.09206],
            [126.75404, 35.09262],
            [126.75418, 35.09273],
            [126.75407, 35.09282],
            [126.75371, 35.09356],
            [126.7536, 35.09393],
            [126.7532, 35.0946],
            [126.75318, 35.09466],
            [126.75275, 35.09503],
            [126.75233, 35.09529],
            [126.75241, 35.09536],
            [126.75239, 35.09548],
            [126.75227, 35.09557],
            [126.75224, 35.09563],
            [126.75227, 35.09573],
            [126.75214, 35.09581],
            [126.75217, 35.09588],
            [126.75196, 35.09605],
            [126.75174, 35.09628],
            [126.75158, 35.09641],
            [126.75113, 35.09684],
            [126.75104, 35.09691],
            [126.75085, 35.09729],
            [126.75089, 35.09756],
            [126.75085, 35.09796],
            [126.75058, 35.09839],
            [126.75033, 35.09881],
            [126.75008, 35.09905],
            [126.74988, 35.09929],
            [126.74965, 35.0997],
            [126.7493, 35.10004],
            [126.74913, 35.10039],
            [126.74904, 35.10076],
            [126.7491, 35.10103],
            [126.74914, 35.10112],
            [126.74915, 35.10141],
            [126.74917, 35.10148],
            [126.74907, 35.10164],
            [126.74899, 35.10173],
            [126.74869, 35.10193],
            [126.74809, 35.10215],
            [126.74764, 35.10253],
            [126.74698, 35.10236],
            [126.74677, 35.10228],
            [126.74633, 35.10252],
            [126.74615, 35.10265],
            [126.74587, 35.10294],
            [126.74518, 35.10342],
            [126.74453, 35.10394],
            [126.74462, 35.10403],
            [126.74454, 35.10406],
            [126.74387, 35.10449],
            [126.74371, 35.10467],
            [126.74359, 35.10468],
            [126.74332, 35.10478],
            [126.74316, 35.10481],
            [126.74318, 35.10494],
            [126.74311, 35.10508],
            [126.74311, 35.10513],
            [126.74297, 35.10528],
            [126.7427, 35.1055],
            [126.74254, 35.10559],
            [126.74205, 35.1058],
            [126.74139, 35.10593],
            [126.74106, 35.10591],
            [126.74076, 35.10594],
            [126.74034, 35.10617],
            [126.74013, 35.10632],
            [126.73982, 35.10646],
            [126.73962, 35.10651],
            [126.73935, 35.10668],
            [126.73896, 35.10704],
            [126.73881, 35.10721],
            [126.73835, 35.10754],
            [126.73741, 35.10792],
            [126.73641, 35.10821],
            [126.73568, 35.10798],
            [126.7355, 35.10795],
            [126.73546, 35.10801],
            [126.73544, 35.10807],
            [126.73548, 35.1083],
            [126.73547, 35.10851],
            [126.7354, 35.10906],
            [126.73532, 35.10923],
            [126.73534, 35.10928],
            [126.73529, 35.1093],
            [126.7351, 35.10946],
            [126.73464, 35.1101],
            [126.7345, 35.11035],
            [126.7345, 35.11042],
            [126.73431, 35.11114],
            [126.73413, 35.11112],
            [126.73403, 35.11127],
            [126.7335, 35.1119],
            [126.73307, 35.11227],
            [126.73282, 35.11259],
            [126.7328, 35.11272],
            [126.73277, 35.11277],
            [126.73265, 35.11286],
            [126.73245, 35.11294],
            [126.73238, 35.11288],
            [126.73225, 35.11281],
            [126.73204, 35.11271],
            [126.73181, 35.11256],
            [126.7318, 35.11244],
            [126.73179, 35.11236],
            [126.73155, 35.11218],
            [126.7315, 35.11212],
            [126.73138, 35.11209],
            [126.7312, 35.1121],
            [126.73092, 35.1121],
            [126.73067, 35.11209],
            [126.73079, 35.11203],
            [126.73084, 35.11198],
            [126.73083, 35.11175],
            [126.7308, 35.11163],
            [126.73065, 35.11147],
            [126.73057, 35.11139],
            [126.73062, 35.11137],
            [126.73059, 35.11129],
            [126.73051, 35.11039],
            [126.73077, 35.10986],
            [126.73129, 35.10938],
            [126.7313, 35.10888],
            [126.73099, 35.10868],
            [126.73132, 35.10821],
            [126.73121, 35.10812],
            [126.73095, 35.10797],
            [126.73086, 35.1079],
            [126.73077, 35.10779],
            [126.73039, 35.10776],
            [126.72986, 35.10766],
            [126.72953, 35.10757],
            [126.7285, 35.10748],
            [126.72842, 35.10743],
            [126.72798, 35.10744],
            [126.72713, 35.10757],
            [126.7268, 35.1077],
            [126.7261, 35.10788],
            [126.72531, 35.108],
            [126.72491, 35.10801],
            [126.72468, 35.10805],
            [126.72447, 35.10809],
            [126.72359, 35.10835],
            [126.72331, 35.10826],
            [126.72319, 35.10829],
            [126.72288, 35.10827],
            [126.72272, 35.10829],
            [126.72243, 35.10835],
            [126.72212, 35.10829],
            [126.72167, 35.10826],
            [126.72122, 35.10829],
            [126.72109, 35.10828],
            [126.72088, 35.10821],
            [126.71987, 35.1083],
            [126.71943, 35.10838],
            [126.71909, 35.10851],
            [126.71896, 35.1086],
            [126.71743, 35.10984],
            [126.71688, 35.11015],
            [126.7168, 35.11029],
            [126.71667, 35.11038],
            [126.71633, 35.1105],
            [126.71593, 35.11071],
            [126.7158, 35.11073],
            [126.7155, 35.11075],
            [126.71494, 35.11057],
            [126.71475, 35.11053],
            [126.71388, 35.11023],
            [126.71362, 35.11016],
            [126.71342, 35.11007],
            [126.713, 35.10997],
            [126.71277, 35.10989],
            [126.71235, 35.10985],
            [126.71139, 35.10986],
            [126.71102, 35.10984],
            [126.7108, 35.10984],
            [126.71064, 35.10975],
            [126.7104, 35.10967],
            [126.70997, 35.10948],
            [126.70914, 35.10945],
            [126.70913, 35.1095],
            [126.70899, 35.1096],
            [126.70892, 35.10957],
            [126.70883, 35.10948],
            [126.70865, 35.10952],
            [126.70838, 35.10981],
            [126.70811, 35.11005],
            [126.70788, 35.11033],
            [126.70756, 35.11062],
            [126.70716, 35.11107],
            [126.70679, 35.11142],
            [126.70639, 35.11199],
            [126.70589, 35.11256],
            [126.70556, 35.11285],
            [126.7054, 35.11289],
            [126.70518, 35.11282],
            [126.70505, 35.11282],
            [126.70502, 35.11289],
            [126.70459, 35.11276],
            [126.70394, 35.11272],
            [126.70337, 35.11274],
            [126.70264, 35.1127],
            [126.70232, 35.11262],
            [126.70206, 35.11249],
            [126.70161, 35.11232],
            [126.70167, 35.11216],
            [126.70179, 35.11196],
            [126.70173, 35.11193],
            [126.70149, 35.11168],
            [126.70116, 35.11139],
            [126.70117, 35.11132],
            [126.70094, 35.11089],
            [126.70085, 35.11081],
            [126.70074, 35.11059],
            [126.70041, 35.11027],
            [126.70037, 35.11015],
            [126.70018, 35.10991],
            [126.70014, 35.10975],
            [126.7001, 35.10945],
            [126.70004, 35.10933],
            [126.70007, 35.10913],
            [126.70005, 35.10907],
            [126.69994, 35.10899],
            [126.69986, 35.10895],
            [126.69955, 35.10886],
            [126.69945, 35.10879],
            [126.69917, 35.10871],
            [126.69884, 35.1087],
            [126.69867, 35.10867],
            [126.69861, 35.10868],
            [126.69838, 35.10856],
            [126.69831, 35.10851],
            [126.69823, 35.10848],
            [126.69841, 35.10825],
            [126.69839, 35.10799],
            [126.69829, 35.10793],
            [126.69754, 35.10759],
            [126.69702, 35.10756],
            [126.69649, 35.10768],
            [126.69621, 35.10751],
            [126.69597, 35.10749],
            [126.69569, 35.10767],
            [126.69554, 35.10789],
            [126.69534, 35.1083],
            [126.69502, 35.10865],
            [126.69447, 35.10881],
            [126.69414, 35.10894],
            [126.69391, 35.10907],
            [126.69383, 35.10904],
            [126.69361, 35.10905],
            [126.69347, 35.109],
            [126.69335, 35.10894],
            [126.69325, 35.10887],
            [126.69325, 35.10879],
            [126.69318, 35.10854],
            [126.69314, 35.10846],
            [126.69299, 35.10839],
            [126.69274, 35.10832],
            [126.69243, 35.10818],
            [126.69219, 35.10812],
            [126.6923, 35.10825],
            [126.69229, 35.1083],
            [126.69223, 35.10846],
            [126.69216, 35.1086],
            [126.69194, 35.10891],
            [126.69186, 35.10911],
            [126.69189, 35.10931],
            [126.69188, 35.10965],
            [126.69168, 35.11006],
            [126.69146, 35.11033],
            [126.69108, 35.11058],
            [126.69045, 35.11094],
            [126.68988, 35.11122],
            [126.68915, 35.11103],
            [126.68877, 35.11102],
            [126.68856, 35.11105],
            [126.68742, 35.11148],
            [126.68701, 35.11161],
            [126.68676, 35.11175],
            [126.68644, 35.11176],
            [126.68623, 35.11178],
            [126.68561, 35.112],
            [126.68553, 35.11199],
            [126.68527, 35.11202],
            [126.6849, 35.11201],
            [126.68386, 35.11169],
            [126.68341, 35.11153],
            [126.68382, 35.11103],
            [126.68427, 35.11008],
            [126.68413, 35.11005],
            [126.68505, 35.10842],
            [126.68496, 35.10842],
            [126.68483, 35.10847],
            [126.68477, 35.10848],
            [126.68449, 35.10845],
            [126.68445, 35.10825],
            [126.68443, 35.10799],
            [126.68446, 35.10787],
            [126.68453, 35.1078],
            [126.68455, 35.10764],
            [126.68384, 35.10752],
            [126.68372, 35.10747],
            [126.68358, 35.10744],
            [126.68345, 35.10745],
            [126.68305, 35.10739],
            [126.68248, 35.10739],
            [126.68193, 35.10743],
            [126.6817, 35.10736],
            [126.68129, 35.10745],
            [126.68, 35.10754],
            [126.6799, 35.10739],
            [126.67962, 35.10718],
            [126.67935, 35.10698],
            [126.67926, 35.10685],
            [126.67924, 35.10668],
            [126.67917, 35.10653],
            [126.67908, 35.10637],
            [126.679, 35.10631],
            [126.67885, 35.10615],
            [126.67885, 35.10606],
            [126.67884, 35.10599],
            [126.67875, 35.10584],
            [126.67864, 35.10595],
            [126.67844, 35.10597],
            [126.67828, 35.106],
            [126.67816, 35.10596],
            [126.67805, 35.10587],
            [126.67778, 35.10585],
            [126.67773, 35.10583],
            [126.67753, 35.10564],
            [126.6775, 35.10559],
            [126.6775, 35.10553],
            [126.67731, 35.10546],
            [126.67731, 35.10538],
            [126.67723, 35.10538],
            [126.67724, 35.10529],
            [126.67707, 35.10526],
            [126.67667, 35.10504],
            [126.67625, 35.10484],
            [126.67571, 35.10496],
            [126.67513, 35.10493],
            [126.67498, 35.10506],
            [126.67332, 35.10514],
            [126.67319, 35.10502],
            [126.67298, 35.10495],
            [126.67253, 35.10474],
            [126.67064, 35.10483],
            [126.66942, 35.1046],
            [126.66863, 35.10479],
            [126.66816, 35.10503],
            [126.66749, 35.10512],
            [126.66754, 35.10562],
            [126.66716, 35.10609],
            [126.667, 35.10644],
            [126.66698, 35.10654],
            [126.6679, 35.10669],
            [126.66803, 35.10685],
            [126.66844, 35.10753],
            [126.66896, 35.10788],
            [126.66911, 35.10902],
            [126.6691, 35.11002],
            [126.66911, 35.11126],
            [126.66927, 35.11207],
            [126.66798, 35.11193],
            [126.66785, 35.11201],
            [126.66688, 35.11229],
            [126.66661, 35.11241],
            [126.66636, 35.11273],
            [126.66598, 35.11342],
            [126.66532, 35.11394],
            [126.66515, 35.11398],
            [126.66505, 35.11405],
            [126.66449, 35.11413],
            [126.66386, 35.11455],
            [126.6634, 35.11482],
            [126.66259, 35.1147],
            [126.6622, 35.11446],
            [126.66151, 35.11411],
            [126.66118, 35.11397],
            [126.66088, 35.11383],
            [126.66057, 35.11384],
            [126.65995, 35.11354],
            [126.65974, 35.11356],
            [126.65822, 35.11351],
            [126.65754, 35.11326],
            [126.65704, 35.1133],
            [126.65678, 35.11347],
            [126.65628, 35.11384],
            [126.65519, 35.11485],
            [126.65514, 35.1148],
            [126.65509, 35.11483],
            [126.65511, 35.11499],
            [126.65511, 35.11566],
            [126.65518, 35.11605],
            [126.65529, 35.11694],
            [126.65501, 35.11747],
            [126.65488, 35.11779],
            [126.65445, 35.11803],
            [126.65362, 35.11843],
            [126.65269, 35.11865],
            [126.65249, 35.11896],
            [126.65221, 35.11929],
            [126.65193, 35.11965],
            [126.65166, 35.12041],
            [126.6525, 35.12166],
            [126.65262, 35.12196],
            [126.65246, 35.12232],
            [126.65233, 35.12271],
            [126.65223, 35.12341],
            [126.65198, 35.12388],
            [126.65185, 35.12454],
            [126.65174, 35.12469],
            [126.65217, 35.12555],
            [126.65269, 35.12599],
            [126.65313, 35.12655],
            [126.65304, 35.12684],
            [126.6531, 35.12697],
            [126.65326, 35.12778],
            [126.65324, 35.12825],
            [126.65333, 35.12868],
            [126.65358, 35.12932],
            [126.65355, 35.12965],
            [126.6536, 35.1305],
            [126.65386, 35.1309],
            [126.65402, 35.13108],
            [126.65416, 35.13119],
            [126.65372, 35.13187],
            [126.65352, 35.13209],
            [126.6538, 35.13256],
            [126.65405, 35.13295],
            [126.65445, 35.13347],
            [126.65451, 35.13397],
            [126.65448, 35.13425],
            [126.65454, 35.13467],
            [126.65457, 35.13561],
            [126.65462, 35.13608],
            [126.65468, 35.13662],
            [126.65479, 35.13694],
            [126.65485, 35.13704],
            [126.65484, 35.1371],
            [126.6546, 35.13715],
            [126.65418, 35.13735],
            [126.65391, 35.13801],
            [126.65374, 35.139],
            [126.65348, 35.13923],
            [126.65326, 35.13948],
            [126.65301, 35.1406],
            [126.65283, 35.14091],
            [126.65292, 35.1418],
            [126.65291, 35.14298],
            [126.6531, 35.14338],
            [126.65333, 35.14396],
            [126.6517, 35.14399],
            [126.65089, 35.14427],
            [126.65053, 35.14437],
            [126.64863, 35.14401],
            [126.64841, 35.144],
            [126.64768, 35.14408],
            [126.64762, 35.14403],
            [126.64749, 35.14409],
            [126.64731, 35.14423],
            [126.64608, 35.14547],
            [126.64568, 35.14593],
            [126.64512, 35.14643],
            [126.64505, 35.14646],
            [126.64498, 35.14655],
            [126.64471, 35.14661],
            [126.64471, 35.14667],
            [126.64553, 35.14666],
            [126.64675, 35.14688],
            [126.64789, 35.14695],
            [126.64863, 35.14707],
            [126.64911, 35.14718],
            [126.64958, 35.1474],
            [126.65016, 35.14764],
            [126.65055, 35.14796],
            [126.65088, 35.14831],
            [126.65097, 35.14863],
            [126.65093, 35.14881],
            [126.6511, 35.14896],
            [126.65086, 35.14949],
            [126.6507, 35.14992],
            [126.65067, 35.15013],
            [126.65129, 35.1507],
            [126.65185, 35.15106],
            [126.6522, 35.15131],
            [126.65238, 35.15146],
            [126.65256, 35.15176],
            [126.65332, 35.15254],
            [126.65351, 35.1528],
            [126.65358, 35.1531],
            [126.65294, 35.15359],
            [126.65178, 35.15425],
            [126.65143, 35.15487],
            [126.65173, 35.15556],
            [126.65225, 35.15611],
            [126.65304, 35.15645],
            [126.65341, 35.15701],
            [126.65369, 35.15748],
            [126.65454, 35.15829],
            [126.65471, 35.15839],
            [126.65499, 35.1586],
            [126.65536, 35.15885],
            [126.65523, 35.1596],
            [126.65431, 35.16008],
            [126.65411, 35.16029],
            [126.65399, 35.16054],
            [126.65379, 35.16104],
            [126.65367, 35.16128],
            [126.65416, 35.16228],
            [126.65415, 35.16304],
            [126.654, 35.16384],
            [126.65398, 35.16418],
            [126.65424, 35.16453],
            [126.65474, 35.16493],
            [126.65492, 35.16513],
            [126.6551, 35.1652],
            [126.65553, 35.1655],
            [126.6558, 35.1658],
            [126.65709, 35.16655],
            [126.65836, 35.16676],
            [126.65854, 35.16675],
            [126.65868, 35.16672],
            [126.65896, 35.16663],
            [126.65906, 35.16656],
            [126.65961, 35.16638],
            [126.66071, 35.16661],
            [126.66157, 35.16652],
            [126.66258, 35.16639],
            [126.66257, 35.16644],
            [126.66264, 35.16642],
            [126.66261, 35.16692],
            [126.66267, 35.16716],
            [126.66279, 35.16736],
            [126.66347, 35.16781],
            [126.66363, 35.16796],
            [126.66415, 35.16831],
            [126.66437, 35.16838],
            [126.66462, 35.16842],
            [126.6648, 35.16842],
            [126.6654, 35.16867],
            [126.66594, 35.1688],
            [126.66584, 35.16886],
            [126.66608, 35.16889],
            [126.66614, 35.1689],
            [126.66622, 35.16884],
            [126.66747, 35.16891],
            [126.66772, 35.16907],
            [126.66799, 35.16908],
            [126.6681, 35.16912],
            [126.66861, 35.16925],
            [126.66892, 35.1694],
            [126.66936, 35.16941],
            [126.66935, 35.16947],
            [126.66949, 35.16951],
            [126.66974, 35.16952],
            [126.66975, 35.16944],
            [126.67023, 35.16946],
            [126.67053, 35.16946],
            [126.67079, 35.16992],
            [126.67146, 35.17084],
            [126.67147, 35.171],
            [126.67145, 35.17132],
            [126.67157, 35.17152],
            [126.67161, 35.17181],
            [126.672, 35.17208],
            [126.67205, 35.17232],
            [126.67208, 35.17238],
            [126.67202, 35.17253],
            [126.67206, 35.17264],
            [126.67209, 35.17273],
            [126.67206, 35.17277],
            [126.67206, 35.17288],
            [126.67215, 35.17317],
            [126.67184, 35.17342],
            [126.67157, 35.17376],
            [126.67131, 35.17448],
            [126.67089, 35.17488],
            [126.67053, 35.17509],
            [126.67014, 35.1751],
            [126.66882, 35.17488],
            [126.6685, 35.17492],
            [126.66825, 35.17544],
            [126.66798, 35.1758],
            [126.66738, 35.17635],
            [126.66712, 35.17673],
            [126.66683, 35.17688],
            [126.66642, 35.17755],
            [126.66598, 35.17793],
            [126.66565, 35.17816],
            [126.66536, 35.17852],
            [126.66515, 35.17883],
            [126.66507, 35.17922],
            [126.66507, 35.17928],
            [126.66423, 35.17958],
            [126.66322, 35.18012],
            [126.66311, 35.18023],
            [126.66269, 35.18048],
            [126.66271, 35.18066],
            [126.66261, 35.18096],
            [126.66271, 35.18139],
            [126.66279, 35.18154],
            [126.66308, 35.18163],
            [126.66327, 35.18176],
            [126.66365, 35.18224],
            [126.6637, 35.1823],
            [126.66335, 35.18257],
            [126.66294, 35.18294],
            [126.66271, 35.18327],
            [126.66236, 35.18362],
            [126.66186, 35.18398],
            [126.66173, 35.1842],
            [126.66169, 35.18441],
            [126.66179, 35.18476],
            [126.66127, 35.18529],
            [126.66024, 35.18568],
            [126.65952, 35.18586],
            [126.65923, 35.18604],
            [126.65855, 35.18612],
            [126.65793, 35.18635],
            [126.65753, 35.18681],
            [126.65738, 35.18733],
            [126.65704, 35.18767],
            [126.65673, 35.18779],
            [126.65635, 35.18813],
            [126.65593, 35.18842],
            [126.65541, 35.18903],
            [126.65545, 35.18927],
            [126.65539, 35.1896],
            [126.65514, 35.18985],
            [126.65489, 35.18998],
            [126.65489, 35.19005],
            [126.65476, 35.19017],
            [126.65476, 35.19053],
            [126.65461, 35.19081],
            [126.65429, 35.19083],
            [126.65407, 35.19098],
            [126.65398, 35.19153],
            [126.65327, 35.19256],
            [126.65337, 35.19261],
            [126.6535, 35.19275],
            [126.6539, 35.19294],
            [126.65404, 35.1931],
            [126.6545, 35.19335],
            [126.6548, 35.19343],
            [126.65522, 35.1936],
            [126.65544, 35.19377],
            [126.65593, 35.19385],
            [126.65627, 35.1941],
            [126.6567, 35.19377],
            [126.657, 35.19343],
            [126.65766, 35.19319],
            [126.6583, 35.19289],
            [126.65885, 35.19239],
            [126.65909, 35.19225],
            [126.65951, 35.1926],
            [126.65981, 35.1927],
            [126.66014, 35.19302],
            [126.66078, 35.1933],
            [126.66091, 35.19339],
            [126.66172, 35.1937],
            [126.66195, 35.19374],
            [126.66209, 35.19375],
            [126.66231, 35.19375],
            [126.6625, 35.19373],
            [126.66277, 35.19366],
            [126.66307, 35.1935],
            [126.66304, 35.19338],
            [126.66297, 35.19319],
            [126.66298, 35.19313],
            [126.66301, 35.19295],
            [126.66318, 35.19268],
            [126.66328, 35.19258],
            [126.66333, 35.19253],
            [126.66349, 35.19248],
            [126.66374, 35.19246],
            [126.66386, 35.19242],
            [126.66419, 35.19238],
            [126.66435, 35.19241],
            [126.66444, 35.19248],
            [126.66462, 35.19253],
            [126.66472, 35.19258],
            [126.66491, 35.19259],
            [126.66518, 35.19256],
            [126.66528, 35.19253],
            [126.66538, 35.19256],
            [126.66551, 35.19265],
            [126.66559, 35.19267],
            [126.66571, 35.19273],
            [126.66581, 35.19283],
            [126.66592, 35.19291],
            [126.66602, 35.19297],
            [126.66619, 35.1931],
            [126.66632, 35.19313],
            [126.66638, 35.19318],
            [126.66658, 35.19323],
            [126.66679, 35.19335],
            [126.66682, 35.19325],
            [126.66699, 35.19308],
            [126.66771, 35.19269],
            [126.66782, 35.19271],
            [126.6681, 35.19264],
            [126.66816, 35.19265],
            [126.66822, 35.19275],
            [126.66841, 35.19285],
            [126.66844, 35.19292],
            [126.66844, 35.193],
            [126.66867, 35.19319],
            [126.66897, 35.1935],
            [126.66927, 35.19389],
            [126.6696, 35.19437],
            [126.66963, 35.19444],
            [126.66975, 35.19435],
            [126.67028, 35.19414],
            [126.67077, 35.19407],
            [126.67082, 35.19401],
            [126.67119, 35.19373],
            [126.6713, 35.1937],
            [126.67163, 35.19388],
            [126.67183, 35.19397],
            [126.6721, 35.19421],
            [126.6725, 35.19389],
            [126.67276, 35.1933],
            [126.67291, 35.19289],
            [126.67326, 35.19299],
            [126.67371, 35.19293],
            [126.67378, 35.19259],
            [126.6739, 35.19232],
            [126.67394, 35.19213],
            [126.67439, 35.1912],
            [126.67482, 35.19108],
            [126.67516, 35.19102],
            [126.6756, 35.19083],
            [126.6757, 35.19071],
            [126.67593, 35.19062],
            [126.67711, 35.19099],
            [126.67718, 35.19107],
            [126.67721, 35.19139],
            [126.67726, 35.1915],
            [126.67729, 35.19166],
            [126.67727, 35.19188],
            [126.67728, 35.19219],
            [126.67713, 35.19267],
            [126.67723, 35.19278],
            [126.67738, 35.19288],
            [126.67753, 35.19294],
            [126.67759, 35.19301],
            [126.67763, 35.19314],
            [126.67766, 35.19338],
            [126.6777, 35.1935],
            [126.67771, 35.19362],
            [126.67784, 35.19376],
            [126.67772, 35.19393],
            [126.67739, 35.19467],
            [126.67718, 35.19527],
            [126.67707, 35.19546],
            [126.67659, 35.19598],
            [126.67651, 35.19622],
            [126.67649, 35.19656],
            [126.67688, 35.197],
            [126.67713, 35.1972],
            [126.67734, 35.19744],
            [126.6779, 35.19833],
            [126.67803, 35.19878],
            [126.67812, 35.19943],
            [126.6781, 35.19958],
            [126.67795, 35.2],
            [126.67781, 35.20034],
            [126.67773, 35.20059],
            [126.67757, 35.20084],
            [126.6774, 35.20113],
            [126.67737, 35.20137],
            [126.67751, 35.20206],
            [126.67758, 35.20254],
            [126.67773, 35.20286],
            [126.67807, 35.20317],
            [126.67878, 35.20369],
            [126.67903, 35.20374],
            [126.67937, 35.20374],
            [126.67965, 35.20378],
            [126.67999, 35.20406],
            [126.68038, 35.20477],
            [126.68064, 35.2052],
            [126.68077, 35.20577],
            [126.68095, 35.20641],
            [126.68056, 35.20685],
            [126.68051, 35.20693],
            [126.68047, 35.20701],
            [126.68046, 35.20734],
            [126.68067, 35.20753],
            [126.6808, 35.20761],
            [126.6808, 35.20781],
            [126.68069, 35.20799],
            [126.68074, 35.20809],
            [126.68087, 35.20818],
            [126.68101, 35.20837],
            [126.68112, 35.20844],
            [126.68121, 35.20848],
            [126.68139, 35.20844],
            [126.68159, 35.20836],
            [126.6817, 35.20837],
            [126.68183, 35.20843],
            [126.68196, 35.20845],
            [126.68212, 35.20847],
            [126.68257, 35.2086],
            [126.68271, 35.20875],
            [126.68288, 35.20882],
            [126.68301, 35.20883],
            [126.68344, 35.20904],
            [126.6839, 35.20941],
            [126.68418, 35.20957],
            [126.68422, 35.20964],
            [126.68454, 35.20984],
            [126.68462, 35.21008],
            [126.68463, 35.21029],
            [126.6845, 35.21088],
            [126.68453, 35.21094],
            [126.68461, 35.21099],
            [126.6847, 35.21111],
            [126.68485, 35.21113],
            [126.68499, 35.21194],
            [126.68523, 35.21214],
            [126.68534, 35.21253],
            [126.68564, 35.21289],
            [126.68559, 35.21328],
            [126.68569, 35.21362],
            [126.68594, 35.21388],
            [126.68613, 35.21423],
            [126.68663, 35.21479],
            [126.68708, 35.2152],
            [126.68714, 35.21516],
            [126.68792, 35.21495],
            [126.68793, 35.21501],
            [126.68793, 35.21483],
            [126.68798, 35.21475],
            [126.68822, 35.21469],
            [126.68845, 35.21455],
            [126.68869, 35.21448],
            [126.68874, 35.21446],
            [126.68876, 35.21474],
            [126.68883, 35.21489],
            [126.68903, 35.21477],
            [126.68905, 35.2147],
            [126.68917, 35.21457],
            [126.68923, 35.21474],
            [126.68933, 35.21491],
            [126.6894, 35.21495],
            [126.68944, 35.21501],
            [126.6895, 35.21502],
            [126.68972, 35.21497],
            [126.68989, 35.21496],
            [126.69022, 35.215],
            [126.69043, 35.215],
            [126.69044, 35.21494],
            [126.69056, 35.21472],
            [126.69069, 35.21438],
            [126.69082, 35.2142],
            [126.69102, 35.21409],
            [126.69124, 35.21401],
            [126.69131, 35.21397],
            [126.69144, 35.21382],
            [126.69145, 35.21371],
            [126.69127, 35.21351],
            [126.69152, 35.21311],
            [126.69182, 35.21304],
            [126.69213, 35.21283],
            [126.69246, 35.21256],
            [126.6926, 35.21248],
            [126.69288, 35.21241],
            [126.69298, 35.21235],
            [126.6932, 35.21214],
            [126.69327, 35.21211],
            [126.69337, 35.21198],
            [126.69342, 35.21187],
            [126.69359, 35.21174],
            [126.6937, 35.21161],
            [126.6938, 35.21153],
            [126.69396, 35.21149],
            [126.6942, 35.2115],
            [126.69454, 35.21135],
            [126.69472, 35.2113],
            [126.69504, 35.21114],
            [126.69522, 35.21086],
            [126.69533, 35.2106],
            [126.69555, 35.21042],
            [126.69565, 35.21038],
            [126.69585, 35.21034],
            [126.69601, 35.21025],
            [126.6962, 35.21013],
            [126.69661, 35.20978],
            [126.69673, 35.20961],
            [126.69679, 35.20947],
            [126.69697, 35.20931],
            [126.697, 35.20927],
            [126.6976, 35.20935],
            [126.69803, 35.20948],
            [126.69853, 35.20947],
            [126.69931, 35.20922],
            [126.69986, 35.20894],
            [126.69988, 35.20849],
            [126.7001, 35.20846],
            [126.70124, 35.20823],
            [126.7026, 35.20757],
            [126.70296, 35.20769],
            [126.70337, 35.20827],
            [126.70378, 35.20858],
            [126.70418, 35.2086],
            [126.7046, 35.20872],
            [126.70525, 35.20922],
            [126.70536, 35.20962],
            [126.70553, 35.20993],
            [126.70585, 35.20992],
            [126.70621, 35.21024],
            [126.70708, 35.21012],
            [126.70778, 35.21044],
            [126.70961, 35.21165],
            [126.70994, 35.21189],
            [126.7107, 35.21227],
            [126.71134, 35.21217],
            [126.71183, 35.21205],
            [126.71244, 35.21217],
            [126.71296, 35.21215],
            [126.71316, 35.2121],
            [126.71378, 35.21225],
            [126.71417, 35.21245],
            [126.71435, 35.21242],
            [126.71448, 35.21234],
            [126.71503, 35.21204],
            [126.71568, 35.2121],
            [126.71652, 35.21226],
            [126.71724, 35.21244],
            [126.7177, 35.21241],
            [126.71852, 35.21322],
            [126.71856, 35.21355],
            [126.71835, 35.21419],
            [126.71832, 35.21482],
            [126.71885, 35.21569],
            [126.7199, 35.21581],
            [126.72018, 35.21602],
            [126.72012, 35.21694],
            [126.72029, 35.21745],
            [126.72057, 35.21778],
            [126.72051, 35.2185],
            [126.72074, 35.21883],
            [126.72061, 35.21897],
            [126.7197, 35.22035],
            [126.71915, 35.2213],
            [126.71912, 35.22136],
            [126.71849, 35.22178],
            [126.71875, 35.22255],
            [126.71949, 35.22392],
            [126.71981, 35.22462],
            [126.7199, 35.2251],
            [126.72008, 35.22524],
            [126.71988, 35.22613],
            [126.71988, 35.22672],
            [126.7205, 35.227],
            [126.72066, 35.22718],
            [126.7214, 35.22743],
            [126.72172, 35.22773],
            [126.72189, 35.22766],
            [126.72221, 35.22765],
            [126.72256, 35.22811],
            [126.72282, 35.22835],
            [126.72329, 35.22868],
            [126.72414, 35.23014],
            [126.72358, 35.23066],
            [126.72414, 35.23172],
            [126.72418, 35.23232],
            [126.72469, 35.23253],
            [126.72495, 35.23274],
            [126.725, 35.23322],
            [126.72491, 35.23362],
            [126.72519, 35.23421],
            [126.72544, 35.23455],
            [126.72568, 35.23492],
            [126.72616, 35.23498],
            [126.72641, 35.23504],
            [126.72645, 35.23518],
            [126.72634, 35.23576],
            [126.72674, 35.23606],
            [126.727, 35.23645],
            [126.72791, 35.23632],
            [126.7291, 35.23724],
            [126.72937, 35.23776],
            [126.72935, 35.23811],
            [126.72961, 35.23876],
            [126.72995, 35.23904],
            [126.73013, 35.23993],
            [126.73037, 35.24044],
            [126.73042, 35.24061],
            [126.73041, 35.24066],
            [126.73043, 35.24071],
            [126.73036, 35.24079],
            [126.73033, 35.24086],
            [126.73027, 35.24117],
            [126.7302, 35.24136],
            [126.73015, 35.24166],
            [126.73014, 35.24179],
            [126.73015, 35.24209],
            [126.73012, 35.24222],
            [126.73004, 35.24239],
            [126.73006, 35.24257],
            [126.73003, 35.2427],
            [126.73, 35.24288],
            [126.72997, 35.24296],
            [126.72996, 35.24316],
            [126.7299, 35.24329],
            [126.7299, 35.24339],
            [126.72994, 35.24346],
            [126.72996, 35.2436],
            [126.73008, 35.24377],
            [126.73018, 35.24385],
            [126.73064, 35.24411],
            [126.73077, 35.24423],
            [126.73097, 35.24442],
            [126.73106, 35.24434],
            [126.7313, 35.24463],
            [126.73139, 35.24482],
            [126.7314, 35.24487],
            [126.73146, 35.24493],
            [126.73158, 35.24511],
            [126.73165, 35.24517],
            [126.73187, 35.24534],
            [126.73179, 35.24547],
            [126.73178, 35.24552],
            [126.73185, 35.24572],
            [126.73186, 35.2458],
            [126.73213, 35.24615],
            [126.73224, 35.24623],
            [126.73239, 35.24643],
            [126.73233, 35.24652],
            [126.7328, 35.24691],
            [126.73295, 35.24705],
            [126.73293, 35.24719],
            [126.733, 35.24723],
            [126.73314, 35.24739],
            [126.73349, 35.24761],
            [126.7336, 35.2478],
            [126.7337, 35.24789],
            [126.73373, 35.24798],
            [126.73383, 35.24807],
            [126.73397, 35.24814],
            [126.73409, 35.24836],
            [126.73435, 35.24864],
            [126.73473, 35.24917],
            [126.73478, 35.24927],
            [126.73499, 35.2494],
            [126.73517, 35.24944],
            [126.73528, 35.24949],
            [126.73553, 35.24985],
            [126.73565, 35.24998],
            [126.73575, 35.25013],
            [126.73584, 35.25024],
            [126.73597, 35.25045],
            [126.73625, 35.25069],
            [126.73632, 35.25077],
            [126.73647, 35.25152],
            [126.7365, 35.25162],
            [126.73655, 35.25172],
            [126.73661, 35.25175],
            [126.73681, 35.25174],
            [126.73704, 35.25184],
            [126.7371, 35.25187],
            [126.73715, 35.25193],
            [126.73719, 35.25208],
            [126.73718, 35.2522],
            [126.73725, 35.25258],
            [126.73724, 35.25288],
            [126.73739, 35.25282],
            [126.73771, 35.25276],
            [126.73776, 35.25273],
            [126.73782, 35.25271],
            [126.73809, 35.25273],
            [126.73813, 35.25269],
            [126.7381, 35.25259],
            [126.73827, 35.25251],
            [126.73835, 35.25245],
            [126.73837, 35.25239],
            [126.73835, 35.25228],
            [126.73839, 35.25209],
            [126.7387, 35.25198],
            [126.73864, 35.25182],
            [126.73883, 35.25177],
            [126.73888, 35.25179],
            [126.73909, 35.25176],
            [126.73947, 35.25168],
            [126.73954, 35.25157],
            [126.73969, 35.25147],
            [126.73991, 35.25136],
            [126.74044, 35.25116],
            [126.7411, 35.25105],
            [126.7413, 35.25098],
            [126.74132, 35.25091],
            [126.74177, 35.25081],
            [126.74226, 35.2508],
            [126.74244, 35.25095],
            [126.74268, 35.25131],
            [126.74275, 35.25138],
            [126.74279, 35.2515],
            [126.74275, 35.25156],
            [126.74291, 35.25176],
            [126.7429, 35.25181],
            [126.74307, 35.25175],
            [126.74323, 35.25166],
            [126.7434, 35.25158],
            [126.74359, 35.25164],
            [126.74378, 35.25165],
            [126.74399, 35.25171],
            [126.74437, 35.25206],
            [126.74472, 35.25222],
            [126.74486, 35.25232],
            [126.74494, 35.25236],
            [126.74504, 35.25236],
            [126.74514, 35.25241],
            [126.74525, 35.2524],
            [126.74534, 35.25234],
            [126.74577, 35.25227],
            [126.74616, 35.25247],
            [126.74727, 35.25294],
            [126.74741, 35.25287],
            [126.74792, 35.25307],
            [126.74841, 35.25333],
            [126.74885, 35.25408],
            [126.74895, 35.25423],
            [126.74901, 35.25427],
            [126.74913, 35.25462],
            [126.74918, 35.25484],
            [126.74932, 35.25514],
            [126.74948, 35.25588],
            [126.74996, 35.25605],
            [126.75037, 35.25607],
            [126.75056, 35.25641],
            [126.75098, 35.25649],
            [126.75146, 35.25664],
            [126.7517, 35.25666],
            [126.75194, 35.25676],
            [126.75229, 35.25678],
            [126.7527, 35.25687],
            [126.75418, 35.25658],
            [126.75506, 35.25647],
            [126.75524, 35.25661],
            [126.75545, 35.25685],
            [126.75567, 35.2569],
            [126.75581, 35.25705],
            [126.75612, 35.2573],
            [126.75614, 35.25751],
            [126.7569, 35.25762],
            [126.75711, 35.25781],
            [126.75791, 35.25838],
            [126.75842, 35.2583],
            [126.75899, 35.25839],
            [126.75916, 35.25846],
            [126.7594, 35.25863],
            [126.75954, 35.2586],
            [126.76029, 35.25871]
          ]
        ]
      }
    }
  ]
};
