import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import PredictiveAnalysisChart from './PredictiveAnalysisChart';
import { chainAxios } from 'helpers/chainAxios';
import {
  useSingleMultiAnalysis,
  useSingleMultiAnalysis2
} from '../hook/useDashboard';
import FalconCardHeader from '../../common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUp from 'react-countup';
import { isEmpty } from '../../../helpers/utils';
import Flex from '../../common/Flex';

const PredictiveAnalysis = () => {
  // const { data: analysis, isLoading, isFetching } = useSingleMultiAnalysis();
  const { data: analysis, isLoading, isFetching } = useSingleMultiAnalysis2();
  const [groundAnalysis, setGroudAnalysis] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [singleCount, setSingleCount] = useState(0);
  const [singleAbnormal, setSingleAbnormal] = useState(0);

  useEffect(() => {
    // makeSingleMultiAnalysisData();
    makeSingleMultiAnalysisData2();
  }, []);

  useEffect(() => {
    if (!isFetching) {
      // makeSingleMultiAnalysisData();
      makeSingleMultiAnalysisData2();
    } else {
      setTotalCount(0);
      setSingleCount(0);

      setGroudAnalysis({});
    }
  }, [isFetching]);

  const makeSingleMultiAnalysisData2 = () => {
    let totalCount = 0;
    let singleAbnormal = 0;
    Object.keys(analysis).forEach((key, index) => {
      if (key === 'COUNT') {
        // console.log(analysis[key]);
        totalCount = analysis[key]['single_total'];
      } else {
        let values = analysis[key]['single_abnormal'];
        if (!isEmpty(values)) {
          singleAbnormal += values.split(',').length;
        }
      }
    });

    setTotalCount(totalCount);
    setSingleCount(totalCount - singleAbnormal);

    setSingleAbnormal(singleAbnormal);
    setGroudAnalysis(analysis);
  };

  const makeSingleMultiAnalysisData = () => {
    let totalCount = 0;
    Object.keys(analysis).forEach((key, index) => {
      // console.log(key);
      if (key !== 'single_abnormal') {
        totalCount += analysis[key];
      }
    });
    setTotalCount(totalCount);
    setSingleCount(totalCount);

    setGroudAnalysis(analysis);
  };

  return (
    <Card className="h-md-100">
      <FalconCardHeader title="지반 데이터 분석 현황" light titleTag="h6" />
      {/*<Card.Header className="pb-0">*/}
      {/*  <h6 className="mb-0 pt-2">지반 데이터 분석 현황</h6>*/}
      {/*</Card.Header>*/}
      <Card.Body className="px-xxl-0 pt-4">
        <Row className="g-0">
          <Col xs={4} className={`text-center border-end`}>
            <p className="fs--1 fw-semi-bold mb-0">
              <span className="text-600 fw-normal">총 분석</span>
            </p>
            <Flex
              direction={'row'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <h5 className="mb-1 font-sans-serif">
                <CountUp
                  start={0}
                  end={totalCount}
                  duration={2.75}
                  className="text-700 fs--0"
                  separator=","
                />
              </h5>
              <div className={'fs--1 fw-semi-bold'}>건</div>
            </Flex>
          </Col>
          <Col xs={4} className={`text-center border-end`}>
            <p className="fs--1 fw-semi-bold mb-0">
              <span className="text-600 fw-normal">싱글 분석</span>
            </p>
            <Flex
              direction={'row'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <h5 className="mb-1 font-sans-serif">
                <CountUp
                  start={0}
                  end={singleCount}
                  duration={2.75}
                  className="text-700 fs--0"
                  separator=","
                />
              </h5>
              <div className={'fs--1 fw-semi-bold'}>건</div>
            </Flex>
          </Col>
          <Col xs={4} className={`text-center`}>
            <p className="fs--1 fw-semi-bold mb-0">
              <span className="text-600 fw-normal">싱글 이상 현황</span>
            </p>
            <Flex
              direction={'row'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <h5 className="mb-1 font-sans-serif">
                <CountUp
                  start={0}
                  end={singleAbnormal}
                  duration={2.75}
                  className="text-700 fs--0"
                  separator=","
                />
              </h5>
              <div className={'fs--1 fw-semi-bold'}>건</div>
            </Flex>
          </Col>
          <Col xs={12} className={'px-xl-4 py-4'}>
            <PredictiveAnalysisChart groundAnalysisDatas={groundAnalysis} />
          </Col>
        </Row>
        {/*<Row className="justify-content-between g-0 h-100">*/}
        {/*  <Col xs={3} md={3} className="pe-2">*/}
        {/*    <Row className="g-0">*/}
        {/*      <Col xs={'auto'} md={12} className={'border-200 pb-4'}>*/}
        {/*        <h6 className="pb-1 text-700">총 분석</h6>*/}
        {/*        <p className="font-sans-serif lh-1 mb-1 fs-0">{totalCount}건</p>*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*    <Row className="g-0">*/}
        {/*      <Col xs={'auto'} md={12} className={'border-200 pb-4'}>*/}
        {/*        <h6 className="pb-1 text-700">싱글 분석</h6>*/}
        {/*        <p className="font-sans-serif lh-1 mb-1 fs-0">*/}
        {/*          {singleCount}건*/}
        {/*        </p>*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*    <Row className="g-0">*/}
        {/*      <Col xs={'auto'} md={12} className={'border-200 pb-4'}>*/}
        {/*        <h6 className="pb-1 text-700">싱글 이상 현황</h6>*/}
        {/*        <p className="font-sans-serif lh-1 mb-1 fs-0">*/}
        {/*          {groundAnalysis.single_abnormal}건*/}
        {/*        </p>*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*  </Col>*/}
        {/*  <Col xs={9}>*/}
        {/*    <PredictiveAnalysisChart groundAnalysisDatas={groundAnalysis} />*/}
        {/*  </Col>*/}
        {/*</Row>*/}
      </Card.Body>
    </Card>
  );
};

export default PredictiveAnalysis;
