export const ncwStnGeoData = {
  type: 'FeatureCollection',
  name: 'ncwstngeo',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      id: 0,
      properties: {
        DATE: '2024-10-26',
        jiguname: '가평가평',
        gennum: '2225',
        well: '암반',
        pyogo: '61.213',
        area: '한강권역',
        addr: '경기도 가평군 가평읍 읍내리 624-1 가평군 보건소내',
        LON: '127.51046389',
        LAT: '37.83370833',
        insdate: '1995-12-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '130',
        gulDiaBtm: '350',
        cseExHgt: '150',
        cseExDia: '0.0-8.1',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0.0-54.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '54.0-70.0',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [127.51046389, 37.83370833] }
    },
    {
      type: 'Feature',
      id: 1,
      properties: {
        DATE: '2024-10-26',
        jiguname: '가평가평',
        gennum: '2224',
        well: '충적',
        pyogo: '61.267',
        area: '한강권역',
        addr: '경기도 가평군 가평읍 읍내리 624-1 가평군 보건소내',
        LON: '127.51046389',
        LAT: '37.83370833',
        insdate: '1995-12-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '7.5',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-1.5',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0-7.5',
        cseInMat: '',
        strHgt: '',
        strDia: '1.5-7.5',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [127.51046389, 37.83370833] }
    },
    {
      type: 'Feature',
      id: 2,
      properties: {
        DATE: '2024-10-26',
        jiguname: '가평달전_심부2',
        gennum: '737554',
        well: '암반',
        pyogo: '127.34',
        area: '한강권역',
        addr: '경기도 가평군 가평읍 달전리 산59-6',
        LON: '127.51340556',
        LAT: '37.80633333',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-15.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.51340556, 37.80633333] }
    },
    {
      type: 'Feature',
      id: 3,
      properties: {
        DATE: '2024-10-26',
        jiguname: '가평달전_천부',
        gennum: '737553',
        well: '충적',
        pyogo: '127.34',
        area: '한강권역',
        addr: '경기도 가평군 가평읍 달전리 산59-6',
        LON: '127.51340556',
        LAT: '37.80633333',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '18',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-18.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '9.0-18.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.51340556, 37.80633333] }
    },
    {
      type: 'Feature',
      id: 4,
      properties: {
        DATE: '2024-10-26',
        jiguname: '가평북면',
        gennum: '9875',
        well: '암반',
        pyogo: '120.65',
        area: '한강권역',
        addr: '경기도 가평군 북면 목동리 866-2 복지회관내',
        LON: '127.55127222',
        LAT: '37.88623333',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '69',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-12.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-69.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [127.55127222, 37.88623333] }
    },
    {
      type: 'Feature',
      id: 5,
      properties: {
        DATE: '2024-10-26',
        jiguname: '가평상면',
        gennum: '95506',
        well: '암반',
        pyogo: '139.58',
        area: '한강권역',
        addr: '경기도 가평군 상면 임초리 643',
        LON: '127.38146111',
        LAT: '37.77580000',
        insdate: '2005-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-8.8',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '48.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [127.38146111, 37.7758] }
    },
    {
      type: 'Feature',
      id: 6,
      properties: {
        DATE: '2024-10-26',
        jiguname: '가평외서',
        gennum: '87235',
        well: '암반',
        pyogo: '57.9',
        area: '한강권역',
        addr: '경기도 가평군 외서면 고성리 55',
        LON: '127.51620556',
        LAT: '37.70197222',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: '한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-11.8',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '28.0-32.0'
      },
      geometry: { type: 'Point', coordinates: [127.51620556, 37.70197222] }
    },
    {
      type: 'Feature',
      id: 7,
      properties: {
        DATE: '2024-10-26',
        jiguname: '가평현리_심부2',
        gennum: '769723',
        well: '암반',
        pyogo: '122.85',
        area: '한강권역',
        addr: '경기도 가평군 조종면 현리 438 조종중고등학교',
        LON: '127.34725556',
        LAT: '37.81326944',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '86',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-12.7',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-86.0',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '77.0-86.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.34725556, 37.81326944] }
    },
    {
      type: 'Feature',
      id: 8,
      properties: {
        DATE: '2024-10-26',
        jiguname: '가평현리_천부',
        gennum: '769724',
        well: '충적',
        pyogo: '122.848',
        area: '한강권역',
        addr: '경기도 가평군 조종면 현리 438 조종중고등학교',
        LON: '127.34725556',
        LAT: '37.81326944',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '6.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.34725556, 37.81326944] }
    },
    {
      type: 'Feature',
      id: 9,
      properties: {
        DATE: '2024-10-26',
        jiguname: '강릉강동',
        gennum: '639081',
        well: '암반',
        pyogo: '86.61',
        area: '한강권역',
        addr: '강원특별자치도 강릉시 강동면 언별리 1336-3',
        LON: '128.92877778',
        LAT: '37.68827778',
        insdate: '2016-11-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '100',
        cseExHgt: '300',
        cseExDia: '200',
        cseExMat: '0.0-17.6m',
        cseInHgt: '200',
        cseInDia: 'Stainless Steel',
        cseInMat: '0.0-100.0',
        strHgt: '150',
        strDia: 'PVC(VG1)',
        strMat: '40-44'
      },
      geometry: { type: 'Point', coordinates: [128.92877778, 37.68827778] }
    },
    {
      type: 'Feature',
      id: 10,
      properties: {
        DATE: '2024-10-26',
        jiguname: '강릉모전_심부2',
        gennum: '769725',
        well: '암반',
        pyogo: '41.3',
        area: '한강권역',
        addr: '강원특별자치도 강릉시 강동면 모전리 384 강동초등학교',
        LON: '128.96378056',
        LAT: '37.72489444',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '83',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-9.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-83.0',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '77.0-83.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.96378056, 37.72489444] }
    },
    {
      type: 'Feature',
      id: 11,
      properties: {
        DATE: '2024-10-26',
        jiguname: '강릉모전_천부',
        gennum: '769726',
        well: '충적',
        pyogo: '41.3',
        area: '한강권역',
        addr: '강원특별자치도 강릉시 강동면 모전리 384 강동초등학교',
        LON: '128.96378056',
        LAT: '37.72489444',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.96378056, 37.72489444] }
    },
    {
      type: 'Feature',
      id: 12,
      properties: {
        DATE: '2024-10-26',
        jiguname: '강릉연곡',
        gennum: '84007',
        well: '암반',
        pyogo: '44.774',
        area: '한강권역',
        addr: '강원특별자치도 강릉시 연곡면 퇴곡리 720-5',
        LON: '128.74890278',
        LAT: '37.84901944',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-12.0',
        cseInHgt: '250',
        cseInDia: '백관',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '24.0-30.0'
      },
      geometry: { type: 'Point', coordinates: [128.74890278, 37.84901944] }
    },
    {
      type: 'Feature',
      id: 13,
      properties: {
        DATE: '2024-10-26',
        jiguname: '강릉왕산',
        gennum: '82004',
        well: '암반',
        pyogo: '986.53',
        area: '한강권역',
        addr: '강원특별자치도 강릉시 왕산면 대기리 2214-107',
        LON: '128.73894444',
        LAT: '37.62261944',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-20.5',
        cseInHgt: '250',
        cseInDia: 'STAINLES STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P.V.C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [128.73894444, 37.62261944] }
    },
    {
      type: 'Feature',
      id: 14,
      properties: {
        DATE: '2024-10-26',
        jiguname: '강릉홍제',
        gennum: '9865',
        well: '암반',
        pyogo: '20.3',
        area: '한강권역',
        addr: '강원특별자치도 강릉시 홍제동  96-54 노인복지회관내',
        LON: '128.88415278',
        LAT: '37.74751944',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '69',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-16.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-69.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [128.88415278, 37.74751944] }
    },
    {
      type: 'Feature',
      id: 15,
      properties: {
        DATE: '2024-10-26',
        jiguname: '강릉홍제',
        gennum: '9866',
        well: '충적',
        pyogo: '20.36',
        area: '한강권역',
        addr: '강원특별자치도 강릉시 홍제동  96-54 노인복지회관내',
        LON: '128.88415278',
        LAT: '37.74751944',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '11',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-11.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0-11',
        cseInMat: '',
        strHgt: '',
        strDia: '7.0-11.0',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [128.88415278, 37.74751944] }
    },
    {
      type: 'Feature',
      id: 16,
      properties: {
        DATE: '2024-10-26',
        jiguname: '강진남성_심부2',
        gennum: '737556',
        well: '암반',
        pyogo: '12',
        area: '영섬권역',
        addr: '전라남도 강진군 강진읍 남성리 12-4',
        LON: '126.77418333',
        LAT: '34.63586944',
        insdate: '2011-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-32.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '59.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.77418333, 34.63586944] }
    },
    {
      type: 'Feature',
      id: 17,
      properties: {
        DATE: '2024-10-26',
        jiguname: '강진남성_천부',
        gennum: '737555',
        well: '충적',
        pyogo: '12',
        area: '영섬권역',
        addr: '전라남도 강진군 강진읍 남성리 12-4',
        LON: '126.77418333',
        LAT: '34.63586944',
        insdate: '2011-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '13',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-13.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '8.5-13.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.77418333, 34.63586944] }
    },
    {
      type: 'Feature',
      id: 18,
      properties: {
        DATE: '2024-10-26',
        jiguname: '강진성남_심부2',
        gennum: '737558',
        well: '암반',
        pyogo: '39',
        area: '영섬권역',
        addr: '전라남도 강진군 병영면 성남리 189-2',
        LON: '126.81285833',
        LAT: '34.71710556',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-32.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '70.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.81285833, 34.71710556] }
    },
    {
      type: 'Feature',
      id: 19,
      properties: {
        DATE: '2024-10-26',
        jiguname: '강진성남_천부',
        gennum: '737557',
        well: '충적',
        pyogo: '39',
        area: '영섬권역',
        addr: '전라남도 강진군 병영면 성남리 189-2',
        LON: '126.81285833',
        LAT: '34.71710556',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '3.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.81285833, 34.71710556] }
    },
    {
      type: 'Feature',
      id: 20,
      properties: {
        DATE: '2024-10-26',
        jiguname: '강진성전',
        gennum: '65001',
        well: '암반',
        pyogo: '54.88',
        area: '영섬권역',
        addr: '전라남도 강진군 성전면 월평리 66-1 면사무소',
        LON: '126.70916389',
        LAT: '34.69223333',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '67',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-18.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0.0-67.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '44.0-48.0'
      },
      geometry: { type: 'Point', coordinates: [126.70916389, 34.69223333] }
    },
    {
      type: 'Feature',
      id: 21,
      properties: {
        DATE: '2024-10-26',
        jiguname: '강진성전',
        gennum: '65002',
        well: '충적',
        pyogo: '54.9',
        area: '영섬권역',
        addr: '전라남도 강진군 성전면 월평리 66-1 면사무소',
        LON: '126.70916389',
        LAT: '34.69223333',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '8.5',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-8.5',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0-8.5',
        strHgt: '',
        strDia: '',
        strMat: '4.5-8.5'
      },
      geometry: { type: 'Point', coordinates: [126.70916389, 34.69223333] }
    },
    {
      type: 'Feature',
      id: 22,
      properties: {
        DATE: '2024-10-26',
        jiguname: '강진옴천',
        gennum: '601716',
        well: '암반',
        pyogo: '103.62',
        area: '영섬권역',
        addr: '전라남도 강진군 옴천면 개산리 279-3',
        LON: '126.79293333',
        LAT: '34.74481944',
        insdate: '2011-12-14',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜한국건업엔지니어링',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-12.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '36-40',
        strMat: ' 52-66'
      },
      geometry: { type: 'Point', coordinates: [126.79293333, 34.74481944] }
    },
    {
      type: 'Feature',
      id: 23,
      properties: {
        DATE: '2024-10-26',
        jiguname: '강진칠량',
        gennum: '65003',
        well: '암반',
        pyogo: '8.2',
        area: '영섬권역',
        addr: '전라남도 강진군 칠량면 영동리 1137-80 복지회관',
        LON: '126.79826389',
        LAT: '34.57216667',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '66',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-40.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0.0-66.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '44.0-48.0'
      },
      geometry: { type: 'Point', coordinates: [126.79826389, 34.57216667] }
    },
    {
      type: 'Feature',
      id: 24,
      properties: {
        DATE: '2024-10-26',
        jiguname: '강진칠량',
        gennum: '65004',
        well: '충적',
        pyogo: '8.39',
        area: '영섬권역',
        addr: '전라남도 강진군 칠량면 영동리 1137-80 복지회관',
        LON: '126.79826389',
        LAT: '34.57216667',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '12',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-12.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0-12',
        strHgt: '',
        strDia: '',
        strMat: '8.0-12.0'
      },
      geometry: { type: 'Point', coordinates: [126.79826389, 34.57216667] }
    },
    {
      type: 'Feature',
      id: 25,
      properties: {
        DATE: '2024-10-26',
        jiguname: '거제신현',
        gennum: '11783',
        well: '암반',
        pyogo: '140.28',
        area: '낙동강권역',
        addr: '경상남도 거제시 삼거동  529-4 수공 거제사무소내',
        LON: '128.64754722',
        LAT: '34.84820000',
        insdate: '1996-08-30',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '68',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-10.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0.0-68.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '44.0-56.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [128.64754722, 34.8482] }
    },
    {
      type: 'Feature',
      id: 26,
      properties: {
        DATE: '2024-10-26',
        jiguname: '거제신현',
        gennum: '11784',
        well: '충적',
        pyogo: '140.28',
        area: '낙동강권역',
        addr: '경상남도 거제시 삼거동  529-4 수공 거제사무소내',
        LON: '128.64754722',
        LAT: '34.84820000',
        insdate: '1996-08-30',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '10',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-2.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0-10',
        cseInMat: '',
        strHgt: '',
        strDia: '',
        strMat: ''
      },
      geometry: { type: 'Point', coordinates: [128.64754722, 34.8482] }
    },
    {
      type: 'Feature',
      id: 27,
      properties: {
        DATE: '2024-10-26',
        jiguname: '거제연초',
        gennum: '637530',
        well: '암반',
        pyogo: '77.614',
        area: '낙동강권역',
        addr: '경상남도 거제시 연초면 덕치리 산69-3 1',
        LON: '128.65291389',
        LAT: '34.94459167',
        insdate: '2015-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜대동공영',
        gulDiaTop: '110',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-12.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-110.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '32.0-36.0',
        strMat: ' 44.0-48.0'
      },
      geometry: { type: 'Point', coordinates: [128.65291389, 34.94459167] }
    },
    {
      type: 'Feature',
      id: 28,
      properties: {
        DATE: '2024-10-26',
        jiguname: '거창거창',
        gennum: '9878',
        well: '암반',
        pyogo: '204.59',
        area: '낙동강권역',
        addr: '경상남도 거창군 거창읍 학리 1047-62',
        LON: '127.92108333',
        LAT: '35.80672778',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '69',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-10.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-69.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [127.92108333, 35.80672778] }
    },
    {
      type: 'Feature',
      id: 29,
      properties: {
        DATE: '2024-10-26',
        jiguname: '거창거창',
        gennum: '9879',
        well: '충적',
        pyogo: '204.58',
        area: '낙동강권역',
        addr: '경상남도 거창군 거창읍 학리 1047-62',
        LON: '127.92108333',
        LAT: '35.80672778',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '9',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-9.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0-9',
        cseInMat: '',
        strHgt: '',
        strDia: '4.5-8.5',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [127.92108333, 35.80672778] }
    },
    {
      type: 'Feature',
      id: 30,
      properties: {
        DATE: '2024-10-26',
        jiguname: '거창농산_심부2',
        gennum: '737560',
        well: '암반',
        pyogo: '334.3',
        area: '낙동강권역',
        addr: '경상남도 거창군 고제면 농산리 252',
        LON: '127.87353333',
        LAT: '35.81283611',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-14.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '67.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.87353333, 35.81283611] }
    },
    {
      type: 'Feature',
      id: 31,
      properties: {
        DATE: '2024-10-26',
        jiguname: '거창농산_천부',
        gennum: '737559',
        well: '충적',
        pyogo: '334.3',
        area: '낙동강권역',
        addr: '경상남도 거창군 고제면 농산리 252',
        LON: '127.87353333',
        LAT: '35.81283611',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '12',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-12.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '6.0-12.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.87353333, 35.81283611] }
    },
    {
      type: 'Feature',
      id: 32,
      properties: {
        DATE: '2024-10-26',
        jiguname: '거창도평_심부2',
        gennum: '737562',
        well: '암반',
        pyogo: '231.3',
        area: '낙동강권역',
        addr: '경상남도 거창군 주상면 도평리 718',
        LON: '127.91324167',
        LAT: '35.74883889',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '83',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-14.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-83.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '76.0-83.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.91324167, 35.74883889] }
    },
    {
      type: 'Feature',
      id: 33,
      properties: {
        DATE: '2024-10-26',
        jiguname: '거창도평_천부',
        gennum: '737561',
        well: '충적',
        pyogo: '231.3',
        area: '낙동강권역',
        addr: '경상남도 거창군 주상면 도평리 718',
        LON: '127.91324167',
        LAT: '35.74883889',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '5.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.91324167, 35.74883889] }
    },
    {
      type: 'Feature',
      id: 34,
      properties: {
        DATE: '2024-10-26',
        jiguname: '거창마상_심부2',
        gennum: '769727',
        well: '암반',
        pyogo: '279.2',
        area: '낙동강권역',
        addr: '경상남도 거창군 가조면 마상리 336-4 가조초등학교',
        LON: '128.01428889',
        LAT: '35.71045278',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-13.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.01428889, 35.71045278] }
    },
    {
      type: 'Feature',
      id: 35,
      properties: {
        DATE: '2024-10-26',
        jiguname: '거창마상_천부',
        gennum: '769728',
        well: '충적',
        pyogo: '279.2',
        area: '낙동강권역',
        addr: '경상남도 거창군 가조면 마상리 336-4 가조초등학교',
        LON: '128.01428889',
        LAT: '35.71045278',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.01428889, 35.71045278] }
    },
    {
      type: 'Feature',
      id: 36,
      properties: {
        DATE: '2024-10-26',
        jiguname: '거창신원',
        gennum: '82046',
        well: '암반',
        pyogo: '215.361',
        area: '낙동강권역',
        addr: '경상남도 거창군 신원면 수원리 산76-2',
        LON: '127.92352500',
        LAT: '35.72336111',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-8',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'P.V.C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.923525, 35.72336111] }
    },
    {
      type: 'Feature',
      id: 37,
      properties: {
        DATE: '2024-10-26',
        jiguname: '거창웅양',
        gennum: '84040',
        well: '암반',
        pyogo: '299.544',
        area: '낙동강권역',
        addr: '경상남도 거창군 웅양면 노현리  1399-9',
        LON: '127.92108333',
        LAT: '35.80672778',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-14.5',
        cseInHgt: '250',
        cseInDia: '백 관',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '54.0-58.0'
      },
      geometry: { type: 'Point', coordinates: [127.92108333, 35.80672778] }
    },
    {
      type: 'Feature',
      id: 38,
      properties: {
        DATE: '2024-10-26',
        jiguname: '경산남산',
        gennum: '11803',
        well: '암반',
        pyogo: '69.5',
        area: '낙동강권역',
        addr: '경상북도 경산시 남산면 산양리 57 남산면사무소내',
        LON: '128.83690556',
        LAT: '35.79357500',
        insdate: '1996-12-26',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-7.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '26.0-30.0',
        strMat: ' 46.0-50.0'
      },
      geometry: { type: 'Point', coordinates: [128.83690556, 35.793575] }
    },
    {
      type: 'Feature',
      id: 39,
      properties: {
        DATE: '2024-10-26',
        jiguname: '경산남산',
        gennum: '11804',
        well: '충적',
        pyogo: '69.5',
        area: '낙동강권역',
        addr: '경상북도 경산시 남산면 산양리 57 남산면사무소내',
        LON: '128.83690556',
        LAT: '35.79357500',
        insdate: '1996-12-26',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '14',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-2.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0-14',
        cseInMat: '',
        strHgt: '',
        strDia: '2.5-6.5',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [128.83690556, 35.793575] }
    },
    {
      type: 'Feature',
      id: 40,
      properties: {
        DATE: '2024-10-26',
        jiguname: '경산진량',
        gennum: '73532',
        well: '암반',
        pyogo: '75.695',
        area: '낙동강권역',
        addr: '경상북도 경산시 진량읍 다문리 261',
        LON: '128.85045000',
        LAT: '35.87084167',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-4.9',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [128.85045, 35.87084167] }
    },
    {
      type: 'Feature',
      id: 41,
      properties: {
        DATE: '2024-10-26',
        jiguname: '경주건천',
        gennum: '73533',
        well: '암반',
        pyogo: '89.092',
        area: '낙동강권역',
        addr: '경상북도 경주시 건천읍 방내리 1299-1',
        LON: '129.10857222',
        LAT: '35.82690278',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-19.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [129.10857222, 35.82690278] }
    },
    {
      type: 'Feature',
      id: 42,
      properties: {
        DATE: '2024-10-26',
        jiguname: '경주건천',
        gennum: '73534',
        well: '충적',
        pyogo: '89.07',
        area: '낙동강권역',
        addr: '경상북도 경주시 건천읍 방내리 1299-1',
        LON: '129.10857222',
        LAT: '35.82690278',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '12',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-12.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-12',
        strHgt: '',
        strDia: '',
        strMat: '8.0-12.0'
      },
      geometry: { type: 'Point', coordinates: [129.10857222, 35.82690278] }
    },
    {
      type: 'Feature',
      id: 43,
      properties: {
        DATE: '2024-10-26',
        jiguname: '경주산내',
        gennum: '11793',
        well: '암반',
        pyogo: '200.04',
        area: '낙동강권역',
        addr: '경상북도 경주시 산내면 의곡리 92-10 의곡초등학교내',
        LON: '129.04777778',
        LAT: '35.75411667',
        insdate: '1996-10-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '65',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-13.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0.0-65.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '41.0-53.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [129.04777778, 35.75411667] }
    },
    {
      type: 'Feature',
      id: 44,
      properties: {
        DATE: '2024-10-26',
        jiguname: '경주산내',
        gennum: '11794',
        well: '충적',
        pyogo: '200.04',
        area: '낙동강권역',
        addr: '경상북도 경주시 산내면 의곡리 92-10 의곡초등학교내',
        LON: '129.04777778',
        LAT: '35.75411667',
        insdate: '1996-10-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '6.8',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-1.8',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0-6.8',
        cseInMat: '',
        strHgt: '',
        strDia: '1.8-5.8',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [129.04777778, 35.75411667] }
    },
    {
      type: 'Feature',
      id: 45,
      properties: {
        DATE: '2024-10-26',
        jiguname: '경주양북_신',
        gennum: '625363',
        well: '암반',
        pyogo: '136.22',
        area: '낙동강권역',
        addr: '경상북도 경주시 양북면 용동리 산323-13',
        LON: '129.41030833',
        LAT: '35.83062500',
        insdate: '2005-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '73',
        cseExHgt: '300',
        cseExDia: '200',
        cseExMat: '0.0-56.6',
        cseInHgt: '200',
        cseInDia: 'STS304',
        cseInMat: '0.0-73.0',
        strHgt: '150',
        strDia: 'P. V. C',
        strMat: '54.0-58.0'
      },
      geometry: { type: 'Point', coordinates: [129.41030833, 35.830625] }
    },
    {
      type: 'Feature',
      id: 46,
      properties: {
        DATE: '2024-10-26',
        jiguname: '경주양북_신',
        gennum: '625364',
        well: '충적',
        pyogo: '136.22',
        area: '낙동강권역',
        addr: '경상북도 경주시 양북면 용동리 산323-13',
        LON: '129.41030833',
        LAT: '35.83062500',
        insdate: '2005-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '26',
        cseExHgt: '300',
        cseExDia: '200',
        cseExMat: '0.0-3.0',
        cseInHgt: '300',
        cseInDia: '백관',
        cseInMat: '0.0-26.0',
        strHgt: '200',
        strDia: 'STS304',
        strMat: '22.0-26.0'
      },
      geometry: { type: 'Point', coordinates: [129.41030833, 35.830625] }
    },
    {
      type: 'Feature',
      id: 47,
      properties: {
        DATE: '2024-10-26',
        jiguname: '경주외동',
        gennum: '603269',
        well: '암반',
        pyogo: '96.8',
        area: '낙동강권역',
        addr: '경상북도 경주시 외동읍 활성리 948-1',
        LON: '129.31465000',
        LAT: '35.75314722',
        insdate: '2011-12-14',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜한국건업엔지니어링',
        gulDiaTop: '98',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-16.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-98.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '34-38',
        strMat: ' 50-54'
      },
      geometry: { type: 'Point', coordinates: [129.31465, 35.75314722] }
    },
    {
      type: 'Feature',
      id: 48,
      properties: {
        DATE: '2024-10-26',
        jiguname: '경주외동',
        gennum: '603270',
        well: '충적',
        pyogo: '96.8',
        area: '낙동강권역',
        addr: '경상북도 경주시 외동읍 활성리 948-1',
        LON: '129.31465000',
        LAT: '35.75314722',
        insdate: '2011-12-14',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜한국건업엔지니어링',
        gulDiaTop: '12.5',
        gulDiaBtm: '400',
        cseExHgt: '300',
        cseExDia: '0.0-3.0',
        cseExMat: '300',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-12.5',
        cseInMat: '200',
        strHgt: 'STAINLESS STEEL',
        strDia: '8.0-10.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [129.31465, 35.75314722] }
    },
    {
      type: 'Feature',
      id: 49,
      properties: {
        DATE: '2024-10-26',
        jiguname: '경주유금_심부2',
        gennum: '771409',
        well: '암반',
        pyogo: '12',
        area: '낙동강권역',
        addr: '경상북도 경주시 강동면 유금리 1109-1 강동초등학교',
        LON: '129.27733333',
        LAT: '35.99281667',
        insdate: '2015-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-27.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.27733333, 35.99281667] }
    },
    {
      type: 'Feature',
      id: 50,
      properties: {
        DATE: '2024-10-26',
        jiguname: '경주유금_천부',
        gennum: '771410',
        well: '충적',
        pyogo: '12',
        area: '낙동강권역',
        addr: '경상북도 경주시 강동면 유금리 1109-1 강동초등학교',
        LON: '129.27733333',
        LAT: '35.99281667',
        insdate: '2015-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.27733333, 35.99281667] }
    },
    {
      type: 'Feature',
      id: 51,
      properties: {
        DATE: '2024-10-26',
        jiguname: '경주장항_심부2',
        gennum: '771411',
        well: '암반',
        pyogo: '346.01',
        area: '낙동강권역',
        addr: '경상북도 경주시 문무대왕면 장항리 산599-2 토함산자연휴양림',
        LON: '129.36487222',
        LAT: '35.76002500',
        insdate: '2008-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-13.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '50.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.36487222, 35.760025] }
    },
    {
      type: 'Feature',
      id: 52,
      properties: {
        DATE: '2024-10-26',
        jiguname: '경주장항_천부',
        gennum: '771412',
        well: '충적',
        pyogo: '345.365',
        area: '낙동강권역',
        addr: '경상북도 경주시 문무대왕면 장항리 산599-2 토함산자연휴양림',
        LON: '129.36487222',
        LAT: '35.76002500',
        insdate: '2008-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '5.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.36487222, 35.760025] }
    },
    {
      type: 'Feature',
      id: 53,
      properties: {
        DATE: '2024-10-26',
        jiguname: '경주천북',
        gennum: '11791',
        well: '암반',
        pyogo: '41.38',
        area: '낙동강권역',
        addr: '경상북도 경주시 천북면 동산리 506-1 천북면사무소내',
        LON: '129.27167222',
        LAT: '35.89505000',
        insdate: '2011-12-14',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '60',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-12.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0.0-60.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '48.0-60.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [129.27167222, 35.89505] }
    },
    {
      type: 'Feature',
      id: 54,
      properties: {
        DATE: '2024-10-26',
        jiguname: '경주천북',
        gennum: '11792',
        well: '충적',
        pyogo: '41.38',
        area: '낙동강권역',
        addr: '경상북도 경주시 천북면 동산리 506-1 천북면사무소내',
        LON: '129.27167222',
        LAT: '35.89505000',
        insdate: '2011-12-14',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '8.8',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-3.8',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0-8.8',
        cseInMat: '',
        strHgt: '',
        strDia: '3.8-7.8',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [129.27167222, 35.89505] }
    },
    {
      type: 'Feature',
      id: 55,
      properties: {
        DATE: '2024-10-26',
        jiguname: '계룡엄사',
        gennum: '612012',
        well: '암반',
        pyogo: '139.02',
        area: '금강권역',
        addr: '충청남도 계룡시 엄사면 엄사리 13-31',
        LON: '127.24699167',
        LAT: '36.28315278',
        insdate: '2012-11-22',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜지오그린21',
        gulDiaTop: '102',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-8.0',
        cseExMat: '200',
        cseInHgt: 'STS304',
        cseInDia: '0.0-102.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '12-18',
        strMat: ' 36-42'
      },
      geometry: { type: 'Point', coordinates: [127.24699167, 36.28315278] }
    },
    {
      type: 'Feature',
      id: 56,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고령개포_심부2',
        gennum: '771415',
        well: '암반',
        pyogo: '28.25',
        area: '낙동강권역',
        addr: '경상북도 고령군 개진면 개포리 240-1 개진면보건지소',
        LON: '128.35049722',
        LAT: '35.70780000',
        insdate: '2018-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-3.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC(HI-VP)',
        strDia: '77.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.35049722, 35.7078] }
    },
    {
      type: 'Feature',
      id: 57,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고령개포_천부',
        gennum: '771416',
        well: '충적',
        pyogo: '28.248',
        area: '낙동강권역',
        addr: '경상북도 고령군 개진면 개포리 240-1 개진면보건지소',
        LON: '128.35049722',
        LAT: '35.70780000',
        insdate: '2018-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC(HI-VP)',
        strDia: '5.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.35049722, 35.7078] }
    },
    {
      type: 'Feature',
      id: 58,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고령고령',
        gennum: '9890',
        well: '암반',
        pyogo: '28.22',
        area: '낙동강권역',
        addr: '경상북도 고령군 고령읍 장기리 331-3 상수도사업소내',
        LON: '128.28123611',
        LAT: '35.72671667',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '65',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-10.0',
        cseExMat: '250',
        cseInHgt: '백관',
        cseInDia: '0.0-65.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [128.28123611, 35.72671667] }
    },
    {
      type: 'Feature',
      id: 59,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고령고령',
        gennum: '9891',
        well: '충적',
        pyogo: '28.2',
        area: '낙동강권역',
        addr: '경상북도 고령군 고령읍 장기리 331-3 상수도사업소내',
        LON: '128.28123611',
        LAT: '35.72671667',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '7',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-7.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0-7',
        cseInMat: '',
        strHgt: '',
        strDia: '3.0-7.0',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [128.28123611, 35.72671667] }
    },
    {
      type: 'Feature',
      id: 60,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고령귀원_심부2',
        gennum: '771417',
        well: '암반',
        pyogo: '54.52',
        area: '낙동강권역',
        addr: '경상북도 고령군 쌍림면 귀원리 28 쌍림중학교',
        LON: '128.24253889',
        LAT: '35.68607500',
        insdate: '2015-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '81',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-12.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-81',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '75.0-81.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.24253889, 35.686075] }
    },
    {
      type: 'Feature',
      id: 61,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고령귀원_천부',
        gennum: '771418',
        well: '충적',
        pyogo: '54.52',
        area: '낙동강권역',
        addr: '경상북도 고령군 쌍림면 귀원리 28 쌍림중학교',
        LON: '128.24253889',
        LAT: '35.68607500',
        insdate: '2015-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '15',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-15',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '7.5-15.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.24253889, 35.686075] }
    },
    {
      type: 'Feature',
      id: 62,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고령다산',
        gennum: '717011',
        well: '암반',
        pyogo: '25.05',
        area: '낙동강권역',
        addr: '경상북도 고령군 다산면 벌지리 63',
        LON: '128.40735556',
        LAT: '35.81777222',
        insdate: '2017-11-30',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜에스제이지반기술',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-13.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '80-100',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [128.40735556, 35.81777222] }
    },
    {
      type: 'Feature',
      id: 63,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고성거류',
        gennum: '65005',
        well: '암반',
        pyogo: '10.98',
        area: '낙동강권역',
        addr: '경상남도 고성군 거류면 당동리 66 거류초등학교',
        LON: '128.40362222',
        LAT: '34.99313611',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-12.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '48.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [128.40362222, 34.99313611] }
    },
    {
      type: 'Feature',
      id: 64,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고성광산_심부2',
        gennum: '769731',
        well: '암반',
        pyogo: '36.14',
        area: '한강권역',
        addr: '강원특별자치도 고성군 간성읍 광산리 193 광산초등학교',
        LON: '128.41236944',
        LAT: '38.37497222',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '84',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-12.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-84.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '78.0-84.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.41236944, 38.37497222] }
    },
    {
      type: 'Feature',
      id: 65,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고성광산_천부',
        gennum: '769732',
        well: '충적',
        pyogo: '36.14',
        area: '한강권역',
        addr: '강원특별자치도 고성군 간성읍 광산리 193 광산초등학교',
        LON: '128.41236944',
        LAT: '38.37497222',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '13',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-13.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '7.0-13.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.41236944, 38.37497222] }
    },
    {
      type: 'Feature',
      id: 66,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고성우산_심부2',
        gennum: '737564',
        well: '암반',
        pyogo: '15.27',
        area: '낙동강권역',
        addr: '경상남도 고성군 고성읍 우산리 230-1',
        LON: '128.32531389',
        LAT: '34.99722222',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '76',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-10.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-76.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '70.0-76.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.32531389, 34.99722222] }
    },
    {
      type: 'Feature',
      id: 67,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고성우산_천부',
        gennum: '737563',
        well: '충적',
        pyogo: '15.27',
        area: '낙동강권역',
        addr: '경상남도 고성군 고성읍 우산리 230-1',
        LON: '128.32531389',
        LAT: '34.99722222',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.32531389, 34.99722222] }
    },
    {
      type: 'Feature',
      id: 68,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고성인흥',
        gennum: '405430',
        well: '암반',
        pyogo: '14.9',
        area: '한강권역',
        addr: '강원특별자치도 고성군 토성면 인흥리 191 상수도가압장',
        LON: '128.55020000',
        LAT: '38.23534444',
        insdate: '2009-12-14',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜해천 E.T.S',
        gulDiaTop: '180',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-21.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '52-56',
        strMat: ' 64-68'
      },
      geometry: { type: 'Point', coordinates: [128.5502, 38.23534444] }
    },
    {
      type: 'Feature',
      id: 69,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고성토성',
        gennum: '73502',
        well: '암반',
        pyogo: '80.646',
        area: '한강권역',
        addr: '강원특별자치도 고성군 토성면 원암리 441-1',
        LON: '128.50936111',
        LAT: '38.21145278',
        insdate: '2005-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국지질조사탐사업협동조합',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0-5.6',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0-70',
        cseInMat: '200',
        strHgt: 'PVC',
        strDia: '50.0-54.0',
        strMat: ' 58.0-62.0'
      },
      geometry: { type: 'Point', coordinates: [128.50936111, 38.21145278] }
    },
    {
      type: 'Feature',
      id: 70,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고양성사',
        gennum: '612006',
        well: '암반',
        pyogo: '34.56',
        area: '한강권역',
        addr: '경기도 고양시 덕양구 성사동  267',
        LON: '126.84560000',
        LAT: '37.64911667',
        insdate: '2012-10-22',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜지오그린21',
        gulDiaTop: '147',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-8.0',
        cseExMat: '200',
        cseInHgt: 'STS304',
        cseInDia: '0.0-147.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '15.0-35.0',
        strMat: ' 67.0-71.0'
      },
      geometry: { type: 'Point', coordinates: [126.8456, 37.64911667] }
    },
    {
      type: 'Feature',
      id: 71,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고창고수',
        gennum: '11768',
        well: '암반',
        pyogo: '56.21',
        area: '영섬권역',
        addr: '전북특별자치도 고창군 고수면 상평리 771-1',
        LON: '126.68982500',
        LAT: '35.39492500',
        insdate: '1996-09-09',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '68',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-4.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0.0-68.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '18.0-24.0',
        strMat: ' 42.0-48.0'
      },
      geometry: { type: 'Point', coordinates: [126.689825, 35.394925] }
    },
    {
      type: 'Feature',
      id: 72,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고창고수',
        gennum: '11769',
        well: '충적',
        pyogo: '56.21',
        area: '영섬권역',
        addr: '전북특별자치도 고창군 고수면 상평리 771-1',
        LON: '126.68982500',
        LAT: '35.39492500',
        insdate: '1996-09-09',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '10',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-0.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0-10',
        cseInMat: '',
        strHgt: '',
        strDia: '0.5-4.5',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [126.689825, 35.394925] }
    },
    {
      type: 'Feature',
      id: 73,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고창공음',
        gennum: '793610',
        well: '암반',
        pyogo: '20.72',
        area: '영섬권역',
        addr: '전북특별자치도 고창군 공음면 칠암리 575-7',
        LON: '126.51658778',
        LAT: '35.37952944',
        insdate: '2023-12-01',
        manager: '',
        builder: '',
        gulDep: '100',
        gulDiaTop: '300',
        gulDiaBtm: '200',
        cseExHgt: '0.0-37.0',
        cseExDia: '200',
        cseExMat: 'STEEL PIPE',
        cseInHgt: '0.0-100.0',
        cseInDia: '150',
        cseInMat: 'P. V. C',
        strHgt: '',
        strDia: '',
        strMat: ''
      },
      geometry: { type: 'Point', coordinates: [126.51658778, 35.37952944] }
    },
    {
      type: 'Feature',
      id: 74,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고창공음',
        gennum: '793611',
        well: '충적',
        pyogo: '20.6',
        area: '영섬권역',
        addr: '전북특별자치도 고창군 공음면 칠암리 575-7',
        LON: '126.51657306',
        LAT: '35.37952944',
        insdate: '2023-12-01',
        manager: '',
        builder: '',
        gulDep: '8',
        gulDiaTop: '400',
        gulDiaBtm: '',
        cseExHgt: '0.0-6.0',
        cseExDia: '300',
        cseExMat: 'STEEL PIPE',
        cseInHgt: '0.0-8.0',
        cseInDia: '200',
        cseInMat: 'P. V. C',
        strHgt: '',
        strDia: '',
        strMat: ''
      },
      geometry: { type: 'Point', coordinates: [126.51657306, 35.37952944] }
    },
    {
      type: 'Feature',
      id: 75,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고창대산',
        gennum: '82022',
        well: '암반',
        pyogo: '31.963',
        area: '영섬권역',
        addr: '전북특별자치도 고창군 대산면 갈마리 산18-4',
        LON: '126.55631944',
        LAT: '35.31895833',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-9',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.55631944, 35.31895833] }
    },
    {
      type: 'Feature',
      id: 76,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고창삼인_심부2',
        gennum: '771419',
        well: '암반',
        pyogo: '18',
        area: '영섬권역',
        addr: '전북특별자치도 고창군 아산면 삼인리 129 선운산도립공원',
        LON: '126.58873056',
        LAT: '35.50442778',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-6.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '70.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.58873056, 35.50442778] }
    },
    {
      type: 'Feature',
      id: 77,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고창삼인_천부',
        gennum: '771420',
        well: '충적',
        pyogo: '18',
        area: '영섬권역',
        addr: '전북특별자치도 고창군 아산면 삼인리 129 선운산도립공원',
        LON: '126.58873056',
        LAT: '35.50442778',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '3.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.58873056, 35.50442778] }
    },
    {
      type: 'Feature',
      id: 78,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고창상하',
        gennum: '82023',
        well: '암반',
        pyogo: '9.515',
        area: '영섬권역',
        addr: '전북특별자치도 고창군 상하면 용대리 1340',
        LON: '126.48880278',
        LAT: '35.42056667',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-12',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.48880278, 35.42056667] }
    },
    {
      type: 'Feature',
      id: 79,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고창상하',
        gennum: '82024',
        well: '충적',
        pyogo: '9.498',
        area: '영섬권역',
        addr: '전북특별자치도 고창군 상하면 용대리 1340',
        LON: '126.48880278',
        LAT: '35.42056667',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0-6',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6.0-10.0'
      },
      geometry: { type: 'Point', coordinates: [126.48880278, 35.42056667] }
    },
    {
      type: 'Feature',
      id: 80,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고창성내',
        gennum: '84026',
        well: '암반',
        pyogo: '16.94',
        area: '금강권역',
        addr: '전북특별자치도 고창군 성내면 덕산리  397-11',
        LON: '126.72952778',
        LAT: '35.56311111',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-17.7',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.72952778, 35.56311111] }
    },
    {
      type: 'Feature',
      id: 81,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고창성내',
        gennum: '84027',
        well: '충적',
        pyogo: '17.005',
        area: '금강권역',
        addr: '전북특별자치도 고창군 성내면 덕산리  397-11',
        LON: '126.72952778',
        LAT: '35.56311111',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '12',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-8.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-12',
        strHgt: '',
        strDia: '',
        strMat: '8.0-12.0'
      },
      geometry: { type: 'Point', coordinates: [126.72952778, 35.56311111] }
    },
    {
      type: 'Feature',
      id: 82,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고창월암_심부2',
        gennum: '771421',
        well: '암반',
        pyogo: '83',
        area: '영섬권역',
        addr: '전북특별자치도 고창군 고창읍 월암리 316 고창군공설운동장',
        LON: '126.72540833',
        LAT: '35.43174722',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-13.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '70.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.72540833, 35.43174722] }
    },
    {
      type: 'Feature',
      id: 83,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고창월암_천부',
        gennum: '771422',
        well: '충적',
        pyogo: '83',
        area: '영섬권역',
        addr: '전북특별자치도 고창군 고창읍 월암리 316 고창군공설운동장',
        LON: '126.72540833',
        LAT: '35.43174722',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.72540833, 35.43174722] }
    },
    {
      type: 'Feature',
      id: 84,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고창해리',
        gennum: '718277',
        well: '암반',
        pyogo: '12.91',
        area: '영섬권역',
        addr: '전북특별자치도 고창군 해리면 안산리 359',
        LON: '126.53105278',
        LAT: '35.46798611',
        insdate: '2018-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '(주)어스이엔지',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-27.0',
        cseExMat: '200',
        cseInHgt: 'Stainless Steel',
        cseInDia: '0.0-100',
        cseInMat: '150',
        strHgt: 'P.V.C(VG1)',
        strDia: '68-100',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [126.53105278, 35.46798611] }
    },
    {
      type: 'Feature',
      id: 85,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고창흥덕',
        gennum: '82025',
        well: '암반',
        pyogo: '30.738',
        area: '금강권역',
        addr: '전북특별자치도 고창군 흥덕면 동사리 435-28',
        LON: '126.70454167',
        LAT: '35.51763333',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-13',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '48.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [126.70454167, 35.51763333] }
    },
    {
      type: 'Feature',
      id: 86,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고흥고흥',
        gennum: '771370',
        well: '암반',
        pyogo: '7.8',
        area: '영섬권역',
        addr: '전라남도 고흥군 고흥읍 고소리 산6-5',
        LON: '127.23737222',
        LAT: '34.60643611',
        insdate: '2021-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100.5',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0-5.3',
        cseExMat: '200',
        cseInHgt: 'SST',
        cseInDia: '0-100.5',
        cseInMat: '150',
        strHgt: 'pvc',
        strDia: '52-64',
        strMat: ' 68-80'
      },
      geometry: { type: 'Point', coordinates: [127.23737222, 34.60643611] }
    },
    {
      type: 'Feature',
      id: 87,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고흥과역',
        gennum: '771369',
        well: '암반',
        pyogo: '11.9',
        area: '영섬권역',
        addr: '전라남도 고흥군 과역면 신곡리 1117-9',
        LON: '127.41585278',
        LAT: '34.66521389',
        insdate: '2021-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '150.5',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0-7.0',
        cseExMat: '200',
        cseInHgt: 'SST',
        cseInDia: '0-150.5',
        cseInMat: '150',
        strHgt: 'pvc',
        strDia: '52-64',
        strMat: ' 68-80'
      },
      geometry: { type: 'Point', coordinates: [127.41585278, 34.66521389] }
    },
    {
      type: 'Feature',
      id: 88,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고흥남양',
        gennum: '771371',
        well: '암반',
        pyogo: '4',
        area: '영섬권역',
        addr: '전라남도 고흥군 남양면 장담리 3056',
        LON: '127.35628889',
        LAT: '34.70423611',
        insdate: '2021-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100.8',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0-8.5',
        cseExMat: '200',
        cseInHgt: 'SST',
        cseInDia: '0-100.8',
        cseInMat: '150',
        strHgt: 'pvc',
        strDia: '52-64',
        strMat: ' 68-80'
      },
      geometry: { type: 'Point', coordinates: [127.35628889, 34.70423611] }
    },
    {
      type: 'Feature',
      id: 89,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고흥대서',
        gennum: '65006',
        well: '암반',
        pyogo: '12.8',
        area: '영섬권역',
        addr: '전라남도 고흥군 대서면 화산리 929-11',
        LON: '127.28480556',
        LAT: '34.77201111',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '71',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-16.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0.0-71.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '48.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [127.28480556, 34.77201111] }
    },
    {
      type: 'Feature',
      id: 90,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고흥도화',
        gennum: '778248',
        well: '암반',
        pyogo: '19.2',
        area: '영섬권역',
        addr: '전라남도 고흥군 도화면 덕중리 384-9',
        LON: '127.36457222',
        LAT: '34.44800833',
        insdate: '2022-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '110',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-12.0',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0.0-110',
        cseInMat: '100',
        strHgt: 'P.V.C(VG1)',
        strDia: '30-60',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [127.36457222, 34.44800833] }
    },
    {
      type: 'Feature',
      id: 91,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고흥도화',
        gennum: '778249',
        well: '충적',
        pyogo: '19.2',
        area: '영섬권역',
        addr: '전라남도 고흥군 도화면 덕중리 384-9',
        LON: '127.36457222',
        LAT: '34.44800833',
        insdate: '2022-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '20',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-12.0',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0.0-20',
        cseInMat: '100',
        strHgt: 'P.V.C(VG1)',
        strDia: '19-20',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [127.36457222, 34.44800833] }
    },
    {
      type: 'Feature',
      id: 92,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고흥봉래',
        gennum: '771368',
        well: '암반',
        pyogo: '22.7',
        area: '영섬권역',
        addr: '전라남도 고흥군 봉래면 외초리 108-3',
        LON: '127.49122222',
        LAT: '34.41918611',
        insdate: '2021-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '101.2',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0-22.3',
        cseExMat: '200',
        cseInHgt: 'SST',
        cseInDia: '0-101.2',
        cseInMat: '150',
        strHgt: 'pvc',
        strDia: '52-64',
        strMat: ' 68-80'
      },
      geometry: { type: 'Point', coordinates: [127.49122222, 34.41918611] }
    },
    {
      type: 'Feature',
      id: 93,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고흥야막_심부2',
        gennum: '771423',
        well: '암반',
        pyogo: '74.483',
        area: '영섬권역',
        addr: '전라남도 고흥군 풍양면 야막리 산86-1 고흥유자공원',
        LON: '127.23834167',
        LAT: '34.57213056',
        insdate: '2018-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-3.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC(HI-VP)',
        strDia: '69.5-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.23834167, 34.57213056] }
    },
    {
      type: 'Feature',
      id: 94,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고흥야막_천부',
        gennum: '771424',
        well: '충적',
        pyogo: '74.475',
        area: '영섬권역',
        addr: '전라남도 고흥군 풍양면 야막리 산86-1 고흥유자공원',
        LON: '127.23834167',
        LAT: '34.57213056',
        insdate: '2018-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '18',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-18',
        cseInMat: '100',
        strHgt: 'PVC(HI-VP)',
        strDia: '12.0-18.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.23834167, 34.57213056] }
    },
    {
      type: 'Feature',
      id: 95,
      properties: {
        DATE: '2024-10-26',
        jiguname: '고흥영남',
        gennum: '601715',
        well: '암반',
        pyogo: '37.52',
        area: '영섬권역',
        addr: '전라남도 고흥군 영남면 양사리 197-1',
        LON: '127.46722222',
        LAT: '34.60666667',
        insdate: '2011-12-07',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜한국건업엔지니어링',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-9.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '36-40',
        strMat: ' 52-56'
      },
      geometry: { type: 'Point', coordinates: [127.46722222, 34.60666667] }
    },
    {
      type: 'Feature',
      id: 96,
      properties: {
        DATE: '2024-10-26',
        jiguname: '곡성고달',
        gennum: '73525',
        well: '암반',
        pyogo: '54',
        area: '영섬권역',
        addr: '전라남도 곡성군 고달면 고달리 785-88',
        LON: '127.33091389',
        LAT: '35.27563333',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-23.5',
        cseInHgt: '250',
        cseInDia: '백관',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.33091389, 35.27563333] }
    },
    {
      type: 'Feature',
      id: 97,
      properties: {
        DATE: '2024-10-26',
        jiguname: '곡성고달',
        gennum: '73526',
        well: '충적',
        pyogo: '54',
        area: '영섬권역',
        addr: '전라남도 곡성군 고달면 고달리 785-88',
        LON: '127.33091389',
        LAT: '35.27563333',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-10.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6.0-10.0'
      },
      geometry: { type: 'Point', coordinates: [127.33091389, 35.27563333] }
    },
    {
      type: 'Feature',
      id: 98,
      properties: {
        DATE: '2024-10-26',
        jiguname: '곡성곡성',
        gennum: '622074',
        well: '암반',
        pyogo: '101.06',
        area: '영섬권역',
        addr: '전라남도 곡성군 곡성읍 구원리 947-5 전라남도 곡성군 곡성읍 구원리 947-5',
        LON: '127.26166667',
        LAT: '35.24944444',
        insdate: '2014-11-28',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜대흥개발',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-16.5',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '36-40',
        strMat: ' 52-56'
      },
      geometry: { type: 'Point', coordinates: [127.26166667, 35.24944444] }
    },
    {
      type: 'Feature',
      id: 99,
      properties: {
        DATE: '2024-10-26',
        jiguname: '곡성동계_심부2',
        gennum: '737570',
        well: '암반',
        pyogo: '70.2',
        area: '영섬권역',
        addr: '전라남도 곡성군 죽곡면 동계리 413-3',
        LON: '127.36113611',
        LAT: '35.15378889',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-5.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.36113611, 35.15378889] }
    },
    {
      type: 'Feature',
      id: 100,
      properties: {
        DATE: '2024-10-26',
        jiguname: '곡성동계_천부',
        gennum: '737569',
        well: '충적',
        pyogo: '70.2',
        area: '영섬권역',
        addr: '전라남도 곡성군 죽곡면 동계리 413-3',
        LON: '127.36113611',
        LAT: '35.15378889',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '16',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-16.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '10.0-16.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.36113611, 35.15378889] }
    },
    {
      type: 'Feature',
      id: 101,
      properties: {
        DATE: '2024-10-26',
        jiguname: '곡성목동_심부2',
        gennum: '737572',
        well: '암반',
        pyogo: '58.817',
        area: '영섬권역',
        addr: '전라남도 곡성군 고달면 목동리 676-4',
        LON: '127.34134167',
        LAT: '35.29438611',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-34.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.34134167, 35.29438611] }
    },
    {
      type: 'Feature',
      id: 102,
      properties: {
        DATE: '2024-10-26',
        jiguname: '곡성목동_천부',
        gennum: '737571',
        well: '충적',
        pyogo: '58.863',
        area: '영섬권역',
        addr: '전라남도 곡성군 고달면 목동리 676-4',
        LON: '127.34134167',
        LAT: '35.29438611',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-5.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '5.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.34134167, 35.29438611] }
    },
    {
      type: 'Feature',
      id: 103,
      properties: {
        DATE: '2024-10-26',
        jiguname: '곡성목사동',
        gennum: '82033',
        well: '암반',
        pyogo: '103.551',
        area: '영섬권역',
        addr: '전라남도 곡성군 목사동면 용봉리 721',
        LON: '127.30635000',
        LAT: '35.10460000',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-14',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.30635, 35.1046] }
    },
    {
      type: 'Feature',
      id: 104,
      properties: {
        DATE: '2024-10-26',
        jiguname: '곡성백곡_심부2',
        gennum: '737574',
        well: '암반',
        pyogo: '67.687',
        area: '영섬권역',
        addr: '전라남도 곡성군 고달면 백곡리 1057-1',
        LON: '127.34758333',
        LAT: '35.31447500',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-21.2',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '72.5-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.34758333, 35.314475] }
    },
    {
      type: 'Feature',
      id: 105,
      properties: {
        DATE: '2024-10-26',
        jiguname: '곡성백곡_천부',
        gennum: '737573',
        well: '충적',
        pyogo: '67.63',
        area: '영섬권역',
        addr: '전라남도 곡성군 고달면 백곡리 1057-1',
        LON: '127.34758333',
        LAT: '35.31447500',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-5.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '5.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.34758333, 35.314475] }
    },
    {
      type: 'Feature',
      id: 106,
      properties: {
        DATE: '2024-10-26',
        jiguname: '곡성석곡_심부2',
        gennum: '769733',
        well: '암반',
        pyogo: '68.4',
        area: '영섬권역',
        addr: '전라남도 곡성군 석곡면 석곡리 8 석곡초등학교',
        LON: '127.25446389',
        LAT: '35.13511389',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-31.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.25446389, 35.13511389] }
    },
    {
      type: 'Feature',
      id: 107,
      properties: {
        DATE: '2024-10-26',
        jiguname: '곡성석곡_천부',
        gennum: '769734',
        well: '충적',
        pyogo: '68.4',
        area: '영섬권역',
        addr: '전라남도 곡성군 석곡면 석곡리 8 석곡초등학교',
        LON: '127.25446389',
        LAT: '35.13511389',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '14',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-14.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '8.0-14.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.25446389, 35.13511389] }
    },
    {
      type: 'Feature',
      id: 108,
      properties: {
        DATE: '2024-10-26',
        jiguname: '곡성옥과',
        gennum: '615513',
        well: '암반',
        pyogo: '91.25',
        area: '영섬권역',
        addr: '전라남도 곡성군 옥과면 무창리 산39-2',
        LON: '127.14560556',
        LAT: '35.27744722',
        insdate: '2013-11-23',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜지오그린21',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-6.0',
        cseExMat: '200',
        cseInHgt: 'STS304',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '44-48',
        strMat: ' 60-64'
      },
      geometry: { type: 'Point', coordinates: [127.14560556, 35.27744722] }
    },
    {
      type: 'Feature',
      id: 109,
      properties: {
        DATE: '2024-10-26',
        jiguname: '곡성입면',
        gennum: '11776',
        well: '암반',
        pyogo: '82.07',
        area: '영섬권역',
        addr: '전라남도 곡성군 입면 매월리 584-7 곡성군 입면 복지회관',
        LON: '127.20351667',
        LAT: '35.29695000',
        insdate: '1996-12-29',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '63',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-10.6',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0.0-63.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '39.0-51.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [127.20351667, 35.29695] }
    },
    {
      type: 'Feature',
      id: 110,
      properties: {
        DATE: '2024-10-26',
        jiguname: '곡성입면',
        gennum: '11777',
        well: '충적',
        pyogo: '82.07',
        area: '영섬권역',
        addr: '전라남도 곡성군 입면 매월리 584-7 곡성군 입면 복지회관',
        LON: '127.20351667',
        LAT: '35.29695000',
        insdate: '1996-12-29',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '8.6',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-3.6',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0-8.6',
        cseInMat: '',
        strHgt: '',
        strDia: '3.6-7.6',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [127.20351667, 35.29695] }
    },
    {
      type: 'Feature',
      id: 111,
      properties: {
        DATE: '2024-10-26',
        jiguname: '공주반포',
        gennum: '11764',
        well: '암반',
        pyogo: '82.25',
        area: '금강권역',
        addr: '충청남도 공주시 반포면 봉곡리 산73-7',
        LON: '127.24250278',
        LAT: '36.40228611',
        insdate: '1996-11-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '68',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-14.8',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0.0-68.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 44.0-48.0'
      },
      geometry: { type: 'Point', coordinates: [127.24250278, 36.40228611] }
    },
    {
      type: 'Feature',
      id: 112,
      properties: {
        DATE: '2024-10-26',
        jiguname: '공주봉곡_심부2',
        gennum: '769735',
        well: '암반',
        pyogo: '155',
        area: '금강권역',
        addr: '충청남도 공주시 반포면 봉곡리 360 대전교육연수원',
        LON: '127.23129722',
        LAT: '36.39363056',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '81',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-27.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-81.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '60.0-81.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.23129722, 36.39363056] }
    },
    {
      type: 'Feature',
      id: 113,
      properties: {
        DATE: '2024-10-26',
        jiguname: '공주봉곡_천부',
        gennum: '769736',
        well: '충적',
        pyogo: '155',
        area: '금강권역',
        addr: '충청남도 공주시 반포면 봉곡리 360 대전교육연수원',
        LON: '127.23129722',
        LAT: '36.39363056',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '25',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-25.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '18.0-25.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.23129722, 36.39363056] }
    },
    {
      type: 'Feature',
      id: 114,
      properties: {
        DATE: '2024-10-26',
        jiguname: '공주신풍',
        gennum: '65007',
        well: '암반',
        pyogo: '43.94',
        area: '금강권역',
        addr: '충청남도 공주시 신풍면 백룡리 914-5',
        LON: '126.97554722',
        LAT: '36.50902778',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '71',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-9.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0.0-71.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '48.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [126.97554722, 36.50902778] }
    },
    {
      type: 'Feature',
      id: 115,
      properties: {
        DATE: '2024-10-26',
        jiguname: '공주정안',
        gennum: '84021',
        well: '암반',
        pyogo: '34.41',
        area: '금강권역',
        addr: '충청남도 공주시 정안면 화봉리  440-53',
        LON: '127.11352500',
        LAT: '36.54092500',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-21.7',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '30.0-34.0'
      },
      geometry: { type: 'Point', coordinates: [127.113525, 36.540925] }
    },
    {
      type: 'Feature',
      id: 116,
      properties: {
        DATE: '2024-10-26',
        jiguname: '공주정안',
        gennum: '84022',
        well: '충적',
        pyogo: '34.423',
        area: '금강권역',
        addr: '충청남도 공주시 정안면 화봉리  440-53',
        LON: '127.11352500',
        LAT: '36.54092500',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-6.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6.0-10.0'
      },
      geometry: { type: 'Point', coordinates: [127.113525, 36.540925] }
    },
    {
      type: 'Feature',
      id: 117,
      properties: {
        DATE: '2024-10-26',
        jiguname: '공주탄천',
        gennum: '82018',
        well: '암반',
        pyogo: '13.39',
        area: '금강권역',
        addr: '충청남도 공주시 탄천면 견동리 356-2',
        LON: '126.99267222',
        LAT: '36.35398056',
        insdate: '2003-05-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-16.0',
        cseInHgt: '250',
        cseInDia: '백관',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '42.0-46.0'
      },
      geometry: { type: 'Point', coordinates: [126.99267222, 36.35398056] }
    },
    {
      type: 'Feature',
      id: 118,
      properties: {
        DATE: '2024-10-26',
        jiguname: '과천과천',
        gennum: '718271',
        well: '암반',
        pyogo: '31.49',
        area: '한강권역',
        addr: '경기도 과천시 과천동  550',
        LON: '127.00645833',
        LAT: '37.45434444',
        insdate: '2018-12-07',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '동서지오베이스(주)',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '150',
        cseExDia: '0.0-9.0',
        cseExMat: '200',
        cseInHgt: 'Stainless Steel',
        cseInDia: '0.0-100',
        cseInMat: '150',
        strHgt: 'P.V.C(VG1)',
        strDia: '9.0-100.0',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [127.00645833, 37.45434444] }
    },
    {
      type: 'Feature',
      id: 119,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광명소하',
        gennum: '717003',
        well: '암반',
        pyogo: '14.47',
        area: '한강권역',
        addr: '경기도 광명시 소하동  1346',
        LON: '126.89636389',
        LAT: '37.44412778',
        insdate: '2017-11-30',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜삼안',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-20.4',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '24-28',
        strMat: ' 60-64'
      },
      geometry: { type: 'Point', coordinates: [126.89636389, 37.44412778] }
    },
    {
      type: 'Feature',
      id: 120,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광명철산',
        gennum: '65008',
        well: '암반',
        pyogo: '11.16',
        area: '한강권역',
        addr: '경기도 광명시 철산동  15 배수펌프장',
        LON: '126.86898056',
        LAT: '37.49120000',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '84',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-14.8',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-84.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '60.0-64.0',
        strMat: ' 68.0-72.0'
      },
      geometry: { type: 'Point', coordinates: [126.86898056, 37.4912] }
    },
    {
      type: 'Feature',
      id: 121,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광명철산',
        gennum: '65009',
        well: '충적',
        pyogo: '11.16',
        area: '한강권역',
        addr: '경기도 광명시 철산동  15 배수펌프장',
        LON: '126.86898056',
        LAT: '37.49120000',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '13.5',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-13.5',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0-13.5',
        cseInMat: '',
        strHgt: '',
        strDia: '9.5-13.5',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [126.86898056, 37.4912] }
    },
    {
      type: 'Feature',
      id: 122,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광양광양',
        gennum: '637533',
        well: '암반',
        pyogo: '8.114',
        area: '영섬권역',
        addr: '전라남도 광양시 광양읍 세풍리 293-2 1',
        LON: '127.58308333',
        LAT: '34.94425000',
        insdate: '2015-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜대동공영',
        gulDiaTop: '103.5',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-18.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-103.5',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '20.0-24.0',
        strMat: ' 56.0-64.0'
      },
      geometry: { type: 'Point', coordinates: [127.58308333, 34.94425] }
    },
    {
      type: 'Feature',
      id: 123,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광양마동_심부2',
        gennum: '769737',
        well: '암반',
        pyogo: '66.25',
        area: '영섬권역',
        addr: '전라남도 광양시 마동  1087',
        LON: '127.69768333',
        LAT: '34.95350556',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-13.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.69768333, 34.95350556] }
    },
    {
      type: 'Feature',
      id: 124,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광양마동_천부',
        gennum: '769738',
        well: '충적',
        pyogo: '66.25',
        area: '영섬권역',
        addr: '전라남도 광양시 마동  1087',
        LON: '127.69768333',
        LAT: '34.95350556',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.69768333, 34.95350556] }
    },
    {
      type: 'Feature',
      id: 125,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광양봉강',
        gennum: '95527',
        well: '암반',
        pyogo: '53.77',
        area: '영섬권역',
        addr: '전라남도 광양시 봉강면 봉당리 978-43',
        LON: '127.57731389',
        LAT: '35.01380000',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-8.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '20.0-24.0'
      },
      geometry: { type: 'Point', coordinates: [127.57731389, 35.0138] }
    },
    {
      type: 'Feature',
      id: 126,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광양석사_심부2',
        gennum: '769739',
        well: '암반',
        pyogo: '27.96',
        area: '영섬권역',
        addr: '전라남도 광양시 봉강면 석사리 75 광양고등학교',
        LON: '127.57339444',
        LAT: '34.98292500',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-5.8',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.57339444, 34.982925] }
    },
    {
      type: 'Feature',
      id: 127,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광양석사_천부',
        gennum: '769740',
        well: '충적',
        pyogo: '27.96',
        area: '영섬권역',
        addr: '전라남도 광양시 봉강면 석사리 75 광양고등학교',
        LON: '127.57339444',
        LAT: '34.98292500',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.57339444, 34.982925] }
    },
    {
      type: 'Feature',
      id: 128,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광양조령_심부2',
        gennum: '771425',
        well: '암반',
        pyogo: '116.96',
        area: '영섬권역',
        addr: '전라남도 광양시 봉강면 조령리 224 봉강햇살수련원',
        LON: '127.56543611',
        LAT: '35.05171944',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-14.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '72.5-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.56543611, 35.05171944] }
    },
    {
      type: 'Feature',
      id: 129,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광양조령_천부',
        gennum: '771426',
        well: '충적',
        pyogo: '116.96',
        area: '영섬권역',
        addr: '전라남도 광양시 봉강면 조령리 224 봉강햇살수련원',
        LON: '127.56543611',
        LAT: '35.05171944',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.56543611, 35.05171944] }
    },
    {
      type: 'Feature',
      id: 130,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광양진상',
        gennum: '637534',
        well: '암반',
        pyogo: '18.357',
        area: '영섬권역',
        addr: '전라남도 광양시 진상면 섬거리 96 1',
        LON: '127.72096667',
        LAT: '35.03208889',
        insdate: '2015-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜대동공영',
        gulDiaTop: '103',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-30.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-103.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '32.0-40.0',
        strMat: ' 48.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [127.72096667, 35.03208889] }
    },
    {
      type: 'Feature',
      id: 131,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광주곤지암',
        gennum: '769851',
        well: '암반',
        pyogo: '67.62',
        area: '한강권역',
        addr: '경기도 광주시 곤지암읍 삼리 171',
        LON: '127.32166667',
        LAT: '37.36083333',
        insdate: '2020-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '155',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0-10.3',
        cseExMat: '200',
        cseInHgt: '아연도강관',
        cseInDia: '0-100',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '16-20',
        strMat: ' 40-44'
      },
      geometry: { type: 'Point', coordinates: [127.32166667, 37.36083333] }
    },
    {
      type: 'Feature',
      id: 132,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광주광주',
        gennum: '65010',
        well: '암반',
        pyogo: '49.12',
        area: '한강권역',
        addr: '경기도 광주시 탄벌동  698-10',
        LON: '127.24891944',
        LAT: '37.41786667',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '74',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-4.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-74.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '50.0-54.0',
        strMat: ' 58.0-62.0'
      },
      geometry: { type: 'Point', coordinates: [127.24891944, 37.41786667] }
    },
    {
      type: 'Feature',
      id: 133,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광주남종',
        gennum: '603271',
        well: '암반',
        pyogo: '26.04',
        area: '한강권역',
        addr: '경기도 광주시 남종면 분원리 100',
        LON: '127.30096667',
        LAT: '37.49671667',
        insdate: '2011-12-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜한국건업엔지니어링',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-6.9',
        cseExMat: '200',
        cseInHgt: '아연도강관',
        cseInDia: '0.0-85.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '29-33',
        strMat: ' 37-41'
      },
      geometry: { type: 'Point', coordinates: [127.30096667, 37.49671667] }
    },
    {
      type: 'Feature',
      id: 134,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광주도수_심부2',
        gennum: '769741',
        well: '암반',
        pyogo: '59',
        area: '한강권역',
        addr: '경기도 광주시 퇴촌면 도수리 585 광수중학교',
        LON: '127.31883611',
        LAT: '37.47291111',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '81',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-12.1',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-81.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '75.0-81.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.31883611, 37.47291111] }
    },
    {
      type: 'Feature',
      id: 135,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광주도수_천부',
        gennum: '769742',
        well: '충적',
        pyogo: '59',
        area: '한강권역',
        addr: '경기도 광주시 퇴촌면 도수리 585 광수중학교',
        LON: '127.31883611',
        LAT: '37.47291111',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '12',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-12.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '6.0-12.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.31883611, 37.47291111] }
    },
    {
      type: 'Feature',
      id: 136,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광주도척',
        gennum: '601739',
        well: '암반',
        pyogo: '112.25',
        area: '한강권역',
        addr: '경기도 광주시 도척면 진우리 600-1',
        LON: '127.34209167',
        LAT: '37.31648333',
        insdate: '2011-12-14',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜한국건업엔지니어링',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-7.1',
        cseExMat: '200',
        cseInHgt: '아연도강관',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '36-40',
        strMat: ' 52-56'
      },
      geometry: { type: 'Point', coordinates: [127.34209167, 37.31648333] }
    },
    {
      type: 'Feature',
      id: 137,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광주운림_심부2',
        gennum: '771427',
        well: '암반',
        pyogo: '134.5',
        area: '영섬권역',
        addr: '광주광역시 동구 운림동  105-3 무등산국립공원',
        LON: '126.95937778',
        LAT: '35.13345833',
        insdate: '2010-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '165',
        cseExHgt: '165',
        cseExDia: '0.0-3.0',
        cseExMat: '165',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '58.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.95937778, 35.13345833] }
    },
    {
      type: 'Feature',
      id: 138,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광주운림_천부',
        gennum: '771428',
        well: '충적',
        pyogo: '134.5',
        area: '영섬권역',
        addr: '광주광역시 동구 운림동  105-3 무등산국립공원',
        LON: '126.95937778',
        LAT: '35.13345833',
        insdate: '2010-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '165',
        cseExHgt: '165',
        cseExDia: '0.0-0.0',
        cseExMat: '165',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.95937778, 35.13345833] }
    },
    {
      type: 'Feature',
      id: 139,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광주운정',
        gennum: '84006',
        well: '암반',
        pyogo: '61.543',
        area: '영섬권역',
        addr: '광주광역시 북구 운정동  697',
        LON: '126.94400556',
        LAT: '35.22955000',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-4.7',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.94400556, 35.22955] }
    },
    {
      type: 'Feature',
      id: 140,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광주유덕',
        gennum: '443',
        well: '암반',
        pyogo: '19.65',
        area: '영섬권역',
        addr: '광주광역시 서구 유촌동   25-1 극락 국민학교',
        LON: '126.85736111',
        LAT: '35.16500556',
        insdate: '1995-12-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-10.0',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '18.0-26.0',
        strMat: ' 52.0-58.0'
      },
      geometry: { type: 'Point', coordinates: [126.85736111, 35.16500556] }
    },
    {
      type: 'Feature',
      id: 141,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광주유덕',
        gennum: '442',
        well: '충적',
        pyogo: '19.86',
        area: '영섬권역',
        addr: '광주광역시 서구 유촌동   25-1 극락 국민학교',
        LON: '126.85736111',
        LAT: '35.16500556',
        insdate: '1995-12-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '10',
        gulDiaBtm: '350',
        cseExHgt: '350',
        cseExDia: '0.0-10.0',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0-10',
        cseInMat: '',
        strHgt: '',
        strDia: '3.0-9.0',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [126.85736111, 35.16500556] }
    },
    {
      type: 'Feature',
      id: 142,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광주임곡_심부2',
        gennum: '769743',
        well: '암반',
        pyogo: '41.23',
        area: '영섬권역',
        addr: '광주광역시 광산구 임곡동  464 임곡초등학교',
        LON: '126.74730278',
        LAT: '35.21995556',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-21.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.74730278, 35.21995556] }
    },
    {
      type: 'Feature',
      id: 143,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광주임곡_천부',
        gennum: '769744',
        well: '충적',
        pyogo: '41.23',
        area: '영섬권역',
        addr: '광주광역시 광산구 임곡동  464 임곡초등학교',
        LON: '126.74730278',
        LAT: '35.21995556',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.74730278, 35.21995556] }
    },
    {
      type: 'Feature',
      id: 144,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광주지석_심부2',
        gennum: '769745',
        well: '암반',
        pyogo: '28.31',
        area: '영섬권역',
        addr: '광주광역시 남구 지석동  223 대촌중앙초등학교',
        LON: '126.83320000',
        LAT: '35.08272500',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-24.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.8332, 35.082725] }
    },
    {
      type: 'Feature',
      id: 145,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광주지석_천부',
        gennum: '769746',
        well: '충적',
        pyogo: '28.31',
        area: '영섬권역',
        addr: '광주광역시 남구 지석동  223 대촌중앙초등학교',
        LON: '126.83320000',
        LAT: '35.08272500',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.8332, 35.082725] }
    },
    {
      type: 'Feature',
      id: 146,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광주추자_심부2',
        gennum: '771429',
        well: '암반',
        pyogo: '59',
        area: '한강권역',
        addr: '경기도 광주시 오포읍 추자리 102-16 오포읍사무소',
        LON: '127.22840833',
        LAT: '37.36693611',
        insdate: '2016-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-7.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.22840833, 37.36693611] }
    },
    {
      type: 'Feature',
      id: 147,
      properties: {
        DATE: '2024-10-26',
        jiguname: '광주추자_천부',
        gennum: '771430',
        well: '충적',
        pyogo: '59',
        area: '한강권역',
        addr: '경기도 광주시 오포읍 추자리 102-16 오포읍사무소',
        LON: '127.22840833',
        LAT: '37.36693611',
        insdate: '2016-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.22840833, 37.36693611] }
    },
    {
      type: 'Feature',
      id: 148,
      properties: {
        DATE: '2024-10-26',
        jiguname: '괴산괴산',
        gennum: '65011',
        well: '암반',
        pyogo: '114.78',
        area: '한강권역',
        addr: '충청북도 괴산군 괴산읍 서부리 175-1',
        LON: '127.78588889',
        LAT: '36.81135000',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '72',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-4.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0.0-72.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '48.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [127.78588889, 36.81135] }
    },
    {
      type: 'Feature',
      id: 149,
      properties: {
        DATE: '2024-10-26',
        jiguname: '괴산능촌',
        gennum: '778268',
        well: '암반',
        pyogo: '146.3',
        area: '한강권역',
        addr: '충청북도 괴산군 괴산읍 능촌리 산66-1',
        LON: '127.82156111',
        LAT: '36.83647778',
        insdate: '2022-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-8.5',
        cseExMat: '200',
        cseInHgt: '아연도강관',
        cseInDia: '0.0-100',
        cseInMat: '100',
        strHgt: 'P.V.C',
        strDia: '28-36',
        strMat: ' 40-44'
      },
      geometry: { type: 'Point', coordinates: [127.82156111, 36.83647778] }
    },
    {
      type: 'Feature',
      id: 150,
      properties: {
        DATE: '2024-10-26',
        jiguname: '괴산청안',
        gennum: '778269',
        well: '암반',
        pyogo: '295.4',
        area: '한강권역',
        addr: '충청북도 괴산군 청안면 문당리 932-2',
        LON: '127.67167222',
        LAT: '36.74131944',
        insdate: '2022-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-9.5',
        cseExMat: '200',
        cseInHgt: '아연도강관',
        cseInDia: '0.0-100',
        cseInMat: '100',
        strHgt: 'P.V.C',
        strDia: '28-44',
        strMat: ' 52-60'
      },
      geometry: { type: 'Point', coordinates: [127.67167222, 36.74131944] }
    },
    {
      type: 'Feature',
      id: 151,
      properties: {
        DATE: '2024-10-26',
        jiguname: '괴산청천',
        gennum: '622075',
        well: '암반',
        pyogo: '160.66',
        area: '한강권역',
        addr: '충청북도 괴산군 청천면 도원리 539-7 충청북도 괴산군 청천면 도원리 539-7번지',
        LON: '127.77805556',
        LAT: '36.67888889',
        insdate: '2014-11-25',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜대흥개발',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-13.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '36-40',
        strMat: ' 52-56'
      },
      geometry: { type: 'Point', coordinates: [127.77805556, 36.67888889] }
    },
    {
      type: 'Feature',
      id: 152,
      properties: {
        DATE: '2024-10-26',
        jiguname: '괴산칠성',
        gennum: '778270',
        well: '암반',
        pyogo: '117',
        area: '한강권역',
        addr: '충청북도 괴산군 칠성면 갈읍리 177-5',
        LON: '127.85438333',
        LAT: '36.79086944',
        insdate: '2022-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '147',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-5.5',
        cseExMat: '200',
        cseInHgt: '아연도강관',
        cseInDia: '0.0-100',
        cseInMat: '100',
        strHgt: 'P.V.C',
        strDia: '28-40',
        strMat: ' 48-52'
      },
      geometry: { type: 'Point', coordinates: [127.85438333, 36.79086944] }
    },
    {
      type: 'Feature',
      id: 153,
      properties: {
        DATE: '2024-10-26',
        jiguname: '구례냉천_심부2',
        gennum: '737578',
        well: '암반',
        pyogo: '41.793',
        area: '영섬권역',
        addr: '전라남도 구례군 마산면 냉천리 756',
        LON: '127.48406389',
        LAT: '35.21961111',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-21.8',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '75.5-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.48406389, 35.21961111] }
    },
    {
      type: 'Feature',
      id: 154,
      properties: {
        DATE: '2024-10-26',
        jiguname: '구례냉천_천부',
        gennum: '737577',
        well: '충적',
        pyogo: '41.782',
        area: '영섬권역',
        addr: '전라남도 구례군 마산면 냉천리 756',
        LON: '127.48406389',
        LAT: '35.21961111',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-5.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '5.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.48406389, 35.21961111] }
    },
    {
      type: 'Feature',
      id: 155,
      properties: {
        DATE: '2024-10-26',
        jiguname: '구례원촌_심부2',
        gennum: '737580',
        well: '암반',
        pyogo: '127.3',
        area: '영섬권역',
        addr: '전라남도 구례군 산동면 원촌리 214',
        LON: '127.43746667',
        LAT: '35.31630278',
        insdate: '2011-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '81',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-19.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-81.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '69.0-81.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.43746667, 35.31630278] }
    },
    {
      type: 'Feature',
      id: 156,
      properties: {
        DATE: '2024-10-26',
        jiguname: '구례원촌_천부',
        gennum: '737579',
        well: '충적',
        pyogo: '127.3',
        area: '영섬권역',
        addr: '전라남도 구례군 산동면 원촌리 214',
        LON: '127.43746667',
        LAT: '35.31630278',
        insdate: '2011-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '13',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-13.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '7.0-13.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.43746667, 35.31630278] }
    },
    {
      type: 'Feature',
      id: 157,
      properties: {
        DATE: '2024-10-26',
        jiguname: '구례토지',
        gennum: '87257',
        well: '암반',
        pyogo: '31.38',
        area: '영섬권역',
        addr: '전라남도 구례군 토지면 구산리 119-11',
        LON: '127.52914167',
        LAT: '35.19787222',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: '한국수자원공사',
        gulDiaBtm: '40',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-18.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-40.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '20.0-24.0'
      },
      geometry: { type: 'Point', coordinates: [127.52914167, 35.19787222] }
    },
    {
      type: 'Feature',
      id: 158,
      properties: {
        DATE: '2024-10-26',
        jiguname: '구미고아',
        gennum: '82039',
        well: '암반',
        pyogo: '57.213',
        area: '낙동강권역',
        addr: '경상북도 구미시 고아읍 횡산리 419',
        LON: '128.28580556',
        LAT: '36.19129167',
        insdate: '2003-05-23',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국지질조사탐사업협동조합',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0-11.8',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0-70',
        cseInMat: '200',
        strHgt: 'P.V.C',
        strDia: '50.0-54.0',
        strMat: ' 58.0-62.0'
      },
      geometry: { type: 'Point', coordinates: [128.28580556, 36.19129167] }
    },
    {
      type: 'Feature',
      id: 159,
      properties: {
        DATE: '2024-10-26',
        jiguname: '구미궁기_심부2',
        gennum: '737582',
        well: '암반',
        pyogo: '39.444',
        area: '낙동강권역',
        addr: '경상북도 구미시 도개면 궁기리 876-3',
        LON: '128.32858611',
        LAT: '36.30114167',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-34.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.32858611, 36.30114167] }
    },
    {
      type: 'Feature',
      id: 160,
      properties: {
        DATE: '2024-10-26',
        jiguname: '구미궁기_천부',
        gennum: '737581',
        well: '충적',
        pyogo: '39.265',
        area: '낙동강권역',
        addr: '경상북도 구미시 도개면 궁기리 876-3',
        LON: '128.32858611',
        LAT: '36.30114167',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '5.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.32858611, 36.30114167] }
    },
    {
      type: 'Feature',
      id: 161,
      properties: {
        DATE: '2024-10-26',
        jiguname: '구미도개',
        gennum: '405434',
        well: '암반',
        pyogo: '55.3',
        area: '낙동강권역',
        addr: '경상북도 구미시 도개면 동산리 911',
        LON: '128.31704722',
        LAT: '36.32225000',
        insdate: '2013-12-26',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜이너에코',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-24.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '52-56',
        strMat: ' 64-68'
      },
      geometry: { type: 'Point', coordinates: [128.31704722, 36.32225] }
    },
    {
      type: 'Feature',
      id: 162,
      properties: {
        DATE: '2024-10-26',
        jiguname: '구미도개',
        gennum: '405435',
        well: '충적',
        pyogo: '55.3',
        area: '낙동강권역',
        addr: '경상북도 구미시 도개면 동산리 911',
        LON: '128.31704722',
        LAT: '36.32225000',
        insdate: '2013-12-26',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜이너에코',
        gulDiaTop: '18',
        gulDiaBtm: '400',
        cseExHgt: '300',
        cseExDia: '0.0-3.0',
        cseExMat: '300',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-18.5',
        cseInMat: '200',
        strHgt: 'STAINLESS STEEL',
        strDia: '13.5-18.5',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [128.31704722, 36.32225] }
    },
    {
      type: 'Feature',
      id: 163,
      properties: {
        DATE: '2024-10-26',
        jiguname: '구미봉한_심부2',
        gennum: '769747',
        well: '암반',
        pyogo: '42',
        area: '낙동강권역',
        addr: '경상북도 구미시 고아읍 봉한리 440 남계초등학교',
        LON: '128.34819444',
        LAT: '36.18115556',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-30.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.34819444, 36.18115556] }
    },
    {
      type: 'Feature',
      id: 164,
      properties: {
        DATE: '2024-10-26',
        jiguname: '구미봉한_천부',
        gennum: '769748',
        well: '충적',
        pyogo: '42',
        area: '낙동강권역',
        addr: '경상북도 구미시 고아읍 봉한리 440 남계초등학교',
        LON: '128.34819444',
        LAT: '36.18115556',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10.5',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.5',
        cseInMat: '100',
        strHgt: '',
        strDia: '4.5-10.5',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.34819444, 36.18115556] }
    },
    {
      type: 'Feature',
      id: 165,
      properties: {
        DATE: '2024-10-26',
        jiguname: '구미선기',
        gennum: '771372',
        well: '암반',
        pyogo: '66.7',
        area: '낙동강권역',
        addr: '경상북도 구미시 선기동  89-1',
        LON: '128.29065833',
        LAT: '36.14248611',
        insdate: '2022-01-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-9.5',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P.V.C(VG1)',
        strDia: '12.0-24.0',
        strMat: ' 60.0-90.0'
      },
      geometry: { type: 'Point', coordinates: [128.29065833, 36.14248611] }
    },
    {
      type: 'Feature',
      id: 166,
      properties: {
        DATE: '2024-10-26',
        jiguname: '구미송삼_심부2',
        gennum: '769749',
        well: '암반',
        pyogo: '75.186',
        area: '낙동강권역',
        addr: '경상북도 구미시 무을면 송삼리 808-2 무을보건진료소',
        LON: '128.19910556',
        LAT: '36.26082500',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-25.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.19910556, 36.260825] }
    },
    {
      type: 'Feature',
      id: 167,
      properties: {
        DATE: '2024-10-26',
        jiguname: '구미송삼_천부',
        gennum: '769750',
        well: '충적',
        pyogo: '75.186',
        area: '낙동강권역',
        addr: '경상북도 구미시 무을면 송삼리 808-2 무을보건진료소',
        LON: '128.19910556',
        LAT: '36.26082500',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.19910556, 36.260825] }
    },
    {
      type: 'Feature',
      id: 168,
      properties: {
        DATE: '2024-10-26',
        jiguname: '구미이문_심부2',
        gennum: '769751',
        well: '암반',
        pyogo: '38.255',
        area: '낙동강권역',
        addr: '경상북도 구미시 선산읍 이문리 509 구미시농업기술센터',
        LON: '128.29033333',
        LAT: '36.23342778',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '78',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-16.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-78.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '75.0-78.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.29033333, 36.23342778] }
    },
    {
      type: 'Feature',
      id: 169,
      properties: {
        DATE: '2024-10-26',
        jiguname: '구미이문_천부',
        gennum: '769752',
        well: '충적',
        pyogo: '38.255',
        area: '낙동강권역',
        addr: '경상북도 구미시 선산읍 이문리 509 구미시농업기술센터',
        LON: '128.29033333',
        LAT: '36.23342778',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.29033333, 36.23342778] }
    },
    {
      type: 'Feature',
      id: 170,
      properties: {
        DATE: '2024-10-26',
        jiguname: '구미인의_심부2',
        gennum: '769753',
        well: '암반',
        pyogo: '130',
        area: '낙동강권역',
        addr: '경상북도 구미시 인의동  2 천생산성 산림욕장',
        LON: '128.44748611',
        LAT: '36.10444722',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-2.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.44748611, 36.10444722] }
    },
    {
      type: 'Feature',
      id: 171,
      properties: {
        DATE: '2024-10-26',
        jiguname: '구미인의_천부',
        gennum: '769754',
        well: '충적',
        pyogo: '130',
        area: '낙동강권역',
        addr: '경상북도 구미시 인의동  2 천생산성 산림욕장',
        LON: '128.44748611',
        LAT: '36.10444722',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '15',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-15.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '6.0-15.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.44748611, 36.10444722] }
    },
    {
      type: 'Feature',
      id: 172,
      properties: {
        DATE: '2024-10-26',
        jiguname: '구미하장_심부2',
        gennum: '737584',
        well: '암반',
        pyogo: '54',
        area: '낙동강권역',
        addr: '경상북도 구미시 장천면 하장리 503-1',
        LON: '128.49492222',
        LAT: '36.13020278',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-15.8',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.49492222, 36.13020278] }
    },
    {
      type: 'Feature',
      id: 173,
      properties: {
        DATE: '2024-10-26',
        jiguname: '구미하장_천부',
        gennum: '737583',
        well: '충적',
        pyogo: '54',
        area: '낙동강권역',
        addr: '경상북도 구미시 장천면 하장리 503-1',
        LON: '128.49492222',
        LAT: '36.13020278',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '12',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-12.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '6.0-12.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.49492222, 36.13020278] }
    },
    {
      type: 'Feature',
      id: 174,
      properties: {
        DATE: '2024-10-26',
        jiguname: '군산서수',
        gennum: '11772',
        well: '암반',
        pyogo: '7.09',
        area: '금강권역',
        addr: '전북특별자치도 군산시 서수면 서수리 771-4 서수면사무소',
        LON: '126.87605833',
        LAT: '35.98345556',
        insdate: '1996-12-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '68',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-15.8',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0.0-68.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '52.0-64.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [126.87605833, 35.98345556] }
    },
    {
      type: 'Feature',
      id: 175,
      properties: {
        DATE: '2024-10-26',
        jiguname: '군산임피',
        gennum: '95522',
        well: '암반',
        pyogo: '15.4',
        area: '금강권역',
        addr: '전북특별자치도 군산시 임피면 읍내리 216-1 임피면사무소 내',
        LON: '126.85038611',
        LAT: '35.98225000',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-20.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '36.0-40.0'
      },
      geometry: { type: 'Point', coordinates: [126.85038611, 35.98225] }
    },
    {
      type: 'Feature',
      id: 176,
      properties: {
        DATE: '2024-10-26',
        jiguname: '군산임피',
        gennum: '95523',
        well: '충적',
        pyogo: '15.44',
        area: '금강권역',
        addr: '전북특별자치도 군산시 임피면 읍내리 216-1 임피면사무소 내',
        LON: '126.85038611',
        LAT: '35.98225000',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '21',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-14.0',
        cseInHgt: '18.0-21.0',
        cseInDia: '200',
        cseInMat: 'STAINLESS STEEL',
        strHgt: '0-21',
        strDia: '',
        strMat: ''
      },
      geometry: { type: 'Point', coordinates: [126.85038611, 35.98225] }
    },
    {
      type: 'Feature',
      id: 177,
      properties: {
        DATE: '2024-10-26',
        jiguname: '군위산성',
        gennum: '95531',
        well: '암반',
        pyogo: '134.87',
        area: '낙동강권역',
        addr: '대구광역시 군위군 산성면 화본리 1129-4',
        LON: '128.69173611',
        LAT: '36.12243056',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-1.7',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '30.0-34.0'
      },
      geometry: { type: 'Point', coordinates: [128.69173611, 36.12243056] }
    },
    {
      type: 'Feature',
      id: 178,
      properties: {
        DATE: '2024-10-26',
        jiguname: '군위의흥',
        gennum: '9892',
        well: '암반',
        pyogo: '123.56',
        area: '낙동강권역',
        addr: '대구광역시 군위군 의흥면 읍내리 248-3',
        LON: '128.71690000',
        LAT: '36.17228333',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '76',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-9.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0.0-76.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '24.0-28.0'
      },
      geometry: { type: 'Point', coordinates: [128.7169, 36.17228333] }
    },
    {
      type: 'Feature',
      id: 179,
      properties: {
        DATE: '2024-10-26',
        jiguname: '군위의흥',
        gennum: '9893',
        well: '충적',
        pyogo: '123.51',
        area: '낙동강권역',
        addr: '대구광역시 군위군 의흥면 읍내리 248-3',
        LON: '128.71690000',
        LAT: '36.17228333',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-10.0',
        cseInHgt: '250',
        cseInDia: '백관',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6.0-10.0'
      },
      geometry: { type: 'Point', coordinates: [128.7169, 36.17228333] }
    },
    {
      type: 'Feature',
      id: 180,
      properties: {
        DATE: '2024-10-26',
        jiguname: '금산금산',
        gennum: '73515',
        well: '암반',
        pyogo: '144.22',
        area: '금강권역',
        addr: '충청남도 금산군 금산읍 신대리 37-2 충남 금산군 금산읍 신대리 37-2(하수처리장)',
        LON: '127.51225278',
        LAT: '36.10014444',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-17.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.51225278, 36.10014444] }
    },
    {
      type: 'Feature',
      id: 181,
      properties: {
        DATE: '2024-10-26',
        jiguname: '금산금산',
        gennum: '73516',
        well: '충적',
        pyogo: '144.22',
        area: '금강권역',
        addr: '충청남도 금산군 금산읍 신대리 37-2',
        LON: '127.51225278',
        LAT: '36.10014444',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-10.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6.0-10.0'
      },
      geometry: { type: 'Point', coordinates: [127.51225278, 36.10014444] }
    },
    {
      type: 'Feature',
      id: 182,
      properties: {
        DATE: '2024-10-26',
        jiguname: '금산복수',
        gennum: '9912',
        well: '암반',
        pyogo: '142.62',
        area: '금강권역',
        addr: '충청남도 금산군 복수면 곡남리 469-2 복수의용소방대내',
        LON: '127.39910000',
        LAT: '36.16303056',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '51',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-30.0',
        cseExMat: '250',
        cseInHgt: '백관',
        cseInDia: '0.0-51.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [127.3991, 36.16303056] }
    },
    {
      type: 'Feature',
      id: 183,
      properties: {
        DATE: '2024-10-26',
        jiguname: '금산복수',
        gennum: '9913',
        well: '충적',
        pyogo: '142.62',
        area: '금강권역',
        addr: '충청남도 금산군 복수면 곡남리 469-2 복수의용소방대내',
        LON: '127.39910000',
        LAT: '36.16303056',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '25',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-10.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-24.0',
        cseInMat: '200',
        strHgt: '백관',
        strDia: '4.0-8.0',
        strMat: ' 12.0-18.0'
      },
      geometry: { type: 'Point', coordinates: [127.3991, 36.16303056] }
    },
    {
      type: 'Feature',
      id: 184,
      properties: {
        DATE: '2024-10-26',
        jiguname: '금산진산',
        gennum: '769853',
        well: '암반',
        pyogo: '174.4',
        area: '금강권역',
        addr: '충청남도 금산군 진산면 묵산리 175',
        LON: '127.35722222',
        LAT: '36.15666944',
        insdate: '2020-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0-6',
        cseExMat: '200',
        cseInHgt: 'stainless steel',
        cseInDia: '0-100',
        cseInMat: '150',
        strHgt: 'P.V.C(VG1)',
        strDia: '8-96',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [127.35722222, 36.15666944] }
    },
    {
      type: 'Feature',
      id: 185,
      properties: {
        DATE: '2024-10-26',
        jiguname: '금산하옥_심부2',
        gennum: '737588',
        well: '암반',
        pyogo: '213',
        area: '금강권역',
        addr: '충청남도 금산군 금산읍 하옥리 73',
        LON: '127.49017778',
        LAT: '36.08247500',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-3.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.49017778, 36.082475] }
    },
    {
      type: 'Feature',
      id: 186,
      properties: {
        DATE: '2024-10-26',
        jiguname: '금산하옥_천부',
        gennum: '737587',
        well: '충적',
        pyogo: '213',
        area: '금강권역',
        addr: '충청남도 금산군 금산읍 하옥리 73',
        LON: '127.49017778',
        LAT: '36.08247500',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10.5',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.5',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '1.5-10.5',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.49017778, 36.082475] }
    },
    {
      type: 'Feature',
      id: 187,
      properties: {
        DATE: '2024-10-26',
        jiguname: '김제봉남',
        gennum: '9904',
        well: '암반',
        pyogo: '15.91',
        area: '금강권역',
        addr: '전북특별자치도 김제시 봉남면 화봉리 11-3',
        LON: '126.98598056',
        LAT: '35.73658056',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-23.0',
        cseExMat: '250',
        cseInHgt: '백관',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [126.98598056, 35.73658056] }
    },
    {
      type: 'Feature',
      id: 188,
      properties: {
        DATE: '2024-10-26',
        jiguname: '김제부량',
        gennum: '87253',
        well: '암반',
        pyogo: '5.67',
        area: '금강권역',
        addr: '전북특별자치도 김제시 부량면 용성리 468-16',
        LON: '126.84888056',
        LAT: '35.74703889',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: '한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-28.4',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '36.0-40.0'
      },
      geometry: { type: 'Point', coordinates: [126.84888056, 35.74703889] }
    },
    {
      type: 'Feature',
      id: 189,
      properties: {
        DATE: '2024-10-26',
        jiguname: '김제용지',
        gennum: '84028',
        well: '암반',
        pyogo: '15.04',
        area: '금강권역',
        addr: '전북특별자치도 김제시 용지면 장신리  994-8',
        LON: '126.97924444',
        LAT: '35.86196944',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-31.2',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [126.97924444, 35.86196944] }
    },
    {
      type: 'Feature',
      id: 190,
      properties: {
        DATE: '2024-10-26',
        jiguname: '김제죽산',
        gennum: '601713',
        well: '암반',
        pyogo: '4.71',
        area: '금강권역',
        addr: '전북특별자치도 김제시 죽산면 연포리 1041',
        LON: '126.80111111',
        LAT: '35.75333333',
        insdate: '2011-12-07',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜한국건업엔지니어링',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-42.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '36-40',
        strMat: ' 52-56'
      },
      geometry: { type: 'Point', coordinates: [126.80111111, 35.75333333] }
    },
    {
      type: 'Feature',
      id: 191,
      properties: {
        DATE: '2024-10-26',
        jiguname: '김제죽산2_심부2',
        gennum: '769755',
        well: '암반',
        pyogo: '5.3',
        area: '금강권역',
        addr: '전북특별자치도 김제시 죽산면 죽산리 498-1 죽산초등학교',
        LON: '126.81588611',
        LAT: '35.77271389',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-20.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '65.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.81588611, 35.77271389] }
    },
    {
      type: 'Feature',
      id: 192,
      properties: {
        DATE: '2024-10-26',
        jiguname: '김제죽산2_천부',
        gennum: '769756',
        well: '충적',
        pyogo: '5.3',
        area: '금강권역',
        addr: '전북특별자치도 김제시 죽산면 죽산리 498-1 죽산초등학교',
        LON: '126.81588611',
        LAT: '35.77271389',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.81588611, 35.77271389] }
    },
    {
      type: 'Feature',
      id: 193,
      properties: {
        DATE: '2024-10-26',
        jiguname: '김천남면',
        gennum: '793603',
        well: '암반',
        pyogo: '144.07',
        area: '',
        addr: '경상북도 김천시 남면 송곡리 740',
        LON: '128.24784722',
        LAT: '36.08345833',
        insdate: '2023-12-01',
        manager: '',
        builder: '',
        gulDep: '106',
        gulDiaTop: '300',
        gulDiaBtm: '200',
        cseExHgt: '0.0-12.0',
        cseExDia: '200',
        cseExMat: 'STEEL PIPE',
        cseInHgt: '0.0-106.0',
        cseInDia: '150',
        cseInMat: 'P. V. C',
        strHgt: '64.0-94.0',
        strDia: '150',
        strMat: 'P. V. C'
      },
      geometry: { type: 'Point', coordinates: [128.24784722, 36.08345833] }
    },
    {
      type: 'Feature',
      id: 194,
      properties: {
        DATE: '2024-10-26',
        jiguname: '김천대덕',
        gennum: '95532',
        well: '암반',
        pyogo: '228.95',
        area: '낙동강권역',
        addr: '경상북도 김천시 대덕면 관기리 372-8',
        LON: '127.96845556',
        LAT: '35.91740556',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-10.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '20.0-24.0'
      },
      geometry: { type: 'Point', coordinates: [127.96845556, 35.91740556] }
    },
    {
      type: 'Feature',
      id: 195,
      properties: {
        DATE: '2024-10-26',
        jiguname: '김천대덕',
        gennum: '95533',
        well: '충적',
        pyogo: '228.92',
        area: '낙동강권역',
        addr: '경상북도 김천시 대덕면 관기리 372-8',
        LON: '127.96845556',
        LAT: '35.91740556',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '11',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-7.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-11',
        strHgt: '',
        strDia: '',
        strMat: '7.0-11.0'
      },
      geometry: { type: 'Point', coordinates: [127.96845556, 35.91740556] }
    },
    {
      type: 'Feature',
      id: 196,
      properties: {
        DATE: '2024-10-26',
        jiguname: '김천동좌_심부2',
        gennum: '771431',
        well: '암반',
        pyogo: '92.005',
        area: '낙동강권역',
        addr: '경상북도 김천시 어모면 동좌리 592 동좌2리마을회관',
        LON: '128.10690556',
        LAT: '36.19956389',
        insdate: '2013-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-18.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '125',
        strHgt: 'PVC',
        strDia: '4.0-80.0',
        strMat: '125'
      },
      geometry: { type: 'Point', coordinates: [128.10690556, 36.19956389] }
    },
    {
      type: 'Feature',
      id: 197,
      properties: {
        DATE: '2024-10-26',
        jiguname: '김천동좌_천부',
        gennum: '771432',
        well: '충적',
        pyogo: '92.005',
        area: '낙동강권역',
        addr: '경상북도 김천시 어모면 동좌리 592 동좌2리마을회관',
        LON: '128.10690556',
        LAT: '36.19956389',
        insdate: '2013-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '5.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.10690556, 36.19956389] }
    },
    {
      type: 'Feature',
      id: 198,
      properties: {
        DATE: '2024-10-26',
        jiguname: '김천부항',
        gennum: '84030',
        well: '암반',
        pyogo: '242.775',
        area: '낙동강권역',
        addr: '경상북도 김천시 부항면 사등리  515-1 면사무소내',
        LON: '127.95481389',
        LAT: '35.99015556',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-9.4',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.95481389, 35.99015556] }
    },
    {
      type: 'Feature',
      id: 199,
      properties: {
        DATE: '2024-10-26',
        jiguname: '김천지좌',
        gennum: '73535',
        well: '암반',
        pyogo: '91.03',
        area: '낙동강권역',
        addr: '경상북도 김천시 지좌동  440-128',
        LON: '128.14466389',
        LAT: '36.11992222',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-8.5',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [128.14466389, 36.11992222] }
    },
    {
      type: 'Feature',
      id: 200,
      properties: {
        DATE: '2024-10-26',
        jiguname: '김포김포',
        gennum: '11738',
        well: '암반',
        pyogo: '6.79',
        area: '한강권역',
        addr: '경기도 김포시 걸포동  1550-10 김포읍 걸포리 폐제방',
        LON: '126.71004167',
        LAT: '37.63294444',
        insdate: '1996-08-29',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '80',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-17.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0.0-80.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '60.0-64.0',
        strMat: ' 68.0-72.0'
      },
      geometry: { type: 'Point', coordinates: [126.71004167, 37.63294444] }
    },
    {
      type: 'Feature',
      id: 201,
      properties: {
        DATE: '2024-10-26',
        jiguname: '김포김포',
        gennum: '11739',
        well: '충적',
        pyogo: '6.79',
        area: '한강권역',
        addr: '경기도 김포시 걸포동  1550-10 김포읍 걸포리 폐제방',
        LON: '126.71004167',
        LAT: '37.63294444',
        insdate: '1996-08-29',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '15.9',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-10.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0-15.9',
        cseInMat: '',
        strHgt: '',
        strDia: '10.0-15.9',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [126.71004167, 37.63294444] }
    },
    {
      type: 'Feature',
      id: 202,
      properties: {
        DATE: '2024-10-26',
        jiguname: '김포대곶',
        gennum: '615509',
        well: '암반',
        pyogo: '9.9',
        area: '한강권역',
        addr: '경기도 김포시 대곶면 송마리 602-4',
        LON: '126.55441111',
        LAT: '37.66250278',
        insdate: '2013-08-30',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜지오그린21',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-17.5',
        cseExMat: '200',
        cseInHgt: 'STS304',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '28-32',
        strMat: ' 36-44'
      },
      geometry: { type: 'Point', coordinates: [126.55441111, 37.66250278] }
    },
    {
      type: 'Feature',
      id: 203,
      properties: {
        DATE: '2024-10-26',
        jiguname: '김포양촌',
        gennum: '87236',
        well: '암반',
        pyogo: '68.17',
        area: '한강권역',
        addr: '경기도 김포시 양촌면 석모리 318-5 운유 배수지 내',
        LON: '126.65250833',
        LAT: '37.64643056',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: '한국수자원공사',
        gulDiaBtm: '100',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-6.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '48.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [126.65250833, 37.64643056] }
    },
    {
      type: 'Feature',
      id: 204,
      properties: {
        DATE: '2024-10-26',
        jiguname: '김포월곶',
        gennum: '615514',
        well: '암반',
        pyogo: '18.28',
        area: '한강권역',
        addr: '경기도 김포시 월곶면 군하리 374-6',
        LON: '126.54922500',
        LAT: '37.71210833',
        insdate: '2013-08-30',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜지오그린21',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-14.0',
        cseExMat: '200',
        cseInHgt: 'STS304',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '20-24',
        strMat: ' 44-48'
      },
      geometry: { type: 'Point', coordinates: [126.549225, 37.71210833] }
    },
    {
      type: 'Feature',
      id: 205,
      properties: {
        DATE: '2024-10-26',
        jiguname: '김포하성',
        gennum: '612056',
        well: '암반',
        pyogo: '11.4',
        area: '한강권역',
        addr: '경기도 김포시 하성면 전류리 66-1',
        LON: '126.66250000',
        LAT: '37.69777778',
        insdate: '2012-09-05',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜지오그린21',
        gulDiaTop: '97',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-27.5',
        cseExMat: '200',
        cseInHgt: 'STS304',
        cseInDia: '0.0-97.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '29.0-49.0',
        strMat: ' 89.0-93.0'
      },
      geometry: { type: 'Point', coordinates: [126.6625, 37.69777778] }
    },
    {
      type: 'Feature',
      id: 206,
      properties: {
        DATE: '2024-10-26',
        jiguname: '김해삼정',
        gennum: '9345',
        well: '암반',
        pyogo: '5',
        area: '낙동강권역',
        addr: '경상남도 김해시 삼정동  635-1 삼성 국민학교',
        LON: '128.89972500',
        LAT: '35.23298611',
        insdate: '1995-12-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-29.5',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '30.5-42.5',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [128.899725, 35.23298611] }
    },
    {
      type: 'Feature',
      id: 207,
      properties: {
        DATE: '2024-10-26',
        jiguname: '김해생림',
        gennum: '87263',
        well: '암반',
        pyogo: '9.88',
        area: '낙동강권역',
        addr: '경상남도 김해시 생림면 안양리 837-1(창암취수장 내)',
        LON: '128.83381667',
        LAT: '35.38084167',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: '한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-4.5',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '40.0-44.0'
      },
      geometry: { type: 'Point', coordinates: [128.83381667, 35.38084167] }
    },
    {
      type: 'Feature',
      id: 208,
      properties: {
        DATE: '2024-10-26',
        jiguname: '김해생철_심부2',
        gennum: '769757',
        well: '암반',
        pyogo: '39.132',
        area: '낙동강권역',
        addr: '경상남도 김해시 생림면 생철리 13 낙동강학생교육원',
        LON: '128.85329444',
        LAT: '35.35603333',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '77',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-28.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-77.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '71.0-77.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.85329444, 35.35603333] }
    },
    {
      type: 'Feature',
      id: 209,
      properties: {
        DATE: '2024-10-26',
        jiguname: '김해생철_천부',
        gennum: '769758',
        well: '충적',
        pyogo: '39.132',
        area: '낙동강권역',
        addr: '경상남도 김해시 생림면 생철리 13 낙동강학생교육원',
        LON: '128.85329444',
        LAT: '35.35603333',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '13',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-13.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '5.5-13.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.85329444, 35.35603333] }
    },
    {
      type: 'Feature',
      id: 210,
      properties: {
        DATE: '2024-10-26',
        jiguname: '나주봉황',
        gennum: '65050',
        well: '암반',
        pyogo: '20.2',
        area: '영섬권역',
        addr: '전라남도 나주시 봉황면 죽석리 468-9 저온냉장고',
        LON: '126.78677500',
        LAT: '34.96481389',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-10.9',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.786775, 34.96481389] }
    },
    {
      type: 'Feature',
      id: 211,
      properties: {
        DATE: '2024-10-26',
        jiguname: '나주봉황',
        gennum: '65051',
        well: '충적',
        pyogo: '20.18',
        area: '영섬권역',
        addr: '전라남도 나주시 봉황면 죽석리 468-9 저온냉장고',
        LON: '126.78677500',
        LAT: '34.96481389',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '8.5',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-4.5',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-8.5',
        strHgt: '',
        strDia: '',
        strMat: '4.5-8.5'
      },
      geometry: { type: 'Point', coordinates: [126.786775, 34.96481389] }
    },
    {
      type: 'Feature',
      id: 212,
      properties: {
        DATE: '2024-10-26',
        jiguname: '나주삼도',
        gennum: '11773',
        well: '암반',
        pyogo: '10.24',
        area: '영섬권역',
        addr: '전라남도 나주시 삼도동  1402-1 청동배수장내',
        LON: '126.73115278',
        LAT: '35.03846389',
        insdate: '1996-09-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '67',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-26.9',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0.0-67.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '28.0-32.0',
        strMat: ' 56.0-64.0'
      },
      geometry: { type: 'Point', coordinates: [126.73115278, 35.03846389] }
    },
    {
      type: 'Feature',
      id: 213,
      properties: {
        DATE: '2024-10-26',
        jiguname: '나주삼도',
        gennum: '11774',
        well: '충적',
        pyogo: '10.24',
        area: '영섬권역',
        addr: '전라남도 나주시 삼도동  1402-1 청동배수장내',
        LON: '126.73115278',
        LAT: '35.03846389',
        insdate: '1996-09-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '10.4',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-5.4',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0-10.4',
        cseInMat: '',
        strHgt: '',
        strDia: '5.4-9.4',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [126.73115278, 35.03846389] }
    },
    {
      type: 'Feature',
      id: 214,
      properties: {
        DATE: '2024-10-26',
        jiguname: '나주왕곡',
        gennum: '637537',
        well: '암반',
        pyogo: '9.526',
        area: '영섬권역',
        addr: '전라남도 나주시 왕곡면 장산리 729 1',
        LON: '126.70529722',
        LAT: '34.97721111',
        insdate: '2015-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜대동공영',
        gulDiaTop: '101',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-14.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-101.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '20.0-28.0',
        strMat: ' 36.0-48.0'
      },
      geometry: { type: 'Point', coordinates: [126.70529722, 34.97721111] }
    },
    {
      type: 'Feature',
      id: 215,
      properties: {
        DATE: '2024-10-26',
        jiguname: '나주죽석_심부2',
        gennum: '769759',
        well: '암반',
        pyogo: '29.34',
        area: '영섬권역',
        addr: '전라남도 나주시 봉황면 죽석리 771 봉황초등학교',
        LON: '126.78979722',
        LAT: '34.95677222',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-6.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.78979722, 34.95677222] }
    },
    {
      type: 'Feature',
      id: 216,
      properties: {
        DATE: '2024-10-26',
        jiguname: '나주죽석_천부',
        gennum: '769760',
        well: '충적',
        pyogo: '29.34',
        area: '영섬권역',
        addr: '전라남도 나주시 봉황면 죽석리 771 봉황초등학교',
        LON: '126.78979722',
        LAT: '34.95677222',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-3.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.78979722, 34.95677222] }
    },
    {
      type: 'Feature',
      id: 217,
      properties: {
        DATE: '2024-10-26',
        jiguname: '남양주별내',
        gennum: '84013',
        well: '암반',
        pyogo: '63.338',
        area: '한강권역',
        addr: '경기도 남양주시 별내면 광전리  118-15',
        LON: '127.12485000',
        LAT: '37.70100000',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-19.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.12485, 37.701] }
    },
    {
      type: 'Feature',
      id: 218,
      properties: {
        DATE: '2024-10-26',
        jiguname: '남양주별내',
        gennum: '84014',
        well: '충적',
        pyogo: '63.387',
        area: '한강권역',
        addr: '경기도 남양주시 별내면 광전리  118-15',
        LON: '127.12485000',
        LAT: '37.70100000',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '12',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-8.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-12',
        strHgt: '',
        strDia: '',
        strMat: '8.0-12.0'
      },
      geometry: { type: 'Point', coordinates: [127.12485, 37.701] }
    },
    {
      type: 'Feature',
      id: 219,
      properties: {
        DATE: '2024-10-26',
        jiguname: '남원도통',
        gennum: '5724',
        well: '암반',
        pyogo: '108.33',
        area: '영섬권역',
        addr: '전북특별자치도 남원시 도통동  554 도통동 사무소',
        LON: '127.40046389',
        LAT: '35.41968333',
        insdate: '1995-12-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '60',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-6.0',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0.0-60.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '18.0-26.0',
        strMat: ' 42.0-50.0'
      },
      geometry: { type: 'Point', coordinates: [127.40046389, 35.41968333] }
    },
    {
      type: 'Feature',
      id: 220,
      properties: {
        DATE: '2024-10-26',
        jiguname: '남원도통',
        gennum: '5723',
        well: '충적',
        pyogo: '108.29',
        area: '영섬권역',
        addr: '전북특별자치도 남원시 도통동  554 도통동 사무소',
        LON: '127.40046389',
        LAT: '35.41968333',
        insdate: '1995-12-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '10',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-6.0',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0-10',
        cseInMat: '',
        strHgt: '',
        strDia: '3.0-6.0',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [127.40046389, 35.41968333] }
    },
    {
      type: 'Feature',
      id: 221,
      properties: {
        DATE: '2024-10-26',
        jiguname: '남원방동_심부2',
        gennum: '769761',
        well: '암반',
        pyogo: '73.24',
        area: '영섬권역',
        addr: '전북특별자치도 남원시 대강면 방동리 500-1 대강중학교',
        LON: '127.21575000',
        LAT: '35.34165556',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-9.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.21575, 35.34165556] }
    },
    {
      type: 'Feature',
      id: 222,
      properties: {
        DATE: '2024-10-26',
        jiguname: '남원방동_천부',
        gennum: '769762',
        well: '충적',
        pyogo: '73.24',
        area: '영섬권역',
        addr: '전북특별자치도 남원시 대강면 방동리 500-1 대강중학교',
        LON: '127.21575000',
        LAT: '35.34165556',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-3.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.21575, 35.34165556] }
    },
    {
      type: 'Feature',
      id: 223,
      properties: {
        DATE: '2024-10-26',
        jiguname: '남원사매',
        gennum: '601714',
        well: '암반',
        pyogo: '137.76',
        area: '영섬권역',
        addr: '전북특별자치도 남원시 사매면 대신리 199',
        LON: '127.37652500',
        LAT: '35.48164444',
        insdate: '2011-12-07',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜한국건업엔지니어링',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-11.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '36-40',
        strMat: ' 52-56'
      },
      geometry: { type: 'Point', coordinates: [127.376525, 35.48164444] }
    },
    {
      type: 'Feature',
      id: 224,
      properties: {
        DATE: '2024-10-26',
        jiguname: '남원산곡_심부2',
        gennum: '771433',
        well: '암반',
        pyogo: '172.72',
        area: '영섬권역',
        addr: '전북특별자치도 남원시 산곡동  251-21 교룡산국민관광지',
        LON: '127.36445833',
        LAT: '35.42865000',
        insdate: '2017-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-6.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.36445833, 35.42865] }
    },
    {
      type: 'Feature',
      id: 225,
      properties: {
        DATE: '2024-10-26',
        jiguname: '남원산내',
        gennum: '753910',
        well: '암반',
        pyogo: '339.908',
        area: '낙동강권역',
        addr: '전북특별자치도 남원시 산내면 장항리 185-1',
        LON: '127.61823333',
        LAT: '35.41841667',
        insdate: '2019-12-05',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '250',
        cseExHgt: '200',
        cseExDia: '0-34',
        cseExMat: '250',
        cseInHgt: '강관',
        cseInDia: '0-100',
        cseInMat: '200',
        strHgt: 'PVC',
        strDia: '34-100',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [127.61823333, 35.41841667] }
    },
    {
      type: 'Feature',
      id: 226,
      properties: {
        DATE: '2024-10-26',
        jiguname: '남원산내',
        gennum: '753909',
        well: '충적',
        pyogo: '339.903',
        area: '낙동강권역',
        addr: '전북특별자치도 남원시 산내면 장항리 185-1',
        LON: '127.61823333',
        LAT: '35.41841667',
        insdate: '2019-12-05',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '22',
        gulDiaBtm: '250',
        cseExHgt: '200',
        cseExDia: '0-4',
        cseExMat: '250',
        cseInHgt: '강관',
        cseInDia: '0-22',
        cseInMat: '200',
        strHgt: 'PVC',
        strDia: '4-22',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [127.61823333, 35.41841667] }
    },
    {
      type: 'Feature',
      id: 227,
      properties: {
        DATE: '2024-10-26',
        jiguname: '남원이백',
        gennum: '637535',
        well: '암반',
        pyogo: '211.987',
        area: '영섬권역',
        addr: '전북특별자치도 남원시 이백면 강기리 180-2 1',
        LON: '127.46967222',
        LAT: '35.45230000',
        insdate: '2015-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜대동공영',
        gulDiaTop: '98',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-18.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-98.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '60.0-64.0',
        strMat: ' 72.0-80.0'
      },
      geometry: { type: 'Point', coordinates: [127.46967222, 35.4523] }
    },
    {
      type: 'Feature',
      id: 228,
      properties: {
        DATE: '2024-10-26',
        jiguname: '남원택내_심부2',
        gennum: '737590',
        well: '암반',
        pyogo: '60.66',
        area: '영섬권역',
        addr: '전북특별자치도 남원시 금지면 택내리 37-2',
        LON: '127.29256667',
        LAT: '35.33352778',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-6.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.29256667, 35.33352778] }
    },
    {
      type: 'Feature',
      id: 229,
      properties: {
        DATE: '2024-10-26',
        jiguname: '남원택내_천부',
        gennum: '737589',
        well: '충적',
        pyogo: '60.66',
        area: '영섬권역',
        addr: '전북특별자치도 남원시 금지면 택내리 37-2',
        LON: '127.29256667',
        LAT: '35.33352778',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-3.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.29256667, 35.33352778] }
    },
    {
      type: 'Feature',
      id: 230,
      properties: {
        DATE: '2024-10-26',
        jiguname: '남해남해',
        gennum: '84039',
        well: '암반',
        pyogo: '90.494',
        area: '낙동강권역',
        addr: '경상남도 남해군 남해읍 아산리  1053-2 아산정수장내',
        LON: '127.88354722',
        LAT: '34.84309444',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '100',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0 0 18.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-100.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '87.0-91.0'
      },
      geometry: { type: 'Point', coordinates: [127.88354722, 34.84309444] }
    },
    {
      type: 'Feature',
      id: 231,
      properties: {
        DATE: '2024-10-26',
        jiguname: '남해봉화_심부2',
        gennum: '737592',
        well: '암반',
        pyogo: '157.34',
        area: '낙동강권역',
        addr: '경상남도 남해군 삼동면 봉화리 산519-2',
        LON: '128.01304722',
        LAT: '34.75766389',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '82',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-17.3',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-82.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '79.0-82.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.01304722, 34.75766389] }
    },
    {
      type: 'Feature',
      id: 232,
      properties: {
        DATE: '2024-10-26',
        jiguname: '남해봉화_천부',
        gennum: '737591',
        well: '충적',
        pyogo: '157.327',
        area: '낙동강권역',
        addr: '경상남도 남해군 삼동면 봉화리 산519-2',
        LON: '128.01304722',
        LAT: '34.75766389',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '29',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-11.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-29.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '11.0-29.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.01304722, 34.75766389] }
    },
    {
      type: 'Feature',
      id: 233,
      properties: {
        DATE: '2024-10-26',
        jiguname: '남해창선',
        gennum: '717017',
        well: '암반',
        pyogo: '90.77',
        area: '낙동강권역',
        addr: '경상남도 남해군 창선면 옥천리 955',
        LON: '127.99464167',
        LAT: '34.86076389',
        insdate: '2017-12-14',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜에스제이지반기술',
        gulDiaTop: '150',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-12.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-150',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '28-32',
        strMat: ' 36-40'
      },
      geometry: { type: 'Point', coordinates: [127.99464167, 34.86076389] }
    },
    {
      type: 'Feature',
      id: 234,
      properties: {
        DATE: '2024-10-26',
        jiguname: '논산광석',
        gennum: '622076',
        well: '암반',
        pyogo: '8.96',
        area: '금강권역',
        addr: '충청남도 논산시 광석면 천동리 40-5 충청남도 논산시 광석면 천동리40-5번지',
        LON: '127.09722222',
        LAT: '36.23138889',
        insdate: '2014-11-28',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜대흥개발',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-19.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '36-40',
        strMat: ' 52-56'
      },
      geometry: { type: 'Point', coordinates: [127.09722222, 36.23138889] }
    },
    {
      type: 'Feature',
      id: 235,
      properties: {
        DATE: '2024-10-26',
        jiguname: '논산마구평_심부2',
        gennum: '737594',
        well: '암반',
        pyogo: '18.5',
        area: '금강권역',
        addr: '충청남도 논산시 부적면 마구평리 439',
        LON: '127.12452778',
        LAT: '36.21663333',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-17.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.12452778, 36.21663333] }
    },
    {
      type: 'Feature',
      id: 236,
      properties: {
        DATE: '2024-10-26',
        jiguname: '논산마구평_천부',
        gennum: '737593',
        well: '충적',
        pyogo: '18.5',
        area: '금강권역',
        addr: '충청남도 논산시 부적면 마구평리 439',
        LON: '127.12452778',
        LAT: '36.21663333',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10.5',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.12452778, 36.21663333] }
    },
    {
      type: 'Feature',
      id: 237,
      properties: {
        DATE: '2024-10-26',
        jiguname: '논산벌곡',
        gennum: '717027',
        well: '암반',
        pyogo: '114.61',
        area: '금강권역',
        addr: '충청남도 논산시 벌곡면 한삼천리 204-1',
        LON: '127.26499444',
        LAT: '36.20536111',
        insdate: '2017-12-05',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜송암',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-11.7',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '12-32',
        strMat: ' 40-52'
      },
      geometry: { type: 'Point', coordinates: [127.26499444, 36.20536111] }
    },
    {
      type: 'Feature',
      id: 238,
      properties: {
        DATE: '2024-10-26',
        jiguname: '논산상월',
        gennum: '11765',
        well: '암반',
        pyogo: '22.01',
        area: '금강권역',
        addr: '충청남도 논산시 상월면 주곡리 153-3',
        LON: '127.14713056',
        LAT: '36.28091111',
        insdate: '1996-11-18',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-7.4',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '14.0-18.0',
        strMat: ' 34.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [127.14713056, 36.28091111] }
    },
    {
      type: 'Feature',
      id: 239,
      properties: {
        DATE: '2024-10-26',
        jiguname: '논산양촌',
        gennum: '793615',
        well: '암반',
        pyogo: '39.28',
        area: '',
        addr: '충청남도 논산시 양촌면 모촌리 365-3',
        LON: '127.20864152',
        LAT: '36.15462023',
        insdate: '2023-12-01',
        manager: '',
        builder: '',
        gulDep: '100',
        gulDiaTop: '300',
        gulDiaBtm: '200',
        cseExHgt: '0.0-21.0',
        cseExDia: '200',
        cseExMat: 'STEEL PIPE',
        cseInHgt: '0.0-100.0',
        cseInDia: '150',
        cseInMat: 'P. V. C',
        strHgt: '70.0-100.0',
        strDia: '150',
        strMat: 'P. V. C'
      },
      geometry: { type: 'Point', coordinates: [127.20864152, 36.15462023] }
    },
    {
      type: 'Feature',
      id: 240,
      properties: {
        DATE: '2024-10-26',
        jiguname: '논산양촌',
        gennum: '793616',
        well: '충적',
        pyogo: '39.28',
        area: '',
        addr: '충청남도 논산시 양촌면 모촌리 365-3',
        LON: '127.20862010',
        LAT: '36.15462606',
        insdate: '2023-12-01',
        manager: '',
        builder: '',
        gulDep: '20',
        gulDiaTop: '400',
        gulDiaBtm: '300',
        cseExHgt: '0.0-3.0',
        cseExDia: '300',
        cseExMat: 'STEEL PIPE',
        cseInHgt: '0.0-20.0',
        cseInDia: '200',
        cseInMat: 'P. V. C',
        strHgt: '19.0-20.0',
        strDia: '200',
        strMat: 'P. V. C'
      },
      geometry: { type: 'Point', coordinates: [127.2086201, 36.15462606] }
    },
    {
      type: 'Feature',
      id: 241,
      properties: {
        DATE: '2024-10-26',
        jiguname: '논산연산',
        gennum: '717025',
        well: '암반',
        pyogo: '85.86',
        area: '금강권역',
        addr: '충청남도 논산시 연산면 신암리 190-20',
        LON: '127.21615556',
        LAT: '36.20735000',
        insdate: '2017-12-05',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜송암',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-14.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '20-40',
        strMat: ' 44-56'
      },
      geometry: { type: 'Point', coordinates: [127.21615556, 36.20735] }
    },
    {
      type: 'Feature',
      id: 242,
      properties: {
        DATE: '2024-10-26',
        jiguname: '단양단양',
        gennum: '65014',
        well: '암반',
        pyogo: '320.43',
        area: '한강권역',
        addr: '충청북도 단양군 단양읍 천동리 380-16 유원지 내',
        LON: '128.41944167',
        LAT: '36.96462222',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '65',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-13.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0.0-65.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '44.0-48.0'
      },
      geometry: { type: 'Point', coordinates: [128.41944167, 36.96462222] }
    },
    {
      type: 'Feature',
      id: 243,
      properties: {
        DATE: '2024-10-26',
        jiguname: '담양금성',
        gennum: '637536',
        well: '암반',
        pyogo: '69.83',
        area: '영섬권역',
        addr: '전라남도 담양군 금성면 대곡리 91-1 1',
        LON: '127.03125278',
        LAT: '35.32315278',
        insdate: '2015-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜대동공영',
        gulDiaTop: '103',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-13.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-103.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '16.0-32.0',
        strMat: ' 60.0-64.0'
      },
      geometry: { type: 'Point', coordinates: [127.03125278, 35.32315278] }
    },
    {
      type: 'Feature',
      id: 244,
      properties: {
        DATE: '2024-10-26',
        jiguname: '담양담양',
        gennum: '9898',
        well: '암반',
        pyogo: '44.88',
        area: '영섬권역',
        addr: '전라남도 담양군 담양읍 천변리 401-1 담양죽세진흥지내',
        LON: '126.97500278',
        LAT: '35.30929167',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '79',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-16.5',
        cseExMat: '250',
        cseInHgt: '백관',
        cseInDia: '0.0-79.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [126.97500278, 35.30929167] }
    },
    {
      type: 'Feature',
      id: 245,
      properties: {
        DATE: '2024-10-26',
        jiguname: '담양대전',
        gennum: '778271',
        well: '암반',
        pyogo: '26.7',
        area: '영섬권역',
        addr: '전라남도 담양군 대전면 응용리 246-8',
        LON: '126.88063889',
        LAT: '35.24824722',
        insdate: '2022-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '120',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-10.0',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0.0-120',
        cseInMat: '100',
        strHgt: 'P.V.C(VG1)',
        strDia: '46.0-72.0',
        strMat: '111-115'
      },
      geometry: { type: 'Point', coordinates: [126.88063889, 35.24824722] }
    },
    {
      type: 'Feature',
      id: 246,
      properties: {
        DATE: '2024-10-26',
        jiguname: '담양월계_심부2',
        gennum: '771437',
        well: '암반',
        pyogo: '135.6',
        area: '영섬권역',
        addr: '전라남도 담양군 용면 월계리 149-38 추월산',
        LON: '126.99856667',
        LAT: '35.40234444',
        insdate: '2010-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '165',
        cseExHgt: '165',
        cseExDia: '0.0-4.5',
        cseExMat: '165',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '50.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.99856667, 35.40234444] }
    },
    {
      type: 'Feature',
      id: 247,
      properties: {
        DATE: '2024-10-26',
        jiguname: '담양월계_천부',
        gennum: '771438',
        well: '충적',
        pyogo: '135.6',
        area: '영섬권역',
        addr: '전라남도 담양군 용면 월계리 149-38 추월산',
        LON: '126.99856667',
        LAT: '35.40234444',
        insdate: '2010-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '15.5',
        gulDiaBtm: '165',
        cseExHgt: '165',
        cseExDia: '0.0-0.0',
        cseExMat: '165',
        cseInHgt: '강관',
        cseInDia: '0-15.5',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '9.5-15.5',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.99856667, 35.40234444] }
    },
    {
      type: 'Feature',
      id: 248,
      properties: {
        DATE: '2024-10-26',
        jiguname: '담양지곡',
        gennum: '778281',
        well: '암반',
        pyogo: '82.4',
        area: '영섬권역',
        addr: '전라남도 담양군 가사문학면 지곡리 319',
        LON: '127.00393889',
        LAT: '35.18829722',
        insdate: '2022-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '108',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-10.0',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0.0-108',
        cseInMat: '100',
        strHgt: 'P.V.C(VG1)',
        strDia: '40.0-70.0',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [127.00393889, 35.18829722] }
    },
    {
      type: 'Feature',
      id: 249,
      properties: {
        DATE: '2024-10-26',
        jiguname: '담양천변_심부2',
        gennum: '771439',
        well: '암반',
        pyogo: '45.4',
        area: '영섬권역',
        addr: '전라남도 담양군 담양읍 천변리 401-1 대나무박물관',
        LON: '126.97814722',
        LAT: '35.30990556',
        insdate: '2010-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '165',
        cseExHgt: '165',
        cseExDia: '0.0-10.0',
        cseExMat: '165',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '62.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.97814722, 35.30990556] }
    },
    {
      type: 'Feature',
      id: 250,
      properties: {
        DATE: '2024-10-26',
        jiguname: '담양천변_천부',
        gennum: '771440',
        well: '충적',
        pyogo: '45.4',
        area: '영섬권역',
        addr: '전라남도 담양군 담양읍 천변리 401-1 대나무박물관',
        LON: '126.97814722',
        LAT: '35.30990556',
        insdate: '2010-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '165',
        cseExHgt: '165',
        cseExDia: '0.0-0.0',
        cseExMat: '165',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '6.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.97814722, 35.30990556] }
    },
    {
      type: 'Feature',
      id: 251,
      properties: {
        DATE: '2024-10-26',
        jiguname: '당진고대',
        gennum: '639083',
        well: '암반',
        pyogo: '8.24',
        area: '금강권역',
        addr: '충청남도 당진시 고대면 대촌리 산42-5 대촌리 산 42-5',
        LON: '126.58836111',
        LAT: '36.94548333',
        insdate: '2016-11-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '150.5',
        cseExHgt: '300',
        cseExDia: '200',
        cseExMat: '0.0-14.0m',
        cseInHgt: '200',
        cseInDia: 'Stainless Steel',
        cseInMat: '0.0-150.5',
        strHgt: '150',
        strDia: 'PVC(VG1)',
        strMat: '38-58'
      },
      geometry: { type: 'Point', coordinates: [126.58836111, 36.94548333] }
    },
    {
      type: 'Feature',
      id: 252,
      properties: {
        DATE: '2024-10-26',
        jiguname: '당진당진',
        gennum: '65592',
        well: '암반',
        pyogo: '12.56',
        area: '금강권역',
        addr: '충청남도 당진시 원당동  486 농업개발센타 내',
        LON: '126.65098889',
        LAT: '36.91360278',
        insdate: '2000-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-12.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.65098889, 36.91360278] }
    },
    {
      type: 'Feature',
      id: 253,
      properties: {
        DATE: '2024-10-26',
        jiguname: '당진사기소_심부2',
        gennum: '737596',
        well: '암반',
        pyogo: '25.208',
        area: '금강권역',
        addr: '충청남도 당진시 사기소동  651-2',
        LON: '126.60414167',
        LAT: '36.83610833',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-8.9',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '77.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.60414167, 36.83610833] }
    },
    {
      type: 'Feature',
      id: 254,
      properties: {
        DATE: '2024-10-26',
        jiguname: '당진사기소_천부',
        gennum: '737595',
        well: '충적',
        pyogo: '25.215',
        area: '금강권역',
        addr: '충청남도 당진시 사기소동  651-2',
        LON: '126.60414167',
        LAT: '36.83610833',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-4.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.60414167, 36.83610833] }
    },
    {
      type: 'Feature',
      id: 255,
      properties: {
        DATE: '2024-10-26',
        jiguname: '당진석문',
        gennum: '514304',
        well: '암반',
        pyogo: '11',
        area: '금강권역',
        addr: '충청남도 당진시 석문면 통정리 631-2',
        LON: '126.58954167',
        LAT: '36.98513333',
        insdate: '2010-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '유성삼정개발㈜',
        gulDiaTop: '102',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-24.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '24-28',
        strMat: ' 32-36'
      },
      geometry: { type: 'Point', coordinates: [126.58954167, 36.98513333] }
    },
    {
      type: 'Feature',
      id: 256,
      properties: {
        DATE: '2024-10-26',
        jiguname: '당진순성',
        gennum: '84023',
        well: '암반',
        pyogo: '17.9',
        area: '금강권역',
        addr: '충청남도 당진시 순성면 광천리  198-17',
        LON: '126.71723056',
        LAT: '36.84067222',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-13.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '24.0-28.0'
      },
      geometry: { type: 'Point', coordinates: [126.71723056, 36.84067222] }
    },
    {
      type: 'Feature',
      id: 257,
      properties: {
        DATE: '2024-10-26',
        jiguname: '당진순성',
        gennum: '84024',
        well: '충적',
        pyogo: '17.9',
        area: '금강권역',
        addr: '충청남도 당진시 순성면 광천리  198-17',
        LON: '126.71723056',
        LAT: '36.84067222',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-6.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6.0-10.0'
      },
      geometry: { type: 'Point', coordinates: [126.71723056, 36.84067222] }
    },
    {
      type: 'Feature',
      id: 258,
      properties: {
        DATE: '2024-10-26',
        jiguname: '당진용두_심부2',
        gennum: '771441',
        well: '암반',
        pyogo: '14.2',
        area: '금강권역',
        addr: '충청남도 당진시 고대면 용두리 664-1 고대면사무소',
        LON: '126.59710833',
        LAT: '36.92589444',
        insdate: '2013-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-18.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '72.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.59710833, 36.92589444] }
    },
    {
      type: 'Feature',
      id: 259,
      properties: {
        DATE: '2024-10-26',
        jiguname: '당진용두_천부',
        gennum: '771442',
        well: '충적',
        pyogo: '14.2',
        area: '금강권역',
        addr: '충청남도 당진시 고대면 용두리 664-1 고대면사무소',
        LON: '126.59710833',
        LAT: '36.92589444',
        insdate: '2013-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.59710833, 36.92589444] }
    },
    {
      type: 'Feature',
      id: 260,
      properties: {
        DATE: '2024-10-26',
        jiguname: '당진천의_심부2',
        gennum: '771443',
        well: '암반',
        pyogo: '9.8',
        area: '금강권역',
        addr: '충청남도 당진시 정미면 천의리 276-1 정미면사무소',
        LON: '126.56893611',
        LAT: '36.88204444',
        insdate: '2013-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-5.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '72.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.56893611, 36.88204444] }
    },
    {
      type: 'Feature',
      id: 261,
      properties: {
        DATE: '2024-10-26',
        jiguname: '당진천의_천부',
        gennum: '771444',
        well: '충적',
        pyogo: '9.8',
        area: '금강권역',
        addr: '충청남도 당진시 정미면 천의리 276-1 정미면사무소',
        LON: '126.56893611',
        LAT: '36.88204444',
        insdate: '2013-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '5.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.56893611, 36.88204444] }
    },
    {
      type: 'Feature',
      id: 262,
      properties: {
        DATE: '2024-10-26',
        jiguname: '대구가창',
        gennum: '84004',
        well: '암반',
        pyogo: '165.409',
        area: '낙동강권역',
        addr: '대구광역시 달성군 가창면 옥분리  778-8',
        LON: '128.65043056',
        LAT: '35.75279722',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-12.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '54.0-58.0'
      },
      geometry: { type: 'Point', coordinates: [128.65043056, 35.75279722] }
    },
    {
      type: 'Feature',
      id: 263,
      properties: {
        DATE: '2024-10-26',
        jiguname: '대구가창',
        gennum: '84005',
        well: '충적',
        pyogo: '165.394',
        area: '낙동강권역',
        addr: '대구광역시 달성군 가창면 옥분리  778-8',
        LON: '128.65043056',
        LAT: '35.75279722',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-6.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6.0-10.0'
      },
      geometry: { type: 'Point', coordinates: [128.65043056, 35.75279722] }
    },
    {
      type: 'Feature',
      id: 264,
      properties: {
        DATE: '2024-10-26',
        jiguname: '대구대봉',
        gennum: '65015',
        well: '암반',
        pyogo: '35.659',
        area: '낙동강권역',
        addr: '대구광역시 중구 대봉동  31-30 중구청주차장',
        LON: '128.60670278',
        LAT: '35.85994444',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '55',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-11.0',
        cseExMat: '250',
        cseInHgt: '백관',
        cseInDia: '0.0-55.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '32.0-36.0',
        strMat: ' 40.0-44.0'
      },
      geometry: { type: 'Point', coordinates: [128.60670278, 35.85994444] }
    },
    {
      type: 'Feature',
      id: 265,
      properties: {
        DATE: '2024-10-26',
        jiguname: '대구백안_심부2',
        gennum: '771445',
        well: '암반',
        pyogo: '161.02',
        area: '낙동강권역',
        addr: '대구광역시 동구 백안동  649 공산초등학교',
        LON: '128.69007500',
        LAT: '35.95755000',
        insdate: '2009-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '81',
        gulDiaBtm: '165',
        cseExHgt: '165',
        cseExDia: '0.0-6.5',
        cseExMat: '165',
        cseInHgt: '강관',
        cseInDia: '0-81',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '73.5-81.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.690075, 35.95755] }
    },
    {
      type: 'Feature',
      id: 266,
      properties: {
        DATE: '2024-10-26',
        jiguname: '대구백안_천부',
        gennum: '771446',
        well: '충적',
        pyogo: '161.2',
        area: '낙동강권역',
        addr: '대구광역시 동구 백안동  649 공산초등학교',
        LON: '128.69007500',
        LAT: '35.95755000',
        insdate: '2009-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '9',
        gulDiaBtm: '165',
        cseExHgt: '165',
        cseExDia: '0.0-0.0',
        cseExMat: '165',
        cseInHgt: '강관',
        cseInDia: '0-9',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '4.5-9.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.690075, 35.95755] }
    },
    {
      type: 'Feature',
      id: 267,
      properties: {
        DATE: '2024-10-26',
        jiguname: '대구송현_심부2',
        gennum: '737598',
        well: '암반',
        pyogo: '125.635',
        area: '낙동강권역',
        addr: '대구광역시 달서구 송현동  701',
        LON: '128.55357222',
        LAT: '35.82082778',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-12.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.55357222, 35.82082778] }
    },
    {
      type: 'Feature',
      id: 268,
      properties: {
        DATE: '2024-10-26',
        jiguname: '대구송현_천부',
        gennum: '737597',
        well: '충적',
        pyogo: '125.618',
        area: '낙동강권역',
        addr: '대구광역시 달서구 송현동  701',
        LON: '128.55357222',
        LAT: '35.82082778',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '15.5',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-8.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-15.5',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '8.0-15.5',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.55357222, 35.82082778] }
    },
    {
      type: 'Feature',
      id: 269,
      properties: {
        DATE: '2024-10-26',
        jiguname: '대구현풍',
        gennum: '9856',
        well: '암반',
        pyogo: '18.46',
        area: '낙동강권역',
        addr: '대구광역시 달성군 현풍면 성하리 725-15 구취수장내',
        LON: '128.43617778',
        LAT: '35.69653889',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-7.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [128.43617778, 35.69653889] }
    },
    {
      type: 'Feature',
      id: 270,
      properties: {
        DATE: '2024-10-26',
        jiguname: '대전매노_심부2',
        gennum: '769763',
        well: '암반',
        pyogo: '81',
        area: '금강권역',
        addr: '대전광역시 서구 매노동  163 기성초등학교',
        LON: '127.33836389',
        LAT: '36.24955833',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-36.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.33836389, 36.24955833] }
    },
    {
      type: 'Feature',
      id: 271,
      properties: {
        DATE: '2024-10-26',
        jiguname: '대전매노_천부',
        gennum: '769764',
        well: '충적',
        pyogo: '81',
        area: '금강권역',
        addr: '대전광역시 서구 매노동  163 기성초등학교',
        LON: '127.33836389',
        LAT: '36.24955833',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.33836389, 36.24955833] }
    },
    {
      type: 'Feature',
      id: 272,
      properties: {
        DATE: '2024-10-26',
        jiguname: '대전장안_심부2',
        gennum: '769765',
        well: '암반',
        pyogo: '134.195',
        area: '금강권역',
        addr: '대전광역시 서구 장안동  산48-7 장태산자연휴양림',
        LON: '127.34067500',
        LAT: '36.22132500',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-2.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.340675, 36.221325] }
    },
    {
      type: 'Feature',
      id: 273,
      properties: {
        DATE: '2024-10-26',
        jiguname: '대전장안_천부',
        gennum: '769766',
        well: '충적',
        pyogo: '134.195',
        area: '금강권역',
        addr: '대전광역시 서구 장안동  산48-7 장태산자연휴양림',
        LON: '127.34067500',
        LAT: '36.22132500',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '15',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-15.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '10.5-15.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.340675, 36.221325] }
    },
    {
      type: 'Feature',
      id: 274,
      properties: {
        DATE: '2024-10-26',
        jiguname: '대전태평',
        gennum: '65016',
        well: '암반',
        pyogo: '52.99',
        area: '금강권역',
        addr: '대전광역시 중구 태평동  510-162',
        LON: '127.38626111',
        LAT: '36.31920000',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '74',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-18.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-74.0',
        cseInMat: '250',
        strHgt: 'P. V. C',
        strDia: '48.0-52.0',
        strMat: ' 56.0-60.0'
      },
      geometry: { type: 'Point', coordinates: [127.38626111, 36.3192] }
    },
    {
      type: 'Feature',
      id: 275,
      properties: {
        DATE: '2024-10-26',
        jiguname: '대전하소_심부2',
        gennum: '769767',
        well: '암반',
        pyogo: '320.488',
        area: '금강권역',
        addr: '대전광역시 동구 하소동  460-2 만인산푸른학습원',
        LON: '127.45468333',
        LAT: '36.20162500',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-5.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '68.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.45468333, 36.201625] }
    },
    {
      type: 'Feature',
      id: 276,
      properties: {
        DATE: '2024-10-26',
        jiguname: '대전하소_천부',
        gennum: '769768',
        well: '충적',
        pyogo: '320.488',
        area: '금강권역',
        addr: '대전광역시 동구 하소동  460-2 만인산푸른학습원',
        LON: '127.45468333',
        LAT: '36.20162500',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '14',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-14.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '9.5-14.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.45468333, 36.201625] }
    },
    {
      type: 'Feature',
      id: 277,
      properties: {
        DATE: '2024-10-26',
        jiguname: '동두천상패',
        gennum: '9871',
        well: '암반',
        pyogo: '61.23',
        area: '한강권역',
        addr: '경기도 동두천시 상패동  122 시민회관내',
        LON: '127.04278056',
        LAT: '37.90582222',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-9.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [127.04278056, 37.90582222] }
    },
    {
      type: 'Feature',
      id: 278,
      properties: {
        DATE: '2024-10-26',
        jiguname: '동해귀운',
        gennum: '11748',
        well: '암반',
        pyogo: '13.96',
        area: '한강권역',
        addr: '강원특별자치도 동해시 귀운동  152-2 전천제방',
        LON: '129.09295000',
        LAT: '37.48045556',
        insdate: '1996-12-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '17',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-14.3',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0.0-17.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '13.0-17.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [129.09295, 37.48045556] }
    },
    {
      type: 'Feature',
      id: 279,
      properties: {
        DATE: '2024-10-26',
        jiguname: '동해귀운',
        gennum: '11749',
        well: '충적',
        pyogo: '13.96',
        area: '한강권역',
        addr: '강원특별자치도 동해시 귀운동  152-2 전천제방',
        LON: '129.09295000',
        LAT: '37.48045556',
        insdate: '1996-12-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '12',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-7.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0-12',
        cseInMat: '',
        strHgt: '',
        strDia: '7.0-11.0',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [129.09295, 37.48045556] }
    },
    {
      type: 'Feature',
      id: 280,
      properties: {
        DATE: '2024-10-26',
        jiguname: '목포용당',
        gennum: '6721',
        well: '암반',
        pyogo: '24.43',
        area: '영섬권역',
        addr: '전라남도 목포시 용당동  846 목포 시청',
        LON: '126.39101389',
        LAT: '34.81158333',
        insdate: '1995-12-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-12.0',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '17.0-27.0',
        strMat: ' 33.0-39.0'
      },
      geometry: { type: 'Point', coordinates: [126.39101389, 34.81158333] }
    },
    {
      type: 'Feature',
      id: 281,
      properties: {
        DATE: '2024-10-26',
        jiguname: '무안몽탄',
        gennum: '73527',
        well: '암반',
        pyogo: '3.508',
        area: '영섬권역',
        addr: '전라남도 무안군 몽탄면 명산리 786',
        LON: '126.52613056',
        LAT: '34.89289444',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-30.0',
        cseInHgt: '250',
        cseInDia: '백관',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.52613056, 34.89289444] }
    },
    {
      type: 'Feature',
      id: 282,
      properties: {
        DATE: '2024-10-26',
        jiguname: '무안무안',
        gennum: '11775',
        well: '암반',
        pyogo: '31.62',
        area: '영섬권역',
        addr: '전라남도 무안군 무안읍 성암리 574 성암 수원지 관리사무소',
        LON: '126.49156667',
        LAT: '34.97725556',
        insdate: '1996-09-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '160',
        cseExMat: '0.0-5.4',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL PIPE',
        cseInMat: '0.0-32.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '28.0-32.0'
      },
      geometry: { type: 'Point', coordinates: [126.49156667, 34.97725556] }
    },
    {
      type: 'Feature',
      id: 283,
      properties: {
        DATE: '2024-10-26',
        jiguname: '무안월암_심부2',
        gennum: '769769',
        well: '암반',
        pyogo: '11',
        area: '영섬권역',
        addr: '전라남도 무안군 일로읍 월암리 350 무안중학교',
        LON: '126.49386944',
        LAT: '34.85360833',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '84',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-52.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-84.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '78.0-84.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.49386944, 34.85360833] }
    },
    {
      type: 'Feature',
      id: 284,
      properties: {
        DATE: '2024-10-26',
        jiguname: '무안월암_천부',
        gennum: '769770',
        well: '충적',
        pyogo: '11',
        area: '영섬권역',
        addr: '전라남도 무안군 일로읍 월암리 350 무안중학교',
        LON: '126.49386944',
        LAT: '34.85360833',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '13',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-13.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '7.0-13.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.49386944, 34.85360833] }
    },
    {
      type: 'Feature',
      id: 285,
      properties: {
        DATE: '2024-10-26',
        jiguname: '무안일로',
        gennum: '612054',
        well: '암반',
        pyogo: '5.21',
        area: '영섬권역',
        addr: '전라남도 무안군 일로읍 복룡리 445-1',
        LON: '126.52542222',
        LAT: '34.87194722',
        insdate: '2012-11-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜지오그린21',
        gulDiaTop: '102',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-8.0',
        cseExMat: '200',
        cseInHgt: 'STS304',
        cseInDia: '0.0-102.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '12-24',
        strMat: ' 30-36'
      },
      geometry: { type: 'Point', coordinates: [126.52542222, 34.87194722] }
    },
    {
      type: 'Feature',
      id: 286,
      properties: {
        DATE: '2024-10-26',
        jiguname: '무안해제',
        gennum: '84029',
        well: '암반',
        pyogo: '14.42',
        area: '영섬권역',
        addr: '전라남도 무안군 해제면 광산리  152-10',
        LON: '126.28609167',
        LAT: '35.09648611',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-10.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.28609167, 35.09648611] }
    },
    {
      type: 'Feature',
      id: 287,
      properties: {
        DATE: '2024-10-26',
        jiguname: '무주무주',
        gennum: '95524',
        well: '암반',
        pyogo: '234.56',
        area: '금강권역',
        addr: '전북특별자치도 무주군 무주읍 당산리 산3-2',
        LON: '127.64772222',
        LAT: '35.99413056',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국지질조사탐사업협동조합',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-2.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '48.0-52.0',
        strMat: ' 56.0-60.0'
      },
      geometry: { type: 'Point', coordinates: [127.64772222, 35.99413056] }
    },
    {
      type: 'Feature',
      id: 288,
      properties: {
        DATE: '2024-10-26',
        jiguname: '무주무풍',
        gennum: '87254',
        well: '암반',
        pyogo: '453.48',
        area: '금강권역',
        addr: '전북특별자치도 무주군 무풍면 금평리 2277-1',
        LON: '127.87432500',
        LAT: '35.95701667',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: '한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-4.5',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '10.0-14.0'
      },
      geometry: { type: 'Point', coordinates: [127.874325, 35.95701667] }
    },
    {
      type: 'Feature',
      id: 289,
      properties: {
        DATE: '2024-10-26',
        jiguname: '무주삼공_심부2',
        gennum: '737602',
        well: '암반',
        pyogo: '606.6',
        area: '금강권역',
        addr: '전북특별자치도 무주군 설천면 삼공리 411',
        LON: '127.77562500',
        LAT: '35.90162500',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-5.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.775625, 35.901625] }
    },
    {
      type: 'Feature',
      id: 290,
      properties: {
        DATE: '2024-10-26',
        jiguname: '무주삼공_천부',
        gennum: '737601',
        well: '충적',
        pyogo: '606.6',
        area: '금강권역',
        addr: '전북특별자치도 무주군 설천면 삼공리 411',
        LON: '127.77562500',
        LAT: '35.90162500',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.775625, 35.901625] }
    },
    {
      type: 'Feature',
      id: 291,
      properties: {
        DATE: '2024-10-26',
        jiguname: '무주현내_심부2',
        gennum: '737604',
        well: '암반',
        pyogo: '375.3',
        area: '금강권역',
        addr: '전북특별자치도 무주군 무풍면 현내리 617',
        LON: '127.84406944',
        LAT: '35.96690833',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-9.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '77.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.84406944, 35.96690833] }
    },
    {
      type: 'Feature',
      id: 292,
      properties: {
        DATE: '2024-10-26',
        jiguname: '무주현내_천부',
        gennum: '737603',
        well: '충적',
        pyogo: '375.3',
        area: '금강권역',
        addr: '전북특별자치도 무주군 무풍면 현내리 617',
        LON: '127.84406944',
        LAT: '35.96690833',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '12',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-12.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '6.0-12.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.84406944, 35.96690833] }
    },
    {
      type: 'Feature',
      id: 293,
      properties: {
        DATE: '2024-10-26',
        jiguname: '문경교촌_심부2',
        gennum: '771447',
        well: '암반',
        pyogo: '191.39',
        area: '낙동강권역',
        addr: '경상북도 문경시 문경읍 교촌리 160 경상북도관광고등학교',
        LON: '128.11516389',
        LAT: '36.73826944',
        insdate: '2015-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-24..0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '72.5-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.11516389, 36.73826944] }
    },
    {
      type: 'Feature',
      id: 294,
      properties: {
        DATE: '2024-10-26',
        jiguname: '문경교촌_천부',
        gennum: '771448',
        well: '충적',
        pyogo: '191.39',
        area: '낙동강권역',
        addr: '경상북도 문경시 문경읍 교촌리 160 경상북도관광고등학교',
        LON: '128.11516389',
        LAT: '36.73826944',
        insdate: '2015-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '15',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-15',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '7.5-15.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.11516389, 36.73826944] }
    },
    {
      type: 'Feature',
      id: 295,
      properties: {
        DATE: '2024-10-26',
        jiguname: '문경농암',
        gennum: '9884',
        well: '암반',
        pyogo: '175.31',
        area: '낙동강권역',
        addr: '경상북도 문경시 농암면 종곡리 536-5',
        LON: '128.00461944',
        LAT: '36.59393611',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '51',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-30.0',
        cseExMat: '250',
        cseInHgt: '백관',
        cseInDia: '0.0-45.0',
        cseInMat: '200',
        strHgt: '백관',
        strDia: '36.0-42.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [128.00461944, 36.59393611] }
    },
    {
      type: 'Feature',
      id: 296,
      properties: {
        DATE: '2024-10-26',
        jiguname: '문경농암',
        gennum: '9885',
        well: '충적',
        pyogo: '175.33',
        area: '낙동강권역',
        addr: '경상북도 문경시 농암면 종곡리 536-5',
        LON: '128.00461944',
        LAT: '36.59393611',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '10',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-10.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0-10',
        cseInMat: '',
        strHgt: '',
        strDia: '6.0-10.0',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [128.00461944, 36.59393611] }
    },
    {
      type: 'Feature',
      id: 297,
      properties: {
        DATE: '2024-10-26',
        jiguname: '문경모전_심부2',
        gennum: '771449',
        well: '암반',
        pyogo: '128.23',
        area: '낙동강권역',
        addr: '경상북도 문경시 모전동  620 점촌고등학교',
        LON: '128.18336111',
        LAT: '36.59621389',
        insdate: '2015-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-6.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.18336111, 36.59621389] }
    },
    {
      type: 'Feature',
      id: 298,
      properties: {
        DATE: '2024-10-26',
        jiguname: '문경모전_천부',
        gennum: '771450',
        well: '충적',
        pyogo: '128.23',
        area: '낙동강권역',
        addr: '경상북도 문경시 모전동  620 점촌고등학교',
        LON: '128.18336111',
        LAT: '36.59621389',
        insdate: '2015-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '15',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-15',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '7.5-15.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.18336111, 36.59621389] }
    },
    {
      type: 'Feature',
      id: 299,
      properties: {
        DATE: '2024-10-26',
        jiguname: '문경문경',
        gennum: '65017',
        well: '암반',
        pyogo: '188.58',
        area: '낙동강권역',
        addr: '경상북도 문경시 문경읍 팔영리 694',
        LON: '128.14528889',
        LAT: '36.74422222',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '71',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-17.9',
        cseInHgt: '250',
        cseInDia: '백관',
        cseInMat: '0.0-71.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '48.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [128.14528889, 36.74422222] }
    },
    {
      type: 'Feature',
      id: 300,
      properties: {
        DATE: '2024-10-26',
        jiguname: '문경문경',
        gennum: '65018',
        well: '충적',
        pyogo: '188.59',
        area: '낙동강권역',
        addr: '경상북도 문경시 문경읍 팔영리 694',
        LON: '128.14528889',
        LAT: '36.74422222',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '15',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-15.0',
        cseInHgt: '200',
        cseInDia: '백관',
        cseInMat: '0-15',
        strHgt: '',
        strDia: '',
        strMat: '11.0-15.0'
      },
      geometry: { type: 'Point', coordinates: [128.14528889, 36.74422222] }
    },
    {
      type: 'Feature',
      id: 301,
      properties: {
        DATE: '2024-10-26',
        jiguname: '문경산북',
        gennum: '718257',
        well: '암반',
        pyogo: '165.3',
        area: '낙동강권역',
        addr: '경상북도 문경시 산북면 내화리 153-3',
        LON: '128.28666667',
        LAT: '36.69222222',
        insdate: '2018-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜호림건설',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: 'SST',
        cseInDia: '0-100',
        cseInMat: '150',
        strHgt: 'P.V.C(VG1)',
        strDia: '',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [128.28666667, 36.69222222] }
    },
    {
      type: 'Feature',
      id: 302,
      properties: {
        DATE: '2024-10-26',
        jiguname: '문경석봉',
        gennum: '793604',
        well: '암반',
        pyogo: '277.64',
        area: '',
        addr: '경상북도 문경시 산북면 석봉리 산163-1',
        LON: '128.22187500',
        LAT: '36.71638056',
        insdate: '2023-12-01',
        manager: '',
        builder: '',
        gulDep: '102',
        gulDiaTop: '300',
        gulDiaBtm: '200',
        cseExHgt: '0.0-12.0',
        cseExDia: '200',
        cseExMat: 'STEEL PIPE',
        cseInHgt: '0.0-102.0',
        cseInDia: '150',
        cseInMat: 'STEEL PIPE',
        strHgt: '68.0-98.0',
        strDia: '150',
        strMat: 'STEEL PIPE'
      },
      geometry: { type: 'Point', coordinates: [128.221875, 36.71638056] }
    },
    {
      type: 'Feature',
      id: 303,
      properties: {
        DATE: '2024-10-26',
        jiguname: '문경영순',
        gennum: '65600',
        well: '암반',
        pyogo: '105.375',
        area: '낙동강권역',
        addr: '경상북도 문경시 영순면 의곡리 22-3번지 영순면사무소 내',
        LON: '128.23573056',
        LAT: '36.58211111',
        insdate: '2000-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국지질조사탐사업협동조합',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-11.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '50.0-54.0',
        strMat: ' 58.0-62.0'
      },
      geometry: { type: 'Point', coordinates: [128.23573056, 36.58211111] }
    },
    {
      type: 'Feature',
      id: 304,
      properties: {
        DATE: '2024-10-26',
        jiguname: '밀양가곡',
        gennum: '11785',
        well: '암반',
        pyogo: '9.22',
        area: '낙동강권역',
        addr: '경상남도 밀양시 가곡동  651-12 가곡동사무소내',
        LON: '128.76461944',
        LAT: '35.47906667',
        insdate: '1996-09-18',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '68',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-26.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0.0-68.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '48.0-60.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [128.76461944, 35.47906667] }
    },
    {
      type: 'Feature',
      id: 305,
      properties: {
        DATE: '2024-10-26',
        jiguname: '밀양가곡',
        gennum: '11786',
        well: '충적',
        pyogo: '9.22',
        area: '낙동강권역',
        addr: '경상남도 밀양시 가곡동  651-12 가곡동사무소내',
        LON: '128.76461944',
        LAT: '35.47906667',
        insdate: '1996-09-18',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '7.6',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-2.6',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0-7.6',
        cseInMat: '',
        strHgt: '',
        strDia: '2.6-6.6',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [128.76461944, 35.47906667] }
    },
    {
      type: 'Feature',
      id: 306,
      properties: {
        DATE: '2024-10-26',
        jiguname: '밀양단장',
        gennum: '73544',
        well: '암반',
        pyogo: '132.787',
        area: '낙동강권역',
        addr: '경상남도 밀양시 단장면 고례리 1601',
        LON: '128.92888333',
        LAT: '35.48709167',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-12.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [128.92888333, 35.48709167] }
    },
    {
      type: 'Feature',
      id: 307,
      properties: {
        DATE: '2024-10-26',
        jiguname: '보령성주',
        gennum: '639091',
        well: '암반',
        pyogo: '222.998',
        area: '금강권역',
        addr: '충청남도 보령시 성주면 성주리 46-1',
        LON: '126.68692500',
        LAT: '36.35395278',
        insdate: '2016-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '150',
        cseExHgt: '300',
        cseExDia: '200',
        cseExMat: '0.0-17.6m',
        cseInHgt: '200',
        cseInDia: 'Stainless Steel',
        cseInMat: '0.0-150',
        strHgt: '150',
        strDia: 'PVC(VG1)',
        strMat: '58-66'
      },
      geometry: { type: 'Point', coordinates: [126.686925, 36.35395278] }
    },
    {
      type: 'Feature',
      id: 308,
      properties: {
        DATE: '2024-10-26',
        jiguname: '보령오천',
        gennum: '769854',
        well: '암반',
        pyogo: '17.6',
        area: '금강권역',
        addr: '충청남도 보령시 오천면 원산도리 746-8',
        LON: '126.42805556',
        LAT: '36.36944444',
        insdate: '2020-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '98.5',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0-22.5',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0-98.5',
        cseInMat: '150',
        strHgt: 'P.V.C(VG1)',
        strDia: '24-28',
        strMat: ' 32-36'
      },
      geometry: { type: 'Point', coordinates: [126.42805556, 36.36944444] }
    },
    {
      type: 'Feature',
      id: 309,
      properties: {
        DATE: '2024-10-26',
        jiguname: '보령옥동_심부2',
        gennum: '769771',
        well: '암반',
        pyogo: '60.168',
        area: '금강권역',
        addr: '충청남도 보령시 남포면 옥동리 331-2 보령종합경기장',
        LON: '126.60526944',
        LAT: '36.31342500',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-33.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.60526944, 36.313425] }
    },
    {
      type: 'Feature',
      id: 310,
      properties: {
        DATE: '2024-10-26',
        jiguname: '보령옥동_천부',
        gennum: '769772',
        well: '충적',
        pyogo: '60.168',
        area: '금강권역',
        addr: '충청남도 보령시 남포면 옥동리 331-2 보령종합경기장',
        LON: '126.60526944',
        LAT: '36.31342500',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '12',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-12.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '7.5-12.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.60526944, 36.313425] }
    },
    {
      type: 'Feature',
      id: 311,
      properties: {
        DATE: '2024-10-26',
        jiguname: '보령웅천',
        gennum: '82019',
        well: '암반',
        pyogo: '17.543',
        area: '금강권역',
        addr: '충청남도 보령시 웅천읍 성동리 1222-89',
        LON: '126.62583889',
        LAT: '36.25091944',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-5',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.62583889, 36.25091944] }
    },
    {
      type: 'Feature',
      id: 312,
      properties: {
        DATE: '2024-10-26',
        jiguname: '보령청라',
        gennum: '87249',
        well: '암반',
        pyogo: '54.89',
        area: '금강권역',
        addr: '충청남도 보령시 청라면 내현리 519-5',
        LON: '126.66292500',
        LAT: '36.39134167',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: '한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-15.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '28.0-32.0'
      },
      geometry: { type: 'Point', coordinates: [126.662925, 36.39134167] }
    },
    {
      type: 'Feature',
      id: 313,
      properties: {
        DATE: '2024-10-26',
        jiguname: '보령청라',
        gennum: '87250',
        well: '충적',
        pyogo: '54.85',
        area: '금강권역',
        addr: '충청남도 보령시 청라면 내현리 519-5',
        LON: '126.66292500',
        LAT: '36.39134167',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: '한국수자원공사',
        gulDiaBtm: '12',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-8.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-12',
        strHgt: '',
        strDia: '',
        strMat: '8.0-12.0'
      },
      geometry: { type: 'Point', coordinates: [126.662925, 36.39134167] }
    },
    {
      type: 'Feature',
      id: 314,
      properties: {
        DATE: '2024-10-26',
        jiguname: '보령청소',
        gennum: '82020',
        well: '암반',
        pyogo: '16.026',
        area: '금강권역',
        addr: '충청남도 보령시 청소면 야현리 644-16',
        LON: '126.59353056',
        LAT: '36.44159444',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-24',
        cseInHgt: '250',
        cseInDia: '백관',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.59353056, 36.44159444] }
    },
    {
      type: 'Feature',
      id: 315,
      properties: {
        DATE: '2024-10-26',
        jiguname: '보령청소',
        gennum: '82021',
        well: '충적',
        pyogo: '16.1',
        area: '금강권역',
        addr: '충청남도 보령시 청소면 야현리 644-16',
        LON: '126.59353056',
        LAT: '36.44159444',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '15',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0-11',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-15',
        strHgt: '',
        strDia: '',
        strMat: '11.0-15.0'
      },
      geometry: { type: 'Point', coordinates: [126.59353056, 36.44159444] }
    },
    {
      type: 'Feature',
      id: 316,
      properties: {
        DATE: '2024-10-26',
        jiguname: '보성겸백',
        gennum: '65019',
        well: '암반',
        pyogo: '120.65',
        area: '영섬권역',
        addr: '전라남도 보성군 겸백면 석호리 449-6 농촌지도소',
        LON: '127.15197778',
        LAT: '34.82858611',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '71',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-11.5',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0.0-71.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '48.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [127.15197778, 34.82858611] }
    },
    {
      type: 'Feature',
      id: 317,
      properties: {
        DATE: '2024-10-26',
        jiguname: '보성벌교',
        gennum: '82034',
        well: '암반',
        pyogo: '13.79',
        area: '영섬권역',
        addr: '전라남도 보성군 벌교읍 연산리 456-6',
        LON: '127.34031944',
        LAT: '34.86040833',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-15',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '27.0-31.0'
      },
      geometry: { type: 'Point', coordinates: [127.34031944, 34.86040833] }
    },
    {
      type: 'Feature',
      id: 318,
      properties: {
        DATE: '2024-10-26',
        jiguname: '보성보성',
        gennum: '771365',
        well: '암반',
        pyogo: '208.2',
        area: '영섬권역',
        addr: '전라남도 보성군 보성읍 대야리 산177-10',
        LON: '127.01789444',
        LAT: '34.76816389',
        insdate: '2021-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: 'SST',
        cseInDia: '',
        cseInMat: '150',
        strHgt: 'pvc',
        strDia: '',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [127.01789444, 34.76816389] }
    },
    {
      type: 'Feature',
      id: 319,
      properties: {
        DATE: '2024-10-26',
        jiguname: '보성예당_심부2',
        gennum: '769773',
        well: '암반',
        pyogo: '26',
        area: '영섬권역',
        addr: '전라남도 보성군 득량면 예당리 369 예당초등학교',
        LON: '127.19512500',
        LAT: '34.77863056',
        insdate: '2011-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '57.5-80.0',
        strMat: ''
      },
      geometry: { type: 'Point', coordinates: [127.195125, 34.77863056] }
    },
    {
      type: 'Feature',
      id: 320,
      properties: {
        DATE: '2024-10-26',
        jiguname: '보성예당_천부',
        gennum: '769774',
        well: '충적',
        pyogo: '26',
        area: '영섬권역',
        addr: '전라남도 보성군 득량면 예당리 369 예당초등학교',
        LON: '127.19512500',
        LAT: '34.77863056',
        insdate: '2011-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '15',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-15.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '10.0-15.0',
        strMat: ''
      },
      geometry: { type: 'Point', coordinates: [127.195125, 34.77863056] }
    },
    {
      type: 'Feature',
      id: 321,
      properties: {
        DATE: '2024-10-26',
        jiguname: '보은교사_심부2',
        gennum: '771451',
        well: '암반',
        pyogo: '157.3',
        area: '금강권역',
        addr: '충청북도 보은군 보은읍 교사리 123 보은자영고등학교',
        LON: '127.71739167',
        LAT: '36.49207222',
        insdate: '2016-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '82',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-10.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-82',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '76.0-82.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.71739167, 36.49207222] }
    },
    {
      type: 'Feature',
      id: 322,
      properties: {
        DATE: '2024-10-26',
        jiguname: '보은교사_천부',
        gennum: '771452',
        well: '충적',
        pyogo: '157.3',
        area: '금강권역',
        addr: '충청북도 보은군 보은읍 교사리 123 보은자영고등학교',
        LON: '127.71739167',
        LAT: '36.49207222',
        insdate: '2016-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.71739167, 36.49207222] }
    },
    {
      type: 'Feature',
      id: 323,
      properties: {
        DATE: '2024-10-26',
        jiguname: '보은마로',
        gennum: '65590',
        well: '암반',
        pyogo: '129.31',
        area: '금강권역',
        addr: '충청북도 보은군 마로면 오천리 683',
        LON: '127.80203611',
        LAT: '36.40081944',
        insdate: '2000-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-16.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.80203611, 36.40081944] }
    },
    {
      type: 'Feature',
      id: 324,
      properties: {
        DATE: '2024-10-26',
        jiguname: '보은마로',
        gennum: '65591',
        well: '충적',
        pyogo: '129.32',
        area: '금강권역',
        addr: '충청북도 보은군 마로면 오천리 683',
        LON: '127.80203611',
        LAT: '36.40081944',
        insdate: '2000-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '16.5',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-12.5',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-16.5',
        strHgt: '',
        strDia: '',
        strMat: '12.5-16.5'
      },
      geometry: { type: 'Point', coordinates: [127.80203611, 36.40081944] }
    },
    {
      type: 'Feature',
      id: 325,
      properties: {
        DATE: '2024-10-26',
        jiguname: '보은보은',
        gennum: '11756',
        well: '암반',
        pyogo: '154.4',
        area: '금강권역',
        addr: '충청북도 보은군 보은읍 장신리 135-9',
        LON: '127.71342222',
        LAT: '36.48423333',
        insdate: '1996-09-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '71',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-14.4',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0.0-71.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '35.0-47.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [127.71342222, 36.48423333] }
    },
    {
      type: 'Feature',
      id: 326,
      properties: {
        DATE: '2024-10-26',
        jiguname: '보은보은',
        gennum: '11757',
        well: '충적',
        pyogo: '154.4',
        area: '금강권역',
        addr: '충청북도 보은군 보은읍 장신리 135-9',
        LON: '127.71342222',
        LAT: '36.48423333',
        insdate: '1996-09-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '11.3',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-7.3',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0-11.3',
        cseInMat: '',
        strHgt: '',
        strDia: '7.3-11.3',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [127.71342222, 36.48423333] }
    },
    {
      type: 'Feature',
      id: 327,
      properties: {
        DATE: '2024-10-26',
        jiguname: '보은속리산',
        gennum: '793613',
        well: '암반',
        pyogo: '312.6',
        area: '',
        addr: '충청북도 보은군 속리산면 중판리 434',
        LON: '127.78936667',
        LAT: '36.52740278',
        insdate: '2023-12-01',
        manager: '',
        builder: '',
        gulDep: '100',
        gulDiaTop: '300',
        gulDiaBtm: '200',
        cseExHgt: '0.0-7.0',
        cseExDia: '200',
        cseExMat: 'STEEL PIPE',
        cseInHgt: '0.0-100.0',
        cseInDia: '150',
        cseInMat: 'P. V. C',
        strHgt: '34.0-38.0m',
        strDia: ' 70.0-78.0m',
        strMat: ' 86.0-98.0'
      },
      geometry: { type: 'Point', coordinates: [127.78936667, 36.52740278] }
    },
    {
      type: 'Feature',
      id: 328,
      properties: {
        DATE: '2024-10-26',
        jiguname: '보은하장_심부2',
        gennum: '771453',
        well: '암반',
        pyogo: '160.04',
        area: '금강권역',
        addr: '충청북도 보은군 탄부면 하장리 347 탄부면사무소',
        LON: '127.79601944',
        LAT: '36.43016944',
        insdate: '2016-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '84',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-8.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-84',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '78.0-84.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.79601944, 36.43016944] }
    },
    {
      type: 'Feature',
      id: 329,
      properties: {
        DATE: '2024-10-26',
        jiguname: '보은하장_천부',
        gennum: '771454',
        well: '충적',
        pyogo: '160.04',
        area: '금강권역',
        addr: '충청북도 보은군 탄부면 하장리 347 탄부면사무소',
        LON: '127.79601944',
        LAT: '36.43016944',
        insdate: '2016-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '15',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-15',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '9.0-15.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.79601944, 36.43016944] }
    },
    {
      type: 'Feature',
      id: 330,
      properties: {
        DATE: '2024-10-26',
        jiguname: '보은회인',
        gennum: '514302',
        well: '암반',
        pyogo: '108.84',
        area: '금강권역',
        addr: '충청북도 보은군 회인면 죽암리 193-1',
        LON: '127.59555556',
        LAT: '36.47083333',
        insdate: '2010-01-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '유성삼정개발㈜',
        gulDiaTop: '150',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-3.7',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-150.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '30.4-34.4',
        strMat: ' 46.6-50.6'
      },
      geometry: { type: 'Point', coordinates: [127.59555556, 36.47083333] }
    },
    {
      type: 'Feature',
      id: 331,
      properties: {
        DATE: '2024-10-26',
        jiguname: '봉화명호',
        gennum: '11801',
        well: '암반',
        pyogo: '204.21',
        area: '낙동강권역',
        addr: '경상북도 봉화군 명호면 도천리 산376 명호면 생활체육시설내',
        LON: '128.90277500',
        LAT: '36.85668056',
        insdate: '1996-09-06',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '73',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-12.1',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0.0-73.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '46.0-50.0',
        strMat: ' 62.0-70.0'
      },
      geometry: { type: 'Point', coordinates: [128.902775, 36.85668056] }
    },
    {
      type: 'Feature',
      id: 332,
      properties: {
        DATE: '2024-10-26',
        jiguname: '봉화명호',
        gennum: '11802',
        well: '충적',
        pyogo: '204.21',
        area: '낙동강권역',
        addr: '경상북도 봉화군 명호면 도천리 산376 명호백 생활체육시설내',
        LON: '128.90277500',
        LAT: '36.85668056',
        insdate: '1996-09-06',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '10.8',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-5.8',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0-10.8',
        cseInMat: '',
        strHgt: '',
        strDia: '5.8-9.8',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [128.902775, 36.85668056] }
    },
    {
      type: 'Feature',
      id: 333,
      properties: {
        DATE: '2024-10-26',
        jiguname: '봉화물야',
        gennum: '717021',
        well: '암반',
        pyogo: '287.94',
        area: '낙동강권역',
        addr: '경상북도 봉화군 물야면 오록리 1336',
        LON: '128.73244722',
        LAT: '36.97619722',
        insdate: '2017-12-14',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜에스제이지반기술',
        gulDiaTop: '101.97',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-9.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '42-46',
        strMat: ' 50-54'
      },
      geometry: { type: 'Point', coordinates: [128.73244722, 36.97619722] }
    },
    {
      type: 'Feature',
      id: 334,
      properties: {
        DATE: '2024-10-26',
        jiguname: '봉화상운',
        gennum: '793605',
        well: '암반',
        pyogo: '234.18',
        area: '',
        addr: '경상북도 봉화군 상운면 하눌리 852-1',
        LON: '128.76805278',
        LAT: '36.86088056',
        insdate: '2023-12-01',
        manager: '',
        builder: '',
        gulDep: '70',
        gulDiaTop: '300',
        gulDiaBtm: '200',
        cseExHgt: '0.0-5.0',
        cseExDia: '200',
        cseExMat: 'STEEL PIPE',
        cseInHgt: '0.0-70.0',
        cseInDia: '150',
        cseInMat: 'P. V. C',
        strHgt: '14.0-22.0m',
        strDia: ' 26.0-34.0m',
        strMat: ' 38.0-46.0m'
      },
      geometry: { type: 'Point', coordinates: [128.76805278, 36.86088056] }
    },
    {
      type: 'Feature',
      id: 335,
      properties: {
        DATE: '2024-10-26',
        jiguname: '봉화재산',
        gennum: '73536',
        well: '암반',
        pyogo: '311.74',
        area: '낙동강권역',
        addr: '경상북도 봉화군 재산면 현동리 1683-1',
        LON: '128.96269722',
        LAT: '36.81150833',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '90',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-5.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-90.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '70.0-90.0'
      },
      geometry: { type: 'Point', coordinates: [128.96269722, 36.81150833] }
    },
    {
      type: 'Feature',
      id: 336,
      properties: {
        DATE: '2024-10-26',
        jiguname: '부산구서_심부2',
        gennum: '771455',
        well: '암반',
        pyogo: '48.4',
        area: '낙동강권역',
        addr: '부산광역시 금정구 구서동  703 장서초등학교',
        LON: '129.08685278',
        LAT: '35.24844444',
        insdate: '2017-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '84',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-5.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-84',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '78.0-84.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.08685278, 35.24844444] }
    },
    {
      type: 'Feature',
      id: 337,
      properties: {
        DATE: '2024-10-26',
        jiguname: '부산구서_천부',
        gennum: '771456',
        well: '충적',
        pyogo: '48.4',
        area: '낙동강권역',
        addr: '부산광역시 금정구 구서동  703 장서초등학교',
        LON: '129.08685278',
        LAT: '35.24844444',
        insdate: '2017-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '21',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-5.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-21',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '15.0-21.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.08685278, 35.24844444] }
    },
    {
      type: 'Feature',
      id: 338,
      properties: {
        DATE: '2024-10-26',
        jiguname: '부산금성_심부2',
        gennum: '771457',
        well: '암반',
        pyogo: '342.462',
        area: '낙동강권역',
        addr: '부산광역시 금정구 금성동  22-2 부산광역시학생교육원',
        LON: '129.04748056',
        LAT: '35.26015278',
        insdate: '2015-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-7.1',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '72.5-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.04748056, 35.26015278] }
    },
    {
      type: 'Feature',
      id: 339,
      properties: {
        DATE: '2024-10-26',
        jiguname: '부산금성_천부',
        gennum: '771458',
        well: '충적',
        pyogo: '342.462',
        area: '낙동강권역',
        addr: '부산광역시 금정구 금성동  22-2 부산광역시학생교육원',
        LON: '129.04748056',
        LAT: '35.26015278',
        insdate: '2015-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '18',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-18',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '12.0-18.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.04748056, 35.26015278] }
    },
    {
      type: 'Feature',
      id: 340,
      properties: {
        DATE: '2024-10-26',
        jiguname: '부산동대신',
        gennum: '9855',
        well: '암반',
        pyogo: '141.28',
        area: '낙동강권역',
        addr: '부산광역시 서구 동대신동2가  98 대청공원내',
        LON: '129.02786944',
        LAT: '35.11329167',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '168',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-9.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0-168',
        cseInMat: '',
        strHgt: '',
        strDia: '',
        strMat: ''
      },
      geometry: { type: 'Point', coordinates: [129.02786944, 35.11329167] }
    },
    {
      type: 'Feature',
      id: 341,
      properties: {
        DATE: '2024-10-26',
        jiguname: '부안동진',
        gennum: '717031',
        well: '암반',
        pyogo: '6.22',
        area: '금강권역',
        addr: '전북특별자치도 부안군 동진면 장등리 341-3',
        LON: '126.76637778',
        LAT: '35.75421944',
        insdate: '2017-12-18',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜삼안',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-28.1',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '60-80',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [126.76637778, 35.75421944] }
    },
    {
      type: 'Feature',
      id: 342,
      properties: {
        DATE: '2024-10-26',
        jiguname: '부안백산',
        gennum: '82026',
        well: '암반',
        pyogo: '13.425',
        area: '금강권역',
        addr: '전북특별자치도 부안군 백산면 오곡리 689-1',
        LON: '126.78820833',
        LAT: '35.70552500',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-22',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.78820833, 35.705525] }
    },
    {
      type: 'Feature',
      id: 343,
      properties: {
        DATE: '2024-10-26',
        jiguname: '부안백산',
        gennum: '82027',
        well: '충적',
        pyogo: '13.415',
        area: '금강권역',
        addr: '전북특별자치도 부안군 백산면 오곡리 689-1',
        LON: '126.78820833',
        LAT: '35.70552500',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '15',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0-11',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-15',
        strHgt: '',
        strDia: '',
        strMat: '11.0-15.0'
      },
      geometry: { type: 'Point', coordinates: [126.78820833, 35.705525] }
    },
    {
      type: 'Feature',
      id: 344,
      properties: {
        DATE: '2024-10-26',
        jiguname: '부안상서',
        gennum: '82028',
        well: '암반',
        pyogo: '12.794',
        area: '금강권역',
        addr: '전북특별자치도 부안군 상서면 가오리 522-1',
        LON: '126.67697778',
        LAT: '35.68593056',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '65',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-37',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-65',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '45.0-49.0'
      },
      geometry: { type: 'Point', coordinates: [126.67697778, 35.68593056] }
    },
    {
      type: 'Feature',
      id: 345,
      properties: {
        DATE: '2024-10-26',
        jiguname: '부안상서',
        gennum: '82029',
        well: '충적',
        pyogo: '12.807',
        area: '금강권역',
        addr: '전북특별자치도 부안군 상서면 가오리 522-1',
        LON: '126.67697778',
        LAT: '35.68593056',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '15',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0-11',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-15',
        strHgt: '',
        strDia: '',
        strMat: '11.0-15.0'
      },
      geometry: { type: 'Point', coordinates: [126.67697778, 35.68593056] }
    },
    {
      type: 'Feature',
      id: 346,
      properties: {
        DATE: '2024-10-26',
        jiguname: '부안진서',
        gennum: '793612',
        well: '암반',
        pyogo: '4.78',
        area: '영섬권역',
        addr: '전북특별자치도 부안군 진서면 운호리 79-12',
        LON: '126.56169028',
        LAT: '35.59451861',
        insdate: '2023-12-01',
        manager: '',
        builder: '',
        gulDep: '98',
        gulDiaTop: '300',
        gulDiaBtm: '200',
        cseExHgt: '0.0-22.0',
        cseExDia: '200',
        cseExMat: 'STEEL PIPE',
        cseInHgt: '0.0-98.0',
        cseInDia: '150',
        cseInMat: 'P. V. C',
        strHgt: '',
        strDia: '',
        strMat: ''
      },
      geometry: { type: 'Point', coordinates: [126.56169028, 35.59451861] }
    },
    {
      type: 'Feature',
      id: 347,
      properties: {
        DATE: '2024-10-26',
        jiguname: '부여귀덕_심부2',
        gennum: '769779',
        well: '암반',
        pyogo: '5.157',
        area: '금강권역',
        addr: '충청남도 부여군 세도면 귀덕리 503 인세초등학교',
        LON: '126.97311667',
        LAT: '36.16604444',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '82',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-13.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-82.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '76.0-82.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.97311667, 36.16604444] }
    },
    {
      type: 'Feature',
      id: 348,
      properties: {
        DATE: '2024-10-26',
        jiguname: '부여귀덕_천부',
        gennum: '769780',
        well: '충적',
        pyogo: '5.168',
        area: '금강권역',
        addr: '충청남도 부여군 세도면 귀덕리 503 인세초등학교',
        LON: '126.97311667',
        LAT: '36.16604444',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-5.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '5.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.97311667, 36.16604444] }
    },
    {
      type: 'Feature',
      id: 349,
      properties: {
        DATE: '2024-10-26',
        jiguname: '부여규암',
        gennum: '65020',
        well: '암반',
        pyogo: '18.951',
        area: '금강권역',
        addr: '충청남도 부여군 규암면 규암리 산12-1',
        LON: '126.88383611',
        LAT: '36.27505000',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '74',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-3.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-74.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.88383611, 36.27505] }
    },
    {
      type: 'Feature',
      id: 350,
      properties: {
        DATE: '2024-10-26',
        jiguname: '부여부여',
        gennum: '4855',
        well: '암반',
        pyogo: '13.33',
        area: '금강권역',
        addr: '충청남도 부여군 부여읍 쌍북리 449-1 한국수자원공사 금강부여취수장내',
        LON: '126.91739722',
        LAT: '36.29203333',
        insdate: '1995-12-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '90',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-7.0',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0.0-74.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '74.0-90.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [126.91739722, 36.29203333] }
    },
    {
      type: 'Feature',
      id: 351,
      properties: {
        DATE: '2024-10-26',
        jiguname: '부여부여',
        gennum: '4854',
        well: '충적',
        pyogo: '13.45',
        area: '금강권역',
        addr: '충청남도 부여군 부여읍 쌍북리 449-1 한국수자원공사 금강부여취수장내',
        LON: '126.91739722',
        LAT: '36.29203333',
        insdate: '1995-12-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '27',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-21.0',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0-27',
        cseInMat: '',
        strHgt: '',
        strDia: '21.0-27.0',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [126.91739722, 36.29203333] }
    },
    {
      type: 'Feature',
      id: 352,
      properties: {
        DATE: '2024-10-26',
        jiguname: '부여양화',
        gennum: '9914',
        well: '암반',
        pyogo: '5.65',
        area: '금강권역',
        addr: '충청남도 부여군 양화면 입포리 60',
        LON: '126.88146111',
        LAT: '36.13516944',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '76',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-33.5',
        cseExMat: '250',
        cseInHgt: '백관',
        cseInDia: '0.0-76.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [126.88146111, 36.13516944] }
    },
    {
      type: 'Feature',
      id: 353,
      properties: {
        DATE: '2024-10-26',
        jiguname: '부여옥산',
        gennum: '9915',
        well: '암반',
        pyogo: '28.73',
        area: '금강권역',
        addr: '충청남도 부여군 옥산면 안서리 368-1 옥산면사무소내',
        LON: '126.73382222',
        LAT: '36.19178056',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '77',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-36.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-77.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [126.73382222, 36.19178056] }
    },
    {
      type: 'Feature',
      id: 354,
      properties: {
        DATE: '2024-10-26',
        jiguname: '부여운치_심부2',
        gennum: '737614',
        well: '암반',
        pyogo: '41.7',
        area: '금강권역',
        addr: '충청남도 부여군 내산면 운치리 452',
        LON: '126.78056667',
        LAT: '36.27469167',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '83',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-25.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-83.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '65.0-83.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.78056667, 36.27469167] }
    },
    {
      type: 'Feature',
      id: 355,
      properties: {
        DATE: '2024-10-26',
        jiguname: '부여운치_천부',
        gennum: '737613',
        well: '충적',
        pyogo: '41.7',
        area: '금강권역',
        addr: '충청남도 부여군 내산면 운치리 452',
        LON: '126.78056667',
        LAT: '36.27469167',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.78056667, 36.27469167] }
    },
    {
      type: 'Feature',
      id: 356,
      properties: {
        DATE: '2024-10-26',
        jiguname: '부여은산',
        gennum: '87251',
        well: '암반',
        pyogo: '14.8',
        area: '금강권역',
        addr: '충청남도 부여군 은산면 회곡리 97-3',
        LON: '126.85833333',
        LAT: '36.32565556',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: '한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-17.8',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.85833333, 36.32565556] }
    },
    {
      type: 'Feature',
      id: 357,
      properties: {
        DATE: '2024-10-26',
        jiguname: '부여청송_심부2',
        gennum: '737616',
        well: '암반',
        pyogo: '8.2',
        area: '금강권역',
        addr: '충청남도 부여군 세도면 청송리 414-2',
        LON: '126.94826389',
        LAT: '36.17029167',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '88',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-6.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-88.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '79.0-88.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.94826389, 36.17029167] }
    },
    {
      type: 'Feature',
      id: 358,
      properties: {
        DATE: '2024-10-26',
        jiguname: '부여청송_천부',
        gennum: '737615',
        well: '충적',
        pyogo: '8.2',
        area: '금강권역',
        addr: '충청남도 부여군 세도면 청송리 414-2',
        LON: '126.94826389',
        LAT: '36.17029167',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '11',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-11.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '6.0-11.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.94826389, 36.17029167] }
    },
    {
      type: 'Feature',
      id: 359,
      properties: {
        DATE: '2024-10-26',
        jiguname: '부여홍산',
        gennum: '717029',
        well: '암반',
        pyogo: '22.88',
        area: '금강권역',
        addr: '충청남도 부여군 홍산면 북촌리 122-1',
        LON: '126.76494444',
        LAT: '36.21635278',
        insdate: '2017-12-05',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜송암',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-48.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '48.0-96.0',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [126.76494444, 36.21635278] }
    },
    {
      type: 'Feature',
      id: 360,
      properties: {
        DATE: '2024-10-26',
        jiguname: '부천원미',
        gennum: '777767',
        well: '암반',
        pyogo: '51',
        area: '한강권역',
        addr: '경기도 부천시 원미구 원미동  16',
        LON: '126.79488611',
        LAT: '37.49712778',
        insdate: '2022-04-28',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0-18',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0-100',
        cseInMat: '150',
        strHgt: 'P.V.C',
        strDia: '50-54',
        strMat: ' 60-64'
      },
      geometry: { type: 'Point', coordinates: [126.79488611, 37.49712778] }
    },
    {
      type: 'Feature',
      id: 361,
      properties: {
        DATE: '2024-10-26',
        jiguname: '사천곤명',
        gennum: '769858',
        well: '암반',
        pyogo: '20.43',
        area: '낙동강권역',
        addr: '경상남도 사천시 곤명면  37-1',
        LON: '127.95279722',
        LAT: '35.07809722',
        insdate: '2020-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '150',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0-7.7',
        cseExMat: '200',
        cseInHgt: 'sst',
        cseInDia: '0-102',
        cseInMat: '150',
        strHgt: 'pvc',
        strDia: '52-64',
        strMat: ' 68-80'
      },
      geometry: { type: 'Point', coordinates: [127.95279722, 35.07809722] }
    },
    {
      type: 'Feature',
      id: 362,
      properties: {
        DATE: '2024-10-26',
        jiguname: '사천곤양',
        gennum: '637526',
        well: '암반',
        pyogo: '25.285',
        area: '낙동강권역',
        addr: '경상남도 사천시 곤양면 맥사리 217-3 1',
        LON: '127.94493611',
        LAT: '35.05494444',
        insdate: '2015-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜대동공영',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-3.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '23.0-100',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [127.94493611, 35.05494444] }
    },
    {
      type: 'Feature',
      id: 363,
      properties: {
        DATE: '2024-10-26',
        jiguname: '사천사천',
        gennum: '84036',
        well: '암반',
        pyogo: '41.912',
        area: '낙동강권역',
        addr: '경상남도 사천시 사천읍  구암리  산2-16 한국수자원공사 사천지사내',
        LON: '128.10367222',
        LAT: '35.09665278',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-6.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [128.10367222, 35.09665278] }
    },
    {
      type: 'Feature',
      id: 364,
      properties: {
        DATE: '2024-10-26',
        jiguname: '사천용강_심부2',
        gennum: '769781',
        well: '암반',
        pyogo: '76.91',
        area: '낙동강권역',
        addr: '경상남도 사천시 용강동  355 용두공원',
        LON: '128.09002500',
        LAT: '34.95714722',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '79',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-24.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-79.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '73.0-79.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.090025, 34.95714722] }
    },
    {
      type: 'Feature',
      id: 365,
      properties: {
        DATE: '2024-10-26',
        jiguname: '사천용강_천부',
        gennum: '769782',
        well: '충적',
        pyogo: '76.91',
        area: '낙동강권역',
        addr: '경상남도 사천시 용강동  355 용두공원',
        LON: '128.09002500',
        LAT: '34.95714722',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.090025, 34.95714722] }
    },
    {
      type: 'Feature',
      id: 366,
      properties: {
        DATE: '2024-10-26',
        jiguname: '산청단성',
        gennum: '84037',
        well: '암반',
        pyogo: '57.437',
        area: '낙동강권역',
        addr: '경상남도 산청군 단성면 남사리  64-1',
        LON: '127.94372778',
        LAT: '35.27241944',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-11.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.94372778, 35.27241944] }
    },
    {
      type: 'Feature',
      id: 367,
      properties: {
        DATE: '2024-10-26',
        jiguname: '산청단성',
        gennum: '84038',
        well: '충적',
        pyogo: '57.448',
        area: '낙동강권역',
        addr: '경상남도 산청군 단성면 남사리  64-1',
        LON: '127.94372778',
        LAT: '35.27241944',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-6.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6.0-10.0'
      },
      geometry: { type: 'Point', coordinates: [127.94372778, 35.27241944] }
    },
    {
      type: 'Feature',
      id: 368,
      properties: {
        DATE: '2024-10-26',
        jiguname: '산청산청',
        gennum: '73545',
        well: '암반',
        pyogo: '116.135',
        area: '낙동강권역',
        addr: '경상남도 산청군 산청읍 차탄리 산101-4',
        LON: '127.86825000',
        LAT: '35.43429722',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-12.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.86825, 35.43429722] }
    },
    {
      type: 'Feature',
      id: 369,
      properties: {
        DATE: '2024-10-26',
        jiguname: '산청신안_심부2',
        gennum: '771461',
        well: '암반',
        pyogo: '70',
        area: '낙동강권역',
        addr: '경상남도 산청군 신안면 문대리 589-3 도산초등학교',
        LON: '128.00043889',
        LAT: '35.33457778',
        insdate: '2009-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '87',
        gulDiaBtm: '165',
        cseExHgt: '165',
        cseExDia: '0.0-8.0',
        cseExMat: '165',
        cseInHgt: '강관',
        cseInDia: '0-87',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '57.0-87.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.00043889, 35.33457778] }
    },
    {
      type: 'Feature',
      id: 370,
      properties: {
        DATE: '2024-10-26',
        jiguname: '산청신안_천부',
        gennum: '771462',
        well: '충적',
        pyogo: '70',
        area: '낙동강권역',
        addr: '경상남도 산청군 신안면 문대리 589-3 도산초등학교',
        LON: '128.00043889',
        LAT: '35.33457778',
        insdate: '2009-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '8',
        gulDiaBtm: '165',
        cseExHgt: '165',
        cseExDia: '0.0-0.0',
        cseExMat: '165',
        cseInHgt: '강관',
        cseInDia: '0-8',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '3.5-8.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.00043889, 35.33457778] }
    },
    {
      type: 'Feature',
      id: 371,
      properties: {
        DATE: '2024-10-26',
        jiguname: '산청차황',
        gennum: '637525',
        well: '암반',
        pyogo: '487.328',
        area: '낙동강권역',
        addr: '경상남도 산청군 차황면 법평리 834',
        LON: '127.95643333',
        LAT: '35.47808611',
        insdate: '2015-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜대동공영',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-32.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '125',
        strHgt: 'P. V. C',
        strDia: '',
        strMat: '125'
      },
      geometry: { type: 'Point', coordinates: [127.95643333, 35.47808611] }
    },
    {
      type: 'Feature',
      id: 372,
      properties: {
        DATE: '2024-10-26',
        jiguname: '삼척가곡',
        gennum: '82005',
        well: '암반',
        pyogo: '111.445',
        area: '한강권역',
        addr: '강원특별자치도 삼척시 가곡면 오저리 629',
        LON: '129.20668056',
        LAT: '37.15008611',
        insdate: '2003-06-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-7.5',
        cseInHgt: '250',
        cseInDia: '백관',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P.V.C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [129.20668056, 37.15008611] }
    },
    {
      type: 'Feature',
      id: 373,
      properties: {
        DATE: '2024-10-26',
        jiguname: '삼척가곡',
        gennum: '82006',
        well: '충적',
        pyogo: '111.451',
        area: '한강권역',
        addr: '강원특별자치도 삼척시 가곡면 오저리 629',
        LON: '129.20668056',
        LAT: '37.15008611',
        insdate: '2003-06-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '7',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-3.0',
        cseInHgt: '200',
        cseInDia: '백관',
        cseInMat: '0-7',
        strHgt: '',
        strDia: '',
        strMat: '3.0-7.0'
      },
      geometry: { type: 'Point', coordinates: [129.20668056, 37.15008611] }
    },
    {
      type: 'Feature',
      id: 374,
      properties: {
        DATE: '2024-10-26',
        jiguname: '삼척근덕',
        gennum: '717015',
        well: '암반',
        pyogo: '12.79',
        area: '한강권역',
        addr: '강원특별자치도 삼척시 근덕면 교가리 162-2',
        LON: '129.22895833',
        LAT: '37.36577500',
        insdate: '2017-11-30',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '벽산엔지니어링㈜',
        gulDiaTop: '127',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-14.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-127',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '44-60',
        strMat: ' 72-84'
      },
      geometry: { type: 'Point', coordinates: [129.22895833, 37.365775] }
    },
    {
      type: 'Feature',
      id: 375,
      properties: {
        DATE: '2024-10-26',
        jiguname: '삼척근덕',
        gennum: '717016',
        well: '충적',
        pyogo: '12.79',
        area: '한강권역',
        addr: '강원특별자치도 삼척시 근덕면 교가리 162-2',
        LON: '129.22895833',
        LAT: '37.36577500',
        insdate: '2017-11-30',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '벽산엔지니어링㈜',
        gulDiaTop: '17',
        gulDiaBtm: '400',
        cseExHgt: '300',
        cseExDia: '0.0-3.0',
        cseExMat: '300',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0.0-3.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '3.0-17.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [129.22895833, 37.365775] }
    },
    {
      type: 'Feature',
      id: 376,
      properties: {
        DATE: '2024-10-26',
        jiguname: '삼척도계_심부2',
        gennum: '769783',
        well: '암반',
        pyogo: '251.72',
        area: '한강권역',
        addr: '강원특별자치도 삼척시 도계읍 도계리 256-1 도계전산정보고등학교',
        LON: '129.04926944',
        LAT: '37.23140278',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-4.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.04926944, 37.23140278] }
    },
    {
      type: 'Feature',
      id: 377,
      properties: {
        DATE: '2024-10-26',
        jiguname: '삼척도계_천부',
        gennum: '769784',
        well: '충적',
        pyogo: '251.715',
        area: '한강권역',
        addr: '강원특별자치도 삼척시 도계읍 도계리 256-1 도계전산정보고등학교',
        LON: '129.04926944',
        LAT: '37.23140278',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-3.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '3.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.04926944, 37.23140278] }
    },
    {
      type: 'Feature',
      id: 378,
      properties: {
        DATE: '2024-10-26',
        jiguname: '삼척마평',
        gennum: '84008',
        well: '암반',
        pyogo: '15.809',
        area: '한강권역',
        addr: '강원특별자치도 삼척시 마평동  70 마평정수장내',
        LON: '129.13625556',
        LAT: '37.43213056',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-6.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '30.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [129.13625556, 37.43213056] }
    },
    {
      type: 'Feature',
      id: 379,
      properties: {
        DATE: '2024-10-26',
        jiguname: '삼척신기',
        gennum: '84009',
        well: '암반',
        pyogo: '138.666',
        area: '한강권역',
        addr: '강원특별자치도 삼척시 신기면 고무릉리  312-12 하수종말처리장내',
        LON: '129.05480278',
        LAT: '37.34368056',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '40',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-30.0',
        cseInHgt: '250',
        cseInDia: '백관',
        cseInMat: '0.0-40.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '28.0-34.0'
      },
      geometry: { type: 'Point', coordinates: [129.05480278, 37.34368056] }
    },
    {
      type: 'Feature',
      id: 380,
      properties: {
        DATE: '2024-10-26',
        jiguname: '삼척원덕',
        gennum: '717009',
        well: '암반',
        pyogo: '45.41',
        area: '한강권역',
        addr: '강원특별자치도 삼척시 원덕읍 노경리 676-5',
        LON: '129.30410556',
        LAT: '37.14848056',
        insdate: '2017-11-30',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜한서엔지니어링',
        gulDiaTop: '90',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-36.0',
        cseExMat: '200',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0.0-90.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '70.0-90.0',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [129.30410556, 37.14848056] }
    },
    {
      type: 'Feature',
      id: 381,
      properties: {
        DATE: '2024-10-26',
        jiguname: '삼척임원_심부2',
        gennum: '771463',
        well: '암반',
        pyogo: '21.547',
        area: '한강권역',
        addr: '강원특별자치도 삼척시 원덕읍 임원리 921 임원중학교',
        LON: '129.33646944',
        LAT: '37.24078333',
        insdate: '2018-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '83',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-8.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-83',
        cseInMat: '100',
        strHgt: 'PVC(HI-VP)',
        strDia: '77.0-83.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.33646944, 37.24078333] }
    },
    {
      type: 'Feature',
      id: 382,
      properties: {
        DATE: '2024-10-26',
        jiguname: '삼척임원_천부',
        gennum: '771464',
        well: '충적',
        pyogo: '21.542',
        area: '한강권역',
        addr: '강원특별자치도 삼척시 원덕읍 임원리 921 임원중학교',
        LON: '129.33646944',
        LAT: '37.24078333',
        insdate: '2018-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '12',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-12',
        cseInMat: '100',
        strHgt: 'PVC(HI-VP)',
        strDia: '6.0-12.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.33646944, 37.24078333] }
    },
    {
      type: 'Feature',
      id: 383,
      properties: {
        DATE: '2024-10-26',
        jiguname: '삼척하장',
        gennum: '615515',
        well: '암반',
        pyogo: '676.06',
        area: '한강권역',
        addr: '강원특별자치도 삼척시 하장면 숙암리 354',
        LON: '128.96444444',
        LAT: '37.33916667',
        insdate: '2013-11-18',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜지오그린21',
        gulDiaTop: '136',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '',
        cseInHgt: '',
        cseInDia: '0-136',
        cseInMat: '',
        strHgt: '',
        strDia: '',
        strMat: ''
      },
      geometry: { type: 'Point', coordinates: [128.96444444, 37.33916667] }
    },
    {
      type: 'Feature',
      id: 384,
      properties: {
        DATE: '2024-10-26',
        jiguname: '상주가곡_심부2',
        gennum: '771465',
        well: '암반',
        pyogo: '97.7',
        area: '낙동강권역',
        addr: '경상북도 상주시 외서면 가곡리 57 외서면보건지소',
        LON: '128.10797222',
        LAT: '36.48080278',
        insdate: '2017-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-4.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.10797222, 36.48080278] }
    },
    {
      type: 'Feature',
      id: 385,
      properties: {
        DATE: '2024-10-26',
        jiguname: '상주가곡_천부',
        gennum: '771466',
        well: '충적',
        pyogo: '97.7',
        area: '낙동강권역',
        addr: '경상북도 상주시 외서면 가곡리 57 외서면보건지소',
        LON: '128.10797222',
        LAT: '36.48080278',
        insdate: '2017-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '3.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.10797222, 36.48080278] }
    },
    {
      type: 'Feature',
      id: 386,
      properties: {
        DATE: '2024-10-26',
        jiguname: '상주공성',
        gennum: '11797',
        well: '암반',
        pyogo: '82.08',
        area: '낙동강권역',
        addr: '경상북도 상주시 공성면 옥산리 254 상수도 취수장내',
        LON: '128.09420833',
        LAT: '36.29146667',
        insdate: '1996-09-13',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '71',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-12.4',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0.0-71.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '32.0-40.0',
        strMat: ' 60.0-64.0'
      },
      geometry: { type: 'Point', coordinates: [128.09420833, 36.29146667] }
    },
    {
      type: 'Feature',
      id: 387,
      properties: {
        DATE: '2024-10-26',
        jiguname: '상주공성',
        gennum: '11798',
        well: '충적',
        pyogo: '82.08',
        area: '낙동강권역',
        addr: '경상북도 상주시 공성면 옥산리 254 상수도 취수장내',
        LON: '128.09420833',
        LAT: '36.29146667',
        insdate: '1996-09-13',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '10.2',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-5.2',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0-10.2',
        cseInMat: '',
        strHgt: '',
        strDia: '5.2-9.2',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [128.09420833, 36.29146667] }
    },
    {
      type: 'Feature',
      id: 388,
      properties: {
        DATE: '2024-10-26',
        jiguname: '상주교촌_심부2',
        gennum: '737618',
        well: '암반',
        pyogo: '85.63',
        area: '낙동강권역',
        addr: '경상북도 상주시 함창읍 교촌리 419',
        LON: '128.16292222',
        LAT: '36.57605556',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-18.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.16292222, 36.57605556] }
    },
    {
      type: 'Feature',
      id: 389,
      properties: {
        DATE: '2024-10-26',
        jiguname: '상주교촌_천부',
        gennum: '737617',
        well: '충적',
        pyogo: '85.63',
        area: '낙동강권역',
        addr: '경상북도 상주시 함창읍 교촌리 419',
        LON: '128.16292222',
        LAT: '36.57605556',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.16292222, 36.57605556] }
    },
    {
      type: 'Feature',
      id: 390,
      properties: {
        DATE: '2024-10-26',
        jiguname: '상주모동',
        gennum: '615516',
        well: '암반',
        pyogo: '230.79',
        area: '금강권역',
        addr: '경상북도 상주시 모동면 수봉리 168-5',
        LON: '127.94491944',
        LAT: '36.29460278',
        insdate: '2013-11-07',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜지오그린21',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-12.0',
        cseExMat: '200',
        cseInHgt: 'STS304',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '36-40',
        strMat: ' 48-52'
      },
      geometry: { type: 'Point', coordinates: [127.94491944, 36.29460278] }
    },
    {
      type: 'Feature',
      id: 391,
      properties: {
        DATE: '2024-10-26',
        jiguname: '상주사벌',
        gennum: '622077',
        well: '암반',
        pyogo: '51.94',
        area: '낙동강권역',
        addr: '경상북도 상주시 사벌면 원흥리 24-28 경북 상주시 사벌면 원흥리 24-28번지',
        LON: '128.19611111',
        LAT: '36.45916667',
        insdate: '2014-11-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜대흥개발',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-21.5',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '36-40',
        strMat: ' 52-56'
      },
      geometry: { type: 'Point', coordinates: [128.19611111, 36.45916667] }
    },
    {
      type: 'Feature',
      id: 392,
      properties: {
        DATE: '2024-10-26',
        jiguname: '상주서문',
        gennum: '65054',
        well: '암반',
        pyogo: '55.76',
        area: '낙동강권역',
        addr: '경상북도 상주시 서문동  131-1 상주초등학교',
        LON: '128.15756111',
        LAT: '36.41624722',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-16.5',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [128.15756111, 36.41624722] }
    },
    {
      type: 'Feature',
      id: 393,
      properties: {
        DATE: '2024-10-26',
        jiguname: '상주신상',
        gennum: '718259',
        well: '암반',
        pyogo: '98.6',
        area: '낙동강권역',
        addr: '경상북도 상주시 외남면 신상리 264-2',
        LON: '128.09305556',
        LAT: '36.35638889',
        insdate: '2018-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '84',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-25.0',
        cseExMat: '200',
        cseInHgt: 'Stainless Steel',
        cseInDia: '0.0-84.0',
        cseInMat: '100',
        strHgt: 'P.V.C',
        strDia: '81.0-84.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.09305556, 36.35638889] }
    },
    {
      type: 'Feature',
      id: 394,
      properties: {
        DATE: '2024-10-26',
        jiguname: '상주신상',
        gennum: '718260',
        well: '충적',
        pyogo: '98.6',
        area: '낙동강권역',
        addr: '경상북도 상주시 외남면 신상리 264-2',
        LON: '128.09305556',
        LAT: '36.35638889',
        insdate: '2018-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '24',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.5',
        cseExMat: '200',
        cseInHgt: 'Stainless Steel',
        cseInDia: '0.0-24.0',
        cseInMat: '100',
        strHgt: 'P.V.C',
        strDia: '18-24',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.09305556, 36.35638889] }
    },
    {
      type: 'Feature',
      id: 395,
      properties: {
        DATE: '2024-10-26',
        jiguname: '상주외서',
        gennum: '793606',
        well: '암반',
        pyogo: '234.4',
        area: '',
        addr: '경상북도 상주시 외서면 예의리 28-3',
        LON: '128.00401944',
        LAT: '36.49345833',
        insdate: '2023-12-01',
        manager: '',
        builder: '',
        gulDep: '104',
        gulDiaTop: '300',
        gulDiaBtm: '200',
        cseExHgt: '0.0-12.0',
        cseExDia: '200',
        cseExMat: 'STEEL PIPE',
        cseInHgt: '0.0-102.0',
        cseInDia: '150',
        cseInMat: 'P. V. C',
        strHgt: '44.0-48.0m',
        strDia: ' 60.0-72.0m',
        strMat: ' 76.0-88.0'
      },
      geometry: { type: 'Point', coordinates: [128.00401944, 36.49345833] }
    },
    {
      type: 'Feature',
      id: 396,
      properties: {
        DATE: '2024-10-26',
        jiguname: '상주외서',
        gennum: '793607',
        well: '충적',
        pyogo: '234.37',
        area: '',
        addr: '경상북도 상주시 외서면 예의리 28-3',
        LON: '128.00401667',
        LAT: '36.49348056',
        insdate: '2023-12-01',
        manager: '',
        builder: '',
        gulDep: '22',
        gulDiaTop: '400',
        gulDiaBtm: '300',
        cseExHgt: '0.0-3.0',
        cseExDia: '250',
        cseExMat: 'STEEL PIPE',
        cseInHgt: '0.0-22.0',
        cseInDia: '200',
        cseInMat: 'P. V. C',
        strHgt: '0.0-14.0m',
        strDia: ' 18.0-22.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [128.00401667, 36.49348056] }
    },
    {
      type: 'Feature',
      id: 397,
      properties: {
        DATE: '2024-10-26',
        jiguname: '상주중벌_심부2',
        gennum: '771467',
        well: '암반',
        pyogo: '280',
        area: '한강권역',
        addr: '경상북도 상주시 화북면 중벌리 604-1 화북면 서부출장소',
        LON: '127.82816667',
        LAT: '36.60154167',
        insdate: '2011-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-12.2',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '60.5-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.82816667, 36.60154167] }
    },
    {
      type: 'Feature',
      id: 398,
      properties: {
        DATE: '2024-10-26',
        jiguname: '상주중벌_천부',
        gennum: '771468',
        well: '충적',
        pyogo: '280',
        area: '한강권역',
        addr: '경상북도 상주시 화북면 중벌리 604-1 화북면 서부출장소',
        LON: '127.82816667',
        LAT: '36.60154167',
        insdate: '2011-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '5.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.82816667, 36.60154167] }
    },
    {
      type: 'Feature',
      id: 399,
      properties: {
        DATE: '2024-10-26',
        jiguname: '상주화남',
        gennum: '615517',
        well: '암반',
        pyogo: '191.48',
        area: '금강권역',
        addr: '경상북도 상주시 화남면 평온리 504-2',
        LON: '127.89188611',
        LAT: '36.44501389',
        insdate: '2013-11-09',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜지오그린21',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-18.0',
        cseExMat: '200',
        cseInHgt: 'STS304',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '36-40',
        strMat: ' 68-72'
      },
      geometry: { type: 'Point', coordinates: [127.89188611, 36.44501389] }
    },
    {
      type: 'Feature',
      id: 400,
      properties: {
        DATE: '2024-10-26',
        jiguname: '상주화동',
        gennum: '615518',
        well: '암반',
        pyogo: '251.53',
        area: '금강권역',
        addr: '경상북도 상주시 화동면 신촌리 790',
        LON: '127.95168889',
        LAT: '36.37223056',
        insdate: '2013-11-07',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜지오그린21',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-9.0',
        cseExMat: '200',
        cseInHgt: 'STS304',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '52-56',
        strMat: ' 64-68'
      },
      geometry: { type: 'Point', coordinates: [127.95168889, 36.37223056] }
    },
    {
      type: 'Feature',
      id: 401,
      properties: {
        DATE: '2024-10-26',
        jiguname: '서산석남',
        gennum: '9909',
        well: '암반',
        pyogo: '30.11',
        area: '금강권역',
        addr: '충청남도 서산시 석남동  298-14',
        LON: '126.45671389',
        LAT: '36.75544444',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '63',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-12.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-63.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [126.45671389, 36.75544444] }
    },
    {
      type: 'Feature',
      id: 402,
      properties: {
        DATE: '2024-10-26',
        jiguname: '서산신창_심부2',
        gennum: '771469',
        well: '암반',
        pyogo: '46.3',
        area: '금강권역',
        addr: '충청남도 서산시 운산면 신창리 304-1 운신초등학교',
        LON: '126.56229444',
        LAT: '36.75718889',
        insdate: '2016-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-22.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-100',
        cseInMat: '125',
        strHgt: 'PVC',
        strDia: '50.0-100.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.56229444, 36.75718889] }
    },
    {
      type: 'Feature',
      id: 403,
      properties: {
        DATE: '2024-10-26',
        jiguname: '서산신창_천부',
        gennum: '771470',
        well: '충적',
        pyogo: '46.3',
        area: '금강권역',
        addr: '충청남도 서산시 운산면 신창리 304-1 운신초등학교',
        LON: '126.56229444',
        LAT: '36.75718889',
        insdate: '2016-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.56229444, 36.75718889] }
    },
    {
      type: 'Feature',
      id: 404,
      properties: {
        DATE: '2024-10-26',
        jiguname: '서산운산',
        gennum: '73517',
        well: '암반',
        pyogo: '32.94',
        area: '금강권역',
        addr: '충청남도 서산시 운산면 수당리 400-45',
        LON: '126.59374444',
        LAT: '36.81755278',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-6.8',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.59374444, 36.81755278] }
    },
    {
      type: 'Feature',
      id: 405,
      properties: {
        DATE: '2024-10-26',
        jiguname: '서산지곡',
        gennum: '639084',
        well: '암반',
        pyogo: '6.62',
        area: '금강권역',
        addr: '충청남도 서산시 지곡면 화천리 104-13',
        LON: '126.44441389',
        LAT: '36.88064167',
        insdate: '2016-11-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '150.5',
        cseExHgt: '300',
        cseExDia: '200',
        cseExMat: '0.0-14.0m',
        cseInHgt: '200',
        cseInDia: 'Stainless Steel',
        cseInMat: '0.0-150.5',
        strHgt: '150',
        strDia: 'PVC(VG1)',
        strMat: '62-78'
      },
      geometry: { type: 'Point', coordinates: [126.44441389, 36.88064167] }
    },
    {
      type: 'Feature',
      id: 406,
      properties: {
        DATE: '2024-10-26',
        jiguname: '서산취평_심부2',
        gennum: '771471',
        well: '암반',
        pyogo: '39.1',
        area: '금강권역',
        addr: '충청남도 서산시 부석면 취평리 550 부석고등학교',
        LON: '126.39988056',
        LAT: '36.71958889',
        insdate: '2016-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-9.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-100',
        cseInMat: '125',
        strHgt: 'PVC',
        strDia: '50.0-100.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.39988056, 36.71958889] }
    },
    {
      type: 'Feature',
      id: 407,
      properties: {
        DATE: '2024-10-26',
        jiguname: '서산취평_천부',
        gennum: '771472',
        well: '충적',
        pyogo: '39.1',
        area: '금강권역',
        addr: '충청남도 서산시 부석면 취평리 550 부석고등학교',
        LON: '126.39988056',
        LAT: '36.71958889',
        insdate: '2016-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '12',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-12',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '7.0-12.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.39988056, 36.71958889] }
    },
    {
      type: 'Feature',
      id: 408,
      properties: {
        DATE: '2024-10-26',
        jiguname: '서산팔봉',
        gennum: '87252',
        well: '암반',
        pyogo: '4.67',
        area: '금강권역',
        addr: '충청남도 서산시 팔봉면 어송리 1654-8',
        LON: '126.34517222',
        LAT: '36.80723611',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: '한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-4.7',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.34517222, 36.80723611] }
    },
    {
      type: 'Feature',
      id: 409,
      properties: {
        DATE: '2024-10-26',
        jiguname: '서산해미',
        gennum: '514305',
        well: '암반',
        pyogo: '34.98',
        area: '금강권역',
        addr: '충청남도 서산시 해미면 휴암리 144-4',
        LON: '126.54909167',
        LAT: '36.70806667',
        insdate: '2010-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '유성삼정개발㈜',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-12.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '39.7-43.7',
        strMat: ' 47.8-51.8'
      },
      geometry: { type: 'Point', coordinates: [126.54909167, 36.70806667] }
    },
    {
      type: 'Feature',
      id: 410,
      properties: {
        DATE: '2024-10-26',
        jiguname: '서울마곡',
        gennum: '84001',
        well: '암반',
        pyogo: '9.866',
        area: '한강권역',
        addr: '서울특별시 강서구 마곡동  41-86 마곡빗물펌프장',
        LON: '126.83711944',
        LAT: '37.57716667',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-23.8',
        cseInHgt: '250',
        cseInDia: '백관',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.83711944, 37.57716667] }
    },
    {
      type: 'Feature',
      id: 411,
      properties: {
        DATE: '2024-10-26',
        jiguname: '서울마곡',
        gennum: '84002',
        well: '충적',
        pyogo: '9.881',
        area: '한강권역',
        addr: '서울특별시 강서구 마곡동  41-86',
        LON: '126.83711944',
        LAT: '37.57716667',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '20',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-16.0',
        cseInHgt: '200',
        cseInDia: '백 관',
        cseInMat: '0-20',
        strHgt: '',
        strDia: '',
        strMat: '16.0-20.0'
      },
      geometry: { type: 'Point', coordinates: [126.83711944, 37.57716667] }
    },
    {
      type: 'Feature',
      id: 412,
      properties: {
        DATE: '2024-10-26',
        jiguname: '서울장위',
        gennum: '95502',
        well: '암반',
        pyogo: '22.33',
        area: '한강권역',
        addr: '서울특별시 성북구 장위동  270-1 장위빗물펌프장 내',
        LON: '127.05200278',
        LAT: '37.62201944',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-10.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '10.0-14.0'
      },
      geometry: { type: 'Point', coordinates: [127.05200278, 37.62201944] }
    },
    {
      type: 'Feature',
      id: 413,
      properties: {
        DATE: '2024-10-26',
        jiguname: '서울항동_신',
        gennum: '718001',
        well: '암반',
        pyogo: '22.06',
        area: '한강권역',
        addr: '서울특별시 구로구 항동  190',
        LON: '126.82138889',
        LAT: '37.47784444',
        insdate: '2018-04-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '60',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-10.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-60.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [126.82138889, 37.47784444] }
    },
    {
      type: 'Feature',
      id: 414,
      properties: {
        DATE: '2024-10-26',
        jiguname: '서천마산',
        gennum: '95520',
        well: '암반',
        pyogo: '25.95',
        area: '금강권역',
        addr: '충청남도 서천군 마산면 신장리 323-11',
        LON: '126.79125278',
        LAT: '36.11800278',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-34.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '20.0-24.0'
      },
      geometry: { type: 'Point', coordinates: [126.79125278, 36.11800278] }
    },
    {
      type: 'Feature',
      id: 415,
      properties: {
        DATE: '2024-10-26',
        jiguname: '서천마산',
        gennum: '95521',
        well: '충적',
        pyogo: '25.95',
        area: '금강권역',
        addr: '충청남도 서천군 마산면 신장리 323-11',
        LON: '126.79125278',
        LAT: '36.11800278',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '17',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-13.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-17',
        strHgt: '',
        strDia: '',
        strMat: '13.0-17.0'
      },
      geometry: { type: 'Point', coordinates: [126.79125278, 36.11800278] }
    },
    {
      type: 'Feature',
      id: 416,
      properties: {
        DATE: '2024-10-26',
        jiguname: '서천송내_심부2',
        gennum: '769785',
        well: '암반',
        pyogo: '13.4',
        area: '금강권역',
        addr: '충청남도 서천군 마서면 송내리 755 국립생태원',
        LON: '126.72263611',
        LAT: '36.02945833',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-14.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-100.0',
        cseInMat: '125',
        strHgt: '',
        strDia: '50.0-100.0',
        strMat: '125'
      },
      geometry: { type: 'Point', coordinates: [126.72263611, 36.02945833] }
    },
    {
      type: 'Feature',
      id: 417,
      properties: {
        DATE: '2024-10-26',
        jiguname: '서천송내_천부',
        gennum: '769786',
        well: '충적',
        pyogo: '13.4',
        area: '금강권역',
        addr: '충청남도 서천군 마서면 송내리 755 국립생태원',
        LON: '126.72263611',
        LAT: '36.02945833',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '11',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-11.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '8.0-11.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.72263611, 36.02945833] }
    },
    {
      type: 'Feature',
      id: 418,
      properties: {
        DATE: '2024-10-26',
        jiguname: '서천종천',
        gennum: '639095',
        well: '암반',
        pyogo: '29.947',
        area: '금강권역',
        addr: '충청남도 서천군 종천면 종천리 192-9',
        LON: '126.64511667',
        LAT: '36.10999167',
        insdate: '2016-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '152',
        cseExHgt: '300',
        cseExDia: '200',
        cseExMat: '0.0-24.5m',
        cseInHgt: '200',
        cseInDia: 'Stainless Steel',
        cseInMat: '0.0-152.0',
        strHgt: '150',
        strDia: 'PVC(VG1)',
        strMat: '80-92'
      },
      geometry: { type: 'Point', coordinates: [126.64511667, 36.10999167] }
    },
    {
      type: 'Feature',
      id: 419,
      properties: {
        DATE: '2024-10-26',
        jiguname: '서천지원_심부2',
        gennum: '769787',
        well: '암반',
        pyogo: '41.2',
        area: '금강권역',
        addr: '충청남도 서천군 문산면 지원리 509-1 서천군귀농지원센터',
        LON: '126.75952222',
        LAT: '36.15874444',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-100.0',
        cseInMat: '125',
        strHgt: '',
        strDia: '50.0-100.0',
        strMat: '125'
      },
      geometry: { type: 'Point', coordinates: [126.75952222, 36.15874444] }
    },
    {
      type: 'Feature',
      id: 420,
      properties: {
        DATE: '2024-10-26',
        jiguname: '서천지원_천부',
        gennum: '769788',
        well: '충적',
        pyogo: '41.2',
        area: '금강권역',
        addr: '충청남도 서천군 문산면 지원리 509-1 서천군귀농지원센터',
        LON: '126.75952222',
        LAT: '36.15874444',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.75952222, 36.15874444] }
    },
    {
      type: 'Feature',
      id: 421,
      properties: {
        DATE: '2024-10-26',
        jiguname: '성남사송',
        gennum: '612017',
        well: '암반',
        pyogo: '59.928',
        area: '한강권역',
        addr: '경기도 성남시수정구 사송동  512',
        LON: '127.10888889',
        LAT: '37.41388889',
        insdate: '2012-08-29',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜지오그린21',
        gulDiaTop: '105',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-13.0',
        cseExMat: '200',
        cseInHgt: 'STS304',
        cseInDia: '0.0-105',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '25.0-33.0',
        strMat: ' 53.0-65.0'
      },
      geometry: { type: 'Point', coordinates: [127.10888889, 37.41388889] }
    },
    {
      type: 'Feature',
      id: 422,
      properties: {
        DATE: '2024-10-26',
        jiguname: '성주대천_심부2',
        gennum: '771473',
        well: '암반',
        pyogo: '116.14',
        area: '낙동강권역',
        addr: '경상북도 성주군 대가면 대천리 635 성주군과체류시험장',
        LON: '128.17826111',
        LAT: '35.88028611',
        insdate: '2015-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-12.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.17826111, 35.88028611] }
    },
    {
      type: 'Feature',
      id: 423,
      properties: {
        DATE: '2024-10-26',
        jiguname: '성주대천_천부',
        gennum: '771474',
        well: '충적',
        pyogo: '116.14',
        area: '낙동강권역',
        addr: '경상북도 성주군 대가면 대천리 635 성주군과체류시험장',
        LON: '128.17826111',
        LAT: '35.88028611',
        insdate: '2015-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.17826111, 35.88028611] }
    },
    {
      type: 'Feature',
      id: 424,
      properties: {
        DATE: '2024-10-26',
        jiguname: '성주벽진',
        gennum: '9894',
        well: '암반',
        pyogo: '48.47',
        area: '낙동강권역',
        addr: '경상북도 성주군 벽진면 봉계리 1442-41',
        LON: '128.23518611',
        LAT: '35.93844722',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '76',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-17.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-76.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [128.23518611, 35.93844722] }
    },
    {
      type: 'Feature',
      id: 425,
      properties: {
        DATE: '2024-10-26',
        jiguname: '성주벽진',
        gennum: '9895',
        well: '충적',
        pyogo: '48.42',
        area: '낙동강권역',
        addr: '경상북도 성주군 벽진면 봉계리 1442-41',
        LON: '128.23518611',
        LAT: '35.93844722',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '10',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-10.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0-10',
        cseInMat: '',
        strHgt: '',
        strDia: '6.0-10.0',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [128.23518611, 35.93844722] }
    },
    {
      type: 'Feature',
      id: 426,
      properties: {
        DATE: '2024-10-26',
        jiguname: '세종부강',
        gennum: '82016',
        well: '암반',
        pyogo: '23.865',
        area: '금강권역',
        addr: '세종특별자치시 세종시 부강면 부강리 1149-43',
        LON: '127.36524444',
        LAT: '36.52205000',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-14.3',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '49.0-53.0'
      },
      geometry: { type: 'Point', coordinates: [127.36524444, 36.52205] }
    },
    {
      type: 'Feature',
      id: 427,
      properties: {
        DATE: '2024-10-26',
        jiguname: '세종부강',
        gennum: '82017',
        well: '충적',
        pyogo: '23.76',
        area: '금강권역',
        addr: '세종특별자치시 세종시 부강면 부강리 1149-43',
        LON: '127.36524444',
        LAT: '36.52205000',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-6.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6.0-10.0'
      },
      geometry: { type: 'Point', coordinates: [127.36524444, 36.52205] }
    },
    {
      type: 'Feature',
      id: 428,
      properties: {
        DATE: '2024-10-26',
        jiguname: '세종조치원',
        gennum: '65593',
        well: '암반',
        pyogo: '26.24',
        area: '금강권역',
        addr: '세종특별자치시 세종시 조치원읍 평리 12-1 평리 정수장 내',
        LON: '127.30025000',
        LAT: '36.60678056',
        insdate: '2000-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-17.5',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.30025, 36.60678056] }
    },
    {
      type: 'Feature',
      id: 429,
      properties: {
        DATE: '2024-10-26',
        jiguname: '세종조치원',
        gennum: '65594',
        well: '충적',
        pyogo: '26.22',
        area: '금강권역',
        addr: '세종특별자치시 세종시 조치원읍 평리 12-1 평리 정수장 내',
        LON: '127.30025000',
        LAT: '36.60678056',
        insdate: '2000-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '15',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-11.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-15',
        strHgt: '',
        strDia: '',
        strMat: '11.0-15.0'
      },
      geometry: { type: 'Point', coordinates: [127.30025, 36.60678056] }
    },
    {
      type: 'Feature',
      id: 430,
      properties: {
        DATE: '2024-10-26',
        jiguname: '속초노학',
        gennum: '73503',
        well: '암반',
        pyogo: '14.284',
        area: '한강권역',
        addr: '강원특별자치도 속초시 노학동  764-6',
        LON: '128.54670833',
        LAT: '38.18677222',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-18',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'PVC',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [128.54670833, 38.18677222] }
    },
    {
      type: 'Feature',
      id: 431,
      properties: {
        DATE: '2024-10-26',
        jiguname: '속초노학',
        gennum: '73504',
        well: '충적',
        pyogo: '14.295',
        area: '한강권역',
        addr: '강원특별자치도 속초시 노학동  764-6',
        LON: '128.54670833',
        LAT: '38.18677222',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '12',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0-12',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-12',
        strHgt: '',
        strDia: '',
        strMat: '8-12'
      },
      geometry: { type: 'Point', coordinates: [128.54670833, 38.18677222] }
    },
    {
      type: 'Feature',
      id: 432,
      properties: {
        DATE: '2024-10-26',
        jiguname: '속초장사_심부2',
        gennum: '769789',
        well: '암반',
        pyogo: '57',
        area: '한강권역',
        addr: '강원특별자치도 속초시 장사동  405-5 속초청해학교',
        LON: '128.56013611',
        LAT: '38.21951389',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '83',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-14.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-83.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '77.0-83.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.56013611, 38.21951389] }
    },
    {
      type: 'Feature',
      id: 433,
      properties: {
        DATE: '2024-10-26',
        jiguname: '속초장사_천부',
        gennum: '769790',
        well: '충적',
        pyogo: '57',
        area: '한강권역',
        addr: '강원특별자치도 속초시 장사동  405-5 속초청해학교',
        LON: '128.56013611',
        LAT: '38.21951389',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.56013611, 38.21951389] }
    },
    {
      type: 'Feature',
      id: 434,
      properties: {
        DATE: '2024-10-26',
        jiguname: '수원오목천',
        gennum: '9872',
        well: '암반',
        pyogo: '26.05',
        area: '한강권역',
        addr: '경기도 수원시권선구 오목천동  919-6',
        LON: '126.96960556',
        LAT: '37.24412500',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '72',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-17.0',
        cseExMat: '250',
        cseInHgt: '백관',
        cseInDia: '0.0-72.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [126.96960556, 37.244125] }
    },
    {
      type: 'Feature',
      id: 435,
      properties: {
        DATE: '2024-10-26',
        jiguname: '순창고원_심부2',
        gennum: '771475',
        well: '암반',
        pyogo: '82.2',
        area: '영섬권역',
        addr: '전북특별자치도 순창군 적성면 고원리 616 적성초등학교',
        LON: '127.20962500',
        LAT: '35.39786111',
        insdate: '2017-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-9.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.209625, 35.39786111] }
    },
    {
      type: 'Feature',
      id: 436,
      properties: {
        DATE: '2024-10-26',
        jiguname: '순창고원_천부',
        gennum: '771476',
        well: '충적',
        pyogo: '82.2',
        area: '영섬권역',
        addr: '전북특별자치도 순창군 적성면 고원리 616 적성초등학교',
        LON: '127.20962500',
        LAT: '35.39786111',
        insdate: '2017-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-3.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.209625, 35.39786111] }
    },
    {
      type: 'Feature',
      id: 437,
      properties: {
        DATE: '2024-10-26',
        jiguname: '순창순창',
        gennum: '65055',
        well: '암반',
        pyogo: '84.82',
        area: '영섬권역',
        addr: '전북특별자치도 순창군 순창읍 남계리 968-28번지',
        LON: '127.14823333',
        LAT: '35.36792222',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-16.5',
        cseInHgt: '250',
        cseInDia: '백관',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.14823333, 35.36792222] }
    },
    {
      type: 'Feature',
      id: 438,
      properties: {
        DATE: '2024-10-26',
        jiguname: '순창순창',
        gennum: '65056',
        well: '충적',
        pyogo: '84.72',
        area: '영섬권역',
        addr: '전북특별자치도 순창군 순창읍 남계리 968-28번지',
        LON: '127.14823333',
        LAT: '35.36792222',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '8',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-4.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-8',
        strHgt: '',
        strDia: '',
        strMat: '4.0-8.0'
      },
      geometry: { type: 'Point', coordinates: [127.14823333, 35.36792222] }
    },
    {
      type: 'Feature',
      id: 439,
      properties: {
        DATE: '2024-10-26',
        jiguname: '순창쌍치',
        gennum: '82030',
        well: '암반',
        pyogo: '226.873',
        area: '영섬권역',
        addr: '전북특별자치도 순창군 쌍치면 금평리 811-7',
        LON: '126.99210556',
        LAT: '35.49944167',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-11',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.99210556, 35.49944167] }
    },
    {
      type: 'Feature',
      id: 440,
      properties: {
        DATE: '2024-10-26',
        jiguname: '순창쌍치',
        gennum: '82031',
        well: '충적',
        pyogo: '226.849',
        area: '영섬권역',
        addr: '전북특별자치도 순창군 쌍치면 금평리 811-7',
        LON: '126.99210556',
        LAT: '35.49944167',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0-6',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6.0-10.0'
      },
      geometry: { type: 'Point', coordinates: [126.99210556, 35.49944167] }
    },
    {
      type: 'Feature',
      id: 441,
      properties: {
        DATE: '2024-10-26',
        jiguname: '순창외이_심부2',
        gennum: '737620',
        well: '암반',
        pyogo: '82.27',
        area: '영섬권역',
        addr: '전북특별자치도 순창군 유등면 외이리 186',
        LON: '127.18926667',
        LAT: '35.36216944',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-9.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.18926667, 35.36216944] }
    },
    {
      type: 'Feature',
      id: 442,
      properties: {
        DATE: '2024-10-26',
        jiguname: '순창외이_천부',
        gennum: '737619',
        well: '충적',
        pyogo: '82.27',
        area: '영섬권역',
        addr: '전북특별자치도 순창군 유등면 외이리 186',
        LON: '127.18926667',
        LAT: '35.36216944',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-3.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.18926667, 35.36216944] }
    },
    {
      type: 'Feature',
      id: 443,
      properties: {
        DATE: '2024-10-26',
        jiguname: '순창운남_심부2',
        gennum: '769791',
        well: '암반',
        pyogo: '199.056',
        area: '영섬권역',
        addr: '전북특별자치도 순창군 구림면 운남리 233 구림초중학교',
        LON: '127.10061944',
        LAT: '35.45273056',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-6.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.10061944, 35.45273056] }
    },
    {
      type: 'Feature',
      id: 444,
      properties: {
        DATE: '2024-10-26',
        jiguname: '순창운남_천부',
        gennum: '769792',
        well: '충적',
        pyogo: '199.076',
        area: '영섬권역',
        addr: '전북특별자치도 순창군 구림면 운남리 233 구림초중학교',
        LON: '127.10061944',
        LAT: '35.45273056',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-5.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '5.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.10061944, 35.45273056] }
    },
    {
      type: 'Feature',
      id: 445,
      properties: {
        DATE: '2024-10-26',
        jiguname: '순창정산_심부2',
        gennum: '769793',
        well: '암반',
        pyogo: '294',
        area: '영섬권역',
        addr: '전북특별자치도 순창군 복흥면 정산리 686 복흥중학교',
        LON: '126.92578056',
        LAT: '35.43226944',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-19.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.92578056, 35.43226944] }
    },
    {
      type: 'Feature',
      id: 446,
      properties: {
        DATE: '2024-10-26',
        jiguname: '순창정산_천부',
        gennum: '769794',
        well: '충적',
        pyogo: '294',
        area: '영섬권역',
        addr: '전북특별자치도 순창군 복흥면 정산리 686 복흥중학교',
        LON: '126.92578056',
        LAT: '35.43226944',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.92578056, 35.43226944] }
    },
    {
      type: 'Feature',
      id: 447,
      properties: {
        DATE: '2024-10-26',
        jiguname: '순창현포_심부2',
        gennum: '737622',
        well: '암반',
        pyogo: '90.983',
        area: '영섬권역',
        addr: '전북특별자치도 순창군 동계면 현포리 516',
        LON: '127.24202500',
        LAT: '35.44150833',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '82',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-23.8',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-82.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '76.0-82.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.242025, 35.44150833] }
    },
    {
      type: 'Feature',
      id: 448,
      properties: {
        DATE: '2024-10-26',
        jiguname: '순창현포_천부',
        gennum: '737621',
        well: '충적',
        pyogo: '90.968',
        area: '영섬권역',
        addr: '전북특별자치도 순창군 동계면 현포리 516',
        LON: '127.24202500',
        LAT: '35.44150833',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.242025, 35.44150833] }
    },
    {
      type: 'Feature',
      id: 449,
      properties: {
        DATE: '2024-10-26',
        jiguname: '순천동송_심부2',
        gennum: '771477',
        well: '암반',
        pyogo: '15.2',
        area: '영섬권역',
        addr: '전라남도 순천시 별량면 동송리 400 순천별량중학교',
        LON: '127.45329167',
        LAT: '34.86581944',
        insdate: '2011-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '74-80',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.45329167, 34.86581944] }
    },
    {
      type: 'Feature',
      id: 450,
      properties: {
        DATE: '2024-10-26',
        jiguname: '순천동송_천부',
        gennum: '771478',
        well: '충적',
        pyogo: '15.2',
        area: '영섬권역',
        addr: '전라남도 순천시 별량면 동송리 400 순천별량중학교',
        LON: '127.45329167',
        LAT: '34.86581944',
        insdate: '2011-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '15',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-15',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.45329167, 34.86581944] }
    },
    {
      type: 'Feature',
      id: 451,
      properties: {
        DATE: '2024-10-26',
        jiguname: '순천상사',
        gennum: '65057',
        well: '암반',
        pyogo: '15.76',
        area: '영섬권역',
        addr: '전라남도 순천시 상사면 흘산리 252-22 상사면사무소',
        LON: '127.45485833',
        LAT: '34.93925000',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-11.2',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.45485833, 34.93925] }
    },
    {
      type: 'Feature',
      id: 452,
      properties: {
        DATE: '2024-10-26',
        jiguname: '순천승주',
        gennum: '65058',
        well: '암반',
        pyogo: '125.13',
        area: '영섬권역',
        addr: '전라남도 순천시 승주읍 서평리 92-1 승주읍사무소',
        LON: '127.39007500',
        LAT: '35.01449722',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-15.6',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.390075, 35.01449722] }
    },
    {
      type: 'Feature',
      id: 453,
      properties: {
        DATE: '2024-10-26',
        jiguname: '순천외서',
        gennum: '9896',
        well: '암반',
        pyogo: '201.69',
        area: '영섬권역',
        addr: '전라남도 순천시 외서면 화전리 320-4 외서면사무소',
        LON: '127.27689167',
        LAT: '34.91396667',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-12.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [127.27689167, 34.91396667] }
    },
    {
      type: 'Feature',
      id: 454,
      properties: {
        DATE: '2024-10-26',
        jiguname: '순천외서',
        gennum: '9897',
        well: '충적',
        pyogo: '201.71',
        area: '영섬권역',
        addr: '전라남도 순천시 외서면 화전리 320-4 외서면사무소',
        LON: '127.27689167',
        LAT: '34.91396667',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '6',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-60.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0-6',
        cseInMat: '',
        strHgt: '',
        strDia: '2.0-6.0',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [127.27689167, 34.91396667] }
    },
    {
      type: 'Feature',
      id: 455,
      properties: {
        DATE: '2024-10-26',
        jiguname: '순천주암',
        gennum: '717013',
        well: '암반',
        pyogo: '203.89',
        area: '영섬권역',
        addr: '전라남도 순천시 주암면 행정리 525-2',
        LON: '127.30483611',
        LAT: '35.03052500',
        insdate: '2017-12-05',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜송암',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-17.3',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '70-90',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [127.30483611, 35.030525] }
    },
    {
      type: 'Feature',
      id: 456,
      properties: {
        DATE: '2024-10-26',
        jiguname: '순천풍덕',
        gennum: '6753',
        well: '암반',
        pyogo: '6.3',
        area: '영섬권역',
        addr: '전라남도 순천시 풍덕동  325-7 풍덕 제2배수펌프장',
        LON: '127.50846389',
        LAT: '34.93701389',
        insdate: '1995-12-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '60',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-12.0',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0.0-60.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '12.0-18.0',
        strMat: ' 48.0-56.0'
      },
      geometry: { type: 'Point', coordinates: [127.50846389, 34.93701389] }
    },
    {
      type: 'Feature',
      id: 457,
      properties: {
        DATE: '2024-10-26',
        jiguname: '순천풍덕',
        gennum: '6752',
        well: '충적',
        pyogo: '6.44',
        area: '영섬권역',
        addr: '전라남도 순천시 풍덕동  325-7 풍덕 제2배수펌프장',
        LON: '127.50846389',
        LAT: '34.93701389',
        insdate: '1995-12-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '10',
        gulDiaBtm: '350',
        cseExHgt: '350',
        cseExDia: '0.0-10.0',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0-10',
        cseInMat: '',
        strHgt: '',
        strDia: '3.0-9.0',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [127.50846389, 34.93701389] }
    },
    {
      type: 'Feature',
      id: 458,
      properties: {
        DATE: '2024-10-26',
        jiguname: '순천황전',
        gennum: '65059',
        well: '암반',
        pyogo: '69.32',
        area: '영섬권역',
        addr: '전라남도 순천시 황전면 괴목리 51-1 황전면사무소',
        LON: '127.42751111',
        LAT: '35.10125000',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-24.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-64.0'
      },
      geometry: { type: 'Point', coordinates: [127.42751111, 35.10125] }
    },
    {
      type: 'Feature',
      id: 459,
      properties: {
        DATE: '2024-10-26',
        jiguname: '순천황전',
        gennum: '65060',
        well: '충적',
        pyogo: '69.31',
        area: '영섬권역',
        addr: '전라남도 순천시 황전면 괴목리 51-1 황전면사무소',
        LON: '127.42751111',
        LAT: '35.10125000',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '8.5',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-4.5',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-8.5',
        strHgt: '',
        strDia: '',
        strMat: '4.5-8.5'
      },
      geometry: { type: 'Point', coordinates: [127.42751111, 35.10125] }
    },
    {
      type: 'Feature',
      id: 460,
      properties: {
        DATE: '2024-10-26',
        jiguname: '시흥군자',
        gennum: '9873',
        well: '암반',
        pyogo: '11.26',
        area: '한강권역',
        addr: '경기도 시흥시 군자동  583-3',
        LON: '126.78410556',
        LAT: '37.35635556',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '62',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-17.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-62.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [126.78410556, 37.35635556] }
    },
    {
      type: 'Feature',
      id: 461,
      properties: {
        DATE: '2024-10-26',
        jiguname: '시흥군자',
        gennum: '9874',
        well: '충적',
        pyogo: '11.29',
        area: '한강권역',
        addr: '경기도 시흥시 군자동  583-3',
        LON: '126.78410556',
        LAT: '37.35635556',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '16.4',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-16.4',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0-16.4',
        cseInMat: '',
        strHgt: '',
        strDia: '4.5-8.5',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [126.78410556, 37.35635556] }
    },
    {
      type: 'Feature',
      id: 462,
      properties: {
        DATE: '2024-10-26',
        jiguname: '신안신용_심부2',
        gennum: '771479',
        well: '암반',
        pyogo: '18.281',
        area: '영섬권역',
        addr: '전라남도 신안군 압해읍 신용리 124-1 신안공설운동장',
        LON: '126.30994444',
        LAT: '34.87138889',
        insdate: '2018-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '83',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-25.9',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-83',
        cseInMat: '100',
        strHgt: 'PVC(HI-VP)',
        strDia: '77.0-83.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.30994444, 34.87138889] }
    },
    {
      type: 'Feature',
      id: 463,
      properties: {
        DATE: '2024-10-26',
        jiguname: '신안신용_천부',
        gennum: '771480',
        well: '충적',
        pyogo: '18.277',
        area: '영섬권역',
        addr: '전라남도 신안군 압해읍 신용리 124-1 신안공설운동장',
        LON: '126.30994444',
        LAT: '34.87138889',
        insdate: '2018-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '11',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-11',
        cseInMat: '100',
        strHgt: 'PVC(HI-VP)',
        strDia: '6.5-11.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.30994444, 34.87138889] }
    },
    {
      type: 'Feature',
      id: 464,
      properties: {
        DATE: '2024-10-26',
        jiguname: '신안신장_심부2',
        gennum: '771481',
        well: '암반',
        pyogo: '22.403',
        area: '영섬권역',
        addr: '전라남도 신안군 압해읍 신장리 462-10 신안군청',
        LON: '126.35119167',
        LAT: '34.83238611',
        insdate: '2018-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-7.7',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC(HI-VP)',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.35119167, 34.83238611] }
    },
    {
      type: 'Feature',
      id: 465,
      properties: {
        DATE: '2024-10-26',
        jiguname: '신안신장_천부',
        gennum: '771482',
        well: '충적',
        pyogo: '22.417',
        area: '영섬권역',
        addr: '전라남도 신안군 압해읍 신장리 462-10 신안군청',
        LON: '126.35119167',
        LAT: '34.83238611',
        insdate: '2018-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '20',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-20',
        cseInMat: '100',
        strHgt: 'PVC(HI-VP)',
        strDia: '6.5-20.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.35119167, 34.83238611] }
    },
    {
      type: 'Feature',
      id: 466,
      properties: {
        DATE: '2024-10-26',
        jiguname: '아산도고',
        gennum: '9910',
        well: '암반',
        pyogo: '17.17',
        area: '금강권역',
        addr: '충청남도 아산시 도고면 시전리 581-37',
        LON: '126.89882500',
        LAT: '36.75545833',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '64',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-18.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-64.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [126.898825, 36.75545833] }
    },
    {
      type: 'Feature',
      id: 467,
      properties: {
        DATE: '2024-10-26',
        jiguname: '아산도고',
        gennum: '9911',
        well: '충적',
        pyogo: '17.15',
        area: '금강권역',
        addr: '충청남도 아산시 도고면 시전리 581-37',
        LON: '126.89882500',
        LAT: '36.75545833',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '9.5',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-9.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0-9.5',
        cseInMat: '',
        strHgt: '',
        strDia: '5.5-9.5',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [126.898825, 36.75545833] }
    },
    {
      type: 'Feature',
      id: 468,
      properties: {
        DATE: '2024-10-26',
        jiguname: '아산득산',
        gennum: '65061',
        well: '암반',
        pyogo: '20.65',
        area: '금강권역',
        addr: '충청남도 아산시 득산동  394-15번지',
        LON: '126.96334167',
        LAT: '36.78562500',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-11',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.96334167, 36.785625] }
    },
    {
      type: 'Feature',
      id: 469,
      properties: {
        DATE: '2024-10-26',
        jiguname: '아산득산',
        gennum: '65062',
        well: '충적',
        pyogo: '20.62',
        area: '금강권역',
        addr: '충청남도 아산시 득산동  394-15',
        LON: '126.96334167',
        LAT: '36.78562500',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '9',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-5.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-9',
        strHgt: '',
        strDia: '',
        strMat: '5.0-9.0'
      },
      geometry: { type: 'Point', coordinates: [126.96334167, 36.785625] }
    },
    {
      type: 'Feature',
      id: 470,
      properties: {
        DATE: '2024-10-26',
        jiguname: '아산탕정',
        gennum: '793617',
        well: '암반',
        pyogo: '16.4',
        area: '',
        addr: '충청남도 아산시 탕정면 갈산리 583-17',
        LON: '127.05435534',
        LAT: '36.79212878',
        insdate: '2023-12-01',
        manager: '',
        builder: '',
        gulDep: '100',
        gulDiaTop: '300',
        gulDiaBtm: '200',
        cseExHgt: '0.0-21.0',
        cseExDia: '200',
        cseExMat: 'STEEL PIPE',
        cseInHgt: '0.0-100.0',
        cseInDia: '150',
        cseInMat: 'STEEL PIPE',
        strHgt: '70.0-100.0',
        strDia: '150',
        strMat: 'STEEL PIPE'
      },
      geometry: { type: 'Point', coordinates: [127.05435534, 36.79212878] }
    },
    {
      type: 'Feature',
      id: 471,
      properties: {
        DATE: '2024-10-26',
        jiguname: '아산탕정',
        gennum: '793618',
        well: '충적',
        pyogo: '16.4',
        area: '',
        addr: '충청남도 아산시 탕정면 갈산리 583-17',
        LON: '127.05433466',
        LAT: '36.79213335',
        insdate: '2023-12-01',
        manager: '',
        builder: '',
        gulDep: '20',
        gulDiaTop: '400',
        gulDiaBtm: '300',
        cseExHgt: '0.0-3.0',
        cseExDia: '300',
        cseExMat: 'STEEL PIPE',
        cseInHgt: '0.0-20.0',
        cseInDia: '200',
        cseInMat: 'P. V. C',
        strHgt: '19.0-20.0',
        strDia: '200',
        strMat: 'P. V. C'
      },
      geometry: { type: 'Point', coordinates: [127.05433466, 36.79213335] }
    },
    {
      type: 'Feature',
      id: 472,
      properties: {
        DATE: '2024-10-26',
        jiguname: '안동가구_심부2',
        gennum: '737626',
        well: '암반',
        pyogo: '202.874',
        area: '낙동강권역',
        addr: '경상북도 안동시 와룡면 가구리 724-5',
        LON: '128.76746111',
        LAT: '36.61635556',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '87',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-17.3',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-87.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '81.0-87.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.76746111, 36.61635556] }
    },
    {
      type: 'Feature',
      id: 473,
      properties: {
        DATE: '2024-10-26',
        jiguname: '안동가구_천부',
        gennum: '737625',
        well: '충적',
        pyogo: '202.871',
        area: '낙동강권역',
        addr: '경상북도 안동시 와룡면 가구리 724-5',
        LON: '128.76746111',
        LAT: '36.61635556',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '12',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-7.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-12.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '7.5-12.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.76746111, 36.61635556] }
    },
    {
      type: 'Feature',
      id: 474,
      properties: {
        DATE: '2024-10-26',
        jiguname: '안동고란',
        gennum: '753914',
        well: '암반',
        pyogo: '244.171',
        area: '낙동강권역',
        addr: '경상북도 안동시 길안면 고란리 108',
        LON: '128.94649444',
        LAT: '36.41016944',
        insdate: '2019-11-26',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '250',
        cseExHgt: '200',
        cseExDia: '0-11.5',
        cseExMat: '250',
        cseInHgt: '강관',
        cseInDia: '0-100',
        cseInMat: '200',
        strHgt: 'PVC',
        strDia: '11.5-100',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [128.94649444, 36.41016944] }
    },
    {
      type: 'Feature',
      id: 475,
      properties: {
        DATE: '2024-10-26',
        jiguname: '안동고란',
        gennum: '753913',
        well: '충적',
        pyogo: '244.146',
        area: '낙동강권역',
        addr: '경상북도 안동시 길안면 고란리 108',
        LON: '128.94649444',
        LAT: '36.41016944',
        insdate: '2019-11-26',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '250',
        cseExHgt: '200',
        cseExDia: '0-4',
        cseExMat: '250',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '200',
        strHgt: 'PVC',
        strDia: '4-10',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [128.94649444, 36.41016944] }
    },
    {
      type: 'Feature',
      id: 476,
      properties: {
        DATE: '2024-10-26',
        jiguname: '안동길안',
        gennum: '73537',
        well: '암반',
        pyogo: '128.91',
        area: '낙동강권역',
        addr: '경상북도 안동시 길안면 천지리 731-1',
        LON: '128.89569444',
        LAT: '36.45789167',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-10.7',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [128.89569444, 36.45789167] }
    },
    {
      type: 'Feature',
      id: 477,
      properties: {
        DATE: '2024-10-26',
        jiguname: '안동길안',
        gennum: '73538',
        well: '충적',
        pyogo: '128.91',
        area: '낙동강권역',
        addr: '경상북도 안동시 길안면 천지리 731-1',
        LON: '128.89569444',
        LAT: '36.45789167',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-10.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6.0-10.0'
      },
      geometry: { type: 'Point', coordinates: [128.89569444, 36.45789167] }
    },
    {
      type: 'Feature',
      id: 478,
      properties: {
        DATE: '2024-10-26',
        jiguname: '안동남후',
        gennum: '771363',
        well: '암반',
        pyogo: '97.7',
        area: '낙동강권역',
        addr: '경상북도 안동시 남후면 광음리 427-1',
        LON: '128.66925556',
        LAT: '36.50855556',
        insdate: '2021-12-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-5.5',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P.V.C(VG1)',
        strDia: '46.0-54.0',
        strMat: ' 72.0-96.0'
      },
      geometry: { type: 'Point', coordinates: [128.66925556, 36.50855556] }
    },
    {
      type: 'Feature',
      id: 479,
      properties: {
        DATE: '2024-10-26',
        jiguname: '안동녹전',
        gennum: '603272',
        well: '암반',
        pyogo: '207.48',
        area: '낙동강권역',
        addr: '경상북도 안동시 녹전면 신평리 729',
        LON: '128.77489167',
        LAT: '36.74257500',
        insdate: '2011-12-28',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜한국건업엔지니어링',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-15.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '36-40',
        strMat: ' 52-56'
      },
      geometry: { type: 'Point', coordinates: [128.77489167, 36.742575] }
    },
    {
      type: 'Feature',
      id: 480,
      properties: {
        DATE: '2024-10-26',
        jiguname: '안동도산',
        gennum: '771361',
        well: '암반',
        pyogo: '172.9',
        area: '낙동강권역',
        addr: '경상북도 안동시 도산면 단천리 532',
        LON: '128.88105833',
        LAT: '36.73588611',
        insdate: '2021-12-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '98',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-7.5',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0.0-98.0',
        cseInMat: '150',
        strHgt: 'P.V.C(VG1)',
        strDia: '40.0-48.0',
        strMat: ' 70.0-92.0'
      },
      geometry: { type: 'Point', coordinates: [128.88105833, 36.73588611] }
    },
    {
      type: 'Feature',
      id: 481,
      properties: {
        DATE: '2024-10-26',
        jiguname: '안동송천',
        gennum: '771362',
        well: '암반',
        pyogo: '98.3',
        area: '낙동강권역',
        addr: '경상북도 안동시 송천동  1319-101',
        LON: '128.80166389',
        LAT: '36.53727500',
        insdate: '2021-12-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-8.0',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P.V.C(VG1)',
        strDia: '36.0-44.0',
        strMat: ' 70.0-92.0'
      },
      geometry: { type: 'Point', coordinates: [128.80166389, 36.537275] }
    },
    {
      type: 'Feature',
      id: 482,
      properties: {
        DATE: '2024-10-26',
        jiguname: '안동신성_심부2',
        gennum: '771483',
        well: '암반',
        pyogo: '65.154',
        area: '낙동강권역',
        addr: '경상북도 안동시 풍천면 신성리 373 신성초등학교',
        LON: '128.46244444',
        LAT: '36.52588611',
        insdate: '2015-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-10.1',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.46244444, 36.52588611] }
    },
    {
      type: 'Feature',
      id: 483,
      properties: {
        DATE: '2024-10-26',
        jiguname: '안동신성_천부',
        gennum: '771484',
        well: '충적',
        pyogo: '65.154',
        area: '낙동강권역',
        addr: '경상북도 안동시 풍천면 신성리 373 신성초등학교',
        LON: '128.46244444',
        LAT: '36.52588611',
        insdate: '2015-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.46244444, 36.52588611] }
    },
    {
      type: 'Feature',
      id: 484,
      properties: {
        DATE: '2024-10-26',
        jiguname: '안동옥동_심부2',
        gennum: '771485',
        well: '암반',
        pyogo: '89.374',
        area: '낙동강권역',
        addr: '경상북도 안동시 옥동  1202 한국생명과학고등학교',
        LON: '128.68498056',
        LAT: '36.55002222',
        insdate: '2015-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-19.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.68498056, 36.55002222] }
    },
    {
      type: 'Feature',
      id: 485,
      properties: {
        DATE: '2024-10-26',
        jiguname: '안동옥동_천부',
        gennum: '771486',
        well: '충적',
        pyogo: '89.374',
        area: '낙동강권역',
        addr: '경상북도 안동시 옥동  1202 한국생명과학고등학교',
        LON: '128.68498056',
        LAT: '36.55002222',
        insdate: '2015-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.68498056, 36.55002222] }
    },
    {
      type: 'Feature',
      id: 486,
      properties: {
        DATE: '2024-10-26',
        jiguname: '안동와룡',
        gennum: '603273',
        well: '암반',
        pyogo: '224.29',
        area: '낙동강권역',
        addr: '경상북도 안동시 와룡면 서현리 273-3',
        LON: '128.75527500',
        LAT: '36.66858056',
        insdate: '2011-12-28',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜한국건업엔지니어링',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-4.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '36-40',
        strMat: ' 52-56'
      },
      geometry: { type: 'Point', coordinates: [128.755275, 36.66858056] }
    },
    {
      type: 'Feature',
      id: 487,
      properties: {
        DATE: '2024-10-26',
        jiguname: '안동정하_심부2',
        gennum: '737628',
        well: '암반',
        pyogo: '123',
        area: '낙동강권역',
        addr: '경상북도 안동시 정하동  425',
        LON: '128.72615556',
        LAT: '36.54858333',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-10.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.72615556, 36.54858333] }
    },
    {
      type: 'Feature',
      id: 488,
      properties: {
        DATE: '2024-10-26',
        jiguname: '안동정하_천부',
        gennum: '737627',
        well: '충적',
        pyogo: '123',
        area: '낙동강권역',
        addr: '경상북도 안동시 정하동  425',
        LON: '128.72615556',
        LAT: '36.54858333',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.72615556, 36.54858333] }
    },
    {
      type: 'Feature',
      id: 489,
      properties: {
        DATE: '2024-10-26',
        jiguname: '안동태화',
        gennum: '9886',
        well: '암반',
        pyogo: '94.93',
        area: '낙동강권역',
        addr: '경상북도 안동시 태화동  570 배수펌프장변',
        LON: '128.70692500',
        LAT: '36.55843333',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '63',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-18.0',
        cseExMat: '250',
        cseInHgt: '백관',
        cseInDia: '0.0-64.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [128.706925, 36.55843333] }
    },
    {
      type: 'Feature',
      id: 490,
      properties: {
        DATE: '2024-10-26',
        jiguname: '안동태화',
        gennum: '9887',
        well: '충적',
        pyogo: '94.92',
        area: '낙동강권역',
        addr: '경상북도 안동시 태화동  570 배수펌프장변',
        LON: '128.70692500',
        LAT: '36.55843333',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '10',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-10.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0-10',
        cseInMat: '',
        strHgt: '',
        strDia: '6.0-10.0',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [128.706925, 36.55843333] }
    },
    {
      type: 'Feature',
      id: 491,
      properties: {
        DATE: '2024-10-26',
        jiguname: '안동풍산',
        gennum: '639082',
        well: '암반',
        pyogo: '80.094',
        area: '낙동강권역',
        addr: '경상북도 안동시 풍산읍 매곡리 581-2',
        LON: '128.55888889',
        LAT: '36.58502500',
        insdate: '2016-11-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '100',
        cseExHgt: '300',
        cseExDia: '200',
        cseExMat: '0.0-39.0m',
        cseInHgt: '200',
        cseInDia: 'Stainless Steel',
        cseInMat: '0.0-100.0',
        strHgt: '150',
        strDia: 'PVC(VG1)',
        strMat: '72-76'
      },
      geometry: { type: 'Point', coordinates: [128.55888889, 36.585025] }
    },
    {
      type: 'Feature',
      id: 492,
      properties: {
        DATE: '2024-10-26',
        jiguname: '안산부곡',
        gennum: '84015',
        well: '암반',
        pyogo: '73.942',
        area: '한강권역',
        addr: '경기도 안산시상록구 부곡동  산41-9 반월정수장내',
        LON: '126.86809167',
        LAT: '37.33711667',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-6.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '51.4-55.4'
      },
      geometry: { type: 'Point', coordinates: [126.86809167, 37.33711667] }
    },
    {
      type: 'Feature',
      id: 493,
      properties: {
        DATE: '2024-10-26',
        jiguname: '안성삼죽',
        gennum: '65021',
        well: '암반',
        pyogo: '109.44',
        area: '한강권역',
        addr: '경기도 안성시 삼죽면 용월리 429-2',
        LON: '127.37875556',
        LAT: '37.07741667',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '49',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-41.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-49.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '28.0-32.0',
        strMat: ' 36.0-40.0'
      },
      geometry: { type: 'Point', coordinates: [127.37875556, 37.07741667] }
    },
    {
      type: 'Feature',
      id: 494,
      properties: {
        DATE: '2024-10-26',
        jiguname: '안성삼죽',
        gennum: '65022',
        well: '충적',
        pyogo: '109.44',
        area: '한강권역',
        addr: '경기도 안성시 삼죽면 용월리 429-2',
        LON: '127.37875556',
        LAT: '37.07741667',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '17',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-17.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0-17',
        cseInMat: '',
        strHgt: '',
        strDia: '9.0-13.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [127.37875556, 37.07741667] }
    },
    {
      type: 'Feature',
      id: 495,
      properties: {
        DATE: '2024-10-26',
        jiguname: '안양비산',
        gennum: '87237',
        well: '암반',
        pyogo: '49.73',
        area: '한강권역',
        addr: '경기도 안양시동안구 비산동  산96-6',
        LON: '126.94791389',
        LAT: '37.41112778',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: '한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-11.8',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.94791389, 37.41112778] }
    },
    {
      type: 'Feature',
      id: 496,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양구가오작_심부2',
        gennum: '769795',
        well: '암반',
        pyogo: '321',
        area: '한강권역',
        addr: '강원특별자치도 양구군 국토정중앙면 가오작리 5-1 광치자연휴양림',
        LON: '128.07171944',
        LAT: '38.14556667',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '83',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-7.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-83.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '77.0-83.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.07171944, 38.14556667] }
    },
    {
      type: 'Feature',
      id: 497,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양구가오작_천부',
        gennum: '769796',
        well: '충적',
        pyogo: '321',
        area: '한강권역',
        addr: '강원특별자치도 양구군 국토정중앙면 가오작리 5-1 광치자연휴양림',
        LON: '128.07171944',
        LAT: '38.14556667',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '12',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-12.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '7.5-12.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.07171944, 38.14556667] }
    },
    {
      type: 'Feature',
      id: 498,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양구방산',
        gennum: '65023',
        well: '암반',
        pyogo: '250.72',
        area: '한강권역',
        addr: '강원특별자치도 양구군 방산면 현리 50-4 방산초등학교',
        LON: '127.94488056',
        LAT: '38.20918889',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-11.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '46.0-50.0',
        strMat: ' 54.0-60.0'
      },
      geometry: { type: 'Point', coordinates: [127.94488056, 38.20918889] }
    },
    {
      type: 'Feature',
      id: 499,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양구양구',
        gennum: '622078',
        well: '암반',
        pyogo: '193.77',
        area: '한강권역',
        addr: '강원특별자치도 양구군 양구읍 죽곡리 산3-8 강원특별자치도 양구군 양구읍 죽곡리 산3-8번지',
        LON: '127.99666667',
        LAT: '38.12527778',
        insdate: '2014-11-21',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '100',
        cseExHgt: '300',
        cseExDia: '200',
        cseExMat: '0.0-13.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-100.0',
        strHgt: '150',
        strDia: 'P. V. C',
        strMat: '30-40'
      },
      geometry: { type: 'Point', coordinates: [127.99666667, 38.12527778] }
    },
    {
      type: 'Feature',
      id: 500,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양구용하_심부2',
        gennum: '769797',
        well: '암반',
        pyogo: '225',
        area: '한강권역',
        addr: '강원특별자치도 양구군 국토정중앙면 용하리 397-5 용하초등학교',
        LON: '128.03620000',
        LAT: '38.11003889',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-21.7',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.0362, 38.11003889] }
    },
    {
      type: 'Feature',
      id: 501,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양구용하_천부',
        gennum: '769798',
        well: '충적',
        pyogo: '225',
        area: '한강권역',
        addr: '강원특별자치도 양구군 국토정중앙면 용하리 397-5 용하초등학교',
        LON: '128.03620000',
        LAT: '38.11003889',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '12',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-12.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '6.0-12.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.0362, 38.11003889] }
    },
    {
      type: 'Feature',
      id: 502,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양구해안',
        gennum: '622079',
        well: '암반',
        pyogo: '417.39',
        area: '한강권역',
        addr: '강원특별자치도 양구군 해안면 후리 25 강원특별자치도 양구군 해안면 후리 25번지',
        LON: '128.15472222',
        LAT: '38.28416667',
        insdate: '2014-11-21',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜대흥개발',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-12.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '30-40',
        strMat: ' 52-56'
      },
      geometry: { type: 'Point', coordinates: [128.15472222, 38.28416667] }
    },
    {
      type: 'Feature',
      id: 503,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양산용당_심부2',
        gennum: '769799',
        well: '암반',
        pyogo: '284.31',
        area: '낙동강권역',
        addr: '경상남도 양산시 용당동  산66 대운산자연휴양림',
        LON: '129.21107500',
        LAT: '35.41481944',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-9.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.211075, 35.41481944] }
    },
    {
      type: 'Feature',
      id: 504,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양산용당_천부',
        gennum: '769800',
        well: '충적',
        pyogo: '284.31',
        area: '낙동강권역',
        addr: '경상남도 양산시 용당동  산66 대운산자연휴양림',
        LON: '129.21107500',
        LAT: '35.41481944',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '12',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-12.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '6.0-12.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.211075, 35.41481944] }
    },
    {
      type: 'Feature',
      id: 505,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양산웅상',
        gennum: '87264',
        well: '암반',
        pyogo: '114.6',
        area: '낙동강권역',
        addr: '경상남도 양산시 덕계동  811-51',
        LON: '129.15717500',
        LAT: '35.38568889',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: '한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-12.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '24.0-28.0'
      },
      geometry: { type: 'Point', coordinates: [129.157175, 35.38568889] }
    },
    {
      type: 'Feature',
      id: 506,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양산웅상',
        gennum: '87265',
        well: '충적',
        pyogo: '114.62',
        area: '낙동강권역',
        addr: '경상남도 양산시 덕계동  811-51',
        LON: '129.15717500',
        LAT: '35.38568889',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: '한국수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-6.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6.0-10.0'
      },
      geometry: { type: 'Point', coordinates: [129.157175, 35.38568889] }
    },
    {
      type: 'Feature',
      id: 507,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양양손양',
        gennum: '65024',
        well: '암반',
        pyogo: '3.47',
        area: '한강권역',
        addr: '강원특별자치도 양양군 손양면 상운리 96-3 양수펌프장',
        LON: '128.69097500',
        LAT: '38.04308056',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '68',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-20.0',
        cseInHgt: '250',
        cseInDia: '백관',
        cseInMat: '0.0-68.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '44.0-48.0'
      },
      geometry: { type: 'Point', coordinates: [128.690975, 38.04308056] }
    },
    {
      type: 'Feature',
      id: 508,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양양손양',
        gennum: '65025',
        well: '충적',
        pyogo: '4.12',
        area: '한강권역',
        addr: '강원특별자치도 양양군 손양면 상운리 96-3 양수펌프장',
        LON: '128.69097500',
        LAT: '38.04308056',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '11',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-11.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0-11',
        strHgt: '',
        strDia: '',
        strMat: '7.0-11.0'
      },
      geometry: { type: 'Point', coordinates: [128.690975, 38.04308056] }
    },
    {
      type: 'Feature',
      id: 509,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양양월리_심부2',
        gennum: '769801',
        well: '암반',
        pyogo: '92',
        area: '한강권역',
        addr: '강원특별자치도 양양군 양양읍 월리 506-3 송이밸리자연휴양림',
        LON: '128.62162500',
        LAT: '38.05449167',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '82',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-8.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-82.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '76.0-82.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.621625, 38.05449167] }
    },
    {
      type: 'Feature',
      id: 510,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양양월리_천부',
        gennum: '769802',
        well: '충적',
        pyogo: '92',
        area: '한강권역',
        addr: '강원특별자치도 양양군 양양읍 월리 506-3 송이밸리자연휴양림',
        LON: '128.62162500',
        LAT: '38.05449167',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.621625, 38.05449167] }
    },
    {
      type: 'Feature',
      id: 511,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양주광적',
        gennum: '82000',
        well: '암반',
        pyogo: '92.686',
        area: '한강권역',
        addr: '경기도 양주시 광적면 광석리 20-1',
        LON: '126.97934722',
        LAT: '37.82384167',
        insdate: '2003-05-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-10.0',
        cseInHgt: '250',
        cseInDia: 'STAINLES STEEL',
        cseInMat: '0-70.0',
        strHgt: '200',
        strDia: 'P.V.C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.97934722, 37.82384167] }
    },
    {
      type: 'Feature',
      id: 512,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양주광적',
        gennum: '82001',
        well: '충적',
        pyogo: '92.674',
        area: '한강권역',
        addr: '경기도 양주시 광적면 광석리 20-1',
        LON: '126.97934722',
        LAT: '37.82384167',
        insdate: '2003-05-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-6.0',
        cseInHgt: '200',
        cseInDia: 'STAINLES STEEL',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6.0-10.0'
      },
      geometry: { type: 'Point', coordinates: [126.97934722, 37.82384167] }
    },
    {
      type: 'Feature',
      id: 513,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양평개군',
        gennum: '65063',
        well: '암반',
        pyogo: '40.352',
        area: '한강권역',
        addr: '경기도 양평군 개군면 석장리 190번지 석장초등학교내',
        LON: '127.51500000',
        LAT: '37.45551944',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-12.2',
        cseInHgt: '250',
        cseInDia: '백관',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.515, 37.45551944] }
    },
    {
      type: 'Feature',
      id: 514,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양평개군',
        gennum: '65064',
        well: '충적',
        pyogo: '40.34',
        area: '한강권역',
        addr: '경기도 양평군 개군면 석장리 190번지 석장초등학교내',
        LON: '127.51500000',
        LAT: '37.45551944',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '9.5',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-5.5',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-9.5',
        strHgt: '',
        strDia: '',
        strMat: '5.5-9.5'
      },
      geometry: { type: 'Point', coordinates: [127.515, 37.45551944] }
    },
    {
      type: 'Feature',
      id: 515,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양평단월',
        gennum: '717023',
        well: '암반',
        pyogo: '103.76',
        area: '한강권역',
        addr: '경기도 양평군 단월면 보룡리 76-1',
        LON: '127.67801944',
        LAT: '37.53561111',
        insdate: '2017-11-30',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '벽산엔지니어링㈜',
        gulDiaTop: '130',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-11.5',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-130',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '48-60',
        strMat: ' 76-92'
      },
      geometry: { type: 'Point', coordinates: [127.67801944, 37.53561111] }
    },
    {
      type: 'Feature',
      id: 516,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양평단월',
        gennum: '717024',
        well: '충적',
        pyogo: '103.78',
        area: '한강권역',
        addr: '경기도 양평군 단월면 보룡리 76-1',
        LON: '127.67801944',
        LAT: '37.53561111',
        insdate: '2017-11-30',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '벽산엔지니어링㈜',
        gulDiaTop: '10.5',
        gulDiaBtm: '400',
        cseExHgt: '300',
        cseExDia: '0.0-6.0',
        cseExMat: '300',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-10.5',
        cseInMat: '200',
        strHgt: 'STAINLESS STEEL',
        strDia: '6.5-10.5',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [127.67801944, 37.53561111] }
    },
    {
      type: 'Feature',
      id: 517,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양평신복_심부2',
        gennum: '769803',
        well: '암반',
        pyogo: '288.66',
        area: '한강권역',
        addr: '경기도 양평군 옥천면 신복리 산201-2 국립중미산자연휴양림',
        LON: '127.45376389',
        LAT: '37.58925278',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '81',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-81.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '72.0-81.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.45376389, 37.58925278] }
    },
    {
      type: 'Feature',
      id: 518,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양평신복_천부',
        gennum: '769804',
        well: '충적',
        pyogo: '288.969',
        area: '한강권역',
        addr: '경기도 양평군 옥천면 신복리 산201-2 국립중미산자연휴양림',
        LON: '127.45376389',
        LAT: '37.58925278',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '12',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.2',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-12.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '8.0-12.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.45376389, 37.58925278] }
    },
    {
      type: 'Feature',
      id: 519,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양평신점_심부2',
        gennum: '737630',
        well: '암반',
        pyogo: '199.25',
        area: '한강권역',
        addr: '경기도 양평군 용문면 신점리 507-9',
        LON: '127.58259167',
        LAT: '37.54672778',
        insdate: '2011-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-8.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '68.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.58259167, 37.54672778] }
    },
    {
      type: 'Feature',
      id: 520,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양평신점_천부',
        gennum: '737629',
        well: '충적',
        pyogo: '199.25',
        area: '한강권역',
        addr: '경기도 양평군 용문면 신점리 507-9',
        LON: '127.58259167',
        LAT: '37.54672778',
        insdate: '2011-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '6.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.58259167, 37.54672778] }
    },
    {
      type: 'Feature',
      id: 521,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양평양동',
        gennum: '65065',
        well: '암반',
        pyogo: '106.6',
        area: '한강권역',
        addr: '경기도 양평군 양동면 쌍학리 739-21번지 양동레포츠 공원내',
        LON: '127.75375556',
        LAT: '37.40644167',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '69',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-22.5',
        cseInHgt: '250',
        cseInDia: '백관',
        cseInMat: '0.0-69.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '49.0-53.0'
      },
      geometry: { type: 'Point', coordinates: [127.75375556, 37.40644167] }
    },
    {
      type: 'Feature',
      id: 522,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양평양동',
        gennum: '65066',
        well: '충적',
        pyogo: '106.54',
        area: '한강권역',
        addr: '경기도 양평군 양동면 쌍학리 739-21번지 양동레포츠 공원내',
        LON: '127.75375556',
        LAT: '37.40644167',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '8',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-4.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-8',
        strHgt: '',
        strDia: '',
        strMat: '4.0-8.0'
      },
      geometry: { type: 'Point', coordinates: [127.75375556, 37.40644167] }
    },
    {
      type: 'Feature',
      id: 523,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양평용문',
        gennum: '9876',
        well: '암반',
        pyogo: '46.44',
        area: '한강권역',
        addr: '경기도 양평군 용문면 삼성리 941',
        LON: '127.56373056',
        LAT: '37.46791389',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-5.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [127.56373056, 37.46791389] }
    },
    {
      type: 'Feature',
      id: 524,
      properties: {
        DATE: '2024-10-26',
        jiguname: '양평정배',
        gennum: '769850',
        well: '암반',
        pyogo: '123.97',
        area: '한강권역',
        addr: '경기도 양평군 서종면 정배리 588',
        LON: '127.42250000',
        LAT: '37.60194444',
        insdate: '2020-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '155',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0-11',
        cseExMat: '200',
        cseInHgt: '아연도강관',
        cseInDia: '0-100',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '32-36',
        strMat: ' 44-48'
      },
      geometry: { type: 'Point', coordinates: [127.4225, 37.60194444] }
    },
    {
      type: 'Feature',
      id: 525,
      properties: {
        DATE: '2024-10-26',
        jiguname: '여수둔전_심부2',
        gennum: '737632',
        well: '암반',
        pyogo: '56.172',
        area: '영섬권역',
        addr: '전라남도 여수시 돌산읍 둔전리 607',
        LON: '127.77195833',
        LAT: '34.65291667',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-18.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.77195833, 34.65291667] }
    },
    {
      type: 'Feature',
      id: 526,
      properties: {
        DATE: '2024-10-26',
        jiguname: '여수둔전_천부',
        gennum: '737631',
        well: '충적',
        pyogo: '56.205',
        area: '영섬권역',
        addr: '전라남도 여수시 돌산읍 둔전리 607',
        LON: '127.77195833',
        LAT: '34.65291667',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-7.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.77195833, 34.65291667] }
    },
    {
      type: 'Feature',
      id: 527,
      properties: {
        DATE: '2024-10-26',
        jiguname: '여수소라',
        gennum: '82035',
        well: '암반',
        pyogo: '48.08',
        area: '영섬권역',
        addr: '전라남도 여수시 소라면 복산리 15-2',
        LON: '127.61864167',
        LAT: '34.77690000',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국지질조사탐사업협동조합',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0-5.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0-70',
        cseInMat: '200',
        strHgt: 'P.V.C',
        strDia: '24.0-28.0',
        strMat: ' 36.0-40.0'
      },
      geometry: { type: 'Point', coordinates: [127.61864167, 34.7769] }
    },
    {
      type: 'Feature',
      id: 528,
      properties: {
        DATE: '2024-10-26',
        jiguname: '여수오림_심부2',
        gennum: '771487',
        well: '암반',
        pyogo: '88.37',
        area: '영섬권역',
        addr: '전라남도 여수시 오림동  10 진남체육공원',
        LON: '127.72801111',
        LAT: '34.76266944',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-4.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.72801111, 34.76266944] }
    },
    {
      type: 'Feature',
      id: 529,
      properties: {
        DATE: '2024-10-26',
        jiguname: '여수오림_천부',
        gennum: '771488',
        well: '충적',
        pyogo: '88.37',
        area: '영섬권역',
        addr: '전라남도 여수시 오림동  10 진남체육공원',
        LON: '127.72801111',
        LAT: '34.76266944',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '15',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-15',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '7.5-15.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.72801111, 34.76266944] }
    },
    {
      type: 'Feature',
      id: 530,
      properties: {
        DATE: '2024-10-26',
        jiguname: '여수평여',
        gennum: '612030',
        well: '암반',
        pyogo: '28.85',
        area: '영섬권역',
        addr: '전라남도 여수시 평여동  986-1',
        LON: '127.67149167',
        LAT: '34.81080833',
        insdate: '2012-11-13',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜지오그린21',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-9.0',
        cseExMat: '200',
        cseInHgt: 'STS304',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '12.0-18.0',
        strMat: ' 24.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [127.67149167, 34.81080833] }
    },
    {
      type: 'Feature',
      id: 531,
      properties: {
        DATE: '2024-10-26',
        jiguname: '여주금사',
        gennum: '87238',
        well: '암반',
        pyogo: '91.91',
        area: '한강권역',
        addr: '경기도 여주시 금사면 장흥리 128',
        LON: '127.48835000',
        LAT: '37.39092778',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국지질조사탐사업협동조합',
        gulDiaTop: '90',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-6.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-90.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '40.0-44.0',
        strMat: ' 48.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [127.48835, 37.39092778] }
    },
    {
      type: 'Feature',
      id: 532,
      properties: {
        DATE: '2024-10-26',
        jiguname: '여주점동',
        gennum: '87239',
        well: '암반',
        pyogo: '61.8',
        area: '한강권역',
        addr: '경기도 여주시 점동면 청안리 47-3',
        LON: '127.66051667',
        LAT: '37.19923889',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: '한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-18.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '36.0-40.0'
      },
      geometry: { type: 'Point', coordinates: [127.66051667, 37.19923889] }
    },
    {
      type: 'Feature',
      id: 533,
      properties: {
        DATE: '2024-10-26',
        jiguname: '여주점동',
        gennum: '87240',
        well: '충적',
        pyogo: '61.74',
        area: '한강권역',
        addr: '경기도 여주시 점동면 청안리 47-3',
        LON: '127.66051667',
        LAT: '37.19923889',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: '한국수자원공사',
        gulDiaBtm: '15',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-11.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-15',
        strHgt: '',
        strDia: '',
        strMat: '11.0-15.0'
      },
      geometry: { type: 'Point', coordinates: [127.66051667, 37.19923889] }
    },
    {
      type: 'Feature',
      id: 534,
      properties: {
        DATE: '2024-10-26',
        jiguname: '연천군남',
        gennum: '622080',
        well: '암반',
        pyogo: '50.3',
        area: '한강권역',
        addr: '경기도 연천군 군남면 선곡리 627-2 경기도 연천군 군남면 선곡리 627-2번지',
        LON: '127.02083333',
        LAT: '38.10305556',
        insdate: '2014-11-22',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '100',
        cseExHgt: '300',
        cseExDia: '200',
        cseExMat: '0.0-15.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-100.0',
        strHgt: '150',
        strDia: 'P. V. C',
        strMat: '36-40'
      },
      geometry: { type: 'Point', coordinates: [127.02083333, 38.10305556] }
    },
    {
      type: 'Feature',
      id: 535,
      properties: {
        DATE: '2024-10-26',
        jiguname: '연천왕징',
        gennum: '637541',
        well: '암반',
        pyogo: '69.422',
        area: '한강권역',
        addr: '경기도 연천군 왕징면 노동리 113-3 1',
        LON: '126.99120000',
        LAT: '38.07440278',
        insdate: '2015-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜대동공영',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-16.5',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '24.0-32.0',
        strMat: ' 48.0-56.0'
      },
      geometry: { type: 'Point', coordinates: [126.9912, 38.07440278] }
    },
    {
      type: 'Feature',
      id: 536,
      properties: {
        DATE: '2024-10-26',
        jiguname: '연천유촌_심부2',
        gennum: '737636',
        well: '암반',
        pyogo: '51.442',
        area: '한강권역',
        addr: '경기도 연천군 미산면 유촌리 383',
        LON: '126.99376944',
        LAT: '38.04701667',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-17.3',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.99376944, 38.04701667] }
    },
    {
      type: 'Feature',
      id: 537,
      properties: {
        DATE: '2024-10-26',
        jiguname: '연천유촌_천부',
        gennum: '737635',
        well: '충적',
        pyogo: '51.438',
        area: '한강권역',
        addr: '경기도 연천군 미산면 유촌리 383',
        LON: '126.99376944',
        LAT: '38.04701667',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-5.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '5.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.99376944, 38.04701667] }
    },
    {
      type: 'Feature',
      id: 538,
      properties: {
        DATE: '2024-10-26',
        jiguname: '연천진상_심부2',
        gennum: '737638',
        well: '암반',
        pyogo: '32.832',
        area: '한강권역',
        addr: '경기도 연천군 군남면 진상리 704-1',
        LON: '127.01611944',
        LAT: '38.06653333',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-13.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.01611944, 38.06653333] }
    },
    {
      type: 'Feature',
      id: 539,
      properties: {
        DATE: '2024-10-26',
        jiguname: '연천진상_천부',
        gennum: '737637',
        well: '충적',
        pyogo: '32.824',
        area: '한강권역',
        addr: '경기도 연천군 군남면 진상리 704-1',
        LON: '127.01611944',
        LAT: '38.06653333',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-5.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '5.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.01611944, 38.06653333] }
    },
    {
      type: 'Feature',
      id: 540,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영광불갑',
        gennum: '82036',
        well: '암반',
        pyogo: '27.462',
        area: '영섬권역',
        addr: '전라남도 영광군 불갑면 안맹리 240-12',
        LON: '126.50696111',
        LAT: '35.20906389',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-7.5',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'P.V.C',
        strMat: '40.0-44.0'
      },
      geometry: { type: 'Point', coordinates: [126.50696111, 35.20906389] }
    },
    {
      type: 'Feature',
      id: 541,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영덕달산',
        gennum: '87259',
        well: '암반',
        pyogo: '46.42',
        area: '낙동강권역',
        addr: '경상북도 영덕군 달산면 대지리 921',
        LON: '129.29795556',
        LAT: '36.41315000',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국지질조사탐사업협동조합',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-15.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [129.29795556, 36.41315] }
    },
    {
      type: 'Feature',
      id: 542,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영덕달산',
        gennum: '87260',
        well: '충적',
        pyogo: '46.43',
        area: '낙동강권역',
        addr: '경상북도 영덕군 달산면 대지리 921',
        LON: '129.29795556',
        LAT: '36.41315000',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국지질조사탐사업협동조합',
        gulDiaTop: '15',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-11.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0-15',
        cseInMat: '',
        strHgt: '',
        strDia: '11.0-15.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [129.29795556, 36.41315] }
    },
    {
      type: 'Feature',
      id: 543,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영덕도곡_심부2',
        gennum: '737642',
        well: '암반',
        pyogo: '46',
        area: '낙동강권역',
        addr: '경상북도 영덕군 축산면 도곡리 산55-6',
        LON: '129.40490278',
        LAT: '36.52093333',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-11.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.40490278, 36.52093333] }
    },
    {
      type: 'Feature',
      id: 544,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영덕도곡_천부',
        gennum: '737641',
        well: '충적',
        pyogo: '46',
        area: '낙동강권역',
        addr: '경상북도 영덕군 축산면 도곡리 산55-6',
        LON: '129.40490278',
        LAT: '36.52093333',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '12',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-12.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '6.0-12.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.40490278, 36.52093333] }
    },
    {
      type: 'Feature',
      id: 545,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영덕도천_심부2',
        gennum: '737644',
        well: '암반',
        pyogo: '23.583',
        area: '낙동강권역',
        addr: '경상북도 영덕군 남정면 도천리 124-12',
        LON: '129.35256667',
        LAT: '36.30959444',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-10.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '72.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.35256667, 36.30959444] }
    },
    {
      type: 'Feature',
      id: 546,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영덕도천_천부',
        gennum: '737643',
        well: '충적',
        pyogo: '23.314',
        area: '낙동강권역',
        addr: '경상북도 영덕군 남정면 도천리 124-12',
        LON: '129.35256667',
        LAT: '36.30959444',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '14',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-14.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '10.0-14.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.35256667, 36.30959444] }
    },
    {
      type: 'Feature',
      id: 547,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영덕병곡',
        gennum: '405436',
        well: '암반',
        pyogo: '4.9',
        area: '낙동강권역',
        addr: '경상북도 영덕군 병곡면 영리 287-13',
        LON: '129.40246389',
        LAT: '36.59094722',
        insdate: '2009-12-14',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜해천 E.T.S',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-18.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '52-56',
        strMat: ' 64-68'
      },
      geometry: { type: 'Point', coordinates: [129.40246389, 36.59094722] }
    },
    {
      type: 'Feature',
      id: 548,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영덕신안_심부2',
        gennum: '771489',
        well: '암반',
        pyogo: '70.61',
        area: '낙동강권역',
        addr: '경상북도 영덕군 지품면 신안리 185-1 지품면사무소',
        LON: '129.28171389',
        LAT: '36.44815833',
        insdate: '2017-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-3.8',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.28171389, 36.44815833] }
    },
    {
      type: 'Feature',
      id: 549,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영덕신안_천부',
        gennum: '771490',
        well: '충적',
        pyogo: '70.604',
        area: '낙동강권역',
        addr: '경상북도 영덕군 지품면 신안리 185-1 지품면사무소',
        LON: '129.28171389',
        LAT: '36.44815833',
        insdate: '2017-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '15',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-15',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '11.0-15.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.28171389, 36.44815833] }
    },
    {
      type: 'Feature',
      id: 550,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영덕영해',
        gennum: '65026',
        well: '암반',
        pyogo: '10.69',
        area: '낙동강권역',
        addr: '경상북도 영덕군 영해면 벌영리 563-2 구상수도취수장',
        LON: '129.39540833',
        LAT: '36.54241944',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '72',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-47.9',
        cseInHgt: '250',
        cseInDia: '백관',
        cseInMat: '0.0-72.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '48.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [129.39540833, 36.54241944] }
    },
    {
      type: 'Feature',
      id: 551,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영덕화개_심부2',
        gennum: '771491',
        well: '암반',
        pyogo: '14.285',
        area: '낙동강권역',
        addr: '경상북도 영덕군 영덕읍 화개리 257-6 영덕군민운동장',
        LON: '129.36382500',
        LAT: '36.42001944',
        insdate: '2017-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-18.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '73.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.363825, 36.42001944] }
    },
    {
      type: 'Feature',
      id: 552,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영덕화개_천부',
        gennum: '771492',
        well: '충적',
        pyogo: '14.287',
        area: '낙동강권역',
        addr: '경상북도 영덕군 영덕읍 화개리 257-6 영덕군민운동장',
        LON: '129.36382500',
        LAT: '36.42001944',
        insdate: '2017-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '6.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.363825, 36.42001944] }
    },
    {
      type: 'Feature',
      id: 553,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영동남성_심부2',
        gennum: '737646',
        well: '암반',
        pyogo: '161.795',
        area: '금강권역',
        addr: '충청북도 영동군 황간면 남성리 164-1',
        LON: '127.91265833',
        LAT: '36.22660000',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '84',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-6.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-84.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '78.0-84.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.91265833, 36.2266] }
    },
    {
      type: 'Feature',
      id: 554,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영동남성_천부',
        gennum: '737645',
        well: '충적',
        pyogo: '161.798',
        area: '금강권역',
        addr: '충청북도 영동군 황간면 남성리 164-1',
        LON: '127.91265833',
        LAT: '36.22660000',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-5.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '5.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.91265833, 36.2266] }
    },
    {
      type: 'Feature',
      id: 555,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영동노근_심부2',
        gennum: '771493',
        well: '암반',
        pyogo: '173.19',
        area: '금강권역',
        addr: '충청북도 영동군 황간면 노근리 697-1 노근리평화공원',
        LON: '127.88166111',
        LAT: '36.21041111',
        insdate: '2018-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '82',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-6.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-82',
        cseInMat: '100',
        strHgt: 'PVC(HI-VP)',
        strDia: '76.0-82.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.88166111, 36.21041111] }
    },
    {
      type: 'Feature',
      id: 556,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영동노근_천부',
        gennum: '771494',
        well: '충적',
        pyogo: '173.185',
        area: '금강권역',
        addr: '충청북도 영동군 황간면 노근리 697-1 노근리평화공원',
        LON: '127.88166111',
        LAT: '36.21041111',
        insdate: '2018-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC(HI-VP)',
        strDia: '5.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.88166111, 36.21041111] }
    },
    {
      type: 'Feature',
      id: 557,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영동매천_심부2',
        gennum: '771495',
        well: '암반',
        pyogo: '158',
        area: '금강권역',
        addr: '충청북도 영동군 영동읍 매천리 110-1 영동군민운동장',
        LON: '127.78342500',
        LAT: '36.16614722',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-2.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.783425, 36.16614722] }
    },
    {
      type: 'Feature',
      id: 558,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영동매천_천부',
        gennum: '771496',
        well: '충적',
        pyogo: '158',
        area: '금강권역',
        addr: '충청북도 영동군 영동읍 매천리 110-1 영동군민운동장',
        LON: '127.78342500',
        LAT: '36.16614722',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10.5',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10.5',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '1.5-10.5',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.783425, 36.16614722] }
    },
    {
      type: 'Feature',
      id: 559,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영동서산_심부2',
        gennum: '771497',
        well: '암반',
        pyogo: '139.263',
        area: '금강권역',
        addr: '충청북도 영동군 학산면 서산리 902 학산중고등학교',
        LON: '127.68260278',
        LAT: '36.09941944',
        insdate: '2018-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-19.6',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC(HI-VP)',
        strDia: '75.5-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.68260278, 36.09941944] }
    },
    {
      type: 'Feature',
      id: 560,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영동서산_천부',
        gennum: '771498',
        well: '충적',
        pyogo: '139.252',
        area: '금강권역',
        addr: '충청북도 영동군 학산면 서산리 902 학산중고등학교',
        LON: '127.68260278',
        LAT: '36.09941944',
        insdate: '2018-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC(HI-VP)',
        strDia: '5.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.68260278, 36.09941944] }
    },
    {
      type: 'Feature',
      id: 561,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영동심천',
        gennum: '65067',
        well: '암반',
        pyogo: '113.83',
        area: '금강권역',
        addr: '충청북도 영동군 심천면 금정리 204-1 취수펌프장',
        LON: '127.70701944',
        LAT: '36.18869444',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-17.5',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.70701944, 36.18869444] }
    },
    {
      type: 'Feature',
      id: 562,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영동양강',
        gennum: '65027',
        well: '암반',
        pyogo: '151.05',
        area: '금강권역',
        addr: '충청북도 영동군 양강면 괴목리 201-1',
        LON: '127.75084167',
        LAT: '36.15283611',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '71',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-10.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0.0-71.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '48.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [127.75084167, 36.15283611] }
    },
    {
      type: 'Feature',
      id: 563,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영동양강',
        gennum: '65028',
        well: '충적',
        pyogo: '151.05',
        area: '금강권역',
        addr: '충청북도 영동군 양강면 괴목리 201-1',
        LON: '127.75084167',
        LAT: '36.15283611',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-10.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6.0-10.0'
      },
      geometry: { type: 'Point', coordinates: [127.75084167, 36.15283611] }
    },
    {
      type: 'Feature',
      id: 564,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영동용산',
        gennum: '718273',
        well: '암반',
        pyogo: '141.21',
        area: '금강권역',
        addr: '충청북도 영동군 용산면 가곡리 203',
        LON: '127.77648889',
        LAT: '36.26746111',
        insdate: '2018-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '(주)어스이엔지',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-15.0',
        cseExMat: '200',
        cseInHgt: 'Stainless Steel',
        cseInDia: '0.0-100',
        cseInMat: '150',
        strHgt: 'P.V.C(VG1)',
        strDia: '68-100',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [127.77648889, 36.26746111] }
    },
    {
      type: 'Feature',
      id: 565,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영동추풍령',
        gennum: '612007',
        well: '암반',
        pyogo: '353.08',
        area: '금강권역',
        addr: '충청북도 영동군 추풍령면 신안리 112-5',
        LON: '128.01675833',
        LAT: '36.24752778',
        insdate: '2012-11-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜지오그린21',
        gulDiaTop: '102',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0-17',
        cseExMat: '200',
        cseInHgt: 'STS304',
        cseInDia: '0.0-102.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '18-30',
        strMat: ' 34-42'
      },
      geometry: { type: 'Point', coordinates: [128.01675833, 36.24752778] }
    },
    {
      type: 'Feature',
      id: 566,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영암금계_심부2',
        gennum: '737648',
        well: '암반',
        pyogo: '18.834',
        area: '영섬권역',
        addr: '전라남도 영암군 학산면 금계리 471-1',
        LON: '126.59718333',
        LAT: '34.71972778',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-33.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.59718333, 34.71972778] }
    },
    {
      type: 'Feature',
      id: 567,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영암금계_천부',
        gennum: '737647',
        well: '충적',
        pyogo: '18.852',
        area: '영섬권역',
        addr: '전라남도 영암군 학산면 금계리 471-1',
        LON: '126.59718333',
        LAT: '34.71972778',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-5.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '5.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.59718333, 34.71972778] }
    },
    {
      type: 'Feature',
      id: 568,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영암덕화_심부2',
        gennum: '737650',
        well: '암반',
        pyogo: '6.98',
        area: '영섬권역',
        addr: '전라남도 영암군 도포면 덕화리 100-2',
        LON: '126.63626667',
        LAT: '34.83530000',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-6.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.63626667, 34.8353] }
    },
    {
      type: 'Feature',
      id: 569,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영암덕화_천부',
        gennum: '737649',
        well: '충적',
        pyogo: '6.98',
        area: '영섬권역',
        addr: '전라남도 영암군 도포면 덕화리 100-2',
        LON: '126.63626667',
        LAT: '34.83530000',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-3.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.63626667, 34.8353] }
    },
    {
      type: 'Feature',
      id: 570,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영암미암',
        gennum: '615519',
        well: '암반',
        pyogo: '23.53',
        area: '영섬권역',
        addr: '전라남도 영암군 미암면 미암리 994-2',
        LON: '126.58296944',
        LAT: '34.69708611',
        insdate: '2013-11-18',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜지오그린21',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0-35',
        cseExMat: '200',
        cseInHgt: 'STS304',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '40-44',
        strMat: ' 48-52'
      },
      geometry: { type: 'Point', coordinates: [126.58296944, 34.69708611] }
    },
    {
      type: 'Feature',
      id: 571,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영암미암',
        gennum: '615520',
        well: '충적',
        pyogo: '23.55',
        area: '영섬권역',
        addr: '전라남도 영암군 미암면 미암리 994-2',
        LON: '126.58296944',
        LAT: '34.69708611',
        insdate: '2013-11-18',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜지오그린21',
        gulDiaTop: '25',
        gulDiaBtm: '400',
        cseExHgt: '300',
        cseExDia: '0.0-3.0',
        cseExMat: '300',
        cseInHgt: '백관',
        cseInDia: '0.0-25.0',
        cseInMat: '200',
        strHgt: 'STS304',
        strDia: '24.0-25.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [126.58296944, 34.69708611] }
    },
    {
      type: 'Feature',
      id: 572,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영암영암',
        gennum: '514306',
        well: '암반',
        pyogo: '8.38',
        area: '영섬권역',
        addr: '전라남도 영암군 영암읍 대신리 704-23',
        LON: '126.70798611',
        LAT: '34.81016944',
        insdate: '2010-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '유성삼정개발㈜',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-27.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '32.2-36.2',
        strMat: ' 44.2-48.2'
      },
      geometry: { type: 'Point', coordinates: [126.70798611, 34.81016944] }
    },
    {
      type: 'Feature',
      id: 573,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영암영암',
        gennum: '514307',
        well: '충적',
        pyogo: '8.38',
        area: '영섬권역',
        addr: '전라남도 영암군 영암읍 대신리 704-23',
        LON: '126.70798611',
        LAT: '34.81016944',
        insdate: '2010-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '유성삼정개발㈜',
        gulDiaTop: '18',
        gulDiaBtm: '400',
        cseExHgt: '300',
        cseExDia: '0.0-6.0',
        cseExMat: '300',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-18.0',
        cseInMat: '200',
        strHgt: 'STAINLESS STEEL',
        strDia: '6.0-12.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [126.70798611, 34.81016944] }
    },
    {
      type: 'Feature',
      id: 574,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영암옥야_심부2',
        gennum: '737652',
        well: '암반',
        pyogo: '10',
        area: '영섬권역',
        addr: '전라남도 영암군 시종면 옥야리 537-2',
        LON: '126.58723333',
        LAT: '34.89583056',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '85',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-30.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-85.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '78.0-85.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.58723333, 34.89583056] }
    },
    {
      type: 'Feature',
      id: 575,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영암옥야_천부',
        gennum: '737651',
        well: '충적',
        pyogo: '10',
        area: '영섬권역',
        addr: '전라남도 영암군 시종면 옥야리 537-2',
        LON: '126.58723333',
        LAT: '34.89583056',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.58723333, 34.89583056] }
    },
    {
      type: 'Feature',
      id: 576,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영암용앙_심부2',
        gennum: '769805',
        well: '암반',
        pyogo: '17.338',
        area: '영섬권역',
        addr: '전라남도 영암군 삼호읍 용앙리 261 삼호고등학교',
        LON: '126.46910000',
        LAT: '34.74989167',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '90',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-9.6',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-90.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '84.0-90.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.4691, 34.74989167] }
    },
    {
      type: 'Feature',
      id: 577,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영암용앙_천부',
        gennum: '769806',
        well: '충적',
        pyogo: '17.425',
        area: '영섬권역',
        addr: '전라남도 영암군 삼호읍 용앙리 261 삼호고등학교',
        LON: '126.46910000',
        LAT: '34.74989167',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '15',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-9.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-15.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '9.0-15.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.4691, 34.74989167] }
    },
    {
      type: 'Feature',
      id: 578,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영암장천_심부2',
        gennum: '737654',
        well: '암반',
        pyogo: '18.14',
        area: '영섬권역',
        addr: '전라남도 영암군 서호면 장천리 573',
        LON: '126.58167778',
        LAT: '34.75537500',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-6.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.58167778, 34.755375] }
    },
    {
      type: 'Feature',
      id: 579,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영암장천_천부',
        gennum: '737653',
        well: '충적',
        pyogo: '18.14',
        area: '영섬권역',
        addr: '전라남도 영암군 서호면 장천리 573',
        LON: '126.58167778',
        LAT: '34.75537500',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-3.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.58167778, 34.755375] }
    },
    {
      type: 'Feature',
      id: 580,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영암학산',
        gennum: '615521',
        well: '암반',
        pyogo: '33.23',
        area: '영섬권역',
        addr: '전라남도 영암군 학산면 금계리 1098-3',
        LON: '126.58505000',
        LAT: '34.72788056',
        insdate: '2013-11-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜지오그린21',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-6.0',
        cseExMat: '200',
        cseInHgt: 'STS304',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '40-44',
        strMat: ' 48-52'
      },
      geometry: { type: 'Point', coordinates: [126.58505, 34.72788056] }
    },
    {
      type: 'Feature',
      id: 581,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영암회문_심부1',
        gennum: '737655',
        well: '충적',
        pyogo: '23.248',
        area: '영섬권역',
        addr: '전라남도 영암군 영암읍 회문리 417-2',
        LON: '126.68540000',
        LAT: '34.79331389',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '30',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-5.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '5.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.6854, 34.79331389] }
    },
    {
      type: 'Feature',
      id: 582,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영암회문_심부2',
        gennum: '737656',
        well: '암반',
        pyogo: '23.235',
        area: '영섬권역',
        addr: '전라남도 영암군 영암읍 회문리 417-2',
        LON: '126.68540000',
        LAT: '34.79331389',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '70',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-66.9',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-70.5',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '67.5-70.5',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.6854, 34.79331389] }
    },
    {
      type: 'Feature',
      id: 583,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영양대천',
        gennum: '753916',
        well: '암반',
        pyogo: '215.47',
        area: '낙동강권역',
        addr: '경상북도 영양군 입암면 대천리 194-3',
        LON: '129.04218611',
        LAT: '36.62168056',
        insdate: '2019-11-26',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '250',
        cseExHgt: '200',
        cseExDia: '0-13',
        cseExMat: '250',
        cseInHgt: '강관',
        cseInDia: '0-100',
        cseInMat: '200',
        strHgt: 'PVC',
        strDia: '13-100',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [129.04218611, 36.62168056] }
    },
    {
      type: 'Feature',
      id: 584,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영양대천',
        gennum: '753915',
        well: '충적',
        pyogo: '215.462',
        area: '낙동강권역',
        addr: '경상북도 영양군 입암면 대천리 194-3',
        LON: '129.04218611',
        LAT: '36.62168056',
        insdate: '2019-11-26',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '11',
        gulDiaBtm: '250',
        cseExHgt: '200',
        cseExDia: '0-3.5',
        cseExMat: '250',
        cseInHgt: '강관',
        cseInDia: '0-11',
        cseInMat: '200',
        strHgt: 'PVC',
        strDia: '3.5-11',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [129.04218611, 36.62168056] }
    },
    {
      type: 'Feature',
      id: 585,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영양무창',
        gennum: '753917',
        well: '암반',
        pyogo: '299.336',
        area: '낙동강권역',
        addr: '경상북도 영양군 영양읍 무창리 584',
        LON: '129.20086111',
        LAT: '36.63140278',
        insdate: '2019-11-26',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '250',
        cseExHgt: '200',
        cseExDia: '0-5.5',
        cseExMat: '250',
        cseInHgt: '강관',
        cseInDia: '0-100',
        cseInMat: '200',
        strHgt: 'PVC',
        strDia: '5.5-100',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [129.20086111, 36.63140278] }
    },
    {
      type: 'Feature',
      id: 586,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영양석보',
        gennum: '771360',
        well: '암반',
        pyogo: '262.8',
        area: '낙동강권역',
        addr: '경상북도 영양군 석보면 요원리 282-1',
        LON: '129.18130278',
        LAT: '36.56815556',
        insdate: '2021-12-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '98.8',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-9.5',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0.0-98.8',
        cseInMat: '150',
        strHgt: 'P.V.C(VG1)',
        strDia: '56.0-64.0',
        strMat: ' 68.0-80.0'
      },
      geometry: { type: 'Point', coordinates: [129.18130278, 36.56815556] }
    },
    {
      type: 'Feature',
      id: 587,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영양섬촌',
        gennum: '771359',
        well: '암반',
        pyogo: '286',
        area: '낙동강권역',
        addr: '경상북도 영양군 일월면 섬촌리 1-2',
        LON: '129.15385833',
        LAT: '36.72589444',
        insdate: '2021-12-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '98.5',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-8.4',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0.0-98.5',
        cseInMat: '150',
        strHgt: 'P.V.C(VG1)',
        strDia: '56.0-64.0',
        strMat: ' 68.0-80.0'
      },
      geometry: { type: 'Point', coordinates: [129.15385833, 36.72589444] }
    },
    {
      type: 'Feature',
      id: 588,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영양수하_심부2',
        gennum: '737658',
        well: '암반',
        pyogo: '307',
        area: '낙동강권역',
        addr: '경상북도 영양군 수비면 수하리 388-1',
        LON: '129.25898611',
        LAT: '36.82817500',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '82',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-6.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-82.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '73.0-82.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.25898611, 36.828175] }
    },
    {
      type: 'Feature',
      id: 589,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영양수하_천부',
        gennum: '737657',
        well: '충적',
        pyogo: '307',
        area: '낙동강권역',
        addr: '경상북도 영양군 수비면 수하리 388-1',
        LON: '129.25898611',
        LAT: '36.82817500',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '11',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-11.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '5.0-11.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.25898611, 36.828175] }
    },
    {
      type: 'Feature',
      id: 590,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영양영양',
        gennum: '622081',
        well: '암반',
        pyogo: '222.91',
        area: '낙동강권역',
        addr: '경상북도 영양군 영양읍 현리 21-1 경상북도 영양군 영양읍 현리 21-1번지',
        LON: '129.12750000',
        LAT: '36.65750000',
        insdate: '2014-11-26',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜대흥개발',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-9.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '36-40',
        strMat: ' 52-56'
      },
      geometry: { type: 'Point', coordinates: [129.1275, 36.6575] }
    },
    {
      type: 'Feature',
      id: 591,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영양일월',
        gennum: '771358',
        well: '암반',
        pyogo: '384.7',
        area: '낙동강권역',
        addr: '경상북도 영양군 일월면 용화리 542-3',
        LON: '129.15346111',
        LAT: '36.79220556',
        insdate: '2021-12-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-5.2',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P.V.C(VG1)',
        strDia: '56.0-64.0',
        strMat: ' 68.0-80.0'
      },
      geometry: { type: 'Point', coordinates: [129.15346111, 36.79220556] }
    },
    {
      type: 'Feature',
      id: 592,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영양입암',
        gennum: '65029',
        well: '암반',
        pyogo: '194.39',
        area: '낙동강권역',
        addr: '경상북도 영양군 입암면 신구리 94-2 면사무소',
        LON: '129.08283333',
        LAT: '36.60832778',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '72',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-8.5',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0.0-72.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '48.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [129.08283333, 36.60832778] }
    },
    {
      type: 'Feature',
      id: 593,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영월광전_심부2',
        gennum: '737660',
        well: '암반',
        pyogo: '240.641',
        area: '한강권역',
        addr: '강원특별자치도 영월군 한반도면 광전리 271-2',
        LON: '128.35630000',
        LAT: '37.24326667',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-6.2',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.3563, 37.24326667] }
    },
    {
      type: 'Feature',
      id: 594,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영월광전_천부',
        gennum: '737659',
        well: '충적',
        pyogo: '240.641',
        area: '한강권역',
        addr: '강원특별자치도 영월군 한반도면 광전리 271-2',
        LON: '128.35630000',
        LAT: '37.24326667',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '15',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-15.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '8.0-15.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.3563, 37.24326667] }
    },
    {
      type: 'Feature',
      id: 595,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영월무릉도원',
        gennum: '753922',
        well: '암반',
        pyogo: '272.289',
        area: '한강권역',
        addr: '강원특별자치도 영월군 무릉도원면 무릉리 821-3',
        LON: '128.26747500',
        LAT: '37.29025278',
        insdate: '2019-11-26',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '33',
        gulDiaBtm: '250',
        cseExHgt: '200',
        cseExDia: '0-15',
        cseExMat: '250',
        cseInHgt: '강관',
        cseInDia: '0-33',
        cseInMat: '200',
        strHgt: 'PVC',
        strDia: '15-33',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [128.267475, 37.29025278] }
    },
    {
      type: 'Feature',
      id: 596,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영월북면',
        gennum: '753923',
        well: '암반',
        pyogo: '259.184',
        area: '한강권역',
        addr: '강원특별자치도 영월군 북면 연덕리 505',
        LON: '128.42576667',
        LAT: '37.28373056',
        insdate: '2019-11-26',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '250',
        cseExHgt: '200',
        cseExDia: '0-7',
        cseExMat: '250',
        cseInHgt: '강관',
        cseInDia: '0-100',
        cseInMat: '200',
        strHgt: 'PVC',
        strDia: '7-100',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [128.42576667, 37.28373056] }
    },
    {
      type: 'Feature',
      id: 597,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영월상동',
        gennum: '9868',
        well: '암반',
        pyogo: '425.32',
        area: '한강권역',
        addr: '강원특별자치도 영월군 상동읍 내덕리 235',
        LON: '128.78111389',
        LAT: '37.11885278',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '77',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-3.7',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-77.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [128.78111389, 37.11885278] }
    },
    {
      type: 'Feature',
      id: 598,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영월영월',
        gennum: '73505',
        well: '암반',
        pyogo: '215.147',
        area: '한강권역',
        addr: '강원특별자치도 영월군 영월읍 영흥리 85',
        LON: '128.47705833',
        LAT: '37.18711389',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-14',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'PVC',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [128.47705833, 37.18711389] }
    },
    {
      type: 'Feature',
      id: 599,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영주문정',
        gennum: '65068',
        well: '암반',
        pyogo: '134.12',
        area: '낙동강권역',
        addr: '경상북도 영주시 문정동  706',
        LON: '128.61701944',
        LAT: '36.79849722',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '69',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-13.7',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-69.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '49.0-53.0'
      },
      geometry: { type: 'Point', coordinates: [128.61701944, 36.79849722] }
    },
    {
      type: 'Feature',
      id: 600,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영주문정',
        gennum: '65069',
        well: '충적',
        pyogo: '134.1',
        area: '낙동강권역',
        addr: '경상북도 영주시 문정동  706',
        LON: '128.61701944',
        LAT: '36.79849722',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '13',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-9.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-13',
        strHgt: '',
        strDia: '',
        strMat: '9.0-13.0'
      },
      geometry: { type: 'Point', coordinates: [128.61701944, 36.79849722] }
    },
    {
      type: 'Feature',
      id: 601,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영주부석',
        gennum: '778272',
        well: '암반',
        pyogo: '213.2',
        area: '낙동강권역',
        addr: '경상북도 영주시 부석면 감곡리 185-1',
        LON: '128.64881389',
        LAT: '36.91576389',
        insdate: '2022-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-12.0',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P.V.C',
        strDia: '40.0-48.0',
        strMat: ' 50.0-58.0'
      },
      geometry: { type: 'Point', coordinates: [128.64881389, 36.91576389] }
    },
    {
      type: 'Feature',
      id: 602,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영주아지',
        gennum: '753919',
        well: '암반',
        pyogo: '185.556',
        area: '낙동강권역',
        addr: '경상북도 영주시 아지동  147-4',
        LON: '128.60062778',
        LAT: '36.86323333',
        insdate: '2019-11-26',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '250',
        cseExHgt: '200',
        cseExDia: '0-4',
        cseExMat: '250',
        cseInHgt: '강관',
        cseInDia: '0-31',
        cseInMat: '200',
        strHgt: 'PVC',
        strDia: '4-31',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [128.60062778, 36.86323333] }
    },
    {
      type: 'Feature',
      id: 603,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영주아지',
        gennum: '753918',
        well: '충적',
        pyogo: '185.575',
        area: '낙동강권역',
        addr: '경상북도 영주시 아지동  147-4',
        LON: '128.60062778',
        LAT: '36.86323333',
        insdate: '2019-11-26',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '31',
        gulDiaBtm: '250',
        cseExHgt: '200',
        cseExDia: '0-40',
        cseExMat: '250',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '200',
        strHgt: 'PVC',
        strDia: '40-100',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [128.60062778, 36.86323333] }
    },
    {
      type: 'Feature',
      id: 604,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영주장수',
        gennum: '793608',
        well: '암반',
        pyogo: '161.27',
        area: '',
        addr: '경상북도 영주시 장수면 파지리 227',
        LON: '128.55247222',
        LAT: '36.80255833',
        insdate: '2023-12-01',
        manager: '',
        builder: '',
        gulDep: '102',
        gulDiaTop: '300',
        gulDiaBtm: '200',
        cseExHgt: '0.0-12.0',
        cseExDia: '200',
        cseExMat: 'STEEL PIPE',
        cseInHgt: '0.0-102.0',
        cseInDia: '150',
        cseInMat: 'P. V. C',
        strHgt: '26.0-36.0m',
        strDia: ' 50.0-70.0',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [128.55247222, 36.80255833] }
    },
    {
      type: 'Feature',
      id: 605,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영주장수',
        gennum: '793609',
        well: '충적',
        pyogo: '161.17',
        area: '',
        addr: '경상북도 영주시 장수면 파지리 227',
        LON: '128.55246667',
        LAT: '36.80257778',
        insdate: '2023-12-01',
        manager: '',
        builder: '',
        gulDep: '21',
        gulDiaTop: '400',
        gulDiaBtm: '300',
        cseExHgt: '0.0-3.0',
        cseExDia: '250',
        cseExMat: 'STEEL PIPE',
        cseInHgt: '0.0-21.0',
        cseInDia: '200',
        cseInMat: 'P. V. C',
        strHgt: '9.0-13.0',
        strDia: '200',
        strMat: 'P. V. C'
      },
      geometry: { type: 'Point', coordinates: [128.55246667, 36.80257778] }
    },
    {
      type: 'Feature',
      id: 606,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영주풍기',
        gennum: '603275',
        well: '암반',
        pyogo: '324.89',
        area: '낙동강권역',
        addr: '경상북도 영주시 풍기읍 전구리 718-1',
        LON: '128.47805556',
        LAT: '36.87361111',
        insdate: '2011-12-28',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜한국건업엔지니어링',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-16.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '36-40',
        strMat: ' 52-56'
      },
      geometry: { type: 'Point', coordinates: [128.47805556, 36.87361111] }
    },
    {
      type: 'Feature',
      id: 607,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영천금노',
        gennum: '84032',
        well: '암반',
        pyogo: '72.891',
        area: '낙동강권역',
        addr: '경상북도 영천시 금노동  398-1',
        LON: '128.93019444',
        LAT: '35.95472778',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-5.4',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [128.93019444, 35.95472778] }
    },
    {
      type: 'Feature',
      id: 608,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영천임고_심부2',
        gennum: '771499',
        well: '암반',
        pyogo: '122.01',
        area: '낙동강권역',
        addr: '경상북도 영천시 임고면 평천리 719-3 평천초등학교',
        LON: '128.98637778',
        LAT: '36.04275278',
        insdate: '2009-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '85',
        gulDiaBtm: '165',
        cseExHgt: '165',
        cseExDia: '0.0-6.5',
        cseExMat: '165',
        cseInHgt: '강관',
        cseInDia: '0-85',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '77.5-85.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.98637778, 36.04275278] }
    },
    {
      type: 'Feature',
      id: 609,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영천임고_천부',
        gennum: '771500',
        well: '충적',
        pyogo: '122',
        area: '낙동강권역',
        addr: '경상북도 영천시 임고면 평천리 719-3 평천초등학교',
        LON: '128.98637778',
        LAT: '36.04275278',
        insdate: '2009-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '8',
        gulDiaBtm: '165',
        cseExHgt: '165',
        cseExDia: '0.0-0.0',
        cseExMat: '165',
        cseInHgt: '강관',
        cseInDia: '0-8',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '3.5-8.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.98637778, 36.04275278] }
    },
    {
      type: 'Feature',
      id: 610,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영천자양_심부2',
        gennum: '771501',
        well: '암반',
        pyogo: '169.66',
        area: '낙동강권역',
        addr: '경상북도 영천시 자양면 성곡리 550 자양면사무소',
        LON: '129.01847222',
        LAT: '36.07815556',
        insdate: '2008-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '70',
        gulDiaBtm: '',
        cseExHgt: '',
        cseExDia: '0.0-9.0',
        cseExMat: '',
        cseInHgt: '강관',
        cseInDia: '0-70',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '58.0-70.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.01847222, 36.07815556] }
    },
    {
      type: 'Feature',
      id: 611,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영천자양_천부',
        gennum: '771502',
        well: '충적',
        pyogo: '169.815',
        area: '낙동강권역',
        addr: '경상북도 영천시 자양면 성곡리 550 자양면사무소',
        LON: '129.01847222',
        LAT: '36.07815556',
        insdate: '2008-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '15',
        gulDiaBtm: '',
        cseExHgt: '',
        cseExDia: '0.0-0.0',
        cseExMat: '',
        cseInHgt: '강관',
        cseInDia: '0-15',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '7.5-15.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.01847222, 36.07815556] }
    },
    {
      type: 'Feature',
      id: 612,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영천청통',
        gennum: '637531',
        well: '암반',
        pyogo: '121.65',
        area: '낙동강권역',
        addr: '경상북도 영천시 청통면 치일리 286-7 1',
        LON: '128.80026944',
        LAT: '35.99191944',
        insdate: '2015-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜대동공영',
        gulDiaTop: '103',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-6.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-103.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '20.0-28.0',
        strMat: ' 52.0-60.0'
      },
      geometry: { type: 'Point', coordinates: [128.80026944, 35.99191944] }
    },
    {
      type: 'Feature',
      id: 613,
      properties: {
        DATE: '2024-10-26',
        jiguname: '영천화북',
        gennum: '82040',
        well: '암반',
        pyogo: '155.594',
        area: '낙동강권역',
        addr: '경상북도 영천시 화북면 자천리 895',
        LON: '128.92111667',
        LAT: '36.10823056',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국지질조사탐사업협동조합',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0-10',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0-70',
        cseInMat: '200',
        strHgt: 'P.V.C',
        strDia: '50.0-54.0',
        strMat: ' 58.0-62.0'
      },
      geometry: { type: 'Point', coordinates: [128.92111667, 36.10823056] }
    },
    {
      type: 'Feature',
      id: 614,
      properties: {
        DATE: '2024-10-26',
        jiguname: '예산고도_심부2',
        gennum: '769807',
        well: '암반',
        pyogo: '0',
        area: '금강권역',
        addr: '충청남도 예산군 봉산면 고도리 19 봉산보건지소',
        LON: '126.68318333',
        LAT: '36.74819722',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-12.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-100.0',
        cseInMat: '125',
        strHgt: '',
        strDia: '50.0-100.0',
        strMat: '125'
      },
      geometry: { type: 'Point', coordinates: [126.68318333, 36.74819722] }
    },
    {
      type: 'Feature',
      id: 615,
      properties: {
        DATE: '2024-10-26',
        jiguname: '예산고도_천부',
        gennum: '769808',
        well: '충적',
        pyogo: '0',
        area: '금강권역',
        addr: '충청남도 예산군 봉산면 고도리 19 봉산보건지소',
        LON: '126.68318333',
        LAT: '36.74819722',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '12',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-12.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '6.0-12.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.68318333, 36.74819722] }
    },
    {
      type: 'Feature',
      id: 616,
      properties: {
        DATE: '2024-10-26',
        jiguname: '예산대술',
        gennum: '793599',
        well: '암반',
        pyogo: '218.65',
        area: '',
        addr: '충청남도 예산군 대술면 송석리 134-1',
        LON: '126.96279285',
        LAT: '36.69126378',
        insdate: '2023-12-01',
        manager: '',
        builder: '',
        gulDep: '100',
        gulDiaTop: '300',
        gulDiaBtm: '200',
        cseExHgt: '0.0-17.0',
        cseExDia: '200',
        cseExMat: 'STEEL PIPE',
        cseInHgt: '0.0-100.0',
        cseInDia: '150',
        cseInMat: 'P. V. C',
        strHgt: '70.0-100.0',
        strDia: '150',
        strMat: 'P. V. C'
      },
      geometry: { type: 'Point', coordinates: [126.96279285, 36.69126378] }
    },
    {
      type: 'Feature',
      id: 617,
      properties: {
        DATE: '2024-10-26',
        jiguname: '예산대술',
        gennum: '793600',
        well: '충적',
        pyogo: '218.65',
        area: '',
        addr: '충청남도 예산군 대술면 송석리 산 48',
        LON: '126.96280700',
        LAT: '36.69124918',
        insdate: '2023-12-01',
        manager: '',
        builder: '',
        gulDep: '18',
        gulDiaTop: '400',
        gulDiaBtm: '300',
        cseExHgt: '0.0-3.0',
        cseExDia: '300',
        cseExMat: 'STEEL PIPE',
        cseInHgt: '0.0-20.0',
        cseInDia: '200',
        cseInMat: 'P. V. C',
        strHgt: '19.0-20.0',
        strDia: '200',
        strMat: 'P. V. C'
      },
      geometry: { type: 'Point', coordinates: [126.962807, 36.69124918] }
    },
    {
      type: 'Feature',
      id: 618,
      properties: {
        DATE: '2024-10-26',
        jiguname: '예산덕산',
        gennum: '9916',
        well: '암반',
        pyogo: '51.05',
        area: '금강권역',
        addr: '충청남도 예산군 덕산면 읍내리 428-15',
        LON: '126.66394444',
        LAT: '36.70527778',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '65',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-28.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-65.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [126.66394444, 36.70527778] }
    },
    {
      type: 'Feature',
      id: 619,
      properties: {
        DATE: '2024-10-26',
        jiguname: '예산덕산',
        gennum: '9917',
        well: '충적',
        pyogo: '51.05',
        area: '금강권역',
        addr: '충청남도 예산군 덕산면 읍내리 428-15',
        LON: '126.66394444',
        LAT: '36.70527778',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '13.8',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-13.8',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0-13.8',
        cseInMat: '',
        strHgt: '',
        strDia: '9.8-13.8',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [126.66394444, 36.70527778] }
    },
    {
      type: 'Feature',
      id: 620,
      properties: {
        DATE: '2024-10-26',
        jiguname: '예산신암',
        gennum: '639096',
        well: '암반',
        pyogo: '15.07',
        area: '금강권역',
        addr: '충청남도 예산군 신암면 오산리 390-25',
        LON: '126.80891111',
        LAT: '36.72741944',
        insdate: '2016-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '148.4',
        cseExHgt: '300',
        cseExDia: '200',
        cseExMat: '0.0-42.0m',
        cseInHgt: '200',
        cseInDia: 'Stainless Steel',
        cseInMat: '0.0-148.4',
        strHgt: '150',
        strDia: 'PVC(VG1)',
        strMat: '46-50'
      },
      geometry: { type: 'Point', coordinates: [126.80891111, 36.72741944] }
    },
    {
      type: 'Feature',
      id: 621,
      properties: {
        DATE: '2024-10-26',
        jiguname: '예산신암',
        gennum: '639097',
        well: '충적',
        pyogo: '15.06',
        area: '금강권역',
        addr: '충청남도 예산군 신암면 오산리 390-25',
        LON: '126.80891111',
        LAT: '36.72741944',
        insdate: '2016-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '22.6',
        cseExHgt: '400',
        cseExDia: '200',
        cseExMat: '0.0-6.0m',
        cseInHgt: '300',
        cseInDia: 'SPPW Steel',
        cseInMat: '0.0-22.6',
        strHgt: '200',
        strDia: 'Stainless Steel',
        strMat: '17.0-22.6'
      },
      geometry: { type: 'Point', coordinates: [126.80891111, 36.72741944] }
    },
    {
      type: 'Feature',
      id: 622,
      properties: {
        DATE: '2024-10-26',
        jiguname: '예산예산',
        gennum: '11762',
        well: '암반',
        pyogo: '14.31',
        area: '금강권역',
        addr: '충청남도 예산군 예산읍 주교리 420 예산정수장내',
        LON: '126.82573889',
        LAT: '36.67686389',
        insdate: '1996-09-02',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '68',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-11.9',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL PIPE',
        cseInMat: '0.0-68.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '48.0-51.0'
      },
      geometry: { type: 'Point', coordinates: [126.82573889, 36.67686389] }
    },
    {
      type: 'Feature',
      id: 623,
      properties: {
        DATE: '2024-10-26',
        jiguname: '예산예산',
        gennum: '11763',
        well: '충적',
        pyogo: '14.31',
        area: '금강권역',
        addr: '충청남도 예산군 예산읍 주교리 420 예산정수장내',
        LON: '126.82573889',
        LAT: '36.67686389',
        insdate: '1996-09-02',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '8.8',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-3.8',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL PIPE',
        cseInMat: '0-8.8',
        strHgt: '',
        strDia: '',
        strMat: '3.8-7.8'
      },
      geometry: { type: 'Point', coordinates: [126.82573889, 36.67686389] }
    },
    {
      type: 'Feature',
      id: 624,
      properties: {
        DATE: '2024-10-26',
        jiguname: '예산오가',
        gennum: '73518',
        well: '암반',
        pyogo: '13.3',
        area: '금강권역',
        addr: '충청남도 예산군 오가면 역탑리 66-4',
        LON: '126.80671667',
        LAT: '36.68424722',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-20.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.80671667, 36.68424722] }
    },
    {
      type: 'Feature',
      id: 625,
      properties: {
        DATE: '2024-10-26',
        jiguname: '예산오가',
        gennum: '73519',
        well: '충적',
        pyogo: '13.16',
        area: '금강권역',
        addr: '충청남도 예산군 오가면 역탑리 66-4',
        LON: '126.80671667',
        LAT: '36.68424722',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-10.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6.0-10.0'
      },
      geometry: { type: 'Point', coordinates: [126.80671667, 36.68424722] }
    },
    {
      type: 'Feature',
      id: 626,
      properties: {
        DATE: '2024-10-26',
        jiguname: '예산황계_심부2',
        gennum: '769860',
        well: '암반',
        pyogo: '42.18',
        area: '금강권역',
        addr: '충청남도 예산군 신양면 황계리 207',
        LON: '126.83127222',
        LAT: '36.59992500',
        insdate: '2012-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '75',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-10.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-75.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '63.0-75.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.83127222, 36.599925] }
    },
    {
      type: 'Feature',
      id: 627,
      properties: {
        DATE: '2024-10-26',
        jiguname: '예산황계_천부',
        gennum: '770549',
        well: '충적',
        pyogo: '42.18',
        area: '금강권역',
        addr: '충청남도 예산군 신양면 황계리 207',
        LON: '126.83127222',
        LAT: '36.59992500',
        insdate: '2012-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '5.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.83127222, 36.599925] }
    },
    {
      type: 'Feature',
      id: 628,
      properties: {
        DATE: '2024-10-26',
        jiguname: '예천가리_심부2',
        gennum: '771503',
        well: '암반',
        pyogo: '100.5',
        area: '낙동강권역',
        addr: '경상북도 예천군 유천면 가리 746 유천초등학교',
        LON: '128.37923889',
        LAT: '36.63684444',
        insdate: '2012-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '82',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-11.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-82',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '74.0-82.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.37923889, 36.63684444] }
    },
    {
      type: 'Feature',
      id: 629,
      properties: {
        DATE: '2024-10-26',
        jiguname: '예천가리_천부',
        gennum: '771504',
        well: '충적',
        pyogo: '100.5',
        area: '낙동강권역',
        addr: '경상북도 예천군 유천면 가리 746 유천초등학교',
        LON: '128.37923889',
        LAT: '36.63684444',
        insdate: '2012-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '15',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-15',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '7.0-15.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.37923889, 36.63684444] }
    },
    {
      type: 'Feature',
      id: 630,
      properties: {
        DATE: '2024-10-26',
        jiguname: '예천동본',
        gennum: '778273',
        well: '암반',
        pyogo: '102.8',
        area: '낙동강권역',
        addr: '경상북도 예천군 예천읍 동본리 200',
        LON: '128.46600278',
        LAT: '36.65981944',
        insdate: '2022-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-12.0',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P.V.C',
        strDia: '40.0-48.0',
        strMat: ' 50.0-58.0'
      },
      geometry: { type: 'Point', coordinates: [128.46600278, 36.65981944] }
    },
    {
      type: 'Feature',
      id: 631,
      properties: {
        DATE: '2024-10-26',
        jiguname: '예천예천',
        gennum: '8937',
        well: '암반',
        pyogo: '87.17',
        area: '낙동강권역',
        addr: '경상북도 예천군 예천읍 지내리 256-2 국도변',
        LON: '128.42403889',
        LAT: '36.64075833',
        insdate: '1995-12-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-30.0',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '30.0-38.0',
        strMat: ' 58.0-66.0'
      },
      geometry: { type: 'Point', coordinates: [128.42403889, 36.64075833] }
    },
    {
      type: 'Feature',
      id: 632,
      properties: {
        DATE: '2024-10-26',
        jiguname: '예천예천',
        gennum: '8936',
        well: '충적',
        pyogo: '87.19',
        area: '낙동강권역',
        addr: '경상북도 예천군 예천읍 지내리 256-2 국도변',
        LON: '128.42403889',
        LAT: '36.64075833',
        insdate: '1995-12-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '12',
        gulDiaBtm: '350',
        cseExHgt: '350',
        cseExDia: '0.0-12.0',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0-12',
        cseInMat: '',
        strHgt: '',
        strDia: '6.0-12.0',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [128.42403889, 36.64075833] }
    },
    {
      type: 'Feature',
      id: 633,
      properties: {
        DATE: '2024-10-26',
        jiguname: '예천용궁',
        gennum: '778274',
        well: '암반',
        pyogo: '80.3',
        area: '낙동강권역',
        addr: '경상북도 예천군 용궁면 무지리 산16-1',
        LON: '128.31176667',
        LAT: '36.61191667',
        insdate: '2022-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-21.0',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P.V.C',
        strDia: '40.0-48.0',
        strMat: ' 50.0-58.0'
      },
      geometry: { type: 'Point', coordinates: [128.31176667, 36.61191667] }
    },
    {
      type: 'Feature',
      id: 634,
      properties: {
        DATE: '2024-10-26',
        jiguname: '예천풍양',
        gennum: '612019',
        well: '암반',
        pyogo: '57.61',
        area: '낙동강권역',
        addr: '경상북도 예천군 풍양면 공덕리 387-5',
        LON: '128.30565556',
        LAT: '36.50492778',
        insdate: '2012-11-13',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜지오그린21',
        gulDiaTop: '40',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-25.0',
        cseExMat: '200',
        cseInHgt: 'STS304',
        cseInDia: '0.0-40.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '28.0-40.0',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [128.30565556, 36.50492778] }
    },
    {
      type: 'Feature',
      id: 635,
      properties: {
        DATE: '2024-10-26',
        jiguname: '오산궐동',
        gennum: '87241',
        well: '암반',
        pyogo: '58.01',
        area: '한강권역',
        addr: '경기도 오산시 궐동  130-5 궐동배수지 내',
        LON: '127.06045278',
        LAT: '37.16245000',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: '한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-5.8',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.06045278, 37.16245] }
    },
    {
      type: 'Feature',
      id: 636,
      properties: {
        DATE: '2024-10-26',
        jiguname: '옥천군북',
        gennum: '65070',
        well: '암반',
        pyogo: '94.58',
        area: '금강권역',
        addr: '충청북도 옥천군 군북면 이백리 346-42 군북면사무소',
        LON: '127.53423333',
        LAT: '36.32990278',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-10.5',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.53423333, 36.32990278] }
    },
    {
      type: 'Feature',
      id: 637,
      properties: {
        DATE: '2024-10-26',
        jiguname: '옥천이원',
        gennum: '65071',
        well: '암반',
        pyogo: '111.56',
        area: '금강권역',
        addr: '충청북도 옥천군 이원면 강청리 42-1번지 이원면사무소',
        LON: '127.61923611',
        LAT: '36.24635833',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-25.5',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.61923611, 36.24635833] }
    },
    {
      type: 'Feature',
      id: 638,
      properties: {
        DATE: '2024-10-26',
        jiguname: '옥천증약_심부2',
        gennum: '771505',
        well: '암반',
        pyogo: '105',
        area: '금강권역',
        addr: '충청북도 옥천군 군북면 증약리 641 증약초등학교',
        LON: '127.52387778',
        LAT: '36.33468056',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-3.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.52387778, 36.33468056] }
    },
    {
      type: 'Feature',
      id: 639,
      properties: {
        DATE: '2024-10-26',
        jiguname: '옥천증약_천부',
        gennum: '771506',
        well: '충적',
        pyogo: '105',
        area: '금강권역',
        addr: '충청북도 옥천군 군북면 증약리 641 증약초등학교',
        LON: '127.52387778',
        LAT: '36.33468056',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.52387778, 36.33468056] }
    },
    {
      type: 'Feature',
      id: 640,
      properties: {
        DATE: '2024-10-26',
        jiguname: '옥천청산',
        gennum: '637527',
        well: '암반',
        pyogo: '110.309',
        area: '금강권역',
        addr: '충청북도 옥천군 청산면 지전리 25 1',
        LON: '127.79328056',
        LAT: '36.34388333',
        insdate: '2015-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜대동공영',
        gulDiaTop: '80',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-17.5',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-80.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '17.5-73.5',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [127.79328056, 36.34388333] }
    },
    {
      type: 'Feature',
      id: 641,
      properties: {
        DATE: '2024-10-26',
        jiguname: '옥천청산',
        gennum: '637528',
        well: '충적',
        pyogo: '110.311',
        area: '금강권역',
        addr: '충청북도 옥천군 청산면 지전리 25 1',
        LON: '127.79328056',
        LAT: '36.34388333',
        insdate: '2015-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜대동공영',
        gulDiaTop: '11',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-5.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-11.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '5.0-11.0',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [127.79328056, 36.34388333] }
    },
    {
      type: 'Feature',
      id: 642,
      properties: {
        DATE: '2024-10-26',
        jiguname: '옥천청성',
        gennum: '11754',
        well: '암반',
        pyogo: '139.98',
        area: '금강권역',
        addr: '충청북도 옥천군 청성면 묘금리 19-1 청성묘금분교',
        LON: '127.73162222',
        LAT: '36.27671944',
        insdate: '1996-11-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '41',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-5.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0.0-41.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '21.0-33.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [127.73162222, 36.27671944] }
    },
    {
      type: 'Feature',
      id: 643,
      properties: {
        DATE: '2024-10-26',
        jiguname: '옥천청성',
        gennum: '11755',
        well: '충적',
        pyogo: '139.98',
        area: '금강권역',
        addr: '충청북도 옥천군 청성면 묘금리 19-1 청성묘금분교',
        LON: '127.73162222',
        LAT: '36.27671944',
        insdate: '1996-11-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '7.9',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-2.9',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0-7.9',
        cseInMat: '',
        strHgt: '',
        strDia: '2.9-6.9',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [127.73162222, 36.27671944] }
    },
    {
      type: 'Feature',
      id: 644,
      properties: {
        DATE: '2024-10-26',
        jiguname: '완도가용_심부2',
        gennum: '737664',
        well: '암반',
        pyogo: '4.347',
        area: '영섬권역',
        addr: '전라남도 완도군 완도읍 가용리 172',
        LON: '126.73393889',
        LAT: '34.31934444',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-6.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.73393889, 34.31934444] }
    },
    {
      type: 'Feature',
      id: 645,
      properties: {
        DATE: '2024-10-26',
        jiguname: '완도가용_천부',
        gennum: '737663',
        well: '충적',
        pyogo: '4.276',
        area: '영섬권역',
        addr: '전라남도 완도군 완도읍 가용리 172',
        LON: '126.73393889',
        LAT: '34.31934444',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '21',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-4.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-21.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '4.5-21.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.73393889, 34.31934444] }
    },
    {
      type: 'Feature',
      id: 646,
      properties: {
        DATE: '2024-10-26',
        jiguname: '완도군외',
        gennum: '612004',
        well: '암반',
        pyogo: '146.87',
        area: '영섬권역',
        addr: '전라남도 완도군 군외면 삼두리 산1-4',
        LON: '126.66824722',
        LAT: '34.34744167',
        insdate: '2012-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '100',
        cseExHgt: '300',
        cseExDia: '200',
        cseExMat: '',
        cseInHgt: '',
        cseInDia: '',
        cseInMat: '0-100',
        strHgt: '',
        strDia: '',
        strMat: ''
      },
      geometry: { type: 'Point', coordinates: [126.66824722, 34.34744167] }
    },
    {
      type: 'Feature',
      id: 647,
      properties: {
        DATE: '2024-10-26',
        jiguname: '완도덕암_심부2',
        gennum: '769809',
        well: '암반',
        pyogo: '25.013',
        area: '영섬권역',
        addr: '전라남도 완도군 고금면 덕암리 775 고금고등학교',
        LON: '126.80419722',
        LAT: '34.40000556',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-34.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.80419722, 34.40000556] }
    },
    {
      type: 'Feature',
      id: 648,
      properties: {
        DATE: '2024-10-26',
        jiguname: '완도덕암_천부',
        gennum: '769810',
        well: '충적',
        pyogo: '24.993',
        area: '영섬권역',
        addr: '전라남도 완도군 고금면 덕암리 775 고금고등학교',
        LON: '126.80419722',
        LAT: '34.40000556',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '13.5',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-9.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-13.5',
        cseInMat: '100',
        strHgt: '',
        strDia: '9.0-13.5',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.80419722, 34.40000556] }
    },
    {
      type: 'Feature',
      id: 649,
      properties: {
        DATE: '2024-10-26',
        jiguname: '완주고산',
        gennum: '65596',
        well: '암반',
        pyogo: '73.62',
        area: '금강권역',
        addr: '전북특별자치도 완주군 고산면 소향리 880-34',
        LON: '127.26049722',
        LAT: '35.98855000',
        insdate: '2000-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-12.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.26049722, 35.98855] }
    },
    {
      type: 'Feature',
      id: 650,
      properties: {
        DATE: '2024-10-26',
        jiguname: '완주삼례',
        gennum: '87255',
        well: '암반',
        pyogo: '15.6',
        area: '금강권역',
        addr: '전북특별자치도 완주군 삼례읍 후정리 103-3',
        LON: '127.06816111',
        LAT: '35.90406111',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: '한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-18.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '42.0-46.0'
      },
      geometry: { type: 'Point', coordinates: [127.06816111, 35.90406111] }
    },
    {
      type: 'Feature',
      id: 651,
      properties: {
        DATE: '2024-10-26',
        jiguname: '완주삼례',
        gennum: '87256',
        well: '충적',
        pyogo: '15.57',
        area: '금강권역',
        addr: '전북특별자치도 완주군 삼례읍 후정리 103-3',
        LON: '127.06816111',
        LAT: '35.90406111',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: '한국수자원공사',
        gulDiaBtm: '15',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-11.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-15',
        strHgt: '',
        strDia: '',
        strMat: '11.0-15.0'
      },
      geometry: { type: 'Point', coordinates: [127.06816111, 35.90406111] }
    },
    {
      type: 'Feature',
      id: 652,
      properties: {
        DATE: '2024-10-26',
        jiguname: '완주오산_심부2',
        gennum: '769811',
        well: '암반',
        pyogo: '103.86',
        area: '금강권역',
        addr: '전북특별자치도 완주군 고산면 오산리 산43-1 고산자연휴양림',
        LON: '127.23376667',
        LAT: '35.96435556',
        insdate: '2012-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '82',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-12.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-82.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '52.0-82.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.23376667, 35.96435556] }
    },
    {
      type: 'Feature',
      id: 653,
      properties: {
        DATE: '2024-10-26',
        jiguname: '완주오산_천부',
        gennum: '769812',
        well: '충적',
        pyogo: '103.86',
        area: '금강권역',
        addr: '전북특별자치도 완주군 고산면 오산리 산43-1 고산자연휴양림',
        LON: '127.23376667',
        LAT: '35.96435556',
        insdate: '2012-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.23376667, 35.96435556] }
    },
    {
      type: 'Feature',
      id: 654,
      properties: {
        DATE: '2024-10-26',
        jiguname: '완주용진',
        gennum: '65030',
        well: '암반',
        pyogo: '28.79',
        area: '금강권역',
        addr: '전북특별자치도 완주군 용진면 구억리 806-20',
        LON: '127.15851667',
        LAT: '35.87477500',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-13.9',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '48.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [127.15851667, 35.874775] }
    },
    {
      type: 'Feature',
      id: 655,
      properties: {
        DATE: '2024-10-26',
        jiguname: '완주용진',
        gennum: '65031',
        well: '충적',
        pyogo: '28.83',
        area: '금강권역',
        addr: '전북특별자치도 완주군 용진면 구억리 806-20',
        LON: '127.15851667',
        LAT: '35.87477500',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '12',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-12.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0-12',
        strHgt: '',
        strDia: '',
        strMat: '8.0-12.0'
      },
      geometry: { type: 'Point', coordinates: [127.15851667, 35.874775] }
    },
    {
      type: 'Feature',
      id: 656,
      properties: {
        DATE: '2024-10-26',
        jiguname: '완주운주',
        gennum: '65597',
        well: '암반',
        pyogo: '124.71',
        area: '금강권역',
        addr: '전북특별자치도 완주군 운주면 산북리 950',
        LON: '127.31550556',
        LAT: '36.09603333',
        insdate: '2000-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-12.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.31550556, 36.09603333] }
    },
    {
      type: 'Feature',
      id: 657,
      properties: {
        DATE: '2024-10-26',
        jiguname: '완주읍내_심부2',
        gennum: '769813',
        well: '암반',
        pyogo: '55.34',
        area: '금강권역',
        addr: '전북특별자치도 완주군 고산면 읍내리 744-1 고산중학교',
        LON: '127.20920833',
        LAT: '35.97733611',
        insdate: '2012-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-6.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.20920833, 35.97733611] }
    },
    {
      type: 'Feature',
      id: 658,
      properties: {
        DATE: '2024-10-26',
        jiguname: '완주읍내_천부',
        gennum: '769814',
        well: '충적',
        pyogo: '55.34',
        area: '금강권역',
        addr: '전북특별자치도 완주군 고산면 읍내리 744-1 고산중학교',
        LON: '127.20920833',
        LAT: '35.97733611',
        insdate: '2012-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.20920833, 35.97733611] }
    },
    {
      type: 'Feature',
      id: 659,
      properties: {
        DATE: '2024-10-26',
        jiguname: '용인남곡',
        gennum: '769852',
        well: '암반',
        pyogo: '137.83',
        area: '한강권역',
        addr: '경기도 용인시 처인구 양지면 남곡리 819-39',
        LON: '127.27777778',
        LAT: '37.22111111',
        insdate: '2020-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0-75',
        cseExMat: '200',
        cseInHgt: '아연도강관',
        cseInDia: '0-100',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '44-48',
        strMat: ' 52-56'
      },
      geometry: { type: 'Point', coordinates: [127.27777778, 37.22111111] }
    },
    {
      type: 'Feature',
      id: 660,
      properties: {
        DATE: '2024-10-26',
        jiguname: '용인남사',
        gennum: '87242',
        well: '암반',
        pyogo: '20.65',
        area: '한강권역',
        addr: '경기도 용인시처인구 남사면 봉무리 701-60',
        LON: '127.15101111',
        LAT: '37.11179167',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: '한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-12.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '48.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [127.15101111, 37.11179167] }
    },
    {
      type: 'Feature',
      id: 661,
      properties: {
        DATE: '2024-10-26',
        jiguname: '용인남사',
        gennum: '87243',
        well: '충적',
        pyogo: '20.69',
        area: '한강권역',
        addr: '경기도 용인시처인구 남사면 봉무리 701-60',
        LON: '127.15101111',
        LAT: '37.11179167',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: '한국수자원공사',
        gulDiaBtm: '12',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-8.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-12',
        strHgt: '',
        strDia: '',
        strMat: '8.0-12.0'
      },
      geometry: { type: 'Point', coordinates: [127.15101111, 37.11179167] }
    },
    {
      type: 'Feature',
      id: 662,
      properties: {
        DATE: '2024-10-26',
        jiguname: '용인마평',
        gennum: '11740',
        well: '암반',
        pyogo: '83.51',
        area: '한강권역',
        addr: '경기도 용인시처인구 마평동  716-5 공설운동장내',
        LON: '127.21231944',
        LAT: '37.23766667',
        insdate: '1996-09-18',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '63',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-11.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS PIPE',
        cseInDia: '0.0-63.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [127.21231944, 37.23766667] }
    },
    {
      type: 'Feature',
      id: 663,
      properties: {
        DATE: '2024-10-26',
        jiguname: '용인마평',
        gennum: '11741',
        well: '충적',
        pyogo: '83.51',
        area: '한강권역',
        addr: '경기도 용인시처인구 마평동  716-5 공설운동장내',
        LON: '127.21231944',
        LAT: '37.23766667',
        insdate: '1996-09-18',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '9.5',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-9.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS PIPE',
        cseInDia: '0-9.5',
        cseInMat: '',
        strHgt: '',
        strDia: '4.5-8.5',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [127.21231944, 37.23766667] }
    },
    {
      type: 'Feature',
      id: 664,
      properties: {
        DATE: '2024-10-26',
        jiguname: '용인양지_심부2',
        gennum: '771507',
        well: '암반',
        pyogo: '131',
        area: '한강권역',
        addr: '경기도 용인시 처인구 양지면 양지리 701-9 양지면사무소',
        LON: '127.27984722',
        LAT: '37.23493056',
        insdate: '2016-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '85',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-31.7',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-85',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '79.0-85.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.27984722, 37.23493056] }
    },
    {
      type: 'Feature',
      id: 665,
      properties: {
        DATE: '2024-10-26',
        jiguname: '용인양지_천부',
        gennum: '771508',
        well: '충적',
        pyogo: '131',
        area: '한강권역',
        addr: '경기도 용인시 처인구 양지면 양지리 701-9 양지면사무소',
        LON: '127.27984722',
        LAT: '37.23493056',
        insdate: '2016-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '12',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-12',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '6.0-12.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.27984722, 37.23493056] }
    },
    {
      type: 'Feature',
      id: 666,
      properties: {
        DATE: '2024-10-26',
        jiguname: '울산고산_심부2',
        gennum: '737666',
        well: '암반',
        pyogo: '32.18',
        area: '낙동강권역',
        addr: '울산광역시 울주군 온양읍 고산리 821-1',
        LON: '129.28065833',
        LAT: '35.43126667',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-64.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.28065833, 35.43126667] }
    },
    {
      type: 'Feature',
      id: 667,
      properties: {
        DATE: '2024-10-26',
        jiguname: '울산고산_천부',
        gennum: '737665',
        well: '충적',
        pyogo: '32.18',
        area: '낙동강권역',
        addr: '울산광역시 울주군 온양읍 고산리 821-1',
        LON: '129.28065833',
        LAT: '35.43126667',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '5.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.28065833, 35.43126667] }
    },
    {
      type: 'Feature',
      id: 668,
      properties: {
        DATE: '2024-10-26',
        jiguname: '울산달천',
        gennum: '73501',
        well: '암반',
        pyogo: '57.802',
        area: '낙동강권역',
        addr: '울산광역시 북구 달천동  892-131',
        LON: '129.30505278',
        LAT: '35.63818056',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-6',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'PVC',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [129.30505278, 35.63818056] }
    },
    {
      type: 'Feature',
      id: 669,
      properties: {
        DATE: '2024-10-26',
        jiguname: '울산범서',
        gennum: '9859',
        well: '암반',
        pyogo: '23.76',
        area: '낙동강권역',
        addr: '울산광역시 울주군 범서읍 사연리 703-88',
        LON: '129.20583056',
        LAT: '35.57633333',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '79',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-13.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-79.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [129.20583056, 35.57633333] }
    },
    {
      type: 'Feature',
      id: 670,
      properties: {
        DATE: '2024-10-26',
        jiguname: '울산범서',
        gennum: '9860',
        well: '충적',
        pyogo: '23.72',
        area: '낙동강권역',
        addr: '울산광역시 울주군 범서읍 사연리 703-88',
        LON: '129.20583056',
        LAT: '35.57633333',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '13.5',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-13.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0-13.5',
        cseInMat: '',
        strHgt: '',
        strDia: '7.5-11.5',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [129.20583056, 35.57633333] }
    },
    {
      type: 'Feature',
      id: 671,
      properties: {
        DATE: '2024-10-26',
        jiguname: '울산상북',
        gennum: '9861',
        well: '암반',
        pyogo: '198.56',
        area: '낙동강권역',
        addr: '울산광역시 울주군 상북면 궁근정리 1233-1',
        LON: '129.07366389',
        LAT: '35.61673611',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '60',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-13.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-80.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [129.07366389, 35.61673611] }
    },
    {
      type: 'Feature',
      id: 672,
      properties: {
        DATE: '2024-10-26',
        jiguname: '울산상북',
        gennum: '9862',
        well: '충적',
        pyogo: '198.59',
        area: '낙동강권역',
        addr: '울산광역시 울주군 상북면 궁근정리 1233-1',
        LON: '129.07366389',
        LAT: '35.61673611',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '13.5',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-13.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0-13.5',
        cseInMat: '',
        strHgt: '',
        strDia: '8.5-12.5',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [129.07366389, 35.61673611] }
    },
    {
      type: 'Feature',
      id: 673,
      properties: {
        DATE: '2024-10-26',
        jiguname: '울산서부_심부2',
        gennum: '737668',
        well: '암반',
        pyogo: '45.5',
        area: '낙동강권역',
        addr: '울산광역시 동구 서부동  615',
        LON: '129.41471111',
        LAT: '35.53217222',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-7.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.41471111, 35.53217222] }
    },
    {
      type: 'Feature',
      id: 674,
      properties: {
        DATE: '2024-10-26',
        jiguname: '울산서부_천부',
        gennum: '737667',
        well: '충적',
        pyogo: '45.5',
        area: '낙동강권역',
        addr: '울산광역시 동구 서부동  615',
        LON: '129.41471111',
        LAT: '35.53217222',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '21',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-21.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '15.0-21.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.41471111, 35.53217222] }
    },
    {
      type: 'Feature',
      id: 675,
      properties: {
        DATE: '2024-10-26',
        jiguname: '울산서생',
        gennum: '718269',
        well: '암반',
        pyogo: '6.3',
        area: '낙동강권역',
        addr: '울산광역시 울주군 서생면 신암리 338-5',
        LON: '129.31972222',
        LAT: '35.34694444',
        insdate: '2018-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '(주)호림건설',
        gulDiaTop: '150',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-14.0',
        cseExMat: '200',
        cseInHgt: 'SST',
        cseInDia: '0.0-150',
        cseInMat: '150',
        strHgt: 'P.V.C(VG1)',
        strDia: '57-58',
        strMat: ' 62-66'
      },
      geometry: { type: 'Point', coordinates: [129.31972222, 35.34694444] }
    },
    {
      type: 'Feature',
      id: 676,
      properties: {
        DATE: '2024-10-26',
        jiguname: '울산서하_심부2',
        gennum: '737670',
        well: '암반',
        pyogo: '89.278',
        area: '낙동강권역',
        addr: '울산광역시 울주군 두서면 서하리 86',
        LON: '129.16306667',
        LAT: '35.62765833',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-8.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '72.5-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.16306667, 35.62765833] }
    },
    {
      type: 'Feature',
      id: 677,
      properties: {
        DATE: '2024-10-26',
        jiguname: '울산서하_천부',
        gennum: '737669',
        well: '충적',
        pyogo: '89.288',
        area: '낙동강권역',
        addr: '울산광역시 울주군 두서면 서하리 86',
        LON: '129.16306667',
        LAT: '35.62765833',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-5.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '5.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.16306667, 35.62765833] }
    },
    {
      type: 'Feature',
      id: 678,
      properties: {
        DATE: '2024-10-26',
        jiguname: '울산온양',
        gennum: '9863',
        well: '암반',
        pyogo: '12.32',
        area: '낙동강권역',
        addr: '울산광역시 울주군 온양읍 망양리 725-135',
        LON: '129.29157778',
        LAT: '35.45975556',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-9.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [129.29157778, 35.45975556] }
    },
    {
      type: 'Feature',
      id: 679,
      properties: {
        DATE: '2024-10-26',
        jiguname: '울산온양',
        gennum: '9864',
        well: '충적',
        pyogo: '12.34',
        area: '낙동강권역',
        addr: '울산광역시 울주군 온양읍 망양리 725-135',
        LON: '129.29157778',
        LAT: '35.45975556',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '8',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-9.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0-8',
        cseInMat: '',
        strHgt: '',
        strDia: '4.0-8.0',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [129.29157778, 35.45975556] }
    },
    {
      type: 'Feature',
      id: 680,
      properties: {
        DATE: '2024-10-26',
        jiguname: '울산천상(구 울산범서)_심부2',
        gennum: '769815',
        well: '암반',
        pyogo: '43.25',
        area: '낙동강권역',
        addr: '울산광역시 울주군 범서읍 천상리 산48-2 천상중학교',
        LON: '129.22023333',
        LAT: '35.55970000',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-6.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.22023333, 35.5597] }
    },
    {
      type: 'Feature',
      id: 681,
      properties: {
        DATE: '2024-10-26',
        jiguname: '울산천상(구 울산범서)_천부',
        gennum: '769816',
        well: '충적',
        pyogo: '43.25',
        area: '낙동강권역',
        addr: '울산광역시 울주군 범서읍 천상리 산48-2 천상중학교',
        LON: '129.22023333',
        LAT: '35.55970000',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.22023333, 35.5597] }
    },
    {
      type: 'Feature',
      id: 682,
      properties: {
        DATE: '2024-10-26',
        jiguname: '울진북면',
        gennum: '87261',
        well: '암반',
        pyogo: '18.13',
        area: '낙동강권역',
        addr: '경상북도 울진군 북면 주인리 8-1',
        LON: '129.34851667',
        LAT: '37.09345000',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국지질조사탐사업협동조합',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-15.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [129.34851667, 37.09345] }
    },
    {
      type: 'Feature',
      id: 683,
      properties: {
        DATE: '2024-10-26',
        jiguname: '울진북면',
        gennum: '87262',
        well: '충적',
        pyogo: '18.09',
        area: '낙동강권역',
        addr: '경상북도 울진군 북면 주인리 8-1',
        LON: '129.34851667',
        LAT: '37.09345000',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국지질조사탐사업협동조합',
        gulDiaTop: '15',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-11.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0-15',
        cseInMat: '',
        strHgt: '',
        strDia: '11.0-15.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [129.34851667, 37.09345] }
    },
    {
      type: 'Feature',
      id: 684,
      properties: {
        DATE: '2024-10-26',
        jiguname: '울진상당_심부2',
        gennum: '737672',
        well: '암반',
        pyogo: '122.344',
        area: '낙동강권역',
        addr: '경상북도 울진군 북면 상당리 273-1',
        LON: '129.29322500',
        LAT: '37.06206944',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-17.2',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '75.5-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.293225, 37.06206944] }
    },
    {
      type: 'Feature',
      id: 685,
      properties: {
        DATE: '2024-10-26',
        jiguname: '울진상당_천부',
        gennum: '737671',
        well: '충적',
        pyogo: '122.357',
        area: '낙동강권역',
        addr: '경상북도 울진군 북면 상당리 273-1',
        LON: '129.29322500',
        LAT: '37.06206944',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-5.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '5.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.293225, 37.06206944] }
    },
    {
      type: 'Feature',
      id: 686,
      properties: {
        DATE: '2024-10-26',
        jiguname: '울진수곡_심부2',
        gennum: '737674',
        well: '암반',
        pyogo: '20',
        area: '낙동강권역',
        addr: '경상북도 울진군 근남면 수곡리 48',
        LON: '129.37329444',
        LAT: '36.95726111',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-27.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.37329444, 36.95726111] }
    },
    {
      type: 'Feature',
      id: 687,
      properties: {
        DATE: '2024-10-26',
        jiguname: '울진수곡_천부',
        gennum: '737673',
        well: '충적',
        pyogo: '20',
        area: '낙동강권역',
        addr: '경상북도 울진군 근남면 수곡리 48',
        LON: '129.37329444',
        LAT: '36.95726111',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '15',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-15.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '9.0-15.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.37329444, 36.95726111] }
    },
    {
      type: 'Feature',
      id: 688,
      properties: {
        DATE: '2024-10-26',
        jiguname: '울진온정',
        gennum: '65032',
        well: '암반',
        pyogo: '108.53',
        area: '낙동강권역',
        addr: '경상북도 울진군 온정면 소태리 542 상수도취수장',
        LON: '129.34904444',
        LAT: '36.71850556',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '71',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-6.9',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-71.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '48.0-52.0',
        strMat: ' 56.0-60.0'
      },
      geometry: { type: 'Point', coordinates: [129.34904444, 36.71850556] }
    },
    {
      type: 'Feature',
      id: 689,
      properties: {
        DATE: '2024-10-26',
        jiguname: '울진울진',
        gennum: '615522',
        well: '암반',
        pyogo: '36',
        area: '낙동강권역',
        addr: '경상북도 울진군 울진읍 정림리 607-1',
        LON: '129.35454444',
        LAT: '37.02629167',
        insdate: '2013-11-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜지오그린21',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-13.0',
        cseExMat: '200',
        cseInHgt: 'STS304',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '44-48',
        strMat: ' 64-68'
      },
      geometry: { type: 'Point', coordinates: [129.35454444, 37.02629167] }
    },
    {
      type: 'Feature',
      id: 690,
      properties: {
        DATE: '2024-10-26',
        jiguname: '원주귀래',
        gennum: '73506',
        well: '암반',
        pyogo: '139.726',
        area: '한강권역',
        addr: '강원특별자치도 원주시 귀래면 운남리 1341',
        LON: '127.88298056',
        LAT: '37.16969444',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-9',
        cseInHgt: '250',
        cseInDia: '백관',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'PVC',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.88298056, 37.16969444] }
    },
    {
      type: 'Feature',
      id: 691,
      properties: {
        DATE: '2024-10-26',
        jiguname: '원주명륜',
        gennum: '87233',
        well: '암반',
        pyogo: '163.88',
        area: '한강권역',
        addr: '강원특별자치도 원주시 명륜동  849-1 구곡 오수가압장 내',
        LON: '127.93924444',
        LAT: '37.32245000',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국지질조사탐사업협동조합',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-23.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '20.0-24.0',
        strMat: ' 44.0-48.0'
      },
      geometry: { type: 'Point', coordinates: [127.93924444, 37.32245] }
    },
    {
      type: 'Feature',
      id: 692,
      properties: {
        DATE: '2024-10-26',
        jiguname: '원주명륜',
        gennum: '87234',
        well: '충적',
        pyogo: '163.86',
        area: '한강권역',
        addr: '강원특별자치도 원주시 명륜동  849-1 구곡 오수가압장 내',
        LON: '127.93924444',
        LAT: '37.32245000',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국지질조사탐사업협동조합',
        gulDiaTop: '20',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-16.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0-20',
        cseInMat: '',
        strHgt: '',
        strDia: '16.0-20.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [127.93924444, 37.32245] }
    },
    {
      type: 'Feature',
      id: 693,
      properties: {
        DATE: '2024-10-26',
        jiguname: '원주문막',
        gennum: '11746',
        well: '암반',
        pyogo: '59.17',
        area: '한강권역',
        addr: '강원특별자치도 원주시 문막읍 문막리 1049-1 문막취수장내',
        LON: '127.81302778',
        LAT: '37.30687778',
        insdate: '1996-11-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '63',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-12.0',
        cseExMat: '250',
        cseInHgt: 'KS 백강관',
        cseInDia: '0.0-63.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '35.0-43.0',
        strMat: ' 55.0-59.0'
      },
      geometry: { type: 'Point', coordinates: [127.81302778, 37.30687778] }
    },
    {
      type: 'Feature',
      id: 694,
      properties: {
        DATE: '2024-10-26',
        jiguname: '원주문막',
        gennum: '11747',
        well: '충적',
        pyogo: '59.17',
        area: '한강권역',
        addr: '강원특별자치도 원주시 문막읍 문막리 1049-1 문막취수장내',
        LON: '127.81302778',
        LAT: '37.30687778',
        insdate: '1996-11-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '8.8',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-8.8',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0-8.8',
        cseInMat: '',
        strHgt: '',
        strDia: '3.8-7.8',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [127.81302778, 37.30687778] }
    },
    {
      type: 'Feature',
      id: 695,
      properties: {
        DATE: '2024-10-26',
        jiguname: '원주반곡',
        gennum: '771356',
        well: '암반',
        pyogo: '198',
        area: '한강권역',
        addr: '강원특별자치도 원주시 반곡동  2040',
        LON: '127.98922222',
        LAT: '37.32191667',
        insdate: '2021-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '150',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0-12',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0-150',
        cseInMat: '150',
        strHgt: 'PVC(VG1)',
        strDia: '84-88',
        strMat: ' 92-96'
      },
      geometry: { type: 'Point', coordinates: [127.98922222, 37.32191667] }
    },
    {
      type: 'Feature',
      id: 696,
      properties: {
        DATE: '2024-10-26',
        jiguname: '원주부론',
        gennum: '718251',
        well: '암반',
        pyogo: '74.84',
        area: '한강권역',
        addr: '강원특별자치도 원주시 부론면 정산리 907-4',
        LON: '127.77916667',
        LAT: '37.16555556',
        insdate: '2018-12-07',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '동서지오베이스(주)',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-12.0',
        cseExMat: '200',
        cseInHgt: 'Stainless Steel',
        cseInDia: '0.0-100',
        cseInMat: '150',
        strHgt: 'P.V.C(VG1)',
        strDia: '48.0-56.0',
        strMat: ' 60.0-68.072.0-80.084.0-92.0'
      },
      geometry: { type: 'Point', coordinates: [127.77916667, 37.16555556] }
    },
    {
      type: 'Feature',
      id: 697,
      properties: {
        DATE: '2024-10-26',
        jiguname: '원주신림',
        gennum: '612032',
        well: '암반',
        pyogo: '336.02',
        area: '한강권역',
        addr: '강원특별자치도 원주시 신림면 신림리 산32-',
        LON: '128.08843333',
        LAT: '37.23818889',
        insdate: '2012-12-17',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '101',
        cseExHgt: '300',
        cseExDia: '200',
        cseExMat: '',
        cseInHgt: '',
        cseInDia: '',
        cseInMat: '0-101',
        strHgt: '',
        strDia: '',
        strMat: ''
      },
      geometry: { type: 'Point', coordinates: [128.08843333, 37.23818889] }
    },
    {
      type: 'Feature',
      id: 698,
      properties: {
        DATE: '2024-10-26',
        jiguname: '원주학곡_심부2',
        gennum: '771509',
        well: '암반',
        pyogo: '245.775',
        area: '한강권역',
        addr: '강원특별자치도 원주시 소초면 학곡리 710-18 원주옻칠기공예관',
        LON: '128.05581389',
        LAT: '37.42593611',
        insdate: '2013-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '82',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-4.7',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-82',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '73.0-82.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.05581389, 37.42593611] }
    },
    {
      type: 'Feature',
      id: 699,
      properties: {
        DATE: '2024-10-26',
        jiguname: '원주학곡_천부',
        gennum: '771510',
        well: '충적',
        pyogo: '245.775',
        area: '한강권역',
        addr: '강원특별자치도 원주시 소초면 학곡리 710-18 원주옻칠기공예관',
        LON: '128.05581389',
        LAT: '37.42593611',
        insdate: '2013-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.05581389, 37.42593611] }
    },
    {
      type: 'Feature',
      id: 700,
      properties: {
        DATE: '2024-10-26',
        jiguname: '음성대소',
        gennum: '11750',
        well: '암반',
        pyogo: '92.22',
        area: '금강권역',
        addr: '충청북도 음성군 대소면 오류리 70-3 대소면사무소내',
        LON: '127.48392500',
        LAT: '36.97207778',
        insdate: '1996-10-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '68',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-35.9',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0.0-68.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '35.0-47.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [127.483925, 36.97207778] }
    },
    {
      type: 'Feature',
      id: 701,
      properties: {
        DATE: '2024-10-26',
        jiguname: '음성대소',
        gennum: '11751',
        well: '충적',
        pyogo: '92.2',
        area: '금강권역',
        addr: '충청북도 음성군 대소면 오류리 70-3 대소면사무소내',
        LON: '127.48392500',
        LAT: '36.97207778',
        insdate: '1996-10-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '21.2',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-21.2',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0-21.2',
        cseInMat: '',
        strHgt: '',
        strDia: '16.0-20.0',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [127.483925, 36.97207778] }
    },
    {
      type: 'Feature',
      id: 702,
      properties: {
        DATE: '2024-10-26',
        jiguname: '음성생극',
        gennum: '65033',
        well: '암반',
        pyogo: '79.343',
        area: '한강권역',
        addr: '충청북도 음성군 생극면 신양리 852-1',
        LON: '127.60089444',
        LAT: '37.04340556',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-18.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '46.0-50.0'
      },
      geometry: { type: 'Point', coordinates: [127.60089444, 37.04340556] }
    },
    {
      type: 'Feature',
      id: 703,
      properties: {
        DATE: '2024-10-26',
        jiguname: '음성생극',
        gennum: '65034',
        well: '충적',
        pyogo: '79.39',
        area: '한강권역',
        addr: '충청북도 음성군 생극면 신양리 852-1',
        LON: '127.60089444',
        LAT: '37.04340556',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '16',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-16.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0-16',
        strHgt: '',
        strDia: '',
        strMat: '12.0-16.0'
      },
      geometry: { type: 'Point', coordinates: [127.60089444, 37.04340556] }
    },
    {
      type: 'Feature',
      id: 704,
      properties: {
        DATE: '2024-10-26',
        jiguname: '음성원남',
        gennum: '514303',
        well: '암반',
        pyogo: '190',
        area: '금강권역',
        addr: '충청북도 음성군 원남면 보천리 산111-1',
        LON: '127.63672500',
        LAT: '36.88054167',
        insdate: '2010-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '유성삼정개발㈜',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-6.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '8-12',
        strMat: ' 24-28'
      },
      geometry: { type: 'Point', coordinates: [127.636725, 36.88054167] }
    },
    {
      type: 'Feature',
      id: 705,
      properties: {
        DATE: '2024-10-26',
        jiguname: '음성음성',
        gennum: '65072',
        well: '암반',
        pyogo: '145.52',
        area: '한강권역',
        addr: '충청북도 음성군 음성읍 읍내리 536-6',
        LON: '127.69031389',
        LAT: '36.93516111',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-12.5',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.69031389, 36.93516111] }
    },
    {
      type: 'Feature',
      id: 706,
      properties: {
        DATE: '2024-10-26',
        jiguname: '의령낙서',
        gennum: '87266',
        well: '암반',
        pyogo: '16.37',
        area: '낙동강권역',
        addr: '경상남도 의령군 낙서면 아근리 449',
        LON: '128.37148889',
        LAT: '35.51360556',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: '한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-41.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '40.0-44.0'
      },
      geometry: { type: 'Point', coordinates: [128.37148889, 35.51360556] }
    },
    {
      type: 'Feature',
      id: 707,
      properties: {
        DATE: '2024-10-26',
        jiguname: '의령낙서',
        gennum: '87267',
        well: '충적',
        pyogo: '16.37',
        area: '낙동강권역',
        addr: '경상남도 의령군 낙서면 아근리 449',
        LON: '128.37148889',
        LAT: '35.51360556',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: '한국수자원공사',
        gulDiaBtm: '20',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-16.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-20',
        strHgt: '',
        strDia: '',
        strMat: '16.0-20.0'
      },
      geometry: { type: 'Point', coordinates: [128.37148889, 35.51360556] }
    },
    {
      type: 'Feature',
      id: 708,
      properties: {
        DATE: '2024-10-26',
        jiguname: '의령벽계_심부2',
        gennum: '737678',
        well: '암반',
        pyogo: '139.7',
        area: '낙동강권역',
        addr: '경상남도 의령군 궁류면 벽계리 15-1',
        LON: '128.22787222',
        LAT: '35.43579444',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '82',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-8.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-82.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '72.0-82.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.22787222, 35.43579444] }
    },
    {
      type: 'Feature',
      id: 709,
      properties: {
        DATE: '2024-10-26',
        jiguname: '의령벽계_천부',
        gennum: '737677',
        well: '충적',
        pyogo: '139.7',
        area: '낙동강권역',
        addr: '경상남도 의령군 궁류면 벽계리 15-1',
        LON: '128.22787222',
        LAT: '35.43579444',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.22787222, 35.43579444] }
    },
    {
      type: 'Feature',
      id: 710,
      properties: {
        DATE: '2024-10-26',
        jiguname: '의령봉수',
        gennum: '9880',
        well: '암반',
        pyogo: '73.55',
        area: '낙동강권역',
        addr: '경상남도 의령군 봉수면 죽전리 1316-1 봉수면사무소내',
        LON: '128.26680278',
        LAT: '35.47216667',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-10.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [128.26680278, 35.47216667] }
    },
    {
      type: 'Feature',
      id: 711,
      properties: {
        DATE: '2024-10-26',
        jiguname: '의령봉수',
        gennum: '9881',
        well: '충적',
        pyogo: '73.56',
        area: '낙동강권역',
        addr: '경상남도 의령군 봉수면 죽전리 1316-1 봉수면사무소내',
        LON: '128.26680278',
        LAT: '35.47216667',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '9.5',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-9.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0-9.5',
        cseInMat: '',
        strHgt: '',
        strDia: '5.5-9.5',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [128.26680278, 35.47216667] }
    },
    {
      type: 'Feature',
      id: 712,
      properties: {
        DATE: '2024-10-26',
        jiguname: '의령의령',
        gennum: '73546',
        well: '암반',
        pyogo: '16.48',
        area: '낙동강권역',
        addr: '경상남도 의령군 의령읍 정암리 75-7',
        LON: '128.30081667',
        LAT: '35.31940833',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-20.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [128.30081667, 35.31940833] }
    },
    {
      type: 'Feature',
      id: 713,
      properties: {
        DATE: '2024-10-26',
        jiguname: '의령의령',
        gennum: '73547',
        well: '충적',
        pyogo: '16.57',
        area: '낙동강권역',
        addr: '경상남도 의령군 의령읍 정암리 75-7',
        LON: '128.30081667',
        LAT: '35.31940833',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-10.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6.0-10.0'
      },
      geometry: { type: 'Point', coordinates: [128.30081667, 35.31940833] }
    },
    {
      type: 'Feature',
      id: 714,
      properties: {
        DATE: '2024-10-26',
        jiguname: '의성금성',
        gennum: '637529',
        well: '암반',
        pyogo: '100.221',
        area: '낙동강권역',
        addr: '경상북도 의성군 금성면 하리 275-1 1',
        LON: '128.68078889',
        LAT: '36.28986389',
        insdate: '2015-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜대동공영',
        gulDiaTop: '98',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-18.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-98.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '24.0-32.0',
        strMat: ' 40.0-48.0'
      },
      geometry: { type: 'Point', coordinates: [128.68078889, 36.28986389] }
    },
    {
      type: 'Feature',
      id: 715,
      properties: {
        DATE: '2024-10-26',
        jiguname: '의성단밀',
        gennum: '717019',
        well: '암반',
        pyogo: '87.06',
        area: '낙동강권역',
        addr: '경상북도 의성군 단밀면 생송리 14-3',
        LON: '128.36210278',
        LAT: '36.37035278',
        insdate: '2017-12-14',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜에스제이지반기술',
        gulDiaTop: '145',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-12.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-145',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '48-52',
        strMat: ' 56-60'
      },
      geometry: { type: 'Point', coordinates: [128.36210278, 36.37035278] }
    },
    {
      type: 'Feature',
      id: 716,
      properties: {
        DATE: '2024-10-26',
        jiguname: '의성대리_심부2',
        gennum: '771511',
        well: '암반',
        pyogo: '91.48',
        area: '낙동강권역',
        addr: '경상북도 의성군 금성면 대리리 175-1 금성초등학교',
        LON: '128.67930556',
        LAT: '36.26229722',
        insdate: '2012-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-10.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.67930556, 36.26229722] }
    },
    {
      type: 'Feature',
      id: 717,
      properties: {
        DATE: '2024-10-26',
        jiguname: '의성대리_천부',
        gennum: '771512',
        well: '충적',
        pyogo: '91.48',
        area: '낙동강권역',
        addr: '경상북도 의성군 금성면 대리리 175-1 금성초등학교',
        LON: '128.67930556',
        LAT: '36.26229722',
        insdate: '2012-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '19.5',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-19.5',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '4.5-15.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.67930556, 36.26229722] }
    },
    {
      type: 'Feature',
      id: 718,
      properties: {
        DATE: '2024-10-26',
        jiguname: '의성안계',
        gennum: '82041',
        well: '암반',
        pyogo: '51.415',
        area: '낙동강권역',
        addr: '경상북도 의성군 안계면 용기리 475-2',
        LON: '128.44027500',
        LAT: '36.38353889',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-6',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'P.V.C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [128.440275, 36.38353889] }
    },
    {
      type: 'Feature',
      id: 719,
      properties: {
        DATE: '2024-10-26',
        jiguname: '의성옥산',
        gennum: '771364',
        well: '암반',
        pyogo: '236.1',
        area: '낙동강권역',
        addr: '경상북도 의성군 옥산면 감계리 519-3',
        LON: '128.79667500',
        LAT: '36.34788056',
        insdate: '2021-12-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-5.0',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P.V.C(VG1)',
        strDia: '12.0-20.0',
        strMat: ' 40.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [128.796675, 36.34788056] }
    },
    {
      type: 'Feature',
      id: 720,
      properties: {
        DATE: '2024-10-26',
        jiguname: '의성의성',
        gennum: '65035',
        well: '암반',
        pyogo: '85.48',
        area: '낙동강권역',
        addr: '경상북도 의성군 의성읍 도서리 114-3 상수도취수장',
        LON: '128.69551111',
        LAT: '36.34523056',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '72',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-6.9',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0.0-72.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '48.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [128.69551111, 36.34523056] }
    },
    {
      type: 'Feature',
      id: 721,
      properties: {
        DATE: '2024-10-26',
        jiguname: '의왕고천',
        gennum: '612001',
        well: '암반',
        pyogo: '64.927',
        area: '한강권역',
        addr: '경기도 의왕시 고천동  171',
        LON: '126.96879444',
        LAT: '37.34554722',
        insdate: '2012-08-25',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜지오그린21',
        gulDiaTop: '104',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-23.5',
        cseExMat: '200',
        cseInHgt: 'STS304',
        cseInDia: '0.0-104.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '24.0-32.0',
        strMat: ' 84.0-100.0'
      },
      geometry: { type: 'Point', coordinates: [126.96879444, 37.34554722] }
    },
    {
      type: 'Feature',
      id: 722,
      properties: {
        DATE: '2024-10-26',
        jiguname: '의정부신곡_신',
        gennum: '65609',
        well: '암반',
        pyogo: '39.033',
        area: '한강권역',
        addr: '경기도 의정부시 신곡1동  615-34 신곡동 배수펌프장 내',
        LON: '127.05465556',
        LAT: '37.73481389',
        insdate: '2000-09-08',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-14.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P.V.C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.05465556, 37.73481389] }
    },
    {
      type: 'Feature',
      id: 723,
      properties: {
        DATE: '2024-10-26',
        jiguname: '의정부신곡_신',
        gennum: '65610',
        well: '충적',
        pyogo: '39.002',
        area: '한강권역',
        addr: '경기도 의정부시 신곡1동  615-34 신곡동 배수펌프장 내',
        LON: '127.05465556',
        LAT: '37.73481389',
        insdate: '2000-09-08',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '11',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-7.0',
        cseInHgt: '200',
        cseInDia: '백관',
        cseInMat: '0-11',
        strHgt: '',
        strDia: '',
        strMat: '7.0-11.0'
      },
      geometry: { type: 'Point', coordinates: [127.05465556, 37.73481389] }
    },
    {
      type: 'Feature',
      id: 724,
      properties: {
        DATE: '2024-10-26',
        jiguname: '이천마장',
        gennum: '771352',
        well: '암반',
        pyogo: '91.3',
        area: '한강권역',
        addr: '경기도 이천시 마장면 양촌리 산1-6',
        LON: '127.35363889',
        LAT: '37.26313889',
        insdate: '2021-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '150',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0-12',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0-150',
        cseInMat: '150',
        strHgt: 'PVC(VG1)',
        strDia: '84-88',
        strMat: ' 92-96'
      },
      geometry: { type: 'Point', coordinates: [127.35363889, 37.26313889] }
    },
    {
      type: 'Feature',
      id: 725,
      properties: {
        DATE: '2024-10-26',
        jiguname: '이천율현',
        gennum: '11744',
        well: '암반',
        pyogo: '50.37',
        area: '한강권역',
        addr: '경기도 이천시 율현동  179-1 복하천 제방 옆',
        LON: '127.44716111',
        LAT: '37.25541667',
        insdate: '1996-09-21',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '63',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-14.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0.0-63.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '28.0-36.0',
        strMat: ' 48.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [127.44716111, 37.25541667] }
    },
    {
      type: 'Feature',
      id: 726,
      properties: {
        DATE: '2024-10-26',
        jiguname: '이천율현',
        gennum: '11745',
        well: '충적',
        pyogo: '50.37',
        area: '한강권역',
        addr: '경기도 이천시 율현동  179-1 복하천 제방 옆',
        LON: '127.44716111',
        LAT: '37.25541667',
        insdate: '1996-09-21',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '9.6',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-9.6',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0-9.6',
        cseInMat: '',
        strHgt: '',
        strDia: '4.6-8.6',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [127.44716111, 37.25541667] }
    },
    {
      type: 'Feature',
      id: 727,
      properties: {
        DATE: '2024-10-26',
        jiguname: '익산낭산',
        gennum: '95525',
        well: '암반',
        pyogo: '18.07',
        area: '금강권역',
        addr: '전북특별자치도 익산시 낭산면 삼담리 589-14',
        LON: '127.01028056',
        LAT: '36.07827222',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-30.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '20.0-24.0'
      },
      geometry: { type: 'Point', coordinates: [127.01028056, 36.07827222] }
    },
    {
      type: 'Feature',
      id: 728,
      properties: {
        DATE: '2024-10-26',
        jiguname: '익산낭산',
        gennum: '95526',
        well: '충적',
        pyogo: '18.08',
        area: '금강권역',
        addr: '전북특별자치도 익산시 낭산면 삼담리 589-14',
        LON: '127.01028056',
        LAT: '36.07827222',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '13',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-9.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-13',
        strHgt: '',
        strDia: '',
        strMat: '9.0-13.0'
      },
      geometry: { type: 'Point', coordinates: [127.01028056, 36.07827222] }
    },
    {
      type: 'Feature',
      id: 729,
      properties: {
        DATE: '2024-10-26',
        jiguname: '익산망성',
        gennum: '769855',
        well: '암반',
        pyogo: '9.3',
        area: '금강권역',
        addr: '전북특별자치도 익산시 망성면 신작리 459-18',
        LON: '127.02083333',
        LAT: '36.13222222',
        insdate: '2020-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0-45.5',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0-100',
        cseInMat: '150',
        strHgt: 'P.V.C(VG1)',
        strDia: '32-36',
        strMat: ' 40-44'
      },
      geometry: { type: 'Point', coordinates: [127.02083333, 36.13222222] }
    },
    {
      type: 'Feature',
      id: 730,
      properties: {
        DATE: '2024-10-26',
        jiguname: '익산망성',
        gennum: '769856',
        well: '충적',
        pyogo: '9.3',
        area: '금강권역',
        addr: '전북특별자치도 익산시 망성면 신작리 459-18',
        LON: '127.02083333',
        LAT: '36.13222222',
        insdate: '2020-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '27.5',
        gulDiaBtm: '400',
        cseExHgt: '300',
        cseExDia: '0-3',
        cseExMat: '300',
        cseInHgt: '백관',
        cseInDia: '0-27.54',
        cseInMat: '200',
        strHgt: 'P.V.C(VG1)',
        strDia: '26-27.5',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [127.02083333, 36.13222222] }
    },
    {
      type: 'Feature',
      id: 731,
      properties: {
        DATE: '2024-10-26',
        jiguname: '익산와리_심부2',
        gennum: '737680',
        well: '암반',
        pyogo: '16.1',
        area: '금강권역',
        addr: '전북특별자치도 익산시 함열읍 와리 154-7',
        LON: '126.96246944',
        LAT: '36.07348889',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '88',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-14.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-88.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '78.0-88.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.96246944, 36.07348889] }
    },
    {
      type: 'Feature',
      id: 732,
      properties: {
        DATE: '2024-10-26',
        jiguname: '익산와리_천부',
        gennum: '737679',
        well: '충적',
        pyogo: '16.1',
        area: '금강권역',
        addr: '전북특별자치도 익산시 함열읍 와리 154-7',
        LON: '126.96246944',
        LAT: '36.07348889',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '5.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.96246944, 36.07348889] }
    },
    {
      type: 'Feature',
      id: 733,
      properties: {
        DATE: '2024-10-26',
        jiguname: '익산용동',
        gennum: '9905',
        well: '암반',
        pyogo: '9.63',
        area: '금강권역',
        addr: '전북특별자치도 익산시 용동면 대조리 815-1 복지회관 내',
        LON: '126.99146111',
        LAT: '36.10923333',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '69',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-41.5',
        cseExMat: '250',
        cseInHgt: '백관',
        cseInDia: '0.0-69.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [126.99146111, 36.10923333] }
    },
    {
      type: 'Feature',
      id: 734,
      properties: {
        DATE: '2024-10-26',
        jiguname: '인제귀둔_심부2',
        gennum: '737682',
        well: '암반',
        pyogo: '395.559',
        area: '한강권역',
        addr: '강원특별자치도 인제군 인제읍 귀둔리 676-2',
        LON: '128.36142778',
        LAT: '38.01655833',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-9.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '69.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.36142778, 38.01655833] }
    },
    {
      type: 'Feature',
      id: 735,
      properties: {
        DATE: '2024-10-26',
        jiguname: '인제귀둔_천부',
        gennum: '737681',
        well: '충적',
        pyogo: '395.559',
        area: '한강권역',
        addr: '강원특별자치도 인제군 인제읍 귀둔리 676-2',
        LON: '128.36142778',
        LAT: '38.01655833',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.36142778, 38.01655833] }
    },
    {
      type: 'Feature',
      id: 736,
      properties: {
        DATE: '2024-10-26',
        jiguname: '인제기린',
        gennum: '95513',
        well: '암반',
        pyogo: '369.04',
        area: '한강권역',
        addr: '강원특별자치도 인제군 기린면 방동리 1246',
        LON: '128.38313889',
        LAT: '37.94925278',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-4.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '15.0-19.0'
      },
      geometry: { type: 'Point', coordinates: [128.38313889, 37.94925278] }
    },
    {
      type: 'Feature',
      id: 737,
      properties: {
        DATE: '2024-10-26',
        jiguname: '인제남면',
        gennum: '95514',
        well: '암반',
        pyogo: '199.98',
        area: '한강권역',
        addr: '강원특별자치도 인제군 남면 신남리 179-2',
        LON: '128.08694167',
        LAT: '37.97557500',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-10.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '34.5-38.5'
      },
      geometry: { type: 'Point', coordinates: [128.08694167, 37.975575] }
    },
    {
      type: 'Feature',
      id: 738,
      properties: {
        DATE: '2024-10-26',
        jiguname: '인제남면',
        gennum: '95515',
        well: '충적',
        pyogo: '199.98',
        area: '한강권역',
        addr: '강원특별자치도 인제군 남면 신남리 179-2',
        LON: '128.08694167',
        LAT: '37.97557500',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '13',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-9.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-13',
        strHgt: '',
        strDia: '',
        strMat: '9.0-13.0'
      },
      geometry: { type: 'Point', coordinates: [128.08694167, 37.975575] }
    },
    {
      type: 'Feature',
      id: 739,
      properties: {
        DATE: '2024-10-26',
        jiguname: '인제남북_심부2',
        gennum: '771513',
        well: '암반',
        pyogo: '197.35',
        area: '한강권역',
        addr: '강원특별자치도 인제군 인제읍 남북리 824 인제공설운동장',
        LON: '128.16669167',
        LAT: '38.05812500',
        insdate: '2013-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '81',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-12.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-81',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '72.0-81.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.16669167, 38.058125] }
    },
    {
      type: 'Feature',
      id: 740,
      properties: {
        DATE: '2024-10-26',
        jiguname: '인제남북_천부',
        gennum: '771514',
        well: '충적',
        pyogo: '197.35',
        area: '한강권역',
        addr: '강원특별자치도 인제군 인제읍 남북리 824 인제공설운동장',
        LON: '128.16669167',
        LAT: '38.05812500',
        insdate: '2013-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.16669167, 38.058125] }
    },
    {
      type: 'Feature',
      id: 741,
      properties: {
        DATE: '2024-10-26',
        jiguname: '인제상남_신',
        gennum: '718240',
        well: '암반',
        pyogo: '400.942',
        area: '한강권역',
        addr: '강원특별자치도 인제군 상남면 상남리 81-45',
        LON: '128.25705278',
        LAT: '37.87493056',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-6.2',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '31.5-35.5'
      },
      geometry: { type: 'Point', coordinates: [128.25705278, 37.87493056] }
    },
    {
      type: 'Feature',
      id: 742,
      properties: {
        DATE: '2024-10-26',
        jiguname: '인제서화2',
        gennum: '769818',
        well: '암반',
        pyogo: '350.41',
        area: '한강권역',
        addr: '강원특별자치도 인제군 서화면 서화리 830 한국DMZ평화생명동산교육마을',
        LON: '128.20658889',
        LAT: '38.24004167',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-6.1',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.20658889, 38.24004167] }
    },
    {
      type: 'Feature',
      id: 743,
      properties: {
        DATE: '2024-10-26',
        jiguname: '인제서화2',
        gennum: '769817',
        well: '충적',
        pyogo: '350.41',
        area: '한강권역',
        addr: '강원특별자치도 인제군 서화면 서화리 830 한국DMZ평화생명동산교육마을',
        LON: '128.20658889',
        LAT: '38.24004167',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '30',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.20658889, 38.24004167] }
    },
    {
      type: 'Feature',
      id: 744,
      properties: {
        DATE: '2024-10-26',
        jiguname: '인제서화_신',
        gennum: '769849',
        well: '암반',
        pyogo: '313.51',
        area: '한강권역',
        addr: '강원특별자치도 인제군 서화면 천도리 1092',
        LON: '128.21611111',
        LAT: '38.23250000',
        insdate: '2020-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '155',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0-8.3',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-155',
        cseInMat: '150',
        strHgt: 'PVC',
        strDia: '36-40',
        strMat: ' 44-48'
      },
      geometry: { type: 'Point', coordinates: [128.21611111, 38.2325] }
    },
    {
      type: 'Feature',
      id: 745,
      properties: {
        DATE: '2024-10-26',
        jiguname: '인제원통_심부1',
        gennum: '737683',
        well: '충적',
        pyogo: '297.111',
        area: '한강권역',
        addr: '강원특별자치도 인제군 북면 원통리 626',
        LON: '128.19718056',
        LAT: '38.12079444',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '30',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-8.7',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.19718056, 38.12079444] }
    },
    {
      type: 'Feature',
      id: 746,
      properties: {
        DATE: '2024-10-26',
        jiguname: '인제원통_심부2',
        gennum: '737684',
        well: '암반',
        pyogo: '297.111',
        area: '한강권역',
        addr: '강원특별자치도 인제군 북면 원통리 626',
        LON: '128.19718056',
        LAT: '38.12079444',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-8.7',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.19718056, 38.12079444] }
    },
    {
      type: 'Feature',
      id: 747,
      properties: {
        DATE: '2024-10-26',
        jiguname: '인제인제',
        gennum: '84010',
        well: '암반',
        pyogo: '195.906',
        area: '한강권역',
        addr: '강원특별자치도 인제군 인제읍 남북리  475-2',
        LON: '128.16654167',
        LAT: '38.05655000',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-12.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [128.16654167, 38.05655] }
    },
    {
      type: 'Feature',
      id: 748,
      properties: {
        DATE: '2024-10-26',
        jiguname: '인제인제',
        gennum: '84011',
        well: '충적',
        pyogo: '195.913',
        area: '한강권역',
        addr: '강원특별자치도 인제군 인제읍 남북리  475-2',
        LON: '128.16654167',
        LAT: '38.05655000',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-6.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6.0-10.0'
      },
      geometry: { type: 'Point', coordinates: [128.16654167, 38.05655] }
    },
    {
      type: 'Feature',
      id: 749,
      properties: {
        DATE: '2024-10-26',
        jiguname: '인천국화_심부2',
        gennum: '771515',
        well: '암반',
        pyogo: '134.093',
        area: '한강권역',
        addr: '인천광역시 강화군 강화읍 국화리 산157-2 국화리학생야영장',
        LON: '126.44676667',
        LAT: '37.74025556',
        insdate: '2013-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-8.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.44676667, 37.74025556] }
    },
    {
      type: 'Feature',
      id: 750,
      properties: {
        DATE: '2024-10-26',
        jiguname: '인천국화_천부',
        gennum: '771516',
        well: '충적',
        pyogo: '134.093',
        area: '한강권역',
        addr: '인천광역시 강화군 강화읍 국화리 산157-2 국화리학생야영장',
        LON: '126.44676667',
        LAT: '37.74025556',
        insdate: '2013-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '13',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-13',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '7.0-13.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.44676667, 37.74025556] }
    },
    {
      type: 'Feature',
      id: 751,
      properties: {
        DATE: '2024-10-26',
        jiguname: '인천만수',
        gennum: '95503',
        well: '암반',
        pyogo: '7.85',
        area: '한강권역',
        addr: '인천광역시 남동구 만수동  811-4 서창톨게이트',
        LON: '126.73623333',
        LAT: '37.43394444',
        insdate: '2005-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-11.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-60.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '20.0-24.0'
      },
      geometry: { type: 'Point', coordinates: [126.73623333, 37.43394444] }
    },
    {
      type: 'Feature',
      id: 752,
      properties: {
        DATE: '2024-10-26',
        jiguname: '인천연수',
        gennum: '95504',
        well: '암반',
        pyogo: '10.03',
        area: '한강권역',
        addr: '인천광역시 연수구 연수동  594-1',
        LON: '126.67925833',
        LAT: '37.41528889',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-16.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '20.0-24.0'
      },
      geometry: { type: 'Point', coordinates: [126.67925833, 37.41528889] }
    },
    {
      type: 'Feature',
      id: 753,
      properties: {
        DATE: '2024-10-26',
        jiguname: '인천연수',
        gennum: '95505',
        well: '충적',
        pyogo: '10.03',
        area: '한강권역',
        addr: '인천광역시 연수구 연수동  594-1',
        LON: '126.67925833',
        LAT: '37.41528889',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '15',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-11.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-15',
        strHgt: '',
        strDia: '',
        strMat: '11.0-15.0'
      },
      geometry: { type: 'Point', coordinates: [126.67925833, 37.41528889] }
    },
    {
      type: 'Feature',
      id: 754,
      properties: {
        DATE: '2024-10-26',
        jiguname: '인천운서_심부2',
        gennum: '737686',
        well: '암반',
        pyogo: '61.571',
        area: '한강권역',
        addr: '인천광역시 중구 운서동  567-6',
        LON: '126.50860556',
        LAT: '37.49628611',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-4.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.50860556, 37.49628611] }
    },
    {
      type: 'Feature',
      id: 755,
      properties: {
        DATE: '2024-10-26',
        jiguname: '인천운서_천부',
        gennum: '737685',
        well: '충적',
        pyogo: '61.571',
        area: '한강권역',
        addr: '인천광역시 중구 운서동  567-6',
        LON: '126.50860556',
        LAT: '37.49628611',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.50860556, 37.49628611] }
    },
    {
      type: 'Feature',
      id: 756,
      properties: {
        DATE: '2024-10-26',
        jiguname: '인천하점',
        gennum: '65036',
        well: '암반',
        pyogo: '17.99',
        area: '한강권역',
        addr: '인천광역시 강화군 하점면 신봉리 617-2 하점면사무소',
        LON: '126.41163889',
        LAT: '37.77421944',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-9.5',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '46.0-50.0'
      },
      geometry: { type: 'Point', coordinates: [126.41163889, 37.77421944] }
    },
    {
      type: 'Feature',
      id: 757,
      properties: {
        DATE: '2024-10-26',
        jiguname: '임실관촌',
        gennum: '771366',
        well: '암반',
        pyogo: '217.7',
        area: '영섬권역',
        addr: '전북특별자치도 임실군 관촌면 관촌리 332-10',
        LON: '127.26986389',
        LAT: '35.67146111',
        insdate: '2021-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100.1',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0-8.5',
        cseExMat: '200',
        cseInHgt: 'SST',
        cseInDia: '0-100.1',
        cseInMat: '150',
        strHgt: 'pvc',
        strDia: '52-64',
        strMat: ' 68-80'
      },
      geometry: { type: 'Point', coordinates: [127.26986389, 35.67146111] }
    },
    {
      type: 'Feature',
      id: 758,
      properties: {
        DATE: '2024-10-26',
        jiguname: '임실덕치_신',
        gennum: '514701',
        well: '암반',
        pyogo: '196.6',
        area: '영섬권역',
        addr: '전북특별자치도 임실군 덕치면 장암리 산301',
        LON: '127.15245278',
        LAT: '35.47365556',
        insdate: '2011-03-17',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '120',
        cseExHgt: '300',
        cseExDia: '200',
        cseExMat: '',
        cseInHgt: '',
        cseInDia: 'STAINLESS STEEL(3T)',
        cseInMat: '0-120',
        strHgt: '',
        strDia: '',
        strMat: ''
      },
      geometry: { type: 'Point', coordinates: [127.15245278, 35.47365556] }
    },
    {
      type: 'Feature',
      id: 759,
      properties: {
        DATE: '2024-10-26',
        jiguname: '임실덕치_신',
        gennum: '514702',
        well: '충적',
        pyogo: '196.6',
        area: '영섬권역',
        addr: '전북특별자치도 임실군 덕치면 장암리 산301',
        LON: '127.15245278',
        LAT: '35.47365556',
        insdate: '2011-03-17',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '15',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '',
        cseInHgt: '',
        cseInDia: 'STAINLESS STEEL(3T)',
        cseInMat: '0-15',
        strHgt: '',
        strDia: '',
        strMat: ''
      },
      geometry: { type: 'Point', coordinates: [127.15245278, 35.47365556] }
    },
    {
      type: 'Feature',
      id: 760,
      properties: {
        DATE: '2024-10-26',
        jiguname: '임실쌍암_심부2',
        gennum: '769819',
        well: '암반',
        pyogo: '220',
        area: '영섬권역',
        addr: '전북특별자치도 임실군 운암면 쌍암리 617-5 운암초중학교',
        LON: '127.15788611',
        LAT: '35.66402222',
        insdate: '2011-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '68.0-80.0',
        strMat: ''
      },
      geometry: { type: 'Point', coordinates: [127.15788611, 35.66402222] }
    },
    {
      type: 'Feature',
      id: 761,
      properties: {
        DATE: '2024-10-26',
        jiguname: '임실쌍암_천부',
        gennum: '769820',
        well: '충적',
        pyogo: '220',
        area: '영섬권역',
        addr: '전북특별자치도 임실군 운암면 쌍암리 617-5 운암초중학교',
        LON: '127.15788611',
        LAT: '35.66402222',
        insdate: '2011-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '13',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-13.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '8.5-13.0',
        strMat: ''
      },
      geometry: { type: 'Point', coordinates: [127.15788611, 35.66402222] }
    },
    {
      type: 'Feature',
      id: 762,
      properties: {
        DATE: '2024-10-26',
        jiguname: '임실오수1',
        gennum: '514310',
        well: '암반',
        pyogo: '116.75',
        area: '영섬권역',
        addr: '전북특별자치도 임실군 오수면 오수리 148-5',
        LON: '127.32740278',
        LAT: '35.52761944',
        insdate: '2010-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '유성삼정개발㈜',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-18.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '24-28',
        strMat: ' 36.3-40.3'
      },
      geometry: { type: 'Point', coordinates: [127.32740278, 35.52761944] }
    },
    {
      type: 'Feature',
      id: 763,
      properties: {
        DATE: '2024-10-26',
        jiguname: '임실오수1',
        gennum: '514311',
        well: '충적',
        pyogo: '116.75',
        area: '영섬권역',
        addr: '전북특별자치도 임실군 오수면 오수리 148-5',
        LON: '127.32740278',
        LAT: '35.52761944',
        insdate: '2010-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '유성삼정개발㈜',
        gulDiaTop: '15',
        gulDiaBtm: '400',
        cseExHgt: '300',
        cseExDia: '0.0-6.0',
        cseExMat: '300',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-15.0',
        cseInMat: '200',
        strHgt: 'STAINLESS STEEL',
        strDia: '6.0-9.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [127.32740278, 35.52761944] }
    },
    {
      type: 'Feature',
      id: 764,
      properties: {
        DATE: '2024-10-26',
        jiguname: '임실오수2',
        gennum: '737688',
        well: '암반',
        pyogo: '123.718',
        area: '영섬권역',
        addr: '전북특별자치도 임실군 오수면 오수리 33',
        LON: '127.33301667',
        LAT: '35.54558333',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-12.8',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.33301667, 35.54558333] }
    },
    {
      type: 'Feature',
      id: 765,
      properties: {
        DATE: '2024-10-26',
        jiguname: '임실오수2',
        gennum: '737687',
        well: '충적',
        pyogo: '123.971',
        area: '영섬권역',
        addr: '전북특별자치도 임실군 오수면 오수리 33',
        LON: '127.33301667',
        LAT: '35.54558333',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.33301667, 35.54558333] }
    },
    {
      type: 'Feature',
      id: 766,
      properties: {
        DATE: '2024-10-26',
        jiguname: '임실임실',
        gennum: '9906',
        well: '암반',
        pyogo: '222.2',
        area: '영섬권역',
        addr: '전북특별자치도 임실군 임실읍 갈마리 290-6',
        LON: '127.29331667',
        LAT: '35.62617778',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '69',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-10.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-69.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [127.29331667, 35.62617778] }
    },
    {
      type: 'Feature',
      id: 767,
      properties: {
        DATE: '2024-10-26',
        jiguname: '장성남면',
        gennum: '73528',
        well: '암반',
        pyogo: '40.99',
        area: '영섬권역',
        addr: '전라남도 장성군 남면 분향리 360-4',
        LON: '126.81299167',
        LAT: '35.24734444',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-29.0',
        cseInHgt: '250',
        cseInDia: '백관',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.81299167, 35.24734444] }
    },
    {
      type: 'Feature',
      id: 768,
      properties: {
        DATE: '2024-10-26',
        jiguname: '장성남면',
        gennum: '73529',
        well: '충적',
        pyogo: '40.99',
        area: '영섬권역',
        addr: '전라남도 장성군 남면 분향리 360-4',
        LON: '126.81299167',
        LAT: '35.24734444',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '14',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-14.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-14',
        strHgt: '',
        strDia: '',
        strMat: '10.0-14.0'
      },
      geometry: { type: 'Point', coordinates: [126.81299167, 35.24734444] }
    },
    {
      type: 'Feature',
      id: 769,
      properties: {
        DATE: '2024-10-26',
        jiguname: '장성북이',
        gennum: '82037',
        well: '암반',
        pyogo: '84.893',
        area: '영섬권역',
        addr: '전라남도 장성군 북이면 모현리 581-86',
        LON: '126.80092778',
        LAT: '35.40238889',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-14',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'P.V.C',
        strMat: '24.0-28.0'
      },
      geometry: { type: 'Point', coordinates: [126.80092778, 35.40238889] }
    },
    {
      type: 'Feature',
      id: 770,
      properties: {
        DATE: '2024-10-26',
        jiguname: '장성북이',
        gennum: '82038',
        well: '충적',
        pyogo: '84.877',
        area: '영섬권역',
        addr: '전라남도 장성군 북이면 모현리 581-86',
        LON: '126.80092778',
        LAT: '35.40238889',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0-6',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6.0-10.0'
      },
      geometry: { type: 'Point', coordinates: [126.80092778, 35.40238889] }
    },
    {
      type: 'Feature',
      id: 771,
      properties: {
        DATE: '2024-10-26',
        jiguname: '장성분향_심부2',
        gennum: '769821',
        well: '암반',
        pyogo: '35.1',
        area: '영섬권역',
        addr: '전라남도 장성군 남면 분향리 335 장성남중학교',
        LON: '126.81228056',
        LAT: '35.24331111',
        insdate: '2010-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '165',
        cseExHgt: '165',
        cseExDia: '0.0-27.0',
        cseExMat: '165',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '63.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.81228056, 35.24331111] }
    },
    {
      type: 'Feature',
      id: 772,
      properties: {
        DATE: '2024-10-26',
        jiguname: '장성분향_천부',
        gennum: '769822',
        well: '충적',
        pyogo: '35.1',
        area: '영섬권역',
        addr: '전라남도 장성군 남면 분향리 335 장성남중학교',
        LON: '126.81228056',
        LAT: '35.24331111',
        insdate: '2010-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '11.5',
        gulDiaBtm: '165',
        cseExHgt: '165',
        cseExDia: '',
        cseExMat: '165',
        cseInHgt: '강관',
        cseInDia: '0.0-11.5',
        cseInMat: '100',
        strHgt: '',
        strDia: '5.5-11.5',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.81228056, 35.24331111] }
    },
    {
      type: 'Feature',
      id: 773,
      properties: {
        DATE: '2024-10-26',
        jiguname: '장성삼계',
        gennum: '622082',
        well: '암반',
        pyogo: '228.48',
        area: '영섬권역',
        addr: '전라남도 장성군 삼계면 부성리 산127-3 전라남도 장성군 삼계면 부성리 산127-3번지',
        LON: '126.62027778',
        LAT: '35.30055556',
        insdate: '2014-11-28',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국건설기술연구원',
        gulDiaTop: '176',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-3.0',
        cseExMat: '200',
        cseInHgt: '아연도강관',
        cseInDia: '0.0-176.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '128-132',
        strMat: ' 136-140'
      },
      geometry: { type: 'Point', coordinates: [126.62027778, 35.30055556] }
    },
    {
      type: 'Feature',
      id: 774,
      properties: {
        DATE: '2024-10-26',
        jiguname: '장성아곡_심부2',
        gennum: '771517',
        well: '암반',
        pyogo: '92',
        area: '영섬권역',
        addr: '전라남도 장성군 황룡면 아곡리 333-1 홍길동테마파크',
        LON: '126.72875278',
        LAT: '35.31679167',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-22.1',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.72875278, 35.31679167] }
    },
    {
      type: 'Feature',
      id: 775,
      properties: {
        DATE: '2024-10-26',
        jiguname: '장성아곡_천부',
        gennum: '771518',
        well: '충적',
        pyogo: '92',
        area: '영섬권역',
        addr: '전라남도 장성군 황룡면 아곡리 333-1 홍길동테마파크',
        LON: '126.72875278',
        LAT: '35.31679167',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.72875278, 35.31679167] }
    },
    {
      type: 'Feature',
      id: 776,
      properties: {
        DATE: '2024-10-26',
        jiguname: '장성약수_심부2',
        gennum: '771519',
        well: '암반',
        pyogo: '139',
        area: '영섬권역',
        addr: '전라남도 장성군 북하면 약수리 146 백양탐방지원센터',
        LON: '126.87965278',
        LAT: '35.42993333',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-2.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '70.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.87965278, 35.42993333] }
    },
    {
      type: 'Feature',
      id: 777,
      properties: {
        DATE: '2024-10-26',
        jiguname: '장성약수_천부',
        gennum: '771520',
        well: '충적',
        pyogo: '139',
        area: '영섬권역',
        addr: '전라남도 장성군 북하면 약수리 146 백양탐방지원센터',
        LON: '126.87965278',
        LAT: '35.42993333',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '2.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.87965278, 35.42993333] }
    },
    {
      type: 'Feature',
      id: 778,
      properties: {
        DATE: '2024-10-26',
        jiguname: '장성영천_심부2',
        gennum: '771521',
        well: '암반',
        pyogo: '194',
        area: '영섬권역',
        addr: '전라남도 장성군 장성읍 영천리 891 장성여자중학교',
        LON: '126.78553611',
        LAT: '35.29710278',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-27.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '70.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.78553611, 35.29710278] }
    },
    {
      type: 'Feature',
      id: 779,
      properties: {
        DATE: '2024-10-26',
        jiguname: '장성영천_천부',
        gennum: '771522',
        well: '충적',
        pyogo: '194',
        area: '영섬권역',
        addr: '전라남도 장성군 장성읍 영천리 891 장성여자중학교',
        LON: '126.78553611',
        LAT: '35.29710278',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '20',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-20',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '10.0-20.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.78553611, 35.29710278] }
    },
    {
      type: 'Feature',
      id: 780,
      properties: {
        DATE: '2024-10-26',
        jiguname: '장수번암',
        gennum: '73524',
        well: '암반',
        pyogo: '312.36',
        area: '영섬권역',
        addr: '전북특별자치도 장수군 번암면 죽산리 589-4',
        LON: '127.49711111',
        LAT: '35.54823056',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-6.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.49711111, 35.54823056] }
    },
    {
      type: 'Feature',
      id: 781,
      properties: {
        DATE: '2024-10-26',
        jiguname: '장수비룡_심부2',
        gennum: '769823',
        well: '암반',
        pyogo: '562.3',
        area: '금강권역',
        addr: '전북특별자치도 장수군 천천면 비룡리 산49-10 와룡자연휴양림',
        LON: '127.47828611',
        LAT: '35.68057222',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '82',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-82.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '76.0-82.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.47828611, 35.68057222] }
    },
    {
      type: 'Feature',
      id: 782,
      properties: {
        DATE: '2024-10-26',
        jiguname: '장수비룡_천부',
        gennum: '769824',
        well: '충적',
        pyogo: '562.3',
        area: '금강권역',
        addr: '전북특별자치도 장수군 천천면 비룡리 산49-10 와룡자연휴양림',
        LON: '127.47828611',
        LAT: '35.68057222',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.47828611, 35.68057222] }
    },
    {
      type: 'Feature',
      id: 783,
      properties: {
        DATE: '2024-10-26',
        jiguname: '장수산서',
        gennum: '9907',
        well: '암반',
        pyogo: '147.4',
        area: '영섬권역',
        addr: '전북특별자치도 장수군 산서면 신창리 801 산서체육공원 내',
        LON: '127.39258889',
        LAT: '35.58329444',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '69',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-12.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-69.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [127.39258889, 35.58329444] }
    },
    {
      type: 'Feature',
      id: 784,
      properties: {
        DATE: '2024-10-26',
        jiguname: '장수산서',
        gennum: '9908',
        well: '충적',
        pyogo: '147.38',
        area: '영섬권역',
        addr: '전북특별자치도 장수군 산서면 신창리 801 산서체육공원 내',
        LON: '127.39258889',
        LAT: '35.58329444',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '10.5',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-10.5',
        cseExMat: '250',
        cseInHgt: '백관',
        cseInDia: '0-10.5',
        cseInMat: '',
        strHgt: '',
        strDia: '8.5-10.5',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [127.39258889, 35.58329444] }
    },
    {
      type: 'Feature',
      id: 785,
      properties: {
        DATE: '2024-10-26',
        jiguname: '장수어전_심부2',
        gennum: '737690',
        well: '암반',
        pyogo: '488.3',
        area: '금강권역',
        addr: '전북특별자치도 장수군 계북면 어전리 737-2',
        LON: '127.61529444',
        LAT: '35.78765833',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '85',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-20.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-85.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '68.0-85.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.61529444, 35.78765833] }
    },
    {
      type: 'Feature',
      id: 786,
      properties: {
        DATE: '2024-10-26',
        jiguname: '장수어전_천부',
        gennum: '737689',
        well: '충적',
        pyogo: '488.3',
        area: '금강권역',
        addr: '전북특별자치도 장수군 계북면 어전리 737-2',
        LON: '127.61529444',
        LAT: '35.78765833',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '5.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.61529444, 35.78765833] }
    },
    {
      type: 'Feature',
      id: 787,
      properties: {
        DATE: '2024-10-26',
        jiguname: '장수장수',
        gennum: '65073',
        well: '암반',
        pyogo: '416.62',
        area: '금강권역',
        addr: '전북특별자치도 장수군 장수읍 송천리 89-1번지',
        LON: '127.51376667',
        LAT: '35.64198333',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-13.7',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.51376667, 35.64198333] }
    },
    {
      type: 'Feature',
      id: 788,
      properties: {
        DATE: '2024-10-26',
        jiguname: '장수장수',
        gennum: '65074',
        well: '충적',
        pyogo: '416.62',
        area: '금강권역',
        addr: '전북특별자치도 장수군 장수읍 송천리 89-1',
        LON: '127.51376667',
        LAT: '35.64198333',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '13',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-9.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-13',
        strHgt: '',
        strDia: '',
        strMat: '9.0-13.0'
      },
      geometry: { type: 'Point', coordinates: [127.51376667, 35.64198333] }
    },
    {
      type: 'Feature',
      id: 789,
      properties: {
        DATE: '2024-10-26',
        jiguname: '장흥신월_심부2',
        gennum: '771523',
        well: '암반',
        pyogo: '124',
        area: '영섬권역',
        addr: '전라남도 장흥군 유치면 신월리 441-2 유치자연휴양림',
        LON: '126.83313889',
        LAT: '34.76720556',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-4.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '70.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.83313889, 34.76720556] }
    },
    {
      type: 'Feature',
      id: 790,
      properties: {
        DATE: '2024-10-26',
        jiguname: '장흥신월_천부',
        gennum: '771524',
        well: '충적',
        pyogo: '124',
        area: '영섬권역',
        addr: '전라남도 장흥군 유치면 신월리 441-2 유치자연휴양림',
        LON: '126.83313889',
        LAT: '34.76720556',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '2.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.83313889, 34.76720556] }
    },
    {
      type: 'Feature',
      id: 791,
      properties: {
        DATE: '2024-10-26',
        jiguname: '장흥장흥',
        gennum: '65037',
        well: '암반',
        pyogo: '23.984',
        area: '영섬권역',
        addr: '전라남도 장흥군 장흥읍 연산리 170-10 취수장',
        LON: '126.89107222',
        LAT: '34.68853889',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '64',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-13.5',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0.0-64.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '40.0-44.0'
      },
      geometry: { type: 'Point', coordinates: [126.89107222, 34.68853889] }
    },
    {
      type: 'Feature',
      id: 792,
      properties: {
        DATE: '2024-10-26',
        jiguname: '장흥장흥',
        gennum: '65038',
        well: '충적',
        pyogo: '23.98',
        area: '영섬권역',
        addr: '전라남도 장흥군 장흥읍 연산리 170-10 취수장',
        LON: '126.89107222',
        LAT: '34.68853889',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-10.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6.0-10.0'
      },
      geometry: { type: 'Point', coordinates: [126.89107222, 34.68853889] }
    },
    {
      type: 'Feature',
      id: 793,
      properties: {
        DATE: '2024-10-26',
        jiguname: '전주만성',
        gennum: '11766',
        well: '암반',
        pyogo: '36.31',
        area: '금강권역',
        addr: '전북특별자치도 전주시덕진구 만성동  832 학생인권교육센터 내',
        LON: '127.07214722',
        LAT: '35.83059167',
        insdate: '1999-12-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '68',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-15.9',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0.0-68.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '46.0-58.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [127.07214722, 35.83059167] }
    },
    {
      type: 'Feature',
      id: 794,
      properties: {
        DATE: '2024-10-26',
        jiguname: '전주만성',
        gennum: '11767',
        well: '충적',
        pyogo: '36.31',
        area: '금강권역',
        addr: '전북특별자치도 전주시덕진구 만성동  832 학생인권교육센터 내',
        LON: '127.07214722',
        LAT: '35.83059167',
        insdate: '1999-12-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '12',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-7.0',
        cseExMat: ' 11.0-12.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL PIPE',
        cseInMat: '0-12',
        strHgt: '',
        strDia: '',
        strMat: '7.0-11.0'
      },
      geometry: { type: 'Point', coordinates: [127.07214722, 35.83059167] }
    },
    {
      type: 'Feature',
      id: 795,
      properties: {
        DATE: '2024-10-26',
        jiguname: '전주중인_심부2',
        gennum: '737692',
        well: '암반',
        pyogo: '62.21',
        area: '금강권역',
        addr: '전북특별자치도 전주시 완산구 중인동  1562',
        LON: '127.10037500',
        LAT: '35.76723889',
        insdate: '2012-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '85',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-6.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-85.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '61.0-85.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.100375, 35.76723889] }
    },
    {
      type: 'Feature',
      id: 796,
      properties: {
        DATE: '2024-10-26',
        jiguname: '전주중인_천부',
        gennum: '737691',
        well: '충적',
        pyogo: '62.21',
        area: '금강권역',
        addr: '전북특별자치도 전주시 완산구 중인동  1562',
        LON: '127.10037500',
        LAT: '35.76723889',
        insdate: '2012-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.100375, 35.76723889] }
    },
    {
      type: 'Feature',
      id: 797,
      properties: {
        DATE: '2024-10-26',
        jiguname: '정선낙천',
        gennum: '771354',
        well: '암반',
        pyogo: '494',
        area: '한강권역',
        addr: '강원특별자치도 정선군 임계면 낙천리 산1-6',
        LON: '128.84855556',
        LAT: '37.47305556',
        insdate: '2022-01-12',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0-12',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0-100',
        cseInMat: '150',
        strHgt: 'PVC(VG1)',
        strDia: '50-54',
        strMat: ' 60-64'
      },
      geometry: { type: 'Point', coordinates: [128.84855556, 37.47305556] }
    },
    {
      type: 'Feature',
      id: 798,
      properties: {
        DATE: '2024-10-26',
        jiguname: '정선남면',
        gennum: '771355',
        well: '암반',
        pyogo: '432',
        area: '한강권역',
        addr: '강원특별자치도 정선군 남면 문곡리 144-4',
        LON: '128.74266667',
        LAT: '37.26647222',
        insdate: '2022-01-12',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '112',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0-12',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0-112',
        cseInMat: '150',
        strHgt: 'PVC(VG1)',
        strDia: '58-62',
        strMat: ' 66-70'
      },
      geometry: { type: 'Point', coordinates: [128.74266667, 37.26647222] }
    },
    {
      type: 'Feature',
      id: 799,
      properties: {
        DATE: '2024-10-26',
        jiguname: '정선동면',
        gennum: '82007',
        well: '암반',
        pyogo: '401.964',
        area: '한강권역',
        addr: '강원특별자치도 정선군 화암면 석곡리 381 정선 청소년 수련장',
        LON: '128.75610278',
        LAT: '37.35121944',
        insdate: '2003-05-30',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-7.0',
        cseInHgt: '250',
        cseInDia: 'STAINLES STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P.V.C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [128.75610278, 37.35121944] }
    },
    {
      type: 'Feature',
      id: 800,
      properties: {
        DATE: '2024-10-26',
        jiguname: '정선여량',
        gennum: '771353',
        well: '암반',
        pyogo: '444',
        area: '한강권역',
        addr: '강원특별자치도 정선군 여량면 고양리 504',
        LON: '128.72319444',
        LAT: '37.44797222',
        insdate: '2022-01-12',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '72',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0-12',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0-72',
        cseInMat: '150',
        strHgt: 'PVC(VG1)',
        strDia: '34-38',
        strMat: ' 40-44'
      },
      geometry: { type: 'Point', coordinates: [128.72319444, 37.44797222] }
    },
    {
      type: 'Feature',
      id: 801,
      properties: {
        DATE: '2024-10-26',
        jiguname: '정선임계',
        gennum: '637539',
        well: '암반',
        pyogo: '483.283',
        area: '한강권역',
        addr: '강원특별자치도 정선군 임계면 고양리 221-1 1',
        LON: '128.80601111',
        LAT: '37.43883611',
        insdate: '2017-11-30',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '(주)유신',
        gulDiaTop: '198',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-9.0',
        cseExMat: '200',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '5.0-80.0',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [128.80601111, 37.43883611] }
    },
    {
      type: 'Feature',
      id: 802,
      properties: {
        DATE: '2024-10-26',
        jiguname: '정선정선',
        gennum: '73507',
        well: '암반',
        pyogo: '347.903',
        area: '한강권역',
        addr: '강원특별자치도 정선군 정선읍 덕송리 626-2',
        LON: '128.66679167',
        LAT: '37.39336944',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-38.5',
        cseInHgt: '250',
        cseInDia: '백관',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'PVC',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [128.66679167, 37.39336944] }
    },
    {
      type: 'Feature',
      id: 803,
      properties: {
        DATE: '2024-10-26',
        jiguname: '정선정선',
        gennum: '73508',
        well: '충적',
        pyogo: '347.054',
        area: '한강권역',
        addr: '강원특별자치도 정선군 정선읍 덕송리 626-2',
        LON: '128.66679167',
        LAT: '37.39336944',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '18',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0-18',
        cseInHgt: '200',
        cseInDia: '백관',
        cseInMat: '0-18',
        strHgt: '',
        strDia: '',
        strMat: '14-18'
      },
      geometry: { type: 'Point', coordinates: [128.66679167, 37.39336944] }
    },
    {
      type: 'Feature',
      id: 804,
      properties: {
        DATE: '2024-10-26',
        jiguname: '정선화암_심부2',
        gennum: '771525',
        well: '암반',
        pyogo: '453.7',
        area: '한강권역',
        addr: '강원특별자치도 정선군 화암면 화암리 393-1 화암면사무소',
        LON: '128.78690278',
        LAT: '37.33978889',
        insdate: '2007-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-11.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '65.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.78690278, 37.33978889] }
    },
    {
      type: 'Feature',
      id: 805,
      properties: {
        DATE: '2024-10-26',
        jiguname: '정선화암_천부',
        gennum: '771526',
        well: '충적',
        pyogo: '453.8',
        area: '한강권역',
        addr: '강원특별자치도 정선군 화암면 화암리 393-1 화암면사무소',
        LON: '128.78690278',
        LAT: '37.33978889',
        insdate: '2007-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '5.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.78690278, 37.33978889] }
    },
    {
      type: 'Feature',
      id: 806,
      properties: {
        DATE: '2024-10-26',
        jiguname: '정읍산외',
        gennum: '405433',
        well: '암반',
        pyogo: '48',
        area: '금강권역',
        addr: '전북특별자치도 정읍시 산외면 평사리 755-1',
        LON: '127.03630833',
        LAT: '35.62193056',
        insdate: '2009-12-14',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜해천 E.T.S',
        gulDiaTop: '120',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-14.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-120.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '52-56',
        strMat: ' 64-68'
      },
      geometry: { type: 'Point', coordinates: [127.03630833, 35.62193056] }
    },
    {
      type: 'Feature',
      id: 807,
      properties: {
        DATE: '2024-10-26',
        jiguname: '정읍상평',
        gennum: '82032',
        well: '암반',
        pyogo: '27.142',
        area: '금강권역',
        addr: '전북특별자치도 정읍시 상평동  697-1',
        LON: '126.82444722',
        LAT: '35.56339722',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-5',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.82444722, 35.56339722] }
    },
    {
      type: 'Feature',
      id: 808,
      properties: {
        DATE: '2024-10-26',
        jiguname: '정읍신태인',
        gennum: '65075',
        well: '암반',
        pyogo: '6.825',
        area: '금강권역',
        addr: '전북특별자치도 정읍시 신태인읍 구석리 439-30 신태인 실내체육관',
        LON: '126.89133611',
        LAT: '35.67933611',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-17.6',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.89133611, 35.67933611] }
    },
    {
      type: 'Feature',
      id: 809,
      properties: {
        DATE: '2024-10-26',
        jiguname: '정읍신태인',
        gennum: '65076',
        well: '충적',
        pyogo: '6.8',
        area: '금강권역',
        addr: '전북특별자치도 정읍시 신태인읍 구석리 439-30',
        LON: '126.89133611',
        LAT: '35.67933611',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '13.5',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-9.5',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-13.5',
        strHgt: '',
        strDia: '',
        strMat: '9.5-13.5'
      },
      geometry: { type: 'Point', coordinates: [126.89133611, 35.67933611] }
    },
    {
      type: 'Feature',
      id: 810,
      properties: {
        DATE: '2024-10-26',
        jiguname: '정읍옹동_신',
        gennum: '65607',
        well: '암반',
        pyogo: '22.97',
        area: '금강권역',
        addr: '전북특별자치도 정읍시 옹동면 산성리 529-6 수자원공사 정읍권관리단 앞',
        LON: '126.97128056',
        LAT: '35.61857500',
        insdate: '2000-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-12.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.97128056, 35.618575] }
    },
    {
      type: 'Feature',
      id: 811,
      properties: {
        DATE: '2024-10-26',
        jiguname: '정읍옹동_신',
        gennum: '65608',
        well: '충적',
        pyogo: '22.92',
        area: '금강권역',
        addr: '전북특별자치도 정읍시 옹동면 산성리 529-6 수자원공사 정읍권관리단 앞',
        LON: '126.97128056',
        LAT: '35.61857500',
        insdate: '2000-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '11',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-11.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-11',
        strHgt: '',
        strDia: '',
        strMat: '7.0-11.0'
      },
      geometry: { type: 'Point', coordinates: [126.97128056, 35.618575] }
    },
    {
      type: 'Feature',
      id: 812,
      properties: {
        DATE: '2024-10-26',
        jiguname: '정읍입암',
        gennum: '718275',
        well: '암반',
        pyogo: '63.88',
        area: '금강권역',
        addr: '전북특별자치도 정읍시 입암면 신면리 1027-6',
        LON: '126.81191667',
        LAT: '35.50625278',
        insdate: '2018-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '(주)어스이엔지',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-27.0',
        cseExMat: '200',
        cseInHgt: 'Stainless Steel',
        cseInDia: '0.0-100',
        cseInMat: '150',
        strHgt: 'P.V.C(VG1)',
        strDia: '68-100',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [126.81191667, 35.50625278] }
    },
    {
      type: 'Feature',
      id: 813,
      properties: {
        DATE: '2024-10-26',
        jiguname: '제주노형',
        gennum: '95534',
        well: '암반',
        pyogo: '271.593',
        area: '영섬권역',
        addr: '제주특별자치도 제주시 노형동  산9',
        LON: '126.48250000',
        LAT: '33.44635833',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '400',
        cseExHgt: '350',
        cseExDia: '150',
        cseExMat: '0-75',
        cseInHgt: '350',
        cseInDia: '시멘트 몰탈',
        cseInMat: '0-175',
        strHgt: '200',
        strDia: 'PVC',
        strMat: '175-330'
      },
      geometry: { type: 'Point', coordinates: [126.4825, 33.44635833] }
    },
    {
      type: 'Feature',
      id: 814,
      properties: {
        DATE: '2024-10-26',
        jiguname: '제주동홍',
        gennum: '95535',
        well: '암반',
        pyogo: '187.704',
        area: '영섬권역',
        addr: '제주특별자치도 서귀포시 동홍동  945',
        LON: '126.56681944',
        LAT: '33.27546389',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '340',
        cseExHgt: '350',
        cseExDia: '150',
        cseExMat: '0-51',
        cseInHgt: ' 51-70',
        cseInDia: '350',
        cseInMat: '시멘트 몰탈',
        strHgt: '0-118',
        strDia: '200',
        strMat: 'PVC'
      },
      geometry: { type: 'Point', coordinates: [126.56681944, 33.27546389] }
    },
    {
      type: 'Feature',
      id: 815,
      properties: {
        DATE: '2024-10-26',
        jiguname: '제주조천',
        gennum: '95537',
        well: '암반',
        pyogo: '342.604',
        area: '영섬권역',
        addr: '제주특별자치도 제주시 조천읍 교래리 11-3',
        LON: '126.72483611',
        LAT: '33.42769722',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '490',
        cseExHgt: '350',
        cseExDia: '150',
        cseExMat: '0-51',
        cseInHgt: ' 51-101',
        cseInDia: '350',
        cseInMat: '시멘트 몰탈',
        strHgt: '0-258',
        strDia: '200',
        strMat: 'PVC'
      },
      geometry: { type: 'Point', coordinates: [126.72483611, 33.42769722] }
    },
    {
      type: 'Feature',
      id: 816,
      properties: {
        DATE: '2024-10-26',
        jiguname: '제주한경',
        gennum: '95536',
        well: '암반',
        pyogo: '139.809',
        area: '영섬권역',
        addr: '제주특별자치도 제주시 한경면 저지리 2417',
        LON: '126.27064167',
        LAT: '33.32237500',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '400',
        cseExHgt: '350',
        cseExDia: '150',
        cseExMat: '0-50',
        cseInHgt: ' 50-70',
        cseInDia: '350',
        cseInMat: 'PVC',
        strHgt: '0-92',
        strDia: '200',
        strMat: 'PVC'
      },
      geometry: { type: 'Point', coordinates: [126.27064167, 33.322375] }
    },
    {
      type: 'Feature',
      id: 817,
      properties: {
        DATE: '2024-10-26',
        jiguname: '제천고암',
        gennum: '11752',
        well: '암반',
        pyogo: '267.09',
        area: '한강권역',
        addr: '충청북도 제천시 고암동  산44-8 고암정수장 부지내',
        LON: '128.24718611',
        LAT: '37.17184167',
        insdate: '1996-10-23',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-29.9',
        cseInHgt: '250',
        cseInDia: 'STEEL PIPE',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '36.0-48.0'
      },
      geometry: { type: 'Point', coordinates: [128.24718611, 37.17184167] }
    },
    {
      type: 'Feature',
      id: 818,
      properties: {
        DATE: '2024-10-26',
        jiguname: '제천고암',
        gennum: '11753',
        well: '충적',
        pyogo: '267.09',
        area: '한강권역',
        addr: '충청북도 제천시 고암동  산44-8 고암정수장 부지내',
        LON: '128.24718611',
        LAT: '37.17184167',
        insdate: '1996-10-23',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '11.4',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-6.4',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL PIPE',
        cseInMat: '0-11.4',
        strHgt: '',
        strDia: '',
        strMat: '6.4-10.4'
      },
      geometry: { type: 'Point', coordinates: [128.24718611, 37.17184167] }
    },
    {
      type: 'Feature',
      id: 819,
      properties: {
        DATE: '2024-10-26',
        jiguname: '제천금성',
        gennum: '601662',
        well: '암반',
        pyogo: '147.14',
        area: '한강권역',
        addr: '충청북도 제천시 금성면 중전리 165',
        LON: '128.18722222',
        LAT: '37.05722222',
        insdate: '2011-12-02',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜한국건업엔지니어링',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-11.5',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '36-40',
        strMat: ' 52-56'
      },
      geometry: { type: 'Point', coordinates: [128.18722222, 37.05722222] }
    },
    {
      type: 'Feature',
      id: 820,
      properties: {
        DATE: '2024-10-26',
        jiguname: '제천덕산',
        gennum: '793614',
        well: '암반',
        pyogo: '248.12',
        area: '',
        addr: '충청북도 제천시 덕산면 신현리 20-3',
        LON: '128.15265556',
        LAT: '36.92494444',
        insdate: '2023-12-01',
        manager: '',
        builder: '',
        gulDep: '120',
        gulDiaTop: '300',
        gulDiaBtm: '200',
        cseExHgt: '0.0-7.0',
        cseExDia: '200',
        cseExMat: 'STEEL PIPE',
        cseInHgt: '0.0-120.0',
        cseInDia: '150',
        cseInMat: 'P. V. C',
        strHgt: '66.0-90.0m',
        strDia: ' 106.0-114.0',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [128.15265556, 36.92494444] }
    },
    {
      type: 'Feature',
      id: 821,
      properties: {
        DATE: '2024-10-26',
        jiguname: '제천청풍',
        gennum: '65077',
        well: '암반',
        pyogo: '195.2',
        area: '한강권역',
        addr: '충청북도 제천시 청풍면 물태리 133번지 청풍면사무소',
        LON: '128.16863056',
        LAT: '37.00098056',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-4.5',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [128.16863056, 37.00098056] }
    },
    {
      type: 'Feature',
      id: 822,
      properties: {
        DATE: '2024-10-26',
        jiguname: '제천평동_심부2',
        gennum: '771527',
        well: '암반',
        pyogo: '217.76',
        area: '한강권역',
        addr: '충청북도 제천시 백운면 평동리 600 백운초등학교',
        LON: '128.02110556',
        LAT: '37.14116111',
        insdate: '2011-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-3.6',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '62.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.02110556, 37.14116111] }
    },
    {
      type: 'Feature',
      id: 823,
      properties: {
        DATE: '2024-10-26',
        jiguname: '제천평동_천부',
        gennum: '771528',
        well: '충적',
        pyogo: '217.76',
        area: '한강권역',
        addr: '충청북도 제천시 백운면 평동리 600 백운초등학교',
        LON: '128.02110556',
        LAT: '37.14116111',
        insdate: '2011-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '5.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.02110556, 37.14116111] }
    },
    {
      type: 'Feature',
      id: 824,
      properties: {
        DATE: '2024-10-26',
        jiguname: '증평증평',
        gennum: '65012',
        well: '암반',
        pyogo: '95.97',
        area: '금강권역',
        addr: '충청북도 증평군 증평읍 덕상리 729',
        LON: '127.62367222',
        LAT: '36.74795278',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '71',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-24.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0.0-71.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '48.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [127.62367222, 36.74795278] }
    },
    {
      type: 'Feature',
      id: 825,
      properties: {
        DATE: '2024-10-26',
        jiguname: '증평증평',
        gennum: '65013',
        well: '충적',
        pyogo: '96.17',
        area: '금강권역',
        addr: '충청북도 증평군 증평읍 덕상리 729',
        LON: '127.62367222',
        LAT: '36.74795278',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '20',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-20.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0-20',
        strHgt: '',
        strDia: '',
        strMat: '16.0-20.0'
      },
      geometry: { type: 'Point', coordinates: [127.62367222, 36.74795278] }
    },
    {
      type: 'Feature',
      id: 826,
      properties: {
        DATE: '2024-10-26',
        jiguname: '진도군내',
        gennum: '778275',
        well: '암반',
        pyogo: '4.3',
        area: '영섬권역',
        addr: '전라남도 진도군 군내면 분토리 1195',
        LON: '126.28040556',
        LAT: '34.51079722',
        insdate: '2022-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '102',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-10.0',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0.0-102',
        cseInMat: '150',
        strHgt: 'P.V.C(VG1)',
        strDia: '50-80',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [126.28040556, 34.51079722] }
    },
    {
      type: 'Feature',
      id: 827,
      properties: {
        DATE: '2024-10-26',
        jiguname: '진도석교_심부2',
        gennum: '737694',
        well: '암반',
        pyogo: '5.795',
        area: '영섬권역',
        addr: '전라남도 진도군 임회면 석교리 293-1',
        LON: '126.19088333',
        LAT: '34.40625278',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-9.1',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '68.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.19088333, 34.40625278] }
    },
    {
      type: 'Feature',
      id: 828,
      properties: {
        DATE: '2024-10-26',
        jiguname: '진도석교_천부',
        gennum: '737693',
        well: '충적',
        pyogo: '5.844',
        area: '영섬권역',
        addr: '전라남도 진도군 임회면 석교리 293-1',
        LON: '126.19088333',
        LAT: '34.40625278',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-7.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.19088333, 34.40625278] }
    },
    {
      type: 'Feature',
      id: 829,
      properties: {
        DATE: '2024-10-26',
        jiguname: '진도의신',
        gennum: '95528',
        well: '암반',
        pyogo: '14.75',
        area: '영섬권역',
        addr: '전라남도 진도군 의신면 돈지리 산71-4',
        LON: '126.27943611',
        LAT: '34.43232222',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-12.6',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '24.0-28.0'
      },
      geometry: { type: 'Point', coordinates: [126.27943611, 34.43232222] }
    },
    {
      type: 'Feature',
      id: 830,
      properties: {
        DATE: '2024-10-26',
        jiguname: '진안덕현_심부2',
        gennum: '771529',
        well: '암반',
        pyogo: '364',
        area: '영섬권역',
        addr: '전북특별자치도 진안군 백운면 덕현리 649 전북산림환경연구소',
        LON: '127.38079167',
        LAT: '35.68413889',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-11.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.38079167, 35.68413889] }
    },
    {
      type: 'Feature',
      id: 831,
      properties: {
        DATE: '2024-10-26',
        jiguname: '진안덕현_천부',
        gennum: '771530',
        well: '충적',
        pyogo: '364',
        area: '영섬권역',
        addr: '전북특별자치도 진안군 백운면 덕현리 649 전북산림환경연구소',
        LON: '127.38079167',
        LAT: '35.68413889',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.38079167, 35.68413889] }
    },
    {
      type: 'Feature',
      id: 832,
      properties: {
        DATE: '2024-10-26',
        jiguname: '진안마령',
        gennum: '65039',
        well: '암반',
        pyogo: '279.65',
        area: '영섬권역',
        addr: '전북특별자치도 진안군 마령면 평지리 1004-2 면사무소',
        LON: '127.37089167',
        LAT: '35.73457500',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '71',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-12.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0.0-71.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '48.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [127.37089167, 35.734575] }
    },
    {
      type: 'Feature',
      id: 833,
      properties: {
        DATE: '2024-10-26',
        jiguname: '진안송풍_심부2',
        gennum: '737696',
        well: '암반',
        pyogo: '217.9',
        area: '금강권역',
        addr: '전북특별자치도 진안군 용담면 송풍리 1182-3',
        LON: '127.52233611',
        LAT: '35.95328611',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-14.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.52233611, 35.95328611] }
    },
    {
      type: 'Feature',
      id: 834,
      properties: {
        DATE: '2024-10-26',
        jiguname: '진안송풍_천부',
        gennum: '737695',
        well: '충적',
        pyogo: '217.9',
        area: '금강권역',
        addr: '전북특별자치도 진안군 용담면 송풍리 1182-3',
        LON: '127.52233611',
        LAT: '35.95328611',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '20',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-20.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '13.0-20.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.52233611, 35.95328611] }
    },
    {
      type: 'Feature',
      id: 835,
      properties: {
        DATE: '2024-10-26',
        jiguname: '진안용담',
        gennum: '793601',
        well: '암반',
        pyogo: '275.02',
        area: '',
        addr: '전북특별자치도 진안군 용담면 와룡리 558',
        LON: '127.45387973',
        LAT: '35.95222650',
        insdate: '2023-12-01',
        manager: '',
        builder: '',
        gulDep: '100',
        gulDiaTop: '300',
        gulDiaBtm: '200',
        cseExHgt: '0.0-21.0',
        cseExDia: '200',
        cseExMat: 'STEEL PIPE',
        cseInHgt: '0.0-100.0',
        cseInDia: '150',
        cseInMat: 'P. V. C',
        strHgt: '70.0-100.0',
        strDia: '150',
        strMat: 'P. V. C'
      },
      geometry: { type: 'Point', coordinates: [127.45387973, 35.9522265] }
    },
    {
      type: 'Feature',
      id: 836,
      properties: {
        DATE: '2024-10-26',
        jiguname: '진안용담',
        gennum: '793602',
        well: '충적',
        pyogo: '275.03',
        area: '',
        addr: '전북특별자치도 진안군 용담면 와룡리 558',
        LON: '127.45385982',
        LAT: '35.95223448',
        insdate: '2023-12-01',
        manager: '',
        builder: '',
        gulDep: '15',
        gulDiaTop: '400',
        gulDiaBtm: '300',
        cseExHgt: '0.0-3.0',
        cseExDia: '300',
        cseExMat: 'STEEL PIPE',
        cseInHgt: '0.0-15.0',
        cseInDia: '200',
        cseInMat: 'P. V. C',
        strHgt: '14.0-15.0',
        strDia: '200',
        strMat: 'P. V. C'
      },
      geometry: { type: 'Point', coordinates: [127.45385982, 35.95223448] }
    },
    {
      type: 'Feature',
      id: 837,
      properties: {
        DATE: '2024-10-26',
        jiguname: '진안정천',
        gennum: '65598',
        well: '암반',
        pyogo: '276.12',
        area: '금강권역',
        addr: '전북특별자치도 진안군 정천면 봉학리 547 정천면사무소 내',
        LON: '127.42930278',
        LAT: '35.87267222',
        insdate: '2000-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '40',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-12.0',
        cseInHgt: '250',
        cseInDia: '백관',
        cseInMat: '0.0-40.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '20.0-24.0'
      },
      geometry: { type: 'Point', coordinates: [127.42930278, 35.87267222] }
    },
    {
      type: 'Feature',
      id: 838,
      properties: {
        DATE: '2024-10-26',
        jiguname: '진안주평_심부2',
        gennum: '769825',
        well: '암반',
        pyogo: '266.4',
        area: '금강권역',
        addr: '전북특별자치도 진안군 상전면 주평리 1004-1 상전면체련공원',
        LON: '127.48251944',
        LAT: '35.82138889',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '85',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-9.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-85.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '72.0-85.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.48251944, 35.82138889] }
    },
    {
      type: 'Feature',
      id: 839,
      properties: {
        DATE: '2024-10-26',
        jiguname: '진안주평_천부',
        gennum: '769826',
        well: '충적',
        pyogo: '266.4',
        area: '금강권역',
        addr: '전북특별자치도 진안군 상전면 주평리 1004-1 상전면체련공원',
        LON: '127.48251944',
        LAT: '35.82138889',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.48251944, 35.82138889] }
    },
    {
      type: 'Feature',
      id: 840,
      properties: {
        DATE: '2024-10-26',
        jiguname: '진주대천_심부2',
        gennum: '769827',
        well: '암반',
        pyogo: '40.4',
        area: '낙동강권역',
        addr: '경상남도 진주시 이반성면 대천리 482-1 경상남도산림환경연구원',
        LON: '128.29714444',
        LAT: '35.16042500',
        insdate: '2012-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-4.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '66.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.29714444, 35.160425] }
    },
    {
      type: 'Feature',
      id: 841,
      properties: {
        DATE: '2024-10-26',
        jiguname: '진주대천_천부',
        gennum: '769828',
        well: '충적',
        pyogo: '40.4',
        area: '낙동강권역',
        addr: '경상남도 진주시 이반성면 대천리 482-1 경상남도산림환경연구원',
        LON: '128.29714444',
        LAT: '35.16042500',
        insdate: '2012-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10.5',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.5',
        cseInMat: '100',
        strHgt: '',
        strDia: '6.0-10.5',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.29714444, 35.160425] }
    },
    {
      type: 'Feature',
      id: 842,
      properties: {
        DATE: '2024-10-26',
        jiguname: '진주오방_심부2',
        gennum: '737698',
        well: '암반',
        pyogo: '44.2',
        area: '낙동강권역',
        addr: '경상남도 진주시 미천면 오방리 747',
        LON: '128.11570000',
        LAT: '35.29935833',
        insdate: '2012-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-5.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '72.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.1157, 35.29935833] }
    },
    {
      type: 'Feature',
      id: 843,
      properties: {
        DATE: '2024-10-26',
        jiguname: '진주오방_천부',
        gennum: '737697',
        well: '충적',
        pyogo: '44.2',
        area: '낙동강권역',
        addr: '경상남도 진주시 미천면 오방리 747',
        LON: '128.11570000',
        LAT: '35.29935833',
        insdate: '2012-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.1157, 35.29935833] }
    },
    {
      type: 'Feature',
      id: 844,
      properties: {
        DATE: '2024-10-26',
        jiguname: '진주일반성',
        gennum: '73548',
        well: '암반',
        pyogo: '29.994',
        area: '낙동강권역',
        addr: '경상남도 진주시 일반성면 개암리 473-1',
        LON: '128.28981944',
        LAT: '35.16890556',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-10.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [128.28981944, 35.16890556] }
    },
    {
      type: 'Feature',
      id: 845,
      properties: {
        DATE: '2024-10-26',
        jiguname: '진주초전',
        gennum: '9251',
        well: '암반',
        pyogo: '21.53',
        area: '낙동강권역',
        addr: '경상남도 진주시 초전동  190-5 환경관리사업소',
        LON: '128.12530556',
        LAT: '35.20533056',
        insdate: '1995-12-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-21.0',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '18.0-26.0',
        strMat: ' 36.0-42.0'
      },
      geometry: { type: 'Point', coordinates: [128.12530556, 35.20533056] }
    },
    {
      type: 'Feature',
      id: 846,
      properties: {
        DATE: '2024-10-26',
        jiguname: '진주초전',
        gennum: '9250',
        well: '충적',
        pyogo: '21.53',
        area: '낙동강권역',
        addr: '경상남도 진주시 초전동  190-5 환경관리사업소',
        LON: '128.12530556',
        LAT: '35.20533056',
        insdate: '1995-12-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '12',
        gulDiaBtm: '350',
        cseExHgt: '350',
        cseExDia: '0.0-12.0',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0-12',
        cseInMat: '',
        strHgt: '',
        strDia: '5.0-11.0',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [128.12530556, 35.20533056] }
    },
    {
      type: 'Feature',
      id: 847,
      properties: {
        DATE: '2024-10-26',
        jiguname: '진천연곡_심부2',
        gennum: '737700',
        well: '암반',
        pyogo: '242.2',
        area: '금강권역',
        addr: '충청북도 진천군 진천읍 연곡리 27',
        LON: '127.36494167',
        LAT: '36.84911667',
        insdate: '2010-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-9.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '58.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.36494167, 36.84911667] }
    },
    {
      type: 'Feature',
      id: 848,
      properties: {
        DATE: '2024-10-26',
        jiguname: '진천연곡_천부',
        gennum: '737699',
        well: '충적',
        pyogo: '242.2',
        area: '금강권역',
        addr: '충청북도 진천군 진천읍 연곡리 27',
        LON: '127.36494167',
        LAT: '36.84911667',
        insdate: '2010-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '13',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-13.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '7.0-13.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.36494167, 36.84911667] }
    },
    {
      type: 'Feature',
      id: 849,
      properties: {
        DATE: '2024-10-26',
        jiguname: '진천진천',
        gennum: '65040',
        well: '암반',
        pyogo: '60.41',
        area: '금강권역',
        addr: '충청북도 진천군 진천읍 성석리 133-5',
        LON: '127.45844722',
        LAT: '36.85609167',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '71',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-10.5',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0.0-71.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '48.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [127.45844722, 36.85609167] }
    },
    {
      type: 'Feature',
      id: 850,
      properties: {
        DATE: '2024-10-26',
        jiguname: '창녕성산',
        gennum: '9882',
        well: '암반',
        pyogo: '68.84',
        area: '낙동강권역',
        addr: '경상남도 창녕군 성산면 냉천리 859-12',
        LON: '128.48762778',
        LAT: '35.63115556',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-12.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [128.48762778, 35.63115556] }
    },
    {
      type: 'Feature',
      id: 851,
      properties: {
        DATE: '2024-10-26',
        jiguname: '창녕성산',
        gennum: '9883',
        well: '충적',
        pyogo: '68.82',
        area: '낙동강권역',
        addr: '경상남도 창녕군 성산면 냉천리 859-12',
        LON: '128.48762778',
        LAT: '35.63115556',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '11.5',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-11.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0-11.5',
        cseInMat: '',
        strHgt: '',
        strDia: '7.5-11.5',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [128.48762778, 35.63115556] }
    },
    {
      type: 'Feature',
      id: 852,
      properties: {
        DATE: '2024-10-26',
        jiguname: '창녕영산',
        gennum: '65041',
        well: '암반',
        pyogo: '39.09',
        area: '낙동강권역',
        addr: '경상남도 창녕군 영산면 동리 433',
        LON: '128.52742222',
        LAT: '35.45017778',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '67',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-6.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0.0-67.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '44.0-48.0'
      },
      geometry: { type: 'Point', coordinates: [128.52742222, 35.45017778] }
    },
    {
      type: 'Feature',
      id: 853,
      properties: {
        DATE: '2024-10-26',
        jiguname: '창녕옥천_심부2',
        gennum: '737702',
        well: '암반',
        pyogo: '124.2',
        area: '낙동강권역',
        addr: '경상남도 창녕군 창녕읍 옥천리 516',
        LON: '128.55191667',
        LAT: '35.51837500',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-13.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.55191667, 35.518375] }
    },
    {
      type: 'Feature',
      id: 854,
      properties: {
        DATE: '2024-10-26',
        jiguname: '창녕옥천_천부',
        gennum: '737701',
        well: '충적',
        pyogo: '124.2',
        area: '낙동강권역',
        addr: '경상남도 창녕군 창녕읍 옥천리 516',
        LON: '128.55191667',
        LAT: '35.51837500',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-15.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '9.0-15.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.55191667, 35.518375] }
    },
    {
      type: 'Feature',
      id: 855,
      properties: {
        DATE: '2024-10-26',
        jiguname: '창녕주매_심부2',
        gennum: '769829',
        well: '암반',
        pyogo: '23.2',
        area: '낙동강권역',
        addr: '경상남도 창녕군 대합면 주매리 557-1 우포늪생태체험장',
        LON: '128.42280556',
        LAT: '35.56594167',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-8.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '73.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.42280556, 35.56594167] }
    },
    {
      type: 'Feature',
      id: 856,
      properties: {
        DATE: '2024-10-26',
        jiguname: '창녕주매_천부',
        gennum: '769830',
        well: '충적',
        pyogo: '23.2',
        area: '낙동강권역',
        addr: '경상남도 창녕군 대합면 주매리 557-1 우포늪생태체험장',
        LON: '128.42280556',
        LAT: '35.56594167',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.42280556, 35.56594167] }
    },
    {
      type: 'Feature',
      id: 857,
      properties: {
        DATE: '2024-10-26',
        jiguname: '창녕창녕',
        gennum: '637540',
        well: '암반',
        pyogo: '32.47',
        area: '낙동강권역',
        addr: '경상남도 창녕군 창녕읍 직교리 204 1',
        LON: '128.47808333',
        LAT: '35.53532222',
        insdate: '2015-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜대동공영',
        gulDiaTop: '103',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-17.5',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-103.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '24.0-32.0',
        strMat: ' 44.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [128.47808333, 35.53532222] }
    },
    {
      type: 'Feature',
      id: 858,
      properties: {
        DATE: '2024-10-26',
        jiguname: '창원북면',
        gennum: '612021',
        well: '암반',
        pyogo: '8.02',
        area: '낙동강권역',
        addr: '경상남도 창원시의장구 북면 마산리 409-288',
        LON: '128.62179722',
        LAT: '35.34680278',
        insdate: '2012-11-29',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜지오그린21',
        gulDiaTop: '102',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-29.0',
        cseExMat: '200',
        cseInHgt: 'STS304',
        cseInDia: '0.0-100',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '24.0-30.0',
        strMat: ' 36.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [128.62179722, 35.34680278] }
    },
    {
      type: 'Feature',
      id: 859,
      properties: {
        DATE: '2024-10-26',
        jiguname: '창원북면',
        gennum: '612022',
        well: '충적',
        pyogo: '8.05',
        area: '낙동강권역',
        addr: '경상남도 창원시의장구 북면 마산리 409-288',
        LON: '128.62179722',
        LAT: '35.34680278',
        insdate: '2012-11-29',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜지오그린21',
        gulDiaTop: '26',
        gulDiaBtm: '400',
        cseExHgt: '300',
        cseExDia: '0.0-3.0',
        cseExMat: '300',
        cseInHgt: '백관',
        cseInDia: '0.0-26.0',
        cseInMat: '200',
        strHgt: 'STS304',
        strDia: '10.0-26.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [128.62179722, 35.34680278] }
    },
    {
      type: 'Feature',
      id: 860,
      properties: {
        DATE: '2024-10-26',
        jiguname: '창원자은_신',
        gennum: '717057',
        well: '암반',
        pyogo: '22.02',
        area: '낙동강권역',
        addr: '경상남도 창원시 진해구 자은동  903-2 자은4공원내',
        LON: '128.70600833',
        LAT: '35.14977778',
        insdate: '2018-06-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '68.95',
        gulDiaBtm: '',
        cseExHgt: '',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '',
        cseInDia: '0-69',
        cseInMat: '150',
        strHgt: '',
        strDia: '',
        strMat: ''
      },
      geometry: { type: 'Point', coordinates: [128.70600833, 35.14977778] }
    },
    {
      type: 'Feature',
      id: 861,
      properties: {
        DATE: '2024-10-26',
        jiguname: '창원자은_신',
        gennum: '717058',
        well: '충적',
        pyogo: '21.92',
        area: '낙동강권역',
        addr: '경상남도 창원시 진해구 자은동  903-2 자은4공원내',
        LON: '128.70600833',
        LAT: '35.14977778',
        insdate: '2018-06-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '17.22',
        gulDiaBtm: '',
        cseExHgt: '',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '',
        cseInDia: '0-17',
        cseInMat: '200',
        strHgt: '',
        strDia: '',
        strMat: ''
      },
      geometry: { type: 'Point', coordinates: [128.70600833, 35.14977778] }
    },
    {
      type: 'Feature',
      id: 862,
      properties: {
        DATE: '2024-10-26',
        jiguname: '창원진전_신',
        gennum: '769848',
        well: '암반',
        pyogo: '20.36',
        area: '낙동강권역',
        addr: '경상남도 창원시 마산합포구 진전면 오서리 484번지',
        LON: '128.41008056',
        LAT: '35.10435833',
        insdate: '2020-12-21',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '120.53',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0-6',
        cseExMat: '200',
        cseInHgt: 'sst',
        cseInDia: '0-124',
        cseInMat: '150',
        strHgt: 'pvc',
        strDia: '54-56',
        strMat: ' 70-74'
      },
      geometry: { type: 'Point', coordinates: [128.41008056, 35.10435833] }
    },
    {
      type: 'Feature',
      id: 863,
      properties: {
        DATE: '2024-10-26',
        jiguname: '창원합성',
        gennum: '84033',
        well: '암반',
        pyogo: '31.542',
        area: '낙동강권역',
        addr: '경상남도 창원시 마산회원구 합성동  45-10',
        LON: '128.59187222',
        LAT: '35.24170278',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-5.7',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '57.0-61.0'
      },
      geometry: { type: 'Point', coordinates: [128.59187222, 35.24170278] }
    },
    {
      type: 'Feature',
      id: 864,
      properties: {
        DATE: '2024-10-26',
        jiguname: '창원회원_심부2',
        gennum: '769831',
        well: '암반',
        pyogo: '72.9',
        area: '낙동강권역',
        addr: '경상남도 창원시 마산회원구 회원동  639-1 마산여자중학교',
        LON: '128.56022778',
        LAT: '35.22105556',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-18.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.56022778, 35.22105556] }
    },
    {
      type: 'Feature',
      id: 865,
      properties: {
        DATE: '2024-10-26',
        jiguname: '창원회원_천부',
        gennum: '769832',
        well: '충적',
        pyogo: '72.9',
        area: '낙동강권역',
        addr: '경상남도 창원시 마산회원구 회원동  639-1 마산여자중학교',
        LON: '128.56022778',
        LAT: '35.22105556',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.56022778, 35.22105556] }
    },
    {
      type: 'Feature',
      id: 866,
      properties: {
        DATE: '2024-10-26',
        jiguname: '천안북면',
        gennum: '65595',
        well: '암반',
        pyogo: '106.532',
        area: '금강권역',
        addr: '충청남도 천안시 동남구 북면 사담리 416번지',
        LON: '127.26881111',
        LAT: '36.83320556',
        insdate: '2000-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-5.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.26881111, 36.83320556] }
    },
    {
      type: 'Feature',
      id: 867,
      properties: {
        DATE: '2024-10-26',
        jiguname: '천안성거',
        gennum: '11760',
        well: '암반',
        pyogo: '50.33',
        area: '한강권역',
        addr: '충청남도 천안시 서북구 성거읍 저리 57-26 성거읍사무소내',
        LON: '127.19943611',
        LAT: '36.87782222',
        insdate: '1996-08-28',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-17.9',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '54.0-66.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [127.19943611, 36.87782222] }
    },
    {
      type: 'Feature',
      id: 868,
      properties: {
        DATE: '2024-10-26',
        jiguname: '천안성거',
        gennum: '11761',
        well: '충적',
        pyogo: '50.38',
        area: '한강권역',
        addr: '충청남도 천안시 서북구 성거읍 저리 57-26 성거읍사무소내',
        LON: '127.19943611',
        LAT: '36.87782222',
        insdate: '1996-08-28',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '11.5',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-11.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0-11.5',
        cseInMat: '',
        strHgt: '',
        strDia: '6.5-10.5',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [127.19943611, 36.87782222] }
    },
    {
      type: 'Feature',
      id: 869,
      properties: {
        DATE: '2024-10-26',
        jiguname: '천안수신',
        gennum: '73520',
        well: '암반',
        pyogo: '52.09',
        area: '금강권역',
        addr: '충청남도 천안시 동남구 수신면 장산리 148-22',
        LON: '127.29676667',
        LAT: '36.73083333',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-12.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.29676667, 36.73083333] }
    },
    {
      type: 'Feature',
      id: 870,
      properties: {
        DATE: '2024-10-26',
        jiguname: '천안수신',
        gennum: '73521',
        well: '충적',
        pyogo: '52.1',
        area: '금강권역',
        addr: '충청남도 천안시 동남구 수신면 장산리 148-22',
        LON: '127.29676667',
        LAT: '36.73083333',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-10.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6.0-10.0'
      },
      geometry: { type: 'Point', coordinates: [127.29676667, 36.73083333] }
    },
    {
      type: 'Feature',
      id: 871,
      properties: {
        DATE: '2024-10-26',
        jiguname: '철원갈말',
        gennum: '637532',
        well: '암반',
        pyogo: '168.77',
        area: '한강권역',
        addr: '강원특별자치도 철원군 갈말읍 문혜리 888-3 1',
        LON: '127.32323056',
        LAT: '38.18590833',
        insdate: '2015-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜대동공영',
        gulDiaTop: '104',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-27.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-104.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '24.0-32.0',
        strMat: ' 48.0-56.0'
      },
      geometry: { type: 'Point', coordinates: [127.32323056, 38.18590833] }
    },
    {
      type: 'Feature',
      id: 872,
      properties: {
        DATE: '2024-10-26',
        jiguname: '철원자등_심부2',
        gennum: '737704',
        well: '암반',
        pyogo: '292.4',
        area: '한강권역',
        addr: '강원특별자치도 철원군 서면 자등리 754',
        LON: '127.41562778',
        LAT: '38.16413333',
        insdate: '2011-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-12.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '70.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.41562778, 38.16413333] }
    },
    {
      type: 'Feature',
      id: 873,
      properties: {
        DATE: '2024-10-26',
        jiguname: '철원자등_천부',
        gennum: '737703',
        well: '충적',
        pyogo: '292.4',
        area: '한강권역',
        addr: '강원특별자치도 철원군 서면 자등리 754',
        LON: '127.41562778',
        LAT: '38.16413333',
        insdate: '2011-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '6.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.41562778, 38.16413333] }
    },
    {
      type: 'Feature',
      id: 874,
      properties: {
        DATE: '2024-10-26',
        jiguname: '철원철원',
        gennum: '95518',
        well: '암반',
        pyogo: '146.08',
        area: '한강권역',
        addr: '강원특별자치도 철원군 철원읍 율이리 산206-2',
        LON: '127.16407500',
        LAT: '38.24385278',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '150',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-4.3',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-150.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '5.5-9.5'
      },
      geometry: { type: 'Point', coordinates: [127.164075, 38.24385278] }
    },
    {
      type: 'Feature',
      id: 875,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청도동천_심부2',
        gennum: '737706',
        well: '암반',
        pyogo: '106.5',
        area: '낙동강권역',
        addr: '경상북도 청도군 화양읍 동천리 457',
        LON: '128.71671111',
        LAT: '35.64842500',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-21.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.71671111, 35.648425] }
    },
    {
      type: 'Feature',
      id: 876,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청도동천_천부',
        gennum: '737705',
        well: '충적',
        pyogo: '106.5',
        area: '낙동강권역',
        addr: '경상북도 청도군 화양읍 동천리 457',
        LON: '128.71671111',
        LAT: '35.64842500',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '13.5',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-13.5',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '7.5-13.5',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.71671111, 35.648425] }
    },
    {
      type: 'Feature',
      id: 877,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청도신도_심부2',
        gennum: '737708',
        well: '암반',
        pyogo: '50.835',
        area: '낙동강권역',
        addr: '경상북도 청도군 청도읍 신도리 50-2',
        LON: '128.76395278',
        LAT: '35.59670000',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '88',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-8.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-88.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '83.5-88.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.76395278, 35.5967] }
    },
    {
      type: 'Feature',
      id: 878,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청도신도_천부',
        gennum: '737707',
        well: '충적',
        pyogo: '50.848',
        area: '낙동강권역',
        addr: '경상북도 청도군 청도읍 신도리 50-2',
        LON: '128.76395278',
        LAT: '35.59670000',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-5.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '5.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.76395278, 35.5967] }
    },
    {
      type: 'Feature',
      id: 879,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청도운문',
        gennum: '753921',
        well: '암반',
        pyogo: '208.342',
        area: '낙동강권역',
        addr: '경상북도 청도군 운문면 봉하리 148-4',
        LON: '128.96842778',
        LAT: '35.80611667',
        insdate: '2019-11-26',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '250',
        cseExHgt: '200',
        cseExDia: '0-34',
        cseExMat: '250',
        cseInHgt: '강관',
        cseInDia: '0-100',
        cseInMat: '200',
        strHgt: 'PVC',
        strDia: '34-100',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [128.96842778, 35.80611667] }
    },
    {
      type: 'Feature',
      id: 880,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청도운문',
        gennum: '753920',
        well: '충적',
        pyogo: '208.342',
        area: '낙동강권역',
        addr: '경상북도 청도군 운문면 봉하리 148-4',
        LON: '128.96842778',
        LAT: '35.80611667',
        insdate: '2019-11-26',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '250',
        cseExHgt: '200',
        cseExDia: '0-4',
        cseExMat: '250',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '200',
        strHgt: 'PVC',
        strDia: '4-10',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [128.96842778, 35.80611667] }
    },
    {
      type: 'Feature',
      id: 881,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청도청도',
        gennum: '65601',
        well: '암반',
        pyogo: '63.41',
        area: '낙동강권역',
        addr: '경상북도 청도군 청도읍 고수리 1034-22',
        LON: '128.75061944',
        LAT: '35.63652778',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-17.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [128.75061944, 35.63652778] }
    },
    {
      type: 'Feature',
      id: 882,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청도청도',
        gennum: '65602',
        well: '충적',
        pyogo: '63.39',
        area: '낙동강권역',
        addr: '경상북도 청도군 청도읍 고수리 1034-22',
        LON: '128.75061944',
        LAT: '35.63652778',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '10.5',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-6.5',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-10.5',
        strHgt: '',
        strDia: '',
        strMat: '6.5-10.5'
      },
      geometry: { type: 'Point', coordinates: [128.75061944, 35.63652778] }
    },
    {
      type: 'Feature',
      id: 883,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청도풍각',
        gennum: '718263',
        well: '암반',
        pyogo: '211.2',
        area: '낙동강권역',
        addr: '경상북도 청도군 풍각면 성곡리 144-7',
        LON: '128.57416667',
        LAT: '35.67861111',
        insdate: '2018-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '(주)호림건설',
        gulDiaTop: '103',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-10.0',
        cseExMat: '200',
        cseInHgt: 'Stainless Steel',
        cseInDia: '0.0-103',
        cseInMat: '150',
        strHgt: 'P.V.C(VG1)',
        strDia: '40-43',
        strMat: ' 47-51'
      },
      geometry: { type: 'Point', coordinates: [128.57416667, 35.67861111] }
    },
    {
      type: 'Feature',
      id: 884,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청송부남',
        gennum: '84031',
        well: '암반',
        pyogo: '245.104',
        area: '낙동강권역',
        addr: '경상북도 청송군 부남면 감연리  산99',
        LON: '129.05966111',
        LAT: '36.35635278',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국지질조사탐사업협동조합',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-6.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '56.0-60.0',
        strMat: ' 62.0-66.0'
      },
      geometry: { type: 'Point', coordinates: [129.05966111, 36.35635278] }
    },
    {
      type: 'Feature',
      id: 885,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청송상의_심부2',
        gennum: '771531',
        well: '암반',
        pyogo: '255',
        area: '낙동강권역',
        addr: '경상북도 청송군 주왕산면 상의리 301 주왕산국립공원안내소',
        LON: '129.14199167',
        LAT: '36.39021944',
        insdate: '2013-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-7.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.14199167, 36.39021944] }
    },
    {
      type: 'Feature',
      id: 886,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청송상의_천부',
        gennum: '771532',
        well: '충적',
        pyogo: '255',
        area: '낙동강권역',
        addr: '경상북도 청송군 주왕산면 상의리 301 주왕산국립공원안내소',
        LON: '129.14199167',
        LAT: '36.39021944',
        insdate: '2013-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10.5',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10.5',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '4.5-10.5',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.14199167, 36.39021944] }
    },
    {
      type: 'Feature',
      id: 887,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청송송생_심부2',
        gennum: '737710',
        well: '암반',
        pyogo: '208.5',
        area: '낙동강권역',
        addr: '경상북도 청송군 청송읍 송생리 720',
        LON: '129.07945000',
        LAT: '36.39137222',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-7.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.07945, 36.39137222] }
    },
    {
      type: 'Feature',
      id: 888,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청송송생_천부',
        gennum: '737709',
        well: '충적',
        pyogo: '208.5',
        area: '낙동강권역',
        addr: '경상북도 청송군 청송읍 송생리 720',
        LON: '129.07945000',
        LAT: '36.39137222',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10.5',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.5',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '4.5-10.5',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.07945, 36.39137222] }
    },
    {
      type: 'Feature',
      id: 889,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청송진보',
        gennum: '603274',
        well: '암반',
        pyogo: '192.47',
        area: '낙동강권역',
        addr: '경상북도 청송군 진보면 이촌리 396-1',
        LON: '129.03250000',
        LAT: '36.51916667',
        insdate: '2011-12-28',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜한국건업엔지니어링',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-9.5',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '36-40',
        strMat: ' 52-56'
      },
      geometry: { type: 'Point', coordinates: [129.0325, 36.51916667] }
    },
    {
      type: 'Feature',
      id: 890,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청송파천',
        gennum: '11799',
        well: '암반',
        pyogo: '172.5',
        area: '낙동강권역',
        addr: '경상북도 청송군 파천면 관리 512-3 파천농촌지도소내',
        LON: '129.03631667',
        LAT: '36.46366667',
        insdate: '1996-10-30',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '68',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-10.7',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0.0-68.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-30.0',
        strMat: ' 38.0-42.0'
      },
      geometry: { type: 'Point', coordinates: [129.03631667, 36.46366667] }
    },
    {
      type: 'Feature',
      id: 891,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청송파천',
        gennum: '11800',
        well: '충적',
        pyogo: '172.5',
        area: '낙동강권역',
        addr: '경상북도 청송군 파천면 관리 512-3 파천농촌지도소내',
        LON: '129.03631667',
        LAT: '36.46366667',
        insdate: '1996-10-30',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '11.5',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-4.8',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0-11.5',
        cseInMat: '',
        strHgt: '',
        strDia: '0.8-4.8',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [129.03631667, 36.46366667] }
    },
    {
      type: 'Feature',
      id: 892,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청송현서',
        gennum: '82042',
        well: '암반',
        pyogo: '321.691',
        area: '낙동강권역',
        addr: '경상북도 청송군 현서면 구산리 509-3',
        LON: '128.89141389',
        LAT: '36.27408056',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-7.5',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'P.V.C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [128.89141389, 36.27408056] }
    },
    {
      type: 'Feature',
      id: 893,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청양와촌_심부2',
        gennum: '771533',
        well: '암반',
        pyogo: '45.2',
        area: '금강권역',
        addr: '충청남도 청양군 정산면 와촌리 82-1 와촌보건진료소',
        LON: '126.92965833',
        LAT: '36.39136111',
        insdate: '2016-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-6.6',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-100',
        cseInMat: '125',
        strHgt: 'PVC',
        strDia: '50.0-100.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.92965833, 36.39136111] }
    },
    {
      type: 'Feature',
      id: 894,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청양와촌_천부',
        gennum: '771534',
        well: '충적',
        pyogo: '45.2',
        area: '금강권역',
        addr: '충청남도 청양군 정산면 와촌리 82-1 와촌보건진료소',
        LON: '126.92965833',
        LAT: '36.39136111',
        insdate: '2016-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.92965833, 36.39136111] }
    },
    {
      type: 'Feature',
      id: 895,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청양정산',
        gennum: '65042',
        well: '암반',
        pyogo: '38.97',
        area: '금강권역',
        addr: '충청남도 청양군 정산면 역촌리 601-2 복지회관',
        LON: '126.94771389',
        LAT: '36.41658333',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '72',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-35.5',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0.0-72.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '48.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [126.94771389, 36.41658333] }
    },
    {
      type: 'Feature',
      id: 896,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청양정좌_심부2',
        gennum: '771535',
        well: '암반',
        pyogo: '94.8',
        area: '금강권역',
        addr: '충청남도 청양군 청양읍 정좌리 455 청양군농업기술센터',
        LON: '126.77973889',
        LAT: '36.42319444',
        insdate: '2013-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-24.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '65.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.77973889, 36.42319444] }
    },
    {
      type: 'Feature',
      id: 897,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청양정좌_천부',
        gennum: '771536',
        well: '충적',
        pyogo: '94.8',
        area: '금강권역',
        addr: '충청남도 청양군 청양읍 정좌리 455 청양군농업기술센터',
        LON: '126.77973889',
        LAT: '36.42319444',
        insdate: '2013-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.77973889, 36.42319444] }
    },
    {
      type: 'Feature',
      id: 898,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청양청양',
        gennum: '639092',
        well: '암반',
        pyogo: '94.76',
        area: '금강권역',
        addr: '충청남도 청양군 청양읍 교월리 64',
        LON: '126.81000833',
        LAT: '36.45307500',
        insdate: '2016-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '150',
        cseExHgt: '300',
        cseExDia: '200',
        cseExMat: '0.0-11.2m',
        cseInHgt: '200',
        cseInDia: 'Stainless Steel',
        cseInMat: '0.0-150.0',
        strHgt: '150',
        strDia: 'PVC(VG1)',
        strMat: '38-46'
      },
      geometry: { type: 'Point', coordinates: [126.81000833, 36.453075] }
    },
    {
      type: 'Feature',
      id: 899,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청양청양',
        gennum: '639093',
        well: '충적',
        pyogo: '94.76',
        area: '금강권역',
        addr: '충청남도 청양군 청양읍 교월리 64',
        LON: '126.81000833',
        LAT: '36.45307500',
        insdate: '2016-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '11',
        cseExHgt: '400',
        cseExDia: '300',
        cseExMat: '0.0-3.0m',
        cseInHgt: '300',
        cseInDia: 'Stainless Steel',
        cseInMat: '0.0-11.0',
        strHgt: '200',
        strDia: 'Stainless Steel',
        strMat: '7.0-8.0'
      },
      geometry: { type: 'Point', coordinates: [126.81000833, 36.453075] }
    },
    {
      type: 'Feature',
      id: 900,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청주가덕',
        gennum: '4085',
        well: '암반',
        pyogo: '87.59',
        area: '금강권역',
        addr: '충청북도 청주시 상당구 가덕면 국전리 194-6 수공 대청취수장',
        LON: '127.50790833',
        LAT: '36.53272222',
        insdate: '1995-12-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '65',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-18.0',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0.0-65.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '18.0-26.0',
        strMat: ' 36.0-42.0'
      },
      geometry: { type: 'Point', coordinates: [127.50790833, 36.53272222] }
    },
    {
      type: 'Feature',
      id: 901,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청주가덕',
        gennum: '4084',
        well: '충적',
        pyogo: '87.617',
        area: '금강권역',
        addr: '충청북도 청주시 상당구 가덕면 국전리 194-6 수공 대청취수장',
        LON: '127.50790833',
        LAT: '36.53272222',
        insdate: '1995-12-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '25',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-18.0',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0-25',
        cseInMat: '',
        strHgt: '',
        strDia: '6.0-12.0',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [127.50790833, 36.53272222] }
    },
    {
      type: 'Feature',
      id: 902,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청주강내',
        gennum: '65078',
        well: '암반',
        pyogo: '42.66',
        area: '금강권역',
        addr: '충청북도 청주시 흥덕구 강내면 다락리 227 한국교원대학교',
        LON: '127.36427222',
        LAT: '36.60643889',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-23.5',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.36427222, 36.60643889] }
    },
    {
      type: 'Feature',
      id: 903,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청주강내',
        gennum: '65079',
        well: '충적',
        pyogo: '42.62',
        area: '금강권역',
        addr: '충청북도 청주시 흥덕구 강내면 다락리 227 한국교원대학교',
        LON: '127.36427222',
        LAT: '36.60643889',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '7.5',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-3.5',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-7.5',
        strHgt: '',
        strDia: '',
        strMat: '3.5-7.5'
      },
      geometry: { type: 'Point', coordinates: [127.36427222, 36.60643889] }
    },
    {
      type: 'Feature',
      id: 904,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청주내덕',
        gennum: '9919',
        well: '암반',
        pyogo: '39.96',
        area: '금강권역',
        addr: '충청북도 청주시 청원구 내덕동  777 배수펌프장내',
        LON: '127.47354722',
        LAT: '36.65908056',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '72',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-9.2',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-72.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [127.47354722, 36.65908056] }
    },
    {
      type: 'Feature',
      id: 905,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청주내덕',
        gennum: '9920',
        well: '충적',
        pyogo: '39.93',
        area: '금강권역',
        addr: '충청북도 청주시 청원구 내덕동  777 배수펌프장내',
        LON: '127.47354722',
        LAT: '36.65908056',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '9.2',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-9.2',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0-9.2',
        cseInMat: '',
        strHgt: '',
        strDia: '5.2-9.2',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [127.47354722, 36.65908056] }
    },
    {
      type: 'Feature',
      id: 906,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청주내수',
        gennum: '11759',
        well: '암반',
        pyogo: '103.89',
        area: '금강권역',
        addr: '충청북도 청주시 상당구 내수읍 초정리 358-6',
        LON: '127.60655278',
        LAT: '36.72266111',
        insdate: '1996-11-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '80',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-9.4',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0.0-80.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '25.0-29.0',
        strMat: ' 33.0-37.0'
      },
      geometry: { type: 'Point', coordinates: [127.60655278, 36.72266111] }
    },
    {
      type: 'Feature',
      id: 907,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청주두모_심부2',
        gennum: '771537',
        well: '암반',
        pyogo: '127.257',
        area: '금강권역',
        addr: '충청북도 청주시 상당구 문의면 두모리 243 문의초등학교도원분교',
        LON: '127.47065556',
        LAT: '36.51791944',
        insdate: '2018-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-18.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC(HI-VP)',
        strDia: '66.5-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.47065556, 36.51791944] }
    },
    {
      type: 'Feature',
      id: 908,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청주두모_천부',
        gennum: '771538',
        well: '충적',
        pyogo: '127.268',
        area: '금강권역',
        addr: '충청북도 청주시 상당구 문의면 두모리 243 문의초등학교도원분교',
        LON: '127.47065556',
        LAT: '36.51791944',
        insdate: '2018-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '16',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-16',
        cseInMat: '100',
        strHgt: 'PVC(HI-VP)',
        strDia: '10.0-16.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.47065556, 36.51791944] }
    },
    {
      type: 'Feature',
      id: 909,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청주미원',
        gennum: '11758',
        well: '암반',
        pyogo: '233.47',
        area: '한강권역',
        addr: '충청북도 청주시 상당구 미원면 내산리 378',
        LON: '127.65758333',
        LAT: '36.64141667',
        insdate: '1996-12-02',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '62',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-4.3',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0.0-62.0',
        cseInMat: '250',
        strHgt: 'P. V. C',
        strDia: '46.0-58.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [127.65758333, 36.64141667] }
    },
    {
      type: 'Feature',
      id: 910,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청주미천_심부2',
        gennum: '737714',
        well: '암반',
        pyogo: '111.488',
        area: '금강권역',
        addr: '충청북도 청주시 상당구 문의면 미천리 228',
        LON: '127.49291111',
        LAT: '36.51778333',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-24.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.49291111, 36.51778333] }
    },
    {
      type: 'Feature',
      id: 911,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청주미천_천부',
        gennum: '737713',
        well: '충적',
        pyogo: '111.488',
        area: '금강권역',
        addr: '충청북도 청주시 상당구 문의면 미천리 228',
        LON: '127.49291111',
        LAT: '36.51778333',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '12',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-12.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '9.0-12.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.49291111, 36.51778333] }
    },
    {
      type: 'Feature',
      id: 912,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청주행정_심부2',
        gennum: '737712',
        well: '암반',
        pyogo: '102.2',
        area: '금강권역',
        addr: '충청북도 청주시 상당구 가덕면 행정리 29-1',
        LON: '127.53098889',
        LAT: '36.53677222',
        insdate: '2010-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '83',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-83',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '75-83',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.53098889, 36.53677222] }
    },
    {
      type: 'Feature',
      id: 913,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청주행정_천부',
        gennum: '737711',
        well: '충적',
        pyogo: '102.2',
        area: '금강권역',
        addr: '충청북도 청주시 상당구 가덕면 행정리 29-1',
        LON: '127.53098889',
        LAT: '36.53677222',
        insdate: '2010-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '5.5-10',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.53098889, 36.53677222] }
    },
    {
      type: 'Feature',
      id: 914,
      properties: {
        DATE: '2024-10-26',
        jiguname: '청주현도',
        gennum: '514301',
        well: '암반',
        pyogo: '53.45',
        area: '금강권역',
        addr: '충청북도 청주시 서원구 현도면 죽암리 474-19',
        LON: '127.43111111',
        LAT: '36.52333333',
        insdate: '2010-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '유성삼정개발㈜',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-12.0',
        cseExMat: '200',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '27.4-31.4',
        strMat: ' 39.7-43.7'
      },
      geometry: { type: 'Point', coordinates: [127.43111111, 36.52333333] }
    },
    {
      type: 'Feature',
      id: 915,
      properties: {
        DATE: '2024-10-26',
        jiguname: '춘천북산',
        gennum: '82008',
        well: '암반',
        pyogo: '218.422',
        area: '한강권역',
        addr: '강원특별자치도 춘천시 북산면 추곡리 108-1',
        LON: '127.88983611',
        LAT: '38.03027222',
        insdate: '2003-05-29',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-6.0',
        cseInHgt: '250',
        cseInDia: 'STAINLES STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P.V.C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.88983611, 38.03027222] }
    },
    {
      type: 'Feature',
      id: 916,
      properties: {
        DATE: '2024-10-26',
        jiguname: '춘천서면',
        gennum: '601661',
        well: '암반',
        pyogo: '84.62',
        area: '한강권역',
        addr: '강원특별자치도 춘천시 서면 금산리 산72-1',
        LON: '127.68111111',
        LAT: '37.89611111',
        insdate: '2011-12-02',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜한국건업엔지니어링',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-6.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '36-40',
        strMat: ' 52-56'
      },
      geometry: { type: 'Point', coordinates: [127.68111111, 37.89611111] }
    },
    {
      type: 'Feature',
      id: 917,
      properties: {
        DATE: '2024-10-26',
        jiguname: '춘천송암_심부2',
        gennum: '737716',
        well: '암반',
        pyogo: '124.22',
        area: '한강권역',
        addr: '강원특별자치도 춘천시 송암동  129-1',
        LON: '127.69660556',
        LAT: '37.85955278',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-31.2',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.69660556, 37.85955278] }
    },
    {
      type: 'Feature',
      id: 918,
      properties: {
        DATE: '2024-10-26',
        jiguname: '춘천송암_천부',
        gennum: '737715',
        well: '충적',
        pyogo: '124.22',
        area: '한강권역',
        addr: '강원특별자치도 춘천시 송암동  129-1',
        LON: '127.69660556',
        LAT: '37.85955278',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '5.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.69660556, 37.85955278] }
    },
    {
      type: 'Feature',
      id: 919,
      properties: {
        DATE: '2024-10-26',
        jiguname: '춘천신동',
        gennum: '82009',
        well: '암반',
        pyogo: '211.242',
        area: '한강권역',
        addr: '강원특별자치도 춘천시 신동면 팔미리 861-1',
        LON: '127.67641111',
        LAT: '37.80756667',
        insdate: '2003-05-29',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-12.0',
        cseInHgt: '250',
        cseInDia: 'STAINLES STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P.V.C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.67641111, 37.80756667] }
    },
    {
      type: 'Feature',
      id: 920,
      properties: {
        DATE: '2024-10-26',
        jiguname: '춘천신동',
        gennum: '82010',
        well: '충적',
        pyogo: '211.307',
        area: '한강권역',
        addr: '강원특별자치도 춘천시 신동면 팔미리 861-1',
        LON: '127.67641111',
        LAT: '37.80756667',
        insdate: '2003-05-29',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-6.0',
        cseInHgt: '200',
        cseInDia: 'STAINLES STEEL',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6.0-10.0'
      },
      geometry: { type: 'Point', coordinates: [127.67641111, 37.80756667] }
    },
    {
      type: 'Feature',
      id: 921,
      properties: {
        DATE: '2024-10-26',
        jiguname: '춘천우두',
        gennum: '3547',
        well: '암반',
        pyogo: '77.53',
        area: '한강권역',
        addr: '강원특별자치도 춘천시 우두동  429-17 우두동사무소',
        LON: '127.73798611',
        LAT: '37.90417778',
        insdate: '1995-12-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-10.0',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '14.0-30.0',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [127.73798611, 37.90417778] }
    },
    {
      type: 'Feature',
      id: 922,
      properties: {
        DATE: '2024-10-26',
        jiguname: '춘천우두',
        gennum: '3546',
        well: '충적',
        pyogo: '77.53',
        area: '한강권역',
        addr: '강원특별자치도 춘천시 우두동  429-17 우두동사무소',
        LON: '127.73798611',
        LAT: '37.90417778',
        insdate: '1995-12-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '12',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-2.7',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0-12',
        cseInMat: '',
        strHgt: '',
        strDia: '2.7-9.7',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [127.73798611, 37.90417778] }
    },
    {
      type: 'Feature',
      id: 923,
      properties: {
        DATE: '2024-10-26',
        jiguname: '충주가금',
        gennum: '3986',
        well: '암반',
        pyogo: '63.09',
        area: '한강권역',
        addr: '충청북도 충주시 중앙탑면 가흥리 582 가흥국교부지',
        LON: '127.85448889',
        LAT: '37.07680833',
        insdate: '1995-12-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원공사',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-16.5',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0.0-54.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '54.0-70.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [127.85448889, 37.07680833] }
    },
    {
      type: 'Feature',
      id: 924,
      properties: {
        DATE: '2024-10-26',
        jiguname: '충주가금',
        gennum: '3985',
        well: '충적',
        pyogo: '63.04',
        area: '한강권역',
        addr: '충청북도 충주시 중앙탑면 가흥리 582 가흥국교부지',
        LON: '127.85448889',
        LAT: '37.07680833',
        insdate: '1995-12-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원공사',
        gulDiaTop: '14.5',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-8.5',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0-14.5',
        cseInMat: '',
        strHgt: '',
        strDia: '8.5-14.5',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [127.85448889, 37.07680833] }
    },
    {
      type: 'Feature',
      id: 925,
      properties: {
        DATE: '2024-10-26',
        jiguname: '충주동량',
        gennum: '9921',
        well: '암반',
        pyogo: '82.66',
        area: '한강권역',
        addr: '충청북도 충주시 동량면 조동리 1370-4 동량면사무소내',
        LON: '127.96270833',
        LAT: '37.02623333',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '72.5',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-11.7',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-72.5',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [127.96270833, 37.02623333] }
    },
    {
      type: 'Feature',
      id: 926,
      properties: {
        DATE: '2024-10-26',
        jiguname: '충주동량',
        gennum: '9922',
        well: '충적',
        pyogo: '82.63',
        area: '한강권역',
        addr: '충청북도 충주시 동량면 조동리 1370-4 동량면사무소내',
        LON: '127.96270833',
        LAT: '37.02623333',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '10.5',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-10.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0-10.5',
        cseInMat: '',
        strHgt: '',
        strDia: '6.5-10.5',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [127.96270833, 37.02623333] }
    },
    {
      type: 'Feature',
      id: 927,
      properties: {
        DATE: '2024-10-26',
        jiguname: '충주본리_심부2',
        gennum: '771539',
        well: '암반',
        pyogo: '78',
        area: '한강권역',
        addr: '충청북도 충주시 대소원면 본리 569-15 대소원초등학교',
        LON: '127.82643889',
        LAT: '36.97468056',
        insdate: '2011-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-9.2',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '60.5-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.82643889, 36.97468056] }
    },
    {
      type: 'Feature',
      id: 928,
      properties: {
        DATE: '2024-10-26',
        jiguname: '충주본리_천부',
        gennum: '771540',
        well: '충적',
        pyogo: '78',
        area: '한강권역',
        addr: '충청북도 충주시 대소원면 본리 569-15 대소원초등학교',
        LON: '127.82643889',
        LAT: '36.97468056',
        insdate: '2011-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '5.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.82643889, 36.97468056] }
    },
    {
      type: 'Feature',
      id: 929,
      properties: {
        DATE: '2024-10-26',
        jiguname: '충주세성_심부2',
        gennum: '771541',
        well: '암반',
        pyogo: '135.5',
        area: '한강권역',
        addr: '충청북도 충주시 살미면 세성리 335-2 살미면사무소',
        LON: '127.96489167',
        LAT: '36.90558333',
        insdate: '2011-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-11.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '65.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.96489167, 36.90558333] }
    },
    {
      type: 'Feature',
      id: 930,
      properties: {
        DATE: '2024-10-26',
        jiguname: '충주세성_천부',
        gennum: '771542',
        well: '충적',
        pyogo: '135.5',
        area: '한강권역',
        addr: '충청북도 충주시 살미면 세성리 335-2 살미면사무소',
        LON: '127.96489167',
        LAT: '36.90558333',
        insdate: '2011-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '5.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.96489167, 36.90558333] }
    },
    {
      type: 'Feature',
      id: 931,
      properties: {
        DATE: '2024-10-26',
        jiguname: '충주신니',
        gennum: '718253',
        well: '암반',
        pyogo: '143.87',
        area: '한강권역',
        addr: '충청북도 충주시 신니면 화석리 191',
        LON: '127.73472222',
        LAT: '37.02944444',
        insdate: '2018-12-07',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '동서지오베이스(주)',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-12.0',
        cseExMat: '200',
        cseInHgt: 'Stainless Steel',
        cseInDia: '0.0-100',
        cseInMat: '150',
        strHgt: 'P.V.C(VG1)',
        strDia: '24.0-28.0',
        strMat: ' 36.0-40.0'
      },
      geometry: { type: 'Point', coordinates: [127.73472222, 37.02944444] }
    },
    {
      type: 'Feature',
      id: 932,
      properties: {
        DATE: '2024-10-26',
        jiguname: '충주용포_심부2',
        gennum: '769833',
        well: '암반',
        pyogo: '130.4',
        area: '한강권역',
        addr: '충청북도 충주시 앙성면 용포리 172 앙성중학교',
        LON: '127.75192222',
        LAT: '37.11379444',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '83',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-17.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-83.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '74.0-83.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.75192222, 37.11379444] }
    },
    {
      type: 'Feature',
      id: 933,
      properties: {
        DATE: '2024-10-26',
        jiguname: '충주용포_천부',
        gennum: '769834',
        well: '충적',
        pyogo: '130.4',
        area: '한강권역',
        addr: '충청북도 충주시 앙성면 용포리 172 앙성중학교',
        LON: '127.75192222',
        LAT: '37.11379444',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '13',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-13.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '7.0-13.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.75192222, 37.11379444] }
    },
    {
      type: 'Feature',
      id: 934,
      properties: {
        DATE: '2024-10-26',
        jiguname: '충주주덕',
        gennum: '718255',
        well: '암반',
        pyogo: '107.2',
        area: '한강권역',
        addr: '충청북도 충주시 주덕읍 대곡리 725',
        LON: '127.76305556',
        LAT: '36.95611111',
        insdate: '2018-12-07',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '동서지오베이스(주)',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-13.0',
        cseExMat: '200',
        cseInHgt: 'Stainless Steel',
        cseInDia: '0.0-100',
        cseInMat: '150',
        strHgt: 'P.V.C(VG1)',
        strDia: '16.0-20.0',
        strMat: ' 28.0-32.0'
      },
      geometry: { type: 'Point', coordinates: [127.76305556, 36.95611111] }
    },
    {
      type: 'Feature',
      id: 935,
      properties: {
        DATE: '2024-10-26',
        jiguname: '칠곡가산',
        gennum: '73539',
        well: '암반',
        pyogo: '113.03',
        area: '낙동강권역',
        addr: '경상북도 칠곡군 가산면 천평리 139-2',
        LON: '128.53908889',
        LAT: '36.08606389',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-12.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [128.53908889, 36.08606389] }
    },
    {
      type: 'Feature',
      id: 936,
      properties: {
        DATE: '2024-10-26',
        jiguname: '칠곡가산',
        gennum: '73540',
        well: '충적',
        pyogo: '113.04',
        area: '낙동강권역',
        addr: '경상북도 칠곡군 가산면 천평리 139-2',
        LON: '128.53908889',
        LAT: '36.08606389',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-10.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6.0-10.0'
      },
      geometry: { type: 'Point', coordinates: [128.53908889, 36.08606389] }
    },
    {
      type: 'Feature',
      id: 937,
      properties: {
        DATE: '2024-10-26',
        jiguname: '칠곡왜관',
        gennum: '82044',
        well: '암반',
        pyogo: '31.213',
        area: '낙동강권역',
        addr: '경상북도 칠곡군 왜관읍 삼청리 1019-21',
        LON: '128.42380556',
        LAT: '35.97971944',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-12',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'P.V.C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [128.42380556, 35.97971944] }
    },
    {
      type: 'Feature',
      id: 938,
      properties: {
        DATE: '2024-10-26',
        jiguname: '칠곡왜관',
        gennum: '82045',
        well: '충적',
        pyogo: '31.25',
        area: '낙동강권역',
        addr: '경상북도 칠곡군 왜관읍 삼청리 1019-21',
        LON: '128.42380556',
        LAT: '35.97971944',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '15.4',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0-6',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-15.4',
        strHgt: '',
        strDia: '',
        strMat: '6.0-10.0'
      },
      geometry: { type: 'Point', coordinates: [128.42380556, 35.97971944] }
    },
    {
      type: 'Feature',
      id: 939,
      properties: {
        DATE: '2024-10-26',
        jiguname: '칠곡지천_심부2',
        gennum: '771543',
        well: '암반',
        pyogo: '36.97',
        area: '낙동강권역',
        addr: '경상북도 칠곡군 지천면 용산리 583-2 지천초등학교',
        LON: '128.49838333',
        LAT: '35.90543056',
        insdate: '2009-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '79',
        gulDiaBtm: '165',
        cseExHgt: '165',
        cseExDia: '0.0-7.0',
        cseExMat: '165',
        cseInHgt: '강관',
        cseInDia: '0-79',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '67.0-79.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.49838333, 35.90543056] }
    },
    {
      type: 'Feature',
      id: 940,
      properties: {
        DATE: '2024-10-26',
        jiguname: '칠곡지천_천부',
        gennum: '771544',
        well: '충적',
        pyogo: '37',
        area: '낙동강권역',
        addr: '경상북도 칠곡군 지천면 용산리 583-2 지천초등학교',
        LON: '128.49838333',
        LAT: '35.90543056',
        insdate: '2009-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '165',
        cseExHgt: '165',
        cseExDia: '0.0-0.0',
        cseExMat: '165',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.49838333, 35.90543056] }
    },
    {
      type: 'Feature',
      id: 941,
      properties: {
        DATE: '2024-10-26',
        jiguname: '태백문곡_심부2',
        gennum: '737720',
        well: '암반',
        pyogo: '647.5',
        area: '낙동강권역',
        addr: '강원특별자치도 태백시 문곡동  10-3',
        LON: '129.00278889',
        LAT: '37.13851944',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-6.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.00278889, 37.13851944] }
    },
    {
      type: 'Feature',
      id: 942,
      properties: {
        DATE: '2024-10-26',
        jiguname: '태백문곡_천부',
        gennum: '737719',
        well: '충적',
        pyogo: '647.5',
        area: '낙동강권역',
        addr: '강원특별자치도 태백시 문곡동  10-3',
        LON: '129.00278889',
        LAT: '37.13851944',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '13.5',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-13.5',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '4.5-13.5',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.00278889, 37.13851944] }
    },
    {
      type: 'Feature',
      id: 943,
      properties: {
        DATE: '2024-10-26',
        jiguname: '태백상사미',
        gennum: '615523',
        well: '암반',
        pyogo: '716.86',
        area: '한강권역',
        addr: '강원특별자치도 태백시 상사미동  289',
        LON: '128.98916667',
        LAT: '37.28361111',
        insdate: '2013-11-18',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부/한국수자원공사',
        gulDiaTop: '141',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '',
        cseInHgt: '',
        cseInDia: '0-141',
        cseInMat: '',
        strHgt: '',
        strDia: '',
        strMat: ''
      },
      geometry: { type: 'Point', coordinates: [128.98916667, 37.28361111] }
    },
    {
      type: 'Feature',
      id: 944,
      properties: {
        DATE: '2024-10-26',
        jiguname: '태백황지',
        gennum: '9867',
        well: '암반',
        pyogo: '699.08',
        area: '낙동강권역',
        addr: '강원특별자치도 태백시 황지동  244-3 태백시청내',
        LON: '128.98623056',
        LAT: '37.16331389',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '76',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-5.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0.0-80.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '24.0-28.0'
      },
      geometry: { type: 'Point', coordinates: [128.98623056, 37.16331389] }
    },
    {
      type: 'Feature',
      id: 945,
      properties: {
        DATE: '2024-10-26',
        jiguname: '태안안면',
        gennum: '405432',
        well: '암반',
        pyogo: '8.9',
        area: '금강권역',
        addr: '충청남도 태안군 안면읍 중장리 392-1',
        LON: '126.38601944',
        LAT: '36.48545278',
        insdate: '2009-12-14',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜해천 E.T.S',
        gulDiaTop: '110',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-30.6',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-110.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '52-56',
        strMat: ' 64-68'
      },
      geometry: { type: 'Point', coordinates: [126.38601944, 36.48545278] }
    },
    {
      type: 'Feature',
      id: 946,
      properties: {
        DATE: '2024-10-26',
        jiguname: '태안원북',
        gennum: '65043',
        well: '암반',
        pyogo: '30.22',
        area: '금강권역',
        addr: '충청남도 태안군 원북면 청산리 산164-1',
        LON: '126.26110000',
        LAT: '36.80991389',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '71',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-9.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0.0-71.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '48.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [126.2611, 36.80991389] }
    },
    {
      type: 'Feature',
      id: 947,
      properties: {
        DATE: '2024-10-26',
        jiguname: '태안장대',
        gennum: '639085',
        well: '암반',
        pyogo: '23.47',
        area: '금강권역',
        addr: '충청남도 태안군 원북면 장대리 64-2',
        LON: '126.24891111',
        LAT: '36.78715278',
        insdate: '2016-07-22',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '154.4',
        cseExHgt: '300',
        cseExDia: '200',
        cseExMat: '0.0-47.0m',
        cseInHgt: '200',
        cseInDia: 'Stainless Steel',
        cseInMat: '0.0-154.4',
        strHgt: '150',
        strDia: 'PVC(VG1)',
        strMat: '50-82'
      },
      geometry: { type: 'Point', coordinates: [126.24891111, 36.78715278] }
    },
    {
      type: 'Feature',
      id: 948,
      properties: {
        DATE: '2024-10-26',
        jiguname: '태안태안',
        gennum: '65044',
        well: '암반',
        pyogo: '21.35',
        area: '금강권역',
        addr: '충청남도 태안군 태안읍 상옥리 1161 마을회관',
        LON: '126.32657778',
        LAT: '36.77288333',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-24.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '48.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [126.32657778, 36.77288333] }
    },
    {
      type: 'Feature',
      id: 949,
      properties: {
        DATE: '2024-10-26',
        jiguname: '통영도산',
        gennum: '769857',
        well: '암반',
        pyogo: '71.12',
        area: '낙동강권역',
        addr: '경상남도 통영시 도산면 수월리 산257-4',
        LON: '128.33567222',
        LAT: '34.88978611',
        insdate: '2020-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '',
        cseExHgt: '',
        cseExDia: '',
        cseExMat: '',
        cseInHgt: '',
        cseInDia: '0-100',
        cseInMat: '',
        strHgt: '',
        strDia: '',
        strMat: ''
      },
      geometry: { type: 'Point', coordinates: [128.33567222, 34.88978611] }
    },
    {
      type: 'Feature',
      id: 950,
      properties: {
        DATE: '2024-10-26',
        jiguname: '통영용남',
        gennum: '65045',
        well: '암반',
        pyogo: '1.432',
        area: '낙동강권역',
        addr: '경상남도 통영시 용남면 원평리 789-14',
        LON: '128.46105000',
        LAT: '34.89024444',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '69',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-3.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0.0-69.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '48.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [128.46105, 34.89024444] }
    },
    {
      type: 'Feature',
      id: 951,
      properties: {
        DATE: '2024-10-26',
        jiguname: '파주가야_심부2',
        gennum: '771545',
        well: '암반',
        pyogo: '67.356',
        area: '한강권역',
        addr: '경기도 파주시 법원읍 가야리 57-1 법원여자중학교',
        LON: '126.86821667',
        LAT: '37.85208056',
        insdate: '2013-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '85',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-11.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-85',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '79.0-85.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.86821667, 37.85208056] }
    },
    {
      type: 'Feature',
      id: 952,
      properties: {
        DATE: '2024-10-26',
        jiguname: '파주가야_천부',
        gennum: '771546',
        well: '충적',
        pyogo: '67.356',
        area: '한강권역',
        addr: '경기도 파주시 법원읍 가야리 57-1 법원여자중학교',
        LON: '126.86821667',
        LAT: '37.85208056',
        insdate: '2013-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '20',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-20',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '8.0-20.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.86821667, 37.85208056] }
    },
    {
      type: 'Feature',
      id: 953,
      properties: {
        DATE: '2024-10-26',
        jiguname: '파주광탄',
        gennum: '717001',
        well: '암반',
        pyogo: '69.64',
        area: '한강권역',
        addr: '경기도 파주시 광탄면 방축리 산27-2',
        LON: '126.86733056',
        LAT: '37.81511111',
        insdate: '2017-11-30',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜인텔리지오',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-26.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '26-100',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [126.86733056, 37.81511111] }
    },
    {
      type: 'Feature',
      id: 954,
      properties: {
        DATE: '2024-10-26',
        jiguname: '파주맥금',
        gennum: '84017',
        well: '암반',
        pyogo: '8.476',
        area: '한강권역',
        addr: '경기도 파주시 맥금동   909',
        LON: '126.73113333',
        LAT: '37.77066667',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '40',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-32.0',
        cseInHgt: '250',
        cseInDia: '백관',
        cseInMat: '0.0-40.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '22.5-26.5'
      },
      geometry: { type: 'Point', coordinates: [126.73113333, 37.77066667] }
    },
    {
      type: 'Feature',
      id: 955,
      properties: {
        DATE: '2024-10-26',
        jiguname: '파주맥금',
        gennum: '84018',
        well: '충적',
        pyogo: '8.459',
        area: '한강권역',
        addr: '경기도 파주시 맥금동   909',
        LON: '126.73113333',
        LAT: '37.77066667',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '15',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-11.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-15',
        strHgt: '',
        strDia: '',
        strMat: '11.0-15.0'
      },
      geometry: { type: 'Point', coordinates: [126.73113333, 37.77066667] }
    },
    {
      type: 'Feature',
      id: 956,
      properties: {
        DATE: '2024-10-26',
        jiguname: '파주문산',
        gennum: '87244',
        well: '암반',
        pyogo: '28.9',
        area: '한강권역',
        addr: '경기도 파주시 문산읍 이천리 449-1',
        LON: '126.82938056',
        LAT: '37.86050833',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: '한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-6.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '12.0-16.0'
      },
      geometry: { type: 'Point', coordinates: [126.82938056, 37.86050833] }
    },
    {
      type: 'Feature',
      id: 957,
      properties: {
        DATE: '2024-10-26',
        jiguname: '파주법원',
        gennum: '65046',
        well: '암반',
        pyogo: '31.57',
        area: '한강권역',
        addr: '경기도 파주시 법원읍 대능리 530-10',
        LON: '126.85226667',
        LAT: '37.83522222',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '85',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-6.6',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-85.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '64.0-68.0',
        strMat: ' 72.0-76.0'
      },
      geometry: { type: 'Point', coordinates: [126.85226667, 37.83522222] }
    },
    {
      type: 'Feature',
      id: 958,
      properties: {
        DATE: '2024-10-26',
        jiguname: '파주성동_심부2',
        gennum: '769835',
        well: '암반',
        pyogo: '18.585',
        area: '한강권역',
        addr: '경기도 파주시 탄현면 성동리 673-1 통일동산근린공원',
        LON: '126.68810278',
        LAT: '37.78004722',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '82',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-5.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-82.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '77.5-82.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.68810278, 37.78004722] }
    },
    {
      type: 'Feature',
      id: 959,
      properties: {
        DATE: '2024-10-26',
        jiguname: '파주성동_천부',
        gennum: '769836',
        well: '충적',
        pyogo: '18.585',
        area: '한강권역',
        addr: '경기도 파주시 탄현면 성동리 673-1 통일동산근린공원',
        LON: '126.68810278',
        LAT: '37.78004722',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '8.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.68810278, 37.78004722] }
    },
    {
      type: 'Feature',
      id: 960,
      properties: {
        DATE: '2024-10-26',
        jiguname: '평창대화',
        gennum: '9869',
        well: '암반',
        pyogo: '439.87',
        area: '한강권역',
        addr: '강원특별자치도 평창군 대화면 대화리 1172-3 대화면사무소내',
        LON: '128.45752222',
        LAT: '37.50114722',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '50',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-24.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-39.0',
        cseInMat: '200',
        strHgt: '백관',
        strDia: '39.0-45.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [128.45752222, 37.50114722] }
    },
    {
      type: 'Feature',
      id: 961,
      properties: {
        DATE: '2024-10-26',
        jiguname: '평창미탄',
        gennum: '771357',
        well: '암반',
        pyogo: '333',
        area: '한강권역',
        addr: '강원특별자치도 평창군 미탄면 창리 617',
        LON: '128.49591667',
        LAT: '37.34258333',
        insdate: '2021-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '62',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0-17',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0-62',
        cseInMat: '150',
        strHgt: 'PVC(VG1)',
        strDia: '22-26',
        strMat: ' 30-34'
      },
      geometry: { type: 'Point', coordinates: [128.49591667, 37.34258333] }
    },
    {
      type: 'Feature',
      id: 962,
      properties: {
        DATE: '2024-10-26',
        jiguname: '평창방림',
        gennum: '637538',
        well: '암반',
        pyogo: '756.6',
        area: '한강권역',
        addr: '강원특별자치도 평창군 방림면 계촌리 산1-15 1',
        LON: '128.24763333',
        LAT: '37.47843889',
        insdate: '2015-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜대동공영',
        gulDiaTop: '133',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-18.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-133.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '36.0-44.0',
        strMat: ' 80.0-88.0'
      },
      geometry: { type: 'Point', coordinates: [128.24763333, 37.47843889] }
    },
    {
      type: 'Feature',
      id: 963,
      properties: {
        DATE: '2024-10-26',
        jiguname: '평창봉평',
        gennum: '82011',
        well: '암반',
        pyogo: '565.165',
        area: '한강권역',
        addr: '강원특별자치도 평창군 봉평면 원길리 129-25',
        LON: '128.35901111',
        LAT: '37.62114444',
        insdate: '2003-05-30',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-18.0',
        cseInHgt: '250',
        cseInDia: 'STAINLES STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P.V.C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [128.35901111, 37.62114444] }
    },
    {
      type: 'Feature',
      id: 964,
      properties: {
        DATE: '2024-10-26',
        jiguname: '평창봉평',
        gennum: '82012',
        well: '충적',
        pyogo: '565.201',
        area: '한강권역',
        addr: '강원특별자치도 평창군 봉평면 원길리 129-25',
        LON: '128.35901111',
        LAT: '37.62114444',
        insdate: '2003-05-30',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '15',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-11',
        cseInHgt: '200',
        cseInDia: 'STAINLES STEEL',
        cseInMat: '0-15',
        strHgt: '',
        strDia: '',
        strMat: '11.0-15.0'
      },
      geometry: { type: 'Point', coordinates: [128.35901111, 37.62114444] }
    },
    {
      type: 'Feature',
      id: 965,
      properties: {
        DATE: '2024-10-26',
        jiguname: '평창장평_심부2',
        gennum: '771547',
        well: '암반',
        pyogo: '566.158',
        area: '한강권역',
        addr: '강원특별자치도 평창군 용평면 장평리 404-1 장평초등학교',
        LON: '128.41071389',
        LAT: '37.59175000',
        insdate: '2013-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-6.6',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.41071389, 37.59175] }
    },
    {
      type: 'Feature',
      id: 966,
      properties: {
        DATE: '2024-10-26',
        jiguname: '평창장평_천부',
        gennum: '771548',
        well: '충적',
        pyogo: '566.158',
        area: '한강권역',
        addr: '강원특별자치도 평창군 용평면 장평리 404-1 장평초등학교',
        LON: '128.41071389',
        LAT: '37.59175000',
        insdate: '2013-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.41071389, 37.59175] }
    },
    {
      type: 'Feature',
      id: 967,
      properties: {
        DATE: '2024-10-26',
        jiguname: '평창진부',
        gennum: '65587',
        well: '암반',
        pyogo: '540.54',
        area: '한강권역',
        addr: '강원특별자치도 평창군 진부면 하진부리 1290-1',
        LON: '128.54347778',
        LAT: '37.63886389',
        insdate: '2000-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-4.2',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [128.54347778, 37.63886389] }
    },
    {
      type: 'Feature',
      id: 968,
      properties: {
        DATE: '2024-10-26',
        jiguname: '평창평창_신',
        gennum: '625365',
        well: '암반',
        pyogo: '281.8',
        area: '한강권역',
        addr: '강원특별자치도 평창군 평창읍 천동리 351',
        LON: '128.36899444',
        LAT: '37.33964444',
        insdate: '2014-06-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '72',
        cseExHgt: '300',
        cseExDia: '200',
        cseExMat: '0.0-22.5',
        cseInHgt: '200',
        cseInDia: 'STS304',
        cseInMat: '0.0-72.0',
        strHgt: '150',
        strDia: 'P. V. C',
        strMat: '32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [128.36899444, 37.33964444] }
    },
    {
      type: 'Feature',
      id: 969,
      properties: {
        DATE: '2024-10-26',
        jiguname: '평창평창_신',
        gennum: '625366',
        well: '충적',
        pyogo: '281.8',
        area: '한강권역',
        addr: '강원특별자치도 평창군 평창읍 천동리 351',
        LON: '128.36899444',
        LAT: '37.33964444',
        insdate: '2014-06-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '16',
        cseExHgt: '300',
        cseExDia: '200',
        cseExMat: '0.0-3.0',
        cseInHgt: '300',
        cseInDia: '백관',
        cseInMat: '0.0-16.0',
        strHgt: '200',
        strDia: 'STS304',
        strMat: '12.0-16.0'
      },
      geometry: { type: 'Point', coordinates: [128.36899444, 37.33964444] }
    },
    {
      type: 'Feature',
      id: 970,
      properties: {
        DATE: '2024-10-26',
        jiguname: '평택숙성_심부2',
        gennum: '771549',
        well: '암반',
        pyogo: '6.5',
        area: '한강권역',
        addr: '경기도 평택시 오성면 숙성리 99-2 평택시농업기술센터',
        LON: '126.98136944',
        LAT: '37.01415833',
        insdate: '2011-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-18.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '64.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.98136944, 37.01415833] }
    },
    {
      type: 'Feature',
      id: 971,
      properties: {
        DATE: '2024-10-26',
        jiguname: '평택숙성_천부',
        gennum: '771550',
        well: '충적',
        pyogo: '6.5',
        area: '한강권역',
        addr: '경기도 평택시 오성면 숙성리 99-2 평택시농업기술센터',
        LON: '126.98136944',
        LAT: '37.01415833',
        insdate: '2011-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '6.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.98136944, 37.01415833] }
    },
    {
      type: 'Feature',
      id: 972,
      properties: {
        DATE: '2024-10-26',
        jiguname: '평택안중',
        gennum: '95509',
        well: '암반',
        pyogo: '17.54',
        area: '한강권역',
        addr: '경기도 평택시 안중면 덕우리 813',
        LON: '126.91683889',
        LAT: '37.01320000',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국지질조사탐사업협동조합',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-16.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '29.0-33.0',
        strMat: ' 45.0-49.0'
      },
      geometry: { type: 'Point', coordinates: [126.91683889, 37.0132] }
    },
    {
      type: 'Feature',
      id: 973,
      properties: {
        DATE: '2024-10-26',
        jiguname: '평택안중',
        gennum: '95510',
        well: '충적',
        pyogo: '17.56',
        area: '한강권역',
        addr: '경기도 평택시 안중면 덕우리 813',
        LON: '126.91683889',
        LAT: '37.01320000',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국지질조사탐사업협동조합',
        gulDiaTop: '15',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-11.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0-15',
        cseInMat: '',
        strHgt: '',
        strDia: '11.0-15.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [126.91683889, 37.0132] }
    },
    {
      type: 'Feature',
      id: 974,
      properties: {
        DATE: '2024-10-26',
        jiguname: '평택이충_심부2',
        gennum: '737722',
        well: '암반',
        pyogo: '48.1',
        area: '한강권역',
        addr: '경기도 평택시 이충동  246-1',
        LON: '127.07193611',
        LAT: '37.06391111',
        insdate: '2011-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-10.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '66.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.07193611, 37.06391111] }
    },
    {
      type: 'Feature',
      id: 975,
      properties: {
        DATE: '2024-10-26',
        jiguname: '평택이충_천부',
        gennum: '737721',
        well: '충적',
        pyogo: '48.1',
        area: '한강권역',
        addr: '경기도 평택시 이충동  246-1',
        LON: '127.07193611',
        LAT: '37.06391111',
        insdate: '2011-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '6.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.07193611, 37.06391111] }
    },
    {
      type: 'Feature',
      id: 976,
      properties: {
        DATE: '2024-10-26',
        jiguname: '평택진위',
        gennum: '87245',
        well: '암반',
        pyogo: '48.99',
        area: '한강권역',
        addr: '경기도 평택시 진위면 동천리 591-1',
        LON: '127.10057500',
        LAT: '37.12478333',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: '한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-21.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.100575, 37.12478333] }
    },
    {
      type: 'Feature',
      id: 977,
      properties: {
        DATE: '2024-10-26',
        jiguname: '평택통복',
        gennum: '11742',
        well: '암반',
        pyogo: '9.62',
        area: '한강권역',
        addr: '경기도 평택시 통복동  73 어린이공원내',
        LON: '127.08569722',
        LAT: '36.99847500',
        insdate: '1996-09-05',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '69',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-22.5',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0.0-57.6',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 54.0-62.0'
      },
      geometry: { type: 'Point', coordinates: [127.08569722, 36.998475] }
    },
    {
      type: 'Feature',
      id: 978,
      properties: {
        DATE: '2024-10-26',
        jiguname: '평택통복',
        gennum: '11743',
        well: '충적',
        pyogo: '9.62',
        area: '한강권역',
        addr: '경기도 평택시 통복동  73 어린이공원내',
        LON: '127.08569722',
        LAT: '36.99847500',
        insdate: '1996-09-05',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '10.3',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-5.3',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0-10.3',
        cseInMat: '',
        strHgt: '',
        strDia: '5.3-9.3',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [127.08569722, 36.998475] }
    },
    {
      type: 'Feature',
      id: 979,
      properties: {
        DATE: '2024-10-26',
        jiguname: '평택팽성',
        gennum: '778276',
        well: '암반',
        pyogo: '22.8',
        area: '한강권역',
        addr: '경기도 평택시 팽성읍 노와리 산30-1',
        LON: '127.09625833',
        LAT: '36.95646389',
        insdate: '2022-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-52.7',
        cseExMat: '200',
        cseInHgt: '아연도강관',
        cseInDia: '0.0-100',
        cseInMat: '150',
        strHgt: 'P.V.C',
        strDia: '32-60',
        strMat: ' 80-84'
      },
      geometry: { type: 'Point', coordinates: [127.09625833, 36.95646389] }
    },
    {
      type: 'Feature',
      id: 980,
      properties: {
        DATE: '2024-10-26',
        jiguname: '포천대회산',
        gennum: '405429',
        well: '암반',
        pyogo: '116.2',
        area: '한강권역',
        addr: '경기도 포천시 영북면 대회산리 452 체육진흥센터',
        LON: '127.22233889',
        LAT: '38.07725278',
        insdate: '2009-12-14',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜해천 E.T.S',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-24.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '52-56',
        strMat: ' 64-68'
      },
      geometry: { type: 'Point', coordinates: [127.22233889, 38.07725278] }
    },
    {
      type: 'Feature',
      id: 981,
      properties: {
        DATE: '2024-10-26',
        jiguname: '포천영북',
        gennum: '95511',
        well: '암반',
        pyogo: '138.55',
        area: '한강권역',
        addr: '경기도 포천시 영북면 야미리 254-3',
        LON: '127.27157222',
        LAT: '38.05875556',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-30.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '38.0-42.0'
      },
      geometry: { type: 'Point', coordinates: [127.27157222, 38.05875556] }
    },
    {
      type: 'Feature',
      id: 982,
      properties: {
        DATE: '2024-10-26',
        jiguname: '포천영북',
        gennum: '95512',
        well: '충적',
        pyogo: '138.55',
        area: '한강권역',
        addr: '경기도 포천시 영북면 야미리 254-3',
        LON: '127.27157222',
        LAT: '38.05875556',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '23',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-13.0 ',
        cseInHgt: ' 17.0-23.0',
        cseInDia: '200',
        cseInMat: 'STAINLESS STEEL',
        strHgt: '0-23',
        strDia: '',
        strMat: ''
      },
      geometry: { type: 'Point', coordinates: [127.27157222, 38.05875556] }
    },
    {
      type: 'Feature',
      id: 983,
      properties: {
        DATE: '2024-10-26',
        jiguname: '포천화현',
        gennum: '65047',
        well: '암반',
        pyogo: '149.93',
        area: '한강권역',
        addr: '경기도 포천시 화현면 명덕리 37-1',
        LON: '127.27971944',
        LAT: '37.88476944',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '75',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-17.4',
        cseExMat: '250',
        cseInHgt: '백관',
        cseInDia: '0.0-75.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '54.0-58.0',
        strMat: ' 62.0-66.0'
      },
      geometry: { type: 'Point', coordinates: [127.27971944, 37.88476944] }
    },
    {
      type: 'Feature',
      id: 984,
      properties: {
        DATE: '2024-10-26',
        jiguname: '포항구룡포1',
        gennum: '73541',
        well: '암반',
        pyogo: '41.6',
        area: '낙동강권역',
        addr: '경상북도 포항시남구 구룡포읍 석병리 산11',
        LON: '129.57426944',
        LAT: '36.02966111',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-6.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [129.57426944, 36.02966111] }
    },
    {
      type: 'Feature',
      id: 985,
      properties: {
        DATE: '2024-10-26',
        jiguname: '포항기북',
        gennum: '73542',
        well: '암반',
        pyogo: '121.11',
        area: '낙동강권역',
        addr: '경상북도 포항시 북구 기북면 용기리 271-8',
        LON: '129.16691111',
        LAT: '36.12984722',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-8.9',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'PVC',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [129.16691111, 36.12984722] }
    },
    {
      type: 'Feature',
      id: 986,
      properties: {
        DATE: '2024-10-26',
        jiguname: '포항기북',
        gennum: '73543',
        well: '충적',
        pyogo: '121.125',
        area: '낙동강권역',
        addr: '경상북도 포항시 북구 기북면 용기리 271-8',
        LON: '129.16691111',
        LAT: '36.12984722',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0-10',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6-10'
      },
      geometry: { type: 'Point', coordinates: [129.16691111, 36.12984722] }
    },
    {
      type: 'Feature',
      id: 987,
      properties: {
        DATE: '2024-10-26',
        jiguname: '포항동해',
        gennum: '622083',
        well: '암반',
        pyogo: '85.81',
        area: '낙동강권역',
        addr: '경상북도 포항시남구 동해면 석리 26 경상북도 포항시 동해면 석리 26번지',
        LON: '129.47750000',
        LAT: '35.98666667',
        insdate: '2014-12-18',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜대흥개발',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-14.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '30.0-34.0',
        strMat: ' 38.0-46.0'
      },
      geometry: { type: 'Point', coordinates: [129.4775, 35.98666667] }
    },
    {
      type: 'Feature',
      id: 988,
      properties: {
        DATE: '2024-10-26',
        jiguname: '포항신광',
        gennum: '9889',
        well: '암반',
        pyogo: '82.38',
        area: '낙동강권역',
        addr: '경상북도 포항시북구 신광면 토성리 340-3 신광면사무소내',
        LON: '129.26313611',
        LAT: '36.13003889',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '79',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-23.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-79.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [129.26313611, 36.13003889] }
    },
    {
      type: 'Feature',
      id: 989,
      properties: {
        DATE: '2024-10-26',
        jiguname: '포항연일',
        gennum: '7851',
        well: '암반',
        pyogo: '5.89',
        area: '낙동강권역',
        addr: '경상북도 포항시남구 연일읍 유강리 196 포항 제2수원지내',
        LON: '129.31351389',
        LAT: '36.00180833',
        insdate: '1995-12-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원공사',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-39.5',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '30.0-38.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [129.31351389, 36.00180833] }
    },
    {
      type: 'Feature',
      id: 990,
      properties: {
        DATE: '2024-10-26',
        jiguname: '포항연일',
        gennum: '7850',
        well: '충적',
        pyogo: '5.89',
        area: '낙동강권역',
        addr: '경상북도 포항시남구 연일읍 유강리 196 포항 제2수원지내',
        LON: '129.31351389',
        LAT: '36.00180833',
        insdate: '1995-12-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원공사',
        gulDiaTop: '30',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-30.0',
        cseExMat: '250',
        cseInHgt: 'STEEL PIPE',
        cseInDia: '0-30',
        cseInMat: '',
        strHgt: '',
        strDia: '18.0-24.0',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [129.31351389, 36.00180833] }
    },
    {
      type: 'Feature',
      id: 991,
      properties: {
        DATE: '2024-10-26',
        jiguname: '포항읍내_심부2',
        gennum: '737724',
        well: '암반',
        pyogo: '22.1',
        area: '낙동강권역',
        addr: '경상북도 포항시 남구 장기면 읍내리 106',
        LON: '129.49290833',
        LAT: '35.89592222',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '87',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-13.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-87.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '81.0-87.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.49290833, 35.89592222] }
    },
    {
      type: 'Feature',
      id: 992,
      properties: {
        DATE: '2024-10-26',
        jiguname: '포항읍내_천부',
        gennum: '737723',
        well: '충적',
        pyogo: '22.1',
        area: '낙동강권역',
        addr: '경상북도 포항시 남구 장기면 읍내리 106',
        LON: '129.49290833',
        LAT: '35.89592222',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '12',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-12.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '6.0-12.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.49290833, 35.89592222] }
    },
    {
      type: 'Feature',
      id: 993,
      properties: {
        DATE: '2024-10-26',
        jiguname: '포항장흥',
        gennum: '9888',
        well: '암반',
        pyogo: '28.73',
        area: '낙동강권역',
        addr: '경상북도 포항시남구 장흥동  1821 공단수도사업소내',
        LON: '129.37405278',
        LAT: '35.97973889',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '50',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-5.7',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-50.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [129.37405278, 35.97973889] }
    },
    {
      type: 'Feature',
      id: 994,
      properties: {
        DATE: '2024-10-26',
        jiguname: '포항현내_심부2',
        gennum: '737726',
        well: '암반',
        pyogo: '56',
        area: '낙동강권역',
        addr: '경상북도 포항시 북구 기계면 현내리 284',
        LON: '129.21025556',
        LAT: '36.07265278',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '83',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-11.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-83.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '74.0-83.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.21025556, 36.07265278] }
    },
    {
      type: 'Feature',
      id: 995,
      properties: {
        DATE: '2024-10-26',
        jiguname: '포항현내_천부',
        gennum: '737725',
        well: '충적',
        pyogo: '56',
        area: '낙동강권역',
        addr: '경상북도 포항시 북구 기계면 현내리 284',
        LON: '129.21025556',
        LAT: '36.07265278',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [129.21025556, 36.07265278] }
    },
    {
      type: 'Feature',
      id: 996,
      properties: {
        DATE: '2024-10-26',
        jiguname: '하남하산곡',
        gennum: '87246',
        well: '암반',
        pyogo: '81.93',
        area: '한강권역',
        addr: '경기도 하남시 하산곡동  산18-34',
        LON: '127.23071111',
        LAT: '37.51193333',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: '한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-16.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '40.0-44.0'
      },
      geometry: { type: 'Point', coordinates: [127.23071111, 37.51193333] }
    },
    {
      type: 'Feature',
      id: 997,
      properties: {
        DATE: '2024-10-26',
        jiguname: '하동양보',
        gennum: '82047',
        well: '암반',
        pyogo: '30.122',
        area: '영섬권역',
        addr: '경상남도 하동군 양보면 운암리 353-3',
        LON: '127.84557778',
        LAT: '35.04948333',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-10',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'P.V.C',
        strMat: '25.0-29.0'
      },
      geometry: { type: 'Point', coordinates: [127.84557778, 35.04948333] }
    },
    {
      type: 'Feature',
      id: 998,
      properties: {
        DATE: '2024-10-26',
        jiguname: '하동진교_심부2',
        gennum: '769837',
        well: '암반',
        pyogo: '18.44',
        area: '낙동강권역',
        addr: '경상남도 하동군 진교면 진교리 466 진교초등학교',
        LON: '127.89857778',
        LAT: '35.03313333',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '79',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-6.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-79.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '73.0-79.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.89857778, 35.03313333] }
    },
    {
      type: 'Feature',
      id: 999,
      properties: {
        DATE: '2024-10-26',
        jiguname: '하동진교_천부',
        gennum: '769838',
        well: '충적',
        pyogo: '18.44',
        area: '낙동강권역',
        addr: '경상남도 하동군 진교면 진교리 466 진교초등학교',
        LON: '127.89857778',
        LAT: '35.03313333',
        insdate: '2015-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.89857778, 35.03313333] }
    },
    {
      type: 'Feature',
      id: 1000,
      properties: {
        DATE: '2024-10-26',
        jiguname: '하동진정_심부2',
        gennum: '737728',
        well: '암반',
        pyogo: '17.7',
        area: '영섬권역',
        addr: '경상남도 하동군 금남면 진정리 555-1',
        LON: '127.82498611',
        LAT: '34.98600556',
        insdate: '2011-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-11.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '69.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.82498611, 34.98600556] }
    },
    {
      type: 'Feature',
      id: 1001,
      properties: {
        DATE: '2024-10-26',
        jiguname: '하동진정_천부',
        gennum: '737727',
        well: '충적',
        pyogo: '17.7',
        area: '영섬권역',
        addr: '경상남도 하동군 금남면 진정리 555-1',
        LON: '127.82498611',
        LAT: '34.98600556',
        insdate: '2011-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '15',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-15.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '9.0-15.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.82498611, 34.98600556] }
    },
    {
      type: 'Feature',
      id: 1002,
      properties: {
        DATE: '2024-10-26',
        jiguname: '하동하동',
        gennum: '11780',
        well: '암반',
        pyogo: '8.95',
        area: '영섬권역',
        addr: '경상남도 하동군 하동읍 읍내리 1563-3 읍내배수장',
        LON: '127.74104444',
        LAT: '35.07117778',
        insdate: '1996-09-17',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '64',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-16.9',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0.0-64.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '48.0-60.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [127.74104444, 35.07117778] }
    },
    {
      type: 'Feature',
      id: 1003,
      properties: {
        DATE: '2024-10-26',
        jiguname: '하동하동',
        gennum: '11781',
        well: '충적',
        pyogo: '8.94',
        area: '영섬권역',
        addr: '경상남도 하동군 하동읍 읍내리 1563-3 읍내배수장',
        LON: '127.74104444',
        LAT: '35.07117778',
        insdate: '1996-09-17',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '10.7',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-5.7',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0-10.7',
        cseInMat: '',
        strHgt: '',
        strDia: '5.7-9.7',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [127.74104444, 35.07117778] }
    },
    {
      type: 'Feature',
      id: 1004,
      properties: {
        DATE: '2024-10-26',
        jiguname: '하동화개',
        gennum: '73549',
        well: '암반',
        pyogo: '22.93',
        area: '영섬권역',
        addr: '경상남도 하동군 화개면 부춘리 1177',
        LON: '127.66520833',
        LAT: '35.15655278',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '100',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-36.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.66520833, 35.15655278] }
    },
    {
      type: 'Feature',
      id: 1005,
      properties: {
        DATE: '2024-10-26',
        jiguname: '하동화개',
        gennum: '73550',
        well: '충적',
        pyogo: '22.91',
        area: '영섬권역',
        addr: '경상남도 하동군 화개면 부춘리 1177',
        LON: '127.66520833',
        LAT: '35.15655278',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '20',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-12.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-12',
        strHgt: '',
        strDia: '',
        strMat: '8.0-12.0'
      },
      geometry: { type: 'Point', coordinates: [127.66520833, 35.15655278] }
    },
    {
      type: 'Feature',
      id: 1006,
      properties: {
        DATE: '2024-10-26',
        jiguname: '함안가야',
        gennum: '615524',
        well: '암반',
        pyogo: '20.26',
        area: '낙동강권역',
        addr: '경상남도 함안군 가야읍 혈곡리 245-1',
        LON: '128.38386111',
        LAT: '35.25538611',
        insdate: '2013-11-12',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부/한국수자원공사',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-13.0',
        cseExMat: '200',
        cseInHgt: 'STS304',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '36-40',
        strMat: ' 48-52'
      },
      geometry: { type: 'Point', coordinates: [128.38386111, 35.25538611] }
    },
    {
      type: 'Feature',
      id: 1007,
      properties: {
        DATE: '2024-10-26',
        jiguname: '함안군북',
        gennum: '718265',
        well: '암반',
        pyogo: '38.9',
        area: '낙동강권역',
        addr: '경상남도 함안군 군북면 사도리 997-2',
        LON: '128.31111111',
        LAT: '35.27944444',
        insdate: '2018-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-15.0',
        cseExMat: '200',
        cseInHgt: 'SST',
        cseInDia: '0.0-100',
        cseInMat: '150',
        strHgt: 'P.V.C(VG1)',
        strDia: '',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [128.31111111, 35.27944444] }
    },
    {
      type: 'Feature',
      id: 1008,
      properties: {
        DATE: '2024-10-26',
        jiguname: '함안칠원_신',
        gennum: '625361',
        well: '암반',
        pyogo: '43',
        area: '낙동강권역',
        addr: '경상남도 함안군 칠원면 예곡리 832-10',
        LON: '128.52588611',
        LAT: '35.26992222',
        insdate: '2014-05-09',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '102',
        cseExHgt: '300',
        cseExDia: '200',
        cseExMat: '0.0-14.5',
        cseInHgt: '200',
        cseInDia: 'STS304',
        cseInMat: '0.0-102.0',
        strHgt: '150',
        strDia: 'P. V. C',
        strMat: '52-56'
      },
      geometry: { type: 'Point', coordinates: [128.52588611, 35.26992222] }
    },
    {
      type: 'Feature',
      id: 1009,
      properties: {
        DATE: '2024-10-26',
        jiguname: '함안칠원_신',
        gennum: '625362',
        well: '충적',
        pyogo: '43',
        area: '낙동강권역',
        addr: '경상남도 함안군 칠원면 예곡리 832-10',
        LON: '128.52588611',
        LAT: '35.26992222',
        insdate: '2014-05-09',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '11',
        cseExHgt: '300',
        cseExDia: '200',
        cseExMat: '0.0-3.0',
        cseInHgt: '300',
        cseInDia: '백관',
        cseInMat: '0.0-11.0',
        strHgt: '200',
        strDia: 'STS304',
        strMat: '7.0-11.0'
      },
      geometry: { type: 'Point', coordinates: [128.52588611, 35.26992222] }
    },
    {
      type: 'Feature',
      id: 1010,
      properties: {
        DATE: '2024-10-26',
        jiguname: '함양마천',
        gennum: '65605',
        well: '암반',
        pyogo: '299.742',
        area: '낙동강권역',
        addr: '경상남도 함양군 마천면 가흥리 513-9 마천면 사무소',
        LON: '127.66305833',
        LAT: '35.39635000',
        insdate: '2000-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-20.5',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.66305833, 35.39635] }
    },
    {
      type: 'Feature',
      id: 1011,
      properties: {
        DATE: '2024-10-26',
        jiguname: '함양마천',
        gennum: '65606',
        well: '충적',
        pyogo: '299.78',
        area: '낙동강권역',
        addr: '경상남도 함양군 마천면 가흥리 513-9 마천면 사무소',
        LON: '127.66305833',
        LAT: '35.39635000',
        insdate: '2000-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '19',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-15.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-19',
        strHgt: '',
        strDia: '',
        strMat: '15.0-19.0'
      },
      geometry: { type: 'Point', coordinates: [127.66305833, 35.39635] }
    },
    {
      type: 'Feature',
      id: 1012,
      properties: {
        DATE: '2024-10-26',
        jiguname: '함양병곡',
        gennum: '82048',
        well: '암반',
        pyogo: '218.913',
        area: '낙동강권역',
        addr: '경상남도 함양군 병곡면 송평리 628-3',
        LON: '127.67816111',
        LAT: '35.52960556',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-12',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'P.V.C',
        strMat: '24.6-28.6'
      },
      geometry: { type: 'Point', coordinates: [127.67816111, 35.52960556] }
    },
    {
      type: 'Feature',
      id: 1013,
      properties: {
        DATE: '2024-10-26',
        jiguname: '함양병곡',
        gennum: '82049',
        well: '충적',
        pyogo: '218.862',
        area: '낙동강권역',
        addr: '경상남도 함양군 병곡면 송평리 628-3',
        LON: '127.67816111',
        LAT: '35.52960556',
        insdate: '2003-06-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0-6',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6.0-10.0'
      },
      geometry: { type: 'Point', coordinates: [127.67816111, 35.52960556] }
    },
    {
      type: 'Feature',
      id: 1014,
      properties: {
        DATE: '2024-10-26',
        jiguname: '함양상남_심부2',
        gennum: '771551',
        well: '암반',
        pyogo: '727',
        area: '낙동강권역',
        addr: '경상남도 함양군 서상면 상남리 1050 경상남도덕유교육원',
        LON: '127.67506944',
        LAT: '35.74771944',
        insdate: '2009-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '165',
        cseExHgt: '165',
        cseExDia: '0.0-10.5',
        cseExMat: '165',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.67506944, 35.74771944] }
    },
    {
      type: 'Feature',
      id: 1015,
      properties: {
        DATE: '2024-10-26',
        jiguname: '함양상남_천부',
        gennum: '771552',
        well: '충적',
        pyogo: '727',
        area: '낙동강권역',
        addr: '경상남도 함양군 서상면 상남리 1050 경상남도덕유교육원',
        LON: '127.67506944',
        LAT: '35.74771944',
        insdate: '2009-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '14',
        gulDiaBtm: '165',
        cseExHgt: '165',
        cseExDia: '0.0-0.0',
        cseExMat: '165',
        cseInHgt: '강관',
        cseInDia: '0-14',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '5.0-14.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.67506944, 35.74771944] }
    },
    {
      type: 'Feature',
      id: 1016,
      properties: {
        DATE: '2024-10-26',
        jiguname: '함양서상',
        gennum: '612028',
        well: '암반',
        pyogo: '735.588',
        area: '낙동강권역',
        addr: '경상남도 함양군 서상면 상남리 1567-5',
        LON: '127.66655833',
        LAT: '35.72250000',
        insdate: '2012-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '100',
        cseExHgt: '200',
        cseExDia: '200',
        cseExMat: '0.0-6.0',
        cseInHgt: '200',
        cseInDia: 'STS304',
        cseInMat: '0.0-100.0',
        strHgt: '150',
        strDia: 'P. V. C',
        strMat: ''
      },
      geometry: { type: 'Point', coordinates: [127.66655833, 35.7225] }
    },
    {
      type: 'Feature',
      id: 1017,
      properties: {
        DATE: '2024-10-26',
        jiguname: '함양안의_심부2',
        gennum: '771553',
        well: '암반',
        pyogo: '215',
        area: '낙동강권역',
        addr: '경상남도 함양군 안의면 석천리 93 안의고등학교',
        LON: '127.81888889',
        LAT: '35.63055556',
        insdate: '2009-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '79',
        gulDiaBtm: '165',
        cseExHgt: '165',
        cseExDia: '0.0-8.0',
        cseExMat: '165',
        cseInHgt: '강관',
        cseInDia: '0-79',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '62.5-79.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.81888889, 35.63055556] }
    },
    {
      type: 'Feature',
      id: 1018,
      properties: {
        DATE: '2024-10-26',
        jiguname: '함양안의_천부',
        gennum: '771554',
        well: '충적',
        pyogo: '215',
        area: '낙동강권역',
        addr: '경상남도 함양군 안의면 석천리 93 안의고등학교',
        LON: '127.81888889',
        LAT: '35.63055556',
        insdate: '2009-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '11',
        gulDiaBtm: '165',
        cseExHgt: '165',
        cseExDia: '0.0-0.0',
        cseExMat: '165',
        cseInHgt: '강관',
        cseInDia: '0-11',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '5.0-11.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.81888889, 35.63055556] }
    },
    {
      type: 'Feature',
      id: 1019,
      properties: {
        DATE: '2024-10-26',
        jiguname: '함양휴천',
        gennum: '753912',
        well: '암반',
        pyogo: '188.376',
        area: '낙동강권역',
        addr: '경상남도 함양군 휴천면 목현리 87-5',
        LON: '127.75064722',
        LAT: '35.48077222',
        insdate: '2019-12-05',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '250',
        cseExHgt: '200',
        cseExDia: '0-28',
        cseExMat: '250',
        cseInHgt: '강관',
        cseInDia: '0-100',
        cseInMat: '200',
        strHgt: 'PVC',
        strDia: '28-100',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [127.75064722, 35.48077222] }
    },
    {
      type: 'Feature',
      id: 1020,
      properties: {
        DATE: '2024-10-26',
        jiguname: '함양휴천',
        gennum: '753911',
        well: '충적',
        pyogo: '188.402',
        area: '낙동강권역',
        addr: '경상남도 함양군 휴천면 목현리 87-5',
        LON: '127.75064722',
        LAT: '35.48077222',
        insdate: '2019-12-05',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '18',
        gulDiaBtm: '250',
        cseExHgt: '200',
        cseExDia: '0-4',
        cseExMat: '250',
        cseInHgt: '강관',
        cseInDia: '0-18',
        cseInMat: '200',
        strHgt: 'PVC',
        strDia: '4-18',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [127.75064722, 35.48077222] }
    },
    {
      type: 'Feature',
      id: 1021,
      properties: {
        DATE: '2024-10-26',
        jiguname: '함평신광',
        gennum: '9899',
        well: '암반',
        pyogo: '40.56',
        area: '영섬권역',
        addr: '전라남도 함평군 신광면 월암리 201',
        LON: '126.49726111',
        LAT: '35.15408889',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-5.5',
        cseExMat: '250',
        cseInHgt: '백관',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [126.49726111, 35.15408889] }
    },
    {
      type: 'Feature',
      id: 1022,
      properties: {
        DATE: '2024-10-26',
        jiguname: '함평신광',
        gennum: '9900',
        well: '충적',
        pyogo: '40.52',
        area: '영섬권역',
        addr: '전라남도 함평군 신광면 월암리 201',
        LON: '126.49726111',
        LAT: '35.15408889',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '9.5',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-5.5',
        cseExMat: '250',
        cseInHgt: '백관',
        cseInDia: '0-9.5',
        cseInMat: '',
        strHgt: '',
        strDia: '5.5-9.5',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [126.49726111, 35.15408889] }
    },
    {
      type: 'Feature',
      id: 1023,
      properties: {
        DATE: '2024-10-26',
        jiguname: '함평엄다_심부2',
        gennum: '737730',
        well: '암반',
        pyogo: '15',
        area: '영섬권역',
        addr: '전라남도 함평군 엄다면 엄다리 821',
        LON: '126.51591944',
        LAT: '35.01070278',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '85',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-4.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-85.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '75.0-85.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.51591944, 35.01070278] }
    },
    {
      type: 'Feature',
      id: 1024,
      properties: {
        DATE: '2024-10-26',
        jiguname: '함평엄다_천부',
        gennum: '737729',
        well: '충적',
        pyogo: '15',
        area: '영섬권역',
        addr: '전라남도 함평군 엄다면 엄다리 821',
        LON: '126.51591944',
        LAT: '35.01070278',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '2.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.51591944, 35.01070278] }
    },
    {
      type: 'Feature',
      id: 1025,
      properties: {
        DATE: '2024-10-26',
        jiguname: '함평운교_심부2',
        gennum: '771555',
        well: '암반',
        pyogo: '64',
        area: '영섬권역',
        addr: '전라남도 함평군 대동면 운교리 500-1 함평자연생태공원',
        LON: '126.50069722',
        LAT: '35.13585556',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-6.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '68.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.50069722, 35.13585556] }
    },
    {
      type: 'Feature',
      id: 1026,
      properties: {
        DATE: '2024-10-26',
        jiguname: '함평운교_천부',
        gennum: '771556',
        well: '충적',
        pyogo: '64',
        area: '영섬권역',
        addr: '전라남도 함평군 대동면 운교리 500-1 함평자연생태공원',
        LON: '126.50069722',
        LAT: '35.13585556',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.50069722, 35.13585556] }
    },
    {
      type: 'Feature',
      id: 1027,
      properties: {
        DATE: '2024-10-26',
        jiguname: '함평월야',
        gennum: '514308',
        well: '암반',
        pyogo: '30.67',
        area: '영섬권역',
        addr: '전라남도 함평군 월야면 계림리 622-28',
        LON: '126.62428889',
        LAT: '35.19975556',
        insdate: '2010-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '유성삼정개발㈜',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-20.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '28-32',
        strMat: ' 52.2-56.2'
      },
      geometry: { type: 'Point', coordinates: [126.62428889, 35.19975556] }
    },
    {
      type: 'Feature',
      id: 1028,
      properties: {
        DATE: '2024-10-26',
        jiguname: '함평월야',
        gennum: '514309',
        well: '충적',
        pyogo: '30.67',
        area: '영섬권역',
        addr: '전라남도 함평군 월야면 계림리 622-28',
        LON: '126.62428889',
        LAT: '35.19975556',
        insdate: '2010-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '유성삼정개발㈜',
        gulDiaTop: '15',
        gulDiaBtm: '400',
        cseExHgt: '300',
        cseExDia: '0.0-6.0',
        cseExMat: '300',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-18.0',
        cseInMat: '200',
        strHgt: 'STAINLESS STEEL',
        strDia: '6.0-9.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [126.62428889, 35.19975556] }
    },
    {
      type: 'Feature',
      id: 1029,
      properties: {
        DATE: '2024-10-26',
        jiguname: '함평월야2',
        gennum: '737732',
        well: '암반',
        pyogo: '30.43',
        area: '영섬권역',
        addr: '전라남도 함평군 월야면 월야리 758-15',
        LON: '126.62428889',
        LAT: '35.19975556',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-6.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.62428889, 35.19975556] }
    },
    {
      type: 'Feature',
      id: 1030,
      properties: {
        DATE: '2024-10-26',
        jiguname: '함평월야2',
        gennum: '737731',
        well: '충적',
        pyogo: '30.43',
        area: '영섬권역',
        addr: '전라남도 함평군 월야면 월야리 758-15',
        LON: '126.62428889',
        LAT: '35.19975556',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-3.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.62428889, 35.19975556] }
    },
    {
      type: 'Feature',
      id: 1031,
      properties: {
        DATE: '2024-10-26',
        jiguname: '합천묘산',
        gennum: '778277',
        well: '암반',
        pyogo: '125',
        area: '낙동강권역',
        addr: '경상남도 합천군 묘산면 화양리 32-1',
        LON: '128.14353333',
        LAT: '35.68023333',
        insdate: '2022-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-12.0',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P.V.C',
        strDia: '40.0-48.0',
        strMat: ' 50.0-58.0'
      },
      geometry: { type: 'Point', coordinates: [128.14353333, 35.68023333] }
    },
    {
      type: 'Feature',
      id: 1032,
      properties: {
        DATE: '2024-10-26',
        jiguname: '합천묘산',
        gennum: '778278',
        well: '충적',
        pyogo: '125',
        area: '낙동강권역',
        addr: '경상남도 합천군 묘산면 화양리 32-1',
        LON: '128.14364167',
        LAT: '35.68022778',
        insdate: '2022-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '15',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-5.1',
        cseExMat: '300',
        cseInHgt: '백관',
        cseInDia: '0.0-15.0',
        cseInMat: '200',
        strHgt: 'P.V.C',
        strDia: '3.0-15.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [128.14364167, 35.68022778] }
    },
    {
      type: 'Feature',
      id: 1033,
      properties: {
        DATE: '2024-10-26',
        jiguname: '합천봉산',
        gennum: '778279',
        well: '암반',
        pyogo: '207.9',
        area: '낙동강권역',
        addr: '경상남도 합천군 봉산면 계산리 산291-1',
        LON: '128.05760000',
        LAT: '35.58809167',
        insdate: '2022-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '85.2',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-11.0',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0.0-85.2',
        cseInMat: '150',
        strHgt: 'P.V.C',
        strDia: '11.0-85.2',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [128.0576, 35.58809167] }
    },
    {
      type: 'Feature',
      id: 1034,
      properties: {
        DATE: '2024-10-26',
        jiguname: '합천삼가',
        gennum: '87268',
        well: '암반',
        pyogo: '98.77',
        area: '낙동강권역',
        addr: '경상남도 합천군 삼가면 양전리 719-33',
        LON: '128.13891389',
        LAT: '35.42100833',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: '한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-5.7',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '46.0-50.0'
      },
      geometry: { type: 'Point', coordinates: [128.13891389, 35.42100833] }
    },
    {
      type: 'Feature',
      id: 1035,
      properties: {
        DATE: '2024-10-26',
        jiguname: '합천야로',
        gennum: '84041',
        well: '암반',
        pyogo: '108.009',
        area: '낙동강권역',
        addr: '경상남도 합천군 야로면 야로리  463-28',
        LON: '128.17088889',
        LAT: '35.70687500',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-15.0',
        cseInHgt: '250',
        cseInDia: '백 관',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '54.0-58.0'
      },
      geometry: { type: 'Point', coordinates: [128.17088889, 35.706875] }
    },
    {
      type: 'Feature',
      id: 1036,
      properties: {
        DATE: '2024-10-26',
        jiguname: '합천야천',
        gennum: '718261',
        well: '암반',
        pyogo: '254',
        area: '낙동강권역',
        addr: '경상남도 합천군 가야면 야천리 951',
        LON: '128.13583333',
        LAT: '35.76833333',
        insdate: '2018-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-24.0',
        cseExMat: '200',
        cseInHgt: 'Stainless Steel',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'P.V.C',
        strDia: '74.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.13583333, 35.76833333] }
    },
    {
      type: 'Feature',
      id: 1037,
      properties: {
        DATE: '2024-10-26',
        jiguname: '합천야천',
        gennum: '718262',
        well: '충적',
        pyogo: '254',
        area: '낙동강권역',
        addr: '경상남도 합천군 가야면 야천리 951',
        LON: '128.13583333',
        LAT: '35.76833333',
        insdate: '2018-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: 'Stainless Steel',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'P.V.C',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.13583333, 35.76833333] }
    },
    {
      type: 'Feature',
      id: 1038,
      properties: {
        DATE: '2024-10-26',
        jiguname: '합천용지_심부2',
        gennum: '737734',
        well: '암반',
        pyogo: '57.4',
        area: '낙동강권역',
        addr: '경상남도 합천군 용주면 용지리 268-1',
        LON: '128.11306944',
        LAT: '35.54091667',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '87',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-3.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-87.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '83.0-87.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.11306944, 35.54091667] }
    },
    {
      type: 'Feature',
      id: 1039,
      properties: {
        DATE: '2024-10-26',
        jiguname: '합천용지_천부',
        gennum: '737733',
        well: '충적',
        pyogo: '57.4',
        area: '낙동강권역',
        addr: '경상남도 합천군 용주면 용지리 268-1',
        LON: '128.11306944',
        LAT: '35.54091667',
        insdate: '2017-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '15',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-15.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '10.0-15.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.11306944, 35.54091667] }
    },
    {
      type: 'Feature',
      id: 1040,
      properties: {
        DATE: '2024-10-26',
        jiguname: '합천적중',
        gennum: '65048',
        well: '암반',
        pyogo: '18.51',
        area: '낙동강권역',
        addr: '경상남도 합천군 적중면 부수리 1071-4',
        LON: '128.28523056',
        LAT: '35.55315833',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '71',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-16.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0.0-71.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '48.0-52.0'
      },
      geometry: { type: 'Point', coordinates: [128.28523056, 35.55315833] }
    },
    {
      type: 'Feature',
      id: 1041,
      properties: {
        DATE: '2024-10-26',
        jiguname: '합천적중',
        gennum: '65049',
        well: '충적',
        pyogo: '18.54',
        area: '낙동강권역',
        addr: '경상남도 합천군 적중면 부수리 1071-4',
        LON: '128.28523056',
        LAT: '35.55315833',
        insdate: '1999-05-10',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '16',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-16.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL(4T)',
        cseInMat: '0-16',
        strHgt: '',
        strDia: '',
        strMat: '11.0-15.0'
      },
      geometry: { type: 'Point', coordinates: [128.28523056, 35.55315833] }
    },
    {
      type: 'Feature',
      id: 1042,
      properties: {
        DATE: '2024-10-26',
        jiguname: '합천합천',
        gennum: '11787',
        well: '암반',
        pyogo: '48.05',
        area: '낙동강권역',
        addr: '경상남도 합천군 합천읍 서산리 1301-84',
        LON: '128.14935278',
        LAT: '35.59011944',
        insdate: '1996-09-17',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '68',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-16.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0.0-68.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '28.0-32.0',
        strMat: ' 40.0-44.0'
      },
      geometry: { type: 'Point', coordinates: [128.14935278, 35.59011944] }
    },
    {
      type: 'Feature',
      id: 1043,
      properties: {
        DATE: '2024-10-26',
        jiguname: '합천합천',
        gennum: '11788',
        well: '충적',
        pyogo: '48.05',
        area: '낙동강권역',
        addr: '경상남도 합천군 합천읍 서산리 1301-84',
        LON: '128.14935278',
        LAT: '35.59011944',
        insdate: '1996-09-17',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '11.5',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-11.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL PIPE',
        cseInDia: '0-11.5',
        cseInMat: '',
        strHgt: '',
        strDia: '6.5-10.5',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [128.14935278, 35.59011944] }
    },
    {
      type: 'Feature',
      id: 1044,
      properties: {
        DATE: '2024-10-26',
        jiguname: '해남북일',
        gennum: '771367',
        well: '암반',
        pyogo: '1.7',
        area: '영섬권역',
        addr: '전라남도 해남군 북일면 용일리 1550',
        LON: '126.71291389',
        LAT: '34.46553333',
        insdate: '2021-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100.6',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0-24',
        cseExMat: '200',
        cseInHgt: 'SST',
        cseInDia: '0-100.6',
        cseInMat: '150',
        strHgt: 'pvc',
        strDia: '52-64',
        strMat: ' 68-80'
      },
      geometry: { type: 'Point', coordinates: [126.71291389, 34.46553333] }
    },
    {
      type: 'Feature',
      id: 1045,
      properties: {
        DATE: '2024-10-26',
        jiguname: '해남북일',
        gennum: '771388',
        well: '충적',
        pyogo: '1.7',
        area: '영섬권역',
        addr: '전라남도 해남군 북일면 용일리 1550',
        LON: '126.71291389',
        LAT: '34.46553333',
        insdate: '2021-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '24',
        gulDiaBtm: '400',
        cseExHgt: '300',
        cseExDia: '0-3.0',
        cseExMat: '300',
        cseInHgt: 'SST',
        cseInDia: '0-24.0',
        cseInMat: '250',
        strHgt: 'pvc',
        strDia: '6.5-22',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [126.71291389, 34.46553333] }
    },
    {
      type: 'Feature',
      id: 1046,
      properties: {
        DATE: '2024-10-26',
        jiguname: '해남상가_심부2',
        gennum: '771557',
        well: '암반',
        pyogo: '76.683',
        area: '영섬권역',
        addr: '전라남도 해남군 삼산면 상가리 317 중리마을회관',
        LON: '126.63910833',
        LAT: '34.52936389',
        insdate: '2013-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-13.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '125',
        strHgt: 'PVC',
        strDia: '35.0-80.0',
        strMat: '125'
      },
      geometry: { type: 'Point', coordinates: [126.63910833, 34.52936389] }
    },
    {
      type: 'Feature',
      id: 1047,
      properties: {
        DATE: '2024-10-26',
        jiguname: '해남상가_천부',
        gennum: '771558',
        well: '충적',
        pyogo: '76.683',
        area: '영섬권역',
        addr: '전라남도 해남군 삼산면 상가리 317 중리마을회관',
        LON: '126.63910833',
        LAT: '34.52936389',
        insdate: '2013-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.63910833, 34.52936389] }
    },
    {
      type: 'Feature',
      id: 1048,
      properties: {
        DATE: '2024-10-26',
        jiguname: '해남옥천',
        gennum: '622084',
        well: '암반',
        pyogo: '11.19',
        area: '영섬권역',
        addr: '전라남도 해남군 옥천면 신죽리 600 전라남도 해남군 옥천면 신죽리 600번지',
        LON: '126.64305556',
        LAT: '34.61027778',
        insdate: '2014-11-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜대흥개발',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-24.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '36-40',
        strMat: ' 52-56'
      },
      geometry: { type: 'Point', coordinates: [126.64305556, 34.61027778] }
    },
    {
      type: 'Feature',
      id: 1049,
      properties: {
        DATE: '2024-10-26',
        jiguname: '해남우항_심부2',
        gennum: '737736',
        well: '암반',
        pyogo: '12',
        area: '영섬권역',
        addr: '전라남도 해남군 황산면 우항리 405-4',
        LON: '126.43625000',
        LAT: '34.57739444',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '90',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-9.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-90.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '80.0-90.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.43625, 34.57739444] }
    },
    {
      type: 'Feature',
      id: 1050,
      properties: {
        DATE: '2024-10-26',
        jiguname: '해남우항_천부',
        gennum: '737735',
        well: '충적',
        pyogo: '12',
        area: '영섬권역',
        addr: '전라남도 해남군 황산면 우항리 405-4',
        LON: '126.43625000',
        LAT: '34.57739444',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '30',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-9.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-30.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '20.0-30.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.43625, 34.57739444] }
    },
    {
      type: 'Feature',
      id: 1051,
      properties: {
        DATE: '2024-10-26',
        jiguname: '해남해남',
        gennum: '9901',
        well: '암반',
        pyogo: '48.76',
        area: '영섬권역',
        addr: '전라남도 해남군 해남읍 용정리 산4-1',
        LON: '126.59085833',
        LAT: '34.55814722',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '76',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-4.0',
        cseExMat: '250',
        cseInHgt: '백관',
        cseInDia: '0.0-76.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [126.59085833, 34.55814722] }
    },
    {
      type: 'Feature',
      id: 1052,
      properties: {
        DATE: '2024-10-26',
        jiguname: '해남현산',
        gennum: '65599',
        well: '암반',
        pyogo: '14.07',
        area: '영섬권역',
        addr: '전라남도 해남군 현산면 구산리 529-10',
        LON: '126.58497778',
        LAT: '34.42958611',
        insdate: '2000-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-6.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.58497778, 34.42958611] }
    },
    {
      type: 'Feature',
      id: 1053,
      properties: {
        DATE: '2024-10-26',
        jiguname: '해남화내_심부2',
        gennum: '771559',
        well: '암반',
        pyogo: '16',
        area: '영섬권역',
        addr: '전라남도 해남군 마산면 화내리 263-2 마산면사무소',
        LON: '126.56873611',
        LAT: '34.62164167',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '85',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-16.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-85',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '76.0-85.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.56873611, 34.62164167] }
    },
    {
      type: 'Feature',
      id: 1054,
      properties: {
        DATE: '2024-10-26',
        jiguname: '해남화내_천부',
        gennum: '771560',
        well: '충적',
        pyogo: '16',
        area: '영섬권역',
        addr: '전라남도 해남군 마산면 화내리 263-2 마산면사무소',
        LON: '126.56873611',
        LAT: '34.62164167',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.56873611, 34.62164167] }
    },
    {
      type: 'Feature',
      id: 1055,
      properties: {
        DATE: '2024-10-26',
        jiguname: '홍성결성',
        gennum: '84025',
        well: '암반',
        pyogo: '46.435',
        area: '금강권역',
        addr: '충청남도 홍성군 결성면 성호리  314-2 수도사업소내',
        LON: '126.53644444',
        LAT: '36.53579722',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-2.8',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '30.0-34.0'
      },
      geometry: { type: 'Point', coordinates: [126.53644444, 36.53579722] }
    },
    {
      type: 'Feature',
      id: 1056,
      properties: {
        DATE: '2024-10-26',
        jiguname: '홍성금당_심부2',
        gennum: '771561',
        well: '암반',
        pyogo: '49',
        area: '금강권역',
        addr: '충청남도 홍성군 홍동면 금당리 656 금당초등학교',
        LON: '126.72381944',
        LAT: '36.56844444',
        insdate: '2016-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-11.1',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-100',
        cseInMat: '125',
        strHgt: 'PVC',
        strDia: '50.0-100.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.72381944, 36.56844444] }
    },
    {
      type: 'Feature',
      id: 1057,
      properties: {
        DATE: '2024-10-26',
        jiguname: '홍성금당_천부',
        gennum: '771562',
        well: '충적',
        pyogo: '49',
        area: '금강권역',
        addr: '충청남도 홍성군 홍동면 금당리 656 금당초등학교',
        LON: '126.72381944',
        LAT: '36.56844444',
        insdate: '2016-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.72381944, 36.56844444] }
    },
    {
      type: 'Feature',
      id: 1058,
      properties: {
        DATE: '2024-10-26',
        jiguname: '홍성내현_심부2',
        gennum: '771563',
        well: '암반',
        pyogo: '44.3',
        area: '금강권역',
        addr: '충청남도 홍성군 구항면 내현리 85 구항초등학교',
        LON: '126.60683611',
        LAT: '36.58504722',
        insdate: '2016-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-14.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-100',
        cseInMat: '125',
        strHgt: 'PVC',
        strDia: '50.0-100.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.60683611, 36.58504722] }
    },
    {
      type: 'Feature',
      id: 1059,
      properties: {
        DATE: '2024-10-26',
        jiguname: '홍성내현_천부',
        gennum: '771564',
        well: '충적',
        pyogo: '44.3',
        area: '금강권역',
        addr: '충청남도 홍성군 구항면 내현리 85 구항초등학교',
        LON: '126.60683611',
        LAT: '36.58504722',
        insdate: '2016-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '12',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-12',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '6.0-12.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.60683611, 36.58504722] }
    },
    {
      type: 'Feature',
      id: 1060,
      properties: {
        DATE: '2024-10-26',
        jiguname: '홍성장곡',
        gennum: '639094',
        well: '암반',
        pyogo: '54.38',
        area: '금강권역',
        addr: '충청남도 홍성군 장곡면 도산리 532',
        LON: '126.69548056',
        LAT: '36.50056111',
        insdate: '2016-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '151',
        cseExHgt: '300',
        cseExDia: '200',
        cseExMat: '0.0-15.2m',
        cseInHgt: '200',
        cseInDia: 'Stainless Steel',
        cseInMat: '0.0-151.0',
        strHgt: '150',
        strDia: 'PVC(VG1)',
        strMat: '71-79'
      },
      geometry: { type: 'Point', coordinates: [126.69548056, 36.50056111] }
    },
    {
      type: 'Feature',
      id: 1061,
      properties: {
        DATE: '2024-10-26',
        jiguname: '홍성홍성',
        gennum: '9918',
        well: '암반',
        pyogo: '50.78',
        area: '금강권역',
        addr: '충청남도 홍성군 홍성읍 고암리 560 홍성중학교내',
        LON: '126.67425556',
        LAT: '36.59748611',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '57.5',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-49.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-57.5',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [126.67425556, 36.59748611] }
    },
    {
      type: 'Feature',
      id: 1062,
      properties: {
        DATE: '2024-10-26',
        jiguname: '홍천내면',
        gennum: '717007',
        well: '암반',
        pyogo: '598.2',
        area: '한강권역',
        addr: '강원특별자치도 홍천군 내면 방내리 산293-2',
        LON: '128.25206944',
        LAT: '37.83695556',
        insdate: '2017-11-30',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '벽산엔지니어링㈜',
        gulDiaTop: '130',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-5.5',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-130',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '56-68',
        strMat: ' 84-100'
      },
      geometry: { type: 'Point', coordinates: [128.25206944, 37.83695556] }
    },
    {
      type: 'Feature',
      id: 1063,
      properties: {
        DATE: '2024-10-26',
        jiguname: '홍천도관_심부2',
        gennum: '771565',
        well: '암반',
        pyogo: '224.09',
        area: '한강권역',
        addr: '강원특별자치도 홍천군 내촌면 도관리 383-1 내촌면체육공원',
        LON: '128.09110278',
        LAT: '37.81893611',
        insdate: '2016-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '82',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-12.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-82',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '76.0-82.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.09110278, 37.81893611] }
    },
    {
      type: 'Feature',
      id: 1064,
      properties: {
        DATE: '2024-10-26',
        jiguname: '홍천도관_천부',
        gennum: '771566',
        well: '충적',
        pyogo: '224.09',
        area: '한강권역',
        addr: '강원특별자치도 홍천군 내촌면 도관리 383-1 내촌면체육공원',
        LON: '128.09110278',
        LAT: '37.81893611',
        insdate: '2016-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '13',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-13',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '7.0-13.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.09110278, 37.81893611] }
    },
    {
      type: 'Feature',
      id: 1065,
      properties: {
        DATE: '2024-10-26',
        jiguname: '홍천동면',
        gennum: '717005',
        well: '암반',
        pyogo: '332.98',
        area: '한강권역',
        addr: '강원특별자치도 홍천군 영귀미면 노천리 산206-2',
        LON: '128.02349722',
        LAT: '37.69716944',
        insdate: '2017-11-30',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '동부엔지니어링㈜',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-11.6',
        cseExMat: '200',
        cseInHgt: 'STEEL',
        cseInDia: '0.0-100',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '80-100',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [128.02349722, 37.69716944] }
    },
    {
      type: 'Feature',
      id: 1066,
      properties: {
        DATE: '2024-10-26',
        jiguname: '홍천서면',
        gennum: '95519',
        well: '암반',
        pyogo: '64.26',
        area: '한강권역',
        addr: '강원특별자치도 홍천군 서면 모곡리 산234-4',
        LON: '127.60790000',
        LAT: '37.67522500',
        insdate: '2005-12-19',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-1.5',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '26.0-30.0'
      },
      geometry: { type: 'Point', coordinates: [127.6079, 37.675225] }
    },
    {
      type: 'Feature',
      id: 1067,
      properties: {
        DATE: '2024-10-26',
        jiguname: '홍천서석',
        gennum: '82015',
        well: '암반',
        pyogo: '299.78',
        area: '한강권역',
        addr: '강원특별자치도 홍천군 서석면 풍암리 1074-1',
        LON: '128.17338333',
        LAT: '37.72110278',
        insdate: '2002-06-30',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-9.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [128.17338333, 37.72110278] }
    },
    {
      type: 'Feature',
      id: 1068,
      properties: {
        DATE: '2024-10-26',
        jiguname: '홍천어유포',
        gennum: '753925',
        well: '암반',
        pyogo: '84.817',
        area: '한강권역',
        addr: '강원특별자치도 홍천군 서면 어유포리 205-7',
        LON: '127.69558333',
        LAT: '37.71068056',
        insdate: '2019-11-26',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '250',
        cseExHgt: '200',
        cseExDia: '0-34',
        cseExMat: '250',
        cseInHgt: '강관',
        cseInDia: '0-100',
        cseInMat: '200',
        strHgt: 'PVC',
        strDia: '34-100',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [127.69558333, 37.71068056] }
    },
    {
      type: 'Feature',
      id: 1069,
      properties: {
        DATE: '2024-10-26',
        jiguname: '홍천어유포',
        gennum: '753924',
        well: '충적',
        pyogo: '84.757',
        area: '한강권역',
        addr: '강원특별자치도 홍천군 서면 어유포리 205-7',
        LON: '127.69558333',
        LAT: '37.71068056',
        insdate: '2019-11-26',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '31',
        gulDiaBtm: '250',
        cseExHgt: '200',
        cseExDia: '0-4',
        cseExMat: '250',
        cseInHgt: '강관',
        cseInDia: '0-31',
        cseInMat: '200',
        strHgt: 'PVC',
        strDia: '4-31',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [127.69558333, 37.71068056] }
    },
    {
      type: 'Feature',
      id: 1070,
      properties: {
        DATE: '2024-10-26',
        jiguname: '홍천홍천',
        gennum: '65588',
        well: '암반',
        pyogo: '128.56',
        area: '한강권역',
        addr: '강원특별자치도 홍천군 홍천읍 희망리 421-2 희망리 배수펌프장 내',
        LON: '127.87359444',
        LAT: '37.68901944',
        insdate: '2000-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-13.9',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.87359444, 37.68901944] }
    },
    {
      type: 'Feature',
      id: 1071,
      properties: {
        DATE: '2024-10-26',
        jiguname: '홍천홍천',
        gennum: '65589',
        well: '충적',
        pyogo: '128.54',
        area: '한강권역',
        addr: '강원특별자치도 홍천군 홍천읍 희망리 421-2 희망리 배수펌프장 내',
        LON: '127.87359444',
        LAT: '37.68901944',
        insdate: '2000-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '12',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-8.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-12',
        strHgt: '',
        strDia: '',
        strMat: '8.0-12.0'
      },
      geometry: { type: 'Point', coordinates: [127.87359444, 37.68901944] }
    },
    {
      type: 'Feature',
      id: 1072,
      properties: {
        DATE: '2024-10-26',
        jiguname: '홍천화촌_신',
        gennum: '422117',
        well: '암반',
        pyogo: '175.4',
        area: '한강권역',
        addr: '강원특별자치도 홍천군 화촌면 군업리 1426-144',
        LON: '128.00138889',
        LAT: '37.75250000',
        insdate: '2009-10-27',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜경기지질',
        gulDiaTop: '70',
        gulDiaBtm: '250',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL(3T)',
        cseInDia: '0.0-18.0',
        cseInMat: '150',
        strHgt: 'STAINLESS STEEL',
        strDia: '',
        strMat: '150'
      },
      geometry: { type: 'Point', coordinates: [128.00138889, 37.7525] }
    },
    {
      type: 'Feature',
      id: 1073,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화성삼화_심부2',
        gennum: '771567',
        well: '암반',
        pyogo: '25.3',
        area: '한강권역',
        addr: '경기도 화성시 비봉면 삼화리 537 비봉초등학교',
        LON: '126.86845833',
        LAT: '37.23672222',
        insdate: '2016-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '85',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-9.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-85',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '77.0-85.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.86845833, 37.23672222] }
    },
    {
      type: 'Feature',
      id: 1074,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화성삼화_천부',
        gennum: '771568',
        well: '충적',
        pyogo: '25.3',
        area: '한강권역',
        addr: '경기도 화성시 비봉면 삼화리 537 비봉초등학교',
        LON: '126.86845833',
        LAT: '37.23672222',
        insdate: '2016-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-10',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '7.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.86845833, 37.23672222] }
    },
    {
      type: 'Feature',
      id: 1075,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화성송산',
        gennum: '622085',
        well: '암반',
        pyogo: '34.82',
        area: '한강권역',
        addr: '경기도 화성시 송산면 육일리 595-3 경기도 화성시 송산면 육일리 595-3',
        LON: '126.72166667',
        LAT: '37.19888889',
        insdate: '2014-11-25',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국건설기술연구원/㈜지오엔이지니어링',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-24.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '36-40',
        strMat: ' 52-56'
      },
      geometry: { type: 'Point', coordinates: [126.72166667, 37.19888889] }
    },
    {
      type: 'Feature',
      id: 1076,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화성송산',
        gennum: '622086',
        well: '충적',
        pyogo: '34.82',
        area: '한강권역',
        addr: '경기도 화성시 송산면 육일리 595-3 경기도 화성시 송산면 육일리 595-3번지',
        LON: '126.72166667',
        LAT: '37.19888889',
        insdate: '2014-11-25',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국건설기술연구원/㈜지오엔이지니어링',
        gulDiaTop: '21',
        gulDiaBtm: '400',
        cseExHgt: '300',
        cseExDia: '0.0-3.0',
        cseExMat: '300',
        cseInHgt: '아연도강관',
        cseInDia: '0.0-21.0',
        cseInMat: '200',
        strHgt: '아연도강관',
        strDia: '17.0-20.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [126.72166667, 37.19888889] }
    },
    {
      type: 'Feature',
      id: 1077,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화성양감',
        gennum: '82002',
        well: '암반',
        pyogo: '25.467',
        area: '한강권역',
        addr: '경기도 화성시 양감면  신왕리 815-2 복지회관',
        LON: '126.94559722',
        LAT: '37.08007778',
        insdate: '2003-05-28',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-11.5',
        cseInHgt: '250',
        cseInDia: 'STAINLES STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P.V.C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.94559722, 37.08007778] }
    },
    {
      type: 'Feature',
      id: 1078,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화성양감',
        gennum: '82003',
        well: '충적',
        pyogo: '25.472',
        area: '한강권역',
        addr: '경기도 화성시 양감면  신왕리 815-2 복지회관',
        LON: '126.94559722',
        LAT: '37.08007778',
        insdate: '2003-05-28',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-6.0',
        cseInHgt: '200',
        cseInDia: 'STAINLES STEEL',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6.0-10.0'
      },
      geometry: { type: 'Point', coordinates: [126.94559722, 37.08007778] }
    },
    {
      type: 'Feature',
      id: 1079,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화성우정',
        gennum: '87247',
        well: '암반',
        pyogo: '26.15',
        area: '한강권역',
        addr: '경기도 화성시 우정읍 조암리 411-2',
        LON: '126.81482222',
        LAT: '37.08976944',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국지질조사탐사업협동조합',
        gulDiaTop: '70',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-19.0',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0.0-70.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 40.0-44.0'
      },
      geometry: { type: 'Point', coordinates: [126.81482222, 37.08976944] }
    },
    {
      type: 'Feature',
      id: 1080,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화성우정',
        gennum: '87248',
        well: '충적',
        pyogo: '26.13',
        area: '한강권역',
        addr: '경기도 화성시 우정읍 조암리 411-2',
        LON: '126.81482222',
        LAT: '37.08976944',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국지질조사탐사업협동조합',
        gulDiaTop: '15',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-11.0',
        cseExMat: '200',
        cseInHgt: 'STAINLESS STEEL',
        cseInDia: '0-15',
        cseInMat: '',
        strHgt: '',
        strDia: '11.0-15.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [126.81482222, 37.08976944] }
    },
    {
      type: 'Feature',
      id: 1081,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화성팔탄',
        gennum: '84019',
        well: '암반',
        pyogo: '30.237',
        area: '한강권역',
        addr: '경기도 화성시 팔탄면 구장리  1029-4',
        LON: '126.90601667',
        LAT: '37.16107778',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-17.3',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [126.90601667, 37.16107778] }
    },
    {
      type: 'Feature',
      id: 1082,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화성팔탄',
        gennum: '84020',
        well: '충적',
        pyogo: '30.203',
        area: '한강권역',
        addr: '경기도 화성시 팔탄면 구장리  1029-4',
        LON: '126.90601667',
        LAT: '37.16107778',
        insdate: '2003-12-15',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-6.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6.0-10.0'
      },
      geometry: { type: 'Point', coordinates: [126.90601667, 37.16107778] }
    },
    {
      type: 'Feature',
      id: 1083,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화성하가등_심부2',
        gennum: '737738',
        well: '암반',
        pyogo: '51.9',
        area: '한강권역',
        addr: '경기도 화성시 봉담읍 하가등리 107-1',
        LON: '126.93954167',
        LAT: '37.15083333',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '85',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-10.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-85.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '79.0-85.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.93954167, 37.15083333] }
    },
    {
      type: 'Feature',
      id: 1084,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화성하가등_천부',
        gennum: '737737',
        well: '충적',
        pyogo: '51.9',
        area: '한강권역',
        addr: '경기도 화성시 봉담읍 하가등리 107-1',
        LON: '126.93954167',
        LAT: '37.15083333',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.93954167, 37.15083333] }
    },
    {
      type: 'Feature',
      id: 1085,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화순남면',
        gennum: '87258',
        well: '암반',
        pyogo: '113.14',
        area: '영섬권역',
        addr: '전라남도 화순군 남면 절산리 368-8',
        LON: '127.12087778',
        LAT: '35.00063611',
        insdate: '2004-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: '한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-5.4',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '24.0-28.0'
      },
      geometry: { type: 'Point', coordinates: [127.12087778, 35.00063611] }
    },
    {
      type: 'Feature',
      id: 1086,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화순능주',
        gennum: '9902',
        well: '암반',
        pyogo: '42.77',
        area: '영섬권역',
        addr: '전라남도 화순군 능주면 만수리 348-32',
        LON: '126.95294444',
        LAT: '35.01691389',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '62',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-31.5',
        cseExMat: '250',
        cseInHgt: '백관',
        cseInDia: '0.0-62.0',
        cseInMat: '200',
        strHgt: 'P. V. C',
        strDia: '24.0-28.0',
        strMat: ' 32.0-36.0'
      },
      geometry: { type: 'Point', coordinates: [126.95294444, 35.01691389] }
    },
    {
      type: 'Feature',
      id: 1087,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화순능주',
        gennum: '9903',
        well: '충적',
        pyogo: '42.8',
        area: '영섬권역',
        addr: '전라남도 화순군 능주면 만수리 348-32',
        LON: '126.95294444',
        LAT: '35.01691389',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '6.4',
        gulDiaBtm: '350',
        cseExHgt: '',
        cseExDia: '0.0-6.4',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0-6.4',
        cseInMat: '',
        strHgt: '',
        strDia: '2.0-6.0',
        strMat: '250'
      },
      geometry: { type: 'Point', coordinates: [126.95294444, 35.01691389] }
    },
    {
      type: 'Feature',
      id: 1088,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화순도암',
        gennum: '778280',
        well: '암반',
        pyogo: '74.7',
        area: '영섬권역',
        addr: '전라남도 화순군 도암면 용강리 981-3',
        LON: '126.87547222',
        LAT: '34.90997222',
        insdate: '2022-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-10.0',
        cseExMat: '200',
        cseInHgt: '백관',
        cseInDia: '0.0-100',
        cseInMat: '150',
        strHgt: 'P.V.C(VG1)',
        strDia: '4-8',
        strMat: '12-16'
      },
      geometry: { type: 'Point', coordinates: [126.87547222, 34.90997222] }
    },
    {
      type: 'Feature',
      id: 1089,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화순독상_심부2',
        gennum: '737740',
        well: '암반',
        pyogo: '128.923',
        area: '영섬권역',
        addr: '전라남도 화순군 동복면 독상리 351-7',
        LON: '127.12851667',
        LAT: '35.06899722',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-9.9',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '75.5-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.12851667, 35.06899722] }
    },
    {
      type: 'Feature',
      id: 1090,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화순독상_천부',
        gennum: '737739',
        well: '충적',
        pyogo: '128.832',
        area: '영섬권역',
        addr: '전라남도 화순군 동복면 독상리 351-7',
        LON: '127.12851667',
        LAT: '35.06899722',
        insdate: '2018-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-5.5',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '5.5-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.12851667, 35.06899722] }
    },
    {
      type: 'Feature',
      id: 1091,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화순북면',
        gennum: '65080',
        well: '암반',
        pyogo: '260.32',
        area: '영섬권역',
        addr: '전라남도 화순군 북면 원리 97-1',
        LON: '127.13689167',
        LAT: '35.19255833',
        insdate: '1999-12-24',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-8.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.13689167, 35.19255833] }
    },
    {
      type: 'Feature',
      id: 1092,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화순원천_심부2',
        gennum: '771569',
        well: '암반',
        pyogo: '73.8',
        area: '영섬권역',
        addr: '전라남도 화순군 도암면 원천리 824 도암면사무소',
        LON: '126.89677222',
        LAT: '34.93765833',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-4.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-80',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.89677222, 34.93765833] }
    },
    {
      type: 'Feature',
      id: 1093,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화순원천_천부',
        gennum: '771570',
        well: '충적',
        pyogo: '73.8',
        area: '영섬권역',
        addr: '전라남도 화순군 도암면 원천리 824 도암면사무소',
        LON: '126.89677222',
        LAT: '34.93765833',
        insdate: '2014-12-01',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '15',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-0.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0-15',
        cseInMat: '100',
        strHgt: 'PVC',
        strDia: '7.5-15.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.89677222, 34.93765833] }
    },
    {
      type: 'Feature',
      id: 1094,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화순이양',
        gennum: '73530',
        well: '암반',
        pyogo: '96.31',
        area: '영섬권역',
        addr: '전라남도 화순군 이양면 매정리 79-2',
        LON: '127.03055000',
        LAT: '34.86439444',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0.0-12.0',
        cseInHgt: '250',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0.0-70.0',
        strHgt: '200',
        strDia: 'P. V. C',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.03055, 34.86439444] }
    },
    {
      type: 'Feature',
      id: 1095,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화순이양',
        gennum: '73531',
        well: '충적',
        pyogo: '96.32',
        area: '영섬권역',
        addr: '전라남도 화순군 이양면 매정리 79-2',
        LON: '127.03055000',
        LAT: '34.86439444',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0.0-10.0',
        cseInHgt: '200',
        cseInDia: 'STAINLESS STEEL',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6.0-10.0'
      },
      geometry: { type: 'Point', coordinates: [127.03055, 34.86439444] }
    },
    {
      type: 'Feature',
      id: 1096,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화순이천_심부2',
        gennum: '737742',
        well: '암반',
        pyogo: '229.5',
        area: '영섬권역',
        addr: '전라남도 화순군 백아면 이천리 55',
        LON: '127.13356389',
        LAT: '35.16925278',
        insdate: '2011-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-19.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '72.5-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.13356389, 35.16925278] }
    },
    {
      type: 'Feature',
      id: 1097,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화순이천_천부',
        gennum: '737741',
        well: '충적',
        pyogo: '229.5',
        area: '영섬권역',
        addr: '전라남도 화순군 백아면 이천리 55',
        LON: '127.13356389',
        LAT: '35.16925278',
        insdate: '2011-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '17',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-1.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-17.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '12.5-17.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.13356389, 35.16925278] }
    },
    {
      type: 'Feature',
      id: 1098,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화순천암_심부2',
        gennum: '737744',
        well: '암반',
        pyogo: '32.95',
        area: '영섬권역',
        addr: '전라남도 화순군 도곡면 천암리 191',
        LON: '126.90828333',
        LAT: '35.01253889',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '80',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-6.8',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-80.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '71.0-80.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.90828333, 35.01253889] }
    },
    {
      type: 'Feature',
      id: 1099,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화순천암_천부',
        gennum: '737743',
        well: '충적',
        pyogo: '32.95',
        area: '영섬권역',
        addr: '전라남도 화순군 도곡면 천암리 191',
        LON: '126.90828333',
        LAT: '35.01253889',
        insdate: '2014-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '10',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-10.0',
        cseInMat: '100',
        strHgt: 'HI-PVC',
        strDia: '4.0-10.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [126.90828333, 35.01253889] }
    },
    {
      type: 'Feature',
      id: 1100,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화천간동',
        gennum: '73509',
        well: '암반',
        pyogo: '187.085',
        area: '한강권역',
        addr: '강원특별자치도 화천군 간동면 유촌리 591-2',
        LON: '127.79287778',
        LAT: '38.05393889',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-22',
        cseInHgt: '250',
        cseInDia: '백관',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'PVC',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.79287778, 38.05393889] }
    },
    {
      type: 'Feature',
      id: 1101,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화천간동',
        gennum: '73510',
        well: '충적',
        pyogo: '187',
        area: '한강권역',
        addr: '강원특별자치도 화천군 간동면 유촌리 591-2',
        LON: '127.79287778',
        LAT: '38.05393889',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '12',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0-12',
        cseInHgt: '200',
        cseInDia: '백관',
        cseInMat: '0-12',
        strHgt: '',
        strDia: '',
        strMat: '8-12'
      },
      geometry: { type: 'Point', coordinates: [127.79287778, 38.05393889] }
    },
    {
      type: 'Feature',
      id: 1102,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화천사내',
        gennum: '73511',
        well: '암반',
        pyogo: '274.43',
        area: '한강권역',
        addr: '강원특별자치도 화천군 사내면 사창리 438-4',
        LON: '127.52119167',
        LAT: '38.07347222',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-13',
        cseInHgt: '250',
        cseInDia: '백관',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'PVC',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.52119167, 38.07347222] }
    },
    {
      type: 'Feature',
      id: 1103,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화천상서',
        gennum: '73512',
        well: '암반',
        pyogo: '173.851',
        area: '한강권역',
        addr: '강원특별자치도 화천군 상서면 파포리 173-3',
        LON: '127.65083056',
        LAT: '38.15999167',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-15',
        cseInHgt: '250',
        cseInDia: '백관',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'PVC',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.65083056, 38.15999167] }
    },
    {
      type: 'Feature',
      id: 1104,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화천상서',
        gennum: '73513',
        well: '충적',
        pyogo: '173.801',
        area: '한강권역',
        addr: '강원특별자치도 화천군 상서면 파포리 173-3',
        LON: '127.65083056',
        LAT: '38.15999167',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '10',
        cseExHgt: '350',
        cseExDia: '',
        cseExMat: '0-10',
        cseInHgt: '200',
        cseInDia: '백관',
        cseInMat: '0-10',
        strHgt: '',
        strDia: '',
        strMat: '6-10'
      },
      geometry: { type: 'Point', coordinates: [127.65083056, 38.15999167] }
    },
    {
      type: 'Feature',
      id: 1105,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화천풍산_심부2',
        gennum: '769839',
        well: '암반',
        pyogo: '166.04',
        area: '한강권역',
        addr: '강원특별자치도 화천군 화천읍 풍산리 764-5 풍산초등학교',
        LON: '127.74130556',
        LAT: '38.14661944',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '86',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-16.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-86.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '80.0-86.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.74130556, 38.14661944] }
    },
    {
      type: 'Feature',
      id: 1106,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화천풍산_천부',
        gennum: '769840',
        well: '충적',
        pyogo: '166.04',
        area: '한강권역',
        addr: '강원특별자치도 화천군 화천읍 풍산리 764-5 풍산초등학교',
        LON: '127.74130556',
        LAT: '38.14661944',
        insdate: '2016-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '14',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-14.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '8.0-14.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [127.74130556, 38.14661944] }
    },
    {
      type: 'Feature',
      id: 1107,
      properties: {
        DATE: '2024-10-26',
        jiguname: '화천화천',
        gennum: '615525',
        well: '암반',
        pyogo: '198.41',
        area: '한강권역',
        addr: '강원특별자치도 화천군 화천읍 풍산리 1383-1',
        LON: '127.74394722',
        LAT: '38.15859444',
        insdate: '2013-09-09',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '㈜지오그린21',
        gulDiaTop: '100',
        gulDiaBtm: '300',
        cseExHgt: '200',
        cseExDia: '0.0-17.5',
        cseExMat: '200',
        cseInHgt: 'STS304',
        cseInDia: '0.0-100.0',
        cseInMat: '150',
        strHgt: 'P. V. C',
        strDia: '20-28',
        strMat: ' 40-44'
      },
      geometry: { type: 'Point', coordinates: [127.74394722, 38.15859444] }
    },
    {
      type: 'Feature',
      id: 1108,
      properties: {
        DATE: '2024-10-26',
        jiguname: '횡성강림_심부2',
        gennum: '769841',
        well: '암반',
        pyogo: '390.038',
        area: '한강권역',
        addr: '강원특별자치도 횡성군 강림면 강림리 872 강림중학교',
        LON: '128.12618333',
        LAT: '37.36350000',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '90',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '0.0-6.0',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-90.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '75.0-90.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.12618333, 37.3635] }
    },
    {
      type: 'Feature',
      id: 1109,
      properties: {
        DATE: '2024-10-26',
        jiguname: '횡성강림_천부',
        gennum: '769842',
        well: '충적',
        pyogo: '390.038',
        area: '한강권역',
        addr: '강원특별자치도 횡성군 강림면 강림리 872 강림중학교',
        LON: '128.12618333',
        LAT: '37.36350000',
        insdate: '2013-12-31',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '',
        gulDiaTop: '12',
        gulDiaBtm: '200',
        cseExHgt: '200',
        cseExDia: '',
        cseExMat: '200',
        cseInHgt: '강관',
        cseInDia: '0.0-12.0',
        cseInMat: '100',
        strHgt: '',
        strDia: '8.0-12.0',
        strMat: '100'
      },
      geometry: { type: 'Point', coordinates: [128.12618333, 37.3635] }
    },
    {
      type: 'Feature',
      id: 1110,
      properties: {
        DATE: '2024-10-26',
        jiguname: '횡성안흥',
        gennum: '9870',
        well: '암반',
        pyogo: '425.88',
        area: '한강권역',
        addr: '강원특별자치도 횡성군 안흥면 안흥리 57-2',
        LON: '128.16013611',
        LAT: '37.41085278',
        insdate: '1998-05-16',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '한국수자원기술공단',
        gulDiaTop: '60',
        gulDiaBtm: '350',
        cseExHgt: '250',
        cseExDia: '0.0-4.5',
        cseExMat: '250',
        cseInHgt: 'STAINLESS STEEL(4T)',
        cseInDia: '0.0-60.0',
        cseInMat: '200',
        strHgt: '백관',
        strDia: '36.0-42.0',
        strMat: '200'
      },
      geometry: { type: 'Point', coordinates: [128.16013611, 37.41085278] }
    },
    {
      type: 'Feature',
      id: 1111,
      properties: {
        DATE: '2024-10-26',
        jiguname: '횡성횡성',
        gennum: '73514',
        well: '암반',
        pyogo: '137.15',
        area: '한강권역',
        addr: '강원특별자치도 횡성군 횡성읍 학곡리 458-21',
        LON: '127.95920000',
        LAT: '37.50177500',
        insdate: '2001-12-20',
        manager: '환경부',
        builder: ' 한국수자원공사',
        gulDep: '국토교통부',
        gulDiaTop: ' 한국수자원공사',
        gulDiaBtm: '70',
        cseExHgt: '350',
        cseExDia: '250',
        cseExMat: '0-5.4',
        cseInHgt: '250',
        cseInDia: '백관',
        cseInMat: '0-70',
        strHgt: '200',
        strDia: 'PVC',
        strMat: '50.0-54.0'
      },
      geometry: { type: 'Point', coordinates: [127.9592, 37.501775] }
    }
  ]
};
