import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ChatContentBodyIntro from './ChatContentBodyIntro';
import Message from './Message';
import SimpleBarReact from 'simplebar-react';
import ThreadInfo from './ThreadInfo';
import { ChatContext } from 'context/Context';

import { SocketContext } from 'context/Context';
import { chainAxios } from 'helpers/chainAxios';

const ChatContentBody = ({ thread, receivedMessage }) => {
  // let lastDate = null;
  // const messagesEndRef = useRef();

  // const { getUser, messages, scrollToBottom, setScrollToBottom } =
  //   useContext(ChatContext);
  // const user = getUser(thread);
  // const { content } = messages.find(({ id }) => id === thread.messagesId);

  // useEffect(() => {
  //   if (scrollToBottom) {
  //     setTimeout(() => {
  //       messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  //     }, 500);
  //     setScrollToBottom(false);
  //   }
  // }, [scrollToBottom]);
  const [chatList, setChatList] = useState([]);
  const messagesEndRef = useRef();
  const [scrollToBottom, setScrollToBottom] = useState(false);

  // const { stompClient } = useContext(SocketContext);

  // useEffect(() => {
  //   // console.log(stompClient);
  //   stompClient.connect({}, frame => {
  //     console.log('frame : ', frame);
  //     stompClient.subscribe('/chat', message => {
  //       console.log('ChatContentBody subscribe : ', currentChatRoom);
  //       addNewMessage(message);
  //     });
  //   });
  // }, []);

  useEffect(() => {
    chatroomsChat();
  }, []);

  useEffect(() => {
    setScrollToBottom(true);
  }, [chatList]);

  useEffect(() => {
    // console.log('ChatContentBody receivedMessage : ', receivedMessage);
    if (
      !!receivedMessage.chatroom &&
      receivedMessage.chatroom.key === thread.id
    ) {
      setChatList(chatList => [...chatList, receivedMessage]);
      // setScrollToBottom(true);
    }
  }, [receivedMessage]);

  // useEffect(() => {
  //   if (scrollToBottom) {
  //     setTimeout(() => {
  //       console.log(messagesEndRef);
  //       if (
  //         messagesEndRef.current.scrollIntoView != undefined &&
  //         messagesEndRef.current.scrollIntoView != null
  //       ) {
  //         messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  //       }
  //     }, 500);
  //     setScrollToBottom(false);
  //   }
  // }, [scrollToBottom]);

  const chatroomsChat = () => {
    let url =
      'api/chatroom/' +
      thread.id +
      "/chats?pageNo=0&pageSize=99999&sort=createDate$desc'";

    // let url =
    //   'api/chatroom/1/chats?pageNo=0&pageSize=99999&sort=createDate$asc';
    // console.log('ChatContentBody chatroomsChat() url: ', url);
    chainAxios
      .get(url)
      .then(response => {
        // console.log('ChatContentBody chatroomsChat() : ', response);
        if (response.data.resultCode === 200) {
          setChatList(response.data.data.chatList.dataList);
          // console.log('chatList : ', chatList);
        }
      })
      .catch(error => {
        console.log('ChatContentBody chatroomsChat() : ', error);
      });
  };

  const MakeChatMessage = () => {
    let chatItem = new Array();
    chatList.map((chat, index) => {
      // console.log('chatItem : ', chat);
      chatItem.push(
        <div key={index}>
          <Message
            message={chat.message}
            senderUserId={chat.sender.key}
            time={chat.createDate}
            status={chat.chatStatus}
            attachments={chat.attachments}
            isGroup={false}
            key={index}
          />
        </div>
      );
    });

    return chatItem;
  };

  return (
    <div className="chat-content-body" style={{ display: 'inherit' }}>
      {/* <ThreadInfo thread={thread} isOpenThreadInfo={true} /> */}
      <SimpleBarReact style={{ height: '100%' }}>
        <div className="chat-content-scroll-area">
          {/* {chatList != [] &&
            chatList.map((chat, index) => {
              <div key={index}>
                <Message
                  message={chat.message}
                  senderUserId={chat.sender.key}
                  time={chat.createDate}
                  status={chat.chatStatus}
                  attachments={chat.attachments}
                  isGroup={false}
                  key={index}
                />
              </div>;
            })} */}
          {MakeChatMessage()}
          {/* <ChatContentBodyIntro user={user} />
          {content.map(({ message, time, senderUserId, status }, index) => (
            <div key={index}>
              {lastDate !== time.date && (
                <div className="text-center fs--2 text-500">{`${time.date}, ${time.hour}`}</div>
              )}
              {(() => {
                lastDate = time.date;
              })()}
              <Message
                message={message}
                senderUserId={senderUserId}
                time={time}
                status={status}
                isGroup={thread.type === 'group'}
              />
            </div>
          ))} */}
          <div ref={messagesEndRef} />
        </div>
      </SimpleBarReact>
    </div>
  );
};

export default ChatContentBody;
