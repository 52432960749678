import React, { useContext, useEffect, useRef, useState } from 'react';
import { Row, Col, Dropdown, Card, Button } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import Flex from 'components/common/Flex';

import AppContext, { CourseContext, SocketContext } from 'context/Context';

const columns = [
  {
    accessor: 'eventNo',
    Header: '이벤트 고유번호',
    headerProps: { className: 'text-900' }
  },
  {
    accessor: 'occurDate',
    Header: '발생일시',
    headerProps: { className: 'text-900' }
  },
  {
    accessor: 'inspectionDate',
    Header: '점검일',
    headerProps: { className: 'text-900' }
  },
  {
    accessor: 'projectName',
    Header: '프로젝트명',
    headerProps: { className: 'text-900' }
  },
  {
    accessor: 'inspectionTarget',
    Header: '점검 대상',
    headerProps: { className: 'text-900' }
  },
  {
    accessor: 'id',
    Header: 'ID',
    headerProps: { className: 'text-900' }
  },
  {
    accessor: 'inspectionContent',
    Header: '점검내용',
    headerProps: { className: 'text-900' }
  },
  {
    accessor: 'actionContent',
    Header: '조치내용',
    headerProps: { className: 'text-900' }
  },
  {
    accessor: 'inspectionManager',
    Header: '점검 담당자',
    headerProps: { className: 'text-900' }
  },
  {
    accessor: 'favorites',
    Header: '즐겨찾기',
    headerProps: { className: 'text-900' }
  },
  {
    accessor: 'action',
    Header: '관리',
    headerProps: { className: 'text-900' }
  }
];

const data = [
  {
    eventNo: '001',
    occurDate: '2023.03.28 14:15:25',
    inspectionDate: '2023.03.29 09:10:23',
    projectName: '고양시 프로젝트',
    inspectionTarget: '센서',
    id: '9',
    inspectionContent: '[고양시 시설물] 센서 상태 불량',
    actionContent: '센서 재작동',
    inspectionManager: '강연우 선임',
    favorites: '★',
    action: '수정/삭제'
  },
  {
    eventNo: '002',
    occurDate: '2023.03.25 16:34:12 ',
    inspectionDate: '2023.03.26 10:01:07',
    projectName: '고양시 프로젝트',
    inspectionTarget: '센서',
    id: '7',
    inspectionContent: '[고양시 시설물] 센서 상태 불량',
    actionContent: '센서 몸통 교체',
    inspectionManager: '강연우 선임',
    favorites: '★',
    action: '수정/삭제'
  },
  {
    eventNo: '003',
    occurDate: '2023.03.20 11:54:52',
    inspectionDate: '2023.03.21 09:25:44',
    projectName: '고양시 프로젝트',
    inspectionTarget: '센서',
    id: '7',
    inspectionContent:
      '[고양시 시설물] 2023.03.20 11:54:52 이후 센서 통신 불량',
    actionContent: '센서 안테나 교체',
    inspectionManager: '강연우 선임',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '004',
    occurDate: '2023.03.14 23:11:43',
    inspectionDate: '2023.03.15 12:14:32',
    projectName: '고양시 프로젝트',
    inspectionTarget: '게이트웨이',
    id: '01037608042',
    inspectionContent: '[GW-01037608042] 2023.03.14 23:11:43 이후 통신 불량',
    actionContent: '전원 재작동',
    inspectionManager: '강연우 선임',
    favorites: '★',
    action: '수정/삭제'
  },
  {
    eventNo: '005',
    occurDate: '2023.03.10 21:09:32',
    inspectionDate: '2023.03.11 17:49:16',
    projectName: '고양시 프로젝트',
    inspectionTarget: '센서',
    id: '9',
    inspectionContent: '[고양시 시설물] 센서 상태 불량',
    actionContent: '센서 재작동',
    inspectionManager: '강연우 선임',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  },
  {
    eventNo: '99999',
    occurDate: '2023.01.01 01:01:01',
    inspectionDate: '2023.01.01 01:01:01',
    projectName: 'SAMPLE 프로젝트',
    inspectionTarget: '*',
    id: '-',
    inspectionContent: '[SAMPLE] 센서 상태 불량',
    actionContent: '[SAMPLE]센서 재작동',
    inspectionManager: 'SAMPLE',
    favorites: '☆',
    action: '수정/삭제'
  }
];
const StatisticsData = () => {
  const {
    config: { isNavbarVerticalCollapsed },
    setConfig
  } = useContext(AppContext);

  const coursesNavbarVerticalCollapsed = useRef(isNavbarVerticalCollapsed);

  useEffect(() => {
    setConfig('isNavbarVerticalCollapsed', false);

    return () => {
      setConfig(
        'isNavbarVerticalCollapsed',
        coursesNavbarVerticalCollapsed.current
      );
    };
  }, []);

  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={12} xl={12} xxl={12}>
          <Card className="h-md-100">
            <Card.Header>
              <h5>데이터 관리</h5>
            </Card.Header>
            <Card.Body>
              <Flex
                alignItems="center"
                direction="row"
                justifyContent="between"
                className="pb-3"
              >
                <Flex
                  alignItems="center"
                  direction="row"
                  justifyContent="start"
                >
                  <h6 className="px-3">프로젝트 선택</h6>
                  <Dropdown align={{ lg: 'start' }}>
                    <Dropdown.Toggle variant="falcon-default">
                      프로젝트를 선택하세요.
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="py-2">
                      <Dropdown.Item href="#/action-4" className="w-100">
                        강매동 스마트타워 프로젝트
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-1" className="w-100">
                        대곡동 서해선 연장프로젝트
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-5" className="w-100">
                        대장동 스마트시티 프로젝트 1구역
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2" className="w-100">
                        대화동 스마트시트 13구역 프로젝트
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-8" className="w-100">
                        삼송동 뉴 스타필드 프로젝트 12구역
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-9" className="w-100">
                        식사동 스마트시트 프로젝트
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3" className="w-100">
                        원당 엘에이치 00단지 조성 프로젝트
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-7" className="w-100">
                        지축동 테크노벨리 프로젝트
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-6" className="w-100">
                        행신동 뉴 스마트시티 프로젝트
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <h6 className="px-3">시설물 선택</h6>
                  <Dropdown align={{ lg: 'start' }}>
                    <Dropdown.Toggle variant="falcon-default">
                      시설물을 선택하세요.
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="py-2">
                      <Dropdown.Item href="#/action-4" className="w-100">
                        [SAMPLE] 시설물1
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-1" className="w-100">
                        [SAMPLE] 시설물2
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-5" className="w-100">
                        [SAMPLE] 시설물3
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2" className="w-100">
                        [SAMPLE] 시설물4
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-8" className="w-100">
                        [SAMPLE] 시설물5
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-9" className="w-100">
                        [SAMPLE] 시설물6
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3" className="w-100">
                        [SAMPLE] 시설물7
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-7" className="w-100">
                        [SAMPLE] 시설물8
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-6" className="w-100">
                        [SAMPLE] 시설물9
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <h6 className="px-3">게이트웨이</h6>
                  <Dropdown align={{ lg: 'start' }}>
                    <Dropdown.Toggle variant="falcon-default">
                      게이트웨이를 선택하세요.
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="py-2">
                      <Dropdown.Item href="#/action-4" className="w-100">
                        [SAMPLE] 게이트웨이1
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-1" className="w-100">
                        [SAMPLE] 게이트웨이2
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-5" className="w-100">
                        [SAMPLE] 게이트웨이3
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2" className="w-100">
                        [SAMPLE] 게이트웨이4
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-8" className="w-100">
                        [SAMPLE] 게이트웨이5
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-9" className="w-100">
                        [SAMPLE] 게이트웨이6
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3" className="w-100">
                        [SAMPLE] 게이트웨이7
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-7" className="w-100">
                        [SAMPLE] 게이트웨이8
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-6" className="w-100">
                        [SAMPLE] 게이트웨이9
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <h6 className="px-3">센서</h6>
                  <Dropdown align={{ lg: 'start' }}>
                    <Dropdown.Toggle variant="falcon-default">
                      센서를 선택하세요.
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="py-2">
                      <Dropdown.Item href="#/action-4" className="w-100">
                        [SAMPLE] 센서1
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-1" className="w-100">
                        [SAMPLE] 센서2
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-5" className="w-100">
                        [SAMPLE] 센서3
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2" className="w-100">
                        [SAMPLE] 센서4
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-8" className="w-100">
                        [SAMPLE] 센서5
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-9" className="w-100">
                        [SAMPLE] 센서6
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3" className="w-100">
                        [SAMPLE] 센서7
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-7" className="w-100">
                        [SAMPLE] 센서8
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-6" className="w-100">
                        [SAMPLE] 센서9
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Flex>
                <Flex alignItems="center" direction="row" justifyContent="end">
                  <Flex
                    alignItems="center"
                    direction="row"
                    justifyContent="start"
                    className="px-3"
                  >
                    <AdvanceTableSearchBox table />
                  </Flex>
                  <Button>관리페이지로 이동</Button>
                  <Button>엑셀 내보내기</Button>
                  <Button>즐겨찾기</Button>
                </Flex>
              </Flex>
              <AdvanceTableWrapper
                columns={columns}
                data={data}
                sortable
                pagination
                perPage={20}
              >
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    bordered: true,
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
                <div className="mt-3">
                  <AdvanceTablePagination table />
                </div>
              </AdvanceTableWrapper>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default StatisticsData;
