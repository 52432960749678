import { chainAxios } from 'helpers/chainAxios';
import Map, {
  NavigationControl,
  AttributionControl,
  FullscreenControl,
  GeolocateControl,
  ScaleControl,
  Source,
  Layer,
  Marker,
  Popup
} from 'react-map-gl';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import { getColor, isEmpty } from 'helpers/utils';
import { Card, Row } from 'react-bootstrap';
import FalconCardHeader from '../../../common/FalconCardHeader';
import AppContext from '../../../../context/Context';

const buildingLayerStyle = {
  id: '3d-buildings',
  source: 'composite',
  'source-layer': 'building',
  filter: ['==', 'extrude', 'true'],
  type: 'fill-extrusion',
  minzoom: 15,
  paint: {
    'fill-extrusion-color': '#aaa', // 건물 색상
    'fill-extrusion-height': ['get', 'height'], // 건물 높이
    'fill-extrusion-base': ['get', 'min_height'], // 건물의 최소 높이
    'fill-extrusion-opacity': 0.6 // 건물 투명도
  }
};
const MapboxMinimapSensor = ({ sensorInfo }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  const mapContainerRef = useRef(null);

  const [mapStyle, setMapStyle] = useState(
    'mapbox://styles/mapbox/streets-v12'
  );

  const [facilitySensors, setFacilitySensors] = useState({});
  const [facilityPolygons, setFacilityPolygons] = useState({});

  useEffect(() => {
    if (!isEmpty(sensorInfo)) {
      mapContainerRef.current?.flyTo({
        center: [sensorInfo.location.longitude, sensorInfo.location.latitude],
        duration: 2000
      });
    }
  }, [sensorInfo]);

  useEffect(() => {
    if (isDark) {
      setMapStyle(`mapbox://styles/mapbox/dark-v11`);
    } else {
      setMapStyle(`mapbox://styles/mapbox/streets-v12`);
    }
  }, [isDark]);

  const onRender = useCallback(e => {
    // https://github.com/mapbox/mapbox-gl-language/?tab=readme-ov-file#setlanguage
    e.target.addControl(new MapboxLanguage({ defaultLanguage: 'ko' }));
  }, []);

  const handleMapLoad = event => {
    const map = event.target;
    console.log('Map loaded successfully'); //

    const mapboxglCtrlLogos = document.querySelectorAll('.mapboxgl-ctrl-logo');
    if (mapboxglCtrlLogos.length > 0) {
      mapboxglCtrlLogos.forEach(logo => {
        logo.style.display = 'none';
      });
    }

    const mapboxglCtrlBottomRights = document.querySelectorAll(
      '.mapboxgl-ctrl-bottom-right'
    );
    if (mapboxglCtrlBottomRights.length > 0) {
      mapboxglCtrlBottomRights.forEach(bottomRight => {
        bottomRight.style.display = 'none';
      });
    }
  };

  return (
    <Map
      onLoad={handleMapLoad}
      onRender={onRender}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      initialViewState={{
        longitude: 126.7901852,
        latitude: 37.6385557,
        pitch: 45, // 카메라 각도 (기울기)
        bearing: 0, // 카메라 방향
        zoom: 19
      }}
      style={{
        position: 'relative',
        width: '100%',
        height: '250px',
        minHeight: '250px'
      }}
      mapStyle={mapStyle}
      ref={mapContainerRef}
      minZoom={18}
      // terrain={{ source: 'mapbox-dem', exaggeration: 0.1 }}
    >
      {/*<GeolocateControl position="top-left" />*/}
      {/*<FullscreenControl position="top-left" />*/}
      <NavigationControl position="top-left" />
      <ScaleControl />

      {!isEmpty(sensorInfo) && (
        <Marker
          key={sensorInfo.id}
          longitude={sensorInfo.location.longitude}
          latitude={sensorInfo.location.latitude}
          anchor="center"
        >
          <div
            // onMouseEnter={() => handleSensorMarkerHover(sensor.id)}
            // onMouseLeave={handleSensorMarkerLeave}
            style={{
              width: '15px',
              height: '15px',
              borderRadius: '50%',
              backgroundColor:
                sensorInfo.status === 'ON'
                  ? getColor('primary')
                  : sensorInfo.status === 'OFF'
                  ? getColor('warning')
                  : getColor('danger'),
              opacity: 0.5,
              cursor: 'default'
            }}
          />
          <h6>{sensorInfo.sensorHole.value}</h6>
        </Marker>
      )}

      <Layer {...buildingLayerStyle} />
    </Map>
  );
};

export default MapboxMinimapSensor;
