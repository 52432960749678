import { chainAxios } from 'helpers/chainAxios';

export const getBottomEvents = async () => {
  let url = 'api/events?pageNo=0&pageSize=20&sort=createDate$desc';
  // console.log(url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    return response.data.data.eventList.dataList;
  }
  return [];
};
