export const guSejongData = {
  type: 'FeatureCollection',
  features: [
    {
      id: '36110',
      type: 'Feature',
      properties: {
        SEC_SGG_CD: '36110',
        SEC_SGG_NM: ['세종특별자치시'],
        COL_SGG_CD: '36000',
        SEC_SGG_SIZE: '2',
        bbox: [127.12773, 36.40676, 127.41084, 36.73382],
        center: [127.25867, 36.56073],
        area: 464798280
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [127.16609, 36.7335],
            [127.16618, 36.73348],
            [127.16661, 36.73348],
            [127.1666, 36.73318],
            [127.16645, 36.7329],
            [127.16655, 36.7328],
            [127.1668, 36.7332],
            [127.167, 36.73332],
            [127.16715, 36.73327],
            [127.16714, 36.73298],
            [127.16709, 36.73273],
            [127.16703, 36.73255],
            [127.16698, 36.73249],
            [127.16695, 36.73242],
            [127.16697, 36.73237],
            [127.16704, 36.73236],
            [127.1671, 36.73239],
            [127.16726, 36.73255],
            [127.1675, 36.73274],
            [127.16773, 36.73302],
            [127.16784, 36.73309],
            [127.16834, 36.73316],
            [127.16858, 36.73314],
            [127.16901, 36.73295],
            [127.16967, 36.73243],
            [127.16994, 36.73238],
            [127.17012, 36.73246],
            [127.17044, 36.73248],
            [127.17056, 36.73242],
            [127.17075, 36.73227],
            [127.17108, 36.7322],
            [127.17107, 36.73225],
            [127.17122, 36.73223],
            [127.17146, 36.73204],
            [127.17186, 36.73164],
            [127.1719, 36.73138],
            [127.17201, 36.73114],
            [127.17206, 36.73082],
            [127.17206, 36.7307],
            [127.17188, 36.73074],
            [127.17262, 36.72986],
            [127.17286, 36.72955],
            [127.17295, 36.72936],
            [127.17306, 36.7292],
            [127.17358, 36.72869],
            [127.17413, 36.72857],
            [127.17424, 36.72857],
            [127.17445, 36.72931],
            [127.17469, 36.72894],
            [127.17491, 36.72896],
            [127.17513, 36.72895],
            [127.17633, 36.72872],
            [127.177, 36.72856],
            [127.17857, 36.7288],
            [127.17997, 36.72907],
            [127.18131, 36.72941],
            [127.18179, 36.73009],
            [127.18271, 36.72989],
            [127.18322, 36.73032],
            [127.18434, 36.73003],
            [127.18457, 36.72969],
            [127.18567, 36.7292],
            [127.1858, 36.72913],
            [127.1873, 36.72799],
            [127.18853, 36.72787],
            [127.18877, 36.72776],
            [127.18893, 36.72772],
            [127.18915, 36.72773],
            [127.18942, 36.72778],
            [127.18952, 36.72828],
            [127.1904, 36.72861],
            [127.19147, 36.72841],
            [127.19259, 36.72864],
            [127.19385, 36.72771],
            [127.19541, 36.7286],
            [127.19567, 36.72836],
            [127.19627, 36.72821],
            [127.19728, 36.72819],
            [127.1976, 36.72765],
            [127.19856, 36.72729],
            [127.19905, 36.7274],
            [127.2002, 36.72686],
            [127.20136, 36.72612],
            [127.2023, 36.72627],
            [127.20312, 36.72615],
            [127.2037, 36.7253],
            [127.20422, 36.72507],
            [127.20367, 36.72374],
            [127.20382, 36.72302],
            [127.20448, 36.72241],
            [127.20436, 36.72163],
            [127.20448, 36.72146],
            [127.20481, 36.72088],
            [127.20629, 36.72069],
            [127.20797, 36.71951],
            [127.20794, 36.71919],
            [127.20784, 36.71904],
            [127.20905, 36.71862],
            [127.20998, 36.71848],
            [127.21068, 36.71822],
            [127.21145, 36.71829],
            [127.21231, 36.71811],
            [127.21249, 36.71821],
            [127.21255, 36.71828],
            [127.21348, 36.71901],
            [127.21402, 36.71878],
            [127.21458, 36.71883],
            [127.21488, 36.71838],
            [127.21504, 36.71838],
            [127.21511, 36.71821],
            [127.21485, 36.71707],
            [127.21503, 36.7169],
            [127.21521, 36.71689],
            [127.21528, 36.71661],
            [127.21599, 36.71644],
            [127.21607, 36.7161],
            [127.21814, 36.71559],
            [127.21924, 36.71547],
            [127.21966, 36.71554],
            [127.22004, 36.7155],
            [127.22031, 36.7156],
            [127.22121, 36.7156],
            [127.22195, 36.71549],
            [127.22278, 36.71554],
            [127.22322, 36.71566],
            [127.22363, 36.71572],
            [127.22402, 36.71534],
            [127.22403, 36.71502],
            [127.22416, 36.71483],
            [127.22421, 36.71472],
            [127.22379, 36.7144],
            [127.22355, 36.71406],
            [127.22352, 36.71399],
            [127.22343, 36.71383],
            [127.22349, 36.71369],
            [127.2234, 36.71356],
            [127.22342, 36.71318],
            [127.22334, 36.71297],
            [127.22364, 36.71166],
            [127.22394, 36.7116],
            [127.22426, 36.71098],
            [127.22512, 36.71037],
            [127.22651, 36.71002],
            [127.22744, 36.709],
            [127.22862, 36.7082],
            [127.22906, 36.70841],
            [127.22975, 36.70783],
            [127.23001, 36.70778],
            [127.23034, 36.70782],
            [127.23078, 36.70802],
            [127.23109, 36.70842],
            [127.23126, 36.70855],
            [127.23168, 36.70882],
            [127.23224, 36.70905],
            [127.23303, 36.70895],
            [127.23316, 36.70895],
            [127.23343, 36.70897],
            [127.23426, 36.7088],
            [127.23441, 36.70807],
            [127.2353, 36.70739],
            [127.2364, 36.70745],
            [127.23687, 36.70651],
            [127.2368, 36.70639],
            [127.23699, 36.70588],
            [127.23701, 36.70573],
            [127.23705, 36.70441],
            [127.23721, 36.70429],
            [127.23779, 36.70395],
            [127.2388, 36.70324],
            [127.23921, 36.70324],
            [127.23956, 36.70335],
            [127.23967, 36.70232],
            [127.24076, 36.70192],
            [127.24169, 36.70126],
            [127.24196, 36.70117],
            [127.24247, 36.70117],
            [127.24326, 36.70093],
            [127.24394, 36.70112],
            [127.24437, 36.70109],
            [127.24476, 36.70077],
            [127.24467, 36.70049],
            [127.24476, 36.70002],
            [127.24399, 36.69961],
            [127.24364, 36.69949],
            [127.24371, 36.69888],
            [127.24402, 36.69845],
            [127.24387, 36.69795],
            [127.24437, 36.69741],
            [127.24506, 36.69691],
            [127.2453, 36.69679],
            [127.24536, 36.69634],
            [127.24557, 36.69601],
            [127.24571, 36.69589],
            [127.24713, 36.69621],
            [127.24741, 36.69558],
            [127.24805, 36.69505],
            [127.24843, 36.69541],
            [127.24883, 36.69556],
            [127.24984, 36.69565],
            [127.24992, 36.69587],
            [127.25052, 36.69646],
            [127.25083, 36.69656],
            [127.25122, 36.69701],
            [127.25217, 36.69676],
            [127.25228, 36.69625],
            [127.25292, 36.69611],
            [127.253, 36.69598],
            [127.2541, 36.69496],
            [127.2546, 36.69486],
            [127.25489, 36.69458],
            [127.25549, 36.69431],
            [127.25628, 36.6941],
            [127.2571, 36.69372],
            [127.25722, 36.69332],
            [127.25792, 36.69222],
            [127.25866, 36.6924],
            [127.25994, 36.69263],
            [127.26012, 36.69262],
            [127.26059, 36.69232],
            [127.26095, 36.69249],
            [127.26111, 36.69263],
            [127.26146, 36.69275],
            [127.26174, 36.69263],
            [127.2635, 36.69236],
            [127.26414, 36.69225],
            [127.26523, 36.6922],
            [127.26582, 36.69247],
            [127.26611, 36.69262],
            [127.2668, 36.69241],
            [127.26701, 36.69273],
            [127.26759, 36.69294],
            [127.26818, 36.69311],
            [127.26843, 36.69329],
            [127.26875, 36.69356],
            [127.26974, 36.69373],
            [127.27032, 36.69435],
            [127.27037, 36.69438],
            [127.27083, 36.69458],
            [127.2715, 36.69444],
            [127.27216, 36.69463],
            [127.27241, 36.69464],
            [127.27302, 36.69506],
            [127.27348, 36.69527],
            [127.27579, 36.69349],
            [127.27581, 36.69325],
            [127.27628, 36.69248],
            [127.27656, 36.69207],
            [127.27665, 36.69189],
            [127.27729, 36.69163],
            [127.27771, 36.69135],
            [127.27832, 36.69155],
            [127.27903, 36.69191],
            [127.27997, 36.69213],
            [127.2806, 36.69174],
            [127.28136, 36.69041],
            [127.28261, 36.69061],
            [127.28371, 36.69052],
            [127.28406, 36.6905],
            [127.28425, 36.69047],
            [127.28459, 36.6905],
            [127.28526, 36.69061],
            [127.28549, 36.6912],
            [127.28603, 36.6908],
            [127.28743, 36.6902],
            [127.28776, 36.68997],
            [127.28789, 36.68958],
            [127.28847, 36.68972],
            [127.28881, 36.6895],
            [127.28884, 36.68931],
            [127.28934, 36.68861],
            [127.28946, 36.68826],
            [127.28957, 36.68806],
            [127.29083, 36.68767],
            [127.29101, 36.68752],
            [127.29194, 36.68704],
            [127.29285, 36.68682],
            [127.29313, 36.6869],
            [127.29369, 36.68689],
            [127.29527, 36.68655],
            [127.2954, 36.68663],
            [127.29624, 36.68588],
            [127.29698, 36.686],
            [127.29756, 36.68579],
            [127.29786, 36.68591],
            [127.29816, 36.686],
            [127.29855, 36.68594],
            [127.29952, 36.68604],
            [127.30022, 36.68554],
            [127.30079, 36.68533],
            [127.30104, 36.68543],
            [127.30128, 36.68564],
            [127.30137, 36.68574],
            [127.30182, 36.68587],
            [127.30282, 36.68564],
            [127.30299, 36.68558],
            [127.30308, 36.6856],
            [127.30332, 36.68531],
            [127.30365, 36.68518],
            [127.30375, 36.68511],
            [127.30448, 36.68512],
            [127.30445, 36.68486],
            [127.30466, 36.68418],
            [127.30567, 36.68351],
            [127.30615, 36.68277],
            [127.30692, 36.68262],
            [127.30733, 36.68225],
            [127.30777, 36.68189],
            [127.30764, 36.68122],
            [127.30766, 36.68088],
            [127.30749, 36.68025],
            [127.30749, 36.67986],
            [127.30739, 36.67941],
            [127.30748, 36.67878],
            [127.30769, 36.67821],
            [127.30731, 36.67776],
            [127.30689, 36.6765],
            [127.30672, 36.67625],
            [127.30625, 36.67534],
            [127.30585, 36.6749],
            [127.30553, 36.67465],
            [127.30527, 36.6743],
            [127.30542, 36.67386],
            [127.30558, 36.67358],
            [127.30544, 36.67316],
            [127.30553, 36.67277],
            [127.306, 36.67245],
            [127.3065, 36.67125],
            [127.30661, 36.67061],
            [127.30664, 36.67014],
            [127.30696, 36.66972],
            [127.30702, 36.6696],
            [127.30714, 36.66943],
            [127.30698, 36.6688],
            [127.30673, 36.6681],
            [127.30639, 36.66734],
            [127.30625, 36.66708],
            [127.30586, 36.6675],
            [127.30516, 36.66747],
            [127.30474, 36.66731],
            [127.30395, 36.66694],
            [127.30367, 36.66661],
            [127.30304, 36.66629],
            [127.30301, 36.66604],
            [127.30281, 36.6659],
            [127.30245, 36.66571],
            [127.30141, 36.66405],
            [127.30143, 36.66351],
            [127.30103, 36.66219],
            [127.29955, 36.66215],
            [127.2979, 36.66136],
            [127.29707, 36.66114],
            [127.29661, 36.66084],
            [127.29627, 36.66032],
            [127.29575, 36.65967],
            [127.29515, 36.65985],
            [127.29446, 36.65966],
            [127.29274, 36.6599],
            [127.29193, 36.65952],
            [127.28899, 36.65938],
            [127.28851, 36.65896],
            [127.2886, 36.65861],
            [127.28891, 36.6582],
            [127.28864, 36.65793],
            [127.28888, 36.65747],
            [127.28873, 36.65685],
            [127.288, 36.6564],
            [127.28796, 36.6563],
            [127.28792, 36.65594],
            [127.28787, 36.65589],
            [127.28741, 36.65566],
            [127.28713, 36.65485],
            [127.28677, 36.6543],
            [127.28665, 36.65402],
            [127.2866, 36.65386],
            [127.28649, 36.65333],
            [127.28653, 36.65282],
            [127.28617, 36.65227],
            [127.28603, 36.65168],
            [127.28629, 36.65124],
            [127.28632, 36.65093],
            [127.28569, 36.65007],
            [127.28499, 36.65001],
            [127.28477, 36.64988],
            [127.28428, 36.64995],
            [127.2839, 36.64934],
            [127.28396, 36.6487],
            [127.2835, 36.64858],
            [127.28326, 36.64763],
            [127.28344, 36.6472],
            [127.28317, 36.64701],
            [127.28268, 36.64682],
            [127.28256, 36.64662],
            [127.28232, 36.64595],
            [127.28205, 36.64564],
            [127.28202, 36.6455],
            [127.28147, 36.645],
            [127.28191, 36.64366],
            [127.28197, 36.64322],
            [127.28236, 36.64258],
            [127.28255, 36.6423],
            [127.28264, 36.64222],
            [127.28175, 36.64186],
            [127.28103, 36.64155],
            [127.28031, 36.6412],
            [127.28008, 36.6409],
            [127.27962, 36.64073],
            [127.27952, 36.64069],
            [127.27859, 36.64134],
            [127.27836, 36.64135],
            [127.27812, 36.64144],
            [127.27788, 36.64156],
            [127.27711, 36.64184],
            [127.27703, 36.64174],
            [127.27652, 36.64132],
            [127.2764, 36.64083],
            [127.27623, 36.64054],
            [127.276, 36.64008],
            [127.27571, 36.63983],
            [127.27623, 36.63912],
            [127.27633, 36.63896],
            [127.27663, 36.63821],
            [127.27671, 36.63779],
            [127.27679, 36.63749],
            [127.27696, 36.63715],
            [127.27715, 36.63684],
            [127.27722, 36.63674],
            [127.2775, 36.63647],
            [127.27801, 36.63607],
            [127.27831, 36.6358],
            [127.2786, 36.63548],
            [127.27881, 36.63518],
            [127.27893, 36.63493],
            [127.27895, 36.63461],
            [127.27889, 36.63417],
            [127.27893, 36.63402],
            [127.27904, 36.63383],
            [127.27918, 36.63367],
            [127.27934, 36.63355],
            [127.27941, 36.63351],
            [127.27949, 36.6335],
            [127.27965, 36.63371],
            [127.27999, 36.63397],
            [127.28032, 36.63413],
            [127.28093, 36.63433],
            [127.28134, 36.63442],
            [127.28217, 36.63453],
            [127.28252, 36.63454],
            [127.28273, 36.63452],
            [127.2831, 36.63444],
            [127.28385, 36.63416],
            [127.28421, 36.63398],
            [127.2846, 36.63384],
            [127.28494, 36.6338],
            [127.2851, 36.63381],
            [127.28528, 36.63384],
            [127.28546, 36.63389],
            [127.28556, 36.63393],
            [127.28573, 36.63405],
            [127.2859, 36.63422],
            [127.28591, 36.63432],
            [127.28616, 36.63464],
            [127.28636, 36.63498],
            [127.28656, 36.63544],
            [127.28674, 36.63574],
            [127.28683, 36.63583],
            [127.28726, 36.63602],
            [127.28764, 36.63622],
            [127.28803, 36.63645],
            [127.28845, 36.63672],
            [127.28869, 36.63681],
            [127.2892, 36.63691],
            [127.28947, 36.63695],
            [127.29028, 36.63695],
            [127.29057, 36.6369],
            [127.2908, 36.63682],
            [127.29107, 36.63669],
            [127.29143, 36.63644],
            [127.29152, 36.63631],
            [127.29206, 36.63583],
            [127.29219, 36.63563],
            [127.29225, 36.63545],
            [127.29228, 36.63518],
            [127.29207, 36.63455],
            [127.2917, 36.63385],
            [127.29112, 36.63283],
            [127.29077, 36.63204],
            [127.29051, 36.6313],
            [127.29045, 36.63095],
            [127.29043, 36.63063],
            [127.29047, 36.63009],
            [127.29054, 36.62962],
            [127.29069, 36.62923],
            [127.2907, 36.62904],
            [127.29115, 36.62664],
            [127.29126, 36.62628],
            [127.2915, 36.62583],
            [127.29165, 36.62569],
            [127.29172, 36.62551],
            [127.29202, 36.62516],
            [127.29223, 36.62496],
            [127.29227, 36.62418],
            [127.29247, 36.62411],
            [127.29261, 36.62403],
            [127.29278, 36.62397],
            [127.29306, 36.62396],
            [127.29323, 36.62394],
            [127.29229, 36.62379],
            [127.29265, 36.62341],
            [127.29279, 36.62319],
            [127.29298, 36.62297],
            [127.29334, 36.6227],
            [127.29356, 36.62263],
            [127.29366, 36.62257],
            [127.29398, 36.62211],
            [127.294, 36.62201],
            [127.29424, 36.62135],
            [127.29444, 36.62096],
            [127.29447, 36.62081],
            [127.29467, 36.62046],
            [127.29485, 36.62023],
            [127.29494, 36.62008],
            [127.29501, 36.61992],
            [127.29537, 36.61938],
            [127.29552, 36.61932],
            [127.29581, 36.61929],
            [127.29592, 36.61916],
            [127.29617, 36.61892],
            [127.29635, 36.61881],
            [127.29649, 36.61875],
            [127.29675, 36.61867],
            [127.29704, 36.61866],
            [127.29754, 36.61871],
            [127.29851, 36.61873],
            [127.2987, 36.6187],
            [127.2989, 36.61864],
            [127.29961, 36.61794],
            [127.29976, 36.61771],
            [127.30012, 36.61699],
            [127.30028, 36.61638],
            [127.30032, 36.61611],
            [127.30033, 36.6159],
            [127.30031, 36.61569],
            [127.30022, 36.61512],
            [127.30009, 36.61452],
            [127.30009, 36.61439],
            [127.30011, 36.61426],
            [127.3002, 36.61399],
            [127.30034, 36.6138],
            [127.30054, 36.6136],
            [127.30103, 36.61318],
            [127.30128, 36.61304],
            [127.30161, 36.61294],
            [127.30178, 36.61291],
            [127.30194, 36.61222],
            [127.30197, 36.61181],
            [127.30197, 36.61159],
            [127.302, 36.61144],
            [127.30189, 36.61104],
            [127.30128, 36.61164],
            [127.30119, 36.6117],
            [127.30118, 36.61162],
            [127.30122, 36.61147],
            [127.3012, 36.61118],
            [127.3014, 36.61068],
            [127.30145, 36.61023],
            [127.30136, 36.61022],
            [127.30144, 36.60985],
            [127.30158, 36.60938],
            [127.30159, 36.6093],
            [127.30122, 36.6092],
            [127.30135, 36.60837],
            [127.30135, 36.60788],
            [127.30167, 36.60735],
            [127.30177, 36.60722],
            [127.30187, 36.60715],
            [127.30198, 36.60702],
            [127.30206, 36.60688],
            [127.30194, 36.60677],
            [127.30188, 36.6067],
            [127.30206, 36.60659],
            [127.30224, 36.60644],
            [127.30241, 36.60624],
            [127.30245, 36.60608],
            [127.30259, 36.60596],
            [127.30285, 36.6058],
            [127.30284, 36.60587],
            [127.30305, 36.60581],
            [127.30331, 36.6057],
            [127.30325, 36.60555],
            [127.30303, 36.60561],
            [127.3028, 36.60574],
            [127.30273, 36.60575],
            [127.30266, 36.60578],
            [127.30256, 36.60585],
            [127.30274, 36.60565],
            [127.30286, 36.6055],
            [127.30296, 36.60536],
            [127.30298, 36.60528],
            [127.3033, 36.6051],
            [127.3037, 36.60492],
            [127.30428, 36.60461],
            [127.30447, 36.60449],
            [127.30493, 36.60409],
            [127.30515, 36.60384],
            [127.30534, 36.60358],
            [127.30541, 36.60346],
            [127.30546, 36.60332],
            [127.30609, 36.60237],
            [127.30617, 36.60226],
            [127.30619, 36.60219],
            [127.3062, 36.60211],
            [127.30611, 36.60157],
            [127.30617, 36.60085],
            [127.30615, 36.60071],
            [127.30615, 36.60054],
            [127.30613, 36.60036],
            [127.30604, 36.60001],
            [127.30592, 36.59972],
            [127.30613, 36.59959],
            [127.30624, 36.59947],
            [127.306, 36.59929],
            [127.30608, 36.59923],
            [127.30614, 36.59916],
            [127.30614, 36.59889],
            [127.30617, 36.59878],
            [127.30604, 36.59879],
            [127.30602, 36.59849],
            [127.30595, 36.59826],
            [127.30581, 36.59832],
            [127.30581, 36.59821],
            [127.30577, 36.59803],
            [127.30569, 36.59776],
            [127.30565, 36.59754],
            [127.30565, 36.59748],
            [127.30569, 36.59736],
            [127.30543, 36.59734],
            [127.30542, 36.59766],
            [127.305, 36.59763],
            [127.30501, 36.5971],
            [127.30509, 36.59659],
            [127.30486, 36.59657],
            [127.30465, 36.59651],
            [127.30472, 36.59629],
            [127.30448, 36.59617],
            [127.30437, 36.59599],
            [127.30429, 36.59556],
            [127.30423, 36.5949],
            [127.30422, 36.59463],
            [127.30422, 36.59446],
            [127.30415, 36.59383],
            [127.30454, 36.59315],
            [127.30458, 36.5926],
            [127.30453, 36.59217],
            [127.30443, 36.59174],
            [127.30433, 36.59148],
            [127.30414, 36.59113],
            [127.30371, 36.59049],
            [127.30356, 36.5903],
            [127.30333, 36.59006],
            [127.30251, 36.58906],
            [127.30244, 36.589],
            [127.30217, 36.58878],
            [127.30171, 36.58848],
            [127.30105, 36.58817],
            [127.30034, 36.58787],
            [127.3, 36.58775],
            [127.29968, 36.58755],
            [127.29958, 36.58746],
            [127.29941, 36.5872],
            [127.29935, 36.587],
            [127.29933, 36.5868],
            [127.29939, 36.58653],
            [127.29951, 36.58631],
            [127.29971, 36.58607],
            [127.29983, 36.58597],
            [127.30005, 36.58583],
            [127.30021, 36.58577],
            [127.30047, 36.58573],
            [127.30076, 36.58565],
            [127.30146, 36.58552],
            [127.30171, 36.5854],
            [127.3019, 36.58526],
            [127.30211, 36.58495],
            [127.30221, 36.5849],
            [127.3024, 36.58485],
            [127.30304, 36.58397],
            [127.30318, 36.58384],
            [127.3034, 36.58367],
            [127.30384, 36.58343],
            [127.30406, 36.58334],
            [127.30542, 36.58306],
            [127.30575, 36.58301],
            [127.3062, 36.58298],
            [127.30707, 36.58304],
            [127.30755, 36.58304],
            [127.30807, 36.58301],
            [127.30877, 36.58283],
            [127.30971, 36.5829],
            [127.31077, 36.5829],
            [127.31185, 36.58287],
            [127.31275, 36.58286],
            [127.31321, 36.58288],
            [127.31366, 36.58302],
            [127.31401, 36.58309],
            [127.31527, 36.58328],
            [127.31564, 36.58331],
            [127.31619, 36.58331],
            [127.31696, 36.58337],
            [127.31879, 36.58368],
            [127.31925, 36.58379],
            [127.31939, 36.58376],
            [127.31998, 36.58374],
            [127.32043, 36.58373],
            [127.32099, 36.58378],
            [127.32147, 36.58379],
            [127.32268, 36.58229],
            [127.32295, 36.58203],
            [127.32296, 36.58195],
            [127.32293, 36.58181],
            [127.32288, 36.58173],
            [127.3228, 36.58168],
            [127.32234, 36.58154],
            [127.32186, 36.58146],
            [127.32177, 36.58143],
            [127.32165, 36.58136],
            [127.32153, 36.58125],
            [127.32153, 36.58118],
            [127.32163, 36.58108],
            [127.3218, 36.58097],
            [127.32198, 36.58089],
            [127.32225, 36.58079],
            [127.32268, 36.58068],
            [127.32475, 36.58038],
            [127.32487, 36.58034],
            [127.32493, 36.58028],
            [127.32502, 36.58003],
            [127.32498, 36.57993],
            [127.32504, 36.579],
            [127.3251, 36.57871],
            [127.32529, 36.57846],
            [127.32535, 36.57832],
            [127.32535, 36.57813],
            [127.32541, 36.57779],
            [127.32555, 36.57764],
            [127.32596, 36.57702],
            [127.32628, 36.57676],
            [127.32639, 36.57652],
            [127.32684, 36.57632],
            [127.32702, 36.57634],
            [127.32719, 36.57617],
            [127.32722, 36.57603],
            [127.32741, 36.57603],
            [127.32753, 36.57597],
            [127.32779, 36.57589],
            [127.32788, 36.57584],
            [127.32791, 36.57577],
            [127.32809, 36.57575],
            [127.32814, 36.57572],
            [127.32842, 36.57556],
            [127.32906, 36.57545],
            [127.32925, 36.57532],
            [127.32967, 36.57521],
            [127.32989, 36.57499],
            [127.33022, 36.57489],
            [127.33071, 36.57501],
            [127.3313, 36.57514],
            [127.33204, 36.57459],
            [127.33219, 36.57436],
            [127.33237, 36.57426],
            [127.33236, 36.57399],
            [127.33211, 36.57357],
            [127.33244, 36.57312],
            [127.33246, 36.57273],
            [127.33379, 36.5723],
            [127.33401, 36.57187],
            [127.33568, 36.5711],
            [127.33563, 36.5708],
            [127.33658, 36.5699],
            [127.33582, 36.56906],
            [127.33584, 36.56893],
            [127.33541, 36.56845],
            [127.33546, 36.56832],
            [127.3353, 36.56806],
            [127.33535, 36.56716],
            [127.3359, 36.56669],
            [127.33604, 36.5666],
            [127.33614, 36.56635],
            [127.33627, 36.56612],
            [127.33627, 36.56597],
            [127.33632, 36.56581],
            [127.33659, 36.56531],
            [127.33667, 36.56534],
            [127.33679, 36.56507],
            [127.33682, 36.56495],
            [127.33677, 36.56489],
            [127.33679, 36.56468],
            [127.33683, 36.56453],
            [127.33681, 36.5643],
            [127.33692, 36.5641],
            [127.3369, 36.56403],
            [127.33684, 36.56394],
            [127.33689, 36.56364],
            [127.33695, 36.56342],
            [127.33707, 36.56316],
            [127.33709, 36.56283],
            [127.33741, 36.56273],
            [127.33804, 36.56236],
            [127.33816, 36.56236],
            [127.33825, 36.56237],
            [127.33838, 36.56248],
            [127.33851, 36.56255],
            [127.3391, 36.56284],
            [127.3393, 36.56288],
            [127.33945, 36.56287],
            [127.33953, 36.56288],
            [127.33966, 36.56296],
            [127.3398, 36.56318],
            [127.33995, 36.56333],
            [127.34048, 36.56353],
            [127.34062, 36.5636],
            [127.34099, 36.56375],
            [127.34105, 36.56383],
            [127.34151, 36.56397],
            [127.34157, 36.56395],
            [127.34178, 36.56402],
            [127.3419, 36.56407],
            [127.3421, 36.5642],
            [127.34221, 36.56421],
            [127.34263, 36.56421],
            [127.34286, 36.56454],
            [127.34306, 36.56462],
            [127.34312, 36.56461],
            [127.34323, 36.56461],
            [127.34335, 36.56442],
            [127.3441, 36.56433],
            [127.34462, 36.56434],
            [127.34521, 36.56427],
            [127.34529, 36.56437],
            [127.34567, 36.56445],
            [127.34605, 36.56442],
            [127.34652, 36.56457],
            [127.34645, 36.56433],
            [127.34655, 36.56436],
            [127.34715, 36.56446],
            [127.3474, 36.56406],
            [127.34787, 36.56383],
            [127.34901, 36.56378],
            [127.34931, 36.56383],
            [127.35036, 36.56308],
            [127.35131, 36.56234],
            [127.35197, 36.56223],
            [127.35246, 36.56216],
            [127.3531, 36.56221],
            [127.35418, 36.56259],
            [127.35485, 36.56284],
            [127.3552, 36.56263],
            [127.35572, 36.5622],
            [127.35587, 36.56204],
            [127.35616, 36.56186],
            [127.35626, 36.56168],
            [127.35641, 36.56172],
            [127.35666, 36.56169],
            [127.35689, 36.5618],
            [127.35714, 36.56183],
            [127.35765, 36.56181],
            [127.35784, 36.56185],
            [127.35826, 36.56195],
            [127.35841, 36.56204],
            [127.35865, 36.56207],
            [127.35923, 36.56209],
            [127.35992, 36.56218],
            [127.36048, 36.56228],
            [127.36058, 36.5624],
            [127.36075, 36.56255],
            [127.36082, 36.56284],
            [127.36085, 36.56291],
            [127.3609, 36.56314],
            [127.36094, 36.56319],
            [127.36115, 36.56335],
            [127.36116, 36.56343],
            [127.36118, 36.56349],
            [127.36126, 36.56356],
            [127.36125, 36.56368],
            [127.36142, 36.56364],
            [127.36137, 36.56348],
            [127.36115, 36.56312],
            [127.36115, 36.56304],
            [127.36119, 36.56298],
            [127.36125, 36.56292],
            [127.36152, 36.56273],
            [127.36176, 36.56248],
            [127.36203, 36.5621],
            [127.36215, 36.56199],
            [127.36257, 36.56201],
            [127.36299, 36.56208],
            [127.36311, 36.56217],
            [127.36333, 36.56222],
            [127.36366, 36.56234],
            [127.36381, 36.56243],
            [127.36394, 36.56247],
            [127.36419, 36.56254],
            [127.36438, 36.56256],
            [127.36464, 36.56256],
            [127.36491, 36.56251],
            [127.36567, 36.56223],
            [127.36591, 36.56237],
            [127.3672, 36.56333],
            [127.36727, 36.5634],
            [127.36733, 36.56351],
            [127.36768, 36.56458],
            [127.36773, 36.56467],
            [127.36761, 36.56471],
            [127.36775, 36.56477],
            [127.36787, 36.56487],
            [127.36801, 36.56502],
            [127.36807, 36.56516],
            [127.36813, 36.5654],
            [127.36815, 36.56592],
            [127.36819, 36.56606],
            [127.36826, 36.5662],
            [127.36835, 36.56627],
            [127.36866, 36.56637],
            [127.369, 36.56635],
            [127.36945, 36.56635],
            [127.37005, 36.56641],
            [127.3701, 36.56645],
            [127.37038, 36.56646],
            [127.37049, 36.56649],
            [127.37076, 36.56649],
            [127.37087, 36.56654],
            [127.37104, 36.56659],
            [127.37155, 36.56682],
            [127.37297, 36.56694],
            [127.37317, 36.56698],
            [127.37456, 36.56735],
            [127.37499, 36.5674],
            [127.37508, 36.5674],
            [127.37577, 36.56751],
            [127.37624, 36.56762],
            [127.37665, 36.56776],
            [127.37677, 36.56773],
            [127.37685, 36.56774],
            [127.37681, 36.56787],
            [127.37718, 36.56805],
            [127.37713, 36.5679],
            [127.37755, 36.56783],
            [127.37748, 36.56771],
            [127.37699, 36.56662],
            [127.37688, 36.5665],
            [127.37678, 36.56642],
            [127.37585, 36.56589],
            [127.37503, 36.5657],
            [127.37396, 36.56524],
            [127.37301, 36.56462],
            [127.37262, 36.56412],
            [127.37205, 36.56361],
            [127.37207, 36.56352],
            [127.37224, 36.56346],
            [127.37229, 36.56355],
            [127.37245, 36.56365],
            [127.37259, 36.56379],
            [127.37299, 36.56426],
            [127.37321, 36.56448],
            [127.3741, 36.5651],
            [127.37599, 36.56346],
            [127.37621, 36.5636],
            [127.37641, 36.56377],
            [127.37665, 36.56393],
            [127.3767, 36.56396],
            [127.37679, 36.56398],
            [127.37703, 36.56383],
            [127.37714, 36.56374],
            [127.3772, 36.56366],
            [127.37748, 36.5634],
            [127.3778, 36.56311],
            [127.37794, 36.56301],
            [127.37799, 36.56294],
            [127.37796, 36.56286],
            [127.3766, 36.56193],
            [127.3762, 36.56156],
            [127.37614, 36.56148],
            [127.37647, 36.56125],
            [127.3765, 36.56121],
            [127.37646, 36.56094],
            [127.37574, 36.56051],
            [127.375, 36.56021],
            [127.37314, 36.56015],
            [127.37267, 36.55995],
            [127.37255, 36.55972],
            [127.37276, 36.55962],
            [127.3731, 36.55914],
            [127.37323, 36.55873],
            [127.37321, 36.55838],
            [127.37342, 36.55811],
            [127.37351, 36.55781],
            [127.37361, 36.55772],
            [127.37375, 36.55765],
            [127.3744, 36.55768],
            [127.37556, 36.55788],
            [127.37564, 36.55779],
            [127.37567, 36.55775],
            [127.37568, 36.55768],
            [127.37609, 36.55725],
            [127.37643, 36.55683],
            [127.37693, 36.55643],
            [127.37709, 36.55628],
            [127.37776, 36.55559],
            [127.37812, 36.55513],
            [127.37824, 36.55485],
            [127.37832, 36.55471],
            [127.37836, 36.55462],
            [127.37836, 36.55456],
            [127.3784, 36.55435],
            [127.37857, 36.55418],
            [127.37897, 36.5534],
            [127.37909, 36.55307],
            [127.37919, 36.55258],
            [127.3789, 36.55253],
            [127.37869, 36.55218],
            [127.37894, 36.55154],
            [127.37845, 36.55112],
            [127.37848, 36.55095],
            [127.37848, 36.5498],
            [127.37929, 36.54939],
            [127.38033, 36.54923],
            [127.38103, 36.54897],
            [127.38147, 36.54895],
            [127.3817, 36.54888],
            [127.38255, 36.54837],
            [127.38315, 36.5479],
            [127.38271, 36.54637],
            [127.38395, 36.54553],
            [127.38391, 36.54524],
            [127.38401, 36.54443],
            [127.38385, 36.5438],
            [127.38378, 36.54343],
            [127.38357, 36.5431],
            [127.38351, 36.5428],
            [127.38364, 36.54176],
            [127.38437, 36.54122],
            [127.38508, 36.54105],
            [127.38567, 36.54125],
            [127.38673, 36.54101],
            [127.38724, 36.54124],
            [127.3878, 36.54114],
            [127.38825, 36.54075],
            [127.38896, 36.54038],
            [127.3898, 36.54029],
            [127.39118, 36.54052],
            [127.3914, 36.54049],
            [127.3918, 36.54039],
            [127.39194, 36.54015],
            [127.39232, 36.54],
            [127.39286, 36.54007],
            [127.39317, 36.54003],
            [127.39421, 36.54025],
            [127.39545, 36.54002],
            [127.39646, 36.54012],
            [127.39661, 36.54009],
            [127.39751, 36.54024],
            [127.39782, 36.5402],
            [127.39849, 36.54053],
            [127.39922, 36.54117],
            [127.40018, 36.54176],
            [127.40102, 36.54139],
            [127.40126, 36.54148],
            [127.40154, 36.54146],
            [127.40176, 36.54122],
            [127.40202, 36.54074],
            [127.40206, 36.54039],
            [127.40242, 36.53994],
            [127.40263, 36.53905],
            [127.4026, 36.53865],
            [127.40316, 36.53803],
            [127.4032, 36.5378],
            [127.40357, 36.5378],
            [127.40373, 36.53785],
            [127.40414, 36.53788],
            [127.40439, 36.53778],
            [127.40498, 36.5373],
            [127.40522, 36.53661],
            [127.40518, 36.53574],
            [127.4053, 36.53489],
            [127.40535, 36.53429],
            [127.40584, 36.53297],
            [127.40558, 36.53221],
            [127.40561, 36.5314],
            [127.40583, 36.53118],
            [127.4062, 36.53057],
            [127.40677, 36.5301],
            [127.40693, 36.52986],
            [127.40705, 36.52977],
            [127.40762, 36.52923],
            [127.40778, 36.5291],
            [127.40842, 36.52884],
            [127.40847, 36.52852],
            [127.40822, 36.52812],
            [127.4079, 36.52786],
            [127.40793, 36.52779],
            [127.40836, 36.52718],
            [127.4082, 36.52639],
            [127.40847, 36.5254],
            [127.40869, 36.52529],
            [127.40871, 36.52398],
            [127.4086, 36.5237],
            [127.40765, 36.52307],
            [127.40678, 36.52319],
            [127.40622, 36.52383],
            [127.40566, 36.52373],
            [127.40436, 36.52393],
            [127.40315, 36.52333],
            [127.40197, 36.52378],
            [127.40147, 36.52367],
            [127.40088, 36.52269],
            [127.40061, 36.52251],
            [127.39978, 36.52202],
            [127.39935, 36.52167],
            [127.39786, 36.52135],
            [127.39771, 36.52106],
            [127.39765, 36.52061],
            [127.39819, 36.52031],
            [127.39869, 36.52019],
            [127.39903, 36.51995],
            [127.4, 36.51967],
            [127.40036, 36.51904],
            [127.40121, 36.51899],
            [127.40137, 36.5185],
            [127.40221, 36.51818],
            [127.40259, 36.51788],
            [127.40286, 36.51785],
            [127.40302, 36.51765],
            [127.40371, 36.51741],
            [127.40419, 36.5168],
            [127.40419, 36.51558],
            [127.40536, 36.51307],
            [127.40465, 36.51271],
            [127.40493, 36.51197],
            [127.40558, 36.51188],
            [127.40617, 36.51182],
            [127.40646, 36.51134],
            [127.407, 36.51089],
            [127.40741, 36.51051],
            [127.40879, 36.50996],
            [127.40902, 36.50974],
            [127.40849, 36.50898],
            [127.40889, 36.50833],
            [127.40903, 36.50745],
            [127.40883, 36.50719],
            [127.40902, 36.50667],
            [127.40883, 36.50644],
            [127.40848, 36.50544],
            [127.40836, 36.50517],
            [127.40844, 36.50511],
            [127.40837, 36.50494],
            [127.40826, 36.50467],
            [127.4081, 36.50442],
            [127.40796, 36.50393],
            [127.40781, 36.50334],
            [127.40757, 36.50328],
            [127.40762, 36.50299],
            [127.40815, 36.50248],
            [127.40818, 36.5021],
            [127.40903, 36.50189],
            [127.40837, 36.50117],
            [127.40837, 36.50094],
            [127.4079, 36.50014],
            [127.40807, 36.49987],
            [127.40823, 36.49956],
            [127.40879, 36.49941],
            [127.40928, 36.49899],
            [127.40942, 36.49883],
            [127.40943, 36.49839],
            [127.40971, 36.49833],
            [127.41037, 36.49834],
            [127.4107, 36.49802],
            [127.41084, 36.49776],
            [127.41068, 36.49726],
            [127.41067, 36.49688],
            [127.41059, 36.49626],
            [127.41, 36.49594],
            [127.40984, 36.4953],
            [127.40976, 36.49494],
            [127.40942, 36.49508],
            [127.40859, 36.4952],
            [127.40822, 36.49523],
            [127.40769, 36.49503],
            [127.40699, 36.49481],
            [127.40653, 36.4946],
            [127.40635, 36.49463],
            [127.40583, 36.49439],
            [127.40548, 36.4942],
            [127.40536, 36.49411],
            [127.40521, 36.49407],
            [127.40492, 36.49395],
            [127.40462, 36.49387],
            [127.40442, 36.49376],
            [127.40425, 36.49369],
            [127.40288, 36.49344],
            [127.40259, 36.4934],
            [127.40238, 36.49342],
            [127.40178, 36.49343],
            [127.40099, 36.49343],
            [127.40073, 36.49341],
            [127.40035, 36.49343],
            [127.40009, 36.49341],
            [127.39975, 36.49334],
            [127.39958, 36.49329],
            [127.39864, 36.49292],
            [127.39777, 36.49261],
            [127.3972, 36.49237],
            [127.397, 36.49235],
            [127.39612, 36.49173],
            [127.39336, 36.49358],
            [127.39199, 36.4949],
            [127.39024, 36.49618],
            [127.38691, 36.49865],
            [127.38386, 36.50023],
            [127.38034, 36.49954],
            [127.38029, 36.49951],
            [127.3794, 36.49827],
            [127.37759, 36.49562],
            [127.37748, 36.49551],
            [127.37523, 36.49333],
            [127.3727, 36.49138],
            [127.37143, 36.49054],
            [127.3708, 36.49014],
            [127.36891, 36.48956],
            [127.36588, 36.49051],
            [127.36351, 36.49102],
            [127.36282, 36.49158],
            [127.36216, 36.49189],
            [127.36114, 36.49211],
            [127.36092, 36.49084],
            [127.36049, 36.49035],
            [127.36012, 36.49],
            [127.36023, 36.48973],
            [127.36017, 36.48848],
            [127.35955, 36.48772],
            [127.35924, 36.48731],
            [127.35925, 36.4861],
            [127.35919, 36.48521],
            [127.35845, 36.48468],
            [127.3584, 36.48459],
            [127.35801, 36.48408],
            [127.35795, 36.48348],
            [127.35783, 36.48312],
            [127.35816, 36.48253],
            [127.35886, 36.48256],
            [127.35899, 36.48252],
            [127.35915, 36.48228],
            [127.35913, 36.48215],
            [127.35914, 36.4821],
            [127.35934, 36.48206],
            [127.35963, 36.48182],
            [127.3599, 36.48176],
            [127.36, 36.4817],
            [127.36026, 36.48161],
            [127.36028, 36.48156],
            [127.36038, 36.48152],
            [127.36047, 36.48127],
            [127.36084, 36.48069],
            [127.36092, 36.48052],
            [127.36088, 36.47993],
            [127.36055, 36.47933],
            [127.36053, 36.4791],
            [127.36071, 36.47853],
            [127.36113, 36.47809],
            [127.36116, 36.47793],
            [127.36116, 36.47786],
            [127.36123, 36.47781],
            [127.36125, 36.47768],
            [127.36132, 36.47763],
            [127.36138, 36.4775],
            [127.36141, 36.47737],
            [127.36147, 36.47732],
            [127.3615, 36.4772],
            [127.36156, 36.47711],
            [127.36158, 36.47702],
            [127.36165, 36.47696],
            [127.36176, 36.47699],
            [127.36189, 36.47689],
            [127.36203, 36.47674],
            [127.36211, 36.47664],
            [127.36218, 36.4766],
            [127.36221, 36.47645],
            [127.3622, 36.47634],
            [127.3623, 36.47616],
            [127.36241, 36.47607],
            [127.36246, 36.476],
            [127.36257, 36.47598],
            [127.36272, 36.47591],
            [127.36283, 36.47594],
            [127.36287, 36.47606],
            [127.36293, 36.47606],
            [127.36296, 36.47601],
            [127.36326, 36.47573],
            [127.36401, 36.47514],
            [127.36404, 36.47497],
            [127.36397, 36.47498],
            [127.36399, 36.47493],
            [127.36399, 36.47482],
            [127.36385, 36.47435],
            [127.36379, 36.47422],
            [127.36367, 36.47405],
            [127.36295, 36.47359],
            [127.36285, 36.4733],
            [127.36272, 36.47287],
            [127.36231, 36.47199],
            [127.36225, 36.4719],
            [127.36205, 36.47176],
            [127.36217, 36.47117],
            [127.36239, 36.47075],
            [127.36239, 36.47069],
            [127.36224, 36.47061],
            [127.36157, 36.47075],
            [127.36082, 36.47051],
            [127.36038, 36.47011],
            [127.35988, 36.46987],
            [127.36005, 36.46902],
            [127.35977, 36.4686],
            [127.35977, 36.46828],
            [127.35972, 36.46821],
            [127.35914, 36.46769],
            [127.35904, 36.46757],
            [127.35931, 36.4673],
            [127.35925, 36.46725],
            [127.35934, 36.46713],
            [127.35946, 36.46684],
            [127.35956, 36.46651],
            [127.35964, 36.46614],
            [127.35934, 36.46567],
            [127.35905, 36.46537],
            [127.35877, 36.465],
            [127.35857, 36.46503],
            [127.35799, 36.46486],
            [127.35727, 36.46432],
            [127.35682, 36.4645],
            [127.35675, 36.4645],
            [127.35665, 36.46443],
            [127.35607, 36.46402],
            [127.35662, 36.46321],
            [127.35706, 36.46264],
            [127.35723, 36.46177],
            [127.35769, 36.46147],
            [127.35714, 36.46068],
            [127.357, 36.45995],
            [127.35679, 36.45977],
            [127.3569, 36.45957],
            [127.35694, 36.45942],
            [127.35691, 36.45926],
            [127.35676, 36.45892],
            [127.35683, 36.45876],
            [127.35735, 36.45834],
            [127.35782, 36.45807],
            [127.35789, 36.4577],
            [127.35801, 36.45741],
            [127.35797, 36.457],
            [127.35814, 36.45677],
            [127.35845, 36.45662],
            [127.3587, 36.45587],
            [127.35815, 36.45542],
            [127.3579, 36.45474],
            [127.3576, 36.45438],
            [127.35713, 36.45446],
            [127.35679, 36.45427],
            [127.35627, 36.45426],
            [127.356, 36.45386],
            [127.35635, 36.45278],
            [127.35621, 36.45248],
            [127.35603, 36.45189],
            [127.35631, 36.45128],
            [127.35614, 36.4509],
            [127.35601, 36.45053],
            [127.3555, 36.45018],
            [127.35533, 36.4502],
            [127.35453, 36.44975],
            [127.35442, 36.44921],
            [127.35417, 36.44907],
            [127.35402, 36.44881],
            [127.35398, 36.44855],
            [127.35351, 36.44789],
            [127.35293, 36.44764],
            [127.35272, 36.44761],
            [127.35146, 36.44794],
            [127.35083, 36.44781],
            [127.34983, 36.44801],
            [127.34913, 36.44729],
            [127.3492, 36.44709],
            [127.34857, 36.4467],
            [127.34821, 36.44656],
            [127.34746, 36.44641],
            [127.34703, 36.44606],
            [127.34651, 36.4457],
            [127.34616, 36.44515],
            [127.34595, 36.44496],
            [127.34536, 36.44448],
            [127.34494, 36.44439],
            [127.34488, 36.4443],
            [127.34478, 36.44399],
            [127.34474, 36.44391],
            [127.3447, 36.44346],
            [127.34449, 36.44336],
            [127.34436, 36.44327],
            [127.34425, 36.44299],
            [127.34414, 36.44268],
            [127.34411, 36.44242],
            [127.34399, 36.44178],
            [127.34375, 36.44142],
            [127.34378, 36.44117],
            [127.34322, 36.4402],
            [127.34306, 36.43954],
            [127.34303, 36.43905],
            [127.34312, 36.43873],
            [127.34255, 36.43788],
            [127.34216, 36.43802],
            [127.34166, 36.43799],
            [127.34151, 36.43765],
            [127.34129, 36.43728],
            [127.3409, 36.43688],
            [127.34091, 36.43662],
            [127.34015, 36.43615],
            [127.34015, 36.43596],
            [127.34035, 36.4357],
            [127.34077, 36.4347],
            [127.34054, 36.43411],
            [127.34048, 36.4333],
            [127.34069, 36.43289],
            [127.34067, 36.43253],
            [127.34059, 36.43223],
            [127.34082, 36.43172],
            [127.34086, 36.43138],
            [127.34106, 36.43081],
            [127.34066, 36.4305],
            [127.34043, 36.43043],
            [127.33981, 36.42996],
            [127.33947, 36.4299],
            [127.33906, 36.42966],
            [127.33882, 36.42915],
            [127.33834, 36.42899],
            [127.33793, 36.4287],
            [127.33756, 36.42834],
            [127.33675, 36.4282],
            [127.33586, 36.42784],
            [127.3356, 36.42766],
            [127.33472, 36.42698],
            [127.33358, 36.4268],
            [127.33273, 36.4265],
            [127.33226, 36.42555],
            [127.33154, 36.42516],
            [127.33145, 36.42493],
            [127.3311, 36.42468],
            [127.33042, 36.42439],
            [127.33026, 36.42404],
            [127.32915, 36.42332],
            [127.32802, 36.42313],
            [127.32734, 36.42275],
            [127.32741, 36.42268],
            [127.3271, 36.42249],
            [127.327, 36.42233],
            [127.3263, 36.42209],
            [127.32618, 36.42219],
            [127.32569, 36.42249],
            [127.32542, 36.42258],
            [127.32515, 36.42246],
            [127.32507, 36.42245],
            [127.32469, 36.42245],
            [127.32428, 36.42252],
            [127.32332, 36.42267],
            [127.32199, 36.42332],
            [127.32161, 36.42368],
            [127.32047, 36.42324],
            [127.32026, 36.42291],
            [127.31968, 36.42256],
            [127.31911, 36.4214],
            [127.31894, 36.42142],
            [127.31827, 36.42111],
            [127.31801, 36.42097],
            [127.31778, 36.42092],
            [127.31744, 36.42082],
            [127.3166, 36.42045],
            [127.3163, 36.42043],
            [127.31626, 36.42103],
            [127.31613, 36.42119],
            [127.31593, 36.42147],
            [127.31557, 36.42153],
            [127.3151, 36.42185],
            [127.31477, 36.42238],
            [127.31443, 36.42269],
            [127.31401, 36.42287],
            [127.31361, 36.42293],
            [127.31316, 36.42288],
            [127.31293, 36.4231],
            [127.31153, 36.42364],
            [127.3115, 36.42405],
            [127.31086, 36.42464],
            [127.31018, 36.42476],
            [127.30993, 36.42467],
            [127.30973, 36.42453],
            [127.30943, 36.42444],
            [127.30847, 36.42437],
            [127.3075, 36.42467],
            [127.30682, 36.42462],
            [127.30631, 36.42474],
            [127.30497, 36.4247],
            [127.3042, 36.42452],
            [127.30387, 36.42453],
            [127.30348, 36.4246],
            [127.30322, 36.42428],
            [127.30283, 36.42423],
            [127.30255, 36.42404],
            [127.30205, 36.4234],
            [127.30185, 36.42302],
            [127.3018, 36.42249],
            [127.30142, 36.42178],
            [127.30082, 36.4219],
            [127.30036, 36.42214],
            [127.29967, 36.42239],
            [127.29939, 36.42284],
            [127.29831, 36.42311],
            [127.29703, 36.42308],
            [127.29643, 36.42317],
            [127.29614, 36.42299],
            [127.29586, 36.42291],
            [127.29555, 36.42268],
            [127.29521, 36.42252],
            [127.29426, 36.42218],
            [127.29422, 36.42171],
            [127.29414, 36.42168],
            [127.29416, 36.42137],
            [127.29456, 36.4213],
            [127.29517, 36.4208],
            [127.29521, 36.42033],
            [127.29532, 36.42003],
            [127.29519, 36.41994],
            [127.29526, 36.41984],
            [127.29537, 36.4198],
            [127.29468, 36.4193],
            [127.29422, 36.41888],
            [127.29391, 36.4187],
            [127.29363, 36.4186],
            [127.29327, 36.41857],
            [127.2929, 36.41861],
            [127.29216, 36.41881],
            [127.29173, 36.41881],
            [127.29135, 36.4187],
            [127.29125, 36.41868],
            [127.2913, 36.41852],
            [127.29114, 36.41854],
            [127.29109, 36.41615],
            [127.29101, 36.41612],
            [127.29078, 36.41605],
            [127.28993, 36.41604],
            [127.28959, 36.41606],
            [127.28925, 36.416],
            [127.28875, 36.41571],
            [127.28749, 36.41547],
            [127.28669, 36.41522],
            [127.28572, 36.41507],
            [127.28522, 36.41519],
            [127.28464, 36.41515],
            [127.2843, 36.41498],
            [127.2834, 36.41495],
            [127.28212, 36.41453],
            [127.2821, 36.415],
            [127.28112, 36.41535],
            [127.27986, 36.4154],
            [127.27828, 36.41458],
            [127.27829, 36.41474],
            [127.27822, 36.41523],
            [127.27789, 36.41572],
            [127.27754, 36.41555],
            [127.27784, 36.41514],
            [127.27794, 36.41489],
            [127.27797, 36.41469],
            [127.27795, 36.41463],
            [127.27788, 36.41451],
            [127.27776, 36.41435],
            [127.27757, 36.41421],
            [127.27726, 36.41401],
            [127.27695, 36.41389],
            [127.27679, 36.41386],
            [127.27632, 36.41388],
            [127.27569, 36.41395],
            [127.27457, 36.41426],
            [127.2743, 36.41435],
            [127.27396, 36.41454],
            [127.27358, 36.41464],
            [127.273, 36.41484],
            [127.27275, 36.41498],
            [127.27197, 36.41553],
            [127.27133, 36.41569],
            [127.27083, 36.41586],
            [127.26952, 36.41626],
            [127.26878, 36.41652],
            [127.2682, 36.41672],
            [127.26732, 36.41696],
            [127.26685, 36.41706],
            [127.26635, 36.41714],
            [127.26571, 36.41719],
            [127.26549, 36.41717],
            [127.26439, 36.41698],
            [127.26335, 36.41684],
            [127.26282, 36.41673],
            [127.26241, 36.41663],
            [127.26178, 36.41635],
            [127.26082, 36.41601],
            [127.25981, 36.41552],
            [127.25958, 36.41534],
            [127.25887, 36.41461],
            [127.25872, 36.4144],
            [127.25865, 36.41424],
            [127.2586, 36.41402],
            [127.25858, 36.41369],
            [127.25865, 36.4133],
            [127.25874, 36.41303],
            [127.25897, 36.41209],
            [127.25924, 36.41138],
            [127.25942, 36.41101],
            [127.25947, 36.41071],
            [127.25956, 36.41035],
            [127.25931, 36.40942],
            [127.25921, 36.40928],
            [127.25912, 36.40911],
            [127.25877, 36.40894],
            [127.25847, 36.40877],
            [127.2582, 36.40842],
            [127.2581, 36.40833],
            [127.25791, 36.40823],
            [127.25732, 36.40803],
            [127.25721, 36.408],
            [127.25619, 36.40799],
            [127.25589, 36.40795],
            [127.25578, 36.40797],
            [127.25555, 36.40809],
            [127.25544, 36.40812],
            [127.25524, 36.40812],
            [127.25505, 36.40805],
            [127.25488, 36.40804],
            [127.25411, 36.40778],
            [127.25389, 36.40775],
            [127.25369, 36.40769],
            [127.25344, 36.40757],
            [127.25334, 36.40737],
            [127.25324, 36.40739],
            [127.25305, 36.40717],
            [127.25294, 36.40711],
            [127.25279, 36.40707],
            [127.25255, 36.40705],
            [127.25239, 36.407],
            [127.25226, 36.40699],
            [127.25226, 36.40708],
            [127.25215, 36.40719],
            [127.25218, 36.40728],
            [127.25221, 36.40734],
            [127.25216, 36.4074],
            [127.25184, 36.40719],
            [127.25166, 36.40712],
            [127.25125, 36.40704],
            [127.25103, 36.40695],
            [127.2508, 36.40676],
            [127.25082, 36.40684],
            [127.25058, 36.40684],
            [127.24995, 36.4074],
            [127.24971, 36.40775],
            [127.24949, 36.40819],
            [127.2495, 36.40824],
            [127.24945, 36.4083],
            [127.24954, 36.40919],
            [127.24938, 36.40952],
            [127.2487, 36.40994],
            [127.24857, 36.41026],
            [127.24805, 36.41044],
            [127.24785, 36.41048],
            [127.24727, 36.41069],
            [127.24725, 36.41095],
            [127.24725, 36.41117],
            [127.24656, 36.41219],
            [127.24618, 36.41255],
            [127.24593, 36.41304],
            [127.24558, 36.41343],
            [127.24479, 36.41411],
            [127.24435, 36.41464],
            [127.2437, 36.41477],
            [127.24329, 36.41506],
            [127.24315, 36.41546],
            [127.24314, 36.41586],
            [127.24315, 36.41651],
            [127.24304, 36.4174],
            [127.24452, 36.41834],
            [127.24403, 36.41858],
            [127.24388, 36.41871],
            [127.24366, 36.41884],
            [127.24309, 36.41882],
            [127.24226, 36.41862],
            [127.24124, 36.41882],
            [127.24086, 36.41851],
            [127.23994, 36.41839],
            [127.23906, 36.41855],
            [127.23786, 36.41857],
            [127.23688, 36.4188],
            [127.23626, 36.41866],
            [127.23563, 36.41881],
            [127.23497, 36.41911],
            [127.2344, 36.4194],
            [127.23336, 36.41951],
            [127.2326, 36.41986],
            [127.23247, 36.4199],
            [127.23193, 36.41991],
            [127.23185, 36.42024],
            [127.23171, 36.42195],
            [127.23121, 36.42217],
            [127.23079, 36.4224],
            [127.23031, 36.42237],
            [127.23018, 36.42243],
            [127.22956, 36.42234],
            [127.22913, 36.42251],
            [127.22878, 36.4228],
            [127.22861, 36.42272],
            [127.22845, 36.42257],
            [127.22829, 36.42227],
            [127.22768, 36.42182],
            [127.22753, 36.42152],
            [127.22693, 36.42122],
            [127.22622, 36.42198],
            [127.2259, 36.42211],
            [127.22564, 36.42229],
            [127.22544, 36.42314],
            [127.2255, 36.42342],
            [127.2253, 36.42399],
            [127.22547, 36.42431],
            [127.22523, 36.42466],
            [127.22523, 36.42499],
            [127.22502, 36.42527],
            [127.22511, 36.42665],
            [127.22471, 36.42695],
            [127.22454, 36.42717],
            [127.22363, 36.42724],
            [127.22321, 36.42748],
            [127.22252, 36.42823],
            [127.22245, 36.42855],
            [127.22177, 36.42942],
            [127.2212, 36.43024],
            [127.22021, 36.43046],
            [127.21984, 36.43069],
            [127.2196, 36.43076],
            [127.21949, 36.43093],
            [127.21942, 36.43138],
            [127.2187, 36.43187],
            [127.21844, 36.43238],
            [127.21811, 36.43285],
            [127.21806, 36.43332],
            [127.21771, 36.43374],
            [127.21659, 36.4338],
            [127.21599, 36.43401],
            [127.21533, 36.43439],
            [127.21463, 36.43444],
            [127.21423, 36.43462],
            [127.21389, 36.43466],
            [127.21345, 36.43461],
            [127.21317, 36.43482],
            [127.21257, 36.43496],
            [127.21268, 36.43561],
            [127.2115, 36.43568],
            [127.2103, 36.43582],
            [127.20901, 36.43593],
            [127.20712, 36.43615],
            [127.20609, 36.43649],
            [127.20505, 36.43689],
            [127.2027, 36.43786],
            [127.20155, 36.43837],
            [127.20049, 36.43886],
            [127.19951, 36.43945],
            [127.19893, 36.43988],
            [127.19987, 36.44085],
            [127.20005, 36.44096],
            [127.20017, 36.44099],
            [127.20083, 36.44107],
            [127.20096, 36.4411],
            [127.20105, 36.44118],
            [127.20111, 36.44125],
            [127.20113, 36.44134],
            [127.20111, 36.44143],
            [127.20102, 36.44157],
            [127.20104, 36.44162],
            [127.20122, 36.44176],
            [127.20133, 36.44189],
            [127.20139, 36.44198],
            [127.2015, 36.44221],
            [127.20139, 36.44211],
            [127.20136, 36.44205],
            [127.20125, 36.44258],
            [127.20138, 36.4427],
            [127.20114, 36.44342],
            [127.20097, 36.44441],
            [127.20092, 36.4449],
            [127.20099, 36.445],
            [127.20153, 36.44548],
            [127.20172, 36.44579],
            [127.20182, 36.44632],
            [127.20142, 36.44692],
            [127.20101, 36.44735],
            [127.20101, 36.44781],
            [127.2014, 36.4485],
            [127.20134, 36.44912],
            [127.20079, 36.44978],
            [127.20069, 36.45005],
            [127.20046, 36.45031],
            [127.19955, 36.45069],
            [127.19852, 36.45175],
            [127.1984, 36.45221],
            [127.2, 36.453],
            [127.20078, 36.45358],
            [127.2012, 36.45415],
            [127.20155, 36.45423],
            [127.20176, 36.4543],
            [127.20308, 36.45427],
            [127.20327, 36.45461],
            [127.20321, 36.45588],
            [127.20325, 36.45749],
            [127.20384, 36.4584],
            [127.20473, 36.45929],
            [127.20431, 36.46031],
            [127.20449, 36.46055],
            [127.20405, 36.4612],
            [127.20419, 36.46201],
            [127.20413, 36.46252],
            [127.20386, 36.46285],
            [127.20357, 36.46298],
            [127.20364, 36.46367],
            [127.20335, 36.46474],
            [127.20328, 36.46498],
            [127.20109, 36.4668],
            [127.19982, 36.46624],
            [127.19945, 36.46583],
            [127.19864, 36.46562],
            [127.19828, 36.4658],
            [127.19791, 36.46597],
            [127.19738, 36.46654],
            [127.19637, 36.46812],
            [127.19572, 36.46846],
            [127.19475, 36.46876],
            [127.19388, 36.47012],
            [127.19403, 36.47075],
            [127.19385, 36.47132],
            [127.19365, 36.47189],
            [127.19348, 36.47257],
            [127.19305, 36.47283],
            [127.19264, 36.47328],
            [127.19202, 36.47467],
            [127.19198, 36.47533],
            [127.19173, 36.47607],
            [127.19191, 36.47634],
            [127.19048, 36.47947],
            [127.19113, 36.4801],
            [127.19129, 36.48022],
            [127.19154, 36.48047],
            [127.19231, 36.48076],
            [127.19294, 36.48111],
            [127.19345, 36.48154],
            [127.19326, 36.4826],
            [127.19352, 36.48296],
            [127.1936, 36.48328],
            [127.19378, 36.48371],
            [127.19391, 36.48397],
            [127.19404, 36.48408],
            [127.19416, 36.48433],
            [127.19482, 36.48439],
            [127.19485, 36.48456],
            [127.19486, 36.48474],
            [127.19479, 36.48545],
            [127.19487, 36.48605],
            [127.19505, 36.48635],
            [127.19494, 36.48694],
            [127.19479, 36.48723],
            [127.19474, 36.48804],
            [127.19485, 36.48859],
            [127.19491, 36.48883],
            [127.19508, 36.48895],
            [127.19483, 36.48888],
            [127.19482, 36.48894],
            [127.19473, 36.48907],
            [127.19475, 36.48925],
            [127.19468, 36.48959],
            [127.19453, 36.48964],
            [127.19416, 36.48957],
            [127.1935, 36.48957],
            [127.19273, 36.49011],
            [127.19265, 36.49019],
            [127.19219, 36.4904],
            [127.19204, 36.49036],
            [127.19182, 36.49037],
            [127.19139, 36.49051],
            [127.1907, 36.49077],
            [127.19011, 36.49097],
            [127.19005, 36.491],
            [127.19005, 36.49135],
            [127.18963, 36.49153],
            [127.18958, 36.49174],
            [127.18926, 36.49201],
            [127.18922, 36.49194],
            [127.18917, 36.49208],
            [127.189, 36.49222],
            [127.18872, 36.49241],
            [127.18831, 36.49262],
            [127.18781, 36.49274],
            [127.18732, 36.49281],
            [127.18671, 36.49277],
            [127.18665, 36.4928],
            [127.18642, 36.49279],
            [127.18619, 36.49283],
            [127.18599, 36.49283],
            [127.18574, 36.493],
            [127.18563, 36.4931],
            [127.18545, 36.49321],
            [127.18501, 36.4932],
            [127.18453, 36.49332],
            [127.184, 36.49335],
            [127.18375, 36.49334],
            [127.18336, 36.49338],
            [127.18316, 36.4933],
            [127.18282, 36.49332],
            [127.18215, 36.49304],
            [127.18158, 36.49311],
            [127.18137, 36.49302],
            [127.18133, 36.49295],
            [127.18096, 36.49259],
            [127.18073, 36.49253],
            [127.18043, 36.49256],
            [127.18004, 36.49208],
            [127.1802, 36.49181],
            [127.17927, 36.49176],
            [127.17906, 36.49188],
            [127.17882, 36.49216],
            [127.1787, 36.49241],
            [127.17837, 36.49234],
            [127.17785, 36.49258],
            [127.17772, 36.49251],
            [127.17738, 36.49251],
            [127.17705, 36.4926],
            [127.17687, 36.49284],
            [127.17686, 36.49267],
            [127.17688, 36.49253],
            [127.17683, 36.49248],
            [127.17674, 36.49269],
            [127.17668, 36.49288],
            [127.1763, 36.49365],
            [127.17632, 36.49385],
            [127.17633, 36.49399],
            [127.17623, 36.49413],
            [127.17569, 36.49437],
            [127.17515, 36.49456],
            [127.17502, 36.4947],
            [127.17488, 36.49479],
            [127.17464, 36.49583],
            [127.17478, 36.49622],
            [127.17463, 36.49667],
            [127.17449, 36.49681],
            [127.17343, 36.49714],
            [127.17324, 36.49744],
            [127.17336, 36.4983],
            [127.1735, 36.49852],
            [127.17342, 36.49867],
            [127.17359, 36.49902],
            [127.17351, 36.49917],
            [127.17347, 36.49921],
            [127.17383, 36.4995],
            [127.17434, 36.49958],
            [127.17492, 36.49988],
            [127.17504, 36.50007],
            [127.17523, 36.50014],
            [127.17533, 36.50038],
            [127.1756, 36.50049],
            [127.1757, 36.50056],
            [127.17578, 36.50045],
            [127.17591, 36.50031],
            [127.176, 36.50029],
            [127.17611, 36.50028],
            [127.17627, 36.50006],
            [127.17649, 36.49988],
            [127.17658, 36.49989],
            [127.17686, 36.49983],
            [127.17719, 36.49993],
            [127.17744, 36.50024],
            [127.1775, 36.50037],
            [127.17755, 36.50097],
            [127.17762, 36.50111],
            [127.17769, 36.50123],
            [127.17797, 36.50148],
            [127.17849, 36.50211],
            [127.17858, 36.50253],
            [127.17856, 36.50258],
            [127.17823, 36.50248],
            [127.17765, 36.50242],
            [127.17751, 36.50243],
            [127.17731, 36.50266],
            [127.17702, 36.50283],
            [127.17697, 36.5029],
            [127.17683, 36.50301],
            [127.17692, 36.50302],
            [127.17663, 36.50304],
            [127.17626, 36.50311],
            [127.17619, 36.50313],
            [127.17585, 36.50344],
            [127.17662, 36.50452],
            [127.17643, 36.50486],
            [127.17626, 36.50534],
            [127.17597, 36.50541],
            [127.17516, 36.50509],
            [127.17459, 36.50544],
            [127.17426, 36.50593],
            [127.17446, 36.50634],
            [127.17419, 36.50644],
            [127.17394, 36.50673],
            [127.17375, 36.50695],
            [127.17359, 36.50736],
            [127.17301, 36.50769],
            [127.17261, 36.50794],
            [127.17177, 36.50799],
            [127.17052, 36.50854],
            [127.17028, 36.50872],
            [127.16999, 36.50904],
            [127.17083, 36.50944],
            [127.17067, 36.50974],
            [127.17117, 36.51107],
            [127.17016, 36.51155],
            [127.17025, 36.51173],
            [127.17076, 36.51187],
            [127.17099, 36.51207],
            [127.17156, 36.51274],
            [127.17282, 36.51343],
            [127.17319, 36.51362],
            [127.17353, 36.51491],
            [127.17399, 36.51551],
            [127.17464, 36.51565],
            [127.17571, 36.51661],
            [127.17675, 36.51663],
            [127.17725, 36.51749],
            [127.17796, 36.51788],
            [127.1784, 36.51805],
            [127.17858, 36.51829],
            [127.17912, 36.51856],
            [127.17964, 36.51888],
            [127.1799, 36.51922],
            [127.18007, 36.51985],
            [127.18046, 36.52024],
            [127.18207, 36.52077],
            [127.18221, 36.52114],
            [127.18235, 36.52144],
            [127.18173, 36.52224],
            [127.18136, 36.52244],
            [127.18112, 36.52271],
            [127.18006, 36.52354],
            [127.17958, 36.52399],
            [127.17983, 36.52437],
            [127.17963, 36.52487],
            [127.17926, 36.52525],
            [127.17881, 36.52534],
            [127.1787, 36.5255],
            [127.17875, 36.52595],
            [127.17826, 36.52652],
            [127.17776, 36.52699],
            [127.17784, 36.52718],
            [127.17782, 36.52727],
            [127.17753, 36.52789],
            [127.17673, 36.52801],
            [127.17646, 36.52818],
            [127.17627, 36.52838],
            [127.17613, 36.52873],
            [127.17597, 36.52877],
            [127.17573, 36.52888],
            [127.17579, 36.52928],
            [127.17522, 36.52999],
            [127.17527, 36.53075],
            [127.17541, 36.53158],
            [127.1752, 36.53176],
            [127.17493, 36.53214],
            [127.17481, 36.53264],
            [127.17492, 36.53298],
            [127.1746, 36.5331],
            [127.17367, 36.53326],
            [127.17302, 36.53346],
            [127.17305, 36.53409],
            [127.17329, 36.53447],
            [127.17326, 36.53492],
            [127.17256, 36.53574],
            [127.17267, 36.53614],
            [127.17297, 36.53639],
            [127.17332, 36.53698],
            [127.17366, 36.53739],
            [127.17392, 36.53763],
            [127.17396, 36.5379],
            [127.17434, 36.53825],
            [127.1749, 36.53848],
            [127.17424, 36.53922],
            [127.17433, 36.53955],
            [127.17416, 36.53987],
            [127.17425, 36.54025],
            [127.17418, 36.54058],
            [127.1738, 36.54075],
            [127.17422, 36.5419],
            [127.17424, 36.54219],
            [127.17497, 36.54277],
            [127.1761, 36.54346],
            [127.17717, 36.54409],
            [127.17785, 36.54461],
            [127.17796, 36.54479],
            [127.17867, 36.54536],
            [127.17878, 36.54552],
            [127.17896, 36.54556],
            [127.17916, 36.54555],
            [127.17926, 36.54547],
            [127.17962, 36.54546],
            [127.1801, 36.54554],
            [127.18048, 36.5454],
            [127.1808, 36.54559],
            [127.18117, 36.5456],
            [127.18131, 36.54551],
            [127.18133, 36.54537],
            [127.18213, 36.54498],
            [127.18258, 36.5446],
            [127.18279, 36.54411],
            [127.18381, 36.54375],
            [127.18406, 36.54387],
            [127.18504, 36.54366],
            [127.18524, 36.54387],
            [127.18581, 36.54416],
            [127.18615, 36.5446],
            [127.18636, 36.5451],
            [127.18665, 36.54529],
            [127.18675, 36.54547],
            [127.18685, 36.54548],
            [127.18693, 36.54558],
            [127.18696, 36.54573],
            [127.18701, 36.5458],
            [127.19183, 36.54603],
            [127.19171, 36.54655],
            [127.1916, 36.5468],
            [127.19177, 36.54682],
            [127.19168, 36.547],
            [127.19146, 36.54727],
            [127.19066, 36.54784],
            [127.19037, 36.54795],
            [127.18987, 36.54818],
            [127.18948, 36.54834],
            [127.18927, 36.54848],
            [127.18919, 36.54858],
            [127.18882, 36.54923],
            [127.18822, 36.55],
            [127.18798, 36.55023],
            [127.18775, 36.55052],
            [127.1873, 36.55102],
            [127.18678, 36.55155],
            [127.18663, 36.55176],
            [127.18641, 36.55197],
            [127.18612, 36.55232],
            [127.1862, 36.55241],
            [127.18624, 36.55255],
            [127.18672, 36.55302],
            [127.18676, 36.55294],
            [127.18735, 36.55302],
            [127.18793, 36.55306],
            [127.18834, 36.55327],
            [127.18862, 36.55367],
            [127.18879, 36.5538],
            [127.18902, 36.55431],
            [127.18913, 36.55437],
            [127.1892, 36.55447],
            [127.18927, 36.55472],
            [127.18898, 36.55531],
            [127.18902, 36.55557],
            [127.18906, 36.55569],
            [127.18955, 36.55626],
            [127.18951, 36.55641],
            [127.18947, 36.55696],
            [127.18934, 36.55716],
            [127.18944, 36.55734],
            [127.1897, 36.55741],
            [127.18985, 36.5574],
            [127.19033, 36.55788],
            [127.19041, 36.558],
            [127.19057, 36.55815],
            [127.19061, 36.55823],
            [127.19024, 36.55856],
            [127.19024, 36.55869],
            [127.19045, 36.55892],
            [127.19044, 36.55906],
            [127.19053, 36.55918],
            [127.19053, 36.55953],
            [127.1907, 36.55991],
            [127.1909, 36.5601],
            [127.19131, 36.56023],
            [127.19144, 36.56042],
            [127.19179, 36.56074],
            [127.19181, 36.56091],
            [127.19175, 36.56109],
            [127.19182, 36.56137],
            [127.19176, 36.56155],
            [127.19214, 36.5616],
            [127.19252, 36.56158],
            [127.19295, 36.56182],
            [127.19319, 36.5622],
            [127.19294, 36.56241],
            [127.19263, 36.56283],
            [127.19276, 36.56321],
            [127.19275, 36.56346],
            [127.19268, 36.56348],
            [127.1929, 36.56368],
            [127.19337, 36.56442],
            [127.19379, 36.56481],
            [127.19428, 36.56475],
            [127.1949, 36.56498],
            [127.19531, 36.56495],
            [127.19545, 36.565],
            [127.19578, 36.565],
            [127.19606, 36.56493],
            [127.19628, 36.56492],
            [127.19678, 36.56464],
            [127.19702, 36.56464],
            [127.19725, 36.56467],
            [127.19749, 36.56455],
            [127.19785, 36.5648],
            [127.19818, 36.56477],
            [127.19865, 36.56494],
            [127.1992, 36.56494],
            [127.19939, 36.56502],
            [127.19945, 36.56524],
            [127.19969, 36.5654],
            [127.19985, 36.56564],
            [127.19986, 36.56575],
            [127.20024, 36.56603],
            [127.2007, 36.566],
            [127.20107, 36.56633],
            [127.20165, 36.56663],
            [127.20216, 36.56683],
            [127.20259, 36.56727],
            [127.2031, 36.56744],
            [127.20316, 36.56821],
            [127.20304, 36.56872],
            [127.20328, 36.5692],
            [127.20336, 36.56963],
            [127.2035, 36.56992],
            [127.20336, 36.57073],
            [127.20363, 36.57167],
            [127.20374, 36.57267],
            [127.20389, 36.57266],
            [127.20413, 36.57284],
            [127.20438, 36.57342],
            [127.20502, 36.57379],
            [127.20529, 36.57419],
            [127.20533, 36.57423],
            [127.20587, 36.57462],
            [127.2064, 36.5748],
            [127.20668, 36.57516],
            [127.20686, 36.5753],
            [127.20704, 36.57585],
            [127.2074, 36.57627],
            [127.20748, 36.57681],
            [127.20757, 36.57714],
            [127.20754, 36.57765],
            [127.20776, 36.57797],
            [127.20747, 36.57831],
            [127.20756, 36.5785],
            [127.20733, 36.57875],
            [127.20714, 36.57934],
            [127.20651, 36.57978],
            [127.20638, 36.58003],
            [127.20618, 36.58018],
            [127.20614, 36.58032],
            [127.20584, 36.58051],
            [127.20579, 36.58062],
            [127.20567, 36.58077],
            [127.20538, 36.58081],
            [127.20486, 36.5811],
            [127.2044, 36.58116],
            [127.20365, 36.58161],
            [127.2031, 36.58156],
            [127.20249, 36.58165],
            [127.20208, 36.58205],
            [127.2017, 36.58205],
            [127.20149, 36.58226],
            [127.20124, 36.58219],
            [127.20109, 36.58225],
            [127.20106, 36.58233],
            [127.20083, 36.58232],
            [127.20062, 36.5825],
            [127.2004, 36.58257],
            [127.19976, 36.5826],
            [127.19931, 36.58254],
            [127.19919, 36.58223],
            [127.19921, 36.58209],
            [127.19895, 36.582],
            [127.19867, 36.5818],
            [127.19831, 36.58172],
            [127.19795, 36.58155],
            [127.1977, 36.58157],
            [127.19752, 36.58155],
            [127.19749, 36.58138],
            [127.19723, 36.5813],
            [127.19701, 36.58109],
            [127.1966, 36.581],
            [127.19624, 36.58119],
            [127.19579, 36.58102],
            [127.19562, 36.5809],
            [127.19536, 36.58085],
            [127.19507, 36.58088],
            [127.19475, 36.58074],
            [127.19447, 36.58066],
            [127.19404, 36.58065],
            [127.19296, 36.58082],
            [127.1931, 36.58144],
            [127.19239, 36.58195],
            [127.19201, 36.58254],
            [127.19153, 36.58285],
            [127.19107, 36.5831],
            [127.19089, 36.58353],
            [127.19102, 36.58407],
            [127.19119, 36.5845],
            [127.19074, 36.58506],
            [127.19003, 36.58567],
            [127.18988, 36.58578],
            [127.18969, 36.58616],
            [127.18946, 36.58638],
            [127.18926, 36.58684],
            [127.1895, 36.58736],
            [127.18953, 36.58786],
            [127.18959, 36.58802],
            [127.18957, 36.58839],
            [127.18979, 36.58875],
            [127.18992, 36.58889],
            [127.19012, 36.5892],
            [127.19002, 36.58949],
            [127.19005, 36.5899],
            [127.19037, 36.59022],
            [127.19037, 36.59057],
            [127.19068, 36.59095],
            [127.19072, 36.59138],
            [127.19051, 36.59165],
            [127.191, 36.59262],
            [127.19134, 36.59353],
            [127.19135, 36.59394],
            [127.191, 36.59393],
            [127.19026, 36.59449],
            [127.19001, 36.59462],
            [127.18957, 36.5946],
            [127.18923, 36.59456],
            [127.18901, 36.59442],
            [127.18797, 36.59408],
            [127.18705, 36.59349],
            [127.18709, 36.59293],
            [127.18727, 36.59284],
            [127.1874, 36.59257],
            [127.18734, 36.59232],
            [127.18645, 36.59196],
            [127.18575, 36.59181],
            [127.1853, 36.59172],
            [127.18507, 36.59153],
            [127.18467, 36.59145],
            [127.18465, 36.59126],
            [127.18446, 36.59116],
            [127.18432, 36.5912],
            [127.18392, 36.59146],
            [127.18364, 36.59186],
            [127.1829, 36.59247],
            [127.18194, 36.59301],
            [127.18209, 36.59329],
            [127.18195, 36.59515],
            [127.18168, 36.59551],
            [127.1808, 36.59571],
            [127.18063, 36.59594],
            [127.17956, 36.59597],
            [127.17862, 36.59671],
            [127.17839, 36.59689],
            [127.17808, 36.59689],
            [127.17717, 36.59645],
            [127.17642, 36.5961],
            [127.17576, 36.59631],
            [127.17508, 36.59608],
            [127.17489, 36.59626],
            [127.17402, 36.59592],
            [127.17349, 36.59603],
            [127.17307, 36.59557],
            [127.17272, 36.59545],
            [127.17268, 36.59552],
            [127.17264, 36.59605],
            [127.17259, 36.59671],
            [127.17258, 36.5968],
            [127.17254, 36.59689],
            [127.17248, 36.59694],
            [127.17227, 36.59708],
            [127.17197, 36.59725],
            [127.17191, 36.59732],
            [127.17183, 36.59737],
            [127.17174, 36.59738],
            [127.17166, 36.59744],
            [127.17141, 36.59751],
            [127.1714, 36.59763],
            [127.17103, 36.59772],
            [127.1711, 36.59799],
            [127.17109, 36.59814],
            [127.17096, 36.59831],
            [127.17095, 36.59873],
            [127.17073, 36.59917],
            [127.16984, 36.59908],
            [127.16983, 36.59943],
            [127.16953, 36.59937],
            [127.16861, 36.60019],
            [127.16867, 36.60034],
            [127.16863, 36.60059],
            [127.16783, 36.60113],
            [127.1681, 36.60186],
            [127.16757, 36.6022],
            [127.16725, 36.60267],
            [127.16713, 36.60287],
            [127.16715, 36.60292],
            [127.16707, 36.6029],
            [127.16674, 36.60263],
            [127.16677, 36.60278],
            [127.16617, 36.60258],
            [127.16608, 36.60255],
            [127.166, 36.60245],
            [127.16586, 36.60243],
            [127.16565, 36.60243],
            [127.16549, 36.60261],
            [127.16505, 36.60279],
            [127.16484, 36.60307],
            [127.16438, 36.60344],
            [127.16372, 36.60363],
            [127.16352, 36.6036],
            [127.16333, 36.60363],
            [127.16324, 36.60377],
            [127.1629, 36.60409],
            [127.16285, 36.60426],
            [127.16272, 36.60438],
            [127.16246, 36.60443],
            [127.16206, 36.60461],
            [127.16189, 36.60467],
            [127.16172, 36.60462],
            [127.16149, 36.60467],
            [127.16134, 36.60476],
            [127.16087, 36.6047],
            [127.1605, 36.60472],
            [127.16033, 36.60488],
            [127.1602, 36.60507],
            [127.15966, 36.60517],
            [127.15947, 36.60523],
            [127.15905, 36.60526],
            [127.1586, 36.60536],
            [127.15832, 36.60563],
            [127.15827, 36.60586],
            [127.1579, 36.60633],
            [127.15758, 36.60638],
            [127.15709, 36.60623],
            [127.15663, 36.60613],
            [127.15616, 36.60615],
            [127.15588, 36.60601],
            [127.15548, 36.6067],
            [127.15562, 36.60788],
            [127.15549, 36.6086],
            [127.15583, 36.60914],
            [127.15549, 36.60973],
            [127.1556, 36.61001],
            [127.15547, 36.61051],
            [127.15557, 36.61068],
            [127.15519, 36.61147],
            [127.15498, 36.61208],
            [127.15526, 36.61239],
            [127.15505, 36.61291],
            [127.15509, 36.61306],
            [127.15559, 36.61405],
            [127.15578, 36.61433],
            [127.15599, 36.61515],
            [127.15562, 36.61599],
            [127.15494, 36.61612],
            [127.15495, 36.61654],
            [127.15426, 36.61683],
            [127.15308, 36.61754],
            [127.15227, 36.61787],
            [127.15155, 36.61795],
            [127.15062, 36.61833],
            [127.15005, 36.61901],
            [127.15045, 36.6197],
            [127.1509, 36.62015],
            [127.1513, 36.62085],
            [127.15162, 36.62108],
            [127.15171, 36.62121],
            [127.15188, 36.62136],
            [127.15222, 36.62179],
            [127.15283, 36.62189],
            [127.15307, 36.62187],
            [127.15347, 36.62253],
            [127.15344, 36.62277],
            [127.15395, 36.62338],
            [127.15432, 36.62389],
            [127.15499, 36.62421],
            [127.15548, 36.6242],
            [127.15583, 36.62404],
            [127.15658, 36.62392],
            [127.15741, 36.62342],
            [127.15772, 36.62356],
            [127.15794, 36.62401],
            [127.1592, 36.62394],
            [127.16011, 36.62407],
            [127.1605, 36.62445],
            [127.16049, 36.62471],
            [127.16043, 36.62478],
            [127.16018, 36.62543],
            [127.16037, 36.62628],
            [127.16005, 36.62637],
            [127.15949, 36.62603],
            [127.15764, 36.62679],
            [127.15705, 36.62723],
            [127.15708, 36.62746],
            [127.15691, 36.62764],
            [127.15718, 36.62781],
            [127.15674, 36.62828],
            [127.15649, 36.62862],
            [127.1566, 36.62877],
            [127.15667, 36.62908],
            [127.15652, 36.62939],
            [127.15617, 36.63],
            [127.15628, 36.63007],
            [127.15619, 36.63029],
            [127.15612, 36.6307],
            [127.15629, 36.6317],
            [127.15661, 36.6319],
            [127.15685, 36.63182],
            [127.15754, 36.63227],
            [127.1571, 36.63264],
            [127.15693, 36.63309],
            [127.15752, 36.63373],
            [127.15754, 36.63388],
            [127.15755, 36.63472],
            [127.15808, 36.63536],
            [127.15801, 36.63581],
            [127.15755, 36.63595],
            [127.15737, 36.63617],
            [127.15728, 36.63642],
            [127.15701, 36.63661],
            [127.15673, 36.63731],
            [127.15658, 36.63753],
            [127.15643, 36.63785],
            [127.15694, 36.63824],
            [127.15749, 36.63904],
            [127.15756, 36.63917],
            [127.1575, 36.63954],
            [127.15717, 36.63968],
            [127.15653, 36.63978],
            [127.15636, 36.63992],
            [127.15588, 36.64071],
            [127.15634, 36.64137],
            [127.1555, 36.64197],
            [127.15473, 36.64225],
            [127.15414, 36.64268],
            [127.15382, 36.64305],
            [127.15344, 36.64361],
            [127.15342, 36.64366],
            [127.15336, 36.64372],
            [127.15354, 36.64441],
            [127.15356, 36.64455],
            [127.15351, 36.64486],
            [127.15314, 36.64521],
            [127.15349, 36.64585],
            [127.15408, 36.64663],
            [127.15415, 36.64669],
            [127.15374, 36.64796],
            [127.15405, 36.6483],
            [127.15397, 36.64909],
            [127.15412, 36.64933],
            [127.15399, 36.64966],
            [127.15444, 36.65054],
            [127.15462, 36.6507],
            [127.1545, 36.65093],
            [127.15445, 36.65111],
            [127.15429, 36.65245],
            [127.15457, 36.65282],
            [127.1547, 36.65286],
            [127.15566, 36.65319],
            [127.15636, 36.6536],
            [127.15737, 36.65434],
            [127.1583, 36.65432],
            [127.15833, 36.6549],
            [127.15981, 36.65593],
            [127.16057, 36.65612],
            [127.16045, 36.65696],
            [127.16048, 36.65733],
            [127.15993, 36.6575],
            [127.15968, 36.6585],
            [127.16, 36.65869],
            [127.15931, 36.65905],
            [127.15918, 36.65931],
            [127.15941, 36.65972],
            [127.15934, 36.66031],
            [127.15894, 36.66094],
            [127.15904, 36.66135],
            [127.15839, 36.66176],
            [127.15795, 36.6617],
            [127.15768, 36.66202],
            [127.15753, 36.66201],
            [127.15725, 36.66235],
            [127.15645, 36.66253],
            [127.15637, 36.66269],
            [127.15616, 36.66276],
            [127.15627, 36.66333],
            [127.15566, 36.66379],
            [127.15535, 36.66386],
            [127.15486, 36.66427],
            [127.15452, 36.66429],
            [127.15436, 36.66442],
            [127.15524, 36.66522],
            [127.15527, 36.66527],
            [127.15563, 36.66747],
            [127.15582, 36.66752],
            [127.15638, 36.6682],
            [127.15622, 36.66855],
            [127.1564, 36.66879],
            [127.15612, 36.66904],
            [127.15663, 36.66934],
            [127.15687, 36.66941],
            [127.15719, 36.66977],
            [127.15756, 36.66979],
            [127.15757, 36.67031],
            [127.15776, 36.67068],
            [127.158, 36.671],
            [127.15872, 36.67133],
            [127.15865, 36.67177],
            [127.15861, 36.67225],
            [127.1587, 36.67258],
            [127.15855, 36.67302],
            [127.15987, 36.67389],
            [127.16051, 36.67407],
            [127.16092, 36.67472],
            [127.1618, 36.67543],
            [127.16221, 36.67559],
            [127.16257, 36.67599],
            [127.16345, 36.67623],
            [127.16359, 36.67704],
            [127.1638, 36.67738],
            [127.16391, 36.67785],
            [127.16412, 36.6785],
            [127.16383, 36.67889],
            [127.16393, 36.67951],
            [127.16349, 36.68009],
            [127.16343, 36.68034],
            [127.16357, 36.68113],
            [127.16366, 36.6812],
            [127.16324, 36.68209],
            [127.16315, 36.68223],
            [127.16295, 36.68306],
            [127.16243, 36.68365],
            [127.16233, 36.68418],
            [127.16227, 36.68487],
            [127.16189, 36.68571],
            [127.16197, 36.68597],
            [127.16142, 36.6862],
            [127.1612, 36.68698],
            [127.16077, 36.68769],
            [127.16087, 36.68814],
            [127.16065, 36.68816],
            [127.16053, 36.68818],
            [127.1603, 36.68816],
            [127.15987, 36.68816],
            [127.15994, 36.68841],
            [127.1611, 36.68958],
            [127.16144, 36.69022],
            [127.16115, 36.69118],
            [127.1608, 36.69175],
            [127.16066, 36.69229],
            [127.16035, 36.69273],
            [127.15975, 36.69319],
            [127.15909, 36.69384],
            [127.15869, 36.69436],
            [127.15861, 36.69468],
            [127.15832, 36.69508],
            [127.15805, 36.69532],
            [127.15711, 36.69566],
            [127.15703, 36.69531],
            [127.15709, 36.69508],
            [127.15712, 36.6948],
            [127.15708, 36.69467],
            [127.15703, 36.69444],
            [127.15703, 36.69429],
            [127.15693, 36.69409],
            [127.1569, 36.69397],
            [127.15675, 36.69375],
            [127.15667, 36.69361],
            [127.15665, 36.69354],
            [127.15666, 36.69339],
            [127.15682, 36.69292],
            [127.15682, 36.69286],
            [127.15673, 36.69258],
            [127.15672, 36.6924],
            [127.15663, 36.69218],
            [127.15658, 36.69196],
            [127.15656, 36.69163],
            [127.15663, 36.69114],
            [127.15658, 36.69103],
            [127.15642, 36.69093],
            [127.15622, 36.69076],
            [127.15596, 36.69076],
            [127.15591, 36.69072],
            [127.15575, 36.69074],
            [127.15565, 36.69073],
            [127.15517, 36.69073],
            [127.15445, 36.69069],
            [127.15334, 36.69098],
            [127.1525, 36.69028],
            [127.15182, 36.69002],
            [127.15089, 36.68945],
            [127.1506, 36.68984],
            [127.14959, 36.68985],
            [127.14917, 36.69027],
            [127.14914, 36.69067],
            [127.14903, 36.69094],
            [127.14872, 36.69077],
            [127.14834, 36.69059],
            [127.14751, 36.69072],
            [127.14659, 36.6901],
            [127.14621, 36.69002],
            [127.14548, 36.6893],
            [127.14483, 36.68924],
            [127.14452, 36.68936],
            [127.14383, 36.689],
            [127.14307, 36.68955],
            [127.14288, 36.68975],
            [127.14175, 36.68998],
            [127.14155, 36.69075],
            [127.14193, 36.69097],
            [127.14219, 36.69133],
            [127.14249, 36.69143],
            [127.14287, 36.69166],
            [127.14291, 36.69178],
            [127.14246, 36.69224],
            [127.14253, 36.6926],
            [127.14235, 36.69289],
            [127.14239, 36.69318],
            [127.1423, 36.69322],
            [127.14222, 36.69349],
            [127.14177, 36.69423],
            [127.14113, 36.6943],
            [127.1406, 36.69442],
            [127.14048, 36.69458],
            [127.1409, 36.69689],
            [127.14069, 36.69732],
            [127.14037, 36.69743],
            [127.1393, 36.69824],
            [127.13883, 36.69825],
            [127.13857, 36.69883],
            [127.1382, 36.69974],
            [127.13834, 36.69991],
            [127.13797, 36.70136],
            [127.13829, 36.70166],
            [127.13712, 36.70217],
            [127.13686, 36.7024],
            [127.13638, 36.70269],
            [127.13603, 36.70294],
            [127.13593, 36.70339],
            [127.13629, 36.70434],
            [127.13614, 36.70504],
            [127.13585, 36.7052],
            [127.13537, 36.70593],
            [127.1351, 36.70602],
            [127.13481, 36.7062],
            [127.13459, 36.70666],
            [127.13438, 36.70679],
            [127.13421, 36.70682],
            [127.13401, 36.70676],
            [127.13378, 36.70679],
            [127.1336, 36.70669],
            [127.13331, 36.70636],
            [127.13297, 36.70633],
            [127.1325, 36.70642],
            [127.13203, 36.70616],
            [127.13187, 36.70621],
            [127.13138, 36.70648],
            [127.13097, 36.70657],
            [127.13063, 36.70657],
            [127.13008, 36.70683],
            [127.13, 36.70676],
            [127.12962, 36.70745],
            [127.1292, 36.7075],
            [127.12903, 36.70764],
            [127.12799, 36.70797],
            [127.12781, 36.70823],
            [127.12773, 36.70852],
            [127.12777, 36.70883],
            [127.12888, 36.7096],
            [127.12919, 36.71],
            [127.12949, 36.7101],
            [127.12956, 36.71022],
            [127.13046, 36.71012],
            [127.13087, 36.71014],
            [127.13112, 36.71023],
            [127.13165, 36.71027],
            [127.13172, 36.71039],
            [127.13211, 36.71069],
            [127.13227, 36.71086],
            [127.13255, 36.71109],
            [127.13251, 36.71127],
            [127.13314, 36.71195],
            [127.13327, 36.71236],
            [127.13425, 36.71275],
            [127.13444, 36.71328],
            [127.1354, 36.71304],
            [127.13567, 36.71302],
            [127.13623, 36.71327],
            [127.13648, 36.71347],
            [127.1366, 36.71351],
            [127.1367, 36.71378],
            [127.13677, 36.7139],
            [127.13692, 36.71395],
            [127.13715, 36.71406],
            [127.13763, 36.71425],
            [127.13802, 36.71448],
            [127.13813, 36.71462],
            [127.13882, 36.71493],
            [127.13915, 36.71524],
            [127.13935, 36.71551],
            [127.13951, 36.71579],
            [127.14026, 36.71581],
            [127.14054, 36.716],
            [127.14053, 36.71633],
            [127.14044, 36.7165],
            [127.14093, 36.71685],
            [127.14184, 36.71681],
            [127.14184, 36.71692],
            [127.14171, 36.71701],
            [127.1427, 36.7172],
            [127.14307, 36.71711],
            [127.14373, 36.71744],
            [127.14394, 36.71741],
            [127.14412, 36.71731],
            [127.14429, 36.71726],
            [127.14511, 36.71752],
            [127.14523, 36.71782],
            [127.14566, 36.71813],
            [127.14574, 36.71874],
            [127.14592, 36.71903],
            [127.14655, 36.71904],
            [127.14708, 36.71933],
            [127.14724, 36.7193],
            [127.14764, 36.71981],
            [127.1479, 36.71976],
            [127.14825, 36.71989],
            [127.14883, 36.72056],
            [127.14882, 36.72102],
            [127.14875, 36.72124],
            [127.14855, 36.72143],
            [127.14809, 36.72156],
            [127.14762, 36.72184],
            [127.14768, 36.72201],
            [127.14762, 36.7223],
            [127.14778, 36.7225],
            [127.1478, 36.72264],
            [127.14819, 36.72271],
            [127.14873, 36.72356],
            [127.14894, 36.72396],
            [127.14929, 36.72443],
            [127.14926, 36.72458],
            [127.14944, 36.7248],
            [127.14948, 36.72532],
            [127.14934, 36.72547],
            [127.14929, 36.72593],
            [127.14965, 36.72599],
            [127.14973, 36.72593],
            [127.14971, 36.72588],
            [127.14981, 36.72589],
            [127.15005, 36.72607],
            [127.15023, 36.72614],
            [127.15093, 36.72626],
            [127.15142, 36.72642],
            [127.15195, 36.72668],
            [127.15205, 36.72681],
            [127.15201, 36.72844],
            [127.15195, 36.72848],
            [127.15241, 36.72846],
            [127.15302, 36.72832],
            [127.15333, 36.72831],
            [127.15368, 36.72837],
            [127.15415, 36.72814],
            [127.15446, 36.72789],
            [127.15478, 36.7277],
            [127.15526, 36.72755],
            [127.15557, 36.7274],
            [127.15592, 36.72731],
            [127.15603, 36.72731],
            [127.15636, 36.72722],
            [127.15647, 36.72722],
            [127.15661, 36.72727],
            [127.15686, 36.72745],
            [127.15753, 36.72746],
            [127.15801, 36.72865],
            [127.15784, 36.72891],
            [127.15765, 36.72972],
            [127.1583, 36.73016],
            [127.15835, 36.73034],
            [127.15835, 36.73059],
            [127.15862, 36.73094],
            [127.15945, 36.7315],
            [127.15964, 36.73284],
            [127.15989, 36.73289],
            [127.16061, 36.73275],
            [127.16128, 36.73311],
            [127.16135, 36.73313],
            [127.16265, 36.7332],
            [127.16284, 36.73313],
            [127.16322, 36.73336],
            [127.16396, 36.73363],
            [127.16469, 36.73382],
            [127.16556, 36.73355],
            [127.16609, 36.7335]
          ]
        ]
      }
    }
  ]
};
