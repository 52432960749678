import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Nav, Tabs, Tab } from 'react-bootstrap';
import ChatThread from './ChatThread';
import SimpleBarReact from 'simplebar-react';
import classNames from 'classnames';
import { ChatContext } from 'context/Context';

import { chainAxios } from 'helpers/chainAxios';
import ChatDetail from '../content';
import { useChatrooms } from '../hook/useChat';
import { isEmpty } from 'helpers/utils';

const ChatSidebar = () => {
  const { data: chatRooms, setSelectedStatus } = useChatrooms();

  return (
    <div
      className={classNames('chat-sidebar', { 'start-0': false })}
      style={{ maxWidth: '100%' }}
    >
      <Tabs
        defaultActiveKey="0"
        id="fill-tab-example"
        className={classNames('mb-2', 'nav')}
        style={{ flexDirection: 'Row' }}
        onSelect={value => {
          setSelectedStatus(value);
        }}
        fill
      >
        <Tab eventKey="0" title={<h6>점검요함</h6>}></Tab>
        <Tab eventKey="1" title={<h6>조치완료</h6>}></Tab>
        <Tab eventKey="2" title={<h6>재점검필</h6>}></Tab>
        <Tab eventKey="3" title={<h6>승인</h6>}></Tab>
      </Tabs>
      <div className="contacts-list" style={{ border: 'none' }}>
        <SimpleBarReact style={{ height: '100%', minWidth: '65px' }}>
          <Nav className="border-200 border-bottom pb-4" activeKey="">
            {!isEmpty(chatRooms) &&
              chatRooms.map((chatRoom, index) => (
                <ChatThread
                  chatRoom={chatRoom}
                  index={index}
                  key={chatRoom.id}
                />
              ))}
          </Nav>
        </SimpleBarReact>
      </div>
      {/* <ChatContactsSearch /> */}
    </div>
  );
};

ChatSidebar.propTypes = {
  hideSidebar: PropTypes.bool
};

export default ChatSidebar;
