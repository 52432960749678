import { Button, Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { getColor, isEmpty } from 'helpers/utils';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import React, { useEffect, useState } from 'react';
import { fromLonLat } from 'ol/proj';
import ResponsivePagination from 'react-responsive-pagination';
import SoftBadge from '../../../../common/SoftBadge';
import { useNavigate } from 'react-router-dom';

const ProjectFacilitiesList = ({ project }) => {
  const facilitiesColumns = [
    // {
    //   accessor: 'no',
    //   Header: 'No',
    //   headerProps: { className: 'text-900' },
    //   Cell: rowData => {
    //     // console.log(rowData.row.original);
    //     const { index } = rowData.row;
    //     return (
    //       <Flex alignItems="center">
    //         <div
    //           className="flex-1 fs--1 text-truncate text-center text-800"
    //           style={{ width: '30px' }}
    //         >
    //           {totalElements - index}
    //         </div>
    //       </Flex>
    //     );
    //   }
    // },
    {
      accessor: 'name',
      Header: '도로·시설물',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { name, id } = rowData.row.original;
        return (
          <Button
            variant="link"
            className={'fs--1'}
            onClick={() => {
              // console.log(rowData.row.original);
              // setFacilitiesDetail(rowData.row.original);
              goToRoadFacilityDetail(id);
            }}
          >
            {name}
          </Button>
        );
      }
    },
    {
      accessor: '7',
      Header: '침하센서',
      headerProps: { className: 'text-900' }
    },
    {
      accessor: 'type',
      Header: '종류',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { id, type } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'flex-1 fs--1 text-truncate text-center text-800'}>
              {type === 'RD' ? '도로' : type === 'FC' ? '시설물' : '공사현장'}(
              {id})
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'status',
      Header: '예측 안전등급',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { status } = rowData.row.original;

        let badgeColor;
        let badgeName;
        if (status === '0') {
          badgeName = 'E등급';
          badgeColor = 'danger';
        } else if (status === '1') {
          badgeName = 'D등급';
          badgeColor = 'warning';
        } else if (status === '2') {
          badgeName = 'C등급';
          badgeColor = 'secondary';
        } else if (status === '3') {
          badgeName = 'B등급';
          badgeColor = 'info';
        } else if (status === '4') {
          badgeName = 'A등급';
          badgeColor = 'primary';
        } else {
          badgeName = '등급확인필요';
          badgeColor = 'dark';
        }

        return (
          <SoftBadge pill bg={badgeColor} className="me-2 fs--2 text-800">
            {badgeName}
          </SoftBadge>
        );
      }
    }
  ];

  const navigate = useNavigate();

  const [projectFacilities, setProjectFacilities] = useState([]);
  const [displayFacilities, setDisplayFacilities] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const _pageSize = 5;

  useEffect(() => {
    getFacilities();
  }, [project]);

  useEffect(() => {
    if (!isEmpty(projectFacilities)) {
      makePagingData(projectFacilities);
    }
  }, [currentPage]);

  const getFacilities = () => {
    if (project !== undefined) {
      if (project.facilities !== undefined && project.facilities !== null) {
        // console.log(project.facilities.length);
        let totalElements = project.facilities.length;
        setTotalPages(Math.ceil(totalElements / _pageSize));
        setTotalElements(totalElements);
        setProjectFacilities(project.facilities);
        makePagingData(project.facilities);
      } else {
        setTotalPages(1);
        setTotalElements(0);
        setProjectFacilities([]);
        setDisplayFacilities([]);
      }
    }
  };

  const makePagingData = facilities => {
    if (facilities === undefined) {
      return;
    }

    // console.log(facilities);
    // console.log(currentPage);
    let startIndex = (currentPage - 1) * _pageSize;
    let endIndex = currentPage * _pageSize;

    if (endIndex >= facilities.length) {
      endIndex = facilities.length;
    }

    let displayValues = [];

    for (startIndex; startIndex < endIndex; startIndex += 1) {
      // console.log(startIndex);
      // console.log(facilities[startIndex]);
      displayValues.push(facilities[startIndex]);
    }

    setDisplayFacilities(displayValues);
  };

  const goToRoadFacilityDetail = facilityId => {
    navigate('/management-target/road-facilities/' + facilityId);
  };

  return (
    // <Card style={{ minHeight: 'inherit' }}>
    //   <Card.Header>
    //     <h6>프로젝트 도로·시설물 목록</h6>
    //   </Card.Header>
    //   <Card.Body>
    <div style={{ minHeight: 'inherit' }}>
      <Flex
        alignItems="center"
        direction="row"
        justifyContent="end"
        className={'px-3 py-3'}
        style={{ backgroundColor: getColor('gray-100') }}
      >
        <Flex alignItems="center" direction="row" justifyContent="end">
          <Flex alignItems="center" direction="row" justifyContent="start">
            <AdvanceTableSearchBox
              table
              globalFilter={''}
              setGlobalFilter={() => {}}
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex
        alignItems="center"
        direction="row"
        justifyContent="between"
        className={'px-3 pt-3'}
      >
        <Flex
          alignItems="end"
          alignContent={'end'}
          direction="column"
          justifyContent="start"
          className={'fs--1'}
        >
          총 {totalElements}건
        </Flex>
      </Flex>
      <AdvanceTableWrapper
        columns={facilitiesColumns}
        data={displayFacilities}
        sortable
        pagination
        perPage={_pageSize}
      >
        <AdvanceTable
          table
          headerClassName="text-nowrap align-middle text-center"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            hover: true,
            className: 'fs--1 mb-0 overflow-hidden text-center'
          }}
        />
        <div className="mt-3">
          <ResponsivePagination
            current={currentPage}
            total={totalPages}
            onPageChange={setCurrentPage}
            maxWidth={350}
            extraClassName={'justify-content-center fs--2'}
            previousLabel="‹"
            nextLabel="›"
            // narrowBehaviour={combine(dropNav, dropEllipsis)}
          />
        </div>
      </AdvanceTableWrapper>
    </div>
    //   </Card.Body>
    // </Card>
  );
};

export default ProjectFacilitiesList;
