import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getColor, isEmpty, isJSON } from 'helpers/utils';
import * as echarts from 'echarts/core';

import Flex from 'components/common/Flex';
import BasicECharts from 'components/common/BasicEChart';
import { chainAxios } from 'helpers/chainAxios';
import { MapsContext } from 'context/Context';

const RoadFacilitiesManageChart = () => {
  const {
    selectedProject,
    setSelectedProject,
    selectedRegion,
    setSelectedRegion
  } = useContext(MapsContext);

  const [roadFacilitiesShare, setRoadFacilitiesShare] = useState([]);
  const [totalRoadFacilityCount, setTotalRoadFacilityCount] = useState(0);
  const [chartTitle, setChartTitle] = useState('');

  useEffect(() => {
    if (!!selectedProject && JSON.stringify(selectedProject) !== '{}') {
      makeRoadFacilitiesManageProjectChartData([selectedProject]);
      setChartTitle(selectedProject.name);
      return;
    }

    if (!!selectedRegion && JSON.stringify(selectedRegion) !== '{}') {
      makeRoadFacilitiesManageRegionChartData(selectedRegion);
      if (isJSON(selectedRegion.properties.rgnKo)) {
        setChartTitle(JSON.parse(selectedRegion.properties.rgnKo).join(' '));
      } else {
        setChartTitle(selectedRegion.properties.rgnKo.join(' '));
      }
      return;
    }

    getProjects();
  }, []);

  useEffect(() => {
    if (!!selectedProject && JSON.stringify(selectedProject) !== '{}') {
      makeRoadFacilitiesManageProjectChartData([selectedProject]);
      setChartTitle(selectedProject.name);
    }
  }, [selectedProject]);

  useEffect(() => {
    if (!!selectedRegion && JSON.stringify(selectedRegion) !== '{}') {
      makeRoadFacilitiesManageRegionChartData(selectedRegion);
      if (isJSON(selectedRegion.properties.rgnKo)) {
        setChartTitle(JSON.parse(selectedRegion.properties.rgnKo).join(' '));
      } else {
        setChartTitle(selectedRegion.properties.rgnKo.join(' '));
      }
    }
  }, [selectedRegion]);

  const getProjects = () => {
    let url = '/api/projects?pageNo=0&pageSize=99999&sort=createDate%24desc';
    chainAxios
      .get(url)
      .then(response => {
        if (response.data.resultCode === 200) {
          // console.log(response.data.data.projectList.dataList);
          makeRoadFacilitiesManageProjectChartData(
            response.data.data.projectList.dataList
          );
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const makeRoadFacilitiesManageRegionChartData = region => {
    console.log('makeRoadFacilitiesManageRegionChartData : ', region);
    let roadFacilitiesManageRegionChartData = [];

    let aClass = 0;
    let aRoad = 0;
    let aFacility = 0;
    let aField = 0;

    let bClass = 0;
    let bRoad = 0;
    let bFacility = 0;
    let bField = 0;

    let cClass = 0;
    let cRoad = 0;
    let cFacility = 0;
    let cField = 0;

    let dClass = 0;
    let dRoad = 0;
    let dFacility = 0;
    let dField = 0;

    let eClass = 0;
    let eRoad = 0;
    let eFacility = 0;
    let eField = 0;

    if (region.properties.rgnCode === '41280') {
      aClass = 3;
      aRoad = 3;
      bClass = 1;
      bFacility = 1;
    }

    setTotalRoadFacilityCount(aClass + bClass + cClass + dClass + eClass);

    roadFacilitiesManageRegionChartData.push({
      id: 1,
      name: 'A등급',
      value: aClass,
      road: aRoad,
      facility: aFacility,
      field: aField,
      color: 'primary'
    });
    roadFacilitiesManageRegionChartData.push({
      id: 2,
      name: 'B등급',
      value: bClass,
      road: bRoad,
      facility: bFacility,
      field: bField,
      color: 'info'
    });
    roadFacilitiesManageRegionChartData.push({
      id: 3,
      name: 'C등급',
      value: cClass,
      road: cRoad,
      facility: cFacility,
      field: cField,
      color: 'secondary'
    });
    roadFacilitiesManageRegionChartData.push({
      id: 4,
      name: 'D등급',
      value: dClass,
      road: dRoad,
      facility: dFacility,
      field: dField,
      color: 'warning'
    });
    roadFacilitiesManageRegionChartData.push({
      id: 5,
      name: 'E등급',
      value: eClass,
      road: eRoad,
      facility: eFacility,
      field: eField,
      color: 'danger'
    });

    setRoadFacilitiesShare(roadFacilitiesManageRegionChartData);
  };
  const makeRoadFacilitiesManageProjectChartData = projects => {
    let roadFacilitiesManageChartData = [];

    let aClass = 0;
    let aRoad = 0;
    let aFacility = 0;
    let aField = 0;

    let bClass = 0;
    let bRoad = 0;
    let bFacility = 0;
    let bField = 0;

    let cClass = 0;
    let cRoad = 0;
    let cFacility = 0;
    let cField = 0;

    let dClass = 0;
    let dRoad = 0;
    let dFacility = 0;
    let dField = 0;

    let eClass = 0;
    let eRoad = 0;
    let eFacility = 0;
    let eField = 0;

    projects.forEach(project => {
      if (!!project.facilities && project.facilities.length > 0) {
        project.facilities.forEach(f => {
          if (f.status === '4') {
            aClass += 1;
            if (f.type === 'RD') {
              aRoad += 1;
            }
            if (f.type === 'FC') {
              aFacility += 1;
            }
            if (f.type === 'FD') {
              aField += 1;
            }
          }
          if (f.status === '3') {
            bClass += 1;
            if (f.type === 'RD') {
              bRoad += 1;
            }
            if (f.type === 'FC') {
              bFacility += 1;
            }
            if (f.type === 'FD') {
              bField += 1;
            }
          }
          if (f.status === '2') {
            cClass += 1;
            if (f.type === 'RD') {
              cRoad += 1;
            }
            if (f.type === 'FC') {
              cFacility += 1;
            }
            if (f.type === 'FD') {
              cField += 1;
            }
          }
          if (f.status === '1') {
            dClass += 1;
            if (f.type === 'RD') {
              dRoad += 1;
            }
            if (f.type === 'FC') {
              dFacility += 1;
            }
            if (f.type === 'FD') {
              dField += 1;
            }
          }
          if (f.status === '0') {
            eClass += 1;
            if (f.type === 'RD') {
              eRoad += 1;
            }
            if (f.type === 'FC') {
              eFacility += 1;
            }
            if (f.type === 'FD') {
              eField += 1;
            }
          }
        });
      }
    });

    setTotalRoadFacilityCount(aClass + bClass + cClass + dClass + eClass);

    roadFacilitiesManageChartData.push({
      id: 1,
      name: 'A등급',
      value: aClass,
      road: aRoad,
      facility: aFacility,
      field: aField,
      color: 'primary'
    });
    roadFacilitiesManageChartData.push({
      id: 2,
      name: 'B등급',
      value: bClass,
      road: bRoad,
      facility: bFacility,
      field: bField,
      color: 'info'
    });
    roadFacilitiesManageChartData.push({
      id: 3,
      name: 'C등급',
      value: cClass,
      road: cRoad,
      facility: cFacility,
      field: cField,
      color: 'secondary'
    });
    roadFacilitiesManageChartData.push({
      id: 4,
      name: 'D등급',
      value: dClass,
      road: dRoad,
      facility: dFacility,
      field: dField,
      color: 'warning'
    });
    roadFacilitiesManageChartData.push({
      id: 5,
      name: 'E등급',
      value: eClass,
      road: eRoad,
      facility: eFacility,
      field: eField,
      color: 'danger'
    });

    setRoadFacilitiesShare(roadFacilitiesManageChartData);
  };

  const radius = ['100%', '65%'];

  const tooltipFormatter = params => {
    // console.log(params);
    return `<div class="dot me-1 fs--2 d-inline-block" style="background-color: ${getColor(
      params.data.color
    )}"></div><span class="fs--1"><strong>${params.data.name}:</strong> ${
      params.value
    }개소</span><br/>
<span class='text-600 fs--1'>도로: ${params.data.road}개소</span><br/>
<span class='text-600 fs--1'>시설물: ${params.data.facility}개소</span><br/>
<span class='text-600 fs--1'>공사현장: ${params.data.field}개소</span><br/>`;
  };

  const getOptions = (data, radius) => ({
    color: [
      getColor('primary'),
      getColor('info'),
      getColor('secondary'),
      getColor('warning'),
      getColor('danger')
    ],

    tooltip: {
      padding: [7, 10],
      transitionDuration: 0,
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: tooltipFormatter
    },
    series: [
      {
        name: '운영 현황\r\n' + total + '개소',
        type: 'pie',
        radius,
        avoidLabelOverlap: false,
        emphasis: {
          scale: false
        },
        itemStyle: {
          borderWidth: 2,
          borderColor: getColor('gray-100')
        },
        label: {
          show: true,
          position: 'center',
          formatter: '{a}',
          fontSize: '0.8333333333rem',
          fontFamily: 'Pretendard',
          color: getColor('dark')
        },
        data
      }
    ]
  });

  const total = roadFacilitiesShare.reduce((acc, val) => val.value + acc, 0);

  const SensorItem = ({ item, index, total }) => {
    const { name, color, value } = item;

    // const percentage = ((value * 100) / total).toFixed(0);
    return (
      <Flex
        alignItems="center"
        justifyContent="between"
        className={`px-4 fw-semi-bold fs--2 ${index == 0 && 'mt-3'}`}
      >
        <p className="mb-1">
          <FontAwesomeIcon
            icon="circle"
            className={`me-2 text-${
              color.split('-')[1] || color || 'text-info'
            }`}
          />
          {name}
        </p>
        {/* <div className="d-xxl-none">{percentage}%</div> */}
        <div>{value} 개소</div>
      </Flex>
    );
  };

  return (
    // <Card className="h-md-100">
    //   <Card.Body>
    //     <Row className="g-0 h-100">
    <>
      <Flex
        direction="column"
        justifyContent="between"
        className="rounded-1 h-100"
        alignItems="center"
      >
        <h6 className={'fs--1 text-900'}>
          {!isEmpty(selectedProject) || !isEmpty(selectedRegion)
            ? chartTitle + ' 도로·시설물'
            : '전체 도로·시설물'}
        </h6>
      </Flex>
      <Flex
        direction="column"
        justifyContent="center"
        className="rounded-1 h-100 mb-1"
        alignItems="center"
      >
        <BasicECharts
          echarts={echarts}
          options={getOptions(roadFacilitiesShare, radius)}
          style={{ width: '10rem', height: '10rem' }}
          // style={{ width: '10rem', height: '15.8rem' }}
        />
      </Flex>
      <Col className="px-2 text-center">
        {roadFacilitiesShare.map((item, index) => (
          <SensorItem item={item} index={index} key={index} total={total} />
        ))}
      </Col>
    </>
    //     </Row>
    //   </Card.Body>
    // </Card>
  );
};

export default RoadFacilitiesManageChart;
