import React, { useEffect, useState, useContext } from 'react';
import {
  Form,
  Tabs,
  Tab,
  Nav,
  FormControl,
  Button,
  InputGroup
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MapsContext } from 'context/Context';

import SimpleBarReact from 'simplebar-react';
import Flex from 'components/common/Flex';
import FacilityItems from './FacilityItems';
import classNames from 'classnames';
import { chainAxios } from 'helpers/chainAxios';
import { isEmpty } from 'helpers/utils';

const RoadFacilityContent = () => {
  // console.log('FilterInfo : ', facilitiesInfo);
  const { selectedProject, setSelectedProject } = useContext(MapsContext);
  const [orginFacilities, setOriginFacilities] = useState([]);
  const [viewFacilities, setViewFacilities] = useState([]);
  const [selectedTabValue, setSelectedTabValue] = useState('ALL');
  const [searchText, setSearchText] = useState('');

  const [totalElements, setTotalElements] = useState(0);

  useEffect(() => {
    LoadRoadFacilityContent();
  }, []);

  useEffect(() => {
    LoadRoadFacilityContent();

    setSelectedTabValue('ALL');
  }, [selectedProject]);

  const LoadRoadFacilityContent = () => {
    if (!isEmpty(selectedProject) && JSON.stringify(selectedProject) !== '{}') {
      if (!isEmpty(selectedProject.facilities)) {
        setOriginFacilities(selectedProject.facilities);
        setViewFacilities(selectedProject.facilities);
        setTotalElements(selectedProject.facilities.length);
      } else {
        setOriginFacilities([]);
        setViewFacilities([]);
        setTotalElements(0);
      }
    } else {
      getFacilities();
    }
  };

  const onTabChange = value => {
    setSelectedTabValue(value);

    let filterFacilitiesRegions = orginFacilities;
    if (!isEmpty(selectedProject) && JSON.stringify(selectedProject) !== '{}') {
      filterFacilitiesRegions = orginFacilities.filter(
        facility =>
          facility.region.key.substring(0, 5) ===
          selectedProject.regions[0].toString().substring(0, 5)
      );
    }

    if (value === 'ALL') {
      setViewFacilities(filterFacilitiesRegions);
      setTotalElements(filterFacilitiesRegions.length);
    }
    if (value === 'A') {
      setViewFacilities(filterFacilitiesRegions.filter(f => f.status === '4'));

      setTotalElements(
        filterFacilitiesRegions.filter(f => f.status === '4').length
      );
    }
    if (value === 'B') {
      setViewFacilities(filterFacilitiesRegions.filter(f => f.status === '3'));
      setTotalElements(
        filterFacilitiesRegions.filter(f => f.status === '3').length
      );
    }

    if (value === 'C') {
      setViewFacilities(filterFacilitiesRegions.filter(f => f.status === '2'));
      setTotalElements(
        filterFacilitiesRegions.filter(f => f.status === '2').length
      );
    }

    if (value === 'D') {
      setViewFacilities(filterFacilitiesRegions.filter(f => f.status === '1'));
      setTotalElements(
        filterFacilitiesRegions.filter(f => f.status === '1').length
      );
    }

    if (value === 'E') {
      setViewFacilities(filterFacilitiesRegions.filter(f => f.status === '0'));
      setTotalElements(
        filterFacilitiesRegions.filter(f => f.status === '0').length
      );
    }

    if (value === 'FAVORITE') {
      setViewFacilities(filterFacilitiesRegions.filter(f => f.favorite));
      setTotalElements(filterFacilitiesRegions.filter(f => f.favorite).length);
    }
  };

  const getFacilities = () => {
    let url = '/api/facilities?pageNo=0&pageSize=99999&sort=createDate%24desc';
    chainAxios
      .get(url)
      .then(response => {
        // console.log('getFacilities : ', response);
        if (response.data.resultCode === 200) {
          // console.log('list : ', response.data.data.facilityList.dataList);
          setOriginFacilities(response.data.data.facilityList.dataList);
          setViewFacilities(response.data.data.facilityList.dataList);
          setTotalElements(response.data.data.facilityList.dataList.length);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <>
      <InputGroup className="position-relative">
        <FormControl
          size="sm"
          id="search"
          type="search"
          className="shadow-none"
          placeholder="검색어를 입력하세요."
          // onChange={e => handleTicketsSearch(e.target.value)}
        />
        <Button
          size="sm"
          variant="outline-secondary"
          className="border-300 hover-border-secondary"
        >
          <FontAwesomeIcon icon="search" className="fs--1" />
        </Button>
      </InputGroup>
      <Flex
        alignItems="center"
        alignContent={'center'}
        direction="row"
        justifyContent="between"
        className={'px-3'}
      >
        <Flex
          alignItems="end"
          alignContent={'end'}
          direction="column"
          justifyContent="start"
          className={'fs--1'}
        >
          총 {totalElements}개소
        </Flex>
        <Flex
          alignItems={'end'}
          alignContent={'end'}
          direction="column"
          justifyContent="end"
          className={'fs--1'}
        >
          <Form.Check
            type="switch"
            id="checkedSwitch"
            label="즐겨찾기"
            // defaultChecked
          />
        </Flex>
      </Flex>
      <Tabs
        activeKey={selectedTabValue}
        id="fill-project-tab"
        className={classNames('nav')}
        onSelect={onTabChange}
        fill
      >
        <Tab eventKey="ALL" title={<h6>전체</h6>}></Tab>
        <Tab eventKey="A" title={<h6>A</h6>}></Tab>
        <Tab eventKey="B" title={<h6>B</h6>}></Tab>
        <Tab eventKey="C" title={<h6>C</h6>}></Tab>
        <Tab eventKey="D" title={<h6>D</h6>}></Tab>
        <Tab eventKey="E" title={<h6>E</h6>}></Tab>
      </Tabs>
      <SimpleBarReact
        style={{ height: '100%', minWidth: '65px' }}
        className={'mt-0'}
      >
        <Nav className="border-0" activeKey="ALL">
          {viewFacilities.map((facilitie, index) => (
            <FacilityItems key={index} facilitie={facilitie} />
          ))}
        </Nav>
      </SimpleBarReact>
    </>
  );
};

export default RoadFacilityContent;
