import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'react-bootstrap';
import ChatContentHeader from './ChatContentHeader';
import threads from 'data/chat/threads';
import ChatContentBody from './ChatContentBody';
import MessageTextArea from './MessageTextArea';

const ChatContent = ({ thread }) => {
  return (
    <Tab.Content className="card-chat-content">
      {thread.map((item, index) => (
        <Tab.Pane key={index} eventKey={index} className="card-chat-pane">
          <ChatContentBody thread={item} />
        </Tab.Pane>
      ))}
      {/* {threads.map((thread, index) => ( */}
      {/* <Tab.Pane className="card-chat-pane"> */}
      {/* <ChatContentHeader thread={thread} /> */}
      {/* <ChatContentBody thread={thread} />
      </Tab.Pane> */}
      {/* ))} */}
      <MessageTextArea thread={thread[0]} />
    </Tab.Content>
  );
};

// ChatContent.propTypes = {
//   setHideSidebar: PropTypes.func.isRequired
// };

export default ChatContent;
