import { useState } from 'react';
import { useQuery } from 'react-query';
import {
  getDataTraffic,
  getFacilityStatus,
  getGatewayStatus,
  getFacilities,
  getProjectsNoSearch,
  getProjectStatus,
  getSensorStatus,
  getSingleMultiAnalysis,
  getSingleMultiAnalysis2,
  getSubsidenceOccurrenceCity,
  getSubsidenceOccurrenceSubCity,
  getSubsidenceRiskPrediction,
  getSubsidenceRiskPredictionResult
} from '../api';
import moment from 'moment/moment';

export function useProjectStatus() {
  // staleTime : fresh의 유효기간
  // cacheTime: 캐시의 유효기간
  const {
    data = {},
    isLoading,
    isFetching
  } = useQuery(['getProjectStatus'], () => getProjectStatus(), {
    staleTime: 0,
    cacheTime: 10000,
    refetchInterval: 10000,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  return {
    data,
    isLoading,
    isFetching
  };
}

export function useFacilityStatus() {
  // staleTime : fresh의 유효기간
  // cacheTime: 캐시의 유효기간
  const {
    data = {},
    isLoading,
    isFetching
  } = useQuery(['getFacilityStatus'], () => getFacilityStatus(), {
    staleTime: 0,
    cacheTime: 10000,
    refetchInterval: 10000,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  return {
    data,
    isLoading,
    isFetching
  };
}

export function useGatewayStatus() {
  // staleTime : fresh의 유효기간
  // cacheTime: 캐시의 유효기간
  const {
    data = {},
    isLoading,
    isFetching
  } = useQuery(['getGatewayStatus'], () => getGatewayStatus(), {
    staleTime: 0,
    cacheTime: 10000,
    refetchInterval: 10000,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  return {
    data,
    isLoading,
    isFetching
  };
}

export function useSensorStatus() {
  // staleTime : fresh의 유효기간
  // cacheTime: 캐시의 유효기간
  const {
    data = {},
    isLoading,
    isFetching
  } = useQuery(['getSensorStatus'], () => getSensorStatus(), {
    staleTime: 0,
    cacheTime: 10000,
    refetchInterval: 10000,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  return {
    data,
    isLoading,
    isFetching
  };
}

export function useProjects() {
  // staleTime : fresh의 유효기간
  // cacheTime: 캐시의 유효기간
  const {
    data = [],
    isLoading,
    isFetching
  } = useQuery(['getProjectsNoSearch'], () => getProjectsNoSearch(), {
    staleTime: 0,
    cacheTime: 10000,
    refetchInterval: 10000,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  return {
    data,
    isLoading,
    isFetching
  };
}

export function useDataTraffic() {
  // staleTime : fresh의 유효기간
  // cacheTime: 캐시의 유효기간
  const [startDate] = useState(
    moment().subtract(30, 'days').format('YYYY-MM-DD')
  );
  const [endDate] = useState(moment().format('YYYY-MM-DD'));

  const {
    data = [],
    isLoading,
    isFetching
  } = useQuery(
    ['getDataTraffic', startDate, endDate],
    () => getDataTraffic(startDate, endDate),
    {
      staleTime: 0,
      cacheTime: 10000,
      refetchInterval: 10000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false
    }
  );

  return {
    data,
    isLoading,
    isFetching
  };
}

export function useSingleMultiAnalysis() {
  const {
    data = [],
    isLoading,
    isFetching
  } = useQuery(['getSingleMultiAnalysis'], () => getSingleMultiAnalysis(), {
    staleTime: 0,
    cacheTime: 10000,
    refetchInterval: 10000,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  return {
    data,
    isLoading,
    isFetching
  };
}

export function useSingleMultiAnalysis2() {
  const {
    data = [],
    isLoading,
    isFetching
  } = useQuery(['getSingleMultiAnalysis2'], () => getSingleMultiAnalysis2(), {
    staleTime: 0,
    cacheTime: 10000,
    refetchInterval: 10000,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  return {
    data,
    isLoading,
    isFetching
  };
}

export function useSubsidenceOccurrenceCity() {
  // staleTime : fresh의 유효기간
  // cacheTime: 캐시의 유효기간
  const {
    data = [],
    isLoading,
    isFetching
  } = useQuery(
    ['getSubsidenceOccurrenceCity'],
    () => getSubsidenceOccurrenceCity(),
    {
      staleTime: 0,
      cacheTime: 10000,
      refetchInterval: 10000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false
    }
  );

  return {
    data,
    isLoading,
    isFetching
  };
}

export function useSubsidenceOccurrenceSubCity() {
  // staleTime : fresh의 유효기간
  // cacheTime: 캐시의 유효기간
  const {
    data = [],
    isLoading,
    isFetching
  } = useQuery(
    ['getSubsidenceOccurrenceSubCity'],
    () => getSubsidenceOccurrenceSubCity(),
    {
      staleTime: 0,
      cacheTime: 10000,
      refetchInterval: 10000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false
    }
  );

  return {
    data,
    isLoading,
    isFetching
  };
}

export function useSubsidenceRiskPrediction() {
  // staleTime : fresh의 유효기간
  // cacheTime: 캐시의 유효기간
  const {
    data = [],
    isLoading,
    isFetching
  } = useQuery(
    ['getSubsidenceRiskPrediction'],
    () => getSubsidenceRiskPrediction(),
    {
      staleTime: 0,
      cacheTime: 10000,
      refetchInterval: 10000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false
    }
  );

  return {
    data,
    isLoading,
    isFetching
  };
}

export function useSubsidenceRiskPredictionResult() {
  // staleTime : fresh의 유효기간
  // cacheTime: 캐시의 유효기간
  const {
    data = [],
    isLoading,
    isFetching
  } = useQuery(
    ['getSubsidenceRiskPredictionResult'],
    () => getSubsidenceRiskPredictionResult(),
    {
      staleTime: 0,
      cacheTime: 10000,
      refetchInterval: 10000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false
    }
  );

  return {
    data,
    isLoading,
    isFetching
  };
}

export function useEventStatus(projectId) {
  const {
    data = { PRE: 0 },
    isLoading,
    isFetching
  } = useQuery(
    ['getEventStatus', projectId],
    async () => {
      const projects = await getProjectsNoSearch();
      const currentProject = projects.find(p => p.id === projectId);
      return currentProject ? currentProject.eventStatus : { PRE: 0 };
    },
    {
      staleTime: 0,
      cacheTime: 10000,
      refetchInterval: 10000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false
    }
  );

  return {
    data,
    isLoading,
    isFetching
  };
}

export function useFacilityEventStatus(facilityId) {
  const {
    data = { PRE: 0 },
    isLoading,
    isFetching
  } = useQuery(
    ['getFacilityStatus', facilityId],
    async () => {
      const facilities = await getFacilities();
      // console.log('getFacilityStatus response:', facilities);
      const currentFacility = facilities.find(f => f.id === facilityId);
      return currentFacility ? currentFacility.eventStatus : { PRE: 0 };
    },
    {
      staleTime: 0,
      cacheTime: 10000,
      refetchInterval: 10000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false
    }
  );

  return {
    data,
    isLoading,
    isFetching
  };
}
