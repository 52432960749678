import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Card,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
  Dropdown,
  Button
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getColor, isEmpty } from 'helpers/utils';
import * as echarts from 'echarts/core';

import Flex from 'components/common/Flex';
import BasicECharts from 'components/common/BasicEChart';
import CountUp from 'react-countup';
import { useSubsidenceRiskPrediction } from '../hook/useDashboard';

const SubsidenceRiskPredictionChart = () => {
  const {
    data: predictions,
    isLoading,
    isFetching
  } = useSubsidenceRiskPrediction();

  const [marketShare, setMarketShare] = useState([]);

  useEffect(() => {
    makeSubsidenceRiskPredictionData();
  }, []);

  useEffect(() => {
    if (!isFetching) {
      makeSubsidenceRiskPredictionData();
    } else {
      setMarketShare([]);
    }
  }, [isFetching]);

  const makeSubsidenceRiskPredictionData = () => {
    let makeShare = [];
    makeShare.push({
      id: 1,
      name: 'E등급',
      value: isEmpty(predictions.e) ? 0 : predictions.e,
      color: 'danger'
    });

    makeShare.push({
      id: 2,
      name: 'D등급',
      value: isEmpty(predictions.d) ? 0 : predictions.d,
      color: 'warning'
    });

    makeShare.push({
      id: 3,
      name: 'C등급',
      value: isEmpty(predictions.c) ? 0 : predictions.c,
      color: 'secondary'
    });

    setMarketShare(makeShare);
  };

  const radius = ['100%', '65%'];

  const getOptions = (data, radius) => ({
    color: [getColor('danger'), getColor('warning'), getColor('secondary')],

    tooltip: {
      padding: [7, 10],
      transitionDuration: 0,
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: params => {
        // console.log(params);
        return `<div class="dot me-1 fs--2 d-inline-block" style="background-color: ${getColor(
          params.data.color
        )}"></div><span class="fs--1"><strong>${params.data.name}:</strong> ${
          params.value
        }개소</span>`;
      }
    },
    series: [
      {
        // name: '프로젝트\r\n' + total + '개소',
        name: total,
        type: 'pie',
        radius,
        avoidLabelOverlap: false,
        emphasis: {
          scale: false
        },
        itemStyle: {
          borderWidth: 2,
          borderColor: getColor('gray-100')
        },
        label: {
          show: true,
          position: 'center',
          formatter: '{a}개소',
          // fontSize: '0.8333333333rem',
          fontSize: '1.2rem',
          fontFamily: 'Pretendard',
          color: getColor('dark')
        },
        data
      }
    ]
  });

  const total = marketShare.reduce((acc, val) => val.value + acc, 0);

  const ProjectItem = ({ item, index, total }) => {
    const { name, color, value } = item;

    // const percentage = ((value * 100) / total).toFixed(0);
    return (
      <Flex
        alignItems="center"
        alignContent={'center'}
        justifyContent="between"
        className={`fw-semi-bold fs--1 ${index == 0 && 'mt-3'}`}
      >
        <div className="mb-1 fs--1">
          <FontAwesomeIcon
            icon="circle"
            className={`me-2 text-${
              color.split('-')[1] || color || 'text-info'
            }`}
          />
          {name}
        </div>
        {/* <div className="d-xxl-none">{percentage}%</div> */}
        <Flex direction={'row'} alignItems={'center'}>
          <h5>
            <CountUp
              start={0}
              end={value}
              duration={2.75}
              className="text-700 fs--0"
              separator=","
            />
          </h5>
          <div className={'fs--1 fw-semi-bold'}>개소</div>
        </Flex>
      </Flex>
    );
  };

  return (
    <Card className="h-sm-100">
      <Card.Header className="pb-0">
        <h6>시군구별 침하 위험 예측 현황 (실시간)</h6>
      </Card.Header>
      <Card.Body style={{ minHeight: '260px', maxHeight: '260px' }}>
        <Row className="justify-content-center g-0">
          {/*<h6 className="mt-1">프로젝트 관리 현황</h6>*/}
          <Col xs="12">
            <div className="ps-0">
              <BasicECharts
                echarts={echarts}
                options={getOptions(marketShare, radius)}
                style={{ width: '100%', height: '100%' }}
              />
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center g-0">
          <Col xs="12">
            {marketShare.map((item, index) => (
              <ProjectItem
                item={item}
                index={index}
                key={index}
                total={total}
              />
            ))}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default SubsidenceRiskPredictionChart;
