export const guDaejeonData = {
  type: 'FeatureCollection',
  features: [
    {
      id: '30110',
      type: 'Feature',
      properties: {
        SEC_SGG_CD: '30110',
        SEC_SGG_NM: ['대전광역시', '동구'],
        COL_SGG_CD: '30000',
        SEC_SGG_SIZE: '2',
        bbox: [127.41365, 36.19456, 127.55919, 36.43983],
        center: [127.47512, 36.32402],
        area: 137355859
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [127.49246, 36.43936],
            [127.49107, 36.43675],
            [127.48972, 36.43414],
            [127.48979, 36.43341],
            [127.48994, 36.43242],
            [127.49004, 36.43211],
            [127.49129, 36.42953],
            [127.49147, 36.42926],
            [127.4917, 36.42884],
            [127.49186, 36.42849],
            [127.49273, 36.42697],
            [127.49297, 36.42654],
            [127.49313, 36.4262],
            [127.49334, 36.42579],
            [127.49362, 36.42541],
            [127.4939, 36.42506],
            [127.49524, 36.42354],
            [127.49555, 36.4231],
            [127.49567, 36.42266],
            [127.49652, 36.42032],
            [127.49695, 36.41949],
            [127.49701, 36.41911],
            [127.49707, 36.41699],
            [127.49711, 36.41657],
            [127.4972, 36.41588],
            [127.49741, 36.41535],
            [127.49775, 36.41491],
            [127.49841, 36.41418],
            [127.49872, 36.41376],
            [127.49943, 36.413],
            [127.49964, 36.4127],
            [127.5, 36.41204],
            [127.50156, 36.40865],
            [127.50231, 36.40853],
            [127.50448, 36.40822],
            [127.50806, 36.40827],
            [127.50949, 36.40863],
            [127.5103, 36.40947],
            [127.51102, 36.41031],
            [127.51138, 36.41112],
            [127.51207, 36.4138],
            [127.51267, 36.41602],
            [127.51334, 36.41848],
            [127.51451, 36.42247],
            [127.51567, 36.42243],
            [127.51606, 36.42245],
            [127.51645, 36.42233],
            [127.51652, 36.42221],
            [127.51655, 36.42211],
            [127.51665, 36.42204],
            [127.5169, 36.42195],
            [127.51741, 36.42166],
            [127.51763, 36.42155],
            [127.51784, 36.4214],
            [127.51795, 36.42106],
            [127.51802, 36.42098],
            [127.51836, 36.42078],
            [127.51919, 36.42023],
            [127.51928, 36.42],
            [127.51966, 36.41995],
            [127.51989, 36.41999],
            [127.52021, 36.41994],
            [127.52046, 36.42033],
            [127.52037, 36.42046],
            [127.52038, 36.42066],
            [127.52077, 36.42089],
            [127.52087, 36.42107],
            [127.52118, 36.42137],
            [127.52143, 36.42145],
            [127.52171, 36.42151],
            [127.5222, 36.42159],
            [127.52255, 36.42175],
            [127.52281, 36.42192],
            [127.52323, 36.42275],
            [127.52343, 36.42291],
            [127.52375, 36.42307],
            [127.52405, 36.42343],
            [127.52431, 36.42353],
            [127.52497, 36.42313],
            [127.52508, 36.42278],
            [127.52538, 36.42261],
            [127.52554, 36.42232],
            [127.52613, 36.42188],
            [127.52606, 36.42152],
            [127.52605, 36.42099],
            [127.5262, 36.42089],
            [127.52635, 36.42089],
            [127.52649, 36.42088],
            [127.52699, 36.42117],
            [127.52714, 36.42119],
            [127.52782, 36.42119],
            [127.52822, 36.42109],
            [127.52857, 36.42076],
            [127.52869, 36.42039],
            [127.52888, 36.42016],
            [127.52892, 36.41966],
            [127.52955, 36.41888],
            [127.5303, 36.41892],
            [127.53059, 36.4191],
            [127.53126, 36.41922],
            [127.5325, 36.41921],
            [127.53278, 36.41928],
            [127.53297, 36.41915],
            [127.53347, 36.41907],
            [127.53447, 36.41924],
            [127.53479, 36.41925],
            [127.53502, 36.41915],
            [127.53535, 36.41885],
            [127.53611, 36.41923],
            [127.53637, 36.41918],
            [127.53668, 36.41938],
            [127.53673, 36.41988],
            [127.53753, 36.4203],
            [127.53786, 36.42015],
            [127.53835, 36.42014],
            [127.53857, 36.42025],
            [127.53935, 36.42041],
            [127.54018, 36.42073],
            [127.541, 36.42029],
            [127.54227, 36.41942],
            [127.54284, 36.41932],
            [127.54216, 36.41817],
            [127.54185, 36.41569],
            [127.54288, 36.41208],
            [127.54557, 36.4088],
            [127.54695, 36.40834],
            [127.55008, 36.40711],
            [127.55168, 36.4055],
            [127.5532, 36.40122],
            [127.555, 36.40012],
            [127.55553, 36.39988],
            [127.5563, 36.39967],
            [127.55711, 36.39955],
            [127.55817, 36.39968],
            [127.55878, 36.39986],
            [127.55917, 36.39918],
            [127.55884, 36.39903],
            [127.55919, 36.39773],
            [127.55886, 36.39764],
            [127.55814, 36.39755],
            [127.55756, 36.3972],
            [127.55569, 36.39596],
            [127.55513, 36.39533],
            [127.55486, 36.39505],
            [127.55398, 36.39479],
            [127.55251, 36.39518],
            [127.55252, 36.3953],
            [127.55178, 36.39517],
            [127.55086, 36.39541],
            [127.55019, 36.3965],
            [127.55001, 36.39675],
            [127.54855, 36.39691],
            [127.54808, 36.39664],
            [127.5468, 36.39632],
            [127.54619, 36.39632],
            [127.54603, 36.39641],
            [127.54466, 36.39603],
            [127.54394, 36.39616],
            [127.54361, 36.39599],
            [127.54346, 36.39629],
            [127.54286, 36.39625],
            [127.54151, 36.39648],
            [127.541, 36.3964],
            [127.54044, 36.39639],
            [127.54019, 36.39711],
            [127.5401, 36.39715],
            [127.5398, 36.39764],
            [127.53917, 36.39778],
            [127.53912, 36.39708],
            [127.53808, 36.39568],
            [127.53753, 36.39507],
            [127.53688, 36.39484],
            [127.53666, 36.39491],
            [127.53607, 36.39472],
            [127.53561, 36.39469],
            [127.5349, 36.39399],
            [127.53459, 36.3937],
            [127.53358, 36.39365],
            [127.53339, 36.39336],
            [127.53393, 36.3929],
            [127.53348, 36.39175],
            [127.53282, 36.39138],
            [127.53261, 36.39095],
            [127.53193, 36.39105],
            [127.53171, 36.39096],
            [127.53015, 36.39145],
            [127.52982, 36.39048],
            [127.52939, 36.38997],
            [127.52943, 36.38936],
            [127.52838, 36.38892],
            [127.52823, 36.38897],
            [127.52692, 36.38777],
            [127.52564, 36.38722],
            [127.52505, 36.38751],
            [127.52484, 36.38739],
            [127.52486, 36.3866],
            [127.52591, 36.38543],
            [127.52564, 36.38482],
            [127.52507, 36.38375],
            [127.52551, 36.38324],
            [127.52529, 36.38292],
            [127.52527, 36.38133],
            [127.52554, 36.38051],
            [127.52567, 36.38031],
            [127.52558, 36.3795],
            [127.52621, 36.37871],
            [127.52597, 36.37834],
            [127.52596, 36.37799],
            [127.52598, 36.37776],
            [127.52602, 36.37743],
            [127.52605, 36.37721],
            [127.52578, 36.37655],
            [127.52552, 36.37648],
            [127.52526, 36.37627],
            [127.52545, 36.3755],
            [127.52532, 36.37513],
            [127.52673, 36.37379],
            [127.52685, 36.3731],
            [127.52682, 36.37284],
            [127.52689, 36.37237],
            [127.52682, 36.37193],
            [127.52675, 36.37163],
            [127.52659, 36.37155],
            [127.52639, 36.37125],
            [127.52669, 36.371],
            [127.52673, 36.37076],
            [127.52704, 36.37012],
            [127.527, 36.36953],
            [127.52676, 36.36937],
            [127.52658, 36.36841],
            [127.52623, 36.36787],
            [127.52577, 36.36756],
            [127.52545, 36.36641],
            [127.52434, 36.3662],
            [127.52444, 36.3661],
            [127.52424, 36.36587],
            [127.52441, 36.3656],
            [127.52447, 36.36506],
            [127.52478, 36.36424],
            [127.52485, 36.36363],
            [127.52449, 36.36338],
            [127.52451, 36.36317],
            [127.52437, 36.36305],
            [127.52449, 36.36239],
            [127.52427, 36.3621],
            [127.52447, 36.36148],
            [127.52426, 36.36109],
            [127.52409, 36.36103],
            [127.52359, 36.36039],
            [127.52368, 36.36004],
            [127.52302, 36.35951],
            [127.52285, 36.35901],
            [127.52289, 36.35868],
            [127.52216, 36.35834],
            [127.52204, 36.358],
            [127.52247, 36.35728],
            [127.52283, 36.357],
            [127.52305, 36.35654],
            [127.52292, 36.356],
            [127.52265, 36.35561],
            [127.52239, 36.35497],
            [127.522, 36.35435],
            [127.52162, 36.35352],
            [127.52116, 36.35283],
            [127.52114, 36.35272],
            [127.52075, 36.35209],
            [127.52033, 36.35132],
            [127.51982, 36.35109],
            [127.51907, 36.35045],
            [127.51853, 36.35037],
            [127.51781, 36.34993],
            [127.51722, 36.34887],
            [127.51572, 36.34846],
            [127.51523, 36.34812],
            [127.51473, 36.34752],
            [127.51448, 36.34743],
            [127.51407, 36.34754],
            [127.51329, 36.34758],
            [127.51298, 36.34743],
            [127.51284, 36.34722],
            [127.51263, 36.3472],
            [127.51221, 36.34632],
            [127.51196, 36.34608],
            [127.51092, 36.34621],
            [127.51084, 36.34627],
            [127.51062, 36.34652],
            [127.5103, 36.3466],
            [127.50977, 36.34669],
            [127.50876, 36.34661],
            [127.50828, 36.34646],
            [127.5076, 36.34632],
            [127.5077, 36.34533],
            [127.50804, 36.3449],
            [127.50845, 36.34446],
            [127.50833, 36.34396],
            [127.50852, 36.34348],
            [127.50856, 36.3433],
            [127.50789, 36.34313],
            [127.50718, 36.34293],
            [127.5069, 36.34258],
            [127.50651, 36.34246],
            [127.5059, 36.3425],
            [127.50488, 36.34225],
            [127.50469, 36.34127],
            [127.50446, 36.34107],
            [127.50439, 36.34099],
            [127.50449, 36.34093],
            [127.50437, 36.34086],
            [127.50405, 36.34082],
            [127.50366, 36.34065],
            [127.50302, 36.34059],
            [127.50108, 36.33991],
            [127.50069, 36.33942],
            [127.50072, 36.33869],
            [127.50077, 36.33861],
            [127.50075, 36.3384],
            [127.50085, 36.33798],
            [127.50173, 36.33653],
            [127.5016, 36.33638],
            [127.50159, 36.33609],
            [127.50154, 36.33566],
            [127.50146, 36.3354],
            [127.50127, 36.33521],
            [127.50146, 36.33472],
            [127.50112, 36.33318],
            [127.50173, 36.33263],
            [127.50199, 36.33202],
            [127.50197, 36.33185],
            [127.50173, 36.3309],
            [127.50213, 36.33021],
            [127.50192, 36.32968],
            [127.50197, 36.32935],
            [127.50202, 36.32921],
            [127.50248, 36.32843],
            [127.50248, 36.3278],
            [127.50324, 36.32701],
            [127.5036, 36.32614],
            [127.50275, 36.32494],
            [127.50258, 36.32464],
            [127.50255, 36.3242],
            [127.50224, 36.3239],
            [127.50212, 36.32363],
            [127.50203, 36.32334],
            [127.50165, 36.32297],
            [127.50134, 36.32288],
            [127.50101, 36.32226],
            [127.50075, 36.32182],
            [127.5004, 36.32133],
            [127.50041, 36.32111],
            [127.50016, 36.32078],
            [127.49937, 36.32056],
            [127.49854, 36.32039],
            [127.49788, 36.32018],
            [127.49754, 36.31994],
            [127.49735, 36.31956],
            [127.49836, 36.31906],
            [127.49841, 36.31883],
            [127.49835, 36.31828],
            [127.49868, 36.31667],
            [127.49905, 36.31558],
            [127.49908, 36.31483],
            [127.49876, 36.31433],
            [127.49827, 36.31381],
            [127.49803, 36.31369],
            [127.49715, 36.31357],
            [127.49694, 36.31327],
            [127.49637, 36.31278],
            [127.49615, 36.3124],
            [127.49595, 36.31203],
            [127.49579, 36.31194],
            [127.49448, 36.31158],
            [127.49414, 36.31107],
            [127.49513, 36.31066],
            [127.49574, 36.3099],
            [127.49631, 36.30954],
            [127.4965, 36.3086],
            [127.49693, 36.30785],
            [127.4972, 36.30744],
            [127.49839, 36.30694],
            [127.4984, 36.30614],
            [127.4991, 36.30553],
            [127.49905, 36.30527],
            [127.49906, 36.30496],
            [127.49847, 36.30426],
            [127.49838, 36.30403],
            [127.49807, 36.30395],
            [127.49794, 36.30366],
            [127.49786, 36.303],
            [127.49749, 36.30231],
            [127.49747, 36.30209],
            [127.49754, 36.30184],
            [127.49762, 36.30138],
            [127.49687, 36.30087],
            [127.49654, 36.30046],
            [127.49649, 36.30038],
            [127.49648, 36.29986],
            [127.49638, 36.29942],
            [127.49622, 36.29896],
            [127.49587, 36.29892],
            [127.49568, 36.29971],
            [127.49528, 36.30011],
            [127.49492, 36.30031],
            [127.4948, 36.30051],
            [127.49452, 36.3006],
            [127.49434, 36.30061],
            [127.49416, 36.30078],
            [127.49359, 36.3007],
            [127.49347, 36.30032],
            [127.49305, 36.30024],
            [127.49279, 36.3],
            [127.49253, 36.29989],
            [127.49228, 36.3],
            [127.49215, 36.29971],
            [127.49209, 36.29972],
            [127.49139, 36.29934],
            [127.4913, 36.29884],
            [127.49121, 36.29811],
            [127.4912, 36.29747],
            [127.49095, 36.29703],
            [127.49101, 36.29659],
            [127.49168, 36.29615],
            [127.49186, 36.2958],
            [127.49212, 36.29563],
            [127.49226, 36.29524],
            [127.49252, 36.29501],
            [127.49262, 36.29457],
            [127.4934, 36.29336],
            [127.49337, 36.29291],
            [127.49356, 36.2925],
            [127.49393, 36.29245],
            [127.49421, 36.29209],
            [127.4942, 36.2918],
            [127.4943, 36.29158],
            [127.49461, 36.29123],
            [127.49505, 36.29043],
            [127.49544, 36.29014],
            [127.49533, 36.28964],
            [127.49564, 36.28779],
            [127.49615, 36.28758],
            [127.49637, 36.28747],
            [127.49735, 36.28671],
            [127.49746, 36.28633],
            [127.4974, 36.28582],
            [127.49756, 36.28479],
            [127.49821, 36.28388],
            [127.49873, 36.28319],
            [127.49846, 36.28281],
            [127.49831, 36.2815],
            [127.49764, 36.28105],
            [127.49715, 36.28057],
            [127.49672, 36.27962],
            [127.49661, 36.27941],
            [127.49594, 36.27886],
            [127.49521, 36.27851],
            [127.49487, 36.27831],
            [127.49452, 36.2779],
            [127.49289, 36.27653],
            [127.49278, 36.276],
            [127.4925, 36.27568],
            [127.49198, 36.27538],
            [127.49116, 36.27496],
            [127.49084, 36.27441],
            [127.49045, 36.27423],
            [127.48959, 36.27375],
            [127.48938, 36.27376],
            [127.48849, 36.27298],
            [127.48876, 36.27274],
            [127.48888, 36.27232],
            [127.48937, 36.2708],
            [127.48948, 36.26996],
            [127.48979, 36.26969],
            [127.48993, 36.26905],
            [127.49028, 36.26848],
            [127.49002, 36.26812],
            [127.48992, 36.26789],
            [127.48871, 36.26742],
            [127.48835, 36.26733],
            [127.48807, 36.26642],
            [127.4881, 36.26634],
            [127.48778, 36.26564],
            [127.48758, 36.26505],
            [127.48746, 36.26356],
            [127.48718, 36.2634],
            [127.48704, 36.26278],
            [127.48687, 36.26236],
            [127.48652, 36.2622],
            [127.48624, 36.26198],
            [127.48603, 36.26141],
            [127.48645, 36.26083],
            [127.48622, 36.26054],
            [127.48609, 36.25989],
            [127.4863, 36.25936],
            [127.48723, 36.2589],
            [127.48744, 36.25843],
            [127.48767, 36.25831],
            [127.48854, 36.25842],
            [127.48896, 36.25851],
            [127.4894, 36.25824],
            [127.48976, 36.25813],
            [127.48981, 36.25805],
            [127.48947, 36.25619],
            [127.48986, 36.25497],
            [127.49007, 36.25451],
            [127.48988, 36.25389],
            [127.48941, 36.25343],
            [127.48828, 36.2523],
            [127.48757, 36.25147],
            [127.48755, 36.25071],
            [127.48795, 36.24985],
            [127.48861, 36.24859],
            [127.48872, 36.24781],
            [127.48895, 36.24672],
            [127.49037, 36.24505],
            [127.49118, 36.24504],
            [127.49189, 36.24521],
            [127.49244, 36.24439],
            [127.49275, 36.24346],
            [127.49282, 36.24304],
            [127.49242, 36.24276],
            [127.49234, 36.24247],
            [127.49255, 36.24221],
            [127.49286, 36.24159],
            [127.49327, 36.24114],
            [127.49333, 36.24064],
            [127.49321, 36.24012],
            [127.49263, 36.23949],
            [127.49266, 36.23797],
            [127.49254, 36.23737],
            [127.49147, 36.23752],
            [127.49139, 36.23752],
            [127.49055, 36.23743],
            [127.49018, 36.23723],
            [127.48995, 36.23713],
            [127.48987, 36.23674],
            [127.48976, 36.23637],
            [127.48933, 36.23575],
            [127.48998, 36.2346],
            [127.48994, 36.2335],
            [127.48879, 36.2331],
            [127.48845, 36.23263],
            [127.4877, 36.23278],
            [127.48729, 36.23295],
            [127.48646, 36.23256],
            [127.48576, 36.23202],
            [127.4853, 36.23204],
            [127.48442, 36.23113],
            [127.48352, 36.23042],
            [127.48296, 36.23053],
            [127.48258, 36.23048],
            [127.48217, 36.23067],
            [127.48165, 36.23049],
            [127.48051, 36.2303],
            [127.47956, 36.23021],
            [127.47836, 36.22983],
            [127.47785, 36.22974],
            [127.47795, 36.22881],
            [127.47807, 36.22858],
            [127.47817, 36.22832],
            [127.47804, 36.22797],
            [127.47797, 36.22685],
            [127.47784, 36.2267],
            [127.47687, 36.22603],
            [127.47628, 36.2261],
            [127.4758, 36.22588],
            [127.47511, 36.22553],
            [127.47479, 36.22543],
            [127.47445, 36.22496],
            [127.47344, 36.2248],
            [127.47246, 36.22471],
            [127.47206, 36.22487],
            [127.47105, 36.22466],
            [127.47011, 36.22359],
            [127.46915, 36.22352],
            [127.4682, 36.22332],
            [127.46771, 36.22315],
            [127.46729, 36.22278],
            [127.46706, 36.22247],
            [127.4671, 36.22172],
            [127.46748, 36.2208],
            [127.46726, 36.22042],
            [127.46729, 36.22006],
            [127.46741, 36.21993],
            [127.46725, 36.21912],
            [127.4673, 36.21867],
            [127.46764, 36.21811],
            [127.46761, 36.21727],
            [127.46764, 36.21714],
            [127.46804, 36.21677],
            [127.46896, 36.21625],
            [127.46926, 36.21606],
            [127.46926, 36.21586],
            [127.46954, 36.21528],
            [127.46877, 36.21458],
            [127.46799, 36.21448],
            [127.46717, 36.21429],
            [127.46653, 36.21425],
            [127.46612, 36.21407],
            [127.46523, 36.21434],
            [127.46367, 36.21402],
            [127.46352, 36.21371],
            [127.46308, 36.21367],
            [127.46275, 36.21355],
            [127.46246, 36.21366],
            [127.46186, 36.21331],
            [127.46123, 36.21307],
            [127.46089, 36.21286],
            [127.46019, 36.21293],
            [127.46001, 36.21263],
            [127.45998, 36.21234],
            [127.45987, 36.21197],
            [127.45994, 36.21168],
            [127.45989, 36.2113],
            [127.45959, 36.21086],
            [127.4595, 36.21064],
            [127.45911, 36.21023],
            [127.45923, 36.20976],
            [127.46023, 36.20991],
            [127.46032, 36.2099],
            [127.46018, 36.20963],
            [127.45931, 36.20924],
            [127.4589, 36.20894],
            [127.45859, 36.2088],
            [127.45817, 36.20881],
            [127.45749, 36.20849],
            [127.45677, 36.2083],
            [127.4564, 36.20813],
            [127.45609, 36.20798],
            [127.45556, 36.20747],
            [127.45424, 36.20708],
            [127.45416, 36.2066],
            [127.45564, 36.2044],
            [127.45619, 36.20392],
            [127.45627, 36.20376],
            [127.45643, 36.20352],
            [127.45636, 36.20333],
            [127.45632, 36.20276],
            [127.45609, 36.20229],
            [127.4561, 36.20203],
            [127.45597, 36.20175],
            [127.45593, 36.20155],
            [127.45589, 36.20108],
            [127.45565, 36.20085],
            [127.45481, 36.20082],
            [127.45392, 36.2004],
            [127.45339, 36.20001],
            [127.4527, 36.19987],
            [127.4523, 36.19955],
            [127.45189, 36.19873],
            [127.45154, 36.19872],
            [127.45083, 36.19887],
            [127.45042, 36.19849],
            [127.45017, 36.19809],
            [127.45014, 36.19787],
            [127.44999, 36.19773],
            [127.44915, 36.19747],
            [127.44888, 36.19675],
            [127.44819, 36.19672],
            [127.44737, 36.19651],
            [127.44588, 36.19598],
            [127.44545, 36.19558],
            [127.44518, 36.19553],
            [127.44487, 36.19556],
            [127.44478, 36.19541],
            [127.44452, 36.1953],
            [127.44437, 36.19498],
            [127.44393, 36.19471],
            [127.44355, 36.19479],
            [127.44287, 36.19469],
            [127.44171, 36.19456],
            [127.44115, 36.19478],
            [127.44095, 36.1949],
            [127.44096, 36.19543],
            [127.44087, 36.19566],
            [127.44078, 36.19626],
            [127.44075, 36.19661],
            [127.44074, 36.1967],
            [127.43998, 36.19706],
            [127.43937, 36.19733],
            [127.43821, 36.19717],
            [127.438, 36.19741],
            [127.4379, 36.19747],
            [127.43761, 36.19758],
            [127.4373, 36.19774],
            [127.43716, 36.19819],
            [127.43676, 36.19851],
            [127.4361, 36.19835],
            [127.43563, 36.19853],
            [127.43525, 36.19861],
            [127.43469, 36.1984],
            [127.4343, 36.19839],
            [127.43391, 36.19841],
            [127.43374, 36.19845],
            [127.43354, 36.19856],
            [127.43332, 36.19923],
            [127.43293, 36.20009],
            [127.43256, 36.20057],
            [127.43228, 36.2012],
            [127.43119, 36.20212],
            [127.4312, 36.20271],
            [127.43111, 36.20329],
            [127.43098, 36.20393],
            [127.43093, 36.20411],
            [127.43082, 36.20415],
            [127.43051, 36.20425],
            [127.43041, 36.20425],
            [127.42937, 36.20521],
            [127.42893, 36.20516],
            [127.42789, 36.20515],
            [127.42759, 36.20535],
            [127.42644, 36.20597],
            [127.42557, 36.20584],
            [127.425, 36.2061],
            [127.42392, 36.20605],
            [127.42364, 36.20617],
            [127.42343, 36.20649],
            [127.42271, 36.2074],
            [127.42237, 36.20811],
            [127.42355, 36.20976],
            [127.4243, 36.21008],
            [127.42614, 36.21161],
            [127.42544, 36.21297],
            [127.42382, 36.21391],
            [127.42397, 36.21432],
            [127.42468, 36.21474],
            [127.42514, 36.21572],
            [127.42517, 36.21619],
            [127.42596, 36.21697],
            [127.42576, 36.21727],
            [127.42579, 36.21777],
            [127.42637, 36.21815],
            [127.42653, 36.21839],
            [127.42664, 36.21847],
            [127.4262, 36.21875],
            [127.42606, 36.21911],
            [127.42617, 36.21965],
            [127.42583, 36.22071],
            [127.42541, 36.22152],
            [127.42566, 36.2219],
            [127.42612, 36.22223],
            [127.42552, 36.22436],
            [127.42568, 36.22485],
            [127.42564, 36.22528],
            [127.42406, 36.2261],
            [127.42394, 36.22651],
            [127.42412, 36.22739],
            [127.42404, 36.22775],
            [127.42411, 36.22776],
            [127.42477, 36.22835],
            [127.42555, 36.22864],
            [127.42599, 36.22903],
            [127.42679, 36.22916],
            [127.42837, 36.22884],
            [127.42904, 36.22856],
            [127.42963, 36.22798],
            [127.43114, 36.2272],
            [127.43263, 36.2274],
            [127.43383, 36.22781],
            [127.43417, 36.22929],
            [127.43438, 36.22948],
            [127.4352, 36.22991],
            [127.43687, 36.23165],
            [127.43764, 36.23235],
            [127.43783, 36.23279],
            [127.43768, 36.23336],
            [127.43766, 36.23413],
            [127.43742, 36.23506],
            [127.43755, 36.23515],
            [127.43757, 36.23526],
            [127.43781, 36.23617],
            [127.43771, 36.23663],
            [127.43871, 36.23714],
            [127.44009, 36.2376],
            [127.44122, 36.23785],
            [127.44186, 36.23763],
            [127.44234, 36.23825],
            [127.44308, 36.23846],
            [127.44325, 36.23858],
            [127.44319, 36.23862],
            [127.44332, 36.23873],
            [127.44228, 36.23924],
            [127.44199, 36.2396],
            [127.44188, 36.23995],
            [127.44179, 36.24017],
            [127.44204, 36.24052],
            [127.44231, 36.24166],
            [127.44235, 36.24264],
            [127.44205, 36.24328],
            [127.44186, 36.2435],
            [127.44208, 36.24388],
            [127.44218, 36.24444],
            [127.44211, 36.24445],
            [127.44208, 36.24455],
            [127.44031, 36.24478],
            [127.43978, 36.24519],
            [127.43912, 36.24574],
            [127.43851, 36.24667],
            [127.43792, 36.24738],
            [127.43784, 36.248],
            [127.43753, 36.24846],
            [127.43727, 36.24921],
            [127.43588, 36.24996],
            [127.43534, 36.24996],
            [127.43529, 36.25005],
            [127.43523, 36.25032],
            [127.43514, 36.25079],
            [127.43475, 36.25113],
            [127.4351, 36.25151],
            [127.43573, 36.25275],
            [127.43459, 36.25339],
            [127.43434, 36.25355],
            [127.4338, 36.25382],
            [127.43352, 36.25398],
            [127.43256, 36.25405],
            [127.43125, 36.25387],
            [127.42989, 36.25389],
            [127.42722, 36.25445],
            [127.42819, 36.25521],
            [127.42818, 36.25545],
            [127.42782, 36.25568],
            [127.42787, 36.2566],
            [127.42843, 36.25655],
            [127.4291, 36.25668],
            [127.42921, 36.25672],
            [127.42994, 36.25707],
            [127.42983, 36.25726],
            [127.42985, 36.25781],
            [127.42972, 36.25795],
            [127.42969, 36.25935],
            [127.42924, 36.25963],
            [127.42924, 36.26054],
            [127.42917, 36.261],
            [127.4293, 36.26154],
            [127.43046, 36.26167],
            [127.4309, 36.2621],
            [127.43106, 36.26216],
            [127.43147, 36.26272],
            [127.43155, 36.26289],
            [127.43213, 36.26355],
            [127.43285, 36.26422],
            [127.43304, 36.26485],
            [127.43354, 36.26526],
            [127.43434, 36.26566],
            [127.43619, 36.26654],
            [127.43726, 36.26745],
            [127.43823, 36.26781],
            [127.43809, 36.26826],
            [127.43818, 36.26873],
            [127.43855, 36.2693],
            [127.43954, 36.27026],
            [127.43932, 36.27092],
            [127.43883, 36.27126],
            [127.43849, 36.27176],
            [127.43825, 36.2718],
            [127.43779, 36.27251],
            [127.43796, 36.27295],
            [127.43779, 36.27331],
            [127.43762, 36.27334],
            [127.43736, 36.27335],
            [127.43699, 36.27372],
            [127.43703, 36.27407],
            [127.43702, 36.27429],
            [127.43697, 36.27448],
            [127.43678, 36.2746],
            [127.43669, 36.27502],
            [127.43555, 36.27518],
            [127.43366, 36.27571],
            [127.43338, 36.27701],
            [127.43322, 36.27759],
            [127.43297, 36.27773],
            [127.43289, 36.27829],
            [127.43273, 36.2784],
            [127.43265, 36.27836],
            [127.43223, 36.27896],
            [127.43231, 36.27931],
            [127.4329, 36.27956],
            [127.43322, 36.27995],
            [127.43392, 36.28027],
            [127.43412, 36.28053],
            [127.43462, 36.28082],
            [127.43538, 36.28167],
            [127.43478, 36.28245],
            [127.43424, 36.28253],
            [127.43388, 36.28277],
            [127.43326, 36.28283],
            [127.4331, 36.2831],
            [127.43249, 36.28339],
            [127.43248, 36.28368],
            [127.43159, 36.2845],
            [127.43134, 36.28449],
            [127.43096, 36.28477],
            [127.4309, 36.2851],
            [127.43084, 36.28637],
            [127.43037, 36.28676],
            [127.43033, 36.28692],
            [127.43008, 36.28712],
            [127.42983, 36.28753],
            [127.42969, 36.28761],
            [127.4296, 36.28762],
            [127.42943, 36.28758],
            [127.4292, 36.28764],
            [127.42904, 36.28759],
            [127.42886, 36.28766],
            [127.42865, 36.28788],
            [127.42819, 36.28799],
            [127.42801, 36.28814],
            [127.42795, 36.28822],
            [127.42791, 36.28839],
            [127.4278, 36.28848],
            [127.4273, 36.28869],
            [127.42712, 36.28944],
            [127.42732, 36.28991],
            [127.42736, 36.2903],
            [127.42735, 36.29066],
            [127.42739, 36.29103],
            [127.42758, 36.29125],
            [127.42758, 36.29146],
            [127.4277, 36.29166],
            [127.42781, 36.29198],
            [127.42794, 36.29189],
            [127.4282, 36.29194],
            [127.42846, 36.2921],
            [127.43004, 36.29139],
            [127.43127, 36.2913],
            [127.43266, 36.29083],
            [127.433, 36.29088],
            [127.43369, 36.29116],
            [127.43385, 36.29106],
            [127.43425, 36.29126],
            [127.43453, 36.2916],
            [127.43475, 36.29224],
            [127.43502, 36.29255],
            [127.43537, 36.29269],
            [127.43584, 36.29266],
            [127.436, 36.29286],
            [127.43722, 36.29249],
            [127.43777, 36.29268],
            [127.43889, 36.29392],
            [127.43879, 36.2941],
            [127.43963, 36.29456],
            [127.4401, 36.29493],
            [127.44076, 36.29519],
            [127.44079, 36.29576],
            [127.4412, 36.29672],
            [127.44162, 36.29685],
            [127.44167, 36.29654],
            [127.44227, 36.29595],
            [127.44323, 36.29562],
            [127.44431, 36.29586],
            [127.44555, 36.2963],
            [127.44578, 36.29626],
            [127.44589, 36.29631],
            [127.44619, 36.29652],
            [127.44637, 36.29677],
            [127.4468, 36.29701],
            [127.44667, 36.29687],
            [127.44672, 36.2966],
            [127.44694, 36.29646],
            [127.44704, 36.29631],
            [127.44719, 36.2962],
            [127.44729, 36.29607],
            [127.44731, 36.29598],
            [127.44739, 36.29489],
            [127.44736, 36.2948],
            [127.44736, 36.29475],
            [127.44716, 36.29418],
            [127.44803, 36.29296],
            [127.4481, 36.29227],
            [127.44822, 36.29209],
            [127.44866, 36.29202],
            [127.44895, 36.2921],
            [127.44928, 36.29246],
            [127.44955, 36.29271],
            [127.44991, 36.29349],
            [127.45087, 36.29386],
            [127.45155, 36.29369],
            [127.4516, 36.29374],
            [127.45194, 36.29365],
            [127.45213, 36.29365],
            [127.45243, 36.29378],
            [127.45291, 36.29337],
            [127.45298, 36.29338],
            [127.4531, 36.29343],
            [127.454, 36.29355],
            [127.45536, 36.29348],
            [127.45629, 36.29281],
            [127.45643, 36.29296],
            [127.45658, 36.29328],
            [127.45703, 36.29376],
            [127.45726, 36.29402],
            [127.45758, 36.29421],
            [127.4579, 36.2936],
            [127.45832, 36.2931],
            [127.45841, 36.29283],
            [127.45876, 36.29282],
            [127.45907, 36.29273],
            [127.45898, 36.29316],
            [127.45888, 36.29348],
            [127.45842, 36.29363],
            [127.45815, 36.29374],
            [127.45723, 36.29524],
            [127.45707, 36.29562],
            [127.457, 36.29599],
            [127.45701, 36.29646],
            [127.45704, 36.29683],
            [127.45704, 36.29709],
            [127.45681, 36.29753],
            [127.45665, 36.29778],
            [127.45631, 36.29822],
            [127.45629, 36.29828],
            [127.45618, 36.29871],
            [127.45614, 36.29902],
            [127.45606, 36.30006],
            [127.45599, 36.30061],
            [127.45594, 36.30065],
            [127.4558, 36.30084],
            [127.45551, 36.30132],
            [127.45347, 36.30223],
            [127.45283, 36.30282],
            [127.45157, 36.30379],
            [127.45137, 36.30432],
            [127.45113, 36.30473],
            [127.45084, 36.30505],
            [127.45066, 36.30535],
            [127.44975, 36.30646],
            [127.44894, 36.30723],
            [127.44863, 36.30761],
            [127.44854, 36.30793],
            [127.44852, 36.30852],
            [127.44848, 36.30895],
            [127.44874, 36.30895],
            [127.44868, 36.30908],
            [127.4484, 36.30944],
            [127.44797, 36.30986],
            [127.4476, 36.3102],
            [127.44704, 36.31049],
            [127.44674, 36.31062],
            [127.44619, 36.31081],
            [127.44566, 36.31088],
            [127.44488, 36.31094],
            [127.44474, 36.31102],
            [127.44429, 36.31106],
            [127.44377, 36.31105],
            [127.44329, 36.31118],
            [127.4425, 36.31142],
            [127.44215, 36.31156],
            [127.44063, 36.31226],
            [127.44047, 36.31235],
            [127.44011, 36.31289],
            [127.43983, 36.31343],
            [127.43939, 36.31449],
            [127.43915, 36.31497],
            [127.43903, 36.31518],
            [127.43883, 36.31572],
            [127.43871, 36.31611],
            [127.43847, 36.31721],
            [127.4383, 36.31774],
            [127.4381, 36.31806],
            [127.4377, 36.31844],
            [127.4376, 36.31879],
            [127.4373, 36.31936],
            [127.43705, 36.31988],
            [127.43637, 36.32079],
            [127.43566, 36.32164],
            [127.43512, 36.32209],
            [127.43485, 36.3224],
            [127.43435, 36.32322],
            [127.4341, 36.32369],
            [127.43383, 36.32411],
            [127.43344, 36.32465],
            [127.43293, 36.32526],
            [127.43256, 36.32514],
            [127.43224, 36.32553],
            [127.43269, 36.3257],
            [127.43088, 36.32784],
            [127.42945, 36.32963],
            [127.42909, 36.33006],
            [127.4281, 36.3308],
            [127.42774, 36.33103],
            [127.42718, 36.33144],
            [127.42671, 36.33181],
            [127.4265, 36.33199],
            [127.42572, 36.33259],
            [127.42548, 36.3328],
            [127.42548, 36.33286],
            [127.42476, 36.33325],
            [127.42412, 36.33486],
            [127.42405, 36.33503],
            [127.42389, 36.33529],
            [127.42353, 36.33565],
            [127.42309, 36.33602],
            [127.42251, 36.33639],
            [127.42201, 36.33675],
            [127.42167, 36.33687],
            [127.42114, 36.33719],
            [127.42076, 36.3375],
            [127.42041, 36.33795],
            [127.4201, 36.33845],
            [127.4198, 36.33887],
            [127.41973, 36.33893],
            [127.4191, 36.33912],
            [127.41918, 36.33901],
            [127.41735, 36.33943],
            [127.41681, 36.33964],
            [127.41601, 36.34002],
            [127.41576, 36.34019],
            [127.41556, 36.34035],
            [127.41499, 36.34093],
            [127.4149, 36.34104],
            [127.41474, 36.34134],
            [127.41365, 36.34395],
            [127.41454, 36.34416],
            [127.41533, 36.34434],
            [127.41699, 36.34466],
            [127.41779, 36.34472],
            [127.41853, 36.34473],
            [127.41949, 36.34467],
            [127.41995, 36.3446],
            [127.42066, 36.34447],
            [127.42109, 36.34438],
            [127.4215, 36.34426],
            [127.42312, 36.3437],
            [127.42323, 36.34385],
            [127.42158, 36.3444],
            [127.42085, 36.3446],
            [127.41844, 36.34636],
            [127.41887, 36.34684],
            [127.41924, 36.3474],
            [127.41941, 36.3477],
            [127.41958, 36.34831],
            [127.42013, 36.34827],
            [127.42039, 36.34833],
            [127.42083, 36.34834],
            [127.4212, 36.34854],
            [127.42129, 36.34848],
            [127.42137, 36.34851],
            [127.42165, 36.34857],
            [127.42178, 36.34856],
            [127.42183, 36.34853],
            [127.42197, 36.34849],
            [127.42212, 36.34849],
            [127.42232, 36.34852],
            [127.42247, 36.34854],
            [127.42265, 36.34855],
            [127.42279, 36.3485],
            [127.4232, 36.3485],
            [127.4237, 36.34856],
            [127.42392, 36.34848],
            [127.42412, 36.34849],
            [127.42426, 36.34853],
            [127.42483, 36.34881],
            [127.42506, 36.34903],
            [127.42523, 36.34923],
            [127.42562, 36.35004],
            [127.42599, 36.35067],
            [127.4262, 36.35098],
            [127.4265, 36.35132],
            [127.42659, 36.3514],
            [127.42692, 36.35165],
            [127.42729, 36.35189],
            [127.42735, 36.35197],
            [127.42725, 36.35251],
            [127.42791, 36.35259],
            [127.42748, 36.35546],
            [127.42911, 36.3556],
            [127.42906, 36.35677],
            [127.43077, 36.35682],
            [127.43067, 36.35906],
            [127.43245, 36.35911],
            [127.43285, 36.35919],
            [127.43291, 36.35918],
            [127.43294, 36.35926],
            [127.4331, 36.35939],
            [127.43378, 36.35984],
            [127.43398, 36.35992],
            [127.43415, 36.36008],
            [127.43501, 36.36071],
            [127.43532, 36.36083],
            [127.43617, 36.36078],
            [127.43636, 36.36069],
            [127.43665, 36.36028],
            [127.43671, 36.36025],
            [127.43677, 36.36028],
            [127.43684, 36.36024],
            [127.43701, 36.36022],
            [127.43717, 36.36023],
            [127.43717, 36.36018],
            [127.43728, 36.36018],
            [127.43746, 36.36021],
            [127.43754, 36.36014],
            [127.43774, 36.36002],
            [127.43828, 36.35998],
            [127.43862, 36.35964],
            [127.43876, 36.35925],
            [127.43923, 36.35831],
            [127.4391, 36.35808],
            [127.43899, 36.35765],
            [127.43897, 36.3575],
            [127.43908, 36.35724],
            [127.43892, 36.35701],
            [127.43925, 36.35675],
            [127.43972, 36.35654],
            [127.43988, 36.35626],
            [127.4397, 36.3556],
            [127.44004, 36.35556],
            [127.43965, 36.35508],
            [127.44148, 36.35356],
            [127.44138, 36.35234],
            [127.44227, 36.35195],
            [127.44277, 36.35173],
            [127.44517, 36.3508],
            [127.44598, 36.35232],
            [127.44568, 36.3522],
            [127.44549, 36.35223],
            [127.44562, 36.3524],
            [127.44583, 36.35336],
            [127.44597, 36.35365],
            [127.4466, 36.35344],
            [127.4469, 36.3539],
            [127.447, 36.35401],
            [127.44871, 36.35337],
            [127.44905, 36.35394],
            [127.44908, 36.35406],
            [127.45432, 36.35444],
            [127.45463, 36.35445],
            [127.45395, 36.35324],
            [127.45416, 36.35318],
            [127.45498, 36.35302],
            [127.45548, 36.35301],
            [127.45596, 36.35304],
            [127.45689, 36.35303],
            [127.45769, 36.35299],
            [127.45848, 36.35297],
            [127.45876, 36.35258],
            [127.4589, 36.35222],
            [127.45924, 36.35216],
            [127.45924, 36.3521],
            [127.4609, 36.35177],
            [127.46194, 36.35154],
            [127.46279, 36.35116],
            [127.46332, 36.35084],
            [127.4635, 36.35077],
            [127.46368, 36.35073],
            [127.46405, 36.35078],
            [127.46438, 36.3509],
            [127.4646, 36.35102],
            [127.46511, 36.35147],
            [127.46504, 36.35239],
            [127.46475, 36.35257],
            [127.46459, 36.3535],
            [127.4646, 36.35373],
            [127.46477, 36.35525],
            [127.46524, 36.35588],
            [127.46533, 36.35626],
            [127.46552, 36.3564],
            [127.46561, 36.35667],
            [127.46563, 36.35724],
            [127.46631, 36.3581],
            [127.46601, 36.35848],
            [127.46592, 36.35892],
            [127.46602, 36.35935],
            [127.46623, 36.36056],
            [127.46619, 36.36076],
            [127.46562, 36.361],
            [127.46509, 36.36106],
            [127.46461, 36.36135],
            [127.46392, 36.36149],
            [127.46344, 36.36198],
            [127.46328, 36.36253],
            [127.46302, 36.36319],
            [127.46301, 36.36325],
            [127.46267, 36.3636],
            [127.46274, 36.3639],
            [127.46253, 36.36435],
            [127.46233, 36.36434],
            [127.4622, 36.36449],
            [127.46212, 36.36454],
            [127.46199, 36.36466],
            [127.46128, 36.366],
            [127.4608, 36.36679],
            [127.46067, 36.36751],
            [127.46002, 36.36801],
            [127.46001, 36.36843],
            [127.45988, 36.36861],
            [127.45975, 36.36872],
            [127.45967, 36.36883],
            [127.45917, 36.36921],
            [127.45816, 36.36926],
            [127.45797, 36.36963],
            [127.45782, 36.36979],
            [127.45778, 36.37007],
            [127.45765, 36.37029],
            [127.45734, 36.3716],
            [127.45718, 36.37241],
            [127.45681, 36.37277],
            [127.45632, 36.37331],
            [127.45601, 36.37355],
            [127.45575, 36.37386],
            [127.45577, 36.37466],
            [127.45577, 36.37476],
            [127.45536, 36.37509],
            [127.45501, 36.37552],
            [127.45462, 36.37581],
            [127.45434, 36.37591],
            [127.45441, 36.37618],
            [127.45429, 36.37669],
            [127.45427, 36.37697],
            [127.45395, 36.37705],
            [127.45312, 36.37733],
            [127.45267, 36.37804],
            [127.45207, 36.3786],
            [127.45177, 36.37904],
            [127.45154, 36.37929],
            [127.45164, 36.37961],
            [127.45148, 36.38003],
            [127.45142, 36.38031],
            [127.45154, 36.3808],
            [127.45119, 36.38102],
            [127.45121, 36.38113],
            [127.45167, 36.38162],
            [127.45208, 36.38177],
            [127.45214, 36.38184],
            [127.45246, 36.38201],
            [127.45269, 36.38236],
            [127.45273, 36.38266],
            [127.45292, 36.38275],
            [127.45315, 36.38305],
            [127.45335, 36.38353],
            [127.4531, 36.38401],
            [127.45307, 36.38414],
            [127.45332, 36.38499],
            [127.4536, 36.3854],
            [127.45423, 36.38592],
            [127.45484, 36.38677],
            [127.45474, 36.38776],
            [127.45468, 36.38802],
            [127.45488, 36.38845],
            [127.45529, 36.38883],
            [127.45416, 36.39006],
            [127.45437, 36.39036],
            [127.45439, 36.39076],
            [127.45415, 36.39121],
            [127.4543, 36.39232],
            [127.45423, 36.39353],
            [127.4542, 36.39377],
            [127.45419, 36.39426],
            [127.45461, 36.39598],
            [127.45493, 36.3967],
            [127.45505, 36.39733],
            [127.45541, 36.3982],
            [127.45543, 36.39863],
            [127.45521, 36.39875],
            [127.45534, 36.39947],
            [127.45532, 36.40003],
            [127.45576, 36.39984],
            [127.4559, 36.40092],
            [127.45631, 36.40106],
            [127.45687, 36.40148],
            [127.45763, 36.40246],
            [127.45758, 36.40308],
            [127.45816, 36.40359],
            [127.45821, 36.40368],
            [127.45912, 36.40395],
            [127.45947, 36.4042],
            [127.46007, 36.40442],
            [127.46021, 36.40449],
            [127.46085, 36.40457],
            [127.46124, 36.40487],
            [127.46144, 36.40527],
            [127.46187, 36.40552],
            [127.46229, 36.40596],
            [127.46273, 36.40656],
            [127.46328, 36.40689],
            [127.46408, 36.40694],
            [127.4642, 36.40714],
            [127.46487, 36.40766],
            [127.46513, 36.40773],
            [127.46565, 36.40793],
            [127.46633, 36.40832],
            [127.46649, 36.40849],
            [127.46655, 36.40856],
            [127.46656, 36.40863],
            [127.46691, 36.40915],
            [127.46742, 36.40972],
            [127.4674, 36.40992],
            [127.46769, 36.40995],
            [127.4679, 36.40992],
            [127.46818, 36.40985],
            [127.46841, 36.41017],
            [127.46853, 36.41063],
            [127.46865, 36.41095],
            [127.46901, 36.41116],
            [127.46958, 36.41185],
            [127.46981, 36.41217],
            [127.47046, 36.41268],
            [127.47127, 36.4136],
            [127.47161, 36.41413],
            [127.47181, 36.41425],
            [127.4719, 36.41434],
            [127.47239, 36.41489],
            [127.47254, 36.41511],
            [127.47281, 36.4156],
            [127.4729, 36.41607],
            [127.4734, 36.41646],
            [127.47362, 36.41669],
            [127.47381, 36.41709],
            [127.47442, 36.41759],
            [127.47455, 36.4178],
            [127.4743, 36.41803],
            [127.47346, 36.41904],
            [127.47339, 36.41908],
            [127.47339, 36.41923],
            [127.47338, 36.41931],
            [127.47388, 36.41946],
            [127.47397, 36.41966],
            [127.47451, 36.41946],
            [127.47468, 36.41947],
            [127.47494, 36.41945],
            [127.47552, 36.41924],
            [127.47578, 36.41919],
            [127.47604, 36.41908],
            [127.47617, 36.4195],
            [127.47655, 36.42042],
            [127.47674, 36.42098],
            [127.47732, 36.42158],
            [127.47765, 36.42185],
            [127.47789, 36.42211],
            [127.47786, 36.42241],
            [127.47806, 36.42306],
            [127.4783, 36.42321],
            [127.47844, 36.42342],
            [127.47841, 36.42369],
            [127.47868, 36.42396],
            [127.47902, 36.4244],
            [127.47903, 36.42457],
            [127.47937, 36.42524],
            [127.47964, 36.42542],
            [127.47956, 36.42584],
            [127.47973, 36.4262],
            [127.48024, 36.4265],
            [127.48116, 36.42796],
            [127.48119, 36.42802],
            [127.4809, 36.42842],
            [127.48108, 36.42885],
            [127.48093, 36.42914],
            [127.48104, 36.42955],
            [127.48138, 36.42997],
            [127.48138, 36.43002],
            [127.48147, 36.43014],
            [127.48147, 36.43025],
            [127.48155, 36.4304],
            [127.48159, 36.43065],
            [127.4819, 36.43085],
            [127.48193, 36.43092],
            [127.48199, 36.43095],
            [127.48232, 36.43151],
            [127.48261, 36.43202],
            [127.48296, 36.4323],
            [127.48316, 36.43262],
            [127.48405, 36.43349],
            [127.48416, 36.43394],
            [127.48453, 36.43419],
            [127.48471, 36.43467],
            [127.4853, 36.43526],
            [127.48568, 36.43585],
            [127.48602, 36.43607],
            [127.48603, 36.43628],
            [127.48606, 36.43639],
            [127.48626, 36.43672],
            [127.48654, 36.43703],
            [127.48712, 36.43747],
            [127.48708, 36.43757],
            [127.48731, 36.43789],
            [127.48766, 36.4385],
            [127.48784, 36.43867],
            [127.4883, 36.43898],
            [127.48885, 36.43879],
            [127.48934, 36.4391],
            [127.48979, 36.43915],
            [127.49001, 36.43937],
            [127.49067, 36.4398],
            [127.49111, 36.43983],
            [127.49246, 36.43936]
          ]
        ]
      }
    },
    {
      id: '30140',
      type: 'Feature',
      properties: {
        SEC_SGG_CD: '30140',
        SEC_SGG_NM: ['대전광역시', '중구'],
        COL_SGG_CD: '30000',
        SEC_SGG_SIZE: '2',
        bbox: [127.36751, 36.20714, 127.45907, 36.34963],
        center: [127.41107, 36.28089],
        area: 62160130
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [127.4091, 36.34796],
            [127.40956, 36.34776],
            [127.40996, 36.34776],
            [127.4101, 36.34765],
            [127.4109, 36.347],
            [127.41135, 36.34653],
            [127.41165, 36.34611],
            [127.41179, 36.34556],
            [127.41204, 36.34504],
            [127.41259, 36.34411],
            [127.41276, 36.34368],
            [127.41296, 36.34303],
            [127.41317, 36.34283],
            [127.4134, 36.34254],
            [127.41376, 36.34203],
            [127.41416, 36.34155],
            [127.4145, 36.3412],
            [127.4149, 36.34096],
            [127.41499, 36.34093],
            [127.41556, 36.34035],
            [127.41576, 36.34019],
            [127.41594, 36.34006],
            [127.41638, 36.33984],
            [127.41681, 36.33964],
            [127.41735, 36.33943],
            [127.41918, 36.33901],
            [127.4191, 36.33912],
            [127.41973, 36.33893],
            [127.4198, 36.33887],
            [127.4201, 36.33845],
            [127.42041, 36.33795],
            [127.42076, 36.3375],
            [127.42114, 36.33719],
            [127.42167, 36.33687],
            [127.42201, 36.33675],
            [127.42251, 36.33639],
            [127.42309, 36.33602],
            [127.42353, 36.33565],
            [127.42389, 36.33529],
            [127.42405, 36.33503],
            [127.42412, 36.33486],
            [127.42476, 36.33325],
            [127.42548, 36.33286],
            [127.42548, 36.3328],
            [127.42572, 36.33259],
            [127.4265, 36.33199],
            [127.42671, 36.33181],
            [127.42718, 36.33144],
            [127.42774, 36.33103],
            [127.4281, 36.3308],
            [127.42909, 36.33006],
            [127.42945, 36.32963],
            [127.43111, 36.32755],
            [127.43269, 36.3257],
            [127.43224, 36.32553],
            [127.43256, 36.32514],
            [127.43293, 36.32526],
            [127.43344, 36.32465],
            [127.43383, 36.32411],
            [127.4341, 36.32369],
            [127.43435, 36.32322],
            [127.43485, 36.3224],
            [127.43512, 36.32209],
            [127.43566, 36.32164],
            [127.43637, 36.32079],
            [127.43705, 36.31988],
            [127.4373, 36.31936],
            [127.4376, 36.31879],
            [127.4377, 36.31844],
            [127.4381, 36.31806],
            [127.4383, 36.31774],
            [127.43847, 36.31721],
            [127.43871, 36.31611],
            [127.43883, 36.31572],
            [127.43903, 36.31518],
            [127.43915, 36.31497],
            [127.43939, 36.31449],
            [127.43983, 36.31343],
            [127.44011, 36.31289],
            [127.44047, 36.31235],
            [127.44063, 36.31226],
            [127.44215, 36.31156],
            [127.4425, 36.31142],
            [127.44329, 36.31118],
            [127.44377, 36.31105],
            [127.44429, 36.31106],
            [127.44474, 36.31102],
            [127.44488, 36.31094],
            [127.44566, 36.31088],
            [127.44619, 36.31081],
            [127.44674, 36.31062],
            [127.44704, 36.31049],
            [127.4476, 36.3102],
            [127.44797, 36.30986],
            [127.4484, 36.30944],
            [127.44868, 36.30908],
            [127.44874, 36.30895],
            [127.44848, 36.30895],
            [127.44852, 36.30852],
            [127.44854, 36.30793],
            [127.44863, 36.30761],
            [127.44894, 36.30723],
            [127.44975, 36.30646],
            [127.45066, 36.30535],
            [127.45084, 36.30505],
            [127.45113, 36.30473],
            [127.45137, 36.30432],
            [127.45157, 36.30379],
            [127.45283, 36.30282],
            [127.45347, 36.30223],
            [127.45551, 36.30132],
            [127.4558, 36.30084],
            [127.45594, 36.30065],
            [127.45599, 36.30061],
            [127.45606, 36.30006],
            [127.45614, 36.29902],
            [127.45618, 36.29871],
            [127.45629, 36.29828],
            [127.45631, 36.29822],
            [127.45665, 36.29778],
            [127.45681, 36.29753],
            [127.45704, 36.29709],
            [127.45704, 36.29683],
            [127.45701, 36.29646],
            [127.457, 36.29599],
            [127.45707, 36.29562],
            [127.45723, 36.29524],
            [127.45815, 36.29374],
            [127.45842, 36.29363],
            [127.45888, 36.29348],
            [127.45898, 36.29316],
            [127.45907, 36.29273],
            [127.45876, 36.29282],
            [127.45841, 36.29283],
            [127.45832, 36.2931],
            [127.4579, 36.2936],
            [127.45758, 36.29421],
            [127.45726, 36.29402],
            [127.45703, 36.29376],
            [127.45658, 36.29328],
            [127.45643, 36.29296],
            [127.45629, 36.29281],
            [127.45536, 36.29348],
            [127.454, 36.29355],
            [127.4531, 36.29343],
            [127.45298, 36.29338],
            [127.45291, 36.29337],
            [127.45243, 36.29378],
            [127.45213, 36.29365],
            [127.45194, 36.29365],
            [127.4516, 36.29374],
            [127.45155, 36.29369],
            [127.45087, 36.29386],
            [127.44991, 36.29349],
            [127.44955, 36.29271],
            [127.44928, 36.29246],
            [127.44895, 36.2921],
            [127.44866, 36.29202],
            [127.44822, 36.29209],
            [127.4481, 36.29227],
            [127.44803, 36.29296],
            [127.44716, 36.29418],
            [127.44736, 36.29475],
            [127.44736, 36.2948],
            [127.44739, 36.29489],
            [127.44731, 36.29598],
            [127.44729, 36.29607],
            [127.44719, 36.2962],
            [127.44704, 36.29631],
            [127.44694, 36.29646],
            [127.44672, 36.2966],
            [127.44667, 36.29687],
            [127.4468, 36.29701],
            [127.44637, 36.29677],
            [127.44619, 36.29652],
            [127.44589, 36.29631],
            [127.44578, 36.29626],
            [127.44555, 36.2963],
            [127.44431, 36.29586],
            [127.44323, 36.29562],
            [127.44227, 36.29595],
            [127.44167, 36.29654],
            [127.44162, 36.29685],
            [127.4412, 36.29672],
            [127.44079, 36.29576],
            [127.44076, 36.29519],
            [127.4401, 36.29493],
            [127.43963, 36.29456],
            [127.43879, 36.2941],
            [127.43889, 36.29392],
            [127.43777, 36.29268],
            [127.43722, 36.29249],
            [127.436, 36.29286],
            [127.43584, 36.29266],
            [127.43537, 36.29269],
            [127.43502, 36.29255],
            [127.43475, 36.29224],
            [127.43453, 36.2916],
            [127.43425, 36.29126],
            [127.43385, 36.29106],
            [127.43369, 36.29116],
            [127.433, 36.29088],
            [127.43266, 36.29083],
            [127.43127, 36.2913],
            [127.43004, 36.29139],
            [127.42846, 36.2921],
            [127.4282, 36.29194],
            [127.42794, 36.29189],
            [127.42781, 36.29198],
            [127.4277, 36.29166],
            [127.42758, 36.29146],
            [127.42758, 36.29125],
            [127.42739, 36.29103],
            [127.42735, 36.29066],
            [127.42736, 36.2903],
            [127.42732, 36.28991],
            [127.42712, 36.28944],
            [127.4273, 36.28869],
            [127.4278, 36.28848],
            [127.42791, 36.28839],
            [127.42795, 36.28822],
            [127.42819, 36.28799],
            [127.42865, 36.28788],
            [127.42886, 36.28766],
            [127.42904, 36.28759],
            [127.4292, 36.28764],
            [127.42943, 36.28758],
            [127.4296, 36.28762],
            [127.42969, 36.28761],
            [127.42983, 36.28753],
            [127.43008, 36.28712],
            [127.43033, 36.28692],
            [127.43037, 36.28676],
            [127.43084, 36.28637],
            [127.4309, 36.2851],
            [127.43096, 36.28477],
            [127.43134, 36.28449],
            [127.43159, 36.2845],
            [127.43248, 36.28368],
            [127.43249, 36.28339],
            [127.4331, 36.2831],
            [127.43326, 36.28283],
            [127.43388, 36.28277],
            [127.43424, 36.28253],
            [127.43478, 36.28245],
            [127.43538, 36.28167],
            [127.43462, 36.28082],
            [127.43412, 36.28053],
            [127.43392, 36.28027],
            [127.43322, 36.27995],
            [127.4329, 36.27956],
            [127.43231, 36.27931],
            [127.43223, 36.27896],
            [127.43265, 36.27836],
            [127.43273, 36.2784],
            [127.43289, 36.27829],
            [127.43297, 36.27773],
            [127.43322, 36.27759],
            [127.43338, 36.27701],
            [127.43366, 36.27571],
            [127.43555, 36.27518],
            [127.43669, 36.27502],
            [127.43678, 36.2746],
            [127.43697, 36.27448],
            [127.43702, 36.27429],
            [127.43703, 36.27407],
            [127.43699, 36.27372],
            [127.43736, 36.27335],
            [127.43762, 36.27334],
            [127.43779, 36.27331],
            [127.43796, 36.27295],
            [127.43779, 36.27251],
            [127.43825, 36.2718],
            [127.43849, 36.27176],
            [127.43883, 36.27126],
            [127.43932, 36.27092],
            [127.43954, 36.27026],
            [127.43855, 36.2693],
            [127.43818, 36.26873],
            [127.43809, 36.26826],
            [127.43823, 36.26781],
            [127.43726, 36.26745],
            [127.43619, 36.26654],
            [127.43434, 36.26566],
            [127.43354, 36.26526],
            [127.43304, 36.26485],
            [127.43285, 36.26422],
            [127.43213, 36.26355],
            [127.43155, 36.26289],
            [127.43147, 36.26272],
            [127.43106, 36.26216],
            [127.4309, 36.2621],
            [127.43046, 36.26167],
            [127.4293, 36.26154],
            [127.42917, 36.261],
            [127.42924, 36.26054],
            [127.42924, 36.25963],
            [127.42969, 36.25935],
            [127.42972, 36.25795],
            [127.42985, 36.25781],
            [127.42983, 36.25726],
            [127.42994, 36.25707],
            [127.42921, 36.25672],
            [127.4291, 36.25668],
            [127.42843, 36.25655],
            [127.42787, 36.2566],
            [127.42782, 36.25568],
            [127.42818, 36.25545],
            [127.42819, 36.25521],
            [127.42722, 36.25445],
            [127.42989, 36.25389],
            [127.43125, 36.25387],
            [127.43256, 36.25405],
            [127.43352, 36.25398],
            [127.4338, 36.25382],
            [127.43434, 36.25355],
            [127.43459, 36.25339],
            [127.43573, 36.25275],
            [127.4351, 36.25151],
            [127.43475, 36.25113],
            [127.43514, 36.25079],
            [127.43523, 36.25032],
            [127.43529, 36.25005],
            [127.43534, 36.24996],
            [127.43588, 36.24996],
            [127.43727, 36.24921],
            [127.43753, 36.24846],
            [127.43784, 36.248],
            [127.43792, 36.24738],
            [127.43851, 36.24667],
            [127.43912, 36.24574],
            [127.43978, 36.24519],
            [127.44031, 36.24478],
            [127.44208, 36.24455],
            [127.44211, 36.24445],
            [127.44218, 36.24444],
            [127.44208, 36.24388],
            [127.44186, 36.2435],
            [127.44205, 36.24328],
            [127.44235, 36.24264],
            [127.44231, 36.24166],
            [127.44204, 36.24052],
            [127.44179, 36.24017],
            [127.44188, 36.23995],
            [127.44199, 36.2396],
            [127.44228, 36.23924],
            [127.44332, 36.23873],
            [127.44319, 36.23862],
            [127.44325, 36.23858],
            [127.44308, 36.23846],
            [127.44234, 36.23825],
            [127.44186, 36.23763],
            [127.44122, 36.23785],
            [127.44009, 36.2376],
            [127.43871, 36.23714],
            [127.43771, 36.23663],
            [127.43781, 36.23617],
            [127.43757, 36.23526],
            [127.43755, 36.23515],
            [127.43742, 36.23506],
            [127.43766, 36.23413],
            [127.43768, 36.23336],
            [127.43783, 36.23279],
            [127.43764, 36.23235],
            [127.43687, 36.23165],
            [127.4352, 36.22991],
            [127.43438, 36.22948],
            [127.43417, 36.22929],
            [127.43383, 36.22781],
            [127.43263, 36.2274],
            [127.43114, 36.2272],
            [127.42963, 36.22798],
            [127.42904, 36.22856],
            [127.42837, 36.22884],
            [127.42679, 36.22916],
            [127.42599, 36.22903],
            [127.42555, 36.22864],
            [127.42477, 36.22835],
            [127.42411, 36.22776],
            [127.42404, 36.22775],
            [127.42412, 36.22739],
            [127.42394, 36.22651],
            [127.42406, 36.2261],
            [127.42566, 36.22523],
            [127.42568, 36.22485],
            [127.42552, 36.22436],
            [127.42612, 36.22223],
            [127.42566, 36.2219],
            [127.42541, 36.22152],
            [127.42583, 36.22071],
            [127.42617, 36.21965],
            [127.42606, 36.21911],
            [127.4262, 36.21875],
            [127.42664, 36.21847],
            [127.42653, 36.21839],
            [127.42637, 36.21815],
            [127.42579, 36.21777],
            [127.42576, 36.21727],
            [127.42596, 36.21697],
            [127.42517, 36.21619],
            [127.42514, 36.21572],
            [127.42468, 36.21474],
            [127.42397, 36.21432],
            [127.42382, 36.21391],
            [127.42544, 36.21297],
            [127.42614, 36.21161],
            [127.4243, 36.21008],
            [127.42355, 36.20976],
            [127.42232, 36.20814],
            [127.42126, 36.20813],
            [127.42119, 36.20811],
            [127.42031, 36.20795],
            [127.41981, 36.20744],
            [127.41979, 36.20719],
            [127.41959, 36.20714],
            [127.41805, 36.20806],
            [127.41722, 36.20845],
            [127.41676, 36.20843],
            [127.41645, 36.2087],
            [127.41567, 36.20888],
            [127.4153, 36.20905],
            [127.41499, 36.20933],
            [127.41499, 36.20961],
            [127.41455, 36.20998],
            [127.41398, 36.21014],
            [127.4135, 36.21042],
            [127.41245, 36.21115],
            [127.41216, 36.21143],
            [127.41134, 36.21162],
            [127.4105, 36.21165],
            [127.41012, 36.21182],
            [127.41003, 36.21186],
            [127.40985, 36.21205],
            [127.40946, 36.21232],
            [127.40935, 36.21243],
            [127.40906, 36.21258],
            [127.40814, 36.21297],
            [127.4086, 36.21343],
            [127.40883, 36.21394],
            [127.40887, 36.2142],
            [127.409, 36.21469],
            [127.40893, 36.21479],
            [127.40985, 36.21578],
            [127.41007, 36.21593],
            [127.41071, 36.21682],
            [127.4107, 36.21746],
            [127.41012, 36.218],
            [127.40987, 36.21833],
            [127.40972, 36.21895],
            [127.40946, 36.21926],
            [127.40928, 36.21927],
            [127.4089, 36.21956],
            [127.40873, 36.21977],
            [127.40839, 36.22055],
            [127.40799, 36.22068],
            [127.40753, 36.22135],
            [127.40701, 36.22148],
            [127.40661, 36.22146],
            [127.40577, 36.22157],
            [127.40535, 36.22195],
            [127.40474, 36.2223],
            [127.40471, 36.22271],
            [127.40419, 36.22347],
            [127.40408, 36.22384],
            [127.4036, 36.22454],
            [127.40324, 36.22496],
            [127.40319, 36.22532],
            [127.40255, 36.22579],
            [127.40228, 36.22669],
            [127.40169, 36.22834],
            [127.40145, 36.22865],
            [127.40134, 36.22917],
            [127.40132, 36.22939],
            [127.40096, 36.23008],
            [127.40101, 36.23028],
            [127.40101, 36.23121],
            [127.40075, 36.23208],
            [127.40088, 36.23234],
            [127.40073, 36.23255],
            [127.40088, 36.23291],
            [127.40066, 36.2332],
            [127.40024, 36.23288],
            [127.39922, 36.23253],
            [127.39848, 36.23258],
            [127.39813, 36.23274],
            [127.39787, 36.23278],
            [127.39741, 36.23225],
            [127.39657, 36.23213],
            [127.39599, 36.23258],
            [127.39506, 36.23248],
            [127.39491, 36.23262],
            [127.39414, 36.23257],
            [127.39291, 36.23342],
            [127.39259, 36.23351],
            [127.39198, 36.23341],
            [127.39158, 36.23349],
            [127.39155, 36.23376],
            [127.39026, 36.23349],
            [127.3898, 36.23352],
            [127.38914, 36.23396],
            [127.38737, 36.23427],
            [127.38732, 36.23471],
            [127.38707, 36.23526],
            [127.38741, 36.23614],
            [127.38737, 36.23622],
            [127.3874, 36.23632],
            [127.38733, 36.23644],
            [127.38738, 36.23654],
            [127.38725, 36.23731],
            [127.38903, 36.23815],
            [127.38959, 36.23905],
            [127.39016, 36.23939],
            [127.39033, 36.23954],
            [127.39061, 36.24062],
            [127.3902, 36.241],
            [127.39026, 36.24121],
            [127.39024, 36.24145],
            [127.38995, 36.24177],
            [127.39009, 36.24212],
            [127.39051, 36.24252],
            [127.39084, 36.2428],
            [127.39084, 36.24296],
            [127.39042, 36.24313],
            [127.39032, 36.24346],
            [127.39039, 36.24358],
            [127.39011, 36.24373],
            [127.38985, 36.24389],
            [127.38957, 36.24399],
            [127.38929, 36.24429],
            [127.38909, 36.24461],
            [127.38846, 36.24502],
            [127.38818, 36.24552],
            [127.38788, 36.24589],
            [127.38814, 36.24632],
            [127.38819, 36.24636],
            [127.38813, 36.2468],
            [127.38789, 36.24699],
            [127.38766, 36.2475],
            [127.38748, 36.24787],
            [127.3874, 36.2483],
            [127.38719, 36.2486],
            [127.38708, 36.24868],
            [127.38739, 36.24904],
            [127.38751, 36.2496],
            [127.38745, 36.24964],
            [127.38708, 36.24966],
            [127.38681, 36.24999],
            [127.38681, 36.25037],
            [127.38641, 36.25074],
            [127.3866, 36.25101],
            [127.38657, 36.25158],
            [127.38675, 36.25208],
            [127.38659, 36.25233],
            [127.38652, 36.25263],
            [127.38678, 36.25293],
            [127.38681, 36.25299],
            [127.38684, 36.25308],
            [127.38684, 36.25331],
            [127.38751, 36.25404],
            [127.38793, 36.25428],
            [127.38816, 36.25429],
            [127.38821, 36.25442],
            [127.38818, 36.25458],
            [127.38859, 36.25503],
            [127.38871, 36.25538],
            [127.38876, 36.25588],
            [127.38886, 36.25614],
            [127.38887, 36.25649],
            [127.38908, 36.25672],
            [127.38912, 36.25683],
            [127.389, 36.25713],
            [127.38881, 36.25731],
            [127.38892, 36.25769],
            [127.3887, 36.25802],
            [127.38906, 36.25875],
            [127.38894, 36.25897],
            [127.38915, 36.25926],
            [127.38925, 36.25929],
            [127.38954, 36.25931],
            [127.38989, 36.25941],
            [127.38998, 36.25964],
            [127.38998, 36.2598],
            [127.39006, 36.26042],
            [127.39034, 36.26094],
            [127.39023, 36.2613],
            [127.39022, 36.2619],
            [127.39016, 36.26228],
            [127.38973, 36.26274],
            [127.38955, 36.2631],
            [127.38827, 36.26412],
            [127.38819, 36.26427],
            [127.38676, 36.26534],
            [127.38628, 36.26605],
            [127.38574, 36.26624],
            [127.38566, 36.26584],
            [127.38491, 36.26626],
            [127.3844, 36.26666],
            [127.38373, 36.26741],
            [127.38336, 36.26797],
            [127.38331, 36.26853],
            [127.38292, 36.26857],
            [127.38285, 36.26872],
            [127.38083, 36.26848],
            [127.38084, 36.26948],
            [127.38084, 36.26953],
            [127.38015, 36.26967],
            [127.37982, 36.26961],
            [127.3796, 36.27013],
            [127.37891, 36.27054],
            [127.37866, 36.27075],
            [127.37761, 36.27147],
            [127.37733, 36.27146],
            [127.37693, 36.27132],
            [127.37632, 36.27163],
            [127.37505, 36.27199],
            [127.37417, 36.27191],
            [127.37324, 36.27221],
            [127.37246, 36.27221],
            [127.37246, 36.27275],
            [127.37247, 36.27292],
            [127.37262, 36.27307],
            [127.37287, 36.27422],
            [127.37301, 36.27456],
            [127.37228, 36.27499],
            [127.37202, 36.27563],
            [127.37129, 36.27624],
            [127.37141, 36.27662],
            [127.3715, 36.27685],
            [127.37118, 36.27749],
            [127.37154, 36.27796],
            [127.37161, 36.27875],
            [127.37152, 36.27883],
            [127.3715, 36.27923],
            [127.3715, 36.27947],
            [127.37125, 36.27958],
            [127.37085, 36.27959],
            [127.37065, 36.2803],
            [127.37023, 36.28042],
            [127.37008, 36.28065],
            [127.37011, 36.28116],
            [127.36947, 36.28167],
            [127.36932, 36.28186],
            [127.36933, 36.28261],
            [127.36926, 36.28299],
            [127.36924, 36.28304],
            [127.36865, 36.28316],
            [127.3682, 36.28375],
            [127.36814, 36.28428],
            [127.36809, 36.28445],
            [127.36803, 36.28449],
            [127.36759, 36.28468],
            [127.36751, 36.28486],
            [127.36787, 36.28541],
            [127.36778, 36.28589],
            [127.36788, 36.28596],
            [127.36805, 36.28596],
            [127.36804, 36.28588],
            [127.36907, 36.28583],
            [127.36948, 36.28614],
            [127.36968, 36.286],
            [127.36977, 36.28619],
            [127.37, 36.28642],
            [127.37016, 36.28681],
            [127.37024, 36.28714],
            [127.37013, 36.28742],
            [127.37022, 36.28762],
            [127.37005, 36.28792],
            [127.3701, 36.28808],
            [127.37033, 36.28838],
            [127.37063, 36.28842],
            [127.37048, 36.28866],
            [127.37055, 36.28892],
            [127.37025, 36.28917],
            [127.37014, 36.28918],
            [127.36991, 36.29007],
            [127.37041, 36.29032],
            [127.37073, 36.2903],
            [127.37107, 36.291],
            [127.37142, 36.29134],
            [127.3728, 36.29142],
            [127.37268, 36.29198],
            [127.37323, 36.29332],
            [127.37474, 36.29311],
            [127.37594, 36.29302],
            [127.37647, 36.29339],
            [127.37785, 36.29305],
            [127.37831, 36.29296],
            [127.37848, 36.29377],
            [127.3793, 36.29404],
            [127.37978, 36.29445],
            [127.38014, 36.29481],
            [127.38048, 36.29519],
            [127.38069, 36.29545],
            [127.38106, 36.29607],
            [127.38188, 36.29735],
            [127.38203, 36.29777],
            [127.38213, 36.29825],
            [127.38216, 36.2986],
            [127.38212, 36.29892],
            [127.38219, 36.29913],
            [127.38224, 36.29938],
            [127.38223, 36.29988],
            [127.38207, 36.30081],
            [127.38214, 36.30076],
            [127.38212, 36.30092],
            [127.38198, 36.30126],
            [127.38141, 36.30229],
            [127.38121, 36.30227],
            [127.38115, 36.30236],
            [127.38137, 36.30237],
            [127.38104, 36.30298],
            [127.38092, 36.30325],
            [127.38071, 36.30385],
            [127.38056, 36.30456],
            [127.38051, 36.30477],
            [127.38034, 36.30464],
            [127.38029, 36.30458],
            [127.38024, 36.30497],
            [127.38056, 36.30521],
            [127.38091, 36.30551],
            [127.38267, 36.30691],
            [127.38313, 36.30724],
            [127.38343, 36.30754],
            [127.38376, 36.30796],
            [127.38398, 36.30816],
            [127.38494, 36.30924],
            [127.38562, 36.30987],
            [127.38597, 36.31014],
            [127.38642, 36.31055],
            [127.38679, 36.31105],
            [127.38698, 36.31137],
            [127.38713, 36.31175],
            [127.38714, 36.3119],
            [127.38718, 36.31206],
            [127.38719, 36.31224],
            [127.38715, 36.31247],
            [127.38706, 36.3128],
            [127.38681, 36.31328],
            [127.38659, 36.31362],
            [127.38597, 36.31402],
            [127.38545, 36.31422],
            [127.38445, 36.31681],
            [127.38424, 36.31764],
            [127.38419, 36.318],
            [127.38417, 36.31844],
            [127.38419, 36.31875],
            [127.38422, 36.31897],
            [127.38469, 36.31969],
            [127.38503, 36.32005],
            [127.38518, 36.32054],
            [127.3853, 36.32115],
            [127.38539, 36.32171],
            [127.38544, 36.32216],
            [127.38541, 36.32225],
            [127.38536, 36.32233],
            [127.38541, 36.32242],
            [127.38565, 36.32257],
            [127.38574, 36.32259],
            [127.38731, 36.32272],
            [127.38802, 36.32282],
            [127.38822, 36.32322],
            [127.38843, 36.32384],
            [127.3885, 36.32418],
            [127.38822, 36.32527],
            [127.38818, 36.32572],
            [127.38818, 36.32617],
            [127.38819, 36.32631],
            [127.38861, 36.32689],
            [127.38881, 36.32731],
            [127.38902, 36.32781],
            [127.38927, 36.32823],
            [127.38969, 36.3287],
            [127.39006, 36.32903],
            [127.39061, 36.32941],
            [127.39178, 36.32976],
            [127.3924, 36.3305],
            [127.39259, 36.33078],
            [127.39266, 36.33108],
            [127.39259, 36.33126],
            [127.39366, 36.33212],
            [127.39368, 36.33202],
            [127.39387, 36.33201],
            [127.39403, 36.33207],
            [127.39392, 36.33218],
            [127.39392, 36.33233],
            [127.394, 36.33239],
            [127.39406, 36.33219],
            [127.39411, 36.3321],
            [127.39425, 36.3322],
            [127.39432, 36.33229],
            [127.39448, 36.33244],
            [127.39448, 36.33254],
            [127.39454, 36.33253],
            [127.39453, 36.33261],
            [127.3946, 36.33272],
            [127.39485, 36.33293],
            [127.39517, 36.33334],
            [127.3961, 36.33409],
            [127.39629, 36.33418],
            [127.39646, 36.33407],
            [127.39652, 36.33411],
            [127.39662, 36.3341],
            [127.39667, 36.33406],
            [127.39679, 36.33415],
            [127.39685, 36.33412],
            [127.3969, 36.33418],
            [127.39695, 36.33414],
            [127.39704, 36.33421],
            [127.39709, 36.33428],
            [127.39717, 36.33435],
            [127.3972, 36.33442],
            [127.39752, 36.33475],
            [127.39759, 36.33474],
            [127.39812, 36.33434],
            [127.39911, 36.33541],
            [127.39951, 36.33594],
            [127.39976, 36.33641],
            [127.39996, 36.3369],
            [127.4, 36.33694],
            [127.40035, 36.33726],
            [127.40179, 36.33835],
            [127.40287, 36.33836],
            [127.40315, 36.33917],
            [127.40351, 36.34046],
            [127.40366, 36.34111],
            [127.40385, 36.34218],
            [127.40325, 36.34371],
            [127.40312, 36.34414],
            [127.40298, 36.34468],
            [127.40281, 36.34551],
            [127.40275, 36.34597],
            [127.40262, 36.34621],
            [127.40274, 36.34637],
            [127.40322, 36.34692],
            [127.40344, 36.34733],
            [127.40355, 36.34774],
            [127.40373, 36.3483],
            [127.40381, 36.34872],
            [127.40391, 36.34963],
            [127.40466, 36.34938],
            [127.40599, 36.3489],
            [127.40841, 36.34828],
            [127.4091, 36.34796]
          ]
        ]
      }
    },
    {
      id: '30170',
      type: 'Feature',
      properties: {
        SEC_SGG_CD: '30170',
        SEC_SGG_NM: ['대전광역시', '서구'],
        COL_SGG_CD: '30000',
        SEC_SGG_SIZE: '2',
        bbox: [127.27903, 36.18314, 127.40461, 36.37304],
        center: [127.34513, 36.28028],
        area: 95261425
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [127.39296, 36.37169],
            [127.39313, 36.37122],
            [127.39333, 36.37082],
            [127.3935, 36.37062],
            [127.39375, 36.37035],
            [127.39462, 36.3697],
            [127.39543, 36.36919],
            [127.39571, 36.36885],
            [127.39637, 36.36782],
            [127.39654, 36.36753],
            [127.39698, 36.3667],
            [127.39722, 36.36612],
            [127.39728, 36.36579],
            [127.39725, 36.36547],
            [127.39724, 36.36502],
            [127.39732, 36.36461],
            [127.39747, 36.36413],
            [127.39802, 36.36339],
            [127.39847, 36.36285],
            [127.39932, 36.36218],
            [127.4002, 36.36171],
            [127.40075, 36.36149],
            [127.40114, 36.36137],
            [127.40177, 36.35973],
            [127.40187, 36.35951],
            [127.40196, 36.35922],
            [127.40229, 36.35855],
            [127.40248, 36.35824],
            [127.40248, 36.35811],
            [127.40311, 36.35675],
            [127.40316, 36.3566],
            [127.40321, 36.35651],
            [127.40328, 36.35601],
            [127.40376, 36.35505],
            [127.40385, 36.35476],
            [127.40461, 36.35327],
            [127.4045, 36.35291],
            [127.40396, 36.35014],
            [127.40393, 36.34988],
            [127.40381, 36.34872],
            [127.40373, 36.3483],
            [127.40355, 36.34774],
            [127.40344, 36.34733],
            [127.40322, 36.34692],
            [127.40274, 36.34637],
            [127.40262, 36.34621],
            [127.40275, 36.34597],
            [127.40281, 36.34551],
            [127.40298, 36.34468],
            [127.40312, 36.34414],
            [127.40325, 36.34371],
            [127.40385, 36.34218],
            [127.40366, 36.34111],
            [127.40337, 36.33993],
            [127.40315, 36.33917],
            [127.40287, 36.33836],
            [127.40179, 36.33835],
            [127.40035, 36.33726],
            [127.4, 36.33694],
            [127.39996, 36.3369],
            [127.39976, 36.33641],
            [127.39951, 36.33594],
            [127.39911, 36.33541],
            [127.39812, 36.33434],
            [127.39759, 36.33474],
            [127.39752, 36.33475],
            [127.3972, 36.33442],
            [127.39717, 36.33435],
            [127.39709, 36.33428],
            [127.39704, 36.33421],
            [127.39695, 36.33414],
            [127.3969, 36.33418],
            [127.39685, 36.33412],
            [127.39679, 36.33415],
            [127.39667, 36.33406],
            [127.39662, 36.3341],
            [127.39652, 36.33411],
            [127.39646, 36.33407],
            [127.39629, 36.33418],
            [127.3961, 36.33409],
            [127.39517, 36.33334],
            [127.39485, 36.33293],
            [127.3946, 36.33272],
            [127.39453, 36.33261],
            [127.39454, 36.33253],
            [127.39448, 36.33254],
            [127.39448, 36.33244],
            [127.39432, 36.33229],
            [127.39425, 36.3322],
            [127.39411, 36.3321],
            [127.39406, 36.33219],
            [127.394, 36.33239],
            [127.39392, 36.33233],
            [127.39392, 36.33218],
            [127.39403, 36.33207],
            [127.39387, 36.33201],
            [127.39368, 36.33202],
            [127.39366, 36.33212],
            [127.39259, 36.33126],
            [127.39266, 36.33108],
            [127.39259, 36.33078],
            [127.3924, 36.3305],
            [127.39178, 36.32976],
            [127.39061, 36.32941],
            [127.39006, 36.32903],
            [127.38969, 36.3287],
            [127.38927, 36.32823],
            [127.38902, 36.32781],
            [127.38881, 36.32731],
            [127.38861, 36.32689],
            [127.38819, 36.32631],
            [127.38818, 36.32617],
            [127.38818, 36.32572],
            [127.38822, 36.32527],
            [127.3885, 36.32418],
            [127.38843, 36.32384],
            [127.38822, 36.32322],
            [127.38802, 36.32282],
            [127.38731, 36.32272],
            [127.38574, 36.32259],
            [127.38565, 36.32257],
            [127.38541, 36.32242],
            [127.38536, 36.32233],
            [127.38541, 36.32225],
            [127.38544, 36.32216],
            [127.38539, 36.32171],
            [127.3853, 36.32115],
            [127.38518, 36.32054],
            [127.38503, 36.32005],
            [127.38469, 36.31969],
            [127.38422, 36.31897],
            [127.38419, 36.31875],
            [127.38417, 36.31844],
            [127.38419, 36.318],
            [127.38424, 36.31764],
            [127.38445, 36.31681],
            [127.38545, 36.31422],
            [127.38597, 36.31402],
            [127.38659, 36.31362],
            [127.38681, 36.31328],
            [127.38706, 36.3128],
            [127.38715, 36.31247],
            [127.38719, 36.31224],
            [127.38718, 36.31206],
            [127.38714, 36.3119],
            [127.38713, 36.31175],
            [127.38698, 36.31137],
            [127.38679, 36.31105],
            [127.38642, 36.31055],
            [127.38597, 36.31014],
            [127.38562, 36.30987],
            [127.38494, 36.30924],
            [127.38398, 36.30816],
            [127.38376, 36.30796],
            [127.38343, 36.30754],
            [127.38313, 36.30724],
            [127.38267, 36.30691],
            [127.38091, 36.30551],
            [127.38056, 36.30521],
            [127.38024, 36.30497],
            [127.38029, 36.30458],
            [127.38034, 36.30464],
            [127.38051, 36.30477],
            [127.38056, 36.30456],
            [127.38071, 36.30385],
            [127.38092, 36.30325],
            [127.38104, 36.30298],
            [127.38137, 36.30237],
            [127.38115, 36.30236],
            [127.38121, 36.30227],
            [127.38141, 36.30229],
            [127.38198, 36.30126],
            [127.38212, 36.30092],
            [127.38214, 36.30076],
            [127.38207, 36.30081],
            [127.38223, 36.29988],
            [127.38224, 36.29938],
            [127.38219, 36.29913],
            [127.38212, 36.29892],
            [127.38216, 36.2986],
            [127.38213, 36.29825],
            [127.38203, 36.29777],
            [127.38188, 36.29735],
            [127.38106, 36.29607],
            [127.38069, 36.29545],
            [127.38048, 36.29519],
            [127.38014, 36.29481],
            [127.37978, 36.29445],
            [127.3793, 36.29404],
            [127.37848, 36.29377],
            [127.37831, 36.29296],
            [127.37785, 36.29305],
            [127.37647, 36.29339],
            [127.37594, 36.29302],
            [127.37474, 36.29311],
            [127.37323, 36.29332],
            [127.37268, 36.29198],
            [127.3728, 36.29142],
            [127.37142, 36.29134],
            [127.37107, 36.291],
            [127.37073, 36.2903],
            [127.37041, 36.29032],
            [127.36991, 36.29007],
            [127.37014, 36.28918],
            [127.37025, 36.28917],
            [127.37055, 36.28892],
            [127.37048, 36.28866],
            [127.37063, 36.28842],
            [127.37033, 36.28838],
            [127.3701, 36.28808],
            [127.37005, 36.28792],
            [127.37022, 36.28762],
            [127.37013, 36.28742],
            [127.37024, 36.28714],
            [127.37016, 36.28681],
            [127.37, 36.28642],
            [127.36977, 36.28619],
            [127.36968, 36.286],
            [127.36948, 36.28614],
            [127.36907, 36.28583],
            [127.36804, 36.28588],
            [127.36805, 36.28596],
            [127.36788, 36.28596],
            [127.36778, 36.28589],
            [127.36787, 36.28541],
            [127.36751, 36.28486],
            [127.36759, 36.28468],
            [127.36803, 36.28449],
            [127.36809, 36.28445],
            [127.36814, 36.28428],
            [127.3682, 36.28375],
            [127.36865, 36.28316],
            [127.36924, 36.28304],
            [127.36926, 36.28299],
            [127.36933, 36.28261],
            [127.36932, 36.28186],
            [127.36947, 36.28167],
            [127.37015, 36.2811],
            [127.37008, 36.28065],
            [127.37023, 36.28042],
            [127.37068, 36.28026],
            [127.37085, 36.27959],
            [127.37125, 36.27958],
            [127.3715, 36.27947],
            [127.3715, 36.27923],
            [127.37152, 36.27883],
            [127.37161, 36.27875],
            [127.37154, 36.27796],
            [127.37118, 36.27749],
            [127.3715, 36.27685],
            [127.37141, 36.27662],
            [127.37129, 36.27624],
            [127.37202, 36.27563],
            [127.37228, 36.27499],
            [127.37301, 36.27456],
            [127.37297, 36.27444],
            [127.37287, 36.27422],
            [127.37262, 36.27307],
            [127.37247, 36.27292],
            [127.37246, 36.27275],
            [127.37246, 36.27221],
            [127.372, 36.27185],
            [127.37191, 36.27113],
            [127.37167, 36.27024],
            [127.37122, 36.27014],
            [127.37114, 36.26989],
            [127.37054, 36.2698],
            [127.37018, 36.26978],
            [127.36978, 36.26955],
            [127.36941, 36.26954],
            [127.36889, 36.26984],
            [127.36853, 36.26924],
            [127.36802, 36.26936],
            [127.3676, 36.26932],
            [127.36687, 36.26882],
            [127.36657, 36.26876],
            [127.36614, 36.26891],
            [127.36573, 36.26916],
            [127.36525, 36.26931],
            [127.36464, 36.26925],
            [127.36396, 36.26943],
            [127.36355, 36.26984],
            [127.36278, 36.26926],
            [127.36304, 36.26897],
            [127.36318, 36.26866],
            [127.36297, 36.268],
            [127.36264, 36.26768],
            [127.36214, 36.26745],
            [127.36173, 36.26704],
            [127.3618, 36.26676],
            [127.36164, 36.26648],
            [127.36166, 36.26631],
            [127.36188, 36.26615],
            [127.36179, 36.26559],
            [127.36164, 36.26544],
            [127.36136, 36.26531],
            [127.36143, 36.2651],
            [127.36136, 36.26493],
            [127.36102, 36.26471],
            [127.36094, 36.26449],
            [127.36066, 36.26414],
            [127.36027, 36.26406],
            [127.35942, 36.26261],
            [127.36005, 36.26226],
            [127.36008, 36.26189],
            [127.36021, 36.26147],
            [127.36044, 36.26088],
            [127.36035, 36.26015],
            [127.35998, 36.25984],
            [127.36018, 36.25921],
            [127.36024, 36.25875],
            [127.36074, 36.25837],
            [127.36086, 36.25792],
            [127.36083, 36.25739],
            [127.36073, 36.25709],
            [127.36061, 36.25695],
            [127.36055, 36.25674],
            [127.36083, 36.25642],
            [127.36073, 36.25623],
            [127.36038, 36.25589],
            [127.36045, 36.25541],
            [127.36034, 36.25516],
            [127.35971, 36.25487],
            [127.35958, 36.25468],
            [127.3597, 36.25458],
            [127.35974, 36.25451],
            [127.35981, 36.25405],
            [127.36005, 36.25377],
            [127.36033, 36.25379],
            [127.36087, 36.25376],
            [127.36156, 36.25333],
            [127.36148, 36.25312],
            [127.36203, 36.25255],
            [127.36271, 36.25233],
            [127.36305, 36.25241],
            [127.36321, 36.25222],
            [127.36331, 36.25195],
            [127.36417, 36.25154],
            [127.36367, 36.25087],
            [127.36387, 36.2504],
            [127.36431, 36.24976],
            [127.36427, 36.24865],
            [127.36447, 36.24822],
            [127.36458, 36.24727],
            [127.36431, 36.24667],
            [127.36455, 36.24628],
            [127.36444, 36.24578],
            [127.3645, 36.2456],
            [127.36459, 36.24498],
            [127.36447, 36.24475],
            [127.36489, 36.24418],
            [127.36517, 36.24359],
            [127.36405, 36.24254],
            [127.36382, 36.24155],
            [127.3639, 36.24129],
            [127.36417, 36.24082],
            [127.36378, 36.24055],
            [127.36334, 36.24055],
            [127.36286, 36.24035],
            [127.36362, 36.23867],
            [127.36315, 36.23859],
            [127.36308, 36.23844],
            [127.36301, 36.23806],
            [127.36265, 36.23753],
            [127.36288, 36.23706],
            [127.36256, 36.23655],
            [127.36214, 36.23636],
            [127.36211, 36.2361],
            [127.36203, 36.23563],
            [127.36119, 36.2351],
            [127.36092, 36.2346],
            [127.3609, 36.23435],
            [127.36115, 36.23441],
            [127.36128, 36.23443],
            [127.36193, 36.23416],
            [127.36225, 36.23392],
            [127.36244, 36.23344],
            [127.36343, 36.23225],
            [127.36275, 36.23169],
            [127.36248, 36.23123],
            [127.36253, 36.23093],
            [127.36203, 36.23015],
            [127.36212, 36.22957],
            [127.36198, 36.22898],
            [127.3622, 36.22844],
            [127.36228, 36.22829],
            [127.36163, 36.22762],
            [127.36125, 36.22754],
            [127.36108, 36.22749],
            [127.36065, 36.22704],
            [127.3601, 36.22664],
            [127.36015, 36.2263],
            [127.36035, 36.22585],
            [127.36033, 36.22548],
            [127.36137, 36.22474],
            [127.36188, 36.22441],
            [127.36212, 36.22428],
            [127.36235, 36.22388],
            [127.36298, 36.22361],
            [127.36324, 36.22334],
            [127.36356, 36.22283],
            [127.36402, 36.22175],
            [127.36455, 36.22003],
            [127.36452, 36.21981],
            [127.36433, 36.21953],
            [127.3642, 36.21891],
            [127.36393, 36.21885],
            [127.36353, 36.21868],
            [127.36314, 36.21877],
            [127.36289, 36.21865],
            [127.36225, 36.21863],
            [127.36168, 36.21838],
            [127.36146, 36.21838],
            [127.361, 36.21856],
            [127.36067, 36.21851],
            [127.36041, 36.21825],
            [127.36031, 36.2179],
            [127.36019, 36.21736],
            [127.35981, 36.21664],
            [127.35965, 36.21573],
            [127.35961, 36.21481],
            [127.35967, 36.21456],
            [127.35943, 36.21393],
            [127.35848, 36.21375],
            [127.35722, 36.21314],
            [127.35723, 36.21262],
            [127.35713, 36.21248],
            [127.35668, 36.21233],
            [127.35632, 36.21231],
            [127.35558, 36.21289],
            [127.35521, 36.21284],
            [127.35483, 36.21272],
            [127.35419, 36.2123],
            [127.3539, 36.21223],
            [127.35299, 36.21183],
            [127.35299, 36.21177],
            [127.35291, 36.21173],
            [127.35305, 36.21104],
            [127.35273, 36.21054],
            [127.35185, 36.20997],
            [127.35168, 36.20943],
            [127.35115, 36.20918],
            [127.34948, 36.20801],
            [127.34932, 36.20745],
            [127.34906, 36.2071],
            [127.34853, 36.20621],
            [127.3478, 36.20547],
            [127.34726, 36.20456],
            [127.34687, 36.20409],
            [127.34668, 36.20323],
            [127.34688, 36.20221],
            [127.34745, 36.20206],
            [127.34755, 36.20191],
            [127.34774, 36.20097],
            [127.34741, 36.20012],
            [127.3474, 36.19995],
            [127.34761, 36.1996],
            [127.34765, 36.19847],
            [127.34785, 36.19824],
            [127.34754, 36.19753],
            [127.34707, 36.19733],
            [127.34547, 36.19677],
            [127.34531, 36.19673],
            [127.34416, 36.19664],
            [127.34394, 36.1966],
            [127.34388, 36.19594],
            [127.344, 36.19581],
            [127.34381, 36.19542],
            [127.34381, 36.19499],
            [127.34376, 36.19453],
            [127.34278, 36.19404],
            [127.34199, 36.19334],
            [127.34205, 36.19296],
            [127.34158, 36.19242],
            [127.34157, 36.19201],
            [127.34147, 36.19146],
            [127.34139, 36.19091],
            [127.3412, 36.19032],
            [127.34123, 36.18998],
            [127.34105, 36.18941],
            [127.34063, 36.1889],
            [127.34028, 36.18888],
            [127.33943, 36.18904],
            [127.33911, 36.18922],
            [127.33812, 36.18905],
            [127.33815, 36.18838],
            [127.33788, 36.18757],
            [127.33803, 36.18727],
            [127.33802, 36.18687],
            [127.33792, 36.1863],
            [127.33754, 36.18558],
            [127.33703, 36.18518],
            [127.33686, 36.18523],
            [127.33627, 36.18478],
            [127.33601, 36.18425],
            [127.33549, 36.18337],
            [127.33477, 36.18329],
            [127.33434, 36.18316],
            [127.33399, 36.18314],
            [127.33367, 36.18329],
            [127.33306, 36.18346],
            [127.33265, 36.18355],
            [127.33175, 36.18412],
            [127.33194, 36.18466],
            [127.33187, 36.18554],
            [127.33194, 36.18591],
            [127.33197, 36.18641],
            [127.33197, 36.18796],
            [127.33154, 36.18879],
            [127.33153, 36.18986],
            [127.33108, 36.19021],
            [127.33111, 36.19057],
            [127.33165, 36.19119],
            [127.33158, 36.19174],
            [127.33173, 36.19269],
            [127.33165, 36.19303],
            [127.33101, 36.19343],
            [127.3308, 36.19352],
            [127.33029, 36.19368],
            [127.33004, 36.19387],
            [127.32986, 36.1942],
            [127.33077, 36.19473],
            [127.33073, 36.19508],
            [127.33094, 36.19573],
            [127.33213, 36.1962],
            [127.33218, 36.197],
            [127.33205, 36.19795],
            [127.33185, 36.19843],
            [127.33179, 36.19874],
            [127.33185, 36.19881],
            [127.33256, 36.19925],
            [127.33347, 36.19974],
            [127.33357, 36.19988],
            [127.33344, 36.20015],
            [127.33351, 36.2007],
            [127.33256, 36.20108],
            [127.33199, 36.20158],
            [127.33135, 36.20143],
            [127.33087, 36.20148],
            [127.33037, 36.20164],
            [127.33007, 36.20188],
            [127.32979, 36.20195],
            [127.32975, 36.20233],
            [127.32971, 36.20244],
            [127.32941, 36.20305],
            [127.329, 36.20337],
            [127.32814, 36.2042],
            [127.32705, 36.20389],
            [127.3259, 36.20368],
            [127.3257, 36.20372],
            [127.32562, 36.20368],
            [127.32502, 36.20349],
            [127.32473, 36.20337],
            [127.3245, 36.2034],
            [127.32432, 36.20339],
            [127.32378, 36.20312],
            [127.32352, 36.20336],
            [127.32317, 36.20353],
            [127.32305, 36.20375],
            [127.32302, 36.20418],
            [127.32287, 36.20469],
            [127.32305, 36.20526],
            [127.32295, 36.20574],
            [127.32268, 36.20639],
            [127.32226, 36.20668],
            [127.32192, 36.20711],
            [127.32178, 36.20794],
            [127.32131, 36.20847],
            [127.32075, 36.20887],
            [127.32096, 36.20974],
            [127.32084, 36.21038],
            [127.32103, 36.2106],
            [127.32094, 36.21107],
            [127.32049, 36.21157],
            [127.3205, 36.21192],
            [127.3206, 36.21216],
            [127.32143, 36.21288],
            [127.32161, 36.21357],
            [127.32151, 36.21358],
            [127.32142, 36.21371],
            [127.32096, 36.21429],
            [127.32019, 36.21462],
            [127.32003, 36.21473],
            [127.31979, 36.21517],
            [127.31957, 36.21554],
            [127.3188, 36.21569],
            [127.31854, 36.21571],
            [127.3181, 36.216],
            [127.31763, 36.2171],
            [127.31711, 36.21775],
            [127.317, 36.21795],
            [127.31682, 36.21815],
            [127.3169, 36.21841],
            [127.31644, 36.21932],
            [127.31641, 36.21958],
            [127.31605, 36.22001],
            [127.31597, 36.2203],
            [127.31566, 36.22091],
            [127.31523, 36.22147],
            [127.31469, 36.2217],
            [127.31434, 36.22204],
            [127.3143, 36.22219],
            [127.31447, 36.22248],
            [127.31368, 36.22243],
            [127.31334, 36.22247],
            [127.31318, 36.22273],
            [127.31275, 36.22299],
            [127.31226, 36.22295],
            [127.31142, 36.22313],
            [127.31142, 36.22318],
            [127.31131, 36.22336],
            [127.31044, 36.22404],
            [127.30955, 36.22463],
            [127.30915, 36.22479],
            [127.30873, 36.22479],
            [127.30809, 36.22514],
            [127.30777, 36.22544],
            [127.30747, 36.22553],
            [127.30706, 36.22628],
            [127.30704, 36.22633],
            [127.30664, 36.22598],
            [127.30638, 36.22614],
            [127.30605, 36.22677],
            [127.3057, 36.22713],
            [127.3057, 36.22731],
            [127.30525, 36.22795],
            [127.30498, 36.22837],
            [127.30482, 36.22869],
            [127.30439, 36.22884],
            [127.30394, 36.22901],
            [127.30379, 36.22916],
            [127.30424, 36.22966],
            [127.30348, 36.22976],
            [127.30336, 36.22908],
            [127.30339, 36.2286],
            [127.30331, 36.22816],
            [127.30318, 36.22786],
            [127.30298, 36.22756],
            [127.30282, 36.22726],
            [127.30279, 36.22708],
            [127.30278, 36.22693],
            [127.30286, 36.22653],
            [127.303, 36.22613],
            [127.3034, 36.22548],
            [127.3036, 36.22449],
            [127.30362, 36.22398],
            [127.3037, 36.22324],
            [127.30342, 36.22286],
            [127.30306, 36.22242],
            [127.30255, 36.22188],
            [127.30164, 36.22154],
            [127.30109, 36.22136],
            [127.30048, 36.22139],
            [127.29984, 36.22139],
            [127.29924, 36.22135],
            [127.29886, 36.22128],
            [127.29825, 36.22132],
            [127.29732, 36.22153],
            [127.29708, 36.22174],
            [127.29688, 36.22189],
            [127.29676, 36.22202],
            [127.29619, 36.2222],
            [127.29611, 36.22221],
            [127.29606, 36.22219],
            [127.29585, 36.22222],
            [127.29575, 36.22227],
            [127.29554, 36.2225],
            [127.29519, 36.22262],
            [127.29487, 36.22276],
            [127.2946, 36.22298],
            [127.29436, 36.22304],
            [127.2941, 36.22303],
            [127.29374, 36.22313],
            [127.29358, 36.22312],
            [127.29357, 36.22351],
            [127.29358, 36.22367],
            [127.29356, 36.22377],
            [127.2935, 36.22397],
            [127.29334, 36.22415],
            [127.29338, 36.22422],
            [127.29343, 36.22425],
            [127.29346, 36.22435],
            [127.29342, 36.22455],
            [127.29335, 36.22469],
            [127.29327, 36.22477],
            [127.29321, 36.22495],
            [127.2931, 36.22506],
            [127.29306, 36.22542],
            [127.29301, 36.2255],
            [127.29295, 36.22549],
            [127.29292, 36.22556],
            [127.29282, 36.22569],
            [127.29276, 36.22586],
            [127.29259, 36.22606],
            [127.29253, 36.22609],
            [127.29238, 36.22622],
            [127.29211, 36.22627],
            [127.29205, 36.22635],
            [127.29201, 36.22649],
            [127.29193, 36.22657],
            [127.29186, 36.22657],
            [127.29171, 36.22655],
            [127.29165, 36.22657],
            [127.29163, 36.22662],
            [127.29164, 36.22671],
            [127.29165, 36.22679],
            [127.29186, 36.22689],
            [127.29205, 36.22692],
            [127.29221, 36.22698],
            [127.29232, 36.22708],
            [127.2923, 36.22713],
            [127.29242, 36.22727],
            [127.29266, 36.22742],
            [127.29279, 36.22742],
            [127.29289, 36.22761],
            [127.29293, 36.22776],
            [127.29302, 36.22792],
            [127.29313, 36.22816],
            [127.29316, 36.22828],
            [127.29313, 36.22845],
            [127.29308, 36.22849],
            [127.293, 36.22848],
            [127.29289, 36.22858],
            [127.29289, 36.22864],
            [127.29296, 36.22878],
            [127.29299, 36.22888],
            [127.29299, 36.22893],
            [127.29285, 36.22908],
            [127.2926, 36.22927],
            [127.29247, 36.22958],
            [127.2923, 36.2298],
            [127.29193, 36.22997],
            [127.29181, 36.22997],
            [127.29143, 36.23019],
            [127.29136, 36.23019],
            [127.29128, 36.23021],
            [127.29119, 36.2303],
            [127.29105, 36.23038],
            [127.2908, 36.23059],
            [127.29046, 36.23073],
            [127.29034, 36.23074],
            [127.29024, 36.2308],
            [127.29006, 36.23082],
            [127.2898, 36.23105],
            [127.28969, 36.23123],
            [127.28958, 36.23139],
            [127.2895, 36.23142],
            [127.28927, 36.23148],
            [127.2892, 36.23158],
            [127.28918, 36.23165],
            [127.28913, 36.23168],
            [127.28905, 36.23182],
            [127.28897, 36.23189],
            [127.28892, 36.23197],
            [127.28891, 36.23233],
            [127.28884, 36.23243],
            [127.28879, 36.2326],
            [127.28875, 36.23265],
            [127.2887, 36.23268],
            [127.28858, 36.23271],
            [127.28849, 36.23278],
            [127.2884, 36.23281],
            [127.28825, 36.23283],
            [127.28819, 36.23287],
            [127.28812, 36.23302],
            [127.28817, 36.23313],
            [127.28817, 36.23325],
            [127.28807, 36.23336],
            [127.28806, 36.23343],
            [127.28784, 36.23372],
            [127.28776, 36.23393],
            [127.28761, 36.23402],
            [127.28741, 36.23418],
            [127.28735, 36.23445],
            [127.2873, 36.23462],
            [127.28727, 36.23469],
            [127.28697, 36.23459],
            [127.28669, 36.23463],
            [127.28632, 36.23459],
            [127.28626, 36.23457],
            [127.28618, 36.23446],
            [127.28606, 36.23445],
            [127.28597, 36.23452],
            [127.28581, 36.23453],
            [127.28564, 36.23468],
            [127.28544, 36.23478],
            [127.2853, 36.23489],
            [127.28524, 36.2349],
            [127.28515, 36.2349],
            [127.28506, 36.23494],
            [127.28467, 36.23488],
            [127.28459, 36.23492],
            [127.28438, 36.23497],
            [127.2843, 36.23498],
            [127.28419, 36.23498],
            [127.28398, 36.23504],
            [127.28379, 36.23513],
            [127.2836, 36.23517],
            [127.28332, 36.23527],
            [127.28311, 36.23532],
            [127.28303, 36.23537],
            [127.28297, 36.23543],
            [127.28291, 36.23568],
            [127.28294, 36.23578],
            [127.28284, 36.23581],
            [127.28276, 36.2358],
            [127.28263, 36.23573],
            [127.28239, 36.23572],
            [127.28215, 36.23566],
            [127.28194, 36.23569],
            [127.28185, 36.23581],
            [127.28183, 36.23586],
            [127.28185, 36.23594],
            [127.2819, 36.23596],
            [127.28206, 36.236],
            [127.28216, 36.23607],
            [127.28275, 36.23648],
            [127.28294, 36.23656],
            [127.28329, 36.23687],
            [127.28312, 36.23704],
            [127.28296, 36.23745],
            [127.2832, 36.23779],
            [127.283, 36.2381],
            [127.28312, 36.2388],
            [127.28281, 36.23891],
            [127.28262, 36.23921],
            [127.28233, 36.23961],
            [127.28212, 36.23981],
            [127.28212, 36.23995],
            [127.28205, 36.2408],
            [127.2818, 36.24141],
            [127.28243, 36.24193],
            [127.28158, 36.24259],
            [127.28071, 36.24314],
            [127.28075, 36.24349],
            [127.28028, 36.24434],
            [127.28052, 36.24452],
            [127.28113, 36.24524],
            [127.28134, 36.24553],
            [127.28143, 36.24562],
            [127.28134, 36.24576],
            [127.28128, 36.24582],
            [127.28116, 36.24586],
            [127.2811, 36.24593],
            [127.28108, 36.24602],
            [127.28111, 36.24629],
            [127.28105, 36.24648],
            [127.28098, 36.24661],
            [127.28077, 36.24674],
            [127.28068, 36.2467],
            [127.28044, 36.24672],
            [127.28034, 36.24673],
            [127.28015, 36.2468],
            [127.28013, 36.24685],
            [127.28, 36.24694],
            [127.27993, 36.24702],
            [127.27989, 36.24714],
            [127.27993, 36.24728],
            [127.28024, 36.24789],
            [127.28043, 36.24817],
            [127.28096, 36.2485],
            [127.28112, 36.24867],
            [127.28115, 36.24881],
            [127.2811, 36.24894],
            [127.28057, 36.24916],
            [127.28038, 36.24928],
            [127.28031, 36.24953],
            [127.28029, 36.24984],
            [127.28031, 36.25016],
            [127.28018, 36.25049],
            [127.27991, 36.25077],
            [127.2799, 36.25134],
            [127.27989, 36.25139],
            [127.27986, 36.25153],
            [127.27979, 36.25179],
            [127.27968, 36.25204],
            [127.27966, 36.25215],
            [127.27955, 36.25233],
            [127.27925, 36.25253],
            [127.2791, 36.2526],
            [127.27903, 36.25278],
            [127.27913, 36.25311],
            [127.2797, 36.25334],
            [127.28018, 36.25356],
            [127.28072, 36.2539],
            [127.28081, 36.25398],
            [127.28123, 36.25437],
            [127.28184, 36.255],
            [127.28211, 36.25543],
            [127.28239, 36.25577],
            [127.28251, 36.25646],
            [127.28276, 36.25625],
            [127.28254, 36.25653],
            [127.28248, 36.25669],
            [127.28224, 36.25694],
            [127.28214, 36.25725],
            [127.28216, 36.25773],
            [127.28222, 36.25794],
            [127.28247, 36.25832],
            [127.28267, 36.25853],
            [127.28297, 36.2588],
            [127.28333, 36.25909],
            [127.28374, 36.25927],
            [127.28455, 36.2599],
            [127.28477, 36.25998],
            [127.2849, 36.25972],
            [127.28541, 36.26006],
            [127.28556, 36.26014],
            [127.28591, 36.26029],
            [127.28643, 36.26043],
            [127.28648, 36.26056],
            [127.28663, 36.26078],
            [127.28721, 36.26117],
            [127.28736, 36.26124],
            [127.2876, 36.2613],
            [127.28789, 36.26142],
            [127.28817, 36.26149],
            [127.28927, 36.26195],
            [127.28986, 36.26223],
            [127.29012, 36.26238],
            [127.29069, 36.26278],
            [127.29103, 36.26304],
            [127.29135, 36.26317],
            [127.29188, 36.26355],
            [127.29193, 36.26423],
            [127.29239, 36.26428],
            [127.29251, 36.26423],
            [127.29286, 36.26418],
            [127.2933, 36.26416],
            [127.2935, 36.26504],
            [127.294, 36.26571],
            [127.29571, 36.26745],
            [127.29668, 36.26723],
            [127.29674, 36.26727],
            [127.29732, 36.26801],
            [127.29776, 36.26839],
            [127.29788, 36.26863],
            [127.29888, 36.26938],
            [127.29941, 36.26957],
            [127.30044, 36.27005],
            [127.30075, 36.27032],
            [127.30121, 36.27035],
            [127.30146, 36.2705],
            [127.302, 36.27054],
            [127.30253, 36.2706],
            [127.30295, 36.27071],
            [127.30451, 36.27134],
            [127.30506, 36.27151],
            [127.30552, 36.27178],
            [127.30651, 36.27216],
            [127.3067, 36.27232],
            [127.30787, 36.27246],
            [127.30826, 36.27217],
            [127.30891, 36.27205],
            [127.31051, 36.27244],
            [127.31003, 36.27297],
            [127.30988, 36.27374],
            [127.30991, 36.27486],
            [127.3099, 36.27526],
            [127.30932, 36.27538],
            [127.30911, 36.27555],
            [127.30891, 36.27587],
            [127.30886, 36.27593],
            [127.30869, 36.27638],
            [127.30865, 36.27678],
            [127.30897, 36.27692],
            [127.30902, 36.27729],
            [127.30939, 36.27735],
            [127.30972, 36.27737],
            [127.30987, 36.27763],
            [127.31028, 36.27777],
            [127.3105, 36.27815],
            [127.31027, 36.27881],
            [127.31075, 36.27979],
            [127.31067, 36.28054],
            [127.31092, 36.28104],
            [127.31105, 36.28201],
            [127.3113, 36.28251],
            [127.31219, 36.28251],
            [127.3124, 36.28249],
            [127.31358, 36.28252],
            [127.31372, 36.28317],
            [127.31405, 36.28426],
            [127.31442, 36.28528],
            [127.31439, 36.28574],
            [127.3154, 36.28573],
            [127.31562, 36.28575],
            [127.31587, 36.28573],
            [127.31603, 36.28568],
            [127.31617, 36.28568],
            [127.31654, 36.2857],
            [127.31669, 36.28573],
            [127.31685, 36.28578],
            [127.31701, 36.28582],
            [127.31715, 36.28582],
            [127.31729, 36.28585],
            [127.31769, 36.28599],
            [127.31824, 36.28628],
            [127.31899, 36.28678],
            [127.31916, 36.28696],
            [127.31986, 36.288],
            [127.31997, 36.28839],
            [127.32004, 36.28903],
            [127.32006, 36.28969],
            [127.32005, 36.29013],
            [127.31995, 36.29086],
            [127.31996, 36.29095],
            [127.31996, 36.29132],
            [127.31998, 36.29153],
            [127.32006, 36.2919],
            [127.32026, 36.29249],
            [127.32033, 36.29287],
            [127.32055, 36.29343],
            [127.3209, 36.29438],
            [127.321, 36.2944],
            [127.32201, 36.29684],
            [127.3222, 36.29717],
            [127.32253, 36.29755],
            [127.32377, 36.29888],
            [127.32404, 36.29912],
            [127.32424, 36.29929],
            [127.32447, 36.29944],
            [127.32474, 36.29959],
            [127.32626, 36.30016],
            [127.32637, 36.30021],
            [127.32662, 36.30026],
            [127.32677, 36.30035],
            [127.3269, 36.30036],
            [127.32702, 36.30038],
            [127.32728, 36.30047],
            [127.32769, 36.30067],
            [127.32788, 36.30068],
            [127.32808, 36.30077],
            [127.32849, 36.30093],
            [127.32927, 36.3012],
            [127.3303, 36.3015],
            [127.33061, 36.30157],
            [127.33125, 36.30161],
            [127.33248, 36.3016],
            [127.33249, 36.30222],
            [127.33248, 36.30243],
            [127.33249, 36.3037],
            [127.33251, 36.30377],
            [127.33249, 36.30488],
            [127.3326, 36.30493],
            [127.33261, 36.30788],
            [127.33305, 36.30787],
            [127.33307, 36.30817],
            [127.33287, 36.30817],
            [127.33312, 36.30854],
            [127.33333, 36.30906],
            [127.33362, 36.30903],
            [127.3337, 36.30899],
            [127.33385, 36.30895],
            [127.33394, 36.30932],
            [127.33385, 36.30945],
            [127.33447, 36.30945],
            [127.33446, 36.31003],
            [127.33464, 36.31037],
            [127.33492, 36.31044],
            [127.3353, 36.31078],
            [127.33558, 36.31078],
            [127.33562, 36.31092],
            [127.33585, 36.3115],
            [127.33592, 36.31146],
            [127.33609, 36.3115],
            [127.33623, 36.31168],
            [127.33621, 36.31205],
            [127.33658, 36.31225],
            [127.33704, 36.31225],
            [127.33752, 36.31296],
            [127.33748, 36.31345],
            [127.33774, 36.31345],
            [127.33904, 36.31325],
            [127.33921, 36.31401],
            [127.33937, 36.31433],
            [127.33984, 36.31448],
            [127.33994, 36.31458],
            [127.33994, 36.31466],
            [127.33942, 36.31559],
            [127.33939, 36.31582],
            [127.33957, 36.31691],
            [127.33967, 36.31721],
            [127.33988, 36.31771],
            [127.33779, 36.3178],
            [127.33744, 36.31899],
            [127.33777, 36.31922],
            [127.33753, 36.31998],
            [127.33738, 36.32065],
            [127.33703, 36.32141],
            [127.3368, 36.32196],
            [127.33664, 36.32268],
            [127.33676, 36.32325],
            [127.33675, 36.32386],
            [127.33716, 36.32467],
            [127.33729, 36.32517],
            [127.33729, 36.32522],
            [127.3371, 36.32567],
            [127.33702, 36.32632],
            [127.337, 36.32664],
            [127.33594, 36.32727],
            [127.33596, 36.32743],
            [127.33589, 36.32744],
            [127.33591, 36.33014],
            [127.33814, 36.33014],
            [127.33814, 36.33221],
            [127.35375, 36.33215],
            [127.35384, 36.33259],
            [127.35415, 36.33306],
            [127.35419, 36.33348],
            [127.35437, 36.33385],
            [127.3545, 36.33447],
            [127.35457, 36.33499],
            [127.35443, 36.33544],
            [127.35413, 36.33598],
            [127.35393, 36.33645],
            [127.35374, 36.33709],
            [127.35369, 36.33731],
            [127.35345, 36.33813],
            [127.35296, 36.33921],
            [127.3524, 36.3407],
            [127.35227, 36.34122],
            [127.35212, 36.34189],
            [127.35204, 36.34233],
            [127.35198, 36.34277],
            [127.3518, 36.34391],
            [127.35169, 36.3443],
            [127.35138, 36.34474],
            [127.35112, 36.34521],
            [127.35094, 36.34579],
            [127.3506, 36.34632],
            [127.35014, 36.34688],
            [127.34971, 36.34768],
            [127.34942, 36.34836],
            [127.34918, 36.34918],
            [127.3491, 36.34973],
            [127.34917, 36.35025],
            [127.34929, 36.35082],
            [127.34982, 36.35209],
            [127.35004, 36.35259],
            [127.3503, 36.35296],
            [127.35067, 36.35343],
            [127.35099, 36.35361],
            [127.35173, 36.35384],
            [127.3522, 36.35408],
            [127.35269, 36.3543],
            [127.35312, 36.3543],
            [127.35383, 36.35427],
            [127.3539, 36.35429],
            [127.35582, 36.35557],
            [127.35807, 36.35713],
            [127.35827, 36.35803],
            [127.35853, 36.3587],
            [127.35854, 36.35885],
            [127.36102, 36.36152],
            [127.36125, 36.36178],
            [127.36148, 36.36201],
            [127.36279, 36.36338],
            [127.36501, 36.36394],
            [127.36577, 36.3642],
            [127.36641, 36.36447],
            [127.36678, 36.36469],
            [127.36726, 36.36507],
            [127.36759, 36.36539],
            [127.36801, 36.36588],
            [127.36851, 36.36666],
            [127.36901, 36.36767],
            [127.36932, 36.36816],
            [127.36972, 36.36859],
            [127.3702, 36.36896],
            [127.37064, 36.36918],
            [127.37127, 36.36948],
            [127.37199, 36.36973],
            [127.37254, 36.37],
            [127.37304, 36.37028],
            [127.37339, 36.37051],
            [127.37371, 36.37075],
            [127.3741, 36.37115],
            [127.37474, 36.37151],
            [127.3754, 36.37168],
            [127.37606, 36.37182],
            [127.37622, 36.37181],
            [127.3771, 36.37196],
            [127.37862, 36.37204],
            [127.37919, 36.37214],
            [127.37968, 36.37211],
            [127.37991, 36.37199],
            [127.38007, 36.37152],
            [127.38205, 36.37148],
            [127.38576, 36.37149],
            [127.38646, 36.37144],
            [127.38818, 36.37125],
            [127.38883, 36.37117],
            [127.38912, 36.37117],
            [127.38989, 36.37114],
            [127.39057, 36.37122],
            [127.39087, 36.3713],
            [127.39119, 36.37143],
            [127.39149, 36.37149],
            [127.39187, 36.37154],
            [127.39215, 36.37156],
            [127.3926, 36.37152],
            [127.39274, 36.37148],
            [127.39247, 36.37201],
            [127.39246, 36.37208],
            [127.39274, 36.37266],
            [127.39298, 36.37301],
            [127.39322, 36.37302],
            [127.39295, 36.3726],
            [127.39285, 36.37217],
            [127.39296, 36.37169]
          ]
        ]
      }
    },
    {
      id: '30200',
      type: 'Feature',
      properties: {
        SEC_SGG_CD: '30200',
        SEC_SGG_NM: ['대전광역시', '유성구'],
        COL_SGG_CD: '30000',
        SEC_SGG_SIZE: '2',
        bbox: [127.24665, 36.26416, 127.42027, 36.50023],
        center: [127.33335, 36.37692],
        area: 176038765
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [127.38577, 36.49923],
            [127.38691, 36.49865],
            [127.39024, 36.49618],
            [127.39199, 36.4949],
            [127.39336, 36.49358],
            [127.39612, 36.49173],
            [127.39914, 36.48887],
            [127.39954, 36.48853],
            [127.39972, 36.48834],
            [127.40207, 36.48633],
            [127.403, 36.48546],
            [127.40332, 36.48428],
            [127.40401, 36.48152],
            [127.40415, 36.481],
            [127.40416, 36.47658],
            [127.40248, 36.4732],
            [127.40164, 36.47031],
            [127.40105, 36.46736],
            [127.4012, 36.46681],
            [127.4012, 36.46671],
            [127.40111, 36.46618],
            [127.40108, 36.46561],
            [127.401, 36.4631],
            [127.40105, 36.46228],
            [127.40107, 36.46159],
            [127.40112, 36.46092],
            [127.40126, 36.46018],
            [127.4014, 36.45964],
            [127.40146, 36.4595],
            [127.40161, 36.45845],
            [127.40198, 36.45719],
            [127.4025, 36.45567],
            [127.40237, 36.45575],
            [127.40209, 36.45604],
            [127.4004, 36.4545],
            [127.39986, 36.45411],
            [127.39935, 36.45368],
            [127.39916, 36.45349],
            [127.3986, 36.45277],
            [127.39694, 36.45113],
            [127.39575, 36.45104],
            [127.39462, 36.45081],
            [127.39408, 36.45061],
            [127.39354, 36.45036],
            [127.39269, 36.44969],
            [127.39219, 36.44924],
            [127.39178, 36.44871],
            [127.39167, 36.44832],
            [127.39119, 36.44724],
            [127.39098, 36.44695],
            [127.39061, 36.4466],
            [127.3905, 36.44643],
            [127.39015, 36.44607],
            [127.38983, 36.44561],
            [127.38964, 36.44536],
            [127.38952, 36.44514],
            [127.38945, 36.44491],
            [127.38941, 36.44464],
            [127.3893, 36.44412],
            [127.38935, 36.44353],
            [127.38933, 36.44276],
            [127.38944, 36.44205],
            [127.38948, 36.44186],
            [127.38987, 36.44132],
            [127.38997, 36.44111],
            [127.39016, 36.44075],
            [127.3905, 36.43979],
            [127.39056, 36.43967],
            [127.39066, 36.43965],
            [127.39072, 36.43957],
            [127.39082, 36.43943],
            [127.39095, 36.43923],
            [127.39116, 36.43901],
            [127.39122, 36.43878],
            [127.39141, 36.43867],
            [127.39244, 36.4382],
            [127.39272, 36.43809],
            [127.39305, 36.43793],
            [127.39344, 36.43781],
            [127.39361, 36.43772],
            [127.39386, 36.43759],
            [127.39386, 36.43745],
            [127.39439, 36.43749],
            [127.39444, 36.43698],
            [127.39456, 36.43664],
            [127.39482, 36.43624],
            [127.3951, 36.43587],
            [127.39567, 36.43517],
            [127.39605, 36.43475],
            [127.39629, 36.43456],
            [127.39752, 36.43373],
            [127.39836, 36.43309],
            [127.39882, 36.43269],
            [127.3995, 36.43194],
            [127.40027, 36.43122],
            [127.40062, 36.43096],
            [127.40108, 36.43066],
            [127.40147, 36.43033],
            [127.40196, 36.42994],
            [127.40271, 36.42919],
            [127.40337, 36.42895],
            [127.40418, 36.42872],
            [127.40471, 36.42853],
            [127.40521, 36.42829],
            [127.40569, 36.42802],
            [127.40613, 36.42767],
            [127.40636, 36.42744],
            [127.40666, 36.4273],
            [127.40712, 36.42704],
            [127.40738, 36.42668],
            [127.40781, 36.42616],
            [127.40851, 36.42567],
            [127.40902, 36.42537],
            [127.40956, 36.42513],
            [127.41149, 36.42458],
            [127.41222, 36.42434],
            [127.41286, 36.42407],
            [127.41322, 36.42388],
            [127.41355, 36.42341],
            [127.4147, 36.42232],
            [127.4149, 36.42209],
            [127.41525, 36.42166],
            [127.41568, 36.42108],
            [127.41612, 36.42052],
            [127.41646, 36.42011],
            [127.41702, 36.41945],
            [127.41754, 36.41877],
            [127.41775, 36.41839],
            [127.41828, 36.41748],
            [127.41852, 36.41704],
            [127.41868, 36.41662],
            [127.41904, 36.41616],
            [127.41932, 36.41585],
            [127.41961, 36.41549],
            [127.41973, 36.4153],
            [127.42026, 36.41369],
            [127.42027, 36.41332],
            [127.42026, 36.41268],
            [127.4199, 36.41182],
            [127.41963, 36.4113],
            [127.41905, 36.41039],
            [127.41861, 36.40957],
            [127.41741, 36.40761],
            [127.41756, 36.40747],
            [127.41738, 36.40717],
            [127.41676, 36.40632],
            [127.41651, 36.40622],
            [127.41606, 36.40599],
            [127.41561, 36.4057],
            [127.41547, 36.4056],
            [127.41534, 36.40547],
            [127.41498, 36.40489],
            [127.4145, 36.40429],
            [127.41384, 36.4033],
            [127.41368, 36.40298],
            [127.4136, 36.40255],
            [127.41354, 36.40178],
            [127.41351, 36.39966],
            [127.41324, 36.39818],
            [127.41263, 36.39735],
            [127.41217, 36.39657],
            [127.41198, 36.39618],
            [127.41172, 36.39584],
            [127.41148, 36.39458],
            [127.41136, 36.39304],
            [127.41131, 36.39255],
            [127.41121, 36.39197],
            [127.41117, 36.39146],
            [127.41118, 36.39046],
            [127.41138, 36.38899],
            [127.41162, 36.38774],
            [127.41173, 36.38737],
            [127.41192, 36.38699],
            [127.41193, 36.38675],
            [127.4119, 36.38634],
            [127.41185, 36.38577],
            [127.41183, 36.38528],
            [127.41178, 36.38312],
            [127.41178, 36.38225],
            [127.41176, 36.3818],
            [127.41164, 36.38129],
            [127.41152, 36.38098],
            [127.4111, 36.38016],
            [127.41082, 36.37986],
            [127.40995, 36.37882],
            [127.4099, 36.37861],
            [127.40937, 36.37826],
            [127.40902, 36.37795],
            [127.40801, 36.37734],
            [127.40756, 36.37705],
            [127.40711, 36.37687],
            [127.40643, 36.37664],
            [127.40542, 36.37634],
            [127.40513, 36.37624],
            [127.40421, 36.37641],
            [127.40371, 36.37659],
            [127.40344, 36.37655],
            [127.40329, 36.37656],
            [127.40272, 36.37639],
            [127.40262, 36.37653],
            [127.40235, 36.37646],
            [127.40228, 36.37654],
            [127.40129, 36.37606],
            [127.40105, 36.37599],
            [127.40092, 36.37596],
            [127.40048, 36.37593],
            [127.40017, 36.37588],
            [127.40089, 36.37502],
            [127.4005, 36.37479],
            [127.39999, 36.37454],
            [127.39799, 36.37376],
            [127.39752, 36.37367],
            [127.39713, 36.37361],
            [127.3964, 36.37357],
            [127.39298, 36.37301],
            [127.39274, 36.37266],
            [127.39246, 36.37208],
            [127.39247, 36.37201],
            [127.39274, 36.37148],
            [127.3926, 36.37152],
            [127.39215, 36.37156],
            [127.39187, 36.37154],
            [127.39149, 36.37149],
            [127.39119, 36.37143],
            [127.39087, 36.3713],
            [127.39057, 36.37122],
            [127.38989, 36.37114],
            [127.38912, 36.37117],
            [127.38883, 36.37117],
            [127.38818, 36.37125],
            [127.38646, 36.37144],
            [127.38576, 36.37149],
            [127.38205, 36.37148],
            [127.38007, 36.37152],
            [127.37991, 36.37199],
            [127.37968, 36.37211],
            [127.37919, 36.37214],
            [127.37862, 36.37204],
            [127.3771, 36.37196],
            [127.37622, 36.37181],
            [127.37606, 36.37182],
            [127.3754, 36.37168],
            [127.37474, 36.37151],
            [127.3741, 36.37115],
            [127.37371, 36.37075],
            [127.37339, 36.37051],
            [127.37304, 36.37028],
            [127.37254, 36.37],
            [127.37199, 36.36973],
            [127.37127, 36.36948],
            [127.37064, 36.36918],
            [127.3702, 36.36896],
            [127.36972, 36.36859],
            [127.36932, 36.36816],
            [127.36901, 36.36767],
            [127.36851, 36.36666],
            [127.36801, 36.36588],
            [127.36759, 36.36539],
            [127.36726, 36.36507],
            [127.36678, 36.36469],
            [127.36641, 36.36447],
            [127.36577, 36.3642],
            [127.36501, 36.36394],
            [127.36279, 36.36338],
            [127.36148, 36.36201],
            [127.36125, 36.36178],
            [127.36102, 36.36152],
            [127.35854, 36.35885],
            [127.35853, 36.3587],
            [127.35827, 36.35803],
            [127.35807, 36.35713],
            [127.35582, 36.35557],
            [127.3539, 36.35429],
            [127.35383, 36.35427],
            [127.35312, 36.3543],
            [127.35269, 36.3543],
            [127.3522, 36.35408],
            [127.35173, 36.35384],
            [127.35099, 36.35361],
            [127.35067, 36.35343],
            [127.3503, 36.35296],
            [127.35004, 36.35259],
            [127.34982, 36.35209],
            [127.34929, 36.35082],
            [127.34917, 36.35025],
            [127.3491, 36.34973],
            [127.34914, 36.34941],
            [127.34918, 36.34918],
            [127.34942, 36.34836],
            [127.34971, 36.34768],
            [127.35014, 36.34688],
            [127.3506, 36.34632],
            [127.35094, 36.34579],
            [127.35112, 36.34521],
            [127.35138, 36.34474],
            [127.35169, 36.3443],
            [127.3518, 36.34391],
            [127.35198, 36.34277],
            [127.35204, 36.34233],
            [127.35212, 36.34189],
            [127.35227, 36.34122],
            [127.3524, 36.3407],
            [127.35296, 36.33921],
            [127.35345, 36.33813],
            [127.35369, 36.33731],
            [127.35374, 36.33709],
            [127.35393, 36.33645],
            [127.35413, 36.33598],
            [127.35443, 36.33544],
            [127.35457, 36.33499],
            [127.3545, 36.33447],
            [127.35437, 36.33385],
            [127.35419, 36.33348],
            [127.35415, 36.33306],
            [127.35384, 36.33259],
            [127.35375, 36.33215],
            [127.33814, 36.33221],
            [127.33814, 36.33014],
            [127.33591, 36.33014],
            [127.33589, 36.32744],
            [127.33596, 36.32743],
            [127.33594, 36.32727],
            [127.337, 36.32664],
            [127.33702, 36.32632],
            [127.3371, 36.32567],
            [127.33729, 36.32522],
            [127.33729, 36.32517],
            [127.33716, 36.32467],
            [127.33675, 36.32386],
            [127.33676, 36.32325],
            [127.33664, 36.32268],
            [127.3368, 36.32196],
            [127.33703, 36.32141],
            [127.33738, 36.32065],
            [127.33753, 36.31998],
            [127.33777, 36.31922],
            [127.33744, 36.31899],
            [127.33779, 36.3178],
            [127.33988, 36.31771],
            [127.33967, 36.31721],
            [127.33957, 36.31691],
            [127.33939, 36.31582],
            [127.33942, 36.31559],
            [127.33994, 36.31466],
            [127.33994, 36.31458],
            [127.33979, 36.31445],
            [127.33937, 36.31433],
            [127.33921, 36.31401],
            [127.33904, 36.31325],
            [127.33774, 36.31345],
            [127.33748, 36.31345],
            [127.33752, 36.31296],
            [127.33704, 36.31225],
            [127.33658, 36.31225],
            [127.33621, 36.31205],
            [127.33623, 36.31168],
            [127.33609, 36.3115],
            [127.33592, 36.31146],
            [127.33585, 36.3115],
            [127.33562, 36.31092],
            [127.33558, 36.31078],
            [127.3353, 36.31078],
            [127.33492, 36.31044],
            [127.33464, 36.31037],
            [127.33446, 36.31003],
            [127.33447, 36.30945],
            [127.33385, 36.30945],
            [127.33394, 36.30932],
            [127.33385, 36.30895],
            [127.3337, 36.30899],
            [127.33362, 36.30903],
            [127.33333, 36.30906],
            [127.33312, 36.30854],
            [127.33287, 36.30817],
            [127.33307, 36.30817],
            [127.33305, 36.30787],
            [127.33261, 36.30788],
            [127.3326, 36.30493],
            [127.33249, 36.30488],
            [127.33251, 36.30377],
            [127.33249, 36.3037],
            [127.33249, 36.3036],
            [127.33248, 36.30243],
            [127.33249, 36.30222],
            [127.33248, 36.30213],
            [127.33248, 36.3016],
            [127.33125, 36.30161],
            [127.33061, 36.30157],
            [127.3303, 36.3015],
            [127.3294, 36.30124],
            [127.32849, 36.30093],
            [127.32808, 36.30077],
            [127.32788, 36.30068],
            [127.32769, 36.30067],
            [127.32728, 36.30047],
            [127.32702, 36.30038],
            [127.3269, 36.30036],
            [127.32677, 36.30035],
            [127.32662, 36.30026],
            [127.32637, 36.30021],
            [127.32474, 36.29959],
            [127.32447, 36.29944],
            [127.32404, 36.29912],
            [127.32377, 36.29888],
            [127.32253, 36.29755],
            [127.32232, 36.29732],
            [127.32214, 36.29707],
            [127.32201, 36.29684],
            [127.32195, 36.29671],
            [127.321, 36.2944],
            [127.3209, 36.29438],
            [127.32055, 36.29343],
            [127.32033, 36.29287],
            [127.32026, 36.29249],
            [127.32006, 36.2919],
            [127.31998, 36.29153],
            [127.31995, 36.2911],
            [127.31996, 36.29095],
            [127.31995, 36.29086],
            [127.32005, 36.29013],
            [127.32006, 36.28942],
            [127.32002, 36.28881],
            [127.31997, 36.28839],
            [127.31986, 36.288],
            [127.31916, 36.28696],
            [127.31899, 36.28678],
            [127.31824, 36.28628],
            [127.31769, 36.28599],
            [127.31729, 36.28585],
            [127.31715, 36.28582],
            [127.31701, 36.28582],
            [127.31685, 36.28578],
            [127.31669, 36.28573],
            [127.31654, 36.2857],
            [127.31617, 36.28568],
            [127.31603, 36.28568],
            [127.31587, 36.28573],
            [127.31562, 36.28575],
            [127.3154, 36.28573],
            [127.31439, 36.28574],
            [127.31442, 36.28528],
            [127.31405, 36.28426],
            [127.31372, 36.28317],
            [127.31358, 36.28252],
            [127.3124, 36.28249],
            [127.31219, 36.28251],
            [127.3113, 36.28251],
            [127.31105, 36.28201],
            [127.31092, 36.28104],
            [127.31067, 36.28054],
            [127.31075, 36.27979],
            [127.31027, 36.27881],
            [127.3105, 36.27815],
            [127.31028, 36.27777],
            [127.30987, 36.27763],
            [127.30972, 36.27737],
            [127.30939, 36.27735],
            [127.30902, 36.27729],
            [127.30897, 36.27692],
            [127.30865, 36.27678],
            [127.30869, 36.27638],
            [127.30886, 36.27593],
            [127.30891, 36.27587],
            [127.30911, 36.27555],
            [127.30932, 36.27538],
            [127.3099, 36.27526],
            [127.30991, 36.27486],
            [127.30988, 36.27374],
            [127.31003, 36.27297],
            [127.31051, 36.27244],
            [127.30891, 36.27205],
            [127.30826, 36.27217],
            [127.30787, 36.27246],
            [127.3067, 36.27232],
            [127.30651, 36.27216],
            [127.30552, 36.27178],
            [127.30506, 36.27151],
            [127.30451, 36.27134],
            [127.30295, 36.27071],
            [127.30253, 36.2706],
            [127.302, 36.27054],
            [127.30146, 36.2705],
            [127.30121, 36.27035],
            [127.30075, 36.27032],
            [127.30044, 36.27005],
            [127.29941, 36.26957],
            [127.29888, 36.26938],
            [127.29788, 36.26863],
            [127.29776, 36.26839],
            [127.29732, 36.26801],
            [127.29674, 36.26727],
            [127.29668, 36.26723],
            [127.29571, 36.26745],
            [127.294, 36.26571],
            [127.2935, 36.26504],
            [127.2933, 36.26416],
            [127.29286, 36.26418],
            [127.29251, 36.26423],
            [127.29229, 36.26433],
            [127.29193, 36.26423],
            [127.29175, 36.26469],
            [127.29153, 36.26496],
            [127.29113, 36.26541],
            [127.29074, 36.26567],
            [127.29046, 36.2658],
            [127.29014, 36.26591],
            [127.28978, 36.2659],
            [127.28865, 36.26569],
            [127.28774, 36.26548],
            [127.28747, 36.26536],
            [127.2863, 36.26505],
            [127.28635, 36.26492],
            [127.28585, 36.26481],
            [127.28543, 36.26479],
            [127.28439, 36.26477],
            [127.2844, 36.26503],
            [127.28412, 36.26497],
            [127.28363, 36.26492],
            [127.28207, 36.26483],
            [127.28163, 36.26485],
            [127.28138, 36.2649],
            [127.28104, 36.26502],
            [127.28022, 36.26543],
            [127.27968, 36.26576],
            [127.27939, 36.26609],
            [127.27901, 36.26664],
            [127.27879, 36.2669],
            [127.2786, 36.26707],
            [127.27841, 36.2672],
            [127.27817, 36.26734],
            [127.27791, 36.26746],
            [127.27776, 36.2675],
            [127.27694, 36.26768],
            [127.27618, 36.26793],
            [127.27555, 36.26816],
            [127.27501, 36.26849],
            [127.27469, 36.26881],
            [127.27346, 36.26947],
            [127.27187, 36.2702],
            [127.27094, 36.27068],
            [127.26995, 36.27108],
            [127.26976, 36.27118],
            [127.26962, 36.27128],
            [127.26933, 36.27157],
            [127.26857, 36.27218],
            [127.26831, 36.27242],
            [127.26821, 36.27253],
            [127.2679, 36.27297],
            [127.26758, 36.27314],
            [127.26697, 36.27353],
            [127.26661, 36.27367],
            [127.26613, 36.27395],
            [127.26568, 36.27416],
            [127.26535, 36.27436],
            [127.26484, 36.27459],
            [127.26451, 36.27469],
            [127.26426, 36.27481],
            [127.26359, 36.27499],
            [127.26296, 36.27525],
            [127.2626, 36.27537],
            [127.26235, 36.27549],
            [127.26218, 36.27554],
            [127.26156, 36.27562],
            [127.26097, 36.27578],
            [127.26076, 36.27581],
            [127.26037, 36.27585],
            [127.26, 36.27586],
            [127.25997, 36.27578],
            [127.25877, 36.27605],
            [127.25787, 36.27668],
            [127.25751, 36.277],
            [127.25679, 36.27775],
            [127.25653, 36.278],
            [127.25593, 36.27864],
            [127.25554, 36.2791],
            [127.25482, 36.28004],
            [127.25423, 36.28047],
            [127.25416, 36.28106],
            [127.25393, 36.28155],
            [127.25381, 36.28211],
            [127.25364, 36.28236],
            [127.25322, 36.28277],
            [127.25126, 36.28399],
            [127.25069, 36.28555],
            [127.25016, 36.28621],
            [127.2489, 36.28697],
            [127.24876, 36.28715],
            [127.24859, 36.28742],
            [127.24851, 36.28761],
            [127.24828, 36.28893],
            [127.24714, 36.29049],
            [127.24665, 36.29096],
            [127.24767, 36.29142],
            [127.24775, 36.29195],
            [127.24798, 36.29295],
            [127.24918, 36.29283],
            [127.2497, 36.29315],
            [127.25019, 36.29394],
            [127.25019, 36.29409],
            [127.25021, 36.29419],
            [127.25076, 36.29499],
            [127.25114, 36.29535],
            [127.25119, 36.29544],
            [127.25119, 36.29561],
            [127.25177, 36.29581],
            [127.25243, 36.29612],
            [127.25406, 36.2968],
            [127.25434, 36.29663],
            [127.25455, 36.29639],
            [127.25471, 36.29614],
            [127.25763, 36.29555],
            [127.25866, 36.29554],
            [127.25991, 36.2956],
            [127.25948, 36.29613],
            [127.25936, 36.29709],
            [127.25961, 36.2983],
            [127.25981, 36.29908],
            [127.25998, 36.30022],
            [127.25995, 36.30056],
            [127.26069, 36.30189],
            [127.26076, 36.302],
            [127.26007, 36.30248],
            [127.25959, 36.30307],
            [127.25893, 36.30337],
            [127.25848, 36.30351],
            [127.25852, 36.30407],
            [127.25865, 36.30486],
            [127.25851, 36.30699],
            [127.25853, 36.30744],
            [127.25853, 36.30816],
            [127.25842, 36.30831],
            [127.25844, 36.30846],
            [127.25864, 36.30865],
            [127.25867, 36.30869],
            [127.25883, 36.30914],
            [127.2582, 36.30983],
            [127.25822, 36.30996],
            [127.25861, 36.31108],
            [127.25896, 36.31141],
            [127.25865, 36.31219],
            [127.2587, 36.31261],
            [127.25892, 36.31335],
            [127.2586, 36.31358],
            [127.25844, 36.31417],
            [127.25853, 36.31474],
            [127.25879, 36.31562],
            [127.2587, 36.31621],
            [127.25876, 36.31737],
            [127.25915, 36.31879],
            [127.25946, 36.31929],
            [127.2601, 36.31948],
            [127.26043, 36.32022],
            [127.26042, 36.3205],
            [127.2606, 36.32149],
            [127.26078, 36.32169],
            [127.26064, 36.32256],
            [127.26079, 36.32313],
            [127.26072, 36.32381],
            [127.26051, 36.32456],
            [127.26014, 36.32503],
            [127.25984, 36.32559],
            [127.26015, 36.32614],
            [127.25997, 36.32673],
            [127.2598, 36.32688],
            [127.2598, 36.32725],
            [127.26086, 36.3271],
            [127.26135, 36.32718],
            [127.26193, 36.32724],
            [127.26251, 36.32725],
            [127.26303, 36.32723],
            [127.26381, 36.32754],
            [127.2646, 36.32799],
            [127.26471, 36.32801],
            [127.26493, 36.32803],
            [127.26612, 36.32837],
            [127.26619, 36.32839],
            [127.26612, 36.32852],
            [127.26598, 36.32905],
            [127.26616, 36.32957],
            [127.26664, 36.33005],
            [127.26696, 36.33034],
            [127.26706, 36.33045],
            [127.26768, 36.33128],
            [127.26815, 36.33138],
            [127.26785, 36.33225],
            [127.26667, 36.3334],
            [127.26672, 36.3346],
            [127.26644, 36.33516],
            [127.26567, 36.33577],
            [127.26564, 36.33645],
            [127.26549, 36.33722],
            [127.26585, 36.33806],
            [127.26663, 36.33875],
            [127.26748, 36.33939],
            [127.26798, 36.33989],
            [127.26815, 36.3401],
            [127.26868, 36.34094],
            [127.27024, 36.34135],
            [127.27125, 36.34187],
            [127.27241, 36.34289],
            [127.27311, 36.34428],
            [127.27569, 36.34387],
            [127.2768, 36.34424],
            [127.27761, 36.34436],
            [127.27831, 36.34444],
            [127.27836, 36.34447],
            [127.27852, 36.3445],
            [127.27912, 36.34482],
            [127.27854, 36.34533],
            [127.27807, 36.34592],
            [127.27781, 36.3466],
            [127.27747, 36.34713],
            [127.27765, 36.34842],
            [127.27791, 36.34882],
            [127.27789, 36.34896],
            [127.27792, 36.349],
            [127.2776, 36.34943],
            [127.27737, 36.35002],
            [127.277, 36.35018],
            [127.27649, 36.35082],
            [127.27638, 36.35121],
            [127.27605, 36.35121],
            [127.27491, 36.35218],
            [127.2746, 36.35256],
            [127.27425, 36.35294],
            [127.27384, 36.35315],
            [127.27376, 36.35327],
            [127.27337, 36.35343],
            [127.27301, 36.35369],
            [127.27263, 36.35411],
            [127.27243, 36.35425],
            [127.27246, 36.35494],
            [127.27252, 36.35507],
            [127.27249, 36.35512],
            [127.27252, 36.3552],
            [127.27233, 36.3556],
            [127.27174, 36.35641],
            [127.27109, 36.35748],
            [127.2711, 36.3583],
            [127.2709, 36.35865],
            [127.27093, 36.35896],
            [127.27104, 36.35953],
            [127.27096, 36.36036],
            [127.27054, 36.36084],
            [127.27064, 36.36107],
            [127.27065, 36.36115],
            [127.27121, 36.36145],
            [127.27187, 36.3616],
            [127.27232, 36.36264],
            [127.27379, 36.36334],
            [127.27472, 36.36373],
            [127.27613, 36.36427],
            [127.27621, 36.36443],
            [127.27676, 36.36461],
            [127.27684, 36.36481],
            [127.27725, 36.36506],
            [127.27774, 36.36543],
            [127.27749, 36.36579],
            [127.27744, 36.36604],
            [127.27749, 36.3665],
            [127.2772, 36.36713],
            [127.2773, 36.36749],
            [127.27709, 36.36841],
            [127.27749, 36.3687],
            [127.27796, 36.3689],
            [127.27866, 36.36908],
            [127.27935, 36.3694],
            [127.27933, 36.36948],
            [127.27944, 36.36954],
            [127.27932, 36.36996],
            [127.27938, 36.37043],
            [127.27933, 36.37068],
            [127.27942, 36.37126],
            [127.2796, 36.37159],
            [127.27975, 36.372],
            [127.28, 36.37221],
            [127.28023, 36.37301],
            [127.2805, 36.37337],
            [127.28069, 36.37425],
            [127.28119, 36.3749],
            [127.28074, 36.37561],
            [127.28067, 36.37599],
            [127.28081, 36.37652],
            [127.28083, 36.37687],
            [127.28059, 36.37743],
            [127.28045, 36.37869],
            [127.28019, 36.37897],
            [127.27995, 36.37908],
            [127.27913, 36.37956],
            [127.27897, 36.37983],
            [127.27901, 36.38206],
            [127.27855, 36.38238],
            [127.27858, 36.38277],
            [127.27897, 36.38365],
            [127.27898, 36.38377],
            [127.27894, 36.38443],
            [127.27883, 36.38496],
            [127.27871, 36.3852],
            [127.27843, 36.38553],
            [127.2783, 36.38638],
            [127.27806, 36.38672],
            [127.27742, 36.38702],
            [127.27846, 36.38781],
            [127.2787, 36.38835],
            [127.27878, 36.3885],
            [127.2788, 36.38947],
            [127.27895, 36.38966],
            [127.27907, 36.39025],
            [127.27905, 36.39104],
            [127.27915, 36.39113],
            [127.27915, 36.39133],
            [127.27917, 36.39147],
            [127.27899, 36.39196],
            [127.27904, 36.3924],
            [127.27894, 36.39288],
            [127.27889, 36.39342],
            [127.27882, 36.39366],
            [127.27886, 36.39464],
            [127.27865, 36.39496],
            [127.27859, 36.3954],
            [127.27862, 36.39577],
            [127.2788, 36.39645],
            [127.2785, 36.39675],
            [127.2789, 36.39822],
            [127.27883, 36.39868],
            [127.27924, 36.39955],
            [127.27943, 36.39983],
            [127.27965, 36.40041],
            [127.27967, 36.40068],
            [127.28076, 36.40336],
            [127.28073, 36.40369],
            [127.2808, 36.40424],
            [127.28104, 36.40484],
            [127.28102, 36.40491],
            [127.28093, 36.40503],
            [127.28089, 36.40571],
            [127.28087, 36.4058],
            [127.28069, 36.40596],
            [127.28068, 36.40669],
            [127.28081, 36.40694],
            [127.28106, 36.40731],
            [127.28123, 36.40766],
            [127.28119, 36.40808],
            [127.28184, 36.40892],
            [127.28174, 36.40979],
            [127.28156, 36.41003],
            [127.28142, 36.41045],
            [127.28151, 36.41064],
            [127.28165, 36.41085],
            [127.28217, 36.41147],
            [127.2823, 36.41199],
            [127.28215, 36.4123],
            [127.2822, 36.41332],
            [127.28197, 36.41409],
            [127.28212, 36.41453],
            [127.2834, 36.41495],
            [127.2843, 36.41498],
            [127.28464, 36.41515],
            [127.28522, 36.41519],
            [127.28572, 36.41507],
            [127.28669, 36.41522],
            [127.28749, 36.41547],
            [127.28875, 36.41571],
            [127.28925, 36.416],
            [127.28959, 36.41606],
            [127.28993, 36.41604],
            [127.29078, 36.41605],
            [127.29109, 36.41615],
            [127.29114, 36.41854],
            [127.2913, 36.41852],
            [127.29125, 36.41868],
            [127.29135, 36.4187],
            [127.29173, 36.41881],
            [127.29216, 36.41881],
            [127.2929, 36.41861],
            [127.29327, 36.41857],
            [127.29363, 36.4186],
            [127.29391, 36.4187],
            [127.29422, 36.41888],
            [127.29468, 36.4193],
            [127.29537, 36.4198],
            [127.29526, 36.41984],
            [127.29519, 36.41994],
            [127.29532, 36.42003],
            [127.29521, 36.42033],
            [127.29517, 36.4208],
            [127.29456, 36.4213],
            [127.29416, 36.42137],
            [127.29414, 36.42168],
            [127.29422, 36.42171],
            [127.29426, 36.42218],
            [127.29521, 36.42252],
            [127.29555, 36.42268],
            [127.29586, 36.42291],
            [127.29614, 36.42299],
            [127.29643, 36.42317],
            [127.29703, 36.42308],
            [127.2983, 36.42311],
            [127.29939, 36.42284],
            [127.29967, 36.42239],
            [127.30036, 36.42214],
            [127.30082, 36.4219],
            [127.30142, 36.42178],
            [127.3018, 36.42249],
            [127.30185, 36.42302],
            [127.30205, 36.4234],
            [127.30255, 36.42404],
            [127.30283, 36.42423],
            [127.30322, 36.42428],
            [127.30348, 36.4246],
            [127.30387, 36.42453],
            [127.3042, 36.42452],
            [127.30497, 36.4247],
            [127.30631, 36.42474],
            [127.30682, 36.42462],
            [127.3075, 36.42467],
            [127.30847, 36.42437],
            [127.30943, 36.42444],
            [127.30973, 36.42453],
            [127.30993, 36.42467],
            [127.31018, 36.42476],
            [127.31086, 36.42464],
            [127.3115, 36.42405],
            [127.31153, 36.42364],
            [127.31293, 36.4231],
            [127.31316, 36.42288],
            [127.31361, 36.42293],
            [127.31401, 36.42287],
            [127.31443, 36.42269],
            [127.31477, 36.42238],
            [127.3151, 36.42185],
            [127.31557, 36.42153],
            [127.31593, 36.42147],
            [127.31613, 36.42119],
            [127.31626, 36.42103],
            [127.3163, 36.42043],
            [127.3166, 36.42045],
            [127.31744, 36.42082],
            [127.31778, 36.42092],
            [127.31801, 36.42097],
            [127.31827, 36.42111],
            [127.31894, 36.42142],
            [127.31911, 36.4214],
            [127.31968, 36.42256],
            [127.32026, 36.42291],
            [127.32047, 36.42324],
            [127.32161, 36.42364],
            [127.32199, 36.42332],
            [127.32332, 36.42267],
            [127.32428, 36.42252],
            [127.32469, 36.42245],
            [127.32507, 36.42245],
            [127.32515, 36.42246],
            [127.32542, 36.42257],
            [127.32569, 36.42249],
            [127.32618, 36.42219],
            [127.3263, 36.42209],
            [127.327, 36.42233],
            [127.3271, 36.42249],
            [127.32741, 36.42268],
            [127.32734, 36.42275],
            [127.32802, 36.42313],
            [127.32915, 36.42332],
            [127.33026, 36.42404],
            [127.33041, 36.42439],
            [127.3311, 36.42468],
            [127.33145, 36.42493],
            [127.33154, 36.42516],
            [127.33226, 36.42555],
            [127.33273, 36.4265],
            [127.33358, 36.4268],
            [127.33472, 36.42698],
            [127.3356, 36.42766],
            [127.33586, 36.42784],
            [127.33675, 36.4282],
            [127.33756, 36.42834],
            [127.33793, 36.4287],
            [127.33834, 36.42899],
            [127.33882, 36.42915],
            [127.33906, 36.42966],
            [127.33947, 36.4299],
            [127.33981, 36.42996],
            [127.34043, 36.43043],
            [127.34066, 36.4305],
            [127.34106, 36.43081],
            [127.34086, 36.43138],
            [127.34082, 36.43172],
            [127.34059, 36.43223],
            [127.34067, 36.43253],
            [127.34069, 36.43289],
            [127.34048, 36.4333],
            [127.34054, 36.43411],
            [127.34077, 36.4347],
            [127.34035, 36.4357],
            [127.34015, 36.43596],
            [127.34015, 36.43615],
            [127.34091, 36.43662],
            [127.3409, 36.43688],
            [127.34129, 36.43728],
            [127.34151, 36.43765],
            [127.34166, 36.43799],
            [127.34216, 36.43802],
            [127.34255, 36.43788],
            [127.34312, 36.43873],
            [127.34303, 36.43905],
            [127.34306, 36.43954],
            [127.34322, 36.4402],
            [127.34378, 36.44117],
            [127.34375, 36.44142],
            [127.34399, 36.44178],
            [127.34411, 36.44242],
            [127.34414, 36.44268],
            [127.34425, 36.44299],
            [127.34436, 36.44327],
            [127.34449, 36.44336],
            [127.3447, 36.44346],
            [127.34474, 36.44391],
            [127.34478, 36.44399],
            [127.34488, 36.4443],
            [127.34494, 36.44439],
            [127.34536, 36.44448],
            [127.34595, 36.44496],
            [127.34616, 36.44515],
            [127.34651, 36.4457],
            [127.34703, 36.44606],
            [127.34746, 36.44641],
            [127.34821, 36.44656],
            [127.34857, 36.4467],
            [127.3492, 36.44709],
            [127.34913, 36.44729],
            [127.34983, 36.44801],
            [127.35083, 36.44781],
            [127.35146, 36.44794],
            [127.35272, 36.44761],
            [127.35293, 36.44764],
            [127.35351, 36.44789],
            [127.35398, 36.44855],
            [127.35402, 36.44881],
            [127.35417, 36.44907],
            [127.35442, 36.44921],
            [127.35453, 36.44975],
            [127.35533, 36.4502],
            [127.3555, 36.45018],
            [127.35601, 36.45053],
            [127.35614, 36.4509],
            [127.3563, 36.45134],
            [127.35603, 36.45189],
            [127.35621, 36.45248],
            [127.35635, 36.45278],
            [127.356, 36.45386],
            [127.35627, 36.45426],
            [127.35679, 36.45427],
            [127.35713, 36.45446],
            [127.35765, 36.45439],
            [127.3579, 36.45474],
            [127.35815, 36.45542],
            [127.3587, 36.45587],
            [127.35845, 36.45662],
            [127.35814, 36.45677],
            [127.35797, 36.457],
            [127.35801, 36.45741],
            [127.35789, 36.4577],
            [127.35782, 36.45807],
            [127.35735, 36.45834],
            [127.35683, 36.45876],
            [127.35676, 36.45892],
            [127.35691, 36.45926],
            [127.35694, 36.45942],
            [127.3569, 36.45957],
            [127.35679, 36.45977],
            [127.357, 36.45995],
            [127.35714, 36.46068],
            [127.35769, 36.46147],
            [127.35723, 36.46177],
            [127.35706, 36.46264],
            [127.35662, 36.46321],
            [127.35607, 36.46402],
            [127.35665, 36.46443],
            [127.35675, 36.4645],
            [127.35682, 36.4645],
            [127.35727, 36.46432],
            [127.35799, 36.46486],
            [127.35857, 36.46496],
            [127.35877, 36.465],
            [127.35905, 36.46537],
            [127.35934, 36.46567],
            [127.35964, 36.46614],
            [127.35956, 36.46651],
            [127.35946, 36.46684],
            [127.35935, 36.46712],
            [127.35925, 36.46725],
            [127.35931, 36.4673],
            [127.35904, 36.46757],
            [127.35914, 36.46769],
            [127.35973, 36.46823],
            [127.35977, 36.46828],
            [127.35977, 36.4686],
            [127.36005, 36.46902],
            [127.35988, 36.46987],
            [127.36038, 36.47011],
            [127.36082, 36.47051],
            [127.36157, 36.47075],
            [127.36224, 36.47061],
            [127.36239, 36.47069],
            [127.36239, 36.47075],
            [127.36217, 36.47117],
            [127.36205, 36.47176],
            [127.36225, 36.4719],
            [127.36231, 36.47199],
            [127.36272, 36.47287],
            [127.36286, 36.4733],
            [127.36295, 36.47359],
            [127.36367, 36.47405],
            [127.36377, 36.47421],
            [127.36385, 36.47435],
            [127.36399, 36.47482],
            [127.36397, 36.47497],
            [127.36404, 36.47497],
            [127.36401, 36.47514],
            [127.36326, 36.47573],
            [127.36296, 36.47601],
            [127.36293, 36.47606],
            [127.36281, 36.47601],
            [127.36283, 36.47594],
            [127.36272, 36.47591],
            [127.36257, 36.47598],
            [127.36246, 36.476],
            [127.36241, 36.47607],
            [127.3623, 36.47616],
            [127.3622, 36.47634],
            [127.36221, 36.47645],
            [127.36218, 36.4766],
            [127.36211, 36.47664],
            [127.36191, 36.47682],
            [127.36189, 36.47689],
            [127.36172, 36.477],
            [127.36165, 36.47696],
            [127.36158, 36.47702],
            [127.36156, 36.47711],
            [127.3615, 36.4772],
            [127.36147, 36.47732],
            [127.36141, 36.47737],
            [127.36138, 36.4775],
            [127.36132, 36.47763],
            [127.36125, 36.47768],
            [127.36123, 36.47781],
            [127.36116, 36.47786],
            [127.36116, 36.47793],
            [127.36113, 36.47809],
            [127.36071, 36.47853],
            [127.36053, 36.4791],
            [127.36055, 36.47933],
            [127.36088, 36.47993],
            [127.36092, 36.48052],
            [127.36084, 36.48069],
            [127.36047, 36.48127],
            [127.36038, 36.48152],
            [127.36028, 36.48156],
            [127.36026, 36.48161],
            [127.36, 36.4817],
            [127.3599, 36.48176],
            [127.35963, 36.48182],
            [127.35934, 36.48206],
            [127.35914, 36.4821],
            [127.3591, 36.48216],
            [127.35915, 36.48228],
            [127.35891, 36.4825],
            [127.35886, 36.48256],
            [127.35816, 36.48253],
            [127.35783, 36.48312],
            [127.35795, 36.48348],
            [127.35801, 36.48408],
            [127.3584, 36.48459],
            [127.35845, 36.48468],
            [127.35919, 36.48521],
            [127.35925, 36.4861],
            [127.35924, 36.48731],
            [127.35955, 36.48772],
            [127.36017, 36.48848],
            [127.36023, 36.48973],
            [127.36012, 36.49],
            [127.36045, 36.49035],
            [127.36092, 36.49084],
            [127.36114, 36.49211],
            [127.36216, 36.49189],
            [127.36282, 36.49158],
            [127.36351, 36.49102],
            [127.36588, 36.49051],
            [127.36891, 36.48956],
            [127.3708, 36.49014],
            [127.37143, 36.49054],
            [127.3727, 36.49138],
            [127.37523, 36.49333],
            [127.37759, 36.49562],
            [127.3794, 36.49827],
            [127.38029, 36.49951],
            [127.38034, 36.49954],
            [127.38386, 36.50023],
            [127.38577, 36.49923]
          ]
        ]
      }
    },
    {
      id: '30230',
      type: 'Feature',
      properties: {
        SEC_SGG_CD: '30230',
        SEC_SGG_NM: ['대전광역시', '대덕구'],
        COL_SGG_CD: '30000',
        SEC_SGG_SIZE: '2',
        bbox: [127.3893, 36.34093, 127.50449, 36.47725],
        center: [127.44012, 36.41225],
        area: 69202568
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [127.48665, 36.4528],
            [127.48691, 36.45279],
            [127.49162, 36.45336],
            [127.50058, 36.45622],
            [127.50279, 36.45551],
            [127.50375, 36.45374],
            [127.50449, 36.45175],
            [127.50353, 36.44927],
            [127.50215, 36.44834],
            [127.49937, 36.44622],
            [127.49713, 36.44472],
            [127.4935, 36.44116],
            [127.49246, 36.43936],
            [127.49111, 36.43983],
            [127.49067, 36.4398],
            [127.49001, 36.43937],
            [127.48979, 36.43915],
            [127.48934, 36.4391],
            [127.48885, 36.43879],
            [127.4883, 36.43898],
            [127.48784, 36.43867],
            [127.48766, 36.4385],
            [127.48731, 36.43789],
            [127.48708, 36.43757],
            [127.48712, 36.43747],
            [127.48654, 36.43703],
            [127.48626, 36.43672],
            [127.48606, 36.43639],
            [127.48603, 36.43628],
            [127.48602, 36.43607],
            [127.48568, 36.43585],
            [127.4853, 36.43526],
            [127.48471, 36.43467],
            [127.48453, 36.43419],
            [127.48416, 36.43394],
            [127.48405, 36.43349],
            [127.48316, 36.43262],
            [127.48296, 36.4323],
            [127.48261, 36.43202],
            [127.48232, 36.43151],
            [127.48199, 36.43095],
            [127.48193, 36.43092],
            [127.4819, 36.43085],
            [127.48159, 36.43065],
            [127.48155, 36.4304],
            [127.48147, 36.43025],
            [127.48147, 36.43014],
            [127.48138, 36.43002],
            [127.48138, 36.42997],
            [127.48104, 36.42955],
            [127.48093, 36.42914],
            [127.48108, 36.42885],
            [127.4809, 36.42842],
            [127.48119, 36.42802],
            [127.48116, 36.42796],
            [127.48024, 36.4265],
            [127.47973, 36.4262],
            [127.47956, 36.42584],
            [127.47964, 36.42542],
            [127.47937, 36.42524],
            [127.47901, 36.4245],
            [127.47902, 36.4244],
            [127.47868, 36.42396],
            [127.47841, 36.42369],
            [127.47844, 36.42342],
            [127.4783, 36.42321],
            [127.47806, 36.42306],
            [127.47786, 36.42241],
            [127.47789, 36.42211],
            [127.47765, 36.42185],
            [127.47732, 36.42158],
            [127.47674, 36.42098],
            [127.47655, 36.42042],
            [127.47617, 36.4195],
            [127.47604, 36.41908],
            [127.47578, 36.41919],
            [127.47552, 36.41924],
            [127.47494, 36.41945],
            [127.47468, 36.41947],
            [127.47451, 36.41946],
            [127.47397, 36.41961],
            [127.47388, 36.41946],
            [127.47338, 36.41931],
            [127.47339, 36.41923],
            [127.47335, 36.41908],
            [127.47339, 36.41908],
            [127.47346, 36.41904],
            [127.4743, 36.41803],
            [127.47455, 36.4178],
            [127.47442, 36.41759],
            [127.47381, 36.41709],
            [127.47362, 36.41669],
            [127.4734, 36.41646],
            [127.4729, 36.41607],
            [127.47281, 36.4156],
            [127.47254, 36.41511],
            [127.47239, 36.41489],
            [127.4719, 36.41434],
            [127.47181, 36.41425],
            [127.47161, 36.41413],
            [127.47127, 36.4136],
            [127.47046, 36.41268],
            [127.46981, 36.41217],
            [127.46958, 36.41185],
            [127.46901, 36.41116],
            [127.46865, 36.41095],
            [127.46853, 36.41063],
            [127.46841, 36.41017],
            [127.46818, 36.40985],
            [127.4679, 36.40992],
            [127.46769, 36.40995],
            [127.4674, 36.40992],
            [127.46742, 36.40972],
            [127.46691, 36.40915],
            [127.46656, 36.40863],
            [127.46655, 36.40856],
            [127.46649, 36.40849],
            [127.46633, 36.40832],
            [127.46565, 36.40793],
            [127.46513, 36.40773],
            [127.46487, 36.40766],
            [127.4642, 36.40714],
            [127.46408, 36.40694],
            [127.46328, 36.40689],
            [127.46273, 36.40656],
            [127.46229, 36.40596],
            [127.46187, 36.40552],
            [127.46144, 36.40527],
            [127.46124, 36.40487],
            [127.46085, 36.40457],
            [127.46021, 36.40449],
            [127.46007, 36.40442],
            [127.45947, 36.4042],
            [127.45912, 36.40395],
            [127.45821, 36.40368],
            [127.45816, 36.40359],
            [127.45758, 36.40308],
            [127.45763, 36.40246],
            [127.45687, 36.40148],
            [127.45631, 36.40106],
            [127.4559, 36.40092],
            [127.45576, 36.39984],
            [127.45532, 36.40003],
            [127.45534, 36.39947],
            [127.45521, 36.39875],
            [127.45543, 36.39863],
            [127.45541, 36.3982],
            [127.45505, 36.39733],
            [127.45493, 36.3967],
            [127.45461, 36.39598],
            [127.45419, 36.39426],
            [127.4542, 36.39377],
            [127.45423, 36.39353],
            [127.4543, 36.39232],
            [127.45415, 36.39121],
            [127.45439, 36.39076],
            [127.45437, 36.39036],
            [127.45416, 36.39006],
            [127.45529, 36.38883],
            [127.45488, 36.38845],
            [127.45468, 36.38802],
            [127.45474, 36.38776],
            [127.45484, 36.38677],
            [127.45423, 36.38592],
            [127.4536, 36.3854],
            [127.45332, 36.38499],
            [127.45307, 36.38414],
            [127.4531, 36.38401],
            [127.45335, 36.38353],
            [127.45315, 36.38305],
            [127.45292, 36.38275],
            [127.45273, 36.38266],
            [127.45269, 36.38236],
            [127.45246, 36.38201],
            [127.45214, 36.38184],
            [127.45208, 36.38177],
            [127.45167, 36.38162],
            [127.45121, 36.38113],
            [127.45119, 36.38102],
            [127.45154, 36.3808],
            [127.45142, 36.38031],
            [127.45148, 36.38003],
            [127.45164, 36.37961],
            [127.45154, 36.37929],
            [127.45177, 36.37904],
            [127.45207, 36.3786],
            [127.45267, 36.37804],
            [127.45312, 36.37733],
            [127.45395, 36.37705],
            [127.45427, 36.37697],
            [127.45429, 36.37669],
            [127.45441, 36.37618],
            [127.45434, 36.37591],
            [127.45462, 36.37581],
            [127.45501, 36.37552],
            [127.45536, 36.37509],
            [127.45577, 36.37476],
            [127.45577, 36.37466],
            [127.45575, 36.37386],
            [127.45601, 36.37355],
            [127.45632, 36.37331],
            [127.45681, 36.37277],
            [127.45718, 36.37241],
            [127.45734, 36.3716],
            [127.45765, 36.37029],
            [127.45778, 36.37007],
            [127.45782, 36.36979],
            [127.45797, 36.36963],
            [127.45816, 36.36926],
            [127.45917, 36.36921],
            [127.45967, 36.36883],
            [127.45975, 36.36872],
            [127.45988, 36.36861],
            [127.46001, 36.36843],
            [127.46002, 36.36801],
            [127.46067, 36.36751],
            [127.4608, 36.36679],
            [127.46128, 36.366],
            [127.46199, 36.36466],
            [127.46212, 36.36454],
            [127.4622, 36.36449],
            [127.46233, 36.36434],
            [127.46253, 36.36435],
            [127.46274, 36.3639],
            [127.46267, 36.3636],
            [127.46301, 36.36325],
            [127.46302, 36.36319],
            [127.46328, 36.36253],
            [127.46344, 36.36198],
            [127.46392, 36.36149],
            [127.46461, 36.36135],
            [127.46509, 36.36106],
            [127.46562, 36.361],
            [127.46619, 36.36076],
            [127.46623, 36.36056],
            [127.46602, 36.35935],
            [127.46592, 36.35892],
            [127.46601, 36.35848],
            [127.46631, 36.3581],
            [127.46563, 36.35724],
            [127.46561, 36.35667],
            [127.46552, 36.3564],
            [127.46533, 36.35626],
            [127.46524, 36.35588],
            [127.46477, 36.35525],
            [127.4646, 36.35373],
            [127.46459, 36.3535],
            [127.46475, 36.35257],
            [127.46504, 36.35239],
            [127.46511, 36.35147],
            [127.4646, 36.35102],
            [127.46438, 36.3509],
            [127.46405, 36.35078],
            [127.46368, 36.35073],
            [127.4635, 36.35077],
            [127.46332, 36.35084],
            [127.46279, 36.35116],
            [127.46194, 36.35154],
            [127.4609, 36.35177],
            [127.45924, 36.3521],
            [127.45924, 36.35216],
            [127.4589, 36.35222],
            [127.45876, 36.35258],
            [127.45848, 36.35297],
            [127.45769, 36.35299],
            [127.45689, 36.35303],
            [127.45596, 36.35304],
            [127.45548, 36.35301],
            [127.45498, 36.35302],
            [127.45416, 36.35318],
            [127.45395, 36.35324],
            [127.45463, 36.35445],
            [127.45432, 36.35444],
            [127.44908, 36.35406],
            [127.44905, 36.35394],
            [127.44871, 36.35337],
            [127.447, 36.35401],
            [127.4469, 36.3539],
            [127.4466, 36.35344],
            [127.44597, 36.35365],
            [127.44583, 36.35336],
            [127.44562, 36.3524],
            [127.44557, 36.35224],
            [127.44568, 36.3522],
            [127.44598, 36.35232],
            [127.44603, 36.35241],
            [127.4461, 36.35244],
            [127.44517, 36.3508],
            [127.44277, 36.35173],
            [127.44227, 36.35195],
            [127.44138, 36.35234],
            [127.44148, 36.35356],
            [127.43965, 36.35508],
            [127.44004, 36.35556],
            [127.4397, 36.3556],
            [127.43988, 36.35626],
            [127.43972, 36.35654],
            [127.43925, 36.35675],
            [127.43892, 36.35701],
            [127.43908, 36.35724],
            [127.43897, 36.3575],
            [127.43899, 36.35765],
            [127.4391, 36.35808],
            [127.43923, 36.35831],
            [127.43876, 36.35925],
            [127.43862, 36.35964],
            [127.43828, 36.35998],
            [127.43774, 36.36002],
            [127.43754, 36.36014],
            [127.43746, 36.36021],
            [127.43728, 36.36018],
            [127.43717, 36.36018],
            [127.43717, 36.36023],
            [127.43701, 36.36022],
            [127.43684, 36.36024],
            [127.43677, 36.36028],
            [127.43671, 36.36025],
            [127.43665, 36.36028],
            [127.43636, 36.36069],
            [127.43617, 36.36078],
            [127.43532, 36.36083],
            [127.43501, 36.36071],
            [127.43415, 36.36008],
            [127.43398, 36.35992],
            [127.43378, 36.35984],
            [127.4331, 36.35939],
            [127.43294, 36.35926],
            [127.43291, 36.35918],
            [127.43285, 36.35919],
            [127.43245, 36.35911],
            [127.43067, 36.35906],
            [127.43077, 36.35682],
            [127.42906, 36.35677],
            [127.42911, 36.3556],
            [127.42748, 36.35546],
            [127.42791, 36.35259],
            [127.42725, 36.35251],
            [127.42735, 36.35197],
            [127.42729, 36.35189],
            [127.42692, 36.35165],
            [127.42653, 36.35142],
            [127.4265, 36.35132],
            [127.4262, 36.35098],
            [127.42599, 36.35067],
            [127.42562, 36.35004],
            [127.42523, 36.34923],
            [127.42506, 36.34903],
            [127.42483, 36.34881],
            [127.42426, 36.34853],
            [127.42412, 36.34849],
            [127.42392, 36.34848],
            [127.4237, 36.34856],
            [127.4232, 36.3485],
            [127.42279, 36.3485],
            [127.42265, 36.34855],
            [127.42247, 36.34854],
            [127.42224, 36.34851],
            [127.42212, 36.34849],
            [127.42197, 36.34849],
            [127.42183, 36.34853],
            [127.42178, 36.34856],
            [127.42165, 36.34857],
            [127.42137, 36.34851],
            [127.42129, 36.34848],
            [127.4212, 36.34854],
            [127.42083, 36.34834],
            [127.42039, 36.34833],
            [127.42013, 36.34827],
            [127.41958, 36.34831],
            [127.41941, 36.3477],
            [127.41924, 36.3474],
            [127.41887, 36.34684],
            [127.41844, 36.34636],
            [127.42085, 36.3446],
            [127.42158, 36.3444],
            [127.42323, 36.34385],
            [127.42312, 36.3437],
            [127.4215, 36.34426],
            [127.42109, 36.34438],
            [127.42066, 36.34447],
            [127.41995, 36.3446],
            [127.41949, 36.34467],
            [127.41853, 36.34473],
            [127.41779, 36.34472],
            [127.41699, 36.34466],
            [127.41533, 36.34434],
            [127.41454, 36.34416],
            [127.41365, 36.34395],
            [127.41474, 36.34134],
            [127.4149, 36.34104],
            [127.41499, 36.34093],
            [127.4149, 36.34096],
            [127.4145, 36.3412],
            [127.41416, 36.34155],
            [127.41376, 36.34203],
            [127.4134, 36.34254],
            [127.41317, 36.34283],
            [127.41296, 36.34303],
            [127.41276, 36.34368],
            [127.41259, 36.34411],
            [127.41204, 36.34504],
            [127.41179, 36.34556],
            [127.41165, 36.34611],
            [127.41135, 36.34653],
            [127.4109, 36.347],
            [127.4101, 36.34765],
            [127.40996, 36.34776],
            [127.40956, 36.34776],
            [127.40841, 36.34828],
            [127.40599, 36.3489],
            [127.40466, 36.34938],
            [127.40391, 36.34963],
            [127.40393, 36.34988],
            [127.40402, 36.35046],
            [127.4045, 36.35291],
            [127.40461, 36.35327],
            [127.40385, 36.35476],
            [127.40376, 36.35505],
            [127.40328, 36.35601],
            [127.40321, 36.35651],
            [127.40316, 36.3566],
            [127.40311, 36.35675],
            [127.40248, 36.35811],
            [127.40248, 36.35824],
            [127.40229, 36.35855],
            [127.40196, 36.35922],
            [127.40187, 36.35951],
            [127.40177, 36.35973],
            [127.40114, 36.36137],
            [127.40075, 36.36149],
            [127.4002, 36.36171],
            [127.39932, 36.36218],
            [127.39847, 36.36285],
            [127.3978, 36.36368],
            [127.39747, 36.36413],
            [127.39732, 36.36461],
            [127.39724, 36.36502],
            [127.39725, 36.36547],
            [127.39728, 36.36579],
            [127.39722, 36.36612],
            [127.39698, 36.3667],
            [127.39654, 36.36753],
            [127.39571, 36.36885],
            [127.39543, 36.36919],
            [127.39462, 36.3697],
            [127.39375, 36.37035],
            [127.39333, 36.37082],
            [127.39313, 36.37122],
            [127.39296, 36.37169],
            [127.39285, 36.37217],
            [127.39295, 36.3726],
            [127.39322, 36.37302],
            [127.3964, 36.37357],
            [127.39713, 36.37361],
            [127.39752, 36.37367],
            [127.39799, 36.37376],
            [127.39999, 36.37454],
            [127.4005, 36.37479],
            [127.40089, 36.37502],
            [127.40017, 36.37588],
            [127.40048, 36.37593],
            [127.40092, 36.37596],
            [127.40105, 36.37599],
            [127.40129, 36.37606],
            [127.40228, 36.37654],
            [127.4024, 36.37642],
            [127.40262, 36.37653],
            [127.40272, 36.37639],
            [127.40329, 36.37656],
            [127.40344, 36.37655],
            [127.40371, 36.37659],
            [127.40421, 36.37641],
            [127.40513, 36.37624],
            [127.40542, 36.37634],
            [127.40643, 36.37664],
            [127.40711, 36.37687],
            [127.40756, 36.37705],
            [127.40801, 36.37734],
            [127.40902, 36.37795],
            [127.40937, 36.37826],
            [127.4099, 36.37861],
            [127.40995, 36.37882],
            [127.41082, 36.37986],
            [127.4111, 36.38016],
            [127.41152, 36.38098],
            [127.41164, 36.38129],
            [127.41176, 36.3818],
            [127.41178, 36.38225],
            [127.41178, 36.38312],
            [127.41183, 36.38528],
            [127.41185, 36.38577],
            [127.4119, 36.38634],
            [127.41193, 36.38675],
            [127.41192, 36.38699],
            [127.41173, 36.38737],
            [127.41162, 36.38774],
            [127.41138, 36.38899],
            [127.41118, 36.39046],
            [127.41117, 36.39146],
            [127.41121, 36.39197],
            [127.41131, 36.39255],
            [127.41136, 36.39304],
            [127.41148, 36.39458],
            [127.41172, 36.39584],
            [127.41198, 36.39618],
            [127.41217, 36.39657],
            [127.41263, 36.39735],
            [127.41324, 36.39818],
            [127.41351, 36.39966],
            [127.41354, 36.40178],
            [127.4136, 36.40255],
            [127.41368, 36.40298],
            [127.41384, 36.4033],
            [127.4145, 36.40429],
            [127.41498, 36.40489],
            [127.41534, 36.40547],
            [127.41547, 36.4056],
            [127.41561, 36.4057],
            [127.41606, 36.40599],
            [127.41651, 36.40622],
            [127.41676, 36.40632],
            [127.41738, 36.40717],
            [127.41756, 36.40747],
            [127.41741, 36.40761],
            [127.41861, 36.40957],
            [127.41905, 36.41039],
            [127.41963, 36.4113],
            [127.4199, 36.41182],
            [127.42026, 36.41268],
            [127.42027, 36.41332],
            [127.42026, 36.41369],
            [127.41973, 36.4153],
            [127.41961, 36.41549],
            [127.41932, 36.41585],
            [127.41904, 36.41616],
            [127.41868, 36.41662],
            [127.41852, 36.41704],
            [127.41828, 36.41748],
            [127.41775, 36.41839],
            [127.41754, 36.41877],
            [127.41702, 36.41945],
            [127.41646, 36.42011],
            [127.41612, 36.42052],
            [127.41568, 36.42108],
            [127.41525, 36.42166],
            [127.4149, 36.42209],
            [127.4147, 36.42232],
            [127.41355, 36.42341],
            [127.41322, 36.42388],
            [127.41286, 36.42407],
            [127.41222, 36.42434],
            [127.41149, 36.42458],
            [127.40956, 36.42513],
            [127.40902, 36.42537],
            [127.40851, 36.42567],
            [127.40781, 36.42616],
            [127.40738, 36.42668],
            [127.40712, 36.42704],
            [127.40666, 36.4273],
            [127.40636, 36.42744],
            [127.40613, 36.42767],
            [127.40569, 36.42802],
            [127.40521, 36.42829],
            [127.40471, 36.42853],
            [127.40418, 36.42872],
            [127.40337, 36.42895],
            [127.40271, 36.42919],
            [127.40196, 36.42994],
            [127.40147, 36.43033],
            [127.40108, 36.43066],
            [127.40062, 36.43096],
            [127.40027, 36.43122],
            [127.3995, 36.43194],
            [127.39882, 36.43269],
            [127.39836, 36.43309],
            [127.39752, 36.43373],
            [127.39629, 36.43456],
            [127.39605, 36.43475],
            [127.39567, 36.43517],
            [127.3951, 36.43587],
            [127.39482, 36.43624],
            [127.39456, 36.43664],
            [127.39444, 36.43698],
            [127.39439, 36.43749],
            [127.39386, 36.43745],
            [127.39386, 36.43759],
            [127.39361, 36.43772],
            [127.39344, 36.43781],
            [127.39305, 36.43793],
            [127.39272, 36.43809],
            [127.39244, 36.4382],
            [127.39141, 36.43867],
            [127.39122, 36.43878],
            [127.39116, 36.43901],
            [127.39095, 36.43923],
            [127.39082, 36.43943],
            [127.39072, 36.43957],
            [127.39066, 36.43965],
            [127.39056, 36.43967],
            [127.3905, 36.43979],
            [127.39016, 36.44075],
            [127.38997, 36.44111],
            [127.38987, 36.44132],
            [127.38948, 36.44186],
            [127.38944, 36.44205],
            [127.38933, 36.44276],
            [127.38935, 36.44353],
            [127.3893, 36.44412],
            [127.38941, 36.44464],
            [127.38945, 36.44491],
            [127.38952, 36.44514],
            [127.38964, 36.44536],
            [127.38983, 36.44561],
            [127.39015, 36.44607],
            [127.3905, 36.44643],
            [127.39061, 36.4466],
            [127.39098, 36.44695],
            [127.39119, 36.44724],
            [127.39167, 36.44832],
            [127.39178, 36.44871],
            [127.39219, 36.44924],
            [127.3924, 36.44943],
            [127.39269, 36.44969],
            [127.39354, 36.45036],
            [127.39374, 36.45046],
            [127.39408, 36.45061],
            [127.39462, 36.45081],
            [127.39502, 36.45089],
            [127.39575, 36.45104],
            [127.39694, 36.45113],
            [127.3986, 36.45277],
            [127.39916, 36.45349],
            [127.39935, 36.45368],
            [127.39986, 36.45411],
            [127.4004, 36.4545],
            [127.40209, 36.45604],
            [127.40237, 36.45575],
            [127.40314, 36.45534],
            [127.40492, 36.45508],
            [127.4061, 36.45493],
            [127.40742, 36.45482],
            [127.40796, 36.45474],
            [127.40933, 36.45475],
            [127.41039, 36.4548],
            [127.41093, 36.45481],
            [127.41302, 36.45481],
            [127.41354, 36.45478],
            [127.41417, 36.45471],
            [127.41497, 36.45471],
            [127.41628, 36.45481],
            [127.41696, 36.45491],
            [127.41819, 36.4551],
            [127.41851, 36.45517],
            [127.41987, 36.45539],
            [127.4209, 36.45561],
            [127.42282, 36.45587],
            [127.42427, 36.45612],
            [127.4247, 36.45618],
            [127.42471, 36.45627],
            [127.4266, 36.45668],
            [127.42749, 36.4569],
            [127.43216, 36.45675],
            [127.43682, 36.45641],
            [127.43708, 36.45601],
            [127.4375, 36.45573],
            [127.43764, 36.45561],
            [127.43952, 36.45404],
            [127.44031, 36.45332],
            [127.4414, 36.45242],
            [127.4416, 36.45215],
            [127.4424, 36.45128],
            [127.44297, 36.45095],
            [127.44411, 36.45041],
            [127.44639, 36.44956],
            [127.44718, 36.44921],
            [127.45133, 36.44925],
            [127.45474, 36.45026],
            [127.4584, 36.4523],
            [127.46096, 36.45448],
            [127.46258, 36.45615],
            [127.4635, 36.46028],
            [127.46363, 36.46285],
            [127.46361, 36.46346],
            [127.46366, 36.464],
            [127.46378, 36.46451],
            [127.46482, 36.46648],
            [127.4667, 36.47007],
            [127.46943, 36.47269],
            [127.47029, 36.47366],
            [127.47075, 36.47402],
            [127.47127, 36.47438],
            [127.4718, 36.4747],
            [127.47243, 36.47502],
            [127.47461, 36.47583],
            [127.4757, 36.47617],
            [127.47613, 36.47633],
            [127.48043, 36.47725],
            [127.48403, 36.47663],
            [127.48453, 36.47451],
            [127.48428, 36.47361],
            [127.48336, 36.47012],
            [127.48328, 36.46996],
            [127.48287, 36.46899],
            [127.48233, 36.46806],
            [127.48158, 36.46637],
            [127.48109, 36.46565],
            [127.48057, 36.46503],
            [127.4801, 36.46464],
            [127.47965, 36.4641],
            [127.47942, 36.46376],
            [127.4792, 36.46334],
            [127.47908, 36.46307],
            [127.47902, 36.46282],
            [127.47889, 36.46257],
            [127.47826, 36.4617],
            [127.47796, 36.4612],
            [127.47782, 36.4609],
            [127.47768, 36.46049],
            [127.47756, 36.45994],
            [127.47749, 36.45954],
            [127.47746, 36.45922],
            [127.47749, 36.45898],
            [127.47759, 36.45844],
            [127.47788, 36.45789],
            [127.47824, 36.45734],
            [127.47896, 36.4565],
            [127.47952, 36.45601],
            [127.47993, 36.45573],
            [127.48211, 36.45389],
            [127.48454, 36.45293],
            [127.48665, 36.4528]
          ]
        ]
      }
    }
  ]
};
