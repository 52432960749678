import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import AppContext, { ChatContext, LoginUserContext } from 'context/Context';
import Picker from '@emoji-mart/react';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import Flex from 'components/common/Flex';

import { Cookies } from 'react-cookie';
import { Stomp } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import ComposeAttachment from 'components/app/email/compose/ComposeAttachment';
const cookies = new Cookies();

const formatDate = date => {
  const options = {
    weekday: 'short',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  };

  const now = date
    .toLocaleString('en-US', options)
    .split(',')
    .map(item => item.trim());

  return {
    day: now[0],
    hour: now[3],
    date: now[1] + ', ' + now[2]
  };
};

const MessageTextArea = () => {
  const { currentChatRoom } = useContext(ChatContext);
  // console.log('MessageTextArea : ', thread);
  // const {
  //   messagesDispatch,
  //   messages,
  //   threadsDispatch,
  //   currentThread,
  //   setScrollToBottom,
  //   isOpenThreadInfo
  // } = useContext(ChatContext);

  const [previewEmoji, setPreviewEmoji] = useState(false);
  const [message, setMessage] = useState('');

  //서버에 보내지는 파일
  const [attachments, setAttachments] = useState([]);

  const {
    config: { isDark }
  } = useContext(AppContext);

  const { loginUser } = useContext(LoginUserContext);

  const addEmoji = e => {
    let emoji = e.native;
    setMessage(message + emoji);
    setPreviewEmoji(false);
  };

  const handleAddAttachment = files => {
    if (files.length === 0) return;
    const fileArray = [];
    Array.from(files).forEach(file => {
      const { name, size, type } = file;

      const newFile = {
        id: name + 1 + Date.now(),
        name,
        size,
        type
      };
      fileArray.push(newFile);
    });
    setAttachments([...attachments, ...fileArray]);
  };

  const handleDetachAttachment = id => {
    setAttachments(attachments.filter(attachment => attachment.id !== id));
  };

  const sendChatFiles = () => {
    let url = 'api/chatroom/' + currentChatRoom.id + '/chat';

    // let url =
    //   'api/chatroom/1/chats?pageNo=0&pageSize=99999&sort=createDate$asc';
    console.log('MessageTextArea sendChatFiles() url: ', url);
    // chainAxios
    //   .get(url)
    //   .then(response => {
    //     console.log('ChatContentBody chatroomsChat() : ', response);
    //     if (response.data.resultCode === 200) {
    //       setChatList(response.data.data.chatList.dataList);
    //       console.log('chatList : ', chatList);
    //     }
    //   })
    //   .catch(error => {
    //     console.log('ChatContentBody chatroomsChat() : ', error);
    //   });
  };

  const handleSubmit = e => {
    e.preventDefault();
    // stompClient.current = Stomp.over(
    //   new SockJS(
    //     'http://112.175.18.232:7000/ws?X-AUTH-TOKEN=' +
    //       cookies.get('Authorization')
    //   )
    // );

    // console.log('MessageTextArea currentThread : ', currentChatRoom);
    // console.log('MessageTextArea message : ', message);

    // stompClient.current.connect({}, frame => {
    //   stompClient.current.send(
    //     '/app/chat',
    //     {},
    //     JSON.stringify({
    //       sender: { key: loginUser.userId },
    //       message: message,
    //       chatroom: { key: currentChatRoom.id }
    //     })
    //   );
    // });

    setMessage('');

    if (attachments.length > 0) {
      sendChatFiles();
    }

    // stompClient.current.disconnect();

    // let newMessage = {
    //   senderUserId: 3,
    //   message: `${message.replace(/(?:\r\n|\r|\n)/g, '<br>')}`,
    //   status: 'delivered',
    //   time: formatDate(date)
    // };

    // const { content } = messages.find(
    //   ({ id }) => id === currentThread.messagesId
    // );

    // if (message) {
    //   messagesDispatch({
    //     type: 'EDIT',
    //     payload: {
    //       id: currentThread.messagesId,
    //       content: [...content, newMessage]
    //     },
    //     id: currentThread.messagesId
    //   });

    //   threadsDispatch({
    //     type: 'EDIT',
    //     payload: currentThread,
    //     id: currentThread.id,
    //     isUpdatedStart: true
    //   });
    // }
    // setMessage('');
    // setScrollToBottom(true);
  };

  // useEffect(() => {
  //   if (isOpenThreadInfo) {
  //     setPreviewEmoji(false);
  //   }
  // }, [isOpenThreadInfo]);

  return (
    <>
      {attachments.length > 0 && (
        <div className="bg-light px-x1 py-3">
          <Flex direction="row" inline>
            {attachments.map(attachment => (
              <ComposeAttachment
                {...attachment}
                key={attachment.id}
                handleDetachAttachment={handleDetachAttachment}
              />
            ))}
          </Flex>
        </div>
      )}
      <Form className="chat-editor-area" onSubmit={handleSubmit}>
        <TextareaAutosize
          minRows={3}
          maxRows={6}
          value={message}
          placeholder="Type your message"
          onChange={({ target }) => setMessage(target.value)}
          className="form-control outline-none resize-none rounded-0 border-0 emojiarea-editor"
        />
        <Form.Group controlId="chatFileUpload">
          <Form.Label className="chat-file-upload cursor-pointer">
            <FontAwesomeIcon icon="paperclip" />
          </Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            multiple
            className="d-none"
            onChange={({ target }) => handleAddAttachment(target.files)}
          />
        </Form.Group>

        <Button
          variant="link"
          className="emoji-icon "
          onClick={() => setPreviewEmoji(!previewEmoji)}
        >
          <FontAwesomeIcon
            icon={['far', 'laugh-beam']}
            onClick={() => setPreviewEmoji(!previewEmoji)}
          />
        </Button>

        {previewEmoji && (
          <div className="chat-emoji-picker" dir="ltr">
            <Picker
              set="google"
              onEmojiSelect={addEmoji}
              theme={isDark ? 'dark' : 'light'}
              previewPosition="none"
              skinTonePosition="none"
            />
          </div>
        )}

        <Button
          variant="send"
          size="sm"
          className={classNames('shadow-none', {
            'text-primary': message.length > 0
          })}
          type="submit"
        >
          Send
        </Button>
      </Form>
    </>
  );
};

// MessageTextArea.propTypes = {
//   thread: PropTypes.object
// };

export default MessageTextArea;
