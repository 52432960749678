import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const ChatMemberCount = ({ members, onClickEvent }) => {
  return (
    <>
      {members.length === 0 ? (
        <div className="fs--1 cursor-pointer text-600" onClick={onClickEvent}>
          <FontAwesomeIcon icon="user" />
          &nbsp;(
          {!!members && members.length})
        </div>
      ) : (
        <OverlayTrigger
          defaultShow={false}
          placement="right"
          overlay={
            <Tooltip style={{ position: 'fixed' }}>
              {members.join(' / ')}
            </Tooltip>
          }
        >
          <div className="fs--1 cursor-pointer text-600" onClick={onClickEvent}>
            <FontAwesomeIcon icon="user" />
            &nbsp;(
            {!!members && members.length})
          </div>
        </OverlayTrigger>
      )}
    </>
  );
};

export default ChatMemberCount;
