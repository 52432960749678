import React from 'react';
import { Row, Col } from 'react-bootstrap';
import BasicECharts from 'components/common/BasicEChart';
import * as echarts from 'echarts/core';
import { getColor } from 'helpers/utils';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AISummaryStatisticsChart = () => {
  const radius = ['90%', '55%']; // Adjusted radius

  const tooltipFormatter = params => {
    return `<div class="dot me-1 fs--2 d-inline-block" style="background-color: ${getColor(
      params.data.color
    )}"></div><span class="fs--1"><strong>${params.data.name}:</strong> ${
      params.value
    }건</span>`;
  };

  const getOptions = (data, radius) => {
    const totalValue = data.reduce((acc, item) => acc + item.value, 0);
    return {
      color: [getColor('danger'), getColor('primary')],
      tooltip: {
        padding: [7, 10],
        transitionDuration: 0,
        backgroundColor: getColor('gray-100'),
        borderColor: getColor('gray-300'),
        textStyle: { color: getColor('dark') },
        borderWidth: 1,
        formatter: tooltipFormatter
      },
      series: [
        {
          name: '총 사고 건수',
          type: 'pie',
          radius,
          avoidLabelOverlap: false,
          emphasis: {
            scale: false
          },
          itemStyle: {
            borderWidth: 2,
            borderColor: getColor('gray-100')
          },
          label: {
            show: true,
            position: 'center',
            formatter: `총 ${totalValue}건`,
            fontSize: '0.8333333333rem',
            fontFamily: 'Pretendard',
            color: getColor('dark')
          },
          data
        }
      ]
    };
  };

  const dummyData = [
    {
      title: '통합 AI (동별)',
      data: [
        { name: '미예측 사고', value: 23, color: 'danger' },
        { name: '사고 예측', value: 11, color: 'primary' }
      ]
    },
    {
      title: '날씨 AI (동별)',
      data: [
        { name: '미예측 사고', value: 28, color: 'danger' },
        { name: '사고 예측', value: 6, color: 'primary' }
      ]
    },
    {
      title: '지하수 AI (동별)',
      data: [
        { name: '미예측 사고', value: 12, color: 'danger' },
        { name: '사고 예측', value: 22, color: 'primary' }
      ]
    }
  ];

  const LabelItem = ({ item, index }) => {
    const { name, color, value } = item;
    return (
      <Flex
        alignItems="center"
        justifyContent="between"
        className={`px-4 fw-semi-bold fs--2 ${index === 0 && 'mt-3'}`}
      >
        <p className="mb-1">
          <FontAwesomeIcon
            icon="circle"
            className={`me-2 text-${
              color.split('-')[1] || color || 'text-info'
            }`}
          />
          {name}
        </p>
        <div>{value}건</div>
      </Flex>
    );
  };

  return (
    <Row>
      {dummyData.map((chartData, index) => (
        <Col key={index} md={4} className="mb-4">
          <div className="mb-3 text-center">
            <h6 className={'fs--1 text-900'}>{chartData.title}</h6>
          </div>
          <BasicECharts
            echarts={echarts}
            options={getOptions(chartData.data, radius)}
            style={{ width: '100%', height: '200px' }}
          />
          <Col className="px-2 text-center mt-2">
            {chartData.data.map((item, idx) => (
              <LabelItem item={item} index={idx} key={idx} />
            ))}
          </Col>
        </Col>
      ))}
    </Row>
  );
};

export default AISummaryStatisticsChart;
