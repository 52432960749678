import { getSearchPeriodAmount, isEmpty } from 'helpers/utils';
import moment from 'moment/moment';
import { chainAxios } from 'helpers/chainAxios';

export const getStatEvent = async (startDate, endDate) => {
  let pStartDate = startDate;
  let pEndDate = endDate;

  if (isEmpty(pStartDate) || isEmpty(pEndDate)) {
    let amount = getSearchPeriodAmount('1W');
    pStartDate = moment().subtract(amount, 'days').format('YYYY-MM-DD');
    pEndDate = moment().format('YYYY-MM-DD');
  }

  // http://112.175.18.232:7000/api/statistics/event?startDate=2024-03-01&endDate=2024-03-31
  let url =
    '/api/statistics/event?startDate=' + pStartDate + '&endDate=' + pEndDate;
  // console.log('getStatEvent : ', url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response);
    return response.data.data;
  }
  return {};
};

export const getStatSensorProcess = async (startDate, endDate) => {
  let pStartDate = startDate;
  let pEndDate = endDate;

  if (isEmpty(pStartDate) || isEmpty(pEndDate)) {
    let amount = getSearchPeriodAmount('1W');
    pStartDate = moment().subtract(amount, 'days').format('YYYY-MM-DD');
    pEndDate = moment().format('YYYY-MM-DD');
  }

  // http://112.175.18.232:7000/api/statistics/eventResult?category=sensor&startDate=2024-03-01&endDate=2024-03-31
  let url =
    '/api/statistics/eventResult?category=sensor&startDate=' +
    pStartDate +
    '&endDate=' +
    pEndDate;
  // console.log('getStatSensorProcess : ', url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response);
    return response.data.data;
  }
  return {};
};
