import React from 'react';
import {
  Form,
  Tabs,
  Tab,
  Nav,
  FormControl,
  Button,
  InputGroup
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { chainAxios } from 'helpers/chainAxios';

import Flex from 'components/common/Flex';
import SimpleBarReact from 'simplebar-react';
import classNames from 'classnames';
import ProjectItems from './ProjectItems';
// import { MapsContext } from 'context/Context';
// import { useQuery, useQueryClient } from 'react-query';
import { useLeftAreaProjects } from '../hook/useLeftArea';

const ProjectContent = () => {
  // const queryClient = useQueryClient();

  const {
    data: viewProjects,
    setSelectedTabValue,
    searchText,
    setSearchText,
    // favorite,
    setFavorite
  } = useLeftAreaProjects();

  return (
    <>
      <InputGroup className="position-relative">
        <FormControl
          size="sm"
          id="search"
          type="search"
          className="shadow-none"
          placeholder="검색어를 입력하세요."
          onChange={e => setSearchText(e.target.value)}
        />
        <Button
          size="sm"
          variant="outline-secondary"
          className="border-300 hover-border-secondary"
        >
          <FontAwesomeIcon icon="search" className="fs--1" />
        </Button>
      </InputGroup>
      <Flex
        alignItems="center"
        alignContent={'center'}
        direction="row"
        justifyContent="between"
        className={'px-3'}
      >
        <Flex
          alignItems="start"
          alignContent={'end'}
          direction="column"
          justifyContent="start"
          className={'fs--1'}
        >
          총 {viewProjects.length}개소
        </Flex>
        <Flex
          alignItems={'end'}
          alignContent={'end'}
          direction="column"
          justifyContent="end"
          className={'fs--1'}
        >
          <Form.Check
            type="switch"
            id="checkedSwitch"
            label="즐겨찾기"
            // defaultChecked
            onClick={e => {
              setFavorite(e.target.checked);
            }}
          />
        </Flex>
      </Flex>
      <Tabs
        defaultActiveKey="ALL"
        id="fill-project-tab"
        className={classNames('nav')}
        onSelect={value => {
          setSelectedTabValue(value);
        }}
        fill
      >
        <Tab eventKey="ALL" title={<h6>전체</h6>}></Tab>
        <Tab eventKey="NO_ISSUE" title={<h6>진행중</h6>}></Tab>
        <Tab eventKey="ON_ISSUE" title={<h6>점검요함</h6>}></Tab>
        {/*<Tab eventKey="BEFORE" title={<h6>예정</h6>}></Tab>*/}
        <Tab eventKey="FINISH" title={<h6>완료</h6>}></Tab>
      </Tabs>
      <SimpleBarReact
        style={{ height: '100%', minWidth: '65px' }}
        className={'mt-0'}
      >
        <Nav className="border-0" activeKey="">
          {!!viewProjects &&
            viewProjects.map(project => (
              <ProjectItems
                key={project.id}
                project={project}
                searchText={searchText}
              />
            ))}
        </Nav>
      </SimpleBarReact>
    </>
  );
};

export default ProjectContent;
