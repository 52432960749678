export const guSeoulData = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        rgnCode: '11110',
        rgnKo: ['서울특별시', '종로구'],
        colCode: '11000',
        rgnSize: '2',
        predVal: [
          0.5924, 0.6024, 0.5765, 0.6474, 0.8111, 0.7375, 0.8091, 0.8509,
          0.6862, 0.7714, 0.7688, 0.5887, 0.3028, 0.5235, 0.5783, 0.3415,
          0.3928, 0.6699, 0.5813, 0.6758, 0.7106, 0.8447, 0.7411, 0.8393,
          0.6718, 0.8187, 0.7483, 0.7705, 0.7533, 0.785, 0.5678
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.97508, 37.63123],
            [126.97464, 37.62974],
            [126.97492, 37.62897],
            [126.97842, 37.62929],
            [126.97953, 37.62889],
            [126.97993, 37.62855],
            [126.98019, 37.62793],
            [126.97988, 37.62745],
            [126.9796, 37.62638],
            [126.98091, 37.62539],
            [126.98185, 37.62431],
            [126.98365, 37.62115],
            [126.98318, 37.62018],
            [126.98316, 37.61927],
            [126.98373, 37.61777],
            [126.98441, 37.61689],
            [126.98455, 37.61602],
            [126.98516, 37.61552],
            [126.98542, 37.61514],
            [126.98659, 37.61399],
            [126.98644, 37.61356],
            [126.98615, 37.61321],
            [126.98619, 37.61295],
            [126.98566, 37.61136],
            [126.98581, 37.61073],
            [126.98588, 37.60946],
            [126.98685, 37.60886],
            [126.98671, 37.60826],
            [126.98627, 37.60772],
            [126.98613, 37.60739],
            [126.98638, 37.60685],
            [126.98632, 37.6065],
            [126.98676, 37.60609],
            [126.98667, 37.60509],
            [126.98679, 37.60452],
            [126.98667, 37.60402],
            [126.98597, 37.60365],
            [126.98578, 37.60345],
            [126.98475, 37.60336],
            [126.98437, 37.60312],
            [126.98399, 37.6032],
            [126.98287, 37.60213],
            [126.9826, 37.60224],
            [126.98068, 37.60161],
            [126.9805, 37.60146],
            [126.98016, 37.60145],
            [126.97924, 37.60104],
            [126.97852, 37.60099],
            [126.97821, 37.60074],
            [126.97794, 37.60031],
            [126.97768, 37.60022],
            [126.97741, 37.59939],
            [126.97741, 37.59912],
            [126.97715, 37.59845],
            [126.97672, 37.5979],
            [126.97689, 37.59775],
            [126.97797, 37.5977],
            [126.9786, 37.59727],
            [126.97864, 37.59707],
            [126.97857, 37.597],
            [126.97866, 37.59671],
            [126.97912, 37.5964],
            [126.9798, 37.59624],
            [126.98029, 37.59596],
            [126.98094, 37.59585],
            [126.98156, 37.59532],
            [126.98251, 37.59521],
            [126.98294, 37.59479],
            [126.98347, 37.5947],
            [126.98389, 37.59444],
            [126.98444, 37.59439],
            [126.98518, 37.59452],
            [126.98544, 37.59448],
            [126.98579, 37.59429],
            [126.98614, 37.59381],
            [126.98628, 37.59333],
            [126.98671, 37.59276],
            [126.98719, 37.59274],
            [126.98777, 37.59227],
            [126.98853, 37.59196],
            [126.98891, 37.59164],
            [126.9892, 37.59127],
            [126.98979, 37.59145],
            [126.99096, 37.59151],
            [126.99136, 37.59139],
            [126.99165, 37.59145],
            [126.99187, 37.59186],
            [126.99216, 37.592],
            [126.99357, 37.59174],
            [126.99366, 37.59149],
            [126.99386, 37.59142],
            [126.99432, 37.59144],
            [126.99476, 37.59166],
            [126.99535, 37.59227],
            [126.99643, 37.59206],
            [126.99677, 37.59222],
            [126.99744, 37.59221],
            [126.99844, 37.59246],
            [126.99991, 37.59235],
            [127.00063, 37.59245],
            [127.00094, 37.5924],
            [127.00162, 37.59189],
            [127.00172, 37.59172],
            [127.00192, 37.59121],
            [127.00174, 37.59068],
            [127.00185, 37.59014],
            [127.00231, 37.58926],
            [127.00253, 37.589],
            [127.00348, 37.58872],
            [127.00359, 37.58845],
            [127.00355, 37.58829],
            [127.00401, 37.58807],
            [127.00408, 37.58773],
            [127.00402, 37.58772],
            [127.00412, 37.58763],
            [127.00425, 37.58718],
            [127.00512, 37.58699],
            [127.00587, 37.58697],
            [127.00667, 37.58609],
            [127.00656, 37.58553],
            [127.0069, 37.58528],
            [127.00691, 37.58475],
            [127.00722, 37.58346],
            [127.00733, 37.58331],
            [127.00731, 37.58307],
            [127.00678, 37.58235],
            [127.00705, 37.582],
            [127.00734, 37.58187],
            [127.00751, 37.58165],
            [127.00781, 37.58151],
            [127.008, 37.58126],
            [127.00832, 37.58106],
            [127.00864, 37.58047],
            [127.01056, 37.58026],
            [127.01113, 37.58104],
            [127.01178, 37.58157],
            [127.01211, 37.58156],
            [127.01285, 37.58133],
            [127.01325, 37.58141],
            [127.01343, 37.58162],
            [127.0141, 37.58179],
            [127.01421, 37.58206],
            [127.01483, 37.58234],
            [127.01549, 37.58185],
            [127.01639, 37.58194],
            [127.0167, 37.58171],
            [127.01685, 37.58143],
            [127.01682, 37.58122],
            [127.01695, 37.58102],
            [127.01689, 37.58086],
            [127.01728, 37.58055],
            [127.0173, 37.58026],
            [127.01755, 37.57973],
            [127.01797, 37.57952],
            [127.01829, 37.57905],
            [127.01819, 37.57866],
            [127.01769, 37.57814],
            [127.0181, 37.57762],
            [127.01844, 37.57768],
            [127.01876, 37.57753],
            [127.01912, 37.57774],
            [127.01907, 37.57802],
            [127.02148, 37.57885],
            [127.0218, 37.57886],
            [127.02272, 37.57835],
            [127.02253, 37.57809],
            [127.02285, 37.57785],
            [127.02299, 37.57784],
            [127.02312, 37.57799],
            [127.02321, 37.57594],
            [127.02332, 37.5759],
            [127.02322, 37.57578],
            [127.02336, 37.57191],
            [127.0222, 37.57202],
            [127.02075, 37.57172],
            [127.01902, 37.57102],
            [127.01711, 37.56996],
            [127.01527, 37.56967],
            [127.01526, 37.56974],
            [127.00984, 37.56977],
            [127.00645, 37.56961],
            [127.00226, 37.56962],
            [126.99734, 37.56877],
            [126.98991, 37.56812],
            [126.98893, 37.5681],
            [126.98664, 37.56823],
            [126.98233, 37.56905],
            [126.98058, 37.56899],
            [126.97801, 37.56918],
            [126.97758, 37.56931],
            [126.97757, 37.56926],
            [126.97645, 37.56923],
            [126.97638, 37.56949],
            [126.97471, 37.5692],
            [126.97428, 37.56925],
            [126.97417, 37.56934],
            [126.9732, 37.56932],
            [126.97319, 37.56916],
            [126.97288, 37.56919],
            [126.97282, 37.56904],
            [126.97259, 37.56906],
            [126.97242, 37.56883],
            [126.97253, 37.56852],
            [126.97193, 37.5683],
            [126.97166, 37.56836],
            [126.97151, 37.56871],
            [126.9712, 37.56882],
            [126.96951, 37.56816],
            [126.96943, 37.56828],
            [126.96927, 37.56813],
            [126.9691, 37.56824],
            [126.9667, 37.56582],
            [126.96698, 37.56551],
            [126.96539, 37.5671],
            [126.96401, 37.56862],
            [126.95856, 37.57388],
            [126.95637, 37.57568],
            [126.95563, 37.57659],
            [126.95546, 37.57655],
            [126.95356, 37.57875],
            [126.95398, 37.57896],
            [126.95518, 37.57932],
            [126.95564, 37.57967],
            [126.9572, 37.57982],
            [126.95722, 37.58035],
            [126.95803, 37.58074],
            [126.95817, 37.58098],
            [126.95816, 37.58117],
            [126.95764, 37.58205],
            [126.95757, 37.5823],
            [126.9577, 37.58262],
            [126.9576, 37.58288],
            [126.95766, 37.58317],
            [126.958, 37.58412],
            [126.958, 37.58444],
            [126.95778, 37.58495],
            [126.95783, 37.58507],
            [126.95747, 37.58605],
            [126.95737, 37.58708],
            [126.95749, 37.58796],
            [126.95733, 37.58872],
            [126.95749, 37.59008],
            [126.95783, 37.59146],
            [126.95782, 37.59256],
            [126.95753, 37.59303],
            [126.95753, 37.59351],
            [126.95856, 37.59418],
            [126.95866, 37.59445],
            [126.95887, 37.59456],
            [126.95905, 37.59478],
            [126.95907, 37.59502],
            [126.95865, 37.59585],
            [126.95871, 37.59637],
            [126.95853, 37.59672],
            [126.95827, 37.59779],
            [126.95804, 37.59816],
            [126.95771, 37.59837],
            [126.95731, 37.59853],
            [126.95675, 37.59858],
            [126.95677, 37.59874],
            [126.95671, 37.59879],
            [126.95544, 37.59926],
            [126.95488, 37.59931],
            [126.95414, 37.5991],
            [126.95384, 37.59867],
            [126.95351, 37.59858],
            [126.95329, 37.59859],
            [126.95317, 37.59875],
            [126.95312, 37.59889],
            [126.95318, 37.59901],
            [126.95353, 37.59947],
            [126.95347, 37.5997],
            [126.95351, 37.60009],
            [126.95275, 37.60117],
            [126.95263, 37.60156],
            [126.9527, 37.60176],
            [126.95262, 37.60227],
            [126.95252, 37.60245],
            [126.95272, 37.6026],
            [126.95294, 37.60295],
            [126.95327, 37.6032],
            [126.95365, 37.60427],
            [126.95399, 37.60475],
            [126.95408, 37.60507],
            [126.95311, 37.60551],
            [126.95272, 37.60592],
            [126.95274, 37.60631],
            [126.95261, 37.60668],
            [126.95177, 37.6074],
            [126.95148, 37.60802],
            [126.95062, 37.60836],
            [126.95058, 37.60888],
            [126.95034, 37.60911],
            [126.95038, 37.61063],
            [126.94983, 37.61166],
            [126.95048, 37.61287],
            [126.95073, 37.61353],
            [126.9508, 37.61385],
            [126.95059, 37.61514],
            [126.95072, 37.61554],
            [126.95047, 37.6161],
            [126.94985, 37.61876],
            [126.94992, 37.62093],
            [126.94923, 37.62236],
            [126.9489, 37.62326],
            [126.949, 37.62434],
            [126.95045, 37.62642],
            [126.95161, 37.62655],
            [126.95202, 37.62687],
            [126.95275, 37.62706],
            [126.95351, 37.62738],
            [126.95401, 37.62736],
            [126.95518, 37.62816],
            [126.95557, 37.62828],
            [126.95642, 37.62823],
            [126.95675, 37.62862],
            [126.95748, 37.6291],
            [126.95881, 37.62959],
            [126.96144, 37.62996],
            [126.96377, 37.62979],
            [126.96433, 37.63036],
            [126.96523, 37.63077],
            [126.96605, 37.63082],
            [126.9668, 37.63069],
            [126.96823, 37.63073],
            [126.96939, 37.63102],
            [126.97058, 37.63149],
            [126.97133, 37.63162],
            [126.97303, 37.63237],
            [126.97367, 37.63203],
            [126.97445, 37.63198],
            [126.97539, 37.63173],
            [126.97508, 37.63123]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        rgnCode: '11140',
        rgnKo: ['서울특별시', '중구'],
        colCode: '11000',
        rgnSize: '2',
        predVal: [
          0.5924, 0.6024, 0.5765, 0.6474, 0.8111, 0.7375, 0.8091, 0.8509,
          0.6862, 0.7714, 0.7688, 0.5887, 0.3028, 0.5235, 0.5783, 0.3415,
          0.3928, 0.6699, 0.5813, 0.6758, 0.7106, 0.8447, 0.7411, 0.8393,
          0.6718, 0.8187, 0.7483, 0.7705, 0.7533, 0.785, 0.5678
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [127.02346, 37.56973],
            [127.02357, 37.56578],
            [127.02358, 37.5652],
            [127.02678, 37.56504],
            [127.02668, 37.5645],
            [127.02679, 37.56423],
            [127.0267, 37.56406],
            [127.02675, 37.56389],
            [127.02653, 37.56339],
            [127.02593, 37.5626],
            [127.0245, 37.56186],
            [127.02335, 37.56066],
            [127.02322, 37.56],
            [127.02276, 37.55933],
            [127.02239, 37.55903],
            [127.02285, 37.55896],
            [127.0229, 37.55786],
            [127.02255, 37.55786],
            [127.0219, 37.55748],
            [127.02095, 37.55776],
            [127.02092, 37.55783],
            [127.01959, 37.5575],
            [127.01966, 37.55727],
            [127.01942, 37.55744],
            [127.01875, 37.55722],
            [127.018, 37.55679],
            [127.01764, 37.5564],
            [127.01734, 37.55585],
            [127.01723, 37.55489],
            [127.01712, 37.55482],
            [127.01691, 37.55443],
            [127.0171, 37.55405],
            [127.01707, 37.5536],
            [127.01688, 37.55337],
            [127.01679, 37.5533],
            [127.01672, 37.55337],
            [127.01682, 37.55353],
            [127.01663, 37.55341],
            [127.01632, 37.55305],
            [127.01615, 37.55264],
            [127.01595, 37.55256],
            [127.01429, 37.55101],
            [127.01322, 37.55013],
            [127.01347, 37.5498],
            [127.01342, 37.54976],
            [127.01313, 37.55005],
            [127.01243, 37.54949],
            [127.01217, 37.54942],
            [127.01183, 37.54875],
            [127.0116, 37.54849],
            [127.01093, 37.54818],
            [127.01059, 37.54827],
            [127.01002, 37.54791],
            [127.00969, 37.54789],
            [127.00933, 37.54747],
            [127.00911, 37.54701],
            [127.00923, 37.54632],
            [127.00873, 37.54603],
            [127.00819, 37.54513],
            [127.00801, 37.54509],
            [127.00826, 37.54492],
            [127.00854, 37.54458],
            [127.00889, 37.54448],
            [127.00898, 37.54414],
            [127.00729, 37.54386],
            [127.00501, 37.5462],
            [127.00543, 37.54706],
            [127.00534, 37.54731],
            [127.00614, 37.54819],
            [127.00608, 37.54839],
            [127.0057, 37.5483],
            [127.00496, 37.54845],
            [127.0047, 37.5487],
            [127.00457, 37.54981],
            [127.00433, 37.55025],
            [127.00423, 37.55015],
            [127.00341, 37.55002],
            [127.00284, 37.54964],
            [127.00221, 37.54974],
            [127.00201, 37.54998],
            [127.00168, 37.55007],
            [127.00135, 37.55003],
            [127.00116, 37.5499],
            [127.00052, 37.55004],
            [127.00016, 37.54982],
            [126.99908, 37.54972],
            [126.99891, 37.54979],
            [126.99833, 37.54969],
            [126.99767, 37.54935],
            [126.99744, 37.54913],
            [126.99739, 37.54898],
            [126.99678, 37.54852],
            [126.99672, 37.54782],
            [126.99637, 37.54745],
            [126.99522, 37.54723],
            [126.99452, 37.54746],
            [126.99406, 37.54839],
            [126.99314, 37.54909],
            [126.99298, 37.54952],
            [126.99238, 37.55007],
            [126.99166, 37.55027],
            [126.991, 37.55077],
            [126.9908, 37.55106],
            [126.99024, 37.55128],
            [126.98939, 37.55133],
            [126.98915, 37.55147],
            [126.98865, 37.5516],
            [126.98782, 37.55146],
            [126.98756, 37.5515],
            [126.98707, 37.55228],
            [126.9866, 37.5526],
            [126.98612, 37.55335],
            [126.98542, 37.55373],
            [126.98462, 37.55314],
            [126.98402, 37.55305],
            [126.98367, 37.55313],
            [126.98332, 37.55337],
            [126.98248, 37.55349],
            [126.98202, 37.5538],
            [126.98172, 37.55387],
            [126.9811, 37.55382],
            [126.98087, 37.5539],
            [126.98039, 37.55332],
            [126.97967, 37.55332],
            [126.97873, 37.5532],
            [126.97871, 37.5538],
            [126.9788, 37.55408],
            [126.97811, 37.55441],
            [126.97726, 37.55501],
            [126.97655, 37.55522],
            [126.97643, 37.55309],
            [126.97583, 37.55339],
            [126.97435, 37.55355],
            [126.97241, 37.55465],
            [126.97241, 37.55488],
            [126.96917, 37.55488],
            [126.96919, 37.55566],
            [126.96686, 37.55447],
            [126.96571, 37.55415],
            [126.96464, 37.55337],
            [126.96338, 37.55226],
            [126.96241, 37.55159],
            [126.9623, 37.55182],
            [126.96229, 37.55291],
            [126.96201, 37.55403],
            [126.96208, 37.55403],
            [126.96173, 37.55479],
            [126.96174, 37.55499],
            [126.96198, 37.55523],
            [126.96202, 37.55513],
            [126.96214, 37.55554],
            [126.9625, 37.55592],
            [126.96275, 37.55601],
            [126.96316, 37.55653],
            [126.96336, 37.55668],
            [126.96343, 37.55694],
            [126.96322, 37.55732],
            [126.96305, 37.55741],
            [126.9631, 37.55822],
            [126.96281, 37.55807],
            [126.96208, 37.55846],
            [126.96157, 37.55844],
            [126.96156, 37.5586],
            [126.96175, 37.55881],
            [126.96168, 37.55903],
            [126.96328, 37.55921],
            [126.96396, 37.55946],
            [126.96559, 37.56037],
            [126.96683, 37.56127],
            [126.96741, 37.56149],
            [126.96946, 37.56198],
            [126.96888, 37.56308],
            [126.96756, 37.56488],
            [126.9667, 37.56582],
            [126.9691, 37.56825],
            [126.96927, 37.56813],
            [126.96943, 37.56828],
            [126.96951, 37.56816],
            [126.97118, 37.56882],
            [126.97151, 37.56871],
            [126.97167, 37.56835],
            [126.9719, 37.56832],
            [126.97255, 37.56847],
            [126.97242, 37.56883],
            [126.97259, 37.56906],
            [126.97281, 37.569],
            [126.97288, 37.56919],
            [126.97319, 37.56916],
            [126.9732, 37.56932],
            [126.97417, 37.56934],
            [126.97427, 37.56933],
            [126.97428, 37.56925],
            [126.97471, 37.5692],
            [126.97638, 37.56949],
            [126.97645, 37.56923],
            [126.97763, 37.5693],
            [126.97798, 37.56919],
            [126.98056, 37.56899],
            [126.98258, 37.56903],
            [126.98628, 37.56828],
            [126.98892, 37.5681],
            [126.99015, 37.56814],
            [126.99734, 37.56877],
            [127.00226, 37.56962],
            [127.00645, 37.56961],
            [127.00984, 37.56977],
            [127.01526, 37.56974],
            [127.01527, 37.56967],
            [127.01711, 37.56996],
            [127.01902, 37.57102],
            [127.02073, 37.57171],
            [127.0222, 37.57202],
            [127.02339, 37.5719],
            [127.02346, 37.56973]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        rgnCode: '11170',
        rgnKo: ['서울특별시', '용산구'],
        colCode: '11000',
        rgnSize: '2',
        predVal: [
          0.5924, 0.6024, 0.5765, 0.6474, 0.8111, 0.7375, 0.8091, 0.8509,
          0.6862, 0.7714, 0.7688, 0.5887, 0.3028, 0.5235, 0.5783, 0.3415,
          0.3928, 0.6699, 0.5813, 0.6758, 0.7106, 0.8447, 0.7411, 0.8393,
          0.6718, 0.8187, 0.7483, 0.7705, 0.7533, 0.785, 0.5678
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.9725, 37.5546],
            [126.97435, 37.55355],
            [126.97583, 37.55339],
            [126.97643, 37.55309],
            [126.97655, 37.55522],
            [126.97726, 37.55501],
            [126.97811, 37.55441],
            [126.97879, 37.55409],
            [126.97872, 37.55383],
            [126.97873, 37.5532],
            [126.97967, 37.55332],
            [126.98039, 37.55332],
            [126.98087, 37.5539],
            [126.9811, 37.55382],
            [126.98172, 37.55387],
            [126.98202, 37.5538],
            [126.98248, 37.55349],
            [126.98332, 37.55337],
            [126.98367, 37.55313],
            [126.98402, 37.55305],
            [126.9846, 37.55314],
            [126.98541, 37.55372],
            [126.98611, 37.55336],
            [126.9866, 37.5526],
            [126.98705, 37.5523],
            [126.98756, 37.5515],
            [126.98777, 37.55146],
            [126.98865, 37.5516],
            [126.98915, 37.55147],
            [126.98939, 37.55133],
            [126.99024, 37.55128],
            [126.9908, 37.55106],
            [126.991, 37.55077],
            [126.99166, 37.55027],
            [126.99238, 37.55007],
            [126.99298, 37.54952],
            [126.99314, 37.54909],
            [126.99342, 37.54882],
            [126.99405, 37.54841],
            [126.99452, 37.54746],
            [126.99522, 37.54723],
            [126.99637, 37.54745],
            [126.99672, 37.54782],
            [126.99678, 37.54852],
            [126.99739, 37.54898],
            [126.99744, 37.54913],
            [126.99767, 37.54935],
            [126.99833, 37.54969],
            [126.99891, 37.54979],
            [126.99908, 37.54972],
            [127.00012, 37.54981],
            [127.00052, 37.55004],
            [127.00081, 37.55003],
            [127.00112, 37.5499],
            [127.00135, 37.55003],
            [127.00168, 37.55007],
            [127.00201, 37.54998],
            [127.00221, 37.54974],
            [127.00284, 37.54964],
            [127.00337, 37.55],
            [127.00423, 37.55015],
            [127.00433, 37.55025],
            [127.00457, 37.54981],
            [127.0047, 37.5487],
            [127.00496, 37.54845],
            [127.0057, 37.5483],
            [127.00608, 37.54839],
            [127.00613, 37.54821],
            [127.00556, 37.54751],
            [127.00533, 37.54735],
            [127.00543, 37.54706],
            [127.00501, 37.5462],
            [127.00726, 37.54386],
            [127.00899, 37.54413],
            [127.00895, 37.54274],
            [127.00867, 37.54198],
            [127.00879, 37.54157],
            [127.00916, 37.54127],
            [127.00926, 37.54105],
            [127.00917, 37.54064],
            [127.00931, 37.54002],
            [127.00959, 37.53964],
            [127.00995, 37.53939],
            [127.01073, 37.5393],
            [127.01148, 37.53903],
            [127.01261, 37.53934],
            [127.01282, 37.53927],
            [127.01322, 37.53889],
            [127.01337, 37.5389],
            [127.01346, 37.53882],
            [127.01386, 37.53904],
            [127.01397, 37.53881],
            [127.01479, 37.53878],
            [127.01507, 37.53828],
            [127.01517, 37.53838],
            [127.01545, 37.53831],
            [127.01548, 37.53823],
            [127.01567, 37.53827],
            [127.01675, 37.53785],
            [127.01776, 37.53785],
            [127.01776, 37.53526],
            [127.01751, 37.53484],
            [127.01775, 37.53502],
            [127.01776, 37.53426],
            [127.0134, 37.53064],
            [127.01, 37.52704],
            [127.00647, 37.52346],
            [127.00079, 37.5195],
            [126.99067, 37.5131],
            [126.98553, 37.50654],
            [126.97819, 37.50655],
            [126.97526, 37.50701],
            [126.97012, 37.50901],
            [126.96686, 37.50989],
            [126.96496, 37.51082],
            [126.96258, 37.51262],
            [126.9612, 37.5135],
            [126.96017, 37.51382],
            [126.9547, 37.51606],
            [126.94989, 37.51753],
            [126.94988, 37.52703],
            [126.94741, 37.53017],
            [126.94717, 37.53063],
            [126.94461, 37.53376],
            [126.9449, 37.5342],
            [126.94482, 37.53452],
            [126.94508, 37.53493],
            [126.94554, 37.53499],
            [126.94544, 37.53518],
            [126.94628, 37.53569],
            [126.9464, 37.53574],
            [126.94652, 37.53558],
            [126.94718, 37.53537],
            [126.94738, 37.53522],
            [126.94748, 37.5353],
            [126.9474, 37.5354],
            [126.94748, 37.5358],
            [126.94758, 37.53584],
            [126.94784, 37.53562],
            [126.94828, 37.53565],
            [126.94875, 37.53559],
            [126.94899, 37.53574],
            [126.94989, 37.53604],
            [126.9506, 37.53609],
            [126.95128, 37.53629],
            [126.95155, 37.53654],
            [126.95211, 37.53678],
            [126.95336, 37.53749],
            [126.95365, 37.53834],
            [126.95407, 37.53891],
            [126.95519, 37.53918],
            [126.95573, 37.53947],
            [126.95607, 37.53996],
            [126.95666, 37.54059],
            [126.95652, 37.54071],
            [126.95673, 37.54086],
            [126.95697, 37.54134],
            [126.95699, 37.54163],
            [126.95818, 37.54231],
            [126.95835, 37.54276],
            [126.95811, 37.5429],
            [126.95761, 37.54297],
            [126.95742, 37.54321],
            [126.95754, 37.5437],
            [126.95746, 37.54401],
            [126.95758, 37.54422],
            [126.95811, 37.54442],
            [126.95782, 37.54514],
            [126.95791, 37.54545],
            [126.95839, 37.5455],
            [126.95897, 37.54574],
            [126.95931, 37.54605],
            [126.95953, 37.54597],
            [126.96005, 37.54607],
            [126.96013, 37.54635],
            [126.96108, 37.54742],
            [126.96171, 37.54759],
            [126.96223, 37.54813],
            [126.96223, 37.54861],
            [126.96291, 37.54868],
            [126.96334, 37.54864],
            [126.96374, 37.54876],
            [126.96373, 37.54923],
            [126.9639, 37.54978],
            [126.96357, 37.54999],
            [126.96349, 37.55018],
            [126.96325, 37.55044],
            [126.96317, 37.55069],
            [126.96235, 37.55151],
            [126.96338, 37.55226],
            [126.96464, 37.55337],
            [126.96571, 37.55415],
            [126.96701, 37.55455],
            [126.96919, 37.55566],
            [126.96917, 37.55488],
            [126.97241, 37.55488],
            [126.97241, 37.55465],
            [126.9725, 37.5546]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        rgnCode: '11200',
        rgnKo: ['서울특별시', '성동구'],
        colCode: '11000',
        rgnSize: '2',
        predVal: [
          0.5924, 0.6024, 0.5765, 0.6474, 0.8111, 0.7375, 0.8091, 0.8509,
          0.6862, 0.7714, 0.7688, 0.5887, 0.3028, 0.5235, 0.5783, 0.3415,
          0.3928, 0.6699, 0.5813, 0.6758, 0.7106, 0.8447, 0.7411, 0.8393,
          0.6718, 0.8187, 0.7483, 0.7705, 0.7533, 0.785, 0.5678
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [127.04353, 37.57229],
            [127.04795, 37.57038],
            [127.05876, 37.56231],
            [127.07106, 37.56055],
            [127.07099, 37.56047],
            [127.07373, 37.55941],
            [127.05622, 37.52833],
            [127.05513, 37.52868],
            [127.04947, 37.53227],
            [127.046, 37.53427],
            [127.04039, 37.53575],
            [127.04039, 37.53582],
            [127.02117, 37.53582],
            [127.01776, 37.53426],
            [127.01776, 37.53503],
            [127.01751, 37.53484],
            [127.01775, 37.53524],
            [127.01776, 37.53785],
            [127.01676, 37.53785],
            [127.01567, 37.53827],
            [127.01551, 37.53824],
            [127.01517, 37.53838],
            [127.01508, 37.53828],
            [127.01479, 37.53878],
            [127.01397, 37.53881],
            [127.01386, 37.53904],
            [127.01346, 37.53882],
            [127.01338, 37.53891],
            [127.01322, 37.53889],
            [127.01282, 37.53927],
            [127.01261, 37.53934],
            [127.01148, 37.53903],
            [127.01073, 37.5393],
            [127.00995, 37.53939],
            [127.00959, 37.53964],
            [127.00931, 37.54002],
            [127.00917, 37.54063],
            [127.00926, 37.54105],
            [127.00917, 37.54125],
            [127.00879, 37.54157],
            [127.00867, 37.54198],
            [127.00895, 37.54274],
            [127.00902, 37.54399],
            [127.00898, 37.54414],
            [127.00892, 37.54446],
            [127.00854, 37.54458],
            [127.00826, 37.54492],
            [127.00801, 37.54509],
            [127.00819, 37.54513],
            [127.00872, 37.54602],
            [127.00923, 37.54632],
            [127.00911, 37.54701],
            [127.00933, 37.54747],
            [127.00969, 37.54789],
            [127.01002, 37.54791],
            [127.01059, 37.54827],
            [127.01092, 37.54818],
            [127.0116, 37.54849],
            [127.01183, 37.54875],
            [127.01217, 37.54942],
            [127.01243, 37.54949],
            [127.01313, 37.55005],
            [127.0134, 37.54977],
            [127.01347, 37.5498],
            [127.01322, 37.55013],
            [127.01429, 37.55101],
            [127.01595, 37.55256],
            [127.01613, 37.55259],
            [127.01632, 37.55305],
            [127.01661, 37.5534],
            [127.01682, 37.55353],
            [127.01679, 37.5533],
            [127.01688, 37.55337],
            [127.01707, 37.5536],
            [127.0171, 37.55405],
            [127.01691, 37.55443],
            [127.01712, 37.55482],
            [127.01723, 37.55489],
            [127.01734, 37.55585],
            [127.01764, 37.5564],
            [127.01805, 37.55684],
            [127.01875, 37.55722],
            [127.01942, 37.55744],
            [127.01966, 37.55727],
            [127.01956, 37.55749],
            [127.02092, 37.55783],
            [127.02095, 37.55776],
            [127.0219, 37.55748],
            [127.02255, 37.55786],
            [127.0229, 37.55786],
            [127.02285, 37.55896],
            [127.02239, 37.55903],
            [127.02276, 37.55933],
            [127.02322, 37.56],
            [127.02339, 37.56073],
            [127.02346, 37.56073],
            [127.02377, 37.56116],
            [127.0245, 37.56186],
            [127.02593, 37.5626],
            [127.02653, 37.56339],
            [127.02675, 37.56389],
            [127.0267, 37.56415],
            [127.0268, 37.5642],
            [127.02669, 37.56449],
            [127.02673, 37.56477],
            [127.02667, 37.56479],
            [127.02678, 37.56503],
            [127.02359, 37.5652],
            [127.02357, 37.56581],
            [127.02356, 37.56632],
            [127.02339, 37.5719],
            [127.02419, 37.57142],
            [127.02459, 37.57105],
            [127.02519, 37.57085],
            [127.02572, 37.57091],
            [127.02616, 37.57126],
            [127.02772, 37.57084],
            [127.02768, 37.57041],
            [127.03029, 37.56981],
            [127.03097, 37.56978],
            [127.03155, 37.56986],
            [127.03253, 37.57022],
            [127.03304, 37.57051],
            [127.03415, 37.57136],
            [127.03566, 37.572],
            [127.03669, 37.57223],
            [127.03813, 37.57226],
            [127.03818, 37.57302],
            [127.0419, 37.57297],
            [127.04353, 37.57229]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        rgnCode: '11215',
        rgnKo: ['서울특별시', '광진구'],
        colCode: '11000',
        rgnSize: '2',
        predVal: [
          0.5924, 0.6024, 0.5765, 0.6474, 0.8111, 0.7375, 0.8091, 0.8509,
          0.6862, 0.7714, 0.7688, 0.5887, 0.3028, 0.5235, 0.5783, 0.3415,
          0.3928, 0.6699, 0.5813, 0.6758, 0.7106, 0.8447, 0.7411, 0.8393,
          0.6718, 0.8187, 0.7483, 0.7705, 0.7533, 0.785, 0.5678
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [127.1017, 37.57247],
            [127.10231, 37.57222],
            [127.10317, 37.57235],
            [127.10377, 37.57196],
            [127.10428, 37.57146],
            [127.10394, 37.57062],
            [127.10351, 37.57011],
            [127.10326, 37.56885],
            [127.10272, 37.56693],
            [127.10232, 37.56491],
            [127.10231, 37.56431],
            [127.10189, 37.56377],
            [127.10169, 37.56326],
            [127.10172, 37.5627],
            [127.10125, 37.56161],
            [127.10117, 37.56107],
            [127.10135, 37.56049],
            [127.10191, 37.55953],
            [127.10227, 37.55916],
            [127.10267, 37.55904],
            [127.10285, 37.55873],
            [127.10427, 37.55759],
            [127.10493, 37.55643],
            [127.1054, 37.55633],
            [127.10639, 37.55646],
            [127.10668, 37.55681],
            [127.10714, 37.5571],
            [127.10752, 37.55761],
            [127.10831, 37.55803],
            [127.10852, 37.55822],
            [127.10922, 37.55839],
            [127.10953, 37.55855],
            [127.11034, 37.55826],
            [127.11089, 37.55837],
            [127.11142, 37.55852],
            [127.11229, 37.559],
            [127.11356, 37.55858],
            [127.11397, 37.55852],
            [127.11396, 37.55685],
            [127.11532, 37.55687],
            [127.11431, 37.55437],
            [127.11156, 37.5505],
            [127.11155, 37.55037],
            [127.11147, 37.54693],
            [127.11024, 37.54501],
            [127.10828, 37.54141],
            [127.09016, 37.52701],
            [127.08563, 37.52476],
            [127.08113, 37.52341],
            [127.07997, 37.52295],
            [127.07695, 37.52251],
            [127.07432, 37.52287],
            [127.07206, 37.52342],
            [127.06866, 37.52387],
            [127.06752, 37.52463],
            [127.06646, 37.52509],
            [127.06546, 37.52509],
            [127.06079, 37.52688],
            [127.05622, 37.52833],
            [127.05949, 37.53424],
            [127.07374, 37.55941],
            [127.07239, 37.55996],
            [127.07243, 37.56006],
            [127.07409, 37.56456],
            [127.07688, 37.56792],
            [127.0782, 37.57187],
            [127.08333, 37.57137],
            [127.08621, 37.57083],
            [127.08818, 37.57085],
            [127.08847, 37.57022],
            [127.0905, 37.5697],
            [127.09376, 37.57057],
            [127.09479, 37.57075],
            [127.09561, 37.57061],
            [127.09581, 37.57101],
            [127.09585, 37.57134],
            [127.09632, 37.5713],
            [127.09742, 37.57195],
            [127.09833, 37.57236],
            [127.09857, 37.57265],
            [127.09948, 37.57274],
            [127.10086, 37.57373],
            [127.10097, 37.57345],
            [127.1017, 37.57247]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        rgnCode: '11230',
        rgnKo: ['서울특별시', '동대문구'],
        colCode: '11000',
        rgnSize: '2',
        predVal: [
          0.5168, 0.6024, 0.5525, 0.813, 0.8164, 0.7375, 0.8091, 0.8509, 0.6862,
          0.7714, 0.7688, 0.6415, 0.3028, 0.6849, 0.5783, 0.3415, 0.3928,
          0.8492, 0.6914, 0.8027, 0.7106, 0.8447, 0.7411, 0.8393, 0.6718,
          0.8187, 0.7906, 0.8134, 0.7946, 0.785, 0.7501
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [127.07111, 37.60706],
            [127.07153, 37.60464],
            [127.07241, 37.60288],
            [127.07287, 37.60133],
            [127.07288, 37.60107],
            [127.0725, 37.60025],
            [127.07206, 37.59969],
            [127.07152, 37.5991],
            [127.07116, 37.5988],
            [127.0708, 37.59862],
            [127.07032, 37.59821],
            [127.06984, 37.59773],
            [127.06949, 37.59724],
            [127.06943, 37.59512],
            [127.07045, 37.59289],
            [127.07072, 37.59191],
            [127.07072, 37.59148],
            [127.07061, 37.59096],
            [127.07023, 37.58996],
            [127.0703, 37.58953],
            [127.07063, 37.58914],
            [127.07104, 37.58896],
            [127.07189, 37.58765],
            [127.07237, 37.58683],
            [127.07238, 37.58667],
            [127.07261, 37.58653],
            [127.07217, 37.58498],
            [127.07223, 37.58488],
            [127.07537, 37.58115],
            [127.07526, 37.58099],
            [127.07581, 37.58095],
            [127.07617, 37.58033],
            [127.07646, 37.57934],
            [127.07723, 37.5735],
            [127.07723, 37.57292],
            [127.0782, 37.57187],
            [127.07688, 37.56794],
            [127.0741, 37.56459],
            [127.07239, 37.55996],
            [127.07099, 37.56047],
            [127.07106, 37.56055],
            [127.05876, 37.56231],
            [127.04795, 37.57038],
            [127.04191, 37.57297],
            [127.03818, 37.57302],
            [127.03813, 37.57227],
            [127.03674, 37.57224],
            [127.03559, 37.57198],
            [127.03418, 37.57139],
            [127.03307, 37.57052],
            [127.03254, 37.57023],
            [127.03155, 37.56986],
            [127.03097, 37.56978],
            [127.0303, 37.56981],
            [127.02768, 37.57041],
            [127.02772, 37.57084],
            [127.02616, 37.57126],
            [127.02572, 37.57091],
            [127.0255, 37.57084],
            [127.025, 37.57091],
            [127.02459, 37.57105],
            [127.02419, 37.57142],
            [127.02336, 37.57191],
            [127.02322, 37.57584],
            [127.02332, 37.5759],
            [127.02321, 37.576],
            [127.02314, 37.57802],
            [127.02327, 37.57801],
            [127.0233, 37.57843],
            [127.02956, 37.58269],
            [127.03095, 37.58425],
            [127.03159, 37.58517],
            [127.03164, 37.58536],
            [127.0323, 37.58567],
            [127.03207, 37.58586],
            [127.03314, 37.58655],
            [127.03386, 37.58748],
            [127.03554, 37.58894],
            [127.0363, 37.59014],
            [127.03622, 37.59118],
            [127.03876, 37.59127],
            [127.04063, 37.59568],
            [127.04079, 37.59563],
            [127.04076, 37.59529],
            [127.04108, 37.59555],
            [127.04139, 37.59549],
            [127.04184, 37.59646],
            [127.0427, 37.59673],
            [127.04302, 37.59695],
            [127.04368, 37.59629],
            [127.04412, 37.5964],
            [127.04478, 37.59636],
            [127.04551, 37.59661],
            [127.04617, 37.5962],
            [127.04677, 37.59604],
            [127.04733, 37.59614],
            [127.04743, 37.59647],
            [127.04782, 37.59675],
            [127.04797, 37.59725],
            [127.04834, 37.59744],
            [127.04907, 37.59814],
            [127.04913, 37.59874],
            [127.04975, 37.59976],
            [127.05041, 37.6001],
            [127.05034, 37.60061],
            [127.05101, 37.60117],
            [127.05119, 37.60122],
            [127.05172, 37.60075],
            [127.05178, 37.60055],
            [127.05209, 37.60015],
            [127.05365, 37.60048],
            [127.05392, 37.60042],
            [127.05381, 37.60061],
            [127.05425, 37.60065],
            [127.05507, 37.60132],
            [127.05541, 37.60101],
            [127.0563, 37.60133],
            [127.05731, 37.60151],
            [127.05722, 37.60127],
            [127.05733, 37.60119],
            [127.05918, 37.60107],
            [127.05931, 37.60133],
            [127.05962, 37.60148],
            [127.05999, 37.60155],
            [127.06021, 37.60173],
            [127.05993, 37.60231],
            [127.06015, 37.60283],
            [127.0601, 37.60306],
            [127.06018, 37.60317],
            [127.06052, 37.60345],
            [127.06071, 37.60353],
            [127.06077, 37.60348],
            [127.06144, 37.60422],
            [127.06148, 37.60446],
            [127.06193, 37.60487],
            [127.06216, 37.60493],
            [127.06219, 37.60512],
            [127.06308, 37.60526],
            [127.06364, 37.60525],
            [127.06495, 37.60586],
            [127.06549, 37.60549],
            [127.06619, 37.60555],
            [127.06629, 37.60577],
            [127.06679, 37.6061],
            [127.06786, 37.60655],
            [127.06861, 37.60663],
            [127.06868, 37.60674],
            [127.06977, 37.60726],
            [127.07012, 37.60725],
            [127.07026, 37.60703],
            [127.07037, 37.60703],
            [127.07039, 37.60718],
            [127.07067, 37.60756],
            [127.07106, 37.60762],
            [127.07111, 37.60706]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        rgnCode: '11260',
        rgnKo: ['서울특별시', '중랑구'],
        colCode: '11000',
        rgnSize: '2',
        predVal: [
          0.5168, 0.6024, 0.5525, 0.813, 0.8164, 0.7375, 0.8091, 0.8509, 0.6862,
          0.7714, 0.7688, 0.6415, 0.3028, 0.6849, 0.5783, 0.3415, 0.3928,
          0.8492, 0.6914, 0.8027, 0.7106, 0.8447, 0.7411, 0.8393, 0.6718,
          0.8187, 0.7906, 0.8134, 0.7946, 0.785, 0.7501
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [127.11131, 37.62069],
            [127.11214, 37.62031],
            [127.11351, 37.62004],
            [127.11502, 37.61954],
            [127.11572, 37.61963],
            [127.11619, 37.61895],
            [127.11587, 37.61858],
            [127.11715, 37.61789],
            [127.11702, 37.61735],
            [127.11725, 37.61673],
            [127.11667, 37.61602],
            [127.1168, 37.61495],
            [127.11668, 37.61468],
            [127.11706, 37.61414],
            [127.11724, 37.61263],
            [127.11749, 37.61183],
            [127.11729, 37.61118],
            [127.11696, 37.61071],
            [127.11679, 37.61028],
            [127.11677, 37.60984],
            [127.11688, 37.60953],
            [127.11669, 37.60886],
            [127.11726, 37.60863],
            [127.11803, 37.60815],
            [127.11848, 37.60761],
            [127.11806, 37.60659],
            [127.118, 37.60589],
            [127.11809, 37.60557],
            [127.11805, 37.6046],
            [127.11738, 37.60394],
            [127.11687, 37.60305],
            [127.11572, 37.60211],
            [127.11558, 37.60172],
            [127.11496, 37.60077],
            [127.1141, 37.60013],
            [127.11404, 37.6],
            [127.11406, 37.59952],
            [127.11549, 37.59765],
            [127.11688, 37.5955],
            [127.11667, 37.59402],
            [127.11655, 37.59392],
            [127.11545, 37.59352],
            [127.11359, 37.59334],
            [127.1132, 37.59321],
            [127.11064, 37.58916],
            [127.11035, 37.58804],
            [127.11012, 37.5875],
            [127.10994, 37.58599],
            [127.10971, 37.58522],
            [127.10931, 37.58455],
            [127.10934, 37.58407],
            [127.109, 37.5833],
            [127.10852, 37.58319],
            [127.1079, 37.58283],
            [127.10732, 37.58229],
            [127.10599, 37.58187],
            [127.10454, 37.58105],
            [127.10343, 37.58053],
            [127.10291, 37.57991],
            [127.10303, 37.57891],
            [127.10196, 37.57755],
            [127.10173, 37.57697],
            [127.10114, 37.57607],
            [127.10125, 37.57517],
            [127.1009, 37.57439],
            [127.10095, 37.57382],
            [127.10074, 37.5736],
            [127.09948, 37.57274],
            [127.09857, 37.57265],
            [127.09833, 37.57236],
            [127.09742, 37.57195],
            [127.09634, 37.5713],
            [127.09585, 37.57134],
            [127.09581, 37.57101],
            [127.09562, 37.57061],
            [127.0948, 37.57075],
            [127.09376, 37.57057],
            [127.09051, 37.5697],
            [127.08848, 37.57021],
            [127.08818, 37.57085],
            [127.08621, 37.57083],
            [127.08333, 37.57137],
            [127.0782, 37.57187],
            [127.07723, 37.57292],
            [127.07723, 37.5735],
            [127.07646, 37.57934],
            [127.07618, 37.5803],
            [127.07581, 37.58095],
            [127.07526, 37.58099],
            [127.07538, 37.58114],
            [127.07216, 37.58496],
            [127.07264, 37.58651],
            [127.0724, 37.58666],
            [127.0719, 37.58764],
            [127.07104, 37.58896],
            [127.0706, 37.58915],
            [127.0703, 37.58953],
            [127.07023, 37.58996],
            [127.07061, 37.59096],
            [127.07072, 37.59148],
            [127.07072, 37.59188],
            [127.07045, 37.59289],
            [127.06943, 37.59512],
            [127.06949, 37.59724],
            [127.06984, 37.59773],
            [127.07032, 37.59821],
            [127.0708, 37.59862],
            [127.07116, 37.5988],
            [127.07152, 37.5991],
            [127.07206, 37.59969],
            [127.0725, 37.60025],
            [127.07288, 37.60107],
            [127.07287, 37.60133],
            [127.07241, 37.60288],
            [127.07153, 37.60464],
            [127.07108, 37.60729],
            [127.07106, 37.60762],
            [127.07155, 37.60756],
            [127.07143, 37.60972],
            [127.07141, 37.60991],
            [127.07135, 37.60991],
            [127.07174, 37.61241],
            [127.07175, 37.61351],
            [127.07165, 37.61387],
            [127.07114, 37.61481],
            [127.07005, 37.61543],
            [127.07041, 37.61553],
            [127.07114, 37.61594],
            [127.07109, 37.61621],
            [127.07139, 37.6164],
            [127.07369, 37.61675],
            [127.07416, 37.61683],
            [127.07441, 37.61703],
            [127.07553, 37.61716],
            [127.07751, 37.61784],
            [127.07757, 37.61774],
            [127.07781, 37.61794],
            [127.07912, 37.61839],
            [127.07969, 37.61842],
            [127.08007, 37.61856],
            [127.08007, 37.61828],
            [127.08062, 37.61842],
            [127.08137, 37.61912],
            [127.08243, 37.61934],
            [127.08278, 37.61934],
            [127.08384, 37.61965],
            [127.08379, 37.61978],
            [127.08495, 37.6201],
            [127.08607, 37.62029],
            [127.08696, 37.62023],
            [127.08823, 37.61988],
            [127.08871, 37.62024],
            [127.08945, 37.61965],
            [127.09138, 37.61896],
            [127.09233, 37.61835],
            [127.09322, 37.618],
            [127.0935, 37.6181],
            [127.09367, 37.61807],
            [127.09445, 37.61899],
            [127.09493, 37.61901],
            [127.09552, 37.61917],
            [127.09619, 37.61902],
            [127.09662, 37.61943],
            [127.09665, 37.61956],
            [127.09849, 37.6202],
            [127.09882, 37.62039],
            [127.09966, 37.61991],
            [127.10087, 37.6194],
            [127.10167, 37.61947],
            [127.10179, 37.61968],
            [127.10169, 37.62002],
            [127.10321, 37.62019],
            [127.10337, 37.62028],
            [127.10381, 37.62029],
            [127.10407, 37.62012],
            [127.10398, 37.62009],
            [127.10405, 37.62006],
            [127.10462, 37.62007],
            [127.10484, 37.62008],
            [127.10565, 37.62038],
            [127.10581, 37.62026],
            [127.10659, 37.62058],
            [127.10709, 37.62047],
            [127.10913, 37.62051],
            [127.11002, 37.6207],
            [127.11052, 37.62105],
            [127.11131, 37.62069]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        rgnCode: '11290',
        rgnKo: ['서울특별시', '성북구'],
        colCode: '11000',
        rgnSize: '2',
        predVal: [
          0.5168, 0.6024, 0.5525, 0.813, 0.8164, 0.7375, 0.8091, 0.8509, 0.6862,
          0.7714, 0.7688, 0.6415, 0.3028, 0.6849, 0.5783, 0.3415, 0.3928,
          0.8492, 0.6914, 0.8027, 0.7106, 0.8447, 0.7411, 0.8393, 0.6718,
          0.8187, 0.7906, 0.8134, 0.7946, 0.785, 0.7501
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.98546, 37.63593],
            [126.98592, 37.63579],
            [126.98634, 37.63548],
            [126.98745, 37.63497],
            [126.9882, 37.63449],
            [126.98857, 37.6338],
            [126.98873, 37.63394],
            [126.98941, 37.63408],
            [126.98985, 37.63346],
            [126.98993, 37.6328],
            [126.99137, 37.63261],
            [126.99185, 37.63229],
            [126.99369, 37.63147],
            [126.9941, 37.63112],
            [126.99426, 37.63085],
            [126.99474, 37.63052],
            [126.99539, 37.63025],
            [126.99605, 37.62935],
            [126.99651, 37.62909],
            [126.99678, 37.62866],
            [126.99757, 37.62806],
            [126.99788, 37.62757],
            [126.99845, 37.62712],
            [126.99927, 37.6262],
            [126.99984, 37.62606],
            [127.0002, 37.62582],
            [127.00068, 37.62571],
            [127.00176, 37.62565],
            [127.00213, 37.62544],
            [127.00298, 37.62528],
            [127.00349, 37.62495],
            [127.0039, 37.62423],
            [127.00443, 37.62406],
            [127.00456, 37.62408],
            [127.0049, 37.62382],
            [127.00519, 37.62401],
            [127.00557, 37.62385],
            [127.00572, 37.62398],
            [127.00734, 37.62414],
            [127.00789, 37.62403],
            [127.0079, 37.6237],
            [127.00775, 37.62337],
            [127.00779, 37.62294],
            [127.00755, 37.62266],
            [127.00764, 37.62226],
            [127.00744, 37.62175],
            [127.00758, 37.6215],
            [127.00743, 37.62138],
            [127.00722, 37.62083],
            [127.00733, 37.6207],
            [127.00724, 37.62051],
            [127.00761, 37.62001],
            [127.00786, 37.61986],
            [127.00774, 37.61963],
            [127.00804, 37.61939],
            [127.00813, 37.61919],
            [127.00845, 37.61898],
            [127.0085, 37.61862],
            [127.00907, 37.61829],
            [127.00946, 37.6182],
            [127.00994, 37.61777],
            [127.00999, 37.61725],
            [127.01015, 37.6168],
            [127.01095, 37.61629],
            [127.01133, 37.61641],
            [127.0118, 37.61631],
            [127.01207, 37.61597],
            [127.01279, 37.61566],
            [127.01355, 37.61544],
            [127.01414, 37.61497],
            [127.01416, 37.61487],
            [127.01447, 37.61469],
            [127.01526, 37.61466],
            [127.016, 37.61475],
            [127.01615, 37.61486],
            [127.0165, 37.61484],
            [127.01652, 37.61494],
            [127.01753, 37.61462],
            [127.01821, 37.61399],
            [127.01863, 37.6138],
            [127.019, 37.61386],
            [127.01923, 37.6138],
            [127.01952, 37.61333],
            [127.01987, 37.61324],
            [127.02001, 37.61301],
            [127.02034, 37.61286],
            [127.0204, 37.6126],
            [127.02155, 37.61244],
            [127.02203, 37.6123],
            [127.0222, 37.61137],
            [127.02242, 37.61145],
            [127.02257, 37.6114],
            [127.02447, 37.61213],
            [127.02467, 37.61215],
            [127.02518, 37.61201],
            [127.02572, 37.61213],
            [127.02604, 37.61224],
            [127.02616, 37.61247],
            [127.02643, 37.61269],
            [127.02733, 37.61256],
            [127.0277, 37.61261],
            [127.02868, 37.61245],
            [127.03018, 37.61237],
            [127.03034, 37.61131],
            [127.03025, 37.60898],
            [127.03272, 37.61026],
            [127.03294, 37.61046],
            [127.03583, 37.61202],
            [127.03604, 37.61244],
            [127.03611, 37.61243],
            [127.03609, 37.61234],
            [127.03678, 37.61242],
            [127.03732, 37.61281],
            [127.03771, 37.61322],
            [127.03793, 37.61377],
            [127.03823, 37.61407],
            [127.03858, 37.61421],
            [127.03861, 37.61434],
            [127.03897, 37.61453],
            [127.03937, 37.61502],
            [127.03956, 37.61511],
            [127.03997, 37.61569],
            [127.04004, 37.61625],
            [127.04041, 37.61607],
            [127.04056, 37.61611],
            [127.04098, 37.61656],
            [127.04394, 37.61864],
            [127.04468, 37.61944],
            [127.04571, 37.62137],
            [127.04653, 37.62232],
            [127.04687, 37.6226],
            [127.04973, 37.62426],
            [127.05055, 37.62346],
            [127.05174, 37.62256],
            [127.05207, 37.62214],
            [127.0527, 37.62158],
            [127.05441, 37.6198],
            [127.05594, 37.61892],
            [127.0574, 37.6175],
            [127.06067, 37.61567],
            [127.06109, 37.61493],
            [127.06175, 37.61449],
            [127.06329, 37.61426],
            [127.06486, 37.61476],
            [127.0697, 37.61572],
            [127.07018, 37.61534],
            [127.07114, 37.61481],
            [127.07165, 37.61387],
            [127.07175, 37.61351],
            [127.07174, 37.61241],
            [127.07135, 37.60991],
            [127.07141, 37.60991],
            [127.07143, 37.60975],
            [127.07155, 37.60756],
            [127.07106, 37.60762],
            [127.07112, 37.60702],
            [127.07069, 37.60756],
            [127.07039, 37.60718],
            [127.07038, 37.60703],
            [127.07028, 37.60703],
            [127.07012, 37.60725],
            [127.06977, 37.60726],
            [127.06874, 37.60678],
            [127.06863, 37.60664],
            [127.06789, 37.60655],
            [127.06679, 37.6061],
            [127.06632, 37.6058],
            [127.06621, 37.60559],
            [127.06549, 37.60549],
            [127.06495, 37.60586],
            [127.06364, 37.60525],
            [127.06308, 37.60526],
            [127.0622, 37.60513],
            [127.06218, 37.60494],
            [127.06196, 37.60489],
            [127.06151, 37.60449],
            [127.06144, 37.60422],
            [127.06077, 37.60348],
            [127.06043, 37.60341],
            [127.06018, 37.60317],
            [127.0601, 37.60306],
            [127.06015, 37.60283],
            [127.05994, 37.60233],
            [127.06021, 37.60173],
            [127.06003, 37.60157],
            [127.05966, 37.60151],
            [127.05931, 37.60133],
            [127.05918, 37.60107],
            [127.05733, 37.60119],
            [127.05722, 37.60127],
            [127.05734, 37.60151],
            [127.05638, 37.60135],
            [127.05543, 37.60102],
            [127.05509, 37.60132],
            [127.05425, 37.60065],
            [127.05381, 37.60061],
            [127.05392, 37.60042],
            [127.05355, 37.60046],
            [127.0521, 37.60015],
            [127.05179, 37.6005],
            [127.05172, 37.60075],
            [127.0512, 37.60122],
            [127.05101, 37.60117],
            [127.05034, 37.60061],
            [127.05041, 37.6001],
            [127.04975, 37.59976],
            [127.04913, 37.59874],
            [127.04908, 37.59818],
            [127.04884, 37.59785],
            [127.04866, 37.59779],
            [127.04834, 37.59744],
            [127.04797, 37.59725],
            [127.04782, 37.59675],
            [127.04743, 37.59647],
            [127.04733, 37.59614],
            [127.04679, 37.59604],
            [127.04619, 37.59619],
            [127.04551, 37.59661],
            [127.04479, 37.59637],
            [127.04412, 37.5964],
            [127.04371, 37.59627],
            [127.04302, 37.59695],
            [127.0427, 37.59673],
            [127.04184, 37.59646],
            [127.0414, 37.59551],
            [127.04112, 37.59554],
            [127.04076, 37.59529],
            [127.04079, 37.59563],
            [127.04063, 37.59568],
            [127.03876, 37.59127],
            [127.03622, 37.59118],
            [127.03633, 37.59014],
            [127.03554, 37.58895],
            [127.03389, 37.58751],
            [127.03334, 37.58674],
            [127.03305, 37.58646],
            [127.03207, 37.58586],
            [127.0323, 37.58568],
            [127.03164, 37.58536],
            [127.03098, 37.58428],
            [127.02956, 37.58269],
            [127.02333, 37.57844],
            [127.02328, 37.57798],
            [127.02319, 37.57807],
            [127.02299, 37.57784],
            [127.02285, 37.57785],
            [127.02253, 37.57809],
            [127.02276, 37.57833],
            [127.02248, 37.57846],
            [127.02232, 37.57863],
            [127.02183, 37.57884],
            [127.02148, 37.57885],
            [127.01907, 37.57802],
            [127.01911, 37.57776],
            [127.01877, 37.57752],
            [127.01844, 37.57768],
            [127.01812, 37.57759],
            [127.01769, 37.57814],
            [127.01818, 37.57861],
            [127.01829, 37.579],
            [127.01797, 37.57952],
            [127.01756, 37.57972],
            [127.01734, 37.58019],
            [127.01728, 37.58055],
            [127.01689, 37.58086],
            [127.01695, 37.58102],
            [127.01682, 37.58122],
            [127.01685, 37.58143],
            [127.01673, 37.58169],
            [127.01639, 37.58194],
            [127.01552, 37.58184],
            [127.01484, 37.58233],
            [127.01425, 37.58208],
            [127.01412, 37.58182],
            [127.01343, 37.58162],
            [127.01325, 37.58141],
            [127.01285, 37.58134],
            [127.01211, 37.58156],
            [127.01178, 37.58157],
            [127.01113, 37.58104],
            [127.01059, 37.58025],
            [127.00864, 37.58047],
            [127.00834, 37.58104],
            [127.00802, 37.58124],
            [127.00782, 37.5815],
            [127.00753, 37.58164],
            [127.00734, 37.58187],
            [127.00705, 37.582],
            [127.00678, 37.58235],
            [127.00731, 37.58305],
            [127.00733, 37.58331],
            [127.00722, 37.58346],
            [127.00691, 37.58475],
            [127.00691, 37.58524],
            [127.00656, 37.58553],
            [127.00667, 37.58609],
            [127.00587, 37.58697],
            [127.00512, 37.58699],
            [127.00425, 37.58718],
            [127.00413, 37.58761],
            [127.00408, 37.58773],
            [127.004, 37.5878],
            [127.00401, 37.58807],
            [127.00355, 37.58829],
            [127.00359, 37.58845],
            [127.00348, 37.58872],
            [127.00253, 37.589],
            [127.00231, 37.58926],
            [127.00185, 37.59014],
            [127.00174, 37.59068],
            [127.00192, 37.59121],
            [127.00172, 37.59172],
            [127.00162, 37.59189],
            [127.00094, 37.5924],
            [127.00063, 37.59245],
            [126.99991, 37.59235],
            [126.99844, 37.59246],
            [126.99744, 37.59221],
            [126.99677, 37.59222],
            [126.99643, 37.59206],
            [126.99535, 37.59227],
            [126.99476, 37.59166],
            [126.99432, 37.59144],
            [126.99386, 37.59142],
            [126.99366, 37.59149],
            [126.99359, 37.59171],
            [126.99216, 37.592],
            [126.99187, 37.59186],
            [126.99166, 37.59146],
            [126.9914, 37.5914],
            [126.99098, 37.5915],
            [126.98979, 37.59145],
            [126.9892, 37.59127],
            [126.98891, 37.59164],
            [126.98853, 37.59196],
            [126.98777, 37.59227],
            [126.98719, 37.59274],
            [126.98671, 37.59276],
            [126.98628, 37.59333],
            [126.98614, 37.59381],
            [126.98579, 37.59429],
            [126.98544, 37.59448],
            [126.98518, 37.59452],
            [126.98444, 37.59439],
            [126.98389, 37.59444],
            [126.98347, 37.5947],
            [126.98294, 37.59479],
            [126.98251, 37.59521],
            [126.98156, 37.59532],
            [126.98094, 37.59585],
            [126.98029, 37.59596],
            [126.9798, 37.59624],
            [126.97912, 37.5964],
            [126.97866, 37.59671],
            [126.97857, 37.597],
            [126.97864, 37.59707],
            [126.9786, 37.59727],
            [126.97797, 37.5977],
            [126.97689, 37.59775],
            [126.97672, 37.5979],
            [126.97715, 37.59845],
            [126.97741, 37.59912],
            [126.97741, 37.59939],
            [126.97768, 37.60022],
            [126.97794, 37.60031],
            [126.97821, 37.60074],
            [126.97852, 37.60099],
            [126.97924, 37.60104],
            [126.98016, 37.60145],
            [126.9805, 37.60146],
            [126.98068, 37.60161],
            [126.9826, 37.60224],
            [126.98287, 37.60213],
            [126.98399, 37.6032],
            [126.98436, 37.60311],
            [126.98475, 37.60336],
            [126.98578, 37.60345],
            [126.98597, 37.60365],
            [126.98667, 37.60402],
            [126.98678, 37.60447],
            [126.98667, 37.60509],
            [126.98676, 37.60609],
            [126.98632, 37.6065],
            [126.98638, 37.60685],
            [126.98613, 37.60739],
            [126.98627, 37.60772],
            [126.98671, 37.60826],
            [126.98685, 37.60886],
            [126.98588, 37.60946],
            [126.98581, 37.61073],
            [126.98566, 37.61136],
            [126.9862, 37.61298],
            [126.98615, 37.61321],
            [126.98644, 37.61356],
            [126.98659, 37.61399],
            [126.98542, 37.61514],
            [126.98518, 37.61549],
            [126.98455, 37.61602],
            [126.98441, 37.61689],
            [126.98373, 37.61777],
            [126.98316, 37.61927],
            [126.98318, 37.62018],
            [126.98365, 37.62115],
            [126.98185, 37.62431],
            [126.98091, 37.62539],
            [126.9796, 37.62638],
            [126.97988, 37.62745],
            [126.98019, 37.62793],
            [126.97993, 37.62855],
            [126.97953, 37.62889],
            [126.97842, 37.62929],
            [126.97495, 37.62897],
            [126.97464, 37.62974],
            [126.97508, 37.63123],
            [126.97536, 37.6317],
            [126.97558, 37.63175],
            [126.97583, 37.63201],
            [126.97622, 37.63223],
            [126.97705, 37.633],
            [126.97737, 37.63361],
            [126.97782, 37.63391],
            [126.9792, 37.63408],
            [126.97965, 37.63421],
            [126.98008, 37.63441],
            [126.98062, 37.63482],
            [126.98148, 37.63484],
            [126.98155, 37.63476],
            [126.98216, 37.63528],
            [126.98226, 37.63547],
            [126.98261, 37.63581],
            [126.98282, 37.63584],
            [126.98313, 37.63611],
            [126.9836, 37.63635],
            [126.98359, 37.63647],
            [126.98367, 37.6365],
            [126.98546, 37.63593]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        rgnCode: '11305',
        rgnKo: ['서울특별시', '강북구'],
        colCode: '11000',
        rgnSize: '2',
        predVal: [
          0.5168, 0.6024, 0.5525, 0.813, 0.8164, 0.7375, 0.8091, 0.8509, 0.6862,
          0.7714, 0.7688, 0.6415, 0.3028, 0.6849, 0.5783, 0.3415, 0.3928,
          0.8492, 0.6914, 0.8027, 0.7106, 0.8447, 0.7411, 0.8393, 0.6718,
          0.8187, 0.7906, 0.8134, 0.7946, 0.785, 0.7501
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [127.00453, 37.68509],
            [127.00552, 37.68486],
            [127.00605, 37.68505],
            [127.00707, 37.68476],
            [127.00766, 37.68447],
            [127.00866, 37.68445],
            [127.00865, 37.68395],
            [127.00842, 37.68323],
            [127.00871, 37.68226],
            [127.00907, 37.68165],
            [127.00893, 37.68059],
            [127.00942, 37.67957],
            [127.01212, 37.67911],
            [127.01242, 37.6787],
            [127.01282, 37.6784],
            [127.01331, 37.67788],
            [127.01402, 37.67588],
            [127.01388, 37.67559],
            [127.01394, 37.67523],
            [127.0144, 37.67481],
            [127.01458, 37.67481],
            [127.01484, 37.67437],
            [127.01513, 37.67416],
            [127.01559, 37.67412],
            [127.01615, 37.67391],
            [127.01623, 37.67394],
            [127.01667, 37.67295],
            [127.01698, 37.67266],
            [127.01713, 37.67264],
            [127.01719, 37.67249],
            [127.01733, 37.67245],
            [127.01741, 37.67216],
            [127.0173, 37.67194],
            [127.01737, 37.67164],
            [127.01759, 37.67149],
            [127.01766, 37.67126],
            [127.01782, 37.67114],
            [127.0179, 37.67085],
            [127.01825, 37.67072],
            [127.0187, 37.67086],
            [127.01876, 37.67052],
            [127.0185, 37.67017],
            [127.01855, 37.66954],
            [127.01837, 37.66919],
            [127.01841, 37.66889],
            [127.01759, 37.66829],
            [127.01747, 37.66809],
            [127.01661, 37.66788],
            [127.01623, 37.6675],
            [127.0164, 37.66737],
            [127.01577, 37.667],
            [127.01577, 37.66655],
            [127.01548, 37.66609],
            [127.01538, 37.6656],
            [127.01581, 37.66507],
            [127.01567, 37.66451],
            [127.01532, 37.66404],
            [127.01535, 37.66375],
            [127.01554, 37.66367],
            [127.01558, 37.66346],
            [127.01601, 37.6633],
            [127.01602, 37.66278],
            [127.01618, 37.66255],
            [127.01668, 37.66223],
            [127.01692, 37.66232],
            [127.01699, 37.66223],
            [127.01651, 37.66166],
            [127.01621, 37.66142],
            [127.01588, 37.66132],
            [127.01531, 37.66137],
            [127.01456, 37.662],
            [127.0148, 37.66136],
            [127.01461, 37.66136],
            [127.01457, 37.66046],
            [127.01443, 37.66046],
            [127.01428, 37.6598],
            [127.01402, 37.65923],
            [127.0138, 37.65795],
            [127.01295, 37.65461],
            [127.01293, 37.65416],
            [127.01268, 37.65343],
            [127.01245, 37.65216],
            [127.0129, 37.65075],
            [127.01314, 37.65047],
            [127.01333, 37.65042],
            [127.0137, 37.65049],
            [127.01401, 37.65026],
            [127.01431, 37.65027],
            [127.0147, 37.64957],
            [127.01509, 37.64928],
            [127.01731, 37.64865],
            [127.01855, 37.64869],
            [127.02017, 37.64909],
            [127.02085, 37.64905],
            [127.02141, 37.6489],
            [127.02183, 37.64859],
            [127.02214, 37.64816],
            [127.02264, 37.64786],
            [127.02469, 37.64734],
            [127.02509, 37.64671],
            [127.02589, 37.64572],
            [127.02755, 37.64496],
            [127.03195, 37.64218],
            [127.03242, 37.64183],
            [127.03281, 37.64143],
            [127.03319, 37.64081],
            [127.03457, 37.63778],
            [127.03515, 37.63727],
            [127.03685, 37.63675],
            [127.0373, 37.6365],
            [127.03782, 37.63604],
            [127.03788, 37.63541],
            [127.038, 37.63515],
            [127.03814, 37.63426],
            [127.03976, 37.6322],
            [127.0408, 37.63155],
            [127.04171, 37.63081],
            [127.04253, 37.63042],
            [127.04322, 37.62986],
            [127.04434, 37.62875],
            [127.04633, 37.62792],
            [127.04701, 37.62751],
            [127.04712, 37.62754],
            [127.0476, 37.62712],
            [127.04858, 37.62599],
            [127.0485, 37.62593],
            [127.04861, 37.62595],
            [127.04869, 37.62587],
            [127.04863, 37.62577],
            [127.04973, 37.62426],
            [127.04764, 37.62307],
            [127.04656, 37.62235],
            [127.04608, 37.62188],
            [127.04571, 37.62137],
            [127.04468, 37.61944],
            [127.04395, 37.61864],
            [127.04098, 37.61656],
            [127.04056, 37.61611],
            [127.04041, 37.61607],
            [127.04004, 37.61624],
            [127.03997, 37.61569],
            [127.03956, 37.61511],
            [127.03937, 37.61502],
            [127.03897, 37.61453],
            [127.03861, 37.61434],
            [127.03858, 37.61421],
            [127.03822, 37.614],
            [127.03793, 37.61377],
            [127.03771, 37.61322],
            [127.03731, 37.6128],
            [127.03678, 37.61243],
            [127.03609, 37.61234],
            [127.03611, 37.61243],
            [127.03604, 37.61244],
            [127.03587, 37.61205],
            [127.03303, 37.6105],
            [127.03272, 37.61026],
            [127.03025, 37.60898],
            [127.03034, 37.61131],
            [127.03018, 37.61237],
            [127.02868, 37.61245],
            [127.0277, 37.61261],
            [127.02733, 37.61256],
            [127.02643, 37.61269],
            [127.02619, 37.6125],
            [127.02604, 37.61224],
            [127.02576, 37.61214],
            [127.02518, 37.61201],
            [127.02467, 37.61215],
            [127.02447, 37.61213],
            [127.02275, 37.61152],
            [127.0225, 37.61137],
            [127.02242, 37.61145],
            [127.0222, 37.61137],
            [127.02204, 37.61229],
            [127.02155, 37.61244],
            [127.02043, 37.61259],
            [127.02034, 37.61286],
            [127.02001, 37.61301],
            [127.01987, 37.61324],
            [127.01952, 37.61333],
            [127.01923, 37.6138],
            [127.019, 37.61386],
            [127.01863, 37.6138],
            [127.01821, 37.61399],
            [127.01754, 37.61462],
            [127.01656, 37.61492],
            [127.0165, 37.61484],
            [127.01615, 37.61486],
            [127.01602, 37.61476],
            [127.01526, 37.61466],
            [127.01447, 37.61469],
            [127.01416, 37.61487],
            [127.01416, 37.61495],
            [127.01355, 37.61544],
            [127.01279, 37.61566],
            [127.01207, 37.61597],
            [127.0118, 37.61631],
            [127.01144, 37.61641],
            [127.01098, 37.6163],
            [127.0101, 37.61685],
            [127.01014, 37.61694],
            [127.00999, 37.61725],
            [127.00994, 37.61777],
            [127.00946, 37.6182],
            [127.00907, 37.61829],
            [127.0085, 37.61862],
            [127.00845, 37.61898],
            [127.00835, 37.61911],
            [127.00816, 37.61917],
            [127.00804, 37.61939],
            [127.00774, 37.61963],
            [127.00786, 37.61986],
            [127.00761, 37.62001],
            [127.00724, 37.62051],
            [127.00734, 37.62066],
            [127.00721, 37.62083],
            [127.00725, 37.62095],
            [127.00742, 37.62134],
            [127.0076, 37.62146],
            [127.00744, 37.62175],
            [127.00764, 37.62226],
            [127.00755, 37.62266],
            [127.00779, 37.62291],
            [127.00775, 37.62337],
            [127.0079, 37.6237],
            [127.0079, 37.62399],
            [127.00734, 37.62414],
            [127.00572, 37.62398],
            [127.00559, 37.62384],
            [127.00519, 37.62401],
            [127.0049, 37.62382],
            [127.00456, 37.62408],
            [127.00443, 37.62406],
            [127.0039, 37.62423],
            [127.00352, 37.62493],
            [127.00298, 37.62528],
            [127.00213, 37.62544],
            [127.00176, 37.62565],
            [127.00068, 37.62571],
            [127.0002, 37.62582],
            [126.99984, 37.62606],
            [126.99927, 37.6262],
            [126.99845, 37.62712],
            [126.99788, 37.62757],
            [126.99757, 37.62806],
            [126.99678, 37.62866],
            [126.99651, 37.62909],
            [126.99605, 37.62935],
            [126.99539, 37.63025],
            [126.99474, 37.63052],
            [126.99426, 37.63085],
            [126.9941, 37.63112],
            [126.99369, 37.63147],
            [126.99185, 37.63229],
            [126.99138, 37.63261],
            [126.98993, 37.6328],
            [126.98985, 37.63346],
            [126.98941, 37.63408],
            [126.98873, 37.63394],
            [126.98857, 37.6338],
            [126.9882, 37.63449],
            [126.98745, 37.63497],
            [126.98634, 37.63548],
            [126.98592, 37.63579],
            [126.98421, 37.63633],
            [126.98444, 37.63661],
            [126.98473, 37.63669],
            [126.98491, 37.63722],
            [126.98505, 37.63734],
            [126.98494, 37.6378],
            [126.98512, 37.63799],
            [126.98519, 37.63827],
            [126.98539, 37.63859],
            [126.98543, 37.63928],
            [126.98553, 37.6397],
            [126.98568, 37.64004],
            [126.98613, 37.64043],
            [126.98565, 37.64078],
            [126.98528, 37.64137],
            [126.98533, 37.64155],
            [126.98524, 37.64162],
            [126.98496, 37.64146],
            [126.98426, 37.64164],
            [126.98383, 37.64215],
            [126.98387, 37.64256],
            [126.98369, 37.64304],
            [126.98326, 37.64367],
            [126.9837, 37.64397],
            [126.98401, 37.64432],
            [126.98419, 37.64443],
            [126.98419, 37.6446],
            [126.98463, 37.64532],
            [126.98482, 37.64537],
            [126.98485, 37.64559],
            [126.98569, 37.64606],
            [126.98564, 37.6461],
            [126.9851, 37.64593],
            [126.98493, 37.64607],
            [126.98476, 37.64676],
            [126.98481, 37.64684],
            [126.98469, 37.64696],
            [126.98471, 37.64725],
            [126.9846, 37.64761],
            [126.98398, 37.64818],
            [126.98388, 37.6486],
            [126.98402, 37.64932],
            [126.98394, 37.64961],
            [126.9825, 37.65054],
            [126.9823, 37.65125],
            [126.98187, 37.65169],
            [126.98168, 37.65232],
            [126.98103, 37.65276],
            [126.98043, 37.65379],
            [126.97994, 37.65482],
            [126.97966, 37.65604],
            [126.98119, 37.65649],
            [126.98286, 37.65645],
            [126.98301, 37.657],
            [126.98374, 37.65733],
            [126.98434, 37.65773],
            [126.98462, 37.65823],
            [126.98602, 37.65894],
            [126.98654, 37.65949],
            [126.98728, 37.6606],
            [126.98723, 37.66085],
            [126.98737, 37.66162],
            [126.98777, 37.6627],
            [126.98784, 37.66365],
            [126.98827, 37.66439],
            [126.98945, 37.66453],
            [126.99, 37.6645],
            [126.99124, 37.66499],
            [126.99222, 37.66523],
            [126.99275, 37.66553],
            [126.99312, 37.66606],
            [126.99402, 37.66678],
            [126.99413, 37.66703],
            [126.99358, 37.66783],
            [126.99383, 37.66862],
            [126.99436, 37.66957],
            [126.9943, 37.66989],
            [126.99381, 37.67028],
            [126.99421, 37.67242],
            [126.99362, 37.67414],
            [126.9938, 37.67423],
            [126.99398, 37.67491],
            [126.99381, 37.67525],
            [126.9932, 37.67572],
            [126.99309, 37.67661],
            [126.99327, 37.677],
            [126.99314, 37.67722],
            [126.99325, 37.67766],
            [126.99298, 37.67883],
            [126.99245, 37.6792],
            [126.9922, 37.67962],
            [126.99243, 37.67976],
            [126.99351, 37.68006],
            [126.99408, 37.68032],
            [126.99417, 37.68059],
            [126.99473, 37.68124],
            [126.9952, 37.68134],
            [126.99686, 37.68243],
            [126.99705, 37.68262],
            [126.99709, 37.68307],
            [126.99736, 37.68342],
            [126.99771, 37.68343],
            [126.99779, 37.68357],
            [126.99827, 37.68366],
            [127.00057, 37.68393],
            [127.00107, 37.68423],
            [127.00172, 37.68429],
            [127.00188, 37.68408],
            [127.0019, 37.68372],
            [127.00224, 37.68359],
            [127.00274, 37.68395],
            [127.00324, 37.68395],
            [127.00358, 37.68424],
            [127.00378, 37.68482],
            [127.00428, 37.68494],
            [127.00453, 37.68509]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        rgnCode: '11320',
        rgnKo: ['서울특별시', '도봉구'],
        colCode: '11000',
        rgnSize: '2',
        predVal: [
          0.8854, 0.8973, 0.8933, 0.8931, 0.8839, 0.88, 0.8353, 0.8509, 0.8545,
          0.8614, 0.8452, 0.8524, 0.8446, 0.8446, 0.8524, 0.8594, 0.8592,
          0.8578, 0.8567, 0.8276, 0.7106, 0.8447, 0.7411, 0.8393, 0.6718,
          0.8187, 0.824, 0.8157, 0.8118, 0.7985, 0.7985
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [127.02165, 37.7],
            [127.02215, 37.69972],
            [127.02339, 37.70007],
            [127.02536, 37.6996],
            [127.02699, 37.70025],
            [127.02707, 37.70112],
            [127.02771, 37.70095],
            [127.02836, 37.70013],
            [127.02898, 37.69969],
            [127.02931, 37.69928],
            [127.02963, 37.69817],
            [127.02977, 37.69627],
            [127.03017, 37.69523],
            [127.03046, 37.69482],
            [127.03086, 37.6937],
            [127.03101, 37.69355],
            [127.0311, 37.69307],
            [127.03158, 37.69284],
            [127.03203, 37.69294],
            [127.03218, 37.69283],
            [127.03246, 37.69185],
            [127.034, 37.69203],
            [127.03428, 37.69221],
            [127.03563, 37.69249],
            [127.0358, 37.69238],
            [127.03633, 37.6925],
            [127.03681, 37.69264],
            [127.03698, 37.69292],
            [127.03755, 37.69321],
            [127.03856, 37.69328],
            [127.03882, 37.6936],
            [127.03898, 37.69397],
            [127.03991, 37.69408],
            [127.04003, 37.69465],
            [127.04092, 37.69509],
            [127.04114, 37.69529],
            [127.04182, 37.69537],
            [127.04204, 37.69508],
            [127.04233, 37.69505],
            [127.04307, 37.69523],
            [127.0434, 37.6944],
            [127.04338, 37.69409],
            [127.04308, 37.69369],
            [127.04383, 37.69357],
            [127.04406, 37.69314],
            [127.04452, 37.69308],
            [127.04482, 37.69294],
            [127.0449, 37.69241],
            [127.0453, 37.69236],
            [127.04597, 37.69243],
            [127.04675, 37.69272],
            [127.04705, 37.69299],
            [127.04727, 37.69301],
            [127.04752, 37.69331],
            [127.04781, 37.69329],
            [127.04813, 37.6937],
            [127.04821, 37.69396],
            [127.04863, 37.69407],
            [127.04892, 37.69281],
            [127.04936, 37.69171],
            [127.04995, 37.69089],
            [127.05001, 37.69042],
            [127.04975, 37.68921],
            [127.04982, 37.68798],
            [127.05065, 37.6865],
            [127.05093, 37.68615],
            [127.05138, 37.68592],
            [127.05177, 37.68582],
            [127.05186, 37.68451],
            [127.0518, 37.68442],
            [127.0518, 37.68261],
            [127.05123, 37.682],
            [127.05092, 37.68009],
            [127.0504, 37.67917],
            [127.05013, 37.6784],
            [127.04997, 37.67741],
            [127.04974, 37.67673],
            [127.04886, 37.67518],
            [127.04874, 37.6748],
            [127.04877, 37.67383],
            [127.04834, 37.67237],
            [127.04816, 37.67119],
            [127.04819, 37.6705],
            [127.04893, 37.66758],
            [127.04965, 37.66657],
            [127.05031, 37.66582],
            [127.05083, 37.66491],
            [127.05145, 37.66302],
            [127.05142, 37.6607],
            [127.05111, 37.66079],
            [127.05115, 37.65994],
            [127.05119, 37.65984],
            [127.05179, 37.65992],
            [127.05254, 37.65891],
            [127.05342, 37.65751],
            [127.0536, 37.65677],
            [127.05358, 37.65615],
            [127.05401, 37.6553],
            [127.05405, 37.65448],
            [127.05393, 37.65306],
            [127.05375, 37.6521],
            [127.05396, 37.65058],
            [127.05466, 37.64956],
            [127.05544, 37.64824],
            [127.05582, 37.64706],
            [127.05593, 37.64596],
            [127.05566, 37.6446],
            [127.05515, 37.64346],
            [127.05499, 37.64235],
            [127.05513, 37.64133],
            [127.05578, 37.64063],
            [127.05472, 37.64015],
            [127.05372, 37.64096],
            [127.05285, 37.64181],
            [127.05233, 37.64261],
            [127.05137, 37.64479],
            [127.05116, 37.64478],
            [127.05096, 37.64471],
            [127.05045, 37.64484],
            [127.05033, 37.64461],
            [127.05068, 37.64381],
            [127.05025, 37.6436],
            [127.04976, 37.64351],
            [127.04943, 37.64273],
            [127.04931, 37.64267],
            [127.04906, 37.64215],
            [127.04879, 37.64193],
            [127.04786, 37.64145],
            [127.0478, 37.64139],
            [127.04783, 37.64119],
            [127.04675, 37.64119],
            [127.04659, 37.64112],
            [127.0465, 37.6403],
            [127.04665, 37.64004],
            [127.04665, 37.6398],
            [127.04678, 37.6397],
            [127.04667, 37.63948],
            [127.04669, 37.63937],
            [127.04652, 37.63924],
            [127.04625, 37.63885],
            [127.04628, 37.63877],
            [127.04603, 37.6379],
            [127.04555, 37.63768],
            [127.04536, 37.63747],
            [127.04537, 37.63737],
            [127.04504, 37.63669],
            [127.04497, 37.63566],
            [127.04464, 37.63536],
            [127.04452, 37.63484],
            [127.04456, 37.63478],
            [127.04445, 37.63442],
            [127.04434, 37.63437],
            [127.04432, 37.63409],
            [127.04392, 37.63347],
            [127.04375, 37.63332],
            [127.04352, 37.63326],
            [127.04352, 37.63309],
            [127.0427, 37.63261],
            [127.04166, 37.63146],
            [127.04267, 37.63091],
            [127.04253, 37.63042],
            [127.04172, 37.63081],
            [127.0408, 37.63155],
            [127.03976, 37.6322],
            [127.03814, 37.63426],
            [127.038, 37.63515],
            [127.03788, 37.63541],
            [127.03782, 37.63604],
            [127.03733, 37.63648],
            [127.03685, 37.63675],
            [127.03515, 37.63727],
            [127.03457, 37.63778],
            [127.03319, 37.64081],
            [127.03284, 37.6414],
            [127.03242, 37.64183],
            [127.03195, 37.64218],
            [127.02755, 37.64496],
            [127.02589, 37.64572],
            [127.02509, 37.64671],
            [127.02469, 37.64734],
            [127.02264, 37.64786],
            [127.02214, 37.64816],
            [127.0218, 37.6486],
            [127.02149, 37.64885],
            [127.02117, 37.64899],
            [127.02017, 37.64909],
            [127.01878, 37.64873],
            [127.01778, 37.64863],
            [127.01731, 37.64865],
            [127.01647, 37.64885],
            [127.01512, 37.64928],
            [127.0147, 37.64957],
            [127.01432, 37.65026],
            [127.01401, 37.65026],
            [127.0137, 37.65049],
            [127.01333, 37.65042],
            [127.01314, 37.65047],
            [127.0129, 37.65075],
            [127.01271, 37.65148],
            [127.01251, 37.65185],
            [127.01245, 37.65216],
            [127.01268, 37.65343],
            [127.01293, 37.65416],
            [127.01293, 37.65457],
            [127.0138, 37.65795],
            [127.01402, 37.65923],
            [127.01428, 37.6598],
            [127.01438, 37.66038],
            [127.01457, 37.66046],
            [127.01461, 37.66136],
            [127.0148, 37.66136],
            [127.01452, 37.66203],
            [127.01483, 37.6617],
            [127.01531, 37.66137],
            [127.01588, 37.66132],
            [127.01621, 37.66142],
            [127.01651, 37.66166],
            [127.01696, 37.66221],
            [127.01692, 37.66232],
            [127.01669, 37.66223],
            [127.01618, 37.66255],
            [127.01602, 37.66278],
            [127.01601, 37.6633],
            [127.01558, 37.66346],
            [127.01554, 37.66367],
            [127.01535, 37.66375],
            [127.01532, 37.66404],
            [127.01567, 37.66451],
            [127.01581, 37.66494],
            [127.01581, 37.66507],
            [127.01538, 37.6656],
            [127.01548, 37.66609],
            [127.01577, 37.66655],
            [127.01577, 37.667],
            [127.0164, 37.66737],
            [127.01623, 37.6675],
            [127.01661, 37.66788],
            [127.01747, 37.66809],
            [127.01759, 37.66829],
            [127.01841, 37.66889],
            [127.01837, 37.66919],
            [127.01855, 37.66954],
            [127.0185, 37.67017],
            [127.01876, 37.67052],
            [127.01872, 37.67087],
            [127.01825, 37.67072],
            [127.0179, 37.67085],
            [127.01782, 37.67114],
            [127.01766, 37.67126],
            [127.01759, 37.67149],
            [127.01737, 37.67164],
            [127.0173, 37.67194],
            [127.0174, 37.67212],
            [127.01733, 37.67245],
            [127.01719, 37.67249],
            [127.01713, 37.67264],
            [127.01698, 37.67266],
            [127.01667, 37.67295],
            [127.01623, 37.67394],
            [127.01615, 37.67391],
            [127.01559, 37.67412],
            [127.01513, 37.67416],
            [127.01484, 37.67437],
            [127.01458, 37.67481],
            [127.0144, 37.67481],
            [127.01394, 37.67523],
            [127.01388, 37.67559],
            [127.01402, 37.67588],
            [127.01331, 37.67788],
            [127.01282, 37.6784],
            [127.01242, 37.6787],
            [127.01214, 37.67911],
            [127.00942, 37.67957],
            [127.00893, 37.68059],
            [127.00907, 37.68165],
            [127.00871, 37.68226],
            [127.00842, 37.68323],
            [127.00865, 37.68395],
            [127.00866, 37.68437],
            [127.00873, 37.68437],
            [127.00845, 37.68539],
            [127.00861, 37.6874],
            [127.00837, 37.68847],
            [127.00834, 37.69005],
            [127.00768, 37.69158],
            [127.00976, 37.69332],
            [127.00967, 37.6967],
            [127.01212, 37.69738],
            [127.01388, 37.6988],
            [127.01496, 37.70043],
            [127.01542, 37.70146],
            [127.01983, 37.70102],
            [127.02165, 37.7]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        rgnCode: '11350',
        rgnKo: ['서울특별시', '노원구'],
        colCode: '11000',
        rgnSize: '2',
        predVal: [
          0.5924, 0.5295, 0.5525, 0.6585, 0.8111, 0.7375, 0.8091, 0.8509,
          0.6862, 0.7714, 0.7688, 0.6415, 0.3028, 0.6849, 0.5783, 0.3415,
          0.3928, 0.8492, 0.6914, 0.686, 0.7106, 0.8447, 0.7411, 0.8393, 0.6718,
          0.8187, 0.7906, 0.8134, 0.7946, 0.785, 0.6404
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [127.08384, 37.69427],
            [127.08391, 37.69178],
            [127.08518, 37.69039],
            [127.08653, 37.68991],
            [127.08845, 37.6899],
            [127.09055, 37.68957],
            [127.09238, 37.68993],
            [127.09304, 37.68997],
            [127.09429, 37.68913],
            [127.0951, 37.68938],
            [127.096, 37.68907],
            [127.09558, 37.68839],
            [127.09611, 37.68782],
            [127.0963, 37.68717],
            [127.09643, 37.68626],
            [127.09642, 37.68554],
            [127.09601, 37.6851],
            [127.0958, 37.68453],
            [127.09429, 37.68328],
            [127.09416, 37.68295],
            [127.09338, 37.68207],
            [127.09295, 37.68144],
            [127.09301, 37.68106],
            [127.09285, 37.68005],
            [127.09257, 37.67945],
            [127.09224, 37.67917],
            [127.09198, 37.67912],
            [127.09199, 37.67864],
            [127.09223, 37.6781],
            [127.09256, 37.67763],
            [127.09302, 37.67742],
            [127.09344, 37.67692],
            [127.09386, 37.67671],
            [127.09392, 37.67618],
            [127.09405, 37.67587],
            [127.094, 37.67567],
            [127.09466, 37.67356],
            [127.09491, 37.67316],
            [127.09517, 37.67293],
            [127.09577, 37.6727],
            [127.09602, 37.67185],
            [127.09574, 37.67134],
            [127.09581, 37.67116],
            [127.09575, 37.67062],
            [127.09601, 37.67008],
            [127.09645, 37.66969],
            [127.09625, 37.66877],
            [127.09502, 37.66758],
            [127.09461, 37.66614],
            [127.09469, 37.66486],
            [127.09539, 37.66389],
            [127.09414, 37.6633],
            [127.09326, 37.66197],
            [127.09217, 37.66079],
            [127.09121, 37.65933],
            [127.09115, 37.65823],
            [127.09182, 37.65741],
            [127.09238, 37.65535],
            [127.09286, 37.65447],
            [127.09307, 37.65378],
            [127.09392, 37.65296],
            [127.09404, 37.65254],
            [127.094, 37.6523],
            [127.09337, 37.65122],
            [127.09327, 37.65062],
            [127.09247, 37.6497],
            [127.09272, 37.64935],
            [127.09264, 37.64897],
            [127.09269, 37.64859],
            [127.09291, 37.64838],
            [127.09307, 37.64784],
            [127.09367, 37.64751],
            [127.09383, 37.64732],
            [127.09363, 37.64663],
            [127.09414, 37.64607],
            [127.09447, 37.64585],
            [127.09454, 37.64551],
            [127.09438, 37.64496],
            [127.09457, 37.64457],
            [127.09756, 37.64396],
            [127.09891, 37.64403],
            [127.09977, 37.64446],
            [127.10163, 37.64477],
            [127.1028, 37.6452],
            [127.10468, 37.64525],
            [127.10508, 37.64535],
            [127.10536, 37.6452],
            [127.10677, 37.64517],
            [127.1079, 37.64468],
            [127.10893, 37.64333],
            [127.10932, 37.64269],
            [127.11009, 37.64228],
            [127.11081, 37.64252],
            [127.11124, 37.6424],
            [127.1115, 37.64196],
            [127.11144, 37.64133],
            [127.1117, 37.64111],
            [127.11178, 37.64061],
            [127.11228, 37.64018],
            [127.11205, 37.63984],
            [127.11171, 37.63974],
            [127.11105, 37.63973],
            [127.11063, 37.63931],
            [127.11057, 37.63899],
            [127.11062, 37.63874],
            [127.11076, 37.63876],
            [127.1109, 37.63826],
            [127.11118, 37.63831],
            [127.11145, 37.63822],
            [127.11248, 37.63649],
            [127.11221, 37.63628],
            [127.11225, 37.63556],
            [127.11255, 37.63525],
            [127.11235, 37.63476],
            [127.11241, 37.63425],
            [127.11162, 37.63386],
            [127.1122, 37.63264],
            [127.11163, 37.6315],
            [127.11105, 37.63094],
            [127.11025, 37.63042],
            [127.10888, 37.6293],
            [127.10705, 37.62805],
            [127.10591, 37.62758],
            [127.10596, 37.62733],
            [127.10543, 37.62578],
            [127.10502, 37.62526],
            [127.10493, 37.62401],
            [127.10436, 37.62329],
            [127.10407, 37.62165],
            [127.1049, 37.62155],
            [127.10492, 37.62139],
            [127.10542, 37.62083],
            [127.10565, 37.62038],
            [127.105, 37.62014],
            [127.10405, 37.62006],
            [127.10398, 37.62009],
            [127.10407, 37.62012],
            [127.10381, 37.62029],
            [127.10337, 37.62028],
            [127.10321, 37.62019],
            [127.10169, 37.62002],
            [127.10179, 37.61968],
            [127.10167, 37.61947],
            [127.10087, 37.6194],
            [127.09964, 37.61989],
            [127.09882, 37.62039],
            [127.09849, 37.6202],
            [127.09665, 37.61956],
            [127.09662, 37.61943],
            [127.09621, 37.61903],
            [127.09557, 37.61917],
            [127.09493, 37.61901],
            [127.09445, 37.61899],
            [127.09367, 37.61807],
            [127.0935, 37.6181],
            [127.09322, 37.618],
            [127.09233, 37.61835],
            [127.09138, 37.61896],
            [127.08945, 37.61965],
            [127.08871, 37.62024],
            [127.08823, 37.61988],
            [127.08696, 37.62023],
            [127.08607, 37.62029],
            [127.08495, 37.6201],
            [127.08379, 37.61978],
            [127.08384, 37.61965],
            [127.08278, 37.61934],
            [127.0814, 37.61914],
            [127.08062, 37.61842],
            [127.08007, 37.61824],
            [127.08007, 37.61856],
            [127.07969, 37.61842],
            [127.07912, 37.61839],
            [127.0777, 37.6179],
            [127.07757, 37.61774],
            [127.0775, 37.61783],
            [127.07553, 37.61716],
            [127.07441, 37.61703],
            [127.07416, 37.61683],
            [127.07335, 37.61668],
            [127.07198, 37.61648],
            [127.07195, 37.61654],
            [127.07139, 37.6164],
            [127.07109, 37.61621],
            [127.07114, 37.61594],
            [127.0701, 37.61541],
            [127.06971, 37.61572],
            [127.06897, 37.6156],
            [127.06491, 37.61477],
            [127.06329, 37.61426],
            [127.0618, 37.61448],
            [127.06145, 37.61464],
            [127.06112, 37.61491],
            [127.06067, 37.61566],
            [127.05742, 37.61748],
            [127.05594, 37.61892],
            [127.05441, 37.6198],
            [127.0527, 37.62158],
            [127.05207, 37.62214],
            [127.05176, 37.62254],
            [127.05055, 37.62346],
            [127.04965, 37.62434],
            [127.04863, 37.62577],
            [127.04869, 37.62587],
            [127.04861, 37.62595],
            [127.04852, 37.6259],
            [127.04858, 37.62599],
            [127.0476, 37.62712],
            [127.04712, 37.62754],
            [127.04703, 37.62748],
            [127.04633, 37.62792],
            [127.04434, 37.62875],
            [127.04322, 37.62986],
            [127.04253, 37.63042],
            [127.04267, 37.63091],
            [127.04166, 37.63146],
            [127.0427, 37.63261],
            [127.04352, 37.63309],
            [127.04349, 37.63325],
            [127.04375, 37.63332],
            [127.04392, 37.63347],
            [127.04416, 37.63397],
            [127.04432, 37.63409],
            [127.04434, 37.63437],
            [127.04445, 37.63442],
            [127.04456, 37.63478],
            [127.04452, 37.63484],
            [127.04464, 37.63536],
            [127.04497, 37.63566],
            [127.04505, 37.63604],
            [127.04504, 37.63669],
            [127.04537, 37.63737],
            [127.04536, 37.63747],
            [127.04555, 37.63768],
            [127.04603, 37.6379],
            [127.04628, 37.63877],
            [127.04625, 37.63885],
            [127.04652, 37.63924],
            [127.04669, 37.63937],
            [127.04667, 37.63948],
            [127.04678, 37.6397],
            [127.04665, 37.6398],
            [127.04665, 37.64004],
            [127.0465, 37.6403],
            [127.04659, 37.64112],
            [127.04675, 37.64119],
            [127.04783, 37.64119],
            [127.0478, 37.64139],
            [127.04786, 37.64145],
            [127.04879, 37.64193],
            [127.04906, 37.64215],
            [127.04931, 37.64267],
            [127.04943, 37.64273],
            [127.04976, 37.64351],
            [127.05025, 37.6436],
            [127.05068, 37.64381],
            [127.05033, 37.64461],
            [127.05045, 37.64484],
            [127.05096, 37.64471],
            [127.05117, 37.64476],
            [127.0511, 37.64491],
            [127.05135, 37.64481],
            [127.05233, 37.64261],
            [127.05285, 37.64181],
            [127.0537, 37.64097],
            [127.05472, 37.64015],
            [127.05578, 37.64063],
            [127.05513, 37.64133],
            [127.05499, 37.64235],
            [127.05515, 37.64346],
            [127.05566, 37.6446],
            [127.05593, 37.64596],
            [127.05583, 37.64705],
            [127.05545, 37.64821],
            [127.05466, 37.64956],
            [127.05396, 37.65058],
            [127.05375, 37.6521],
            [127.05393, 37.65306],
            [127.05405, 37.65448],
            [127.05401, 37.6553],
            [127.05358, 37.65615],
            [127.0536, 37.65677],
            [127.05342, 37.65751],
            [127.05254, 37.65891],
            [127.05179, 37.65992],
            [127.05119, 37.65984],
            [127.05115, 37.65994],
            [127.05111, 37.66079],
            [127.05142, 37.6607],
            [127.05145, 37.663],
            [127.05083, 37.66491],
            [127.05031, 37.66581],
            [127.04891, 37.66758],
            [127.04819, 37.6705],
            [127.04816, 37.67119],
            [127.04834, 37.67237],
            [127.04877, 37.67383],
            [127.04874, 37.6748],
            [127.04886, 37.67518],
            [127.04974, 37.67673],
            [127.04997, 37.67741],
            [127.05013, 37.6784],
            [127.0504, 37.67917],
            [127.05092, 37.68009],
            [127.05123, 37.682],
            [127.0518, 37.6826],
            [127.0518, 37.68442],
            [127.05186, 37.68451],
            [127.0518, 37.68473],
            [127.0518, 37.68707],
            [127.05223, 37.68742],
            [127.0547, 37.68872],
            [127.05518, 37.6892],
            [127.05618, 37.6892],
            [127.05719, 37.68962],
            [127.05834, 37.68968],
            [127.05971, 37.69028],
            [127.06073, 37.69127],
            [127.06122, 37.69211],
            [127.06172, 37.69237],
            [127.06181, 37.69255],
            [127.06219, 37.69273],
            [127.06241, 37.693],
            [127.06246, 37.69359],
            [127.06278, 37.69468],
            [127.06338, 37.69492],
            [127.06463, 37.69457],
            [127.06542, 37.69478],
            [127.06633, 37.69443],
            [127.0679, 37.69476],
            [127.06835, 37.69436],
            [127.06864, 37.69398],
            [127.06913, 37.69384],
            [127.06924, 37.69371],
            [127.06956, 37.69379],
            [127.06982, 37.69398],
            [127.07106, 37.69369],
            [127.07279, 37.69378],
            [127.07326, 37.69394],
            [127.07383, 37.69447],
            [127.0749, 37.69515],
            [127.07582, 37.69543],
            [127.0782, 37.69599],
            [127.0811, 37.69614],
            [127.08384, 37.69427]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        rgnCode: '11380',
        rgnKo: ['서울특별시', '은평구'],
        colCode: '11000',
        rgnSize: '2',
        predVal: [
          0.5924, 0.6024, 0.5765, 0.6474, 0.8111, 0.7375, 0.8091, 0.8509,
          0.6862, 0.7714, 0.7688, 0.5887, 0.3028, 0.6775, 0.6215, 0.3415,
          0.3928, 0.6699, 0.5813, 0.6758, 0.7106, 0.8447, 0.7411, 0.8393,
          0.6718, 0.8187, 0.7483, 0.7705, 0.7533, 0.785, 0.6494
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.95138, 37.65495],
            [126.95186, 37.65486],
            [126.95321, 37.65493],
            [126.95395, 37.6553],
            [126.95419, 37.65521],
            [126.95403, 37.65508],
            [126.95404, 37.65498],
            [126.95435, 37.6546],
            [126.95524, 37.65431],
            [126.95552, 37.65379],
            [126.95585, 37.65366],
            [126.95616, 37.65336],
            [126.95651, 37.65319],
            [126.95641, 37.65317],
            [126.95711, 37.65284],
            [126.95746, 37.65187],
            [126.95768, 37.65071],
            [126.95779, 37.64981],
            [126.95756, 37.64904],
            [126.95784, 37.64847],
            [126.95784, 37.64805],
            [126.95838, 37.64765],
            [126.95903, 37.64678],
            [126.95892, 37.64614],
            [126.95915, 37.64575],
            [126.95891, 37.64476],
            [126.95911, 37.64437],
            [126.95902, 37.64388],
            [126.95937, 37.6432],
            [126.95933, 37.64214],
            [126.96001, 37.64143],
            [126.96014, 37.64053],
            [126.96051, 37.63983],
            [126.96085, 37.6388],
            [126.96169, 37.63799],
            [126.96173, 37.63701],
            [126.96263, 37.63578],
            [126.9634, 37.63323],
            [126.96162, 37.63189],
            [126.9598, 37.62973],
            [126.95881, 37.62959],
            [126.95748, 37.6291],
            [126.95675, 37.62862],
            [126.95642, 37.62823],
            [126.95557, 37.62828],
            [126.95518, 37.62816],
            [126.95401, 37.62736],
            [126.95351, 37.62738],
            [126.95275, 37.62706],
            [126.95202, 37.62687],
            [126.95161, 37.62655],
            [126.95045, 37.62642],
            [126.949, 37.62434],
            [126.9489, 37.62326],
            [126.94923, 37.62236],
            [126.94992, 37.62093],
            [126.94985, 37.61876],
            [126.95047, 37.6161],
            [126.95072, 37.61554],
            [126.95059, 37.61514],
            [126.9508, 37.61385],
            [126.95073, 37.61353],
            [126.95048, 37.61287],
            [126.94983, 37.61166],
            [126.95038, 37.61063],
            [126.94936, 37.61001],
            [126.94894, 37.6094],
            [126.94877, 37.60901],
            [126.94821, 37.60861],
            [126.94708, 37.60812],
            [126.94624, 37.60738],
            [126.94539, 37.60693],
            [126.94411, 37.60641],
            [126.94377, 37.60618],
            [126.94367, 37.60506],
            [126.94214, 37.60496],
            [126.94125, 37.60312],
            [126.93995, 37.60306],
            [126.93981, 37.60277],
            [126.93985, 37.60187],
            [126.9401, 37.60179],
            [126.94108, 37.60116],
            [126.94138, 37.60068],
            [126.94087, 37.59873],
            [126.93966, 37.59799],
            [126.93863, 37.59751],
            [126.93754, 37.59787],
            [126.93671, 37.59762],
            [126.93454, 37.59661],
            [126.93384, 37.59663],
            [126.9328, 37.5963],
            [126.93221, 37.59579],
            [126.93159, 37.59539],
            [126.93132, 37.59502],
            [126.93027, 37.59455],
            [126.9304, 37.59436],
            [126.92996, 37.59416],
            [126.92932, 37.59332],
            [126.92887, 37.59288],
            [126.92854, 37.59224],
            [126.92777, 37.59158],
            [126.92798, 37.59103],
            [126.92766, 37.59051],
            [126.92794, 37.58995],
            [126.92762, 37.58939],
            [126.92775, 37.58892],
            [126.92801, 37.58839],
            [126.92774, 37.58806],
            [126.92631, 37.58727],
            [126.92572, 37.58715],
            [126.92513, 37.58725],
            [126.92389, 37.5871],
            [126.92366, 37.58614],
            [126.92286, 37.58564],
            [126.92275, 37.58545],
            [126.92243, 37.58533],
            [126.92186, 37.58469],
            [126.92199, 37.58411],
            [126.92173, 37.58373],
            [126.92176, 37.58346],
            [126.92125, 37.58334],
            [126.92086, 37.58294],
            [126.92025, 37.58296],
            [126.91989, 37.58327],
            [126.91955, 37.58321],
            [126.91886, 37.58339],
            [126.91773, 37.58324],
            [126.91702, 37.58327],
            [126.91594, 37.5831],
            [126.9159, 37.58343],
            [126.91574, 37.58345],
            [126.91571, 37.58389],
            [126.91577, 37.58397],
            [126.91559, 37.58419],
            [126.91568, 37.58439],
            [126.9157, 37.585],
            [126.91603, 37.58531],
            [126.91639, 37.58583],
            [126.91603, 37.58562],
            [126.91531, 37.58542],
            [126.9157, 37.58598],
            [126.91284, 37.58722],
            [126.91244, 37.58647],
            [126.91187, 37.58578],
            [126.90416, 37.57853],
            [126.90412, 37.57849],
            [126.90417, 37.57874],
            [126.90184, 37.57655],
            [126.90263, 37.57639],
            [126.90208, 37.57601],
            [126.8977, 37.57899],
            [126.89452, 37.58105],
            [126.89409, 37.58141],
            [126.89324, 37.58187],
            [126.88889, 37.58465],
            [126.88574, 37.58718],
            [126.88206, 37.59079],
            [126.88232, 37.59113],
            [126.88268, 37.59138],
            [126.88357, 37.59185],
            [126.88514, 37.59375],
            [126.8858, 37.59395],
            [126.88637, 37.59395],
            [126.88673, 37.59381],
            [126.88734, 37.59392],
            [126.88672, 37.59317],
            [126.88657, 37.5927],
            [126.88623, 37.59231],
            [126.88615, 37.5921],
            [126.8856, 37.5915],
            [126.88543, 37.59102],
            [126.88571, 37.59079],
            [126.88587, 37.58993],
            [126.88581, 37.58954],
            [126.88656, 37.58916],
            [126.88716, 37.58853],
            [126.88828, 37.58878],
            [126.88921, 37.58882],
            [126.88958, 37.58913],
            [126.89022, 37.58892],
            [126.89094, 37.58857],
            [126.89139, 37.58848],
            [126.89224, 37.58852],
            [126.89329, 37.58908],
            [126.89384, 37.58907],
            [126.89427, 37.58882],
            [126.89523, 37.58886],
            [126.89595, 37.58873],
            [126.89641, 37.58874],
            [126.89686, 37.58857],
            [126.89757, 37.58896],
            [126.89796, 37.58909],
            [126.89801, 37.58927],
            [126.89949, 37.58978],
            [126.89966, 37.58977],
            [126.89975, 37.58994],
            [126.89959, 37.5906],
            [126.89974, 37.59093],
            [126.89971, 37.59131],
            [126.89957, 37.59169],
            [126.89896, 37.59268],
            [126.8993, 37.59287],
            [126.89952, 37.59321],
            [126.89978, 37.59339],
            [126.89999, 37.59378],
            [126.90037, 37.59422],
            [126.90184, 37.59511],
            [126.90163, 37.596],
            [126.90121, 37.59632],
            [126.90122, 37.59679],
            [126.90107, 37.59697],
            [126.90101, 37.59734],
            [126.90125, 37.59791],
            [126.90117, 37.59821],
            [126.90126, 37.59849],
            [126.90132, 37.59937],
            [126.90102, 37.59998],
            [126.90101, 37.6002],
            [126.90023, 37.60143],
            [126.89998, 37.60204],
            [126.9002, 37.60257],
            [126.90015, 37.60312],
            [126.90077, 37.60337],
            [126.90131, 37.6034],
            [126.90211, 37.60374],
            [126.90203, 37.60427],
            [126.90151, 37.60536],
            [126.90175, 37.60572],
            [126.90141, 37.60619],
            [126.90078, 37.60844],
            [126.9006, 37.60932],
            [126.90071, 37.61032],
            [126.9003, 37.61119],
            [126.90089, 37.61233],
            [126.90091, 37.61285],
            [126.90117, 37.61315],
            [126.90173, 37.61407],
            [126.9018, 37.61447],
            [126.90173, 37.61478],
            [126.90192, 37.61509],
            [126.90182, 37.61575],
            [126.90215, 37.61637],
            [126.90247, 37.61667],
            [126.90248, 37.6169],
            [126.90313, 37.6173],
            [126.90301, 37.61779],
            [126.90305, 37.61809],
            [126.90334, 37.61831],
            [126.90336, 37.61884],
            [126.90427, 37.61883],
            [126.90523, 37.61907],
            [126.90519, 37.61981],
            [126.90556, 37.62062],
            [126.90628, 37.62135],
            [126.90712, 37.62194],
            [126.90714, 37.62331],
            [126.907, 37.62394],
            [126.90663, 37.6245],
            [126.9074, 37.62506],
            [126.90861, 37.62627],
            [126.9089, 37.62745],
            [126.9088, 37.62909],
            [126.90877, 37.62933],
            [126.90838, 37.62999],
            [126.90812, 37.63006],
            [126.90729, 37.63087],
            [126.90693, 37.6318],
            [126.90676, 37.63274],
            [126.9062, 37.63322],
            [126.9071, 37.6334],
            [126.90912, 37.63433],
            [126.90981, 37.63494],
            [126.91085, 37.63532],
            [126.91121, 37.63583],
            [126.91009, 37.63851],
            [126.91115, 37.64098],
            [126.91218, 37.64414],
            [126.91226, 37.64426],
            [126.9125, 37.64421],
            [126.91014, 37.64513],
            [126.9077, 37.64629],
            [126.90732, 37.64695],
            [126.90709, 37.64719],
            [126.9048, 37.6492],
            [126.90516, 37.64897],
            [126.90593, 37.64871],
            [126.90974, 37.64755],
            [126.91074, 37.64703],
            [126.91329, 37.64501],
            [126.91372, 37.64476],
            [126.91461, 37.64491],
            [126.91519, 37.64512],
            [126.9173, 37.64525],
            [126.92128, 37.64576],
            [126.92286, 37.64588],
            [126.92408, 37.6461],
            [126.9244, 37.64628],
            [126.92713, 37.64809],
            [126.92799, 37.64889],
            [126.92926, 37.64982],
            [126.9297, 37.65005],
            [126.93086, 37.6503],
            [126.93143, 37.65033],
            [126.93185, 37.65023],
            [126.93222, 37.65039],
            [126.93315, 37.65055],
            [126.9357, 37.6512],
            [126.93716, 37.6523],
            [126.93743, 37.65268],
            [126.93775, 37.65346],
            [126.93846, 37.65477],
            [126.93967, 37.65624],
            [126.94033, 37.65677],
            [126.9421, 37.65772],
            [126.94268, 37.65791],
            [126.94334, 37.65806],
            [126.94412, 37.65811],
            [126.94502, 37.65833],
            [126.94643, 37.65876],
            [126.94756, 37.65922],
            [126.948, 37.65857],
            [126.94792, 37.65826],
            [126.94804, 37.65789],
            [126.94777, 37.65728],
            [126.9479, 37.65713],
            [126.94883, 37.65698],
            [126.94922, 37.65677],
            [126.94963, 37.65609],
            [126.95075, 37.65555],
            [126.95138, 37.65495]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        rgnCode: '11410',
        rgnKo: ['서울특별시', '서대문구'],
        colCode: '11000',
        rgnSize: '2',
        predVal: [
          0.5924, 0.6024, 0.5765, 0.6474, 0.8111, 0.7375, 0.8091, 0.8509,
          0.6862, 0.7714, 0.7688, 0.5887, 0.3028, 0.5235, 0.5783, 0.3415,
          0.3928, 0.6699, 0.5813, 0.6758, 0.7106, 0.8447, 0.7411, 0.8393,
          0.6718, 0.8187, 0.7483, 0.7705, 0.7533, 0.785, 0.5678
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.95329, 37.59859],
            [126.95384, 37.59867],
            [126.95414, 37.5991],
            [126.95488, 37.59931],
            [126.95541, 37.59927],
            [126.95677, 37.59874],
            [126.95675, 37.59861],
            [126.95731, 37.59853],
            [126.95771, 37.59837],
            [126.95804, 37.59816],
            [126.95827, 37.59779],
            [126.9584, 37.59714],
            [126.95869, 37.59641],
            [126.95865, 37.59585],
            [126.95907, 37.59502],
            [126.95905, 37.59478],
            [126.95887, 37.59456],
            [126.95866, 37.59445],
            [126.95856, 37.59418],
            [126.95753, 37.59351],
            [126.95753, 37.59303],
            [126.95782, 37.59256],
            [126.95783, 37.59146],
            [126.95749, 37.59008],
            [126.95733, 37.58872],
            [126.95749, 37.58796],
            [126.95737, 37.58708],
            [126.95747, 37.58605],
            [126.95785, 37.58502],
            [126.958, 37.58444],
            [126.958, 37.58412],
            [126.95766, 37.58317],
            [126.9576, 37.58288],
            [126.9577, 37.58262],
            [126.95757, 37.5823],
            [126.95764, 37.58205],
            [126.95816, 37.58117],
            [126.95817, 37.58098],
            [126.95803, 37.58074],
            [126.9573, 37.58044],
            [126.95722, 37.58035],
            [126.95721, 37.57987],
            [126.95568, 37.57968],
            [126.95519, 37.57932],
            [126.95398, 37.57896],
            [126.95356, 37.57875],
            [126.95545, 37.57654],
            [126.95563, 37.57659],
            [126.95637, 37.57568],
            [126.95834, 37.5741],
            [126.964, 37.56864],
            [126.96539, 37.5671],
            [126.96669, 37.56583],
            [126.96756, 37.56488],
            [126.96888, 37.56308],
            [126.96946, 37.56198],
            [126.96744, 37.56151],
            [126.96683, 37.56127],
            [126.96562, 37.5604],
            [126.96396, 37.55946],
            [126.9633, 37.55921],
            [126.96168, 37.55903],
            [126.96165, 37.55909],
            [126.96088, 37.55865],
            [126.96078, 37.55843],
            [126.96011, 37.55772],
            [126.95972, 37.55758],
            [126.95968, 37.55769],
            [126.95913, 37.55724],
            [126.95619, 37.55741],
            [126.94137, 37.55654],
            [126.93686, 37.5551],
            [126.92659, 37.55885],
            [126.92672, 37.56097],
            [126.92718, 37.56177],
            [126.92737, 37.5618],
            [126.9283, 37.56306],
            [126.92816, 37.56316],
            [126.92828, 37.56333],
            [126.92602, 37.5651],
            [126.92473, 37.56567],
            [126.92358, 37.566],
            [126.92156, 37.56645],
            [126.92018, 37.56665],
            [126.91844, 37.56712],
            [126.91574, 37.56825],
            [126.91163, 37.57019],
            [126.90909, 37.57128],
            [126.90804, 37.57192],
            [126.90666, 37.57296],
            [126.90211, 37.57599],
            [126.90264, 37.57639],
            [126.90183, 37.57655],
            [126.90417, 37.57874],
            [126.90416, 37.57852],
            [126.91187, 37.58578],
            [126.91244, 37.58647],
            [126.91284, 37.58722],
            [126.9157, 37.58598],
            [126.91531, 37.58542],
            [126.91601, 37.58561],
            [126.91638, 37.58586],
            [126.91603, 37.58531],
            [126.9157, 37.585],
            [126.91561, 37.58422],
            [126.91577, 37.58397],
            [126.91571, 37.58389],
            [126.91574, 37.58345],
            [126.9159, 37.58343],
            [126.91594, 37.5831],
            [126.91702, 37.58327],
            [126.9177, 37.58324],
            [126.91886, 37.58339],
            [126.9195, 37.58322],
            [126.91986, 37.5833],
            [126.92025, 37.58296],
            [126.92086, 37.58294],
            [126.92125, 37.58334],
            [126.92176, 37.58346],
            [126.92173, 37.58373],
            [126.92199, 37.58411],
            [126.92186, 37.58468],
            [126.92243, 37.58533],
            [126.92275, 37.58545],
            [126.92295, 37.5857],
            [126.92366, 37.58614],
            [126.92389, 37.5871],
            [126.92513, 37.58725],
            [126.92572, 37.58715],
            [126.92631, 37.58727],
            [126.92774, 37.58806],
            [126.92801, 37.58839],
            [126.92776, 37.58888],
            [126.92762, 37.58939],
            [126.92795, 37.58992],
            [126.92766, 37.59051],
            [126.92798, 37.59103],
            [126.92777, 37.59158],
            [126.92854, 37.59224],
            [126.92887, 37.59288],
            [126.92932, 37.59332],
            [126.92996, 37.59416],
            [126.93038, 37.59432],
            [126.93027, 37.59455],
            [126.93132, 37.59502],
            [126.93159, 37.59539],
            [126.93221, 37.59579],
            [126.9328, 37.5963],
            [126.93384, 37.59663],
            [126.93454, 37.59661],
            [126.93671, 37.59762],
            [126.93754, 37.59787],
            [126.93863, 37.59751],
            [126.93966, 37.59799],
            [126.94087, 37.59873],
            [126.94138, 37.60065],
            [126.94108, 37.60116],
            [126.9401, 37.60179],
            [126.93985, 37.60187],
            [126.93981, 37.60277],
            [126.93995, 37.60306],
            [126.94125, 37.60312],
            [126.94212, 37.60492],
            [126.94367, 37.60506],
            [126.94377, 37.60618],
            [126.94411, 37.60641],
            [126.94539, 37.60693],
            [126.94624, 37.60738],
            [126.94708, 37.60812],
            [126.94821, 37.60861],
            [126.94877, 37.60901],
            [126.94894, 37.6094],
            [126.94936, 37.61001],
            [126.95037, 37.61061],
            [126.95034, 37.60911],
            [126.95058, 37.60888],
            [126.95062, 37.60836],
            [126.95148, 37.60802],
            [126.95177, 37.6074],
            [126.95226, 37.60691],
            [126.95261, 37.60668],
            [126.95274, 37.60631],
            [126.95272, 37.60592],
            [126.95311, 37.60551],
            [126.95408, 37.60507],
            [126.95399, 37.60475],
            [126.95367, 37.60431],
            [126.95327, 37.6032],
            [126.95294, 37.60295],
            [126.95274, 37.60262],
            [126.95252, 37.60245],
            [126.95271, 37.6018],
            [126.95263, 37.60156],
            [126.95275, 37.60117],
            [126.95299, 37.60087],
            [126.95306, 37.60064],
            [126.95337, 37.60038],
            [126.95351, 37.60009],
            [126.95347, 37.5997],
            [126.95353, 37.59947],
            [126.95312, 37.59889],
            [126.95329, 37.59859]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        rgnCode: '11440',
        rgnKo: ['서울특별시', '마포구'],
        colCode: '11000',
        rgnSize: '2',
        predVal: [
          0.5924, 0.6024, 0.5765, 0.6474, 0.8111, 0.7375, 0.8091, 0.8509,
          0.6862, 0.7714, 0.7688, 0.5887, 0.3028, 0.6775, 0.6215, 0.3415,
          0.3928, 0.6699, 0.5813, 0.6758, 0.7106, 0.8447, 0.7411, 0.8393,
          0.6718, 0.8187, 0.7483, 0.7705, 0.7533, 0.785, 0.6494
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.88889, 37.58465],
            [126.89324, 37.58187],
            [126.89409, 37.58141],
            [126.89452, 37.58105],
            [126.8977, 37.57899],
            [126.90879, 37.57145],
            [126.91574, 37.56825],
            [126.91844, 37.56712],
            [126.92018, 37.56665],
            [126.92155, 37.56645],
            [126.92358, 37.566],
            [126.92472, 37.56568],
            [126.92601, 37.5651],
            [126.92828, 37.56333],
            [126.92816, 37.56316],
            [126.92827, 37.56307],
            [126.92738, 37.56182],
            [126.92722, 37.56179],
            [126.92693, 37.56142],
            [126.92672, 37.56097],
            [126.92659, 37.55885],
            [126.93686, 37.5551],
            [126.94137, 37.55654],
            [126.95617, 37.55741],
            [126.95913, 37.55724],
            [126.95964, 37.55767],
            [126.95972, 37.55758],
            [126.96011, 37.55772],
            [126.96078, 37.55843],
            [126.96086, 37.55864],
            [126.96165, 37.55909],
            [126.96175, 37.55885],
            [126.96156, 37.55862],
            [126.96161, 37.55845],
            [126.96205, 37.55847],
            [126.96281, 37.55807],
            [126.9631, 37.55822],
            [126.96305, 37.55744],
            [126.96322, 37.55732],
            [126.96343, 37.55694],
            [126.96336, 37.55668],
            [126.96316, 37.55653],
            [126.96275, 37.55601],
            [126.9625, 37.55592],
            [126.96214, 37.55554],
            [126.962, 37.55524],
            [126.96174, 37.55499],
            [126.96173, 37.55479],
            [126.96208, 37.55403],
            [126.96201, 37.55403],
            [126.96231, 37.55278],
            [126.9623, 37.55182],
            [126.96244, 37.55155],
            [126.96235, 37.55151],
            [126.96315, 37.55072],
            [126.96324, 37.55046],
            [126.96349, 37.55018],
            [126.9636, 37.54994],
            [126.96389, 37.54976],
            [126.96373, 37.54923],
            [126.96374, 37.54876],
            [126.96338, 37.54865],
            [126.96224, 37.54862],
            [126.96223, 37.54813],
            [126.96171, 37.54759],
            [126.9611, 37.54743],
            [126.96013, 37.54636],
            [126.96005, 37.54607],
            [126.95953, 37.54597],
            [126.95934, 37.54605],
            [126.95897, 37.54575],
            [126.95841, 37.5455],
            [126.95794, 37.54546],
            [126.95785, 37.54507],
            [126.95811, 37.54442],
            [126.95761, 37.54423],
            [126.95753, 37.54413],
            [126.95746, 37.54384],
            [126.95754, 37.54373],
            [126.95742, 37.54321],
            [126.95759, 37.54298],
            [126.95806, 37.54292],
            [126.95832, 37.54278],
            [126.95818, 37.54231],
            [126.95699, 37.54164],
            [126.95695, 37.5413],
            [126.95674, 37.54089],
            [126.95652, 37.54071],
            [126.95664, 37.54061],
            [126.95661, 37.54054],
            [126.95607, 37.53996],
            [126.95573, 37.53947],
            [126.95521, 37.53919],
            [126.9541, 37.53893],
            [126.95365, 37.53834],
            [126.95337, 37.53751],
            [126.95211, 37.53678],
            [126.95155, 37.53654],
            [126.95128, 37.53629],
            [126.95094, 37.53617],
            [126.9499, 37.53604],
            [126.94899, 37.53574],
            [126.94875, 37.53559],
            [126.94828, 37.53565],
            [126.94784, 37.53562],
            [126.94751, 37.53583],
            [126.9474, 37.5354],
            [126.94748, 37.53531],
            [126.94738, 37.53522],
            [126.94718, 37.53537],
            [126.94652, 37.53558],
            [126.94643, 37.53572],
            [126.94628, 37.53569],
            [126.94544, 37.53518],
            [126.94557, 37.53502],
            [126.9451, 37.53496],
            [126.94482, 37.53452],
            [126.94493, 37.53423],
            [126.9446, 37.53381],
            [126.94422, 37.53423],
            [126.93856, 37.53784],
            [126.9329, 37.54053],
            [126.93034, 37.54136],
            [126.92767, 37.53908],
            [126.92733, 37.53913],
            [126.92711, 37.53904],
            [126.92619, 37.53929],
            [126.92586, 37.53947],
            [126.92444, 37.5399],
            [126.92308, 37.54045],
            [126.92283, 37.54062],
            [126.92241, 37.54143],
            [126.90484, 37.54142],
            [126.90461, 37.54157],
            [126.90219, 37.5441],
            [126.90182, 37.54502],
            [126.89894, 37.54801],
            [126.89464, 37.54943],
            [126.88906, 37.55186],
            [126.88812, 37.55229],
            [126.88762, 37.55261],
            [126.88759, 37.55271],
            [126.87382, 37.55969],
            [126.86894, 37.56204],
            [126.86677, 37.56288],
            [126.8638, 37.56452],
            [126.85608, 37.56943],
            [126.85363, 37.57179],
            [126.85363, 37.5738],
            [126.85929, 37.57492],
            [126.85929, 37.57524],
            [126.86494, 37.57747],
            [126.86838, 37.57752],
            [126.8706, 37.57801],
            [126.87383, 37.57828],
            [126.87626, 37.57819],
            [126.87726, 37.57799],
            [126.8775, 37.57941],
            [126.87768, 37.57977],
            [126.87742, 37.58011],
            [126.87751, 37.58031],
            [126.87747, 37.58062],
            [126.87667, 37.58126],
            [126.87663, 37.58136],
            [126.87668, 37.58163],
            [126.87706, 37.58221],
            [126.8771, 37.58288],
            [126.87699, 37.58339],
            [126.87725, 37.58377],
            [126.87733, 37.58405],
            [126.8773, 37.58418],
            [126.87717, 37.58424],
            [126.87664, 37.58416],
            [126.87654, 37.58439],
            [126.87676, 37.58464],
            [126.8772, 37.58493],
            [126.87728, 37.58559],
            [126.87755, 37.58625],
            [126.87846, 37.58637],
            [126.87915, 37.58678],
            [126.87934, 37.5876],
            [126.8796, 37.58824],
            [126.88034, 37.5895],
            [126.88096, 37.58995],
            [126.88199, 37.59054],
            [126.88191, 37.59062],
            [126.88206, 37.59079],
            [126.88574, 37.58718],
            [126.88889, 37.58465]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        rgnCode: '11470',
        rgnKo: ['서울특별시', '양천구'],
        colCode: '11000',
        rgnSize: '2',
        predVal: [
          0.7099, 0.6689, 0.7819, 0.7751, 0.8111, 0.78, 0.8091, 0.8509, 0.7685,
          0.8051, 0.7963, 0.8296, 0.3028, 0.7751, 0.7168, 0.7818, 0.3928,
          0.7223, 0.714, 0.7166, 0.7306, 0.8447, 0.7636, 0.8393, 0.7385, 0.8187,
          0.763, 0.7705, 0.7533, 0.785, 0.6952
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.87405, 37.54694],
            [126.87796, 37.5471],
            [126.88026, 37.54774],
            [126.87992, 37.54814],
            [126.8806, 37.54821],
            [126.88079, 37.54809],
            [126.88213, 37.54672],
            [126.88274, 37.54578],
            [126.88418, 37.54456],
            [126.88433, 37.5435],
            [126.88444, 37.54338],
            [126.88475, 37.5433],
            [126.88509, 37.54332],
            [126.88545, 37.54233],
            [126.88551, 37.54158],
            [126.88658, 37.53929],
            [126.8869, 37.53878],
            [126.88713, 37.53859],
            [126.88772, 37.53753],
            [126.88832, 37.53667],
            [126.88943, 37.53559],
            [126.88994, 37.53487],
            [126.89016, 37.53438],
            [126.89017, 37.5341],
            [126.89005, 37.5337],
            [126.8898, 37.53339],
            [126.88869, 37.53276],
            [126.88857, 37.5324],
            [126.88866, 37.53217],
            [126.88907, 37.53193],
            [126.89033, 37.53189],
            [126.89071, 37.53169],
            [126.89068, 37.53154],
            [126.88975, 37.53028],
            [126.88977, 37.53008],
            [126.8897, 37.53013],
            [126.88959, 37.52998],
            [126.88937, 37.53019],
            [126.88847, 37.53043],
            [126.88769, 37.53038],
            [126.88718, 37.53019],
            [126.88596, 37.52942],
            [126.88515, 37.52915],
            [126.88418, 37.52867],
            [126.88392, 37.52841],
            [126.88389, 37.52791],
            [126.88375, 37.52759],
            [126.88328, 37.52728],
            [126.88273, 37.52726],
            [126.8819, 37.52765],
            [126.88134, 37.52779],
            [126.88088, 37.52772],
            [126.8806, 37.52743],
            [126.88067, 37.52713],
            [126.88117, 37.52655],
            [126.88113, 37.52616],
            [126.88094, 37.52591],
            [126.88058, 37.52575],
            [126.87941, 37.52574],
            [126.87917, 37.52564],
            [126.87875, 37.52525],
            [126.87867, 37.52491],
            [126.87869, 37.52463],
            [126.87892, 37.5241],
            [126.87893, 37.52319],
            [126.87912, 37.52251],
            [126.87903, 37.5225],
            [126.87922, 37.52214],
            [126.87934, 37.52165],
            [126.87949, 37.52058],
            [126.87946, 37.52037],
            [126.87917, 37.52],
            [126.87867, 37.5197],
            [126.87836, 37.51939],
            [126.87825, 37.51876],
            [126.87851, 37.51824],
            [126.87878, 37.51794],
            [126.87944, 37.51779],
            [126.87955, 37.51766],
            [126.87942, 37.51753],
            [126.87929, 37.51752],
            [126.87874, 37.51765],
            [126.87858, 37.51763],
            [126.87848, 37.51753],
            [126.87847, 37.51739],
            [126.87896, 37.51704],
            [126.87892, 37.51689],
            [126.87833, 37.51632],
            [126.87749, 37.51617],
            [126.87732, 37.516],
            [126.8774, 37.51585],
            [126.87779, 37.51559],
            [126.87781, 37.51547],
            [126.87759, 37.51504],
            [126.87753, 37.51471],
            [126.87767, 37.51384],
            [126.87786, 37.51359],
            [126.87791, 37.51336],
            [126.87773, 37.51331],
            [126.87728, 37.51374],
            [126.87665, 37.51367],
            [126.8765, 37.51355],
            [126.87639, 37.51329],
            [126.87645, 37.51292],
            [126.8761, 37.51241],
            [126.8756, 37.51217],
            [126.87532, 37.51224],
            [126.87518, 37.51245],
            [126.874, 37.51131],
            [126.87406, 37.51077],
            [126.87433, 37.51062],
            [126.87469, 37.51069],
            [126.87492, 37.51091],
            [126.87498, 37.51116],
            [126.87488, 37.51164],
            [126.87508, 37.5118],
            [126.8752, 37.51179],
            [126.87584, 37.5113],
            [126.87657, 37.51115],
            [126.87685, 37.51085],
            [126.87674, 37.51068],
            [126.87654, 37.51063],
            [126.87614, 37.51106],
            [126.87578, 37.51115],
            [126.87552, 37.51108],
            [126.87522, 37.5107],
            [126.8752, 37.51053],
            [126.87535, 37.51002],
            [126.87561, 37.50974],
            [126.87551, 37.50955],
            [126.8753, 37.50938],
            [126.87504, 37.50931],
            [126.87479, 37.50933],
            [126.87452, 37.50947],
            [126.87424, 37.50948],
            [126.87398, 37.50934],
            [126.87384, 37.50914],
            [126.87379, 37.50882],
            [126.87391, 37.50868],
            [126.87477, 37.5083],
            [126.87504, 37.50771],
            [126.87488, 37.5075],
            [126.87445, 37.50736],
            [126.87424, 37.50718],
            [126.8742, 37.50703],
            [126.87432, 37.50678],
            [126.87449, 37.50665],
            [126.87471, 37.50663],
            [126.87442, 37.50609],
            [126.87411, 37.50515],
            [126.87374, 37.50472],
            [126.87379, 37.50427],
            [126.87359, 37.50354],
            [126.87318, 37.50378],
            [126.87267, 37.50388],
            [126.87244, 37.50403],
            [126.87186, 37.50413],
            [126.87145, 37.50469],
            [126.87144, 37.50507],
            [126.87101, 37.50527],
            [126.87065, 37.50573],
            [126.87022, 37.50586],
            [126.86905, 37.50487],
            [126.86796, 37.50489],
            [126.86714, 37.5053],
            [126.86679, 37.50529],
            [126.86657, 37.50521],
            [126.86645, 37.50526],
            [126.8665, 37.50535],
            [126.86635, 37.50545],
            [126.86581, 37.50557],
            [126.86487, 37.50552],
            [126.86482, 37.50507],
            [126.86466, 37.50486],
            [126.86353, 37.50506],
            [126.86324, 37.50528],
            [126.86278, 37.50594],
            [126.86266, 37.50645],
            [126.86292, 37.50657],
            [126.86297, 37.50653],
            [126.86265, 37.5068],
            [126.86331, 37.50751],
            [126.86372, 37.5078],
            [126.8638, 37.50802],
            [126.86407, 37.50831],
            [126.86318, 37.50786],
            [126.86274, 37.50743],
            [126.86044, 37.50674],
            [126.86034, 37.50659],
            [126.85831, 37.50998],
            [126.85561, 37.50921],
            [126.8552, 37.50977],
            [126.85284, 37.51073],
            [126.8525, 37.51057],
            [126.85215, 37.51055],
            [126.85135, 37.51019],
            [126.85072, 37.51005],
            [126.85005, 37.51019],
            [126.84897, 37.50912],
            [126.84866, 37.50893],
            [126.84727, 37.50952],
            [126.84698, 37.50939],
            [126.84663, 37.50904],
            [126.84626, 37.50897],
            [126.84619, 37.50846],
            [126.84596, 37.50804],
            [126.84608, 37.50807],
            [126.84538, 37.50779],
            [126.84516, 37.50778],
            [126.84484, 37.5074],
            [126.84436, 37.50654],
            [126.84468, 37.50575],
            [126.84425, 37.50572],
            [126.84344, 37.50584],
            [126.84264, 37.5055],
            [126.84239, 37.50552],
            [126.84206, 37.50569],
            [126.84117, 37.50584],
            [126.84072, 37.50608],
            [126.84048, 37.50556],
            [126.84015, 37.50545],
            [126.83998, 37.50487],
            [126.83958, 37.50446],
            [126.83929, 37.5044],
            [126.8388, 37.5045],
            [126.83842, 37.50436],
            [126.83818, 37.5038],
            [126.8375, 37.50324],
            [126.8369, 37.50328],
            [126.83645, 37.50303],
            [126.8353, 37.50282],
            [126.83398, 37.50336],
            [126.83363, 37.50388],
            [126.83305, 37.50407],
            [126.83292, 37.50427],
            [126.83201, 37.50468],
            [126.83165, 37.50508],
            [126.83154, 37.50545],
            [126.83126, 37.50572],
            [126.83123, 37.50587],
            [126.83062, 37.50631],
            [126.83066, 37.50687],
            [126.83107, 37.50791],
            [126.83106, 37.50807],
            [126.83066, 37.50827],
            [126.82985, 37.50839],
            [126.8295, 37.50836],
            [126.82809, 37.50884],
            [126.82692, 37.50851],
            [126.82655, 37.50874],
            [126.8264, 37.50874],
            [126.82474, 37.50828],
            [126.8239, 37.5091],
            [126.82434, 37.51023],
            [126.82405, 37.5105],
            [126.82389, 37.51084],
            [126.82418, 37.51238],
            [126.82444, 37.51317],
            [126.82427, 37.51444],
            [126.8234, 37.51495],
            [126.82311, 37.51619],
            [126.82452, 37.51756],
            [126.82463, 37.51796],
            [126.82559, 37.51988],
            [126.82567, 37.52101],
            [126.82554, 37.52145],
            [126.82559, 37.52175],
            [126.82548, 37.52175],
            [126.82565, 37.52219],
            [126.82522, 37.52278],
            [126.82514, 37.52301],
            [126.82582, 37.52371],
            [126.82643, 37.52475],
            [126.82646, 37.52492],
            [126.8267, 37.52512],
            [126.82719, 37.52523],
            [126.82818, 37.52583],
            [126.82824, 37.52624],
            [126.82884, 37.52651],
            [126.82842, 37.5267],
            [126.828, 37.52798],
            [126.82798, 37.52839],
            [126.82831, 37.52904],
            [126.82714, 37.52965],
            [126.82542, 37.52992],
            [126.82472, 37.53059],
            [126.82455, 37.53125],
            [126.82408, 37.5317],
            [126.82397, 37.53189],
            [126.82396, 37.53207],
            [126.8235, 37.53262],
            [126.82347, 37.53345],
            [126.82355, 37.53376],
            [126.8227, 37.53375],
            [126.82262, 37.5342],
            [126.82188, 37.53489],
            [126.82243, 37.53654],
            [126.82234, 37.53796],
            [126.8216, 37.53976],
            [126.82215, 37.54067],
            [126.82401, 37.54132],
            [126.82444, 37.54129],
            [126.82538, 37.54161],
            [126.82543, 37.54171],
            [126.82532, 37.54204],
            [126.82604, 37.54291],
            [126.82612, 37.54327],
            [126.82574, 37.54389],
            [126.82571, 37.54426],
            [126.82598, 37.54484],
            [126.82625, 37.54519],
            [126.82655, 37.54539],
            [126.827, 37.54605],
            [126.82703, 37.54655],
            [126.82715, 37.54674],
            [126.82741, 37.5469],
            [126.82744, 37.54745],
            [126.8276, 37.54767],
            [126.82789, 37.54753],
            [126.82975, 37.54771],
            [126.82995, 37.54692],
            [126.82996, 37.54656],
            [126.83009, 37.54643],
            [126.83013, 37.5462],
            [126.83024, 37.54618],
            [126.83047, 37.54578],
            [126.83044, 37.54549],
            [126.83014, 37.54494],
            [126.82996, 37.5444],
            [126.82986, 37.54444],
            [126.82963, 37.54409],
            [126.82973, 37.54395],
            [126.82971, 37.54374],
            [126.83027, 37.54254],
            [126.83001, 37.54252],
            [126.82999, 37.5417],
            [126.83027, 37.5415],
            [126.83054, 37.54176],
            [126.8331, 37.54185],
            [126.83373, 37.53984],
            [126.83516, 37.53753],
            [126.83521, 37.53713],
            [126.83511, 37.53673],
            [126.83443, 37.53646],
            [126.84048, 37.52649],
            [126.84885, 37.52789],
            [126.86397, 37.52979],
            [126.86344, 37.5361],
            [126.86378, 37.54071],
            [126.86374, 37.54094],
            [126.86339, 37.54161],
            [126.86352, 37.54172],
            [126.8629, 37.54252],
            [126.86228, 37.54366],
            [126.86212, 37.54415],
            [126.86212, 37.54447],
            [126.86421, 37.55115],
            [126.86447, 37.55124],
            [126.86576, 37.55066],
            [126.87032, 37.54791],
            [126.871, 37.54757],
            [126.87243, 37.54711],
            [126.87405, 37.54694]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        rgnCode: '11500',
        rgnKo: ['서울특별시', '강서구'],
        colCode: '11000',
        rgnSize: '2',
        predVal: [
          0.5924, 0.6607, 0.8323, 0.8374, 0.8375, 0.8345, 0.825, 0.8509, 0.8108,
          0.8123, 0.7949, 0.7949, 0.3028, 0.7361, 0.7046, 0.8224, 0.3928,
          0.7118, 0.6993, 0.7033, 0.7344, 0.8447, 0.7967, 0.8393, 0.7133,
          0.8187, 0.763, 0.7705, 0.7533, 0.785, 0.6592
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.80936, 37.6004],
            [126.81137, 37.59896],
            [126.81395, 37.59762],
            [126.81656, 37.59568],
            [126.81753, 37.59533],
            [126.81862, 37.59437],
            [126.82279, 37.5913],
            [126.82332, 37.59117],
            [126.82529, 37.59008],
            [126.82705, 37.5882],
            [126.83279, 37.58359],
            [126.8467, 37.57739],
            [126.84796, 37.57644],
            [126.85101, 37.57451],
            [126.85363, 37.5738],
            [126.85363, 37.57179],
            [126.85607, 37.56944],
            [126.8638, 37.56452],
            [126.86677, 37.56288],
            [126.86911, 37.56196],
            [126.87382, 37.55969],
            [126.88045, 37.55626],
            [126.87924, 37.55475],
            [126.87922, 37.55315],
            [126.87824, 37.55358],
            [126.878, 37.5535],
            [126.87793, 37.55334],
            [126.878, 37.55304],
            [126.87805, 37.5517],
            [126.87966, 37.54976],
            [126.88044, 37.54846],
            [126.88086, 37.54813],
            [126.88048, 37.54822],
            [126.87992, 37.54814],
            [126.88026, 37.54774],
            [126.87796, 37.5471],
            [126.87395, 37.54694],
            [126.87286, 37.54704],
            [126.8718, 37.54728],
            [126.87044, 37.54784],
            [126.86576, 37.55066],
            [126.86447, 37.55124],
            [126.86421, 37.55115],
            [126.86212, 37.54448],
            [126.86212, 37.54415],
            [126.86228, 37.54366],
            [126.8629, 37.54252],
            [126.86352, 37.54172],
            [126.86339, 37.54161],
            [126.86374, 37.54094],
            [126.86378, 37.54071],
            [126.86344, 37.5361],
            [126.86398, 37.52979],
            [126.84888, 37.5279],
            [126.84048, 37.52648],
            [126.83442, 37.53645],
            [126.8351, 37.53672],
            [126.83521, 37.53713],
            [126.83516, 37.53753],
            [126.83373, 37.53984],
            [126.8331, 37.54185],
            [126.83054, 37.54176],
            [126.83031, 37.54148],
            [126.82998, 37.54166],
            [126.83001, 37.54252],
            [126.83027, 37.54254],
            [126.82972, 37.54373],
            [126.82973, 37.54395],
            [126.82963, 37.54409],
            [126.82986, 37.54444],
            [126.82996, 37.5444],
            [126.83014, 37.54494],
            [126.83044, 37.54549],
            [126.83047, 37.54578],
            [126.83024, 37.54618],
            [126.83013, 37.5462],
            [126.83009, 37.54643],
            [126.82996, 37.54656],
            [126.82995, 37.54692],
            [126.82975, 37.54771],
            [126.82789, 37.54753],
            [126.8276, 37.54767],
            [126.82754, 37.5476],
            [126.82738, 37.54721],
            [126.82741, 37.54692],
            [126.82715, 37.54674],
            [126.82703, 37.54655],
            [126.827, 37.54605],
            [126.82655, 37.54539],
            [126.82625, 37.54519],
            [126.82598, 37.54484],
            [126.82571, 37.54426],
            [126.82574, 37.54389],
            [126.82612, 37.54327],
            [126.82604, 37.54292],
            [126.82532, 37.54204],
            [126.82543, 37.54171],
            [126.82538, 37.54161],
            [126.82444, 37.54129],
            [126.82401, 37.54132],
            [126.82214, 37.54068],
            [126.82079, 37.54079],
            [126.81943, 37.54078],
            [126.81631, 37.54055],
            [126.81535, 37.54058],
            [126.81473, 37.54072],
            [126.81307, 37.5407],
            [126.81257, 37.54078],
            [126.8104, 37.54168],
            [126.80826, 37.54323],
            [126.80739, 37.54358],
            [126.80687, 37.54361],
            [126.80537, 37.54333],
            [126.80352, 37.54314],
            [126.80167, 37.54267],
            [126.80043, 37.54181],
            [126.79968, 37.54146],
            [126.80058, 37.54154],
            [126.80082, 37.54128],
            [126.8009, 37.54088],
            [126.80081, 37.54087],
            [126.80081, 37.54081],
            [126.79944, 37.54066],
            [126.79944, 37.54028],
            [126.79938, 37.54026],
            [126.79889, 37.54035],
            [126.79876, 37.53948],
            [126.79923, 37.53937],
            [126.79943, 37.5391],
            [126.79947, 37.53898],
            [126.79916, 37.53896],
            [126.79942, 37.53821],
            [126.79948, 37.53777],
            [126.79929, 37.53773],
            [126.79909, 37.53757],
            [126.79826, 37.5377],
            [126.79837, 37.53725],
            [126.79804, 37.53727],
            [126.79781, 37.53705],
            [126.79702, 37.53706],
            [126.79677, 37.53696],
            [126.79675, 37.53684],
            [126.79664, 37.53679],
            [126.79611, 37.53677],
            [126.79544, 37.53642],
            [126.79481, 37.53597],
            [126.79443, 37.53584],
            [126.79438, 37.53625],
            [126.79397, 37.53737],
            [126.79405, 37.538],
            [126.79423, 37.5383],
            [126.79418, 37.53859],
            [126.79394, 37.53903],
            [126.79395, 37.53943],
            [126.79391, 37.53954],
            [126.79327, 37.53971],
            [126.79409, 37.54017],
            [126.79434, 37.54021],
            [126.7944, 37.54044],
            [126.79489, 37.54105],
            [126.79494, 37.54134],
            [126.79213, 37.54176],
            [126.79181, 37.54192],
            [126.79176, 37.54218],
            [126.79159, 37.54245],
            [126.79154, 37.54283],
            [126.79158, 37.54306],
            [126.79176, 37.54329],
            [126.79183, 37.54375],
            [126.79123, 37.54374],
            [126.79076, 37.54385],
            [126.78939, 37.54447],
            [126.78834, 37.54507],
            [126.78775, 37.54574],
            [126.78727, 37.54609],
            [126.78696, 37.546],
            [126.78549, 37.54601],
            [126.78448, 37.54613],
            [126.78394, 37.54602],
            [126.78339, 37.54602],
            [126.78204, 37.54633],
            [126.78184, 37.54607],
            [126.78049, 37.5464],
            [126.77921, 37.54656],
            [126.77875, 37.54672],
            [126.77758, 37.54671],
            [126.77675, 37.54821],
            [126.7753, 37.54897],
            [126.77259, 37.54876],
            [126.77195, 37.54838],
            [126.77158, 37.54833],
            [126.77136, 37.54864],
            [126.77109, 37.5487],
            [126.77103, 37.54903],
            [126.77084, 37.54936],
            [126.77032, 37.54984],
            [126.77031, 37.55002],
            [126.76988, 37.55036],
            [126.76986, 37.55064],
            [126.77027, 37.55109],
            [126.77023, 37.55113],
            [126.77017, 37.55118],
            [126.76979, 37.55108],
            [126.76935, 37.55137],
            [126.76933, 37.55158],
            [126.76888, 37.55158],
            [126.76877, 37.5515],
            [126.76856, 37.5516],
            [126.76816, 37.55164],
            [126.76805, 37.5518],
            [126.76747, 37.55196],
            [126.76772, 37.55284],
            [126.76754, 37.55292],
            [126.76737, 37.55313],
            [126.76763, 37.5535],
            [126.76756, 37.55357],
            [126.76735, 37.55359],
            [126.76729, 37.55368],
            [126.76764, 37.55395],
            [126.76764, 37.55411],
            [126.7674, 37.55423],
            [126.76702, 37.55409],
            [126.76673, 37.55407],
            [126.76653, 37.55381],
            [126.76637, 37.55376],
            [126.76636, 37.55396],
            [126.76621, 37.55424],
            [126.76641, 37.55466],
            [126.76636, 37.55486],
            [126.76607, 37.555],
            [126.76506, 37.55502],
            [126.76479, 37.55509],
            [126.76451, 37.55528],
            [126.76458, 37.55545],
            [126.76504, 37.55561],
            [126.76598, 37.55611],
            [126.76675, 37.55633],
            [126.76678, 37.55652],
            [126.76631, 37.55686],
            [126.76651, 37.55704],
            [126.76734, 37.55663],
            [126.76876, 37.55681],
            [126.76902, 37.5571],
            [126.7699, 37.55723],
            [126.77119, 37.55717],
            [126.77198, 37.55697],
            [126.77241, 37.55698],
            [126.7732, 37.5575],
            [126.77291, 37.5578],
            [126.77179, 37.55863],
            [126.77221, 37.55862],
            [126.77247, 37.55872],
            [126.77276, 37.5594],
            [126.77319, 37.55929],
            [126.77352, 37.55976],
            [126.77361, 37.56005],
            [126.77419, 37.5597],
            [126.77429, 37.55976],
            [126.77425, 37.56006],
            [126.77417, 37.56016],
            [126.77441, 37.56041],
            [126.77481, 37.56046],
            [126.77466, 37.5607],
            [126.77493, 37.5609],
            [126.77495, 37.56104],
            [126.77468, 37.56132],
            [126.77545, 37.56141],
            [126.77556, 37.56183],
            [126.77575, 37.56193],
            [126.77589, 37.56177],
            [126.77611, 37.56186],
            [126.77718, 37.5607],
            [126.77735, 37.56032],
            [126.77784, 37.55993],
            [126.77795, 37.56028],
            [126.7776, 37.56146],
            [126.77753, 37.56228],
            [126.77695, 37.56224],
            [126.77665, 37.56237],
            [126.77662, 37.56261],
            [126.77672, 37.56264],
            [126.77675, 37.56277],
            [126.77676, 37.56322],
            [126.777, 37.56369],
            [126.77713, 37.56372],
            [126.77614, 37.56481],
            [126.77596, 37.56521],
            [126.77578, 37.56535],
            [126.77538, 37.56534],
            [126.7756, 37.56556],
            [126.77546, 37.56577],
            [126.77516, 37.56587],
            [126.77502, 37.56692],
            [126.77478, 37.56733],
            [126.7748, 37.56769],
            [126.77554, 37.56825],
            [126.77559, 37.56774],
            [126.77543, 37.56733],
            [126.77563, 37.56722],
            [126.77634, 37.56711],
            [126.7766, 37.5661],
            [126.77675, 37.56605],
            [126.77699, 37.56621],
            [126.77703, 37.56636],
            [126.77732, 37.56667],
            [126.77729, 37.56675],
            [126.77757, 37.56693],
            [126.77897, 37.56728],
            [126.77892, 37.56801],
            [126.77898, 37.56828],
            [126.7792, 37.56841],
            [126.78003, 37.56771],
            [126.78022, 37.56758],
            [126.7803, 37.56762],
            [126.78041, 37.56786],
            [126.78044, 37.56838],
            [126.78117, 37.56892],
            [126.7815, 37.56898],
            [126.78166, 37.56921],
            [126.78132, 37.56949],
            [126.78121, 37.56949],
            [126.78084, 37.56994],
            [126.7804, 37.57032],
            [126.78048, 37.57046],
            [126.78056, 37.57054],
            [126.7812, 37.57029],
            [126.78171, 37.57025],
            [126.78244, 37.57033],
            [126.78262, 37.57051],
            [126.78192, 37.5711],
            [126.78137, 37.57126],
            [126.78144, 37.57145],
            [126.78192, 37.57179],
            [126.78221, 37.57266],
            [126.78213, 37.57296],
            [126.78217, 37.57316],
            [126.78244, 37.5736],
            [126.78403, 37.57419],
            [126.78444, 37.57447],
            [126.78528, 37.57485],
            [126.78764, 37.57557],
            [126.78786, 37.57585],
            [126.78795, 37.57611],
            [126.78806, 37.57615],
            [126.78837, 37.57604],
            [126.78864, 37.57574],
            [126.78924, 37.57557],
            [126.78851, 37.57676],
            [126.78936, 37.57771],
            [126.78994, 37.57764],
            [126.79003, 37.57736],
            [126.7906, 37.57777],
            [126.79085, 37.57897],
            [126.79071, 37.57901],
            [126.79085, 37.57956],
            [126.79076, 37.58059],
            [126.79084, 37.58076],
            [126.7912, 37.58082],
            [126.79129, 37.58092],
            [126.79138, 37.5814],
            [126.7916, 37.58132],
            [126.79241, 37.57971],
            [126.79275, 37.57747],
            [126.79276, 37.57687],
            [126.79324, 37.57689],
            [126.79318, 37.57816],
            [126.79328, 37.57853],
            [126.79309, 37.57863],
            [126.79309, 37.57902],
            [126.79287, 37.58016],
            [126.79289, 37.58022],
            [126.79334, 37.58024],
            [126.7935, 37.58098],
            [126.79382, 37.58183],
            [126.79391, 37.58296],
            [126.79376, 37.58303],
            [126.79367, 37.58383],
            [126.79345, 37.58449],
            [126.79378, 37.58447],
            [126.79422, 37.58408],
            [126.79454, 37.58355],
            [126.79451, 37.583],
            [126.79441, 37.58269],
            [126.79449, 37.5826],
            [126.7947, 37.5826],
            [126.79495, 37.5834],
            [126.7949, 37.58475],
            [126.79499, 37.58517],
            [126.79523, 37.58518],
            [126.79525, 37.5853],
            [126.79554, 37.58515],
            [126.79572, 37.58312],
            [126.79587, 37.58319],
            [126.79635, 37.58373],
            [126.79688, 37.58401],
            [126.79711, 37.58426],
            [126.79632, 37.58488],
            [126.79643, 37.58503],
            [126.79668, 37.58491],
            [126.79716, 37.58539],
            [126.79723, 37.58539],
            [126.79753, 37.58598],
            [126.7976, 37.58641],
            [126.79877, 37.58807],
            [126.79913, 37.58819],
            [126.79945, 37.58806],
            [126.80031, 37.58793],
            [126.80053, 37.58776],
            [126.80076, 37.58781],
            [126.80096, 37.58814],
            [126.80087, 37.58824],
            [126.80102, 37.5885],
            [126.8009, 37.58856],
            [126.80083, 37.58891],
            [126.80087, 37.58896],
            [126.80112, 37.58888],
            [126.80125, 37.58933],
            [126.80076, 37.58987],
            [126.80055, 37.59028],
            [126.80009, 37.59035],
            [126.79946, 37.59111],
            [126.79901, 37.59122],
            [126.79883, 37.59138],
            [126.79905, 37.59223],
            [126.79901, 37.59239],
            [126.79908, 37.59244],
            [126.79961, 37.59244],
            [126.79991, 37.59223],
            [126.80005, 37.59203],
            [126.80029, 37.59204],
            [126.8004, 37.59238],
            [126.8005, 37.59241],
            [126.79873, 37.59365],
            [126.79825, 37.59409],
            [126.79772, 37.59475],
            [126.7975, 37.59521],
            [126.79712, 37.5977],
            [126.79751, 37.59764],
            [126.79757, 37.59776],
            [126.79757, 37.59859],
            [126.79767, 37.59924],
            [126.79789, 37.59987],
            [126.79807, 37.60015],
            [126.79822, 37.6003],
            [126.79857, 37.60006],
            [126.7988, 37.60036],
            [126.79872, 37.60042],
            [126.79907, 37.60073],
            [126.79922, 37.60105],
            [126.79996, 37.60142],
            [126.80004, 37.60155],
            [126.8, 37.60168],
            [126.79981, 37.60188],
            [126.79995, 37.60248],
            [126.80276, 37.6049],
            [126.80586, 37.60258],
            [126.80824, 37.60121],
            [126.80936, 37.6004]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        rgnCode: '11530',
        rgnKo: ['서울특별시', '구로구'],
        colCode: '11000',
        rgnSize: '2',
        predVal: [
          0.7099, 0.7664, 0.7819, 0.827, 0.8111, 0.78, 0.8091, 0.8509, 0.6924,
          0.7714, 0.7823, 0.8296, 0.3028, 0.824, 0.7644, 0.7818, 0.7912, 0.7962,
          0.792, 0.789, 0.8105, 0.8447, 0.7636, 0.8393, 0.7397, 0.8187, 0.7989,
          0.7846, 0.7533, 0.785, 0.741
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.87936, 37.51793],
            [126.88179, 37.51635],
            [126.88247, 37.51635],
            [126.88249, 37.51623],
            [126.88268, 37.51616],
            [126.88273, 37.51517],
            [126.8841, 37.51442],
            [126.88434, 37.51439],
            [126.8843, 37.51418],
            [126.88443, 37.51385],
            [126.88462, 37.51375],
            [126.88569, 37.51362],
            [126.8868, 37.5133],
            [126.88752, 37.51323],
            [126.888, 37.51295],
            [126.8884, 37.51234],
            [126.88973, 37.51227],
            [126.88983, 37.5121],
            [126.8897, 37.51191],
            [126.8898, 37.51176],
            [126.88995, 37.51171],
            [126.88994, 37.51156],
            [126.89026, 37.5114],
            [126.89065, 37.51094],
            [126.89069, 37.5107],
            [126.89108, 37.5104],
            [126.8915, 37.51042],
            [126.89171, 37.51035],
            [126.89205, 37.50987],
            [126.89215, 37.50937],
            [126.89243, 37.50912],
            [126.89228, 37.50906],
            [126.89308, 37.50714],
            [126.89302, 37.50711],
            [126.89302, 37.50693],
            [126.89324, 37.50673],
            [126.89353, 37.50603],
            [126.89367, 37.50586],
            [126.89398, 37.50501],
            [126.89393, 37.50482],
            [126.89378, 37.50473],
            [126.89317, 37.5046],
            [126.89311, 37.50445],
            [126.89331, 37.50397],
            [126.89315, 37.50374],
            [126.89273, 37.50347],
            [126.89267, 37.50333],
            [126.89314, 37.50268],
            [126.89273, 37.50207],
            [126.89292, 37.50161],
            [126.89276, 37.50083],
            [126.89293, 37.50035],
            [126.89362, 37.49959],
            [126.89403, 37.4982],
            [126.89428, 37.49726],
            [126.89432, 37.49645],
            [126.8946, 37.49516],
            [126.89568, 37.49085],
            [126.89602, 37.49021],
            [126.89612, 37.48934],
            [126.89826, 37.48687],
            [126.89976, 37.48609],
            [126.90171, 37.48522],
            [126.90274, 37.48502],
            [126.90319, 37.485],
            [126.89962, 37.48057],
            [126.89909, 37.4795],
            [126.89887, 37.47834],
            [126.89805, 37.47834],
            [126.89724, 37.47846],
            [126.89624, 37.47831],
            [126.89587, 37.47834],
            [126.89585, 37.47851],
            [126.89508, 37.47851],
            [126.88988, 37.47939],
            [126.88879, 37.47979],
            [126.88773, 37.48044],
            [126.88617, 37.48234],
            [126.88272, 37.48475],
            [126.88135, 37.48556],
            [126.87944, 37.48623],
            [126.87849, 37.48666],
            [126.87768, 37.48611],
            [126.87457, 37.48537],
            [126.87486, 37.48567],
            [126.87502, 37.48601],
            [126.87505, 37.48666],
            [126.87536, 37.4869],
            [126.87597, 37.48691],
            [126.87604, 37.48698],
            [126.87667, 37.48783],
            [126.87679, 37.48857],
            [126.87638, 37.48894],
            [126.87605, 37.48905],
            [126.87581, 37.48903],
            [126.87521, 37.48852],
            [126.87472, 37.48857],
            [126.87449, 37.48887],
            [126.87437, 37.48887],
            [126.87373, 37.48866],
            [126.87326, 37.48831],
            [126.87308, 37.48826],
            [126.87275, 37.48843],
            [126.87264, 37.48898],
            [126.87241, 37.48933],
            [126.87244, 37.48954],
            [126.87284, 37.49001],
            [126.87337, 37.49039],
            [126.87375, 37.49048],
            [126.87393, 37.49043],
            [126.87409, 37.49021],
            [126.87411, 37.48978],
            [126.87433, 37.48969],
            [126.87458, 37.48976],
            [126.87465, 37.48989],
            [126.87444, 37.49028],
            [126.87446, 37.49042],
            [126.87484, 37.49075],
            [126.87468, 37.49084],
            [126.87437, 37.49122],
            [126.87413, 37.49134],
            [126.87377, 37.49122],
            [126.8733, 37.49085],
            [126.87272, 37.49082],
            [126.87225, 37.49063],
            [126.87181, 37.4908],
            [126.87155, 37.49077],
            [126.87147, 37.49057],
            [126.87159, 37.49028],
            [126.87151, 37.49],
            [126.87134, 37.48983],
            [126.87066, 37.48956],
            [126.87021, 37.48961],
            [126.87013, 37.48981],
            [126.87023, 37.49011],
            [126.86976, 37.49035],
            [126.86968, 37.49049],
            [126.86999, 37.49087],
            [126.86991, 37.49125],
            [126.86935, 37.49166],
            [126.8693, 37.49185],
            [126.86951, 37.49213],
            [126.87001, 37.49225],
            [126.87001, 37.49248],
            [126.86946, 37.49261],
            [126.86937, 37.49277],
            [126.86959, 37.49312],
            [126.87006, 37.49354],
            [126.8695, 37.49433],
            [126.86871, 37.49481],
            [126.86839, 37.49511],
            [126.86823, 37.49513],
            [126.86759, 37.49482],
            [126.86755, 37.49462],
            [126.86681, 37.49426],
            [126.86686, 37.49412],
            [126.86719, 37.49399],
            [126.86755, 37.49426],
            [126.86765, 37.49426],
            [126.8678, 37.49405],
            [126.8678, 37.49361],
            [126.86737, 37.49301],
            [126.86713, 37.49287],
            [126.86661, 37.49289],
            [126.86669, 37.49266],
            [126.86655, 37.49259],
            [126.8663, 37.49263],
            [126.8662, 37.49256],
            [126.86579, 37.4921],
            [126.86545, 37.49156],
            [126.86538, 37.49152],
            [126.86516, 37.49162],
            [126.86482, 37.49135],
            [126.8648, 37.4912],
            [126.86462, 37.49103],
            [126.86449, 37.49109],
            [126.86452, 37.4912],
            [126.86403, 37.49102],
            [126.86281, 37.49083],
            [126.86217, 37.49058],
            [126.86177, 37.49033],
            [126.85797, 37.48608],
            [126.85748, 37.48581],
            [126.85643, 37.4857],
            [126.85573, 37.4855],
            [126.85516, 37.48517],
            [126.85489, 37.48482],
            [126.85386, 37.48284],
            [126.85365, 37.48263],
            [126.8531, 37.48233],
            [126.85274, 37.48183],
            [126.85179, 37.48159],
            [126.85076, 37.48151],
            [126.84851, 37.48208],
            [126.84813, 37.4821],
            [126.84702, 37.48191],
            [126.84652, 37.4816],
            [126.84623, 37.48126],
            [126.846, 37.4807],
            [126.84566, 37.47641],
            [126.84536, 37.47381],
            [126.84499, 37.47349],
            [126.84484, 37.47372],
            [126.84449, 37.47398],
            [126.84425, 37.4746],
            [126.84405, 37.47447],
            [126.84355, 37.47454],
            [126.84311, 37.47487],
            [126.84278, 37.47497],
            [126.84083, 37.47466],
            [126.84011, 37.47476],
            [126.83957, 37.47519],
            [126.83923, 37.47517],
            [126.8383, 37.47539],
            [126.8372, 37.47488],
            [126.8362, 37.47481],
            [126.83593, 37.47448],
            [126.83572, 37.47448],
            [126.83559, 37.47439],
            [126.83483, 37.47442],
            [126.83464, 37.47436],
            [126.83464, 37.47481],
            [126.83489, 37.47572],
            [126.83426, 37.47624],
            [126.83393, 37.47675],
            [126.83364, 37.47697],
            [126.83322, 37.47719],
            [126.83199, 37.47749],
            [126.83175, 37.47765],
            [126.83046, 37.47734],
            [126.82963, 37.4769],
            [126.82956, 37.47661],
            [126.8292, 37.4762],
            [126.82787, 37.47599],
            [126.82622, 37.47615],
            [126.82433, 37.47614],
            [126.82433, 37.47625],
            [126.82417, 37.47619],
            [126.82416, 37.47632],
            [126.82402, 37.47644],
            [126.82386, 37.47636],
            [126.8237, 37.47611],
            [126.82323, 37.47611],
            [126.82324, 37.47595],
            [126.82215, 37.47572],
            [126.82199, 37.47551],
            [126.82196, 37.47528],
            [126.82175, 37.47519],
            [126.82165, 37.47535],
            [126.82164, 37.47624],
            [126.82132, 37.47619],
            [126.82107, 37.4763],
            [126.82018, 37.47627],
            [126.82007, 37.4762],
            [126.8196, 37.47636],
            [126.81938, 37.47631],
            [126.8191, 37.47615],
            [126.81909, 37.47546],
            [126.81947, 37.47514],
            [126.81896, 37.47524],
            [126.81889, 37.47515],
            [126.81845, 37.4752],
            [126.81872, 37.47412],
            [126.81795, 37.47367],
            [126.81768, 37.47322],
            [126.81708, 37.47329],
            [126.81695, 37.47368],
            [126.81658, 37.47398],
            [126.81556, 37.47405],
            [126.81465, 37.47472],
            [126.81496, 37.47586],
            [126.8153, 37.47636],
            [126.81611, 37.47675],
            [126.81609, 37.47711],
            [126.81641, 37.47754],
            [126.81721, 37.47814],
            [126.81826, 37.47816],
            [126.819, 37.47916],
            [126.81937, 37.47986],
            [126.81955, 37.48038],
            [126.81958, 37.48079],
            [126.81998, 37.48169],
            [126.81995, 37.48194],
            [126.81957, 37.48248],
            [126.81952, 37.48297],
            [126.81939, 37.48331],
            [126.81956, 37.4837],
            [126.81931, 37.48418],
            [126.81949, 37.48459],
            [126.8193, 37.48548],
            [126.82128, 37.48616],
            [126.82163, 37.4865],
            [126.82238, 37.48671],
            [126.82247, 37.4869],
            [126.82351, 37.48774],
            [126.82301, 37.48815],
            [126.82305, 37.48929],
            [126.82283, 37.48964],
            [126.82276, 37.48998],
            [126.8216, 37.4903],
            [126.82094, 37.49058],
            [126.821, 37.49065],
            [126.82096, 37.49079],
            [126.82033, 37.49074],
            [126.82004, 37.4908],
            [126.81909, 37.49138],
            [126.81858, 37.49157],
            [126.81792, 37.49155],
            [126.81589, 37.49263],
            [126.8155, 37.4932],
            [126.81462, 37.4932],
            [126.81432, 37.49376],
            [126.81435, 37.49443],
            [126.81382, 37.49498],
            [126.81298, 37.49648],
            [126.81326, 37.49682],
            [126.81399, 37.49735],
            [126.81412, 37.49821],
            [126.81429, 37.49835],
            [126.81467, 37.4981],
            [126.81517, 37.49811],
            [126.81576, 37.49775],
            [126.81613, 37.49774],
            [126.81651, 37.49787],
            [126.81687, 37.49811],
            [126.81756, 37.49831],
            [126.81821, 37.49896],
            [126.81891, 37.49885],
            [126.81967, 37.49936],
            [126.8194, 37.50064],
            [126.81943, 37.5008],
            [126.82, 37.50143],
            [126.82061, 37.50177],
            [126.82134, 37.50202],
            [126.82161, 37.50224],
            [126.82159, 37.5029],
            [126.82206, 37.50398],
            [126.82186, 37.50456],
            [126.82208, 37.50513],
            [126.82238, 37.50535],
            [126.82255, 37.50569],
            [126.82251, 37.506],
            [126.82235, 37.50631],
            [126.82222, 37.50763],
            [126.8226, 37.50763],
            [126.8235, 37.50804],
            [126.82474, 37.50828],
            [126.8264, 37.50874],
            [126.82655, 37.50874],
            [126.82692, 37.50851],
            [126.82809, 37.50884],
            [126.8295, 37.50836],
            [126.82985, 37.50839],
            [126.83066, 37.50827],
            [126.83106, 37.50807],
            [126.83107, 37.50791],
            [126.83066, 37.50687],
            [126.83062, 37.50631],
            [126.83123, 37.50587],
            [126.83126, 37.50572],
            [126.83154, 37.50545],
            [126.83165, 37.50508],
            [126.83201, 37.50468],
            [126.83292, 37.50427],
            [126.83305, 37.50407],
            [126.83363, 37.50388],
            [126.83398, 37.50336],
            [126.8353, 37.50282],
            [126.83641, 37.50302],
            [126.8369, 37.50328],
            [126.8375, 37.50324],
            [126.83818, 37.5038],
            [126.83842, 37.50436],
            [126.8388, 37.5045],
            [126.83929, 37.5044],
            [126.83958, 37.50446],
            [126.83998, 37.50487],
            [126.84015, 37.50545],
            [126.84048, 37.50556],
            [126.84072, 37.50608],
            [126.84117, 37.50584],
            [126.84206, 37.50569],
            [126.84239, 37.50552],
            [126.84264, 37.5055],
            [126.84344, 37.50584],
            [126.8444, 37.50569],
            [126.84449, 37.50579],
            [126.84451, 37.50572],
            [126.84468, 37.50575],
            [126.84436, 37.50654],
            [126.84484, 37.5074],
            [126.84516, 37.50778],
            [126.84536, 37.50777],
            [126.84595, 37.50803],
            [126.84619, 37.50846],
            [126.84626, 37.50897],
            [126.84663, 37.50904],
            [126.84698, 37.50939],
            [126.84727, 37.50952],
            [126.84866, 37.50893],
            [126.84897, 37.50912],
            [126.85005, 37.51019],
            [126.85072, 37.51005],
            [126.85135, 37.51019],
            [126.85215, 37.51055],
            [126.8525, 37.51057],
            [126.85282, 37.51073],
            [126.8552, 37.50977],
            [126.85561, 37.50921],
            [126.85831, 37.50998],
            [126.86032, 37.50662],
            [126.86044, 37.50674],
            [126.86271, 37.5074],
            [126.86318, 37.50786],
            [126.8641, 37.50835],
            [126.86372, 37.5078],
            [126.86331, 37.50751],
            [126.86265, 37.5068],
            [126.863, 37.50651],
            [126.86266, 37.50646],
            [126.86285, 37.50581],
            [126.86332, 37.50519],
            [126.86353, 37.50506],
            [126.86466, 37.50486],
            [126.86482, 37.50507],
            [126.86484, 37.5055],
            [126.86525, 37.50559],
            [126.86581, 37.50557],
            [126.86652, 37.50538],
            [126.86645, 37.50526],
            [126.86657, 37.50521],
            [126.86679, 37.50529],
            [126.86714, 37.5053],
            [126.86796, 37.50489],
            [126.86901, 37.50486],
            [126.87022, 37.50586],
            [126.87065, 37.50573],
            [126.87101, 37.50527],
            [126.87144, 37.50507],
            [126.87145, 37.50469],
            [126.87186, 37.50413],
            [126.87244, 37.50403],
            [126.87265, 37.50389],
            [126.87318, 37.50378],
            [126.87357, 37.50356],
            [126.87379, 37.50427],
            [126.87374, 37.50472],
            [126.87411, 37.50515],
            [126.87442, 37.50609],
            [126.87471, 37.50663],
            [126.87449, 37.50665],
            [126.87432, 37.50678],
            [126.8742, 37.50703],
            [126.87424, 37.50718],
            [126.87445, 37.50736],
            [126.87488, 37.5075],
            [126.87504, 37.50771],
            [126.87477, 37.5083],
            [126.87391, 37.50868],
            [126.87379, 37.50882],
            [126.87384, 37.50914],
            [126.87404, 37.50939],
            [126.87433, 37.50949],
            [126.875, 37.50931],
            [126.87522, 37.50934],
            [126.87551, 37.50955],
            [126.87561, 37.50974],
            [126.87535, 37.51002],
            [126.87519, 37.51051],
            [126.87522, 37.5107],
            [126.87552, 37.51108],
            [126.87578, 37.51115],
            [126.87614, 37.51106],
            [126.87654, 37.51063],
            [126.87674, 37.51068],
            [126.87685, 37.51084],
            [126.87657, 37.51115],
            [126.87584, 37.5113],
            [126.8752, 37.51179],
            [126.87509, 37.51181],
            [126.87488, 37.51164],
            [126.87498, 37.51116],
            [126.87492, 37.51091],
            [126.87469, 37.51069],
            [126.87433, 37.51062],
            [126.87406, 37.51077],
            [126.874, 37.51129],
            [126.8751, 37.51241],
            [126.87518, 37.51245],
            [126.87532, 37.51224],
            [126.8756, 37.51217],
            [126.8761, 37.51241],
            [126.87645, 37.51292],
            [126.87639, 37.51327],
            [126.8765, 37.51355],
            [126.87665, 37.51367],
            [126.87728, 37.51374],
            [126.87773, 37.51331],
            [126.87791, 37.51336],
            [126.87786, 37.51359],
            [126.87767, 37.51384],
            [126.87753, 37.51471],
            [126.87759, 37.51504],
            [126.8778, 37.51544],
            [126.87779, 37.51559],
            [126.8774, 37.51585],
            [126.87734, 37.51605],
            [126.87749, 37.51617],
            [126.87807, 37.51622],
            [126.87849, 37.51641],
            [126.87896, 37.51693],
            [126.87889, 37.51709],
            [126.87851, 37.51733],
            [126.87848, 37.51753],
            [126.87874, 37.51765],
            [126.87942, 37.51753],
            [126.87955, 37.5177],
            [126.87913, 37.51786],
            [126.87936, 37.51793]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        rgnCode: '11545',
        rgnKo: ['서울특별시', '금천구'],
        colCode: '11000',
        rgnSize: '2',
        predVal: [
          0.5924, 0.8183, 0.8323, 0.8374, 0.8375, 0.8345, 0.825, 0.8509, 0.6862,
          0.7714, 0.7746, 0.7949, 0.3028, 0.7947, 0.7876, 0.8224, 0.8414, 0.849,
          0.8407, 0.8347, 0.8981, 0.8447, 0.8013, 0.8393, 0.7689, 0.8187,
          0.7526, 0.7705, 0.7533, 0.785, 0.5678
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.88148, 37.48549],
            [126.88272, 37.48475],
            [126.88581, 37.48262],
            [126.88615, 37.48235],
            [126.88773, 37.48044],
            [126.88876, 37.47981],
            [126.88999, 37.47937],
            [126.89508, 37.47851],
            [126.89585, 37.47851],
            [126.89587, 37.47834],
            [126.89647, 37.47833],
            [126.8972, 37.47846],
            [126.89805, 37.47834],
            [126.89887, 37.47834],
            [126.89894, 37.47894],
            [126.90524, 37.48011],
            [126.90552, 37.47998],
            [126.9098, 37.48072],
            [126.90993, 37.48027],
            [126.90902, 37.48009],
            [126.9091, 37.47962],
            [126.90875, 37.47947],
            [126.90864, 37.47909],
            [126.90948, 37.478],
            [126.90984, 37.47819],
            [126.91047, 37.47836],
            [126.91064, 37.47796],
            [126.91183, 37.47782],
            [126.91181, 37.47685],
            [126.91162, 37.4763],
            [126.91139, 37.476],
            [126.9113, 37.47543],
            [126.91119, 37.47522],
            [126.91134, 37.4749],
            [126.91137, 37.47446],
            [126.91108, 37.47417],
            [126.91104, 37.47386],
            [126.91076, 37.47358],
            [126.91054, 37.4736],
            [126.91002, 37.47342],
            [126.90986, 37.47327],
            [126.90866, 37.47297],
            [126.90821, 37.4727],
            [126.90962, 37.47066],
            [126.90955, 37.47045],
            [126.91025, 37.46925],
            [126.90997, 37.46879],
            [126.91104, 37.46806],
            [126.91107, 37.46771],
            [126.91158, 37.4671],
            [126.91161, 37.46679],
            [126.91236, 37.46623],
            [126.91235, 37.46586],
            [126.91254, 37.46572],
            [126.91306, 37.46564],
            [126.91351, 37.46539],
            [126.9137, 37.46538],
            [126.91294, 37.46418],
            [126.9129, 37.464],
            [126.91296, 37.46365],
            [126.9133, 37.46312],
            [126.91371, 37.46287],
            [126.91395, 37.462],
            [126.9144, 37.46162],
            [126.91419, 37.46098],
            [126.91426, 37.45997],
            [126.91405, 37.45949],
            [126.91428, 37.45892],
            [126.91403, 37.45846],
            [126.91403, 37.45791],
            [126.91458, 37.45746],
            [126.91498, 37.45731],
            [126.91558, 37.4574],
            [126.91642, 37.45739],
            [126.9169, 37.45752],
            [126.91728, 37.45734],
            [126.91747, 37.45712],
            [126.91832, 37.45693],
            [126.91914, 37.4569],
            [126.91968, 37.45676],
            [126.92089, 37.45683],
            [126.92219, 37.45663],
            [126.92253, 37.45633],
            [126.92281, 37.45521],
            [126.92309, 37.45452],
            [126.92332, 37.45422],
            [126.92418, 37.45368],
            [126.92426, 37.45339],
            [126.92476, 37.45287],
            [126.92542, 37.45301],
            [126.92566, 37.45293],
            [126.92628, 37.45254],
            [126.92669, 37.45173],
            [126.92731, 37.45101],
            [126.92787, 37.4511],
            [126.92837, 37.45101],
            [126.92845, 37.45081],
            [126.9285, 37.45057],
            [126.9283, 37.44933],
            [126.92774, 37.44895],
            [126.92654, 37.44839],
            [126.92602, 37.4477],
            [126.925, 37.4468],
            [126.92325, 37.44577],
            [126.9229, 37.44517],
            [126.92295, 37.44487],
            [126.92276, 37.44404],
            [126.92236, 37.4437],
            [126.92166, 37.44281],
            [126.92117, 37.44255],
            [126.9203, 37.44043],
            [126.91929, 37.43982],
            [126.91789, 37.44011],
            [126.91613, 37.44002],
            [126.91485, 37.43935],
            [126.91391, 37.43928],
            [126.9132, 37.43908],
            [126.91284, 37.4387],
            [126.91229, 37.43855],
            [126.9118, 37.43777],
            [126.91143, 37.43762],
            [126.9112, 37.4372],
            [126.91133, 37.43618],
            [126.91068, 37.4355],
            [126.91061, 37.43526],
            [126.91022, 37.43482],
            [126.91003, 37.43432],
            [126.90941, 37.43386],
            [126.9086, 37.43366],
            [126.90793, 37.43369],
            [126.90723, 37.43348],
            [126.90703, 37.43351],
            [126.90692, 37.43363],
            [126.90641, 37.43371],
            [126.90615, 37.434],
            [126.90485, 37.43402],
            [126.90353, 37.43419],
            [126.90299, 37.43407],
            [126.90282, 37.43472],
            [126.90283, 37.43585],
            [126.90202, 37.43629],
            [126.90108, 37.43694],
            [126.90087, 37.43699],
            [126.90055, 37.43736],
            [126.90035, 37.43775],
            [126.89993, 37.43799],
            [126.89922, 37.43871],
            [126.89899, 37.43866],
            [126.89876, 37.43928],
            [126.89927, 37.43965],
            [126.8999, 37.43955],
            [126.89989, 37.43963],
            [126.89941, 37.44073],
            [126.89926, 37.44098],
            [126.89899, 37.44117],
            [126.89874, 37.44214],
            [126.89726, 37.44543],
            [126.89671, 37.4457],
            [126.89578, 37.44563],
            [126.89551, 37.44578],
            [126.89531, 37.44621],
            [126.89465, 37.4467],
            [126.89473, 37.44763],
            [126.895, 37.44822],
            [126.89525, 37.44837],
            [126.89559, 37.44834],
            [126.89602, 37.44805],
            [126.89481, 37.45088],
            [126.8946, 37.45117],
            [126.89398, 37.45272],
            [126.89361, 37.45282],
            [126.89325, 37.45276],
            [126.89306, 37.4526],
            [126.89273, 37.452],
            [126.8926, 37.45193],
            [126.8924, 37.45199],
            [126.89212, 37.45227],
            [126.89196, 37.4523],
            [126.89105, 37.45224],
            [126.89021, 37.4525],
            [126.88985, 37.45228],
            [126.88964, 37.45231],
            [126.88952, 37.4527],
            [126.88966, 37.4536],
            [126.88954, 37.45376],
            [126.8891, 37.45397],
            [126.88903, 37.45417],
            [126.88926, 37.45448],
            [126.8892, 37.45472],
            [126.88877, 37.45477],
            [126.88834, 37.45509],
            [126.8882, 37.4554],
            [126.88801, 37.45558],
            [126.88729, 37.45549],
            [126.88635, 37.45623],
            [126.88619, 37.45652],
            [126.88587, 37.45759],
            [126.88595, 37.45789],
            [126.88632, 37.45845],
            [126.88634, 37.45896],
            [126.88623, 37.45921],
            [126.8861, 37.4593],
            [126.88542, 37.45942],
            [126.88535, 37.45986],
            [126.88552, 37.46026],
            [126.88613, 37.4609],
            [126.88683, 37.46093],
            [126.88747, 37.46107],
            [126.88623, 37.46249],
            [126.88611, 37.46276],
            [126.88514, 37.46247],
            [126.88432, 37.46273],
            [126.88378, 37.46318],
            [126.88275, 37.46439],
            [126.88277, 37.46483],
            [126.88287, 37.46496],
            [126.88315, 37.46513],
            [126.88429, 37.4654],
            [126.88457, 37.46565],
            [126.88462, 37.46601],
            [126.8843, 37.46652],
            [126.8831, 37.46702],
            [126.88272, 37.46774],
            [126.88254, 37.46793],
            [126.88195, 37.46822],
            [126.88163, 37.46846],
            [126.88145, 37.469],
            [126.88159, 37.46942],
            [126.8802, 37.47093],
            [126.87801, 37.47383],
            [126.87599, 37.4771],
            [126.8742, 37.48049],
            [126.87421, 37.48061],
            [126.87338, 37.48244],
            [126.87307, 37.48228],
            [126.87276, 37.48249],
            [126.87282, 37.48346],
            [126.87288, 37.48364],
            [126.87331, 37.48418],
            [126.87229, 37.48451],
            [126.87188, 37.48496],
            [126.87182, 37.48523],
            [126.87232, 37.48573],
            [126.87244, 37.4861],
            [126.87279, 37.48627],
            [126.87313, 37.48615],
            [126.87341, 37.48591],
            [126.87379, 37.48536],
            [126.87422, 37.4852],
            [126.87456, 37.48537],
            [126.87768, 37.48611],
            [126.87846, 37.48667],
            [126.87944, 37.48623],
            [126.88148, 37.48549]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        rgnCode: '11560',
        rgnKo: ['서울특별시', '영등포구'],
        colCode: '11000',
        rgnSize: '2',
        predVal: [
          0.5924, 0.605, 0.8323, 0.8374, 0.8375, 0.8345, 0.825, 0.8509, 0.8108,
          0.8123, 0.7949, 0.7949, 0.3028, 0.7361, 0.7046, 0.8224, 0.3928,
          0.7118, 0.6993, 0.7033, 0.7344, 0.8447, 0.7967, 0.8393, 0.7133,
          0.8187, 0.7483, 0.7705, 0.7533, 0.785, 0.6494
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.88591, 37.55354],
            [126.88759, 37.55271],
            [126.88759, 37.55263],
            [126.88811, 37.5523],
            [126.89391, 37.54974],
            [126.89464, 37.54943],
            [126.89894, 37.54801],
            [126.90182, 37.54502],
            [126.90218, 37.54412],
            [126.90482, 37.54143],
            [126.92241, 37.54143],
            [126.92283, 37.54062],
            [126.92308, 37.54045],
            [126.92482, 37.53974],
            [126.92711, 37.53904],
            [126.92729, 37.53913],
            [126.92765, 37.53908],
            [126.93032, 37.54135],
            [126.9329, 37.54053],
            [126.93856, 37.53784],
            [126.94422, 37.53424],
            [126.94459, 37.53379],
            [126.94468, 37.53391],
            [126.94717, 37.53063],
            [126.94741, 37.53017],
            [126.94988, 37.52703],
            [126.94989, 37.51753],
            [126.94956, 37.51763],
            [126.93928, 37.51608],
            [126.93414, 37.51591],
            [126.93241, 37.51558],
            [126.92971, 37.51545],
            [126.929, 37.51542],
            [126.92686, 37.51555],
            [126.9268, 37.5147],
            [126.92689, 37.51452],
            [126.92687, 37.51441],
            [126.92677, 37.51446],
            [126.92667, 37.5144],
            [126.92702, 37.51387],
            [126.92697, 37.51322],
            [126.92676, 37.51314],
            [126.92679, 37.51284],
            [126.92539, 37.51253],
            [126.92036, 37.50005],
            [126.92029, 37.50003],
            [126.92021, 37.49834],
            [126.91982, 37.4978],
            [126.91923, 37.49756],
            [126.91487, 37.49661],
            [126.91253, 37.49635],
            [126.91196, 37.49604],
            [126.91161, 37.49561],
            [126.91023, 37.49348],
            [126.90842, 37.49135],
            [126.90752, 37.49],
            [126.90683, 37.48923],
            [126.90664, 37.48921],
            [126.90321, 37.48502],
            [126.90274, 37.48502],
            [126.90171, 37.48522],
            [126.89865, 37.48664],
            [126.89814, 37.48694],
            [126.89612, 37.48934],
            [126.89602, 37.49021],
            [126.89568, 37.49085],
            [126.8946, 37.49516],
            [126.89432, 37.49645],
            [126.89428, 37.49725],
            [126.89403, 37.4982],
            [126.89362, 37.49959],
            [126.89293, 37.50035],
            [126.89276, 37.50083],
            [126.89293, 37.50157],
            [126.89273, 37.50207],
            [126.89313, 37.50267],
            [126.89269, 37.50331],
            [126.89273, 37.50347],
            [126.89315, 37.50374],
            [126.8933, 37.50394],
            [126.89311, 37.50445],
            [126.89317, 37.5046],
            [126.89378, 37.50473],
            [126.8939, 37.5048],
            [126.89397, 37.50499],
            [126.89367, 37.50586],
            [126.89326, 37.50669],
            [126.89302, 37.50693],
            [126.89303, 37.50725],
            [126.89228, 37.50906],
            [126.89243, 37.50912],
            [126.89219, 37.50934],
            [126.89191, 37.51015],
            [126.89171, 37.51035],
            [126.8915, 37.51042],
            [126.89111, 37.51037],
            [126.89069, 37.5107],
            [126.89065, 37.51094],
            [126.89026, 37.5114],
            [126.88994, 37.51156],
            [126.88996, 37.51171],
            [126.88968, 37.51188],
            [126.88983, 37.5121],
            [126.88973, 37.51227],
            [126.8884, 37.51234],
            [126.888, 37.51295],
            [126.88752, 37.51323],
            [126.8868, 37.5133],
            [126.88569, 37.51362],
            [126.88462, 37.51375],
            [126.88443, 37.51385],
            [126.8843, 37.51418],
            [126.88434, 37.51439],
            [126.8841, 37.51442],
            [126.88273, 37.51517],
            [126.88268, 37.51616],
            [126.88249, 37.51623],
            [126.88247, 37.51635],
            [126.88179, 37.51635],
            [126.87936, 37.51793],
            [126.8792, 37.51787],
            [126.87878, 37.51794],
            [126.87851, 37.51824],
            [126.87825, 37.51875],
            [126.87835, 37.51936],
            [126.87867, 37.5197],
            [126.87919, 37.52003],
            [126.87946, 37.52035],
            [126.87949, 37.52059],
            [126.87931, 37.52178],
            [126.87903, 37.5225],
            [126.87912, 37.52251],
            [126.87893, 37.52319],
            [126.87893, 37.52406],
            [126.87869, 37.52463],
            [126.8787, 37.52512],
            [126.87886, 37.52539],
            [126.87917, 37.52564],
            [126.8794, 37.52574],
            [126.88071, 37.52578],
            [126.88112, 37.52613],
            [126.88117, 37.52655],
            [126.88067, 37.52713],
            [126.8806, 37.52743],
            [126.88067, 37.52756],
            [126.88094, 37.52774],
            [126.88134, 37.52779],
            [126.8819, 37.52765],
            [126.88273, 37.52726],
            [126.88309, 37.52724],
            [126.88342, 37.52733],
            [126.88375, 37.52758],
            [126.88389, 37.52791],
            [126.88392, 37.52841],
            [126.88418, 37.52867],
            [126.88515, 37.52915],
            [126.88594, 37.52941],
            [126.88718, 37.53019],
            [126.88769, 37.53038],
            [126.88847, 37.53043],
            [126.88937, 37.53019],
            [126.88959, 37.52998],
            [126.88967, 37.53015],
            [126.88977, 37.53008],
            [126.88975, 37.53028],
            [126.89073, 37.53168],
            [126.89033, 37.53189],
            [126.88907, 37.53193],
            [126.88869, 37.53212],
            [126.88857, 37.5324],
            [126.88869, 37.53276],
            [126.88984, 37.53342],
            [126.89005, 37.5337],
            [126.89017, 37.5341],
            [126.89016, 37.53438],
            [126.88994, 37.53487],
            [126.88943, 37.53559],
            [126.88832, 37.53667],
            [126.88772, 37.53753],
            [126.88713, 37.53859],
            [126.8869, 37.53878],
            [126.88658, 37.53929],
            [126.88551, 37.54158],
            [126.88545, 37.54232],
            [126.8851, 37.5433],
            [126.88444, 37.54338],
            [126.8843, 37.54354],
            [126.88418, 37.54456],
            [126.88266, 37.54587],
            [126.88213, 37.54672],
            [126.88068, 37.54818],
            [126.88086, 37.54813],
            [126.88044, 37.54846],
            [126.87966, 37.54976],
            [126.87805, 37.5517],
            [126.87795, 37.55345],
            [126.87824, 37.55358],
            [126.87922, 37.55315],
            [126.87924, 37.55475],
            [126.88043, 37.55622],
            [126.88139, 37.55581],
            [126.88591, 37.55354]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        rgnCode: '11590',
        rgnKo: ['서울특별시', '동작구'],
        colCode: '11000',
        rgnSize: '2',
        predVal: [
          0.5924, 0.6024, 0.7119, 0.8192, 0.8373, 0.8402, 0.8573, 0.854, 0.8502,
          0.8505, 0.842, 0.8461, 0.8411, 0.8518, 0.8437, 0.845, 0.8786, 0.8883,
          0.8865, 0.8799, 0.9234, 0.8447, 0.8598, 0.8393, 0.7829, 0.8187,
          0.7852, 0.7705, 0.8494, 0.9051, 0.9049
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.9547, 37.51606],
            [126.96017, 37.51382],
            [126.9612, 37.5135],
            [126.96258, 37.51262],
            [126.96496, 37.51082],
            [126.96686, 37.50989],
            [126.97008, 37.50902],
            [126.97526, 37.50701],
            [126.97819, 37.50655],
            [126.9804, 37.50655],
            [126.9804, 37.50459],
            [126.98007, 37.50436],
            [126.97985, 37.50437],
            [126.97985, 37.50417],
            [126.98015, 37.5037],
            [126.98022, 37.50321],
            [126.9804, 37.50286],
            [126.98054, 37.5027],
            [126.98203, 37.50204],
            [126.98341, 37.50126],
            [126.98428, 37.50071],
            [126.98538, 37.49986],
            [126.98293, 37.49699],
            [126.98282, 37.49265],
            [126.98268, 37.49188],
            [126.98264, 37.49009],
            [126.98232, 37.48854],
            [126.9822, 37.4869],
            [126.98169, 37.47653],
            [126.97842, 37.47665],
            [126.97429, 37.4762],
            [126.97052, 37.47538],
            [126.97031, 37.47588],
            [126.97025, 37.47654],
            [126.96888, 37.47659],
            [126.96778, 37.47786],
            [126.96726, 37.47806],
            [126.96633, 37.47896],
            [126.96479, 37.47951],
            [126.96443, 37.47982],
            [126.96395, 37.48103],
            [126.96325, 37.48141],
            [126.96269, 37.48209],
            [126.96204, 37.48246],
            [126.96183, 37.48295],
            [126.96107, 37.48358],
            [126.96108, 37.4837],
            [126.96163, 37.48421],
            [126.9617, 37.48451],
            [126.96164, 37.48469],
            [126.96199, 37.48518],
            [126.96167, 37.48598],
            [126.96181, 37.48632],
            [126.96185, 37.48714],
            [126.96172, 37.48722],
            [126.96197, 37.48863],
            [126.96184, 37.48868],
            [126.96176, 37.48901],
            [126.9614, 37.48915],
            [126.961, 37.49004],
            [126.96087, 37.49018],
            [126.96078, 37.49083],
            [126.9614, 37.49148],
            [126.96138, 37.49287],
            [126.96095, 37.49292],
            [126.96105, 37.49326],
            [126.96029, 37.49374],
            [126.95931, 37.49381],
            [126.95894, 37.49362],
            [126.9587, 37.49292],
            [126.95845, 37.49262],
            [126.9576, 37.49236],
            [126.95748, 37.49198],
            [126.95696, 37.49172],
            [126.95623, 37.49149],
            [126.95539, 37.49143],
            [126.95516, 37.49131],
            [126.95488, 37.49099],
            [126.95418, 37.49095],
            [126.95373, 37.49064],
            [126.95287, 37.49134],
            [126.95281, 37.49149],
            [126.95224, 37.49169],
            [126.95207, 37.49195],
            [126.9517, 37.49221],
            [126.95, 37.4926],
            [126.94957, 37.49278],
            [126.94944, 37.49298],
            [126.94882, 37.49318],
            [126.94879, 37.49386],
            [126.94834, 37.49372],
            [126.94755, 37.49376],
            [126.94696, 37.49413],
            [126.94678, 37.4939],
            [126.94609, 37.49353],
            [126.94587, 37.49356],
            [126.94542, 37.49335],
            [126.94436, 37.49312],
            [126.94436, 37.49249],
            [126.94394, 37.49249],
            [126.94339, 37.49224],
            [126.94262, 37.49214],
            [126.9423, 37.49225],
            [126.94159, 37.4922],
            [126.94108, 37.49242],
            [126.94085, 37.49289],
            [126.94058, 37.49286],
            [126.94039, 37.49297],
            [126.93993, 37.4929],
            [126.93973, 37.49279],
            [126.93974, 37.49263],
            [126.93949, 37.49247],
            [126.93946, 37.49229],
            [126.93921, 37.49222],
            [126.93889, 37.49233],
            [126.9383, 37.49239],
            [126.93811, 37.49226],
            [126.93782, 37.49225],
            [126.93726, 37.49191],
            [126.93633, 37.49204],
            [126.93613, 37.49229],
            [126.93582, 37.49239],
            [126.93537, 37.49273],
            [126.93385, 37.49319],
            [126.9324, 37.49297],
            [126.93136, 37.49322],
            [126.93007, 37.494],
            [126.92986, 37.494],
            [126.92842, 37.49486],
            [126.92791, 37.49508],
            [126.9275, 37.49499],
            [126.92723, 37.49472],
            [126.92717, 37.49456],
            [126.92664, 37.49415],
            [126.92651, 37.49349],
            [126.92558, 37.4928],
            [126.92541, 37.49247],
            [126.92535, 37.49197],
            [126.92512, 37.49166],
            [126.92482, 37.49092],
            [126.92416, 37.49036],
            [126.92416, 37.48996],
            [126.92281, 37.49005],
            [126.9213, 37.49033],
            [126.91945, 37.49028],
            [126.9187, 37.49013],
            [126.91665, 37.48955],
            [126.91468, 37.48859],
            [126.91357, 37.48801],
            [126.91307, 37.48738],
            [126.91231, 37.48677],
            [126.91192, 37.48651],
            [126.91127, 37.48626],
            [126.90989, 37.4859],
            [126.90939, 37.4859],
            [126.9061, 37.48496],
            [126.90553, 37.48491],
            [126.9032, 37.485],
            [126.9067, 37.48928],
            [126.9068, 37.48921],
            [126.90752, 37.49],
            [126.90842, 37.49135],
            [126.91047, 37.49382],
            [126.91161, 37.49561],
            [126.91196, 37.49604],
            [126.91253, 37.49635],
            [126.91487, 37.49661],
            [126.91941, 37.4976],
            [126.91982, 37.4978],
            [126.92022, 37.49834],
            [126.92029, 37.5],
            [126.92539, 37.51253],
            [126.92679, 37.5128],
            [126.92677, 37.51311],
            [126.92697, 37.51322],
            [126.92702, 37.51387],
            [126.92667, 37.5144],
            [126.92677, 37.51446],
            [126.92687, 37.51441],
            [126.92689, 37.51452],
            [126.92681, 37.5147],
            [126.92686, 37.51554],
            [126.929, 37.51542],
            [126.92971, 37.51545],
            [126.93239, 37.51558],
            [126.93414, 37.51591],
            [126.93928, 37.51608],
            [126.94956, 37.51763],
            [126.9547, 37.51606]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        rgnCode: '11620',
        rgnKo: ['서울특별시', '관악구'],
        colCode: '11000',
        rgnSize: '2',
        predVal: [
          0.5924, 0.7171, 0.8059, 0.8192, 0.8373, 0.8402, 0.8573, 0.854, 0.7468,
          0.7772, 0.8128, 0.8461, 0.3028, 0.8518, 0.8437, 0.845, 0.8786, 0.8883,
          0.8865, 0.8799, 0.9234, 0.8447, 0.8598, 0.8393, 0.7829, 0.8187,
          0.7852, 0.7705, 0.8494, 0.9051, 0.9049
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.92791, 37.49508],
            [126.92842, 37.49486],
            [126.92986, 37.494],
            [126.93007, 37.494],
            [126.93136, 37.49322],
            [126.9324, 37.49297],
            [126.93382, 37.49318],
            [126.93384, 37.49312],
            [126.93437, 37.49304],
            [126.93537, 37.49273],
            [126.93582, 37.49239],
            [126.93613, 37.49229],
            [126.93633, 37.49204],
            [126.93726, 37.49191],
            [126.93782, 37.49225],
            [126.93811, 37.49226],
            [126.9383, 37.49239],
            [126.93889, 37.49233],
            [126.93921, 37.49222],
            [126.93946, 37.49229],
            [126.93956, 37.49256],
            [126.93971, 37.49261],
            [126.93973, 37.49279],
            [126.93993, 37.4929],
            [126.94039, 37.49297],
            [126.94058, 37.49286],
            [126.94085, 37.49289],
            [126.94108, 37.49242],
            [126.94159, 37.4922],
            [126.9423, 37.49225],
            [126.94262, 37.49214],
            [126.94338, 37.49224],
            [126.94353, 37.49236],
            [126.94394, 37.49249],
            [126.94436, 37.49249],
            [126.94436, 37.49312],
            [126.94542, 37.49335],
            [126.94587, 37.49356],
            [126.94609, 37.49353],
            [126.94678, 37.4939],
            [126.94696, 37.49413],
            [126.94755, 37.49376],
            [126.94834, 37.49372],
            [126.94879, 37.49386],
            [126.94882, 37.49318],
            [126.94942, 37.493],
            [126.94957, 37.49278],
            [126.94996, 37.49261],
            [126.9517, 37.49221],
            [126.95207, 37.49195],
            [126.95224, 37.49169],
            [126.95281, 37.49149],
            [126.95285, 37.49135],
            [126.95373, 37.49064],
            [126.95418, 37.49095],
            [126.95488, 37.49099],
            [126.95516, 37.49131],
            [126.95539, 37.49143],
            [126.95623, 37.49149],
            [126.95696, 37.49172],
            [126.95748, 37.49198],
            [126.9576, 37.49236],
            [126.95845, 37.49262],
            [126.9587, 37.49292],
            [126.95894, 37.49362],
            [126.95931, 37.49381],
            [126.96029, 37.49374],
            [126.96105, 37.49326],
            [126.96095, 37.49292],
            [126.96138, 37.49287],
            [126.9614, 37.4915],
            [126.96078, 37.49083],
            [126.96084, 37.49042],
            [126.96087, 37.49019],
            [126.9614, 37.48915],
            [126.96176, 37.48901],
            [126.96184, 37.48868],
            [126.96197, 37.48863],
            [126.96172, 37.48722],
            [126.96185, 37.48714],
            [126.96181, 37.48632],
            [126.96167, 37.48598],
            [126.96199, 37.48518],
            [126.96164, 37.48469],
            [126.96169, 37.48455],
            [126.96163, 37.48421],
            [126.96108, 37.4837],
            [126.96107, 37.48358],
            [126.96183, 37.48295],
            [126.96204, 37.48246],
            [126.96269, 37.48209],
            [126.96325, 37.48141],
            [126.96395, 37.48103],
            [126.96443, 37.47982],
            [126.96479, 37.47951],
            [126.96633, 37.47896],
            [126.96726, 37.47806],
            [126.96776, 37.47787],
            [126.96888, 37.47659],
            [126.97025, 37.47654],
            [126.97031, 37.47588],
            [126.97052, 37.47538],
            [126.97429, 37.4762],
            [126.97842, 37.47665],
            [126.98169, 37.47653],
            [126.98168, 37.47702],
            [126.98184, 37.47509],
            [126.98214, 37.47381],
            [126.98319, 37.47162],
            [126.98275, 37.47201],
            [126.98452, 37.46982],
            [126.98468, 37.46988],
            [126.98466, 37.46993],
            [126.98534, 37.46928],
            [126.98607, 37.46896],
            [126.98553, 37.46905],
            [126.98645, 37.46837],
            [126.9873, 37.46761],
            [126.98711, 37.46726],
            [126.98724, 37.46723],
            [126.98736, 37.46703],
            [126.98793, 37.46594],
            [126.98823, 37.46423],
            [126.98834, 37.464],
            [126.98826, 37.46344],
            [126.98758, 37.46091],
            [126.98754, 37.46023],
            [126.9877, 37.4596],
            [126.9886, 37.45815],
            [126.98678, 37.4574],
            [126.98661, 37.45721],
            [126.98526, 37.45718],
            [126.9849, 37.45704],
            [126.98439, 37.45711],
            [126.98289, 37.45683],
            [126.9819, 37.45694],
            [126.98242, 37.45583],
            [126.97983, 37.45556],
            [126.97855, 37.45565],
            [126.97779, 37.45512],
            [126.97466, 37.45432],
            [126.97344, 37.45314],
            [126.97293, 37.45234],
            [126.97185, 37.45166],
            [126.97058, 37.44948],
            [126.96766, 37.44838],
            [126.96429, 37.44627],
            [126.96394, 37.44525],
            [126.96442, 37.44431],
            [126.9643, 37.44377],
            [126.96438, 37.44258],
            [126.96463, 37.44204],
            [126.96382, 37.44132],
            [126.9638, 37.44079],
            [126.96298, 37.4403],
            [126.96176, 37.44028],
            [126.96006, 37.4404],
            [126.95915, 37.43955],
            [126.95891, 37.43912],
            [126.95637, 37.43875],
            [126.95515, 37.43874],
            [126.9539, 37.43906],
            [126.95239, 37.43924],
            [126.95153, 37.43866],
            [126.95109, 37.4385],
            [126.95092, 37.43873],
            [126.95003, 37.43862],
            [126.94984, 37.43838],
            [126.94951, 37.43828],
            [126.94931, 37.43826],
            [126.94877, 37.43865],
            [126.94836, 37.43871],
            [126.9468, 37.43817],
            [126.94661, 37.43794],
            [126.94573, 37.43729],
            [126.94513, 37.43708],
            [126.94203, 37.43749],
            [126.94146, 37.43739],
            [126.94117, 37.43696],
            [126.94059, 37.43631],
            [126.94047, 37.43582],
            [126.94024, 37.4357],
            [126.93937, 37.43603],
            [126.93862, 37.43603],
            [126.93858, 37.43641],
            [126.93833, 37.43665],
            [126.93801, 37.4372],
            [126.93775, 37.43738],
            [126.93774, 37.43788],
            [126.93756, 37.43833],
            [126.93727, 37.43863],
            [126.93725, 37.43917],
            [126.93769, 37.43968],
            [126.93785, 37.4402],
            [126.93679, 37.44145],
            [126.93666, 37.44174],
            [126.93599, 37.44214],
            [126.93564, 37.44218],
            [126.93492, 37.44319],
            [126.93457, 37.4432],
            [126.93177, 37.44496],
            [126.93057, 37.44546],
            [126.93017, 37.44638],
            [126.93029, 37.44662],
            [126.93039, 37.44761],
            [126.93017, 37.44837],
            [126.92929, 37.44947],
            [126.92899, 37.44963],
            [126.92891, 37.44987],
            [126.9284, 37.45021],
            [126.92851, 37.45054],
            [126.92837, 37.45101],
            [126.92787, 37.4511],
            [126.92734, 37.45101],
            [126.92669, 37.45173],
            [126.92628, 37.45254],
            [126.9257, 37.45291],
            [126.92542, 37.45301],
            [126.92476, 37.45287],
            [126.92428, 37.45337],
            [126.92419, 37.45366],
            [126.92332, 37.45422],
            [126.92309, 37.45452],
            [126.92281, 37.45521],
            [126.92253, 37.45633],
            [126.92219, 37.45663],
            [126.92089, 37.45683],
            [126.91968, 37.45676],
            [126.91914, 37.4569],
            [126.91832, 37.45693],
            [126.91748, 37.45711],
            [126.91729, 37.45734],
            [126.9169, 37.45752],
            [126.91642, 37.45739],
            [126.91558, 37.4574],
            [126.91498, 37.45731],
            [126.91461, 37.45744],
            [126.91403, 37.45791],
            [126.91402, 37.45843],
            [126.91428, 37.45891],
            [126.91404, 37.45947],
            [126.91427, 37.45993],
            [126.91419, 37.46098],
            [126.9144, 37.46162],
            [126.91397, 37.46199],
            [126.91373, 37.46285],
            [126.9133, 37.46312],
            [126.91297, 37.46362],
            [126.9129, 37.464],
            [126.91293, 37.46417],
            [126.9137, 37.46538],
            [126.91351, 37.46539],
            [126.91306, 37.46564],
            [126.91254, 37.46572],
            [126.91235, 37.46586],
            [126.91236, 37.46623],
            [126.91161, 37.46679],
            [126.91158, 37.4671],
            [126.91107, 37.46771],
            [126.91104, 37.46808],
            [126.90997, 37.46877],
            [126.91024, 37.46923],
            [126.90955, 37.47045],
            [126.90962, 37.47066],
            [126.9082, 37.47269],
            [126.90866, 37.47297],
            [126.90994, 37.4733],
            [126.91001, 37.47339],
            [126.91053, 37.4736],
            [126.91076, 37.47358],
            [126.91104, 37.47386],
            [126.91108, 37.47417],
            [126.91137, 37.47446],
            [126.91134, 37.4749],
            [126.91119, 37.47521],
            [126.91139, 37.476],
            [126.91162, 37.4763],
            [126.91181, 37.47685],
            [126.91183, 37.47782],
            [126.91064, 37.47796],
            [126.91047, 37.47836],
            [126.90984, 37.47819],
            [126.9095, 37.47802],
            [126.90863, 37.47908],
            [126.90875, 37.47924],
            [126.9087, 37.47938],
            [126.90881, 37.47955],
            [126.9091, 37.47962],
            [126.90898, 37.48008],
            [126.90992, 37.48025],
            [126.9098, 37.48072],
            [126.90552, 37.47998],
            [126.90525, 37.4801],
            [126.89894, 37.47894],
            [126.89922, 37.47984],
            [126.8997, 37.48068],
            [126.90319, 37.485],
            [126.90553, 37.48491],
            [126.9061, 37.48496],
            [126.90939, 37.4859],
            [126.90989, 37.4859],
            [126.91125, 37.48626],
            [126.91192, 37.48651],
            [126.91227, 37.48675],
            [126.91307, 37.48738],
            [126.91357, 37.48801],
            [126.91468, 37.48859],
            [126.91665, 37.48955],
            [126.9187, 37.49013],
            [126.91942, 37.49027],
            [126.9213, 37.49033],
            [126.92276, 37.49006],
            [126.92416, 37.48996],
            [126.9242, 37.49032],
            [126.92414, 37.49035],
            [126.92439, 37.49062],
            [126.92477, 37.49084],
            [126.92512, 37.49166],
            [126.92534, 37.49194],
            [126.92541, 37.49247],
            [126.92558, 37.4928],
            [126.92648, 37.49348],
            [126.92662, 37.4941],
            [126.92713, 37.49453],
            [126.92746, 37.49499],
            [126.92791, 37.49508]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        rgnCode: '11650',
        rgnKo: ['서울특별시', '서초구'],
        colCode: '11000',
        rgnSize: '2',
        predVal: [
          0.7063, 0.661, 0.5914, 0.6597, 0.8111, 0.7846, 0.8236, 0.8509, 0.8246,
          0.8383, 0.8346, 0.8388, 0.8428, 0.8445, 0.8432, 0.8232, 0.8522,
          0.8675, 0.8575, 0.8539, 0.8657, 0.8665, 0.8528, 0.8472, 0.8188,
          0.8187, 0.818, 0.8159, 0.8101, 0.8137, 0.8173
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [127.02133, 37.5111],
            [127.03409, 37.48437],
            [127.04175, 37.48544],
            [127.04161, 37.48524],
            [127.04295, 37.48227],
            [127.04306, 37.48226],
            [127.04314, 37.48187],
            [127.04389, 37.48056],
            [127.044, 37.47994],
            [127.04519, 37.47736],
            [127.04597, 37.47668],
            [127.04607, 37.47647],
            [127.0509, 37.47181],
            [127.05118, 37.47179],
            [127.04867, 37.47006],
            [127.04886, 37.46989],
            [127.05026, 37.46973],
            [127.05042, 37.46977],
            [127.05105, 37.46937],
            [127.05112, 37.46854],
            [127.05095, 37.46758],
            [127.05077, 37.4674],
            [127.05079, 37.46729],
            [127.05234, 37.46796],
            [127.05321, 37.4682],
            [127.05358, 37.46813],
            [127.05419, 37.46817],
            [127.05487, 37.46866],
            [127.05502, 37.46891],
            [127.05539, 37.46887],
            [127.05602, 37.4685],
            [127.05649, 37.46853],
            [127.0572, 37.46907],
            [127.05783, 37.46895],
            [127.05867, 37.46895],
            [127.05907, 37.46924],
            [127.06003, 37.46911],
            [127.06033, 37.46915],
            [127.06092, 37.46892],
            [127.06104, 37.46899],
            [127.06145, 37.469],
            [127.06161, 37.46892],
            [127.06213, 37.46905],
            [127.06236, 37.46929],
            [127.0626, 37.4693],
            [127.06322, 37.46901],
            [127.0638, 37.46897],
            [127.06413, 37.46918],
            [127.06507, 37.46929],
            [127.0656, 37.4695],
            [127.06588, 37.46975],
            [127.06622, 37.46986],
            [127.06644, 37.4701],
            [127.06784, 37.47038],
            [127.06855, 37.47096],
            [127.06956, 37.47099],
            [127.06991, 37.47086],
            [127.07042, 37.47099],
            [127.07073, 37.47131],
            [127.07131, 37.47148],
            [127.07193, 37.47184],
            [127.07269, 37.47192],
            [127.07317, 37.47223],
            [127.07384, 37.4729],
            [127.07423, 37.47292],
            [127.07531, 37.47343],
            [127.07651, 37.47449],
            [127.07692, 37.47524],
            [127.07796, 37.47485],
            [127.07853, 37.4751],
            [127.07899, 37.47492],
            [127.08176, 37.47544],
            [127.08275, 37.47547],
            [127.08324, 37.4757],
            [127.08385, 37.47559],
            [127.08448, 37.4758],
            [127.08494, 37.47566],
            [127.08446, 37.47514],
            [127.08451, 37.47403],
            [127.08417, 37.47293],
            [127.08502, 37.4713],
            [127.08589, 37.47121],
            [127.0866, 37.47085],
            [127.08676, 37.4707],
            [127.08716, 37.46998],
            [127.08756, 37.46984],
            [127.08758, 37.46941],
            [127.08779, 37.46925],
            [127.08813, 37.46849],
            [127.08842, 37.4682],
            [127.08898, 37.46815],
            [127.08904, 37.46824],
            [127.08971, 37.46742],
            [127.09013, 37.46714],
            [127.09043, 37.46715],
            [127.09112, 37.46618],
            [127.09162, 37.46572],
            [127.09195, 37.46523],
            [127.09204, 37.46492],
            [127.0921, 37.46227],
            [127.09334, 37.46125],
            [127.09414, 37.46113],
            [127.09467, 37.46092],
            [127.09566, 37.46099],
            [127.09578, 37.46061],
            [127.09566, 37.46026],
            [127.09531, 37.46005],
            [127.0951, 37.45977],
            [127.0952, 37.45944],
            [127.09493, 37.45846],
            [127.09535, 37.45791],
            [127.09546, 37.45746],
            [127.09525, 37.45646],
            [127.09567, 37.45657],
            [127.09577, 37.45652],
            [127.09523, 37.4564],
            [127.09521, 37.45632],
            [127.09431, 37.45623],
            [127.09377, 37.45608],
            [127.09354, 37.45589],
            [127.09346, 37.45555],
            [127.09314, 37.45491],
            [127.09322, 37.45472],
            [127.09273, 37.45363],
            [127.09188, 37.45347],
            [127.09096, 37.45278],
            [127.0903, 37.45127],
            [127.09014, 37.4511],
            [127.08962, 37.45089],
            [127.08954, 37.45017],
            [127.08882, 37.44975],
            [127.08896, 37.44946],
            [127.08843, 37.44899],
            [127.08833, 37.44863],
            [127.08836, 37.44837],
            [127.08841, 37.44808],
            [127.08864, 37.4476],
            [127.08837, 37.44724],
            [127.08828, 37.44655],
            [127.08839, 37.4464],
            [127.08822, 37.44603],
            [127.08828, 37.44541],
            [127.08795, 37.44488],
            [127.08758, 37.44479],
            [127.08739, 37.44461],
            [127.08653, 37.44425],
            [127.08583, 37.4444],
            [127.08395, 37.44391],
            [127.08391, 37.44343],
            [127.08339, 37.44294],
            [127.08329, 37.44254],
            [127.08304, 37.44232],
            [127.08251, 37.4416],
            [127.08216, 37.44134],
            [127.08147, 37.44124],
            [127.08128, 37.44113],
            [127.0803, 37.44103],
            [127.07973, 37.44138],
            [127.07921, 37.44129],
            [127.07871, 37.44153],
            [127.07818, 37.44155],
            [127.0778, 37.44182],
            [127.07746, 37.44163],
            [127.0772, 37.44164],
            [127.07684, 37.44192],
            [127.07614, 37.44213],
            [127.07534, 37.44195],
            [127.07489, 37.44221],
            [127.07385, 37.44221],
            [127.07299, 37.44202],
            [127.07214, 37.44226],
            [127.07163, 37.44152],
            [127.07186, 37.44107],
            [127.07221, 37.43938],
            [127.07201, 37.43886],
            [127.0736, 37.43792],
            [127.07388, 37.43742],
            [127.07367, 37.43707],
            [127.07324, 37.43669],
            [127.07308, 37.43642],
            [127.07169, 37.43598],
            [127.07144, 37.43586],
            [127.07128, 37.43562],
            [127.0713, 37.43536],
            [127.0715, 37.43507],
            [127.07152, 37.43412],
            [127.07112, 37.4334],
            [127.07073, 37.43294],
            [127.07057, 37.43242],
            [127.07062, 37.43211],
            [127.07091, 37.43186],
            [127.07121, 37.43082],
            [127.07118, 37.43051],
            [127.07091, 37.43021],
            [127.07034, 37.43049],
            [127.07003, 37.43091],
            [127.06982, 37.43097],
            [127.0699, 37.43089],
            [127.06962, 37.43061],
            [127.06897, 37.43045],
            [127.06831, 37.43072],
            [127.06792, 37.43048],
            [127.06655, 37.43006],
            [127.06595, 37.42954],
            [127.06569, 37.42919],
            [127.06566, 37.42902],
            [127.06509, 37.42902],
            [127.06464, 37.42923],
            [127.06438, 37.42916],
            [127.06329, 37.4298],
            [127.06289, 37.42976],
            [127.06087, 37.43012],
            [127.05998, 37.42986],
            [127.05975, 37.42972],
            [127.05889, 37.42981],
            [127.05786, 37.43015],
            [127.05618, 37.42973],
            [127.05588, 37.42974],
            [127.05535, 37.42949],
            [127.05511, 37.42956],
            [127.05494, 37.42942],
            [127.05454, 37.4294],
            [127.0537, 37.42912],
            [127.0535, 37.42912],
            [127.0532, 37.42887],
            [127.05234, 37.42859],
            [127.05213, 37.42844],
            [127.05142, 37.42905],
            [127.05136, 37.4292],
            [127.0502, 37.4299],
            [127.04997, 37.43021],
            [127.04964, 37.43046],
            [127.04932, 37.43034],
            [127.04737, 37.43076],
            [127.04725, 37.4318],
            [127.04731, 37.43215],
            [127.04662, 37.4328],
            [127.04639, 37.43359],
            [127.04594, 37.43364],
            [127.04507, 37.43399],
            [127.04474, 37.43421],
            [127.04447, 37.43456],
            [127.04421, 37.43525],
            [127.04273, 37.4365],
            [127.04149, 37.43726],
            [127.04125, 37.4375],
            [127.0412, 37.4379],
            [127.04014, 37.43834],
            [127.03915, 37.43828],
            [127.03854, 37.43849],
            [127.0379, 37.43839],
            [127.0375, 37.43845],
            [127.03715, 37.43839],
            [127.0364, 37.43891],
            [127.03569, 37.43914],
            [127.03573, 37.43953],
            [127.03564, 37.43986],
            [127.03545, 37.4401],
            [127.03526, 37.44102],
            [127.03562, 37.44133],
            [127.03643, 37.44183],
            [127.03684, 37.44236],
            [127.03682, 37.44259],
            [127.03697, 37.4429],
            [127.03748, 37.44327],
            [127.03757, 37.44384],
            [127.03792, 37.44416],
            [127.03823, 37.44518],
            [127.03821, 37.44586],
            [127.03733, 37.44634],
            [127.03725, 37.44645],
            [127.03753, 37.44736],
            [127.03717, 37.44804],
            [127.03737, 37.44856],
            [127.03781, 37.44892],
            [127.03774, 37.44945],
            [127.03731, 37.45],
            [127.03691, 37.45082],
            [127.03658, 37.45121],
            [127.03648, 37.45146],
            [127.0361, 37.45167],
            [127.03579, 37.4522],
            [127.03538, 37.45223],
            [127.03515, 37.45244],
            [127.03477, 37.45261],
            [127.03484, 37.45308],
            [127.03498, 37.45323],
            [127.03551, 37.45345],
            [127.03564, 37.45409],
            [127.03581, 37.45419],
            [127.03633, 37.45424],
            [127.03648, 37.45462],
            [127.03717, 37.4552],
            [127.03689, 37.45583],
            [127.03675, 37.45647],
            [127.03581, 37.45747],
            [127.03509, 37.45785],
            [127.03499, 37.45798],
            [127.03494, 37.45845],
            [127.03504, 37.45901],
            [127.03492, 37.46018],
            [127.03451, 37.46007],
            [127.03433, 37.46015],
            [127.03429, 37.46026],
            [127.03401, 37.4603],
            [127.03373, 37.46124],
            [127.03396, 37.46223],
            [127.03451, 37.4633],
            [127.03469, 37.46415],
            [127.03348, 37.46464],
            [127.03314, 37.46502],
            [127.03224, 37.46533],
            [127.03192, 37.46552],
            [127.0312, 37.46563],
            [127.0304, 37.46549],
            [127.03017, 37.46536],
            [127.02953, 37.46537],
            [127.02951, 37.46447],
            [127.02967, 37.46416],
            [127.02969, 37.46391],
            [127.02955, 37.46309],
            [127.02912, 37.46237],
            [127.02844, 37.46174],
            [127.02804, 37.46125],
            [127.02815, 37.46065],
            [127.02846, 37.46013],
            [127.02838, 37.45994],
            [127.02815, 37.45984],
            [127.02753, 37.45983],
            [127.02657, 37.45964],
            [127.0264, 37.45938],
            [127.02655, 37.45858],
            [127.02636, 37.45811],
            [127.02611, 37.45784],
            [127.02528, 37.45748],
            [127.02413, 37.45745],
            [127.02375, 37.45733],
            [127.02285, 37.45725],
            [127.02243, 37.45692],
            [127.02221, 37.45662],
            [127.0217, 37.45632],
            [127.02138, 37.4562],
            [127.02048, 37.45616],
            [127.01982, 37.45578],
            [127.01928, 37.45569],
            [127.01755, 37.45614],
            [127.01695, 37.45597],
            [127.0163, 37.45524],
            [127.01581, 37.455],
            [127.01506, 37.45483],
            [127.01451, 37.45486],
            [127.01393, 37.45509],
            [127.0134, 37.45548],
            [127.01285, 37.45563],
            [127.01197, 37.45567],
            [127.01082, 37.45544],
            [127.01062, 37.45614],
            [127.00996, 37.45688],
            [127.00957, 37.45712],
            [127.00924, 37.45722],
            [127.00866, 37.45784],
            [127.00854, 37.45804],
            [127.00864, 37.45816],
            [127.00858, 37.4584],
            [127.00841, 37.4585],
            [127.00824, 37.45909],
            [127.00804, 37.45916],
            [127.00794, 37.4595],
            [127.00732, 37.46011],
            [127.00705, 37.46023],
            [127.0061, 37.46176],
            [127.0062, 37.46181],
            [127.00577, 37.46255],
            [127.00533, 37.4628],
            [127.00512, 37.46303],
            [127.00484, 37.46347],
            [127.00475, 37.46384],
            [127.00451, 37.46403],
            [127.00488, 37.46481],
            [127.00474, 37.46511],
            [127.00492, 37.46573],
            [127.00451, 37.4663],
            [127.00456, 37.4667],
            [127.00413, 37.46729],
            [127.00368, 37.46772],
            [127.00273, 37.46712],
            [127.00196, 37.46709],
            [127.00148, 37.46738],
            [127.0005, 37.46712],
            [126.99874, 37.46726],
            [126.99776, 37.46705],
            [126.99743, 37.46724],
            [126.99717, 37.46721],
            [126.99677, 37.46708],
            [126.99671, 37.46693],
            [126.99623, 37.46661],
            [126.99652, 37.46592],
            [126.99645, 37.46569],
            [126.99664, 37.46543],
            [126.99662, 37.46478],
            [126.99726, 37.46422],
            [126.99737, 37.46369],
            [126.99698, 37.46316],
            [126.99677, 37.46187],
            [126.99429, 37.46142],
            [126.99335, 37.4615],
            [126.99289, 37.46095],
            [126.99265, 37.46034],
            [126.99203, 37.46055],
            [126.9917, 37.46052],
            [126.99133, 37.46014],
            [126.98999, 37.45946],
            [126.9895, 37.45852],
            [126.98865, 37.45814],
            [126.98786, 37.45929],
            [126.98755, 37.46014],
            [126.98758, 37.46091],
            [126.98826, 37.46344],
            [126.98834, 37.464],
            [126.98823, 37.46423],
            [126.98793, 37.46594],
            [126.98736, 37.46703],
            [126.98724, 37.46723],
            [126.98711, 37.46723],
            [126.9873, 37.46761],
            [126.98661, 37.46828],
            [126.98553, 37.46905],
            [126.98607, 37.46896],
            [126.98537, 37.46927],
            [126.98468, 37.46987],
            [126.98452, 37.46982],
            [126.98275, 37.47201],
            [126.98321, 37.47157],
            [126.98217, 37.47372],
            [126.98185, 37.47508],
            [126.98168, 37.47706],
            [126.98232, 37.48854],
            [126.98264, 37.49009],
            [126.98268, 37.49188],
            [126.9828, 37.49265],
            [126.98293, 37.49699],
            [126.98539, 37.49986],
            [126.98428, 37.50071],
            [126.98343, 37.50125],
            [126.98203, 37.50204],
            [126.98054, 37.5027],
            [126.9804, 37.50285],
            [126.98022, 37.50321],
            [126.98015, 37.5037],
            [126.97985, 37.50417],
            [126.97985, 37.50437],
            [126.98007, 37.50436],
            [126.9804, 37.50458],
            [126.98041, 37.50654],
            [126.98553, 37.50654],
            [126.99067, 37.5131],
            [127.00079, 37.5195],
            [127.00644, 37.52344],
            [127.01371, 37.5309],
            [127.01266, 37.52974],
            [127.01365, 37.5286],
            [127.01638, 37.52601],
            [127.01523, 37.52485],
            [127.01694, 37.52302],
            [127.01782, 37.52181],
            [127.02052, 37.5128],
            [127.02133, 37.5111]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        rgnCode: '11680',
        rgnKo: ['서울특별시', '강남구'],
        colCode: '11000',
        rgnSize: '2',
        predVal: [
          0.5924, 0.6024, 0.5914, 0.801, 0.837, 0.846, 0.8895, 0.8895, 0.8895,
          0.8888, 0.8891, 0.8974, 0.8999, 0.9088, 0.8997, 0.8676, 0.9158,
          0.9277, 0.9323, 0.9251, 0.9487, 0.9503, 0.9228, 0.8891, 0.8526,
          0.8328, 0.8493, 0.8586, 0.8494, 0.9051, 0.9049
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [127.06887, 37.51031],
            [127.06982, 37.50274],
            [127.07699, 37.50207],
            [127.08415, 37.49967],
            [127.09479, 37.49668],
            [127.09714, 37.49583],
            [127.0978, 37.49548],
            [127.0974, 37.49519],
            [127.0988, 37.49489],
            [127.09976, 37.49451],
            [127.09983, 37.4944],
            [127.10036, 37.49422],
            [127.10046, 37.49409],
            [127.10331, 37.49282],
            [127.10701, 37.49029],
            [127.11166, 37.48379],
            [127.11297, 37.48172],
            [127.11824, 37.4747],
            [127.11972, 37.47258],
            [127.1197, 37.47276],
            [127.12418, 37.46655],
            [127.12296, 37.46583],
            [127.1228, 37.46563],
            [127.12218, 37.46522],
            [127.12186, 37.46513],
            [127.12144, 37.46437],
            [127.12075, 37.46389],
            [127.12011, 37.46349],
            [127.1185, 37.46278],
            [127.11748, 37.4622],
            [127.11699, 37.46147],
            [127.1169, 37.45863],
            [127.11588, 37.4586],
            [127.11549, 37.45871],
            [127.11513, 37.45908],
            [127.11397, 37.45951],
            [127.11331, 37.46002],
            [127.11323, 37.46035],
            [127.11278, 37.46052],
            [127.1133, 37.46084],
            [127.11333, 37.46109],
            [127.11297, 37.46113],
            [127.11223, 37.46151],
            [127.11182, 37.46164],
            [127.10989, 37.46194],
            [127.10946, 37.46187],
            [127.10815, 37.46227],
            [127.10732, 37.46218],
            [127.10689, 37.46237],
            [127.10646, 37.46243],
            [127.10593, 37.46235],
            [127.10562, 37.46219],
            [127.10435, 37.46216],
            [127.10478, 37.46116],
            [127.10447, 37.46103],
            [127.10405, 37.46059],
            [127.10394, 37.46006],
            [127.10344, 37.45976],
            [127.10269, 37.45973],
            [127.10264, 37.45986],
            [127.10182, 37.45918],
            [127.10138, 37.45897],
            [127.10117, 37.45855],
            [127.10125, 37.45826],
            [127.1007, 37.45774],
            [127.09968, 37.45735],
            [127.09904, 37.45628],
            [127.09882, 37.45619],
            [127.09855, 37.45623],
            [127.09815, 37.45642],
            [127.09621, 37.45642],
            [127.09567, 37.45657],
            [127.09525, 37.45646],
            [127.09546, 37.45746],
            [127.09535, 37.45791],
            [127.09493, 37.45846],
            [127.0952, 37.45944],
            [127.0951, 37.45977],
            [127.09531, 37.46005],
            [127.09566, 37.46026],
            [127.09578, 37.46061],
            [127.0957, 37.46099],
            [127.09467, 37.46092],
            [127.09414, 37.46113],
            [127.09334, 37.46125],
            [127.0921, 37.46227],
            [127.09204, 37.46492],
            [127.09195, 37.46523],
            [127.09162, 37.46572],
            [127.09119, 37.46613],
            [127.09043, 37.46715],
            [127.09013, 37.46714],
            [127.08971, 37.46742],
            [127.08904, 37.46824],
            [127.08898, 37.46815],
            [127.08842, 37.4682],
            [127.08813, 37.46849],
            [127.08779, 37.46925],
            [127.08758, 37.46941],
            [127.08756, 37.46984],
            [127.08716, 37.46998],
            [127.08676, 37.4707],
            [127.0866, 37.47085],
            [127.08589, 37.47121],
            [127.08502, 37.4713],
            [127.08417, 37.47293],
            [127.08451, 37.47403],
            [127.08446, 37.47514],
            [127.08494, 37.47566],
            [127.08448, 37.4758],
            [127.08385, 37.47559],
            [127.08324, 37.4757],
            [127.08275, 37.47547],
            [127.08176, 37.47544],
            [127.07899, 37.47492],
            [127.07853, 37.4751],
            [127.07796, 37.47485],
            [127.07692, 37.47524],
            [127.07651, 37.47449],
            [127.07531, 37.47343],
            [127.07423, 37.47292],
            [127.07384, 37.4729],
            [127.07317, 37.47223],
            [127.07269, 37.47192],
            [127.07193, 37.47184],
            [127.07131, 37.47148],
            [127.07073, 37.47131],
            [127.07042, 37.47099],
            [127.06991, 37.47086],
            [127.06956, 37.47099],
            [127.06855, 37.47096],
            [127.06784, 37.47038],
            [127.06644, 37.4701],
            [127.06622, 37.46986],
            [127.06588, 37.46975],
            [127.0656, 37.4695],
            [127.06507, 37.46929],
            [127.06413, 37.46918],
            [127.0638, 37.46897],
            [127.06322, 37.46901],
            [127.0626, 37.4693],
            [127.06236, 37.46929],
            [127.06213, 37.46905],
            [127.06161, 37.46892],
            [127.06146, 37.469],
            [127.06104, 37.46899],
            [127.06092, 37.46892],
            [127.06033, 37.46915],
            [127.06003, 37.46911],
            [127.05908, 37.46924],
            [127.05867, 37.46895],
            [127.05785, 37.46895],
            [127.0572, 37.46907],
            [127.05649, 37.46853],
            [127.05602, 37.4685],
            [127.05539, 37.46887],
            [127.05502, 37.46891],
            [127.05488, 37.46869],
            [127.05419, 37.46817],
            [127.05358, 37.46813],
            [127.05321, 37.4682],
            [127.05234, 37.46796],
            [127.05079, 37.46729],
            [127.05077, 37.4674],
            [127.05095, 37.46758],
            [127.05112, 37.46854],
            [127.05105, 37.46937],
            [127.05042, 37.46977],
            [127.05026, 37.46973],
            [127.04886, 37.46989],
            [127.04867, 37.47006],
            [127.05118, 37.47179],
            [127.0509, 37.47181],
            [127.04607, 37.47647],
            [127.04597, 37.47668],
            [127.04519, 37.47736],
            [127.044, 37.47994],
            [127.04389, 37.48056],
            [127.04314, 37.48187],
            [127.04316, 37.48197],
            [127.04295, 37.48227],
            [127.04161, 37.48524],
            [127.04175, 37.48544],
            [127.03409, 37.48437],
            [127.02052, 37.5128],
            [127.01782, 37.52181],
            [127.01694, 37.52302],
            [127.01523, 37.52485],
            [127.01634, 37.526],
            [127.01365, 37.5286],
            [127.01266, 37.52974],
            [127.01369, 37.53088],
            [127.01774, 37.53425],
            [127.02117, 37.53582],
            [127.04039, 37.53582],
            [127.04039, 37.53575],
            [127.046, 37.53427],
            [127.04947, 37.53227],
            [127.05513, 37.52868],
            [127.06032, 37.52703],
            [127.06543, 37.52506],
            [127.06746, 37.5246],
            [127.06767, 37.51987],
            [127.0679, 37.51858],
            [127.06887, 37.51031]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        rgnCode: '11710',
        rgnKo: ['서울특별시', '송파구'],
        colCode: '11000',
        rgnSize: '2',
        predVal: [
          0.5924, 0.6024, 0.5765, 0.6474, 0.8111, 0.7375, 0.8091, 0.8509,
          0.6862, 0.7714, 0.7688, 0.5887, 0.3028, 0.5235, 0.5783, 0.3415,
          0.3928, 0.6699, 0.5813, 0.6758, 0.7106, 0.8447, 0.7411, 0.8393,
          0.6718, 0.8187, 0.7483, 0.7705, 0.7533, 0.785, 0.5678
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [127.11552, 37.54225],
            [127.11653, 37.54196],
            [127.11733, 37.54149],
            [127.11888, 37.54107],
            [127.11893, 37.54113],
            [127.11891, 37.54097],
            [127.12026, 37.54033],
            [127.12185, 37.53929],
            [127.12344, 37.53865],
            [127.12061, 37.53063],
            [127.12014, 37.52966],
            [127.11909, 37.52796],
            [127.13817, 37.51953],
            [127.13826, 37.51937],
            [127.13848, 37.51931],
            [127.13857, 37.51936],
            [127.14383, 37.51704],
            [127.14509, 37.51689],
            [127.14532, 37.5166],
            [127.14543, 37.51642],
            [127.14543, 37.51607],
            [127.14502, 37.51585],
            [127.14485, 37.51567],
            [127.14462, 37.5156],
            [127.14262, 37.51557],
            [127.14183, 37.51564],
            [127.14118, 37.51554],
            [127.14062, 37.51558],
            [127.14123, 37.51503],
            [127.14202, 37.51471],
            [127.14295, 37.51409],
            [127.14342, 37.51388],
            [127.14337, 37.51345],
            [127.14354, 37.51266],
            [127.14288, 37.51273],
            [127.14144, 37.5124],
            [127.14128, 37.51215],
            [127.14112, 37.51115],
            [127.14122, 37.51105],
            [127.14122, 37.51078],
            [127.14095, 37.51039],
            [127.14035, 37.51022],
            [127.14018, 37.50923],
            [127.13995, 37.50852],
            [127.14032, 37.50829],
            [127.14039, 37.50809],
            [127.14035, 37.50775],
            [127.14088, 37.50744],
            [127.14152, 37.50671],
            [127.14081, 37.50598],
            [127.14115, 37.50546],
            [127.14212, 37.50508],
            [127.14223, 37.50488],
            [127.14342, 37.50441],
            [127.14412, 37.5045],
            [127.1444, 37.50432],
            [127.14471, 37.50425],
            [127.14499, 37.50412],
            [127.14552, 37.50332],
            [127.14611, 37.50323],
            [127.14634, 37.50335],
            [127.14658, 37.50332],
            [127.14651, 37.50324],
            [127.14724, 37.50336],
            [127.1477, 37.50324],
            [127.14803, 37.50351],
            [127.1485, 37.50364],
            [127.15026, 37.50468],
            [127.1517, 37.50335],
            [127.15174, 37.50339],
            [127.15213, 37.50299],
            [127.15256, 37.50312],
            [127.15283, 37.50307],
            [127.15325, 37.5027],
            [127.15399, 37.50267],
            [127.15655, 37.50189],
            [127.1577, 37.50314],
            [127.15887, 37.50239],
            [127.15939, 37.50138],
            [127.16046, 37.50113],
            [127.16136, 37.50024],
            [127.16143, 37.50002],
            [127.16136, 37.49954],
            [127.16107, 37.49925],
            [127.16063, 37.49855],
            [127.16057, 37.49756],
            [127.15978, 37.49682],
            [127.15974, 37.49655],
            [127.15986, 37.49615],
            [127.15987, 37.49569],
            [127.15998, 37.49543],
            [127.16003, 37.49439],
            [127.15976, 37.49364],
            [127.15952, 37.49327],
            [127.15905, 37.49294],
            [127.15867, 37.49245],
            [127.15836, 37.49159],
            [127.15826, 37.49064],
            [127.15799, 37.49037],
            [127.15728, 37.48995],
            [127.15838, 37.48989],
            [127.15852, 37.48967],
            [127.15043, 37.48525],
            [127.14992, 37.48491],
            [127.14947, 37.48443],
            [127.14909, 37.48388],
            [127.14868, 37.48404],
            [127.14777, 37.48262],
            [127.14753, 37.48201],
            [127.14715, 37.47722],
            [127.14437, 37.47733],
            [127.14427, 37.4764],
            [127.14362, 37.47517],
            [127.14353, 37.47393],
            [127.1364, 37.47417],
            [127.13529, 37.47426],
            [127.13286, 37.47463],
            [127.13274, 37.47456],
            [127.13269, 37.47429],
            [127.13259, 37.4728],
            [127.13262, 37.4724],
            [127.13283, 37.4717],
            [127.13282, 37.4712],
            [127.13266, 37.47121],
            [127.13249, 37.47061],
            [127.13198, 37.4698],
            [127.1328, 37.46839],
            [127.13196, 37.46818],
            [127.13083, 37.46775],
            [127.1302, 37.4678],
            [127.13014, 37.46775],
            [127.12889, 37.46808],
            [127.12703, 37.46842],
            [127.12637, 37.4688],
            [127.12603, 37.46911],
            [127.12521, 37.46963],
            [127.12489, 37.46961],
            [127.12503, 37.46902],
            [127.12518, 37.46877],
            [127.12521, 37.46784],
            [127.12508, 37.46769],
            [127.12505, 37.46728],
            [127.12449, 37.46664],
            [127.12421, 37.46652],
            [127.1197, 37.47276],
            [127.11972, 37.47261],
            [127.11824, 37.4747],
            [127.11297, 37.48172],
            [127.11166, 37.48379],
            [127.11173, 37.48381],
            [127.10701, 37.49029],
            [127.10351, 37.49271],
            [127.10046, 37.49409],
            [127.10036, 37.49422],
            [127.09983, 37.4944],
            [127.09976, 37.49451],
            [127.0988, 37.49489],
            [127.09744, 37.49518],
            [127.0978, 37.49548],
            [127.09714, 37.49583],
            [127.09479, 37.49668],
            [127.08415, 37.49967],
            [127.07699, 37.50207],
            [127.06982, 37.50274],
            [127.0679, 37.51858],
            [127.06767, 37.51987],
            [127.06748, 37.52458],
            [127.06546, 37.52509],
            [127.06646, 37.52509],
            [127.06752, 37.52463],
            [127.06866, 37.52387],
            [127.07206, 37.52342],
            [127.07432, 37.52287],
            [127.07695, 37.52251],
            [127.07997, 37.52295],
            [127.08113, 37.52341],
            [127.08563, 37.52476],
            [127.09016, 37.52701],
            [127.10828, 37.54141],
            [127.10924, 37.54317],
            [127.1131, 37.54317],
            [127.11305, 37.54298],
            [127.11552, 37.54225]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        rgnCode: '11740',
        rgnKo: ['서울특별시', '강동구'],
        colCode: '11000',
        rgnSize: '2',
        predVal: [
          0.5924, 0.6024, 0.5765, 0.6474, 0.8111, 0.7375, 0.8091, 0.8509,
          0.6862, 0.7714, 0.7688, 0.5887, 0.3028, 0.5235, 0.5783, 0.3415,
          0.3928, 0.6699, 0.5813, 0.6758, 0.7106, 0.8447, 0.7411, 0.8393,
          0.6718, 0.8187, 0.7483, 0.7705, 0.7533, 0.785, 0.5678
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [127.17932, 37.56831],
            [127.1795, 37.56773],
            [127.17943, 37.56717],
            [127.17959, 37.5655],
            [127.17979, 37.56506],
            [127.18019, 37.56459],
            [127.18119, 37.56302],
            [127.182, 37.56099],
            [127.18204, 37.5604],
            [127.18199, 37.55965],
            [127.18156, 37.55756],
            [127.18168, 37.55683],
            [127.1817, 37.556],
            [127.18153, 37.55548],
            [127.1815, 37.55433],
            [127.18134, 37.55301],
            [127.18157, 37.55265],
            [127.18291, 37.55182],
            [127.18297, 37.55162],
            [127.18312, 37.55108],
            [127.1831, 37.55066],
            [127.18302, 37.55066],
            [127.18293, 37.55024],
            [127.1829, 37.5493],
            [127.18308, 37.54863],
            [127.1827, 37.54789],
            [127.18272, 37.5467],
            [127.18283, 37.54634],
            [127.18379, 37.54557],
            [127.18357, 37.54518],
            [127.18285, 37.54558],
            [127.18274, 37.54551],
            [127.18219, 37.54574],
            [127.18169, 37.54644],
            [127.18119, 37.54662],
            [127.17984, 37.54661],
            [127.17934, 37.54667],
            [127.17679, 37.54575],
            [127.17679, 37.54547],
            [127.17664, 37.54534],
            [127.17598, 37.54564],
            [127.17577, 37.5454],
            [127.17572, 37.54521],
            [127.17431, 37.5453],
            [127.17415, 37.54567],
            [127.17361, 37.54554],
            [127.17211, 37.54553],
            [127.17157, 37.54539],
            [127.17107, 37.54511],
            [127.16976, 37.5448],
            [127.16882, 37.54514],
            [127.16798, 37.54505],
            [127.16698, 37.5452],
            [127.16675, 37.54482],
            [127.16689, 37.54427],
            [127.16531, 37.54441],
            [127.1652, 37.54449],
            [127.16517, 37.54464],
            [127.16349, 37.54502],
            [127.16317, 37.54499],
            [127.16228, 37.54385],
            [127.16171, 37.54342],
            [127.16116, 37.5428],
            [127.16041, 37.5417],
            [127.15996, 37.54133],
            [127.15989, 37.54117],
            [127.1591, 37.54073],
            [127.15896, 37.54048],
            [127.15825, 37.53975],
            [127.15765, 37.53927],
            [127.1574, 37.53895],
            [127.15718, 37.53845],
            [127.15701, 37.53839],
            [127.15694, 37.53786],
            [127.15655, 37.53745],
            [127.15603, 37.53734],
            [127.15594, 37.53706],
            [127.15529, 37.53623],
            [127.15473, 37.5351],
            [127.15452, 37.53482],
            [127.15402, 37.53452],
            [127.1538, 37.53395],
            [127.15361, 37.53368],
            [127.15385, 37.53268],
            [127.15393, 37.53192],
            [127.15377, 37.5316],
            [127.15338, 37.53133],
            [127.15333, 37.53103],
            [127.15343, 37.53091],
            [127.15354, 37.53044],
            [127.15313, 37.52995],
            [127.15329, 37.52947],
            [127.15316, 37.52911],
            [127.15278, 37.5288],
            [127.15269, 37.52854],
            [127.15164, 37.5277],
            [127.15078, 37.52686],
            [127.1504, 37.52637],
            [127.14994, 37.52623],
            [127.14947, 37.52577],
            [127.14959, 37.52533],
            [127.14949, 37.52505],
            [127.14926, 37.52485],
            [127.14902, 37.52415],
            [127.1486, 37.52353],
            [127.1486, 37.52319],
            [127.14823, 37.52294],
            [127.14789, 37.52248],
            [127.14782, 37.52214],
            [127.14577, 37.52194],
            [127.14479, 37.51962],
            [127.1448, 37.51877],
            [127.1449, 37.51839],
            [127.14486, 37.51773],
            [127.14508, 37.51715],
            [127.14509, 37.51689],
            [127.14371, 37.51709],
            [127.13857, 37.51936],
            [127.13834, 37.51934],
            [127.13814, 37.51952],
            [127.13496, 37.52095],
            [127.11909, 37.52796],
            [127.12014, 37.52966],
            [127.12061, 37.53063],
            [127.12344, 37.53865],
            [127.12188, 37.53927],
            [127.12026, 37.54033],
            [127.11885, 37.54099],
            [127.11888, 37.54107],
            [127.11733, 37.54149],
            [127.11653, 37.54196],
            [127.11305, 37.54298],
            [127.11312, 37.54317],
            [127.10924, 37.54317],
            [127.11024, 37.54501],
            [127.11147, 37.54693],
            [127.11155, 37.55038],
            [127.11156, 37.5505],
            [127.11431, 37.55437],
            [127.11532, 37.55687],
            [127.11577, 37.55688],
            [127.11602, 37.55752],
            [127.11769, 37.55924],
            [127.11963, 37.56073],
            [127.1205, 37.561],
            [127.12344, 37.56333],
            [127.12902, 37.56594],
            [127.13569, 37.56788],
            [127.13806, 37.5682],
            [127.14936, 37.56819],
            [127.15277, 37.57019],
            [127.1536, 37.5704],
            [127.15601, 37.57207],
            [127.15744, 37.57274],
            [127.16127, 37.57538],
            [127.16297, 37.57646],
            [127.16716, 37.57869],
            [127.16866, 37.5787],
            [127.16866, 37.57899],
            [127.17026, 37.57901],
            [127.17184, 37.57926],
            [127.17387, 37.57973],
            [127.17507, 37.58026],
            [127.17652, 37.58103],
            [127.17713, 37.58119],
            [127.17708, 37.58048],
            [127.17714, 37.57986],
            [127.17699, 37.57942],
            [127.17653, 37.57892],
            [127.17542, 37.5783],
            [127.17533, 37.57727],
            [127.1755, 37.57702],
            [127.17577, 37.57566],
            [127.17569, 37.5749],
            [127.17614, 37.5741],
            [127.1765, 37.57383],
            [127.17697, 37.57329],
            [127.1774, 37.57254],
            [127.17779, 37.57214],
            [127.17788, 37.57187],
            [127.17779, 37.57179],
            [127.17784, 37.57146],
            [127.17832, 37.57071],
            [127.17865, 37.56981],
            [127.17913, 37.56912],
            [127.17932, 37.56831]
          ]
        ]
      }
    }
  ]
};
