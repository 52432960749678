import React, { useContext } from 'react';
import NavbarDropdown from './NavbarDropdown';
import {
  dashboardRoutes,
  appRoutes,
  pagesRoutes,
  modulesRoutes,
  documentationRoutes,
  manageRoutes,
  evnetRoutes,
  statisticsRoutes
} from 'routes/siteMaps';
import { Dropdown, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { flatRoutes } from 'helpers/utils';
import NavbarDropdownApp from './NavbarDropdownApp';
import NavbarDropdownPages from './NavbarDropdownPages';
import NavbarDropdownModules from './NavbarDropdownModules';
import AppContext from 'context/Context';

import { navbarBreakPoint, topNavbarBreakpoint } from 'config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from '../../common/SoftBadge';
import Flex from '../../common/Flex';

const NavbarTopDropDownMenus = () => {
  const {
    config: { navbarCollapsed, showBurgerMenu },
    setConfig
  } = useContext(AppContext);

  const handleDropdownItemClick = () => {
    if (navbarCollapsed) {
      setConfig('navbarCollapsed', !navbarCollapsed);
    }
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };
  return (
    <>
      {/*<NavbarDropdown title="dashboard">*/}
      {/*  {dashboardRoutes.children[0].children.map(route => (*/}
      {/*    <Dropdown.Item*/}
      {/*      key={route.name}*/}
      {/*      as={Link}*/}
      {/*      className={route.active ? 'link-600' : 'text-500'}*/}
      {/*      to={route.to}*/}
      {/*      onClick={handleDropdownItemClick}*/}
      {/*    >*/}
      {/*      {route.name}*/}
      {/*    </Dropdown.Item>*/}
      {/*  ))}*/}
      {/*</NavbarDropdown>*/}

      {/*<NavbarDropdown title="app">*/}
      {/*  <NavbarDropdownApp items={appRoutes.children} />*/}
      {/*</NavbarDropdown>*/}

      {/*<NavbarDropdown title="pages">*/}
      {/*  <NavbarDropdownPages items={pagesRoutes.children} />*/}
      {/*</NavbarDropdown>*/}
      {/*<NavbarDropdown title="modules">*/}
      {/*  <NavbarDropdownModules items={modulesRoutes.children} />*/}
      {/*</NavbarDropdown>*/}

      {/*<NavbarDropdown title="documentation">*/}
      {/*  {flatRoutes(documentationRoutes.children).map(route => (*/}
      {/*    <Dropdown.Item*/}
      {/*      key={route.name}*/}
      {/*      as={Link}*/}
      {/*      className={route.active ? 'link-600' : 'text-500'}*/}
      {/*      to={route.to}*/}
      {/*      onClick={handleDropdownItemClick}*/}
      {/*    >*/}
      {/*      {route.name}*/}
      {/*    </Dropdown.Item>*/}
      {/*  ))}*/}
      {/*</NavbarDropdown>*/}
      <Nav
        navbar
        className={`align-items-start d-${topNavbarBreakpoint}-block`}
        as="ul"
      >
        <Nav.Item as="li">
          <Nav.Link as={Link} to="/">
            <Flex alignItems="center">
              <span className="nav-link-icon">
                <FontAwesomeIcon icon={'chart-pie'} />
              </span>
              <span className="nav-link-text ps-1">대시보드</span>
            </Flex>
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Nav
        navbar
        className={`align-items-start d-${topNavbarBreakpoint}-block`}
        as="ul"
      >
        <Nav.Item as="li">
          <Nav.Link as={Link} to="/maps">
            <Flex alignItems="center">
              <span className="nav-link-icon">
                <FontAwesomeIcon icon={'map'} />
              </span>
              <span className="nav-link-text ps-1">맵모드</span>
            </Flex>
          </Nav.Link>
        </Nav.Item>
      </Nav>
      {/* <Nav
        navbar
        className={`align-items-start d-${topNavbarBreakpoint}-block`}
        as="ul"
      >
        <Nav.Item as="li">
          <Nav.Link as={Link} to="/sample-maps">
            <Flex alignItems="center">
              <span className="nav-link-icon">
                <FontAwesomeIcon icon={'map'} />
              </span>
              <span className="nav-link-text ps-1">샘플 맵모드</span>
            </Flex>
          </Nav.Link>
        </Nav.Item>
      </Nav> */}
      {/*<Nav*/}
      {/*  navbar*/}
      {/*  className={`align-items-start d-${topNavbarBreakpoint}-block`}*/}
      {/*  as="ul"*/}
      {/*>*/}
      {/*  <Nav.Item as="li">*/}
      {/*    <Nav.Link as={Link} to="/maps2">*/}
      {/*      <Flex alignItems="center">*/}
      {/*        <span className="nav-link-icon">*/}
      {/*          <FontAwesomeIcon icon={'map'} />*/}
      {/*        </span>*/}
      {/*        <span className="nav-link-text ps-1">맵모드(Mapbox)</span>*/}
      {/*      </Flex>*/}
      {/*    </Nav.Link>*/}
      {/*  </Nav.Item>*/}
      {/*</Nav>*/}
      <NavbarDropdown title="관리대상">
        {flatRoutes(manageRoutes.children).map(route => (
          <Dropdown.Item
            key={route.name}
            as={Link}
            className={route.active ? 'link-600' : 'text-500'}
            to={route.to}
            onClick={handleDropdownItemClick}
          >
            {route.name}
          </Dropdown.Item>
        ))}
      </NavbarDropdown>
      <NavbarDropdown title="이벤트">
        {flatRoutes(evnetRoutes.children).map(route => (
          <Dropdown.Item
            key={route.name}
            as={Link}
            className={route.active ? 'link-600' : 'text-500'}
            to={route.to}
            onClick={handleDropdownItemClick}
          >
            {route.name}
          </Dropdown.Item>
        ))}
      </NavbarDropdown>
      <NavbarDropdown title="통계">
        {flatRoutes(statisticsRoutes.children).map(route => (
          <Dropdown.Item
            key={route.name}
            as={Link}
            className={route.active ? 'link-600' : 'text-500'}
            to={route.to}
            onClick={handleDropdownItemClick}
          >
            {route.name}
          </Dropdown.Item>
        ))}
      </NavbarDropdown>
    </>
  );
};

export default NavbarTopDropDownMenus;
