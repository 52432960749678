import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppContext, { MapsContext } from 'context/Context';
import { chainAxios } from 'helpers/chainAxios';
import { Col, Row } from 'react-bootstrap';
import ProjectDetailTopArea from '../../project/detail/component/TopArea';
import ProjectBaseInfo from '../../project/detail/component/BaseInfo';
import ProjectEventList from '../../project/detail/component/EventList';
import ProjectFacilitiesList from '../../project/detail/component/FacilitiesList';
import ProjectSensorList from '../../project/detail/component/SensorList';
import ProjectPhotoArea from '../../project/detail/component/PhotoArea';
import RoadFacilitiesDetailTopArea from './component/TopArea';
import RoadFacilitiesDetailBaseInfo from './component/BaseInfo';
import RoadFacilitiesDetailEventList from './component/EventList';
import RoadFacilitiesDetailSensorList from './component/SensorList';
import RoadFacilitiesDetailPhotoArea from './component/PhotoArea';
const RoadFacilitiesDetail = () => {
  const {
    config: { isNavbarVerticalCollapsed },
    setConfig
  } = useContext(AppContext);
  const { setSelectedProject, setSelectedRegion, setSendRegion } =
    useContext(MapsContext);

  const coursesNavbarVerticalCollapsed = useRef(isNavbarVerticalCollapsed);

  const navigate = useNavigate();
  const params = useParams();

  const [facility, setFacility] = useState({});

  useEffect(() => {
    setConfig('isNavbarVerticalCollapsed', false);
    setSelectedProject({});
    setSelectedRegion({});
    setSendRegion({});

    return () => {
      setConfig(
        'isNavbarVerticalCollapsed',
        coursesNavbarVerticalCollapsed.current
      );
    };
  }, []);

  useEffect(() => {
    getFacility();
  }, [params]);

  const getFacility = () => {
    let url = '/api/facility/' + params.facilityId;
    chainAxios
      .get(url)
      .then(response => {
        // console.log('ProjectDetail getProject() : ', response);
        if (response.data.resultCode === 200) {
          // console.log(response.data.data.facility);
          setFacility(response.data.data.facility);
        }
      })
      .catch(error => {
        console.log('FacilityDetail getFacility() : ', error);
      });
  };

  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <RoadFacilitiesDetailTopArea />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col>
          <RoadFacilitiesDetailBaseInfo facility={facility} />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col>
          <RoadFacilitiesDetailEventList facility={facility} />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={12} xl={8} style={{ minHeight: '100%' }}>
          <RoadFacilitiesDetailSensorList facility={facility} />
        </Col>
        <Col md={12} xl={4}>
          <RoadFacilitiesDetailPhotoArea facility={facility} />
        </Col>
      </Row>
    </>
  );
};

export default RoadFacilitiesDetail;
