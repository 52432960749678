import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Nav, Tabs, Tab } from 'react-bootstrap';
import ChatThread from './ChatThread';
import SimpleBarReact from 'simplebar-react';
import ChatContactsSearch from './ChatContactSearch';
import classNames from 'classnames';
import { ChatContext } from 'context/Context';

import { chainAxios } from 'helpers/chainAxios';

const ChatSidebar = ({ hideSidebar, handleTabChange, threads }) => {
  // const { threads } = useContext(ChatContext);

  const onTabChange = value => {
    // console.log(value);
    handleTabChange(value); // 콜백 함수 호출하여 데이터 전달
  };

  return (
    <div className={classNames('chat-sidebar', { 'start-0': hideSidebar })}>
      <Tabs
        defaultActiveKey="0"
        id="fill-tab-example"
        className={classNames('mb-3', 'nav')}
        style={{ flexDirection: 'Row' }}
        onSelect={onTabChange}
        fill
      >
        <Tab eventKey="0" title={<h6>점검요함</h6>}></Tab>
        <Tab eventKey="1" title={<h6>조치완료</h6>}></Tab>
        <Tab eventKey="2" title={<h6>재점검필</h6>}></Tab>
        <Tab eventKey="3" title={<h6>승인</h6>}></Tab>
      </Tabs>
      <div className="contacts-list">
        <SimpleBarReact style={{ height: '100%', minWidth: '65px' }}>
          <Nav className="border-0">
            {threads.map((thread, index) => (
              <ChatThread thread={thread} index={thread.id} key={thread.id} />
            ))}
          </Nav>
        </SimpleBarReact>
      </div>
      {/* <ChatContactsSearch /> */}
    </div>
  );
};

ChatSidebar.propTypes = {
  hideSidebar: PropTypes.bool
};

export default ChatSidebar;
