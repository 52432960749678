import React, { useContext, useEffect, useState, useRef } from 'react';
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Image,
  Dropdown
} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { ChatContext, LoginUserContext } from 'context/Context';
import { chainAxios } from 'helpers/chainAxios';
import SimpleBarReact from 'simplebar-react';
import FileItem from './FileItem';
import { toast } from 'react-toastify';
import moment from 'moment/moment';
import ComposeAttachment from '../../../app/email/compose/ComposeAttachment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fromLonLat } from 'ol/proj';
import { useMutation } from 'react-query';
import { isEmpty } from 'helpers/utils';
import SoftBadge from '../../SoftBadge';

const ChatEventAction = ({
  chatEventActionShow,
  onHideEvent,
  changeStatusChatroom
}) => {
  const { currentChatRoom } = useContext(ChatContext);
  const { loginUser } = useContext(LoginUserContext);

  const [inspectorMembers, setInspectorMembers] = useState([]);
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [checkItems, setCheckItems] = useState(new Set());

  const [selectedInspectorMember, setSelectedInspectorMember] = useState({});
  const [actionDetails, setActionDetails] = useState('');
  // 저장된 파일정보
  const [attachments, setAttachments] = useState([]);

  const inputForm = useRef();

  const [checkTypeCode, setCheckTypeCode] = useState('');
  const [actionTypeCode, setActionTypeCode] = useState('');

  const [checkMainCategory, setCheckMainCategory] = useState([]);
  const [selectedCheckMainCategory, setSelectedCheckMainCategory] = useState(
    {}
  );
  const [checkSubCategory, setCheckSubCategory] = useState([]);
  const [selectedCheckSubCategory, setSelectedCheckSubCategory] = useState({});

  const [actionMainCategory, setActionMainCategory] = useState([]);
  const [selectedActionMainCategory, setSelectedActionMainCategory] = useState(
    {}
  );
  const [actionSubCategory, setActionSubCategory] = useState([]);
  const [selectedActionSubCategory, setSelectedActionSubCategory] = useState(
    {}
  );

  const [isValidInspector, setIsValidInspector] = useState(false);
  const [isValidCheckMain, setIsValidCheckMain] = useState(false);
  const [isValidCheckSub, setIsValidCheckSub] = useState(false);
  const [isValidActionMain, setIsValidActionMain] = useState(false);
  const [isValidActionSub, setIsValidActionSub] = useState(false);
  const [isValidActionDetail, setIsValidActionDetail] = useState(false);

  useEffect(() => {
    // console.log('ChatEventAction currentChatRoom : ', currentChatRoom);
    getInspectorMembers();
    setCheckAndActionTypeCode();
  }, []);

  useEffect(() => {
    getChatroomFiles();
  }, [currentChatRoom]);

  useEffect(() => {
    if (!isEmpty(checkTypeCode)) {
      getCheckMainCategory();
    }
  }, [checkTypeCode]);

  useEffect(() => {
    if (!isEmpty(selectedCheckMainCategory)) {
      setSelectedCheckSubCategory({});
      getCheckSubCategory();
    }
  }, [selectedCheckMainCategory]);

  useEffect(() => {
    if (!isEmpty(actionTypeCode)) {
      getActionMainCategory();
    }
  }, [actionTypeCode]);

  useEffect(() => {
    if (!isEmpty(selectedActionMainCategory)) {
      setSelectedActionSubCategory({});
      getActionSubCategory();
    }
  }, [selectedActionMainCategory]);

  const setCheckAndActionTypeCode = () => {
    if (!isEmpty(currentChatRoom.event.facility)) {
      setCheckTypeCode('c02');
      setActionTypeCode('a02');
    }

    if (!isEmpty(currentChatRoom.event.gateway)) {
      setCheckTypeCode('c03');
      setActionTypeCode('a03');
    }

    if (!isEmpty(currentChatRoom.event.sensor)) {
      setCheckTypeCode('c04');
      setActionTypeCode('a04');
    }
  };
  const getCheckMainCategory = () => {
    // http://112.175.18.232:7000/api/codes/depth2?depth1=c04&sort=code%24asc
    // let url = '/api/codes/depth2?depth1=' + checkTypeCode + '&sort=code%24asc';
    let url = '/api/codes/depth2?depth1=z00&sort=code%24asc';
    chainAxios
      .get(url)
      .then(response => {
        // console.log('getInspectorMembers : ', response);
        if (response.data.resultCode === 200) {
          // console.log('getCheckMainCategory : ', response.data.data.codeList);
          setCheckMainCategory(response.data.data.codeList);
        }
      })
      .catch(error => {
        console.log('getCheckMainCategory ERROR : ', error);
      });
  };
  const getCheckSubCategory = () => {
    // http://112.175.18.232:7000/api/codes/depth3?depth2=c0400&sort=code%24asc
    let url =
      '/api/codes/depth3?depth2=' +
      selectedCheckMainCategory.code +
      '&sort=code%24asc';
    chainAxios
      .get(url)
      .then(response => {
        // console.log('getInspectorMembers : ', response);
        if (response.data.resultCode === 200) {
          // console.log('getCheckMainCategory : ', response.data.data.codeList);
          setCheckSubCategory(response.data.data.codeList);
        }
      })
      .catch(error => {
        console.log('getCheckMainCategory ERROR : ', error);
      });
  };
  const getActionMainCategory = () => {
    // http://112.175.18.232:7000/api/codes/depth2?depth1=c04&sort=code%24asc
    // let url = '/api/codes/depth2?depth1=' + actionTypeCode + '&sort=code%24asc';
    let url = '/api/codes/depth2?depth1=z01&sort=code%24asc';

    chainAxios
      .get(url)
      .then(response => {
        // console.log('getInspectorMembers : ', response);
        if (response.data.resultCode === 200) {
          // console.log('getActionMainCategory : ', response.data.data.codeList);
          setActionMainCategory(response.data.data.codeList);
        }
      })
      .catch(error => {
        console.log('getCheckMainCategory ERROR : ', error);
      });
  };
  const getActionSubCategory = () => {
    // http://112.175.18.232:7000/api/codes/depth3?depth2=c0400&sort=code%24asc
    let url =
      '/api/codes/depth3?depth2=' +
      selectedActionMainCategory.code +
      '&sort=code%24asc';
    chainAxios
      .get(url)
      .then(response => {
        // console.log('getInspectorMembers : ', response);
        if (response.data.resultCode === 200) {
          // console.log('getActionMainCategory : ', response.data.data.codeList);
          setActionSubCategory(response.data.data.codeList);
        }
      })
      .catch(error => {
        console.log('getCheckMainCategory ERROR : ', error);
      });
  };
  const getInspectorMembers = () => {
    // http://112.175.18.232:7000/api/users?pageNo=0&pageSize=99999&sort=createDate%24desc
    let url = '/api/users?pageNo=0&pageSize=99999&sort=name%24asc';
    chainAxios
      .get(url)
      .then(response => {
        // console.log('getInspectorMembers : ', response);
        if (response.data.resultCode === 200) {
          // console.log(
          //   'getInspectorMembers : ',
          //   response.data.data.userList.dataList
          // );
          setInspectorMembers(response.data.data.userList.dataList);

          response.data.data.userList.dataList.forEach(user => {
            if (user.userId === loginUser.userId) {
              setSelectedInspectorMember(user);
            }
          });
        }
      })
      .catch(error => {
        console.log('getInspectorMembers ERROR : ', error);
      });
  };
  const getChatroomFiles = () => {
    // http://112.175.18.232:7000/api/chatroom/be3a31e4-47c5-44ae-86db-abf3edc9cdca/files?sort=createDate%24asc
    let url =
      '/api/chatroom/' + currentChatRoom.id + '/files?sort=createDate%24asc';
    // console.log(url);
    chainAxios
      .get(url)
      .then(response => {
        // console.log('getChatroomFiles : ', response);
        if (response.data.resultCode === 200) {
          makePathFiles(response.data.data.fileList);
        }
      })
      .catch(error => {
        console.log('getChatroomFiles ERROR : ', error);
      });
  };
  const makePathFiles = attachments => {
    let files = [];
    attachments.forEach((attachement, index) => {
      let url =
        process.env.REACT_APP_API_SERVER + 'api/file/' + attachement.key;
      files.push({ key: attachement.key, url: url });
    });

    setAttachmentFiles(files);
  };
  const makeChatRoomMembers = members => {
    let chatMembers = [];
    // let members = '';
    members.forEach(member => {
      chatMembers.push(member.memberName);
    });
    chatMembers.sort();
    return chatMembers.join(' / ');
  };
  const checkItemHandler = (file, isChecked) => {
    if (isChecked) {
      setCheckItems(prev => [...prev, file]);
    } else {
      setCheckItems(checkItems.filter(item => item.id !== file.key));
    }
  };
  const handleSubmit = event => {
    event.preventDefault();

    const form = event.currentTarget;

    setIsValidInspector(false);
    setIsValidCheckMain(false);
    setIsValidCheckSub(false);
    setIsValidActionMain(false);
    setIsValidActionSub(false);
    setIsValidActionDetail(false);

    if (
      form.checkValidity() === false ||
      isEmpty(selectedInspectorMember) ||
      isEmpty(selectedCheckMainCategory) ||
      (!isEmpty(selectedCheckMainCategory) &&
        isEmpty(selectedCheckSubCategory)) ||
      isEmpty(selectedActionMainCategory) ||
      (!isEmpty(selectedActionMainCategory) &&
        isEmpty(selectedActionSubCategory)) ||
      isEmpty(actionDetails)
    ) {
      if (isEmpty(selectedInspectorMember)) {
        setIsValidInspector(true);
      }
      if (isEmpty(selectedCheckMainCategory)) {
        setIsValidCheckMain(true);
      }
      if (
        !isEmpty(selectedCheckMainCategory) &&
        isEmpty(selectedCheckSubCategory)
      ) {
        setIsValidCheckSub(true);
      }
      if (isEmpty(selectedActionMainCategory)) {
        setIsValidActionMain(true);
      }
      if (
        !isEmpty(selectedActionMainCategory) &&
        isEmpty(selectedActionSubCategory)
      ) {
        setIsValidActionSub(true);
      }
      if (isEmpty(actionDetails)) {
        setIsValidActionDetail(true);
      }
      event.stopPropagation();
    } else {
      // 여기에 유효성 검사 통과 후의 로직을 추가합니다.
      saveEventAction();
    }
  };
  const saveEventAction = () => {
    const formData = new FormData();
    Array.from(attachments).forEach(attachment => {
      formData.append('file', attachment.files);
    });
    checkItems.forEach(file => {
      formData.append('fileIds', file.key);
    });
    formData.append('eventInspectCode', selectedCheckSubCategory.code);
    formData.append('eventActionCode', selectedActionSubCategory.code);
    formData.append('actorId', selectedInspectorMember.userId);
    formData.append('actionDate', moment().format('YYYY-MM-DD HH:mm:ss'));
    formData.append('actionContent', actionDetails);

    // console.log(currentChatRoom.event);
    // 'http://112.175.18.232:7000/api/events/cfb0e26d-c9f3-40fa-890c-c30e858d4a0d/actions?actorId=dkchoi%40chainreum.com&actionDate=2024-02-26%2016%3A00%3A00&actionContent=blabla&fileIds=291f55d6-b64e-4f09-a6ab-57954f067f03&fileIds=69be270d-a6d8-48e0-8f08-873c334ce365'
    let url = '/api/events/' + currentChatRoom.event.id + '/actions';

    chainAxios
      .post(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(response => {
        // console.log('saveEventAction : ', response);
        if (response.data.resultCode === 0) {
          toast.success('조치/점검내용이 등록되었습니다.', {
            theme: 'colored'
          });

          changeStatusChatroom.mutate('1');

          onHideEvent();
        } else {
          toast.error(response.data.message, {
            theme: 'colored'
          });
        }
      })
      .catch(error => {
        console.log('saveEventAction ERROR : ', error);
      });
  };
  const handleActionDetailsChange = e => {
    setActionDetails(e.target.value);
  };
  const handleAddAttachment = files => {
    if (files.length === 0) return;
    if (files.length > 6 || files.length + attachments.length > 6) {
      toast.error(`한 번에 파일은 최대 6개까지만 선택이 가능합니다.`, {
        theme: 'colored'
      });
      return;
    }
    // console.log('handleAddAttachment : ', files);
    const fileArray = [];
    const orginFileArray = [];

    Array.from(files).forEach(file => {
      orginFileArray.push(file);
      const { name, size, type } = file;

      const newFile = {
        id: name + 1 + Date.now(),
        name,
        size,
        type,
        files: file
      };
      fileArray.push(newFile);
    });
    setAttachments([...attachments, ...fileArray]);
  };
  const handleDetachAttachment = id => {
    setAttachments(attachments.filter(attachment => attachment.id !== id));
  };
  const makeTargetName = () => {
    let targetName = '-';

    if (!isEmpty(currentChatRoom.event.facility)) {
      targetName = '시설물 (' + currentChatRoom.event.facility.value + ')';
    }

    if (!isEmpty(currentChatRoom.event.gateway)) {
      targetName = '게이트웨이 (' + currentChatRoom.event.gateway.value + ')';
    }

    if (!isEmpty(currentChatRoom.event.sensor)) {
      targetName = '센서 (' + currentChatRoom.event.sensor.value + ')';
    }

    return targetName;
  };
  const makeEventType = () => {
    // console.log(currentChatRoom.event.eventCode);
    let eventCode = currentChatRoom.event.eventCode;
    let eventTypeName = '-';

    if (!isEmpty(eventCode) && eventCode.length > 5) {
      let eventType = eventCode.substring(0, 5);

      if (
        eventType === 'e0000' ||
        eventType === 'e0100' ||
        eventType === 'e0302' ||
        eventType === 'e0405'
      ) {
        eventTypeName = '인증문제';
      }
      if (eventType === 'e0300' || eventType === 'e0400') {
        eventTypeName = '신호불량';
      }
      if (
        eventType === 'e0301' ||
        eventType === 'e0401' ||
        eventType === 'e0402' ||
        eventType === 'e0403'
      ) {
        eventTypeName = '상태불량';
      }
      if (
        eventType === 'e0200' ||
        eventType === 'e0500' ||
        eventType === 'e0501' ||
        eventType === 'e0502' ||
        eventType === 'e0503'
      ) {
        eventTypeName = '위험예측';
      }
    }

    return eventTypeName;
  };
  const makeEventLevel = () => {
    let badgeColor = '-';
    let badgeName = 'dark';

    let eventLevel = currentChatRoom.event.eventLevel;

    if (eventLevel === '0') {
      badgeName = '심각';
      badgeColor = 'danger';
    }
    if (eventLevel === '1') {
      badgeName = '경고';
      badgeColor = 'warning';
    }
    if (eventLevel === '2') {
      badgeName = '주의';
      badgeColor = 'info';
    }
    if (eventLevel === '3') {
      badgeName = '관찰';
      badgeColor = 'success';
    }
    if (eventLevel === '4') {
      badgeName = '알림';
      badgeColor = 'secondary';
    }

    return (
      <SoftBadge pill bg={badgeColor} className="me-2 fs--2">
        {badgeName}
      </SoftBadge>
    );
  };

  return (
    <Modal
      // dialogClassName="modal-70w"
      size="lg"
      show={chatEventActionShow}
      onHide={onHideEvent}
      backdrop="static"
      keyboard={false}
      aria-labelledby="example-custom-modal-styling-title"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          <h4>조치/점검내용</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form ref={inputForm} className={'px-1'} onSubmit={handleSubmit}>
          <Form.Group
            as={Row}
            className="mb-2 border-bottom"
            controlId="formBaseInfo"
          >
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700'}
            >
              프로젝트
            </Form.Label>
            <Col md={'10'} xs={'8'}>
              <Form.Control
                plaintext
                readOnly
                defaultValue={currentChatRoom.project.value}
                className={'fs--1 fw-normal text-800'}
              />
            </Col>
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700'}
            >
              발생일시
            </Form.Label>
            <Col md={'4'} xs={'8'}>
              <Form.Control
                plaintext
                readOnly
                defaultValue={currentChatRoom.createDate}
                className={'fs--1 fw-normal text-800'}
              />
            </Col>
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700'}
            >
              발생위치
            </Form.Label>
            <Col md={'4'} xs={'8'}>
              <Form.Control
                plaintext
                readOnly
                defaultValue={makeTargetName()}
                className={'fs--1 fw-normal text-800'}
              />
            </Col>
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700'}
            >
              이벤트 분류
            </Form.Label>
            <Col md={'4'} xs={'8'}>
              <Form.Control
                plaintext
                readOnly
                defaultValue={makeEventType()}
                className={'fs--1 fw-normal text-800'}
              />
            </Col>
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700'}
            >
              심각도
            </Form.Label>
            <Col md={'4'} xs={'8'}>
              {makeEventLevel()}
            </Col>
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700'}
            >
              이벤트 내용
            </Form.Label>
            <Col md={'10'} xs={'8'}>
              <Form.Control
                plaintext
                readOnly
                defaultValue={currentChatRoom.description}
                className={'fs--1 fw-normal text-800 text-truncate'}
              />
            </Col>
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700 mb-2'}
            >
              참가자
            </Form.Label>
            <Col md={'10'} xs={'8'} className={'mb-2'}>
              <Form.Control
                plaintext
                readOnly
                defaultValue={makeChatRoomMembers(currentChatRoom.members)}
                className={'fs--1 fw-normal text-800'}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formEventInspectorMember">
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700 mb-2'}
            >
              점검자
            </Form.Label>
            <Col md={'10'} xs={'8'} className={'mb-2'}>
              <Dropdown>
                <Dropdown.Toggle variant="falcon-default" size={'sm'}>
                  {selectedInspectorMember.name +
                    ' (' +
                    selectedInspectorMember.userId +
                    ')' || '점검자를 선택하세요.'}
                </Dropdown.Toggle>
                <Dropdown.Menu className="py-2">
                  {inspectorMembers.map((user, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => setSelectedInspectorMember(user)}
                    >
                      {user.name}&nbsp;({user.userId})
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Form.Control.Feedback
                type="invalid"
                style={{ display: isValidInspector ? 'block' : 'none' }}
              >
                점검자를 선택하세요.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formEventActionDetails"
          >
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700 mb-2'}
            >
              점검 대분류
            </Form.Label>
            <Col md={'4'} xs={'8'} className={'mb-2'}>
              <Flex direction={'column'}>
                <Dropdown>
                  <Dropdown.Toggle variant="falcon-default" size={'sm'}>
                    {selectedCheckMainCategory.name ||
                      '점검 대분류를 선택하세요.'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="py-2">
                    {checkMainCategory.map((checkCode, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => setSelectedCheckMainCategory(checkCode)}
                      >
                        {checkCode.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                {/*{isEmpty(selectedCheckMainCategory) &&*/}
                {/*  JSON.stringify(selectedCheckMainCategory) === '{}' && (*/}
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: isValidCheckMain ? 'block' : 'none' }}
                >
                  점검 대분류를 선택하세요.
                </Form.Control.Feedback>
                {/*)}*/}
              </Flex>
            </Col>

            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700 mb-2'}
            >
              점검 소분류
            </Form.Label>
            <Col md={'4'} xs={'8'} className={'mb-2'}>
              <Flex direction={'column'}>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="falcon-default"
                    size={'sm'}
                    disabled={isEmpty(selectedCheckMainCategory)}
                  >
                    {selectedCheckSubCategory.name ||
                      '점검 소분류를 선택하세요.'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="py-2">
                    {checkSubCategory.map((checkSubCode, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() =>
                          setSelectedCheckSubCategory(checkSubCode)
                        }
                      >
                        {checkSubCode.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Form.Control.Feedback
                  type="invalid"
                  style={{
                    display:
                      !isEmpty(selectedCheckMainCategory) && isValidCheckSub
                        ? 'block'
                        : 'none'
                  }}
                >
                  점검 소분류를 선택하세요.
                </Form.Control.Feedback>
              </Flex>
            </Col>
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700 mb-2'}
            >
              조치 대분류
            </Form.Label>
            <Col md={'4'} xs={'8'} className={'mb-2'}>
              <Flex direction={'column'}>
                <Dropdown>
                  <Dropdown.Toggle variant="falcon-default" size={'sm'}>
                    {selectedActionMainCategory.name ||
                      '조치 대분류를 선택하세요.'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="py-2">
                    {actionMainCategory.map((actionCode, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() =>
                          setSelectedActionMainCategory(actionCode)
                        }
                      >
                        {actionCode.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: isValidActionMain ? 'block' : 'none' }}
                >
                  조치 대분류를 선택하세요.
                </Form.Control.Feedback>
              </Flex>
            </Col>
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700 mb-2'}
            >
              조치 소분류
            </Form.Label>
            <Col md={'4'} xs={'8'} className={'mb-2'}>
              <Flex direction={'column'}>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="falcon-default"
                    size={'sm'}
                    disabled={isEmpty(selectedActionMainCategory)}
                  >
                    {selectedActionSubCategory.name ||
                      '조치 소분류를 선택하세요.'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="py-2">
                    {actionSubCategory.map((actionSubCode, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() =>
                          setSelectedActionSubCategory(actionSubCode)
                        }
                      >
                        {actionSubCode.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Form.Control.Feedback
                  type="invalid"
                  style={{
                    display:
                      !isEmpty(selectedActionMainCategory) && isValidActionSub
                        ? 'block'
                        : 'none'
                  }}
                >
                  조치 소분류를 선택하세요.
                </Form.Control.Feedback>
              </Flex>
            </Col>
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700'}
            >
              점검/조치내용
            </Form.Label>
            <Col xs={'12'}>
              <Form.Control
                as="textarea"
                rows="5"
                placeholder="조치내역을 입력하세요."
                onChange={handleActionDetailsChange}
                style={{ resize: 'none' }}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: isValidActionDetail ? 'block' : 'none' }}
              >
                조치내역을 입력하세요.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formEventActionImage"
          >
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700'}
            >
              첨부 이미지
            </Form.Label>
            <Col xs={'12'}>
              <SimpleBarReact
                style={{
                  minHeight: '85px',
                  maxHeight: '180px'
                  // minWidth: '250px'
                }}
                className={'border rounded px-4'}
              >
                <Flex direction={'row'} wrap={'wrap'}>
                  {attachmentFiles.length === 0 && (
                    <Flex
                      direction={'column'}
                      className={'text-500 pt-4 text-center'}
                    >
                      첨부 된 이미지 없습니다.
                    </Flex>
                  )}
                  {attachmentFiles.map((file, index) => (
                    <FileItem
                      key={file.key}
                      file={file}
                      checkItemHandler={checkItemHandler}
                    />
                  ))}
                </Flex>
              </SimpleBarReact>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formFileMultiple">
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700'}
            >
              첨부 파일
            </Form.Label>
            <Col md={'10'} xs={'8'}>
              <Form.Control
                type="file"
                accept="image/*"
                multiple
                // className="d-none"
                onChange={({ target }) => handleAddAttachment(target.files)}
              />
              <SimpleBarReact
                style={{
                  minHeight: '100px',
                  maxHeight: '100px',
                  minWidth: '150px'
                }}
              >
                {attachments.length > 0 && (
                  <div className="bg-light px-x1 py-3">
                    <Flex direction="column" inline>
                      {attachments.map(attachment => (
                        <ComposeAttachment
                          {...attachment}
                          key={attachment.id}
                          handleDetachAttachment={handleDetachAttachment}
                        />
                      ))}
                    </Flex>
                  </div>
                )}
              </SimpleBarReact>
              {/*<Form.Control ref={uploadFiles} type="file" multiple />*/}
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Flex
          direction={'row'}
          justifyContent={'end'}
          style={{ width: '100%' }}
        >
          <Flex direction={'row'}>
            <Button
              className="btn_close me-1"
              variant="primary"
              type="button"
              // onClick={saveEventAction}
              onClick={handleSubmit}
            >
              확인
            </Button>
            <Button
              className="btn_close"
              variant="secondary"
              onClick={onHideEvent}
            >
              닫기
            </Button>
          </Flex>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};

export default ChatEventAction;
