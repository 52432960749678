import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LoginUserContext } from 'context/Context';

const LoginUserProvider = ({ children }) => {
  const [loginUser, setLoginUser] = useState({});

  const value = { loginUser, setLoginUser };
  return (
    <LoginUserContext.Provider value={value}>
      {children}
    </LoginUserContext.Provider>
  );
};

LoginUserProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default LoginUserProvider;
