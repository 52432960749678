import React from 'react';
import { Card, Col, Row, Container } from 'react-bootstrap';
import Logo from 'components/common/Logo';
import Section from 'components/common/Section';
import { Outlet } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Background from 'components/common/Background';
import bgImg from 'assets/img/generic/seoul-gangnam-district-skyline.jpg';

const AuthSimpleLayout = () => {
  return (
    <Container fluid>
      <Background image={bgImg} />
      <Section className="py-0">
        <Row className="flex-center min-vh-100 py-6">
          <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
            <Card>
              <Card.Body className="p-4 p-sm-5">
                <Logo />
                <Flex alignItems="center" justifyContent="center">
                  <h3>도로·시설물</h3>
                </Flex>
                <Flex alignItems="center" justifyContent="center">
                  <h3>위험 관리 시스템</h3>
                </Flex>
                <Outlet />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Section>
    </Container>
  );
};

export default AuthSimpleLayout;
