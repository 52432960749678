import { chainAxios } from 'helpers/chainAxios';
import moment from 'moment';
import { getSearchPeriodAmount, isEmpty } from 'helpers/utils';

export const getRealTimeDistance = async (facilityId, searchPeriod) => {
  if (facilityId === '') {
    facilityId = '*';
  }
  let amount = getSearchPeriodAmount(searchPeriod);
  let startDate = moment().subtract(amount, 'days');
  let endDate = moment();

  // http://112.175.18.232:7000/api/facility/FD1/sensors/chart?startDate=2024-01-06&endDate=2024-01-06&items=distance
  let url =
    '/api/facility/' +
    facilityId +
    '/sensors/chart?startDate=' +
    moment(startDate).format('YYYY-MM-DD') +
    '&endDate=' +
    moment(endDate).format('YYYY-MM-DD') +
    '&items=distance';
  // console.log('getRealTimeDistance : ', url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response);
    return response.data.data.chart;
  }
  return {};
};

export const getRealTimeAngle = async (facilityId, searchPeriod) => {
  if (facilityId === '') {
    facilityId = '*';
  }
  let amount = getSearchPeriodAmount(searchPeriod);
  let startDate = moment().subtract(amount, 'days');
  let endDate = moment();

  // http://112.175.18.232:7000/api/facility/FD1/sensors/chart?startDate=2024-01-01&endDate=2024-01-18&items=angleX%2C%20angleY
  let url =
    '/api/facility/' +
    facilityId +
    '/sensors/chart?startDate=' +
    moment(startDate).format('YYYY-MM-DD') +
    '&endDate=' +
    moment(endDate).format('YYYY-MM-DD') +
    '&items=angleX,angleY';
  // console.log('getRealTimeAngle : ', url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response);
    return response.data.data.chart;
  }
  return {};
};

export const getRealDataSend = async (facilityId, searchPeriod) => {
  if (facilityId === '') {
    facilityId = '*';
  }
  let amount = getSearchPeriodAmount(searchPeriod);
  let startDate = moment().subtract(amount, 'days');
  let endDate = moment();

  // http://112.175.18.232:7000/api/facility/FD1/sensors/chart/traffic?startDate=2024-01-01&endDate=2024-01-31
  let url =
    '/api/facility/' +
    facilityId +
    '/sensors/chart/traffic?startDate=' +
    startDate.format('YYYY-MM-DD') +
    '&endDate=' +
    endDate.format('YYYY-MM-DD');
  // console.log('getRealDataSend : ', url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response.data.data.projectList.dataList);
    return response.data.data.chart;
  }
  return [];
};

export const getFacilityPredictedSafety = async (facilityId, searchPeriod) => {
  if (facilityId === '') {
    facilityId = '*';
  }
  let amount = getSearchPeriodAmount(searchPeriod);
  let startDate = moment().subtract(amount, 'days');
  let endDate = moment();

  // http://112.175.18.232:7000/api/facility/FD1/chart?startDate=2024-01-06&endDate=2024-01-06&items=status
  let url =
    '/api/facility/' +
    facilityId +
    '/chart?startDate=' +
    moment(startDate).format('YYYY-MM-DD') +
    '&endDate=' +
    moment(endDate).format('YYYY-MM-DD') +
    '&items=status';
  // console.log('getFacilityPredictedSafety : ', url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response.data.data.projectList.dataList);
    return response.data.data.chart;
  }
  return [];
};

export const getSlopeAngels = async (sensorId, searchPeriod) => {
  if (sensorId === '') {
    sensorId = '*';
  }
  let amount = getSearchPeriodAmount(searchPeriod);
  let startDate = moment().subtract(amount, 'days');
  let endDate = moment();

  // http://112.175.18.232:7000/api/sensor/1/analyzedchart?pageNo=0&pageSize=9999&sort=sawDate%24asc&startDate=2023-12-20&endDate=2024-01-20&items=saw_tilt
  let url =
    '/api/sensor/' +
    sensorId +
    '/analyzedchart?pageNo=0&pageSize=9999&sort=sawDate$asc&startDate=' +
    moment(startDate).format('YYYY-MM-DD') +
    '&endDate=' +
    moment(endDate).format('YYYY-MM-DD') +
    '&items=saw_tilt';
  // console.log('getSlopeAngels : ', url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response.data.data.projectList.dataList);
    return response.data.data.chart.dataList;
  }
  return [];
};

export const getSolidationsSubsidendces = async (sensorId, searchPeriod) => {
  if (sensorId === '') {
    sensorId = '*';
  }
  let amount = getSearchPeriodAmount(searchPeriod);
  let startDate = moment().subtract(amount, 'days');
  let endDate = moment();

  // http://112.175.18.232:7000/api/sensor/1/analyzedchart?pageNo=0&pageSize=9999&sort=sawDate%24asc&startDate=2023-12-20&endDate=2024-01-20&items=saw_subs%2Csaw_replus%2Csaw_reminus
  let url =
    '/api/sensor/' +
    sensorId +
    '/analyzedchart?pageNo=0&pageSize=9999&sort=sawDate$asc&startDate=' +
    moment(startDate).format('YYYY-MM-DD') +
    '&endDate=' +
    moment(endDate).format('YYYY-MM-DD') +
    '&items=saw_subs,saw_replus,saw_reminus';
  // console.log('getSolidationsSubsidendces : ', url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response.data.data.projectList.dataList);
    return response.data.data.chart.dataList;
  }
  return [];
};

export const getFluctuations = async (sensorId, searchPeriod) => {
  if (sensorId === '') {
    sensorId = '*';
  }
  let amount = getSearchPeriodAmount(searchPeriod);
  let startDate = moment().subtract(amount, 'days');
  let endDate = moment();

  // http://112.175.18.232:7000/api/sensor/2/fluctchart?startDate=2023-12-01&endDate=2023-12-31
  let url =
    '/api/sensor/' +
    sensorId +
    '/fluctchart?startDate=' +
    moment(startDate).format('YYYY-MM-DD') +
    '&endDate=' +
    moment(endDate).format('YYYY-MM-DD');
  // console.log('getFluctuations : ', url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response.data.data.projectList.dataList);
    return response.data.data.chart.chartData;
  }
  return [];
};

export const getSubsidenceMapImages = async (
  facilityId,
  startDate,
  endDate
) => {
  if (facilityId === '') {
    facilityId = '*';
  }

  let pStartDate = startDate;
  let pEndDate = endDate;

  if (isEmpty(pStartDate) || isEmpty(pEndDate)) {
    let amount = getSearchPeriodAmount('1W');
    pStartDate = moment().subtract(amount, 'days').format('YYYY-MM-DD');
    pEndDate = moment().format('YYYY-MM-DD');
  }

  // http://112.175.18.232:7000/api/facility/FD1/sawmaps?startDate=2023-11-01&endDate=2023-12-31&includePredict=true&includeContent=false
  let url =
    '/api/facility/' +
    facilityId +
    '/sawmaps?startDate=' +
    pStartDate +
    '&endDate=' +
    pEndDate +
    '&includePredict=true&includeContent=true';
  // console.log('getSubsidenceMapImages : ', url);
  const response = await chainAxios.get(url);
  // console.log(response.data);
  if (response.data.resultCode === 200) {
    // console.log(response.data.data.diagrams);
    return response.data.data.diagrams;
  }
  return [];
};

export const getGeoLogicalCrossSectionImages = async (
  facilityId,
  sectionId,
  startDate,
  endDate
) => {
  if (isEmpty(facilityId)) {
    facilityId = '*';
  }
  if (isEmpty(sectionId)) {
    sectionId = '*';
  }
  let pStartDate = startDate;
  let pEndDate = endDate;

  if (isEmpty(pStartDate) || isEmpty(pEndDate)) {
    let amount = getSearchPeriodAmount('1W');
    pStartDate = moment().subtract(amount, 'days').format('YYYY-MM-DD');
    pEndDate = moment().format('YYYY-MM-DD');
  }

  // http://112.175.18.232:7000/api/facility/RD1/section/RD1-1/diagrams?startDate=2024-02-01&endDate=2024-05-31
  let url =
    '/api/facility/' +
    facilityId +
    '/section/' +
    sectionId +
    '/diagrams?startDate=' +
    pStartDate +
    '&endDate=' +
    pEndDate;
  // console.log('getGeoLogicalCrossSectionImages : ', url);
  const response = await chainAxios.get(url);
  // console.log(response.data);
  if (response.data.resultCode === 200) {
    // console.log(response.data.data.diagrams);
    return response.data.data.diagrams;
  }
  return [];
};
