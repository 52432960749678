export const dongGoyangData = {
  type: 'FeatureCollection',
  name: 'goyang',
  features: [
    {
      type: 'Feature',
      id: '41287104',
      properties: {
        rgnCode: '41287104',
        colCode: '41280',
        rgnKo: ['대화동'],
        rgnSize: 1,
        rgnBbox: [
          126.71777604614, 37.647478315752, 126.762416820852, 37.690077622742
        ],
        rgnCenter: [126.741078201762, 37.672450913751],
        rgnArea: 8926463,
        predVal: [
          0.64137, 0.64109, 0.64408, 0.64357, 0.64363, 0.64363, 0.64363,
          0.64357, 0.64362, 0.64408, 0.64347, 0.64347, 0.64347, 0.64347,
          0.64347, 0.63627, 0.07607, 0.62608, 0.63799, 0.6363, 0.63563, 0.63525,
          0.63525, 0.63525, 0.63525, 0.63525, 0.63525, 0.63148, 0.63063, 0.6228,
          0.61643
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.71777604614, 37.673097460357],
            [126.717807465686, 37.673234735542],
            [126.717887098798, 37.673695232257],
            [126.718028652951, 37.674031688135],
            [126.718126189897, 37.674178366051],
            [126.718479053188, 37.674456423515],
            [126.71898655426, 37.674932063551],
            [126.719232265286, 37.675091952201],
            [126.719435502255, 37.675246233845],
            [126.720105956484, 37.675894388739],
            [126.720209297035, 37.676054845549],
            [126.72022994651, 37.676103898496],
            [126.72040243819, 37.67623630168],
            [126.720760207768, 37.676525743182],
            [126.720828127598, 37.676580683743],
            [126.720913344181, 37.676657081445],
            [126.72101306685, 37.676556797555],
            [126.721188300369, 37.67654559167],
            [126.721517297759, 37.676731297007],
            [126.721715916843, 37.676892185851],
            [126.721924910535, 37.677113058424],
            [126.722084245898, 37.677294071251],
            [126.722201011027, 37.677409996177],
            [126.722348994244, 37.677532202555],
            [126.722459098898, 37.677583457942],
            [126.722572687878, 37.677612422491],
            [126.722592201467, 37.67760811696],
            [126.722629031044, 37.6776445221],
            [126.722674078894, 37.677677946432],
            [126.722752360004, 37.677727378425],
            [126.722825588762, 37.677788051527],
            [126.723408657152, 37.678249147073],
            [126.723426936125, 37.678262830838],
            [126.723483061762, 37.678304849189],
            [126.723547720376, 37.678353743995],
            [126.723657135627, 37.678438422571],
            [126.723809164102, 37.678556176838],
            [126.723871874768, 37.678604697481],
            [126.723874090204, 37.678606414537],
            [126.724029216987, 37.678726428233],
            [126.724035839608, 37.678801863893],
            [126.724219200663, 37.679014383132],
            [126.72435052181, 37.679134799921],
            [126.724453516454, 37.679251708325],
            [126.724645430559, 37.67935959949],
            [126.724841759938, 37.679438669707],
            [126.724994580161, 37.679466327442],
            [126.725058473646, 37.679477559137],
            [126.725090898737, 37.679476463877],
            [126.725121282692, 37.679475544019],
            [126.72516323096, 37.679474200766],
            [126.725396652368, 37.679189374695],
            [126.725436893401, 37.679165358979],
            [126.725437109922, 37.679137519557],
            [126.725550541478, 37.679001828864],
            [126.725702754568, 37.678819738775],
            [126.726016389662, 37.678444623719],
            [126.726061080545, 37.678391174523],
            [126.726080531898, 37.678369975108],
            [126.726159201949, 37.678403927106],
            [126.726123223088, 37.678446549018],
            [126.726099194297, 37.678473269699],
            [126.725809554214, 37.67881561878],
            [126.725559668504, 37.67910239831],
            [126.725409269595, 37.679278005478],
            [126.725405398696, 37.679282501259],
            [126.725255750144, 37.679469552281],
            [126.725168007606, 37.679595320141],
            [126.72516581888, 37.67960166684],
            [126.725148389222, 37.679621663539],
            [126.725139496537, 37.679629183807],
            [126.725130462838, 37.679638046187],
            [126.725054100372, 37.679727405461],
            [126.726066330961, 37.680221143974],
            [126.726510161129, 37.680461296692],
            [126.727127659388, 37.680795282311],
            [126.727816566053, 37.681164387991],
            [126.728328059013, 37.681442621613],
            [126.729025097248, 37.681823361537],
            [126.72941439925, 37.682037699703],
            [126.730024675265, 37.682369761411],
            [126.730722976902, 37.682750764467],
            [126.731217961979, 37.683023001222],
            [126.731321226354, 37.683079368151],
            [126.731715797874, 37.683294764901],
            [126.732071846481, 37.683489133827],
            [126.732620586112, 37.683790318447],
            [126.733053584361, 37.684027989153],
            [126.733996187178, 37.684545664868],
            [126.73534037549, 37.685279751555],
            [126.739134107736, 37.687353991466],
            [126.739797981405, 37.687718646596],
            [126.741264604006, 37.688507679963],
            [126.742928118672, 37.689408810824],
            [126.743269412941, 37.689595085862],
            [126.743455107931, 37.689697391746],
            [126.743821992176, 37.68989397418],
            [126.744046167797, 37.68998338928],
            [126.744252116238, 37.690032490959],
            [126.74446139949, 37.690068085037],
            [126.744703765343, 37.690077622742],
            [126.744870691431, 37.69006717452],
            [126.744907362627, 37.690059064526],
            [126.74536990453, 37.689956801199],
            [126.745689891189, 37.689886058074],
            [126.751852836615, 37.687910830738],
            [126.751905846251, 37.687925448525],
            [126.754159901527, 37.688548892243],
            [126.754140478736, 37.688593809788],
            [126.754558094917, 37.68867856512],
            [126.754702158969, 37.688791667854],
            [126.75491972761, 37.688893481842],
            [126.755071999296, 37.688959120292],
            [126.755194767635, 37.688998388672],
            [126.755524382184, 37.689091065452],
            [126.755778863395, 37.689173584038],
            [126.755831568765, 37.6891778383],
            [126.756079454505, 37.689197725251],
            [126.75613662843, 37.689153336427],
            [126.756224832668, 37.689084956285],
            [126.756316777495, 37.689016764046],
            [126.756374626341, 37.688973908153],
            [126.756432263791, 37.68892636676],
            [126.75663725851, 37.688853363732],
            [126.756923127527, 37.688699531114],
            [126.757031534448, 37.688658581677],
            [126.757516080033, 37.688525070703],
            [126.757926266304, 37.688456093651],
            [126.758352676237, 37.688383454655],
            [126.758581180704, 37.688331489256],
            [126.758806429293, 37.688234828712],
            [126.758898578676, 37.688172401061],
            [126.759111146927, 37.68803526039],
            [126.759197045958, 37.687977234397],
            [126.75942965431, 37.687917797747],
            [126.759620506168, 37.687869086612],
            [126.759855679951, 37.687787490573],
            [126.760666840345, 37.68751417454],
            [126.760884491129, 37.687437541026],
            [126.760992249423, 37.687399604852],
            [126.761233326015, 37.687314774609],
            [126.761342733514, 37.687278598362],
            [126.761358687439, 37.687253223554],
            [126.761625834495, 37.686817698747],
            [126.76170133063, 37.686679733887],
            [126.761819402116, 37.686522664895],
            [126.761893627912, 37.686391724873],
            [126.76186589514, 37.686378334195],
            [126.761855269951, 37.686368492051],
            [126.761994297142, 37.686181463218],
            [126.762100015286, 37.686057974952],
            [126.762223986132, 37.68593434343],
            [126.762416820852, 37.685689581086],
            [126.762382854413, 37.685640409451],
            [126.761877652334, 37.684908698732],
            [126.761714030634, 37.684671771867],
            [126.758159187978, 37.679522121321],
            [126.756847161665, 37.677621515218],
            [126.756710987516, 37.677424190654],
            [126.754012358409, 37.673514116528],
            [126.753794187528, 37.673198138839],
            [126.751109512248, 37.669307666388],
            [126.750917872901, 37.669030030759],
            [126.750989952685, 37.668998469708],
            [126.751047950565, 37.668974987447],
            [126.751072355447, 37.668964317717],
            [126.752322892614, 37.668419085996],
            [126.752519706764, 37.66830237543],
            [126.752779414163, 37.668189130767],
            [126.752918926202, 37.66815915226],
            [126.75300712203, 37.668120686459],
            [126.75799497517, 37.66594542384],
            [126.757977152392, 37.665919439075],
            [126.757887360127, 37.665789063316],
            [126.75736305392, 37.66502710904],
            [126.756786419662, 37.664189091907],
            [126.756660987514, 37.664006835378],
            [126.756382828234, 37.663602622441],
            [126.756325256494, 37.663532785611],
            [126.75632062818, 37.663527171948],
            [126.756318575317, 37.663524176455],
            [126.756237115331, 37.663405250045],
            [126.75623298704, 37.66339923198],
            [126.756206852536, 37.663361120597],
            [126.756171965134, 37.663310269284],
            [126.756117801328, 37.663231285568],
            [126.755966702622, 37.663010980906],
            [126.755763810392, 37.662715147817],
            [126.755714362139, 37.662643048146],
            [126.755482191721, 37.662307808112],
            [126.755454477589, 37.66226779221],
            [126.755335590301, 37.662096125282],
            [126.755288441561, 37.662028057569],
            [126.75506383123, 37.662125704336],
            [126.754417009227, 37.66240689591],
            [126.754379486825, 37.662423205978],
            [126.754161184592, 37.662518116269],
            [126.753973344831, 37.662599773723],
            [126.753947115322, 37.662611179037],
            [126.75358166069, 37.66277005464],
            [126.75325648543, 37.662911426917],
            [126.75323477298, 37.66292086845],
            [126.753102807076, 37.662978234778],
            [126.752966391738, 37.663037546691],
            [126.752834493514, 37.663094885831],
            [126.752715692972, 37.663146540289],
            [126.752164500345, 37.663386197108],
            [126.752116710643, 37.663408710462],
            [126.752092313762, 37.663417578496],
            [126.751963262981, 37.663473706365],
            [126.751805179473, 37.663535358659],
            [126.751785145951, 37.663544226856],
            [126.751015865992, 37.663884901791],
            [126.750858899109, 37.663954411652],
            [126.75065973413, 37.664041508629],
            [126.750470863204, 37.664124194397],
            [126.750404245466, 37.664153964848],
            [126.750178432703, 37.664251491583],
            [126.749235370305, 37.664665402794],
            [126.748991240328, 37.664772591526],
            [126.748461755395, 37.665004177681],
            [126.748460729162, 37.665002661848],
            [126.748194518391, 37.664612997038],
            [126.748026527802, 37.664370130739],
            [126.7478565409, 37.664124719161],
            [126.747613934533, 37.663771214679],
            [126.747203906331, 37.663193088065],
            [126.747002537984, 37.662882548408],
            [126.746566593304, 37.662249998963],
            [126.746337116568, 37.661931522882],
            [126.746176055904, 37.661697255106],
            [126.746018971306, 37.661473762325],
            [126.746016693392, 37.661470432805],
            [126.746010456822, 37.661461463639],
            [126.746006193807, 37.661455345818],
            [126.745996743087, 37.661441756714],
            [126.745996720544, 37.661441720626],
            [126.745995547666, 37.661440033268],
            [126.745992130845, 37.661435025475],
            [126.745927023911, 37.661341651879],
            [126.745914923051, 37.661324227896],
            [126.745909837609, 37.661316684754],
            [126.74590771735, 37.661313652895],
            [126.745903667612, 37.661308139183],
            [126.745893766229, 37.661293783268],
            [126.745872629588, 37.661264068466],
            [126.745828424673, 37.661196192559],
            [126.745779361694, 37.661123900352],
            [126.745742338623, 37.661070284344],
            [126.745712234345, 37.661024981277],
            [126.745693889005, 37.660997642051],
            [126.745643643298, 37.660923311024],
            [126.745616750955, 37.660883294593],
            [126.745577710998, 37.660826322542],
            [126.745549339831, 37.660781329522],
            [126.745472545412, 37.660669352268],
            [126.745404458568, 37.660569556986],
            [126.745345221389, 37.660483448624],
            [126.745345131158, 37.660483322292],
            [126.745340057071, 37.660475806178],
            [126.745333561213, 37.660466485033],
            [126.745328509578, 37.660459032035],
            [126.745325633551, 37.660454980425],
            [126.745316017451, 37.660440120536],
            [126.745309045363, 37.660430870432],
            [126.745308854373, 37.660430383493],
            [126.745305538248, 37.660425781334],
            [126.745294792908, 37.660409738717],
            [126.745108916208, 37.660140124462],
            [126.744968518924, 37.659940091771],
            [126.744848450143, 37.659764177109],
            [126.744752822159, 37.659629219646],
            [126.744560455549, 37.65935960839],
            [126.744446393322, 37.659190788027],
            [126.744083097704, 37.658654467025],
            [126.743902594012, 37.65838701585],
            [126.74372155916, 37.65811950917],
            [126.743563340358, 37.657897298917],
            [126.743316467298, 37.657534189608],
            [126.743630218664, 37.657493187927],
            [126.74399200284, 37.657444100439],
            [126.744431832615, 37.657385739761],
            [126.744737727391, 37.657345267602],
            [126.745115186678, 37.657295165657],
            [126.745487721348, 37.657246736633],
            [126.745618740837, 37.657229424927],
            [126.745723932985, 37.657215471761],
            [126.745656141389, 37.657159248231],
            [126.745886981764, 37.657127764158],
            [126.74576321157, 37.656538259968],
            [126.745702566961, 37.656245492633],
            [126.745671304674, 37.65607586182],
            [126.745649915628, 37.655968239316],
            [126.74560541573, 37.655739629134],
            [126.745595014604, 37.655686205929],
            [126.745569181917, 37.655552625754],
            [126.745525420755, 37.655326837174],
            [126.745483455379, 37.65510618801],
            [126.745420675592, 37.654775845267],
            [126.744281169951, 37.6549204949],
            [126.744217225518, 37.654929275081],
            [126.743190621426, 37.655063438786],
            [126.743056057275, 37.655077739802],
            [126.742890738155, 37.654974126031],
            [126.742771467144, 37.654892056181],
            [126.742437504889, 37.654663647447],
            [126.742425415156, 37.654653349808],
            [126.742377057508, 37.654611798852],
            [126.742278116852, 37.654582119668],
            [126.742162011995, 37.654502398685],
            [126.742065128438, 37.654435243382],
            [126.742034691881, 37.654389136736],
            [126.742021808999, 37.654378837311],
            [126.741585732988, 37.654089836323],
            [126.741490885232, 37.654024126588],
            [126.741279598199, 37.653877703111],
            [126.740963946891, 37.653665367886],
            [126.740955127342, 37.653659789405],
            [126.74079694504, 37.653558594044],
            [126.740670306821, 37.653478217623],
            [126.740624287447, 37.653449014441],
            [126.740582458734, 37.65342045119],
            [126.740151358821, 37.653127788917],
            [126.739883543801, 37.652954902239],
            [126.739682497787, 37.652830482334],
            [126.739657004522, 37.65282943468],
            [126.739369944204, 37.652609860745],
            [126.739047973743, 37.652393001458],
            [126.738825999477, 37.652227088768],
            [126.738529190244, 37.652041998328],
            [126.7384845278, 37.652014103742],
            [126.738491501776, 37.651999865898],
            [126.738493953242, 37.651992366259],
            [126.738350261393, 37.651954970772],
            [126.73833443407, 37.651950836038],
            [126.738116441662, 37.651893588229],
            [126.738133821168, 37.65183362223],
            [126.738202075244, 37.651598620638],
            [126.737928468768, 37.651541041114],
            [126.737889502738, 37.651530304545],
            [126.737867429198, 37.651523678114],
            [126.737801639937, 37.651493979154],
            [126.737777268439, 37.651432208019],
            [126.73780447427, 37.651397446228],
            [126.737645269248, 37.651219553503],
            [126.737607462493, 37.651159869566],
            [126.737282107314, 37.651047591819],
            [126.73686118186, 37.650912907851],
            [126.736756084243, 37.650832052962],
            [126.736644186229, 37.650831423377],
            [126.736575601516, 37.650823385859],
            [126.736473262164, 37.650811488384],
            [126.736286988878, 37.650742325505],
            [126.736205675561, 37.650681056692],
            [126.736171170081, 37.650569753955],
            [126.736166519421, 37.65055514773],
            [126.736042405191, 37.650452968443],
            [126.735705752696, 37.650195883541],
            [126.735411140732, 37.649971057249],
            [126.735239087131, 37.649839667533],
            [126.734601175285, 37.649349988597],
            [126.733657514642, 37.648542406699],
            [126.732423905252, 37.647478315752],
            [126.730647500159, 37.648404055227],
            [126.730149454065, 37.648444941098],
            [126.730289079438, 37.649840644495],
            [126.730299873127, 37.65005086634],
            [126.730318472626, 37.650420488255],
            [126.730335878929, 37.650744337973],
            [126.730381766792, 37.651525046402],
            [126.730407026214, 37.651901981398],
            [126.730424233756, 37.652187178833],
            [126.730441853464, 37.652639597738],
            [126.730463013344, 37.652866331087],
            [126.730500865321, 37.653083732656],
            [126.730550445274, 37.653410539218],
            [126.730772995452, 37.654974327531],
            [126.730851877866, 37.655533651963],
            [126.730876092668, 37.65554370831],
            [126.730903364254, 37.65555458254],
            [126.73096897311, 37.655618611957],
            [126.730976608259, 37.655700617943],
            [126.730978783523, 37.655725760057],
            [126.730981074726, 37.655750181659],
            [126.731011218014, 37.655875395826],
            [126.731007897458, 37.655947285795],
            [126.731005050549, 37.655982327086],
            [126.731019485848, 37.656064168463],
            [126.730994391886, 37.656202680444],
            [126.730999099846, 37.6562169266],
            [126.731017145955, 37.656271837186],
            [126.731125798738, 37.656594607273],
            [126.731133635734, 37.656617897345],
            [126.73113585812, 37.656630065562],
            [126.731261876426, 37.65684884006],
            [126.731278973847, 37.656946724795],
            [126.731306313709, 37.657064003886],
            [126.731309654432, 37.657142846592],
            [126.731311557845, 37.657211775313],
            [126.731281933209, 37.657224591354],
            [126.731261502622, 37.657233374076],
            [126.731240178166, 37.657301168415],
            [126.731222644369, 37.657354916271],
            [126.731203659167, 37.657465151742],
            [126.731174618574, 37.657598158755],
            [126.73107714589, 37.657695060117],
            [126.731035637046, 37.657734878008],
            [126.730990103982, 37.657759370132],
            [126.73097943039, 37.657765111875],
            [126.730964051361, 37.657755886691],
            [126.730949434561, 37.657755222487],
            [126.730912034327, 37.657818294833],
            [126.730901200961, 37.657836830004],
            [126.730823755987, 37.657942156132],
            [126.730817492606, 37.657950520798],
            [126.730793792912, 37.658016868041],
            [126.730790023362, 37.658025058234],
            [126.730729172723, 37.658085734173],
            [126.730731425301, 37.65812078715],
            [126.730669809142, 37.658142584363],
            [126.730386773236, 37.658095759376],
            [126.73037363208, 37.658094422763],
            [126.730321614129, 37.658063408865],
            [126.730287243459, 37.658041156927],
            [126.730221245561, 37.65799953343],
            [126.730203626219, 37.657989338956],
            [126.73013585613, 37.657864848949],
            [126.730021356213, 37.657797823471],
            [126.73000252095, 37.657664075888],
            [126.729720634056, 37.657781427051],
            [126.729710215988, 37.657785439402],
            [126.729660660394, 37.65780653409],
            [126.729365368842, 37.657935467035],
            [126.729438029903, 37.658287644346],
            [126.72964320022, 37.658570121549],
            [126.729836693041, 37.658838741575],
            [126.730061402145, 37.659140498773],
            [126.73016906549, 37.659283604544],
            [126.730259510106, 37.659406101439],
            [126.730375543443, 37.659563281421],
            [126.730706003271, 37.660015418286],
            [126.730738494835, 37.660062316366],
            [126.730747221438, 37.660074760795],
            [126.730850133991, 37.660743652989],
            [126.730852624894, 37.66075992124],
            [126.730930277992, 37.661222568027],
            [126.730961112244, 37.661407788372],
            [126.730969320933, 37.661456820042],
            [126.731090638239, 37.662192561105],
            [126.73109984814, 37.662246820687],
            [126.73116087472, 37.662607258961],
            [126.731231029993, 37.66301330724],
            [126.731295417033, 37.663385015238],
            [126.731328726507, 37.663575737015],
            [126.731348069027, 37.663680023667],
            [126.731519519295, 37.664626345637],
            [126.731568414832, 37.664895036961],
            [126.731591950974, 37.665030777029],
            [126.731645902217, 37.665346348373],
            [126.731678574027, 37.665535239561],
            [126.731727080042, 37.665821057341],
            [126.731794390292, 37.666216286917],
            [126.731741569342, 37.666214391213],
            [126.731725205681, 37.66621366004],
            [126.731438435798, 37.66620339945],
            [126.731384617895, 37.666192005073],
            [126.731278349035, 37.666170462716],
            [126.731109711606, 37.666104530637],
            [126.730996217712, 37.66601579509],
            [126.730883564595, 37.665926565829],
            [126.730778569758, 37.66584460685],
            [126.730731632381, 37.665808126923],
            [126.730701729231, 37.665784326706],
            [126.730500719793, 37.665625888843],
            [126.730411809245, 37.665559337049],
            [126.730397520204, 37.665549159278],
            [126.730031771268, 37.665285685093],
            [126.729997475184, 37.665260739362],
            [126.729920460871, 37.665204945139],
            [126.72984841405, 37.665154505063],
            [126.729746638189, 37.665082436222],
            [126.729427269107, 37.66485383546],
            [126.729289648891, 37.66475595181],
            [126.729208620268, 37.664697931467],
            [126.729109455437, 37.664764599589],
            [126.729099843402, 37.664774172731],
            [126.729568481711, 37.665119695216],
            [126.729471054237, 37.665219559405],
            [126.729680924671, 37.66534348524],
            [126.729826004886, 37.66542562763],
            [126.729773540445, 37.665441138622],
            [126.729719963956, 37.665460115764],
            [126.729516890705, 37.665492244796],
            [126.729495380224, 37.665495006222],
            [126.729455506734, 37.665501446302],
            [126.729261666807, 37.665478700044],
            [126.729107409588, 37.665510508564],
            [126.729035071636, 37.665549083228],
            [126.72896203363, 37.665546617015],
            [126.728772427204, 37.665590398401],
            [126.728535662649, 37.665636584066],
            [126.728414359492, 37.665629365809],
            [126.728306180901, 37.665597446181],
            [126.72824325503, 37.665557252271],
            [126.728027649306, 37.665535850064],
            [126.728003065193, 37.665533000053],
            [126.727833194838, 37.665464492665],
            [126.727695025935, 37.665466874923],
            [126.727571702642, 37.665469741981],
            [126.72753882569, 37.665472098245],
            [126.727482670069, 37.66548647343],
            [126.727323977986, 37.66552205339],
            [126.727156039707, 37.665575450851],
            [126.727099946525, 37.665603610849],
            [126.72716869218, 37.6657099051],
            [126.727219071938, 37.665786784961],
            [126.727336607217, 37.665940223222],
            [126.727384211157, 37.666001509766],
            [126.727435417172, 37.666069561939],
            [126.727447366495, 37.66608715863],
            [126.72750407722, 37.666168557927],
            [126.727544447608, 37.666224421778],
            [126.727605251336, 37.666302406844],
            [126.727738887387, 37.666454530623],
            [126.72787943142, 37.666608382118],
            [126.728056546973, 37.66678190533],
            [126.728074685807, 37.6668054537],
            [126.728460826551, 37.667306828278],
            [126.72857857283, 37.667459707156],
            [126.728587051691, 37.667453275827],
            [126.728615624646, 37.667493669371],
            [126.728772187708, 37.66770017351],
            [126.728815902428, 37.667771811628],
            [126.728856090863, 37.667784968602],
            [126.72893568063, 37.667933542366],
            [126.729188157526, 37.668226580502],
            [126.729197300837, 37.668233737295],
            [126.729253822064, 37.668277997295],
            [126.7292869369, 37.668303931572],
            [126.72932005176, 37.66832986584],
            [126.729437343721, 37.668396988441],
            [126.729462008897, 37.668408956159],
            [126.729504362116, 37.668450210158],
            [126.729559595934, 37.668502593792],
            [126.729526461428, 37.668513058745],
            [126.729520446707, 37.668515117107],
            [126.729401468398, 37.668537276852],
            [126.729329076493, 37.66855882321],
            [126.729198563765, 37.668573658274],
            [126.729058332211, 37.66861162568],
            [126.728998085604, 37.668628334648],
            [126.728819459578, 37.668689277896],
            [126.728418702071, 37.668713037302],
            [126.728182783279, 37.66872672618],
            [126.727968960326, 37.668742087578],
            [126.727819187123, 37.668723720743],
            [126.727679039335, 37.668738621865],
            [126.727575535798, 37.668775051019],
            [126.727558106925, 37.668786452861],
            [126.727539916045, 37.668736893289],
            [126.727525257633, 37.668697640009],
            [126.727451971068, 37.668499941046],
            [126.727424945491, 37.668427187972],
            [126.727409952175, 37.668386573432],
            [126.727360763234, 37.668432228404],
            [126.727324710207, 37.668465705744],
            [126.72725818842, 37.668518203792],
            [126.727188926955, 37.668572875779],
            [126.726898885602, 37.668802218564],
            [126.7267983968, 37.66888163033],
            [126.726630046465, 37.669013860925],
            [126.72662447669, 37.669018172602],
            [126.726442939979, 37.669160643253],
            [126.726249466536, 37.669312545951],
            [126.726015287256, 37.669498950508],
            [126.725853520768, 37.669627771668],
            [126.725640485959, 37.669796836161],
            [126.725371620967, 37.670013520977],
            [126.724961452456, 37.670341414027],
            [126.724819234283, 37.670455053185],
            [126.72473500233, 37.670520265974],
            [126.724555506944, 37.670660486038],
            [126.724259835041, 37.670890800072],
            [126.72377691247, 37.671272846376],
            [126.723448161294, 37.671527316532],
            [126.723261390011, 37.67167202229],
            [126.723125547152, 37.671776844908],
            [126.723088121849, 37.671812615162],
            [126.723042730866, 37.671856745616],
            [126.722720883108, 37.672112761657],
            [126.722527954749, 37.67226637128],
            [126.722399601568, 37.672368327661],
            [126.722292846377, 37.672453757191],
            [126.722078318499, 37.672623712577],
            [126.721991459128, 37.67269306161],
            [126.721759985381, 37.672877752146],
            [126.72144525019, 37.67313900714],
            [126.721275850088, 37.673274020479],
            [126.720937502803, 37.673543687103],
            [126.720806869414, 37.673647978817],
            [126.720483751905, 37.673905967762],
            [126.720162696971, 37.674157473747],
            [126.71984344464, 37.674411325669],
            [126.719516608514, 37.674661734898],
            [126.719257865105, 37.674860320262],
            [126.719225919966, 37.674884840266],
            [126.719026730354, 37.674712818857],
            [126.71887548513, 37.674586870441],
            [126.718573617697, 37.67433548807],
            [126.71853581425, 37.674260977327],
            [126.718468948306, 37.674257843687],
            [126.718455275586, 37.67424654876],
            [126.718378889447, 37.674183387676],
            [126.718308429205, 37.674141773933],
            [126.718288993742, 37.674125699023],
            [126.718102515347, 37.673971482682],
            [126.718037463357, 37.673917682649],
            [126.717970241264, 37.673829496378],
            [126.717942767759, 37.673721494217],
            [126.717951103072, 37.673616190952],
            [126.717993841144, 37.673492824865],
            [126.718126340852, 37.67311035747],
            [126.71817576177, 37.672961275677],
            [126.718280331194, 37.672644160363],
            [126.718303827407, 37.672572896044],
            [126.718108448636, 37.672539550384],
            [126.717958649442, 37.672550488515],
            [126.717912234385, 37.672690045199],
            [126.717860269443, 37.672846301496],
            [126.717846532906, 37.672887595925],
            [126.717833332097, 37.672931080996],
            [126.717802867378, 37.673019392814],
            [126.71777604614, 37.673097460357]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281101',
      properties: {
        rgnCode: '41281101',
        colCode: '41280',
        rgnKo: ['주교동'],
        rgnSize: 1,
        rgnBbox: [
          126.810691941168, 37.644125951399, 126.841708528298, 37.677799031059
        ],
        rgnCenter: [126.826555407015, 37.661038941626],
        rgnArea: 5357453,
        predVal: [
          0.46078, 0.46856, 0.44835, 0.50352, 0.63083, 0.57358, 0.62929,
          0.66182, 0.56994, 0.6, 0.59799, 0.45786, 0.65811, 0.58495, 0.66524,
          0.2656, 0.6167, 0.56582, 0.72991, 0.6374, 0.65287, 0.66425, 0.57641,
          0.65276, 0.52253, 0.63675, 0.582, 0.59926, 0.58589, 0.67108, 0.64503
        ]
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.810691941168, 37.658207046361],
              [126.81069711814, 37.658354003459],
              [126.810693579722, 37.658408146222],
              [126.810711909219, 37.658464036206],
              [126.810765718498, 37.658516830157],
              [126.810770129534, 37.658520260987],
              [126.810770883725, 37.658624144408],
              [126.810783729551, 37.658697234098],
              [126.81080546987, 37.658748895006],
              [126.810836302537, 37.658790389611],
              [126.810909045155, 37.658884748955],
              [126.811001949488, 37.659027793362],
              [126.811094319517, 37.659158313304],
              [126.811113512183, 37.659231413197],
              [126.811075912413, 37.659354470466],
              [126.811109179708, 37.659361389582],
              [126.811133317857, 37.659401765103],
              [126.811207450195, 37.659462646341],
              [126.811438289226, 37.659619328324],
              [126.81156883891, 37.659679795681],
              [126.81201020495, 37.659772972403],
              [126.812448659956, 37.659700616055],
              [126.812876308812, 37.659804894568],
              [126.813169051751, 37.659877097805],
              [126.813233325361, 37.65989353505],
              [126.813457635141, 37.660026029551],
              [126.814083508685, 37.660394091039],
              [126.814321371934, 37.66051012663],
              [126.814382095583, 37.661119424767],
              [126.814434396122, 37.661418459496],
              [126.814539091781, 37.661649445693],
              [126.814709411536, 37.661847470034],
              [126.814720986734, 37.662004248598],
              [126.814742153897, 37.662290674285],
              [126.814744329401, 37.662322545131],
              [126.8147602191, 37.662385791536],
              [126.814811198684, 37.662588726212],
              [126.814847394984, 37.662635111506],
              [126.81490823617, 37.66272444009],
              [126.814946906678, 37.662778532582],
              [126.815039427064, 37.662907923387],
              [126.815437511296, 37.663182645877],
              [126.815524859802, 37.663250491715],
              [126.815771255774, 37.663207921589],
              [126.816340667329, 37.66327108304],
              [126.816551233729, 37.66336706064],
              [126.817251668447, 37.663726293411],
              [126.817292726661, 37.664039598614],
              [126.817300279869, 37.664097209574],
              [126.817342313945, 37.664102861239],
              [126.817412257619, 37.664280461977],
              [126.817972392889, 37.664225384491],
              [126.818048567411, 37.664220547645],
              [126.818156941405, 37.664257025341],
              [126.818740601371, 37.664036922631],
              [126.819406277656, 37.663785139341],
              [126.820545560265, 37.663728779976],
              [126.821735171066, 37.6634339088],
              [126.822373876395, 37.663315952135],
              [126.822727022157, 37.663158934844],
              [126.822761126109, 37.663200944823],
              [126.822772321158, 37.663235081618],
              [126.822782165999, 37.663265125951],
              [126.822804808196, 37.663303902077],
              [126.822809928678, 37.663314316068],
              [126.822829744105, 37.663350339939],
              [126.822846323012, 37.663384331717],
              [126.822858504303, 37.663408946718],
              [126.822842417829, 37.663448484057],
              [126.822805559235, 37.663539021009],
              [126.822607873483, 37.66365434315],
              [126.82205997276, 37.663973950752],
              [126.821961075198, 37.664096215379],
              [126.821941159261, 37.664120826658],
              [126.821945903258, 37.664132240191],
              [126.822030788225, 37.664336520719],
              [126.821938217184, 37.664612870227],
              [126.821855596525, 37.664719942107],
              [126.821883831055, 37.664954255928],
              [126.821889761504, 37.665028324881],
              [126.821920203188, 37.665273363612],
              [126.821811862302, 37.665461537844],
              [126.821732142882, 37.665568244642],
              [126.821428096933, 37.665770796902],
              [126.821257341766, 37.665877661672],
              [126.821049801218, 37.666015130001],
              [126.820738066439, 37.666540414911],
              [126.820607964595, 37.666684352952],
              [126.820547964603, 37.666753257352],
              [126.820532753177, 37.666767694614],
              [126.820493821462, 37.666804637794],
              [126.820487257037, 37.666833449814],
              [126.820410191001, 37.66717151134],
              [126.820382776803, 37.667296136731],
              [126.820365149976, 37.667376305171],
              [126.820737728845, 37.667506751871],
              [126.820945913193, 37.667685625356],
              [126.82096745688, 37.667742897076],
              [126.821066774631, 37.668006862618],
              [126.821015483917, 37.668293599501],
              [126.820987102248, 37.668434179739],
              [126.82097312671, 37.668509155236],
              [126.820928099165, 37.668624482767],
              [126.820898797835, 37.668695434474],
              [126.820868675133, 37.668773223289],
              [126.820804416974, 37.66895224704],
              [126.820800844315, 37.668962548682],
              [126.820798629908, 37.668968951198],
              [126.820796381302, 37.668975434749],
              [126.820808856932, 37.668990941565],
              [126.820792323809, 37.669092194458],
              [126.820738869034, 37.669419895029],
              [126.820732715056, 37.66948036782],
              [126.820732516466, 37.669496819257],
              [126.820790020793, 37.669569841027],
              [126.820845830275, 37.66964070685],
              [126.820877718221, 37.669681200302],
              [126.820968379079, 37.669874777699],
              [126.820862266594, 37.670304198434],
              [126.820691185757, 37.670554676637],
              [126.820682905035, 37.670604028166],
              [126.820663783202, 37.670963765731],
              [126.820957588679, 37.671568317113],
              [126.821651184722, 37.672077794892],
              [126.822309056103, 37.672259918494],
              [126.822326078492, 37.672378890569],
              [126.822960257821, 37.672705535357],
              [126.823129693017, 37.672743659547],
              [126.823324028967, 37.672626385577],
              [126.824004580807, 37.672707967582],
              [126.824320355262, 37.672944785134],
              [126.82460990701, 37.673160533842],
              [126.825290591872, 37.673548015322],
              [126.82573786009, 37.673511751927],
              [126.825824563859, 37.673597734638],
              [126.826015063904, 37.673755913413],
              [126.826314560675, 37.673828733667],
              [126.826656496187, 37.673877749362],
              [126.827162732144, 37.674073694135],
              [126.827117371562, 37.674363018938],
              [126.82743649814, 37.674817048121],
              [126.827243481146, 37.675291556685],
              [126.826959894697, 37.675658120733],
              [126.827077046558, 37.675972913019],
              [126.827421732334, 37.67627560785],
              [126.828032838312, 37.676808531424],
              [126.828216459222, 37.676815594683],
              [126.828573893171, 37.676806442903],
              [126.828768434129, 37.67684610041],
              [126.829348282107, 37.676980381992],
              [126.829511635755, 37.676830158249],
              [126.829688739319, 37.676763294086],
              [126.829754140999, 37.676765425541],
              [126.830383221482, 37.676785927083],
              [126.831101089855, 37.676843096958],
              [126.831640506216, 37.676813962885],
              [126.831897467444, 37.677020925258],
              [126.832228545496, 37.677236732883],
              [126.832539506893, 37.677381523288],
              [126.832873088519, 37.677539481366],
              [126.833069357268, 37.677649094606],
              [126.833328551562, 37.677793845018],
              [126.833553668638, 37.677799031059],
              [126.833944691254, 37.677473841378],
              [126.833964090474, 37.677462084244],
              [126.834321313993, 37.677451806557],
              [126.834449487411, 37.677451699872],
              [126.834870935445, 37.67726059659],
              [126.835083708661, 37.67705164661],
              [126.835100124114, 37.677015495803],
              [126.835125759961, 37.676962095405],
              [126.835137630052, 37.676834444613],
              [126.835168819119, 37.67658354125],
              [126.835195466441, 37.676320675582],
              [126.835223365697, 37.676218004259],
              [126.835248406904, 37.67596865086],
              [126.835269746586, 37.675854077458],
              [126.835241393898, 37.675555545863],
              [126.835242431011, 37.675497164415],
              [126.835218328278, 37.675427665582],
              [126.835220317613, 37.675394665824],
              [126.835105309188, 37.675150682878],
              [126.834979154285, 37.674918892229],
              [126.835111123454, 37.674795726559],
              [126.835277213054, 37.674626127743],
              [126.835316479007, 37.674590351463],
              [126.835642734722, 37.674273263506],
              [126.835731308022, 37.674110393364],
              [126.835795769232, 37.674085914471],
              [126.835892151603, 37.674045587241],
              [126.835977945419, 37.673949745253],
              [126.836147642873, 37.673710532207],
              [126.836349892432, 37.673446335515],
              [126.836409792638, 37.673198336963],
              [126.836534499878, 37.673081223031],
              [126.836613382608, 37.673008624963],
              [126.836689751034, 37.672945267293],
              [126.837199202361, 37.672709968693],
              [126.837276048316, 37.672645584197],
              [126.837368954826, 37.672447860228],
              [126.837653449687, 37.672299145049],
              [126.837808709243, 37.672260889165],
              [126.83796261814, 37.672218216443],
              [126.838163838054, 37.672051815582],
              [126.838426459431, 37.671952171174],
              [126.838506183325, 37.671882275901],
              [126.83876369488, 37.671682525747],
              [126.839130813386, 37.671534551638],
              [126.839360203399, 37.671338635625],
              [126.839557457902, 37.671168893311],
              [126.839715544208, 37.671080094282],
              [126.839858185009, 37.671057315089],
              [126.840014130803, 37.671066808725],
              [126.84025761245, 37.671114172571],
              [126.840455664852, 37.671150932523],
              [126.840503930237, 37.671160548717],
              [126.84047986763, 37.671123305742],
              [126.840369268095, 37.671005577899],
              [126.840124184203, 37.670442045442],
              [126.839688794757, 37.669973186878],
              [126.839670961781, 37.669953989732],
              [126.839502877878, 37.669772951969],
              [126.839266049921, 37.669342403394],
              [126.839312420239, 37.668868078451],
              [126.839948730298, 37.668483452889],
              [126.839993948712, 37.668454719754],
              [126.84035029506, 37.668373732287],
              [126.840893370802, 37.668104875206],
              [126.841401768607, 37.667852996913],
              [126.841708528298, 37.6672356884],
              [126.841495482121, 37.666954728709],
              [126.841455947958, 37.666754479038],
              [126.841294342482, 37.666418755579],
              [126.840835566214, 37.666143587239],
              [126.840767467185, 37.665667303524],
              [126.840715245857, 37.665302239401],
              [126.8405321268, 37.664818221452],
              [126.8404799153, 37.664680247325],
              [126.840117121299, 37.663952279164],
              [126.840085258612, 37.663885951003],
              [126.840350782629, 37.663729292561],
              [126.840366772127, 37.663719863202],
              [126.840436222634, 37.663678873705],
              [126.840297245197, 37.663576054039],
              [126.83967085572, 37.663112627846],
              [126.839615398464, 37.662988379672],
              [126.839565171102, 37.662877500077],
              [126.839504003094, 37.662742369263],
              [126.839467964506, 37.662663268402],
              [126.839399960019, 37.66251376663],
              [126.839372560566, 37.662454210702],
              [126.83932022629, 37.662358779782],
              [126.839246995004, 37.66219488219],
              [126.839212865935, 37.662118703023],
              [126.83920737609, 37.662099937213],
              [126.839153114463, 37.661921767214],
              [126.83914868136, 37.661922571988],
              [126.839053194942, 37.661747003058],
              [126.83897965902, 37.661686987053],
              [126.838890621953, 37.66161532708],
              [126.838745928181, 37.661496469365],
              [126.838766143818, 37.661373244146],
              [126.838769069781, 37.661367148595],
              [126.838724923666, 37.661332165965],
              [126.838599094218, 37.661231786025],
              [126.837848299312, 37.660643141064],
              [126.837658445323, 37.660493847527],
              [126.837223334479, 37.660153007821],
              [126.837026700776, 37.660083043518],
              [126.836949672152, 37.660035734095],
              [126.837112682291, 37.659966054878],
              [126.837101913083, 37.659957039146],
              [126.837631536812, 37.659686042591],
              [126.838789592977, 37.659093543938],
              [126.838732813689, 37.659042200213],
              [126.838757183948, 37.659029782387],
              [126.838877810345, 37.658968069903],
              [126.837142073009, 37.657419683597],
              [126.834417700977, 37.654989263279],
              [126.833818638169, 37.655186807675],
              [126.833690668959, 37.655228998496],
              [126.83361225476, 37.655171098448],
              [126.833531605303, 37.655134611314],
              [126.833369092683, 37.655187888631],
              [126.833330023839, 37.655200698846],
              [126.833056292061, 37.655290450715],
              [126.832896601984, 37.655343082708],
              [126.832807745702, 37.655372750874],
              [126.832797266728, 37.655376222657],
              [126.832771522939, 37.655384745087],
              [126.832688008663, 37.655412402622],
              [126.832546554787, 37.65545875342],
              [126.832542165593, 37.655460323837],
              [126.832443669476, 37.65549308628],
              [126.832379774235, 37.655514473906],
              [126.83224947835, 37.655557614856],
              [126.832021349926, 37.655630518462],
              [126.831938145581, 37.655656193767],
              [126.831703298623, 37.655733105441],
              [126.831698241795, 37.655739206764],
              [126.831688549819, 37.655735435751],
              [126.831708498347, 37.655718994666],
              [126.831972894678, 37.655534864515],
              [126.832059991623, 37.655471759312],
              [126.832206423701, 37.655369204481],
              [126.8323776273, 37.655251936043],
              [126.832437183083, 37.655210090091],
              [126.832522609568, 37.655148675978],
              [126.832667903352, 37.655047794749],
              [126.832688811114, 37.655032949592],
              [126.832692499311, 37.655030657387],
              [126.832921009024, 37.654868205215],
              [126.832940210002, 37.654856384847],
              [126.833080127806, 37.654762000454],
              [126.832911229497, 37.65450723402],
              [126.832907751012, 37.65450199439],
              [126.832831481331, 37.65438338024],
              [126.832796513245, 37.654321874793],
              [126.832719069119, 37.654196600689],
              [126.832805243398, 37.654119600578],
              [126.832791708275, 37.654091533897],
              [126.832818633234, 37.654101690342],
              [126.832804736551, 37.65403307931],
              [126.83279835453, 37.653998905244],
              [126.832743160127, 37.653846660774],
              [126.832739684979, 37.653829951741],
              [126.832727983677, 37.653767749765],
              [126.832719148346, 37.653741068288],
              [126.832684975431, 37.653643849324],
              [126.832657758439, 37.653562560519],
              [126.832641576609, 37.65354736495],
              [126.832527226399, 37.653156412648],
              [126.832494931812, 37.653095649729],
              [126.832493946245, 37.653060510321],
              [126.832451159166, 37.652859396602],
              [126.832413986241, 37.652761200194],
              [126.832381630917, 37.652672371802],
              [126.832360492245, 37.652654394086],
              [126.832327547224, 37.65255581629],
              [126.832296299268, 37.652523399464],
              [126.832248480755, 37.652442504455],
              [126.832134751938, 37.652248595694],
              [126.832103158726, 37.65219366297],
              [126.832046689975, 37.652064850643],
              [126.83199867232, 37.651957151252],
              [126.8319490305, 37.651846160948],
              [126.831836882363, 37.651496151316],
              [126.831819169645, 37.651436886766],
              [126.831758506891, 37.651224457817],
              [126.831689244031, 37.650987005396],
              [126.831642449058, 37.650820636137],
              [126.831571435403, 37.6505810458],
              [126.831477262376, 37.650236206212],
              [126.83140137297, 37.649973642877],
              [126.831366967478, 37.649880531581],
              [126.831335472436, 37.64978822634],
              [126.831303561893, 37.649709200858],
              [126.831217264151, 37.649573849014],
              [126.831177349758, 37.649551167914],
              [126.830994824726, 37.649500431626],
              [126.830940908355, 37.649486073232],
              [126.830765786247, 37.64952800354],
              [126.830680513439, 37.649504725038],
              [126.830657813075, 37.649466634989],
              [126.830654054937, 37.649450087649],
              [126.830613514922, 37.64936921149],
              [126.830600614039, 37.649223342998],
              [126.8305655458, 37.649039295107],
              [126.830531237701, 37.648929056163],
              [126.830526489889, 37.64892409392],
              [126.83051507248, 37.648912184503],
              [126.830493028183, 37.64888953808],
              [126.830465671181, 37.648861207807],
              [126.830420260483, 37.648769891223],
              [126.830360326088, 37.648652578443],
              [126.830301038136, 37.648658510927],
              [126.830104219253, 37.648690056569],
              [126.829953557196, 37.648734498895],
              [126.829653155351, 37.648791724056],
              [126.82930810285, 37.648885264648],
              [126.829296412172, 37.648888734355],
              [126.829146099742, 37.648913895296],
              [126.828831019012, 37.648987260401],
              [126.828740493021, 37.649011445171],
              [126.828727237977, 37.648985820054],
              [126.828708778258, 37.648956844694],
              [126.82868616354, 37.648921421275],
              [126.828654819712, 37.648873038053],
              [126.828639327914, 37.648849364763],
              [126.828460037041, 37.64887133796],
              [126.828390493944, 37.648891877263],
              [126.828267005216, 37.648906165638],
              [126.828241220056, 37.648908848713],
              [126.828168705659, 37.648925536358],
              [126.828095520374, 37.648943214044],
              [126.827952898593, 37.648964735821],
              [126.82785662475, 37.648970558599],
              [126.827830302489, 37.64897516888],
              [126.827506490321, 37.649026263512],
              [126.827536730976, 37.648838049422],
              [126.827469837376, 37.648660278513],
              [126.827415443236, 37.648516195439],
              [126.827158009223, 37.648107898861],
              [126.827152113442, 37.648099709295],
              [126.827125905452, 37.648055558824],
              [126.826985854463, 37.647807106085],
              [126.826732041095, 37.647214537458],
              [126.826712389895, 37.647181974133],
              [126.826684692083, 37.647135559903],
              [126.826583298946, 37.646957737313],
              [126.826458698853, 37.646746715304],
              [126.826276751642, 37.646328915269],
              [126.826269977309, 37.64624624073],
              [126.826234310847, 37.645839703749],
              [126.826111396125, 37.645472121356],
              [126.82607169634, 37.645383874859],
              [126.826057371709, 37.645351184189],
              [126.826043239736, 37.645318484795],
              [126.826027141027, 37.64528373726],
              [126.825886062511, 37.644974952265],
              [126.825793358416, 37.644838361497],
              [126.825716592808, 37.644722796142],
              [126.825581703557, 37.644514749039],
              [126.825556494194, 37.644475681186],
              [126.825524881937, 37.644427458875],
              [126.825514796506, 37.644408712549],
              [126.825476404005, 37.64433840621],
              [126.825358511199, 37.644125951399],
              [126.825285087116, 37.644150501447],
              [126.825252248566, 37.644161047863],
              [126.825146245117, 37.644196703223],
              [126.825152686903, 37.644208560681],
              [126.825081109494, 37.64425709733],
              [126.825068712214, 37.644343959859],
              [126.825050845054, 37.644372124659],
              [126.825018607421, 37.644377662485],
              [126.824943373065, 37.644391370875],
              [126.824936481053, 37.644392612916],
              [126.824923049176, 37.644394719115],
              [126.824885518778, 37.644400807586],
              [126.824786801567, 37.644440780192],
              [126.82469789804, 37.6444753886],
              [126.824521693514, 37.644499459738],
              [126.824508047413, 37.64450110607],
              [126.824228007154, 37.644535048793],
              [126.824132194514, 37.644525507603],
              [126.824088834199, 37.644519739228],
              [126.8237432462, 37.644553338944],
              [126.823709540712, 37.644566154081],
              [126.82356779466, 37.64461779151],
              [126.823495077213, 37.644659153708],
              [126.82335818971, 37.644735358805],
              [126.823301130154, 37.644767653684],
              [126.823195158122, 37.644827336347],
              [126.823136998012, 37.644850709827],
              [126.82308260492, 37.644900316368],
              [126.82303905206, 37.645036532027],
              [126.822949013882, 37.645127961911],
              [126.822927857514, 37.645152346321],
              [126.82274151733, 37.645248891927],
              [126.822727288907, 37.645252321102],
              [126.822716970602, 37.645255341751],
              [126.822691808806, 37.645253672851],
              [126.822649124832, 37.645249238416],
              [126.822425422088, 37.645380540556],
              [126.822269497392, 37.645531739691],
              [126.822230834575, 37.645556079354],
              [126.822206303545, 37.645570079269],
              [126.822172593156, 37.645589344939],
              [126.822089007214, 37.645640312156],
              [126.821995798273, 37.645697211108],
              [126.821987239838, 37.645703414814],
              [126.821824157249, 37.645843205121],
              [126.821813631031, 37.645866632464],
              [126.821707895992, 37.646104193673],
              [126.821628078951, 37.646283438137],
              [126.821428065349, 37.646433145358],
              [126.821307395745, 37.646379172885],
              [126.82130668052, 37.646370360256],
              [126.821259457275, 37.64634978192],
              [126.821146064129, 37.646356023558],
              [126.821083494399, 37.646370037153],
              [126.821026712232, 37.646357012295],
              [126.821026651239, 37.646340073869],
              [126.82101862696, 37.6463361874],
              [126.820562698171, 37.646272096375],
              [126.820360638218, 37.646237098743],
              [126.819994407625, 37.646149321436],
              [126.819887415126, 37.646118974101],
              [126.819624529084, 37.64607558357],
              [126.819584815792, 37.646070224602],
              [126.819570418871, 37.646068283323],
              [126.819488094733, 37.646079490603],
              [126.819343331873, 37.646092772779],
              [126.819073554731, 37.645999357248],
              [126.818950452576, 37.646018970253],
              [126.818880212118, 37.64604205264],
              [126.81871680769, 37.646093182184],
              [126.818504362422, 37.646116665598],
              [126.818351722771, 37.646125952028],
              [126.818180374473, 37.646080393862],
              [126.818104049016, 37.646085032379],
              [126.81798941168, 37.646090431142],
              [126.817851522932, 37.646082125806],
              [126.817708235369, 37.646075974239],
              [126.817625734114, 37.646048600187],
              [126.817448366445, 37.645999770046],
              [126.817428921026, 37.646000379398],
              [126.817330217481, 37.64600481131],
              [126.817254701953, 37.646007261172],
              [126.816824047347, 37.645974837419],
              [126.816718099226, 37.64596720243],
              [126.816602497176, 37.645959561224],
              [126.81650047044, 37.646056661535],
              [126.816454724305, 37.646117261452],
              [126.81637790879, 37.646264504423],
              [126.816331815924, 37.646391649765],
              [126.816227393446, 37.646554201909],
              [126.816176636261, 37.646643048419],
              [126.816162971665, 37.6466701463],
              [126.816102762767, 37.646777808304],
              [126.816066422996, 37.64690127486],
              [126.816329299432, 37.647222705165],
              [126.816311219686, 37.647339443137],
              [126.816293601629, 37.647452758123],
              [126.816111646286, 37.64758014046],
              [126.81598769366, 37.647666979773],
              [126.815519783747, 37.648093755581],
              [126.815627204057, 37.648184743145],
              [126.815691430147, 37.648239173104],
              [126.815666779377, 37.648264001178],
              [126.815483223873, 37.64844111381],
              [126.81549464685, 37.648586098657],
              [126.815673056472, 37.648905405967],
              [126.815749190665, 37.648910535293],
              [126.81647164996, 37.648991316839],
              [126.816858270374, 37.649245952424],
              [126.816922723962, 37.649291606569],
              [126.817088884059, 37.649407920981],
              [126.817791075344, 37.649232020136],
              [126.817802330976, 37.649400808234],
              [126.817828139886, 37.649742975509],
              [126.817620626071, 37.649986410893],
              [126.817519752169, 37.650032491624],
              [126.817395008869, 37.65008977017],
              [126.81704542406, 37.650247858641],
              [126.81706179679, 37.650408798284],
              [126.81702308979, 37.65046303048],
              [126.816928527981, 37.65059554204],
              [126.816607306246, 37.650628915643],
              [126.81654398207, 37.650803695507],
              [126.816374050498, 37.650877398945],
              [126.816327289057, 37.6512269937],
              [126.816232447492, 37.651744886982],
              [126.816181434332, 37.651857789022],
              [126.816049907657, 37.65214859699],
              [126.815953796985, 37.6526879762],
              [126.815946942215, 37.652723977353],
              [126.815567187221, 37.653032161221],
              [126.815619756414, 37.6532185655],
              [126.815749196202, 37.653662318889],
              [126.81575028888, 37.653951533241],
              [126.815724334173, 37.654362516649],
              [126.815738724663, 37.654408579074],
              [126.815832156127, 37.654705506946],
              [126.815925736515, 37.655170556663],
              [126.816113821057, 37.655320323948],
              [126.816765809505, 37.655671194905],
              [126.816799049217, 37.65569337487],
              [126.816975453088, 37.655811083859],
              [126.816986421833, 37.655958049815],
              [126.8168455943, 37.656126942189],
              [126.816892437463, 37.656248106358],
              [126.816855808831, 37.656258049833],
              [126.816830382111, 37.656320717789],
              [126.816640257461, 37.656397993807],
              [126.816414282874, 37.65701584241],
              [126.816361344671, 37.656907723606],
              [126.816300435092, 37.656910997631],
              [126.816147534703, 37.656966023181],
              [126.816105155818, 37.657027772368],
              [126.816130479968, 37.657093303927],
              [126.81605804492, 37.657105695584],
              [126.816019044876, 37.657112031176],
              [126.815927298384, 37.657092786237],
              [126.815856185555, 37.657070240101],
              [126.81584350315, 37.65706616576],
              [126.815716407917, 37.657038774274],
              [126.815628553124, 37.657054114592],
              [126.815456799455, 37.657039004792],
              [126.815456626511, 37.657012939358],
              [126.815456494959, 37.656974827974],
              [126.815366535491, 37.656920807843],
              [126.815206751334, 37.656963892421],
              [126.815236418925, 37.657018808555],
              [126.815193108954, 37.657071897589],
              [126.815171718572, 37.657105920597],
              [126.815064869553, 37.657275558223],
              [126.815027819747, 37.657322197109],
              [126.814849561889, 37.657365432188],
              [126.814595238077, 37.657427052487],
              [126.814368039005, 37.657138615039],
              [126.814362616544, 37.657131759039],
              [126.814321991783, 37.657198366612],
              [126.814008201447, 37.657108428633],
              [126.813813409263, 37.65705259186],
              [126.813665338092, 37.656944239356],
              [126.813489282987, 37.656833049046],
              [126.813321034749, 37.65677196505],
              [126.813296907205, 37.656763169095],
              [126.813108750018, 37.65666538341],
              [126.812763615873, 37.656679067067],
              [126.812754574613, 37.656678223705],
              [126.812402101865, 37.656645242157],
              [126.812254443105, 37.656625562346],
              [126.812200645592, 37.656724474985],
              [126.811997906857, 37.657138678304],
              [126.811983619176, 37.65716433309],
              [126.811882194486, 37.65737277245],
              [126.811867097577, 37.657404660656],
              [126.811808901431, 37.657526027295],
              [126.811395522454, 37.657586664021],
              [126.811257086921, 37.65761139779],
              [126.811012827564, 37.658185662339],
              [126.810886978426, 37.658207181501],
              [126.81075700527, 37.658201655655],
              [126.810737598453, 37.658168198166],
              [126.810691941168, 37.658207046361]
            ]
          ],
          [
            [
              [126.830407085771, 37.648649745132],
              [126.830512469552, 37.648811902227],
              [126.830530094193, 37.648834722436],
              [126.830553367379, 37.648865028921],
              [126.83061763778, 37.648954588844],
              [126.830625140616, 37.648993521827],
              [126.830632476283, 37.64900650649],
              [126.830640206585, 37.649045079411],
              [126.830671391552, 37.649202984377],
              [126.830673009395, 37.649327771732],
              [126.830694105661, 37.649413151623],
              [126.830723093482, 37.649442006766],
              [126.830749862277, 37.649450226367],
              [126.83078475005, 37.649460935393],
              [126.830814689455, 37.649450320185],
              [126.830913666525, 37.649427038012],
              [126.830946149702, 37.649418949179],
              [126.831006870709, 37.649450598101],
              [126.831153681656, 37.649500075497],
              [126.831042829709, 37.649359516466],
              [126.83088104751, 37.649157698475],
              [126.830808945302, 37.649063757637],
              [126.830589334725, 37.648790201014],
              [126.8305404111, 37.648728584527],
              [126.830498273438, 37.648662725255],
              [126.830460252294, 37.648605368129],
              [126.830407085771, 37.648649745132]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41285106',
      properties: {
        rgnCode: '41285106',
        colCode: '41280',
        rgnKo: ['백석동'],
        rgnSize: 1,
        rgnBbox: [
          126.776291989205, 37.625622623711, 126.803300176554, 37.660854667985
        ],
        rgnCenter: [126.789336505037, 37.641671863481],
        rgnArea: 3946372,
        predVal: [
          0.52661, 0.5355, 0.5124, 0.57545, 0.72095, 0.65552, 0.71919, 0.80744,
          0.74031, 0.7906, 0.75564, 0.52326, 0.26917, 0.46534, 0.51403, 0.30354,
          0.34912, 0.59549, 0.83418, 0.6007, 0.63168, 0.75083, 0.65875, 0.74601,
          0.59717, 0.72772, 0.66514, 0.68487, 0.66959, 0.69775, 0.50469
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.781038796625, 37.648303296675],
            [126.781215344227, 37.648454719861],
            [126.783268093911, 37.650169575154],
            [126.783386343864, 37.650264756512],
            [126.783508910641, 37.650356341832],
            [126.783635794767, 37.650444150907],
            [126.783766769606, 37.650528183303],
            [126.783901495732, 37.650608258188],
            [126.784039973398, 37.650684285451],
            [126.784181976485, 37.650756084468],
            [126.784327165031, 37.650823654601],
            [126.784475426229, 37.650886815435],
            [126.784626646743, 37.65094556675],
            [126.785438373188, 37.651245729127],
            [126.790978692119, 37.653292037457],
            [126.795689042769, 37.655032831853],
            [126.796021874003, 37.655155854382],
            [126.796107119748, 37.655187356719],
            [126.795288866779, 37.65660135822],
            [126.795185306183, 37.656758306859],
            [126.795042272057, 37.657009968695],
            [126.794950788562, 37.657143242661],
            [126.794855588907, 37.657308764879],
            [126.794249761414, 37.658362651297],
            [126.793981186186, 37.658268486929],
            [126.79395947105, 37.658260871542],
            [126.793834798504, 37.658488814501],
            [126.793827121102, 37.658502838142],
            [126.793755347182, 37.658634055584],
            [126.793906818406, 37.658692435007],
            [126.794004527728, 37.658730069379],
            [126.794033950357, 37.658741401312],
            [126.793914492735, 37.658948144608],
            [126.79319010365, 37.660201562179],
            [126.792831220429, 37.660795930129],
            [126.79308287985, 37.660854667985],
            [126.793086976912, 37.660848548611],
            [126.793109740427, 37.66081381129],
            [126.793246997213, 37.660566196154],
            [126.793263392136, 37.660539286019],
            [126.793348685866, 37.66039302852],
            [126.793441924042, 37.660202006629],
            [126.793555110873, 37.66000993866],
            [126.793648596591, 37.659851712434],
            [126.793796637704, 37.65959636725],
            [126.794014162415, 37.659254740822],
            [126.794093435736, 37.659112436439],
            [126.794111773664, 37.65907940298],
            [126.79435844596, 37.658647189255],
            [126.795566399833, 37.656601843067],
            [126.795822916907, 37.656168832387],
            [126.795935644606, 37.655974418655],
            [126.796125556664, 37.655652561389],
            [126.796305915949, 37.655342309738],
            [126.796483739754, 37.655046919448],
            [126.796650716131, 37.654739527089],
            [126.796804789801, 37.65446706986],
            [126.797028922307, 37.654102113789],
            [126.79703825665, 37.654086813417],
            [126.797045086804, 37.654075563085],
            [126.797367481817, 37.653539411416],
            [126.79769078317, 37.653001638624],
            [126.798314401931, 37.651913707288],
            [126.798674765914, 37.651284636696],
            [126.798945720183, 37.650821280782],
            [126.799062748622, 37.650622816866],
            [126.799193551426, 37.650400861023],
            [126.79927029608, 37.650264585114],
            [126.799307516095, 37.650203472764],
            [126.799462056106, 37.64991911977],
            [126.799504606712, 37.649856124436],
            [126.799647017357, 37.649614816986],
            [126.799699612679, 37.649524719437],
            [126.799776660594, 37.649401327629],
            [126.799833905381, 37.649309796408],
            [126.800090209809, 37.648853079859],
            [126.800122721729, 37.648856468969],
            [126.800276247675, 37.648567788428],
            [126.800389315709, 37.648364631288],
            [126.800472429732, 37.648217914098],
            [126.800596537233, 37.647996485773],
            [126.800797350167, 37.647651033527],
            [126.800923362175, 37.647436996068],
            [126.801041869759, 37.647232315851],
            [126.801160951713, 37.647024483071],
            [126.801319632295, 37.646754369907],
            [126.801571176983, 37.646333500643],
            [126.801589072639, 37.646336233895],
            [126.802181810792, 37.645313549102],
            [126.802203780574, 37.64527565517],
            [126.802214024744, 37.645258283657],
            [126.802299247245, 37.645125533769],
            [126.802373247434, 37.644994476727],
            [126.802455658786, 37.644853433003],
            [126.802620594821, 37.644570985172],
            [126.802966747659, 37.643975392918],
            [126.803244585078, 37.64350266793],
            [126.803300176554, 37.643411726791],
            [126.802860909964, 37.643553468223],
            [126.802852241898, 37.643574806742],
            [126.802173550906, 37.644757984897],
            [126.802087500344, 37.644908104103],
            [126.802035624327, 37.644884059474],
            [126.8019825827, 37.644859481276],
            [126.801676713896, 37.644717843849],
            [126.80158595391, 37.644675803748],
            [126.801351438286, 37.644567171961],
            [126.801136922236, 37.644458474569],
            [126.80081451021, 37.644305562632],
            [126.80053731355, 37.644230823373],
            [126.800428268638, 37.644213428941],
            [126.800157117578, 37.644170188176],
            [126.80013815725, 37.644163317413],
            [126.800055106563, 37.644136984279],
            [126.800014196577, 37.644126724386],
            [126.799928651442, 37.644113631237],
            [126.799528511481, 37.644049968698],
            [126.799297813571, 37.644014489537],
            [126.799086103306, 37.644109458664],
            [126.798512022544, 37.6440475213],
            [126.798144281071, 37.643966277725],
            [126.797878293801, 37.643862684296],
            [126.797779417325, 37.643723619253],
            [126.79772124057, 37.643605328845],
            [126.797638539889, 37.643434883621],
            [126.797597482581, 37.643346138678],
            [126.797597872581, 37.643249059265],
            [126.797739703053, 37.64306012653],
            [126.797765232223, 37.643038817505],
            [126.797726473015, 37.64295082438],
            [126.797650204743, 37.64277769634],
            [126.797615758554, 37.64269951324],
            [126.797611040472, 37.642688765459],
            [126.797564963235, 37.642583803271],
            [126.797434714771, 37.642635393141],
            [126.797266541274, 37.642646274217],
            [126.797180317274, 37.642637763865],
            [126.797013490341, 37.642269002904],
            [126.797057436725, 37.64225398773],
            [126.797197392821, 37.642161790102],
            [126.797275826617, 37.64211030001],
            [126.797333530964, 37.642065188938],
            [126.797304922975, 37.642006819283],
            [126.797231413284, 37.64185728326],
            [126.797226827326, 37.641847977252],
            [126.797202024853, 37.641843988031],
            [126.797194273059, 37.641828117438],
            [126.797181721501, 37.641802408889],
            [126.797027745859, 37.641487043947],
            [126.796997685425, 37.641442033162],
            [126.796975207796, 37.641408387808],
            [126.796974711318, 37.641407639137],
            [126.796866430879, 37.641245537047],
            [126.796855047223, 37.641236102106],
            [126.796852379358, 37.641233890088],
            [126.796820161514, 37.641207174329],
            [126.796818024958, 37.64120540471],
            [126.796715696695, 37.641120562453],
            [126.79612016282, 37.640839062506],
            [126.795903506385, 37.640736649645],
            [126.795882685159, 37.640727126085],
            [126.795520294814, 37.640561372116],
            [126.795405272282, 37.640540547904],
            [126.795224275917, 37.640429078208],
            [126.795167931312, 37.640326664878],
            [126.795157432105, 37.640231080021],
            [126.7951559586, 37.640161657264],
            [126.795124461594, 37.64007544186],
            [126.795065340617, 37.639625319042],
            [126.79506393996, 37.639611495622],
            [126.79500160993, 37.639522820617],
            [126.794984845492, 37.639500942608],
            [126.794887437153, 37.639363526408],
            [126.794864918176, 37.639333106062],
            [126.794803755536, 37.639289436739],
            [126.794727729925, 37.639232848369],
            [126.794660923033, 37.639198115769],
            [126.794634683188, 37.638936516459],
            [126.794629188096, 37.638887520763],
            [126.794912777959, 37.638894531745],
            [126.794987116798, 37.638856406456],
            [126.794979462284, 37.638781116517],
            [126.794973895755, 37.638733291979],
            [126.794916948164, 37.638243646972],
            [126.794916549849, 37.638240195536],
            [126.79490560345, 37.638146754163],
            [126.794888761219, 37.638001712574],
            [126.794828328073, 37.637948665371],
            [126.794736094682, 37.63787029904],
            [126.79470478894, 37.637842313912],
            [126.794634273119, 37.637735965209],
            [126.794567140392, 37.637635388643],
            [126.794530771739, 37.637472158071],
            [126.794629345934, 37.637143024167],
            [126.794608622366, 37.636976127035],
            [126.794542911535, 37.6368124846],
            [126.794436907012, 37.636553087732],
            [126.794460790915, 37.63631581249],
            [126.794533555804, 37.63606006288],
            [126.794415035079, 37.635820465485],
            [126.794330720877, 37.635584081449],
            [126.794311549396, 37.635511429083],
            [126.794315207864, 37.635417914152],
            [126.794324559035, 37.635232689812],
            [126.79437756846, 37.635073670435],
            [126.794457709558, 37.634976505635],
            [126.794490548236, 37.634941515271],
            [126.794534961488, 37.634932439268],
            [126.794530051265, 37.634885102357],
            [126.794521775986, 37.63480518924],
            [126.794318173754, 37.632839990909],
            [126.794311355158, 37.632774189606],
            [126.794309874385, 37.63276075344],
            [126.794286398933, 37.632553361395],
            [126.794224490029, 37.632005530752],
            [126.794219415744, 37.631964374219],
            [126.794214064278, 37.631921045844],
            [126.794206210898, 37.631909715775],
            [126.794202210933, 37.631881490148],
            [126.794195335496, 37.631852529677],
            [126.794200980537, 37.631724916202],
            [126.794201049519, 37.631720474507],
            [126.794200869895, 37.63171580713],
            [126.794112004996, 37.631732814575],
            [126.794099528565, 37.631640298342],
            [126.794097094491, 37.631619337338],
            [126.794096459737, 37.631611308517],
            [126.794095959174, 37.631603910615],
            [126.794064752451, 37.63154948179],
            [126.79403656721, 37.631500328984],
            [126.794082372999, 37.631470479061],
            [126.794088249827, 37.631438874107],
            [126.794096208876, 37.63139992985],
            [126.794117184532, 37.63130802201],
            [126.794164174446, 37.631088410339],
            [126.794187236208, 37.630979540748],
            [126.794202736747, 37.630909156619],
            [126.794251660797, 37.630682304439],
            [126.7942697075, 37.630600635526],
            [126.79429716296, 37.630480060917],
            [126.794323284539, 37.630366574648],
            [126.794413478394, 37.62996801451],
            [126.794428525122, 37.629901801068],
            [126.794463955549, 37.629744390429],
            [126.794537832425, 37.629399851152],
            [126.794550808369, 37.629336805495],
            [126.794601967962, 37.629308036911],
            [126.794573250388, 37.629238593271],
            [126.794600704678, 37.629134425359],
            [126.794632964856, 37.629045618521],
            [126.79464733636, 37.629006261993],
            [126.794569093528, 37.629058579702],
            [126.794513758284, 37.629106198405],
            [126.794398890356, 37.62892181002],
            [126.793965894843, 37.628702931572],
            [126.793859613036, 37.628515440371],
            [126.793914854825, 37.628338495491],
            [126.794040483558, 37.628225013083],
            [126.793942401851, 37.628136013209],
            [126.79387227586, 37.62807237091],
            [126.793780912843, 37.627991275286],
            [126.79375633471, 37.627969455386],
            [126.793715970969, 37.627933309168],
            [126.793662115107, 37.627885057061],
            [126.793616440457, 37.627844153302],
            [126.79352355465, 37.627760919476],
            [126.793437811036, 37.627683932937],
            [126.793162117351, 37.627436398391],
            [126.793092917356, 37.6273742709],
            [126.79300613401, 37.6272967236],
            [126.792784860136, 37.627098910161],
            [126.792694902249, 37.627018500914],
            [126.792564015425, 37.627084085637],
            [126.792301664047, 37.627045257148],
            [126.792025967183, 37.626635543926],
            [126.791814911775, 37.626205249468],
            [126.791541959107, 37.626022090827],
            [126.791280719447, 37.625843439289],
            [126.791236834516, 37.625807493164],
            [126.791122759614, 37.62573625673],
            [126.791088824247, 37.625715122347],
            [126.790939976443, 37.625622623711],
            [126.790868220079, 37.625684870339],
            [126.790428232035, 37.626082334316],
            [126.790278751429, 37.626193472728],
            [126.789859283644, 37.626499026496],
            [126.788717278924, 37.627340152521],
            [126.788618084287, 37.627461290227],
            [126.788344773226, 37.627757804049],
            [126.787857218898, 37.628031179805],
            [126.787460631004, 37.628263634012],
            [126.787051570011, 37.62849939782],
            [126.786708648895, 37.628697061159],
            [126.786687298211, 37.628719564743],
            [126.78659442078, 37.628765561693],
            [126.786396994877, 37.628878076378],
            [126.786355166081, 37.628899299218],
            [126.786285656472, 37.628934256509],
            [126.786036327128, 37.629068903013],
            [126.785886159993, 37.629150617626],
            [126.785720266468, 37.629240943659],
            [126.78554707918, 37.629334445566],
            [126.785473196233, 37.629373241543],
            [126.785126813945, 37.629531070715],
            [126.784712760156, 37.629721849419],
            [126.783871143582, 37.630091421558],
            [126.783621323233, 37.630193536743],
            [126.783244689733, 37.63034232175],
            [126.782975981635, 37.630441012948],
            [126.782697752955, 37.630533288939],
            [126.782552726299, 37.630578924684],
            [126.782204769753, 37.630690197838],
            [126.782180763441, 37.630698234981],
            [126.782170658598, 37.630701991291],
            [126.782169887085, 37.631230475906],
            [126.782169647929, 37.631350395487],
            [126.7821999843, 37.631393464514],
            [126.782150915596, 37.631402527225],
            [126.782150805167, 37.631440097756],
            [126.78215128775, 37.631584254952],
            [126.78211273946, 37.631594634612],
            [126.782005502809, 37.631655791688],
            [126.781977757376, 37.631767911684],
            [126.78164215361, 37.631783234081],
            [126.780995305642, 37.631812840366],
            [126.780374971221, 37.63184087104],
            [126.780377474849, 37.631875293045],
            [126.780405899483, 37.632223033244],
            [126.780435774436, 37.632578434434],
            [126.780457199962, 37.632841469662],
            [126.780485880977, 37.633179389621],
            [126.780515039951, 37.633509021464],
            [126.780528734554, 37.633666627907],
            [126.780536733407, 37.633758272198],
            [126.780546274325, 37.633864785487],
            [126.780558884968, 37.634005902018],
            [126.78056217098, 37.634043974427],
            [126.78056601838, 37.634083759742],
            [126.780713588508, 37.634023580466],
            [126.780761516049, 37.634021778094],
            [126.78110533429, 37.633875381489],
            [126.781112144754, 37.633870979426],
            [126.781676919499, 37.63426837259],
            [126.78172883078, 37.634318023002],
            [126.78170840919, 37.634327895701],
            [126.781435403932, 37.634433702021],
            [126.781600937293, 37.634582761863],
            [126.781796407939, 37.634760978759],
            [126.781945124272, 37.634888923949],
            [126.78209056147, 37.635014880703],
            [126.782281201487, 37.635179933548],
            [126.782303721697, 37.635200049142],
            [126.782326232072, 37.635219660166],
            [126.782364903945, 37.635187116658],
            [126.782755267552, 37.634854389712],
            [126.782860188849, 37.634888821296],
            [126.782868603742, 37.634882025508],
            [126.782875667265, 37.634876317397],
            [126.782941450756, 37.634901405332],
            [126.782968814337, 37.634911844276],
            [126.782999111168, 37.634934244602],
            [126.782982390483, 37.634957008337],
            [126.782898954675, 37.634998091462],
            [126.782804393162, 37.635027666457],
            [126.782779547919, 37.635035440875],
            [126.782540007007, 37.635218912858],
            [126.782296793209, 37.635445146867],
            [126.782233161572, 37.635505033646],
            [126.78192727705, 37.635733312734],
            [126.781615770368, 37.635934983723],
            [126.781614169769, 37.635936052901],
            [126.781195067636, 37.636290525959],
            [126.780798817061, 37.636501965251],
            [126.780438440194, 37.636802937344],
            [126.780231910636, 37.636991214564],
            [126.779963917465, 37.637198386611],
            [126.779859127038, 37.637309955862],
            [126.779806984657, 37.63739500012],
            [126.77966252741, 37.637529829925],
            [126.779674121624, 37.637555169147],
            [126.779513462634, 37.637720376237],
            [126.779223127735, 37.638017106577],
            [126.779245672351, 37.638040448284],
            [126.779300501594, 37.638095952563],
            [126.779563646469, 37.638377732512],
            [126.779928551313, 37.638768540886],
            [126.780203672862, 37.639063045631],
            [126.780308368356, 37.639175233241],
            [126.780461967921, 37.639339678881],
            [126.780544672273, 37.639429075439],
            [126.780665710223, 37.639559177698],
            [126.78108316773, 37.63999855328],
            [126.781271228246, 37.640196578515],
            [126.78136090905, 37.640291438415],
            [126.781525474676, 37.640465633642],
            [126.781466925992, 37.640509933541],
            [126.781430758853, 37.640537300785],
            [126.781409649049, 37.64055327174],
            [126.78071866384, 37.641076159143],
            [126.780676534094, 37.641108155017],
            [126.780614646109, 37.64115488988],
            [126.780174160146, 37.641487605407],
            [126.779952723075, 37.641654951375],
            [126.779810143225, 37.641784423975],
            [126.779770154457, 37.641820117542],
            [126.779677838907, 37.641887418038],
            [126.779653880056, 37.641904860917],
            [126.779699721245, 37.642004378735],
            [126.779768471475, 37.642157619696],
            [126.779786316879, 37.642214775062],
            [126.779707606316, 37.642279046838],
            [126.779550784153, 37.642343234701],
            [126.779539127256, 37.642353961404],
            [126.779212017764, 37.642620484646],
            [126.779104059128, 37.642689656264],
            [126.777297515621, 37.643743549057],
            [126.777272703407, 37.643758025615],
            [126.777261216695, 37.643764725055],
            [126.777095952914, 37.643861175517],
            [126.776947283374, 37.643947899775],
            [126.776882766745, 37.643985527802],
            [126.776796320694, 37.644035952918],
            [126.77665695795, 37.644117252644],
            [126.776502646159, 37.644207254141],
            [126.776405870492, 37.644263704808],
            [126.776352647192, 37.644294758954],
            [126.776291989205, 37.644330132573],
            [126.776766115985, 37.644727015135],
            [126.777050933583, 37.644965414691],
            [126.780859299493, 37.648153264215],
            [126.781038796625, 37.648303296675]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41285112',
      properties: {
        rgnCode: '41285112',
        colCode: '41280',
        rgnKo: ['문봉동'],
        rgnSize: 1,
        rgnBbox: [
          126.810455187123, 37.690613109673, 126.838578098979, 37.710793555591
        ],
        rgnCenter: [126.8240066998, 37.700047206566],
        rgnArea: 2863429,
        predVal: [
          0.39496, 0.40163, 0.3843, 0.43159, 0.54071, 0.49164, 0.53939, 0.60558,
          0.55523, 0.59295, 0.56673, 0.39245, 0.20188, 0.34901, 0.38552,
          0.22766, 0.26184, 0.44661, 0.62564, 0.45053, 0.47376, 0.56312,
          0.49407, 0.55951, 0.44788, 0.54579, 0.49886, 0.51365, 0.50219,
          0.52331, 0.37852
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.819878303822, 37.710793555591],
            [126.819866459863, 37.710720919548],
            [126.819886138746, 37.710696623802],
            [126.820105905837, 37.710424690432],
            [126.820273757673, 37.710328004697],
            [126.820344808121, 37.710257838596],
            [126.820551592554, 37.710053637017],
            [126.820596609975, 37.710009108316],
            [126.820650381751, 37.709955493275],
            [126.820696477873, 37.709882360539],
            [126.820708439683, 37.709897434014],
            [126.820708678405, 37.709897200129],
            [126.820722797835, 37.709883076623],
            [126.820745082394, 37.709859676681],
            [126.820771435985, 37.709832534956],
            [126.820819290013, 37.709782631752],
            [126.821103465162, 37.709487523224],
            [126.82112628511, 37.709463042869],
            [126.821148172671, 37.709439570164],
            [126.821176484926, 37.709408971636],
            [126.821211220156, 37.709371950028],
            [126.821256199524, 37.709295977316],
            [126.821307521028, 37.709176677839],
            [126.821378259477, 37.708906586376],
            [126.821469071623, 37.708781166521],
            [126.821549541293, 37.708660289695],
            [126.821640147575, 37.708615109383],
            [126.82173529623, 37.708567323127],
            [126.822035644068, 37.70841874296],
            [126.822051337217, 37.708410180644],
            [126.82217480233, 37.708282431379],
            [126.82217391833, 37.708272789702],
            [126.822159674519, 37.708112891683],
            [126.822178901418, 37.708040258046],
            [126.82220270064, 37.708093802592],
            [126.822258033877, 37.708125339438],
            [126.822299723259, 37.707936109922],
            [126.822303908214, 37.707917123926],
            [126.822329339093, 37.707801685801],
            [126.822488933613, 37.707692550985],
            [126.822731035363, 37.707509751987],
            [126.822838055377, 37.707428827289],
            [126.82295961661, 37.707337112924],
            [126.822978514098, 37.707259568384],
            [126.823016831607, 37.706985849912],
            [126.8230335501, 37.706866380118],
            [126.823302461475, 37.706662357699],
            [126.823320808365, 37.706639311691],
            [126.823681087768, 37.706386963484],
            [126.823731529704, 37.706348568303],
            [126.823904360623, 37.706217215655],
            [126.823898770063, 37.706027085391],
            [126.824051757504, 37.705737655164],
            [126.824092733128, 37.705626537615],
            [126.824075040625, 37.705484698932],
            [126.824347471769, 37.70525647942],
            [126.824365990396, 37.705213405023],
            [126.824429088703, 37.705078787345],
            [126.824463111085, 37.705006202543],
            [126.824553479781, 37.704958172549],
            [126.824749892119, 37.704765119809],
            [126.824969712264, 37.704647152271],
            [126.824958428929, 37.704575508561],
            [126.824991892559, 37.704575639749],
            [126.825089063068, 37.704575974381],
            [126.825131360401, 37.704576127756],
            [126.825484320997, 37.704597017044],
            [126.825775463759, 37.704624570405],
            [126.825927300409, 37.704674439844],
            [126.826018855793, 37.704704488224],
            [126.826295023924, 37.704643317888],
            [126.826646515829, 37.704546301072],
            [126.826960909188, 37.704644837078],
            [126.827079181629, 37.704839440617],
            [126.827310555893, 37.705282562508],
            [126.827586566282, 37.705818324029],
            [126.828113471387, 37.706090561142],
            [126.828618407312, 37.706400063672],
            [126.829230877972, 37.706772022994],
            [126.830495375477, 37.705746726467],
            [126.830609073381, 37.705745584917],
            [126.830659923314, 37.705745072981],
            [126.831634783465, 37.705401402462],
            [126.832437058065, 37.704995192711],
            [126.833487478954, 37.704471358799],
            [126.833551223959, 37.704439564419],
            [126.833592963052, 37.704440840171],
            [126.833790622348, 37.704493629812],
            [126.833670979374, 37.704838926099],
            [126.83358790414, 37.705089835043],
            [126.833711372122, 37.705155817391],
            [126.834071154155, 37.705226703324],
            [126.834538196593, 37.705072399427],
            [126.834882480526, 37.704913901949],
            [126.834928124449, 37.704893298276],
            [126.835547775178, 37.704850925696],
            [126.835911909596, 37.704871934647],
            [126.836403073059, 37.705010921764],
            [126.836747363825, 37.705006222807],
            [126.8371183768, 37.704737243609],
            [126.837196147696, 37.704798014077],
            [126.837254108057, 37.704843296315],
            [126.837479876012, 37.704899110134],
            [126.837615524756, 37.705043002892],
            [126.837977325251, 37.705068912488],
            [126.838144173062, 37.705051935319],
            [126.838578098979, 37.704972800209],
            [126.838363905848, 37.70480933903],
            [126.838271803417, 37.7047678572],
            [126.838264421836, 37.704772712199],
            [126.838137165952, 37.70473118159],
            [126.83814751686, 37.704716780469],
            [126.83795761775, 37.704516124271],
            [126.837943588998, 37.704501256884],
            [126.83777451188, 37.704346551526],
            [126.837735302129, 37.704298628725],
            [126.837490990312, 37.703998024039],
            [126.837453995191, 37.703814968257],
            [126.837444071368, 37.703753931988],
            [126.837441835201, 37.703739513412],
            [126.83750169832, 37.703744371816],
            [126.837548763724, 37.703762474621],
            [126.837844956614, 37.703278238188],
            [126.837886746764, 37.703199281416],
            [126.837722546256, 37.703148779508],
            [126.837511046805, 37.70279530661],
            [126.837320512805, 37.702546176435],
            [126.837269541009, 37.702032464309],
            [126.837248166981, 37.701980673993],
            [126.837162876089, 37.701860456272],
            [126.836913861951, 37.701706494093],
            [126.836879514728, 37.701650135716],
            [126.836739719106, 37.701410823849],
            [126.83651841449, 37.701193174155],
            [126.836507922033, 37.700657624723],
            [126.836115947436, 37.700493730254],
            [126.836090602387, 37.700418013472],
            [126.835963696347, 37.700325035768],
            [126.835865010399, 37.700187769995],
            [126.835887485193, 37.700177350347],
            [126.835836386577, 37.7001079041],
            [126.83570349364, 37.699949038988],
            [126.835692874456, 37.699936338439],
            [126.835615746419, 37.699844331311],
            [126.835611788372, 37.699839550616],
            [126.835227623942, 37.699481595845],
            [126.835211500603, 37.699476140276],
            [126.83509652916, 37.699437533806],
            [126.834831057594, 37.699346791845],
            [126.834744805392, 37.699177918741],
            [126.834591311785, 37.699161394055],
            [126.834598442161, 37.699116716175],
            [126.834601431912, 37.699098160483],
            [126.834623335159, 37.699089722393],
            [126.834631469255, 37.699052523959],
            [126.834598570633, 37.699059414843],
            [126.834626291143, 37.698833311305],
            [126.83458544842, 37.698792709969],
            [126.834730076498, 37.698818321894],
            [126.834676164942, 37.698740041672],
            [126.834580661188, 37.698601517939],
            [126.834405320876, 37.698417292069],
            [126.834183037117, 37.698233630005],
            [126.834237454151, 37.698237941758],
            [126.834231584258, 37.698226220856],
            [126.83387491299, 37.698002364537],
            [126.833684963404, 37.697811629845],
            [126.833716815944, 37.697800791488],
            [126.833731457505, 37.697557893489],
            [126.833750604672, 37.697413765941],
            [126.833599100208, 37.697220833362],
            [126.833573161819, 37.697042828316],
            [126.833566830887, 37.696999329598],
            [126.833410768603, 37.696766657605],
            [126.83334474684, 37.696579432508],
            [126.833586299742, 37.695858011571],
            [126.833098505566, 37.695461520806],
            [126.832710796852, 37.695431865195],
            [126.832410793306, 37.695476303563],
            [126.832029603131, 37.695322681899],
            [126.8321933272, 37.695174527705],
            [126.832169219725, 37.695000903624],
            [126.832130507509, 37.6948775686],
            [126.832146819299, 37.694635141583],
            [126.832214279117, 37.694187817797],
            [126.832151145311, 37.694128984032],
            [126.831497743141, 37.694268054226],
            [126.83069148951, 37.694262564421],
            [126.830590862197, 37.694188449148],
            [126.830844766774, 37.693923481834],
            [126.83047304725, 37.693501830838],
            [126.830472627927, 37.693388218163],
            [126.830488871482, 37.692960489115],
            [126.830490082576, 37.692921902408],
            [126.830413881985, 37.69282696498],
            [126.830131094941, 37.692631683948],
            [126.8300118004, 37.692546089727],
            [126.829866907393, 37.692395462283],
            [126.829961003981, 37.692128642331],
            [126.830121180963, 37.692042517433],
            [126.830170178786, 37.691862295879],
            [126.830295819264, 37.691460745611],
            [126.830053176708, 37.69114595512],
            [126.829825334085, 37.690972871953],
            [126.829770337244, 37.690932329381],
            [126.829580315963, 37.690613109673],
            [126.82928286148, 37.690616955226],
            [126.829109247316, 37.690778290228],
            [126.828629508522, 37.690867909985],
            [126.828603232663, 37.690860825889],
            [126.828044679964, 37.690708705975],
            [126.827632194999, 37.691080378636],
            [126.827063151827, 37.691297527513],
            [126.826885415957, 37.691473223563],
            [126.825842205425, 37.691743135889],
            [126.825454367135, 37.691657821444],
            [126.824902232414, 37.691696818642],
            [126.82487312063, 37.691723831115],
            [126.824502939812, 37.692066472851],
            [126.824138788043, 37.692342207569],
            [126.823648940703, 37.692503734122],
            [126.823607829643, 37.692664828266],
            [126.82349797804, 37.693095262137],
            [126.822771570934, 37.693084432897],
            [126.822537575012, 37.693169309564],
            [126.822464859933, 37.693420749432],
            [126.821885129344, 37.693518974263],
            [126.82150275667, 37.693680042858],
            [126.821061022796, 37.693866120349],
            [126.820585390285, 37.693863499532],
            [126.820265702009, 37.693959457219],
            [126.820257183224, 37.693942370786],
            [126.82023676005, 37.693948258751],
            [126.820180278609, 37.693964542499],
            [126.820134076823, 37.693977859827],
            [126.820039449421, 37.694005103665],
            [126.819833702063, 37.694127318482],
            [126.819632823884, 37.694158993187],
            [126.819171280972, 37.694249818751],
            [126.819075051814, 37.694268770434],
            [126.8189268546, 37.694252602949],
            [126.818881558914, 37.694247658572],
            [126.818649979581, 37.694222432985],
            [126.818152082418, 37.694005607788],
            [126.818123640729, 37.693993968105],
            [126.817565805576, 37.693766298655],
            [126.816921957334, 37.693517796723],
            [126.816306176972, 37.693336998041],
            [126.815972342646, 37.69321619406],
            [126.815783014971, 37.693117600431],
            [126.815539205794, 37.692906479796],
            [126.815310671194, 37.692708626998],
            [126.815262010814, 37.692671340179],
            [126.815033531972, 37.692501606136],
            [126.814898418864, 37.692401222783],
            [126.814704424279, 37.692257094114],
            [126.81469410743, 37.692256807481],
            [126.814661003126, 37.692255763968],
            [126.814589466141, 37.692253488274],
            [126.813938264275, 37.692232813168],
            [126.813833449195, 37.692230997631],
            [126.813279669671, 37.692221402636],
            [126.812783541251, 37.692212861355],
            [126.812700324242, 37.692211376694],
            [126.812335882681, 37.692806873007],
            [126.812140054121, 37.693126852771],
            [126.811856886564, 37.693370920367],
            [126.811695700143, 37.693532294645],
            [126.811592914236, 37.693601053186],
            [126.811471842438, 37.69368203533],
            [126.810914149646, 37.694289108263],
            [126.810806229232, 37.694458315698],
            [126.810720617338, 37.694682518146],
            [126.810805434526, 37.694901410266],
            [126.811210783758, 37.695255604757],
            [126.811272604874, 37.695378326181],
            [126.811333746805, 37.695425221053],
            [126.811430919944, 37.695499761601],
            [126.812024683346, 37.695837895253],
            [126.812628460968, 37.696181736058],
            [126.812949427979, 37.696416771537],
            [126.813205901267, 37.696486510808],
            [126.813413524388, 37.696593651849],
            [126.813537303691, 37.696698811918],
            [126.813962987748, 37.696911712563],
            [126.814282062238, 37.697428807193],
            [126.814293918149, 37.697466648619],
            [126.814301125631, 37.697481760272],
            [126.814402817845, 37.697993707057],
            [126.814127411747, 37.69838426242],
            [126.814111672978, 37.698406923759],
            [126.814084356075, 37.698560630368],
            [126.81419069559, 37.698929024553],
            [126.814278377213, 37.699232249088],
            [126.814325815291, 37.699396390476],
            [126.814333500007, 37.699406673697],
            [126.814508334312, 37.699638049097],
            [126.814525896836, 37.699661321892],
            [126.814552636153, 37.699696772288],
            [126.814725746671, 37.699870032261],
            [126.814775526324, 37.699919439037],
            [126.814919787055, 37.700062605787],
            [126.814990937048, 37.700131822506],
            [126.815051712464, 37.700268685382],
            [126.81507944277, 37.700334229512],
            [126.815175561516, 37.700349337489],
            [126.815266606298, 37.700352995096],
            [126.815459841964, 37.700392221909],
            [126.815643333782, 37.700428369759],
            [126.815688554873, 37.700437360602],
            [126.815971748289, 37.70050718098],
            [126.816284518971, 37.700584525166],
            [126.81658884616, 37.700728887206],
            [126.817029308499, 37.700939592478],
            [126.817204288682, 37.70096574187],
            [126.817488252204, 37.701037343722],
            [126.817646111779, 37.701163932533],
            [126.817739724156, 37.701234957512],
            [126.817474111269, 37.701528438573],
            [126.817479508783, 37.701936674954],
            [126.817464038942, 37.70246065293],
            [126.817505997149, 37.702510343646],
            [126.817530677678, 37.702567692634],
            [126.817628940884, 37.702730542432],
            [126.817645279051, 37.702758966371],
            [126.817658958912, 37.702784764351],
            [126.817757622314, 37.702781134087],
            [126.817921161327, 37.702767568002],
            [126.818029374487, 37.702758582619],
            [126.818146033976, 37.702768674721],
            [126.818172292679, 37.702742587526],
            [126.818281771674, 37.702629326023],
            [126.818358829439, 37.702557999099],
            [126.818363261825, 37.702553951634],
            [126.818487906353, 37.702498555538],
            [126.818574474789, 37.702433009419],
            [126.818590186206, 37.702453575829],
            [126.818650205055, 37.702532593584],
            [126.818731722759, 37.702584345352],
            [126.8187597004, 37.702597002247],
            [126.818843554253, 37.702621007791],
            [126.819051205743, 37.702749987302],
            [126.819153133832, 37.702802310938],
            [126.819221312541, 37.702837283759],
            [126.819309495517, 37.702899586737],
            [126.819352619093, 37.7028860758],
            [126.819350448174, 37.702939112339],
            [126.819350223722, 37.703030830373],
            [126.819352265729, 37.703030473141],
            [126.819387543965, 37.70302557232],
            [126.819464401934, 37.703012491848],
            [126.81938373892, 37.703143998476],
            [126.819356475915, 37.703233061938],
            [126.819349501113, 37.703349275824],
            [126.819275842155, 37.703394129238],
            [126.819307013822, 37.703403853788],
            [126.819328327577, 37.703475333352],
            [126.819329865525, 37.703486336527],
            [126.819329689986, 37.703567324119],
            [126.819374483226, 37.703636272193],
            [126.819267206578, 37.703683767555],
            [126.819219026386, 37.703710604943],
            [126.818913923065, 37.703884830225],
            [126.818561674348, 37.704088560292],
            [126.818514997885, 37.704117003443],
            [126.818510682578, 37.704119519451],
            [126.818479823645, 37.704079378557],
            [126.818435938611, 37.704079400524],
            [126.818321983619, 37.70402931001],
            [126.818275609196, 37.704027165731],
            [126.81820773256, 37.704053458542],
            [126.818132111111, 37.704093433957],
            [126.817977548018, 37.704140133818],
            [126.817947134592, 37.704149366427],
            [126.817942811486, 37.704155035784],
            [126.817910016812, 37.704164219628],
            [126.817876444942, 37.70421263933],
            [126.817615342512, 37.704420535788],
            [126.817258929834, 37.704581477572],
            [126.816898875094, 37.704738015857],
            [126.816875254603, 37.704774386913],
            [126.816889633769, 37.704769742423],
            [126.816829067138, 37.704957228715],
            [126.816842190905, 37.705060950464],
            [126.816809159396, 37.705073962735],
            [126.816654101358, 37.705136156691],
            [126.816390425744, 37.705236110633],
            [126.816325840404, 37.705260605589],
            [126.81619914868, 37.705315906053],
            [126.816081452805, 37.705356174395],
            [126.815853648552, 37.705440686923],
            [126.815440506095, 37.705587343622],
            [126.815040290511, 37.705733658926],
            [126.814922741972, 37.705714642782],
            [126.81447799537, 37.70580106156],
            [126.814312572674, 37.705834765462],
            [126.814182033536, 37.705885823102],
            [126.814145028563, 37.705900269856],
            [126.814106434385, 37.705915344749],
            [126.814064775209, 37.705931676109],
            [126.814000172388, 37.705962566617],
            [126.813883811038, 37.706012385119],
            [126.813875637354, 37.706015885879],
            [126.813674768609, 37.706075038816],
            [126.813639815137, 37.706085335226],
            [126.813557233787, 37.706050516432],
            [126.813505476313, 37.706068993814],
            [126.813482873661, 37.706083193033],
            [126.813254914758, 37.706226803637],
            [126.812803714117, 37.706486281312],
            [126.812659151527, 37.70652136772],
            [126.812324783886, 37.706589485295],
            [126.811937506366, 37.706549175734],
            [126.811845489729, 37.706576876607],
            [126.811346888611, 37.706713146453],
            [126.811208467215, 37.70680747948],
            [126.811134447071, 37.707016879564],
            [126.811075965625, 37.707083303422],
            [126.811023133291, 37.707153466374],
            [126.810996557786, 37.707230843416],
            [126.810919662203, 37.707327185536],
            [126.810801033514, 37.707419342699],
            [126.810558323268, 37.707651218814],
            [126.810455187123, 37.707671683852],
            [126.810503686292, 37.707774652696],
            [126.810625324646, 37.70770322292],
            [126.810671324646, 37.707719514761],
            [126.811384223177, 37.707879596781],
            [126.81162178223, 37.708199191691],
            [126.811978594835, 37.708449423328],
            [126.812502688218, 37.708607933577],
            [126.81271384914, 37.7086521489],
            [126.813122091126, 37.708389449671],
            [126.813183656628, 37.708349815443],
            [126.813211400478, 37.708276070677],
            [126.81326163544, 37.708142807994],
            [126.813286410592, 37.708076986965],
            [126.813392380034, 37.708012286563],
            [126.813698815579, 37.707825284133],
            [126.813846964855, 37.707852729182],
            [126.814067432133, 37.707893533227],
            [126.814071550956, 37.707879124326],
            [126.814137293968, 37.707891481951],
            [126.814255291191, 37.707913743063],
            [126.814334458211, 37.707999460495],
            [126.814385916532, 37.708055311941],
            [126.814530278701, 37.708236274651],
            [126.814557297326, 37.708270103702],
            [126.814597542366, 37.708320621577],
            [126.814607495611, 37.708326592737],
            [126.814661406248, 37.708358923737],
            [126.814689567719, 37.70837573533],
            [126.814813480598, 37.708449711667],
            [126.814941208195, 37.708526586008],
            [126.815123488367, 37.708556335867],
            [126.815666588771, 37.708645037278],
            [126.815736304357, 37.70865640921],
            [126.815924253146, 37.708687067728],
            [126.815983565023, 37.708686620491],
            [126.816233515168, 37.708684310625],
            [126.816262546707, 37.70868435624],
            [126.816297591374, 37.708683330136],
            [126.81658851705, 37.708848933746],
            [126.816741703989, 37.708858363748],
            [126.817256371955, 37.708889892067],
            [126.817280750413, 37.708891371705],
            [126.817603161689, 37.709120720259],
            [126.817740693831, 37.709316083809],
            [126.817776719924, 37.709744458898],
            [126.817803346777, 37.70975405062],
            [126.817867594226, 37.709775954066],
            [126.818338442169, 37.709966790174],
            [126.818765338739, 37.710138005337],
            [126.818945719278, 37.710204685918],
            [126.818953441708, 37.710209554078],
            [126.819726799392, 37.710697099016],
            [126.819737001588, 37.710703592685],
            [126.819878303822, 37.710793555591]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281102',
      properties: {
        rgnCode: '41281102',
        colCode: '41280',
        rgnKo: ['원당동'],
        rgnSize: 1,
        rgnBbox: [
          126.833237359674, 37.651631673877, 126.877310820805, 37.690430132338
        ],
        rgnCenter: [126.856912145194, 37.671712187264],
        rgnArea: 8378945,
        predVal: [
          0.13165, 0.13388, 0.1281, 0.14386, 0.18024, 0.16388, 0.1798, 0.18909,
          0.16284, 0.17143, 0.17085, 0.13082, 0.18803, 0.16713, 0.19007,
          0.07589, 0.1762, 0.16166, 0.20855, 0.18212, 0.18653, 0.18979, 0.16469,
          0.1865, 0.14929, 0.18193, 0.16629, 0.17122, 0.1674, 0.19174, 0.18429
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.877310820805, 37.660540686176],
            [126.876868296503, 37.659366791298],
            [126.876782455798, 37.659125690077],
            [126.876556644279, 37.658532069721],
            [126.876506458963, 37.657722986997],
            [126.875792297612, 37.65717538183],
            [126.875755624539, 37.657148854101],
            [126.875714536783, 37.657119348439],
            [126.875717121016, 37.657065382846],
            [126.875730953617, 37.656591124811],
            [126.874859444647, 37.656678037298],
            [126.873768124311, 37.656342959505],
            [126.872999215453, 37.656603947426],
            [126.872596966994, 37.656636033681],
            [126.872453494542, 37.656768148812],
            [126.871549271858, 37.656895184486],
            [126.871368271542, 37.656626566728],
            [126.870913112359, 37.656709322707],
            [126.870809586679, 37.656730137674],
            [126.869815928952, 37.656906100952],
            [126.870492478824, 37.65696835482],
            [126.87069740763, 37.656987214398],
            [126.869130157828, 37.657302077261],
            [126.868608895936, 37.657469648912],
            [126.86821944813, 37.657627825957],
            [126.867846081285, 37.657721987718],
            [126.867007265999, 37.657596222329],
            [126.866267192284, 37.657484664468],
            [126.865595555866, 37.657182048219],
            [126.865498514287, 37.65713857249],
            [126.864936933921, 37.656886065363],
            [126.864965012173, 37.656702587476],
            [126.865076647102, 37.655997857571],
            [126.864792555822, 37.655773924881],
            [126.86458840521, 37.655617801538],
            [126.864386394487, 37.655463193963],
            [126.864129767922, 37.65526877146],
            [126.863847475904, 37.655055416004],
            [126.863665466427, 37.654914876711],
            [126.863494374765, 37.654784350724],
            [126.863165506398, 37.654536720113],
            [126.863067962854, 37.654461040955],
            [126.862907992853, 37.654341275785],
            [126.862173267336, 37.653783653079],
            [126.86206687018, 37.65377741875],
            [126.861748088329, 37.653757886777],
            [126.861495227744, 37.653743288457],
            [126.861010960049, 37.653713322888],
            [126.860880891583, 37.653706392573],
            [126.860798012449, 37.653560110631],
            [126.860770579112, 37.653517182509],
            [126.860740739764, 37.653470179104],
            [126.860067950249, 37.652372509856],
            [126.860008080373, 37.652272727189],
            [126.859891216951, 37.652080418059],
            [126.859919539813, 37.65171398959],
            [126.859902313099, 37.651631673877],
            [126.859704354306, 37.651758563621],
            [126.859244940986, 37.651757568997],
            [126.859037567281, 37.651752237229],
            [126.85839601324, 37.651753442803],
            [126.858058005598, 37.651754338672],
            [126.857866677118, 37.651755277122],
            [126.857387357922, 37.651863566287],
            [126.857089201808, 37.651929900805],
            [126.856647385135, 37.652244285773],
            [126.856218116143, 37.652549296366],
            [126.855540562837, 37.6525342065],
            [126.855318465606, 37.652529606793],
            [126.855077909464, 37.652524596338],
            [126.854575980934, 37.652514349815],
            [126.854459117894, 37.652512654522],
            [126.854321196753, 37.65271359806],
            [126.854081108872, 37.653061849259],
            [126.854004988867, 37.653172087439],
            [126.853288138747, 37.653027591593],
            [126.852356639691, 37.65283255482],
            [126.851918431807, 37.652741965563],
            [126.85139248437, 37.653646335291],
            [126.851317425575, 37.653778800109],
            [126.85110624649, 37.65414629976],
            [126.85107989669, 37.654192450104],
            [126.851283081232, 37.654474614874],
            [126.851815201933, 37.655200917931],
            [126.851990094125, 37.655432654051],
            [126.852054264143, 37.655520111839],
            [126.852338268943, 37.655909259655],
            [126.852494293476, 37.6564596997],
            [126.852797776278, 37.656750890043],
            [126.852759701673, 37.656919846723],
            [126.852716709389, 37.65709404986],
            [126.852558846023, 37.657751894874],
            [126.852481049199, 37.658073831656],
            [126.85247142768, 37.658119174517],
            [126.852343609164, 37.658643893182],
            [126.852698927501, 37.660523934827],
            [126.852825283365, 37.660812531763],
            [126.85299083251, 37.661191185133],
            [126.853176731687, 37.661595784838],
            [126.853522522195, 37.661618959735],
            [126.853815394199, 37.661626678748],
            [126.854140054469, 37.661623841309],
            [126.854412756853, 37.661761823369],
            [126.854876495879, 37.661997617824],
            [126.855063687629, 37.662338931769],
            [126.855236141597, 37.662631295276],
            [126.855398791285, 37.662918961315],
            [126.855473116533, 37.663283442867],
            [126.855550867987, 37.663687048833],
            [126.855566635808, 37.663922997098],
            [126.855573635206, 37.664000714666],
            [126.855523021859, 37.664022365479],
            [126.854791687992, 37.664318247517],
            [126.854757136353, 37.664333007517],
            [126.854752190737, 37.664335091621],
            [126.854727099797, 37.664345601781],
            [126.854677047253, 37.664433285222],
            [126.854490333044, 37.664744518919],
            [126.854364758444, 37.665029565236],
            [126.854275973406, 37.665101955714],
            [126.853946027878, 37.665373799307],
            [126.853442345482, 37.665375690824],
            [126.853372207326, 37.665394793474],
            [126.853111340692, 37.665488617195],
            [126.852676298249, 37.66585251245],
            [126.852263412432, 37.666469697465],
            [126.851968483526, 37.666703847031],
            [126.851711816628, 37.666904257984],
            [126.851491855538, 37.666999751787],
            [126.851394578041, 37.667071165225],
            [126.851299832546, 37.667123391096],
            [126.851257356409, 37.667166223276],
            [126.850590591848, 37.667433771666],
            [126.850554988291, 37.667496704066],
            [126.850471496223, 37.667644176433],
            [126.850114392021, 37.667848689785],
            [126.849834811729, 37.667942211897],
            [126.849687363346, 37.667992566734],
            [126.849563057082, 37.668032950411],
            [126.849400755748, 37.66830771791],
            [126.849319423577, 37.668450507169],
            [126.849264613899, 37.668537650532],
            [126.849191853793, 37.668584317043],
            [126.849148953364, 37.668611921476],
            [126.848979746576, 37.668720540205],
            [126.848734037343, 37.668876270555],
            [126.848657540716, 37.668926265489],
            [126.848577958272, 37.669540994601],
            [126.848521754272, 37.66992004167],
            [126.848524783496, 37.670001457415],
            [126.848517180549, 37.670027809989],
            [126.848509170192, 37.670086975428],
            [126.848246666053, 37.670359809266],
            [126.84780622458, 37.670826570164],
            [126.846387238734, 37.671208796676],
            [126.845346340704, 37.671339415437],
            [126.844799122422, 37.671429327379],
            [126.844384576203, 37.671498150669],
            [126.844350448294, 37.67155666835],
            [126.844063541043, 37.67205010819],
            [126.84395152182, 37.672230603379],
            [126.843501882292, 37.672222253512],
            [126.842673233473, 37.672199336976],
            [126.842097538778, 37.672196127663],
            [126.841668176608, 37.672193654525],
            [126.84160936678, 37.672153337427],
            [126.841304563716, 37.672019390831],
            [126.840701161657, 37.671562119521],
            [126.840686418753, 37.671523627936],
            [126.840679749791, 37.671504509236],
            [126.840648189898, 37.671448470817],
            [126.840614248424, 37.671377292819],
            [126.840530929978, 37.671202390651],
            [126.840503930237, 37.671160548717],
            [126.840455664852, 37.671150932523],
            [126.84025761245, 37.671114172571],
            [126.840014130803, 37.671066808725],
            [126.839858185009, 37.671057315089],
            [126.839715544208, 37.671080094282],
            [126.839557457902, 37.671168893311],
            [126.839360203399, 37.671338635625],
            [126.839130813386, 37.671534551638],
            [126.83876369488, 37.671682525747],
            [126.838506183325, 37.671882275901],
            [126.838426459431, 37.671952171174],
            [126.838163838054, 37.672051815582],
            [126.83796261814, 37.672218216443],
            [126.837808709243, 37.672260889165],
            [126.837653449687, 37.672299145049],
            [126.837368954826, 37.672447860228],
            [126.837276048316, 37.672645584197],
            [126.837199202361, 37.672709968693],
            [126.836689751034, 37.672945267293],
            [126.836613382608, 37.673008624963],
            [126.836534499878, 37.673081223031],
            [126.836409792638, 37.673198336963],
            [126.836349892432, 37.673446335515],
            [126.836147642873, 37.673710532207],
            [126.835977945419, 37.673949745253],
            [126.835892151603, 37.674045587241],
            [126.835795769232, 37.674085914471],
            [126.835731308022, 37.674110393364],
            [126.835642734722, 37.674273263506],
            [126.835316479007, 37.674590351463],
            [126.835277213054, 37.674626127743],
            [126.835111123454, 37.674795726559],
            [126.834979154285, 37.674918892229],
            [126.835105309188, 37.675150682878],
            [126.835220317613, 37.675394665824],
            [126.835218328278, 37.675427665582],
            [126.835242431011, 37.675497164415],
            [126.835241393898, 37.675555545863],
            [126.835269746586, 37.675854077458],
            [126.835248406904, 37.67596865086],
            [126.835223365697, 37.676218004259],
            [126.835195466441, 37.676320675582],
            [126.835168819119, 37.67658354125],
            [126.835137630052, 37.676834444613],
            [126.835125759961, 37.676962095405],
            [126.835100124114, 37.677015495803],
            [126.835083708661, 37.67705164661],
            [126.834870935445, 37.67726059659],
            [126.834449487411, 37.677451699872],
            [126.834321313993, 37.677451806557],
            [126.833964090474, 37.677462084244],
            [126.833944691254, 37.677473841378],
            [126.833553668638, 37.677799031059],
            [126.833509609463, 37.678285312143],
            [126.833515610935, 37.678489300384],
            [126.833472330852, 37.678579606053],
            [126.833362455007, 37.678844424824],
            [126.83324866594, 37.679084821603],
            [126.833244404654, 37.679716756031],
            [126.833237359674, 37.680075422192],
            [126.833813561147, 37.680746528596],
            [126.833879342966, 37.680823150506],
            [126.834447092627, 37.681466798184],
            [126.834415819723, 37.681657029712],
            [126.834385718895, 37.681840163244],
            [126.834361119637, 37.681991401219],
            [126.834325563643, 37.682225963359],
            [126.834251837713, 37.682697696683],
            [126.834228451729, 37.68286371223],
            [126.834323784138, 37.682917815499],
            [126.834468934228, 37.683000189612],
            [126.834748476218, 37.683160191998],
            [126.835498513378, 37.683029735641],
            [126.835864896935, 37.68295996617],
            [126.836010883672, 37.682931907707],
            [126.836665829415, 37.682902065879],
            [126.837062873759, 37.682900358751],
            [126.837866827697, 37.682921613716],
            [126.838163456987, 37.682928331728],
            [126.838380175501, 37.68287262742],
            [126.839108699885, 37.682673716704],
            [126.839292417682, 37.682627803858],
            [126.839360007604, 37.682590632697],
            [126.83969839808, 37.682310949914],
            [126.840306870936, 37.68280917259],
            [126.840282788324, 37.682978521984],
            [126.840019825564, 37.683459361106],
            [126.840080944602, 37.683557299097],
            [126.840433898323, 37.684030078984],
            [126.841105715516, 37.684745085096],
            [126.841307061265, 37.684975457376],
            [126.841584563613, 37.685377612324],
            [126.841705405369, 37.685551392934],
            [126.842038678767, 37.685360550251],
            [126.842343024379, 37.685108671949],
            [126.842536319176, 37.685156197529],
            [126.842989080839, 37.68538017533],
            [126.843212487932, 37.685475599768],
            [126.843233232752, 37.685487123981],
            [126.843520623693, 37.685893801478],
            [126.84377962275, 37.686282348815],
            [126.843895146333, 37.686411531079],
            [126.843915252975, 37.686483725588],
            [126.84400696444, 37.686540068591],
            [126.844248626711, 37.68665810273],
            [126.844490104145, 37.686487744884],
            [126.844944620524, 37.686277333168],
            [126.845116581084, 37.686191086306],
            [126.845249144484, 37.68612668956],
            [126.845517974892, 37.686004702836],
            [126.845973004389, 37.685670638775],
            [126.846432368443, 37.685626798908],
            [126.847030083179, 37.685590949965],
            [126.847075113726, 37.685612785374],
            [126.847434141673, 37.68584436265],
            [126.847663480312, 37.685942119916],
            [126.847813614074, 37.685987301079],
            [126.847889202905, 37.6860014637],
            [126.84807654385, 37.686036250755],
            [126.848119244799, 37.686066542835],
            [126.848157954555, 37.686091613097],
            [126.848143458343, 37.686155797322],
            [126.848199398433, 37.686187376956],
            [126.848223389804, 37.686357105716],
            [126.848258156513, 37.686386675665],
            [126.848348469938, 37.686374629909],
            [126.848376259998, 37.686411849002],
            [126.848209446641, 37.686433507809],
            [126.848226139398, 37.686486389376],
            [126.848235722683, 37.686484726029],
            [126.848430992845, 37.687367415955],
            [126.848635031914, 37.688299561978],
            [126.848643329796, 37.688338755877],
            [126.848651953828, 37.688384761524],
            [126.848702120854, 37.688617312578],
            [126.848758250883, 37.688866845582],
            [126.84879722032, 37.689037007995],
            [126.848842587085, 37.689250055782],
            [126.848862039338, 37.689339997656],
            [126.848884613503, 37.689444566291],
            [126.848905040573, 37.689534662582],
            [126.848928405772, 37.689640556653],
            [126.848961325484, 37.689786736383],
            [126.84898572783, 37.689895595967],
            [126.849049372721, 37.690196881133],
            [126.849085481178, 37.690225578617],
            [126.84912629144, 37.690399977815],
            [126.849125594824, 37.690430132338],
            [126.849277141353, 37.690304868177],
            [126.849284273564, 37.690126990117],
            [126.849448839638, 37.690004922955],
            [126.849616560978, 37.689880922541],
            [126.8498343111, 37.689858858785],
            [126.849958929644, 37.689811006375],
            [126.850135383379, 37.689813458404],
            [126.850236827286, 37.689721095119],
            [126.850321250289, 37.689680434546],
            [126.850505770966, 37.689780471285],
            [126.850577738413, 37.689804691362],
            [126.85067782755, 37.689804377948],
            [126.850843512485, 37.689727421771],
            [126.851042001636, 37.689664877673],
            [126.851123543664, 37.689652647425],
            [126.851284605932, 37.689635599158],
            [126.851457083913, 37.68965289211],
            [126.851510248269, 37.689629201198],
            [126.851549833294, 37.689547650793],
            [126.851568950401, 37.689512897704],
            [126.85164143186, 37.689495826384],
            [126.851780837959, 37.689518725903],
            [126.8518323034, 37.689477157465],
            [126.851862811901, 37.689443752219],
            [126.851918282794, 37.689327120098],
            [126.851928077974, 37.689225746492],
            [126.851959068137, 37.689121786954],
            [126.851981809909, 37.688969966486],
            [126.852001949771, 37.68880396146],
            [126.852008329635, 37.688737748328],
            [126.852073119987, 37.688705449622],
            [126.852216757489, 37.688743382153],
            [126.852414956331, 37.688733352846],
            [126.852608309313, 37.688612272657],
            [126.852688114902, 37.68851514082],
            [126.852759990164, 37.688437676088],
            [126.852837922883, 37.688403924958],
            [126.852924394411, 37.688363580431],
            [126.853309633157, 37.688252705416],
            [126.85340435454, 37.688225434955],
            [126.853838983179, 37.687962987123],
            [126.85404850394, 37.687587401007],
            [126.854137814873, 37.687429086227],
            [126.854229186713, 37.687271828081],
            [126.854363633959, 37.686933781063],
            [126.854578777232, 37.686745377329],
            [126.854727986763, 37.686577919775],
            [126.854863345747, 37.686425671186],
            [126.855015866763, 37.6862916073],
            [126.855699611509, 37.685993639158],
            [126.855811042704, 37.685947566157],
            [126.856219021452, 37.685794264999],
            [126.856881891116, 37.685516743286],
            [126.857484222604, 37.68540030935],
            [126.857681355451, 37.685326769541],
            [126.857913984782, 37.685240974394],
            [126.858417284096, 37.685053959233],
            [126.858599904842, 37.684985833121],
            [126.858776806602, 37.684819674304],
            [126.858917286146, 37.684781796296],
            [126.859075966285, 37.684741255214],
            [126.85950048334, 37.684629650503],
            [126.859946761911, 37.684520097566],
            [126.860375407521, 37.684412089525],
            [126.860981894516, 37.684320779721],
            [126.861226300279, 37.684340964117],
            [126.861547056546, 37.684423036161],
            [126.861792622088, 37.684483962612],
            [126.862133481791, 37.684613429864],
            [126.862620416065, 37.684835129341],
            [126.86266283207, 37.684862965193],
            [126.862792276972, 37.684947898774],
            [126.863150273796, 37.685142775616],
            [126.863334532665, 37.685185526428],
            [126.863582795161, 37.685243145748],
            [126.864000059468, 37.68528754629],
            [126.864361688679, 37.6853594143],
            [126.864552067519, 37.685373087027],
            [126.864752844448, 37.685354778073],
            [126.864941725079, 37.685350879526],
            [126.865442219905, 37.685349349792],
            [126.865669942956, 37.68535672998],
            [126.865900089858, 37.685365373873],
            [126.866157055047, 37.68537676002],
            [126.866082760656, 37.685147918526],
            [126.867244685373, 37.685151679742],
            [126.867818882439, 37.685155161123],
            [126.869073981062, 37.685161406486],
            [126.86967868835, 37.685163525579],
            [126.870412066092, 37.685167676717],
            [126.870663239221, 37.685171992014],
            [126.870586724519, 37.685102099958],
            [126.870541691292, 37.685026458593],
            [126.870552369818, 37.684949554664],
            [126.870610217593, 37.684887515042],
            [126.870725738903, 37.684823764455],
            [126.87078808241, 37.684809652313],
            [126.870843223366, 37.684797604388],
            [126.870915561132, 37.684786233191],
            [126.871065660068, 37.684760946917],
            [126.871210970585, 37.684738042731],
            [126.871237012982, 37.684691725615],
            [126.871239212714, 37.684613722079],
            [126.871205528583, 37.684472529922],
            [126.871191181066, 37.684294788754],
            [126.871242162948, 37.684177899191],
            [126.871255104898, 37.684076824664],
            [126.871179507426, 37.68384587661],
            [126.871116124281, 37.683748492782],
            [126.871174344934, 37.683615087451],
            [126.87136212144, 37.683552641377],
            [126.871398076441, 37.683477576192],
            [126.871458153646, 37.683384238899],
            [126.871461113125, 37.683312461882],
            [126.871466804975, 37.683208072761],
            [126.871467361212, 37.683129725019],
            [126.871457043782, 37.683058942444],
            [126.871294092821, 37.682027106776],
            [126.87128935816, 37.682011866141],
            [126.871240737795, 37.681855367974],
            [126.871139950902, 37.68151559982],
            [126.870824847765, 37.681120094493],
            [126.870724171086, 37.680964556491],
            [126.870386986214, 37.680570935528],
            [126.870327404532, 37.680512162034],
            [126.870252015146, 37.680417701498],
            [126.869914425784, 37.680281540029],
            [126.869420034803, 37.68008212513],
            [126.869041620335, 37.680153778329],
            [126.868645272271, 37.680174054794],
            [126.868018685215, 37.680384895084],
            [126.866884097971, 37.680293508337],
            [126.866783928929, 37.68026987862],
            [126.866253019074, 37.680102951782],
            [126.866015774396, 37.680035403989],
            [126.865925800779, 37.680009785101],
            [126.865931491969, 37.679997078951],
            [126.865944778493, 37.679984300443],
            [126.865901049181, 37.679968843574],
            [126.86581745184, 37.679965233634],
            [126.865806631998, 37.679968077261],
            [126.865728339907, 37.679983159491],
            [126.865647074559, 37.679981327027],
            [126.865534537037, 37.67997719702],
            [126.865375454238, 37.679972607751],
            [126.865326110599, 37.679952630325],
            [126.865231633657, 37.679903985912],
            [126.865007489601, 37.679864174089],
            [126.864802572171, 37.679805634933],
            [126.86475347947, 37.679791135459],
            [126.864671900438, 37.679824430799],
            [126.864597054731, 37.67984552575],
            [126.864580815155, 37.67986127388],
            [126.86456421078, 37.679810295709],
            [126.864476781812, 37.679541831078],
            [126.86405165309, 37.679242862818],
            [126.864033714796, 37.679116417717],
            [126.864015124058, 37.678950419239],
            [126.86399560772, 37.678776184803],
            [126.863919263181, 37.678277363438],
            [126.863878000017, 37.678007699796],
            [126.863815608914, 37.677645130382],
            [126.863758434413, 37.677318497724],
            [126.863696701516, 37.677293207429],
            [126.863694702253, 37.67726482451],
            [126.863724182285, 37.677028020736],
            [126.863747893701, 37.676888542097],
            [126.863752941349, 37.676856113039],
            [126.863761061183, 37.676670279244],
            [126.863765795946, 37.676328825777],
            [126.86393345423, 37.675969948467],
            [126.864084868783, 37.67567780492],
            [126.86442404378, 37.67568666877],
            [126.865172455504, 37.675043233787],
            [126.865909240861, 37.67473950085],
            [126.8661326766, 37.67464936308],
            [126.866468940579, 37.674504484971],
            [126.867149194501, 37.674787184823],
            [126.867907867494, 37.675116504765],
            [126.869438589697, 37.674654390777],
            [126.86943068679, 37.674425418077],
            [126.869429584946, 37.674388359891],
            [126.869427539264, 37.674269870853],
            [126.869418638494, 37.674175421063],
            [126.869402243178, 37.673800778776],
            [126.869397352272, 37.673682376636],
            [126.86952815786, 37.673638852997],
            [126.869831197207, 37.673539733477],
            [126.869972857045, 37.673492230157],
            [126.870001665416, 37.67348253179],
            [126.870017317097, 37.673477323606],
            [126.870575221497, 37.673290091723],
            [126.870654336948, 37.673240508965],
            [126.871106956174, 37.672963538051],
            [126.871131715756, 37.672948402072],
            [126.871199784706, 37.672901924094],
            [126.871638222102, 37.672005751652],
            [126.872006591836, 37.671962180269],
            [126.872862617815, 37.671875398474],
            [126.872908683403, 37.671027778346],
            [126.87291687519, 37.670806328375],
            [126.872909068653, 37.670771695511],
            [126.872851909595, 37.670547336199],
            [126.872696290268, 37.66992746876],
            [126.872646927996, 37.669730876784],
            [126.873405415371, 37.669038071583],
            [126.872938599061, 37.667938053064],
            [126.872869572233, 37.667767883218],
            [126.872822478365, 37.66764971436],
            [126.872958480826, 37.667523249008],
            [126.873575784973, 37.667049855485],
            [126.873723818184, 37.666416542127],
            [126.873795558517, 37.666227226366],
            [126.873816601352, 37.666037981817],
            [126.874329408094, 37.665931923768],
            [126.875123227586, 37.665806019183],
            [126.875621949913, 37.665769549572],
            [126.876093889612, 37.665733076406],
            [126.876171543358, 37.665730230788],
            [126.876567751444, 37.665462449567],
            [126.876601917609, 37.665425212508],
            [126.876722202438, 37.665318088021],
            [126.877097152851, 37.665058607498],
            [126.876759110692, 37.664644640817],
            [126.876456960565, 37.664247550386],
            [126.876290441041, 37.663912779371],
            [126.876136212463, 37.663575750716],
            [126.875964888547, 37.663174806631],
            [126.87591924347, 37.66299602298],
            [126.875870311865, 37.663018981725],
            [126.8757623745, 37.662425774048],
            [126.875725990063, 37.662206519442],
            [126.875718234698, 37.662174896018],
            [126.876347830908, 37.661775389445],
            [126.876774407261, 37.66109125467],
            [126.876938891295, 37.660830830916],
            [126.877310820805, 37.660540686176]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41287101',
      properties: {
        rgnCode: '41287101',
        colCode: '41280',
        rgnKo: ['일산동'],
        rgnSize: 1,
        rgnBbox: [
          126.758159187978, 37.670852854967, 126.781825650288, 37.699263235372
        ],
        rgnCenter: [126.770005192487, 37.683318989486],
        rgnArea: 2587558,
        predVal: [
          0.21379, 0.2137, 0.21469, 0.21452, 0.21454, 0.21454, 0.21454, 0.21452,
          0.21454, 0.21469, 0.21449, 0.21449, 0.21449, 0.21449, 0.21449,
          0.21209, 0.02536, 0.20869, 0.21266, 0.2121, 0.21188, 0.21175, 0.21175,
          0.21175, 0.21175, 0.21175, 0.21175, 0.21049, 0.21021, 0.2076, 0.20548
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.758159187978, 37.679522121321],
            [126.761714030634, 37.684671771867],
            [126.761877652334, 37.684908698732],
            [126.762382854413, 37.685640409451],
            [126.762416820852, 37.685689581086],
            [126.762223986132, 37.68593434343],
            [126.762100015286, 37.686057974952],
            [126.761994297142, 37.686181463218],
            [126.761855269951, 37.686368492051],
            [126.76186589514, 37.686378334195],
            [126.761893627912, 37.686391724873],
            [126.761819402116, 37.686522664895],
            [126.76170133063, 37.686679733887],
            [126.761625834495, 37.686817698747],
            [126.761358687439, 37.687253223554],
            [126.761342733514, 37.687278598362],
            [126.76163886873, 37.687276408066],
            [126.761623989413, 37.687320164843],
            [126.761726671226, 37.687330554602],
            [126.761882627259, 37.687344296017],
            [126.762046677161, 37.687379496698],
            [126.762118170917, 37.687393246462],
            [126.76216009265, 37.687401350167],
            [126.762121199662, 37.687438571297],
            [126.762037499694, 37.687518767706],
            [126.761986671769, 37.68772064347],
            [126.761962969117, 37.687814692413],
            [126.761934695437, 37.687926994683],
            [126.761889161234, 37.688107807507],
            [126.761836874103, 37.688315446415],
            [126.761798976139, 37.688501239043],
            [126.761790615163, 37.688633213825],
            [126.761781743968, 37.6890293513],
            [126.762154409344, 37.689099212488],
            [126.762185795185, 37.689105132475],
            [126.76226556318, 37.689120070198],
            [126.762919684279, 37.68924266565],
            [126.763708633905, 37.689393549523],
            [126.764296456526, 37.689509408549],
            [126.764470692176, 37.689541454935],
            [126.764559877975, 37.689557851583],
            [126.76463597657, 37.689571843323],
            [126.764737456524, 37.689590498931],
            [126.766859722166, 37.689990896291],
            [126.767120962234, 37.690020246771],
            [126.767177845741, 37.690041046032],
            [126.767521570867, 37.690166746803],
            [126.767573222603, 37.690185634451],
            [126.767587559609, 37.690190879493],
            [126.76761522577, 37.690200989167],
            [126.767752143641, 37.690253823095],
            [126.767930491796, 37.690324028499],
            [126.768002816317, 37.690352498151],
            [126.768203042402, 37.690431314662],
            [126.769102094683, 37.690785215057],
            [126.769410470526, 37.690897902751],
            [126.769536402924, 37.690944009785],
            [126.769696526862, 37.691005770638],
            [126.769768209109, 37.691033391033],
            [126.769807617291, 37.691048604723],
            [126.769838192432, 37.691060467467],
            [126.770070905244, 37.691150210287],
            [126.770237371658, 37.691214415465],
            [126.770379377878, 37.691269202407],
            [126.770665542611, 37.691379590862],
            [126.771416131335, 37.691665583289],
            [126.771517371243, 37.691704702574],
            [126.771573653197, 37.691726525643],
            [126.771604455656, 37.691738388371],
            [126.77170626182, 37.691777778888],
            [126.771821204707, 37.691822150225],
            [126.771943734927, 37.691869509413],
            [126.77206354727, 37.691915872121],
            [126.772230696287, 37.691980436197],
            [126.772262517978, 37.691992751218],
            [126.772421513491, 37.692054235883],
            [126.772487535472, 37.692079681305],
            [126.77254166629, 37.692100688863],
            [126.772584812679, 37.692117350429],
            [126.772707682073, 37.692165340158],
            [126.773296098476, 37.692395145865],
            [126.773381188392, 37.692414230817],
            [126.773451671725, 37.692426980689],
            [126.773485907332, 37.692428668634],
            [126.773522636736, 37.692430541584],
            [126.773574670153, 37.692433164877],
            [126.773872543897, 37.692351031446],
            [126.774004743053, 37.692314527091],
            [126.774170190229, 37.692268896821],
            [126.774402573176, 37.692209520347],
            [126.774466342477, 37.692193155436],
            [126.774505375351, 37.692183229841],
            [126.774562790441, 37.692168474387],
            [126.77503343343, 37.692055316713],
            [126.775163802939, 37.69221008293],
            [126.775342839163, 37.69243089316],
            [126.775488101699, 37.692610193884],
            [126.776018699811, 37.693740662254],
            [126.7760627477, 37.693834627255],
            [126.776087966512, 37.693893688816],
            [126.776149636745, 37.693971830445],
            [126.776310830871, 37.694144826762],
            [126.77633238299, 37.694167959706],
            [126.776635272434, 37.6944348906],
            [126.777076068934, 37.694823353421],
            [126.777275571001, 37.694999169251],
            [126.777369147978, 37.695081641481],
            [126.777590160167, 37.695276399943],
            [126.777663597423, 37.695341120682],
            [126.777774985155, 37.695439627567],
            [126.777783314132, 37.695448770165],
            [126.77784753898, 37.695519230488],
            [126.777938034455, 37.695623932281],
            [126.777947537123, 37.695634924074],
            [126.778023278134, 37.695786069701],
            [126.77812427844, 37.695987437974],
            [126.778460499185, 37.696657772081],
            [126.778661152503, 37.697057802287],
            [126.778750535292, 37.697236011204],
            [126.778838633547, 37.697411658878],
            [126.778885985421, 37.697506061475],
            [126.779102739303, 37.697938177665],
            [126.779115729256, 37.697958618068],
            [126.779173839994, 37.698050031604],
            [126.779258609338, 37.69814118903],
            [126.779260586665, 37.69814352626],
            [126.779325047364, 37.69821978842],
            [126.779383018905, 37.698262999877],
            [126.779393212586, 37.698270596207],
            [126.779430491344, 37.698298389162],
            [126.779436012442, 37.698302507967],
            [126.779468392397, 37.698326642769],
            [126.779729482137, 37.69849408321],
            [126.779764792046, 37.698516763875],
            [126.77980044268, 37.698539264977],
            [126.779836776618, 37.698560776289],
            [126.779864509927, 37.698576595335],
            [126.779901866547, 37.698597387776],
            [126.779939225333, 37.698617459435],
            [126.779977152425, 37.698637081668],
            [126.78001598772, 37.698656345207],
            [126.780054597865, 37.698675067731],
            [126.780354893132, 37.69881821827],
            [126.780406140797, 37.698842649478],
            [126.780528689095, 37.698901108662],
            [126.781164023667, 37.699203941148],
            [126.781180943133, 37.699222550691],
            [126.781217935428, 37.699263235372],
            [126.781382751209, 37.699089746731],
            [126.781784415123, 37.699154464481],
            [126.781794252485, 37.699148446336],
            [126.781825650288, 37.699129224152],
            [126.781806754099, 37.69911567445],
            [126.781762059603, 37.699083606858],
            [126.78156786637, 37.698991886807],
            [126.781127961552, 37.69878393328],
            [126.780933769886, 37.698692212186],
            [126.78077017137, 37.698646317144],
            [126.780491057687, 37.698514433404],
            [126.780388659801, 37.69844054237],
            [126.780100625254, 37.698296748216],
            [126.779925573802, 37.698213440383],
            [126.779862894853, 37.698166922807],
            [126.779795589608, 37.698112828379],
            [126.779731579959, 37.698056307501],
            [126.779670978757, 37.697997540584],
            [126.779613785729, 37.697936617728],
            [126.77956022738, 37.697873629462],
            [126.779510416824, 37.697808666101],
            [126.779464352979, 37.697742088033],
            [126.779422262882, 37.697673805594],
            [126.779384259375, 37.697603999194],
            [126.778995913537, 37.696842580832],
            [126.778849929906, 37.696557599767],
            [126.778674976171, 37.696216163243],
            [126.778649902848, 37.696107549351],
            [126.778521619738, 37.695857198429],
            [126.778418409999, 37.695715551409],
            [126.778296214882, 37.695477284701],
            [126.778266803981, 37.695416413755],
            [126.778240570309, 37.695354737946],
            [126.778217400743, 37.695292166966],
            [126.778197294467, 37.695228971105],
            [126.77818047824, 37.695165150794],
            [126.777968162676, 37.694278827573],
            [126.777969937098, 37.694140982961],
            [126.777927721501, 37.69396458365],
            [126.777831297949, 37.693565542601],
            [126.777789196381, 37.69338914344],
            [126.777724159021, 37.693261443068],
            [126.77763973423, 37.692907292761],
            [126.77762956787, 37.692743837979],
            [126.777566306727, 37.692478202768],
            [126.777512984746, 37.69237620208],
            [126.777163093538, 37.690908671693],
            [126.777147109439, 37.690832689709],
            [126.777135208025, 37.690756355103],
            [126.777127389556, 37.690679577781],
            [126.777123766581, 37.690602628246],
            [126.777124225167, 37.690525686476],
            [126.777128765303, 37.690448752471],
            [126.777137612913, 37.69037209695],
            [126.777178741097, 37.690080171542],
            [126.777194689217, 37.69001749452],
            [126.777255728133, 37.689777502686],
            [126.777284803565, 37.689572227399],
            [126.777269797989, 37.689434891363],
            [126.777348110698, 37.688879773711],
            [126.777391395312, 37.688774893209],
            [126.777429276923, 37.688506296534],
            [126.777423874064, 37.688342580374],
            [126.777506586614, 37.687756207276],
            [126.777559246237, 37.687624946131],
            [126.777584497972, 37.687446512284],
            [126.777641492038, 37.687042356045],
            [126.777666743371, 37.686863922161],
            [126.777658801081, 37.686790568267],
            [126.777651964351, 37.68672658654],
            [126.777768751499, 37.685891430289],
            [126.777781377774, 37.685801897979],
            [126.777801534023, 37.685658772327],
            [126.777844816375, 37.685553801496],
            [126.777882581556, 37.685285114205],
            [126.777876950891, 37.68512148764],
            [126.77790463743, 37.684924498311],
            [126.777914715729, 37.684852800318],
            [126.777922194611, 37.684740643906],
            [126.777931486866, 37.684666421708],
            [126.777935902465, 37.684630571527],
            [126.777954141119, 37.684484018503],
            [126.777995030234, 37.6841562335],
            [126.778009433478, 37.684041026898],
            [126.778053462715, 37.683686669222],
            [126.778083200574, 37.683447968831],
            [126.778115378085, 37.683189541826],
            [126.778140818643, 37.682984979981],
            [126.778153131691, 37.682886167013],
            [126.778197968209, 37.682526765314],
            [126.77822631424, 37.682298243153],
            [126.77823095979, 37.682261222122],
            [126.778243853454, 37.682157725192],
            [126.778270106817, 37.68194649765],
            [126.778297982746, 37.681723380373],
            [126.77832330703, 37.681519448894],
            [126.778356526313, 37.681253275373],
            [126.778360593044, 37.681220217499],
            [126.778366051424, 37.681176711016],
            [126.778349640334, 37.681130009761],
            [126.77835468034, 37.68103694918],
            [126.778357476855, 37.680973796531],
            [126.778358686299, 37.680910640878],
            [126.778358988846, 37.680847483508],
            [126.778358837946, 37.680784325279],
            [126.778357100253, 37.680721073951],
            [126.778354455401, 37.680657911002],
            [126.778349316602, 37.680594743334],
            [126.778343043928, 37.680531663616],
            [126.778331664342, 37.680432625559],
            [126.778285522932, 37.680282346653],
            [126.778268930182, 37.680220688946],
            [126.778251884285, 37.680158940281],
            [126.778233704, 37.680097459756],
            [126.778213142633, 37.680036154913],
            [126.778190084668, 37.679975746308],
            [126.778164758183, 37.679915783887],
            [126.778138864396, 37.679856000579],
            [126.778111609248, 37.679796575075],
            [126.77808265212, 37.67973768692],
            [126.778051879386, 37.679679425997],
            [126.778032823432, 37.679645423344],
            [126.778019518039, 37.679621702636],
            [126.777985795073, 37.67956442717],
            [126.777950709949, 37.679507779791],
            [126.777914716918, 37.679451491067],
            [126.777877362005, 37.679395740331],
            [126.77772407435, 37.679177505064],
            [126.777615157049, 37.679019448495],
            [126.777605450415, 37.679005374944],
            [126.777624632722, 37.678997212537],
            [126.777571585819, 37.678919988837],
            [126.777280301063, 37.679054310537],
            [126.776955669984, 37.679194424329],
            [126.77675459677, 37.679260532866],
            [126.776109468825, 37.679532754926],
            [126.776103532504, 37.679535257285],
            [126.776091613915, 37.679540460129],
            [126.775949619421, 37.679600103113],
            [126.775862329425, 37.679638317434],
            [126.77569297629, 37.679710611429],
            [126.775306575658, 37.6798821363],
            [126.775272199216, 37.679854050216],
            [126.775173889086, 37.679899991221],
            [126.775195380199, 37.679915619232],
            [126.774778486102, 37.680086182876],
            [126.77438184379, 37.680269217418],
            [126.774346200121, 37.680285005828],
            [126.774315096918, 37.680298820842],
            [126.77420703052, 37.680346724374],
            [126.774173427825, 37.680362336472],
            [126.774062851071, 37.68041555075],
            [126.77395272788, 37.680468675702],
            [126.773485882365, 37.680660851721],
            [126.773411455997, 37.680680709317],
            [126.773297919124, 37.680515702414],
            [126.774063468325, 37.680213374444],
            [126.774078109746, 37.680207546379],
            [126.774125806525, 37.6801797083],
            [126.774135339827, 37.680176122802],
            [126.774261882987, 37.680128525228],
            [126.774265287655, 37.680127270431],
            [126.777074196843, 37.678871562284],
            [126.777469218873, 37.678694912431],
            [126.77744824766, 37.678664681103],
            [126.777278018773, 37.678419032581],
            [126.777036934512, 37.678070529423],
            [126.775982789651, 37.676543987524],
            [126.775950462734, 37.676438422135],
            [126.775870894741, 37.676323576474],
            [126.775799453325, 37.676220368822],
            [126.775720901619, 37.676106966554],
            [126.775587891434, 37.675972106941],
            [126.775101471747, 37.675267696841],
            [126.775069135398, 37.675165825149],
            [126.774896466588, 37.674915113938],
            [126.774928241934, 37.67482859176],
            [126.774705241959, 37.67450462478],
            [126.774596386849, 37.674479368436],
            [126.774429134636, 37.674237676637],
            [126.774335860095, 37.674158572031],
            [126.773601301586, 37.673095541665],
            [126.773563217417, 37.672983837843],
            [126.773504647177, 37.672899303712],
            [126.773466954921, 37.672844812232],
            [126.77339619749, 37.672742505364],
            [126.773336160708, 37.672655805981],
            [126.773208694661, 37.672527621526],
            [126.772163279537, 37.671014395519],
            [126.772072418818, 37.670852854967],
            [126.771859834224, 37.670945421423],
            [126.771928443694, 37.671044661757],
            [126.773240064691, 37.672943750368],
            [126.773009768943, 37.67304412302],
            [126.771798464037, 37.673572171328],
            [126.770804391572, 37.674005399694],
            [126.768667062307, 37.674936947149],
            [126.76841893166, 37.675045654597],
            [126.766232502152, 37.675999765081],
            [126.765238930749, 37.67643330766],
            [126.764741970404, 37.67665016554],
            [126.762628551564, 37.677572280857],
            [126.762300829728, 37.677715231162],
            [126.761264302825, 37.678167483198],
            [126.760320168248, 37.678579371434],
            [126.758282359353, 37.679468407352],
            [126.758159187978, 37.679522121321]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41287103',
      properties: {
        rgnCode: '41287103',
        colCode: '41280',
        rgnKo: ['탄현동'],
        rgnSize: 1,
        rgnBbox: [
          126.761180809761, 37.687320164843, 126.781180943133, 37.707293726621
        ],
        rgnCenter: [126.768773092039, 37.697751158106],
        rgnArea: 2192083,
        predVal: [
          0.21379, 0.2137, 0.21469, 0.21452, 0.21454, 0.21454, 0.21454, 0.21452,
          0.21454, 0.21469, 0.21449, 0.21449, 0.21449, 0.21449, 0.21449,
          0.21209, 0.02536, 0.20869, 0.21266, 0.2121, 0.21188, 0.21175, 0.21175,
          0.21175, 0.21175, 0.21175, 0.21175, 0.21049, 0.21021, 0.2076, 0.20548
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.781180943133, 37.699222550691],
            [126.781164023667, 37.699203941148],
            [126.780528689095, 37.698901108662],
            [126.780406140797, 37.698842649478],
            [126.780354893132, 37.69881821827],
            [126.780054597865, 37.698675067731],
            [126.78001598772, 37.698656345207],
            [126.779977152425, 37.698637081668],
            [126.779939225333, 37.698617459435],
            [126.779901866547, 37.698597387776],
            [126.779864509927, 37.698576595335],
            [126.779836776618, 37.698560776289],
            [126.77980044268, 37.698539264977],
            [126.779764792046, 37.698516763875],
            [126.779729482137, 37.69849408321],
            [126.779468392397, 37.698326642769],
            [126.779436012442, 37.698302507967],
            [126.779430491344, 37.698298389162],
            [126.779393212586, 37.698270596207],
            [126.779383018905, 37.698262999877],
            [126.779325047364, 37.69821978842],
            [126.779260586665, 37.69814352626],
            [126.779258609338, 37.69814118903],
            [126.779173839994, 37.698050031604],
            [126.779115729256, 37.697958618068],
            [126.779102739303, 37.697938177665],
            [126.778885985421, 37.697506061475],
            [126.778838633547, 37.697411658878],
            [126.778750535292, 37.697236011204],
            [126.778661152503, 37.697057802287],
            [126.778460499185, 37.696657772081],
            [126.77812427844, 37.695987437974],
            [126.778023278134, 37.695786069701],
            [126.777947537123, 37.695634924074],
            [126.777938034455, 37.695623932281],
            [126.77784753898, 37.695519230488],
            [126.777783314132, 37.695448770165],
            [126.777774985155, 37.695439627567],
            [126.777663597423, 37.695341120682],
            [126.777590160167, 37.695276399943],
            [126.777369147978, 37.695081641481],
            [126.777275571001, 37.694999169251],
            [126.777076068934, 37.694823353421],
            [126.776635272434, 37.6944348906],
            [126.77633238299, 37.694167959706],
            [126.776310830871, 37.694144826762],
            [126.776149636745, 37.693971830445],
            [126.776087966512, 37.693893688816],
            [126.7760627477, 37.693834627255],
            [126.776018699811, 37.693740662254],
            [126.775488101699, 37.692610193884],
            [126.775342839163, 37.69243089316],
            [126.775163802939, 37.69221008293],
            [126.77503343343, 37.692055316713],
            [126.774562790441, 37.692168474387],
            [126.774505375351, 37.692183229841],
            [126.774466342477, 37.692193155436],
            [126.774402573176, 37.692209520347],
            [126.774170190229, 37.692268896821],
            [126.774004743053, 37.692314527091],
            [126.773872543897, 37.692351031446],
            [126.773574670153, 37.692433164877],
            [126.773522636736, 37.692430541584],
            [126.773485907332, 37.692428668634],
            [126.773451671725, 37.692426980689],
            [126.773381188392, 37.692414230817],
            [126.773296098476, 37.692395145865],
            [126.772707682073, 37.692165340158],
            [126.772584812679, 37.692117350429],
            [126.77254166629, 37.692100688863],
            [126.772487535472, 37.692079681305],
            [126.772421513491, 37.692054235883],
            [126.772262517978, 37.691992751218],
            [126.772230696287, 37.691980436197],
            [126.77206354727, 37.691915872121],
            [126.771943734927, 37.691869509413],
            [126.771821204707, 37.691822150225],
            [126.77170626182, 37.691777778888],
            [126.771604455656, 37.691738388371],
            [126.771573653197, 37.691726525643],
            [126.771517371243, 37.691704702574],
            [126.771416131335, 37.691665583289],
            [126.770665542611, 37.691379590862],
            [126.770379377878, 37.691269202407],
            [126.770237371658, 37.691214415465],
            [126.770070905244, 37.691150210287],
            [126.769838192432, 37.691060467467],
            [126.769807617291, 37.691048604723],
            [126.769768209109, 37.691033391033],
            [126.769696526862, 37.691005770638],
            [126.769536402924, 37.690944009785],
            [126.769410470526, 37.690897902751],
            [126.769102094683, 37.690785215057],
            [126.768203042402, 37.690431314662],
            [126.768002816317, 37.690352498151],
            [126.767930491796, 37.690324028499],
            [126.767752143641, 37.690253823095],
            [126.76761522577, 37.690200989167],
            [126.767587559609, 37.690190879493],
            [126.767573222603, 37.690185634451],
            [126.767521570867, 37.690166746803],
            [126.767177845741, 37.690041046032],
            [126.767120962234, 37.690020246771],
            [126.766859722166, 37.689990896291],
            [126.764737456524, 37.689590498931],
            [126.76463597657, 37.689571843323],
            [126.764559877975, 37.689557851583],
            [126.764470692176, 37.689541454935],
            [126.764296456526, 37.689509408549],
            [126.763708633905, 37.689393549523],
            [126.762919684279, 37.68924266565],
            [126.76226556318, 37.689120070198],
            [126.762185795185, 37.689105132475],
            [126.762154409344, 37.689099212488],
            [126.761781743968, 37.6890293513],
            [126.761790615163, 37.688633213825],
            [126.761798976139, 37.688501239043],
            [126.761836874103, 37.688315446415],
            [126.761889161234, 37.688107807507],
            [126.761934695437, 37.687926994683],
            [126.761962969117, 37.687814692413],
            [126.761986671769, 37.68772064347],
            [126.762037499694, 37.687518767706],
            [126.762121199662, 37.687438571297],
            [126.76216009265, 37.687401350167],
            [126.762118170917, 37.687393246462],
            [126.762046677161, 37.687379496698],
            [126.761882627259, 37.687344296017],
            [126.761726671226, 37.687330554602],
            [126.761623989413, 37.687320164843],
            [126.7615973321, 37.687395161246],
            [126.761594582739, 37.687403985139],
            [126.761543796215, 37.687546919478],
            [126.761510834633, 37.687639688137],
            [126.761346533851, 37.688068935138],
            [126.761277744275, 37.688376565633],
            [126.761238215207, 37.688540371141],
            [126.761180809761, 37.688938662099],
            [126.761181020507, 37.688971728042],
            [126.761184906405, 37.689566374608],
            [126.761189805926, 37.689629091937],
            [126.761200393378, 37.689791287676],
            [126.761207922069, 37.689882480935],
            [126.761230782723, 37.690422387248],
            [126.761239882069, 37.691081012971],
            [126.761250009687, 37.691420968583],
            [126.76124785001, 37.691492951471],
            [126.761253604006, 37.692063996245],
            [126.761257584513, 37.692165633456],
            [126.761244503269, 37.692459952782],
            [126.76124435648, 37.692575726761],
            [126.761244389405, 37.692987198507],
            [126.761256943048, 37.6934186972],
            [126.761252076129, 37.693521487619],
            [126.761243312386, 37.693742657157],
            [126.761229361221, 37.694060489866],
            [126.761227187171, 37.694242300555],
            [126.761227335727, 37.694266536867],
            [126.761224044419, 37.694408072056],
            [126.761214183509, 37.694793485571],
            [126.761203636021, 37.695286202799],
            [126.761195798066, 37.695641978574],
            [126.761203360758, 37.696179510674],
            [126.761204728874, 37.696282584039],
            [126.76122513837, 37.696633912483],
            [126.761257619906, 37.697074280984],
            [126.761284192154, 37.697097129571],
            [126.761312065996, 37.698262946719],
            [126.761333338585, 37.698979708669],
            [126.761340326787, 37.699203612988],
            [126.761358661722, 37.699783692291],
            [126.761370370514, 37.700154193357],
            [126.761322208982, 37.700601785243],
            [126.761294103698, 37.701264478475],
            [126.76129247612, 37.701487734481],
            [126.761290980227, 37.701635039335],
            [126.761292096739, 37.701780998059],
            [126.761292374411, 37.701800369388],
            [126.761293760749, 37.701968132027],
            [126.76129358223, 37.701988313295],
            [126.761296973679, 37.702518628587],
            [126.761297180414, 37.70255998333],
            [126.76129697458, 37.702694046591],
            [126.761301703196, 37.702915423464],
            [126.761302697319, 37.703029037244],
            [126.761303157595, 37.703062103616],
            [126.7612911563, 37.703548240146],
            [126.761283766773, 37.703754320936],
            [126.761284385825, 37.703822516269],
            [126.76131705188, 37.703827186748],
            [126.76131493036, 37.703876690476],
            [126.761554767454, 37.703911415474],
            [126.763040022688, 37.704131741472],
            [126.763774565985, 37.704430813598],
            [126.764094997157, 37.704689856072],
            [126.76417917272, 37.704643715909],
            [126.764625641002, 37.704281794943],
            [126.764803457489, 37.704136556025],
            [126.765071552267, 37.703915366313],
            [126.76511987238, 37.70387564052],
            [126.765093402362, 37.704069394129],
            [126.765195441885, 37.704393099315],
            [126.76522795104, 37.704640254217],
            [126.765072224443, 37.704739030227],
            [126.765037765457, 37.704734546395],
            [126.765040441836, 37.704784465242],
            [126.764996372733, 37.704913665378],
            [126.765113493718, 37.705027151583],
            [126.765236855561, 37.705103800696],
            [126.765378758956, 37.705233914052],
            [126.765732837838, 37.705362739857],
            [126.765835039315, 37.705461239483],
            [126.765870045431, 37.705472121028],
            [126.765960768978, 37.705506809267],
            [126.766078940059, 37.705575879017],
            [126.766144413378, 37.70563448237],
            [126.766210111891, 37.705693626714],
            [126.766231027293, 37.705713759974],
            [126.766297392996, 37.705920663891],
            [126.766308913461, 37.705971231018],
            [126.766279693504, 37.706067035421],
            [126.766232176444, 37.706175326723],
            [126.766085708435, 37.706447576319],
            [126.766045058702, 37.706571558041],
            [126.766025380153, 37.706626387514],
            [126.766023337294, 37.706770267553],
            [126.765998533096, 37.706832204407],
            [126.766024013991, 37.707000825853],
            [126.766067654494, 37.707293726621],
            [126.767038478958, 37.707179986264],
            [126.767223757236, 37.707129152714],
            [126.767250702285, 37.70712174626],
            [126.767330073104, 37.707099974435],
            [126.767886972871, 37.706947185332],
            [126.768842439425, 37.70650206075],
            [126.769774040408, 37.705953225427],
            [126.770334102019, 37.705617814281],
            [126.77098406337, 37.705428352434],
            [126.771175907866, 37.705364128132],
            [126.771447104243, 37.705272128443],
            [126.771516007376, 37.705066121985],
            [126.772439961971, 37.704594553338],
            [126.772648913865, 37.70460811318],
            [126.772753883648, 37.704583360129],
            [126.773436342083, 37.703816248629],
            [126.773598932452, 37.703089970229],
            [126.77368678745, 37.702739448096],
            [126.774393402284, 37.70250142461],
            [126.774741904152, 37.701781773113],
            [126.775465134347, 37.701370564387],
            [126.775492055132, 37.70135525452],
            [126.776151183776, 37.700802872416],
            [126.776836029722, 37.700592902718],
            [126.77711786906, 37.700234764742],
            [126.778232785411, 37.699992358865],
            [126.779049447661, 37.700028724732],
            [126.77933378049, 37.700042820347],
            [126.779740294322, 37.699888979766],
            [126.779811964217, 37.699810640367],
            [126.780843961567, 37.699489183243],
            [126.780974190632, 37.699362967229],
            [126.781180943133, 37.699222550691]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281106',
      properties: {
        rgnCode: '41281106',
        colCode: '41280',
        rgnKo: ['성사동'],
        rgnSize: 1,
        rgnBbox: [
          126.830360326088, 37.623481093046, 126.863468550135, 37.672230603379
        ],
        rgnCenter: [126.847941669687, 37.65060890955],
        rgnArea: 6257426,
        predVal: [
          0.2633, 0.26775, 0.2562, 0.28773, 0.36048, 0.32776, 0.35959, 0.37818,
          0.32568, 0.34286, 0.34171, 0.26163, 0.37606, 0.33426, 0.38013,
          0.15177, 0.3524, 0.32333, 0.41709, 0.36423, 0.37307, 0.37957, 0.32938,
          0.37301, 0.29859, 0.36386, 0.33257, 0.34243, 0.33479, 0.38347, 0.36859
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.830360326088, 37.648652578443],
            [126.830420260483, 37.648769891223],
            [126.830465671181, 37.648861207807],
            [126.830493028183, 37.64888953808],
            [126.83051507248, 37.648912184503],
            [126.830526489889, 37.64892409392],
            [126.830531237701, 37.648929056163],
            [126.8305655458, 37.649039295107],
            [126.830600614039, 37.649223342998],
            [126.830613514922, 37.64936921149],
            [126.830654054937, 37.649450087649],
            [126.830657813075, 37.649466634989],
            [126.830680513439, 37.649504725038],
            [126.830765786247, 37.64952800354],
            [126.830940908355, 37.649486073232],
            [126.830994824726, 37.649500431626],
            [126.831177349758, 37.649551167914],
            [126.831217264151, 37.649573849014],
            [126.831303561893, 37.649709200858],
            [126.831335472436, 37.64978822634],
            [126.831366967478, 37.649880531581],
            [126.83140137297, 37.649973642877],
            [126.831477262376, 37.650236206212],
            [126.831571435403, 37.6505810458],
            [126.831642449058, 37.650820636137],
            [126.831689244031, 37.650987005396],
            [126.831758506891, 37.651224457817],
            [126.831819169645, 37.651436886766],
            [126.831836882363, 37.651496151316],
            [126.8319490305, 37.651846160948],
            [126.83199867232, 37.651957151252],
            [126.832046689975, 37.652064850643],
            [126.832103158726, 37.65219366297],
            [126.832134751938, 37.652248595694],
            [126.832248480755, 37.652442504455],
            [126.832296299268, 37.652523399464],
            [126.832327547224, 37.65255581629],
            [126.832360492245, 37.652654394086],
            [126.832381630917, 37.652672371802],
            [126.832413986241, 37.652761200194],
            [126.832451159166, 37.652859396602],
            [126.832493946245, 37.653060510321],
            [126.832494931812, 37.653095649729],
            [126.832527226399, 37.653156412648],
            [126.832641576609, 37.65354736495],
            [126.832657758439, 37.653562560519],
            [126.832684975431, 37.653643849324],
            [126.832719148346, 37.653741068288],
            [126.832727983677, 37.653767749765],
            [126.832739684979, 37.653829951741],
            [126.832743160127, 37.653846660774],
            [126.83279835453, 37.653998905244],
            [126.832804736551, 37.65403307931],
            [126.832818633234, 37.654101690342],
            [126.832791708275, 37.654091533897],
            [126.832805243398, 37.654119600578],
            [126.832719069119, 37.654196600689],
            [126.832796513245, 37.654321874793],
            [126.832831481331, 37.65438338024],
            [126.832907751012, 37.65450199439],
            [126.832911229497, 37.65450723402],
            [126.833080127806, 37.654762000454],
            [126.832940210002, 37.654856384847],
            [126.832921009024, 37.654868205215],
            [126.832692499311, 37.655030657387],
            [126.832688811114, 37.655032949592],
            [126.832667903352, 37.655047794749],
            [126.832522609568, 37.655148675978],
            [126.832437183083, 37.655210090091],
            [126.8323776273, 37.655251936043],
            [126.832206423701, 37.655369204481],
            [126.832059991623, 37.655471759312],
            [126.831972894678, 37.655534864515],
            [126.831708498347, 37.655718994666],
            [126.831688549819, 37.655735435751],
            [126.831698241795, 37.655739206764],
            [126.831703298623, 37.655733105441],
            [126.831938145581, 37.655656193767],
            [126.832021349926, 37.655630518462],
            [126.83224947835, 37.655557614856],
            [126.832379774235, 37.655514473906],
            [126.832443669476, 37.65549308628],
            [126.832542165593, 37.655460323837],
            [126.832546554787, 37.65545875342],
            [126.832688008663, 37.655412402622],
            [126.832771522939, 37.655384745087],
            [126.832797266728, 37.655376222657],
            [126.832807745702, 37.655372750874],
            [126.832896601984, 37.655343082708],
            [126.833056292061, 37.655290450715],
            [126.833330023839, 37.655200698846],
            [126.833369092683, 37.655187888631],
            [126.833531605303, 37.655134611314],
            [126.83361225476, 37.655171098448],
            [126.833690668959, 37.655228998496],
            [126.833818638169, 37.655186807675],
            [126.834417700977, 37.654989263279],
            [126.837142073009, 37.657419683597],
            [126.838877810345, 37.658968069903],
            [126.838757183948, 37.659029782387],
            [126.838732813689, 37.659042200213],
            [126.838789592977, 37.659093543938],
            [126.837631536812, 37.659686042591],
            [126.837101913083, 37.659957039146],
            [126.837112682291, 37.659966054878],
            [126.836949672152, 37.660035734095],
            [126.837026700776, 37.660083043518],
            [126.837223334479, 37.660153007821],
            [126.837658445323, 37.660493847527],
            [126.837848299312, 37.660643141064],
            [126.838599094218, 37.661231786025],
            [126.838724923666, 37.661332165965],
            [126.838769069781, 37.661367148595],
            [126.838766143818, 37.661373244146],
            [126.838745928181, 37.661496469365],
            [126.838890621953, 37.66161532708],
            [126.83897965902, 37.661686987053],
            [126.839053194942, 37.661747003058],
            [126.83914868136, 37.661922571988],
            [126.839153114463, 37.661921767214],
            [126.83920737609, 37.662099937213],
            [126.839212865935, 37.662118703023],
            [126.839246995004, 37.66219488219],
            [126.83932022629, 37.662358779782],
            [126.839372560566, 37.662454210702],
            [126.839399960019, 37.66251376663],
            [126.839467964506, 37.662663268402],
            [126.839504003094, 37.662742369263],
            [126.839565171102, 37.662877500077],
            [126.839615398464, 37.662988379672],
            [126.83967085572, 37.663112627846],
            [126.840297245197, 37.663576054039],
            [126.840436222634, 37.663678873705],
            [126.840366772127, 37.663719863202],
            [126.840350782629, 37.663729292561],
            [126.840085258612, 37.663885951003],
            [126.840117121299, 37.663952279164],
            [126.8404799153, 37.664680247325],
            [126.8405321268, 37.664818221452],
            [126.840715245857, 37.665302239401],
            [126.840767467185, 37.665667303524],
            [126.840835566214, 37.666143587239],
            [126.841294342482, 37.666418755579],
            [126.841455947958, 37.666754479038],
            [126.841495482121, 37.666954728709],
            [126.841708528298, 37.6672356884],
            [126.841401768607, 37.667852996913],
            [126.840893370802, 37.668104875206],
            [126.84035029506, 37.668373732287],
            [126.839993948712, 37.668454719754],
            [126.839948730298, 37.668483452889],
            [126.839312420239, 37.668868078451],
            [126.839266049921, 37.669342403394],
            [126.839502877878, 37.669772951969],
            [126.839670961781, 37.669953989732],
            [126.839688794757, 37.669973186878],
            [126.840124184203, 37.670442045442],
            [126.840369268095, 37.671005577899],
            [126.84047986763, 37.671123305742],
            [126.840503930237, 37.671160548717],
            [126.840530929978, 37.671202390651],
            [126.840614248424, 37.671377292819],
            [126.840648189898, 37.671448470817],
            [126.840679749791, 37.671504509236],
            [126.840686418753, 37.671523627936],
            [126.840701161657, 37.671562119521],
            [126.841304563716, 37.672019390831],
            [126.84160936678, 37.672153337427],
            [126.841668176608, 37.672193654525],
            [126.842097538778, 37.672196127663],
            [126.842673233473, 37.672199336976],
            [126.843501882292, 37.672222253512],
            [126.84395152182, 37.672230603379],
            [126.844063541043, 37.67205010819],
            [126.844350448294, 37.67155666835],
            [126.844384576203, 37.671498150669],
            [126.844799122422, 37.671429327379],
            [126.845346340704, 37.671339415437],
            [126.846387238734, 37.671208796676],
            [126.84780622458, 37.670826570164],
            [126.848246666053, 37.670359809266],
            [126.848509170192, 37.670086975428],
            [126.848517180549, 37.670027809989],
            [126.848524783496, 37.670001457415],
            [126.848521754272, 37.66992004167],
            [126.848577958272, 37.669540994601],
            [126.848657540716, 37.668926265489],
            [126.848734037343, 37.668876270555],
            [126.848979746576, 37.668720540205],
            [126.849148953364, 37.668611921476],
            [126.849191853793, 37.668584317043],
            [126.849264613899, 37.668537650532],
            [126.849319423577, 37.668450507169],
            [126.849400755748, 37.66830771791],
            [126.849563057082, 37.668032950411],
            [126.849687363346, 37.667992566734],
            [126.849834811729, 37.667942211897],
            [126.850114392021, 37.667848689785],
            [126.850471496223, 37.667644176433],
            [126.850554988291, 37.667496704066],
            [126.850590591848, 37.667433771666],
            [126.851257356409, 37.667166223276],
            [126.851299832546, 37.667123391096],
            [126.851394578041, 37.667071165225],
            [126.851491855538, 37.666999751787],
            [126.851711816628, 37.666904257984],
            [126.851968483526, 37.666703847031],
            [126.852263412432, 37.666469697465],
            [126.852676298249, 37.66585251245],
            [126.853111340692, 37.665488617195],
            [126.853372207326, 37.665394793474],
            [126.853442345482, 37.665375690824],
            [126.853946027878, 37.665373799307],
            [126.854275973406, 37.665101955714],
            [126.854364758444, 37.665029565236],
            [126.854490333044, 37.664744518919],
            [126.854677047253, 37.664433285222],
            [126.854727099797, 37.664345601781],
            [126.854752190737, 37.664335091621],
            [126.854757136353, 37.664333007517],
            [126.854791687992, 37.664318247517],
            [126.855523021859, 37.664022365479],
            [126.855573635206, 37.664000714666],
            [126.855566635808, 37.663922997098],
            [126.855550867987, 37.663687048833],
            [126.855473116533, 37.663283442867],
            [126.855398791285, 37.662918961315],
            [126.855236141597, 37.662631295276],
            [126.855063687629, 37.662338931769],
            [126.854876495879, 37.661997617824],
            [126.854412756853, 37.661761823369],
            [126.854140054469, 37.661623841309],
            [126.853815394199, 37.661626678748],
            [126.853522522195, 37.661618959735],
            [126.853176731687, 37.661595784838],
            [126.85299083251, 37.661191185133],
            [126.852825283365, 37.660812531763],
            [126.852698927501, 37.660523934827],
            [126.852343609164, 37.658643893182],
            [126.85247142768, 37.658119174517],
            [126.852481049199, 37.658073831656],
            [126.852558846023, 37.657751894874],
            [126.852716709389, 37.65709404986],
            [126.852759701673, 37.656919846723],
            [126.852797776278, 37.656750890043],
            [126.852494293476, 37.6564596997],
            [126.852338268943, 37.655909259655],
            [126.852054264143, 37.655520111839],
            [126.851990094125, 37.655432654051],
            [126.851815201933, 37.655200917931],
            [126.851283081232, 37.654474614874],
            [126.85107989669, 37.654192450104],
            [126.85110624649, 37.65414629976],
            [126.851317425575, 37.653778800109],
            [126.85139248437, 37.653646335291],
            [126.851918431807, 37.652741965563],
            [126.852356639691, 37.65283255482],
            [126.853288138747, 37.653027591593],
            [126.854004988867, 37.653172087439],
            [126.854081108872, 37.653061849259],
            [126.854321196753, 37.65271359806],
            [126.854459117894, 37.652512654522],
            [126.854575980934, 37.652514349815],
            [126.855077909464, 37.652524596338],
            [126.855318465606, 37.652529606793],
            [126.855540562837, 37.6525342065],
            [126.856218116143, 37.652549296366],
            [126.856647385135, 37.652244285773],
            [126.857089201808, 37.651929900805],
            [126.857387357922, 37.651863566287],
            [126.857866677118, 37.651755277122],
            [126.858058005598, 37.651754338672],
            [126.85839601324, 37.651753442803],
            [126.859037567281, 37.651752237229],
            [126.859244940986, 37.651757568997],
            [126.859704354306, 37.651758563621],
            [126.859902313099, 37.651631673877],
            [126.859839659394, 37.651309617284],
            [126.85975005937, 37.650844507509],
            [126.860325056813, 37.650512890488],
            [126.860431718343, 37.650233067146],
            [126.860540681533, 37.649934118745],
            [126.860567396278, 37.64988883162],
            [126.860645452742, 37.649826325104],
            [126.860725774437, 37.649758316282],
            [126.861035445937, 37.649461706024],
            [126.861369579223, 37.649291909223],
            [126.861395439775, 37.649278695606],
            [126.861494775517, 37.64924025181],
            [126.861480179986, 37.649186266073],
            [126.861394147378, 37.64890506867],
            [126.861370330114, 37.648850080891],
            [126.861337431883, 37.648809579008],
            [126.861306248077, 37.648773322747],
            [126.861257832412, 37.648717323643],
            [126.861152334409, 37.648604477158],
            [126.86109879217, 37.648515036696],
            [126.861053415752, 37.648361636686],
            [126.861081992167, 37.648229858018],
            [126.861125436811, 37.648024937837],
            [126.861580043638, 37.647791314441],
            [126.861612357509, 37.647774684721],
            [126.861813555552, 37.647610945619],
            [126.862021165949, 37.647247377393],
            [126.862277699357, 37.647049105438],
            [126.862440878785, 37.646982265333],
            [126.862378211485, 37.646378177421],
            [126.862482007545, 37.646193689881],
            [126.862582732704, 37.646088124096],
            [126.86268161867, 37.645996503162],
            [126.862935014763, 37.645922317422],
            [126.86296378836, 37.645913891055],
            [126.863112583465, 37.645738348413],
            [126.863361298795, 37.645370393116],
            [126.863394267714, 37.644895184994],
            [126.863468550135, 37.643739193663],
            [126.862374676151, 37.643498861702],
            [126.862232399491, 37.643316038942],
            [126.862014875539, 37.64300097188],
            [126.861788822393, 37.642637079411],
            [126.861399081116, 37.642212933128],
            [126.86118801563, 37.642160362585],
            [126.860751424312, 37.642123235831],
            [126.860015205524, 37.641857215856],
            [126.860045821254, 37.641545893177],
            [126.860080687723, 37.641233431329],
            [126.859799312251, 37.640953304476],
            [126.858386254981, 37.640457710454],
            [126.857615980107, 37.640185858876],
            [126.857311377075, 37.639924798422],
            [126.857319345233, 37.639671093157],
            [126.857386898331, 37.639481520122],
            [126.857358019874, 37.639410307687],
            [126.856865605784, 37.638196179464],
            [126.856968386411, 37.637952140705],
            [126.856887244114, 37.637741059514],
            [126.856877728813, 37.637716829591],
            [126.856937786874, 37.637454061205],
            [126.856904926381, 37.637415450112],
            [126.856852162403, 37.637281959754],
            [126.856831004049, 37.637226586795],
            [126.85681104917, 37.637175765242],
            [126.856350904022, 37.636781824437],
            [126.856068375521, 37.636783512702],
            [126.855952792351, 37.636788812064],
            [126.855751389694, 37.636769913196],
            [126.8554507141, 37.636822248509],
            [126.855366145863, 37.636870751496],
            [126.855248956709, 37.63678782451],
            [126.855201754774, 37.636754682109],
            [126.855184445299, 37.636742064984],
            [126.855110317276, 37.636689464085],
            [126.85490544224, 37.636581371747],
            [126.854814680434, 37.636529993339],
            [126.854728645317, 37.635861271228],
            [126.854587425157, 37.635066703846],
            [126.854454468694, 37.634495453733],
            [126.854706814589, 37.633649407805],
            [126.854911606716, 37.63312650983],
            [126.854956083666, 37.632376942682],
            [126.855223917428, 37.632130398152],
            [126.855171493639, 37.631982834077],
            [126.854986902147, 37.631463290399],
            [126.854897627048, 37.631211869748],
            [126.854866205142, 37.631123381701],
            [126.854791370228, 37.630591972977],
            [126.854607251373, 37.629789341866],
            [126.852763005526, 37.629164347315],
            [126.852699143278, 37.628961835013],
            [126.851729793472, 37.628393550819],
            [126.851313373123, 37.627365311521],
            [126.851228918324, 37.627007010994],
            [126.851207747588, 37.626719391692],
            [126.85039207729, 37.626521305864],
            [126.85014065529, 37.626497495086],
            [126.849928403221, 37.626368896233],
            [126.849938677154, 37.626330113294],
            [126.849877759856, 37.626327881688],
            [126.849895939135, 37.626278729638],
            [126.849911192981, 37.626119402164],
            [126.849925575246, 37.626004338643],
            [126.849941061667, 37.625858724351],
            [126.849968881101, 37.625614144377],
            [126.849978362592, 37.625535987643],
            [126.850050507901, 37.625425205831],
            [126.850056985301, 37.625141513963],
            [126.850007042236, 37.625029314032],
            [126.849868237569, 37.624892592397],
            [126.84976211821, 37.624764904404],
            [126.849565206202, 37.624537721468],
            [126.849387506917, 37.624293957927],
            [126.849349006582, 37.624248327764],
            [126.849124371062, 37.623997043175],
            [126.849064200792, 37.623901750002],
            [126.848986997979, 37.623782324585],
            [126.848809152167, 37.623689518982],
            [126.848670942612, 37.623618505078],
            [126.848451446227, 37.623569883046],
            [126.848313944529, 37.62354611698],
            [126.848064101839, 37.623481093046],
            [126.848062779365, 37.62356236864],
            [126.847999752801, 37.623749690286],
            [126.847914947873, 37.624006178725],
            [126.847919909791, 37.624127096552],
            [126.847922926524, 37.624246480179],
            [126.847951490881, 37.624452165778],
            [126.847976744077, 37.62462106009],
            [126.848002050126, 37.624786341536],
            [126.847986901691, 37.625019251868],
            [126.847977727981, 37.625156071573],
            [126.848103429086, 37.625290021392],
            [126.848148456555, 37.625334417086],
            [126.848176215609, 37.625370149937],
            [126.848215901945, 37.625439441715],
            [126.848264550585, 37.625524359078],
            [126.848319098356, 37.625616158547],
            [126.848336742631, 37.625651490806],
            [126.848334346025, 37.625869911946],
            [126.848288812188, 37.626143732303],
            [126.848252003118, 37.626361135675],
            [126.848301318608, 37.62649124696],
            [126.848385018436, 37.626724501817],
            [126.848433671404, 37.626863045338],
            [126.848512471654, 37.627215610307],
            [126.848571556499, 37.627493287358],
            [126.84856766249, 37.627851231998],
            [126.848508243967, 37.628062254182],
            [126.84844697805, 37.628273544224],
            [126.848412067851, 37.628393329039],
            [126.848322065005, 37.62864152182],
            [126.84825200005, 37.628823230213],
            [126.848327497895, 37.629107460719],
            [126.8483228621, 37.629423274553],
            [126.848322083724, 37.629427192797],
            [126.84833278448, 37.629495681023],
            [126.848366226639, 37.629694155838],
            [126.848414478096, 37.629985171284],
            [126.848571451996, 37.630153695562],
            [126.848726003059, 37.630415918194],
            [126.848747925911, 37.630747001899],
            [126.848670395723, 37.631027637185],
            [126.848536384822, 37.631455103618],
            [126.848543445377, 37.631658265235],
            [126.848554561746, 37.631894561052],
            [126.848569918015, 37.632195804824],
            [126.848428605295, 37.632448147577],
            [126.848274103426, 37.632723195674],
            [126.848224651146, 37.632955547606],
            [126.848160044395, 37.633241055504],
            [126.848176373067, 37.633454356112],
            [126.848176836538, 37.633460366234],
            [126.848189925856, 37.633513432796],
            [126.848233114358, 37.633688900206],
            [126.848256709429, 37.633784344364],
            [126.848282829944, 37.633891315296],
            [126.848285964508, 37.633904104236],
            [126.848242862812, 37.634105461745],
            [126.848188398018, 37.634350781168],
            [126.848075895481, 37.634848460903],
            [126.848008575785, 37.63505832806],
            [126.847986423272, 37.635126223909],
            [126.847880552042, 37.635257736887],
            [126.84784109292, 37.635284795903],
            [126.847802071896, 37.635335659294],
            [126.847691286439, 37.635476544867],
            [126.847514530866, 37.635704639918],
            [126.847517690131, 37.635875613453],
            [126.847522479844, 37.63610197217],
            [126.847526775772, 37.636111951593],
            [126.847622511777, 37.636380108153],
            [126.847716553163, 37.636646262246],
            [126.84773388668, 37.636800514073],
            [126.847757555746, 37.637014157537],
            [126.847714334627, 37.637145454626],
            [126.847661246089, 37.637314805101],
            [126.847402630057, 37.637438243784],
            [126.847172001797, 37.637548636329],
            [126.847108194126, 37.637690474708],
            [126.846958168425, 37.637782430183],
            [126.846695931781, 37.637820260759],
            [126.846599469607, 37.637891671699],
            [126.846447937632, 37.637895626145],
            [126.846481760405, 37.637866659141],
            [126.84643593839, 37.637814369299],
            [126.84614003404, 37.637719413477],
            [126.845899087964, 37.637642089447],
            [126.845550383034, 37.638118966495],
            [126.845520069742, 37.638149019042],
            [126.845408096173, 37.638274286899],
            [126.845230941949, 37.638469834603],
            [126.845076829762, 37.638644690126],
            [126.844913367765, 37.638847463306],
            [126.844824652318, 37.638956579921],
            [126.844800753316, 37.638996371344],
            [126.84465586897, 37.639210827464],
            [126.844557521132, 37.639298938312],
            [126.844453220188, 37.639346199887],
            [126.84402814202, 37.639448768036],
            [126.843616215507, 37.639434162242],
            [126.843592781517, 37.639435878772],
            [126.843457906451, 37.639448095602],
            [126.843439080823, 37.63945653956],
            [126.843181770278, 37.63956665425],
            [126.843156574576, 37.639586982503],
            [126.842887214322, 37.639795394921],
            [126.842841606962, 37.639829264366],
            [126.842637763984, 37.639863344279],
            [126.842350151153, 37.639911212927],
            [126.842242713371, 37.639935448362],
            [126.842123553644, 37.640026421204],
            [126.842054249615, 37.640089152639],
            [126.842032387609, 37.640118612046],
            [126.84201764105, 37.640137386479],
            [126.841827190256, 37.640398502098],
            [126.841668877822, 37.64062443277],
            [126.841078378343, 37.640927800856],
            [126.840983025106, 37.641069223526],
            [126.840713402646, 37.641520146311],
            [126.840708842437, 37.641538438921],
            [126.840661686432, 37.641753608822],
            [126.841053297892, 37.642110937615],
            [126.841238856682, 37.642178853139],
            [126.841239257355, 37.642198035463],
            [126.841254266523, 37.642226076206],
            [126.841352812664, 37.642392259904],
            [126.841379866514, 37.642457076801],
            [126.841350230232, 37.642645160357],
            [126.841421514708, 37.642801396239],
            [126.841453294472, 37.642828829026],
            [126.841596353399, 37.642955520044],
            [126.841642027735, 37.642997297129],
            [126.841847993962, 37.643060644362],
            [126.841854629387, 37.643083808423],
            [126.841859813789, 37.643102015152],
            [126.841950146254, 37.643348374066],
            [126.84204188831, 37.643423252075],
            [126.842143144716, 37.643505080379],
            [126.842350530807, 37.64354638177],
            [126.842428904581, 37.643562263563],
            [126.842487059528, 37.643654944261],
            [126.842488249251, 37.643750611476],
            [126.842488190258, 37.643815490664],
            [126.842324870732, 37.644197734623],
            [126.842301383084, 37.644526477975],
            [126.842302321518, 37.644532732012],
            [126.842308779739, 37.644564725364],
            [126.842366401757, 37.64496060171],
            [126.842598135371, 37.645164534568],
            [126.842931248116, 37.645330852404],
            [126.84323081782, 37.645643758066],
            [126.843221601119, 37.6456459621],
            [126.843194818703, 37.645681523705],
            [126.843015830264, 37.645844044601],
            [126.842942982971, 37.645956316373],
            [126.842635114065, 37.646188344826],
            [126.842484051785, 37.64630413287],
            [126.842475802778, 37.646309464535],
            [126.842477576996, 37.646311692336],
            [126.842174691864, 37.646654627346],
            [126.842174354307, 37.646802035467],
            [126.842168966002, 37.648008875513],
            [126.842174548842, 37.648418916829],
            [126.842114399938, 37.648573722199],
            [126.842024049285, 37.648851992365],
            [126.841709596121, 37.649019247291],
            [126.841452898484, 37.649297111354],
            [126.841235287152, 37.649382417536],
            [126.841218823455, 37.649397801839],
            [126.841161046123, 37.649449646505],
            [126.841081950902, 37.649476676334],
            [126.840794481359, 37.649682265933],
            [126.840713642361, 37.64974006143],
            [126.840711129011, 37.649744274566],
            [126.840638569008, 37.649793522034],
            [126.840398776335, 37.650011410899],
            [126.840399647824, 37.650027629636],
            [126.840383158339, 37.650107253289],
            [126.840290114262, 37.65013892157],
            [126.839253125298, 37.650368825113],
            [126.838734975079, 37.650618284956],
            [126.838648536294, 37.650302166789],
            [126.838646221114, 37.650293406118],
            [126.8385788509, 37.650051365325],
            [126.838180198605, 37.649583982968],
            [126.837963550586, 37.649460447478],
            [126.837845347514, 37.649379826494],
            [126.837469714322, 37.649222985623],
            [126.837430169873, 37.649067422358],
            [126.837404454084, 37.648966603532],
            [126.837071776707, 37.648904297367],
            [126.836744399715, 37.648910147412],
            [126.836742133443, 37.648910144246],
            [126.836511255178, 37.648833779272],
            [126.836305382171, 37.648723211816],
            [126.835994704173, 37.648710523081],
            [126.835929055555, 37.64862646009],
            [126.835907432424, 37.648668595367],
            [126.835496134371, 37.648604048067],
            [126.834827927233, 37.64845075098],
            [126.83471836262, 37.648431378329],
            [126.834617553721, 37.648413549621],
            [126.834580005247, 37.648406910368],
            [126.834556762585, 37.648402796061],
            [126.834590177382, 37.648357794595],
            [126.834524403173, 37.648330672262],
            [126.834382446539, 37.648319659541],
            [126.834226418964, 37.648317636448],
            [126.834194112839, 37.648373451084],
            [126.834116991382, 37.648403974829],
            [126.833921142996, 37.648473612432],
            [126.833773197661, 37.648505386782],
            [126.833686496998, 37.648512471275],
            [126.833511806562, 37.64854555871],
            [126.833316800477, 37.648542758119],
            [126.833171331891, 37.648481104142],
            [126.833088776397, 37.648408818216],
            [126.833050191014, 37.648434891398],
            [126.83295952645, 37.648441068722],
            [126.832885688256, 37.648472587425],
            [126.832788151482, 37.648511189893],
            [126.832738214249, 37.648546256486],
            [126.832664483051, 37.648580478128],
            [126.832577438382, 37.648589273166],
            [126.832548076984, 37.648545083345],
            [126.83250589492, 37.64850808295],
            [126.832242731876, 37.64857978365],
            [126.831926293092, 37.64865933577],
            [126.831689118129, 37.648713323381],
            [126.831472156358, 37.648748148886],
            [126.831313983252, 37.648742514948],
            [126.831190199608, 37.648762247847],
            [126.831127967719, 37.648772158806],
            [126.831011932337, 37.648773072333],
            [126.830933404509, 37.648773679595],
            [126.830836452624, 37.64877395382],
            [126.830753039364, 37.648780212016],
            [126.830676303738, 37.648784957175],
            [126.830641726678, 37.648787150527],
            [126.830589334725, 37.648790201014],
            [126.830808945302, 37.649063757637],
            [126.83088104751, 37.649157698475],
            [126.831042829709, 37.649359516466],
            [126.831153681656, 37.649500075497],
            [126.831006870709, 37.649450598101],
            [126.830946149702, 37.649418949179],
            [126.830913666525, 37.649427038012],
            [126.830814689455, 37.649450320185],
            [126.83078475005, 37.649460935393],
            [126.830749862277, 37.649450226367],
            [126.830723093482, 37.649442006766],
            [126.830694105661, 37.649413151623],
            [126.830673009395, 37.649327771732],
            [126.830671391552, 37.649202984377],
            [126.830640206585, 37.649045079411],
            [126.830632476283, 37.64900650649],
            [126.830625140616, 37.648993521827],
            [126.83061763778, 37.648954588844],
            [126.830553367379, 37.648865028921],
            [126.830530094193, 37.648834722436],
            [126.830512469552, 37.648811902227],
            [126.830407085771, 37.648649745132],
            [126.830360326088, 37.648652578443]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41285101',
      properties: {
        rgnCode: '41285101',
        colCode: '41280',
        rgnKo: ['식사동'],
        rgnSize: 1,
        rgnBbox: [
          126.798317136792, 37.659361389582, 126.834748476218, 37.694268770434
        ],
        rgnCenter: [126.816134296688, 37.678906499342],
        rgnArea: 6845357,
        predVal: [
          0.32913, 0.33469, 0.32025, 0.35966, 0.4506, 0.4097, 0.44949, 0.50465,
          0.46269, 0.49412, 0.47228, 0.32704, 0.16823, 0.29084, 0.32127,
          0.18971, 0.2182, 0.37218, 0.52136, 0.37544, 0.3948, 0.46927, 0.41172,
          0.46626, 0.37323, 0.45482, 0.41571, 0.42804, 0.41849, 0.43609, 0.31543
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.834748476218, 37.683160191998],
            [126.834468934228, 37.683000189612],
            [126.834323784138, 37.682917815499],
            [126.834228451729, 37.68286371223],
            [126.834251837713, 37.682697696683],
            [126.834325563643, 37.682225963359],
            [126.834361119637, 37.681991401219],
            [126.834385718895, 37.681840163244],
            [126.834415819723, 37.681657029712],
            [126.834447092627, 37.681466798184],
            [126.833879342966, 37.680823150506],
            [126.833813561147, 37.680746528596],
            [126.833237359674, 37.680075422192],
            [126.833244404654, 37.679716756031],
            [126.83324866594, 37.679084821603],
            [126.833362455007, 37.678844424824],
            [126.833472330852, 37.678579606053],
            [126.833515610935, 37.678489300384],
            [126.833509609463, 37.678285312143],
            [126.833553668638, 37.677799031059],
            [126.833328551562, 37.677793845018],
            [126.833069357268, 37.677649094606],
            [126.832873088519, 37.677539481366],
            [126.832539506893, 37.677381523288],
            [126.832228545496, 37.677236732883],
            [126.831897467444, 37.677020925258],
            [126.831640506216, 37.676813962885],
            [126.831101089855, 37.676843096958],
            [126.830383221482, 37.676785927083],
            [126.829754140999, 37.676765425541],
            [126.829688739319, 37.676763294086],
            [126.829511635755, 37.676830158249],
            [126.829348282107, 37.676980381992],
            [126.828768434129, 37.67684610041],
            [126.828573893171, 37.676806442903],
            [126.828216459222, 37.676815594683],
            [126.828032838312, 37.676808531424],
            [126.827421732334, 37.67627560785],
            [126.827077046558, 37.675972913019],
            [126.826959894697, 37.675658120733],
            [126.827243481146, 37.675291556685],
            [126.82743649814, 37.674817048121],
            [126.827117371562, 37.674363018938],
            [126.827162732144, 37.674073694135],
            [126.826656496187, 37.673877749362],
            [126.826314560675, 37.673828733667],
            [126.826015063904, 37.673755913413],
            [126.825824563859, 37.673597734638],
            [126.82573786009, 37.673511751927],
            [126.825290591872, 37.673548015322],
            [126.82460990701, 37.673160533842],
            [126.824320355262, 37.672944785134],
            [126.824004580807, 37.672707967582],
            [126.823324028967, 37.672626385577],
            [126.823129693017, 37.672743659547],
            [126.822960257821, 37.672705535357],
            [126.822326078492, 37.672378890569],
            [126.822309056103, 37.672259918494],
            [126.821651184722, 37.672077794892],
            [126.820957588679, 37.671568317113],
            [126.820663783202, 37.670963765731],
            [126.820682905035, 37.670604028166],
            [126.820691185757, 37.670554676637],
            [126.820862266594, 37.670304198434],
            [126.820968379079, 37.669874777699],
            [126.820877718221, 37.669681200302],
            [126.820845830275, 37.66964070685],
            [126.820790020793, 37.669569841027],
            [126.820732516466, 37.669496819257],
            [126.820732715056, 37.66948036782],
            [126.820738869034, 37.669419895029],
            [126.820792323809, 37.669092194458],
            [126.820808856932, 37.668990941565],
            [126.820796381302, 37.668975434749],
            [126.820798629908, 37.668968951198],
            [126.820800844315, 37.668962548682],
            [126.820804416974, 37.66895224704],
            [126.820868675133, 37.668773223289],
            [126.820898797835, 37.668695434474],
            [126.820928099165, 37.668624482767],
            [126.82097312671, 37.668509155236],
            [126.820987102248, 37.668434179739],
            [126.821015483917, 37.668293599501],
            [126.821066774631, 37.668006862618],
            [126.82096745688, 37.667742897076],
            [126.820945913193, 37.667685625356],
            [126.820737728845, 37.667506751871],
            [126.820365149976, 37.667376305171],
            [126.820382776803, 37.667296136731],
            [126.820410191001, 37.66717151134],
            [126.820487257037, 37.666833449814],
            [126.820493821462, 37.666804637794],
            [126.820532753177, 37.666767694614],
            [126.820547964603, 37.666753257352],
            [126.820607964595, 37.666684352952],
            [126.820738066439, 37.666540414911],
            [126.821049801218, 37.666015130001],
            [126.821257341766, 37.665877661672],
            [126.821428096933, 37.665770796902],
            [126.821732142882, 37.665568244642],
            [126.821811862302, 37.665461537844],
            [126.821920203188, 37.665273363612],
            [126.821889761504, 37.665028324881],
            [126.821883831055, 37.664954255928],
            [126.821855596525, 37.664719942107],
            [126.821938217184, 37.664612870227],
            [126.822030788225, 37.664336520719],
            [126.821945903258, 37.664132240191],
            [126.821941159261, 37.664120826658],
            [126.821961075198, 37.664096215379],
            [126.82205997276, 37.663973950752],
            [126.822607873483, 37.66365434315],
            [126.822805559235, 37.663539021009],
            [126.822842417829, 37.663448484057],
            [126.822858504303, 37.663408946718],
            [126.822846323012, 37.663384331717],
            [126.822829744105, 37.663350339939],
            [126.822809928678, 37.663314316068],
            [126.822804808196, 37.663303902077],
            [126.822782165999, 37.663265125951],
            [126.822772321158, 37.663235081618],
            [126.822761126109, 37.663200944823],
            [126.822727022157, 37.663158934844],
            [126.822373876395, 37.663315952135],
            [126.821735171066, 37.6634339088],
            [126.820545560265, 37.663728779976],
            [126.819406277656, 37.663785139341],
            [126.818740601371, 37.664036922631],
            [126.818156941405, 37.664257025341],
            [126.818048567411, 37.664220547645],
            [126.817972392889, 37.664225384491],
            [126.817412257619, 37.664280461977],
            [126.817342313945, 37.664102861239],
            [126.817300279869, 37.664097209574],
            [126.817292726661, 37.664039598614],
            [126.817251668447, 37.663726293411],
            [126.816551233729, 37.66336706064],
            [126.816340667329, 37.66327108304],
            [126.815771255774, 37.663207921589],
            [126.815524859802, 37.663250491715],
            [126.815437511296, 37.663182645877],
            [126.815039427064, 37.662907923387],
            [126.814946906678, 37.662778532582],
            [126.81490823617, 37.66272444009],
            [126.814847394984, 37.662635111506],
            [126.814811198684, 37.662588726212],
            [126.8147602191, 37.662385791536],
            [126.814744329401, 37.662322545131],
            [126.814742153897, 37.662290674285],
            [126.814720986734, 37.662004248598],
            [126.814709411536, 37.661847470034],
            [126.814539091781, 37.661649445693],
            [126.814434396122, 37.661418459496],
            [126.814382095583, 37.661119424767],
            [126.814321371934, 37.66051012663],
            [126.814083508685, 37.660394091039],
            [126.813457635141, 37.660026029551],
            [126.813233325361, 37.65989353505],
            [126.813169051751, 37.659877097805],
            [126.812876308812, 37.659804894568],
            [126.812448659956, 37.659700616055],
            [126.81201020495, 37.659772972403],
            [126.81156883891, 37.659679795681],
            [126.811438289226, 37.659619328324],
            [126.811207450195, 37.659462646341],
            [126.811133317857, 37.659401765103],
            [126.811109179708, 37.659361389582],
            [126.810954077879, 37.659578120456],
            [126.810918116934, 37.659769248821],
            [126.811005881201, 37.660017158146],
            [126.811016380078, 37.660121868152],
            [126.810996323245, 37.660231664355],
            [126.810996861245, 37.66025174791],
            [126.810994645868, 37.660355869766],
            [126.810970629147, 37.660573857392],
            [126.811048378894, 37.660896035682],
            [126.811085292029, 37.661028808566],
            [126.811102533203, 37.661111815988],
            [126.811110139393, 37.661128946747],
            [126.811237843008, 37.661404039584],
            [126.81128602354, 37.661509576145],
            [126.811281241694, 37.661669148713],
            [126.811270609733, 37.661860624292],
            [126.811270455883, 37.661947558892],
            [126.811268927102, 37.662058105766],
            [126.811259206308, 37.662225580891],
            [126.811292586377, 37.662557462914],
            [126.811329578262, 37.662784243282],
            [126.811348711878, 37.6629475844],
            [126.81148295449, 37.663291107278],
            [126.811594508755, 37.663576660933],
            [126.811755965984, 37.663835508852],
            [126.811744674331, 37.663836815116],
            [126.811761418124, 37.663866420973],
            [126.811747446345, 37.663874291014],
            [126.811732173169, 37.663881384124],
            [126.811628734554, 37.663893245638],
            [126.8115867328, 37.663897367539],
            [126.811482680013, 37.663901164234],
            [126.811398840189, 37.663934094822],
            [126.811369123209, 37.663943011597],
            [126.811369568777, 37.663990493556],
            [126.811373204063, 37.664009374785],
            [126.811429040545, 37.664091182964],
            [126.811543758722, 37.66430525859],
            [126.811548763137, 37.664342837192],
            [126.811576168635, 37.664351530658],
            [126.811380912376, 37.664430952135],
            [126.81127028986, 37.664477354037],
            [126.811090402853, 37.664574008387],
            [126.810793114212, 37.664708358577],
            [126.8104960933, 37.664848384571],
            [126.810436290316, 37.66487716387],
            [126.810400213227, 37.664891025443],
            [126.810294927738, 37.664932975261],
            [126.810098762935, 37.665011987694],
            [126.809852102398, 37.665114207892],
            [126.809757443536, 37.665166490624],
            [126.80971045349, 37.665192857757],
            [126.809641017833, 37.665198961538],
            [126.809470887735, 37.665180349894],
            [126.80932676243, 37.665186241769],
            [126.809278474781, 37.665188325417],
            [126.808854891919, 37.665311968487],
            [126.808827010866, 37.665333771518],
            [126.808825219259, 37.665369176793],
            [126.808821746421, 37.665463052407],
            [126.808829162214, 37.665509960116],
            [126.808813189983, 37.665550477765],
            [126.808731748981, 37.665662650853],
            [126.808716975113, 37.665699926947],
            [126.808689038396, 37.665769463349],
            [126.808674774087, 37.665802523722],
            [126.80864639237, 37.665864419143],
            [126.808649775762, 37.665870911673],
            [126.808641807512, 37.66588423303],
            [126.808634012516, 37.665874399689],
            [126.808626541618, 37.665875099241],
            [126.808364453375, 37.666020006158],
            [126.8082051881, 37.666050594538],
            [126.808109478455, 37.666070132895],
            [126.808040297795, 37.666086795541],
            [126.80801965788, 37.66609142872],
            [126.807898833126, 37.666180336581],
            [126.807880560782, 37.666202884944],
            [126.807845802862, 37.666262598357],
            [126.807830726874, 37.66631560481],
            [126.807834037043, 37.666332809798],
            [126.807832402616, 37.66638168484],
            [126.807703965808, 37.666477337286],
            [126.807516561545, 37.666628032101],
            [126.807482553861, 37.666673655437],
            [126.807480051693, 37.666676984917],
            [126.807460969683, 37.666692630436],
            [126.80735280577, 37.666750204736],
            [126.807214345075, 37.666777546478],
            [126.807021050682, 37.666795878036],
            [126.806803484113, 37.666820746307],
            [126.80613850265, 37.666780904951],
            [126.805988208287, 37.666782457859],
            [126.8057187946, 37.666783182228],
            [126.805256491815, 37.666777278402],
            [126.80491143219, 37.666838960927],
            [126.804636428495, 37.666896254533],
            [126.804374727915, 37.666937442337],
            [126.804230684125, 37.666932516455],
            [126.803693169127, 37.666951527698],
            [126.803613742314, 37.666941934262],
            [126.803564817129, 37.666936058919],
            [126.80356188709, 37.666942522979],
            [126.803346603592, 37.666918060501],
            [126.80334144277, 37.666910961183],
            [126.803252579977, 37.666920695514],
            [126.803122766399, 37.666934892797],
            [126.80297601507, 37.666967801642],
            [126.802808923496, 37.666977701261],
            [126.80263595078, 37.66694037981],
            [126.802616118883, 37.66698179107],
            [126.802598481798, 37.667007709315],
            [126.80259791317, 37.667008429133],
            [126.802611253498, 37.66702115533],
            [126.802641325263, 37.66705003713],
            [126.802666441774, 37.667067017742],
            [126.802687145927, 37.667081017705],
            [126.802701763706, 37.667124739471],
            [126.802674495638, 37.667191996094],
            [126.802637335463, 37.667228512896],
            [126.802627569362, 37.667235524009],
            [126.802620835586, 37.667252991506],
            [126.802607483048, 37.667287296011],
            [126.802541851011, 37.667332414096],
            [126.802520282225, 37.667345081411],
            [126.802514492671, 37.667348495336],
            [126.802485483518, 37.667346103865],
            [126.80246915938, 37.66737689856],
            [126.802462244687, 37.667389941971],
            [126.802441495011, 37.667435406022],
            [126.802410482966, 37.667461481857],
            [126.802348895325, 37.667519941039],
            [126.802281432841, 37.66757127259],
            [126.802269492937, 37.667585848163],
            [126.802247297827, 37.667620768369],
            [126.802237684129, 37.667655529632],
            [126.802226124578, 37.667697405282],
            [126.802177078289, 37.667770571397],
            [126.802136196986, 37.667757348118],
            [126.802134639426, 37.667788789393],
            [126.802143153808, 37.667804859099],
            [126.802150992335, 37.667819359971],
            [126.802140584121, 37.667875698151],
            [126.80213901413, 37.667975342953],
            [126.802158962711, 37.668017632236],
            [126.802180266883, 37.668044670358],
            [126.802213878863, 37.6680806579],
            [126.802273052296, 37.66811993211],
            [126.802330804579, 37.668160861678],
            [126.802366019583, 37.668195067959],
            [126.802397448622, 37.668208185106],
            [126.802409026449, 37.668214619567],
            [126.802435990737, 37.668231621363],
            [126.802460241812, 37.668245996748],
            [126.802504682155, 37.66827629933],
            [126.802522567775, 37.668297511345],
            [126.802528968506, 37.668307189567],
            [126.802548483754, 37.668329350348],
            [126.802584209542, 37.668405812989],
            [126.802621834245, 37.668450294332],
            [126.802662421541, 37.668488924343],
            [126.802774121829, 37.668595156943],
            [126.802789345586, 37.668624283971],
            [126.802795253334, 37.668636087641],
            [126.802798365013, 37.668642318596],
            [126.802939840393, 37.668633997625],
            [126.802948927354, 37.668669240907],
            [126.803035301542, 37.668666953656],
            [126.803081580224, 37.668909482937],
            [126.803095406092, 37.668952860941],
            [126.803102262792, 37.668974387672],
            [126.803146426149, 37.669117536212],
            [126.803193181367, 37.669232335521],
            [126.803214329421, 37.669284239999],
            [126.803233478838, 37.669329050468],
            [126.803246638035, 37.669367453969],
            [126.803291841171, 37.669503396422],
            [126.803361993452, 37.669804979298],
            [126.80337972067, 37.669873212604],
            [126.803410747237, 37.669969848839],
            [126.803399822486, 37.669986228168],
            [126.80340449055, 37.670021013499],
            [126.803446540864, 37.670064060448],
            [126.803463327359, 37.67010247001],
            [126.803481311836, 37.670159261395],
            [126.803537705729, 37.670221703273],
            [126.803596744717, 37.670269824119],
            [126.803597255218, 37.670290997799],
            [126.803576526076, 37.670286007673],
            [126.803559390653, 37.670336253111],
            [126.80356528256, 37.670422395847],
            [126.803541192293, 37.670487946119],
            [126.803536968325, 37.670499291266],
            [126.80351680862, 37.670535926955],
            [126.803530809975, 37.670641454182],
            [126.803518936506, 37.670758920891],
            [126.803519501579, 37.670844874487],
            [126.803511979643, 37.670902974499],
            [126.803536058993, 37.670969596684],
            [126.803540996376, 37.671031051201],
            [126.803543869683, 37.671058805934],
            [126.803526178093, 37.671104996057],
            [126.803522109453, 37.671143190402],
            [126.803537184323, 37.671271333794],
            [126.803520007347, 37.671294459918],
            [126.803536550122, 37.671382062073],
            [126.803573700766, 37.671435101522],
            [126.803615263469, 37.671448685829],
            [126.803620507074, 37.671480228614],
            [126.803601987368, 37.671496775408],
            [126.803656824444, 37.671548312854],
            [126.803669654487, 37.671582841565],
            [126.803693980502, 37.671642076163],
            [126.803709358037, 37.67169904332],
            [126.803719977364, 37.671755101525],
            [126.803717830683, 37.671795191135],
            [126.803713476816, 37.671855458793],
            [126.803725569936, 37.67191142937],
            [126.803747376836, 37.671937594094],
            [126.803767941222, 37.671962134983],
            [126.803806491701, 37.672000400777],
            [126.803854755546, 37.672051746915],
            [126.803917939804, 37.6720760889],
            [126.803965279312, 37.672134100629],
            [126.804004032321, 37.6721815566],
            [126.804034182088, 37.672224763591],
            [126.80408646426, 37.672299721798],
            [126.804125693963, 37.672424031345],
            [126.80417127971, 37.672545198112],
            [126.804180625437, 37.672568729089],
            [126.804176510784, 37.672667198321],
            [126.804170951902, 37.672711787079],
            [126.804142122207, 37.67276922078],
            [126.804064592361, 37.672854142667],
            [126.804038295143, 37.672896894756],
            [126.80399908762, 37.672978006578],
            [126.803985715334, 37.673019609036],
            [126.803985661122, 37.673040060982],
            [126.803986475738, 37.67307483982],
            [126.803997381068, 37.6731514406],
            [126.804005265839, 37.673212990109],
            [126.804006269037, 37.673219388681],
            [126.804058515778, 37.673350747599],
            [126.804090262711, 37.673433059372],
            [126.804117247801, 37.673473017541],
            [126.804206036351, 37.673545604151],
            [126.804341318573, 37.673627998909],
            [126.804385388733, 37.673666904411],
            [126.804476974158, 37.673835088481],
            [126.804457136665, 37.674049756652],
            [126.804428261153, 37.674124398873],
            [126.804355982902, 37.674237620183],
            [126.804360786598, 37.674307093047],
            [126.804383377627, 37.674637066239],
            [126.804513406356, 37.674589351856],
            [126.804532744137, 37.674606592691],
            [126.804545522961, 37.674617966262],
            [126.804607925673, 37.674638702682],
            [126.804644028824, 37.674647205043],
            [126.804721208259, 37.674665380282],
            [126.804812174255, 37.674686794928],
            [126.804886066557, 37.674735029964],
            [126.804911885749, 37.674744632302],
            [126.804920763948, 37.674747926633],
            [126.804950274788, 37.674758922611],
            [126.80487396641, 37.674853036934],
            [126.804820578783, 37.67489529353],
            [126.8047781949, 37.674934324962],
            [126.804537591196, 37.675131416123],
            [126.804428712144, 37.675196734794],
            [126.80419032598, 37.675326256158],
            [126.803969825955, 37.67546445631],
            [126.803699572273, 37.67559896873],
            [126.803555692678, 37.675653956819],
            [126.803427970238, 37.675685997123],
            [126.803276805189, 37.675709799058],
            [126.802936461275, 37.675772294461],
            [126.802863568746, 37.675731808241],
            [126.802775735418, 37.675683097972],
            [126.802615303608, 37.675581558464],
            [126.802550755297, 37.675540707713],
            [126.802465441671, 37.675486703755],
            [126.802452648335, 37.675480825857],
            [126.802388058504, 37.675451336183],
            [126.800382402666, 37.674535619347],
            [126.800371075602, 37.67453257277],
            [126.799493120817, 37.67479700272],
            [126.799344999019, 37.674827381918],
            [126.799074047441, 37.674882777345],
            [126.798516927693, 37.675294374161],
            [126.798513762093, 37.675332930245],
            [126.798504264783, 37.675448778688],
            [126.798497814063, 37.675528143073],
            [126.79847248901, 37.675836591718],
            [126.798317136792, 37.67618968472],
            [126.798408611084, 37.676523651743],
            [126.798455748818, 37.676863128369],
            [126.798459305492, 37.676889082432],
            [126.798475867024, 37.677009660747],
            [126.798493811407, 37.677163577331],
            [126.79850785128, 37.677252797536],
            [126.798528019518, 37.677381040289],
            [126.798570753827, 37.677485581307],
            [126.798677173375, 37.677681410015],
            [126.798680099567, 37.67768916339],
            [126.798685726709, 37.677704129169],
            [126.798683418132, 37.677736001518],
            [126.798682428846, 37.677749622483],
            [126.798673484524, 37.677870517275],
            [126.798671955443, 37.677890876567],
            [126.798673068234, 37.677919331113],
            [126.798673966723, 37.677943226382],
            [126.798674286682, 37.677950614886],
            [126.798700913492, 37.677996790339],
            [126.798770413742, 37.678117459611],
            [126.79883860199, 37.678553826442],
            [126.798889343181, 37.678694915412],
            [126.79892771259, 37.678800124506],
            [126.799002734446, 37.679016756397],
            [126.79911190873, 37.679472474126],
            [126.799311114177, 37.679502728014],
            [126.7993047045, 37.679775981143],
            [126.799269048451, 37.679881874042],
            [126.799177904691, 37.680049388171],
            [126.798941113962, 37.680304334518],
            [126.798640684629, 37.68054931432],
            [126.798699030512, 37.680716445416],
            [126.798803225767, 37.681014944569],
            [126.79909159859, 37.681523676741],
            [126.799082150609, 37.681871705007],
            [126.799082161643, 37.681951080428],
            [126.799364677666, 37.681988234456],
            [126.799387424524, 37.682086298909],
            [126.799088183661, 37.682071189979],
            [126.799086764069, 37.682176420762],
            [126.79906956923, 37.682371541203],
            [126.798974223739, 37.682957367906],
            [126.798900842184, 37.683178249606],
            [126.79896149008, 37.683471168757],
            [126.798914162574, 37.683490908785],
            [126.798941702931, 37.683618803611],
            [126.79893608749, 37.683682726733],
            [126.798926845506, 37.68378788999],
            [126.798970573166, 37.683924732201],
            [126.798933941943, 37.683971429579],
            [126.798903724303, 37.684035887054],
            [126.798745346159, 37.684162291108],
            [126.79872971579, 37.684198663382],
            [126.798721750755, 37.684292710851],
            [126.798824660564, 37.684344693477],
            [126.798938721268, 37.684340024218],
            [126.799014156265, 37.68436502051],
            [126.799058320514, 37.684466545441],
            [126.799157069688, 37.684464462495],
            [126.799189720494, 37.684464158126],
            [126.799180040282, 37.68435512427],
            [126.7991711336, 37.684253479686],
            [126.799641555963, 37.684147701211],
            [126.800093643819, 37.684449217644],
            [126.800153430907, 37.68472717857],
            [126.800224678166, 37.685211390796],
            [126.800336203123, 37.685306543211],
            [126.800426907499, 37.685555185112],
            [126.800572195604, 37.685742473875],
            [126.801917494992, 37.685934682597],
            [126.802120703946, 37.686000887158],
            [126.802657471598, 37.686186041571],
            [126.802689522962, 37.686240694318],
            [126.802781411422, 37.68636759748],
            [126.803030544647, 37.686537507273],
            [126.803096816555, 37.686560611512],
            [126.803196479339, 37.686595358326],
            [126.803388156657, 37.686663334235],
            [126.803587066289, 37.686714672068],
            [126.803843278917, 37.686546350348],
            [126.804033916, 37.686480899005],
            [126.804215696966, 37.686420297769],
            [126.804412005257, 37.686353593944],
            [126.804574949228, 37.686299357547],
            [126.804669141538, 37.686220680033],
            [126.804876270132, 37.686048760335],
            [126.804908012754, 37.686049173644],
            [126.805194040754, 37.686051271872],
            [126.80542309006, 37.686121793359],
            [126.805764391167, 37.686273092433],
            [126.806034571795, 37.686235943146],
            [126.806091625768, 37.68621643262],
            [126.806731165797, 37.685880789564],
            [126.806979187287, 37.685719565121],
            [126.807143783607, 37.685686897086],
            [126.807390564166, 37.685627127837],
            [126.80767643982, 37.685573909309],
            [126.807799863866, 37.685570138831],
            [126.808045556575, 37.68551855623],
            [126.808046749133, 37.685452246871],
            [126.808332134746, 37.685482626858],
            [126.808541500732, 37.685537027758],
            [126.808824886042, 37.685639660989],
            [126.809064806468, 37.685715824229],
            [126.809757263221, 37.686290869352],
            [126.810005657284, 37.686379567917],
            [126.810060461418, 37.686405784995],
            [126.810196349093, 37.686467091203],
            [126.810492074263, 37.686614221266],
            [126.810831398133, 37.686812073552],
            [126.810958995637, 37.686888267498],
            [126.811115394512, 37.687108987157],
            [126.81115675679, 37.687160589339],
            [126.811320581251, 37.687426279041],
            [126.81145617814, 37.687522703081],
            [126.811736588098, 37.687787561925],
            [126.811929623131, 37.687998969351],
            [126.812036172386, 37.68812337515],
            [126.812182574476, 37.688296208831],
            [126.812495447717, 37.688659837078],
            [126.81255960307, 37.688732602998],
            [126.812506382099, 37.688840994322],
            [126.812491418489, 37.688884937601],
            [126.812479421345, 37.688921497692],
            [126.812454857176, 37.688995788214],
            [126.812151448411, 37.689448578639],
            [126.812146869879, 37.689465689686],
            [126.811990069579, 37.69004557132],
            [126.812069218597, 37.690219044792],
            [126.812080585659, 37.69029663642],
            [126.812158565166, 37.690279237822],
            [126.812202751012, 37.690559086432],
            [126.812566859505, 37.690805904983],
            [126.812691825488, 37.691091639973],
            [126.812895160542, 37.691475507499],
            [126.812964215188, 37.691607069171],
            [126.813001816102, 37.691802369063],
            [126.812973062931, 37.691878581032],
            [126.812951021508, 37.691936982577],
            [126.812885969532, 37.692015533051],
            [126.812700324242, 37.692211376694],
            [126.812783541251, 37.692212861355],
            [126.813279669671, 37.692221402636],
            [126.813833449195, 37.692230997631],
            [126.813938264275, 37.692232813168],
            [126.814589466141, 37.692253488274],
            [126.814661003126, 37.692255763968],
            [126.81469410743, 37.692256807481],
            [126.814704424279, 37.692257094114],
            [126.814898418864, 37.692401222783],
            [126.815033531972, 37.692501606136],
            [126.815262010814, 37.692671340179],
            [126.815310671194, 37.692708626998],
            [126.815539205794, 37.692906479796],
            [126.815783014971, 37.693117600431],
            [126.815972342646, 37.69321619406],
            [126.816306176972, 37.693336998041],
            [126.816921957334, 37.693517796723],
            [126.817565805576, 37.693766298655],
            [126.818123640729, 37.693993968105],
            [126.818152082418, 37.694005607788],
            [126.818649979581, 37.694222432985],
            [126.818881558914, 37.694247658572],
            [126.8189268546, 37.694252602949],
            [126.819075051814, 37.694268770434],
            [126.819171280972, 37.694249818751],
            [126.819632823884, 37.694158993187],
            [126.819833702063, 37.694127318482],
            [126.820039449421, 37.694005103665],
            [126.820134076823, 37.693977859827],
            [126.820180278609, 37.693964542499],
            [126.82023676005, 37.693948258751],
            [126.820257183224, 37.693942370786],
            [126.820265702009, 37.693959457219],
            [126.820585390285, 37.693863499532],
            [126.821061022796, 37.693866120349],
            [126.82150275667, 37.693680042858],
            [126.821885129344, 37.693518974263],
            [126.822464859933, 37.693420749432],
            [126.822537575012, 37.693169309564],
            [126.822771570934, 37.693084432897],
            [126.82349797804, 37.693095262137],
            [126.823607829643, 37.692664828266],
            [126.823648940703, 37.692503734122],
            [126.824138788043, 37.692342207569],
            [126.824502939812, 37.692066472851],
            [126.82487312063, 37.691723831115],
            [126.824902232414, 37.691696818642],
            [126.825454367135, 37.691657821444],
            [126.825842205425, 37.691743135889],
            [126.826885415957, 37.691473223563],
            [126.827063151827, 37.691297527513],
            [126.827632194999, 37.691080378636],
            [126.828044679964, 37.690708705975],
            [126.828603232663, 37.690860825889],
            [126.828629508522, 37.690867909985],
            [126.829109247316, 37.690778290228],
            [126.82928286148, 37.690616955226],
            [126.829580315963, 37.690613109673],
            [126.829770337244, 37.690932329381],
            [126.829825334085, 37.690972871953],
            [126.830053176708, 37.69114595512],
            [126.830295819264, 37.691460745611],
            [126.830837300723, 37.691393147042],
            [126.830910110083, 37.691379332435],
            [126.831635456581, 37.691207213427],
            [126.831785102223, 37.691063994733],
            [126.832099959786, 37.690861729578],
            [126.832065567364, 37.690330739441],
            [126.832057746218, 37.690280093772],
            [126.832051817014, 37.690245037577],
            [126.832003130027, 37.690067026317],
            [126.831972196487, 37.689969361905],
            [126.831945058351, 37.689872919249],
            [126.831928762, 37.689815008583],
            [126.831942915653, 37.689574109928],
            [126.831974024567, 37.68918128727],
            [126.831817158558, 37.689086775276],
            [126.831539520109, 37.688632197099],
            [126.831472880009, 37.688547184724],
            [126.83115885078, 37.68821738237],
            [126.830875583539, 37.687946501994],
            [126.830957067137, 37.687712863488],
            [126.831016670312, 37.687553072738],
            [126.83114511641, 37.687208412496],
            [126.831015432631, 37.686484161309],
            [126.83097564584, 37.686283277767],
            [126.831232818085, 37.685965066678],
            [126.831603996328, 37.685516108484],
            [126.832348297437, 37.685203641151],
            [126.833234743536, 37.685147877981],
            [126.833027643302, 37.684735839294],
            [126.833639725892, 37.684380919322],
            [126.834021146186, 37.684005478059],
            [126.834224864181, 37.683804950104],
            [126.834363948265, 37.683661082276],
            [126.834678457933, 37.683343935825],
            [126.834748476218, 37.683160191998]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281105',
      properties: {
        rgnCode: '41281105',
        colCode: '41280',
        rgnKo: ['도내동'],
        rgnSize: 1,
        rgnBbox: [
          126.848064101839, 37.609020279583, 126.876273513484, 37.641235061209
        ],
        rgnCenter: [126.86245064589, 37.625176718246],
        rgnArea: 4746605,
        predVal: [
          0.39496, 0.40163, 0.3843, 0.43159, 0.54071, 0.49164, 0.53939, 0.56727,
          0.48852, 0.51429, 0.51256, 0.39245, 0.56409, 0.50139, 0.5702, 0.22766,
          0.5286, 0.48499, 0.62564, 0.54635, 0.5596, 0.56936, 0.49407, 0.55951,
          0.44788, 0.54579, 0.49886, 0.51365, 0.50219, 0.57521, 0.55288
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.860080687723, 37.641233431329],
            [126.860192729333, 37.641235061209],
            [126.860252775077, 37.640998086227],
            [126.860436608931, 37.640847572833],
            [126.860414752338, 37.640764062217],
            [126.860655911267, 37.640513932979],
            [126.860916184613, 37.640418965164],
            [126.86096211406, 37.6402400409],
            [126.861089352151, 37.64023938153],
            [126.861214975201, 37.640073300806],
            [126.861348281771, 37.639737124687],
            [126.861448562713, 37.639490195983],
            [126.861610485037, 37.63948110804],
            [126.861658302637, 37.639478371704],
            [126.861735554718, 37.639367192633],
            [126.861820442312, 37.639232146666],
            [126.862029094232, 37.639074632503],
            [126.862168884075, 37.638843336756],
            [126.862350406519, 37.638469285239],
            [126.862661679186, 37.63838550074],
            [126.86316066614, 37.63825121069],
            [126.863314827952, 37.638209766233],
            [126.863603214774, 37.638185957497],
            [126.863933129436, 37.637944700661],
            [126.864232680244, 37.63782558004],
            [126.864323733925, 37.637784222991],
            [126.864592936079, 37.637594537681],
            [126.864831178949, 37.637420114478],
            [126.86485273413, 37.637404372349],
            [126.864657987446, 37.637343285618],
            [126.864370783958, 37.637230690392],
            [126.86411480438, 37.636872731705],
            [126.864180086936, 37.636785710255],
            [126.864272049753, 37.636680574125],
            [126.864297987246, 37.636622860668],
            [126.864322226697, 37.636570533074],
            [126.864481584446, 37.636226905375],
            [126.864565708003, 37.635762729479],
            [126.864574394967, 37.63571480757],
            [126.864606708631, 37.635515999381],
            [126.864740882877, 37.635188109147],
            [126.864896610718, 37.635153692035],
            [126.864989735993, 37.6351174544],
            [126.865018187162, 37.635109468619],
            [126.865212885261, 37.634890206594],
            [126.865419431511, 37.634715024712],
            [126.865532409483, 37.63465135676],
            [126.865734940948, 37.634359430037],
            [126.865804595061, 37.634133932432],
            [126.865829702266, 37.634027024283],
            [126.866061402333, 37.633810524311],
            [126.866159486666, 37.633718538942],
            [126.866413812202, 37.633544049775],
            [126.866760911543, 37.633368747048],
            [126.867037438152, 37.633314373193],
            [126.867376353563, 37.633511964968],
            [126.86745730551, 37.633559196121],
            [126.868035530619, 37.633438489978],
            [126.868551660591, 37.633323486305],
            [126.868920077727, 37.633127568847],
            [126.869123843351, 37.633024194228],
            [126.869436718512, 37.633176440707],
            [126.870161120897, 37.633226442758],
            [126.870400053612, 37.633224356957],
            [126.870960152862, 37.633222184995],
            [126.871363493179, 37.633213089241],
            [126.871444540962, 37.633148100992],
            [126.871457876837, 37.633140664606],
            [126.871521331042, 37.633263988286],
            [126.871536359544, 37.633286439188],
            [126.871615180487, 37.633368244669],
            [126.871752223633, 37.633585711284],
            [126.871806906543, 37.633671904875],
            [126.871981596258, 37.633870492062],
            [126.871987215385, 37.633890139543],
            [126.872051052992, 37.634106786605],
            [126.872126437576, 37.634106562964],
            [126.872386079509, 37.634106351866],
            [126.872531062321, 37.634104258003],
            [126.872609316037, 37.634187638901],
            [126.872799250472, 37.634206793813],
            [126.872818965074, 37.634277983538],
            [126.87283235692, 37.634422082476],
            [126.872840697128, 37.634512955158],
            [126.872791000245, 37.634707440018],
            [126.872759035066, 37.634841876032],
            [126.872703101562, 37.635039300234],
            [126.872722028471, 37.635087766453],
            [126.87273759127, 37.635122714336],
            [126.872773069087, 37.635231014501],
            [126.872786622455, 37.635268392823],
            [126.87283049868, 37.635348546587],
            [126.872997796193, 37.63548837142],
            [126.873005124341, 37.635496082759],
            [126.873022818795, 37.635503832416],
            [126.873102228861, 37.635502360178],
            [126.87322048999, 37.635479567963],
            [126.873270950874, 37.635486217975],
            [126.87333558693, 37.635511416472],
            [126.873383463087, 37.635565220784],
            [126.873465617907, 37.635694050631],
            [126.873556019017, 37.635777687327],
            [126.873640589614, 37.635852415978],
            [126.873780320771, 37.636061035469],
            [126.873805811655, 37.636100976357],
            [126.873958240692, 37.636271822386],
            [126.874028845133, 37.636385079461],
            [126.874076841661, 37.636461894574],
            [126.874189526301, 37.636666952514],
            [126.874274746455, 37.63683344593],
            [126.87432074989, 37.636949919805],
            [126.874429189878, 37.637213347233],
            [126.874440585005, 37.637321242508],
            [126.87442266672, 37.637404653689],
            [126.874411110571, 37.637477755533],
            [126.874425455319, 37.637483591297],
            [126.874439037632, 37.637484741158],
            [126.874457114341, 37.637481282854],
            [126.874455252236, 37.63749030864],
            [126.874549104602, 37.637471867564],
            [126.874579398921, 37.637465917672],
            [126.874684812788, 37.637417693613],
            [126.874857297836, 37.637369595537],
            [126.875022458158, 37.637338139414],
            [126.875078255339, 37.637332234752],
            [126.87513058586, 37.637326200213],
            [126.875187105831, 37.63732164774],
            [126.875280479776, 37.637317891496],
            [126.875431993919, 37.637316215512],
            [126.875450098198, 37.637316505151],
            [126.875669556125, 37.637328307977],
            [126.876161009075, 37.637357878646],
            [126.876273513484, 37.637364034669],
            [126.876171591128, 37.637236384111],
            [126.875867890303, 37.636979976341],
            [126.875906788912, 37.636620825171],
            [126.875892348522, 37.636490132055],
            [126.875885530076, 37.636435156172],
            [126.875747788689, 37.636050364199],
            [126.875467845763, 37.635482368632],
            [126.875345267137, 37.635090726878],
            [126.875080669468, 37.63477081286],
            [126.874962163497, 37.634331062823],
            [126.874911204596, 37.63409746575],
            [126.874905590657, 37.633967457663],
            [126.874894790032, 37.633749454527],
            [126.874875847883, 37.633395566195],
            [126.874888236569, 37.63315832502],
            [126.874911796889, 37.633073883635],
            [126.874962803177, 37.63288497628],
            [126.87522500434, 37.632280403747],
            [126.875249357027, 37.632216045929],
            [126.875370925832, 37.631915195268],
            [126.875448931434, 37.631723469435],
            [126.875469299911, 37.631515941901],
            [126.875432006239, 37.63128758521],
            [126.875401772035, 37.631071768649],
            [126.875337843106, 37.630811092384],
            [126.875285690385, 37.630728110574],
            [126.875257027646, 37.630675291597],
            [126.875195129711, 37.630573874314],
            [126.875106216889, 37.630430163172],
            [126.875096163757, 37.63041285362],
            [126.875025027522, 37.63027483762],
            [126.875007863559, 37.63024211371],
            [126.874953662382, 37.630138352992],
            [126.874886796806, 37.63002079369],
            [126.874870531751, 37.629992485516],
            [126.87483020795, 37.629922346121],
            [126.87477632431, 37.629832010233],
            [126.874635072053, 37.629623732443],
            [126.874531044803, 37.629509286387],
            [126.874523774185, 37.629501304905],
            [126.874520276182, 37.629499841555],
            [126.874419795618, 37.629391733094],
            [126.874341748293, 37.629308650876],
            [126.874229674869, 37.629143344658],
            [126.874137524212, 37.629007134411],
            [126.87401851464, 37.628731486677],
            [126.873980543855, 37.62860446174],
            [126.873947276566, 37.628489451916],
            [126.873866357213, 37.628205375982],
            [126.873818928348, 37.628011857543],
            [126.873802898912, 37.6279456453],
            [126.873769182157, 37.627848681528],
            [126.873863171743, 37.62752442194],
            [126.873981737314, 37.627309279319],
            [126.874103514817, 37.627083535521],
            [126.874442959182, 37.626545611809],
            [126.874543950838, 37.626377669959],
            [126.874587403864, 37.626305043757],
            [126.874649642396, 37.626183505576],
            [126.874724381941, 37.625884740229],
            [126.874675667624, 37.625573949259],
            [126.874547702995, 37.62517070464],
            [126.874485138888, 37.624991369511],
            [126.874324555803, 37.624579521182],
            [126.874090766703, 37.624132851821],
            [126.874063617501, 37.623898784152],
            [126.874013172974, 37.623542221192],
            [126.873945241042, 37.622952609996],
            [126.873904087712, 37.622628285009],
            [126.873873461745, 37.622394438722],
            [126.87385180788, 37.622213201305],
            [126.873822685815, 37.621981086496],
            [126.873802664962, 37.621825375573],
            [126.873748780204, 37.621223543993],
            [126.873705342191, 37.620673304728],
            [126.873330508908, 37.620044419749],
            [126.873141021934, 37.619759540189],
            [126.872805410548, 37.619394115602],
            [126.872650644072, 37.619299578064],
            [126.872238640179, 37.619240095229],
            [126.871651548824, 37.619211484192],
            [126.871575422152, 37.619210688618],
            [126.871395556909, 37.619168342588],
            [126.87128476328, 37.619125946393],
            [126.871207892198, 37.619090939553],
            [126.871063261761, 37.619011745801],
            [126.870998139239, 37.618975373312],
            [126.870938662158, 37.618942845208],
            [126.870925079702, 37.618930784077],
            [126.870655731869, 37.618688978028],
            [126.870529082316, 37.618575287013],
            [126.870479173181, 37.618383521082],
            [126.870423219752, 37.618273386211],
            [126.870412135187, 37.61825157018],
            [126.870401124805, 37.618232664396],
            [126.870333633858, 37.618100803041],
            [126.870318620817, 37.618052151448],
            [126.870282307209, 37.617942678032],
            [126.870214971626, 37.617748495983],
            [126.870058924325, 37.617278686363],
            [126.870057463956, 37.617265386269],
            [126.8700444734, 37.617065363206],
            [126.869919436761, 37.616636258142],
            [126.8698442866, 37.616494269923],
            [126.869611031071, 37.616371286908],
            [126.869112915203, 37.616109760144],
            [126.868929382598, 37.616013644728],
            [126.868747856211, 37.615916981684],
            [126.868644726416, 37.615861365222],
            [126.868301636482, 37.615684926865],
            [126.868060370135, 37.615560174817],
            [126.868003950131, 37.615530459735],
            [126.867751104752, 37.615389269079],
            [126.867619465134, 37.615316365623],
            [126.867432390538, 37.615214351438],
            [126.867098281751, 37.615028891946],
            [126.866663369337, 37.614787996155],
            [126.865784914935, 37.614459870073],
            [126.864870030708, 37.614157274104],
            [126.864615834728, 37.614103695507],
            [126.864343878423, 37.613939860741],
            [126.864185299168, 37.613824260692],
            [126.86400839081, 37.613698548089],
            [126.863839569197, 37.613548608273],
            [126.86376945983, 37.613444877643],
            [126.863723247877, 37.613286251091],
            [126.863533467886, 37.613023537624],
            [126.863476959135, 37.612829382262],
            [126.863103945722, 37.612557290645],
            [126.862797913075, 37.612335587359],
            [126.862228127567, 37.611919050497],
            [126.861965127601, 37.611727155214],
            [126.86182875852, 37.611627931027],
            [126.861279333175, 37.611224991423],
            [126.860817128638, 37.610890159306],
            [126.860527603503, 37.610678425602],
            [126.860361050893, 37.610557702272],
            [126.860327671603, 37.610577159575],
            [126.860206243184, 37.610604719412],
            [126.860052480975, 37.610607904871],
            [126.859887198989, 37.610588065249],
            [126.859793031891, 37.610561481379],
            [126.859643974877, 37.610508369647],
            [126.859470941659, 37.610395809168],
            [126.859223744865, 37.610136974846],
            [126.858966250877, 37.609844656109],
            [126.858723731907, 37.609468987121],
            [126.8584871683, 37.6091073441],
            [126.858432916727, 37.609020279583],
            [126.858297927867, 37.609086319956],
            [126.858167713083, 37.609154960798],
            [126.85801824592, 37.609228974966],
            [126.857772165176, 37.609354074076],
            [126.857552477631, 37.609464410755],
            [126.857328790999, 37.609580904849],
            [126.857235612955, 37.609629732273],
            [126.856820548966, 37.609839143724],
            [126.856186369755, 37.610152500574],
            [126.856091800936, 37.610199415296],
            [126.855822460335, 37.610331392175],
            [126.85557303506, 37.610454536421],
            [126.855165143422, 37.610657571937],
            [126.854715236049, 37.610881339409],
            [126.853734802476, 37.611612252881],
            [126.853687536156, 37.611860323811],
            [126.85361794838, 37.611941505094],
            [126.853549798729, 37.612017020969],
            [126.853381737542, 37.612181419327],
            [126.853550723637, 37.612602037948],
            [126.85360010154, 37.612695036007],
            [126.854050066674, 37.613349783157],
            [126.854115880056, 37.61344262133],
            [126.854182906995, 37.613534695146],
            [126.854232309743, 37.613621548271],
            [126.854252146749, 37.613653422689],
            [126.854453879156, 37.61397915347],
            [126.854475581836, 37.614012715007],
            [126.854471977398, 37.614042866334],
            [126.854464289783, 37.614104709066],
            [126.854462511334, 37.614145097806],
            [126.854450185948, 37.614261426056],
            [126.854437592232, 37.614381709273],
            [126.854437814963, 37.614389385904],
            [126.854575946237, 37.614661239614],
            [126.854584419416, 37.614677909292],
            [126.85457059095, 37.614683243898],
            [126.854486609206, 37.614716709826],
            [126.854537862916, 37.615521033773],
            [126.854627390023, 37.615611243269],
            [126.854552972619, 37.615785580395],
            [126.8547341934, 37.616189535168],
            [126.854998860537, 37.616725181414],
            [126.854918602944, 37.616845272486],
            [126.854660382552, 37.617120741426],
            [126.85465168241, 37.617513017293],
            [126.854685215179, 37.617682803645],
            [126.854720652376, 37.617863404111],
            [126.854728312348, 37.61811532762],
            [126.854731619055, 37.618219394908],
            [126.854739404324, 37.618522854603],
            [126.854842548721, 37.618861697191],
            [126.854855547375, 37.618904383749],
            [126.854875472003, 37.619077045239],
            [126.854883167757, 37.61914380839],
            [126.85488685923, 37.619173202936],
            [126.854917428664, 37.619416433357],
            [126.854993115266, 37.61964725924],
            [126.855010623878, 37.619700673032],
            [126.854873473038, 37.619754579478],
            [126.854810797789, 37.619779215449],
            [126.854805107269, 37.619781442804],
            [126.854726146918, 37.619844809615],
            [126.85443798628, 37.620075858417],
            [126.854166679089, 37.620151652734],
            [126.853972842312, 37.620167447977],
            [126.853955846991, 37.620169859371],
            [126.853920903144, 37.620370373717],
            [126.853906852644, 37.620607773253],
            [126.85385386756, 37.620760837436],
            [126.853795972339, 37.620876486779],
            [126.853571570278, 37.621076556454],
            [126.853733141195, 37.621265351915],
            [126.853756416154, 37.621295203478],
            [126.853971407818, 37.621546764638],
            [126.853984358918, 37.621561908273],
            [126.854125600593, 37.621727180224],
            [126.854439774607, 37.622094793357],
            [126.854456828266, 37.622114735263],
            [126.854467394966, 37.622127100856],
            [126.854483827057, 37.6221463212],
            [126.854489409884, 37.622152860255],
            [126.85432557482, 37.622231491697],
            [126.854312909181, 37.622220844343],
            [126.854062156305, 37.622318017232],
            [126.854008992651, 37.622337411939],
            [126.853473391444, 37.622473509859],
            [126.853278027129, 37.622570930746],
            [126.853171370664, 37.622603952748],
            [126.853122868174, 37.622614613417],
            [126.852964786535, 37.622589997984],
            [126.852715023282, 37.622586980364],
            [126.852659253612, 37.622606461274],
            [126.852597198139, 37.622653864579],
            [126.852547083082, 37.622621095781],
            [126.852505856272, 37.622618611085],
            [126.852420457902, 37.622613457733],
            [126.85234321846, 37.622550021282],
            [126.852268545396, 37.622392796091],
            [126.852221826627, 37.622247859537],
            [126.852085983116, 37.622148940272],
            [126.85196277275, 37.622076886042],
            [126.851835527624, 37.622039964756],
            [126.851810049238, 37.622035932088],
            [126.85175610184, 37.622090093576],
            [126.851569352865, 37.622115957793],
            [126.851359973382, 37.621966417311],
            [126.851156540713, 37.621817298482],
            [126.851072491317, 37.621755924767],
            [126.850986885282, 37.621725092204],
            [126.850779749918, 37.621654560422],
            [126.850462188612, 37.62155453307],
            [126.850324183155, 37.621585268934],
            [126.850027223774, 37.621648722469],
            [126.849640209366, 37.621624421133],
            [126.849394126682, 37.62160676932],
            [126.84906831094, 37.621583887443],
            [126.848781425536, 37.621561793864],
            [126.848588016276, 37.621471607623],
            [126.848544066658, 37.621466054684],
            [126.848299517961, 37.621435401446],
            [126.848297931805, 37.62175569752],
            [126.848229667631, 37.622106188892],
            [126.848157344387, 37.622364504761],
            [126.848085974459, 37.622616181598],
            [126.848082964765, 37.622697202736],
            [126.848078186618, 37.622894628071],
            [126.848074367491, 37.623066340705],
            [126.848069141104, 37.62329444378],
            [126.848064101839, 37.623481093046],
            [126.848313944529, 37.62354611698],
            [126.848451446227, 37.623569883046],
            [126.848670942612, 37.623618505078],
            [126.848809152167, 37.623689518982],
            [126.848986997979, 37.623782324585],
            [126.849064200792, 37.623901750002],
            [126.849124371062, 37.623997043175],
            [126.849349006582, 37.624248327764],
            [126.849387506917, 37.624293957927],
            [126.849565206202, 37.624537721468],
            [126.84976211821, 37.624764904404],
            [126.849868237569, 37.624892592397],
            [126.850007042236, 37.625029314032],
            [126.850056985301, 37.625141513963],
            [126.850050507901, 37.625425205831],
            [126.849978362592, 37.625535987643],
            [126.849968881101, 37.625614144377],
            [126.849941061667, 37.625858724351],
            [126.849925575246, 37.626004338643],
            [126.849911192981, 37.626119402164],
            [126.849895939135, 37.626278729638],
            [126.849877759856, 37.626327881688],
            [126.849938677154, 37.626330113294],
            [126.849928403221, 37.626368896233],
            [126.85014065529, 37.626497495086],
            [126.85039207729, 37.626521305864],
            [126.851207747588, 37.626719391692],
            [126.851228918324, 37.627007010994],
            [126.851313373123, 37.627365311521],
            [126.851729793472, 37.628393550819],
            [126.852699143278, 37.628961835013],
            [126.852763005526, 37.629164347315],
            [126.854607251373, 37.629789341866],
            [126.854791370228, 37.630591972977],
            [126.854866205142, 37.631123381701],
            [126.854897627048, 37.631211869748],
            [126.854986902147, 37.631463290399],
            [126.855171493639, 37.631982834077],
            [126.855223917428, 37.632130398152],
            [126.854956083666, 37.632376942682],
            [126.854911606716, 37.63312650983],
            [126.854706814589, 37.633649407805],
            [126.854454468694, 37.634495453733],
            [126.854587425157, 37.635066703846],
            [126.854728645317, 37.635861271228],
            [126.854814680434, 37.636529993339],
            [126.85490544224, 37.636581371747],
            [126.855110317276, 37.636689464085],
            [126.855184445299, 37.636742064984],
            [126.855201754774, 37.636754682109],
            [126.855248956709, 37.63678782451],
            [126.855366145863, 37.636870751496],
            [126.8554507141, 37.636822248509],
            [126.855751389694, 37.636769913196],
            [126.855952792351, 37.636788812064],
            [126.856068375521, 37.636783512702],
            [126.856350904022, 37.636781824437],
            [126.85681104917, 37.637175765242],
            [126.856831004049, 37.637226586795],
            [126.856852162403, 37.637281959754],
            [126.856904926381, 37.637415450112],
            [126.856937786874, 37.637454061205],
            [126.856877728813, 37.637716829591],
            [126.856887244114, 37.637741059514],
            [126.856968386411, 37.637952140705],
            [126.856865605784, 37.638196179464],
            [126.857358019874, 37.639410307687],
            [126.857386898331, 37.639481520122],
            [126.857319345233, 37.639671093157],
            [126.857311377075, 37.639924798422],
            [126.857615980107, 37.640185858876],
            [126.858386254981, 37.640457710454],
            [126.859799312251, 37.640953304476],
            [126.860080687723, 37.641233431329]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41285102',
      properties: {
        rgnCode: '41285102',
        colCode: '41280',
        rgnKo: ['중산동'],
        rgnSize: 1,
        rgnBbox: [
          126.773297919124, 37.673096143016, 126.794649534417, 37.699129224152
        ],
        rgnCenter: [126.784797645442, 37.685928142955],
        rgnArea: 2843069,
        predVal: [
          0.13165, 0.13388, 0.1281, 0.14386, 0.18024, 0.16388, 0.1798, 0.20186,
          0.18508, 0.19765, 0.18891, 0.13082, 0.06729, 0.11634, 0.12851,
          0.07589, 0.08728, 0.14887, 0.20855, 0.15018, 0.15792, 0.18771,
          0.16469, 0.1865, 0.14929, 0.18193, 0.16629, 0.17122, 0.1674, 0.17444,
          0.12617
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.773297919124, 37.680515702414],
            [126.773411455997, 37.680680709317],
            [126.773485882365, 37.680660851721],
            [126.77395272788, 37.680468675702],
            [126.774062851071, 37.68041555075],
            [126.774173427825, 37.680362336472],
            [126.77420703052, 37.680346724374],
            [126.774315096918, 37.680298820842],
            [126.774346200121, 37.680285005828],
            [126.77438184379, 37.680269217418],
            [126.774778486102, 37.680086182876],
            [126.775195380199, 37.679915619232],
            [126.775173889086, 37.679899991221],
            [126.775272199216, 37.679854050216],
            [126.775306575658, 37.6798821363],
            [126.77569297629, 37.679710611429],
            [126.775862329425, 37.679638317434],
            [126.775949619421, 37.679600103113],
            [126.776091613915, 37.679540460129],
            [126.776103532504, 37.679535257285],
            [126.776109468825, 37.679532754926],
            [126.77675459677, 37.679260532866],
            [126.776955669984, 37.679194424329],
            [126.777280301063, 37.679054310537],
            [126.777571585819, 37.678919988837],
            [126.777624632722, 37.678997212537],
            [126.777605450415, 37.679005374944],
            [126.777742778122, 37.679204128161],
            [126.777924690568, 37.67946728595],
            [126.778043949486, 37.679662526805],
            [126.778121938973, 37.679818407109],
            [126.77813613888, 37.679850058029],
            [126.778187950562, 37.679969156181],
            [126.778237273361, 37.68010931426],
            [126.778283616719, 37.680275360533],
            [126.778331287551, 37.680429741744],
            [126.778351697683, 37.68061729009],
            [126.778358995568, 37.680769549682],
            [126.778358624973, 37.680900838217],
            [126.7783574517, 37.680974625375],
            [126.778349640334, 37.681130009761],
            [126.778366051424, 37.681176711016],
            [126.778360593044, 37.681220217499],
            [126.778356526313, 37.681253275373],
            [126.77832330703, 37.681519448894],
            [126.778297982746, 37.681723380373],
            [126.778270106817, 37.68194649765],
            [126.778243853454, 37.682157725192],
            [126.77823095979, 37.682261222122],
            [126.77822631424, 37.682298243153],
            [126.778197968209, 37.682526765314],
            [126.778153131691, 37.682886167013],
            [126.778140818643, 37.682984979981],
            [126.778115378085, 37.683189541826],
            [126.778083200574, 37.683447968831],
            [126.778053462715, 37.683686669222],
            [126.778009433478, 37.684041026898],
            [126.777995030234, 37.6841562335],
            [126.777954141119, 37.684484018503],
            [126.777935902465, 37.684630571527],
            [126.777931486866, 37.684666421708],
            [126.777922194611, 37.684740643906],
            [126.777914715729, 37.684852800318],
            [126.77790463743, 37.684924498311],
            [126.777876950891, 37.68512148764],
            [126.777882581556, 37.685285114205],
            [126.777844816375, 37.685553801496],
            [126.777801534023, 37.685658772327],
            [126.777781377774, 37.685801897979],
            [126.777768751499, 37.685891430289],
            [126.777651964351, 37.68672658654],
            [126.777658801081, 37.686790568267],
            [126.777666743371, 37.686863922161],
            [126.777641492038, 37.687042356045],
            [126.777584497972, 37.687446512284],
            [126.777559246237, 37.687624946131],
            [126.777506586614, 37.687756207276],
            [126.777423874064, 37.688342580374],
            [126.777429276923, 37.688506296534],
            [126.777391395312, 37.688774893209],
            [126.777348110698, 37.688879773711],
            [126.777269797989, 37.689434891363],
            [126.777284803565, 37.689572227399],
            [126.777255728133, 37.689777502686],
            [126.777194689217, 37.69001749452],
            [126.777178741097, 37.690080171542],
            [126.777137612913, 37.69037209695],
            [126.777128765303, 37.690448752471],
            [126.777124225167, 37.690525686476],
            [126.777123766581, 37.690602628246],
            [126.777127389556, 37.690679577781],
            [126.777135208025, 37.690756355103],
            [126.777147109439, 37.690832689709],
            [126.777163093538, 37.690908671693],
            [126.777512984746, 37.69237620208],
            [126.777566306727, 37.692478202768],
            [126.77762956787, 37.692743837979],
            [126.77763973423, 37.692907292761],
            [126.777724159021, 37.693261443068],
            [126.777789196381, 37.69338914344],
            [126.777831297949, 37.693565542601],
            [126.777927721501, 37.69396458365],
            [126.777969937098, 37.694140982961],
            [126.777968162676, 37.694278827573],
            [126.77818047824, 37.695165150794],
            [126.778197294467, 37.695228971105],
            [126.778217400743, 37.695292166966],
            [126.778240570309, 37.695354737946],
            [126.778266803981, 37.695416413755],
            [126.778296214882, 37.695477284701],
            [126.778418409999, 37.695715551409],
            [126.778521619738, 37.695857198429],
            [126.778649902848, 37.696107549351],
            [126.778674976171, 37.696216163243],
            [126.778849929906, 37.696557599767],
            [126.778995913537, 37.696842580832],
            [126.779384259375, 37.697603999194],
            [126.779422262882, 37.697673805594],
            [126.779464352979, 37.697742088033],
            [126.779510416824, 37.697808666101],
            [126.77956022738, 37.697873629462],
            [126.779613785729, 37.697936617728],
            [126.779670978757, 37.697997540584],
            [126.779731579959, 37.698056307501],
            [126.779795589608, 37.698112828379],
            [126.779862894853, 37.698166922807],
            [126.779925573802, 37.698213440383],
            [126.780100625254, 37.698296748216],
            [126.780388659801, 37.69844054237],
            [126.780491057687, 37.698514433404],
            [126.78077017137, 37.698646317144],
            [126.780933769886, 37.698692212186],
            [126.781127961552, 37.69878393328],
            [126.78156786637, 37.698991886807],
            [126.781762059603, 37.699083606858],
            [126.781806754099, 37.69911567445],
            [126.781825650288, 37.699129224152],
            [126.781925955384, 37.699067694776],
            [126.781929363449, 37.699065538803],
            [126.78193982257, 37.69906015248],
            [126.781986144835, 37.699036273016],
            [126.782257559169, 37.698896407402],
            [126.78233251043, 37.698857715082],
            [126.782486972026, 37.698849352802],
            [126.782684642296, 37.698838547805],
            [126.783086786813, 37.698816679129],
            [126.7833306137, 37.69880343583],
            [126.78348519381, 37.698754528966],
            [126.784571447973, 37.698410921592],
            [126.784636095494, 37.698502849006],
            [126.78517191851, 37.698346164099],
            [126.785157779178, 37.698299242844],
            [126.785197316269, 37.698089074807],
            [126.785208324793, 37.698050569665],
            [126.785273174655, 37.697784101563],
            [126.785441664, 37.697235127008],
            [126.785450750099, 37.697210385088],
            [126.785498708829, 37.697059389822],
            [126.785533497531, 37.696945508267],
            [126.785497324708, 37.69683690253],
            [126.785338426769, 37.696336232343],
            [126.785472470336, 37.696168681905],
            [126.785562820754, 37.696053712825],
            [126.785630599877, 37.695972551684],
            [126.78548362565, 37.695719903643],
            [126.785465427395, 37.695688624766],
            [126.785810137557, 37.695368736763],
            [126.786194808924, 37.69528538854],
            [126.786575061945, 37.695200481343],
            [126.787166321693, 37.694918761958],
            [126.787641358758, 37.69459368154],
            [126.787871197156, 37.6944359784],
            [126.788173841989, 37.694229303825],
            [126.788231001451, 37.694224235666],
            [126.788843199092, 37.694169202033],
            [126.789233522165, 37.694028948963],
            [126.789475651835, 37.693534762478],
            [126.789844327111, 37.692767836518],
            [126.789652432404, 37.692101622801],
            [126.789549776138, 37.691588616522],
            [126.789688212652, 37.691070097023],
            [126.789866059227, 37.690826109896],
            [126.789539088206, 37.690404320138],
            [126.789471351622, 37.690143323095],
            [126.789575689322, 37.689670214139],
            [126.789675075251, 37.689331196249],
            [126.789997666096, 37.688838810558],
            [126.789778621589, 37.688401808296],
            [126.790036125965, 37.688310641866],
            [126.790336301174, 37.688204324925],
            [126.790474160523, 37.688165379585],
            [126.790694848812, 37.688102976809],
            [126.790820567205, 37.688067433037],
            [126.791307526003, 37.687899730838],
            [126.791735313401, 37.687727957121],
            [126.791862582851, 37.687543755209],
            [126.791937117652, 37.687445772227],
            [126.79219311909, 37.687443523979],
            [126.792230057628, 37.687410614091],
            [126.792317119138, 37.687332924885],
            [126.792351813156, 37.687291812164],
            [126.792420973829, 37.687209766482],
            [126.792451303828, 37.687107740524],
            [126.792486682794, 37.687105280524],
            [126.79251325902, 37.687007662641],
            [126.79254703557, 37.686929788719],
            [126.792634079335, 37.686817682243],
            [126.792739395974, 37.686738763691],
            [126.792765970209, 37.686722593298],
            [126.792810260323, 37.686695732725],
            [126.792908267132, 37.686635991715],
            [126.792934614048, 37.686620001075],
            [126.793100872355, 37.686518936039],
            [126.793208491771, 37.686427047185],
            [126.793668919341, 37.685929443681],
            [126.794050357376, 37.685866957394],
            [126.794395328384, 37.685708993248],
            [126.794398573307, 37.685231053039],
            [126.79441146732, 37.685082848294],
            [126.794649534417, 37.684677379646],
            [126.794614242937, 37.683710037464],
            [126.794200235604, 37.683381177507],
            [126.793790695859, 37.683199632401],
            [126.793649843858, 37.682730519898],
            [126.793663553968, 37.682692433065],
            [126.79379666444, 37.682319486134],
            [126.793799749389, 37.682310842263],
            [126.793795913097, 37.682304348528],
            [126.793651498609, 37.682056237449],
            [126.793820846955, 37.68165605494],
            [126.79370865754, 37.681357005783],
            [126.793501286473, 37.681164643644],
            [126.793616988183, 37.68093591132],
            [126.793737724335, 37.680690249517],
            [126.793720920664, 37.680293162647],
            [126.793751405321, 37.680134015032],
            [126.793795935118, 37.679897679046],
            [126.793871294371, 37.679500213843],
            [126.79380404709, 37.679431765891],
            [126.793761747735, 37.679388706126],
            [126.793695291936, 37.679321061368],
            [126.793653307169, 37.679295309746],
            [126.79360023256, 37.679262601092],
            [126.793519884903, 37.679213176381],
            [126.793334182574, 37.679098155299],
            [126.793293443498, 37.679072946329],
            [126.793197819722, 37.679013854051],
            [126.7930607861, 37.678926307968],
            [126.793032609885, 37.678908328867],
            [126.793007938984, 37.678893509351],
            [126.79299311418, 37.678884473445],
            [126.792987455565, 37.678881129852],
            [126.792862754571, 37.678802164554],
            [126.79284476257, 37.678790690407],
            [126.792813759052, 37.67877036373],
            [126.792805839335, 37.6787648538],
            [126.792710341316, 37.678701616887],
            [126.792663693625, 37.678681532765],
            [126.79264750254, 37.678674656722],
            [126.792626669819, 37.67866561013],
            [126.792530745365, 37.678653466312],
            [126.792350051061, 37.678633225558],
            [126.792205676841, 37.678617112364],
            [126.791658020942, 37.678704073839],
            [126.791537708327, 37.678717734639],
            [126.791502669075, 37.678721726616],
            [126.791300373928, 37.678743800379],
            [126.791192254916, 37.678333125794],
            [126.791151493621, 37.678195745299],
            [126.791036442777, 37.677871911668],
            [126.790948274283, 37.677743095794],
            [126.790733294848, 37.677429309408],
            [126.790809592605, 37.677358224063],
            [126.790824533015, 37.677326176222],
            [126.790967663642, 37.677019201052],
            [126.789870256416, 37.676500981105],
            [126.78885044219, 37.676019371132],
            [126.788109138905, 37.675669284662],
            [126.788028867775, 37.675631379737],
            [126.787503762445, 37.67538338209],
            [126.787621555063, 37.675116602245],
            [126.787814740539, 37.674679026805],
            [126.787925913526, 37.674427226774],
            [126.788160601819, 37.673895718605],
            [126.788180225147, 37.673851426353],
            [126.788197795492, 37.67381154514],
            [126.788104052969, 37.673772183349],
            [126.787948042095, 37.673706670705],
            [126.787795428147, 37.673642515465],
            [126.787602849475, 37.673561619601],
            [126.787128451447, 37.673372455445],
            [126.786771714272, 37.673222515679],
            [126.786670569079, 37.67318003993],
            [126.786495925799, 37.673106689017],
            [126.786468862807, 37.673152039584],
            [126.786454260619, 37.673176510349],
            [126.786333957335, 37.673378126349],
            [126.786251774991, 37.673496814398],
            [126.786186123545, 37.673582557048],
            [126.786171985665, 37.673599271268],
            [126.785958754383, 37.673499432652],
            [126.785919719302, 37.673474854911],
            [126.785820287505, 37.673404397423],
            [126.785556392602, 37.673096143016],
            [126.785542960893, 37.673115579386],
            [126.784772825479, 37.674529140732],
            [126.784716844379, 37.674601836246],
            [126.784517040196, 37.674861578934],
            [126.784161427748, 37.675258071815],
            [126.783794235937, 37.675581563638],
            [126.782979163731, 37.676179651836],
            [126.782717895591, 37.67632863838],
            [126.781816737211, 37.676745283577],
            [126.780398828301, 37.677373132596],
            [126.779425059011, 37.677801966548],
            [126.779083953245, 37.677957912283],
            [126.778922197417, 37.678031756921],
            [126.778566673436, 37.678194251018],
            [126.777562411874, 37.678653284437],
            [126.777469218873, 37.678694912431],
            [126.777074196843, 37.678871562284],
            [126.774265287655, 37.680127270431],
            [126.774261882987, 37.680128525228],
            [126.774135339827, 37.680176122802],
            [126.774125806525, 37.6801797083],
            [126.774078109746, 37.680207546379],
            [126.774063468325, 37.680213374444],
            [126.773297919124, 37.680515702414]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41285103',
      properties: {
        rgnCode: '41285103',
        colCode: '41280',
        rgnKo: ['정발산동'],
        rgnSize: 1,
        rgnBbox: [
          126.76722430241, 37.662474538548, 126.788528595379, 37.678694912431
        ],
        rgnCenter: [126.778020773216, 37.670459862161],
        rgnArea: 1546630,
        predVal: [
          0.2633, 0.26775, 0.2562, 0.28773, 0.36048, 0.32776, 0.35959, 0.40372,
          0.37016, 0.3953, 0.37782, 0.26163, 0.13458, 0.23267, 0.25701, 0.15177,
          0.17456, 0.29774, 0.41709, 0.30035, 0.31584, 0.37541, 0.32938,
          0.37301, 0.29859, 0.36386, 0.33257, 0.34243, 0.33479, 0.34887, 0.25234
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.76722430241, 37.666095487107],
            [126.767225456233, 37.666096201166],
            [126.767636533946, 37.666351090848],
            [126.76873596035, 37.66703268802],
            [126.768846474289, 37.667103362007],
            [126.768954032859, 37.667176823075],
            [126.769058749967, 37.66725289126],
            [126.769160512554, 37.66733147625],
            [126.769258980598, 37.667412577385],
            [126.769354267719, 37.667496104798],
            [126.769446260855, 37.667581968177],
            [126.769534733601, 37.667670076988],
            [126.769619799574, 37.667760341364],
            [126.769701232365, 37.667852670772],
            [126.769778918905, 37.667946974899],
            [126.769852972808, 37.668043163878],
            [126.769923167662, 37.668141147174],
            [126.771792015306, 37.670847173655],
            [126.771859834224, 37.670945421423],
            [126.772072418818, 37.670852854967],
            [126.772163279537, 37.671014395519],
            [126.773208694661, 37.672527621526],
            [126.773336160708, 37.672655805981],
            [126.77339619749, 37.672742505364],
            [126.773466954921, 37.672844812232],
            [126.773504647177, 37.672899303712],
            [126.773563217417, 37.672983837843],
            [126.773601301586, 37.673095541665],
            [126.774335860095, 37.674158572031],
            [126.774429134636, 37.674237676637],
            [126.774596386849, 37.674479368436],
            [126.774705241959, 37.67450462478],
            [126.774928241934, 37.67482859176],
            [126.774896466588, 37.674915113938],
            [126.775069135398, 37.675165825149],
            [126.775101471747, 37.675267696841],
            [126.775587891434, 37.675972106941],
            [126.775720901619, 37.676106966554],
            [126.775799453325, 37.676220368822],
            [126.775870894741, 37.676323576474],
            [126.775950462734, 37.676438422135],
            [126.775982789651, 37.676543987524],
            [126.777036934512, 37.678070529423],
            [126.777278018773, 37.678419032581],
            [126.77744824766, 37.678664681103],
            [126.777469218873, 37.678694912431],
            [126.777562411874, 37.678653284437],
            [126.778566673436, 37.678194251018],
            [126.778922197417, 37.678031756921],
            [126.779083953245, 37.677957912283],
            [126.779425059011, 37.677801966548],
            [126.780398828301, 37.677373132596],
            [126.781816737211, 37.676745283577],
            [126.782717895591, 37.67632863838],
            [126.782979163731, 37.676179651836],
            [126.783794235937, 37.675581563638],
            [126.784161427748, 37.675258071815],
            [126.784517040196, 37.674861578934],
            [126.784716844379, 37.674601836246],
            [126.784772825479, 37.674529140732],
            [126.785542960893, 37.673115579386],
            [126.785556392602, 37.673096143016],
            [126.785719734547, 37.672860027301],
            [126.785725255149, 37.672852045792],
            [126.785725710478, 37.672851379907],
            [126.785759915503, 37.672801898108],
            [126.785896737595, 37.672603123901],
            [126.785915746953, 37.672575498852],
            [126.785982563881, 37.672478496334],
            [126.785996953483, 37.67244131254],
            [126.786197185887, 37.672107778506],
            [126.786424649151, 37.671764202898],
            [126.78670264647, 37.671269265678],
            [126.786799896725, 37.671112673823],
            [126.786859156645, 37.67100187217],
            [126.786962177305, 37.670809251839],
            [126.787262111594, 37.670290567477],
            [126.787406391144, 37.670018015442],
            [126.787763476527, 37.669409336342],
            [126.788129771863, 37.668748866907],
            [126.788305265605, 37.668458530925],
            [126.788421242973, 37.668266779559],
            [126.788485654368, 37.668160274959],
            [126.788528595379, 37.668086562951],
            [126.788142843596, 37.667944052893],
            [126.787834537615, 37.667830152589],
            [126.783415099452, 37.66619698164],
            [126.783235196568, 37.666130517554],
            [126.781610317133, 37.668908797997],
            [126.781430522683, 37.668842331385],
            [126.779559003448, 37.668150572712],
            [126.779398127154, 37.668088733548],
            [126.779239870091, 37.66802284473],
            [126.77908423201, 37.667952996364],
            [126.778931439074, 37.667879369083],
            [126.778781604639, 37.66780196311],
            [126.778716653276, 37.667766071887],
            [126.778634841792, 37.667720868768],
            [126.778491377227, 37.667636086497],
            [126.778351437098, 37.667547796931],
            [126.778214907801, 37.667456089964],
            [126.778082129366, 37.667360966252],
            [126.777953214327, 37.667262696312],
            [126.777828162955, 37.66716119006],
            [126.777707201661, 37.667056538036],
            [126.777590442973, 37.666949010755],
            [126.777478000225, 37.666838608447],
            [126.777369760343, 37.666725240811],
            [126.777303226763, 37.666650694126],
            [126.777215242747, 37.666548176503],
            [126.777130891546, 37.666443773683],
            [126.777050172877, 37.666337575771],
            [126.776973200072, 37.66622958299],
            [126.776170647249, 37.665064357705],
            [126.776135090774, 37.665016358084],
            [126.77609590182, 37.66497024355],
            [126.776053080116, 37.664926104197],
            [126.776007078472, 37.664884121081],
            [126.775957783006, 37.664844474174],
            [126.775905533185, 37.664807344317],
            [126.775850555138, 37.664772912133],
            [126.7757929622, 37.664741177833],
            [126.775732980496, 37.664712322043],
            [126.775670836152, 37.664686525385],
            [126.775606869445, 37.664663698411],
            [126.775541305953, 37.664644201938],
            [126.77547425955, 37.664627855988],
            [126.775406183031, 37.664614841621],
            [126.775337189452, 37.664605249148],
            [126.775267505751, 37.664598988906],
            [126.775197584719, 37.664596241958],
            [126.775127426621, 37.664596918205],
            [126.775057598406, 37.664600928639],
            [126.774988212853, 37.664608453671],
            [126.774851586893, 37.66462621065],
            [126.774785262495, 37.664633381057],
            [126.774718494056, 37.664637487268],
            [126.77465162187, 37.664638439842],
            [126.774584759007, 37.664636329092],
            [126.774518132701, 37.664630975261],
            [126.77445208242, 37.664622559198],
            [126.774386721785, 37.664610991024],
            [126.774322390266, 37.664596451591],
            [126.774259314818, 37.664578851238],
            [126.774197494899, 37.664558370162],
            [126.774137384138, 37.664534919142],
            [126.774079095052, 37.664508768688],
            [126.774022967656, 37.66447991946],
            [126.773968888343, 37.664448461338],
            [126.773957688076, 37.664441862638],
            [126.772035520893, 37.663251919494],
            [126.771856791636, 37.66314120261],
            [126.771164729156, 37.662712702437],
            [126.77098600221, 37.662602074329],
            [126.770781601192, 37.662474538548],
            [126.770619386568, 37.662638873759],
            [126.770521745986, 37.662782396623],
            [126.77009799057, 37.663215582207],
            [126.769436374906, 37.66388791192],
            [126.768723716568, 37.664613006118],
            [126.76837557146, 37.664969932161],
            [126.768027446117, 37.665323100138],
            [126.767761326785, 37.665594107844],
            [126.767646635135, 37.665667534918],
            [126.76722430241, 37.666095487107]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281114',
      properties: {
        rgnCode: '41281114',
        colCode: '41280',
        rgnKo: ['벽제동'],
        rgnSize: 1,
        rgnBbox: [
          126.888464185378, 37.694747782944, 126.933108441103, 37.748491530913
        ],
        rgnCenter: [126.911647261551, 37.7267075653],
        rgnArea: 10240725,
        predVal: [
          0.13165, 0.13388, 0.1281, 0.14386, 0.18024, 0.16388, 0.1798, 0.18909,
          0.16284, 0.17143, 0.17085, 0.13082, 0.18803, 0.16713, 0.19007,
          0.07589, 0.1762, 0.16166, 0.20855, 0.18212, 0.18653, 0.18979, 0.16469,
          0.1865, 0.14929, 0.18193, 0.16629, 0.17122, 0.1674, 0.19174, 0.18429
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.8885718282, 37.72300905969],
            [126.889985349027, 37.724207128832],
            [126.890729395007, 37.725182294834],
            [126.891196541085, 37.725346005555],
            [126.891626155521, 37.725616858084],
            [126.892300571533, 37.725538380194],
            [126.892407616056, 37.725638909737],
            [126.892468483268, 37.725685176482],
            [126.892593151674, 37.725571391905],
            [126.892724146514, 37.725629994417],
            [126.892829754051, 37.725681978326],
            [126.893236870835, 37.725937776158],
            [126.89353816428, 37.725986560388],
            [126.894268032101, 37.727068617163],
            [126.895341494249, 37.726941435807],
            [126.895400545088, 37.726935092135],
            [126.897026194367, 37.727138251948],
            [126.897041277853, 37.727140472677],
            [126.897470693983, 37.72687029311],
            [126.899447384694, 37.726836712366],
            [126.899474617609, 37.726838366685],
            [126.900261392475, 37.727167356191],
            [126.901003984395, 37.727879572615],
            [126.902142860093, 37.728137492901],
            [126.902832811688, 37.728704237845],
            [126.902860227697, 37.728987433548],
            [126.902845710131, 37.72901139603],
            [126.902381462007, 37.729811251044],
            [126.902136183683, 37.730323818649],
            [126.902255098543, 37.73115828263],
            [126.902340977552, 37.731715528369],
            [126.902466289273, 37.731907070306],
            [126.902738893604, 37.732132350539],
            [126.902911331989, 37.732220086505],
            [126.903266906128, 37.732920287261],
            [126.903185846528, 37.733301750313],
            [126.902500603266, 37.734515115561],
            [126.901349734712, 37.735599820837],
            [126.901282904333, 37.736499690074],
            [126.901077516275, 37.736846097332],
            [126.901032044321, 37.736981887611],
            [126.900994691218, 37.737179283565],
            [126.901658646454, 37.737695864838],
            [126.902054202983, 37.73844725908],
            [126.901862156805, 37.738927687893],
            [126.902422655454, 37.739690121713],
            [126.903041402509, 37.740520110496],
            [126.903552262622, 37.740768660966],
            [126.903524885635, 37.741638309194],
            [126.905873577826, 37.742662595125],
            [126.906822911262, 37.743646292151],
            [126.907879108925, 37.744049378994],
            [126.907899675177, 37.744088470007],
            [126.908127839779, 37.74498356645],
            [126.90873193365, 37.745622293161],
            [126.909230834821, 37.746663708835],
            [126.909362986775, 37.746936866347],
            [126.910965175994, 37.746586373133],
            [126.911722660616, 37.747378914977],
            [126.912341685312, 37.74748377023],
            [126.91245576625, 37.747423591242],
            [126.912801997139, 37.747209009313],
            [126.913024928822, 37.74707723123],
            [126.914478544395, 37.747232467975],
            [126.915217870986, 37.747831573721],
            [126.915437741648, 37.747881656721],
            [126.915695530093, 37.747707905286],
            [126.917036330906, 37.748055707678],
            [126.917985294654, 37.747865110144],
            [126.917997134007, 37.747850586042],
            [126.918245869075, 37.747619800822],
            [126.919690117544, 37.747037764738],
            [126.920254094775, 37.74705227298],
            [126.921643646641, 37.747011949827],
            [126.922942986516, 37.747021247175],
            [126.923677703455, 37.747361414842],
            [126.924482297763, 37.747571020368],
            [126.925681669858, 37.748196253881],
            [126.927033506531, 37.748275616615],
            [126.928294867485, 37.748413776426],
            [126.928357574352, 37.748419446369],
            [126.929009178305, 37.748484222655],
            [126.929084830131, 37.748491530913],
            [126.929664884517, 37.748121365979],
            [126.93021696685, 37.747771984425],
            [126.930695916347, 37.747470253195],
            [126.93131673822, 37.747047110046],
            [126.931880173293, 37.746661941234],
            [126.93219439885, 37.74645380633],
            [126.932585305257, 37.746203820776],
            [126.933108441103, 37.74586363446],
            [126.93038679983, 37.744121682159],
            [126.930479286688, 37.74001946245],
            [126.931176155595, 37.737637358688],
            [126.93046019914, 37.736784231656],
            [126.930645371141, 37.736258109099],
            [126.930662948016, 37.736027824687],
            [126.930023056911, 37.736034303648],
            [126.929399277447, 37.736096873898],
            [126.928582145755, 37.736151546065],
            [126.927140787245, 37.735135161712],
            [126.926598368615, 37.735205048239],
            [126.926541037574, 37.734075826441],
            [126.925543934594, 37.73307720066],
            [126.92517059257, 37.733058705944],
            [126.923346203327, 37.73286512765],
            [126.922780706591, 37.732351157434],
            [126.922125220497, 37.731831213575],
            [126.922108930557, 37.731809885819],
            [126.922093346284, 37.731786378207],
            [126.921993530818, 37.731414564581],
            [126.921848917796, 37.730482934189],
            [126.921651147312, 37.730252649325],
            [126.921690293483, 37.729831124165],
            [126.921749503174, 37.729222013956],
            [126.922432023409, 37.729011954718],
            [126.923395585041, 37.728685988963],
            [126.923356739078, 37.727849508708],
            [126.923367920295, 37.727744797126],
            [126.923464723211, 37.72726007977],
            [126.923369124097, 37.726556021418],
            [126.923441815297, 37.726163411546],
            [126.923242051552, 37.725753133303],
            [126.923119590969, 37.725376908507],
            [126.92307694862, 37.724735231611],
            [126.923173376307, 37.724540182957],
            [126.923664818581, 37.724259425747],
            [126.92375369746, 37.723829192942],
            [126.92419550104, 37.723577177527],
            [126.923810542054, 37.723128811822],
            [126.923857603855, 37.722530413314],
            [126.92405691295, 37.722206818918],
            [126.924003887655, 37.721585253722],
            [126.924153555266, 37.721363543572],
            [126.924178131467, 37.721224468786],
            [126.924664271459, 37.720803279412],
            [126.924871858234, 37.720253745004],
            [126.924886951804, 37.720179569379],
            [126.924862875041, 37.719791562256],
            [126.924002448639, 37.719047667623],
            [126.923705413934, 37.718513388937],
            [126.923679562603, 37.718461971818],
            [126.923667938577, 37.718438845381],
            [126.923670552229, 37.718434071993],
            [126.9236583903, 37.718350544505],
            [126.92368250372, 37.718209253071],
            [126.923696962519, 37.71812460794],
            [126.923707009202, 37.718040031971],
            [126.923722365369, 37.7179106455],
            [126.923733167493, 37.71788452464],
            [126.923813217331, 37.71769032935],
            [126.923799744881, 37.717558419174],
            [126.923489194703, 37.716703017876],
            [126.923287494173, 37.71613068114],
            [126.923326635836, 37.715978002484],
            [126.923164280825, 37.715654736426],
            [126.922440594217, 37.715157019428],
            [126.922206102974, 37.715141338283],
            [126.921744025736, 37.714022306575],
            [126.921083329139, 37.71402994836],
            [126.920929974376, 37.714017149033],
            [126.92054230079, 37.713781839486],
            [126.920087019494, 37.713561474051],
            [126.919527411898, 37.713393650748],
            [126.919264925894, 37.713090220859],
            [126.918630963759, 37.712440315649],
            [126.918181225004, 37.712083315223],
            [126.918054638889, 37.711939665765],
            [126.917620732676, 37.711345864465],
            [126.917275961965, 37.711261999974],
            [126.916887616243, 37.710912516416],
            [126.916726633031, 37.71077314743],
            [126.916306193831, 37.71033822725],
            [126.916071358652, 37.710389520489],
            [126.915501141307, 37.71005927115],
            [126.914985081179, 37.709387232174],
            [126.914619967136, 37.709360790578],
            [126.914405042406, 37.709349045556],
            [126.91403670152, 37.709328140713],
            [126.913300010297, 37.709514866689],
            [126.912971358192, 37.709153269763],
            [126.912702285925, 37.708778058144],
            [126.912114906598, 37.707954527288],
            [126.912097239038, 37.707897257466],
            [126.911773488505, 37.706951816413],
            [126.911764259992, 37.706921581955],
            [126.91152803933, 37.705677148735],
            [126.911277977928, 37.704135574246],
            [126.910283844453, 37.703752312644],
            [126.909460135359, 37.703432216495],
            [126.909935160954, 37.702275636241],
            [126.90926042618, 37.701927174655],
            [126.908820893229, 37.701001302044],
            [126.908387275351, 37.700875906068],
            [126.908697578341, 37.700418999748],
            [126.908519150848, 37.699968771727],
            [126.909174976147, 37.699153938281],
            [126.908749602288, 37.698408368771],
            [126.908713343603, 37.698369391394],
            [126.908530211138, 37.698167592462],
            [126.907536487048, 37.697830743578],
            [126.907310961283, 37.69731686764],
            [126.90724562709, 37.697164795068],
            [126.907146335265, 37.696922021844],
            [126.906859509849, 37.696789367643],
            [126.906270076631, 37.69663202544],
            [126.906106237697, 37.696477881476],
            [126.905904853057, 37.69628287496],
            [126.90548576042, 37.695988791707],
            [126.905431400132, 37.69594733892],
            [126.905404904739, 37.695910017238],
            [126.905356054843, 37.69581607849],
            [126.905314723379, 37.695721470128],
            [126.905269418926, 37.695621659919],
            [126.905176559637, 37.695505332137],
            [126.904978782996, 37.69541734399],
            [126.904102810641, 37.695075850318],
            [126.903313071893, 37.694856818542],
            [126.902320309092, 37.694751439228],
            [126.902151043323, 37.694747782944],
            [126.90210326453, 37.694755427935],
            [126.902033918565, 37.694764243994],
            [126.902071235643, 37.695042548598],
            [126.902120954147, 37.695238262844],
            [126.902116684605, 37.695319409472],
            [126.902106291956, 37.695485719481],
            [126.902094654073, 37.695599547026],
            [126.902077402539, 37.695705666554],
            [126.902056110665, 37.695840073077],
            [126.90204240251, 37.69589937226],
            [126.902001943466, 37.695986515819],
            [126.901877235022, 37.696195011703],
            [126.901878558806, 37.696324220683],
            [126.901881921809, 37.696530986729],
            [126.901920179302, 37.696716798696],
            [126.901955200816, 37.696812808413],
            [126.902067526271, 37.696936867409],
            [126.902129698442, 37.69706276706],
            [126.902238713097, 37.69733179788],
            [126.902302165356, 37.69752712711],
            [126.902383234416, 37.69782770418],
            [126.902396834018, 37.69793517407],
            [126.902416532654, 37.698103266209],
            [126.902439491637, 37.698352060781],
            [126.902454191887, 37.698620994043],
            [126.902454482572, 37.698734876631],
            [126.902455962624, 37.6989263786],
            [126.902443791096, 37.699100372297],
            [126.902434861741, 37.699266404157],
            [126.902471939288, 37.699454809655],
            [126.902472330726, 37.699458891368],
            [126.902461209349, 37.699474225508],
            [126.902444229261, 37.699503889121],
            [126.902303477679, 37.699673540138],
            [126.902401382465, 37.699986591634],
            [126.902491536527, 37.700091684174],
            [126.902570589929, 37.700179585875],
            [126.902750795639, 37.700288384643],
            [126.903152296189, 37.700553361479],
            [126.903289620145, 37.70065232111],
            [126.903364769746, 37.700735128552],
            [126.903366629991, 37.700837876445],
            [126.903322165563, 37.701055071717],
            [126.903274176084, 37.701262344374],
            [126.903258305558, 37.701490816509],
            [126.903247553692, 37.701655621529],
            [126.903274928719, 37.701834810726],
            [126.903304746551, 37.701976071219],
            [126.903380046039, 37.70211823449],
            [126.903512194292, 37.702369741324],
            [126.903576909392, 37.702602172589],
            [126.9036467873, 37.702728915622],
            [126.903724857169, 37.702843583434],
            [126.903808358232, 37.702967445559],
            [126.903893466847, 37.703093561371],
            [126.903988186007, 37.703241398379],
            [126.904010024787, 37.703285771057],
            [126.904056304641, 37.703379032395],
            [126.904026627749, 37.70353557794],
            [126.904027479228, 37.703586762589],
            [126.90402382628, 37.703631240318],
            [126.904015523397, 37.703762774656],
            [126.903911651544, 37.70392688097],
            [126.903688881537, 37.704142667179],
            [126.903619779326, 37.704226922326],
            [126.903553527345, 37.704308071463],
            [126.903521349166, 37.704382347477],
            [126.903498593341, 37.704483218852],
            [126.903497336712, 37.704714126642],
            [126.903661351452, 37.705028835489],
            [126.903770694414, 37.705224985595],
            [126.903939397125, 37.705563915905],
            [126.904113743416, 37.705984892653],
            [126.904194575813, 37.706253555616],
            [126.904191631146, 37.706354740761],
            [126.904026573555, 37.706650445836],
            [126.903997591226, 37.706708588279],
            [126.903979297033, 37.706745071308],
            [126.903974851375, 37.706753951162],
            [126.903932237225, 37.706838651817],
            [126.903883437186, 37.706936087002],
            [126.903879797323, 37.706944462978],
            [126.903828382942, 37.707063708375],
            [126.903814386152, 37.707195508195],
            [126.903812948737, 37.707210652252],
            [126.9038122244, 37.707218192743],
            [126.90383691088, 37.70721054597],
            [126.903893282946, 37.707262758624],
            [126.903899218187, 37.70723356321],
            [126.903906945366, 37.707195584855],
            [126.90394508365, 37.707039163567],
            [126.904001960883, 37.707051986326],
            [126.904131431527, 37.707052760047],
            [126.904147160334, 37.707052854124],
            [126.904155234526, 37.707052905839],
            [126.904173979812, 37.707053020423],
            [126.904181036358, 37.706998770037],
            [126.904181700737, 37.706993689134],
            [126.904193980887, 37.706899314004],
            [126.904204321924, 37.706882924946],
            [126.904228360351, 37.706885107102],
            [126.904390778487, 37.706899872702],
            [126.904498452546, 37.706909655748],
            [126.904505137051, 37.706914436371],
            [126.904518391134, 37.706925168779],
            [126.904550121602, 37.706950899456],
            [126.904598521154, 37.707047135458],
            [126.904600260875, 37.707052263388],
            [126.904649244873, 37.707196197021],
            [126.904674145928, 37.707328605509],
            [126.904701745319, 37.707470305169],
            [126.90472908149, 37.707622645019],
            [126.90473028286, 37.707631907942],
            [126.90474140213, 37.707749592308],
            [126.904726720646, 37.707798619866],
            [126.904722912366, 37.707823177087],
            [126.904722510719, 37.707826816662],
            [126.904717667855, 37.707870815891],
            [126.904743342768, 37.70792291283],
            [126.904767743106, 37.707969738064],
            [126.904756724443, 37.707992676639],
            [126.904765649832, 37.708044633691],
            [126.904830128612, 37.708186417619],
            [126.904826653923, 37.70820735323],
            [126.904824459449, 37.70822052356],
            [126.904828870086, 37.70822986119],
            [126.904862142003, 37.708300416123],
            [126.905021683696, 37.70848947946],
            [126.905110783577, 37.708604984161],
            [126.905134547344, 37.70863580763],
            [126.905081326146, 37.70872352725],
            [126.905076711654, 37.70873143396],
            [126.905020597356, 37.708750056073],
            [126.905004361601, 37.708755826989],
            [126.904984029644, 37.708763351433],
            [126.904945537588, 37.708773906268],
            [126.904916427451, 37.708781901022],
            [126.904940819562, 37.708826626955],
            [126.905027328661, 37.708797614587],
            [126.905171521163, 37.709053336653],
            [126.905050318085, 37.708795921562],
            [126.905138417318, 37.708791218486],
            [126.905308027468, 37.709123498249],
            [126.905389555084, 37.709272539525],
            [126.905390074853, 37.709274008524],
            [126.90551070477, 37.709458570647],
            [126.9055530593, 37.709531006776],
            [126.90555634233, 37.709544262662],
            [126.90563878744, 37.709659689451],
            [126.905660101389, 37.709717539804],
            [126.905727150668, 37.709776643622],
            [126.905748480785, 37.70979557223],
            [126.905919008403, 37.709964894052],
            [126.906089140368, 37.710133827886],
            [126.906108576341, 37.710153097262],
            [126.906134774071, 37.710179129351],
            [126.906145953122, 37.710190247307],
            [126.906354138634, 37.710409043965],
            [126.906715045816, 37.710801741481],
            [126.906882877522, 37.710972077806],
            [126.906911928526, 37.711002184376],
            [126.906980562751, 37.711074091469],
            [126.907059901875, 37.711128933791],
            [126.907097520511, 37.711154992831],
            [126.907153950084, 37.711192842541],
            [126.907160719336, 37.711202776604],
            [126.9075207383, 37.711703514731],
            [126.907554385797, 37.711686450227],
            [126.907573178417, 37.711676951002],
            [126.907588895602, 37.711713984215],
            [126.90762713568, 37.711778208495],
            [126.907624954271, 37.71178133311],
            [126.907580612843, 37.711797893583],
            [126.907824116558, 37.712210927929],
            [126.907844493461, 37.712303356188],
            [126.907878777206, 37.712375658901],
            [126.907904730625, 37.7123896625],
            [126.907944935388, 37.712461267141],
            [126.907933323721, 37.712478033909],
            [126.908196034238, 37.712943672056],
            [126.908331906132, 37.713220484118],
            [126.908346099998, 37.71325189399],
            [126.908436097623, 37.713438104588],
            [126.908501696936, 37.713572859736],
            [126.908535135801, 37.713614339542],
            [126.908606432221, 37.713700915479],
            [126.908625830411, 37.713724463986],
            [126.908608140502, 37.713731567676],
            [126.908630101605, 37.713764380129],
            [126.908665833753, 37.713849549526],
            [126.908666928306, 37.713881264378],
            [126.908615938681, 37.713935101955],
            [126.908588229251, 37.713964451597],
            [126.908414626953, 37.714028643581],
            [126.908387349805, 37.714038730873],
            [126.908297445321, 37.714040218494],
            [126.908239954987, 37.714041173097],
            [126.908207529493, 37.714041706038],
            [126.908160231111, 37.714036722295],
            [126.908097523398, 37.71402894234],
            [126.907969902813, 37.714053428502],
            [126.907907737371, 37.714065362004],
            [126.90793251818, 37.714110429955],
            [126.907945492939, 37.714164317994],
            [126.907915759687, 37.714170366901],
            [126.907766707685, 37.714200665073],
            [126.907499663783, 37.714255050942],
            [126.90730205329, 37.714289940757],
            [126.906939970606, 37.714342357346],
            [126.906917851993, 37.714344682112],
            [126.906527996245, 37.714386263561],
            [126.906220055641, 37.714403764084],
            [126.905832730681, 37.714417054962],
            [126.905425237039, 37.714415552297],
            [126.905132883565, 37.714394591456],
            [126.904534778938, 37.714364459226],
            [126.904083633205, 37.714353906355],
            [126.903626657835, 37.714550478885],
            [126.903569325739, 37.714594578579],
            [126.903571537326, 37.714637646565],
            [126.903588662003, 37.714638291466],
            [126.903609870488, 37.714724441377],
            [126.903594168115, 37.714935704731],
            [126.903625934025, 37.715247176827],
            [126.903814162936, 37.715476286276],
            [126.903825102226, 37.715523505924],
            [126.903813294092, 37.715619719236],
            [126.903711998724, 37.715803882653],
            [126.903707487034, 37.715871181017],
            [126.903795813407, 37.715976306809],
            [126.903893480985, 37.716136759534],
            [126.903826539075, 37.716329510643],
            [126.903824598029, 37.716512134679],
            [126.903848422639, 37.716679553745],
            [126.903904331631, 37.716943222445],
            [126.903902124213, 37.716983223468],
            [126.903928186727, 37.717001174249],
            [126.904018074567, 37.717214236764],
            [126.904103818942, 37.717387202836],
            [126.904085195797, 37.717664234192],
            [126.90407565945, 37.717757836573],
            [126.904113648234, 37.717936078836],
            [126.90419415846, 37.718122735164],
            [126.904222342426, 37.71825339834],
            [126.904342539443, 37.718535139095],
            [126.904347855564, 37.718633168454],
            [126.904260162426, 37.718734724981],
            [126.904344598406, 37.718955981468],
            [126.904416314052, 37.719103888878],
            [126.904483788614, 37.719199176408],
            [126.904550448498, 37.7193105004],
            [126.904681621589, 37.719483638491],
            [126.904812795291, 37.719656776433],
            [126.904583278837, 37.719880207382],
            [126.903914990775, 37.719668064469],
            [126.903492387913, 37.719533965645],
            [126.903190472312, 37.719438662454],
            [126.902639248855, 37.719264585537],
            [126.902712330655, 37.719244068772],
            [126.902607718971, 37.719238097755],
            [126.902340031518, 37.719222835773],
            [126.902170437972, 37.719202123961],
            [126.902089636668, 37.719176783784],
            [126.902033363212, 37.719155302361],
            [126.901972500964, 37.719113338108],
            [126.901922188547, 37.719112520787],
            [126.901838708948, 37.719029228122],
            [126.901635595197, 37.718826357162],
            [126.901648106447, 37.718732694449],
            [126.901660617666, 37.718639031734],
            [126.901628694048, 37.718534564846],
            [126.901597119422, 37.718432107396],
            [126.90159793783, 37.718388555448],
            [126.901598585121, 37.718278178789],
            [126.901556200536, 37.718261051556],
            [126.901515391628, 37.71824457434],
            [126.901387564054, 37.718163063668],
            [126.901135189044, 37.717780389444],
            [126.901031008663, 37.717733819914],
            [126.900937100223, 37.7176649872],
            [126.900938686483, 37.717548719022],
            [126.900940982675, 37.717419072116],
            [126.900973758838, 37.717319642543],
            [126.900935940247, 37.71726459736],
            [126.90088086391, 37.71725659484],
            [126.90081030427, 37.717266643418],
            [126.900788478435, 37.717269742112],
            [126.900744411422, 37.717163904129],
            [126.900700333204, 37.717058057109],
            [126.900581575398, 37.71682255134],
            [126.900462818355, 37.716587036434],
            [126.90035553275, 37.716448484104],
            [126.900248247556, 37.716309922664],
            [126.900250918387, 37.716239190182],
            [126.900253589224, 37.716168448688],
            [126.900159517625, 37.716121454595],
            [126.900092911326, 37.715936131836],
            [126.900020579917, 37.715566394533],
            [126.900050495776, 37.715394056609],
            [126.900091849798, 37.715342061444],
            [126.900133192432, 37.715290057244],
            [126.900141165031, 37.715165550688],
            [126.900149137614, 37.715041035119],
            [126.900029225779, 37.715181275337],
            [126.900010590783, 37.714782915469],
            [126.900002083061, 37.714576307761],
            [126.899993575385, 37.714369700045],
            [126.900052510679, 37.714407212923],
            [126.900096240202, 37.714434234464],
            [126.900145585343, 37.714460080552],
            [126.900151189598, 37.714458896096],
            [126.900149806603, 37.714400142948],
            [126.900148262683, 37.71433464143],
            [126.900145324448, 37.714210377743],
            [126.90014466374, 37.714062672888],
            [126.900143991706, 37.71391495901],
            [126.900097544422, 37.713543955498],
            [126.900143937739, 37.713113793313],
            [126.900267573811, 37.712826059029],
            [126.900477780933, 37.712442409927],
            [126.900584131888, 37.712145560708],
            [126.900690470663, 37.711848702357],
            [126.900704107076, 37.711795115558],
            [126.900717802194, 37.711723293257],
            [126.900725317052, 37.71160917433],
            [126.900686679575, 37.711415839055],
            [126.900648042299, 37.711222503762],
            [126.900565057265, 37.711091522382],
            [126.900491045462, 37.710975531695],
            [126.900243908787, 37.710914909909],
            [126.900227052752, 37.710909183305],
            [126.900118150113, 37.710873366397],
            [126.899903673287, 37.710789563096],
            [126.899833194434, 37.710811278684],
            [126.899722586061, 37.710853690819],
            [126.899685346672, 37.710867416414],
            [126.899618516552, 37.710891234275],
            [126.899529901383, 37.710922781565],
            [126.899096014843, 37.711077371611],
            [126.898012632228, 37.711477715766],
            [126.897763645379, 37.711898401156],
            [126.897251339581, 37.712496821191],
            [126.896850122919, 37.712507187195],
            [126.896410402223, 37.712510192665],
            [126.895213904579, 37.712900625606],
            [126.895029178912, 37.71303614434],
            [126.894636147901, 37.713330377439],
            [126.894491170467, 37.713442695442],
            [126.894346928344, 37.713658372701],
            [126.893964197149, 37.714088370366],
            [126.89356134003, 37.714449307349],
            [126.893225010929, 37.714675654781],
            [126.892888000278, 37.715033388389],
            [126.892651089333, 37.715280773158],
            [126.892799596156, 37.715574931193],
            [126.892969669815, 37.716215286732],
            [126.892802278493, 37.716393775867],
            [126.892437770644, 37.716593390379],
            [126.892123106801, 37.71706363684],
            [126.891965256219, 37.717299516069],
            [126.891839709423, 37.717487061335],
            [126.891517552588, 37.717650304357],
            [126.891242636991, 37.717788111403],
            [126.89057546212, 37.717810739391],
            [126.890121771426, 37.718078357655],
            [126.890330628638, 37.71854426351],
            [126.890286899696, 37.719132074712],
            [126.890345590196, 37.719719721179],
            [126.890366234179, 37.720144013859],
            [126.89039221674, 37.721451662067],
            [126.889597191949, 37.722099171822],
            [126.889216264941, 37.722128568432],
            [126.888971727532, 37.722145813949],
            [126.888760839673, 37.722195426751],
            [126.888723046426, 37.722367366641],
            [126.888685803951, 37.722549974456],
            [126.888464185378, 37.722867766437],
            [126.888527886507, 37.722971816779],
            [126.8885718282, 37.72300905969]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41285110',
      properties: {
        rgnCode: '41285110',
        colCode: '41280',
        rgnKo: ['지영동'],
        rgnSize: 1,
        rgnBbox: [
          126.818639107334, 37.704546301072, 126.841184999024, 37.728990778966
        ],
        rgnCenter: [126.830237699283, 37.716613508953],
        rgnArea: 3260348,
        predVal: [
          0.2633, 0.26775, 0.2562, 0.28773, 0.36048, 0.32776, 0.35959, 0.40372,
          0.37016, 0.3953, 0.37782, 0.26163, 0.13458, 0.23267, 0.25701, 0.15177,
          0.17456, 0.29774, 0.41709, 0.30035, 0.31584, 0.37541, 0.32938,
          0.37301, 0.29859, 0.36386, 0.33257, 0.34243, 0.33479, 0.34887, 0.25234
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.819878303822, 37.710793555591],
            [126.819926465388, 37.71108752462],
            [126.81993853386, 37.71116115206],
            [126.820018207119, 37.711647885993],
            [126.820032845947, 37.711737194166],
            [126.81994608733, 37.711816336512],
            [126.819883265936, 37.711873640238],
            [126.819815458168, 37.712054809928],
            [126.819734636166, 37.712270826855],
            [126.81967274856, 37.712442365252],
            [126.819621108772, 37.712583160478],
            [126.819604017275, 37.712629750035],
            [126.819461938076, 37.712666019823],
            [126.819312141525, 37.712704439829],
            [126.819241093253, 37.712818662462],
            [126.819171409226, 37.712931445615],
            [126.819135358273, 37.713018332966],
            [126.819097026423, 37.713110352276],
            [126.819008775853, 37.713255721527],
            [126.818930651337, 37.713383132142],
            [126.818846058884, 37.713521073969],
            [126.818835420938, 37.713696565425],
            [126.818805613751, 37.713780669346],
            [126.818813237514, 37.7140937664],
            [126.818791686003, 37.7143259116],
            [126.818760645363, 37.714427240033],
            [126.818767534593, 37.714443666287],
            [126.818767190019, 37.714463901419],
            [126.818730502005, 37.714661885582],
            [126.818715809458, 37.714761040986],
            [126.818639107334, 37.714982586356],
            [126.818642741991, 37.714985006578],
            [126.818732358654, 37.715023355449],
            [126.819235403242, 37.715238635871],
            [126.819250233723, 37.715244974559],
            [126.819463420472, 37.715722094337],
            [126.819742482066, 37.71579072791],
            [126.820255188825, 37.71576295667],
            [126.82039879185, 37.715755248866],
            [126.820648881735, 37.715985108421],
            [126.820937675048, 37.716250614815],
            [126.820983506441, 37.716292760038],
            [126.821076027324, 37.716374033512],
            [126.821533918477, 37.716409240322],
            [126.821809176384, 37.716434526808],
            [126.821943081791, 37.716446290203],
            [126.821950463628, 37.71644695014],
            [126.822074854167, 37.716458401576],
            [126.82218626484, 37.716443705172],
            [126.822279636419, 37.716431413844],
            [126.822329781871, 37.716425075199],
            [126.822348535593, 37.716422608028],
            [126.822443347202, 37.716410444894],
            [126.822482510268, 37.716537585394],
            [126.822503736945, 37.716671636073],
            [126.822550456412, 37.71669869088],
            [126.822628629559, 37.716899003789],
            [126.822657280668, 37.716964277072],
            [126.822774088477, 37.717299838166],
            [126.822958888761, 37.717754789777],
            [126.823033515451, 37.717911985877],
            [126.823064467824, 37.718001687673],
            [126.823082747833, 37.718073936625],
            [126.823095536956, 37.718142050849],
            [126.823092527746, 37.718143550905],
            [126.823084577662, 37.718148025675],
            [126.823086545735, 37.718178661434],
            [126.823158024674, 37.718262964684],
            [126.823208386773, 37.718379670664],
            [126.823346352804, 37.718473417328],
            [126.823373259448, 37.71849091867],
            [126.823492777238, 37.718502090958],
            [126.823653823933, 37.718506073021],
            [126.823846193894, 37.718501948346],
            [126.824068840597, 37.718500481725],
            [126.82421752981, 37.718454666152],
            [126.824293824361, 37.718470187345],
            [126.824335020714, 37.718459707983],
            [126.8243549939, 37.718454962887],
            [126.824362012172, 37.718460739603],
            [126.824397355409, 37.718445764622],
            [126.824415486032, 37.718433655872],
            [126.824482864931, 37.718387582653],
            [126.824541112252, 37.718361091659],
            [126.824548496076, 37.718356237536],
            [126.824570924158, 37.718368434214],
            [126.824614473987, 37.718393681512],
            [126.824575809568, 37.718460294836],
            [126.824564971871, 37.718520084567],
            [126.824549652607, 37.718604328745],
            [126.824526091634, 37.718715508382],
            [126.824506097274, 37.718729127971],
            [126.82446197171, 37.718755099575],
            [126.824473997109, 37.718825392829],
            [126.824521660375, 37.718923813626],
            [126.824616717497, 37.719119636664],
            [126.824635933521, 37.719185354779],
            [126.824670203213, 37.719307207508],
            [126.824653162659, 37.719485707954],
            [126.824642934355, 37.719627855708],
            [126.824641371848, 37.719698272704],
            [126.824635935326, 37.719790433155],
            [126.82461458941, 37.719871487878],
            [126.824580737139, 37.71994126178],
            [126.824530963068, 37.719981279963],
            [126.824468943787, 37.720019432762],
            [126.824334269696, 37.720056097887],
            [126.824282243042, 37.720070326994],
            [126.824263490577, 37.720105229171],
            [126.824217668416, 37.720247809875],
            [126.824228758303, 37.720263278081],
            [126.82421379895, 37.720400832758],
            [126.824193580809, 37.72046053625],
            [126.824159773228, 37.720701583302],
            [126.824114080507, 37.72088477058],
            [126.82408872834, 37.721099612272],
            [126.824136765265, 37.72136042344],
            [126.824189457996, 37.721476366629],
            [126.824194122368, 37.721518088261],
            [126.824231316812, 37.721664550784],
            [126.824308615614, 37.721736023311],
            [126.824419139751, 37.721858630336],
            [126.824467891938, 37.721914923685],
            [126.824515844026, 37.722021489579],
            [126.824634584683, 37.722171137638],
            [126.824676766082, 37.722209951357],
            [126.824776509957, 37.722301719906],
            [126.824899791385, 37.722450203246],
            [126.824840001419, 37.722599583502],
            [126.824811175227, 37.72265404859],
            [126.824802489799, 37.72268142486],
            [126.824769723791, 37.722770931509],
            [126.824711972902, 37.723016627737],
            [126.824713724336, 37.72304329888],
            [126.824720955652, 37.723150524367],
            [126.824712184117, 37.723166368168],
            [126.82475683021, 37.72328040698],
            [126.824747687127, 37.723309404292],
            [126.824765312438, 37.723481785004],
            [126.824797286452, 37.723544350781],
            [126.824808333324, 37.723754841377],
            [126.824823018328, 37.723780360647],
            [126.824834992423, 37.723801100748],
            [126.824852290131, 37.723825002197],
            [126.824812774092, 37.723867017961],
            [126.824777428058, 37.723944177529],
            [126.824731884261, 37.724221984326],
            [126.824633894605, 37.724884432685],
            [126.824587446996, 37.725226999249],
            [126.824614351806, 37.725289341218],
            [126.82479743956, 37.725618737597],
            [126.825086600213, 37.726133890829],
            [126.825099815662, 37.726157515834],
            [126.825128683368, 37.726133863704],
            [126.825133910172, 37.72613008748],
            [126.825134437159, 37.726147026372],
            [126.82513792592, 37.726254336274],
            [126.825137910963, 37.726260642992],
            [126.82516289831, 37.726342667997],
            [126.825201762165, 37.726408766706],
            [126.825204213874, 37.726427240112],
            [126.825237276269, 37.726404585289],
            [126.825264314391, 37.726387147035],
            [126.825306235929, 37.726359640237],
            [126.825379060709, 37.726311097067],
            [126.825500761279, 37.726268122709],
            [126.82563183389, 37.726243181465],
            [126.825864773646, 37.726263890522],
            [126.826021903222, 37.726252592205],
            [126.826191351734, 37.726260412413],
            [126.826260417664, 37.726314482784],
            [126.826298950464, 37.726377066885],
            [126.826365055396, 37.7264843797],
            [126.826467346539, 37.726591205633],
            [126.826525349222, 37.72662291554],
            [126.826600194121, 37.726631585758],
            [126.826695276716, 37.726620374662],
            [126.826762323623, 37.726616780119],
            [126.826819283767, 37.726609476643],
            [126.827114927503, 37.726592705979],
            [126.827187408563, 37.726642005754],
            [126.827389175268, 37.72660608523],
            [126.827560156037, 37.726589669833],
            [126.827584198104, 37.72659204781],
            [126.827834250678, 37.726620076021],
            [126.827999987665, 37.726614734104],
            [126.828065781505, 37.726613389383],
            [126.828080756401, 37.726612600547],
            [126.828101398057, 37.72661389226],
            [126.828323358745, 37.726673862292],
            [126.82847352213, 37.726731654327],
            [126.828548860014, 37.726821140401],
            [126.828571732669, 37.726838652637],
            [126.828647478297, 37.726899218337],
            [126.82869996706, 37.72696119144],
            [126.828724852482, 37.726991229979],
            [126.828785494308, 37.727108173724],
            [126.828790126004, 37.727116379272],
            [126.828745872194, 37.72726929791],
            [126.828849662256, 37.727465319279],
            [126.828865583337, 37.727496786197],
            [126.829008432982, 37.727676557189],
            [126.829052429907, 37.727732120865],
            [126.828943271816, 37.727795929479],
            [126.828914898449, 37.727851207051],
            [126.82900593283, 37.72792305693],
            [126.829179483363, 37.727973674575],
            [126.829298269435, 37.728062863275],
            [126.829596437544, 37.728281241342],
            [126.829687313062, 37.728324169557],
            [126.829738304469, 37.728347668897],
            [126.829757680963, 37.728356706755],
            [126.8298903712, 37.728418255558],
            [126.830010072546, 37.728504741981],
            [126.830372025887, 37.7286112212],
            [126.830483023555, 37.728635347892],
            [126.830555128005, 37.728652931153],
            [126.830621675003, 37.728670145929],
            [126.830748911223, 37.72868681792],
            [126.830806035534, 37.728707352504],
            [126.830939775255, 37.728757099034],
            [126.830988626137, 37.728774468189],
            [126.831091462696, 37.728796059807],
            [126.831186265843, 37.7288087202],
            [126.831315514544, 37.728788815406],
            [126.831389085627, 37.728859647163],
            [126.831482090559, 37.728914649964],
            [126.831605448168, 37.728990778966],
            [126.831645656608, 37.728967952462],
            [126.831702900295, 37.728936411146],
            [126.831751856774, 37.728907650853],
            [126.831888077253, 37.728815137886],
            [126.832062545229, 37.728664027077],
            [126.832127136993, 37.72859357453],
            [126.83238451334, 37.728248064494],
            [126.832515263835, 37.728115810487],
            [126.832649815793, 37.728007707578],
            [126.83288050783, 37.727772345942],
            [126.832981103111, 37.727631308928],
            [126.833175426341, 37.727325709695],
            [126.833245104166, 37.727212287851],
            [126.833316730305, 37.727089949211],
            [126.833335865218, 37.727055379535],
            [126.833365932003, 37.727002085421],
            [126.833392582128, 37.726954822878],
            [126.833429039918, 37.726834145834],
            [126.833449059745, 37.726759304358],
            [126.83346266138, 37.726713644927],
            [126.833503527854, 37.726549367533],
            [126.833519893498, 37.726485242292],
            [126.833548006263, 37.72629247627],
            [126.833562744996, 37.726094825982],
            [126.833566025807, 37.725847516298],
            [126.833539293648, 37.725529528335],
            [126.833530143065, 37.725412570283],
            [126.833654651479, 37.725379231801],
            [126.833814325912, 37.725344681868],
            [126.833971564794, 37.725333823588],
            [126.833978846416, 37.725374377278],
            [126.833995984484, 37.725470624495],
            [126.834017347721, 37.725554354324],
            [126.834067831267, 37.725752818115],
            [126.834154828575, 37.726006742947],
            [126.834224870795, 37.726183971693],
            [126.834332468126, 37.726458197274],
            [126.834394970771, 37.726608926903],
            [126.834445620977, 37.726733962255],
            [126.834563678122, 37.727048745755],
            [126.834632915523, 37.727231649155],
            [126.834708652091, 37.727399785915],
            [126.834734500174, 37.72745694353],
            [126.834871283927, 37.727717695408],
            [126.834955971095, 37.727891070232],
            [126.83533960923, 37.727584833795],
            [126.835376086757, 37.727555603915],
            [126.835547636838, 37.727640085576],
            [126.835696804194, 37.727690118727],
            [126.835894448693, 37.727620031363],
            [126.836066340859, 37.727500084283],
            [126.836307383526, 37.727501773766],
            [126.836606120425, 37.727471919678],
            [126.836744940525, 37.727482114471],
            [126.836757417716, 37.727461860245],
            [126.836762346292, 37.727449109499],
            [126.836808553822, 37.727349059072],
            [126.836869189695, 37.727279859726],
            [126.83692173676, 37.727108560948],
            [126.836922521683, 37.727097300007],
            [126.836940987406, 37.726918412568],
            [126.836883296148, 37.726800269831],
            [126.836921756137, 37.726653881012],
            [126.837076533455, 37.726264457526],
            [126.837212187029, 37.726043406172],
            [126.837273113335, 37.725698205965],
            [126.837260647985, 37.725430800752],
            [126.837239061949, 37.725297374065],
            [126.837366245124, 37.725228213091],
            [126.837576372781, 37.72513120145],
            [126.837804705942, 37.724997860848],
            [126.83787432789, 37.724957188921],
            [126.838087625367, 37.724877839643],
            [126.838183016267, 37.72479157847],
            [126.838217981169, 37.724747542773],
            [126.838308402144, 37.724636074668],
            [126.83833440786, 37.724518805242],
            [126.838370135935, 37.724468040358],
            [126.838574100672, 37.724200285835],
            [126.838669319212, 37.724021125683],
            [126.8386815063, 37.723998167949],
            [126.838926690042, 37.723916428573],
            [126.839041903497, 37.723878026174],
            [126.839042814461, 37.723876405695],
            [126.839102260603, 37.723768011605],
            [126.8391719797, 37.723681975524],
            [126.839179492064, 37.723670003055],
            [126.839300034747, 37.723476191537],
            [126.839455071156, 37.723224585451],
            [126.839943445778, 37.722408622352],
            [126.840021326542, 37.722302946914],
            [126.840170692878, 37.722069450445],
            [126.840259924715, 37.721928121221],
            [126.840465538897, 37.721625588351],
            [126.840765604413, 37.721126683706],
            [126.841184999024, 37.720401185975],
            [126.841056819366, 37.720375153925],
            [126.840688313557, 37.720341496336],
            [126.840662359497, 37.720022880049],
            [126.840649215812, 37.719932810758],
            [126.840591446278, 37.719640882624],
            [126.840577812618, 37.71961484417],
            [126.840552855335, 37.719564518282],
            [126.840627601996, 37.719505958534],
            [126.840888243683, 37.718839167966],
            [126.8409190382, 37.718765204714],
            [126.840931160781, 37.718309008952],
            [126.840941249259, 37.71805949161],
            [126.840912763489, 37.717747124498],
            [126.840906377734, 37.717694715709],
            [126.840870030479, 37.717309684084],
            [126.840863831395, 37.717244391752],
            [126.840831207566, 37.717110085565],
            [126.840755335306, 37.716798437614],
            [126.840717957817, 37.716644006366],
            [126.840664357018, 37.716649203894],
            [126.840585357755, 37.716357543948],
            [126.840565039945, 37.716365048278],
            [126.840543656739, 37.716372217792],
            [126.840487414152, 37.716387601543],
            [126.839882419013, 37.716563533942],
            [126.839773132925, 37.716619784451],
            [126.839555128906, 37.716731565173],
            [126.839211890349, 37.717008995675],
            [126.83908973013, 37.717148395854],
            [126.838970339813, 37.717281249709],
            [126.838783367628, 37.717541685848],
            [126.838733123856, 37.717458475492],
            [126.838702242326, 37.717405609306],
            [126.83863069989, 37.71728564618],
            [126.838420199783, 37.717030409262],
            [126.838357553724, 37.716950163775],
            [126.838339301127, 37.716936344749],
            [126.838339381844, 37.716925398142],
            [126.838307184305, 37.716764351217],
            [126.838165038083, 37.716470079534],
            [126.83808019643, 37.716421129677],
            [126.838241201576, 37.715615439882],
            [126.838256912233, 37.715536717322],
            [126.838267050195, 37.715495989735],
            [126.838607953841, 37.715527112078],
            [126.838658186128, 37.715272929225],
            [126.838685136884, 37.715136650479],
            [126.838791678751, 37.714597299834],
            [126.838797529835, 37.714566675104],
            [126.838228154623, 37.714480386373],
            [126.838192199604, 37.71448168802],
            [126.838259331617, 37.714330689183],
            [126.838305322873, 37.714252188716],
            [126.838336082122, 37.713930857098],
            [126.838322742919, 37.713859302017],
            [126.838298103217, 37.713769171412],
            [126.838262360197, 37.713725695415],
            [126.838243827865, 37.713643321552],
            [126.838255255687, 37.713397103636],
            [126.838293747194, 37.713273274233],
            [126.8382941445, 37.71314385115],
            [126.838377775788, 37.712941429941],
            [126.838363365492, 37.712758432994],
            [126.838339929263, 37.712693702254],
            [126.838295914576, 37.712647106482],
            [126.838283031904, 37.71257952527],
            [126.838337449534, 37.712609143242],
            [126.838328455526, 37.712547324584],
            [126.83829538937, 37.71231780298],
            [126.838327525139, 37.712247572175],
            [126.838540767172, 37.712145589546],
            [126.838362377503, 37.71207625682],
            [126.838111066647, 37.711866974956],
            [126.838123442915, 37.711860324965],
            [126.838116084953, 37.711854098108],
            [126.838071098751, 37.711821546947],
            [126.83782249328, 37.711646279652],
            [126.837643153105, 37.711484127066],
            [126.837541060426, 37.711392266858],
            [126.837467068735, 37.711327033149],
            [126.837461200106, 37.711159715715],
            [126.837324505571, 37.711125108565],
            [126.837196241593, 37.711175924522],
            [126.837008402229, 37.711037634698],
            [126.836952933043, 37.710991968444],
            [126.836879524329, 37.71095582735],
            [126.836572205184, 37.710794395317],
            [126.836488216619, 37.710737462914],
            [126.836399970413, 37.710677659419],
            [126.836375386026, 37.710635667028],
            [126.83599025311, 37.709977800585],
            [126.83579339047, 37.709641508949],
            [126.835690345038, 37.70958749534],
            [126.835346275123, 37.709473606405],
            [126.834897580489, 37.708917888271],
            [126.834797632455, 37.708874095204],
            [126.834641555387, 37.708766758605],
            [126.834133679165, 37.708334476143],
            [126.833774508863, 37.70809618284],
            [126.833650531528, 37.707992395335],
            [126.833473569056, 37.707824761836],
            [126.832788870003, 37.707710622735],
            [126.832498622451, 37.707267418352],
            [126.831936004302, 37.706408287285],
            [126.831917459895, 37.706379862164],
            [126.831847841978, 37.706390159158],
            [126.831752657653, 37.705652202998],
            [126.831634783465, 37.705401402462],
            [126.830659923314, 37.705745072981],
            [126.830609073381, 37.705745584917],
            [126.830495375477, 37.705746726467],
            [126.829230877972, 37.706772022994],
            [126.828618407312, 37.706400063672],
            [126.828113471387, 37.706090561142],
            [126.827586566282, 37.705818324029],
            [126.827310555893, 37.705282562508],
            [126.827079181629, 37.704839440617],
            [126.826960909188, 37.704644837078],
            [126.826646515829, 37.704546301072],
            [126.826295023924, 37.704643317888],
            [126.826018855793, 37.704704488224],
            [126.825927300409, 37.704674439844],
            [126.825775463759, 37.704624570405],
            [126.825484320997, 37.704597017044],
            [126.825131360401, 37.704576127756],
            [126.825089063068, 37.704575974381],
            [126.824991892559, 37.704575639749],
            [126.824958428929, 37.704575508561],
            [126.824969712264, 37.704647152271],
            [126.824749892119, 37.704765119809],
            [126.824553479781, 37.704958172549],
            [126.824463111085, 37.705006202543],
            [126.824429088703, 37.705078787345],
            [126.824365990396, 37.705213405023],
            [126.824347471769, 37.70525647942],
            [126.824075040625, 37.705484698932],
            [126.824092733128, 37.705626537615],
            [126.824051757504, 37.705737655164],
            [126.823898770063, 37.706027085391],
            [126.823904360623, 37.706217215655],
            [126.823731529704, 37.706348568303],
            [126.823681087768, 37.706386963484],
            [126.823320808365, 37.706639311691],
            [126.823302461475, 37.706662357699],
            [126.8230335501, 37.706866380118],
            [126.823016831607, 37.706985849912],
            [126.822978514098, 37.707259568384],
            [126.82295961661, 37.707337112924],
            [126.822838055377, 37.707428827289],
            [126.822731035363, 37.707509751987],
            [126.822488933613, 37.707692550985],
            [126.822329339093, 37.707801685801],
            [126.822303908214, 37.707917123926],
            [126.822299723259, 37.707936109922],
            [126.822258033877, 37.708125339438],
            [126.82220270064, 37.708093802592],
            [126.822178901418, 37.708040258046],
            [126.822159674519, 37.708112891683],
            [126.82217391833, 37.708272789702],
            [126.82217480233, 37.708282431379],
            [126.822051337217, 37.708410180644],
            [126.822035644068, 37.70841874296],
            [126.82173529623, 37.708567323127],
            [126.821640147575, 37.708615109383],
            [126.821549541293, 37.708660289695],
            [126.821469071623, 37.708781166521],
            [126.821378259477, 37.708906586376],
            [126.821307521028, 37.709176677839],
            [126.821256199524, 37.709295977316],
            [126.821211220156, 37.709371950028],
            [126.821176484926, 37.709408971636],
            [126.821148172671, 37.709439570164],
            [126.82112628511, 37.709463042869],
            [126.821103465162, 37.709487523224],
            [126.820819290013, 37.709782631752],
            [126.820771435985, 37.709832534956],
            [126.820745082394, 37.709859676681],
            [126.820722797835, 37.709883076623],
            [126.820708678405, 37.709897200129],
            [126.820708439683, 37.709897434014],
            [126.820696477873, 37.709882360539],
            [126.820650381751, 37.709955493275],
            [126.820596609975, 37.710009108316],
            [126.820551592554, 37.710053637017],
            [126.820344808121, 37.710257838596],
            [126.820273757673, 37.710328004697],
            [126.820105905837, 37.710424690432],
            [126.819886138746, 37.710696623802],
            [126.819866459863, 37.710720919548],
            [126.819878303822, 37.710793555591]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281121',
      properties: {
        rgnCode: '41281121',
        colCode: '41280',
        rgnKo: ['내곡동'],
        rgnSize: 1,
        rgnBbox: [
          126.79403656721, 37.627484574443, 126.814607604976, 37.648031458522
        ],
        rgnCenter: [126.803408029674, 37.638529819169],
        rgnArea: 2067360,
        predVal: [
          0.39496, 0.40163, 0.3843, 0.43159, 0.54071, 0.49164, 0.53939, 0.56727,
          0.48852, 0.51429, 0.51256, 0.39245, 0.56409, 0.50139, 0.5702, 0.22766,
          0.5286, 0.48499, 0.62564, 0.54635, 0.5596, 0.56936, 0.49407, 0.55951,
          0.44788, 0.54579, 0.49886, 0.51365, 0.50219, 0.57521, 0.55288
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.79403656721, 37.631500328984],
            [126.794064752451, 37.63154948179],
            [126.794095959174, 37.631603910615],
            [126.794096459737, 37.631611308517],
            [126.794097094491, 37.631619337338],
            [126.794099528565, 37.631640298342],
            [126.794112004996, 37.631732814575],
            [126.794200869895, 37.63171580713],
            [126.794201049519, 37.631720474507],
            [126.794200980537, 37.631724916202],
            [126.794195335496, 37.631852529677],
            [126.794202210933, 37.631881490148],
            [126.794206210898, 37.631909715775],
            [126.794214064278, 37.631921045844],
            [126.794219415744, 37.631964374219],
            [126.794224490029, 37.632005530752],
            [126.794286398933, 37.632553361395],
            [126.794309874385, 37.63276075344],
            [126.794311355158, 37.632774189606],
            [126.794318173754, 37.632839990909],
            [126.794521775986, 37.63480518924],
            [126.794530051265, 37.634885102357],
            [126.794534961488, 37.634932439268],
            [126.794490548236, 37.634941515271],
            [126.794457709558, 37.634976505635],
            [126.79437756846, 37.635073670435],
            [126.794324559035, 37.635232689812],
            [126.794315207864, 37.635417914152],
            [126.794311549396, 37.635511429083],
            [126.794330720877, 37.635584081449],
            [126.794415035079, 37.635820465485],
            [126.794533555804, 37.63606006288],
            [126.794460790915, 37.63631581249],
            [126.794436907012, 37.636553087732],
            [126.794542911535, 37.6368124846],
            [126.794608622366, 37.636976127035],
            [126.794629345934, 37.637143024167],
            [126.794530771739, 37.637472158071],
            [126.794567140392, 37.637635388643],
            [126.794634273119, 37.637735965209],
            [126.79470478894, 37.637842313912],
            [126.794736094682, 37.63787029904],
            [126.794828328073, 37.637948665371],
            [126.794888761219, 37.638001712574],
            [126.79490560345, 37.638146754163],
            [126.794916549849, 37.638240195536],
            [126.794916948164, 37.638243646972],
            [126.794973895755, 37.638733291979],
            [126.794979462284, 37.638781116517],
            [126.794987116798, 37.638856406456],
            [126.794912777959, 37.638894531745],
            [126.794629188096, 37.638887520763],
            [126.794634683188, 37.638936516459],
            [126.794660923033, 37.639198115769],
            [126.794727729925, 37.639232848369],
            [126.794803755536, 37.639289436739],
            [126.794864918176, 37.639333106062],
            [126.794887437153, 37.639363526408],
            [126.794984845492, 37.639500942608],
            [126.79500160993, 37.639522820617],
            [126.79506393996, 37.639611495622],
            [126.795065340617, 37.639625319042],
            [126.795124461594, 37.64007544186],
            [126.7951559586, 37.640161657264],
            [126.795157432105, 37.640231080021],
            [126.795167931312, 37.640326664878],
            [126.795224275917, 37.640429078208],
            [126.795405272282, 37.640540547904],
            [126.795520294814, 37.640561372116],
            [126.795882685159, 37.640727126085],
            [126.795903506385, 37.640736649645],
            [126.79612016282, 37.640839062506],
            [126.796715696695, 37.641120562453],
            [126.796818024958, 37.64120540471],
            [126.796820161514, 37.641207174329],
            [126.796852379358, 37.641233890088],
            [126.796855047223, 37.641236102106],
            [126.796866430879, 37.641245537047],
            [126.796974711318, 37.641407639137],
            [126.796975207796, 37.641408387808],
            [126.796997685425, 37.641442033162],
            [126.797027745859, 37.641487043947],
            [126.797181721501, 37.641802408889],
            [126.797194273059, 37.641828117438],
            [126.797202024853, 37.641843988031],
            [126.797226827326, 37.641847977252],
            [126.797231413284, 37.64185728326],
            [126.797304922975, 37.642006819283],
            [126.797333530964, 37.642065188938],
            [126.797275826617, 37.64211030001],
            [126.797197392821, 37.642161790102],
            [126.797057436725, 37.64225398773],
            [126.797013490341, 37.642269002904],
            [126.797180317274, 37.642637763865],
            [126.797266541274, 37.642646274217],
            [126.797434714771, 37.642635393141],
            [126.797564963235, 37.642583803271],
            [126.797611040472, 37.642688765459],
            [126.797615758554, 37.64269951324],
            [126.797650204743, 37.64277769634],
            [126.797726473015, 37.64295082438],
            [126.797765232223, 37.643038817505],
            [126.797739703053, 37.64306012653],
            [126.797597872581, 37.643249059265],
            [126.797597482581, 37.643346138678],
            [126.797638539889, 37.643434883621],
            [126.79772124057, 37.643605328845],
            [126.797779417325, 37.643723619253],
            [126.797878293801, 37.643862684296],
            [126.798144281071, 37.643966277725],
            [126.798512022544, 37.6440475213],
            [126.799086103306, 37.644109458664],
            [126.799297813571, 37.644014489537],
            [126.799528511481, 37.644049968698],
            [126.799928651442, 37.644113631237],
            [126.800014196577, 37.644126724386],
            [126.800055106563, 37.644136984279],
            [126.80013815725, 37.644163317413],
            [126.800157117578, 37.644170188176],
            [126.800428268638, 37.644213428941],
            [126.80053731355, 37.644230823373],
            [126.80081451021, 37.644305562632],
            [126.801136922236, 37.644458474569],
            [126.801351438286, 37.644567171961],
            [126.80158595391, 37.644675803748],
            [126.801676713896, 37.644717843849],
            [126.8019825827, 37.644859481276],
            [126.802035624327, 37.644884059474],
            [126.802087500344, 37.644908104103],
            [126.802173550906, 37.644757984897],
            [126.802852241898, 37.643574806742],
            [126.802860909964, 37.643553468223],
            [126.803300176554, 37.643411726791],
            [126.803357758267, 37.643394732014],
            [126.803386786851, 37.643386311598],
            [126.803443737295, 37.643368081383],
            [126.803447638097, 37.643366916664],
            [126.803454532567, 37.643364846985],
            [126.803645095013, 37.643303062504],
            [126.803842874931, 37.643292501248],
            [126.803900375737, 37.643305968048],
            [126.803986574959, 37.64332869985],
            [126.804091728989, 37.643309090436],
            [126.804188288319, 37.643317486888],
            [126.804225913436, 37.643344561053],
            [126.804327238807, 37.643423124313],
            [126.804531031175, 37.643534338773],
            [126.804743341007, 37.643595427807],
            [126.804982935296, 37.643626397258],
            [126.805458767224, 37.643689626527],
            [126.805774154025, 37.643797753687],
            [126.805806438259, 37.643831044225],
            [126.805815479105, 37.643840059965],
            [126.805919686791, 37.643948115557],
            [126.805954289695, 37.643970057609],
            [126.805993982344, 37.643995458821],
            [126.806003742137, 37.644002538641],
            [126.806170931755, 37.644108454934],
            [126.806295231232, 37.644237545188],
            [126.806441541684, 37.644398881571],
            [126.806597156241, 37.644598884977],
            [126.806637692899, 37.644652740162],
            [126.80665559043, 37.644741425684],
            [126.806658163074, 37.644754223781],
            [126.806984344117, 37.645027036753],
            [126.80707265272, 37.645097998974],
            [126.807322693716, 37.645283651291],
            [126.807403556265, 37.645342437842],
            [126.807530121241, 37.645604108985],
            [126.807568673408, 37.645659041749],
            [126.807600789174, 37.645705449708],
            [126.807738613507, 37.645907945058],
            [126.807880829929, 37.646055650151],
            [126.80796152552, 37.646139969671],
            [126.808164064159, 37.646237102532],
            [126.808251493458, 37.646272320741],
            [126.808354303566, 37.646314528605],
            [126.80838975638, 37.646319632105],
            [126.80846054918, 37.646329658694],
            [126.80853624238, 37.646337602993],
            [126.808594821477, 37.646351591832],
            [126.808670161042, 37.64636485122],
            [126.808739086961, 37.646378235226],
            [126.808864440818, 37.64640498271],
            [126.80896678873, 37.646428917513],
            [126.809282261253, 37.646523439692],
            [126.809367613577, 37.646560311483],
            [126.809652748385, 37.646679019482],
            [126.809752287828, 37.646720193696],
            [126.809920057973, 37.646785408546],
            [126.810024671888, 37.646814175336],
            [126.810165564285, 37.646857641722],
            [126.810301383058, 37.646900117654],
            [126.810432836001, 37.64694396485],
            [126.81054086278, 37.646989738106],
            [126.810615119986, 37.647023401581],
            [126.810619707835, 37.647139004083],
            [126.810760141468, 37.647211588524],
            [126.810793399653, 37.647228805865],
            [126.810976751507, 37.647325335254],
            [126.811133031536, 37.647453778252],
            [126.811254888618, 37.647608609143],
            [126.811287604756, 37.647651872652],
            [126.811410664113, 37.647766485801],
            [126.811488147997, 37.647837256065],
            [126.811547811627, 37.647893248626],
            [126.811617236192, 37.64793851725],
            [126.811719790826, 37.647975901548],
            [126.811872848099, 37.648031458522],
            [126.811872859454, 37.64803144953],
            [126.812319578586, 37.647836664249],
            [126.8126306376, 37.647646606501],
            [126.812681939538, 37.647528030239],
            [126.812686031366, 37.647518576553],
            [126.812720051048, 37.647333660858],
            [126.812865903883, 37.647011795642],
            [126.81306859156, 37.6465286564],
            [126.813092987351, 37.646470312184],
            [126.81314648276, 37.646375434841],
            [126.813235287046, 37.646208085335],
            [126.813330348619, 37.646030564718],
            [126.813352753762, 37.645988615015],
            [126.813224091788, 37.645660544897],
            [126.813231799388, 37.645569738902],
            [126.813247461322, 37.645384622507],
            [126.813149558761, 37.64529644096],
            [126.813124789887, 37.645273868022],
            [126.812775245903, 37.6449554543],
            [126.8127883198, 37.644938266587],
            [126.812793208455, 37.644931778376],
            [126.812854376789, 37.644849265784],
            [126.813041518022, 37.644597291811],
            [126.813641803609, 37.644446570047],
            [126.813769065133, 37.643827307003],
            [126.813777175994, 37.64377852308],
            [126.813784378329, 37.643735062475],
            [126.813797943602, 37.643653005195],
            [126.81383241987, 37.64350997613],
            [126.814126078444, 37.643251241267],
            [126.81423099928, 37.643115811047],
            [126.814506599206, 37.642963532854],
            [126.814607604976, 37.642852692719],
            [126.814575292385, 37.642680104695],
            [126.814434613864, 37.642526355462],
            [126.814370251672, 37.642392999088],
            [126.814227240869, 37.642266004879],
            [126.81421580607, 37.64221733404],
            [126.81419994022, 37.642141942235],
            [126.81417829674, 37.642039151591],
            [126.812974002655, 37.641945964389],
            [126.812707872125, 37.641924996557],
            [126.812579368535, 37.641784310709],
            [126.812554763578, 37.641755701364],
            [126.811899761727, 37.641433461656],
            [126.81185535344, 37.641413145352],
            [126.811795031336, 37.641163568169],
            [126.811739482701, 37.641131043704],
            [126.811690105896, 37.640997349555],
            [126.811708189882, 37.640979142898],
            [126.811643483692, 37.640952225743],
            [126.811715644115, 37.640801401363],
            [126.811745522796, 37.640743084207],
            [126.811727486655, 37.640724693307],
            [126.811683806641, 37.640681060852],
            [126.811688574675, 37.64056604994],
            [126.811664359334, 37.640375715853],
            [126.811666270452, 37.640292558848],
            [126.81166757851, 37.640237123902],
            [126.811704196175, 37.640081701394],
            [126.811712130153, 37.640062730594],
            [126.811735059154, 37.639890203544],
            [126.811743824294, 37.639825077077],
            [126.811767268866, 37.639739341865],
            [126.81177440548, 37.63971311692],
            [126.811775150336, 37.639700900883],
            [126.811784988393, 37.639628189917],
            [126.811799890899, 37.63951984448],
            [126.811821986928, 37.639460893103],
            [126.81182452454, 37.639456554478],
            [126.811818116207, 37.63929884633],
            [126.811813861997, 37.639194055968],
            [126.811933830985, 37.63906612999],
            [126.811968058818, 37.638838598405],
            [126.811973128852, 37.638805180333],
            [126.812005974075, 37.638765049556],
            [126.812065421875, 37.638733791054],
            [126.812218500243, 37.638662030733],
            [126.811947270808, 37.638726213276],
            [126.811945242001, 37.638632868882],
            [126.811940533984, 37.638563936637],
            [126.811936630731, 37.63849933037],
            [126.811894060591, 37.637997147888],
            [126.811808662866, 37.637674010597],
            [126.811551629599, 37.637525836922],
            [126.811307841269, 37.637382279018],
            [126.810984336693, 37.637221743646],
            [126.810430797563, 37.637123092535],
            [126.810053830141, 37.637091217545],
            [126.809879647987, 37.636623958673],
            [126.809753281159, 37.636464190424],
            [126.80987932082, 37.636266090268],
            [126.8099779005, 37.636216786726],
            [126.810147258991, 37.636135072664],
            [126.810220563944, 37.636045364171],
            [126.810219116495, 37.636022206726],
            [126.810274682185, 37.635962345819],
            [126.810313936607, 37.635971986818],
            [126.810365511461, 37.635970700899],
            [126.810369591562, 37.635970112864],
            [126.810400546711, 37.635960036031],
            [126.810408316173, 37.635956597876],
            [126.810464153211, 37.635914576655],
            [126.810552731752, 37.635846164739],
            [126.810565662932, 37.63580905642],
            [126.810560133829, 37.635782630838],
            [126.810572396962, 37.635780785662],
            [126.810855502521, 37.635738329955],
            [126.810878101985, 37.63573494276],
            [126.811369554927, 37.635661243031],
            [126.811400706983, 37.635658265949],
            [126.81145125963, 37.635653446079],
            [126.811478780882, 37.635630344318],
            [126.811647450278, 37.63548879312],
            [126.811774335094, 37.635401242103],
            [126.811783101607, 37.635393273546],
            [126.811858661821, 37.635324506346],
            [126.811864271494, 37.635319397815],
            [126.811929964138, 37.635219251707],
            [126.811939356747, 37.635196715351],
            [126.8119416297, 37.635162707131],
            [126.811634423433, 37.63506952066],
            [126.811459668152, 37.635016514139],
            [126.811341278027, 37.634980599652],
            [126.810455784389, 37.634709596865],
            [126.809661211184, 37.634466124996],
            [126.808726455021, 37.634183604724],
            [126.808707759415, 37.634180186503],
            [126.808657363168, 37.634247551267],
            [126.808654006455, 37.634253203915],
            [126.808633474853, 37.634245656207],
            [126.808592691289, 37.63431521702],
            [126.808335424047, 37.634242474683],
            [126.80840786143, 37.634116015021],
            [126.808317746256, 37.634117227977],
            [126.808210431663, 37.63411912446],
            [126.807760234234, 37.634127765215],
            [126.807759765373, 37.63413381],
            [126.807408180314, 37.634136718914],
            [126.807302875661, 37.634136797905],
            [126.807249106855, 37.634137051761],
            [126.805356536307, 37.633431014469],
            [126.803820039919, 37.632864636738],
            [126.803642768366, 37.632799054797],
            [126.80330105893, 37.632673263479],
            [126.802961580012, 37.632548384909],
            [126.802533472489, 37.6323909568],
            [126.802481976638, 37.632371994478],
            [126.802503835799, 37.632429315468],
            [126.802515930597, 37.632528704623],
            [126.80252414364, 37.63263507881],
            [126.80252130055, 37.63263490283],
            [126.802445395299, 37.632648199349],
            [126.802403640215, 37.632655444829],
            [126.802405715574, 37.63267586447],
            [126.802403472611, 37.632697033644],
            [126.802392525049, 37.632800366239],
            [126.802318924336, 37.632981581642],
            [126.80224192313, 37.633171819038],
            [126.802211186961, 37.633248395211],
            [126.802175581921, 37.633336693868],
            [126.802075726168, 37.633299179635],
            [126.801991168247, 37.633268808904],
            [126.801884231957, 37.633231327584],
            [126.801813864861, 37.633206900153],
            [126.801797074917, 37.633202799319],
            [126.801108909183, 37.632948385911],
            [126.801043243236, 37.632924290356],
            [126.800970354282, 37.632898398553],
            [126.800811564984, 37.632840384951],
            [126.800580004255, 37.632755100796],
            [126.800429828565, 37.632700173679],
            [126.800165211831, 37.632601957409],
            [126.799783428983, 37.63246305939],
            [126.799739808767, 37.632443577761],
            [126.799333928241, 37.632293158628],
            [126.79891507957, 37.632137922633],
            [126.798719208517, 37.632065327794],
            [126.798514736583, 37.631989555404],
            [126.79810346265, 37.631837122585],
            [126.798065753191, 37.631823146485],
            [126.798083599375, 37.631792832347],
            [126.798565304711, 37.630970258858],
            [126.798191099641, 37.630831756116],
            [126.797853570957, 37.630708307756],
            [126.797770275144, 37.630677945181],
            [126.797369305726, 37.630530356809],
            [126.797279411539, 37.630497874184],
            [126.79733410984, 37.630471624273],
            [126.797367209124, 37.630456103646],
            [126.797454109316, 37.630415187419],
            [126.797781272746, 37.630268001495],
            [126.797936915834, 37.630197399254],
            [126.797940874689, 37.630195496013],
            [126.797809241862, 37.630102378077],
            [126.797760843516, 37.63003287421],
            [126.797671087245, 37.630020565002],
            [126.797641240994, 37.630010656745],
            [126.797621408968, 37.630000819844],
            [126.797578092449, 37.629966282603],
            [126.797548157981, 37.629934777744],
            [126.797529692681, 37.629893264826],
            [126.797529803606, 37.629831953509],
            [126.797523715126, 37.629796507544],
            [126.79750442706, 37.629765904035],
            [126.797438488846, 37.629764114195],
            [126.797444591806, 37.62971552603],
            [126.797552499331, 37.629577340496],
            [126.797573081678, 37.629544706631],
            [126.797558164352, 37.629519012001],
            [126.797545918051, 37.62949837647],
            [126.797521527031, 37.629457249735],
            [126.797523221827, 37.62944230545],
            [126.79753041311, 37.629380961319],
            [126.797532487717, 37.629351394826],
            [126.797535781799, 37.629327434521],
            [126.797543268871, 37.629253188903],
            [126.79754456133, 37.629240379238],
            [126.797546645562, 37.629219723427],
            [126.797548077456, 37.629205634615],
            [126.797549917513, 37.629187275875],
            [126.797573716021, 37.629109400479],
            [126.797584447478, 37.629070812142],
            [126.797588555481, 37.629055763908],
            [126.797590926217, 37.629046334771],
            [126.797595938089, 37.62902801755],
            [126.797693949222, 37.62868440991],
            [126.797722748539, 37.628579684974],
            [126.797771679764, 37.628412115566],
            [126.797771729659, 37.628298385252],
            [126.797772101788, 37.62825750854],
            [126.797777872156, 37.628206135751],
            [126.797797813862, 37.628022046393],
            [126.797815410576, 37.627937565068],
            [126.797839289603, 37.627813325429],
            [126.797863245971, 37.627698077673],
            [126.797853055925, 37.627675373467],
            [126.797856728735, 37.62765785579],
            [126.797818988846, 37.627597704445],
            [126.797817015009, 37.627594556625],
            [126.797796934289, 37.627555698829],
            [126.79779469949, 37.627552703725],
            [126.797744056076, 37.627484574443],
            [126.797452478695, 37.627597341478],
            [126.797255415549, 37.627675466667],
            [126.797097205043, 37.62783313408],
            [126.797011637265, 37.62786011425],
            [126.796879506534, 37.627851361931],
            [126.796699298458, 37.627830416885],
            [126.796567444762, 37.627877579371],
            [126.7962616362, 37.628056126139],
            [126.795927324038, 37.628185411053],
            [126.795539579301, 37.628395347365],
            [126.794789002591, 37.628734514184],
            [126.794702166323, 37.628785249228],
            [126.794582468962, 37.628855495813],
            [126.794398890356, 37.62892181002],
            [126.794513758284, 37.629106198405],
            [126.794569093528, 37.629058579702],
            [126.79464733636, 37.629006261993],
            [126.794632964856, 37.629045618521],
            [126.794600704678, 37.629134425359],
            [126.794573250388, 37.629238593271],
            [126.794601967962, 37.629308036911],
            [126.794550808369, 37.629336805495],
            [126.794537832425, 37.629399851152],
            [126.794463955549, 37.629744390429],
            [126.794428525122, 37.629901801068],
            [126.794413478394, 37.62996801451],
            [126.794323284539, 37.630366574648],
            [126.79429716296, 37.630480060917],
            [126.7942697075, 37.630600635526],
            [126.794251660797, 37.630682304439],
            [126.794202736747, 37.630909156619],
            [126.794187236208, 37.630979540748],
            [126.794164174446, 37.631088410339],
            [126.794117184532, 37.63130802201],
            [126.794096208876, 37.63139992985],
            [126.794088249827, 37.631438874107],
            [126.794082372999, 37.631470479061],
            [126.79403656721, 37.631500328984]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281112',
      properties: {
        rgnCode: '41281112',
        colCode: '41280',
        rgnKo: ['동산동'],
        rgnSize: 1,
        rgnBbox: [
          126.876856854723, 37.633172521454, 126.913287313238, 37.651657366557
        ],
        rgnCenter: [126.89610746221, 37.641733292764],
        rgnArea: 4262694,
        predVal: [
          0.13165, 0.13388, 0.1281, 0.14386, 0.18024, 0.16388, 0.1798, 0.18909,
          0.16284, 0.17143, 0.17085, 0.13082, 0.18803, 0.16713, 0.19007,
          0.07589, 0.1762, 0.16166, 0.20855, 0.18212, 0.18653, 0.18979, 0.16469,
          0.1865, 0.14929, 0.18193, 0.16629, 0.17122, 0.1674, 0.19174, 0.18429
        ]
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.876856854723, 37.639296563345],
              [126.877210282307, 37.640193408129],
              [126.877210496783, 37.640193885873],
              [126.877211342979, 37.640196022079],
              [126.877409015183, 37.640627347515],
              [126.877427914177, 37.640708905779],
              [126.877518947282, 37.640882169194],
              [126.876869356119, 37.64117241004],
              [126.876858595698, 37.641177218895],
              [126.877377060335, 37.642018737011],
              [126.877393490304, 37.64204540518],
              [126.878017429603, 37.642614098526],
              [126.878232711321, 37.642858974791],
              [126.879058276629, 37.643692003265],
              [126.879717958358, 37.644266634975],
              [126.880191541866, 37.644583635835],
              [126.881018062664, 37.645011833851],
              [126.881246846254, 37.645122310721],
              [126.881874371138, 37.645390178235],
              [126.882114686979, 37.645503791498],
              [126.882796396707, 37.645882204604],
              [126.883486617262, 37.646342971457],
              [126.884867383959, 37.647398711628],
              [126.885143696703, 37.647571701542],
              [126.885565887047, 37.647789648796],
              [126.885843020617, 37.647902957246],
              [126.886218845726, 37.648026605629],
              [126.887157538055, 37.648271422378],
              [126.887752155222, 37.648467545002],
              [126.888334653559, 37.64869928658],
              [126.889858126663, 37.649423212521],
              [126.890584821518, 37.649723337244],
              [126.891080594357, 37.649894528202],
              [126.891870735527, 37.650092749997],
              [126.89314543069, 37.650408908795],
              [126.893691631285, 37.650562512004],
              [126.894053242389, 37.650683743825],
              [126.894967139708, 37.651026375742],
              [126.895713555252, 37.651365588946],
              [126.896021518181, 37.651478315449],
              [126.89626774157, 37.651546234646],
              [126.896570689078, 37.651608518652],
              [126.896829422393, 37.651637030143],
              [126.89707496578, 37.651657366557],
              [126.897284826287, 37.651652731946],
              [126.897405159841, 37.65164394558],
              [126.897525493355, 37.6516351681],
              [126.897715339094, 37.651616180565],
              [126.897980386955, 37.651569607822],
              [126.898609193644, 37.651418478846],
              [126.899036976406, 37.65127260494],
              [126.899334791342, 37.651136752948],
              [126.899637564179, 37.651020644849],
              [126.899858414648, 37.65094940605],
              [126.900114486485, 37.650884404835],
              [126.900534372647, 37.650816615231],
              [126.900724417467, 37.650807524738],
              [126.90113886851, 37.650710707923],
              [126.901239219384, 37.6506872688],
              [126.90104184232, 37.650167211069],
              [126.90156235155, 37.650046111649],
              [126.902235977758, 37.64986044885],
              [126.902380772244, 37.649817873388],
              [126.902514699038, 37.649818418323],
              [126.903157385394, 37.649890817711],
              [126.903541766204, 37.64960378045],
              [126.903562841015, 37.649587895733],
              [126.903635423108, 37.649533212706],
              [126.903636409101, 37.649533096398],
              [126.903641989667, 37.649528929511],
              [126.903992829115, 37.649490541181],
              [126.904230000227, 37.649462230138],
              [126.904451553131, 37.649392875344],
              [126.904469418828, 37.649387276965],
              [126.904614843603, 37.649276531466],
              [126.904820622145, 37.649119831459],
              [126.905028645284, 37.648970466865],
              [126.90507403535, 37.648938185985],
              [126.905182602478, 37.648849771856],
              [126.905223108172, 37.648817802271],
              [126.90541043901, 37.648668104762],
              [126.905706140217, 37.648414017874],
              [126.905762175884, 37.648359320087],
              [126.905901057111, 37.648232034767],
              [126.906092305481, 37.648055967774],
              [126.90622226893, 37.647936729578],
              [126.906335759036, 37.647833136905],
              [126.906386467487, 37.647787822685],
              [126.906482247769, 37.647700973736],
              [126.906575474525, 37.647617095873],
              [126.906600204868, 37.647594852661],
              [126.906624980591, 37.647572555421],
              [126.906660088847, 37.647604901656],
              [126.906892807947, 37.647382592563],
              [126.907091820031, 37.647192519974],
              [126.906875033059, 37.647335006781],
              [126.90708009362, 37.647137776304],
              [126.90727556227, 37.646953241561],
              [126.907313207516, 37.646887824737],
              [126.907338747216, 37.646843427014],
              [126.907511723358, 37.646568136624],
              [126.907700026786, 37.646276649598],
              [126.908423872221, 37.645933185287],
              [126.908793101776, 37.645815430174],
              [126.909075242565, 37.645650475634],
              [126.909463249751, 37.645427859505],
              [126.909865794328, 37.645267979346],
              [126.910140379146, 37.64512576602],
              [126.910543923681, 37.64497960618],
              [126.910885515183, 37.644838415817],
              [126.911275960931, 37.64472757969],
              [126.911432701796, 37.644650611946],
              [126.911654677216, 37.644542303683],
              [126.912065225406, 37.644389981278],
              [126.912499729986, 37.644214502406],
              [126.912465566886, 37.644167932185],
              [126.912223035708, 37.644270243942],
              [126.91220191178, 37.644216385605],
              [126.912197030734, 37.644204849412],
              [126.91217992454, 37.644164373612],
              [126.911952230243, 37.643544986914],
              [126.911028910675, 37.640715386785],
              [126.911024598589, 37.640702166147],
              [126.910793911905, 37.640025796779],
              [126.910556253847, 37.639522958706],
              [126.91023847573, 37.638759315362],
              [126.910091720695, 37.638509090462],
              [126.91021782662, 37.638227272332],
              [126.910218623926, 37.638047897381],
              [126.910325363546, 37.637795985583],
              [126.911209928112, 37.635832814315],
              [126.910854132264, 37.635316164117],
              [126.910760377403, 37.635282647745],
              [126.910902136748, 37.635249159241],
              [126.909827635361, 37.63486587309],
              [126.909720666279, 37.634772214363],
              [126.909220250463, 37.634343661293],
              [126.908545002191, 37.633993533715],
              [126.90717983685, 37.633378190383],
              [126.906923910438, 37.633291356451],
              [126.906268111014, 37.633172521454],
              [126.906254230833, 37.633183069711],
              [126.905552682956, 37.633499014785],
              [126.90448946129, 37.633978375499],
              [126.90347609811, 37.634388023332],
              [126.901635616227, 37.634681730096],
              [126.900081231272, 37.634607605032],
              [126.898386771374, 37.634568835665],
              [126.896579343359, 37.634542555237],
              [126.895083376056, 37.634502833627],
              [126.893778083169, 37.634485143053],
              [126.891993604137, 37.63450271798],
              [126.891841496185, 37.634504000235],
              [126.891601087504, 37.634598126908],
              [126.8912710543, 37.634683402869],
              [126.89018125538, 37.634633546578],
              [126.889817212591, 37.634656537828],
              [126.889092872867, 37.634569455135],
              [126.889052457754, 37.634632088627],
              [126.888799843954, 37.634553435487],
              [126.888605452217, 37.634489289218],
              [126.888575112419, 37.634534309064],
              [126.888521827102, 37.634589316792],
              [126.888445154566, 37.63468408025],
              [126.888296100822, 37.634620967987],
              [126.888208035193, 37.634541191974],
              [126.888153688364, 37.634481891479],
              [126.888089785056, 37.634523464243],
              [126.887972324072, 37.634545560366],
              [126.887942365409, 37.634615312253],
              [126.88787723978, 37.63468567897],
              [126.887812247507, 37.634727926266],
              [126.887941337333, 37.63491116529],
              [126.887921718893, 37.634930949892],
              [126.887897293652, 37.634945378054],
              [126.887856539093, 37.634999496566],
              [126.887795217394, 37.63507137153],
              [126.887536455052, 37.635353920682],
              [126.887500351, 37.63533740692],
              [126.887234995379, 37.63559615421],
              [126.886841588389, 37.635990463709],
              [126.886267330997, 37.636563880838],
              [126.885785906469, 37.636928368487],
              [126.885801868116, 37.637229022236],
              [126.885714890047, 37.637346145029],
              [126.88566632521, 37.637408363889],
              [126.885645480128, 37.637436589055],
              [126.88551627211, 37.637607134203],
              [126.885454763115, 37.63768733276],
              [126.885426046178, 37.637706702546],
              [126.885398238546, 37.637724181169],
              [126.88531548836, 37.637773833639],
              [126.885281214622, 37.637796522523],
              [126.885156283912, 37.637875108743],
              [126.885016686079, 37.637964906524],
              [126.885001357317, 37.637971919009],
              [126.885010915896, 37.637981559893],
              [126.88501669772, 37.637986475928],
              [126.885068494257, 37.638039252241],
              [126.885081137255, 37.638047157278],
              [126.885017569988, 37.638088143046],
              [126.884840661541, 37.638164154858],
              [126.884769576696, 37.638194303313],
              [126.88465616671, 37.638258719018],
              [126.884816261078, 37.638458678148],
              [126.88487042945, 37.638552559462],
              [126.884864540299, 37.638572789584],
              [126.884848777873, 37.638639680552],
              [126.884831228701, 37.638704398395],
              [126.884778190469, 37.638699192341],
              [126.884635665854, 37.638573085641],
              [126.884471347425, 37.638487591213],
              [126.884358750722, 37.638430465634],
              [126.884273801416, 37.638393378123],
              [126.884228547251, 37.638373610758],
              [126.884184380443, 37.638354051681],
              [126.88397785691, 37.638212699055],
              [126.883900633679, 37.638133291068],
              [126.883803746056, 37.638058593542],
              [126.883732121994, 37.63800667083],
              [126.883674411757, 37.637954239406],
              [126.883641901475, 37.637921708686],
              [126.883604470442, 37.637913012895],
              [126.883453004316, 37.63787652502],
              [126.883395779998, 37.637846546295],
              [126.883270660512, 37.637685488431],
              [126.88311423667, 37.637546481971],
              [126.883035090998, 37.637451547643],
              [126.882972066884, 37.637349944209],
              [126.882839985273, 37.637229206621],
              [126.882798863172, 37.637190801642],
              [126.88271730584, 37.637081151711],
              [126.882614204013, 37.636990229349],
              [126.882571937062, 37.636881258596],
              [126.882480865767, 37.636819945346],
              [126.882402877742, 37.636753113233],
              [126.882326497925, 37.636708014265],
              [126.882310828298, 37.636687275957],
              [126.882291717859, 37.636657344202],
              [126.882274624623, 37.636619990429],
              [126.882212338902, 37.636482807727],
              [126.882158657086, 37.636364004622],
              [126.88212842529, 37.636267803714],
              [126.882049988054, 37.636015063235],
              [126.882041311211, 37.6358159925],
              [126.882041947142, 37.635737229697],
              [126.882049597976, 37.635727975396],
              [126.882024989148, 37.635714679079],
              [126.881935955803, 37.635789694357],
              [126.881826829204, 37.635882582597],
              [126.881721918652, 37.63596731216],
              [126.881547717867, 37.63600918378],
              [126.881467235877, 37.636035869958],
              [126.881379873039, 37.636142330622],
              [126.88126906361, 37.636287446283],
              [126.881304709701, 37.636348550851],
              [126.881336754364, 37.636352574824],
              [126.881403103973, 37.636371157472],
              [126.881411677302, 37.636373085281],
              [126.881414353274, 37.636392828418],
              [126.881415516216, 37.636409389565],
              [126.881413351418, 37.636416964581],
              [126.881406181407, 37.636458222044],
              [126.881383595395, 37.636546575914],
              [126.881327867807, 37.63668579223],
              [126.8813246303, 37.636712223606],
              [126.881313803919, 37.636941412183],
              [126.881303884563, 37.637008407754],
              [126.881253560193, 37.637190119614],
              [126.881233414246, 37.63726156459],
              [126.881224266018, 37.6373983236],
              [126.881218242642, 37.637438005503],
              [126.881207692752, 37.637453284328],
              [126.881118425587, 37.637538002251],
              [126.881015942526, 37.63764703299],
              [126.880956142271, 37.637718473446],
              [126.880915435657, 37.637780932627],
              [126.880863695844, 37.637856156367],
              [126.880844538975, 37.637883130049],
              [126.880814518644, 37.637930022229],
              [126.88075210583, 37.638019353309],
              [126.880714343719, 37.638159344507],
              [126.880697233943, 37.638258425453],
              [126.880691224871, 37.638282060937],
              [126.880640821292, 37.638413299682],
              [126.880682280502, 37.638530108768],
              [126.880769301378, 37.638700311322],
              [126.880843968855, 37.638852112233],
              [126.880854764282, 37.638874332356],
              [126.880925434281, 37.639123263444],
              [126.881048675808, 37.639266896943],
              [126.88114126665, 37.639377658435],
              [126.881237161322, 37.639512479303],
              [126.881212138663, 37.63955840361],
              [126.881201320558, 37.639578223079],
              [126.88109103588, 37.63975985561],
              [126.881114431332, 37.639816659045],
              [126.881095925267, 37.63984028179],
              [126.880932126947, 37.639889433965],
              [126.880845107863, 37.639913184843],
              [126.880766302801, 37.6399300606],
              [126.880584524113, 37.639985689931],
              [126.880440896026, 37.640102570499],
              [126.880430362224, 37.640149491567],
              [126.88041302407, 37.640249148828],
              [126.880316681354, 37.640362572966],
              [126.880330249576, 37.640407653758],
              [126.880385544746, 37.640467859745],
              [126.880454453171, 37.640538531005],
              [126.880595322665, 37.640649171245],
              [126.880751214321, 37.640775080227],
              [126.880862740346, 37.640864192803],
              [126.881029722165, 37.641083165619],
              [126.881070252691, 37.641201018702],
              [126.880944194578, 37.641438108065],
              [126.8808153656, 37.641496494765],
              [126.8807150515, 37.641472921658],
              [126.880721002962, 37.641471026691],
              [126.880672542799, 37.641464390927],
              [126.880659337694, 37.641439708664],
              [126.880652713567, 37.641402221253],
              [126.880570990067, 37.641317310563],
              [126.880298941921, 37.641043810221],
              [126.880175606942, 37.64094279208],
              [126.880068589729, 37.640875360995],
              [126.880026935218, 37.640850451156],
              [126.879979855028, 37.640817697215],
              [126.879983104672, 37.640770309191],
              [126.88001094556, 37.640719973272],
              [126.880024596014, 37.640686651193],
              [126.8800132189, 37.640645915332],
              [126.879995476572, 37.64060375838],
              [126.87995413603, 37.640580966132],
              [126.879912975371, 37.640559048002],
              [126.879877658386, 37.64053878467],
              [126.87984234142, 37.640518521327],
              [126.879674485245, 37.640414645685],
              [126.879465749769, 37.640297393065],
              [126.879358348901, 37.640223041432],
              [126.879253109964, 37.640143195983],
              [126.879050219678, 37.64002058787],
              [126.878841204888, 37.639902207628],
              [126.878804208934, 37.639869824089],
              [126.878783527925, 37.639837637707],
              [126.87883042541, 37.639843002246],
              [126.87890469796, 37.639805959211],
              [126.878971839197, 37.639764854327],
              [126.879043631675, 37.639713185775],
              [126.878996474736, 37.639665772464],
              [126.878957031445, 37.639612763072],
              [126.878956680971, 37.63961230321],
              [126.878921178819, 37.639518988195],
              [126.878915391527, 37.639503782703],
              [126.878837523862, 37.639315757993],
              [126.878794659499, 37.639248806874],
              [126.878739902239, 37.639152219244],
              [126.878722182947, 37.639110485574],
              [126.878712367993, 37.63907804019],
              [126.878712153912, 37.639077319186],
              [126.878893866215, 37.638951281315],
              [126.878823228412, 37.638914357924],
              [126.878750799546, 37.638864278364],
              [126.878700896714, 37.638826655691],
              [126.878570475222, 37.638698040547],
              [126.878515034344, 37.638652303231],
              [126.878458642272, 37.638633774401],
              [126.878392161336, 37.638618478533],
              [126.8783477764, 37.638601493839],
              [126.878315748412, 37.638573160714],
              [126.878314956486, 37.638572457125],
              [126.878269400804, 37.638516630062],
              [126.878304760434, 37.638441615611],
              [126.87832610076, 37.638417221418],
              [126.878296551082, 37.638198613539],
              [126.878087298473, 37.638070275885],
              [126.878059130792, 37.638037189567],
              [126.878058667183, 37.638036639486],
              [126.877826836791, 37.637914224189],
              [126.877078537461, 37.638032366319],
              [126.877099301087, 37.638082212246],
              [126.877151943143, 37.638174978281],
              [126.877187624158, 37.638247634633],
              [126.877176174132, 37.638299392705],
              [126.877175861862, 37.638303212519],
              [126.877180074089, 37.638359077532],
              [126.877171304414, 37.638440904029],
              [126.877143568583, 37.63850914182],
              [126.877102351254, 37.638563156982],
              [126.877025571561, 37.638677049579],
              [126.876987777365, 37.638716472484],
              [126.876945625744, 37.638787064579],
              [126.876948379565, 37.638902032141],
              [126.876905933322, 37.639148945083],
              [126.876862194388, 37.639287829511],
              [126.876856854723, 37.639296563345]
            ]
          ],
          [
            [
              [126.906008350025, 37.648676978412],
              [126.906302272696, 37.648598470727],
              [126.906677485565, 37.648484529243],
              [126.906643010357, 37.64850648533],
              [126.907470391822, 37.64826334378],
              [126.907651419759, 37.64821015019],
              [126.908109262476, 37.64807320488],
              [126.908622196495, 37.647917741079],
              [126.909535038756, 37.647645100274],
              [126.909521024402, 37.647615627437],
              [126.909735835993, 37.647546419611],
              [126.910744714445, 37.647030130117],
              [126.911685293231, 37.646292049757],
              [126.911849405932, 37.646164776591],
              [126.912028501313, 37.646015530743],
              [126.912373664116, 37.64571306408],
              [126.912944809271, 37.645275169347],
              [126.913265424852, 37.645028091642],
              [126.913286043027, 37.645012240871],
              [126.913287313238, 37.645011259757],
              [126.912990966934, 37.645049599546],
              [126.91298901627, 37.645051084693],
              [126.912928749763, 37.645058832899],
              [126.912894069772, 37.645065293882],
              [126.912684601664, 37.645099310376],
              [126.912576843271, 37.645120176886],
              [126.912326998646, 37.6451768669],
              [126.912019002504, 37.645329015761],
              [126.91193954864, 37.64536830109],
              [126.911811170873, 37.645471131064],
              [126.911773856602, 37.645500771831],
              [126.911702424286, 37.645549811663],
              [126.911632149917, 37.645596951274],
              [126.911410425538, 37.64574807405],
              [126.911300266739, 37.645822635734],
              [126.911166970023, 37.645878403255],
              [126.910968610599, 37.645962312249],
              [126.91086725228, 37.646001985477],
              [126.910213141056, 37.646444094554],
              [126.909970154853, 37.646659329612],
              [126.909720910116, 37.646876487379],
              [126.909350670671, 37.647065682964],
              [126.909161098075, 37.647159497488],
              [126.909007660569, 37.647234626777],
              [126.908868825649, 37.647303028035],
              [126.90874104693, 37.647364545358],
              [126.908713109933, 37.647378200198],
              [126.908716910567, 37.647383536965],
              [126.908654664565, 37.647413940969],
              [126.908618205809, 37.64739174826],
              [126.908437533905, 37.647252585278],
              [126.908420298759, 37.647262031925],
              [126.908131583967, 37.647413437978],
              [126.908052698445, 37.647456937691],
              [126.907669727012, 37.647664083348],
              [126.907632414933, 37.647689803549],
              [126.907552789261, 37.647744708689],
              [126.907298763105, 37.647926214884],
              [126.90723123564, 37.647974597392],
              [126.907099480538, 37.648069238628],
              [126.906715368264, 37.648277893889],
              [126.906590960632, 37.648350043093],
              [126.906573191327, 37.648360353975],
              [126.906595952592, 37.64835409249],
              [126.906108464475, 37.648631289857],
              [126.906051547759, 37.64865728201],
              [126.906008350025, 37.648676978412]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281116',
      properties: {
        rgnCode: '41281116',
        colCode: '41280',
        rgnKo: ['고양동'],
        rgnSize: 1,
        rgnBbox: [
          126.888835798063, 37.69742693728, 126.908666928306, 37.719880207382
        ],
        rgnCenter: [126.898756187961, 37.707151888104],
        rgnArea: 2006336,
        predVal: [
          0.59243, 0.60244, 0.57645, 0.64738, 0.81107, 0.73746, 0.80909,
          0.85091, 0.73277, 0.77143, 0.76884, 0.58867, 0.84614, 0.75208, 0.8553,
          0.34149, 0.79289, 0.72748, 0.93845, 0.81952, 0.8394, 0.85404, 0.7411,
          0.83926, 0.67182, 0.81868, 0.74828, 0.77047, 0.75329, 0.86281, 0.82932
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.902303477679, 37.699673540138],
            [126.902261817931, 37.699545810917],
            [126.902177406356, 37.699378294972],
            [126.902016825453, 37.699112905645],
            [126.901932005112, 37.699005925198],
            [126.901803988809, 37.698886429764],
            [126.901593584044, 37.698716076843],
            [126.901477306344, 37.698527784075],
            [126.901364098426, 37.698308491487],
            [126.9012342683, 37.698038739449],
            [126.901125052819, 37.697828072274],
            [126.901037245827, 37.697676076254],
            [126.900978162491, 37.69757289208],
            [126.900843257957, 37.69742693728],
            [126.900805007573, 37.697430382355],
            [126.900772276489, 37.697460951273],
            [126.900734311345, 37.697496579148],
            [126.900636222486, 37.69758636684],
            [126.900524145326, 37.697689891241],
            [126.900429878465, 37.697770357012],
            [126.900271204293, 37.697902645162],
            [126.900131036442, 37.698008604705],
            [126.899952903693, 37.698149200634],
            [126.899807688282, 37.698338765265],
            [126.89977527709, 37.698383578479],
            [126.899650204539, 37.698544059023],
            [126.899475508122, 37.698682602978],
            [126.89942641251, 37.698722374254],
            [126.899311088293, 37.698818155355],
            [126.899189546314, 37.698924868696],
            [126.899088476978, 37.698977739837],
            [126.898943723483, 37.698969180952],
            [126.898923246603, 37.698968172073],
            [126.898586925338, 37.69878382927],
            [126.898475038036, 37.698722240495],
            [126.89821183302, 37.698611119077],
            [126.898113658017, 37.698522927378],
            [126.898034467718, 37.698456988145],
            [126.897934838401, 37.698371200603],
            [126.897872916672, 37.698346648877],
            [126.89779344138, 37.698330920173],
            [126.897643083249, 37.698341923785],
            [126.89756270198, 37.698349781469],
            [126.897388947075, 37.698363998603],
            [126.897139371813, 37.698335541619],
            [126.896841699489, 37.698305347602],
            [126.896560607962, 37.69831319745],
            [126.896388080394, 37.698325405043],
            [126.896026237718, 37.698393897825],
            [126.895804648866, 37.698452217249],
            [126.895601344806, 37.698473910318],
            [126.895432012744, 37.69847128945],
            [126.895428182284, 37.698485728519],
            [126.895427657053, 37.698854313999],
            [126.895544674203, 37.699173902404],
            [126.895575267832, 37.699428273094],
            [126.895459512709, 37.699686972019],
            [126.895441152903, 37.699727994583],
            [126.895373118197, 37.699767684056],
            [126.89509423547, 37.699876314636],
            [126.894959821567, 37.700058188716],
            [126.894831535819, 37.700244041449],
            [126.894744357824, 37.700601286288],
            [126.894609260288, 37.700562845732],
            [126.894175470661, 37.700433415072],
            [126.894065548425, 37.700378157723],
            [126.893965091924, 37.700324755887],
            [126.89371911529, 37.700148887643],
            [126.893610803824, 37.700078089648],
            [126.893487720677, 37.700098951478],
            [126.893342552063, 37.700117504463],
            [126.893253546801, 37.700128000139],
            [126.892987819668, 37.700263252388],
            [126.892783771275, 37.700363621402],
            [126.892438468174, 37.700604077061],
            [126.892166751947, 37.700807966621],
            [126.891820077654, 37.700921959381],
            [126.891188033743, 37.700747149622],
            [126.890474059771, 37.700599738857],
            [126.890264412315, 37.700589297245],
            [126.889777644649, 37.700563736033],
            [126.889758082254, 37.700557626953],
            [126.889502891305, 37.700829504031],
            [126.889582806595, 37.701013233127],
            [126.889656500974, 37.701156565892],
            [126.889745561413, 37.701329491934],
            [126.889820282041, 37.701628143328],
            [126.889819753869, 37.701640071636],
            [126.890101980906, 37.70196581309],
            [126.89032595833, 37.702140352668],
            [126.890352737489, 37.702439670233],
            [126.890189767952, 37.702566931268],
            [126.88970632929, 37.702807770885],
            [126.889392774111, 37.703284562277],
            [126.889386260416, 37.703310278694],
            [126.889503081424, 37.703830481972],
            [126.889658513248, 37.704231920221],
            [126.889390586272, 37.704736964037],
            [126.889349347323, 37.705185633333],
            [126.889267409758, 37.705610901775],
            [126.889302405778, 37.706031659511],
            [126.888835798063, 37.70651643912],
            [126.889230895185, 37.706835920372],
            [126.889204127252, 37.707094751574],
            [126.889540986993, 37.707186925495],
            [126.889855570382, 37.707357929946],
            [126.88999101962, 37.70731960489],
            [126.890100345784, 37.707308184884],
            [126.8905197439, 37.707422120234],
            [126.890756895044, 37.707656567493],
            [126.891018520467, 37.707722808754],
            [126.891049319231, 37.707731549958],
            [126.891219152816, 37.707769513501],
            [126.891294263049, 37.707782782897],
            [126.891395315294, 37.707914238221],
            [126.891742236155, 37.708063104047],
            [126.891903574332, 37.708125898336],
            [126.892087192075, 37.708191848446],
            [126.892429558772, 37.708308390355],
            [126.892642297703, 37.708305947153],
            [126.892806814214, 37.708344272893],
            [126.892827369326, 37.708347778583],
            [126.892934046701, 37.708380392746],
            [126.892879057166, 37.708434553238],
            [126.89292042078, 37.708454475669],
            [126.893147929797, 37.708558043847],
            [126.893181940997, 37.708744259789],
            [126.893269437689, 37.708865799413],
            [126.893404378428, 37.709053288164],
            [126.893412923346, 37.709057323318],
            [126.893697014148, 37.709191412969],
            [126.893846680836, 37.709125184562],
            [126.893863419686, 37.709125064701],
            [126.894097037709, 37.709123439798],
            [126.894295691231, 37.709122872811],
            [126.894083604687, 37.709590938924],
            [126.893709670002, 37.710021385562],
            [126.89350926499, 37.710414446815],
            [126.893644438767, 37.710686049643],
            [126.893758939016, 37.710793288209],
            [126.894113830797, 37.711175909895],
            [126.894678267533, 37.711933405217],
            [126.894596317023, 37.712565169212],
            [126.894522101822, 37.713100879145],
            [126.894491170467, 37.713442695442],
            [126.894636147901, 37.713330377439],
            [126.895029178912, 37.71303614434],
            [126.895213904579, 37.712900625606],
            [126.896410402223, 37.712510192665],
            [126.896850122919, 37.712507187195],
            [126.897251339581, 37.712496821191],
            [126.897763645379, 37.711898401156],
            [126.898012632228, 37.711477715766],
            [126.899096014843, 37.711077371611],
            [126.899529901383, 37.710922781565],
            [126.899618516552, 37.710891234275],
            [126.899685346672, 37.710867416414],
            [126.899722586061, 37.710853690819],
            [126.899833194434, 37.710811278684],
            [126.899903673287, 37.710789563096],
            [126.900118150113, 37.710873366397],
            [126.900227052752, 37.710909183305],
            [126.900243908787, 37.710914909909],
            [126.900491045462, 37.710975531695],
            [126.900565057265, 37.711091522382],
            [126.900648042299, 37.711222503762],
            [126.900686679575, 37.711415839055],
            [126.900725317052, 37.71160917433],
            [126.900717802194, 37.711723293257],
            [126.900704107076, 37.711795115558],
            [126.900690470663, 37.711848702357],
            [126.900584131888, 37.712145560708],
            [126.900477780933, 37.712442409927],
            [126.900267573811, 37.712826059029],
            [126.900143937739, 37.713113793313],
            [126.900097544422, 37.713543955498],
            [126.900143991706, 37.71391495901],
            [126.90014466374, 37.714062672888],
            [126.900145324448, 37.714210377743],
            [126.900148262683, 37.71433464143],
            [126.900149806603, 37.714400142948],
            [126.900151189598, 37.714458896096],
            [126.900145585343, 37.714460080552],
            [126.900096240202, 37.714434234464],
            [126.900052510679, 37.714407212923],
            [126.899993575385, 37.714369700045],
            [126.900002083061, 37.714576307761],
            [126.900010590783, 37.714782915469],
            [126.900029225779, 37.715181275337],
            [126.900149137614, 37.715041035119],
            [126.900141165031, 37.715165550688],
            [126.900133192432, 37.715290057244],
            [126.900091849798, 37.715342061444],
            [126.900050495776, 37.715394056609],
            [126.900020579917, 37.715566394533],
            [126.900092911326, 37.715936131836],
            [126.900159517625, 37.716121454595],
            [126.900253589224, 37.716168448688],
            [126.900250918387, 37.716239190182],
            [126.900248247556, 37.716309922664],
            [126.90035553275, 37.716448484104],
            [126.900462818355, 37.716587036434],
            [126.900581575398, 37.71682255134],
            [126.900700333204, 37.717058057109],
            [126.900744411422, 37.717163904129],
            [126.900788478435, 37.717269742112],
            [126.90081030427, 37.717266643418],
            [126.90088086391, 37.71725659484],
            [126.900935940247, 37.71726459736],
            [126.900973758838, 37.717319642543],
            [126.900940982675, 37.717419072116],
            [126.900938686483, 37.717548719022],
            [126.900937100223, 37.7176649872],
            [126.901031008663, 37.717733819914],
            [126.901135189044, 37.717780389444],
            [126.901387564054, 37.718163063668],
            [126.901515391628, 37.71824457434],
            [126.901556200536, 37.718261051556],
            [126.901598585121, 37.718278178789],
            [126.90159793783, 37.718388555448],
            [126.901597119422, 37.718432107396],
            [126.901628694048, 37.718534564846],
            [126.901660617666, 37.718639031734],
            [126.901648106447, 37.718732694449],
            [126.901635595197, 37.718826357162],
            [126.901838708948, 37.719029228122],
            [126.901922188547, 37.719112520787],
            [126.901972500964, 37.719113338108],
            [126.902033363212, 37.719155302361],
            [126.902089636668, 37.719176783784],
            [126.902170437972, 37.719202123961],
            [126.902340031518, 37.719222835773],
            [126.902607718971, 37.719238097755],
            [126.902712330655, 37.719244068772],
            [126.902639248855, 37.719264585537],
            [126.903190472312, 37.719438662454],
            [126.903492387913, 37.719533965645],
            [126.903914990775, 37.719668064469],
            [126.904583278837, 37.719880207382],
            [126.904812795291, 37.719656776433],
            [126.904681621589, 37.719483638491],
            [126.904550448498, 37.7193105004],
            [126.904483788614, 37.719199176408],
            [126.904416314052, 37.719103888878],
            [126.904344598406, 37.718955981468],
            [126.904260162426, 37.718734724981],
            [126.904347855564, 37.718633168454],
            [126.904342539443, 37.718535139095],
            [126.904222342426, 37.71825339834],
            [126.90419415846, 37.718122735164],
            [126.904113648234, 37.717936078836],
            [126.90407565945, 37.717757836573],
            [126.904085195797, 37.717664234192],
            [126.904103818942, 37.717387202836],
            [126.904018074567, 37.717214236764],
            [126.903928186727, 37.717001174249],
            [126.903902124213, 37.716983223468],
            [126.903904331631, 37.716943222445],
            [126.903848422639, 37.716679553745],
            [126.903824598029, 37.716512134679],
            [126.903826539075, 37.716329510643],
            [126.903893480985, 37.716136759534],
            [126.903795813407, 37.715976306809],
            [126.903707487034, 37.715871181017],
            [126.903711998724, 37.715803882653],
            [126.903813294092, 37.715619719236],
            [126.903825102226, 37.715523505924],
            [126.903814162936, 37.715476286276],
            [126.903625934025, 37.715247176827],
            [126.903594168115, 37.714935704731],
            [126.903609870488, 37.714724441377],
            [126.903588662003, 37.714638291466],
            [126.903571537326, 37.714637646565],
            [126.903569325739, 37.714594578579],
            [126.903626657835, 37.714550478885],
            [126.904083633205, 37.714353906355],
            [126.904534778938, 37.714364459226],
            [126.905132883565, 37.714394591456],
            [126.905425237039, 37.714415552297],
            [126.905832730681, 37.714417054962],
            [126.906220055641, 37.714403764084],
            [126.906527996245, 37.714386263561],
            [126.906917851993, 37.714344682112],
            [126.906939970606, 37.714342357346],
            [126.90730205329, 37.714289940757],
            [126.907499663783, 37.714255050942],
            [126.907766707685, 37.714200665073],
            [126.907915759687, 37.714170366901],
            [126.907945492939, 37.714164317994],
            [126.90793251818, 37.714110429955],
            [126.907907737371, 37.714065362004],
            [126.907969902813, 37.714053428502],
            [126.908097523398, 37.71402894234],
            [126.908160231111, 37.714036722295],
            [126.908207529493, 37.714041706038],
            [126.908239954987, 37.714041173097],
            [126.908297445321, 37.714040218494],
            [126.908387349805, 37.714038730873],
            [126.908414626953, 37.714028643581],
            [126.908588229251, 37.713964451597],
            [126.908615938681, 37.713935101955],
            [126.908666928306, 37.713881264378],
            [126.908665833753, 37.713849549526],
            [126.908630101605, 37.713764380129],
            [126.908608140502, 37.713731567676],
            [126.908625830411, 37.713724463986],
            [126.908606432221, 37.713700915479],
            [126.908535135801, 37.713614339542],
            [126.908501696936, 37.713572859736],
            [126.908436097623, 37.713438104588],
            [126.908346099998, 37.71325189399],
            [126.908331906132, 37.713220484118],
            [126.908196034238, 37.712943672056],
            [126.907933323721, 37.712478033909],
            [126.907944935388, 37.712461267141],
            [126.907904730625, 37.7123896625],
            [126.907878777206, 37.712375658901],
            [126.907844493461, 37.712303356188],
            [126.907824116558, 37.712210927929],
            [126.907580612843, 37.711797893583],
            [126.907624954271, 37.71178133311],
            [126.90762713568, 37.711778208495],
            [126.907588895602, 37.711713984215],
            [126.907573178417, 37.711676951002],
            [126.907554385797, 37.711686450227],
            [126.9075207383, 37.711703514731],
            [126.907160719336, 37.711202776604],
            [126.907153950084, 37.711192842541],
            [126.907097520511, 37.711154992831],
            [126.907059901875, 37.711128933791],
            [126.906980562751, 37.711074091469],
            [126.906911928526, 37.711002184376],
            [126.906882877522, 37.710972077806],
            [126.906715045816, 37.710801741481],
            [126.906354138634, 37.710409043965],
            [126.906145953122, 37.710190247307],
            [126.906134774071, 37.710179129351],
            [126.906108576341, 37.710153097262],
            [126.906089140368, 37.710133827886],
            [126.905919008403, 37.709964894052],
            [126.905748480785, 37.70979557223],
            [126.905727150668, 37.709776643622],
            [126.905660101389, 37.709717539804],
            [126.90563878744, 37.709659689451],
            [126.90555634233, 37.709544262662],
            [126.9055530593, 37.709531006776],
            [126.90551070477, 37.709458570647],
            [126.905390074853, 37.709274008524],
            [126.905389555084, 37.709272539525],
            [126.905308027468, 37.709123498249],
            [126.905138417318, 37.708791218486],
            [126.905050318085, 37.708795921562],
            [126.905171521163, 37.709053336653],
            [126.905027328661, 37.708797614587],
            [126.904940819562, 37.708826626955],
            [126.904916427451, 37.708781901022],
            [126.904945537588, 37.708773906268],
            [126.904984029644, 37.708763351433],
            [126.905004361601, 37.708755826989],
            [126.905020597356, 37.708750056073],
            [126.905076711654, 37.70873143396],
            [126.905081326146, 37.70872352725],
            [126.905134547344, 37.70863580763],
            [126.905110783577, 37.708604984161],
            [126.905021683696, 37.70848947946],
            [126.904862142003, 37.708300416123],
            [126.904828870086, 37.70822986119],
            [126.904824459449, 37.70822052356],
            [126.904826653923, 37.70820735323],
            [126.904830128612, 37.708186417619],
            [126.904765649832, 37.708044633691],
            [126.904756724443, 37.707992676639],
            [126.904767743106, 37.707969738064],
            [126.904743342768, 37.70792291283],
            [126.904717667855, 37.707870815891],
            [126.904722510719, 37.707826816662],
            [126.904722912366, 37.707823177087],
            [126.904726720646, 37.707798619866],
            [126.90474140213, 37.707749592308],
            [126.90473028286, 37.707631907942],
            [126.90472908149, 37.707622645019],
            [126.904701745319, 37.707470305169],
            [126.904674145928, 37.707328605509],
            [126.904649244873, 37.707196197021],
            [126.904600260875, 37.707052263388],
            [126.904598521154, 37.707047135458],
            [126.904550121602, 37.706950899456],
            [126.904518391134, 37.706925168779],
            [126.904505137051, 37.706914436371],
            [126.904498452546, 37.706909655748],
            [126.904390778487, 37.706899872702],
            [126.904228360351, 37.706885107102],
            [126.904204321924, 37.706882924946],
            [126.904193980887, 37.706899314004],
            [126.904181700737, 37.706993689134],
            [126.904181036358, 37.706998770037],
            [126.904173979812, 37.707053020423],
            [126.904155234526, 37.707052905839],
            [126.904147160334, 37.707052854124],
            [126.904131431527, 37.707052760047],
            [126.904001960883, 37.707051986326],
            [126.90394508365, 37.707039163567],
            [126.903906945366, 37.707195584855],
            [126.903899218187, 37.70723356321],
            [126.903893282946, 37.707262758624],
            [126.90383691088, 37.70721054597],
            [126.9038122244, 37.707218192743],
            [126.903812948737, 37.707210652252],
            [126.903814386152, 37.707195508195],
            [126.903828382942, 37.707063708375],
            [126.903879797323, 37.706944462978],
            [126.903883437186, 37.706936087002],
            [126.903932237225, 37.706838651817],
            [126.903974851375, 37.706753951162],
            [126.903979297033, 37.706745071308],
            [126.903997591226, 37.706708588279],
            [126.904026573555, 37.706650445836],
            [126.904191631146, 37.706354740761],
            [126.904194575813, 37.706253555616],
            [126.904113743416, 37.705984892653],
            [126.903939397125, 37.705563915905],
            [126.903770694414, 37.705224985595],
            [126.903661351452, 37.705028835489],
            [126.903497336712, 37.704714126642],
            [126.903498593341, 37.704483218852],
            [126.903521349166, 37.704382347477],
            [126.903553527345, 37.704308071463],
            [126.903619779326, 37.704226922326],
            [126.903688881537, 37.704142667179],
            [126.903911651544, 37.70392688097],
            [126.904015523397, 37.703762774656],
            [126.90402382628, 37.703631240318],
            [126.904027479228, 37.703586762589],
            [126.904026627749, 37.70353557794],
            [126.904056304641, 37.703379032395],
            [126.904010024787, 37.703285771057],
            [126.903988186007, 37.703241398379],
            [126.903893466847, 37.703093561371],
            [126.903808358232, 37.702967445559],
            [126.903724857169, 37.702843583434],
            [126.9036467873, 37.702728915622],
            [126.903576909392, 37.702602172589],
            [126.903512194292, 37.702369741324],
            [126.903380046039, 37.70211823449],
            [126.903304746551, 37.701976071219],
            [126.903274928719, 37.701834810726],
            [126.903247553692, 37.701655621529],
            [126.903258305558, 37.701490816509],
            [126.903274176084, 37.701262344374],
            [126.903322165563, 37.701055071717],
            [126.903366629991, 37.700837876445],
            [126.903364769746, 37.700735128552],
            [126.903289620145, 37.70065232111],
            [126.903152296189, 37.700553361479],
            [126.902750795639, 37.700288384643],
            [126.902570589929, 37.700179585875],
            [126.902491536527, 37.700091684174],
            [126.902401382465, 37.699986591634],
            [126.902303477679, 37.699673540138]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41285111',
      properties: {
        rgnCode: '41285111',
        colCode: '41280',
        rgnKo: ['설문동'],
        rgnSize: 1,
        rgnBbox: [
          126.785250858248, 37.70770322292, 126.825099815662, 37.736995835342
        ],
        rgnCenter: [126.806504965653, 37.720813810555],
        rgnArea: 4988651,
        predVal: [
          0.39496, 0.40163, 0.3843, 0.43159, 0.54071, 0.49164, 0.53939, 0.60558,
          0.55523, 0.59295, 0.56673, 0.39245, 0.20188, 0.34901, 0.38552,
          0.22766, 0.26184, 0.44661, 0.62564, 0.45053, 0.47376, 0.56312,
          0.49407, 0.55951, 0.44788, 0.54579, 0.49886, 0.51365, 0.50219,
          0.52331, 0.37852
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.785250858248, 37.720758982495],
            [126.785296002177, 37.720777985523],
            [126.785383686697, 37.720853196532],
            [126.785458020725, 37.720916940779],
            [126.785464682584, 37.720927314064],
            [126.785500926774, 37.72098405106],
            [126.78570993813, 37.721072007447],
            [126.785778815775, 37.721100964324],
            [126.785838999648, 37.721155312419],
            [126.785936576124, 37.721183420737],
            [126.785939409356, 37.721184236784],
            [126.786059876547, 37.721219594559],
            [126.78627225411, 37.721281428169],
            [126.786323482151, 37.721295126227],
            [126.78631640194, 37.721311691025],
            [126.786324674557, 37.721314228822],
            [126.786512075726, 37.721384215173],
            [126.786572929531, 37.721403246359],
            [126.786683141373, 37.72145516252],
            [126.786650231582, 37.721460958808],
            [126.786862017992, 37.721531529619],
            [126.787195469939, 37.721891350235],
            [126.787182189627, 37.722012775911],
            [126.78715873389, 37.722280229184],
            [126.787157446492, 37.722294011577],
            [126.787146305023, 37.7224210271],
            [126.787390491074, 37.722782486698],
            [126.787431437099, 37.722822203426],
            [126.787443539693, 37.722834028016],
            [126.78750326211, 37.722891978261],
            [126.788036617029, 37.723160441116],
            [126.787991573848, 37.72316486436],
            [126.788003804754, 37.723171553636],
            [126.788091831291, 37.723208562402],
            [126.788309166652, 37.723244904057],
            [126.788428954277, 37.723241877158],
            [126.788513230128, 37.723242029449],
            [126.788762195516, 37.723244461111],
            [126.78878884996, 37.723244779497],
            [126.788933843739, 37.723312252859],
            [126.789162768366, 37.723421411635],
            [126.789339520286, 37.723489662466],
            [126.789504786444, 37.723528611085],
            [126.78967290718, 37.723560987573],
            [126.789926203131, 37.723556667338],
            [126.790111901723, 37.72355042344],
            [126.790384720858, 37.723541272027],
            [126.790408883349, 37.723540414333],
            [126.790574441435, 37.723532656062],
            [126.790810853399, 37.723666538444],
            [126.790820841694, 37.723672196323],
            [126.790828746275, 37.723676670215],
            [126.790910280903, 37.723723756058],
            [126.791489048745, 37.724104454261],
            [126.791643474847, 37.724327897742],
            [126.791784652216, 37.724423020354],
            [126.791810351664, 37.724440364554],
            [126.791823438956, 37.724449262315],
            [126.7920459461, 37.724600524073],
            [126.792232511381, 37.724686221624],
            [126.792338555524, 37.724734926664],
            [126.7924309941, 37.724777525983],
            [126.79254069038, 37.725137294675],
            [126.792686743986, 37.725678221127],
            [126.793178500312, 37.725868563593],
            [126.793672227953, 37.726085017373],
            [126.793818148924, 37.726148990608],
            [126.79401569847, 37.726694781147],
            [126.794335630374, 37.727164474933],
            [126.794599998952, 37.727587941041],
            [126.795036845756, 37.728114148145],
            [126.795142771086, 37.728531028628],
            [126.795153094566, 37.728571589996],
            [126.795232344492, 37.728664167409],
            [126.795286163182, 37.728744185919],
            [126.795294234042, 37.72880761876],
            [126.795308376364, 37.729029649567],
            [126.795341206193, 37.729082305153],
            [126.795397865229, 37.729173149128],
            [126.795419406353, 37.729206035865],
            [126.795491920808, 37.729318494561],
            [126.795591821012, 37.729471210985],
            [126.795630792057, 37.729530652447],
            [126.795710594591, 37.730002084995],
            [126.795713372405, 37.730064076024],
            [126.795734478901, 37.730143487614],
            [126.795781940382, 37.730184780449],
            [126.795762477205, 37.730273995766],
            [126.795711611282, 37.730434007903],
            [126.795498098808, 37.731133988618],
            [126.795501737936, 37.731253552601],
            [126.795429835743, 37.731492542166],
            [126.795373105998, 37.731741648978],
            [126.795317769358, 37.731938232127],
            [126.795299613506, 37.731981086142],
            [126.795199193776, 37.732165904883],
            [126.79505494452, 37.732447139761],
            [126.795016152549, 37.732522995845],
            [126.7955985464, 37.732888282331],
            [126.795668516877, 37.732925307914],
            [126.795720632983, 37.732953662067],
            [126.795633236784, 37.733207895932],
            [126.79561857543, 37.733250530849],
            [126.795473239272, 37.733305631986],
            [126.795327902899, 37.733360732943],
            [126.795185139591, 37.733469652647],
            [126.794796058642, 37.733733213964],
            [126.794292903043, 37.734071659625],
            [126.794272766608, 37.734104293082],
            [126.7942526415, 37.734136926556],
            [126.794233210856, 37.734160533615],
            [126.794004911459, 37.734422581972],
            [126.793939921163, 37.734580406052],
            [126.794000025361, 37.734709979995],
            [126.794064398269, 37.734773430867],
            [126.794093248845, 37.734801321335],
            [126.794330278358, 37.735029951624],
            [126.794583375363, 37.735274106166],
            [126.794682290405, 37.735318246663],
            [126.79480155505, 37.735347016328],
            [126.795244718456, 37.735453385248],
            [126.795479954805, 37.7355123591],
            [126.795514149102, 37.735515824493],
            [126.795705894751, 37.735569244043],
            [126.79603467121, 37.735770731909],
            [126.79603087646, 37.735789194996],
            [126.796084387811, 37.735884069362],
            [126.796265970245, 37.73590042277],
            [126.796325909232, 37.73592728566],
            [126.796578808512, 37.73599971238],
            [126.796599895805, 37.736004704324],
            [126.796617581707, 37.736008969583],
            [126.796654159717, 37.736032638335],
            [126.79673613131, 37.736050890078],
            [126.796933808023, 37.736072946468],
            [126.797034967485, 37.736085645343],
            [126.79723769047, 37.736087888836],
            [126.797285222904, 37.736088511772],
            [126.797500571523, 37.736120057918],
            [126.797586441386, 37.73616480407],
            [126.797617045251, 37.736257295608],
            [126.797618507947, 37.736261712847],
            [126.797684825982, 37.736320750386],
            [126.797939875033, 37.736396241169],
            [126.797998165119, 37.736487068589],
            [126.798033410081, 37.736542088052],
            [126.798061425805, 37.736585652822],
            [126.798271913421, 37.736860538716],
            [126.798517271997, 37.736995835342],
            [126.798766252563, 37.736593714497],
            [126.799497848299, 37.735893752567],
            [126.799497621407, 37.735893752178],
            [126.800521019357, 37.734971115412],
            [126.800759167824, 37.734792860607],
            [126.800804751106, 37.734758791795],
            [126.800852380716, 37.734723104718],
            [126.80112372044, 37.734519949033],
            [126.801272479669, 37.734128283633],
            [126.801290414219, 37.734082274969],
            [126.801306177803, 37.734042028769],
            [126.80133976193, 37.733955953892],
            [126.801367078608, 37.733795989528],
            [126.801384713233, 37.733692769287],
            [126.801421343883, 37.733486241006],
            [126.801358489031, 37.73306763768],
            [126.801350575352, 37.733015368474],
            [126.801345228633, 37.732978780356],
            [126.801339324171, 37.732938677541],
            [126.801330423673, 37.7329158681],
            [126.801190828182, 37.732559931329],
            [126.801165928187, 37.732496641508],
            [126.801085934886, 37.732292617911],
            [126.801116641229, 37.731843000135],
            [126.801119171328, 37.731788135867],
            [126.801121338039, 37.731741830116],
            [126.801123266699, 37.731699668385],
            [126.801128084941, 37.731595525397],
            [126.801083795719, 37.731529049222],
            [126.801040071827, 37.731463294761],
            [126.801003804618, 37.731408814988],
            [126.800571219352, 37.730752088105],
            [126.800862270505, 37.730133352716],
            [126.801227615351, 37.729894588386],
            [126.801389571505, 37.729485015792],
            [126.801721816638, 37.729320072598],
            [126.801821762541, 37.729316908381],
            [126.801880414416, 37.729314935504],
            [126.801897478155, 37.729296945157],
            [126.802273049541, 37.729302986276],
            [126.802408273142, 37.729257806246],
            [126.802586222207, 37.729142693406],
            [126.802630313026, 37.72911366672],
            [126.802781104374, 37.729015716139],
            [126.803293208927, 37.728739712632],
            [126.803392580909, 37.728696183008],
            [126.803402125999, 37.728689982409],
            [126.803560215341, 37.728491765987],
            [126.803587984011, 37.728457756237],
            [126.803610517649, 37.728430134525],
            [126.80365524529, 37.728374620229],
            [126.803826184386, 37.728163991723],
            [126.803876912139, 37.727984785271],
            [126.803960010571, 37.727704274772],
            [126.803978293431, 37.727568620469],
            [126.803985110898, 37.727521601653],
            [126.804022302892, 37.727270655826],
            [126.804227929856, 37.7272810008],
            [126.804303558184, 37.727292929946],
            [126.804312402115, 37.72729438628],
            [126.804360984513, 37.727324739876],
            [126.804577517814, 37.727286000024],
            [126.804766586122, 37.72725252962],
            [126.804882756433, 37.72724695728],
            [126.805017467793, 37.727264840755],
            [126.805153646198, 37.727328585511],
            [126.805349594706, 37.727395943332],
            [126.805507010541, 37.727450262909],
            [126.805624787207, 37.727523977206],
            [126.805662277784, 37.727502146108],
            [126.805718854501, 37.727469174763],
            [126.80577191356, 37.727436648032],
            [126.805782124898, 37.727435764024],
            [126.80579422429, 37.727450199511],
            [126.806233269699, 37.72738497714],
            [126.806235371342, 37.727362186271],
            [126.806246995775, 37.727298327285],
            [126.806331338092, 37.727231705633],
            [126.806390362077, 37.727216937459],
            [126.806469756834, 37.727090393511],
            [126.806470904906, 37.727076502568],
            [126.80700546677, 37.726873452885],
            [126.807840873078, 37.726970114072],
            [126.80820335103, 37.727047290821],
            [126.808508983626, 37.726942748359],
            [126.808915706425, 37.726785654976],
            [126.809184350739, 37.726683924394],
            [126.809549173149, 37.726454863629],
            [126.810000125138, 37.726211346077],
            [126.810184897072, 37.726126324924],
            [126.810082051713, 37.72571531895],
            [126.810082611068, 37.725542064711],
            [126.81049525617, 37.725150544907],
            [126.810671578877, 37.724953249303],
            [126.810946503296, 37.724698991803],
            [126.811156780335, 37.72457418776],
            [126.81138826088, 37.724452120425],
            [126.811587385093, 37.724385589929],
            [126.811768659344, 37.724335247702],
            [126.811907622105, 37.724285828214],
            [126.812005423157, 37.724275804583],
            [126.812388406411, 37.724212271023],
            [126.812563679003, 37.72415759332],
            [126.812766388981, 37.724062956526],
            [126.812923900983, 37.723989239528],
            [126.813015657239, 37.723947311295],
            [126.813263045709, 37.723812201729],
            [126.813596766167, 37.723626505055],
            [126.81365372404, 37.723574790447],
            [126.813734296338, 37.723469596214],
            [126.813761635649, 37.723468288311],
            [126.813800545874, 37.723466458249],
            [126.814301894846, 37.723421486512],
            [126.814328552889, 37.723420447685],
            [126.814719882093, 37.72373469365],
            [126.814943904664, 37.724050205291],
            [126.815302293878, 37.724430347659],
            [126.815648904992, 37.724804523992],
            [126.815911205518, 37.724965669033],
            [126.816222555252, 37.725017153298],
            [126.816382268536, 37.725425630525],
            [126.817169123983, 37.725567503852],
            [126.81756194256, 37.725610012216],
            [126.817881730035, 37.725645287932],
            [126.818194145109, 37.725634422015],
            [126.818396749818, 37.725627348993],
            [126.818702813538, 37.725616111477],
            [126.818865031225, 37.72561131752],
            [126.819115659013, 37.725587109262],
            [126.8191047482, 37.725549612323],
            [126.819272668756, 37.725578252353],
            [126.819458854803, 37.725605659004],
            [126.819488133262, 37.72560020834],
            [126.819462834927, 37.725555211226],
            [126.819429295706, 37.725494074141],
            [126.819527986944, 37.725491343448],
            [126.819745607548, 37.725512851812],
            [126.819923943024, 37.725503576432],
            [126.820569803143, 37.725509074305],
            [126.820866375361, 37.725482410137],
            [126.821145288332, 37.725440852316],
            [126.821468974008, 37.725459366356],
            [126.821489765672, 37.725679683552],
            [126.821538305129, 37.72582400183],
            [126.821612059979, 37.725954483737],
            [126.821664061452, 37.726168361574],
            [126.82172108478, 37.72627575323],
            [126.821758573472, 37.726346355789],
            [126.821804987623, 37.726431747732],
            [126.821895777435, 37.726695237526],
            [126.821900812898, 37.726723805718],
            [126.821977802929, 37.726971417467],
            [126.822052147828, 37.727187221144],
            [126.822064425253, 37.727223188246],
            [126.822073211272, 37.72724878896],
            [126.822086675617, 37.727309984822],
            [126.822094339931, 37.727330358244],
            [126.822113274289, 37.727428141511],
            [126.822193914916, 37.727620709827],
            [126.822209129025, 37.727756057558],
            [126.822336019916, 37.728008790308],
            [126.822546495684, 37.728315887779],
            [126.822540478964, 37.728412101445],
            [126.822604270091, 37.728489500858],
            [126.822653708869, 37.728638865367],
            [126.822755857241, 37.728851287065],
            [126.822773590033, 37.729024749227],
            [126.822797215344, 37.729059201811],
            [126.822849402639, 37.729103608267],
            [126.822895067207, 37.729124489697],
            [126.822980050508, 37.729163540007],
            [126.822982470961, 37.729053085678],
            [126.823139597846, 37.728856373135],
            [126.823222285554, 37.728717299514],
            [126.823326574242, 37.72860528736],
            [126.823580319122, 37.728324930692],
            [126.823617594152, 37.728296336337],
            [126.823770897727, 37.728179172151],
            [126.823792690907, 37.728125958118],
            [126.823814004055, 37.7280837351],
            [126.823826883455, 37.728058077079],
            [126.823847018087, 37.727891879821],
            [126.823817877029, 37.727839580066],
            [126.82375780034, 37.72772650878],
            [126.823744224953, 37.727521339108],
            [126.82381783969, 37.727427930163],
            [126.823844511089, 37.727326882345],
            [126.823992551617, 37.727132587506],
            [126.824039296218, 37.726985710859],
            [126.824053353528, 37.72694167494],
            [126.824127113797, 37.72688196202],
            [126.824323262752, 37.726729093321],
            [126.824381286591, 37.726703953558],
            [126.824493927978, 37.726655020276],
            [126.824629843795, 37.726501880408],
            [126.824772805847, 37.726438306652],
            [126.825011724788, 37.726233965828],
            [126.82507549117, 37.726178652061],
            [126.825099815662, 37.726157515834],
            [126.825086600213, 37.726133890829],
            [126.82479743956, 37.725618737597],
            [126.824614351806, 37.725289341218],
            [126.824587446996, 37.725226999249],
            [126.824633894605, 37.724884432685],
            [126.824731884261, 37.724221984326],
            [126.824777428058, 37.723944177529],
            [126.824812774092, 37.723867017961],
            [126.824852290131, 37.723825002197],
            [126.824834992423, 37.723801100748],
            [126.824823018328, 37.723780360647],
            [126.824808333324, 37.723754841377],
            [126.824797286452, 37.723544350781],
            [126.824765312438, 37.723481785004],
            [126.824747687127, 37.723309404292],
            [126.82475683021, 37.72328040698],
            [126.824712184117, 37.723166368168],
            [126.824720955652, 37.723150524367],
            [126.824713724336, 37.72304329888],
            [126.824711972902, 37.723016627737],
            [126.824769723791, 37.722770931509],
            [126.824802489799, 37.72268142486],
            [126.824811175227, 37.72265404859],
            [126.824840001419, 37.722599583502],
            [126.824899791385, 37.722450203246],
            [126.824776509957, 37.722301719906],
            [126.824676766082, 37.722209951357],
            [126.824634584683, 37.722171137638],
            [126.824515844026, 37.722021489579],
            [126.824467891938, 37.721914923685],
            [126.824419139751, 37.721858630336],
            [126.824308615614, 37.721736023311],
            [126.824231316812, 37.721664550784],
            [126.824194122368, 37.721518088261],
            [126.824189457996, 37.721476366629],
            [126.824136765265, 37.72136042344],
            [126.82408872834, 37.721099612272],
            [126.824114080507, 37.72088477058],
            [126.824159773228, 37.720701583302],
            [126.824193580809, 37.72046053625],
            [126.82421379895, 37.720400832758],
            [126.824228758303, 37.720263278081],
            [126.824217668416, 37.720247809875],
            [126.824263490577, 37.720105229171],
            [126.824282243042, 37.720070326994],
            [126.824334269696, 37.720056097887],
            [126.824468943787, 37.720019432762],
            [126.824530963068, 37.719981279963],
            [126.824580737139, 37.71994126178],
            [126.82461458941, 37.719871487878],
            [126.824635935326, 37.719790433155],
            [126.824641371848, 37.719698272704],
            [126.824642934355, 37.719627855708],
            [126.824653162659, 37.719485707954],
            [126.824670203213, 37.719307207508],
            [126.824635933521, 37.719185354779],
            [126.824616717497, 37.719119636664],
            [126.824521660375, 37.718923813626],
            [126.824473997109, 37.718825392829],
            [126.82446197171, 37.718755099575],
            [126.824506097274, 37.718729127971],
            [126.824526091634, 37.718715508382],
            [126.824549652607, 37.718604328745],
            [126.824564971871, 37.718520084567],
            [126.824575809568, 37.718460294836],
            [126.824614473987, 37.718393681512],
            [126.824570924158, 37.718368434214],
            [126.824548496076, 37.718356237536],
            [126.824541112252, 37.718361091659],
            [126.824482864931, 37.718387582653],
            [126.824415486032, 37.718433655872],
            [126.824397355409, 37.718445764622],
            [126.824362012172, 37.718460739603],
            [126.8243549939, 37.718454962887],
            [126.824335020714, 37.718459707983],
            [126.824293824361, 37.718470187345],
            [126.82421752981, 37.718454666152],
            [126.824068840597, 37.718500481725],
            [126.823846193894, 37.718501948346],
            [126.823653823933, 37.718506073021],
            [126.823492777238, 37.718502090958],
            [126.823373259448, 37.71849091867],
            [126.823346352804, 37.718473417328],
            [126.823208386773, 37.718379670664],
            [126.823158024674, 37.718262964684],
            [126.823086545735, 37.718178661434],
            [126.823084577662, 37.718148025675],
            [126.823092527746, 37.718143550905],
            [126.823095536956, 37.718142050849],
            [126.823082747833, 37.718073936625],
            [126.823064467824, 37.718001687673],
            [126.823033515451, 37.717911985877],
            [126.822958888761, 37.717754789777],
            [126.822774088477, 37.717299838166],
            [126.822657280668, 37.716964277072],
            [126.822628629559, 37.716899003789],
            [126.822550456412, 37.71669869088],
            [126.822503736945, 37.716671636073],
            [126.822482510268, 37.716537585394],
            [126.822443347202, 37.716410444894],
            [126.822348535593, 37.716422608028],
            [126.822329781871, 37.716425075199],
            [126.822279636419, 37.716431413844],
            [126.82218626484, 37.716443705172],
            [126.822074854167, 37.716458401576],
            [126.821950463628, 37.71644695014],
            [126.821943081791, 37.716446290203],
            [126.821809176384, 37.716434526808],
            [126.821533918477, 37.716409240322],
            [126.821076027324, 37.716374033512],
            [126.820983506441, 37.716292760038],
            [126.820937675048, 37.716250614815],
            [126.820648881735, 37.715985108421],
            [126.82039879185, 37.715755248866],
            [126.820255188825, 37.71576295667],
            [126.819742482066, 37.71579072791],
            [126.819463420472, 37.715722094337],
            [126.819250233723, 37.715244974559],
            [126.819235403242, 37.715238635871],
            [126.818732358654, 37.715023355449],
            [126.818642741991, 37.714985006578],
            [126.818639107334, 37.714982586356],
            [126.818715809458, 37.714761040986],
            [126.818730502005, 37.714661885582],
            [126.818767190019, 37.714463901419],
            [126.818767534593, 37.714443666287],
            [126.818760645363, 37.714427240033],
            [126.818791686003, 37.7143259116],
            [126.818813237514, 37.7140937664],
            [126.818805613751, 37.713780669346],
            [126.818835420938, 37.713696565425],
            [126.818846058884, 37.713521073969],
            [126.818930651337, 37.713383132142],
            [126.819008775853, 37.713255721527],
            [126.819097026423, 37.713110352276],
            [126.819135358273, 37.713018332966],
            [126.819171409226, 37.712931445615],
            [126.819241093253, 37.712818662462],
            [126.819312141525, 37.712704439829],
            [126.819461938076, 37.712666019823],
            [126.819604017275, 37.712629750035],
            [126.819621108772, 37.712583160478],
            [126.81967274856, 37.712442365252],
            [126.819734636166, 37.712270826855],
            [126.819815458168, 37.712054809928],
            [126.819883265936, 37.711873640238],
            [126.81994608733, 37.711816336512],
            [126.820032845947, 37.711737194166],
            [126.820018207119, 37.711647885993],
            [126.81993853386, 37.71116115206],
            [126.819926465388, 37.71108752462],
            [126.819878303822, 37.710793555591],
            [126.819737001588, 37.710703592685],
            [126.819726799392, 37.710697099016],
            [126.818953441708, 37.710209554078],
            [126.818945719278, 37.710204685918],
            [126.818765338739, 37.710138005337],
            [126.818338442169, 37.709966790174],
            [126.817867594226, 37.709775954066],
            [126.817803346777, 37.70975405062],
            [126.817776719924, 37.709744458898],
            [126.817740693831, 37.709316083809],
            [126.817603161689, 37.709120720259],
            [126.817280750413, 37.708891371705],
            [126.817256371955, 37.708889892067],
            [126.816741703989, 37.708858363748],
            [126.81658851705, 37.708848933746],
            [126.816297591374, 37.708683330136],
            [126.816262546707, 37.70868435624],
            [126.816233515168, 37.708684310625],
            [126.815983565023, 37.708686620491],
            [126.815924253146, 37.708687067728],
            [126.815736304357, 37.70865640921],
            [126.815666588771, 37.708645037278],
            [126.815123488367, 37.708556335867],
            [126.814941208195, 37.708526586008],
            [126.814813480598, 37.708449711667],
            [126.814689567719, 37.70837573533],
            [126.814661406248, 37.708358923737],
            [126.814607495611, 37.708326592737],
            [126.814597542366, 37.708320621577],
            [126.814557297326, 37.708270103702],
            [126.814530278701, 37.708236274651],
            [126.814385916532, 37.708055311941],
            [126.814334458211, 37.707999460495],
            [126.814255291191, 37.707913743063],
            [126.814137293968, 37.707891481951],
            [126.814071550956, 37.707879124326],
            [126.814067432133, 37.707893533227],
            [126.813846964855, 37.707852729182],
            [126.813698815579, 37.707825284133],
            [126.813392380034, 37.708012286563],
            [126.813286410592, 37.708076986965],
            [126.81326163544, 37.708142807994],
            [126.813211400478, 37.708276070677],
            [126.813183656628, 37.708349815443],
            [126.813122091126, 37.708389449671],
            [126.81271384914, 37.7086521489],
            [126.812502688218, 37.708607933577],
            [126.811978594835, 37.708449423328],
            [126.81162178223, 37.708199191691],
            [126.811384223177, 37.707879596781],
            [126.810671324646, 37.707719514761],
            [126.810625324646, 37.70770322292],
            [126.810503686292, 37.707774652696],
            [126.810453548045, 37.707780067354],
            [126.810417703202, 37.707783703225],
            [126.810162945927, 37.707936634404],
            [126.810045988102, 37.708039785286],
            [126.809703054768, 37.709579788634],
            [126.809673583239, 37.70975029341],
            [126.809533159948, 37.710683554866],
            [126.809424140727, 37.711311890524],
            [126.809279491373, 37.712254334378],
            [126.809142710466, 37.713244496818],
            [126.809100813009, 37.713241320124],
            [126.808646245469, 37.713202556713],
            [126.808314933253, 37.713172913132],
            [126.808228421541, 37.71316466266],
            [126.808250123874, 37.713236324928],
            [126.808257980229, 37.713442748812],
            [126.808205996967, 37.713458340381],
            [126.808146749481, 37.713476172424],
            [126.807940658422, 37.713440696628],
            [126.807500476228, 37.713365102964],
            [126.80730026219, 37.713294227807],
            [126.807261737823, 37.713280559777],
            [126.807013574212, 37.71315743927],
            [126.806847641284, 37.713075177638],
            [126.806784806593, 37.713033629503],
            [126.80647448472, 37.712827966912],
            [126.80640032899, 37.712778921835],
            [126.806248809086, 37.712779932349],
            [126.805920251639, 37.71278200056],
            [126.805844491682, 37.712782505533],
            [126.805807337531, 37.712782759206],
            [126.805636684767, 37.712783917367],
            [126.805588404796, 37.712784242583],
            [126.805530223957, 37.712784596371],
            [126.805506015597, 37.712795647005],
            [126.805086365033, 37.712987205482],
            [126.804878454864, 37.713083262145],
            [126.804856066007, 37.713092378605],
            [126.804789671409, 37.713119422919],
            [126.804533615552, 37.713194676444],
            [126.804529358217, 37.713196336116],
            [126.804458413969, 37.713223994308],
            [126.804424060108, 37.713237379279],
            [126.803395836489, 37.712836267401],
            [126.803372855865, 37.71285036492],
            [126.803104756326, 37.712891178255],
            [126.802757122772, 37.712944110052],
            [126.802670101738, 37.712957027313],
            [126.802348275817, 37.713452194853],
            [126.802029380887, 37.714108900348],
            [126.802019322263, 37.71412963254],
            [126.802000790325, 37.714167784057],
            [126.801866965922, 37.714377932724],
            [126.801505561536, 37.714403538268],
            [126.80125974741, 37.714420960117],
            [126.80059615068, 37.714468032757],
            [126.800450840182, 37.714478236234],
            [126.800080241005, 37.714967367881],
            [126.799926473792, 37.715251989981],
            [126.799909153416, 37.715323857313],
            [126.7997807882, 37.715860071837],
            [126.799760025256, 37.715946618965],
            [126.799280356275, 37.716000485705],
            [126.798776748559, 37.716057012224],
            [126.798518080438, 37.716086118774],
            [126.798382102785, 37.71616616048],
            [126.798062320873, 37.716354541267],
            [126.798036420379, 37.716369722862],
            [126.797685056134, 37.716576607966],
            [126.797680023606, 37.71659200575],
            [126.797670188339, 37.716621720559],
            [126.797569316601, 37.716927423391],
            [126.797514648782, 37.717093106182],
            [126.79749417763, 37.717154876886],
            [126.79747839411, 37.717202961046],
            [126.79744031007, 37.717318128418],
            [126.797379122972, 37.717503440862],
            [126.797360480617, 37.717559989114],
            [126.797361362743, 37.717569180473],
            [126.797386051224, 37.717830592832],
            [126.797412945466, 37.718114893473],
            [126.7973299567, 37.71818511505],
            [126.797211953602, 37.71828482751],
            [126.797160000123, 37.718328794599],
            [126.797102328434, 37.718377544877],
            [126.797102135176, 37.718377706716],
            [126.797006547613, 37.718492323728],
            [126.796887336465, 37.718659786231],
            [126.796750899833, 37.718607536719],
            [126.796667759895, 37.718575705402],
            [126.796636058495, 37.718563541366],
            [126.796458721135, 37.718495516636],
            [126.796184863522, 37.718394762753],
            [126.796072853238, 37.718336185179],
            [126.795860728378, 37.718183011909],
            [126.795784160671, 37.718145488332],
            [126.795732210572, 37.718167462272],
            [126.795639949348, 37.718206502137],
            [126.795506614437, 37.718148832797],
            [126.795491664496, 37.718141103433],
            [126.795404081082, 37.718067792098],
            [126.795183973398, 37.717849283777],
            [126.794980599657, 37.71775211012],
            [126.794809609935, 37.7176132152],
            [126.794787604, 37.717593796885],
            [126.794683420506, 37.71739292447],
            [126.794555849248, 37.717221157086],
            [126.794504255102, 37.717180523134],
            [126.794419952487, 37.717115028175],
            [126.794375586677, 37.717081290227],
            [126.794322037537, 37.7170381661],
            [126.794193212984, 37.716966646368],
            [126.794118433803, 37.716922980274],
            [126.793989906852, 37.7168302972],
            [126.79387537613, 37.716741593866],
            [126.793727291196, 37.71664682186],
            [126.793709361802, 37.716642105248],
            [126.793671306109, 37.716590863415],
            [126.793668234902, 37.716553467996],
            [126.793652820312, 37.716468660111],
            [126.793669817478, 37.71643355248],
            [126.793774672434, 37.716290934484],
            [126.793814523429, 37.716235325117],
            [126.7939025081, 37.716122859576],
            [126.793932485472, 37.716069485191],
            [126.793963858402, 37.715963046472],
            [126.79395917381, 37.715894114474],
            [126.793907859962, 37.715790052875],
            [126.793831067872, 37.715712254528],
            [126.793821447223, 37.71566457658],
            [126.793847722252, 37.715515423204],
            [126.793818048375, 37.715378874867],
            [126.793754115295, 37.715261447683],
            [126.793751404165, 37.715261632108],
            [126.793729240027, 37.715217986384],
            [126.793412300168, 37.715320137319],
            [126.79302521124, 37.71548333884],
            [126.792785106973, 37.715565712619],
            [126.792654467396, 37.715681615542],
            [126.792288478101, 37.715801695826],
            [126.79196375635, 37.715886710726],
            [126.79159479347, 37.715974619149],
            [126.791238992546, 37.716060207384],
            [126.790966165643, 37.716121914036],
            [126.790709965803, 37.716159359796],
            [126.790498396924, 37.716296108047],
            [126.790226137527, 37.7165168972],
            [126.790047773939, 37.716662083097],
            [126.789995360949, 37.716667124573],
            [126.789801356448, 37.716648847183],
            [126.78970789984, 37.716689042512],
            [126.789624504629, 37.716860165898],
            [126.789525140647, 37.716983239137],
            [126.789383933772, 37.717063531327],
            [126.789201101724, 37.717144018685],
            [126.789185884541, 37.71715074851],
            [126.789060626925, 37.717206022238],
            [126.788974751781, 37.717323434228],
            [126.788978070708, 37.717364614256],
            [126.78897712652, 37.717373541105],
            [126.788963933446, 37.717426043509],
            [126.78896042293, 37.717436046887],
            [126.788958763217, 37.717492957776],
            [126.78895988164, 37.717605661347],
            [126.78896145188, 37.717699166194],
            [126.788983022085, 37.717818979195],
            [126.789036167924, 37.718105680542],
            [126.78904869679, 37.718416841903],
            [126.789051642027, 37.718485644792],
            [126.789019710405, 37.718487028786],
            [126.788968381652, 37.718489233721],
            [126.789007530453, 37.718760539377],
            [126.788819067389, 37.718944446578],
            [126.788614894426, 37.719148236397],
            [126.788610465595, 37.719189762816],
            [126.788408582827, 37.719068759069],
            [126.788240724119, 37.718968493641],
            [126.787539536562, 37.719685921692],
            [126.787029310913, 37.719370693492],
            [126.786871256797, 37.719273029722],
            [126.786862312496, 37.719267499541],
            [126.786804236665, 37.719229688471],
            [126.786758844195, 37.719198747799],
            [126.785906883237, 37.720079326104],
            [126.785250858248, 37.720758982495]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281132',
      properties: {
        rgnCode: '41281132',
        colCode: '41280',
        rgnKo: ['향동동'],
        rgnSize: 1,
        rgnBbox: [
          126.883579979618, 37.588477773861, 126.90204571583, 37.612567203776
        ],
        rgnCenter: [126.893221589763, 37.599936242943],
        rgnArea: 3272310,
        predVal: [
          0.06583, 0.06694, 0.06405, 0.07193, 0.09012, 0.08194, 0.0899, 0.09455,
          0.08142, 0.08571, 0.08543, 0.06541, 0.09402, 0.08356, 0.09503,
          0.03794, 0.0881, 0.08083, 0.10427, 0.09106, 0.09327, 0.09489, 0.08234,
          0.09325, 0.07465, 0.09096, 0.08314, 0.08561, 0.0837, 0.09587, 0.09215
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.883579979618, 37.603124906344],
            [126.884438359938, 37.603278026994],
            [126.88462682107, 37.603625993009],
            [126.885322281683, 37.604194929308],
            [126.885784732225, 37.604883103447],
            [126.886843697473, 37.6048893628],
            [126.887401807399, 37.605760973299],
            [126.887537535148, 37.605872736136],
            [126.88739844471, 37.6063905763],
            [126.887032804251, 37.606902448447],
            [126.886896802899, 37.60725611401],
            [126.886931001926, 37.607922333167],
            [126.887988076701, 37.608381188615],
            [126.887732437819, 37.609589023604],
            [126.888304685713, 37.60975589512],
            [126.889156254078, 37.609841671494],
            [126.889614780435, 37.60988491278],
            [126.890516271205, 37.610308261344],
            [126.891067836658, 37.610300579896],
            [126.891658848488, 37.610809050776],
            [126.891792725126, 37.610826879605],
            [126.892234385832, 37.611131956243],
            [126.89243236546, 37.611297829865],
            [126.892520515412, 37.611374026209],
            [126.892544880882, 37.611386896702],
            [126.892556601315, 37.611395809219],
            [126.892594445561, 37.611415647721],
            [126.892622530489, 37.611440901114],
            [126.892679788792, 37.611482867581],
            [126.892790429769, 37.611521783838],
            [126.892907196334, 37.611561903932],
            [126.893078783487, 37.611622760825],
            [126.893167577645, 37.611653773066],
            [126.893583947687, 37.611315755045],
            [126.894178448852, 37.611297286549],
            [126.894348573001, 37.611288791738],
            [126.894390547281, 37.611232644653],
            [126.894654382353, 37.611000854126],
            [126.895074047372, 37.610735570016],
            [126.895263130418, 37.610630694982],
            [126.895844408872, 37.610452047725],
            [126.895998725212, 37.61054437422],
            [126.896276565533, 37.610752550798],
            [126.896671739177, 37.611002429322],
            [126.89723724327, 37.611876359648],
            [126.89760600763, 37.612212399524],
            [126.897833784422, 37.612422411344],
            [126.897907472006, 37.61245399238],
            [126.898225179146, 37.612515816901],
            [126.898471477043, 37.61255303551],
            [126.898698510508, 37.61256509044],
            [126.899222703739, 37.612567203776],
            [126.900229025478, 37.612187623447],
            [126.90054834479, 37.612180130617],
            [126.900839039048, 37.612176063265],
            [126.900769572876, 37.612003348951],
            [126.900280692989, 37.611177307369],
            [126.900678525465, 37.61031672417],
            [126.900603626511, 37.609489153637],
            [126.900731877848, 37.608874775711],
            [126.901060164043, 37.607637746284],
            [126.901315486061, 37.606669317599],
            [126.901440262127, 37.606197975577],
            [126.901607615752, 37.605965447734],
            [126.901657295944, 37.60589924056],
            [126.901736566354, 37.605774863945],
            [126.901694634726, 37.605712300321],
            [126.901457867698, 37.605355986598],
            [126.901805759856, 37.60476625474],
            [126.901835835782, 37.604615356589],
            [126.90204571583, 37.604232868414],
            [126.902045012612, 37.603680853873],
            [126.901334454274, 37.603351448299],
            [126.900746693316, 37.603315755549],
            [126.900268792441, 37.603154187793],
            [126.900106031738, 37.603091519864],
            [126.900143706202, 37.602617274967],
            [126.899912434769, 37.602137933493],
            [126.900055692232, 37.60180072878],
            [126.900229592844, 37.601429267776],
            [126.90100899103, 37.600201173499],
            [126.901018462585, 37.599978800922],
            [126.901276244102, 37.599428357264],
            [126.901232178328, 37.598545797735],
            [126.901116913198, 37.598209092369],
            [126.901050186434, 37.597675617377],
            [126.901001824241, 37.597346537039],
            [126.901025240257, 37.597027428616],
            [126.901218177326, 37.596786219227],
            [126.901206445741, 37.596331032334],
            [126.901765103792, 37.59559747436],
            [126.901837992673, 37.595113076945],
            [126.900373296011, 37.594218685588],
            [126.89998563115, 37.593773717159],
            [126.899766772573, 37.593429442886],
            [126.899606130806, 37.59332334857],
            [126.899238312775, 37.592861906747],
            [126.89888157543, 37.592745000733],
            [126.898882357438, 37.592744469833],
            [126.899044145113, 37.592417805405],
            [126.899109068945, 37.592443548811],
            [126.899374448112, 37.591991926132],
            [126.899594277069, 37.591587015576],
            [126.899712362616, 37.591311956847],
            [126.899739565345, 37.590933611889],
            [126.899585680202, 37.590584572857],
            [126.899619124059, 37.590437263732],
            [126.899735948783, 37.589914306931],
            [126.899655628179, 37.589772404645],
            [126.899493879803, 37.58977782403],
            [126.898561839935, 37.589473409978],
            [126.897644968995, 37.589135890716],
            [126.89758090741, 37.589005461835],
            [126.896886750645, 37.588558266467],
            [126.896750712146, 37.58861599804],
            [126.896349487067, 37.588751509762],
            [126.895962821523, 37.588766391277],
            [126.89527039994, 37.588891106063],
            [126.895189103803, 37.588900484192],
            [126.894279014257, 37.588894615388],
            [126.893809049199, 37.589121776239],
            [126.893290463264, 37.589075081394],
            [126.892227899162, 37.588508103779],
            [126.892034382678, 37.588544513445],
            [126.891859875326, 37.588527403938],
            [126.891741914627, 37.588515761692],
            [126.891391207489, 37.588477773861],
            [126.890943853769, 37.58856249872],
            [126.890332551625, 37.588876278087],
            [126.889583180206, 37.589144333599],
            [126.88929012415, 37.58886529068],
            [126.888938092974, 37.588804048898],
            [126.888284605644, 37.588780899225],
            [126.887883236816, 37.588702398151],
            [126.887641146848, 37.588641078088],
            [126.887312303161, 37.588529858181],
            [126.887145657223, 37.58852581359],
            [126.887033038451, 37.588649139327],
            [126.88671807688, 37.588987513584],
            [126.886556353478, 37.58916124623],
            [126.88643408226, 37.589228250441],
            [126.885871720285, 37.589515889766],
            [126.885787760788, 37.58955500031],
            [126.885839738129, 37.589696460785],
            [126.88586985659, 37.589930106576],
            [126.885808611331, 37.590011910009],
            [126.885787568429, 37.590271571127],
            [126.885766202648, 37.590512977968],
            [126.885740547373, 37.590628891482],
            [126.885724839855, 37.590689377177],
            [126.88570635562, 37.590788115942],
            [126.885653129328, 37.590845780768],
            [126.885621192723, 37.590894033168],
            [126.885476456115, 37.590966816645],
            [126.885434319065, 37.591021455953],
            [126.885461640046, 37.591101994779],
            [126.885496609739, 37.591207930853],
            [126.885533554982, 37.591273964261],
            [126.88554788102, 37.591381573882],
            [126.885599687761, 37.591494842454],
            [126.885778846158, 37.591736518076],
            [126.885990516808, 37.591924697771],
            [126.88608584357, 37.592027043728],
            [126.886152253237, 37.592099367584],
            [126.886192559592, 37.592211228117],
            [126.886225169838, 37.592306277762],
            [126.88627268228, 37.592290358721],
            [126.886372655589, 37.59244933583],
            [126.88644850365, 37.592569366821],
            [126.886566360826, 37.592696105961],
            [126.88662780317, 37.592884741749],
            [126.886677165566, 37.593043047634],
            [126.886720037156, 37.593129565784],
            [126.886717644902, 37.593168449928],
            [126.886922286336, 37.593374046104],
            [126.886988162227, 37.5934782728],
            [126.887183383627, 37.593669632836],
            [126.887265934976, 37.593783885455],
            [126.887315518969, 37.593872545208],
            [126.887347721353, 37.593902443976],
            [126.887292238249, 37.593895110348],
            [126.887202521535, 37.593897807534],
            [126.88706967057, 37.593844430647],
            [126.886950139668, 37.593818005959],
            [126.88690315423, 37.593807229626],
            [126.886868857218, 37.593799528945],
            [126.886773601306, 37.593803743088],
            [126.886720957813, 37.59380547585],
            [126.88666970037, 37.593826292776],
            [126.886537934847, 37.593877304343],
            [126.886443201591, 37.593917044524],
            [126.886355978006, 37.593947764096],
            [126.886284960762, 37.593954443157],
            [126.886267395974, 37.593956200946],
            [126.886148142198, 37.593970374052],
            [126.885991091632, 37.593984762325],
            [126.88587245683, 37.593994007376],
            [126.885791713599, 37.593992973198],
            [126.885726641744, 37.593991783152],
            [126.885599503818, 37.593978774294],
            [126.885471304562, 37.593920084373],
            [126.885312372555, 37.593819107915],
            [126.885209453579, 37.593741521931],
            [126.885171853852, 37.593768000832],
            [126.885100202118, 37.593818592511],
            [126.885085930324, 37.593828912719],
            [126.884873003233, 37.593623971919],
            [126.884642923775, 37.593780551571],
            [126.884508523276, 37.59387202145],
            [126.884166144549, 37.594105027369],
            [126.884120062579, 37.594139155863],
            [126.883961705767, 37.59425645051],
            [126.883876808103, 37.594319263608],
            [126.883940372807, 37.594652429663],
            [126.883956826285, 37.594738228716],
            [126.884006329114, 37.594991625627],
            [126.884073513753, 37.595334958272],
            [126.884097027907, 37.595359308225],
            [126.884135882698, 37.595399620841],
            [126.884164631079, 37.595429381887],
            [126.884204072474, 37.595456720899],
            [126.884390262995, 37.595585746466],
            [126.884532431566, 37.595785491367],
            [126.884598493919, 37.595878322094],
            [126.884560525526, 37.596269761835],
            [126.88472138483, 37.596596527637],
            [126.884744676183, 37.596684135286],
            [126.884765400929, 37.596762081847],
            [126.884725144631, 37.597016479742],
            [126.884733589195, 37.597126948664],
            [126.88475228299, 37.597337986476],
            [126.88478557289, 37.597549110793],
            [126.884875544982, 37.597907980302],
            [126.884878775208, 37.59792057924],
            [126.884917799363, 37.598071181111],
            [126.884925926133, 37.598102543356],
            [126.884929528188, 37.598116340968],
            [126.884958433601, 37.598307639246],
            [126.884973948602, 37.598379282732],
            [126.884824916871, 37.598821608321],
            [126.884510322417, 37.599383438245],
            [126.884329126366, 37.599709936905],
            [126.88411575974, 37.599869108757],
            [126.884029397174, 37.600132199942],
            [126.884033528253, 37.600868171914],
            [126.88403161388, 37.600904560704],
            [126.883988895722, 37.60139636467],
            [126.883719827166, 37.602218774699],
            [126.883767681682, 37.602227264658],
            [126.883579979618, 37.603124906344]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281109',
      properties: {
        rgnCode: '41281109',
        colCode: '41280',
        rgnKo: ['지축동'],
        rgnSize: 1,
        rgnBbox: [
          126.89620524303, 37.644763732003, 126.976683448231, 37.693090573765
        ],
        rgnCenter: [126.938155583962, 37.666829551431],
        rgnArea: 10337391,
        predVal: [
          0.13165, 0.13388, 0.1281, 0.14386, 0.18024, 0.16388, 0.1798, 0.18909,
          0.16284, 0.17143, 0.17085, 0.13082, 0.18803, 0.16713, 0.19007,
          0.07589, 0.1762, 0.16166, 0.20855, 0.18212, 0.18653, 0.18979, 0.16469,
          0.1865, 0.14929, 0.18193, 0.16629, 0.17122, 0.1674, 0.19174, 0.18429
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.89620524303, 37.653891948214],
            [126.896281407526, 37.65388318663],
            [126.896534017969, 37.653863365015],
            [126.896579137241, 37.653867189253],
            [126.896682936983, 37.653877264341],
            [126.896724620098, 37.653882887419],
            [126.8967158703, 37.653899989157],
            [126.896795980267, 37.653914061446],
            [126.896857945408, 37.653913449715],
            [126.896852042701, 37.653928634912],
            [126.896939707847, 37.653953237191],
            [126.897005578143, 37.653971990792],
            [126.897006898521, 37.654097191412],
            [126.896991411246, 37.654094456747],
            [126.896986788011, 37.654312515561],
            [126.896985986926, 37.654350436876],
            [126.896983237034, 37.654477039402],
            [126.896981159767, 37.654608850155],
            [126.896980211392, 37.654679080288],
            [126.896978725252, 37.654736813426],
            [126.897077887787, 37.65476561532],
            [126.897233401442, 37.654813828903],
            [126.897286254249, 37.654830795916],
            [126.897393204879, 37.654865884218],
            [126.897613761279, 37.654934075239],
            [126.897734112619, 37.654973283478],
            [126.897740001535, 37.65497608168],
            [126.897829239083, 37.655011937851],
            [126.897866702962, 37.655028828009],
            [126.897992406406, 37.655163859333],
            [126.898037247698, 37.655213461287],
            [126.89809514939, 37.655299987602],
            [126.898129886471, 37.655362960133],
            [126.89820168919, 37.655493303958],
            [126.898331448062, 37.655728752071],
            [126.898345555528, 37.655754352086],
            [126.898417923879, 37.655885957629],
            [126.898468340108, 37.655977540686],
            [126.898521582363, 37.656074351839],
            [126.898671705421, 37.65609790815],
            [126.898967872994, 37.656144385971],
            [126.899059272531, 37.656158781874],
            [126.89907705101, 37.656160401048],
            [126.899213611595, 37.656182359176],
            [126.899346987902, 37.65620426033],
            [126.899451824851, 37.656220920002],
            [126.899595522181, 37.656245046201],
            [126.899668260274, 37.656257263168],
            [126.899796141921, 37.656278177008],
            [126.899916615858, 37.656296381405],
            [126.900053652331, 37.656318762429],
            [126.900121734474, 37.656329821864],
            [126.90039310814, 37.65637640093],
            [126.900743025944, 37.656600610526],
            [126.900905467376, 37.656902728757],
            [126.90155653999, 37.656782713867],
            [126.901748265071, 37.656714870628],
            [126.902078521492, 37.656585886684],
            [126.90222785444, 37.656591661578],
            [126.902814377097, 37.656604758415],
            [126.903601505423, 37.656922809636],
            [126.903802610198, 37.657020957349],
            [126.903880843205, 37.657087288788],
            [126.903953623419, 37.657042498501],
            [126.904243363477, 37.656835243383],
            [126.904692136863, 37.656720242817],
            [126.905075267525, 37.656756559601],
            [126.905279495346, 37.656885890075],
            [126.905947350064, 37.65671723004],
            [126.906297694528, 37.656683798889],
            [126.906489397223, 37.656665510526],
            [126.906689070542, 37.656777806479],
            [126.907010961178, 37.656849800377],
            [126.907026259955, 37.656957307885],
            [126.907365613347, 37.657084688683],
            [126.907638482551, 37.657317366541],
            [126.908050116112, 37.657265932434],
            [126.908269776938, 37.657248158864],
            [126.908545725102, 37.657131961775],
            [126.908750968829, 37.657042485416],
            [126.909166963262, 37.65697267997],
            [126.909204884208, 37.656971096906],
            [126.909710671882, 37.657080734764],
            [126.909794373318, 37.657072745164],
            [126.91024393289, 37.656920703052],
            [126.910537809918, 37.656809722797],
            [126.910576260305, 37.656801373383],
            [126.911201868202, 37.6568178373],
            [126.911261361963, 37.656819846958],
            [126.911388083774, 37.656819367222],
            [126.911652256356, 37.657140603959],
            [126.911921873297, 37.65746928627],
            [126.912020401428, 37.657600155503],
            [126.912263106263, 37.657749000146],
            [126.912316443652, 37.657763374991],
            [126.912625225079, 37.657746102147],
            [126.912907018798, 37.657722906845],
            [126.913153898599, 37.657812405614],
            [126.913320684435, 37.657868525982],
            [126.913597294144, 37.6578689487],
            [126.913873576553, 37.658253861285],
            [126.914570579459, 37.658088606832],
            [126.915154488752, 37.657953349042],
            [126.91571155678, 37.658244283719],
            [126.915877494468, 37.658330942878],
            [126.91585934661, 37.658354138797],
            [126.915897920549, 37.658524568051],
            [126.915994849969, 37.658949925193],
            [126.915845650608, 37.659503357382],
            [126.915751579, 37.659854740963],
            [126.915331820538, 37.660181894007],
            [126.91554876434, 37.660373932726],
            [126.915837018699, 37.660643921058],
            [126.916172647112, 37.660677130996],
            [126.916318392999, 37.661072935048],
            [126.916306619543, 37.661656370114],
            [126.91685785946, 37.661798391082],
            [126.917533523437, 37.661946895036],
            [126.918033660467, 37.662052925261],
            [126.918320337252, 37.662115367064],
            [126.918425282584, 37.66214774094],
            [126.918851584884, 37.662366211228],
            [126.91898228946, 37.662380177747],
            [126.919357337419, 37.66247156404],
            [126.919527593235, 37.662519037676],
            [126.919593342591, 37.662536147798],
            [126.919684175136, 37.662561609293],
            [126.919879272664, 37.66253820204],
            [126.919997515611, 37.662527138772],
            [126.92008442483, 37.662544119086],
            [126.920215982934, 37.662565752113],
            [126.921125370205, 37.662712936765],
            [126.921994789457, 37.662274157306],
            [126.922792510638, 37.662118445546],
            [126.923135814001, 37.662040244406],
            [126.923423380611, 37.661873980247],
            [126.923842462052, 37.661780123219],
            [126.92419779052, 37.66158968359],
            [126.924327440297, 37.661144363096],
            [126.924365692446, 37.661032559248],
            [126.924367903852, 37.660834058133],
            [126.924619836095, 37.660663334917],
            [126.925449877441, 37.660098602037],
            [126.927336830217, 37.660757613852],
            [126.92738176837, 37.661165900396],
            [126.927492770034, 37.661310639475],
            [126.927538968921, 37.66156889656],
            [126.927787067379, 37.662370531089],
            [126.927701496468, 37.662763049802],
            [126.927746292751, 37.663329366736],
            [126.927848709917, 37.663792720316],
            [126.927885328482, 37.663953882279],
            [126.928012802697, 37.664271491837],
            [126.928245506357, 37.664664271789],
            [126.928092857651, 37.665244700972],
            [126.92800941566, 37.665648861613],
            [126.92813307033, 37.665705150323],
            [126.928177083636, 37.665701555786],
            [126.928874340674, 37.665651812063],
            [126.929537120595, 37.665858541231],
            [126.930284822547, 37.665918864804],
            [126.930649435174, 37.666195079524],
            [126.930799734132, 37.66631631457],
            [126.930859821, 37.666394681178],
            [126.930971958832, 37.666551850968],
            [126.931101446182, 37.666712706977],
            [126.931315252058, 37.666942249176],
            [126.931458458527, 37.667015132938],
            [126.931678512522, 37.667074250135],
            [126.9320037537, 37.667090839968],
            [126.932771737108, 37.666608764971],
            [126.932983229713, 37.666610581874],
            [126.933131049151, 37.666523976043],
            [126.933253137175, 37.666279468614],
            [126.933775885417, 37.666080745033],
            [126.933925434501, 37.665934386652],
            [126.934380031633, 37.665781291637],
            [126.934793196435, 37.665899526536],
            [126.935074401861, 37.666003729654],
            [126.935158643759, 37.666058889604],
            [126.93555666812, 37.666523996954],
            [126.936365791084, 37.667581215886],
            [126.936420470851, 37.66768118199],
            [126.936621478116, 37.668065413523],
            [126.936771165977, 37.668657714207],
            [126.936864750663, 37.669079898284],
            [126.937224862817, 37.669232269409],
            [126.937377482925, 37.669296844124],
            [126.938036007329, 37.669575276578],
            [126.938268576618, 37.669673877825],
            [126.938846991493, 37.670117843484],
            [126.939432570184, 37.670610552657],
            [126.940922507969, 37.67097073443],
            [126.941798487732, 37.671697914518],
            [126.941818752234, 37.671714872066],
            [126.941836094297, 37.671729395506],
            [126.942908373792, 37.671931075274],
            [126.943285592915, 37.673172486428],
            [126.943353891102, 37.673399420931],
            [126.943411144098, 37.673545748989],
            [126.943668066091, 37.674159752538],
            [126.944184505183, 37.67528730969],
            [126.944164627842, 37.675958244641],
            [126.944522107134, 37.676162695235],
            [126.944587494007, 37.676796154879],
            [126.944342868978, 37.677241566944],
            [126.943891787082, 37.677754189342],
            [126.943077716294, 37.678884039225],
            [126.943031111537, 37.679840369937],
            [126.943106825667, 37.679984643914],
            [126.9435273589, 37.680848107759],
            [126.944195943295, 37.681459725317],
            [126.944467998228, 37.68173442808],
            [126.944840615694, 37.682120061041],
            [126.945047944812, 37.682333870745],
            [126.945373404564, 37.682604038029],
            [126.9454554958, 37.683111647875],
            [126.945534114649, 37.683625211386],
            [126.945363539619, 37.68402047612],
            [126.945226272115, 37.684533369151],
            [126.945210959164, 37.685788980992],
            [126.946297345691, 37.686194372645],
            [126.947122633876, 37.686745618293],
            [126.947929356039, 37.686976852188],
            [126.948513136209, 37.687240388452],
            [126.948890547931, 37.687231322541],
            [126.949303301519, 37.687394725728],
            [126.950281162996, 37.687674887773],
            [126.950654923946, 37.687751974378],
            [126.951413094075, 37.688007039161],
            [126.951770334654, 37.688566341261],
            [126.952089302857, 37.689043628943],
            [126.952292041422, 37.689367756489],
            [126.952351980238, 37.689664047326],
            [126.952375549556, 37.689803869607],
            [126.95238717301, 37.689834624541],
            [126.952310815881, 37.69027796881],
            [126.952435509211, 37.690396408164],
            [126.952971238401, 37.691127901748],
            [126.953145402411, 37.691680871154],
            [126.953472926153, 37.691953638346],
            [126.953760092144, 37.692610453408],
            [126.955098624475, 37.693090573765],
            [126.956376971229, 37.692630611786],
            [126.95647477762, 37.692567284027],
            [126.956875137311, 37.692299470029],
            [126.957384713217, 37.691935246829],
            [126.957915086376, 37.691614536902],
            [126.959169067968, 37.691657862033],
            [126.959899741399, 37.6918169991],
            [126.960594328255, 37.691957279851],
            [126.96089639971, 37.692030119545],
            [126.961907864995, 37.692254822999],
            [126.962219372912, 37.692605530373],
            [126.96274835082, 37.692843264012],
            [126.963113707848, 37.692934363431],
            [126.963868748444, 37.692974979347],
            [126.964043643787, 37.692987630607],
            [126.965129127115, 37.692812245565],
            [126.965110007497, 37.6926573],
            [126.965092898473, 37.692564359748],
            [126.965104657919, 37.692560498222],
            [126.965127486057, 37.692527791079],
            [126.965097409571, 37.692451415645],
            [126.965133276737, 37.692325227963],
            [126.96516760663, 37.692305300102],
            [126.965214412049, 37.692277708825],
            [126.965252335587, 37.692282000099],
            [126.965290836961, 37.692287129439],
            [126.96532141052, 37.692297508983],
            [126.965334946528, 37.692300747617],
            [126.965388091398, 37.692293285868],
            [126.965395858893, 37.692291306116],
            [126.965445567537, 37.692262084882],
            [126.96552192337, 37.692203347003],
            [126.965573682803, 37.692129798654],
            [126.965600431493, 37.692077838899],
            [126.965638381146, 37.692004241265],
            [126.965786608867, 37.691612616907],
            [126.966402889008, 37.691017891971],
            [126.966799514997, 37.690882179267],
            [126.967516561279, 37.690536983544],
            [126.968194011972, 37.690338738407],
            [126.968587457907, 37.689764066397],
            [126.968716947472, 37.689706746763],
            [126.969454921254, 37.689502753976],
            [126.970395006209, 37.689242904913],
            [126.970776071287, 37.689163187969],
            [126.97129483888, 37.688893301446],
            [126.97154253596, 37.688763435824],
            [126.971709783882, 37.688656253807],
            [126.973104812792, 37.687793252849],
            [126.973344888265, 37.687683112821],
            [126.974256212724, 37.686914979818],
            [126.974931330204, 37.686631454528],
            [126.975315614925, 37.686596934284],
            [126.976275906268, 37.6865256698],
            [126.976683448231, 37.686473256808],
            [126.97658290318, 37.686258489354],
            [126.976120674855, 37.685072966119],
            [126.97414007331, 37.684512867133],
            [126.973825389331, 37.684106870422],
            [126.972782548438, 37.683652693943],
            [126.972030176397, 37.682767728023],
            [126.971810805997, 37.682020326737],
            [126.971269931336, 37.6817729178],
            [126.970245843421, 37.681967341462],
            [126.969745228448, 37.682068828141],
            [126.969155885424, 37.68200491519],
            [126.96858576485, 37.681824446068],
            [126.968462864816, 37.681777777433],
            [126.967965556984, 37.681808071784],
            [126.967517360693, 37.681634010992],
            [126.966682364736, 37.681543769315],
            [126.966146478148, 37.681482587042],
            [126.965727138491, 37.681404049025],
            [126.965005462314, 37.681273655519],
            [126.964340930846, 37.681151600594],
            [126.963288283217, 37.68142259144],
            [126.962643606334, 37.681185839794],
            [126.962171030178, 37.681269986917],
            [126.961865493465, 37.681142108887],
            [126.96175691336, 37.680931452354],
            [126.961368306805, 37.680698104359],
            [126.961132086248, 37.680565130481],
            [126.96084161756, 37.680421001426],
            [126.960407967081, 37.680207347951],
            [126.960073642584, 37.680081896851],
            [126.959906166444, 37.680013625356],
            [126.959873569019, 37.680004126625],
            [126.95973852574, 37.679956345413],
            [126.959479691997, 37.679908880374],
            [126.95930141517, 37.679877625071],
            [126.95860371096, 37.679641751285],
            [126.958085315117, 37.679344619436],
            [126.957766992828, 37.679104537107],
            [126.957622877144, 37.678894791511],
            [126.957485458826, 37.678690093671],
            [126.957286846031, 37.678396681113],
            [126.95712283537, 37.678135337799],
            [126.957042045557, 37.677944094203],
            [126.956872147336, 37.677466001598],
            [126.956747670721, 37.677105475717],
            [126.956497234138, 37.676725404642],
            [126.956173941358, 37.67633488078],
            [126.955956541758, 37.675955199614],
            [126.955866907957, 37.675850454022],
            [126.955802047881, 37.675773810263],
            [126.955714994629, 37.67567618323],
            [126.955445023932, 37.675372531679],
            [126.955419349482, 37.675317075843],
            [126.955330596055, 37.675111178071],
            [126.955188966088, 37.674791989283],
            [126.955017395577, 37.674608339732],
            [126.954889097424, 37.674471494513],
            [126.954625035338, 37.674193448984],
            [126.954541214791, 37.674151700626],
            [126.954413464947, 37.674046668419],
            [126.954087368352, 37.673786904627],
            [126.953962696402, 37.673692117215],
            [126.953804949023, 37.673419905885],
            [126.953558220282, 37.673045172603],
            [126.953447320019, 37.672778286532],
            [126.953369323796, 37.672532424454],
            [126.953327120461, 37.672397045151],
            [126.953272756135, 37.672137155498],
            [126.953224420117, 37.671898206874],
            [126.953319997338, 37.671650829959],
            [126.953436171752, 37.671346961376],
            [126.953495517516, 37.671211452275],
            [126.953501370419, 37.671187425723],
            [126.953644186121, 37.671174023251],
            [126.953688345881, 37.67113963303],
            [126.953778527942, 37.670994207566],
            [126.953884221422, 37.670821912286],
            [126.954019155174, 37.670600660829],
            [126.95418174048, 37.670332560728],
            [126.954542397106, 37.669848810561],
            [126.954764489016, 37.669520557342],
            [126.954776553055, 37.669333321977],
            [126.95478607891, 37.669199035768],
            [126.95478124998, 37.669037065984],
            [126.95477913938, 37.668968582209],
            [126.954733024769, 37.668815695874],
            [126.954636152068, 37.668685250606],
            [126.954495725167, 37.668495296664],
            [126.9544238697, 37.668398422424],
            [126.954331108799, 37.668273744765],
            [126.954176424368, 37.668102425854],
            [126.954017887571, 37.667947971397],
            [126.953692386195, 37.667634490482],
            [126.953665454681, 37.667583592606],
            [126.953049700793, 37.667145116924],
            [126.952600673677, 37.666835591462],
            [126.952131807632, 37.666110509128],
            [126.951826807903, 37.665636216838],
            [126.951768474425, 37.665547761722],
            [126.951672088551, 37.665330523396],
            [126.951617858331, 37.665208256405],
            [126.951452339155, 37.664835687839],
            [126.951425378387, 37.664680312539],
            [126.951376088494, 37.66440950403],
            [126.95128825876, 37.663978720246],
            [126.951049852572, 37.663613129552],
            [126.950758389169, 37.663224567735],
            [126.950368331813, 37.662829178129],
            [126.949786192118, 37.662574408192],
            [126.949644729953, 37.662522089589],
            [126.949438184391, 37.6624470736],
            [126.94916241144, 37.662279595642],
            [126.948928474753, 37.662139444211],
            [126.948722296502, 37.661964373985],
            [126.948650033048, 37.661902904214],
            [126.948444281262, 37.661624140605],
            [126.948375352981, 37.661368223976],
            [126.948338156324, 37.661194526468],
            [126.948329694142, 37.661141581424],
            [126.94832256018, 37.661085528617],
            [126.948314516569, 37.660906636608],
            [126.948303925679, 37.660755781755],
            [126.94830775331, 37.660744097849],
            [126.948349480614, 37.660539712687],
            [126.948373761399, 37.660373547975],
            [126.948336053665, 37.660281505488],
            [126.948229867804, 37.659972369391],
            [126.948158545209, 37.659850525446],
            [126.947996348916, 37.659578492912],
            [126.947890687061, 37.659395006576],
            [126.947793345006, 37.659226029614],
            [126.947680441735, 37.659027763803],
            [126.947662919484, 37.659045550046],
            [126.947561347753, 37.659210210733],
            [126.947494934698, 37.659181448267],
            [126.947450174091, 37.659159443988],
            [126.947250557942, 37.659077850193],
            [126.946858427871, 37.658921250999],
            [126.946565246628, 37.658811358095],
            [126.946421029995, 37.658757196325],
            [126.946112362439, 37.658656557075],
            [126.945954633568, 37.6586078573],
            [126.945536720742, 37.658473937099],
            [126.945066295073, 37.658337314402],
            [126.944427592072, 37.658168479758],
            [126.943857840058, 37.658088349277],
            [126.943331256161, 37.658051412038],
            [126.943154222913, 37.658012591691],
            [126.942680943476, 37.657911267249],
            [126.942318969025, 37.657789814991],
            [126.942071425404, 37.65770726936],
            [126.941477932408, 37.657365913903],
            [126.940897686409, 37.657046239698],
            [126.94069408491, 37.656934116727],
            [126.94031529403, 37.656765744895],
            [126.939946720347, 37.656481728073],
            [126.939650512294, 37.65623301221],
            [126.939240714136, 37.65577510143],
            [126.938828996095, 37.655301817541],
            [126.938459303348, 37.654775081792],
            [126.938388514741, 37.654622797193],
            [126.938168720539, 37.654161452274],
            [126.938142186375, 37.654114551284],
            [126.938052913281, 37.653952453927],
            [126.937928223581, 37.653728629632],
            [126.937736311774, 37.653439493226],
            [126.937560826563, 37.652999226822],
            [126.937428139636, 37.652682993611],
            [126.937316337236, 37.652526280258],
            [126.937158723072, 37.652300698292],
            [126.937048864335, 37.652204008709],
            [126.936786412081, 37.651988108437],
            [126.936484097948, 37.65174389498],
            [126.936272465997, 37.651573827142],
            [126.936030490747, 37.651414085277],
            [126.935702318796, 37.651199587627],
            [126.935437856231, 37.651112360512],
            [126.935269162541, 37.651059117359],
            [126.935258591279, 37.65105771491],
            [126.934548331549, 37.65089135417],
            [126.934460416988, 37.650869662799],
            [126.934001364908, 37.650749643311],
            [126.933881436059, 37.650718328783],
            [126.933671225438, 37.650677186661],
            [126.933564761239, 37.650655709197],
            [126.933347843384, 37.650613733738],
            [126.933154644752, 37.650545057704],
            [126.932700938401, 37.650469364243],
            [126.93251419082, 37.650434855914],
            [126.932394526537, 37.650412738988],
            [126.932219605665, 37.650382318541],
            [126.932164542268, 37.65030024335],
            [126.931890899175, 37.650222931628],
            [126.931535070766, 37.650312359138],
            [126.931542839773, 37.650317138917],
            [126.931443457131, 37.650333793022],
            [126.930862061849, 37.650301731907],
            [126.930447490238, 37.650246793668],
            [126.93030767587, 37.650228239357],
            [126.930017757273, 37.650168509517],
            [126.929704665003, 37.650051012326],
            [126.929282895854, 37.649836656001],
            [126.929190444061, 37.649764160918],
            [126.929147152265, 37.64971125611],
            [126.929109041294, 37.649667697583],
            [126.928950139088, 37.649551500225],
            [126.928865077217, 37.649489496786],
            [126.928706103182, 37.649378011155],
            [126.928456289418, 37.649204472949],
            [126.927988722179, 37.648880110501],
            [126.927939882264, 37.648835752044],
            [126.927731051032, 37.648646011267],
            [126.927555677045, 37.648484428569],
            [126.927489540035, 37.648423273898],
            [126.92727321825, 37.648225022386],
            [126.927176550818, 37.648134152156],
            [126.927133670221, 37.648091707184],
            [126.926578189569, 37.647763121119],
            [126.926569072205, 37.64775886272],
            [126.926123554505, 37.647430092032],
            [126.925857209178, 37.647245978266],
            [126.925838754865, 37.647230730926],
            [126.925479416634, 37.646990822235],
            [126.925171451547, 37.646788309175],
            [126.924974909833, 37.646659738676],
            [126.92471795558, 37.646494341683],
            [126.924435015326, 37.646311970779],
            [126.924120618184, 37.646111090529],
            [126.923657650281, 37.646008876485],
            [126.923577445645, 37.645991065286],
            [126.923369378221, 37.645944869744],
            [126.923362773156, 37.64594402746],
            [126.921470516121, 37.645773717597],
            [126.920624841279, 37.645671195324],
            [126.919890495444, 37.645578492632],
            [126.919840283507, 37.645572448369],
            [126.919417471847, 37.645509870409],
            [126.919234621522, 37.645482443443],
            [126.919130859196, 37.64546681117],
            [126.918563427092, 37.64539915568],
            [126.918044961847, 37.645337010051],
            [126.917363824176, 37.645261456822],
            [126.915805758918, 37.645157326555],
            [126.915511055013, 37.645137813172],
            [126.915186767814, 37.645117133169],
            [126.914878391597, 37.645006654648],
            [126.914591148563, 37.644905615104],
            [126.914040699917, 37.644817327025],
            [126.913706572826, 37.644763732003],
            [126.913390901248, 37.645000228143],
            [126.913286043027, 37.645012240871],
            [126.913265424852, 37.645028091642],
            [126.912944809271, 37.645275169347],
            [126.912373664116, 37.64571306408],
            [126.912028501313, 37.646015530743],
            [126.911849405932, 37.646164776591],
            [126.911685293231, 37.646292049757],
            [126.910744714445, 37.647030130117],
            [126.909735835993, 37.647546419611],
            [126.909521024402, 37.647615627437],
            [126.909535038756, 37.647645100274],
            [126.908622196495, 37.647917741079],
            [126.908109262476, 37.64807320488],
            [126.907651419759, 37.64821015019],
            [126.907470391822, 37.64826334378],
            [126.906643010357, 37.64850648533],
            [126.906677485565, 37.648484529243],
            [126.906302272696, 37.648598470727],
            [126.906008350025, 37.648676978412],
            [126.905961808608, 37.648688536281],
            [126.905546220547, 37.648881214942],
            [126.905385377027, 37.648920726914],
            [126.905171947717, 37.648966322367],
            [126.905010228078, 37.649069622182],
            [126.904811080965, 37.649198568901],
            [126.904820622145, 37.649119831459],
            [126.904614843603, 37.649276531466],
            [126.904469418828, 37.649387276965],
            [126.904451553131, 37.649392875344],
            [126.904230000227, 37.649462230138],
            [126.903992829115, 37.649490541181],
            [126.903641989667, 37.649528929511],
            [126.903636409101, 37.649533096398],
            [126.903635423108, 37.649533212706],
            [126.903562841015, 37.649587895733],
            [126.903541766204, 37.64960378045],
            [126.903159695959, 37.649891630515],
            [126.902946807628, 37.6500820722],
            [126.902890196865, 37.650132101098],
            [126.902557106518, 37.650318630394],
            [126.902508844044, 37.650345069565],
            [126.902506519665, 37.650346121755],
            [126.901835568784, 37.650646905957],
            [126.901289671566, 37.650819529797],
            [126.902285438816, 37.653404115834],
            [126.901512757026, 37.653474189879],
            [126.901015780065, 37.653530384797],
            [126.900721356288, 37.653541936372],
            [126.900471089759, 37.653581455264],
            [126.900422149968, 37.653562069405],
            [126.899464912308, 37.653613781049],
            [126.898574393024, 37.653610835974],
            [126.898005320894, 37.653591435564],
            [126.897873062804, 37.653592121308],
            [126.897814033378, 37.653592429822],
            [126.897723375775, 37.653592908698],
            [126.897575099433, 37.653581615068],
            [126.897088482297, 37.653544560683],
            [126.897026275657, 37.653539820531],
            [126.896874690898, 37.653528279827],
            [126.896583222448, 37.653508848182],
            [126.896522211303, 37.653564546218],
            [126.896462687225, 37.65361836251],
            [126.896304260315, 37.65378976692],
            [126.896217943156, 37.653878183647],
            [126.89620524303, 37.653891948214]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281111',
      properties: {
        rgnCode: '41281111',
        colCode: '41280',
        rgnKo: ['삼송동'],
        rgnSize: 1,
        rgnBbox: [
          126.873705638846, 37.641177218895, 126.903541766204, 37.659526558718
        ],
        rgnCenter: [126.888009588007, 37.651614842034],
        rgnArea: 1783419,
        predVal: [
          0.06583, 0.06694, 0.06405, 0.07193, 0.09012, 0.08194, 0.0899, 0.09455,
          0.08142, 0.08571, 0.08543, 0.06541, 0.09402, 0.08356, 0.09503,
          0.03794, 0.0881, 0.08083, 0.10427, 0.09106, 0.09327, 0.09489, 0.08234,
          0.09325, 0.07465, 0.09096, 0.08314, 0.08561, 0.0837, 0.09587, 0.09215
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.873705638846, 37.643447675495],
            [126.873857060643, 37.643725754541],
            [126.874059181047, 37.644094265073],
            [126.874028919556, 37.644298195338],
            [126.874099300354, 37.644533606309],
            [126.874204032455, 37.644824500364],
            [126.874322558671, 37.645119878024],
            [126.874337049575, 37.645173762996],
            [126.874350944608, 37.645198338578],
            [126.87457957263, 37.645481320031],
            [126.874620098841, 37.645477219139],
            [126.874610928167, 37.645619428314],
            [126.874616625612, 37.645733831345],
            [126.874741968465, 37.645853714797],
            [126.875357227676, 37.646429539241],
            [126.875559297359, 37.64653483595],
            [126.875750452405, 37.646642147872],
            [126.875797458651, 37.6467064015],
            [126.875863754418, 37.646687596687],
            [126.876006555829, 37.646535096378],
            [126.876095360508, 37.64663421706],
            [126.875922733655, 37.646720626189],
            [126.875977961327, 37.64676773392],
            [126.875876409137, 37.646850281324],
            [126.875620367495, 37.647040816998],
            [126.876135827181, 37.647670255908],
            [126.876334324815, 37.647901350687],
            [126.876466803458, 37.648060576428],
            [126.876694103146, 37.648334803779],
            [126.876920840345, 37.648607588526],
            [126.876968784263, 37.6486680946],
            [126.877013552471, 37.648730399254],
            [126.87705491909, 37.648794051765],
            [126.877092883961, 37.648859142233],
            [126.877127334215, 37.648925400251],
            [126.877158156681, 37.648992735605],
            [126.877185351651, 37.649060968102],
            [126.877319138858, 37.649423391197],
            [126.877355803768, 37.649554981191],
            [126.877560994138, 37.649466622084],
            [126.877804508134, 37.649425441839],
            [126.87786703012, 37.649414866867],
            [126.878238531379, 37.649449943204],
            [126.878522439329, 37.649554482374],
            [126.878689570486, 37.649600921579],
            [126.878889587114, 37.649646628906],
            [126.881745205308, 37.65043178028],
            [126.884648660724, 37.651215423264],
            [126.884832150242, 37.651269690427],
            [126.88511156327, 37.651345864599],
            [126.88511140132, 37.651347981731],
            [126.885344740085, 37.651409478044],
            [126.885342235318, 37.651431585505],
            [126.885339615589, 37.651454746994],
            [126.885336214687, 37.651484719084],
            [126.88533400864, 37.651504195992],
            [126.885329158111, 37.651540896938],
            [126.885322294413, 37.651589641937],
            [126.885319842302, 37.651607037349],
            [126.885327941112, 37.651609243705],
            [126.885321831266, 37.651639618565],
            [126.885308556915, 37.651708142659],
            [126.885260978566, 37.651952728511],
            [126.885195766772, 37.652219551029],
            [126.885076497201, 37.652525170191],
            [126.88503955649, 37.652618555808],
            [126.884886528732, 37.652923429651],
            [126.884733468207, 37.65322671753],
            [126.884689671175, 37.653319870995],
            [126.884667682964, 37.653365807939],
            [126.884598170903, 37.653511048273],
            [126.884549074368, 37.65358703286],
            [126.884499966388, 37.653663026424],
            [126.884412240701, 37.653766407307],
            [126.884323942316, 37.653851876179],
            [126.883982857155, 37.654117027045],
            [126.88417720186, 37.654161791727],
            [126.884055906357, 37.654389221162],
            [126.88391891235, 37.654395517636],
            [126.883951309244, 37.654669905691],
            [126.883952273528, 37.654856129076],
            [126.88395721435, 37.654892109912],
            [126.883974478114, 37.654953861888],
            [126.883948699099, 37.655031644356],
            [126.883837725165, 37.655108341781],
            [126.883766003204, 37.655208521641],
            [126.883545590872, 37.655321806189],
            [126.883491384593, 37.655352664402],
            [126.883473870577, 37.655563727164],
            [126.883473251098, 37.655704494782],
            [126.883481138989, 37.65579007722],
            [126.883425612301, 37.656325281484],
            [126.883881210737, 37.657031325642],
            [126.884011973031, 37.657976532753],
            [126.884570867508, 37.658073222073],
            [126.884647895947, 37.658095282177],
            [126.885200176356, 37.658146733066],
            [126.885906218907, 37.657921913331],
            [126.885806641294, 37.657313568082],
            [126.88580609018, 37.657303566728],
            [126.885932223161, 37.656850148987],
            [126.886351432854, 37.656560706564],
            [126.88687437292, 37.656576253189],
            [126.887188382534, 37.656608785783],
            [126.887310825246, 37.656619941321],
            [126.887782207109, 37.65665488603],
            [126.888562848998, 37.656716704123],
            [126.88911394442, 37.656571975443],
            [126.890001272259, 37.656837839171],
            [126.889999749326, 37.656984723563],
            [126.889975533168, 37.657074509779],
            [126.889954057457, 37.657120186883],
            [126.890595605161, 37.657405463672],
            [126.891054206774, 37.657633993626],
            [126.891130750281, 37.657686060494],
            [126.89133228341, 37.657791365559],
            [126.891684849792, 37.657980718714],
            [126.891747120858, 37.658067144052],
            [126.891963178, 37.658408291505],
            [126.892141784999, 37.658677785404],
            [126.892521494603, 37.658568470301],
            [126.892617902584, 37.658592272975],
            [126.893159450606, 37.658915293324],
            [126.89357684982, 37.659027486863],
            [126.893648476071, 37.659041733711],
            [126.893702610506, 37.659043837421],
            [126.893792425394, 37.659082156809],
            [126.893778349868, 37.658917463935],
            [126.893937098253, 37.658864730654],
            [126.894367527575, 37.658869068675],
            [126.894601082013, 37.659062701679],
            [126.894839801699, 37.659227309505],
            [126.895032862243, 37.659399533916],
            [126.895167991985, 37.659450668961],
            [126.895326195009, 37.659478579498],
            [126.895577498345, 37.659498888163],
            [126.895900858048, 37.659526558718],
            [126.896449441686, 37.659262928105],
            [126.896618195164, 37.659106029521],
            [126.896708876597, 37.659020814919],
            [126.896794810953, 37.658966715673],
            [126.896951490166, 37.658868747603],
            [126.897029744514, 37.658819839997],
            [126.897131797086, 37.658755294471],
            [126.897213080391, 37.658704019861],
            [126.897311009783, 37.658686474348],
            [126.897534704728, 37.658649425475],
            [126.897739629993, 37.658613963401],
            [126.897799862465, 37.658590807283],
            [126.89787957472, 37.658560496497],
            [126.898034474542, 37.658501807886],
            [126.898238688563, 37.658423007467],
            [126.898284361185, 37.658389378044],
            [126.898262875369, 37.658355734886],
            [126.898370921377, 37.658299329324],
            [126.898581326855, 37.658233579827],
            [126.898638064119, 37.658147712431],
            [126.898788538718, 37.658075459305],
            [126.899026355621, 37.658035599847],
            [126.899161117217, 37.658024896155],
            [126.899292289051, 37.658012171016],
            [126.899410653124, 37.657942736343],
            [126.899466208706, 37.657924494642],
            [126.899491265329, 37.65791631745],
            [126.89963568434, 37.657721561553],
            [126.899895299318, 37.65756861987],
            [126.899904352138, 37.657578421259],
            [126.899966976371, 37.657545427451],
            [126.900025043467, 37.657513411751],
            [126.900196233659, 37.65741464095],
            [126.900207572075, 37.657343707975],
            [126.900270380338, 37.657099832123],
            [126.900905467376, 37.656902728757],
            [126.900743025944, 37.656600610526],
            [126.90039310814, 37.65637640093],
            [126.900121734474, 37.656329821864],
            [126.900053652331, 37.656318762429],
            [126.899916615858, 37.656296381405],
            [126.899796141921, 37.656278177008],
            [126.899668260274, 37.656257263168],
            [126.899595522181, 37.656245046201],
            [126.899451824851, 37.656220920002],
            [126.899346987902, 37.65620426033],
            [126.899213611595, 37.656182359176],
            [126.89907705101, 37.656160401048],
            [126.899059272531, 37.656158781874],
            [126.898967872994, 37.656144385971],
            [126.898671705421, 37.65609790815],
            [126.898521582363, 37.656074351839],
            [126.898468340108, 37.655977540686],
            [126.898417923879, 37.655885957629],
            [126.898345555528, 37.655754352086],
            [126.898331448062, 37.655728752071],
            [126.89820168919, 37.655493303958],
            [126.898129886471, 37.655362960133],
            [126.89809514939, 37.655299987602],
            [126.898037247698, 37.655213461287],
            [126.897992406406, 37.655163859333],
            [126.897866702962, 37.655028828009],
            [126.897829239083, 37.655011937851],
            [126.897740001535, 37.65497608168],
            [126.897734112619, 37.654973283478],
            [126.897613761279, 37.654934075239],
            [126.897393204879, 37.654865884218],
            [126.897286254249, 37.654830795916],
            [126.897233401442, 37.654813828903],
            [126.897077887787, 37.65476561532],
            [126.896978725252, 37.654736813426],
            [126.896980211392, 37.654679080288],
            [126.896981159767, 37.654608850155],
            [126.896983237034, 37.654477039402],
            [126.896985986926, 37.654350436876],
            [126.896986788011, 37.654312515561],
            [126.896991411246, 37.654094456747],
            [126.897006898521, 37.654097191412],
            [126.897005578143, 37.653971990792],
            [126.896939707847, 37.653953237191],
            [126.896852042701, 37.653928634912],
            [126.896857945408, 37.653913449715],
            [126.896795980267, 37.653914061446],
            [126.8967158703, 37.653899989157],
            [126.896724620098, 37.653882887419],
            [126.896682936983, 37.653877264341],
            [126.896579137241, 37.653867189253],
            [126.896534017969, 37.653863365015],
            [126.896281407526, 37.65388318663],
            [126.89620524303, 37.653891948214],
            [126.896217943156, 37.653878183647],
            [126.896304260315, 37.65378976692],
            [126.896462687225, 37.65361836251],
            [126.896522211303, 37.653564546218],
            [126.896583222448, 37.653508848182],
            [126.896874690898, 37.653528279827],
            [126.897026275657, 37.653539820531],
            [126.897088482297, 37.653544560683],
            [126.897575099433, 37.653581615068],
            [126.897723375775, 37.653592908698],
            [126.897814033378, 37.653592429822],
            [126.897873062804, 37.653592121308],
            [126.898005320894, 37.653591435564],
            [126.898574393024, 37.653610835974],
            [126.899464912308, 37.653613781049],
            [126.900422149968, 37.653562069405],
            [126.900471089759, 37.653581455264],
            [126.900721356288, 37.653541936372],
            [126.901015780065, 37.653530384797],
            [126.901512757026, 37.653474189879],
            [126.902285438816, 37.653404115834],
            [126.901289671566, 37.650819529797],
            [126.901835568784, 37.650646905957],
            [126.902506519665, 37.650346121755],
            [126.902508844044, 37.650345069565],
            [126.902557106518, 37.650318630394],
            [126.902890196865, 37.650132101098],
            [126.902946807628, 37.6500820722],
            [126.903159695959, 37.649891630515],
            [126.903541766204, 37.64960378045],
            [126.903157385394, 37.649890817711],
            [126.902514699038, 37.649818418323],
            [126.902380772244, 37.649817873388],
            [126.902235977758, 37.64986044885],
            [126.90156235155, 37.650046111649],
            [126.90104184232, 37.650167211069],
            [126.901239219384, 37.6506872688],
            [126.90113886851, 37.650710707923],
            [126.900724417467, 37.650807524738],
            [126.900534372647, 37.650816615231],
            [126.900114486485, 37.650884404835],
            [126.899858414648, 37.65094940605],
            [126.899637564179, 37.651020644849],
            [126.899334791342, 37.651136752948],
            [126.899036976406, 37.65127260494],
            [126.898609193644, 37.651418478846],
            [126.897980386955, 37.651569607822],
            [126.897715339094, 37.651616180565],
            [126.897525493355, 37.6516351681],
            [126.897405159841, 37.65164394558],
            [126.897284826287, 37.651652731946],
            [126.89707496578, 37.651657366557],
            [126.896829422393, 37.651637030143],
            [126.896570689078, 37.651608518652],
            [126.89626774157, 37.651546234646],
            [126.896021518181, 37.651478315449],
            [126.895713555252, 37.651365588946],
            [126.894967139708, 37.651026375742],
            [126.894053242389, 37.650683743825],
            [126.893691631285, 37.650562512004],
            [126.89314543069, 37.650408908795],
            [126.891870735527, 37.650092749997],
            [126.891080594357, 37.649894528202],
            [126.890584821518, 37.649723337244],
            [126.889858126663, 37.649423212521],
            [126.888334653559, 37.64869928658],
            [126.887752155222, 37.648467545002],
            [126.887157538055, 37.648271422378],
            [126.886218845726, 37.648026605629],
            [126.885843020617, 37.647902957246],
            [126.885565887047, 37.647789648796],
            [126.885143696703, 37.647571701542],
            [126.884867383959, 37.647398711628],
            [126.883486617262, 37.646342971457],
            [126.882796396707, 37.645882204604],
            [126.882114686979, 37.645503791498],
            [126.881874371138, 37.645390178235],
            [126.881246846254, 37.645122310721],
            [126.881018062664, 37.645011833851],
            [126.880191541866, 37.644583635835],
            [126.879717958358, 37.644266634975],
            [126.879058276629, 37.643692003265],
            [126.878232711321, 37.642858974791],
            [126.878017429603, 37.642614098526],
            [126.877393490304, 37.64204540518],
            [126.877377060335, 37.642018737011],
            [126.876858595698, 37.641177218895],
            [126.876759983017, 37.641221280533],
            [126.876653693914, 37.641268766685],
            [126.876427302927, 37.64132366664],
            [126.87507394882, 37.641651801212],
            [126.875122956966, 37.641816281927],
            [126.875127407123, 37.641978363344],
            [126.875194447608, 37.642293569654],
            [126.875112437929, 37.64240740127],
            [126.875134887324, 37.642532210542],
            [126.875241798766, 37.642640811576],
            [126.875174580209, 37.642664615486],
            [126.873911229451, 37.643084903733],
            [126.873854268731, 37.643291444968],
            [126.873705638846, 37.643447675495]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281103',
      properties: {
        rgnCode: '41281103',
        colCode: '41280',
        rgnKo: ['신원동'],
        rgnSize: 1,
        rgnBbox: [
          126.863694702253, 37.656560706564, 126.895744345963, 37.68739769539
        ],
        rgnCenter: [126.880045655686, 37.671652105618],
        rgnArea: 4369417,
        predVal: [
          0.2633, 0.26775, 0.2562, 0.28773, 0.36048, 0.32776, 0.35959, 0.37818,
          0.32568, 0.34286, 0.34171, 0.26163, 0.37606, 0.33426, 0.38013,
          0.15177, 0.3524, 0.32333, 0.41709, 0.36423, 0.37307, 0.37957, 0.32938,
          0.37301, 0.29859, 0.36386, 0.33257, 0.34243, 0.33479, 0.38347, 0.36859
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.863694702253, 37.67726482451],
            [126.863696701516, 37.677293207429],
            [126.863758434413, 37.677318497724],
            [126.863815608914, 37.677645130382],
            [126.863878000017, 37.678007699796],
            [126.863919263181, 37.678277363438],
            [126.86399560772, 37.678776184803],
            [126.864015124058, 37.678950419239],
            [126.864033714796, 37.679116417717],
            [126.86405165309, 37.679242862818],
            [126.864476781812, 37.679541831078],
            [126.86456421078, 37.679810295709],
            [126.864580815155, 37.67986127388],
            [126.864597054731, 37.67984552575],
            [126.864671900438, 37.679824430799],
            [126.86475347947, 37.679791135459],
            [126.864802572171, 37.679805634933],
            [126.865007489601, 37.679864174089],
            [126.865231633657, 37.679903985912],
            [126.865326110599, 37.679952630325],
            [126.865375454238, 37.679972607751],
            [126.865534537037, 37.67997719702],
            [126.865647074559, 37.679981327027],
            [126.865728339907, 37.679983159491],
            [126.865806631998, 37.679968077261],
            [126.86581745184, 37.679965233634],
            [126.865901049181, 37.679968843574],
            [126.865944778493, 37.679984300443],
            [126.865931491969, 37.679997078951],
            [126.865925800779, 37.680009785101],
            [126.866015774396, 37.680035403989],
            [126.866253019074, 37.680102951782],
            [126.866783928929, 37.68026987862],
            [126.866884097971, 37.680293508337],
            [126.868018685215, 37.680384895084],
            [126.868645272271, 37.680174054794],
            [126.869041620335, 37.680153778329],
            [126.869420034803, 37.68008212513],
            [126.869914425784, 37.680281540029],
            [126.870252015146, 37.680417701498],
            [126.870327404532, 37.680512162034],
            [126.870386986214, 37.680570935528],
            [126.870724171086, 37.680964556491],
            [126.870824847765, 37.681120094493],
            [126.871139950902, 37.68151559982],
            [126.871240737795, 37.681855367974],
            [126.87128935816, 37.682011866141],
            [126.871294092821, 37.682027106776],
            [126.871457043782, 37.683058942444],
            [126.871467361212, 37.683129725019],
            [126.871466804975, 37.683208072761],
            [126.871461113125, 37.683312461882],
            [126.871458153646, 37.683384238899],
            [126.871398076441, 37.683477576192],
            [126.87136212144, 37.683552641377],
            [126.871174344934, 37.683615087451],
            [126.871116124281, 37.683748492782],
            [126.871179507426, 37.68384587661],
            [126.871255104898, 37.684076824664],
            [126.871242162948, 37.684177899191],
            [126.871191181066, 37.684294788754],
            [126.871205528583, 37.684472529922],
            [126.871239212714, 37.684613722079],
            [126.871237012982, 37.684691725615],
            [126.871210970585, 37.684738042731],
            [126.871065660068, 37.684760946917],
            [126.870915561132, 37.684786233191],
            [126.870843223366, 37.684797604388],
            [126.87078808241, 37.684809652313],
            [126.870725738903, 37.684823764455],
            [126.870610217593, 37.684887515042],
            [126.870552369818, 37.684949554664],
            [126.870541691292, 37.685026458593],
            [126.870586724519, 37.685102099958],
            [126.870663239221, 37.685171992014],
            [126.870939152741, 37.685407288747],
            [126.87145676854, 37.685541024033],
            [126.871489664101, 37.685562989908],
            [126.871976530679, 37.685875810781],
            [126.872555945102, 37.686261096745],
            [126.873065809533, 37.686674198125],
            [126.873421634485, 37.687031738463],
            [126.873676664161, 37.687395394186],
            [126.873680106737, 37.68739769539],
            [126.874384815905, 37.687200379043],
            [126.875276844341, 37.686943741092],
            [126.876078214117, 37.686539412163],
            [126.876463778768, 37.686478429566],
            [126.87683482942, 37.686418565585],
            [126.877071304213, 37.686381398086],
            [126.878072498505, 37.686023567075],
            [126.878652256979, 37.685745889986],
            [126.879065644157, 37.685549169733],
            [126.879672858114, 37.685421014019],
            [126.880933788196, 37.68504993918],
            [126.881895931185, 37.684800980948],
            [126.882840225892, 37.684593270326],
            [126.883019811627, 37.684579639034],
            [126.883519482414, 37.684543794897],
            [126.883708556954, 37.684530081953],
            [126.884357073368, 37.68424584128],
            [126.885015165801, 37.683779475388],
            [126.885284010504, 37.683564904337],
            [126.885408972683, 37.683398888538],
            [126.885520887868, 37.683129653637],
            [126.885625709922, 37.682792028007],
            [126.885648114181, 37.682600458699],
            [126.885701602062, 37.68239490982],
            [126.885842839869, 37.68182777048],
            [126.885832647907, 37.681740510511],
            [126.885793602414, 37.681640239249],
            [126.885661587249, 37.681489097988],
            [126.885478584802, 37.681357853908],
            [126.885143910967, 37.681076412358],
            [126.884877379361, 37.680883458505],
            [126.884332893509, 37.680526305617],
            [126.884024666057, 37.680315234916],
            [126.883931607581, 37.680034985361],
            [126.883865474059, 37.679847400387],
            [126.883813905841, 37.679696886831],
            [126.883729502074, 37.679450116788],
            [126.883685487769, 37.679318846435],
            [126.883606712314, 37.679083362066],
            [126.883528896329, 37.678850770712],
            [126.883453104241, 37.678686168583],
            [126.883441796958, 37.67866093909],
            [126.883385595693, 37.678537972368],
            [126.883166296987, 37.67798484469],
            [126.883058774289, 37.677765332298],
            [126.882830451204, 37.67735107955],
            [126.88255661873, 37.67683694383],
            [126.882644780978, 37.676563876289],
            [126.882665531371, 37.6763041382],
            [126.882685466313, 37.676086510669],
            [126.882694047008, 37.675994295898],
            [126.882687195191, 37.675762973632],
            [126.882684483109, 37.675643961602],
            [126.882681637704, 37.675494829963],
            [126.882679885421, 37.675129394217],
            [126.88266606456, 37.675024687415],
            [126.882632782825, 37.674741172226],
            [126.882621369022, 37.674648270565],
            [126.882626130178, 37.673873683124],
            [126.882627278592, 37.673828824907],
            [126.882638384584, 37.673574599935],
            [126.882649997467, 37.673258370592],
            [126.882674666791, 37.672999906673],
            [126.882757261157, 37.672597165399],
            [126.882866899717, 37.672080568406],
            [126.882961734165, 37.671820012603],
            [126.88318542598, 37.671428764929],
            [126.883325454097, 37.671258711722],
            [126.883477013122, 37.670964425841],
            [126.883529184556, 37.670908698862],
            [126.883473624081, 37.670885677494],
            [126.883571723115, 37.670637017243],
            [126.883591343, 37.670587186075],
            [126.883619747113, 37.670587592878],
            [126.883715060519, 37.67058764306],
            [126.883978585396, 37.670527324594],
            [126.884056650056, 37.670496877424],
            [126.884071584515, 37.670492639707],
            [126.884086552948, 37.670488420042],
            [126.884243978353, 37.670424877938],
            [126.884546806009, 37.670302646426],
            [126.884986868353, 37.670125049908],
            [126.885310662853, 37.670002656944],
            [126.885468684499, 37.669933392614],
            [126.88573032989, 37.669818280254],
            [126.886452046782, 37.669642936538],
            [126.886619446534, 37.66972400703],
            [126.886735971271, 37.66972051659],
            [126.886781804026, 37.669620192873],
            [126.886880471166, 37.669583439042],
            [126.886949413362, 37.669565126192],
            [126.887174344707, 37.669456056516],
            [126.887275208938, 37.669390635545],
            [126.88729541219, 37.669409467413],
            [126.887321614514, 37.66943367488],
            [126.887433833969, 37.669532331821],
            [126.887791366312, 37.669624323984],
            [126.887981337587, 37.669673673052],
            [126.888368658375, 37.66975831317],
            [126.889312466728, 37.669998512994],
            [126.88975667829, 37.670113988878],
            [126.890169163102, 37.670246281322],
            [126.890212453422, 37.670259251131],
            [126.890471158843, 37.670333059354],
            [126.890578353795, 37.670353846546],
            [126.890887617956, 37.670416385186],
            [126.891289240648, 37.670469224804],
            [126.891715607799, 37.670591127589],
            [126.89185796785, 37.670632218221],
            [126.891963813241, 37.670661769358],
            [126.89202965485, 37.670500925876],
            [126.892207868477, 37.670015196854],
            [126.892279978361, 37.669845700679],
            [126.892313021109, 37.669805367718],
            [126.892342278174, 37.669718541073],
            [126.892353609237, 37.669682242377],
            [126.892364940289, 37.66964594368],
            [126.892399093635, 37.669543714922],
            [126.892400915963, 37.669460286571],
            [126.892392923733, 37.669422528436],
            [126.892404734006, 37.669329829307],
            [126.892374162921, 37.669153120342],
            [126.892343918947, 37.668985331294],
            [126.892310551523, 37.668938089451],
            [126.892302855594, 37.668930243865],
            [126.892261483901, 37.668853577847],
            [126.892220112293, 37.668776911813],
            [126.892132757561, 37.668676732798],
            [126.892097366224, 37.668618587249],
            [126.89209511932, 37.66860498048],
            [126.892080912478, 37.66855424255],
            [126.892065791695, 37.66850841407],
            [126.89205067093, 37.668462585588],
            [126.8920320985, 37.668413150009],
            [126.892013526095, 37.668363714427],
            [126.891991267869, 37.668315762024],
            [126.891970548023, 37.668223663392],
            [126.892042153906, 37.668010649867],
            [126.892117542408, 37.667845751736],
            [126.89213980332, 37.667814598729],
            [126.892169219601, 37.667773361458],
            [126.89222579182, 37.667694110281],
            [126.89227211939, 37.667629211091],
            [126.89247349183, 37.667383476942],
            [126.892674862941, 37.667137742438],
            [126.892755166563, 37.667020554913],
            [126.892835469932, 37.666903367331],
            [126.892960621336, 37.666965739792],
            [126.892967089796, 37.666960249811],
            [126.893001360067, 37.666931990801],
            [126.892916708774, 37.666844068051],
            [126.892894980097, 37.666821523725],
            [126.892988172873, 37.66672565591],
            [126.893001667533, 37.666720803072],
            [126.893166999477, 37.666665365037],
            [126.893138174713, 37.66661227126],
            [126.893111186366, 37.666621346285],
            [126.8931007539, 37.66662485049],
            [126.893378402014, 37.666338055424],
            [126.893563970745, 37.666160643657],
            [126.893662633572, 37.666043066856],
            [126.893738163701, 37.665933127106],
            [126.893831340614, 37.665846718809],
            [126.893866182599, 37.665814405685],
            [126.893851356064, 37.665799886489],
            [126.89382204238, 37.665771298887],
            [126.893899799852, 37.66560982541],
            [126.893907075288, 37.665594785799],
            [126.893981798105, 37.665383954194],
            [126.89402998202, 37.665247933171],
            [126.894067476274, 37.665183818054],
            [126.894126035465, 37.665130804072],
            [126.894172775064, 37.665100213533],
            [126.894537337597, 37.664975669871],
            [126.894684995128, 37.664912285095],
            [126.894750780079, 37.664878287876],
            [126.89477120611, 37.664860737387],
            [126.894865122565, 37.664794330528],
            [126.894838656751, 37.664771511977],
            [126.894946434777, 37.664696828634],
            [126.895257287968, 37.664481506207],
            [126.895309251006, 37.664443351745],
            [126.895358157344, 37.664402761881],
            [126.895404120063, 37.664359916916],
            [126.895446685557, 37.66431499664],
            [126.895485853703, 37.664268091153],
            [126.895521397443, 37.664219470547],
            [126.895553316528, 37.664169315019],
            [126.895581384287, 37.664117624369],
            [126.895605486878, 37.664064758887],
            [126.895711592919, 37.663809338107],
            [126.895744345963, 37.663730352128],
            [126.895682380611, 37.663709393969],
            [126.8956136242, 37.66368203277],
            [126.895550989793, 37.663653415667],
            [126.89548870121, 37.663620744463],
            [126.895442382831, 37.663595295374],
            [126.895378171478, 37.663559919463],
            [126.895382416594, 37.663547507871],
            [126.893885200409, 37.66273000455],
            [126.893406289129, 37.662466139994],
            [126.893778612541, 37.662068682844],
            [126.893874037937, 37.66196009454],
            [126.893919733611, 37.661677789147],
            [126.893904192699, 37.661232270566],
            [126.893897531578, 37.661026175781],
            [126.893893280504, 37.660901332994],
            [126.89379918108, 37.66033632758],
            [126.89379877676, 37.660137689525],
            [126.893802735493, 37.660017620363],
            [126.893808401802, 37.659962873359],
            [126.893820151506, 37.659847063903],
            [126.893832820214, 37.659738363966],
            [126.893819877118, 37.659566284163],
            [126.893813727475, 37.659469243662],
            [126.893801968647, 37.659285479299],
            [126.893792425394, 37.659082156809],
            [126.893702610506, 37.659043837421],
            [126.893648476071, 37.659041733711],
            [126.89357684982, 37.659027486863],
            [126.893159450606, 37.658915293324],
            [126.892617902584, 37.658592272975],
            [126.892521494603, 37.658568470301],
            [126.892141784999, 37.658677785404],
            [126.891963178, 37.658408291505],
            [126.891747120858, 37.658067144052],
            [126.891684849792, 37.657980718714],
            [126.89133228341, 37.657791365559],
            [126.891130750281, 37.657686060494],
            [126.891054206774, 37.657633993626],
            [126.890595605161, 37.657405463672],
            [126.889954057457, 37.657120186883],
            [126.889975533168, 37.657074509779],
            [126.889999749326, 37.656984723563],
            [126.890001272259, 37.656837839171],
            [126.88911394442, 37.656571975443],
            [126.888562848998, 37.656716704123],
            [126.887782207109, 37.65665488603],
            [126.887310825246, 37.656619941321],
            [126.887188382534, 37.656608785783],
            [126.88687437292, 37.656576253189],
            [126.886351432854, 37.656560706564],
            [126.885932223161, 37.656850148987],
            [126.88580609018, 37.657303566728],
            [126.885806641294, 37.657313568082],
            [126.885906218907, 37.657921913331],
            [126.885200176356, 37.658146733066],
            [126.884647895947, 37.658095282177],
            [126.884570867508, 37.658073222073],
            [126.884011973031, 37.657976532753],
            [126.883835767748, 37.657964193954],
            [126.883598483648, 37.657948820638],
            [126.883480120998, 37.657979425477],
            [126.883061426526, 37.658089465199],
            [126.882898919218, 37.658228772678],
            [126.88280203165, 37.658294896802],
            [126.882644413164, 37.658402449513],
            [126.882582336569, 37.658449210558],
            [126.882462211154, 37.658539700333],
            [126.882424098192, 37.65856841194],
            [126.882445023947, 37.658806380273],
            [126.882527775168, 37.659219289995],
            [126.882486261771, 37.65945399684],
            [126.882475026886, 37.659548695867],
            [126.882464711059, 37.659635602396],
            [126.882446974081, 37.659785110098],
            [126.882266240061, 37.65993750743],
            [126.882000845956, 37.660161265949],
            [126.881558513117, 37.660493186142],
            [126.881199189465, 37.66039137056],
            [126.880677069682, 37.660322452961],
            [126.880362810851, 37.660463313186],
            [126.880229252556, 37.660524171851],
            [126.879952985772, 37.660502174009],
            [126.879747770895, 37.66048457356],
            [126.879444659077, 37.660322355318],
            [126.879388303455, 37.660292195456],
            [126.878992219522, 37.660259088386],
            [126.878943720333, 37.660255073717],
            [126.878903719795, 37.660251788641],
            [126.878476246352, 37.660216980406],
            [126.878014881991, 37.660322200505],
            [126.877416571966, 37.660458178199],
            [126.877310820805, 37.660540686176],
            [126.876938891295, 37.660830830916],
            [126.876774407261, 37.66109125467],
            [126.876347830908, 37.661775389445],
            [126.875718234698, 37.662174896018],
            [126.875725990063, 37.662206519442],
            [126.8757623745, 37.662425774048],
            [126.875870311865, 37.663018981725],
            [126.87591924347, 37.66299602298],
            [126.875964888547, 37.663174806631],
            [126.876136212463, 37.663575750716],
            [126.876290441041, 37.663912779371],
            [126.876456960565, 37.664247550386],
            [126.876759110692, 37.664644640817],
            [126.877097152851, 37.665058607498],
            [126.876722202438, 37.665318088021],
            [126.876601917609, 37.665425212508],
            [126.876567751444, 37.665462449567],
            [126.876171543358, 37.665730230788],
            [126.876093889612, 37.665733076406],
            [126.875621949913, 37.665769549572],
            [126.875123227586, 37.665806019183],
            [126.874329408094, 37.665931923768],
            [126.873816601352, 37.666037981817],
            [126.873795558517, 37.666227226366],
            [126.873723818184, 37.666416542127],
            [126.873575784973, 37.667049855485],
            [126.872958480826, 37.667523249008],
            [126.872822478365, 37.66764971436],
            [126.872869572233, 37.667767883218],
            [126.872938599061, 37.667938053064],
            [126.873405415371, 37.669038071583],
            [126.872646927996, 37.669730876784],
            [126.872696290268, 37.66992746876],
            [126.872851909595, 37.670547336199],
            [126.872909068653, 37.670771695511],
            [126.87291687519, 37.670806328375],
            [126.872908683403, 37.671027778346],
            [126.872862617815, 37.671875398474],
            [126.872006591836, 37.671962180269],
            [126.871638222102, 37.672005751652],
            [126.871199784706, 37.672901924094],
            [126.871131715756, 37.672948402072],
            [126.871106956174, 37.672963538051],
            [126.870654336948, 37.673240508965],
            [126.870575221497, 37.673290091723],
            [126.870017317097, 37.673477323606],
            [126.870001665416, 37.67348253179],
            [126.869972857045, 37.673492230157],
            [126.869831197207, 37.673539733477],
            [126.86952815786, 37.673638852997],
            [126.869397352272, 37.673682376636],
            [126.869402243178, 37.673800778776],
            [126.869418638494, 37.674175421063],
            [126.869427539264, 37.674269870853],
            [126.869429584946, 37.674388359891],
            [126.86943068679, 37.674425418077],
            [126.869438589697, 37.674654390777],
            [126.867907867494, 37.675116504765],
            [126.867149194501, 37.674787184823],
            [126.866468940579, 37.674504484971],
            [126.8661326766, 37.67464936308],
            [126.865909240861, 37.67473950085],
            [126.865172455504, 37.675043233787],
            [126.86442404378, 37.67568666877],
            [126.864084868783, 37.67567780492],
            [126.86393345423, 37.675969948467],
            [126.863765795946, 37.676328825777],
            [126.863761061183, 37.676670279244],
            [126.863752941349, 37.676856113039],
            [126.863747893701, 37.676888542097],
            [126.863724182285, 37.677028020736],
            [126.863694702253, 37.67726482451]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281117',
      properties: {
        rgnCode: '41281117',
        colCode: '41280',
        rgnKo: ['대자동'],
        rgnSize: 1,
        rgnBbox: [
          126.868239563439, 37.669390635545, 126.906242034246, 37.72620373677
        ],
        rgnCenter: [126.885954048048, 37.697949536368],
        rgnArea: 11358596,
        predVal: [
          0.13165, 0.13388, 0.1281, 0.14386, 0.18024, 0.16388, 0.1798, 0.18909,
          0.16284, 0.17143, 0.17085, 0.13082, 0.18803, 0.16713, 0.19007,
          0.07589, 0.1762, 0.16166, 0.20855, 0.18212, 0.18653, 0.18979, 0.16469,
          0.1865, 0.14929, 0.18193, 0.16629, 0.17122, 0.1674, 0.19174, 0.18429
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.868239563439, 37.695518428513],
            [126.868549807312, 37.695956487054],
            [126.868522165358, 37.696292111519],
            [126.868568901828, 37.696407127735],
            [126.868807574056, 37.696550371181],
            [126.868804345603, 37.696580459878],
            [126.868576244182, 37.696790993407],
            [126.868466791148, 37.697774871004],
            [126.868758163806, 37.697979133448],
            [126.869738269038, 37.698102935593],
            [126.869942765226, 37.698257265461],
            [126.87002189482, 37.698381876471],
            [126.870483441696, 37.699054268842],
            [126.871367118074, 37.699753010797],
            [126.872159183602, 37.700324888495],
            [126.872259491546, 37.70087279566],
            [126.872283333116, 37.701006309109],
            [126.872273192067, 37.701530940243],
            [126.87267717901, 37.702180790473],
            [126.872713127484, 37.702349887216],
            [126.873272750823, 37.703103291189],
            [126.874023421506, 37.703866197117],
            [126.874035679257, 37.703939341851],
            [126.87410001838, 37.704384795338],
            [126.87361146998, 37.704968606415],
            [126.87355131886, 37.705274419341],
            [126.873246083182, 37.705417728802],
            [126.873065394271, 37.705771026351],
            [126.87286776478, 37.705860006584],
            [126.872911584386, 37.706174113302],
            [126.873269389279, 37.706956361704],
            [126.873533691616, 37.707104064999],
            [126.87355340228, 37.707116501708],
            [126.873631245093, 37.707154192498],
            [126.873949540264, 37.707308332246],
            [126.874059914536, 37.707461075305],
            [126.874080807138, 37.707492001032],
            [126.874170955702, 37.70761535963],
            [126.874227291613, 37.707689975422],
            [126.874275740212, 37.707754762142],
            [126.874318733586, 37.708158297257],
            [126.874357994975, 37.708522843499],
            [126.874376696683, 37.708694695931],
            [126.874932626017, 37.708957439174],
            [126.874511539329, 37.709602681697],
            [126.874596145149, 37.709985620271],
            [126.87426529235, 37.710568864326],
            [126.874894209749, 37.711187045352],
            [126.875111218275, 37.711880913684],
            [126.875255533122, 37.712101003037],
            [126.875876704471, 37.71215409378],
            [126.876163910959, 37.71258793511],
            [126.8761708189, 37.71263434218],
            [126.876224501063, 37.71299524494],
            [126.875902998271, 37.713091513384],
            [126.875312435851, 37.713268345897],
            [126.874739556749, 37.713155921488],
            [126.874626497953, 37.713188342739],
            [126.874268439981, 37.713291009278],
            [126.873913491851, 37.713603359769],
            [126.873792524855, 37.713709803983],
            [126.873718864417, 37.714072362713],
            [126.873715967649, 37.714081720602],
            [126.873691404346, 37.714133373344],
            [126.87369783129, 37.71416847291],
            [126.873384730859, 37.714544015771],
            [126.873356689278, 37.714560472957],
            [126.873175031986, 37.714673526685],
            [126.87310304999, 37.714718406472],
            [126.872914833321, 37.714833344678],
            [126.872880977401, 37.714854029945],
            [126.872650902015, 37.715143880472],
            [126.872640354184, 37.71515712214],
            [126.872625473301, 37.715170710446],
            [126.872710385054, 37.715263557601],
            [126.872639802952, 37.715278932022],
            [126.872403992927, 37.715290566766],
            [126.87224758183, 37.715297332811],
            [126.87219131721, 37.715316569782],
            [126.871922004089, 37.715276163103],
            [126.871884239785, 37.71527449985],
            [126.871537753315, 37.715257081133],
            [126.871522016925, 37.715234395502],
            [126.871320823404, 37.715128057832],
            [126.871237778692, 37.715064655279],
            [126.871071188146, 37.714997511356],
            [126.87100714306, 37.714984502621],
            [126.870792427617, 37.715003284126],
            [126.87076995589, 37.715005727853],
            [126.870682802809, 37.715015190414],
            [126.870555318997, 37.715012030646],
            [126.870471740979, 37.715013613577],
            [126.870472590643, 37.715123622363],
            [126.870478290002, 37.715261926841],
            [126.870490716665, 37.715367029219],
            [126.870862914918, 37.715842845474],
            [126.871336529546, 37.716250506427],
            [126.871227670135, 37.716478474463],
            [126.871198369605, 37.716623758709],
            [126.871165218778, 37.716817402482],
            [126.871158701581, 37.716879678977],
            [126.87110677885, 37.716912155398],
            [126.870879391276, 37.717000765828],
            [126.870483035558, 37.717294193679],
            [126.870374633496, 37.717350428499],
            [126.869858954061, 37.717620179238],
            [126.86978767154, 37.717696447366],
            [126.869540421917, 37.718039924922],
            [126.86987085494, 37.719118181337],
            [126.870335507185, 37.719252717721],
            [126.870524928261, 37.719422165496],
            [126.870541486286, 37.719435896571],
            [126.870541120777, 37.719521100373],
            [126.870475347614, 37.719913901826],
            [126.870486794077, 37.719951304569],
            [126.870599110003, 37.720309202284],
            [126.870700354641, 37.72059743404],
            [126.870729432911, 37.720683247109],
            [126.870735647967, 37.720741618468],
            [126.870754555256, 37.720922508011],
            [126.870820451374, 37.721008145652],
            [126.871296724622, 37.721438324472],
            [126.871523944026, 37.721972684769],
            [126.871561167924, 37.722519340684],
            [126.871830600303, 37.723156844119],
            [126.871981211942, 37.723343878617],
            [126.872263534114, 37.723682401296],
            [126.872642133365, 37.72439608175],
            [126.87288759256, 37.724855787236],
            [126.87322751627, 37.725093804803],
            [126.873339981481, 37.725574654266],
            [126.873563633387, 37.725818292512],
            [126.87371355948, 37.725994070942],
            [126.874453170312, 37.725958345359],
            [126.875062471893, 37.725861822571],
            [126.875387522691, 37.725378776813],
            [126.876521330019, 37.725404490603],
            [126.877427335449, 37.725722676995],
            [126.87771805227, 37.72620373677],
            [126.878930128731, 37.725892729406],
            [126.879916832513, 37.726121875248],
            [126.880894075075, 37.725614014973],
            [126.882168627534, 37.725869293804],
            [126.882731930157, 37.725583086013],
            [126.883273010445, 37.725430556233],
            [126.883699477711, 37.725020774442],
            [126.883948506123, 37.725061025839],
            [126.88467111034, 37.72531455466],
            [126.885123594734, 37.724800281692],
            [126.885252915861, 37.724829249148],
            [126.885422666501, 37.724856238124],
            [126.88574506333, 37.725033855819],
            [126.886052837624, 37.725059916211],
            [126.886512109695, 37.72482158271],
            [126.886661527863, 37.724529464731],
            [126.886981875009, 37.724123964256],
            [126.887218096067, 37.723669693258],
            [126.887342471472, 37.723528128184],
            [126.887523795439, 37.723536457373],
            [126.888368787949, 37.723475465256],
            [126.8885718282, 37.72300905969],
            [126.888527886507, 37.722971816779],
            [126.888464185378, 37.722867766437],
            [126.888685803951, 37.722549974456],
            [126.888723046426, 37.722367366641],
            [126.888760839673, 37.722195426751],
            [126.888971727532, 37.722145813949],
            [126.889216264941, 37.722128568432],
            [126.889597191949, 37.722099171822],
            [126.89039221674, 37.721451662067],
            [126.890366234179, 37.720144013859],
            [126.890345590196, 37.719719721179],
            [126.890286899696, 37.719132074712],
            [126.890330628638, 37.71854426351],
            [126.890121771426, 37.718078357655],
            [126.89057546212, 37.717810739391],
            [126.891242636991, 37.717788111403],
            [126.891517552588, 37.717650304357],
            [126.891839709423, 37.717487061335],
            [126.891965256219, 37.717299516069],
            [126.892123106801, 37.71706363684],
            [126.892437770644, 37.716593390379],
            [126.892802278493, 37.716393775867],
            [126.892969669815, 37.716215286732],
            [126.892799596156, 37.715574931193],
            [126.892651089333, 37.715280773158],
            [126.892888000278, 37.715033388389],
            [126.893225010929, 37.714675654781],
            [126.89356134003, 37.714449307349],
            [126.893964197149, 37.714088370366],
            [126.894346928344, 37.713658372701],
            [126.894491170467, 37.713442695442],
            [126.894522101822, 37.713100879145],
            [126.894596317023, 37.712565169212],
            [126.894678267533, 37.711933405217],
            [126.894113830797, 37.711175909895],
            [126.893758939016, 37.710793288209],
            [126.893644438767, 37.710686049643],
            [126.89350926499, 37.710414446815],
            [126.893709670002, 37.710021385562],
            [126.894083604687, 37.709590938924],
            [126.894295691231, 37.709122872811],
            [126.894097037709, 37.709123439798],
            [126.893863419686, 37.709125064701],
            [126.893846680836, 37.709125184562],
            [126.893697014148, 37.709191412969],
            [126.893412923346, 37.709057323318],
            [126.893404378428, 37.709053288164],
            [126.893269437689, 37.708865799413],
            [126.893181940997, 37.708744259789],
            [126.893147929797, 37.708558043847],
            [126.89292042078, 37.708454475669],
            [126.892879057166, 37.708434553238],
            [126.892934046701, 37.708380392746],
            [126.892827369326, 37.708347778583],
            [126.892806814214, 37.708344272893],
            [126.892642297703, 37.708305947153],
            [126.892429558772, 37.708308390355],
            [126.892087192075, 37.708191848446],
            [126.891903574332, 37.708125898336],
            [126.891742236155, 37.708063104047],
            [126.891395315294, 37.707914238221],
            [126.891294263049, 37.707782782897],
            [126.891219152816, 37.707769513501],
            [126.891049319231, 37.707731549958],
            [126.891018520467, 37.707722808754],
            [126.890756895044, 37.707656567493],
            [126.8905197439, 37.707422120234],
            [126.890100345784, 37.707308184884],
            [126.88999101962, 37.70731960489],
            [126.889855570382, 37.707357929946],
            [126.889540986993, 37.707186925495],
            [126.889204127252, 37.707094751574],
            [126.889230895185, 37.706835920372],
            [126.888835798063, 37.70651643912],
            [126.889302405778, 37.706031659511],
            [126.889267409758, 37.705610901775],
            [126.889349347323, 37.705185633333],
            [126.889390586272, 37.704736964037],
            [126.889658513248, 37.704231920221],
            [126.889503081424, 37.703830481972],
            [126.889386260416, 37.703310278694],
            [126.889392774111, 37.703284562277],
            [126.88970632929, 37.702807770885],
            [126.890189767952, 37.702566931268],
            [126.890352737489, 37.702439670233],
            [126.89032595833, 37.702140352668],
            [126.890101980906, 37.70196581309],
            [126.889819753869, 37.701640071636],
            [126.889820282041, 37.701628143328],
            [126.889745561413, 37.701329491934],
            [126.889656500974, 37.701156565892],
            [126.889582806595, 37.701013233127],
            [126.889502891305, 37.700829504031],
            [126.889758082254, 37.700557626953],
            [126.889777644649, 37.700563736033],
            [126.890264412315, 37.700589297245],
            [126.890474059771, 37.700599738857],
            [126.891188033743, 37.700747149622],
            [126.891820077654, 37.700921959381],
            [126.892166751947, 37.700807966621],
            [126.892438468174, 37.700604077061],
            [126.892783771275, 37.700363621402],
            [126.892987819668, 37.700263252388],
            [126.893253546801, 37.700128000139],
            [126.893342552063, 37.700117504463],
            [126.893487720677, 37.700098951478],
            [126.893610803824, 37.700078089648],
            [126.89371911529, 37.700148887643],
            [126.893965091924, 37.700324755887],
            [126.894065548425, 37.700378157723],
            [126.894175470661, 37.700433415072],
            [126.894609260288, 37.700562845732],
            [126.894744357824, 37.700601286288],
            [126.894831535819, 37.700244041449],
            [126.894959821567, 37.700058188716],
            [126.89509423547, 37.699876314636],
            [126.895373118197, 37.699767684056],
            [126.895441152903, 37.699727994583],
            [126.895459512709, 37.699686972019],
            [126.895575267832, 37.699428273094],
            [126.895544674203, 37.699173902404],
            [126.895427657053, 37.698854313999],
            [126.895428182284, 37.698485728519],
            [126.895432012744, 37.69847128945],
            [126.895601344806, 37.698473910318],
            [126.895804648866, 37.698452217249],
            [126.896026237718, 37.698393897825],
            [126.896388080394, 37.698325405043],
            [126.896560607962, 37.69831319745],
            [126.896841699489, 37.698305347602],
            [126.897139371813, 37.698335541619],
            [126.897388947075, 37.698363998603],
            [126.89756270198, 37.698349781469],
            [126.897643083249, 37.698341923785],
            [126.89779344138, 37.698330920173],
            [126.897872916672, 37.698346648877],
            [126.897934838401, 37.698371200603],
            [126.898034467718, 37.698456988145],
            [126.898113658017, 37.698522927378],
            [126.89821183302, 37.698611119077],
            [126.898475038036, 37.698722240495],
            [126.898586925338, 37.69878382927],
            [126.898923246603, 37.698968172073],
            [126.898943723483, 37.698969180952],
            [126.899088476978, 37.698977739837],
            [126.899189546314, 37.698924868696],
            [126.899311088293, 37.698818155355],
            [126.89942641251, 37.698722374254],
            [126.899475508122, 37.698682602978],
            [126.899650204539, 37.698544059023],
            [126.89977527709, 37.698383578479],
            [126.899807688282, 37.698338765265],
            [126.899952903693, 37.698149200634],
            [126.900131036442, 37.698008604705],
            [126.900271204293, 37.697902645162],
            [126.900429878465, 37.697770357012],
            [126.900524145326, 37.697689891241],
            [126.900636222486, 37.69758636684],
            [126.900734311345, 37.697496579148],
            [126.900772276489, 37.697460951273],
            [126.900805007573, 37.697430382355],
            [126.900843257957, 37.69742693728],
            [126.900978162491, 37.69757289208],
            [126.901037245827, 37.697676076254],
            [126.901125052819, 37.697828072274],
            [126.9012342683, 37.698038739449],
            [126.901364098426, 37.698308491487],
            [126.901477306344, 37.698527784075],
            [126.901593584044, 37.698716076843],
            [126.901803988809, 37.698886429764],
            [126.901932005112, 37.699005925198],
            [126.902016825453, 37.699112905645],
            [126.902177406356, 37.699378294972],
            [126.902261817931, 37.699545810917],
            [126.902303477679, 37.699673540138],
            [126.902444229261, 37.699503889121],
            [126.902461209349, 37.699474225508],
            [126.902472330726, 37.699458891368],
            [126.902471939288, 37.699454809655],
            [126.902434861741, 37.699266404157],
            [126.902443791096, 37.699100372297],
            [126.902455962624, 37.6989263786],
            [126.902454482572, 37.698734876631],
            [126.902454191887, 37.698620994043],
            [126.902439491637, 37.698352060781],
            [126.902416532654, 37.698103266209],
            [126.902396834018, 37.69793517407],
            [126.902383234416, 37.69782770418],
            [126.902302165356, 37.69752712711],
            [126.902238713097, 37.69733179788],
            [126.902129698442, 37.69706276706],
            [126.902067526271, 37.696936867409],
            [126.901955200816, 37.696812808413],
            [126.901920179302, 37.696716798696],
            [126.901881921809, 37.696530986729],
            [126.901878558806, 37.696324220683],
            [126.901877235022, 37.696195011703],
            [126.902001943466, 37.695986515819],
            [126.90204240251, 37.69589937226],
            [126.902056110665, 37.695840073077],
            [126.902077402539, 37.695705666554],
            [126.902094654073, 37.695599547026],
            [126.902106291956, 37.695485719481],
            [126.902116684605, 37.695319409472],
            [126.902120954147, 37.695238262844],
            [126.902071235643, 37.695042548598],
            [126.902033918565, 37.694764243994],
            [126.90210326453, 37.694755427935],
            [126.902151043323, 37.694747782944],
            [126.902320309092, 37.694751439228],
            [126.903313071893, 37.694856818542],
            [126.904102810641, 37.695075850318],
            [126.904978782996, 37.69541734399],
            [126.905176559637, 37.695505332137],
            [126.905237037893, 37.695269562052],
            [126.905064539948, 37.694678989399],
            [126.905030132055, 37.694518363605],
            [126.905037449032, 37.694244204849],
            [126.905032816602, 37.693995389562],
            [126.904900049083, 37.693844107326],
            [126.904702191849, 37.69366477842],
            [126.904468498249, 37.693474689153],
            [126.904266337621, 37.693136731403],
            [126.903990356242, 37.692703804203],
            [126.903854942862, 37.69264546252],
            [126.903672588067, 37.69256283667],
            [126.903273120278, 37.692369562066],
            [126.903275950906, 37.692226400466],
            [126.903278502523, 37.69195094936],
            [126.903606859739, 37.691727665008],
            [126.903725755382, 37.691627945307],
            [126.903758203895, 37.691602465778],
            [126.903683075867, 37.691397712322],
            [126.904060468837, 37.69126722212],
            [126.904210124626, 37.691218071704],
            [126.904481223886, 37.690791596118],
            [126.905387755508, 37.690723576599],
            [126.905548865209, 37.690626754526],
            [126.905710265807, 37.690532626365],
            [126.906242034246, 37.690217817065],
            [126.906085993911, 37.689766377366],
            [126.905766396457, 37.689158928029],
            [126.905694976876, 37.689012624665],
            [126.905490047432, 37.688646457155],
            [126.905405689776, 37.688493773213],
            [126.905186121685, 37.688482043635],
            [126.905085711063, 37.688250565571],
            [126.905042899445, 37.688146801933],
            [126.904953183984, 37.68785167899],
            [126.904692499922, 37.687637412889],
            [126.904350212216, 37.687370642564],
            [126.904086604187, 37.687305528231],
            [126.904111935848, 37.686790807139],
            [126.903730668854, 37.68619351804],
            [126.903712510539, 37.686138913219],
            [126.903701533385, 37.686097937016],
            [126.90368154728, 37.685855406376],
            [126.903460090834, 37.685561866647],
            [126.903352764575, 37.685414928268],
            [126.903388685425, 37.685393641213],
            [126.903936394373, 37.685023031176],
            [126.90443340168, 37.684659458467],
            [126.904050974315, 37.684253526288],
            [126.903866048188, 37.684297196501],
            [126.903383995217, 37.684134801941],
            [126.903185279321, 37.684061396559],
            [126.903016418363, 37.683949985829],
            [126.902841314816, 37.683870211706],
            [126.902518091901, 37.683726434291],
            [126.90238123436, 37.683676000143],
            [126.902069184157, 37.68358839737],
            [126.901830584778, 37.683077354799],
            [126.901761085656, 37.682971603207],
            [126.901706258606, 37.682906398644],
            [126.901581581484, 37.682845360439],
            [126.901316586639, 37.682746173011],
            [126.901072591415, 37.682669500104],
            [126.900700475739, 37.68235497818],
            [126.900492751473, 37.682158587089],
            [126.900296422559, 37.681736088843],
            [126.900295314066, 37.681717581958],
            [126.9002281526, 37.68167098917],
            [126.899991546747, 37.681599149562],
            [126.899598811349, 37.681315951126],
            [126.899581254798, 37.681305160345],
            [126.899509085263, 37.681273834267],
            [126.899068563377, 37.681161029318],
            [126.898737031303, 37.68088289955],
            [126.898631100404, 37.680779682088],
            [126.898403064354, 37.680563862641],
            [126.898361129541, 37.680532661394],
            [126.898296252636, 37.680483393613],
            [126.898287872043, 37.680461114283],
            [126.898167208896, 37.680208232267],
            [126.898086464713, 37.680058870633],
            [126.898043974166, 37.679979187548],
            [126.897982747005, 37.679914453124],
            [126.89767406894, 37.679667261693],
            [126.897651787544, 37.67963090592],
            [126.897619810652, 37.679564314032],
            [126.897461119929, 37.679245725985],
            [126.8976276265, 37.678893908491],
            [126.897572113589, 37.678618153461],
            [126.897538227458, 37.678579733219],
            [126.897571431291, 37.678554688465],
            [126.897618638359, 37.678519069629],
            [126.897720418352, 37.678465785725],
            [126.897825594974, 37.678407225029],
            [126.897529965975, 37.677562772884],
            [126.897558770303, 37.677164569024],
            [126.89762204219, 37.676411403805],
            [126.897616424206, 37.676278136189],
            [126.897621702059, 37.676216343215],
            [126.897587614496, 37.675949265273],
            [126.897571888203, 37.67584645058],
            [126.897718759541, 37.675783322725],
            [126.897761305224, 37.675764466789],
            [126.89920797561, 37.675325912326],
            [126.899110299598, 37.675036282346],
            [126.899357211267, 37.674884196241],
            [126.899314785811, 37.674716885105],
            [126.899235209491, 37.674520377288],
            [126.899083626736, 37.674146261423],
            [126.898765109271, 37.673749628902],
            [126.898619686477, 37.673563541558],
            [126.898473782685, 37.673356947492],
            [126.898410607291, 37.673270272855],
            [126.898198055258, 37.672948602093],
            [126.898170540843, 37.672910782219],
            [126.898139389735, 37.672871643732],
            [126.897963822937, 37.67267460935],
            [126.897812769326, 37.67249768793],
            [126.897742401043, 37.67241676381],
            [126.897544823113, 37.672228412811],
            [126.897519004486, 37.672218578465],
            [126.89722006052, 37.671988972087],
            [126.896932530306, 37.671817550827],
            [126.89674512703, 37.671715286361],
            [126.896554730304, 37.671630227498],
            [126.895851103402, 37.671409347622],
            [126.894984695553, 37.671153142681],
            [126.894057157566, 37.671004541859],
            [126.893498488045, 37.670990066487],
            [126.893106562008, 37.670969435039],
            [126.892383013721, 37.670802790709],
            [126.892245259173, 37.670758821867],
            [126.891963813241, 37.670661769358],
            [126.89185796785, 37.670632218221],
            [126.891715607799, 37.670591127589],
            [126.891289240648, 37.670469224804],
            [126.890887617956, 37.670416385186],
            [126.890578353795, 37.670353846546],
            [126.890471158843, 37.670333059354],
            [126.890212453422, 37.670259251131],
            [126.890169163102, 37.670246281322],
            [126.88975667829, 37.670113988878],
            [126.889312466728, 37.669998512994],
            [126.888368658375, 37.66975831317],
            [126.887981337587, 37.669673673052],
            [126.887791366312, 37.669624323984],
            [126.887433833969, 37.669532331821],
            [126.887321614514, 37.66943367488],
            [126.88729541219, 37.669409467413],
            [126.887275208938, 37.669390635545],
            [126.887174344707, 37.669456056516],
            [126.886949413362, 37.669565126192],
            [126.886880471166, 37.669583439042],
            [126.886781804026, 37.669620192873],
            [126.886735971271, 37.66972051659],
            [126.886619446534, 37.66972400703],
            [126.886452046782, 37.669642936538],
            [126.88573032989, 37.669818280254],
            [126.885468684499, 37.669933392614],
            [126.885310662853, 37.670002656944],
            [126.884986868353, 37.670125049908],
            [126.884546806009, 37.670302646426],
            [126.884243978353, 37.670424877938],
            [126.884086552948, 37.670488420042],
            [126.884071584515, 37.670492639707],
            [126.884056650056, 37.670496877424],
            [126.883978585396, 37.670527324594],
            [126.883715060519, 37.67058764306],
            [126.883619747113, 37.670587592878],
            [126.883591343, 37.670587186075],
            [126.883571723115, 37.670637017243],
            [126.883473624081, 37.670885677494],
            [126.883529184556, 37.670908698862],
            [126.883477013122, 37.670964425841],
            [126.883325454097, 37.671258711722],
            [126.88318542598, 37.671428764929],
            [126.882961734165, 37.671820012603],
            [126.882866899717, 37.672080568406],
            [126.882757261157, 37.672597165399],
            [126.882674666791, 37.672999906673],
            [126.882649997467, 37.673258370592],
            [126.882638384584, 37.673574599935],
            [126.882627278592, 37.673828824907],
            [126.882626130178, 37.673873683124],
            [126.882621369022, 37.674648270565],
            [126.882632782825, 37.674741172226],
            [126.88266606456, 37.675024687415],
            [126.882679885421, 37.675129394217],
            [126.882681637704, 37.675494829963],
            [126.882684483109, 37.675643961602],
            [126.882687195191, 37.675762973632],
            [126.882694047008, 37.675994295898],
            [126.882685466313, 37.676086510669],
            [126.882665531371, 37.6763041382],
            [126.882644780978, 37.676563876289],
            [126.88255661873, 37.67683694383],
            [126.882830451204, 37.67735107955],
            [126.883058774289, 37.677765332298],
            [126.883166296987, 37.67798484469],
            [126.883385595693, 37.678537972368],
            [126.883441796958, 37.67866093909],
            [126.883453104241, 37.678686168583],
            [126.883528896329, 37.678850770712],
            [126.883606712314, 37.679083362066],
            [126.883685487769, 37.679318846435],
            [126.883729502074, 37.679450116788],
            [126.883813905841, 37.679696886831],
            [126.883865474059, 37.679847400387],
            [126.883931607581, 37.680034985361],
            [126.884024666057, 37.680315234916],
            [126.884332893509, 37.680526305617],
            [126.884877379361, 37.680883458505],
            [126.885143910967, 37.681076412358],
            [126.885478584802, 37.681357853908],
            [126.885661587249, 37.681489097988],
            [126.885793602414, 37.681640239249],
            [126.885832647907, 37.681740510511],
            [126.885842839869, 37.68182777048],
            [126.885701602062, 37.68239490982],
            [126.885648114181, 37.682600458699],
            [126.885625709922, 37.682792028007],
            [126.885520887868, 37.683129653637],
            [126.885408972683, 37.683398888538],
            [126.885284010504, 37.683564904337],
            [126.885015165801, 37.683779475388],
            [126.884357073368, 37.68424584128],
            [126.883708556954, 37.684530081953],
            [126.883519482414, 37.684543794897],
            [126.883019811627, 37.684579639034],
            [126.882840225892, 37.684593270326],
            [126.881895931185, 37.684800980948],
            [126.880933788196, 37.68504993918],
            [126.879672858114, 37.685421014019],
            [126.879065644157, 37.685549169733],
            [126.878652256979, 37.685745889986],
            [126.878072498505, 37.686023567075],
            [126.877071304213, 37.686381398086],
            [126.87683482942, 37.686418565585],
            [126.876463778768, 37.686478429566],
            [126.876078214117, 37.686539412163],
            [126.875276844341, 37.686943741092],
            [126.874384815905, 37.687200379043],
            [126.873680106737, 37.68739769539],
            [126.873668816167, 37.687403521433],
            [126.873826633362, 37.688334420772],
            [126.873831009588, 37.688380717311],
            [126.873815712173, 37.688415676374],
            [126.873804223048, 37.688438034999],
            [126.873788803359, 37.688438504823],
            [126.873764313131, 37.688512033425],
            [126.873709614354, 37.688522740741],
            [126.873638353049, 37.688578811894],
            [126.873585604261, 37.688615910655],
            [126.872924420372, 37.689132131632],
            [126.872892668784, 37.689871143925],
            [126.872703286745, 37.689988108238],
            [126.872289238821, 37.69024770193],
            [126.872316832541, 37.691176964449],
            [126.872303865862, 37.69146228706],
            [126.871748635547, 37.691848039819],
            [126.871383663398, 37.692161805419],
            [126.870968895384, 37.692305231687],
            [126.870626978235, 37.69292922391],
            [126.870123566201, 37.693122282222],
            [126.869795433633, 37.693510918308],
            [126.869577737151, 37.693768748337],
            [126.86900199679, 37.694015328499],
            [126.868803315517, 37.695008233388],
            [126.868239563439, 37.695518428513]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41285104',
      properties: {
        rgnCode: '41285104',
        colCode: '41280',
        rgnKo: ['장항동'],
        rgnSize: 1,
        rgnBbox: [
          126.732423905252, 37.629688243071, 126.782999111168, 37.666095487107
        ],
        rgnCenter: [126.759359584826, 37.647419338801],
        rgnArea: 11270112,
        predVal: [
          0.39496, 0.40163, 0.3843, 0.43159, 0.54071, 0.49164, 0.53939, 0.60558,
          0.55523, 0.59295, 0.56673, 0.39245, 0.20188, 0.34901, 0.38552,
          0.22766, 0.26184, 0.44661, 0.62564, 0.45053, 0.47376, 0.56312,
          0.49407, 0.55951, 0.44788, 0.54579, 0.49886, 0.51365, 0.50219,
          0.52331, 0.37852
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.732423905252, 37.647478315752],
            [126.733657514642, 37.648542406699],
            [126.734601175285, 37.649349988597],
            [126.735239087131, 37.649839667533],
            [126.735411140732, 37.649971057249],
            [126.735705752696, 37.650195883541],
            [126.736042405191, 37.650452968443],
            [126.736166519421, 37.65055514773],
            [126.736171170081, 37.650569753955],
            [126.736205675561, 37.650681056692],
            [126.736286988878, 37.650742325505],
            [126.736473262164, 37.650811488384],
            [126.736575601516, 37.650823385859],
            [126.736644186229, 37.650831423377],
            [126.736756084243, 37.650832052962],
            [126.73686118186, 37.650912907851],
            [126.737282107314, 37.651047591819],
            [126.737607462493, 37.651159869566],
            [126.737645269248, 37.651219553503],
            [126.73780447427, 37.651397446228],
            [126.737777268439, 37.651432208019],
            [126.737801639937, 37.651493979154],
            [126.737867429198, 37.651523678114],
            [126.737889502738, 37.651530304545],
            [126.737928468768, 37.651541041114],
            [126.738202075244, 37.651598620638],
            [126.738133821168, 37.65183362223],
            [126.738116441662, 37.651893588229],
            [126.73833443407, 37.651950836038],
            [126.738350261393, 37.651954970772],
            [126.738493953242, 37.651992366259],
            [126.738491501776, 37.651999865898],
            [126.7384845278, 37.652014103742],
            [126.738529190244, 37.652041998328],
            [126.738825999477, 37.652227088768],
            [126.739047973743, 37.652393001458],
            [126.739369944204, 37.652609860745],
            [126.739657004522, 37.65282943468],
            [126.739682497787, 37.652830482334],
            [126.739883543801, 37.652954902239],
            [126.740151358821, 37.653127788917],
            [126.740582458734, 37.65342045119],
            [126.740624287447, 37.653449014441],
            [126.740670306821, 37.653478217623],
            [126.74079694504, 37.653558594044],
            [126.740955127342, 37.653659789405],
            [126.740963946891, 37.653665367886],
            [126.741279598199, 37.653877703111],
            [126.741490885232, 37.654024126588],
            [126.741585732988, 37.654089836323],
            [126.742021808999, 37.654378837311],
            [126.742034691881, 37.654389136736],
            [126.742065128438, 37.654435243382],
            [126.742162011995, 37.654502398685],
            [126.742278116852, 37.654582119668],
            [126.742377057508, 37.654611798852],
            [126.742425415156, 37.654653349808],
            [126.742437504889, 37.654663647447],
            [126.742771467144, 37.654892056181],
            [126.742890738155, 37.654974126031],
            [126.743056057275, 37.655077739802],
            [126.743190621426, 37.655063438786],
            [126.744217225518, 37.654929275081],
            [126.744281169951, 37.6549204949],
            [126.745420675592, 37.654775845267],
            [126.745483455379, 37.65510618801],
            [126.745525420755, 37.655326837174],
            [126.745569181917, 37.655552625754],
            [126.745595014604, 37.655686205929],
            [126.74560541573, 37.655739629134],
            [126.745649915628, 37.655968239316],
            [126.745671304674, 37.65607586182],
            [126.745702566961, 37.656245492633],
            [126.74576321157, 37.656538259968],
            [126.745886981764, 37.657127764158],
            [126.745656141389, 37.657159248231],
            [126.745724000795, 37.657215525967],
            [126.745725029091, 37.65721639313],
            [126.744768277933, 37.6573426492],
            [126.743580753154, 37.657499260451],
            [126.743317146973, 37.657534272183],
            [126.74335252297, 37.65759453455],
            [126.743368858562, 37.657622500436],
            [126.743587904525, 37.657921741944],
            [126.743819722062, 37.658264257593],
            [126.74413091573, 37.658725333711],
            [126.744505257342, 37.659279346687],
            [126.744607874224, 37.659428230586],
            [126.744693481226, 37.659547345287],
            [126.744867285568, 37.659790535478],
            [126.744898864323, 37.659835202287],
            [126.744931115714, 37.659882014862],
            [126.744963367115, 37.659928836438],
            [126.745171761936, 37.66023132227],
            [126.745430000619, 37.660606687426],
            [126.74568934429, 37.660991064111],
            [126.745859606415, 37.661244606283],
            [126.746209216154, 37.661747204668],
            [126.746328759536, 37.661919548911],
            [126.746566733108, 37.662258828786],
            [126.746783277016, 37.662568329831],
            [126.746997994605, 37.662882151187],
            [126.747143798875, 37.663095094949],
            [126.747225346015, 37.663214018802],
            [126.747257937804, 37.663261570249],
            [126.747359213598, 37.663408106253],
            [126.747444925373, 37.663532354653],
            [126.747589170938, 37.66374115002],
            [126.747761612643, 37.663990729765],
            [126.74787924322, 37.66416108613],
            [126.747966197906, 37.664286958558],
            [126.748052814714, 37.664412289612],
            [126.748229207021, 37.664667553257],
            [126.748359133205, 37.66485568466],
            [126.748428608073, 37.664956228959],
            [126.748461766637, 37.665004204734],
            [126.750858887897, 37.663954375589],
            [126.750858853835, 37.663954393536],
            [126.750858899109, 37.663954411652],
            [126.751015865992, 37.663884901791],
            [126.751785145951, 37.663544226856],
            [126.751805179473, 37.663535358659],
            [126.751963262981, 37.663473706365],
            [126.752092313762, 37.663417578496],
            [126.752116710643, 37.663408710462],
            [126.752164500345, 37.663386197108],
            [126.752715692972, 37.663146540289],
            [126.752834493514, 37.663094885831],
            [126.752966391738, 37.663037546691],
            [126.753102807076, 37.662978234778],
            [126.75323477298, 37.66292086845],
            [126.75325648543, 37.662911426917],
            [126.75358166069, 37.66277005464],
            [126.753947115322, 37.662611179037],
            [126.753973344831, 37.662599773723],
            [126.754161184592, 37.662518116269],
            [126.754379486825, 37.662423205978],
            [126.754417009227, 37.66240689591],
            [126.75506383123, 37.662125704336],
            [126.755288441561, 37.662028057569],
            [126.755335590301, 37.662096125282],
            [126.755454477589, 37.66226779221],
            [126.755482191721, 37.662307808112],
            [126.755714362139, 37.662643048146],
            [126.755763810392, 37.662715147817],
            [126.755966702622, 37.663010980906],
            [126.756117801328, 37.663231285568],
            [126.756171965134, 37.663310269284],
            [126.756206852536, 37.663361120597],
            [126.75623298704, 37.66339923198],
            [126.756237115331, 37.663405250045],
            [126.756318575317, 37.663524176455],
            [126.75632062818, 37.663527171948],
            [126.756325256494, 37.663532785611],
            [126.756382828234, 37.663602622441],
            [126.756660987514, 37.664006835378],
            [126.756786419662, 37.664189091907],
            [126.75736305392, 37.66502710904],
            [126.757887360127, 37.665789063316],
            [126.757977152392, 37.665919439075],
            [126.75799497517, 37.66594542384],
            [126.758090182198, 37.666083739234],
            [126.759704046168, 37.665379886349],
            [126.759873166182, 37.665304281039],
            [126.760040141773, 37.665225787987],
            [126.760204972637, 37.665144497298],
            [126.760367545425, 37.665060408749],
            [126.760527860127, 37.664973522351],
            [126.760685802812, 37.664884018073],
            [126.760841373763, 37.664791805828],
            [126.760994346007, 37.66469697526],
            [126.761144719537, 37.664599526379],
            [126.761292494059, 37.664499549291],
            [126.761437442602, 37.664397133642],
            [126.761579678792, 37.664292189574],
            [126.761719088709, 37.664184897062],
            [126.76185544568, 37.664075255653],
            [126.761988976087, 37.663963355916],
            [126.762119339924, 37.66384919717],
            [126.762246650529, 37.663732779654],
            [126.762370793991, 37.663614283343],
            [126.76249165698, 37.663493708017],
            [126.762555219341, 37.663428966839],
            [126.762694510695, 37.663286985504],
            [126.76722430241, 37.666095487107],
            [126.767646635135, 37.665667534918],
            [126.767761326785, 37.665594107844],
            [126.768027446117, 37.665323100138],
            [126.76837557146, 37.664969932161],
            [126.768723716568, 37.664613006118],
            [126.769436374906, 37.66388791192],
            [126.77009799057, 37.663215582207],
            [126.770521745986, 37.662782396623],
            [126.770619386568, 37.662638873759],
            [126.770781601192, 37.662474538548],
            [126.77098600221, 37.662602074329],
            [126.771561056427, 37.662016123414],
            [126.771695788883, 37.661876014543],
            [126.771827126681, 37.661734006863],
            [126.771955070108, 37.661590010288],
            [126.772079505841, 37.66144402461],
            [126.77220054639, 37.661296320349],
            [126.772317965659, 37.661146716882],
            [126.77243187616, 37.660995484729],
            [126.77254216513, 37.660842443488],
            [126.772648831753, 37.660687863459],
            [126.77275164966, 37.660531654115],
            [126.772850844976, 37.660373996099],
            [126.772946304664, 37.660214799103],
            [126.775906412081, 37.655154057497],
            [126.778294915382, 37.651069803313],
            [126.778381353109, 37.650926081314],
            [126.778471865299, 37.650783898607],
            [126.778566225036, 37.65064334485],
            [126.778664545618, 37.65050442025],
            [126.778766713176, 37.650367304775],
            [126.778872614113, 37.650232088299],
            [126.778982248685, 37.650098680714],
            [126.779095503028, 37.649967261992],
            [126.779212490178, 37.649837922432],
            [126.779332983766, 37.649710571498],
            [126.77945709656, 37.649585389589],
            [126.779584488344, 37.649462466149],
            [126.779715385739, 37.649341801594],
            [126.779849562115, 37.649223395487],
            [126.779987016664, 37.649107518108],
            [126.780127636608, 37.648993989039],
            [126.78027142141, 37.648882988463],
            [126.781038796625, 37.648303296675],
            [126.780859299493, 37.648153264215],
            [126.777050933583, 37.644965414691],
            [126.776766115985, 37.644727015135],
            [126.776291989205, 37.644330132573],
            [126.776352647192, 37.644294758954],
            [126.776405870492, 37.644263704808],
            [126.776502646159, 37.644207254141],
            [126.77665695795, 37.644117252644],
            [126.776796320694, 37.644035952918],
            [126.776882766745, 37.643985527802],
            [126.776947283374, 37.643947899775],
            [126.777095952914, 37.643861175517],
            [126.777261216695, 37.643764725055],
            [126.777272703407, 37.643758025615],
            [126.777297515621, 37.643743549057],
            [126.779104059128, 37.642689656264],
            [126.779212017764, 37.642620484646],
            [126.779539127256, 37.642353961404],
            [126.779550784153, 37.642343234701],
            [126.779707606316, 37.642279046838],
            [126.779786316879, 37.642214775062],
            [126.779768471475, 37.642157619696],
            [126.779699721245, 37.642004378735],
            [126.779653880056, 37.641904860917],
            [126.779677838907, 37.641887418038],
            [126.779770154457, 37.641820117542],
            [126.779810143225, 37.641784423975],
            [126.779952723075, 37.641654951375],
            [126.780174160146, 37.641487605407],
            [126.780614646109, 37.64115488988],
            [126.780676534094, 37.641108155017],
            [126.78071866384, 37.641076159143],
            [126.781409649049, 37.64055327174],
            [126.781430758853, 37.640537300785],
            [126.781466925992, 37.640509933541],
            [126.781525474676, 37.640465633642],
            [126.78136090905, 37.640291438415],
            [126.781271228246, 37.640196578515],
            [126.78108316773, 37.63999855328],
            [126.780665710223, 37.639559177698],
            [126.780544672273, 37.639429075439],
            [126.780461967921, 37.639339678881],
            [126.780308368356, 37.639175233241],
            [126.780203672862, 37.639063045631],
            [126.779928551313, 37.638768540886],
            [126.779563646469, 37.638377732512],
            [126.779300501594, 37.638095952563],
            [126.779245672351, 37.638040448284],
            [126.779223127735, 37.638017106577],
            [126.779513462634, 37.637720376237],
            [126.779674121624, 37.637555169147],
            [126.77966252741, 37.637529829925],
            [126.779806984657, 37.63739500012],
            [126.779859127038, 37.637309955862],
            [126.779963917465, 37.637198386611],
            [126.780231910636, 37.636991214564],
            [126.780438440194, 37.636802937344],
            [126.780798817061, 37.636501965251],
            [126.781195067636, 37.636290525959],
            [126.781614169769, 37.635936052901],
            [126.781615770368, 37.635934983723],
            [126.78192727705, 37.635733312734],
            [126.782233161572, 37.635505033646],
            [126.782296793209, 37.635445146867],
            [126.782540007007, 37.635218912858],
            [126.782779547919, 37.635035440875],
            [126.782804393162, 37.635027666457],
            [126.782898954675, 37.634998091462],
            [126.782982390483, 37.634957008337],
            [126.782999111168, 37.634934244602],
            [126.782968814337, 37.634911844276],
            [126.782941450756, 37.634901405332],
            [126.782875667265, 37.634876317397],
            [126.782868603742, 37.634882025508],
            [126.782860188849, 37.634888821296],
            [126.782755267552, 37.634854389712],
            [126.782364903945, 37.635187116658],
            [126.782326232072, 37.635219660166],
            [126.782303721697, 37.635200049142],
            [126.782281201487, 37.635179933548],
            [126.78209056147, 37.635014880703],
            [126.781945124272, 37.634888923949],
            [126.781796407939, 37.634760978759],
            [126.781600937293, 37.634582761863],
            [126.781435403932, 37.634433702021],
            [126.78170840919, 37.634327895701],
            [126.78172883078, 37.634318023002],
            [126.781676919499, 37.63426837259],
            [126.781112144754, 37.633870979426],
            [126.78110533429, 37.633875381489],
            [126.780761516049, 37.634021778094],
            [126.780713588508, 37.634023580466],
            [126.78056601838, 37.634083759742],
            [126.78056217098, 37.634043974427],
            [126.780558884968, 37.634005902018],
            [126.780546274325, 37.633864785487],
            [126.780536733407, 37.633758272198],
            [126.780528734554, 37.633666627907],
            [126.780515039951, 37.633509021464],
            [126.780485880977, 37.633179389621],
            [126.780457199962, 37.632841469662],
            [126.780435774436, 37.632578434434],
            [126.780405899483, 37.632223033244],
            [126.780377474849, 37.631875293045],
            [126.780374971221, 37.63184087104],
            [126.780995305642, 37.631812840366],
            [126.78164215361, 37.631783234081],
            [126.781977757376, 37.631767911684],
            [126.782005502809, 37.631655791688],
            [126.78211273946, 37.631594634612],
            [126.78215128775, 37.631584254952],
            [126.782150805167, 37.631440097756],
            [126.782150915596, 37.631402527225],
            [126.7821999843, 37.631393464514],
            [126.782169647929, 37.631350395487],
            [126.782169887085, 37.631230475906],
            [126.782170658598, 37.630701991291],
            [126.781816169211, 37.630819287696],
            [126.781722502678, 37.630850512306],
            [126.780528391988, 37.631027666474],
            [126.780354663196, 37.631055640664],
            [126.780174855349, 37.631082071534],
            [126.779877474483, 37.631132670788],
            [126.779778958011, 37.631141035968],
            [126.779451131488, 37.631238986331],
            [126.778758528079, 37.63125809697],
            [126.777020204074, 37.631303905711],
            [126.776557104541, 37.631316628468],
            [126.776296045503, 37.631320419118],
            [126.775873260486, 37.631330774486],
            [126.775736598496, 37.631399392743],
            [126.775342084975, 37.631527116484],
            [126.77454364106, 37.631785830447],
            [126.77421093073, 37.63185492558],
            [126.773956493915, 37.63192662141],
            [126.773554259299, 37.632039620325],
            [126.773492743336, 37.632058304844],
            [126.773323585635, 37.632101341833],
            [126.772663674875, 37.632262212532],
            [126.771336993838, 37.632700524823],
            [126.771183251512, 37.632736417034],
            [126.770270238629, 37.632956775991],
            [126.769716808398, 37.633106008981],
            [126.769086219761, 37.633275242243],
            [126.768540717578, 37.633717635987],
            [126.767587490017, 37.633905216303],
            [126.766907165557, 37.634161157334],
            [126.766279409178, 37.634494475965],
            [126.76559639015, 37.634922328283],
            [126.764138924883, 37.63530022067],
            [126.764017634321, 37.635331907181],
            [126.762871035154, 37.635325989775],
            [126.762630929959, 37.634916361694],
            [126.760496583828, 37.63130811615],
            [126.759515853175, 37.629688243071],
            [126.758271395931, 37.63030085313],
            [126.757221138236, 37.63081606921],
            [126.756551953397, 37.631299992853],
            [126.754954039164, 37.632020058635],
            [126.751550655165, 37.632656915645],
            [126.745877190705, 37.635286511616],
            [126.741797789559, 37.638476245389],
            [126.740195056741, 37.64027731427],
            [126.736153835167, 37.64314785392],
            [126.73450810261, 37.646401972813],
            [126.732423905252, 37.647478315752]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281113',
      properties: {
        rgnCode: '41281113',
        colCode: '41280',
        rgnKo: ['용두동'],
        rgnSize: 1,
        rgnBbox: [
          126.8698442866, 37.609589023604, 126.908921118918, 37.641496494765
        ],
        rgnCenter: [126.889848144795, 37.624668328545],
        rgnArea: 7099236,
        predVal: [
          0.39496, 0.40163, 0.3843, 0.43159, 0.54071, 0.49164, 0.53939, 0.56727,
          0.48852, 0.51429, 0.51256, 0.39245, 0.56409, 0.50139, 0.5702, 0.22766,
          0.5286, 0.48499, 0.62564, 0.54635, 0.5596, 0.56936, 0.49407, 0.55951,
          0.44788, 0.54579, 0.49886, 0.51365, 0.50219, 0.57521, 0.55288
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.880672531469, 37.641464390916],
            [126.880672542799, 37.641464390927],
            [126.880721002962, 37.641471026691],
            [126.8807150515, 37.641472921658],
            [126.8808153656, 37.641496494765],
            [126.880944194578, 37.641438108065],
            [126.881070252691, 37.641201018702],
            [126.881029722165, 37.641083165619],
            [126.880862740346, 37.640864192803],
            [126.880751214321, 37.640775080227],
            [126.880595322665, 37.640649171245],
            [126.880454453171, 37.640538531005],
            [126.880385544746, 37.640467859745],
            [126.880330249576, 37.640407653758],
            [126.880316681354, 37.640362572966],
            [126.88041302407, 37.640249148828],
            [126.880430362224, 37.640149491567],
            [126.880440896026, 37.640102570499],
            [126.880584524113, 37.639985689931],
            [126.880766302801, 37.6399300606],
            [126.880845107863, 37.639913184843],
            [126.880932126947, 37.639889433965],
            [126.881095925267, 37.63984028179],
            [126.881114431332, 37.639816659045],
            [126.88109103588, 37.63975985561],
            [126.881201320558, 37.639578223079],
            [126.881212138663, 37.63955840361],
            [126.881237161322, 37.639512479303],
            [126.88114126665, 37.639377658435],
            [126.881048675808, 37.639266896943],
            [126.880925434281, 37.639123263444],
            [126.880854764282, 37.638874332356],
            [126.880843968855, 37.638852112233],
            [126.880769301378, 37.638700311322],
            [126.880682280502, 37.638530108768],
            [126.880640821292, 37.638413299682],
            [126.880691224871, 37.638282060937],
            [126.880697233943, 37.638258425453],
            [126.880714343719, 37.638159344507],
            [126.88075210583, 37.638019353309],
            [126.880814518644, 37.637930022229],
            [126.880844538975, 37.637883130049],
            [126.880863695844, 37.637856156367],
            [126.880915435657, 37.637780932627],
            [126.880956142271, 37.637718473446],
            [126.881015942526, 37.63764703299],
            [126.881118425587, 37.637538002251],
            [126.881207692752, 37.637453284328],
            [126.881218242642, 37.637438005503],
            [126.881224266018, 37.6373983236],
            [126.881233414246, 37.63726156459],
            [126.881253560193, 37.637190119614],
            [126.881303884563, 37.637008407754],
            [126.881313803919, 37.636941412183],
            [126.8813246303, 37.636712223606],
            [126.881327867807, 37.63668579223],
            [126.881383595395, 37.636546575914],
            [126.881406181407, 37.636458222044],
            [126.881413351418, 37.636416964581],
            [126.881415516216, 37.636409389565],
            [126.881414353274, 37.636392828418],
            [126.881411677302, 37.636373085281],
            [126.881403103973, 37.636371157472],
            [126.881336754364, 37.636352574824],
            [126.881304709701, 37.636348550851],
            [126.88126906361, 37.636287446283],
            [126.881379873039, 37.636142330622],
            [126.881467235877, 37.636035869958],
            [126.881547717867, 37.63600918378],
            [126.881721918652, 37.63596731216],
            [126.881826829204, 37.635882582597],
            [126.881935955803, 37.635789694357],
            [126.882024989148, 37.635714679079],
            [126.882049597976, 37.635727975396],
            [126.882041947142, 37.635737229697],
            [126.882041311211, 37.6358159925],
            [126.882049988054, 37.636015063235],
            [126.88212842529, 37.636267803714],
            [126.882158657086, 37.636364004622],
            [126.882212338902, 37.636482807727],
            [126.882274624623, 37.636619990429],
            [126.882291717859, 37.636657344202],
            [126.882310828298, 37.636687275957],
            [126.882326497925, 37.636708014265],
            [126.882402877742, 37.636753113233],
            [126.882480865767, 37.636819945346],
            [126.882571937062, 37.636881258596],
            [126.882614204013, 37.636990229349],
            [126.88271730584, 37.637081151711],
            [126.882798863172, 37.637190801642],
            [126.882839985273, 37.637229206621],
            [126.882972066884, 37.637349944209],
            [126.883035090998, 37.637451547643],
            [126.88311423667, 37.637546481971],
            [126.883270660512, 37.637685488431],
            [126.883395779998, 37.637846546295],
            [126.883453004316, 37.63787652502],
            [126.883604470442, 37.637913012895],
            [126.883641901475, 37.637921708686],
            [126.883674411757, 37.637954239406],
            [126.883732121994, 37.63800667083],
            [126.883803746056, 37.638058593542],
            [126.883900633679, 37.638133291068],
            [126.88397785691, 37.638212699055],
            [126.884184380443, 37.638354051681],
            [126.884228547251, 37.638373610758],
            [126.884273801416, 37.638393378123],
            [126.884358750722, 37.638430465634],
            [126.884471347425, 37.638487591213],
            [126.884635665854, 37.638573085641],
            [126.884778190469, 37.638699192341],
            [126.884831228701, 37.638704398395],
            [126.884848777873, 37.638639680552],
            [126.884864540299, 37.638572789584],
            [126.88487042945, 37.638552559462],
            [126.884816261078, 37.638458678148],
            [126.88465616671, 37.638258719018],
            [126.884769576696, 37.638194303313],
            [126.884840661541, 37.638164154858],
            [126.885017569988, 37.638088143046],
            [126.885081137255, 37.638047157278],
            [126.885068494257, 37.638039252241],
            [126.88501669772, 37.637986475928],
            [126.885010915896, 37.637981559893],
            [126.885001357317, 37.637971919009],
            [126.885016686079, 37.637964906524],
            [126.885156283912, 37.637875108743],
            [126.885281214622, 37.637796522523],
            [126.88531548836, 37.637773833639],
            [126.885398238546, 37.637724181169],
            [126.885426046178, 37.637706702546],
            [126.885454763115, 37.63768733276],
            [126.88551627211, 37.637607134203],
            [126.885645480128, 37.637436589055],
            [126.88566632521, 37.637408363889],
            [126.885714890047, 37.637346145029],
            [126.885801868116, 37.637229022236],
            [126.885785906469, 37.636928368487],
            [126.886267330997, 37.636563880838],
            [126.886841588389, 37.635990463709],
            [126.887234995379, 37.63559615421],
            [126.887500351, 37.63533740692],
            [126.887536455052, 37.635353920682],
            [126.887795217394, 37.63507137153],
            [126.887856539093, 37.634999496566],
            [126.887897293652, 37.634945378054],
            [126.887921718893, 37.634930949892],
            [126.887941337333, 37.63491116529],
            [126.887812247507, 37.634727926266],
            [126.88787723978, 37.63468567897],
            [126.887942365409, 37.634615312253],
            [126.887972324072, 37.634545560366],
            [126.888089785056, 37.634523464243],
            [126.888153688364, 37.634481891479],
            [126.888208035193, 37.634541191974],
            [126.888296100822, 37.634620967987],
            [126.888445154566, 37.63468408025],
            [126.888521827102, 37.634589316792],
            [126.888575112419, 37.634534309064],
            [126.888605452217, 37.634489289218],
            [126.888799843954, 37.634553435487],
            [126.889052457754, 37.634632088627],
            [126.889092872867, 37.634569455135],
            [126.889817212591, 37.634656537828],
            [126.89018125538, 37.634633546578],
            [126.8912710543, 37.634683402869],
            [126.891601087504, 37.634598126908],
            [126.891841496185, 37.634504000235],
            [126.891993604137, 37.63450271798],
            [126.893778083169, 37.634485143053],
            [126.895083376056, 37.634502833627],
            [126.896579343359, 37.634542555237],
            [126.898386771374, 37.634568835665],
            [126.900081231272, 37.634607605032],
            [126.901635616227, 37.634681730096],
            [126.90347609811, 37.634388023332],
            [126.90448946129, 37.633978375499],
            [126.905552682956, 37.633499014785],
            [126.906254230833, 37.633183069711],
            [126.906268111014, 37.633172521454],
            [126.90670066649, 37.63283187668],
            [126.906899301576, 37.631908083042],
            [126.907234807698, 37.630964126353],
            [126.907278113731, 37.630850277281],
            [126.908071772942, 37.630057506864],
            [126.908361048856, 37.630010884716],
            [126.908740951259, 37.629327431393],
            [126.908759939063, 37.629180496705],
            [126.908786669715, 37.629001673449],
            [126.908824248071, 37.628297588095],
            [126.908856419962, 37.628024166294],
            [126.908893711387, 37.627723818233],
            [126.908921118918, 37.627455257934],
            [126.908679517513, 37.626613013139],
            [126.90858659412, 37.626276054909],
            [126.908375527168, 37.626025236104],
            [126.907987190533, 37.625684719206],
            [126.907737460765, 37.625464591976],
            [126.907437863886, 37.625085491721],
            [126.907399610076, 37.625058431838],
            [126.906539848222, 37.624510848515],
            [126.906789515313, 37.624266244098],
            [126.907021679913, 37.624039275314],
            [126.907136179111, 37.623743989844],
            [126.907140999956, 37.623362419869],
            [126.907084844151, 37.623097919424],
            [126.907157847225, 37.622633937283],
            [126.907095513167, 37.622069126323],
            [126.906226176942, 37.621383836048],
            [126.905537778455, 37.620703488826],
            [126.905503260474, 37.620627508137],
            [126.905130425164, 37.619884346185],
            [126.905082088442, 37.61977375644],
            [126.905117143684, 37.619117150767],
            [126.905031723705, 37.619128973895],
            [126.904508979842, 37.618960854899],
            [126.90425765078, 37.618880577916],
            [126.903702757639, 37.618839034583],
            [126.903307587644, 37.618872844512],
            [126.903296466241, 37.618355023893],
            [126.903019890682, 37.618156433548],
            [126.902964835508, 37.617878714455],
            [126.903084842294, 37.617431576961],
            [126.902440233027, 37.616919064395],
            [126.902398506616, 37.616696757464],
            [126.902104796096, 37.61636693172],
            [126.901794070284, 37.615803826963],
            [126.901765913661, 37.614497471162],
            [126.901598714585, 37.613923314782],
            [126.900955182583, 37.612926787709],
            [126.900909206961, 37.612853805093],
            [126.900872667855, 37.612770982783],
            [126.900864511826, 37.612687959426],
            [126.9008941741, 37.612326700285],
            [126.900839039048, 37.612176063265],
            [126.90054834479, 37.612180130617],
            [126.900229025478, 37.612187623447],
            [126.899222703739, 37.612567203776],
            [126.898698510508, 37.61256509044],
            [126.898471477043, 37.61255303551],
            [126.898225179146, 37.612515816901],
            [126.897907472006, 37.61245399238],
            [126.897833784422, 37.612422411344],
            [126.89760600763, 37.612212399524],
            [126.89723724327, 37.611876359648],
            [126.896671739177, 37.611002429322],
            [126.896276565533, 37.610752550798],
            [126.895998725212, 37.61054437422],
            [126.895844408872, 37.610452047725],
            [126.895263130418, 37.610630694982],
            [126.895074047372, 37.610735570016],
            [126.894654382353, 37.611000854126],
            [126.894390547281, 37.611232644653],
            [126.894348573001, 37.611288791738],
            [126.894178448852, 37.611297286549],
            [126.893583947687, 37.611315755045],
            [126.893167577645, 37.611653773066],
            [126.893078783487, 37.611622760825],
            [126.892907196334, 37.611561903932],
            [126.892790429769, 37.611521783838],
            [126.892679788792, 37.611482867581],
            [126.892622530489, 37.611440901114],
            [126.892594445561, 37.611415647721],
            [126.892556601315, 37.611395809219],
            [126.892544880882, 37.611386896702],
            [126.892520515412, 37.611374026209],
            [126.89243236546, 37.611297829865],
            [126.892234385832, 37.611131956243],
            [126.891792725126, 37.610826879605],
            [126.891658848488, 37.610809050776],
            [126.891067836658, 37.610300579896],
            [126.890516271205, 37.610308261344],
            [126.889614780435, 37.60988491278],
            [126.889156254078, 37.609841671494],
            [126.888304685713, 37.60975589512],
            [126.887732437819, 37.609589023604],
            [126.887386330225, 37.611228475513],
            [126.887177304948, 37.611708315975],
            [126.886996031822, 37.612265126746],
            [126.886830560076, 37.612863307567],
            [126.886711502274, 37.613459821391],
            [126.88706667872, 37.613856507864],
            [126.886363195535, 37.614031154394],
            [126.886457719711, 37.614812676206],
            [126.883287163107, 37.615324047392],
            [126.883199204844, 37.61543012178],
            [126.880919426764, 37.615019228391],
            [126.880700248277, 37.614979721453],
            [126.880126980374, 37.614590089729],
            [126.879198484593, 37.614712745557],
            [126.877856336274, 37.615323563118],
            [126.877114730732, 37.615298997801],
            [126.876080226157, 37.61541016492],
            [126.87515100094, 37.615619282225],
            [126.87520903667, 37.615827155497],
            [126.875302452166, 37.615983944948],
            [126.875365112595, 37.616056063351],
            [126.875564547024, 37.616224597531],
            [126.875569309764, 37.61622797228],
            [126.875633091246, 37.616273647961],
            [126.875492786639, 37.616390040023],
            [126.875450796981, 37.616572632899],
            [126.875308052125, 37.616859046119],
            [126.875250236242, 37.6169760126],
            [126.875103924163, 37.61692659937],
            [126.874911691685, 37.616825393405],
            [126.874865975706, 37.616813271215],
            [126.874684956322, 37.616759198182],
            [126.874392093628, 37.616502148726],
            [126.87425518232, 37.6164869638],
            [126.874020950321, 37.616420840303],
            [126.873899974961, 37.616492175302],
            [126.873887349263, 37.616576376278],
            [126.873831411449, 37.616653673936],
            [126.873804663136, 37.616736904581],
            [126.873716983006, 37.61680398675],
            [126.873617566987, 37.616799166918],
            [126.873515774044, 37.616661242607],
            [126.873460372769, 37.616669786865],
            [126.873333361069, 37.617037086744],
            [126.873332898562, 37.617068980941],
            [126.87339558139, 37.617345307563],
            [126.873450806934, 37.617624437104],
            [126.873434719545, 37.617692533743],
            [126.873409502578, 37.61776737782],
            [126.873358391531, 37.617796739325],
            [126.873211052372, 37.617797732478],
            [126.872869515595, 37.617740535832],
            [126.872753654732, 37.617721029387],
            [126.872661652126, 37.617692503042],
            [126.872486384061, 37.617587203194],
            [126.871967562848, 37.617387184804],
            [126.871914720579, 37.617356628604],
            [126.871682825005, 37.617216667715],
            [126.871607244782, 37.617163363638],
            [126.871504295381, 37.617066980528],
            [126.871492942563, 37.616997493435],
            [126.871311948997, 37.616871300802],
            [126.871028618779, 37.616675042669],
            [126.870659549194, 37.616375031025],
            [126.870526792469, 37.616271289025],
            [126.870395208418, 37.616319120098],
            [126.8698442866, 37.616494269923],
            [126.869919436761, 37.616636258142],
            [126.8700444734, 37.617065363206],
            [126.870057463956, 37.617265386269],
            [126.870058924325, 37.617278686363],
            [126.870214971626, 37.617748495983],
            [126.870282307209, 37.617942678032],
            [126.870318620817, 37.618052151448],
            [126.870333633858, 37.618100803041],
            [126.870401124805, 37.618232664396],
            [126.870412135187, 37.61825157018],
            [126.870423219752, 37.618273386211],
            [126.870479173181, 37.618383521082],
            [126.870529082316, 37.618575287013],
            [126.870655731869, 37.618688978028],
            [126.870925079702, 37.618930784077],
            [126.870938662158, 37.618942845208],
            [126.870998139239, 37.618975373312],
            [126.871063261761, 37.619011745801],
            [126.871207892198, 37.619090939553],
            [126.87128476328, 37.619125946393],
            [126.871395556909, 37.619168342588],
            [126.871575422152, 37.619210688618],
            [126.871651548824, 37.619211484192],
            [126.872238640179, 37.619240095229],
            [126.872650644072, 37.619299578064],
            [126.872805410548, 37.619394115602],
            [126.873141021934, 37.619759540189],
            [126.873330508908, 37.620044419749],
            [126.873705342191, 37.620673304728],
            [126.873748780204, 37.621223543993],
            [126.873802664962, 37.621825375573],
            [126.873822685815, 37.621981086496],
            [126.87385180788, 37.622213201305],
            [126.873873461745, 37.622394438722],
            [126.873904087712, 37.622628285009],
            [126.873945241042, 37.622952609996],
            [126.874013172974, 37.623542221192],
            [126.874063617501, 37.623898784152],
            [126.874090766703, 37.624132851821],
            [126.874324555803, 37.624579521182],
            [126.874485138888, 37.624991369511],
            [126.874547702995, 37.62517070464],
            [126.874675667624, 37.625573949259],
            [126.874724381941, 37.625884740229],
            [126.874649642396, 37.626183505576],
            [126.874587403864, 37.626305043757],
            [126.874543950838, 37.626377669959],
            [126.874442959182, 37.626545611809],
            [126.874103514817, 37.627083535521],
            [126.873981737314, 37.627309279319],
            [126.873863171743, 37.62752442194],
            [126.873769182157, 37.627848681528],
            [126.873802898912, 37.6279456453],
            [126.873818928348, 37.628011857543],
            [126.873866357213, 37.628205375982],
            [126.873947276566, 37.628489451916],
            [126.873980543855, 37.62860446174],
            [126.87401851464, 37.628731486677],
            [126.874137524212, 37.629007134411],
            [126.874229674869, 37.629143344658],
            [126.874341748293, 37.629308650876],
            [126.874419795618, 37.629391733094],
            [126.874520276182, 37.629499841555],
            [126.874523774185, 37.629501304905],
            [126.874531044803, 37.629509286387],
            [126.874635072053, 37.629623732443],
            [126.87477632431, 37.629832010233],
            [126.87483020795, 37.629922346121],
            [126.874870531751, 37.629992485516],
            [126.874886796806, 37.63002079369],
            [126.874953662382, 37.630138352992],
            [126.875007863559, 37.63024211371],
            [126.875025027522, 37.63027483762],
            [126.875096163757, 37.63041285362],
            [126.875106216889, 37.630430163172],
            [126.875195129711, 37.630573874314],
            [126.875257027646, 37.630675291597],
            [126.875285690385, 37.630728110574],
            [126.875337843106, 37.630811092384],
            [126.875401772035, 37.631071768649],
            [126.875432006239, 37.63128758521],
            [126.875469299911, 37.631515941901],
            [126.875448931434, 37.631723469435],
            [126.875370925832, 37.631915195268],
            [126.875249357027, 37.632216045929],
            [126.87522500434, 37.632280403747],
            [126.874962803177, 37.63288497628],
            [126.874911796889, 37.633073883635],
            [126.874888236569, 37.63315832502],
            [126.874875847883, 37.633395566195],
            [126.874894790032, 37.633749454527],
            [126.874905590657, 37.633967457663],
            [126.874911204596, 37.63409746575],
            [126.874962163497, 37.634331062823],
            [126.875080669468, 37.63477081286],
            [126.875345267137, 37.635090726878],
            [126.875467845763, 37.635482368632],
            [126.875747788689, 37.636050364199],
            [126.875885530076, 37.636435156172],
            [126.875892348522, 37.636490132055],
            [126.875906788912, 37.636620825171],
            [126.875867890303, 37.636979976341],
            [126.876171591128, 37.637236384111],
            [126.876273513484, 37.637364034669],
            [126.876363233778, 37.637471634464],
            [126.876740537067, 37.63794424518],
            [126.876891506261, 37.638228879226],
            [126.876868241193, 37.638523510163],
            [126.876698707573, 37.638696075245],
            [126.876593921943, 37.639025163288],
            [126.876557563198, 37.639174641898],
            [126.876544713597, 37.639310801929],
            [126.876543831924, 37.639404268331],
            [126.876563944662, 37.639518046966],
            [126.876653184325, 37.63977246016],
            [126.876691671492, 37.639901664913],
            [126.876730885595, 37.64010438111],
            [126.876750611683, 37.640253171245],
            [126.876758808129, 37.640467125834],
            [126.876781424649, 37.64091343951],
            [126.876749135026, 37.641081969077],
            [126.876759983017, 37.641221280533],
            [126.876869356119, 37.64117241004],
            [126.877518947282, 37.640882169194],
            [126.877461784741, 37.640781019528],
            [126.877427914177, 37.640708905779],
            [126.877409015183, 37.640627347515],
            [126.877210282307, 37.640193408129],
            [126.876856854723, 37.639296563345],
            [126.876862194388, 37.639287829511],
            [126.876905933322, 37.639148945083],
            [126.876948379565, 37.638902032141],
            [126.876945625744, 37.638787064579],
            [126.876987777365, 37.638716472484],
            [126.877025571561, 37.638677049579],
            [126.877102351254, 37.638563156982],
            [126.877143568583, 37.63850914182],
            [126.877171212319, 37.63844177788],
            [126.877180074089, 37.638359077532],
            [126.877176151938, 37.638299113379],
            [126.877187624158, 37.638247634633],
            [126.877151943143, 37.638174978281],
            [126.877099301087, 37.638082212246],
            [126.877078537461, 37.638032366319],
            [126.877826836791, 37.637914224189],
            [126.878058667183, 37.638036639486],
            [126.878086709922, 37.638069914879],
            [126.878296551082, 37.638198613539],
            [126.87832610076, 37.638417221418],
            [126.878304760434, 37.638441615611],
            [126.878269400804, 37.638516630062],
            [126.878314956486, 37.638572457125],
            [126.8783477764, 37.638601493839],
            [126.878392161336, 37.638618478533],
            [126.878458642272, 37.638633774401],
            [126.878515034344, 37.638652303231],
            [126.878570475222, 37.638698040547],
            [126.878700896714, 37.638826655691],
            [126.878823228412, 37.638914357924],
            [126.878893866215, 37.638951281315],
            [126.878712367993, 37.63907804019],
            [126.878739902239, 37.639152219244],
            [126.878837523862, 37.639315757993],
            [126.878915391527, 37.639503782703],
            [126.878956680971, 37.63961230321],
            [126.878996474736, 37.639665772464],
            [126.879043631675, 37.639713185775],
            [126.878971839197, 37.639764854327],
            [126.87890469796, 37.639805959211],
            [126.87883042541, 37.639843002246],
            [126.878783527925, 37.639837637707],
            [126.878804208934, 37.639869824089],
            [126.878841204888, 37.639902207628],
            [126.879050219678, 37.64002058787],
            [126.879253109964, 37.640143195983],
            [126.879465749769, 37.640297393065],
            [126.879674485245, 37.640414645685],
            [126.879877658386, 37.64053878467],
            [126.879995476572, 37.64060375838],
            [126.8800132189, 37.640645915332],
            [126.880024596014, 37.640686651193],
            [126.88001094556, 37.640719973272],
            [126.879983104672, 37.640770309191],
            [126.879979855028, 37.640817697215],
            [126.880068589729, 37.640875360995],
            [126.880175606942, 37.64094279208],
            [126.880298941921, 37.641043810221],
            [126.880570990067, 37.641317310563],
            [126.880652713567, 37.641402221253],
            [126.880659337694, 37.641439708664],
            [126.880672531469, 37.641464390916]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41285108',
      properties: {
        rgnCode: '41285108',
        colCode: '41280',
        rgnKo: ['산황동'],
        rgnSize: 1,
        rgnBbox: [
          126.797046401977, 37.643292214759, 126.817804858749, 37.659354742861
        ],
        rgnCenter: [126.808574129039, 37.651129391114],
        rgnArea: 1823721,
        predVal: [
          0.2633, 0.26775, 0.2562, 0.28773, 0.36048, 0.32776, 0.35959, 0.40372,
          0.37016, 0.3953, 0.37782, 0.26163, 0.13458, 0.23267, 0.25701, 0.15177,
          0.17456, 0.29774, 0.41709, 0.30035, 0.31584, 0.37541, 0.32938,
          0.37301, 0.29859, 0.36386, 0.33257, 0.34243, 0.33479, 0.34887, 0.25234
        ]
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.797046401977, 37.654087728508],
              [126.797066011956, 37.654089924832],
              [126.797071703954, 37.654080474473],
              [126.797356535083, 37.653605614039],
              [126.797384157666, 37.653615752745],
              [126.797421853702, 37.653630323643],
              [126.797444801193, 37.65363861626],
              [126.797894276639, 37.653800973548],
              [126.797985976499, 37.653834107426],
              [126.797987946266, 37.653834849622],
              [126.798049487064, 37.653857101712],
              [126.798060242226, 37.653860904351],
              [126.798063186357, 37.653861720304],
              [126.798035091104, 37.653941678309],
              [126.79799007018, 37.654078062116],
              [126.797975147025, 37.654123247226],
              [126.797963628914, 37.65415814909],
              [126.797963651554, 37.654158158139],
              [126.79817299485, 37.654260671554],
              [126.79818339319, 37.654229119362],
              [126.798248457378, 37.654031512824],
              [126.798254056039, 37.654014674263],
              [126.798263883922, 37.653984508579],
              [126.798279864407, 37.6539421453],
              [126.798385034605, 37.65398088811],
              [126.798530958222, 37.65403524275],
              [126.798640984329, 37.654076138004],
              [126.798680720114, 37.654090901212],
              [126.798680731421, 37.654090910241],
              [126.798680754061, 37.654090919289],
              [126.798955712216, 37.654193192737],
              [126.799240208381, 37.654297112659],
              [126.799446588539, 37.654373148129],
              [126.799600439762, 37.654429902604],
              [126.799676460789, 37.654457575459],
              [126.799734707746, 37.654478784913],
              [126.799746866203, 37.654483355625],
              [126.799830141575, 37.654514626663],
              [126.799904860229, 37.654542053881],
              [126.799925973803, 37.654549847345],
              [126.800116335886, 37.654620079028],
              [126.80022020572, 37.65465847561],
              [126.800248564755, 37.65466895727],
              [126.800266316112, 37.654675492584],
              [126.800308566177, 37.654691043378],
              [126.800357789567, 37.654709326989],
              [126.800495474816, 37.654760466692],
              [126.800646836975, 37.654816314585],
              [126.800707744971, 37.654838492131],
              [126.800707982706, 37.654838582633],
              [126.80081351739, 37.654877576168],
              [126.801037979928, 37.654960505031],
              [126.801037979904, 37.654960514041],
              [126.801024083797, 37.65497837475],
              [126.801125232034, 37.654962995807],
              [126.801199943954, 37.654942652575],
              [126.801246129751, 37.654927522557],
              [126.80127130768, 37.65491999712],
              [126.801313372415, 37.654907626069],
              [126.801318385237, 37.65490615698],
              [126.801323138046, 37.654904453195],
              [126.801425113746, 37.654867821433],
              [126.801449979763, 37.65486671937],
              [126.801453085185, 37.65486658048],
              [126.801455931827, 37.654865747402],
              [126.801469439263, 37.654861769986],
              [126.801477849212, 37.654865478238],
              [126.801487562155, 37.654865044221],
              [126.801557173806, 37.65489561514],
              [126.801567443508, 37.654898858032],
              [126.801850546106, 37.654988101443],
              [126.802094782509, 37.655021129808],
              [126.802127269217, 37.655013796737],
              [126.802178844275, 37.655021650289],
              [126.80217898031, 37.655021632499],
              [126.802216123773, 37.65502732634],
              [126.802309135622, 37.655041493579],
              [126.802509618369, 37.654993810059],
              [126.803906741506, 37.654666132005],
              [126.804160174892, 37.654606731605],
              [126.804190383683, 37.654599304058],
              [126.80419510098, 37.654598140682],
              [126.804202039933, 37.654631037827],
              [126.804202263001, 37.65463238966],
              [126.804170957977, 37.65463868015],
              [126.804215403004, 37.654805758963],
              [126.804424220142, 37.65559148667],
              [126.804498048545, 37.655570887602],
              [126.804618653117, 37.655689116488],
              [126.804704160585, 37.655751876832],
              [126.804814180106, 37.655844770501],
              [126.804821514792, 37.65585667558],
              [126.804935085928, 37.655978045818],
              [126.805040768806, 37.656091276026],
              [126.805043571368, 37.656094271926],
              [126.805050469531, 37.65609983341],
              [126.805140937201, 37.656172818733],
              [126.805167978808, 37.656189171364],
              [126.805457712052, 37.65629011178],
              [126.805549760696, 37.656322339379],
              [126.805785553807, 37.656465805413],
              [126.8058590689, 37.65652160752],
              [126.806040216099, 37.656782352194],
              [126.806051459548, 37.656794741188],
              [126.806057892843, 37.656822060349],
              [126.806087395447, 37.656920036019],
              [126.806106280308, 37.657104172187],
              [126.806144682544, 37.657148978127],
              [126.806164821145, 37.657170418603],
              [126.806437790729, 37.657287339135],
              [126.806434863813, 37.6572625305],
              [126.806448698323, 37.657259219776],
              [126.806568176368, 37.657216720159],
              [126.806594799221, 37.657207114728],
              [126.807441111687, 37.656906233538],
              [126.807522694463, 37.657128637845],
              [126.807669682678, 37.657345113061],
              [126.807729992147, 37.657389882403],
              [126.807739018215, 37.657396582451],
              [126.807739063592, 37.657396564506],
              [126.807741378119, 37.657395541199],
              [126.807771013234, 37.657382399626],
              [126.80780765462, 37.657368224421],
              [126.807848374099, 37.657354686581],
              [126.807848475765, 37.657354812884],
              [126.807848487075, 37.657354821913],
              [126.807863817739, 37.657373217925],
              [126.807899752913, 37.657377871874],
              [126.807908264515, 37.657377579513],
              [126.807927191882, 37.657376889796],
              [126.807979928165, 37.657383571452],
              [126.808069078849, 37.657367455111],
              [126.808126219143, 37.65735398915],
              [126.808131270389, 37.657342086564],
              [126.808223224125, 37.657328812767],
              [126.808263904661, 37.657321518465],
              [126.808323222102, 37.657307533411],
              [126.80833433484, 37.657304938787],
              [126.808339540026, 37.657307965571],
              [126.808339766795, 37.657307920894],
              [126.808498962495, 37.657397657134],
              [126.808714019632, 37.657508459128],
              [126.808726757369, 37.657517309483],
              [126.808735856942, 37.657521919318],
              [126.808921065858, 37.657649709555],
              [126.809040293511, 37.657725856193],
              [126.809184485768, 37.657833406292],
              [126.80929676826, 37.657865105346],
              [126.809468752584, 37.657826112037],
              [126.809549991733, 37.657836983866],
              [126.809660673597, 37.657847777391],
              [126.809660867154, 37.657851832085],
              [126.809666327663, 37.657852561744],
              [126.809661765088, 37.657868420462],
              [126.809662196539, 37.657872511583],
              [126.809664843977, 37.657887445014],
              [126.809682292817, 37.657946108727],
              [126.809702571403, 37.658036959801],
              [126.809715427896, 37.658149512256],
              [126.809650569, 37.65825130685],
              [126.809607585815, 37.658263670362],
              [126.809532574146, 37.658303191126],
              [126.809506529242, 37.658330403175],
              [126.809504050111, 37.65833373274],
              [126.809796265579, 37.658369553192],
              [126.809818324441, 37.65837194959],
              [126.809823387832, 37.65837287681],
              [126.809834014672, 37.65837418247],
              [126.810086446302, 37.658421127638],
              [126.810087522247, 37.658421390666],
              [126.81012518618, 37.658428308185],
              [126.810179453179, 37.658443883945],
              [126.81033663174, 37.65848234905],
              [126.810336240962, 37.658488898492],
              [126.810341710709, 37.658490466039],
              [126.810334271049, 37.658563252611],
              [126.810335791639, 37.65885417929],
              [126.810338595816, 37.659289804302],
              [126.811075912413, 37.659354470466],
              [126.81107721501, 37.659354742861],
              [126.811110446314, 37.659233750779],
              [126.811085823751, 37.659156767936],
              [126.811002048313, 37.659033469652],
              [126.810904725538, 37.658889877522],
              [126.810832449599, 37.658790293285],
              [126.810800828475, 37.658746905362],
              [126.810779328415, 37.6586899291],
              [126.810767603199, 37.658621796574],
              [126.810765944002, 37.658517281008],
              [126.810710771328, 37.658465836312],
              [126.810688715846, 37.658404534462],
              [126.810695150984, 37.658369857405],
              [126.810689567284, 37.65820469999],
              [126.810737598453, 37.658168198166],
              [126.81075700527, 37.658201655655],
              [126.810886978426, 37.658207181501],
              [126.811012827541, 37.658185671349],
              [126.811248507072, 37.657625105775],
              [126.811400199558, 37.657601294355],
              [126.811797909808, 37.657538956604],
              [126.811862965564, 37.657402924144],
              [126.811882447719, 37.657362321566],
              [126.811977125297, 37.657164439783],
              [126.81199227804, 37.657132839963],
              [126.812240991142, 37.656656894639],
              [126.812376755709, 37.656656752002],
              [126.812739753109, 37.656695534717],
              [126.812814414426, 37.656703582736],
              [126.813116391411, 37.656673071907],
              [126.81324076482, 37.656741023675],
              [126.813482247716, 37.656836641726],
              [126.813667002568, 37.656958297191],
              [126.81381871426, 37.657061069451],
              [126.814327296848, 37.65720684418],
              [126.814367921609, 37.657140236605],
              [126.814595215502, 37.657427016412],
              [126.815027819747, 37.657322197109],
              [126.815064880818, 37.65727558527],
              [126.815193108954, 37.657071897589],
              [126.815236418925, 37.657018808555],
              [126.815206751334, 37.656963892421],
              [126.815366422166, 37.656920807664],
              [126.815456494959, 37.656974827974],
              [126.815456787965, 37.657039067842],
              [126.815628553124, 37.657054114592],
              [126.815716419295, 37.657038756272],
              [126.81584350315, 37.65706616576],
              [126.815927298384, 37.657092786237],
              [126.816019044876, 37.657112031176],
              [126.816059291954, 37.65710551735],
              [126.816130376228, 37.657094006523],
              [126.816106175817, 37.657027746942],
              [126.816140317599, 37.656969868006],
              [126.816298096957, 37.656912453537],
              [126.816361915864, 37.656905886517],
              [126.816414567418, 37.657015347321],
              [126.816552971668, 37.656636614966],
              [126.816640257461, 37.656397993807],
              [126.816830382111, 37.656320717789],
              [126.816855808831, 37.656258049833],
              [126.816892437463, 37.656248106358],
              [126.8168455943, 37.656126942189],
              [126.816986421833, 37.655958049815],
              [126.816975453088, 37.655811083859],
              [126.816765809505, 37.655671194905],
              [126.816113821057, 37.655320323948],
              [126.815925736515, 37.655170556663],
              [126.815832156127, 37.654705506946],
              [126.815738724663, 37.654408579074],
              [126.815724334173, 37.654362516649],
              [126.81575028888, 37.653951533241],
              [126.815749196202, 37.653662318889],
              [126.815619756414, 37.6532185655],
              [126.815567186997, 37.653032251318],
              [126.815956761899, 37.652721488091],
              [126.815962386056, 37.652692755863],
              [126.816055883622, 37.652142407682],
              [126.816188726903, 37.65185529577],
              [126.816244098476, 37.651735012583],
              [126.816327289057, 37.6512269937],
              [126.816374050498, 37.650877398945],
              [126.81654398207, 37.650803695507],
              [126.816607306246, 37.650628915643],
              [126.816894903233, 37.65062909577],
              [126.81708127396, 37.650414144484],
              [126.817063227301, 37.650242750924],
              [126.817374304882, 37.650094846381],
              [126.817498301459, 37.650037107195],
              [126.817597680242, 37.649990771879],
              [126.817804858749, 37.649745489016],
              [126.817781784538, 37.649406416338],
              [126.817769316191, 37.649223949554],
              [126.81709148783, 37.649399753211],
              [126.816924733768, 37.649289933901],
              [126.816469613332, 37.648990097329],
              [126.815771505799, 37.648936383353],
              [126.815687556176, 37.648929944328],
              [126.815507009877, 37.648581342984],
              [126.815483223873, 37.64844111381],
              [126.815666779377, 37.648264001178],
              [126.815691430147, 37.648239173104],
              [126.815627204057, 37.648184743145],
              [126.815519783747, 37.648093755581],
              [126.81598769366, 37.647666979773],
              [126.816111646286, 37.64758014046],
              [126.816293601629, 37.647452758123],
              [126.816311219686, 37.647339443137],
              [126.816329299432, 37.647222705165],
              [126.816259010551, 37.64713675893],
              [126.816209248112, 37.647075910018],
              [126.816165134137, 37.647021971426],
              [126.816125584132, 37.646973608015],
              [126.816066422996, 37.64690127486],
              [126.816102762767, 37.646777808304],
              [126.816162971665, 37.6466701463],
              [126.816176636261, 37.646643048419],
              [126.816227393446, 37.646554201909],
              [126.816331815924, 37.646391649765],
              [126.81637790879, 37.646264504423],
              [126.816500459109, 37.646056661517],
              [126.816481136435, 37.645989508566],
              [126.816346512599, 37.645583588231],
              [126.816286975492, 37.645284280901],
              [126.816003458748, 37.64497299897],
              [126.815784921488, 37.64491391155],
              [126.815595818661, 37.644865231329],
              [126.81555738321, 37.644874901287],
              [126.815132811861, 37.644968383238],
              [126.81508326653, 37.644980287928],
              [126.814687483589, 37.645024800707],
              [126.81402835502, 37.644805628983],
              [126.814006877371, 37.644785413003],
              [126.813892594792, 37.64467747467],
              [126.81388389009, 37.644669532244],
              [126.813641746865, 37.644446605995],
              [126.813041518045, 37.644597282802],
              [126.812854376812, 37.644849256774],
              [126.812788319823, 37.644938257577],
              [126.812775245926, 37.64495544529],
              [126.813149547453, 37.645296431932],
              [126.813247450014, 37.64538461348],
              [126.813224091788, 37.645660544897],
              [126.813352663115, 37.64598861487],
              [126.813235173509, 37.646208175251],
              [126.81314648276, 37.646375434841],
              [126.813095703372, 37.646471667984],
              [126.812865790572, 37.64701179546],
              [126.81272005082, 37.647333750955],
              [126.812686042469, 37.647518666668],
              [126.812630648703, 37.647646696617],
              [126.812319578586, 37.647836664249],
              [126.811872859431, 37.64803145854],
              [126.811719575785, 37.647975802095],
              [126.811615990907, 37.647938064758],
              [126.811559089506, 37.647900852993],
              [126.811302352947, 37.647667176964],
              [126.811133157903, 37.647453102725],
              [126.810977456498, 37.64732437235],
              [126.810793569988, 37.647228661984],
              [126.810623713503, 37.647141172904],
              [126.810620939341, 37.647029717822],
              [126.810443828641, 37.64694338801],
              [126.810025367799, 37.646812338477],
              [126.809890041176, 37.646781305408],
              [126.809659252127, 37.646683534933],
              [126.809312096459, 37.64654079603],
              [126.808945499882, 37.64642370216],
              [126.808718427847, 37.646379273627],
              [126.808675960967, 37.646369834082],
              [126.808615375282, 37.646356220412],
              [126.808594198508, 37.646351500715],
              [126.80853599212, 37.646337980993],
              [126.808446615372, 37.646328374527],
              [126.808354303799, 37.646314438508],
              [126.808251526844, 37.646272555049],
              [126.808163916413, 37.646237273475],
              [126.807961458399, 37.6461396362],
              [126.807881451328, 37.646056344921],
              [126.807739177594, 37.645908892009],
              [126.807599774901, 37.645703330749],
              [126.807567824296, 37.645658770061],
              [126.807531250577, 37.645605552402],
              [126.807404120226, 37.645343429843],
              [126.807324504767, 37.645284375052],
              [126.807062360042, 37.645090954404],
              [126.806986834534, 37.645027941834],
              [126.806661438626, 37.644758193481],
              [126.806658086482, 37.644740168441],
              [126.806638151541, 37.644650668677],
              [126.806596141187, 37.64459708135],
              [126.80644357225, 37.644402308634],
              [126.806295231232, 37.644237545188],
              [126.806170480772, 37.644107598261],
              [126.806009052406, 37.644008313683],
              [126.805994243798, 37.643995134903],
              [126.805953858046, 37.643970471342],
              [126.805916979134, 37.643947975922],
              [126.805813827716, 37.643834642364],
              [126.805774154096, 37.643797726658],
              [126.805458313765, 37.643689715871],
              [126.805046815342, 37.6436356936],
              [126.804742579651, 37.643596264444],
              [126.804531767565, 37.643534376041],
              [126.804328900105, 37.643424748845],
              [126.804225890751, 37.643344570025],
              [126.804188344996, 37.643317477973],
              [126.804091717587, 37.643309117446],
              [126.803986631683, 37.643328672916],
              [126.803900922607, 37.643304833735],
              [126.803843963422, 37.643292214759],
              [126.803645991819, 37.643302424316],
              [126.803448217029, 37.643366512197],
              [126.803386419621, 37.64338379726],
              [126.803299100778, 37.64341149073],
              [126.803244585078, 37.64350266793],
              [126.802967198724, 37.643976204555],
              [126.802620821675, 37.644570895457],
              [126.802373247434, 37.644994476727],
              [126.802295855234, 37.645122825116],
              [126.802275615997, 37.645106753582],
              [126.802186993999, 37.645239587807],
              [126.801557850178, 37.646317170411],
              [126.800085716452, 37.648838566498],
              [126.799823199269, 37.649288064623],
              [126.79944774596, 37.649931078218],
              [126.799060092138, 37.650599567174],
              [126.799049162687, 37.650618378778],
              [126.798955657496, 37.650792286477],
              [126.798940965304, 37.650819650864],
              [126.798297254831, 37.65192692207],
              [126.797046401977, 37.654087728508]
            ]
          ],
          [
            [
              [126.802340819059, 37.65505911606],
              [126.802398288787, 37.655079025484],
              [126.802412908148, 37.655078779867],
              [126.802476717247, 37.655075752156],
              [126.802535239714, 37.655032414946],
              [126.802527566227, 37.65502023886],
              [126.802495079027, 37.655027770254],
              [126.80236632077, 37.655057627459],
              [126.802345673364, 37.655057592601],
              [126.802340819059, 37.65505911606]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41285107',
      properties: {
        rgnCode: '41285107',
        colCode: '41280',
        rgnKo: ['풍동'],
        rgnSize: 1,
        rgnBbox: [
          126.785556392602, 37.653609589875, 126.811761418124, 37.685135332909
        ],
        rgnCenter: [126.798306720882, 37.66747709689],
        rgnArea: 3839853,
        predVal: [
          0.32913, 0.33469, 0.32025, 0.35966, 0.4506, 0.4097, 0.44949, 0.50465,
          0.46269, 0.49412, 0.47228, 0.32704, 0.16823, 0.29084, 0.32127,
          0.18971, 0.2182, 0.37218, 0.52136, 0.37544, 0.3948, 0.46927, 0.41172,
          0.46626, 0.37323, 0.45482, 0.41571, 0.42804, 0.41849, 0.43609, 0.31543
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.797987946266, 37.653834849622],
            [126.797894276639, 37.653800973548],
            [126.797383702164, 37.653616562833],
            [126.79736321014, 37.653609589875],
            [126.797215434406, 37.65385962454],
            [126.797187882378, 37.653906427453],
            [126.79708472404, 37.65408482171],
            [126.797079258685, 37.654094272462],
            [126.797041972781, 37.654095379107],
            [126.797046401977, 37.654087728508],
            [126.79703825665, 37.654086813417],
            [126.797028922307, 37.654102113789],
            [126.796804789801, 37.65446706986],
            [126.796650716131, 37.654739527089],
            [126.796483739754, 37.655046919448],
            [126.796305915949, 37.655342309738],
            [126.796125556664, 37.655652561389],
            [126.795935644606, 37.655974418655],
            [126.795822916907, 37.656168832387],
            [126.795566399833, 37.656601843067],
            [126.79435844596, 37.658647189255],
            [126.794111773664, 37.65907940298],
            [126.794093435736, 37.659112436439],
            [126.794014162415, 37.659254740822],
            [126.793796637704, 37.65959636725],
            [126.793648596591, 37.659851712434],
            [126.793555110873, 37.66000993866],
            [126.793441924042, 37.660202006629],
            [126.793348685866, 37.66039302852],
            [126.793263392136, 37.660539286019],
            [126.793246997213, 37.660566196154],
            [126.793109740427, 37.66081381129],
            [126.793086976912, 37.660848548611],
            [126.79308287985, 37.660854667985],
            [126.792831220429, 37.660795930129],
            [126.792825869137, 37.660804750193],
            [126.792705880069, 37.6609965351],
            [126.792572639583, 37.66122550657],
            [126.792317203022, 37.661664368],
            [126.792179755938, 37.661897205718],
            [126.792112108613, 37.662013671472],
            [126.792103116311, 37.662027530483],
            [126.792011825759, 37.66216873094],
            [126.791926471636, 37.662334808705],
            [126.791753485152, 37.662630380511],
            [126.7915978264, 37.662889673704],
            [126.791404153713, 37.663205299831],
            [126.791042096702, 37.663801638483],
            [126.790673475097, 37.664408775886],
            [126.790524645665, 37.664695199036],
            [126.790476506379, 37.664804941393],
            [126.790005536303, 37.665461807272],
            [126.789616225735, 37.666295858839],
            [126.789535944646, 37.666467980367],
            [126.78939183929, 37.666684495073],
            [126.789375906511, 37.666707351097],
            [126.78932617312, 37.666778798786],
            [126.788940294944, 37.667401756482],
            [126.788721431538, 37.667755083234],
            [126.788586605885, 37.667986840058],
            [126.788528595379, 37.668086562951],
            [126.788485654368, 37.668160274959],
            [126.788421242973, 37.668266779559],
            [126.788305265605, 37.668458530925],
            [126.788129771863, 37.668748866907],
            [126.787763476527, 37.669409336342],
            [126.787406391144, 37.670018015442],
            [126.787262111594, 37.670290567477],
            [126.786962177305, 37.670809251839],
            [126.786859156645, 37.67100187217],
            [126.786799896725, 37.671112673823],
            [126.78670264647, 37.671269265678],
            [126.786424649151, 37.671764202898],
            [126.786197185887, 37.672107778506],
            [126.785996953483, 37.67244131254],
            [126.785982563881, 37.672478496334],
            [126.785915746953, 37.672575498852],
            [126.785896737595, 37.672603123901],
            [126.785759915503, 37.672801898108],
            [126.785725710478, 37.672851379907],
            [126.785725255149, 37.672852045792],
            [126.785719734547, 37.672860027301],
            [126.785556392602, 37.673096143016],
            [126.785820287505, 37.673404397423],
            [126.785919719302, 37.673474854911],
            [126.785958754383, 37.673499432652],
            [126.786171985665, 37.673599271268],
            [126.786186123545, 37.673582557048],
            [126.786251774991, 37.673496814398],
            [126.786333957335, 37.673378126349],
            [126.786454260619, 37.673176510349],
            [126.786468862807, 37.673152039584],
            [126.786495925799, 37.673106689017],
            [126.786670569079, 37.67318003993],
            [126.786771714272, 37.673222515679],
            [126.787128451447, 37.673372455445],
            [126.787602849475, 37.673561619601],
            [126.787795428147, 37.673642515465],
            [126.787948042095, 37.673706670705],
            [126.788104052969, 37.673772183349],
            [126.788197795492, 37.67381154514],
            [126.788180225147, 37.673851426353],
            [126.788160601819, 37.673895718605],
            [126.787925913526, 37.674427226774],
            [126.787814740539, 37.674679026805],
            [126.787621555063, 37.675116602245],
            [126.787503762445, 37.67538338209],
            [126.788028867775, 37.675631379737],
            [126.788109138905, 37.675669284662],
            [126.78885044219, 37.676019371132],
            [126.789870256416, 37.676500981105],
            [126.790967663642, 37.677019201052],
            [126.790824533015, 37.677326176222],
            [126.790809592605, 37.677358224063],
            [126.790733294848, 37.677429309408],
            [126.790948274283, 37.677743095794],
            [126.791036442777, 37.677871911668],
            [126.791151493621, 37.678195745299],
            [126.791192254916, 37.678333125794],
            [126.791300373928, 37.678743800379],
            [126.791502669075, 37.678721726616],
            [126.791537708327, 37.678717734639],
            [126.791658020942, 37.678704073839],
            [126.792205676841, 37.678617112364],
            [126.792350051061, 37.678633225558],
            [126.792530745365, 37.678653466312],
            [126.792626669819, 37.67866561013],
            [126.79264750254, 37.678674656722],
            [126.792663693625, 37.678681532765],
            [126.792710341316, 37.678701616887],
            [126.792805839335, 37.6787648538],
            [126.792813759052, 37.67877036373],
            [126.79284476257, 37.678790690407],
            [126.792862754571, 37.678802164554],
            [126.792987455565, 37.678881129852],
            [126.79299311418, 37.678884473445],
            [126.793007938984, 37.678893509351],
            [126.793032609885, 37.678908328867],
            [126.7930607861, 37.678926307968],
            [126.793197819722, 37.679013854051],
            [126.793293443498, 37.679072946329],
            [126.793334182574, 37.679098155299],
            [126.793519884903, 37.679213176381],
            [126.79360023256, 37.679262601092],
            [126.793653307169, 37.679295309746],
            [126.793695291936, 37.679321061368],
            [126.793761747735, 37.679388706126],
            [126.79380404709, 37.679431765891],
            [126.793871294371, 37.679500213843],
            [126.793795935118, 37.679897679046],
            [126.793751405321, 37.680134015032],
            [126.793720920664, 37.680293162647],
            [126.793737724335, 37.680690249517],
            [126.793616988183, 37.68093591132],
            [126.793501286473, 37.681164643644],
            [126.79370865754, 37.681357005783],
            [126.793820846955, 37.68165605494],
            [126.793651498609, 37.682056237449],
            [126.793795913097, 37.682304348528],
            [126.793799749389, 37.682310842263],
            [126.79379666444, 37.682319486134],
            [126.793663553968, 37.682692433065],
            [126.793649843858, 37.682730519898],
            [126.793790695859, 37.683199632401],
            [126.794200235604, 37.683381177507],
            [126.794614242937, 37.683710037464],
            [126.794649534417, 37.684677379646],
            [126.795351320683, 37.684664193014],
            [126.795647245783, 37.684774988538],
            [126.795985724845, 37.685035688607],
            [126.796657274582, 37.685135332909],
            [126.796942237783, 37.684822020094],
            [126.796997272355, 37.684597053509],
            [126.79777994368, 37.684066476077],
            [126.797960248599, 37.683757484738],
            [126.798409492048, 37.683435712315],
            [126.798786085333, 37.683272744261],
            [126.798900842184, 37.683178249606],
            [126.798974223739, 37.682957367906],
            [126.79906956923, 37.682371541203],
            [126.799086764069, 37.682176420762],
            [126.799088183661, 37.682071189979],
            [126.799387424524, 37.682086298909],
            [126.799364677666, 37.681988234456],
            [126.799082161643, 37.681951080428],
            [126.799082150609, 37.681871705007],
            [126.79909159859, 37.681523676741],
            [126.798803225767, 37.681014944569],
            [126.798699030512, 37.680716445416],
            [126.798640684629, 37.68054931432],
            [126.798941113962, 37.680304334518],
            [126.799177904691, 37.680049388171],
            [126.799269048451, 37.679881874042],
            [126.7993047045, 37.679775981143],
            [126.799311114177, 37.679502728014],
            [126.79911190873, 37.679472474126],
            [126.799002734446, 37.679016756397],
            [126.79892771259, 37.678800124506],
            [126.798889343181, 37.678694915412],
            [126.79883860199, 37.678553826442],
            [126.798770413742, 37.678117459611],
            [126.798700913492, 37.677996790339],
            [126.798674286682, 37.677950614886],
            [126.798673966723, 37.677943226382],
            [126.798673068234, 37.677919331113],
            [126.798671955443, 37.677890876567],
            [126.798673484524, 37.677870517275],
            [126.798682428846, 37.677749622483],
            [126.798683418132, 37.677736001518],
            [126.798685726709, 37.677704129169],
            [126.798680099567, 37.67768916339],
            [126.798677173375, 37.677681410015],
            [126.798570753827, 37.677485581307],
            [126.798528019518, 37.677381040289],
            [126.79850785128, 37.677252797536],
            [126.798493811407, 37.677163577331],
            [126.798475867024, 37.677009660747],
            [126.798459305492, 37.676889082432],
            [126.798455748818, 37.676863128369],
            [126.798408611084, 37.676523651743],
            [126.798317136792, 37.67618968472],
            [126.79847248901, 37.675836591718],
            [126.798497814063, 37.675528143073],
            [126.798504264783, 37.675448778688],
            [126.798513762093, 37.675332930245],
            [126.798516927693, 37.675294374161],
            [126.799074047441, 37.674882777345],
            [126.799344999019, 37.674827381918],
            [126.799493120817, 37.67479700272],
            [126.800371075602, 37.67453257277],
            [126.800382402666, 37.674535619347],
            [126.802388058504, 37.675451336183],
            [126.802452648335, 37.675480825857],
            [126.802465441671, 37.675486703755],
            [126.802550755297, 37.675540707713],
            [126.802615303608, 37.675581558464],
            [126.802775735418, 37.675683097972],
            [126.802863568746, 37.675731808241],
            [126.802936461275, 37.675772294461],
            [126.803276805189, 37.675709799058],
            [126.803427970238, 37.675685997123],
            [126.803555692678, 37.675653956819],
            [126.803699572273, 37.67559896873],
            [126.803969825955, 37.67546445631],
            [126.80419032598, 37.675326256158],
            [126.804428712144, 37.675196734794],
            [126.804537591196, 37.675131416123],
            [126.8047781949, 37.674934324962],
            [126.804820578783, 37.67489529353],
            [126.80487396641, 37.674853036934],
            [126.804950274788, 37.674758922611],
            [126.804920763948, 37.674747926633],
            [126.804911885749, 37.674744632302],
            [126.804886066557, 37.674735029964],
            [126.804812174255, 37.674686794928],
            [126.804721208259, 37.674665380282],
            [126.804644028824, 37.674647205043],
            [126.804607925673, 37.674638702682],
            [126.804545522961, 37.674617966262],
            [126.804532744137, 37.674606592691],
            [126.804513406356, 37.674589351856],
            [126.804383377627, 37.674637066239],
            [126.804360786598, 37.674307093047],
            [126.804355982902, 37.674237620183],
            [126.804428261153, 37.674124398873],
            [126.804457136665, 37.674049756652],
            [126.804476974158, 37.673835088481],
            [126.804385388733, 37.673666904411],
            [126.804341318573, 37.673627998909],
            [126.804206036351, 37.673545604151],
            [126.804117247801, 37.673473017541],
            [126.804090262711, 37.673433059372],
            [126.804058515778, 37.673350747599],
            [126.804006269037, 37.673219388681],
            [126.804005265839, 37.673212990109],
            [126.803997381068, 37.6731514406],
            [126.803986475738, 37.67307483982],
            [126.803985661122, 37.673040060982],
            [126.803985715334, 37.673019609036],
            [126.80399908762, 37.672978006578],
            [126.804038295143, 37.672896894756],
            [126.804064592361, 37.672854142667],
            [126.804142122207, 37.67276922078],
            [126.804170951902, 37.672711787079],
            [126.804176510784, 37.672667198321],
            [126.804180625437, 37.672568729089],
            [126.80417127971, 37.672545198112],
            [126.804125693963, 37.672424031345],
            [126.80408646426, 37.672299721798],
            [126.804034182088, 37.672224763591],
            [126.804004032321, 37.6721815566],
            [126.803965279312, 37.672134100629],
            [126.803917939804, 37.6720760889],
            [126.803854755546, 37.672051746915],
            [126.803806491701, 37.672000400777],
            [126.803767941222, 37.671962134983],
            [126.803747376836, 37.671937594094],
            [126.803725569936, 37.67191142937],
            [126.803713476816, 37.671855458793],
            [126.803717830683, 37.671795191135],
            [126.803719977364, 37.671755101525],
            [126.803709358037, 37.67169904332],
            [126.803693980502, 37.671642076163],
            [126.803669654487, 37.671582841565],
            [126.803656824444, 37.671548312854],
            [126.803601987368, 37.671496775408],
            [126.803620507074, 37.671480228614],
            [126.803615263469, 37.671448685829],
            [126.803573700766, 37.671435101522],
            [126.803536550122, 37.671382062073],
            [126.803520007347, 37.671294459918],
            [126.803537184323, 37.671271333794],
            [126.803522109453, 37.671143190402],
            [126.803526178093, 37.671104996057],
            [126.803543869683, 37.671058805934],
            [126.803540996376, 37.671031051201],
            [126.803536058993, 37.670969596684],
            [126.803511979643, 37.670902974499],
            [126.803519501579, 37.670844874487],
            [126.803518936506, 37.670758920891],
            [126.803530809975, 37.670641454182],
            [126.80351680862, 37.670535926955],
            [126.803536968325, 37.670499291266],
            [126.803541192293, 37.670487946119],
            [126.80356528256, 37.670422395847],
            [126.803559390653, 37.670336253111],
            [126.803576526076, 37.670286007673],
            [126.803597255218, 37.670290997799],
            [126.803596744717, 37.670269824119],
            [126.803537705729, 37.670221703273],
            [126.803481311836, 37.670159261395],
            [126.803463327359, 37.67010247001],
            [126.803446540864, 37.670064060448],
            [126.80340449055, 37.670021013499],
            [126.803399822486, 37.669986228168],
            [126.803410747237, 37.669969848839],
            [126.80337972067, 37.669873212604],
            [126.803361993452, 37.669804979298],
            [126.803291841171, 37.669503396422],
            [126.803246638035, 37.669367453969],
            [126.803233478838, 37.669329050468],
            [126.803214329421, 37.669284239999],
            [126.803193181367, 37.669232335521],
            [126.803146426149, 37.669117536212],
            [126.803102262792, 37.668974387672],
            [126.803095406092, 37.668952860941],
            [126.803081580224, 37.668909482937],
            [126.803035301542, 37.668666953656],
            [126.802948927354, 37.668669240907],
            [126.802939840393, 37.668633997625],
            [126.802798365013, 37.668642318596],
            [126.802795253334, 37.668636087641],
            [126.802789345586, 37.668624283971],
            [126.802774121829, 37.668595156943],
            [126.802662421541, 37.668488924343],
            [126.802621834245, 37.668450294332],
            [126.802584209542, 37.668405812989],
            [126.802548483754, 37.668329350348],
            [126.802528968506, 37.668307189567],
            [126.802522567775, 37.668297511345],
            [126.802504682155, 37.66827629933],
            [126.802460241812, 37.668245996748],
            [126.802435990737, 37.668231621363],
            [126.802409026449, 37.668214619567],
            [126.802397448622, 37.668208185106],
            [126.802366019583, 37.668195067959],
            [126.802330804579, 37.668160861678],
            [126.802273052296, 37.66811993211],
            [126.802213878863, 37.6680806579],
            [126.802180266883, 37.668044670358],
            [126.802158962711, 37.668017632236],
            [126.80213901413, 37.667975342953],
            [126.802140584121, 37.667875698151],
            [126.802150992335, 37.667819359971],
            [126.802143153808, 37.667804859099],
            [126.802134639426, 37.667788789393],
            [126.802136196986, 37.667757348118],
            [126.802177078289, 37.667770571397],
            [126.802226124578, 37.667697405282],
            [126.802237684129, 37.667655529632],
            [126.802247297827, 37.667620768369],
            [126.802269492937, 37.667585848163],
            [126.802281432841, 37.66757127259],
            [126.802348895325, 37.667519941039],
            [126.802410482966, 37.667461481857],
            [126.802441495011, 37.667435406022],
            [126.802462244687, 37.667389941971],
            [126.80246915938, 37.66737689856],
            [126.802485483518, 37.667346103865],
            [126.802514492671, 37.667348495336],
            [126.802520282225, 37.667345081411],
            [126.802541851011, 37.667332414096],
            [126.802607483048, 37.667287296011],
            [126.802620835586, 37.667252991506],
            [126.802627569362, 37.667235524009],
            [126.802637335463, 37.667228512896],
            [126.802674495638, 37.667191996094],
            [126.802701763706, 37.667124739471],
            [126.802687145927, 37.667081017705],
            [126.802666441774, 37.667067017742],
            [126.802641325263, 37.66705003713],
            [126.802611253498, 37.66702115533],
            [126.80259791317, 37.667008429133],
            [126.802598481798, 37.667007709315],
            [126.802616118883, 37.66698179107],
            [126.80263595078, 37.66694037981],
            [126.802808923496, 37.666977701261],
            [126.80297601507, 37.666967801642],
            [126.803122766399, 37.666934892797],
            [126.803252579977, 37.666920695514],
            [126.80334144277, 37.666910961183],
            [126.803346603592, 37.666918060501],
            [126.80356188709, 37.666942522979],
            [126.803564817129, 37.666936058919],
            [126.803613742314, 37.666941934262],
            [126.803693169127, 37.666951527698],
            [126.804230684125, 37.666932516455],
            [126.804374727915, 37.666937442337],
            [126.804636428495, 37.666896254533],
            [126.80491143219, 37.666838960927],
            [126.805256491815, 37.666777278402],
            [126.8057187946, 37.666783182228],
            [126.805988208287, 37.666782457859],
            [126.80613850265, 37.666780904951],
            [126.806803484113, 37.666820746307],
            [126.807021050682, 37.666795878036],
            [126.807214345075, 37.666777546478],
            [126.80735280577, 37.666750204736],
            [126.807460969683, 37.666692630436],
            [126.807480051693, 37.666676984917],
            [126.807482553861, 37.666673655437],
            [126.807516561545, 37.666628032101],
            [126.807703965808, 37.666477337286],
            [126.807832402616, 37.66638168484],
            [126.807834037043, 37.666332809798],
            [126.807830726874, 37.66631560481],
            [126.807845802862, 37.666262598357],
            [126.807880560782, 37.666202884944],
            [126.807898833126, 37.666180336581],
            [126.80801965788, 37.66609142872],
            [126.808040297795, 37.666086795541],
            [126.808109478455, 37.666070132895],
            [126.8082051881, 37.666050594538],
            [126.808364453375, 37.666020006158],
            [126.808626541618, 37.665875099241],
            [126.808634012516, 37.665874399689],
            [126.808641807512, 37.66588423303],
            [126.808649775762, 37.665870911673],
            [126.80864639237, 37.665864419143],
            [126.808674774087, 37.665802523722],
            [126.808689038396, 37.665769463349],
            [126.808716975113, 37.665699926947],
            [126.808731748981, 37.665662650853],
            [126.808813189983, 37.665550477765],
            [126.808829162214, 37.665509960116],
            [126.808821746421, 37.665463052407],
            [126.808825219259, 37.665369176793],
            [126.808827010866, 37.665333771518],
            [126.808854891919, 37.665311968487],
            [126.809278474781, 37.665188325417],
            [126.80932676243, 37.665186241769],
            [126.809470887735, 37.665180349894],
            [126.809641017833, 37.665198961538],
            [126.80971045349, 37.665192857757],
            [126.809757443536, 37.665166490624],
            [126.809852102398, 37.665114207892],
            [126.810098762935, 37.665011987694],
            [126.810294927738, 37.664932975261],
            [126.810400213227, 37.664891025443],
            [126.810436290316, 37.66487716387],
            [126.8104960933, 37.664848384571],
            [126.810793114212, 37.664708358577],
            [126.811090402853, 37.664574008387],
            [126.81127028986, 37.664477354037],
            [126.811380912376, 37.664430952135],
            [126.811576168635, 37.664351530658],
            [126.811548763137, 37.664342837192],
            [126.811543758722, 37.66430525859],
            [126.811429040545, 37.664091182964],
            [126.811373204063, 37.664009374785],
            [126.811369568777, 37.663990493556],
            [126.811369123209, 37.663943011597],
            [126.811398840189, 37.663934094822],
            [126.811482680013, 37.663901164234],
            [126.8115867328, 37.663897367539],
            [126.811628734554, 37.663893245638],
            [126.811732173169, 37.663881384124],
            [126.811747446345, 37.663874291014],
            [126.811761418124, 37.663866420973],
            [126.811744674331, 37.663836815116],
            [126.811755965984, 37.663835508852],
            [126.811594508755, 37.663576660933],
            [126.81148295449, 37.663291107278],
            [126.811348711878, 37.6629475844],
            [126.811329578262, 37.662784243282],
            [126.811292586377, 37.662557462914],
            [126.811259206308, 37.662225580891],
            [126.811268927102, 37.662058105766],
            [126.811270455883, 37.661947558892],
            [126.811270609733, 37.661860624292],
            [126.811281241694, 37.661669148713],
            [126.81128602354, 37.661509576145],
            [126.811237843008, 37.661404039584],
            [126.811110139393, 37.661128946747],
            [126.811102533203, 37.661111815988],
            [126.811085292029, 37.661028808566],
            [126.811048378894, 37.660896035682],
            [126.810970629147, 37.660573857392],
            [126.810994645868, 37.660355869766],
            [126.810996861245, 37.66025174791],
            [126.810996323245, 37.660231664355],
            [126.811016380078, 37.660121868152],
            [126.811005881201, 37.660017158146],
            [126.810918116934, 37.659769248821],
            [126.810954077879, 37.659578120456],
            [126.811109179708, 37.659361389582],
            [126.81107721501, 37.659354742861],
            [126.811075670681, 37.659360371449],
            [126.810338627748, 37.659295029997],
            [126.810338595816, 37.659289804302],
            [126.810337508086, 37.659289712442],
            [126.810331894854, 37.658561807203],
            [126.810336240962, 37.658488898492],
            [126.810179453179, 37.658443883945],
            [126.810087522247, 37.658421390666],
            [126.809823387832, 37.65837287681],
            [126.809818324441, 37.65837194959],
            [126.809796265579, 37.658369553192],
            [126.809499847586, 37.658337374841],
            [126.809506529242, 37.658330403175],
            [126.809528841638, 37.658300346987],
            [126.80960617177, 37.658262676991],
            [126.809645926739, 37.658249677548],
            [126.809710381577, 37.658150765414],
            [126.809699443196, 37.658041504629],
            [126.809673087946, 37.65793393062],
            [126.809664843977, 37.657887445014],
            [126.809660495821, 37.657872824157],
            [126.809661765088, 37.657868420462],
            [126.809661532688, 37.657866248739],
            [126.809660867154, 37.657851832085],
            [126.809549991733, 37.657836983866],
            [126.809460474142, 37.657828260895],
            [126.809298093235, 37.657865476904],
            [126.80929676826, 37.657865105346],
            [126.809295940485, 37.657865293202],
            [126.80919005079, 37.657837550832],
            [126.809184485768, 37.657833406292],
            [126.80918382896, 37.657833216017],
            [126.80895170029, 37.657670842339],
            [126.808921065858, 37.657649709555],
            [126.808873976785, 37.657619630257],
            [126.808726757369, 37.657517309483],
            [126.808574124539, 37.657440026727],
            [126.808498962495, 37.657397657134],
            [126.808454733367, 37.657374871222],
            [126.808339540026, 37.657307965571],
            [126.808263904661, 37.657321518465],
            [126.808126219143, 37.65735398915],
            [126.808124771066, 37.657357392455],
            [126.808069078849, 37.657367455111],
            [126.807993466265, 37.657385287491],
            [126.807979928165, 37.657383571452],
            [126.807971141211, 37.657385160768],
            [126.807922368145, 37.657379719946],
            [126.807908600751, 37.657379021622],
            [126.807899752913, 37.657377871874],
            [126.80786859656, 37.65737895596],
            [126.807848475765, 37.657354812884],
            [126.807848374099, 37.657354686581],
            [126.80780765462, 37.657368224421],
            [126.807771013234, 37.657382399626],
            [126.807741378119, 37.657395541199],
            [126.807739063592, 37.657396564506],
            [126.807739018215, 37.657396582451],
            [126.807729992147, 37.657389882403],
            [126.807669682678, 37.657345113061],
            [126.807522694463, 37.657128637845],
            [126.807441111687, 37.656906233538],
            [126.806594799221, 37.657207114728],
            [126.806568176368, 37.657216720159],
            [126.806447448907, 37.657260298878],
            [126.806441311237, 37.657288849582],
            [126.806437790729, 37.657287339135],
            [126.806437857145, 37.657287942896],
            [126.80616934158, 37.657175237286],
            [126.806164821145, 37.657170418603],
            [126.80616204775, 37.657169233735],
            [126.806144682544, 37.657148978127],
            [126.806106733469, 37.657108551668],
            [126.806106280308, 37.657104172187],
            [126.806106178646, 37.657104045882],
            [126.806080902693, 37.656919718931],
            [126.806057892843, 37.656822060349],
            [126.806050320626, 37.656796901637],
            [126.806040216099, 37.656782352194],
            [126.805892299973, 37.656619418242],
            [126.805805944159, 37.656490525914],
            [126.805761389407, 37.656462792105],
            [126.805521228416, 37.656334130768],
            [126.805441755973, 37.656294382904],
            [126.805153073196, 37.656190452965],
            [126.805050469531, 37.65609983341],
            [126.805043571368, 37.656094271926],
            [126.805040768806, 37.656091276026],
            [126.804958211222, 37.656018357864],
            [126.804492684057, 37.655576789029],
            [126.804423479609, 37.655592972039],
            [126.804215176217, 37.654805812642],
            [126.804186126398, 37.654696421895],
            [126.804170685861, 37.654638733754],
            [126.804170857036, 37.654638283553],
            [126.804201983248, 37.654631046741],
            [126.804202039933, 37.654631037827],
            [126.80419510098, 37.654598140682],
            [126.804190383683, 37.654599304058],
            [126.804160174892, 37.654606731605],
            [126.802992221365, 37.654881280714],
            [126.802509618369, 37.654993810059],
            [126.802309135622, 37.655041493579],
            [126.80234707034, 37.655052189118],
            [126.80236632077, 37.655057627459],
            [126.802505567918, 37.655025337301],
            [126.802527566227, 37.65502023886],
            [126.80253794816, 37.65503665409],
            [126.802491496485, 37.655075047302],
            [126.802476717247, 37.655075752156],
            [126.802474003469, 37.655077756748],
            [126.802412908148, 37.655078779867],
            [126.802399919648, 37.655079397636],
            [126.802285419156, 37.655053436476],
            [126.802256801466, 37.655033566724],
            [126.802216123773, 37.65502732634],
            [126.80217898031, 37.655021632499],
            [126.802178844275, 37.655021650289],
            [126.80212003227, 37.655029101056],
            [126.801838424443, 37.654986639369],
            [126.80153293608, 37.6548906187],
            [126.801471361878, 37.654863214804],
            [126.801455931827, 37.654865747402],
            [126.801453085185, 37.65486658048],
            [126.801449979763, 37.65486671937],
            [126.801427826715, 37.65487034876],
            [126.801323138046, 37.654904453195],
            [126.801318385237, 37.65490615698],
            [126.801313372415, 37.654907626069],
            [126.801137806001, 37.654964819112],
            [126.801022401196, 37.654980390071],
            [126.801037979904, 37.654960514041],
            [126.801037979928, 37.654960505031],
            [126.80081351739, 37.654877576168],
            [126.800707982706, 37.654838582633],
            [126.800707744971, 37.654838492131],
            [126.800646836975, 37.654816314585],
            [126.800495474816, 37.654760466692],
            [126.800357789567, 37.654709326989],
            [126.800308566177, 37.654691043378],
            [126.800266316112, 37.654675492584],
            [126.800248564755, 37.65466895727],
            [126.80022020572, 37.65465847561],
            [126.800116335886, 37.654620079028],
            [126.799925973803, 37.654549847345],
            [126.799904860229, 37.654542053881],
            [126.799830141575, 37.654514626663],
            [126.799746866203, 37.654483355625],
            [126.799734707746, 37.654478784913],
            [126.799676460789, 37.654457575459],
            [126.799600439762, 37.654429902604],
            [126.799446588539, 37.654373148129],
            [126.799240208381, 37.654297112659],
            [126.798955712216, 37.654193192737],
            [126.798680754061, 37.654090919289],
            [126.798680731421, 37.654090910241],
            [126.798680720114, 37.654090901212],
            [126.798640984329, 37.654076138004],
            [126.798530958222, 37.65403524275],
            [126.798281674719, 37.653943184538],
            [126.798265468699, 37.653985141991],
            [126.798254056039, 37.654014674263],
            [126.798248457378, 37.654031512824],
            [126.79818339319, 37.654229119362],
            [126.798181153541, 37.654235917853],
            [126.798171554936, 37.654265092853],
            [126.797961731794, 37.654164002145],
            [126.797963628914, 37.65415814909],
            [126.797975147025, 37.654123247226],
            [126.797990641852, 37.654076207096],
            [126.798060242226, 37.653860904351],
            [126.798049258333, 37.653857867145],
            [126.797987946266, 37.653834849622]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41285109',
      properties: {
        rgnCode: '41285109',
        colCode: '41280',
        rgnKo: ['사리현동'],
        rgnSize: 1,
        rgnBbox: [
          126.829866907393, 37.682310949914, 126.856070384107, 37.717541685848
        ],
        rgnCenter: [126.841605711859, 37.697068363176],
        rgnArea: 4492865,
        predVal: [
          0.2633, 0.26775, 0.2562, 0.28773, 0.36048, 0.32776, 0.35959, 0.40372,
          0.37016, 0.3953, 0.37782, 0.26163, 0.13458, 0.23267, 0.25701, 0.15177,
          0.17456, 0.29774, 0.41709, 0.30035, 0.31584, 0.37541, 0.32938,
          0.37301, 0.29859, 0.36386, 0.33257, 0.34243, 0.33479, 0.34887, 0.25234
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.829866907393, 37.692395462283],
            [126.8300118004, 37.692546089727],
            [126.830131094941, 37.692631683948],
            [126.830413881985, 37.69282696498],
            [126.830490082576, 37.692921902408],
            [126.830488871482, 37.692960489115],
            [126.830472627927, 37.693388218163],
            [126.83047304725, 37.693501830838],
            [126.830844766774, 37.693923481834],
            [126.830590862197, 37.694188449148],
            [126.83069148951, 37.694262564421],
            [126.831497743141, 37.694268054226],
            [126.832151145311, 37.694128984032],
            [126.832214279117, 37.694187817797],
            [126.832146819299, 37.694635141583],
            [126.832130507509, 37.6948775686],
            [126.832169219725, 37.695000903624],
            [126.8321933272, 37.695174527705],
            [126.832029603131, 37.695322681899],
            [126.832410793306, 37.695476303563],
            [126.832710796852, 37.695431865195],
            [126.833098505566, 37.695461520806],
            [126.833586299742, 37.695858011571],
            [126.83334474684, 37.696579432508],
            [126.833410768603, 37.696766657605],
            [126.833566830887, 37.696999329598],
            [126.833573161819, 37.697042828316],
            [126.833599100208, 37.697220833362],
            [126.833750604672, 37.697413765941],
            [126.833731457505, 37.697557893489],
            [126.833716815944, 37.697800791488],
            [126.833684963404, 37.697811629845],
            [126.83387491299, 37.698002364537],
            [126.834231584258, 37.698226220856],
            [126.834237454151, 37.698237941758],
            [126.834183037117, 37.698233630005],
            [126.834405320876, 37.698417292069],
            [126.834580661188, 37.698601517939],
            [126.834676164942, 37.698740041672],
            [126.834730076498, 37.698818321894],
            [126.83458544842, 37.698792709969],
            [126.834626291143, 37.698833311305],
            [126.834598570633, 37.699059414843],
            [126.834631469255, 37.699052523959],
            [126.834623335159, 37.699089722393],
            [126.834601431912, 37.699098160483],
            [126.834598442161, 37.699116716175],
            [126.834591311785, 37.699161394055],
            [126.834744805392, 37.699177918741],
            [126.834831057594, 37.699346791845],
            [126.83509652916, 37.699437533806],
            [126.835211500603, 37.699476140276],
            [126.835227623942, 37.699481595845],
            [126.835611788372, 37.699839550616],
            [126.835615746419, 37.699844331311],
            [126.835692874456, 37.699936338439],
            [126.83570349364, 37.699949038988],
            [126.835836386577, 37.7001079041],
            [126.835887485193, 37.700177350347],
            [126.835865010399, 37.700187769995],
            [126.835963696347, 37.700325035768],
            [126.836090602387, 37.700418013472],
            [126.836115947436, 37.700493730254],
            [126.836507922033, 37.700657624723],
            [126.83651841449, 37.701193174155],
            [126.836739719106, 37.701410823849],
            [126.836879514728, 37.701650135716],
            [126.836913861951, 37.701706494093],
            [126.837162876089, 37.701860456272],
            [126.837248166981, 37.701980673993],
            [126.837269541009, 37.702032464309],
            [126.837320512805, 37.702546176435],
            [126.837511046805, 37.70279530661],
            [126.837722546256, 37.703148779508],
            [126.837886746764, 37.703199281416],
            [126.837844956614, 37.703278238188],
            [126.837548763724, 37.703762474621],
            [126.83750169832, 37.703744371816],
            [126.837441835201, 37.703739513412],
            [126.837444071368, 37.703753931988],
            [126.837453995191, 37.703814968257],
            [126.837490990312, 37.703998024039],
            [126.837735302129, 37.704298628725],
            [126.83777451188, 37.704346551526],
            [126.837943588998, 37.704501256884],
            [126.83795761775, 37.704516124271],
            [126.83814751686, 37.704716780469],
            [126.838137165952, 37.70473118159],
            [126.838264421836, 37.704772712199],
            [126.838271803417, 37.7047678572],
            [126.838363905848, 37.70480933903],
            [126.838578098979, 37.704972800209],
            [126.838144173062, 37.705051935319],
            [126.837977325251, 37.705068912488],
            [126.837615524756, 37.705043002892],
            [126.837479876012, 37.704899110134],
            [126.837254108057, 37.704843296315],
            [126.837196147696, 37.704798014077],
            [126.8371183768, 37.704737243609],
            [126.836747363825, 37.705006222807],
            [126.836403073059, 37.705010921764],
            [126.835911909596, 37.704871934647],
            [126.835547775178, 37.704850925696],
            [126.834928124449, 37.704893298276],
            [126.834882480526, 37.704913901949],
            [126.834538196593, 37.705072399427],
            [126.834071154155, 37.705226703324],
            [126.833711372122, 37.705155817391],
            [126.83358790414, 37.705089835043],
            [126.833670979374, 37.704838926099],
            [126.833790622348, 37.704493629812],
            [126.833592963052, 37.704440840171],
            [126.833551223959, 37.704439564419],
            [126.833487478954, 37.704471358799],
            [126.832437058065, 37.704995192711],
            [126.831634783465, 37.705401402462],
            [126.831752657653, 37.705652202998],
            [126.831847841978, 37.706390159158],
            [126.831917459895, 37.706379862164],
            [126.831936004302, 37.706408287285],
            [126.832498622451, 37.707267418352],
            [126.832788870003, 37.707710622735],
            [126.833473569056, 37.707824761836],
            [126.833650531528, 37.707992395335],
            [126.833774508863, 37.70809618284],
            [126.834133679165, 37.708334476143],
            [126.834641555387, 37.708766758605],
            [126.834797632455, 37.708874095204],
            [126.834897580489, 37.708917888271],
            [126.835346275123, 37.709473606405],
            [126.835690345038, 37.70958749534],
            [126.83579339047, 37.709641508949],
            [126.83599025311, 37.709977800585],
            [126.836375386026, 37.710635667028],
            [126.836399970413, 37.710677659419],
            [126.836488216619, 37.710737462914],
            [126.836572205184, 37.710794395317],
            [126.836879524329, 37.71095582735],
            [126.836952933043, 37.710991968444],
            [126.837008402229, 37.711037634698],
            [126.837196241593, 37.711175924522],
            [126.837324505571, 37.711125108565],
            [126.837461200106, 37.711159715715],
            [126.837467068735, 37.711327033149],
            [126.837541060426, 37.711392266858],
            [126.837643153105, 37.711484127066],
            [126.83782249328, 37.711646279652],
            [126.838071098751, 37.711821546947],
            [126.838116084953, 37.711854098108],
            [126.838123442915, 37.711860324965],
            [126.838111066647, 37.711866974956],
            [126.838362377503, 37.71207625682],
            [126.838540767172, 37.712145589546],
            [126.838327525139, 37.712247572175],
            [126.83829538937, 37.71231780298],
            [126.838328455526, 37.712547324584],
            [126.838337449534, 37.712609143242],
            [126.838283031904, 37.71257952527],
            [126.838295914576, 37.712647106482],
            [126.838339929263, 37.712693702254],
            [126.838363365492, 37.712758432994],
            [126.838377775788, 37.712941429941],
            [126.8382941445, 37.71314385115],
            [126.838293747194, 37.713273274233],
            [126.838255255687, 37.713397103636],
            [126.838243827865, 37.713643321552],
            [126.838262360197, 37.713725695415],
            [126.838298103217, 37.713769171412],
            [126.838322742919, 37.713859302017],
            [126.838336082122, 37.713930857098],
            [126.838305322873, 37.714252188716],
            [126.838259331617, 37.714330689183],
            [126.838192199604, 37.71448168802],
            [126.838228154623, 37.714480386373],
            [126.838797529835, 37.714566675104],
            [126.838791678751, 37.714597299834],
            [126.838685136884, 37.715136650479],
            [126.838658186128, 37.715272929225],
            [126.838607953841, 37.715527112078],
            [126.838267050195, 37.715495989735],
            [126.838256912233, 37.715536717322],
            [126.838241201576, 37.715615439882],
            [126.83808019643, 37.716421129677],
            [126.838165038083, 37.716470079534],
            [126.838307184305, 37.716764351217],
            [126.838339381844, 37.716925398142],
            [126.838339301127, 37.716936344749],
            [126.838357553724, 37.716950163775],
            [126.838420199783, 37.717030409262],
            [126.83863069989, 37.71728564618],
            [126.838702242326, 37.717405609306],
            [126.838733123856, 37.717458475492],
            [126.838783367628, 37.717541685848],
            [126.838970339813, 37.717281249709],
            [126.83908973013, 37.717148395854],
            [126.839211890349, 37.717008995675],
            [126.839555128906, 37.716731565173],
            [126.839773132925, 37.716619784451],
            [126.839882419013, 37.716563533942],
            [126.840487414152, 37.716387601543],
            [126.840543656739, 37.716372217792],
            [126.840565039945, 37.716365048278],
            [126.840585357755, 37.716357543948],
            [126.840844212135, 37.716292847342],
            [126.840911832027, 37.716276641022],
            [126.841575600544, 37.716187681112],
            [126.842003513124, 37.716101191596],
            [126.842160994126, 37.715994090647],
            [126.842381115588, 37.715812140813],
            [126.842808570893, 37.715396237013],
            [126.842949869622, 37.7151485627],
            [126.843110335745, 37.714857658636],
            [126.843185449276, 37.714623517666],
            [126.843190144633, 37.714420969037],
            [126.843258619745, 37.714076279693],
            [126.843338670997, 37.713679484991],
            [126.843372132399, 37.713495841118],
            [126.843439570427, 37.713152717902],
            [126.843478811759, 37.712874633551],
            [126.843515755501, 37.712647630824],
            [126.84353928052, 37.712453630473],
            [126.843521784898, 37.712110059962],
            [126.843547314129, 37.711928135213],
            [126.843775587209, 37.711151574408],
            [126.843875625122, 37.710744651993],
            [126.843869992662, 37.710732094013],
            [126.843865336999, 37.710718834585],
            [126.843845408929, 37.710596799187],
            [126.843815214164, 37.710412132945],
            [126.843882936186, 37.710264573263],
            [126.84400048511, 37.710118864026],
            [126.844035358778, 37.710075529115],
            [126.844154762456, 37.709931957484],
            [126.845027509895, 37.708884521043],
            [126.845301431719, 37.708591439696],
            [126.845515539563, 37.708342768271],
            [126.84563319627, 37.708120961912],
            [126.845784722285, 37.707803364414],
            [126.845994069385, 37.707399827752],
            [126.846163590192, 37.707060098701],
            [126.846558839641, 37.706141263888],
            [126.846804550406, 37.705708023538],
            [126.847039718308, 37.705200772461],
            [126.847272191199, 37.704859736646],
            [126.847399365049, 37.704592946565],
            [126.847672931534, 37.704212708512],
            [126.847946713699, 37.703803522019],
            [126.848106153878, 37.703532322367],
            [126.8479124267, 37.70293863042],
            [126.84780689787, 37.702676167418],
            [126.84775460351, 37.702454749711],
            [126.847885588028, 37.702151862217],
            [126.84797817797, 37.701892044852],
            [126.848033005743, 37.701698831792],
            [126.848144387922, 37.701459112278],
            [126.848346648896, 37.701238566183],
            [126.848661999593, 37.700922276455],
            [126.849018928525, 37.70055071125],
            [126.8492420369, 37.700147402315],
            [126.849314405836, 37.699930020278],
            [126.84989665139, 37.699171678375],
            [126.850362898792, 37.698664834081],
            [126.850809587739, 37.698098273799],
            [126.851135919248, 37.69767237958],
            [126.851293688442, 37.697492107845],
            [126.851696798228, 37.697067273843],
            [126.851907538691, 37.696881599679],
            [126.852111682771, 37.696688393694],
            [126.85249071434, 37.696330207033],
            [126.85266082546, 37.696223495024],
            [126.852889982715, 37.696102099348],
            [126.853128529863, 37.695979543782],
            [126.853243601539, 37.695912908784],
            [126.853385418741, 37.695864317621],
            [126.853576476008, 37.695800120238],
            [126.853731473477, 37.695748842288],
            [126.854277717325, 37.695542888586],
            [126.854707394968, 37.69533885951],
            [126.854934969458, 37.695194762482],
            [126.855263429048, 37.694988830599],
            [126.855414666828, 37.694887496988],
            [126.855678279056, 37.694658707103],
            [126.855889689393, 37.694278480505],
            [126.855972150409, 37.694088306834],
            [126.855992260201, 37.693719204965],
            [126.856007991317, 37.693443933516],
            [126.856023133423, 37.693266956972],
            [126.856070384107, 37.692730542647],
            [126.855923234364, 37.692552987413],
            [126.855771758162, 37.692367615247],
            [126.855603737183, 37.692166626646],
            [126.855308805573, 37.691674116305],
            [126.855230549281, 37.691542423786],
            [126.855134842187, 37.69137719352],
            [126.855074014845, 37.691290570955],
            [126.85506389117, 37.691278521442],
            [126.854975676762, 37.691586930539],
            [126.854890461665, 37.69167881351],
            [126.854805251947, 37.691652759639],
            [126.854805122637, 37.691603296303],
            [126.854671845623, 37.691517087923],
            [126.853431414283, 37.689823274323],
            [126.853876906268, 37.689701769623],
            [126.853715540632, 37.689426303483],
            [126.853703275904, 37.689390762908],
            [126.853659891326, 37.689257275025],
            [126.853655473876, 37.689140954397],
            [126.853659602772, 37.689117083897],
            [126.853736781129, 37.68867516525],
            [126.853747673412, 37.688613822908],
            [126.85377239826, 37.688437714438],
            [126.853838983179, 37.687962987123],
            [126.85340435454, 37.688225434955],
            [126.853309633157, 37.688252705416],
            [126.852924394411, 37.688363580431],
            [126.852837922883, 37.688403924958],
            [126.852759990164, 37.688437676088],
            [126.852688114902, 37.68851514082],
            [126.852608309313, 37.688612272657],
            [126.852414956331, 37.688733352846],
            [126.852216757489, 37.688743382153],
            [126.852073119987, 37.688705449622],
            [126.852008329635, 37.688737748328],
            [126.852001949771, 37.68880396146],
            [126.851981809909, 37.688969966486],
            [126.851959068137, 37.689121786954],
            [126.851928077974, 37.689225746492],
            [126.851918282794, 37.689327120098],
            [126.851862811901, 37.689443752219],
            [126.8518323034, 37.689477157465],
            [126.851780837959, 37.689518725903],
            [126.85164143186, 37.689495826384],
            [126.851568950401, 37.689512897704],
            [126.851549833294, 37.689547650793],
            [126.851510248269, 37.689629201198],
            [126.851457083913, 37.68965289211],
            [126.851284605932, 37.689635599158],
            [126.851123543664, 37.689652647425],
            [126.851042001636, 37.689664877673],
            [126.850843512485, 37.689727421771],
            [126.85067782755, 37.689804377948],
            [126.850577738413, 37.689804691362],
            [126.850505770966, 37.689780471285],
            [126.850321250289, 37.689680434546],
            [126.850236827286, 37.689721095119],
            [126.850135383379, 37.689813458404],
            [126.849958929644, 37.689811006375],
            [126.8498343111, 37.689858858785],
            [126.849616560978, 37.689880922541],
            [126.849448839638, 37.690004922955],
            [126.849284273564, 37.690126990117],
            [126.849277141353, 37.690304868177],
            [126.849125594824, 37.690430132338],
            [126.84912629144, 37.690399977815],
            [126.849085481178, 37.690225578617],
            [126.849049372721, 37.690196881133],
            [126.84898572783, 37.689895595967],
            [126.848961325484, 37.689786736383],
            [126.848928405772, 37.689640556653],
            [126.848905040573, 37.689534662582],
            [126.848884613503, 37.689444566291],
            [126.848862039338, 37.689339997656],
            [126.848842587085, 37.689250055782],
            [126.84879722032, 37.689037007995],
            [126.848758250883, 37.688866845582],
            [126.848702120854, 37.688617312578],
            [126.848651953828, 37.688384761524],
            [126.848643329796, 37.688338755877],
            [126.848635031914, 37.688299561978],
            [126.848430992845, 37.687367415955],
            [126.848235722683, 37.686484726029],
            [126.848226139398, 37.686486389376],
            [126.848209446641, 37.686433507809],
            [126.848376259998, 37.686411849002],
            [126.848348469938, 37.686374629909],
            [126.848258156513, 37.686386675665],
            [126.848223389804, 37.686357105716],
            [126.848199398433, 37.686187376956],
            [126.848143458343, 37.686155797322],
            [126.848157954555, 37.686091613097],
            [126.848119244799, 37.686066542835],
            [126.84807654385, 37.686036250755],
            [126.847889202905, 37.6860014637],
            [126.847813614074, 37.685987301079],
            [126.847663480312, 37.685942119916],
            [126.847434141673, 37.68584436265],
            [126.847075113726, 37.685612785374],
            [126.847030083179, 37.685590949965],
            [126.846432368443, 37.685626798908],
            [126.845973004389, 37.685670638775],
            [126.845517974892, 37.686004702836],
            [126.845249144484, 37.68612668956],
            [126.845116581084, 37.686191086306],
            [126.844944620524, 37.686277333168],
            [126.844490104145, 37.686487744884],
            [126.844248626711, 37.68665810273],
            [126.84400696444, 37.686540068591],
            [126.843915252975, 37.686483725588],
            [126.843895146333, 37.686411531079],
            [126.84377962275, 37.686282348815],
            [126.843520623693, 37.685893801478],
            [126.843233232752, 37.685487123981],
            [126.843212487932, 37.685475599768],
            [126.842989080839, 37.68538017533],
            [126.842536319176, 37.685156197529],
            [126.842343024379, 37.685108671949],
            [126.842038678767, 37.685360550251],
            [126.841705405369, 37.685551392934],
            [126.841584563613, 37.685377612324],
            [126.841307061265, 37.684975457376],
            [126.841105715516, 37.684745085096],
            [126.840433898323, 37.684030078984],
            [126.840080944602, 37.683557299097],
            [126.840019825564, 37.683459361106],
            [126.840282788324, 37.682978521984],
            [126.840306870936, 37.68280917259],
            [126.83969839808, 37.682310949914],
            [126.839360007604, 37.682590632697],
            [126.839292417682, 37.682627803858],
            [126.839108699885, 37.682673716704],
            [126.838380175501, 37.68287262742],
            [126.838163456987, 37.682928331728],
            [126.837866827697, 37.682921613716],
            [126.837062873759, 37.682900358751],
            [126.836665829415, 37.682902065879],
            [126.836010883672, 37.682931907707],
            [126.835864896935, 37.68295996617],
            [126.835498513378, 37.683029735641],
            [126.834748476218, 37.683160191998],
            [126.834678457933, 37.683343935825],
            [126.834363948265, 37.683661082276],
            [126.834224864181, 37.683804950104],
            [126.834021146186, 37.684005478059],
            [126.833639725892, 37.684380919322],
            [126.833027643302, 37.684735839294],
            [126.833234743536, 37.685147877981],
            [126.832348297437, 37.685203641151],
            [126.831603996328, 37.685516108484],
            [126.831232818085, 37.685965066678],
            [126.83097564584, 37.686283277767],
            [126.831015432631, 37.686484161309],
            [126.83114511641, 37.687208412496],
            [126.831016670312, 37.687553072738],
            [126.830957067137, 37.687712863488],
            [126.830875583539, 37.687946501994],
            [126.83115885078, 37.68821738237],
            [126.831472880009, 37.688547184724],
            [126.831539520109, 37.688632197099],
            [126.831817158558, 37.689086775276],
            [126.831974024567, 37.68918128727],
            [126.831942915653, 37.689574109928],
            [126.831928762, 37.689815008583],
            [126.831945058351, 37.689872919249],
            [126.831972196487, 37.689969361905],
            [126.832003130027, 37.690067026317],
            [126.832051817014, 37.690245037577],
            [126.832057746218, 37.690280093772],
            [126.832065567364, 37.690330739441],
            [126.832099959786, 37.690861729578],
            [126.831785102223, 37.691063994733],
            [126.831635456581, 37.691207213427],
            [126.830910110083, 37.691379332435],
            [126.830837300723, 37.691393147042],
            [126.830295819264, 37.691460745611],
            [126.830170178786, 37.691862295879],
            [126.830121180963, 37.692042517433],
            [126.829961003981, 37.692128642331],
            [126.829866907393, 37.692395462283]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281118',
      properties: {
        rgnCode: '41281118',
        colCode: '41280',
        rgnKo: ['관산동'],
        rgnSize: 1,
        rgnBbox: [
          126.845994069385, 37.684320779721, 126.876224501063, 37.719118181337
        ],
        rgnCenter: [126.862328340514, 37.701611509582],
        rgnArea: 6200995,
        predVal: [
          0.19748, 0.20081, 0.19215, 0.21579, 0.27036, 0.24582, 0.2697, 0.28364,
          0.24426, 0.25714, 0.25628, 0.19622, 0.28205, 0.25069, 0.2851, 0.11383,
          0.2643, 0.24249, 0.31282, 0.27317, 0.2798, 0.28468, 0.24703, 0.27975,
          0.22394, 0.27289, 0.24943, 0.25682, 0.2511, 0.2876, 0.27644
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.854671845623, 37.691517087923],
            [126.854805122637, 37.691603296303],
            [126.854805251947, 37.691652759639],
            [126.854890461665, 37.69167881351],
            [126.854975676762, 37.691586930539],
            [126.855064678901, 37.691275765456],
            [126.855074014845, 37.691290570955],
            [126.855134842187, 37.69137719352],
            [126.855230549281, 37.691542423786],
            [126.855308805573, 37.691674116305],
            [126.855603737183, 37.692166626646],
            [126.855771758162, 37.692367615247],
            [126.855923234364, 37.692552987413],
            [126.856070384107, 37.692730542647],
            [126.856023133423, 37.693266956972],
            [126.856007991317, 37.693443933516],
            [126.855992260201, 37.693719204965],
            [126.855972150409, 37.694088306834],
            [126.855889689393, 37.694278480505],
            [126.855678279056, 37.694658707103],
            [126.855414666828, 37.694887496988],
            [126.855263429048, 37.694988830599],
            [126.854934969458, 37.695194762482],
            [126.854707394968, 37.69533885951],
            [126.854277717325, 37.695542888586],
            [126.853731473477, 37.695748842288],
            [126.853576476008, 37.695800120238],
            [126.853385418741, 37.695864317621],
            [126.853243601539, 37.695912908784],
            [126.853128529863, 37.695979543782],
            [126.852889982715, 37.696102099348],
            [126.85266082546, 37.696223495024],
            [126.85249071434, 37.696330207033],
            [126.852111682771, 37.696688393694],
            [126.851907538691, 37.696881599679],
            [126.851696798228, 37.697067273843],
            [126.851293688442, 37.697492107845],
            [126.851135919248, 37.69767237958],
            [126.850809587739, 37.698098273799],
            [126.850362898792, 37.698664834081],
            [126.84989665139, 37.699171678375],
            [126.849314405836, 37.699930020278],
            [126.8492420369, 37.700147402315],
            [126.849018928525, 37.70055071125],
            [126.848661999593, 37.700922276455],
            [126.848346648896, 37.701238566183],
            [126.848144387922, 37.701459112278],
            [126.848033005743, 37.701698831792],
            [126.84797817797, 37.701892044852],
            [126.847885588028, 37.702151862217],
            [126.84775460351, 37.702454749711],
            [126.84780689787, 37.702676167418],
            [126.8479124267, 37.70293863042],
            [126.848106153878, 37.703532322367],
            [126.847946713699, 37.703803522019],
            [126.847672931534, 37.704212708512],
            [126.847399365049, 37.704592946565],
            [126.847272191199, 37.704859736646],
            [126.847039718308, 37.705200772461],
            [126.846804550406, 37.705708023538],
            [126.846558839641, 37.706141263888],
            [126.846163590192, 37.707060098701],
            [126.845994069385, 37.707399827752],
            [126.846373932281, 37.70756451121],
            [126.846638037449, 37.707727816135],
            [126.846839698141, 37.707893786513],
            [126.847126577376, 37.707958518494],
            [126.847195234732, 37.707973636485],
            [126.847368820541, 37.708012677159],
            [126.847517151902, 37.70804602653],
            [126.847497641063, 37.708070534349],
            [126.847468159525, 37.708101714306],
            [126.847455657399, 37.708115086326],
            [126.847399669358, 37.708186495799],
            [126.847385603575, 37.708199217072],
            [126.847324948933, 37.708240465077],
            [126.847249161963, 37.708376709121],
            [126.84715041597, 37.708553944034],
            [126.847098715952, 37.708646279399],
            [126.847043100097, 37.708745637143],
            [126.847027381569, 37.708778700016],
            [126.847337613424, 37.70877279031],
            [126.847404162066, 37.708771561899],
            [126.847689492824, 37.708764339347],
            [126.849918313439, 37.708712852729],
            [126.85139667341, 37.708678534378],
            [126.851463246461, 37.708676375712],
            [126.853361672164, 37.70862860158],
            [126.853787900474, 37.708607702386],
            [126.854107168304, 37.70859092955],
            [126.853928164431, 37.708656719402],
            [126.853733141083, 37.708742850735],
            [126.853656744929, 37.708768964057],
            [126.853536983917, 37.708834341067],
            [126.853505739626, 37.70885203286],
            [126.853436125113, 37.708849548884],
            [126.853332410865, 37.708931893006],
            [126.853407710108, 37.708978432406],
            [126.85350476967, 37.70904892873],
            [126.853773447434, 37.709221593435],
            [126.854008168775, 37.709350455139],
            [126.854118573999, 37.709605836824],
            [126.854203262236, 37.709791001035],
            [126.854203132618, 37.709799190652],
            [126.85419630856, 37.71026742303],
            [126.854191560236, 37.7103172405],
            [126.854261646239, 37.710374449275],
            [126.854740308496, 37.710522372008],
            [126.854903606528, 37.710584570621],
            [126.855268756359, 37.710717682284],
            [126.855331702579, 37.710738356417],
            [126.855608896854, 37.710835544558],
            [126.855634012976, 37.710849054082],
            [126.855887080057, 37.711065229271],
            [126.855853390615, 37.7112658417],
            [126.8559588432, 37.711541586299],
            [126.855976292319, 37.711561320964],
            [126.856640017861, 37.712063624775],
            [126.856684185255, 37.712113241146],
            [126.856696099629, 37.712214893692],
            [126.856771224799, 37.712674379186],
            [126.856890095487, 37.713593960132],
            [126.85691463722, 37.713839440182],
            [126.856950534875, 37.714211744984],
            [126.856957253075, 37.714314697495],
            [126.85696705948, 37.714421771201],
            [126.857277739147, 37.71543690858],
            [126.857293440578, 37.715487111535],
            [126.857333421117, 37.715615025375],
            [126.857343861574, 37.715641076028],
            [126.857947702122, 37.715850197035],
            [126.858068086041, 37.716053853508],
            [126.858084213785, 37.716083289632],
            [126.858101585089, 37.716120700804],
            [126.858203504094, 37.716283944416],
            [126.858432089109, 37.716517076398],
            [126.858413841174, 37.716882233286],
            [126.858603296534, 37.717069692562],
            [126.858714619473, 37.717180177605],
            [126.858939065187, 37.717172682925],
            [126.859179775668, 37.717336751129],
            [126.859323224923, 37.71743868818],
            [126.860300412247, 37.717445754969],
            [126.861220291146, 37.717241433222],
            [126.861447609846, 37.717328430363],
            [126.86165765239, 37.717495502331],
            [126.861685953792, 37.717487598402],
            [126.862136525837, 37.717435857951],
            [126.86216192005, 37.717496486853],
            [126.862288079084, 37.717621752864],
            [126.862651669946, 37.717613045885],
            [126.862932075239, 37.717448346118],
            [126.86312596555, 37.71723402714],
            [126.863498856063, 37.717004483984],
            [126.86393551276, 37.7169867498],
            [126.864775748903, 37.717194165349],
            [126.865126219275, 37.717145973264],
            [126.865585892474, 37.717343554127],
            [126.865788040907, 37.717347994521],
            [126.865989404732, 37.717366011206],
            [126.8666077179, 37.717727394529],
            [126.867547983282, 37.718438426822],
            [126.867599494585, 37.718453243143],
            [126.867620638816, 37.718458006232],
            [126.867896273405, 37.718476040915],
            [126.868088861264, 37.718488196807],
            [126.868632523876, 37.718452556007],
            [126.869015391946, 37.718683687904],
            [126.869408545714, 37.718776514],
            [126.869499769468, 37.71894282612],
            [126.869673928085, 37.719102104308],
            [126.86987085494, 37.719118181337],
            [126.869540421917, 37.718039924922],
            [126.86978767154, 37.717696447366],
            [126.869858954061, 37.717620179238],
            [126.870374633496, 37.717350428499],
            [126.870483035558, 37.717294193679],
            [126.870879391276, 37.717000765828],
            [126.87110677885, 37.716912155398],
            [126.871158701581, 37.716879678977],
            [126.871165218778, 37.716817402482],
            [126.871198369605, 37.716623758709],
            [126.871227670135, 37.716478474463],
            [126.871336529546, 37.716250506427],
            [126.870862914918, 37.715842845474],
            [126.870490716665, 37.715367029219],
            [126.870478290002, 37.715261926841],
            [126.870472590643, 37.715123622363],
            [126.870471740979, 37.715013613577],
            [126.870555318997, 37.715012030646],
            [126.870682802809, 37.715015190414],
            [126.87076995589, 37.715005727853],
            [126.870792427617, 37.715003284126],
            [126.87100714306, 37.714984502621],
            [126.871071188146, 37.714997511356],
            [126.871237778692, 37.715064655279],
            [126.871320823404, 37.715128057832],
            [126.871522016925, 37.715234395502],
            [126.871537753315, 37.715257081133],
            [126.871884239785, 37.71527449985],
            [126.871922004089, 37.715276163103],
            [126.87219131721, 37.715316569782],
            [126.87224758183, 37.715297332811],
            [126.872403992927, 37.715290566766],
            [126.872639802952, 37.715278932022],
            [126.872710385054, 37.715263557601],
            [126.872625473301, 37.715170710446],
            [126.872640354184, 37.71515712214],
            [126.872650902015, 37.715143880472],
            [126.872880977401, 37.714854029945],
            [126.872914833321, 37.714833344678],
            [126.87310304999, 37.714718406472],
            [126.873175031986, 37.714673526685],
            [126.873356689278, 37.714560472957],
            [126.873384730859, 37.714544015771],
            [126.87369783129, 37.71416847291],
            [126.873691404346, 37.714133373344],
            [126.873715967649, 37.714081720602],
            [126.873718864417, 37.714072362713],
            [126.873792524855, 37.713709803983],
            [126.873913491851, 37.713603359769],
            [126.874268439981, 37.713291009278],
            [126.874626497953, 37.713188342739],
            [126.874739556749, 37.713155921488],
            [126.875312435851, 37.713268345897],
            [126.875902998271, 37.713091513384],
            [126.876224501063, 37.71299524494],
            [126.8761708189, 37.71263434218],
            [126.876163910959, 37.71258793511],
            [126.875876704471, 37.71215409378],
            [126.875255533122, 37.712101003037],
            [126.875111218275, 37.711880913684],
            [126.874894209749, 37.711187045352],
            [126.87426529235, 37.710568864326],
            [126.874596145149, 37.709985620271],
            [126.874511539329, 37.709602681697],
            [126.874932626017, 37.708957439174],
            [126.874376696683, 37.708694695931],
            [126.874357994975, 37.708522843499],
            [126.874318733586, 37.708158297257],
            [126.874275740212, 37.707754762142],
            [126.874227291613, 37.707689975422],
            [126.874170955702, 37.70761535963],
            [126.874080807138, 37.707492001032],
            [126.874059914536, 37.707461075305],
            [126.873949540264, 37.707308332246],
            [126.873631245093, 37.707154192498],
            [126.87355340228, 37.707116501708],
            [126.873533691616, 37.707104064999],
            [126.873269389279, 37.706956361704],
            [126.872911584386, 37.706174113302],
            [126.87286776478, 37.705860006584],
            [126.873065394271, 37.705771026351],
            [126.873246083182, 37.705417728802],
            [126.87355131886, 37.705274419341],
            [126.87361146998, 37.704968606415],
            [126.87410001838, 37.704384795338],
            [126.874035679257, 37.703939341851],
            [126.874023421506, 37.703866197117],
            [126.873272750823, 37.703103291189],
            [126.872713127484, 37.702349887216],
            [126.87267717901, 37.702180790473],
            [126.872273192067, 37.701530940243],
            [126.872283333116, 37.701006309109],
            [126.872259491546, 37.70087279566],
            [126.872159183602, 37.700324888495],
            [126.871367118074, 37.699753010797],
            [126.870483441696, 37.699054268842],
            [126.87002189482, 37.698381876471],
            [126.869942765226, 37.698257265461],
            [126.869738269038, 37.698102935593],
            [126.868758163806, 37.697979133448],
            [126.868466791148, 37.697774871004],
            [126.868576244182, 37.696790993407],
            [126.868804345603, 37.696580459878],
            [126.868807574056, 37.696550371181],
            [126.868568901828, 37.696407127735],
            [126.868522165358, 37.696292111519],
            [126.868549807312, 37.695956487054],
            [126.868239563439, 37.695518428513],
            [126.868803315517, 37.695008233388],
            [126.86900199679, 37.694015328499],
            [126.869577737151, 37.693768748337],
            [126.869795433633, 37.693510918308],
            [126.870123566201, 37.693122282222],
            [126.870626978235, 37.69292922391],
            [126.870968895384, 37.692305231687],
            [126.871383663398, 37.692161805419],
            [126.871748635547, 37.691848039819],
            [126.872303865862, 37.69146228706],
            [126.872316832541, 37.691176964449],
            [126.872289238821, 37.69024770193],
            [126.872703286745, 37.689988108238],
            [126.872892668784, 37.689871143925],
            [126.872924420372, 37.689132131632],
            [126.873585604261, 37.688615910655],
            [126.873638353049, 37.688578811894],
            [126.873709614354, 37.688522740741],
            [126.873764313131, 37.688512033425],
            [126.873788803359, 37.688438504823],
            [126.873804223048, 37.688438034999],
            [126.873815712173, 37.688415676374],
            [126.873831009588, 37.688380717311],
            [126.873826633362, 37.688334420772],
            [126.873668816167, 37.687403521433],
            [126.873680106737, 37.68739769539],
            [126.873676664161, 37.687395394186],
            [126.873421634485, 37.687031738463],
            [126.873065809533, 37.686674198125],
            [126.872555945102, 37.686261096745],
            [126.871976530679, 37.685875810781],
            [126.871489664101, 37.685562989908],
            [126.87145676854, 37.685541024033],
            [126.870939152741, 37.685407288747],
            [126.870663239221, 37.685171992014],
            [126.870412066092, 37.685167676717],
            [126.86967868835, 37.685163525579],
            [126.869073981062, 37.685161406486],
            [126.867818882439, 37.685155161123],
            [126.867244685373, 37.685151679742],
            [126.866082760656, 37.685147918526],
            [126.866157055047, 37.68537676002],
            [126.865900089858, 37.685365373873],
            [126.865669942956, 37.68535672998],
            [126.865442219905, 37.685349349792],
            [126.864941725079, 37.685350879526],
            [126.864752844448, 37.685354778073],
            [126.864552067519, 37.685373087027],
            [126.864361688679, 37.6853594143],
            [126.864000059468, 37.68528754629],
            [126.863582795161, 37.685243145748],
            [126.863334532665, 37.685185526428],
            [126.863150273796, 37.685142775616],
            [126.862792276972, 37.684947898774],
            [126.86266283207, 37.684862965193],
            [126.862620416065, 37.684835129341],
            [126.862133481791, 37.684613429864],
            [126.861792622088, 37.684483962612],
            [126.861547056546, 37.684423036161],
            [126.861226300279, 37.684340964117],
            [126.860981894516, 37.684320779721],
            [126.860375407521, 37.684412089525],
            [126.859946761911, 37.684520097566],
            [126.85950048334, 37.684629650503],
            [126.859075966285, 37.684741255214],
            [126.858917286146, 37.684781796296],
            [126.858776806602, 37.684819674304],
            [126.858599904842, 37.684985833121],
            [126.858417284096, 37.685053959233],
            [126.857913984782, 37.685240974394],
            [126.857681355451, 37.685326769541],
            [126.857484222604, 37.68540030935],
            [126.856881891116, 37.685516743286],
            [126.856219021452, 37.685794264999],
            [126.855811042704, 37.685947566157],
            [126.855699611509, 37.685993639158],
            [126.855015866763, 37.6862916073],
            [126.854863345747, 37.686425671186],
            [126.854727986763, 37.686577919775],
            [126.854578777232, 37.686745377329],
            [126.854363633959, 37.686933781063],
            [126.854229186713, 37.687271828081],
            [126.854137814873, 37.687429086227],
            [126.85404850394, 37.687587401007],
            [126.853838983179, 37.687962987123],
            [126.85377239826, 37.688437714438],
            [126.853747673412, 37.688613822908],
            [126.853736781129, 37.68867516525],
            [126.853659602772, 37.689117083897],
            [126.853655473876, 37.689140954397],
            [126.853659891326, 37.689257275025],
            [126.853703275904, 37.689390762908],
            [126.853715540632, 37.689426303483],
            [126.853873174152, 37.689702801065],
            [126.853431414283, 37.689823274323],
            [126.854671845623, 37.691517087923]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281128',
      properties: {
        rgnCode: '41281128',
        colCode: '41280',
        rgnKo: ['행신동'],
        rgnSize: 1,
        rgnBbox: [
          126.824965992558, 37.610576834174, 126.855010623878, 37.630565221177
        ],
        rgnCenter: [126.840703697485, 37.619578954684],
        rgnArea: 3735524,
        predVal: [
          0.52661, 0.5355, 0.5124, 0.57545, 0.72095, 0.65552, 0.71919, 0.75637,
          0.65136, 0.68572, 0.68342, 0.52326, 0.75213, 0.66851, 0.76027,
          0.30354, 0.7048, 0.64665, 0.83418, 0.72846, 0.74613, 0.75914, 0.65875,
          0.74601, 0.59717, 0.72772, 0.66514, 0.68487, 0.66959, 0.76695, 0.73717
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.84825200005, 37.628823230213],
            [126.848322065005, 37.62864152182],
            [126.848412067851, 37.628393329039],
            [126.84844697805, 37.628273544224],
            [126.848508243967, 37.628062254182],
            [126.84856766249, 37.627851231998],
            [126.848571556499, 37.627493287358],
            [126.848512471654, 37.627215610307],
            [126.848433671404, 37.626863045338],
            [126.848385018436, 37.626724501817],
            [126.848301318608, 37.62649124696],
            [126.848252003118, 37.626361135675],
            [126.848288812188, 37.626143732303],
            [126.848334346025, 37.625869911946],
            [126.848336742631, 37.625651490806],
            [126.848319098356, 37.625616158547],
            [126.848264550585, 37.625524359078],
            [126.848215901945, 37.625439441715],
            [126.848176215609, 37.625370149937],
            [126.848148456555, 37.625334417086],
            [126.848103429086, 37.625290021392],
            [126.847977727981, 37.625156071573],
            [126.847986901691, 37.625019251868],
            [126.848002050126, 37.624786341536],
            [126.847976744077, 37.62462106009],
            [126.847951490881, 37.624452165778],
            [126.847922926524, 37.624246480179],
            [126.847919909791, 37.624127096552],
            [126.847914947873, 37.624006178725],
            [126.847999752801, 37.623749690286],
            [126.848062779365, 37.62356236864],
            [126.848064101839, 37.623481093046],
            [126.848069141104, 37.62329444378],
            [126.848074367491, 37.623066340705],
            [126.848078186618, 37.622894628071],
            [126.848082964765, 37.622697202736],
            [126.848085974459, 37.622616181598],
            [126.848157344387, 37.622364504761],
            [126.848229667631, 37.622106188892],
            [126.848297931805, 37.62175569752],
            [126.848299517961, 37.621435401446],
            [126.848544066658, 37.621466054684],
            [126.848588016276, 37.621471607623],
            [126.848781425536, 37.621561793864],
            [126.84906831094, 37.621583887443],
            [126.849394126682, 37.62160676932],
            [126.849640209366, 37.621624421133],
            [126.850027223774, 37.621648722469],
            [126.850324183155, 37.621585268934],
            [126.850462188612, 37.62155453307],
            [126.850779749918, 37.621654560422],
            [126.850986885282, 37.621725092204],
            [126.851072491317, 37.621755924767],
            [126.851156540713, 37.621817298482],
            [126.851359973382, 37.621966417311],
            [126.851569352865, 37.622115957793],
            [126.85175610184, 37.622090093576],
            [126.851810049238, 37.622035932088],
            [126.851835527624, 37.622039964756],
            [126.85196277275, 37.622076886042],
            [126.852085983116, 37.622148940272],
            [126.852221826627, 37.622247859537],
            [126.852268545396, 37.622392796091],
            [126.85234321846, 37.622550021282],
            [126.852420457902, 37.622613457733],
            [126.852505856272, 37.622618611085],
            [126.852547083082, 37.622621095781],
            [126.852597198139, 37.622653864579],
            [126.852659253612, 37.622606461274],
            [126.852715023282, 37.622586980364],
            [126.852964786535, 37.622589997984],
            [126.853122868174, 37.622614613417],
            [126.853171370664, 37.622603952748],
            [126.853278027129, 37.622570930746],
            [126.853473391444, 37.622473509859],
            [126.854008992651, 37.622337411939],
            [126.854062156305, 37.622318017232],
            [126.854312909181, 37.622220844343],
            [126.85432557482, 37.622231491697],
            [126.854489409884, 37.622152860255],
            [126.854483827057, 37.6221463212],
            [126.854467394966, 37.622127100856],
            [126.854456828266, 37.622114735263],
            [126.854439774607, 37.622094793357],
            [126.854125600593, 37.621727180224],
            [126.853984358918, 37.621561908273],
            [126.853971407818, 37.621546764638],
            [126.853756416154, 37.621295203478],
            [126.853733141195, 37.621265351915],
            [126.853571570278, 37.621076556454],
            [126.853795972339, 37.620876486779],
            [126.85385386756, 37.620760837436],
            [126.853906852644, 37.620607773253],
            [126.853920903144, 37.620370373717],
            [126.853955846991, 37.620169859371],
            [126.853972842312, 37.620167447977],
            [126.854166679089, 37.620151652734],
            [126.85443798628, 37.620075858417],
            [126.854726146918, 37.619844809615],
            [126.854805107269, 37.619781442804],
            [126.854810797789, 37.619779215449],
            [126.854873473038, 37.619754579478],
            [126.855010623878, 37.619700673032],
            [126.854993115266, 37.61964725924],
            [126.854917428664, 37.619416433357],
            [126.85488685923, 37.619173202936],
            [126.854883167757, 37.61914380839],
            [126.854875472003, 37.619077045239],
            [126.854855547375, 37.618904383749],
            [126.854842548721, 37.618861697191],
            [126.854739404324, 37.618522854603],
            [126.854731619055, 37.618219394908],
            [126.854728312348, 37.61811532762],
            [126.854720652376, 37.617863404111],
            [126.854685215179, 37.617682803645],
            [126.85465168241, 37.617513017293],
            [126.854660382552, 37.617120741426],
            [126.854918602944, 37.616845272486],
            [126.854998860537, 37.616725181414],
            [126.8547341934, 37.616189535168],
            [126.854552972619, 37.615785580395],
            [126.854627390023, 37.615611243269],
            [126.854537862916, 37.615521033773],
            [126.854486609206, 37.614716709826],
            [126.85457059095, 37.614683243898],
            [126.854584419416, 37.614677909292],
            [126.854575946237, 37.614661239614],
            [126.854437814963, 37.614389385904],
            [126.854437592232, 37.614381709273],
            [126.854450185948, 37.614261426056],
            [126.854462511334, 37.614145097806],
            [126.854464289783, 37.614104709066],
            [126.854471977398, 37.614042866334],
            [126.854475581836, 37.614012715007],
            [126.854453879156, 37.61397915347],
            [126.854252146749, 37.613653422689],
            [126.854232309743, 37.613621548271],
            [126.854182906995, 37.613534695146],
            [126.854115880056, 37.61344262133],
            [126.854050066674, 37.613349783157],
            [126.85360010154, 37.612695036007],
            [126.853550723637, 37.612602037948],
            [126.853381737542, 37.612181419327],
            [126.853549798729, 37.612017020969],
            [126.85361794838, 37.611941505094],
            [126.853687536156, 37.611860323811],
            [126.853734802476, 37.611612252881],
            [126.854715236049, 37.610881339409],
            [126.854673691754, 37.610813173561],
            [126.854563283971, 37.610631848852],
            [126.854342170878, 37.610737123246],
            [126.854230193577, 37.610576834174],
            [126.854004917252, 37.61069683378],
            [126.853736563384, 37.610836780798],
            [126.853038158341, 37.611206711918],
            [126.852542557336, 37.61146256058],
            [126.852204113895, 37.611636706926],
            [126.851906696609, 37.611785343484],
            [126.851467266285, 37.611944160093],
            [126.851107800519, 37.612038044208],
            [126.850977433104, 37.612069817755],
            [126.850641879978, 37.612149990991],
            [126.850368494692, 37.612190464609],
            [126.850188768171, 37.612216921338],
            [126.849850291959, 37.612263391893],
            [126.849359008885, 37.612314511838],
            [126.849152346472, 37.612314371345],
            [126.849069101784, 37.612314236813],
            [126.848849518443, 37.612313187127],
            [126.848441330637, 37.612309784255],
            [126.848093745194, 37.612301971887],
            [126.847886772116, 37.61229871138],
            [126.847660029795, 37.612292703778],
            [126.847285330625, 37.612284808628],
            [126.846941620523, 37.612281665012],
            [126.846387611472, 37.612273450931],
            [126.846071868675, 37.612268521559],
            [126.845965078487, 37.612267975411],
            [126.845672807016, 37.612266490623],
            [126.845546490638, 37.612266161574],
            [126.845273754636, 37.612265449427],
            [126.844927050807, 37.61226416101],
            [126.844805615066, 37.612264216056],
            [126.844646665439, 37.612265563579],
            [126.844305100298, 37.612265820842],
            [126.844163043436, 37.612264343117],
            [126.844062144231, 37.612263073307],
            [126.843946740761, 37.612259846938],
            [126.843513639298, 37.612255195452],
            [126.843367572587, 37.612253972685],
            [126.842927691791, 37.612247075532],
            [126.842521963617, 37.612243078865],
            [126.842330462216, 37.612239739359],
            [126.842225613337, 37.612237075141],
            [126.841816016945, 37.612230683227],
            [126.841410857579, 37.612225584313],
            [126.841338873495, 37.612224234271],
            [126.840743247256, 37.612219351919],
            [126.840501278998, 37.612215598311],
            [126.839907338843, 37.612211452846],
            [126.83975287191, 37.612208664415],
            [126.839750878648, 37.612208625644],
            [126.839470202673, 37.612203086976],
            [126.839055269038, 37.61219817378],
            [126.839021881543, 37.612197695343],
            [126.838899758175, 37.612196310806],
            [126.838669401392, 37.612191488265],
            [126.838508690777, 37.612190185218],
            [126.838316382986, 37.61218802738],
            [126.838232007424, 37.612187189885],
            [126.837924754528, 37.612179556532],
            [126.837739921014, 37.612177768507],
            [126.837696316927, 37.61217761789],
            [126.837556903988, 37.612174000559],
            [126.837337641294, 37.612171623479],
            [126.837170249133, 37.61217003902],
            [126.836955965444, 37.612169650322],
            [126.836033751501, 37.612153233328],
            [126.835566821104, 37.612148523259],
            [126.834787873838, 37.612144704402],
            [126.834237023755, 37.612137996444],
            [126.83340199396, 37.612112843812],
            [126.833052697716, 37.61211145375],
            [126.832488521981, 37.612107799963],
            [126.832150451237, 37.612115703408],
            [126.831862527846, 37.612120065052],
            [126.831659660223, 37.612129873238],
            [126.831594496148, 37.612147735973],
            [126.831224169164, 37.612200360039],
            [126.831050337144, 37.612221633503],
            [126.830898947805, 37.612240146119],
            [126.830850323744, 37.612246094363],
            [126.830821771745, 37.612250864305],
            [126.830677335572, 37.612289523608],
            [126.830499973335, 37.612323972495],
            [126.83024367748, 37.61237481255],
            [126.829808956117, 37.61251819357],
            [126.829541058112, 37.612659005142],
            [126.828991247717, 37.612950940205],
            [126.828473470806, 37.613244352222],
            [126.828402127998, 37.613298288392],
            [126.828028176219, 37.613567096323],
            [126.827767592602, 37.613759360561],
            [126.827506597439, 37.613957543048],
            [126.827291486301, 37.614120330119],
            [126.82686970775, 37.614442248574],
            [126.82625718281, 37.614908093248],
            [126.82607784194, 37.615043622486],
            [126.825929781128, 37.615156114885],
            [126.825734005716, 37.615305611359],
            [126.825411310421, 37.615554431409],
            [126.825182000137, 37.615730780168],
            [126.825070551121, 37.615811269308],
            [126.824965992558, 37.615894750894],
            [126.825154051415, 37.61597815649],
            [126.825180517287, 37.616008595099],
            [126.82539738229, 37.61625900392],
            [126.825524257888, 37.616587149847],
            [126.825564947765, 37.616623429909],
            [126.825734376714, 37.616774326217],
            [126.825994787668, 37.616816429185],
            [126.826058799457, 37.616809226438],
            [126.826132278237, 37.616773927158],
            [126.82674450268, 37.616762762309],
            [126.826849735677, 37.616710210793],
            [126.827133684387, 37.616567645228],
            [126.827443370235, 37.616704961268],
            [126.827671195641, 37.616927028224],
            [126.828038655548, 37.617110467856],
            [126.828036429549, 37.617434817277],
            [126.828036097938, 37.617480045969],
            [126.82796557616, 37.617607701194],
            [126.827914789057, 37.617699589485],
            [126.82790631503, 37.617714929712],
            [126.827887159794, 37.617749823501],
            [126.827863759666, 37.617798198705],
            [126.827841054633, 37.617917815384],
            [126.827686792955, 37.617865781929],
            [126.827649552754, 37.617855221646],
            [126.827470125922, 37.617811854291],
            [126.827361160585, 37.617793592853],
            [126.827368979242, 37.617801911423],
            [126.827330771298, 37.617827505923],
            [126.827309286104, 37.617841421373],
            [126.827246029185, 37.61788398936],
            [126.827059957012, 37.617994363735],
            [126.827011139185, 37.618023330129],
            [126.827006137743, 37.618026052701],
            [126.827058939424, 37.61806630545],
            [126.827008563018, 37.618199945343],
            [126.826961055039, 37.618269340603],
            [126.82694650588, 37.618291122786],
            [126.82687100058, 37.618330374863],
            [126.826829926084, 37.618351315902],
            [126.826912034292, 37.618360771564],
            [126.827085583023, 37.618380110962],
            [126.827231129789, 37.61839557959],
            [126.827220883641, 37.618451848647],
            [126.827328942168, 37.61846593734],
            [126.827323630755, 37.618494706785],
            [126.827316383356, 37.618508526123],
            [126.827334286072, 37.618534915209],
            [126.827316706418, 37.618564252182],
            [126.827438079307, 37.618590685827],
            [126.827426894825, 37.618621951336],
            [126.827605075198, 37.618666362052],
            [126.827624489524, 37.618671147833],
            [126.827876438423, 37.618604927429],
            [126.827870660837, 37.618615063956],
            [126.82781647436, 37.618712046731],
            [126.827727875685, 37.618892256337],
            [126.827759453702, 37.619048532671],
            [126.827776561086, 37.619134511296],
            [126.827765813222, 37.619158272318],
            [126.827751461892, 37.619192416326],
            [126.827702885748, 37.619302237246],
            [126.827687383573, 37.619339289714],
            [126.827684092488, 37.619346853092],
            [126.827650308765, 37.619413043326],
            [126.82834579583, 37.619260385911],
            [126.82835965568, 37.619257342918],
            [126.828382158346, 37.619322516745],
            [126.828704654427, 37.620390920232],
            [126.827953349166, 37.620567860117],
            [126.827957045216, 37.620600499019],
            [126.828003980815, 37.620987223248],
            [126.828079152902, 37.621618929103],
            [126.828277440538, 37.621856376064],
            [126.828292632758, 37.621899311996],
            [126.828446614086, 37.622300824951],
            [126.82814663582, 37.623259972145],
            [126.827641246672, 37.623746883296],
            [126.827353823063, 37.624022654448],
            [126.827383183788, 37.624241383314],
            [126.827496966687, 37.624364309502],
            [126.827782130451, 37.624709462116],
            [126.828065449119, 37.624850413538],
            [126.828182101525, 37.624928771865],
            [126.828348410908, 37.624945458899],
            [126.8289155948, 37.625012737044],
            [126.828987781635, 37.625181559846],
            [126.829019710777, 37.625354828648],
            [126.829153419031, 37.625234491128],
            [126.829248124268, 37.625158776044],
            [126.829446316666, 37.625005835797],
            [126.829608734077, 37.624941940869],
            [126.829777104333, 37.624924652992],
            [126.829830425024, 37.624919315701],
            [126.829919200211, 37.624944140652],
            [126.83016323298, 37.625003851736],
            [126.830139942106, 37.625280598514],
            [126.830298939417, 37.625408930444],
            [126.830305943166, 37.625452007377],
            [126.830318955661, 37.625492912659],
            [126.830330811993, 37.625499561062],
            [126.830344194112, 37.625655927266],
            [126.830351939349, 37.625731350396],
            [126.830394344141, 37.626050565479],
            [126.830462589986, 37.626097199961],
            [126.830533554891, 37.626168444063],
            [126.83054211058, 37.626226443427],
            [126.830564285315, 37.626401923068],
            [126.830586069466, 37.626411622123],
            [126.830594589198, 37.626421040678],
            [126.830639384492, 37.626399301879],
            [126.830738283492, 37.626347278408],
            [126.830834697009, 37.626292278029],
            [126.83092851114, 37.626234570879],
            [126.83127099884, 37.626016758438],
            [126.831278484427, 37.625963070924],
            [126.831259948837, 37.625944574125],
            [126.831403229714, 37.625852700775],
            [126.83293199119, 37.624872287597],
            [126.832958536484, 37.624855297023],
            [126.833903725283, 37.624249113466],
            [126.834284995487, 37.624004588197],
            [126.834633117192, 37.624348534124],
            [126.834705680542, 37.624420264529],
            [126.834957280079, 37.62466883955],
            [126.835225383433, 37.624933744998],
            [126.835463987964, 37.625169506711],
            [126.835609570504, 37.625313237372],
            [126.835874061579, 37.625574532329],
            [126.835996700123, 37.625695705831],
            [126.836038819045, 37.625909477002],
            [126.836275405479, 37.62711063253],
            [126.836347341699, 37.627467700832],
            [126.836501384434, 37.628245370424],
            [126.836489464772, 37.62835914729],
            [126.836647207955, 37.629155471995],
            [126.836700020571, 37.629247986115],
            [126.836840962158, 37.629959504959],
            [126.836907237918, 37.630317285635],
            [126.836946454125, 37.630514023788],
            [126.837133816368, 37.630519871018],
            [126.837436209587, 37.630556511207],
            [126.837544605261, 37.630565221177],
            [126.837853590509, 37.630540602969],
            [126.837896968283, 37.630545528407],
            [126.838046989966, 37.630427978542],
            [126.838147567691, 37.630349210201],
            [126.838323881739, 37.630211136065],
            [126.838420899115, 37.630138741486],
            [126.838598574059, 37.63001510248],
            [126.839019613599, 37.629722144459],
            [126.839084237097, 37.629683031887],
            [126.839127149236, 37.629657061699],
            [126.839266501424, 37.629589031346],
            [126.839474621978, 37.629487434748],
            [126.839558853102, 37.629459439878],
            [126.839716961394, 37.629424158252],
            [126.839853883574, 37.629442005091],
            [126.839946915312, 37.629477450849],
            [126.840033797479, 37.629538295697],
            [126.840053142965, 37.629550395275],
            [126.84007576922, 37.629564571588],
            [126.840220662408, 37.629458724632],
            [126.840264017721, 37.629421393302],
            [126.840347331504, 37.629398351994],
            [126.840427924865, 37.629376027694],
            [126.840516906243, 37.629351372243],
            [126.840580640985, 37.629319384371],
            [126.840709364271, 37.629199189203],
            [126.840826962854, 37.629089430088],
            [126.840990340076, 37.629184615548],
            [126.841390507486, 37.629426170885],
            [126.841544897967, 37.629486565627],
            [126.841612845689, 37.629497289266],
            [126.84163402269, 37.62950065158],
            [126.841655475433, 37.629491634827],
            [126.841694888606, 37.629475065173],
            [126.841857618005, 37.629407973404],
            [126.842150181286, 37.629378456432],
            [126.842221905829, 37.629371165261],
            [126.842265302845, 37.629366890134],
            [126.842282525836, 37.629365120431],
            [126.842382237642, 37.629354983798],
            [126.842423711674, 37.629349363562],
            [126.842612157954, 37.62932375942],
            [126.84286693723, 37.629269052493],
            [126.843105055695, 37.629217962601],
            [126.84335042739, 37.629165278209],
            [126.843370261008, 37.629161070196],
            [126.843440538679, 37.629141432945],
            [126.843642298194, 37.629199987332],
            [126.843774861184, 37.629238465197],
            [126.843876355287, 37.629242475039],
            [126.844120088953, 37.629241115578],
            [126.84440970684, 37.629237780504],
            [126.844575023326, 37.62964677423],
            [126.845015977954, 37.629568704517],
            [126.845080290714, 37.629589809615],
            [126.845014123244, 37.629599614613],
            [126.845030489802, 37.629628098214],
            [126.845054308034, 37.629630742636],
            [126.845180662624, 37.629648488193],
            [126.845732905009, 37.629615008441],
            [126.845862075995, 37.629618629652],
            [126.846200799649, 37.629628365074],
            [126.846424953606, 37.629891258956],
            [126.84674915276, 37.629787549594],
            [126.846759553236, 37.629781643809],
            [126.847155586342, 37.629644583214],
            [126.847313648529, 37.629668458574],
            [126.847373997936, 37.629612343426],
            [126.847434975633, 37.629378051646],
            [126.847427124683, 37.629131290525],
            [126.847502615431, 37.629088187181],
            [126.847509713186, 37.629079700221],
            [126.847585442368, 37.629129668175],
            [126.847773679974, 37.629198748314],
            [126.847849274466, 37.629259257367],
            [126.847874797327, 37.629275796521],
            [126.847943651809, 37.6292205661],
            [126.848060035992, 37.629084714869],
            [126.84809038852, 37.62891203683],
            [126.84810502928, 37.62884921264],
            [126.84825200005, 37.628823230213]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281129',
      properties: {
        rgnCode: '41281129',
        colCode: '41280',
        rgnKo: ['화전동'],
        rgnSize: 1,
        rgnBbox: [
          126.851736075434, 37.595932237785, 126.887988076701, 37.617797732478
        ],
        rgnCenter: [126.872255367733, 37.606744154948],
        rgnArea: 4642849,
        predVal: [
          0.39496, 0.40163, 0.3843, 0.43159, 0.54071, 0.49164, 0.53939, 0.56727,
          0.48852, 0.51429, 0.51256, 0.39245, 0.56409, 0.50139, 0.5702, 0.22766,
          0.5286, 0.48499, 0.62564, 0.54635, 0.5596, 0.56936, 0.49407, 0.55951,
          0.44788, 0.54579, 0.49886, 0.51365, 0.50219, 0.57521, 0.55288
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.858432916727, 37.609020279583],
            [126.8584871683, 37.6091073441],
            [126.858723731907, 37.609468987121],
            [126.858966250877, 37.609844656109],
            [126.859223744865, 37.610136974846],
            [126.859470941659, 37.610395809168],
            [126.859643974877, 37.610508369647],
            [126.859793031891, 37.610561481379],
            [126.859887198989, 37.610588065249],
            [126.860052480975, 37.610607904871],
            [126.860206243184, 37.610604719412],
            [126.860327671603, 37.610577159575],
            [126.860361050893, 37.610557702272],
            [126.860527603503, 37.610678425602],
            [126.860817128638, 37.610890159306],
            [126.861279333175, 37.611224991423],
            [126.86182875852, 37.611627931027],
            [126.861965127601, 37.611727155214],
            [126.862228127567, 37.611919050497],
            [126.862797913075, 37.612335587359],
            [126.863103945722, 37.612557290645],
            [126.863476959135, 37.612829382262],
            [126.863533467886, 37.613023537624],
            [126.863723247877, 37.613286251091],
            [126.86376945983, 37.613444877643],
            [126.863839569197, 37.613548608273],
            [126.86400839081, 37.613698548089],
            [126.864185299168, 37.613824260692],
            [126.864343878423, 37.613939860741],
            [126.864615834728, 37.614103695507],
            [126.864870030708, 37.614157274104],
            [126.865784914935, 37.614459870073],
            [126.866663369337, 37.614787996155],
            [126.867098281751, 37.615028891946],
            [126.867432390538, 37.615214351438],
            [126.867619465134, 37.615316365623],
            [126.867751104752, 37.615389269079],
            [126.868003950131, 37.615530459735],
            [126.868060370135, 37.615560174817],
            [126.868301636482, 37.615684926865],
            [126.868644726416, 37.615861365222],
            [126.868747856211, 37.615916981684],
            [126.868929382598, 37.616013644728],
            [126.869112915203, 37.616109760144],
            [126.869611031071, 37.616371286908],
            [126.8698442866, 37.616494269923],
            [126.870395208418, 37.616319120098],
            [126.870526792469, 37.616271289025],
            [126.870659549194, 37.616375031025],
            [126.871028618779, 37.616675042669],
            [126.871311948997, 37.616871300802],
            [126.871492942563, 37.616997493435],
            [126.871504295381, 37.617066980528],
            [126.871607244782, 37.617163363638],
            [126.871682825005, 37.617216667715],
            [126.871914720579, 37.617356628604],
            [126.871967562848, 37.617387184804],
            [126.872486384061, 37.617587203194],
            [126.872661652126, 37.617692503042],
            [126.872753654732, 37.617721029387],
            [126.872869515595, 37.617740535832],
            [126.873211052372, 37.617797732478],
            [126.873358391531, 37.617796739325],
            [126.873409502578, 37.61776737782],
            [126.873434719545, 37.617692533743],
            [126.873450806934, 37.617624437104],
            [126.87339558139, 37.617345307563],
            [126.873332898562, 37.617068980941],
            [126.873333361069, 37.617037086744],
            [126.873460372769, 37.616669786865],
            [126.873515774044, 37.616661242607],
            [126.873617566987, 37.616799166918],
            [126.873716983006, 37.61680398675],
            [126.873804663136, 37.616736904581],
            [126.873831411449, 37.616653673936],
            [126.873887349263, 37.616576376278],
            [126.873899974961, 37.616492175302],
            [126.874020950321, 37.616420840303],
            [126.87425518232, 37.6164869638],
            [126.874392093628, 37.616502148726],
            [126.874684956322, 37.616759198182],
            [126.874865975706, 37.616813271215],
            [126.874911691685, 37.616825393405],
            [126.875103924163, 37.61692659937],
            [126.875250236242, 37.6169760126],
            [126.875308052125, 37.616859046119],
            [126.875450796981, 37.616572632899],
            [126.875492786639, 37.616390040023],
            [126.875633091246, 37.616273647961],
            [126.875569309764, 37.61622797228],
            [126.875564547024, 37.616224597531],
            [126.875365112595, 37.616056063351],
            [126.875302452166, 37.615983944948],
            [126.87520903667, 37.615827155497],
            [126.87515100094, 37.615619282225],
            [126.876080226157, 37.61541016492],
            [126.877114730732, 37.615298997801],
            [126.877856336274, 37.615323563118],
            [126.879198484593, 37.614712745557],
            [126.880126980374, 37.614590089729],
            [126.880700248277, 37.614979721453],
            [126.880919426764, 37.615019228391],
            [126.883199204844, 37.61543012178],
            [126.883287163107, 37.615324047392],
            [126.886457719711, 37.614812676206],
            [126.886363195535, 37.614031154394],
            [126.88706667872, 37.613856507864],
            [126.886711502274, 37.613459821391],
            [126.886830560076, 37.612863307567],
            [126.886996031822, 37.612265126746],
            [126.887177304948, 37.611708315975],
            [126.887386330225, 37.611228475513],
            [126.887732437819, 37.609589023604],
            [126.887988076701, 37.608381188615],
            [126.886931001926, 37.607922333167],
            [126.886896802899, 37.60725611401],
            [126.887032804251, 37.606902448447],
            [126.88739844471, 37.6063905763],
            [126.887537535148, 37.605872736136],
            [126.887401807399, 37.605760973299],
            [126.886843697473, 37.6048893628],
            [126.885784732225, 37.604883103447],
            [126.885322281683, 37.604194929308],
            [126.88462682107, 37.603625993009],
            [126.884438359938, 37.603278026994],
            [126.883579979618, 37.603124906344],
            [126.883767681682, 37.602227264658],
            [126.883719827166, 37.602218774699],
            [126.883988895722, 37.60139636467],
            [126.88403161388, 37.600904560704],
            [126.884033528253, 37.600868171914],
            [126.884029397174, 37.600132199942],
            [126.883514932345, 37.600070239679],
            [126.883176423289, 37.599663287151],
            [126.882931066019, 37.599357517551],
            [126.882490704658, 37.599128855286],
            [126.881898396506, 37.59892549025],
            [126.881768971217, 37.598886436542],
            [126.881453310327, 37.598793738068],
            [126.881378707257, 37.598773777458],
            [126.881256678128, 37.598741019578],
            [126.881241476958, 37.598736868577],
            [126.881139356659, 37.598710212497],
            [126.881136390325, 37.598709903138],
            [126.881060824453, 37.59869022065],
            [126.880901509897, 37.598648279367],
            [126.880845978293, 37.59863413123],
            [126.880744182518, 37.598589023005],
            [126.880723305857, 37.598579316073],
            [126.880624783376, 37.598533850702],
            [126.880465507804, 37.598461212373],
            [126.880419521909, 37.59844034348],
            [126.880177733483, 37.598331112239],
            [126.880067884088, 37.598281048752],
            [126.879934184342, 37.598253557255],
            [126.879647867226, 37.598216952353],
            [126.878782937291, 37.598025236899],
            [126.878761387438, 37.597984417969],
            [126.878600919271, 37.59772303791],
            [126.878484549841, 37.597665839378],
            [126.878464757335, 37.59761010194],
            [126.878450506389, 37.597572389942],
            [126.878439941294, 37.597544989035],
            [126.878423455189, 37.59750309414],
            [126.878421256262, 37.59749752377],
            [126.878352119373, 37.597315849453],
            [126.878190036105, 37.597245790934],
            [126.878102149491, 37.597207335202],
            [126.878023015478, 37.597182943903],
            [126.877814550027, 37.597120656865],
            [126.877793249832, 37.597114183499],
            [126.877673812639, 37.597077874752],
            [126.87758232825, 37.597051479024],
            [126.877563149324, 37.597029105488],
            [126.877512200833, 37.596970055582],
            [126.877380459875, 37.596896937449],
            [126.877291981358, 37.596847209188],
            [126.877235799643, 37.596816660768],
            [126.877148766626, 37.596762591181],
            [126.877127376827, 37.59674201721],
            [126.876880790985, 37.596575624758],
            [126.876851936957, 37.5965563673],
            [126.876770363092, 37.596501897755],
            [126.876637422375, 37.596388737816],
            [126.876431361443, 37.596380221566],
            [126.876370178305, 37.596361506354],
            [126.876355861338, 37.596350463136],
            [126.876319257249, 37.596327467258],
            [126.876247539252, 37.596284207028],
            [126.876099097838, 37.596211610323],
            [126.87596107195, 37.596140375986],
            [126.875734901908, 37.596030665692],
            [126.875654354429, 37.596060096048],
            [126.87559797568, 37.59608002874],
            [126.875577542594, 37.596069969984],
            [126.87529183709, 37.595932237785],
            [126.875188342241, 37.596012197465],
            [126.874683124597, 37.596413971801],
            [126.874738224627, 37.596627446935],
            [126.874790439206, 37.596826548254],
            [126.87479631352, 37.596861458655],
            [126.874794146398, 37.596877367695],
            [126.874658873088, 37.596832254305],
            [126.874485252465, 37.596890658523],
            [126.874334349573, 37.596914020693],
            [126.874147399372, 37.596906782423],
            [126.874032340222, 37.596920605428],
            [126.873659649081, 37.597046520165],
            [126.87355684088, 37.597093881565],
            [126.873404854917, 37.597134324015],
            [126.873228767408, 37.597171000995],
            [126.872999090231, 37.597257497808],
            [126.872767055724, 37.597351433723],
            [126.872649604184, 37.597379650481],
            [126.87248011058, 37.597400593363],
            [126.87240904224, 37.597427680292],
            [126.872337732844, 37.597482823533],
            [126.872270008869, 37.597540313212],
            [126.87217173875, 37.597594868215],
            [126.871821991858, 37.59778733087],
            [126.871815520105, 37.597764664032],
            [126.871693071626, 37.597820219184],
            [126.871504461001, 37.597926588877],
            [126.871402317416, 37.597967966595],
            [126.871201772462, 37.598053924387],
            [126.871102083972, 37.597953597927],
            [126.871057538711, 37.597971874697],
            [126.870890117837, 37.597874914929],
            [126.870398757991, 37.597599489157],
            [126.870232034927, 37.597480130783],
            [126.869844539929, 37.597088942713],
            [126.869950147189, 37.596919495423],
            [126.870112047394, 37.596805521191],
            [126.869906854289, 37.596541926135],
            [126.869885344462, 37.596513521173],
            [126.869862009023, 37.596486564755],
            [126.869396601475, 37.596148527334],
            [126.869110797005, 37.596296599039],
            [126.868935471325, 37.59651651247],
            [126.868882636385, 37.596567385699],
            [126.868797324274, 37.596619042286],
            [126.868639477787, 37.596719432452],
            [126.868355210193, 37.596892605435],
            [126.868138335432, 37.5969916309],
            [126.868104332223, 37.59700990946],
            [126.868016490523, 37.597057129781],
            [126.867972171605, 37.59708802842],
            [126.867959147312, 37.597101942885],
            [126.867944067223, 37.597113007914],
            [126.867868834416, 37.597131546076],
            [126.867533918206, 37.597213471194],
            [126.86751299081, 37.597214285351],
            [126.86716301174, 37.597227906795],
            [126.86687975829, 37.597237467819],
            [126.866772531524, 37.59728989981],
            [126.86676052819, 37.597296373185],
            [126.866740800064, 37.597304018027],
            [126.866608481958, 37.597355394094],
            [126.866244621256, 37.597499324163],
            [126.865615394637, 37.59774034414],
            [126.864885276739, 37.598021040426],
            [126.864785494032, 37.598131574607],
            [126.864595924885, 37.598285594139],
            [126.864574389279, 37.59848216365],
            [126.864519118567, 37.598901867476],
            [126.864856508502, 37.599442217653],
            [126.865236287393, 37.600070344464],
            [126.865239227163, 37.60016592411],
            [126.865231525163, 37.600173051],
            [126.865244492775, 37.600246667253],
            [126.865274584859, 37.600256802009],
            [126.86532667906, 37.600285828725],
            [126.865372769203, 37.600309938137],
            [126.865380966698, 37.600310542241],
            [126.865402447822, 37.600323279882],
            [126.865330786264, 37.600337405716],
            [126.865088082468, 37.600384733353],
            [126.86463425054, 37.600513679113],
            [126.864537192427, 37.600581410508],
            [126.864438654812, 37.600696270506],
            [126.864315730357, 37.600838924418],
            [126.864199659558, 37.600899831009],
            [126.863963269453, 37.600967039348],
            [126.86376730379, 37.601023212312],
            [126.863166831034, 37.601181758651],
            [126.862896502114, 37.601254240859],
            [126.862695868328, 37.601310956157],
            [126.862685148596, 37.601314754705],
            [126.86261859924, 37.601331497751],
            [126.862588839219, 37.601337625445],
            [126.862379010807, 37.601339036082],
            [126.862183768039, 37.601340391487],
            [126.862091982765, 37.60129939651],
            [126.862035091721, 37.601274263957],
            [126.861891881068, 37.601210368133],
            [126.861798534159, 37.601168956627],
            [126.861672907347, 37.601112694653],
            [126.861552221801, 37.601060474806],
            [126.861495257909, 37.601038152972],
            [126.861425877703, 37.601048747286],
            [126.861389663799, 37.601096681619],
            [126.861345762431, 37.601198044087],
            [126.861309702915, 37.601278296821],
            [126.861267116311, 37.60137289444],
            [126.861233240425, 37.601448059192],
            [126.861176667374, 37.601452677046],
            [126.861060639553, 37.601398498096],
            [126.861012439048, 37.601376934274],
            [126.86089912888, 37.601304909935],
            [126.860786430384, 37.601232913246],
            [126.860702020542, 37.601178573421],
            [126.860634463254, 37.601183177911],
            [126.860505159233, 37.601263706484],
            [126.860380446068, 37.601341672605],
            [126.860238053134, 37.601337087433],
            [126.860177651098, 37.601390353281],
            [126.860158696592, 37.601407151925],
            [126.860092739184, 37.60146379877],
            [126.86002180164, 37.601525043628],
            [126.859888097442, 37.601638145771],
            [126.85967006263, 37.601822062769],
            [126.859563968757, 37.601909844073],
            [126.859480545908, 37.60197376752],
            [126.859462128529, 37.60198783672],
            [126.859322874023, 37.602088894407],
            [126.859233381228, 37.602134826757],
            [126.859137739941, 37.60216849826],
            [126.859056628042, 37.602243542315],
            [126.858988900833, 37.602306979804],
            [126.858899163753, 37.602391653844],
            [126.858852745105, 37.602445116096],
            [126.858699989193, 37.602617829861],
            [126.858661237037, 37.602683050121],
            [126.858281108575, 37.602861704691],
            [126.858213316507, 37.602970127692],
            [126.858205571462, 37.602969676807],
            [126.857691493975, 37.602954626752],
            [126.857481673909, 37.602884697924],
            [126.857047168019, 37.602585689461],
            [126.856720071152, 37.602365575081],
            [126.856519367555, 37.602226748592],
            [126.856374623775, 37.602189900702],
            [126.855973739529, 37.602238267311],
            [126.855658855247, 37.602360222987],
            [126.85554334616, 37.602378045771],
            [126.855145147316, 37.602332359341],
            [126.854794539914, 37.602364278287],
            [126.854711671558, 37.602374203176],
            [126.854441384461, 37.602459360885],
            [126.854272261686, 37.602496324546],
            [126.854133601098, 37.602527640808],
            [126.854145673059, 37.60263088142],
            [126.854140736636, 37.602693520553],
            [126.854139379969, 37.602726819162],
            [126.853996929816, 37.603088448629],
            [126.853889133202, 37.603082313304],
            [126.853838925986, 37.603079592597],
            [126.853787360235, 37.603076671951],
            [126.853250489522, 37.602883368753],
            [126.853040780713, 37.602896412635],
            [126.852362944345, 37.603050571961],
            [126.851946645902, 37.603147062895],
            [126.851744446528, 37.603277593049],
            [126.851740421339, 37.603432601915],
            [126.851736075434, 37.603589412334],
            [126.851840899417, 37.603734279075],
            [126.851971972097, 37.603834913734],
            [126.852241370884, 37.603926181878],
            [126.852568526906, 37.603891898385],
            [126.852947964449, 37.603893638002],
            [126.85336463747, 37.603912370617],
            [126.853581692023, 37.60406855782],
            [126.853649561188, 37.604216881441],
            [126.853691148223, 37.604301193373],
            [126.85366669156, 37.604482007842],
            [126.85356292267, 37.604877084521],
            [126.853543117284, 37.604956346098],
            [126.853419869578, 37.605419395293],
            [126.853289507801, 37.60568865223],
            [126.85310765841, 37.606142212166],
            [126.853179016397, 37.60623359835],
            [126.853213294797, 37.606274609066],
            [126.853290664791, 37.606361191493],
            [126.853563584506, 37.606487031677],
            [126.8536858433, 37.606512700679],
            [126.854056624727, 37.606586252157],
            [126.854192031308, 37.606698485357],
            [126.854297106632, 37.607002013021],
            [126.854289218992, 37.607182884243],
            [126.854280420036, 37.607400901789],
            [126.854274260528, 37.607561088618],
            [126.854267796516, 37.607714869084],
            [126.85449544817, 37.607936812354],
            [126.854768093887, 37.608061964639],
            [126.85529506973, 37.607875349719],
            [126.855621040456, 37.607725478429],
            [126.855860828547, 37.60771351225],
            [126.85613733528, 37.607822412438],
            [126.856400943469, 37.607925466767],
            [126.856986280994, 37.60809165028],
            [126.857520413783, 37.608243307672],
            [126.857720662395, 37.60832191018],
            [126.85778757806, 37.608348147181],
            [126.858012171796, 37.608547113915],
            [126.858309440255, 37.608711138267],
            [126.858412593027, 37.608964313011],
            [126.858432916727, 37.609020279583]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281123',
      properties: {
        rgnCode: '41281123',
        colCode: '41280',
        rgnKo: ['화정동'],
        rgnSize: 1,
        rgnBbox: [
          126.822206188038, 37.624004588197, 126.848747925911, 37.650618284956
        ],
        rgnCenter: [126.834675998803, 37.637365821934],
        rgnArea: 4246649,
        predVal: [
          0.52661, 0.5355, 0.5124, 0.57545, 0.72095, 0.65552, 0.71919, 0.75637,
          0.65136, 0.68572, 0.68342, 0.52326, 0.75213, 0.66851, 0.76027,
          0.30354, 0.7048, 0.64665, 0.83418, 0.72846, 0.74613, 0.75914, 0.65875,
          0.74601, 0.59717, 0.72772, 0.66514, 0.68487, 0.66959, 0.76695, 0.73717
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.824467768461, 37.632401220859],
            [126.824497711535, 37.632458189532],
            [126.824504703885, 37.632471498447],
            [126.824853686504, 37.633262899311],
            [126.824915114534, 37.633396065599],
            [126.825002587987, 37.63367784195],
            [126.825122851649, 37.633924772406],
            [126.825147012691, 37.633974389293],
            [126.825226396133, 37.634151730118],
            [126.825251820546, 37.634208529657],
            [126.825520727999, 37.634790448663],
            [126.825557169515, 37.634869311469],
            [126.825561901185, 37.63487579655],
            [126.825504783121, 37.634874062571],
            [126.825511195234, 37.634883775658],
            [126.825757669851, 37.635251426311],
            [126.825680422931, 37.635272186854],
            [126.825444039882, 37.635328397677],
            [126.825347209772, 37.635351669531],
            [126.825258008819, 37.635373006593],
            [126.825018665756, 37.635430239254],
            [126.824743603201, 37.635496049277],
            [126.824513759591, 37.635550655281],
            [126.824048629854, 37.635661299321],
            [126.823657138247, 37.635753600544],
            [126.822931866211, 37.635927907197],
            [126.822540502691, 37.636022331207],
            [126.822348001701, 37.636070556648],
            [126.822272493318, 37.636088434469],
            [126.822206188038, 37.636104803578],
            [126.82254480244, 37.636992941883],
            [126.822563491787, 37.637042046426],
            [126.822892452585, 37.637910338466],
            [126.822907314526, 37.63795877944],
            [126.822991448382, 37.638181646167],
            [126.823237074489, 37.638837360751],
            [126.823256550906, 37.638889367503],
            [126.823420378536, 37.639317542605],
            [126.823586381141, 37.6397514149],
            [126.823602403563, 37.639793424552],
            [126.823845500351, 37.640416671718],
            [126.823947285306, 37.640675720424],
            [126.823941501699, 37.640677919111],
            [126.82395671113, 37.640718927402],
            [126.824277146635, 37.641579021379],
            [126.824291700211, 37.641614866043],
            [126.824623491227, 37.642510618616],
            [126.824641952678, 37.642556641107],
            [126.824975237192, 37.643413733929],
            [126.824994282287, 37.643476650525],
            [126.824990778552, 37.643477744473],
            [126.825252248566, 37.644161047863],
            [126.825285087116, 37.644150501447],
            [126.825358511199, 37.644125951399],
            [126.825476404005, 37.64433840621],
            [126.825514796506, 37.644408712549],
            [126.825524881937, 37.644427458875],
            [126.825556494194, 37.644475681186],
            [126.825581703557, 37.644514749039],
            [126.825716592808, 37.644722796142],
            [126.825793358416, 37.644838361497],
            [126.825886062511, 37.644974952265],
            [126.826027141027, 37.64528373726],
            [126.826043239736, 37.645318484795],
            [126.826057371709, 37.645351184189],
            [126.82607169634, 37.645383874859],
            [126.826111396125, 37.645472121356],
            [126.826234310847, 37.645839703749],
            [126.826269977309, 37.64624624073],
            [126.826276751642, 37.646328915269],
            [126.826458698853, 37.646746715304],
            [126.826583298946, 37.646957737313],
            [126.826684692083, 37.647135559903],
            [126.826712389895, 37.647181974133],
            [126.826732041095, 37.647214537458],
            [126.826985854463, 37.647807106085],
            [126.827125905452, 37.648055558824],
            [126.827152113442, 37.648099709295],
            [126.827158009223, 37.648107898861],
            [126.827415443236, 37.648516195439],
            [126.827469837376, 37.648660278513],
            [126.827536730976, 37.648838049422],
            [126.827506490321, 37.649026263512],
            [126.827830302489, 37.64897516888],
            [126.82785662475, 37.648970558599],
            [126.827952898593, 37.648964735821],
            [126.828095520374, 37.648943214044],
            [126.828168705659, 37.648925536358],
            [126.828241220056, 37.648908848713],
            [126.828267005216, 37.648906165638],
            [126.828390493944, 37.648891877263],
            [126.828460037041, 37.64887133796],
            [126.828639327914, 37.648849364763],
            [126.828654819712, 37.648873038053],
            [126.82868616354, 37.648921421275],
            [126.828708778258, 37.648956844694],
            [126.828727237977, 37.648985820054],
            [126.828740493021, 37.649011445171],
            [126.828831019012, 37.648987260401],
            [126.829146099742, 37.648913895296],
            [126.829296412172, 37.648888734355],
            [126.82930810285, 37.648885264648],
            [126.829653155351, 37.648791724056],
            [126.829953557196, 37.648734498895],
            [126.830104219253, 37.648690056569],
            [126.830301038136, 37.648658510927],
            [126.830360326088, 37.648652578443],
            [126.830407085771, 37.648649745132],
            [126.830460252294, 37.648605368129],
            [126.830498273438, 37.648662725255],
            [126.8305404111, 37.648728584527],
            [126.830589334725, 37.648790201014],
            [126.830641726678, 37.648787150527],
            [126.830676303738, 37.648784957175],
            [126.830753039364, 37.648780212016],
            [126.830836452624, 37.64877395382],
            [126.830933404509, 37.648773679595],
            [126.831011932337, 37.648773072333],
            [126.831127967719, 37.648772158806],
            [126.831190199608, 37.648762247847],
            [126.831313983252, 37.648742514948],
            [126.831472156358, 37.648748148886],
            [126.831689118129, 37.648713323381],
            [126.831926293092, 37.64865933577],
            [126.832242731876, 37.64857978365],
            [126.83250589492, 37.64850808295],
            [126.832548076984, 37.648545083345],
            [126.832577438382, 37.648589273166],
            [126.832664483051, 37.648580478128],
            [126.832738214249, 37.648546256486],
            [126.832788151482, 37.648511189893],
            [126.832885688256, 37.648472587425],
            [126.83295952645, 37.648441068722],
            [126.833050191014, 37.648434891398],
            [126.833088776397, 37.648408818216],
            [126.833171331891, 37.648481104142],
            [126.833316800477, 37.648542758119],
            [126.833511806562, 37.64854555871],
            [126.833686496998, 37.648512471275],
            [126.833773197661, 37.648505386782],
            [126.833921142996, 37.648473612432],
            [126.834116991382, 37.648403974829],
            [126.834194112839, 37.648373451084],
            [126.834226418964, 37.648317636448],
            [126.834382446539, 37.648319659541],
            [126.834524403173, 37.648330672262],
            [126.834590177382, 37.648357794595],
            [126.834556762585, 37.648402796061],
            [126.834580005247, 37.648406910368],
            [126.834617553721, 37.648413549621],
            [126.83471836262, 37.648431378329],
            [126.834827927233, 37.64845075098],
            [126.835496134371, 37.648604048067],
            [126.835907432424, 37.648668595367],
            [126.835929055555, 37.64862646009],
            [126.835994704173, 37.648710523081],
            [126.836305382171, 37.648723211816],
            [126.836511255178, 37.648833779272],
            [126.836742133443, 37.648910144246],
            [126.836744399715, 37.648910147412],
            [126.837071776707, 37.648904297367],
            [126.837404454084, 37.648966603532],
            [126.837430169873, 37.649067422358],
            [126.837469714322, 37.649222985623],
            [126.837845347514, 37.649379826494],
            [126.837963550586, 37.649460447478],
            [126.838180198605, 37.649583982968],
            [126.8385788509, 37.650051365325],
            [126.838646221114, 37.650293406118],
            [126.838648536294, 37.650302166789],
            [126.838734975079, 37.650618284956],
            [126.839253125298, 37.650368825113],
            [126.840290114262, 37.65013892157],
            [126.840383158339, 37.650107253289],
            [126.840399647824, 37.650027629636],
            [126.840398776335, 37.650011410899],
            [126.840638569008, 37.649793522034],
            [126.840711129011, 37.649744274566],
            [126.840713642361, 37.64974006143],
            [126.840794481359, 37.649682265933],
            [126.841081950902, 37.649476676334],
            [126.841161046123, 37.649449646505],
            [126.841218823455, 37.649397801839],
            [126.841235287152, 37.649382417536],
            [126.841452898484, 37.649297111354],
            [126.841709596121, 37.649019247291],
            [126.842024049285, 37.648851992365],
            [126.842114399938, 37.648573722199],
            [126.842174548842, 37.648418916829],
            [126.842168966002, 37.648008875513],
            [126.842174354307, 37.646802035467],
            [126.842174691864, 37.646654627346],
            [126.842477576996, 37.646311692336],
            [126.842475802778, 37.646309464535],
            [126.842484051785, 37.64630413287],
            [126.842635114065, 37.646188344826],
            [126.842942982971, 37.645956316373],
            [126.843015830264, 37.645844044601],
            [126.843194818703, 37.645681523705],
            [126.843221601119, 37.6456459621],
            [126.84323081782, 37.645643758066],
            [126.842931248116, 37.645330852404],
            [126.842598135371, 37.645164534568],
            [126.842366401757, 37.64496060171],
            [126.842308779739, 37.644564725364],
            [126.842302321518, 37.644532732012],
            [126.842301383084, 37.644526477975],
            [126.842324870732, 37.644197734623],
            [126.842488190258, 37.643815490664],
            [126.842488249251, 37.643750611476],
            [126.842487059528, 37.643654944261],
            [126.842428904581, 37.643562263563],
            [126.842350530807, 37.64354638177],
            [126.842143144716, 37.643505080379],
            [126.84204188831, 37.643423252075],
            [126.841950146254, 37.643348374066],
            [126.841859813789, 37.643102015152],
            [126.841854629387, 37.643083808423],
            [126.841847993962, 37.643060644362],
            [126.841642027735, 37.642997297129],
            [126.841596353399, 37.642955520044],
            [126.841453294472, 37.642828829026],
            [126.841421514708, 37.642801396239],
            [126.841350230232, 37.642645160357],
            [126.841379866514, 37.642457076801],
            [126.841352812664, 37.642392259904],
            [126.841254266523, 37.642226076206],
            [126.841239257355, 37.642198035463],
            [126.841238856682, 37.642178853139],
            [126.841053297892, 37.642110937615],
            [126.840661686432, 37.641753608822],
            [126.840708842437, 37.641538438921],
            [126.840713402646, 37.641520146311],
            [126.840983025106, 37.641069223526],
            [126.841078378343, 37.640927800856],
            [126.841668877822, 37.64062443277],
            [126.841827190256, 37.640398502098],
            [126.84201764105, 37.640137386479],
            [126.842032387609, 37.640118612046],
            [126.842054249615, 37.640089152639],
            [126.842123553644, 37.640026421204],
            [126.842242713371, 37.639935448362],
            [126.842350151153, 37.639911212927],
            [126.842637763984, 37.639863344279],
            [126.842841606962, 37.639829264366],
            [126.842887214322, 37.639795394921],
            [126.843156574576, 37.639586982503],
            [126.843181770278, 37.63956665425],
            [126.843439080823, 37.63945653956],
            [126.843457906451, 37.639448095602],
            [126.843592781517, 37.639435878772],
            [126.843616215507, 37.639434162242],
            [126.84402814202, 37.639448768036],
            [126.844453220188, 37.639346199887],
            [126.844557521132, 37.639298938312],
            [126.84465586897, 37.639210827464],
            [126.844800753316, 37.638996371344],
            [126.844824652318, 37.638956579921],
            [126.844913367765, 37.638847463306],
            [126.845076829762, 37.638644690126],
            [126.845230941949, 37.638469834603],
            [126.845408096173, 37.638274286899],
            [126.845520069742, 37.638149019042],
            [126.845550383034, 37.638118966495],
            [126.845899087964, 37.637642089447],
            [126.84614003404, 37.637719413477],
            [126.84643593839, 37.637814369299],
            [126.846481760405, 37.637866659141],
            [126.846447937632, 37.637895626145],
            [126.846599469607, 37.637891671699],
            [126.846695931781, 37.637820260759],
            [126.846958168425, 37.637782430183],
            [126.847108194126, 37.637690474708],
            [126.847172001797, 37.637548636329],
            [126.847402630057, 37.637438243784],
            [126.847661246089, 37.637314805101],
            [126.847714334627, 37.637145454626],
            [126.847757555746, 37.637014157537],
            [126.84773388668, 37.636800514073],
            [126.847716553163, 37.636646262246],
            [126.847622511777, 37.636380108153],
            [126.847526775772, 37.636111951593],
            [126.847522479844, 37.63610197217],
            [126.847517690131, 37.635875613453],
            [126.847514530866, 37.635704639918],
            [126.847691286439, 37.635476544867],
            [126.847802071896, 37.635335659294],
            [126.84784109292, 37.635284795903],
            [126.847880552042, 37.635257736887],
            [126.847986423272, 37.635126223909],
            [126.848008575785, 37.63505832806],
            [126.848075895481, 37.634848460903],
            [126.848188398018, 37.634350781168],
            [126.848242862812, 37.634105461745],
            [126.848285964508, 37.633904104236],
            [126.848282829944, 37.633891315296],
            [126.848256709429, 37.633784344364],
            [126.848233114358, 37.633688900206],
            [126.848189925856, 37.633513432796],
            [126.848176836538, 37.633460366234],
            [126.848176373067, 37.633454356112],
            [126.848160044395, 37.633241055504],
            [126.848224651146, 37.632955547606],
            [126.848274103426, 37.632723195674],
            [126.848428605295, 37.632448147577],
            [126.848569918015, 37.632195804824],
            [126.848554561746, 37.631894561052],
            [126.848543445377, 37.631658265235],
            [126.848536384822, 37.631455103618],
            [126.848670395723, 37.631027637185],
            [126.848747925911, 37.630747001899],
            [126.848726003059, 37.630415918194],
            [126.848571451996, 37.630153695562],
            [126.848414478096, 37.629985171284],
            [126.848366226639, 37.629694155838],
            [126.84833278448, 37.629495681023],
            [126.848322083724, 37.629427192797],
            [126.8483228621, 37.629423274553],
            [126.848327497895, 37.629107460719],
            [126.84825200005, 37.628823230213],
            [126.84810502928, 37.62884921264],
            [126.84809038852, 37.62891203683],
            [126.848060035992, 37.629084714869],
            [126.847943651809, 37.6292205661],
            [126.847874797327, 37.629275796521],
            [126.847849274466, 37.629259257367],
            [126.847773679974, 37.629198748314],
            [126.847585442368, 37.629129668175],
            [126.847509713186, 37.629079700221],
            [126.847502615431, 37.629088187181],
            [126.847427124683, 37.629131290525],
            [126.847434975633, 37.629378051646],
            [126.847373997936, 37.629612343426],
            [126.847313648529, 37.629668458574],
            [126.847155586342, 37.629644583214],
            [126.846759553236, 37.629781643809],
            [126.84674915276, 37.629787549594],
            [126.846424953606, 37.629891258956],
            [126.846200799649, 37.629628365074],
            [126.845862075995, 37.629618629652],
            [126.845732905009, 37.629615008441],
            [126.845180662624, 37.629648488193],
            [126.845054308034, 37.629630742636],
            [126.845030489802, 37.629628098214],
            [126.845014123244, 37.629599614613],
            [126.845080290714, 37.629589809615],
            [126.845015977954, 37.629568704517],
            [126.844575023326, 37.62964677423],
            [126.84440970684, 37.629237780504],
            [126.844120088953, 37.629241115578],
            [126.843876355287, 37.629242475039],
            [126.843774861184, 37.629238465197],
            [126.843642298194, 37.629199987332],
            [126.843440538679, 37.629141432945],
            [126.843370261008, 37.629161070196],
            [126.84335042739, 37.629165278209],
            [126.843105055695, 37.629217962601],
            [126.84286693723, 37.629269052493],
            [126.842612157954, 37.62932375942],
            [126.842423711674, 37.629349363562],
            [126.842382237642, 37.629354983798],
            [126.842282525836, 37.629365120431],
            [126.842265302845, 37.629366890134],
            [126.842221905829, 37.629371165261],
            [126.842150181286, 37.629378456432],
            [126.841857618005, 37.629407973404],
            [126.841694888606, 37.629475065173],
            [126.841655475433, 37.629491634827],
            [126.84163402269, 37.62950065158],
            [126.841612845689, 37.629497289266],
            [126.841544897967, 37.629486565627],
            [126.841390507486, 37.629426170885],
            [126.840990340076, 37.629184615548],
            [126.840826962854, 37.629089430088],
            [126.840709364271, 37.629199189203],
            [126.840580640985, 37.629319384371],
            [126.840516906243, 37.629351372243],
            [126.840427924865, 37.629376027694],
            [126.840347331504, 37.629398351994],
            [126.840264017721, 37.629421393302],
            [126.840220662408, 37.629458724632],
            [126.84007576922, 37.629564571588],
            [126.840053142965, 37.629550395275],
            [126.840033797479, 37.629538295697],
            [126.839946915312, 37.629477450849],
            [126.839853883574, 37.629442005091],
            [126.839716961394, 37.629424158252],
            [126.839558853102, 37.629459439878],
            [126.839474621978, 37.629487434748],
            [126.839266501424, 37.629589031346],
            [126.839127149236, 37.629657061699],
            [126.839084237097, 37.629683031887],
            [126.839019613599, 37.629722144459],
            [126.838598574059, 37.63001510248],
            [126.838420899115, 37.630138741486],
            [126.838323881739, 37.630211136065],
            [126.838147567691, 37.630349210201],
            [126.838046989966, 37.630427978542],
            [126.837896968283, 37.630545528407],
            [126.837853590509, 37.630540602969],
            [126.837544605261, 37.630565221177],
            [126.837436209587, 37.630556511207],
            [126.837133816368, 37.630519871018],
            [126.836946454125, 37.630514023788],
            [126.836907237918, 37.630317285635],
            [126.836840962158, 37.629959504959],
            [126.836700020571, 37.629247986115],
            [126.836647207955, 37.629155471995],
            [126.836489464772, 37.62835914729],
            [126.836501384434, 37.628245370424],
            [126.836347341699, 37.627467700832],
            [126.836275405479, 37.62711063253],
            [126.836038819045, 37.625909477002],
            [126.835996700123, 37.625695705831],
            [126.835874061579, 37.625574532329],
            [126.835609570504, 37.625313237372],
            [126.835463987964, 37.625169506711],
            [126.835225383433, 37.624933744998],
            [126.834957280079, 37.62466883955],
            [126.834705680542, 37.624420264529],
            [126.834633117192, 37.624348534124],
            [126.834284995487, 37.624004588197],
            [126.833903725283, 37.624249113466],
            [126.832958536484, 37.624855297023],
            [126.83293199119, 37.624872287597],
            [126.831403229714, 37.625852700775],
            [126.831259948837, 37.625944574125],
            [126.831278484427, 37.625963070924],
            [126.83127099884, 37.626016758438],
            [126.83092851114, 37.626234570879],
            [126.830834697009, 37.626292278029],
            [126.830738283492, 37.626347278408],
            [126.830639384492, 37.626399301879],
            [126.830594589198, 37.626421040678],
            [126.830598951396, 37.626445373411],
            [126.828126262371, 37.627232644637],
            [126.828096107317, 37.62724224079],
            [126.828053594818, 37.627256053367],
            [126.828010617454, 37.627270036431],
            [126.827931158632, 37.627295876749],
            [126.827797045586, 37.627339466922],
            [126.827647979237, 37.627387927221],
            [126.827362509125, 37.627480721442],
            [126.827237136057, 37.627521467775],
            [126.827028301532, 37.627589354185],
            [126.826921044271, 37.627624216551],
            [126.82672025831, 37.627689483467],
            [126.826710123213, 37.627692775042],
            [126.823156955078, 37.62884764712],
            [126.823004130143, 37.628897312173],
            [126.82299358662, 37.628900719938],
            [126.822956914287, 37.629029594378],
            [126.82293268609, 37.629023521161],
            [126.823095046932, 37.629363660653],
            [126.823179496795, 37.629540578175],
            [126.823314220628, 37.629822814776],
            [126.823323824505, 37.629842921083],
            [126.823324466678, 37.629844408666],
            [126.823333941517, 37.629866370791],
            [126.823393832455, 37.6300052298],
            [126.8234843115, 37.630214987813],
            [126.823516589376, 37.630289808645],
            [126.823573720726, 37.630422275459],
            [126.823595306978, 37.630472330287],
            [126.823675692469, 37.63065868354],
            [126.823806349327, 37.630961572815],
            [126.824068668834, 37.63156972197],
            [126.824083630996, 37.631604396063],
            [126.824089275759, 37.631617414665],
            [126.824144262428, 37.63173792198],
            [126.824431189924, 37.632328574224],
            [126.824450682338, 37.632368705978],
            [126.824467768461, 37.632401220859]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41287107',
      properties: {
        rgnCode: '41287107',
        colCode: '41280',
        rgnKo: ['구산동'],
        rgnSize: 1,
        rgnBbox: [
          126.670104826919, 37.664001149292, 126.71322763932, 37.700418301606
        ],
        rgnCenter: [126.690425653693, 37.683799484064],
        rgnArea: 9254078,
        predVal: [
          0.53448, 0.53424, 0.53673, 0.53631, 0.53636, 0.53636, 0.53636,
          0.53631, 0.53635, 0.53673, 0.53623, 0.53623, 0.53623, 0.53623,
          0.53623, 0.53023, 0.06339, 0.52174, 0.53166, 0.53025, 0.52969,
          0.52937, 0.52937, 0.52937, 0.52937, 0.52937, 0.52937, 0.52623,
          0.52553, 0.519, 0.51369
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.711489642606, 37.699757627268],
            [126.711494730419, 37.6997235833],
            [126.711503380612, 37.699665762614],
            [126.711519406415, 37.699597869256],
            [126.711596001915, 37.699545459253],
            [126.71160717801, 37.699537810506],
            [126.711628542711, 37.69929271041],
            [126.711609141076, 37.698946331691],
            [126.711557292639, 37.698779165258],
            [126.711512818912, 37.698640036954],
            [126.711506485983, 37.698606595567],
            [126.711472809918, 37.698431815573],
            [126.711428364982, 37.698198085941],
            [126.711428885989, 37.698011983819],
            [126.711428920671, 37.69799726213],
            [126.71144827197, 37.697948837795],
            [126.711516503489, 37.697781426048],
            [126.711600204829, 37.69763044986],
            [126.711733742168, 37.697458441415],
            [126.71217630891, 37.697066508961],
            [126.712337605205, 37.696948878121],
            [126.712495136499, 37.696822498464],
            [126.712661178368, 37.696518919504],
            [126.712674216295, 37.69631470253],
            [126.71270907266, 37.696185679521],
            [126.712858174453, 37.696098020239],
            [126.71289382653, 37.695968368441],
            [126.712952019493, 37.695756783907],
            [126.712927774297, 37.695722577975],
            [126.712912551637, 37.695700827447],
            [126.712861128731, 37.695628444134],
            [126.712907055407, 37.695539180738],
            [126.712948297986, 37.695459005615],
            [126.712967320221, 37.695465809404],
            [126.712923281553, 37.695419031612],
            [126.71292309669, 37.69540821957],
            [126.712922167834, 37.695355330604],
            [126.712921678041, 37.695329579803],
            [126.712935972879, 37.694990968756],
            [126.712934932557, 37.694782527755],
            [126.712926031526, 37.694478384927],
            [126.712708437609, 37.694124799712],
            [126.712716354082, 37.694112493885],
            [126.712736510378, 37.69408113557],
            [126.71271751876, 37.694069457592],
            [126.712690756302, 37.694053003477],
            [126.712690116529, 37.693940246014],
            [126.71268370347, 37.693868963895],
            [126.712690799157, 37.69353039823],
            [126.712699723739, 37.693100298865],
            [126.712699969755, 37.693089487874],
            [126.712702996959, 37.692957954228],
            [126.712707617456, 37.692760563853],
            [126.712699384629, 37.692720000211],
            [126.712693600727, 37.692691155127],
            [126.712650102631, 37.692476258239],
            [126.712649665048, 37.692442921416],
            [126.712756992346, 37.692366421939],
            [126.712814383368, 37.692273312442],
            [126.712819740105, 37.692266117825],
            [126.712946890227, 37.692135788912],
            [126.713046500122, 37.692031790809],
            [126.713061027739, 37.692013266438],
            [126.71307824939, 37.691992946722],
            [126.713086457799, 37.691990083706],
            [126.71322763932, 37.691837354737],
            [126.713214950351, 37.691747046888],
            [126.713209382128, 37.691486744087],
            [126.713135821494, 37.691422505518],
            [126.7131107062, 37.691355682488],
            [126.713094189101, 37.691311224449],
            [126.713089173579, 37.691025425624],
            [126.713049821765, 37.690896220876],
            [126.7130696748, 37.690805316868],
            [126.713075376853, 37.690723568103],
            [126.713037720299, 37.690581168332],
            [126.713029455592, 37.690548848465],
            [126.713022260471, 37.690416028393],
            [126.712975384201, 37.690312302556],
            [126.712852969503, 37.69024551165],
            [126.712798320818, 37.690146379664],
            [126.712757385103, 37.690072111868],
            [126.712725854544, 37.690016715311],
            [126.712613424535, 37.689758177854],
            [126.712523761617, 37.68968065523],
            [126.712488662239, 37.689639304941],
            [126.712474805861, 37.689587204117],
            [126.712471475837, 37.689563005],
            [126.71232513905, 37.689502452664],
            [126.712283956431, 37.689486134269],
            [126.711971838436, 37.689362476525],
            [126.71197279493, 37.689320583921],
            [126.711968571674, 37.688890452028],
            [126.711964445133, 37.688522937536],
            [126.711961338622, 37.688155515622],
            [126.711958437856, 37.687793499992],
            [126.711955755444, 37.687433647196],
            [126.711954111137, 37.687185877228],
            [126.711953009292, 37.687002707921],
            [126.711951417899, 37.686782156281],
            [126.711949845052, 37.686562667819],
            [126.711945077961, 37.68615582439],
            [126.71194342301, 37.68599828621],
            [126.711942534032, 37.685789529918],
            [126.711941147791, 37.68541706664],
            [126.711942867745, 37.685324586567],
            [126.711557390239, 37.685286699809],
            [126.711563718371, 37.68524828911],
            [126.711709440563, 37.684348490751],
            [126.711296294633, 37.684307841257],
            [126.711090010275, 37.684287683357],
            [126.711095457225, 37.684257244069],
            [126.711218700059, 37.683483174902],
            [126.711239153013, 37.683354738253],
            [126.710971936044, 37.683329024357],
            [126.710833284806, 37.683315663726],
            [126.710706192585, 37.683303421605],
            [126.710116706435, 37.683246872434],
            [126.709685036012, 37.683205243532],
            [126.709601403635, 37.683197171115],
            [126.708902775372, 37.683127867287],
            [126.708627857605, 37.683100516243],
            [126.708407899272, 37.683079040323],
            [126.708407374605, 37.68303951357],
            [126.708405507865, 37.682952115073],
            [126.708405511701, 37.68290792262],
            [126.70840569827, 37.682889318103],
            [126.708406990095, 37.682820307192],
            [126.708419727116, 37.682075373708],
            [126.708422204687, 37.682050738397],
            [126.708424556521, 37.682014840676],
            [126.708417467223, 37.68111688248],
            [126.708417186128, 37.681052904051],
            [126.708416465742, 37.68067413534],
            [126.708416847967, 37.680525116186],
            [126.708417024206, 37.680212345578],
            [126.708492521569, 37.680212668317],
            [126.708491349786, 37.680150318417],
            [126.708422657637, 37.680149111621],
            [126.70842375175, 37.680127455068],
            [126.7084248224, 37.680094473288],
            [126.7084358263, 37.679640052327],
            [126.708446556209, 37.679188964231],
            [126.708446520515, 37.679140401957],
            [126.708444518121, 37.678237356481],
            [126.70839678783, 37.678238994759],
            [126.708323315879, 37.678243001645],
            [126.70820849892, 37.678242067475],
            [126.707861736007, 37.678239150421],
            [126.707415232345, 37.678235425082],
            [126.707322621376, 37.678234725613],
            [126.706958866879, 37.678231619366],
            [126.706514177152, 37.678227868117],
            [126.706054496046, 37.678222636088],
            [126.706054639489, 37.678195039788],
            [126.706055121044, 37.67815936265],
            [126.706057613698, 37.677259211393],
            [126.706057934366, 37.677204072927],
            [126.706062841425, 37.676312477763],
            [126.70606097469, 37.676282975376],
            [126.706063284537, 37.676243608834],
            [126.706074535636, 37.675350056293],
            [126.706074123493, 37.67529676296],
            [126.706078990788, 37.674397923615],
            [126.706532293835, 37.674396265167],
            [126.706532271587, 37.674367524204],
            [126.706531207533, 37.674327068039],
            [126.706544791799, 37.67401662812],
            [126.706554973485, 37.6737925826],
            [126.706533256066, 37.67376662543],
            [126.706505111934, 37.67373200288],
            [126.706071499188, 37.673943095741],
            [126.704348984749, 37.67480108583],
            [126.703810549769, 37.675064090286],
            [126.703125801672, 37.675407223758],
            [126.703082102879, 37.675421582756],
            [126.70282257294, 37.675572937233],
            [126.70267807677, 37.67565295564],
            [126.702616798078, 37.675681045731],
            [126.702487799307, 37.675771797672],
            [126.702432949966, 37.675810607474],
            [126.701298864192, 37.676594479014],
            [126.700739535443, 37.676984137347],
            [126.700657580604, 37.676949997856],
            [126.700396258697, 37.676723187919],
            [126.700463262228, 37.676675787829],
            [126.700266038863, 37.676391479206],
            [126.700210478713, 37.676311331287],
            [126.699516926285, 37.675315428839],
            [126.698162573275, 37.673338831816],
            [126.696582968408, 37.671040711374],
            [126.696556409551, 37.671063257037],
            [126.696404315504, 37.671224948064],
            [126.696374644938, 37.671218474429],
            [126.696311851988, 37.671301111033],
            [126.696200753358, 37.671443807313],
            [126.695994635199, 37.671815824322],
            [126.69590830546, 37.671971468323],
            [126.695872909435, 37.672034354344],
            [126.695782667611, 37.671900146374],
            [126.695601352672, 37.671641188239],
            [126.69557103986, 37.671597863101],
            [126.695477285355, 37.671463645778],
            [126.695370907804, 37.671312006912],
            [126.695265202246, 37.67116244192],
            [126.694678981984, 37.671412550071],
            [126.69407777919, 37.671669436576],
            [126.694056319797, 37.671678300235],
            [126.694018541123, 37.671694707506],
            [126.693929044389, 37.671468763389],
            [126.693909819133, 37.671419682516],
            [126.693898508777, 37.671391795056],
            [126.693882392391, 37.671350200329],
            [126.693843737446, 37.671241190362],
            [126.693837250289, 37.67122835265],
            [126.693817733828, 37.671181199074],
            [126.693782576317, 37.671125598648],
            [126.693748287124, 37.671062855791],
            [126.693695620799, 37.670888470928],
            [126.693773944902, 37.6708332657],
            [126.693939319627, 37.670756015488],
            [126.693967570493, 37.670757593771],
            [126.69398575822, 37.670744937526],
            [126.693915327426, 37.670617897414],
            [126.693813828934, 37.670630786831],
            [126.693788488029, 37.670591528562],
            [126.693766047996, 37.670563449874],
            [126.693716129212, 37.670492323247],
            [126.693653023238, 37.670458101918],
            [126.693616116059, 37.670420074779],
            [126.69358336698, 37.670280969734],
            [126.693596930598, 37.67020784644],
            [126.693640400845, 37.6701936345],
            [126.693755545163, 37.670169879108],
            [126.693713347836, 37.669958221413],
            [126.69370329419, 37.669922516793],
            [126.69364896199, 37.669726324071],
            [126.693632773473, 37.669639203131],
            [126.693630580846, 37.669621223069],
            [126.693610344538, 37.669361600971],
            [126.693565987648, 37.668685262563],
            [126.693515014733, 37.668279873384],
            [126.693522890798, 37.667731608903],
            [126.69358953821, 37.66744302213],
            [126.693619706978, 37.667191279851],
            [126.693830613196, 37.666784817091],
            [126.693868435211, 37.666518408872],
            [126.693817664594, 37.666160050831],
            [126.693674281499, 37.665887583812],
            [126.693438421311, 37.665531265106],
            [126.69277593226, 37.664880068764],
            [126.691924901127, 37.664001149292],
            [126.678925867102, 37.670738344747],
            [126.677744280613, 37.671477298343],
            [126.675410753847, 37.67353111453],
            [126.672315249433, 37.678046906143],
            [126.67088198847, 37.681512612081],
            [126.670209513575, 37.683476364323],
            [126.670104826919, 37.685067357348],
            [126.672185817214, 37.695964510025],
            [126.673498131321, 37.700418301606],
            [126.675994383917, 37.699578943183],
            [126.680245434726, 37.697416573887],
            [126.682871339923, 37.695993867232],
            [126.68313327515, 37.695778162897],
            [126.683405334229, 37.695554466689],
            [126.683395434284, 37.695350461472],
            [126.683327779722, 37.69527308425],
            [126.68335726532, 37.695247864702],
            [126.684216786951, 37.694582222882],
            [126.686685484913, 37.692400249973],
            [126.686953358653, 37.692147324926],
            [126.687390533595, 37.691734523473],
            [126.687862194401, 37.691289161082],
            [126.689001275038, 37.690026300842],
            [126.689946805428, 37.688623538583],
            [126.690256337489, 37.688645672794],
            [126.690313811875, 37.68864717592],
            [126.690304318352, 37.688667152321],
            [126.690322403742, 37.688653054873],
            [126.690701294782, 37.688817309153],
            [126.690744140796, 37.688819584385],
            [126.690814332181, 37.688762197579],
            [126.690816000077, 37.68877004038],
            [126.690835348148, 37.688860908759],
            [126.690893122789, 37.689116665088],
            [126.690897479352, 37.68913226328],
            [126.69091412741, 37.689190960033],
            [126.691010366758, 37.689303563955],
            [126.691074569325, 37.689350222836],
            [126.691153239916, 37.689407461063],
            [126.691207045274, 37.689418954824],
            [126.691276416328, 37.689449680091],
            [126.691611142021, 37.68971211066],
            [126.691865031789, 37.68992027008],
            [126.691951817605, 37.690071139464],
            [126.692115940896, 37.690327714841],
            [126.69219783421, 37.690455686661],
            [126.692400446565, 37.690617671037],
            [126.692719505286, 37.690832666542],
            [126.692753553453, 37.690851585882],
            [126.692806969184, 37.690875331031],
            [126.693078658845, 37.690948479592],
            [126.693171751274, 37.690973589693],
            [126.693322917831, 37.691096840569],
            [126.693731044483, 37.691521937081],
            [126.693730202063, 37.691533737536],
            [126.693879677842, 37.691550795428],
            [126.693937072807, 37.691544638373],
            [126.69405481238, 37.691531881388],
            [126.694402472405, 37.691494316024],
            [126.694544825817, 37.691478919748],
            [126.694655346248, 37.691402084687],
            [126.694697726147, 37.691325252454],
            [126.694704013144, 37.691257786454],
            [126.694712942516, 37.691154378781],
            [126.694738122923, 37.691096782458],
            [126.694792003511, 37.691035386623],
            [126.694958176793, 37.691044738266],
            [126.694972563376, 37.691047748851],
            [126.695073044964, 37.691068281731],
            [126.695144639292, 37.691082973301],
            [126.695231753278, 37.691100858516],
            [126.695618161249, 37.69117970491],
            [126.695751269079, 37.691206808792],
            [126.696199891393, 37.691348611906],
            [126.696273265537, 37.69137177648],
            [126.696389568128, 37.691515871633],
            [126.696417488276, 37.69159180521],
            [126.696416120851, 37.691648832822],
            [126.696393454313, 37.691701931174],
            [126.696368989206, 37.69175124081],
            [126.696336124828, 37.691747461808],
            [126.696254417964, 37.691737880292],
            [126.696152968482, 37.69184249009],
            [126.6961225673, 37.691873945177],
            [126.696104748448, 37.691906063517],
            [126.696078591652, 37.691953116295],
            [126.69607461049, 37.691956259365],
            [126.695933336022, 37.691957244825],
            [126.695768248668, 37.691931409693],
            [126.695515219632, 37.69184056672],
            [126.695455751477, 37.691826988007],
            [126.695365359746, 37.691806391551],
            [126.695172472757, 37.691813548863],
            [126.695095963494, 37.691835874243],
            [126.694939773842, 37.6919071852],
            [126.694797175171, 37.692037003987],
            [126.694738450516, 37.692090638904],
            [126.694681546509, 37.69214265679],
            [126.69465343591, 37.692168351272],
            [126.694710842015, 37.692214630088],
            [126.694703830494, 37.692292815686],
            [126.694691474149, 37.692429910546],
            [126.694736782618, 37.692743204147],
            [126.694798095734, 37.692887067677],
            [126.694800482835, 37.692913111797],
            [126.694811636838, 37.693034879304],
            [126.694812092326, 37.693039907878],
            [126.694919574866, 37.693315882887],
            [126.694932027993, 37.693347989642],
            [126.69497743617, 37.693389552107],
            [126.695200797971, 37.693389411719],
            [126.695282496872, 37.693401336456],
            [126.695365079942, 37.69347398852],
            [126.695394566259, 37.693499832685],
            [126.695779483474, 37.693447764352],
            [126.695671526924, 37.693645246394],
            [126.695619352584, 37.693765732357],
            [126.695607768456, 37.693792479008],
            [126.695555113191, 37.69391406287],
            [126.695831595349, 37.694038392358],
            [126.696037553591, 37.693864454047],
            [126.696079400488, 37.69382910944],
            [126.696189266821, 37.693856963495],
            [126.696302862599, 37.693832480993],
            [126.696402764523, 37.693912474973],
            [126.69641374217, 37.693917458686],
            [126.696562224553, 37.693984604301],
            [126.69662404428, 37.694005846743],
            [126.696708508547, 37.694034895989],
            [126.696822334012, 37.694092851941],
            [126.696889824809, 37.694113658413],
            [126.697028769703, 37.694156542827],
            [126.697069796792, 37.694160793192],
            [126.697130997659, 37.694167077741],
            [126.697192849367, 37.694180571663],
            [126.697335832523, 37.694261937369],
            [126.697382321177, 37.694289266434],
            [126.697524950995, 37.694373964572],
            [126.697589641825, 37.694411404261],
            [126.697643156855, 37.694442382223],
            [126.697679909606, 37.69446608222],
            [126.697695170196, 37.694477383607],
            [126.697759415595, 37.694515659953],
            [126.697900456725, 37.694600894122],
            [126.697965198469, 37.694628720433],
            [126.698036957467, 37.694659628049],
            [126.698164877649, 37.69476509986],
            [126.698226725262, 37.69480760432],
            [126.698262907064, 37.694832383825],
            [126.698311825505, 37.694847916122],
            [126.698249359514, 37.694929382979],
            [126.698211329782, 37.694979694196],
            [126.698188466869, 37.695014259498],
            [126.698236377614, 37.695079576589],
            [126.698320540086, 37.695141428057],
            [126.698391972413, 37.695169181237],
            [126.69849068131, 37.695208987308],
            [126.698529169914, 37.695224222423],
            [126.698570120253, 37.695236355519],
            [126.698477713826, 37.695347387337],
            [126.698514715687, 37.695365772015],
            [126.698539722775, 37.69537826959],
            [126.698577505519, 37.695399809634],
            [126.69866370968, 37.695447692125],
            [126.698682704941, 37.695460804906],
            [126.698737879928, 37.695499327711],
            [126.698776211759, 37.695525283832],
            [126.698827891365, 37.695559022505],
            [126.698961665966, 37.695647480219],
            [126.698976588672, 37.69565833009],
            [126.699014809114, 37.695683835364],
            [126.699153001373, 37.695773475448],
            [126.69925760708, 37.695841316019],
            [126.69929918935, 37.695848720412],
            [126.699335781273, 37.69585647239],
            [126.699426094297, 37.695925897924],
            [126.699475489618, 37.695963864998],
            [126.699575858657, 37.696042055537],
            [126.699676448831, 37.696121688101],
            [126.699894479113, 37.696236127991],
            [126.699995519416, 37.696316752494],
            [126.700084747418, 37.696385607139],
            [126.700079807809, 37.696390216465],
            [126.700205267457, 37.696516762341],
            [126.700292280441, 37.696589512347],
            [126.700387157981, 37.696652191559],
            [126.700516199098, 37.696762528871],
            [126.700651040529, 37.696896666322],
            [126.700695865952, 37.696932242673],
            [126.700724845417, 37.696955237152],
            [126.700818849365, 37.69695367496],
            [126.700892941458, 37.696941160254],
            [126.700925860743, 37.696931964227],
            [126.701408188442, 37.696683625293],
            [126.701763748767, 37.696491272548],
            [126.701769544093, 37.6964881339],
            [126.701844028912, 37.696490575618],
            [126.702070203915, 37.696498087591],
            [126.702285158257, 37.696532689717],
            [126.702544754727, 37.696518842604],
            [126.703327938523, 37.696597317542],
            [126.703485252704, 37.696727093858],
            [126.703571855451, 37.696761549381],
            [126.703851148185, 37.696841179268],
            [126.703949316918, 37.696875573612],
            [126.704204841116, 37.696887930664],
            [126.704396065589, 37.696874267466],
            [126.704702959589, 37.696799899792],
            [126.704791857703, 37.69679913245],
            [126.704880013281, 37.696899361395],
            [126.705019965191, 37.696976565705],
            [126.705177528408, 37.697044714355],
            [126.705277297978, 37.697075958238],
            [126.705396804456, 37.697133830078],
            [126.705630150928, 37.697220457994],
            [126.705978514167, 37.697382334072],
            [126.706177989407, 37.697518519201],
            [126.706325302176, 37.697598713578],
            [126.706441210502, 37.697649367628],
            [126.706554625665, 37.69767109434],
            [126.706690693701, 37.697670443544],
            [126.706853639034, 37.697668688499],
            [126.706967903345, 37.697705102671],
            [126.706972433556, 37.697692113057],
            [126.706983464426, 37.697698294202],
            [126.707030034753, 37.697735079797],
            [126.707035799309, 37.697739689116],
            [126.707064784381, 37.697779043582],
            [126.707143620437, 37.697885824601],
            [126.707255583062, 37.697973975185],
            [126.707305827853, 37.698013535803],
            [126.707553159855, 37.698177767911],
            [126.707840354967, 37.698326151694],
            [126.708027695732, 37.698435544795],
            [126.708159969545, 37.698477318326],
            [126.708389740562, 37.698609971202],
            [126.708566855563, 37.698725014106],
            [126.708723794319, 37.698809554024],
            [126.708777341711, 37.698831400227],
            [126.709299341314, 37.698987660927],
            [126.709398078827, 37.699022953106],
            [126.709557416985, 37.699045421358],
            [126.709763539264, 37.699050706621],
            [126.709833917304, 37.699060187692],
            [126.709999152713, 37.699082462712],
            [126.710102482506, 37.69913236129],
            [126.710130558248, 37.699161243534],
            [126.710189668747, 37.699222042556],
            [126.710271506516, 37.699316665854],
            [126.710399857317, 37.699463479716],
            [126.710469159755, 37.69951617705],
            [126.710618787957, 37.699557359977],
            [126.71107775085, 37.699684626397],
            [126.711116774821, 37.699720842197],
            [126.711240312234, 37.699762095224],
            [126.711364670503, 37.699773149792],
            [126.711466345249, 37.699762291023],
            [126.711489642606, 37.699757627268]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41287106',
      properties: {
        rgnCode: '41287106',
        colCode: '41280',
        rgnKo: ['가좌동'],
        rgnSize: 1,
        rgnBbox: [
          126.708405507865, 37.678369975108, 126.729107838247, 37.704617740418
        ],
        rgnCenter: [126.720470592341, 37.691136735543],
        rgnArea: 3389621,
        predVal: [
          0.53448, 0.53424, 0.53673, 0.53631, 0.53636, 0.53636, 0.53636,
          0.53631, 0.53635, 0.53673, 0.53623, 0.53623, 0.53623, 0.53623,
          0.53623, 0.53023, 0.06339, 0.52174, 0.53166, 0.53025, 0.52969,
          0.52937, 0.52937, 0.52937, 0.52937, 0.52937, 0.52937, 0.52623,
          0.52553, 0.519, 0.51369
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.72435052181, 37.679134799921],
            [126.724341854232, 37.679228065833],
            [126.724277905305, 37.679323210971],
            [126.724328857576, 37.679363640058],
            [126.72475348742, 37.679688616498],
            [126.724789421581, 37.679719441858],
            [126.724851426428, 37.679763095088],
            [126.724867649014, 37.679793441719],
            [126.724825815028, 37.679864691275],
            [126.724581120783, 37.68014657958],
            [126.724479327892, 37.680297523013],
            [126.723657043185, 37.681229171988],
            [126.723607753136, 37.68128383472],
            [126.723516063339, 37.681384572118],
            [126.723482276524, 37.681370617564],
            [126.723421570103, 37.681370744731],
            [126.723352132276, 37.681371526999],
            [126.723161454846, 37.681371707812],
            [126.722879405872, 37.681372122971],
            [126.72243303633, 37.68137273437],
            [126.722368872365, 37.681372852813],
            [126.721769823021, 37.681368054815],
            [126.721230117976, 37.681363620062],
            [126.72122998074, 37.681234375858],
            [126.721183503215, 37.681234040154],
            [126.721148928519, 37.681233777775],
            [126.720005070772, 37.681225692327],
            [126.720005677427, 37.68117014909],
            [126.719932390742, 37.681169523658],
            [126.719585049253, 37.681168964166],
            [126.719373282306, 37.681170349796],
            [126.719031275663, 37.681167999499],
            [126.718817026721, 37.681166224797],
            [126.718565709184, 37.681164180587],
            [126.718250343648, 37.681161621646],
            [126.717933163665, 37.681159237693],
            [126.717562137916, 37.68115645297],
            [126.717210043266, 37.681153802813],
            [126.716759552746, 37.681150373501],
            [126.716662630549, 37.681149598873],
            [126.716301468424, 37.681146563717],
            [126.716222115578, 37.681146281697],
            [126.716135509999, 37.681145351397],
            [126.715907313248, 37.681144708918],
            [126.71569022664, 37.681143912729],
            [126.715238255661, 37.681142275954],
            [126.715111971437, 37.681141789166],
            [126.714773889105, 37.68115078825],
            [126.714428028833, 37.681148325139],
            [126.714097130595, 37.68114634796],
            [126.713739932472, 37.681144305685],
            [126.713416063054, 37.681142253649],
            [126.712960129071, 37.681139427205],
            [126.71284336796, 37.681138781093],
            [126.712164907923, 37.681134685939],
            [126.71131357466, 37.681129350773],
            [126.710864214457, 37.681126892789],
            [126.710577527855, 37.681124924585],
            [126.710224184297, 37.681122881171],
            [126.709887386267, 37.681122229062],
            [126.70964263928, 37.681121443441],
            [126.709319786516, 37.681120373738],
            [126.708861124463, 37.681119506819],
            [126.708705478651, 37.681119210602],
            [126.708417467223, 37.68111688248],
            [126.708424556521, 37.682014840676],
            [126.708422204687, 37.682050738397],
            [126.708419727116, 37.682075373708],
            [126.708406990095, 37.682820307192],
            [126.70840569827, 37.682889318103],
            [126.708405511701, 37.68290792262],
            [126.708405507865, 37.682952115073],
            [126.708407374605, 37.68303951357],
            [126.708407899272, 37.683079040323],
            [126.708627857605, 37.683100516243],
            [126.708902775372, 37.683127867287],
            [126.709601403635, 37.683197171115],
            [126.709685036012, 37.683205243532],
            [126.710116706435, 37.683246872434],
            [126.710706192585, 37.683303421605],
            [126.710833284806, 37.683315663726],
            [126.710971936044, 37.683329024357],
            [126.711239153013, 37.683354738253],
            [126.711218700059, 37.683483174902],
            [126.711095457225, 37.684257244069],
            [126.711090010275, 37.684287683357],
            [126.711296294633, 37.684307841257],
            [126.711709440563, 37.684348490751],
            [126.711563718371, 37.68524828911],
            [126.711557390239, 37.685286699809],
            [126.711942867745, 37.685324586567],
            [126.711941147791, 37.68541706664],
            [126.711942534032, 37.685789529918],
            [126.71194342301, 37.68599828621],
            [126.711945077961, 37.68615582439],
            [126.711949845052, 37.686562667819],
            [126.711951417899, 37.686782156281],
            [126.711953009292, 37.687002707921],
            [126.711954111137, 37.687185877228],
            [126.711955755444, 37.687433647196],
            [126.711958437856, 37.687793499992],
            [126.711961338622, 37.688155515622],
            [126.711964445133, 37.688522937536],
            [126.711968571674, 37.688890452028],
            [126.71197279493, 37.689320583921],
            [126.711971838436, 37.689362476525],
            [126.712283956431, 37.689486134269],
            [126.71232513905, 37.689502452664],
            [126.712471475837, 37.689563005],
            [126.712474805861, 37.689587204117],
            [126.712488662239, 37.689639304941],
            [126.712523761617, 37.68968065523],
            [126.712613424535, 37.689758177854],
            [126.712725854544, 37.690016715311],
            [126.712757385103, 37.690072111868],
            [126.712798320818, 37.690146379664],
            [126.712852969503, 37.69024551165],
            [126.712975384201, 37.690312302556],
            [126.713022260471, 37.690416028393],
            [126.713029455592, 37.690548848465],
            [126.713037720299, 37.690581168332],
            [126.713075376853, 37.690723568103],
            [126.7130696748, 37.690805316868],
            [126.713049821765, 37.690896220876],
            [126.713089173579, 37.691025425624],
            [126.713094189101, 37.691311224449],
            [126.7131107062, 37.691355682488],
            [126.713135821494, 37.691422505518],
            [126.713209382128, 37.691486744087],
            [126.713214950351, 37.691747046888],
            [126.71322763932, 37.691837354737],
            [126.713086457799, 37.691990083706],
            [126.71307824939, 37.691992946722],
            [126.713061027739, 37.692013266438],
            [126.713046500122, 37.692031790809],
            [126.712946890227, 37.692135788912],
            [126.712819740105, 37.692266117825],
            [126.712814383368, 37.692273312442],
            [126.712756992346, 37.692366421939],
            [126.712649665048, 37.692442921416],
            [126.712650102631, 37.692476258239],
            [126.712693600727, 37.692691155127],
            [126.712699384629, 37.692720000211],
            [126.712707617456, 37.692760563853],
            [126.712702996959, 37.692957954228],
            [126.712699969755, 37.693089487874],
            [126.712699723739, 37.693100298865],
            [126.712690799157, 37.69353039823],
            [126.71268370347, 37.693868963895],
            [126.712690116529, 37.693940246014],
            [126.712690756302, 37.694053003477],
            [126.71271751876, 37.694069457592],
            [126.712736510378, 37.69408113557],
            [126.712716354082, 37.694112493885],
            [126.712708437609, 37.694124799712],
            [126.712926031526, 37.694478384927],
            [126.712934932557, 37.694782527755],
            [126.712935972879, 37.694990968756],
            [126.712921678041, 37.695329579803],
            [126.712922167834, 37.695355330604],
            [126.71292309669, 37.69540821957],
            [126.712923281553, 37.695419031612],
            [126.712967320221, 37.695465809404],
            [126.712948297986, 37.695459005615],
            [126.712907055407, 37.695539180738],
            [126.712861128731, 37.695628444134],
            [126.712912551637, 37.695700827447],
            [126.712927774297, 37.695722577975],
            [126.712952019493, 37.695756783907],
            [126.71289382653, 37.695968368441],
            [126.712858174453, 37.696098020239],
            [126.71270907266, 37.696185679521],
            [126.712674216295, 37.69631470253],
            [126.712661178368, 37.696518919504],
            [126.712495136499, 37.696822498464],
            [126.712337605205, 37.696948878121],
            [126.71217630891, 37.697066508961],
            [126.711733742168, 37.697458441415],
            [126.711600204829, 37.69763044986],
            [126.711516503489, 37.697781426048],
            [126.71144827197, 37.697948837795],
            [126.711428920671, 37.69799726213],
            [126.711428885989, 37.698011983819],
            [126.711428364982, 37.698198085941],
            [126.711472809918, 37.698431815573],
            [126.711506485983, 37.698606595567],
            [126.711512818912, 37.698640036954],
            [126.711557292639, 37.698779165258],
            [126.711609141076, 37.698946331691],
            [126.711628542711, 37.69929271041],
            [126.71160717801, 37.699537810506],
            [126.711596001915, 37.699545459253],
            [126.711519406415, 37.699597869256],
            [126.711503380612, 37.699665762614],
            [126.711494730419, 37.6997235833],
            [126.711489642606, 37.699757627268],
            [126.711600785665, 37.699735070114],
            [126.711638756324, 37.699727361102],
            [126.711859540031, 37.699753581135],
            [126.711963602602, 37.699761404809],
            [126.712108489102, 37.699768427625],
            [126.712215662968, 37.699763465009],
            [126.712512463826, 37.699693439993],
            [126.712521418578, 37.699691326651],
            [126.71273976174, 37.699703664192],
            [126.712862951747, 37.69969144239],
            [126.712968346836, 37.69967728489],
            [126.712979693855, 37.699675240435],
            [126.713254152435, 37.699573832321],
            [126.713530720208, 37.699601176639],
            [126.713546022251, 37.699602655553],
            [126.71366706296, 37.699618357581],
            [126.714022272841, 37.699629675314],
            [126.714047782767, 37.699630458276],
            [126.714072802609, 37.699640700173],
            [126.714133710004, 37.699665805359],
            [126.714177522686, 37.699683841331],
            [126.714468702629, 37.699803567984],
            [126.714736782695, 37.699921129483],
            [126.714750910063, 37.69992732644],
            [126.714871373029, 37.700034383693],
            [126.714974493486, 37.700051482362],
            [126.715042201117, 37.700048133103],
            [126.715127962087, 37.700038520897],
            [126.715372892583, 37.700007761902],
            [126.715585890743, 37.699965482717],
            [126.715720146004, 37.699935986229],
            [126.71596787008, 37.700034070785],
            [126.716318719334, 37.700118709607],
            [126.71642063687, 37.700183555255],
            [126.716438418197, 37.700197968637],
            [126.716507791363, 37.700254221407],
            [126.716681598676, 37.700408255913],
            [126.716793681466, 37.700483757178],
            [126.717029830139, 37.700585145121],
            [126.717304589878, 37.700701311387],
            [126.7173046143, 37.700694914594],
            [126.717379928925, 37.700727774095],
            [126.717397440576, 37.700735681714],
            [126.717966006702, 37.701148340794],
            [126.717974952824, 37.701181247528],
            [126.718028874861, 37.701344091452],
            [126.718092729752, 37.701527960714],
            [126.718134073466, 37.701647023489],
            [126.718169192201, 37.701748151099],
            [126.718298532257, 37.70189348113],
            [126.718382227407, 37.701965353877],
            [126.718387815901, 37.701986711153],
            [126.718497858696, 37.701988434881],
            [126.718541569647, 37.701989125411],
            [126.718916316112, 37.701995006441],
            [126.718988341444, 37.701974844253],
            [126.719263558405, 37.70189781313],
            [126.719716238386, 37.701865289805],
            [126.719861429547, 37.701826039279],
            [126.719952453452, 37.701801435073],
            [126.720043254868, 37.701778677243],
            [126.720116747567, 37.701760166633],
            [126.720360946247, 37.701728981103],
            [126.720905806439, 37.701659382016],
            [126.72097510899, 37.701693504283],
            [126.721015161858, 37.701717024655],
            [126.721195726004, 37.70182665099],
            [126.721278313133, 37.701877301327],
            [126.72135191178, 37.701936038935],
            [126.721483915504, 37.702023115414],
            [126.721609404791, 37.702063506297],
            [126.721687777657, 37.702089099517],
            [126.72173307687, 37.702104703603],
            [126.721776517113, 37.702132195998],
            [126.721897482524, 37.702199694887],
            [126.72208234473, 37.702252479163],
            [126.722221930892, 37.702313715088],
            [126.722398315747, 37.702359361167],
            [126.722511121899, 37.702365214111],
            [126.722642103874, 37.702332098153],
            [126.722794172049, 37.702360928182],
            [126.722967524069, 37.702489905496],
            [126.723122240058, 37.702599197523],
            [126.723203031186, 37.702736515091],
            [126.723245851376, 37.702808783434],
            [126.723306917527, 37.702853615383],
            [126.723402299779, 37.702818882946],
            [126.723418381641, 37.702824326664],
            [126.723484252211, 37.702888360403],
            [126.723719709791, 37.703483281828],
            [126.723838071393, 37.70370267524],
            [126.724127221761, 37.703705968589],
            [126.724435823433, 37.703814900005],
            [126.725018564401, 37.704519110437],
            [126.725469070508, 37.704617740418],
            [126.726380137581, 37.704488689286],
            [126.726533288376, 37.703704216154],
            [126.726564977907, 37.703536710596],
            [126.72643414818, 37.703344409859],
            [126.726419376597, 37.703321851277],
            [126.72643600493, 37.703302249035],
            [126.726443569138, 37.703311276329],
            [126.726471918024, 37.703311342457],
            [126.726523320226, 37.703311462341],
            [126.726493868721, 37.703263011842],
            [126.726590826509, 37.703261976587],
            [126.726676822154, 37.703281547799],
            [126.726776662072, 37.703267725408],
            [126.726864983232, 37.703302438111],
            [126.726931740701, 37.703342145939],
            [126.726985612671, 37.70337056167],
            [126.727141783968, 37.703548595424],
            [126.727155589379, 37.70355646594],
            [126.727183199547, 37.703572387158],
            [126.727294941906, 37.703590576272],
            [126.727382184731, 37.703641143059],
            [126.727484943045, 37.703697151628],
            [126.727584519776, 37.703662515656],
            [126.727676772949, 37.703769133876],
            [126.727714441831, 37.703825081166],
            [126.72784581222, 37.704025670604],
            [126.727886978441, 37.70408658126],
            [126.727892814987, 37.704226334437],
            [126.727915614155, 37.704317204585],
            [126.727934245533, 37.70433878087],
            [126.728053415983, 37.704341760195],
            [126.728139208309, 37.7043553835],
            [126.728263194017, 37.704374951527],
            [126.728403260377, 37.704400503037],
            [126.728351337545, 37.702388257904],
            [126.728299024316, 37.702358467927],
            [126.728289992989, 37.702356762197],
            [126.728240015934, 37.701264325286],
            [126.728208355494, 37.700501701942],
            [126.728199138334, 37.700294323407],
            [126.728188166419, 37.700067660137],
            [126.728178915093, 37.699854172961],
            [126.728164648856, 37.699499465835],
            [126.728147856356, 37.699153420117],
            [126.728139748652, 37.698952783325],
            [126.728123165467, 37.698617729838],
            [126.728106548538, 37.698282640212],
            [126.728095456017, 37.698073527389],
            [126.728075036226, 37.697671766456],
            [126.728050677969, 37.697156907129],
            [126.728045601393, 37.697023300141],
            [126.728030560497, 37.696719270365],
            [126.728013856268, 37.696374206724],
            [126.727999190548, 37.69610700927],
            [126.727964170138, 37.6954175809],
            [126.727956389065, 37.695239369773],
            [126.727950074238, 37.695047845762],
            [126.727933415702, 37.69472139608],
            [126.727918388911, 37.69440759072],
            [126.727901380958, 37.694000422617],
            [126.727878990905, 37.693586169409],
            [126.727873054255, 37.693359697731],
            [126.727848737723, 37.692877390004],
            [126.727819768586, 37.692093941374],
            [126.727858536937, 37.691448038252],
            [126.727879281344, 37.69124852226],
            [126.72795110689, 37.690831721476],
            [126.7281635803, 37.69018198638],
            [126.728454785941, 37.689301245007],
            [126.728688145345, 37.688611283908],
            [126.728851519313, 37.688084325643],
            [126.729044247953, 37.687466166933],
            [126.729107838247, 37.687097367695],
            [126.729088446057, 37.686435580331],
            [126.729088284173, 37.68643024623],
            [126.729078823609, 37.686010193313],
            [126.729066350252, 37.685298039695],
            [126.729065865665, 37.685272451093],
            [126.729052287315, 37.684555609799],
            [126.729041681947, 37.68358082817],
            [126.729034756778, 37.683428548587],
            [126.729034112228, 37.682705970751],
            [126.729023709527, 37.68195480959],
            [126.729025097248, 37.681823361537],
            [126.728328059013, 37.681442621613],
            [126.727816566053, 37.681164387991],
            [126.727127659388, 37.680795282311],
            [126.726510161129, 37.680461296692],
            [126.726066330961, 37.680221143974],
            [126.725054100372, 37.679727405461],
            [126.725130462838, 37.679638046187],
            [126.725139496537, 37.679629183807],
            [126.725148389222, 37.679621663539],
            [126.72516581888, 37.67960166684],
            [126.725168007606, 37.679595320141],
            [126.725255750144, 37.679469552281],
            [126.725405398696, 37.679282501259],
            [126.725409269595, 37.679278005478],
            [126.725559668504, 37.67910239831],
            [126.725809554214, 37.67881561878],
            [126.726099194297, 37.678473269699],
            [126.726123223088, 37.678446549018],
            [126.726159201949, 37.678403927106],
            [126.726080531898, 37.678369975108],
            [126.726061080545, 37.678391174523],
            [126.726016389662, 37.678444623719],
            [126.725702754568, 37.678819738775],
            [126.725550541478, 37.679001828864],
            [126.725437109922, 37.679137519557],
            [126.725436893401, 37.679165358979],
            [126.725396652368, 37.679189374695],
            [126.72516323096, 37.679474200766],
            [126.725121282692, 37.679475544019],
            [126.725090898737, 37.679476463877],
            [126.725058473646, 37.679477559137],
            [126.724994580161, 37.679466327442],
            [126.724841759938, 37.679438669707],
            [126.724645430559, 37.67935959949],
            [126.724453516454, 37.679251708325],
            [126.72435052181, 37.679134799921]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41287108',
      properties: {
        rgnCode: '41287108',
        colCode: '41280',
        rgnKo: ['법곳동'],
        rgnSize: 1,
        rgnBbox: [
          126.691924901127, 37.648444941098, 126.731794390292, 37.681384572118
        ],
        rgnCenter: [126.714036082778, 37.665400725055],
        rgnArea: 7636995,
        predVal: [
          0.32069, 0.32054, 0.32204, 0.32179, 0.32182, 0.32182, 0.32182,
          0.32179, 0.32181, 0.32204, 0.32174, 0.32174, 0.32174, 0.32174,
          0.32174, 0.31814, 0.03803, 0.31304, 0.31899, 0.31815, 0.31781,
          0.31762, 0.31762, 0.31762, 0.31762, 0.31762, 0.31762, 0.31574,
          0.31532, 0.3114, 0.30822
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.708417467223, 37.68111688248],
            [126.708705478651, 37.681119210602],
            [126.708861124463, 37.681119506819],
            [126.709319786516, 37.681120373738],
            [126.70964263928, 37.681121443441],
            [126.709887386267, 37.681122229062],
            [126.710224184297, 37.681122881171],
            [126.710577527855, 37.681124924585],
            [126.710864214457, 37.681126892789],
            [126.71131357466, 37.681129350773],
            [126.712164907923, 37.681134685939],
            [126.71284336796, 37.681138781093],
            [126.712960129071, 37.681139427205],
            [126.713416063054, 37.681142253649],
            [126.713739932472, 37.681144305685],
            [126.714097130595, 37.68114634796],
            [126.714428028833, 37.681148325139],
            [126.714773889105, 37.68115078825],
            [126.715111971437, 37.681141789166],
            [126.715238255661, 37.681142275954],
            [126.71569022664, 37.681143912729],
            [126.715907313248, 37.681144708918],
            [126.716135509999, 37.681145351397],
            [126.716222115578, 37.681146281697],
            [126.716301468424, 37.681146563717],
            [126.716662630549, 37.681149598873],
            [126.716759552746, 37.681150373501],
            [126.717210043266, 37.681153802813],
            [126.717562137916, 37.68115645297],
            [126.717933163665, 37.681159237693],
            [126.718250343648, 37.681161621646],
            [126.718565709184, 37.681164180587],
            [126.718817026721, 37.681166224797],
            [126.719031275663, 37.681167999499],
            [126.719373282306, 37.681170349796],
            [126.719585049253, 37.681168964166],
            [126.719932390742, 37.681169523658],
            [126.720005677427, 37.68117014909],
            [126.720005070772, 37.681225692327],
            [126.721148928519, 37.681233777775],
            [126.721183503215, 37.681234040154],
            [126.72122998074, 37.681234375858],
            [126.721230117976, 37.681363620062],
            [126.721769823021, 37.681368054815],
            [126.722368872365, 37.681372852813],
            [126.72243303633, 37.68137273437],
            [126.722879405872, 37.681372122971],
            [126.723161454846, 37.681371707812],
            [126.723352132276, 37.681371526999],
            [126.723421570103, 37.681370744731],
            [126.723482276524, 37.681370617564],
            [126.723516063339, 37.681384572118],
            [126.723607753136, 37.68128383472],
            [126.723657043185, 37.681229171988],
            [126.724479327892, 37.680297523013],
            [126.724581120783, 37.68014657958],
            [126.724825815028, 37.679864691275],
            [126.724867649014, 37.679793441719],
            [126.724851426428, 37.679763095088],
            [126.724789421581, 37.679719441858],
            [126.72475348742, 37.679688616498],
            [126.724328857576, 37.679363640058],
            [126.724277905305, 37.679323210971],
            [126.724341854232, 37.679228065833],
            [126.72435052181, 37.679134799921],
            [126.724219200663, 37.679014383132],
            [126.724035839608, 37.678801863893],
            [126.724029216987, 37.678726428233],
            [126.723874090204, 37.678606414537],
            [126.723871874768, 37.678604697481],
            [126.723809164102, 37.678556176838],
            [126.723657135627, 37.678438422571],
            [126.723547720376, 37.678353743995],
            [126.723483061762, 37.678304849189],
            [126.723426936125, 37.678262830838],
            [126.723408657152, 37.678249147073],
            [126.722825588762, 37.677788051527],
            [126.722752360004, 37.677727378425],
            [126.722674078894, 37.677677946432],
            [126.722629031044, 37.6776445221],
            [126.722592201467, 37.67760811696],
            [126.722572687878, 37.677612422491],
            [126.722459098898, 37.677583457942],
            [126.722348994244, 37.677532202555],
            [126.722201011027, 37.677409996177],
            [126.722084245898, 37.677294071251],
            [126.721924910535, 37.677113058424],
            [126.721715916843, 37.676892185851],
            [126.721517297759, 37.676731297007],
            [126.721188300369, 37.67654559167],
            [126.72101306685, 37.676556797555],
            [126.720913344181, 37.676657081445],
            [126.720828127598, 37.676580683743],
            [126.720760207768, 37.676525743182],
            [126.72040243819, 37.67623630168],
            [126.72022994651, 37.676103898496],
            [126.720209297035, 37.676054845549],
            [126.720105956484, 37.675894388739],
            [126.719435502255, 37.675246233845],
            [126.719232265286, 37.675091952201],
            [126.71898655426, 37.674932063551],
            [126.718479053188, 37.674456423515],
            [126.718126189897, 37.674178366051],
            [126.718028652951, 37.674031688135],
            [126.717887098798, 37.673695232257],
            [126.717807465686, 37.673234735542],
            [126.71777604614, 37.673097460357],
            [126.717802867378, 37.673019392814],
            [126.717833332097, 37.672931080996],
            [126.717846532906, 37.672887595925],
            [126.717860269443, 37.672846301496],
            [126.717912234385, 37.672690045199],
            [126.717958649442, 37.672550488515],
            [126.718108448636, 37.672539550384],
            [126.718303827407, 37.672572896044],
            [126.718280331194, 37.672644160363],
            [126.71817576177, 37.672961275677],
            [126.718126340852, 37.67311035747],
            [126.717993841144, 37.673492824865],
            [126.717951103072, 37.673616190952],
            [126.717942767759, 37.673721494217],
            [126.717970241264, 37.673829496378],
            [126.718037463357, 37.673917682649],
            [126.718102515347, 37.673971482682],
            [126.718288993742, 37.674125699023],
            [126.718308429205, 37.674141773933],
            [126.718378889447, 37.674183387676],
            [126.718455275586, 37.67424654876],
            [126.718468948306, 37.674257843687],
            [126.71853581425, 37.674260977327],
            [126.718573617697, 37.67433548807],
            [126.71887548513, 37.674586870441],
            [126.719026730354, 37.674712818857],
            [126.719225919966, 37.674884840266],
            [126.719257865105, 37.674860320262],
            [126.719516608514, 37.674661734898],
            [126.71984344464, 37.674411325669],
            [126.720162696971, 37.674157473747],
            [126.720483751905, 37.673905967762],
            [126.720806869414, 37.673647978817],
            [126.720937502803, 37.673543687103],
            [126.721275850088, 37.673274020479],
            [126.72144525019, 37.67313900714],
            [126.721759985381, 37.672877752146],
            [126.721991459128, 37.67269306161],
            [126.722078318499, 37.672623712577],
            [126.722292846377, 37.672453757191],
            [126.722399601568, 37.672368327661],
            [126.722527954749, 37.67226637128],
            [126.722720883108, 37.672112761657],
            [126.723042730866, 37.671856745616],
            [126.723088121849, 37.671812615162],
            [126.723125547152, 37.671776844908],
            [126.723261390011, 37.67167202229],
            [126.723448161294, 37.671527316532],
            [126.72377691247, 37.671272846376],
            [126.724259835041, 37.670890800072],
            [126.724555506944, 37.670660486038],
            [126.72473500233, 37.670520265974],
            [126.724819234283, 37.670455053185],
            [126.724961452456, 37.670341414027],
            [126.725371620967, 37.670013520977],
            [126.725640485959, 37.669796836161],
            [126.725853520768, 37.669627771668],
            [126.726015287256, 37.669498950508],
            [126.726249466536, 37.669312545951],
            [126.726442939979, 37.669160643253],
            [126.72662447669, 37.669018172602],
            [126.726630046465, 37.669013860925],
            [126.7267983968, 37.66888163033],
            [126.726898885602, 37.668802218564],
            [126.727188926955, 37.668572875779],
            [126.72725818842, 37.668518203792],
            [126.727324710207, 37.668465705744],
            [126.727360763234, 37.668432228404],
            [126.727409952175, 37.668386573432],
            [126.727424945491, 37.668427187972],
            [126.727451971068, 37.668499941046],
            [126.727525257633, 37.668697640009],
            [126.727539916045, 37.668736893289],
            [126.727558106925, 37.668786452861],
            [126.727575535798, 37.668775051019],
            [126.727679039335, 37.668738621865],
            [126.727819187123, 37.668723720743],
            [126.727968960326, 37.668742087578],
            [126.728182783279, 37.66872672618],
            [126.728418702071, 37.668713037302],
            [126.728819459578, 37.668689277896],
            [126.728998085604, 37.668628334648],
            [126.729058332211, 37.66861162568],
            [126.729198563765, 37.668573658274],
            [126.729329076493, 37.66855882321],
            [126.729401468398, 37.668537276852],
            [126.729520446707, 37.668515117107],
            [126.729526461428, 37.668513058745],
            [126.729559595934, 37.668502593792],
            [126.729504362116, 37.668450210158],
            [126.729462008897, 37.668408956159],
            [126.729437343721, 37.668396988441],
            [126.72932005176, 37.66832986584],
            [126.7292869369, 37.668303931572],
            [126.729253822064, 37.668277997295],
            [126.729197300837, 37.668233737295],
            [126.729188157526, 37.668226580502],
            [126.72893568063, 37.667933542366],
            [126.728856090863, 37.667784968602],
            [126.728815902428, 37.667771811628],
            [126.728772187708, 37.66770017351],
            [126.728615624646, 37.667493669371],
            [126.728587051691, 37.667453275827],
            [126.72857857283, 37.667459707156],
            [126.728460826551, 37.667306828278],
            [126.728074685807, 37.6668054537],
            [126.728056546973, 37.66678190533],
            [126.72787943142, 37.666608382118],
            [126.727738887387, 37.666454530623],
            [126.727605251336, 37.666302406844],
            [126.727544447608, 37.666224421778],
            [126.72750407722, 37.666168557927],
            [126.727447366495, 37.66608715863],
            [126.727435417172, 37.666069561939],
            [126.727384211157, 37.666001509766],
            [126.727336607217, 37.665940223222],
            [126.727219071938, 37.665786784961],
            [126.72716869218, 37.6657099051],
            [126.727099946525, 37.665603610849],
            [126.727156039707, 37.665575450851],
            [126.727323977986, 37.66552205339],
            [126.727482670069, 37.66548647343],
            [126.72753882569, 37.665472098245],
            [126.727571702642, 37.665469741981],
            [126.727695025935, 37.665466874923],
            [126.727833194838, 37.665464492665],
            [126.728003065193, 37.665533000053],
            [126.728027649306, 37.665535850064],
            [126.72824325503, 37.665557252271],
            [126.728306180901, 37.665597446181],
            [126.728414359492, 37.665629365809],
            [126.728535662649, 37.665636584066],
            [126.728772427204, 37.665590398401],
            [126.72896203363, 37.665546617015],
            [126.729035071636, 37.665549083228],
            [126.729107409588, 37.665510508564],
            [126.729261666807, 37.665478700044],
            [126.729455506734, 37.665501446302],
            [126.729495380224, 37.665495006222],
            [126.729516890705, 37.665492244796],
            [126.729719963956, 37.665460115764],
            [126.729773540445, 37.665441138622],
            [126.729826004886, 37.66542562763],
            [126.729680924671, 37.66534348524],
            [126.729471054237, 37.665219559405],
            [126.729568481711, 37.665119695216],
            [126.729099843402, 37.664774172731],
            [126.729109455437, 37.664764599589],
            [126.729208620268, 37.664697931467],
            [126.729289648891, 37.66475595181],
            [126.729427269107, 37.66485383546],
            [126.729746638189, 37.665082436222],
            [126.72984841405, 37.665154505063],
            [126.729920460871, 37.665204945139],
            [126.729997475184, 37.665260739362],
            [126.730031771268, 37.665285685093],
            [126.730397520204, 37.665549159278],
            [126.730411809245, 37.665559337049],
            [126.730500719793, 37.665625888843],
            [126.730701729231, 37.665784326706],
            [126.730731632381, 37.665808126923],
            [126.730778569758, 37.66584460685],
            [126.730883564595, 37.665926565829],
            [126.730996217712, 37.66601579509],
            [126.731109711606, 37.666104530637],
            [126.731278349035, 37.666170462716],
            [126.731384617895, 37.666192005073],
            [126.731438435798, 37.66620339945],
            [126.731725205681, 37.66621366004],
            [126.731741569342, 37.666214391213],
            [126.731794390292, 37.666216286917],
            [126.731727080042, 37.665821057341],
            [126.731678574027, 37.665535239561],
            [126.731645902217, 37.665346348373],
            [126.731591950974, 37.665030777029],
            [126.731568414832, 37.664895036961],
            [126.731519519295, 37.664626345637],
            [126.731348069027, 37.663680023667],
            [126.731328726507, 37.663575737015],
            [126.731295417033, 37.663385015238],
            [126.731231029993, 37.66301330724],
            [126.73116087472, 37.662607258961],
            [126.73109984814, 37.662246820687],
            [126.731090638239, 37.662192561105],
            [126.730969320933, 37.661456820042],
            [126.730961112244, 37.661407788372],
            [126.730930277992, 37.661222568027],
            [126.730852624894, 37.66075992124],
            [126.730850133991, 37.660743652989],
            [126.730747221438, 37.660074760795],
            [126.730738494835, 37.660062316366],
            [126.730706003271, 37.660015418286],
            [126.730375543443, 37.659563281421],
            [126.730259510106, 37.659406101439],
            [126.73016906549, 37.659283604544],
            [126.730061402145, 37.659140498773],
            [126.729836693041, 37.658838741575],
            [126.72964320022, 37.658570121549],
            [126.729438029903, 37.658287644346],
            [126.729365368842, 37.657935467035],
            [126.729660660394, 37.65780653409],
            [126.729710215988, 37.657785439402],
            [126.729720634056, 37.657781427051],
            [126.73000252095, 37.657664075888],
            [126.730021356213, 37.657797823471],
            [126.73013585613, 37.657864848949],
            [126.730203626219, 37.657989338956],
            [126.730221245561, 37.65799953343],
            [126.730287243459, 37.658041156927],
            [126.730321614129, 37.658063408865],
            [126.73037363208, 37.658094422763],
            [126.730386773236, 37.658095759376],
            [126.730669809142, 37.658142584363],
            [126.730731425301, 37.65812078715],
            [126.730729172723, 37.658085734173],
            [126.730790023362, 37.658025058234],
            [126.730793792912, 37.658016868041],
            [126.730817492606, 37.657950520798],
            [126.730823755987, 37.657942156132],
            [126.730901200961, 37.657836830004],
            [126.730912034327, 37.657818294833],
            [126.730949434561, 37.657755222487],
            [126.730964051361, 37.657755886691],
            [126.73097943039, 37.657765111875],
            [126.730990103982, 37.657759370132],
            [126.731035637046, 37.657734878008],
            [126.73107714589, 37.657695060117],
            [126.731174618574, 37.657598158755],
            [126.731203659167, 37.657465151742],
            [126.731222644369, 37.657354916271],
            [126.731240178166, 37.657301168415],
            [126.731261502622, 37.657233374076],
            [126.731281933209, 37.657224591354],
            [126.731311557845, 37.657211775313],
            [126.731309654432, 37.657142846592],
            [126.731306313709, 37.657064003886],
            [126.731278973847, 37.656946724795],
            [126.731261876426, 37.65684884006],
            [126.73113585812, 37.656630065562],
            [126.731133635734, 37.656617897345],
            [126.731125798738, 37.656594607273],
            [126.731017145955, 37.656271837186],
            [126.730999099846, 37.6562169266],
            [126.730994391886, 37.656202680444],
            [126.731019485848, 37.656064168463],
            [126.731005050549, 37.655982327086],
            [126.731007897458, 37.655947285795],
            [126.731011218014, 37.655875395826],
            [126.730981074726, 37.655750181659],
            [126.730978783523, 37.655725760057],
            [126.730976608259, 37.655700617943],
            [126.73096897311, 37.655618611957],
            [126.730903364254, 37.65555458254],
            [126.730876092668, 37.65554370831],
            [126.730851877866, 37.655533651963],
            [126.730772995452, 37.654974327531],
            [126.730550445274, 37.653410539218],
            [126.730500865321, 37.653083732656],
            [126.730463013344, 37.652866331087],
            [126.730441853464, 37.652639597738],
            [126.730424233756, 37.652187178833],
            [126.730407026214, 37.651901981398],
            [126.730381766792, 37.651525046402],
            [126.730335878929, 37.650744337973],
            [126.730318472626, 37.650420488255],
            [126.730299873127, 37.65005086634],
            [126.730289079438, 37.649840644495],
            [126.730149454065, 37.648444941098],
            [126.72651336255, 37.648749204228],
            [126.724829350489, 37.648887530459],
            [126.724011019448, 37.649247439227],
            [126.723165117032, 37.649614845409],
            [126.721204573411, 37.650481354158],
            [126.718894504742, 37.65149961808],
            [126.717489839276, 37.652119087007],
            [126.71580432758, 37.652831740595],
            [126.714462508478, 37.653420692381],
            [126.713262338691, 37.653946186177],
            [126.711814281364, 37.654597375488],
            [126.709874078446, 37.655498342031],
            [126.707888658782, 37.656416192272],
            [126.706137802828, 37.657212875176],
            [126.7055546449, 37.657493507742],
            [126.704976481123, 37.657771086656],
            [126.702713067691, 37.658856451508],
            [126.701679330846, 37.659351615115],
            [126.700460490718, 37.65993621425],
            [126.699652467828, 37.660294357523],
            [126.69860980398, 37.660773163623],
            [126.697452933281, 37.661296354267],
            [126.69478203043, 37.662507281389],
            [126.693870527236, 37.663018190544],
            [126.692882616797, 37.663585383537],
            [126.691924901127, 37.664001149292],
            [126.69277593226, 37.664880068764],
            [126.693438421311, 37.665531265106],
            [126.693674281499, 37.665887583812],
            [126.693817664594, 37.666160050831],
            [126.693868435211, 37.666518408872],
            [126.693830613196, 37.666784817091],
            [126.693619706978, 37.667191279851],
            [126.69358953821, 37.66744302213],
            [126.693522890798, 37.667731608903],
            [126.693515014733, 37.668279873384],
            [126.693565987648, 37.668685262563],
            [126.693610344538, 37.669361600971],
            [126.693630580846, 37.669621223069],
            [126.693632773473, 37.669639203131],
            [126.69364896199, 37.669726324071],
            [126.69370329419, 37.669922516793],
            [126.693713347836, 37.669958221413],
            [126.693755545163, 37.670169879108],
            [126.693640400845, 37.6701936345],
            [126.693596930598, 37.67020784644],
            [126.69358336698, 37.670280969734],
            [126.693616116059, 37.670420074779],
            [126.693653023238, 37.670458101918],
            [126.693716129212, 37.670492323247],
            [126.693766047996, 37.670563449874],
            [126.693788488029, 37.670591528562],
            [126.693813828934, 37.670630786831],
            [126.693915327426, 37.670617897414],
            [126.69398575822, 37.670744937526],
            [126.693967570493, 37.670757593771],
            [126.693939319627, 37.670756015488],
            [126.693773944902, 37.6708332657],
            [126.693695620799, 37.670888470928],
            [126.693748287124, 37.671062855791],
            [126.693782576317, 37.671125598648],
            [126.693817733828, 37.671181199074],
            [126.693837250289, 37.67122835265],
            [126.693843737446, 37.671241190362],
            [126.693882392391, 37.671350200329],
            [126.693898508777, 37.671391795056],
            [126.693909819133, 37.671419682516],
            [126.693929044389, 37.671468763389],
            [126.694018541123, 37.671694707506],
            [126.694056319797, 37.671678300235],
            [126.69407777919, 37.671669436576],
            [126.694678981984, 37.671412550071],
            [126.695265202246, 37.67116244192],
            [126.695370907804, 37.671312006912],
            [126.695477285355, 37.671463645778],
            [126.69557103986, 37.671597863101],
            [126.695601352672, 37.671641188239],
            [126.695782667611, 37.671900146374],
            [126.695872909435, 37.672034354344],
            [126.69590830546, 37.671971468323],
            [126.695994635199, 37.671815824322],
            [126.696200753358, 37.671443807313],
            [126.696311851988, 37.671301111033],
            [126.696374644938, 37.671218474429],
            [126.696404315504, 37.671224948064],
            [126.696556409551, 37.671063257037],
            [126.696582968408, 37.671040711374],
            [126.698162573275, 37.673338831816],
            [126.699516926285, 37.675315428839],
            [126.700210478713, 37.676311331287],
            [126.700266038863, 37.676391479206],
            [126.700463262228, 37.676675787829],
            [126.700396258697, 37.676723187919],
            [126.700657580604, 37.676949997856],
            [126.700739535443, 37.676984137347],
            [126.701298864192, 37.676594479014],
            [126.702432949966, 37.675810607474],
            [126.702487799307, 37.675771797672],
            [126.702616798078, 37.675681045731],
            [126.70267807677, 37.67565295564],
            [126.70282257294, 37.675572937233],
            [126.703082102879, 37.675421582756],
            [126.703125801672, 37.675407223758],
            [126.703810549769, 37.675064090286],
            [126.704348984749, 37.67480108583],
            [126.706071499188, 37.673943095741],
            [126.706505111934, 37.67373200288],
            [126.706533256066, 37.67376662543],
            [126.706554973485, 37.6737925826],
            [126.706544791799, 37.67401662812],
            [126.706531207533, 37.674327068039],
            [126.706532271587, 37.674367524204],
            [126.706532293835, 37.674396265167],
            [126.706078990788, 37.674397923615],
            [126.706074123493, 37.67529676296],
            [126.706074535636, 37.675350056293],
            [126.706063284537, 37.676243608834],
            [126.70606097469, 37.676282975376],
            [126.706062841425, 37.676312477763],
            [126.706057934366, 37.677204072927],
            [126.706057613698, 37.677259211393],
            [126.706055121044, 37.67815936265],
            [126.706054639489, 37.678195039788],
            [126.706054496046, 37.678222636088],
            [126.706514177152, 37.678227868117],
            [126.706958866879, 37.678231619366],
            [126.707322621376, 37.678234725613],
            [126.707415232345, 37.678235425082],
            [126.707861736007, 37.678239150421],
            [126.70820849892, 37.678242067475],
            [126.708323315879, 37.678243001645],
            [126.70839678783, 37.678238994759],
            [126.708444518121, 37.678237356481],
            [126.708446520515, 37.679140401957],
            [126.708446556209, 37.679188964231],
            [126.7084358263, 37.679640052327],
            [126.7084248224, 37.680094473288],
            [126.70842375175, 37.680127455068],
            [126.708422657637, 37.680149111621],
            [126.708491349786, 37.680150318417],
            [126.708492521569, 37.680212668317],
            [126.708417024206, 37.680212345578],
            [126.708416847967, 37.680525116186],
            [126.708416465742, 37.68067413534],
            [126.708417186128, 37.681052904051],
            [126.708417467223, 37.68111688248]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281119',
      properties: {
        rgnCode: '41281119',
        colCode: '41280',
        rgnKo: ['내유동'],
        rgnSize: 1,
        rgnBbox: [
          126.836757417716, 37.707399827752, 126.87371355948, 37.735529416929
        ],
        rgnCenter: [126.854110155955, 37.723056515799],
        rgnArea: 5946068,
        predVal: [
          0.13165, 0.13388, 0.1281, 0.14386, 0.18024, 0.16388, 0.1798, 0.18909,
          0.16284, 0.17143, 0.17085, 0.13082, 0.18803, 0.16713, 0.19007,
          0.07589, 0.1762, 0.16166, 0.20855, 0.18212, 0.18653, 0.18979, 0.16469,
          0.1865, 0.14929, 0.18193, 0.16629, 0.17122, 0.1674, 0.19174, 0.18429
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.840585357755, 37.716357543948],
            [126.840664357018, 37.716649203894],
            [126.840717957817, 37.716644006366],
            [126.840755335306, 37.716798437614],
            [126.840831207566, 37.717110085565],
            [126.840863831395, 37.717244391752],
            [126.840870030479, 37.717309684084],
            [126.840906377734, 37.717694715709],
            [126.840912763489, 37.717747124498],
            [126.840941249259, 37.71805949161],
            [126.840931160781, 37.718309008952],
            [126.8409190382, 37.718765204714],
            [126.840888243683, 37.718839167966],
            [126.840627601996, 37.719505958534],
            [126.840552855335, 37.719564518282],
            [126.840577812618, 37.71961484417],
            [126.840591446278, 37.719640882624],
            [126.840649215812, 37.719932810758],
            [126.840662359497, 37.720022880049],
            [126.840688313557, 37.720341496336],
            [126.841056819366, 37.720375153925],
            [126.841184999024, 37.720401185975],
            [126.840765604413, 37.721126683706],
            [126.840465538897, 37.721625588351],
            [126.840259924715, 37.721928121221],
            [126.840170692878, 37.722069450445],
            [126.840021326542, 37.722302946914],
            [126.839943445778, 37.722408622352],
            [126.839455071156, 37.723224585451],
            [126.839300034747, 37.723476191537],
            [126.839179492064, 37.723670003055],
            [126.8391719797, 37.723681975524],
            [126.839102260603, 37.723768011605],
            [126.839042814461, 37.723876405695],
            [126.839041903497, 37.723878026174],
            [126.838926690042, 37.723916428573],
            [126.8386815063, 37.723998167949],
            [126.838669319212, 37.724021125683],
            [126.838574100672, 37.724200285835],
            [126.838370135935, 37.724468040358],
            [126.83833440786, 37.724518805242],
            [126.838308402144, 37.724636074668],
            [126.838217981169, 37.724747542773],
            [126.838183016267, 37.72479157847],
            [126.838087625367, 37.724877839643],
            [126.83787432789, 37.724957188921],
            [126.837804705942, 37.724997860848],
            [126.837576372781, 37.72513120145],
            [126.837366245124, 37.725228213091],
            [126.837239061949, 37.725297374065],
            [126.837260647985, 37.725430800752],
            [126.837273113335, 37.725698205965],
            [126.837212187029, 37.726043406172],
            [126.837076533455, 37.726264457526],
            [126.836921756137, 37.726653881012],
            [126.836883296148, 37.726800269831],
            [126.836940987406, 37.726918412568],
            [126.836922521683, 37.727097300007],
            [126.83692173676, 37.727108560948],
            [126.836869189695, 37.727279859726],
            [126.836808553822, 37.727349059072],
            [126.836762346292, 37.727449109499],
            [126.836757417716, 37.727461860245],
            [126.83685819342, 37.727435638869],
            [126.836896159448, 37.727425465961],
            [126.837006976576, 37.727454451464],
            [126.837077798768, 37.727472425374],
            [126.837171002102, 37.727541433952],
            [126.837180608101, 37.727531996242],
            [126.837222202821, 37.727609654147],
            [126.837255912027, 37.727693328491],
            [126.837261186393, 37.727708877448],
            [126.837269546352, 37.72771920512],
            [126.837300661812, 37.727754926595],
            [126.837311208481, 37.727761284063],
            [126.837324409738, 37.727767537113],
            [126.83734750347, 37.727788741901],
            [126.837389248764, 37.727839434136],
            [126.837439139488, 37.727900048284],
            [126.837483373499, 37.727953897319],
            [126.837555909059, 37.727982198359],
            [126.83785047222, 37.728097029941],
            [126.83800937191, 37.728107070928],
            [126.83804407802, 37.728109281369],
            [126.83821799059, 37.72816850843],
            [126.838322131599, 37.728175193628],
            [126.838377750747, 37.728179388008],
            [126.838462075948, 37.728237265392],
            [126.838679710044, 37.728377963228],
            [126.838727336794, 37.728400787323],
            [126.838897441791, 37.728526255911],
            [126.838926661099, 37.728547739126],
            [126.838974901847, 37.728585555986],
            [126.839064779506, 37.728568651677],
            [126.839099261158, 37.728569870439],
            [126.839117906483, 37.728576635327],
            [126.839147517659, 37.728590127487],
            [126.839228342939, 37.728599311475],
            [126.839310583843, 37.728656374513],
            [126.839434917853, 37.728704368621],
            [126.839443316204, 37.728749320187],
            [126.839471911463, 37.728843870482],
            [126.839520429124, 37.728999533415],
            [126.839580610203, 37.729050097034],
            [126.839605151072, 37.729146948187],
            [126.839611456234, 37.729225971269],
            [126.839640265759, 37.729290015216],
            [126.839667677449, 37.729308351403],
            [126.839716470969, 37.729342763076],
            [126.839911414498, 37.729268286614],
            [126.840022181725, 37.729280268044],
            [126.840189845073, 37.729361881558],
            [126.840322592698, 37.729454105483],
            [126.840324881467, 37.729471028691],
            [126.840304895557, 37.729574936405],
            [126.840212733621, 37.729849603918],
            [126.840285015654, 37.730278705102],
            [126.84055155115, 37.730382634969],
            [126.840729369649, 37.730469784469],
            [126.840875670904, 37.730674547439],
            [126.840910700268, 37.731068676114],
            [126.841060656127, 37.731252325257],
            [126.841297067156, 37.731350761597],
            [126.841501363281, 37.731560260545],
            [126.841697863736, 37.731676372835],
            [126.841971921585, 37.731571475626],
            [126.842524490333, 37.731729079699],
            [126.842689087559, 37.731778899549],
            [126.84277503099, 37.731804917974],
            [126.842892458828, 37.73190319083],
            [126.842976297625, 37.731958721799],
            [126.843095460898, 37.731988109213],
            [126.843275234443, 37.732028668725],
            [126.843561662197, 37.732113283817],
            [126.843813852609, 37.732113053851],
            [126.844014132402, 37.732150396203],
            [126.844565281871, 37.732178331336],
            [126.844944687727, 37.732203729448],
            [126.845740960886, 37.732399545349],
            [126.845892855314, 37.732449857485],
            [126.846197877972, 37.732568366894],
            [126.846347019234, 37.732628206997],
            [126.846511810069, 37.732761477886],
            [126.846714577616, 37.732924016619],
            [126.846888736769, 37.733064335791],
            [126.847028876214, 37.733176878512],
            [126.847062064929, 37.733195427763],
            [126.847199697751, 37.733275199003],
            [126.847381089748, 37.73338036236],
            [126.847678427553, 37.733515138261],
            [126.849145801745, 37.734155889189],
            [126.84971122666, 37.734073315733],
            [126.849736404627, 37.734082069466],
            [126.850429644123, 37.734184246153],
            [126.851404415142, 37.734412182499],
            [126.856165453726, 37.735525359202],
            [126.856182825703, 37.735529416929],
            [126.856198472702, 37.735522174467],
            [126.857709041826, 37.734822519984],
            [126.858112485062, 37.734540469787],
            [126.858435182716, 37.734254842501],
            [126.858881217252, 37.734168413169],
            [126.858769851146, 37.733363899375],
            [126.860016971778, 37.733214392656],
            [126.860240568938, 37.732944516313],
            [126.860368567144, 37.732901810865],
            [126.860402451919, 37.732890021797],
            [126.860710949446, 37.73281616941],
            [126.861178740433, 37.732658527258],
            [126.861539672316, 37.732739511426],
            [126.862004109632, 37.732548211069],
            [126.862322249429, 37.732678920362],
            [126.862840880795, 37.732533305417],
            [126.863366272852, 37.73261272948],
            [126.863593825552, 37.732722543945],
            [126.864166339439, 37.732583049675],
            [126.86500267627, 37.732613158272],
            [126.865335335348, 37.731892791109],
            [126.865427165201, 37.731855660376],
            [126.8656820984, 37.731750730991],
            [126.865795958466, 37.731626313074],
            [126.86630907726, 37.73164104782],
            [126.866470167066, 37.730832483395],
            [126.867128038647, 37.729987852856],
            [126.867965694737, 37.729406755762],
            [126.868033382957, 37.729071457987],
            [126.867953260099, 37.72870046781],
            [126.868016414726, 37.728406951533],
            [126.868929179294, 37.728783349866],
            [126.869546959512, 37.729161321304],
            [126.869998370343, 37.729185358925],
            [126.870163452243, 37.729263467264],
            [126.871588589801, 37.729385271069],
            [126.871801125684, 37.72973576346],
            [126.872291061538, 37.730126769829],
            [126.872388399713, 37.729729813149],
            [126.872523782046, 37.729470988586],
            [126.873099783414, 37.729258449609],
            [126.873114503558, 37.728807380498],
            [126.873321948631, 37.728047535777],
            [126.873105401061, 37.727417499794],
            [126.87305777692, 37.726816054911],
            [126.873457490924, 37.726367990211],
            [126.87371355948, 37.725994070942],
            [126.873563633387, 37.725818292512],
            [126.873339981481, 37.725574654266],
            [126.87322751627, 37.725093804803],
            [126.87288759256, 37.724855787236],
            [126.872642133365, 37.72439608175],
            [126.872263534114, 37.723682401296],
            [126.871981211942, 37.723343878617],
            [126.871830600303, 37.723156844119],
            [126.871561167924, 37.722519340684],
            [126.871523944026, 37.721972684769],
            [126.871296724622, 37.721438324472],
            [126.870820451374, 37.721008145652],
            [126.870754555256, 37.720922508011],
            [126.870735647967, 37.720741618468],
            [126.870729432911, 37.720683247109],
            [126.870700354641, 37.72059743404],
            [126.870599110003, 37.720309202284],
            [126.870486794077, 37.719951304569],
            [126.870475347614, 37.719913901826],
            [126.870541120777, 37.719521100373],
            [126.870541486286, 37.719435896571],
            [126.870524928261, 37.719422165496],
            [126.870335507185, 37.719252717721],
            [126.86987085494, 37.719118181337],
            [126.869673928085, 37.719102104308],
            [126.869499769468, 37.71894282612],
            [126.869408545714, 37.718776514],
            [126.869015391946, 37.718683687904],
            [126.868632523876, 37.718452556007],
            [126.868088861264, 37.718488196807],
            [126.867896273405, 37.718476040915],
            [126.867620638816, 37.718458006232],
            [126.867599494585, 37.718453243143],
            [126.867547983282, 37.718438426822],
            [126.8666077179, 37.717727394529],
            [126.865989404732, 37.717366011206],
            [126.865788040907, 37.717347994521],
            [126.865585892474, 37.717343554127],
            [126.865126219275, 37.717145973264],
            [126.864775748903, 37.717194165349],
            [126.86393551276, 37.7169867498],
            [126.863498856063, 37.717004483984],
            [126.86312596555, 37.71723402714],
            [126.862932075239, 37.717448346118],
            [126.862651669946, 37.717613045885],
            [126.862288079084, 37.717621752864],
            [126.86216192005, 37.717496486853],
            [126.862136525837, 37.717435857951],
            [126.861685953792, 37.717487598402],
            [126.86165765239, 37.717495502331],
            [126.861447609846, 37.717328430363],
            [126.861220291146, 37.717241433222],
            [126.860300412247, 37.717445754969],
            [126.859323224923, 37.71743868818],
            [126.859179775668, 37.717336751129],
            [126.858939065187, 37.717172682925],
            [126.858714619473, 37.717180177605],
            [126.858603296534, 37.717069692562],
            [126.858413841174, 37.716882233286],
            [126.858432089109, 37.716517076398],
            [126.858203504094, 37.716283944416],
            [126.858101585089, 37.716120700804],
            [126.858084213785, 37.716083289632],
            [126.858068086041, 37.716053853508],
            [126.857947702122, 37.715850197035],
            [126.857343861574, 37.715641076028],
            [126.857333421117, 37.715615025375],
            [126.857293440578, 37.715487111535],
            [126.857277739147, 37.71543690858],
            [126.85696705948, 37.714421771201],
            [126.856957253075, 37.714314697495],
            [126.856950534875, 37.714211744984],
            [126.85691463722, 37.713839440182],
            [126.856890095487, 37.713593960132],
            [126.856771224799, 37.712674379186],
            [126.856696099629, 37.712214893692],
            [126.856684185255, 37.712113241146],
            [126.856640017861, 37.712063624775],
            [126.855976292319, 37.711561320964],
            [126.8559588432, 37.711541586299],
            [126.855853390615, 37.7112658417],
            [126.855887080057, 37.711065229271],
            [126.855634012976, 37.710849054082],
            [126.855608896854, 37.710835544558],
            [126.855331702579, 37.710738356417],
            [126.855268756359, 37.710717682284],
            [126.854903606528, 37.710584570621],
            [126.854740308496, 37.710522372008],
            [126.854261646239, 37.710374449275],
            [126.854191560236, 37.7103172405],
            [126.85419630856, 37.71026742303],
            [126.854203132618, 37.709799190652],
            [126.854203262236, 37.709791001035],
            [126.854118573999, 37.709605836824],
            [126.854008168775, 37.709350455139],
            [126.853773447434, 37.709221593435],
            [126.85350476967, 37.70904892873],
            [126.853407710108, 37.708978432406],
            [126.853332410865, 37.708931893006],
            [126.853436125113, 37.708849548884],
            [126.853505739626, 37.70885203286],
            [126.853536983917, 37.708834341067],
            [126.853656744929, 37.708768964057],
            [126.853733141083, 37.708742850735],
            [126.853928164431, 37.708656719402],
            [126.854107168304, 37.70859092955],
            [126.853787900474, 37.708607702386],
            [126.853361672164, 37.70862860158],
            [126.851463246461, 37.708676375712],
            [126.85139667341, 37.708678534378],
            [126.849918313439, 37.708712852729],
            [126.847689492824, 37.708764339347],
            [126.847404162066, 37.708771561899],
            [126.847337613424, 37.70877279031],
            [126.847027381569, 37.708778700016],
            [126.847043100097, 37.708745637143],
            [126.847098715952, 37.708646279399],
            [126.84715041597, 37.708553944034],
            [126.847249161963, 37.708376709121],
            [126.847324948933, 37.708240465077],
            [126.847385603575, 37.708199217072],
            [126.847399669358, 37.708186495799],
            [126.847455657399, 37.708115086326],
            [126.847468159525, 37.708101714306],
            [126.847497641063, 37.708070534349],
            [126.847517151902, 37.70804602653],
            [126.847368820541, 37.708012677159],
            [126.847195234732, 37.707973636485],
            [126.847126577376, 37.707958518494],
            [126.846839698141, 37.707893786513],
            [126.846638037449, 37.707727816135],
            [126.846373932281, 37.70756451121],
            [126.845994069385, 37.707399827752],
            [126.845784722285, 37.707803364414],
            [126.84563319627, 37.708120961912],
            [126.845515539563, 37.708342768271],
            [126.845301431719, 37.708591439696],
            [126.845027509895, 37.708884521043],
            [126.844154762456, 37.709931957484],
            [126.844035358778, 37.710075529115],
            [126.84400048511, 37.710118864026],
            [126.843882936186, 37.710264573263],
            [126.843815214164, 37.710412132945],
            [126.843845408929, 37.710596799187],
            [126.843865336999, 37.710718834585],
            [126.843869992662, 37.710732094013],
            [126.843875625122, 37.710744651993],
            [126.843775587209, 37.711151574408],
            [126.843547314129, 37.711928135213],
            [126.843521784898, 37.712110059962],
            [126.84353928052, 37.712453630473],
            [126.843515755501, 37.712647630824],
            [126.843478811759, 37.712874633551],
            [126.843439570427, 37.713152717902],
            [126.843372132399, 37.713495841118],
            [126.843338670997, 37.713679484991],
            [126.843258619745, 37.714076279693],
            [126.843190144633, 37.714420969037],
            [126.843185449276, 37.714623517666],
            [126.843110335745, 37.714857658636],
            [126.842949869622, 37.7151485627],
            [126.842808570893, 37.715396237013],
            [126.842381115588, 37.715812140813],
            [126.842160994126, 37.715994090647],
            [126.842003513124, 37.716101191596],
            [126.841575600544, 37.716187681112],
            [126.840911832027, 37.716276641022],
            [126.840844212135, 37.716292847342],
            [126.840585357755, 37.716357543948]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281122',
      properties: {
        rgnCode: '41281122',
        colCode: '41280',
        rgnKo: ['대장동'],
        rgnSize: 1,
        rgnBbox: [
          126.797279411539, 37.622611693073, 126.825757669851, 37.646433145358
        ],
        rgnCenter: [126.813033107285, 37.635485484267],
        rgnArea: 2393681,
        predVal: [
          0.13165, 0.13388, 0.1281, 0.14386, 0.18024, 0.16388, 0.1798, 0.18909,
          0.16284, 0.17143, 0.17085, 0.13082, 0.18803, 0.16713, 0.19007,
          0.07589, 0.1762, 0.16166, 0.20855, 0.18212, 0.18653, 0.18979, 0.16469,
          0.1865, 0.14929, 0.18193, 0.16629, 0.17122, 0.1674, 0.19174, 0.18429
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.81650047044, 37.646056661535],
            [126.816602497176, 37.645959561224],
            [126.816718099226, 37.64596720243],
            [126.816824047347, 37.645974837419],
            [126.817254701953, 37.646007261172],
            [126.817330217481, 37.64600481131],
            [126.817428921026, 37.646000379398],
            [126.817448366445, 37.645999770046],
            [126.817625734114, 37.646048600187],
            [126.817708235369, 37.646075974239],
            [126.817851522932, 37.646082125806],
            [126.81798941168, 37.646090431142],
            [126.818104049016, 37.646085032379],
            [126.818180374473, 37.646080393862],
            [126.818351722771, 37.646125952028],
            [126.818504362422, 37.646116665598],
            [126.81871680769, 37.646093182184],
            [126.818880212118, 37.64604205264],
            [126.818950452576, 37.646018970253],
            [126.819073554731, 37.645999357248],
            [126.819343331873, 37.646092772779],
            [126.819488094733, 37.646079490603],
            [126.819570418871, 37.646068283323],
            [126.819584815792, 37.646070224602],
            [126.819624529084, 37.64607558357],
            [126.819887415126, 37.646118974101],
            [126.819994407625, 37.646149321436],
            [126.820360638218, 37.646237098743],
            [126.820562698171, 37.646272096375],
            [126.82101862696, 37.6463361874],
            [126.821026651239, 37.646340073869],
            [126.821026712232, 37.646357012295],
            [126.821083494399, 37.646370037153],
            [126.821146064129, 37.646356023558],
            [126.821259457275, 37.64634978192],
            [126.82130668052, 37.646370360256],
            [126.821307395745, 37.646379172885],
            [126.821428065349, 37.646433145358],
            [126.821628078951, 37.646283438137],
            [126.821707895992, 37.646104193673],
            [126.821813631031, 37.645866632464],
            [126.821824157249, 37.645843205121],
            [126.821987239838, 37.645703414814],
            [126.821995798273, 37.645697211108],
            [126.822089007214, 37.645640312156],
            [126.822172593156, 37.645589344939],
            [126.822206303545, 37.645570079269],
            [126.822230834575, 37.645556079354],
            [126.822269497392, 37.645531739691],
            [126.822425422088, 37.645380540556],
            [126.822649124832, 37.645249238416],
            [126.822691808806, 37.645253672851],
            [126.822716970602, 37.645255341751],
            [126.822727288907, 37.645252321102],
            [126.82274151733, 37.645248891927],
            [126.822927857514, 37.645152346321],
            [126.822949013882, 37.645127961911],
            [126.82303905206, 37.645036532027],
            [126.82308260492, 37.644900316368],
            [126.823136998012, 37.644850709827],
            [126.823195158122, 37.644827336347],
            [126.823301130154, 37.644767653684],
            [126.82335818971, 37.644735358805],
            [126.823495077213, 37.644659153708],
            [126.82356779466, 37.64461779151],
            [126.823709540712, 37.644566154081],
            [126.8237432462, 37.644553338944],
            [126.824088834199, 37.644519739228],
            [126.824132194514, 37.644525507603],
            [126.824228007154, 37.644535048793],
            [126.824508047413, 37.64450110607],
            [126.824521693514, 37.644499459738],
            [126.82469789804, 37.6444753886],
            [126.824786801567, 37.644440780192],
            [126.824885518778, 37.644400807586],
            [126.824923049176, 37.644394719115],
            [126.824936481053, 37.644392612916],
            [126.824943373065, 37.644391370875],
            [126.825018607421, 37.644377662485],
            [126.825050845054, 37.644372124659],
            [126.825068712214, 37.644343959859],
            [126.825081109494, 37.64425709733],
            [126.825152686903, 37.644208560681],
            [126.825146245117, 37.644196703223],
            [126.825252248566, 37.644161047863],
            [126.824990778552, 37.643477744473],
            [126.824994282287, 37.643476650525],
            [126.824975237192, 37.643413733929],
            [126.824641952678, 37.642556641107],
            [126.824623491227, 37.642510618616],
            [126.824291700211, 37.641614866043],
            [126.824277146635, 37.641579021379],
            [126.82395671113, 37.640718927402],
            [126.823941501699, 37.640677919111],
            [126.823947285306, 37.640675720424],
            [126.823845500351, 37.640416671718],
            [126.823602403563, 37.639793424552],
            [126.823586381141, 37.6397514149],
            [126.823420378536, 37.639317542605],
            [126.823256550906, 37.638889367503],
            [126.823237074489, 37.638837360751],
            [126.822991448382, 37.638181646167],
            [126.822907314526, 37.63795877944],
            [126.822892452585, 37.637910338466],
            [126.822563491787, 37.637042046426],
            [126.82254480244, 37.636992941883],
            [126.822206188038, 37.636104803578],
            [126.822272493318, 37.636088434469],
            [126.822348001701, 37.636070556648],
            [126.822540502691, 37.636022331207],
            [126.822931866211, 37.635927907197],
            [126.823657138247, 37.635753600544],
            [126.824048629854, 37.635661299321],
            [126.824513759591, 37.635550655281],
            [126.824743603201, 37.635496049277],
            [126.825018665756, 37.635430239254],
            [126.825258008819, 37.635373006593],
            [126.825347209772, 37.635351669531],
            [126.825444039882, 37.635328397677],
            [126.825680422931, 37.635272186854],
            [126.825757669851, 37.635251426311],
            [126.825511195234, 37.634883775658],
            [126.825504783121, 37.634874062571],
            [126.825561901185, 37.63487579655],
            [126.825557169515, 37.634869311469],
            [126.825520727999, 37.634790448663],
            [126.825251820546, 37.634208529657],
            [126.825226396133, 37.634151730118],
            [126.825147012691, 37.633974389293],
            [126.825122851649, 37.633924772406],
            [126.825002587987, 37.63367784195],
            [126.824915114534, 37.633396065599],
            [126.824853686504, 37.633262899311],
            [126.824504703885, 37.632471498447],
            [126.824497711535, 37.632458189532],
            [126.824467768461, 37.632401220859],
            [126.824448308492, 37.63239515511],
            [126.824373076181, 37.632374869306],
            [126.82435592669, 37.632369068294],
            [126.824314436038, 37.632380412364],
            [126.824280768441, 37.632389173356],
            [126.824092510398, 37.632434551959],
            [126.82403819097, 37.632447741664],
            [126.823962595018, 37.63246604391],
            [126.823869712179, 37.632488662793],
            [126.823673019548, 37.63253629849],
            [126.8232373482, 37.632643479002],
            [126.823053973406, 37.632688124522],
            [126.822313546336, 37.632868251915],
            [126.82195768629, 37.632955538175],
            [126.821689821988, 37.633022217006],
            [126.821249951505, 37.633130113557],
            [126.821006436983, 37.633190043674],
            [126.820985216975, 37.633195047671],
            [126.820811460168, 37.633238722349],
            [126.820729650532, 37.633258589713],
            [126.820637637456, 37.633281459653],
            [126.820446106384, 37.633329233019],
            [126.820410623587, 37.633338683871],
            [126.820237017243, 37.633301486225],
            [126.819992449236, 37.633249024739],
            [126.819537862961, 37.633150694632],
            [126.819376887436, 37.633118912052],
            [126.819181283485, 37.633080786926],
            [126.81835801353, 37.632920642552],
            [126.818262670473, 37.632901564985],
            [126.817714613173, 37.632794144953],
            [126.817697752313, 37.632790920218],
            [126.817596486842, 37.632770913889],
            [126.817566128028, 37.632765199418],
            [126.816431508747, 37.632542559561],
            [126.816365288743, 37.632534445983],
            [126.815233233388, 37.632308672235],
            [126.815185061717, 37.632300117979],
            [126.81398496704, 37.632058484718],
            [126.813055714931, 37.631871799293],
            [126.812624879567, 37.631787085238],
            [126.812478732009, 37.631758191101],
            [126.812431943554, 37.631749466751],
            [126.812439196573, 37.631761857801],
            [126.812497933923, 37.631879114992],
            [126.812538873201, 37.631960845144],
            [126.812231399167, 37.631826259856],
            [126.812177668759, 37.631803108633],
            [126.812155155427, 37.631781971617],
            [126.812098528028, 37.631726344488],
            [126.812110854285, 37.631654709552],
            [126.812126885009, 37.631565223189],
            [126.812099651353, 37.631502462444],
            [126.811986293048, 37.631445581914],
            [126.81191130341, 37.631411755877],
            [126.811809299406, 37.631367209781],
            [126.81174168768, 37.631336756142],
            [126.811498828712, 37.631236410871],
            [126.811266949174, 37.631140011064],
            [126.811191117348, 37.631107949125],
            [126.811148452874, 37.631090428369],
            [126.811037198917, 37.631044046706],
            [126.810890024147, 37.630863712664],
            [126.810962788627, 37.630681580485],
            [126.811043485209, 37.630476296931],
            [126.81096008496, 37.630351719267],
            [126.810849675276, 37.630307915582],
            [126.810786477591, 37.630284027641],
            [126.810682647695, 37.630241126402],
            [126.810482935821, 37.630250254497],
            [126.810296131944, 37.630337094459],
            [126.810158905087, 37.630272316959],
            [126.810142938246, 37.630265389577],
            [126.809864984363, 37.630144820131],
            [126.809787026779, 37.630111825827],
            [126.809763964311, 37.630102057794],
            [126.809739920454, 37.630086251613],
            [126.809677113633, 37.630047137192],
            [126.809638151546, 37.630026378388],
            [126.809620196075, 37.63005712658],
            [126.809262134875, 37.630664162916],
            [126.808849602302, 37.630510864472],
            [126.808658898262, 37.630440096498],
            [126.80844250143, 37.630360546697],
            [126.808222823523, 37.63027954956],
            [126.808030081332, 37.630208858318],
            [126.807997024875, 37.630191280097],
            [126.808464571199, 37.629405096161],
            [126.808479362179, 37.629380343476],
            [126.808499217859, 37.629350859931],
            [126.808508433637, 37.629335486309],
            [126.808516670092, 37.629322075215],
            [126.808521800784, 37.629313731544],
            [126.80847406504, 37.629286597101],
            [126.808445747494, 37.62919766032],
            [126.808213225534, 37.629060213908],
            [126.80803346616, 37.628894400689],
            [126.807955785472, 37.628772920608],
            [126.807910013018, 37.628661673879],
            [126.807900111159, 37.628565946772],
            [126.807770886087, 37.62813944606],
            [126.807756960382, 37.628097077239],
            [126.807771639063, 37.628085028239],
            [126.80780233103, 37.628061743304],
            [126.807875765201, 37.628007570914],
            [126.807906281519, 37.62785981558],
            [126.807871651627, 37.627759227651],
            [126.807633345987, 37.627364667534],
            [126.807592362952, 37.627298450392],
            [126.807590857189, 37.627285032357],
            [126.807581530058, 37.627203766841],
            [126.807423335065, 37.627111552933],
            [126.80727210333, 37.626890240136],
            [126.807139448105, 37.62669592401],
            [126.806926194103, 37.626371553815],
            [126.806820509788, 37.626217375313],
            [126.806768625928, 37.62613993172],
            [126.806739106712, 37.626095987347],
            [126.806722612428, 37.626070255215],
            [126.806695947177, 37.626083014092],
            [126.806615202762, 37.626121658851],
            [126.806234764967, 37.626303712179],
            [126.806114218103, 37.626362274409],
            [126.806018147986, 37.626408939111],
            [126.805917304373, 37.626331873522],
            [126.805702277229, 37.626236761037],
            [126.80525430428, 37.626158379531],
            [126.805203917365, 37.626124013501],
            [126.805052214742, 37.626024617399],
            [126.80496157938, 37.625861398443],
            [126.804879921052, 37.62566619163],
            [126.804798738555, 37.625462525364],
            [126.804557475266, 37.625279089203],
            [126.804337349439, 37.62511332001],
            [126.804291444788, 37.625007828847],
            [126.804228247209, 37.624754935746],
            [126.804222278382, 37.624617445496],
            [126.804215499574, 37.624482125246],
            [126.804250827027, 37.624470624757],
            [126.804312886561, 37.624451015081],
            [126.804739941189, 37.624704137053],
            [126.805107484359, 37.624719822748],
            [126.805396564661, 37.624733944363],
            [126.805904085453, 37.624714803071],
            [126.805921508562, 37.624515517537],
            [126.80577862694, 37.62433615712],
            [126.805342705344, 37.624279806828],
            [126.805154003233, 37.624183061259],
            [126.804996436954, 37.624049219908],
            [126.804925217495, 37.623839479651],
            [126.804912040084, 37.623625718591],
            [126.804954120772, 37.623260477972],
            [126.804895391342, 37.623022539828],
            [126.804510558748, 37.622852622142],
            [126.804334526295, 37.622699774442],
            [126.804291230635, 37.622657761537],
            [126.804257883504, 37.622618891642],
            [126.804229365948, 37.622617060031],
            [126.804211358318, 37.622615948749],
            [126.804144877726, 37.622611693073],
            [126.804132091471, 37.622615077385],
            [126.804012503971, 37.62264233011],
            [126.80398544782, 37.622653276761],
            [126.803863384242, 37.622704410131],
            [126.803846545358, 37.622711085206],
            [126.803799553029, 37.622760479211],
            [126.803681852804, 37.622883301554],
            [126.803564692658, 37.623007350012],
            [126.803472303258, 37.623106554901],
            [126.803384748792, 37.623197848233],
            [126.80335449914, 37.623229701086],
            [126.803252307125, 37.623338854158],
            [126.803078059892, 37.623507864124],
            [126.803025338769, 37.623555770575],
            [126.802985135437, 37.623611851917],
            [126.802855128855, 37.623671548183],
            [126.802850914779, 37.623680145432],
            [126.802606394727, 37.624178794429],
            [126.802553061957, 37.624302895365],
            [126.802335988583, 37.624681309426],
            [126.802150575935, 37.624830000093],
            [126.802038673393, 37.625095509443],
            [126.801859133228, 37.625284194975],
            [126.80165566853, 37.625493751409],
            [126.80125825113, 37.625883309243],
            [126.801048820147, 37.626267473021],
            [126.800974749628, 37.626439244743],
            [126.800899965318, 37.626615150689],
            [126.800795796377, 37.626702350379],
            [126.800591629695, 37.626715634707],
            [126.800283422586, 37.626616866877],
            [126.799860373346, 37.626731866268],
            [126.799621973231, 37.626841440982],
            [126.79941797057, 37.626932000428],
            [126.799100424189, 37.627014940746],
            [126.79884355171, 37.627105705813],
            [126.79841973417, 37.627243115031],
            [126.798032406494, 37.627395848215],
            [126.79776208989, 37.627476487775],
            [126.797744056076, 37.627484574443],
            [126.79779469949, 37.627552703725],
            [126.797796934289, 37.627555698829],
            [126.797817015009, 37.627594556625],
            [126.797818988846, 37.627597704445],
            [126.797856728735, 37.62765785579],
            [126.797853055925, 37.627675373467],
            [126.797863245971, 37.627698077673],
            [126.797839289603, 37.627813325429],
            [126.797815410576, 37.627937565068],
            [126.797797813862, 37.628022046393],
            [126.797777872156, 37.628206135751],
            [126.797772101788, 37.62825750854],
            [126.797771729659, 37.628298385252],
            [126.797771679764, 37.628412115566],
            [126.797722748539, 37.628579684974],
            [126.797693949222, 37.62868440991],
            [126.797595938089, 37.62902801755],
            [126.797590926217, 37.629046334771],
            [126.797588555481, 37.629055763908],
            [126.797584447478, 37.629070812142],
            [126.797573716021, 37.629109400479],
            [126.797549917513, 37.629187275875],
            [126.797548077456, 37.629205634615],
            [126.797546645562, 37.629219723427],
            [126.79754456133, 37.629240379238],
            [126.797543268871, 37.629253188903],
            [126.797535781799, 37.629327434521],
            [126.797532487717, 37.629351394826],
            [126.79753041311, 37.629380961319],
            [126.797523221827, 37.62944230545],
            [126.797521527031, 37.629457249735],
            [126.797545918051, 37.62949837647],
            [126.797558164352, 37.629519012001],
            [126.797573081678, 37.629544706631],
            [126.797552499331, 37.629577340496],
            [126.797444591806, 37.62971552603],
            [126.797438488846, 37.629764114195],
            [126.79750442706, 37.629765904035],
            [126.797523715126, 37.629796507544],
            [126.797529803606, 37.629831953509],
            [126.797529692681, 37.629893264826],
            [126.797548157981, 37.629934777744],
            [126.797578092449, 37.629966282603],
            [126.797621408968, 37.630000819844],
            [126.797641240994, 37.630010656745],
            [126.797671087245, 37.630020565002],
            [126.797760843516, 37.63003287421],
            [126.797809241862, 37.630102378077],
            [126.797940874689, 37.630195496013],
            [126.797936915834, 37.630197399254],
            [126.797781272746, 37.630268001495],
            [126.797454109316, 37.630415187419],
            [126.797367209124, 37.630456103646],
            [126.79733410984, 37.630471624273],
            [126.797279411539, 37.630497874184],
            [126.797369305726, 37.630530356809],
            [126.797770275144, 37.630677945181],
            [126.797853570957, 37.630708307756],
            [126.798191099641, 37.630831756116],
            [126.798565304711, 37.630970258858],
            [126.798083599375, 37.631792832347],
            [126.798065753191, 37.631823146485],
            [126.79810346265, 37.631837122585],
            [126.798514736583, 37.631989555404],
            [126.798719208517, 37.632065327794],
            [126.79891507957, 37.632137922633],
            [126.799333928241, 37.632293158628],
            [126.799739808767, 37.632443577761],
            [126.799783428983, 37.63246305939],
            [126.800165211831, 37.632601957409],
            [126.800429828565, 37.632700173679],
            [126.800580004255, 37.632755100796],
            [126.800811564984, 37.632840384951],
            [126.800970354282, 37.632898398553],
            [126.801043243236, 37.632924290356],
            [126.801108909183, 37.632948385911],
            [126.801797074917, 37.633202799319],
            [126.801813864861, 37.633206900153],
            [126.801884231957, 37.633231327584],
            [126.801991168247, 37.633268808904],
            [126.802075726168, 37.633299179635],
            [126.802175581921, 37.633336693868],
            [126.802211186961, 37.633248395211],
            [126.80224192313, 37.633171819038],
            [126.802318924336, 37.632981581642],
            [126.802392525049, 37.632800366239],
            [126.802403472611, 37.632697033644],
            [126.802405715574, 37.63267586447],
            [126.802403640215, 37.632655444829],
            [126.802445395299, 37.632648199349],
            [126.80252130055, 37.63263490283],
            [126.80252414364, 37.63263507881],
            [126.802515930597, 37.632528704623],
            [126.802503835799, 37.632429315468],
            [126.802481976638, 37.632371994478],
            [126.802533472489, 37.6323909568],
            [126.802961580012, 37.632548384909],
            [126.80330105893, 37.632673263479],
            [126.803642768366, 37.632799054797],
            [126.803820039919, 37.632864636738],
            [126.805356536307, 37.633431014469],
            [126.807249106855, 37.634137051761],
            [126.807302875661, 37.634136797905],
            [126.807408180314, 37.634136718914],
            [126.807759765373, 37.63413381],
            [126.807760234234, 37.634127765215],
            [126.808210431663, 37.63411912446],
            [126.808317746256, 37.634117227977],
            [126.80840786143, 37.634116015021],
            [126.808335424047, 37.634242474683],
            [126.808592691289, 37.63431521702],
            [126.808633474853, 37.634245656207],
            [126.808654006455, 37.634253203915],
            [126.808657363168, 37.634247551267],
            [126.808707759415, 37.634180186503],
            [126.808726455021, 37.634183604724],
            [126.809661211184, 37.634466124996],
            [126.810455784389, 37.634709596865],
            [126.811341278027, 37.634980599652],
            [126.811459668152, 37.635016514139],
            [126.811634423433, 37.63506952066],
            [126.8119416297, 37.635162707131],
            [126.811939356747, 37.635196715351],
            [126.811929964138, 37.635219251707],
            [126.811864271494, 37.635319397815],
            [126.811858661821, 37.635324506346],
            [126.811783101607, 37.635393273546],
            [126.811774335094, 37.635401242103],
            [126.811647450278, 37.63548879312],
            [126.811478780882, 37.635630344318],
            [126.81145125963, 37.635653446079],
            [126.811400706983, 37.635658265949],
            [126.811369554927, 37.635661243031],
            [126.810878101985, 37.63573494276],
            [126.810855502521, 37.635738329955],
            [126.810572396962, 37.635780785662],
            [126.810560133829, 37.635782630838],
            [126.810565662932, 37.63580905642],
            [126.810552731752, 37.635846164739],
            [126.810464153211, 37.635914576655],
            [126.810408316173, 37.635956597876],
            [126.810400546711, 37.635960036031],
            [126.810369591562, 37.635970112864],
            [126.810365511461, 37.635970700899],
            [126.810313936607, 37.635971986818],
            [126.810274682185, 37.635962345819],
            [126.810219116495, 37.636022206726],
            [126.810220563944, 37.636045364171],
            [126.810147258991, 37.636135072664],
            [126.8099779005, 37.636216786726],
            [126.80987932082, 37.636266090268],
            [126.809753281159, 37.636464190424],
            [126.809879647987, 37.636623958673],
            [126.810053830141, 37.637091217545],
            [126.810430797563, 37.637123092535],
            [126.810984336693, 37.637221743646],
            [126.811307841269, 37.637382279018],
            [126.811551629599, 37.637525836922],
            [126.811808662866, 37.637674010597],
            [126.811894060591, 37.637997147888],
            [126.811936630731, 37.63849933037],
            [126.811940533984, 37.638563936637],
            [126.811945242001, 37.638632868882],
            [126.811947270808, 37.638726213276],
            [126.812218500243, 37.638662030733],
            [126.812065421875, 37.638733791054],
            [126.812005974075, 37.638765049556],
            [126.811973128852, 37.638805180333],
            [126.811968058818, 37.638838598405],
            [126.811933830985, 37.63906612999],
            [126.811813861997, 37.639194055968],
            [126.811818116207, 37.63929884633],
            [126.81182452454, 37.639456554478],
            [126.811821986928, 37.639460893103],
            [126.811799890899, 37.63951984448],
            [126.811784988393, 37.639628189917],
            [126.811775150336, 37.639700900883],
            [126.81177440548, 37.63971311692],
            [126.811767268866, 37.639739341865],
            [126.811743824294, 37.639825077077],
            [126.811735059154, 37.639890203544],
            [126.811712130153, 37.640062730594],
            [126.811704196175, 37.640081701394],
            [126.81166757851, 37.640237123902],
            [126.811666270452, 37.640292558848],
            [126.811664359334, 37.640375715853],
            [126.811688574675, 37.64056604994],
            [126.811683806641, 37.640681060852],
            [126.811727486655, 37.640724693307],
            [126.811745522796, 37.640743084207],
            [126.811715644115, 37.640801401363],
            [126.811643483692, 37.640952225743],
            [126.811708189882, 37.640979142898],
            [126.811690105896, 37.640997349555],
            [126.811739482701, 37.641131043704],
            [126.811795031336, 37.641163568169],
            [126.81185535344, 37.641413145352],
            [126.811899761727, 37.641433461656],
            [126.812554763578, 37.641755701364],
            [126.812579368535, 37.641784310709],
            [126.812707872125, 37.641924996557],
            [126.812974002655, 37.641945964389],
            [126.81417829674, 37.642039151591],
            [126.81419994022, 37.642141942235],
            [126.81421580607, 37.64221733404],
            [126.814227240869, 37.642266004879],
            [126.814370251672, 37.642392999088],
            [126.814434613864, 37.642526355462],
            [126.814575292385, 37.642680104695],
            [126.814607604976, 37.642852692719],
            [126.814506599206, 37.642963532854],
            [126.81423099928, 37.643115811047],
            [126.814126078444, 37.643251241267],
            [126.81383241987, 37.64350997613],
            [126.813797943602, 37.643653005195],
            [126.813784378329, 37.643735062475],
            [126.813777175994, 37.64377852308],
            [126.813769065133, 37.643827307003],
            [126.813641803609, 37.644446570047],
            [126.813884003398, 37.644669532425],
            [126.813996706672, 37.644774675233],
            [126.814042488991, 37.644817364122],
            [126.814685739778, 37.645019824562],
            [126.815080792972, 37.6449725987],
            [126.81518374395, 37.64495017389],
            [126.815557496742, 37.644874811368],
            [126.815612344285, 37.644863086019],
            [126.815784921488, 37.64491391155],
            [126.816003458748, 37.64497299897],
            [126.816033290613, 37.645005751277],
            [126.816274997509, 37.645271107856],
            [126.816286975492, 37.645284280901],
            [126.816346524154, 37.645583498152],
            [126.816483271428, 37.645996719716],
            [126.81650047044, 37.646056661535]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41287102',
      properties: {
        rgnCode: '41287102',
        colCode: '41280',
        rgnKo: ['주엽동'],
        rgnSize: 1,
        rgnBbox: [
          126.750917872901, 37.663286985504, 126.773240064691, 37.679522121321
        ],
        rgnCenter: [126.761894639137, 37.671275730442],
        rgnArea: 1926963,
        predVal: [
          0.1069, 0.10685, 0.10735, 0.10726, 0.10727, 0.10727, 0.10727, 0.10726,
          0.10727, 0.10735, 0.10725, 0.10725, 0.10725, 0.10725, 0.10725,
          0.10605, 0.01268, 0.10435, 0.10633, 0.10605, 0.10594, 0.10587,
          0.10587, 0.10587, 0.10587, 0.10587, 0.10587, 0.10525, 0.10511, 0.1038,
          0.10274
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.771859834224, 37.670945421423],
            [126.771792015306, 37.670847173655],
            [126.769923167662, 37.668141147174],
            [126.769852972808, 37.668043163878],
            [126.769778918905, 37.667946974899],
            [126.769701232365, 37.667852670772],
            [126.769619799574, 37.667760341364],
            [126.769534733601, 37.667670076988],
            [126.769446260855, 37.667581968177],
            [126.769354267719, 37.667496104798],
            [126.769258980598, 37.667412577385],
            [126.769160512554, 37.66733147625],
            [126.769058749967, 37.66725289126],
            [126.768954032859, 37.667176823075],
            [126.768846474289, 37.667103362007],
            [126.76873596035, 37.66703268802],
            [126.767636533946, 37.666351090848],
            [126.767225456233, 37.666096201166],
            [126.762694510695, 37.663286985504],
            [126.762555219341, 37.663428966839],
            [126.76249165698, 37.663493708017],
            [126.762370793991, 37.663614283343],
            [126.762246650529, 37.663732779654],
            [126.762119339924, 37.66384919717],
            [126.761988976087, 37.663963355916],
            [126.76185544568, 37.664075255653],
            [126.761719088709, 37.664184897062],
            [126.761579678792, 37.664292189574],
            [126.761437442602, 37.664397133642],
            [126.761292494059, 37.664499549291],
            [126.761144719537, 37.664599526379],
            [126.760994346007, 37.66469697526],
            [126.760841373763, 37.664791805828],
            [126.760685802812, 37.664884018073],
            [126.760527860127, 37.664973522351],
            [126.760367545425, 37.665060408749],
            [126.760204972637, 37.665144497298],
            [126.760040141773, 37.665225787987],
            [126.759873166182, 37.665304281039],
            [126.759704046168, 37.665379886349],
            [126.758090182198, 37.666083739234],
            [126.75799497517, 37.66594542384],
            [126.75300712203, 37.668120686459],
            [126.752918926202, 37.66815915226],
            [126.752779414163, 37.668189130767],
            [126.752519706764, 37.66830237543],
            [126.752322892614, 37.668419085996],
            [126.751072355447, 37.668964317717],
            [126.751047950565, 37.668974987447],
            [126.750989952685, 37.668998469708],
            [126.750917872901, 37.669030030759],
            [126.751109512248, 37.669307666388],
            [126.753794187528, 37.673198138839],
            [126.754012358409, 37.673514116528],
            [126.756710987516, 37.677424190654],
            [126.756847161665, 37.677621515218],
            [126.758159187978, 37.679522121321],
            [126.758282359353, 37.679468407352],
            [126.760320168248, 37.678579371434],
            [126.761264302825, 37.678167483198],
            [126.762300829728, 37.677715231162],
            [126.762628551564, 37.677572280857],
            [126.764741970404, 37.67665016554],
            [126.765238930749, 37.67643330766],
            [126.766232502152, 37.675999765081],
            [126.76841893166, 37.675045654597],
            [126.768667062307, 37.674936947149],
            [126.770804391572, 37.674005399694],
            [126.771798464037, 37.673572171328],
            [126.773009768943, 37.67304412302],
            [126.773240064691, 37.672943750368],
            [126.771928443694, 37.671044661757],
            [126.771859834224, 37.670945421423]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281104',
      properties: {
        rgnCode: '41281104',
        colCode: '41280',
        rgnKo: ['원흥동'],
        rgnSize: 1,
        rgnBbox: [
          126.85975005937, 37.633024194228, 126.885344740085, 37.660540686176
        ],
        rgnCenter: [126.871125554234, 37.647713029378],
        rgnArea: 3754418,
        predVal: [
          0.2633, 0.26775, 0.2562, 0.28773, 0.36048, 0.32776, 0.35959, 0.37818,
          0.32568, 0.34286, 0.34171, 0.26163, 0.37606, 0.33426, 0.38013,
          0.15177, 0.3524, 0.32333, 0.41709, 0.36423, 0.37307, 0.37957, 0.32938,
          0.37301, 0.29859, 0.36386, 0.33257, 0.34243, 0.33479, 0.38347, 0.36859
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.85975005937, 37.650844507509],
            [126.859839659394, 37.651309617284],
            [126.859902313099, 37.651631673877],
            [126.859919539813, 37.65171398959],
            [126.859891216951, 37.652080418059],
            [126.860008080373, 37.652272727189],
            [126.860067950249, 37.652372509856],
            [126.860740739764, 37.653470179104],
            [126.860770579112, 37.653517182509],
            [126.860798012449, 37.653560110631],
            [126.860880891583, 37.653706392573],
            [126.861010960049, 37.653713322888],
            [126.861495227744, 37.653743288457],
            [126.861748088329, 37.653757886777],
            [126.86206687018, 37.65377741875],
            [126.862173267336, 37.653783653079],
            [126.862907992853, 37.654341275785],
            [126.863067962854, 37.654461040955],
            [126.863165506398, 37.654536720113],
            [126.863494374765, 37.654784350724],
            [126.863665466427, 37.654914876711],
            [126.863847475904, 37.655055416004],
            [126.864129767922, 37.65526877146],
            [126.864386394487, 37.655463193963],
            [126.86458840521, 37.655617801538],
            [126.864792555822, 37.655773924881],
            [126.865076647102, 37.655997857571],
            [126.864965012173, 37.656702587476],
            [126.864936933921, 37.656886065363],
            [126.865498514287, 37.65713857249],
            [126.865595555866, 37.657182048219],
            [126.866267192284, 37.657484664468],
            [126.867007265999, 37.657596222329],
            [126.867846081285, 37.657721987718],
            [126.86821944813, 37.657627825957],
            [126.868608895936, 37.657469648912],
            [126.869130157828, 37.657302077261],
            [126.87069740763, 37.656987214398],
            [126.870492478824, 37.65696835482],
            [126.869815928952, 37.656906100952],
            [126.870809586679, 37.656730137674],
            [126.870913112359, 37.656709322707],
            [126.871368271542, 37.656626566728],
            [126.871549271858, 37.656895184486],
            [126.872453494542, 37.656768148812],
            [126.872596966994, 37.656636033681],
            [126.872999215453, 37.656603947426],
            [126.873768124311, 37.656342959505],
            [126.874859444647, 37.656678037298],
            [126.875730953617, 37.656591124811],
            [126.875717121016, 37.657065382846],
            [126.875714536783, 37.657119348439],
            [126.875755624539, 37.657148854101],
            [126.875792297612, 37.65717538183],
            [126.876506458963, 37.657722986997],
            [126.876556644279, 37.658532069721],
            [126.876782455798, 37.659125690077],
            [126.876868296503, 37.659366791298],
            [126.877310820805, 37.660540686176],
            [126.877416571966, 37.660458178199],
            [126.878014881991, 37.660322200505],
            [126.878476246352, 37.660216980406],
            [126.878903719795, 37.660251788641],
            [126.878943720333, 37.660255073717],
            [126.878992219522, 37.660259088386],
            [126.879388303455, 37.660292195456],
            [126.879444659077, 37.660322355318],
            [126.879747770895, 37.66048457356],
            [126.879952985772, 37.660502174009],
            [126.880229252556, 37.660524171851],
            [126.880362810851, 37.660463313186],
            [126.880677069682, 37.660322452961],
            [126.881199189465, 37.66039137056],
            [126.881558513117, 37.660493186142],
            [126.882000845956, 37.660161265949],
            [126.882266240061, 37.65993750743],
            [126.882446974081, 37.659785110098],
            [126.882464711059, 37.659635602396],
            [126.882475026886, 37.659548695867],
            [126.882486261771, 37.65945399684],
            [126.882527775168, 37.659219289995],
            [126.882445023947, 37.658806380273],
            [126.882424098192, 37.65856841194],
            [126.882462211154, 37.658539700333],
            [126.882582336569, 37.658449210558],
            [126.882644413164, 37.658402449513],
            [126.88280203165, 37.658294896802],
            [126.882898919218, 37.658228772678],
            [126.883061426526, 37.658089465199],
            [126.883480120998, 37.657979425477],
            [126.883598483648, 37.657948820638],
            [126.883835767748, 37.657964193954],
            [126.884011973031, 37.657976532753],
            [126.883881210737, 37.657031325642],
            [126.883425612301, 37.656325281484],
            [126.883481138989, 37.65579007722],
            [126.883473251098, 37.655704494782],
            [126.883473870577, 37.655563727164],
            [126.883491384593, 37.655352664402],
            [126.883545590872, 37.655321806189],
            [126.883766003204, 37.655208521641],
            [126.883837725165, 37.655108341781],
            [126.883948699099, 37.655031644356],
            [126.883974478114, 37.654953861888],
            [126.88395721435, 37.654892109912],
            [126.883952273528, 37.654856129076],
            [126.883951309244, 37.654669905691],
            [126.88391891235, 37.654395517636],
            [126.884055906357, 37.654389221162],
            [126.88417720186, 37.654161791727],
            [126.883982857155, 37.654117027045],
            [126.884323942316, 37.653851876179],
            [126.884412240701, 37.653766407307],
            [126.884499966388, 37.653663026424],
            [126.884549074368, 37.65358703286],
            [126.884598170903, 37.653511048273],
            [126.884667682964, 37.653365807939],
            [126.884689671175, 37.653319870995],
            [126.884733468207, 37.65322671753],
            [126.884886528732, 37.652923429651],
            [126.88503955649, 37.652618555808],
            [126.885076497201, 37.652525170191],
            [126.885195766772, 37.652219551029],
            [126.885260978566, 37.651952728511],
            [126.885308556915, 37.651708142659],
            [126.885321831266, 37.651639618565],
            [126.885327941112, 37.651609243705],
            [126.885319842302, 37.651607037349],
            [126.885322294413, 37.651589641937],
            [126.885329158111, 37.651540896938],
            [126.88533400864, 37.651504195992],
            [126.885336214687, 37.651484719084],
            [126.885339615589, 37.651454746994],
            [126.885342235318, 37.651431585505],
            [126.885344740085, 37.651409478044],
            [126.88511140132, 37.651347981731],
            [126.88511156327, 37.651345864599],
            [126.884832150242, 37.651269690427],
            [126.884648660724, 37.651215423264],
            [126.881745205308, 37.65043178028],
            [126.878889587114, 37.649646628906],
            [126.878689570486, 37.649600921579],
            [126.878522439329, 37.649554482374],
            [126.878238531379, 37.649449943204],
            [126.87786703012, 37.649414866867],
            [126.877804508134, 37.649425441839],
            [126.877560994138, 37.649466622084],
            [126.877355803768, 37.649554981191],
            [126.877319138858, 37.649423391197],
            [126.877185351651, 37.649060968102],
            [126.877158156681, 37.648992735605],
            [126.877127334215, 37.648925400251],
            [126.877092883961, 37.648859142233],
            [126.87705491909, 37.648794051765],
            [126.877013552471, 37.648730399254],
            [126.876968784263, 37.6486680946],
            [126.876920840345, 37.648607588526],
            [126.876694103146, 37.648334803779],
            [126.876466803458, 37.648060576428],
            [126.876334324815, 37.647901350687],
            [126.876135827181, 37.647670255908],
            [126.875620367495, 37.647040816998],
            [126.875876409137, 37.646850281324],
            [126.875977961327, 37.64676773392],
            [126.875922733655, 37.646720626189],
            [126.876095360508, 37.64663421706],
            [126.876006555829, 37.646535096378],
            [126.875863754418, 37.646687596687],
            [126.875797458651, 37.6467064015],
            [126.875750452405, 37.646642147872],
            [126.875559297359, 37.64653483595],
            [126.875357227676, 37.646429539241],
            [126.874741968465, 37.645853714797],
            [126.874616625612, 37.645733831345],
            [126.874610928167, 37.645619428314],
            [126.874620098841, 37.645477219139],
            [126.87457957263, 37.645481320031],
            [126.874350944608, 37.645198338578],
            [126.874337049575, 37.645173762996],
            [126.874322558671, 37.645119878024],
            [126.874204032455, 37.644824500364],
            [126.874099300354, 37.644533606309],
            [126.874028919556, 37.644298195338],
            [126.874059181047, 37.644094265073],
            [126.873857060643, 37.643725754541],
            [126.873705638846, 37.643447675495],
            [126.873854268731, 37.643291444968],
            [126.873911229451, 37.643084903733],
            [126.875174580209, 37.642664615486],
            [126.875241798766, 37.642640811576],
            [126.875134887324, 37.642532210542],
            [126.875112437929, 37.64240740127],
            [126.875194447608, 37.642293569654],
            [126.875127407123, 37.641978363344],
            [126.875122956966, 37.641816281927],
            [126.87507394882, 37.641651801212],
            [126.876427302927, 37.64132366664],
            [126.876653693914, 37.641268766685],
            [126.876759983017, 37.641221280533],
            [126.876749135026, 37.641081969077],
            [126.876781424649, 37.64091343951],
            [126.876758808129, 37.640467125834],
            [126.876750611683, 37.640253171245],
            [126.876730885595, 37.64010438111],
            [126.876691671492, 37.639901664913],
            [126.876653184325, 37.63977246016],
            [126.876563944662, 37.639518046966],
            [126.876543831924, 37.639404268331],
            [126.876544713597, 37.639310801929],
            [126.876557563198, 37.639174641898],
            [126.876593921943, 37.639025163288],
            [126.876698707573, 37.638696075245],
            [126.876868241193, 37.638523510163],
            [126.876891506261, 37.638228879226],
            [126.876740537067, 37.63794424518],
            [126.876363233778, 37.637471634464],
            [126.876273513484, 37.637364034669],
            [126.876161009075, 37.637357878646],
            [126.875669556125, 37.637328307977],
            [126.875450098198, 37.637316505151],
            [126.875431993919, 37.637316215512],
            [126.875280479776, 37.637317891496],
            [126.875187105831, 37.63732164774],
            [126.87513058586, 37.637326200213],
            [126.875078255339, 37.637332234752],
            [126.875022458158, 37.637338139414],
            [126.874857297836, 37.637369595537],
            [126.874684812788, 37.637417693613],
            [126.874579398921, 37.637465917672],
            [126.874549104602, 37.637471867564],
            [126.874455252236, 37.63749030864],
            [126.874457114341, 37.637481282854],
            [126.874439037632, 37.637484741158],
            [126.874425455319, 37.637483591297],
            [126.874411110571, 37.637477755533],
            [126.87442266672, 37.637404653689],
            [126.874440585005, 37.637321242508],
            [126.874429189878, 37.637213347233],
            [126.87432074989, 37.636949919805],
            [126.874274746455, 37.63683344593],
            [126.874189526301, 37.636666952514],
            [126.874076841661, 37.636461894574],
            [126.874028845133, 37.636385079461],
            [126.873958240692, 37.636271822386],
            [126.873805811655, 37.636100976357],
            [126.873780320771, 37.636061035469],
            [126.873640589614, 37.635852415978],
            [126.873556019017, 37.635777687327],
            [126.873465617907, 37.635694050631],
            [126.873383463087, 37.635565220784],
            [126.87333558693, 37.635511416472],
            [126.873270950874, 37.635486217975],
            [126.87322048999, 37.635479567963],
            [126.873102228861, 37.635502360178],
            [126.873022818795, 37.635503832416],
            [126.873005124341, 37.635496082759],
            [126.872997796193, 37.63548837142],
            [126.87283049868, 37.635348546587],
            [126.872786622455, 37.635268392823],
            [126.872773069087, 37.635231014501],
            [126.87273759127, 37.635122714336],
            [126.872722028471, 37.635087766453],
            [126.872703101562, 37.635039300234],
            [126.872759035066, 37.634841876032],
            [126.872791000245, 37.634707440018],
            [126.872840697128, 37.634512955158],
            [126.87283235692, 37.634422082476],
            [126.872818965074, 37.634277983538],
            [126.872799250472, 37.634206793813],
            [126.872609316037, 37.634187638901],
            [126.872531062321, 37.634104258003],
            [126.872386079509, 37.634106351866],
            [126.872126437576, 37.634106562964],
            [126.872051052992, 37.634106786605],
            [126.871987215385, 37.633890139543],
            [126.871981596258, 37.633870492062],
            [126.871806906543, 37.633671904875],
            [126.871752223633, 37.633585711284],
            [126.871615180487, 37.633368244669],
            [126.871536359544, 37.633286439188],
            [126.871521331042, 37.633263988286],
            [126.871457876837, 37.633140664606],
            [126.871444540962, 37.633148100992],
            [126.871363493179, 37.633213089241],
            [126.870960152862, 37.633222184995],
            [126.870400053612, 37.633224356957],
            [126.870161120897, 37.633226442758],
            [126.869436718512, 37.633176440707],
            [126.869123843351, 37.633024194228],
            [126.868920077727, 37.633127568847],
            [126.868551660591, 37.633323486305],
            [126.868035530619, 37.633438489978],
            [126.86745730551, 37.633559196121],
            [126.867376353563, 37.633511964968],
            [126.867037438152, 37.633314373193],
            [126.866760911543, 37.633368747048],
            [126.866413812202, 37.633544049775],
            [126.866159486666, 37.633718538942],
            [126.866061402333, 37.633810524311],
            [126.865829702266, 37.634027024283],
            [126.865804595061, 37.634133932432],
            [126.865734940948, 37.634359430037],
            [126.865532409483, 37.63465135676],
            [126.865419431511, 37.634715024712],
            [126.865212885261, 37.634890206594],
            [126.865018187162, 37.635109468619],
            [126.864989735993, 37.6351174544],
            [126.864896610718, 37.635153692035],
            [126.864740882877, 37.635188109147],
            [126.864606708631, 37.635515999381],
            [126.864574394967, 37.63571480757],
            [126.864565708003, 37.635762729479],
            [126.864481584446, 37.636226905375],
            [126.864322226697, 37.636570533074],
            [126.864297987246, 37.636622860668],
            [126.864272049753, 37.636680574125],
            [126.864180086936, 37.636785710255],
            [126.86411480438, 37.636872731705],
            [126.864370783958, 37.637230690392],
            [126.864657987446, 37.637343285618],
            [126.86485273413, 37.637404372349],
            [126.864831178949, 37.637420114478],
            [126.864592936079, 37.637594537681],
            [126.864323733925, 37.637784222991],
            [126.864232680244, 37.63782558004],
            [126.863933129436, 37.637944700661],
            [126.863603214774, 37.638185957497],
            [126.863314827952, 37.638209766233],
            [126.86316066614, 37.63825121069],
            [126.862661679186, 37.63838550074],
            [126.862350406519, 37.638469285239],
            [126.862168884075, 37.638843336756],
            [126.862029094232, 37.639074632503],
            [126.861820442312, 37.639232146666],
            [126.861735554718, 37.639367192633],
            [126.861658302637, 37.639478371704],
            [126.861610485037, 37.63948110804],
            [126.861448562713, 37.639490195983],
            [126.861348281771, 37.639737124687],
            [126.861214975201, 37.640073300806],
            [126.861089352151, 37.64023938153],
            [126.86096211406, 37.6402400409],
            [126.860916184613, 37.640418965164],
            [126.860655911267, 37.640513932979],
            [126.860414752338, 37.640764062217],
            [126.860436608931, 37.640847572833],
            [126.860252775077, 37.640998086227],
            [126.860192729333, 37.641235061209],
            [126.860080687723, 37.641233431329],
            [126.860045821254, 37.641545893177],
            [126.860015205524, 37.641857215856],
            [126.860751424312, 37.642123235831],
            [126.86118801563, 37.642160362585],
            [126.861399081116, 37.642212933128],
            [126.861788822393, 37.642637079411],
            [126.862014875539, 37.64300097188],
            [126.862232399491, 37.643316038942],
            [126.862374676151, 37.643498861702],
            [126.863468550135, 37.643739193663],
            [126.863394267714, 37.644895184994],
            [126.863361298795, 37.645370393116],
            [126.863112583465, 37.645738348413],
            [126.86296378836, 37.645913891055],
            [126.862935014763, 37.645922317422],
            [126.86268161867, 37.645996503162],
            [126.862582732704, 37.646088124096],
            [126.862482007545, 37.646193689881],
            [126.862378211485, 37.646378177421],
            [126.862440878785, 37.646982265333],
            [126.862277699357, 37.647049105438],
            [126.862021165949, 37.647247377393],
            [126.861813555552, 37.647610945619],
            [126.861612357509, 37.647774684721],
            [126.861580043638, 37.647791314441],
            [126.861125436811, 37.648024937837],
            [126.861081992167, 37.648229858018],
            [126.861053415752, 37.648361636686],
            [126.86109879217, 37.648515036696],
            [126.861152334409, 37.648604477158],
            [126.861257832412, 37.648717323643],
            [126.861306248077, 37.648773322747],
            [126.861337431883, 37.648809579008],
            [126.861370330114, 37.648850080891],
            [126.861394147378, 37.64890506867],
            [126.861480179986, 37.649186266073],
            [126.861494775517, 37.64924025181],
            [126.861395439775, 37.649278695606],
            [126.861369579223, 37.649291909223],
            [126.861035445937, 37.649461706024],
            [126.860725774437, 37.649758316282],
            [126.860645452742, 37.649826325104],
            [126.860567396278, 37.64988883162],
            [126.860540681533, 37.649934118745],
            [126.860431718343, 37.650233067146],
            [126.860325056813, 37.650512890488],
            [126.85975005937, 37.650844507509]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281110',
      properties: {
        rgnCode: '41281110',
        colCode: '41280',
        rgnKo: ['오금동'],
        rgnSize: 1,
        rgnBbox: [
          126.891963813241, 37.656585886684, 126.938846991493, 37.678095248358
        ],
        rgnCenter: [126.910896612924, 37.666037772821],
        rgnArea: 4815312,
        predVal: [
          0.06583, 0.06694, 0.06405, 0.07193, 0.09012, 0.08194, 0.0899, 0.09455,
          0.08142, 0.08571, 0.08543, 0.06541, 0.09402, 0.08356, 0.09503,
          0.03794, 0.0881, 0.08083, 0.10427, 0.09106, 0.09327, 0.09489, 0.08234,
          0.09325, 0.07465, 0.09096, 0.08314, 0.08561, 0.0837, 0.09587, 0.09215
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.891963813241, 37.670661769358],
            [126.892245259173, 37.670758821867],
            [126.892383013721, 37.670802790709],
            [126.893106562008, 37.670969435039],
            [126.893498488045, 37.670990066487],
            [126.894057157566, 37.671004541859],
            [126.894984695553, 37.671153142681],
            [126.895851103402, 37.671409347622],
            [126.896554730304, 37.671630227498],
            [126.89674512703, 37.671715286361],
            [126.896932530306, 37.671817550827],
            [126.89722006052, 37.671988972087],
            [126.897519004486, 37.672218578465],
            [126.897544823113, 37.672228412811],
            [126.897954582687, 37.672105259058],
            [126.898022727814, 37.67208928157],
            [126.898112117978, 37.672070844998],
            [126.898151764003, 37.672122433365],
            [126.898204118778, 37.672213297209],
            [126.89827422299, 37.672337782819],
            [126.898322857363, 37.672414317896],
            [126.898384264948, 37.672516451725],
            [126.898470100227, 37.672659150598],
            [126.89848887348, 37.672690160431],
            [126.898672000531, 37.672970522501],
            [126.898770974372, 37.673121350357],
            [126.898794287023, 37.673156868952],
            [126.8988888408, 37.673332289385],
            [126.89900241984, 37.673545675205],
            [126.899161574537, 37.673844323383],
            [126.899276336013, 37.674056241364],
            [126.89928382105, 37.67407000569],
            [126.899349811166, 37.674213173209],
            [126.899557436333, 37.67466245119],
            [126.899760169388, 37.675101759807],
            [126.899868653843, 37.675339214294],
            [126.899991938383, 37.675521893339],
            [126.900188561782, 37.67573960198],
            [126.900196952278, 37.675754628382],
            [126.900561863102, 37.67646661858],
            [126.90091907219, 37.677069772685],
            [126.901075347223, 37.677262245209],
            [126.901387493815, 37.677611817148],
            [126.901689356644, 37.677812908509],
            [126.902102507865, 37.678037301966],
            [126.902333103616, 37.678095248358],
            [126.902729784606, 37.678073750753],
            [126.903056367442, 37.678024335288],
            [126.9037983317, 37.677851461365],
            [126.904210911656, 37.677754488585],
            [126.90427410471, 37.677731484853],
            [126.904342096011, 37.67770598935],
            [126.904489203595, 37.677625257311],
            [126.904715268072, 37.677602792656],
            [126.904887255406, 37.677523314867],
            [126.905316617102, 37.67724449993],
            [126.905534475106, 37.677035706154],
            [126.905573274268, 37.676949911199],
            [126.905604531488, 37.676734856754],
            [126.905598553824, 37.676473633274],
            [126.9055468282, 37.676404234421],
            [126.905816999798, 37.676051804737],
            [126.905833772386, 37.676028438136],
            [126.905851517734, 37.676024353111],
            [126.90604920189, 37.675906314846],
            [126.906150620274, 37.675764944376],
            [126.906231357062, 37.675654325284],
            [126.906265697355, 37.675605961843],
            [126.906289978849, 37.675481061752],
            [126.906302486291, 37.675450970391],
            [126.906598146257, 37.675452812431],
            [126.906781528848, 37.67545452756],
            [126.906855651802, 37.675436288343],
            [126.90696401246, 37.675262469736],
            [126.907037000936, 37.675146447051],
            [126.907299083455, 37.674763455461],
            [126.907559375805, 37.674449602404],
            [126.907616241873, 37.674380083667],
            [126.906921386636, 37.673900022262],
            [126.906907675949, 37.674074709686],
            [126.906866068534, 37.674169548626],
            [126.906830372515, 37.67425087772],
            [126.906841587359, 37.674434865142],
            [126.906757056257, 37.674767706282],
            [126.906700176737, 37.674802393026],
            [126.90663812572, 37.674625410318],
            [126.906564626658, 37.674403712142],
            [126.906364406931, 37.674167676395],
            [126.906334753155, 37.674132784863],
            [126.906426079214, 37.673450372411],
            [126.906449883288, 37.673272449661],
            [126.906450721344, 37.673246376212],
            [126.906551872651, 37.672928666895],
            [126.906916574479, 37.672788678643],
            [126.908509337344, 37.67179162333],
            [126.907896048576, 37.670765831802],
            [126.90785962418, 37.670698383132],
            [126.907712587701, 37.670340174852],
            [126.907828976971, 37.6701829666],
            [126.908264837865, 37.669993819494],
            [126.908523524013, 37.669778763406],
            [126.909145269813, 37.669569379441],
            [126.909320543363, 37.669581679702],
            [126.909732505834, 37.669473704105],
            [126.90986062979, 37.669198511595],
            [126.909871929189, 37.669171743472],
            [126.910165367624, 37.669033104206],
            [126.911256188343, 37.668646823104],
            [126.911790538411, 37.668702649622],
            [126.911929737875, 37.668918268069],
            [126.912058173823, 37.669112137715],
            [126.912201805965, 37.66906347692],
            [126.912307320501, 37.669061844923],
            [126.912592938974, 37.669070367565],
            [126.913035213577, 37.669029201374],
            [126.913098741709, 37.669049610992],
            [126.913219698515, 37.66908222671],
            [126.913306456437, 37.669107690041],
            [126.913334698448, 37.669091493653],
            [126.913818628045, 37.668818211066],
            [126.913865270755, 37.668903657937],
            [126.913933297093, 37.669031601525],
            [126.913914870763, 37.669096115495],
            [126.913939616559, 37.669161391312],
            [126.914055511568, 37.669441571641],
            [126.914409531293, 37.669531345235],
            [126.914665191761, 37.669736838361],
            [126.915082627076, 37.66984922597],
            [126.915260815855, 37.669841427855],
            [126.915244584106, 37.669822378436],
            [126.915260849817, 37.669841463919],
            [126.915419064291, 37.669914513232],
            [126.915492461134, 37.67004738814],
            [126.916035299326, 37.670155593157],
            [126.916424834888, 37.669968634735],
            [126.916796597861, 37.66991952005],
            [126.917144872648, 37.669822482688],
            [126.917357141901, 37.66955579332],
            [126.917715713173, 37.669321345282],
            [126.918284831465, 37.669340551552],
            [126.918529853095, 37.6692967207],
            [126.918643750165, 37.669279375971],
            [126.918783175086, 37.66929031255],
            [126.918940025467, 37.669328074107],
            [126.919025532673, 37.669348865318],
            [126.919269612523, 37.669418563819],
            [126.919345145748, 37.66949056813],
            [126.91939978804, 37.669543367135],
            [126.919549812287, 37.66951224378],
            [126.919715311993, 37.669477527077],
            [126.92016695037, 37.669527113623],
            [126.920222917984, 37.670007253416],
            [126.920396615465, 37.670230967332],
            [126.920277051127, 37.670465651416],
            [126.920376926461, 37.671002005926],
            [126.920182494986, 37.671059651364],
            [126.920121108874, 37.671430476225],
            [126.92037907038, 37.671583485879],
            [126.921164083971, 37.671629612482],
            [126.921420887181, 37.671565782087],
            [126.921813412834, 37.671619007137],
            [126.921959671485, 37.671455101908],
            [126.923135060136, 37.671562581999],
            [126.923569101057, 37.671512730537],
            [126.924346135396, 37.671351625094],
            [126.925110056976, 37.671141466111],
            [126.925492125206, 37.670968374934],
            [126.925785791493, 37.670862771683],
            [126.926238682025, 37.670728645537],
            [126.926786449451, 37.670802251931],
            [126.927424668733, 37.670636956743],
            [126.928342146386, 37.671247911248],
            [126.928635116742, 37.671491616442],
            [126.928700809514, 37.67161167553],
            [126.928715379668, 37.671653111228],
            [126.929139456932, 37.671868722605],
            [126.92923333965, 37.671919838273],
            [126.929488791389, 37.671747070953],
            [126.929523948783, 37.671727126874],
            [126.930109194961, 37.6718059303],
            [126.930426985993, 37.672084702875],
            [126.930678681835, 37.671997342884],
            [126.931581334704, 37.671710993987],
            [126.932110617162, 37.671442708783],
            [126.932750613195, 37.671433317116],
            [126.932868705077, 37.671365713879],
            [126.932970124444, 37.671272152831],
            [126.933214659155, 37.671250067706],
            [126.93383607387, 37.671404888621],
            [126.934113695411, 37.671307283096],
            [126.934570235111, 37.671198065965],
            [126.934857192353, 37.670989130829],
            [126.936309068772, 37.670955894703],
            [126.936576752056, 37.671110144705],
            [126.936938505955, 37.670987468717],
            [126.936992503983, 37.670975290094],
            [126.937260905223, 37.670666682387],
            [126.93775242307, 37.670548354134],
            [126.938188937276, 37.670517370619],
            [126.938846991493, 37.670117843484],
            [126.938268576618, 37.669673877825],
            [126.938036007329, 37.669575276578],
            [126.937377482925, 37.669296844124],
            [126.937224862817, 37.669232269409],
            [126.936864750663, 37.669079898284],
            [126.936771165977, 37.668657714207],
            [126.936621478116, 37.668065413523],
            [126.936420470851, 37.66768118199],
            [126.936365791084, 37.667581215886],
            [126.93555666812, 37.666523996954],
            [126.935158643759, 37.666058889604],
            [126.935074401861, 37.666003729654],
            [126.934793196435, 37.665899526536],
            [126.934380031633, 37.665781291637],
            [126.933925434501, 37.665934386652],
            [126.933775885417, 37.666080745033],
            [126.933253137175, 37.666279468614],
            [126.933131049151, 37.666523976043],
            [126.932983229713, 37.666610581874],
            [126.932771737108, 37.666608764971],
            [126.9320037537, 37.667090839968],
            [126.931678512522, 37.667074250135],
            [126.931458458527, 37.667015132938],
            [126.931315252058, 37.666942249176],
            [126.931101446182, 37.666712706977],
            [126.930971958832, 37.666551850968],
            [126.930859821, 37.666394681178],
            [126.930799734132, 37.66631631457],
            [126.930649435174, 37.666195079524],
            [126.930284822547, 37.665918864804],
            [126.929537120595, 37.665858541231],
            [126.928874340674, 37.665651812063],
            [126.928177083636, 37.665701555786],
            [126.92813307033, 37.665705150323],
            [126.92800941566, 37.665648861613],
            [126.928092857651, 37.665244700972],
            [126.928245506357, 37.664664271789],
            [126.928012802697, 37.664271491837],
            [126.927885328482, 37.663953882279],
            [126.927848709917, 37.663792720316],
            [126.927746292751, 37.663329366736],
            [126.927701496468, 37.662763049802],
            [126.927787067379, 37.662370531089],
            [126.927538968921, 37.66156889656],
            [126.927492770034, 37.661310639475],
            [126.92738176837, 37.661165900396],
            [126.927336830217, 37.660757613852],
            [126.925449877441, 37.660098602037],
            [126.924619836095, 37.660663334917],
            [126.924367903852, 37.660834058133],
            [126.924365692446, 37.661032559248],
            [126.924327440297, 37.661144363096],
            [126.92419779052, 37.66158968359],
            [126.923842462052, 37.661780123219],
            [126.923423380611, 37.661873980247],
            [126.923135814001, 37.662040244406],
            [126.922792510638, 37.662118445546],
            [126.921994789457, 37.662274157306],
            [126.921125370205, 37.662712936765],
            [126.920215982934, 37.662565752113],
            [126.92008442483, 37.662544119086],
            [126.919997515611, 37.662527138772],
            [126.919879272664, 37.66253820204],
            [126.919684175136, 37.662561609293],
            [126.919593342591, 37.662536147798],
            [126.919527593235, 37.662519037676],
            [126.919357337419, 37.66247156404],
            [126.91898228946, 37.662380177747],
            [126.918851584884, 37.662366211228],
            [126.918425282584, 37.66214774094],
            [126.918320337252, 37.662115367064],
            [126.918033660467, 37.662052925261],
            [126.917533523437, 37.661946895036],
            [126.91685785946, 37.661798391082],
            [126.916306619543, 37.661656370114],
            [126.916318392999, 37.661072935048],
            [126.916172647112, 37.660677130996],
            [126.915837018699, 37.660643921058],
            [126.91554876434, 37.660373932726],
            [126.915331820538, 37.660181894007],
            [126.915751579, 37.659854740963],
            [126.915845650608, 37.659503357382],
            [126.915994849969, 37.658949925193],
            [126.915897920549, 37.658524568051],
            [126.91585934661, 37.658354138797],
            [126.915877494468, 37.658330942878],
            [126.91571155678, 37.658244283719],
            [126.915154488752, 37.657953349042],
            [126.914570579459, 37.658088606832],
            [126.913873576553, 37.658253861285],
            [126.913597294144, 37.6578689487],
            [126.913320684435, 37.657868525982],
            [126.913153898599, 37.657812405614],
            [126.912907018798, 37.657722906845],
            [126.912625225079, 37.657746102147],
            [126.912316443652, 37.657763374991],
            [126.912263106263, 37.657749000146],
            [126.912020401428, 37.657600155503],
            [126.911921873297, 37.65746928627],
            [126.911652256356, 37.657140603959],
            [126.911388083774, 37.656819367222],
            [126.911261361963, 37.656819846958],
            [126.911201868202, 37.6568178373],
            [126.910576260305, 37.656801373383],
            [126.910537809918, 37.656809722797],
            [126.91024393289, 37.656920703052],
            [126.909794373318, 37.657072745164],
            [126.909710671882, 37.657080734764],
            [126.909204884208, 37.656971096906],
            [126.909166963262, 37.65697267997],
            [126.908750968829, 37.657042485416],
            [126.908545725102, 37.657131961775],
            [126.908269776938, 37.657248158864],
            [126.908050116112, 37.657265932434],
            [126.907638482551, 37.657317366541],
            [126.907365613347, 37.657084688683],
            [126.907026259955, 37.656957307885],
            [126.907010961178, 37.656849800377],
            [126.906689070542, 37.656777806479],
            [126.906489397223, 37.656665510526],
            [126.906297694528, 37.656683798889],
            [126.905947350064, 37.65671723004],
            [126.905279495346, 37.656885890075],
            [126.905075267525, 37.656756559601],
            [126.904692136863, 37.656720242817],
            [126.904243363477, 37.656835243383],
            [126.903953623419, 37.657042498501],
            [126.903880843205, 37.657087288788],
            [126.903802610198, 37.657020957349],
            [126.903601505423, 37.656922809636],
            [126.902814377097, 37.656604758415],
            [126.90222785444, 37.656591661578],
            [126.902078521492, 37.656585886684],
            [126.901748265071, 37.656714870628],
            [126.90155653999, 37.656782713867],
            [126.900905467376, 37.656902728757],
            [126.900270380338, 37.657099832123],
            [126.900207572075, 37.657343707975],
            [126.900196233659, 37.65741464095],
            [126.900025043467, 37.657513411751],
            [126.899966976371, 37.657545427451],
            [126.899904352138, 37.657578421259],
            [126.899895299318, 37.65756861987],
            [126.89963568434, 37.657721561553],
            [126.899491265329, 37.65791631745],
            [126.899466208706, 37.657924494642],
            [126.899410653124, 37.657942736343],
            [126.899292289051, 37.658012171016],
            [126.899161117217, 37.658024896155],
            [126.899026355621, 37.658035599847],
            [126.898788538718, 37.658075459305],
            [126.898638064119, 37.658147712431],
            [126.898581326855, 37.658233579827],
            [126.898370921377, 37.658299329324],
            [126.898262875369, 37.658355734886],
            [126.898284361185, 37.658389378044],
            [126.898238688563, 37.658423007467],
            [126.898034474542, 37.658501807886],
            [126.89787957472, 37.658560496497],
            [126.897799862465, 37.658590807283],
            [126.897739629993, 37.658613963401],
            [126.897534704728, 37.658649425475],
            [126.897311009783, 37.658686474348],
            [126.897213080391, 37.658704019861],
            [126.897131797086, 37.658755294471],
            [126.897029744514, 37.658819839997],
            [126.896951490166, 37.658868747603],
            [126.896794810953, 37.658966715673],
            [126.896708876597, 37.659020814919],
            [126.896618195164, 37.659106029521],
            [126.896449441686, 37.659262928105],
            [126.895900858048, 37.659526558718],
            [126.895577498345, 37.659498888163],
            [126.895326195009, 37.659478579498],
            [126.895167991985, 37.659450668961],
            [126.895032862243, 37.659399533916],
            [126.894839801699, 37.659227309505],
            [126.894601082013, 37.659062701679],
            [126.894367527575, 37.658869068675],
            [126.893937098253, 37.658864730654],
            [126.893778349868, 37.658917463935],
            [126.893792425394, 37.659082156809],
            [126.893801968647, 37.659285479299],
            [126.893813727475, 37.659469243662],
            [126.893819877118, 37.659566284163],
            [126.893832820214, 37.659738363966],
            [126.893820151506, 37.659847063903],
            [126.893808401802, 37.659962873359],
            [126.893802735493, 37.660017620363],
            [126.89379877676, 37.660137689525],
            [126.89379918108, 37.66033632758],
            [126.893893280504, 37.660901332994],
            [126.893897531578, 37.661026175781],
            [126.893904192699, 37.661232270566],
            [126.893919733611, 37.661677789147],
            [126.893874037937, 37.66196009454],
            [126.893778612541, 37.662068682844],
            [126.893406289129, 37.662466139994],
            [126.893885200409, 37.66273000455],
            [126.895382416594, 37.663547507871],
            [126.895378171478, 37.663559919463],
            [126.895442382831, 37.663595295374],
            [126.89548870121, 37.663620744463],
            [126.895550989793, 37.663653415667],
            [126.8956136242, 37.66368203277],
            [126.895682380611, 37.663709393969],
            [126.895744345963, 37.663730352128],
            [126.895711592919, 37.663809338107],
            [126.895605486878, 37.664064758887],
            [126.895581384287, 37.664117624369],
            [126.895553316528, 37.664169315019],
            [126.895521397443, 37.664219470547],
            [126.895485853703, 37.664268091153],
            [126.895446685557, 37.66431499664],
            [126.895404120063, 37.664359916916],
            [126.895358157344, 37.664402761881],
            [126.895309251006, 37.664443351745],
            [126.895257287968, 37.664481506207],
            [126.894946434777, 37.664696828634],
            [126.894838656751, 37.664771511977],
            [126.894865122565, 37.664794330528],
            [126.89477120611, 37.664860737387],
            [126.894750780079, 37.664878287876],
            [126.894684995128, 37.664912285095],
            [126.894537337597, 37.664975669871],
            [126.894172775064, 37.665100213533],
            [126.894126035465, 37.665130804072],
            [126.894067476274, 37.665183818054],
            [126.89402998202, 37.665247933171],
            [126.893981798105, 37.665383954194],
            [126.893907075288, 37.665594785799],
            [126.893899799852, 37.66560982541],
            [126.89382204238, 37.665771298887],
            [126.893851356064, 37.665799886489],
            [126.893866182599, 37.665814405685],
            [126.893831340614, 37.665846718809],
            [126.893738163701, 37.665933127106],
            [126.893662633572, 37.666043066856],
            [126.893563970745, 37.666160643657],
            [126.893378402014, 37.666338055424],
            [126.8931007539, 37.66662485049],
            [126.893111186366, 37.666621346285],
            [126.893138174713, 37.66661227126],
            [126.893166999477, 37.666665365037],
            [126.893001667533, 37.666720803072],
            [126.892988172873, 37.66672565591],
            [126.892894980097, 37.666821523725],
            [126.892916708774, 37.666844068051],
            [126.893001360067, 37.666931990801],
            [126.892967089796, 37.666960249811],
            [126.892960621336, 37.666965739792],
            [126.892835469932, 37.666903367331],
            [126.892755166563, 37.667020554913],
            [126.892674862941, 37.667137742438],
            [126.89247349183, 37.667383476942],
            [126.89227211939, 37.667629211091],
            [126.89222579182, 37.667694110281],
            [126.892169219601, 37.667773361458],
            [126.89213980332, 37.667814598729],
            [126.892117542408, 37.667845751736],
            [126.892042153906, 37.668010649867],
            [126.891970548023, 37.668223663392],
            [126.891991267869, 37.668315762024],
            [126.892013526095, 37.668363714427],
            [126.8920320985, 37.668413150009],
            [126.89205067093, 37.668462585588],
            [126.892065791695, 37.66850841407],
            [126.892080912478, 37.66855424255],
            [126.89209511932, 37.66860498048],
            [126.892097366224, 37.668618587249],
            [126.892132757561, 37.668676732798],
            [126.892220112293, 37.668776911813],
            [126.892261483901, 37.668853577847],
            [126.892302855594, 37.668930243865],
            [126.892310551523, 37.668938089451],
            [126.892343918947, 37.668985331294],
            [126.892374162921, 37.669153120342],
            [126.892404734006, 37.669329829307],
            [126.892392923733, 37.669422528436],
            [126.892400915963, 37.669460286571],
            [126.892399093635, 37.669543714922],
            [126.892364940289, 37.66964594368],
            [126.892353609237, 37.669682242377],
            [126.892342278174, 37.669718541073],
            [126.892313021109, 37.669805367718],
            [126.892279978361, 37.669845700679],
            [126.892207868477, 37.670015196854],
            [126.89202965485, 37.670500925876],
            [126.891963813241, 37.670661769358]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41285113',
      properties: {
        rgnCode: '41285113',
        colCode: '41280',
        rgnKo: ['성석동'],
        rgnSize: 1,
        rgnBbox: [
          126.773436342083, 37.683178249606, 126.819464401934, 37.720758982495
        ],
        rgnCenter: [126.795664096906, 37.702567418836],
        rgnArea: 9428452,
        predVal: [
          0.39496, 0.40163, 0.3843, 0.43159, 0.54071, 0.49164, 0.53939, 0.60558,
          0.55523, 0.59295, 0.56673, 0.39245, 0.20188, 0.34901, 0.38552,
          0.22766, 0.26184, 0.44661, 0.62564, 0.45053, 0.47376, 0.56312,
          0.49407, 0.55951, 0.44788, 0.54579, 0.49886, 0.51365, 0.50219,
          0.52331, 0.37852
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.785250858248, 37.720758982495],
            [126.785906883237, 37.720079326104],
            [126.786758844195, 37.719198747799],
            [126.786804236665, 37.719229688471],
            [126.786862312496, 37.719267499541],
            [126.786871256797, 37.719273029722],
            [126.787029310913, 37.719370693492],
            [126.787539536562, 37.719685921692],
            [126.788240724119, 37.718968493641],
            [126.788408582827, 37.719068759069],
            [126.788610465595, 37.719189762816],
            [126.788614894426, 37.719148236397],
            [126.788819067389, 37.718944446578],
            [126.789007530453, 37.718760539377],
            [126.788968381652, 37.718489233721],
            [126.789019710405, 37.718487028786],
            [126.789051642027, 37.718485644792],
            [126.78904869679, 37.718416841903],
            [126.789036167924, 37.718105680542],
            [126.788983022085, 37.717818979195],
            [126.78896145188, 37.717699166194],
            [126.78895988164, 37.717605661347],
            [126.788958763217, 37.717492957776],
            [126.78896042293, 37.717436046887],
            [126.788963933446, 37.717426043509],
            [126.78897712652, 37.717373541105],
            [126.788978070708, 37.717364614256],
            [126.788974751781, 37.717323434228],
            [126.789060626925, 37.717206022238],
            [126.789185884541, 37.71715074851],
            [126.789201101724, 37.717144018685],
            [126.789383933772, 37.717063531327],
            [126.789525140647, 37.716983239137],
            [126.789624504629, 37.716860165898],
            [126.78970789984, 37.716689042512],
            [126.789801356448, 37.716648847183],
            [126.789995360949, 37.716667124573],
            [126.790047773939, 37.716662083097],
            [126.790226137527, 37.7165168972],
            [126.790498396924, 37.716296108047],
            [126.790709965803, 37.716159359796],
            [126.790966165643, 37.716121914036],
            [126.791238992546, 37.716060207384],
            [126.79159479347, 37.715974619149],
            [126.79196375635, 37.715886710726],
            [126.792288478101, 37.715801695826],
            [126.792654467396, 37.715681615542],
            [126.792785106973, 37.715565712619],
            [126.79302521124, 37.71548333884],
            [126.793412300168, 37.715320137319],
            [126.793729240027, 37.715217986384],
            [126.793751404165, 37.715261632108],
            [126.793754115295, 37.715261447683],
            [126.793818048375, 37.715378874867],
            [126.793847722252, 37.715515423204],
            [126.793821447223, 37.71566457658],
            [126.793831067872, 37.715712254528],
            [126.793907859962, 37.715790052875],
            [126.79395917381, 37.715894114474],
            [126.793963858402, 37.715963046472],
            [126.793932485472, 37.716069485191],
            [126.7939025081, 37.716122859576],
            [126.793814523429, 37.716235325117],
            [126.793774672434, 37.716290934484],
            [126.793669817478, 37.71643355248],
            [126.793652820312, 37.716468660111],
            [126.793668234902, 37.716553467996],
            [126.793671306109, 37.716590863415],
            [126.793709361802, 37.716642105248],
            [126.793727291196, 37.71664682186],
            [126.79387537613, 37.716741593866],
            [126.793989906852, 37.7168302972],
            [126.794118433803, 37.716922980274],
            [126.794193212984, 37.716966646368],
            [126.794322037537, 37.7170381661],
            [126.794375586677, 37.717081290227],
            [126.794419952487, 37.717115028175],
            [126.794504255102, 37.717180523134],
            [126.794555849248, 37.717221157086],
            [126.794683420506, 37.71739292447],
            [126.794787604, 37.717593796885],
            [126.794809609935, 37.7176132152],
            [126.794980599657, 37.71775211012],
            [126.795183973398, 37.717849283777],
            [126.795404081082, 37.718067792098],
            [126.795491664496, 37.718141103433],
            [126.795506614437, 37.718148832797],
            [126.795639949348, 37.718206502137],
            [126.795732210572, 37.718167462272],
            [126.795784160671, 37.718145488332],
            [126.795860728378, 37.718183011909],
            [126.796072853238, 37.718336185179],
            [126.796184863522, 37.718394762753],
            [126.796458721135, 37.718495516636],
            [126.796636058495, 37.718563541366],
            [126.796667759895, 37.718575705402],
            [126.796750899833, 37.718607536719],
            [126.796887336465, 37.718659786231],
            [126.797006547613, 37.718492323728],
            [126.797102135176, 37.718377706716],
            [126.797102328434, 37.718377544877],
            [126.797160000123, 37.718328794599],
            [126.797211953602, 37.71828482751],
            [126.7973299567, 37.71818511505],
            [126.797412945466, 37.718114893473],
            [126.797386051224, 37.717830592832],
            [126.797361362743, 37.717569180473],
            [126.797360480617, 37.717559989114],
            [126.797379122972, 37.717503440862],
            [126.79744031007, 37.717318128418],
            [126.79747839411, 37.717202961046],
            [126.79749417763, 37.717154876886],
            [126.797514648782, 37.717093106182],
            [126.797569316601, 37.716927423391],
            [126.797670188339, 37.716621720559],
            [126.797680023606, 37.71659200575],
            [126.797685056134, 37.716576607966],
            [126.798036420379, 37.716369722862],
            [126.798062320873, 37.716354541267],
            [126.798382102785, 37.71616616048],
            [126.798518080438, 37.716086118774],
            [126.798776748559, 37.716057012224],
            [126.799280356275, 37.716000485705],
            [126.799760025256, 37.715946618965],
            [126.7997807882, 37.715860071837],
            [126.799909153416, 37.715323857313],
            [126.799926473792, 37.715251989981],
            [126.800080241005, 37.714967367881],
            [126.800450840182, 37.714478236234],
            [126.80059615068, 37.714468032757],
            [126.80125974741, 37.714420960117],
            [126.801505561536, 37.714403538268],
            [126.801866965922, 37.714377932724],
            [126.802000790325, 37.714167784057],
            [126.802019322263, 37.71412963254],
            [126.802029380887, 37.714108900348],
            [126.802348275817, 37.713452194853],
            [126.802670101738, 37.712957027313],
            [126.802757122772, 37.712944110052],
            [126.803104756326, 37.712891178255],
            [126.803372855865, 37.71285036492],
            [126.803395836489, 37.712836267401],
            [126.804424060108, 37.713237379279],
            [126.804458413969, 37.713223994308],
            [126.804529358217, 37.713196336116],
            [126.804533615552, 37.713194676444],
            [126.804789671409, 37.713119422919],
            [126.804856066007, 37.713092378605],
            [126.804878454864, 37.713083262145],
            [126.805086365033, 37.712987205482],
            [126.805506015597, 37.712795647005],
            [126.805530223957, 37.712784596371],
            [126.805588404796, 37.712784242583],
            [126.805636684767, 37.712783917367],
            [126.805807337531, 37.712782759206],
            [126.805844491682, 37.712782505533],
            [126.805920251639, 37.71278200056],
            [126.806248809086, 37.712779932349],
            [126.80640032899, 37.712778921835],
            [126.80647448472, 37.712827966912],
            [126.806784806593, 37.713033629503],
            [126.806847641284, 37.713075177638],
            [126.807013574212, 37.71315743927],
            [126.807261737823, 37.713280559777],
            [126.80730026219, 37.713294227807],
            [126.807500476228, 37.713365102964],
            [126.807940658422, 37.713440696628],
            [126.808146749481, 37.713476172424],
            [126.808205996967, 37.713458340381],
            [126.808257980229, 37.713442748812],
            [126.808250123874, 37.713236324928],
            [126.808228421541, 37.71316466266],
            [126.808314933253, 37.713172913132],
            [126.808646245469, 37.713202556713],
            [126.809100813009, 37.713241320124],
            [126.809142710466, 37.713244496818],
            [126.809279491373, 37.712254334378],
            [126.809424140727, 37.711311890524],
            [126.809533159948, 37.710683554866],
            [126.809673583239, 37.70975029341],
            [126.809703054768, 37.709579788634],
            [126.810045988102, 37.708039785286],
            [126.810162945927, 37.707936634404],
            [126.810417703202, 37.707783703225],
            [126.810453548045, 37.707780067354],
            [126.810503686292, 37.707774652696],
            [126.810455187123, 37.707671683852],
            [126.810558323268, 37.707651218814],
            [126.810801033514, 37.707419342699],
            [126.810919662203, 37.707327185536],
            [126.810996557786, 37.707230843416],
            [126.811023133291, 37.707153466374],
            [126.811075965625, 37.707083303422],
            [126.811134447071, 37.707016879564],
            [126.811208467215, 37.70680747948],
            [126.811346888611, 37.706713146453],
            [126.811845489729, 37.706576876607],
            [126.811937506366, 37.706549175734],
            [126.812324783886, 37.706589485295],
            [126.812659151527, 37.70652136772],
            [126.812803714117, 37.706486281312],
            [126.813254914758, 37.706226803637],
            [126.813482873661, 37.706083193033],
            [126.813505476313, 37.706068993814],
            [126.813557233787, 37.706050516432],
            [126.813639815137, 37.706085335226],
            [126.813674768609, 37.706075038816],
            [126.813875637354, 37.706015885879],
            [126.813883811038, 37.706012385119],
            [126.814000172388, 37.705962566617],
            [126.814064775209, 37.705931676109],
            [126.814106434385, 37.705915344749],
            [126.814145028563, 37.705900269856],
            [126.814182033536, 37.705885823102],
            [126.814312572674, 37.705834765462],
            [126.81447799537, 37.70580106156],
            [126.814922741972, 37.705714642782],
            [126.815040290511, 37.705733658926],
            [126.815440506095, 37.705587343622],
            [126.815853648552, 37.705440686923],
            [126.816081452805, 37.705356174395],
            [126.81619914868, 37.705315906053],
            [126.816325840404, 37.705260605589],
            [126.816390425744, 37.705236110633],
            [126.816654101358, 37.705136156691],
            [126.816809159396, 37.705073962735],
            [126.816842190905, 37.705060950464],
            [126.816829067138, 37.704957228715],
            [126.816889633769, 37.704769742423],
            [126.816875254603, 37.704774386913],
            [126.816898875094, 37.704738015857],
            [126.817258929834, 37.704581477572],
            [126.817615342512, 37.704420535788],
            [126.817876444942, 37.70421263933],
            [126.817910016812, 37.704164219628],
            [126.817942811486, 37.704155035784],
            [126.817947134592, 37.704149366427],
            [126.817977548018, 37.704140133818],
            [126.818132111111, 37.704093433957],
            [126.81820773256, 37.704053458542],
            [126.818275609196, 37.704027165731],
            [126.818321983619, 37.70402931001],
            [126.818435938611, 37.704079400524],
            [126.818479823645, 37.704079378557],
            [126.818510682578, 37.704119519451],
            [126.818514997885, 37.704117003443],
            [126.818561674348, 37.704088560292],
            [126.818913923065, 37.703884830225],
            [126.819219026386, 37.703710604943],
            [126.819267206578, 37.703683767555],
            [126.819374483226, 37.703636272193],
            [126.819329689986, 37.703567324119],
            [126.819329865525, 37.703486336527],
            [126.819328327577, 37.703475333352],
            [126.819307013822, 37.703403853788],
            [126.819275842155, 37.703394129238],
            [126.819349501113, 37.703349275824],
            [126.819356475915, 37.703233061938],
            [126.81938373892, 37.703143998476],
            [126.819464401934, 37.703012491848],
            [126.819387543965, 37.70302557232],
            [126.819352265729, 37.703030473141],
            [126.819350223722, 37.703030830373],
            [126.819350448174, 37.702939112339],
            [126.819352619093, 37.7028860758],
            [126.819309495517, 37.702899586737],
            [126.819221312541, 37.702837283759],
            [126.819153133832, 37.702802310938],
            [126.819051205743, 37.702749987302],
            [126.818843554253, 37.702621007791],
            [126.8187597004, 37.702597002247],
            [126.818731722759, 37.702584345352],
            [126.818650205055, 37.702532593584],
            [126.818590186206, 37.702453575829],
            [126.818574474789, 37.702433009419],
            [126.818487906353, 37.702498555538],
            [126.818363261825, 37.702553951634],
            [126.818358829439, 37.702557999099],
            [126.818281771674, 37.702629326023],
            [126.818172292679, 37.702742587526],
            [126.818146033976, 37.702768674721],
            [126.818029374487, 37.702758582619],
            [126.817921161327, 37.702767568002],
            [126.817757622314, 37.702781134087],
            [126.817658958912, 37.702784764351],
            [126.817645279051, 37.702758966371],
            [126.817628940884, 37.702730542432],
            [126.817530677678, 37.702567692634],
            [126.817505997149, 37.702510343646],
            [126.817464038942, 37.70246065293],
            [126.817479508783, 37.701936674954],
            [126.817474111269, 37.701528438573],
            [126.817739724156, 37.701234957512],
            [126.817646111779, 37.701163932533],
            [126.817488252204, 37.701037343722],
            [126.817204288682, 37.70096574187],
            [126.817029308499, 37.700939592478],
            [126.81658884616, 37.700728887206],
            [126.816284518971, 37.700584525166],
            [126.815971748289, 37.70050718098],
            [126.815688554873, 37.700437360602],
            [126.815643333782, 37.700428369759],
            [126.815459841964, 37.700392221909],
            [126.815266606298, 37.700352995096],
            [126.815175561516, 37.700349337489],
            [126.81507944277, 37.700334229512],
            [126.815051712464, 37.700268685382],
            [126.814990937048, 37.700131822506],
            [126.814919787055, 37.700062605787],
            [126.814775526324, 37.699919439037],
            [126.814725746671, 37.699870032261],
            [126.814552636153, 37.699696772288],
            [126.814525896836, 37.699661321892],
            [126.814508334312, 37.699638049097],
            [126.814333500007, 37.699406673697],
            [126.814325815291, 37.699396390476],
            [126.814278377213, 37.699232249088],
            [126.81419069559, 37.698929024553],
            [126.814084356075, 37.698560630368],
            [126.814111672978, 37.698406923759],
            [126.814127411747, 37.69838426242],
            [126.814402817845, 37.697993707057],
            [126.814301125631, 37.697481760272],
            [126.814293918149, 37.697466648619],
            [126.814282062238, 37.697428807193],
            [126.813962987748, 37.696911712563],
            [126.813537303691, 37.696698811918],
            [126.813413524388, 37.696593651849],
            [126.813205901267, 37.696486510808],
            [126.812949427979, 37.696416771537],
            [126.812628460968, 37.696181736058],
            [126.812024683346, 37.695837895253],
            [126.811430919944, 37.695499761601],
            [126.811333746805, 37.695425221053],
            [126.811272604874, 37.695378326181],
            [126.811210783758, 37.695255604757],
            [126.810805434526, 37.694901410266],
            [126.810720617338, 37.694682518146],
            [126.810806229232, 37.694458315698],
            [126.810914149646, 37.694289108263],
            [126.811471842438, 37.69368203533],
            [126.811592914236, 37.693601053186],
            [126.811695700143, 37.693532294645],
            [126.811856886564, 37.693370920367],
            [126.812140054121, 37.693126852771],
            [126.812335882681, 37.692806873007],
            [126.812700324242, 37.692211376694],
            [126.812885969532, 37.692015533051],
            [126.812951021508, 37.691936982577],
            [126.812973062931, 37.691878581032],
            [126.813001816102, 37.691802369063],
            [126.812964215188, 37.691607069171],
            [126.812895160542, 37.691475507499],
            [126.812691825488, 37.691091639973],
            [126.812566859505, 37.690805904983],
            [126.812202751012, 37.690559086432],
            [126.812158565166, 37.690279237822],
            [126.812080585659, 37.69029663642],
            [126.812069218597, 37.690219044792],
            [126.811990069579, 37.69004557132],
            [126.812146869879, 37.689465689686],
            [126.812151448411, 37.689448578639],
            [126.812454857176, 37.688995788214],
            [126.812479421345, 37.688921497692],
            [126.812491418489, 37.688884937601],
            [126.812506382099, 37.688840994322],
            [126.81255960307, 37.688732602998],
            [126.812495447717, 37.688659837078],
            [126.812182574476, 37.688296208831],
            [126.812036172386, 37.68812337515],
            [126.811929623131, 37.687998969351],
            [126.811736588098, 37.687787561925],
            [126.81145617814, 37.687522703081],
            [126.811320581251, 37.687426279041],
            [126.81115675679, 37.687160589339],
            [126.811115394512, 37.687108987157],
            [126.810958995637, 37.686888267498],
            [126.810831398133, 37.686812073552],
            [126.810492074263, 37.686614221266],
            [126.810196349093, 37.686467091203],
            [126.810060461418, 37.686405784995],
            [126.810005657284, 37.686379567917],
            [126.809757263221, 37.686290869352],
            [126.809064806468, 37.685715824229],
            [126.808824886042, 37.685639660989],
            [126.808541500732, 37.685537027758],
            [126.808332134746, 37.685482626858],
            [126.808046749133, 37.685452246871],
            [126.808045556575, 37.68551855623],
            [126.807799863866, 37.685570138831],
            [126.80767643982, 37.685573909309],
            [126.807390564166, 37.685627127837],
            [126.807143783607, 37.685686897086],
            [126.806979187287, 37.685719565121],
            [126.806731165797, 37.685880789564],
            [126.806091625768, 37.68621643262],
            [126.806034571795, 37.686235943146],
            [126.805764391167, 37.686273092433],
            [126.80542309006, 37.686121793359],
            [126.805194040754, 37.686051271872],
            [126.804908012754, 37.686049173644],
            [126.804876270132, 37.686048760335],
            [126.804669141538, 37.686220680033],
            [126.804574949228, 37.686299357547],
            [126.804412005257, 37.686353593944],
            [126.804215696966, 37.686420297769],
            [126.804033916, 37.686480899005],
            [126.803843278917, 37.686546350348],
            [126.803587066289, 37.686714672068],
            [126.803388156657, 37.686663334235],
            [126.803196479339, 37.686595358326],
            [126.803096816555, 37.686560611512],
            [126.803030544647, 37.686537507273],
            [126.802781411422, 37.68636759748],
            [126.802689522962, 37.686240694318],
            [126.802657471598, 37.686186041571],
            [126.802120703946, 37.686000887158],
            [126.801917494992, 37.685934682597],
            [126.800572195604, 37.685742473875],
            [126.800426907499, 37.685555185112],
            [126.800336203123, 37.685306543211],
            [126.800224678166, 37.685211390796],
            [126.800153430907, 37.68472717857],
            [126.800093643819, 37.684449217644],
            [126.799641555963, 37.684147701211],
            [126.7991711336, 37.684253479686],
            [126.799180040282, 37.68435512427],
            [126.799189720494, 37.684464158126],
            [126.799157069688, 37.684464462495],
            [126.799058320514, 37.684466545441],
            [126.799014156265, 37.68436502051],
            [126.798938721268, 37.684340024218],
            [126.798824660564, 37.684344693477],
            [126.798721750755, 37.684292710851],
            [126.79872971579, 37.684198663382],
            [126.798745346159, 37.684162291108],
            [126.798903724303, 37.684035887054],
            [126.798933941943, 37.683971429579],
            [126.798970573166, 37.683924732201],
            [126.798926845506, 37.68378788999],
            [126.79893608749, 37.683682726733],
            [126.798941702931, 37.683618803611],
            [126.798914162574, 37.683490908785],
            [126.79896149008, 37.683471168757],
            [126.798900842184, 37.683178249606],
            [126.798786085333, 37.683272744261],
            [126.798409492048, 37.683435712315],
            [126.797960248599, 37.683757484738],
            [126.79777994368, 37.684066476077],
            [126.796997272355, 37.684597053509],
            [126.796942237783, 37.684822020094],
            [126.796657274582, 37.685135332909],
            [126.795985724845, 37.685035688607],
            [126.795647245783, 37.684774988538],
            [126.795351320683, 37.684664193014],
            [126.794649534417, 37.684677379646],
            [126.79441146732, 37.685082848294],
            [126.794398573307, 37.685231053039],
            [126.794395328384, 37.685708993248],
            [126.794050357376, 37.685866957394],
            [126.793668919341, 37.685929443681],
            [126.793208491771, 37.686427047185],
            [126.793100872355, 37.686518936039],
            [126.792934614048, 37.686620001075],
            [126.792908267132, 37.686635991715],
            [126.792810260323, 37.686695732725],
            [126.792765970209, 37.686722593298],
            [126.792739395974, 37.686738763691],
            [126.792634079335, 37.686817682243],
            [126.79254703557, 37.686929788719],
            [126.79251325902, 37.687007662641],
            [126.792486682794, 37.687105280524],
            [126.792451303828, 37.687107740524],
            [126.792420973829, 37.687209766482],
            [126.792351813156, 37.687291812164],
            [126.792317119138, 37.687332924885],
            [126.792230057628, 37.687410614091],
            [126.79219311909, 37.687443523979],
            [126.791937117652, 37.687445772227],
            [126.791862582851, 37.687543755209],
            [126.791735313401, 37.687727957121],
            [126.791307526003, 37.687899730838],
            [126.790820567205, 37.688067433037],
            [126.790694848812, 37.688102976809],
            [126.790474160523, 37.688165379585],
            [126.790336301174, 37.688204324925],
            [126.790036125965, 37.688310641866],
            [126.789778621589, 37.688401808296],
            [126.789997666096, 37.688838810558],
            [126.789675075251, 37.689331196249],
            [126.789575689322, 37.689670214139],
            [126.789471351622, 37.690143323095],
            [126.789539088206, 37.690404320138],
            [126.789866059227, 37.690826109896],
            [126.789688212652, 37.691070097023],
            [126.789549776138, 37.691588616522],
            [126.789652432404, 37.692101622801],
            [126.789844327111, 37.692767836518],
            [126.789475651835, 37.693534762478],
            [126.789233522165, 37.694028948963],
            [126.788843199092, 37.694169202033],
            [126.788231001451, 37.694224235666],
            [126.788173841989, 37.694229303825],
            [126.787871197156, 37.6944359784],
            [126.787641358758, 37.69459368154],
            [126.787166321693, 37.694918761958],
            [126.786575061945, 37.695200481343],
            [126.786194808924, 37.69528538854],
            [126.785810137557, 37.695368736763],
            [126.785465427395, 37.695688624766],
            [126.78548362565, 37.695719903643],
            [126.785630599877, 37.695972551684],
            [126.785562820754, 37.696053712825],
            [126.785472470336, 37.696168681905],
            [126.785338426769, 37.696336232343],
            [126.785497324708, 37.69683690253],
            [126.785533497531, 37.696945508267],
            [126.785498708829, 37.697059389822],
            [126.785450750099, 37.697210385088],
            [126.785441664, 37.697235127008],
            [126.785273174655, 37.697784101563],
            [126.785208324793, 37.698050569665],
            [126.785197316269, 37.698089074807],
            [126.785157779178, 37.698299242844],
            [126.78517191851, 37.698346164099],
            [126.784636095494, 37.698502849006],
            [126.784571447973, 37.698410921592],
            [126.78348519381, 37.698754528966],
            [126.7833306137, 37.69880343583],
            [126.783086786813, 37.698816679129],
            [126.782684642296, 37.698838547805],
            [126.782486972026, 37.698849352802],
            [126.78233251043, 37.698857715082],
            [126.782257559169, 37.698896407402],
            [126.781986144835, 37.699036273016],
            [126.78193982257, 37.69906015248],
            [126.781929363449, 37.699065538803],
            [126.781925955384, 37.699067694776],
            [126.781825650288, 37.699129224152],
            [126.781794252485, 37.699148446336],
            [126.781784415123, 37.699154464481],
            [126.781382751209, 37.699089746731],
            [126.781217935428, 37.699263235372],
            [126.781180943133, 37.699222550691],
            [126.780974190632, 37.699362967229],
            [126.780843961567, 37.699489183243],
            [126.779811964217, 37.699810640367],
            [126.779740294322, 37.699888979766],
            [126.77933378049, 37.700042820347],
            [126.779049447661, 37.700028724732],
            [126.778232785411, 37.699992358865],
            [126.77711786906, 37.700234764742],
            [126.776836029722, 37.700592902718],
            [126.776151183776, 37.700802872416],
            [126.775492055132, 37.70135525452],
            [126.775465134347, 37.701370564387],
            [126.774741904152, 37.701781773113],
            [126.774393402284, 37.70250142461],
            [126.77368678745, 37.702739448096],
            [126.773598932452, 37.703089970229],
            [126.773436342083, 37.703816248629],
            [126.773852471695, 37.704366551695],
            [126.773950302689, 37.704488190692],
            [126.774237303575, 37.705197263468],
            [126.773973012035, 37.705925184247],
            [126.773534488772, 37.706222376513],
            [126.773725302943, 37.706754134568],
            [126.773560290082, 37.707758121456],
            [126.773687490228, 37.708437514685],
            [126.773937581731, 37.709279138425],
            [126.774146952228, 37.709642631139],
            [126.774278897303, 37.710033633888],
            [126.774832623486, 37.710456170993],
            [126.774463942294, 37.711271735502],
            [126.774410398929, 37.711387946897],
            [126.774596604864, 37.711577688132],
            [126.775106205488, 37.712082126687],
            [126.775186045585, 37.71215687982],
            [126.775541143328, 37.712489476175],
            [126.776289993689, 37.713126449201],
            [126.776676405985, 37.713120159172],
            [126.777354421471, 37.713483007087],
            [126.777994244978, 37.714291755928],
            [126.778231024876, 37.714792149516],
            [126.778552518577, 37.715598760259],
            [126.778341471017, 37.715857297981],
            [126.778212426993, 37.71600075738],
            [126.778310315197, 37.716110770223],
            [126.778447314234, 37.716264643825],
            [126.779070158876, 37.716732609898],
            [126.779165137277, 37.716944155138],
            [126.779353969319, 37.717364630456],
            [126.780151214438, 37.717773996225],
            [126.780819453024, 37.717476309246],
            [126.781851411491, 37.717334172324],
            [126.781870386309, 37.717629994039],
            [126.781871362118, 37.717645222143],
            [126.78220065336, 37.718055773569],
            [126.782199562743, 37.718079466882],
            [126.782176160783, 37.71859234188],
            [126.782428521388, 37.718745974885],
            [126.783212564046, 37.718717877612],
            [126.783511289866, 37.718648245294],
            [126.784114909644, 37.718959291309],
            [126.784415159269, 37.718989396169],
            [126.784445329353, 37.719028193148],
            [126.784476968409, 37.719068884847],
            [126.784499228847, 37.719097486365],
            [126.78449554684, 37.719115498856],
            [126.784512957716, 37.719173552948],
            [126.784536068277, 37.719221797025],
            [126.78454272171, 37.719234963336],
            [126.784562131409, 37.71954105632],
            [126.784564062284, 37.719617912048],
            [126.784514678773, 37.719711160968],
            [126.784292475084, 37.719947885327],
            [126.784284444296, 37.719979133957],
            [126.784254917653, 37.720069446168],
            [126.784293740791, 37.720135918698],
            [126.784332794523, 37.72020113029],
            [126.784365072729, 37.720256148504],
            [126.784375454156, 37.720274457179],
            [126.784471958704, 37.720319502846],
            [126.784576731618, 37.720368527888],
            [126.784742541263, 37.720451271004],
            [126.785017546983, 37.720661430461],
            [126.785027162724, 37.720670097365],
            [126.785045288827, 37.720677518546],
            [126.785250858248, 37.720758982495]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41285105',
      properties: {
        rgnCode: '41285105',
        colCode: '41280',
        rgnKo: ['마두동'],
        rgnSize: 1,
        rgnBbox: [
          126.77098600221, 37.648303296675, 126.796107119748, 37.668908797997
        ],
        rgnCenter: [126.783439722107, 37.659126306916],
        rgnArea: 2835452,
        predVal: [
          0.06583, 0.06694, 0.06405, 0.07193, 0.09012, 0.08194, 0.0899, 0.10093,
          0.09254, 0.09882, 0.09446, 0.06541, 0.03365, 0.05817, 0.06425,
          0.03794, 0.04364, 0.07444, 0.10427, 0.07509, 0.07896, 0.09385,
          0.08234, 0.09325, 0.07465, 0.09096, 0.08314, 0.08561, 0.0837, 0.08722,
          0.06309
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.781038796625, 37.648303296675],
            [126.78027142141, 37.648882988463],
            [126.780127636608, 37.648993989039],
            [126.779987016664, 37.649107518108],
            [126.779849562115, 37.649223395487],
            [126.779715385739, 37.649341801594],
            [126.779584488344, 37.649462466149],
            [126.77945709656, 37.649585389589],
            [126.779332983766, 37.649710571498],
            [126.779212490178, 37.649837922432],
            [126.779095503028, 37.649967261992],
            [126.778982248685, 37.650098680714],
            [126.778872614113, 37.650232088299],
            [126.778766713176, 37.650367304775],
            [126.778664545618, 37.65050442025],
            [126.778566225036, 37.65064334485],
            [126.778471865299, 37.650783898607],
            [126.778381353109, 37.650926081314],
            [126.778294915382, 37.651069803313],
            [126.775906412081, 37.655154057497],
            [126.772946304664, 37.660214799103],
            [126.772850844976, 37.660373996099],
            [126.77275164966, 37.660531654115],
            [126.772648831753, 37.660687863459],
            [126.77254216513, 37.660842443488],
            [126.77243187616, 37.660995484729],
            [126.772317965659, 37.661146716882],
            [126.77220054639, 37.661296320349],
            [126.772079505841, 37.66144402461],
            [126.771955070108, 37.661590010288],
            [126.771827126681, 37.661734006863],
            [126.771695788883, 37.661876014543],
            [126.771561056427, 37.662016123414],
            [126.77098600221, 37.662602074329],
            [126.771164729156, 37.662712702437],
            [126.771856791636, 37.66314120261],
            [126.772035520893, 37.663251919494],
            [126.773957688076, 37.664441862638],
            [126.773968888343, 37.664448461338],
            [126.774022967656, 37.66447991946],
            [126.774079095052, 37.664508768688],
            [126.774137384138, 37.664534919142],
            [126.774197494899, 37.664558370162],
            [126.774259314818, 37.664578851238],
            [126.774322390266, 37.664596451591],
            [126.774386721785, 37.664610991024],
            [126.77445208242, 37.664622559198],
            [126.774518132701, 37.664630975261],
            [126.774584759007, 37.664636329092],
            [126.77465162187, 37.664638439842],
            [126.774718494056, 37.664637487268],
            [126.774785262495, 37.664633381057],
            [126.774851586893, 37.66462621065],
            [126.774988212853, 37.664608453671],
            [126.775057598406, 37.664600928639],
            [126.775127426621, 37.664596918205],
            [126.775197584719, 37.664596241958],
            [126.775267505751, 37.664598988906],
            [126.775337189452, 37.664605249148],
            [126.775406183031, 37.664614841621],
            [126.77547425955, 37.664627855988],
            [126.775541305953, 37.664644201938],
            [126.775606869445, 37.664663698411],
            [126.775670836152, 37.664686525385],
            [126.775732980496, 37.664712322043],
            [126.7757929622, 37.664741177833],
            [126.775850555138, 37.664772912133],
            [126.775905533185, 37.664807344317],
            [126.775957783006, 37.664844474174],
            [126.776007078472, 37.664884121081],
            [126.776053080116, 37.664926104197],
            [126.77609590182, 37.66497024355],
            [126.776135090774, 37.665016358084],
            [126.776170647249, 37.665064357705],
            [126.776973200072, 37.66622958299],
            [126.777050172877, 37.666337575771],
            [126.777130891546, 37.666443773683],
            [126.777215242747, 37.666548176503],
            [126.777303226763, 37.666650694126],
            [126.777369760343, 37.666725240811],
            [126.777478000225, 37.666838608447],
            [126.777590442973, 37.666949010755],
            [126.777707201661, 37.667056538036],
            [126.777828162955, 37.66716119006],
            [126.777953214327, 37.667262696312],
            [126.778082129366, 37.667360966252],
            [126.778214907801, 37.667456089964],
            [126.778351437098, 37.667547796931],
            [126.778491377227, 37.667636086497],
            [126.778634841792, 37.667720868768],
            [126.778716653276, 37.667766071887],
            [126.778781604639, 37.66780196311],
            [126.778931439074, 37.667879369083],
            [126.77908423201, 37.667952996364],
            [126.779239870091, 37.66802284473],
            [126.779398127154, 37.668088733548],
            [126.779559003448, 37.668150572712],
            [126.781430522683, 37.668842331385],
            [126.781610317133, 37.668908797997],
            [126.783235196568, 37.666130517554],
            [126.783415099452, 37.66619698164],
            [126.787834537615, 37.667830152589],
            [126.788142843596, 37.667944052893],
            [126.788528595379, 37.668086562951],
            [126.788586605885, 37.667986840058],
            [126.788721431538, 37.667755083234],
            [126.788940294944, 37.667401756482],
            [126.78932617312, 37.666778798786],
            [126.789375906511, 37.666707351097],
            [126.78939183929, 37.666684495073],
            [126.789535944646, 37.666467980367],
            [126.789616225735, 37.666295858839],
            [126.790005536303, 37.665461807272],
            [126.790476506379, 37.664804941393],
            [126.790524645665, 37.664695199036],
            [126.790673475097, 37.664408775886],
            [126.791042096702, 37.663801638483],
            [126.791404153713, 37.663205299831],
            [126.7915978264, 37.662889673704],
            [126.791753485152, 37.662630380511],
            [126.791926471636, 37.662334808705],
            [126.792011825759, 37.66216873094],
            [126.792103116311, 37.662027530483],
            [126.792112108613, 37.662013671472],
            [126.792179755938, 37.661897205718],
            [126.792317203022, 37.661664368],
            [126.792572639583, 37.66122550657],
            [126.792705880069, 37.6609965351],
            [126.792825869137, 37.660804750193],
            [126.792831220429, 37.660795930129],
            [126.79319010365, 37.660201562179],
            [126.793914492735, 37.658948144608],
            [126.794033950357, 37.658741401312],
            [126.794004527728, 37.658730069379],
            [126.793906818406, 37.658692435007],
            [126.793755347182, 37.658634055584],
            [126.793827121102, 37.658502838142],
            [126.793834798504, 37.658488814501],
            [126.79395947105, 37.658260871542],
            [126.793981186186, 37.658268486929],
            [126.794249761414, 37.658362651297],
            [126.794855588907, 37.657308764879],
            [126.794950788562, 37.657143242661],
            [126.795042272057, 37.657009968695],
            [126.795185306183, 37.656758306859],
            [126.795288866779, 37.65660135822],
            [126.796107119748, 37.655187356719],
            [126.796021874003, 37.655155854382],
            [126.795689042769, 37.655032831853],
            [126.790978692119, 37.653292037457],
            [126.785438373188, 37.651245729127],
            [126.784626646743, 37.65094556675],
            [126.784475426229, 37.650886815435],
            [126.784327165031, 37.650823654601],
            [126.784181976485, 37.650756084468],
            [126.784039973398, 37.650684285451],
            [126.783901495732, 37.650608258188],
            [126.783766769606, 37.650528183303],
            [126.783635794767, 37.650444150907],
            [126.783508910641, 37.650356341832],
            [126.783386343864, 37.650264756512],
            [126.783268093911, 37.650169575154],
            [126.781215344227, 37.648454719861],
            [126.781038796625, 37.648303296675]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281127',
      properties: {
        rgnCode: '41281127',
        colCode: '41280',
        rgnKo: ['신평동'],
        rgnSize: 1,
        rgnBbox: [
          126.759515853175, 37.605429072077, 126.804763600334, 37.635331907181
        ],
        rgnCenter: [126.783386323433, 37.621991388613],
        rgnArea: 4485347,
        predVal: [
          0.32913, 0.33469, 0.32025, 0.35966, 0.4506, 0.4097, 0.44949, 0.47273,
          0.4071, 0.42857, 0.42713, 0.32704, 0.47008, 0.41782, 0.47517, 0.18971,
          0.4405, 0.40416, 0.52136, 0.45529, 0.46633, 0.47447, 0.41172, 0.46626,
          0.37323, 0.45482, 0.41571, 0.42804, 0.41849, 0.47934, 0.46073
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.790939976443, 37.625622623711],
            [126.791219335105, 37.625356369365],
            [126.791356080125, 37.6251969687],
            [126.791509725768, 37.625013343622],
            [126.791526644527, 37.624990930368],
            [126.791722399824, 37.624757042291],
            [126.791902105778, 37.624570417709],
            [126.791920202336, 37.624552060891],
            [126.792292540207, 37.624165256007],
            [126.792474353032, 37.623997861141],
            [126.792682049302, 37.623785661104],
            [126.792898431849, 37.623561574126],
            [126.793182796155, 37.623266087123],
            [126.793206129668, 37.623242135263],
            [126.793815133532, 37.622613749227],
            [126.793984005485, 37.62244149095],
            [126.794248071857, 37.622081482584],
            [126.794261425864, 37.622061405208],
            [126.794300924779, 37.622008145656],
            [126.794326681018, 37.62197215174],
            [126.794374680182, 37.62190488786],
            [126.794454300998, 37.62179823461],
            [126.794535336426, 37.621687880763],
            [126.794568406236, 37.621645016143],
            [126.794854493022, 37.621270566252],
            [126.79511112171, 37.620930580612],
            [126.795386230822, 37.620558876183],
            [126.795514703125, 37.620365750479],
            [126.795919010127, 37.619905406688],
            [126.795946661347, 37.619875866714],
            [126.79598987546, 37.619821766125],
            [126.796038578394, 37.619752908034],
            [126.79608957426, 37.619669890525],
            [126.79623459482, 37.619467440648],
            [126.796372806202, 37.619273574089],
            [126.796426088943, 37.619215463461],
            [126.796552375768, 37.619073931846],
            [126.79662451571, 37.618979301159],
            [126.797087704608, 37.618329732715],
            [126.79752573278, 37.617707778945],
            [126.797632989338, 37.617556807012],
            [126.797781047462, 37.617329808715],
            [126.797991540457, 37.617047408579],
            [126.798653141073, 37.616420105605],
            [126.798888949882, 37.616193508952],
            [126.798998550487, 37.616082669385],
            [126.799363985888, 37.615714425906],
            [126.799612637294, 37.615474172904],
            [126.800175860702, 37.614953386857],
            [126.800515227536, 37.614676112224],
            [126.80108730826, 37.61421814413],
            [126.80193619076, 37.613501663989],
            [126.802098616502, 37.613366187915],
            [126.8025838803, 37.612736356931],
            [126.803001921109, 37.612275506773],
            [126.803711089585, 37.611667706999],
            [126.804314089695, 37.611026164014],
            [126.80453146474, 37.610716229398],
            [126.804688715128, 37.610365911268],
            [126.804729740022, 37.610102262696],
            [126.804763600334, 37.609587453824],
            [126.804739267227, 37.609073899355],
            [126.802685354472, 37.606288795735],
            [126.802034858974, 37.605429072077],
            [126.801469925304, 37.605660298726],
            [126.800342904125, 37.606155364238],
            [126.798330410833, 37.607217560928],
            [126.797185693786, 37.607818692161],
            [126.796934035827, 37.607951628372],
            [126.795608427033, 37.608829743311],
            [126.79323097172, 37.610406035232],
            [126.792711416577, 37.610748841112],
            [126.792350304474, 37.610985799144],
            [126.791665155108, 37.611443109655],
            [126.790209647023, 37.612418661518],
            [126.789530238489, 37.612871203585],
            [126.78882054913, 37.613343004131],
            [126.788329609168, 37.613633882567],
            [126.787949159096, 37.61385470055],
            [126.787423629034, 37.614163027625],
            [126.786792058909, 37.61452656969],
            [126.786638194947, 37.614616387591],
            [126.785587828833, 37.615226929235],
            [126.785587823623, 37.615228731185],
            [126.783743100034, 37.61629346146],
            [126.782233361813, 37.617177868976],
            [126.781597611165, 37.617543735787],
            [126.781582962595, 37.617548681892],
            [126.781366783628, 37.617671262438],
            [126.780696340104, 37.618064638591],
            [126.77968992468, 37.618664451802],
            [126.777695061249, 37.619831831272],
            [126.775781695262, 37.620960302246],
            [126.775093545762, 37.621357153242],
            [126.773473693313, 37.622308359196],
            [126.773259066587, 37.622436766092],
            [126.772301571726, 37.623006175266],
            [126.771167130251, 37.623684366774],
            [126.769955111, 37.624394352743],
            [126.769357077716, 37.624726007871],
            [126.768306734499, 37.625292814028],
            [126.765226361337, 37.626861720709],
            [126.761925313665, 37.628503131709],
            [126.760946721468, 37.628984109355],
            [126.760399702751, 37.62925256513],
            [126.75996684695, 37.629465373524],
            [126.759515853175, 37.629688243071],
            [126.760496583828, 37.63130811615],
            [126.762630929959, 37.634916361694],
            [126.762871035154, 37.635325989775],
            [126.764017634321, 37.635331907181],
            [126.764138924883, 37.63530022067],
            [126.76559639015, 37.634922328283],
            [126.766279409178, 37.634494475965],
            [126.766907165557, 37.634161157334],
            [126.767587490017, 37.633905216303],
            [126.768540717578, 37.633717635987],
            [126.769086219761, 37.633275242243],
            [126.769716808398, 37.633106008981],
            [126.770270238629, 37.632956775991],
            [126.771183251512, 37.632736417034],
            [126.771336993838, 37.632700524823],
            [126.772663674875, 37.632262212532],
            [126.773323585635, 37.632101341833],
            [126.773492743336, 37.632058304844],
            [126.773554259299, 37.632039620325],
            [126.773956493915, 37.63192662141],
            [126.77421093073, 37.63185492558],
            [126.77454364106, 37.631785830447],
            [126.775342084975, 37.631527116484],
            [126.775736598496, 37.631399392743],
            [126.775873260486, 37.631330774486],
            [126.776296045503, 37.631320419118],
            [126.776557104541, 37.631316628468],
            [126.777020204074, 37.631303905711],
            [126.778758528079, 37.63125809697],
            [126.779451131488, 37.631238986331],
            [126.779778958011, 37.631141035968],
            [126.779877474483, 37.631132670788],
            [126.780174855349, 37.631082071534],
            [126.780354663196, 37.631055640664],
            [126.780528391988, 37.631027666474],
            [126.781722502678, 37.630850512306],
            [126.781816169211, 37.630819287696],
            [126.782170658598, 37.630701991291],
            [126.782180763441, 37.630698234981],
            [126.782204769753, 37.630690197838],
            [126.782552726299, 37.630578924684],
            [126.782697752955, 37.630533288939],
            [126.782975981635, 37.630441012948],
            [126.783244689733, 37.63034232175],
            [126.783621323233, 37.630193536743],
            [126.783871143582, 37.630091421558],
            [126.784712760156, 37.629721849419],
            [126.785126813945, 37.629531070715],
            [126.785473196233, 37.629373241543],
            [126.78554707918, 37.629334445566],
            [126.785720266468, 37.629240943659],
            [126.785886159993, 37.629150617626],
            [126.786036327128, 37.629068903013],
            [126.786285656472, 37.628934256509],
            [126.786355166081, 37.628899299218],
            [126.786396994877, 37.628878076378],
            [126.78659442078, 37.628765561693],
            [126.786687298211, 37.628719564743],
            [126.786708648895, 37.628697061159],
            [126.787051570011, 37.62849939782],
            [126.787460631004, 37.628263634012],
            [126.787857218898, 37.628031179805],
            [126.788344773226, 37.627757804049],
            [126.788618084287, 37.627461290227],
            [126.788717278924, 37.627340152521],
            [126.789859283644, 37.626499026496],
            [126.790278751429, 37.626193472728],
            [126.790428232035, 37.626082334316],
            [126.790868220079, 37.625684870339],
            [126.790939976443, 37.625622623711]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281131',
      properties: {
        rgnCode: '41281131',
        colCode: '41280',
        rgnKo: ['덕은동'],
        rgnSize: 1,
        rgnBbox: [
          126.844263320255, 37.574299539785, 126.885171853852, 37.600132199942
        ],
        rgnCenter: [126.866028636439, 37.584225871755],
        rgnArea: 3947727,
        predVal: [
          0.39496, 0.40163, 0.3843, 0.43159, 0.54071, 0.49164, 0.53939, 0.56727,
          0.48852, 0.51429, 0.51256, 0.39245, 0.56409, 0.50139, 0.5702, 0.22766,
          0.5286, 0.48499, 0.62564, 0.54635, 0.5596, 0.56936, 0.49407, 0.55951,
          0.44788, 0.54579, 0.49886, 0.51365, 0.50219, 0.57521, 0.55288
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.844263320255, 37.578520500296],
            [126.845523109548, 37.580492032797],
            [126.845774866589, 37.581002872613],
            [126.846139873963, 37.581829124764],
            [126.846308043033, 37.582252782067],
            [126.846422281537, 37.582424200505],
            [126.846335893464, 37.582470938209],
            [126.846686891273, 37.58255156876],
            [126.846771249718, 37.582588322443],
            [126.846826923208, 37.582612559835],
            [126.846940463277, 37.582690040126],
            [126.846933732325, 37.582769633358],
            [126.846698818005, 37.583046504596],
            [126.846482802858, 37.583255249658],
            [126.846375491826, 37.583372912483],
            [126.846216230364, 37.583559423226],
            [126.846071690101, 37.583667765648],
            [126.8460545406, 37.583704638404],
            [126.846057190984, 37.583736753002],
            [126.846130946557, 37.583787746756],
            [126.846161881535, 37.583801887888],
            [126.846205423901, 37.583807441193],
            [126.84623378881, 37.583794378185],
            [126.846308358349, 37.583753319255],
            [126.846470106485, 37.583661658361],
            [126.846493345469, 37.58364723708],
            [126.846558115757, 37.583636321093],
            [126.846564601553, 37.583631545376],
            [126.846715022989, 37.583596802525],
            [126.846867389935, 37.583573801866],
            [126.846971967315, 37.583524132365],
            [126.847219280542, 37.58332885201],
            [126.847446778708, 37.583288019175],
            [126.847588171647, 37.583233324623],
            [126.84766366335, 37.583205645663],
            [126.847739748419, 37.583164668887],
            [126.847792534986, 37.583134185231],
            [126.848077645264, 37.583044520711],
            [126.848206906152, 37.583009270926],
            [126.848425763157, 37.58294701757],
            [126.848757784498, 37.582914363507],
            [126.848955668332, 37.582937900869],
            [126.849109455493, 37.582922539502],
            [126.849274712646, 37.582884208595],
            [126.849434555392, 37.582880558412],
            [126.849664914684, 37.582951510223],
            [126.849876839417, 37.582985236225],
            [126.849937903584, 37.582992531541],
            [126.850097400223, 37.582986582505],
            [126.850249978022, 37.582981345175],
            [126.850325949064, 37.582990344234],
            [126.850449201645, 37.583031307647],
            [126.850536431047, 37.583077171273],
            [126.850631487396, 37.583126792938],
            [126.850779807528, 37.583211927308],
            [126.850868823377, 37.583365911192],
            [126.850901796416, 37.583419651983],
            [126.850984931781, 37.583542445175],
            [126.85101282432, 37.583583601702],
            [126.851084318105, 37.583685594251],
            [126.851101075989, 37.583701454923],
            [126.851135645807, 37.583726987837],
            [126.851286664942, 37.583850209643],
            [126.851325976839, 37.583882262657],
            [126.851370688843, 37.583931378174],
            [126.85139410993, 37.583955518323],
            [126.851412151793, 37.583974768287],
            [126.851428644749, 37.583998593284],
            [126.851590776492, 37.584237821642],
            [126.851611722708, 37.584265418386],
            [126.851670452097, 37.584336715819],
            [126.851722922056, 37.584396058217],
            [126.852017286137, 37.584479934672],
            [126.85204239122, 37.584488742058],
            [126.852172280707, 37.584552867383],
            [126.85237239394, 37.584642029585],
            [126.852403833083, 37.584653926264],
            [126.85243324676, 37.584665270777],
            [126.852461672896, 37.584677902444],
            [126.852618992461, 37.584743539524],
            [126.852731875672, 37.584808696932],
            [126.852894524833, 37.584887449693],
            [126.853092379926, 37.584980076619],
            [126.85319757407, 37.585046440241],
            [126.853307740138, 37.585123468664],
            [126.853354880598, 37.585147935522],
            [126.853766891006, 37.585382113357],
            [126.853942738981, 37.585468990051],
            [126.854069493899, 37.585528271073],
            [126.854144809801, 37.585561016816],
            [126.854224374832, 37.585632266897],
            [126.854310312588, 37.585715832324],
            [126.854364623132, 37.585761949375],
            [126.854496037098, 37.585864771332],
            [126.854540044939, 37.585905154216],
            [126.85460466477, 37.585965438462],
            [126.854712421774, 37.586042624743],
            [126.854755437138, 37.586075392989],
            [126.854770126529, 37.586095953704],
            [126.854791106964, 37.586112747111],
            [126.854786633176, 37.586119354779],
            [126.854786289527, 37.58612715688],
            [126.854795931981, 37.586134854266],
            [126.854834124572, 37.58616749035],
            [126.854859042927, 37.58618536982],
            [126.854978442847, 37.58621460189],
            [126.85513840662, 37.586204682299],
            [126.855358625267, 37.586183268466],
            [126.855460610991, 37.586195792247],
            [126.855573767828, 37.586221772446],
            [126.855738438086, 37.586277440543],
            [126.85592754488, 37.586360321262],
            [126.85609599943, 37.586397397201],
            [126.856187976162, 37.5864278196],
            [126.856324573437, 37.586475343451],
            [126.856563099574, 37.586565140418],
            [126.856660894295, 37.586602381037],
            [126.856779370073, 37.586647665699],
            [126.856793523359, 37.586653079951],
            [126.856824517904, 37.586655649714],
            [126.856848528244, 37.58665162471],
            [126.856854949788, 37.586638892656],
            [126.856882865611, 37.586623465965],
            [126.856959220051, 37.586559481458],
            [126.856969221173, 37.586551637118],
            [126.856975208913, 37.586499747727],
            [126.856980158337, 37.586457857996],
            [126.856910806873, 37.586255411876],
            [126.856928044574, 37.586182182926],
            [126.857722777802, 37.586326860769],
            [126.858085843286, 37.586524437946],
            [126.858442095972, 37.586129878536],
            [126.858520966776, 37.585797060197],
            [126.858729903064, 37.585771635041],
            [126.859105617616, 37.58544485118],
            [126.859265434702, 37.585415671767],
            [126.859598689228, 37.585386790876],
            [126.860055122074, 37.585179571594],
            [126.860496157795, 37.585354926419],
            [126.860662191066, 37.585361125211],
            [126.861334494053, 37.585352194866],
            [126.861371106736, 37.585389440009],
            [126.861860974613, 37.58588764357],
            [126.862058719609, 37.586022394397],
            [126.862563255577, 37.586369688357],
            [126.862787295145, 37.586625110771],
            [126.862854500288, 37.586888637686],
            [126.86296286654, 37.587308263424],
            [126.863308572184, 37.587652304249],
            [126.863435915067, 37.587730298351],
            [126.863938880214, 37.58803649961],
            [126.864212777399, 37.588398744023],
            [126.864745381766, 37.588580550326],
            [126.865016097047, 37.588642400928],
            [126.865404945717, 37.588432650249],
            [126.86628074724, 37.588348784123],
            [126.867197838707, 37.588057083315],
            [126.867531837557, 37.587687302356],
            [126.867935505254, 37.587522393117],
            [126.868284959983, 37.58747882],
            [126.868676814486, 37.587429437168],
            [126.869005894971, 37.587701634132],
            [126.86932603437, 37.587793983614],
            [126.869498329167, 37.587758578528],
            [126.870020704687, 37.587478081389],
            [126.870283190827, 37.587235891467],
            [126.870305305039, 37.587215256469],
            [126.870721491044, 37.587041125513],
            [126.870900030493, 37.587143341715],
            [126.870943810951, 37.587176807677],
            [126.870946411552, 37.58717879272],
            [126.870950708411, 37.58718195092],
            [126.870963372233, 37.587191605463],
            [126.871011539699, 37.587228509004],
            [126.871348714848, 37.587485941261],
            [126.871618081922, 37.587412348229],
            [126.87210582219, 37.587486233118],
            [126.87223351639, 37.587687022345],
            [126.872240988452, 37.587693878012],
            [126.872252183927, 37.587701638741],
            [126.872577828138, 37.587945981556],
            [126.87272062632, 37.5883252718],
            [126.872761972606, 37.58851730772],
            [126.872704906118, 37.588781504242],
            [126.872861770599, 37.589337402575],
            [126.872863459491, 37.589342900421],
            [126.872867062328, 37.589354707244],
            [126.872884849613, 37.589414011408],
            [126.873295799664, 37.589774491902],
            [126.873347265585, 37.589817524753],
            [126.873183461251, 37.590063045225],
            [126.873185027607, 37.590126746523],
            [126.87319191774, 37.590400202764],
            [126.873202206548, 37.590804485603],
            [126.873296293467, 37.590951898769],
            [126.873527785864, 37.591314615953],
            [126.87359847626, 37.591425333439],
            [126.873642361016, 37.591452951104],
            [126.873842783487, 37.591579125643],
            [126.873773859068, 37.591693566157],
            [126.873753564221, 37.591841936277],
            [126.873742502383, 37.591921129816],
            [126.873735296295, 37.59199679567],
            [126.87379198493, 37.592078918655],
            [126.873799337848, 37.592089576242],
            [126.873664519191, 37.592267284545],
            [126.873665977845, 37.592341256914],
            [126.873688866053, 37.592537065531],
            [126.873695012939, 37.592590860932],
            [126.873720385257, 37.592645127641],
            [126.873950305838, 37.592937925842],
            [126.87412919831, 37.59313616432],
            [126.874164337214, 37.593313335673],
            [126.874167624189, 37.593377840655],
            [126.87417105537, 37.593563717329],
            [126.874168623487, 37.593595438346],
            [126.874124202022, 37.594008040999],
            [126.874079097293, 37.594356222532],
            [126.874303896687, 37.594601541604],
            [126.874329010126, 37.594628940552],
            [126.874346320765, 37.594716904221],
            [126.874375878527, 37.594774301693],
            [126.874440492252, 37.594931998364],
            [126.87452177629, 37.595148348947],
            [126.874689963207, 37.595627123248],
            [126.87529183709, 37.595932237785],
            [126.875577542594, 37.596069969984],
            [126.87559797568, 37.59608002874],
            [126.875654354429, 37.596060096048],
            [126.875734901908, 37.596030665692],
            [126.87596107195, 37.596140375986],
            [126.876099097838, 37.596211610323],
            [126.876247539252, 37.596284207028],
            [126.876319257249, 37.596327467258],
            [126.876355861338, 37.596350463136],
            [126.876370178305, 37.596361506354],
            [126.876431361443, 37.596380221566],
            [126.876637422375, 37.596388737816],
            [126.876770363092, 37.596501897755],
            [126.876851936957, 37.5965563673],
            [126.876880790985, 37.596575624758],
            [126.877127376827, 37.59674201721],
            [126.877148766626, 37.596762591181],
            [126.877235799643, 37.596816660768],
            [126.877291981358, 37.596847209188],
            [126.877380459875, 37.596896937449],
            [126.877512200833, 37.596970055582],
            [126.877563149324, 37.597029105488],
            [126.87758232825, 37.597051479024],
            [126.877673812639, 37.597077874752],
            [126.877793249832, 37.597114183499],
            [126.877814550027, 37.597120656865],
            [126.878023015478, 37.597182943903],
            [126.878102149491, 37.597207335202],
            [126.878190036105, 37.597245790934],
            [126.878352119373, 37.597315849453],
            [126.878421256262, 37.59749752377],
            [126.878423455189, 37.59750309414],
            [126.878439941294, 37.597544989035],
            [126.878450506389, 37.597572389942],
            [126.878464757335, 37.59761010194],
            [126.878484549841, 37.597665839378],
            [126.878600919271, 37.59772303791],
            [126.878761387438, 37.597984417969],
            [126.878782937291, 37.598025236899],
            [126.879647867226, 37.598216952353],
            [126.879934184342, 37.598253557255],
            [126.880067884088, 37.598281048752],
            [126.880177733483, 37.598331112239],
            [126.880419521909, 37.59844034348],
            [126.880465507804, 37.598461212373],
            [126.880624783376, 37.598533850702],
            [126.880723305857, 37.598579316073],
            [126.880744182518, 37.598589023005],
            [126.880845978293, 37.59863413123],
            [126.880901509897, 37.598648279367],
            [126.881060824453, 37.59869022065],
            [126.881136390325, 37.598709903138],
            [126.881139356659, 37.598710212497],
            [126.881241476958, 37.598736868577],
            [126.881256678128, 37.598741019578],
            [126.881378707257, 37.598773777458],
            [126.881453310327, 37.598793738068],
            [126.881768971217, 37.598886436542],
            [126.881898396506, 37.59892549025],
            [126.882490704658, 37.599128855286],
            [126.882931066019, 37.599357517551],
            [126.883176423289, 37.599663287151],
            [126.883514932345, 37.600070239679],
            [126.884029397174, 37.600132199942],
            [126.88411575974, 37.599869108757],
            [126.884329126366, 37.599709936905],
            [126.884510322417, 37.599383438245],
            [126.884824916871, 37.598821608321],
            [126.884973948602, 37.598379282732],
            [126.884958433601, 37.598307639246],
            [126.884929528188, 37.598116340968],
            [126.884925926133, 37.598102543356],
            [126.884917799363, 37.598071181111],
            [126.884878775208, 37.59792057924],
            [126.884875544982, 37.597907980302],
            [126.88478557289, 37.597549110793],
            [126.88475228299, 37.597337986476],
            [126.884733589195, 37.597126948664],
            [126.884725144631, 37.597016479742],
            [126.884765400929, 37.596762081847],
            [126.884744676183, 37.596684135286],
            [126.88472138483, 37.596596527637],
            [126.884560525526, 37.596269761835],
            [126.884598493919, 37.595878322094],
            [126.884532431566, 37.595785491367],
            [126.884390262995, 37.595585746466],
            [126.884204072474, 37.595456720899],
            [126.884164631079, 37.595429381887],
            [126.884135882698, 37.595399620841],
            [126.884097027907, 37.595359308225],
            [126.884073513753, 37.595334958272],
            [126.884006329114, 37.594991625627],
            [126.883956826285, 37.594738228716],
            [126.883940372807, 37.594652429663],
            [126.883876808103, 37.594319263608],
            [126.883961705767, 37.59425645051],
            [126.884120062579, 37.594139155863],
            [126.884166144549, 37.594105027369],
            [126.884508523276, 37.59387202145],
            [126.884642923775, 37.593780551571],
            [126.884873003233, 37.593623971919],
            [126.885085930324, 37.593828912719],
            [126.885100202118, 37.593818592511],
            [126.885171853852, 37.593768000832],
            [126.885037821306, 37.593652876065],
            [126.884663995653, 37.593179670192],
            [126.884497537477, 37.592897317089],
            [126.884369722464, 37.592739698294],
            [126.884218498415, 37.592563766118],
            [126.884084576611, 37.592402086508],
            [126.883941040653, 37.592234270474],
            [126.883782809875, 37.592051122874],
            [126.883681637794, 37.591945336475],
            [126.883636644892, 37.591899881948],
            [126.883572520184, 37.591852155827],
            [126.883512990362, 37.591834527138],
            [126.883364961727, 37.591788428865],
            [126.883081050559, 37.591605695034],
            [126.883013973275, 37.591563822043],
            [126.882890333289, 37.591489997341],
            [126.882689327316, 37.591365279138],
            [126.882556966085, 37.591279390135],
            [126.882524526195, 37.591258346484],
            [126.882380869563, 37.591162706255],
            [126.882337551304, 37.591132939054],
            [126.882257060223, 37.591053904503],
            [126.882231183451, 37.59102838951],
            [126.882217164849, 37.591014950676],
            [126.882081621937, 37.590880206549],
            [126.882047506658, 37.590830798084],
            [126.882031939956, 37.590808383856],
            [126.882050121969, 37.590795509174],
            [126.881957383566, 37.590707497224],
            [126.881881810561, 37.590633521916],
            [126.881976529964, 37.590537636012],
            [126.881724556111, 37.590355111421],
            [126.881679645212, 37.59033046896],
            [126.881653398398, 37.590317107741],
            [126.881495804923, 37.590236669896],
            [126.881420685006, 37.590198391751],
            [126.881147246754, 37.590057739857],
            [126.880988188846, 37.589973335506],
            [126.880644454393, 37.589753684402],
            [126.880340979941, 37.589494880799],
            [126.880181363392, 37.589267668729],
            [126.880036658842, 37.58906164492],
            [126.880005061401, 37.588997011802],
            [126.879902031857, 37.588786075296],
            [126.879832060575, 37.58864671096],
            [126.879792551714, 37.588573600368],
            [126.879605479267, 37.588244187504],
            [126.879536106321, 37.588085722721],
            [126.879463464813, 37.587918334765],
            [126.879405955073, 37.587775559298],
            [126.879340235797, 37.587598898274],
            [126.879304281669, 37.587360460468],
            [126.879283027011, 37.587203126504],
            [126.879147856213, 37.586779343353],
            [126.879040778669, 37.586692917946],
            [126.878968185414, 37.586635720158],
            [126.878884400853, 37.586567969176],
            [126.878472151915, 37.586371846244],
            [126.878072347883, 37.586319712288],
            [126.877555837026, 37.586254660492],
            [126.877420745623, 37.585926379804],
            [126.877378454384, 37.58582659629],
            [126.877276842383, 37.585587277848],
            [126.877272844244, 37.585472398038],
            [126.877268414211, 37.585344903979],
            [126.877257026274, 37.585112257623],
            [126.877225614572, 37.585006448893],
            [126.877203893603, 37.584928310604],
            [126.877093915711, 37.584818815131],
            [126.876941891747, 37.584735133466],
            [126.876764191613, 37.584637279003],
            [126.8765893803, 37.584491197588],
            [126.876560912192, 37.584467408476],
            [126.87653864447, 37.584445941453],
            [126.876544508404, 37.58439215884],
            [126.876560028088, 37.584251261169],
            [126.876626855458, 37.584165558088],
            [126.876644274312, 37.584161810396],
            [126.876695149154, 37.584150854161],
            [126.876778368639, 37.584147338186],
            [126.876981545226, 37.584242786854],
            [126.877174018444, 37.584239115513],
            [126.877299452764, 37.584176358796],
            [126.877334752403, 37.584054312423],
            [126.877290841052, 37.583908216495],
            [126.877250081622, 37.583773115887],
            [126.877151291389, 37.58360894241],
            [126.877132203509, 37.583581622441],
            [126.876989988492, 37.583387940836],
            [126.877000260331, 37.583202438765],
            [126.877063166529, 37.583023028793],
            [126.877077380651, 37.582985562781],
            [126.877101444344, 37.582880082741],
            [126.877093408099, 37.582674829766],
            [126.877090835968, 37.582519677341],
            [126.877072299927, 37.582365048675],
            [126.877056596528, 37.58220907151],
            [126.876974332238, 37.582048248912],
            [126.876803329741, 37.581807595305],
            [126.876675931627, 37.581624740747],
            [126.876633800565, 37.581363320224],
            [126.876670660382, 37.581255961709],
            [126.876998891008, 37.581033584694],
            [126.877045147347, 37.580993809903],
            [126.877163030509, 37.580893798576],
            [126.877174139375, 37.580885259922],
            [126.87733122777, 37.580757557368],
            [126.87738187814, 37.580703055947],
            [126.877427154014, 37.580653522295],
            [126.877449122784, 37.580629480054],
            [126.877459326759, 37.580572656588],
            [126.877488695607, 37.580421258749],
            [126.877493658396, 37.580323182637],
            [126.877465972782, 37.580251597245],
            [126.877402271015, 37.580162062385],
            [126.877407938701, 37.580109982346],
            [126.877568575941, 37.579902681079],
            [126.877673062243, 37.579769570949],
            [126.877633529672, 37.579653365343],
            [126.8776245957, 37.579627110245],
            [126.877585723145, 37.579577244915],
            [126.877569839772, 37.579556856945],
            [126.877479737664, 37.579590486217],
            [126.877501698767, 37.579352441745],
            [126.87743091865, 37.578944409873],
            [126.877365140617, 37.578565124651],
            [126.877257671691, 37.577986767628],
            [126.876260194965, 37.578191399655],
            [126.875194058607, 37.578278605349],
            [126.874546793772, 37.5782811365],
            [126.873925657244, 37.578283557196],
            [126.873832050921, 37.578280933229],
            [126.873728710133, 37.578270910563],
            [126.87359480465, 37.578260224001],
            [126.873441181167, 37.57824802026],
            [126.873090377042, 37.578220888872],
            [126.872961235834, 37.578211314987],
            [126.872145602258, 37.578146544054],
            [126.871803503511, 37.578120085112],
            [126.871434852582, 37.578090262275],
            [126.87096060333, 37.578053681283],
            [126.870600170757, 37.578011557457],
            [126.870243108827, 37.577932630991],
            [126.869798324259, 37.577834342516],
            [126.869358825557, 37.577737202534],
            [126.868865191149, 37.577628296286],
            [126.868784936837, 37.577610645859],
            [126.868386130475, 37.577524224686],
            [126.868035779745, 37.5775187385],
            [126.867952395048, 37.577517247661],
            [126.867498131752, 37.577512363058],
            [126.867165541331, 37.577507876525],
            [126.866741839658, 37.577498951331],
            [126.866189867785, 37.577488318942],
            [126.865572170039, 37.577477031673],
            [126.864940839002, 37.577467671531],
            [126.863297049359, 37.576823481327],
            [126.863254616753, 37.576806997648],
            [126.862963939496, 37.576691781101],
            [126.8627405589, 37.576603231073],
            [126.862163472008, 37.576375070249],
            [126.862083663429, 37.576344576735],
            [126.86155683218, 37.576134266765],
            [126.860899891251, 37.575874056077],
            [126.860329332405, 37.575647849184],
            [126.859284718323, 37.575278938727],
            [126.859159126913, 37.575299257764],
            [126.858322574978, 37.575144303495],
            [126.858180775001, 37.575116317963],
            [126.857895111221, 37.575062362086],
            [126.857673555498, 37.575019457663],
            [126.857281806313, 37.57494444108],
            [126.857048931433, 37.574924812157],
            [126.856829349428, 37.574903703429],
            [126.856231908501, 37.574849135765],
            [126.855790073929, 37.574806938594],
            [126.855358913193, 37.574765545853],
            [126.854955144777, 37.574725645146],
            [126.854140089912, 37.574647244752],
            [126.853625941714, 37.574600840689],
            [126.852745184555, 37.574427249209],
            [126.852442098631, 37.574371167893],
            [126.852290211391, 37.574356902593],
            [126.851687927457, 37.574299539785],
            [126.851199268364, 37.574392107651],
            [126.851008223165, 37.574513803767],
            [126.850466973847, 37.574855937871],
            [126.849826708476, 37.575261651035],
            [126.849229020626, 37.575640782466],
            [126.848561310664, 37.576064049557],
            [126.847962093877, 37.576442776096],
            [126.847761777383, 37.576603341412],
            [126.847446596894, 37.57683813294],
            [126.847037673184, 37.577142294044],
            [126.846701318082, 37.577393796167],
            [126.846019676875, 37.577707966104],
            [126.84580869596, 37.577805282821],
            [126.845125232175, 37.578121256142],
            [126.844263320255, 37.578520500296]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41287105',
      properties: {
        rgnCode: '41287105',
        colCode: '41280',
        rgnKo: ['덕이동'],
        rgnSize: 1,
        rgnBbox: [
          126.727819768586, 37.681823361537, 126.76163886873, 37.706658668741
        ],
        rgnCenter: [126.743682907354, 37.695846296581],
        rgnArea: 5533234,
        predVal: [
          0.53448, 0.53424, 0.53673, 0.53631, 0.53636, 0.53636, 0.53636,
          0.53631, 0.53635, 0.53673, 0.53623, 0.53623, 0.53623, 0.53623,
          0.53623, 0.53023, 0.06339, 0.52174, 0.53166, 0.53025, 0.52969,
          0.52937, 0.52937, 0.52937, 0.52937, 0.52937, 0.52937, 0.52623,
          0.52553, 0.519, 0.51369
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.727819768586, 37.692093941374],
            [126.727848737723, 37.692877390004],
            [126.727873054255, 37.693359697731],
            [126.727878990905, 37.693586169409],
            [126.727901380958, 37.694000422617],
            [126.727918388911, 37.69440759072],
            [126.727933415702, 37.69472139608],
            [126.727950074238, 37.695047845762],
            [126.727956389065, 37.695239369773],
            [126.727964170138, 37.6954175809],
            [126.727999190548, 37.69610700927],
            [126.728013856268, 37.696374206724],
            [126.728030560497, 37.696719270365],
            [126.728045601393, 37.697023300141],
            [126.728050677969, 37.697156907129],
            [126.728075036226, 37.697671766456],
            [126.728095456017, 37.698073527389],
            [126.728106548538, 37.698282640212],
            [126.728123165467, 37.698617729838],
            [126.728139748652, 37.698952783325],
            [126.728147856356, 37.699153420117],
            [126.728164648856, 37.699499465835],
            [126.728178915093, 37.699854172961],
            [126.728188166419, 37.700067660137],
            [126.728199138334, 37.700294323407],
            [126.728208355494, 37.700501701942],
            [126.728240015934, 37.701264325286],
            [126.728289992989, 37.702356762197],
            [126.728299024316, 37.702358467927],
            [126.728351337545, 37.702388257904],
            [126.728403260377, 37.704400503037],
            [126.72845773801, 37.704418738594],
            [126.728514320983, 37.704450403374],
            [126.728582135457, 37.704418846401],
            [126.728613776595, 37.704418018669],
            [126.728603546821, 37.704455475126],
            [126.728630208732, 37.704451842875],
            [126.72890526924, 37.704494734352],
            [126.728997297439, 37.704508731836],
            [126.729023943848, 37.704509333994],
            [126.729335582697, 37.704534830275],
            [126.729354747523, 37.704534694317],
            [126.729517479432, 37.704533267874],
            [126.729605403257, 37.704522298693],
            [126.729678179066, 37.704529403917],
            [126.729669605038, 37.704579387693],
            [126.729591635801, 37.704659123486],
            [126.729483979893, 37.704732303797],
            [126.729415657677, 37.704809719218],
            [126.729397050075, 37.704874545735],
            [126.729422353175, 37.704932446056],
            [126.729474717513, 37.704970587585],
            [126.729482831139, 37.704984571256],
            [126.729486888118, 37.704991518043],
            [126.729518780935, 37.705046009879],
            [126.729742671692, 37.705065896856],
            [126.729779576119, 37.705052287245],
            [126.729817502103, 37.705038409687],
            [126.729897324278, 37.705010303293],
            [126.729918419954, 37.705009360824],
            [126.730145935109, 37.704999343357],
            [126.730400330159, 37.704988125905],
            [126.730631537113, 37.705023074126],
            [126.730717560453, 37.705035885219],
            [126.730901280366, 37.705063426032],
            [126.731227416651, 37.705157063514],
            [126.731290962932, 37.705145316425],
            [126.731560235718, 37.705096740532],
            [126.731750952468, 37.705008612174],
            [126.731813375986, 37.704993708858],
            [126.732016309681, 37.704945070265],
            [126.732164888238, 37.704937931744],
            [126.732168764638, 37.704932174424],
            [126.732177477054, 37.704937510017],
            [126.732185850256, 37.704942574545],
            [126.73231165696, 37.704929527553],
            [126.732379309998, 37.704942295492],
            [126.732480169493, 37.704960454853],
            [126.732603500298, 37.704942176305],
            [126.732816684589, 37.704912389873],
            [126.733055936921, 37.704917800032],
            [126.733208324155, 37.704955086428],
            [126.733523235967, 37.705017338453],
            [126.733592939871, 37.705027497559],
            [126.73374269416, 37.705071804807],
            [126.733937222522, 37.705121348975],
            [126.734036598755, 37.705142206533],
            [126.734249949212, 37.70516088979],
            [126.734297722885, 37.705088650596],
            [126.734360280814, 37.705036176006],
            [126.734543290105, 37.705009291266],
            [126.734788044382, 37.704840193493],
            [126.734893312738, 37.704798987184],
            [126.735009822976, 37.704658429772],
            [126.735239957524, 37.704613090571],
            [126.735445639217, 37.704619591322],
            [126.735720030416, 37.704660663468],
            [126.735804767625, 37.704684459722],
            [126.735825299885, 37.704714237827],
            [126.735906263001, 37.704715231094],
            [126.736005960629, 37.704645991236],
            [126.73611786871, 37.704650567795],
            [126.736143289658, 37.70451782279],
            [126.736267911237, 37.704518733862],
            [126.736308500151, 37.704520897377],
            [126.736812738187, 37.704374001857],
            [126.737626349209, 37.704379879813],
            [126.738530869054, 37.704396135844],
            [126.738581949819, 37.704413638379],
            [126.7387623752, 37.70447539627],
            [126.739186043966, 37.705018810522],
            [126.739277144993, 37.705135778202],
            [126.739460788144, 37.705508194834],
            [126.739551499221, 37.70573625038],
            [126.739738666468, 37.706235980683],
            [126.739751923158, 37.706271418024],
            [126.739785064461, 37.706360146512],
            [126.739917958061, 37.706492793121],
            [126.740084075489, 37.706658668741],
            [126.740392453994, 37.706615114693],
            [126.740441679383, 37.706612520799],
            [126.740957999696, 37.706482122019],
            [126.741163660909, 37.706334007222],
            [126.741170368543, 37.706329156822],
            [126.741197653304, 37.706309576032],
            [126.741192783145, 37.706281896655],
            [126.741163294926, 37.70611444132],
            [126.741174448813, 37.706076895719],
            [126.741236780779, 37.705912778445],
            [126.741086686253, 37.705620399314],
            [126.741139059349, 37.705560636855],
            [126.741240077002, 37.705404704656],
            [126.741366887483, 37.705282687533],
            [126.741716795506, 37.705103329088],
            [126.741921313216, 37.704998681963],
            [126.742432678726, 37.704740310721],
            [126.742569573963, 37.704671777703],
            [126.742650193359, 37.704631411295],
            [126.742847499292, 37.704630330661],
            [126.742997881296, 37.704583422859],
            [126.743120841187, 37.704654742467],
            [126.743479705208, 37.704843667817],
            [126.743972159009, 37.704857817428],
            [126.744034248121, 37.70490606455],
            [126.744175384294, 37.704984846697],
            [126.744249229965, 37.705011045721],
            [126.744293556694, 37.705080246434],
            [126.744299100762, 37.705083862387],
            [126.744331572016, 37.705105376179],
            [126.744369926626, 37.705130776942],
            [126.744443743884, 37.705165264659],
            [126.744523681454, 37.705167871512],
            [126.744684002052, 37.705208313753],
            [126.744832790959, 37.705238639867],
            [126.74494230766, 37.705246536418],
            [126.745040488328, 37.705286212272],
            [126.745100997934, 37.705299497957],
            [126.745150005567, 37.705326903753],
            [126.745160165223, 37.705340350218],
            [126.745179130276, 37.705365348176],
            [126.74520931997, 37.705390911108],
            [126.745210106305, 37.705327214599],
            [126.745253979179, 37.70526505328],
            [126.745381503948, 37.705180639654],
            [126.745522547941, 37.705088957423],
            [126.745637992376, 37.704988840499],
            [126.745777667896, 37.704932202527],
            [126.74580476613, 37.704923503915],
            [126.745853502062, 37.704907860726],
            [126.745903163339, 37.704910040609],
            [126.745955434269, 37.704845734912],
            [126.745991818767, 37.704817523462],
            [126.746067656766, 37.704792010276],
            [126.746095318649, 37.704695036268],
            [126.746163438123, 37.704572472367],
            [126.746171114083, 37.704417432906],
            [126.746179311819, 37.704341679496],
            [126.74617087721, 37.704288143906],
            [126.746220952761, 37.704202480457],
            [126.746286495308, 37.704136761793],
            [126.746294200099, 37.70410551498],
            [126.746380368677, 37.704043354668],
            [126.746493858491, 37.704006534465],
            [126.746636364854, 37.703960298722],
            [126.746667427344, 37.703929642949],
            [126.747055166283, 37.703721005913],
            [126.747264152251, 37.70362289112],
            [126.747422774046, 37.703562507976],
            [126.747584239383, 37.703532763559],
            [126.747690784967, 37.703513442008],
            [126.748006395134, 37.703439025505],
            [126.748514937452, 37.703217894635],
            [126.748526112175, 37.703233054827],
            [126.748657299596, 37.703236669785],
            [126.748931475011, 37.7031749106],
            [126.749161457739, 37.70307017026],
            [126.749276553926, 37.703036990706],
            [126.749467987962, 37.703097674628],
            [126.749571252169, 37.703109427665],
            [126.749792626235, 37.703068636235],
            [126.750311911184, 37.70302208256],
            [126.751016534636, 37.703634616205],
            [126.75155531355, 37.703997316888],
            [126.751765338718, 37.704368662253],
            [126.752261384456, 37.704386586926],
            [126.752563818875, 37.70448111541],
            [126.752650090889, 37.704508686862],
            [126.752662120913, 37.704512541353],
            [126.752722233296, 37.704549896081],
            [126.752851976723, 37.704589361741],
            [126.753133870571, 37.704726361989],
            [126.75332228077, 37.704811926945],
            [126.753613094544, 37.705052961239],
            [126.753646503125, 37.704998126659],
            [126.753845182576, 37.704945765597],
            [126.753801670845, 37.704911842957],
            [126.753904247553, 37.704872154657],
            [126.754098658689, 37.704797115921],
            [126.75415672164, 37.704796291723],
            [126.754355960896, 37.704793637219],
            [126.75438404879, 37.704797354009],
            [126.754684573114, 37.704891287423],
            [126.755097434684, 37.705049306283],
            [126.755565765341, 37.705237108187],
            [126.755703624264, 37.705302616518],
            [126.75579495978, 37.705047374267],
            [126.755963065938, 37.704585808627],
            [126.755970851965, 37.704391216354],
            [126.755992716558, 37.704157010917],
            [126.756554085294, 37.703763015063],
            [126.756634513484, 37.703750595632],
            [126.757102993206, 37.703915876653],
            [126.757349432945, 37.704045946017],
            [126.757674158493, 37.704267534659],
            [126.757693808391, 37.70425775477],
            [126.7581333761, 37.70403837685],
            [126.75834503577, 37.703916011953],
            [126.758321917186, 37.703876862379],
            [126.758871625316, 37.703576892194],
            [126.758929420284, 37.703518808741],
            [126.758968420394, 37.703479633905],
            [126.758955137431, 37.703526159465],
            [126.758935782633, 37.703584682402],
            [126.759155350435, 37.703470621188],
            [126.75918240836, 37.70347714573],
            [126.759243241614, 37.703429645701],
            [126.759278284623, 37.703383299955],
            [126.759293242225, 37.703365698788],
            [126.75932624795, 37.703293752414],
            [126.759363004343, 37.703254005228],
            [126.759368112428, 37.70325239398],
            [126.759483555722, 37.703215781503],
            [126.759529983768, 37.703200740569],
            [126.759640755625, 37.703136008227],
            [126.759684949338, 37.703110601547],
            [126.759730761435, 37.703040780568],
            [126.759884394177, 37.703151463759],
            [126.759916854144, 37.703212525633],
            [126.759933644255, 37.703245174983],
            [126.75994987091, 37.703276742018],
            [126.759959674947, 37.703295682377],
            [126.760030645233, 37.703335560316],
            [126.760162627924, 37.703408898831],
            [126.760331051284, 37.703539883429],
            [126.760353472499, 37.703549569617],
            [126.7605175558, 37.703620270483],
            [126.76067229514, 37.703670049638],
            [126.760748689053, 37.703681107328],
            [126.760813861694, 37.703690610422],
            [126.760866563072, 37.703699367257],
            [126.760966299247, 37.703715878234],
            [126.761283766773, 37.703754320936],
            [126.7612911563, 37.703548240146],
            [126.761303157595, 37.703062103616],
            [126.761302697319, 37.703029037244],
            [126.761301703196, 37.702915423464],
            [126.76129697458, 37.702694046591],
            [126.761297180414, 37.70255998333],
            [126.761296973679, 37.702518628587],
            [126.76129358223, 37.701988313295],
            [126.761293760749, 37.701968132027],
            [126.761292374411, 37.701800369388],
            [126.761292096739, 37.701780998059],
            [126.761290980227, 37.701635039335],
            [126.76129247612, 37.701487734481],
            [126.761294103698, 37.701264478475],
            [126.761322208982, 37.700601785243],
            [126.761370370514, 37.700154193357],
            [126.761358661722, 37.699783692291],
            [126.761340326787, 37.699203612988],
            [126.761333338585, 37.698979708669],
            [126.761312065996, 37.698262946719],
            [126.761284192154, 37.697097129571],
            [126.761257619906, 37.697074280984],
            [126.76122513837, 37.696633912483],
            [126.761204728874, 37.696282584039],
            [126.761203360758, 37.696179510674],
            [126.761195798066, 37.695641978574],
            [126.761203636021, 37.695286202799],
            [126.761214183509, 37.694793485571],
            [126.761224044419, 37.694408072056],
            [126.761227335727, 37.694266536867],
            [126.761227187171, 37.694242300555],
            [126.761229361221, 37.694060489866],
            [126.761243312386, 37.693742657157],
            [126.761252076129, 37.693521487619],
            [126.761256943048, 37.6934186972],
            [126.761244389405, 37.692987198507],
            [126.76124435648, 37.692575726761],
            [126.761244503269, 37.692459952782],
            [126.761257584513, 37.692165633456],
            [126.761253604006, 37.692063996245],
            [126.76124785001, 37.691492951471],
            [126.761250009687, 37.691420968583],
            [126.761239882069, 37.691081012971],
            [126.761230782723, 37.690422387248],
            [126.761207922069, 37.689882480935],
            [126.761200393378, 37.689791287676],
            [126.761189805926, 37.689629091937],
            [126.761184906405, 37.689566374608],
            [126.761181020507, 37.688971728042],
            [126.761180809761, 37.688938662099],
            [126.761238215207, 37.688540371141],
            [126.761277744275, 37.688376565633],
            [126.761346533851, 37.688068935138],
            [126.761510834633, 37.687639688137],
            [126.761543796215, 37.687546919478],
            [126.761594582739, 37.687403985139],
            [126.7615973321, 37.687395161246],
            [126.761623989413, 37.687320164843],
            [126.76163886873, 37.687276408066],
            [126.761342733514, 37.687278598362],
            [126.761233326015, 37.687314774609],
            [126.760992249423, 37.687399604852],
            [126.760884491129, 37.687437541026],
            [126.760666840345, 37.68751417454],
            [126.759855679951, 37.687787490573],
            [126.759620506168, 37.687869086612],
            [126.75942965431, 37.687917797747],
            [126.759197045958, 37.687977234397],
            [126.759111146927, 37.68803526039],
            [126.758898578676, 37.688172401061],
            [126.758806429293, 37.688234828712],
            [126.758581180704, 37.688331489256],
            [126.758352676237, 37.688383454655],
            [126.757926266304, 37.688456093651],
            [126.757516080033, 37.688525070703],
            [126.757031534448, 37.688658581677],
            [126.756923127527, 37.688699531114],
            [126.75663725851, 37.688853363732],
            [126.756432263791, 37.68892636676],
            [126.756374626341, 37.688973908153],
            [126.756316777495, 37.689016764046],
            [126.756224832668, 37.689084956285],
            [126.75613662843, 37.689153336427],
            [126.756079454505, 37.689197725251],
            [126.755831568765, 37.6891778383],
            [126.755778863395, 37.689173584038],
            [126.755524382184, 37.689091065452],
            [126.755194767635, 37.688998388672],
            [126.755071999296, 37.688959120292],
            [126.75491972761, 37.688893481842],
            [126.754702158969, 37.688791667854],
            [126.754558094917, 37.68867856512],
            [126.754140478736, 37.688593809788],
            [126.754159901527, 37.688548892243],
            [126.751905846251, 37.687925448525],
            [126.751852836615, 37.687910830738],
            [126.745689891189, 37.689886058074],
            [126.74536990453, 37.689956801199],
            [126.744907362627, 37.690059064526],
            [126.744870691431, 37.69006717452],
            [126.744703765343, 37.690077622742],
            [126.74446139949, 37.690068085037],
            [126.744252116238, 37.690032490959],
            [126.744046167797, 37.68998338928],
            [126.743821992176, 37.68989397418],
            [126.743455107931, 37.689697391746],
            [126.743269412941, 37.689595085862],
            [126.742928118672, 37.689408810824],
            [126.741264604006, 37.688507679963],
            [126.739797981405, 37.687718646596],
            [126.739134107736, 37.687353991466],
            [126.73534037549, 37.685279751555],
            [126.733996187178, 37.684545664868],
            [126.733053584361, 37.684027989153],
            [126.732620586112, 37.683790318447],
            [126.732071846481, 37.683489133827],
            [126.731715797874, 37.683294764901],
            [126.731321226354, 37.683079368151],
            [126.731217961979, 37.683023001222],
            [126.730722976902, 37.682750764467],
            [126.730024675265, 37.682369761411],
            [126.72941439925, 37.682037699703],
            [126.729025097248, 37.681823361537],
            [126.729023709527, 37.68195480959],
            [126.729034112228, 37.682705970751],
            [126.729034756778, 37.683428548587],
            [126.729041681947, 37.68358082817],
            [126.729052287315, 37.684555609799],
            [126.729065865665, 37.685272451093],
            [126.729066350252, 37.685298039695],
            [126.729078823609, 37.686010193313],
            [126.729088284173, 37.68643024623],
            [126.729088446057, 37.686435580331],
            [126.729107838247, 37.687097367695],
            [126.729044247953, 37.687466166933],
            [126.728851519313, 37.688084325643],
            [126.728688145345, 37.688611283908],
            [126.728454785941, 37.689301245007],
            [126.7281635803, 37.69018198638],
            [126.72795110689, 37.690831721476],
            [126.727879281344, 37.69124852226],
            [126.727858536937, 37.691448038252],
            [126.727819768586, 37.692093941374]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281126',
      properties: {
        rgnCode: '41281126',
        colCode: '41280',
        rgnKo: ['행주외동'],
        rgnSize: 1,
        rgnBbox: [
          126.80193619076, 37.591297243135, 126.826260139509, 37.617886195964
        ],
        rgnCenter: [126.814747266606, 37.605746783285],
        rgnArea: 3205814,
        predVal: [
          0.39496, 0.40163, 0.3843, 0.43159, 0.54071, 0.49164, 0.53939, 0.56727,
          0.48852, 0.51429, 0.51256, 0.39245, 0.56409, 0.50139, 0.5702, 0.22766,
          0.5286, 0.48499, 0.62564, 0.54635, 0.5596, 0.56936, 0.49407, 0.55951,
          0.44788, 0.54579, 0.49886, 0.51365, 0.50219, 0.57521, 0.55288
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.82278494331, 37.591297243135],
            [126.822122973748, 37.591779175154],
            [126.820580839764, 37.59291144783],
            [126.818619488664, 37.594372865141],
            [126.815536906341, 37.596783745682],
            [126.81300751745, 37.59876982329],
            [126.81274127018, 37.598967992288],
            [126.812326835702, 37.599381600377],
            [126.811578422674, 37.600128105472],
            [126.810976690962, 37.600731009828],
            [126.810424212329, 37.601284175505],
            [126.809919837537, 37.601790601616],
            [126.807254789663, 37.604460493848],
            [126.806527261619, 37.605136841112],
            [126.806057012367, 37.60557412035],
            [126.805584704421, 37.60589178901],
            [126.805296887115, 37.605933332611],
            [126.804895407654, 37.605933889783],
            [126.804084197865, 37.605714588219],
            [126.802604017151, 37.605175127084],
            [126.802034858974, 37.605429072077],
            [126.802685354472, 37.606288795735],
            [126.804739267227, 37.609073899355],
            [126.804763600334, 37.609587453824],
            [126.804729740022, 37.610102262696],
            [126.804688715128, 37.610365911268],
            [126.80453146474, 37.610716229398],
            [126.804314089695, 37.611026164014],
            [126.803711089585, 37.611667706999],
            [126.803001921109, 37.612275506773],
            [126.8025838803, 37.612736356931],
            [126.802098616502, 37.613366187915],
            [126.80193619076, 37.613501663989],
            [126.802293524943, 37.613816475487],
            [126.802350849662, 37.614112445023],
            [126.802392323668, 37.614193981606],
            [126.802579910998, 37.614226462951],
            [126.802720636366, 37.614218618299],
            [126.80308310424, 37.614283369077],
            [126.803239705463, 37.61427682088],
            [126.803428512943, 37.614297869493],
            [126.803530563755, 37.61433117878],
            [126.803590803384, 37.614349831001],
            [126.803660770193, 37.614373517956],
            [126.803742305333, 37.614399864128],
            [126.803964117509, 37.614472359079],
            [126.804233768191, 37.614559115016],
            [126.804423504517, 37.614584794625],
            [126.804324574413, 37.614639003493],
            [126.803997475836, 37.614822454572],
            [126.80397707538, 37.614840313884],
            [126.804077186556, 37.614870790374],
            [126.804095146811, 37.614876262338],
            [126.804143065028, 37.614890271636],
            [126.804267295816, 37.614952809121],
            [126.80429583574, 37.614970795327],
            [126.804401241052, 37.615033904865],
            [126.804703849623, 37.615276917619],
            [126.804736942952, 37.615303497647],
            [126.804943513006, 37.615376208598],
            [126.804973352804, 37.615386718673],
            [126.805206808971, 37.615469195507],
            [126.80535386941, 37.615521111229],
            [126.805450484014, 37.615555220698],
            [126.805519487304, 37.615579859986],
            [126.805532157723, 37.615797071183],
            [126.805521799029, 37.615846977261],
            [126.805542834379, 37.615854742599],
            [126.805660492161, 37.615898157918],
            [126.8056954121, 37.615910847601],
            [126.805763950711, 37.615935756268],
            [126.805824546422, 37.615957543345],
            [126.805865724361, 37.615972351647],
            [126.805911450409, 37.615989113589],
            [126.805981877622, 37.616015187523],
            [126.805989696605, 37.616018074603],
            [126.80607649705, 37.616050311279],
            [126.806147444778, 37.616076620231],
            [126.806216896228, 37.616103404183],
            [126.806353705994, 37.616156166632],
            [126.806387651044, 37.616169259939],
            [126.806718740455, 37.616296935138],
            [126.806732555553, 37.616298147234],
            [126.806949894136, 37.616317273166],
            [126.807167161722, 37.616250571266],
            [126.807309306388, 37.616207828547],
            [126.807344386213, 37.616220175632],
            [126.807510574278, 37.616278643226],
            [126.807525002437, 37.616283667381],
            [126.807612316681, 37.616314696459],
            [126.807745587468, 37.61636123472],
            [126.807800266752, 37.616380560402],
            [126.807876514063, 37.616407687895],
            [126.807920499742, 37.616423220871],
            [126.808062222135, 37.616473484674],
            [126.808331203333, 37.61656972646],
            [126.808377404267, 37.616587163958],
            [126.808463505616, 37.616618839125],
            [126.808657585348, 37.616688892274],
            [126.808763344807, 37.616726870164],
            [126.808927178586, 37.616785575179],
            [126.80912940692, 37.616858487944],
            [126.80925292232, 37.616902630011],
            [126.809419743844, 37.616962591571],
            [126.809789573798, 37.617101042859],
            [126.809782501072, 37.61713825984],
            [126.809755936232, 37.617205988353],
            [126.80986807506, 37.6172346685],
            [126.809859352951, 37.617345240554],
            [126.81010075497, 37.61735921913],
            [126.81044189688, 37.617378521359],
            [126.810757651438, 37.617381888289],
            [126.810931681534, 37.617298837881],
            [126.811060105836, 37.617319722693],
            [126.811384063352, 37.617357608699],
            [126.811751268616, 37.617403455948],
            [126.811811268536, 37.61741124678],
            [126.811825807649, 37.617528847971],
            [126.811859787389, 37.617582420764],
            [126.812072665774, 37.61772309925],
            [126.812314605484, 37.617749030158],
            [126.812363008848, 37.617765127166],
            [126.812626008502, 37.617789703682],
            [126.812773823486, 37.61780686058],
            [126.812840287851, 37.617829491349],
            [126.812824477841, 37.617860063331],
            [126.812810219467, 37.617886195964],
            [126.813059943371, 37.617821589361],
            [126.813150103566, 37.617781234283],
            [126.813298035988, 37.617711401147],
            [126.813223380056, 37.617828949966],
            [126.813213900236, 37.617846675125],
            [126.813198018429, 37.61787433688],
            [126.813292002914, 37.617844601334],
            [126.815379949208, 37.617186684253],
            [126.815420779921, 37.617173693453],
            [126.816508129039, 37.616831751489],
            [126.816542407257, 37.616820930398],
            [126.817580002456, 37.61648728865],
            [126.817630433479, 37.616471230696],
            [126.818718894098, 37.616120368351],
            [126.818639968828, 37.615964259443],
            [126.818382616333, 37.615449229695],
            [126.818239649034, 37.615161153687],
            [126.818008124726, 37.614697248731],
            [126.817886312701, 37.614452605363],
            [126.817585179107, 37.613852155779],
            [126.81695245362, 37.612589570448],
            [126.817022081385, 37.612549828959],
            [126.817038400734, 37.612541160011],
            [126.817043414229, 37.612538140554],
            [126.816978259384, 37.612459040739],
            [126.816968077447, 37.612445284867],
            [126.816853246283, 37.612293425208],
            [126.817048819313, 37.612188577649],
            [126.817388135144, 37.612007163562],
            [126.81776540746, 37.611814311085],
            [126.817828722347, 37.611780505756],
            [126.817909172874, 37.611737555073],
            [126.818073112922, 37.611650036499],
            [126.818115050505, 37.611627640236],
            [126.818473945692, 37.6114360273],
            [126.818860634573, 37.611229572086],
            [126.818917724863, 37.611198144133],
            [126.819253493374, 37.611018647251],
            [126.819774369996, 37.610739831206],
            [126.819883827482, 37.610681120633],
            [126.819952765655, 37.610644142348],
            [126.819990934213, 37.610624956116],
            [126.820148744994, 37.610545633143],
            [126.820432539569, 37.610396821486],
            [126.820446398036, 37.610389130355],
            [126.820553556773, 37.610287781223],
            [126.820581822114, 37.610261047414],
            [126.820781554614, 37.610014863154],
            [126.820849317744, 37.609915417546],
            [126.820826837939, 37.609863396542],
            [126.820811948425, 37.609829190535],
            [126.820776136538, 37.609745263398],
            [126.820501735348, 37.609103722919],
            [126.820490939, 37.609081713416],
            [126.820354767495, 37.60880435374],
            [126.820275052105, 37.608644235157],
            [126.820269325007, 37.60863345964],
            [126.820249302863, 37.608591064766],
            [126.820206758917, 37.608504973755],
            [126.820154199699, 37.608429796236],
            [126.820079040296, 37.608347943721],
            [126.819843728415, 37.608122814005],
            [126.819816293075, 37.608097219948],
            [126.819788348955, 37.608071282729],
            [126.819757682464, 37.608042476191],
            [126.81965413584, 37.607940298597],
            [126.819543270598, 37.607834640849],
            [126.819542300918, 37.60783288244],
            [126.819355749123, 37.607653299422],
            [126.819240163246, 37.607542552566],
            [126.819233551083, 37.607514008283],
            [126.819225913387, 37.607506599431],
            [126.819034871361, 37.607320639026],
            [126.818951886128, 37.607239827827],
            [126.818585684536, 37.606885950043],
            [126.818279102574, 37.606591457257],
            [126.818276289363, 37.606588722917],
            [126.818270598268, 37.606595768763],
            [126.81826573982, 37.606591166216],
            [126.818304113368, 37.606515435251],
            [126.818332992508, 37.606459817457],
            [126.818266639894, 37.606441235327],
            [126.818257625857, 37.606436365042],
            [126.818179360551, 37.606394257764],
            [126.818087634197, 37.606345822644],
            [126.817941603394, 37.606242712545],
            [126.817802466777, 37.60614085635],
            [126.817783958828, 37.606137493908],
            [126.817779326272, 37.606133161986],
            [126.817773789944, 37.606127927676],
            [126.817785107934, 37.606130738337],
            [126.817823701999, 37.606140438912],
            [126.818012849988, 37.606269753672],
            [126.818027582292, 37.606265722162],
            [126.818125095039, 37.606346655703],
            [126.818146910213, 37.60635913215],
            [126.81818981913, 37.60638272343],
            [126.81827236238, 37.606430621643],
            [126.818337148499, 37.6064414529],
            [126.818374977067, 37.606426276013],
            [126.818390169004, 37.606419542227],
            [126.818455954404, 37.606406174613],
            [126.818797160035, 37.606333579713],
            [126.818921969051, 37.606307040758],
            [126.819411005515, 37.606184884473],
            [126.819401908673, 37.606167508527],
            [126.819590143287, 37.606124299454],
            [126.819794976486, 37.606077872105],
            [126.819956221399, 37.606040161923],
            [126.82003205968, 37.606093292305],
            [126.820073112906, 37.605995184501],
            [126.820082658143, 37.605949231107],
            [126.820087843217, 37.605926624446],
            [126.820102401485, 37.605947549591],
            [126.820121583911, 37.60597632038],
            [126.820136590161, 37.605999318467],
            [126.820171893214, 37.60605856719],
            [126.820217951223, 37.606119364091],
            [126.820256341622, 37.60611978345],
            [126.820219161553, 37.606087291015],
            [126.820147509695, 37.605979693855],
            [126.820142887731, 37.605970947233],
            [126.820124428762, 37.605924067835],
            [126.820108854321, 37.605901879756],
            [126.820096101029, 37.605883840526],
            [126.820105410066, 37.605855861344],
            [126.820102288172, 37.605779696591],
            [126.820100437332, 37.605735077148],
            [126.820272266433, 37.605577507225],
            [126.820411312689, 37.605451330999],
            [126.820694971651, 37.605364352709],
            [126.821201927661, 37.605199347728],
            [126.821159355549, 37.60524252979],
            [126.821086067246, 37.60529647666],
            [126.82100735628, 37.605391770238],
            [126.820953655414, 37.605447458845],
            [126.820900251542, 37.605473956083],
            [126.820897649432, 37.605519721958],
            [126.820914459995, 37.605545948222],
            [126.820932624447, 37.605574284852],
            [126.820890947055, 37.60562197309],
            [126.820854039531, 37.60566408253],
            [126.820834171026, 37.605684774687],
            [126.820797002288, 37.605741209312],
            [126.820803786222, 37.605886187603],
            [126.820805164075, 37.605925202205],
            [126.820796126255, 37.606009700417],
            [126.820819440154, 37.606156505973],
            [126.820843165932, 37.606179877711],
            [126.820835767754, 37.606129618658],
            [126.820816982927, 37.606001893814],
            [126.82082161355, 37.605960275565],
            [126.820825203794, 37.605927395243],
            [126.820825564536, 37.605871895343],
            [126.820817389329, 37.605740249448],
            [126.820886554534, 37.605658816507],
            [126.82092653345, 37.605611035574],
            [126.820960604704, 37.60557099403],
            [126.820936160634, 37.605540314254],
            [126.820920636099, 37.605520838311],
            [126.820947666579, 37.60548880473],
            [126.821020890686, 37.605414675866],
            [126.821108594113, 37.605300385316],
            [126.821175172104, 37.605258321134],
            [126.821223108585, 37.605197848417],
            [126.821246440553, 37.605196622683],
            [126.821294632344, 37.605171108408],
            [126.821312882741, 37.605163838324],
            [126.821960310596, 37.604988331801],
            [126.821989181082, 37.604982059839],
            [126.822002097505, 37.604979466639],
            [126.822617216371, 37.604802456777],
            [126.822764877601, 37.60476051457],
            [126.822766821398, 37.604662815073],
            [126.822768225142, 37.604592036086],
            [126.822987643939, 37.604533786369],
            [126.822998352251, 37.604531063582],
            [126.823118605588, 37.604499224524],
            [126.823130367639, 37.604496269063],
            [126.823181838453, 37.604481931141],
            [126.823220592283, 37.604472078893],
            [126.823379905971, 37.60442997332],
            [126.823419978957, 37.604489048092],
            [126.823492856988, 37.604515935214],
            [126.823515612697, 37.604514059454],
            [126.823568756261, 37.604510589724],
            [126.823729302204, 37.60446933246],
            [126.823741519024, 37.604479892347],
            [126.823764453017, 37.604498270875],
            [126.823812389543, 37.604536670818],
            [126.823887224004, 37.604593365114],
            [126.823898193257, 37.604599958788],
            [126.823911982961, 37.604611061613],
            [126.823973130839, 37.604656202693],
            [126.82399643003, 37.604673446488],
            [126.824091153587, 37.604743324898],
            [126.824173634808, 37.604775253513],
            [126.824194857898, 37.604755914297],
            [126.824099596081, 37.604721894228],
            [126.824044868464, 37.604686583567],
            [126.824021575466, 37.604666726945],
            [126.823999094858, 37.604648132914],
            [126.823939617662, 37.604605246815],
            [126.823929115926, 37.604597302376],
            [126.823914749951, 37.604585658096],
            [126.823851461416, 37.604535918757],
            [126.823796682309, 37.604493796482],
            [126.823756485426, 37.604462868317],
            [126.823834458672, 37.604442551466],
            [126.824026051262, 37.604393961493],
            [126.824166912645, 37.604358251104],
            [126.824587318406, 37.604252494163],
            [126.824612338843, 37.604245873414],
            [126.82474019341, 37.604054597366],
            [126.824755601582, 37.604037393673],
            [126.824797676957, 37.603992389575],
            [126.824758612514, 37.603966193598],
            [126.824671571426, 37.603990281599],
            [126.824636004367, 37.604003175408],
            [126.824540636017, 37.604000104254],
            [126.824556595567, 37.603955718795],
            [126.824599200625, 37.603868792883],
            [126.824602309896, 37.603851768989],
            [126.82462310202, 37.603722536304],
            [126.824613250354, 37.603688770762],
            [126.824431631446, 37.603677542464],
            [126.824387165571, 37.603675196255],
            [126.824406946012, 37.603566810805],
            [126.824460797158, 37.603403048065],
            [126.824221944467, 37.603387273712],
            [126.824070195029, 37.603363980527],
            [126.823994566805, 37.603308186125],
            [126.82394468189, 37.6032131476],
            [126.823909934158, 37.603162550237],
            [126.82382731811, 37.603064308966],
            [126.823631562644, 37.602950670577],
            [126.823601357231, 37.602699971893],
            [126.823611328052, 37.602483715226],
            [126.823842901678, 37.602306696753],
            [126.82388557092, 37.602235592413],
            [126.823925129112, 37.602172664292],
            [126.823949695924, 37.60212793146],
            [126.824151558536, 37.601938938663],
            [126.824229349397, 37.601913197353],
            [126.824349247291, 37.601776148816],
            [126.824464388767, 37.601643796145],
            [126.824444794016, 37.601597636468],
            [126.824360188058, 37.601402978466],
            [126.824400332429, 37.601345601106],
            [126.824529089852, 37.601158525119],
            [126.824624490952, 37.600983607305],
            [126.824820803012, 37.60096570152],
            [126.825100896763, 37.600849353214],
            [126.82523438895, 37.600713414256],
            [126.825170531537, 37.600517084935],
            [126.825216099425, 37.600449939733],
            [126.825242756279, 37.600392316683],
            [126.825282574386, 37.600304710569],
            [126.825313510386, 37.600252409686],
            [126.825329881216, 37.600172336799],
            [126.825225464989, 37.599984145878],
            [126.825146194619, 37.59984131185],
            [126.825134163175, 37.599613345333],
            [126.825143225293, 37.599324242632],
            [126.825060867322, 37.599285647585],
            [126.825475501433, 37.598829288427],
            [126.825586660895, 37.598798280172],
            [126.825645088521, 37.598703394679],
            [126.825635432325, 37.598471206098],
            [126.825574236166, 37.59796736651],
            [126.825565936344, 37.597837342342],
            [126.825634998718, 37.597649950778],
            [126.825697536607, 37.597540304258],
            [126.826025765518, 37.597076155923],
            [126.826162653489, 37.596937157527],
            [126.82618813659, 37.596790154987],
            [126.826232075209, 37.596740395895],
            [126.826260139509, 37.596506632517],
            [126.826248518242, 37.596488775801],
            [126.826168268672, 37.596377925814],
            [126.826192283394, 37.596036218667],
            [126.825995409083, 37.595923123006],
            [126.825971256338, 37.595841187735],
            [126.825836919899, 37.595372386607],
            [126.825743786358, 37.595145957048],
            [126.825740035174, 37.595128057938],
            [126.825760215965, 37.594727258623],
            [126.825766545003, 37.594390480539],
            [126.825453985952, 37.593975229094],
            [126.82540431395, 37.593431294448],
            [126.82535696735, 37.59291950128],
            [126.825301751533, 37.592335878907],
            [126.825284205489, 37.592146772311],
            [126.824196926849, 37.59177798418],
            [126.823148897735, 37.591421030204],
            [126.82278494331, 37.591297243135]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281130',
      properties: {
        rgnCode: '41281130',
        colCode: '41280',
        rgnKo: ['현천동'],
        rgnSize: 1,
        rgnBbox: [
          126.82278494331, 37.578520500296, 126.87529183709, 37.602970127692
        ],
        rgnCenter: [126.850330736804, 37.591313408646],
        rgnArea: 6122929,
        predVal: [
          0.39496, 0.40163, 0.3843, 0.43159, 0.54071, 0.49164, 0.53939, 0.56727,
          0.48852, 0.51429, 0.51256, 0.39245, 0.56409, 0.50139, 0.5702, 0.22766,
          0.5286, 0.48499, 0.62564, 0.54635, 0.5596, 0.56936, 0.49407, 0.55951,
          0.44788, 0.54579, 0.49886, 0.51365, 0.50219, 0.57521, 0.55288
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.82278494331, 37.591297243135],
            [126.823148897735, 37.591421030204],
            [126.824196926849, 37.59177798418],
            [126.825284205489, 37.592146772311],
            [126.827596996018, 37.592220425333],
            [126.828349985696, 37.592244399363],
            [126.830945242205, 37.592327545821],
            [126.831116626018, 37.592371491059],
            [126.831518676939, 37.592465971284],
            [126.831535825059, 37.593011253185],
            [126.831546573727, 37.593328477907],
            [126.831546541482, 37.593362571073],
            [126.83150826403, 37.593907682972],
            [126.831481668074, 37.594165641236],
            [126.831458691132, 37.594350805249],
            [126.831434783916, 37.594486945412],
            [126.831280186963, 37.595377641742],
            [126.831414294544, 37.595698341797],
            [126.83157149272, 37.596073332128],
            [126.831632940899, 37.596199531176],
            [126.831705622026, 37.596350685558],
            [126.831786523844, 37.596511996781],
            [126.831794841633, 37.596524144982],
            [126.83190674016, 37.596653768066],
            [126.831965840234, 37.596722363717],
            [126.83213029165, 37.596867000362],
            [126.832459817638, 37.596911702047],
            [126.832515132021, 37.596912123633],
            [126.832734168003, 37.596914761585],
            [126.832816908104, 37.596915105117],
            [126.833314482178, 37.596821094898],
            [126.833794820726, 37.596727058088],
            [126.834030673152, 37.596688578647],
            [126.834141651297, 37.596669797409],
            [126.83432074408, 37.596639093458],
            [126.834364841436, 37.596627280959],
            [126.83470054534, 37.596537261157],
            [126.834980171152, 37.596459027235],
            [126.835231863412, 37.596385014949],
            [126.835516940458, 37.596299075017],
            [126.835610389144, 37.596270032574],
            [126.835799254608, 37.596212833192],
            [126.836234795439, 37.596101343569],
            [126.8365947691, 37.59599213438],
            [126.837085365846, 37.595827254188],
            [126.83742226103, 37.595733047724],
            [126.837661411559, 37.595670257077],
            [126.838059177345, 37.595582169862],
            [126.838404419443, 37.595532832099],
            [126.838832210924, 37.595554919949],
            [126.839114724048, 37.595572067281],
            [126.839316458692, 37.595650955454],
            [126.839472968522, 37.595714113106],
            [126.839719768365, 37.595804595032],
            [126.839893368108, 37.595824798656],
            [126.840024898221, 37.595841376579],
            [126.840234866223, 37.595836510042],
            [126.840379480421, 37.595813768523],
            [126.840597895514, 37.595735293538],
            [126.840816503858, 37.59565098905],
            [126.841023059568, 37.595484254833],
            [126.841108848423, 37.595403454148],
            [126.841325969398, 37.595082593534],
            [126.841492462744, 37.594920281987],
            [126.841588781861, 37.594828503223],
            [126.84180994784, 37.594621225127],
            [126.841880835555, 37.594555405077],
            [126.842267200409, 37.594190515144],
            [126.84232277697, 37.594109465565],
            [126.842328075861, 37.593710922675],
            [126.842309214284, 37.593470523844],
            [126.842288133559, 37.593214165587],
            [126.842267374617, 37.592913136982],
            [126.842328359688, 37.592801542323],
            [126.842375234556, 37.59271734554],
            [126.842541809817, 37.592518290437],
            [126.842976324122, 37.592165914476],
            [126.843022877806, 37.591997709268],
            [126.843028108545, 37.591805662588],
            [126.843024816638, 37.591558626425],
            [126.843026194312, 37.591474935886],
            [126.843054148172, 37.591219463401],
            [126.843145850054, 37.591118045586],
            [126.843272124377, 37.591030432075],
            [126.843391189874, 37.591015869558],
            [126.843597163071, 37.591024938903],
            [126.843786418704, 37.59106877254],
            [126.844041351339, 37.591126109162],
            [126.844183158917, 37.591183889153],
            [126.844320920505, 37.591254349438],
            [126.844601474654, 37.591480166913],
            [126.844698795138, 37.591646509748],
            [126.844786322779, 37.59180702815],
            [126.844929349868, 37.592353691664],
            [126.845013699726, 37.59266976951],
            [126.845024424424, 37.592771585875],
            [126.845092436827, 37.593428168732],
            [126.845185858078, 37.593912165707],
            [126.845181874283, 37.594024918514],
            [126.845164356695, 37.594442041639],
            [126.845128146356, 37.594733732092],
            [126.845084508162, 37.595090112287],
            [126.845006292183, 37.59549326169],
            [126.844791465207, 37.595828692],
            [126.844594160678, 37.59593426835],
            [126.844288198642, 37.595990370851],
            [126.843896213614, 37.596013706086],
            [126.843462634328, 37.596065347278],
            [126.843319089401, 37.596139486085],
            [126.84329925151, 37.596214033869],
            [126.84326463313, 37.596439899964],
            [126.843299572638, 37.596549046845],
            [126.843396541427, 37.596829814987],
            [126.843507790058, 37.596965931314],
            [126.843664736742, 37.59715225737],
            [126.843810731688, 37.597259345148],
            [126.843996651731, 37.597367504143],
            [126.84419813935, 37.59747166521],
            [126.844487719741, 37.597551490486],
            [126.844694756202, 37.59760213069],
            [126.844816858987, 37.597623610113],
            [126.844897377101, 37.597609373333],
            [126.845024226266, 37.597559744257],
            [126.84513885969, 37.597438533785],
            [126.845203597869, 37.597291795352],
            [126.845277170478, 37.597116867807],
            [126.845319508319, 37.597025194761],
            [126.84536740168, 37.596751296216],
            [126.845365119449, 37.596629633453],
            [126.845308933579, 37.5964882309],
            [126.845237363186, 37.596296280815],
            [126.845233682394, 37.596172075416],
            [126.845253352888, 37.596133332154],
            [126.845305167789, 37.59605252849],
            [126.845459485219, 37.595997150968],
            [126.845678835415, 37.595940372501],
            [126.8460106306, 37.595897797159],
            [126.846242544344, 37.5958868582],
            [126.846385013559, 37.595898361882],
            [126.846655296626, 37.595917385177],
            [126.846721140253, 37.595956475109],
            [126.846918143236, 37.596066391968],
            [126.846963472307, 37.596098481283],
            [126.847097815942, 37.596212117928],
            [126.847295719789, 37.596412845423],
            [126.847349298764, 37.596518834994],
            [126.847351904905, 37.596528965448],
            [126.847377380498, 37.596672750575],
            [126.847378154605, 37.596879365026],
            [126.847360707824, 37.596949393674],
            [126.847334949869, 37.596947918458],
            [126.847243586092, 37.597252322332],
            [126.847150404257, 37.597438812101],
            [126.847066061131, 37.597616555801],
            [126.846639220978, 37.59777326306],
            [126.846261582981, 37.59791780716],
            [126.846105901627, 37.598159119749],
            [126.846109921461, 37.598398723408],
            [126.846118995435, 37.59860387112],
            [126.846256506907, 37.59890025017],
            [126.846360953168, 37.599016974681],
            [126.846607891454, 37.599219290348],
            [126.84691210429, 37.599346448475],
            [126.847319297734, 37.59947358723],
            [126.847511450472, 37.599485785137],
            [126.847774201817, 37.599330284707],
            [126.848236355434, 37.599019425071],
            [126.848410788994, 37.59895800623],
            [126.848674891755, 37.598926111358],
            [126.848945009514, 37.599048381821],
            [126.849104430634, 37.599229532152],
            [126.849189636045, 37.599466087039],
            [126.8491543045, 37.599672042114],
            [126.849037109845, 37.599873431579],
            [126.8489779863, 37.600140244251],
            [126.84896744851, 37.600187784492],
            [126.849013496984, 37.600290718189],
            [126.849174563295, 37.600443904018],
            [126.849307338256, 37.600504792531],
            [126.84953486407, 37.6006091402],
            [126.849825480426, 37.600862401828],
            [126.849776267103, 37.601252094567],
            [126.849720748707, 37.601484224363],
            [126.849649898007, 37.601598837287],
            [126.84949252456, 37.601849648371],
            [126.849412022777, 37.60202647956],
            [126.849449011658, 37.602180414997],
            [126.849694849649, 37.602331582944],
            [126.84991221145, 37.602369379244],
            [126.850176163695, 37.602294440926],
            [126.850447953063, 37.602045280091],
            [126.850466126594, 37.601917417921],
            [126.850419078294, 37.601708239697],
            [126.850412989906, 37.6014598671],
            [126.850458865809, 37.601235049623],
            [126.850674485046, 37.600956678541],
            [126.85075095169, 37.600880670251],
            [126.850934486875, 37.600698239358],
            [126.851069748514, 37.6005936456],
            [126.851308778351, 37.600407068239],
            [126.851459197015, 37.600203367212],
            [126.851635724665, 37.599877111445],
            [126.85184475724, 37.599728336149],
            [126.852227441013, 37.599670347028],
            [126.852504156241, 37.599770696774],
            [126.85264582049, 37.599860775592],
            [126.852821220332, 37.600086846015],
            [126.852870970643, 37.600255590647],
            [126.852784925757, 37.600434453434],
            [126.852489962325, 37.600704322081],
            [126.852212933967, 37.601008296833],
            [126.852072203354, 37.601345140226],
            [126.852048214622, 37.601590600528],
            [126.85212370202, 37.601862369315],
            [126.852286064684, 37.602074440711],
            [126.852531826006, 37.602255407153],
            [126.852691935292, 37.602258059822],
            [126.852916684442, 37.602118060057],
            [126.853101164579, 37.602051682556],
            [126.853184511251, 37.602046030055],
            [126.853306706914, 37.602037002553],
            [126.853571575768, 37.602074464341],
            [126.853769816223, 37.602127330034],
            [126.853900305322, 37.602196526575],
            [126.854035514682, 37.602335690146],
            [126.85412045707, 37.602474178126],
            [126.854133601098, 37.602527640808],
            [126.854272261686, 37.602496324546],
            [126.854441384461, 37.602459360885],
            [126.854711671558, 37.602374203176],
            [126.854794539914, 37.602364278287],
            [126.855145147316, 37.602332359341],
            [126.85554334616, 37.602378045771],
            [126.855658855247, 37.602360222987],
            [126.855973739529, 37.602238267311],
            [126.856374623775, 37.602189900702],
            [126.856519367555, 37.602226748592],
            [126.856720071152, 37.602365575081],
            [126.857047168019, 37.602585689461],
            [126.857481673909, 37.602884697924],
            [126.857691493975, 37.602954626752],
            [126.858205571462, 37.602969676807],
            [126.858213316507, 37.602970127692],
            [126.858281108575, 37.602861704691],
            [126.858661237037, 37.602683050121],
            [126.858699989193, 37.602617829861],
            [126.858852745105, 37.602445116096],
            [126.858899163753, 37.602391653844],
            [126.858988900833, 37.602306979804],
            [126.859056628042, 37.602243542315],
            [126.859137739941, 37.60216849826],
            [126.859233381228, 37.602134826757],
            [126.859322874023, 37.602088894407],
            [126.859462128529, 37.60198783672],
            [126.859480545908, 37.60197376752],
            [126.859563968757, 37.601909844073],
            [126.85967006263, 37.601822062769],
            [126.859888097442, 37.601638145771],
            [126.86002180164, 37.601525043628],
            [126.860092739184, 37.60146379877],
            [126.860158696592, 37.601407151925],
            [126.860177651098, 37.601390353281],
            [126.860238053134, 37.601337087433],
            [126.860380446068, 37.601341672605],
            [126.860505159233, 37.601263706484],
            [126.860634463254, 37.601183177911],
            [126.860702020542, 37.601178573421],
            [126.860786430384, 37.601232913246],
            [126.86089912888, 37.601304909935],
            [126.861012439048, 37.601376934274],
            [126.861060639553, 37.601398498096],
            [126.861176667374, 37.601452677046],
            [126.861233240425, 37.601448059192],
            [126.861267116311, 37.60137289444],
            [126.861309702915, 37.601278296821],
            [126.861345762431, 37.601198044087],
            [126.861389663799, 37.601096681619],
            [126.861425877703, 37.601048747286],
            [126.861495257909, 37.601038152972],
            [126.861552221801, 37.601060474806],
            [126.861672907347, 37.601112694653],
            [126.861798534159, 37.601168956627],
            [126.861891881068, 37.601210368133],
            [126.862035091721, 37.601274263957],
            [126.862091982765, 37.60129939651],
            [126.862183768039, 37.601340391487],
            [126.862379010807, 37.601339036082],
            [126.862588839219, 37.601337625445],
            [126.86261859924, 37.601331497751],
            [126.862685148596, 37.601314754705],
            [126.862695868328, 37.601310956157],
            [126.862896502114, 37.601254240859],
            [126.863166831034, 37.601181758651],
            [126.86376730379, 37.601023212312],
            [126.863963269453, 37.600967039348],
            [126.864199659558, 37.600899831009],
            [126.864315730357, 37.600838924418],
            [126.864438654812, 37.600696270506],
            [126.864537192427, 37.600581410508],
            [126.86463425054, 37.600513679113],
            [126.865088082468, 37.600384733353],
            [126.865330786264, 37.600337405716],
            [126.865402447822, 37.600323279882],
            [126.865380966698, 37.600310542241],
            [126.865372769203, 37.600309938137],
            [126.86532667906, 37.600285828725],
            [126.865274584859, 37.600256802009],
            [126.865244492775, 37.600246667253],
            [126.865231525163, 37.600173051],
            [126.865239227163, 37.60016592411],
            [126.865236287393, 37.600070344464],
            [126.864856508502, 37.599442217653],
            [126.864519118567, 37.598901867476],
            [126.864574389279, 37.59848216365],
            [126.864595924885, 37.598285594139],
            [126.864785494032, 37.598131574607],
            [126.864885276739, 37.598021040426],
            [126.865615394637, 37.59774034414],
            [126.866244621256, 37.597499324163],
            [126.866608481958, 37.597355394094],
            [126.866740800064, 37.597304018027],
            [126.86676052819, 37.597296373185],
            [126.866772531524, 37.59728989981],
            [126.86687975829, 37.597237467819],
            [126.86716301174, 37.597227906795],
            [126.86751299081, 37.597214285351],
            [126.867533918206, 37.597213471194],
            [126.867868834416, 37.597131546076],
            [126.867944067223, 37.597113007914],
            [126.867959147312, 37.597101942885],
            [126.867972171605, 37.59708802842],
            [126.868016490523, 37.597057129781],
            [126.868104332223, 37.59700990946],
            [126.868138335432, 37.5969916309],
            [126.868355210193, 37.596892605435],
            [126.868639477787, 37.596719432452],
            [126.868797324274, 37.596619042286],
            [126.868882636385, 37.596567385699],
            [126.868935471325, 37.59651651247],
            [126.869110797005, 37.596296599039],
            [126.869396601475, 37.596148527334],
            [126.869862009023, 37.596486564755],
            [126.869885344462, 37.596513521173],
            [126.869906854289, 37.596541926135],
            [126.870112047394, 37.596805521191],
            [126.869950147189, 37.596919495423],
            [126.869844539929, 37.597088942713],
            [126.870232034927, 37.597480130783],
            [126.870398757991, 37.597599489157],
            [126.870890117837, 37.597874914929],
            [126.871057538711, 37.597971874697],
            [126.871102083972, 37.597953597927],
            [126.871201772462, 37.598053924387],
            [126.871402317416, 37.597967966595],
            [126.871504461001, 37.597926588877],
            [126.871693071626, 37.597820219184],
            [126.871815520105, 37.597764664032],
            [126.871821991858, 37.59778733087],
            [126.87217173875, 37.597594868215],
            [126.872270008869, 37.597540313212],
            [126.872337732844, 37.597482823533],
            [126.87240904224, 37.597427680292],
            [126.87248011058, 37.597400593363],
            [126.872649604184, 37.597379650481],
            [126.872767055724, 37.597351433723],
            [126.872999090231, 37.597257497808],
            [126.873228767408, 37.597171000995],
            [126.873404854917, 37.597134324015],
            [126.87355684088, 37.597093881565],
            [126.873659649081, 37.597046520165],
            [126.874032340222, 37.596920605428],
            [126.874147399372, 37.596906782423],
            [126.874334349573, 37.596914020693],
            [126.874485252465, 37.596890658523],
            [126.874658873088, 37.596832254305],
            [126.874794146398, 37.596877367695],
            [126.87479631352, 37.596861458655],
            [126.874790439206, 37.596826548254],
            [126.874738224627, 37.596627446935],
            [126.874683124597, 37.596413971801],
            [126.875188342241, 37.596012197465],
            [126.87529183709, 37.595932237785],
            [126.874689963207, 37.595627123248],
            [126.87452177629, 37.595148348947],
            [126.874440492252, 37.594931998364],
            [126.874375878527, 37.594774301693],
            [126.874346320765, 37.594716904221],
            [126.874329010126, 37.594628940552],
            [126.874303896687, 37.594601541604],
            [126.874079097293, 37.594356222532],
            [126.874124202022, 37.594008040999],
            [126.874168623487, 37.593595438346],
            [126.87417105537, 37.593563717329],
            [126.874167624189, 37.593377840655],
            [126.874164337214, 37.593313335673],
            [126.87412919831, 37.59313616432],
            [126.873950305838, 37.592937925842],
            [126.873720385257, 37.592645127641],
            [126.873695012939, 37.592590860932],
            [126.873688866053, 37.592537065531],
            [126.873665977845, 37.592341256914],
            [126.873664519191, 37.592267284545],
            [126.873799337848, 37.592089576242],
            [126.87379198493, 37.592078918655],
            [126.873735296295, 37.59199679567],
            [126.873742502383, 37.591921129816],
            [126.873753564221, 37.591841936277],
            [126.873773859068, 37.591693566157],
            [126.873842783487, 37.591579125643],
            [126.873642361016, 37.591452951104],
            [126.87359847626, 37.591425333439],
            [126.873527785864, 37.591314615953],
            [126.873296293467, 37.590951898769],
            [126.873202206548, 37.590804485603],
            [126.87319191774, 37.590400202764],
            [126.873185027607, 37.590126746523],
            [126.873183461251, 37.590063045225],
            [126.873347265585, 37.589817524753],
            [126.873295799664, 37.589774491902],
            [126.872884849613, 37.589414011408],
            [126.872867062328, 37.589354707244],
            [126.872863459491, 37.589342900421],
            [126.872861770599, 37.589337402575],
            [126.872704906118, 37.588781504242],
            [126.872761972606, 37.58851730772],
            [126.87272062632, 37.5883252718],
            [126.872577828138, 37.587945981556],
            [126.872252183927, 37.587701638741],
            [126.872240988452, 37.587693878012],
            [126.87223351639, 37.587687022345],
            [126.87210582219, 37.587486233118],
            [126.871618081922, 37.587412348229],
            [126.871348714848, 37.587485941261],
            [126.871011539699, 37.587228509004],
            [126.870963372233, 37.587191605463],
            [126.870950708411, 37.58718195092],
            [126.870946411552, 37.58717879272],
            [126.870943810951, 37.587176807677],
            [126.870900030493, 37.587143341715],
            [126.870721491044, 37.587041125513],
            [126.870305305039, 37.587215256469],
            [126.870283190827, 37.587235891467],
            [126.870020704687, 37.587478081389],
            [126.869498329167, 37.587758578528],
            [126.86932603437, 37.587793983614],
            [126.869005894971, 37.587701634132],
            [126.868676814486, 37.587429437168],
            [126.868284959983, 37.58747882],
            [126.867935505254, 37.587522393117],
            [126.867531837557, 37.587687302356],
            [126.867197838707, 37.588057083315],
            [126.86628074724, 37.588348784123],
            [126.865404945717, 37.588432650249],
            [126.865016097047, 37.588642400928],
            [126.864745381766, 37.588580550326],
            [126.864212777399, 37.588398744023],
            [126.863938880214, 37.58803649961],
            [126.863435915067, 37.587730298351],
            [126.863308572184, 37.587652304249],
            [126.86296286654, 37.587308263424],
            [126.862854500288, 37.586888637686],
            [126.862787295145, 37.586625110771],
            [126.862563255577, 37.586369688357],
            [126.862058719609, 37.586022394397],
            [126.861860974613, 37.58588764357],
            [126.861371106736, 37.585389440009],
            [126.861334494053, 37.585352194866],
            [126.860662191066, 37.585361125211],
            [126.860496157795, 37.585354926419],
            [126.860055122074, 37.585179571594],
            [126.859598689228, 37.585386790876],
            [126.859265434702, 37.585415671767],
            [126.859105617616, 37.58544485118],
            [126.858729903064, 37.585771635041],
            [126.858520966776, 37.585797060197],
            [126.858442095972, 37.586129878536],
            [126.858085843286, 37.586524437946],
            [126.857722777802, 37.586326860769],
            [126.856928044574, 37.586182182926],
            [126.856910806873, 37.586255411876],
            [126.856980158337, 37.586457857996],
            [126.856975208913, 37.586499747727],
            [126.856969221173, 37.586551637118],
            [126.856959220051, 37.586559481458],
            [126.856882865611, 37.586623465965],
            [126.856854949788, 37.586638892656],
            [126.856848528244, 37.58665162471],
            [126.856824517904, 37.586655649714],
            [126.856793523359, 37.586653079951],
            [126.856779370073, 37.586647665699],
            [126.856660894295, 37.586602381037],
            [126.856563099574, 37.586565140418],
            [126.856324573437, 37.586475343451],
            [126.856187976162, 37.5864278196],
            [126.85609599943, 37.586397397201],
            [126.85592754488, 37.586360321262],
            [126.855738438086, 37.586277440543],
            [126.855573767828, 37.586221772446],
            [126.855460610991, 37.586195792247],
            [126.855358625267, 37.586183268466],
            [126.85513840662, 37.586204682299],
            [126.854978442847, 37.58621460189],
            [126.854859042927, 37.58618536982],
            [126.854834124572, 37.58616749035],
            [126.854795931981, 37.586134854266],
            [126.854786289527, 37.58612715688],
            [126.854786633176, 37.586119354779],
            [126.854791106964, 37.586112747111],
            [126.854770126529, 37.586095953704],
            [126.854755437138, 37.586075392989],
            [126.854712421774, 37.586042624743],
            [126.85460466477, 37.585965438462],
            [126.854540044939, 37.585905154216],
            [126.854496037098, 37.585864771332],
            [126.854364623132, 37.585761949375],
            [126.854310312588, 37.585715832324],
            [126.854224374832, 37.585632266897],
            [126.854144809801, 37.585561016816],
            [126.854069493899, 37.585528271073],
            [126.853942738981, 37.585468990051],
            [126.853766891006, 37.585382113357],
            [126.853354880598, 37.585147935522],
            [126.853307740138, 37.585123468664],
            [126.85319757407, 37.585046440241],
            [126.853092379926, 37.584980076619],
            [126.852894524833, 37.584887449693],
            [126.852731875672, 37.584808696932],
            [126.852618992461, 37.584743539524],
            [126.852461672896, 37.584677902444],
            [126.85243324676, 37.584665270777],
            [126.852403833083, 37.584653926264],
            [126.85237239394, 37.584642029585],
            [126.852172280707, 37.584552867383],
            [126.85204239122, 37.584488742058],
            [126.852017286137, 37.584479934672],
            [126.851722922056, 37.584396058217],
            [126.851670452097, 37.584336715819],
            [126.851611722708, 37.584265418386],
            [126.851590776492, 37.584237821642],
            [126.851428644749, 37.583998593284],
            [126.851412151793, 37.583974768287],
            [126.85139410993, 37.583955518323],
            [126.851370688843, 37.583931378174],
            [126.851325976839, 37.583882262657],
            [126.851286664942, 37.583850209643],
            [126.851135645807, 37.583726987837],
            [126.851101075989, 37.583701454923],
            [126.851084318105, 37.583685594251],
            [126.85101282432, 37.583583601702],
            [126.850984931781, 37.583542445175],
            [126.850901796416, 37.583419651983],
            [126.850868823377, 37.583365911192],
            [126.850779807528, 37.583211927308],
            [126.850631487396, 37.583126792938],
            [126.850536431047, 37.583077171273],
            [126.850449201645, 37.583031307647],
            [126.850325949064, 37.582990344234],
            [126.850249978022, 37.582981345175],
            [126.850097400223, 37.582986582505],
            [126.849937903584, 37.582992531541],
            [126.849876839417, 37.582985236225],
            [126.849664914684, 37.582951510223],
            [126.849434555392, 37.582880558412],
            [126.849274712646, 37.582884208595],
            [126.849109455493, 37.582922539502],
            [126.848955668332, 37.582937900869],
            [126.848757784498, 37.582914363507],
            [126.848425763157, 37.58294701757],
            [126.848206906152, 37.583009270926],
            [126.848077645264, 37.583044520711],
            [126.847792534986, 37.583134185231],
            [126.847739748419, 37.583164668887],
            [126.84766366335, 37.583205645663],
            [126.847588171647, 37.583233324623],
            [126.847446778708, 37.583288019175],
            [126.847219280542, 37.58332885201],
            [126.846971967315, 37.583524132365],
            [126.846867389935, 37.583573801866],
            [126.846715022989, 37.583596802525],
            [126.846564601553, 37.583631545376],
            [126.846558115757, 37.583636321093],
            [126.846493345469, 37.58364723708],
            [126.846470106485, 37.583661658361],
            [126.846308358349, 37.583753319255],
            [126.84623378881, 37.583794378185],
            [126.846205423901, 37.583807441193],
            [126.846161881535, 37.583801887888],
            [126.846130946557, 37.583787746756],
            [126.846057190984, 37.583736753002],
            [126.8460545406, 37.583704638404],
            [126.846071690101, 37.583667765648],
            [126.846216230364, 37.583559423226],
            [126.846375491826, 37.583372912483],
            [126.846482802858, 37.583255249658],
            [126.846698818005, 37.583046504596],
            [126.846933732325, 37.582769633358],
            [126.846940463277, 37.582690040126],
            [126.846826923208, 37.582612559835],
            [126.846771249718, 37.582588322443],
            [126.846686891273, 37.58255156876],
            [126.846335893464, 37.582470938209],
            [126.846422281537, 37.582424200505],
            [126.846308043033, 37.582252782067],
            [126.846139873963, 37.581829124764],
            [126.845774866589, 37.581002872613],
            [126.845523109548, 37.580492032797],
            [126.844263320255, 37.578520500296],
            [126.832789134818, 37.58358678332],
            [126.827051007371, 37.588200573907],
            [126.826793135201, 37.58847583057],
            [126.825997050308, 37.589327728256],
            [126.825289130205, 37.59008212148],
            [126.823320638421, 37.591165266084],
            [126.82278494331, 37.591297243135]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281124',
      properties: {
        rgnCode: '41281124',
        colCode: '41280',
        rgnKo: ['강매동'],
        rgnSize: 1,
        rgnBbox: [
          126.829398802637, 37.591015869558, 126.858432916727, 37.612314511838
        ],
        rgnCenter: [126.842724698602, 37.604493016004],
        rgnArea: 3200724,
        predVal: [
          0.32913, 0.33469, 0.32025, 0.35966, 0.4506, 0.4097, 0.44949, 0.47273,
          0.4071, 0.42857, 0.42713, 0.32704, 0.47008, 0.41782, 0.47517, 0.18971,
          0.4405, 0.40416, 0.52136, 0.45529, 0.46633, 0.47447, 0.41172, 0.46626,
          0.37323, 0.45482, 0.41571, 0.42804, 0.41849, 0.47934, 0.46073
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.854715236049, 37.610881339409],
            [126.855165143422, 37.610657571937],
            [126.85557303506, 37.610454536421],
            [126.855822460335, 37.610331392175],
            [126.856091800936, 37.610199415296],
            [126.856186369755, 37.610152500574],
            [126.856820548966, 37.609839143724],
            [126.857235612955, 37.609629732273],
            [126.857328790999, 37.609580904849],
            [126.857552477631, 37.609464410755],
            [126.857772165176, 37.609354074076],
            [126.85801824592, 37.609228974966],
            [126.858167713083, 37.609154960798],
            [126.858297927867, 37.609086319956],
            [126.858432916727, 37.609020279583],
            [126.858412593027, 37.608964313011],
            [126.858309440255, 37.608711138267],
            [126.858012171796, 37.608547113915],
            [126.85778757806, 37.608348147181],
            [126.857720662395, 37.60832191018],
            [126.857520413783, 37.608243307672],
            [126.856986280994, 37.60809165028],
            [126.856400943469, 37.607925466767],
            [126.85613733528, 37.607822412438],
            [126.855860828547, 37.60771351225],
            [126.855621040456, 37.607725478429],
            [126.85529506973, 37.607875349719],
            [126.854768093887, 37.608061964639],
            [126.85449544817, 37.607936812354],
            [126.854267796516, 37.607714869084],
            [126.854274260528, 37.607561088618],
            [126.854280420036, 37.607400901789],
            [126.854289218992, 37.607182884243],
            [126.854297106632, 37.607002013021],
            [126.854192031308, 37.606698485357],
            [126.854056624727, 37.606586252157],
            [126.8536858433, 37.606512700679],
            [126.853563584506, 37.606487031677],
            [126.853290664791, 37.606361191493],
            [126.853213294797, 37.606274609066],
            [126.853179016397, 37.60623359835],
            [126.85310765841, 37.606142212166],
            [126.853289507801, 37.60568865223],
            [126.853419869578, 37.605419395293],
            [126.853543117284, 37.604956346098],
            [126.85356292267, 37.604877084521],
            [126.85366669156, 37.604482007842],
            [126.853691148223, 37.604301193373],
            [126.853649561188, 37.604216881441],
            [126.853581692023, 37.60406855782],
            [126.85336463747, 37.603912370617],
            [126.852947964449, 37.603893638002],
            [126.852568526906, 37.603891898385],
            [126.852241370884, 37.603926181878],
            [126.851971972097, 37.603834913734],
            [126.851840899417, 37.603734279075],
            [126.851736075434, 37.603589412334],
            [126.851740421339, 37.603432601915],
            [126.851744446528, 37.603277593049],
            [126.851946645902, 37.603147062895],
            [126.852362944345, 37.603050571961],
            [126.853040780713, 37.602896412635],
            [126.853250489522, 37.602883368753],
            [126.853787360235, 37.603076671951],
            [126.853838925986, 37.603079592597],
            [126.853889133202, 37.603082313304],
            [126.853996929816, 37.603088448629],
            [126.854139379969, 37.602726819162],
            [126.854140736636, 37.602693520553],
            [126.854145673059, 37.60263088142],
            [126.854133601098, 37.602527640808],
            [126.85412045707, 37.602474178126],
            [126.854035514682, 37.602335690146],
            [126.853900305322, 37.602196526575],
            [126.853769816223, 37.602127330034],
            [126.853571575768, 37.602074464341],
            [126.853306706914, 37.602037002553],
            [126.853184511251, 37.602046030055],
            [126.853101164579, 37.602051682556],
            [126.852916684442, 37.602118060057],
            [126.852691935292, 37.602258059822],
            [126.852531826006, 37.602255407153],
            [126.852286064684, 37.602074440711],
            [126.85212370202, 37.601862369315],
            [126.852048214622, 37.601590600528],
            [126.852072203354, 37.601345140226],
            [126.852212933967, 37.601008296833],
            [126.852489962325, 37.600704322081],
            [126.852784925757, 37.600434453434],
            [126.852870970643, 37.600255590647],
            [126.852821220332, 37.600086846015],
            [126.85264582049, 37.599860775592],
            [126.852504156241, 37.599770696774],
            [126.852227441013, 37.599670347028],
            [126.85184475724, 37.599728336149],
            [126.851635724665, 37.599877111445],
            [126.851459197015, 37.600203367212],
            [126.851308778351, 37.600407068239],
            [126.851069748514, 37.6005936456],
            [126.850934486875, 37.600698239358],
            [126.85075095169, 37.600880670251],
            [126.850674485046, 37.600956678541],
            [126.850458865809, 37.601235049623],
            [126.850412989906, 37.6014598671],
            [126.850419078294, 37.601708239697],
            [126.850466126594, 37.601917417921],
            [126.850447953063, 37.602045280091],
            [126.850176163695, 37.602294440926],
            [126.84991221145, 37.602369379244],
            [126.849694849649, 37.602331582944],
            [126.849449011658, 37.602180414997],
            [126.849412022777, 37.60202647956],
            [126.84949252456, 37.601849648371],
            [126.849649898007, 37.601598837287],
            [126.849720748707, 37.601484224363],
            [126.849776267103, 37.601252094567],
            [126.849825480426, 37.600862401828],
            [126.84953486407, 37.6006091402],
            [126.849307338256, 37.600504792531],
            [126.849174563295, 37.600443904018],
            [126.849013496984, 37.600290718189],
            [126.84896744851, 37.600187784492],
            [126.8489779863, 37.600140244251],
            [126.849037109845, 37.599873431579],
            [126.8491543045, 37.599672042114],
            [126.849189636045, 37.599466087039],
            [126.849104430634, 37.599229532152],
            [126.848945009514, 37.599048381821],
            [126.848674891755, 37.598926111358],
            [126.848410788994, 37.59895800623],
            [126.848236355434, 37.599019425071],
            [126.847774201817, 37.599330284707],
            [126.847511450472, 37.599485785137],
            [126.847319297734, 37.59947358723],
            [126.84691210429, 37.599346448475],
            [126.846607891454, 37.599219290348],
            [126.846360953168, 37.599016974681],
            [126.846256506907, 37.59890025017],
            [126.846118995435, 37.59860387112],
            [126.846109921461, 37.598398723408],
            [126.846105901627, 37.598159119749],
            [126.846261582981, 37.59791780716],
            [126.846639220978, 37.59777326306],
            [126.847066061131, 37.597616555801],
            [126.847150404257, 37.597438812101],
            [126.847243586092, 37.597252322332],
            [126.847334949869, 37.596947918458],
            [126.847360707824, 37.596949393674],
            [126.847378154605, 37.596879365026],
            [126.847377380498, 37.596672750575],
            [126.847351904905, 37.596528965448],
            [126.847349298764, 37.596518834994],
            [126.847295719789, 37.596412845423],
            [126.847097815942, 37.596212117928],
            [126.846963472307, 37.596098481283],
            [126.846918143236, 37.596066391968],
            [126.846721140253, 37.595956475109],
            [126.846655296626, 37.595917385177],
            [126.846385013559, 37.595898361882],
            [126.846242544344, 37.5958868582],
            [126.8460106306, 37.595897797159],
            [126.845678835415, 37.595940372501],
            [126.845459485219, 37.595997150968],
            [126.845305167789, 37.59605252849],
            [126.845253352888, 37.596133332154],
            [126.845233682394, 37.596172075416],
            [126.845237363186, 37.596296280815],
            [126.845308933579, 37.5964882309],
            [126.845365119449, 37.596629633453],
            [126.84536740168, 37.596751296216],
            [126.845319508319, 37.597025194761],
            [126.845277170478, 37.597116867807],
            [126.845203597869, 37.597291795352],
            [126.84513885969, 37.597438533785],
            [126.845024226266, 37.597559744257],
            [126.844897377101, 37.597609373333],
            [126.844816858987, 37.597623610113],
            [126.844694756202, 37.59760213069],
            [126.844487719741, 37.597551490486],
            [126.84419813935, 37.59747166521],
            [126.843996651731, 37.597367504143],
            [126.843810731688, 37.597259345148],
            [126.843664736742, 37.59715225737],
            [126.843507790058, 37.596965931314],
            [126.843396541427, 37.596829814987],
            [126.843299572638, 37.596549046845],
            [126.84326463313, 37.596439899964],
            [126.84329925151, 37.596214033869],
            [126.843319089401, 37.596139486085],
            [126.843462634328, 37.596065347278],
            [126.843896213614, 37.596013706086],
            [126.844288198642, 37.595990370851],
            [126.844594160678, 37.59593426835],
            [126.844791465207, 37.595828692],
            [126.845006292183, 37.59549326169],
            [126.845084508162, 37.595090112287],
            [126.845128146356, 37.594733732092],
            [126.845164356695, 37.594442041639],
            [126.845181874283, 37.594024918514],
            [126.845185858078, 37.593912165707],
            [126.845092436827, 37.593428168732],
            [126.845024424424, 37.592771585875],
            [126.845013699726, 37.59266976951],
            [126.844929349868, 37.592353691664],
            [126.844786322779, 37.59180702815],
            [126.844698795138, 37.591646509748],
            [126.844601474654, 37.591480166913],
            [126.844320920505, 37.591254349438],
            [126.844183158917, 37.591183889153],
            [126.844041351339, 37.591126109162],
            [126.843786418704, 37.59106877254],
            [126.843597163071, 37.591024938903],
            [126.843391189874, 37.591015869558],
            [126.843272124377, 37.591030432075],
            [126.843145850054, 37.591118045586],
            [126.843054148172, 37.591219463401],
            [126.843026194312, 37.591474935886],
            [126.843024816638, 37.591558626425],
            [126.843028108545, 37.591805662588],
            [126.843022877806, 37.591997709268],
            [126.842976324122, 37.592165914476],
            [126.842541809817, 37.592518290437],
            [126.842375234556, 37.59271734554],
            [126.842328359688, 37.592801542323],
            [126.842267374617, 37.592913136982],
            [126.842288133559, 37.593214165587],
            [126.842309214284, 37.593470523844],
            [126.842328075861, 37.593710922675],
            [126.84232277697, 37.594109465565],
            [126.842267200409, 37.594190515144],
            [126.841880835555, 37.594555405077],
            [126.84180994784, 37.594621225127],
            [126.841588781861, 37.594828503223],
            [126.841492462744, 37.594920281987],
            [126.841325969398, 37.595082593534],
            [126.841108848423, 37.595403454148],
            [126.841023059568, 37.595484254833],
            [126.840816503858, 37.59565098905],
            [126.840597895514, 37.595735293538],
            [126.840379480421, 37.595813768523],
            [126.840234866223, 37.595836510042],
            [126.840024898221, 37.595841376579],
            [126.839893368108, 37.595824798656],
            [126.839719768365, 37.595804595032],
            [126.839472968522, 37.595714113106],
            [126.839316458692, 37.595650955454],
            [126.839114724048, 37.595572067281],
            [126.838832210924, 37.595554919949],
            [126.838404419443, 37.595532832099],
            [126.838059177345, 37.595582169862],
            [126.837661411559, 37.595670257077],
            [126.83742226103, 37.595733047724],
            [126.837085365846, 37.595827254188],
            [126.8365947691, 37.59599213438],
            [126.836234795439, 37.596101343569],
            [126.835799254608, 37.596212833192],
            [126.835610389144, 37.596270032574],
            [126.835516940458, 37.596299075017],
            [126.835231863412, 37.596385014949],
            [126.834980171152, 37.596459027235],
            [126.83470054534, 37.596537261157],
            [126.834364841436, 37.596627280959],
            [126.83432074408, 37.596639093458],
            [126.834141651297, 37.596669797409],
            [126.834030673152, 37.596688578647],
            [126.833794820726, 37.596727058088],
            [126.833868709582, 37.596861932085],
            [126.833935214776, 37.596982100518],
            [126.833979698817, 37.597196930957],
            [126.833939825869, 37.597349626986],
            [126.83390915984, 37.597375666897],
            [126.833807186705, 37.597463358866],
            [126.833775645655, 37.597490739963],
            [126.833548653161, 37.597591354259],
            [126.833225605682, 37.597614797171],
            [126.832637214238, 37.597662150342],
            [126.832239565326, 37.597695944202],
            [126.832074321855, 37.597754892658],
            [126.831989586153, 37.597820191341],
            [126.831835601583, 37.597937368126],
            [126.831572166308, 37.59825671184],
            [126.831428061081, 37.598430682233],
            [126.831305232592, 37.598544975011],
            [126.831187080299, 37.598654364051],
            [126.831153245683, 37.598678839966],
            [126.830927968493, 37.598888038091],
            [126.830847586435, 37.598994634262],
            [126.830825775404, 37.599104279326],
            [126.83078831343, 37.599288836462],
            [126.830810844627, 37.599414195715],
            [126.830872948719, 37.599521574513],
            [126.830885942334, 37.599544135879],
            [126.830897720068, 37.599583426731],
            [126.83090562642, 37.599607233106],
            [126.830911082682, 37.599642766731],
            [126.830890258959, 37.599781190601],
            [126.830808385128, 37.60029741621],
            [126.830818383879, 37.600510711209],
            [126.830922341044, 37.600846936914],
            [126.830999134495, 37.600934190887],
            [126.831135536598, 37.601032144444],
            [126.831990817074, 37.601140737038],
            [126.832326228188, 37.601115224979],
            [126.832445856009, 37.601070383329],
            [126.832497749471, 37.601062610097],
            [126.832548486264, 37.601065556876],
            [126.832604896416, 37.601073530223],
            [126.832644880709, 37.601099130285],
            [126.832607223531, 37.601126123887],
            [126.832872149657, 37.601197373986],
            [126.833138517924, 37.601252011436],
            [126.833236362281, 37.601265512546],
            [126.833233373041, 37.601320666416],
            [126.833232384374, 37.601357398051],
            [126.833731744598, 37.601373515778],
            [126.833922669334, 37.601373967232],
            [126.834134204427, 37.601374267412],
            [126.834469268175, 37.601377643272],
            [126.834468252367, 37.601411590845],
            [126.834395197061, 37.604065663317],
            [126.834380825332, 37.604622837121],
            [126.834378855431, 37.604698498776],
            [126.834371131646, 37.60500740743],
            [126.834319174907, 37.607020703163],
            [126.834316986968, 37.607077344761],
            [126.834312764021, 37.607228550476],
            [126.834284520206, 37.608179153797],
            [126.834269030865, 37.6087187745],
            [126.834261110255, 37.608978606217],
            [126.834257542926, 37.609125208797],
            [126.833086510575, 37.609100766582],
            [126.8330309059, 37.609099515938],
            [126.832210876542, 37.609082034652],
            [126.83186139142, 37.60907531601],
            [126.831439433797, 37.609064347403],
            [126.831136700382, 37.609067334475],
            [126.830906953478, 37.609097455734],
            [126.830785142886, 37.609125029795],
            [126.830688686098, 37.609159127495],
            [126.830635071956, 37.609178871461],
            [126.830415750726, 37.609304871231],
            [126.830280655555, 37.609396395158],
            [126.829979546389, 37.609628501066],
            [126.829749458949, 37.609805749749],
            [126.82947195615, 37.610015003783],
            [126.82943890868, 37.610039444262],
            [126.829436486008, 37.61011275354],
            [126.829398802637, 37.611141393351],
            [126.829582265178, 37.611343930928],
            [126.829749449652, 37.611528505999],
            [126.829886534142, 37.611677259119],
            [126.830108390943, 37.611920476906],
            [126.830122195751, 37.611955599158],
            [126.830128209611, 37.611990205544],
            [126.830138675819, 37.612029179142],
            [126.830174917711, 37.612054152887],
            [126.830222692002, 37.612058195562],
            [126.830300483119, 37.612050857337],
            [126.830486991269, 37.612008601491],
            [126.830553998475, 37.612001995283],
            [126.830520669482, 37.612055176928],
            [126.830488692675, 37.612066843338],
            [126.830640380165, 37.612125816005],
            [126.830703656312, 37.612151090012],
            [126.830765061046, 37.612182496954],
            [126.830821771745, 37.612250864305],
            [126.830850323744, 37.612246094363],
            [126.830898947805, 37.612240146119],
            [126.831050337144, 37.612221633503],
            [126.831224169164, 37.612200360039],
            [126.831594496148, 37.612147735973],
            [126.831659660223, 37.612129873238],
            [126.831862527846, 37.612120065052],
            [126.832150451237, 37.612115703408],
            [126.832488521981, 37.612107799963],
            [126.833052697716, 37.61211145375],
            [126.83340199396, 37.612112843812],
            [126.834237023755, 37.612137996444],
            [126.834787873838, 37.612144704402],
            [126.835566821104, 37.612148523259],
            [126.836033751501, 37.612153233328],
            [126.836955965444, 37.612169650322],
            [126.837170249133, 37.61217003902],
            [126.837337641294, 37.612171623479],
            [126.837556903988, 37.612174000559],
            [126.837696316927, 37.61217761789],
            [126.837739921014, 37.612177768507],
            [126.837924754528, 37.612179556532],
            [126.838232007424, 37.612187189885],
            [126.838316382986, 37.61218802738],
            [126.838508690777, 37.612190185218],
            [126.838669401392, 37.612191488265],
            [126.838899758175, 37.612196310806],
            [126.839021881543, 37.612197695343],
            [126.839055269038, 37.61219817378],
            [126.839470202673, 37.612203086976],
            [126.839750878648, 37.612208625644],
            [126.83975287191, 37.612208664415],
            [126.839907338843, 37.612211452846],
            [126.840501278998, 37.612215598311],
            [126.840743247256, 37.612219351919],
            [126.841338873495, 37.612224234271],
            [126.841410857579, 37.612225584313],
            [126.841816016945, 37.612230683227],
            [126.842225613337, 37.612237075141],
            [126.842330462216, 37.612239739359],
            [126.842521963617, 37.612243078865],
            [126.842927691791, 37.612247075532],
            [126.843367572587, 37.612253972685],
            [126.843513639298, 37.612255195452],
            [126.843946740761, 37.612259846938],
            [126.844062144231, 37.612263073307],
            [126.844163043436, 37.612264343117],
            [126.844305100298, 37.612265820842],
            [126.844646665439, 37.612265563579],
            [126.844805615066, 37.612264216056],
            [126.844927050807, 37.61226416101],
            [126.845273754636, 37.612265449427],
            [126.845546490638, 37.612266161574],
            [126.845672807016, 37.612266490623],
            [126.845965078487, 37.612267975411],
            [126.846071868675, 37.612268521559],
            [126.846387611472, 37.612273450931],
            [126.846941620523, 37.612281665012],
            [126.847285330625, 37.612284808628],
            [126.847660029795, 37.612292703778],
            [126.847886772116, 37.61229871138],
            [126.848093745194, 37.612301971887],
            [126.848441330637, 37.612309784255],
            [126.848849518443, 37.612313187127],
            [126.849069101784, 37.612314236813],
            [126.849152346472, 37.612314371345],
            [126.849359008885, 37.612314511838],
            [126.849850291959, 37.612263391893],
            [126.850188768171, 37.612216921338],
            [126.850368494692, 37.612190464609],
            [126.850641879978, 37.612149990991],
            [126.850977433104, 37.612069817755],
            [126.851107800519, 37.612038044208],
            [126.851467266285, 37.611944160093],
            [126.851906696609, 37.611785343484],
            [126.852204113895, 37.611636706926],
            [126.852542557336, 37.61146256058],
            [126.853038158341, 37.611206711918],
            [126.853736563384, 37.610836780798],
            [126.854004917252, 37.61069683378],
            [126.854230193577, 37.610576834174],
            [126.854342170878, 37.610737123246],
            [126.854563283971, 37.610631848852],
            [126.854673691754, 37.610813173561],
            [126.854715236049, 37.610881339409]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281125',
      properties: {
        rgnCode: '41281125',
        colCode: '41280',
        rgnKo: ['행주내동'],
        rgnSize: 1,
        rgnBbox: [
          126.816853246283, 37.592146772311, 126.834469268175, 37.616461073559
        ],
        rgnCenter: [126.826764896957, 37.605394676962],
        rgnArea: 2150119,
        predVal: [
          0.32913, 0.33469, 0.32025, 0.35966, 0.4506, 0.4097, 0.44949, 0.47273,
          0.4071, 0.42857, 0.42713, 0.32704, 0.47008, 0.41782, 0.47517, 0.18971,
          0.4405, 0.40416, 0.52136, 0.45529, 0.46633, 0.47447, 0.41172, 0.46626,
          0.37323, 0.45482, 0.41571, 0.42804, 0.41849, 0.47934, 0.46073
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.830821771745, 37.612250864305],
            [126.830765061046, 37.612182496954],
            [126.830703656312, 37.612151090012],
            [126.830640380165, 37.612125816005],
            [126.830488692675, 37.612066843338],
            [126.830520669482, 37.612055176928],
            [126.830553998475, 37.612001995283],
            [126.830486991269, 37.612008601491],
            [126.830300483119, 37.612050857337],
            [126.830222692002, 37.612058195562],
            [126.830174917711, 37.612054152887],
            [126.830138675819, 37.612029179142],
            [126.830128209611, 37.611990205544],
            [126.830122195751, 37.611955599158],
            [126.830108390943, 37.611920476906],
            [126.829886534142, 37.611677259119],
            [126.829749449652, 37.611528505999],
            [126.829582265178, 37.611343930928],
            [126.829398802637, 37.611141393351],
            [126.829436486008, 37.61011275354],
            [126.82943890868, 37.610039444262],
            [126.82947195615, 37.610015003783],
            [126.829749458949, 37.609805749749],
            [126.829979546389, 37.609628501066],
            [126.830280655555, 37.609396395158],
            [126.830415750726, 37.609304871231],
            [126.830635071956, 37.609178871461],
            [126.830688686098, 37.609159127495],
            [126.830785142886, 37.609125029795],
            [126.830906953478, 37.609097455734],
            [126.831136700382, 37.609067334475],
            [126.831439433797, 37.609064347403],
            [126.83186139142, 37.60907531601],
            [126.832210876542, 37.609082034652],
            [126.8330309059, 37.609099515938],
            [126.833086510575, 37.609100766582],
            [126.834257542926, 37.609125208797],
            [126.834261110255, 37.608978606217],
            [126.834269030865, 37.6087187745],
            [126.834284520206, 37.608179153797],
            [126.834312764021, 37.607228550476],
            [126.834316986968, 37.607077344761],
            [126.834319174907, 37.607020703163],
            [126.834371131646, 37.60500740743],
            [126.834378855431, 37.604698498776],
            [126.834380825332, 37.604622837121],
            [126.834395197061, 37.604065663317],
            [126.834468252367, 37.601411590845],
            [126.834469268175, 37.601377643272],
            [126.834134204427, 37.601374267412],
            [126.833922669334, 37.601373967232],
            [126.833731744598, 37.601373515778],
            [126.833232384374, 37.601357398051],
            [126.833233373041, 37.601320666416],
            [126.833236362281, 37.601265512546],
            [126.833138517924, 37.601252011436],
            [126.832872149657, 37.601197373986],
            [126.832607223531, 37.601126123887],
            [126.832644880709, 37.601099130285],
            [126.832604896416, 37.601073530223],
            [126.832548486264, 37.601065556876],
            [126.832497749471, 37.601062610097],
            [126.832445856009, 37.601070383329],
            [126.832326228188, 37.601115224979],
            [126.831990817074, 37.601140737038],
            [126.831135536598, 37.601032144444],
            [126.830999134495, 37.600934190887],
            [126.830922341044, 37.600846936914],
            [126.830818383879, 37.600510711209],
            [126.830808385128, 37.60029741621],
            [126.830890258959, 37.599781190601],
            [126.830911082682, 37.599642766731],
            [126.83090562642, 37.599607233106],
            [126.830897720068, 37.599583426731],
            [126.830885942334, 37.599544135879],
            [126.830872948719, 37.599521574513],
            [126.830810844627, 37.599414195715],
            [126.83078831343, 37.599288836462],
            [126.830825775404, 37.599104279326],
            [126.830847586435, 37.598994634262],
            [126.830927968493, 37.598888038091],
            [126.831153245683, 37.598678839966],
            [126.831187080299, 37.598654364051],
            [126.831305232592, 37.598544975011],
            [126.831428061081, 37.598430682233],
            [126.831572166308, 37.59825671184],
            [126.831835601583, 37.597937368126],
            [126.831989586153, 37.597820191341],
            [126.832074321855, 37.597754892658],
            [126.832239565326, 37.597695944202],
            [126.832637214238, 37.597662150342],
            [126.833225605682, 37.597614797171],
            [126.833548653161, 37.597591354259],
            [126.833775645655, 37.597490739963],
            [126.833807186705, 37.597463358866],
            [126.83390915984, 37.597375666897],
            [126.833939825869, 37.597349626986],
            [126.833979698817, 37.597196930957],
            [126.833935214776, 37.596982100518],
            [126.833868709582, 37.596861932085],
            [126.833794820726, 37.596727058088],
            [126.833314482178, 37.596821094898],
            [126.832816908104, 37.596915105117],
            [126.832734168003, 37.596914761585],
            [126.832515132021, 37.596912123633],
            [126.832459817638, 37.596911702047],
            [126.83213029165, 37.596867000362],
            [126.831965840234, 37.596722363717],
            [126.83190674016, 37.596653768066],
            [126.831794841633, 37.596524144982],
            [126.831786523844, 37.596511996781],
            [126.831705622026, 37.596350685558],
            [126.831632940899, 37.596199531176],
            [126.83157149272, 37.596073332128],
            [126.831414294544, 37.595698341797],
            [126.831280186963, 37.595377641742],
            [126.831434783916, 37.594486945412],
            [126.831458691132, 37.594350805249],
            [126.831481668074, 37.594165641236],
            [126.83150826403, 37.593907682972],
            [126.831546541482, 37.593362571073],
            [126.831546573727, 37.593328477907],
            [126.831535825059, 37.593011253185],
            [126.831518676939, 37.592465971284],
            [126.831116626018, 37.592371491059],
            [126.830945242205, 37.592327545821],
            [126.828349985696, 37.592244399363],
            [126.827596996018, 37.592220425333],
            [126.825284205489, 37.592146772311],
            [126.825301751533, 37.592335878907],
            [126.82535696735, 37.59291950128],
            [126.82540431395, 37.593431294448],
            [126.825453985952, 37.593975229094],
            [126.825766545003, 37.594390480539],
            [126.825760215965, 37.594727258623],
            [126.825740035174, 37.595128057938],
            [126.825743786358, 37.595145957048],
            [126.825836919899, 37.595372386607],
            [126.825971256338, 37.595841187735],
            [126.825995409083, 37.595923123006],
            [126.826192283394, 37.596036218667],
            [126.826168268672, 37.596377925814],
            [126.826248518242, 37.596488775801],
            [126.826260139509, 37.596506632517],
            [126.826232075209, 37.596740395895],
            [126.82618813659, 37.596790154987],
            [126.826162653489, 37.596937157527],
            [126.826025765518, 37.597076155923],
            [126.825697536607, 37.597540304258],
            [126.825634998718, 37.597649950778],
            [126.825565936344, 37.597837342342],
            [126.825574236166, 37.59796736651],
            [126.825635432325, 37.598471206098],
            [126.825645088521, 37.598703394679],
            [126.825586660895, 37.598798280172],
            [126.825475501433, 37.598829288427],
            [126.825060867322, 37.599285647585],
            [126.825143225293, 37.599324242632],
            [126.825134163175, 37.599613345333],
            [126.825146194619, 37.59984131185],
            [126.825225464989, 37.599984145878],
            [126.825329881216, 37.600172336799],
            [126.825313510386, 37.600252409686],
            [126.825282574386, 37.600304710569],
            [126.825242756279, 37.600392316683],
            [126.825216099425, 37.600449939733],
            [126.825170531537, 37.600517084935],
            [126.82523438895, 37.600713414256],
            [126.825100896763, 37.600849353214],
            [126.824820803012, 37.60096570152],
            [126.824624490952, 37.600983607305],
            [126.824529089852, 37.601158525119],
            [126.824400332429, 37.601345601106],
            [126.824360188058, 37.601402978466],
            [126.824444794016, 37.601597636468],
            [126.824464388767, 37.601643796145],
            [126.824349247291, 37.601776148816],
            [126.824229349397, 37.601913197353],
            [126.824151558536, 37.601938938663],
            [126.823949695924, 37.60212793146],
            [126.823925129112, 37.602172664292],
            [126.82388557092, 37.602235592413],
            [126.823842901678, 37.602306696753],
            [126.823611328052, 37.602483715226],
            [126.823601357231, 37.602699971893],
            [126.823631562644, 37.602950670577],
            [126.82382731811, 37.603064308966],
            [126.823909934158, 37.603162550237],
            [126.82394468189, 37.6032131476],
            [126.823994566805, 37.603308186125],
            [126.824070195029, 37.603363980527],
            [126.824221944467, 37.603387273712],
            [126.824460797158, 37.603403048065],
            [126.824406946012, 37.603566810805],
            [126.824387165571, 37.603675196255],
            [126.824431631446, 37.603677542464],
            [126.824613250354, 37.603688770762],
            [126.82462310202, 37.603722536304],
            [126.824602309896, 37.603851768989],
            [126.824599200625, 37.603868792883],
            [126.824556595567, 37.603955718795],
            [126.824540636017, 37.604000104254],
            [126.824636004367, 37.604003175408],
            [126.824671571426, 37.603990281599],
            [126.824758612514, 37.603966193598],
            [126.824797676957, 37.603992389575],
            [126.824755601582, 37.604037393673],
            [126.82474019341, 37.604054597366],
            [126.824612338843, 37.604245873414],
            [126.824587318406, 37.604252494163],
            [126.824166912645, 37.604358251104],
            [126.824026051262, 37.604393961493],
            [126.823834458672, 37.604442551466],
            [126.823756485426, 37.604462868317],
            [126.823796682309, 37.604493796482],
            [126.823851461416, 37.604535918757],
            [126.823914749951, 37.604585658096],
            [126.823929115926, 37.604597302376],
            [126.823939617662, 37.604605246815],
            [126.823999094858, 37.604648132914],
            [126.824021575466, 37.604666726945],
            [126.824044868464, 37.604686583567],
            [126.824099596081, 37.604721894228],
            [126.824194857898, 37.604755914297],
            [126.824173634808, 37.604775253513],
            [126.824091153587, 37.604743324898],
            [126.82399643003, 37.604673446488],
            [126.823973130839, 37.604656202693],
            [126.823911982961, 37.604611061613],
            [126.823898193257, 37.604599958788],
            [126.823887224004, 37.604593365114],
            [126.823812389543, 37.604536670818],
            [126.823764453017, 37.604498270875],
            [126.823741519024, 37.604479892347],
            [126.823729302204, 37.60446933246],
            [126.823568756261, 37.604510589724],
            [126.823515612697, 37.604514059454],
            [126.823492856988, 37.604515935214],
            [126.823419978957, 37.604489048092],
            [126.823379905971, 37.60442997332],
            [126.823220592283, 37.604472078893],
            [126.823181838453, 37.604481931141],
            [126.823130367639, 37.604496269063],
            [126.823118605588, 37.604499224524],
            [126.822998352251, 37.604531063582],
            [126.822987643939, 37.604533786369],
            [126.822768225142, 37.604592036086],
            [126.822766821398, 37.604662815073],
            [126.822764877601, 37.60476051457],
            [126.822617216371, 37.604802456777],
            [126.822002097505, 37.604979466639],
            [126.821989181082, 37.604982059839],
            [126.821960310596, 37.604988331801],
            [126.821312882741, 37.605163838324],
            [126.821294632344, 37.605171108408],
            [126.821246440553, 37.605196622683],
            [126.821223108585, 37.605197848417],
            [126.821175172104, 37.605258321134],
            [126.821108594113, 37.605300385316],
            [126.821020890686, 37.605414675866],
            [126.820947666579, 37.60548880473],
            [126.820920636099, 37.605520838311],
            [126.820936160634, 37.605540314254],
            [126.820960604704, 37.60557099403],
            [126.82092653345, 37.605611035574],
            [126.820886554534, 37.605658816507],
            [126.820817389329, 37.605740249448],
            [126.820825564536, 37.605871895343],
            [126.820825203794, 37.605927395243],
            [126.82082161355, 37.605960275565],
            [126.820816982927, 37.606001893814],
            [126.820835767754, 37.606129618658],
            [126.820843165932, 37.606179877711],
            [126.820819440154, 37.606156505973],
            [126.820796126255, 37.606009700417],
            [126.820805164075, 37.605925202205],
            [126.820803786222, 37.605886187603],
            [126.820797002288, 37.605741209312],
            [126.820834171026, 37.605684774687],
            [126.820854039531, 37.60566408253],
            [126.820890947055, 37.60562197309],
            [126.820932624447, 37.605574284852],
            [126.820914459995, 37.605545948222],
            [126.820897649432, 37.605519721958],
            [126.820900251542, 37.605473956083],
            [126.820953655414, 37.605447458845],
            [126.82100735628, 37.605391770238],
            [126.821086067246, 37.60529647666],
            [126.821159355549, 37.60524252979],
            [126.821201927661, 37.605199347728],
            [126.820694971651, 37.605364352709],
            [126.820411312689, 37.605451330999],
            [126.820272266433, 37.605577507225],
            [126.820100437332, 37.605735077148],
            [126.820102288172, 37.605779696591],
            [126.820105410066, 37.605855861344],
            [126.820096101029, 37.605883840526],
            [126.820108854321, 37.605901879756],
            [126.820124428762, 37.605924067835],
            [126.820142887731, 37.605970947233],
            [126.820147509695, 37.605979693855],
            [126.820219161553, 37.606087291015],
            [126.820256341622, 37.60611978345],
            [126.820217951223, 37.606119364091],
            [126.820171893214, 37.60605856719],
            [126.820136590161, 37.605999318467],
            [126.820121583911, 37.60597632038],
            [126.820102401485, 37.605947549591],
            [126.820087843217, 37.605926624446],
            [126.820082658143, 37.605949231107],
            [126.820073112906, 37.605995184501],
            [126.82003205968, 37.606093292305],
            [126.819956221399, 37.606040161923],
            [126.819794976486, 37.606077872105],
            [126.819590143287, 37.606124299454],
            [126.819401908673, 37.606167508527],
            [126.819411005515, 37.606184884473],
            [126.818921969051, 37.606307040758],
            [126.818797160035, 37.606333579713],
            [126.818455954404, 37.606406174613],
            [126.818390169004, 37.606419542227],
            [126.818374977067, 37.606426276013],
            [126.818337148499, 37.6064414529],
            [126.81827236238, 37.606430621643],
            [126.81818981913, 37.60638272343],
            [126.818146910213, 37.60635913215],
            [126.818125095039, 37.606346655703],
            [126.818027582292, 37.606265722162],
            [126.818012849988, 37.606269753672],
            [126.817823701999, 37.606140438912],
            [126.817785107934, 37.606130738337],
            [126.817773789944, 37.606127927676],
            [126.817779326272, 37.606133161986],
            [126.817783958828, 37.606137493908],
            [126.817802466777, 37.60614085635],
            [126.817941603394, 37.606242712545],
            [126.818087634197, 37.606345822644],
            [126.818179360551, 37.606394257764],
            [126.818257625857, 37.606436365042],
            [126.818266639894, 37.606441235327],
            [126.818332992508, 37.606459817457],
            [126.818304113368, 37.606515435251],
            [126.81826573982, 37.606591166216],
            [126.818270598268, 37.606595768763],
            [126.818276289363, 37.606588722917],
            [126.818279102574, 37.606591457257],
            [126.818585684536, 37.606885950043],
            [126.818951886128, 37.607239827827],
            [126.819034871361, 37.607320639026],
            [126.819225913387, 37.607506599431],
            [126.819233551083, 37.607514008283],
            [126.819240163246, 37.607542552566],
            [126.819355749123, 37.607653299422],
            [126.819542300918, 37.60783288244],
            [126.819543270598, 37.607834640849],
            [126.81965413584, 37.607940298597],
            [126.819757682464, 37.608042476191],
            [126.819788348955, 37.608071282729],
            [126.819816293075, 37.608097219948],
            [126.819843728415, 37.608122814005],
            [126.820079040296, 37.608347943721],
            [126.820154199699, 37.608429796236],
            [126.820206758917, 37.608504973755],
            [126.820249302863, 37.608591064766],
            [126.820269325007, 37.60863345964],
            [126.820275052105, 37.608644235157],
            [126.820354767495, 37.60880435374],
            [126.820490939, 37.609081713416],
            [126.820501735348, 37.609103722919],
            [126.820776136538, 37.609745263398],
            [126.820811948425, 37.609829190535],
            [126.820826837939, 37.609863396542],
            [126.820849317744, 37.609915417546],
            [126.820781554614, 37.610014863154],
            [126.820581822114, 37.610261047414],
            [126.820553556773, 37.610287781223],
            [126.820446398036, 37.610389130355],
            [126.820432539569, 37.610396821486],
            [126.820148744994, 37.610545633143],
            [126.819990934213, 37.610624956116],
            [126.819952765655, 37.610644142348],
            [126.819883827482, 37.610681120633],
            [126.819774369996, 37.610739831206],
            [126.819253493374, 37.611018647251],
            [126.818917724863, 37.611198144133],
            [126.818860634573, 37.611229572086],
            [126.818473945692, 37.6114360273],
            [126.818115050505, 37.611627640236],
            [126.818073112922, 37.611650036499],
            [126.817909172874, 37.611737555073],
            [126.817828722347, 37.611780505756],
            [126.81776540746, 37.611814311085],
            [126.817388135144, 37.612007163562],
            [126.817048819313, 37.612188577649],
            [126.816853246283, 37.612293425208],
            [126.816968077447, 37.612445284867],
            [126.816978259384, 37.612459040739],
            [126.817043414229, 37.612538140554],
            [126.817038400734, 37.612541160011],
            [126.817022081385, 37.612549828959],
            [126.81695245362, 37.612589570448],
            [126.817585179107, 37.613852155779],
            [126.817886312701, 37.614452605363],
            [126.818008124726, 37.614697248731],
            [126.818239649034, 37.615161153687],
            [126.818382616333, 37.615449229695],
            [126.818639968828, 37.615964259443],
            [126.818718894098, 37.616120368351],
            [126.818752966694, 37.616109996787],
            [126.81988052226, 37.615756310108],
            [126.819969499653, 37.615729390597],
            [126.82071439761, 37.61549191867],
            [126.820917425151, 37.61542971055],
            [126.821229718013, 37.615329638693],
            [126.82182008446, 37.615138954738],
            [126.8220270923, 37.615073975746],
            [126.82207478093, 37.615057722511],
            [126.822320154368, 37.614972177901],
            [126.822384120736, 37.61495034516],
            [126.822685654904, 37.614829188193],
            [126.822835825537, 37.614759751883],
            [126.822961504262, 37.614701621665],
            [126.822981596537, 37.61469231791],
            [126.823077860483, 37.614909293354],
            [126.823170227112, 37.615117469258],
            [126.823195944117, 37.615176459239],
            [126.823252441087, 37.61530393415],
            [126.823347012453, 37.615518897611],
            [126.82350929426, 37.61589027414],
            [126.823517966124, 37.615896765265],
            [126.823546419456, 37.615910412974],
            [126.823712365774, 37.615999355572],
            [126.823761606386, 37.616025909548],
            [126.823832858112, 37.61616984026],
            [126.8238976009, 37.616302778185],
            [126.823904192548, 37.616312446609],
            [126.823947344759, 37.616375021525],
            [126.823980810209, 37.616395857479],
            [126.824038760124, 37.616432407296],
            [126.824084756748, 37.616461073559],
            [126.824130087337, 37.616426832396],
            [126.824148673508, 37.616427067557],
            [126.824157229232, 37.616420512273],
            [126.824255363707, 37.616438760402],
            [126.824714672693, 37.616090346771],
            [126.824965992558, 37.615894750894],
            [126.825070551121, 37.615811269308],
            [126.825182000137, 37.615730780168],
            [126.825411310421, 37.615554431409],
            [126.825734005716, 37.615305611359],
            [126.825929781128, 37.615156114885],
            [126.82607784194, 37.615043622486],
            [126.82625718281, 37.614908093248],
            [126.82686970775, 37.614442248574],
            [126.827291486301, 37.614120330119],
            [126.827506597439, 37.613957543048],
            [126.827767592602, 37.613759360561],
            [126.828028176219, 37.613567096323],
            [126.828402127998, 37.613298288392],
            [126.828473470806, 37.613244352222],
            [126.828991247717, 37.612950940205],
            [126.829541058112, 37.612659005142],
            [126.829808956117, 37.61251819357],
            [126.83024367748, 37.61237481255],
            [126.830499973335, 37.612323972495],
            [126.830677335572, 37.612289523608],
            [126.830821771745, 37.612250864305]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281120',
      properties: {
        rgnCode: '41281120',
        colCode: '41280',
        rgnKo: ['토당동'],
        rgnSize: 1,
        rgnBbox: [
          126.790939976443, 37.613501663989, 126.830598951396, 37.633338683871
        ],
        rgnCenter: [126.812336370002, 37.623220574401],
        rgnArea: 4340752,
        predVal: [
          0.46078, 0.46856, 0.44835, 0.50352, 0.63083, 0.57358, 0.62929,
          0.66182, 0.56994, 0.6, 0.59799, 0.45786, 0.65811, 0.58495, 0.66524,
          0.2656, 0.6167, 0.56582, 0.72991, 0.6374, 0.65287, 0.66425, 0.57641,
          0.65276, 0.52253, 0.63675, 0.582, 0.59926, 0.58589, 0.67108, 0.64503
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.824467768461, 37.632401220859],
            [126.824450682338, 37.632368705978],
            [126.824431189924, 37.632328574224],
            [126.824144262428, 37.63173792198],
            [126.824089275759, 37.631617414665],
            [126.824083630996, 37.631604396063],
            [126.824068668834, 37.63156972197],
            [126.823806349327, 37.630961572815],
            [126.823675692469, 37.63065868354],
            [126.823595306978, 37.630472330287],
            [126.823573720726, 37.630422275459],
            [126.823516589376, 37.630289808645],
            [126.8234843115, 37.630214987813],
            [126.823393832455, 37.6300052298],
            [126.823333941517, 37.629866370791],
            [126.823324466678, 37.629844408666],
            [126.823323824505, 37.629842921083],
            [126.823314220628, 37.629822814776],
            [126.823179496795, 37.629540578175],
            [126.823095046932, 37.629363660653],
            [126.82293268609, 37.629023521161],
            [126.822956914287, 37.629029594378],
            [126.82299358662, 37.628900719938],
            [126.823004130143, 37.628897312173],
            [126.823156955078, 37.62884764712],
            [126.826710123213, 37.627692775042],
            [126.82672025831, 37.627689483467],
            [126.826921044271, 37.627624216551],
            [126.827028301532, 37.627589354185],
            [126.827237136057, 37.627521467775],
            [126.827362509125, 37.627480721442],
            [126.827647979237, 37.627387927221],
            [126.827797045586, 37.627339466922],
            [126.827931158632, 37.627295876749],
            [126.828010617454, 37.627270036431],
            [126.828053594818, 37.627256053367],
            [126.828096107317, 37.62724224079],
            [126.828126262371, 37.627232644637],
            [126.830598951396, 37.626445373411],
            [126.830594589198, 37.626421040678],
            [126.830586069466, 37.626411622123],
            [126.830564285315, 37.626401923068],
            [126.83054211058, 37.626226443427],
            [126.830533554891, 37.626168444063],
            [126.830462589986, 37.626097199961],
            [126.830394344141, 37.626050565479],
            [126.830351939349, 37.625731350396],
            [126.830344194112, 37.625655927266],
            [126.830330811993, 37.625499561062],
            [126.830318955661, 37.625492912659],
            [126.830305943166, 37.625452007377],
            [126.830298939417, 37.625408930444],
            [126.830139942106, 37.625280598514],
            [126.83016323298, 37.625003851736],
            [126.829919200211, 37.624944140652],
            [126.829830425024, 37.624919315701],
            [126.829777104333, 37.624924652992],
            [126.829608734077, 37.624941940869],
            [126.829446316666, 37.625005835797],
            [126.829248124268, 37.625158776044],
            [126.829153419031, 37.625234491128],
            [126.829019710777, 37.625354828648],
            [126.828987781635, 37.625181559846],
            [126.8289155948, 37.625012737044],
            [126.828348410908, 37.624945458899],
            [126.828182101525, 37.624928771865],
            [126.828065449119, 37.624850413538],
            [126.827782130451, 37.624709462116],
            [126.827496966687, 37.624364309502],
            [126.827383183788, 37.624241383314],
            [126.827353823063, 37.624022654448],
            [126.827641246672, 37.623746883296],
            [126.82814663582, 37.623259972145],
            [126.828446614086, 37.622300824951],
            [126.828292632758, 37.621899311996],
            [126.828277440538, 37.621856376064],
            [126.828079152902, 37.621618929103],
            [126.828003980815, 37.620987223248],
            [126.827957045216, 37.620600499019],
            [126.827953349166, 37.620567860117],
            [126.828704654427, 37.620390920232],
            [126.828382158346, 37.619322516745],
            [126.82835965568, 37.619257342918],
            [126.82834579583, 37.619260385911],
            [126.827650308765, 37.619413043326],
            [126.827684092488, 37.619346853092],
            [126.827687383573, 37.619339289714],
            [126.827702885748, 37.619302237246],
            [126.827751461892, 37.619192416326],
            [126.827765813222, 37.619158272318],
            [126.827776561086, 37.619134511296],
            [126.827759453702, 37.619048532671],
            [126.827727875685, 37.618892256337],
            [126.82781647436, 37.618712046731],
            [126.827870660837, 37.618615063956],
            [126.827876438423, 37.618604927429],
            [126.827624489524, 37.618671147833],
            [126.827605075198, 37.618666362052],
            [126.827426894825, 37.618621951336],
            [126.827438079307, 37.618590685827],
            [126.827316706418, 37.618564252182],
            [126.827334286072, 37.618534915209],
            [126.827316383356, 37.618508526123],
            [126.827323630755, 37.618494706785],
            [126.827328942168, 37.61846593734],
            [126.827220883641, 37.618451848647],
            [126.827231129789, 37.61839557959],
            [126.827085583023, 37.618380110962],
            [126.826912034292, 37.618360771564],
            [126.826829926084, 37.618351315902],
            [126.82687100058, 37.618330374863],
            [126.82694650588, 37.618291122786],
            [126.826961055039, 37.618269340603],
            [126.827008563018, 37.618199945343],
            [126.827058939424, 37.61806630545],
            [126.827006137743, 37.618026052701],
            [126.827011139185, 37.618023330129],
            [126.827059957012, 37.617994363735],
            [126.827246029185, 37.61788398936],
            [126.827309286104, 37.617841421373],
            [126.827330771298, 37.617827505923],
            [126.827368979242, 37.617801911423],
            [126.827361160585, 37.617793592853],
            [126.827470125922, 37.617811854291],
            [126.827649552754, 37.617855221646],
            [126.827686792955, 37.617865781929],
            [126.827841054633, 37.617917815384],
            [126.827863759666, 37.617798198705],
            [126.827887159794, 37.617749823501],
            [126.82790631503, 37.617714929712],
            [126.827914789057, 37.617699589485],
            [126.82796557616, 37.617607701194],
            [126.828036097938, 37.617480045969],
            [126.828036429549, 37.617434817277],
            [126.828038655548, 37.617110467856],
            [126.827671195641, 37.616927028224],
            [126.827443370235, 37.616704961268],
            [126.827133684387, 37.616567645228],
            [126.826849735677, 37.616710210793],
            [126.82674450268, 37.616762762309],
            [126.826132278237, 37.616773927158],
            [126.826058799457, 37.616809226438],
            [126.825994787668, 37.616816429185],
            [126.825734376714, 37.616774326217],
            [126.825564947765, 37.616623429909],
            [126.825524257888, 37.616587149847],
            [126.82539738229, 37.61625900392],
            [126.825180517287, 37.616008595099],
            [126.825154051415, 37.61597815649],
            [126.824965992558, 37.615894750894],
            [126.824714672693, 37.616090346771],
            [126.824255363707, 37.616438760402],
            [126.824157229232, 37.616420512273],
            [126.824148673508, 37.616427067557],
            [126.824130087337, 37.616426832396],
            [126.824084756748, 37.616461073559],
            [126.824038760124, 37.616432407296],
            [126.823980810209, 37.616395857479],
            [126.823947344759, 37.616375021525],
            [126.823904192548, 37.616312446609],
            [126.8238976009, 37.616302778185],
            [126.823832858112, 37.61616984026],
            [126.823761606386, 37.616025909548],
            [126.823712365774, 37.615999355572],
            [126.823546419456, 37.615910412974],
            [126.823517966124, 37.615896765265],
            [126.82350929426, 37.61589027414],
            [126.823347012453, 37.615518897611],
            [126.823252441087, 37.61530393415],
            [126.823195944117, 37.615176459239],
            [126.823170227112, 37.615117469258],
            [126.823077860483, 37.614909293354],
            [126.822981596537, 37.61469231791],
            [126.822961504262, 37.614701621665],
            [126.822835825537, 37.614759751883],
            [126.822685654904, 37.614829188193],
            [126.822384120736, 37.61495034516],
            [126.822320154368, 37.614972177901],
            [126.82207478093, 37.615057722511],
            [126.8220270923, 37.615073975746],
            [126.82182008446, 37.615138954738],
            [126.821229718013, 37.615329638693],
            [126.820917425151, 37.61542971055],
            [126.82071439761, 37.61549191867],
            [126.819969499653, 37.615729390597],
            [126.81988052226, 37.615756310108],
            [126.818752966694, 37.616109996787],
            [126.818718894098, 37.616120368351],
            [126.817630433479, 37.616471230696],
            [126.817580002456, 37.61648728865],
            [126.816542407257, 37.616820930398],
            [126.816508129039, 37.616831751489],
            [126.815420779921, 37.617173693453],
            [126.815379949208, 37.617186684253],
            [126.813292002914, 37.617844601334],
            [126.813198018429, 37.61787433688],
            [126.813213900236, 37.617846675125],
            [126.813223380056, 37.617828949966],
            [126.813298035988, 37.617711401147],
            [126.813150103566, 37.617781234283],
            [126.813059943371, 37.617821589361],
            [126.812810219467, 37.617886195964],
            [126.812824477841, 37.617860063331],
            [126.812840287851, 37.617829491349],
            [126.812773823486, 37.61780686058],
            [126.812626008502, 37.617789703682],
            [126.812363008848, 37.617765127166],
            [126.812314605484, 37.617749030158],
            [126.812072665774, 37.61772309925],
            [126.811859787389, 37.617582420764],
            [126.811825807649, 37.617528847971],
            [126.811811268536, 37.61741124678],
            [126.811751268616, 37.617403455948],
            [126.811384063352, 37.617357608699],
            [126.811060105836, 37.617319722693],
            [126.810931681534, 37.617298837881],
            [126.810757651438, 37.617381888289],
            [126.81044189688, 37.617378521359],
            [126.81010075497, 37.61735921913],
            [126.809859352951, 37.617345240554],
            [126.80986807506, 37.6172346685],
            [126.809755936232, 37.617205988353],
            [126.809782501072, 37.61713825984],
            [126.809789573798, 37.617101042859],
            [126.809419743844, 37.616962591571],
            [126.80925292232, 37.616902630011],
            [126.80912940692, 37.616858487944],
            [126.808927178586, 37.616785575179],
            [126.808763344807, 37.616726870164],
            [126.808657585348, 37.616688892274],
            [126.808463505616, 37.616618839125],
            [126.808377404267, 37.616587163958],
            [126.808331203333, 37.61656972646],
            [126.808062222135, 37.616473484674],
            [126.807920499742, 37.616423220871],
            [126.807876514063, 37.616407687895],
            [126.807800266752, 37.616380560402],
            [126.807745587468, 37.61636123472],
            [126.807612316681, 37.616314696459],
            [126.807525002437, 37.616283667381],
            [126.807510574278, 37.616278643226],
            [126.807344386213, 37.616220175632],
            [126.807309306388, 37.616207828547],
            [126.807167161722, 37.616250571266],
            [126.806949894136, 37.616317273166],
            [126.806732555553, 37.616298147234],
            [126.806718740455, 37.616296935138],
            [126.806387651044, 37.616169259939],
            [126.806353705994, 37.616156166632],
            [126.806216896228, 37.616103404183],
            [126.806147444778, 37.616076620231],
            [126.80607649705, 37.616050311279],
            [126.805989696605, 37.616018074603],
            [126.805981877622, 37.616015187523],
            [126.805911450409, 37.615989113589],
            [126.805865724361, 37.615972351647],
            [126.805824546422, 37.615957543345],
            [126.805763950711, 37.615935756268],
            [126.8056954121, 37.615910847601],
            [126.805660492161, 37.615898157918],
            [126.805542834379, 37.615854742599],
            [126.805521799029, 37.615846977261],
            [126.805532157723, 37.615797071183],
            [126.805519487304, 37.615579859986],
            [126.805450484014, 37.615555220698],
            [126.80535386941, 37.615521111229],
            [126.805206808971, 37.615469195507],
            [126.804973352804, 37.615386718673],
            [126.804943513006, 37.615376208598],
            [126.804736942952, 37.615303497647],
            [126.804703849623, 37.615276917619],
            [126.804401241052, 37.615033904865],
            [126.80429583574, 37.614970795327],
            [126.804267295816, 37.614952809121],
            [126.804143065028, 37.614890271636],
            [126.804095146811, 37.614876262338],
            [126.804077186556, 37.614870790374],
            [126.80397707538, 37.614840313884],
            [126.803997475836, 37.614822454572],
            [126.804324574413, 37.614639003493],
            [126.804423504517, 37.614584794625],
            [126.804233768191, 37.614559115016],
            [126.803964117509, 37.614472359079],
            [126.803742305333, 37.614399864128],
            [126.803660770193, 37.614373517956],
            [126.803590803384, 37.614349831001],
            [126.803530563755, 37.61433117878],
            [126.803428512943, 37.614297869493],
            [126.803239705463, 37.61427682088],
            [126.80308310424, 37.614283369077],
            [126.802720636366, 37.614218618299],
            [126.802579910998, 37.614226462951],
            [126.802392323668, 37.614193981606],
            [126.802350849662, 37.614112445023],
            [126.802293524943, 37.613816475487],
            [126.80193619076, 37.613501663989],
            [126.80108730826, 37.61421814413],
            [126.800515227536, 37.614676112224],
            [126.800175860702, 37.614953386857],
            [126.799612637294, 37.615474172904],
            [126.799363985888, 37.615714425906],
            [126.798998550487, 37.616082669385],
            [126.798888949882, 37.616193508952],
            [126.798653141073, 37.616420105605],
            [126.797991540457, 37.617047408579],
            [126.797781047462, 37.617329808715],
            [126.797632989338, 37.617556807012],
            [126.79752573278, 37.617707778945],
            [126.797087704608, 37.618329732715],
            [126.79662451571, 37.618979301159],
            [126.796552375768, 37.619073931846],
            [126.796426088943, 37.619215463461],
            [126.796372806202, 37.619273574089],
            [126.79623459482, 37.619467440648],
            [126.79608957426, 37.619669890525],
            [126.796038578394, 37.619752908034],
            [126.79598987546, 37.619821766125],
            [126.795946661347, 37.619875866714],
            [126.795919010127, 37.619905406688],
            [126.795514703125, 37.620365750479],
            [126.795386230822, 37.620558876183],
            [126.79511112171, 37.620930580612],
            [126.794854493022, 37.621270566252],
            [126.794568406236, 37.621645016143],
            [126.794535336426, 37.621687880763],
            [126.794454300998, 37.62179823461],
            [126.794374680182, 37.62190488786],
            [126.794326681018, 37.62197215174],
            [126.794300924779, 37.622008145656],
            [126.794261425864, 37.622061405208],
            [126.794248071857, 37.622081482584],
            [126.793984005485, 37.62244149095],
            [126.793815133532, 37.622613749227],
            [126.793206129668, 37.623242135263],
            [126.793182796155, 37.623266087123],
            [126.792898431849, 37.623561574126],
            [126.792682049302, 37.623785661104],
            [126.792474353032, 37.623997861141],
            [126.792292540207, 37.624165256007],
            [126.791920202336, 37.624552060891],
            [126.791902105778, 37.624570417709],
            [126.791722399824, 37.624757042291],
            [126.791526644527, 37.624990930368],
            [126.791509725768, 37.625013343622],
            [126.791356080125, 37.6251969687],
            [126.791219335105, 37.625356369365],
            [126.790939976443, 37.625622623711],
            [126.791088824247, 37.625715122347],
            [126.791122759614, 37.62573625673],
            [126.791236834516, 37.625807493164],
            [126.791280719447, 37.625843439289],
            [126.791541959107, 37.626022090827],
            [126.791814911775, 37.626205249468],
            [126.792025967183, 37.626635543926],
            [126.792301664047, 37.627045257148],
            [126.792564015425, 37.627084085637],
            [126.792694902249, 37.627018500914],
            [126.792784860136, 37.627098910161],
            [126.79300613401, 37.6272967236],
            [126.793092917356, 37.6273742709],
            [126.793162117351, 37.627436398391],
            [126.793437811036, 37.627683932937],
            [126.79352355465, 37.627760919476],
            [126.793616440457, 37.627844153302],
            [126.793662115107, 37.627885057061],
            [126.793715970969, 37.627933309168],
            [126.79375633471, 37.627969455386],
            [126.793780912843, 37.627991275286],
            [126.79387227586, 37.62807237091],
            [126.793942401851, 37.628136013209],
            [126.794040483558, 37.628225013083],
            [126.793914854825, 37.628338495491],
            [126.793859613036, 37.628515440371],
            [126.793965894843, 37.628702931572],
            [126.794398890356, 37.62892181002],
            [126.794582468962, 37.628855495813],
            [126.794702166323, 37.628785249228],
            [126.794789002591, 37.628734514184],
            [126.795539579301, 37.628395347365],
            [126.795927324038, 37.628185411053],
            [126.7962616362, 37.628056126139],
            [126.796567444762, 37.627877579371],
            [126.796699298458, 37.627830416885],
            [126.796879506534, 37.627851361931],
            [126.797011637265, 37.62786011425],
            [126.797097205043, 37.62783313408],
            [126.797255415549, 37.627675466667],
            [126.797452478695, 37.627597341478],
            [126.797744056076, 37.627484574443],
            [126.79776208989, 37.627476487775],
            [126.798032406494, 37.627395848215],
            [126.79841973417, 37.627243115031],
            [126.79884355171, 37.627105705813],
            [126.799100424189, 37.627014940746],
            [126.79941797057, 37.626932000428],
            [126.799621973231, 37.626841440982],
            [126.799860373346, 37.626731866268],
            [126.800283422586, 37.626616866877],
            [126.800591629695, 37.626715634707],
            [126.800795796377, 37.626702350379],
            [126.800899965318, 37.626615150689],
            [126.800974749628, 37.626439244743],
            [126.801048820147, 37.626267473021],
            [126.80125825113, 37.625883309243],
            [126.80165566853, 37.625493751409],
            [126.801859133228, 37.625284194975],
            [126.802038673393, 37.625095509443],
            [126.802150575935, 37.624830000093],
            [126.802335988583, 37.624681309426],
            [126.802553061957, 37.624302895365],
            [126.802606394727, 37.624178794429],
            [126.802850914779, 37.623680145432],
            [126.802855128855, 37.623671548183],
            [126.802985135437, 37.623611851917],
            [126.803025338769, 37.623555770575],
            [126.803078059892, 37.623507864124],
            [126.803252307125, 37.623338854158],
            [126.80335449914, 37.623229701086],
            [126.803384748792, 37.623197848233],
            [126.803472303258, 37.623106554901],
            [126.803564692658, 37.623007350012],
            [126.803681852804, 37.622883301554],
            [126.803799553029, 37.622760479211],
            [126.803846545358, 37.622711085206],
            [126.803863384242, 37.622704410131],
            [126.80398544782, 37.622653276761],
            [126.804012503971, 37.62264233011],
            [126.804132091471, 37.622615077385],
            [126.804144877726, 37.622611693073],
            [126.804211358318, 37.622615948749],
            [126.804229365948, 37.622617060031],
            [126.804257883504, 37.622618891642],
            [126.804291230635, 37.622657761537],
            [126.804334526295, 37.622699774442],
            [126.804510558748, 37.622852622142],
            [126.804895391342, 37.623022539828],
            [126.804954120772, 37.623260477972],
            [126.804912040084, 37.623625718591],
            [126.804925217495, 37.623839479651],
            [126.804996436954, 37.624049219908],
            [126.805154003233, 37.624183061259],
            [126.805342705344, 37.624279806828],
            [126.80577862694, 37.62433615712],
            [126.805921508562, 37.624515517537],
            [126.805904085453, 37.624714803071],
            [126.805396564661, 37.624733944363],
            [126.805107484359, 37.624719822748],
            [126.804739941189, 37.624704137053],
            [126.804312886561, 37.624451015081],
            [126.804250827027, 37.624470624757],
            [126.804215499574, 37.624482125246],
            [126.804222278382, 37.624617445496],
            [126.804228247209, 37.624754935746],
            [126.804291444788, 37.625007828847],
            [126.804337349439, 37.62511332001],
            [126.804557475266, 37.625279089203],
            [126.804798738555, 37.625462525364],
            [126.804879921052, 37.62566619163],
            [126.80496157938, 37.625861398443],
            [126.805052214742, 37.626024617399],
            [126.805203917365, 37.626124013501],
            [126.80525430428, 37.626158379531],
            [126.805702277229, 37.626236761037],
            [126.805917304373, 37.626331873522],
            [126.806018147986, 37.626408939111],
            [126.806114218103, 37.626362274409],
            [126.806234764967, 37.626303712179],
            [126.806615202762, 37.626121658851],
            [126.806695947177, 37.626083014092],
            [126.806722612428, 37.626070255215],
            [126.806739106712, 37.626095987347],
            [126.806768625928, 37.62613993172],
            [126.806820509788, 37.626217375313],
            [126.806926194103, 37.626371553815],
            [126.807139448105, 37.62669592401],
            [126.80727210333, 37.626890240136],
            [126.807423335065, 37.627111552933],
            [126.807581530058, 37.627203766841],
            [126.807590857189, 37.627285032357],
            [126.807592362952, 37.627298450392],
            [126.807633345987, 37.627364667534],
            [126.807871651627, 37.627759227651],
            [126.807906281519, 37.62785981558],
            [126.807875765201, 37.628007570914],
            [126.80780233103, 37.628061743304],
            [126.807771639063, 37.628085028239],
            [126.807756960382, 37.628097077239],
            [126.807770886087, 37.62813944606],
            [126.807900111159, 37.628565946772],
            [126.807910013018, 37.628661673879],
            [126.807955785472, 37.628772920608],
            [126.80803346616, 37.628894400689],
            [126.808213225534, 37.629060213908],
            [126.808445747494, 37.62919766032],
            [126.80847406504, 37.629286597101],
            [126.808521800784, 37.629313731544],
            [126.808516670092, 37.629322075215],
            [126.808508433637, 37.629335486309],
            [126.808499217859, 37.629350859931],
            [126.808479362179, 37.629380343476],
            [126.808464571199, 37.629405096161],
            [126.807997024875, 37.630191280097],
            [126.808030081332, 37.630208858318],
            [126.808222823523, 37.63027954956],
            [126.80844250143, 37.630360546697],
            [126.808658898262, 37.630440096498],
            [126.808849602302, 37.630510864472],
            [126.809262134875, 37.630664162916],
            [126.809620196075, 37.63005712658],
            [126.809638151546, 37.630026378388],
            [126.809677113633, 37.630047137192],
            [126.809739920454, 37.630086251613],
            [126.809763964311, 37.630102057794],
            [126.809787026779, 37.630111825827],
            [126.809864984363, 37.630144820131],
            [126.810142938246, 37.630265389577],
            [126.810158905087, 37.630272316959],
            [126.810296131944, 37.630337094459],
            [126.810482935821, 37.630250254497],
            [126.810682647695, 37.630241126402],
            [126.810786477591, 37.630284027641],
            [126.810849675276, 37.630307915582],
            [126.81096008496, 37.630351719267],
            [126.811043485209, 37.630476296931],
            [126.810962788627, 37.630681580485],
            [126.810890024147, 37.630863712664],
            [126.811037198917, 37.631044046706],
            [126.811148452874, 37.631090428369],
            [126.811191117348, 37.631107949125],
            [126.811266949174, 37.631140011064],
            [126.811498828712, 37.631236410871],
            [126.81174168768, 37.631336756142],
            [126.811809299406, 37.631367209781],
            [126.81191130341, 37.631411755877],
            [126.811986293048, 37.631445581914],
            [126.812099651353, 37.631502462444],
            [126.812126885009, 37.631565223189],
            [126.812110854285, 37.631654709552],
            [126.812098528028, 37.631726344488],
            [126.812155155427, 37.631781971617],
            [126.812177668759, 37.631803108633],
            [126.812231399167, 37.631826259856],
            [126.812538873201, 37.631960845144],
            [126.812497933923, 37.631879114992],
            [126.812439196573, 37.631761857801],
            [126.812431943554, 37.631749466751],
            [126.812478732009, 37.631758191101],
            [126.812624879567, 37.631787085238],
            [126.813055714931, 37.631871799293],
            [126.81398496704, 37.632058484718],
            [126.815185061717, 37.632300117979],
            [126.815233233388, 37.632308672235],
            [126.816365288743, 37.632534445983],
            [126.816431508747, 37.632542559561],
            [126.817566128028, 37.632765199418],
            [126.817596486842, 37.632770913889],
            [126.817697752313, 37.632790920218],
            [126.817714613173, 37.632794144953],
            [126.818262670473, 37.632901564985],
            [126.81835801353, 37.632920642552],
            [126.819181283485, 37.633080786926],
            [126.819376887436, 37.633118912052],
            [126.819537862961, 37.633150694632],
            [126.819992449236, 37.633249024739],
            [126.820237017243, 37.633301486225],
            [126.820410623587, 37.633338683871],
            [126.820446106384, 37.633329233019],
            [126.820637637456, 37.633281459653],
            [126.820729650532, 37.633258589713],
            [126.820811460168, 37.633238722349],
            [126.820985216975, 37.633195047671],
            [126.821006436983, 37.633190043674],
            [126.821249951505, 37.633130113557],
            [126.821689821988, 37.633022217006],
            [126.82195768629, 37.632955538175],
            [126.822313546336, 37.632868251915],
            [126.823053973406, 37.632688124522],
            [126.8232373482, 37.632643479002],
            [126.823673019548, 37.63253629849],
            [126.823869712179, 37.632488662793],
            [126.823962595018, 37.63246604391],
            [126.82403819097, 37.632447741664],
            [126.824092510398, 37.632434551959],
            [126.824280768441, 37.632389173356],
            [126.824314436038, 37.632380412364],
            [126.82435592669, 37.632369068294],
            [126.824373076181, 37.632374869306],
            [126.824448308492, 37.63239515511],
            [126.824467768461, 37.632401220859]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281115',
      properties: {
        rgnCode: '41281115',
        colCode: '41280',
        rgnKo: ['선유동'],
        rgnSize: 1,
        rgnBbox: [
          126.897461119929, 37.672070844998, 126.925362131891, 37.713781839486
        ],
        rgnCenter: [126.912205721589, 37.693180237064],
        rgnArea: 4535038,
        predVal: [
          0.32913, 0.33469, 0.32025, 0.35966, 0.4506, 0.4097, 0.44949, 0.47273,
          0.4071, 0.42857, 0.42713, 0.32704, 0.47008, 0.41782, 0.47517, 0.18971,
          0.4405, 0.40416, 0.52136, 0.45529, 0.46633, 0.47447, 0.41172, 0.46626,
          0.37323, 0.45482, 0.41571, 0.42804, 0.41849, 0.47934, 0.46073
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.92054230079, 37.713781839486],
            [126.920748855635, 37.713597337252],
            [126.920965349945, 37.713376253222],
            [126.921398040032, 37.713197219817],
            [126.921398024103, 37.713212013664],
            [126.921537762247, 37.71321947844],
            [126.922454888459, 37.712933229493],
            [126.923079040926, 37.712585504158],
            [126.923778599864, 37.712199750417],
            [126.924572472526, 37.711826847803],
            [126.924688144902, 37.710832734164],
            [126.924812267895, 37.709954868416],
            [126.924484927101, 37.70954139976],
            [126.924591895692, 37.7094500125],
            [126.924577848741, 37.709226689799],
            [126.925362131891, 37.708625993051],
            [126.924444774107, 37.707325392227],
            [126.923760294691, 37.706893019358],
            [126.923642977136, 37.706657339114],
            [126.923483873202, 37.706528521803],
            [126.923375598412, 37.70641937905],
            [126.923384322759, 37.705259939479],
            [126.923586710044, 37.70478085804],
            [126.922903356698, 37.704565352571],
            [126.922546871267, 37.704377803285],
            [126.922252637977, 37.703609513281],
            [126.922388448346, 37.703156066561],
            [126.922178927797, 37.702973002366],
            [126.922189266569, 37.702571457156],
            [126.922534817724, 37.70207946356],
            [126.922497597601, 37.70177335292],
            [126.922062984061, 37.701492571637],
            [126.922045948587, 37.700964944493],
            [126.92233589147, 37.700350093923],
            [126.922225528557, 37.70015674415],
            [126.92170913262, 37.699593633232],
            [126.921684977206, 37.699141835521],
            [126.921463672406, 37.698773315712],
            [126.921055091241, 37.698472078257],
            [126.921116021169, 37.698067756325],
            [126.920638672485, 37.697539408481],
            [126.920465950367, 37.697016899489],
            [126.920412903842, 37.696854003987],
            [126.920224741778, 37.69669488054],
            [126.920124480924, 37.696615823485],
            [126.920042138641, 37.696502677073],
            [126.919178222173, 37.696012643939],
            [126.919092802043, 37.695762610566],
            [126.919017179418, 37.695266556499],
            [126.918732235481, 37.694284985743],
            [126.918871763122, 37.693377240453],
            [126.918677012505, 37.692761129398],
            [126.917895571759, 37.69247650235],
            [126.917683408503, 37.692099115993],
            [126.917653434445, 37.691834209781],
            [126.917330027073, 37.691536974884],
            [126.917734998713, 37.691069074678],
            [126.916919572252, 37.690479519938],
            [126.917059559186, 37.689829715067],
            [126.916053339017, 37.688915704452],
            [126.915293419172, 37.688182843229],
            [126.914939836426, 37.686996340438],
            [126.914731167742, 37.68638984337],
            [126.914234848016, 37.684951646099],
            [126.914253667179, 37.684738814844],
            [126.91426039619, 37.684539921651],
            [126.914323316496, 37.684161425559],
            [126.914394155234, 37.683680828295],
            [126.914424379899, 37.683476321347],
            [126.914439689079, 37.6834238241],
            [126.914425835538, 37.682822776525],
            [126.91442654666, 37.682350281106],
            [126.914425375967, 37.682052284238],
            [126.914424761895, 37.681937554194],
            [126.914280695773, 37.681794724968],
            [126.914490940872, 37.681649085233],
            [126.914632408084, 37.681551488282],
            [126.914576316092, 37.681405471682],
            [126.914424211169, 37.681012310996],
            [126.914372307244, 37.680853035089],
            [126.914296003608, 37.68061032924],
            [126.914282582639, 37.680560964134],
            [126.914178931432, 37.680245412495],
            [126.914076852568, 37.679933483816],
            [126.913915633034, 37.679516538996],
            [126.913865854921, 37.67938289703],
            [126.913624912092, 37.679061818884],
            [126.913430793424, 37.678842278607],
            [126.913171142024, 37.678547016373],
            [126.913114273558, 37.678371842999],
            [126.913106893377, 37.678123908231],
            [126.913101547816, 37.677833160827],
            [126.913135981713, 37.677358960428],
            [126.91313095792, 37.677303366736],
            [126.913104967059, 37.677015135547],
            [126.913093371523, 37.676746961744],
            [126.913066021274, 37.676620598021],
            [126.91301247086, 37.676373277238],
            [126.912801839066, 37.675977907867],
            [126.912737742412, 37.675896997446],
            [126.912481710273, 37.675562453918],
            [126.912453430487, 37.675532177942],
            [126.912221698894, 37.675324842335],
            [126.911878234675, 37.67504292907],
            [126.911454500663, 37.674766701724],
            [126.910104755749, 37.674185356483],
            [126.909505420964, 37.673979216624],
            [126.909102777886, 37.673882560917],
            [126.908799394656, 37.673834219934],
            [126.908586345063, 37.673865171829],
            [126.908331370187, 37.673902135742],
            [126.908033243447, 37.674037261821],
            [126.908000046355, 37.674052308762],
            [126.907714379316, 37.674256719624],
            [126.907616241873, 37.674380083667],
            [126.907559375805, 37.674449602404],
            [126.907299083455, 37.674763455461],
            [126.907037000936, 37.675146447051],
            [126.90696401246, 37.675262469736],
            [126.906855651802, 37.675436288343],
            [126.906781528848, 37.67545452756],
            [126.906598146257, 37.675452812431],
            [126.906302486291, 37.675450970391],
            [126.906289978849, 37.675481061752],
            [126.906265697355, 37.675605961843],
            [126.906231357062, 37.675654325284],
            [126.906150620274, 37.675764944376],
            [126.90604920189, 37.675906314846],
            [126.905851517734, 37.676024353111],
            [126.905833772386, 37.676028438136],
            [126.905816999798, 37.676051804737],
            [126.9055468282, 37.676404234421],
            [126.905598553824, 37.676473633274],
            [126.905604531488, 37.676734856754],
            [126.905573274268, 37.676949911199],
            [126.905534475106, 37.677035706154],
            [126.905316617102, 37.67724449993],
            [126.904887255406, 37.677523314867],
            [126.904715268072, 37.677602792656],
            [126.904489203595, 37.677625257311],
            [126.904342096011, 37.67770598935],
            [126.90427410471, 37.677731484853],
            [126.904210911656, 37.677754488585],
            [126.9037983317, 37.677851461365],
            [126.903056367442, 37.678024335288],
            [126.902729784606, 37.678073750753],
            [126.902333103616, 37.678095248358],
            [126.902102507865, 37.678037301966],
            [126.901689356644, 37.677812908509],
            [126.901387493815, 37.677611817148],
            [126.901075347223, 37.677262245209],
            [126.90091907219, 37.677069772685],
            [126.900561863102, 37.67646661858],
            [126.900196952278, 37.675754628382],
            [126.900188561782, 37.67573960198],
            [126.899991938383, 37.675521893339],
            [126.899868653843, 37.675339214294],
            [126.899760169388, 37.675101759807],
            [126.899557436333, 37.67466245119],
            [126.899349811166, 37.674213173209],
            [126.89928382105, 37.67407000569],
            [126.899276336013, 37.674056241364],
            [126.899161574537, 37.673844323383],
            [126.89900241984, 37.673545675205],
            [126.8988888408, 37.673332289385],
            [126.898794287023, 37.673156868952],
            [126.898770974372, 37.673121350357],
            [126.898672000531, 37.672970522501],
            [126.89848887348, 37.672690160431],
            [126.898470100227, 37.672659150598],
            [126.898384264948, 37.672516451725],
            [126.898322857363, 37.672414317896],
            [126.89827422299, 37.672337782819],
            [126.898204118778, 37.672213297209],
            [126.898151764003, 37.672122433365],
            [126.898112117978, 37.672070844998],
            [126.898022727814, 37.67208928157],
            [126.897954582687, 37.672105259058],
            [126.897544823113, 37.672228412811],
            [126.897742401043, 37.67241676381],
            [126.897812769326, 37.67249768793],
            [126.897963822937, 37.67267460935],
            [126.898139389735, 37.672871643732],
            [126.898170540843, 37.672910782219],
            [126.898198055258, 37.672948602093],
            [126.898410607291, 37.673270272855],
            [126.898473782685, 37.673356947492],
            [126.898619686477, 37.673563541558],
            [126.898765109271, 37.673749628902],
            [126.899083626736, 37.674146261423],
            [126.899235209491, 37.674520377288],
            [126.899314785811, 37.674716885105],
            [126.899357211267, 37.674884196241],
            [126.899110299598, 37.675036282346],
            [126.89920797561, 37.675325912326],
            [126.897761305224, 37.675764466789],
            [126.897718759541, 37.675783322725],
            [126.897571888203, 37.67584645058],
            [126.897587614496, 37.675949265273],
            [126.897621702059, 37.676216343215],
            [126.897616424206, 37.676278136189],
            [126.89762204219, 37.676411403805],
            [126.897558770303, 37.677164569024],
            [126.897529965975, 37.677562772884],
            [126.897825594974, 37.678407225029],
            [126.897720418352, 37.678465785725],
            [126.897618638359, 37.678519069629],
            [126.897571431291, 37.678554688465],
            [126.897538227458, 37.678579733219],
            [126.897572113589, 37.678618153461],
            [126.8976276265, 37.678893908491],
            [126.897461119929, 37.679245725985],
            [126.897619810652, 37.679564314032],
            [126.897651787544, 37.67963090592],
            [126.89767406894, 37.679667261693],
            [126.897982747005, 37.679914453124],
            [126.898043974166, 37.679979187548],
            [126.898086464713, 37.680058870633],
            [126.898167208896, 37.680208232267],
            [126.898287872043, 37.680461114283],
            [126.898296252636, 37.680483393613],
            [126.898361129541, 37.680532661394],
            [126.898403064354, 37.680563862641],
            [126.898631100404, 37.680779682088],
            [126.898737031303, 37.68088289955],
            [126.899068563377, 37.681161029318],
            [126.899509085263, 37.681273834267],
            [126.899581254798, 37.681305160345],
            [126.899598811349, 37.681315951126],
            [126.899991546747, 37.681599149562],
            [126.9002281526, 37.68167098917],
            [126.900295314066, 37.681717581958],
            [126.900296422559, 37.681736088843],
            [126.900492751473, 37.682158587089],
            [126.900700475739, 37.68235497818],
            [126.901072591415, 37.682669500104],
            [126.901316586639, 37.682746173011],
            [126.901581581484, 37.682845360439],
            [126.901706258606, 37.682906398644],
            [126.901761085656, 37.682971603207],
            [126.901830584778, 37.683077354799],
            [126.902069184157, 37.68358839737],
            [126.90238123436, 37.683676000143],
            [126.902518091901, 37.683726434291],
            [126.902841314816, 37.683870211706],
            [126.903016418363, 37.683949985829],
            [126.903185279321, 37.684061396559],
            [126.903383995217, 37.684134801941],
            [126.903866048188, 37.684297196501],
            [126.904050974315, 37.684253526288],
            [126.90443340168, 37.684659458467],
            [126.903936394373, 37.685023031176],
            [126.903388685425, 37.685393641213],
            [126.903352764575, 37.685414928268],
            [126.903460090834, 37.685561866647],
            [126.90368154728, 37.685855406376],
            [126.903701533385, 37.686097937016],
            [126.903712510539, 37.686138913219],
            [126.903730668854, 37.68619351804],
            [126.904111935848, 37.686790807139],
            [126.904086604187, 37.687305528231],
            [126.904350212216, 37.687370642564],
            [126.904692499922, 37.687637412889],
            [126.904953183984, 37.68785167899],
            [126.905042899445, 37.688146801933],
            [126.905085711063, 37.688250565571],
            [126.905186121685, 37.688482043635],
            [126.905405689776, 37.688493773213],
            [126.905490047432, 37.688646457155],
            [126.905694976876, 37.689012624665],
            [126.905766396457, 37.689158928029],
            [126.906085993911, 37.689766377366],
            [126.906242034246, 37.690217817065],
            [126.905710265807, 37.690532626365],
            [126.905548865209, 37.690626754526],
            [126.905387755508, 37.690723576599],
            [126.904481223886, 37.690791596118],
            [126.904210124626, 37.691218071704],
            [126.904060468837, 37.69126722212],
            [126.903683075867, 37.691397712322],
            [126.903758203895, 37.691602465778],
            [126.903725755382, 37.691627945307],
            [126.903606859739, 37.691727665008],
            [126.903278502523, 37.69195094936],
            [126.903275950906, 37.692226400466],
            [126.903273120278, 37.692369562066],
            [126.903672588067, 37.69256283667],
            [126.903854942862, 37.69264546252],
            [126.903990356242, 37.692703804203],
            [126.904266337621, 37.693136731403],
            [126.904468498249, 37.693474689153],
            [126.904702191849, 37.69366477842],
            [126.904900049083, 37.693844107326],
            [126.905032816602, 37.693995389562],
            [126.905037449032, 37.694244204849],
            [126.905030132055, 37.694518363605],
            [126.905064539948, 37.694678989399],
            [126.905237037893, 37.695269562052],
            [126.905176559637, 37.695505332137],
            [126.905269418926, 37.695621659919],
            [126.905314723379, 37.695721470128],
            [126.905356054843, 37.69581607849],
            [126.905404904739, 37.695910017238],
            [126.905431400132, 37.69594733892],
            [126.90548576042, 37.695988791707],
            [126.905904853057, 37.69628287496],
            [126.906106237697, 37.696477881476],
            [126.906270076631, 37.69663202544],
            [126.906859509849, 37.696789367643],
            [126.907146335265, 37.696922021844],
            [126.90724562709, 37.697164795068],
            [126.907310961283, 37.69731686764],
            [126.907536487048, 37.697830743578],
            [126.908530211138, 37.698167592462],
            [126.908713343603, 37.698369391394],
            [126.908749602288, 37.698408368771],
            [126.909174976147, 37.699153938281],
            [126.908519150848, 37.699968771727],
            [126.908697578341, 37.700418999748],
            [126.908387275351, 37.700875906068],
            [126.908820893229, 37.701001302044],
            [126.90926042618, 37.701927174655],
            [126.909935160954, 37.702275636241],
            [126.909460135359, 37.703432216495],
            [126.910283844453, 37.703752312644],
            [126.911277977928, 37.704135574246],
            [126.91152803933, 37.705677148735],
            [126.911764259992, 37.706921581955],
            [126.911773488505, 37.706951816413],
            [126.912097239038, 37.707897257466],
            [126.912114906598, 37.707954527288],
            [126.912702285925, 37.708778058144],
            [126.912971358192, 37.709153269763],
            [126.913300010297, 37.709514866689],
            [126.91403670152, 37.709328140713],
            [126.914405042406, 37.709349045556],
            [126.914619967136, 37.709360790578],
            [126.914985081179, 37.709387232174],
            [126.915501141307, 37.71005927115],
            [126.916071358652, 37.710389520489],
            [126.916306193831, 37.71033822725],
            [126.916726633031, 37.71077314743],
            [126.916887616243, 37.710912516416],
            [126.917275961965, 37.711261999974],
            [126.917620732676, 37.711345864465],
            [126.918054638889, 37.711939665765],
            [126.918181225004, 37.712083315223],
            [126.918630963759, 37.712440315649],
            [126.919264925894, 37.713090220859],
            [126.919527411898, 37.713393650748],
            [126.920087019494, 37.713561474051],
            [126.92054230079, 37.713781839486]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281108',
      properties: {
        rgnCode: '41281108',
        colCode: '41280',
        rgnKo: ['효자동'],
        rgnSize: 1,
        rgnBbox: [
          126.947680441735, 37.65486634741, 126.99432919518, 37.686554992336
        ],
        rgnCenter: [126.972180467198, 37.670640240965],
        rgnArea: 8834975,
        predVal: [
          0.32913, 0.33469, 0.32025, 0.35966, 0.4506, 0.4097, 0.44949, 0.47273,
          0.4071, 0.42857, 0.42713, 0.32704, 0.47008, 0.41782, 0.47517, 0.18971,
          0.4405, 0.40416, 0.52136, 0.45529, 0.46633, 0.47447, 0.41172, 0.46626,
          0.37323, 0.45482, 0.41571, 0.42804, 0.41849, 0.47934, 0.46073
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.98724440335, 37.661179387718],
            [126.986995696316, 37.661170726023],
            [126.983027230667, 37.661105633827],
            [126.98021574644, 37.661012845739],
            [126.978173914025, 37.661375263509],
            [126.976204128988, 37.66104158691],
            [126.975291441324, 37.661197138881],
            [126.97251637235, 37.661611766115],
            [126.97077291106, 37.658801249201],
            [126.970452036893, 37.658433441433],
            [126.968160620679, 37.657604594881],
            [126.966040409942, 37.658351388648],
            [126.964908469458, 37.657691511424],
            [126.963106622904, 37.658763269217],
            [126.96176951482, 37.659242847326],
            [126.960718393287, 37.659655134649],
            [126.959712151871, 37.659014162581],
            [126.958643853652, 37.6585970875],
            [126.957463505085, 37.657859709552],
            [126.957309974248, 37.657793052078],
            [126.956398969738, 37.657621161596],
            [126.955394590541, 37.657426721257],
            [126.954685905859, 37.657435298608],
            [126.954259299783, 37.657217164703],
            [126.953839400595, 37.657059280131],
            [126.953661249864, 37.656567257821],
            [126.953362419302, 37.656149147014],
            [126.953207757767, 37.655757321929],
            [126.953365632614, 37.655665730312],
            [126.954058205659, 37.655786354191],
            [126.954187928504, 37.655607445685],
            [126.954299574804, 37.655322340899],
            [126.954231383273, 37.655222341412],
            [126.954192403137, 37.65521728032],
            [126.953961655869, 37.655302347667],
            [126.953939782699, 37.655287211479],
            [126.953371562014, 37.654977333879],
            [126.95321091784, 37.654937787483],
            [126.952834211104, 37.654934073766],
            [126.952297917438, 37.654921039355],
            [126.95186135346, 37.65486634741],
            [126.95136755702, 37.654953821394],
            [126.950860083942, 37.655471079476],
            [126.95075201133, 37.655547624886],
            [126.95026986038, 37.655781219366],
            [126.950046015424, 37.655888896508],
            [126.949790745736, 37.656011614736],
            [126.949790616827, 37.656197431707],
            [126.949737751126, 37.656245682739],
            [126.949631653123, 37.65644529211],
            [126.949526445032, 37.656733035111],
            [126.94945715429, 37.656770133715],
            [126.949222456141, 37.656770029902],
            [126.949065895193, 37.656852039178],
            [126.94882691299, 37.656980321553],
            [126.948584825264, 37.657012197768],
            [126.948245039468, 37.65705871529],
            [126.948128080391, 37.657067311768],
            [126.947896056437, 37.657129373771],
            [126.947767092438, 37.6572842827],
            [126.947827616837, 37.657430718624],
            [126.947993444789, 37.657703969451],
            [126.948041023737, 37.657887879918],
            [126.948044226365, 37.658004827851],
            [126.947922555788, 37.658259478099],
            [126.947948752979, 37.65839166294],
            [126.948006989683, 37.658569271418],
            [126.947970063756, 37.658700256329],
            [126.947803572642, 37.658872627116],
            [126.94775253643, 37.658925400958],
            [126.947680441735, 37.659027763803],
            [126.947793345006, 37.659226029614],
            [126.947890687061, 37.659395006576],
            [126.947996348916, 37.659578492912],
            [126.948158545209, 37.659850525446],
            [126.948229867804, 37.659972369391],
            [126.948336053665, 37.660281505488],
            [126.948373761399, 37.660373547975],
            [126.948349480614, 37.660539712687],
            [126.94830775331, 37.660744097849],
            [126.948303925679, 37.660755781755],
            [126.948314516569, 37.660906636608],
            [126.94832256018, 37.661085528617],
            [126.948329694142, 37.661141581424],
            [126.948338156324, 37.661194526468],
            [126.948375352981, 37.661368223976],
            [126.948444281262, 37.661624140605],
            [126.948650033048, 37.661902904214],
            [126.948722296502, 37.661964373985],
            [126.948928474753, 37.662139444211],
            [126.94916241144, 37.662279595642],
            [126.949438184391, 37.6624470736],
            [126.949644729953, 37.662522089589],
            [126.949786192118, 37.662574408192],
            [126.950368331813, 37.662829178129],
            [126.950758389169, 37.663224567735],
            [126.951049852572, 37.663613129552],
            [126.95128825876, 37.663978720246],
            [126.951376088494, 37.66440950403],
            [126.951425378387, 37.664680312539],
            [126.951452339155, 37.664835687839],
            [126.951617858331, 37.665208256405],
            [126.951672088551, 37.665330523396],
            [126.951768474425, 37.665547761722],
            [126.951826807903, 37.665636216838],
            [126.952131807632, 37.666110509128],
            [126.952600673677, 37.666835591462],
            [126.953049700793, 37.667145116924],
            [126.953665454681, 37.667583592606],
            [126.953692386195, 37.667634490482],
            [126.954017887571, 37.667947971397],
            [126.954176424368, 37.668102425854],
            [126.954331108799, 37.668273744765],
            [126.9544238697, 37.668398422424],
            [126.954495725167, 37.668495296664],
            [126.954636152068, 37.668685250606],
            [126.954733024769, 37.668815695874],
            [126.95477913938, 37.668968582209],
            [126.95478124998, 37.669037065984],
            [126.95478607891, 37.669199035768],
            [126.954776553055, 37.669333321977],
            [126.954764489016, 37.669520557342],
            [126.954542397106, 37.669848810561],
            [126.95418174048, 37.670332560728],
            [126.954019155174, 37.670600660829],
            [126.953884221422, 37.670821912286],
            [126.953778527942, 37.670994207566],
            [126.953688345881, 37.67113963303],
            [126.953644186121, 37.671174023251],
            [126.953501370419, 37.671187425723],
            [126.953495517516, 37.671211452275],
            [126.953436171752, 37.671346961376],
            [126.953319997338, 37.671650829959],
            [126.953224420117, 37.671898206874],
            [126.953272756135, 37.672137155498],
            [126.953327120461, 37.672397045151],
            [126.953369323796, 37.672532424454],
            [126.953447320019, 37.672778286532],
            [126.953558220282, 37.673045172603],
            [126.953804949023, 37.673419905885],
            [126.953962696402, 37.673692117215],
            [126.954087368352, 37.673786904627],
            [126.954413464947, 37.674046668419],
            [126.954541214791, 37.674151700626],
            [126.954625035338, 37.674193448984],
            [126.954889097424, 37.674471494513],
            [126.955017395577, 37.674608339732],
            [126.955188966088, 37.674791989283],
            [126.955330596055, 37.675111178071],
            [126.955419349482, 37.675317075843],
            [126.955445023932, 37.675372531679],
            [126.955714994629, 37.67567618323],
            [126.955802047881, 37.675773810263],
            [126.955866907957, 37.675850454022],
            [126.955956541758, 37.675955199614],
            [126.956173941358, 37.67633488078],
            [126.956497234138, 37.676725404642],
            [126.956747670721, 37.677105475717],
            [126.956872147336, 37.677466001598],
            [126.957042045557, 37.677944094203],
            [126.95712283537, 37.678135337799],
            [126.957286846031, 37.678396681113],
            [126.957485458826, 37.678690093671],
            [126.957622877144, 37.678894791511],
            [126.957766992828, 37.679104537107],
            [126.958085315117, 37.679344619436],
            [126.95860371096, 37.679641751285],
            [126.95930141517, 37.679877625071],
            [126.959479691997, 37.679908880374],
            [126.95973852574, 37.679956345413],
            [126.959873569019, 37.680004126625],
            [126.959906166444, 37.680013625356],
            [126.960073642584, 37.680081896851],
            [126.960407967081, 37.680207347951],
            [126.96084161756, 37.680421001426],
            [126.961132086248, 37.680565130481],
            [126.961368306805, 37.680698104359],
            [126.96175691336, 37.680931452354],
            [126.961865493465, 37.681142108887],
            [126.962171030178, 37.681269986917],
            [126.962643606334, 37.681185839794],
            [126.963288283217, 37.68142259144],
            [126.964340930846, 37.681151600594],
            [126.965005462314, 37.681273655519],
            [126.965727138491, 37.681404049025],
            [126.966146478148, 37.681482587042],
            [126.966682364736, 37.681543769315],
            [126.967517360693, 37.681634010992],
            [126.967965556984, 37.681808071784],
            [126.968462864816, 37.681777777433],
            [126.96858576485, 37.681824446068],
            [126.969155885424, 37.68200491519],
            [126.969745228448, 37.682068828141],
            [126.970245843421, 37.681967341462],
            [126.971269931336, 37.6817729178],
            [126.971810805997, 37.682020326737],
            [126.972030176397, 37.682767728023],
            [126.972782548438, 37.683652693943],
            [126.973825389331, 37.684106870422],
            [126.97414007331, 37.684512867133],
            [126.976120674855, 37.685072966119],
            [126.97658290318, 37.686258489354],
            [126.976683448231, 37.686473256808],
            [126.977069166984, 37.686554992336],
            [126.979160426583, 37.686289377944],
            [126.98010779427, 37.685952798139],
            [126.982457504543, 37.685176204611],
            [126.983229330093, 37.684683234772],
            [126.984011565847, 37.684182080573],
            [126.984153820299, 37.684082201998],
            [126.984848145513, 37.683450154229],
            [126.987069834292, 37.682059582862],
            [126.988570384775, 37.681531202632],
            [126.989990644004, 37.681061214398],
            [126.990678434604, 37.680474756509],
            [126.99084323008, 37.679835127094],
            [126.992178068304, 37.679657109389],
            [126.992451527242, 37.679203455679],
            [126.992975464348, 37.678827627602],
            [126.993013819838, 37.678577088262],
            [126.993019011516, 37.678181300909],
            [126.993052337947, 37.677591788659],
            [126.993135188141, 37.677215242529],
            [126.993260619347, 37.676996945964],
            [126.993085184838, 37.6766275622],
            [126.993140602943, 37.676139374631],
            [126.993195944748, 37.675718840963],
            [126.993832405586, 37.675208771933],
            [126.993954379149, 37.674940587514],
            [126.993970456671, 37.674789558617],
            [126.993853392003, 37.674393312594],
            [126.993590330555, 37.674213208908],
            [126.994207563808, 37.67242158453],
            [126.993754541513, 37.670326190896],
            [126.994303101931, 37.669893308374],
            [126.99432919518, 37.669648056182],
            [126.994197576091, 37.669439220615],
            [126.993988765814, 37.669045536405],
            [126.993818827877, 37.668718580396],
            [126.993540452894, 37.66788670257],
            [126.993895045777, 37.667338087496],
            [126.994084647739, 37.667078123808],
            [126.994029318015, 37.666928522724],
            [126.993043577697, 37.666014346566],
            [126.99271472759, 37.665578170288],
            [126.992176630219, 37.665272789],
            [126.991119703251, 37.664998273031],
            [126.989969718722, 37.664526290156],
            [126.989442202261, 37.66450667718],
            [126.988863814115, 37.664588947494],
            [126.988619301141, 37.664510257004],
            [126.988210451625, 37.664417014872],
            [126.987841089235, 37.663709969619],
            [126.987806884215, 37.663217331251],
            [126.987788311117, 37.663018394087],
            [126.987768966657, 37.662700825604],
            [126.987427086442, 37.66188800771],
            [126.98724440335, 37.661179387718]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      id: '41281107',
      properties: {
        rgnCode: '41281107',
        colCode: '41280',
        rgnKo: ['북한동'],
        rgnSize: 1,
        rgnBbox: [
          126.953207757767, 37.629762273799, 126.98724440335, 37.661611766115
        ],
        rgnCenter: [126.971148345006, 37.64659629151],
        rgnArea: 6734783,
        predVal: [
          0.06583, 0.06694, 0.06405, 0.07193, 0.09012, 0.08194, 0.0899, 0.09455,
          0.08142, 0.08571, 0.08543, 0.06541, 0.09402, 0.08356, 0.09503,
          0.03794, 0.0881, 0.08083, 0.10427, 0.09106, 0.09327, 0.09489, 0.08234,
          0.09325, 0.07465, 0.09096, 0.08314, 0.08561, 0.0837, 0.09587, 0.09215
        ]
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.954192403137, 37.65521728032],
            [126.954231383273, 37.655222341412],
            [126.954299574804, 37.655322340899],
            [126.954187928504, 37.655607445685],
            [126.954058205659, 37.655786354191],
            [126.953365632614, 37.655665730312],
            [126.953207757767, 37.655757321929],
            [126.953362419302, 37.656149147014],
            [126.953661249864, 37.656567257821],
            [126.953839400595, 37.657059280131],
            [126.954259299783, 37.657217164703],
            [126.954685905859, 37.657435298608],
            [126.955394590541, 37.657426721257],
            [126.956398969738, 37.657621161596],
            [126.957309974248, 37.657793052078],
            [126.957463505085, 37.657859709552],
            [126.958643853652, 37.6585970875],
            [126.959712151871, 37.659014162581],
            [126.960718393287, 37.659655134649],
            [126.96176951482, 37.659242847326],
            [126.963106622904, 37.658763269217],
            [126.964908469458, 37.657691511424],
            [126.966040409942, 37.658351388648],
            [126.968160620679, 37.657604594881],
            [126.970452036893, 37.658433441433],
            [126.97077291106, 37.658801249201],
            [126.97251637235, 37.661611766115],
            [126.975291441324, 37.661197138881],
            [126.976204128988, 37.66104158691],
            [126.978173914025, 37.661375263509],
            [126.98021574644, 37.661012845739],
            [126.983027230667, 37.661105633827],
            [126.986995696316, 37.661170726023],
            [126.98724440335, 37.661179387718],
            [126.987125454633, 37.66079959467],
            [126.987128413786, 37.660617354997],
            [126.986369941672, 37.659494141597],
            [126.985922962873, 37.658974113028],
            [126.984496000949, 37.658317844151],
            [126.984218951196, 37.65776259631],
            [126.983701341126, 37.657373991981],
            [126.983373131781, 37.657293683126],
            [126.982954814664, 37.656995458486],
            [126.982759259327, 37.656497323672],
            [126.982168640295, 37.656496660645],
            [126.981190020063, 37.65652624623],
            [126.979571046108, 37.656114102507],
            [126.979668187379, 37.655692031847],
            [126.979845392123, 37.654875519962],
            [126.980191831718, 37.654125070883],
            [126.980295558548, 37.653914838033],
            [126.98065002399, 37.653365351458],
            [126.980948359578, 37.652887923005],
            [126.981034757041, 37.652756224458],
            [126.981611410129, 37.652324764997],
            [126.981871851673, 37.651680331915],
            [126.98224350205, 37.651284603691],
            [126.982474637482, 37.650525217894],
            [126.983277371116, 37.650019194623],
            [126.983905380546, 37.649626951151],
            [126.9839653037, 37.649425555946],
            [126.984003738989, 37.649333833307],
            [126.98393198873, 37.648850620247],
            [126.98380507899, 37.648651557651],
            [126.984003355157, 37.648121552083],
            [126.984376363387, 37.64780487775],
            [126.984601197841, 37.647605344],
            [126.984695704881, 37.647282979238],
            [126.984684372036, 37.646939029955],
            [126.984755387047, 37.646875548273],
            [126.984735338168, 37.646742975776],
            [126.984865992705, 37.646203390575],
            [126.984878618099, 37.646089887355],
            [126.985114850352, 37.645917428515],
            [126.985636829079, 37.646097812349],
            [126.985692620294, 37.646056419983],
            [126.985619254099, 37.645994440962],
            [126.985523300314, 37.646009131925],
            [126.985314351653, 37.645878344704],
            [126.985095452176, 37.645709687686],
            [126.984810636347, 37.645565599577],
            [126.984757687829, 37.645360403687],
            [126.984559786094, 37.645296216867],
            [126.984061259299, 37.644388716598],
            [126.98370428383, 37.643966006071],
            [126.983530793477, 37.643899514694],
            [126.983238884602, 37.643657548564],
            [126.983520172588, 37.643165063853],
            [126.98369544674, 37.643014176847],
            [126.983738947261, 37.642907886163],
            [126.983776986877, 37.642799441306],
            [126.983791361221, 37.642768756194],
            [126.983851123221, 37.642364686028],
            [126.983827158446, 37.642082027946],
            [126.984054092852, 37.64184706894],
            [126.984250851483, 37.641619673283],
            [126.984873364862, 37.641450027692],
            [126.985073144197, 37.641483806313],
            [126.985197254403, 37.641595184315],
            [126.985290671699, 37.641651967767],
            [126.985299189426, 37.641612109713],
            [126.985258877473, 37.641398401413],
            [126.985405097542, 37.641174979155],
            [126.985652679722, 37.640777627731],
            [126.98595190008, 37.640552017855],
            [126.986097265069, 37.64044589282],
            [126.985629500216, 37.640076565214],
            [126.985509552057, 37.639691705545],
            [126.985384721242, 37.639310007493],
            [126.985365471943, 37.639099365347],
            [126.985427886296, 37.638899347872],
            [126.985375716363, 37.638775168028],
            [126.985395236267, 37.638624671416],
            [126.985189042277, 37.63827183827],
            [126.985116956225, 37.637989452884],
            [126.984938747859, 37.637797672905],
            [126.985054808651, 37.6373396231],
            [126.984906076038, 37.637224051857],
            [126.984727007801, 37.636685178732],
            [126.984441973695, 37.636608852026],
            [126.984164496383, 37.636340212021],
            [126.983948124541, 37.636440774172],
            [126.983673607543, 37.636499882163],
            [126.983587701991, 37.636473911966],
            [126.983597202561, 37.636352803967],
            [126.983144048923, 37.6361532037],
            [126.983058431248, 37.636065047667],
            [126.982832125797, 37.635836839381],
            [126.982644939951, 37.635841134278],
            [126.982610257199, 37.635809765682],
            [126.982362031252, 37.635582643153],
            [126.982255982267, 37.635473949921],
            [126.98216940446, 37.635294424896],
            [126.98159826896, 37.634702855764],
            [126.981478730108, 37.63484394671],
            [126.980626083852, 37.634821616719],
            [126.980079790887, 37.634411726836],
            [126.979189870226, 37.634108833102],
            [126.97856622663, 37.634021687942],
            [126.977837329441, 37.633922084905],
            [126.977398750833, 37.633657667063],
            [126.977046834099, 37.633004601552],
            [126.9762955964, 37.632302851451],
            [126.975827231189, 37.6320579185],
            [126.975650004943, 37.631855186638],
            [126.975407513121, 37.631753538882],
            [126.974483111825, 37.631923138112],
            [126.973746512168, 37.631951122607],
            [126.973021571559, 37.632299628418],
            [126.972670400572, 37.632148241764],
            [126.971309783833, 37.63158213178],
            [126.970537133587, 37.631430134557],
            [126.969363868264, 37.630989494377],
            [126.968218224608, 37.630726667457],
            [126.967535462022, 37.630743303068],
            [126.966796632826, 37.63068860578],
            [126.965973630176, 37.630830039617],
            [126.965651416531, 37.63081431013],
            [126.965255317778, 37.630798016491],
            [126.965140386424, 37.63077908759],
            [126.964370575144, 37.630405022662],
            [126.964255607402, 37.630347188624],
            [126.963833140671, 37.629855408349],
            [126.963158454826, 37.629886626085],
            [126.962457087761, 37.629921624253],
            [126.961379429825, 37.629980853311],
            [126.961003004926, 37.629939712102],
            [126.960397996782, 37.629872064545],
            [126.959814775349, 37.629762273799],
            [126.960957434308, 37.631037025446],
            [126.961707556303, 37.631879506676],
            [126.963401203627, 37.633233443644],
            [126.963251275574, 37.633807228161],
            [126.962786535721, 37.635285383347],
            [126.962633307949, 37.635782420495],
            [126.96207950369, 37.636532147561],
            [126.961734536252, 37.6370107032],
            [126.961711415658, 37.637620693318],
            [126.961685271348, 37.637994022576],
            [126.961161719099, 37.63850489712],
            [126.9608188955, 37.638826743649],
            [126.960545922337, 37.63967700061],
            [126.960142772249, 37.640525012848],
            [126.960056495787, 37.641111689848],
            [126.960011361323, 37.641428259293],
            [126.959327393044, 37.642138292861],
            [126.959363911395, 37.643300313742],
            [126.959006380219, 37.643948402134],
            [126.959126253954, 37.64445495629],
            [126.958861160176, 37.644870958724],
            [126.958966818405, 37.645310475176],
            [126.959108417532, 37.645853247306],
            [126.958822931113, 37.646274630058],
            [126.958920155785, 37.646861768344],
            [126.958212304788, 37.647754792403],
            [126.957792794416, 37.648131272663],
            [126.95772957988, 37.648531913222],
            [126.957452238026, 37.649119129097],
            [126.957697003099, 37.649957578223],
            [126.957485112625, 37.651054013679],
            [126.957416528625, 37.651425424221],
            [126.957387003162, 37.651608149022],
            [126.957295779886, 37.652127689359],
            [126.957191394094, 37.652293114305],
            [126.956745193879, 37.652925484512],
            [126.956548420046, 37.653083512797],
            [126.956272888706, 37.653258241679],
            [126.955984889538, 37.65347210346],
            [126.95593251259, 37.653527664389],
            [126.955901142564, 37.653550672177],
            [126.955747973406, 37.653631520415],
            [126.955538612893, 37.653740925494],
            [126.955370615472, 37.653884817696],
            [126.955329923317, 37.654010217501],
            [126.955277809785, 37.654169552544],
            [126.955241426386, 37.65425388756],
            [126.955214108848, 37.654283221597],
            [126.95508189437, 37.654340057188],
            [126.954882865558, 37.654431896145],
            [126.954796094938, 37.654449331715],
            [126.954623694032, 37.654508745675],
            [126.954402918199, 37.654581654697],
            [126.954337540135, 37.654603026723],
            [126.954237975263, 37.654673740423],
            [126.954159984075, 37.654766734803],
            [126.953989046066, 37.654893703521],
            [126.954132716292, 37.65514479095],
            [126.954157222683, 37.655189128756],
            [126.954192403137, 37.65521728032]
          ]
        ]
      }
    }
  ]
};
