import React, { useState, useContext } from 'react';
import Flex from 'components/common/Flex';

import { Badge, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { MapsContext } from 'context/Context';
import { chainAxios } from 'helpers/chainAxios';
import SoftBadge from 'components/common/SoftBadge';
import ItemDetail from '../../left-area/detail';
import RegionDetail from '../region-detail';

const RegionItem = ({ region }) => {
  const [marked, setMarked] = useState(region.favorite);
  const [detailShow, setDetailShow] = useState(false);

  const makeSubTitle = () => {
    let subTitle = '-';
    subTitle = '위험점수 : ' + region.riskScore + ' / ';
    subTitle += '직전사고 : ' + region.nearAccident;

    return subTitle;
  };

  const makeStatusBadge = () => {
    let badgeColor = 'dark';
    let badgeName = '등급확인필요';
    if (region.status === '4') {
      badgeName = 'A등급';
      badgeColor = 'primary';
    }
    if (region.status === '3') {
      badgeName = 'B등급';
      badgeColor = 'info';
    }
    if (region.status === '2') {
      badgeName = 'C등급';
      badgeColor = 'secondary';
    }
    if (region.status === '1') {
      badgeName = 'D등급';
      badgeColor = 'warning';
    }
    if (region.status === '0') {
      badgeName = 'E등급';
      badgeColor = 'danger';
    }

    return (
      <SoftBadge bg={badgeColor} className="mb-1">
        {badgeName}
      </SoftBadge>
    );
  };

  const detailModalShowEvent = () => {
    setDetailShow(!detailShow);
  };

  return (
    <Nav.Link
      eventKey={region.id}
      className={classNames(`chat-contact hover-actions-trigger`, {
        'unread-message': false,
        'read-message': false
      })}
      style={{ height: 'auto', border: 0, paddingLeft: 5, paddingRight: 0 }}
    >
      <Flex
        alignItems="center"
        direction="row"
        justifyContent="between"
        style={{ width: '100%' }}
        // className="py-3"
      >
        <Flex direction="row" alignItems="center" style={{ width: '100%' }}>
          <div className="pe-3">
            <FontAwesomeIcon
              // onClick={() => onClickMarked(facilitie)}
              icon={marked ? 'star' : ['far', 'star']}
              // transform="down-2"
              className={classNames(
                'ms-1',
                { 'text-warning': marked, 'text-300': !marked },
                'cursor-pointer'
              )}
            />
          </div>
          <Flex
            direction="column"
            alignItems="start"
            justifyContent="around"
            style={{ cursor: 'pointer', width: '100%' }}
            onClick={detailModalShowEvent}
          >
            <Flex
              alignItems="end"
              direction="row"
              justifyContent="between"
              style={{ width: '100%' }}
            >
              <div className="fs--0 text-700 fw-semi-bold">{region.name}</div>
              {makeStatusBadge()}
            </Flex>
            <div className="fs--1 text-900">{makeSubTitle()}</div>
          </Flex>
        </Flex>
      </Flex>
      {detailShow && (
        <RegionDetail
          regionInfo={region}
          detailModalShow={detailShow}
          detailModalShowEvent={detailModalShowEvent}
        />
      )}
    </Nav.Link>
  );
};

export default RegionItem;
