import React, { useState, useContext, useEffect } from 'react';
import Flex from 'components/common/Flex';

import {
  Button,
  Modal,
  Tab,
  Card,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import classNames from 'classnames';
import { ActionContext, ChatContext } from 'context/Context';
import ChatContent from './ChatContent';
import { chainAxios } from 'helpers/chainAxios';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChatAddUser from '../user';
import ChatMemberCount from '../common/ChatMemberCount';
import ChatEventAction from '../action';
import SoftBadge from '../../SoftBadge';
import { isEmpty } from '../../../../helpers/utils';

import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import ActionDetail from '../../../event/process/detail';

const ChatDetail = ({ ChatDetailShow }) => {
  const queryClient = useQueryClient();
  const { currentChatRoom, setIsOpenChatRoom, getChatRoom } =
    useContext(ChatContext);
  const { isOpenActionDetail, setIsOpenActionDetail, setCurrentEvent } =
    useContext(ActionContext);

  const [isShowAddUser, setIsShowAddUser] = useState(false);
  const [isShowEventAction, setIsShowEventAction] = useState(false);

  const [chatMembers, setChatMembers] = useState([]);

  useEffect(() => {
    console.log('ChatDetail currentChatRoom : ', currentChatRoom);
    makeChatRoomMembers(currentChatRoom.members);
  }, [currentChatRoom]);

  const makeStatusBadge = () => {
    // const { eventStatus } = rowData.row.original;
    //
    let badgeName = '-';
    let badgeColor = 'dark';

    if (currentChatRoom.status === '0') {
      badgeName = '점검요함';
      badgeColor = 'danger';
    }

    if (currentChatRoom.status === '1') {
      badgeName = '조치완료';
      badgeColor = 'primary';
    }

    if (currentChatRoom.status === '2') {
      badgeName = '재점검필';
      badgeColor = 'warning';
    }

    if (currentChatRoom.status === '3') {
      badgeName = '승인';
      badgeColor = 'success';
    }

    return (
      <SoftBadge pill bg={badgeColor} className="ms-2 me-3 fs--0 pt-2">
        {badgeName}
      </SoftBadge>
    );
    //
    // return (
    //   <div
    //     className={'fs--1 text-truncate text-center'}
    //     style={{ width: '80px' }}
    //   >
    //     <SoftBadge pill bg={badgeColor} className="me-2 fs--2">
    //       {badgeName}
    //     </SoftBadge>
    //   </div>
    // );
  };

  const makeTitle = () => {
    let header = '-';

    if (currentChatRoom.status === '0') {
      header = '[점검요함] ';
    }

    if (currentChatRoom.status === '1') {
      header = '[조치완료] ';
    }

    if (currentChatRoom.status === '2') {
      header = '[재점검필] ';
    }

    if (currentChatRoom.status === '3') {
      header = '[승인] ';
    }

    // header += !!thread.project ? thread.project.value : '-';
    header += !!currentChatRoom.title ? currentChatRoom.title : '-';

    return header;
  };

  const makeChatRoomMembers = members => {
    let chatMembers = [];
    // let members = '';
    members.forEach(member => {
      chatMembers.push(member.memberName);
    });
    setChatMembers(chatMembers);
  };

  const onHideEvent = () => {
    setIsOpenChatRoom(!ChatDetailShow);
    if (!ChatDetailShow) {
      window.sessionStorage.setItem('currentChatRoomId', '');
    }
  };

  const onHideAddUserEvent = () => {
    setIsShowAddUser(false);
  };

  const onHideEventActionEvent = () => {
    setIsShowEventAction(false);
  };

  const onShowAddUserEvent = () => {
    setIsShowAddUser(true);
  };

  const changeStatusChatroom = useMutation(
    statusValue => {
      let url =
        '/api/chatroom/' + currentChatRoom.id + '?status=' + statusValue;
      console.log('changeStatusChatroom : ', url);
      chainAxios
        .put(url)
        .then(response => {
          // console.log('ChatDetail onClickStatusChange() : ', response);
          if (response.data.resultCode === 0) {
            let msgValue = '점검요함';
            if (statusValue === '1') {
              msgValue = '조치완료';
            }
            if (statusValue === '2') {
              msgValue = '재점검필';
            }
            if (statusValue === '3') {
              msgValue = '승인';
            }
            toast.success(
              currentChatRoom.title +
                ' 항목이 ' +
                msgValue +
                '상태로 변경되었습니다.',
              {
                theme: 'colored'
              }
            );
            queryClient.invalidateQueries([
              'getChatrooms',
              currentChatRoom.status
            ]);
            queryClient.invalidateQueries(['getBottomEvents']);

            // // setIsOpenChatRoom(false);
            // getChatRoom(currentChatRoom.id);
            // // console.log(currentChatRoom);
          }
        })
        .catch(error => {
          console.log('ChatDetail onClickStatusChange() : ', error);
        });
    },
    {
      onSuccess: () => {}
    }
  );

  const showInsertEventAction = () => {
    setIsShowEventAction(true);
  };

  const onClickShowActionReport = () => {
    setCurrentEvent(currentChatRoom.event);
    setIsOpenActionDetail(true);
  };

  return (
    <>
      {isShowAddUser && (
        <ChatAddUser
          ChatAddUserShow={isShowAddUser}
          Members={!!currentChatRoom.members && currentChatRoom.members}
          onHideEvent={onHideAddUserEvent}
        />
      )}
      {isShowEventAction && (
        <ChatEventAction
          chatEventActionShow={isShowEventAction}
          onHideEvent={onHideEventActionEvent}
          changeStatusChatroom={changeStatusChatroom}
        />
      )}
      {isOpenActionDetail && (
        <ActionDetail isActionDetailShow={isOpenActionDetail} />
      )}

      <Modal
        // dialogClassName="modal-70w"
        size="lg"
        show={ChatDetailShow}
        onHide={onHideEvent}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Flex direction="column">
            <Modal.Title id="example-custom-modal-styling-title">
              <Flex direction={'row'}>
                {!isEmpty(currentChatRoom.title)
                  ? currentChatRoom.title + ' '
                  : '- '}
                {!isEmpty(currentChatRoom.status) && makeStatusBadge()}
                {currentChatRoom.status === '1' && (
                  <div
                    className="fs--0 cursor-pointer text-600"
                    onClick={onClickShowActionReport}
                  >
                    <FontAwesomeIcon icon={faFileAlt} />
                  </div>
                )}
              </Flex>
              <Flex direction={'row'}>
                <div className="fs--1 pe-2">
                  {!!currentChatRoom.project
                    ? currentChatRoom.project.value
                    : '-'}
                </div>
                <ChatMemberCount
                  members={chatMembers}
                  onClickEvent={onShowAddUserEvent}
                />
              </Flex>
              <div className="fs--1">
                [{currentChatRoom.createDate}] {currentChatRoom.description}
              </div>
            </Modal.Title>
          </Flex>
        </Modal.Header>
        <Modal.Body>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="0"
            // onSelect={handleSelect}
          >
            <Card
              className="card-chat overflow-hidden"
              style={{
                height: '500px'
              }}
            >
              <Card.Body as={Flex} className="p-0 h-100">
                <ChatContent thread={[currentChatRoom]} />
              </Card.Body>
            </Card>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Flex
            direction={'row'}
            justifyContent={'between'}
            style={{ width: '100%' }}
          >
            <Flex direction={'row'}>
              <Button
                variant="falcon-primary"
                className="me-2"
                size="sm"
                disabled={currentChatRoom.status === '0'}
                // onClick={() => onClickStatusChange('0')}
                onClick={async () => {
                  await changeStatusChatroom.mutate('0');
                }}
              >
                {/* <FontAwesomeIcon icon="phone" /> */}
                점검요함
              </Button>
              <Button
                variant="falcon-primary"
                className="me-2"
                size="sm"
                disabled={currentChatRoom.status === '1'}
                onClick={showInsertEventAction}
              >
                {/* <FontAwesomeIcon icon="video" /> */}
                조치완료
              </Button>
              <Button
                variant="falcon-primary"
                className="me-2"
                size="sm"
                disabled={currentChatRoom.status === '2'}
                onClick={async () => {
                  await changeStatusChatroom.mutate('2');
                }}
              >
                {/* <FontAwesomeIcon icon="info" /> */}
                재점검필
              </Button>
              <Button
                variant="falcon-primary"
                size="sm"
                disabled={currentChatRoom.status === '3'}
                onClick={async () => {
                  await changeStatusChatroom.mutate('3');
                }}
              >
                {/* <FontAwesomeIcon icon="info" /> */}
                승인
              </Button>
            </Flex>
            <Flex direction={'column'}>
              <Button
                className="btn_close"
                variant="secondary"
                onClick={onHideEvent}
              >
                닫기
              </Button>
            </Flex>
          </Flex>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChatDetail;
