import React, { useContext, useState } from 'react';
import { Offcanvas, Tabs, Tab, Card } from 'react-bootstrap';
import ChatSidebar from './sidebar/ChatSidebar';
import ChatContent from './content/ChatContent';
import Flex from 'components/common/Flex';

const Chatting = ({ chattingShow, ChattingAreaShowEvent }) => {
  return (
    <Offcanvas
      show={chattingShow}
      onHide={ChattingAreaShowEvent}
      scroll={true}
      backdrop={false}
      keyboard={false}
      placement="end"
    >
      <Offcanvas.Header closeButton className="pb-0">
        <Offcanvas.Title>채팅목록</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <ChatSidebar />
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default Chatting;
