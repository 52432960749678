import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MapsContext } from 'context/Context';
import { chainAxios } from 'helpers/chainAxios';

const MapsProvider = ({ children }) => {
  const [selectedProject, setSelectedProject] = useState({});
  const [selectedRegion, setSelectedRegion] = useState({});

  const [sendRegion, setSendRegion] = useState({});

  const [miniMapSelectedSensor, setMiniMapSelectedSensor] = useState('');
  const [isShowModelView, setIsShowModelView] = useState(false);
  const [modelViewArgs, setModelViewArgs] = useState({
    isMapWireframe: true,
    isUnderGroundWireframe: true,
    circleScale: 0.5,
    facilityHeightScale: 0.5,
    markerHeightScale: 0.1,
    underGroundHeightScale: 5,
    markerDistanceThreshold: 10,
    underGroundDistanceThreshold: 250
  });

  const getProject = projectId => {
    // http://112.175.18.232:7000/api/chatroom/d7e47f3b-4307-4e1a-bcdd-d3722eeaf0c1?includeMember=true
    let url = '/api/project/' + projectId;
    chainAxios
      .get(url)
      .then(response => {
        // console.log('MapsProvider getProject() : ', response);
        if (response.data.resultCode === 200) {
          // setCurrentChatRoom({ ...response.data.data.chatroom });
          // setIsOpenChatRoom(true);
          setSelectedProject(response.data.data.project);
        }
      })
      .catch(error => {
        console.log('ChatProvider getChatRoom() : ', error);
      });
  };

  const value = {
    selectedProject,
    setSelectedProject,
    selectedRegion,
    setSelectedRegion,
    sendRegion,
    setSendRegion,
    miniMapSelectedSensor,
    setMiniMapSelectedSensor,
    isShowModelView,
    setIsShowModelView,
    modelViewArgs,
    setModelViewArgs,
    getProject
  };
  return <MapsContext.Provider value={value}>{children}</MapsContext.Provider>;
};

MapsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default MapsProvider;
