import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Image,
  Modal,
  Nav,
  Row,
  Tab
} from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import Flex from 'components/common/Flex';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { SensorContext } from 'context/Context';
import {
  getColor,
  getSearchPeriodAmount,
  isEmpty,
  toStringByFormatting
} from 'helpers/utils';
import DatePicker from 'react-datepicker';
import IconButton from 'components/common/IconButton';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import SensorDistance from '../chart/SensorDistance';
import SensorAngle from '../chart/SensorAngle';
import SensorDirectionAngels from '../chart/SensorDirectionAngels';
import SensorSlopeAngles from '../chart/SensorSlopeAngles';
import SensorSolidationsSubsidences from '../chart/SensorSolidationsSubsidences';
import SensorFluctuations from '../chart/SensorFluctuations';
import SensorDataSend from '../chart/SensorDataSend';
import SensorRawData from '../chart/SensorRawData';
import classNames from 'classnames';
import { Reset } from 'styled-reset';

const SensorCurrentSituation = ({ isSensorCurrentSituationShow }) => {
  const {
    isOpenSensorCurrentSituation,
    setIsOpenSensorCurrentSituation,
    currentSensor
  } = useContext(SensorContext);

  const [selectedSearchPeriod, setSelectedSearchPriod] = useState('1주일');
  const [searchStartDate, setSearchStartDate] = useState(
    moment().subtract(7, 'days').format('YYYY-MM-DD')
  );
  const [searchEndDate, setSearchEndDate] = useState(
    moment().format('YYYY-MM-DD')
  );

  const [isDisable, setIsDisable] = useState(true);

  const [startDate, setStartDate] = useState(
    Date.parse(moment().subtract(7, 'days').format('YYYY-MM-DD'))
  );
  const [endDate, setEndDate] = useState(
    Date.parse(moment().format('YYYY-MM-DD'))
  );

  useEffect(() => {
    console.log('SensorCurrentSituation current sensor : ', currentSensor);
  }, []);

  const onHideEvent = () => {
    setIsOpenSensorCurrentSituation(!isSensorCurrentSituationShow);
  };

  const onSelectSearchPeriod = value => {
    if (value === '1W') {
      setSelectedSearchPriod('1주일');
    }
    if (value === '1M') {
      setSelectedSearchPriod('1개월');
    }
    if (value === '3M') {
      setSelectedSearchPriod('3개월');
    }
    if (value === '6M') {
      setSelectedSearchPriod('6개월');
    }
    if (value === '1Y') {
      setSelectedSearchPriod('1년');
    }
    if (value === 'ALL') {
      setSelectedSearchPriod('설치일부터');
    }
    if (value === 'USER') {
      setSelectedSearchPriod('사용자지정');
      setIsDisable(false);
    }

    if (value !== 'USER') {
      let amount = getSearchPeriodAmount(value);
      let pStartDate = moment().subtract(amount, 'days').format('YYYY-MM-DD');
      let pEndDate = moment().format('YYYY-MM-DD');

      setSearchStartDate(pStartDate);
      setSearchEndDate(pEndDate);

      setStartDate(Date.parse(pStartDate));
      setEndDate(Date.parse(pEndDate));

      setIsDisable(true);
    }
  };

  const SearchCustom = () => {
    if (startDate > endDate) {
      toast.error(`조회 시작일이 조회 종료일보다 큽니다.`, {
        theme: 'colored'
      });
    } else {
      setSearchStartDate(toStringByFormatting(new Date(startDate)));
      setSearchEndDate(toStringByFormatting(new Date(endDate)));
    }
  };

  return (
    <Modal
      // dialogClassName="modal-70w"
      size="xl"
      show={isSensorCurrentSituationShow}
      onHide={onHideEvent}
      backdrop="static"
      keyboard={false}
      aria-labelledby="example-custom-modal-styling-title"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          {'센서 현황'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row
          as={Flex}
          alignItems="center"
          justifyContent="end"
          className={'px-3 pt-3'}
          style={{ backgroundColor: getColor('gray-100') }}
        >
          <Row as={Flex} alignItems="center" justifyContent="end">
            <Col
              xs={12}
              xxl={6}
              className={'pb-3'}
              as={Flex}
              alignItems="center"
              alignContent={'center'}
              justifyContent="end"
            >
              <Dropdown onSelect={onSelectSearchPeriod} className={'me-3'}>
                <Dropdown.Toggle variant="falcon-default" size="sm">
                  {!!selectedSearchPeriod
                    ? selectedSearchPeriod
                    : '조회 개월 수'}
                </Dropdown.Toggle>
                <Dropdown.Menu className="py-2">
                  <Dropdown.Item key="1" eventKey="1W">
                    1주일
                  </Dropdown.Item>
                  <Dropdown.Item key="2" eventKey="1M">
                    1개월
                  </Dropdown.Item>
                  <Dropdown.Item key="3" eventKey="3M">
                    3개월
                  </Dropdown.Item>
                  <Dropdown.Item key="4" eventKey="6M">
                    6개월
                  </Dropdown.Item>
                  <Dropdown.Item key="5" eventKey="1Y">
                    1년
                  </Dropdown.Item>
                  <Dropdown.Item key="6" eventKey="ALL">
                    설치일부터
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item key="7" eventKey="USER">
                    사용자지정
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <DatePicker
                showIcon
                selected={startDate}
                disabled={isDisable}
                onChange={date => setStartDate(date)}
                maxDate={new Date()}
                dateFormat="yyyy-MM-dd"
                className="form-control"
              />
              <h6 className={'mx-2'}>~</h6>
              <DatePicker
                showIcon
                selected={endDate}
                disabled={isDisable}
                onChange={date => setEndDate(date)}
                maxDate={new Date()}
                dateFormat="yyyy-MM-dd"
                className="form-control"
              />
              <IconButton
                variant="falcon-default"
                // size="sm"
                icon="search"
                className="me-1 mb-2 mb-sm-0 ms-3"
                iconClassName="me-1"
                disabled={isDisable}
                onClick={() => SearchCustom()}
              />
            </Col>
          </Row>
        </Row>
        <Row className="g-3 mb-3">
          <Col xs={12}>
            <SensorRawData
              sensor={currentSensor}
              searchStartDate={searchStartDate}
              searchEndDate={searchEndDate}
            />
          </Col>
        </Row>
        <Row className="g-3 mb-3">
          {/*<Card className={classNames('overflow-hidden')}>*/}
          <Tab.Container id="audience-tab" defaultActiveKey="sensor2">
            <Col xs={3}>
              <Card.Header className="p-0 bg-light">
                <Nav className="nav-tabs border-0 flex-nowrap chart-tab">
                  <Nav.Item>
                    <Nav.Link className="mb-0 py-3 px-4" eventKey="sensor2">
                      <h6 className={'mb-0'}>측정거리</h6>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="mb-0 py-3 px-4" eventKey="sensor3">
                      <h6 className={'mb-0'}>측정각도</h6>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="mb-0 py-3 px-4" eventKey="sensor7">
                      <h6 className={'mb-0'}>압밀·지표 침하/변화량</h6>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="mb-0 py-3 px-4" eventKey="sensor4">
                      <h6 className={'mb-0'}>침하각</h6>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="mb-0 py-3 px-4" eventKey="sensor5">
                      <h6 className={'mb-0'}>방향각</h6>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="mb-0 py-3 px-4" eventKey="sensor6">
                      <h6 className={'mb-0'}>진동수</h6>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="mb-0 py-3 px-4" eventKey="sensor1">
                      <h6 className={'mb-0'}>데이터 전송횟수</h6>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Header>
            </Col>
            <Col xs={9}>
              <Card.Body className={'pt-0'}>
                <Tab.Content>
                  <Tab.Pane mountOnEnter unmountOnExit eventKey="sensor1">
                    <SensorDataSend
                      sensor={currentSensor}
                      searchStartDate={searchStartDate}
                      searchEndDate={searchEndDate}
                    />
                  </Tab.Pane>
                  <Tab.Pane mountOnEnter unmountOnExit eventKey="sensor2">
                    <SensorDistance
                      sensor={currentSensor}
                      searchStartDate={searchStartDate}
                      searchEndDate={searchEndDate}
                    />
                  </Tab.Pane>
                  <Tab.Pane mountOnEnter unmountOnExit eventKey="sensor3">
                    <SensorAngle
                      sensor={currentSensor}
                      searchStartDate={searchStartDate}
                      searchEndDate={searchEndDate}
                    />
                  </Tab.Pane>
                  <Tab.Pane mountOnEnter unmountOnExit eventKey="sensor4">
                    <SensorSlopeAngles
                      sensor={currentSensor}
                      searchStartDate={searchStartDate}
                      searchEndDate={searchEndDate}
                    />
                  </Tab.Pane>
                  <Tab.Pane mountOnEnter unmountOnExit eventKey="sensor5">
                    <SensorDirectionAngels
                      sensor={currentSensor}
                      searchStartDate={searchStartDate}
                      searchEndDate={searchEndDate}
                    />
                  </Tab.Pane>
                  <Tab.Pane mountOnEnter unmountOnExit eventKey="sensor6">
                    <SensorFluctuations
                      sensor={currentSensor}
                      searchStartDate={searchStartDate}
                      searchEndDate={searchEndDate}
                    />
                  </Tab.Pane>
                  <Tab.Pane mountOnEnter unmountOnExit eventKey="sensor7">
                    <SensorSolidationsSubsidences
                      sensor={currentSensor}
                      searchStartDate={searchStartDate}
                      searchEndDate={searchEndDate}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Col>
          </Tab.Container>
          {/*</Card>*/}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Flex
          direction={'row'}
          justifyContent={'end'}
          style={{ width: '100%' }}
        >
          <Flex direction={'row'}>
            <Button
              className="btn_close"
              variant="secondary"
              onClick={onHideEvent}
            >
              닫기
            </Button>
          </Flex>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};

export default SensorCurrentSituation;
