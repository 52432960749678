export const guDaeguData = {
  type: 'FeatureCollection',
  features: [
    {
      id: '27110',
      type: 'Feature',
      properties: {
        SEC_SGG_CD: '27110',
        SEC_SGG_NM: ['대구광역시', '중구'],
        COL_SGG_CD: '27000',
        SEC_SGG_SIZE: '2',
        bbox: [128.57381, 35.85495, 128.61464, 35.87951],
        center: [128.59349, 35.86644],
        area: 7006456
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [128.58399, 35.87825],
            [128.58492, 35.87807],
            [128.5861, 35.87788],
            [128.58668, 35.87777],
            [128.58706, 35.8777],
            [128.58721, 35.87771],
            [128.58724, 35.87765],
            [128.58737, 35.87766],
            [128.58745, 35.87768],
            [128.58747, 35.87761],
            [128.58768, 35.87758],
            [128.58778, 35.87758],
            [128.58798, 35.87752],
            [128.58822, 35.87747],
            [128.58822, 35.87732],
            [128.58835, 35.8773],
            [128.58859, 35.87723],
            [128.58859, 35.87705],
            [128.59336, 35.87609],
            [128.59496, 35.87578],
            [128.59496, 35.87567],
            [128.59609, 35.87547],
            [128.59985, 35.87475],
            [128.60037, 35.87478],
            [128.60152, 35.87448],
            [128.60269, 35.87413],
            [128.60296, 35.87409],
            [128.60295, 35.87403],
            [128.60376, 35.87378],
            [128.60417, 35.87367],
            [128.60437, 35.87364],
            [128.60469, 35.87359],
            [128.60514, 35.87358],
            [128.60554, 35.87362],
            [128.60604, 35.87371],
            [128.60626, 35.87376],
            [128.60628, 35.87363],
            [128.60759, 35.8738],
            [128.60875, 35.87413],
            [128.60894, 35.8742],
            [128.60958, 35.87437],
            [128.60975, 35.87438],
            [128.60977, 35.87444],
            [128.60999, 35.87453],
            [128.60995, 35.87461],
            [128.60972, 35.8748],
            [128.61055, 35.87502],
            [128.61132, 35.8746],
            [128.61186, 35.87445],
            [128.61221, 35.87425],
            [128.61241, 35.87417],
            [128.61348, 35.87174],
            [128.61341, 35.87163],
            [128.61342, 35.87147],
            [128.61358, 35.87068],
            [128.61364, 35.87006],
            [128.61376, 35.86983],
            [128.61435, 35.86892],
            [128.61464, 35.86822],
            [128.61415, 35.86776],
            [128.61405, 35.86746],
            [128.61421, 35.86679],
            [128.6142, 35.86648],
            [128.6139, 35.86595],
            [128.6135, 35.86516],
            [128.61333, 35.86491],
            [128.61327, 35.86475],
            [128.61288, 35.86413],
            [128.61267, 35.86391],
            [128.61237, 35.86375],
            [128.61191, 35.86354],
            [128.61123, 35.8632],
            [128.61054, 35.86291],
            [128.61032, 35.86277],
            [128.61015, 35.86272],
            [128.60998, 35.86254],
            [128.60983, 35.86229],
            [128.60966, 35.86213],
            [128.60955, 35.86189],
            [128.60939, 35.86164],
            [128.6094, 35.86149],
            [128.60933, 35.86148],
            [128.60938, 35.86124],
            [128.60936, 35.86072],
            [128.60867, 35.86084],
            [128.60823, 35.85984],
            [128.60815, 35.85949],
            [128.608, 35.85834],
            [128.60801, 35.85747],
            [128.60815, 35.85567],
            [128.60827, 35.85506],
            [128.60834, 35.85495],
            [128.60733, 35.855],
            [128.60718, 35.85499],
            [128.60648, 35.85506],
            [128.60503, 35.85514],
            [128.60375, 35.85521],
            [128.60363, 35.85518],
            [128.60352, 35.85523],
            [128.60208, 35.85529],
            [128.59811, 35.85553],
            [128.5981, 35.85561],
            [128.59801, 35.85572],
            [128.59779, 35.85586],
            [128.59771, 35.85589],
            [128.59762, 35.85598],
            [128.59736, 35.85607],
            [128.59704, 35.85623],
            [128.59679, 35.85633],
            [128.59474, 35.85656],
            [128.59101, 35.85697],
            [128.59101, 35.85707],
            [128.59094, 35.85716],
            [128.59084, 35.85722],
            [128.59069, 35.85725],
            [128.59053, 35.85721],
            [128.59044, 35.85715],
            [128.5904, 35.85707],
            [128.59039, 35.85696],
            [128.58289, 35.85628],
            [128.57446, 35.85999],
            [128.57408, 35.86263],
            [128.57422, 35.86266],
            [128.57394, 35.86441],
            [128.57381, 35.86533],
            [128.57471, 35.86606],
            [128.57499, 35.86625],
            [128.57652, 35.86732],
            [128.57725, 35.86779],
            [128.57838, 35.86871],
            [128.57842, 35.86883],
            [128.57859, 35.86895],
            [128.57906, 35.86944],
            [128.57909, 35.86954],
            [128.57927, 35.86982],
            [128.57931, 35.86987],
            [128.57952, 35.87023],
            [128.57963, 35.87031],
            [128.5794, 35.87056],
            [128.57937, 35.87095],
            [128.57934, 35.87102],
            [128.57936, 35.87173],
            [128.57941, 35.87181],
            [128.57941, 35.87188],
            [128.57944, 35.87193],
            [128.57947, 35.87222],
            [128.5794, 35.87248],
            [128.5794, 35.87262],
            [128.57942, 35.87274],
            [128.57938, 35.87286],
            [128.57911, 35.87256],
            [128.57885, 35.8724],
            [128.57878, 35.87238],
            [128.57858, 35.87239],
            [128.57824, 35.87247],
            [128.57816, 35.87245],
            [128.57803, 35.87231],
            [128.57788, 35.87221],
            [128.57792, 35.87211],
            [128.57789, 35.87204],
            [128.57762, 35.87204],
            [128.57752, 35.87203],
            [128.57732, 35.87197],
            [128.577, 35.87197],
            [128.57675, 35.87193],
            [128.5767, 35.872],
            [128.57656, 35.87221],
            [128.57647, 35.87244],
            [128.57645, 35.87263],
            [128.57643, 35.8727],
            [128.57638, 35.87277],
            [128.57612, 35.87289],
            [128.57605, 35.87289],
            [128.57598, 35.87287],
            [128.57588, 35.87295],
            [128.57569, 35.87302],
            [128.57563, 35.8731],
            [128.57556, 35.87314],
            [128.57556, 35.87331],
            [128.57552, 35.87346],
            [128.57552, 35.87359],
            [128.57549, 35.87369],
            [128.57547, 35.87384],
            [128.57549, 35.87394],
            [128.57555, 35.87412],
            [128.57564, 35.87421],
            [128.57573, 35.87432],
            [128.57603, 35.87447],
            [128.57602, 35.87452],
            [128.57615, 35.87451],
            [128.57621, 35.87453],
            [128.57626, 35.87476],
            [128.57631, 35.87487],
            [128.57628, 35.87495],
            [128.5763, 35.87519],
            [128.57625, 35.87527],
            [128.57633, 35.87548],
            [128.57639, 35.87558],
            [128.57645, 35.87576],
            [128.57646, 35.87597],
            [128.57655, 35.87614],
            [128.57667, 35.87623],
            [128.57675, 35.87627],
            [128.57693, 35.87629],
            [128.57698, 35.87625],
            [128.57754, 35.87596],
            [128.57803, 35.87567],
            [128.57811, 35.87566],
            [128.5784, 35.87554],
            [128.57849, 35.87545],
            [128.57867, 35.87545],
            [128.57883, 35.87541],
            [128.5789, 35.87542],
            [128.57902, 35.8754],
            [128.579, 35.87551],
            [128.57846, 35.87586],
            [128.57842, 35.87593],
            [128.57818, 35.87628],
            [128.57802, 35.87648],
            [128.57795, 35.87662],
            [128.57799, 35.87666],
            [128.578, 35.87678],
            [128.57804, 35.87685],
            [128.57805, 35.87695],
            [128.578, 35.87718],
            [128.57797, 35.87743],
            [128.57796, 35.87757],
            [128.57784, 35.87783],
            [128.57782, 35.87806],
            [128.57777, 35.87825],
            [128.57775, 35.87855],
            [128.57776, 35.87861],
            [128.57774, 35.87874],
            [128.57784, 35.87877],
            [128.57787, 35.87882],
            [128.5779, 35.87892],
            [128.57794, 35.87903],
            [128.5779, 35.87919],
            [128.57777, 35.8794],
            [128.57773, 35.87951],
            [128.57793, 35.87947],
            [128.57815, 35.8794],
            [128.5807, 35.87891],
            [128.58164, 35.87869],
            [128.58399, 35.87825]
          ]
        ]
      }
    },
    {
      id: '27140',
      type: 'Feature',
      properties: {
        SEC_SGG_CD: '27140',
        SEC_SGG_NM: ['대구광역시', '동구'],
        COL_SGG_CD: '27000',
        SEC_SGG_SIZE: '2',
        bbox: [128.60375, 35.85143, 128.76236, 36.01639],
        center: [128.6855, 35.93451],
        area: 180952179
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [128.69546, 36.0158],
            [128.69623, 36.01482],
            [128.69626, 36.0141],
            [128.69631, 36.01341],
            [128.69942, 36.01214],
            [128.70088, 36.01107],
            [128.70217, 36.01079],
            [128.70391, 36.0103],
            [128.70591, 36.00997],
            [128.70749, 36.0099],
            [128.71132, 36.00939],
            [128.71314, 36.00856],
            [128.71435, 36.00841],
            [128.71479, 36.00865],
            [128.71537, 36.00874],
            [128.71617, 36.00844],
            [128.71721, 36.00835],
            [128.71734, 36.00836],
            [128.71802, 36.00792],
            [128.72037, 36.00728],
            [128.72081, 36.00695],
            [128.72067, 36.00664],
            [128.72138, 36.00644],
            [128.72215, 36.00638],
            [128.72252, 36.0059],
            [128.72303, 36.00558],
            [128.72422, 36.00429],
            [128.72491, 36.00421],
            [128.72525, 36.0042],
            [128.72586, 36.0039],
            [128.72638, 36.00348],
            [128.72686, 36.00272],
            [128.72732, 36.00248],
            [128.72754, 36.00202],
            [128.72812, 36.00097],
            [128.72788, 35.99985],
            [128.72814, 35.99897],
            [128.72773, 35.99861],
            [128.72779, 35.99858],
            [128.72784, 35.9984],
            [128.72742, 35.99809],
            [128.72836, 35.99759],
            [128.72954, 35.99768],
            [128.73018, 35.99736],
            [128.73107, 35.9972],
            [128.73173, 35.99668],
            [128.73226, 35.99567],
            [128.73288, 35.99521],
            [128.73329, 35.99436],
            [128.73314, 35.99418],
            [128.73439, 35.99321],
            [128.7337, 35.99206],
            [128.73271, 35.99094],
            [128.73161, 35.99036],
            [128.73137, 35.98998],
            [128.73108, 35.98983],
            [128.73052, 35.98898],
            [128.73058, 35.98807],
            [128.73026, 35.98711],
            [128.73056, 35.98564],
            [128.73015, 35.98498],
            [128.73137, 35.98349],
            [128.73247, 35.98274],
            [128.73475, 35.98194],
            [128.73514, 35.9817],
            [128.73606, 35.981],
            [128.73633, 35.98026],
            [128.73658, 35.97917],
            [128.73719, 35.97837],
            [128.73738, 35.97779],
            [128.73743, 35.97751],
            [128.73819, 35.97681],
            [128.73857, 35.97656],
            [128.73882, 35.97657],
            [128.7394, 35.97622],
            [128.73978, 35.9758],
            [128.74076, 35.97539],
            [128.74113, 35.97525],
            [128.74182, 35.9751],
            [128.74323, 35.97475],
            [128.74369, 35.97418],
            [128.74446, 35.97415],
            [128.74485, 35.97411],
            [128.74547, 35.97349],
            [128.74571, 35.9731],
            [128.74533, 35.97258],
            [128.74513, 35.97165],
            [128.74495, 35.96952],
            [128.74495, 35.96878],
            [128.74536, 35.96811],
            [128.74551, 35.9679],
            [128.74524, 35.96766],
            [128.74509, 35.96751],
            [128.74504, 35.96748],
            [128.74493, 35.96724],
            [128.74387, 35.96691],
            [128.74377, 35.96675],
            [128.74379, 35.96669],
            [128.74372, 35.96659],
            [128.74369, 35.96651],
            [128.74365, 35.96643],
            [128.74365, 35.96621],
            [128.74363, 35.96613],
            [128.74365, 35.96602],
            [128.74358, 35.96591],
            [128.74353, 35.9657],
            [128.74345, 35.96558],
            [128.74342, 35.96549],
            [128.74342, 35.96539],
            [128.74334, 35.96519],
            [128.74325, 35.96508],
            [128.74302, 35.96505],
            [128.74301, 35.96492],
            [128.74295, 35.96475],
            [128.74293, 35.96462],
            [128.743, 35.96452],
            [128.74304, 35.96444],
            [128.74309, 35.96425],
            [128.74318, 35.9642],
            [128.74325, 35.96414],
            [128.74328, 35.96407],
            [128.74311, 35.96394],
            [128.74302, 35.96395],
            [128.74287, 35.96388],
            [128.74287, 35.96381],
            [128.74289, 35.96366],
            [128.74294, 35.96344],
            [128.74297, 35.9634],
            [128.74286, 35.9631],
            [128.7429, 35.963],
            [128.7429, 35.9629],
            [128.7429, 35.96285],
            [128.74298, 35.96251],
            [128.74293, 35.96242],
            [128.74259, 35.96151],
            [128.74245, 35.96134],
            [128.74244, 35.96087],
            [128.74259, 35.96056],
            [128.74257, 35.96023],
            [128.74236, 35.95993],
            [128.74205, 35.95955],
            [128.74145, 35.95911],
            [128.74069, 35.95887],
            [128.73989, 35.95843],
            [128.73948, 35.95795],
            [128.73919, 35.95787],
            [128.73912, 35.95772],
            [128.73849, 35.95752],
            [128.73815, 35.95709],
            [128.73812, 35.95697],
            [128.73793, 35.9568],
            [128.73762, 35.95638],
            [128.73753, 35.95623],
            [128.73939, 35.95506],
            [128.74026, 35.95356],
            [128.74054, 35.95188],
            [128.74083, 35.95159],
            [128.74137, 35.95127],
            [128.74122, 35.95078],
            [128.74204, 35.94985],
            [128.7419, 35.94932],
            [128.74185, 35.94806],
            [128.74288, 35.9474],
            [128.74319, 35.94705],
            [128.74307, 35.94578],
            [128.7437, 35.94423],
            [128.74343, 35.94323],
            [128.74267, 35.94252],
            [128.74114, 35.94019],
            [128.74056, 35.93939],
            [128.73937, 35.93769],
            [128.73914, 35.9377],
            [128.7405, 35.93715],
            [128.73988, 35.93708],
            [128.73916, 35.93534],
            [128.73909, 35.9353],
            [128.7378, 35.93439],
            [128.73797, 35.93333],
            [128.73822, 35.9314],
            [128.73814, 35.93079],
            [128.73752, 35.92983],
            [128.73708, 35.92998],
            [128.73652, 35.92987],
            [128.7375, 35.92835],
            [128.73879, 35.92702],
            [128.73997, 35.92593],
            [128.74058, 35.92486],
            [128.74101, 35.92419],
            [128.74247, 35.92172],
            [128.74258, 35.92134],
            [128.74263, 35.92069],
            [128.74398, 35.91966],
            [128.74526, 35.9192],
            [128.74556, 35.91887],
            [128.74701, 35.91867],
            [128.74846, 35.91813],
            [128.74886, 35.91773],
            [128.74997, 35.91774],
            [128.75085, 35.91704],
            [128.75218, 35.91699],
            [128.75294, 35.91646],
            [128.75382, 35.91614],
            [128.75427, 35.91573],
            [128.75481, 35.91555],
            [128.75528, 35.91525],
            [128.75564, 35.91465],
            [128.75574, 35.91444],
            [128.75654, 35.91372],
            [128.75653, 35.91354],
            [128.75656, 35.91328],
            [128.75693, 35.91294],
            [128.75742, 35.91201],
            [128.75724, 35.91172],
            [128.75758, 35.91112],
            [128.75742, 35.91072],
            [128.75777, 35.9105],
            [128.75808, 35.91025],
            [128.75832, 35.90978],
            [128.75843, 35.90913],
            [128.75919, 35.90856],
            [128.7594, 35.90806],
            [128.75951, 35.90765],
            [128.75981, 35.90709],
            [128.75794, 35.90628],
            [128.75778, 35.90611],
            [128.75763, 35.90586],
            [128.75703, 35.90538],
            [128.75672, 35.90492],
            [128.75651, 35.90382],
            [128.75664, 35.90349],
            [128.7566, 35.90309],
            [128.75656, 35.90285],
            [128.75679, 35.90262],
            [128.7569, 35.90209],
            [128.75683, 35.90155],
            [128.75672, 35.90112],
            [128.75712, 35.89888],
            [128.75717, 35.89837],
            [128.75718, 35.89786],
            [128.75727, 35.89743],
            [128.75751, 35.89703],
            [128.75742, 35.89654],
            [128.75774, 35.89561],
            [128.75804, 35.89524],
            [128.75852, 35.8949],
            [128.75853, 35.89469],
            [128.75861, 35.89451],
            [128.75864, 35.89432],
            [128.75886, 35.8939],
            [128.75954, 35.89229],
            [128.76025, 35.89078],
            [128.76058, 35.89055],
            [128.76103, 35.89006],
            [128.76147, 35.88986],
            [128.76177, 35.88963],
            [128.76228, 35.88881],
            [128.76236, 35.88873],
            [128.76216, 35.88871],
            [128.76209, 35.88874],
            [128.76203, 35.88869],
            [128.7618, 35.88852],
            [128.76171, 35.88844],
            [128.76167, 35.88835],
            [128.76153, 35.88828],
            [128.76143, 35.88813],
            [128.76122, 35.88813],
            [128.76126, 35.88793],
            [128.76126, 35.88787],
            [128.76138, 35.88761],
            [128.76124, 35.88735],
            [128.76132, 35.88732],
            [128.76129, 35.88715],
            [128.76133, 35.88711],
            [128.76135, 35.887],
            [128.76136, 35.88683],
            [128.7613, 35.88678],
            [128.76119, 35.88662],
            [128.76112, 35.88657],
            [128.76108, 35.88633],
            [128.76094, 35.88608],
            [128.76093, 35.88589],
            [128.76098, 35.88568],
            [128.76097, 35.88555],
            [128.761, 35.88543],
            [128.76095, 35.88527],
            [128.76095, 35.88522],
            [128.76091, 35.8851],
            [128.7609, 35.88474],
            [128.76097, 35.88378],
            [128.76063, 35.88279],
            [128.7602, 35.8822],
            [128.76013, 35.88181],
            [128.75935, 35.88066],
            [128.75906, 35.88034],
            [128.75894, 35.87995],
            [128.75881, 35.87966],
            [128.75893, 35.87927],
            [128.7587, 35.87883],
            [128.75806, 35.8783],
            [128.75779, 35.87829],
            [128.75765, 35.87837],
            [128.75714, 35.87815],
            [128.75695, 35.87814],
            [128.75691, 35.87809],
            [128.75695, 35.87791],
            [128.75707, 35.87782],
            [128.75717, 35.87773],
            [128.75716, 35.87754],
            [128.75718, 35.87741],
            [128.7573, 35.87701],
            [128.75737, 35.87685],
            [128.75741, 35.8768],
            [128.75741, 35.87672],
            [128.75746, 35.87662],
            [128.7574, 35.8764],
            [128.7575, 35.87631],
            [128.75759, 35.8763],
            [128.75777, 35.87633],
            [128.75796, 35.87637],
            [128.75812, 35.87643],
            [128.75834, 35.87647],
            [128.75853, 35.8765],
            [128.75868, 35.8765],
            [128.7589, 35.87636],
            [128.75897, 35.8763],
            [128.75913, 35.87625],
            [128.75934, 35.87611],
            [128.7595, 35.87605],
            [128.75959, 35.87604],
            [128.75982, 35.87593],
            [128.75994, 35.87583],
            [128.76003, 35.87569],
            [128.76015, 35.87557],
            [128.7605, 35.87545],
            [128.76062, 35.8753],
            [128.76105, 35.87507],
            [128.76111, 35.87501],
            [128.76112, 35.87482],
            [128.76114, 35.87476],
            [128.76121, 35.87474],
            [128.76112, 35.87456],
            [128.76104, 35.87447],
            [128.76089, 35.87436],
            [128.7608, 35.87423],
            [128.76043, 35.87418],
            [128.76022, 35.87413],
            [128.7601, 35.87405],
            [128.75978, 35.87371],
            [128.75972, 35.87358],
            [128.75967, 35.87343],
            [128.75961, 35.8734],
            [128.75954, 35.87333],
            [128.75951, 35.87324],
            [128.75951, 35.87317],
            [128.75955, 35.87293],
            [128.75958, 35.8728],
            [128.75958, 35.87229],
            [128.75954, 35.8721],
            [128.75949, 35.87192],
            [128.75946, 35.87168],
            [128.75938, 35.87155],
            [128.75925, 35.87141],
            [128.75897, 35.87116],
            [128.75893, 35.87112],
            [128.75884, 35.87093],
            [128.75874, 35.87082],
            [128.75837, 35.87064],
            [128.75819, 35.87049],
            [128.75806, 35.87051],
            [128.75773, 35.87032],
            [128.75829, 35.8699],
            [128.75958, 35.86923],
            [128.75987, 35.86875],
            [128.75981, 35.86838],
            [128.75981, 35.86766],
            [128.75982, 35.86708],
            [128.7598, 35.86637],
            [128.75927, 35.86604],
            [128.75774, 35.86534],
            [128.75595, 35.86447],
            [128.75537, 35.8641],
            [128.75507, 35.86384],
            [128.75481, 35.86353],
            [128.75504, 35.86327],
            [128.7544, 35.86276],
            [128.75399, 35.8624],
            [128.75372, 35.86196],
            [128.75305, 35.86119],
            [128.75262, 35.86062],
            [128.75236, 35.86009],
            [128.75223, 35.85992],
            [128.75181, 35.85942],
            [128.75184, 35.85936],
            [128.75161, 35.85915],
            [128.75146, 35.85876],
            [128.75091, 35.85791],
            [128.75079, 35.85777],
            [128.75061, 35.8577],
            [128.74986, 35.85753],
            [128.74805, 35.85687],
            [128.74753, 35.85673],
            [128.74679, 35.85647],
            [128.74634, 35.85625],
            [128.74551, 35.85606],
            [128.74519, 35.8559],
            [128.74483, 35.85553],
            [128.74462, 35.85516],
            [128.74451, 35.85508],
            [128.74341, 35.85455],
            [128.7432, 35.8544],
            [128.74262, 35.85408],
            [128.74206, 35.85373],
            [128.74186, 35.8536],
            [128.74148, 35.8533],
            [128.7412, 35.85313],
            [128.74105, 35.85301],
            [128.74088, 35.85282],
            [128.74086, 35.85247],
            [128.74037, 35.8524],
            [128.73972, 35.85215],
            [128.73926, 35.85191],
            [128.73857, 35.85143],
            [128.7371, 35.85145],
            [128.73629, 35.85152],
            [128.73529, 35.85168],
            [128.73505, 35.85169],
            [128.73453, 35.85198],
            [128.73339, 35.85272],
            [128.73294, 35.85308],
            [128.73272, 35.85329],
            [128.73186, 35.85395],
            [128.73154, 35.85423],
            [128.72995, 35.85521],
            [128.72965, 35.85532],
            [128.72696, 35.85754],
            [128.7247, 35.85909],
            [128.72446, 35.85962],
            [128.72419, 35.8602],
            [128.72281, 35.8603],
            [128.72062, 35.86066],
            [128.71864, 35.86072],
            [128.71823, 35.86082],
            [128.71672, 35.86108],
            [128.71602, 35.86136],
            [128.71556, 35.86151],
            [128.71516, 35.86109],
            [128.71468, 35.86102],
            [128.7115, 35.86104],
            [128.71121, 35.86092],
            [128.71042, 35.8605],
            [128.71002, 35.86021],
            [128.70864, 35.85954],
            [128.70759, 35.85846],
            [128.70717, 35.85845],
            [128.70636, 35.85839],
            [128.70558, 35.85828],
            [128.7037, 35.85775],
            [128.70314, 35.85772],
            [128.70267, 35.85782],
            [128.70227, 35.85795],
            [128.70164, 35.85802],
            [128.70093, 35.85806],
            [128.70017, 35.85803],
            [128.69966, 35.85795],
            [128.6993, 35.85802],
            [128.69875, 35.85818],
            [128.69836, 35.85842],
            [128.69798, 35.85876],
            [128.69789, 35.8589],
            [128.69326, 35.85895],
            [128.69176, 35.85898],
            [128.69075, 35.8591],
            [128.69026, 35.8592],
            [128.68988, 35.85928],
            [128.6891, 35.85952],
            [128.68874, 35.85966],
            [128.68864, 35.8596],
            [128.6875, 35.85929],
            [128.68711, 35.8592],
            [128.68682, 35.85916],
            [128.6861, 35.85912],
            [128.68544, 35.8593],
            [128.68458, 35.85977],
            [128.68381, 35.86027],
            [128.68272, 35.86095],
            [128.68205, 35.86051],
            [128.68184, 35.86066],
            [128.68155, 35.86081],
            [128.68086, 35.86136],
            [128.68046, 35.86178],
            [128.68009, 35.86228],
            [128.68011, 35.86258],
            [128.68006, 35.86277],
            [128.68004, 35.86288],
            [128.68043, 35.86387],
            [128.68044, 35.86432],
            [128.68039, 35.86449],
            [128.68039, 35.86458],
            [128.67991, 35.86627],
            [128.67994, 35.86682],
            [128.67918, 35.86815],
            [128.67832, 35.86979],
            [128.67819, 35.87019],
            [128.67773, 35.87099],
            [128.67756, 35.87123],
            [128.67579, 35.87343],
            [128.67493, 35.87378],
            [128.67458, 35.8739],
            [128.67431, 35.87395],
            [128.67312, 35.87394],
            [128.67291, 35.87334],
            [128.67199, 35.87299],
            [128.66875, 35.8727],
            [128.66738, 35.87252],
            [128.66607, 35.87276],
            [128.66497, 35.87292],
            [128.66392, 35.87319],
            [128.66228, 35.87386],
            [128.66083, 35.87475],
            [128.65981, 35.87632],
            [128.6594, 35.87688],
            [128.65936, 35.8765],
            [128.65904, 35.87665],
            [128.65882, 35.87661],
            [128.65863, 35.87666],
            [128.65857, 35.87673],
            [128.65794, 35.87657],
            [128.65801, 35.87654],
            [128.65804, 35.8765],
            [128.65787, 35.87646],
            [128.65772, 35.87643],
            [128.65771, 35.8765],
            [128.65665, 35.8763],
            [128.65621, 35.87625],
            [128.65567, 35.87626],
            [128.65511, 35.87635],
            [128.65473, 35.87647],
            [128.65433, 35.87666],
            [128.65412, 35.8768],
            [128.65399, 35.87694],
            [128.65373, 35.87714],
            [128.65353, 35.87731],
            [128.65349, 35.87727],
            [128.65326, 35.87741],
            [128.6523, 35.87807],
            [128.65192, 35.87829],
            [128.65138, 35.87848],
            [128.65116, 35.87851],
            [128.65095, 35.87853],
            [128.65081, 35.87866],
            [128.64998, 35.87871],
            [128.64983, 35.87866],
            [128.64945, 35.87858],
            [128.64959, 35.87832],
            [128.64499, 35.8766],
            [128.64486, 35.87677],
            [128.64457, 35.87661],
            [128.63892, 35.87325],
            [128.63452, 35.87199],
            [128.63408, 35.87178],
            [128.63386, 35.87153],
            [128.63373, 35.87128],
            [128.63362, 35.87116],
            [128.63058, 35.86772],
            [128.62971, 35.86678],
            [128.62382, 35.86735],
            [128.62309, 35.86742],
            [128.62269, 35.86748],
            [128.62191, 35.86754],
            [128.61464, 35.86822],
            [128.61435, 35.86892],
            [128.61376, 35.86983],
            [128.61364, 35.87006],
            [128.61358, 35.87068],
            [128.61342, 35.87147],
            [128.61341, 35.87163],
            [128.61348, 35.87174],
            [128.61241, 35.87417],
            [128.61221, 35.87425],
            [128.61186, 35.87445],
            [128.61132, 35.8746],
            [128.61055, 35.87502],
            [128.60972, 35.8748],
            [128.60971, 35.87543],
            [128.60936, 35.87559],
            [128.60868, 35.87607],
            [128.60838, 35.87575],
            [128.6078, 35.87655],
            [128.60696, 35.87682],
            [128.60734, 35.87732],
            [128.6073, 35.8774],
            [128.6085, 35.87799],
            [128.60959, 35.87849],
            [128.60968, 35.87849],
            [128.60989, 35.87859],
            [128.61025, 35.87887],
            [128.61052, 35.87924],
            [128.61067, 35.8794],
            [128.61111, 35.87993],
            [128.61116, 35.87996],
            [128.61138, 35.88025],
            [128.61144, 35.8803],
            [128.61159, 35.88036],
            [128.61163, 35.88043],
            [128.61169, 35.88046],
            [128.61174, 35.8805],
            [128.61189, 35.88057],
            [128.61199, 35.88065],
            [128.61205, 35.88078],
            [128.61205, 35.88085],
            [128.61203, 35.88096],
            [128.61195, 35.88116],
            [128.61201, 35.8815],
            [128.61218, 35.8815],
            [128.61224, 35.88168],
            [128.61212, 35.88179],
            [128.61248, 35.88207],
            [128.61238, 35.88212],
            [128.61236, 35.88217],
            [128.61246, 35.88232],
            [128.61247, 35.88237],
            [128.61261, 35.88253],
            [128.61268, 35.88269],
            [128.61277, 35.88278],
            [128.613, 35.88313],
            [128.61307, 35.88329],
            [128.61383, 35.88428],
            [128.61389, 35.88437],
            [128.61402, 35.88443],
            [128.61405, 35.88449],
            [128.61404, 35.88455],
            [128.61408, 35.88459],
            [128.61418, 35.88463],
            [128.61419, 35.88468],
            [128.61429, 35.88472],
            [128.61424, 35.88481],
            [128.61498, 35.88521],
            [128.61508, 35.88524],
            [128.61524, 35.88537],
            [128.61549, 35.88611],
            [128.6156, 35.88668],
            [128.61571, 35.88679],
            [128.6158, 35.8871],
            [128.61591, 35.88726],
            [128.61595, 35.8874],
            [128.61615, 35.88768],
            [128.61624, 35.88789],
            [128.61629, 35.88787],
            [128.61643, 35.88813],
            [128.61685, 35.88868],
            [128.61734, 35.88926],
            [128.61755, 35.88941],
            [128.61914, 35.89046],
            [128.62208, 35.8924],
            [128.62235, 35.89229],
            [128.62335, 35.89184],
            [128.62554, 35.89089],
            [128.62698, 35.89023],
            [128.62817, 35.88935],
            [128.62874, 35.88986],
            [128.62867, 35.88991],
            [128.6301, 35.89111],
            [128.63031, 35.8913],
            [128.63038, 35.8917],
            [128.63107, 35.89197],
            [128.63174, 35.89294],
            [128.63183, 35.8929],
            [128.63193, 35.89388],
            [128.63148, 35.89423],
            [128.63074, 35.89526],
            [128.63, 35.8961],
            [128.62936, 35.89687],
            [128.62913, 35.8976],
            [128.62898, 35.89785],
            [128.62895, 35.89814],
            [128.62881, 35.89882],
            [128.62837, 35.8998],
            [128.62787, 35.90053],
            [128.62777, 35.90087],
            [128.62778, 35.90107],
            [128.62757, 35.90193],
            [128.62745, 35.90277],
            [128.62734, 35.90319],
            [128.62716, 35.90355],
            [128.6268, 35.90414],
            [128.62691, 35.90495],
            [128.62721, 35.90566],
            [128.62755, 35.90663],
            [128.628, 35.90742],
            [128.6282, 35.90766],
            [128.62859, 35.90806],
            [128.62935, 35.9088],
            [128.63008, 35.90946],
            [128.63054, 35.91],
            [128.63077, 35.91039],
            [128.6309, 35.91071],
            [128.63192, 35.91189],
            [128.63207, 35.91329],
            [128.63198, 35.9139],
            [128.63191, 35.91425],
            [128.63198, 35.91497],
            [128.63201, 35.91548],
            [128.63201, 35.91594],
            [128.63215, 35.9166],
            [128.63208, 35.91718],
            [128.63212, 35.91807],
            [128.63209, 35.91855],
            [128.6321, 35.92025],
            [128.63205, 35.92045],
            [128.63209, 35.92076],
            [128.63188, 35.92151],
            [128.63176, 35.92253],
            [128.63164, 35.92324],
            [128.63157, 35.92382],
            [128.63152, 35.92593],
            [128.63145, 35.9264],
            [128.63101, 35.92732],
            [128.63046, 35.9281],
            [128.63011, 35.92849],
            [128.62967, 35.92882],
            [128.62884, 35.92923],
            [128.62902, 35.9296],
            [128.62875, 35.9297],
            [128.6283, 35.92978],
            [128.62867, 35.93019],
            [128.62865, 35.93097],
            [128.62835, 35.93179],
            [128.62791, 35.9321],
            [128.62778, 35.93213],
            [128.6271, 35.93274],
            [128.62666, 35.9338],
            [128.62598, 35.93472],
            [128.62553, 35.93514],
            [128.62527, 35.93545],
            [128.62562, 35.9361],
            [128.62551, 35.9367],
            [128.62525, 35.93696],
            [128.62522, 35.93786],
            [128.62508, 35.93818],
            [128.62541, 35.93839],
            [128.62608, 35.93887],
            [128.62609, 35.93975],
            [128.62628, 35.94036],
            [128.62623, 35.94131],
            [128.62609, 35.94169],
            [128.62637, 35.94332],
            [128.62649, 35.94356],
            [128.62635, 35.94399],
            [128.62649, 35.94426],
            [128.6263, 35.94464],
            [128.62637, 35.94536],
            [128.62563, 35.94588],
            [128.62488, 35.94616],
            [128.62427, 35.94625],
            [128.6234, 35.94609],
            [128.62348, 35.947],
            [128.62357, 35.9471],
            [128.62336, 35.94795],
            [128.62322, 35.94836],
            [128.62332, 35.94951],
            [128.62377, 35.95019],
            [128.6238, 35.95108],
            [128.62366, 35.95155],
            [128.62358, 35.95195],
            [128.62351, 35.95221],
            [128.62369, 35.9525],
            [128.62468, 35.9531],
            [128.6246, 35.95368],
            [128.62435, 35.95457],
            [128.62486, 35.95503],
            [128.62545, 35.95576],
            [128.62637, 35.9563],
            [128.62662, 35.95648],
            [128.62676, 35.95797],
            [128.62675, 35.95806],
            [128.62635, 35.95839],
            [128.62568, 35.95958],
            [128.62542, 35.96019],
            [128.62524, 35.96059],
            [128.6252, 35.96063],
            [128.62439, 35.96116],
            [128.6239, 35.96138],
            [128.62257, 35.96211],
            [128.62253, 35.96269],
            [128.62219, 35.96336],
            [128.62282, 35.96427],
            [128.6233, 35.96542],
            [128.62299, 35.96591],
            [128.62227, 35.96631],
            [128.62201, 35.96671],
            [128.62157, 35.96693],
            [128.6209, 35.96832],
            [128.62041, 35.96904],
            [128.61895, 35.96993],
            [128.61863, 35.9702],
            [128.61833, 35.97052],
            [128.61843, 35.97195],
            [128.61905, 35.97285],
            [128.61985, 35.97388],
            [128.61991, 35.974],
            [128.61979, 35.9747],
            [128.61977, 35.97475],
            [128.61926, 35.97515],
            [128.61915, 35.97541],
            [128.61927, 35.97586],
            [128.61927, 35.97592],
            [128.61894, 35.97644],
            [128.61876, 35.97655],
            [128.61805, 35.97695],
            [128.61748, 35.97723],
            [128.61737, 35.97725],
            [128.61725, 35.97735],
            [128.61612, 35.97757],
            [128.61597, 35.97769],
            [128.61575, 35.97778],
            [128.615, 35.97812],
            [128.61478, 35.97927],
            [128.61482, 35.97937],
            [128.61488, 35.97952],
            [128.6148, 35.97964],
            [128.61463, 35.97969],
            [128.61451, 35.97975],
            [128.61448, 35.97982],
            [128.61447, 35.97987],
            [128.61441, 35.97995],
            [128.61446, 35.98016],
            [128.6144, 35.98023],
            [128.61438, 35.98029],
            [128.61431, 35.98039],
            [128.61415, 35.98051],
            [128.61396, 35.9807],
            [128.61388, 35.98073],
            [128.61353, 35.98141],
            [128.61352, 35.9817],
            [128.61295, 35.9825],
            [128.61204, 35.98328],
            [128.61048, 35.98436],
            [128.60989, 35.98497],
            [128.60944, 35.9853],
            [128.60893, 35.98545],
            [128.6086, 35.98579],
            [128.60797, 35.98609],
            [128.60628, 35.98706],
            [128.60499, 35.98757],
            [128.60375, 35.98774],
            [128.60616, 35.99068],
            [128.6067, 35.99197],
            [128.60786, 35.99297],
            [128.60832, 35.9932],
            [128.60875, 35.99347],
            [128.609, 35.9938],
            [128.60895, 35.99396],
            [128.60914, 35.99452],
            [128.60972, 35.99499],
            [128.61038, 35.996],
            [128.61026, 35.99619],
            [128.61015, 35.99642],
            [128.6101, 35.99659],
            [128.61007, 35.99663],
            [128.61011, 35.99667],
            [128.61011, 35.99674],
            [128.61022, 35.99683],
            [128.61058, 35.99698],
            [128.61065, 35.99703],
            [128.61076, 35.99707],
            [128.61094, 35.99696],
            [128.61119, 35.99739],
            [128.61149, 35.99755],
            [128.61172, 35.99752],
            [128.61197, 35.99786],
            [128.61266, 35.99918],
            [128.61368, 35.99936],
            [128.6146, 35.99981],
            [128.61508, 36.00029],
            [128.61585, 36.00082],
            [128.6161, 36.00153],
            [128.61637, 36.00207],
            [128.61671, 36.00304],
            [128.6172, 36.00481],
            [128.61732, 36.00536],
            [128.61767, 36.00655],
            [128.6181, 36.00631],
            [128.61999, 36.00593],
            [128.6202, 36.00596],
            [128.62079, 36.00583],
            [128.62174, 36.00587],
            [128.6222, 36.00581],
            [128.62229, 36.00604],
            [128.6239, 36.00619],
            [128.62431, 36.00726],
            [128.62477, 36.00748],
            [128.62534, 36.00755],
            [128.6257, 36.00736],
            [128.62603, 36.00758],
            [128.62638, 36.00764],
            [128.63004, 36.00709],
            [128.63084, 36.00698],
            [128.63263, 36.00811],
            [128.63334, 36.00829],
            [128.63407, 36.00855],
            [128.63498, 36.00902],
            [128.63693, 36.00915],
            [128.63716, 36.00913],
            [128.63764, 36.00918],
            [128.6379, 36.00915],
            [128.63884, 36.00923],
            [128.63961, 36.0095],
            [128.64003, 36.00956],
            [128.6407, 36.01042],
            [128.64222, 36.01031],
            [128.64274, 36.01039],
            [128.64301, 36.01037],
            [128.64388, 36.01053],
            [128.64408, 36.01049],
            [128.64524, 36.00998],
            [128.64567, 36.00971],
            [128.64636, 36.00958],
            [128.64787, 36.00885],
            [128.64835, 36.00843],
            [128.64972, 36.0077],
            [128.65042, 36.00738],
            [128.65109, 36.00762],
            [128.65117, 36.00758],
            [128.65318, 36.0084],
            [128.65402, 36.00948],
            [128.65433, 36.00945],
            [128.65515, 36.00962],
            [128.65597, 36.00994],
            [128.66003, 36.00999],
            [128.6623, 36.01036],
            [128.66358, 36.01021],
            [128.66483, 36.01061],
            [128.66488, 36.01067],
            [128.66548, 36.01087],
            [128.66679, 36.01252],
            [128.66734, 36.01281],
            [128.66787, 36.0129],
            [128.66837, 36.01312],
            [128.66854, 36.01315],
            [128.66902, 36.01336],
            [128.67223, 36.01328],
            [128.67304, 36.01349],
            [128.67685, 36.01336],
            [128.67723, 36.01358],
            [128.67742, 36.01357],
            [128.67958, 36.01476],
            [128.67979, 36.01491],
            [128.68235, 36.01473],
            [128.68462, 36.01449],
            [128.68591, 36.01462],
            [128.68649, 36.01426],
            [128.68708, 36.0145],
            [128.68715, 36.01446],
            [128.68902, 36.01524],
            [128.68995, 36.01573],
            [128.69117, 36.01569],
            [128.69367, 36.01607],
            [128.695, 36.01639],
            [128.69546, 36.0158]
          ]
        ]
      }
    },
    {
      id: '27170',
      type: 'Feature',
      properties: {
        SEC_SGG_CD: '27170',
        SEC_SGG_NM: ['대구광역시', '서구'],
        COL_SGG_CD: '27000',
        SEC_SGG_SIZE: '2',
        bbox: [128.51692, 35.85428, 128.58211, 35.89697],
        center: [128.54973, 35.87493],
        area: 17238538
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [128.5522, 35.89425],
            [128.55189, 35.89424],
            [128.55168, 35.89419],
            [128.55176, 35.89413],
            [128.55203, 35.89402],
            [128.55167, 35.89397],
            [128.55459, 35.89081],
            [128.55475, 35.89068],
            [128.55495, 35.89056],
            [128.55531, 35.8904],
            [128.55574, 35.89029],
            [128.56193, 35.88941],
            [128.56194, 35.88926],
            [128.56434, 35.88893],
            [128.56881, 35.88829],
            [128.56963, 35.88816],
            [128.56967, 35.88829],
            [128.57619, 35.8873],
            [128.57867, 35.88628],
            [128.57865, 35.88615],
            [128.57962, 35.88575],
            [128.57967, 35.88589],
            [128.58211, 35.88483],
            [128.58141, 35.8826],
            [128.58093, 35.88096],
            [128.58033, 35.87899],
            [128.57815, 35.8794],
            [128.57793, 35.87947],
            [128.57773, 35.87951],
            [128.57777, 35.8794],
            [128.5779, 35.87919],
            [128.57794, 35.87903],
            [128.5779, 35.87892],
            [128.57787, 35.87882],
            [128.57784, 35.87877],
            [128.57774, 35.87874],
            [128.57776, 35.87861],
            [128.57775, 35.87855],
            [128.57777, 35.87825],
            [128.57782, 35.87806],
            [128.57784, 35.87783],
            [128.57796, 35.87757],
            [128.57797, 35.87743],
            [128.578, 35.87718],
            [128.57805, 35.87695],
            [128.57804, 35.87685],
            [128.578, 35.87678],
            [128.57799, 35.87666],
            [128.57795, 35.87662],
            [128.57802, 35.87648],
            [128.57818, 35.87628],
            [128.57842, 35.87593],
            [128.57846, 35.87586],
            [128.579, 35.87551],
            [128.57902, 35.8754],
            [128.5789, 35.87542],
            [128.57883, 35.87541],
            [128.57867, 35.87545],
            [128.57849, 35.87545],
            [128.57843, 35.87549],
            [128.5784, 35.87554],
            [128.57811, 35.87566],
            [128.57803, 35.87567],
            [128.57786, 35.87576],
            [128.57754, 35.87596],
            [128.57698, 35.87625],
            [128.57685, 35.87628],
            [128.57675, 35.87627],
            [128.57667, 35.87623],
            [128.57655, 35.87614],
            [128.57646, 35.87597],
            [128.57645, 35.87576],
            [128.57639, 35.87558],
            [128.57633, 35.87548],
            [128.57625, 35.87527],
            [128.5763, 35.87519],
            [128.57628, 35.87495],
            [128.57631, 35.87487],
            [128.57626, 35.87476],
            [128.57621, 35.87453],
            [128.57615, 35.87451],
            [128.57602, 35.87452],
            [128.57603, 35.87447],
            [128.57573, 35.87432],
            [128.57564, 35.87421],
            [128.57555, 35.87412],
            [128.57549, 35.87394],
            [128.57547, 35.87384],
            [128.57549, 35.87369],
            [128.57552, 35.87359],
            [128.57552, 35.87346],
            [128.57556, 35.87331],
            [128.57556, 35.87314],
            [128.57563, 35.8731],
            [128.57569, 35.87302],
            [128.57588, 35.87295],
            [128.57598, 35.87287],
            [128.57605, 35.87289],
            [128.57612, 35.87289],
            [128.57638, 35.87277],
            [128.57643, 35.8727],
            [128.57645, 35.87263],
            [128.57647, 35.87244],
            [128.57656, 35.87221],
            [128.57675, 35.87193],
            [128.577, 35.87197],
            [128.57732, 35.87197],
            [128.57752, 35.87203],
            [128.57762, 35.87204],
            [128.57789, 35.87204],
            [128.57792, 35.87211],
            [128.57788, 35.87221],
            [128.57803, 35.87231],
            [128.57816, 35.87245],
            [128.57824, 35.87247],
            [128.57858, 35.87239],
            [128.57878, 35.87238],
            [128.57885, 35.8724],
            [128.57911, 35.87256],
            [128.57928, 35.87275],
            [128.57938, 35.87286],
            [128.57942, 35.87274],
            [128.5794, 35.87262],
            [128.5794, 35.87248],
            [128.57947, 35.87222],
            [128.57944, 35.87193],
            [128.57941, 35.87188],
            [128.57941, 35.87181],
            [128.57936, 35.87173],
            [128.57934, 35.87102],
            [128.57937, 35.87095],
            [128.5794, 35.87056],
            [128.57963, 35.87031],
            [128.57952, 35.87023],
            [128.57931, 35.86987],
            [128.57927, 35.86982],
            [128.57909, 35.86954],
            [128.57906, 35.86944],
            [128.57859, 35.86895],
            [128.57842, 35.86883],
            [128.57838, 35.86871],
            [128.57725, 35.86779],
            [128.57652, 35.86732],
            [128.57499, 35.86625],
            [128.57471, 35.86606],
            [128.57381, 35.86533],
            [128.57394, 35.86441],
            [128.57422, 35.86266],
            [128.57191, 35.86211],
            [128.57143, 35.86204],
            [128.57128, 35.86189],
            [128.57112, 35.86181],
            [128.57039, 35.86167],
            [128.56944, 35.86139],
            [128.56907, 35.86133],
            [128.56849, 35.86112],
            [128.56835, 35.86112],
            [128.56684, 35.86069],
            [128.56681, 35.86076],
            [128.55855, 35.85823],
            [128.5566, 35.85756],
            [128.54817, 35.85428],
            [128.54815, 35.85436],
            [128.54803, 35.85432],
            [128.5474, 35.85647],
            [128.54367, 35.85573],
            [128.54349, 35.8563],
            [128.54207, 35.85602],
            [128.54208, 35.85627],
            [128.54194, 35.85629],
            [128.54109, 35.8563],
            [128.54064, 35.85632],
            [128.54014, 35.85626],
            [128.54016, 35.85636],
            [128.54014, 35.85666],
            [128.54016, 35.85674],
            [128.54023, 35.85699],
            [128.54045, 35.85735],
            [128.54049, 35.85753],
            [128.54048, 35.85767],
            [128.54038, 35.85785],
            [128.5402, 35.85805],
            [128.53996, 35.85839],
            [128.53988, 35.85871],
            [128.53372, 35.85869],
            [128.53319, 35.85871],
            [128.53319, 35.85881],
            [128.53378, 35.86189],
            [128.53291, 35.86149],
            [128.53234, 35.8615],
            [128.53139, 35.86147],
            [128.52764, 35.86154],
            [128.52739, 35.8617],
            [128.52726, 35.86212],
            [128.52716, 35.8622],
            [128.52708, 35.86237],
            [128.52705, 35.86247],
            [128.52697, 35.86253],
            [128.52691, 35.86262],
            [128.5268, 35.86272],
            [128.52673, 35.86277],
            [128.52661, 35.8629],
            [128.52656, 35.86299],
            [128.52653, 35.8631],
            [128.52671, 35.8635],
            [128.52698, 35.8638],
            [128.52778, 35.86445],
            [128.52744, 35.86478],
            [128.52711, 35.86504],
            [128.52666, 35.86525],
            [128.52633, 35.86544],
            [128.52553, 35.86575],
            [128.52521, 35.86605],
            [128.52492, 35.86679],
            [128.52488, 35.86691],
            [128.52488, 35.867],
            [128.5247, 35.86704],
            [128.52328, 35.8673],
            [128.52302, 35.86733],
            [128.52221, 35.86722],
            [128.52064, 35.8674],
            [128.52018, 35.86806],
            [128.51962, 35.86877],
            [128.51943, 35.86895],
            [128.51924, 35.8692],
            [128.51879, 35.8695],
            [128.51906, 35.8697],
            [128.51919, 35.86995],
            [128.51973, 35.87025],
            [128.51995, 35.8705],
            [128.52019, 35.87082],
            [128.52097, 35.87217],
            [128.52113, 35.87252],
            [128.52086, 35.87318],
            [128.5204, 35.87377],
            [128.52035, 35.87467],
            [128.52038, 35.87536],
            [128.52031, 35.87627],
            [128.52014, 35.87677],
            [128.51998, 35.87718],
            [128.5197, 35.87781],
            [128.51956, 35.87808],
            [128.51905, 35.87897],
            [128.51873, 35.87961],
            [128.51841, 35.87991],
            [128.51767, 35.88125],
            [128.51755, 35.88149],
            [128.51692, 35.88244],
            [128.51693, 35.88249],
            [128.5171, 35.88278],
            [128.51719, 35.88317],
            [128.51736, 35.88336],
            [128.51819, 35.88403],
            [128.51981, 35.88425],
            [128.52033, 35.88461],
            [128.52077, 35.88494],
            [128.52136, 35.88525],
            [128.52209, 35.88511],
            [128.52321, 35.88543],
            [128.52331, 35.88551],
            [128.5237, 35.88571],
            [128.52396, 35.88571],
            [128.52468, 35.88564],
            [128.52529, 35.88576],
            [128.52612, 35.88633],
            [128.52541, 35.88679],
            [128.52553, 35.88687],
            [128.52544, 35.88712],
            [128.52625, 35.88818],
            [128.52662, 35.88783],
            [128.52676, 35.88802],
            [128.52692, 35.88788],
            [128.52765, 35.88699],
            [128.52805, 35.88658],
            [128.52895, 35.88588],
            [128.52994, 35.88571],
            [128.53115, 35.88562],
            [128.53316, 35.88552],
            [128.53386, 35.88555],
            [128.53396, 35.88519],
            [128.53495, 35.88548],
            [128.53634, 35.88624],
            [128.53683, 35.88682],
            [128.53695, 35.88719],
            [128.53703, 35.88771],
            [128.53732, 35.88818],
            [128.53774, 35.88905],
            [128.53823, 35.88973],
            [128.53839, 35.89015],
            [128.53847, 35.89054],
            [128.53853, 35.89107],
            [128.5387, 35.89137],
            [128.54081, 35.89333],
            [128.54374, 35.89432],
            [128.54636, 35.89477],
            [128.55138, 35.89676],
            [128.55187, 35.89697],
            [128.55313, 35.89541],
            [128.55293, 35.89528],
            [128.55265, 35.89491],
            [128.55216, 35.89454],
            [128.5522, 35.89425]
          ]
        ]
      }
    },
    {
      id: '27200',
      type: 'Feature',
      properties: {
        SEC_SGG_CD: '27200',
        SEC_SGG_NM: ['대구광역시', '남구'],
        COL_SGG_CD: '27000',
        SEC_SGG_SIZE: '2',
        bbox: [128.55554, 35.80731, 128.60888, 35.85999],
        center: [128.58541, 35.83516],
        area: 17778743
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [128.58215, 35.85661],
            [128.58289, 35.85628],
            [128.59039, 35.85696],
            [128.5904, 35.85707],
            [128.59044, 35.85715],
            [128.59053, 35.85721],
            [128.59069, 35.85725],
            [128.59084, 35.85722],
            [128.59094, 35.85716],
            [128.59101, 35.85707],
            [128.59101, 35.85697],
            [128.59474, 35.85656],
            [128.59679, 35.85633],
            [128.59704, 35.85623],
            [128.59736, 35.85607],
            [128.59762, 35.85598],
            [128.59771, 35.85589],
            [128.59779, 35.85586],
            [128.59801, 35.85572],
            [128.59802, 35.85577],
            [128.59801, 35.85583],
            [128.5981, 35.85561],
            [128.59811, 35.85553],
            [128.60208, 35.85529],
            [128.60352, 35.85523],
            [128.60363, 35.85518],
            [128.60375, 35.85521],
            [128.60503, 35.85514],
            [128.60648, 35.85506],
            [128.60718, 35.85499],
            [128.60733, 35.855],
            [128.60834, 35.85495],
            [128.60873, 35.8543],
            [128.60887, 35.85365],
            [128.60888, 35.85332],
            [128.60886, 35.85245],
            [128.60852, 35.85113],
            [128.60849, 35.85079],
            [128.60834, 35.84985],
            [128.60831, 35.84906],
            [128.60799, 35.84787],
            [128.60787, 35.84717],
            [128.60784, 35.84647],
            [128.60817, 35.84478],
            [128.60792, 35.8437],
            [128.60772, 35.84305],
            [128.60763, 35.84257],
            [128.60763, 35.84195],
            [128.60766, 35.8415],
            [128.60751, 35.84006],
            [128.60738, 35.83957],
            [128.60725, 35.83933],
            [128.60711, 35.83916],
            [128.60701, 35.83915],
            [128.6066, 35.83914],
            [128.60654, 35.83892],
            [128.60648, 35.83821],
            [128.60613, 35.83642],
            [128.60586, 35.83539],
            [128.60595, 35.83491],
            [128.6061, 35.83429],
            [128.60631, 35.83353],
            [128.60634, 35.83314],
            [128.60629, 35.8329],
            [128.6063, 35.83252],
            [128.60639, 35.83212],
            [128.60705, 35.8303],
            [128.60743, 35.82949],
            [128.60782, 35.8286],
            [128.60777, 35.82802],
            [128.60775, 35.82746],
            [128.60784, 35.82714],
            [128.60702, 35.82719],
            [128.60637, 35.8276],
            [128.60631, 35.82722],
            [128.6062, 35.82714],
            [128.60567, 35.82718],
            [128.6056, 35.82678],
            [128.60551, 35.82679],
            [128.60547, 35.82665],
            [128.60501, 35.82668],
            [128.60472, 35.82535],
            [128.60465, 35.82512],
            [128.60482, 35.82467],
            [128.60483, 35.82418],
            [128.6043, 35.8235],
            [128.60417, 35.82251],
            [128.60426, 35.8224],
            [128.6036, 35.82123],
            [128.60193, 35.81855],
            [128.60171, 35.81799],
            [128.60079, 35.81569],
            [128.59996, 35.81483],
            [128.59887, 35.81493],
            [128.59842, 35.8143],
            [128.59676, 35.81386],
            [128.59485, 35.81316],
            [128.59456, 35.81308],
            [128.59407, 35.81255],
            [128.59376, 35.81218],
            [128.59259, 35.81124],
            [128.59223, 35.81034],
            [128.59244, 35.80961],
            [128.5922, 35.80962],
            [128.59177, 35.80925],
            [128.59121, 35.80894],
            [128.59101, 35.80902],
            [128.59047, 35.80852],
            [128.59015, 35.80818],
            [128.58953, 35.80794],
            [128.58777, 35.80766],
            [128.58518, 35.80731],
            [128.58482, 35.80747],
            [128.58474, 35.80884],
            [128.58473, 35.80932],
            [128.58435, 35.80924],
            [128.58343, 35.81096],
            [128.58275, 35.81211],
            [128.58174, 35.81206],
            [128.58108, 35.81184],
            [128.58004, 35.81187],
            [128.5795, 35.81215],
            [128.57884, 35.81333],
            [128.57762, 35.81398],
            [128.57675, 35.81514],
            [128.57505, 35.81727],
            [128.57272, 35.8185],
            [128.57218, 35.8188],
            [128.57117, 35.81891],
            [128.57116, 35.81897],
            [128.57075, 35.81941],
            [128.57113, 35.82066],
            [128.57108, 35.82203],
            [128.5708, 35.82226],
            [128.57052, 35.82281],
            [128.57006, 35.82315],
            [128.56937, 35.82428],
            [128.56862, 35.82492],
            [128.56739, 35.82356],
            [128.56614, 35.82392],
            [128.56352, 35.82427],
            [128.56328, 35.8245],
            [128.56262, 35.82541],
            [128.56254, 35.82559],
            [128.56245, 35.82558],
            [128.56123, 35.82807],
            [128.56106, 35.82836],
            [128.56112, 35.82837],
            [128.56096, 35.82874],
            [128.56096, 35.82886],
            [128.56058, 35.82925],
            [128.56051, 35.82939],
            [128.5605, 35.82944],
            [128.56056, 35.82961],
            [128.56056, 35.82966],
            [128.56039, 35.82965],
            [128.56036, 35.82989],
            [128.56017, 35.83039],
            [128.55889, 35.8316],
            [128.5588, 35.83166],
            [128.55848, 35.8318],
            [128.55836, 35.83204],
            [128.55849, 35.83203],
            [128.5583, 35.83227],
            [128.55842, 35.83248],
            [128.5582, 35.8329],
            [128.55778, 35.83317],
            [128.55709, 35.83357],
            [128.55661, 35.83394],
            [128.55633, 35.83402],
            [128.55592, 35.8341],
            [128.55569, 35.83437],
            [128.55554, 35.83445],
            [128.55562, 35.83473],
            [128.55602, 35.83559],
            [128.55624, 35.83594],
            [128.55634, 35.83614],
            [128.55655, 35.83641],
            [128.55681, 35.83684],
            [128.557, 35.83705],
            [128.55733, 35.83737],
            [128.55783, 35.83774],
            [128.55755, 35.83768],
            [128.56187, 35.84328],
            [128.56199, 35.84344],
            [128.56211, 35.84355],
            [128.56511, 35.84593],
            [128.57188, 35.85008],
            [128.57194, 35.85001],
            [128.57362, 35.85114],
            [128.57355, 35.85121],
            [128.57358, 35.85154],
            [128.57363, 35.85166],
            [128.57365, 35.85175],
            [128.57408, 35.85577],
            [128.57448, 35.85992],
            [128.57446, 35.85999],
            [128.58215, 35.85661]
          ]
        ]
      }
    },
    {
      id: '27230',
      type: 'Feature',
      properties: {
        SEC_SGG_CD: '27230',
        SEC_SGG_NM: ['대구광역시', '북구'],
        COL_SGG_CD: '27000',
        SEC_SGG_SIZE: '2',
        bbox: [128.50463, 35.87358, 128.63215, 35.98768],
        center: [128.57735, 35.92854],
        area: 93449326
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [128.61388, 35.98073],
            [128.61396, 35.9807],
            [128.61415, 35.98051],
            [128.61431, 35.98039],
            [128.61438, 35.98029],
            [128.6144, 35.98023],
            [128.61446, 35.98016],
            [128.61441, 35.97995],
            [128.61447, 35.97987],
            [128.61448, 35.97982],
            [128.61451, 35.97975],
            [128.61463, 35.97969],
            [128.6148, 35.97964],
            [128.61488, 35.97952],
            [128.61485, 35.97943],
            [128.61482, 35.97937],
            [128.61478, 35.97927],
            [128.615, 35.97812],
            [128.61575, 35.97778],
            [128.61597, 35.97769],
            [128.61612, 35.97757],
            [128.61725, 35.97735],
            [128.61737, 35.97725],
            [128.61748, 35.97723],
            [128.61805, 35.97695],
            [128.61876, 35.97655],
            [128.61894, 35.97644],
            [128.61927, 35.97592],
            [128.61927, 35.97586],
            [128.61915, 35.97541],
            [128.61926, 35.97515],
            [128.61977, 35.97475],
            [128.61979, 35.9747],
            [128.61991, 35.974],
            [128.61985, 35.97388],
            [128.61905, 35.97285],
            [128.61843, 35.97195],
            [128.61833, 35.97052],
            [128.61863, 35.9702],
            [128.61895, 35.96993],
            [128.62041, 35.96904],
            [128.6209, 35.96832],
            [128.62157, 35.96693],
            [128.62201, 35.96671],
            [128.62227, 35.96631],
            [128.62299, 35.96591],
            [128.6233, 35.96542],
            [128.62282, 35.96427],
            [128.62219, 35.96336],
            [128.62253, 35.96269],
            [128.62257, 35.96211],
            [128.6239, 35.96138],
            [128.62439, 35.96116],
            [128.6252, 35.96063],
            [128.62524, 35.96059],
            [128.62542, 35.96019],
            [128.62568, 35.95958],
            [128.62635, 35.95839],
            [128.62675, 35.95806],
            [128.62676, 35.95797],
            [128.62662, 35.95648],
            [128.62637, 35.9563],
            [128.62545, 35.95576],
            [128.62486, 35.95503],
            [128.62435, 35.95457],
            [128.6246, 35.95368],
            [128.62468, 35.9531],
            [128.62369, 35.9525],
            [128.62351, 35.95221],
            [128.62358, 35.95195],
            [128.62366, 35.95155],
            [128.6238, 35.95108],
            [128.62377, 35.95019],
            [128.62332, 35.94951],
            [128.62322, 35.94836],
            [128.62336, 35.94795],
            [128.62357, 35.9471],
            [128.62348, 35.947],
            [128.6234, 35.94609],
            [128.62427, 35.94625],
            [128.62488, 35.94616],
            [128.62563, 35.94588],
            [128.62637, 35.94536],
            [128.6263, 35.94464],
            [128.62649, 35.94426],
            [128.62635, 35.94399],
            [128.62649, 35.94356],
            [128.62637, 35.94332],
            [128.62609, 35.94169],
            [128.62623, 35.94131],
            [128.62628, 35.94036],
            [128.62609, 35.93975],
            [128.62608, 35.93887],
            [128.62541, 35.93839],
            [128.62508, 35.93818],
            [128.62522, 35.93786],
            [128.62525, 35.93696],
            [128.62551, 35.9367],
            [128.62562, 35.9361],
            [128.62527, 35.93545],
            [128.62553, 35.93514],
            [128.62598, 35.93472],
            [128.62666, 35.9338],
            [128.6271, 35.93274],
            [128.62778, 35.93213],
            [128.62791, 35.9321],
            [128.62835, 35.93179],
            [128.62865, 35.93097],
            [128.62867, 35.93019],
            [128.6283, 35.92978],
            [128.62875, 35.9297],
            [128.62902, 35.9296],
            [128.62884, 35.92923],
            [128.62967, 35.92882],
            [128.63011, 35.92849],
            [128.63046, 35.9281],
            [128.63101, 35.92732],
            [128.63145, 35.9264],
            [128.63152, 35.92593],
            [128.63157, 35.92382],
            [128.63164, 35.92324],
            [128.63176, 35.92253],
            [128.63188, 35.92151],
            [128.63209, 35.92076],
            [128.63205, 35.92045],
            [128.6321, 35.92025],
            [128.63211, 35.91945],
            [128.63209, 35.91855],
            [128.63212, 35.91807],
            [128.63208, 35.91718],
            [128.63215, 35.9166],
            [128.63201, 35.91594],
            [128.63201, 35.91548],
            [128.63198, 35.91497],
            [128.63191, 35.91425],
            [128.63198, 35.9139],
            [128.63207, 35.91329],
            [128.63192, 35.91189],
            [128.6309, 35.91071],
            [128.63077, 35.91039],
            [128.63054, 35.91],
            [128.63008, 35.90946],
            [128.62935, 35.9088],
            [128.62859, 35.90806],
            [128.6282, 35.90766],
            [128.628, 35.90742],
            [128.62755, 35.90663],
            [128.62721, 35.90566],
            [128.62691, 35.90495],
            [128.62684, 35.90444],
            [128.6268, 35.90414],
            [128.62716, 35.90355],
            [128.62734, 35.90319],
            [128.62745, 35.90277],
            [128.62757, 35.90193],
            [128.62778, 35.90107],
            [128.62777, 35.90087],
            [128.62787, 35.90053],
            [128.62837, 35.8998],
            [128.62881, 35.89882],
            [128.62895, 35.89814],
            [128.62898, 35.89785],
            [128.62913, 35.8976],
            [128.62936, 35.89687],
            [128.63, 35.8961],
            [128.63074, 35.89526],
            [128.63148, 35.89423],
            [128.63193, 35.89388],
            [128.63183, 35.8929],
            [128.63174, 35.89294],
            [128.63107, 35.89197],
            [128.63038, 35.8917],
            [128.63031, 35.8913],
            [128.6301, 35.89111],
            [128.62867, 35.88991],
            [128.62874, 35.88986],
            [128.62817, 35.88935],
            [128.62698, 35.89023],
            [128.62554, 35.89089],
            [128.62335, 35.89184],
            [128.62235, 35.89229],
            [128.62208, 35.8924],
            [128.61755, 35.88941],
            [128.61734, 35.88926],
            [128.61685, 35.88868],
            [128.61643, 35.88813],
            [128.61629, 35.88787],
            [128.61624, 35.88789],
            [128.61615, 35.88768],
            [128.61595, 35.8874],
            [128.61591, 35.88726],
            [128.6158, 35.8871],
            [128.61571, 35.88679],
            [128.6156, 35.88668],
            [128.61549, 35.88611],
            [128.61524, 35.88537],
            [128.61508, 35.88524],
            [128.61498, 35.88521],
            [128.61424, 35.88481],
            [128.61429, 35.88472],
            [128.61419, 35.88468],
            [128.61418, 35.88463],
            [128.61408, 35.88459],
            [128.61404, 35.88455],
            [128.61405, 35.88449],
            [128.61402, 35.88443],
            [128.61389, 35.88437],
            [128.61383, 35.88428],
            [128.61307, 35.88329],
            [128.613, 35.88313],
            [128.61277, 35.88278],
            [128.61268, 35.88269],
            [128.61261, 35.88253],
            [128.61246, 35.88232],
            [128.61236, 35.88217],
            [128.61238, 35.88212],
            [128.61248, 35.88207],
            [128.61212, 35.88179],
            [128.61224, 35.88168],
            [128.61218, 35.8815],
            [128.61201, 35.8815],
            [128.61195, 35.88116],
            [128.61203, 35.88096],
            [128.61205, 35.88085],
            [128.61205, 35.88078],
            [128.61199, 35.88065],
            [128.61189, 35.88057],
            [128.61174, 35.8805],
            [128.61169, 35.88046],
            [128.61163, 35.88043],
            [128.61159, 35.88036],
            [128.61144, 35.8803],
            [128.61138, 35.88025],
            [128.61116, 35.87996],
            [128.61111, 35.87993],
            [128.61067, 35.8794],
            [128.61052, 35.87924],
            [128.61025, 35.87887],
            [128.60989, 35.87859],
            [128.60968, 35.87849],
            [128.60959, 35.87849],
            [128.6085, 35.87799],
            [128.6073, 35.8774],
            [128.60734, 35.87732],
            [128.60696, 35.87682],
            [128.6078, 35.87655],
            [128.60838, 35.87575],
            [128.60868, 35.87607],
            [128.60936, 35.87559],
            [128.60971, 35.87543],
            [128.60972, 35.8748],
            [128.60995, 35.87461],
            [128.60999, 35.87453],
            [128.60976, 35.87447],
            [128.60977, 35.87444],
            [128.60975, 35.87438],
            [128.60958, 35.87437],
            [128.60894, 35.8742],
            [128.60875, 35.87413],
            [128.60759, 35.8738],
            [128.60628, 35.87363],
            [128.60626, 35.87376],
            [128.60604, 35.87371],
            [128.60554, 35.87362],
            [128.60514, 35.87358],
            [128.60469, 35.87359],
            [128.60417, 35.87367],
            [128.60376, 35.87378],
            [128.60295, 35.87403],
            [128.60296, 35.87409],
            [128.60269, 35.87413],
            [128.60152, 35.87448],
            [128.60037, 35.87478],
            [128.59985, 35.87475],
            [128.59609, 35.87547],
            [128.59496, 35.87567],
            [128.59496, 35.87578],
            [128.58859, 35.87705],
            [128.58859, 35.87723],
            [128.58835, 35.8773],
            [128.58822, 35.87732],
            [128.58822, 35.87747],
            [128.58798, 35.87752],
            [128.58778, 35.87758],
            [128.58768, 35.87758],
            [128.58747, 35.87761],
            [128.58745, 35.87768],
            [128.58737, 35.87766],
            [128.58724, 35.87765],
            [128.58721, 35.87771],
            [128.58706, 35.8777],
            [128.5861, 35.87788],
            [128.58492, 35.87807],
            [128.58416, 35.87822],
            [128.58377, 35.87828],
            [128.5807, 35.87891],
            [128.58033, 35.87899],
            [128.58093, 35.88096],
            [128.58141, 35.8826],
            [128.58211, 35.88483],
            [128.57967, 35.88589],
            [128.57962, 35.88575],
            [128.57865, 35.88615],
            [128.57867, 35.88628],
            [128.57619, 35.8873],
            [128.56967, 35.88829],
            [128.56963, 35.88816],
            [128.56881, 35.88829],
            [128.56434, 35.88893],
            [128.56194, 35.88926],
            [128.56193, 35.88941],
            [128.55574, 35.89029],
            [128.55542, 35.89036],
            [128.55521, 35.89043],
            [128.55495, 35.89056],
            [128.55459, 35.89081],
            [128.55167, 35.89397],
            [128.55203, 35.89402],
            [128.55187, 35.89407],
            [128.55168, 35.89419],
            [128.55189, 35.89424],
            [128.55207, 35.89423],
            [128.5522, 35.89425],
            [128.55216, 35.89454],
            [128.55265, 35.89491],
            [128.55293, 35.89528],
            [128.55313, 35.89541],
            [128.55187, 35.89697],
            [128.55138, 35.89676],
            [128.54636, 35.89477],
            [128.54374, 35.89432],
            [128.54081, 35.89333],
            [128.5387, 35.89137],
            [128.53853, 35.89107],
            [128.53847, 35.89054],
            [128.53839, 35.89015],
            [128.53823, 35.88973],
            [128.53774, 35.88905],
            [128.53732, 35.88818],
            [128.53703, 35.88771],
            [128.53695, 35.88719],
            [128.53683, 35.88682],
            [128.53634, 35.88624],
            [128.53495, 35.88548],
            [128.53396, 35.88519],
            [128.53386, 35.88555],
            [128.53316, 35.88552],
            [128.53115, 35.88562],
            [128.52994, 35.88571],
            [128.52895, 35.88588],
            [128.52805, 35.88658],
            [128.52765, 35.88699],
            [128.52692, 35.88788],
            [128.52676, 35.88802],
            [128.52662, 35.88783],
            [128.52625, 35.88818],
            [128.52635, 35.88829],
            [128.52607, 35.88838],
            [128.5244, 35.88911],
            [128.52332, 35.88967],
            [128.52208, 35.89039],
            [128.52018, 35.89112],
            [128.51883, 35.89176],
            [128.51777, 35.89192],
            [128.51685, 35.8922],
            [128.5163, 35.89228],
            [128.51557, 35.89244],
            [128.51368, 35.8932],
            [128.51316, 35.89328],
            [128.51205, 35.89335],
            [128.50947, 35.89329],
            [128.50884, 35.89334],
            [128.50775, 35.89324],
            [128.5076, 35.89316],
            [128.50696, 35.89294],
            [128.50652, 35.89259],
            [128.50615, 35.89212],
            [128.50586, 35.89171],
            [128.50571, 35.89162],
            [128.50517, 35.89128],
            [128.50483, 35.89145],
            [128.50495, 35.89169],
            [128.50466, 35.89174],
            [128.50469, 35.89243],
            [128.50463, 35.89339],
            [128.50472, 35.89373],
            [128.50502, 35.89399],
            [128.50513, 35.8946],
            [128.50538, 35.89527],
            [128.5054, 35.89561],
            [128.50573, 35.89614],
            [128.50584, 35.89668],
            [128.50548, 35.89733],
            [128.50544, 35.89753],
            [128.50465, 35.89801],
            [128.50471, 35.89873],
            [128.50485, 35.89932],
            [128.50522, 35.8997],
            [128.50597, 35.90039],
            [128.50587, 35.90123],
            [128.50561, 35.90214],
            [128.50518, 35.90258],
            [128.50512, 35.90281],
            [128.50574, 35.90325],
            [128.50585, 35.90347],
            [128.506, 35.90408],
            [128.50659, 35.90465],
            [128.50697, 35.90519],
            [128.50701, 35.90551],
            [128.50698, 35.9056],
            [128.50699, 35.90565],
            [128.50683, 35.90611],
            [128.5074, 35.90648],
            [128.50769, 35.90696],
            [128.50819, 35.90755],
            [128.50894, 35.9079],
            [128.50904, 35.90804],
            [128.50912, 35.90812],
            [128.50939, 35.90851],
            [128.50966, 35.90886],
            [128.50984, 35.90932],
            [128.51012, 35.90993],
            [128.51067, 35.90995],
            [128.51082, 35.9102],
            [128.51118, 35.91035],
            [128.51188, 35.9104],
            [128.51233, 35.91066],
            [128.51274, 35.9111],
            [128.51348, 35.91155],
            [128.51409, 35.91157],
            [128.51461, 35.91115],
            [128.51495, 35.91102],
            [128.51504, 35.91102],
            [128.51528, 35.91114],
            [128.51573, 35.91147],
            [128.51603, 35.91148],
            [128.51626, 35.91159],
            [128.5169, 35.91187],
            [128.51714, 35.91261],
            [128.51757, 35.91302],
            [128.51815, 35.9135],
            [128.5188, 35.91392],
            [128.51887, 35.9139],
            [128.51915, 35.91429],
            [128.51923, 35.91466],
            [128.51926, 35.91474],
            [128.51973, 35.91515],
            [128.52001, 35.91545],
            [128.52118, 35.91596],
            [128.5218, 35.91635],
            [128.52211, 35.9165],
            [128.52233, 35.91657],
            [128.52246, 35.91682],
            [128.52316, 35.91757],
            [128.52316, 35.91765],
            [128.5232, 35.9177],
            [128.52321, 35.9188],
            [128.52308, 35.9192],
            [128.52328, 35.91941],
            [128.52331, 35.91955],
            [128.52384, 35.91996],
            [128.52455, 35.92032],
            [128.5246, 35.92038],
            [128.52482, 35.92083],
            [128.5249, 35.92132],
            [128.52499, 35.92141],
            [128.52534, 35.92168],
            [128.52568, 35.92202],
            [128.52588, 35.92261],
            [128.52584, 35.92291],
            [128.52674, 35.92269],
            [128.52719, 35.9228],
            [128.52767, 35.92318],
            [128.52863, 35.92363],
            [128.52947, 35.92362],
            [128.52973, 35.92448],
            [128.52966, 35.92522],
            [128.53001, 35.92568],
            [128.52994, 35.92619],
            [128.53009, 35.92663],
            [128.53006, 35.92726],
            [128.53027, 35.92815],
            [128.53055, 35.92908],
            [128.53132, 35.93017],
            [128.53174, 35.9306],
            [128.53185, 35.93071],
            [128.53332, 35.9318],
            [128.53356, 35.93214],
            [128.53357, 35.93347],
            [128.53357, 35.93371],
            [128.5336, 35.93377],
            [128.53361, 35.93398],
            [128.53372, 35.9346],
            [128.53382, 35.93485],
            [128.53437, 35.93532],
            [128.53425, 35.93598],
            [128.53439, 35.937],
            [128.53505, 35.93789],
            [128.53511, 35.93801],
            [128.53537, 35.93836],
            [128.53513, 35.93852],
            [128.53495, 35.93896],
            [128.53472, 35.93961],
            [128.53471, 35.94001],
            [128.53474, 35.94027],
            [128.53482, 35.94064],
            [128.5347, 35.94098],
            [128.53478, 35.94184],
            [128.53428, 35.94232],
            [128.53439, 35.94312],
            [128.5341, 35.94342],
            [128.53405, 35.94424],
            [128.53408, 35.94446],
            [128.53336, 35.94552],
            [128.53402, 35.94608],
            [128.53353, 35.94777],
            [128.53375, 35.94801],
            [128.53377, 35.94806],
            [128.53381, 35.94833],
            [128.53365, 35.94918],
            [128.53301, 35.94953],
            [128.53255, 35.95054],
            [128.53284, 35.95153],
            [128.533, 35.95284],
            [128.53322, 35.95369],
            [128.53341, 35.95409],
            [128.53408, 35.95564],
            [128.5345, 35.95692],
            [128.53468, 35.95772],
            [128.53488, 35.9584],
            [128.53357, 35.96094],
            [128.53286, 35.96131],
            [128.53194, 35.9613],
            [128.53157, 35.96146],
            [128.53087, 35.96157],
            [128.53058, 35.96194],
            [128.53048, 35.96218],
            [128.53028, 35.96304],
            [128.53016, 35.96359],
            [128.52989, 35.96508],
            [128.52957, 35.96549],
            [128.52942, 35.96599],
            [128.52926, 35.96885],
            [128.52778, 35.97155],
            [128.52809, 35.97247],
            [128.52828, 35.97324],
            [128.52791, 35.97359],
            [128.52781, 35.97363],
            [128.52713, 35.9752],
            [128.52734, 35.97567],
            [128.52805, 35.97614],
            [128.52935, 35.97705],
            [128.52947, 35.97775],
            [128.52875, 35.9788],
            [128.52886, 35.98013],
            [128.52895, 35.98023],
            [128.53123, 35.98029],
            [128.53418, 35.97893],
            [128.53594, 35.97914],
            [128.53709, 35.97706],
            [128.53896, 35.97629],
            [128.53973, 35.9762],
            [128.54021, 35.97606],
            [128.54042, 35.97603],
            [128.54038, 35.97574],
            [128.54133, 35.97531],
            [128.54152, 35.97516],
            [128.54196, 35.97515],
            [128.54258, 35.97473],
            [128.54304, 35.97452],
            [128.54331, 35.97424],
            [128.54296, 35.97359],
            [128.54294, 35.97331],
            [128.54317, 35.97283],
            [128.54317, 35.97276],
            [128.54473, 35.97075],
            [128.54472, 35.97051],
            [128.54543, 35.96956],
            [128.5457, 35.96871],
            [128.54567, 35.96846],
            [128.54593, 35.96788],
            [128.54578, 35.96709],
            [128.54582, 35.96611],
            [128.54632, 35.96546],
            [128.54719, 35.96463],
            [128.54857, 35.96321],
            [128.5486, 35.96289],
            [128.54875, 35.96244],
            [128.54918, 35.96196],
            [128.55018, 35.96213],
            [128.55042, 35.96193],
            [128.5505, 35.96188],
            [128.55062, 35.96185],
            [128.55077, 35.96177],
            [128.55094, 35.96173],
            [128.55117, 35.96163],
            [128.55147, 35.96133],
            [128.55166, 35.96125],
            [128.55341, 35.96074],
            [128.55369, 35.9619],
            [128.55409, 35.96333],
            [128.55427, 35.96362],
            [128.5545, 35.96418],
            [128.55498, 35.9649],
            [128.55541, 35.96528],
            [128.55558, 35.96549],
            [128.5557, 35.96582],
            [128.55601, 35.96646],
            [128.55618, 35.96692],
            [128.55621, 35.9674],
            [128.5559, 35.96841],
            [128.55595, 35.96884],
            [128.55612, 35.9691],
            [128.5562, 35.96977],
            [128.55665, 35.96976],
            [128.5566, 35.97004],
            [128.55654, 35.97027],
            [128.55646, 35.97046],
            [128.55641, 35.97076],
            [128.55632, 35.97107],
            [128.5563, 35.97139],
            [128.55639, 35.97176],
            [128.55707, 35.97223],
            [128.55851, 35.97275],
            [128.55961, 35.97274],
            [128.56029, 35.97287],
            [128.56095, 35.97241],
            [128.56157, 35.97202],
            [128.56218, 35.9724],
            [128.56324, 35.97259],
            [128.5643, 35.9725],
            [128.56524, 35.97287],
            [128.56615, 35.9736],
            [128.56613, 35.97367],
            [128.56619, 35.97371],
            [128.56603, 35.97455],
            [128.56681, 35.97499],
            [128.56779, 35.97549],
            [128.56946, 35.97591],
            [128.57031, 35.97651],
            [128.57063, 35.9767],
            [128.5708, 35.97666],
            [128.57111, 35.97686],
            [128.57301, 35.97771],
            [128.57413, 35.97727],
            [128.5751, 35.97725],
            [128.57622, 35.97758],
            [128.57718, 35.97783],
            [128.57939, 35.97787],
            [128.58172, 35.97738],
            [128.58272, 35.97768],
            [128.58321, 35.97718],
            [128.58403, 35.97734],
            [128.58441, 35.97748],
            [128.58478, 35.97744],
            [128.58681, 35.97857],
            [128.5872, 35.97877],
            [128.58785, 35.97861],
            [128.58922, 35.97881],
            [128.5902, 35.97887],
            [128.59077, 35.97908],
            [128.59104, 35.97986],
            [128.59196, 35.98025],
            [128.59231, 35.98029],
            [128.59304, 35.98057],
            [128.59348, 35.9813],
            [128.59338, 35.9829],
            [128.59495, 35.98362],
            [128.59592, 35.98409],
            [128.59716, 35.98421],
            [128.59763, 35.98442],
            [128.59862, 35.98481],
            [128.60044, 35.98508],
            [128.60261, 35.98583],
            [128.60289, 35.98591],
            [128.60297, 35.98645],
            [128.60371, 35.98768],
            [128.60468, 35.98761],
            [128.60499, 35.98757],
            [128.60628, 35.98706],
            [128.60797, 35.98609],
            [128.6086, 35.98579],
            [128.60893, 35.98545],
            [128.60944, 35.9853],
            [128.60989, 35.98497],
            [128.61048, 35.98436],
            [128.61204, 35.98328],
            [128.61295, 35.9825],
            [128.61352, 35.9817],
            [128.61353, 35.98141],
            [128.61388, 35.98073]
          ]
        ]
      }
    },
    {
      id: '27260',
      type: 'Feature',
      properties: {
        SEC_SGG_CD: '27260',
        SEC_SGG_NM: ['대구광역시', '수성구'],
        COL_SGG_CD: '27000',
        SEC_SGG_SIZE: '2',
        bbox: [128.59177, 35.79035, 128.72698, 35.87871],
        center: [128.66126, 35.83401],
        area: 76624049
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [128.65095, 35.87853],
            [128.65116, 35.87851],
            [128.65138, 35.87848],
            [128.65192, 35.87829],
            [128.6523, 35.87807],
            [128.65326, 35.87741],
            [128.65349, 35.87727],
            [128.65353, 35.87731],
            [128.65373, 35.87714],
            [128.65399, 35.87694],
            [128.65412, 35.8768],
            [128.65433, 35.87666],
            [128.65473, 35.87647],
            [128.65511, 35.87635],
            [128.65567, 35.87626],
            [128.65621, 35.87625],
            [128.65665, 35.8763],
            [128.65771, 35.8765],
            [128.65772, 35.87643],
            [128.65787, 35.87646],
            [128.65804, 35.8765],
            [128.65801, 35.87654],
            [128.65794, 35.87657],
            [128.65857, 35.87673],
            [128.65863, 35.87666],
            [128.65882, 35.87661],
            [128.65904, 35.87665],
            [128.65936, 35.8765],
            [128.6594, 35.87688],
            [128.65981, 35.87632],
            [128.66083, 35.87475],
            [128.66228, 35.87386],
            [128.66392, 35.87319],
            [128.66497, 35.87292],
            [128.66607, 35.87276],
            [128.66738, 35.87252],
            [128.66875, 35.8727],
            [128.67199, 35.87299],
            [128.67291, 35.87334],
            [128.67312, 35.87394],
            [128.67431, 35.87395],
            [128.67458, 35.8739],
            [128.67493, 35.87378],
            [128.67579, 35.87343],
            [128.67756, 35.87123],
            [128.67773, 35.87099],
            [128.67819, 35.87019],
            [128.67832, 35.86979],
            [128.67918, 35.86815],
            [128.67994, 35.86682],
            [128.67991, 35.86627],
            [128.68039, 35.86458],
            [128.68039, 35.86449],
            [128.68044, 35.86432],
            [128.68043, 35.86387],
            [128.68004, 35.86288],
            [128.68006, 35.86277],
            [128.68011, 35.86258],
            [128.68009, 35.86228],
            [128.68046, 35.86178],
            [128.68086, 35.86136],
            [128.68155, 35.86081],
            [128.68184, 35.86066],
            [128.68205, 35.86051],
            [128.68272, 35.86095],
            [128.68381, 35.86027],
            [128.68458, 35.85977],
            [128.68487, 35.85961],
            [128.68544, 35.8593],
            [128.6861, 35.85912],
            [128.68682, 35.85916],
            [128.68711, 35.8592],
            [128.68835, 35.85952],
            [128.68864, 35.8596],
            [128.68874, 35.85966],
            [128.6891, 35.85952],
            [128.68988, 35.85928],
            [128.69026, 35.8592],
            [128.69075, 35.8591],
            [128.69176, 35.85898],
            [128.69326, 35.85895],
            [128.69789, 35.8589],
            [128.69798, 35.85876],
            [128.69836, 35.85842],
            [128.69875, 35.85818],
            [128.6993, 35.85802],
            [128.69966, 35.85795],
            [128.70017, 35.85803],
            [128.70093, 35.85806],
            [128.70164, 35.85802],
            [128.70227, 35.85795],
            [128.70267, 35.85782],
            [128.70314, 35.85772],
            [128.7037, 35.85775],
            [128.70558, 35.85828],
            [128.70636, 35.85839],
            [128.70717, 35.85845],
            [128.70759, 35.85846],
            [128.70864, 35.85954],
            [128.71002, 35.86021],
            [128.71042, 35.8605],
            [128.71121, 35.86092],
            [128.7115, 35.86104],
            [128.71468, 35.86102],
            [128.71516, 35.86109],
            [128.71556, 35.86151],
            [128.71602, 35.86136],
            [128.71672, 35.86108],
            [128.71823, 35.86082],
            [128.71864, 35.86072],
            [128.72062, 35.86066],
            [128.72281, 35.8603],
            [128.72419, 35.8602],
            [128.72446, 35.85962],
            [128.7247, 35.85909],
            [128.72573, 35.85838],
            [128.72543, 35.85818],
            [128.72511, 35.85826],
            [128.7248, 35.85837],
            [128.72444, 35.85843],
            [128.72416, 35.85842],
            [128.72395, 35.8583],
            [128.72366, 35.8582],
            [128.72305, 35.85834],
            [128.72296, 35.85831],
            [128.72294, 35.85822],
            [128.7228, 35.85805],
            [128.72273, 35.85788],
            [128.72271, 35.85778],
            [128.72263, 35.8576],
            [128.72209, 35.85744],
            [128.72181, 35.85738],
            [128.72204, 35.85704],
            [128.72229, 35.8568],
            [128.72235, 35.85668],
            [128.72254, 35.85655],
            [128.72263, 35.85646],
            [128.72261, 35.85618],
            [128.72263, 35.85602],
            [128.72262, 35.85596],
            [128.72269, 35.85598],
            [128.72274, 35.85585],
            [128.72307, 35.85573],
            [128.72374, 35.85536],
            [128.72417, 35.85521],
            [128.72441, 35.85504],
            [128.72456, 35.85477],
            [128.72492, 35.85457],
            [128.72542, 35.85418],
            [128.72533, 35.85405],
            [128.72531, 35.85398],
            [128.72514, 35.85385],
            [128.7254, 35.85366],
            [128.72531, 35.85352],
            [128.72529, 35.85346],
            [128.7251, 35.85331],
            [128.72537, 35.85317],
            [128.72565, 35.85214],
            [128.72568, 35.8518],
            [128.72564, 35.85138],
            [128.72564, 35.85126],
            [128.72558, 35.85062],
            [128.726, 35.84961],
            [128.72651, 35.84853],
            [128.72689, 35.84762],
            [128.72698, 35.84691],
            [128.72689, 35.84665],
            [128.72692, 35.84639],
            [128.72659, 35.84533],
            [128.72674, 35.84473],
            [128.72669, 35.84431],
            [128.72645, 35.84412],
            [128.72626, 35.84392],
            [128.72632, 35.84343],
            [128.72636, 35.84326],
            [128.72637, 35.84296],
            [128.72627, 35.84284],
            [128.7263, 35.84272],
            [128.7263, 35.84242],
            [128.7256, 35.84164],
            [128.72563, 35.84156],
            [128.72554, 35.84145],
            [128.72547, 35.84141],
            [128.72541, 35.84134],
            [128.72531, 35.84116],
            [128.72502, 35.84074],
            [128.72491, 35.84065],
            [128.72458, 35.84053],
            [128.72449, 35.84046],
            [128.72435, 35.84046],
            [128.72436, 35.84036],
            [128.72431, 35.84014],
            [128.72433, 35.84008],
            [128.72427, 35.83996],
            [128.72428, 35.83983],
            [128.72425, 35.83964],
            [128.72422, 35.8396],
            [128.72423, 35.83953],
            [128.72422, 35.83947],
            [128.72426, 35.83911],
            [128.72415, 35.839],
            [128.72413, 35.83895],
            [128.72417, 35.8387],
            [128.72432, 35.83847],
            [128.72442, 35.83835],
            [128.72461, 35.83809],
            [128.72471, 35.83789],
            [128.72486, 35.83766],
            [128.72505, 35.83741],
            [128.72524, 35.83713],
            [128.72526, 35.83703],
            [128.72516, 35.83672],
            [128.72511, 35.83648],
            [128.72498, 35.83651],
            [128.72487, 35.83649],
            [128.72474, 35.8364],
            [128.72429, 35.83615],
            [128.72398, 35.83602],
            [128.72362, 35.83594],
            [128.72328, 35.83585],
            [128.72307, 35.83582],
            [128.72282, 35.83573],
            [128.72253, 35.83556],
            [128.72241, 35.83551],
            [128.72235, 35.8355],
            [128.7223, 35.83554],
            [128.72224, 35.83553],
            [128.72219, 35.8356],
            [128.72194, 35.83574],
            [128.72213, 35.83526],
            [128.72195, 35.83521],
            [128.72183, 35.83518],
            [128.72183, 35.83528],
            [128.72161, 35.83573],
            [128.72139, 35.83578],
            [128.72119, 35.83592],
            [128.72096, 35.83614],
            [128.72051, 35.83633],
            [128.72038, 35.83643],
            [128.72013, 35.83648],
            [128.71994, 35.83647],
            [128.7198, 35.83654],
            [128.71959, 35.83652],
            [128.7195, 35.83647],
            [128.71944, 35.83641],
            [128.71873, 35.83612],
            [128.71853, 35.83599],
            [128.71832, 35.83596],
            [128.71824, 35.83591],
            [128.71827, 35.83579],
            [128.7182, 35.83558],
            [128.71815, 35.83551],
            [128.7181, 35.83538],
            [128.71808, 35.83528],
            [128.7181, 35.8352],
            [128.71805, 35.83501],
            [128.7179, 35.8347],
            [128.71789, 35.83462],
            [128.71786, 35.83458],
            [128.71781, 35.83454],
            [128.71767, 35.83451],
            [128.71752, 35.83451],
            [128.71741, 35.83456],
            [128.7173, 35.83466],
            [128.71718, 35.83468],
            [128.71708, 35.83466],
            [128.717, 35.83461],
            [128.71701, 35.83446],
            [128.71695, 35.83447],
            [128.71697, 35.83435],
            [128.7163, 35.83395],
            [128.71613, 35.83383],
            [128.71554, 35.83363],
            [128.71465, 35.83328],
            [128.71418, 35.83222],
            [128.71322, 35.83154],
            [128.71275, 35.83118],
            [128.71228, 35.83027],
            [128.71201, 35.82998],
            [128.71159, 35.82974],
            [128.71119, 35.82942],
            [128.71066, 35.82885],
            [128.71031, 35.82838],
            [128.7103, 35.82826],
            [128.71012, 35.8283],
            [128.71002, 35.82827],
            [128.70989, 35.82827],
            [128.70932, 35.82766],
            [128.70899, 35.82737],
            [128.70871, 35.82701],
            [128.7087, 35.82682],
            [128.70879, 35.82681],
            [128.70875, 35.82591],
            [128.70874, 35.82501],
            [128.70873, 35.82473],
            [128.7086, 35.82371],
            [128.70868, 35.82323],
            [128.70902, 35.82246],
            [128.70908, 35.82192],
            [128.7096, 35.8214],
            [128.71013, 35.82118],
            [128.71216, 35.81964],
            [128.71257, 35.81914],
            [128.71287, 35.81892],
            [128.71385, 35.8179],
            [128.71389, 35.81783],
            [128.71433, 35.81593],
            [128.71499, 35.81524],
            [128.71498, 35.81472],
            [128.71488, 35.81444],
            [128.71486, 35.81418],
            [128.71483, 35.81409],
            [128.71504, 35.81329],
            [128.71538, 35.81269],
            [128.71563, 35.81195],
            [128.71587, 35.81161],
            [128.71583, 35.81069],
            [128.716, 35.80995],
            [128.71676, 35.80931],
            [128.7176, 35.80902],
            [128.71777, 35.80868],
            [128.71775, 35.80793],
            [128.71769, 35.80742],
            [128.71699, 35.80719],
            [128.7162, 35.80583],
            [128.71512, 35.80566],
            [128.71448, 35.80531],
            [128.71328, 35.80552],
            [128.71181, 35.80527],
            [128.71037, 35.80476],
            [128.70878, 35.80343],
            [128.70787, 35.80293],
            [128.70788, 35.80263],
            [128.70802, 35.802],
            [128.70801, 35.80162],
            [128.70806, 35.80113],
            [128.70804, 35.80068],
            [128.70732, 35.8003],
            [128.70622, 35.80035],
            [128.70567, 35.80019],
            [128.70539, 35.80015],
            [128.70403, 35.7997],
            [128.70321, 35.79925],
            [128.70255, 35.79882],
            [128.70179, 35.79788],
            [128.7012, 35.79751],
            [128.70053, 35.7972],
            [128.70044, 35.7971],
            [128.70038, 35.79618],
            [128.7004, 35.79587],
            [128.7003, 35.79546],
            [128.69911, 35.79522],
            [128.69874, 35.79508],
            [128.69835, 35.79504],
            [128.69766, 35.79477],
            [128.69757, 35.79462],
            [128.69658, 35.79486],
            [128.69568, 35.79542],
            [128.69545, 35.79586],
            [128.69503, 35.79629],
            [128.69466, 35.79711],
            [128.69399, 35.79708],
            [128.69344, 35.79711],
            [128.69294, 35.79729],
            [128.69263, 35.79667],
            [128.6919, 35.79624],
            [128.69101, 35.79583],
            [128.69112, 35.79487],
            [128.69085, 35.7944],
            [128.69043, 35.79356],
            [128.68984, 35.7931],
            [128.68909, 35.79288],
            [128.68803, 35.79262],
            [128.6873, 35.79261],
            [128.686, 35.79188],
            [128.68534, 35.79124],
            [128.6838, 35.79085],
            [128.68241, 35.79035],
            [128.6824, 35.79063],
            [128.68193, 35.79109],
            [128.6817, 35.79119],
            [128.68138, 35.79156],
            [128.68125, 35.79175],
            [128.68129, 35.79238],
            [128.68076, 35.79247],
            [128.68065, 35.79259],
            [128.68011, 35.79294],
            [128.67993, 35.79336],
            [128.67981, 35.79336],
            [128.67949, 35.79339],
            [128.679, 35.7934],
            [128.67855, 35.79326],
            [128.67794, 35.79329],
            [128.67769, 35.79355],
            [128.67728, 35.79389],
            [128.67695, 35.79415],
            [128.67673, 35.79441],
            [128.67651, 35.79448],
            [128.676, 35.79485],
            [128.67523, 35.79551],
            [128.67475, 35.79535],
            [128.67466, 35.79504],
            [128.67395, 35.79523],
            [128.67354, 35.79518],
            [128.67314, 35.79557],
            [128.67308, 35.7956],
            [128.67215, 35.79554],
            [128.67094, 35.79536],
            [128.67039, 35.79568],
            [128.66953, 35.79554],
            [128.66925, 35.79576],
            [128.66946, 35.79638],
            [128.66934, 35.79652],
            [128.66944, 35.79655],
            [128.66936, 35.79669],
            [128.66924, 35.79665],
            [128.66879, 35.79717],
            [128.66755, 35.79747],
            [128.66691, 35.7977],
            [128.66509, 35.79785],
            [128.66481, 35.79819],
            [128.6635, 35.79843],
            [128.66306, 35.79855],
            [128.66285, 35.79868],
            [128.66152, 35.79879],
            [128.66089, 35.79881],
            [128.66036, 35.7987],
            [128.65943, 35.79825],
            [128.65906, 35.79865],
            [128.6579, 35.79842],
            [128.65727, 35.79857],
            [128.657, 35.79885],
            [128.65648, 35.79915],
            [128.65493, 35.79953],
            [128.65381, 35.79951],
            [128.65283, 35.80007],
            [128.65265, 35.80041],
            [128.65221, 35.80085],
            [128.6521, 35.80134],
            [128.65159, 35.80307],
            [128.64984, 35.80317],
            [128.64948, 35.80351],
            [128.64922, 35.80339],
            [128.64857, 35.80336],
            [128.64744, 35.80282],
            [128.64693, 35.8027],
            [128.64502, 35.80244],
            [128.64383, 35.80261],
            [128.64301, 35.80232],
            [128.64122, 35.80248],
            [128.64059, 35.80303],
            [128.63934, 35.80326],
            [128.63801, 35.80371],
            [128.637, 35.80339],
            [128.63618, 35.80303],
            [128.63559, 35.80346],
            [128.63482, 35.80386],
            [128.63361, 35.8038],
            [128.63266, 35.80365],
            [128.63195, 35.8036],
            [128.63052, 35.80302],
            [128.6295, 35.80276],
            [128.62945, 35.80269],
            [128.62912, 35.80297],
            [128.62811, 35.80392],
            [128.62778, 35.8043],
            [128.62738, 35.80456],
            [128.62703, 35.80495],
            [128.62663, 35.80517],
            [128.62609, 35.80535],
            [128.62338, 35.80598],
            [128.62363, 35.80576],
            [128.62387, 35.80559],
            [128.62365, 35.80562],
            [128.62354, 35.8056],
            [128.62323, 35.80565],
            [128.62315, 35.80553],
            [128.62225, 35.80561],
            [128.6208, 35.80609],
            [128.62021, 35.80594],
            [128.6204, 35.80572],
            [128.61987, 35.80519],
            [128.61882, 35.80412],
            [128.61723, 35.80284],
            [128.61687, 35.80353],
            [128.61669, 35.80367],
            [128.6158, 35.80387],
            [128.61558, 35.80388],
            [128.61504, 35.80405],
            [128.61394, 35.80446],
            [128.61316, 35.80443],
            [128.61283, 35.80456],
            [128.61154, 35.80463],
            [128.61093, 35.80446],
            [128.61057, 35.80463],
            [128.6102, 35.8047],
            [128.60959, 35.80468],
            [128.60911, 35.80476],
            [128.60802, 35.80507],
            [128.6076, 35.80515],
            [128.60731, 35.80517],
            [128.60582, 35.80539],
            [128.60549, 35.80541],
            [128.605, 35.80573],
            [128.60379, 35.80606],
            [128.6027, 35.80632],
            [128.60178, 35.80623],
            [128.60092, 35.80609],
            [128.60062, 35.80611],
            [128.60012, 35.80594],
            [128.5993, 35.80575],
            [128.59873, 35.8057],
            [128.59785, 35.80599],
            [128.59742, 35.80673],
            [128.59665, 35.80696],
            [128.59612, 35.80721],
            [128.59515, 35.80739],
            [128.59488, 35.80751],
            [128.59427, 35.80791],
            [128.59394, 35.80826],
            [128.59313, 35.80874],
            [128.59264, 35.80899],
            [128.59177, 35.80925],
            [128.5922, 35.80962],
            [128.59244, 35.80961],
            [128.59223, 35.81034],
            [128.59259, 35.81124],
            [128.59376, 35.81218],
            [128.59407, 35.81255],
            [128.59456, 35.81308],
            [128.59485, 35.81316],
            [128.59676, 35.81386],
            [128.59842, 35.8143],
            [128.59887, 35.81493],
            [128.59996, 35.81483],
            [128.60079, 35.81569],
            [128.60171, 35.81799],
            [128.60193, 35.81855],
            [128.6036, 35.82123],
            [128.60426, 35.8224],
            [128.60417, 35.82251],
            [128.6043, 35.8235],
            [128.60483, 35.82418],
            [128.60482, 35.82467],
            [128.60465, 35.82512],
            [128.60472, 35.82535],
            [128.60501, 35.82668],
            [128.60547, 35.82665],
            [128.60551, 35.82679],
            [128.6056, 35.82678],
            [128.60567, 35.82718],
            [128.6062, 35.82714],
            [128.60631, 35.82722],
            [128.60637, 35.8276],
            [128.60702, 35.82719],
            [128.60784, 35.82714],
            [128.60775, 35.82746],
            [128.60777, 35.82802],
            [128.60782, 35.8286],
            [128.60743, 35.82949],
            [128.60705, 35.8303],
            [128.60639, 35.83212],
            [128.6063, 35.83252],
            [128.60629, 35.8329],
            [128.60634, 35.83314],
            [128.60631, 35.83353],
            [128.6061, 35.83429],
            [128.60595, 35.83491],
            [128.60586, 35.83539],
            [128.60613, 35.83642],
            [128.60648, 35.83821],
            [128.60654, 35.83892],
            [128.6066, 35.83914],
            [128.60701, 35.83915],
            [128.60711, 35.83916],
            [128.60725, 35.83933],
            [128.60738, 35.83957],
            [128.60751, 35.84006],
            [128.60766, 35.8415],
            [128.60763, 35.84195],
            [128.60763, 35.84257],
            [128.60772, 35.84305],
            [128.60792, 35.8437],
            [128.60817, 35.84478],
            [128.60784, 35.84647],
            [128.60787, 35.84717],
            [128.60799, 35.84787],
            [128.60831, 35.84906],
            [128.60834, 35.84985],
            [128.60849, 35.85079],
            [128.60852, 35.85113],
            [128.60886, 35.85245],
            [128.60888, 35.85332],
            [128.60887, 35.85365],
            [128.60873, 35.8543],
            [128.60827, 35.85506],
            [128.60815, 35.85567],
            [128.60801, 35.85747],
            [128.608, 35.85834],
            [128.60815, 35.85949],
            [128.60823, 35.85984],
            [128.60867, 35.86084],
            [128.60936, 35.86072],
            [128.60938, 35.86124],
            [128.60933, 35.86148],
            [128.6094, 35.86149],
            [128.60939, 35.86164],
            [128.60955, 35.86189],
            [128.60966, 35.86213],
            [128.60983, 35.86229],
            [128.60998, 35.86254],
            [128.61015, 35.86272],
            [128.61032, 35.86277],
            [128.61054, 35.86291],
            [128.61123, 35.8632],
            [128.61191, 35.86354],
            [128.61237, 35.86375],
            [128.61267, 35.86391],
            [128.61288, 35.86413],
            [128.61327, 35.86475],
            [128.61333, 35.86491],
            [128.6135, 35.86516],
            [128.6139, 35.86595],
            [128.6142, 35.86648],
            [128.61421, 35.86679],
            [128.61405, 35.86746],
            [128.61415, 35.86776],
            [128.61464, 35.86822],
            [128.62191, 35.86754],
            [128.62269, 35.86748],
            [128.62309, 35.86742],
            [128.62382, 35.86735],
            [128.62971, 35.86678],
            [128.63058, 35.86772],
            [128.63362, 35.87116],
            [128.63373, 35.87128],
            [128.63386, 35.87153],
            [128.63408, 35.87178],
            [128.63452, 35.87199],
            [128.63892, 35.87325],
            [128.64457, 35.87661],
            [128.64486, 35.87677],
            [128.64499, 35.8766],
            [128.64704, 35.87737],
            [128.64959, 35.87832],
            [128.64945, 35.87858],
            [128.64983, 35.87866],
            [128.64998, 35.87871],
            [128.65037, 35.8787],
            [128.65081, 35.87866],
            [128.65095, 35.87853]
          ]
        ]
      }
    },
    {
      id: '27290',
      type: 'Feature',
      properties: {
        SEC_SGG_CD: '27290',
        SEC_SGG_NM: ['대구광역시', '달서구'],
        COL_SGG_CD: '27000',
        SEC_SGG_SIZE: '2',
        bbox: [128.46827, 35.77286, 128.58795, 35.86914],
        center: [128.52942, 35.82758],
        area: 62043448
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [128.52048, 35.86762],
            [128.52064, 35.8674],
            [128.52221, 35.86722],
            [128.52302, 35.86733],
            [128.52328, 35.8673],
            [128.5247, 35.86704],
            [128.52488, 35.867],
            [128.52488, 35.86691],
            [128.52492, 35.86679],
            [128.52521, 35.86605],
            [128.52553, 35.86575],
            [128.52633, 35.86544],
            [128.52666, 35.86525],
            [128.52711, 35.86504],
            [128.52744, 35.86478],
            [128.52778, 35.86445],
            [128.52698, 35.8638],
            [128.52671, 35.8635],
            [128.52653, 35.8631],
            [128.52656, 35.86299],
            [128.52661, 35.8629],
            [128.52673, 35.86277],
            [128.5268, 35.86272],
            [128.52691, 35.86262],
            [128.52697, 35.86253],
            [128.52705, 35.86247],
            [128.52708, 35.86237],
            [128.52716, 35.8622],
            [128.52726, 35.86212],
            [128.52739, 35.8617],
            [128.52764, 35.86154],
            [128.53139, 35.86147],
            [128.53234, 35.8615],
            [128.53291, 35.86149],
            [128.53378, 35.86189],
            [128.53319, 35.85881],
            [128.53319, 35.85871],
            [128.53372, 35.85869],
            [128.53988, 35.85871],
            [128.53996, 35.85839],
            [128.5402, 35.85805],
            [128.54038, 35.85785],
            [128.54048, 35.85767],
            [128.54049, 35.85753],
            [128.54045, 35.85735],
            [128.54023, 35.85699],
            [128.54016, 35.85674],
            [128.54014, 35.85666],
            [128.54016, 35.85636],
            [128.54014, 35.85626],
            [128.54064, 35.85632],
            [128.54109, 35.8563],
            [128.54194, 35.85629],
            [128.54208, 35.85627],
            [128.54207, 35.85602],
            [128.54349, 35.8563],
            [128.54367, 35.85573],
            [128.5474, 35.85647],
            [128.54803, 35.85432],
            [128.54815, 35.85436],
            [128.54817, 35.85428],
            [128.5566, 35.85756],
            [128.55855, 35.85823],
            [128.56681, 35.86076],
            [128.56684, 35.86069],
            [128.56835, 35.86112],
            [128.56849, 35.86112],
            [128.56907, 35.86133],
            [128.56944, 35.86139],
            [128.57039, 35.86167],
            [128.57112, 35.86181],
            [128.57128, 35.86189],
            [128.57143, 35.86204],
            [128.57191, 35.86211],
            [128.57408, 35.86263],
            [128.57448, 35.85992],
            [128.57408, 35.85577],
            [128.57372, 35.85232],
            [128.57365, 35.85175],
            [128.57363, 35.85166],
            [128.57358, 35.85154],
            [128.57355, 35.85121],
            [128.57362, 35.85114],
            [128.57194, 35.85001],
            [128.57188, 35.85008],
            [128.56511, 35.84593],
            [128.56211, 35.84355],
            [128.56199, 35.84344],
            [128.56187, 35.84328],
            [128.55755, 35.83768],
            [128.55783, 35.83774],
            [128.55733, 35.83737],
            [128.557, 35.83705],
            [128.55681, 35.83684],
            [128.55655, 35.83641],
            [128.55634, 35.83614],
            [128.55624, 35.83594],
            [128.55602, 35.83559],
            [128.55562, 35.83473],
            [128.55554, 35.83445],
            [128.55569, 35.83437],
            [128.55592, 35.8341],
            [128.55633, 35.83402],
            [128.55661, 35.83394],
            [128.55709, 35.83357],
            [128.55778, 35.83317],
            [128.5582, 35.8329],
            [128.55842, 35.83248],
            [128.5583, 35.83227],
            [128.55849, 35.83203],
            [128.55836, 35.83204],
            [128.55848, 35.8318],
            [128.5588, 35.83166],
            [128.55889, 35.8316],
            [128.56017, 35.83039],
            [128.56036, 35.82989],
            [128.56039, 35.82965],
            [128.56056, 35.82961],
            [128.5605, 35.82944],
            [128.56051, 35.82939],
            [128.56058, 35.82925],
            [128.56096, 35.82886],
            [128.56096, 35.82874],
            [128.56112, 35.82837],
            [128.56106, 35.82836],
            [128.56123, 35.82807],
            [128.56245, 35.82558],
            [128.56254, 35.82559],
            [128.56262, 35.82541],
            [128.56328, 35.8245],
            [128.56352, 35.82427],
            [128.56614, 35.82392],
            [128.56739, 35.82356],
            [128.56862, 35.82492],
            [128.56937, 35.82428],
            [128.57006, 35.82315],
            [128.57052, 35.82281],
            [128.5708, 35.82226],
            [128.57108, 35.82203],
            [128.57113, 35.82066],
            [128.57075, 35.81941],
            [128.57116, 35.81897],
            [128.57117, 35.81891],
            [128.57218, 35.8188],
            [128.57272, 35.8185],
            [128.57505, 35.81727],
            [128.57675, 35.81514],
            [128.57762, 35.81398],
            [128.57884, 35.81333],
            [128.5795, 35.81215],
            [128.58004, 35.81187],
            [128.58108, 35.81184],
            [128.58174, 35.81206],
            [128.58275, 35.81211],
            [128.58343, 35.81096],
            [128.58435, 35.80924],
            [128.58473, 35.80932],
            [128.58474, 35.80884],
            [128.58482, 35.80747],
            [128.58518, 35.80731],
            [128.58795, 35.80769],
            [128.58778, 35.80686],
            [128.58707, 35.80546],
            [128.58682, 35.80515],
            [128.58645, 35.80462],
            [128.58614, 35.80391],
            [128.58568, 35.80301],
            [128.58593, 35.80258],
            [128.58602, 35.80206],
            [128.58664, 35.80107],
            [128.58647, 35.80004],
            [128.58545, 35.79907],
            [128.58344, 35.79899],
            [128.58329, 35.79891],
            [128.58317, 35.79881],
            [128.58267, 35.79871],
            [128.58235, 35.79873],
            [128.58209, 35.7987],
            [128.58177, 35.79855],
            [128.58117, 35.79824],
            [128.58113, 35.79815],
            [128.58051, 35.79745],
            [128.58009, 35.79744],
            [128.57948, 35.79667],
            [128.57877, 35.79628],
            [128.57735, 35.79654],
            [128.57692, 35.79613],
            [128.57681, 35.7961],
            [128.57668, 35.79602],
            [128.57563, 35.79625],
            [128.57518, 35.79612],
            [128.57432, 35.79638],
            [128.57396, 35.79661],
            [128.57377, 35.79658],
            [128.57342, 35.7966],
            [128.57244, 35.79694],
            [128.57148, 35.79708],
            [128.56958, 35.79746],
            [128.56895, 35.79655],
            [128.56837, 35.79619],
            [128.56814, 35.79566],
            [128.56804, 35.79556],
            [128.56775, 35.79536],
            [128.56746, 35.79536],
            [128.56719, 35.79508],
            [128.56748, 35.79483],
            [128.56802, 35.79379],
            [128.56877, 35.7929],
            [128.5685, 35.79221],
            [128.56878, 35.7916],
            [128.56879, 35.79108],
            [128.56885, 35.79062],
            [128.56898, 35.7902],
            [128.56937, 35.79006],
            [128.56957, 35.78975],
            [128.5693, 35.78921],
            [128.56923, 35.78845],
            [128.56922, 35.78783],
            [128.5695, 35.78727],
            [128.56959, 35.78641],
            [128.56992, 35.78572],
            [128.57018, 35.78539],
            [128.56986, 35.78471],
            [128.56981, 35.78423],
            [128.5695, 35.78338],
            [128.56955, 35.78307],
            [128.56953, 35.78295],
            [128.56974, 35.78275],
            [128.56991, 35.78222],
            [128.57007, 35.78203],
            [128.5705, 35.78133],
            [128.5705, 35.78099],
            [128.57045, 35.7804],
            [128.57058, 35.77997],
            [128.56957, 35.77941],
            [128.56793, 35.77819],
            [128.56767, 35.77765],
            [128.56733, 35.77651],
            [128.56596, 35.77518],
            [128.56557, 35.77462],
            [128.56539, 35.7745],
            [128.56404, 35.77391],
            [128.5629, 35.77432],
            [128.56225, 35.77436],
            [128.56136, 35.77409],
            [128.55991, 35.77405],
            [128.55885, 35.77372],
            [128.55766, 35.77305],
            [128.5565, 35.773],
            [128.55517, 35.77302],
            [128.55436, 35.77286],
            [128.55474, 35.77343],
            [128.55426, 35.7746],
            [128.55387, 35.77526],
            [128.55405, 35.77579],
            [128.55376, 35.7771],
            [128.5537, 35.77754],
            [128.553, 35.77817],
            [128.55237, 35.77931],
            [128.55046, 35.77972],
            [128.54923, 35.78034],
            [128.54883, 35.78077],
            [128.54853, 35.78122],
            [128.5478, 35.78251],
            [128.54709, 35.78317],
            [128.54682, 35.78366],
            [128.5467, 35.78398],
            [128.54664, 35.78432],
            [128.54628, 35.78522],
            [128.54629, 35.78576],
            [128.54567, 35.78629],
            [128.54482, 35.78638],
            [128.54419, 35.78676],
            [128.54366, 35.78679],
            [128.54225, 35.78756],
            [128.54174, 35.78813],
            [128.54165, 35.78981],
            [128.54046, 35.78824],
            [128.53902, 35.78779],
            [128.53865, 35.7872],
            [128.53842, 35.78699],
            [128.53827, 35.7869],
            [128.53739, 35.78671],
            [128.53636, 35.78668],
            [128.53554, 35.78678],
            [128.53463, 35.78682],
            [128.53427, 35.78673],
            [128.53347, 35.78659],
            [128.53203, 35.78733],
            [128.53097, 35.78731],
            [128.53005, 35.78776],
            [128.52909, 35.78808],
            [128.52863, 35.78989],
            [128.52839, 35.79043],
            [128.52809, 35.79131],
            [128.52798, 35.79174],
            [128.52786, 35.79257],
            [128.52773, 35.79268],
            [128.5276, 35.79286],
            [128.52627, 35.79287],
            [128.52604, 35.79279],
            [128.52593, 35.79279],
            [128.52554, 35.79267],
            [128.52503, 35.79246],
            [128.52456, 35.79251],
            [128.52345, 35.7932],
            [128.5231, 35.79371],
            [128.52257, 35.79495],
            [128.52115, 35.79601],
            [128.52081, 35.7959],
            [128.51901, 35.79709],
            [128.51888, 35.79717],
            [128.51841, 35.79759],
            [128.51761, 35.79833],
            [128.51686, 35.7993],
            [128.51643, 35.80047],
            [128.51628, 35.80076],
            [128.51566, 35.80166],
            [128.51563, 35.80212],
            [128.51568, 35.80303],
            [128.51562, 35.80364],
            [128.51516, 35.80362],
            [128.51514, 35.80354],
            [128.51508, 35.80353],
            [128.51472, 35.80352],
            [128.51446, 35.80356],
            [128.51432, 35.80367],
            [128.5143, 35.80382],
            [128.51434, 35.80389],
            [128.51423, 35.80391],
            [128.51427, 35.8041],
            [128.51433, 35.80424],
            [128.51438, 35.80432],
            [128.51444, 35.80458],
            [128.51446, 35.80476],
            [128.51443, 35.80484],
            [128.51471, 35.80562],
            [128.51471, 35.80568],
            [128.51377, 35.80564],
            [128.5129, 35.80552],
            [128.51208, 35.80519],
            [128.5116, 35.80545],
            [128.51147, 35.8053],
            [128.51111, 35.80546],
            [128.51054, 35.80592],
            [128.51009, 35.80584],
            [128.50966, 35.80573],
            [128.5086, 35.8056],
            [128.50808, 35.80606],
            [128.5083, 35.80658],
            [128.50847, 35.80679],
            [128.50873, 35.80746],
            [128.50869, 35.80762],
            [128.50861, 35.80771],
            [128.50876, 35.80783],
            [128.50915, 35.80797],
            [128.51002, 35.80818],
            [128.51084, 35.80852],
            [128.51147, 35.80873],
            [128.51217, 35.80918],
            [128.51305, 35.80956],
            [128.51316, 35.80963],
            [128.51355, 35.80978],
            [128.51347, 35.80988],
            [128.51326, 35.8102],
            [128.5128, 35.81099],
            [128.51268, 35.81103],
            [128.51233, 35.81114],
            [128.51148, 35.81166],
            [128.51111, 35.81184],
            [128.51025, 35.8122],
            [128.50973, 35.81234],
            [128.50959, 35.81244],
            [128.50877, 35.81257],
            [128.50821, 35.81303],
            [128.50721, 35.81345],
            [128.50705, 35.81355],
            [128.507, 35.81379],
            [128.50683, 35.81432],
            [128.50672, 35.81453],
            [128.50666, 35.81457],
            [128.50662, 35.81465],
            [128.5053, 35.81537],
            [128.50476, 35.81569],
            [128.50243, 35.817],
            [128.50181, 35.8173],
            [128.50074, 35.81775],
            [128.50012, 35.81797],
            [128.49851, 35.81836],
            [128.49691, 35.81864],
            [128.49572, 35.81892],
            [128.49474, 35.81911],
            [128.49284, 35.81943],
            [128.49273, 35.81946],
            [128.49154, 35.81967],
            [128.49039, 35.81991],
            [128.49033, 35.81994],
            [128.49015, 35.82008],
            [128.49013, 35.82019],
            [128.48963, 35.81988],
            [128.48925, 35.81971],
            [128.48886, 35.81944],
            [128.48854, 35.81917],
            [128.48823, 35.81895],
            [128.48791, 35.81865],
            [128.48774, 35.81851],
            [128.4875, 35.81833],
            [128.48718, 35.81814],
            [128.48708, 35.81809],
            [128.48696, 35.81809],
            [128.48683, 35.81813],
            [128.48663, 35.81808],
            [128.48639, 35.818],
            [128.48623, 35.81799],
            [128.48608, 35.81794],
            [128.48584, 35.81791],
            [128.48518, 35.81777],
            [128.48483, 35.81763],
            [128.48459, 35.81748],
            [128.48443, 35.81736],
            [128.48432, 35.81724],
            [128.48417, 35.81701],
            [128.48407, 35.81679],
            [128.48394, 35.81639],
            [128.48388, 35.81633],
            [128.48403, 35.81626],
            [128.484, 35.81611],
            [128.48387, 35.81598],
            [128.48344, 35.81565],
            [128.48297, 35.81536],
            [128.48283, 35.81525],
            [128.4829, 35.81549],
            [128.48311, 35.81603],
            [128.48379, 35.81699],
            [128.48406, 35.81841],
            [128.48467, 35.81886],
            [128.48486, 35.81949],
            [128.4849, 35.81977],
            [128.48498, 35.82002],
            [128.4849, 35.82013],
            [128.48513, 35.82073],
            [128.48524, 35.82091],
            [128.48527, 35.82095],
            [128.48559, 35.82121],
            [128.48584, 35.82195],
            [128.48592, 35.8223],
            [128.48602, 35.82263],
            [128.48611, 35.82296],
            [128.48626, 35.82331],
            [128.48641, 35.82387],
            [128.48644, 35.82417],
            [128.48648, 35.82433],
            [128.48646, 35.82445],
            [128.48647, 35.82451],
            [128.48646, 35.82458],
            [128.48649, 35.82516],
            [128.48646, 35.82614],
            [128.48634, 35.82675],
            [128.48602, 35.82756],
            [128.48568, 35.82817],
            [128.48559, 35.82837],
            [128.48532, 35.82883],
            [128.48512, 35.82912],
            [128.48512, 35.82917],
            [128.48506, 35.82923],
            [128.48502, 35.82934],
            [128.48493, 35.82944],
            [128.48458, 35.83012],
            [128.48426, 35.8306],
            [128.48421, 35.8307],
            [128.48411, 35.83083],
            [128.48366, 35.83131],
            [128.48208, 35.83042],
            [128.48119, 35.82993],
            [128.47904, 35.82873],
            [128.47883, 35.82896],
            [128.47848, 35.82992],
            [128.47777, 35.83069],
            [128.47636, 35.83179],
            [128.46933, 35.83638],
            [128.46827, 35.83701],
            [128.46842, 35.83752],
            [128.46884, 35.8387],
            [128.46955, 35.84133],
            [128.4699, 35.84253],
            [128.47076, 35.84525],
            [128.4709, 35.8462],
            [128.47102, 35.84668],
            [128.47129, 35.84852],
            [128.47132, 35.84896],
            [128.47147, 35.85037],
            [128.47171, 35.85143],
            [128.47185, 35.85274],
            [128.47209, 35.85378],
            [128.47208, 35.85391],
            [128.47238, 35.85385],
            [128.47245, 35.85411],
            [128.47242, 35.85507],
            [128.47253, 35.85541],
            [128.47302, 35.85677],
            [128.47313, 35.85764],
            [128.47343, 35.85835],
            [128.47347, 35.8595],
            [128.47376, 35.8595],
            [128.47375, 35.85799],
            [128.47322, 35.85593],
            [128.47291, 35.85478],
            [128.47278, 35.85392],
            [128.47315, 35.85429],
            [128.47334, 35.85458],
            [128.4736, 35.85498],
            [128.47387, 35.85559],
            [128.47368, 35.85593],
            [128.47402, 35.85687],
            [128.47437, 35.85688],
            [128.47396, 35.85791],
            [128.47579, 35.85935],
            [128.47585, 35.85972],
            [128.47651, 35.86026],
            [128.47697, 35.86162],
            [128.47715, 35.86206],
            [128.47777, 35.86257],
            [128.47832, 35.86368],
            [128.47914, 35.8648],
            [128.47953, 35.8656],
            [128.47989, 35.86621],
            [128.48093, 35.86719],
            [128.48149, 35.86752],
            [128.482, 35.86792],
            [128.48293, 35.86753],
            [128.4837, 35.86762],
            [128.48428, 35.86771],
            [128.48459, 35.86786],
            [128.4849, 35.86782],
            [128.4859, 35.86792],
            [128.48663, 35.86792],
            [128.48719, 35.86772],
            [128.48762, 35.86748],
            [128.48801, 35.86716],
            [128.48916, 35.8664],
            [128.48967, 35.86634],
            [128.49083, 35.86574],
            [128.49092, 35.86572],
            [128.49207, 35.86524],
            [128.49328, 35.86447],
            [128.49408, 35.86457],
            [128.49477, 35.86446],
            [128.49585, 35.8646],
            [128.49664, 35.86412],
            [128.49823, 35.86394],
            [128.4998, 35.86354],
            [128.50047, 35.8633],
            [128.50097, 35.86327],
            [128.50109, 35.86336],
            [128.50153, 35.86336],
            [128.50233, 35.86401],
            [128.50259, 35.86437],
            [128.50258, 35.86477],
            [128.50338, 35.86523],
            [128.50391, 35.86557],
            [128.50478, 35.86555],
            [128.50519, 35.8656],
            [128.50533, 35.86559],
            [128.50662, 35.86573],
            [128.50681, 35.86587],
            [128.50757, 35.86577],
            [128.50769, 35.86585],
            [128.50811, 35.86579],
            [128.50857, 35.86614],
            [128.50912, 35.86703],
            [128.50953, 35.86771],
            [128.50978, 35.86912],
            [128.51052, 35.86883],
            [128.51142, 35.86826],
            [128.51257, 35.86788],
            [128.51338, 35.86792],
            [128.51414, 35.86771],
            [128.51508, 35.86771],
            [128.51551, 35.86759],
            [128.51568, 35.86765],
            [128.51584, 35.86768],
            [128.51597, 35.86765],
            [128.51669, 35.86774],
            [128.51779, 35.86813],
            [128.51887, 35.86859],
            [128.51922, 35.86908],
            [128.51929, 35.86914],
            [128.51943, 35.86895],
            [128.51962, 35.86877],
            [128.52018, 35.86806],
            [128.52048, 35.86762]
          ]
        ]
      }
    },
    {
      id: '27710',
      type: 'Feature',
      properties: {
        SEC_SGG_CD: '27710',
        SEC_SGG_NM: ['대구광역시', '달성군'],
        COL_SGG_CD: '27000',
        SEC_SGG_SIZE: '2',
        bbox: [128.35006, 35.60723, 128.69567, 35.94338],
        center: [128.49803, 35.7666],
        area: 422033335
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [128.48366, 35.83131],
              [128.48411, 35.83083],
              [128.48458, 35.83012],
              [128.48493, 35.82944],
              [128.48502, 35.82934],
              [128.48506, 35.82923],
              [128.48512, 35.82917],
              [128.48512, 35.82912],
              [128.48532, 35.82883],
              [128.48559, 35.82837],
              [128.48568, 35.82817],
              [128.48602, 35.82756],
              [128.48624, 35.82704],
              [128.48634, 35.82675],
              [128.48646, 35.82614],
              [128.48649, 35.82516],
              [128.48646, 35.82458],
              [128.48647, 35.82451],
              [128.48646, 35.82445],
              [128.48648, 35.82433],
              [128.48644, 35.82417],
              [128.48641, 35.82387],
              [128.48626, 35.82331],
              [128.48611, 35.82296],
              [128.48602, 35.82263],
              [128.48592, 35.8223],
              [128.48584, 35.82195],
              [128.48559, 35.82121],
              [128.48527, 35.82095],
              [128.48524, 35.82091],
              [128.48513, 35.82073],
              [128.4849, 35.82013],
              [128.48498, 35.82002],
              [128.4849, 35.81977],
              [128.48486, 35.81949],
              [128.48467, 35.81886],
              [128.48406, 35.81841],
              [128.48379, 35.81699],
              [128.48311, 35.81603],
              [128.4829, 35.81549],
              [128.48283, 35.81525],
              [128.48297, 35.81536],
              [128.48344, 35.81565],
              [128.48387, 35.81598],
              [128.484, 35.81611],
              [128.48403, 35.81626],
              [128.48388, 35.81633],
              [128.48394, 35.81639],
              [128.48407, 35.81679],
              [128.48417, 35.81701],
              [128.48432, 35.81724],
              [128.48443, 35.81736],
              [128.48459, 35.81748],
              [128.48483, 35.81763],
              [128.48518, 35.81777],
              [128.48584, 35.81791],
              [128.48608, 35.81794],
              [128.48623, 35.81799],
              [128.48639, 35.818],
              [128.48663, 35.81808],
              [128.48683, 35.81813],
              [128.48696, 35.81809],
              [128.48708, 35.81809],
              [128.48718, 35.81814],
              [128.4875, 35.81833],
              [128.48774, 35.81851],
              [128.48791, 35.81865],
              [128.48823, 35.81895],
              [128.48854, 35.81917],
              [128.48886, 35.81944],
              [128.48925, 35.81971],
              [128.48963, 35.81988],
              [128.49013, 35.82019],
              [128.49015, 35.82008],
              [128.49033, 35.81994],
              [128.49039, 35.81991],
              [128.49154, 35.81967],
              [128.49273, 35.81946],
              [128.49284, 35.81943],
              [128.49474, 35.81911],
              [128.49572, 35.81892],
              [128.49691, 35.81864],
              [128.49851, 35.81836],
              [128.50012, 35.81797],
              [128.50074, 35.81775],
              [128.50181, 35.8173],
              [128.50243, 35.817],
              [128.50476, 35.81569],
              [128.5053, 35.81537],
              [128.50662, 35.81465],
              [128.50666, 35.81457],
              [128.50672, 35.81453],
              [128.50683, 35.81432],
              [128.507, 35.81379],
              [128.50705, 35.81355],
              [128.50721, 35.81345],
              [128.50821, 35.81303],
              [128.50877, 35.81257],
              [128.50959, 35.81244],
              [128.50973, 35.81234],
              [128.51025, 35.8122],
              [128.51111, 35.81184],
              [128.51148, 35.81166],
              [128.51233, 35.81114],
              [128.51268, 35.81103],
              [128.5128, 35.81099],
              [128.51326, 35.8102],
              [128.51347, 35.80988],
              [128.51355, 35.80978],
              [128.51316, 35.80963],
              [128.51305, 35.80956],
              [128.51217, 35.80918],
              [128.51147, 35.80873],
              [128.51084, 35.80852],
              [128.51002, 35.80818],
              [128.50915, 35.80797],
              [128.50876, 35.80783],
              [128.50861, 35.80771],
              [128.50869, 35.80762],
              [128.50871, 35.80741],
              [128.50847, 35.80679],
              [128.5083, 35.80658],
              [128.50808, 35.80606],
              [128.5086, 35.8056],
              [128.50966, 35.80573],
              [128.51009, 35.80584],
              [128.51054, 35.80592],
              [128.51111, 35.80546],
              [128.51147, 35.8053],
              [128.5116, 35.80545],
              [128.51208, 35.80519],
              [128.5129, 35.80552],
              [128.51377, 35.80564],
              [128.51471, 35.80568],
              [128.51471, 35.80562],
              [128.51443, 35.80484],
              [128.51446, 35.80476],
              [128.51444, 35.80458],
              [128.51438, 35.80432],
              [128.51433, 35.80424],
              [128.51427, 35.8041],
              [128.51423, 35.80391],
              [128.51434, 35.80389],
              [128.5143, 35.80382],
              [128.51432, 35.80367],
              [128.51446, 35.80356],
              [128.51472, 35.80352],
              [128.51508, 35.80353],
              [128.51514, 35.80354],
              [128.51516, 35.80362],
              [128.51562, 35.80364],
              [128.51568, 35.80303],
              [128.51563, 35.80212],
              [128.51566, 35.80166],
              [128.51628, 35.80076],
              [128.51643, 35.80047],
              [128.51686, 35.7993],
              [128.51761, 35.79833],
              [128.51841, 35.79759],
              [128.51888, 35.79717],
              [128.51901, 35.79709],
              [128.52081, 35.7959],
              [128.52115, 35.79601],
              [128.52257, 35.79495],
              [128.5231, 35.79371],
              [128.52345, 35.7932],
              [128.52456, 35.79251],
              [128.52503, 35.79246],
              [128.52554, 35.79267],
              [128.52593, 35.79279],
              [128.52604, 35.79279],
              [128.52627, 35.79287],
              [128.5276, 35.79286],
              [128.52773, 35.79268],
              [128.52786, 35.79257],
              [128.52798, 35.79174],
              [128.52809, 35.79131],
              [128.52839, 35.79043],
              [128.52863, 35.78989],
              [128.52909, 35.78808],
              [128.53005, 35.78776],
              [128.53097, 35.78731],
              [128.53203, 35.78733],
              [128.53347, 35.78659],
              [128.53427, 35.78673],
              [128.53463, 35.78682],
              [128.53554, 35.78678],
              [128.53636, 35.78668],
              [128.53739, 35.78671],
              [128.53827, 35.7869],
              [128.53842, 35.78699],
              [128.53865, 35.7872],
              [128.53902, 35.78779],
              [128.54046, 35.78824],
              [128.54165, 35.78981],
              [128.54174, 35.78813],
              [128.54225, 35.78756],
              [128.54366, 35.78679],
              [128.54419, 35.78676],
              [128.54482, 35.78638],
              [128.54567, 35.78629],
              [128.54629, 35.78576],
              [128.54628, 35.78522],
              [128.54664, 35.78432],
              [128.5467, 35.78398],
              [128.54682, 35.78366],
              [128.54709, 35.78317],
              [128.5478, 35.78251],
              [128.54853, 35.78122],
              [128.54883, 35.78077],
              [128.54923, 35.78034],
              [128.55046, 35.77972],
              [128.55237, 35.77931],
              [128.553, 35.77817],
              [128.5537, 35.77754],
              [128.55376, 35.7771],
              [128.55405, 35.77579],
              [128.55387, 35.77526],
              [128.55426, 35.7746],
              [128.55474, 35.77343],
              [128.55436, 35.77286],
              [128.55517, 35.77302],
              [128.5565, 35.773],
              [128.55766, 35.77305],
              [128.55885, 35.77372],
              [128.55991, 35.77405],
              [128.56136, 35.77409],
              [128.56225, 35.77436],
              [128.5629, 35.77432],
              [128.56404, 35.77391],
              [128.56539, 35.7745],
              [128.56557, 35.77462],
              [128.56596, 35.77518],
              [128.56733, 35.77651],
              [128.56767, 35.77765],
              [128.56793, 35.77819],
              [128.56957, 35.77941],
              [128.57058, 35.77997],
              [128.57045, 35.7804],
              [128.5705, 35.78099],
              [128.5705, 35.78133],
              [128.57007, 35.78203],
              [128.56991, 35.78222],
              [128.56974, 35.78275],
              [128.56953, 35.78295],
              [128.56955, 35.78307],
              [128.5695, 35.78338],
              [128.56981, 35.78423],
              [128.56986, 35.78471],
              [128.57018, 35.78539],
              [128.56992, 35.78572],
              [128.56959, 35.78641],
              [128.5695, 35.78727],
              [128.56922, 35.78783],
              [128.56923, 35.78845],
              [128.5693, 35.78921],
              [128.56957, 35.78975],
              [128.56937, 35.79006],
              [128.56898, 35.7902],
              [128.56885, 35.79062],
              [128.56879, 35.79108],
              [128.56878, 35.7916],
              [128.5685, 35.79221],
              [128.56877, 35.7929],
              [128.56802, 35.79379],
              [128.56748, 35.79483],
              [128.56719, 35.79508],
              [128.56746, 35.79536],
              [128.56775, 35.79536],
              [128.56804, 35.79556],
              [128.56814, 35.79566],
              [128.56837, 35.79619],
              [128.56895, 35.79655],
              [128.56958, 35.79746],
              [128.57148, 35.79708],
              [128.57244, 35.79694],
              [128.57342, 35.7966],
              [128.57377, 35.79658],
              [128.57396, 35.79661],
              [128.57432, 35.79638],
              [128.57518, 35.79612],
              [128.57563, 35.79625],
              [128.57668, 35.79602],
              [128.57681, 35.7961],
              [128.57692, 35.79613],
              [128.57735, 35.79654],
              [128.57877, 35.79628],
              [128.57948, 35.79667],
              [128.58009, 35.79744],
              [128.58051, 35.79745],
              [128.58113, 35.79815],
              [128.58117, 35.79824],
              [128.58177, 35.79855],
              [128.58209, 35.7987],
              [128.58235, 35.79873],
              [128.58267, 35.79871],
              [128.58317, 35.79881],
              [128.58329, 35.79891],
              [128.58344, 35.79899],
              [128.58545, 35.79907],
              [128.58647, 35.80004],
              [128.58664, 35.80107],
              [128.58602, 35.80206],
              [128.58593, 35.80258],
              [128.58568, 35.80301],
              [128.58614, 35.80391],
              [128.58645, 35.80462],
              [128.58682, 35.80515],
              [128.58707, 35.80546],
              [128.58778, 35.80686],
              [128.58795, 35.80769],
              [128.58953, 35.80794],
              [128.59015, 35.80818],
              [128.59047, 35.80852],
              [128.59101, 35.80902],
              [128.59121, 35.80894],
              [128.59177, 35.80925],
              [128.59264, 35.80899],
              [128.59313, 35.80874],
              [128.59394, 35.80826],
              [128.59427, 35.80791],
              [128.59488, 35.80751],
              [128.59515, 35.80739],
              [128.59612, 35.80721],
              [128.59665, 35.80696],
              [128.59742, 35.80673],
              [128.59785, 35.80599],
              [128.59873, 35.8057],
              [128.5993, 35.80575],
              [128.60012, 35.80594],
              [128.60062, 35.80611],
              [128.60092, 35.80609],
              [128.60178, 35.80623],
              [128.6027, 35.80632],
              [128.60379, 35.80606],
              [128.605, 35.80573],
              [128.60549, 35.80541],
              [128.60582, 35.80539],
              [128.60731, 35.80517],
              [128.6076, 35.80515],
              [128.60802, 35.80507],
              [128.60911, 35.80476],
              [128.60959, 35.80468],
              [128.6102, 35.8047],
              [128.61057, 35.80463],
              [128.61093, 35.80446],
              [128.61154, 35.80463],
              [128.61283, 35.80456],
              [128.61316, 35.80443],
              [128.61394, 35.80446],
              [128.61504, 35.80405],
              [128.61558, 35.80388],
              [128.6158, 35.80387],
              [128.61669, 35.80367],
              [128.61687, 35.80353],
              [128.61723, 35.80284],
              [128.61882, 35.80412],
              [128.61987, 35.80519],
              [128.6204, 35.80572],
              [128.62021, 35.80594],
              [128.6208, 35.80609],
              [128.62225, 35.80561],
              [128.62315, 35.80553],
              [128.62323, 35.80565],
              [128.62354, 35.8056],
              [128.62365, 35.80562],
              [128.62387, 35.80559],
              [128.62363, 35.80576],
              [128.62338, 35.80598],
              [128.62609, 35.80535],
              [128.62663, 35.80517],
              [128.62703, 35.80495],
              [128.62738, 35.80456],
              [128.62778, 35.8043],
              [128.62811, 35.80392],
              [128.62912, 35.80297],
              [128.62945, 35.80269],
              [128.6295, 35.80276],
              [128.63052, 35.80302],
              [128.63132, 35.80333],
              [128.63195, 35.8036],
              [128.63266, 35.80365],
              [128.63361, 35.8038],
              [128.63482, 35.80386],
              [128.63559, 35.80346],
              [128.63618, 35.80303],
              [128.637, 35.80339],
              [128.63801, 35.80371],
              [128.63934, 35.80326],
              [128.64059, 35.80303],
              [128.64122, 35.80248],
              [128.64301, 35.80232],
              [128.64383, 35.80261],
              [128.64502, 35.80244],
              [128.64693, 35.8027],
              [128.64744, 35.80282],
              [128.64857, 35.80336],
              [128.64922, 35.80339],
              [128.64948, 35.80351],
              [128.64984, 35.80317],
              [128.65159, 35.80307],
              [128.6521, 35.80134],
              [128.65221, 35.80085],
              [128.65265, 35.80041],
              [128.65283, 35.80007],
              [128.65381, 35.79951],
              [128.65493, 35.79953],
              [128.65648, 35.79915],
              [128.657, 35.79885],
              [128.65727, 35.79857],
              [128.6579, 35.79842],
              [128.65906, 35.79865],
              [128.65943, 35.79825],
              [128.66036, 35.7987],
              [128.66089, 35.79881],
              [128.66152, 35.79879],
              [128.66285, 35.79868],
              [128.66306, 35.79855],
              [128.6635, 35.79843],
              [128.66481, 35.79819],
              [128.66509, 35.79785],
              [128.66691, 35.7977],
              [128.66755, 35.79747],
              [128.66879, 35.79717],
              [128.66924, 35.79665],
              [128.66936, 35.79669],
              [128.66944, 35.79655],
              [128.66934, 35.79652],
              [128.66946, 35.79638],
              [128.66925, 35.79576],
              [128.66953, 35.79554],
              [128.67039, 35.79568],
              [128.67094, 35.79536],
              [128.67215, 35.79554],
              [128.67308, 35.7956],
              [128.67314, 35.79557],
              [128.67354, 35.79518],
              [128.67395, 35.79523],
              [128.67466, 35.79504],
              [128.67475, 35.79535],
              [128.67523, 35.79551],
              [128.676, 35.79485],
              [128.67651, 35.79448],
              [128.67673, 35.79441],
              [128.67695, 35.79415],
              [128.67728, 35.79389],
              [128.67769, 35.79355],
              [128.67794, 35.79329],
              [128.67855, 35.79326],
              [128.679, 35.7934],
              [128.67949, 35.79339],
              [128.67981, 35.79336],
              [128.67993, 35.79336],
              [128.68011, 35.79294],
              [128.68065, 35.79259],
              [128.68076, 35.79247],
              [128.68129, 35.79238],
              [128.68125, 35.79175],
              [128.68138, 35.79156],
              [128.6817, 35.79119],
              [128.68193, 35.79109],
              [128.6824, 35.79063],
              [128.68241, 35.79035],
              [128.68238, 35.78924],
              [128.68236, 35.78901],
              [128.68235, 35.78888],
              [128.68243, 35.78849],
              [128.68241, 35.78836],
              [128.68242, 35.78799],
              [128.68243, 35.78794],
              [128.68233, 35.78796],
              [128.68207, 35.78704],
              [128.68204, 35.78698],
              [128.68199, 35.78692],
              [128.6817, 35.78672],
              [128.68158, 35.78626],
              [128.68137, 35.78587],
              [128.68133, 35.78503],
              [128.68134, 35.78463],
              [128.68142, 35.78422],
              [128.68144, 35.78393],
              [128.68163, 35.78347],
              [128.68173, 35.78306],
              [128.68179, 35.78289],
              [128.68181, 35.78265],
              [128.68193, 35.78245],
              [128.68224, 35.78216],
              [128.68244, 35.78204],
              [128.68271, 35.78178],
              [128.68292, 35.7816],
              [128.68309, 35.78138],
              [128.68279, 35.78086],
              [128.68328, 35.78026],
              [128.68374, 35.7795],
              [128.68416, 35.77927],
              [128.6843, 35.77876],
              [128.68485, 35.77799],
              [128.68527, 35.77783],
              [128.6856, 35.77763],
              [128.68591, 35.77791],
              [128.68635, 35.77742],
              [128.68641, 35.77708],
              [128.68647, 35.77658],
              [128.68643, 35.77614],
              [128.68682, 35.77596],
              [128.68704, 35.77548],
              [128.68702, 35.77511],
              [128.68686, 35.77478],
              [128.68691, 35.77428],
              [128.6869, 35.77423],
              [128.6871, 35.77381],
              [128.68706, 35.77323],
              [128.687, 35.77284],
              [128.68673, 35.77244],
              [128.68632, 35.7717],
              [128.68595, 35.77122],
              [128.68609, 35.77056],
              [128.68602, 35.76998],
              [128.686, 35.76969],
              [128.68624, 35.76917],
              [128.68601, 35.76869],
              [128.68622, 35.76829],
              [128.68662, 35.768],
              [128.68702, 35.76782],
              [128.68718, 35.76785],
              [128.6883, 35.76801],
              [128.68889, 35.7675],
              [128.68924, 35.76712],
              [128.68905, 35.7667],
              [128.68907, 35.76662],
              [128.689, 35.76653],
              [128.68904, 35.76643],
              [128.68981, 35.76533],
              [128.69043, 35.76497],
              [128.69114, 35.76423],
              [128.69129, 35.76392],
              [128.69144, 35.76305],
              [128.69147, 35.76274],
              [128.69091, 35.76119],
              [128.69093, 35.76112],
              [128.69082, 35.76076],
              [128.69085, 35.76016],
              [128.69146, 35.7596],
              [128.69167, 35.75923],
              [128.6924, 35.759],
              [128.69288, 35.75872],
              [128.69387, 35.75801],
              [128.69401, 35.75785],
              [128.6945, 35.75743],
              [128.69478, 35.75713],
              [128.6949, 35.75694],
              [128.69497, 35.75662],
              [128.69538, 35.75607],
              [128.69567, 35.75563],
              [128.69466, 35.75509],
              [128.69415, 35.75366],
              [128.69409, 35.75191],
              [128.69401, 35.75054],
              [128.69394, 35.75007],
              [128.69372, 35.74892],
              [128.69262, 35.74859],
              [128.69225, 35.74663],
              [128.69254, 35.74545],
              [128.6929, 35.74483],
              [128.69313, 35.74434],
              [128.69346, 35.74358],
              [128.69408, 35.74298],
              [128.69303, 35.74205],
              [128.69177, 35.74107],
              [128.69102, 35.74021],
              [128.68978, 35.73955],
              [128.68985, 35.73909],
              [128.68966, 35.73862],
              [128.68959, 35.7382],
              [128.68955, 35.73738],
              [128.68876, 35.73712],
              [128.68857, 35.73721],
              [128.68857, 35.7371],
              [128.68976, 35.73599],
              [128.68997, 35.7356],
              [128.69086, 35.73513],
              [128.69141, 35.73427],
              [128.69094, 35.73335],
              [128.69145, 35.73223],
              [128.69039, 35.72986],
              [128.69, 35.72945],
              [128.68963, 35.72914],
              [128.68802, 35.72836],
              [128.68724, 35.72771],
              [128.68711, 35.72765],
              [128.68721, 35.72746],
              [128.68676, 35.72725],
              [128.68613, 35.72704],
              [128.68581, 35.72695],
              [128.68536, 35.72678],
              [128.6849, 35.72646],
              [128.68405, 35.72583],
              [128.68349, 35.72531],
              [128.6829, 35.72498],
              [128.68279, 35.72472],
              [128.68289, 35.72373],
              [128.68285, 35.72263],
              [128.68305, 35.72173],
              [128.68306, 35.72116],
              [128.68276, 35.72081],
              [128.6818, 35.7206],
              [128.6799, 35.72058],
              [128.6795, 35.72037],
              [128.67918, 35.72015],
              [128.67741, 35.72024],
              [128.67723, 35.72034],
              [128.6772, 35.72039],
              [128.67714, 35.72042],
              [128.67713, 35.72037],
              [128.67703, 35.72034],
              [128.67689, 35.72037],
              [128.67664, 35.72036],
              [128.67473, 35.72043],
              [128.6741, 35.72033],
              [128.67294, 35.72024],
              [128.67274, 35.72016],
              [128.67138, 35.71974],
              [128.67116, 35.71931],
              [128.67052, 35.71818],
              [128.67028, 35.71815],
              [128.6697, 35.7183],
              [128.66727, 35.71872],
              [128.66658, 35.71874],
              [128.66593, 35.71866],
              [128.66511, 35.71835],
              [128.66494, 35.71844],
              [128.66458, 35.71896],
              [128.66304, 35.71988],
              [128.66188, 35.71885],
              [128.66056, 35.71949],
              [128.65993, 35.719],
              [128.65721, 35.71767],
              [128.65631, 35.71749],
              [128.65591, 35.71661],
              [128.65541, 35.71607],
              [128.65507, 35.71561],
              [128.65472, 35.71487],
              [128.65377, 35.71396],
              [128.65313, 35.71344],
              [128.65259, 35.71355],
              [128.65038, 35.71227],
              [128.64972, 35.71161],
              [128.64881, 35.71124],
              [128.64795, 35.71111],
              [128.64696, 35.71107],
              [128.64649, 35.71071],
              [128.64653, 35.70959],
              [128.64635, 35.70899],
              [128.64584, 35.70852],
              [128.6458, 35.70776],
              [128.64495, 35.7076],
              [128.64309, 35.70666],
              [128.64173, 35.70669],
              [128.64101, 35.70662],
              [128.64051, 35.70629],
              [128.64019, 35.70619],
              [128.63986, 35.70587],
              [128.6391, 35.70578],
              [128.63833, 35.70552],
              [128.63782, 35.70551],
              [128.63738, 35.70514],
              [128.63629, 35.70585],
              [128.63461, 35.70606],
              [128.63424, 35.70613],
              [128.63329, 35.70611],
              [128.63233, 35.70627],
              [128.63148, 35.70625],
              [128.63035, 35.70648],
              [128.63004, 35.70639],
              [128.62939, 35.70566],
              [128.62877, 35.7053],
              [128.62832, 35.70508],
              [128.62786, 35.70506],
              [128.62728, 35.70485],
              [128.62691, 35.70477],
              [128.62661, 35.70465],
              [128.62621, 35.70442],
              [128.6252, 35.70359],
              [128.62395, 35.70307],
              [128.62352, 35.70201],
              [128.6193, 35.70251],
              [128.61935, 35.70418],
              [128.61838, 35.70485],
              [128.6178, 35.7051],
              [128.61694, 35.7057],
              [128.6169, 35.70643],
              [128.61662, 35.70705],
              [128.6156, 35.70749],
              [128.61502, 35.70798],
              [128.61487, 35.70853],
              [128.61444, 35.70879],
              [128.61386, 35.7093],
              [128.61335, 35.70959],
              [128.61327, 35.70987],
              [128.61328, 35.71045],
              [128.61331, 35.71085],
              [128.61333, 35.71175],
              [128.61334, 35.71187],
              [128.61355, 35.71212],
              [128.61347, 35.71252],
              [128.61333, 35.71297],
              [128.61347, 35.71349],
              [128.61345, 35.71381],
              [128.61373, 35.71411],
              [128.61521, 35.71512],
              [128.61559, 35.7156],
              [128.61625, 35.71592],
              [128.61647, 35.7164],
              [128.6165, 35.71656],
              [128.61651, 35.71715],
              [128.61636, 35.71741],
              [128.61607, 35.71773],
              [128.61573, 35.71915],
              [128.61535, 35.71986],
              [128.61492, 35.72042],
              [128.61408, 35.72122],
              [128.6137, 35.72173],
              [128.61349, 35.72184],
              [128.61244, 35.72209],
              [128.61231, 35.72213],
              [128.61218, 35.72253],
              [128.61135, 35.72317],
              [128.61042, 35.72396],
              [128.60994, 35.72445],
              [128.60955, 35.72461],
              [128.6095, 35.72503],
              [128.60969, 35.72549],
              [128.60969, 35.72594],
              [128.61086, 35.72698],
              [128.61123, 35.72752],
              [128.61181, 35.72785],
              [128.61269, 35.72864],
              [128.61342, 35.72881],
              [128.61423, 35.72929],
              [128.61464, 35.73002],
              [128.61453, 35.73058],
              [128.61418, 35.73113],
              [128.61392, 35.73138],
              [128.61346, 35.7321],
              [128.61418, 35.73337],
              [128.61487, 35.73355],
              [128.61461, 35.73643],
              [128.61313, 35.73805],
              [128.61158, 35.73826],
              [128.61059, 35.73841],
              [128.60825, 35.73858],
              [128.60861, 35.73909],
              [128.60887, 35.73994],
              [128.60849, 35.74278],
              [128.60878, 35.74317],
              [128.60881, 35.74352],
              [128.60724, 35.74448],
              [128.60674, 35.74449],
              [128.60527, 35.74409],
              [128.60688, 35.74313],
              [128.60685, 35.74281],
              [128.60655, 35.7424],
              [128.60673, 35.74096],
              [128.60694, 35.73959],
              [128.60668, 35.73873],
              [128.60631, 35.73822],
              [128.60597, 35.73791],
              [128.60491, 35.73724],
              [128.60467, 35.73721],
              [128.60425, 35.73692],
              [128.60395, 35.73661],
              [128.60346, 35.7365],
              [128.60231, 35.73633],
              [128.60167, 35.73605],
              [128.60149, 35.73577],
              [128.60025, 35.73569],
              [128.59987, 35.73567],
              [128.59925, 35.73543],
              [128.59881, 35.73538],
              [128.59814, 35.73568],
              [128.59766, 35.73556],
              [128.59729, 35.73576],
              [128.59666, 35.73584],
              [128.59556, 35.73575],
              [128.59498, 35.73597],
              [128.59442, 35.73628],
              [128.59399, 35.73629],
              [128.59302, 35.73612],
              [128.59261, 35.73549],
              [128.5929, 35.73464],
              [128.59197, 35.73336],
              [128.59187, 35.73301],
              [128.59141, 35.7327],
              [128.59131, 35.73242],
              [128.59095, 35.73179],
              [128.59029, 35.73145],
              [128.59017, 35.73137],
              [128.58885, 35.73094],
              [128.58835, 35.73064],
              [128.58792, 35.73088],
              [128.58758, 35.73144],
              [128.58699, 35.73178],
              [128.58666, 35.73216],
              [128.5862, 35.73234],
              [128.58506, 35.73287],
              [128.5843, 35.73339],
              [128.58399, 35.73365],
              [128.58377, 35.73425],
              [128.58357, 35.73439],
              [128.58321, 35.7351],
              [128.58283, 35.73546],
              [128.58238, 35.73565],
              [128.58206, 35.73648],
              [128.5812, 35.73755],
              [128.58077, 35.73755],
              [128.5804, 35.73768],
              [128.57987, 35.73768],
              [128.57935, 35.73787],
              [128.57811, 35.73686],
              [128.5775, 35.73655],
              [128.57704, 35.73661],
              [128.57653, 35.73665],
              [128.57447, 35.73565],
              [128.57399, 35.73564],
              [128.57352, 35.73556],
              [128.57323, 35.7351],
              [128.57268, 35.73465],
              [128.5723, 35.73462],
              [128.57183, 35.73443],
              [128.57148, 35.73409],
              [128.57063, 35.73371],
              [128.56971, 35.73363],
              [128.56897, 35.73399],
              [128.56692, 35.73467],
              [128.56656, 35.73525],
              [128.56589, 35.7356],
              [128.56556, 35.73605],
              [128.56517, 35.73595],
              [128.56365, 35.73586],
              [128.56344, 35.73554],
              [128.56277, 35.73526],
              [128.56262, 35.73518],
              [128.56249, 35.73503],
              [128.56217, 35.73403],
              [128.56204, 35.73349],
              [128.56187, 35.73299],
              [128.56177, 35.73136],
              [128.5609, 35.73091],
              [128.56074, 35.7307],
              [128.56065, 35.72973],
              [128.56032, 35.72946],
              [128.56007, 35.72894],
              [128.55872, 35.72808],
              [128.55763, 35.72797],
              [128.55709, 35.72781],
              [128.55689, 35.72759],
              [128.55641, 35.72734],
              [128.55596, 35.72687],
              [128.55541, 35.72665],
              [128.55486, 35.72697],
              [128.55461, 35.72696],
              [128.55391, 35.72651],
              [128.55348, 35.72646],
              [128.55264, 35.72608],
              [128.55191, 35.72581],
              [128.55153, 35.72575],
              [128.55141, 35.72553],
              [128.5505, 35.72483],
              [128.55042, 35.72465],
              [128.54956, 35.72408],
              [128.54889, 35.72378],
              [128.54844, 35.72373],
              [128.54775, 35.7233],
              [128.54746, 35.72316],
              [128.54685, 35.72266],
              [128.54652, 35.72261],
              [128.54639, 35.72242],
              [128.54575, 35.72184],
              [128.54518, 35.72175],
              [128.54477, 35.72118],
              [128.54454, 35.72023],
              [128.54392, 35.7197],
              [128.54315, 35.71856],
              [128.54236, 35.71835],
              [128.54102, 35.71808],
              [128.54006, 35.71821],
              [128.53965, 35.71801],
              [128.53942, 35.71781],
              [128.53872, 35.71744],
              [128.53803, 35.71719],
              [128.53738, 35.71709],
              [128.537, 35.71645],
              [128.53656, 35.71637],
              [128.53589, 35.71598],
              [128.53507, 35.71598],
              [128.53308, 35.7158],
              [128.53238, 35.71561],
              [128.53154, 35.71482],
              [128.53052, 35.71395],
              [128.52971, 35.71314],
              [128.52866, 35.71212],
              [128.52799, 35.71117],
              [128.52751, 35.71085],
              [128.5285, 35.71013],
              [128.52928, 35.70922],
              [128.52972, 35.70849],
              [128.53041, 35.70607],
              [128.53157, 35.70416],
              [128.53296, 35.70225],
              [128.53163, 35.69971],
              [128.53172, 35.69726],
              [128.53098, 35.69546],
              [128.53185, 35.6947],
              [128.53265, 35.69396],
              [128.5338, 35.69348],
              [128.53426, 35.69302],
              [128.53363, 35.69274],
              [128.53305, 35.69131],
              [128.53275, 35.69097],
              [128.53262, 35.69046],
              [128.53172, 35.68942],
              [128.53161, 35.68909],
              [128.53013, 35.68688],
              [128.53021, 35.6846],
              [128.53052, 35.68236],
              [128.52703, 35.6827],
              [128.52571, 35.68222],
              [128.52517, 35.68209],
              [128.52446, 35.68156],
              [128.52374, 35.68163],
              [128.52077, 35.68055],
              [128.52016, 35.68],
              [128.51916, 35.67983],
              [128.51858, 35.67933],
              [128.51712, 35.67915],
              [128.51508, 35.67698],
              [128.5151, 35.67672],
              [128.51373, 35.67519],
              [128.51236, 35.67487],
              [128.51084, 35.67478],
              [128.50975, 35.67439],
              [128.50834, 35.67454],
              [128.5077, 35.6742],
              [128.50714, 35.6742],
              [128.50698, 35.674],
              [128.50627, 35.67296],
              [128.50592, 35.67248],
              [128.50581, 35.67191],
              [128.50615, 35.67111],
              [128.50712, 35.66992],
              [128.50739, 35.66955],
              [128.50751, 35.66863],
              [128.50751, 35.6681],
              [128.50743, 35.66725],
              [128.50549, 35.66714],
              [128.50493, 35.66641],
              [128.50734, 35.66675],
              [128.50737, 35.66662],
              [128.50717, 35.66629],
              [128.50721, 35.66585],
              [128.50731, 35.6654],
              [128.50738, 35.66495],
              [128.5062, 35.66326],
              [128.50621, 35.66227],
              [128.50628, 35.66122],
              [128.5061, 35.6602],
              [128.50601, 35.65929],
              [128.50602, 35.658],
              [128.50588, 35.6573],
              [128.50511, 35.65492],
              [128.50368, 35.65446],
              [128.50446, 35.65311],
              [128.50539, 35.65194],
              [128.50493, 35.65152],
              [128.50485, 35.64938],
              [128.50637, 35.64839],
              [128.50695, 35.64769],
              [128.50723, 35.64718],
              [128.5076, 35.64693],
              [128.50761, 35.64685],
              [128.50768, 35.64683],
              [128.50769, 35.64675],
              [128.50777, 35.64674],
              [128.5078, 35.64666],
              [128.50789, 35.64656],
              [128.50802, 35.6465],
              [128.50833, 35.64627],
              [128.50837, 35.64616],
              [128.50844, 35.64612],
              [128.5085, 35.64605],
              [128.50845, 35.64595],
              [128.50841, 35.64586],
              [128.50842, 35.64578],
              [128.50848, 35.6456],
              [128.50845, 35.64556],
              [128.50849, 35.64541],
              [128.50849, 35.64532],
              [128.50852, 35.64525],
              [128.5085, 35.64518],
              [128.50845, 35.64512],
              [128.50855, 35.64487],
              [128.50854, 35.64482],
              [128.50849, 35.64478],
              [128.50847, 35.6447],
              [128.50841, 35.64462],
              [128.50839, 35.64457],
              [128.50834, 35.64453],
              [128.50824, 35.64435],
              [128.50808, 35.64416],
              [128.50804, 35.64406],
              [128.50795, 35.64397],
              [128.5079, 35.64395],
              [128.5079, 35.64385],
              [128.50784, 35.64376],
              [128.50781, 35.64369],
              [128.5078, 35.64363],
              [128.50776, 35.64347],
              [128.50783, 35.64345],
              [128.50787, 35.64333],
              [128.50788, 35.64326],
              [128.50792, 35.64315],
              [128.50795, 35.64309],
              [128.50789, 35.64304],
              [128.50781, 35.64281],
              [128.50775, 35.64273],
              [128.50763, 35.64264],
              [128.50757, 35.64263],
              [128.50758, 35.64251],
              [128.50758, 35.64245],
              [128.50754, 35.64241],
              [128.50758, 35.64237],
              [128.50753, 35.64231],
              [128.50733, 35.64219],
              [128.50712, 35.64207],
              [128.50715, 35.64202],
              [128.50708, 35.6419],
              [128.50705, 35.64185],
              [128.507, 35.6418],
              [128.50687, 35.64176],
              [128.50674, 35.64165],
              [128.5066, 35.64148],
              [128.50653, 35.64144],
              [128.50642, 35.64142],
              [128.50636, 35.64143],
              [128.50626, 35.64141],
              [128.50619, 35.64137],
              [128.50614, 35.64126],
              [128.50614, 35.64115],
              [128.50611, 35.64107],
              [128.50597, 35.64089],
              [128.50591, 35.64076],
              [128.50575, 35.64065],
              [128.50565, 35.64064],
              [128.50544, 35.64056],
              [128.50525, 35.64054],
              [128.50513, 35.64058],
              [128.50503, 35.64059],
              [128.50499, 35.64052],
              [128.50492, 35.64027],
              [128.50494, 35.64021],
              [128.50502, 35.64018],
              [128.50506, 35.64006],
              [128.50504, 35.64],
              [128.5048, 35.63986],
              [128.50459, 35.6397],
              [128.50453, 35.63971],
              [128.50438, 35.6396],
              [128.50434, 35.63956],
              [128.50421, 35.63948],
              [128.50408, 35.63936],
              [128.50397, 35.63923],
              [128.50385, 35.63917],
              [128.50374, 35.63915],
              [128.50362, 35.63917],
              [128.50355, 35.6392],
              [128.50351, 35.63916],
              [128.50333, 35.6392],
              [128.50332, 35.63915],
              [128.50322, 35.63912],
              [128.50323, 35.63893],
              [128.50319, 35.63879],
              [128.50302, 35.6387],
              [128.50297, 35.63873],
              [128.50269, 35.63867],
              [128.50255, 35.63867],
              [128.5025, 35.63871],
              [128.50225, 35.63877],
              [128.50218, 35.63877],
              [128.50203, 35.63868],
              [128.50189, 35.63865],
              [128.50188, 35.6386],
              [128.50167, 35.63854],
              [128.50141, 35.63849],
              [128.50133, 35.6385],
              [128.50103, 35.63844],
              [128.50097, 35.63847],
              [128.50087, 35.63847],
              [128.50087, 35.63842],
              [128.50082, 35.63839],
              [128.50075, 35.63837],
              [128.50064, 35.63836],
              [128.50041, 35.63837],
              [128.50032, 35.63835],
              [128.50021, 35.63836],
              [128.49991, 35.63834],
              [128.49978, 35.63838],
              [128.49962, 35.63845],
              [128.49936, 35.63861],
              [128.49923, 35.63863],
              [128.4991, 35.6386],
              [128.49906, 35.63855],
              [128.49899, 35.63854],
              [128.49887, 35.63841],
              [128.4988, 35.63841],
              [128.4987, 35.63836],
              [128.4986, 35.6384],
              [128.49832, 35.6386],
              [128.49815, 35.63863],
              [128.49804, 35.63871],
              [128.49783, 35.63872],
              [128.49774, 35.63874],
              [128.49762, 35.63873],
              [128.49755, 35.63868],
              [128.49714, 35.63828],
              [128.49687, 35.63823],
              [128.49649, 35.63834],
              [128.49591, 35.63843],
              [128.49518, 35.63849],
              [128.49469, 35.63838],
              [128.49452, 35.63837],
              [128.49429, 35.63848],
              [128.49388, 35.63864],
              [128.49366, 35.63866],
              [128.49345, 35.63856],
              [128.49326, 35.63827],
              [128.49311, 35.63792],
              [128.49298, 35.63774],
              [128.49283, 35.63762],
              [128.49257, 35.63751],
              [128.49231, 35.63748],
              [128.49197, 35.63747],
              [128.49163, 35.6376],
              [128.49134, 35.63763],
              [128.49102, 35.63753],
              [128.49081, 35.63734],
              [128.49066, 35.63706],
              [128.49041, 35.63696],
              [128.48968, 35.63695],
              [128.48932, 35.63682],
              [128.48878, 35.63658],
              [128.48802, 35.63637],
              [128.48773, 35.63623],
              [128.48743, 35.63593],
              [128.48735, 35.63574],
              [128.4871, 35.63554],
              [128.48657, 35.63548],
              [128.48621, 35.63566],
              [128.48586, 35.63577],
              [128.48549, 35.63577],
              [128.48468, 35.63543],
              [128.48434, 35.63535],
              [128.48407, 35.63532],
              [128.48368, 35.63519],
              [128.48325, 35.63518],
              [128.48302, 35.6351],
              [128.48226, 35.63507],
              [128.48164, 35.63507],
              [128.48124, 35.63496],
              [128.48093, 35.63484],
              [128.48068, 35.6348],
              [128.48054, 35.63474],
              [128.47989, 35.63463],
              [128.47939, 35.6346],
              [128.47899, 35.63471],
              [128.47842, 35.6349],
              [128.47776, 35.63677],
              [128.47777, 35.63761],
              [128.47765, 35.63858],
              [128.47723, 35.63935],
              [128.47658, 35.63986],
              [128.47559, 35.64019],
              [128.47472, 35.64018],
              [128.47377, 35.64024],
              [128.47326, 35.63988],
              [128.47142, 35.63826],
              [128.46967, 35.63787],
              [128.46829, 35.63674],
              [128.46762, 35.63677],
              [128.46569, 35.63682],
              [128.46491, 35.63688],
              [128.46463, 35.63694],
              [128.46361, 35.63728],
              [128.46344, 35.63735],
              [128.4628, 35.63736],
              [128.46192, 35.63791],
              [128.46164, 35.63801],
              [128.46059, 35.63862],
              [128.4599, 35.6392],
              [128.4594, 35.63972],
              [128.45902, 35.64037],
              [128.45868, 35.64057],
              [128.45807, 35.64053],
              [128.45802, 35.6405],
              [128.45738, 35.64039],
              [128.45708, 35.64027],
              [128.45694, 35.64023],
              [128.45668, 35.64021],
              [128.45641, 35.64008],
              [128.45631, 35.64005],
              [128.45615, 35.63998],
              [128.45603, 35.63991],
              [128.45589, 35.63981],
              [128.45571, 35.63976],
              [128.45533, 35.6396],
              [128.45528, 35.63955],
              [128.45526, 35.63944],
              [128.45533, 35.63915],
              [128.45532, 35.63896],
              [128.45528, 35.63886],
              [128.45525, 35.63871],
              [128.45517, 35.6385],
              [128.45506, 35.63837],
              [128.4545, 35.63815],
              [128.45447, 35.63811],
              [128.45442, 35.63792],
              [128.45441, 35.63762],
              [128.45437, 35.63752],
              [128.45429, 35.63739],
              [128.45404, 35.63738],
              [128.45386, 35.6374],
              [128.4535, 35.63754],
              [128.45342, 35.63758],
              [128.45334, 35.63757],
              [128.4532, 35.63763],
              [128.45306, 35.63764],
              [128.45208, 35.63714],
              [128.45182, 35.63709],
              [128.45165, 35.6371],
              [128.45151, 35.63705],
              [128.45134, 35.63701],
              [128.45115, 35.63693],
              [128.45095, 35.63693],
              [128.45086, 35.63696],
              [128.45059, 35.63697],
              [128.45043, 35.63704],
              [128.45037, 35.63704],
              [128.45028, 35.63701],
              [128.4502, 35.63701],
              [128.45015, 35.63704],
              [128.45005, 35.6371],
              [128.44993, 35.63722],
              [128.44985, 35.63736],
              [128.44983, 35.63746],
              [128.4498, 35.6375],
              [128.44966, 35.63753],
              [128.44961, 35.63759],
              [128.44958, 35.63768],
              [128.44953, 35.63774],
              [128.44943, 35.6378],
              [128.44924, 35.63802],
              [128.44909, 35.63798],
              [128.44889, 35.63797],
              [128.44874, 35.63799],
              [128.44869, 35.63784],
              [128.44863, 35.63779],
              [128.44847, 35.63774],
              [128.4483, 35.63773],
              [128.44818, 35.63774],
              [128.44804, 35.63781],
              [128.44794, 35.63771],
              [128.44797, 35.63757],
              [128.44786, 35.63733],
              [128.44782, 35.63738],
              [128.44772, 35.63745],
              [128.44729, 35.63769],
              [128.44714, 35.63775],
              [128.44668, 35.6378],
              [128.44659, 35.63779],
              [128.44618, 35.6378],
              [128.44607, 35.63782],
              [128.44591, 35.63789],
              [128.44561, 35.63806],
              [128.44537, 35.63824],
              [128.44511, 35.63835],
              [128.44499, 35.63842],
              [128.44478, 35.63849],
              [128.4446, 35.63853],
              [128.44441, 35.63854],
              [128.4443, 35.63851],
              [128.44418, 35.63839],
              [128.44418, 35.63832],
              [128.44413, 35.63814],
              [128.444, 35.63793],
              [128.44388, 35.63763],
              [128.44381, 35.63735],
              [128.44368, 35.63699],
              [128.44368, 35.63693],
              [128.44366, 35.63687],
              [128.44356, 35.63679],
              [128.44349, 35.63679],
              [128.44346, 35.63666],
              [128.44349, 35.6366],
              [128.44347, 35.63637],
              [128.44346, 35.63597],
              [128.44374, 35.63535],
              [128.44369, 35.63524],
              [128.44354, 35.63501],
              [128.44325, 35.63476],
              [128.44296, 35.63463],
              [128.44264, 35.63445],
              [128.4426, 35.6345],
              [128.44258, 35.63465],
              [128.44253, 35.6348],
              [128.4425, 35.63484],
              [128.44221, 35.63508],
              [128.44213, 35.63524],
              [128.44195, 35.63549],
              [128.44185, 35.63547],
              [128.44177, 35.6354],
              [128.44171, 35.63537],
              [128.44158, 35.63537],
              [128.44118, 35.63549],
              [128.44063, 35.63529],
              [128.44057, 35.63519],
              [128.44036, 35.635],
              [128.44014, 35.63487],
              [128.43995, 35.63472],
              [128.43983, 35.63472],
              [128.43972, 35.63479],
              [128.43941, 35.63449],
              [128.43918, 35.63434],
              [128.43916, 35.63427],
              [128.43917, 35.633],
              [128.43906, 35.6327],
              [128.43879, 35.63279],
              [128.43864, 35.6327],
              [128.43859, 35.63261],
              [128.43864, 35.63257],
              [128.43863, 35.63251],
              [128.43852, 35.63233],
              [128.43846, 35.63229],
              [128.43839, 35.63187],
              [128.43838, 35.63153],
              [128.43824, 35.63074],
              [128.43726, 35.63055],
              [128.43666, 35.63051],
              [128.43605, 35.63043],
              [128.43517, 35.63079],
              [128.43515, 35.6309],
              [128.43499, 35.63065],
              [128.43498, 35.6306],
              [128.43482, 35.63043],
              [128.43471, 35.6303],
              [128.43457, 35.63003],
              [128.43423, 35.62946],
              [128.43417, 35.62922],
              [128.43417, 35.62911],
              [128.43413, 35.62907],
              [128.43402, 35.62901],
              [128.43398, 35.62893],
              [128.43391, 35.6289],
              [128.43383, 35.6289],
              [128.43377, 35.62874],
              [128.43358, 35.62852],
              [128.43323, 35.62818],
              [128.43313, 35.62813],
              [128.433, 35.62802],
              [128.43285, 35.62786],
              [128.43272, 35.62777],
              [128.43265, 35.62775],
              [128.43252, 35.62777],
              [128.43247, 35.62775],
              [128.43238, 35.62755],
              [128.43225, 35.62735],
              [128.4321, 35.62717],
              [128.43204, 35.62704],
              [128.43185, 35.62688],
              [128.43184, 35.62681],
              [128.43174, 35.62658],
              [128.43168, 35.62644],
              [128.43164, 35.62637],
              [128.43143, 35.62612],
              [128.43128, 35.62604],
              [128.43114, 35.6259],
              [128.43106, 35.62588],
              [128.431, 35.62577],
              [128.43091, 35.62572],
              [128.43076, 35.62558],
              [128.43054, 35.62517],
              [128.43046, 35.62507],
              [128.43028, 35.6248],
              [128.43015, 35.62464],
              [128.43006, 35.6245],
              [128.42989, 35.62441],
              [128.42982, 35.62439],
              [128.42975, 35.62432],
              [128.42974, 35.62418],
              [128.42958, 35.624],
              [128.42954, 35.62389],
              [128.42947, 35.62364],
              [128.42938, 35.62338],
              [128.42944, 35.62333],
              [128.42944, 35.62302],
              [128.42945, 35.62297],
              [128.42947, 35.62289],
              [128.42927, 35.62297],
              [128.42907, 35.62304],
              [128.42902, 35.62298],
              [128.42901, 35.6229],
              [128.42903, 35.62284],
              [128.42904, 35.62277],
              [128.429, 35.62271],
              [128.42904, 35.62255],
              [128.42901, 35.62248],
              [128.42896, 35.6224],
              [128.42881, 35.62231],
              [128.42872, 35.62215],
              [128.42853, 35.62196],
              [128.42844, 35.622],
              [128.42827, 35.62212],
              [128.42824, 35.62221],
              [128.42812, 35.6223],
              [128.428, 35.62235],
              [128.42779, 35.6225],
              [128.42764, 35.62231],
              [128.42753, 35.62216],
              [128.42747, 35.62215],
              [128.42739, 35.62218],
              [128.42736, 35.62223],
              [128.42708, 35.62245],
              [128.42693, 35.62252],
              [128.42675, 35.62266],
              [128.42668, 35.62269],
              [128.42657, 35.62277],
              [128.42643, 35.62296],
              [128.42624, 35.62318],
              [128.42613, 35.62337],
              [128.42596, 35.62343],
              [128.42553, 35.62368],
              [128.42534, 35.62376],
              [128.42523, 35.62377],
              [128.42511, 35.62376],
              [128.42501, 35.62378],
              [128.42484, 35.62382],
              [128.42471, 35.62389],
              [128.42467, 35.62395],
              [128.42466, 35.62424],
              [128.42443, 35.62438],
              [128.42428, 35.62454],
              [128.42393, 35.62481],
              [128.42385, 35.62486],
              [128.42361, 35.62498],
              [128.42356, 35.62506],
              [128.42347, 35.62515],
              [128.42338, 35.62522],
              [128.42318, 35.62532],
              [128.42303, 35.62547],
              [128.4229, 35.62555],
              [128.42277, 35.6256],
              [128.42238, 35.62558],
              [128.42229, 35.6256],
              [128.42231, 35.62575],
              [128.42227, 35.62579],
              [128.4221, 35.62567],
              [128.42204, 35.6257],
              [128.42191, 35.62555],
              [128.42176, 35.62546],
              [128.42154, 35.62529],
              [128.42146, 35.62515],
              [128.42135, 35.62527],
              [128.42094, 35.62549],
              [128.42076, 35.62553],
              [128.42055, 35.6255],
              [128.42041, 35.62543],
              [128.42031, 35.62541],
              [128.42017, 35.62526],
              [128.41985, 35.62504],
              [128.41985, 35.62496],
              [128.41965, 35.62475],
              [128.41958, 35.62465],
              [128.4194, 35.62446],
              [128.41935, 35.62436],
              [128.41929, 35.62429],
              [128.41918, 35.62404],
              [128.41912, 35.62401],
              [128.41895, 35.62354],
              [128.41881, 35.62338],
              [128.41868, 35.62329],
              [128.41858, 35.62328],
              [128.41854, 35.6232],
              [128.41855, 35.62309],
              [128.41852, 35.62299],
              [128.41836, 35.62271],
              [128.41824, 35.62266],
              [128.41818, 35.62251],
              [128.41811, 35.62239],
              [128.41799, 35.62224],
              [128.41792, 35.62206],
              [128.41789, 35.62188],
              [128.41784, 35.62178],
              [128.41774, 35.62175],
              [128.41763, 35.62159],
              [128.41758, 35.62141],
              [128.41758, 35.62129],
              [128.41752, 35.6212],
              [128.41721, 35.62092],
              [128.41713, 35.62077],
              [128.41681, 35.62035],
              [128.41678, 35.62027],
              [128.41654, 35.62007],
              [128.41641, 35.6203],
              [128.4162, 35.62064],
              [128.41632, 35.62108],
              [128.41633, 35.62146],
              [128.41625, 35.62153],
              [128.41619, 35.62167],
              [128.41611, 35.62184],
              [128.41601, 35.62188],
              [128.41587, 35.622],
              [128.41555, 35.62209],
              [128.41551, 35.62215],
              [128.41529, 35.62205],
              [128.4152, 35.62209],
              [128.41507, 35.62219],
              [128.41495, 35.62224],
              [128.41484, 35.62222],
              [128.41468, 35.62226],
              [128.41457, 35.62233],
              [128.41451, 35.62226],
              [128.41422, 35.62235],
              [128.41427, 35.62227],
              [128.4146, 35.62204],
              [128.41464, 35.62193],
              [128.4143, 35.62213],
              [128.41401, 35.6221],
              [128.41384, 35.62206],
              [128.41374, 35.62208],
              [128.41371, 35.62197],
              [128.41376, 35.62195],
              [128.41379, 35.6219],
              [128.41353, 35.62167],
              [128.41341, 35.62161],
              [128.41336, 35.62153],
              [128.41323, 35.62143],
              [128.41313, 35.6213],
              [128.41311, 35.62116],
              [128.41328, 35.62106],
              [128.41346, 35.62095],
              [128.4135, 35.62091],
              [128.4135, 35.62081],
              [128.41344, 35.62072],
              [128.41344, 35.62053],
              [128.41327, 35.62039],
              [128.41292, 35.6202],
              [128.41286, 35.61983],
              [128.41289, 35.61966],
              [128.41282, 35.61945],
              [128.41281, 35.61932],
              [128.41263, 35.61918],
              [128.4124, 35.61889],
              [128.41234, 35.6187],
              [128.41219, 35.61854],
              [128.41186, 35.61839],
              [128.41116, 35.61768],
              [128.41099, 35.61756],
              [128.41065, 35.61719],
              [128.41059, 35.61705],
              [128.41047, 35.61697],
              [128.41035, 35.6169],
              [128.40961, 35.6167],
              [128.40886, 35.61643],
              [128.40868, 35.61646],
              [128.40841, 35.61647],
              [128.40835, 35.61641],
              [128.40815, 35.61629],
              [128.40816, 35.61622],
              [128.4081, 35.61607],
              [128.40805, 35.616],
              [128.40796, 35.61597],
              [128.40795, 35.61611],
              [128.40793, 35.61622],
              [128.40788, 35.61601],
              [128.40783, 35.61595],
              [128.40781, 35.61588],
              [128.40791, 35.61575],
              [128.40791, 35.61568],
              [128.40762, 35.61554],
              [128.40757, 35.61556],
              [128.40751, 35.61557],
              [128.40735, 35.61554],
              [128.40729, 35.61556],
              [128.40704, 35.6155],
              [128.40691, 35.61549],
              [128.4067, 35.6155],
              [128.40649, 35.61547],
              [128.40642, 35.61541],
              [128.40632, 35.61544],
              [128.40611, 35.61556],
              [128.40606, 35.61558],
              [128.40565, 35.61538],
              [128.40557, 35.61541],
              [128.40538, 35.61541],
              [128.4053, 35.61537],
              [128.40524, 35.61529],
              [128.40518, 35.61526],
              [128.40509, 35.61525],
              [128.40501, 35.61528],
              [128.40463, 35.61514],
              [128.40455, 35.61513],
              [128.40449, 35.61514],
              [128.40441, 35.61508],
              [128.40433, 35.61505],
              [128.40425, 35.61499],
              [128.40411, 35.61485],
              [128.40408, 35.61478],
              [128.40395, 35.61469],
              [128.40379, 35.61474],
              [128.40332, 35.61495],
              [128.40325, 35.61502],
              [128.40315, 35.61504],
              [128.40308, 35.61508],
              [128.40297, 35.61516],
              [128.4029, 35.61524],
              [128.40282, 35.61524],
              [128.40265, 35.61539],
              [128.40261, 35.61546],
              [128.40238, 35.61575],
              [128.40232, 35.61589],
              [128.40234, 35.61627],
              [128.4023, 35.61646],
              [128.4023, 35.61661],
              [128.40235, 35.61672],
              [128.40237, 35.61681],
              [128.40225, 35.61688],
              [128.40211, 35.61693],
              [128.40209, 35.61698],
              [128.402, 35.61706],
              [128.40192, 35.61711],
              [128.40185, 35.61709],
              [128.40171, 35.61731],
              [128.4017, 35.61743],
              [128.40165, 35.61753],
              [128.40129, 35.61768],
              [128.40122, 35.61775],
              [128.40101, 35.61789],
              [128.40079, 35.6178],
              [128.40057, 35.6178],
              [128.40045, 35.61773],
              [128.40045, 35.61767],
              [128.40043, 35.6176],
              [128.40048, 35.6175],
              [128.40044, 35.61738],
              [128.40044, 35.61713],
              [128.40053, 35.61691],
              [128.40042, 35.61667],
              [128.40034, 35.61647],
              [128.40029, 35.61619],
              [128.40036, 35.61605],
              [128.40036, 35.61597],
              [128.40036, 35.61573],
              [128.4002, 35.61573],
              [128.40001, 35.61585],
              [128.39991, 35.61585],
              [128.39969, 35.61593],
              [128.39862, 35.61617],
              [128.39678, 35.61649],
              [128.39574, 35.61578],
              [128.39573, 35.61566],
              [128.39555, 35.61551],
              [128.39454, 35.61546],
              [128.39392, 35.6153],
              [128.39348, 35.61483],
              [128.39319, 35.61466],
              [128.39259, 35.61507],
              [128.39237, 35.61495],
              [128.39171, 35.61446],
              [128.39137, 35.61403],
              [128.39104, 35.61374],
              [128.39108, 35.61353],
              [128.39088, 35.61332],
              [128.38971, 35.61305],
              [128.38916, 35.61302],
              [128.38847, 35.61286],
              [128.38795, 35.61297],
              [128.38753, 35.61295],
              [128.38721, 35.6126],
              [128.38709, 35.61236],
              [128.38638, 35.61198],
              [128.38562, 35.61124],
              [128.38513, 35.61071],
              [128.38483, 35.61079],
              [128.38452, 35.61073],
              [128.38375, 35.6108],
              [128.3835, 35.61058],
              [128.383, 35.6106],
              [128.38223, 35.61066],
              [128.38175, 35.61052],
              [128.38147, 35.61025],
              [128.38128, 35.6102],
              [128.38107, 35.60939],
              [128.3808, 35.60894],
              [128.38075, 35.60871],
              [128.38056, 35.60842],
              [128.37987, 35.60775],
              [128.37973, 35.60764],
              [128.37979, 35.60755],
              [128.37969, 35.60741],
              [128.37973, 35.60723],
              [128.3795, 35.60729],
              [128.37926, 35.60731],
              [128.3791, 35.60745],
              [128.37892, 35.60754],
              [128.37788, 35.60854],
              [128.37774, 35.60889],
              [128.37781, 35.60904],
              [128.37806, 35.60938],
              [128.37809, 35.60961],
              [128.37806, 35.60971],
              [128.37753, 35.60993],
              [128.37728, 35.60993],
              [128.37711, 35.60987],
              [128.37667, 35.60946],
              [128.37598, 35.60867],
              [128.37592, 35.60852],
              [128.3757, 35.60772],
              [128.37548, 35.60756],
              [128.37516, 35.60754],
              [128.37443, 35.60773],
              [128.37338, 35.6081],
              [128.37219, 35.60855],
              [128.37112, 35.60914],
              [128.37064, 35.60915],
              [128.37014, 35.60923],
              [128.36985, 35.60963],
              [128.36932, 35.60995],
              [128.36864, 35.6102],
              [128.3685, 35.61036],
              [128.36832, 35.61096],
              [128.36781, 35.61117],
              [128.36891, 35.6121],
              [128.37394, 35.61316],
              [128.37899, 35.61528],
              [128.38403, 35.61723],
              [128.38536, 35.61849],
              [128.38805, 35.62174],
              [128.38913, 35.62245],
              [128.39117, 35.62499],
              [128.39321, 35.62825],
              [128.39689, 35.63146],
              [128.39657, 35.6332],
              [128.39933, 35.63327],
              [128.39781, 35.63339],
              [128.39818, 35.63475],
              [128.39778, 35.63803],
              [128.39689, 35.64132],
              [128.39676, 35.64245],
              [128.39672, 35.64303],
              [128.39657, 35.6446],
              [128.39453, 35.6479],
              [128.39067, 35.65122],
              [128.38905, 35.65264],
              [128.38571, 35.65454],
              [128.38461, 35.65556],
              [128.38202, 35.65705],
              [128.38062, 35.65787],
              [128.37964, 35.65895],
              [128.37467, 35.66248],
              [128.37189, 35.66451],
              [128.36971, 35.66624],
              [128.36801, 35.66783],
              [128.36477, 35.67114],
              [128.36035, 35.67493],
              [128.35804, 35.67776],
              [128.35564, 35.68106],
              [128.35488, 35.68214],
              [128.35372, 35.68436],
              [128.35243, 35.68765],
              [128.35127, 35.69094],
              [128.35037, 35.69422],
              [128.35006, 35.69579],
              [128.35018, 35.70407],
              [128.35086, 35.70532],
              [128.35224, 35.70661],
              [128.35384, 35.7073],
              [128.36349, 35.7072],
              [128.36528, 35.70642],
              [128.37028, 35.7048],
              [128.37321, 35.7035],
              [128.37313, 35.70254],
              [128.37434, 35.70169],
              [128.37576, 35.70098],
              [128.37656, 35.70063],
              [128.3767, 35.70052],
              [128.38743, 35.70041],
              [128.3903, 35.70014],
              [128.39531, 35.69931],
              [128.39529, 35.69741],
              [128.396, 35.6972],
              [128.39595, 35.6971],
              [128.39604, 35.69705],
              [128.4003, 35.697],
              [128.40216, 35.69724],
              [128.40531, 35.6963],
              [128.40528, 35.69441],
              [128.40596, 35.69428],
              [128.40601, 35.69419],
              [128.40601, 35.69407],
              [128.40616, 35.69393],
              [128.40617, 35.694],
              [128.40621, 35.69404],
              [128.40622, 35.6941],
              [128.40625, 35.69415],
              [128.40631, 35.6942],
              [128.40641, 35.69415],
              [128.40689, 35.69405],
              [128.40688, 35.69399],
              [128.40699, 35.69397],
              [128.40707, 35.69393],
              [128.40723, 35.69389],
              [128.40737, 35.69392],
              [128.40759, 35.69387],
              [128.40781, 35.69385],
              [128.40788, 35.69382],
              [128.40782, 35.69377],
              [128.408, 35.69379],
              [128.40811, 35.69375],
              [128.40823, 35.69374],
              [128.4085, 35.69368],
              [128.40866, 35.69369],
              [128.40879, 35.69364],
              [128.42, 35.69353],
              [128.4204, 35.69371],
              [128.42044, 35.69379],
              [128.42066, 35.69388],
              [128.42065, 35.69393],
              [128.42101, 35.69415],
              [128.42147, 35.69446],
              [128.42153, 35.69453],
              [128.42203, 35.69492],
              [128.4221, 35.69499],
              [128.42243, 35.6952],
              [128.42252, 35.69521],
              [128.42258, 35.69525],
              [128.42279, 35.69542],
              [128.42293, 35.69549],
              [128.4233, 35.69575],
              [128.42339, 35.69584],
              [128.42349, 35.6959],
              [128.42357, 35.696],
              [128.42407, 35.69635],
              [128.42438, 35.69659],
              [128.42454, 35.69668],
              [128.42502, 35.69703],
              [128.42518, 35.6972],
              [128.42533, 35.69728],
              [128.42551, 35.69743],
              [128.42563, 35.69745],
              [128.42594, 35.69779],
              [128.4261, 35.69787],
              [128.42502, 35.69901],
              [128.42606, 35.70002],
              [128.43053, 35.70325],
              [128.43158, 35.70425],
              [128.43243, 35.70539],
              [128.43271, 35.7065],
              [128.43513, 35.70648],
              [128.43515, 35.70666],
              [128.43531, 35.70667],
              [128.43537, 35.70666],
              [128.43547, 35.7067],
              [128.43556, 35.70665],
              [128.43561, 35.70661],
              [128.43561, 35.70686],
              [128.43556, 35.7069],
              [128.43548, 35.70692],
              [128.43536, 35.70689],
              [128.43521, 35.70689],
              [128.43534, 35.70724],
              [128.43533, 35.70733],
              [128.43536, 35.70738],
              [128.43546, 35.70796],
              [128.43546, 35.70818],
              [128.43563, 35.70837],
              [128.43564, 35.70862],
              [128.43544, 35.7086],
              [128.43532, 35.70892],
              [128.43537, 35.70947],
              [128.43532, 35.70976],
              [128.43534, 35.71024],
              [128.43551, 35.71042],
              [128.43543, 35.71053],
              [128.43544, 35.7107],
              [128.43531, 35.71093],
              [128.43529, 35.71142],
              [128.43543, 35.71158],
              [128.4354, 35.71162],
              [128.43539, 35.71171],
              [128.43527, 35.71195],
              [128.43525, 35.7128],
              [128.43524, 35.71285],
              [128.43525, 35.7146],
              [128.43535, 35.71522],
              [128.43521, 35.71639],
              [128.43551, 35.71682],
              [128.43553, 35.71714],
              [128.43558, 35.71716],
              [128.43561, 35.7174],
              [128.43552, 35.71737],
              [128.43558, 35.71769],
              [128.4357, 35.71802],
              [128.4356, 35.71845],
              [128.43564, 35.71885],
              [128.43572, 35.71904],
              [128.43569, 35.71922],
              [128.43574, 35.72026],
              [128.43588, 35.72075],
              [128.43598, 35.72074],
              [128.43599, 35.72079],
              [128.43586, 35.72082],
              [128.4358, 35.72102],
              [128.43578, 35.72089],
              [128.4357, 35.72082],
              [128.43516, 35.71977],
              [128.43274, 35.71975],
              [128.43257, 35.7206],
              [128.43217, 35.72166],
              [128.43153, 35.7229],
              [128.43086, 35.72395],
              [128.43089, 35.72619],
              [128.42753, 35.72622],
              [128.42589, 35.72749],
              [128.42592, 35.72952],
              [128.4231, 35.72955],
              [128.42093, 35.7317],
              [128.42095, 35.73285],
              [128.41873, 35.73287],
              [128.41595, 35.73456],
              [128.41597, 35.73617],
              [128.41292, 35.73621],
              [128.41097, 35.73733],
              [128.411, 35.7395],
              [128.40597, 35.73955],
              [128.40099, 35.74234],
              [128.39956, 35.7429],
              [128.396, 35.7444],
              [128.39603, 35.74621],
              [128.39299, 35.74624],
              [128.39102, 35.74755],
              [128.39105, 35.74954],
              [128.38972, 35.74955],
              [128.38767, 35.75285],
              [128.3861, 35.75465],
              [128.38622, 35.7627],
              [128.38805, 35.76595],
              [128.3913, 35.76592],
              [128.39137, 35.77086],
              [128.39256, 35.77231],
              [128.39562, 35.77014],
              [128.39575, 35.77005],
              [128.39586, 35.77001],
              [128.39584, 35.76983],
              [128.39592, 35.76977],
              [128.39597, 35.76987],
              [128.39607, 35.76997],
              [128.396, 35.7701],
              [128.39617, 35.77022],
              [128.39618, 35.77032],
              [128.39625, 35.77039],
              [128.39639, 35.77053],
              [128.39647, 35.7757],
              [128.4015, 35.77565],
              [128.40165, 35.78548],
              [128.40354, 35.78874],
              [128.40673, 35.78871],
              [128.40681, 35.79381],
              [128.40758, 35.79525],
              [128.40992, 35.79851],
              [128.41191, 35.79849],
              [128.41263, 35.79813],
              [128.41073, 35.79955],
              [128.41074, 35.80109],
              [128.41244, 35.80282],
              [128.41577, 35.80281],
              [128.41579, 35.80609],
              [128.42029, 35.80608],
              [128.42082, 35.8063],
              [128.42083, 35.80935],
              [128.44095, 35.80929],
              [128.44093, 35.80604],
              [128.44103, 35.80602],
              [128.45747, 35.80596],
              [128.45804, 35.80608],
              [128.45858, 35.80611],
              [128.45922, 35.80617],
              [128.45933, 35.80614],
              [128.45945, 35.80615],
              [128.45956, 35.80613],
              [128.45963, 35.80606],
              [128.46003, 35.80614],
              [128.46029, 35.80625],
              [128.46041, 35.80627],
              [128.46167, 35.80585],
              [128.46187, 35.80585],
              [128.46207, 35.80593],
              [128.46237, 35.80591],
              [128.46298, 35.80597],
              [128.46336, 35.80606],
              [128.4636, 35.80601],
              [128.46382, 35.80594],
              [128.46647, 35.80594],
              [128.4661, 35.80922],
              [128.46737, 35.80966],
              [128.4686, 35.81016],
              [128.47113, 35.81135],
              [128.47338, 35.81247],
              [128.47722, 35.81573],
              [128.47992, 35.819],
              [128.48023, 35.82228],
              [128.4804, 35.82555],
              [128.47904, 35.82873],
              [128.48119, 35.82993],
              [128.48208, 35.83042],
              [128.48366, 35.83131]
            ]
          ],
          [
            [
              [128.45928, 35.94136],
              [128.46089, 35.94158],
              [128.46179, 35.9411],
              [128.46271, 35.94038],
              [128.46287, 35.94009],
              [128.46293, 35.94007],
              [128.46306, 35.94008],
              [128.46319, 35.94007],
              [128.46357, 35.93978],
              [128.46359, 35.93967],
              [128.46355, 35.93958],
              [128.46356, 35.9395],
              [128.46355, 35.93943],
              [128.46358, 35.93933],
              [128.46365, 35.93926],
              [128.46371, 35.93915],
              [128.4637, 35.93903],
              [128.46366, 35.93896],
              [128.46365, 35.93887],
              [128.46362, 35.93877],
              [128.46399, 35.93874],
              [128.46408, 35.93878],
              [128.4643, 35.93884],
              [128.46495, 35.93888],
              [128.46546, 35.93886],
              [128.46602, 35.9389],
              [128.46623, 35.93888],
              [128.46643, 35.93875],
              [128.46657, 35.93869],
              [128.46662, 35.93862],
              [128.46682, 35.93844],
              [128.46687, 35.93837],
              [128.46713, 35.93819],
              [128.46718, 35.93813],
              [128.46725, 35.93806],
              [128.46769, 35.93826],
              [128.46834, 35.93844],
              [128.46854, 35.93854],
              [128.46886, 35.93861],
              [128.46905, 35.93852],
              [128.46914, 35.9385],
              [128.46934, 35.93854],
              [128.46989, 35.93876],
              [128.47004, 35.93879],
              [128.47019, 35.93877],
              [128.4703, 35.93866],
              [128.4705, 35.93867],
              [128.47063, 35.93853],
              [128.4707, 35.93842],
              [128.47071, 35.93837],
              [128.47068, 35.93833],
              [128.47068, 35.93817],
              [128.47082, 35.93817],
              [128.47088, 35.93813],
              [128.47103, 35.93808],
              [128.47112, 35.93808],
              [128.47116, 35.93803],
              [128.47134, 35.93798],
              [128.47142, 35.93798],
              [128.4715, 35.9379],
              [128.47156, 35.93788],
              [128.47167, 35.93791],
              [128.4717, 35.93785],
              [128.47185, 35.93782],
              [128.47251, 35.93743],
              [128.47331, 35.93733],
              [128.47359, 35.93659],
              [128.47417, 35.93654],
              [128.47468, 35.93655],
              [128.47503, 35.93648],
              [128.47526, 35.93635],
              [128.47575, 35.93604],
              [128.47621, 35.93579],
              [128.47679, 35.93526],
              [128.47727, 35.93432],
              [128.47733, 35.9339],
              [128.47717, 35.93335],
              [128.47739, 35.93281],
              [128.47744, 35.93188],
              [128.47701, 35.93145],
              [128.47686, 35.93006],
              [128.47707, 35.92969],
              [128.47678, 35.92853],
              [128.47689, 35.92814],
              [128.4766, 35.92742],
              [128.47603, 35.92631],
              [128.47555, 35.92592],
              [128.47519, 35.9256],
              [128.4751, 35.92534],
              [128.47506, 35.92503],
              [128.47469, 35.9243],
              [128.47431, 35.92411],
              [128.47421, 35.92376],
              [128.47388, 35.92309],
              [128.474, 35.92222],
              [128.47398, 35.92168],
              [128.47384, 35.92135],
              [128.47401, 35.92109],
              [128.4744, 35.91979],
              [128.47438, 35.91954],
              [128.47413, 35.91852],
              [128.47409, 35.91784],
              [128.47457, 35.91726],
              [128.47419, 35.91624],
              [128.4734, 35.91466],
              [128.47272, 35.91405],
              [128.47203, 35.9135],
              [128.47156, 35.913],
              [128.47076, 35.91243],
              [128.46997, 35.91219],
              [128.46943, 35.9112],
              [128.46859, 35.91105],
              [128.46726, 35.91056],
              [128.46665, 35.90991],
              [128.46608, 35.90901],
              [128.46649, 35.9083],
              [128.46746, 35.90746],
              [128.46799, 35.90673],
              [128.46823, 35.90567],
              [128.46809, 35.90518],
              [128.46805, 35.90495],
              [128.46775, 35.9042],
              [128.46813, 35.90342],
              [128.46813, 35.90209],
              [128.46829, 35.90168],
              [128.4683, 35.90149],
              [128.46841, 35.90111],
              [128.4684, 35.90104],
              [128.46826, 35.90061],
              [128.46855, 35.90033],
              [128.46894, 35.89989],
              [128.46963, 35.89945],
              [128.46981, 35.89909],
              [128.47026, 35.89856],
              [128.47133, 35.89833],
              [128.47157, 35.89803],
              [128.47251, 35.89693],
              [128.4733, 35.89645],
              [128.4738, 35.89597],
              [128.47392, 35.89588],
              [128.47452, 35.89561],
              [128.4751, 35.89523],
              [128.47578, 35.89492],
              [128.4762, 35.89511],
              [128.47677, 35.89561],
              [128.47728, 35.89578],
              [128.47793, 35.89646],
              [128.47876, 35.89691],
              [128.479, 35.89724],
              [128.4796, 35.8971],
              [128.48218, 35.89598],
              [128.4831, 35.8957],
              [128.48376, 35.89583],
              [128.48439, 35.8951],
              [128.48497, 35.89478],
              [128.48541, 35.89554],
              [128.48544, 35.89595],
              [128.48567, 35.89629],
              [128.48595, 35.89637],
              [128.48551, 35.89693],
              [128.4862, 35.89696],
              [128.48647, 35.89688],
              [128.48695, 35.8968],
              [128.48714, 35.89679],
              [128.48761, 35.89713],
              [128.48781, 35.89724],
              [128.48779, 35.89736],
              [128.4879, 35.89748],
              [128.48806, 35.89757],
              [128.48831, 35.89783],
              [128.4886, 35.898],
              [128.48901, 35.89812],
              [128.4897, 35.8981],
              [128.49008, 35.89794],
              [128.49048, 35.89811],
              [128.49086, 35.89797],
              [128.49113, 35.898],
              [128.49153, 35.89745],
              [128.49217, 35.8971],
              [128.49261, 35.89672],
              [128.49317, 35.89656],
              [128.49335, 35.89598],
              [128.49312, 35.89548],
              [128.4932, 35.89534],
              [128.49332, 35.89509],
              [128.4937, 35.89447],
              [128.49383, 35.8941],
              [128.49477, 35.89269],
              [128.49496, 35.89224],
              [128.4951, 35.89177],
              [128.49522, 35.89119],
              [128.49649, 35.89029],
              [128.49685, 35.88955],
              [128.49949, 35.89021],
              [128.49976, 35.88993],
              [128.49981, 35.88972],
              [128.49989, 35.88948],
              [128.50072, 35.88946],
              [128.50088, 35.88895],
              [128.50085, 35.88715],
              [128.50171, 35.88668],
              [128.50206, 35.88677],
              [128.50283, 35.88707],
              [128.50328, 35.88774],
              [128.50485, 35.89101],
              [128.50517, 35.89128],
              [128.50586, 35.89171],
              [128.50615, 35.89212],
              [128.50652, 35.89259],
              [128.50696, 35.89294],
              [128.5076, 35.89316],
              [128.50775, 35.89324],
              [128.50884, 35.89334],
              [128.50947, 35.89329],
              [128.51205, 35.89335],
              [128.51316, 35.89328],
              [128.51368, 35.8932],
              [128.51557, 35.89244],
              [128.5163, 35.89228],
              [128.51685, 35.8922],
              [128.51777, 35.89192],
              [128.51883, 35.89176],
              [128.52018, 35.89112],
              [128.52208, 35.89039],
              [128.52332, 35.88967],
              [128.5244, 35.88911],
              [128.52607, 35.88838],
              [128.52635, 35.88829],
              [128.52544, 35.88712],
              [128.52553, 35.88687],
              [128.52541, 35.88679],
              [128.52612, 35.88633],
              [128.52529, 35.88576],
              [128.52468, 35.88564],
              [128.52396, 35.88571],
              [128.5237, 35.88571],
              [128.52331, 35.88551],
              [128.52321, 35.88543],
              [128.52209, 35.88511],
              [128.52136, 35.88525],
              [128.52077, 35.88494],
              [128.52033, 35.88461],
              [128.51981, 35.88425],
              [128.51819, 35.88403],
              [128.51736, 35.88336],
              [128.51719, 35.88317],
              [128.5171, 35.88278],
              [128.51693, 35.88249],
              [128.51692, 35.88244],
              [128.51755, 35.88149],
              [128.51767, 35.88125],
              [128.51841, 35.87991],
              [128.51873, 35.87961],
              [128.51905, 35.87897],
              [128.51956, 35.87808],
              [128.5197, 35.87781],
              [128.52014, 35.87677],
              [128.52031, 35.87627],
              [128.52038, 35.87536],
              [128.52035, 35.87467],
              [128.5204, 35.87377],
              [128.52086, 35.87318],
              [128.52113, 35.87252],
              [128.52097, 35.87217],
              [128.52019, 35.87082],
              [128.51995, 35.8705],
              [128.51973, 35.87025],
              [128.51919, 35.86995],
              [128.51906, 35.8697],
              [128.51879, 35.8695],
              [128.51924, 35.8692],
              [128.51929, 35.86914],
              [128.51922, 35.86908],
              [128.51887, 35.86859],
              [128.51779, 35.86813],
              [128.51669, 35.86774],
              [128.51597, 35.86765],
              [128.51584, 35.86768],
              [128.51568, 35.86765],
              [128.51551, 35.86759],
              [128.51508, 35.86771],
              [128.51414, 35.86771],
              [128.51338, 35.86792],
              [128.51257, 35.86788],
              [128.51142, 35.86826],
              [128.51052, 35.86883],
              [128.50978, 35.86912],
              [128.50953, 35.86771],
              [128.50912, 35.86703],
              [128.50857, 35.86614],
              [128.50811, 35.86579],
              [128.50769, 35.86585],
              [128.50757, 35.86577],
              [128.50681, 35.86587],
              [128.50662, 35.86573],
              [128.50533, 35.86559],
              [128.50519, 35.8656],
              [128.50478, 35.86555],
              [128.50391, 35.86557],
              [128.50338, 35.86523],
              [128.50258, 35.86477],
              [128.50259, 35.86437],
              [128.50233, 35.86401],
              [128.50153, 35.86336],
              [128.50109, 35.86336],
              [128.50097, 35.86327],
              [128.50047, 35.8633],
              [128.4998, 35.86354],
              [128.49823, 35.86394],
              [128.49664, 35.86412],
              [128.49585, 35.8646],
              [128.49477, 35.86446],
              [128.49408, 35.86457],
              [128.49328, 35.86447],
              [128.49207, 35.86524],
              [128.49092, 35.86572],
              [128.49083, 35.86574],
              [128.48967, 35.86634],
              [128.48916, 35.8664],
              [128.48801, 35.86716],
              [128.48762, 35.86748],
              [128.48719, 35.86772],
              [128.48663, 35.86792],
              [128.4859, 35.86792],
              [128.4849, 35.86782],
              [128.48459, 35.86786],
              [128.48428, 35.86771],
              [128.4837, 35.86762],
              [128.48293, 35.86753],
              [128.482, 35.86792],
              [128.48149, 35.86752],
              [128.48093, 35.86719],
              [128.47989, 35.86621],
              [128.47953, 35.8656],
              [128.47914, 35.8648],
              [128.47832, 35.86368],
              [128.47777, 35.86257],
              [128.47715, 35.86206],
              [128.47697, 35.86162],
              [128.47651, 35.86026],
              [128.47585, 35.85972],
              [128.47579, 35.85935],
              [128.47396, 35.85791],
              [128.47437, 35.85688],
              [128.47402, 35.85687],
              [128.47368, 35.85593],
              [128.47387, 35.85559],
              [128.4736, 35.85498],
              [128.47334, 35.85458],
              [128.47315, 35.85429],
              [128.47278, 35.85392],
              [128.47291, 35.85478],
              [128.47322, 35.85593],
              [128.47375, 35.85799],
              [128.47376, 35.8595],
              [128.47347, 35.8595],
              [128.47343, 35.85835],
              [128.47313, 35.85764],
              [128.47302, 35.85677],
              [128.47253, 35.85541],
              [128.47242, 35.85507],
              [128.47245, 35.85411],
              [128.47238, 35.85385],
              [128.47208, 35.85391],
              [128.47209, 35.85378],
              [128.47185, 35.85274],
              [128.47171, 35.85143],
              [128.47147, 35.85037],
              [128.47132, 35.84896],
              [128.47129, 35.84852],
              [128.47102, 35.84668],
              [128.4709, 35.8462],
              [128.47076, 35.84525],
              [128.4699, 35.84253],
              [128.46955, 35.84133],
              [128.46884, 35.8387],
              [128.46842, 35.83752],
              [128.46875, 35.8387],
              [128.46624, 35.83871],
              [128.46625, 35.84164],
              [128.46564, 35.84182],
              [128.46536, 35.84188],
              [128.46515, 35.84196],
              [128.46498, 35.84199],
              [128.45619, 35.84202],
              [128.4562, 35.84481],
              [128.45642, 35.84519],
              [128.45621, 35.8453],
              [128.45118, 35.84531],
              [128.45119, 35.8482],
              [128.45083, 35.84839],
              [128.45065, 35.8484],
              [128.45056, 35.84846],
              [128.45043, 35.84859],
              [128.45019, 35.84859],
              [128.45025, 35.84847],
              [128.44988, 35.84859],
              [128.4361, 35.84864],
              [128.43381, 35.84946],
              [128.43107, 35.85006],
              [128.42604, 35.85032],
              [128.42605, 35.85182],
              [128.42564, 35.85194],
              [128.41945, 35.85196],
              [128.41598, 35.85176],
              [128.41096, 35.85189],
              [128.40089, 35.85193],
              [128.3967, 35.85208],
              [128.39514, 35.85227],
              [128.39393, 35.85253],
              [128.39245, 35.8537],
              [128.39084, 35.85488],
              [128.39007, 35.85532],
              [128.38923, 35.8565],
              [128.38835, 35.85794],
              [128.38808, 35.8586],
              [128.38738, 35.8612],
              [128.38738, 35.86188],
              [128.38772, 35.86372],
              [128.38822, 35.86516],
              [128.38859, 35.86718],
              [128.38932, 35.86843],
              [128.3909, 35.86927],
              [128.39373, 35.8717],
              [128.39543, 35.87169],
              [128.3956, 35.87196],
              [128.39589, 35.87263],
              [128.3955, 35.87283],
              [128.39595, 35.87381],
              [128.39599, 35.88317],
              [128.39753, 35.88369],
              [128.39802, 35.88414],
              [128.39883, 35.88502],
              [128.39996, 35.88686],
              [128.40012, 35.88769],
              [128.4015, 35.89091],
              [128.40185, 35.89161],
              [128.40185, 35.89286],
              [128.40229, 35.89336],
              [128.40345, 35.89453],
              [128.40429, 35.89499],
              [128.40496, 35.89524],
              [128.40507, 35.89545],
              [128.4056, 35.89625],
              [128.40568, 35.89639],
              [128.40583, 35.89819],
              [128.40619, 35.89973],
              [128.40656, 35.90091],
              [128.40783, 35.90204],
              [128.40836, 35.90119],
              [128.40861, 35.90214],
              [128.40916, 35.90315],
              [128.40948, 35.90341],
              [128.40958, 35.90346],
              [128.40967, 35.90356],
              [128.40964, 35.90435],
              [128.40989, 35.90511],
              [128.41056, 35.90616],
              [128.41084, 35.90709],
              [128.41094, 35.90712],
              [128.41155, 35.9077],
              [128.41165, 35.90788],
              [128.41174, 35.90823],
              [128.41232, 35.90874],
              [128.41275, 35.90933],
              [128.4136, 35.90971],
              [128.41382, 35.91025],
              [128.4142, 35.91065],
              [128.41453, 35.91166],
              [128.41564, 35.91273],
              [128.41647, 35.91297],
              [128.4172, 35.91307],
              [128.41753, 35.91353],
              [128.41727, 35.91409],
              [128.41702, 35.91489],
              [128.41748, 35.91541],
              [128.41836, 35.91658],
              [128.41914, 35.91705],
              [128.41885, 35.9182],
              [128.41879, 35.91963],
              [128.41871, 35.91987],
              [128.41883, 35.92133],
              [128.41891, 35.92154],
              [128.41908, 35.92153],
              [128.4204, 35.92195],
              [128.42118, 35.92258],
              [128.42216, 35.92225],
              [128.42377, 35.92312],
              [128.42429, 35.92312],
              [128.42443, 35.92322],
              [128.42458, 35.92333],
              [128.42508, 35.92388],
              [128.425, 35.92451],
              [128.42549, 35.92532],
              [128.42535, 35.92579],
              [128.42509, 35.92692],
              [128.42545, 35.92734],
              [128.42571, 35.92824],
              [128.42697, 35.92761],
              [128.42726, 35.92753],
              [128.42744, 35.92746],
              [128.42767, 35.92755],
              [128.42782, 35.92757],
              [128.42793, 35.92762],
              [128.42822, 35.92758],
              [128.42843, 35.92752],
              [128.42854, 35.92756],
              [128.42857, 35.92763],
              [128.42856, 35.92768],
              [128.42867, 35.9279],
              [128.42866, 35.92796],
              [128.42862, 35.92807],
              [128.42841, 35.92833],
              [128.42842, 35.92843],
              [128.42848, 35.92854],
              [128.42856, 35.92864],
              [128.42864, 35.92867],
              [128.42875, 35.92877],
              [128.42884, 35.92886],
              [128.42887, 35.92892],
              [128.42881, 35.92897],
              [128.42877, 35.92905],
              [128.42879, 35.92913],
              [128.42896, 35.92919],
              [128.42914, 35.92937],
              [128.42946, 35.92959],
              [128.42953, 35.92965],
              [128.42959, 35.92968],
              [128.4299, 35.9297],
              [128.43002, 35.92974],
              [128.43019, 35.92978],
              [128.43076, 35.93008],
              [128.43078, 35.93013],
              [128.43083, 35.93018],
              [128.43099, 35.93027],
              [128.43115, 35.93035],
              [128.43127, 35.9304],
              [128.43143, 35.93056],
              [128.43149, 35.93058],
              [128.43178, 35.93066],
              [128.43196, 35.93073],
              [128.43201, 35.93067],
              [128.43215, 35.93065],
              [128.43212, 35.93073],
              [128.43205, 35.93072],
              [128.43202, 35.93083],
              [128.43192, 35.93096],
              [128.43206, 35.93111],
              [128.43217, 35.93117],
              [128.43222, 35.93122],
              [128.43226, 35.93127],
              [128.43233, 35.93142],
              [128.4327, 35.93159],
              [128.43282, 35.9317],
              [128.43287, 35.93172],
              [128.43293, 35.93174],
              [128.43304, 35.93171],
              [128.43319, 35.93157],
              [128.43326, 35.93154],
              [128.43359, 35.9315],
              [128.43361, 35.93136],
              [128.43374, 35.93117],
              [128.434, 35.93117],
              [128.43414, 35.93122],
              [128.43455, 35.93123],
              [128.43453, 35.93128],
              [128.43462, 35.93127],
              [128.43471, 35.93133],
              [128.43475, 35.93139],
              [128.43482, 35.9314],
              [128.43499, 35.93134],
              [128.43519, 35.93134],
              [128.43542, 35.9314],
              [128.4355, 35.93147],
              [128.43556, 35.93148],
              [128.43579, 35.93152],
              [128.43606, 35.9315],
              [128.43621, 35.93157],
              [128.43636, 35.9316],
              [128.43674, 35.93163],
              [128.43679, 35.93177],
              [128.43687, 35.93186],
              [128.43695, 35.93188],
              [128.43721, 35.93184],
              [128.43743, 35.93185],
              [128.4376, 35.93191],
              [128.43812, 35.93205],
              [128.43842, 35.93192],
              [128.43852, 35.93185],
              [128.43935, 35.93165],
              [128.43973, 35.9318],
              [128.44022, 35.93216],
              [128.44045, 35.9323],
              [128.44051, 35.9323],
              [128.44072, 35.93244],
              [128.44077, 35.93255],
              [128.44085, 35.93264],
              [128.44092, 35.93269],
              [128.44087, 35.93272],
              [128.4409, 35.93276],
              [128.442, 35.93537],
              [128.44249, 35.93565],
              [128.44334, 35.93572],
              [128.44373, 35.9353],
              [128.44487, 35.93519],
              [128.44529, 35.93523],
              [128.44629, 35.93565],
              [128.4464, 35.93574],
              [128.44718, 35.9355],
              [128.44877, 35.93528],
              [128.44929, 35.93512],
              [128.45028, 35.93521],
              [128.45117, 35.93536],
              [128.45219, 35.93595],
              [128.45362, 35.93687],
              [128.45362, 35.93763],
              [128.454, 35.9383],
              [128.4546, 35.93914],
              [128.45441, 35.9402],
              [128.45443, 35.94034],
              [128.45453, 35.94108],
              [128.45465, 35.94163],
              [128.45479, 35.94195],
              [128.45476, 35.94257],
              [128.455, 35.9428],
              [128.4551, 35.94338],
              [128.45584, 35.9431],
              [128.45637, 35.94304],
              [128.45707, 35.94235],
              [128.45799, 35.94199],
              [128.45881, 35.94173],
              [128.45928, 35.94136]
            ]
          ]
        ]
      }
    },
    {
      id: '27720',
      type: 'Feature',
      properties: {
        SEC_SGG_CD: '27720',
        SEC_SGG_NM: ['대구광역시', '군위군'],
        COL_SGG_CD: '27000',
        SEC_SGG_SIZE: '2',
        bbox: [128.4104, 36.00738, 128.90043, 36.3272],
        center: [128.64813, 36.17014],
        area: 613308131
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [128.45161, 36.32718],
            [128.45296, 36.32678],
            [128.45376, 36.32716],
            [128.45539, 36.32683],
            [128.45561, 36.32575],
            [128.45555, 36.32556],
            [128.45555, 36.3254],
            [128.4559, 36.32499],
            [128.45772, 36.3246],
            [128.45827, 36.32417],
            [128.46015, 36.32419],
            [128.46094, 36.32333],
            [128.46264, 36.32276],
            [128.46343, 36.32274],
            [128.46458, 36.32269],
            [128.46529, 36.32231],
            [128.4664, 36.32188],
            [128.46754, 36.32192],
            [128.46868, 36.32174],
            [128.46962, 36.32087],
            [128.46934, 36.32066],
            [128.46976, 36.32034],
            [128.4701, 36.32011],
            [128.47101, 36.32092],
            [128.47122, 36.32075],
            [128.47128, 36.32066],
            [128.4713, 36.32057],
            [128.47134, 36.32053],
            [128.47116, 36.32041],
            [128.47116, 36.32029],
            [128.47119, 36.32024],
            [128.47126, 36.32001],
            [128.47138, 36.31983],
            [128.47143, 36.31964],
            [128.47148, 36.31954],
            [128.47167, 36.31928],
            [128.47177, 36.31921],
            [128.47181, 36.31912],
            [128.47183, 36.31901],
            [128.47175, 36.31887],
            [128.47176, 36.3188],
            [128.47173, 36.31865],
            [128.47176, 36.31861],
            [128.47184, 36.3186],
            [128.47187, 36.31856],
            [128.47197, 36.31854],
            [128.47209, 36.31848],
            [128.47221, 36.31844],
            [128.47237, 36.31847],
            [128.4724, 36.31843],
            [128.47247, 36.31841],
            [128.47257, 36.31835],
            [128.47263, 36.31833],
            [128.47291, 36.31817],
            [128.47359, 36.31789],
            [128.4732, 36.31629],
            [128.47314, 36.31561],
            [128.47347, 36.31422],
            [128.47345, 36.31359],
            [128.47324, 36.31216],
            [128.47406, 36.31169],
            [128.47563, 36.31174],
            [128.47618, 36.31137],
            [128.47673, 36.31149],
            [128.47757, 36.31112],
            [128.47916, 36.31102],
            [128.47934, 36.31075],
            [128.47957, 36.31009],
            [128.48079, 36.30983],
            [128.48252, 36.30889],
            [128.48342, 36.30793],
            [128.48428, 36.30757],
            [128.48556, 36.30777],
            [128.48611, 36.30826],
            [128.48718, 36.308],
            [128.48762, 36.30822],
            [128.48915, 36.30736],
            [128.49044, 36.30603],
            [128.49076, 36.30485],
            [128.49093, 36.30437],
            [128.49121, 36.30406],
            [128.49221, 36.30457],
            [128.493, 36.30474],
            [128.49389, 36.30449],
            [128.49456, 36.30412],
            [128.49628, 36.30349],
            [128.4965, 36.30301],
            [128.49759, 36.30319],
            [128.49775, 36.30325],
            [128.49809, 36.30333],
            [128.49864, 36.30356],
            [128.49919, 36.30385],
            [128.49965, 36.30404],
            [128.49987, 36.30412],
            [128.49995, 36.30414],
            [128.50021, 36.30391],
            [128.50208, 36.30384],
            [128.5022, 36.30379],
            [128.50304, 36.30354],
            [128.50398, 36.30331],
            [128.50646, 36.30341],
            [128.50697, 36.30373],
            [128.50847, 36.30328],
            [128.50902, 36.30213],
            [128.51065, 36.30066],
            [128.51156, 36.3012],
            [128.51191, 36.30133],
            [128.51307, 36.30087],
            [128.51417, 36.30119],
            [128.51494, 36.30202],
            [128.51578, 36.30182],
            [128.51793, 36.3019],
            [128.51845, 36.30111],
            [128.5186, 36.30093],
            [128.51951, 36.30022],
            [128.52043, 36.30006],
            [128.52171, 36.30046],
            [128.52305, 36.29955],
            [128.52481, 36.29994],
            [128.52575, 36.29927],
            [128.52637, 36.29798],
            [128.52639, 36.29703],
            [128.52739, 36.29606],
            [128.52827, 36.29516],
            [128.5291, 36.29375],
            [128.52964, 36.29379],
            [128.53168, 36.29447],
            [128.53263, 36.29488],
            [128.53337, 36.2951],
            [128.53382, 36.29506],
            [128.5347, 36.29536],
            [128.53566, 36.29585],
            [128.53589, 36.29562],
            [128.53612, 36.29487],
            [128.53754, 36.29449],
            [128.53851, 36.29437],
            [128.53898, 36.29464],
            [128.5398, 36.29477],
            [128.5417, 36.29421],
            [128.54283, 36.2939],
            [128.5432, 36.29318],
            [128.54336, 36.29292],
            [128.54456, 36.29234],
            [128.54553, 36.29192],
            [128.54534, 36.29071],
            [128.54565, 36.29054],
            [128.54574, 36.29025],
            [128.5474, 36.28953],
            [128.54786, 36.28886],
            [128.54813, 36.28768],
            [128.54818, 36.28703],
            [128.54868, 36.2868],
            [128.54907, 36.2862],
            [128.54901, 36.28563],
            [128.54889, 36.28478],
            [128.55001, 36.2843],
            [128.54993, 36.28279],
            [128.55009, 36.28135],
            [128.55011, 36.28096],
            [128.55002, 36.27994],
            [128.55008, 36.27936],
            [128.55111, 36.27852],
            [128.55209, 36.27802],
            [128.55174, 36.27746],
            [128.55198, 36.27672],
            [128.55183, 36.27623],
            [128.55192, 36.27498],
            [128.55275, 36.27459],
            [128.55367, 36.27434],
            [128.55483, 36.27512],
            [128.55563, 36.27425],
            [128.55756, 36.27408],
            [128.55892, 36.27476],
            [128.55925, 36.27505],
            [128.56021, 36.27558],
            [128.56064, 36.27558],
            [128.56149, 36.27547],
            [128.56246, 36.27541],
            [128.56284, 36.27543],
            [128.56293, 36.27534],
            [128.56308, 36.2753],
            [128.56329, 36.27534],
            [128.56338, 36.27542],
            [128.56332, 36.27572],
            [128.56336, 36.27585],
            [128.56446, 36.27549],
            [128.56534, 36.27507],
            [128.566, 36.27448],
            [128.56606, 36.27446],
            [128.56621, 36.27436],
            [128.56677, 36.27424],
            [128.56789, 36.27451],
            [128.56852, 36.27446],
            [128.56988, 36.27465],
            [128.57119, 36.27506],
            [128.5717, 36.27537],
            [128.57151, 36.276],
            [128.57317, 36.27649],
            [128.5736, 36.27685],
            [128.57399, 36.27731],
            [128.57415, 36.27839],
            [128.57543, 36.27914],
            [128.57581, 36.2799],
            [128.57713, 36.27965],
            [128.57721, 36.27977],
            [128.57735, 36.27974],
            [128.57745, 36.27977],
            [128.57758, 36.27976],
            [128.57767, 36.27969],
            [128.57797, 36.2797],
            [128.57806, 36.27974],
            [128.57832, 36.27971],
            [128.57835, 36.27959],
            [128.5785, 36.27965],
            [128.57875, 36.27965],
            [128.57878, 36.27954],
            [128.57889, 36.27955],
            [128.579, 36.27954],
            [128.57919, 36.27944],
            [128.57929, 36.27944],
            [128.57954, 36.27933],
            [128.57963, 36.27933],
            [128.57988, 36.2793],
            [128.58005, 36.27933],
            [128.5801, 36.27926],
            [128.58014, 36.2793],
            [128.58054, 36.27942],
            [128.58064, 36.27948],
            [128.58089, 36.2794],
            [128.58098, 36.27938],
            [128.58098, 36.27944],
            [128.58154, 36.27928],
            [128.58202, 36.27905],
            [128.58245, 36.27909],
            [128.58266, 36.27906],
            [128.58276, 36.27901],
            [128.58281, 36.27908],
            [128.58326, 36.27869],
            [128.58366, 36.27869],
            [128.58388, 36.27879],
            [128.58417, 36.2788],
            [128.58474, 36.27867],
            [128.58486, 36.27868],
            [128.58493, 36.27864],
            [128.58502, 36.27867],
            [128.58553, 36.27822],
            [128.58642, 36.27827],
            [128.58684, 36.27844],
            [128.58788, 36.27829],
            [128.58883, 36.27769],
            [128.5889, 36.27725],
            [128.58963, 36.2773],
            [128.59076, 36.27713],
            [128.59159, 36.27762],
            [128.59243, 36.2774],
            [128.59314, 36.27708],
            [128.59463, 36.27771],
            [128.59473, 36.27755],
            [128.59572, 36.27701],
            [128.59665, 36.27551],
            [128.59684, 36.27388],
            [128.59759, 36.27328],
            [128.5978, 36.27269],
            [128.59757, 36.27225],
            [128.5975, 36.27173],
            [128.59769, 36.27129],
            [128.59934, 36.2705],
            [128.60025, 36.2703],
            [128.60081, 36.2704],
            [128.60171, 36.27005],
            [128.60331, 36.26989],
            [128.60516, 36.27021],
            [128.60682, 36.27111],
            [128.60871, 36.2711],
            [128.60901, 36.27123],
            [128.61033, 36.27072],
            [128.61157, 36.27003],
            [128.61361, 36.26959],
            [128.61494, 36.26846],
            [128.61527, 36.26794],
            [128.61633, 36.26732],
            [128.6174, 36.2675],
            [128.619, 36.26805],
            [128.61958, 36.26811],
            [128.61998, 36.26793],
            [128.62057, 36.26749],
            [128.62121, 36.26731],
            [128.623, 36.26719],
            [128.62382, 36.26701],
            [128.62396, 36.26692],
            [128.62443, 36.26675],
            [128.62494, 36.26648],
            [128.62552, 36.26612],
            [128.62592, 36.26574],
            [128.62599, 36.26563],
            [128.62701, 36.26523],
            [128.6273, 36.2649],
            [128.62778, 36.26453],
            [128.62801, 36.26441],
            [128.62818, 36.26438],
            [128.62902, 36.26378],
            [128.62926, 36.26343],
            [128.62951, 36.26329],
            [128.62992, 36.26242],
            [128.63064, 36.26256],
            [128.63125, 36.26288],
            [128.63152, 36.26277],
            [128.63196, 36.26224],
            [128.63225, 36.26211],
            [128.63256, 36.26191],
            [128.63317, 36.26193],
            [128.63342, 36.26188],
            [128.63396, 36.26167],
            [128.63461, 36.26158],
            [128.63568, 36.26156],
            [128.63611, 36.2615],
            [128.63704, 36.26057],
            [128.63716, 36.25988],
            [128.63769, 36.25982],
            [128.63816, 36.25916],
            [128.63903, 36.2585],
            [128.63974, 36.25831],
            [128.64057, 36.25747],
            [128.64015, 36.25631],
            [128.63999, 36.25567],
            [128.64047, 36.25443],
            [128.64052, 36.25317],
            [128.63982, 36.25249],
            [128.6397, 36.25198],
            [128.63899, 36.25193],
            [128.6385, 36.25187],
            [128.63869, 36.25103],
            [128.63863, 36.25007],
            [128.63839, 36.24938],
            [128.63878, 36.24874],
            [128.63881, 36.24846],
            [128.63867, 36.24793],
            [128.63878, 36.24778],
            [128.63897, 36.24773],
            [128.63908, 36.24766],
            [128.63962, 36.24748],
            [128.6399, 36.2471],
            [128.64002, 36.24655],
            [128.64024, 36.24617],
            [128.64065, 36.24585],
            [128.64076, 36.24559],
            [128.64136, 36.24485],
            [128.63982, 36.24448],
            [128.63968, 36.24384],
            [128.63979, 36.24377],
            [128.6399, 36.24373],
            [128.6399, 36.24351],
            [128.6396, 36.24356],
            [128.63913, 36.24333],
            [128.63908, 36.24323],
            [128.63898, 36.24317],
            [128.63891, 36.24305],
            [128.63939, 36.24134],
            [128.63907, 36.24125],
            [128.63861, 36.24105],
            [128.63745, 36.24077],
            [128.63649, 36.24039],
            [128.63574, 36.24033],
            [128.63454, 36.23939],
            [128.63368, 36.23883],
            [128.63315, 36.23864],
            [128.63406, 36.23782],
            [128.63467, 36.23743],
            [128.63483, 36.23728],
            [128.63477, 36.2369],
            [128.63437, 36.23636],
            [128.6353, 36.23561],
            [128.63526, 36.23527],
            [128.63556, 36.23477],
            [128.63643, 36.23361],
            [128.6367, 36.23308],
            [128.63615, 36.23216],
            [128.63598, 36.232],
            [128.63513, 36.23131],
            [128.63451, 36.23106],
            [128.63502, 36.23035],
            [128.63532, 36.23008],
            [128.6368, 36.22977],
            [128.63699, 36.22966],
            [128.63708, 36.22948],
            [128.63721, 36.22842],
            [128.63719, 36.22792],
            [128.63712, 36.22754],
            [128.63643, 36.22694],
            [128.63457, 36.22595],
            [128.63295, 36.22546],
            [128.63322, 36.22508],
            [128.63463, 36.22471],
            [128.6363, 36.22385],
            [128.63609, 36.2225],
            [128.63589, 36.22082],
            [128.63513, 36.21916],
            [128.63489, 36.21844],
            [128.63385, 36.21742],
            [128.63204, 36.21688],
            [128.63206, 36.21658],
            [128.63186, 36.21622],
            [128.63312, 36.2148],
            [128.63356, 36.21421],
            [128.63434, 36.21367],
            [128.63455, 36.21294],
            [128.63459, 36.21286],
            [128.63469, 36.21288],
            [128.63496, 36.21279],
            [128.63489, 36.21255],
            [128.63487, 36.21247],
            [128.63482, 36.21236],
            [128.63472, 36.21219],
            [128.63468, 36.21213],
            [128.6346, 36.21211],
            [128.63461, 36.21191],
            [128.63545, 36.20896],
            [128.6361, 36.2088],
            [128.6367, 36.20864],
            [128.63786, 36.20826],
            [128.63945, 36.20751],
            [128.6412, 36.20972],
            [128.6419, 36.21072],
            [128.64223, 36.2116],
            [128.64245, 36.21205],
            [128.64384, 36.21302],
            [128.64401, 36.21358],
            [128.64407, 36.21366],
            [128.64418, 36.2137],
            [128.64435, 36.2137],
            [128.6445, 36.21369],
            [128.64456, 36.21372],
            [128.64483, 36.21389],
            [128.64511, 36.21412],
            [128.64535, 36.21426],
            [128.64561, 36.21452],
            [128.64632, 36.21473],
            [128.6468, 36.2153],
            [128.64745, 36.21596],
            [128.64732, 36.21672],
            [128.6479, 36.21715],
            [128.64874, 36.21809],
            [128.64903, 36.21806],
            [128.65035, 36.21754],
            [128.65139, 36.2176],
            [128.65196, 36.21713],
            [128.65298, 36.2172],
            [128.65421, 36.21807],
            [128.65563, 36.21842],
            [128.65588, 36.21901],
            [128.65575, 36.2198],
            [128.65564, 36.22013],
            [128.65691, 36.22008],
            [128.65831, 36.22019],
            [128.65904, 36.22017],
            [128.65968, 36.22133],
            [128.66104, 36.22091],
            [128.66251, 36.22031],
            [128.66239, 36.22011],
            [128.66243, 36.21895],
            [128.66292, 36.21896],
            [128.66462, 36.2189],
            [128.66451, 36.21818],
            [128.66455, 36.21768],
            [128.66591, 36.21689],
            [128.66538, 36.21573],
            [128.66577, 36.2156],
            [128.66614, 36.21545],
            [128.66756, 36.21471],
            [128.66755, 36.21462],
            [128.66772, 36.21352],
            [128.66763, 36.21301],
            [128.66742, 36.21214],
            [128.66788, 36.21157],
            [128.66805, 36.21146],
            [128.66815, 36.21135],
            [128.66832, 36.2113],
            [128.66856, 36.21087],
            [128.66874, 36.21062],
            [128.66921, 36.20955],
            [128.66926, 36.20949],
            [128.66951, 36.20926],
            [128.66957, 36.20925],
            [128.66962, 36.20929],
            [128.6698, 36.20922],
            [128.66995, 36.20923],
            [128.67003, 36.20914],
            [128.67015, 36.20907],
            [128.67032, 36.20902],
            [128.67046, 36.20899],
            [128.67067, 36.20902],
            [128.6709, 36.20887],
            [128.67103, 36.2088],
            [128.67111, 36.20872],
            [128.67138, 36.20854],
            [128.67149, 36.20855],
            [128.67169, 36.20847],
            [128.67176, 36.20838],
            [128.67193, 36.20834],
            [128.6719, 36.20815],
            [128.67199, 36.20813],
            [128.67214, 36.20809],
            [128.67237, 36.20837],
            [128.67272, 36.20907],
            [128.67282, 36.20993],
            [128.67282, 36.2101],
            [128.67277, 36.21015],
            [128.67302, 36.2103],
            [128.67324, 36.2111],
            [128.67385, 36.212],
            [128.67463, 36.21234],
            [128.67476, 36.21279],
            [128.6753, 36.21276],
            [128.67617, 36.2128],
            [128.67697, 36.2136],
            [128.6778, 36.21357],
            [128.67932, 36.2141],
            [128.68013, 36.21428],
            [128.68066, 36.21461],
            [128.68155, 36.21483],
            [128.682, 36.21531],
            [128.68275, 36.21536],
            [128.68348, 36.21497],
            [128.68387, 36.21479],
            [128.68468, 36.21475],
            [128.68514, 36.2148],
            [128.68574, 36.21422],
            [128.688, 36.21383],
            [128.6886, 36.2132],
            [128.68881, 36.21276],
            [128.68908, 36.21264],
            [128.6893, 36.2126],
            [128.68946, 36.21253],
            [128.69022, 36.21223],
            [128.69117, 36.21264],
            [128.69156, 36.21258],
            [128.6917, 36.21266],
            [128.69205, 36.21301],
            [128.69225, 36.21331],
            [128.69303, 36.21389],
            [128.69419, 36.21402],
            [128.69499, 36.21316],
            [128.69625, 36.21284],
            [128.69698, 36.21194],
            [128.69735, 36.21174],
            [128.69811, 36.2117],
            [128.69816, 36.2114],
            [128.69784, 36.21076],
            [128.69781, 36.21043],
            [128.69788, 36.21028],
            [128.69991, 36.20881],
            [128.69894, 36.20782],
            [128.69835, 36.20772],
            [128.69797, 36.2061],
            [128.69787, 36.2059],
            [128.6974, 36.2057],
            [128.69701, 36.20533],
            [128.69681, 36.20517],
            [128.69663, 36.20499],
            [128.69642, 36.20457],
            [128.69628, 36.2044],
            [128.69628, 36.20298],
            [128.69626, 36.20281],
            [128.69634, 36.20222],
            [128.69921, 36.20123],
            [128.70009, 36.20134],
            [128.70104, 36.20117],
            [128.7014, 36.20121],
            [128.70175, 36.20105],
            [128.70196, 36.2011],
            [128.70215, 36.20071],
            [128.70297, 36.20028],
            [128.70373, 36.20029],
            [128.70408, 36.19918],
            [128.70428, 36.19904],
            [128.70461, 36.19911],
            [128.70586, 36.19927],
            [128.70677, 36.19908],
            [128.70674, 36.19901],
            [128.7075, 36.19888],
            [128.7086, 36.1986],
            [128.70959, 36.1988],
            [128.71008, 36.19897],
            [128.71117, 36.19947],
            [128.71223, 36.19913],
            [128.71355, 36.20006],
            [128.71536, 36.20012],
            [128.71672, 36.20044],
            [128.71794, 36.20001],
            [128.71868, 36.20009],
            [128.71914, 36.19867],
            [128.71918, 36.19808],
            [128.72104, 36.19808],
            [128.72172, 36.1986],
            [128.72258, 36.19914],
            [128.72342, 36.1991],
            [128.72369, 36.19906],
            [128.72392, 36.19891],
            [128.72498, 36.19926],
            [128.72553, 36.19983],
            [128.72541, 36.20001],
            [128.72499, 36.2003],
            [128.72476, 36.2005],
            [128.72555, 36.20074],
            [128.72639, 36.20033],
            [128.72687, 36.20045],
            [128.72746, 36.20074],
            [128.72821, 36.19997],
            [128.72877, 36.19956],
            [128.72927, 36.19896],
            [128.72953, 36.19878],
            [128.7298, 36.19885],
            [128.73085, 36.19928],
            [128.73158, 36.19924],
            [128.73244, 36.19937],
            [128.7331, 36.19966],
            [128.73431, 36.20034],
            [128.73503, 36.20069],
            [128.73555, 36.20015],
            [128.73656, 36.20005],
            [128.73693, 36.20028],
            [128.73716, 36.20022],
            [128.73766, 36.2],
            [128.73789, 36.19993],
            [128.73845, 36.19992],
            [128.73925, 36.19923],
            [128.73993, 36.19909],
            [128.74038, 36.19919],
            [128.7405, 36.19918],
            [128.74179, 36.19852],
            [128.74311, 36.19742],
            [128.74312, 36.19679],
            [128.74329, 36.19621],
            [128.74408, 36.19596],
            [128.74441, 36.19566],
            [128.74463, 36.19555],
            [128.74518, 36.19493],
            [128.74552, 36.19475],
            [128.74597, 36.1944],
            [128.74712, 36.19493],
            [128.74742, 36.19521],
            [128.74876, 36.19562],
            [128.74962, 36.19596],
            [128.74988, 36.19675],
            [128.75044, 36.1971],
            [128.75057, 36.19787],
            [128.75058, 36.19806],
            [128.75025, 36.1986],
            [128.75001, 36.19887],
            [128.75085, 36.19981],
            [128.75145, 36.20005],
            [128.7516, 36.20036],
            [128.75186, 36.20122],
            [128.75168, 36.20167],
            [128.75278, 36.20283],
            [128.75429, 36.20263],
            [128.75507, 36.20245],
            [128.7557, 36.2026],
            [128.75616, 36.20246],
            [128.75801, 36.20285],
            [128.75868, 36.20163],
            [128.75861, 36.20121],
            [128.75891, 36.20074],
            [128.75968, 36.20019],
            [128.76087, 36.19963],
            [128.76144, 36.19937],
            [128.7634, 36.19934],
            [128.76474, 36.19803],
            [128.76437, 36.19751],
            [128.76512, 36.19754],
            [128.7658, 36.19696],
            [128.76585, 36.19663],
            [128.76655, 36.19604],
            [128.7675, 36.19536],
            [128.76933, 36.19379],
            [128.76993, 36.19344],
            [128.7724, 36.19472],
            [128.77285, 36.19484],
            [128.7749, 36.19488],
            [128.77555, 36.19452],
            [128.777, 36.19449],
            [128.77743, 36.19435],
            [128.77776, 36.19426],
            [128.7801, 36.19423],
            [128.78072, 36.19436],
            [128.78198, 36.19373],
            [128.7829, 36.19369],
            [128.78424, 36.19384],
            [128.78533, 36.19353],
            [128.78772, 36.1931],
            [128.78938, 36.19245],
            [128.79207, 36.19133],
            [128.7936, 36.19091],
            [128.79439, 36.19071],
            [128.79645, 36.19082],
            [128.79799, 36.19345],
            [128.79845, 36.19459],
            [128.79886, 36.19604],
            [128.80152, 36.19787],
            [128.80176, 36.19837],
            [128.80234, 36.19941],
            [128.80321, 36.20105],
            [128.80329, 36.20172],
            [128.80338, 36.20208],
            [128.80319, 36.20252],
            [128.80379, 36.20318],
            [128.8029, 36.20419],
            [128.80229, 36.20459],
            [128.80164, 36.20579],
            [128.80337, 36.20626],
            [128.80523, 36.207],
            [128.80456, 36.20826],
            [128.80419, 36.20971],
            [128.80496, 36.21021],
            [128.80679, 36.21006],
            [128.8079, 36.21006],
            [128.80996, 36.21022],
            [128.81093, 36.21032],
            [128.81144, 36.21004],
            [128.81233, 36.21047],
            [128.81339, 36.2105],
            [128.81381, 36.21068],
            [128.81423, 36.21096],
            [128.81483, 36.21155],
            [128.81563, 36.21213],
            [128.81637, 36.21307],
            [128.81685, 36.21336],
            [128.81716, 36.21382],
            [128.81783, 36.21397],
            [128.81833, 36.2144],
            [128.81848, 36.2148],
            [128.81923, 36.2152],
            [128.81974, 36.21542],
            [128.82035, 36.21545],
            [128.82117, 36.21592],
            [128.82181, 36.2165],
            [128.82287, 36.21707],
            [128.82343, 36.21715],
            [128.82379, 36.21724],
            [128.82528, 36.21793],
            [128.82656, 36.21826],
            [128.82866, 36.21792],
            [128.83068, 36.21799],
            [128.83116, 36.21733],
            [128.83178, 36.21668],
            [128.83257, 36.21562],
            [128.83381, 36.21485],
            [128.83426, 36.21455],
            [128.83538, 36.21452],
            [128.83645, 36.21512],
            [128.83899, 36.21511],
            [128.84056, 36.21555],
            [128.84212, 36.21614],
            [128.84317, 36.2162],
            [128.84366, 36.21557],
            [128.84563, 36.21456],
            [128.84799, 36.21484],
            [128.84853, 36.21473],
            [128.84981, 36.2134],
            [128.85135, 36.21264],
            [128.85265, 36.21266],
            [128.85322, 36.21163],
            [128.85522, 36.21056],
            [128.85719, 36.20968],
            [128.85757, 36.20865],
            [128.85816, 36.20815],
            [128.85844, 36.2077],
            [128.85942, 36.20714],
            [128.86028, 36.20701],
            [128.86158, 36.20737],
            [128.86368, 36.20674],
            [128.86586, 36.20546],
            [128.86713, 36.2055],
            [128.86862, 36.20561],
            [128.87113, 36.20525],
            [128.87211, 36.20349],
            [128.87306, 36.20214],
            [128.87438, 36.19977],
            [128.87605, 36.19869],
            [128.87714, 36.19869],
            [128.87857, 36.19882],
            [128.88073, 36.19883],
            [128.88193, 36.19867],
            [128.88315, 36.19841],
            [128.88251, 36.19701],
            [128.8823, 36.19602],
            [128.88228, 36.19545],
            [128.88248, 36.19368],
            [128.88233, 36.19245],
            [128.88124, 36.19165],
            [128.88077, 36.19023],
            [128.88339, 36.18845],
            [128.88408, 36.1853],
            [128.88373, 36.18483],
            [128.88359, 36.18393],
            [128.88496, 36.18405],
            [128.88606, 36.18383],
            [128.88616, 36.18377],
            [128.88658, 36.18336],
            [128.88774, 36.18301],
            [128.88914, 36.18233],
            [128.88923, 36.18214],
            [128.88982, 36.18211],
            [128.89017, 36.18187],
            [128.89051, 36.18109],
            [128.89097, 36.18076],
            [128.89125, 36.17856],
            [128.89118, 36.17798],
            [128.89115, 36.17663],
            [128.89095, 36.17621],
            [128.89169, 36.17537],
            [128.89252, 36.17508],
            [128.89341, 36.17417],
            [128.89372, 36.17417],
            [128.89516, 36.17304],
            [128.89508, 36.17019],
            [128.89474, 36.16922],
            [128.89586, 36.16745],
            [128.8981, 36.16489],
            [128.89857, 36.16455],
            [128.89957, 36.1631],
            [128.90043, 36.16236],
            [128.89954, 36.16192],
            [128.89771, 36.1608],
            [128.89732, 36.16046],
            [128.89563, 36.15975],
            [128.89471, 36.15842],
            [128.8947, 36.15835],
            [128.89358, 36.1563],
            [128.89239, 36.15522],
            [128.89181, 36.15501],
            [128.89186, 36.15455],
            [128.8924, 36.15382],
            [128.89235, 36.15204],
            [128.89216, 36.15163],
            [128.89151, 36.15108],
            [128.89113, 36.15011],
            [128.89115, 36.14954],
            [128.89084, 36.14892],
            [128.8908, 36.14861],
            [128.89091, 36.14824],
            [128.8902, 36.1472],
            [128.89036, 36.14612],
            [128.8908, 36.14555],
            [128.89106, 36.14493],
            [128.89116, 36.14436],
            [128.89177, 36.1434],
            [128.89028, 36.14293],
            [128.88991, 36.14249],
            [128.88755, 36.14281],
            [128.88677, 36.14262],
            [128.88596, 36.1429],
            [128.88469, 36.14347],
            [128.88365, 36.1429],
            [128.88294, 36.14255],
            [128.88181, 36.14272],
            [128.88052, 36.1427],
            [128.88004, 36.14251],
            [128.87912, 36.14253],
            [128.8784, 36.14261],
            [128.87798, 36.14263],
            [128.87666, 36.14261],
            [128.8743, 36.14283],
            [128.87354, 36.14312],
            [128.87218, 36.1439],
            [128.8715, 36.1441],
            [128.87018, 36.14467],
            [128.86972, 36.1448],
            [128.86888, 36.14483],
            [128.8681, 36.14525],
            [128.86659, 36.14476],
            [128.86493, 36.14435],
            [128.86378, 36.14467],
            [128.86237, 36.14496],
            [128.86182, 36.1452],
            [128.86024, 36.14515],
            [128.85921, 36.14493],
            [128.85882, 36.14509],
            [128.8586, 36.14497],
            [128.85691, 36.14256],
            [128.85661, 36.1419],
            [128.85618, 36.14139],
            [128.85364, 36.1393],
            [128.85215, 36.13836],
            [128.85095, 36.13804],
            [128.8503, 36.13644],
            [128.85053, 36.13593],
            [128.85051, 36.13457],
            [128.85065, 36.13431],
            [128.85161, 36.13233],
            [128.8522, 36.13199],
            [128.85287, 36.13047],
            [128.85339, 36.12844],
            [128.85339, 36.12705],
            [128.85232, 36.12661],
            [128.8516, 36.12523],
            [128.85089, 36.1242],
            [128.85161, 36.1233],
            [128.85253, 36.12124],
            [128.85228, 36.12081],
            [128.85221, 36.12046],
            [128.85093, 36.1182],
            [128.85048, 36.11796],
            [128.84798, 36.11708],
            [128.84778, 36.11689],
            [128.84611, 36.11667],
            [128.84509, 36.11631],
            [128.84398, 36.11484],
            [128.84445, 36.11397],
            [128.84394, 36.11319],
            [128.84319, 36.11291],
            [128.84116, 36.11336],
            [128.84044, 36.11313],
            [128.83957, 36.11334],
            [128.8391, 36.11324],
            [128.83775, 36.11302],
            [128.83722, 36.11169],
            [128.837, 36.11057],
            [128.83661, 36.1097],
            [128.83682, 36.10905],
            [128.83677, 36.10876],
            [128.83688, 36.10785],
            [128.83644, 36.1072],
            [128.83647, 36.10709],
            [128.83527, 36.10552],
            [128.83492, 36.10539],
            [128.83448, 36.10421],
            [128.83404, 36.10368],
            [128.83373, 36.10319],
            [128.83262, 36.10199],
            [128.83168, 36.10249],
            [128.83078, 36.10265],
            [128.83037, 36.10281],
            [128.82986, 36.10275],
            [128.82906, 36.10237],
            [128.82851, 36.10189],
            [128.82789, 36.10166],
            [128.82753, 36.10141],
            [128.826, 36.10116],
            [128.82542, 36.10091],
            [128.82441, 36.10088],
            [128.82362, 36.10034],
            [128.82228, 36.10047],
            [128.82198, 36.10028],
            [128.82008, 36.09861],
            [128.81927, 36.09831],
            [128.81826, 36.09691],
            [128.81816, 36.09644],
            [128.81831, 36.09611],
            [128.81845, 36.09541],
            [128.81888, 36.09455],
            [128.81883, 36.09414],
            [128.81784, 36.09387],
            [128.81703, 36.0933],
            [128.81639, 36.09231],
            [128.81459, 36.09192],
            [128.81363, 36.09177],
            [128.81233, 36.09064],
            [128.81152, 36.09018],
            [128.81131, 36.08933],
            [128.81064, 36.08904],
            [128.80871, 36.08867],
            [128.80829, 36.08841],
            [128.80767, 36.08827],
            [128.80674, 36.08824],
            [128.8062, 36.08814],
            [128.80586, 36.0882],
            [128.80494, 36.08814],
            [128.80478, 36.08805],
            [128.8045, 36.08808],
            [128.80287, 36.08652],
            [128.80209, 36.08597],
            [128.79853, 36.08532],
            [128.79773, 36.08623],
            [128.79613, 36.08695],
            [128.79523, 36.08706],
            [128.79315, 36.08672],
            [128.79229, 36.08664],
            [128.79154, 36.08677],
            [128.79051, 36.08665],
            [128.78942, 36.08623],
            [128.78898, 36.08593],
            [128.78848, 36.08614],
            [128.78799, 36.08801],
            [128.78762, 36.08914],
            [128.78735, 36.08959],
            [128.78629, 36.08995],
            [128.78562, 36.09038],
            [128.78524, 36.09075],
            [128.78477, 36.091],
            [128.78421, 36.09118],
            [128.7836, 36.09125],
            [128.78252, 36.09183],
            [128.78126, 36.09202],
            [128.7801, 36.09323],
            [128.77648, 36.09567],
            [128.77611, 36.09572],
            [128.77581, 36.09562],
            [128.7749, 36.09525],
            [128.77468, 36.09544],
            [128.77462, 36.09553],
            [128.77408, 36.09584],
            [128.77071, 36.09675],
            [128.76943, 36.09691],
            [128.76928, 36.09713],
            [128.76939, 36.09718],
            [128.7682, 36.09867],
            [128.7672, 36.09828],
            [128.76721, 36.09854],
            [128.76719, 36.09889],
            [128.76729, 36.09917],
            [128.76729, 36.09952],
            [128.76685, 36.10018],
            [128.76553, 36.10054],
            [128.76452, 36.10066],
            [128.76398, 36.10007],
            [128.76335, 36.0998],
            [128.76187, 36.09892],
            [128.76158, 36.09866],
            [128.75949, 36.09757],
            [128.75858, 36.09684],
            [128.75755, 36.09567],
            [128.7573, 36.09475],
            [128.75732, 36.09402],
            [128.75501, 36.09268],
            [128.7547, 36.09226],
            [128.75367, 36.09078],
            [128.75271, 36.09153],
            [128.75186, 36.0918],
            [128.75091, 36.0918],
            [128.75026, 36.09207],
            [128.75184, 36.09412],
            [128.75275, 36.09583],
            [128.75257, 36.09651],
            [128.75221, 36.09698],
            [128.75219, 36.09733],
            [128.75282, 36.09832],
            [128.75263, 36.09901],
            [128.7514, 36.09916],
            [128.75143, 36.09921],
            [128.75145, 36.09964],
            [128.75134, 36.10012],
            [128.75128, 36.10044],
            [128.75145, 36.10127],
            [128.75176, 36.10187],
            [128.7517, 36.10261],
            [128.75164, 36.10305],
            [128.75129, 36.10336],
            [128.75103, 36.10366],
            [128.75046, 36.10389],
            [128.75022, 36.10427],
            [128.74996, 36.10455],
            [128.74956, 36.10522],
            [128.74892, 36.10566],
            [128.74882, 36.10572],
            [128.74834, 36.10643],
            [128.74779, 36.10653],
            [128.74704, 36.10683],
            [128.74689, 36.10801],
            [128.74573, 36.10903],
            [128.74501, 36.10931],
            [128.74514, 36.10788],
            [128.74494, 36.10735],
            [128.74473, 36.10579],
            [128.74429, 36.10545],
            [128.74451, 36.10487],
            [128.74437, 36.10413],
            [128.74411, 36.10365],
            [128.74376, 36.10335],
            [128.74413, 36.10235],
            [128.74375, 36.102],
            [128.74255, 36.10164],
            [128.74225, 36.10144],
            [128.74188, 36.10081],
            [128.74024, 36.09963],
            [128.7398, 36.09957],
            [128.73936, 36.09965],
            [128.73885, 36.09926],
            [128.73841, 36.09923],
            [128.73823, 36.09912],
            [128.73634, 36.09941],
            [128.73618, 36.09937],
            [128.73579, 36.09914],
            [128.7356, 36.09848],
            [128.73537, 36.09817],
            [128.73586, 36.0977],
            [128.73601, 36.09741],
            [128.73611, 36.09701],
            [128.73589, 36.09667],
            [128.73529, 36.09558],
            [128.73421, 36.09503],
            [128.73361, 36.09426],
            [128.73062, 36.09298],
            [128.73055, 36.09285],
            [128.73044, 36.09277],
            [128.72909, 36.09259],
            [128.72914, 36.09269],
            [128.72898, 36.09275],
            [128.72891, 36.09271],
            [128.72866, 36.09281],
            [128.72846, 36.09279],
            [128.72827, 36.09283],
            [128.72812, 36.09277],
            [128.72781, 36.09281],
            [128.72772, 36.09279],
            [128.72755, 36.09282],
            [128.72745, 36.09276],
            [128.72739, 36.09276],
            [128.72733, 36.0928],
            [128.72729, 36.09285],
            [128.72732, 36.09297],
            [128.72725, 36.09308],
            [128.72717, 36.09315],
            [128.72711, 36.09317],
            [128.72697, 36.09317],
            [128.72674, 36.09327],
            [128.72654, 36.09323],
            [128.72629, 36.09306],
            [128.72614, 36.093],
            [128.72603, 36.09299],
            [128.72593, 36.09296],
            [128.72577, 36.09281],
            [128.72573, 36.09275],
            [128.72556, 36.0926],
            [128.72552, 36.09255],
            [128.72548, 36.09246],
            [128.72527, 36.09235],
            [128.72514, 36.09224],
            [128.72504, 36.09219],
            [128.72479, 36.09214],
            [128.72457, 36.09212],
            [128.72439, 36.09202],
            [128.72424, 36.09168],
            [128.72407, 36.09152],
            [128.7239, 36.09142],
            [128.72384, 36.09137],
            [128.72364, 36.09108],
            [128.7234, 36.09072],
            [128.72336, 36.09066],
            [128.72329, 36.09055],
            [128.7232, 36.09054],
            [128.72301, 36.09039],
            [128.7229, 36.09022],
            [128.72407, 36.0896],
            [128.72439, 36.08936],
            [128.72436, 36.08929],
            [128.72425, 36.08933],
            [128.7239, 36.08894],
            [128.72388, 36.08888],
            [128.72389, 36.08875],
            [128.72561, 36.08776],
            [128.72561, 36.08708],
            [128.72749, 36.08681],
            [128.72805, 36.08564],
            [128.72819, 36.08539],
            [128.7281, 36.08511],
            [128.72793, 36.0848],
            [128.72805, 36.0843],
            [128.72857, 36.08411],
            [128.72861, 36.08385],
            [128.72871, 36.08354],
            [128.7293, 36.08274],
            [128.72906, 36.08236],
            [128.7292, 36.08197],
            [128.72939, 36.08097],
            [128.72885, 36.08068],
            [128.72862, 36.08028],
            [128.72813, 36.07991],
            [128.72752, 36.07917],
            [128.72669, 36.07868],
            [128.7269, 36.07707],
            [128.72555, 36.07584],
            [128.72461, 36.07573],
            [128.72483, 36.07451],
            [128.72409, 36.07398],
            [128.7238, 36.07374],
            [128.72372, 36.07352],
            [128.72283, 36.07329],
            [128.72144, 36.07313],
            [128.72101, 36.07376],
            [128.71964, 36.07404],
            [128.71926, 36.07428],
            [128.71885, 36.07491],
            [128.71783, 36.0745],
            [128.71628, 36.07519],
            [128.71548, 36.07516],
            [128.71518, 36.07536],
            [128.71404, 36.07548],
            [128.71376, 36.07521],
            [128.71384, 36.07452],
            [128.71367, 36.07429],
            [128.71328, 36.07396],
            [128.71304, 36.07336],
            [128.71281, 36.07292],
            [128.71322, 36.07181],
            [128.71315, 36.07164],
            [128.71313, 36.07154],
            [128.71306, 36.07153],
            [128.71223, 36.07085],
            [128.71231, 36.07026],
            [128.71231, 36.07002],
            [128.71222, 36.06951],
            [128.71194, 36.06887],
            [128.71186, 36.06882],
            [128.71179, 36.06875],
            [128.71167, 36.0685],
            [128.71146, 36.06782],
            [128.71148, 36.06772],
            [128.71163, 36.06718],
            [128.71189, 36.06676],
            [128.71124, 36.06636],
            [128.7109, 36.06631],
            [128.71024, 36.06625],
            [128.70982, 36.0659],
            [128.70992, 36.06543],
            [128.71012, 36.06524],
            [128.71012, 36.0646],
            [128.70979, 36.06449],
            [128.70932, 36.06416],
            [128.70879, 36.06388],
            [128.70886, 36.06336],
            [128.7083, 36.06296],
            [128.70781, 36.0616],
            [128.70747, 36.06124],
            [128.70749, 36.06112],
            [128.70749, 36.06093],
            [128.70733, 36.06077],
            [128.70709, 36.06064],
            [128.7065, 36.06041],
            [128.70646, 36.06036],
            [128.70645, 36.06023],
            [128.7064, 36.06013],
            [128.70605, 36.05981],
            [128.70582, 36.05981],
            [128.70567, 36.05968],
            [128.70541, 36.05951],
            [128.7053, 36.05941],
            [128.7052, 36.05943],
            [128.70472, 36.05935],
            [128.70466, 36.05932],
            [128.70373, 36.05801],
            [128.70407, 36.05725],
            [128.70316, 36.05642],
            [128.70287, 36.05551],
            [128.70355, 36.05527],
            [128.70331, 36.05457],
            [128.70384, 36.05434],
            [128.7033, 36.05326],
            [128.70376, 36.05159],
            [128.70445, 36.04992],
            [128.7047, 36.04887],
            [128.70479, 36.04861],
            [128.70465, 36.04744],
            [128.70409, 36.04541],
            [128.70411, 36.04441],
            [128.70342, 36.04339],
            [128.70256, 36.04164],
            [128.7021, 36.04142],
            [128.70184, 36.04142],
            [128.70183, 36.04102],
            [128.70122, 36.04028],
            [128.70108, 36.03978],
            [128.70094, 36.03973],
            [128.70048, 36.0394],
            [128.70005, 36.03888],
            [128.69962, 36.03857],
            [128.69849, 36.03803],
            [128.69832, 36.03762],
            [128.69788, 36.03736],
            [128.69744, 36.03695],
            [128.69686, 36.03625],
            [128.69673, 36.03568],
            [128.69673, 36.03541],
            [128.69639, 36.03504],
            [128.69724, 36.03445],
            [128.69753, 36.03377],
            [128.6978, 36.03272],
            [128.69853, 36.03185],
            [128.69902, 36.03083],
            [128.69911, 36.03018],
            [128.69957, 36.02963],
            [128.69987, 36.02874],
            [128.69962, 36.02764],
            [128.69963, 36.02726],
            [128.69979, 36.02708],
            [128.69914, 36.02615],
            [128.69937, 36.0257],
            [128.69934, 36.0253],
            [128.69902, 36.02494],
            [128.69896, 36.02454],
            [128.69851, 36.02389],
            [128.6983, 36.0238],
            [128.69799, 36.02332],
            [128.69787, 36.02271],
            [128.69758, 36.02224],
            [128.69749, 36.02177],
            [128.69596, 36.01968],
            [128.6949, 36.01845],
            [128.69415, 36.01814],
            [128.695, 36.01639],
            [128.69367, 36.01607],
            [128.69117, 36.01569],
            [128.68995, 36.01573],
            [128.68902, 36.01524],
            [128.68715, 36.01446],
            [128.68708, 36.0145],
            [128.68649, 36.01426],
            [128.68591, 36.01462],
            [128.68462, 36.01449],
            [128.68235, 36.01473],
            [128.67979, 36.01491],
            [128.67958, 36.01476],
            [128.67742, 36.01357],
            [128.67723, 36.01358],
            [128.67685, 36.01336],
            [128.67304, 36.01349],
            [128.67223, 36.01328],
            [128.66902, 36.01336],
            [128.66854, 36.01315],
            [128.66837, 36.01312],
            [128.66787, 36.0129],
            [128.66734, 36.01281],
            [128.66679, 36.01252],
            [128.66548, 36.01087],
            [128.66488, 36.01067],
            [128.66483, 36.01061],
            [128.66358, 36.01021],
            [128.6623, 36.01036],
            [128.66003, 36.00999],
            [128.65597, 36.00994],
            [128.65515, 36.00962],
            [128.65433, 36.00945],
            [128.65402, 36.00948],
            [128.65318, 36.0084],
            [128.65117, 36.00758],
            [128.65109, 36.00762],
            [128.65042, 36.00738],
            [128.64972, 36.0077],
            [128.64835, 36.00843],
            [128.64787, 36.00885],
            [128.64636, 36.00958],
            [128.64567, 36.00971],
            [128.64524, 36.00998],
            [128.64408, 36.01049],
            [128.64388, 36.01053],
            [128.64301, 36.01037],
            [128.64274, 36.01039],
            [128.64222, 36.01031],
            [128.6407, 36.01042],
            [128.64032, 36.01127],
            [128.6392, 36.01296],
            [128.63732, 36.01527],
            [128.63695, 36.01693],
            [128.63451, 36.01865],
            [128.63216, 36.01983],
            [128.63183, 36.01983],
            [128.6293, 36.02107],
            [128.62882, 36.02129],
            [128.62649, 36.02182],
            [128.62465, 36.02329],
            [128.62264, 36.02406],
            [128.62246, 36.02425],
            [128.62135, 36.02524],
            [128.62175, 36.02615],
            [128.6216, 36.02701],
            [128.62124, 36.02806],
            [128.62164, 36.02865],
            [128.62151, 36.02899],
            [128.62196, 36.02987],
            [128.62189, 36.03061],
            [128.62188, 36.03099],
            [128.62172, 36.03119],
            [128.62145, 36.03226],
            [128.62136, 36.0324],
            [128.62128, 36.03264],
            [128.62171, 36.03392],
            [128.62227, 36.03475],
            [128.62258, 36.03497],
            [128.62307, 36.03592],
            [128.62287, 36.03643],
            [128.62312, 36.03661],
            [128.62259, 36.03781],
            [128.62269, 36.03817],
            [128.62319, 36.03851],
            [128.6234, 36.0385],
            [128.62395, 36.03921],
            [128.62428, 36.03922],
            [128.62447, 36.03934],
            [128.62439, 36.03996],
            [128.62574, 36.04157],
            [128.62612, 36.04188],
            [128.6261, 36.04216],
            [128.62613, 36.04248],
            [128.62625, 36.04264],
            [128.62602, 36.04366],
            [128.62613, 36.04367],
            [128.627, 36.04428],
            [128.62695, 36.04433],
            [128.62768, 36.04498],
            [128.62773, 36.04505],
            [128.62791, 36.04567],
            [128.62745, 36.04662],
            [128.62731, 36.04759],
            [128.62747, 36.04794],
            [128.62778, 36.04831],
            [128.62824, 36.04963],
            [128.62891, 36.05074],
            [128.62889, 36.05158],
            [128.62925, 36.05233],
            [128.62934, 36.05265],
            [128.6288, 36.05307],
            [128.62875, 36.05319],
            [128.6288, 36.05393],
            [128.62921, 36.05521],
            [128.62913, 36.05556],
            [128.629, 36.056],
            [128.62868, 36.0565],
            [128.6286, 36.05696],
            [128.62872, 36.05795],
            [128.62829, 36.05921],
            [128.62837, 36.05975],
            [128.62844, 36.05995],
            [128.62912, 36.05994],
            [128.62959, 36.06023],
            [128.63059, 36.05996],
            [128.63059, 36.06051],
            [128.63048, 36.06086],
            [128.6303, 36.06111],
            [128.6302, 36.06116],
            [128.63044, 36.06137],
            [128.63186, 36.06362],
            [128.63194, 36.06521],
            [128.63264, 36.06669],
            [128.63269, 36.06684],
            [128.63159, 36.06766],
            [128.63075, 36.06793],
            [128.62922, 36.06884],
            [128.62865, 36.06877],
            [128.62759, 36.06901],
            [128.62768, 36.06937],
            [128.62822, 36.06971],
            [128.62875, 36.07018],
            [128.62914, 36.07112],
            [128.62881, 36.07161],
            [128.62831, 36.07204],
            [128.62919, 36.07243],
            [128.63032, 36.07354],
            [128.63053, 36.07394],
            [128.6315, 36.07536],
            [128.63135, 36.07671],
            [128.63033, 36.07758],
            [128.6285, 36.07883],
            [128.62817, 36.07981],
            [128.62776, 36.08016],
            [128.62747, 36.08021],
            [128.62609, 36.08021],
            [128.62535, 36.08054],
            [128.62473, 36.08014],
            [128.62455, 36.07955],
            [128.62456, 36.07932],
            [128.62464, 36.07919],
            [128.62395, 36.07804],
            [128.62345, 36.07774],
            [128.6223, 36.07732],
            [128.62118, 36.07711],
            [128.62067, 36.07708],
            [128.61965, 36.07725],
            [128.61821, 36.07694],
            [128.61727, 36.07737],
            [128.61557, 36.0779],
            [128.61529, 36.07776],
            [128.61437, 36.07718],
            [128.61303, 36.0771],
            [128.61211, 36.07651],
            [128.61188, 36.07647],
            [128.61137, 36.0766],
            [128.60897, 36.07658],
            [128.60714, 36.07611],
            [128.60773, 36.07535],
            [128.60741, 36.07532],
            [128.60717, 36.07534],
            [128.60652, 36.07553],
            [128.60604, 36.07562],
            [128.60542, 36.07554],
            [128.60526, 36.07555],
            [128.60383, 36.07568],
            [128.60373, 36.07568],
            [128.60342, 36.07561],
            [128.60281, 36.07556],
            [128.60244, 36.07541],
            [128.60227, 36.07554],
            [128.60021, 36.07541],
            [128.59881, 36.07498],
            [128.59788, 36.07496],
            [128.59758, 36.07467],
            [128.59733, 36.07409],
            [128.59692, 36.07191],
            [128.59696, 36.07092],
            [128.59671, 36.07032],
            [128.59697, 36.06873],
            [128.59759, 36.06689],
            [128.59662, 36.06608],
            [128.59615, 36.06603],
            [128.59581, 36.06547],
            [128.59544, 36.06519],
            [128.59495, 36.06382],
            [128.59446, 36.06283],
            [128.59409, 36.06293],
            [128.5939, 36.06296],
            [128.59344, 36.06297],
            [128.59226, 36.0628],
            [128.59114, 36.06286],
            [128.58945, 36.06329],
            [128.58847, 36.06344],
            [128.58811, 36.06378],
            [128.58744, 36.06382],
            [128.58698, 36.06365],
            [128.58479, 36.06664],
            [128.58437, 36.06701],
            [128.58347, 36.06789],
            [128.58312, 36.06752],
            [128.58258, 36.0672],
            [128.582, 36.06711],
            [128.58165, 36.06696],
            [128.58035, 36.06671],
            [128.58025, 36.06647],
            [128.58026, 36.06617],
            [128.58008, 36.06566],
            [128.58009, 36.06527],
            [128.57964, 36.06473],
            [128.579, 36.06384],
            [128.57818, 36.06359],
            [128.57793, 36.06327],
            [128.57767, 36.06316],
            [128.57739, 36.06278],
            [128.57717, 36.06278],
            [128.57691, 36.06263],
            [128.57672, 36.06267],
            [128.57625, 36.06261],
            [128.57563, 36.06264],
            [128.57527, 36.06229],
            [128.57472, 36.06204],
            [128.5744, 36.06182],
            [128.57395, 36.06161],
            [128.57358, 36.0615],
            [128.57304, 36.0612],
            [128.57233, 36.06031],
            [128.57199, 36.05985],
            [128.57139, 36.05934],
            [128.57081, 36.05906],
            [128.57008, 36.05852],
            [128.56939, 36.05823],
            [128.56909, 36.05871],
            [128.56895, 36.05925],
            [128.56892, 36.062],
            [128.56786, 36.06381],
            [128.56676, 36.06576],
            [128.5668, 36.06637],
            [128.56674, 36.06696],
            [128.56561, 36.06768],
            [128.5645, 36.06903],
            [128.56384, 36.06976],
            [128.56382, 36.07072],
            [128.56388, 36.07134],
            [128.56385, 36.07183],
            [128.56457, 36.07281],
            [128.56487, 36.07342],
            [128.56559, 36.07411],
            [128.56523, 36.07492],
            [128.565, 36.07604],
            [128.56529, 36.07721],
            [128.56698, 36.07894],
            [128.56772, 36.07984],
            [128.56676, 36.08075],
            [128.56664, 36.08112],
            [128.56651, 36.08144],
            [128.56676, 36.0822],
            [128.56763, 36.08329],
            [128.568, 36.0837],
            [128.56821, 36.0846],
            [128.56832, 36.08582],
            [128.5686, 36.0873],
            [128.569, 36.0885],
            [128.56862, 36.08952],
            [128.56809, 36.09057],
            [128.56791, 36.09171],
            [128.56784, 36.09254],
            [128.5679, 36.09348],
            [128.56746, 36.09471],
            [128.56746, 36.09482],
            [128.56756, 36.09555],
            [128.56711, 36.09628],
            [128.56643, 36.09698],
            [128.56572, 36.0978],
            [128.56489, 36.09822],
            [128.56386, 36.09821],
            [128.56336, 36.09833],
            [128.56314, 36.09857],
            [128.56296, 36.09882],
            [128.563, 36.0992],
            [128.56279, 36.09964],
            [128.56284, 36.10034],
            [128.56293, 36.10047],
            [128.56401, 36.10142],
            [128.56462, 36.1026],
            [128.56493, 36.10391],
            [128.5649, 36.10437],
            [128.56471, 36.10591],
            [128.56463, 36.10685],
            [128.56516, 36.10866],
            [128.56522, 36.10962],
            [128.56367, 36.11075],
            [128.56284, 36.11112],
            [128.56283, 36.11215],
            [128.56308, 36.11363],
            [128.56374, 36.11438],
            [128.564, 36.11586],
            [128.56374, 36.11667],
            [128.56368, 36.11678],
            [128.56365, 36.11694],
            [128.56357, 36.11702],
            [128.56306, 36.11779],
            [128.56252, 36.11835],
            [128.56198, 36.11901],
            [128.56159, 36.11937],
            [128.56039, 36.11965],
            [128.55957, 36.12011],
            [128.55903, 36.11962],
            [128.55866, 36.11954],
            [128.55799, 36.11949],
            [128.55675, 36.1206],
            [128.55593, 36.12108],
            [128.55488, 36.12162],
            [128.55469, 36.12246],
            [128.55467, 36.12289],
            [128.55415, 36.12342],
            [128.55409, 36.12378],
            [128.55374, 36.12402],
            [128.5531, 36.12482],
            [128.55277, 36.12516],
            [128.5525, 36.1256],
            [128.55268, 36.1261],
            [128.55292, 36.12759],
            [128.5525, 36.12828],
            [128.5521, 36.12883],
            [128.55162, 36.12884],
            [128.55077, 36.12936],
            [128.55049, 36.12983],
            [128.55013, 36.13016],
            [128.54979, 36.1307],
            [128.5497, 36.13071],
            [128.54923, 36.13069],
            [128.54857, 36.13097],
            [128.5486, 36.13168],
            [128.54859, 36.13216],
            [128.54906, 36.13294],
            [128.55005, 36.13431],
            [128.55138, 36.13586],
            [128.55167, 36.13688],
            [128.55291, 36.13906],
            [128.55268, 36.13919],
            [128.5514, 36.14009],
            [128.55024, 36.14024],
            [128.54926, 36.14021],
            [128.5486, 36.14034],
            [128.54844, 36.14047],
            [128.54827, 36.1407],
            [128.54858, 36.14125],
            [128.54876, 36.14309],
            [128.54888, 36.14343],
            [128.54967, 36.14526],
            [128.54964, 36.14531],
            [128.54944, 36.14632],
            [128.54901, 36.14714],
            [128.54876, 36.14804],
            [128.54905, 36.14902],
            [128.54939, 36.14932],
            [128.54982, 36.14964],
            [128.55007, 36.1503],
            [128.55019, 36.15065],
            [128.54926, 36.15099],
            [128.54854, 36.1514],
            [128.54821, 36.15183],
            [128.54742, 36.15235],
            [128.54665, 36.15381],
            [128.54553, 36.15385],
            [128.54447, 36.15429],
            [128.54241, 36.15467],
            [128.5426, 36.15524],
            [128.54202, 36.15705],
            [128.54173, 36.158],
            [128.54098, 36.15981],
            [128.54088, 36.1603],
            [128.54008, 36.16109],
            [128.54043, 36.16171],
            [128.54185, 36.16338],
            [128.54212, 36.16371],
            [128.5432, 36.16457],
            [128.54391, 36.16499],
            [128.54445, 36.16601],
            [128.54487, 36.1661],
            [128.54647, 36.16508],
            [128.54732, 36.16461],
            [128.54831, 36.16426],
            [128.54827, 36.16439],
            [128.5483, 36.16456],
            [128.54837, 36.16459],
            [128.54842, 36.16452],
            [128.54848, 36.16426],
            [128.55034, 36.16437],
            [128.55144, 36.16458],
            [128.55237, 36.16491],
            [128.55266, 36.16552],
            [128.55338, 36.16669],
            [128.55396, 36.16831],
            [128.55395, 36.17015],
            [128.5538, 36.17204],
            [128.55402, 36.17291],
            [128.55418, 36.17347],
            [128.55478, 36.17467],
            [128.556, 36.17611],
            [128.55688, 36.17717],
            [128.55599, 36.17776],
            [128.55515, 36.1778],
            [128.55393, 36.17811],
            [128.55294, 36.17792],
            [128.55165, 36.17812],
            [128.55041, 36.17815],
            [128.54883, 36.17781],
            [128.54874, 36.17845],
            [128.54873, 36.1794],
            [128.54864, 36.18004],
            [128.54788, 36.18084],
            [128.54597, 36.18152],
            [128.54555, 36.18254],
            [128.54529, 36.18363],
            [128.54497, 36.18398],
            [128.54386, 36.18442],
            [128.54289, 36.18504],
            [128.54279, 36.18534],
            [128.54285, 36.18617],
            [128.54255, 36.18705],
            [128.54159, 36.18869],
            [128.54088, 36.18964],
            [128.53884, 36.19133],
            [128.53871, 36.1924],
            [128.53734, 36.19411],
            [128.53661, 36.19493],
            [128.53364, 36.19626],
            [128.533, 36.19632],
            [128.53251, 36.19654],
            [128.53116, 36.19725],
            [128.5286, 36.19769],
            [128.5275, 36.19749],
            [128.52707, 36.19787],
            [128.5266, 36.19834],
            [128.52635, 36.19914],
            [128.5255, 36.20022],
            [128.52446, 36.20072],
            [128.52389, 36.2009],
            [128.52324, 36.20107],
            [128.52278, 36.20142],
            [128.5225, 36.20136],
            [128.52193, 36.20089],
            [128.52143, 36.20066],
            [128.5214, 36.20072],
            [128.5213, 36.20063],
            [128.5211, 36.20057],
            [128.52103, 36.2006],
            [128.52066, 36.20062],
            [128.52047, 36.20061],
            [128.51931, 36.20032],
            [128.51819, 36.20038],
            [128.51709, 36.19994],
            [128.51601, 36.1997],
            [128.51578, 36.19912],
            [128.51576, 36.19904],
            [128.51557, 36.19812],
            [128.51533, 36.19664],
            [128.51428, 36.19419],
            [128.51342, 36.19223],
            [128.51319, 36.19202],
            [128.51183, 36.19162],
            [128.51063, 36.18969],
            [128.50975, 36.18813],
            [128.50907, 36.1873],
            [128.50711, 36.18557],
            [128.50588, 36.18536],
            [128.50537, 36.18509],
            [128.50294, 36.18488],
            [128.50211, 36.18558],
            [128.50179, 36.18609],
            [128.50182, 36.1866],
            [128.50171, 36.1873],
            [128.5019, 36.1882],
            [128.50167, 36.18935],
            [128.50192, 36.18999],
            [128.5018, 36.19046],
            [128.50126, 36.19127],
            [128.50023, 36.19171],
            [128.49961, 36.19267],
            [128.4996, 36.19427],
            [128.49887, 36.19601],
            [128.49646, 36.19598],
            [128.49544, 36.19647],
            [128.49439, 36.19779],
            [128.49427, 36.19855],
            [128.49389, 36.19899],
            [128.49362, 36.20002],
            [128.49321, 36.2007],
            [128.49379, 36.20398],
            [128.49407, 36.20622],
            [128.49343, 36.20706],
            [128.49324, 36.20915],
            [128.49276, 36.21009],
            [128.49207, 36.21123],
            [128.49213, 36.21209],
            [128.49156, 36.21272],
            [128.48949, 36.21375],
            [128.4891, 36.21403],
            [128.48792, 36.21472],
            [128.48773, 36.21559],
            [128.4882, 36.21633],
            [128.48862, 36.21685],
            [128.48873, 36.21733],
            [128.48902, 36.2176],
            [128.4887, 36.21828],
            [128.4894, 36.21917],
            [128.48939, 36.2198],
            [128.48916, 36.22028],
            [128.48803, 36.22083],
            [128.4874, 36.22198],
            [128.48553, 36.22319],
            [128.48531, 36.22367],
            [128.48506, 36.22427],
            [128.48402, 36.22455],
            [128.48301, 36.22466],
            [128.48165, 36.22395],
            [128.48155, 36.22387],
            [128.48138, 36.22381],
            [128.48119, 36.2238],
            [128.48113, 36.2237],
            [128.48104, 36.22374],
            [128.48103, 36.22382],
            [128.48094, 36.22386],
            [128.48084, 36.22384],
            [128.48075, 36.22385],
            [128.48052, 36.22396],
            [128.48035, 36.22407],
            [128.48029, 36.22417],
            [128.48014, 36.2243],
            [128.47973, 36.22447],
            [128.47953, 36.22447],
            [128.47953, 36.22518],
            [128.47974, 36.22562],
            [128.48048, 36.22612],
            [128.48134, 36.22663],
            [128.48185, 36.22682],
            [128.48229, 36.22703],
            [128.48268, 36.22732],
            [128.48306, 36.22788],
            [128.48327, 36.22836],
            [128.4834, 36.22847],
            [128.48488, 36.22944],
            [128.48509, 36.22963],
            [128.48561, 36.23026],
            [128.48588, 36.23099],
            [128.48583, 36.23162],
            [128.48574, 36.23197],
            [128.48543, 36.23247],
            [128.4851, 36.2331],
            [128.48506, 36.2332],
            [128.48506, 36.23359],
            [128.4847, 36.23357],
            [128.48457, 36.23343],
            [128.48456, 36.2335],
            [128.48446, 36.23344],
            [128.48446, 36.23337],
            [128.48438, 36.23341],
            [128.48432, 36.23347],
            [128.48422, 36.23349],
            [128.48415, 36.23357],
            [128.48278, 36.23401],
            [128.48034, 36.23413],
            [128.47956, 36.23463],
            [128.47845, 36.23471],
            [128.4763, 36.23331],
            [128.47576, 36.23253],
            [128.47577, 36.23162],
            [128.47489, 36.23139],
            [128.47437, 36.23083],
            [128.47289, 36.23057],
            [128.4716, 36.22916],
            [128.46985, 36.22915],
            [128.46811, 36.22949],
            [128.46739, 36.23026],
            [128.46657, 36.22984],
            [128.46536, 36.22889],
            [128.46368, 36.22808],
            [128.46204, 36.22667],
            [128.46166, 36.22656],
            [128.46116, 36.22628],
            [128.45995, 36.22664],
            [128.45971, 36.22678],
            [128.45875, 36.22613],
            [128.45834, 36.22626],
            [128.45646, 36.22536],
            [128.45365, 36.22432],
            [128.45319, 36.22334],
            [128.45275, 36.22299],
            [128.45158, 36.2227],
            [128.4509, 36.22174],
            [128.45005, 36.22157],
            [128.44839, 36.22131],
            [128.44657, 36.22092],
            [128.44553, 36.221],
            [128.44492, 36.22142],
            [128.44452, 36.22137],
            [128.44386, 36.22122],
            [128.44345, 36.22068],
            [128.44336, 36.22],
            [128.44257, 36.21976],
            [128.44223, 36.21916],
            [128.44143, 36.21885],
            [128.44095, 36.21836],
            [128.44001, 36.21959],
            [128.43906, 36.22051],
            [128.43785, 36.22275],
            [128.43763, 36.2239],
            [128.43766, 36.22521],
            [128.43777, 36.2271],
            [128.43712, 36.22805],
            [128.43659, 36.22872],
            [128.43584, 36.22917],
            [128.43532, 36.22954],
            [128.43453, 36.23051],
            [128.43447, 36.23107],
            [128.43451, 36.23151],
            [128.43456, 36.23189],
            [128.43434, 36.23352],
            [128.43431, 36.23361],
            [128.434, 36.23435],
            [128.43315, 36.23476],
            [128.43241, 36.23534],
            [128.43137, 36.23563],
            [128.43017, 36.23632],
            [128.42924, 36.23616],
            [128.4274, 36.23658],
            [128.42683, 36.23618],
            [128.42545, 36.23616],
            [128.42379, 36.237],
            [128.42381, 36.23738],
            [128.42362, 36.23777],
            [128.42331, 36.23823],
            [128.42354, 36.2392],
            [128.42349, 36.2396],
            [128.42357, 36.24036],
            [128.42415, 36.24081],
            [128.42382, 36.24225],
            [128.42166, 36.24276],
            [128.42053, 36.24383],
            [128.41942, 36.24458],
            [128.41765, 36.24586],
            [128.41744, 36.24794],
            [128.4178, 36.2492],
            [128.4188, 36.2517],
            [128.42017, 36.25206],
            [128.42007, 36.25378],
            [128.41961, 36.25468],
            [128.41923, 36.25546],
            [128.41718, 36.25644],
            [128.41665, 36.25684],
            [128.41646, 36.2572],
            [128.41642, 36.25715],
            [128.41617, 36.25769],
            [128.41863, 36.25816],
            [128.42048, 36.25966],
            [128.4215, 36.26185],
            [128.42097, 36.2642],
            [128.42075, 36.26632],
            [128.42114, 36.26779],
            [128.4225, 36.26975],
            [128.42401, 36.27102],
            [128.4247, 36.27088],
            [128.42492, 36.27204],
            [128.42512, 36.27238],
            [128.42581, 36.27273],
            [128.42632, 36.27334],
            [128.4264, 36.27386],
            [128.42664, 36.27496],
            [128.42662, 36.2754],
            [128.42596, 36.27606],
            [128.42602, 36.27652],
            [128.42557, 36.27671],
            [128.42416, 36.27785],
            [128.42182, 36.27863],
            [128.42157, 36.27953],
            [128.42154, 36.27957],
            [128.41931, 36.28006],
            [128.41852, 36.28033],
            [128.41721, 36.28174],
            [128.4164, 36.28259],
            [128.41525, 36.28206],
            [128.41397, 36.28261],
            [128.41352, 36.28287],
            [128.41345, 36.28352],
            [128.41329, 36.28419],
            [128.41261, 36.28499],
            [128.41138, 36.28577],
            [128.41097, 36.28651],
            [128.4104, 36.28724],
            [128.41111, 36.28833],
            [128.41217, 36.28931],
            [128.41356, 36.29064],
            [128.41388, 36.29129],
            [128.41373, 36.29175],
            [128.4132, 36.29222],
            [128.41282, 36.29371],
            [128.41321, 36.29407],
            [128.4138, 36.29488],
            [128.41405, 36.29579],
            [128.4142, 36.29611],
            [128.41544, 36.29734],
            [128.41624, 36.29794],
            [128.41654, 36.29872],
            [128.41779, 36.29927],
            [128.41802, 36.29904],
            [128.41884, 36.29907],
            [128.41979, 36.29915],
            [128.42069, 36.29869],
            [128.42109, 36.29836],
            [128.42217, 36.2986],
            [128.42268, 36.29875],
            [128.42379, 36.29884],
            [128.42477, 36.29856],
            [128.42548, 36.29914],
            [128.42787, 36.29887],
            [128.4298, 36.30047],
            [128.43186, 36.30268],
            [128.43193, 36.3044],
            [128.43254, 36.30486],
            [128.43356, 36.30542],
            [128.43462, 36.30577],
            [128.43514, 36.3055],
            [128.43535, 36.30546],
            [128.43563, 36.30527],
            [128.43614, 36.30451],
            [128.43734, 36.3044],
            [128.438, 36.30492],
            [128.43853, 36.30513],
            [128.43892, 36.30595],
            [128.4385, 36.30691],
            [128.43886, 36.30804],
            [128.43877, 36.30828],
            [128.43868, 36.30865],
            [128.43893, 36.30981],
            [128.43866, 36.30995],
            [128.4384, 36.3102],
            [128.43906, 36.31101],
            [128.43903, 36.31187],
            [128.43922, 36.31232],
            [128.43886, 36.31274],
            [128.43883, 36.31335],
            [128.43846, 36.31374],
            [128.43801, 36.31407],
            [128.439, 36.31481],
            [128.43986, 36.31532],
            [128.44034, 36.31572],
            [128.44207, 36.31594],
            [128.44346, 36.31682],
            [128.44439, 36.3178],
            [128.44522, 36.31839],
            [128.44537, 36.31962],
            [128.44572, 36.3201],
            [128.44644, 36.32085],
            [128.44702, 36.32162],
            [128.44707, 36.3227],
            [128.44781, 36.32325],
            [128.44819, 36.32358],
            [128.4491, 36.32342],
            [128.44937, 36.32363],
            [128.45098, 36.32477],
            [128.45073, 36.32539],
            [128.4506, 36.32577],
            [128.45154, 36.3272],
            [128.45161, 36.32718]
          ]
        ]
      }
    }
  ]
};
