import Divider from 'components/common/Divider';
import PropTypes from 'prop-types';
import React, { useState, useContext, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import SocialAuthButtons from './SocialAuthButtons';

import { chainAxios } from 'helpers/chainAxios';
import { useNavigate, useLocation } from 'react-router-dom';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const LoginForm = ({ hasLabel, layout }) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });

  useEffect(() => {
    if (!!cookies.get('Remember') && cookies.get('Remember')) {
      console.log(cookies.get('LoginEmail'));

      setFormData({
        email: cookies.get('LoginEmail'),
        password: '',
        remember: true
      });
    }
  }, []);

  // Handler
  const handleSubmit = e => {
    e.preventDefault();

    let loginUrl =
      '/api/signin?userId=' + formData.email + '&password=' + formData.password;
    chainAxios
      .get(loginUrl)
      .then(response => {
        if (response.data.resultCode !== 200) {
          toast.error(`Fail Login as ${formData.email}`, {
            theme: 'colored'
          });

          toast.error(response.data.message, {
            theme: 'colored'
          });

          return;
        }
        toast.success(`Logged in as ${formData.email}`, {
          theme: 'colored'
        });

        cookies.set('Remember', formData.remember);
        if (formData.remember) {
          cookies.set('LoginEmail', formData.email);
        }

        window.sessionStorage.setItem('isAuthorized', true);
        cookies.set('Authorization', response.data.data.jwt, {
          path: '/',
          secure: false, // HTTPS 연결에서만 전송
          sameSite: 'Strict', // 동일 사이트 요청에만 전송
          expires: new Date(Date.now() + 86400000) // 1일 후 만료
        });
        window.sessionStorage.setItem(
          'loginUser',
          JSON.stringify(response.data.data.user)
        );

        navigate('/');
      })
      .catch(error => {
        toast.error(`Error Login as ${formData.email}`, {
          theme: 'colored'
        });
        console.log('handleSubmit : ', error);
      });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const onClickOtherLogin = () => {
    let loginUrl = '/api/signin?userId=imgeun@chainreum.com&password=imgeun';
    chainAxios
      .get(loginUrl)
      .then(response => {
        if (response.data.resultCode !== 200) {
          toast.error(`Fail Login as imgeun@chainreum.com`, {
            theme: 'colored'
          });
        }
        toast.success(`Logged in as imgeun@chainreum.com`, {
          theme: 'colored'
        });

        window.sessionStorage.setItem('isAuthorized', true);
        window.sessionStorage.setItem(
          'loginUser',
          JSON.stringify(response.data.data.user)
        );
        cookies.set('Authorization', response.data.data.jwt);

        navigate(state);
      })
      .catch(error => {
        toast.error(`Error Login as imgeun@chainreum.com`, {
          theme: 'colored'
        });

        console.log(error);
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              Remember me
            </Form.Check.Label>
          </Form.Check>
        </Col>

        {/*<Col xs="auto">*/}
        {/*  <Link className="fs--1 mb-0" to={`/forgot-password`}>*/}
        {/*    Forgot Password?*/}
        {/*  </Link>*/}
        {/*</Col>*/}
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password}
        >
          Log in
        </Button>

        {/* <Button
          color="primary"
          className="mt-3 w-100"
          onClick={onClickOtherLogin}
        >
          Log in (Imgeun)
        </Button> */}
      </Form.Group>

      {/* <Divider className="mt-4">or log in with</Divider> */}
      {/* <SocialAuthButtons /> */}
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
