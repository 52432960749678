import dayjs from 'dayjs';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { getColor, getPastDates, isEmpty, rgbaColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useRef } from 'react';
import Flex from '../../../../common/Flex';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  LegendComponent
]);

const tooltipFormatter = params => {
  // console.log('params : ', params);
  if (params.componentType === 'series' && params.componentSubType === 'bar') {
    return `<span class='text-800'>${
      params.name
    }</span><br/><div class="dot me-1 fs--2 d-inline-block" style="background-color: ${
      params.borderColor
    }"></div>
                 <span class='text-600 fs--1'>${
                   params.seriesName === 'deathCnt'
                     ? '사망자'
                     : params.seriesName === 'injuryCnt'
                     ? '부상자'
                     : '사고건수'
                 }: ${params.value.toLocaleString()}${
      params.seriesName === 'deathCnt' || params.seriesName === 'injuryCnt'
        ? '명'
        : '건'
    }</span>`;
  }
};

const getOptions = data => ({
  color: getColor('gray-100'),
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-100'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    formatter: tooltipFormatter,
    transitionDuration: 0
  },
  xAxis: {
    type: 'category',
    data: data.dates,
    boundaryGap: true,
    axisPointer: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    splitLine: {
      show: true, // 보조선 표시
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      },
      alignWithLabel: true // 보조선을 레이블과 맞춤
    },
    axisLine: {
      lineStyle: {
        color: rgbaColor('#000', 0.01),
        type: 'dashed'
      }
    },
    axisTick: { show: false },
    axisLabel: {
      color: getColor('gray-400'),
      margin: 15,
      formatter: value => dayjs(value).format('YYYY-MM-DD'),
      interval: 'auto', // 자동으로 레이블 간격 조정
      rotate: 45, // 레이블을 45도 회전
      fontSize: 10 // 글꼴 크기를 줄임
    }
  },
  yAxis: {
    type: 'value',
    name: '', // Y축에 추가적인 문구를 추가
    nameLocation: 'middle', // 문구 위치 (start, middle, end)
    nameTextStyle: {
      color: getColor('gray-600'),
      fontWeight: 'regular',
      fontSize: 12,
      padding: [0, 0, 10, 0] // 이름의 위치를 조정하기 위한 패딩 설정
    },
    axisPointer: { show: false },
    splitLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    boundaryGap: false,
    axisLabel: {
      show: true,
      color: getColor('gray-400'),
      margin: 5 // Y축 값 레이블의 간격을 조정하여 겹침 방지
    },
    axisTick: { show: false },
    axisLine: { show: false },
    interval: 1, // Y축 간격을 1로 설정
    min: 0,
    max: 5 // 최대값을 5로 설정
  },
  series: [
    {
      name: 'accidentCnt', // 새로운 차트 이름
      type: 'bar',
      stack: 'total1', // 같은 스택 그룹으로 설정
      z: 10,
      data: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0,
        0, 0, 0, 0, 0, 0, 0
      ],
      itemStyle: {
        borderRadius: [5, 5, 0, 0],
        color: '#000000', // 새로운 색상
        borderWidth: 1,
        borderColor: '#000000'
      }
    },
    {
      name: 'deathCnt',
      type: 'bar',
      stack: 'total', // 스택 그룹 이름
      z: 10, // Bar chart z-index
      data: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0
      ],
      itemStyle: {
        color: rgbaColor(getColor('danger'), 1),
        borderRadius: [5, 5, 0, 0],
        borderWidth: 1,
        borderColor: getColor('danger')
      },
      markArea: {
        silent: false, // Silent to avoid interference
        z: -1, // MarkArea z-index (lower than bar chart)
        data: data.markArea
      }
    },
    {
      name: 'injuryCnt',
      type: 'bar',
      stack: 'total', // 스택 그룹 이름
      z: 10, // Bar chart z-index
      data: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0
      ],
      itemStyle: {
        borderRadius: [5, 5, 0, 0],
        color: getColor('warning'),
        borderWidth: 1,
        borderColor: getColor('warning')
      }
    }
  ],
  dataZoom: [
    {
      show: true,
      type: 'inside',
      filterMode: 'none',
      xAxisIndex: [0],
      startValue: -9999,
      endValue: 100
    }
  ],
  grid: {
    right: '2%',
    left: '7%',
    bottom: '20%',
    top: '5%',
    containLabel: false
  }
});
const AccidentStatusChart = () => {
  const chartRef = useRef(null);

  const data = {
    dates: [
      '2024-07-01',
      '2024-07-02',
      '2024-07-03',
      '2024-07-04',
      '2024-07-05',
      '2024-07-06',
      '2024-07-07',
      '2024-07-08',
      '2024-07-09',
      '2024-07-10',
      '2024-07-11',
      '2024-07-12',
      '2024-07-13',
      '2024-07-14',
      '2024-07-15',
      '2024-07-16',
      '2024-07-17',
      '2024-07-18',
      '2024-07-19',
      '2024-07-20',
      '2024-07-21',
      '2024-07-22',
      '2024-07-23',
      '2024-07-24',
      '2024-07-25',
      '2024-07-26',
      '2024-07-27',
      '2024-07-28',
      '2024-07-29',
      '2024-07-30',
      '2024-07-31'
    ],
    predProbs: [
      0.22952, 0.22353, 0.29822, 0.26136, 0.34246, 0.33979, 0.30741, 0.45792,
      0.4571, 0.34324, 0.31119, 0.20095, 0.3302, 0.43484, 0.38574, 0.33803,
      0.3208, 0.60815, 0.61589, 0.59674, 0.63377, 0.52807, 0.56405, 0.57773,
      0.57186, 0.61595, 0.61445, 0.61179, 0.63506, 0.60254, 0.61378
    ],
    accident: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0,
      0, 0, 0, 0, 0, 0
    ],
    predVal: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1,
      1, 1, 1, 1, 1, 1
    ]
  };

  useEffect(() => {
    if (chartRef.current) {
      // 1. 기존 차트 인스턴스를 초기화(삭제)
      echarts.dispose(chartRef.current.getEchartsInstance());
    }
    makeMarkAreaData();
    // 2. 새로운 차트를 생성
    chartRef.current.getEchartsInstance().setOption(getOptions(data));
  });

  const makeMarkAreaData = () => {
    let makeMarkAreas = [];

    // * 안전점수
    //   const sfScore = (e) =>  parseInt(Math.ceil(-100*(predVal**2.1) + 100));
    // * 안전등급
    //   const sfgrade = (e) => e >= 95 ? 'A' : e >= 80 ? 'B' : e >= 50 ? 'C' : e >= 30 ? 'D' : e >= 15 ? 'E' : 'F';

    data.predProbs.forEach((predProb, index) => {
      let riskScore = parseInt(Math.ceil(-100 * predProb ** 2.1 + 100));
      console.log('riskScore : ', riskScore);
      const grade =
        riskScore >= 95
          ? 'A'
          : riskScore >= 80
          ? 'B'
          : riskScore >= 50
          ? 'C'
          : riskScore >= 30
          ? 'D'
          : riskScore >= 15
          ? 'E'
          : 'F';

      const gradeName =
        riskScore >= 95
          ? '안전'
          : riskScore >= 80
          ? '관찰'
          : riskScore >= 50
          ? '주의'
          : riskScore >= 30
          ? '경고'
          : riskScore >= 15
          ? '심각'
          : '심각';

      const color =
        riskScore >= 95
          ? '#27ae60'
          : riskScore >= 80
          ? '#2980b9'
          : riskScore >= 50
          ? '#f1c40f'
          : riskScore >= 30
          ? '#e67e22'
          : '#e74c3c';

      let day = (index + 1).toString().padStart(2, '0');
      const icon1 = data.predVal[index] === 1 ? '' : '';
      const icon1Value = data.predVal[index] === 1 ? '예측' : '-';
      const icon2 = data.accident[index] === 1 ? '⚠️' : '';
      const icon2Value = data.accident[index] === 1 ? '발생' : '-';

      const sagoDate = '2024-07-23';
      const accidentDetail = '빗물받이 연결관 파손';
      const accidentAddr = '경기도 고양시 일산서구 대화동 2428';
      const deathCnt = '0';
      const injuryCnt = '0';
      const vehicleCnt = '0';
      const trStatus = '복구완료';
      const trFnDate = '2024-07-24';

      makeMarkAreas.push([
        {
          tooltip: {
            formatter: () => {
              // 특정 조건에 따라 accidentDetail과 accidentAddr 추가
              const accidentInfo =
                data.accident[index] === 1
                  ? `<hr><span class='fs--2'>발생일자: ${sagoDate}<br>사고발생원인: ${accidentDetail}<br>사고발생위치: ${accidentAddr}<br>피해사망자수: ${deathCnt}명 / 피해부상자수: ${injuryCnt}명 / 피해차량대수: ${vehicleCnt}대<hr>복구상태: ${trStatus}<br>복구완료일자: ${trFnDate}</span>`
                  : '';

              return `<span class='fs--1'>
                  <span class='fs--2'>2024-07-${day}</span><br>
                  안전점수: <strong>${riskScore}</strong>점<br>
                  안전등급: <strong>${grade}</strong>등급
                <hr><span class='fs--1'>침하위험단계: <strong>${gradeName}</strong><br/>침하사고여부: <strong>${icon2Value}</strong></span>
                ${accidentInfo} <!-- 조건에 따라 추가 -->
              </span>`;
            },
            backgroundColor: color,
            textStyle: {
              color: '#000'
            }
          },
          label: {
            show: true,
            formatter: () => `${icon1} ${icon2}`, // 두 아이콘 조합
            position: 'insideTop',
            color: '#000',
            fontSize: 12
          },
          xAxis: '2024-07-' + day,
          itemStyle: { color: rgbaColor(color, 0.2) }
        },
        { xAxis: '2024-07-' + day }
      ]);
    });
    console.log('makeMarkAreas : ', makeMarkAreas);
    // Add markArea
    data.markArea = makeMarkAreas;
  };

  const tooltipInfo = data => {
    return (
      <div className="card text-center shadow-sm">
        <div className="card-body p-3">
          {/* Location Name */}
          <h6 className="mb-3 text-dark fw-bold">2024-07-26</h6>

          {/* Safety Grade and Score */}
          <div className="d-flex justify-content-between">
            {/* Safety Grade */}
            <div className="text-center flex-fill">
              <small className="fw-b">안전등급</small>
              <div className="display-4 fw-bold mt-1">{/*{data.grade}*/}A</div>
            </div>

            {/* Safety Score */}
            <div className="text-center flex-fill">
              <small className="fw-b">안전점수</small>
              <div className="display-4 fw-bold mt-1 text-secondary">
                {/*{data.riskScore}*/}
                97
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Flex
        direction="column"
        justifyContent="between"
        className="rounded-1 h-100"
        alignItems="center"
      >
        <h6 className={'fs--1 text-900'}>7월 사고발생 예측 및 현황</h6>
      </Flex>
      <ReactEChartsCore
        ref={chartRef}
        echarts={echarts}
        option={getOptions(data)}
        className={'ps-4'}
      />
    </>
  );
};

export default AccidentStatusChart;
