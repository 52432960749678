import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import PropTypes from 'prop-types';
import mapMarker from '../../../assets/img/icons/map-marker.png';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const SensorMap = ({ sensor }) => {
  const sensorMapRef = useRef();
  const sensorMapTooltipRef = useRef();
  const [sensorMapInstance, setSensorMapInstance] = useState(null);

  useEffect(() => {
    MakeSensorMapboxMap();
  }, []);

  useEffect(() => {
    if (!!sensor && JSON.stringify(sensor) !== '{}') {
      if (sensorMapInstance !== null) {
        sensorMapInstance.setCenter([
          sensor?.location.longitude,
          sensor?.location.latitude
        ]);
        MakeMapboxSensorMarker();
      }
    }
  }, [sensor]);

  const MakeSensorMapboxMap = () => {
    const map = new mapboxgl.Map({
      container: 'sensorMap',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [126.7901852, 37.6385557],
      zoom: 17,
      attributionControl: false
    });

    setSensorMapInstance(map);
  };

  const MakeMapboxSensorMarker = () => {
    const markers = document.getElementsByClassName('mapboxgl-marker');
    while (markers.length > 0) {
      markers[0].parentNode.removeChild(markers[0]);
    }

    const el = document.createElement('div');
    el.className = 'marker';
    el.style.backgroundImage = `url(${mapMarker})`;
    el.style.width = '30px';
    el.style.height = '30px';
    el.style.backgroundSize = '100%';

    new mapboxgl.Marker(el)
      .setLngLat([sensor.location.longitude, sensor.location.latitude])
      .addTo(sensorMapInstance);
  };

  return (
    <div
      ref={sensorMapRef}
      id="sensorMap"
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        minHeight: '260px'
      }}
    >
      <div
        ref={sensorMapTooltipRef}
        id="info"
        style={{
          position: 'absolute',
          display: 'inline-block',
          height: 'auto',
          width: 'auto',
          zIndex: '100',
          backgroundColor: '#333',
          color: '#fff',
          textAlign: 'center',
          borderRadius: '4px',
          padding: '5px',
          left: '50%',
          transform: 'translateX(3%)',
          visibility: 'hidden',
          pointerEvents: 'none'
        }}
      ></div>
    </div>
  );
};

SensorMap.propTypes = {
  sensor: PropTypes.shape({
    location: PropTypes.shape({
      longitude: PropTypes.number.isRequired,
      latitude: PropTypes.number.isRequired
    }).isRequired,
    sensorHole: PropTypes.shape({
      value: PropTypes.string.isRequired
    }).isRequired,
    status: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }).isRequired
};

export default SensorMap;

// import { Card, Row } from 'react-bootstrap';
// import React, { useEffect, useRef, useState } from 'react';
// import mapboxgl from 'mapbox-gl';
// import PropTypes from 'prop-types';
// import { fromLonLat } from 'ol/proj';
// import { Point } from 'ol/geom';
// import { XYZ } from 'ol/source';
// import { Point } from 'ol/geom';
// import { Circle, Fill, Icon, Stroke, Style, Text } from 'ol/style';
// import mapMarker from '../../../assets/img/icons/map-marker.png';
// import VectorSource from 'ol/source/Vector';
// import { getColor } from 'helpers/utils';

// const SensorMap = ({ sensor }) => {
//   const sensorMapRef = useRef();
//   const sensorMapTooltipRef = useRef();
//   const [sensorMapInstance, setSensorMapInstance] = useState(null);

//   useEffect(() => {
//     MakeSensorVworldMap();
//   }, []);

//   useEffect(() => {
//     // console.log(sensor);
//     if (!!sensor && JSON.stringify(sensor) !== '{}') {
//       if (sensorMapInstance !== null) {
//         sensorMapInstance
//           .getView()
//           .setCenter(
//             fromLonLat([sensor?.location.longitude, sensor?.location.latitude])
//           );
//         MakeVworldRoadSensorMarker();
//       }
//     }
//   }, [sensor]);

//   const MakeSensorVworldMap = () => {
//     const miniMap = new Map({
//       controls: defaults({ zoom: false, rotate: false }),
//       // layers: [vworldLayers],
//       target: 'sensorMap',
//       view: new View({
//         center: fromLonLat([126.7901852, 37.6385557]),
//         // center: fromLonLat([
//         //   facilityInfo.location.longitude,
//         //   facilityInfo.location.latitude
//         // ]),
//         maxZoom: 19,
//         minZoom: 18,
//         zoom: 19
//       })
//     });

//     const baseLayerGroup = new Group({
//       layers: [
//         new TileLayer({
//           source: new XYZ({
//             url: 'https://api.vworld.kr/req/wmts/1.0.0/BB2A44B3-37F9-31C5-8C81-8DBFF739A3E4/Base/{z}/{y}/{x}.png'
//           }),
//           title: 'base-vworld-base',
//           visible: true,
//           // properties: { name: 'base-vworld-base' },
//           minZoom: 6,
//           maxZoom: 19,
//           zIndex: 2
//           // preload: Infinity
//         })
//       ]
//     });
//     miniMap.addLayer(baseLayerGroup);

//     setSensorMapInstance(miniMap);
//   };

//   const MakeVworldRoadSensorMarker = () => {
//     sensorMapInstance.getLayers().forEach(layer => {
//       if (layer.get('name') && layer.get('name') === 'sensorMarker') {
//         sensorMapInstance.removeLayer(layer);
//       }
//     });

//     let marker = new Feature({
//       geometry: new Point(
//         fromLonLat([sensor.location.longitude, sensor.location.latitude])
//       ),
//       name: sensor.sensorHole.value,
//       type: 'SENSOR',
//       id: sensor.id
//     });
//     let sensorColor = getColor('primary');
//     if (sensor.status === 'ERR') {
//       sensorColor = getColor('danger');
//     }
//     if (sensor.status === 'OFF') {
//       sensorColor = getColor('warning');
//     }

//     let myStyle = new Style({
//       image: new Circle({
//         radius: 5,
//         stroke: new Stroke({
//           color: '#fff'
//         }),
//         fill: new Fill({
//           color: sensorColor
//         })
//       }),
//       text: new Text({
//         text: marker.get('name'),
//         font: 'bold 12px Pretendard',
//         offsetX: 20,
//         offsetY: 0,
//         fill: new Fill({
//           color: '#42f'
//         })
//       })
//     });
//     marker.setStyle(myStyle);
//     // create a vector layer and add the marker feature to it
//     let markerLayer = new VectorLayer({
//       source: new VectorSource({
//         features: [marker]
//       }),
//       zIndex: 99,
//       visible: true,
//       name: 'sensorMarker'
//     });

//     // add myLayer
//     sensorMapInstance.addLayer(markerLayer);
//   };

//   return (
//     // <Card className="h-100">
//     //   <Card.Header className={'pb-0'}>
//     //     <h5>센서 맵 위치</h5>
//     //   </Card.Header>
//     //   <Card.Body>
//     <div
//       ref={sensorMapRef}
//       id="sensorMap"
//       style={{
//         position: 'relative',
//         width: '100%',
//         height: '100%',
//         minHeight: '260px'
//       }}
//     >
//       <div
//         ref={sensorMapTooltipRef}
//         id="info"
//         style={{
//           position: 'absolute',
//           display: 'inline-block',
//           height: 'auto',
//           width: 'auto',
//           zIndex: '100',
//           backgroundColor: '#333',
//           color: '#fff',
//           textAlign: 'center',
//           borderRadius: '4px',
//           padding: '5px',
//           left: '50%',
//           transform: 'translateX(3%)',
//           visibility: 'hidden',
//           pointerEvents: 'none'
//         }}
//       ></div>
//     </div>
//     //   </Card.Body>
//     // </Card>
//   );
// };

// export default SensorMap;
