import { ChatContext } from 'context/Context';
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'react-bootstrap';
import ChatContentHeader from './ChatContentHeader';
import threads from 'data/chat/threads';
import ChatContentBody from './ChatContentBody';
import MessageTextArea from './MessageTextArea';

import { chainAxios } from 'helpers/chainAxios';

const ChatContent = ({ setHideSidebar, filterStatus, receivedMessage }) => {
  const [threads, setThreads] = useState([]);

  useEffect(() => {
    chatroomsList();
  }, []);

  useEffect(() => {
    chatroomsList();
  }, [filterStatus]);

  const chatroomsList = () => {
    let url =
      '/api/chatrooms?pageNo=0&pageSize=20&sort=createDate$desc&filters=status$' +
      filterStatus;

    chainAxios
      .get(url)
      .then(response => {
        // console.log('ChatSidebar chatroomsList() : ', response);
        if (response.data.resultCode === 200) {
          // console.log(
          //   'chatroomsList() threads : ',
          //   response.data.data.chatroomList.dataList
          // );
          setThreads(response.data.data.chatroomList.dataList);
        }

        if (response.data.resultCode === 404) {
          setThreads([]);
        }
      })
      .catch(error => {
        console.log('ChatSidebar chatroomsList() : ', error);
      });
  };

  return (
    <Tab.Content className="card-chat-content">
      {threads.map((thread, index) => (
        <Tab.Pane
          key={thread.id}
          eventKey={thread.id}
          className="card-chat-pane"
        >
          <ChatContentHeader thread={thread} setHideSidebar={setHideSidebar} />
          <ChatContentBody thread={thread} receivedMessage={receivedMessage} />
        </Tab.Pane>
      ))}
      {/* <Tab.Pane
        className="card-chat-pane"
        key={currentThread.id}
        eventKey={currentThread.id}
        style={{ display: 'contents' }}
      >
        <ChatContentHeader setHideSidebar={setHideSidebar} />
        <ChatContentBody />
      </Tab.Pane> */}
      <MessageTextArea />
    </Tab.Content>
  );
};

ChatContent.propTypes = {
  setHideSidebar: PropTypes.func.isRequired
};

export default ChatContent;
