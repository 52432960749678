import React, { useEffect, useState, useContext } from 'react';
import {
  Form,
  Tabs,
  Tab,
  Nav,
  FormControl,
  Button,
  InputGroup
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MapsContext } from 'context/Context';

import SimpleBarReact from 'simplebar-react';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import RegionItem from './RegionItem';

const RegionContent = () => {
  const orginRegions = [
    {
      id: 1,
      favorite: false,
      name: '경기도 고양시 덕양구',
      riskScore: '33',
      status: '1',
      nearAccident: '2023-05-31',
      regionId: '4128510600',
      colCode: '41280',
      rgnCode: '41281',
      area: 165082570,
      weatherAi: 'ML20240731',
      underWaterAi: 'ML20240731',
      earthquakeAi: 'ML20240731',
      facilityAi: 'ML20240731'
    },
    {
      id: 2,
      favorite: false,
      name: '경기도 고양시 일산동구',
      riskScore: '77',
      status: '3',
      nearAccident: '2022-10-05',
      regionId: '4128510600',
      colCode: '41280',
      rgnCode: '41285',
      area: 59961625,
      weatherAi: 'ML20240731',
      underWaterAi: 'ML20240731',
      earthquakeAi: 'ML20240731',
      facilityAi: 'ML20240731'
    },
    {
      id: 3,
      favorite: true,
      name: '경기도 고양시 일산서구',
      riskScore: '64',
      status: '3',
      nearAccident: '2024-07-23',
      regionId: '4128510600',
      colCode: '41280',
      rgnCode: '41287',
      area: 41299413,
      weatherAi: 'ML20240731',
      underWaterAi: 'ML20240731',
      earthquakeAi: 'ML20240731',
      facilityAi: 'ML20240731'
    },
    {
      id: 3,
      favorite: true,
      name: '경기도 고양시',
      riskScore: '33',
      status: '1',
      nearAccident: '2024-07-23',
      regionId: '4128510600',
      colCode: '41280',
      rgnCode: '41280',
      area: 266345080,
      weatherAi: 'ML20240731',
      underWaterAi: 'ML20240731',
      earthquakeAi: 'ML20240731',
      facilityAi: 'ML20240731'
    },
    {
      id: 4,
      favorite: false,
      name: '서울시 금천구',
      riskScore: '84',
      status: '3',
      nearAccident: '2024-09-30',
      regionId: '1154510200',
      colCode: '11000',
      rgnCode: '11545',
      area: 13021517,
      weatherAi: 'ML20240731',
      underWaterAi: 'ML20240731',
      earthquakeAi: 'ML20240731',
      facilityAi: 'ML20240731'
    }
  ];

  const {
    selectedProject,
    setSelectedProject,
    selectedRegion,
    setSelectedRegion
  } = useContext(MapsContext);
  const [viewRegions, setViewRegions] = useState([]);
  const [selectedTabValue, setSelectedTabValue] = useState('ALL');
  const [searchText, setSearchText] = useState('');

  const [totalElements, setTotalElements] = useState(0);

  useEffect(() => {
    if (!!selectedProject && JSON.stringify(selectedProject) !== '{}') {
      setViewRegions(
        orginRegions.filter(
          region => region.regionId === selectedProject.regions[0]
        )
      );
      setTotalElements(
        orginRegions.filter(
          region => region.regionId === selectedProject.regions[0]
        ).length
      );
      return;
    }

    if (!!selectedRegion && JSON.stringify(selectedRegion) !== '{}') {
      console.log(selectedRegion);
      setViewRegions(
        orginRegions.filter(
          region => region.colCode === selectedRegion.properties.colCode
        )
      );
      setTotalElements(
        orginRegions.filter(
          region => region.colCode === selectedRegion.properties.colCode
        ).length
      );
      return;
    }

    setViewRegions(orginRegions);
  }, []);

  useEffect(() => {
    if (!!selectedProject && JSON.stringify(selectedProject) !== '{}') {
      setViewRegions(
        orginRegions.filter(
          region => region.regionId === selectedProject.regions[0]
        )
      );
      setTotalElements(
        orginRegions.filter(
          region => region.regionId === selectedProject.regions[0]
        ).length
      );
    }
    // else {
    //   setViewRegions(orginRegions);
    //   setTotalElements(orginRegions.length);
    // }

    setSelectedTabValue('ALL');
  }, [selectedProject]);

  useEffect(() => {
    if (!!selectedRegion && JSON.stringify(selectedRegion) !== '{}') {
      setViewRegions(
        orginRegions.filter(
          region => region.colCode === selectedRegion.properties.colCode
        )
      );
      setTotalElements(
        orginRegions.filter(
          region => region.colCode === selectedRegion.properties.colCode
        ).length
      );
    }
    // else {
    //   setViewRegions(orginRegions);
    //   setTotalElements(orginRegions.length);
    // }

    setSelectedTabValue('ALL');
  }, [selectedRegion]);

  const onTabChange = value => {
    setSelectedTabValue(value);

    let filterOrginRegions = orginRegions;
    if (!!selectedProject && JSON.stringify(selectedProject) !== '{}') {
      filterOrginRegions = orginRegions.filter(
        region => region.regionId === selectedProject.regions[0]
      );
    }
    if (!!selectedRegion && JSON.stringify(selectedRegion) !== '{}') {
      filterOrginRegions = orginRegions.filter(
        region => region.colCode === selectedRegion.properties.colCode
      );
    }

    if (value === 'ALL') {
      setViewRegions(filterOrginRegions);
      setTotalElements(filterOrginRegions.length);
    }
    if (value === 'A') {
      setViewRegions(filterOrginRegions.filter(f => f.status === '4'));
      setTotalElements(filterOrginRegions.filter(f => f.status === '4').length);
    }
    if (value === 'B') {
      setViewRegions(filterOrginRegions.filter(f => f.status === '3'));
      setTotalElements(filterOrginRegions.filter(f => f.status === '3').length);
    }
    if (value === 'C') {
      setViewRegions(filterOrginRegions.filter(f => f.status === '2'));
      setTotalElements(filterOrginRegions.filter(f => f.status === '2').length);
    }
    if (value === 'D') {
      setViewRegions(filterOrginRegions.filter(f => f.status === '1'));
      setTotalElements(filterOrginRegions.filter(f => f.status === '1').length);
    }
    if (value === 'E') {
      setViewRegions(filterOrginRegions.filter(f => f.status === '0'));
      setTotalElements(filterOrginRegions.filter(f => f.status === '0').length);
    }
    if (value === 'FAVORITE') {
      setViewRegions(filterOrginRegions.filter(f => f.favorite));
      setTotalElements(filterOrginRegions.filter(f => f.favorite).length);
    }
  };

  return (
    <>
      <InputGroup className="position-relative">
        <FormControl
          size="sm"
          id="search"
          type="search"
          className="shadow-none"
          placeholder="검색어를 입력하세요."
          // onChange={e => handleTicketsSearch(e.target.value)}
        />
        <Button
          size="sm"
          variant="outline-secondary"
          className="border-300 hover-border-secondary"
        >
          <FontAwesomeIcon icon="search" className="fs--1" />
        </Button>
      </InputGroup>
      <Flex
        alignItems="center"
        alignContent={'center'}
        direction="row"
        justifyContent="between"
        className={'px-3'}
      >
        <Flex
          alignItems="end"
          alignContent={'end'}
          direction="column"
          justifyContent="start"
          className={'fs--1'}
        >
          총 {totalElements}개소
        </Flex>
        <Flex
          alignItems={'end'}
          alignContent={'end'}
          direction="column"
          justifyContent="end"
          className={'fs--1'}
        >
          <Form.Check
            type="switch"
            id="checkedSwitch"
            label="즐겨찾기"
            // defaultChecked
          />
        </Flex>
      </Flex>
      <Tabs
        activeKey={selectedTabValue}
        id="fill-project-tab"
        className={classNames('nav')}
        onSelect={onTabChange}
        fill
      >
        <Tab eventKey="ALL" title={<h6>전체</h6>}></Tab>
        <Tab eventKey="A" title={<h6>A</h6>}></Tab>
        <Tab eventKey="B" title={<h6>B</h6>}></Tab>
        <Tab eventKey="C" title={<h6>C</h6>}></Tab>
        <Tab eventKey="D" title={<h6>D</h6>}></Tab>
        <Tab eventKey="E" title={<h6>E</h6>}></Tab>
        {/*<Tab eventKey="BEFORE" title="예정"></Tab>*/}
        {/*<Tab eventKey="FINISH" title="완료"></Tab>*/}
      </Tabs>
      <SimpleBarReact
        style={{ height: '100%', minWidth: '65px' }}
        className={'mt-0'}
      >
        <Nav className="border-0" activeKey="ALL">
          {viewRegions.map((region, index) => (
            <RegionItem key={index} region={region} />
          ))}
        </Nav>
      </SimpleBarReact>
    </>
  );
};

export default RegionContent;
