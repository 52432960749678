import React, { useContext, useEffect, useRef, useState } from 'react';
import SimpleBarReact from 'simplebar-react';
import { chainAxios } from 'helpers/chainAxios';
import Message from 'components/common/chat/content/Message';
import { SocketContext } from 'context/Context';
import { useQueryClient } from 'react-query';

const ChatContentBody = ({ thread }) => {
  const [chatList, setChatList] = useState([]);
  const messagesEndRef = useRef();
  const [scrollToBottom, setScrollToBottom] = useState(true);

  const { stompChatClient } = useContext(SocketContext);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (stompChatClient.connected) {
      stompChatClient.subscribe('/chat', message => {
        addNewMessage(message);
      });
    } else {
      stompChatClient.connect({}, frame => {
        stompChatClient.subscribe('/chat', message => {
          addNewMessage(message);
        });
      });
    }
    queryClient.invalidateQueries(['getChatrooms']);
  }, []);

  useEffect(() => {
    chatroomsChat();
  }, []);

  const addNewMessage = msg => {
    let receivedMsg = JSON.parse(msg.body);
    // console.log('/chat : ', receivedMsg);
    // console.log('thread : ', thread);
    if (receivedMsg.chatroom.key === thread.id) {
      console.log('addNewMessage : ', chatList);
      // prevArray => [...prevArray, newValue]
      setChatList(chatList => [...chatList, receivedMsg]);

      setScrollToBottom(true);
    }
  };

  useEffect(() => {
    if (scrollToBottom) {
      setTimeout(() => {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 400);
      setScrollToBottom(false);
    }
  }, [scrollToBottom]);

  const chatroomsChat = () => {
    let url =
      'api/chatroom/' +
      thread.id +
      "/chats?pageNo=0&pageSize=99999&sort=createDate$desc'";
    chainAxios
      .get(url)
      .then(response => {
        // console.log('ChatContentBody chatroomsChat() : ', response);
        if (response.data.resultCode === 200) {
          setChatList(response.data.data.chatList.dataList);
          // console.log('chatList : ', chatList);
        }
      })
      .catch(error => {
        console.log('ChatContentBody chatroomsChat() : ', error);
      });
  };

  return (
    <div className="chat-content-body" style={{ display: 'inherit' }}>
      {/* <ThreadInfo thread={thread} isOpenThreadInfo={true} /> */}
      <SimpleBarReact style={{ height: '100%' }}>
        <div className="chat-content-scroll-area">
          {chatList != [] &&
            chatList.map((chat, index) => (
              <div key={index}>
                <Message
                  message={chat.message}
                  senderUserId={chat.sender.key}
                  senderName={chat.sender.value}
                  time={chat.createDate}
                  status={chat.chatStatus}
                  attachments={chat.attachments}
                  isGroup={false}
                  key={index}
                />
              </div>
            ))}
          {/* <ChatContentBodyIntro user={user} />
          {content.map(({ message, time, senderUserId, status }, index) => (
            <div key={index}>
              {lastDate !== time.date && (
                <div className="text-center fs--2 text-500">{`${time.date}, ${time.hour}`}</div>
              )}
              {(() => {
                lastDate = time.date;
              })()}
              <Message
                message={message}
                senderUserId={senderUserId}
                time={time}
                status={status}
                isGroup={thread.type === 'group'}
              />
            </div>
          ))} */}
        </div>
        <div ref={messagesEndRef} />
      </SimpleBarReact>
    </div>
  );
};

export default ChatContentBody;
