import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getColor, isEmpty, isJSON } from 'helpers/utils';
import * as echarts from 'echarts/core';

import Flex from 'components/common/Flex';
import BasicECharts from 'components/common/BasicEChart';
import { chainAxios } from 'helpers/chainAxios';
import { MapsContext } from 'context/Context';

const SubsidenceRiskPredictionChart = () => {
  const {
    selectedProject,
    setSelectedProject,
    selectedRegion,
    setSelectedRegion
  } = useContext(MapsContext);

  const [chartTitle, setChartTitle] = useState('');
  const [subsidenceRiskPredictionShare, setSubsidenceRiskPredictionShare] =
    useState([]);

  useEffect(() => {
    if (!!selectedProject && JSON.stringify(selectedProject) !== '{}') {
      makeSelectedProjectDummyChartData(selectedProject);
      setChartTitle(selectedProject.name);
      return;
    }

    if (!!selectedRegion && JSON.stringify(selectedRegion) !== '{}') {
      makeSeletedRegionDummyChartData(selectedRegion);

      if (isJSON(selectedRegion.properties.rgnKo)) {
        setChartTitle(JSON.parse(selectedRegion.properties.rgnKo).join(' '));
      } else {
        setChartTitle(selectedRegion.properties.rgnKo.join(' '));
      }
      return;
    }

    makeSubsidenceRiskPredictionChartData();
    setChartTitle('');
  }, []);

  useEffect(() => {
    if (!!selectedProject && JSON.stringify(selectedProject) !== '{}') {
      makeSelectedProjectDummyChartData(selectedProject);
      setChartTitle(selectedProject.name);
    }
  }, [selectedProject]);

  useEffect(() => {
    if (!!selectedRegion && JSON.stringify(selectedRegion) !== '{}') {
      makeSeletedRegionDummyChartData(selectedRegion);

      if (isJSON(selectedRegion.properties.rgnKo)) {
        setChartTitle(JSON.parse(selectedRegion.properties.rgnKo).join(' '));
      } else {
        setChartTitle(selectedRegion.properties.rgnKo.join(' '));
      }
    }
  }, [selectedRegion]);

  const makeSeletedRegionDummyChartData = region => {
    let subsidenceRiskPredictionChartData = [];

    let aClass = 0;
    let bClass = 0;
    let cClass = 0;
    let dClass = 0;
    let eClass = 0;

    if (region.properties.rgnCode === '41280') {
      aClass = 0;
      bClass = 2;
      cClass = 0;
      dClass = 2;
      eClass = 0;
    } else {
      aClass = 0;
      bClass = 1;
      cClass = 0;
      dClass = 0;
      eClass = 0;
    }

    subsidenceRiskPredictionChartData.push({
      id: 1,
      name: 'A등급',
      value: aClass,
      color: 'primary'
    });
    subsidenceRiskPredictionChartData.push({
      id: 2,
      name: 'B등급',
      value: bClass,
      color: 'info'
    });
    subsidenceRiskPredictionChartData.push({
      id: 3,
      name: 'C등급',
      value: cClass,
      color: 'secondary'
    });
    subsidenceRiskPredictionChartData.push({
      id: 4,
      name: 'D등급',
      value: dClass,
      color: 'warning'
    });
    subsidenceRiskPredictionChartData.push({
      id: 5,
      name: 'E등급',
      value: eClass,
      color: 'danger'
    });

    setSubsidenceRiskPredictionShare(subsidenceRiskPredictionChartData);
  };

  const makeSelectedProjectDummyChartData = project => {
    console.log(
      'SubsidenceRiskPredictionChart makeSelectedProjectDummyChartData : ',
      project
    );
    let subsidenceProjectDummyChartData = [];

    let aClass = 0;
    let bClass = 1;
    let cClass = 0;
    let dClass = 0;
    let eClass = 0;

    if (project.id.toString() === '1') {
      aClass = 0;
      bClass = 2;
      cClass = 0;
      dClass = 2;
      eClass = 0;
    }

    subsidenceProjectDummyChartData.push({
      id: 1,
      name: 'A등급',
      value: aClass,
      color: 'primary'
    });
    subsidenceProjectDummyChartData.push({
      id: 2,
      name: 'B등급',
      value: bClass,
      color: 'info'
    });
    subsidenceProjectDummyChartData.push({
      id: 3,
      name: 'C등급',
      value: cClass,
      color: 'secondary'
    });
    subsidenceProjectDummyChartData.push({
      id: 4,
      name: 'D등급',
      value: dClass,
      color: 'warning'
    });
    subsidenceProjectDummyChartData.push({
      id: 5,
      name: 'E등급',
      value: eClass,
      color: 'danger'
    });

    setSubsidenceRiskPredictionShare(subsidenceProjectDummyChartData);
  };

  const makeSubsidenceRiskPredictionChartData = () => {
    console.log(
      'SubsidenceRiskPredictionChart makeSubsidenceRiskPredictionChartData'
    );

    let subsidenceRiskPredictionChartData = [];

    let aClass = 0;
    let bClass = 3;
    let cClass = 0;
    let dClass = 2;
    let eClass = 0;

    subsidenceRiskPredictionChartData.push({
      id: 1,
      name: 'A등급',
      value: aClass,
      color: 'primary'
    });
    subsidenceRiskPredictionChartData.push({
      id: 2,
      name: 'B등급',
      value: bClass,
      color: 'info'
    });
    subsidenceRiskPredictionChartData.push({
      id: 3,
      name: 'C등급',
      value: cClass,
      color: 'secondary'
    });
    subsidenceRiskPredictionChartData.push({
      id: 4,
      name: 'D등급',
      value: dClass,
      color: 'warning'
    });
    subsidenceRiskPredictionChartData.push({
      id: 5,
      name: 'E등급',
      value: eClass,
      color: 'danger'
    });

    setSubsidenceRiskPredictionShare(subsidenceRiskPredictionChartData);
  };

  const radius = ['100%', '65%'];

  const tooltipFormatter = params => {
    // console.log(params);
    return `<div class="dot me-1 fs--2 d-inline-block" style="background-color: ${getColor(
      params.data.color
    )}"></div><span class="fs--1"><strong>${params.data.name}:</strong> ${
      params.value
    }개소</span><br/>`;
  };

  const getOptions = (data, radius) => ({
    color: [
      getColor('primary'),
      getColor('info'),
      getColor('secondary'),
      getColor('warning'),
      getColor('danger')
    ],

    tooltip: {
      padding: [7, 10],
      transitionDuration: 0,
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: tooltipFormatter
    },
    series: [
      {
        name: '지역 현황\r\n' + total + '개소',
        type: 'pie',
        radius,
        avoidLabelOverlap: false,
        emphasis: {
          scale: false
        },
        itemStyle: {
          borderWidth: 2,
          borderColor: getColor('gray-100')
        },
        label: {
          show: true,
          position: 'center',
          formatter: '{a}',
          fontSize: '0.8333333333rem',
          fontFamily: 'Pretendard',
          color: getColor('dark')
        },
        data
      }
    ]
  });

  const total = subsidenceRiskPredictionShare.reduce(
    (acc, val) => val.value + acc,
    0
  );

  const SensorItem = ({ item, index, total }) => {
    const { name, color, value } = item;

    // const percentage = ((value * 100) / total).toFixed(0);
    return (
      <Flex
        alignItems="center"
        justifyContent="between"
        className={`px-4 fw-semi-bold fs--2 ${index == 0 && 'mt-3'}`}
      >
        <p className="mb-1">
          <FontAwesomeIcon
            icon="circle"
            className={`me-2 text-${
              color.split('-')[1] || color || 'text-info'
            }`}
          />
          {name}
        </p>
        {/* <div className="d-xxl-none">{percentage}%</div> */}
        <div>{value} 개소</div>
      </Flex>
    );
  };

  return (
    // <Card className="h-md-100">
    //   <Card.Body>
    //     <Row className="g-0 h-100">
    <>
      <Flex
        direction="column"
        justifyContent="between"
        className="rounded-1 h-100"
        alignItems="center"
      >
        <h6 className={'fs--1 text-900'}>
          {!isEmpty(selectedProject) || !isEmpty(selectedRegion)
            ? chartTitle + ' 지역'
            : '전체 지역'}
        </h6>
      </Flex>
      <Flex
        direction="column"
        justifyContent="center"
        className="rounded-1 h-100 mb-1"
        alignItems="center"
      >
        <BasicECharts
          echarts={echarts}
          options={getOptions(subsidenceRiskPredictionShare, radius)}
          style={{ width: '10rem', height: '10rem' }}
          // style={{ width: '10rem', height: '15.8rem' }}
        />
      </Flex>
      <Col className="px-2 text-center">
        {subsidenceRiskPredictionShare.map((item, index) => (
          <SensorItem item={item} index={index} key={index} total={total} />
        ))}
      </Col>
    </>
  );
};

export default SubsidenceRiskPredictionChart;
