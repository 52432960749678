import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import team3 from 'assets/img/team/3.jpg';
import Avatar from 'components/common/Avatar';
import { LoginUserContext } from '../../../context/Context';

const ProfileDropdown = () => {
  const { loginUser } = useContext(LoginUserContext);
  // console.log(loginUser);
  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        {/*<Avatar src={team3} />*/}
        <Avatar
          size="xl"
          isExact={true}
          name={
            Object.keys(loginUser).length !== 0
              ? loginUser.name.substring(0, 1)
              : '-'
          }
          className="me-1"
        />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          {/*<Dropdown.Item className="fw-bold text-warning" href="#!">*/}
          {/*  <FontAwesomeIcon icon="crown" className="me-1" />*/}
          {/*  <span>Go Pro</span>*/}
          {/*</Dropdown.Item>*/}
          {/*<Dropdown.Divider />*/}
          {/*<Dropdown.Item href="#!">Set status</Dropdown.Item>*/}
          {/*<Dropdown.Item as={Link} to="/user/profile">*/}
          {/*  Profile &amp; account*/}
          {/*</Dropdown.Item>*/}
          {/*<Dropdown.Item href="#!">Feedback</Dropdown.Item>*/}
          {/*<Dropdown.Divider />*/}
          {/*<Dropdown.Item as={Link} to="/user/settings">*/}
          {/*  Settings*/}
          {/*</Dropdown.Item>*/}
          <Dropdown.Item as={Link} to="/logout">
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
