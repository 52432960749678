export const accGeoData = {
  type: 'FeatureCollection',
  name: 'accgeo',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      id: 0,
      properties: {
        sagoDate: '2018-01-02',
        sagoNo: '20180018',
        sido: '전북특별자치도',
        sigungu: '정읍시',
        dong: '이평면 창동리',
        addr: '산11-5번지 근처',
        fullAdr: '전북특별자치도 정읍시 이평면 창동리 산11-5번지 근처',
        SEC_CD: '52180370',
        sagoLon: '126.809',
        sagoLat: '35.6698',
        sinkWidth: '1.5',
        sinkExtend: '1.8',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '농배수로관 노후로 인하여 지반 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-12'
      },
      geometry: { type: 'Point', coordinates: [126.809, 35.6698] }
    },
    {
      type: 'Feature',
      id: 1,
      properties: {
        sagoDate: '2018-01-03',
        sagoNo: '20180019',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '도산동',
        addr: '1221-20번지',
        fullAdr: '광주광역시 광산구 도산동 1221-20',
        SEC_CD: '29200102',
        sagoLon: '126.783',
        sagoLat: '35.1269',
        sinkWidth: '0.3',
        sinkExtend: '0.2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수암거박스 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-01-04'
      },
      geometry: { type: 'Point', coordinates: [126.783, 35.1269] }
    },
    {
      type: 'Feature',
      id: 2,
      properties: {
        sagoDate: '2018-01-12',
        sagoNo: '20180020',
        sido: '경상남도',
        sigungu: '사천시',
        dong: '사천읍',
        addr: '선인길 30-21',
        fullAdr: '경상남도 사천시 사천읍 선인길 30-21',
        SEC_CD: '48240250',
        sagoLon: '128.0965',
        sagoLat: '35.0796',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '0.1',
        grdKind: '기타',
        sagoDetail: '오수맨홀 주변 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-01-12'
      },
      geometry: { type: 'Point', coordinates: [128.0965, 35.0796] }
    },
    {
      type: 'Feature',
      id: 3,
      properties: {
        sagoDate: '2018-01-16',
        sagoNo: '20180021',
        sido: '충청북도',
        sigungu: '청주시 흥덕구',
        dong: '운천동',
        addr: '660',
        fullAdr: '충청북도 청주시 흥덕구 운천동 662-1',
        SEC_CD: '43113104',
        sagoLon: '127.4745',
        sagoLat: '36.6554',
        sinkWidth: '0.8',
        sinkExtend: '0.8',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-01-17'
      },
      geometry: { type: 'Point', coordinates: [127.4745, 36.6554] }
    },
    {
      type: 'Feature',
      id: 4,
      properties: {
        sagoDate: '2018-01-22',
        sagoNo: '20180022',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '우산동',
        addr: '1600-1번지',
        fullAdr: '광주광역시 광산구 우산동 1600-1',
        SEC_CD: '29200109',
        sagoLon: '126.8062',
        sagoLat: '35.1613',
        sinkWidth: '0.1',
        sinkExtend: '0.1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관로 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-01-25'
      },
      geometry: { type: 'Point', coordinates: [126.8062, 35.1613] }
    },
    {
      type: 'Feature',
      id: 5,
      properties: {
        sagoDate: '2018-01-22',
        sagoNo: '20180023',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '소촌동',
        addr: '450-3번지',
        fullAdr: '광주광역시 광산구 어등대로647번북길 8 (소촌동)',
        SEC_CD: '29200108',
        sagoLon: '126.793',
        sagoLat: '35.1459',
        sinkWidth: '0.3',
        sinkExtend: '0.3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수암거박스 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-01-25'
      },
      geometry: { type: 'Point', coordinates: [126.793, 35.1459] }
    },
    {
      type: 'Feature',
      id: 6,
      properties: {
        sagoDate: '2018-01-24',
        sagoNo: '20180024',
        sido: '울산광역시',
        sigungu: '울주군',
        dong: '온산읍 대정리',
        addr: '70-5 (온산항 사거리앞 울산에서 부산방면 1차로)',
        fullAdr:
          '울산광역시 울주군 온산읍 대정리 70-5 (온산항 사거리앞 울산에서 부산방면 1차로)',
        SEC_CD: '31710250',
        sagoLon: '129.351',
        sagoLat: '35.4385',
        sinkWidth: '1.1',
        sinkExtend: '1.1',
        sinkDepth: '1.2',
        grdKind: '',
        sagoDetail:
          '송유관로 매설을 위한 도로 횡단압입 굴착공사 중 싱크홀 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-01-25'
      },
      geometry: { type: 'Point', coordinates: [129.351, 35.4385] }
    },
    {
      type: 'Feature',
      id: 7,
      properties: {
        sagoDate: '2018-01-25',
        sagoNo: '20180025',
        sido: '경기도',
        sigungu: '군포시',
        dong: '산본동',
        addr: '1189',
        fullAdr: '경기도 군포시 산본동 1189',
        SEC_CD: '41410104',
        sagoLon: '126.9304',
        sagoLat: '37.3601',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '하수관로 교체작업 다짐불량으로 인하여 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-02-01'
      },
      geometry: { type: 'Point', coordinates: [126.9304, 37.3601] }
    },
    {
      type: 'Feature',
      id: 8,
      properties: {
        sagoDate: '2018-01-26',
        sagoNo: '20180026',
        sido: '부산광역시',
        sigungu: '해운대구',
        dong: '',
        addr: '좌동순환로 419 앞',
        fullAdr: '부산광역시 해운대구 좌동순환로 419 (중동)',
        SEC_CD: '26350106',
        sagoLon: '129.175',
        sagoLat: '35.1616',
        sinkWidth: '1',
        sinkExtend: '4',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 노후로 인한 하수누수에 따른 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-01-26'
      },
      geometry: { type: 'Point', coordinates: [129.175, 35.1616] }
    },
    {
      type: 'Feature',
      id: 9,
      properties: {
        sagoDate: '2018-01-28',
        sagoNo: '20180027',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '',
        addr: '학성안길41',
        fullAdr: '강원특별자치도 원주시  학성안길41',
        SEC_CD: '51130109',
        sagoLon: '127.947',
        sagoLat: '37.3554',
        sinkWidth: '2.5',
        sinkExtend: '5.5',
        sinkDepth: '1.5',
        grdKind: '충척층',
        sagoDetail: '상수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '15300000',
        trFnDate: '2018-01-28'
      },
      geometry: { type: 'Point', coordinates: [127.947, 37.3554] }
    },
    {
      type: 'Feature',
      id: 10,
      properties: {
        sagoDate: '2018-01-28',
        sagoNo: '20180028',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '',
        addr: '행구로257아욱이',
        fullAdr: '강원특별자치도 원주시  행구로257아욱이',
        SEC_CD: '51130114',
        sagoLon: '127.984',
        sagoLat: '37.3434',
        sinkWidth: '3',
        sinkExtend: '3.5',
        sinkDepth: '1.6',
        grdKind: '충척층',
        sagoDetail: '상수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '17000000',
        trFnDate: '2018-01-28'
      },
      geometry: { type: 'Point', coordinates: [127.984, 37.3434] }
    },
    {
      type: 'Feature',
      id: 11,
      properties: {
        sagoDate: '2018-01-31',
        sagoNo: '20180029',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '단구동',
        addr: '1515-4',
        fullAdr: '강원특별자치도 원주시 단구동 1515-4',
        SEC_CD: '51130107',
        sagoLon: '127.949',
        sagoLat: '37.3304',
        sinkWidth: '2.7',
        sinkExtend: '5',
        sinkDepth: '1.6',
        grdKind: '충척층',
        sagoDetail: '상수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '2030000',
        trFnDate: '2018-01-31'
      },
      geometry: { type: 'Point', coordinates: [127.949, 37.3304] }
    },
    {
      type: 'Feature',
      id: 12,
      properties: {
        sagoDate: '2018-02-01',
        sagoNo: '20180030',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '흥업면 매지리',
        addr: '4018 무수막다리',
        fullAdr: '강원특별자치도 원주시 흥업면 매지리 4018 무수막다리',
        SEC_CD: '51130370',
        sagoLon: '127.902',
        sagoLat: '37.2594',
        sinkWidth: '2.5',
        sinkExtend: '3.8',
        sinkDepth: '1.5',
        grdKind: '충척층',
        sagoDetail: '상수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1270000',
        trFnDate: '2018-02-01'
      },
      geometry: { type: 'Point', coordinates: [127.902, 37.2594] }
    },
    {
      type: 'Feature',
      id: 13,
      properties: {
        sagoDate: '2018-02-01',
        sagoNo: '20180031',
        sido: '충청북도',
        sigungu: '청주시 상당구',
        dong: '방서동',
        addr: '644-117',
        fullAdr: '충청북도 청주시 상당구 방서동 644-117',
        SEC_CD: '43111126',
        sagoLon: '127.5054',
        sagoLat: '36.5984',
        sinkWidth: '2',
        sinkExtend: '3',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '하수관 파손에 의한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-02-02'
      },
      geometry: { type: 'Point', coordinates: [127.5054, 36.5984] }
    },
    {
      type: 'Feature',
      id: 14,
      properties: {
        sagoDate: '2018-02-06',
        sagoNo: '20180032',
        sido: '경기도',
        sigungu: '부천시',
        dong: '소사본동',
        addr: ' 148-9',
        fullAdr: '경기도 부천시 소사구 소사본동 148-9',
        SEC_CD: '41194101',
        sagoLon: '126.792',
        sagoLat: '37.477',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '하수관 파손으로 인한 토사 유출로 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-17'
      },
      geometry: { type: 'Point', coordinates: [126.792, 37.477] }
    },
    {
      type: 'Feature',
      id: 15,
      properties: {
        sagoDate: '2018-02-06',
        sagoNo: '20180033',
        sido: '경기도',
        sigungu: '부천시',
        dong: '원미동',
        addr: ' 76-1번지',
        fullAdr: '경기도 부천시 원미구 원미동 76-1',
        SEC_CD: '41192101',
        sagoLon: '126.7918',
        sagoLat: '37.4952',
        sinkWidth: '2',
        sinkExtend: '4',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수관로 파손에 따른 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-21'
      },
      geometry: { type: 'Point', coordinates: [126.7918, 37.4952] }
    },
    {
      type: 'Feature',
      id: 16,
      properties: {
        sagoDate: '2018-02-07',
        sagoNo: '20180034',
        sido: '충청북도',
        sigungu: '청주시 흥덕구',
        dong: '복대동',
        addr: '31-6',
        fullAdr: '충청북도 청주시 흥덕구 복대동 31-6',
        SEC_CD: '43113114',
        sagoLon: '127.4405',
        sagoLat: '36.6342',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '연결관 불량에 의한 하수누수로 인한 지반 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-02-08'
      },
      geometry: { type: 'Point', coordinates: [127.4405, 36.6342] }
    },
    {
      type: 'Feature',
      id: 17,
      properties: {
        sagoDate: '2018-02-09',
        sagoNo: '20180035',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '월곡동',
        addr: '515-10번지',
        fullAdr: '광주광역시 광산구 월곡동 515-10',
        SEC_CD: '29200128',
        sagoLon: '126.8066',
        sagoLat: '35.1716',
        sinkWidth: '0.3',
        sinkExtend: '0.3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '연결관 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-02-10'
      },
      geometry: { type: 'Point', coordinates: [126.8066, 35.1716] }
    },
    {
      type: 'Feature',
      id: 18,
      properties: {
        sagoDate: '2018-02-09',
        sagoNo: '20180036',
        sido: '경기도',
        sigungu: '부천시',
        dong: '춘의동',
        addr: ' 72-5번지',
        fullAdr: '경기도 부천시 원미구 춘의동 72-5',
        SEC_CD: '41192103',
        sagoLon: '126.7881',
        sagoLat: '37.5056',
        sinkWidth: '2',
        sinkExtend: '1.8',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '하수관로 파손에 따른 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-21'
      },
      geometry: { type: 'Point', coordinates: [126.7881, 37.5056] }
    },
    {
      type: 'Feature',
      id: 19,
      properties: {
        sagoDate: '2018-02-19',
        sagoNo: '20180037',
        sido: '충청북도',
        sigungu: '청주시 흥덕구',
        dong: '복대동',
        addr: '2200',
        fullAdr: '충청북도 청주시 흥덕구 복대동 2200',
        SEC_CD: '43113114',
        sagoLon: '127.4465',
        sagoLat: '36.6276',
        sinkWidth: '0.8',
        sinkExtend: '0.8',
        sinkDepth: '0.8',
        grdKind: '',
        sagoDetail: '하수관 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-02-20'
      },
      geometry: { type: 'Point', coordinates: [127.4465, 36.6276] }
    },
    {
      type: 'Feature',
      id: 20,
      properties: {
        sagoDate: '2018-02-20',
        sagoNo: '20180038',
        sido: '부산광역시',
        sigungu: '수영구',
        dong: '',
        addr: '광안로 성원쌍떼빌 앞(광안역2번 출구)',
        fullAdr:
          '부산광역시 수영구 수영로582번길 10 (광안동·광안역 성원상떼빌)',
        SEC_CD: '26500104',
        sagoLon: '129.1136',
        sagoLat: '35.1574',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '0.7',
        grdKind: '',
        sagoDetail: '부산교통공사· 한전· 상수도 등 지하매설물 주변 다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-02-20'
      },
      geometry: { type: 'Point', coordinates: [129.1136, 35.1574] }
    },
    {
      type: 'Feature',
      id: 21,
      properties: {
        sagoDate: '2018-02-21',
        sagoNo: '20180039',
        sido: '경기도',
        sigungu: '부천시',
        dong: '춘의동',
        addr: ' 155-1번지',
        fullAdr: '경기도 부천시 원미구 춘의동 155-1',
        SEC_CD: '41192103',
        sagoLon: '126.7917',
        sagoLat: '37.5042',
        sinkWidth: '5.4',
        sinkExtend: '6',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-21'
      },
      geometry: { type: 'Point', coordinates: [126.7917, 37.5042] }
    },
    {
      type: 'Feature',
      id: 22,
      properties: {
        sagoDate: '2018-02-21',
        sagoNo: '20180040',
        sido: '경기도',
        sigungu: '부천시',
        dong: '원미동',
        addr: ' 111-8번지',
        fullAdr: '경기도 부천시 원미구 원미동 111-8',
        SEC_CD: '41192101',
        sagoLon: '126.7854',
        sagoLat: '37.4945',
        sinkWidth: '2.5',
        sinkExtend: '10',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관로 파손에 따른 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-21'
      },
      geometry: { type: 'Point', coordinates: [126.7854, 37.4945] }
    },
    {
      type: 'Feature',
      id: 23,
      properties: {
        sagoDate: '2018-02-21',
        sagoNo: '20180041',
        sido: '경기도',
        sigungu: '부천시',
        dong: '작동',
        addr: ' 5 로얄아파트 일원',
        fullAdr: '경기도 부천시 오정구 작동 5',
        SEC_CD: '41196103',
        sagoLon: '126.813',
        sagoLat: '37.5202',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '다짐불량에 따른 도로파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-07'
      },
      geometry: { type: 'Point', coordinates: [126.813, 37.5202] }
    },
    {
      type: 'Feature',
      id: 24,
      properties: {
        sagoDate: '2018-02-26',
        sagoNo: '20180042',
        sido: '경상남도',
        sigungu: '거제시',
        dong: '일운면 지세포리',
        addr: '416-17번지 일원 (도시계획도로 소로1-19호선)',
        fullAdr:
          '경상남도 거제시 일운면 지세포리 416-17번지 일원 (도시계획도로 소로1-19호선)',
        SEC_CD: '48310310',
        sagoLon: '128.710819',
        sagoLat: '34.829266',
        sinkWidth: '3.5',
        sinkExtend: '20',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '해안도로 호안옹벽 기초세굴에 따른 포장도로 지반 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1500000',
        trFnDate: '2018-04-20'
      },
      geometry: { type: 'Point', coordinates: [128.710819, 34.829266] }
    },
    {
      type: 'Feature',
      id: 25,
      properties: {
        sagoDate: '2018-03-01',
        sagoNo: '20180001',
        sido: '서울특별시',
        sigungu: '강남구',
        dong: '삼성동',
        addr: '147-2앞 (봉은사로 86길)',
        fullAdr: '서울특별시 강남구 삼성동 147-2',
        SEC_CD: '11680105',
        sagoLon: '127.056',
        sagoLat: '37.5133',
        sinkWidth: '3',
        sinkExtend: '4',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '열수송관(D600mm) 노후 손상으로 함몰 발생·(굴착복구 미흡분야로 관리)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-06'
      },
      geometry: { type: 'Point', coordinates: [127.056, 37.5133] }
    },
    {
      type: 'Feature',
      id: 26,
      properties: {
        sagoDate: '2018-03-04',
        sagoNo: '20180002',
        sido: '서울특별시',
        sigungu: '송파구',
        dong: '삼전동',
        addr: '180-6 (백제고분로 272)',
        fullAdr: '서울특별시 송파구 삼전동 180-6',
        SEC_CD: '11710106',
        sagoLon: '127.0965',
        sagoLat: '37.5021',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail:
          '상수관(？300mm)에서 분기된 소화전 연결관(？100mm) 동파로 누수되어 함몰발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-05'
      },
      geometry: { type: 'Point', coordinates: [127.0965, 37.5021] }
    },
    {
      type: 'Feature',
      id: 27,
      properties: {
        sagoDate: '2018-03-11',
        sagoNo: '20180003',
        sido: '서울특별시',
        sigungu: '구로구',
        dong: '구로동',
        addr: '723-16 (구로동로17길)',
        fullAdr: '서울특별시 구로구 구로동 723-16',
        SEC_CD: '11530102',
        sagoLon: '126.8835',
        sagoLat: '37.4884',
        sinkWidth: '0.4',
        sinkExtend: '0.4',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: 'KT 통신맨홀 슬라브 파손·(굴착복구 미흡분야로 관리)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-12'
      },
      geometry: { type: 'Point', coordinates: [126.8835, 37.4884] }
    },
    {
      type: 'Feature',
      id: 28,
      properties: {
        sagoDate: '2018-03-13',
        sagoNo: '20180004',
        sido: '서울특별시',
        sigungu: '영등포구',
        dong: '당산동4가',
        addr: '93-5 (국회대로 539)',
        fullAdr: '서울특별시 영등포구 당산동 4-1',
        SEC_CD: '11560117',
        sagoLon: '126.9017',
        sagoLat: '37.5428',
        sinkWidth: '0.5',
        sinkExtend: '0.7',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수관로(D450㎜) 노후？파손으로 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-18'
      },
      geometry: { type: 'Point', coordinates: [126.9017, 37.5428] }
    },
    {
      type: 'Feature',
      id: 29,
      properties: {
        sagoDate: '2018-03-27',
        sagoNo: '20180005',
        sido: '서울특별시',
        sigungu: '영등포구',
        dong: '양평동4가',
        addr: '23-2 (양평로19길 19)',
        fullAdr: '서울특별시 영등포구 양평동4가 4-2',
        SEC_CD: '11560128',
        sagoLon: '126.8964',
        sagoLat: '37.534',
        sinkWidth: '5',
        sinkExtend: '5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '인근 공사차량(레미콘차) 중량에 의한 노후 하수박스 상판파손(하수박스 1946년 부설)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-07'
      },
      geometry: { type: 'Point', coordinates: [126.8964, 37.534] }
    },
    {
      type: 'Feature',
      id: 30,
      properties: {
        sagoDate: '2018-03-28',
        sagoNo: '20180006',
        sido: '서울특별시',
        sigungu: '영등포구',
        dong: '여의도동',
        addr: '44-11 (의사당대로1길 34)',
        fullAdr: '서울특별시 영등포구 여의도동 44-11',
        SEC_CD: '11560110',
        sagoLon: '126.9307',
        sagoLat: '37.5201',
        sinkWidth: '0.3',
        sinkExtend: '0.3',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '폐쇄된 빗물받이 연결관을 폐관처리 하지 않아 토사가 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-28'
      },
      geometry: { type: 'Point', coordinates: [126.9307, 37.5201] }
    },
    {
      type: 'Feature',
      id: 31,
      properties: {
        sagoDate: '2018-03-01',
        sagoNo: '20180043',
        sido: '충청북도',
        sigungu: '음성군',
        dong: '음성읍 읍내리',
        addr: '350-1 삼보아파트 단지 내 인도',
        fullAdr: '충청북도 음성군 음성읍 읍내리 350-1',
        SEC_CD: '43770250',
        sagoLon: '127.6937',
        sagoLat: '36.9317',
        sinkWidth: '11',
        sinkExtend: '1.8',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '지하 공동피트 상부 슬래브 침하로 인한 인도 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-30'
      },
      geometry: { type: 'Point', coordinates: [127.6937, 36.9317] }
    },
    {
      type: 'Feature',
      id: 32,
      properties: {
        sagoDate: '2018-03-02',
        sagoNo: '20180044',
        sido: '경상남도',
        sigungu: '사천시',
        dong: '용현면 신복리 ',
        addr: '',
        fullAdr: '경상남도 사천시 용현면 신복리 914-6',
        SEC_CD: '48240330',
        sagoLon: '128.0656',
        sagoLat: '35.0339',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '0.1',
        grdKind: '기타',
        sagoDetail: '오수받이 연결배관 파손으로 주변토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-02'
      },
      geometry: { type: 'Point', coordinates: [128.0656, 35.0339] }
    },
    {
      type: 'Feature',
      id: 33,
      properties: {
        sagoDate: '2018-03-04',
        sagoNo: '20180045',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '개운동',
        addr: '399-19',
        fullAdr: '강원특별자치도 원주시 개운동 399-19',
        SEC_CD: '51130105',
        sagoLon: '127.957',
        sagoLat: '37.3402',
        sinkWidth: '3.1',
        sinkExtend: '4.5',
        sinkDepth: '1.8',
        grdKind: '충척층',
        sagoDetail: '상수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1820000',
        trFnDate: '2018-03-04'
      },
      geometry: { type: 'Point', coordinates: [127.957, 37.3402] }
    },
    {
      type: 'Feature',
      id: 34,
      properties: {
        sagoDate: '2018-03-05',
        sagoNo: '20180046',
        sido: '경기도',
        sigungu: '부천시',
        dong: '춘의동',
        addr: ' 부천종합운동장역',
        fullAdr: '경기도 부천시 원미구 길주로 486 (춘의동)',
        SEC_CD: '41192103',
        sagoLon: '126.7965',
        sagoLat: '37.5049',
        sinkWidth: '7',
        sinkExtend: '8',
        sinkDepth: '1.2',
        grdKind: '',
        sagoDetail: '하수맨홀 내부 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-21'
      },
      geometry: { type: 'Point', coordinates: [126.7965, 37.5049] }
    },
    {
      type: 'Feature',
      id: 35,
      properties: {
        sagoDate: '2018-03-07',
        sagoNo: '20180047',
        sido: '부산광역시',
        sigungu: '중구',
        dong: '영주동',
        addr: '모노레일 삼거리',
        fullAdr: '부산광역시 중구 영주동 292-10',
        SEC_CD: '26110101',
        sagoLon: '129.0318',
        sagoLat: '35.1096',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail:
          '해빙기 잦은 우천에 따른 노면수 침투로 지하 내 토사유실로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-28'
      },
      geometry: { type: 'Point', coordinates: [129.0318, 35.1096] }
    },
    {
      type: 'Feature',
      id: 36,
      properties: {
        sagoDate: '2018-03-07',
        sagoNo: '20180048',
        sido: '부산광역시',
        sigungu: '해운대구',
        dong: '',
        addr: '청사포로 126번길 6 일원',
        fullAdr: '부산광역시 해운대구 청사포로128번길 6 (중동)',
        SEC_CD: '26350106',
        sagoLon: '129.1922',
        sagoLat: '35.1608',
        sinkWidth: '1.2',
        sinkExtend: '2',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 노후화에 의한 관파손에 따른 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-07'
      },
      geometry: { type: 'Point', coordinates: [129.1922, 35.1608] }
    },
    {
      type: 'Feature',
      id: 37,
      properties: {
        sagoDate: '2018-03-07',
        sagoNo: '20180049',
        sido: '경기도',
        sigungu: '고양시 일산동구',
        dong: '설문동',
        addr: ' 199-2번지 일원',
        fullAdr: '경기도 고양시 일산동구 설문동 199-2',
        SEC_CD: '41285111',
        sagoLon: '126.8134',
        sagoLat: '37.711',
        sinkWidth: '2.5',
        sinkExtend: '2.5',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-07'
      },
      geometry: { type: 'Point', coordinates: [126.8134, 37.711] }
    },
    {
      type: 'Feature',
      id: 38,
      properties: {
        sagoDate: '2018-03-08',
        sagoNo: '20180050',
        sido: '부산광역시',
        sigungu: '사하구',
        dong: '',
        addr: '하단오거리 교차로(하단1동)',
        fullAdr: '부산광역시 사하구 하신중앙로 348 (하단동)',
        SEC_CD: '26380103',
        sagoLon: '128.9682',
        sagoLat: '35.1056',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail: '지하철공사구간으로 철골구조물 뺀자리에 다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-09'
      },
      geometry: { type: 'Point', coordinates: [128.9682, 35.1056] }
    },
    {
      type: 'Feature',
      id: 39,
      properties: {
        sagoDate: '2018-03-09',
        sagoNo: '20180051',
        sido: '경기도',
        sigungu: '부천시',
        dong: '소사본동',
        addr: ' 421-1(동남삼거리)',
        fullAdr: '경기도 부천시 소사구 소사본동 421-1',
        SEC_CD: '41194101',
        sagoLon: '126.8017',
        sagoLat: '37.4719',
        sinkWidth: '3',
        sinkExtend: '3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수관 파손으로 인한 토사 유출로 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-30'
      },
      geometry: { type: 'Point', coordinates: [126.8017, 37.4719] }
    },
    {
      type: 'Feature',
      id: 40,
      properties: {
        sagoDate: '2018-03-09',
        sagoNo: '20180052',
        sido: '경기도',
        sigungu: '부천시',
        dong: '도당동',
        addr: ' 7번지',
        fullAdr: '경기도 부천시 원미구 도당동 7',
        SEC_CD: '41192104',
        sagoLon: '126.7783',
        sagoLat: '37.516',
        sinkWidth: '5',
        sinkExtend: '7',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수관로 파손에 따른 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-21'
      },
      geometry: { type: 'Point', coordinates: [126.7783, 37.516] }
    },
    {
      type: 'Feature',
      id: 41,
      properties: {
        sagoDate: '2018-03-10',
        sagoNo: '20180053',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '내덕동',
        addr: '648-14',
        fullAdr: '충청북도 청주시 청원구 내덕동 648-14',
        SEC_CD: '43114102',
        sagoLon: '127.4824',
        sagoLat: '36.6565',
        sinkWidth: '8',
        sinkExtend: '7',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '상수도관 파열에 의한 도로침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-10'
      },
      geometry: { type: 'Point', coordinates: [127.4824, 36.6565] }
    },
    {
      type: 'Feature',
      id: 42,
      properties: {
        sagoDate: '2018-03-12',
        sagoNo: '20180054',
        sido: '강원특별자치도',
        sigungu: '강릉시',
        dong: '',
        addr: '율곡초교길39번길 3',
        fullAdr: '강원특별자치도 강릉시 율곡초교길39번길 3 (교동)',
        SEC_CD: '51150110',
        sagoLon: '128.879',
        sagoLat: '37.7656',
        sinkWidth: '0.3',
        sinkExtend: '0.5',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '원인미상',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-30'
      },
      geometry: { type: 'Point', coordinates: [128.879, 37.7656] }
    },
    {
      type: 'Feature',
      id: 43,
      properties: {
        sagoDate: '2018-03-12',
        sagoNo: '20180055',
        sido: '경상남도',
        sigungu: '사천시',
        dong: '',
        addr: '중앙로 3-1',
        fullAdr: '경상남도 사천시 중앙로 3 (선구동)',
        SEC_CD: '48240103',
        sagoLon: '128.0728',
        sagoLat: '34.9271',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.05',
        grdKind: '기타',
        sagoDetail: '오수받이 주변 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-12'
      },
      geometry: { type: 'Point', coordinates: [128.0728, 34.9271] }
    },
    {
      type: 'Feature',
      id: 44,
      properties: {
        sagoDate: '2018-03-12',
        sagoNo: '20180057',
        sido: '경기도',
        sigungu: '부천시',
        dong: '춘의동',
        addr: ' 까치울역사거리',
        fullAdr: '경기도 부천시 원미구 춘의동 417',
        SEC_CD: '41192103',
        sagoLon: '126.8107',
        sagoLat: '37.5056',
        sinkWidth: '4',
        sinkExtend: '4',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '하수맨홀 내부 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-21'
      },
      geometry: { type: 'Point', coordinates: [126.8107, 37.5056] }
    },
    {
      type: 'Feature',
      id: 45,
      properties: {
        sagoDate: '2018-03-12',
        sagoNo: '20180058',
        sido: '경기도',
        sigungu: '부천시',
        dong: '',
        addr: '까치울사거리역',
        fullAdr: '경기도 부천시 오정구 작동 248-1',
        SEC_CD: '41196103',
        sagoLon: '126.8112',
        sagoLat: '37.5063',
        sinkWidth: '4',
        sinkExtend: '4',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '하수맨홀 내부 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-16'
      },
      geometry: { type: 'Point', coordinates: [126.8112, 37.5063] }
    },
    {
      type: 'Feature',
      id: 46,
      properties: {
        sagoDate: '2018-03-13',
        sagoNo: '20180059',
        sido: '대구광역시',
        sigungu: '남구',
        dong: '',
        addr: '봉덕로7(한진기사식당) 앞',
        fullAdr: '대구광역시 남구  봉덕로7(한진기사식당) 앞',
        SEC_CD: '27200102',
        sagoLon: '128.592',
        sagoLat: '35.8453',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail:
          '미군부대 캠프조지 ~ 캠프워커 간 비상급수관로의 통수시험 중 관로 누수에 따른 아스팔트포장 도로 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-13'
      },
      geometry: { type: 'Point', coordinates: [128.592, 35.8453] }
    },
    {
      type: 'Feature',
      id: 47,
      properties: {
        sagoDate: '2018-03-13',
        sagoNo: '20180060',
        sido: '경기도',
        sigungu: '부천시',
        dong: '',
        addr: '원미동 181-3',
        fullAdr: '경기도 부천시 원미구 원미동 181-3',
        SEC_CD: '41192101',
        sagoLon: '126.7899',
        sagoLat: '37.491',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 노후',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-15'
      },
      geometry: { type: 'Point', coordinates: [126.7899, 37.491] }
    },
    {
      type: 'Feature',
      id: 48,
      properties: {
        sagoDate: '2018-03-13',
        sagoNo: '20180061',
        sido: '경기도',
        sigungu: '부천시',
        dong: '소사본동',
        addr: ' 420-1(동남삼거리)',
        fullAdr: '경기도 부천시 소사구 소사본동 420-1',
        SEC_CD: '41194101',
        sagoLon: '126.7972',
        sagoLat: '37.4692',
        sinkWidth: '3',
        sinkExtend: '170',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail:
          '지하철 공사 포장공사시 다짐불량 등으로 토사유실로 침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-11'
      },
      geometry: { type: 'Point', coordinates: [126.7972, 37.4692] }
    },
    {
      type: 'Feature',
      id: 49,
      properties: {
        sagoDate: '2018-03-13',
        sagoNo: '20180062',
        sido: '경기도',
        sigungu: '부천시',
        dong: '원미동',
        addr: ' 83-28번지',
        fullAdr: '경기도 부천시 원미구 원미동 83-28',
        SEC_CD: '41192101',
        sagoLon: '126.7883',
        sagoLat: '37.4955',
        sinkWidth: '1.6',
        sinkExtend: '1.8',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '집수받이 연결관 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-21'
      },
      geometry: { type: 'Point', coordinates: [126.7883, 37.4955] }
    },
    {
      type: 'Feature',
      id: 50,
      properties: {
        sagoDate: '2018-03-13',
        sagoNo: '20180063',
        sido: '경기도',
        sigungu: '부천시',
        dong: '고강동',
        addr: ' 113-5 봉오대로 자전거도로',
        fullAdr: '경기도 부천시 오정구 고강동 113-5',
        SEC_CD: '41196105',
        sagoLon: '126.8101',
        sagoLat: '37.5354',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '다짐불량에 따른 자전거도로 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-13'
      },
      geometry: { type: 'Point', coordinates: [126.8101, 37.5354] }
    },
    {
      type: 'Feature',
      id: 51,
      properties: {
        sagoDate: '2018-03-15',
        sagoNo: '20180064',
        sido: '전라남도',
        sigungu: '완도군',
        dong: '완도읍 군내리',
        addr: '1433(경도빌딩)',
        fullAdr: '전라남도 완도군 완도읍 군내리 1433',
        SEC_CD: '46890250',
        sagoLon: '126.7562',
        sagoLat: '34.3145',
        sinkWidth: '14.6',
        sinkExtend: '6.2',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '부동침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-10-30'
      },
      geometry: { type: 'Point', coordinates: [126.7562, 34.3145] }
    },
    {
      type: 'Feature',
      id: 52,
      properties: {
        sagoDate: '2018-03-15',
        sagoNo: '20180065',
        sido: '경기도',
        sigungu: '부천시',
        dong: '원미동',
        addr: '189-96',
        fullAdr: '경기도 부천시 원미구 원미동 189-96',
        SEC_CD: '41192101',
        sagoLon: '126.7923',
        sagoLat: '37.4891',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 노후',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-16'
      },
      geometry: { type: 'Point', coordinates: [126.7923, 37.4891] }
    },
    {
      type: 'Feature',
      id: 53,
      properties: {
        sagoDate: '2018-03-15',
        sagoNo: '20180066',
        sido: '경기도',
        sigungu: '부천시',
        dong: '소사본동',
        addr: ' 368-6(동남삼거리)',
        fullAdr: '경기도 부천시 소사구 소사로126번길 18 (소사본동·양우아파트)',
        SEC_CD: '41194101',
        sagoLon: '126.7976',
        sagoLat: '37.4695',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail:
          '지하철 공사 포장공사시 다짐불량 등으로 토사유실로 침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-11'
      },
      geometry: { type: 'Point', coordinates: [126.7976, 37.4695] }
    },
    {
      type: 'Feature',
      id: 54,
      properties: {
        sagoDate: '2018-03-16',
        sagoNo: '20180067',
        sido: '경상북도',
        sigungu: '포항시 북구',
        dong: '',
        addr: '청하면 비학로 2167번지 앞',
        fullAdr: '경상북도 포항시 북구 청하면 비학로 2167',
        SEC_CD: '47113320',
        sagoLon: '129.3462',
        sagoLat: '36.2021',
        sinkWidth: '2',
        sinkExtend: '150',
        sinkDepth: '0.08',
        grdKind: '',
        sagoDetail: '도로 노후화 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-12-15'
      },
      geometry: { type: 'Point', coordinates: [129.3462, 36.2021] }
    },
    {
      type: 'Feature',
      id: 55,
      properties: {
        sagoDate: '2018-03-19',
        sagoNo: '20180068',
        sido: '부산광역시',
        sigungu: '연제구',
        dong: '',
        addr: '거제시장로 14번길 28',
        fullAdr: '부산광역시 연제구 거제시장로14번길 28 (거제동)',
        SEC_CD: '26470101',
        sagoLon: '129.0727',
        sagoLat: '35.1819',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '150mm 상수관 일부 누수발생으로 도로침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-19'
      },
      geometry: { type: 'Point', coordinates: [129.0727, 35.1819] }
    },
    {
      type: 'Feature',
      id: 56,
      properties: {
        sagoDate: '2018-03-19',
        sagoNo: '20180069',
        sido: '경기도',
        sigungu: '양주시',
        dong: '',
        addr: '화합로 201',
        fullAdr: '경기도 양주시  화합로 201',
        SEC_CD: '41630330',
        sagoLon: '126.959',
        sagoLat: '37.8586',
        sinkWidth: '1.7',
        sinkExtend: '1.2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '시공부실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-21'
      },
      geometry: { type: 'Point', coordinates: [126.959, 37.8586] }
    },
    {
      type: 'Feature',
      id: 57,
      properties: {
        sagoDate: '2018-03-21',
        sagoNo: '20180070',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '태장동',
        addr: '원마트 옆',
        fullAdr: '강원특별자치도 원주시 태장동 원마트 옆',
        SEC_CD: '51130112',
        sagoLon: '127.953',
        sagoLat: '37.384',
        sinkWidth: '5.5',
        sinkExtend: '6',
        sinkDepth: '1.2',
        grdKind: '편암류',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '800000',
        trFnDate: '2018-03-21'
      },
      geometry: { type: 'Point', coordinates: [127.953, 37.384] }
    },
    {
      type: 'Feature',
      id: 58,
      properties: {
        sagoDate: '2018-03-22',
        sagoNo: '20180071',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '내덕동',
        addr: '740-10',
        fullAdr: '충청북도 청주시 청원구 내덕동 740-10',
        SEC_CD: '43114102',
        sagoLon: '127.476',
        sagoLat: '36.661',
        sinkWidth: '2',
        sinkExtend: '2.5',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail: '연결관 불량에 의한 하수누수로 인한 지반 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-22'
      },
      geometry: { type: 'Point', coordinates: [127.476, 36.661] }
    },
    {
      type: 'Feature',
      id: 59,
      properties: {
        sagoDate: '2018-03-25',
        sagoNo: '20180072',
        sido: '전라남도',
        sigungu: '목포시',
        dong: '연산동',
        addr: '1922 늘품어린이공원 ·(백련초등학교 옆)',
        fullAdr: '전라남도 목포시 연산동 1922',
        SEC_CD: '46110103',
        sagoLon: '126.39',
        sagoLat: '34.8189',
        sinkWidth: '1.2',
        sinkExtend: '1.2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '백련지구 택지개발 시 별도의 배수처리시설 미설치로 인한 지하수 유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-07'
      },
      geometry: { type: 'Point', coordinates: [126.39, 34.8189] }
    },
    {
      type: 'Feature',
      id: 60,
      properties: {
        sagoDate: '2018-03-26',
        sagoNo: '20180073',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '오창읍 탑리',
        addr: '429-2',
        fullAdr: '충청북도 청주시 청원구 오창읍 탑리 429-2',
        SEC_CD: '43114253',
        sagoLon: '127.4555',
        sagoLat: '36.7123',
        sinkWidth: '2',
        sinkExtend: '1.5',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '연결관 노후에 의한 하수누수로 인한 지반 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-26'
      },
      geometry: { type: 'Point', coordinates: [127.4555, 36.7123] }
    },
    {
      type: 'Feature',
      id: 61,
      properties: {
        sagoDate: '2018-03-26',
        sagoNo: '20180074',
        sido: '전라남도',
        sigungu: '해남군',
        dong: '황산면',
        addr: '청룡마을회관 - 산이면 대명리 마을회관',
        fullAdr: '전라남도 해남군 황산면 원금길 291',
        SEC_CD: '46820400',
        sagoLon: '126.4853',
        sagoLat: '34.6093',
        sinkWidth: '1.1',
        sinkExtend: '1.1',
        sinkDepth: '0.7',
        grdKind: '',
        sagoDetail: '원인 불명',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-26'
      },
      geometry: { type: 'Point', coordinates: [126.4853, 34.6093] }
    },
    {
      type: 'Feature',
      id: 62,
      properties: {
        sagoDate: '2018-03-28',
        sagoNo: '20180075',
        sido: '부산광역시',
        sigungu: '사하구',
        dong: '',
        addr: '무지개공단 내 도로(장림1동)',
        fullAdr: '부산광역시 사하구 다대동 1525',
        SEC_CD: '26380106',
        sagoLon: '128.9536',
        sagoLat: '35.0699',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '단순침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-28'
      },
      geometry: { type: 'Point', coordinates: [128.9536, 35.0699] }
    },
    {
      type: 'Feature',
      id: 63,
      properties: {
        sagoDate: '2018-03-28',
        sagoNo: '20180076',
        sido: '울산광역시',
        sigungu: '울주군',
        dong: '온산읍 덕신리',
        addr: '580-3 (온산소방서 앞)',
        fullAdr: '울산광역시 울주군 온산읍 덕신리 580-3 (온산소방서 앞)',
        SEC_CD: '31710250',
        sagoLon: '129.306',
        sagoLat: '35.4338',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '상수관로 누수로 인한 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-29'
      },
      geometry: { type: 'Point', coordinates: [129.306, 35.4338] }
    },
    {
      type: 'Feature',
      id: 64,
      properties: {
        sagoDate: '2018-03-29',
        sagoNo: '20180077',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '우산동',
        addr: '237-16',
        fullAdr: '강원특별자치도 원주시 우산동 237-16',
        SEC_CD: '51130111',
        sagoLon: '127.934',
        sagoLat: '37.3673',
        sinkWidth: '3.8',
        sinkExtend: '4.7',
        sinkDepth: '1.3',
        grdKind: '충척층',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '770000',
        trFnDate: '2018-03-29'
      },
      geometry: { type: 'Point', coordinates: [127.934, 37.3673] }
    },
    {
      type: 'Feature',
      id: 65,
      properties: {
        sagoDate: '2018-03-29',
        sagoNo: '20180078',
        sido: '부산광역시',
        sigungu: '부산진구',
        dong: '',
        addr: '서전로 41 도요코인 호텔 앞',
        fullAdr: '부산광역시 부산진구 서전로 41 (전포동)',
        SEC_CD: '26230102',
        sagoLon: '129.0644',
        sagoLat: '35.158',
        sinkWidth: '2',
        sinkExtend: '7',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '상수도관 균열에 따른 누수',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-29'
      },
      geometry: { type: 'Point', coordinates: [129.0644, 35.158] }
    },
    {
      type: 'Feature',
      id: 66,
      properties: {
        sagoDate: '2018-03-03',
        sagoNo: '20180323',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '',
        addr: '금남로4가역 1번출구 앞',
        fullAdr: '광주광역시 동구 구성로194번길 4 (금남로5가)',
        SEC_CD: '29110102',
        sagoLon: '126.9142',
        sagoLat: '35.1527',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '다짐불량으로 인한 토사세굴 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-04'
      },
      geometry: { type: 'Point', coordinates: [126.9142, 35.1527] }
    },
    {
      type: 'Feature',
      id: 67,
      properties: {
        sagoDate: '2018-03-10',
        sagoNo: '20180328',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '',
        addr: '내덕동 648-14',
        fullAdr: '충청북도 청주시 청원구 내덕동 648-14',
        SEC_CD: '43114102',
        sagoLon: '127.4824',
        sagoLat: '36.6565',
        sinkWidth: '7',
        sinkExtend: '8',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '타공사(간접원인)에 의한 이음부 단락(직접원인) 토사유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-12'
      },
      geometry: { type: 'Point', coordinates: [127.4824, 36.6565] }
    },
    {
      type: 'Feature',
      id: 68,
      properties: {
        sagoDate: '2018-03-22',
        sagoNo: '20180329',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '',
        addr: '내덕동 740-10',
        fullAdr: '충청북도 청주시 청원구 내덕동 740-10',
        SEC_CD: '43114102',
        sagoLon: '127.476',
        sagoLat: '36.661',
        sinkWidth: '2',
        sinkExtend: '2.5',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail: '타공사(간접원인)에 의한 이음부 단락(직접원인) 토사유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-24'
      },
      geometry: { type: 'Point', coordinates: [127.476, 36.661] }
    },
    {
      type: 'Feature',
      id: 69,
      properties: {
        sagoDate: '2018-03-26',
        sagoNo: '20180330',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '',
        addr: '오창읍 탑리 429-2',
        fullAdr: '충청북도 청주시 청원구 오창읍 탑리 429-2',
        SEC_CD: '43114253',
        sagoLon: '127.4555',
        sagoLat: '36.7123',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '연결관 노후에 의한 하수누수로 인한 지반 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-03-28'
      },
      geometry: { type: 'Point', coordinates: [127.4555, 36.7123] }
    },
    {
      type: 'Feature',
      id: 70,
      properties: {
        sagoDate: '2018-04-25',
        sagoNo: '20180007',
        sido: '서울특별시',
        sigungu: '도봉구',
        dong: '창동',
        addr: '316 (해등로12길)',
        fullAdr: '서울특별시 도봉구 창동 316',
        SEC_CD: '11320107',
        sagoLon: '127.0425',
        sagoLat: '37.6528',
        sinkWidth: '0.8',
        sinkExtend: '0.6',
        sinkDepth: '1.3',
        grdKind: '',
        sagoDetail: '우수관로(D1000mm) 노후파손으로 인한 토사유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-26'
      },
      geometry: { type: 'Point', coordinates: [127.0425, 37.6528] }
    },
    {
      type: 'Feature',
      id: 71,
      properties: {
        sagoDate: '2018-04-26',
        sagoNo: '20180008',
        sido: '서울특별시',
        sigungu: '강남구',
        dong: '역삼동',
        addr: '814-6 (강남대로100길10)',
        fullAdr: '서울특별시 강남구 역삼동 814-6',
        SEC_CD: '11680101',
        sagoLon: '127.0264',
        sagoLat: '37.5017',
        sinkWidth: '0.5',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '공공하수관 노후 손상되어 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-25'
      },
      geometry: { type: 'Point', coordinates: [127.0264, 37.5017] }
    },
    {
      type: 'Feature',
      id: 72,
      properties: {
        sagoDate: '2018-04-28',
        sagoNo: '20180009',
        sido: '서울특별시',
        sigungu: '용산구',
        dong: '용산동5가',
        addr: '19-389 (파크타워 104동앞)',
        fullAdr: '서울특별시 용산구 용산동5가 5',
        SEC_CD: '11170127',
        sagoLon: '126.9748',
        sagoLat: '37.5277',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 연결부 이격으로 인한 토사유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-29'
      },
      geometry: { type: 'Point', coordinates: [126.9748, 37.5277] }
    },
    {
      type: 'Feature',
      id: 73,
      properties: {
        sagoDate: '2018-04-02',
        sagoNo: '20180079',
        sido: '세종특별자치시',
        sigungu: '',
        dong: '어진동',
        addr: '513 M브릿지 신축현장',
        fullAdr: '세종특별자치시 어진동 513',
        SEC_CD: '36110110',
        sagoLon: '127.2573',
        sagoLat: '36.5017',
        sinkWidth: '0.8',
        sinkExtend: '0.8',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail: '오수관로 등 누수에 따른 토사유실',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-12-04'
      },
      geometry: { type: 'Point', coordinates: [127.2573, 36.5017] }
    },
    {
      type: 'Feature',
      id: 74,
      properties: {
        sagoDate: '2018-04-02',
        sagoNo: '20180080',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '우암동',
        addr: '397-20',
        fullAdr: '충청북도 청주시 청원구 우암동 397-20',
        SEC_CD: '43114101',
        sagoLon: '127.482',
        sagoLat: '36.6496',
        sinkWidth: '4',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '연결관 노후에 의한 하수누수로 인한 지반 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-03'
      },
      geometry: { type: 'Point', coordinates: [127.482, 36.6496] }
    },
    {
      type: 'Feature',
      id: 75,
      properties: {
        sagoDate: '2018-04-02',
        sagoNo: '20180081',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '',
        addr: '평화18길 11 서진어린이집 앞',
        fullAdr: '전북특별자치도 전주시 완산구 평화18길 11 (평화동1가)',
        SEC_CD: '52111132',
        sagoLon: '127.1353',
        sagoLat: '35.7947',
        sinkWidth: '2',
        sinkExtend: '3',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '하수관 노후에 의한 공동발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '20000000',
        trFnDate: '2018-04-04'
      },
      geometry: { type: 'Point', coordinates: [127.1353, 35.7947] }
    },
    {
      type: 'Feature',
      id: 76,
      properties: {
        sagoDate: '2018-04-02',
        sagoNo: '20180082',
        sido: '경기도',
        sigungu: '부천시',
        dong: '소사본동',
        addr: ' 196',
        fullAdr: '경기도 부천시 소사구 소사본동 196',
        SEC_CD: '41194101',
        sagoLon: '126.7985',
        sagoLat: '37.4751',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '기타',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-28'
      },
      geometry: { type: 'Point', coordinates: [126.7985, 37.4751] }
    },
    {
      type: 'Feature',
      id: 77,
      properties: {
        sagoDate: '2018-04-03',
        sagoNo: '20180083',
        sido: '경상북도',
        sigungu: '경주시',
        dong: '용강동',
        addr: '1238-45',
        fullAdr: '경상북도 경주시 용강동 1238-45',
        SEC_CD: '47130124',
        sagoLon: '129.221',
        sagoLat: '35.8682',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.4',
        grdKind: '',
        sagoDetail: '노상토 부족 및 자연침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-05'
      },
      geometry: { type: 'Point', coordinates: [129.221, 35.8682] }
    },
    {
      type: 'Feature',
      id: 78,
      properties: {
        sagoDate: '2018-04-04',
        sagoNo: '20180084',
        sido: '부산광역시',
        sigungu: '사하구',
        dong: '',
        addr: '다대몰운대아파트207동 앞(다대1동)',
        fullAdr: '부산광역시 사하구 다대동 1627',
        SEC_CD: '26380106',
        sagoLon: '128.9642',
        sagoLat: '35.0572',
        sinkWidth: '1',
        sinkExtend: '1.2',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '수도관로 이음새 부분 누수',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-05'
      },
      geometry: { type: 'Point', coordinates: [128.9642, 35.0572] }
    },
    {
      type: 'Feature',
      id: 79,
      properties: {
        sagoDate: '2018-04-04',
        sagoNo: '20180085',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '우암동',
        addr: '766-6',
        fullAdr: '충청북도 청주시 청원구 우암동 766-6',
        SEC_CD: '43114101',
        sagoLon: '127.4825',
        sagoLat: '36.6473',
        sinkWidth: '2',
        sinkExtend: '1.5',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '연결관 노후에 의한 하수누수로 인한 지반 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-08'
      },
      geometry: { type: 'Point', coordinates: [127.4825, 36.6473] }
    },
    {
      type: 'Feature',
      id: 80,
      properties: {
        sagoDate: '2018-04-05',
        sagoNo: '20180086',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '송정동',
        addr: '576-18번지',
        fullAdr: '광주광역시 광산구 송정동 576-18',
        SEC_CD: '29200101',
        sagoLon: '126.7976',
        sagoLat: '35.1394',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.7',
        grdKind: '',
        sagoDetail: '연결관 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-06'
      },
      geometry: { type: 'Point', coordinates: [126.7976, 35.1394] }
    },
    {
      type: 'Feature',
      id: 81,
      properties: {
        sagoDate: '2018-04-08',
        sagoNo: '20180087',
        sido: '경기도',
        sigungu: '화성시',
        dong: '영천동',
        addr: ' 산24-1번지(동탄(2)택지C3블럭)',
        fullAdr:
          '경기도 화성시 동탄대로 636-14 (영천동·동탄역 대방디엠시티 더 센텀)',
        SEC_CD: '41590131',
        sagoLon: '127.0994',
        sagoLat: '37.2103',
        sinkWidth: '5',
        sinkExtend: '25',
        sinkDepth: '18',
        grdKind: '',
        sagoDetail:
          '흙막이 배면 지층(암)의 절리면 사이로 우수가 유입되어 마찰력감소로 인하여 흙막이 전도사고 발생.(2018.04.08)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-31'
      },
      geometry: { type: 'Point', coordinates: [127.0994, 37.2103] }
    },
    {
      type: 'Feature',
      id: 82,
      properties: {
        sagoDate: '2018-04-09',
        sagoNo: '20180088',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '',
        addr: '안행로 24 전북불교대학 앞',
        fullAdr: '전북특별자치도 전주시 완산구 안행로 24 (삼천동1가)',
        SEC_CD: '52111137',
        sagoLon: '127.1309',
        sagoLat: '35.8003',
        sinkWidth: '3',
        sinkExtend: '3',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '하수관 노후에 의한 공동발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '30000000',
        trFnDate: '2018-04-12'
      },
      geometry: { type: 'Point', coordinates: [127.1309, 35.8003] }
    },
    {
      type: 'Feature',
      id: 83,
      properties: {
        sagoDate: '2018-04-09',
        sagoNo: '20180089',
        sido: '경상남도',
        sigungu: '김해시',
        dong: '',
        addr: '분성로 14(일동한신아파트 정문 교통섬)',
        fullAdr: '경상남도 김해시  분성로 14(일동한신아파트 정문 교통섬)',
        SEC_CD: '48250109',
        sagoLon: '128.849',
        sagoLat: '35.2332',
        sinkWidth: '4',
        sinkExtend: '4',
        sinkDepth: '1.5',
        grdKind: '충척층',
        sagoDetail:
          '도로 개설 공사로 이설한 상수관로의 압륜부 누수로 인하여 지반침하 발생(18. 4. 9. 13:10)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1029120',
        trFnDate: '2018-04-09'
      },
      geometry: { type: 'Point', coordinates: [128.849, 35.2332] }
    },
    {
      type: 'Feature',
      id: 84,
      properties: {
        sagoDate: '2018-04-11',
        sagoNo: '20180090',
        sido: '부산광역시',
        sigungu: '동래구',
        dong: '',
        addr: '반송로 202(cu편의점 일원)',
        fullAdr: '부산광역시 동래구 반송로 202 (안락동)',
        SEC_CD: '26260102',
        sagoLon: '129.0973',
        sagoLat: '35.1989',
        sinkWidth: '2',
        sinkExtend: '2.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '통신맨홀 주변 마감 불량으로 인한 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-11'
      },
      geometry: { type: 'Point', coordinates: [129.0973, 35.1989] }
    },
    {
      type: 'Feature',
      id: 85,
      properties: {
        sagoDate: '2018-04-11',
        sagoNo: '20180091',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '월곡동',
        addr: '497-4번지',
        fullAdr: '광주광역시 광산구 월곡동 497-4',
        SEC_CD: '29200128',
        sagoLon: '126.8073',
        sagoLat: '35.1747',
        sinkWidth: '0.1',
        sinkExtend: '0.1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관로 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-13'
      },
      geometry: { type: 'Point', coordinates: [126.8073, 35.1747] }
    },
    {
      type: 'Feature',
      id: 86,
      properties: {
        sagoDate: '2018-04-11',
        sagoNo: '20180092',
        sido: '경상남도',
        sigungu: '사천시',
        dong: '서포면 구평리',
        addr: '940-1',
        fullAdr: '경상남도 사천시 서포면 구평리 940-1',
        SEC_CD: '48240370',
        sagoLon: '127.9733',
        sagoLat: '35.008',
        sinkWidth: '0.6',
        sinkExtend: '1',
        sinkDepth: '0.05',
        grdKind: '기타',
        sagoDetail: '오수관로 주변 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-11'
      },
      geometry: { type: 'Point', coordinates: [127.9733, 35.008] }
    },
    {
      type: 'Feature',
      id: 87,
      properties: {
        sagoDate: '2018-04-13',
        sagoNo: '20180093',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '개운동',
        addr: '정충각 앞 ',
        fullAdr: '강원특별자치도 원주시 개운동 정충각 앞',
        SEC_CD: '51130105',
        sagoLon: '127.954',
        sagoLat: '37.3343',
        sinkWidth: '4.8',
        sinkExtend: '6.3',
        sinkDepth: '1',
        grdKind: '충척층',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '930000',
        trFnDate: '2018-04-13'
      },
      geometry: { type: 'Point', coordinates: [127.954, 37.3343] }
    },
    {
      type: 'Feature',
      id: 88,
      properties: {
        sagoDate: '2018-04-14',
        sagoNo: '20180094',
        sido: '대구광역시',
        sigungu: '중구',
        dong: '남산동',
        addr: '610-13번지(금강산보쌈식당앞)',
        fullAdr: '대구광역시 중구 남산동 610-13번지(금강산보쌈식당앞)',
        SEC_CD: '27110156',
        sagoLon: '128.59',
        sagoLat: '35.8613',
        sinkWidth: '4',
        sinkExtend: '0.8',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '노후 하수관 파손으로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-14'
      },
      geometry: { type: 'Point', coordinates: [128.59, 35.8613] }
    },
    {
      type: 'Feature',
      id: 89,
      properties: {
        sagoDate: '2018-04-15',
        sagoNo: '20180095',
        sido: '경기도',
        sigungu: '여주시',
        dong: '하동',
        addr: ' 138 하나빌라 앞 ',
        fullAdr: '경기도 여주시 하동 138',
        SEC_CD: '41670107',
        sagoLon: '127.6302',
        sagoLat: '37.3009',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '우수에 의한 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-15'
      },
      geometry: { type: 'Point', coordinates: [127.6302, 37.3009] }
    },
    {
      type: 'Feature',
      id: 90,
      properties: {
        sagoDate: '2018-04-17',
        sagoNo: '20180096',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '율량동',
        addr: '1183',
        fullAdr: '충청북도 청주시 청원구 율량동 1183',
        SEC_CD: '43114103',
        sagoLon: '127.4787',
        sagoLat: '36.6684',
        sinkWidth: '2.5',
        sinkExtend: '3.5',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail: '연결관 노후에 의한 하수누수로 인한 지반 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-25'
      },
      geometry: { type: 'Point', coordinates: [127.4787, 36.6684] }
    },
    {
      type: 'Feature',
      id: 91,
      properties: {
        sagoDate: '2018-04-18',
        sagoNo: '20180097',
        sido: '대구광역시',
        sigungu: '중구',
        dong: '대신동',
        addr: '신남역 1번출구 앞(양말골목 입구)',
        fullAdr: '대구광역시 중구 대신동 신남역 1번출구 앞(양말골목 입구)',
        SEC_CD: '27110154',
        sagoLon: '128.582',
        sagoLat: '35.8649',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '다짐 불량에 의한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-18'
      },
      geometry: { type: 'Point', coordinates: [128.582, 35.8649] }
    },
    {
      type: 'Feature',
      id: 92,
      properties: {
        sagoDate: '2018-04-18',
        sagoNo: '20180098',
        sido: '충청북도',
        sigungu: '옥천군',
        dong: '옥천읍',
        addr: '충북도립대학교 앞 운동장 인근(옥천읍 금구리 40-1번지 일원)',
        fullAdr: '충청북도 옥천군 옥천읍 금구리 40-1',
        SEC_CD: '43730250',
        sagoLon: '127.5723',
        sagoLat: '36.3003',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '노후된 박스 구간 상부 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-27'
      },
      geometry: { type: 'Point', coordinates: [127.5723, 36.3003] }
    },
    {
      type: 'Feature',
      id: 93,
      properties: {
        sagoDate: '2018-04-19',
        sagoNo: '20180099',
        sido: '부산광역시',
        sigungu: '남구',
        dong: '',
        addr: '유엔로84번길 71-12',
        fullAdr: '부산광역시 남구 유엔로84번길 71-12 (우암동)',
        SEC_CD: '26290110',
        sagoLon: '129.0835',
        sagoLat: '35.126',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 손상 누수로 침하발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-25'
      },
      geometry: { type: 'Point', coordinates: [129.0835, 35.126] }
    },
    {
      type: 'Feature',
      id: 94,
      properties: {
        sagoDate: '2018-04-19',
        sagoNo: '20180100',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '둔산동',
        addr: '문예로 정부청사 동문 입구',
        fullAdr: '대전광역시 서구 둔산동 문예로 정부청사 동문 입구',
        SEC_CD: '30170112',
        sagoLon: '127.39',
        sagoLat: '36.3622',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail:
          '우수박스와 하수관접합부 마감불량 및 오수관 이탈로 인한 장기간 토사유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '20000000',
        trFnDate: '2018-04-23'
      },
      geometry: { type: 'Point', coordinates: [127.39, 36.3622] }
    },
    {
      type: 'Feature',
      id: 95,
      properties: {
        sagoDate: '2018-04-19',
        sagoNo: '20180101',
        sido: '충청북도',
        sigungu: '청주시 흥덕구',
        dong: '향정동',
        addr: '50',
        fullAdr: '충청북도 청주시 흥덕구 향정동 50',
        SEC_CD: '43113137',
        sagoLon: '127.4292',
        sagoLat: '36.6487',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 파손 및 하수 누수로 지반유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-20'
      },
      geometry: { type: 'Point', coordinates: [127.4292, 36.6487] }
    },
    {
      type: 'Feature',
      id: 96,
      properties: {
        sagoDate: '2018-04-19',
        sagoNo: '20180103',
        sido: '경상북도',
        sigungu: '영주시',
        dong: '휴천동',
        addr: ' 506-3번지 앞',
        fullAdr: '경상북도 영주시 휴천동  506-3번지 앞',
        SEC_CD: '47210104',
        sagoLon: '128.618',
        sagoLat: '36.8066',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '우수관 누수',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-27'
      },
      geometry: { type: 'Point', coordinates: [128.618, 36.8066] }
    },
    {
      type: 'Feature',
      id: 97,
      properties: {
        sagoDate: '2018-04-19',
        sagoNo: '20180104',
        sido: '경기도',
        sigungu: '수원시 팔달구',
        dong: '',
        addr: '수원시청역 사거리 교차로 내',
        fullAdr: '경기도 수원시 팔달구 효원로 271-2 (인계동)',
        SEC_CD: '41115141',
        sagoLon: '127.0314',
        sagoLat: '37.262',
        sinkWidth: '0.5',
        sinkExtend: '0.3',
        sinkDepth: '1.1',
        grdKind: '',
        sagoDetail:
          '지하철 공사 시 설치한 시트파일 사이 공간의 다짐불량으로 장기간 공극이 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-20'
      },
      geometry: { type: 'Point', coordinates: [127.0314, 37.262] }
    },
    {
      type: 'Feature',
      id: 98,
      properties: {
        sagoDate: '2018-04-21',
        sagoNo: '20180105',
        sido: '부산광역시',
        sigungu: '연제구',
        dong: '',
        addr: '월드컵대로 224',
        fullAdr: '부산광역시 연제구 거제동 1189-3',
        SEC_CD: '26470101',
        sagoLon: '129.0728',
        sagoLat: '35.1894',
        sinkWidth: '1',
        sinkExtend: '1.9',
        sinkDepth: '0.25',
        grdKind: '',
        sagoDetail: '700mm 상수관 일부 누수발생으로 도로침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-22'
      },
      geometry: { type: 'Point', coordinates: [129.0728, 35.1894] }
    },
    {
      type: 'Feature',
      id: 99,
      properties: {
        sagoDate: '2018-04-23',
        sagoNo: '20180106',
        sido: '충청북도',
        sigungu: '제천시',
        dong: '',
        addr: '환경사업소에서 강제동 롯데캐슬아파트 가는 길목',
        fullAdr: '충청북도 제천시 영천동 1169-1',
        SEC_CD: '43150110',
        sagoLon: '128.1953',
        sagoLat: '37.126',
        sinkWidth: '4.2',
        sinkExtend: '10',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '우수관 연결을 위해 도로굴착 복구한 구간 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-27'
      },
      geometry: { type: 'Point', coordinates: [128.1953, 37.126] }
    },
    {
      type: 'Feature',
      id: 100,
      properties: {
        sagoDate: '2018-04-24',
        sagoNo: '20180107',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '',
        addr: '1순환로 339번길34',
        fullAdr: '충청북도 청주시 청원구 1순환로 339 (내덕동)',
        SEC_CD: '43114102',
        sagoLon: '127.4773',
        sagoLat: '36.6583',
        sinkWidth: '2',
        sinkExtend: '1.5',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '타공사에 의한 이음부 단락 토사유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-24'
      },
      geometry: { type: 'Point', coordinates: [127.4773, 36.6583] }
    },
    {
      type: 'Feature',
      id: 101,
      properties: {
        sagoDate: '2018-04-27',
        sagoNo: '20180108',
        sido: '강원특별자치도',
        sigungu: '고성군',
        dong: '거진읍',
        addr: '거탄진로 116(호남식당 옆)',
        fullAdr: '강원특별자치도 고성군 거진읍 거탄진로 116',
        SEC_CD: '51820253',
        sagoLon: '128.4544',
        sagoLat: '38.4453',
        sinkWidth: '2.5',
        sinkExtend: '10',
        sinkDepth: '2.5',
        grdKind: '기타',
        sagoDetail: '노후 하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '34489300',
        trFnDate: '2018-06-18'
      },
      geometry: { type: 'Point', coordinates: [128.4544, 38.4453] }
    },
    {
      type: 'Feature',
      id: 102,
      properties: {
        sagoDate: '2018-04-27',
        sagoNo: '20180109',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '비아동',
        addr: '675-2번지',
        fullAdr: '광주광역시 광산구 비아동 675-2',
        SEC_CD: '29200112',
        sagoLon: '126.8194',
        sagoLat: '35.2231',
        sinkWidth: '0.4',
        sinkExtend: '0.4',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '연결관 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-28'
      },
      geometry: { type: 'Point', coordinates: [126.8194, 35.2231] }
    },
    {
      type: 'Feature',
      id: 103,
      properties: {
        sagoDate: '2018-04-30',
        sagoNo: '20180110',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '중앙동',
        addr: '중앙시장 동원약국',
        fullAdr: '강원특별자치도 원주시 중앙동 중앙시장 동원약국',
        SEC_CD: '51130101',
        sagoLon: '127.95',
        sagoLat: '37.3494',
        sinkWidth: '2.3',
        sinkExtend: '3.8',
        sinkDepth: '1.6',
        grdKind: '화강암류',
        sagoDetail: '상수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1470000',
        trFnDate: '2018-04-30'
      },
      geometry: { type: 'Point', coordinates: [127.95, 37.3494] }
    },
    {
      type: 'Feature',
      id: 104,
      properties: {
        sagoDate: '2018-04-30',
        sagoNo: '20180111',
        sido: '인천광역시',
        sigungu: '중구',
        dong: '북성동',
        addr: ' 인천역 월미은하레일 앞 도로',
        fullAdr: '인천광역시 중구 북성동  인천역 월미은하레일 앞 도로',
        SEC_CD: '28110138',
        sagoLon: '126.617',
        sagoLat: '37.4775',
        sinkWidth: '1',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '수인선 노반신설공사 제6공구 인천역구간 굴착부분 다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-03'
      },
      geometry: { type: 'Point', coordinates: [126.617, 37.4775] }
    },
    {
      type: 'Feature',
      id: 105,
      properties: {
        sagoDate: '2018-04-09',
        sagoNo: '20180324',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '학동',
        addr: '제봉로 10(이상명 내과)',
        fullAdr: '광주광역시 동구 제봉로 10 (학동)',
        SEC_CD: '29110122',
        sagoLon: '126.9227',
        sagoLat: '35.1401',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '다짐불량으로 인한 토사세굴 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-10'
      },
      geometry: { type: 'Point', coordinates: [126.9227, 35.1401] }
    },
    {
      type: 'Feature',
      id: 106,
      properties: {
        sagoDate: '2018-04-02',
        sagoNo: '20180331',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '',
        addr: '우암동 397-20',
        fullAdr: '충청북도 청주시 청원구 우암동 397-20',
        SEC_CD: '43114101',
        sagoLon: '127.482',
        sagoLat: '36.6496',
        sinkWidth: '1',
        sinkExtend: '4',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '연결관 노후에 의한 하수누수로 인한 지반 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-04'
      },
      geometry: { type: 'Point', coordinates: [127.482, 36.6496] }
    },
    {
      type: 'Feature',
      id: 107,
      properties: {
        sagoDate: '2018-04-04',
        sagoNo: '20180332',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '',
        addr: '우암동 766-6',
        fullAdr: '충청북도 청주시 청원구 우암동 766-6',
        SEC_CD: '43114101',
        sagoLon: '127.4825',
        sagoLat: '36.6473',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '연결관 노후에 의한 하수누수로 인한 지반 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-06'
      },
      geometry: { type: 'Point', coordinates: [127.4825, 36.6473] }
    },
    {
      type: 'Feature',
      id: 108,
      properties: {
        sagoDate: '2018-04-17',
        sagoNo: '20180333',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '',
        addr: '율량동 1183',
        fullAdr: '충청북도 청주시 청원구 율량동 1183',
        SEC_CD: '43114103',
        sagoLon: '127.4787',
        sagoLat: '36.6684',
        sinkWidth: '2.5',
        sinkExtend: '3.5',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail: '연결관 노후에 의한 하수누수로 인한 지반 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-19'
      },
      geometry: { type: 'Point', coordinates: [127.4787, 36.6684] }
    },
    {
      type: 'Feature',
      id: 109,
      properties: {
        sagoDate: '2018-04-24',
        sagoNo: '20180334',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '',
        addr: '1순환로 339번길 34',
        fullAdr: '충청북도 청주시 청원구 1순환로 339 (내덕동)',
        SEC_CD: '43114102',
        sagoLon: '127.4773',
        sagoLat: '36.6583',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '타공사(간접원인)에 의한 이음부 단락(직접원인) 토사유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-04-26'
      },
      geometry: { type: 'Point', coordinates: [127.4773, 36.6583] }
    },
    {
      type: 'Feature',
      id: 110,
      properties: {
        sagoDate: '2018-05-01',
        sagoNo: '20180112',
        sido: '부산광역시',
        sigungu: '해운대구',
        dong: '우동',
        addr: '운촌삼거리 일원',
        fullAdr: '부산광역시 해운대구 우동 807-1',
        SEC_CD: '26350105',
        sagoLon: '129.1493',
        sagoLat: '35.1604',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '지하철 가시설 미철거로 인한 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-01'
      },
      geometry: { type: 'Point', coordinates: [129.1493, 35.1604] }
    },
    {
      type: 'Feature',
      id: 111,
      properties: {
        sagoDate: '2018-05-01',
        sagoNo: '20180113',
        sido: '충청북도',
        sigungu: '청주시 상당구',
        dong: '대신리',
        addr: '',
        fullAdr: '충청북도 청주시 상당구 미원면 대신리 256-5',
        SEC_CD: '43111320',
        sagoLon: '127.6165',
        sagoLat: '36.6928',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-02'
      },
      geometry: { type: 'Point', coordinates: [127.6165, 36.6928] }
    },
    {
      type: 'Feature',
      id: 112,
      properties: {
        sagoDate: '2018-05-01',
        sagoNo: '20180114',
        sido: '전라남도',
        sigungu: '신안군',
        dong: '임자면 광산리',
        addr: '407-20',
        fullAdr: '전라남도 신안군 임자면 광산리 407-20',
        SEC_CD: '46910320',
        sagoLon: '126.065',
        sagoLat: '35.1019',
        sinkWidth: '3',
        sinkExtend: '0.8',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '보수공사불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-01'
      },
      geometry: { type: 'Point', coordinates: [126.065, 35.1019] }
    },
    {
      type: 'Feature',
      id: 113,
      properties: {
        sagoDate: '2018-05-03',
        sagoNo: '20180115',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '내덕동',
        addr: '740-10',
        fullAdr: '충청북도 청주시 청원구 내덕동 740-10',
        SEC_CD: '43114102',
        sagoLon: '127.476',
        sagoLat: '36.661',
        sinkWidth: '2',
        sinkExtend: '2.5',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail: '연결관 노후에 의한 하수누수로 인한 지반 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-03'
      },
      geometry: { type: 'Point', coordinates: [127.476, 36.661] }
    },
    {
      type: 'Feature',
      id: 114,
      properties: {
        sagoDate: '2018-05-08',
        sagoNo: '20180116',
        sido: '경상북도',
        sigungu: '포항시 남구',
        dong: '해도동',
        addr: ' 고속터미널 인근',
        fullAdr: '경상북도 포항시 남구 중앙로 166-2 (해도동)',
        SEC_CD: '47111103',
        sagoLon: '129.3674',
        sagoLat: '36.0281',
        sinkWidth: '0.08',
        sinkExtend: '20',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail:
          '오피스텔 기초 지하연속벽(슬러리월) 누수로 인한 주변지하수 및 토사 유출로 도로 침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-09-18'
      },
      geometry: { type: 'Point', coordinates: [129.3674, 36.0281] }
    },
    {
      type: 'Feature',
      id: 115,
      properties: {
        sagoDate: '2018-05-09',
        sagoNo: '20180117',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '소촌동',
        addr: '631-6번지',
        fullAdr: '광주광역시 광산구 소촌동 631-6',
        SEC_CD: '29200108',
        sagoLon: '126.7893',
        sagoLat: '35.1462',
        sinkWidth: '0.1',
        sinkExtend: '0.1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관로 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-10'
      },
      geometry: { type: 'Point', coordinates: [126.7893, 35.1462] }
    },
    {
      type: 'Feature',
      id: 116,
      properties: {
        sagoDate: '2018-05-09',
        sagoNo: '20180118',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '송정동',
        addr: '1001-9번지',
        fullAdr: '광주광역시 광산구 송정동 1001-9',
        SEC_CD: '29200101',
        sagoLon: '126.7921',
        sagoLat: '35.1375',
        sinkWidth: '0.5',
        sinkExtend: '0.4',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수관로 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-10'
      },
      geometry: { type: 'Point', coordinates: [126.7921, 35.1375] }
    },
    {
      type: 'Feature',
      id: 117,
      properties: {
        sagoDate: '2018-05-09',
        sagoNo: '20180119',
        sido: '인천광역시',
        sigungu: '미추홀구',
        dong: '',
        addr: '경인로 270-1 일원 2차선 도로',
        fullAdr: '인천광역시 미추홀구  경인로 270-1 일원 2차선 도로',
        SEC_CD: '28177104',
        sagoLon: '126.671',
        sagoLat: '37.4621',
        sinkWidth: '3',
        sinkExtend: '3',
        sinkDepth: '5',
        grdKind: '',
        sagoDetail: '상수도관 누수로 인한 도로침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-10'
      },
      geometry: { type: 'Point', coordinates: [126.671, 37.4621] }
    },
    {
      type: 'Feature',
      id: 118,
      properties: {
        sagoDate: '2018-05-10',
        sagoNo: '20180120',
        sido: '경상북도',
        sigungu: '포항시 북구',
        dong: '흥해읍',
        addr: ' 동해대로 1352(만남의광장 휴게소)',
        fullAdr: '경상북도 포항시 북구 흥해읍 동해대로 1352',
        SEC_CD: '47113250',
        sagoLon: '129.343',
        sagoLat: '36.0938',
        sinkWidth: '3.7',
        sinkExtend: '2',
        sinkDepth: '0.12',
        grdKind: '',
        sagoDetail: '다짐불량에 의한 침하발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-10-12'
      },
      geometry: { type: 'Point', coordinates: [129.343, 36.0938] }
    },
    {
      type: 'Feature',
      id: 119,
      properties: {
        sagoDate: '2018-05-11',
        sagoNo: '20180121',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '내덕동',
        addr: '188-3 ',
        fullAdr: '충청북도 청주시 청원구 내덕동 188-3',
        SEC_CD: '43114102',
        sagoLon: '127.4888',
        sagoLat: '36.6569',
        sinkWidth: '1.5',
        sinkExtend: '3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '타공사에 의한 이음부 단락 토사유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-11'
      },
      geometry: { type: 'Point', coordinates: [127.4888, 36.6569] }
    },
    {
      type: 'Feature',
      id: 120,
      properties: {
        sagoDate: '2018-05-12',
        sagoNo: '20180122',
        sido: '광주광역시',
        sigungu: '북구',
        dong: '용봉동',
        addr: '1248-10번지 주변',
        fullAdr: '광주광역시 북구 용봉동 1248-10번지 주변',
        SEC_CD: '29170107',
        sagoLon: '126.898',
        sagoLat: '35.178',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail: '맨홀 이음부내 마감부 미흡으로 발생한 것으로 의심',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '2000000',
        trFnDate: '2018-05-17'
      },
      geometry: { type: 'Point', coordinates: [126.898, 35.178] }
    },
    {
      type: 'Feature',
      id: 121,
      properties: {
        sagoDate: '2018-05-14',
        sagoNo: '20180123',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '송촌동',
        addr: '1-146번지',
        fullAdr: '광주광역시 광산구 송촌동 1-146',
        SEC_CD: '29200148',
        sagoLon: '126.7816',
        sagoLat: '35.138',
        sinkWidth: '0.5',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수암거박스 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-16'
      },
      geometry: { type: 'Point', coordinates: [126.7816, 35.138] }
    },
    {
      type: 'Feature',
      id: 122,
      properties: {
        sagoDate: '2018-05-14',
        sagoNo: '20180124',
        sido: '충청북도',
        sigungu: '청주시 서원구',
        dong: '분평동',
        addr: '249-5번지',
        fullAdr: '충청북도 청주시 서원구 분평동 249-5',
        SEC_CD: '43112106',
        sagoLon: '127.4829',
        sagoLat: '36.6123',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '하수BOX 벽체 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-03'
      },
      geometry: { type: 'Point', coordinates: [127.4829, 36.6123] }
    },
    {
      type: 'Feature',
      id: 123,
      properties: {
        sagoDate: '2018-05-14',
        sagoNo: '20180125',
        sido: '충청북도',
        sigungu: '제천시',
        dong: '장락동',
        addr: '180-2번지',
        fullAdr: '충청북도 제천시 장락동 180-2',
        SEC_CD: '43150116',
        sagoLon: '128.2282',
        sagoLat: '37.1423',
        sinkWidth: '4.2',
        sinkExtend: '4',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '도로 우수관에 배수시설 연결부분이 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-20'
      },
      geometry: { type: 'Point', coordinates: [128.2282, 37.1423] }
    },
    {
      type: 'Feature',
      id: 124,
      properties: {
        sagoDate: '2018-05-15',
        sagoNo: '20180126',
        sido: '경상북도',
        sigungu: '상주시',
        dong: '화동면 보미리',
        addr: ' 산 43-1번지 일원',
        fullAdr: '경상북도 상주시 화동면 보미리 388',
        SEC_CD: '47250400',
        sagoLon: '127.9131',
        sagoLat: '36.3796',
        sinkWidth: '0.3',
        sinkExtend: '0.6',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '명확하지 않음',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-30'
      },
      geometry: { type: 'Point', coordinates: [127.9131, 36.3796] }
    },
    {
      type: 'Feature',
      id: 125,
      properties: {
        sagoDate: '2018-05-15',
        sagoNo: '20180127',
        sido: '경기도',
        sigungu: '용인시 처인구',
        dong: '김량장동',
        addr: ' 383번지',
        fullAdr: '경기도 용인시 처인구 김량장동  383번지',
        SEC_CD: '41461101',
        sagoLon: '127.201',
        sagoLat: '37.2341',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '노후 우수관과 맨홀 접합부 손상으로 누수 및 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-15'
      },
      geometry: { type: 'Point', coordinates: [127.201, 37.2341] }
    },
    {
      type: 'Feature',
      id: 126,
      properties: {
        sagoDate: '2018-05-16',
        sagoNo: '20180128',
        sido: '경기도',
        sigungu: '남양주시',
        dong: '화도읍 월산리',
        addr: ' 374-10',
        fullAdr: '경기도 남양주시 화도읍 월산리 374-10',
        SEC_CD: '41360256',
        sagoLon: '127.3321',
        sagoLat: '37.6536',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '우수관 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-25'
      },
      geometry: { type: 'Point', coordinates: [127.3321, 37.6536] }
    },
    {
      type: 'Feature',
      id: 127,
      properties: {
        sagoDate: '2018-05-17',
        sagoNo: '20180129',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '소촌동',
        addr: '603-4번지',
        fullAdr: '광주광역시 광산구 소촌동 603-4',
        SEC_CD: '29200108',
        sagoLon: '126.7916',
        sagoLat: '35.146',
        sinkWidth: '1',
        sinkExtend: '1.2',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '연결관 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-19'
      },
      geometry: { type: 'Point', coordinates: [126.7916, 35.146] }
    },
    {
      type: 'Feature',
      id: 128,
      properties: {
        sagoDate: '2018-05-17',
        sagoNo: '20180130',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '월곡동',
        addr: '526-4번지',
        fullAdr: '광주광역시 광산구 월곡동 526-4',
        SEC_CD: '29200128',
        sagoLon: '126.8076',
        sagoLat: '35.1701',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '연결관 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-19'
      },
      geometry: { type: 'Point', coordinates: [126.8076, 35.1701] }
    },
    {
      type: 'Feature',
      id: 129,
      properties: {
        sagoDate: '2018-05-21',
        sagoNo: '20180131',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '단계동',
        addr: '왕국회관 앞',
        fullAdr: '강원특별자치도 원주시 단계동 왕국회관 앞',
        SEC_CD: '51130110',
        sagoLon: '127.929',
        sagoLat: '37.3567',
        sinkWidth: '1.5',
        sinkExtend: '3',
        sinkDepth: '1',
        grdKind: '충척층',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '110000',
        trFnDate: '2018-05-21'
      },
      geometry: { type: 'Point', coordinates: [127.929, 37.3567] }
    },
    {
      type: 'Feature',
      id: 130,
      properties: {
        sagoDate: '2018-05-21',
        sagoNo: '20180132',
        sido: '경상북도',
        sigungu: '영주시',
        dong: '문수면 월호리',
        addr: ' 1406번지 앞',
        fullAdr: '경상북도 영주시 문수면 월호리  1406번지 앞',
        SEC_CD: '47210330',
        sagoLon: '128.606',
        sagoLat: '36.744',
        sinkWidth: '7',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '횡배수관 누수',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-31'
      },
      geometry: { type: 'Point', coordinates: [128.606, 36.744] }
    },
    {
      type: 'Feature',
      id: 131,
      properties: {
        sagoDate: '2018-05-25',
        sagoNo: '20180133',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '월곡동',
        addr: '545-9번지',
        fullAdr: '광주광역시 광산구 월곡동 545-9',
        SEC_CD: '29200128',
        sagoLon: '126.8102',
        sagoLat: '35.1695',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수암거박스 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-27'
      },
      geometry: { type: 'Point', coordinates: [126.8102, 35.1695] }
    },
    {
      type: 'Feature',
      id: 132,
      properties: {
        sagoDate: '2018-05-25',
        sagoNo: '20180134',
        sido: '충청북도',
        sigungu: '청주시 흥덕구',
        dong: '복대동',
        addr: '204-11',
        fullAdr: '충청북도 청주시 흥덕구 복대동 204-11',
        SEC_CD: '43113114',
        sagoLon: '127.4393',
        sagoLat: '36.6354',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 파손 및 하수 누수로 지반유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-26'
      },
      geometry: { type: 'Point', coordinates: [127.4393, 36.6354] }
    },
    {
      type: 'Feature',
      id: 133,
      properties: {
        sagoDate: '2018-05-29',
        sagoNo: '20180135',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '태장동',
        addr: '6612 부대 앞',
        fullAdr: '강원특별자치도 원주시 태장동 6612 부대 앞',
        SEC_CD: '51130112',
        sagoLon: '127.957',
        sagoLat: '37.3741',
        sinkWidth: '2.3',
        sinkExtend: '3',
        sinkDepth: '1.3',
        grdKind: '충척층',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '940000',
        trFnDate: '2018-05-29'
      },
      geometry: { type: 'Point', coordinates: [127.957, 37.3741] }
    },
    {
      type: 'Feature',
      id: 134,
      properties: {
        sagoDate: '2018-05-30',
        sagoNo: '20180136',
        sido: '부산광역시',
        sigungu: '동래구',
        dong: '온천동',
        addr: '1646 ( 백옥사우나 일원)',
        fullAdr: '부산광역시 동래구 온천동 1646',
        SEC_CD: '26260108',
        sagoLon: '129.0588',
        sagoLat: '35.2043',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '인근 아파트 배수시설 누수에 따른 침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-01'
      },
      geometry: { type: 'Point', coordinates: [129.0588, 35.2043] }
    },
    {
      type: 'Feature',
      id: 135,
      properties: {
        sagoDate: '2018-05-30',
        sagoNo: '20180137',
        sido: '부산광역시',
        sigungu: '사상구',
        dong: '',
        addr: '장인로 77번길 33',
        fullAdr: '부산광역시 사상구 장인로77번길 33 (학장동)',
        SEC_CD: '26530107',
        sagoLon: '128.9777',
        sagoLat: '35.1435',
        sinkWidth: '4',
        sinkExtend: '8',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '관파손으로 인한 맨홀 주변부 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-01'
      },
      geometry: { type: 'Point', coordinates: [128.9777, 35.1435] }
    },
    {
      type: 'Feature',
      id: 136,
      properties: {
        sagoDate: '2018-05-30',
        sagoNo: '20180138',
        sido: '대구광역시',
        sigungu: '수성구',
        dong: '만촌동',
        addr: '달구벌대로 대륜고등학교 정문 앞',
        fullAdr: '대구광역시 수성구 만촌동 달구벌대로 대륜고등학교 정문 앞',
        SEC_CD: '27260102',
        sagoLon: '128.656',
        sagoLat: '35.8537',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '폐쇄된 통신맨홀의 손궤로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-30'
      },
      geometry: { type: 'Point', coordinates: [128.656, 35.8537] }
    },
    {
      type: 'Feature',
      id: 137,
      properties: {
        sagoDate: '2018-05-30',
        sagoNo: '20180139',
        sido: '충청북도',
        sigungu: '청주시 상당구',
        dong: '금천동',
        addr: '94-17',
        fullAdr: '충청북도 청주시 상당구 금천동 94-17',
        SEC_CD: '43111120',
        sagoLon: '127.5056',
        sagoLat: '36.6263',
        sinkWidth: '2.5',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 접합부 파손으로 토사유실 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-01'
      },
      geometry: { type: 'Point', coordinates: [127.5056, 36.6263] }
    },
    {
      type: 'Feature',
      id: 138,
      properties: {
        sagoDate: '2018-05-30',
        sagoNo: '20180140',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '우암동',
        addr: '111-13',
        fullAdr: '충청북도 청주시 청원구 우암동 111-13',
        SEC_CD: '43114101',
        sagoLon: '127.49',
        sagoLat: '36.6497',
        sinkWidth: '2.5',
        sinkExtend: '3.5',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '연결관 노후에 의한 하수누수로 인한 지반 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-03'
      },
      geometry: { type: 'Point', coordinates: [127.49, 36.6497] }
    },
    {
      type: 'Feature',
      id: 139,
      properties: {
        sagoDate: '2018-05-31',
        sagoNo: '20180141',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '단계동',
        addr: '무상길48-6',
        fullAdr: '강원특별자치도 원주시 단계동 무상길48-6',
        SEC_CD: '51130110',
        sagoLon: '127.926',
        sagoLat: '37.3588',
        sinkWidth: '1.8',
        sinkExtend: '2.3',
        sinkDepth: '1.8',
        grdKind: '충척층',
        sagoDetail: '상수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1120000',
        trFnDate: '2018-05-31'
      },
      geometry: { type: 'Point', coordinates: [127.926, 37.3588] }
    },
    {
      type: 'Feature',
      id: 140,
      properties: {
        sagoDate: '2018-05-31',
        sagoNo: '20180142',
        sido: '충청북도',
        sigungu: '청주시 서원구',
        dong: '사창동',
        addr: '154-1번지',
        fullAdr: '충청북도 청주시 서원구 사창동 154-1',
        SEC_CD: '43112102',
        sagoLon: '127.4586',
        sagoLat: '36.634',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수BOX 하부 헌치부 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-09-19'
      },
      geometry: { type: 'Point', coordinates: [127.4586, 36.634] }
    },
    {
      type: 'Feature',
      id: 141,
      properties: {
        sagoDate: '2018-05-11',
        sagoNo: '20180325',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '',
        addr: '금남로4가역 1번출구 앞(국민은행)',
        fullAdr: '광주광역시 동구 구성로194번길 4 (금남로5가)',
        SEC_CD: '29110102',
        sagoLon: '126.9142',
        sagoLat: '35.1527',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '다짐불량으로 인한 토사세굴 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-14'
      },
      geometry: { type: 'Point', coordinates: [126.9142, 35.1527] }
    },
    {
      type: 'Feature',
      id: 142,
      properties: {
        sagoDate: '2018-05-03',
        sagoNo: '20180335',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '',
        addr: '내덕동 740-10',
        fullAdr: '충청북도 청주시 청원구 내덕동 740-10',
        SEC_CD: '43114102',
        sagoLon: '127.476',
        sagoLat: '36.661',
        sinkWidth: '2',
        sinkExtend: '2.5',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail: '연결관 불량에 의한 하수누수로 인한 지반 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-05'
      },
      geometry: { type: 'Point', coordinates: [127.476, 36.661] }
    },
    {
      type: 'Feature',
      id: 143,
      properties: {
        sagoDate: '2018-05-11',
        sagoNo: '20180336',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '내덕동',
        addr: '188-3',
        fullAdr: '충청북도 청주시 청원구 내덕동 188-3',
        SEC_CD: '43114102',
        sagoLon: '127.4888',
        sagoLat: '36.6569',
        sinkWidth: '1.5',
        sinkExtend: '2.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '타공사(간접원인)에 의한 이음부 단락(직접원인) 토사유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-13'
      },
      geometry: { type: 'Point', coordinates: [127.4888, 36.6569] }
    },
    {
      type: 'Feature',
      id: 144,
      properties: {
        sagoDate: '2018-05-14',
        sagoNo: '20180337',
        sido: '충청북도',
        sigungu: '청주시 서원구',
        dong: '',
        addr: '분평동 249-5',
        fullAdr: '충청북도 청주시 서원구 분평동 249-5',
        SEC_CD: '43112106',
        sagoLon: '127.4829',
        sagoLat: '36.6123',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '하수BOX 벽체 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-05-16'
      },
      geometry: { type: 'Point', coordinates: [127.4829, 36.6123] }
    },
    {
      type: 'Feature',
      id: 145,
      properties: {
        sagoDate: '2018-05-30',
        sagoNo: '20180338',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '',
        addr: '우암동 111-13',
        fullAdr: '충청북도 청주시 청원구 우암동 111-13',
        SEC_CD: '43114101',
        sagoLon: '127.49',
        sagoLat: '36.6497',
        sinkWidth: '2.5',
        sinkExtend: '3.5',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '타공사(간접원인)에 의한 이음부 단락(직접원인) 토사유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-01'
      },
      geometry: { type: 'Point', coordinates: [127.49, 36.6497] }
    },
    {
      type: 'Feature',
      id: 146,
      properties: {
        sagoDate: '2018-05-31',
        sagoNo: '20180339',
        sido: '충청북도',
        sigungu: '청주시 서원구',
        dong: '',
        addr: '사창동 154-1',
        fullAdr: '충청북도 청주시 서원구 사창동 154-1',
        SEC_CD: '43112102',
        sagoLon: '127.4586',
        sagoLat: '36.634',
        sinkWidth: '3',
        sinkExtend: '3',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수BOX 헌치부 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-09-19'
      },
      geometry: { type: 'Point', coordinates: [127.4586, 36.634] }
    },
    {
      type: 'Feature',
      id: 147,
      properties: {
        sagoDate: '2018-06-24',
        sagoNo: '20180010',
        sido: '서울특별시',
        sigungu: '구로구',
        dong: '구로동',
        addr: '126-28 (도림로 럭키아파트 인근)',
        fullAdr: '서울특별시 구로구 구로동 126-28',
        SEC_CD: '11530102',
        sagoLon: '126.8942',
        sagoLat: '37.4914',
        sinkWidth: '0.2',
        sinkExtend: '0.2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail:
          '지하철 7호선 공사시 설치한 가시설(H-beam)인접 다짐불량· 토류판 부식 등 장기침하로 인한 함몰',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-24'
      },
      geometry: { type: 'Point', coordinates: [126.8942, 37.4914] }
    },
    {
      type: 'Feature',
      id: 148,
      properties: {
        sagoDate: '2018-06-01',
        sagoNo: '20180143',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '율량동',
        addr: '1015',
        fullAdr: '충청북도 청주시 청원구 율량동 1015',
        SEC_CD: '43114103',
        sagoLon: '127.4914',
        sagoLat: '36.666',
        sinkWidth: '2',
        sinkExtend: '3',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '타공사에 의한 이음부 단락 토사유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-12'
      },
      geometry: { type: 'Point', coordinates: [127.4914, 36.666] }
    },
    {
      type: 'Feature',
      id: 149,
      properties: {
        sagoDate: '2018-06-03',
        sagoNo: '20180144',
        sido: '인천광역시',
        sigungu: '남동구',
        dong: '',
        addr: '석정로 간석역북광장 사거리',
        fullAdr: '인천광역시 남동구  석정로 간석역북광장 사거리',
        SEC_CD: '28200102',
        sagoLon: '126.694',
        sagoLat: '37.4656',
        sinkWidth: '0.3',
        sinkExtend: '0.3',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수박스 증설로 인한 되메우기 미흡',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-03'
      },
      geometry: { type: 'Point', coordinates: [126.694, 37.4656] }
    },
    {
      type: 'Feature',
      id: 150,
      properties: {
        sagoDate: '2018-06-04',
        sagoNo: '20180145',
        sido: '세종특별자치시',
        sigungu: '',
        dong: '',
        addr: '금남면 부용리 613-6',
        fullAdr: '세종특별자치시 금남면 부용리 613-6',
        SEC_CD: '36110340',
        sagoLon: '127.3495',
        sagoLat: '36.4972',
        sinkWidth: '4',
        sinkExtend: '3.5',
        sinkDepth: '3.5',
        grdKind: '',
        sagoDetail: '농업용 송수관로 누수',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-10-01'
      },
      geometry: { type: 'Point', coordinates: [127.3495, 36.4972] }
    },
    {
      type: 'Feature',
      id: 151,
      properties: {
        sagoDate: '2018-06-04',
        sagoNo: '20180146',
        sido: '경기도',
        sigungu: '부천시',
        dong: '중동',
        addr: ' 679-5번지',
        fullAdr: '경기도 부천시 원미구 중동 679-5',
        SEC_CD: '41192108',
        sagoLon: '126.7617',
        sagoLat: '37.4968',
        sinkWidth: '0.5',
        sinkExtend: '2',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '노후 하수관과 맨홀 접합부 손상으로 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-05'
      },
      geometry: { type: 'Point', coordinates: [126.7617, 37.4968] }
    },
    {
      type: 'Feature',
      id: 152,
      properties: {
        sagoDate: '2018-06-04',
        sagoNo: '20180147',
        sido: '경기도',
        sigungu: '부천시',
        dong: '상동',
        addr: ' 420-13번지',
        fullAdr: '경기도 부천시 원미구 상동 420-13',
        SEC_CD: '41192109',
        sagoLon: '126.7595',
        sagoLat: '37.4932',
        sinkWidth: '3',
        sinkExtend: '1',
        sinkDepth: '0.6',
        grdKind: '',
        sagoDetail: '노후 하수관 손상으로 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-05'
      },
      geometry: { type: 'Point', coordinates: [126.7595, 37.4932] }
    },
    {
      type: 'Feature',
      id: 153,
      properties: {
        sagoDate: '2018-06-05',
        sagoNo: '20180148',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '흑석동',
        addr: '506번지',
        fullAdr: '광주광역시 광산구 흑석동 506',
        SEC_CD: '29200124',
        sagoLon: '126.8102',
        sagoLat: '35.1844',
        sinkWidth: '1.8',
        sinkExtend: '1.8',
        sinkDepth: '1.2',
        grdKind: '',
        sagoDetail: '연결관 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-07'
      },
      geometry: { type: 'Point', coordinates: [126.8102, 35.1844] }
    },
    {
      type: 'Feature',
      id: 154,
      properties: {
        sagoDate: '2018-06-05',
        sagoNo: '20180149',
        sido: '전라남도',
        sigungu: '여수시',
        dong: '공화동',
        addr: '1184-22 도',
        fullAdr: '전라남도 여수시 공화동 1184-22',
        SEC_CD: '46130103',
        sagoLon: '127.7416',
        sagoLat: '34.7474',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '도시가스관로 매설구간 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-05'
      },
      geometry: { type: 'Point', coordinates: [127.7416, 34.7474] }
    },
    {
      type: 'Feature',
      id: 155,
      properties: {
        sagoDate: '2018-06-05',
        sagoNo: '20180150',
        sido: '인천광역시',
        sigungu: '중구',
        dong: '',
        addr: '서해대로410번길 39 앞 도로',
        fullAdr: '인천광역시 중구  서해대로410번길 39 앞 도로',
        SEC_CD: '28110128',
        sagoLon: '126.635',
        sagoLat: '37.4626',
        sinkWidth: '0.2',
        sinkExtend: '0.2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail:
          '수인선 노반신설공사 제6공구 서해대로410번길 구간 굴착부분 다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-07-03'
      },
      geometry: { type: 'Point', coordinates: [126.635, 37.4626] }
    },
    {
      type: 'Feature',
      id: 156,
      properties: {
        sagoDate: '2018-06-06',
        sagoNo: '20180151',
        sido: '충청북도',
        sigungu: '청주시 서원구',
        dong: '모충동',
        addr: '412-2번지',
        fullAdr: '충청북도 청주시 서원구 모충동 412-2',
        SEC_CD: '43112103',
        sagoLon: '127.4771',
        sagoLat: '36.6287',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관로 노후',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-10-11'
      },
      geometry: { type: 'Point', coordinates: [127.4771, 36.6287] }
    },
    {
      type: 'Feature',
      id: 157,
      properties: {
        sagoDate: '2018-06-06',
        sagoNo: '20180152',
        sido: '충청북도',
        sigungu: '청주시 서원구',
        dong: '분평동',
        addr: '1385',
        fullAdr: '충청북도 청주시 서원구 분평동 1385',
        SEC_CD: '43112106',
        sagoLon: '127.4892',
        sagoLat: '36.6066',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '관로 연결부 접속 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-09-12'
      },
      geometry: { type: 'Point', coordinates: [127.4892, 36.6066] }
    },
    {
      type: 'Feature',
      id: 158,
      properties: {
        sagoDate: '2018-06-07',
        sagoNo: '20180153',
        sido: '울산광역시',
        sigungu: '동구',
        dong: '',
        addr: '대송로 12 (생생마트 앞)',
        fullAdr: '울산광역시 동구  대송로 12 (생생마트 앞)',
        SEC_CD: '31170103',
        sagoLon: '129.428',
        sagoLat: '35.5043',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수박스 슬래브 파손 누수로 인한 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-07'
      },
      geometry: { type: 'Point', coordinates: [129.428, 35.5043] }
    },
    {
      type: 'Feature',
      id: 159,
      properties: {
        sagoDate: '2018-06-07',
        sagoNo: '20180154',
        sido: '충청북도',
        sigungu: '청주시 서원구',
        dong: '분평동',
        addr: '249-26번지',
        fullAdr: '충청북도 청주시 서원구 분평동 249-26',
        SEC_CD: '43112106',
        sagoLon: '127.4836',
        sagoLat: '36.6121',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '하수관로 내부 횡단하는 지장물과의 연결부 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-09-12'
      },
      geometry: { type: 'Point', coordinates: [127.4836, 36.6121] }
    },
    {
      type: 'Feature',
      id: 160,
      properties: {
        sagoDate: '2018-06-07',
        sagoNo: '20180155',
        sido: '전라남도',
        sigungu: '장성군',
        dong: '황룡면 와룡리',
        addr: '36-18',
        fullAdr: '전라남도 장성군 황룡면 와룡리 36-18',
        SEC_CD: '46880360',
        sagoLon: '126.7661',
        sagoLat: '35.271',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '0.4',
        grdKind: '',
        sagoDetail: '원인미상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-07'
      },
      geometry: { type: 'Point', coordinates: [126.7661, 35.271] }
    },
    {
      type: 'Feature',
      id: 161,
      properties: {
        sagoDate: '2018-06-07',
        sagoNo: '20180156',
        sido: '인천광역시',
        sigungu: '서구',
        dong: '백석동',
        addr: ' 54 한들로 교차점 부근',
        fullAdr: '인천광역시 서구 백석동  54 한들로 교차점 부근',
        SEC_CD: '28260101',
        sagoLon: '126.674',
        sagoLat: '37.5772',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 굴착공사 다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-08'
      },
      geometry: { type: 'Point', coordinates: [126.674, 37.5772] }
    },
    {
      type: 'Feature',
      id: 162,
      properties: {
        sagoDate: '2018-06-07',
        sagoNo: '20180157',
        sido: '경기도',
        sigungu: '안양시 동안구',
        dong: '호계동',
        addr: ' 1022-5',
        fullAdr: '경기도 안양시 동안구 호계동 1022-5',
        SEC_CD: '41173104',
        sagoLon: '126.9593',
        sagoLat: '37.3723',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 접합부위 파손에 따른 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-08'
      },
      geometry: { type: 'Point', coordinates: [126.9593, 37.3723] }
    },
    {
      type: 'Feature',
      id: 163,
      properties: {
        sagoDate: '2018-06-08',
        sagoNo: '20180158',
        sido: '부산광역시',
        sigungu: '부산진구',
        dong: '',
        addr: '신천대로 246 메리움 아파트 옆 이면도로',
        fullAdr: '부산광역시 부산진구 신천대로 246 (부암동·메리움아파트)',
        SEC_CD: '26230108',
        sagoLon: '129.0515',
        sagoLat: '35.1638',
        sinkWidth: '1.6',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '인접 건축물 지하 터파기 후 되메우기 시 다짐불량 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-08'
      },
      geometry: { type: 'Point', coordinates: [129.0515, 35.1638] }
    },
    {
      type: 'Feature',
      id: 164,
      properties: {
        sagoDate: '2018-06-08',
        sagoNo: '20180159',
        sido: '경기도',
        sigungu: '용인시 기흥구',
        dong: '언남동',
        addr: ' 356번지',
        fullAdr: '경기도 용인시 기흥구 언남동  356번지',
        SEC_CD: '41463112',
        sagoLon: '127.118',
        sagoLat: '37.2946',
        sinkWidth: '0.3',
        sinkExtend: '0.4',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '우수맨홀 내부 파손에 의한 토사유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-08'
      },
      geometry: { type: 'Point', coordinates: [127.118, 37.2946] }
    },
    {
      type: 'Feature',
      id: 165,
      properties: {
        sagoDate: '2018-06-10',
        sagoNo: '20180160',
        sido: '충청북도',
        sigungu: '청주시 상당구',
        dong: '방서동',
        addr: '210-3',
        fullAdr: '충청북도 청주시 상당구 방서동 210-3',
        SEC_CD: '43111126',
        sagoLon: '127.502',
        sagoLat: '36.6048',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '우수관 파손으로 토사유실 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-11'
      },
      geometry: { type: 'Point', coordinates: [127.502, 36.6048] }
    },
    {
      type: 'Feature',
      id: 166,
      properties: {
        sagoDate: '2018-06-11',
        sagoNo: '20180161',
        sido: '충청북도',
        sigungu: '청주시 서원구',
        dong: '사창동',
        addr: '149-11번지',
        fullAdr: '충청북도 청주시 서원구 사창동 149-11',
        SEC_CD: '43112102',
        sagoLon: '127.4571',
        sagoLat: '36.6345',
        sinkWidth: '0.3',
        sinkExtend: '0.3',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관로 내부 횡단하는 지장물과의 연결부 불량',
        deathCnt: '0',
        injuryCnt: '2',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-09-12'
      },
      geometry: { type: 'Point', coordinates: [127.4571, 36.6345] }
    },
    {
      type: 'Feature',
      id: 167,
      properties: {
        sagoDate: '2018-06-11',
        sagoNo: '20180162',
        sido: '경기도',
        sigungu: '부천시',
        dong: '심곡2동',
        addr: ' 380-11',
        fullAdr: '경기도 부천시 원미구 심곡동 380-11',
        SEC_CD: '41192102',
        sagoLon: '126.7809',
        sagoLat: '37.4872',
        sinkWidth: '0',
        sinkExtend: '0',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수맨홀 노후',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-11'
      },
      geometry: { type: 'Point', coordinates: [126.7809, 37.4872] }
    },
    {
      type: 'Feature',
      id: 168,
      properties: {
        sagoDate: '2018-06-12',
        sagoNo: '20180163',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '비아동',
        addr: '66-62번지',
        fullAdr: '광주광역시 광산구 비아동 66-62',
        SEC_CD: '29200112',
        sagoLon: '126.8255',
        sagoLat: '35.2205',
        sinkWidth: '1.2',
        sinkExtend: '0.8',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '연결관 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-13'
      },
      geometry: { type: 'Point', coordinates: [126.8255, 35.2205] }
    },
    {
      type: 'Feature',
      id: 169,
      properties: {
        sagoDate: '2018-06-12',
        sagoNo: '20180164',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '우암동',
        addr: '333-11 ',
        fullAdr: '충청북도 청주시 청원구 우암동 333-11',
        SEC_CD: '43114101',
        sagoLon: '127.4839',
        sagoLat: '36.646',
        sinkWidth: '2',
        sinkExtend: '3',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '타공사에 의한 이음부 단락 토사유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-26'
      },
      geometry: { type: 'Point', coordinates: [127.4839, 36.646] }
    },
    {
      type: 'Feature',
      id: 170,
      properties: {
        sagoDate: '2018-06-13',
        sagoNo: '20180165',
        sido: '경기도',
        sigungu: '수원시 팔달구',
        dong: '화서동',
        addr: ' 212-2번지 앞',
        fullAdr: '경기도 수원시 팔달구 화서동 212-2',
        SEC_CD: '41115138',
        sagoLon: '126.9965',
        sagoLat: '37.2812',
        sinkWidth: '0',
        sinkExtend: '0',
        sinkDepth: '1.1',
        grdKind: '',
        sagoDetail: '하수관 접합부 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-13'
      },
      geometry: { type: 'Point', coordinates: [126.9965, 37.2812] }
    },
    {
      type: 'Feature',
      id: 171,
      properties: {
        sagoDate: '2018-06-14',
        sagoNo: '20180166',
        sido: '충청북도',
        sigungu: '청주시 흥덕구',
        dong: '가경동',
        addr: '1386',
        fullAdr: '충청북도 청주시 흥덕구 가경동 1386',
        SEC_CD: '43113113',
        sagoLon: '127.4329',
        sagoLat: '36.6277',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 파손 및 하수 누수로 지반유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-15'
      },
      geometry: { type: 'Point', coordinates: [127.4329, 36.6277] }
    },
    {
      type: 'Feature',
      id: 172,
      properties: {
        sagoDate: '2018-06-14',
        sagoNo: '20180167',
        sido: '경상북도',
        sigungu: '영주시',
        dong: '영주동',
        addr: ' 426-2번지 앞',
        fullAdr: '경상북도 영주시 영주동  426-2번지 앞',
        SEC_CD: '47210101',
        sagoLon: '128.621',
        sagoLat: '36.8212',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '우수관 누수',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-23'
      },
      geometry: { type: 'Point', coordinates: [128.621, 36.8212] }
    },
    {
      type: 'Feature',
      id: 173,
      properties: {
        sagoDate: '2018-06-14',
        sagoNo: '20180168',
        sido: '경기도',
        sigungu: '수원시 팔달구',
        dong: '인계동',
        addr: ' 323-5번지 앞',
        fullAdr: '경기도 수원시 팔달구 인계동 323-5',
        SEC_CD: '41115141',
        sagoLon: '127.0321',
        sagoLat: '37.2756',
        sinkWidth: '0',
        sinkExtend: '0',
        sinkDepth: '1.2',
        grdKind: '',
        sagoDetail: '하수관 접합부 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-14'
      },
      geometry: { type: 'Point', coordinates: [127.0321, 37.2756] }
    },
    {
      type: 'Feature',
      id: 174,
      properties: {
        sagoDate: '2018-06-15',
        sagoNo: '20180169',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '',
        addr: '중앙로40 민생반점 앞',
        fullAdr: '강원특별자치도 원주시  중앙로40 민생반점 앞',
        SEC_CD: '51730370',
        sagoLon: '127.878',
        sagoLat: '37.5073',
        sinkWidth: '3',
        sinkExtend: '4.5',
        sinkDepth: '1.2',
        grdKind: '화강암류',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '960000',
        trFnDate: '2018-06-15'
      },
      geometry: { type: 'Point', coordinates: [127.878, 37.5073] }
    },
    {
      type: 'Feature',
      id: 175,
      properties: {
        sagoDate: '2018-06-15',
        sagoNo: '20180170',
        sido: '인천광역시',
        sigungu: '중구',
        dong: '',
        addr: '축항대로118번길 135',
        fullAdr: '인천광역시 중구  축항대로118번길 135',
        SEC_CD: '28110118',
        sagoLon: '126.601',
        sagoLat: '37.4409',
        sinkWidth: '0.9',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '지장물의 하수관 손상으로 인한 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-16'
      },
      geometry: { type: 'Point', coordinates: [126.601, 37.4409] }
    },
    {
      type: 'Feature',
      id: 176,
      properties: {
        sagoDate: '2018-06-18',
        sagoNo: '20180171',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '무실동',
        addr: '144 해장국집 앞',
        fullAdr: '강원특별자치도 원주시 무실동 144 해장국집 앞',
        SEC_CD: '51130115',
        sagoLon: '127.927',
        sagoLat: '37.3236',
        sinkWidth: '2.7',
        sinkExtend: '3',
        sinkDepth: '1.2',
        grdKind: '충척층',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '720000',
        trFnDate: '2018-06-18'
      },
      geometry: { type: 'Point', coordinates: [127.927, 37.3236] }
    },
    {
      type: 'Feature',
      id: 177,
      properties: {
        sagoDate: '2018-06-18',
        sagoNo: '20180172',
        sido: '인천광역시',
        sigungu: '부평구',
        dong: '',
        addr: '경인로 760 부평삼거리역 2번출구',
        fullAdr: '인천광역시 부평구  경인로 760 부평삼거리역 2번출구',
        SEC_CD: '28237101',
        sagoLon: '126.723',
        sagoLat: '37.4996',
        sinkWidth: '0.5',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '노후 하수관 손상으로 누수',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-19'
      },
      geometry: { type: 'Point', coordinates: [126.723, 37.4996] }
    },
    {
      type: 'Feature',
      id: 178,
      properties: {
        sagoDate: '2018-06-18',
        sagoNo: '20180173',
        sido: '경기도',
        sigungu: '남양주시',
        dong: '화도읍 묵현리',
        addr: ' 435-8',
        fullAdr: '경기도 남양주시 화도읍 묵현리 435-8',
        SEC_CD: '41360256',
        sagoLon: '127.2752',
        sagoLat: '37.6571',
        sinkWidth: '0',
        sinkExtend: '0',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '우수관 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-18'
      },
      geometry: { type: 'Point', coordinates: [127.2752, 37.6571] }
    },
    {
      type: 'Feature',
      id: 179,
      properties: {
        sagoDate: '2018-06-19',
        sagoNo: '20180174',
        sido: '부산광역시',
        sigungu: '연제구',
        dong: '',
        addr: '배산로 24-1',
        fullAdr: '부산광역시 연제구 배산로 24-1 (연산동)',
        SEC_CD: '26470102',
        sagoLon: '129.0948',
        sagoLat: '35.1741',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.8',
        grdKind: '',
        sagoDetail: '도로 하부 다짐불량 으로 인한 지반함몰',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-20'
      },
      geometry: { type: 'Point', coordinates: [129.0948, 35.1741] }
    },
    {
      type: 'Feature',
      id: 180,
      properties: {
        sagoDate: '2018-06-19',
        sagoNo: '20180175',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '운남동',
        addr: '769-4번지',
        fullAdr: '광주광역시 광산구 운남동 769-4',
        SEC_CD: '29200120',
        sagoLon: '126.8204',
        sagoLat: '35.1781',
        sinkWidth: '0.7',
        sinkExtend: '0.6',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관로 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-20'
      },
      geometry: { type: 'Point', coordinates: [126.8204, 35.1781] }
    },
    {
      type: 'Feature',
      id: 181,
      properties: {
        sagoDate: '2018-06-19',
        sagoNo: '20180176',
        sido: '경기도',
        sigungu: '동두천시',
        dong: '안흥동',
        addr: ' 121-1',
        fullAdr: '경기도 동두천시 안흥동 121-1',
        SEC_CD: '41250108',
        sagoLon: '127.0419',
        sagoLat: '37.9334',
        sinkWidth: '0.7',
        sinkExtend: '0.8',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '포장다짐 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-20'
      },
      geometry: { type: 'Point', coordinates: [127.0419, 37.9334] }
    },
    {
      type: 'Feature',
      id: 182,
      properties: {
        sagoDate: '2018-06-20',
        sagoNo: '20180177',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '도산동',
        addr: '662-6번지',
        fullAdr: '광주광역시 광산구 도산동 662-6',
        SEC_CD: '29200102',
        sagoLon: '126.7929',
        sagoLat: '35.1265',
        sinkWidth: '0.5',
        sinkExtend: '0.7',
        sinkDepth: '1.1',
        grdKind: '',
        sagoDetail: '연결관 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-22'
      },
      geometry: { type: 'Point', coordinates: [126.7929, 35.1265] }
    },
    {
      type: 'Feature',
      id: 183,
      properties: {
        sagoDate: '2018-06-20',
        sagoNo: '20180178',
        sido: '전라남도',
        sigungu: '완도군',
        dong: '약산면',
        addr: '지방도 830호선 보건지소 뒤 ',
        fullAdr: '전라남도 완도군 약산면 장용리 540-15',
        SEC_CD: '46890340',
        sagoLon: '126.9012',
        sagoLat: '34.3948',
        sinkWidth: '1.3',
        sinkExtend: '1.3',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail:
          '연약지반 구간으로서 도로시설공사 당시 연약지반 드레인공법으로 시공된 파이프의 내측이 침하되는 현상 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-20'
      },
      geometry: { type: 'Point', coordinates: [126.9012, 34.3948] }
    },
    {
      type: 'Feature',
      id: 184,
      properties: {
        sagoDate: '2018-06-20',
        sagoNo: '20180179',
        sido: '경상북도',
        sigungu: '포항시 북구',
        dong: '청하면 덕장리',
        addr: ' 중고차 200m 전',
        fullAdr: '경상북도 포항시 북구 흥해읍 덕장리 87-4',
        SEC_CD: '47113250',
        sagoLon: '129.3496',
        sagoLat: '36.1399',
        sinkWidth: '6',
        sinkExtend: '12',
        sinkDepth: '0.6',
        grdKind: '',
        sagoDetail: '도로 노후화 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '0001-01-01'
      },
      geometry: { type: 'Point', coordinates: [129.3496, 36.1399] }
    },
    {
      type: 'Feature',
      id: 185,
      properties: {
        sagoDate: '2018-06-20',
        sagoNo: '20180180',
        sido: '경기도',
        sigungu: '여주시',
        dong: '흥문동',
        addr: ' 427-7 삼한아파트 진입로 ',
        fullAdr: '경기도 여주시 홍문동 428',
        SEC_CD: '41670102',
        sagoLon: '127.6359',
        sagoLat: '37.286',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '우수에 의한 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-20'
      },
      geometry: { type: 'Point', coordinates: [127.6359, 37.286] }
    },
    {
      type: 'Feature',
      id: 186,
      properties: {
        sagoDate: '2018-06-21',
        sagoNo: '20180181',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '',
        addr: '청주내덕노인복지관 내 주차장',
        fullAdr: '충청북도 청주시 청원구 충청대로62번길 1-18 (내덕동)',
        SEC_CD: '43114102',
        sagoLon: '127.4947',
        sagoLat: '36.6626',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '우수맨홀구조물 측부 싱크홀 및 일부 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-07-12'
      },
      geometry: { type: 'Point', coordinates: [127.4947, 36.6626] }
    },
    {
      type: 'Feature',
      id: 187,
      properties: {
        sagoDate: '2018-06-21',
        sagoNo: '20180182',
        sido: '충청북도',
        sigungu: '충주시',
        dong: '',
        addr: '대원9길27',
        fullAdr: '충청북도 충주시  대원9길27',
        SEC_CD: '43130116',
        sagoLon: '127.921',
        sagoLat: '36.9851',
        sinkWidth: '0.5',
        sinkExtend: '3',
        sinkDepth: '0.7',
        grdKind: '',
        sagoDetail: '우수관 이음부 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-25'
      },
      geometry: { type: 'Point', coordinates: [127.921, 36.9851] }
    },
    {
      type: 'Feature',
      id: 188,
      properties: {
        sagoDate: '2018-06-21',
        sagoNo: '20180183',
        sido: '경상북도',
        sigungu: '영주시',
        dong: '휴천동',
        addr: ' 328-3번지 앞',
        fullAdr: '경상북도 영주시 휴천동  328-3번지 앞',
        SEC_CD: '47210104',
        sagoLon: '128.622',
        sagoLat: '36.8096',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '우수관 누수',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-25'
      },
      geometry: { type: 'Point', coordinates: [128.622, 36.8096] }
    },
    {
      type: 'Feature',
      id: 189,
      properties: {
        sagoDate: '2018-06-24',
        sagoNo: '20180184',
        sido: '충청북도',
        sigungu: '충주시',
        dong: '',
        addr: '동수10길26',
        fullAdr: '충청북도 충주시  동수10길26',
        SEC_CD: '43130118',
        sagoLon: '127.934',
        sagoLat: '36.9848',
        sinkWidth: '1.2',
        sinkExtend: '1.2',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '우수관 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-07-02'
      },
      geometry: { type: 'Point', coordinates: [127.934, 36.9848] }
    },
    {
      type: 'Feature',
      id: 190,
      properties: {
        sagoDate: '2018-06-25',
        sagoNo: '20180185',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '월곡동',
        addr: '620번지',
        fullAdr: '광주광역시 광산구 월곡동 620',
        SEC_CD: '29200128',
        sagoLon: '126.8082',
        sagoLat: '35.1732',
        sinkWidth: '0.8',
        sinkExtend: '0.6',
        sinkDepth: '1.2',
        grdKind: '',
        sagoDetail: '하수관로 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-28'
      },
      geometry: { type: 'Point', coordinates: [126.8082, 35.1732] }
    },
    {
      type: 'Feature',
      id: 191,
      properties: {
        sagoDate: '2018-06-26',
        sagoNo: '20180186',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '율량동',
        addr: '1052',
        fullAdr: '충청북도 청주시 청원구 율량동 1052',
        SEC_CD: '43114103',
        sagoLon: '127.4924',
        sagoLat: '36.6674',
        sinkWidth: '2',
        sinkExtend: '3',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '타공사에 의한 이음부 단락 토사유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-26'
      },
      geometry: { type: 'Point', coordinates: [127.4924, 36.6674] }
    },
    {
      type: 'Feature',
      id: 192,
      properties: {
        sagoDate: '2018-06-26',
        sagoNo: '20180187',
        sido: '경기도',
        sigungu: '여주시',
        dong: '산북면',
        addr: ' 광여로 1526',
        fullAdr: '경기도 여주시 산북면 광여로 1526',
        SEC_CD: '41670380',
        sagoLon: '127.4655',
        sagoLat: '37.419',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '급수공사 후 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-26'
      },
      geometry: { type: 'Point', coordinates: [127.4655, 37.419] }
    },
    {
      type: 'Feature',
      id: 193,
      properties: {
        sagoDate: '2018-06-27',
        sagoNo: '20180188',
        sido: '제주특별자치도',
        sigungu: '제주시',
        dong: '삼도일동',
        addr: '서광로 15길 27·재활용센터 앞',
        fullAdr: '제주특별자치도 제주시 서광로11길 15 (삼도일동)',
        SEC_CD: '50110105',
        sagoLon: '126.5164',
        sagoLat: '33.5014',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '관로 공사 후 복구 불량 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-30'
      },
      geometry: { type: 'Point', coordinates: [126.5164, 33.5014] }
    },
    {
      type: 'Feature',
      id: 194,
      properties: {
        sagoDate: '2018-06-27',
        sagoNo: '20180189',
        sido: '제주특별자치도',
        sigungu: '제주시',
        dong: '용담이동',
        addr: '다호길 131',
        fullAdr: '제주특별자치도 제주시 다호길 131 (용담이동)',
        SEC_CD: '50110109',
        sagoLon: '126.4933',
        sagoLat: '33.5032',
        sinkWidth: '0.5',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '집수정 연결 공사 시 관로 미연결 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-28'
      },
      geometry: { type: 'Point', coordinates: [126.4933, 33.5032] }
    },
    {
      type: 'Feature',
      id: 195,
      properties: {
        sagoDate: '2018-06-27',
        sagoNo: '20180190',
        sido: '경기도',
        sigungu: '용인시 수지구',
        dong: '풍덕천동',
        addr: ' 69-112번지',
        fullAdr: '경기도 용인시 수지구 풍덕천동  69-112번지',
        SEC_CD: '41465101',
        sagoLon: '127.1',
        sagoLat: '37.325',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '오수맨홀 내부 파손에 의한 토사유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-27'
      },
      geometry: { type: 'Point', coordinates: [127.1, 37.325] }
    },
    {
      type: 'Feature',
      id: 196,
      properties: {
        sagoDate: '2018-06-28',
        sagoNo: '20180191',
        sido: '경기도',
        sigungu: '부천시',
        dong: '소사동',
        addr: ' 18-38',
        fullAdr: '경기도 부천시 원미구 소사동 18-38',
        SEC_CD: '41192106',
        sagoLon: '126.7933',
        sagoLat: '37.4849',
        sinkWidth: '0',
        sinkExtend: '0',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 노후',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-28'
      },
      geometry: { type: 'Point', coordinates: [126.7933, 37.4849] }
    },
    {
      type: 'Feature',
      id: 197,
      properties: {
        sagoDate: '2018-06-28',
        sagoNo: '20180192',
        sido: '경기도',
        sigungu: '안양시 동안구',
        dong: '호계동',
        addr: ' 1014-1',
        fullAdr: '경기도 안양시 동안구 호계동 1014-1',
        SEC_CD: '41173104',
        sagoLon: '126.9455',
        sagoLat: '37.3866',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 접합부위 파손에 따른 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-29'
      },
      geometry: { type: 'Point', coordinates: [126.9455, 37.3866] }
    },
    {
      type: 'Feature',
      id: 198,
      properties: {
        sagoDate: '2018-06-30',
        sagoNo: '20180193',
        sido: '충청북도',
        sigungu: '청주시 상당구',
        dong: '남일면 효촌리',
        addr: '주원애뜰앞 사거리',
        fullAdr: '충청북도 청주시 상당구 남일면 효촌리 35-19',
        SEC_CD: '43111340',
        sagoLon: '127.5101',
        sagoLat: '36.5864',
        sinkWidth: '1',
        sinkExtend: '3',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '도시계획도로 하수관 공사 구간 굴착부분 다짐 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-07-24'
      },
      geometry: { type: 'Point', coordinates: [127.5101, 36.5864] }
    },
    {
      type: 'Feature',
      id: 199,
      properties: {
        sagoDate: '2018-06-30',
        sagoNo: '20180194',
        sido: '경상북도',
        sigungu: '구미시',
        dong: '신평동',
        addr: ' 168-16(구미대로32길 1 인근)',
        fullAdr: '경상북도 구미시 신평동 168-16',
        SEC_CD: '47190111',
        sagoLon: '128.3631',
        sagoLat: '36.1207',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '노후 하수관 접합부(관대관접합) 손상으로 누수 및 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-30'
      },
      geometry: { type: 'Point', coordinates: [128.3631, 36.1207] }
    },
    {
      type: 'Feature',
      id: 200,
      properties: {
        sagoDate: '2018-06-14',
        sagoNo: '20180326',
        sido: '제주특별자치도',
        sigungu: '제주시',
        dong: '',
        addr: '남광로 228 주차장 앞',
        fullAdr: '제주특별자치도 제주시 남광로 228 (건입동)',
        SEC_CD: '50110107',
        sagoLon: '126.5511',
        sagoLat: '33.5043',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '내부 지반 유실 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-14'
      },
      geometry: { type: 'Point', coordinates: [126.5511, 33.5043] }
    },
    {
      type: 'Feature',
      id: 201,
      properties: {
        sagoDate: '2018-06-01',
        sagoNo: '20180340',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '',
        addr: '율량동 1015',
        fullAdr: '충청북도 청주시 청원구 율량동 1015',
        SEC_CD: '43114103',
        sagoLon: '127.4914',
        sagoLat: '36.666',
        sinkWidth: '2',
        sinkExtend: '3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '타공사(간접원인)에 의한 이음부 단락(직접원인) 토사유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-03'
      },
      geometry: { type: 'Point', coordinates: [127.4914, 36.666] }
    },
    {
      type: 'Feature',
      id: 202,
      properties: {
        sagoDate: '2018-06-07',
        sagoNo: '20180341',
        sido: '충청북도',
        sigungu: '청주시 서원구',
        dong: '',
        addr: '분평동 249-26',
        fullAdr: '충청북도 청주시 서원구 분평동 249-26',
        SEC_CD: '43112106',
        sagoLon: '127.4836',
        sagoLat: '36.6121',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '하수BOX내부를 관통하는 지장물과의 연결부 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-09-12'
      },
      geometry: { type: 'Point', coordinates: [127.4836, 36.6121] }
    },
    {
      type: 'Feature',
      id: 203,
      properties: {
        sagoDate: '2018-06-11',
        sagoNo: '20180342',
        sido: '충청북도',
        sigungu: '청주시 서원구',
        dong: '',
        addr: '사창동 149-11',
        fullAdr: '충청북도 청주시 서원구 사창동 149-11',
        SEC_CD: '43112102',
        sagoLon: '127.4571',
        sagoLat: '36.6345',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수BOX내부를 관통하는 지장물과의 연결부 불량',
        deathCnt: '0',
        injuryCnt: '2',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-09-12'
      },
      geometry: { type: 'Point', coordinates: [127.4571, 36.6345] }
    },
    {
      type: 'Feature',
      id: 204,
      properties: {
        sagoDate: '2018-06-12',
        sagoNo: '20180343',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '',
        addr: '우암동 333-11',
        fullAdr: '충청북도 청주시 청원구 우암동 333-11',
        SEC_CD: '43114101',
        sagoLon: '127.4839',
        sagoLat: '36.646',
        sinkWidth: '4',
        sinkExtend: '5',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '타공사(간접원인)에 의한 이음부 단락(직접원인) 토사유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-14'
      },
      geometry: { type: 'Point', coordinates: [127.4839, 36.646] }
    },
    {
      type: 'Feature',
      id: 205,
      properties: {
        sagoDate: '2018-06-26',
        sagoNo: '20180344',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '',
        addr: '율량동 1052',
        fullAdr: '충청북도 청주시 청원구 율량동 1052',
        SEC_CD: '43114103',
        sagoLon: '127.4924',
        sagoLat: '36.6674',
        sinkWidth: '2',
        sinkExtend: '3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '노후화(간접원인)에 의한 관파손(직접원인) 토사유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-06-28'
      },
      geometry: { type: 'Point', coordinates: [127.4924, 36.6674] }
    },
    {
      type: 'Feature',
      id: 206,
      properties: {
        sagoDate: '2018-07-17',
        sagoNo: '20180011',
        sido: '서울특별시',
        sigungu: '관악구',
        dong: '보라매동',
        addr: ' 732-1 7(보라매로 21)',
        fullAdr: '서울특별시 관악구 봉천동 732-17',
        SEC_CD: '11620101',
        sagoLon: '126.9268',
        sagoLat: '37.4914',
        sinkWidth: '2',
        sinkExtend: '0.6',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '폐쇄되지 않은 폐 빗물받이연결관으로 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-07-17'
      },
      geometry: { type: 'Point', coordinates: [126.9268, 37.4914] }
    },
    {
      type: 'Feature',
      id: 207,
      properties: {
        sagoDate: '2018-07-17',
        sagoNo: '20180012',
        sido: '서울특별시',
        sigungu: '중구',
        dong: '',
        addr: '퇴계로 196 (충무로역 3번출구)',
        fullAdr: '서울특별시 중구 퇴계로 196 (필동2가)',
        SEC_CD: '11140138',
        sagoLon: '126.9937',
        sagoLat: '37.5609',
        sinkWidth: '5',
        sinkExtend: '5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '상수도 이설구간 접합부(700mm에서 300mm로 분기)에서 외부 진동· 압력 등에 의한 파열로 누수 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-07-17'
      },
      geometry: { type: 'Point', coordinates: [126.9937, 37.5609] }
    },
    {
      type: 'Feature',
      id: 208,
      properties: {
        sagoDate: '2018-07-01',
        sagoNo: '20180195',
        sido: '부산광역시',
        sigungu: '사하구',
        dong: '',
        addr: '반도보라아파트 102동 앞 ',
        fullAdr: '부산광역시 사하구 당리동 180',
        SEC_CD: '26380102',
        sagoLon: '128.9748',
        sagoLat: '35.108',
        sinkWidth: '1.5',
        sinkExtend: '3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수관로 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-07-02'
      },
      geometry: { type: 'Point', coordinates: [128.9748, 35.108] }
    },
    {
      type: 'Feature',
      id: 209,
      properties: {
        sagoDate: '2018-07-01',
        sagoNo: '20180196',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '월평동',
        addr: '진달래네거리 갑천초등학교 방면',
        fullAdr: '대전광역시 서구 월평동 진달래네거리 갑천초등학교 방면',
        SEC_CD: '30170113',
        sagoLon: '127.368',
        sagoLat: '36.3599',
        sinkWidth: '2',
        sinkExtend: '5',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '오수관 파손 및 집중호우로 인한 단기간 다량의 토사유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '25000000',
        trFnDate: '2018-07-04'
      },
      geometry: { type: 'Point', coordinates: [127.368, 36.3599] }
    },
    {
      type: 'Feature',
      id: 210,
      properties: {
        sagoDate: '2018-07-03',
        sagoNo: '20180197',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '우산동',
        addr: '한지공장 앞',
        fullAdr: '강원특별자치도 원주시 우산동 한지공장 앞',
        SEC_CD: '51130111',
        sagoLon: '127.941',
        sagoLat: '37.3764',
        sinkWidth: '3.3',
        sinkExtend: '4.2',
        sinkDepth: '1',
        grdKind: '편암류',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '820000',
        trFnDate: '2018-07-03'
      },
      geometry: { type: 'Point', coordinates: [127.941, 37.3764] }
    },
    {
      type: 'Feature',
      id: 211,
      properties: {
        sagoDate: '2018-07-03',
        sagoNo: '20180198',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '월평동',
        addr: '백합네거리 월평동 265번지 방면',
        fullAdr: '대전광역시 서구 월평동 백합네거리 월평동 265번지 방면',
        SEC_CD: '30170113',
        sagoLon: '127.377',
        sagoLat: '36.3622',
        sinkWidth: '1',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '오수관 접합부 마감불량으로 인한 장기간 토사유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '5000000',
        trFnDate: '2018-07-04'
      },
      geometry: { type: 'Point', coordinates: [127.377, 36.3622] }
    },
    {
      type: 'Feature',
      id: 212,
      properties: {
        sagoDate: '2018-07-04',
        sagoNo: '20180199',
        sido: '인천광역시',
        sigungu: '연수구',
        dong: '',
        addr: '인천타워대로 155 인근',
        fullAdr: '인천광역시 연수구  인천타워대로 155 인근',
        SEC_CD: '28185106',
        sagoLon: '126.644',
        sagoLat: '37.3799',
        sinkWidth: '5',
        sinkExtend: '2',
        sinkDepth: '6',
        grdKind: '',
        sagoDetail: '오수압송관로 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-07-05'
      },
      geometry: { type: 'Point', coordinates: [126.644, 37.3799] }
    },
    {
      type: 'Feature',
      id: 213,
      properties: {
        sagoDate: '2018-07-05',
        sagoNo: '20180200',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '흥업면 흥업리',
        addr: '181',
        fullAdr: '강원특별자치도 원주시 흥업면 흥업리 181',
        SEC_CD: '51130370',
        sagoLon: '127.922',
        sagoLat: '37.2983',
        sinkWidth: '2',
        sinkExtend: '2.8',
        sinkDepth: '1',
        grdKind: '충척층',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '640000',
        trFnDate: '2018-07-05'
      },
      geometry: { type: 'Point', coordinates: [127.922, 37.2983] }
    },
    {
      type: 'Feature',
      id: 214,
      properties: {
        sagoDate: '2018-07-06',
        sagoNo: '20180201',
        sido: '경상남도',
        sigungu: '진주시',
        dong: '상평동',
        addr: '구 삼삼주유소 사거리',
        fullAdr: '경상남도 진주시 대신로 181-1 (상평동)',
        SEC_CD: '48170121',
        sagoLon: '128.1175',
        sagoLat: '35.1767',
        sinkWidth: '3',
        sinkExtend: '1.5',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail: '폐수관로 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-07-06'
      },
      geometry: { type: 'Point', coordinates: [128.1175, 35.1767] }
    },
    {
      type: 'Feature',
      id: 215,
      properties: {
        sagoDate: '2018-07-10',
        sagoNo: '20180202',
        sido: '충청남도',
        sigungu: '공주시',
        dong: '유구읍 신영리',
        addr: '522',
        fullAdr: '충청남도 공주시 유구읍 신영리 522',
        SEC_CD: '44150250',
        sagoLon: '126.967',
        sagoLat: '36.5652',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '시공불량(신규설치)으로 인한 배수관 누수로 지반이 약화됨',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-07-20'
      },
      geometry: { type: 'Point', coordinates: [126.967, 36.5652] }
    },
    {
      type: 'Feature',
      id: 216,
      properties: {
        sagoDate: '2018-07-11',
        sagoNo: '20180203',
        sido: '부산광역시',
        sigungu: '동래구',
        dong: '',
        addr: '번영로 서울방향 원동IC 300m 지난지점 1차로',
        fullAdr: '부산광역시 동래구 삼어로 32 (안락동)',
        SEC_CD: '26260102',
        sagoLon: '129.115',
        sagoLat: '35.2004',
        sinkWidth: '3.5',
        sinkExtend: '3.5',
        sinkDepth: '3.6',
        grdKind: '',
        sagoDetail:
          '하천 수위변동에 따른 세립분 유출 및 매립층 침하로 도로 성토부 함몰',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-07-11'
      },
      geometry: { type: 'Point', coordinates: [129.115, 35.2004] }
    },
    {
      type: 'Feature',
      id: 217,
      properties: {
        sagoDate: '2018-07-12',
        sagoNo: '20180205',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '명륜동',
        addr: '교촌길2',
        fullAdr: '강원특별자치도 원주시 명륜동 교촌길2',
        SEC_CD: '51130106',
        sagoLon: '127.95',
        sagoLat: '37.3382',
        sinkWidth: '2.7',
        sinkExtend: '3.2',
        sinkDepth: '1.5',
        grdKind: '충척층',
        sagoDetail: '상수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1470000',
        trFnDate: '2018-07-12'
      },
      geometry: { type: 'Point', coordinates: [127.95, 37.3382] }
    },
    {
      type: 'Feature',
      id: 218,
      properties: {
        sagoDate: '2018-07-12',
        sagoNo: '20180206',
        sido: '경상북도',
        sigungu: '포항시 북구',
        dong: '청하면',
        addr: ' 청하삼거리 현대북포항서비스 앞',
        fullAdr: '경상북도 포항시 북구 청하면 동해대로 2349',
        SEC_CD: '47113320',
        sagoLon: '129.3399',
        sagoLat: '36.1787',
        sinkWidth: '5.5',
        sinkExtend: '2.4',
        sinkDepth: '0.04',
        grdKind: '',
        sagoDetail: '도로 노후화 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '0',
        trFnDate: '0001-01-01'
      },
      geometry: { type: 'Point', coordinates: [129.3399, 36.1787] }
    },
    {
      type: 'Feature',
      id: 219,
      properties: {
        sagoDate: '2018-07-12',
        sagoNo: '20180207',
        sido: '경상북도',
        sigungu: '포항시 북구',
        dong: '청하면',
        addr: ' 포항cc 입구 전방',
        fullAdr: '경상북도 포항시 북구 송라면 상송리 460',
        SEC_CD: '47113330',
        sagoLon: '129.338',
        sagoLat: '36.2214',
        sinkWidth: '1.1',
        sinkExtend: '1.6',
        sinkDepth: '0.04',
        grdKind: '',
        sagoDetail: '도로 노후화 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '0',
        trFnDate: '0001-01-01'
      },
      geometry: { type: 'Point', coordinates: [129.338, 36.2214] }
    },
    {
      type: 'Feature',
      id: 220,
      properties: {
        sagoDate: '2018-07-13',
        sagoNo: '20180208',
        sido: '전북특별자치도',
        sigungu: '정읍시',
        dong: '이평면 산매리',
        addr: '968번지 근처(덕천천)',
        fullAdr: '전북특별자치도 정읍시 이평면 산매리 968번지 근처(덕천천)',
        SEC_CD: '52180370',
        sagoLon: '126.815',
        sagoLat: '35.6472',
        sinkWidth: '1.5',
        sinkExtend: '2.3',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '농배수로관 옆으로 농업용수 유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-07-13'
      },
      geometry: { type: 'Point', coordinates: [126.815, 35.6472] }
    },
    {
      type: 'Feature',
      id: 221,
      properties: {
        sagoDate: '2018-07-15',
        sagoNo: '20180209',
        sido: '광주광역시',
        sigungu: '북구',
        dong: '양산동',
        addr: '283-20번지 주변',
        fullAdr: '광주광역시 북구 양산동 283-20',
        SEC_CD: '29170128',
        sagoLon: '126.8724',
        sagoLat: '35.2057',
        sinkWidth: '0',
        sinkExtend: '0',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '맨홀 슬래브 노후화로 인한 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-07-16'
      },
      geometry: { type: 'Point', coordinates: [126.8724, 35.2057] }
    },
    {
      type: 'Feature',
      id: 222,
      properties: {
        sagoDate: '2018-07-15',
        sagoNo: '20180210',
        sido: '경기도',
        sigungu: '수원시 팔달구',
        dong: '',
        addr: '수원시청역 사거리 교차로 내',
        fullAdr: '경기도 수원시 팔달구 효원로 271-2 (인계동)',
        SEC_CD: '41115141',
        sagoLon: '127.0314',
        sagoLat: '37.262',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.15',
        grdKind: '',
        sagoDetail:
          '지하철 공사 시 설치한 가시설을 제거하지 않고 매몰하여·폐자재 사이에 빈 공간이 생겨 상부 지반의 토사가 장시간에·걸쳐 그 빈공간으로 흘러 내리면서 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-07-16'
      },
      geometry: { type: 'Point', coordinates: [127.0314, 37.262] }
    },
    {
      type: 'Feature',
      id: 223,
      properties: {
        sagoDate: '2018-07-16',
        sagoNo: '20180211',
        sido: '부산광역시',
        sigungu: '연제구',
        dong: '',
        addr: '중앙대로1028',
        fullAdr: '부산광역시 연제구 중앙대로 1028 (연산동)',
        SEC_CD: '26470102',
        sagoLon: '129.0773',
        sagoLat: '35.1802',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '도로 하부 다짐불량 으로 인한 지반함몰',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-07-16'
      },
      geometry: { type: 'Point', coordinates: [129.0773, 35.1802] }
    },
    {
      type: 'Feature',
      id: 224,
      properties: {
        sagoDate: '2018-07-16',
        sagoNo: '20180212',
        sido: '경기도',
        sigungu: '부천시',
        dong: '원미동',
        addr: ' 76-1번지',
        fullAdr: '경기도 부천시 원미구 원미동 76-1',
        SEC_CD: '41192101',
        sagoLon: '126.7918',
        sagoLat: '37.4952',
        sinkWidth: '2',
        sinkExtend: '4',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수관로 파손에 따른 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-12-14'
      },
      geometry: { type: 'Point', coordinates: [126.7918, 37.4952] }
    },
    {
      type: 'Feature',
      id: 225,
      properties: {
        sagoDate: '2018-07-17',
        sagoNo: '20180213',
        sido: '인천광역시',
        sigungu: '중구',
        dong: '',
        addr: '축항대로 234 연안아파트 앞 도로',
        fullAdr: '인천광역시 중구  축항대로 234 연안아파트 앞 도로',
        SEC_CD: '28110118',
        sagoLon: '126.621',
        sagoLat: '37.4495',
        sinkWidth: '0.4',
        sinkExtend: '0.4',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '지장물의 하수관 손상으로 인한 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-07-18'
      },
      geometry: { type: 'Point', coordinates: [126.621, 37.4495] }
    },
    {
      type: 'Feature',
      id: 226,
      properties: {
        sagoDate: '2018-07-17',
        sagoNo: '20180214',
        sido: '경기도',
        sigungu: '부천시',
        dong: '소사동',
        addr: '소사동3-4',
        fullAdr: '경기도 부천시 원미구 소사동 3-4',
        SEC_CD: '41192106',
        sagoLon: '126.7903',
        sagoLat: '37.4871',
        sinkWidth: '0',
        sinkExtend: '0',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수맨홀 노후',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-07-18'
      },
      geometry: { type: 'Point', coordinates: [126.7903, 37.4871] }
    },
    {
      type: 'Feature',
      id: 227,
      properties: {
        sagoDate: '2018-07-17',
        sagoNo: '20180215',
        sido: '경기도',
        sigungu: '부천시',
        dong: '춘의동',
        addr: ' 75-9번지',
        fullAdr: '경기도 부천시 원미구 춘의동 75-9',
        SEC_CD: '41192103',
        sagoLon: '126.7901',
        sagoLat: '37.5047',
        sinkWidth: '2',
        sinkExtend: '1.8',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '하수관로 파손에 따른 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-12-14'
      },
      geometry: { type: 'Point', coordinates: [126.7901, 37.5047] }
    },
    {
      type: 'Feature',
      id: 228,
      properties: {
        sagoDate: '2018-07-18',
        sagoNo: '20180216',
        sido: '경상북도',
        sigungu: '포항시 북구',
        dong: '청하면',
        addr: ' 청하삼거리 후방 명가짜장 진입로',
        fullAdr: '경상북도 포항시 북구 청하면 동해대로 2349',
        SEC_CD: '47113320',
        sagoLon: '129.3399',
        sagoLat: '36.1787',
        sinkWidth: '2.6',
        sinkExtend: '17',
        sinkDepth: '0.13',
        grdKind: '',
        sagoDetail: '도로 노후화 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '0001-01-01'
      },
      geometry: { type: 'Point', coordinates: [129.3399, 36.1787] }
    },
    {
      type: 'Feature',
      id: 229,
      properties: {
        sagoDate: '2018-07-19',
        sagoNo: '20180217',
        sido: '부산광역시',
        sigungu: '수영구',
        dong: '',
        addr: '광안해변로277번길 3 앞',
        fullAdr: '부산광역시 수영구 광안해변로277번길 3 (민락동)',
        SEC_CD: '26500103',
        sagoLon: '129.124',
        sagoLat: '35.156',
        sinkWidth: '0.7',
        sinkExtend: '0.8',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '측구균열 및 개인배수시설 누수로 인한 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-07-19'
      },
      geometry: { type: 'Point', coordinates: [129.124, 35.156] }
    },
    {
      type: 'Feature',
      id: 230,
      properties: {
        sagoDate: '2018-07-20',
        sagoNo: '20180218',
        sido: '부산광역시',
        sigungu: '연제구',
        dong: '',
        addr: '월드컵대로 233',
        fullAdr: '부산광역시 연제구 월드컵대로 233 (거제동)',
        SEC_CD: '26470101',
        sagoLon: '129.0714',
        sagoLat: '35.1894',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '도로 하부 다짐불량 으로 인한 지반함몰',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-07-20'
      },
      geometry: { type: 'Point', coordinates: [129.0714, 35.1894] }
    },
    {
      type: 'Feature',
      id: 231,
      properties: {
        sagoDate: '2018-07-20',
        sagoNo: '20180219',
        sido: '전라남도',
        sigungu: '영광군',
        dong: '대마면 원흥리',
        addr: '17-7 버스승강장 옆 마을 진입로',
        fullAdr: '전라남도 영광군 대마면 원흥리 17-7',
        SEC_CD: '46870310',
        sagoLon: '126.5672',
        sagoLat: '35.3025',
        sinkWidth: '2',
        sinkExtend: '1',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '상수도 개량공사 구간 굴착부분 아스콘 다짐 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-07-27'
      },
      geometry: { type: 'Point', coordinates: [126.5672, 35.3025] }
    },
    {
      type: 'Feature',
      id: 232,
      properties: {
        sagoDate: '2018-07-23',
        sagoNo: '20180220',
        sido: '부산광역시',
        sigungu: '북구',
        dong: '화명동',
        addr: '941-1번지 일원 지하철 2호선 상부도로',
        fullAdr: '부산광역시 북구 화명동 941-1',
        SEC_CD: '26320102',
        sagoLon: '129.009',
        sagoLat: '35.2229',
        sinkWidth: '5',
        sinkExtend: '1.5',
        sinkDepth: '1.7',
        grdKind: '',
        sagoDetail:
          '도시철도 건설 시 가시설 따라 한전지중선로BOX와 지하철 시설 토류벽 사이 폭 1m공간에 공동발생· 되메움부의 압밀 등에 의한 부등침하 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-07-24'
      },
      geometry: { type: 'Point', coordinates: [129.009, 35.2229] }
    },
    {
      type: 'Feature',
      id: 233,
      properties: {
        sagoDate: '2018-07-23',
        sagoNo: '20180221',
        sido: '경상북도',
        sigungu: '청도군',
        dong: '금천면 동곡리',
        addr: ' 885-36',
        fullAdr: '경상북도 청도군 금천면 동곡리  885-36',
        SEC_CD: '48740400',
        sagoLon: '128.534',
        sagoLat: '35.4109',
        sinkWidth: '3.5',
        sinkExtend: '10',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail:
          '전력관· 통신관· 상수관· 오수관· 가스관 등 지하매설물 복합에 따른 동공 발생으로 잠정 결론',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-07-23'
      },
      geometry: { type: 'Point', coordinates: [128.534, 35.4109] }
    },
    {
      type: 'Feature',
      id: 234,
      properties: {
        sagoDate: '2018-07-23',
        sagoNo: '20180222',
        sido: '경기도',
        sigungu: '부천시',
        dong: '소사동',
        addr: ' 17-15',
        fullAdr: '경기도 부천시 원미구 소사동 17-15',
        SEC_CD: '41192106',
        sagoLon: '126.7924',
        sagoLat: '37.4849',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수맨홀 노후',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-07-24'
      },
      geometry: { type: 'Point', coordinates: [126.7924, 37.4849] }
    },
    {
      type: 'Feature',
      id: 235,
      properties: {
        sagoDate: '2018-07-23',
        sagoNo: '20180223',
        sido: '경기도',
        sigungu: '부천시',
        dong: '원미동',
        addr: ' 1-23번지',
        fullAdr: '경기도 부천시 원미구 원미동 1-23',
        SEC_CD: '41192101',
        sagoLon: '126.7931',
        sagoLat: '37.4992',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '집수받이 연결관 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-12-14'
      },
      geometry: { type: 'Point', coordinates: [126.7931, 37.4992] }
    },
    {
      type: 'Feature',
      id: 236,
      properties: {
        sagoDate: '2018-07-25',
        sagoNo: '20180224',
        sido: '대구광역시',
        sigungu: '서구',
        dong: '평리동',
        addr: '평리네거리',
        fullAdr: '대구광역시 서구 평리동 평리네거리',
        SEC_CD: '27170103',
        sagoLon: '128.555',
        sagoLat: '35.879',
        sinkWidth: '4',
        sinkExtend: '4',
        sinkDepth: '4',
        grdKind: '',
        sagoDetail: '상수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '3',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-07-25'
      },
      geometry: { type: 'Point', coordinates: [128.555, 35.879] }
    },
    {
      type: 'Feature',
      id: 237,
      properties: {
        sagoDate: '2018-07-26',
        sagoNo: '20180225',
        sido: '부산광역시',
        sigungu: '남구',
        dong: '',
        addr: '석포로 64번길 69-1일원',
        fullAdr: '부산광역시 남구 석포로64번길 69-1 (감만동·그랜드빌라)',
        SEC_CD: '26290111',
        sagoLon: '129.0872',
        sagoLat: '35.1209',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '잔류 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-07-28'
      },
      geometry: { type: 'Point', coordinates: [129.0872, 35.1209] }
    },
    {
      type: 'Feature',
      id: 238,
      properties: {
        sagoDate: '2018-07-26',
        sagoNo: '20180226',
        sido: '경기도',
        sigungu: '부천시',
        dong: '춘의동',
        addr: ' 155-1번지',
        fullAdr: '경기도 부천시 원미구 춘의동 155-1',
        SEC_CD: '41192103',
        sagoLon: '126.7917',
        sagoLat: '37.5042',
        sinkWidth: '5.4',
        sinkExtend: '6',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-12-14'
      },
      geometry: { type: 'Point', coordinates: [126.7917, 37.5042] }
    },
    {
      type: 'Feature',
      id: 239,
      properties: {
        sagoDate: '2018-07-11',
        sagoNo: '20180322',
        sido: '충청남도',
        sigungu: '공주시',
        dong: '탄천면 광명리 ',
        addr: '235-2번지 일원',
        fullAdr: '충청남도 공주시 탄천면 광명리  235-2번지 일원',
        SEC_CD: '44150320',
        sagoLon: '127.068',
        sagoLat: '36.3315',
        sinkWidth: '2',
        sinkExtend: '3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '초과하중으로 인한 흄관 및 도로 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '8140000',
        trFnDate: '2018-08-10'
      },
      geometry: { type: 'Point', coordinates: [127.068, 36.3315] }
    },
    {
      type: 'Feature',
      id: 240,
      properties: {
        sagoDate: '2018-07-06',
        sagoNo: '20180327',
        sido: '제주특별자치도',
        sigungu: '제주시',
        dong: '',
        addr: '지석8길 13-11',
        fullAdr: '제주특별자치도 제주시 지석8길 13-11 (삼양이동)',
        SEC_CD: '50110114',
        sagoLon: '126.5824',
        sagoLat: '33.5201',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '집수정 우수 유입 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-07-08'
      },
      geometry: { type: 'Point', coordinates: [126.5824, 33.5201] }
    },
    {
      type: 'Feature',
      id: 241,
      properties: {
        sagoDate: '2018-08-15',
        sagoNo: '20180013',
        sido: '서울특별시',
        sigungu: '강남구',
        dong: '논현동',
        addr: '280(강남대로 논현역 7번출구 앞)',
        fullAdr: '서울특별시 강남구 논현동 280',
        SEC_CD: '11680108',
        sagoLon: '127.0218',
        sagoLat: '37.5104',
        sinkWidth: '1.5',
        sinkExtend: '3',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '굴착복구 다짐 미흡으로 인한 장기침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-08-15'
      },
      geometry: { type: 'Point', coordinates: [127.0218, 37.5104] }
    },
    {
      type: 'Feature',
      id: 242,
      properties: {
        sagoDate: '2018-08-01',
        sagoNo: '20180227',
        sido: '강원특별자치도',
        sigungu: '삼척시',
        dong: '가곡면 풍곡리',
        addr: '삼방공원',
        fullAdr: '강원특별자치도 삼척시 가곡면 풍곡리 삼방공원',
        SEC_CD: '51230350',
        sagoLon: '129.155',
        sagoLat: '37.1006',
        sinkWidth: '2',
        sinkExtend: '45',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '석회암지대 동공발생 현상 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '5000000',
        trFnDate: '2018-09-20'
      },
      geometry: { type: 'Point', coordinates: [129.155, 37.1006] }
    },
    {
      type: 'Feature',
      id: 243,
      properties: {
        sagoDate: '2018-08-01',
        sagoNo: '20180228',
        sido: '경기도',
        sigungu: '부천시',
        dong: '원미동',
        addr: ' 33-1번지',
        fullAdr: '경기도 부천시 원미구 원미동 33-1',
        SEC_CD: '41192101',
        sagoLon: '126.7908',
        sagoLat: '37.4987',
        sinkWidth: '3',
        sinkExtend: '10',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-12-14'
      },
      geometry: { type: 'Point', coordinates: [126.7908, 37.4987] }
    },
    {
      type: 'Feature',
      id: 244,
      properties: {
        sagoDate: '2018-08-02',
        sagoNo: '20180229',
        sido: '부산광역시',
        sigungu: '북구',
        dong: '덕천동',
        addr: '814-1 덕천동 기비골 아파트 내 삼거리도로',
        fullAdr: '부산광역시 북구 덕천동 814-1',
        SEC_CD: '26320104',
        sagoLon: '129.0204',
        sagoLat: '35.2173',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '아파트 내 사유 하수관로 파손에 따른 토사유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-08-11'
      },
      geometry: { type: 'Point', coordinates: [129.0204, 35.2173] }
    },
    {
      type: 'Feature',
      id: 245,
      properties: {
        sagoDate: '2018-08-06',
        sagoNo: '20180230',
        sido: '부산광역시',
        sigungu: '부산진구',
        dong: '',
        addr: '서전로 35 · 서전로 41',
        fullAdr: '부산광역시 부산진구 서전로 35 (전포동)',
        SEC_CD: '26230102',
        sagoLon: '129.0635',
        sagoLat: '35.1579',
        sinkWidth: '2',
        sinkExtend: '1.2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail:
          '도시철도 공사 시 매몰된 흙막이 가시설인 토료판의 부식에 따른 공극 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-08-07'
      },
      geometry: { type: 'Point', coordinates: [129.0635, 35.1579] }
    },
    {
      type: 'Feature',
      id: 246,
      properties: {
        sagoDate: '2018-08-08',
        sagoNo: '20180231',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '명륜동',
        addr: '성원 상가 나동',
        fullAdr: '강원특별자치도 원주시 명륜동 성원 상가 나동',
        SEC_CD: '51130106',
        sagoLon: '127.941',
        sagoLat: '37.3416',
        sinkWidth: '2.8',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '충척층',
        sagoDetail: '상수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1560000',
        trFnDate: '2018-08-08'
      },
      geometry: { type: 'Point', coordinates: [127.941, 37.3416] }
    },
    {
      type: 'Feature',
      id: 247,
      properties: {
        sagoDate: '2018-08-09',
        sagoNo: '20180232',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '흥업면',
        addr: '현대아파트 상가',
        fullAdr: '강원특별자치도 원주시 흥업면 현대아파트 상가',
        SEC_CD: '51130370',
        sagoLon: '127.912',
        sagoLat: '37.2798',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '1.7',
        grdKind: '충척층',
        sagoDetail: '상수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1300000',
        trFnDate: '2018-08-09'
      },
      geometry: { type: 'Point', coordinates: [127.912, 37.2798] }
    },
    {
      type: 'Feature',
      id: 248,
      properties: {
        sagoDate: '2018-08-09',
        sagoNo: '20180233',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '안청동',
        addr: '740-1',
        fullAdr: '광주광역시 광산구 안청동 740-1',
        SEC_CD: '29200121',
        sagoLon: '126.8028',
        sagoLat: '35.2133',
        sinkWidth: '1.8',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '연결관 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-08-10'
      },
      geometry: { type: 'Point', coordinates: [126.8028, 35.2133] }
    },
    {
      type: 'Feature',
      id: 249,
      properties: {
        sagoDate: '2018-08-10',
        sagoNo: '20180235',
        sido: '인천광역시',
        sigungu: '부평구',
        dong: '',
        addr: '산곡사거리 금호아파트 인근',
        fullAdr: '인천광역시 부평구  산곡사거리 금호아파트 인근',
        SEC_CD: '28237104',
        sagoLon: '126.714',
        sagoLat: '37.5083',
        sinkWidth: '6',
        sinkExtend: '6',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '노후 상수관 누수 및 도로침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-08-11'
      },
      geometry: { type: 'Point', coordinates: [126.714, 37.5083] }
    },
    {
      type: 'Feature',
      id: 250,
      properties: {
        sagoDate: '2018-08-11',
        sagoNo: '20180236',
        sido: '경기도',
        sigungu: '구리시',
        dong: '교문동',
        addr: ' 223-2번지 (중앙예식장 사거리)',
        fullAdr: '경기도 구리시 교문동 223-2',
        SEC_CD: '41310104',
        sagoLon: '127.1361',
        sagoLat: '37.5994',
        sinkWidth: '0.3',
        sinkExtend: '0.3',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관로 연결불량으로 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-08-11'
      },
      geometry: { type: 'Point', coordinates: [127.1361, 37.5994] }
    },
    {
      type: 'Feature',
      id: 251,
      properties: {
        sagoDate: '2018-08-12',
        sagoNo: '20180237',
        sido: '광주광역시',
        sigungu: '북구',
        dong: '우산동',
        addr: '232-1(구두수선소 앞)',
        fullAdr: '광주광역시 북구 우산동 232-1(구두수선소 앞)',
        SEC_CD: '29170110',
        sagoLon: '126.922',
        sagoLat: '35.1721',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '하수암거박스 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '2000000',
        trFnDate: '2018-08-12'
      },
      geometry: { type: 'Point', coordinates: [126.922, 35.1721] }
    },
    {
      type: 'Feature',
      id: 252,
      properties: {
        sagoDate: '2018-08-12',
        sagoNo: '20180238',
        sido: '경기도',
        sigungu: '부천시',
        dong: '',
        addr: '까치울사거리역',
        fullAdr: '경기도 부천시 오정구 작동 248-1',
        SEC_CD: '41196103',
        sagoLon: '126.8112',
        sagoLat: '37.5063',
        sinkWidth: '4',
        sinkExtend: '4',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '하수맨홀 내부 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-09-10'
      },
      geometry: { type: 'Point', coordinates: [126.8112, 37.5063] }
    },
    {
      type: 'Feature',
      id: 253,
      properties: {
        sagoDate: '2018-08-13',
        sagoNo: '20180239',
        sido: '경상남도',
        sigungu: '진주시',
        dong: '',
        addr: '천전동 동부농협 오거리',
        fullAdr: '경상남도 진주시 진주대로 931 (강남동)',
        SEC_CD: '48170103',
        sagoLon: '128.0887',
        sagoLat: '35.1832',
        sinkWidth: '1',
        sinkExtend: '1.2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수관로 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-08-13'
      },
      geometry: { type: 'Point', coordinates: [128.0887, 35.1832] }
    },
    {
      type: 'Feature',
      id: 254,
      properties: {
        sagoDate: '2018-08-15',
        sagoNo: '20180240',
        sido: '경기도',
        sigungu: '부천시',
        dong: '심곡동',
        addr: '349-6',
        fullAdr: '경기도 부천시 원미구 심곡동 349-6',
        SEC_CD: '41192102',
        sagoLon: '126.7834',
        sagoLat: '37.4882',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 노후',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-08-15'
      },
      geometry: { type: 'Point', coordinates: [126.7834, 37.4882] }
    },
    {
      type: 'Feature',
      id: 255,
      properties: {
        sagoDate: '2018-08-16',
        sagoNo: '20180241',
        sido: '경기도',
        sigungu: '고양시 일산서구',
        dong: '일산동',
        addr: ' 2340',
        fullAdr: '경기도 고양시 일산서구 대화동 2340',
        SEC_CD: '41287104',
        sagoLon: '126.7602',
        sagoLat: '37.6872',
        sinkWidth: '2.5',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-08-20'
      },
      geometry: { type: 'Point', coordinates: [126.7602, 37.6872] }
    },
    {
      type: 'Feature',
      id: 256,
      properties: {
        sagoDate: '2018-08-17',
        sagoNo: '20180242',
        sido: '경기도',
        sigungu: '용인시 기흥구',
        dong: '마북동',
        addr: ' 139 현대필그린아파트 단지 내',
        fullAdr: '경기도 용인시 기흥구 마북동  139 현대필그린아파트 단지 내',
        SEC_CD: '41463113',
        sagoLon: '127.13',
        sagoLat: '37.3065',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '우수관 손상으로 누수 및 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-08-17'
      },
      geometry: { type: 'Point', coordinates: [127.13, 37.3065] }
    },
    {
      type: 'Feature',
      id: 257,
      properties: {
        sagoDate: '2018-08-18',
        sagoNo: '20180243',
        sido: '경상북도',
        sigungu: '상주시',
        dong: '도남동',
        addr: ' 산 2-1',
        fullAdr: '경상북도 상주시 도남동 산 2-1',
        SEC_CD: '47250125',
        sagoLon: '128.2489',
        sagoLat: '36.4551',
        sinkWidth: '3',
        sinkExtend: '4',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '가스관로 구간 침하',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-09-27'
      },
      geometry: { type: 'Point', coordinates: [128.2489, 36.4551] }
    },
    {
      type: 'Feature',
      id: 258,
      properties: {
        sagoDate: '2018-08-21',
        sagoNo: '20180244',
        sido: '부산광역시',
        sigungu: '연제구',
        dong: '',
        addr: '월드컵대로 21',
        fullAdr: '부산광역시 연제구 월드컵대로 21 (연산동)',
        SEC_CD: '26470102',
        sagoLon: '129.0855',
        sagoLat: '35.1765',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '400mm 상수관 일부 누수발생으로 도로침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-08-21'
      },
      geometry: { type: 'Point', coordinates: [129.0855, 35.1765] }
    },
    {
      type: 'Feature',
      id: 259,
      properties: {
        sagoDate: '2018-08-21',
        sagoNo: '20180245',
        sido: '전라남도',
        sigungu: '순천시',
        dong: '풍덕동',
        addr: '867-1번지',
        fullAdr: '전라남도 순천시 풍덕동 867-1',
        SEC_CD: '46150115',
        sagoLon: '127.5039',
        sagoLat: '34.943',
        sinkWidth: '65',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '다짐 불량에 따른 도로 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-09-18'
      },
      geometry: { type: 'Point', coordinates: [127.5039, 34.943] }
    },
    {
      type: 'Feature',
      id: 260,
      properties: {
        sagoDate: '2018-08-22',
        sagoNo: '20180246',
        sido: '경기도',
        sigungu: '부천시',
        dong: '원미동',
        addr: ' 106-17번지',
        fullAdr: '경기도 부천시 원미구 원미동 106-17',
        SEC_CD: '41192101',
        sagoLon: '126.7867',
        sagoLat: '37.4939',
        sinkWidth: '2',
        sinkExtend: '2.4',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-12-14'
      },
      geometry: { type: 'Point', coordinates: [126.7867, 37.4939] }
    },
    {
      type: 'Feature',
      id: 261,
      properties: {
        sagoDate: '2018-08-24',
        sagoNo: '20180247',
        sido: '전라남도',
        sigungu: '순천시',
        dong: '석현동',
        addr: '34-1번지 내 교차로',
        fullAdr: '전라남도 순천시 석현동 34-1',
        SEC_CD: '46150108',
        sagoLon: '127.4877',
        sagoLat: '34.9659',
        sinkWidth: '3',
        sinkExtend: '3',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail:
          '되메우기 시 충분한 다짐이 되지 않은 상태로 아스콘 포장을 진행하여· 우기 및 장마철로 인한 지하수 유입이 많아짐에 따라 토사가 유실된 것으로 판단',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-08-30'
      },
      geometry: { type: 'Point', coordinates: [127.4877, 34.9659] }
    },
    {
      type: 'Feature',
      id: 262,
      properties: {
        sagoDate: '2018-08-28',
        sagoNo: '20180248',
        sido: '충청북도',
        sigungu: '제천시',
        dong: '',
        addr: '내토로41길 8',
        fullAdr: '충청북도 제천시 내토로41안길 41 (화산동)',
        SEC_CD: '43150109',
        sagoLon: '128.2164',
        sagoLat: '37.1273',
        sinkWidth: '1.3',
        sinkExtend: '1.1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: 'CCTV지지대가 세워진 인도블럭 토사유실되어 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-08-28'
      },
      geometry: { type: 'Point', coordinates: [128.2164, 37.1273] }
    },
    {
      type: 'Feature',
      id: 263,
      properties: {
        sagoDate: '2018-08-29',
        sagoNo: '20180249',
        sido: '광주광역시',
        sigungu: '남구',
        dong: '봉선동',
        addr: '봉선중길 26-1',
        fullAdr: '광주광역시 남구 봉선중길 26-1 (봉선동)',
        SEC_CD: '29155115',
        sagoLon: '126.9138',
        sagoLat: '35.13',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '2.5',
        grdKind: '',
        sagoDetail: '맨홀접합부 관로파손으로 토사가 세굴되어 도로침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-08-29'
      },
      geometry: { type: 'Point', coordinates: [126.9138, 35.13] }
    },
    {
      type: 'Feature',
      id: 264,
      properties: {
        sagoDate: '2018-08-29',
        sagoNo: '20180250',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '수기동',
        addr: '81번지 주변',
        fullAdr: '광주광역시 동구 수기동 81',
        SEC_CD: '29110104',
        sagoLon: '126.9104',
        sagoLat: '35.1497',
        sinkWidth: '0',
        sinkExtend: '0',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수도 연결관 접합부 파손으로 인한 토사 세굴',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-08-30'
      },
      geometry: { type: 'Point', coordinates: [126.9104, 35.1497] }
    },
    {
      type: 'Feature',
      id: 265,
      properties: {
        sagoDate: '2018-08-29',
        sagoNo: '20180251',
        sido: '경기도',
        sigungu: '수원시 팔달구',
        dong: '',
        addr: '수원시청역 사거리 교차로 내',
        fullAdr: '경기도 수원시 팔달구 효원로 271-2 (인계동)',
        SEC_CD: '41115141',
        sagoLon: '127.0314',
        sagoLat: '37.262',
        sinkWidth: '2',
        sinkExtend: '4',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail:
          '지하철 공사 시 설치한 가시설을 제거하지 않고 매몰하여·폐자재 사이에 빈 공간이 생겨 상부 지반의 토사가 장시간에·걸쳐 그 빈공간으로 흘러 내리면서 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-08-30'
      },
      geometry: { type: 'Point', coordinates: [127.0314, 37.262] }
    },
    {
      type: 'Feature',
      id: 266,
      properties: {
        sagoDate: '2018-08-31',
        sagoNo: '20180252',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '귀래면 운남리',
        addr: '경로당 앞',
        fullAdr: '강원특별자치도 원주시 귀래면 운남리 경로당 앞',
        SEC_CD: '51130360',
        sagoLon: '127.922',
        sagoLat: '37.19',
        sinkWidth: '3',
        sinkExtend: '3.2',
        sinkDepth: '1',
        grdKind: '화강암류',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1240000',
        trFnDate: '2018-08-31'
      },
      geometry: { type: 'Point', coordinates: [127.922, 37.19] }
    },
    {
      type: 'Feature',
      id: 267,
      properties: {
        sagoDate: '2018-09-04',
        sagoNo: '20180014',
        sido: '서울특별시',
        sigungu: '종로구',
        dong: '연건동',
        addr: '28-2(대학로 103)',
        fullAdr: '서울특별시 종로구 연건동 28-2',
        SEC_CD: '11110166',
        sagoLon: '126.9992',
        sagoLat: '37.5808',
        sinkWidth: '1.1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '상수도관 및 통신관 매설 시 하수관로 파손 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-09-05'
      },
      geometry: { type: 'Point', coordinates: [126.9992, 37.5808] }
    },
    {
      type: 'Feature',
      id: 268,
      properties: {
        sagoDate: '2018-09-04',
        sagoNo: '20180015',
        sido: '서울특별시',
        sigungu: '강북구',
        dong: '수유동',
        addr: '313-49(삼양로 119길 1)',
        fullAdr: '서울특별시 강북구 수유동 313-49',
        SEC_CD: '11305103',
        sagoLon: '127.0157',
        sagoLat: '37.644',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail:
          '주변 사각형거의 바닥 세굴 및 파손· 벽체 및 슬래브 노후화 심화',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-09-05'
      },
      geometry: { type: 'Point', coordinates: [127.0157, 37.644] }
    },
    {
      type: 'Feature',
      id: 269,
      properties: {
        sagoDate: '2018-09-03',
        sagoNo: '20180253',
        sido: '충청북도',
        sigungu: '음성군',
        dong: '삼성면 용성리',
        addr: '산128-1번지',
        fullAdr: '충청북도 음성군 삼성면 용성리 산 128-1',
        SEC_CD: '43770350',
        sagoLon: '127.4756',
        sagoLat: '37.0015',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '집중호우(9.3.)로 인한 유수로 싱크홀 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-09-30'
      },
      geometry: { type: 'Point', coordinates: [127.4756, 37.0015] }
    },
    {
      type: 'Feature',
      id: 270,
      properties: {
        sagoDate: '2018-09-03',
        sagoNo: '20180254',
        sido: '충청북도',
        sigungu: '단양군',
        dong: '단양읍 심곡리',
        addr: '147',
        fullAdr: '충청북도 단양군 단양읍 심곡리 147',
        SEC_CD: '43800340',
        sagoLon: '128.387',
        sagoLat: '37.0552',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail: '기타 원인으로 인한 토사유실 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1000000',
        trFnDate: '2018-11-30'
      },
      geometry: { type: 'Point', coordinates: [128.387, 37.0552] }
    },
    {
      type: 'Feature',
      id: 271,
      properties: {
        sagoDate: '2018-09-03',
        sagoNo: '20180255',
        sido: '경기도',
        sigungu: '고양시 일산서구',
        dong: '주엽동',
        addr: ' 151',
        fullAdr: '경기도 고양시 일산서구 주엽동 151',
        SEC_CD: '41287102',
        sagoLon: '126.7644',
        sagoLat: '37.6742',
        sinkWidth: '3',
        sinkExtend: '2',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-09-28'
      },
      geometry: { type: 'Point', coordinates: [126.7644, 37.6742] }
    },
    {
      type: 'Feature',
      id: 272,
      properties: {
        sagoDate: '2018-09-03',
        sagoNo: '20180256',
        sido: '경기도',
        sigungu: '부천시',
        dong: '심곡동',
        addr: '349-3',
        fullAdr: '경기도 부천시 원미구 심곡동 349-3',
        SEC_CD: '41192102',
        sagoLon: '126.7833',
        sagoLat: '37.4887',
        sinkWidth: '3',
        sinkExtend: '5',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 노후',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-09-03'
      },
      geometry: { type: 'Point', coordinates: [126.7833, 37.4887] }
    },
    {
      type: 'Feature',
      id: 273,
      properties: {
        sagoDate: '2018-09-03',
        sagoNo: '20180257',
        sido: '경기도',
        sigungu: '부천시',
        dong: '도당동',
        addr: ' 260-5번지',
        fullAdr: '경기도 부천시 원미구 도당동 260-5',
        SEC_CD: '41192104',
        sagoLon: '126.7843',
        sagoLat: '37.5084',
        sinkWidth: '3.5',
        sinkExtend: '10',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-12-14'
      },
      geometry: { type: 'Point', coordinates: [126.7843, 37.5084] }
    },
    {
      type: 'Feature',
      id: 274,
      properties: {
        sagoDate: '2018-09-04',
        sagoNo: '20180258',
        sido: '충청북도',
        sigungu: '제천시',
        dong: '',
        addr: '용두천로 98 종로약국 앞',
        fullAdr: '충청북도 제천시 용두천로 98 (남천동)',
        SEC_CD: '43150104',
        sagoLon: '128.2122',
        sagoLat: '37.1361',
        sinkWidth: '1.1',
        sinkExtend: '1.3',
        sinkDepth: '0.6',
        grdKind: '',
        sagoDetail: '상수관 누수로 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-09-07'
      },
      geometry: { type: 'Point', coordinates: [128.2122, 37.1361] }
    },
    {
      type: 'Feature',
      id: 275,
      properties: {
        sagoDate: '2018-09-07',
        sagoNo: '20180259',
        sido: '충청북도',
        sigungu: '제천시',
        dong: '장락동',
        addr: '843번지 앞',
        fullAdr: '충청북도 제천시 장락동 843',
        SEC_CD: '43150116',
        sagoLon: '128.2199',
        sagoLat: '37.1516',
        sinkWidth: '0.2',
        sinkExtend: '0.2',
        sinkDepth: '2.8',
        grdKind: '',
        sagoDetail: '보도블럭 침하(원인미상)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-09-07'
      },
      geometry: { type: 'Point', coordinates: [128.2199, 37.1516] }
    },
    {
      type: 'Feature',
      id: 276,
      properties: {
        sagoDate: '2018-09-07',
        sagoNo: '20180260',
        sido: '경기도',
        sigungu: '부천시',
        dong: '역곡동',
        addr: ' 85-3번지',
        fullAdr: '경기도 부천시 원미구 역곡동 85-3',
        SEC_CD: '41192107',
        sagoLon: '126.811',
        sagoLat: '37.4877',
        sinkWidth: '5',
        sinkExtend: '13',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-12-14'
      },
      geometry: { type: 'Point', coordinates: [126.811, 37.4877] }
    },
    {
      type: 'Feature',
      id: 277,
      properties: {
        sagoDate: '2018-09-08',
        sagoNo: '20180261',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '월평동',
        addr: '월평동로 다모아아파트 102동 앞',
        fullAdr: '대전광역시 서구 월평동 월평동로 다모아아파트 102동 앞',
        SEC_CD: '30170113',
        sagoLon: '127.368',
        sagoLat: '36.3612',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '오수관 상단부 파손으로 인한 장기간 토사유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '20000000',
        trFnDate: '2018-09-10'
      },
      geometry: { type: 'Point', coordinates: [127.368, 36.3612] }
    },
    {
      type: 'Feature',
      id: 278,
      properties: {
        sagoDate: '2018-09-10',
        sagoNo: '20180262',
        sido: '경상남도',
        sigungu: '창원시 의창구',
        dong: '신월동',
        addr: '90(신월주공아파트 앞)',
        fullAdr: '경상남도 창원시 의창구 신월동 90(신월주공아파트 앞)',
        SEC_CD: '48123127',
        sagoLon: '128.689',
        sagoLat: '35.2224',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '노후 하수관 손상으로 누수 및 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '2',
        trStatus: '복구완료',
        trAmt: '14733000',
        trFnDate: '2018-09-10'
      },
      geometry: { type: 'Point', coordinates: [128.689, 35.2224] }
    },
    {
      type: 'Feature',
      id: 279,
      properties: {
        sagoDate: '2018-09-10',
        sagoNo: '20180263',
        sido: '경기도',
        sigungu: '부천시',
        dong: '춘의동',
        addr: ' 126-36번지',
        fullAdr: '경기도 부천시 원미구 춘의동 126-36',
        SEC_CD: '41192103',
        sagoLon: '126.7828',
        sagoLat: '37.5054',
        sinkWidth: '2.8',
        sinkExtend: '18',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-12-14'
      },
      geometry: { type: 'Point', coordinates: [126.7828, 37.5054] }
    },
    {
      type: 'Feature',
      id: 280,
      properties: {
        sagoDate: '2018-09-11',
        sagoNo: '20180264',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '명륜동',
        addr: '향교길 9',
        fullAdr: '강원특별자치도 원주시 명륜동 향교길 9',
        SEC_CD: '51130106',
        sagoLon: '127.949',
        sagoLat: '37.337',
        sinkWidth: '1.2',
        sinkExtend: '1.5',
        sinkDepth: '1.6',
        grdKind: '충척층',
        sagoDetail: '상수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1120000',
        trFnDate: '2018-09-11'
      },
      geometry: { type: 'Point', coordinates: [127.949, 37.337] }
    },
    {
      type: 'Feature',
      id: 281,
      properties: {
        sagoDate: '2018-09-11',
        sagoNo: '20180265',
        sido: '경기도',
        sigungu: '부천시',
        dong: '원미동',
        addr: ' 177-149번지',
        fullAdr: '경기도 부천시 원미구 원미동 177-149',
        SEC_CD: '41192101',
        sagoLon: '126.7919',
        sagoLat: '37.4926',
        sinkWidth: '4',
        sinkExtend: '16',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-12-14'
      },
      geometry: { type: 'Point', coordinates: [126.7919, 37.4926] }
    },
    {
      type: 'Feature',
      id: 282,
      properties: {
        sagoDate: '2018-09-12',
        sagoNo: '20180266',
        sido: '경기도',
        sigungu: '부천시',
        dong: '심곡동',
        addr: '399-11',
        fullAdr: '경기도 부천시 원미구 심곡동 399-11',
        SEC_CD: '41192102',
        sagoLon: '126.78',
        sagoLat: '37.4865',
        sinkWidth: '4',
        sinkExtend: '10',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 노후',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-09-12'
      },
      geometry: { type: 'Point', coordinates: [126.78, 37.4865] }
    },
    {
      type: 'Feature',
      id: 283,
      properties: {
        sagoDate: '2018-09-12',
        sagoNo: '20180267',
        sido: '경기도',
        sigungu: '부천시',
        dong: '심곡동',
        addr: '426-4',
        fullAdr: '경기도 부천시 원미구 심곡동 426-4',
        SEC_CD: '41192102',
        sagoLon: '126.7803',
        sagoLat: '37.4904',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 노후',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-09-13'
      },
      geometry: { type: 'Point', coordinates: [126.7803, 37.4904] }
    },
    {
      type: 'Feature',
      id: 284,
      properties: {
        sagoDate: '2018-09-12',
        sagoNo: '20180268',
        sido: '경기도',
        sigungu: '부천시',
        dong: '원미동',
        addr: ' 75-5번지',
        fullAdr: '경기도 부천시 원미구 원미동 71',
        SEC_CD: '41192101',
        sagoLon: '126.787',
        sagoLat: '37.4965',
        sinkWidth: '3',
        sinkExtend: '12',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '원인미상의 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-12-14'
      },
      geometry: { type: 'Point', coordinates: [126.787, 37.4965] }
    },
    {
      type: 'Feature',
      id: 285,
      properties: {
        sagoDate: '2018-09-12',
        sagoNo: '20180269',
        sido: '경기도',
        sigungu: '부천시',
        dong: '춘의동',
        addr: ' 200-1번지',
        fullAdr: '경기도 부천시 원미구 춘의동 200-1',
        SEC_CD: '41192103',
        sagoLon: '126.7894',
        sagoLat: '37.5015',
        sinkWidth: '2.4',
        sinkExtend: '70',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-12-14'
      },
      geometry: { type: 'Point', coordinates: [126.7894, 37.5015] }
    },
    {
      type: 'Feature',
      id: 286,
      properties: {
        sagoDate: '2018-09-13',
        sagoNo: '20180270',
        sido: '울산광역시',
        sigungu: '남구',
        dong: '선암동',
        addr: '308-5번지 일원 (사평로)명동삼거리에서 공단 2정문 방향 약 150m 지점',
        fullAdr:
          '울산광역시 남구 선암동 308-5번지 일원 (사평로)명동삼거리에서 공단 2정문 방향 약 150m 지점',
        SEC_CD: '31140109',
        sagoLon: '129.335',
        sagoLat: '35.5079',
        sinkWidth: '3',
        sinkExtend: '5',
        sinkDepth: '3',
        grdKind: '기타',
        sagoDetail: '산· 알카리로 오염된 지하수로 인해 스팀배관·손상사고 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-11-25'
      },
      geometry: { type: 'Point', coordinates: [129.335, 35.5079] }
    },
    {
      type: 'Feature',
      id: 287,
      properties: {
        sagoDate: '2018-09-14',
        sagoNo: '20180271',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '우산동',
        addr: '1582-4',
        fullAdr: '광주광역시 광산구 우산동 1582-4',
        SEC_CD: '29200109',
        sagoLon: '126.8079',
        sagoLat: '35.1624',
        sinkWidth: '0.3',
        sinkExtend: '0.7',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관로 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-09-18'
      },
      geometry: { type: 'Point', coordinates: [126.8079, 35.1624] }
    },
    {
      type: 'Feature',
      id: 288,
      properties: {
        sagoDate: '2018-09-14',
        sagoNo: '20180272',
        sido: '울산광역시',
        sigungu: '울주군',
        dong: '온산읍 원산리',
        addr: '1241(우봉 이진로· S-OIL부지 앞)',
        fullAdr:
          '울산광역시 울주군 온산읍 원산리 1241(우봉 이진로· S-OIL부지 앞)',
        SEC_CD: '31710250',
        sagoLon: '129.364',
        sagoLat: '35.4187',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail: '연약지반과 파랑 및 조력에 의한 매립토의 흡출로 공동발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '2200000000',
        trFnDate: '2020-02-25'
      },
      geometry: { type: 'Point', coordinates: [129.364, 35.4187] }
    },
    {
      type: 'Feature',
      id: 289,
      properties: {
        sagoDate: '2018-09-20',
        sagoNo: '20180273',
        sido: '경기도',
        sigungu: '부천시',
        dong: '원미동',
        addr: ' 50-4번지',
        fullAdr: '경기도 부천시 원미구 원미동 50-4',
        SEC_CD: '41192101',
        sagoLon: '126.7921',
        sagoLat: '37.4967',
        sinkWidth: '1.5',
        sinkExtend: '27',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-12-14'
      },
      geometry: { type: 'Point', coordinates: [126.7921, 37.4967] }
    },
    {
      type: 'Feature',
      id: 290,
      properties: {
        sagoDate: '2018-09-20',
        sagoNo: '20180274',
        sido: '경기도',
        sigungu: '부천시',
        dong: '중동',
        addr: ' 628-10번지',
        fullAdr: '경기도 부천시 원미구 중동 628-10',
        SEC_CD: '41192108',
        sagoLon: '126.7647',
        sagoLat: '37.4963',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '개인하수도 연결관 손상으로 인한 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-09-21'
      },
      geometry: { type: 'Point', coordinates: [126.7647, 37.4963] }
    },
    {
      type: 'Feature',
      id: 291,
      properties: {
        sagoDate: '2018-09-21',
        sagoNo: '20180275',
        sido: '경기도',
        sigungu: '여주시',
        dong: '강천면',
        addr: ' 굴암1길 33',
        fullAdr: '경기도 여주시 강천면 굴암1길 33',
        SEC_CD: '41670370',
        sagoLon: '127.7005',
        sagoLat: '37.2384',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '급수공사 후 도로침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-09-23'
      },
      geometry: { type: 'Point', coordinates: [127.7005, 37.2384] }
    },
    {
      type: 'Feature',
      id: 292,
      properties: {
        sagoDate: '2018-09-24',
        sagoNo: '20180276',
        sido: '경기도',
        sigungu: '부천시',
        dong: '원미동',
        addr: ' 83-20번지',
        fullAdr: '경기도 부천시 원미구 원미동 83-20',
        SEC_CD: '41192101',
        sagoLon: '126.7888',
        sagoLat: '37.4954',
        sinkWidth: '1.5',
        sinkExtend: '20',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-12-14'
      },
      geometry: { type: 'Point', coordinates: [126.7888, 37.4954] }
    },
    {
      type: 'Feature',
      id: 293,
      properties: {
        sagoDate: '2018-09-26',
        sagoNo: '20180277',
        sido: '경기도',
        sigungu: '부천시',
        dong: '역곡동',
        addr: ' 80-3번지',
        fullAdr: '경기도 부천시 원미구 역곡동 80-3',
        SEC_CD: '41192107',
        sagoLon: '126.811',
        sagoLat: '37.4855',
        sinkWidth: '2.5',
        sinkExtend: '19',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-12-14'
      },
      geometry: { type: 'Point', coordinates: [126.811, 37.4855] }
    },
    {
      type: 'Feature',
      id: 294,
      properties: {
        sagoDate: '2018-09-28',
        sagoNo: '20180279',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '지정면',
        addr: '간현4리 경장동',
        fullAdr: '강원특별자치도 원주시 지정면 간현4리 경장동',
        SEC_CD: '51130330',
        sagoLon: '127.839',
        sagoLat: '37.3811',
        sinkWidth: '1.3',
        sinkExtend: '2',
        sinkDepth: '1.7',
        grdKind: '충척층',
        sagoDetail: '상수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1230000',
        trFnDate: '2018-09-28'
      },
      geometry: { type: 'Point', coordinates: [127.839, 37.3811] }
    },
    {
      type: 'Feature',
      id: 295,
      properties: {
        sagoDate: '2018-10-01',
        sagoNo: '20180280',
        sido: '전라남도',
        sigungu: '장성군',
        dong: '황룡면 와룡리',
        addr: '35-21',
        fullAdr: '전라남도 장성군 황룡면 와룡리 35-21',
        SEC_CD: '46880360',
        sagoLon: '126.7669',
        sagoLat: '35.2715',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '원인미상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-10-01'
      },
      geometry: { type: 'Point', coordinates: [126.7669, 35.2715] }
    },
    {
      type: 'Feature',
      id: 296,
      properties: {
        sagoDate: '2018-10-04',
        sagoNo: '20180281',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '지정면',
        addr: '간현리 경장길19-11',
        fullAdr: '강원특별자치도 원주시 지정면 간현리 경장길19-11',
        SEC_CD: '51130330',
        sagoLon: '127.846',
        sagoLat: '37.3434',
        sinkWidth: '1',
        sinkExtend: '2.1',
        sinkDepth: '1.7',
        grdKind: '충척층',
        sagoDetail: '상수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1170000',
        trFnDate: '2018-10-04'
      },
      geometry: { type: 'Point', coordinates: [127.846, 37.3434] }
    },
    {
      type: 'Feature',
      id: 297,
      properties: {
        sagoDate: '2018-10-05',
        sagoNo: '20180282',
        sido: '세종특별자치시',
        sigungu: '',
        dong: '보람동',
        addr: '629-2(금남프라자)',
        fullAdr: '세종특별자치시 보람동 745',
        SEC_CD: '36110103',
        sagoLon: '127.29',
        sagoLat: '36.4782',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '우수관로 파손 및 누수에 의한 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-04-03'
      },
      geometry: { type: 'Point', coordinates: [127.29, 36.4782] }
    },
    {
      type: 'Feature',
      id: 298,
      properties: {
        sagoDate: '2018-10-07',
        sagoNo: '20180283',
        sido: '울산광역시',
        sigungu: '동구',
        dong: '서부동',
        addr: ' 178번지(명덕마을 앞 교차로 인근)',
        fullAdr: '울산광역시 동구 서부동  178번지(명덕마을 앞 교차로 인근)',
        SEC_CD: '31170108',
        sagoLon: '129.433',
        sagoLat: '35.5258',
        sinkWidth: '5',
        sinkExtend: '5',
        sinkDepth: '4',
        grdKind: '기타',
        sagoDetail: '하수관로파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '38937000',
        trFnDate: '2018-11-05'
      },
      geometry: { type: 'Point', coordinates: [129.433, 35.5258] }
    },
    {
      type: 'Feature',
      id: 299,
      properties: {
        sagoDate: '2018-10-08',
        sagoNo: '20180284',
        sido: '전북특별자치도',
        sigungu: '고창군',
        dong: '성내면',
        addr: '내토마을회관 앞',
        fullAdr: '전북특별자치도 고창군 성내면 내토1길 48',
        SEC_CD: '52790410',
        sagoLon: '126.7523',
        sagoLat: '35.538',
        sinkWidth: '0.5',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '기타',
        sagoDetail: '하수관거 다짐 불량으로 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-10-08'
      },
      geometry: { type: 'Point', coordinates: [126.7523, 35.538] }
    },
    {
      type: 'Feature',
      id: 300,
      properties: {
        sagoDate: '2018-10-09',
        sagoNo: '20180285',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '태장동',
        addr: '일야미 어린이집 앞',
        fullAdr: '강원특별자치도 원주시 태장동 일야미 어린이집 앞',
        SEC_CD: '51130112',
        sagoLon: '127.951',
        sagoLat: '37.3737',
        sinkWidth: '5.8',
        sinkExtend: '7.3',
        sinkDepth: '2.3',
        grdKind: '화강암류',
        sagoDetail: '상수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1800000',
        trFnDate: '2018-10-09'
      },
      geometry: { type: 'Point', coordinates: [127.951, 37.3737] }
    },
    {
      type: 'Feature',
      id: 301,
      properties: {
        sagoDate: '2018-10-10',
        sagoNo: '20180286',
        sido: '경기도',
        sigungu: '수원시 팔달구',
        dong: '',
        addr: '수원시청역 사거리 교차로 내',
        fullAdr: '경기도 수원시 팔달구 효원로 271-2 (인계동)',
        SEC_CD: '41115141',
        sagoLon: '127.0314',
        sagoLat: '37.262',
        sinkWidth: '2',
        sinkExtend: '1.5',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail:
          '지하철 공사 시 설치한 가시설을 제거하지 않고 매몰하여·폐자재 사이에 빈 공간이 생겨 상부 지반의 토사가 장시간에·걸쳐 그 빈공간으로 흘러 내리면서 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-10-11'
      },
      geometry: { type: 'Point', coordinates: [127.0314, 37.262] }
    },
    {
      type: 'Feature',
      id: 302,
      properties: {
        sagoDate: '2018-10-12',
        sagoNo: '20180287',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '개운동',
        addr: '원흥2차APT',
        fullAdr: '강원특별자치도 원주시 개운동 원흥2차APT',
        SEC_CD: '51130108',
        sagoLon: '127.942',
        sagoLat: '37.3437',
        sinkWidth: '2.2',
        sinkExtend: '2',
        sinkDepth: '2',
        grdKind: '충척층',
        sagoDetail: '상수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1230000',
        trFnDate: '2018-10-12'
      },
      geometry: { type: 'Point', coordinates: [127.942, 37.3437] }
    },
    {
      type: 'Feature',
      id: 303,
      properties: {
        sagoDate: '2018-10-14',
        sagoNo: '20180288',
        sido: '경기도',
        sigungu: '부천시',
        dong: '춘의동',
        addr: ' 산55-4번지',
        fullAdr: '경기도 부천시 원미구 춘의동 산 55-4',
        SEC_CD: '41192103',
        sagoLon: '126.809',
        sagoLat: '37.5008',
        sinkWidth: '6',
        sinkExtend: '13',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-12-14'
      },
      geometry: { type: 'Point', coordinates: [126.809, 37.5008] }
    },
    {
      type: 'Feature',
      id: 304,
      properties: {
        sagoDate: '2018-10-15',
        sagoNo: '20180289',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '우산동',
        addr: '안전공업사앞',
        fullAdr: '강원특별자치도 원주시 우산동 안전공업사앞',
        SEC_CD: '51130111',
        sagoLon: '127.942',
        sagoLat: '37.3791',
        sinkWidth: '4',
        sinkExtend: '5.5',
        sinkDepth: '2.4',
        grdKind: '충척층',
        sagoDetail: '상수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1670000',
        trFnDate: '2018-10-15'
      },
      geometry: { type: 'Point', coordinates: [127.942, 37.3791] }
    },
    {
      type: 'Feature',
      id: 305,
      properties: {
        sagoDate: '2018-10-15',
        sagoNo: '20180290',
        sido: '경기도',
        sigungu: '광주시',
        dong: '역동',
        addr: ' 27-15',
        fullAdr: '경기도 광주시 역동 27-15',
        SEC_CD: '41610112',
        sagoLon: '127.2599',
        sagoLat: '37.4059',
        sinkWidth: '0.5',
        sinkExtend: '60',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-11-05'
      },
      geometry: { type: 'Point', coordinates: [127.2599, 37.4059] }
    },
    {
      type: 'Feature',
      id: 306,
      properties: {
        sagoDate: '2018-10-19',
        sagoNo: '20180291',
        sido: '경기도',
        sigungu: '부천시',
        dong: '도당동',
        addr: ' 136-2번지',
        fullAdr: '경기도 부천시 원미구 도당동 136-2',
        SEC_CD: '41192104',
        sagoLon: '126.7941',
        sagoLat: '37.5219',
        sinkWidth: '6',
        sinkExtend: '3',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-12-14'
      },
      geometry: { type: 'Point', coordinates: [126.7941, 37.5219] }
    },
    {
      type: 'Feature',
      id: 307,
      properties: {
        sagoDate: '2018-10-21',
        sagoNo: '20180292',
        sido: '경기도',
        sigungu: '부천시',
        dong: '원미동',
        addr: '162-34번지',
        fullAdr: '경기도 부천시 원미구 원미동 162-34',
        SEC_CD: '41192101',
        sagoLon: '126.794',
        sagoLat: '37.4932',
        sinkWidth: '2',
        sinkExtend: '4',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-12-14'
      },
      geometry: { type: 'Point', coordinates: [126.794, 37.4932] }
    },
    {
      type: 'Feature',
      id: 308,
      properties: {
        sagoDate: '2018-10-24',
        sagoNo: '20180293',
        sido: '강원특별자치도',
        sigungu: '속초시',
        dong: '조양동',
        addr: '1441-2',
        fullAdr: '강원특별자치도 속초시 조양동 1441-2',
        SEC_CD: '51210108',
        sagoLon: '128.5996',
        sagoLat: '38.1913',
        sinkWidth: '3',
        sinkExtend: '6.5',
        sinkDepth: '0.15',
        grdKind: '충척층',
        sagoDetail: '인접공사장 차수벽 부실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-11-30'
      },
      geometry: { type: 'Point', coordinates: [128.5996, 38.1913] }
    },
    {
      type: 'Feature',
      id: 309,
      properties: {
        sagoDate: '2018-10-24',
        sagoNo: '20180294',
        sido: '강원특별자치도',
        sigungu: '속초시',
        dong: '조양동',
        addr: '1431-1 비치하우스 대지경계',
        fullAdr: '강원특별자치도 속초시 조양동 1431-1',
        SEC_CD: '51210108',
        sagoLon: '128.6003',
        sagoLat: '38.191',
        sinkWidth: '3',
        sinkExtend: '2.5',
        sinkDepth: '2.5',
        grdKind: '충척층',
        sagoDetail: '인접공사장 차수벽 부실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-11-30'
      },
      geometry: { type: 'Point', coordinates: [128.6003, 38.191] }
    },
    {
      type: 'Feature',
      id: 310,
      properties: {
        sagoDate: '2018-11-01',
        sagoNo: '20180295',
        sido: '경상북도',
        sigungu: '경주시',
        dong: '내남면 덕천리',
        addr: ' 894-1번지 일원·904지방도',
        fullAdr: '경상북도 경주시 내남면 덕천리  894-1번지 일원·904지방도',
        SEC_CD: '47130330',
        sagoLon: '129.176',
        sagoLat: '35.7455',
        sinkWidth: '1.5',
        sinkExtend: '2000',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '상하수관공사 부실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-08-28'
      },
      geometry: { type: 'Point', coordinates: [129.176, 35.7455] }
    },
    {
      type: 'Feature',
      id: 311,
      properties: {
        sagoDate: '2018-11-01',
        sagoNo: '20180296',
        sido: '경상북도',
        sigungu: '경주시',
        dong: '조양동',
        addr: '259-2 일원·조양동 탑리마을',
        fullAdr: '경상북도 경주시 조양동 259-2 일원·조양동 탑리마을',
        SEC_CD: '47130128',
        sagoLon: '129.275',
        sagoLat: '35.7777',
        sinkWidth: '4',
        sinkExtend: '600',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '상하수관공사 부실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-08-28'
      },
      geometry: { type: 'Point', coordinates: [129.275, 35.7777] }
    },
    {
      type: 'Feature',
      id: 312,
      properties: {
        sagoDate: '2018-11-06',
        sagoNo: '20180297',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '문막읍 문막리',
        addr: '191 순복음교회',
        fullAdr: '강원특별자치도 원주시 문막읍 문막리 191 순복음교회',
        SEC_CD: '51130250',
        sagoLon: '127.823',
        sagoLat: '37.3039',
        sinkWidth: '2.2',
        sinkExtend: '3',
        sinkDepth: '2',
        grdKind: '편암류',
        sagoDetail: '굴착공사 부실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1400000',
        trFnDate: '2018-11-06'
      },
      geometry: { type: 'Point', coordinates: [127.823, 37.3039] }
    },
    {
      type: 'Feature',
      id: 313,
      properties: {
        sagoDate: '2018-11-06',
        sagoNo: '20180298',
        sido: '충청남도',
        sigungu: '아산시',
        dong: '도고면 금산리 ',
        addr: '서해안휴게소(금산주유소) 입구 -국도21호',
        fullAdr:
          '충청남도 아산시 도고면 금산리  서해안휴게소(금산주유소) 입구 -국도21호',
        SEC_CD: '44200400',
        sagoLon: '126.867',
        sagoLat: '36.7467',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '0.6',
        grdKind: '',
        sagoDetail: '국도21호 노후 종배수관 접합부 손상으로 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-11-14'
      },
      geometry: { type: 'Point', coordinates: [126.867, 36.7467] }
    },
    {
      type: 'Feature',
      id: 314,
      properties: {
        sagoDate: '2018-11-08',
        sagoNo: '20180299',
        sido: '부산광역시',
        sigungu: '사하구',
        dong: '',
        addr: '다대로 277번길 85 내 도로',
        fullAdr: '부산광역시 사하구 다대로277번길 85 (장림동·신세대지큐빌)',
        SEC_CD: '26380105',
        sagoLon: '128.9808',
        sagoLat: '35.0766',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '원인불명의 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-11-10'
      },
      geometry: { type: 'Point', coordinates: [128.9808, 35.0766] }
    },
    {
      type: 'Feature',
      id: 315,
      properties: {
        sagoDate: '2018-11-26',
        sagoNo: '20180301',
        sido: '경기도',
        sigungu: '여주시',
        dong: '흥천면 외사리',
        addr: ' 280-3 일원',
        fullAdr: '경기도 여주시 흥천면 외사리 280-3',
        SEC_CD: '41670320',
        sagoLon: '127.494',
        sagoLat: '37.3452',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '관로공사 후 포장미복구로 인한 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-11-27'
      },
      geometry: { type: 'Point', coordinates: [127.494, 37.3452] }
    },
    {
      type: 'Feature',
      id: 316,
      properties: {
        sagoDate: '2018-11-26',
        sagoNo: '20180302',
        sido: '경기도',
        sigungu: '여주시',
        dong: '산북면',
        addr: ' 산북파출소 앞',
        fullAdr: '경기도 여주시 산북면 금품1로 2',
        SEC_CD: '41670380',
        sagoLon: '127.4436',
        sagoLat: '37.4047',
        sinkWidth: '1',
        sinkExtend: '3',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '급수공사 후 포장미복구로 인한 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '2',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-11-28'
      },
      geometry: { type: 'Point', coordinates: [127.4436, 37.4047] }
    },
    {
      type: 'Feature',
      id: 317,
      properties: {
        sagoDate: '2018-11-27',
        sagoNo: '20180303',
        sido: '충청북도',
        sigungu: '청주시 흥덕구',
        dong: '운천동',
        addr: '722번지 앞(산정공원)',
        fullAdr: '충청북도 청주시 흥덕구 운천동 722',
        SEC_CD: '43113104',
        sagoLon: '127.473',
        sagoLat: '36.6487',
        sinkWidth: '0.6',
        sinkExtend: '0.6',
        sinkDepth: '0.6',
        grdKind: '',
        sagoDetail: '노후하수관로에 따른 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-11-28'
      },
      geometry: { type: 'Point', coordinates: [127.473, 36.6487] }
    },
    {
      type: 'Feature',
      id: 318,
      properties: {
        sagoDate: '2018-11-29',
        sagoNo: '20180304',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '판부면',
        addr: ' 남송 침례교회앞',
        fullAdr: '강원특별자치도 원주시 판부면  남송 침례교회앞',
        SEC_CD: '51130380',
        sagoLon: '127.937',
        sagoLat: '37.3183',
        sinkWidth: '5.4',
        sinkExtend: '6',
        sinkDepth: '2',
        grdKind: '편암류',
        sagoDetail: '상수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1870000',
        trFnDate: '2018-11-29'
      },
      geometry: { type: 'Point', coordinates: [127.937, 37.3183] }
    },
    {
      type: 'Feature',
      id: 319,
      properties: {
        sagoDate: '2018-12-02',
        sagoNo: '20180016',
        sido: '서울특별시',
        sigungu: '동작구',
        dong: '노량진동',
        addr: '50-2앞 (노량진초교 앞)',
        fullAdr: '서울특별시 동작구 노량진동 50-2',
        SEC_CD: '11590101',
        sagoLon: '126.9405',
        sagoLat: '37.5122',
        sinkWidth: '0.7',
        sinkExtend: '0.7',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관과 맨홀 접속부 이음 불량으로 인한 도로침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-12-02'
      },
      geometry: { type: 'Point', coordinates: [126.9405, 37.5122] }
    },
    {
      type: 'Feature',
      id: 320,
      properties: {
        sagoDate: '2018-12-09',
        sagoNo: '20180017',
        sido: '서울특별시',
        sigungu: '강서구',
        dong: '마곡동',
        addr: '793-7 (마곡지구 C17-2블록 공사장 앞 보도)',
        fullAdr: '서울특별시 강서구 마곡동 793-7',
        SEC_CD: '11500105',
        sagoLon: '126.8388',
        sagoLat: '37.5615',
        sinkWidth: '1.5',
        sinkExtend: '3',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail:
          '건축공사장 인입 상수도 맨홀 하중 등으로 하수관 처짐발생->하수가 공사장 내부로 토립자와 함께 유출되어 지반(보도)침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-12-19'
      },
      geometry: { type: 'Point', coordinates: [126.8388, 37.5615] }
    },
    {
      type: 'Feature',
      id: 321,
      properties: {
        sagoDate: '2018-12-01',
        sagoNo: '20180305',
        sido: '강원특별자치도',
        sigungu: '삼척시',
        dong: '교동',
        addr: '151(전)',
        fullAdr: '강원특별자치도 삼척시 갈천동 151',
        SEC_CD: '51230107',
        sagoLon: '129.1599',
        sagoLat: '37.4657',
        sinkWidth: '0.4',
        sinkExtend: '0.4',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '석회암지대 동공발생 현상 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2018-12-31'
      },
      geometry: { type: 'Point', coordinates: [129.1599, 37.4657] }
    },
    {
      type: 'Feature',
      id: 322,
      properties: {
        sagoDate: '2018-12-01',
        sagoNo: '20180306',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '',
        addr: '봉화로 101 태백한우촌 뒤',
        fullAdr: '강원특별자치도 원주시  봉화로 101 태백한우촌 뒤',
        SEC_CD: '51130110',
        sagoLon: '127.928',
        sagoLat: '37.3534',
        sinkWidth: '3.1',
        sinkExtend: '3.5',
        sinkDepth: '1.6',
        grdKind: '충척층',
        sagoDetail: '상수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1300000',
        trFnDate: '2018-12-01'
      },
      geometry: { type: 'Point', coordinates: [127.928, 37.3534] }
    },
    {
      type: 'Feature',
      id: 323,
      properties: {
        sagoDate: '2018-12-01',
        sagoNo: '20180307',
        sido: '경상북도',
        sigungu: '경주시',
        dong: '산내면',
        addr: ' 의곡초등(의곡리 84)- 대현지(저수지) 일원·921지방도',
        fullAdr:
          '경상북도 경주시 산내면  의곡초등(의곡리 84)- 대현지(저수지) 일원·921지방도',
        SEC_CD: '47130340',
        sagoLon: '129.047',
        sagoLat: '35.7551',
        sinkWidth: '20',
        sinkExtend: '21',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '맨홀 주변 단차 및 횡단 관로 침하(33개소)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-04-05'
      },
      geometry: { type: 'Point', coordinates: [129.047, 35.7551] }
    },
    {
      type: 'Feature',
      id: 324,
      properties: {
        sagoDate: '2018-12-01',
        sagoNo: '20180308',
        sido: '경상북도',
        sigungu: '경주시',
        dong: '강동면 국당리',
        addr: ' 904-5 - 왕신리 779-1 ',
        fullAdr: '경상북도 경주시 강동면 국당리  904-5 - 왕신리 779-1',
        SEC_CD: '47130370',
        sagoLon: '129.286',
        sagoLat: '35.9805',
        sinkWidth: '20',
        sinkExtend: '5',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '맨홀 주변 침하(20개소)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-04-05'
      },
      geometry: { type: 'Point', coordinates: [129.286, 35.9805] }
    },
    {
      type: 'Feature',
      id: 325,
      properties: {
        sagoDate: '2018-12-01',
        sagoNo: '20180309',
        sido: '경상북도',
        sigungu: '경주시',
        dong: '안강읍 양월리',
        addr: ' 89번지',
        fullAdr: '경상북도 경주시 안강읍 양월리  89번지',
        SEC_CD: '47130253',
        sagoLon: '129.227',
        sagoLat: '36.005',
        sinkWidth: '7.5',
        sinkExtend: '2',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '맨홀 주변 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-04-05'
      },
      geometry: { type: 'Point', coordinates: [129.227, 36.005] }
    },
    {
      type: 'Feature',
      id: 326,
      properties: {
        sagoDate: '2018-12-05',
        sagoNo: '20180310',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '우산동',
        addr: '덕원아파트 상가',
        fullAdr: '강원특별자치도 원주시 우산동 덕원아파트 상가',
        SEC_CD: '51130111',
        sagoLon: '127.936',
        sagoLat: '37.3628',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '2',
        grdKind: '충척층',
        sagoDetail: '상수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1450000',
        trFnDate: '2018-12-05'
      },
      geometry: { type: 'Point', coordinates: [127.936, 37.3628] }
    },
    {
      type: 'Feature',
      id: 327,
      properties: {
        sagoDate: '2018-12-05',
        sagoNo: '20180311',
        sido: '부산광역시',
        sigungu: '금정구',
        dong: '',
        addr: '금강로 279-1 앞 교차로',
        fullAdr: '부산광역시 금정구 금강로 279-1 (장전동)',
        SEC_CD: '26410108',
        sagoLon: '129.0854',
        sagoLat: '35.233',
        sinkWidth: '2',
        sinkExtend: '3',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail:
          '도로 함몰 부는 ‘사직·장전분구 하수관로 사업(BTL)’ 구간으로 기존의 우수박스로 인해 오수관을 비굴착 압입하였으며· 오수관로 작업 후 토사 유실로 인해 도로 함몰된 것으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-12-06'
      },
      geometry: { type: 'Point', coordinates: [129.0854, 35.233] }
    },
    {
      type: 'Feature',
      id: 328,
      properties: {
        sagoDate: '2018-12-06',
        sagoNo: '20180312',
        sido: '경상남도',
        sigungu: '김해시',
        dong: '내동',
        addr: '186-13번지 앞',
        fullAdr: '경상남도 김해시 내동 186-13',
        SEC_CD: '48250108',
        sagoLon: '128.8565',
        sagoLat: '35.2388',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '노후 하수관과 맨홀 접합부 손상 및 기타매설물 손상(통신관)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '3000000',
        trFnDate: '2018-12-12'
      },
      geometry: { type: 'Point', coordinates: [128.8565, 35.2388] }
    },
    {
      type: 'Feature',
      id: 329,
      properties: {
        sagoDate: '2018-12-10',
        sagoNo: '20180313',
        sido: '강원특별자치도',
        sigungu: '고성군',
        dong: '현내면 철통리',
        addr: '122-5 도로일대',
        fullAdr: '강원특별자치도 고성군 현내면 철통리 122-5 도로일대',
        SEC_CD: '51820310',
        sagoLon: '128.426',
        sagoLat: '38.4935',
        sinkWidth: '5',
        sinkExtend: '15',
        sinkDepth: '3',
        grdKind: '기타',
        sagoDetail: '기타 매설물의 요인으로 도로침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '15000000',
        trFnDate: '2019-11-14'
      },
      geometry: { type: 'Point', coordinates: [128.426, 38.4935] }
    },
    {
      type: 'Feature',
      id: 330,
      properties: {
        sagoDate: '2018-12-13',
        sagoNo: '20180314',
        sido: '부산광역시',
        sigungu: '기장군',
        dong: '',
        addr: '청강리 795-9번지 일원',
        fullAdr: '부산광역시 기장군 기장읍 청강리 795-9',
        SEC_CD: '26710250',
        sagoLon: '129.22',
        sagoLat: '35.2403',
        sinkWidth: '4',
        sinkExtend: '3.4',
        sinkDepth: '1.7',
        grdKind: '',
        sagoDetail:
          '설치년도 불분명한(40년이상 추정) 노후 하수박스 상부슬래브 및 벽체 붕괴',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-12-27'
      },
      geometry: { type: 'Point', coordinates: [129.22, 35.2403] }
    },
    {
      type: 'Feature',
      id: 331,
      properties: {
        sagoDate: '2018-12-14',
        sagoNo: '20180315',
        sido: '경상북도',
        sigungu: '경산시',
        dong: '압량면 가일리',
        addr: ' 100-6번지 일원',
        fullAdr: '경상북도 경산시 압량읍 가일리 100-6',
        SEC_CD: '47290256',
        sagoLon: '128.7845',
        sagoLat: '35.8428',
        sinkWidth: '1.2',
        sinkExtend: '55',
        sinkDepth: '0.07',
        grdKind: '',
        sagoDetail: '전력관로 공사 후 굴착부분 다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-03-31'
      },
      geometry: { type: 'Point', coordinates: [128.7845, 35.8428] }
    },
    {
      type: 'Feature',
      id: 332,
      properties: {
        sagoDate: '2018-12-18',
        sagoNo: '20180316',
        sido: '경기도',
        sigungu: '남양주시',
        dong: '금곡동',
        addr: ' 175-13번지',
        fullAdr: '경기도 남양주시 금곡동 175-13',
        SEC_CD: '41360103',
        sagoLon: '127.2148',
        sagoLat: '37.6368',
        sinkWidth: '0.3',
        sinkExtend: '4',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '우수관로 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1851000',
        trFnDate: '2018-12-26'
      },
      geometry: { type: 'Point', coordinates: [127.2148, 37.6368] }
    },
    {
      type: 'Feature',
      id: 333,
      properties: {
        sagoDate: '2018-12-21',
        sagoNo: '20180317',
        sido: '강원특별자치도',
        sigungu: '고성군',
        dong: '현내면',
        addr: '현내면 초도리 225(동해반점 앞) 도로일대',
        fullAdr:
          '강원특별자치도 고성군 현내면 현내면 초도리 225(동해반점 앞) 도로일대',
        SEC_CD: '51820310',
        sagoLon: '128.428',
        sagoLat: '38.4933',
        sinkWidth: '3',
        sinkExtend: '30',
        sinkDepth: '3',
        grdKind: '기타',
        sagoDetail: '기타 매설물의 요인으로 도로침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '12000000',
        trFnDate: '2019-10-31'
      },
      geometry: { type: 'Point', coordinates: [128.428, 38.4933] }
    },
    {
      type: 'Feature',
      id: 334,
      properties: {
        sagoDate: '2018-12-22',
        sagoNo: '20180318',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '',
        addr: '원동59-3 KBS부근',
        fullAdr: '강원특별자치도 원주시  원동59-3 KBS부근',
        SEC_CD: '41370102',
        sagoLon: '127.087',
        sagoLat: '37.1468',
        sinkWidth: '1.3',
        sinkExtend: '1.3',
        sinkDepth: '1.8',
        grdKind: '충척층',
        sagoDetail: '상수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1110000',
        trFnDate: '2018-12-22'
      },
      geometry: { type: 'Point', coordinates: [127.087, 37.1468] }
    },
    {
      type: 'Feature',
      id: 335,
      properties: {
        sagoDate: '2018-12-22',
        sagoNo: '20180319',
        sido: '경기도',
        sigungu: '수원시 팔달구',
        dong: '',
        addr: '수원시청역 사거리 교차로 내',
        fullAdr: '경기도 수원시 팔달구 효원로 271-2 (인계동)',
        SEC_CD: '41115141',
        sagoLon: '127.0314',
        sagoLat: '37.262',
        sinkWidth: '3',
        sinkExtend: '4',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail:
          '지하철 공사 시 설치한 가시설을 제거하지 않고 매몰하여·폐자재 사이에 빈 공간이 생겨 상부 지반의 토사가 장시간에·걸쳐 그 빈공간으로 흘러 내리면서 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2018-12-22'
      },
      geometry: { type: 'Point', coordinates: [127.0314, 37.262] }
    },
    {
      type: 'Feature',
      id: 336,
      properties: {
        sagoDate: '2018-12-31',
        sagoNo: '20180320',
        sido: '강원특별자치도',
        sigungu: '강릉시',
        dong: '사천면 사천진리',
        addr: '266-2 도로일원',
        fullAdr: '강원특별자치도 강릉시 사천면 사천진리 266-2',
        SEC_CD: '51150360',
        sagoLon: '128.8722',
        sagoLat: '37.8441',
        sinkWidth: '2',
        sinkExtend: '3',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '파랑에 의한 지반 교란으로 판단 됨',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-01-30'
      },
      geometry: { type: 'Point', coordinates: [128.8722, 37.8441] }
    },
    {
      type: 'Feature',
      id: 337,
      properties: {
        sagoDate: '2018-12-31',
        sagoNo: '20180321',
        sido: '강원특별자치도',
        sigungu: '강릉시',
        dong: '사천면 판교리',
        addr: '550 마을안길 일원',
        fullAdr: '강원특별자치도 강릉시 사천면 판교리 550',
        SEC_CD: '51150360',
        sagoLon: '128.852',
        sagoLat: '37.8356',
        sinkWidth: '3',
        sinkExtend: '20',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '토탄층에 의한 지반침하로 판단 됨',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-01-30'
      },
      geometry: { type: 'Point', coordinates: [128.852, 37.8356] }
    },
    {
      type: 'Feature',
      id: 338,
      properties: {
        sagoDate: '2019-01-02',
        sagoNo: '20190002',
        sido: '경상남도',
        sigungu: '김해시',
        dong: '진영읍 신용리',
        addr: '405-11일원',
        fullAdr: '경상남도 김해시 진영읍 신용리 405-11',
        SEC_CD: '48250250',
        sagoLon: '128.7571',
        sagoLat: '35.301',
        sinkWidth: '1200',
        sinkExtend: '1200',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail:
          '기타(연약지반침하)·* 부산외곽순환고속도로 진영IC 진출입로 개설후 동반침하발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-09-08'
      },
      geometry: { type: 'Point', coordinates: [128.7571, 35.301] }
    },
    {
      type: 'Feature',
      id: 339,
      properties: {
        sagoDate: '2019-01-18',
        sagoNo: '20190005',
        sido: '인천광역시',
        sigungu: '부평구',
        dong: '청천동',
        addr: ' 112 번지 쌍용아파트 삼거리',
        fullAdr: '인천광역시 부평구 청천동  112 번지 쌍용아파트 삼거리',
        SEC_CD: '28237104',
        sagoLon: '126.72',
        sagoLat: '37.5072',
        sinkWidth: '4',
        sinkExtend: '5',
        sinkDepth: '5',
        grdKind: '',
        sagoDetail: '지하수유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-16'
      },
      geometry: { type: 'Point', coordinates: [126.72, 37.5072] }
    },
    {
      type: 'Feature',
      id: 340,
      properties: {
        sagoDate: '2019-01-24',
        sagoNo: '20190006',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '비아동',
        addr: '86-3번지',
        fullAdr: '광주광역시 광산구 비아동 86-3',
        SEC_CD: '29200112',
        sagoLon: '126.825',
        sagoLat: '35.2219',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-01-25'
      },
      geometry: { type: 'Point', coordinates: [126.825, 35.2219] }
    },
    {
      type: 'Feature',
      id: 341,
      properties: {
        sagoDate: '2019-01-31',
        sagoNo: '20190008',
        sido: '세종특별자치시',
        sigungu: '',
        dong: '나성동',
        addr: '2-4생활권(주상복합 공사현장 주변도로)',
        fullAdr: '세종특별자치시 나성동 839',
        SEC_CD: '36110107',
        sagoLon: '127.2636',
        sagoLat: '36.487',
        sinkWidth: '0.2',
        sinkExtend: '50',
        sinkDepth: '0',
        grdKind: '',
        sagoDetail:
          '굴착에 따른 지하수 유동과 적용공법 특수성· 전석지반으로 다수 공극 및 흙막이 공사시 유수유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2019-03-12'
      },
      geometry: { type: 'Point', coordinates: [127.2636, 36.487] }
    },
    {
      type: 'Feature',
      id: 342,
      properties: {
        sagoDate: '2019-01-04',
        sagoNo: '20190218',
        sido: '충청남도',
        sigungu: '당진시',
        dong: '송악읍',
        addr: '한진리 408-34 번지 일원',
        fullAdr: '충청남도 당진시 송악읍 한진리 408-34 번지 일원',
        SEC_CD: '44270253',
        sagoLon: '126.776',
        sagoLat: '36.9616',
        sinkWidth: '600',
        sinkExtend: '22',
        sinkDepth: '0.5',
        grdKind: '호상편마암',
        sagoDetail: '한국전력공사 전력구 공사 따른 지하수 유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '0',
        trFnDate: '2021-02-01'
      },
      geometry: { type: 'Point', coordinates: [126.776, 36.9616] }
    },
    {
      type: 'Feature',
      id: 343,
      properties: {
        sagoDate: '2019-02-01',
        sagoNo: '20190009',
        sido: '전라남도',
        sigungu: '해남군',
        dong: '황산면 송호리',
        addr: '167-6 일원',
        fullAdr: '전라남도 해남군 황산면 송호리 167-6',
        SEC_CD: '46820400',
        sagoLon: '126.4699',
        sagoLat: '34.5757',
        sinkWidth: '2',
        sinkExtend: '4',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '원인 불명',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-02-02'
      },
      geometry: { type: 'Point', coordinates: [126.4699, 34.5757] }
    },
    {
      type: 'Feature',
      id: 344,
      properties: {
        sagoDate: '2019-02-01',
        sagoNo: '20190010',
        sido: '전라남도',
        sigungu: '해남군',
        dong: '황산면 연당리',
        addr: '359-5 일원',
        fullAdr: '전라남도 해남군 황산면 연당리 359-5',
        SEC_CD: '46820400',
        sagoLon: '126.4037',
        sagoLat: '34.5739',
        sinkWidth: '2',
        sinkExtend: '5',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '원인 불명',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-02-02'
      },
      geometry: { type: 'Point', coordinates: [126.4037, 34.5739] }
    },
    {
      type: 'Feature',
      id: 345,
      properties: {
        sagoDate: '2019-02-06',
        sagoNo: '20190011',
        sido: '인천광역시',
        sigungu: '부평구',
        dong: '청천동',
        addr: ' 112 번지 쌍용아파트 삼거리',
        fullAdr: '인천광역시 부평구 청천동  112 번지 쌍용아파트 삼거리',
        SEC_CD: '28237104',
        sagoLon: '126.72',
        sagoLat: '37.5072',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '지하수유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-16'
      },
      geometry: { type: 'Point', coordinates: [126.72, 37.5072] }
    },
    {
      type: 'Feature',
      id: 346,
      properties: {
        sagoDate: '2019-02-08',
        sagoNo: '20190012',
        sido: '인천광역시',
        sigungu: '부평구',
        dong: '청천동',
        addr: ' 112 번지 쌍용아파트 삼거리',
        fullAdr: '인천광역시 부평구 청천동  112 번지 쌍용아파트 삼거리',
        SEC_CD: '28237104',
        sagoLon: '126.72',
        sagoLat: '37.5072',
        sinkWidth: '3',
        sinkExtend: '4',
        sinkDepth: '5',
        grdKind: '',
        sagoDetail: '지하수유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-16'
      },
      geometry: { type: 'Point', coordinates: [126.72, 37.5072] }
    },
    {
      type: 'Feature',
      id: 347,
      properties: {
        sagoDate: '2019-02-12',
        sagoNo: '20190013',
        sido: '경기도',
        sigungu: '고양시 덕양구',
        dong: '내유동',
        addr: ' 298-3',
        fullAdr: '경기도 고양시 덕양구 내유동 298-3',
        SEC_CD: '41281119',
        sagoLon: '126.8526',
        sagoLat: '37.7122',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-30'
      },
      geometry: { type: 'Point', coordinates: [126.8526, 37.7122] }
    },
    {
      type: 'Feature',
      id: 348,
      properties: {
        sagoDate: '2019-02-13',
        sagoNo: '20190014',
        sido: '경기도',
        sigungu: '부천시',
        dong: '',
        addr: '경인옛로 111',
        fullAdr: '경기도 부천시 소사구 경인옛로 111 (괴안동·에디션아파트)',
        SEC_CD: '41194104',
        sagoLon: '126.8038',
        sagoLat: '37.4807',
        sinkWidth: '2',
        sinkExtend: '0.7',
        sinkDepth: '0.03',
        grdKind: '',
        sagoDetail: '굴착공사 시 다짐부족',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-02-13'
      },
      geometry: { type: 'Point', coordinates: [126.8038, 37.4807] }
    },
    {
      type: 'Feature',
      id: 349,
      properties: {
        sagoDate: '2019-03-06',
        sagoNo: '20190015',
        sido: '경상남도',
        sigungu: '양산시',
        dong: '북부동',
        addr: '154-11· 삼우아파트',
        fullAdr: '경상남도 양산시 북부동 154-11',
        SEC_CD: '48330104',
        sagoLon: '129.0364',
        sagoLat: '35.3479',
        sinkWidth: '50',
        sinkExtend: '1',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '원도심 지반침하로 인한 영향으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2019-07-19'
      },
      geometry: { type: 'Point', coordinates: [129.0364, 35.3479] }
    },
    {
      type: 'Feature',
      id: 350,
      properties: {
        sagoDate: '2019-03-06',
        sagoNo: '20190016',
        sido: '경상남도',
        sigungu: '양산시',
        dong: '북부동',
        addr: '154-10· 신양주1차아파트',
        fullAdr: '경상남도 양산시 북부동 154-10',
        SEC_CD: '48330104',
        sagoLon: '129.0362',
        sagoLat: '35.3472',
        sinkWidth: '5',
        sinkExtend: '2',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '원도심 지반침하로 인한 영향으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-07-19'
      },
      geometry: { type: 'Point', coordinates: [129.0362, 35.3472] }
    },
    {
      type: 'Feature',
      id: 351,
      properties: {
        sagoDate: '2019-03-06',
        sagoNo: '20190017',
        sido: '경상남도',
        sigungu: '양산시',
        dong: '북부동',
        addr: '153-7· 신양주2차아파트',
        fullAdr: '경상남도 양산시 북부동 153-7',
        SEC_CD: '48330104',
        sagoLon: '129.0372',
        sagoLat: '35.348',
        sinkWidth: '5',
        sinkExtend: '2',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '원도심 지반침하로 인한 영향으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '0',
        trFnDate: '0001-01-01'
      },
      geometry: { type: 'Point', coordinates: [129.0372, 35.348] }
    },
    {
      type: 'Feature',
      id: 352,
      properties: {
        sagoDate: '2019-03-06',
        sagoNo: '20190018',
        sido: '경상남도',
        sigungu: '양산시',
        dong: '북부동',
        addr: '462-33· 해동아파트',
        fullAdr: '경상남도 양산시 북부동 462-33',
        SEC_CD: '48330104',
        sagoLon: '129.0357',
        sagoLat: '35.3462',
        sinkWidth: '40',
        sinkExtend: '2.5',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '원도심 지반침하로 인한 영향으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '0',
        trFnDate: '0001-01-01'
      },
      geometry: { type: 'Point', coordinates: [129.0357, 35.3462] }
    },
    {
      type: 'Feature',
      id: 353,
      properties: {
        sagoDate: '2019-03-06',
        sagoNo: '20190019',
        sido: '경상남도',
        sigungu: '양산시',
        dong: '북부동',
        addr: '462-16· 신우아파트',
        fullAdr: '경상남도 양산시 북부동 462-16',
        SEC_CD: '48330104',
        sagoLon: '129.0355',
        sagoLat: '35.3459',
        sinkWidth: '42',
        sinkExtend: '3',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '원도심 지반침하로 인한 영향으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-07-19'
      },
      geometry: { type: 'Point', coordinates: [129.0355, 35.3459] }
    },
    {
      type: 'Feature',
      id: 354,
      properties: {
        sagoDate: '2019-03-06',
        sagoNo: '20190020',
        sido: '경상남도',
        sigungu: '양산시',
        dong: '북부동',
        addr: '462-5· 삼보아파트',
        fullAdr: '경상남도 양산시 북부동 462-5',
        SEC_CD: '48330104',
        sagoLon: '129.0353',
        sagoLat: '35.3454',
        sinkWidth: '108',
        sinkExtend: '1',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '원도심 지반침하로 인한 영향으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-07-19'
      },
      geometry: { type: 'Point', coordinates: [129.0353, 35.3454] }
    },
    {
      type: 'Feature',
      id: 355,
      properties: {
        sagoDate: '2019-03-07',
        sagoNo: '20190021',
        sido: '경상남도',
        sigungu: '양산시',
        dong: '북부동',
        addr: '삼일로 75-1· 아레나매장빌딩',
        fullAdr: '경상남도 양산시 삼일로 75-1 (북부동)',
        SEC_CD: '48330104',
        sagoLon: '129.0358',
        sagoLat: '35.344',
        sinkWidth: '20',
        sinkExtend: '10',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '원도심 지반침하로 인한 영향으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '0',
        trFnDate: '0001-01-01'
      },
      geometry: { type: 'Point', coordinates: [129.0358, 35.344] }
    },
    {
      type: 'Feature',
      id: 356,
      properties: {
        sagoDate: '2019-03-12',
        sagoNo: '20190022',
        sido: '경상남도',
        sigungu: '양산시',
        dong: '북부동',
        addr: '북안남2길36',
        fullAdr: '경상남도 양산시 북안남2길 36 (북부동)',
        SEC_CD: '48330104',
        sagoLon: '129.0374',
        sagoLat: '35.347',
        sinkWidth: '50',
        sinkExtend: '34',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '양산시2청사 주변 인근공사에 따른 침하로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-04-24'
      },
      geometry: { type: 'Point', coordinates: [129.0374, 35.347] }
    },
    {
      type: 'Feature',
      id: 357,
      properties: {
        sagoDate: '2019-03-15',
        sagoNo: '20190023',
        sido: '광주광역시',
        sigungu: '남구',
        dong: '진월동',
        addr: '한신아파트 일원',
        fullAdr: '광주광역시 남구 진월동 267',
        SEC_CD: '29155108',
        sagoLon: '126.8973',
        sagoLat: '35.1203',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '원인불명',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-03-30'
      },
      geometry: { type: 'Point', coordinates: [126.8973, 35.1203] }
    },
    {
      type: 'Feature',
      id: 358,
      properties: {
        sagoDate: '2019-03-15',
        sagoNo: '20190024',
        sido: '경상남도',
        sigungu: '양산시',
        dong: '북부동',
        addr: '양주3길 36(양주동)· 제2청사',
        fullAdr: '경상남도 양산시 양주3길 36 (중부동)',
        SEC_CD: '48330103',
        sagoLon: '129.0328',
        sagoLat: '35.342',
        sinkWidth: '3',
        sinkExtend: '190',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '연약지반으로 인한 지하수위 저하로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '21000000',
        trFnDate: '0001-01-01'
      },
      geometry: { type: 'Point', coordinates: [129.0328, 35.342] }
    },
    {
      type: 'Feature',
      id: 359,
      properties: {
        sagoDate: '2019-03-15',
        sagoNo: '20190025',
        sido: '경기도',
        sigungu: '화성시',
        dong: '양감면 신왕리',
        addr: ' 113-3',
        fullAdr: '경기도 화성시 양감면 신왕리 113-3',
        SEC_CD: '41590400',
        sagoLon: '126.9582',
        sagoLat: '37.0766',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '기타(하자보수 기간 후· 자연 침하)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-03-16'
      },
      geometry: { type: 'Point', coordinates: [126.9582, 37.0766] }
    },
    {
      type: 'Feature',
      id: 360,
      properties: {
        sagoDate: '2019-03-18',
        sagoNo: '20190026',
        sido: '경기도',
        sigungu: '화성시',
        dong: '양감면 정문리',
        addr: ' 504-15',
        fullAdr: '경기도 화성시 양감면 정문리 504-15',
        SEC_CD: '41590400',
        sagoLon: '126.9811',
        sagoLat: '37.0897',
        sinkWidth: '1',
        sinkExtend: '1.2',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '다짐(되메우기) 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-03-19'
      },
      geometry: { type: 'Point', coordinates: [126.9811, 37.0897] }
    },
    {
      type: 'Feature',
      id: 361,
      properties: {
        sagoDate: '2019-03-20',
        sagoNo: '20190027',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '도천동',
        addr: '622-3번지',
        fullAdr: '광주광역시 광산구 도천동 622-3',
        SEC_CD: '29200113',
        sagoLon: '126.8138',
        sagoLat: '35.2086',
        sinkWidth: '3',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '맨홀 접합부 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-03-21'
      },
      geometry: { type: 'Point', coordinates: [126.8138, 35.2086] }
    },
    {
      type: 'Feature',
      id: 362,
      properties: {
        sagoDate: '2019-03-21',
        sagoNo: '20190029',
        sido: '부산광역시',
        sigungu: '사하구',
        dong: '',
        addr: '다대로 268-1 앞 인도(다대동)',
        fullAdr: '부산광역시 사하구 다대로 268-1 (장림동)',
        SEC_CD: '26380105',
        sagoLon: '128.977',
        sagoLat: '35.0784',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 접합부 불량으로 인한 토사유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-04-09'
      },
      geometry: { type: 'Point', coordinates: [128.977, 35.0784] }
    },
    {
      type: 'Feature',
      id: 363,
      properties: {
        sagoDate: '2019-03-21',
        sagoNo: '20190030',
        sido: '경기도',
        sigungu: '부천시',
        dong: '춘의동',
        addr: ' 72-5번지',
        fullAdr: '경기도 부천시 원미구 춘의동 72-5',
        SEC_CD: '41192103',
        sagoLon: '126.7881',
        sagoLat: '37.5056',
        sinkWidth: '0.5',
        sinkExtend: '2',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-05-14'
      },
      geometry: { type: 'Point', coordinates: [126.7881, 37.5056] }
    },
    {
      type: 'Feature',
      id: 364,
      properties: {
        sagoDate: '2019-03-28',
        sagoNo: '20190031',
        sido: '경상남도',
        sigungu: '거제시',
        dong: '장평동',
        addr: '571-3',
        fullAdr: '경상남도 거제시 장평동 571-3',
        SEC_CD: '48310108',
        sagoLon: '128.602832',
        sagoLat: '34.892476',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '0.5',
        grdKind: '기타',
        sagoDetail: '사각맨홀 뚜껑노후로 침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1000000',
        trFnDate: '2019-03-28'
      },
      geometry: { type: 'Point', coordinates: [128.602832, 34.892476] }
    },
    {
      type: 'Feature',
      id: 365,
      properties: {
        sagoDate: '2019-03-28',
        sagoNo: '20190032',
        sido: '경상남도',
        sigungu: '양산시',
        dong: '',
        addr: '삼일로 65· 현대타운빌딩',
        fullAdr: '경상남도 양산시 삼일로 65 (북부동)',
        SEC_CD: '48330104',
        sagoLon: '129.0345',
        sagoLat: '35.3439',
        sinkWidth: '1',
        sinkExtend: '25',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '원도심 지반침하로 인한 영향으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '0',
        trFnDate: '0001-01-01'
      },
      geometry: { type: 'Point', coordinates: [129.0345, 35.3439] }
    },
    {
      type: 'Feature',
      id: 366,
      properties: {
        sagoDate: '2019-03-28',
        sagoNo: '20190033',
        sido: '경상남도',
        sigungu: '양산시',
        dong: '',
        addr: '삼일로 67· 네오프라자상가',
        fullAdr: '경상남도 양산시 삼일로 67 (북부동)',
        SEC_CD: '48330104',
        sagoLon: '129.0348',
        sagoLat: '35.3439',
        sinkWidth: '3',
        sinkExtend: '5',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '원도심 지반침하로 인한 영향으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '0',
        trFnDate: '0001-01-01'
      },
      geometry: { type: 'Point', coordinates: [129.0348, 35.3439] }
    },
    {
      type: 'Feature',
      id: 367,
      properties: {
        sagoDate: '2019-03-28',
        sagoNo: '20190034',
        sido: '경상남도',
        sigungu: '양산시',
        dong: '',
        addr: '양주3길 40-13· 양주초등학교',
        fullAdr: '경상남도 양산시 양주3길 40-13 (중부동)',
        SEC_CD: '48330103',
        sagoLon: '129.0321',
        sagoLat: '35.3428',
        sinkWidth: '15',
        sinkExtend: '11',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '원도심 지반침하로 인한 영향으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '0',
        trFnDate: '0001-01-01'
      },
      geometry: { type: 'Point', coordinates: [129.0321, 35.3428] }
    },
    {
      type: 'Feature',
      id: 368,
      properties: {
        sagoDate: '2019-04-24',
        sagoNo: '20190001',
        sido: '서울특별시',
        sigungu: '강서구',
        dong: '화곡동',
        addr: '50-120(거성빌라 입구)',
        fullAdr: '서울특별시 강서구 화곡동 50-120',
        SEC_CD: '11500103',
        sagoLon: '126.8498',
        sagoLat: '37.543',
        sinkWidth: '1.2',
        sinkExtend: '1.2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail:
          '노후하수관(1·200mm)손상 및 연결관 접속부 미폐쇄 조치로 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-04-25'
      },
      geometry: { type: 'Point', coordinates: [126.8498, 37.543] }
    },
    {
      type: 'Feature',
      id: 369,
      properties: {
        sagoDate: '2019-04-03',
        sagoNo: '20190035',
        sido: '부산광역시',
        sigungu: '동래구',
        dong: '온천동',
        addr: '1134-26번지',
        fullAdr: '부산광역시 동래구 온천동 1134-26',
        SEC_CD: '26260108',
        sagoLon: '129.0628',
        sagoLat: '35.2112',
        sinkWidth: '1',
        sinkExtend: '3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '노후하수관 파손에 의한 박스부 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-04-05'
      },
      geometry: { type: 'Point', coordinates: [129.0628, 35.2112] }
    },
    {
      type: 'Feature',
      id: 370,
      properties: {
        sagoDate: '2019-04-03',
        sagoNo: '20190036',
        sido: '경상남도',
        sigungu: '양산시',
        dong: '',
        addr: '양산대로 849· 양산종합운동장',
        fullAdr: '경상남도 양산시 양산대로 849 (북부동)',
        SEC_CD: '48330104',
        sagoLon: '129.0334',
        sagoLat: '35.3467',
        sinkWidth: '33',
        sinkExtend: '43',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail:
          '원도심 지반침하로 인한 영향(종합운동장· 실내체육관 구조물과 바닥경계부 틈발생 및 보도블럭 침하)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '604000000',
        trFnDate: '2019-05-22'
      },
      geometry: { type: 'Point', coordinates: [129.0334, 35.3467] }
    },
    {
      type: 'Feature',
      id: 371,
      properties: {
        sagoDate: '2019-04-04',
        sagoNo: '20190037',
        sido: '경상남도',
        sigungu: '양산시',
        dong: '중부동',
        addr: '605-6· 중부동 1·3공영주차장',
        fullAdr: '경상남도 양산시 중부동 605-6',
        SEC_CD: '48330103',
        sagoLon: '129.0354',
        sagoLat: '35.3398',
        sinkWidth: '40',
        sinkExtend: '2',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '원도심 지반침하로 인한 영향으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '0',
        trFnDate: '0001-01-01'
      },
      geometry: { type: 'Point', coordinates: [129.0354, 35.3398] }
    },
    {
      type: 'Feature',
      id: 372,
      properties: {
        sagoDate: '2019-04-05',
        sagoNo: '20190038',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '월계동',
        addr: '896-4번지',
        fullAdr: '광주광역시 광산구 월계동 896-4',
        SEC_CD: '29200115',
        sagoLon: '126.8422',
        sagoLat: '35.2102',
        sinkWidth: '0.7',
        sinkExtend: '0.8',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '맨홀 접합부 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-04-05'
      },
      geometry: { type: 'Point', coordinates: [126.8422, 35.2102] }
    },
    {
      type: 'Feature',
      id: 373,
      properties: {
        sagoDate: '2019-04-05',
        sagoNo: '20190039',
        sido: '경상남도',
        sigungu: '김해시',
        dong: '주촌면',
        addr: '주촌선천지구 28블록 2로트 옆 아파트 조경 및 부출입구',
        fullAdr: '경상남도 김해시 주촌면 천곡로 26 (김해센텀 두산위브더제니스)',
        SEC_CD: '48250320',
        sagoLon: '128.8374',
        sagoLat: '35.2367',
        sinkWidth: '2.5',
        sinkExtend: '25',
        sinkDepth: '0.4',
        grdKind: '기타',
        sagoDetail: '연약지반의 자연 침하 및 인접 아파트 굴착공사',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-05-20'
      },
      geometry: { type: 'Point', coordinates: [128.8374, 35.2367] }
    },
    {
      type: 'Feature',
      id: 374,
      properties: {
        sagoDate: '2019-04-06',
        sagoNo: '20190040',
        sido: '충청남도',
        sigungu: '당진시',
        dong: '송악읍 복운리 ',
        addr: '일원(부곡공단 내 해안도로)',
        fullAdr: '충청남도 당진시 송악읍 복운리  일원(부곡공단 내 해안도로)',
        SEC_CD: '44270253',
        sagoLon: '126.782',
        sagoLat: '36.963',
        sinkWidth: '0.3',
        sinkExtend: '0.3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '열(수증기) 공급관로 노후로 인한 누수 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '2000000',
        trFnDate: '2019-04-08'
      },
      geometry: { type: 'Point', coordinates: [126.782, 36.963] }
    },
    {
      type: 'Feature',
      id: 375,
      properties: {
        sagoDate: '2019-04-08',
        sagoNo: '20190041',
        sido: '경상남도',
        sigungu: '양산시',
        dong: '',
        addr: '양주3길 36(양주동)',
        fullAdr: '경상남도 양산시 양주3길 36 (중부동)',
        SEC_CD: '48330103',
        sagoLon: '129.0328',
        sagoLat: '35.342',
        sinkWidth: '8',
        sinkExtend: '5',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '원도심 지반침하로 인한 영향으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '250000000',
        trFnDate: '0001-01-01'
      },
      geometry: { type: 'Point', coordinates: [129.0328, 35.342] }
    },
    {
      type: 'Feature',
      id: 376,
      properties: {
        sagoDate: '2019-04-10',
        sagoNo: '20190042',
        sido: '경기도',
        sigungu: '부천시',
        dong: '',
        addr: '심중로 55',
        fullAdr: '경기도 부천시 원미구 심중로 55 (중동·예일주택)',
        SEC_CD: '41192108',
        sagoLon: '126.7721',
        sagoLat: '37.4883',
        sinkWidth: '2.5',
        sinkExtend: '98',
        sinkDepth: '0.7',
        grdKind: '',
        sagoDetail: '상수도 공사시 다짐이 충분하지 않았던 것으로 판단',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-04-12'
      },
      geometry: { type: 'Point', coordinates: [126.7721, 37.4883] }
    },
    {
      type: 'Feature',
      id: 377,
      properties: {
        sagoDate: '2019-04-10',
        sagoNo: '20190043',
        sido: '경기도',
        sigungu: '남양주시',
        dong: '진접읍 장현리',
        addr: ' 409번지',
        fullAdr: '경기도 남양주시 진접읍 장현리 409-1',
        SEC_CD: '41360253',
        sagoLon: '127.1816',
        sagoLat: '37.722',
        sinkWidth: '0.5',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '다짐(되메우기) 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-04-13'
      },
      geometry: { type: 'Point', coordinates: [127.1816, 37.722] }
    },
    {
      type: 'Feature',
      id: 378,
      properties: {
        sagoDate: '2019-04-11',
        sagoNo: '20190044',
        sido: '부산광역시',
        sigungu: '동래구',
        dong: '',
        addr: '명서로 182',
        fullAdr: '부산광역시 동래구 명서로 182 (명장동·명장경동아파트)',
        SEC_CD: '26260101',
        sagoLon: '129.1018',
        sagoLat: '35.2071',
        sinkWidth: '2',
        sinkExtend: '3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수박스 벽체부 파손으로 인한 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-04-11'
      },
      geometry: { type: 'Point', coordinates: [129.1018, 35.2071] }
    },
    {
      type: 'Feature',
      id: 379,
      properties: {
        sagoDate: '2019-04-12',
        sagoNo: '20190045',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '',
        addr: '갈마공원네거리 일원',
        fullAdr: '대전광역시 서구  갈마공원네거리 일원',
        SEC_CD: '30170112',
        sagoLon: '127.375',
        sagoLat: '36.3577',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.8',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '2',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '20000000',
        trFnDate: '2019-04-16'
      },
      geometry: { type: 'Point', coordinates: [127.375, 36.3577] }
    },
    {
      type: 'Feature',
      id: 380,
      properties: {
        sagoDate: '2019-04-15',
        sagoNo: '20190046',
        sido: '경상남도',
        sigungu: '양산시',
        dong: '',
        addr: '중앙로 177· 신한은행',
        fullAdr: '경상남도 양산시 중앙로 177 (북부동)',
        SEC_CD: '48330104',
        sagoLon: '129.0389',
        sagoLat: '35.3464',
        sinkWidth: '80',
        sinkExtend: '1.5',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '원도심 지반침하로 인한 영향으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '0',
        trFnDate: '0001-01-01'
      },
      geometry: { type: 'Point', coordinates: [129.0389, 35.3464] }
    },
    {
      type: 'Feature',
      id: 381,
      properties: {
        sagoDate: '2019-04-15',
        sagoNo: '20190047',
        sido: '경상남도',
        sigungu: '양산시',
        dong: '',
        addr: '서일동로16· 보훈회관',
        fullAdr: '경상남도 양산시 서일동로 16 (남부동)',
        SEC_CD: '48330102',
        sagoLon: '129.0366',
        sagoLat: '35.3398',
        sinkWidth: '20',
        sinkExtend: '2',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '원도심 지반침하로 인한 영향으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '10000000',
        trFnDate: '0001-01-01'
      },
      geometry: { type: 'Point', coordinates: [129.0366, 35.3398] }
    },
    {
      type: 'Feature',
      id: 382,
      properties: {
        sagoDate: '2019-04-16',
        sagoNo: '20190048',
        sido: '충청북도',
        sigungu: '청주시 상당구',
        dong: '',
        addr: '북문로3가 98번지',
        fullAdr: '충청북도 청주시 상당구 상당로115번길 3 (북문로2가)',
        SEC_CD: '43111103',
        sagoLon: '127.4897',
        sagoLat: '36.6384',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 파손에 의한 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-04-16'
      },
      geometry: { type: 'Point', coordinates: [127.4897, 36.6384] }
    },
    {
      type: 'Feature',
      id: 383,
      properties: {
        sagoDate: '2019-04-16',
        sagoNo: '20190049',
        sido: '경상남도',
        sigungu: '양산시',
        dong: '',
        addr: '중앙로 198· 대한상공회의소',
        fullAdr: '경상남도 양산시 중앙로 198 (북부동)',
        SEC_CD: '48330104',
        sagoLon: '129.0404',
        sagoLat: '35.3481',
        sinkWidth: '60',
        sinkExtend: '1.5',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '원도심 지반침하로 인한 영향으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '0',
        trFnDate: '0001-01-01'
      },
      geometry: { type: 'Point', coordinates: [129.0404, 35.3481] }
    },
    {
      type: 'Feature',
      id: 384,
      properties: {
        sagoDate: '2019-04-16',
        sagoNo: '20190050',
        sido: '경상남도',
        sigungu: '양산시',
        dong: '',
        addr: '북안남2길 24-11',
        fullAdr: '경상남도 양산시 북안남2길 24-11 (북부동)',
        SEC_CD: '48330104',
        sagoLon: '129.0373',
        sagoLat: '35.3459',
        sinkWidth: '25',
        sinkExtend: '2',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '원도심 지반침하로 인한 영향으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '0',
        trFnDate: '0001-01-01'
      },
      geometry: { type: 'Point', coordinates: [129.0373, 35.3459] }
    },
    {
      type: 'Feature',
      id: 385,
      properties: {
        sagoDate: '2019-04-18',
        sagoNo: '20190051',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '하남동',
        addr: '505-1번지',
        fullAdr: '광주광역시 광산구 하남동 505-1',
        SEC_CD: '29200125',
        sagoLon: '126.7962',
        sagoLat: '35.1868',
        sinkWidth: '1',
        sinkExtend: '1.2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-04-20'
      },
      geometry: { type: 'Point', coordinates: [126.7962, 35.1868] }
    },
    {
      type: 'Feature',
      id: 386,
      properties: {
        sagoDate: '2019-04-22',
        sagoNo: '20190052',
        sido: '부산광역시',
        sigungu: '강서구',
        dong: '명지동',
        addr: '3589-5번지 일원 도로',
        fullAdr: '부산광역시 강서구 명지동 3589-5',
        SEC_CD: '26440104',
        sagoLon: '128.9034',
        sagoLat: '35.092',
        sinkWidth: '27',
        sinkExtend: '13.4',
        sinkDepth: '1.4',
        grdKind: '',
        sagoDetail:
          '인접 건축공사장의 지하터파기로 인한 지하수 유출로 인접도로 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-07-11'
      },
      geometry: { type: 'Point', coordinates: [128.9034, 35.092] }
    },
    {
      type: 'Feature',
      id: 387,
      properties: {
        sagoDate: '2019-04-22',
        sagoNo: '20190053',
        sido: '경기도',
        sigungu: '화성시',
        dong: '팔탄면 기천리',
        addr: ' 390',
        fullAdr: '경기도 화성시 팔탄면 기천리 390',
        SEC_CD: '41590360',
        sagoLon: '126.9047',
        sagoLat: '37.1829',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '다짐(되메우기) 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-04-23'
      },
      geometry: { type: 'Point', coordinates: [126.9047, 37.1829] }
    },
    {
      type: 'Feature',
      id: 388,
      properties: {
        sagoDate: '2019-04-23',
        sagoNo: '20190054',
        sido: '부산광역시',
        sigungu: '동래구',
        dong: '',
        addr: '우장춘로 67번길 2',
        fullAdr: '부산광역시 동래구 우장춘로67번길 2 (온천동)',
        SEC_CD: '26260108',
        sagoLon: '129.0716',
        sagoLat: '35.2139',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수박스 벽체부 파손으로 인한 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-04-25'
      },
      geometry: { type: 'Point', coordinates: [129.0716, 35.2139] }
    },
    {
      type: 'Feature',
      id: 389,
      properties: {
        sagoDate: '2019-04-24',
        sagoNo: '20190055',
        sido: '경상남도',
        sigungu: '김해시',
        dong: '어방동',
        addr: '365-5번지',
        fullAdr: '경상남도 김해시 어방동 365-5',
        SEC_CD: '48250118',
        sagoLon: '128.9015',
        sagoLat: '35.239',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '우수맨홀 내부 관 접합부 파손으로 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '2500000',
        trFnDate: '2019-04-25'
      },
      geometry: { type: 'Point', coordinates: [128.9015, 35.239] }
    },
    {
      type: 'Feature',
      id: 390,
      properties: {
        sagoDate: '2019-05-02',
        sagoNo: '20190057',
        sido: '경기도',
        sigungu: '부천시',
        dong: '중동',
        addr: ' 1056 사거리 코너',
        fullAdr: '경기도 부천시 원미구 중동 1056',
        SEC_CD: '41192108',
        sagoLon: '126.7696',
        sagoLat: '37.5044',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail:
          '인근 하수 박스 공사시 다짐이 충분하지 않았던 것으로 판단됨',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-05-02'
      },
      geometry: { type: 'Point', coordinates: [126.7696, 37.5044] }
    },
    {
      type: 'Feature',
      id: 391,
      properties: {
        sagoDate: '2019-05-03',
        sagoNo: '20190058',
        sido: '경기도',
        sigungu: '고양시 덕양구',
        dong: '효자동',
        addr: ' 177-2번지',
        fullAdr: '경기도 고양시 덕양구 효자동 177-2',
        SEC_CD: '41281108',
        sagoLon: '126.9497',
        sagoLat: '37.6603',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-05-04'
      },
      geometry: { type: 'Point', coordinates: [126.9497, 37.6603] }
    },
    {
      type: 'Feature',
      id: 392,
      properties: {
        sagoDate: '2019-05-03',
        sagoNo: '20190059',
        sido: '경기도',
        sigungu: '부천시',
        dong: '',
        addr: '심곡로34번길 43 앞 사거리',
        fullAdr: '경기도 부천시 소사구 심곡로34번길 43 (송내동)',
        SEC_CD: '41194105',
        sagoLon: '126.7731',
        sagoLat: '37.4811',
        sinkWidth: '0.7',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '맨홀 하부 파손에 따른 토사유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-05-05'
      },
      geometry: { type: 'Point', coordinates: [126.7731, 37.4811] }
    },
    {
      type: 'Feature',
      id: 393,
      properties: {
        sagoDate: '2019-05-07',
        sagoNo: '20190060',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '내덕동',
        addr: '103-3번지',
        fullAdr: '충청북도 청주시 청원구 내덕동 103-3',
        SEC_CD: '43114102',
        sagoLon: '127.4953',
        sagoLat: '36.6571',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-05-07'
      },
      geometry: { type: 'Point', coordinates: [127.4953, 36.6571] }
    },
    {
      type: 'Feature',
      id: 394,
      properties: {
        sagoDate: '2019-05-08',
        sagoNo: '20190061',
        sido: '경기도',
        sigungu: '고양시 덕양구',
        dong: '행신동',
        addr: ' 226-4번지',
        fullAdr: '경기도 고양시 덕양구 행신동 226-4',
        SEC_CD: '41281128',
        sagoLon: '126.8457',
        sagoLat: '37.6222',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-05-29'
      },
      geometry: { type: 'Point', coordinates: [126.8457, 37.6222] }
    },
    {
      type: 'Feature',
      id: 395,
      properties: {
        sagoDate: '2019-05-10',
        sagoNo: '20190062',
        sido: '경기도',
        sigungu: '고양시 덕양구',
        dong: '화전동',
        addr: ' 218-6번지',
        fullAdr: '경기도 고양시 덕양구 화전동 218-6',
        SEC_CD: '41281129',
        sagoLon: '126.8729',
        sagoLat: '37.6018',
        sinkWidth: '1',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-05-29'
      },
      geometry: { type: 'Point', coordinates: [126.8729, 37.6018] }
    },
    {
      type: 'Feature',
      id: 396,
      properties: {
        sagoDate: '2019-05-11',
        sagoNo: '20190063',
        sido: '부산광역시',
        sigungu: '사하구',
        dong: '신평동',
        addr: '409-9(신평동)',
        fullAdr: '부산광역시 사하구 신평동 409-9',
        SEC_CD: '26380104',
        sagoLon: '128.9627',
        sagoLat: '35.0947',
        sinkWidth: '1.2',
        sinkExtend: '1.2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '오수관로 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-20'
      },
      geometry: { type: 'Point', coordinates: [128.9627, 35.0947] }
    },
    {
      type: 'Feature',
      id: 397,
      properties: {
        sagoDate: '2019-05-11',
        sagoNo: '20190064',
        sido: '대전광역시',
        sigungu: '대덕구',
        dong: '신탄진동',
        addr: '127-28번지 일원',
        fullAdr: '대전광역시 대덕구 신탄진동 127-28',
        SEC_CD: '30230126',
        sagoLon: '127.4347',
        sagoLat: '36.4519',
        sinkWidth: '2',
        sinkExtend: '4',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 파손 및 하수 누수로 지반유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-05-11'
      },
      geometry: { type: 'Point', coordinates: [127.4347, 36.4519] }
    },
    {
      type: 'Feature',
      id: 398,
      properties: {
        sagoDate: '2019-05-13',
        sagoNo: '20190065',
        sido: '부산광역시',
        sigungu: '동래구',
        dong: '명륜동',
        addr: '386 동래전화국',
        fullAdr: '부산광역시 동래구 명륜동 386',
        SEC_CD: '26260107',
        sagoLon: '129.083',
        sagoLat: '35.2042',
        sinkWidth: '0.5',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수박스 벽체부 파손으로 인한 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-02'
      },
      geometry: { type: 'Point', coordinates: [129.083, 35.2042] }
    },
    {
      type: 'Feature',
      id: 399,
      properties: {
        sagoDate: '2019-05-14',
        sagoNo: '20190067',
        sido: '경기도',
        sigungu: '고양시 일산서구',
        dong: '주엽동',
        addr: ' 14',
        fullAdr: '경기도 고양시 일산서구 주엽동 14',
        SEC_CD: '41287102',
        sagoLon: '126.7562',
        sagoLat: '37.6745',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-05-16'
      },
      geometry: { type: 'Point', coordinates: [126.7562, 37.6745] }
    },
    {
      type: 'Feature',
      id: 400,
      properties: {
        sagoDate: '2019-05-19',
        sagoNo: '20190070',
        sido: '울산광역시',
        sigungu: '울주군',
        dong: '서생면 화정리',
        addr: '935번지(서생삼거리 인근)',
        fullAdr: '울산광역시 울주군 서생면 화정리 935번지(서생삼거리 인근)',
        SEC_CD: '31710310',
        sagoLon: '129.329',
        sagoLat: '35.3937',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '하수관로 노후에 따른 부식으로 누수발생 및 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '6',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-05-21'
      },
      geometry: { type: 'Point', coordinates: [129.329, 35.3937] }
    },
    {
      type: 'Feature',
      id: 401,
      properties: {
        sagoDate: '2019-05-20',
        sagoNo: '20190071',
        sido: '인천광역시',
        sigungu: '중구',
        dong: '',
        addr: '월미로233번길 11',
        fullAdr: '인천광역시 중구  월미로233번길 11',
        SEC_CD: '28110138',
        sagoLon: '126.601',
        sagoLat: '37.4748',
        sinkWidth: '2',
        sinkExtend: '15',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '다짐불량에 의한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-01'
      },
      geometry: { type: 'Point', coordinates: [126.601, 37.4748] }
    },
    {
      type: 'Feature',
      id: 402,
      properties: {
        sagoDate: '2019-05-22',
        sagoNo: '20190072',
        sido: '대전광역시',
        sigungu: '동구',
        dong: '',
        addr: '대동역 1번출구 앞 2차선',
        fullAdr: '대전광역시 동구 계족로 171 (대동)',
        SEC_CD: '30110110',
        sagoLon: '127.4429',
        sagoLat: '36.329',
        sinkWidth: '4.2',
        sinkExtend: '6',
        sinkDepth: '0.15',
        grdKind: '',
        sagoDetail: '상수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-05-22'
      },
      geometry: { type: 'Point', coordinates: [127.4429, 36.329] }
    },
    {
      type: 'Feature',
      id: 403,
      properties: {
        sagoDate: '2019-05-22',
        sagoNo: '20190073',
        sido: '대전광역시',
        sigungu: '중구',
        dong: '선화동',
        addr: '430-10',
        fullAdr: '대전광역시 중구 선화동 430-10',
        SEC_CD: '30140102',
        sagoLon: '127.4165',
        sagoLat: '36.3255',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-05-22'
      },
      geometry: { type: 'Point', coordinates: [127.4165, 36.3255] }
    },
    {
      type: 'Feature',
      id: 404,
      properties: {
        sagoDate: '2019-05-22',
        sagoNo: '20190074',
        sido: '경기도',
        sigungu: '부천시',
        dong: '도당동',
        addr: ' 72-9번지',
        fullAdr: '경기도 부천시 원미구 도당동 72-9',
        SEC_CD: '41192104',
        sagoLon: '126.787',
        sagoLat: '37.5142',
        sinkWidth: '0.5',
        sinkExtend: '3',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-04-15'
      },
      geometry: { type: 'Point', coordinates: [126.787, 37.5142] }
    },
    {
      type: 'Feature',
      id: 405,
      properties: {
        sagoDate: '2019-05-23',
        sagoNo: '20190075',
        sido: '경기도',
        sigungu: '고양시 일산동구',
        dong: '백석동',
        addr: ' 1288-1',
        fullAdr: '경기도 고양시 일산동구 백석동 1288-1',
        SEC_CD: '41285106',
        sagoLon: '126.7857',
        sagoLat: '37.6458',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-13'
      },
      geometry: { type: 'Point', coordinates: [126.7857, 37.6458] }
    },
    {
      type: 'Feature',
      id: 406,
      properties: {
        sagoDate: '2019-05-23',
        sagoNo: '20190076',
        sido: '경기도',
        sigungu: '고양시 일산서구',
        dong: '덕이동',
        addr: ' 274-25',
        fullAdr: '경기도 고양시 일산서구 덕이동 274-25',
        SEC_CD: '41287105',
        sagoLon: '126.76',
        sagoLat: '37.6941',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-05-30'
      },
      geometry: { type: 'Point', coordinates: [126.76, 37.6941] }
    },
    {
      type: 'Feature',
      id: 407,
      properties: {
        sagoDate: '2019-05-24',
        sagoNo: '20190077',
        sido: '대구광역시',
        sigungu: '달서구',
        dong: '감삼동',
        addr: '죽전네거리(구 알리앙스 앞)',
        fullAdr: '대구광역시 달서구 감삼동 죽전네거리(구 알리앙스 앞)',
        SEC_CD: '27290113',
        sagoLon: '128.537',
        sagoLat: '35.8492',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '2.5',
        grdKind: '',
        sagoDetail: '노후상수도관 누수에 따른 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-10'
      },
      geometry: { type: 'Point', coordinates: [128.537, 35.8492] }
    },
    {
      type: 'Feature',
      id: 408,
      properties: {
        sagoDate: '2019-05-26',
        sagoNo: '20190078',
        sido: '대전광역시',
        sigungu: '대덕구',
        dong: '읍내동',
        addr: '483-2번지 일원',
        fullAdr: '대전광역시 대덕구 읍내동 483-2',
        SEC_CD: '30230103',
        sagoLon: '127.4184',
        sagoLat: '36.375',
        sinkWidth: '1.8',
        sinkExtend: '2',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관로 노후화로 인한 이음부 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-05-26'
      },
      geometry: { type: 'Point', coordinates: [127.4184, 36.375] }
    },
    {
      type: 'Feature',
      id: 409,
      properties: {
        sagoDate: '2019-05-27',
        sagoNo: '20190079',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '탄방동',
        addr: '1423번지 앞 2차로',
        fullAdr: '대전광역시 서구 탄방동 1423번지 앞 2차로',
        SEC_CD: '30170105',
        sagoLon: '127.399',
        sagoLat: '36.3426',
        sinkWidth: '0.2',
        sinkExtend: '0.1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '5000000',
        trFnDate: '2019-05-30'
      },
      geometry: { type: 'Point', coordinates: [127.399, 36.3426] }
    },
    {
      type: 'Feature',
      id: 410,
      properties: {
        sagoDate: '2019-05-27',
        sagoNo: '20190080',
        sido: '경상남도',
        sigungu: '김해시',
        dong: '내동',
        addr: '1101-7번지',
        fullAdr: '경상남도 김해시 내동 1101-7',
        SEC_CD: '48250108',
        sagoLon: '128.8586',
        sagoLat: '35.2374',
        sinkWidth: '1',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '우수박스 크랙 발생으로 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '2700000',
        trFnDate: '2019-05-27'
      },
      geometry: { type: 'Point', coordinates: [128.8586, 35.2374] }
    },
    {
      type: 'Feature',
      id: 411,
      properties: {
        sagoDate: '2019-05-30',
        sagoNo: '20190082',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '학동',
        addr: '12-1번지',
        fullAdr: '광주광역시 동구 학동 12-1번지',
        SEC_CD: '29110122',
        sagoLon: '126.923',
        sagoLat: '35.1401',
        sinkWidth: '0.6',
        sinkExtend: '2',
        sinkDepth: '1.2',
        grdKind: '기타',
        sagoDetail: '다짐불량으로 인한 토사세굴 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '4500000',
        trFnDate: '2019-07-14'
      },
      geometry: { type: 'Point', coordinates: [126.923, 35.1401] }
    },
    {
      type: 'Feature',
      id: 412,
      properties: {
        sagoDate: '2019-05-31',
        sagoNo: '20190083',
        sido: '강원특별자치도',
        sigungu: '속초시',
        dong: '동명동',
        addr: '81-1번지',
        fullAdr: '강원특별자치도 속초시 동명동 81-1',
        SEC_CD: '51210102',
        sagoLon: '128.5958',
        sagoLat: '38.2121',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '0.8',
        grdKind: '',
        sagoDetail: '하수관 손상으로 인하여 토사 유실에 따른 지반 침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-05-31'
      },
      geometry: { type: 'Point', coordinates: [128.5958, 38.2121] }
    },
    {
      type: 'Feature',
      id: 413,
      properties: {
        sagoDate: '2019-05-31',
        sagoNo: '20190084',
        sido: '경상남도',
        sigungu: '양산시',
        dong: '북부동',
        addr: '480-3· 양복교회 건물',
        fullAdr: '경상남도 양산시 북부동 480-3',
        SEC_CD: '48330104',
        sagoLon: '129.037',
        sagoLat: '35.3473',
        sinkWidth: '50',
        sinkExtend: '3',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '원도심 지반침하로 인한 영향으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '0',
        trFnDate: '0001-01-01'
      },
      geometry: { type: 'Point', coordinates: [129.037, 35.3473] }
    },
    {
      type: 'Feature',
      id: 414,
      properties: {
        sagoDate: '2019-06-04',
        sagoNo: '20190086',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '오선동',
        addr: '270-49번지',
        fullAdr: '광주광역시 광산구 오선동 270-49',
        SEC_CD: '29200202',
        sagoLon: '126.7973',
        sagoLat: '35.2026',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-05'
      },
      geometry: { type: 'Point', coordinates: [126.7973, 35.2026] }
    },
    {
      type: 'Feature',
      id: 415,
      properties: {
        sagoDate: '2019-06-04',
        sagoNo: '20190087',
        sido: '경상남도',
        sigungu: '양산시',
        dong: '북부동',
        addr: '487-9· Y모텔',
        fullAdr: '경상남도 양산시 북부동 487-9',
        SEC_CD: '48330104',
        sagoLon: '129.0371',
        sagoLat: '35.3454',
        sinkWidth: '14',
        sinkExtend: '12',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '원도심 지반침하로 인한 영향으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '0',
        trFnDate: '0001-01-01'
      },
      geometry: { type: 'Point', coordinates: [129.0371, 35.3454] }
    },
    {
      type: 'Feature',
      id: 416,
      properties: {
        sagoDate: '2019-06-04',
        sagoNo: '20190088',
        sido: '경상남도',
        sigungu: '진주시',
        dong: '이현동',
        addr: '30-9번지 앞 도로',
        fullAdr: '경상남도 진주시 이현동 30-9',
        SEC_CD: '48170127',
        sagoLon: '128.0676',
        sagoLat: '35.191',
        sinkWidth: '2.5',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수관로 부식',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-04'
      },
      geometry: { type: 'Point', coordinates: [128.0676, 35.191] }
    },
    {
      type: 'Feature',
      id: 417,
      properties: {
        sagoDate: '2019-06-05',
        sagoNo: '20190089',
        sido: '대전광역시',
        sigungu: '동구',
        dong: '용전동',
        addr: '12-9번지 앞 ',
        fullAdr: '대전광역시 동구 용전동 12-9번지 앞',
        SEC_CD: '30110115',
        sagoLon: '127.434',
        sagoLat: '36.3577',
        sinkWidth: '2.7',
        sinkExtend: '3.8',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '연결관 파손으로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-12'
      },
      geometry: { type: 'Point', coordinates: [127.434, 36.3577] }
    },
    {
      type: 'Feature',
      id: 418,
      properties: {
        sagoDate: '2019-06-05',
        sagoNo: '20190090',
        sido: '경기도',
        sigungu: '고양시 덕양구',
        dong: '주교동',
        addr: ' 594-12',
        fullAdr: '경기도 고양시 덕양구 주교동 594-12',
        SEC_CD: '41281101',
        sagoLon: '126.8309',
        sagoLat: '37.6584',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-10'
      },
      geometry: { type: 'Point', coordinates: [126.8309, 37.6584] }
    },
    {
      type: 'Feature',
      id: 419,
      properties: {
        sagoDate: '2019-06-05',
        sagoNo: '20190091',
        sido: '경기도',
        sigungu: '부천시',
        dong: '괴안동',
        addr: ' 108',
        fullAdr: '경기도 부천시 소사구 괴안동 108',
        SEC_CD: '41194104',
        sagoLon: '126.81',
        sagoLat: '37.4825',
        sinkWidth: '1',
        sinkExtend: '3',
        sinkDepth: '0.07',
        grdKind: '',
        sagoDetail: '하수맨홀 연결접합 불량으로 인한 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-07-01'
      },
      geometry: { type: 'Point', coordinates: [126.81, 37.4825] }
    },
    {
      type: 'Feature',
      id: 420,
      properties: {
        sagoDate: '2019-06-07',
        sagoNo: '20190092',
        sido: '강원특별자치도',
        sigungu: '강릉시',
        dong: '송정동 ',
        addr: '945번지 앞',
        fullAdr: '강원특별자치도 강릉시 송정동 945',
        SEC_CD: '51150114',
        sagoLon: '128.9274',
        sagoLat: '37.7742',
        sinkWidth: '5',
        sinkExtend: '5',
        sinkDepth: '2.5',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-14'
      },
      geometry: { type: 'Point', coordinates: [128.9274, 37.7742] }
    },
    {
      type: 'Feature',
      id: 421,
      properties: {
        sagoDate: '2019-06-08',
        sagoNo: '20190094',
        sido: '대전광역시',
        sigungu: '유성구',
        dong: '구암동',
        addr: '현충원역 3번출구 앞',
        fullAdr: '대전광역시 유성구 구암동 현충원역 3번출구 앞',
        SEC_CD: '30200112',
        sagoLon: '127.321',
        sagoLat: '36.3594',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.8',
        grdKind: '기타',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '2000000',
        trFnDate: '2019-06-09'
      },
      geometry: { type: 'Point', coordinates: [127.321, 36.3594] }
    },
    {
      type: 'Feature',
      id: 422,
      properties: {
        sagoDate: '2019-06-11',
        sagoNo: '20190096',
        sido: '경상남도',
        sigungu: '양산시',
        dong: '중부동',
        addr: '398-15· 스마트교복담장',
        fullAdr: '경상남도 양산시 중부동 398-15',
        SEC_CD: '48330103',
        sagoLon: '129.0346',
        sagoLat: '35.3436',
        sinkWidth: '25',
        sinkExtend: '1',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '원도심 지반침하로 인한 영향으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '0',
        trFnDate: '0001-01-01'
      },
      geometry: { type: 'Point', coordinates: [129.0346, 35.3436] }
    },
    {
      type: 'Feature',
      id: 423,
      properties: {
        sagoDate: '2019-06-11',
        sagoNo: '20190097',
        sido: '경기도',
        sigungu: '고양시 일산서구',
        dong: '일산동',
        addr: ' 1398',
        fullAdr: '경기도 고양시 일산서구 일산동 1398',
        SEC_CD: '41287101',
        sagoLon: '126.7655',
        sagoLat: '37.6793',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-20'
      },
      geometry: { type: 'Point', coordinates: [126.7655, 37.6793] }
    },
    {
      type: 'Feature',
      id: 424,
      properties: {
        sagoDate: '2019-06-12',
        sagoNo: '20190099',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '신촌동',
        addr: '1090-33번지',
        fullAdr: '광주광역시 광산구 신촌동 1090-33',
        SEC_CD: '29200104',
        sagoLon: '126.802',
        sagoLat: '35.1439',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-13'
      },
      geometry: { type: 'Point', coordinates: [126.802, 35.1439] }
    },
    {
      type: 'Feature',
      id: 425,
      properties: {
        sagoDate: '2019-06-14',
        sagoNo: '20190102',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '신가동',
        addr: '872-7번지',
        fullAdr: '광주광역시 광산구 신가동 872-7',
        SEC_CD: '29200119',
        sagoLon: '126.8275',
        sagoLat: '35.182',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '빗물받이 연결관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-16'
      },
      geometry: { type: 'Point', coordinates: [126.8275, 35.182] }
    },
    {
      type: 'Feature',
      id: 426,
      properties: {
        sagoDate: '2019-06-14',
        sagoNo: '20190103',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '화정동',
        addr: '857-2',
        fullAdr: '광주광역시 서구 화정동 1712',
        SEC_CD: '29140119',
        sagoLon: '126.8747',
        sagoLat: '35.1399',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '우수관 연결부 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-14'
      },
      geometry: { type: 'Point', coordinates: [126.8747, 35.1399] }
    },
    {
      type: 'Feature',
      id: 427,
      properties: {
        sagoDate: '2019-06-14',
        sagoNo: '20190104',
        sido: '경기도',
        sigungu: '부천시',
        dong: '중동',
        addr: ' 1142-4',
        fullAdr: '경기도 부천시 원미구 중동 1142-4',
        SEC_CD: '41192108',
        sagoLon: '126.7732',
        sagoLat: '37.5023',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '전기배선 하부 다짐불량으로 발생한 것으로 판단됨',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-14'
      },
      geometry: { type: 'Point', coordinates: [126.7732, 37.5023] }
    },
    {
      type: 'Feature',
      id: 428,
      properties: {
        sagoDate: '2019-06-16',
        sagoNo: '20190108',
        sido: '경상남도',
        sigungu: '진주시',
        dong: '신안동',
        addr: '463-9번지 앞 도로',
        fullAdr: '경상남도 진주시 신안동 463-9',
        SEC_CD: '48170125',
        sagoLon: '128.0683',
        sagoLat: '35.1791',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관로 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-24'
      },
      geometry: { type: 'Point', coordinates: [128.0683, 35.1791] }
    },
    {
      type: 'Feature',
      id: 429,
      properties: {
        sagoDate: '2019-06-17',
        sagoNo: '20190109',
        sido: '대구광역시',
        sigungu: '수성구',
        dong: '두산동',
        addr: '34-8번지 삼거리 일원',
        fullAdr: '대구광역시 수성구 두산동 34-8번지 삼거리 일원',
        SEC_CD: '27260111',
        sagoLon: '128.618',
        sagoLat: '35.8374',
        sinkWidth: '3',
        sinkExtend: '2.7',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '노후하수관(D450) 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-18'
      },
      geometry: { type: 'Point', coordinates: [128.618, 35.8374] }
    },
    {
      type: 'Feature',
      id: 430,
      properties: {
        sagoDate: '2019-06-20',
        sagoNo: '20190112',
        sido: '부산광역시',
        sigungu: '해운대구',
        dong: '우동',
        addr: '825-1',
        fullAdr: '부산광역시 해운대구 우동 825-1',
        SEC_CD: '26350105',
        sagoLon: '129.1505',
        sagoLat: '35.1601',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '지하철 공사용 매몰 토류판 부식부를 통해· 토사유출 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-24'
      },
      geometry: { type: 'Point', coordinates: [129.1505, 35.1601] }
    },
    {
      type: 'Feature',
      id: 431,
      properties: {
        sagoDate: '2019-06-20',
        sagoNo: '20190115',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '',
        addr: '금남로5가 163-1',
        fullAdr: '광주광역시 동구  금남로5가 163-1',
        SEC_CD: '29110134',
        sagoLon: '126.915',
        sagoLat: '35.1502',
        sinkWidth: '0.5',
        sinkExtend: '3',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '하수관 손상으로 인한 토사세굴 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '4500000',
        trFnDate: '2019-07-12'
      },
      geometry: { type: 'Point', coordinates: [126.915, 35.1502] }
    },
    {
      type: 'Feature',
      id: 432,
      properties: {
        sagoDate: '2019-06-22',
        sagoNo: '20190117',
        sido: '부산광역시',
        sigungu: '동래구',
        dong: '',
        addr: '미남로130',
        fullAdr: '부산광역시 동래구 미남로 130 (온천동)',
        SEC_CD: '26260108',
        sagoLon: '129.0698',
        sagoLat: '35.2056',
        sinkWidth: '2',
        sinkExtend: '3',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '노후 하수관 파손에 의한 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-24'
      },
      geometry: { type: 'Point', coordinates: [129.0698, 35.2056] }
    },
    {
      type: 'Feature',
      id: 433,
      properties: {
        sagoDate: '2019-06-22',
        sagoNo: '20190118',
        sido: '경기도',
        sigungu: '화성시',
        dong: '송산동',
        addr: ' 132-1(황계남길 80)',
        fullAdr: '경기도 화성시 송산동 132-1',
        SEC_CD: '41590125',
        sagoLon: '127.0152',
        sagoLat: '37.213',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '기타(하자보수 기간 후· 자연 침하)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-23'
      },
      geometry: { type: 'Point', coordinates: [127.0152, 37.213] }
    },
    {
      type: 'Feature',
      id: 434,
      properties: {
        sagoDate: '2019-06-23',
        sagoNo: '20190119',
        sido: '경기도',
        sigungu: '의왕시',
        dong: '포일동',
        addr: ' 505-107',
        fullAdr: '경기도 의왕시 포일동 505-107',
        SEC_CD: '41430109',
        sagoLon: '126.9871',
        sagoLat: '37.3933',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '0.03',
        grdKind: '',
        sagoDetail: '다짐 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2019-06-24'
      },
      geometry: { type: 'Point', coordinates: [126.9871, 37.3933] }
    },
    {
      type: 'Feature',
      id: 435,
      properties: {
        sagoDate: '2019-06-24',
        sagoNo: '20190121',
        sido: '충청북도',
        sigungu: '청주시 상당구',
        dong: '석교동',
        addr: '126-129번지',
        fullAdr: '충청북도 청주시 상당구 석교동 126-129',
        SEC_CD: '43111111',
        sagoLon: '127.4928',
        sagoLat: '36.6276',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 파손에 의한 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-24'
      },
      geometry: { type: 'Point', coordinates: [127.4928, 36.6276] }
    },
    {
      type: 'Feature',
      id: 436,
      properties: {
        sagoDate: '2019-06-25',
        sagoNo: '20190122',
        sido: '충청북도',
        sigungu: '청주시 상당구',
        dong: '명암동',
        addr: '산60-8번지',
        fullAdr: '충청북도 청주시 상당구 명암동 산 60-8',
        SEC_CD: '43111122',
        sagoLon: '127.5147',
        sagoLat: '36.6488',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 파손에 의한 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-25'
      },
      geometry: { type: 'Point', coordinates: [127.5147, 36.6488] }
    },
    {
      type: 'Feature',
      id: 437,
      properties: {
        sagoDate: '2019-06-25',
        sagoNo: '20190123',
        sido: '경상남도',
        sigungu: '창원시 진해구',
        dong: '장찬동',
        addr: '391-2번지',
        fullAdr: '경상남도 창원시 진해구 장찬동 391-2번지',
        SEC_CD: '48129145',
        sagoLon: '128.709',
        sagoLat: '35.1305',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '도시가스 관로교차점 채움 모래 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '600000',
        trFnDate: '2019-07-09'
      },
      geometry: { type: 'Point', coordinates: [128.709, 35.1305] }
    },
    {
      type: 'Feature',
      id: 438,
      properties: {
        sagoDate: '2019-06-25',
        sagoNo: '20190124',
        sido: '인천광역시',
        sigungu: '계양구',
        dong: '',
        addr: '새말사거리',
        fullAdr: '인천광역시 계양구  새말사거리',
        SEC_CD: '28245101',
        sagoLon: '126.705',
        sagoLat: '37.5255',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '하수맨홀 불량으로 인한 동공 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-25'
      },
      geometry: { type: 'Point', coordinates: [126.705, 37.5255] }
    },
    {
      type: 'Feature',
      id: 439,
      properties: {
        sagoDate: '2019-06-25',
        sagoNo: '20190125',
        sido: '경기도',
        sigungu: '부천시',
        dong: '중동',
        addr: ' 1105(신흥로쪽 삼거리)',
        fullAdr: '경기도 부천시 원미구 중동 1105',
        SEC_CD: '41192108',
        sagoLon: '126.7773',
        sagoLat: '37.5021',
        sinkWidth: '1.2',
        sinkExtend: '2',
        sinkDepth: '1.7',
        grdKind: '',
        sagoDetail:
          '가스배관 철판하부 및 엘보구간 다짐불량으로 인한 모래유실(모래층 다 파도',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-25'
      },
      geometry: { type: 'Point', coordinates: [126.7773, 37.5021] }
    },
    {
      type: 'Feature',
      id: 440,
      properties: {
        sagoDate: '2019-06-25',
        sagoNo: '20190126',
        sido: '경기도',
        sigungu: '부천시',
        dong: '',
        addr: '부흥로200번길 18',
        fullAdr: '경기도 부천시 원미구 부흥로200번길 18 (중동)',
        SEC_CD: '41192108',
        sagoLon: '126.7644',
        sagoLat: '37.4953',
        sinkWidth: '0.6',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 손상에 따른 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-26'
      },
      geometry: { type: 'Point', coordinates: [126.7644, 37.4953] }
    },
    {
      type: 'Feature',
      id: 441,
      properties: {
        sagoDate: '2019-06-27',
        sagoNo: '20190127',
        sido: '부산광역시',
        sigungu: '영도구',
        dong: '',
        addr: '태종로 668번길 1 일원',
        fullAdr: '부산광역시 영도구 태종로 666 (동삼동)',
        SEC_CD: '26200121',
        sagoLon: '129.0742',
        sagoLat: '35.0742',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '상수도관(200mm) 파손으로 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-28'
      },
      geometry: { type: 'Point', coordinates: [129.0742, 35.0742] }
    },
    {
      type: 'Feature',
      id: 442,
      properties: {
        sagoDate: '2019-06-27',
        sagoNo: '20190128',
        sido: '경기도',
        sigungu: '남양주시',
        dong: '와부읍 덕소리',
        addr: '458-26',
        fullAdr: '경기도 남양주시 와부읍 덕소리 458-26',
        SEC_CD: '41360250',
        sagoLon: '127.2107',
        sagoLat: '37.5875',
        sinkWidth: '1.5',
        sinkExtend: '2.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '노후로 인한 집수정 손상',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-29'
      },
      geometry: { type: 'Point', coordinates: [127.2107, 37.5875] }
    },
    {
      type: 'Feature',
      id: 443,
      properties: {
        sagoDate: '2019-06-29',
        sagoNo: '20190129',
        sido: '전라남도',
        sigungu: '완도군',
        dong: '완도읍',
        addr: '개포로 146-8',
        fullAdr: '전라남도 완도군 완도읍 개포로 146-8',
        SEC_CD: '46890250',
        sagoLon: '126.7441',
        sagoLat: '34.3188',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '다짐불량으로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-30'
      },
      geometry: { type: 'Point', coordinates: [126.7441, 34.3188] }
    },
    {
      type: 'Feature',
      id: 444,
      properties: {
        sagoDate: '2019-06-29',
        sagoNo: '20190130',
        sido: '경기도',
        sigungu: '부천시',
        dong: '도당동',
        addr: ' 83-2번지',
        fullAdr: '경기도 부천시 원미구 도당동 83-1',
        SEC_CD: '41192104',
        sagoLon: '126.7851',
        sagoLat: '37.5124',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '개인배수설비불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-08-06'
      },
      geometry: { type: 'Point', coordinates: [126.7851, 37.5124] }
    },
    {
      type: 'Feature',
      id: 445,
      properties: {
        sagoDate: '2019-06-29',
        sagoNo: '20190131',
        sido: '경기도',
        sigungu: '하남시',
        dong: '신장동',
        addr: ' 26-15(지번)',
        fullAdr: '경기도 하남시 신장동 27',
        SEC_CD: '41450106',
        sagoLon: '127.2196',
        sagoLat: '37.5467',
        sinkWidth: '1.8',
        sinkExtend: '1.8',
        sinkDepth: '0.8',
        grdKind: '',
        sagoDetail: '다짐 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-06-29'
      },
      geometry: { type: 'Point', coordinates: [127.2196, 37.5467] }
    },
    {
      type: 'Feature',
      id: 446,
      properties: {
        sagoDate: '2019-06-30',
        sagoNo: '20190180',
        sido: '경상남도',
        sigungu: '김해시',
        dong: '주촌면 망덕리',
        addr: '874-5번지 일원',
        fullAdr: '경상남도 김해시 주촌면 망덕리 874-5',
        SEC_CD: '48250320',
        sagoLon: '128.8263',
        sagoLat: '35.2177',
        sinkWidth: '1200',
        sinkExtend: '1200',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '연약지반 개량공사 미실시',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-07-26'
      },
      geometry: { type: 'Point', coordinates: [128.8263, 35.2177] }
    },
    {
      type: 'Feature',
      id: 447,
      properties: {
        sagoDate: '2019-07-17',
        sagoNo: '20190135',
        sido: '대전광역시',
        sigungu: '유성구',
        dong: '반석동',
        addr: '반석역 1번출구 앞',
        fullAdr: '대전광역시 유성구 북유성대로 303 (반석동)',
        SEC_CD: '30200139',
        sagoLon: '127.3146',
        sagoLat: '36.3921',
        sinkWidth: '0.6',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-07-18'
      },
      geometry: { type: 'Point', coordinates: [127.3146, 36.3921] }
    },
    {
      type: 'Feature',
      id: 448,
      properties: {
        sagoDate: '2019-07-18',
        sagoNo: '20190136',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '변동',
        addr: '변동우체국 맞은편',
        fullAdr: '대전광역시 서구 변동 50-1',
        SEC_CD: '30170102',
        sagoLon: '127.3786',
        sagoLat: '36.3254',
        sinkWidth: '0.5',
        sinkExtend: '0.8',
        sinkDepth: '5',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-07-19'
      },
      geometry: { type: 'Point', coordinates: [127.3786, 36.3254] }
    },
    {
      type: 'Feature',
      id: 449,
      properties: {
        sagoDate: '2019-07-19',
        sagoNo: '20190137',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '탄방동',
        addr: '목련네거리 기업은행 앞 측구',
        fullAdr: '대전광역시 서구 탄방동 728',
        SEC_CD: '30170106',
        sagoLon: '127.3898',
        sagoLat: '36.3487',
        sinkWidth: '0.3',
        sinkExtend: '0.2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-07-20'
      },
      geometry: { type: 'Point', coordinates: [127.3898, 36.3487] }
    },
    {
      type: 'Feature',
      id: 450,
      properties: {
        sagoDate: '2019-07-24',
        sagoNo: '20190138',
        sido: '대전광역시',
        sigungu: '중구',
        dong: '문창동',
        addr: ' 문창2동 우편취급국 맞은편',
        fullAdr: '대전광역시 중구 문창동 365-11',
        SEC_CD: '30140106',
        sagoLon: '127.438',
        sagoLat: '36.3145',
        sinkWidth: '0.3',
        sinkExtend: '0.2',
        sinkDepth: '1.1',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-07-25'
      },
      geometry: { type: 'Point', coordinates: [127.438, 36.3145] }
    },
    {
      type: 'Feature',
      id: 451,
      properties: {
        sagoDate: '2019-07-07',
        sagoNo: '20190147',
        sido: '부산광역시',
        sigungu: '북구',
        dong: '구포동',
        addr: '모분재로 145(구포동 칠우그린빌라 앞)',
        fullAdr: '부산광역시 북구 모분재로 145 (구포동·칠우그린빌라)',
        SEC_CD: '26320105',
        sagoLon: '129.0123',
        sagoLat: '35.1969',
        sinkWidth: '0.7',
        sinkExtend: '0.6',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '토압에 의한 하수관로 이음새 파손 부분에 발생한 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-07-08'
      },
      geometry: { type: 'Point', coordinates: [129.0123, 35.1969] }
    },
    {
      type: 'Feature',
      id: 452,
      properties: {
        sagoDate: '2019-07-11',
        sagoNo: '20190148',
        sido: '부산광역시',
        sigungu: '남구',
        dong: '대연동',
        addr: '245-150 일원',
        fullAdr: '부산광역시 남구 대연동 245-150',
        SEC_CD: '26290106',
        sagoLon: '129.088',
        sagoLat: '35.1504',
        sinkWidth: '1.8',
        sinkExtend: '1.1',
        sinkDepth: '0.6',
        grdKind: '',
        sagoDetail: '하수도관의 파손으로 인한 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-11-14'
      },
      geometry: { type: 'Point', coordinates: [129.088, 35.1504] }
    },
    {
      type: 'Feature',
      id: 453,
      properties: {
        sagoDate: '2019-07-07',
        sagoNo: '20190152',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '',
        addr: '무실로47번길 44(원동 현진아파트 후문 앞)',
        fullAdr: '강원특별자치도 원주시 무실로47번길 45 (원동)',
        SEC_CD: '51130103',
        sagoLon: '127.9478',
        sagoLat: '37.3439',
        sinkWidth: '2',
        sinkExtend: '3',
        sinkDepth: '4',
        grdKind: '',
        sagoDetail: '우수관로(1·000mm) 파손으로 인한 도로침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-07-09'
      },
      geometry: { type: 'Point', coordinates: [127.9478, 37.3439] }
    },
    {
      type: 'Feature',
      id: 454,
      properties: {
        sagoDate: '2019-07-29',
        sagoNo: '20190153',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '문막읍',
        addr: '동화리 산47-7',
        fullAdr: '강원특별자치도 원주시 문막읍 동화리 산 47-7',
        SEC_CD: '51130250',
        sagoLon: '127.8482',
        sagoLat: '37.3295',
        sinkWidth: '0.8',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '알수없음',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-07-31'
      },
      geometry: { type: 'Point', coordinates: [127.8482, 37.3295] }
    },
    {
      type: 'Feature',
      id: 455,
      properties: {
        sagoDate: '2019-07-01',
        sagoNo: '20190155',
        sido: '경기도',
        sigungu: '부천시',
        dong: '',
        addr: '중동 1151',
        fullAdr: '경기도 부천시 원미구 중동 1151',
        SEC_CD: '41192108',
        sagoLon: '126.7693',
        sagoLat: '37.5022',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '1.7',
        grdKind: '',
        sagoDetail: '상수도 누수로 인한 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-07-03'
      },
      geometry: { type: 'Point', coordinates: [126.7693, 37.5022] }
    },
    {
      type: 'Feature',
      id: 456,
      properties: {
        sagoDate: '2019-07-01',
        sagoNo: '20190156',
        sido: '경기도',
        sigungu: '부천시',
        dong: '',
        addr: '약대동 80-11',
        fullAdr: '경기도 부천시 원미구 약대동 80-11',
        SEC_CD: '41192105',
        sagoLon: '126.7681',
        sagoLat: '37.5143',
        sinkWidth: '0.3',
        sinkExtend: '0.3',
        sinkDepth: '1.3',
        grdKind: '',
        sagoDetail: '하수도 맨홀 연결관 접합부 부실로 인한 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-07-03'
      },
      geometry: { type: 'Point', coordinates: [126.7681, 37.5143] }
    },
    {
      type: 'Feature',
      id: 457,
      properties: {
        sagoDate: '2019-07-01',
        sagoNo: '20190157',
        sido: '경기도',
        sigungu: '안양시 만안구',
        dong: '',
        addr: '석수동 241-43',
        fullAdr: '경기도 안양시 만안구 석수동 241-43',
        SEC_CD: '41171102',
        sagoLon: '126.9297',
        sagoLat: '37.4207',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '맨홀 접합부 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-07-03'
      },
      geometry: { type: 'Point', coordinates: [126.9297, 37.4207] }
    },
    {
      type: 'Feature',
      id: 458,
      properties: {
        sagoDate: '2019-07-01',
        sagoNo: '20190158',
        sido: '경기도',
        sigungu: '의왕시',
        dong: '포일동',
        addr: '505-79',
        fullAdr: '경기도 의왕시 포일동 505-79',
        SEC_CD: '41430109',
        sagoLon: '126.9872',
        sagoLat: '37.393',
        sinkWidth: '1.5',
        sinkExtend: '1.7',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '기타매설물 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-07-03'
      },
      geometry: { type: 'Point', coordinates: [126.9872, 37.393] }
    },
    {
      type: 'Feature',
      id: 459,
      properties: {
        sagoDate: '2019-07-02',
        sagoNo: '20190159',
        sido: '경기도',
        sigungu: '고양시 덕양구',
        dong: '',
        addr: '동산동 368-2',
        fullAdr: '경기도 고양시 덕양구 동산동 368-2',
        SEC_CD: '41281112',
        sagoLon: '126.8953',
        sagoLat: '37.6493',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '맨홀 시공 불량',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-07-04'
      },
      geometry: { type: 'Point', coordinates: [126.8953, 37.6493] }
    },
    {
      type: 'Feature',
      id: 460,
      properties: {
        sagoDate: '2019-07-03',
        sagoNo: '20190160',
        sido: '경기도',
        sigungu: '고양시 일산동구',
        dong: '',
        addr: '백석동 1460',
        fullAdr: '경기도 고양시 일산동구 백석동 1460',
        SEC_CD: '41285106',
        sagoLon: '126.7772',
        sagoLat: '37.6443',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 손상으로 인하여 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-07-05'
      },
      geometry: { type: 'Point', coordinates: [126.7772, 37.6443] }
    },
    {
      type: 'Feature',
      id: 461,
      properties: {
        sagoDate: '2019-07-04',
        sagoNo: '20190161',
        sido: '경기도',
        sigungu: '구리시',
        dong: '',
        addr: '동구릉로 200번길(한진그랑빌 앞)',
        fullAdr: '경기도 구리시 동구릉로238번길 20 (인창동)',
        SEC_CD: '41310103',
        sagoLon: '127.1418',
        sagoLat: '37.619',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '0.6',
        grdKind: '',
        sagoDetail: '개인하수관로 파손으로 인한 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-07-06'
      },
      geometry: { type: 'Point', coordinates: [127.1418, 37.619] }
    },
    {
      type: 'Feature',
      id: 462,
      properties: {
        sagoDate: '2019-07-15',
        sagoNo: '20190162',
        sido: '경기도',
        sigungu: '의왕시',
        dong: '포일동',
        addr: '680',
        fullAdr: '경기도 의왕시 포일동 680',
        SEC_CD: '41430109',
        sagoLon: '126.9881',
        sagoLat: '37.3973',
        sinkWidth: '1',
        sinkExtend: '2.5',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '기타매설물 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-07-17'
      },
      geometry: { type: 'Point', coordinates: [126.9881, 37.3973] }
    },
    {
      type: 'Feature',
      id: 463,
      properties: {
        sagoDate: '2019-07-22',
        sagoNo: '20190163',
        sido: '경기도',
        sigungu: '고양시 덕양구',
        dong: '',
        addr: '행신동 1121',
        fullAdr: '경기도 고양시 덕양구 행신동 1121',
        SEC_CD: '41281128',
        sagoLon: '126.8462',
        sagoLat: '37.6179',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '맨홀 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-07-24'
      },
      geometry: { type: 'Point', coordinates: [126.8462, 37.6179] }
    },
    {
      type: 'Feature',
      id: 464,
      properties: {
        sagoDate: '2019-07-24',
        sagoNo: '20190164',
        sido: '경기도',
        sigungu: '의왕시',
        dong: '고천동',
        addr: '231-14',
        fullAdr: '경기도 의왕시 고천동 231-14',
        SEC_CD: '41430101',
        sagoLon: '126.9748',
        sagoLat: '37.3446',
        sinkWidth: '0.8',
        sinkExtend: '1.3',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '통신관로 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-07-26'
      },
      geometry: { type: 'Point', coordinates: [126.9748, 37.3446] }
    },
    {
      type: 'Feature',
      id: 465,
      properties: {
        sagoDate: '2019-07-25',
        sagoNo: '20190165',
        sido: '경기도',
        sigungu: '구리시',
        dong: '교문동',
        addr: ' 635-3번지 쉐보레 정비소 앞',
        fullAdr: '경기도 구리시 교문동 635-1',
        SEC_CD: '41310104',
        sagoLon: '127.128',
        sagoLat: '37.5801',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관로 손상에 의한 토사유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-07-27'
      },
      geometry: { type: 'Point', coordinates: [127.128, 37.5801] }
    },
    {
      type: 'Feature',
      id: 466,
      properties: {
        sagoDate: '2019-07-25',
        sagoNo: '20190183',
        sido: '광주광역시',
        sigungu: '남구',
        dong: '진월동',
        addr: '331-64번지 앞',
        fullAdr: '광주광역시 남구 진월동 331-64',
        SEC_CD: '29155108',
        sagoLon: '126.8945',
        sagoLat: '35.1239',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.3',
        grdKind: '',
        sagoDetail: '맨홀접합부 관로파손으로 인한 도로침하 및 동공발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-07-27'
      },
      geometry: { type: 'Point', coordinates: [126.8945, 35.1239] }
    },
    {
      type: 'Feature',
      id: 467,
      properties: {
        sagoDate: '2019-07-31',
        sagoNo: '20190184',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '오선동',
        addr: '547-10번지',
        fullAdr: '광주광역시 광산구 오선동 547-10',
        SEC_CD: '29200202',
        sagoLon: '126.8068',
        sagoLat: '35.2072',
        sinkWidth: '1.2',
        sinkExtend: '1.2',
        sinkDepth: '0.8',
        grdKind: '',
        sagoDetail: '하수관로 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-08-02'
      },
      geometry: { type: 'Point', coordinates: [126.8068, 35.2072] }
    },
    {
      type: 'Feature',
      id: 468,
      properties: {
        sagoDate: '2019-07-26',
        sagoNo: '20190193',
        sido: '서울특별시',
        sigungu: '강서구',
        dong: '마곡동',
        addr: '278-5(마곡지구내 보도)',
        fullAdr: '서울특별시 강서구 마곡동 761-5',
        SEC_CD: '11500105',
        sagoLon: '126.8278',
        sagoLat: '37.5662',
        sinkWidth: '0.8',
        sinkExtend: '1',
        sinkDepth: '0.4',
        grdKind: '',
        sagoDetail: '굴착원인조사 결과 원인불명(굴착복구 불량 추정)',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-07-28'
      },
      geometry: { type: 'Point', coordinates: [126.8278, 37.5662] }
    },
    {
      type: 'Feature',
      id: 469,
      properties: {
        sagoDate: '2019-07-19',
        sagoNo: '20190205',
        sido: '인천광역시',
        sigungu: '서구',
        dong: '',
        addr: '검단로 검단사거리 횡단보도 도로',
        fullAdr: '인천광역시 서구 검단로 486 (마전동)',
        SEC_CD: '28260113',
        sagoLon: '126.6583',
        sagoLat: '37.6021',
        sinkWidth: '0.8',
        sinkExtend: '0.8',
        sinkDepth: '1.2',
        grdKind: '',
        sagoDetail: '우수관과 오수관 접합부 불량에 의한 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-07-21'
      },
      geometry: { type: 'Point', coordinates: [126.6583, 37.6021] }
    },
    {
      type: 'Feature',
      id: 470,
      properties: {
        sagoDate: '2019-07-31',
        sagoNo: '20190210',
        sido: '전북특별자치도',
        sigungu: '군산시',
        dong: '',
        addr: '분멀2길 32-7',
        fullAdr: '전북특별자치도 군산시 분멀2길 32-7 (오식도동)',
        SEC_CD: '52130147',
        sagoLon: '126.571',
        sagoLat: '35.9619',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관거 파손으로 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-08-02'
      },
      geometry: { type: 'Point', coordinates: [126.571, 35.9619] }
    },
    {
      type: 'Feature',
      id: 471,
      properties: {
        sagoDate: '2019-08-12',
        sagoNo: '20190139',
        sido: '대전광역시',
        sigungu: '동구',
        dong: '',
        addr: '삼성5가-한남5가 한남철강 맞은편',
        fullAdr: '대전광역시 동구 대전로 987 (삼성동)',
        SEC_CD: '30110118',
        sagoLon: '127.4208',
        sagoLat: '36.3441',
        sinkWidth: '0.7',
        sinkExtend: '1',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-08-13'
      },
      geometry: { type: 'Point', coordinates: [127.4208, 36.3441] }
    },
    {
      type: 'Feature',
      id: 472,
      properties: {
        sagoDate: '2019-08-14',
        sagoNo: '20190140',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '',
        addr: '정림동 639번지 인근',
        fullAdr: '대전광역시 서구 정림동 639',
        SEC_CD: '30170104',
        sagoLon: '127.3646',
        sagoLat: '36.3034',
        sinkWidth: '0.3',
        sinkExtend: '0.3',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-08-15'
      },
      geometry: { type: 'Point', coordinates: [127.3646, 36.3034] }
    },
    {
      type: 'Feature',
      id: 473,
      properties: {
        sagoDate: '2019-08-28',
        sagoNo: '20190141',
        sido: '대전광역시',
        sigungu: '동구',
        dong: '',
        addr: '구대전터널-신상교 방향 측구',
        fullAdr: '대전광역시 동구 회남로58번길 7 (신상동)',
        SEC_CD: '30110129',
        sagoLon: '127.4986',
        sagoLat: '36.3522',
        sinkWidth: '0.6',
        sinkExtend: '0.8',
        sinkDepth: '1.3',
        grdKind: '',
        sagoDetail: '기타매설물 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-08-29'
      },
      geometry: { type: 'Point', coordinates: [127.4986, 36.3522] }
    },
    {
      type: 'Feature',
      id: 474,
      properties: {
        sagoDate: '2019-08-30',
        sagoNo: '20190142',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '',
        addr: '도마동 50-59번지 인근',
        fullAdr: '대전광역시 서구 도마동 50-59',
        SEC_CD: '30170103',
        sagoLon: '127.3712',
        sagoLat: '36.322',
        sinkWidth: '0.2',
        sinkExtend: '0.2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-08-31'
      },
      geometry: { type: 'Point', coordinates: [127.3712, 36.322] }
    },
    {
      type: 'Feature',
      id: 475,
      properties: {
        sagoDate: '2019-08-04',
        sagoNo: '20190149',
        sido: '부산광역시',
        sigungu: '금정구',
        dong: '청룡동',
        addr: '310-1번지 상 도로',
        fullAdr: '부산광역시 금정구 청룡동 310-1',
        SEC_CD: '26410103',
        sagoLon: '129.0877',
        sagoLat: '35.2801',
        sinkWidth: '0.8',
        sinkExtend: '1.8',
        sinkDepth: '0.8',
        grdKind: '',
        sagoDetail: '하수박스 벽체 점검구 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-08-05'
      },
      geometry: { type: 'Point', coordinates: [129.0877, 35.2801] }
    },
    {
      type: 'Feature',
      id: 476,
      properties: {
        sagoDate: '2019-08-10',
        sagoNo: '20190150',
        sido: '부산광역시',
        sigungu: '사상구',
        dong: '',
        addr: '새벽로 200',
        fullAdr: '부산광역시 사상구 새벽로 200 (괘법동)',
        SEC_CD: '26530104',
        sagoLon: '128.9841',
        sagoLat: '35.1589',
        sinkWidth: '1',
        sinkExtend: '3',
        sinkDepth: '0.15',
        grdKind: '',
        sagoDetail: '측구 주변부 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-08-11'
      },
      geometry: { type: 'Point', coordinates: [128.9841, 35.1589] }
    },
    {
      type: 'Feature',
      id: 477,
      properties: {
        sagoDate: '2019-08-14',
        sagoNo: '20190151',
        sido: '부산광역시',
        sigungu: '서구',
        dong: '서대신동2가',
        addr: '543 일원',
        fullAdr: '부산광역시 서구 서대신동1가 2-3',
        SEC_CD: '26140104',
        sagoLon: '129.0189',
        sagoLat: '35.1085',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1.7',
        grdKind: '',
        sagoDetail: '노후(폐)하수도관 토사유입으로 인한 함몰 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-09-29'
      },
      geometry: { type: 'Point', coordinates: [129.0189, 35.1085] }
    },
    {
      type: 'Feature',
      id: 478,
      properties: {
        sagoDate: '2019-08-13',
        sagoNo: '20190166',
        sido: '경기도',
        sigungu: '부천시',
        dong: '',
        addr: '중동 1058-4',
        fullAdr: '경기도 부천시 원미구 중동 1058-4',
        SEC_CD: '41192108',
        sagoLon: '126.7738',
        sagoLat: '37.5039',
        sinkWidth: '1',
        sinkExtend: '1.2',
        sinkDepth: '0.7',
        grdKind: '',
        sagoDetail:
          '굴착결과 특이사항 없었음. 건물 앞 기초부분 밑으로 유실된것으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-08-15'
      },
      geometry: { type: 'Point', coordinates: [126.7738, 37.5039] }
    },
    {
      type: 'Feature',
      id: 479,
      properties: {
        sagoDate: '2019-08-22',
        sagoNo: '20190167',
        sido: '경기도',
        sigungu: '고양시 덕양구',
        dong: '',
        addr: '화전동 274-3',
        fullAdr: '경기도 고양시 덕양구 화전동 274-3',
        SEC_CD: '41281129',
        sagoLon: '126.8711',
        sagoLat: '37.6045',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '도로다짐 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-08-24'
      },
      geometry: { type: 'Point', coordinates: [126.8711, 37.6045] }
    },
    {
      type: 'Feature',
      id: 480,
      properties: {
        sagoDate: '2019-08-19',
        sagoNo: '20190181',
        sido: '경상남도',
        sigungu: '창원시 의창구',
        dong: '',
        addr: '봉곡동 36-4· 황제빌딩 지하주차장 입구',
        fullAdr: '경상남도 창원시 의창구 봉곡동 36-4',
        SEC_CD: '48121127',
        sagoLon: '128.6502',
        sagoLat: '35.2433',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '노후 하수관 손상으로 누수 및 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-08-21'
      },
      geometry: { type: 'Point', coordinates: [128.6502, 35.2433] }
    },
    {
      type: 'Feature',
      id: 481,
      properties: {
        sagoDate: '2019-08-05',
        sagoNo: '20190185',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '신가동',
        addr: '918-4번지',
        fullAdr: '광주광역시 광산구 신가동 918-4',
        SEC_CD: '29200119',
        sagoLon: '126.8252',
        sagoLat: '35.1798',
        sinkWidth: '1',
        sinkExtend: '1.2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관로 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-08-07'
      },
      geometry: { type: 'Point', coordinates: [126.8252, 35.1798] }
    },
    {
      type: 'Feature',
      id: 482,
      properties: {
        sagoDate: '2019-08-28',
        sagoNo: '20190186',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '쌍촌동',
        addr: '807-9번지',
        fullAdr: '광주광역시 서구 쌍촌동 807-9',
        SEC_CD: '29140118',
        sagoLon: '126.8636',
        sagoLat: '35.1662',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수 BOX와 연결된 흄관 접합부 이탈로 침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-08-30'
      },
      geometry: { type: 'Point', coordinates: [126.8636, 35.1662] }
    },
    {
      type: 'Feature',
      id: 483,
      properties: {
        sagoDate: '2019-08-01',
        sagoNo: '20190194',
        sido: '서울특별시',
        sigungu: '중구',
        dong: '',
        addr: '무교로 32 효령빌딩 앞',
        fullAdr: '서울특별시 중구 무교로 32 (무교동)',
        SEC_CD: '11140101',
        sagoLon: '126.9796',
        sagoLat: '37.5688',
        sinkWidth: '1',
        sinkExtend: '4',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '노후하수관-하수맨홀 연결부 파손으로 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-08-03'
      },
      geometry: { type: 'Point', coordinates: [126.9796, 37.5688] }
    },
    {
      type: 'Feature',
      id: 484,
      properties: {
        sagoDate: '2019-08-04',
        sagoNo: '20190195',
        sido: '서울특별시',
        sigungu: '성동구',
        dong: '',
        addr: ' 왕십리2동975-1 (새마을금고앞)',
        fullAdr: '서울특별시 성동구 하왕십리동 975-1',
        SEC_CD: '11200102',
        sagoLon: '127.0312',
        sagoLat: '37.5616',
        sinkWidth: '4',
        sinkExtend: '4',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail:
          '노후하수관(900mm) 개량공사 중 집중호우(35mm/hr)로 임시 연결부 이격으로 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-08-06'
      },
      geometry: { type: 'Point', coordinates: [127.0312, 37.5616] }
    },
    {
      type: 'Feature',
      id: 485,
      properties: {
        sagoDate: '2019-08-06',
        sagoNo: '20190196',
        sido: '서울특별시',
        sigungu: '성동구',
        dong: '마장동',
        addr: ' 785-3호(내부순환도로 사근램프 근처)',
        fullAdr: '서울특별시 성동구 마장동 785-3',
        SEC_CD: '11200105',
        sagoLon: '127.0468',
        sagoLat: '37.5653',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '0.4',
        grdKind: '',
        sagoDetail: '노후상수도관(150mm) 부식에 의한 상수도누수로 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-08-08'
      },
      geometry: { type: 'Point', coordinates: [127.0468, 37.5653] }
    },
    {
      type: 'Feature',
      id: 486,
      properties: {
        sagoDate: '2019-08-12',
        sagoNo: '20190197',
        sido: '서울특별시',
        sigungu: '성동구',
        dong: '사근동',
        addr: '293-4 앞',
        fullAdr: '서울특별시 성동구 사근동 293-4',
        SEC_CD: '11200106',
        sagoLon: '127.047',
        sagoLat: '37.5652',
        sinkWidth: '0.3',
        sinkExtend: '0.8',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '기존 하수관로 미철거구간 노후 및 손괴',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-08-14'
      },
      geometry: { type: 'Point', coordinates: [127.047, 37.5652] }
    },
    {
      type: 'Feature',
      id: 487,
      properties: {
        sagoDate: '2019-08-29',
        sagoNo: '20190206',
        sido: '인천광역시',
        sigungu: '계양구',
        dong: '',
        addr: '계양대로 170 경인교대입구역 1번출구 앞 도로',
        fullAdr: '인천광역시 계양구 계양대로 170 (계산동)',
        SEC_CD: '28245102',
        sagoLon: '126.7229',
        sagoLat: '37.5391',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail:
          '기존 기층부 동공이 8.29. 집중호우로 인해 확대됨에 따라 침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-08-31'
      },
      geometry: { type: 'Point', coordinates: [126.7229, 37.5391] }
    },
    {
      type: 'Feature',
      id: 488,
      properties: {
        sagoDate: '2019-08-02',
        sagoNo: '20190211',
        sido: '전북특별자치도',
        sigungu: '군산시',
        dong: '',
        addr: '한밭3길5',
        fullAdr: '전북특별자치도 군산시 한밭3길 5 (나운동)',
        SEC_CD: '52130144',
        sagoLon: '126.6951',
        sagoLat: '35.9621',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관거 파손으로 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-08-04'
      },
      geometry: { type: 'Point', coordinates: [126.6951, 35.9621] }
    },
    {
      type: 'Feature',
      id: 489,
      properties: {
        sagoDate: '2019-09-16',
        sagoNo: '20190143',
        sido: '대전광역시',
        sigungu: '중구',
        dong: '',
        addr: '문화초교4가-유천4가 공주얼큰이칼국수 앞',
        fullAdr: '대전광역시 중구 보문산로171번길 40-4 (문화동)',
        SEC_CD: '30140116',
        sagoLon: '127.3992',
        sagoLat: '36.3075',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-09-17'
      },
      geometry: { type: 'Point', coordinates: [127.3992, 36.3075] }
    },
    {
      type: 'Feature',
      id: 490,
      properties: {
        sagoDate: '2019-09-11',
        sagoNo: '20190168',
        sido: '경기도',
        sigungu: '의정부시',
        dong: '',
        addr: '효암로 23번길 14',
        fullAdr: '경기도 의정부시 호암로 21 (호원동)',
        SEC_CD: '41150102',
        sagoLon: '127.0466',
        sagoLat: '37.7026',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-09-13'
      },
      geometry: { type: 'Point', coordinates: [127.0466, 37.7026] }
    },
    {
      type: 'Feature',
      id: 491,
      properties: {
        sagoDate: '2019-09-26',
        sagoNo: '20190169',
        sido: '경기도',
        sigungu: '고양시 일산서구',
        dong: '',
        addr: '일산동 1979',
        fullAdr: '경기도 고양시 일산서구 일산동 1979',
        SEC_CD: '41287101',
        sagoLon: '126.7714',
        sagoLat: '37.6904',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '하수관 파손으로 인한 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-09-28'
      },
      geometry: { type: 'Point', coordinates: [126.7714, 37.6904] }
    },
    {
      type: 'Feature',
      id: 492,
      properties: {
        sagoDate: '2019-09-15',
        sagoNo: '20190192',
        sido: '대구광역시',
        sigungu: '달성군',
        dong: '',
        addr: '대실역 지하철 1번 출구 보도부',
        fullAdr: '대구광역시 달성군 다사읍 달구벌대로 881',
        SEC_CD: '27710256',
        sagoLon: '128.4658',
        sagoLat: '35.8578',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail:
          '다수의 지하매설물 시공으로 인한 되메우기 시 다짐 불량에 따른 느슨한 지반이 형성된 상태로 집중호우로 과다한 우수유입 등으로 유로가 형성되면서 동공이 확장되어 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-09-17'
      },
      geometry: { type: 'Point', coordinates: [128.4658, 35.8578] }
    },
    {
      type: 'Feature',
      id: 493,
      properties: {
        sagoDate: '2019-09-05',
        sagoNo: '20190198',
        sido: '서울특별시',
        sigungu: '영등포구',
        dong: '',
        addr: '국제금융로 24',
        fullAdr: '서울특별시 영등포구 국제금융로 24 (여의도동)',
        SEC_CD: '11560110',
        sagoLon: '126.9275',
        sagoLat: '37.5246',
        sinkWidth: '5',
        sinkExtend: '5',
        sinkDepth: '5',
        grdKind: '',
        sagoDetail:
          '지하보도 출입구 #2 현장 가시설 배면 구간에서 집중호우로 인한 토사가 급격히 현장 내로 유입되면서 보도침하 및 매설관로 침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-09-07'
      },
      geometry: { type: 'Point', coordinates: [126.9275, 37.5246] }
    },
    {
      type: 'Feature',
      id: 494,
      properties: {
        sagoDate: '2019-09-09',
        sagoNo: '20190199',
        sido: '서울특별시',
        sigungu: '서초구',
        dong: '양재동',
        addr: '203-10번지 앞',
        fullAdr: '서울특별시 서초구 양재동 203-10',
        SEC_CD: '11650102',
        sagoLon: '127.0346',
        sagoLat: '37.4663',
        sinkWidth: '1.2',
        sinkExtend: '1.2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관로(D1200) 이음부 이격으로 인한 토사 유실로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-09-11'
      },
      geometry: { type: 'Point', coordinates: [127.0346, 37.4663] }
    },
    {
      type: 'Feature',
      id: 495,
      properties: {
        sagoDate: '2019-09-10',
        sagoNo: '20190200',
        sido: '서울특별시',
        sigungu: '동작구',
        dong: '',
        addr: '상도로 162(편도2차로)',
        fullAdr: '서울특별시 동작구 상도동 185-1',
        SEC_CD: '11590102',
        sagoLon: '126.9373',
        sagoLat: '37.5036',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '굴착원인조사 결과 원인불명(장기침하 추정)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-09-12'
      },
      geometry: { type: 'Point', coordinates: [126.9373, 37.5036] }
    },
    {
      type: 'Feature',
      id: 496,
      properties: {
        sagoDate: '2019-09-15',
        sagoNo: '20190201',
        sido: '서울특별시',
        sigungu: '영등포구',
        dong: '여의도동',
        addr: '23-1',
        fullAdr: '서울특별시 영등포구 여의도동 23-1',
        SEC_CD: '11560110',
        sagoLon: '126.9262',
        sagoLat: '37.5255',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '구조물 주변 다짐불량 등으로 인한 장기 압밀침하 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-09-17'
      },
      geometry: { type: 'Point', coordinates: [126.9262, 37.5255] }
    },
    {
      type: 'Feature',
      id: 497,
      properties: {
        sagoDate: '2019-09-20',
        sagoNo: '20190202',
        sido: '서울특별시',
        sigungu: '종로구',
        dong: '',
        addr: ' 통일로 78· 1차로 경찰청 앞 사거리',
        fullAdr: '서울특별시 중구 통일로 86 (순화동)',
        SEC_CD: '11140169',
        sagoLon: '126.9695',
        sagoLat: '37.5631',
        sinkWidth: '5',
        sinkExtend: '7',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '차량진동에 의한 상수도관 누수로 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-09-22'
      },
      geometry: { type: 'Point', coordinates: [126.9695, 37.5631] }
    },
    {
      type: 'Feature',
      id: 498,
      properties: {
        sagoDate: '2019-09-26',
        sagoNo: '20190208',
        sido: '전라남도',
        sigungu: '신안군',
        dong: '신의면 하태동리',
        addr: '산 195-8',
        fullAdr: '전라남도 신안군 신의면 하태동리 산 195-8',
        SEC_CD: '46910380',
        sagoLon: '126.0668',
        sagoLat: '34.5486',
        sinkWidth: '4',
        sinkExtend: '15',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '포장 하부 누수로 인한 지반 불안정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-09-28'
      },
      geometry: { type: 'Point', coordinates: [126.0668, 34.5486] }
    },
    {
      type: 'Feature',
      id: 499,
      properties: {
        sagoDate: '2019-09-27',
        sagoNo: '20190212',
        sido: '전북특별자치도',
        sigungu: '군산시',
        dong: '소룡동',
        addr: '1589-13',
        fullAdr: '전북특별자치도 군산시 소룡동 1589-13',
        SEC_CD: '52130146',
        sagoLon: '126.5924',
        sagoLat: '35.9693',
        sinkWidth: '3',
        sinkExtend: '3',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관거 파손으로 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-09-29'
      },
      geometry: { type: 'Point', coordinates: [126.5924, 35.9693] }
    },
    {
      type: 'Feature',
      id: 500,
      properties: {
        sagoDate: '2019-10-10',
        sagoNo: '20190144',
        sido: '대전광역시',
        sigungu: '동구',
        dong: '',
        addr: '가오동 374-43번지 앞',
        fullAdr: '대전광역시 동구 가오동 374-43',
        SEC_CD: '30110105',
        sagoLon: '127.455',
        sagoLat: '36.3028',
        sinkWidth: '0.3',
        sinkExtend: '0.2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '기타매설물 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-10-11'
      },
      geometry: { type: 'Point', coordinates: [127.455, 36.3028] }
    },
    {
      type: 'Feature',
      id: 501,
      properties: {
        sagoDate: '2019-10-14',
        sagoNo: '20190145',
        sido: '대전광역시',
        sigungu: '중구',
        dong: '',
        addr: '유천동 200-15',
        fullAdr: '대전광역시 중구 유천동 200-15',
        SEC_CD: '30140115',
        sagoLon: '127.3973',
        sagoLat: '36.3215',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '하수 벽체 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-10-15'
      },
      geometry: { type: 'Point', coordinates: [127.3973, 36.3215] }
    },
    {
      type: 'Feature',
      id: 502,
      properties: {
        sagoDate: '2019-10-24',
        sagoNo: '20190146',
        sido: '대전광역시',
        sigungu: '중구',
        dong: '',
        addr: '문창동 121-1',
        fullAdr: '대전광역시 중구 문창동 121-1',
        SEC_CD: '30140106',
        sagoLon: '127.4368',
        sagoLat: '36.3154',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-10-25'
      },
      geometry: { type: 'Point', coordinates: [127.4368, 36.3154] }
    },
    {
      type: 'Feature',
      id: 503,
      properties: {
        sagoDate: '2019-10-01',
        sagoNo: '20190170',
        sido: '경기도',
        sigungu: '의왕시',
        dong: '포일동',
        addr: '485-5',
        fullAdr: '경기도 의왕시 포일동 485-5',
        SEC_CD: '41430109',
        sagoLon: '126.9857',
        sagoLat: '37.3947',
        sinkWidth: '0.8',
        sinkExtend: '1.4',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '하수관로 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-10-03'
      },
      geometry: { type: 'Point', coordinates: [126.9857, 37.3947] }
    },
    {
      type: 'Feature',
      id: 504,
      properties: {
        sagoDate: '2019-10-17',
        sagoNo: '20190171',
        sido: '경기도',
        sigungu: '양주시',
        dong: '봉양동',
        addr: '311-4',
        fullAdr: '경기도 양주시 봉양동 311-4',
        SEC_CD: '41630111',
        sagoLon: '127.0806',
        sagoLat: '37.8471',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '도시가스관 연결작업후 다짐(되메우기) 불량으로 인하여 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-10-19'
      },
      geometry: { type: 'Point', coordinates: [127.0806, 37.8471] }
    },
    {
      type: 'Feature',
      id: 505,
      properties: {
        sagoDate: '2019-10-17',
        sagoNo: '20190172',
        sido: '경기도',
        sigungu: '양주시',
        dong: '봉양동',
        addr: '340',
        fullAdr: '경기도 양주시 봉양동 340',
        SEC_CD: '41630111',
        sagoLon: '127.0769',
        sagoLat: '37.8486',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '도시가스관 연결작업후 다짐(되메우기) 불량으로 인하여 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-10-19'
      },
      geometry: { type: 'Point', coordinates: [127.0769, 37.8486] }
    },
    {
      type: 'Feature',
      id: 506,
      properties: {
        sagoDate: '2019-10-18',
        sagoNo: '20190173',
        sido: '경기도',
        sigungu: '부천시',
        dong: '',
        addr: '고강동 415-4번지',
        fullAdr: '경기도 부천시 오정구 고강동 415-4',
        SEC_CD: '41196105',
        sagoLon: '126.8249',
        sagoLat: '37.5255',
        sinkWidth: '6',
        sinkExtend: '7',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail: '하수관 손상으로 인하여 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-10-20'
      },
      geometry: { type: 'Point', coordinates: [126.8249, 37.5255] }
    },
    {
      type: 'Feature',
      id: 507,
      properties: {
        sagoDate: '2019-10-03',
        sagoNo: '20190182',
        sido: '경상북도',
        sigungu: '포항시 남구',
        dong: '이동',
        addr: ' 209-3번지',
        fullAdr: '경상북도 포항시 남구 이동 209-3',
        SEC_CD: '47111117',
        sagoLon: '129.3339',
        sagoLat: '36.0337',
        sinkWidth: '4',
        sinkExtend: '5',
        sinkDepth: '3.5',
        grdKind: '',
        sagoDetail: '배수관 이음부 누수로 인한 토사 유출로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-10-05'
      },
      geometry: { type: 'Point', coordinates: [129.3339, 36.0337] }
    },
    {
      type: 'Feature',
      id: 508,
      properties: {
        sagoDate: '2019-10-02',
        sagoNo: '20190187',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '소촌동',
        addr: '826번지',
        fullAdr: '광주광역시 광산구 소촌동 826',
        SEC_CD: '29200108',
        sagoLon: '126.7886',
        sagoLat: '35.1564',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관로 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-10-04'
      },
      geometry: { type: 'Point', coordinates: [126.7886, 35.1564] }
    },
    {
      type: 'Feature',
      id: 509,
      properties: {
        sagoDate: '2019-10-11',
        sagoNo: '20190188',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '오선동',
        addr: '547-26번지',
        fullAdr: '광주광역시 광산구 오선동 547-26',
        SEC_CD: '29200202',
        sagoLon: '126.8078',
        sagoLat: '35.2069',
        sinkWidth: '2',
        sinkExtend: '3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수관로 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-10-13'
      },
      geometry: { type: 'Point', coordinates: [126.8078, 35.2069] }
    },
    {
      type: 'Feature',
      id: 510,
      properties: {
        sagoDate: '2019-10-14',
        sagoNo: '20190189',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '송정동',
        addr: ' 579-4번지',
        fullAdr: '광주광역시 광산구 송정동 579-4',
        SEC_CD: '29200101',
        sagoLon: '126.7985',
        sagoLat: '35.1406',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관로 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-10-16'
      },
      geometry: { type: 'Point', coordinates: [126.7985, 35.1406] }
    },
    {
      type: 'Feature',
      id: 511,
      properties: {
        sagoDate: '2019-10-15',
        sagoNo: '20190190',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '우산동',
        addr: '1582-4번지',
        fullAdr: '광주광역시 광산구 우산동 1582-4',
        SEC_CD: '29200109',
        sagoLon: '126.8079',
        sagoLat: '35.1624',
        sinkWidth: '0.6',
        sinkExtend: '1.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관로 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-10-17'
      },
      geometry: { type: 'Point', coordinates: [126.8079, 35.1624] }
    },
    {
      type: 'Feature',
      id: 512,
      properties: {
        sagoDate: '2019-10-09',
        sagoNo: '20190213',
        sido: '전북특별자치도',
        sigungu: '군산시',
        dong: '',
        addr: '서해로178',
        fullAdr: '전북특별자치도 군산시 동장산로 267 (소룡동)',
        SEC_CD: '52130146',
        sagoLon: '126.5843',
        sagoLat: '35.9725',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관거 파손으로 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-10-11'
      },
      geometry: { type: 'Point', coordinates: [126.5843, 35.9725] }
    },
    {
      type: 'Feature',
      id: 513,
      properties: {
        sagoDate: '2019-10-12',
        sagoNo: '20190214',
        sido: '전북특별자치도',
        sigungu: '군산시',
        dong: '',
        addr: '현충로 8',
        fullAdr: '전북특별자치도 군산시 현충로 8 (나운동)',
        SEC_CD: '52130144',
        sagoLon: '126.6878',
        sagoLat: '35.9564',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관거 파손으로 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-10-14'
      },
      geometry: { type: 'Point', coordinates: [126.6878, 35.9564] }
    },
    {
      type: 'Feature',
      id: 514,
      properties: {
        sagoDate: '2019-10-18',
        sagoNo: '20190216',
        sido: '충청북도',
        sigungu: '청주시 흥덕구',
        dong: '',
        addr: '오송읍 봉산리 25-8',
        fullAdr: '충청북도 청주시 흥덕구 오송읍 봉산리 25-8',
        SEC_CD: '43113250',
        sagoLon: '127.3309',
        sagoLat: '36.6237',
        sinkWidth: '3',
        sinkExtend: '6',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '상수도관 압밀 추진에 따른공극 발생 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '4',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-10-20'
      },
      geometry: { type: 'Point', coordinates: [127.3309, 36.6237] }
    },
    {
      type: 'Feature',
      id: 515,
      properties: {
        sagoDate: '2019-11-05',
        sagoNo: '20190133',
        sido: '제주특별자치도',
        sigungu: '제주시',
        dong: '노형동',
        addr: '도령로 33',
        fullAdr: '제주특별자치도 제주시 도령로 33 (노형동)',
        SEC_CD: '50110122',
        sagoLon: '126.4824',
        sagoLat: '33.4881',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수도관 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-11-06'
      },
      geometry: { type: 'Point', coordinates: [126.4824, 33.4881] }
    },
    {
      type: 'Feature',
      id: 516,
      properties: {
        sagoDate: '2019-11-07',
        sagoNo: '20190134',
        sido: '제주특별자치도',
        sigungu: '제주시',
        dong: '한림읍 옹포리',
        addr: '588-1',
        fullAdr: '제주특별자치도 제주시 한림읍 옹포리 588-1',
        SEC_CD: '50110250',
        sagoLon: '126.2512',
        sagoLat: '33.4022',
        sinkWidth: '0.2',
        sinkExtend: '0.2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '우수관(BOX) 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-11-14'
      },
      geometry: { type: 'Point', coordinates: [126.2512, 33.4022] }
    },
    {
      type: 'Feature',
      id: 517,
      properties: {
        sagoDate: '2019-11-29',
        sagoNo: '20190154',
        sido: '강원특별자치도',
        sigungu: '강릉시',
        dong: '교동',
        addr: '859-25',
        fullAdr: '강원특별자치도 강릉시 교동 859-25',
        SEC_CD: '51150110',
        sagoLon: '128.8886',
        sagoLat: '37.7591',
        sinkWidth: '0.2',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '상수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-12-01'
      },
      geometry: { type: 'Point', coordinates: [128.8886, 37.7591] }
    },
    {
      type: 'Feature',
      id: 518,
      properties: {
        sagoDate: '2019-11-04',
        sagoNo: '20190174',
        sido: '경기도',
        sigungu: '안양시 만안구',
        dong: '',
        addr: '안양동 192-5',
        fullAdr: '경기도 안양시 만안구 안양동 192-5',
        SEC_CD: '41171101',
        sagoLon: '126.9363',
        sagoLat: '37.3915',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '하수관로 연결부위 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-11-06'
      },
      geometry: { type: 'Point', coordinates: [126.9363, 37.3915] }
    },
    {
      type: 'Feature',
      id: 519,
      properties: {
        sagoDate: '2019-11-15',
        sagoNo: '20190175',
        sido: '경기도',
        sigungu: '동두천시',
        dong: '',
        addr: '동두천시경계 ∼ 상패동 980-2',
        fullAdr: '경기도 동두천시 상패동 980-2',
        SEC_CD: '41250112',
        sagoLon: '127.0175',
        sagoLat: '37.9102',
        sinkWidth: '0.6',
        sinkExtend: '620',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '도시가스관 공사후 다짐불량 으로 인한 땅꺼짐',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-11-17'
      },
      geometry: { type: 'Point', coordinates: [127.0175, 37.9102] }
    },
    {
      type: 'Feature',
      id: 520,
      properties: {
        sagoDate: '2019-11-19',
        sagoNo: '20190191',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '송정동',
        addr: '544-37번지',
        fullAdr: '광주광역시 광산구 송정동 544-37',
        SEC_CD: '29200101',
        sagoLon: '126.8008',
        sagoLat: '35.139',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관로 파손에 의한 동공발생 및 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-11-21'
      },
      geometry: { type: 'Point', coordinates: [126.8008, 35.139] }
    },
    {
      type: 'Feature',
      id: 521,
      properties: {
        sagoDate: '2019-11-25',
        sagoNo: '20190203',
        sido: '서울특별시',
        sigungu: '강서구',
        dong: '마곡동',
        addr: '798-7',
        fullAdr: '서울특별시 강서구 마곡동 798-6',
        SEC_CD: '11500105',
        sagoLon: '126.8248',
        sagoLat: '37.5589',
        sinkWidth: '4',
        sinkExtend: '4',
        sinkDepth: '4',
        grdKind: '',
        sagoDetail:
          '건축물로 연결되는 오수관로 분기 접합부 파손에 따른 오수 누수 및 파손부위로 토사가 유출됨에 따라 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-11-27'
      },
      geometry: { type: 'Point', coordinates: [126.8248, 37.5589] }
    },
    {
      type: 'Feature',
      id: 522,
      properties: {
        sagoDate: '2019-11-07',
        sagoNo: '20190209',
        sido: '전라남도',
        sigungu: '신안군',
        dong: '신의면 하태서리',
        addr: '산 202',
        fullAdr: '전라남도 신안군 신의면 하태서리 산 202',
        SEC_CD: '46910380',
        sagoLon: '126.0398',
        sagoLat: '34.5379',
        sinkWidth: '1.5',
        sinkExtend: '3',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '포장 하부 누수로 인한 지반 불안정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-11-09'
      },
      geometry: { type: 'Point', coordinates: [126.0398, 34.5379] }
    },
    {
      type: 'Feature',
      id: 523,
      properties: {
        sagoDate: '2019-11-03',
        sagoNo: '20190215',
        sido: '전북특별자치도',
        sigungu: '정읍시',
        dong: '상동',
        addr: '737',
        fullAdr: '전북특별자치도 정읍시 상동 737',
        SEC_CD: '52180103',
        sagoLon: '126.8658',
        sagoLat: '35.5641',
        sinkWidth: '2',
        sinkExtend: '3.6',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '충정로 지하에 설치된 횡단우수암거 관통 오수관 및 수도관 공사후 접촉면 마감부실로 발생한 구멍으로 수년간 토사유실에 따른 싱크홀 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-11-05'
      },
      geometry: { type: 'Point', coordinates: [126.8658, 35.5641] }
    },
    {
      type: 'Feature',
      id: 524,
      properties: {
        sagoDate: '2019-12-21',
        sagoNo: '20190176',
        sido: '경기도',
        sigungu: '고양시 덕양구',
        dong: '',
        addr: '백석동 1335 일원(백석로)',
        fullAdr: '경기도 고양시 일산동구 백석동 1335',
        SEC_CD: '41285106',
        sagoLon: '126.7846',
        sagoLat: '37.6451',
        sinkWidth: '25',
        sinkExtend: '50',
        sinkDepth: '2.5',
        grdKind: '',
        sagoDetail:
          '건축공사중 흙막이 벽체 내로 외부지하수 유출로 인한 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-12-23'
      },
      geometry: { type: 'Point', coordinates: [126.7846, 37.6451] }
    },
    {
      type: 'Feature',
      id: 525,
      properties: {
        sagoDate: '2019-12-25',
        sagoNo: '20190177',
        sido: '경기도',
        sigungu: '수원시',
        dong: '',
        addr: '우만동 293-12',
        fullAdr: '경기도 수원시 팔달구 우만동 293-12',
        SEC_CD: '41115140',
        sagoLon: '127.0306',
        sagoLat: '37.2902',
        sinkWidth: '0.4',
        sinkExtend: '0.4',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '개인 배수설비 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-12-27'
      },
      geometry: { type: 'Point', coordinates: [127.0306, 37.2902] }
    },
    {
      type: 'Feature',
      id: 526,
      properties: {
        sagoDate: '2019-12-26',
        sagoNo: '20190178',
        sido: '경기도',
        sigungu: '부천시',
        dong: '',
        addr: '중동 1150',
        fullAdr: '경기도 부천시 원미구 중동 1150',
        SEC_CD: '41192108',
        sagoLon: '126.7693',
        sagoLat: '37.5029',
        sinkWidth: '2.5',
        sinkExtend: '2.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '상수도 누수로 인한 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-12-28'
      },
      geometry: { type: 'Point', coordinates: [126.7693, 37.5029] }
    },
    {
      type: 'Feature',
      id: 527,
      properties: {
        sagoDate: '2019-12-26',
        sagoNo: '20190179',
        sido: '경기도',
        sigungu: '이천시',
        dong: '신둔면 수광리',
        addr: ' 716· 산18-13',
        fullAdr: '경기도 이천시 신둔면 수광리 716',
        SEC_CD: '41500310',
        sagoLon: '127.401',
        sagoLat: '37.3109',
        sinkWidth: '1',
        sinkExtend: '5',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '전력관로공사 부실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-12-31'
      },
      geometry: { type: 'Point', coordinates: [127.401, 37.3109] }
    },
    {
      type: 'Feature',
      id: 528,
      properties: {
        sagoDate: '2019-12-22',
        sagoNo: '20190204',
        sido: '서울특별시',
        sigungu: '영등포구',
        dong: '여의도동',
        addr: '25-1',
        fullAdr: '서울특별시 영등포구 여의도동 25-1',
        SEC_CD: '11560110',
        sagoLon: '126.9264',
        sagoLat: '37.5243',
        sinkWidth: '1.5',
        sinkExtend: '2.5',
        sinkDepth: '2.5',
        grdKind: '',
        sagoDetail:
          '상수관로(D100mm)가 파손됨에 따른 누수로 토사유실 및 도로지반 침하 발생(원인 조사 중)',
        deathCnt: '1',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-12-24'
      },
      geometry: { type: 'Point', coordinates: [126.9264, 37.5243] }
    },
    {
      type: 'Feature',
      id: 529,
      properties: {
        sagoDate: '2019-12-16',
        sagoNo: '20190207',
        sido: '인천광역시',
        sigungu: '남동구',
        dong: '구월동',
        addr: '1455(구 롯데백화점) 일원',
        fullAdr: '인천광역시 남동구 구월동 1455',
        SEC_CD: '28200101',
        sagoLon: '126.7016',
        sagoLat: '37.447',
        sinkWidth: '2',
        sinkExtend: '12',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail:
          '개인 배수설비(하수관) 파손 및 다짐불량으로 인한 사유지 도로 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-12-18'
      },
      geometry: { type: 'Point', coordinates: [126.7016, 37.447] }
    },
    {
      type: 'Feature',
      id: 530,
      properties: {
        sagoDate: '2019-12-03',
        sagoNo: '20190217',
        sido: '충청북도',
        sigungu: '청주시 서원구',
        dong: '',
        addr: '수곡동 301-35번지',
        fullAdr: '충청북도 청주시 서원구 수곡동 301-35',
        SEC_CD: '43112107',
        sagoLon: '127.4812',
        sagoLat: '36.6172',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '2.3',
        grdKind: '',
        sagoDetail: '하수관 손상에 의한 주변 토사 세굴',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2019-12-05'
      },
      geometry: { type: 'Point', coordinates: [127.4812, 36.6172] }
    },
    {
      type: 'Feature',
      id: 531,
      properties: {
        sagoDate: '2020-01-30',
        sagoNo: '20200026',
        sido: '경기도',
        sigungu: '의왕시',
        dong: '이동',
        addr: '293',
        fullAdr: '경기도 의왕시 이동 293',
        SEC_CD: '41430102',
        sagoLon: '126.9559',
        sagoLat: '37.3323',
        sinkWidth: '1.5',
        sinkExtend: '2.5',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '제수변 공사 다짐 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-02-01'
      },
      geometry: { type: 'Point', coordinates: [126.9559, 37.3323] }
    },
    {
      type: 'Feature',
      id: 532,
      properties: {
        sagoDate: '2020-01-10',
        sagoNo: '20200032',
        sido: '경상북도',
        sigungu: '경주시',
        dong: '강동면 유금리',
        addr: '1553-2',
        fullAdr: '경상북도 경주시 강동면 유금리 1553-2',
        SEC_CD: '47130370',
        sagoLon: '129.283',
        sagoLat: '36.0028',
        sinkWidth: '0.8',
        sinkExtend: '2.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '압송관로(D80mm) 노후파손으로 인한 토사유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-01-12'
      },
      geometry: { type: 'Point', coordinates: [129.283, 36.0028] }
    },
    {
      type: 'Feature',
      id: 533,
      properties: {
        sagoDate: '2020-01-04',
        sagoNo: '20200035',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '산수동',
        addr: '133-1(박양례 헤어스튜디오 앞)',
        fullAdr: '광주광역시 동구 산수동 133-1',
        SEC_CD: '29110110',
        sagoLon: '126.9328',
        sagoLat: '35.1606',
        sinkWidth: '0.1',
        sinkExtend: '0.5',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '하수관로 부식으로 인한 상부 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-01-06'
      },
      geometry: { type: 'Point', coordinates: [126.9328, 35.1606] }
    },
    {
      type: 'Feature',
      id: 534,
      properties: {
        sagoDate: '2020-01-23',
        sagoNo: '20200038',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '화정동',
        addr: '852-9번지 (염주초 인근)',
        fullAdr: '광주광역시 서구 화정동 852-9',
        SEC_CD: '29140119',
        sagoLon: '126.8779',
        sagoLat: '35.1403',
        sinkWidth: '1.5',
        sinkExtend: '4',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail:
          '하수도관(D600mm)의 심각한 노후화 등으로 인한 1본(2.5m)이 소실되어 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-01-25'
      },
      geometry: { type: 'Point', coordinates: [126.8779, 35.1403] }
    },
    {
      type: 'Feature',
      id: 535,
      properties: {
        sagoDate: '2020-01-07',
        sagoNo: '20200044',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '월곡동',
        addr: '556-6번지 주변',
        fullAdr: '광주광역시 광산구 월곡동 556-6',
        SEC_CD: '29200128',
        sagoLon: '126.8117',
        sagoLat: '35.1694',
        sinkWidth: '0.3',
        sinkExtend: '0.5',
        sinkDepth: '1.8',
        grdKind: '',
        sagoDetail: '관로 노후로 인한 세굴 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-01-09'
      },
      geometry: { type: 'Point', coordinates: [126.8117, 35.1694] }
    },
    {
      type: 'Feature',
      id: 536,
      properties: {
        sagoDate: '2020-01-20',
        sagoNo: '20200045',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '산정동',
        addr: '999번지 주변',
        fullAdr: '광주광역시 광산구 산정동 999',
        SEC_CD: '29200127',
        sagoLon: '126.8033',
        sagoLat: '35.1715',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '다짐 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-01-22'
      },
      geometry: { type: 'Point', coordinates: [126.8033, 35.1715] }
    },
    {
      type: 'Feature',
      id: 537,
      properties: {
        sagoDate: '2020-01-30',
        sagoNo: '20200046',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '신가동',
        addr: '756-4번지 주변',
        fullAdr: '광주광역시 광산구 신가동 756-4',
        SEC_CD: '29200119',
        sagoLon: '126.8298',
        sagoLat: '35.1762',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '관로 노후로 인한 세굴 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-02-01'
      },
      geometry: { type: 'Point', coordinates: [126.8298, 35.1762] }
    },
    {
      type: 'Feature',
      id: 538,
      properties: {
        sagoDate: '2020-01-08',
        sagoNo: '20200073',
        sido: '서울특별시',
        sigungu: '송파구',
        dong: '가락동',
        addr: '122(경찰병원 사거리)',
        fullAdr: '서울특별시 송파구 가락동 122',
        SEC_CD: '11710107',
        sagoLon: '127.1233',
        sagoLat: '37.4945',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '장기간 압밀침하에 따른 지반침하 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-01-08'
      },
      geometry: { type: 'Point', coordinates: [127.1233, 37.4945] }
    },
    {
      type: 'Feature',
      id: 539,
      properties: {
        sagoDate: '2020-02-21',
        sagoNo: '20200014',
        sido: '경기도',
        sigungu: '구리시',
        dong: '수택동',
        addr: '863번지',
        fullAdr: '경기도 구리시 수택동 863',
        SEC_CD: '41310105',
        sagoLon: '127.1413',
        sagoLat: '37.5915',
        sinkWidth: '0.15',
        sinkExtend: '0.2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관로 손상에 의한 토사유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-02-23'
      },
      geometry: { type: 'Point', coordinates: [127.1413, 37.5915] }
    },
    {
      type: 'Feature',
      id: 540,
      properties: {
        sagoDate: '2020-02-11',
        sagoNo: '20200019',
        sido: '경기도',
        sigungu: '화성시',
        dong: '기산동',
        addr: '106-9',
        fullAdr: '경기도 화성시 기산동 106-9',
        SEC_CD: '41590119',
        sagoLon: '127.0525',
        sagoLat: '37.2145',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '기타(하자보수 기간 후· 자연 침하)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-02-13'
      },
      geometry: { type: 'Point', coordinates: [127.0525, 37.2145] }
    },
    {
      type: 'Feature',
      id: 541,
      properties: {
        sagoDate: '2020-02-11',
        sagoNo: '20200027',
        sido: '경기도',
        sigungu: '의왕시',
        dong: '왕곡동',
        addr: '새능교 앞',
        fullAdr: '경기도 의왕시 왕곡동 158-1',
        SEC_CD: '41430104',
        sagoLon: '126.9883',
        sagoLat: '37.3449',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '우주맨홀 주변 다짐 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-02-13'
      },
      geometry: { type: 'Point', coordinates: [126.9883, 37.3449] }
    },
    {
      type: 'Feature',
      id: 542,
      properties: {
        sagoDate: '2020-02-28',
        sagoNo: '20200030',
        sido: '경상남도',
        sigungu: '양산시',
        dong: '중부동',
        addr: '402번지 일원 도로(지방도 1077호선 동구간)',
        fullAdr: '경상남도 양산시 중부동 402',
        SEC_CD: '48330103',
        sagoLon: '129.0352',
        sagoLat: '35.3434',
        sinkWidth: '15',
        sinkExtend: '50',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail:
          '사고지역 바로 옆 양산금호리첸시아 신축공사 중 시공과실로 인한 차수벽 토사유출로 차수벽 부 도로구간 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-03-01'
      },
      geometry: { type: 'Point', coordinates: [129.0352, 35.3434] }
    },
    {
      type: 'Feature',
      id: 543,
      properties: {
        sagoDate: '2020-02-14',
        sagoNo: '20200031',
        sido: '경상북도',
        sigungu: '포항시 남구',
        dong: '이동',
        addr: '524-2',
        fullAdr: '경상북도 포항시 남구 이동 524-2',
        SEC_CD: '47111117',
        sagoLon: '129.3316',
        sagoLat: '36.037',
        sinkWidth: '4',
        sinkExtend: '5',
        sinkDepth: '4',
        grdKind: '',
        sagoDetail: '파형강관 노후화로 인한 손상으로 토사 유실 및 싱크홀 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-02-16'
      },
      geometry: { type: 'Point', coordinates: [129.3316, 36.037] }
    },
    {
      type: 'Feature',
      id: 544,
      properties: {
        sagoDate: '2020-02-15',
        sagoNo: '20200033',
        sido: '경상북도',
        sigungu: '경주시',
        dong: '황오동',
        addr: '110-9(원화로 230-1)',
        fullAdr: '경상북도 경주시 황오동 110-9',
        SEC_CD: '47130105',
        sagoLon: '129.2177',
        sagoLat: '35.8411',
        sinkWidth: '1.3',
        sinkExtend: '3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '우수관로(D1000mm) 노후파손으로 인한 토사유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-02-17'
      },
      geometry: { type: 'Point', coordinates: [129.2177, 35.8411] }
    },
    {
      type: 'Feature',
      id: 545,
      properties: {
        sagoDate: '2020-02-28',
        sagoNo: '20200034',
        sido: '경상북도',
        sigungu: '경주시',
        dong: '산내면 대현리',
        addr: '2653-6(산내면 문복로 397-101)',
        fullAdr: '경상북도 경주시 산내면 대현리 2653-6',
        SEC_CD: '47130340',
        sagoLon: '129.0498',
        sagoLat: '35.667',
        sinkWidth: '0.5',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '우수관로(D300mm) 노후파손으로 인한 토사유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-03-01'
      },
      geometry: { type: 'Point', coordinates: [129.0498, 35.667] }
    },
    {
      type: 'Feature',
      id: 546,
      properties: {
        sagoDate: '2020-02-29',
        sagoNo: '20200065',
        sido: '부산광역시',
        sigungu: '강서구',
        dong: '송정동',
        addr: '1627-5번지 일원(녹지구간· 보도 및 도로 1차선)',
        fullAdr: '부산광역시 강서구 송정동 1627-5',
        SEC_CD: '26440109',
        sagoLon: '128.8533',
        sagoLat: '35.0895',
        sinkWidth: '8',
        sinkExtend: '30',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail:
          '주변 건축공사(삼정그린코아 더시티) 현장 내 흙막이 누수로 인한 인접 지반 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-03-20'
      },
      geometry: { type: 'Point', coordinates: [128.8533, 35.0895] }
    },
    {
      type: 'Feature',
      id: 547,
      properties: {
        sagoDate: '2020-02-22',
        sagoNo: '20200074',
        sido: '서울특별시',
        sigungu: '강동구',
        dong: '고덕동',
        addr: '217(고덕그라시움 101동 앞)',
        fullAdr: '서울특별시 강동구 고덕동 693',
        SEC_CD: '11740102',
        sagoLon: '127.1635',
        sagoLat: '37.5581',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '하수도 맨홀 구체 및 오수관 접속부 시공불량으로 토사 유실',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-03-06'
      },
      geometry: { type: 'Point', coordinates: [127.1635, 37.5581] }
    },
    {
      type: 'Feature',
      id: 548,
      properties: {
        sagoDate: '2020-02-18',
        sagoNo: '20200081',
        sido: '인천광역시',
        sigungu: '계양구',
        dong: '계산동',
        addr: '1034(계양대로 161-2)',
        fullAdr: '인천광역시 계양구 계산동 1034',
        SEC_CD: '28245102',
        sagoLon: '126.7226',
        sagoLat: '37.5381',
        sinkWidth: '4',
        sinkExtend: '4',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '상수도관(D=600mm) 누수로 인한 도로침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-07-04'
      },
      geometry: { type: 'Point', coordinates: [126.7226, 37.5381] }
    },
    {
      type: 'Feature',
      id: 549,
      properties: {
        sagoDate: '2020-02-18',
        sagoNo: '20200082',
        sido: '인천광역시',
        sigungu: '계양구',
        dong: '계산동',
        addr: '973-18 청운교회 앞 도로',
        fullAdr: '인천광역시 계양구 계산동 973-18',
        SEC_CD: '28245102',
        sagoLon: '126.7222',
        sagoLat: '37.5389',
        sinkWidth: '5',
        sinkExtend: '6',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '상수도 누수(관 파열)로 인한 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-02-19'
      },
      geometry: { type: 'Point', coordinates: [126.7222, 37.5389] }
    },
    {
      type: 'Feature',
      id: 550,
      properties: {
        sagoDate: '2020-02-20',
        sagoNo: '20200086',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '',
        addr: '전룡로 65',
        fullAdr: '전북특별자치도 전주시 완산구 전룡로 65 (서신동·우성아파트)',
        SEC_CD: '52111129',
        sagoLon: '127.1196',
        sagoLat: '35.8279',
        sinkWidth: '3.8',
        sinkExtend: '5',
        sinkDepth: '0.8',
        grdKind: '',
        sagoDetail: '관로 주변 노후화로 인한 토사유실로 판단됨',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-02-20'
      },
      geometry: { type: 'Point', coordinates: [127.1196, 35.8279] }
    },
    {
      type: 'Feature',
      id: 551,
      properties: {
        sagoDate: '2020-03-03',
        sagoNo: '20200016',
        sido: '경기도',
        sigungu: '과천시',
        dong: '과천동',
        addr: '539-3',
        fullAdr: '경기도 과천시 과천동 539-3',
        SEC_CD: '41290105',
        sagoLon: '126.9959',
        sagoLat: '37.4466',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1.2',
        grdKind: '',
        sagoDetail: '하단부 하천박스 누수',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-03-05'
      },
      geometry: { type: 'Point', coordinates: [126.9959, 37.4466] }
    },
    {
      type: 'Feature',
      id: 552,
      properties: {
        sagoDate: '2020-03-18',
        sagoNo: '20200020',
        sido: '경기도',
        sigungu: '화성시',
        dong: '정남면 발산리',
        addr: '726-5',
        fullAdr: '경기도 화성시 정남면 발산리 726-5',
        SEC_CD: '41590410',
        sagoLon: '126.9836',
        sagoLat: '37.1565',
        sinkWidth: '1',
        sinkExtend: '1.2',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '기타(하자보수 기간 후·자연 침하)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-03-20'
      },
      geometry: { type: 'Point', coordinates: [126.9836, 37.1565] }
    },
    {
      type: 'Feature',
      id: 553,
      properties: {
        sagoDate: '2020-03-20',
        sagoNo: '20200021',
        sido: '경기도',
        sigungu: '화성시',
        dong: '장안면 노진리',
        addr: '산 48-13',
        fullAdr: '경기도 화성시 장안면 노진리 산 48-13',
        SEC_CD: '41590370',
        sagoLon: '126.8109',
        sagoLat: '37.0507',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '기타(하자보수 기간 후· 자연 침하)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-03-22'
      },
      geometry: { type: 'Point', coordinates: [126.8109, 37.0507] }
    },
    {
      type: 'Feature',
      id: 554,
      properties: {
        sagoDate: '2020-03-09',
        sagoNo: '20200028',
        sido: '경기도',
        sigungu: '의왕시',
        dong: '',
        addr: '철도박물관로 223',
        fullAdr: '경기도 의왕시 철도박물관로 223 (월암동)',
        SEC_CD: '41430110',
        sagoLon: '126.9574',
        sagoLat: '37.3135',
        sinkWidth: '1',
        sinkExtend: '1.2',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '기타매설공사 다짐 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-03-11'
      },
      geometry: { type: 'Point', coordinates: [126.9574, 37.3135] }
    },
    {
      type: 'Feature',
      id: 555,
      properties: {
        sagoDate: '2020-03-26',
        sagoNo: '20200029',
        sido: '경기도',
        sigungu: '의왕시',
        dong: '월암동',
        addr: '295',
        fullAdr: '경기도 의왕시 월암동 295',
        SEC_CD: '41430110',
        sagoLon: '126.9562',
        sagoLat: '37.3065',
        sinkWidth: '0.3',
        sinkExtend: '4',
        sinkDepth: '0.03',
        grdKind: '',
        sagoDetail: '우수관로 노후',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-03-28'
      },
      geometry: { type: 'Point', coordinates: [126.9562, 37.3065] }
    },
    {
      type: 'Feature',
      id: 556,
      properties: {
        sagoDate: '2020-03-30',
        sagoNo: '20200036',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '대인동',
        addr: '32-20',
        fullAdr: '광주광역시 동구 대인동 32-20',
        SEC_CD: '29110101',
        sagoLon: '126.9151',
        sagoLat: '35.1538',
        sinkWidth: '0.05',
        sinkExtend: '0.05',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail:
          '콘크리트관(D450) 균열 및 부식으로 인해 하수관 파손 및 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-27'
      },
      geometry: { type: 'Point', coordinates: [126.9151, 35.1538] }
    },
    {
      type: 'Feature',
      id: 557,
      properties: {
        sagoDate: '2020-03-25',
        sagoNo: '20200062',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '월평동',
        addr: '1364번지',
        fullAdr: '대전광역시 서구 월평동 1364',
        SEC_CD: '30170113',
        sagoLon: '127.3535',
        sagoLat: '36.3533',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관로 파손에 따른 도로침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-03-27'
      },
      geometry: { type: 'Point', coordinates: [127.3535, 36.3533] }
    },
    {
      type: 'Feature',
      id: 558,
      properties: {
        sagoDate: '2020-03-13',
        sagoNo: '20200066',
        sido: '부산광역시',
        sigungu: '사하구',
        dong: '',
        addr: '다대로 52 일원',
        fullAdr: '부산광역시 사하구 다대로 52 (당리동)',
        SEC_CD: '26380101',
        sagoLon: '128.9773',
        sagoLat: '35.0978',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '0.9',
        grdKind: '',
        sagoDetail: '지반상태가 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-03-31'
      },
      geometry: { type: 'Point', coordinates: [128.9773, 35.0978] }
    },
    {
      type: 'Feature',
      id: 559,
      properties: {
        sagoDate: '2020-03-02',
        sagoNo: '20200075',
        sido: '서울특별시',
        sigungu: '강서구',
        dong: '',
        addr: '화곡본동 105-61(까치산로 22)',
        fullAdr: '서울특별시 강서구 화곡동 105-61',
        SEC_CD: '11500103',
        sagoLon: '126.8431',
        sagoLat: '37.5419',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '노후 하수관 접속부 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-05-12'
      },
      geometry: { type: 'Point', coordinates: [126.8431, 37.5419] }
    },
    {
      type: 'Feature',
      id: 560,
      properties: {
        sagoDate: '2020-03-11',
        sagoNo: '20200076',
        sido: '서울특별시',
        sigungu: '강남구',
        dong: '일원동',
        addr: '617 앞(영화초교 사거리)',
        fullAdr: '서울특별시 강남구 일원동 617',
        SEC_CD: '11680114',
        sagoLon: '127.0813',
        sagoLat: '37.4919',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '상수도관 누수에 따른 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-03-13'
      },
      geometry: { type: 'Point', coordinates: [127.0813, 37.4919] }
    },
    {
      type: 'Feature',
      id: 561,
      properties: {
        sagoDate: '2020-03-10',
        sagoNo: '20200083',
        sido: '인천광역시',
        sigungu: '중구',
        dong: '북성동1가',
        addr: '98-54(월미로233번길 3)',
        fullAdr: '인천광역시 중구 북성동1가 1-1',
        SEC_CD: '28110138',
        sagoLon: '126.6191',
        sagoLat: '37.4819',
        sinkWidth: '1.2',
        sinkExtend: '1.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관로(D600mm) 파손으로 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-03-15'
      },
      geometry: { type: 'Point', coordinates: [126.6191, 37.4819] }
    },
    {
      type: 'Feature',
      id: 562,
      properties: {
        sagoDate: '2020-03-11',
        sagoNo: '20200089',
        sido: '충청북도',
        sigungu: '청주시 흥덕구',
        dong: '',
        addr: '운천동 1493',
        fullAdr: '충청북도 청주시 흥덕구 운천동 1493',
        SEC_CD: '43113104',
        sagoLon: '127.4778',
        sagoLat: '36.6486',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '접합부 마감 불량 및 다짐 불량에 따른 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-03-23'
      },
      geometry: { type: 'Point', coordinates: [127.4778, 36.6486] }
    },
    {
      type: 'Feature',
      id: 563,
      properties: {
        sagoDate: '2020-03-18',
        sagoNo: '20200094',
        sido: '부산광역시',
        sigungu: '사상구',
        dong: '삼락동',
        addr: '29-61',
        fullAdr: '부산광역시 사상구 삼락동 29-61',
        SEC_CD: '26530101',
        sagoLon: '128.9671',
        sagoLat: '35.1593',
        sinkWidth: '50',
        sinkExtend: '50',
        sinkDepth: '10',
        grdKind: '',
        sagoDetail: '한국지반공학회 원인 조사중',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-03-30'
      },
      geometry: { type: 'Point', coordinates: [128.9671, 35.1593] }
    },
    {
      type: 'Feature',
      id: 564,
      properties: {
        sagoDate: '2020-04-21',
        sagoNo: '20200006',
        sido: '강원특별자치도',
        sigungu: '강릉시',
        dong: '송정동',
        addr: '187-6(팔송길 6 앞)',
        fullAdr: '강원특별자치도 강릉시 송정동 187-6',
        SEC_CD: '51150114',
        sagoLon: '128.9329',
        sagoLat: '37.775',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '다짐 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-04-23'
      },
      geometry: { type: 'Point', coordinates: [128.9329, 37.775] }
    },
    {
      type: 'Feature',
      id: 565,
      properties: {
        sagoDate: '2020-04-25',
        sagoNo: '20200022',
        sido: '경기도',
        sigungu: '화성시',
        dong: '장안면 덕다리',
        addr: '99',
        fullAdr: '경기도 화성시 장안면 덕다리 99',
        SEC_CD: '41590370',
        sagoLon: '126.841',
        sagoLat: '37.0581',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '기타(하자보수기간 후· 자연 침하)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-04-27'
      },
      geometry: { type: 'Point', coordinates: [126.841, 37.0581] }
    },
    {
      type: 'Feature',
      id: 566,
      properties: {
        sagoDate: '2020-04-14',
        sagoNo: '20200025',
        sido: '경기도',
        sigungu: '부천시',
        dong: '',
        addr: '괴안동 167',
        fullAdr: '경기도 부천시 소사구 괴안동 167',
        SEC_CD: '41194104',
        sagoLon: '126.8156',
        sagoLat: '37.4847',
        sinkWidth: '0.5',
        sinkExtend: '20',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail:
          '대규모 건축공사장 터파기 시 CIP를 설치하지 않는 일부구간에 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-04-16'
      },
      geometry: { type: 'Point', coordinates: [126.8156, 37.4847] }
    },
    {
      type: 'Feature',
      id: 567,
      properties: {
        sagoDate: '2020-04-26',
        sagoNo: '20200039',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '쌍촌동',
        addr: '807-9번지',
        fullAdr: '광주광역시 서구 쌍촌동 807-9',
        SEC_CD: '29140118',
        sagoLon: '126.8636',
        sagoLat: '35.1662',
        sinkWidth: '1',
        sinkExtend: '2.5',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '폐공시킨 관이 잘못 연결되어 있어 토사유실 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-04-28'
      },
      geometry: { type: 'Point', coordinates: [126.8636, 35.1662] }
    },
    {
      type: 'Feature',
      id: 568,
      properties: {
        sagoDate: '2020-04-01',
        sagoNo: '20200049',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '도마동',
        addr: '67-5 (기아자동차 앞)',
        fullAdr: '대전광역시 서구 도마동 67-5',
        SEC_CD: '30170103',
        sagoLon: '127.3775',
        sagoLat: '36.3207',
        sinkWidth: '0.8',
        sinkExtend: '1',
        sinkDepth: '1.2',
        grdKind: '',
        sagoDetail:
          '우수박스와 하수관접합부 마감불량 및 오수관 이탈로 인한 장기간 토사유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-04-03'
      },
      geometry: { type: 'Point', coordinates: [127.3775, 36.3207] }
    },
    {
      type: 'Feature',
      id: 569,
      properties: {
        sagoDate: '2020-04-16',
        sagoNo: '20200050',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '관저동',
        addr: '1914(롯데시네마 앞)',
        fullAdr: '대전광역시 서구 관저동 1914',
        SEC_CD: '30170116',
        sagoLon: '127.3349',
        sagoLat: '36.3046',
        sinkWidth: '1.2',
        sinkExtend: '1.2',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail:
          '우수박스와 하수관접합부 마감불량 및 오수관 이탈로 인한 장기간 토사유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-04-18'
      },
      geometry: { type: 'Point', coordinates: [127.3349, 36.3046] }
    },
    {
      type: 'Feature',
      id: 570,
      properties: {
        sagoDate: '2020-04-20',
        sagoNo: '20200051',
        sido: '대전광역시',
        sigungu: '동구',
        dong: '삼성동',
        addr: '375-28 (영광세탁 앞)',
        fullAdr: '대전광역시 동구 삼성동 375-28',
        SEC_CD: '30110118',
        sagoLon: '127.419',
        sagoLat: '36.3427',
        sinkWidth: '0.4',
        sinkExtend: '0.5',
        sinkDepth: '1.2',
        grdKind: '',
        sagoDetail: '다짐(되메우기) 불량으로 인한 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-04-22'
      },
      geometry: { type: 'Point', coordinates: [127.419, 36.3427] }
    },
    {
      type: 'Feature',
      id: 571,
      properties: {
        sagoDate: '2020-04-29',
        sagoNo: '20200052',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '도마동',
        addr: '24-7 (더본한의원 앞)',
        fullAdr: '대전광역시 서구 도마동 24-7',
        SEC_CD: '30170103',
        sagoLon: '127.3706',
        sagoLat: '36.3241',
        sinkWidth: '0.3',
        sinkExtend: '0.6',
        sinkDepth: '1.4',
        grdKind: '',
        sagoDetail:
          '우수박스와 하수관접합부 마감불량 및 오수관 이탈로 인한 장기간 토사유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-05-01'
      },
      geometry: { type: 'Point', coordinates: [127.3706, 36.3241] }
    },
    {
      type: 'Feature',
      id: 572,
      properties: {
        sagoDate: '2020-04-16',
        sagoNo: '20200060',
        sido: '대전광역시',
        sigungu: '유성구',
        dong: '노은동',
        addr: '499-7',
        fullAdr: '대전광역시 유성구 노은동 499-7',
        SEC_CD: '30200119',
        sagoLon: '127.3125',
        sagoLat: '36.3675',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '우수관로 주변 토사유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-04-18'
      },
      geometry: { type: 'Point', coordinates: [127.3125, 36.3675] }
    },
    {
      type: 'Feature',
      id: 573,
      properties: {
        sagoDate: '2020-04-16',
        sagoNo: '20200063',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '',
        addr: '도안대로 동우재네거리 방향 약 100m',
        fullAdr: '대전광역시 서구 도안대로 11 (관저동)',
        SEC_CD: '30170116',
        sagoLon: '127.3344',
        sagoLat: '36.3027',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관로 파손에 따른 도로침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-04-18'
      },
      geometry: { type: 'Point', coordinates: [127.3344, 36.3027] }
    },
    {
      type: 'Feature',
      id: 574,
      properties: {
        sagoDate: '2020-04-28',
        sagoNo: '20200067',
        sido: '부산광역시',
        sigungu: '사상구',
        dong: '',
        addr: '광장로 56',
        fullAdr: '부산광역시 사상구 광장로 56 (괘법동)',
        SEC_CD: '26530104',
        sagoLon: '128.9828',
        sagoLat: '35.1619',
        sinkWidth: '4',
        sinkExtend: '4',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '원인 불명확',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '2',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-05-03'
      },
      geometry: { type: 'Point', coordinates: [128.9828, 35.1619] }
    },
    {
      type: 'Feature',
      id: 575,
      properties: {
        sagoDate: '2020-05-12',
        sagoNo: '20200003',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '',
        addr: '일산초교길67',
        fullAdr: '강원특별자치도 원주시 일산초교길 67 (일산동)',
        SEC_CD: '51130108',
        sagoLon: '127.9439',
        sagoLat: '37.3493',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '우수관로(450mm) 파손으로 인한 도로침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-05-14'
      },
      geometry: { type: 'Point', coordinates: [127.9439, 37.3493] }
    },
    {
      type: 'Feature',
      id: 576,
      properties: {
        sagoDate: '2020-05-01',
        sagoNo: '20200005',
        sido: '강원특별자치도',
        sigungu: '강릉시',
        dong: '포남동',
        addr: '1197-24(경강로2291번길 10 앞)',
        fullAdr: '강원특별자치도 강릉시 포남동 1197-24',
        SEC_CD: '51150111',
        sagoLon: '128.9121',
        sagoLat: '37.7683',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '수도관 손상에 따른 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-05-03'
      },
      geometry: { type: 'Point', coordinates: [128.9121, 37.7683] }
    },
    {
      type: 'Feature',
      id: 577,
      properties: {
        sagoDate: '2020-05-04',
        sagoNo: '20200007',
        sido: '강원특별자치도',
        sigungu: '강릉시',
        dong: '포남동',
        addr: '1197-13(강릉대로 380 앞)',
        fullAdr: '강원특별자치도 강릉시 포남동 1197-13',
        SEC_CD: '51150111',
        sagoLon: '128.906',
        sagoLat: '37.7673',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '다짐 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-05-06'
      },
      geometry: { type: 'Point', coordinates: [128.906, 37.7673] }
    },
    {
      type: 'Feature',
      id: 578,
      properties: {
        sagoDate: '2020-05-25',
        sagoNo: '20200008',
        sido: '강원특별자치도',
        sigungu: '강릉시',
        dong: '포남동',
        addr: '1197-19(경강로2245번길 2-1 앞)',
        fullAdr: '강원특별자치도 강릉시 포남동 1197-19',
        SEC_CD: '51150111',
        sagoLon: '128.9082',
        sagoLat: '37.7656',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '다짐 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-05-27'
      },
      geometry: { type: 'Point', coordinates: [128.9082, 37.7656] }
    },
    {
      type: 'Feature',
      id: 579,
      properties: {
        sagoDate: '2020-05-26',
        sagoNo: '20200009',
        sido: '강원특별자치도',
        sigungu: '강릉시',
        dong: '포남동',
        addr: '1197-19(경강로2255번길 2 앞)',
        fullAdr: '강원특별자치도 강릉시 포남동 1197-19',
        SEC_CD: '51150111',
        sagoLon: '128.9082',
        sagoLat: '37.7656',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '다짐 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-05-28'
      },
      geometry: { type: 'Point', coordinates: [128.9082, 37.7656] }
    },
    {
      type: 'Feature',
      id: 580,
      properties: {
        sagoDate: '2020-05-27',
        sagoNo: '20200013',
        sido: '강원특별자치도',
        sigungu: '삼척시',
        dong: '',
        addr: '봉황로 73(삼척시보건소 사거리 현대아파트B동 앞)',
        fullAdr: '강원특별자치도 삼척시 봉황로 73 (남양동·현대아파트)',
        SEC_CD: '51230120',
        sagoLon: '129.1718',
        sagoLat: '37.4447',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '0.8',
        grdKind: '',
        sagoDetail:
          '차량통행 하중으로 인한 하수관 눌림으로 박스 접합부 공간발생 박스 접합부 공간 발생부로 토사유입· 침하발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-05-29'
      },
      geometry: { type: 'Point', coordinates: [129.1718, 37.4447] }
    },
    {
      type: 'Feature',
      id: 581,
      properties: {
        sagoDate: '2020-05-06',
        sagoNo: '20200017',
        sido: '경기도',
        sigungu: '과천시',
        dong: '문원동',
        addr: '1023-27',
        fullAdr: '경기도 과천시 문원동 1023-27',
        SEC_CD: '41290102',
        sagoLon: '127.0013',
        sagoLat: '37.4297',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1.2',
        grdKind: '',
        sagoDetail: '하수관 파손으로 인한 토사 유출로 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-05-08'
      },
      geometry: { type: 'Point', coordinates: [127.0013, 37.4297] }
    },
    {
      type: 'Feature',
      id: 582,
      properties: {
        sagoDate: '2020-05-25',
        sagoNo: '20200023',
        sido: '경기도',
        sigungu: '화성시',
        dong: '',
        addr: '남양읍 남양리 1865-8',
        fullAdr: '경기도 화성시 남양읍 남양리 1865-8',
        SEC_CD: '41590262',
        sagoLon: '126.8118',
        sagoLat: '37.2063',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '기타(하자보수 기간 후· 자연침하)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-05-27'
      },
      geometry: { type: 'Point', coordinates: [126.8118, 37.2063] }
    },
    {
      type: 'Feature',
      id: 583,
      properties: {
        sagoDate: '2020-05-24',
        sagoNo: '20200048',
        sido: '대구광역시',
        sigungu: '달성군',
        dong: '다사읍 서재리',
        addr: '973(서재중계펌프장 압송관로)',
        fullAdr: '대구광역시 달성군 다사읍 서재리 973',
        SEC_CD: '27710256',
        sagoLon: '128.495',
        sagoLat: '35.8766',
        sinkWidth: '0.3',
        sinkExtend: '0.6',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '하수관로 플랜지 접합부 패킹노후',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-05-26'
      },
      geometry: { type: 'Point', coordinates: [128.495, 35.8766] }
    },
    {
      type: 'Feature',
      id: 584,
      properties: {
        sagoDate: '2020-05-04',
        sagoNo: '20200053',
        sido: '대전광역시',
        sigungu: '유성구',
        dong: '화암동',
        addr: '59-8 (에이팩 정문 앞)',
        fullAdr: '대전광역시 유성구 화암동 59-8',
        SEC_CD: '30200130',
        sagoLon: '127.3767',
        sagoLat: '36.4046',
        sinkWidth: '0.3',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail:
          '우수박스와 하수관접합부 마감불량 및 오수관 이탈로 인한 장기간 토사유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-05-06'
      },
      geometry: { type: 'Point', coordinates: [127.3767, 36.4046] }
    },
    {
      type: 'Feature',
      id: 585,
      properties: {
        sagoDate: '2020-05-08',
        sagoNo: '20200054',
        sido: '대전광역시',
        sigungu: '중구',
        dong: '문화동',
        addr: '676-25(버스정류장 앞 2차로)',
        fullAdr: '대전광역시 중구 문화동 676-25',
        SEC_CD: '30140116',
        sagoLon: '127.3974',
        sagoLat: '36.312',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '우수박스와 하수관접합부 마감불량 및 오수관 이탈로 인한 장기간 토사유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-05-10'
      },
      geometry: { type: 'Point', coordinates: [127.3974, 36.312] }
    },
    {
      type: 'Feature',
      id: 586,
      properties: {
        sagoDate: '2020-05-15',
        sagoNo: '20200055',
        sido: '대전광역시',
        sigungu: '중구',
        dong: '유천동',
        addr: '207-6 (남다른감자탕 앞)',
        fullAdr: '대전광역시 중구 유천동 207-6',
        SEC_CD: '30140115',
        sagoLon: '127.398',
        sagoLat: '36.3188',
        sinkWidth: '0.3',
        sinkExtend: '0.4',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '우수박스와 하수관접합부 마감불량 및 오수관 및 오수관 이탈로 인한 장기간 토사유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-17'
      },
      geometry: { type: 'Point', coordinates: [127.398, 36.3188] }
    },
    {
      type: 'Feature',
      id: 587,
      properties: {
        sagoDate: '2020-05-21',
        sagoNo: '20200056',
        sido: '대전광역시',
        sigungu: '중구',
        dong: '유천동',
        addr: '210-38 (선진약국 앞)',
        fullAdr: '대전광역시 중구 유천동 210-38',
        SEC_CD: '30140115',
        sagoLon: '127.3973',
        sagoLat: '36.3182',
        sinkWidth: '0.5',
        sinkExtend: '0.7',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail:
          '우수박스와 하수관접합부 마감불량 및 오수관 이탈로 인한 장기간 토사유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-05-23'
      },
      geometry: { type: 'Point', coordinates: [127.3973, 36.3182] }
    },
    {
      type: 'Feature',
      id: 588,
      properties: {
        sagoDate: '2020-05-06',
        sagoNo: '20200068',
        sido: '부산광역시',
        sigungu: '영도구',
        dong: '',
        addr: '봉래나루로 234번길 5',
        fullAdr: '부산광역시 영도구 봉래나루로 236 (봉래동3가)',
        SEC_CD: '26200117',
        sagoLon: '129.0456',
        sagoLat: '35.0957',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.8',
        grdKind: '',
        sagoDetail: '하수관 파손으로 인한 토사 유출로 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-05-08'
      },
      geometry: { type: 'Point', coordinates: [129.0456, 35.0957] }
    },
    {
      type: 'Feature',
      id: 589,
      properties: {
        sagoDate: '2020-05-09',
        sagoNo: '20200069',
        sido: '부산광역시',
        sigungu: '북구',
        dong: '구포동',
        addr: '1002-9 인접보도(구명역 사상방면 엘리베이터 입구)',
        fullAdr: '부산광역시 북구 구포동 1002-9',
        SEC_CD: '26320105',
        sagoLon: '128.9995',
        sagoLat: '35.203',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '장기침하 및 강우에 따른 공동 진행 가속화로 인한 보도침하 발생',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-05-10'
      },
      geometry: { type: 'Point', coordinates: [128.9995, 35.203] }
    },
    {
      type: 'Feature',
      id: 590,
      properties: {
        sagoDate: '2020-05-20',
        sagoNo: '20200070',
        sido: '부산광역시',
        sigungu: '남구',
        dong: '',
        addr: '수영로 45 앞',
        fullAdr: '부산광역시 남구 수영로 45 (문현동)',
        SEC_CD: '26290109',
        sagoLon: '129.0729',
        sagoLat: '35.1366',
        sinkWidth: '1',
        sinkExtend: '5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '지하철 2호선 관리 부실 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-05-21'
      },
      geometry: { type: 'Point', coordinates: [129.0729, 35.1366] }
    },
    {
      type: 'Feature',
      id: 591,
      properties: {
        sagoDate: '2020-05-15',
        sagoNo: '20200077',
        sido: '서울특별시',
        sigungu: '송파구',
        dong: '신천동',
        addr: '1(올림픽대로 공항방향 유턴부 하행3교 밑단 50m)',
        fullAdr: '서울특별시 송파구 신천동 1',
        SEC_CD: '11710102',
        sagoLon: '127.0991',
        sagoLat: '37.5219',
        sinkWidth: '1.1',
        sinkExtend: '2.4',
        sinkDepth: '1.1',
        grdKind: '',
        sagoDetail: '중차량 하중 작용으로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-05-15'
      },
      geometry: { type: 'Point', coordinates: [127.0991, 37.5219] }
    },
    {
      type: 'Feature',
      id: 592,
      properties: {
        sagoDate: '2020-05-22',
        sagoNo: '20200078',
        sido: '서울특별시',
        sigungu: '성북구',
        dong: '삼선동1가',
        addr: '22-1(삼선교로 4-1)',
        fullAdr: '서울특별시 성북구 삼선동1가 1',
        SEC_CD: '11290111',
        sagoLon: '127.0044',
        sagoLat: '37.5875',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '굴착복구 장기침하로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-05-22'
      },
      geometry: { type: 'Point', coordinates: [127.0044, 37.5875] }
    },
    {
      type: 'Feature',
      id: 593,
      properties: {
        sagoDate: '2020-05-15',
        sagoNo: '20200079',
        sido: '세종특별자치시',
        sigungu: '',
        dong: '',
        addr: '조치원읍 신안리 83-4',
        fullAdr: '세종특별자치시 조치원읍 신안리 83-4',
        SEC_CD: '36110250',
        sagoLon: '127.2912',
        sagoLat: '36.6194',
        sinkWidth: '0.4',
        sinkExtend: '0.4',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail:
          '강우로 인한 지하수 침투로 지반이 약화되어 지반침하 발생 추정',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-07-07'
      },
      geometry: { type: 'Point', coordinates: [127.2912, 36.6194] }
    },
    {
      type: 'Feature',
      id: 594,
      properties: {
        sagoDate: '2020-05-28',
        sagoNo: '20200084',
        sido: '인천광역시',
        sigungu: '남동구',
        dong: '만수동',
        addr: '만수주공사거리 주변',
        fullAdr: '인천광역시 남동구 백범로 123 (만수동)',
        SEC_CD: '28200103',
        sagoLon: '126.7332',
        sagoLat: '37.4546',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail:
          '과거 지하매설물(하수박스 등) 공사 후 주변 다짐불량으로 도로침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-05-29'
      },
      geometry: { type: 'Point', coordinates: [126.7332, 37.4546] }
    },
    {
      type: 'Feature',
      id: 595,
      properties: {
        sagoDate: '2020-05-08',
        sagoNo: '20200090',
        sido: '충청북도',
        sigungu: '청주시 흥덕구',
        dong: '',
        addr: '비하동 165-13',
        fullAdr: '충청북도 청주시 흥덕구 비하동 165-13',
        SEC_CD: '43113138',
        sagoLon: '127.4241',
        sagoLat: '36.6322',
        sinkWidth: '1.7',
        sinkExtend: '2.3',
        sinkDepth: '1.2',
        grdKind: '',
        sagoDetail: '접합부 마감 불량 및 다짐 불량에 따른 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-05-29'
      },
      geometry: { type: 'Point', coordinates: [127.4241, 36.6322] }
    },
    {
      type: 'Feature',
      id: 596,
      properties: {
        sagoDate: '2020-06-10',
        sagoNo: '20200010',
        sido: '강원특별자치도',
        sigungu: '강릉시',
        dong: '포남동',
        addr: '1197-35(경강로 2263 앞)',
        fullAdr: '강원특별자치도 강릉시 포남동 1197-35',
        SEC_CD: '51150111',
        sagoLon: '128.9069',
        sagoLat: '37.7631',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '수도관 손상에 따른 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-06-12'
      },
      geometry: { type: 'Point', coordinates: [128.9069, 37.7631] }
    },
    {
      type: 'Feature',
      id: 597,
      properties: {
        sagoDate: '2020-06-17',
        sagoNo: '20200011',
        sido: '강원특별자치도',
        sigungu: '강릉시',
        dong: '포남동',
        addr: '1197-16(경강로2255번길 7 앞)',
        fullAdr: '강원특별자치도 강릉시 포남동 1197-16',
        SEC_CD: '51150111',
        sagoLon: '128.9045',
        sagoLat: '37.7656',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '다짐 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-06-19'
      },
      geometry: { type: 'Point', coordinates: [128.9045, 37.7656] }
    },
    {
      type: 'Feature',
      id: 598,
      properties: {
        sagoDate: '2020-06-19',
        sagoNo: '20200012',
        sido: '강원특별자치도',
        sigungu: '강릉시',
        dong: '포남동',
        addr: '1197-23(성덕포남로168번길 10 앞)',
        fullAdr: '강원특별자치도 강릉시 포남동 1197-23',
        SEC_CD: '51150111',
        sagoLon: '128.9093',
        sagoLat: '37.7683',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '다짐 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-06-21'
      },
      geometry: { type: 'Point', coordinates: [128.9093, 37.7683] }
    },
    {
      type: 'Feature',
      id: 599,
      properties: {
        sagoDate: '2020-06-16',
        sagoNo: '20200015',
        sido: '경기도',
        sigungu: '구리시',
        dong: '수택동',
        addr: '584-3',
        fullAdr: '경기도 구리시 수택동 584-3',
        SEC_CD: '41310105',
        sagoLon: '127.1471',
        sagoLat: '37.5946',
        sinkWidth: '0.2',
        sinkExtend: '0.2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수박스 내 상수관로 관통',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-06-18'
      },
      geometry: { type: 'Point', coordinates: [127.1471, 37.5946] }
    },
    {
      type: 'Feature',
      id: 600,
      properties: {
        sagoDate: '2020-06-30',
        sagoNo: '20200018',
        sido: '경기도',
        sigungu: '과천시',
        dong: '별양동',
        addr: '1-9',
        fullAdr: '경기도 과천시 별양동 1-9',
        SEC_CD: '41290109',
        sagoLon: '126.9928',
        sagoLat: '37.4286',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '하수관 파손으로 인한 토사 유출로 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-07-02'
      },
      geometry: { type: 'Point', coordinates: [126.9928, 37.4286] }
    },
    {
      type: 'Feature',
      id: 601,
      properties: {
        sagoDate: '2020-06-04',
        sagoNo: '20200024',
        sido: '경기도',
        sigungu: '화성시',
        dong: '서신면 상안리',
        addr: '9-19인근',
        fullAdr: '경기도 화성시 서신면 상안리 9-19',
        SEC_CD: '41590350',
        sagoLon: '126.7147',
        sagoLat: '37.1911',
        sinkWidth: '1',
        sinkExtend: '1.2',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '다짐(되메우기) 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-06-06'
      },
      geometry: { type: 'Point', coordinates: [126.7147, 37.1911] }
    },
    {
      type: 'Feature',
      id: 602,
      properties: {
        sagoDate: '2020-06-16',
        sagoNo: '20200037',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '학동',
        addr: '738-3',
        fullAdr: '광주광역시 동구 학동 738-3',
        SEC_CD: '29110122',
        sagoLon: '126.9296',
        sagoLat: '35.1328',
        sinkWidth: '0.3',
        sinkExtend: '0.3',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관로 이음부 접합불량으로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-06-18'
      },
      geometry: { type: 'Point', coordinates: [126.9296, 35.1328] }
    },
    {
      type: 'Feature',
      id: 603,
      properties: {
        sagoDate: '2020-06-11',
        sagoNo: '20200040',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '농성동',
        addr: '666-1번지(화정로 259) 인근',
        fullAdr: '광주광역시 서구 농성동 666-1',
        SEC_CD: '29140106',
        sagoLon: '126.8851',
        sagoLat: '35.1482',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail:
          '폐공된 하수관(D800)이 하수박스에 연결된 체 방치되어 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-06-13'
      },
      geometry: { type: 'Point', coordinates: [126.8851, 35.1482] }
    },
    {
      type: 'Feature',
      id: 604,
      properties: {
        sagoDate: '2020-06-15',
        sagoNo: '20200041',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '쌍촌동',
        addr: '1229번지 (쌍촌주공아파트) 인근',
        fullAdr: '광주광역시 서구 쌍촌동 1229',
        SEC_CD: '29140118',
        sagoLon: '126.8698',
        sagoLat: '35.1472',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '쌍촌주공아파트의 개인관(개인 하수를 공공관에 보내기 위한 관)에 이격이 나서 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-06-17'
      },
      geometry: { type: 'Point', coordinates: [126.8698, 35.1472] }
    },
    {
      type: 'Feature',
      id: 605,
      properties: {
        sagoDate: '2020-06-16',
        sagoNo: '20200042',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '화정동',
        addr: '907-6번지 인근',
        fullAdr: '광주광역시 서구 화정동 907-6',
        SEC_CD: '29140119',
        sagoLon: '126.8651',
        sagoLat: '35.1432',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '개인관(개인 하수를 공공관에 보내기 위한 관)이 노후화 및 연결부분이 잘못되었고· 공공관 노후화로 인해 토사 유실(원인이 2가지)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-06-18'
      },
      geometry: { type: 'Point', coordinates: [126.8651, 35.1432] }
    },
    {
      type: 'Feature',
      id: 606,
      properties: {
        sagoDate: '2020-06-23',
        sagoNo: '20200043',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '화정동',
        addr: '929-1번지 (화정남초 인근)',
        fullAdr: '광주광역시 서구 화정동 929-1',
        SEC_CD: '29140119',
        sagoLon: '126.8721',
        sagoLat: '35.1378',
        sinkWidth: '1.5',
        sinkExtend: '3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '오수관(D400mm) 노후화 손상에 의하여 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-06-25'
      },
      geometry: { type: 'Point', coordinates: [126.8721, 35.1378] }
    },
    {
      type: 'Feature',
      id: 607,
      properties: {
        sagoDate: '2020-06-22',
        sagoNo: '20200047',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '장덕동',
        addr: '972-2번지 주변',
        fullAdr: '광주광역시 광산구 장덕동 972-2',
        SEC_CD: '29200123',
        sagoLon: '126.8051',
        sagoLat: '35.1971',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '관로 노후로 인한 세굴 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-06-24'
      },
      geometry: { type: 'Point', coordinates: [126.8051, 35.1971] }
    },
    {
      type: 'Feature',
      id: 608,
      properties: {
        sagoDate: '2020-06-18',
        sagoNo: '20200057',
        sido: '대전광역시',
        sigungu: '중구',
        dong: '부사동',
        addr: '89-7 (GS주유소 앞)',
        fullAdr: '대전광역시 중구 부사동 89-7',
        SEC_CD: '30140111',
        sagoLon: '127.4319',
        sagoLat: '36.3189',
        sinkWidth: '0.4',
        sinkExtend: '0.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '우수박스와 하수접합부 마감불량 및 오수관 이탈로 인한 장기간 토사유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-06-20'
      },
      geometry: { type: 'Point', coordinates: [127.4319, 36.3189] }
    },
    {
      type: 'Feature',
      id: 609,
      properties: {
        sagoDate: '2020-06-25',
        sagoNo: '20200058',
        sido: '대전광역시',
        sigungu: '대덕구',
        dong: '중리동',
        addr: '236-6 (더원막창 앞)',
        fullAdr: '대전광역시 대덕구 중리동 236-6',
        SEC_CD: '30230109',
        sagoLon: '127.4256',
        sagoLat: '36.3586',
        sinkWidth: '0.2',
        sinkExtend: '0.3',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail:
          '우수박스와 하수관접합부 마감불량 및 오수관 이탈로 인한 장기간 토사유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-06-27'
      },
      geometry: { type: 'Point', coordinates: [127.4256, 36.3586] }
    },
    {
      type: 'Feature',
      id: 610,
      properties: {
        sagoDate: '2020-06-25',
        sagoNo: '20200059',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '괴정동',
        addr: '129-4 (골든타이어 앞)',
        fullAdr: '대전광역시 서구 괴정동 129-4',
        SEC_CD: '30170108',
        sagoLon: '127.3764',
        sagoLat: '36.3384',
        sinkWidth: '0.4',
        sinkExtend: '0.8',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail:
          '우수박스와 하수관접합부 마감불량 및 오수관 이탈로 인한 장기간 토사유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-06-27'
      },
      geometry: { type: 'Point', coordinates: [127.3764, 36.3384] }
    },
    {
      type: 'Feature',
      id: 611,
      properties: {
        sagoDate: '2020-06-12',
        sagoNo: '20200061',
        sido: '대전광역시',
        sigungu: '유성구',
        dong: '화암동',
        addr: '58-4',
        fullAdr: '대전광역시 유성구 화암동 58-4',
        SEC_CD: '30200130',
        sagoLon: '127.3839',
        sagoLat: '36.406',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '우수관로 주변 토사유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-06-14'
      },
      geometry: { type: 'Point', coordinates: [127.3839, 36.406] }
    },
    {
      type: 'Feature',
      id: 612,
      properties: {
        sagoDate: '2020-06-03',
        sagoNo: '20200064',
        sido: '부산광역시',
        sigungu: '강서구',
        dong: '송정동',
        addr: '1627-8번지(경남은행 건물 주차장)',
        fullAdr: '부산광역시 강서구 송정동 1627-8',
        SEC_CD: '26440109',
        sagoLon: '128.8538',
        sagoLat: '35.0895',
        sinkWidth: '40',
        sinkExtend: '40',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail:
          '주변 건축공사(삼정그린코아 더시티) 현장 내 흙막이 누수로 인한 인접 지반 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-01-05'
      },
      geometry: { type: 'Point', coordinates: [128.8538, 35.0895] }
    },
    {
      type: 'Feature',
      id: 613,
      properties: {
        sagoDate: '2020-06-22',
        sagoNo: '20200071',
        sido: '부산광역시',
        sigungu: '남구',
        dong: '문현동',
        addr: '651-6',
        fullAdr: '부산광역시 남구 문현동 651-6',
        SEC_CD: '26290109',
        sagoLon: '129.0677',
        sagoLat: '35.1385',
        sinkWidth: '0.5',
        sinkExtend: '0.8',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '미상의 관과 지반 사이로 0.5m 지반 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-06-22'
      },
      geometry: { type: 'Point', coordinates: [129.0677, 35.1385] }
    },
    {
      type: 'Feature',
      id: 614,
      properties: {
        sagoDate: '2020-06-26',
        sagoNo: '20200072',
        sido: '부산광역시',
        sigungu: '서구',
        dong: '',
        addr: '충무대로 209-2',
        fullAdr: '부산광역시 서구 충무대로 209-1 (남부민동)',
        SEC_CD: '26140123',
        sagoLon: '129.0242',
        sagoLat: '35.0893',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '0.7',
        grdKind: '',
        sagoDetail: '오수관로 상부 세굴 흔적 발견',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-07-03'
      },
      geometry: { type: 'Point', coordinates: [129.0242, 35.0893] }
    },
    {
      type: 'Feature',
      id: 615,
      properties: {
        sagoDate: '2020-06-08',
        sagoNo: '20200080',
        sido: '울산광역시',
        sigungu: '울주군',
        dong: '언양읍 서부리',
        addr: '230-5번지 앞(남천로)',
        fullAdr: '울산광역시 울주군 언양읍 서부리 230-5',
        SEC_CD: '31710253',
        sagoLon: '129.1093',
        sagoLat: '35.5686',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '도로공사 시 되메우기 불량으로 인한 공극 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-06-09'
      },
      geometry: { type: 'Point', coordinates: [129.1093, 35.5686] }
    },
    {
      type: 'Feature',
      id: 616,
      properties: {
        sagoDate: '2020-06-27',
        sagoNo: '20200085',
        sido: '인천광역시',
        sigungu: '계양구',
        dong: '계산동',
        addr: '1034(계양대로 161-2)',
        fullAdr: '인천광역시 계양구 계산동 1034',
        SEC_CD: '28245102',
        sagoLon: '126.7226',
        sagoLat: '37.5381',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '원인불명',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-06-28'
      },
      geometry: { type: 'Point', coordinates: [126.7226, 37.5381] }
    },
    {
      type: 'Feature',
      id: 617,
      properties: {
        sagoDate: '2020-06-08',
        sagoNo: '20200087',
        sido: '전북특별자치도',
        sigungu: '고창군',
        dong: '고창읍 월산리',
        addr: '산7-2',
        fullAdr: '전북특별자치도 고창군 고창읍 월산리 산 7-2',
        SEC_CD: '52790250',
        sagoLon: '126.7393',
        sagoLat: '35.4205',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '자연침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-06-15'
      },
      geometry: { type: 'Point', coordinates: [126.7393, 35.4205] }
    },
    {
      type: 'Feature',
      id: 618,
      properties: {
        sagoDate: '2020-06-18',
        sagoNo: '20200088',
        sido: '충청남도',
        sigungu: '보령시',
        dong: '오천면 원산도리',
        addr: '947-5번지',
        fullAdr: '충청남도 보령시 오천면 원산도리 947-5',
        SEC_CD: '44180320',
        sagoLon: '126.4234',
        sagoLat: '36.3684',
        sinkWidth: '4',
        sinkExtend: '23',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-07-09'
      },
      geometry: { type: 'Point', coordinates: [126.4234, 36.3684] }
    },
    {
      type: 'Feature',
      id: 619,
      properties: {
        sagoDate: '2020-06-01',
        sagoNo: '20200091',
        sido: '충청북도',
        sigungu: '청주시 흥덕구',
        dong: '',
        addr: '강내면 다락리 344-6',
        fullAdr: '충청북도 청주시 흥덕구 강내면 다락리 344-6',
        SEC_CD: '43113310',
        sagoLon: '127.3568',
        sagoLat: '36.598',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.2',
        grdKind: '',
        sagoDetail: '접합부 마감 불량 및 다짐 불량에 따른 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-06-19'
      },
      geometry: { type: 'Point', coordinates: [127.3568, 36.598] }
    },
    {
      type: 'Feature',
      id: 620,
      properties: {
        sagoDate: '2020-06-01',
        sagoNo: '20200092',
        sido: '충청북도',
        sigungu: '청주시 흥덕구',
        dong: '',
        addr: '복대동 220',
        fullAdr: '충청북도 청주시 흥덕구 복대동 220-2',
        SEC_CD: '43113114',
        sagoLon: '127.438',
        sagoLat: '36.6365',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '접합부 마감 불량 및 다짐 불량에 따른 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-06-19'
      },
      geometry: { type: 'Point', coordinates: [127.438, 36.6365] }
    },
    {
      type: 'Feature',
      id: 621,
      properties: {
        sagoDate: '2020-06-29',
        sagoNo: '20200093',
        sido: '충청북도',
        sigungu: '음성군',
        dong: '금왕읍 오선리',
        addr: '535 목우촌 앞 사거리(82번 국지도 노선)',
        fullAdr: '충청북도 음성군 금왕읍 오선리 535',
        SEC_CD: '43770253',
        sagoLon: '127.579',
        sagoLat: '36.9842',
        sinkWidth: '2.5',
        sinkExtend: '4',
        sinkDepth: '0.8',
        grdKind: '',
        sagoDetail: '접합부 마감 불량 및 다짐 불량에 따른 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-07-10'
      },
      geometry: { type: 'Point', coordinates: [127.579, 36.9842] }
    },
    {
      type: 'Feature',
      id: 622,
      properties: {
        sagoDate: '2020-07-21',
        sagoNo: '20200098',
        sido: '경상북도',
        sigungu: '경주시',
        dong: '황남동',
        addr: '포석로 392-4',
        fullAdr: '경상북도 경주시 황남동 포석로 392-4',
        SEC_CD: '47130143',
        sagoLon: '129.218',
        sagoLat: '35.8094',
        sinkWidth: '1.5',
        sinkExtend: '3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수관 파손으로 인한 토사 유출로 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-07-21'
      },
      geometry: { type: 'Point', coordinates: [129.218, 35.8094] }
    },
    {
      type: 'Feature',
      id: 623,
      properties: {
        sagoDate: '2020-07-06',
        sagoNo: '20200108',
        sido: '대전광역시',
        sigungu: '중구',
        dong: '대흥동',
        addr: '119-3',
        fullAdr: '대전광역시 중구 대흥동 119-3',
        SEC_CD: '30140105',
        sagoLon: '127.43',
        sagoLat: '36.3251',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-07-07'
      },
      geometry: { type: 'Point', coordinates: [127.43, 36.3251] }
    },
    {
      type: 'Feature',
      id: 624,
      properties: {
        sagoDate: '2020-07-30',
        sagoNo: '20200109',
        sido: '대전광역시',
        sigungu: '중구',
        dong: '석교동',
        addr: '45-1',
        fullAdr: '대전광역시 중구 석교동 45-1',
        SEC_CD: '30140107',
        sagoLon: '127.441',
        sagoLat: '36.307',
        sinkWidth: '1.7',
        sinkExtend: '5',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '집중호우 및 하수박스 노후로인한 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-06'
      },
      geometry: { type: 'Point', coordinates: [127.441, 36.307] }
    },
    {
      type: 'Feature',
      id: 625,
      properties: {
        sagoDate: '2020-07-16',
        sagoNo: '20200115',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '',
        addr: '내덕동 699-26',
        fullAdr: '충청북도 청주시 청원구  내덕동 699-26',
        SEC_CD: '43114102',
        sagoLon: '127.477',
        sagoLat: '36.6557',
        sinkWidth: '0.3',
        sinkExtend: '0.5',
        sinkDepth: '1.1',
        grdKind: '',
        sagoDetail: '연결관 노후에 의한 하수누수로 인한 지반 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-07-16'
      },
      geometry: { type: 'Point', coordinates: [127.477, 36.6557] }
    },
    {
      type: 'Feature',
      id: 626,
      properties: {
        sagoDate: '2020-07-27',
        sagoNo: '20200118',
        sido: '충청북도',
        sigungu: '청주시 서원구',
        dong: '',
        addr: '죽림동 470',
        fullAdr: '충청북도 청주시 서원구  죽림동 470',
        SEC_CD: '43112110',
        sagoLon: '127.443',
        sagoLat: '36.6105',
        sinkWidth: '3',
        sinkExtend: '4',
        sinkDepth: '1.7',
        grdKind: '',
        sagoDetail: '아스팔트 균열부위로 침투된 빗물로 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-07-28'
      },
      geometry: { type: 'Point', coordinates: [127.443, 36.6105] }
    },
    {
      type: 'Feature',
      id: 627,
      properties: {
        sagoDate: '2020-07-16',
        sagoNo: '20200130',
        sido: '경기도',
        sigungu: '고양시 일산서구',
        dong: '',
        addr: '대화동 2513',
        fullAdr: '경기도 고양시 일산서구  대화동 2513',
        SEC_CD: '41287104',
        sagoLon: '126.741',
        sagoLat: '37.6735',
        sinkWidth: '15',
        sinkExtend: '5',
        sinkDepth: '0.2',
        grdKind: '기타',
        sagoDetail: '집중호우로 인한 지반약화',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-07-18'
      },
      geometry: { type: 'Point', coordinates: [126.741, 37.6735] }
    },
    {
      type: 'Feature',
      id: 628,
      properties: {
        sagoDate: '2020-07-28',
        sagoNo: '20200131',
        sido: '경기도',
        sigungu: '하남시',
        dong: '신장동',
        addr: '260-8번지',
        fullAdr: '경기도 하남시 신장동 260-8번지',
        SEC_CD: '41450106',
        sagoLon: '127.214',
        sagoLat: '37.5468',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '하수관 연결부 불량으로 싱크홀 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-07-28'
      },
      geometry: { type: 'Point', coordinates: [127.214, 37.5468] }
    },
    {
      type: 'Feature',
      id: 629,
      properties: {
        sagoDate: '2020-07-30',
        sagoNo: '20200138',
        sido: '강원특별자치도',
        sigungu: '양구군',
        dong: '양구읍 상리',
        addr: '237-2',
        fullAdr: '강원특별자치도 양구군 양구읍 상리 237-2',
        SEC_CD: '51800250',
        sagoLon: '127.989',
        sagoLat: '38.1043',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관로 파손으로 인해 토사가 유입되며 지반 침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-07'
      },
      geometry: { type: 'Point', coordinates: [127.989, 38.1043] }
    },
    {
      type: 'Feature',
      id: 630,
      properties: {
        sagoDate: '2020-07-27',
        sagoNo: '20200139',
        sido: '경기도',
        sigungu: '화성시',
        dong: '매송면 어천리',
        addr: '산7-1',
        fullAdr: '경기도 화성시 매송면 어천리 산7-1',
        SEC_CD: '41590310',
        sagoLon: '126.922',
        sagoLat: '37.2762',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '하자보수 기간 후 자연침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-07-29'
      },
      geometry: { type: 'Point', coordinates: [126.922, 37.2762] }
    },
    {
      type: 'Feature',
      id: 631,
      properties: {
        sagoDate: '2020-07-16',
        sagoNo: '20200158',
        sido: '전라남도',
        sigungu: '화순군',
        dong: '화순읍 삼천리',
        addr: '672· 나눔의 교회 진입로',
        fullAdr: '전라남도 화순군 화순읍 삼천리 672· 나눔의 교회 진입로',
        SEC_CD: '46790250',
        sagoLon: '126.978',
        sagoLat: '35.0547',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '지방하수도 배수관 확장공사에 인근 지반 침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '18000000',
        trFnDate: '2020-07-29'
      },
      geometry: { type: 'Point', coordinates: [126.978, 35.0547] }
    },
    {
      type: 'Feature',
      id: 632,
      properties: {
        sagoDate: '2020-07-30',
        sagoNo: '20200167',
        sido: '경기도',
        sigungu: '의왕시',
        dong: '청계동',
        addr: '440-8',
        fullAdr: '경기도 의왕시 청계동 440-8',
        SEC_CD: '41430108',
        sagoLon: '127.009',
        sagoLat: '37.4038',
        sinkWidth: '1',
        sinkExtend: '1.3',
        sinkDepth: '0.7',
        grdKind: '',
        sagoDetail: '우수관 다짐불량·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2020-07-30'
      },
      geometry: { type: 'Point', coordinates: [127.009, 37.4038] }
    },
    {
      type: 'Feature',
      id: 633,
      properties: {
        sagoDate: '2020-07-30',
        sagoNo: '20200177',
        sido: '부산광역시',
        sigungu: '중구',
        dong: '영주동',
        addr: '1-144',
        fullAdr: '부산광역시 중구 영주동 1-144',
        SEC_CD: '26110101',
        sagoLon: '129.031',
        sagoLat: '35.115',
        sinkWidth: '3',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '계속된 장마철 집중호우로 지표수 유입으로 인한 지반침하로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-07-31'
      },
      geometry: { type: 'Point', coordinates: [129.031, 35.115] }
    },
    {
      type: 'Feature',
      id: 634,
      properties: {
        sagoDate: '2020-07-10',
        sagoNo: '20200187',
        sido: '경기도',
        sigungu: '군포시',
        dong: '당동',
        addr: '917번지',
        fullAdr: '경기도 군포시 당동 917번지',
        SEC_CD: '41410101',
        sagoLon: '126.945',
        sagoLat: '37.3475',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '노후하수관로 파손(토사유실)으로 인한 싱크홀 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-09-28'
      },
      geometry: { type: 'Point', coordinates: [126.945, 37.3475] }
    },
    {
      type: 'Feature',
      id: 635,
      properties: {
        sagoDate: '2020-07-23',
        sagoNo: '20200188',
        sido: '경상북도',
        sigungu: '울진군',
        dong: '기성면 다천리',
        addr: '산176',
        fullAdr: '경상북도 울진군 기성면 다천리 산176',
        SEC_CD: '47930350',
        sagoLon: '129.42',
        sagoLat: '36.8013',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '원인미상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-07-23'
      },
      geometry: { type: 'Point', coordinates: [129.42, 36.8013] }
    },
    {
      type: 'Feature',
      id: 636,
      properties: {
        sagoDate: '2020-07-31',
        sagoNo: '20200190',
        sido: '대구광역시',
        sigungu: '달성군',
        dong: '화원읍 천내리',
        addr: '비슬로 2624(대구교도소 진입로 보도)',
        fullAdr:
          '대구광역시 달성군 화원읍 천내리 비슬로 2624(대구교도소 진입로 보도)',
        SEC_CD: '27710250',
        sagoLon: '128.503',
        sagoLat: '35.8055',
        sinkWidth: '1.7',
        sinkExtend: '1.7',
        sinkDepth: '0.9',
        grdKind: '',
        sagoDetail: '다짐 불량에 의한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-07-31'
      },
      geometry: { type: 'Point', coordinates: [128.503, 35.8055] }
    },
    {
      type: 'Feature',
      id: 637,
      properties: {
        sagoDate: '2020-07-25',
        sagoNo: '20200201',
        sido: '전라남도',
        sigungu: '보성군',
        dong: '득량면 삼정리',
        addr: '277-2',
        fullAdr: '전라남도 보성군 득량면 삼정리 277-2',
        SEC_CD: '46780380',
        sagoLon: '127.153',
        sagoLat: '34.7831',
        sinkWidth: '2',
        sinkExtend: '8',
        sinkDepth: '1.7',
        grdKind: '',
        sagoDetail: '오폐수관 터짐으로 인한 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-09-02'
      },
      geometry: { type: 'Point', coordinates: [127.153, 34.7831] }
    },
    {
      type: 'Feature',
      id: 638,
      properties: {
        sagoDate: '2020-07-22',
        sagoNo: '20200207',
        sido: '인천광역시',
        sigungu: '남동구',
        dong: '논현동',
        addr: '445-1',
        fullAdr: '인천광역시 남동구 논현동 445-1',
        SEC_CD: '28200110',
        sagoLon: '126.7',
        sagoLat: '37.4096',
        sinkWidth: '1.3',
        sinkExtend: '1.3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-07-23'
      },
      geometry: { type: 'Point', coordinates: [126.7, 37.4096] }
    },
    {
      type: 'Feature',
      id: 639,
      properties: {
        sagoDate: '2020-07-23',
        sagoNo: '20200208',
        sido: '인천광역시',
        sigungu: '남동구',
        dong: '구월동',
        addr: '1264',
        fullAdr: '인천광역시 남동구 구월동 1264',
        SEC_CD: '28200101',
        sagoLon: '126.72',
        sagoLat: '37.4556',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.9',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-07-24'
      },
      geometry: { type: 'Point', coordinates: [126.72, 37.4556] }
    },
    {
      type: 'Feature',
      id: 640,
      properties: {
        sagoDate: '2020-07-29',
        sagoNo: '20200215',
        sido: '인천광역시',
        sigungu: '서구',
        dong: '가좌동',
        addr: '가좌역1번출입구',
        fullAdr: '인천광역시 서구 가좌동 가좌역1번출입구',
        SEC_CD: '28260112',
        sagoLon: '126.675',
        sagoLat: '37.4907',
        sinkWidth: '1.5',
        sinkExtend: '2.5',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '집중호우로 인한 모래유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-07-29'
      },
      geometry: { type: 'Point', coordinates: [126.675, 37.4907] }
    },
    {
      type: 'Feature',
      id: 641,
      properties: {
        sagoDate: '2020-07-23',
        sagoNo: '20200224',
        sido: '경상북도',
        sigungu: '포항시 남구',
        dong: '송내동',
        addr: '657-2번지',
        fullAdr: '경상북도 포항시 남구 송내동 657-2번지',
        SEC_CD: '47111108',
        sagoLon: '129.365',
        sagoLat: '36.0004',
        sinkWidth: '3',
        sinkExtend: '70',
        sinkDepth: '0.6',
        grdKind: '',
        sagoDetail:
          '철강산업단지 완충저류시설 공사 현장내 지하구조물 터파기에 의한 파이핑현상 발생으로 도로가 침하된 것으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-04-12'
      },
      geometry: { type: 'Point', coordinates: [129.365, 36.0004] }
    },
    {
      type: 'Feature',
      id: 642,
      properties: {
        sagoDate: '2020-07-27',
        sagoNo: '20200225',
        sido: '경상북도',
        sigungu: '포항시 남구',
        dong: '대도동',
        addr: '422-27',
        fullAdr: '경상북도 포항시 남구 대도동 422-27',
        SEC_CD: '47111102',
        sagoLon: '129.356',
        sagoLat: '36.0088',
        sinkWidth: '3',
        sinkExtend: '4',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 파손으로 인한 토사 유출로 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-02'
      },
      geometry: { type: 'Point', coordinates: [129.356, 36.0088] }
    },
    {
      type: 'Feature',
      id: 643,
      properties: {
        sagoDate: '2020-07-06',
        sagoNo: '20200236',
        sido: '부산광역시',
        sigungu: '부산진구',
        dong: '범천동',
        addr: '847-2',
        fullAdr: '부산광역시 부산진구 범천동 847-2',
        SEC_CD: '26230104',
        sagoLon: '129.06',
        sagoLat: '35.1437',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.8',
        grdKind: '',
        sagoDetail: '하수박스 벽체부 파손으로 인한 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-07-30'
      },
      geometry: { type: 'Point', coordinates: [129.06, 35.1437] }
    },
    {
      type: 'Feature',
      id: 644,
      properties: {
        sagoDate: '2020-07-11',
        sagoNo: '20200237',
        sido: '부산광역시',
        sigungu: '부산진구',
        dong: '전포동',
        addr: '428-1',
        fullAdr: '부산광역시 부산진구 전포동 428-1',
        SEC_CD: '26230102',
        sagoLon: '129.072',
        sagoLat: '35.1539',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '노후 하수관 파손으로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-07-11'
      },
      geometry: { type: 'Point', coordinates: [129.072, 35.1539] }
    },
    {
      type: 'Feature',
      id: 645,
      properties: {
        sagoDate: '2020-07-24',
        sagoNo: '20200238',
        sido: '부산광역시',
        sigungu: '부산진구',
        dong: '개금동',
        addr: '진사로 61번길 29',
        fullAdr: '부산광역시 부산진구 개금동 진사로 61번길 29',
        SEC_CD: '26230111',
        sagoLon: '129.017',
        sagoLat: '35.1467',
        sinkWidth: '1.2',
        sinkExtend: '1.2',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '노후 하수관 파손으로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-12-15'
      },
      geometry: { type: 'Point', coordinates: [129.017, 35.1467] }
    },
    {
      type: 'Feature',
      id: 646,
      properties: {
        sagoDate: '2020-07-29',
        sagoNo: '20200239',
        sido: '부산광역시',
        sigungu: '부산진구',
        dong: '부전동',
        addr: '가야대로 763-1',
        fullAdr: '부산광역시 부산진구 부전동 가야대로 763-1',
        SEC_CD: '26230103',
        sagoLon: '129.055',
        sagoLat: '35.1578',
        sinkWidth: '0.8',
        sinkExtend: '0.8',
        sinkDepth: '2.5',
        grdKind: '',
        sagoDetail: '다짐불량으로 인한 지반침하 발생 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-09-23'
      },
      geometry: { type: 'Point', coordinates: [129.055, 35.1578] }
    },
    {
      type: 'Feature',
      id: 647,
      properties: {
        sagoDate: '2020-07-25',
        sagoNo: '20200242',
        sido: '부산광역시',
        sigungu: '기장군',
        dong: '정관면 달산리',
        addr: '44-45번지 앞(산단5로)',
        fullAdr: '부산광역시 기장군 정관면 달산리 44-45번지 앞(산단5로)',
        SEC_CD: '26710256',
        sagoLon: '129.203',
        sagoLat: '35.3174',
        sinkWidth: '1.2',
        sinkExtend: '7',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '하수관로(CSP D1·200mm) 파손으로 인한 함몰 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-02-02'
      },
      geometry: { type: 'Point', coordinates: [129.203, 35.3174] }
    },
    {
      type: 'Feature',
      id: 648,
      properties: {
        sagoDate: '2020-07-20',
        sagoNo: '20200247',
        sido: '광주광역시',
        sigungu: '북구',
        dong: '연제동',
        addr: '36',
        fullAdr: '광주광역시 북구 연제동 36',
        SEC_CD: '29170129',
        sagoLon: '126.87',
        sagoLat: '35.1985',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-07-22'
      },
      geometry: { type: 'Point', coordinates: [126.87, 35.1985] }
    },
    {
      type: 'Feature',
      id: 649,
      properties: {
        sagoDate: '2020-07-06',
        sagoNo: '20200251',
        sido: '경기도',
        sigungu: '평택시',
        dong: '안중읍 현화리',
        addr: '648-2번지',
        fullAdr: '경기도 평택시 안중읍 현화리 648-2번지',
        SEC_CD: '41220253',
        sagoLon: '126.913',
        sagoLat: '36.9904',
        sinkWidth: '3',
        sinkExtend: '3',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail: '하수관 노후로 인한 관로 파손 및 누수에 따른 도로 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-07-09'
      },
      geometry: { type: 'Point', coordinates: [126.913, 36.9904] }
    },
    {
      type: 'Feature',
      id: 650,
      properties: {
        sagoDate: '2020-07-30',
        sagoNo: '20200267',
        sido: '충청북도',
        sigungu: '음성군',
        dong: '금왕읍',
        addr: '무극로 221',
        fullAdr: '충청북도 음성군 금왕읍 무극로 221',
        SEC_CD: '43770253',
        sagoLon: '127.587',
        sagoLat: '36.9914',
        sinkWidth: '1',
        sinkExtend: '5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '파형강관 이음부(맞대기) 부분침하에 따른 단차발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-01'
      },
      geometry: { type: 'Point', coordinates: [127.587, 36.9914] }
    },
    {
      type: 'Feature',
      id: 651,
      properties: {
        sagoDate: '2020-07-24',
        sagoNo: '20200269',
        sido: '부산광역시',
        sigungu: '기장군',
        dong: '일광면 원리',
        addr: '748-2번지 일원(부산~울산고속도로 원리 2교 하부)',
        fullAdr:
          '부산광역시 기장군 일광면 원리 748-2번지 일원(부산~울산고속도로 원리 2교 하부)',
        SEC_CD: '26710259',
        sagoLon: '129.229',
        sagoLat: '35.3099',
        sinkWidth: '5',
        sinkExtend: '10',
        sinkDepth: '6',
        grdKind: '기타',
        sagoDetail: '도로를 횡단하는 하수박스(3.0x2.5) 벽체 파손으로 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-24'
      },
      geometry: { type: 'Point', coordinates: [129.229, 35.3099] }
    },
    {
      type: 'Feature',
      id: 652,
      properties: {
        sagoDate: '2020-07-28',
        sagoNo: '20200270',
        sido: '경상북도',
        sigungu: '포항시 남구',
        dong: '장흥동',
        addr: '1262-2',
        fullAdr: '경상북도 포항시 남구 장흥동 1262-2',
        SEC_CD: '47111111',
        sagoLon: '129.368',
        sagoLat: '35.9784',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '우수관로 400mm 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-09'
      },
      geometry: { type: 'Point', coordinates: [129.368, 35.9784] }
    },
    {
      type: 'Feature',
      id: 653,
      properties: {
        sagoDate: '2020-07-24',
        sagoNo: '20200305',
        sido: '서울특별시',
        sigungu: '마포구',
        dong: '상수동',
        addr: '309-18',
        fullAdr: '서울특별시 마포구 상수동 309-18',
        SEC_CD: '11440115',
        sagoLon: '126.923',
        sagoLat: '37.5476',
        sinkWidth: '2.6',
        sinkExtend: '1',
        sinkDepth: '0.3',
        grdKind: '기타',
        sagoDetail: '엘리베이터 설치 후 부실로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-07-24'
      },
      geometry: { type: 'Point', coordinates: [126.923, 37.5476] }
    },
    {
      type: 'Feature',
      id: 654,
      properties: {
        sagoDate: '2020-07-28',
        sagoNo: '20200312',
        sido: '부산광역시',
        sigungu: '남구',
        dong: '대연동',
        addr: '209-4 번지',
        fullAdr: '부산광역시 남구 대연동 209-4 번지',
        SEC_CD: '26290106',
        sagoLon: '129.097',
        sagoLat: '35.1463',
        sinkWidth: '2',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 파손으로 인한 도로 침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-07-29'
      },
      geometry: { type: 'Point', coordinates: [129.097, 35.1463] }
    },
    {
      type: 'Feature',
      id: 655,
      properties: {
        sagoDate: '2020-07-23',
        sagoNo: '20200313',
        sido: '부산광역시',
        sigungu: '동구',
        dong: '범일동',
        addr: '1542-7',
        fullAdr: '부산광역시 동구 범일동 1542-7',
        SEC_CD: '26170104',
        sagoLon: '129.045',
        sagoLat: '35.1458',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.7',
        grdKind: '',
        sagoDetail: '측구파손에 따른 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-28'
      },
      geometry: { type: 'Point', coordinates: [129.045, 35.1458] }
    },
    {
      type: 'Feature',
      id: 656,
      properties: {
        sagoDate: '2020-07-23',
        sagoNo: '20200314',
        sido: '부산광역시',
        sigungu: '동구',
        dong: '범일동',
        addr: '830-100 (자성로 133번길 26-2) ',
        fullAdr: '부산광역시 동구 범일동 830-100 (자성로 133번길 26-2)',
        SEC_CD: '26170104',
        sagoLon: '129.063',
        sagoLat: '35.1399',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.7',
        grdKind: '',
        sagoDetail: '측구 파손에 따른 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-28'
      },
      geometry: { type: 'Point', coordinates: [129.063, 35.1399] }
    },
    {
      type: 'Feature',
      id: 657,
      properties: {
        sagoDate: '2020-07-23',
        sagoNo: '20200315',
        sido: '부산광역시',
        sigungu: '동구',
        dong: '범일동',
        addr: '1453-8 (망양로 921)',
        fullAdr: '부산광역시 동구 범일동 1453-8 (망양로 921)',
        SEC_CD: '26170104',
        sagoLon: '129.051',
        sagoLat: '35.1404',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '오수관로 파손에 따른 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-07-24'
      },
      geometry: { type: 'Point', coordinates: [129.051, 35.1404] }
    },
    {
      type: 'Feature',
      id: 658,
      properties: {
        sagoDate: '2020-07-15',
        sagoNo: '20200316',
        sido: '부산광역시',
        sigungu: '남구',
        dong: '용당동',
        addr: '525-7',
        fullAdr: '부산광역시 남구 용당동 525-7',
        SEC_CD: '26290108',
        sagoLon: '129.1',
        sagoLat: '35.1233',
        sinkWidth: '1',
        sinkExtend: '3.8',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수관 파손으로 인한 도로 침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-07-17'
      },
      geometry: { type: 'Point', coordinates: [129.1, 35.1233] }
    },
    {
      type: 'Feature',
      id: 659,
      properties: {
        sagoDate: '2020-07-11',
        sagoNo: '20200317',
        sido: '부산광역시',
        sigungu: '남구',
        dong: '문현동',
        addr: '920-4',
        fullAdr: '부산광역시 남구 문현동 920-4',
        SEC_CD: '26290109',
        sagoLon: '129.07',
        sagoLat: '35.1343',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '누후화된 측구로 인한 도로 침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-26'
      },
      geometry: { type: 'Point', coordinates: [129.07, 35.1343] }
    },
    {
      type: 'Feature',
      id: 660,
      properties: {
        sagoDate: '2020-07-07',
        sagoNo: '20200318',
        sido: '부산광역시',
        sigungu: '동래구',
        dong: '명륜동',
        addr: '652-24',
        fullAdr: '부산광역시 동래구 명륜동 652-24',
        SEC_CD: '26260107',
        sagoLon: '129.08',
        sagoLat: '35.2085',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '자연침하로 인한 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-07-15'
      },
      geometry: { type: 'Point', coordinates: [129.08, 35.2085] }
    },
    {
      type: 'Feature',
      id: 661,
      properties: {
        sagoDate: '2020-08-09',
        sagoNo: '20200095',
        sido: '인천광역시',
        sigungu: '서구',
        dong: '마전동',
        addr: '1002-2 세븐일레븐 앞',
        fullAdr: '인천광역시 서구 마전동 1002-2',
        SEC_CD: '28260113',
        sagoLon: '126.6711',
        sagoLat: '37.5957',
        sinkWidth: '3',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '오수관로(D400) 파손을 인한 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-10'
      },
      geometry: { type: 'Point', coordinates: [126.6711, 37.5957] }
    },
    {
      type: 'Feature',
      id: 662,
      properties: {
        sagoDate: '2020-08-26',
        sagoNo: '20200096',
        sido: '인천광역시',
        sigungu: '서구',
        dong: '마전동',
        addr: '1001-5',
        fullAdr: '인천광역시 서구 마전동 1001-5',
        SEC_CD: '28260113',
        sagoLon: '126.6703',
        sagoLat: '37.5959',
        sinkWidth: '2.5',
        sinkExtend: '10',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: 'H파일과 토류판· 기존 하수관(폐관) 마감처리 미비',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-27'
      },
      geometry: { type: 'Point', coordinates: [126.6703, 37.5959] }
    },
    {
      type: 'Feature',
      id: 663,
      properties: {
        sagoDate: '2020-08-11',
        sagoNo: '20200099',
        sido: '충청남도',
        sigungu: '보령시',
        dong: '주교면 송학리',
        addr: '478-2',
        fullAdr: '충청남도 보령시 주교면 송학리 478-2',
        SEC_CD: '44180410',
        sagoLon: '126.524',
        sagoLat: '36.3807',
        sinkWidth: '2.5',
        sinkExtend: '2.5',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail: '흄관 연결 접속부의 되메우기 토사유출로 인한 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-04-05'
      },
      geometry: { type: 'Point', coordinates: [126.524, 36.3807] }
    },
    {
      type: 'Feature',
      id: 664,
      properties: {
        sagoDate: '2020-08-13',
        sagoNo: '20200101',
        sido: '강원특별자치도',
        sigungu: '홍천군',
        dong: '내촌면 물걸리',
        addr: '56',
        fullAdr: '강원특별자치도 홍천군 내촌면 물걸리 56',
        SEC_CD: '51720330',
        sagoLon: '128.173',
        sagoLat: '37.7856',
        sinkWidth: '2',
        sinkExtend: '2.5',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '비포장도로 내 공동현상으로 인한 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '300000',
        trFnDate: '2020-08-21'
      },
      geometry: { type: 'Point', coordinates: [128.173, 37.7856] }
    },
    {
      type: 'Feature',
      id: 665,
      properties: {
        sagoDate: '2020-08-26',
        sagoNo: '20200103',
        sido: '강원특별자치도',
        sigungu: '속초시',
        dong: '조양동',
        addr: '1502 앞',
        fullAdr: '강원특별자치도 속초시 조양동 1502 앞',
        SEC_CD: '51210108',
        sagoLon: '128.58',
        sagoLat: '38.1878',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1.3',
        grdKind: '',
        sagoDetail: '하수맨홀 노후로 벽체 파손에 따른 주변 지반 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '3000000',
        trFnDate: '2020-08-26'
      },
      geometry: { type: 'Point', coordinates: [128.58, 38.1878] }
    },
    {
      type: 'Feature',
      id: 666,
      properties: {
        sagoDate: '2020-08-06',
        sagoNo: '20200104',
        sido: '강원특별자치도',
        sigungu: '강릉시',
        dong: '송정동',
        addr: '송정동 187-6',
        fullAdr: '강원특별자치도 강릉시 송정동 송정동 187-6',
        SEC_CD: '51150114',
        sagoLon: '128.933',
        sagoLat: '37.7748',
        sinkWidth: '1',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-03-17'
      },
      geometry: { type: 'Point', coordinates: [128.933, 37.7748] }
    },
    {
      type: 'Feature',
      id: 667,
      properties: {
        sagoDate: '2020-08-09',
        sagoNo: '20200105',
        sido: '강원특별자치도',
        sigungu: '강릉시',
        dong: '교동',
        addr: '994-9',
        fullAdr: '강원특별자치도 강릉시 교동 994-9',
        SEC_CD: '51150110',
        sagoLon: '128.883',
        sagoLat: '37.7585',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-14'
      },
      geometry: { type: 'Point', coordinates: [128.883, 37.7585] }
    },
    {
      type: 'Feature',
      id: 668,
      properties: {
        sagoDate: '2020-08-10',
        sagoNo: '20200106',
        sido: '강원특별자치도',
        sigungu: '강릉시',
        dong: '교동',
        addr: '교동 828-17',
        fullAdr: '강원특별자치도 강릉시 교동 교동 828-17',
        SEC_CD: '51150110',
        sagoLon: '128.889',
        sagoLat: '37.7643',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-17'
      },
      geometry: { type: 'Point', coordinates: [128.889, 37.7643] }
    },
    {
      type: 'Feature',
      id: 669,
      properties: {
        sagoDate: '2020-08-10',
        sagoNo: '20200107',
        sido: '전북특별자치도',
        sigungu: '정읍시',
        dong: '영원면 은선리',
        addr: '',
        fullAdr: '전북특별자치도 정읍시 영원면 은선리',
        SEC_CD: '52180350',
        sagoLon: '126.791',
        sagoLat: '35.6455',
        sinkWidth: '1.5',
        sinkExtend: '15',
        sinkDepth: '22.5',
        grdKind: '',
        sagoDetail: '배수로 암거(L=35m)가 노후되어 누수 및 씽크홀 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-21'
      },
      geometry: { type: 'Point', coordinates: [126.791, 35.6455] }
    },
    {
      type: 'Feature',
      id: 670,
      properties: {
        sagoDate: '2020-08-12',
        sagoNo: '20200110',
        sido: '충청북도',
        sigungu: '청주시 흥덕구',
        dong: '',
        addr: '운천동 1698',
        fullAdr: '충청북도 청주시 흥덕구  운천동 1698',
        SEC_CD: '43113104',
        sagoLon: '127.479',
        sagoLat: '36.646',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail: '하수관 접합부 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-12'
      },
      geometry: { type: 'Point', coordinates: [127.479, 36.646] }
    },
    {
      type: 'Feature',
      id: 671,
      properties: {
        sagoDate: '2020-08-14',
        sagoNo: '20200111',
        sido: '충청북도',
        sigungu: '청주시 흥덕구',
        dong: '',
        addr: '옥산면 장동리 372-1',
        fullAdr: '충청북도 청주시 흥덕구  옥산면 장동리 372-1',
        SEC_CD: '43113320',
        sagoLon: '127.318',
        sagoLat: '36.6939',
        sinkWidth: '1.5',
        sinkExtend: '3',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 접합부 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-15'
      },
      geometry: { type: 'Point', coordinates: [127.318, 36.6939] }
    },
    {
      type: 'Feature',
      id: 672,
      properties: {
        sagoDate: '2020-08-19',
        sagoNo: '20200112',
        sido: '충청북도',
        sigungu: '청주시 흥덕구',
        dong: '',
        addr: '운천동 676',
        fullAdr: '충청북도 청주시 흥덕구  운천동 676',
        SEC_CD: '43113104',
        sagoLon: '127.472',
        sagoLat: '36.6526',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '하수관 접합부 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-20'
      },
      geometry: { type: 'Point', coordinates: [127.472, 36.6526] }
    },
    {
      type: 'Feature',
      id: 673,
      properties: {
        sagoDate: '2020-08-27',
        sagoNo: '20200113',
        sido: '충청북도',
        sigungu: '청주시 흥덕구',
        dong: '',
        addr: '운천동 876',
        fullAdr: '충청북도 청주시 흥덕구  운천동 876',
        SEC_CD: '43113104',
        sagoLon: '127.473',
        sagoLat: '36.6431',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 접합부 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-28'
      },
      geometry: { type: 'Point', coordinates: [127.473, 36.6431] }
    },
    {
      type: 'Feature',
      id: 674,
      properties: {
        sagoDate: '2020-08-28',
        sagoNo: '20200114',
        sido: '충청북도',
        sigungu: '청주시 흥덕구',
        dong: '',
        addr: '운천동 1655',
        fullAdr: '충청북도 청주시 흥덕구  운천동 1655',
        SEC_CD: '43113104',
        sagoLon: '127.48',
        sagoLat: '36.6462',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail: '하수관 접합부 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-28'
      },
      geometry: { type: 'Point', coordinates: [127.48, 36.6462] }
    },
    {
      type: 'Feature',
      id: 675,
      properties: {
        sagoDate: '2020-08-05',
        sagoNo: '20200116',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '',
        addr: '율량동 1089',
        fullAdr: '충청북도 청주시 청원구  율량동 1089',
        SEC_CD: '43114103',
        sagoLon: '127.492',
        sagoLat: '36.6653',
        sinkWidth: '0.5',
        sinkExtend: '0.4',
        sinkDepth: '1.2',
        grdKind: '',
        sagoDetail: '타공사(간접원인)에 의한 이음부 단락(직접원인) 토사유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-05'
      },
      geometry: { type: 'Point', coordinates: [127.492, 36.6653] }
    },
    {
      type: 'Feature',
      id: 676,
      properties: {
        sagoDate: '2020-08-11',
        sagoNo: '20200117',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '',
        addr: '율봉로 211',
        fullAdr: '충청북도 청주시 청원구  율봉로 211',
        SEC_CD: '43114103',
        sagoLon: '127.492',
        sagoLat: '36.6685',
        sinkWidth: '0.7',
        sinkExtend: '0.7',
        sinkDepth: '1.1',
        grdKind: '',
        sagoDetail: '연결관 노후에 의한 하수누수로 인한 지반 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-11'
      },
      geometry: { type: 'Point', coordinates: [127.492, 36.6685] }
    },
    {
      type: 'Feature',
      id: 677,
      properties: {
        sagoDate: '2020-08-13',
        sagoNo: '20200119',
        sido: '충청북도',
        sigungu: '청주시 서원구',
        dong: '',
        addr: '사창동 265-3',
        fullAdr: '충청북도 청주시 서원구  사창동 265-3',
        SEC_CD: '43112102',
        sagoLon: '127.464',
        sagoLat: '36.6338',
        sinkWidth: '2',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '노후화된 하수관로 파손에 따른 토사 유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-03-26'
      },
      geometry: { type: 'Point', coordinates: [127.464, 36.6338] }
    },
    {
      type: 'Feature',
      id: 678,
      properties: {
        sagoDate: '2020-08-13',
        sagoNo: '20200120',
        sido: '충청북도',
        sigungu: '청주시 서원구',
        dong: '',
        addr: '사창동 265-5',
        fullAdr: '충청북도 청주시 서원구  사창동 265-5',
        SEC_CD: '43112102',
        sagoLon: '127.464',
        sagoLat: '36.6337',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '노후화된 하수BOX 내부 바닥으로 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-03-26'
      },
      geometry: { type: 'Point', coordinates: [127.464, 36.6337] }
    },
    {
      type: 'Feature',
      id: 679,
      properties: {
        sagoDate: '2020-08-26',
        sagoNo: '20200121',
        sido: '충청북도',
        sigungu: '청주시 서원구',
        dong: '',
        addr: '사창동 143-5',
        fullAdr: '충청북도 청주시 서원구  사창동 143-5',
        SEC_CD: '43112102',
        sagoLon: '127.459',
        sagoLat: '36.6352',
        sinkWidth: '3',
        sinkExtend: '2',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '노후화된 하수관로 파손에 따른 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-26'
      },
      geometry: { type: 'Point', coordinates: [127.459, 36.6352] }
    },
    {
      type: 'Feature',
      id: 680,
      properties: {
        sagoDate: '2020-08-31',
        sagoNo: '20200122',
        sido: '충청북도',
        sigungu: '청주시 서원구',
        dong: '',
        addr: '개신동 12',
        fullAdr: '충청북도 청주시 서원구  개신동 12',
        SEC_CD: '43112109',
        sagoLon: '127.457',
        sagoLat: '36.6299',
        sinkWidth: '3',
        sinkExtend: '2.5',
        sinkDepth: '2.5',
        grdKind: '',
        sagoDetail: '노후화된 하수BOX 내부 바닥으로 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-31'
      },
      geometry: { type: 'Point', coordinates: [127.457, 36.6299] }
    },
    {
      type: 'Feature',
      id: 681,
      properties: {
        sagoDate: '2020-08-04',
        sagoNo: '20200123',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '',
        addr: '오창읍 석우리 631(석우리 63-1)',
        fullAdr: '충청북도 청주시 청원구  오창읍 석우리 631(석우리 63-1)',
        SEC_CD: '43114253',
        sagoLon: '127.457',
        sagoLat: '36.7177',
        sinkWidth: '0.3',
        sinkExtend: '0.7',
        sinkDepth: '1.3',
        grdKind: '',
        sagoDetail: '연결관 노후에 의한 하수누수로 인한 지반 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-04'
      },
      geometry: { type: 'Point', coordinates: [127.457, 36.7177] }
    },
    {
      type: 'Feature',
      id: 682,
      properties: {
        sagoDate: '2020-08-25',
        sagoNo: '20200124',
        sido: '대전광역시',
        sigungu: '중구',
        dong: '은행동',
        addr: '45-12',
        fullAdr: '대전광역시 중구 은행동 45-12',
        SEC_CD: '30140101',
        sagoLon: '127.429',
        sagoLat: '36.329',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-09-10'
      },
      geometry: { type: 'Point', coordinates: [127.429, 36.329] }
    },
    {
      type: 'Feature',
      id: 683,
      properties: {
        sagoDate: '2020-08-02',
        sagoNo: '20200125',
        sido: '강원특별자치도',
        sigungu: '영월군',
        dong: '남면 북쌍리',
        addr: '193-4',
        fullAdr: '강원특별자치도 영월군 남면 북쌍리 193-4',
        SEC_CD: '51750340',
        sagoLon: '128.414',
        sagoLat: '37.2083',
        sinkWidth: '8',
        sinkExtend: '6',
        sinkDepth: '2.2',
        grdKind: '',
        sagoDetail: '폭우로 인한 지반연약 등',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-03'
      },
      geometry: { type: 'Point', coordinates: [128.414, 37.2083] }
    },
    {
      type: 'Feature',
      id: 684,
      properties: {
        sagoDate: '2020-08-31',
        sagoNo: '20200126',
        sido: '경기도',
        sigungu: '시흥시',
        dong: '월곶동',
        addr: '993-1',
        fullAdr: '경기도 시흥시 월곶동 993-1',
        SEC_CD: '41390131',
        sagoLon: '126.744',
        sagoLat: '37.3894',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '장마로 인한 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-09-06'
      },
      geometry: { type: 'Point', coordinates: [126.744, 37.3894] }
    },
    {
      type: 'Feature',
      id: 685,
      properties: {
        sagoDate: '2020-08-29',
        sagoNo: '20200127',
        sido: '경상남도',
        sigungu: '김해시',
        dong: '삼방동',
        addr: '182-7',
        fullAdr: '경상남도 김해시 삼방동 182-7',
        SEC_CD: '48250119',
        sagoLon: '128.909',
        sagoLat: '35.244',
        sinkWidth: '0.4',
        sinkExtend: '0.4',
        sinkDepth: '1.2',
        grdKind: '기타',
        sagoDetail: '우수받이와 연결관의 접합부 파손으로 인한 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-29'
      },
      geometry: { type: 'Point', coordinates: [128.909, 35.244] }
    },
    {
      type: 'Feature',
      id: 686,
      properties: {
        sagoDate: '2020-08-04',
        sagoNo: '20200129',
        sido: '경기도',
        sigungu: '포천시',
        dong: '어룡동',
        addr: '410-2',
        fullAdr: '경기도 포천시 어룡동 410-2',
        SEC_CD: '41650102',
        sagoLon: '127.192',
        sagoLat: '37.8861',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '우수관 누수',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-12-31'
      },
      geometry: { type: 'Point', coordinates: [127.192, 37.8861] }
    },
    {
      type: 'Feature',
      id: 687,
      properties: {
        sagoDate: '2020-08-10',
        sagoNo: '20200132',
        sido: '경기도',
        sigungu: '하남시',
        dong: '덕풍동',
        addr: '396-1번지',
        fullAdr: '경기도 하남시 덕풍동 396-1번지',
        SEC_CD: '41450108',
        sagoLon: '127.203',
        sagoLat: '37.5409',
        sinkWidth: '1.5',
        sinkExtend: '2.5',
        sinkDepth: '1.3',
        grdKind: '기타',
        sagoDetail: '하수관(흄관) 파손으로 싱크홀 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-20'
      },
      geometry: { type: 'Point', coordinates: [127.203, 37.5409] }
    },
    {
      type: 'Feature',
      id: 688,
      properties: {
        sagoDate: '2020-08-13',
        sagoNo: '20200133',
        sido: '경기도',
        sigungu: '하남시',
        dong: '덕풍동',
        addr: '401-11번지',
        fullAdr: '경기도 하남시 덕풍동 401-11번지',
        SEC_CD: '41450108',
        sagoLon: '127.203',
        sagoLat: '37.5409',
        sinkWidth: '1.5',
        sinkExtend: '2.5',
        sinkDepth: '1.3',
        grdKind: '기타',
        sagoDetail: '하수관 연결부 불량으로 싱크홀 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-19'
      },
      geometry: { type: 'Point', coordinates: [127.203, 37.5409] }
    },
    {
      type: 'Feature',
      id: 689,
      properties: {
        sagoDate: '2020-08-14',
        sagoNo: '20200134',
        sido: '경기도',
        sigungu: '고양시 일산서구',
        dong: '',
        addr: '대화동 2409번지',
        fullAdr: '경기도 고양시 일산서구  대화동 2409번지',
        SEC_CD: '41287104',
        sagoLon: '126.757',
        sagoLat: '37.6803',
        sinkWidth: '3',
        sinkExtend: '3',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '집중호우로 인한 지반약화',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-16'
      },
      geometry: { type: 'Point', coordinates: [126.757, 37.6803] }
    },
    {
      type: 'Feature',
      id: 690,
      properties: {
        sagoDate: '2020-08-14',
        sagoNo: '20200135',
        sido: '경기도',
        sigungu: '고양시 일산서구',
        dong: '',
        addr: '일산동 1025-13',
        fullAdr: '경기도 고양시 일산서구  일산동 1025-13',
        SEC_CD: '41287101',
        sagoLon: '126.769',
        sagoLat: '37.684',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '0.1',
        grdKind: '기타',
        sagoDetail: '호우로 인한 지반약화 및 인근 공사차량으로통행으로 인한',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-30'
      },
      geometry: { type: 'Point', coordinates: [126.769, 37.684] }
    },
    {
      type: 'Feature',
      id: 691,
      properties: {
        sagoDate: '2020-08-26',
        sagoNo: '20200136',
        sido: '경기도',
        sigungu: '구리시',
        dong: '교문동',
        addr: '813번지',
        fullAdr: '경기도 구리시 교문동 813번지',
        SEC_CD: '41310105',
        sagoLon: '127.138',
        sagoLat: '37.5891',
        sinkWidth: '16',
        sinkExtend: '15',
        sinkDepth: '21',
        grdKind: '기타',
        sagoDetail: '별내선 복선전철 터널공사 시공관리 미흡',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '0',
        trFnDate: '2021-12-31'
      },
      geometry: { type: 'Point', coordinates: [127.138, 37.5891] }
    },
    {
      type: 'Feature',
      id: 692,
      properties: {
        sagoDate: '2020-08-24',
        sagoNo: '20200137',
        sido: '경기도',
        sigungu: '고양시 일산동구',
        dong: '',
        addr: '백석동 1532',
        fullAdr: '경기도 고양시 일산동구  백석동 1532',
        SEC_CD: '41285106',
        sagoLon: '126.789',
        sagoLat: '37.6403',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '집중호우로 인한 지반약화· 장기 지반침하로 인한 땅꺼짐',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-30'
      },
      geometry: { type: 'Point', coordinates: [126.789, 37.6403] }
    },
    {
      type: 'Feature',
      id: 693,
      properties: {
        sagoDate: '2020-08-04',
        sagoNo: '20200140',
        sido: '경기도',
        sigungu: '화성시',
        dong: '동탄면',
        addr: '동탄신리천로5길 103-50',
        fullAdr: '경기도 화성시 동탄면 동탄신리천로5길 103-50',
        SEC_CD: '41590133',
        sagoLon: '127.14',
        sagoLat: '37.1894',
        sinkWidth: '10',
        sinkExtend: '30',
        sinkDepth: '0.03',
        grdKind: '',
        sagoDetail: '집중호우로 인한 기타 매설물 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-02-28'
      },
      geometry: { type: 'Point', coordinates: [127.14, 37.1894] }
    },
    {
      type: 'Feature',
      id: 694,
      properties: {
        sagoDate: '2020-08-11',
        sagoNo: '20200141',
        sido: '경기도',
        sigungu: '화성시',
        dong: '석우동',
        addr: '47',
        fullAdr: '경기도 화성시 석우동 47',
        SEC_CD: '41590128',
        sagoLon: '127.081',
        sagoLat: '37.2114',
        sinkWidth: '10',
        sinkExtend: '40',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '지하수위에 따른 지반 전면침하 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-15'
      },
      geometry: { type: 'Point', coordinates: [127.081, 37.2114] }
    },
    {
      type: 'Feature',
      id: 695,
      properties: {
        sagoDate: '2020-08-04',
        sagoNo: '20200146',
        sido: '경기도',
        sigungu: '파주시',
        dong: '군내면 백연리',
        addr: '228-2',
        fullAdr: '경기도 파주시 군내면 백연리 228-2',
        SEC_CD: '41480380',
        sagoLon: '126.731',
        sagoLat: '37.9096',
        sinkWidth: '2.5',
        sinkExtend: '6.5',
        sinkDepth: '1.1',
        grdKind: '',
        sagoDetail:
          '도로횡단배수관(갈비철관) 노후에 따른 관 부식에 따른 누수 발생으로 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-11-13'
      },
      geometry: { type: 'Point', coordinates: [126.731, 37.9096] }
    },
    {
      type: 'Feature',
      id: 696,
      properties: {
        sagoDate: '2020-08-22',
        sagoNo: '20200147',
        sido: '경기도',
        sigungu: '남양주시',
        dong: '도농동',
        addr: '도농로중촌2길 26-24(다산동 4046번지 일원)',
        fullAdr:
          '경기도 남양주시 도농동 도농로중촌2길 26-24(다산동 4046번지 일원)',
        SEC_CD: '41360112',
        sagoLon: '127.156',
        sagoLat: '37.6089',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail:
          "상하수도관 노후 손상으로 지반침하 및 함몰 발생·(대상지는 '92.12.13. 도농동 주거환경개선공사 사업 완료된 구간으로· 노후화에 따른 파손 예상됨.)",
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-22'
      },
      geometry: { type: 'Point', coordinates: [127.156, 37.6089] }
    },
    {
      type: 'Feature',
      id: 697,
      properties: {
        sagoDate: '2020-08-21',
        sagoNo: '20200148',
        sido: '전라남도',
        sigungu: '여수시',
        dong: '소라면',
        addr: '1099',
        fullAdr: '전라남도 여수시 소라면 1099',
        SEC_CD: '46130310',
        sagoLon: '127.637',
        sagoLat: '34.7684',
        sinkWidth: '3.2',
        sinkExtend: '5',
        sinkDepth: '0.5',
        grdKind: '기타',
        sagoDetail:
          '아스팔트 포장 면과 L형 측구 부분 틈 발생으로 인한 우수침투로 침하.',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '2',
        trStatus: '복구완료',
        trAmt: '2000000',
        trFnDate: '2020-08-24'
      },
      geometry: { type: 'Point', coordinates: [127.637, 34.7684] }
    },
    {
      type: 'Feature',
      id: 698,
      properties: {
        sagoDate: '2020-08-04',
        sagoNo: '20200150',
        sido: '충청남도',
        sigungu: '아산시',
        dong: '탕정면 갈산리',
        addr: '63-3',
        fullAdr: '충청남도 아산시 탕정면 갈산리 63-3',
        SEC_CD: '44200330',
        sagoLon: '127.071',
        sagoLat: '36.8027',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '2.5',
        grdKind: '기타',
        sagoDetail:
          '민간 사업자의 전원주택 개발시 우수맨홀 접합 불량으로 인한 토사 유출로 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-05'
      },
      geometry: { type: 'Point', coordinates: [127.071, 36.8027] }
    },
    {
      type: 'Feature',
      id: 699,
      properties: {
        sagoDate: '2020-08-19',
        sagoNo: '20200151',
        sido: '강원특별자치도',
        sigungu: '춘천시',
        dong: '온의동',
        addr: '585-2',
        fullAdr: '강원특별자치도 춘천시 온의동 585-2',
        SEC_CD: '51110121',
        sagoLon: '127.722',
        sagoLat: '37.8694',
        sinkWidth: '10',
        sinkExtend: '30',
        sinkDepth: '0.2',
        grdKind: '기타',
        sagoDetail: '펌프장 공사 중 다짐불량으로 인하여 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-24'
      },
      geometry: { type: 'Point', coordinates: [127.722, 37.8694] }
    },
    {
      type: 'Feature',
      id: 700,
      properties: {
        sagoDate: '2020-08-03',
        sagoNo: '20200152',
        sido: '충청북도',
        sigungu: '충주시',
        dong: '산척면 송강리',
        addr: '268-2번지 일원(성불사 앞)',
        fullAdr: '충청북도 충주시 산척면 송강리 268-2번지 일원(성불사 앞)',
        SEC_CD: '43130410',
        sagoLon: '127.977',
        sagoLat: '37.101',
        sinkWidth: '2',
        sinkExtend: '10',
        sinkDepth: '20',
        grdKind: '',
        sagoDetail: '지하 횡배수관의 토사유입 및 배수로 연결부 미흡(추정)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-08-14'
      },
      geometry: { type: 'Point', coordinates: [127.977, 37.101] }
    },
    {
      type: 'Feature',
      id: 701,
      properties: {
        sagoDate: '2020-08-26',
        sagoNo: '20200154',
        sido: '경기도',
        sigungu: '수원시 영통구',
        dong: '영통동',
        addr: '영통동 1055',
        fullAdr: '경기도 수원시 영통구 영통동 영통동 1055',
        SEC_CD: '41117105',
        sagoLon: '127.08',
        sagoLat: '37.2606',
        sinkWidth: '0.5',
        sinkExtend: '1.2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '지하철 시설물(엘리베이터)옆 지중 토사유실로 지반함몰발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '72840',
        trFnDate: '2020-08-26'
      },
      geometry: { type: 'Point', coordinates: [127.08, 37.2606] }
    },
    {
      type: 'Feature',
      id: 702,
      properties: {
        sagoDate: '2020-08-10',
        sagoNo: '20200155',
        sido: '경기도',
        sigungu: '과천시',
        dong: '갈현동',
        addr: '661',
        fullAdr: '경기도 과천시 갈현동 661',
        SEC_CD: '41290103',
        sagoLon: '126.989',
        sagoLat: '37.423',
        sinkWidth: '1.2',
        sinkExtend: '2',
        sinkDepth: '0.3',
        grdKind: '기타',
        sagoDetail: '하수관 파손으로 인한 토사유출 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-10'
      },
      geometry: { type: 'Point', coordinates: [126.989, 37.423] }
    },
    {
      type: 'Feature',
      id: 703,
      properties: {
        sagoDate: '2020-08-12',
        sagoNo: '20200156',
        sido: '경기도',
        sigungu: '과천시',
        dong: '과천동',
        addr: '605-1',
        fullAdr: '경기도 과천시 과천동 605-1',
        SEC_CD: '41290105',
        sagoLon: '126.996',
        sagoLat: '37.4455',
        sinkWidth: '2',
        sinkExtend: '3',
        sinkDepth: '0.3',
        grdKind: '기타',
        sagoDetail:
          '과천동 605-1 일원 (남태령 지하차도 상부도로 상하행선)··도로하부 매설된 하천박스 누수로 인한 토사 유출 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-12'
      },
      geometry: { type: 'Point', coordinates: [126.996, 37.4455] }
    },
    {
      type: 'Feature',
      id: 704,
      properties: {
        sagoDate: '2020-08-09',
        sagoNo: '20200159',
        sido: '전라남도',
        sigungu: '화순군',
        dong: '화순읍',
        addr: '서양로 322· 1주차장 도로 입구',
        fullAdr: '전라남도 화순군 화순읍 서양로 322· 1주차장 도로 입구',
        SEC_CD: '46790250',
        sagoLon: '127.003',
        sagoLat: '35.0604',
        sinkWidth: '4',
        sinkExtend: '3.5',
        sinkDepth: '4',
        grdKind: '',
        sagoDetail: '우수 맨홀 주위 지하 우수관 접속부위 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '45800000',
        trFnDate: '2020-08-30'
      },
      geometry: { type: 'Point', coordinates: [127.003, 35.0604] }
    },
    {
      type: 'Feature',
      id: 705,
      properties: {
        sagoDate: '2020-08-03',
        sagoNo: '20200160',
        sido: '경기도',
        sigungu: '용인시 처인구',
        dong: '',
        addr: '양지면 남곡리 130-2',
        fullAdr: '경기도 용인시 처인구  양지면 남곡리 130-2',
        SEC_CD: '41461360',
        sagoLon: '127.286',
        sagoLat: '37.233',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 파손으로 토사 유실 및 침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-09-10'
      },
      geometry: { type: 'Point', coordinates: [127.286, 37.233] }
    },
    {
      type: 'Feature',
      id: 706,
      properties: {
        sagoDate: '2020-08-09',
        sagoNo: '20200161',
        sido: '경기도',
        sigungu: '',
        dong: '',
        addr: '양지면 814',
        fullAdr: '경기도   양지면 814',
        SEC_CD: '41461360',
        sagoLon: '127.286',
        sagoLat: '37.2329',
        sinkWidth: '2',
        sinkExtend: '1.5',
        sinkDepth: '0.7',
        grdKind: '',
        sagoDetail: '하수관 파손으로 토사 유실 및 침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-09-10'
      },
      geometry: { type: 'Point', coordinates: [127.286, 37.2329] }
    },
    {
      type: 'Feature',
      id: 707,
      properties: {
        sagoDate: '2020-08-12',
        sagoNo: '20200162',
        sido: '전북특별자치도',
        sigungu: '임실군',
        dong: '임실읍 이도리',
        addr: '517-6번지',
        fullAdr: '전북특별자치도 임실군 임실읍 이도리 517-6번지',
        SEC_CD: '52750250',
        sagoLon: '127.282',
        sagoLat: '35.6099',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail: '우수관로 누수로 인한 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-12'
      },
      geometry: { type: 'Point', coordinates: [127.282, 35.6099] }
    },
    {
      type: 'Feature',
      id: 708,
      properties: {
        sagoDate: '2020-08-07',
        sagoNo: '20200166',
        sido: '강원특별자치도',
        sigungu: '영월군',
        dong: '영월읍 삼옥리',
        addr: '산121-14',
        fullAdr: '강원특별자치도 영월군 영월읍 삼옥리 산121-14',
        SEC_CD: '51750250',
        sagoLon: '128.514',
        sagoLat: '37.219',
        sinkWidth: '9',
        sinkExtend: '60',
        sinkDepth: '2.2',
        grdKind: '',
        sagoDetail: '폭우로 인한 지반연약',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-12-30'
      },
      geometry: { type: 'Point', coordinates: [128.514, 37.219] }
    },
    {
      type: 'Feature',
      id: 709,
      properties: {
        sagoDate: '2020-08-07',
        sagoNo: '20200170',
        sido: '강원특별자치도',
        sigungu: '춘천시',
        dong: '퇴계동',
        addr: '영서로 2241',
        fullAdr: '강원특별자치도 춘천시 퇴계동 영서로 2241',
        SEC_CD: '51110123',
        sagoLon: '127.725',
        sagoLat: '37.8628',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.7',
        grdKind: '',
        sagoDetail: '지반 노후화로 인한 단순 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-07'
      },
      geometry: { type: 'Point', coordinates: [127.725, 37.8628] }
    },
    {
      type: 'Feature',
      id: 710,
      properties: {
        sagoDate: '2020-08-20',
        sagoNo: '20200171',
        sido: '강원특별자치도',
        sigungu: '춘천시',
        dong: '근화동',
        addr: '496-4',
        fullAdr: '강원특별자치도 춘천시 근화동 496-4',
        SEC_CD: '51110117',
        sagoLon: '127.713',
        sagoLat: '37.8803',
        sinkWidth: '3',
        sinkExtend: '10',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 파손으로 인한 토사 유출로 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-12-31'
      },
      geometry: { type: 'Point', coordinates: [127.713, 37.8803] }
    },
    {
      type: 'Feature',
      id: 711,
      properties: {
        sagoDate: '2020-08-22',
        sagoNo: '20200172',
        sido: '강원특별자치도',
        sigungu: '춘천시',
        dong: '근화동',
        addr: '291-20',
        fullAdr: '강원특별자치도 춘천시 근화동 291-20',
        SEC_CD: '51110117',
        sagoLon: '127.72',
        sagoLat: '37.8722',
        sinkWidth: '0.2',
        sinkExtend: '0.2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '지반 노후화로 인한 단순 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-22'
      },
      geometry: { type: 'Point', coordinates: [127.72, 37.8722] }
    },
    {
      type: 'Feature',
      id: 712,
      properties: {
        sagoDate: '2020-08-25',
        sagoNo: '20200173',
        sido: '강원특별자치도',
        sigungu: '춘천시',
        dong: '온의동',
        addr: '536-5',
        fullAdr: '강원특별자치도 춘천시 온의동 536-5',
        SEC_CD: '51110121',
        sagoLon: '127.718',
        sagoLat: '37.8592',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '4',
        grdKind: '',
        sagoDetail: '하수관 파손으로 인한 토사 유출로 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-26'
      },
      geometry: { type: 'Point', coordinates: [127.718, 37.8592] }
    },
    {
      type: 'Feature',
      id: 713,
      properties: {
        sagoDate: '2020-08-21',
        sagoNo: '20200178',
        sido: '강원특별자치도',
        sigungu: '양구군',
        dong: '남면 죽리',
        addr: '283번지',
        fullAdr: '강원특별자치도 양구군 남면 죽리 283번지',
        SEC_CD: '51800315',
        sagoLon: '127.994',
        sagoLat: '38.0738',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-09-21'
      },
      geometry: { type: 'Point', coordinates: [127.994, 38.0738] }
    },
    {
      type: 'Feature',
      id: 714,
      properties: {
        sagoDate: '2020-08-21',
        sagoNo: '20200179',
        sido: '강원특별자치도',
        sigungu: '양구군',
        dong: '동면 팔랑리',
        addr: '1373-21',
        fullAdr: '강원특별자치도 양구군 동면 팔랑리 1373-21',
        SEC_CD: '51800320',
        sagoLon: '128.061',
        sagoLat: '38.2226',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-09-21'
      },
      geometry: { type: 'Point', coordinates: [128.061, 38.2226] }
    },
    {
      type: 'Feature',
      id: 715,
      properties: {
        sagoDate: '2020-08-21',
        sagoNo: '20200180',
        sido: '강원특별자치도',
        sigungu: '양구군',
        dong: '남면 가오작리',
        addr: '612-2',
        fullAdr: '강원특별자치도 양구군 남면 가오작리 612-2',
        SEC_CD: '51800315',
        sagoLon: '128.046',
        sagoLat: '38.1422',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-09-21'
      },
      geometry: { type: 'Point', coordinates: [128.046, 38.1422] }
    },
    {
      type: 'Feature',
      id: 716,
      properties: {
        sagoDate: '2020-08-05',
        sagoNo: '20200182',
        sido: '광주광역시',
        sigungu: '남구',
        dong: '월산동',
        addr: '921-1 주변',
        fullAdr: '광주광역시 남구 월산동 921-1 주변',
        SEC_CD: '29140106',
        sagoLon: '126.887',
        sagoLat: '35.1475',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관로 파손으로 인하여 토사가 유실되어 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-13'
      },
      geometry: { type: 'Point', coordinates: [126.887, 35.1475] }
    },
    {
      type: 'Feature',
      id: 717,
      properties: {
        sagoDate: '2020-08-09',
        sagoNo: '20200183',
        sido: '광주광역시',
        sigungu: '남구',
        dong: '주월동',
        addr: '1283-1 주변',
        fullAdr: '광주광역시 남구 주월동 1283-1 주변',
        SEC_CD: '29155115',
        sagoLon: '126.904',
        sagoLat: '35.1332',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail:
          '하수BOX 벽체 손상으로 인하여 토사가 유실되어 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-09'
      },
      geometry: { type: 'Point', coordinates: [126.904, 35.1332] }
    },
    {
      type: 'Feature',
      id: 718,
      properties: {
        sagoDate: '2020-08-12',
        sagoNo: '20200184',
        sido: '광주광역시',
        sigungu: '남구',
        dong: '봉선동',
        addr: '1040-12',
        fullAdr: '광주광역시 남구 봉선동 1040-12',
        SEC_CD: '29155115',
        sagoLon: '126.911',
        sagoLat: '35.1273',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '빗물받이 연결관 접합부 파손으로 인하여 토사가 유실되어 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-12'
      },
      geometry: { type: 'Point', coordinates: [126.911, 35.1273] }
    },
    {
      type: 'Feature',
      id: 719,
      properties: {
        sagoDate: '2020-08-13',
        sagoNo: '20200185',
        sido: '광주광역시',
        sigungu: '남구',
        dong: '진월동',
        addr: '262-4',
        fullAdr: '광주광역시 남구 진월동 262-4',
        SEC_CD: '29155108',
        sagoLon: '126.899',
        sagoLat: '35.1193',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail:
          '하수관로 연결부 파손으로 인하여 토사가 유실되어 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-14'
      },
      geometry: { type: 'Point', coordinates: [126.899, 35.1193] }
    },
    {
      type: 'Feature',
      id: 720,
      properties: {
        sagoDate: '2020-08-20',
        sagoNo: '20200186',
        sido: '광주광역시',
        sigungu: '남구',
        dong: '방림동',
        addr: '555-20',
        fullAdr: '광주광역시 남구 방림동 555-20',
        SEC_CD: '29155114',
        sagoLon: '126.914',
        sagoLat: '35.1302',
        sinkWidth: '1',
        sinkExtend: '3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수관로 파손으로 인하여 토사가 유실되어 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-09-01'
      },
      geometry: { type: 'Point', coordinates: [126.914, 35.1302] }
    },
    {
      type: 'Feature',
      id: 721,
      properties: {
        sagoDate: '2020-08-09',
        sagoNo: '20200192',
        sido: '부산광역시',
        sigungu: '금정구',
        dong: '서동',
        addr: '서동 502-4번지 앞(서동시립도서관~금정구장애인복지관 앞)',
        fullAdr:
          '부산광역시 금정구 서동 서동 502-4번지 앞(서동시립도서관~금정구장애인복지관 앞)',
        SEC_CD: '26410110',
        sagoLon: '129.099',
        sagoLat: '35.2197',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-11'
      },
      geometry: { type: 'Point', coordinates: [129.099, 35.2197] }
    },
    {
      type: 'Feature',
      id: 722,
      properties: {
        sagoDate: '2020-08-19',
        sagoNo: '20200193',
        sido: '부산광역시',
        sigungu: '금정구',
        dong: '장전동',
        addr: '장전동 732 일원(금정산쌍용예가 사거리)',
        fullAdr:
          '부산광역시 금정구 장전동 장전동 732 일원(금정산쌍용예가 사거리)',
        SEC_CD: '26410108',
        sagoLon: '129.084',
        sagoLat: '35.2421',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-21'
      },
      geometry: { type: 'Point', coordinates: [129.084, 35.2421] }
    },
    {
      type: 'Feature',
      id: 723,
      properties: {
        sagoDate: '2020-08-28',
        sagoNo: '20200194',
        sido: '부산광역시',
        sigungu: '금정구',
        dong: '부곡동',
        addr: '동현로 65-1 앞(동광교회 앞)',
        fullAdr: '부산광역시 금정구 부곡동 동현로 65-1 앞(동광교회 앞)',
        SEC_CD: '26410109',
        sagoLon: '129.096',
        sagoLat: '35.2227',
        sinkWidth: '3',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-30'
      },
      geometry: { type: 'Point', coordinates: [129.096, 35.2227] }
    },
    {
      type: 'Feature',
      id: 724,
      properties: {
        sagoDate: '2020-08-09',
        sagoNo: '20200199',
        sido: '인천광역시',
        sigungu: '연수구',
        dong: '송도동',
        addr: '신항대로 892번길 50',
        fullAdr: '인천광역시 연수구 송도동 신항대로 892번길 50',
        SEC_CD: '28185106',
        sagoLon: '126.62',
        sagoLat: '37.3533',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '화강암류',
        sagoDetail: '면적 4(가로2*세로2) 되메우기 불량사고',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-09'
      },
      geometry: { type: 'Point', coordinates: [126.62, 37.3533] }
    },
    {
      type: 'Feature',
      id: 725,
      properties: {
        sagoDate: '2020-08-11',
        sagoNo: '20200200',
        sido: '인천광역시',
        sigungu: '연수구',
        dong: '송도동',
        addr: '신항대로 892번길 50',
        fullAdr: '인천광역시 연수구 송도동 신항대로 892번길 50',
        SEC_CD: '28185106',
        sagoLon: '126.62',
        sagoLat: '37.3533',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '2.25',
        grdKind: '화강암류',
        sagoDetail: '다짐(되메우기) 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-11'
      },
      geometry: { type: 'Point', coordinates: [126.62, 37.3533] }
    },
    {
      type: 'Feature',
      id: 726,
      properties: {
        sagoDate: '2020-08-25',
        sagoNo: '20200202',
        sido: '전라남도',
        sigungu: '보성군',
        dong: '미력면 화방리',
        addr: '96',
        fullAdr: '전라남도 보성군 미력면 화방리 96',
        SEC_CD: '46780320',
        sagoLon: '127.128',
        sagoLat: '34.8119',
        sinkWidth: '1.2',
        sinkExtend: '8',
        sinkDepth: '0.4',
        grdKind: '',
        sagoDetail: '우수관로 파손에 따른 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-09-07'
      },
      geometry: { type: 'Point', coordinates: [127.128, 34.8119] }
    },
    {
      type: 'Feature',
      id: 727,
      properties: {
        sagoDate: '2020-08-25',
        sagoNo: '20200203',
        sido: '전라남도',
        sigungu: '보성군',
        dong: '미력면 반룡리',
        addr: '843-51',
        fullAdr: '전라남도 보성군 미력면 반룡리 843-51',
        SEC_CD: '46780320',
        sagoLon: '127.107',
        sagoLat: '34.7868',
        sinkWidth: '0.8',
        sinkExtend: '2',
        sinkDepth: '0.8',
        grdKind: '',
        sagoDetail: '배수관로 파손에 따른 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-09-07'
      },
      geometry: { type: 'Point', coordinates: [127.107, 34.7868] }
    },
    {
      type: 'Feature',
      id: 728,
      properties: {
        sagoDate: '2020-08-20',
        sagoNo: '20200204',
        sido: '전라남도',
        sigungu: '보성군',
        dong: '미력면 반룡리',
        addr: '811-6',
        fullAdr: '전라남도 보성군 미력면 반룡리 811-6',
        SEC_CD: '46780320',
        sagoLon: '127.11',
        sagoLat: '34.7871',
        sinkWidth: '1.2',
        sinkExtend: '12',
        sinkDepth: '0.7',
        grdKind: '',
        sagoDetail: '배수관로 노후화에 따른 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-09-07'
      },
      geometry: { type: 'Point', coordinates: [127.11, 34.7871] }
    },
    {
      type: 'Feature',
      id: 729,
      properties: {
        sagoDate: '2020-08-10',
        sagoNo: '20200205',
        sido: '서울특별시',
        sigungu: '송파구',
        dong: '신천동',
        addr: '올림픽로 435',
        fullAdr: '서울특별시 송파구 신천동 올림픽로 435',
        SEC_CD: '11710102',
        sagoLon: '127.107',
        sagoLat: '37.522',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail:
          '오수관과 맨홀 접합부 결함· 맨홀 구체 천공부위 마감불량으로 맨홀주변 동공발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-09-22'
      },
      geometry: { type: 'Point', coordinates: [127.107, 37.522] }
    },
    {
      type: 'Feature',
      id: 730,
      properties: {
        sagoDate: '2020-08-03',
        sagoNo: '20200206',
        sido: '서울특별시',
        sigungu: '강남구',
        dong: '논현동',
        addr: '279(을지병원 앞)',
        fullAdr: '서울특별시 강남구 논현동 279(을지병원 앞)',
        SEC_CD: '11680108',
        sagoLon: '127.029',
        sagoLat: '37.5196',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '장기압밀 침하 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-10'
      },
      geometry: { type: 'Point', coordinates: [127.029, 37.5196] }
    },
    {
      type: 'Feature',
      id: 731,
      properties: {
        sagoDate: '2020-08-10',
        sagoNo: '20200209',
        sido: '인천광역시',
        sigungu: '남동구',
        dong: '구월동',
        addr: '73-3',
        fullAdr: '인천광역시 남동구 구월동 73-3',
        SEC_CD: '28200101',
        sagoLon: '126.711',
        sagoLat: '37.4568',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-11'
      },
      geometry: { type: 'Point', coordinates: [126.711, 37.4568] }
    },
    {
      type: 'Feature',
      id: 732,
      properties: {
        sagoDate: '2020-08-19',
        sagoNo: '20200210',
        sido: '서울특별시',
        sigungu: '구로구',
        dong: '구로동',
        addr: '514-2',
        fullAdr: '서울특별시 구로구 구로동 514-2',
        SEC_CD: '11530102',
        sagoLon: '126.886',
        sagoLat: '37.4987',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '노후 하수관 연결부 파손에 따른 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-20'
      },
      geometry: { type: 'Point', coordinates: [126.886, 37.4987] }
    },
    {
      type: 'Feature',
      id: 733,
      properties: {
        sagoDate: '2020-08-03',
        sagoNo: '20200211',
        sido: '서울특별시',
        sigungu: '강북구',
        dong: '미아동',
        addr: '1261-279',
        fullAdr: '서울특별시 강북구 미아동 1261-279',
        SEC_CD: '11305101',
        sagoLon: '127.024',
        sagoLat: '37.6124',
        sinkWidth: '1',
        sinkExtend: '10',
        sinkDepth: '2.5',
        grdKind: '기타',
        sagoDetail: '상수도관 D50mm 누수',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-29'
      },
      geometry: { type: 'Point', coordinates: [127.024, 37.6124] }
    },
    {
      type: 'Feature',
      id: 734,
      properties: {
        sagoDate: '2020-08-12',
        sagoNo: '20200214',
        sido: '서울특별시',
        sigungu: '영등포구',
        dong: '신길동',
        addr: '10-40',
        fullAdr: '서울특별시 영등포구 신길동 10-40',
        SEC_CD: '11560132',
        sagoLon: '126.922',
        sagoLat: '37.5162',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1.2',
        grdKind: '기타',
        sagoDetail: '노들길 빗물받이 벽체 파손으로 인한 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-12'
      },
      geometry: { type: 'Point', coordinates: [126.922, 37.5162] }
    },
    {
      type: 'Feature',
      id: 735,
      properties: {
        sagoDate: '2020-08-03',
        sagoNo: '20200216',
        sido: '인천광역시',
        sigungu: '서구',
        dong: '가좌동',
        addr: '139',
        fullAdr: '인천광역시 서구 가좌동 139',
        SEC_CD: '28260112',
        sagoLon: '126.67',
        sagoLat: '37.4971',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '다짐미흡으로 인한 장기침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-03'
      },
      geometry: { type: 'Point', coordinates: [126.67, 37.4971] }
    },
    {
      type: 'Feature',
      id: 736,
      properties: {
        sagoDate: '2020-08-10',
        sagoNo: '20200217',
        sido: '인천광역시',
        sigungu: '서구',
        dong: '가좌동',
        addr: '158',
        fullAdr: '인천광역시 서구 가좌동 158',
        SEC_CD: '28260112',
        sagoLon: '126.673',
        sagoLat: '37.4916',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '다짐미흡으로 인한 장기침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-10'
      },
      geometry: { type: 'Point', coordinates: [126.673, 37.4916] }
    },
    {
      type: 'Feature',
      id: 737,
      properties: {
        sagoDate: '2020-08-11',
        sagoNo: '20200218',
        sido: '인천광역시',
        sigungu: '서구',
        dong: '가정동',
        addr: '566-5 한국빌라206동 앞 도로',
        fullAdr: '인천광역시 서구 가정동 566-5 한국빌라206동 앞 도로',
        SEC_CD: '28260108',
        sagoLon: '126.678',
        sagoLat: '37.5149',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '집중호우로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-11'
      },
      geometry: { type: 'Point', coordinates: [126.678, 37.5149] }
    },
    {
      type: 'Feature',
      id: 738,
      properties: {
        sagoDate: '2020-08-13',
        sagoNo: '20200219',
        sido: '인천광역시',
        sigungu: '서구',
        dong: '가좌동',
        addr: '가좌역2번출입구 E/V옆 전방 도로',
        fullAdr: '인천광역시 서구 가좌동 가좌역2번출입구 E/V옆 전방 도로',
        SEC_CD: '28260112',
        sagoLon: '126.675',
        sagoLat: '37.49',
        sinkWidth: '2.9',
        sinkExtend: '8',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '집중호우로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-13'
      },
      geometry: { type: 'Point', coordinates: [126.675, 37.49] }
    },
    {
      type: 'Feature',
      id: 739,
      properties: {
        sagoDate: '2020-08-13',
        sagoNo: '20200220',
        sido: '인천광역시',
        sigungu: '서구',
        dong: '가좌동',
        addr: '가좌역2번출입구 E/V 옆 환기구 전면 보도',
        fullAdr:
          '인천광역시 서구 가좌동 가좌역2번출입구 E/V 옆 환기구 전면 보도',
        SEC_CD: '28260112',
        sagoLon: '126.675',
        sagoLat: '37.4899',
        sinkWidth: '2.6',
        sinkExtend: '9',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '집중호우로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-13'
      },
      geometry: { type: 'Point', coordinates: [126.675, 37.4899] }
    },
    {
      type: 'Feature',
      id: 740,
      properties: {
        sagoDate: '2020-08-15',
        sagoNo: '20200221',
        sido: '인천광역시',
        sigungu: '서구',
        dong: '석남동',
        addr: '469-11',
        fullAdr: '인천광역시 서구 석남동 469-11',
        SEC_CD: '28260110',
        sagoLon: '126.678',
        sagoLat: '37.5129',
        sinkWidth: '0.5',
        sinkExtend: '0.7',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '흙 다지기 및 되메우기 재시공 및 포장복구',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-15'
      },
      geometry: { type: 'Point', coordinates: [126.678, 37.5129] }
    },
    {
      type: 'Feature',
      id: 741,
      properties: {
        sagoDate: '2020-08-11',
        sagoNo: '20200222',
        sido: '인천광역시',
        sigungu: '서구',
        dong: '마전동',
        addr: '완정로34번길',
        fullAdr: '인천광역시 서구 마전동 완정로34번길',
        SEC_CD: '28260113',
        sagoLon: '126.672',
        sagoLat: '37.5975',
        sinkWidth: '2.5',
        sinkExtend: '0.5',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '집중호우로 인한 모래유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-11'
      },
      geometry: { type: 'Point', coordinates: [126.672, 37.5975] }
    },
    {
      type: 'Feature',
      id: 742,
      properties: {
        sagoDate: '2020-08-03',
        sagoNo: '20200232',
        sido: '충청북도',
        sigungu: '음성군',
        dong: '금왕읍 무극리',
        addr: '무극리 산 17-5',
        fullAdr: '충청북도 음성군 금왕읍 무극리 무극리 산 17-5',
        SEC_CD: '43770253',
        sagoLon: '127.585',
        sagoLat: '36.9912',
        sinkWidth: '3',
        sinkExtend: '5',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '우수관로 노후파손으로 토사유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-05'
      },
      geometry: { type: 'Point', coordinates: [127.585, 36.9912] }
    },
    {
      type: 'Feature',
      id: 743,
      properties: {
        sagoDate: '2020-08-06',
        sagoNo: '20200234',
        sido: '충청북도',
        sigungu: '음성군',
        dong: '금왕읍 무극리',
        addr: '523-3',
        fullAdr: '충청북도 음성군 금왕읍 무극리 523-3',
        SEC_CD: '43770253',
        sagoLon: '127.587',
        sagoLat: '36.9912',
        sinkWidth: '1',
        sinkExtend: '2.5',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '오수관 파손으로 토사유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-10'
      },
      geometry: { type: 'Point', coordinates: [127.587, 36.9912] }
    },
    {
      type: 'Feature',
      id: 744,
      properties: {
        sagoDate: '2020-08-10',
        sagoNo: '20200235',
        sido: '충청북도',
        sigungu: '음성군',
        dong: '생극면',
        addr: '차평리 457-4',
        fullAdr: '충청북도 음성군 생극면 차평리 457-4',
        SEC_CD: '43770360',
        sagoLon: '127.603',
        sagoLat: '37.0503',
        sinkWidth: '0.6',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-17'
      },
      geometry: { type: 'Point', coordinates: [127.603, 37.0503] }
    },
    {
      type: 'Feature',
      id: 745,
      properties: {
        sagoDate: '2020-08-24',
        sagoNo: '20200240',
        sido: '경기도',
        sigungu: '의정부시',
        dong: '녹양동',
        addr: '114-25',
        fullAdr: '경기도 의정부시 녹양동 114-25',
        SEC_CD: '41150111',
        sagoLon: '127.046',
        sagoLat: '37.7558',
        sinkWidth: '2',
        sinkExtend: '15',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '지하매설물 매설로인한 굴착 공사 후 다짐불량·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-20'
      },
      geometry: { type: 'Point', coordinates: [127.046, 37.7558] }
    },
    {
      type: 'Feature',
      id: 746,
      properties: {
        sagoDate: '2020-08-08',
        sagoNo: '20200246',
        sido: '전북특별자치도',
        sigungu: '고창군',
        dong: '고창읍 월산리',
        addr: '산12-8번지',
        fullAdr: '전북특별자치도 고창군 고창읍 월산리 산12-8번지',
        SEC_CD: '52790250',
        sagoLon: '126.742',
        sagoLat: '35.42',
        sinkWidth: '1',
        sinkExtend: '20',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '절개지 축대 크랙 발생으로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-11-30'
      },
      geometry: { type: 'Point', coordinates: [126.742, 35.42] }
    },
    {
      type: 'Feature',
      id: 747,
      properties: {
        sagoDate: '2020-08-21',
        sagoNo: '20200249',
        sido: '광주광역시',
        sigungu: '북구',
        dong: '동림동',
        addr: '26-1',
        fullAdr: '광주광역시 북구 동림동 26-1',
        SEC_CD: '29170109',
        sagoLon: '126.883',
        sagoLat: '35.1708',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.8',
        grdKind: '',
        sagoDetail: '우수받이 연결부위 세굴',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-21'
      },
      geometry: { type: 'Point', coordinates: [126.883, 35.1708] }
    },
    {
      type: 'Feature',
      id: 748,
      properties: {
        sagoDate: '2020-08-10',
        sagoNo: '20200250',
        sido: '광주광역시',
        sigungu: '북구',
        dong: '운암동',
        addr: '1051-11',
        fullAdr: '광주광역시 북구 운암동 1051-11',
        SEC_CD: '29170109',
        sagoLon: '126.873',
        sagoLat: '35.1822',
        sinkWidth: '0.6',
        sinkExtend: '2',
        sinkDepth: '0.8',
        grdKind: '',
        sagoDetail: '우수맨홀 세굴',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-08-10'
      },
      geometry: { type: 'Point', coordinates: [126.873, 35.1822] }
    },
    {
      type: 'Feature',
      id: 749,
      properties: {
        sagoDate: '2020-08-20',
        sagoNo: '20200252',
        sido: '경기도',
        sigungu: '부천시',
        dong: '',
        addr: '중동 859-5',
        fullAdr: '경기도 부천시  중동 859-5',
        SEC_CD: '41192108',
        sagoLon: '126.769',
        sagoLat: '37.4866',
        sinkWidth: '3',
        sinkExtend: '2.2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '폐하수관 미처리에 따른 지반 침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-21'
      },
      geometry: { type: 'Point', coordinates: [126.769, 37.4866] }
    },
    {
      type: 'Feature',
      id: 750,
      properties: {
        sagoDate: '2020-08-08',
        sagoNo: '20200254',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '',
        addr: '계림동 1070',
        fullAdr: '광주광역시 동구  계림동 1070',
        SEC_CD: '29110109',
        sagoLon: '126.924',
        sagoLat: '35.1636',
        sinkWidth: '0.7',
        sinkExtend: '5',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-12-31'
      },
      geometry: { type: 'Point', coordinates: [126.924, 35.1636] }
    },
    {
      type: 'Feature',
      id: 751,
      properties: {
        sagoDate: '2020-08-08',
        sagoNo: '20200255',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '',
        addr: '학동 994',
        fullAdr: '광주광역시 동구  학동 994',
        SEC_CD: '29110122',
        sagoLon: '126.922',
        sagoLat: '35.1403',
        sinkWidth: '2',
        sinkExtend: '1.5',
        sinkDepth: '0.4',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-12-31'
      },
      geometry: { type: 'Point', coordinates: [126.922, 35.1403] }
    },
    {
      type: 'Feature',
      id: 752,
      properties: {
        sagoDate: '2020-08-08',
        sagoNo: '20200256',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '',
        addr: '운림동 628',
        fullAdr: '광주광역시 동구  운림동 628',
        SEC_CD: '29110121',
        sagoLon: '126.942',
        sagoLat: '35.1325',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-12-31'
      },
      geometry: { type: 'Point', coordinates: [126.942, 35.1325] }
    },
    {
      type: 'Feature',
      id: 753,
      properties: {
        sagoDate: '2020-08-10',
        sagoNo: '20200257',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '',
        addr: '산수동 62-1',
        fullAdr: '광주광역시 동구  산수동 62-1',
        SEC_CD: '29110110',
        sagoLon: '126.937',
        sagoLat: '35.1563',
        sinkWidth: '1.2',
        sinkExtend: '1.2',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-12-31'
      },
      geometry: { type: 'Point', coordinates: [126.937, 35.1563] }
    },
    {
      type: 'Feature',
      id: 754,
      properties: {
        sagoDate: '2020-08-11',
        sagoNo: '20200258',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '',
        addr: '금남로 4가역',
        fullAdr: '광주광역시 동구  금남로 4가역',
        SEC_CD: '29110102',
        sagoLon: '126.914',
        sagoLat: '35.1511',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-12-31'
      },
      geometry: { type: 'Point', coordinates: [126.914, 35.1511] }
    },
    {
      type: 'Feature',
      id: 755,
      properties: {
        sagoDate: '2020-08-11',
        sagoNo: '20200259',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '',
        addr: '수기동 80',
        fullAdr: '광주광역시 동구  수기동 80',
        SEC_CD: '29110104',
        sagoLon: '126.91',
        sagoLat: '35.1499',
        sinkWidth: '0.5',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '3',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-12-31'
      },
      geometry: { type: 'Point', coordinates: [126.91, 35.1499] }
    },
    {
      type: 'Feature',
      id: 756,
      properties: {
        sagoDate: '2020-08-02',
        sagoNo: '20200264',
        sido: '경상북도',
        sigungu: '영주시',
        dong: '상망동',
        addr: '898',
        fullAdr: '경상북도 영주시 상망동 898',
        SEC_CD: '47210102',
        sagoLon: '128.646',
        sagoLat: '36.837',
        sinkWidth: '2',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '빗물 배수 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-11-30'
      },
      geometry: { type: 'Point', coordinates: [128.646, 36.837] }
    },
    {
      type: 'Feature',
      id: 757,
      properties: {
        sagoDate: '2020-08-12',
        sagoNo: '20200265',
        sido: '경상북도',
        sigungu: '영주시',
        dong: '상망동',
        addr: '57-8',
        fullAdr: '경상북도 영주시 상망동 57-8',
        SEC_CD: '47210102',
        sagoLon: '128.661',
        sagoLat: '36.8384',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '지반 약화',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-31'
      },
      geometry: { type: 'Point', coordinates: [128.661, 36.8384] }
    },
    {
      type: 'Feature',
      id: 758,
      properties: {
        sagoDate: '2020-08-09',
        sagoNo: '20200266',
        sido: '경상북도',
        sigungu: '경산시',
        dong: '정평동',
        addr: '138-2',
        fullAdr: '경상북도 경산시 정평동 138-2',
        SEC_CD: '47290111',
        sagoLon: '128.728',
        sagoLat: '35.8339',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '0.5',
        grdKind: '기타',
        sagoDetail: '콘크리트 포장층 다짐불량에 따른 집중호우시 자연침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '2000000',
        trFnDate: '2020-08-09'
      },
      geometry: { type: 'Point', coordinates: [128.728, 35.8339] }
    },
    {
      type: 'Feature',
      id: 759,
      properties: {
        sagoDate: '2020-08-12',
        sagoNo: '20200268',
        sido: '경상북도',
        sigungu: '포항시 남구',
        dong: '연일읍 생지리',
        addr: '408',
        fullAdr: '경상북도 포항시 남구 연일읍 생지리 408',
        SEC_CD: '47111253',
        sagoLon: '129.346',
        sagoLat: '35.9993',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '부근의 하천에서 흙 유실 원인·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-17'
      },
      geometry: { type: 'Point', coordinates: [129.346, 35.9993] }
    },
    {
      type: 'Feature',
      id: 760,
      properties: {
        sagoDate: '2020-08-10',
        sagoNo: '20200271',
        sido: '경상북도',
        sigungu: '포항시 남구',
        dong: '연일읍 생지리',
        addr: '287-11',
        fullAdr: '경상북도 포항시 남구 연일읍 생지리 287-11',
        SEC_CD: '47111253',
        sagoLon: '129.344',
        sagoLat: '35.9971',
        sinkWidth: '2',
        sinkExtend: '4',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관로 노후 및 파손으로 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-17'
      },
      geometry: { type: 'Point', coordinates: [129.344, 35.9971] }
    },
    {
      type: 'Feature',
      id: 761,
      properties: {
        sagoDate: '2020-08-14',
        sagoNo: '20200273',
        sido: '광주광역시',
        sigungu: '북구',
        dong: '대촌동',
        addr: '870-14 (대원장례식장 입구)',
        fullAdr: '광주광역시 북구 대촌동 870-14 (대원장례식장 입구)',
        SEC_CD: '29170140',
        sagoLon: '126.848',
        sagoLat: '35.2347',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '굴착부분 되메우기 재시공 및 포장 복구 완료',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-16'
      },
      geometry: { type: 'Point', coordinates: [126.848, 35.2347] }
    },
    {
      type: 'Feature',
      id: 762,
      properties: {
        sagoDate: '2020-08-16',
        sagoNo: '20200274',
        sido: '광주광역시',
        sigungu: '북구',
        dong: '양산동',
        addr: '205-1',
        fullAdr: '광주광역시 북구 양산동 205-1',
        SEC_CD: '29170128',
        sagoLon: '126.879',
        sagoLat: '35.2137',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '흄관 부식',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-19'
      },
      geometry: { type: 'Point', coordinates: [126.879, 35.2137] }
    },
    {
      type: 'Feature',
      id: 763,
      properties: {
        sagoDate: '2020-08-18',
        sagoNo: '20200275',
        sido: '광주광역시',
        sigungu: '북구',
        dong: '오치동',
        addr: '1036-1',
        fullAdr: '광주광역시 북구 오치동 1036-1',
        SEC_CD: '29170115',
        sagoLon: '126.913',
        sagoLat: '35.1867',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '암거박스 하단 접합부 세굴',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-20'
      },
      geometry: { type: 'Point', coordinates: [126.913, 35.1867] }
    },
    {
      type: 'Feature',
      id: 764,
      properties: {
        sagoDate: '2020-08-19',
        sagoNo: '20200276',
        sido: '광주광역시',
        sigungu: '북구',
        dong: '운암동',
        addr: '1578',
        fullAdr: '광주광역시 북구 운암동 1578',
        SEC_CD: '29170109',
        sagoLon: '126.882',
        sagoLat: '35.1708',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '도시가스 흄관 관통',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-20'
      },
      geometry: { type: 'Point', coordinates: [126.882, 35.1708] }
    },
    {
      type: 'Feature',
      id: 765,
      properties: {
        sagoDate: '2020-08-21',
        sagoNo: '20200277',
        sido: '광주광역시',
        sigungu: '북구',
        dong: '양산동',
        addr: '330 (KT&amp;G)',
        fullAdr: '광주광역시 북구 양산동 330 (KT&amp;G)',
        SEC_CD: '29170128',
        sagoLon: '126.877',
        sagoLat: '35.2051',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '암거박스 기초 하단부 세굴',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-08-22'
      },
      geometry: { type: 'Point', coordinates: [126.877, 35.2051] }
    },
    {
      type: 'Feature',
      id: 766,
      properties: {
        sagoDate: '2020-08-08',
        sagoNo: '20200279',
        sido: '울산광역시',
        sigungu: '동구',
        dong: '',
        addr: '해수욕장길28',
        fullAdr: '울산광역시 동구  해수욕장길28',
        SEC_CD: '31170103',
        sagoLon: '129.43',
        sagoLat: '35.4963',
        sinkWidth: '3',
        sinkExtend: '1.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '우수관 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-12'
      },
      geometry: { type: 'Point', coordinates: [129.43, 35.4963] }
    },
    {
      type: 'Feature',
      id: 767,
      properties: {
        sagoDate: '2020-08-11',
        sagoNo: '20200280',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '비아동',
        addr: '비아동707-14번지 주변',
        fullAdr: '광주광역시 광산구 비아동 비아동707-14번지 주변',
        SEC_CD: '29200112',
        sagoLon: '126.825',
        sagoLat: '35.2209',
        sinkWidth: '0.3',
        sinkExtend: '0.5',
        sinkDepth: '1.8',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-12'
      },
      geometry: { type: 'Point', coordinates: [126.825, 35.2209] }
    },
    {
      type: 'Feature',
      id: 768,
      properties: {
        sagoDate: '2020-08-28',
        sagoNo: '20200281',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '수완동',
        addr: '수완동929',
        fullAdr: '광주광역시 광산구 수완동 수완동929',
        SEC_CD: '29200114',
        sagoLon: '126.824',
        sagoLat: '35.1982',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '0.4',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-29'
      },
      geometry: { type: 'Point', coordinates: [126.824, 35.1982] }
    },
    {
      type: 'Feature',
      id: 769,
      properties: {
        sagoDate: '2020-08-07',
        sagoNo: '20200283',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '',
        addr: '상무대로 쌍촌역에서 화정역 방면',
        fullAdr: '광주광역시 서구  상무대로 쌍촌역에서 화정역 방면',
        SEC_CD: '29140118',
        sagoLon: '126.869',
        sagoLat: '35.1517',
        sinkWidth: '7',
        sinkExtend: '9',
        sinkDepth: '2.5',
        grdKind: '',
        sagoDetail: '다짐(되메우기)불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-14'
      },
      geometry: { type: 'Point', coordinates: [126.869, 35.1517] }
    },
    {
      type: 'Feature',
      id: 770,
      properties: {
        sagoDate: '2020-08-08',
        sagoNo: '20200284',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '',
        addr: '제봉로 15',
        fullAdr: '광주광역시 동구  제봉로 15',
        SEC_CD: '29110122',
        sagoLon: '126.922',
        sagoLat: '35.1403',
        sinkWidth: '3',
        sinkExtend: '40',
        sinkDepth: '0.03',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-12'
      },
      geometry: { type: 'Point', coordinates: [126.922, 35.1403] }
    },
    {
      type: 'Feature',
      id: 771,
      properties: {
        sagoDate: '2020-08-11',
        sagoNo: '20200285',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '',
        addr: '제봉로 27',
        fullAdr: '광주광역시 동구  제봉로 27',
        SEC_CD: '29110122',
        sagoLon: '126.921',
        sagoLat: '35.1412',
        sinkWidth: '3',
        sinkExtend: '4',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-13'
      },
      geometry: { type: 'Point', coordinates: [126.921, 35.1412] }
    },
    {
      type: 'Feature',
      id: 772,
      properties: {
        sagoDate: '2020-08-12',
        sagoNo: '20200286',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '',
        addr: '남문로 소태역 주차장 입구',
        fullAdr: '광주광역시 동구  남문로 소태역 주차장 입구',
        SEC_CD: '29110119',
        sagoLon: '126.933',
        sagoLat: '35.1223',
        sinkWidth: '5',
        sinkExtend: '10',
        sinkDepth: '2.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-13'
      },
      geometry: { type: 'Point', coordinates: [126.933, 35.1223] }
    },
    {
      type: 'Feature',
      id: 773,
      properties: {
        sagoDate: '2020-08-19',
        sagoNo: '20200289',
        sido: '광주광역시',
        sigungu: '북구',
        dong: '',
        addr: '삼소로 대원장례식장 입구 3거리 교차로',
        fullAdr: '광주광역시 북구  삼소로 대원장례식장 입구 3거리 교차로',
        SEC_CD: '29170140',
        sagoLon: '126.85',
        sagoLat: '35.234',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-21'
      },
      geometry: { type: 'Point', coordinates: [126.85, 35.234] }
    },
    {
      type: 'Feature',
      id: 774,
      properties: {
        sagoDate: '2020-08-08',
        sagoNo: '20200291',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '',
        addr: '서구 치평동 754-1',
        fullAdr: '광주광역시 서구  서구 치평동 754-1',
        SEC_CD: '29140120',
        sagoLon: '126.828',
        sagoLat: '35.1598',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-19'
      },
      geometry: { type: 'Point', coordinates: [126.828, 35.1598] }
    },
    {
      type: 'Feature',
      id: 775,
      properties: {
        sagoDate: '2020-08-07',
        sagoNo: '20200292',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '마륵동',
        addr: '171-12 인근',
        fullAdr: '광주광역시 서구 마륵동 171-12 인근',
        SEC_CD: '29140131',
        sagoLon: '126.841',
        sagoLat: '35.1428',
        sinkWidth: '2',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-12'
      },
      geometry: { type: 'Point', coordinates: [126.841, 35.1428] }
    },
    {
      type: 'Feature',
      id: 776,
      properties: {
        sagoDate: '2020-08-07',
        sagoNo: '20200293',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '쌍촌동',
        addr: '877-6번지 인근',
        fullAdr: '광주광역시 서구 쌍촌동 877-6번지 인근',
        SEC_CD: '29140118',
        sagoLon: '126.857',
        sagoLat: '35.1462',
        sinkWidth: '5',
        sinkExtend: '2',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-12'
      },
      geometry: { type: 'Point', coordinates: [126.857, 35.1462] }
    },
    {
      type: 'Feature',
      id: 777,
      properties: {
        sagoDate: '2020-08-10',
        sagoNo: '20200295',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '',
        addr: '쌍촌동 1229번지 인근',
        fullAdr: '광주광역시 서구  쌍촌동 1229번지 인근',
        SEC_CD: '29140118',
        sagoLon: '126.87',
        sagoLat: '35.1466',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '우수받이 연결관의 연결 미흡으로 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-12'
      },
      geometry: { type: 'Point', coordinates: [126.87, 35.1466] }
    },
    {
      type: 'Feature',
      id: 778,
      properties: {
        sagoDate: '2020-08-07',
        sagoNo: '20200298',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '화정동',
        addr: '1708',
        fullAdr: '광주광역시 서구 화정동 1708',
        SEC_CD: '29140121',
        sagoLon: '126.877',
        sagoLat: '35.1589',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-09'
      },
      geometry: { type: 'Point', coordinates: [126.877, 35.1589] }
    },
    {
      type: 'Feature',
      id: 779,
      properties: {
        sagoDate: '2020-08-10',
        sagoNo: '20200299',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '쌍촌동',
        addr: '1229',
        fullAdr: '광주광역시 서구 쌍촌동 1229',
        SEC_CD: '29140118',
        sagoLon: '126.87',
        sagoLat: '35.1469',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-12'
      },
      geometry: { type: 'Point', coordinates: [126.87, 35.1469] }
    },
    {
      type: 'Feature',
      id: 780,
      properties: {
        sagoDate: '2020-08-03',
        sagoNo: '20200302',
        sido: '경상남도',
        sigungu: '창원시 마산합포구',
        dong: '',
        addr: '가포로8· 선진빌딩 앞',
        fullAdr: '경상남도 창원시 마산합포구  가포로8· 선진빌딩 앞',
        SEC_CD: '48125158',
        sagoLon: '128.558',
        sagoLat: '35.1794',
        sinkWidth: '2',
        sinkExtend: '0.5',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '집중호우로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-19'
      },
      geometry: { type: 'Point', coordinates: [128.558, 35.1794] }
    },
    {
      type: 'Feature',
      id: 781,
      properties: {
        sagoDate: '2020-08-03',
        sagoNo: '20200303',
        sido: '경상남도',
        sigungu: '창원시 마산합포구',
        dong: '',
        addr: '월영동 733',
        fullAdr: '경상남도 창원시 마산합포구  월영동 733',
        SEC_CD: '48125134',
        sagoLon: '128.561',
        sagoLat: '35.1707',
        sinkWidth: '1.5',
        sinkExtend: '6',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '집중호우로 인한 침하(도로 틈새로 빗물 유입되어 토사유출)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-19'
      },
      geometry: { type: 'Point', coordinates: [128.561, 35.1707] }
    },
    {
      type: 'Feature',
      id: 782,
      properties: {
        sagoDate: '2020-08-18',
        sagoNo: '20200304',
        sido: '서울특별시',
        sigungu: '마포구',
        dong: '대흥동',
        addr: '서강대길40',
        fullAdr: '서울특별시 마포구 대흥동 서강대길40',
        SEC_CD: '11440108',
        sagoLon: '126.943',
        sagoLat: '37.5488',
        sinkWidth: '0.2',
        sinkExtend: '0.2',
        sinkDepth: '6',
        grdKind: '기타',
        sagoDetail:
          '○ 지하시설물관계자(하수도· 가스· 전력) 점검결과 이상없음· ○ 굴착조사결과 뚜렷한 원인을 찾을 수 없었음',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-18'
      },
      geometry: { type: 'Point', coordinates: [126.943, 37.5488] }
    },
    {
      type: 'Feature',
      id: 783,
      properties: {
        sagoDate: '2020-08-11',
        sagoNo: '20200306',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '',
        addr: '월곡동 579-7',
        fullAdr: '광주광역시 광산구  월곡동 579-7',
        SEC_CD: '29200128',
        sagoLon: '126.817',
        sagoLat: '35.1687',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '하수관 노후화에 따른 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-12'
      },
      geometry: { type: 'Point', coordinates: [126.817, 35.1687] }
    },
    {
      type: 'Feature',
      id: 784,
      properties: {
        sagoDate: '2020-08-26',
        sagoNo: '20200307',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '',
        addr: '월계동 847-1',
        fullAdr: '광주광역시 광산구  월계동 847-1',
        SEC_CD: '29200115',
        sagoLon: '126.84',
        sagoLat: '35.211',
        sinkWidth: '0.8',
        sinkExtend: '0.8',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '도로내 다짐 불량 등에 의한 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-26'
      },
      geometry: { type: 'Point', coordinates: [126.84, 35.211] }
    },
    {
      type: 'Feature',
      id: 785,
      properties: {
        sagoDate: '2020-08-11',
        sagoNo: '20200308',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '',
        addr: '비아동 707-8',
        fullAdr: '광주광역시 광산구  비아동 707-8',
        SEC_CD: '29200112',
        sagoLon: '126.825',
        sagoLat: '35.2205',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 접합 부위 불량에 따른 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-20'
      },
      geometry: { type: 'Point', coordinates: [126.825, 35.2205] }
    },
    {
      type: 'Feature',
      id: 786,
      properties: {
        sagoDate: '2020-08-09',
        sagoNo: '20200309',
        sido: '전북특별자치도',
        sigungu: '순창군',
        dong: '유등면',
        addr: '오교리 990-149',
        fullAdr: '전북특별자치도 순창군 유등면 오교리 990-149',
        SEC_CD: '52770390',
        sagoLon: '127.196',
        sagoLat: '35.3679',
        sinkWidth: '3',
        sinkExtend: '3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '0',
        trFnDate: '2020-08-11'
      },
      geometry: { type: 'Point', coordinates: [127.196, 35.3679] }
    },
    {
      type: 'Feature',
      id: 787,
      properties: {
        sagoDate: '2020-08-21',
        sagoNo: '20200310',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '도천동',
        addr: '도천동621-15번지 주변',
        fullAdr: '광주광역시 광산구 도천동 도천동621-15번지 주변',
        SEC_CD: '29200113',
        sagoLon: '126.815',
        sagoLat: '35.2095',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-22'
      },
      geometry: { type: 'Point', coordinates: [126.815, 35.2095] }
    },
    {
      type: 'Feature',
      id: 788,
      properties: {
        sagoDate: '2020-08-12',
        sagoNo: '20200311',
        sido: '부산광역시',
        sigungu: '동래구',
        dong: '사직동',
        addr: '802-4번지 일원',
        fullAdr: '부산광역시 동래구 사직동 802-4번지 일원',
        SEC_CD: '26260109',
        sagoLon: '129.061',
        sagoLat: '35.1958',
        sinkWidth: '0.8',
        sinkExtend: '0.8',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '한전 전력구 인근 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-08-30'
      },
      geometry: { type: 'Point', coordinates: [129.061, 35.1958] }
    },
    {
      type: 'Feature',
      id: 789,
      properties: {
        sagoDate: '2020-09-15',
        sagoNo: '20200100',
        sido: '서울특별시',
        sigungu: '금천구',
        dong: '시흥동',
        addr: '918-20',
        fullAdr: '서울특별시 금천구 시흥동 918-20',
        SEC_CD: '11545103',
        sagoLon: '126.908',
        sagoLat: '37.4491',
        sinkWidth: '2.5',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '호상편마암',
        sagoDetail:
          '하수박스 접합부 관경D200mm와 빗물받이 연결관 D300mm가 밀실하게 접합되지 않아 노면수 장기 누수로 지반침하 발생 추정·동공부의 토사는 하수박스 벽체 하단의 쇄굴부위 등으로 유실·(빗물받이 연결관 결함+하수박스 벽체하단 쇄굴 복합작용)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '20000000',
        trFnDate: '2020-09-17'
      },
      geometry: { type: 'Point', coordinates: [126.908, 37.4491] }
    },
    {
      type: 'Feature',
      id: 790,
      properties: {
        sagoDate: '2020-09-02',
        sagoNo: '20200143',
        sido: '경기도',
        sigungu: '화성시',
        dong: '장안면 어은리',
        addr: '1-41',
        fullAdr: '경기도 화성시 장안면 어은리 1-41',
        SEC_CD: '41590370',
        sagoLon: '126.842',
        sagoLat: '37.0989',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '하자보수 기간 후 자연침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-09-05'
      },
      geometry: { type: 'Point', coordinates: [126.842, 37.0989] }
    },
    {
      type: 'Feature',
      id: 791,
      properties: {
        sagoDate: '2020-09-02',
        sagoNo: '20200153',
        sido: '충청남도',
        sigungu: '부여군',
        dong: '구룡면 주정리',
        addr: '187-6',
        fullAdr: '충청남도 부여군 구룡면 주정리 187-6',
        SEC_CD: '44760350',
        sagoLon: '126.823',
        sagoLat: '36.257',
        sinkWidth: '2',
        sinkExtend: '4',
        sinkDepth: '0.1',
        grdKind: '기타',
        sagoDetail: '상수관로 손상으로 인한 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-07-06'
      },
      geometry: { type: 'Point', coordinates: [126.823, 36.257] }
    },
    {
      type: 'Feature',
      id: 792,
      properties: {
        sagoDate: '2020-09-25',
        sagoNo: '20200157',
        sido: '경기도',
        sigungu: '과천시',
        dong: '갈현동',
        addr: '21-13',
        fullAdr: '경기도 과천시 갈현동 21-13',
        SEC_CD: '41290103',
        sagoLon: '126.985',
        sagoLat: '37.4153',
        sinkWidth: '1.5',
        sinkExtend: '3',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '하수관 파손으로 인한 토사유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-09-25'
      },
      geometry: { type: 'Point', coordinates: [126.985, 37.4153] }
    },
    {
      type: 'Feature',
      id: 793,
      properties: {
        sagoDate: '2020-09-01',
        sagoNo: '20200168',
        sido: '경기도',
        sigungu: '의왕시',
        dong: '월암동',
        addr: '351-3',
        fullAdr: '경기도 의왕시 월암동 351-3',
        SEC_CD: '41430110',
        sagoLon: '126.955',
        sagoLat: '37.3113',
        sinkWidth: '3',
        sinkExtend: '0.8',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '상하수관공사 부실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2020-09-01'
      },
      geometry: { type: 'Point', coordinates: [126.955, 37.3113] }
    },
    {
      type: 'Feature',
      id: 794,
      properties: {
        sagoDate: '2020-09-22',
        sagoNo: '20200169',
        sido: '경기도',
        sigungu: '의왕시',
        dong: '초평동',
        addr: '253-8',
        fullAdr: '경기도 의왕시 초평동 253-8',
        SEC_CD: '41430111',
        sagoLon: '126.943',
        sagoLat: '37.3165',
        sinkWidth: '1.5',
        sinkExtend: '1',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '다짐(되메우기) 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2020-09-22'
      },
      geometry: { type: 'Point', coordinates: [126.943, 37.3165] }
    },
    {
      type: 'Feature',
      id: 795,
      properties: {
        sagoDate: '2020-09-21',
        sagoNo: '20200174',
        sido: '강원특별자치도',
        sigungu: '춘천시',
        dong: '소양로3가',
        addr: '185',
        fullAdr: '강원특별자치도 춘천시 소양로3가 185',
        SEC_CD: '51110115',
        sagoLon: '127.724',
        sagoLat: '37.8821',
        sinkWidth: '0.2',
        sinkExtend: '0.2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '굴착공사 후 다짐 불량으로 인한 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-09-21'
      },
      geometry: { type: 'Point', coordinates: [127.724, 37.8821] }
    },
    {
      type: 'Feature',
      id: 796,
      properties: {
        sagoDate: '2020-09-24',
        sagoNo: '20200175',
        sido: '강원특별자치도',
        sigungu: '춘천시',
        dong: '효자동',
        addr: '178-54',
        fullAdr: '강원특별자치도 춘천시 효자동 178-54',
        SEC_CD: '51110112',
        sagoLon: '127.738',
        sagoLat: '37.8695',
        sinkWidth: '1.2',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '지반 노후화로 인한 단순 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-09-24'
      },
      geometry: { type: 'Point', coordinates: [127.738, 37.8695] }
    },
    {
      type: 'Feature',
      id: 797,
      properties: {
        sagoDate: '2020-09-01',
        sagoNo: '20200189',
        sido: '충청남도',
        sigungu: '공주시',
        dong: '옥룡동',
        addr: '309-16번지',
        fullAdr: '충청남도 공주시 옥룡동 309-16번지',
        SEC_CD: '44150109',
        sagoLon: '127.134',
        sagoLat: '36.4561',
        sinkWidth: '8',
        sinkExtend: '3',
        sinkDepth: '0.05',
        grdKind: '충척층',
        sagoDetail:
          '다짐(되메우기) 불량 혹은 상하수도 노후화에 따라 지반침하 발생 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '500000',
        trFnDate: '2021-07-24'
      },
      geometry: { type: 'Point', coordinates: [127.134, 36.4561] }
    },
    {
      type: 'Feature',
      id: 798,
      properties: {
        sagoDate: '2020-09-03',
        sagoNo: '20200212',
        sido: '대전광역시',
        sigungu: '동구',
        dong: '가양동',
        addr: '416-7번지',
        fullAdr: '대전광역시 동구 가양동 416-7번지',
        SEC_CD: '30110114',
        sagoLon: '127.446',
        sagoLat: '36.3456',
        sinkWidth: '1.7',
        sinkExtend: '2.3',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '하수관 파손으로 인한 씽크홀 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-09-04'
      },
      geometry: { type: 'Point', coordinates: [127.446, 36.3456] }
    },
    {
      type: 'Feature',
      id: 799,
      properties: {
        sagoDate: '2020-09-11',
        sagoNo: '20200213',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '만년동',
        addr: '400',
        fullAdr: '대전광역시 서구 만년동 400',
        SEC_CD: '30170128',
        sagoLon: '127.383',
        sagoLat: '36.3698',
        sinkWidth: '2',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수관로(D300mm) 노후 손상으로 함몰 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '7500000',
        trFnDate: '2020-09-12'
      },
      geometry: { type: 'Point', coordinates: [127.383, 36.3698] }
    },
    {
      type: 'Feature',
      id: 800,
      properties: {
        sagoDate: '2020-09-15',
        sagoNo: '20200233',
        sido: '전북특별자치도',
        sigungu: '군산시',
        dong: '비응도동',
        addr: '42(43-1 앞)',
        fullAdr: '전북특별자치도 군산시 비응도동 42(43-1 앞)',
        SEC_CD: '52130148',
        sagoLon: '126.531',
        sagoLat: '35.942',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 파손으로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-09-15'
      },
      geometry: { type: 'Point', coordinates: [126.531, 35.942] }
    },
    {
      type: 'Feature',
      id: 801,
      properties: {
        sagoDate: '2020-09-01',
        sagoNo: '20200278',
        sido: '광주광역시',
        sigungu: '북구',
        dong: '두암동',
        addr: '875-8',
        fullAdr: '광주광역시 북구 두암동 875-8',
        SEC_CD: '29170114',
        sagoLon: '126.928',
        sagoLat: '35.1698',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '암거박스 측면 세굴',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-09-01'
      },
      geometry: { type: 'Point', coordinates: [126.928, 35.1698] }
    },
    {
      type: 'Feature',
      id: 802,
      properties: {
        sagoDate: '2020-09-07',
        sagoNo: '20200287',
        sido: '세종특별자치시',
        sigungu: '세종특별자치시',
        dong: '',
        addr: '보람동 한누리대로 2144(스마트허브2)앞 인도',
        fullAdr:
          '세종특별자치시 세종특별자치시  보람동 한누리대로 2144(스마트허브2)앞 인도',
        SEC_CD: '36110103',
        sagoLon: '127.288',
        sagoLat: '36.4789',
        sinkWidth: '3',
        sinkExtend: '5',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '지반 다짐 부실로 토사유실에 따른 보도침하·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-09-12'
      },
      geometry: { type: 'Point', coordinates: [127.288, 36.4789] }
    },
    {
      type: 'Feature',
      id: 803,
      properties: {
        sagoDate: '2020-09-03',
        sagoNo: '20200290',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '',
        addr: '필문대로 남광주고가 하부',
        fullAdr: '광주광역시 동구  필문대로 남광주고가 하부',
        SEC_CD: '29110122',
        sagoLon: '126.923',
        sagoLat: '35.1393',
        sinkWidth: '2',
        sinkExtend: '4',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-09-03'
      },
      geometry: { type: 'Point', coordinates: [126.923, 35.1393] }
    },
    {
      type: 'Feature',
      id: 804,
      properties: {
        sagoDate: '2020-10-22',
        sagoNo: '20200102',
        sido: '서울특별시',
        sigungu: '성동구',
        dong: '',
        addr: '성수1가2동 13-17',
        fullAdr: '서울특별시 성동구  성수1가2동 13-17',
        SEC_CD: '11200115',
        sagoLon: '127.052',
        sagoLat: '37.5502',
        sinkWidth: '4',
        sinkExtend: '5',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail:
          '○ 지반침하 원인에 대한 전문가의 다수 의견은 복합적인 요인으로 발생· - 노후 상·하수관로· 교통하중· 건축현장 공사 영향 · ○ 하수관로 및 상수관로 파손으로 인접 현장으로 토석류가 다량 유출됨에 따라 급격한 지반침하 발생· ○ 건축공사 주변 하수관로 조사 시 시멘트풀 등으로 인한 유수 장애가 있음을 확인 건축현장 차수 그라우팅 시 하수관로에 영향을 주었을 것으로 추정· ○ 신축공사장 터파기 공사에 의한 지반침하로 상수도관 파손으로 추정· ※ 상수관로 및 하수관로 손괴에 대해 신축공사장 현장 관계자 사실 확인서 제출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-11-16'
      },
      geometry: { type: 'Point', coordinates: [127.052, 37.5502] }
    },
    {
      type: 'Feature',
      id: 805,
      properties: {
        sagoDate: '2020-10-05',
        sagoNo: '20200142',
        sido: '부산광역시',
        sigungu: '연제구',
        dong: '연산동',
        addr: '월드컵대로140(연산동1516-16·연산역 5번출구 앞)',
        fullAdr:
          '부산광역시 연제구 연산동 월드컵대로140(연산동1516-16·연산역 5번출구 앞)',
        SEC_CD: '26470102',
        sagoLon: '129.081',
        sagoLat: '35.1864',
        sinkWidth: '3',
        sinkExtend: '8',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail: '원인 불명확',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-10-05'
      },
      geometry: { type: 'Point', coordinates: [129.081, 35.1864] }
    },
    {
      type: 'Feature',
      id: 806,
      properties: {
        sagoDate: '2020-10-07',
        sagoNo: '20200144',
        sido: '경기도',
        sigungu: '화성시',
        dong: '진안동',
        addr: '881-1',
        fullAdr: '경기도 화성시 진안동 881-1',
        SEC_CD: '41590116',
        sagoLon: '127.04',
        sagoLat: '37.2126',
        sinkWidth: '1.3',
        sinkExtend: '1',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '하자보수 기간 후 자연침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-10-10'
      },
      geometry: { type: 'Point', coordinates: [127.04, 37.2126] }
    },
    {
      type: 'Feature',
      id: 807,
      properties: {
        sagoDate: '2020-10-26',
        sagoNo: '20200300',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '쌍촌동',
        addr: '쌍촌로48번길 인근',
        fullAdr: '광주광역시 서구 쌍촌동 쌍촌로48번길 인근',
        SEC_CD: '29140118',
        sagoLon: '126.865',
        sagoLat: '35.1491',
        sinkWidth: '1',
        sinkExtend: '1.2',
        sinkDepth: '1.1',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-10-29'
      },
      geometry: { type: 'Point', coordinates: [126.865, 35.1491] }
    },
    {
      type: 'Feature',
      id: 808,
      properties: {
        sagoDate: '2020-11-25',
        sagoNo: '20200128',
        sido: '경상남도',
        sigungu: '김해시',
        dong: '부원동',
        addr: '981-23',
        fullAdr: '경상남도 김해시 부원동 981-23',
        SEC_CD: '48250103',
        sagoLon: '128.885',
        sagoLat: '35.2247',
        sinkWidth: '0.6',
        sinkExtend: '0.6',
        sinkDepth: '0.36',
        grdKind: '기타',
        sagoDetail: '도로 교량 밑 구조물 이탈로 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-11-25'
      },
      geometry: { type: 'Point', coordinates: [128.885, 35.2247] }
    },
    {
      type: 'Feature',
      id: 809,
      properties: {
        sagoDate: '2020-11-06',
        sagoNo: '20200145',
        sido: '경기도',
        sigungu: '화성시',
        dong: '북양동',
        addr: '남양읍 북양리 113',
        fullAdr: '경기도 화성시 북양동 남양읍 북양리 113',
        SEC_CD: '41590262',
        sagoLon: '126.841',
        sagoLat: '37.2259',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '하자보수 기간 후 자연침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-11-08'
      },
      geometry: { type: 'Point', coordinates: [126.841, 37.2259] }
    },
    {
      type: 'Feature',
      id: 810,
      properties: {
        sagoDate: '2020-11-19',
        sagoNo: '20200176',
        sido: '강원특별자치도',
        sigungu: '춘천시',
        dong: '후평동',
        addr: '616-6',
        fullAdr: '강원특별자치도 춘천시 후평동 616-6',
        SEC_CD: '51110112',
        sagoLon: '127.74',
        sagoLat: '37.8741',
        sinkWidth: '0.8',
        sinkExtend: '0.8',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '노후 하수관 파손으로 누수 및 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-11-19'
      },
      geometry: { type: 'Point', coordinates: [127.74, 37.8741] }
    },
    {
      type: 'Feature',
      id: 811,
      properties: {
        sagoDate: '2020-11-29',
        sagoNo: '20200282',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '비아동',
        addr: '비아동677-1번지 주변',
        fullAdr: '광주광역시 광산구 비아동 비아동677-1번지 주변',
        SEC_CD: '29200112',
        sagoLon: '126.818',
        sagoLat: '35.2225',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-11-30'
      },
      geometry: { type: 'Point', coordinates: [126.818, 35.2225] }
    },
    {
      type: 'Feature',
      id: 812,
      properties: {
        sagoDate: '2020-11-28',
        sagoNo: '20200301',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '화정동',
        addr: '178-8',
        fullAdr: '광주광역시 서구 화정동 178-8',
        SEC_CD: '29140119',
        sagoLon: '126.882',
        sagoLat: '35.1569',
        sinkWidth: '0.2',
        sinkExtend: '0.2',
        sinkDepth: '2.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-11-30'
      },
      geometry: { type: 'Point', coordinates: [126.882, 35.1569] }
    },
    {
      type: 'Feature',
      id: 813,
      properties: {
        sagoDate: '2020-12-07',
        sagoNo: '20200181',
        sido: '강원특별자치도',
        sigungu: '양구군',
        dong: '동면 팔랑리',
        addr: '1459-2',
        fullAdr: '강원특별자치도 양구군 동면 팔랑리 1459-2',
        SEC_CD: '51800320',
        sagoLon: '128.051',
        sagoLat: '38.2171',
        sinkWidth: '1.2',
        sinkExtend: '1.2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-01-07'
      },
      geometry: { type: 'Point', coordinates: [128.051, 38.2171] }
    },
    {
      type: 'Feature',
      id: 814,
      properties: {
        sagoDate: '2021-01-13',
        sagoNo: '20210104',
        sido: '경기도',
        sigungu: '안산시 상록구',
        dong: '사동',
        addr: '1639-24',
        fullAdr: '경기도 안산시 상록구 사동 1639-24',
        SEC_CD: '41271103',
        sagoLon: '126.828',
        sagoLat: '37.2868',
        sinkWidth: '10',
        sinkExtend: '50',
        sinkDepth: '5',
        grdKind: '기타',
        sagoDetail:
          '시공시 과굴착· 엄지말뚝 근입깊이 부족· 설계시 검토되지 않은 중장비 사용·설계 변경시 지반정수 과다 적용· 지반수위 상이적용',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-06-06'
      },
      geometry: { type: 'Point', coordinates: [126.828, 37.2868] }
    },
    {
      type: 'Feature',
      id: 815,
      properties: {
        sagoDate: '2021-01-01',
        sagoNo: '20210112',
        sido: '경상북도',
        sigungu: '영주시',
        dong: '하망동',
        addr: '광복로92번길 15',
        fullAdr: '경상북도 영주시 하망동 광복로92번길 15',
        SEC_CD: '47210103',
        sagoLon: '128.629',
        sagoLat: '36.8269',
        sinkWidth: '4',
        sinkExtend: '10',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-01-30'
      },
      geometry: { type: 'Point', coordinates: [128.629, 36.8269] }
    },
    {
      type: 'Feature',
      id: 816,
      properties: {
        sagoDate: '2021-01-11',
        sagoNo: '20210113',
        sido: '전라남도',
        sigungu: '함평군',
        dong: '함평읍',
        addr: '석성리 521-33번지',
        fullAdr: '전라남도 함평군 함평읍 석성리 521-33번지',
        SEC_CD: '46860250',
        sagoLon: '126.445',
        sagoLat: '35.0873',
        sinkWidth: '4',
        sinkExtend: '100',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-24'
      },
      geometry: { type: 'Point', coordinates: [126.445, 35.0873] }
    },
    {
      type: 'Feature',
      id: 817,
      properties: {
        sagoDate: '2021-01-12',
        sagoNo: '20210114',
        sido: '전라남도',
        sigungu: '함평군',
        dong: '손불면',
        addr: '산남리 84-11',
        fullAdr: '전라남도 함평군 손불면 산남리 84-11',
        SEC_CD: '46860310',
        sagoLon: '126.428',
        sagoLat: '35.1232',
        sinkWidth: '3',
        sinkExtend: '70',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-09-07'
      },
      geometry: { type: 'Point', coordinates: [126.428, 35.1232] }
    },
    {
      type: 'Feature',
      id: 818,
      properties: {
        sagoDate: '2021-02-22',
        sagoNo: '20210001',
        sido: '경기도',
        sigungu: '포천시',
        dong: '일동면 기산리',
        addr: '82-2번지 일원',
        fullAdr: '경기도 포천시 일동면 기산리 82-2번지 일원',
        SEC_CD: '41650370',
        sagoLon: '127.319',
        sagoLat: '37.9591',
        sinkWidth: '0.15',
        sinkExtend: '0.15',
        sinkDepth: '3',
        grdKind: '기타',
        sagoDetail:
          '침하발생 위치에 접하여 D1000규모 우수관이 지나가는 것으로 보아 관로 손상에 의한 침하인 것으로 추정 됨',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-03-26'
      },
      geometry: { type: 'Point', coordinates: [127.319, 37.9591] }
    },
    {
      type: 'Feature',
      id: 819,
      properties: {
        sagoDate: '2021-02-19',
        sagoNo: '20210025',
        sido: '전북특별자치도',
        sigungu: '익산시',
        dong: '마동',
        addr: '153-37(동부시장 오거리)',
        fullAdr: '전북특별자치도 익산시 마동 153-37(동부시장 오거리)',
        SEC_CD: '52140112',
        sagoLon: '126.966',
        sagoLat: '35.9457',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 파손으로 인한 지반침하 사고',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-02-19'
      },
      geometry: { type: 'Point', coordinates: [126.966, 35.9457] }
    },
    {
      type: 'Feature',
      id: 820,
      properties: {
        sagoDate: '2021-02-01',
        sagoNo: '20210115',
        sido: '전라남도',
        sigungu: '여수시',
        dong: '',
        addr: '웅천로90',
        fullAdr: '전라남도 여수시  웅천로90',
        SEC_CD: '46130134',
        sagoLon: '127.675',
        sagoLat: '34.7431',
        sinkWidth: '3',
        sinkExtend: '25',
        sinkDepth: '0.02',
        grdKind: '',
        sagoDetail: '굴착공사 부실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-02-05'
      },
      geometry: { type: 'Point', coordinates: [127.675, 34.7431] }
    },
    {
      type: 'Feature',
      id: 821,
      properties: {
        sagoDate: '2021-02-01',
        sagoNo: '20210116',
        sido: '전라남도',
        sigungu: '여수시',
        dong: '',
        addr: '웅천동 1887-4',
        fullAdr: '전라남도 여수시  웅천동 1887-4',
        SEC_CD: '46130134',
        sagoLon: '127.674',
        sagoLat: '34.7421',
        sinkWidth: '3',
        sinkExtend: '40',
        sinkDepth: '0.02',
        grdKind: '',
        sagoDetail: '굴착공사 부실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-02-05'
      },
      geometry: { type: 'Point', coordinates: [127.674, 34.7421] }
    },
    {
      type: 'Feature',
      id: 822,
      properties: {
        sagoDate: '2021-02-09',
        sagoNo: '20210123',
        sido: '울산광역시',
        sigungu: '동구',
        dong: '',
        addr: '미포1길 2(한국프랜지 앞)',
        fullAdr: '울산광역시 동구  미포1길 2(한국프랜지 앞)',
        SEC_CD: '31170108',
        sagoLon: '129.427',
        sagoLat: '35.5394',
        sinkWidth: '0.7',
        sinkExtend: '0.7',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail: '우수관 균열',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-02-09'
      },
      geometry: { type: 'Point', coordinates: [129.427, 35.5394] }
    },
    {
      type: 'Feature',
      id: 823,
      properties: {
        sagoDate: '2021-03-15',
        sagoNo: '20210002',
        sido: '부산광역시',
        sigungu: '사하구',
        dong: '다대동',
        addr: '927-17번지 일원',
        fullAdr: '부산광역시 사하구 다대동 927-17번지 일원',
        SEC_CD: '26380106',
        sagoLon: '128.974',
        sagoLat: '35.061',
        sinkWidth: '0.5',
        sinkExtend: '0.7',
        sinkDepth: '1.2',
        grdKind: '',
        sagoDetail: '폐하수관의 마감처리 미흡',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-03-15'
      },
      geometry: { type: 'Point', coordinates: [128.974, 35.061] }
    },
    {
      type: 'Feature',
      id: 824,
      properties: {
        sagoDate: '2021-03-30',
        sagoNo: '20210003',
        sido: '서울특별시',
        sigungu: '강남구',
        dong: '삼성동',
        addr: '160-21(봉은사로 104길 10)',
        fullAdr: '서울특별시 강남구 삼성동 160-21(봉은사로 104길 10)',
        SEC_CD: '11680105',
        sagoLon: '127.062',
        sagoLat: '37.5139',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '통신관 아래 공동발생 부분 큰돌 사이 빈 공간이 있어 동공 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-03-31'
      },
      geometry: { type: 'Point', coordinates: [127.062, 37.5139] }
    },
    {
      type: 'Feature',
      id: 825,
      properties: {
        sagoDate: '2021-03-31',
        sagoNo: '20210004',
        sido: '서울특별시',
        sigungu: '송파구',
        dong: '마천동',
        addr: '마천로61다길 25',
        fullAdr: '서울특별시 송파구 마천동 마천로61다길 25',
        SEC_CD: '11710114',
        sagoLon: '127.152',
        sagoLat: '37.4953',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '맨홀 하부구체 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-03-31'
      },
      geometry: { type: 'Point', coordinates: [127.152, 37.4953] }
    },
    {
      type: 'Feature',
      id: 826,
      properties: {
        sagoDate: '2021-03-09',
        sagoNo: '20210005',
        sido: '서울특별시',
        sigungu: '강남구',
        dong: '압구정동',
        addr: '500(올림픽대로 공항방향 성수대교 밑단 약 200m 5차로)',
        fullAdr:
          '서울특별시 강남구 압구정동 500(올림픽대로 공항방향 성수대교 밑단 약 200m 5차로)',
        SEC_CD: '11680110',
        sagoLon: '127.032',
        sagoLat: '37.5339',
        sinkWidth: '2.5',
        sinkExtend: '2.1',
        sinkDepth: '1.1',
        grdKind: '',
        sagoDetail:
          '○ 하수관(D=1200mm) 이음부 이격 및 노후 파손 · ① 맨홀→우수토실로 연결된 하수관 이음부 이격 및 노후 파손으로 공동발생· ② 기존 보수보강을 시행한 하수관로(올림픽대로 횡단)에 시공된 라이닝의 변형이 있어 기존 하수관과 라이닝 사이로 하수가 흘러 기존관의 이음부 사이로 하수 유출·○ 2021. 3월중 : 굴착공사가 불가하여 비굴착 공법으로 보수방안 강구· (올림픽대로· 수문구조물· 광역상수도관(D2500mm) 저촉 등)·○ 2021. 4월중 : 정비완료 예정·○ 소요예산 : 100백만원(서울시 물재생계획과 긴급복구비 요청)· D=1200mm· L=100m(2열). 맨홀4개소 보수보강',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-03-09'
      },
      geometry: { type: 'Point', coordinates: [127.032, 37.5339] }
    },
    {
      type: 'Feature',
      id: 827,
      properties: {
        sagoDate: '2021-03-30',
        sagoNo: '20210018',
        sido: '충청남도',
        sigungu: '보령시',
        dong: '',
        addr: '',
        fullAdr: '충청남도 보령시',
        SEC_CD: '44180400',
        sagoLon: '126.65',
        sagoLat: '36.336',
        sinkWidth: '0.4',
        sinkExtend: '0.7',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-06-06'
      },
      geometry: { type: 'Point', coordinates: [126.65, 36.336] }
    },
    {
      type: 'Feature',
      id: 828,
      properties: {
        sagoDate: '2021-03-01',
        sagoNo: '20210020',
        sido: '경기도',
        sigungu: '화성시',
        dong: '비봉면 구포리',
        addr: '831',
        fullAdr: '경기도 화성시 비봉면 구포리 831',
        SEC_CD: '41590320',
        sagoLon: '126.872',
        sagoLat: '37.25',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '하자보수 기간 후 자연침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-03-01'
      },
      geometry: { type: 'Point', coordinates: [126.872, 37.25] }
    },
    {
      type: 'Feature',
      id: 829,
      properties: {
        sagoDate: '2021-03-30',
        sagoNo: '20210021',
        sido: '경기도',
        sigungu: '화성시',
        dong: '송산면 천등리',
        addr: '469-1',
        fullAdr: '경기도 화성시 송산면 천등리 469-1',
        SEC_CD: '41590340',
        sagoLon: '126.707',
        sagoLat: '37.2333',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '하자보수 기간 후 자연침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-03-30'
      },
      geometry: { type: 'Point', coordinates: [126.707, 37.2333] }
    },
    {
      type: 'Feature',
      id: 830,
      properties: {
        sagoDate: '2021-03-26',
        sagoNo: '20210026',
        sido: '전북특별자치도',
        sigungu: '익산시',
        dong: '금마면 동고도리',
        addr: ' 고도길 58 (금마시장 인근)',
        fullAdr:
          '전북특별자치도 익산시 금마면 동고도리  고도길 58 (금마시장 인근)',
        SEC_CD: '52140400',
        sagoLon: '127.054',
        sagoLat: '35.9917',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 연결부 누수로 인한 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-03-26'
      },
      geometry: { type: 'Point', coordinates: [127.054, 35.9917] }
    },
    {
      type: 'Feature',
      id: 831,
      properties: {
        sagoDate: '2021-03-31',
        sagoNo: '20210043',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '정림동',
        addr: '704',
        fullAdr: '대전광역시 서구 정림동 704',
        SEC_CD: '30170104',
        sagoLon: '127.365',
        sagoLat: '36.3063',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수BOX와 하수관 접속부 이음 불량으로 인한 도로침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '5000000',
        trFnDate: '2021-04-01'
      },
      geometry: { type: 'Point', coordinates: [127.365, 36.3063] }
    },
    {
      type: 'Feature',
      id: 832,
      properties: {
        sagoDate: '2021-03-03',
        sagoNo: '20210085',
        sido: '경기도',
        sigungu: '의왕시',
        dong: '오전동',
        addr: '206-1',
        fullAdr: '경기도 의왕시 오전동 206-1',
        SEC_CD: '41430105',
        sagoLon: '126.968',
        sagoLat: '37.3561',
        sinkWidth: '10',
        sinkExtend: '3',
        sinkDepth: '0.1',
        grdKind: '기타',
        sagoDetail: '상수관 공사 후 다짐 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-03-04'
      },
      geometry: { type: 'Point', coordinates: [126.968, 37.3561] }
    },
    {
      type: 'Feature',
      id: 833,
      properties: {
        sagoDate: '2021-03-18',
        sagoNo: '20210086',
        sido: '경기도',
        sigungu: '의왕시',
        dong: '월암동',
        addr: '의왕시 월암동 541',
        fullAdr: '경기도 의왕시 월암동 의왕시 월암동 541',
        SEC_CD: '41430110',
        sagoLon: '126.952',
        sagoLat: '37.3088',
        sinkWidth: '1.4',
        sinkExtend: '1.1',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '자연침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2021-03-18'
      },
      geometry: { type: 'Point', coordinates: [126.952, 37.3088] }
    },
    {
      type: 'Feature',
      id: 834,
      properties: {
        sagoDate: '2021-03-18',
        sagoNo: '20210087',
        sido: '경기도',
        sigungu: '의왕시',
        dong: '',
        addr: '삼동 산 112',
        fullAdr: '경기도 의왕시  삼동 산 112',
        SEC_CD: '41430103',
        sagoLon: '126.951',
        sagoLat: '37.3152',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-03-19'
      },
      geometry: { type: 'Point', coordinates: [126.951, 37.3152] }
    },
    {
      type: 'Feature',
      id: 835,
      properties: {
        sagoDate: '2021-03-19',
        sagoNo: '20210091',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '',
        addr: '지산동 5-1 일원',
        fullAdr: '광주광역시 동구  지산동 5-1 일원',
        SEC_CD: '29110111',
        sagoLon: '126.946',
        sagoLat: '35.1493',
        sinkWidth: '5',
        sinkExtend: '5',
        sinkDepth: '4',
        grdKind: '',
        sagoDetail: '지하 매설시설물 손상 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2021-03-22'
      },
      geometry: { type: 'Point', coordinates: [126.946, 35.1493] }
    },
    {
      type: 'Feature',
      id: 836,
      properties: {
        sagoDate: '2021-03-08',
        sagoNo: '20210102',
        sido: '부산광역시',
        sigungu: '연제구',
        dong: '거제동',
        addr: '1465-1(거제천로255번길 54)',
        fullAdr: '부산광역시 연제구 거제동 1465-1(거제천로255번길 54)',
        SEC_CD: '26470102',
        sagoLon: '129.085',
        sagoLat: '35.1946',
        sinkWidth: '3',
        sinkExtend: '4',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-03-10'
      },
      geometry: { type: 'Point', coordinates: [129.085, 35.1946] }
    },
    {
      type: 'Feature',
      id: 837,
      properties: {
        sagoDate: '2021-03-03',
        sagoNo: '20210105',
        sido: '경기도',
        sigungu: '이천시',
        dong: '장호원읍',
        addr: '장호원리 368',
        fullAdr: '경기도 이천시 장호원읍 장호원리 368',
        SEC_CD: '41500250',
        sagoLon: '127.618',
        sagoLat: '37.1242',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '개인 배수설비 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-03-03'
      },
      geometry: { type: 'Point', coordinates: [127.618, 37.1242] }
    },
    {
      type: 'Feature',
      id: 838,
      properties: {
        sagoDate: '2021-03-02',
        sagoNo: '20210110',
        sido: '경상북도',
        sigungu: '포항시 북구',
        dong: '',
        addr: '득량동 148-1번지',
        fullAdr: '경상북도 포항시 북구  득량동 148-1번지',
        SEC_CD: '47113114',
        sagoLon: '129.349',
        sagoLat: '36.027',
        sinkWidth: '2',
        sinkExtend: '40',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '득량주공재건축정비사업으로 인한 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-03-19'
      },
      geometry: { type: 'Point', coordinates: [129.349, 36.027] }
    },
    {
      type: 'Feature',
      id: 839,
      properties: {
        sagoDate: '2021-03-26',
        sagoNo: '20210111',
        sido: '경상북도',
        sigungu: '포항시 북구',
        dong: '',
        addr: '득량동 306번지',
        fullAdr: '경상북도 포항시 북구  득량동 306번지',
        SEC_CD: '47113114',
        sagoLon: '129.35',
        sagoLat: '36.0264',
        sinkWidth: '1',
        sinkExtend: '30',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '득량주공재건축사업으로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-04-08'
      },
      geometry: { type: 'Point', coordinates: [129.35, 36.0264] }
    },
    {
      type: 'Feature',
      id: 840,
      properties: {
        sagoDate: '2021-03-26',
        sagoNo: '20210125',
        sido: '울산광역시',
        sigungu: '동구',
        dong: '서부동',
        addr: '257-36',
        fullAdr: '울산광역시 동구 서부동 257-36',
        SEC_CD: '31170108',
        sagoLon: '129.429',
        sagoLat: '35.5286',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '지표수 유입으로 일부 지반 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-03-27'
      },
      geometry: { type: 'Point', coordinates: [129.429, 35.5286] }
    },
    {
      type: 'Feature',
      id: 841,
      properties: {
        sagoDate: '2021-04-07',
        sagoNo: '20210006',
        sido: '서울특별시',
        sigungu: '송파구',
        dong: '거여동',
        addr: '33-16',
        fullAdr: '서울특별시 송파구 거여동 33-16',
        SEC_CD: '11710113',
        sagoLon: '127.15',
        sagoLat: '37.4921',
        sinkWidth: '0.7',
        sinkExtend: '0.7',
        sinkDepth: '1.9',
        grdKind: '',
        sagoDetail: '하수맨홀 노후로 인한 하부 구체파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-04-07'
      },
      geometry: { type: 'Point', coordinates: [127.15, 37.4921] }
    },
    {
      type: 'Feature',
      id: 842,
      properties: {
        sagoDate: '2021-04-19',
        sagoNo: '20210007',
        sido: '경기도',
        sigungu: '용인시 수지구',
        dong: '',
        addr: '풍덕천동 1045-1번지',
        fullAdr: '경기도 용인시 수지구  풍덕천동 1045-1번지',
        SEC_CD: '41465101',
        sagoLon: '127.094',
        sagoLat: '37.3222',
        sinkWidth: '1.5',
        sinkExtend: '15',
        sinkDepth: '0.01',
        grdKind: '호상편마암',
        sagoDetail:
          'PVC관(D80mm)을 토류판 가로 버팀보 받침대(브라켓)에 올려 되메우기한 후 토사가 침하되어 급수관 탈락',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '25000000',
        trFnDate: '2021-04-19'
      },
      geometry: { type: 'Point', coordinates: [127.094, 37.3222] }
    },
    {
      type: 'Feature',
      id: 843,
      properties: {
        sagoDate: '2021-04-19',
        sagoNo: '20210008',
        sido: '경기도',
        sigungu: '구리시',
        dong: '인창동',
        addr: '681-1번지',
        fullAdr: '경기도 구리시 인창동 681-1번지',
        SEC_CD: '41310103',
        sagoLon: '127.142',
        sagoLat: '37.6044',
        sinkWidth: '1.5',
        sinkExtend: '5',
        sinkDepth: '1.2',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-06-30'
      },
      geometry: { type: 'Point', coordinates: [127.142, 37.6044] }
    },
    {
      type: 'Feature',
      id: 844,
      properties: {
        sagoDate: '2021-04-22',
        sagoNo: '20210009',
        sido: '부산광역시',
        sigungu: '사하구',
        dong: '괴정동',
        addr: '203-31번지 ',
        fullAdr: '부산광역시 사하구 괴정동 203-31번지',
        SEC_CD: '26380101',
        sagoLon: '129.004',
        sagoLat: '35.1055',
        sinkWidth: '2',
        sinkExtend: '4',
        sinkDepth: '5',
        grdKind: '기타',
        sagoDetail: '하수BOX(2.0mX2.0m) 파손에 의한 동공 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '20000000',
        trFnDate: '2021-04-23'
      },
      geometry: { type: 'Point', coordinates: [129.004, 35.1055] }
    },
    {
      type: 'Feature',
      id: 845,
      properties: {
        sagoDate: '2021-04-07',
        sagoNo: '20210022',
        sido: '경기도',
        sigungu: '화성시',
        dong: '팔탄면 율암리',
        addr: '483-11',
        fullAdr: '경기도 화성시 팔탄면 율암리 483-11',
        SEC_CD: '41590360',
        sagoLon: '126.881',
        sagoLat: '37.1668',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '하자보수 기간 후 자연 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-04-07'
      },
      geometry: { type: 'Point', coordinates: [126.881, 37.1668] }
    },
    {
      type: 'Feature',
      id: 846,
      properties: {
        sagoDate: '2021-04-08',
        sagoNo: '20210032',
        sido: '경기도',
        sigungu: '시흥시',
        dong: '조남동',
        addr: '',
        fullAdr: '경기도 시흥시 조남동',
        SEC_CD: '41390124',
        sagoLon: '126.86',
        sagoLat: '37.3853',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '상수관로 제수변 인근 아스콘 포장불량',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-04-08'
      },
      geometry: { type: 'Point', coordinates: [126.86, 37.3853] }
    },
    {
      type: 'Feature',
      id: 847,
      properties: {
        sagoDate: '2021-04-30',
        sagoNo: '20210041',
        sido: '대전광역시',
        sigungu: '동구',
        dong: '용전동',
        addr: '227번지',
        fullAdr: '대전광역시 동구 용전동 227번지',
        SEC_CD: '30110115',
        sagoLon: '127.433',
        sagoLat: '36.3556',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '하수박스(2.0*2.0) 바닥 파손부위로 토사가 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '5000000',
        trFnDate: '2021-04-30'
      },
      geometry: { type: 'Point', coordinates: [127.433, 36.3556] }
    },
    {
      type: 'Feature',
      id: 848,
      properties: {
        sagoDate: '2021-04-01',
        sagoNo: '20210068',
        sido: '경상북도',
        sigungu: '포항시 남구',
        dong: '송내동',
        addr: '668',
        fullAdr: '경상북도 포항시 남구 송내동 668',
        SEC_CD: '47111108',
        sagoLon: '129.365',
        sagoLat: '35.9996',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '포항철강산업단지 완충저류시설 설치공사로 인한 침하·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-06-27'
      },
      geometry: { type: 'Point', coordinates: [129.365, 35.9996] }
    },
    {
      type: 'Feature',
      id: 849,
      properties: {
        sagoDate: '2021-04-01',
        sagoNo: '20210069',
        sido: '경상북도',
        sigungu: '포항시 남구',
        dong: '괴동동',
        addr: '911-6',
        fullAdr: '경상북도 포항시 남구 괴동동 911-6',
        SEC_CD: '47111109',
        sagoLon: '129.366',
        sagoLat: '35.9986',
        sinkWidth: '1.2',
        sinkExtend: '1.2',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '포항철강산업단지 완충저류시설 설치공사로 인한 침하·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-06-27'
      },
      geometry: { type: 'Point', coordinates: [129.366, 35.9986] }
    },
    {
      type: 'Feature',
      id: 850,
      properties: {
        sagoDate: '2021-04-02',
        sagoNo: '20210072',
        sido: '경상북도',
        sigungu: '포항시 남구',
        dong: '송내동',
        addr: '444',
        fullAdr: '경상북도 포항시 남구 송내동 444',
        SEC_CD: '47111108',
        sagoLon: '129.378',
        sagoLat: '36.0061',
        sinkWidth: '0.8',
        sinkExtend: '2',
        sinkDepth: '0.7',
        grdKind: '',
        sagoDetail: '국도 31호선으로 중량 차량의 잦은 통행 등 자연 침하 발생·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-06-29'
      },
      geometry: { type: 'Point', coordinates: [129.378, 36.0061] }
    },
    {
      type: 'Feature',
      id: 851,
      properties: {
        sagoDate: '2021-04-02',
        sagoNo: '20210073',
        sido: '경상북도',
        sigungu: '포항시 남구',
        dong: '송내동',
        addr: '444',
        fullAdr: '경상북도 포항시 남구 송내동 444',
        SEC_CD: '47111108',
        sagoLon: '129.378',
        sagoLat: '36.0054',
        sinkWidth: '0.6',
        sinkExtend: '16',
        sinkDepth: '0.8',
        grdKind: '',
        sagoDetail: '국도 31호선으로 중량 차량의 잦은 통행 등 자연 침하 발생·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-06-29'
      },
      geometry: { type: 'Point', coordinates: [129.378, 36.0054] }
    },
    {
      type: 'Feature',
      id: 852,
      properties: {
        sagoDate: '2021-04-02',
        sagoNo: '20210075',
        sido: '경상북도',
        sigungu: '포항시 남구',
        dong: '동촌동',
        addr: '545-6',
        fullAdr: '경상북도 포항시 남구 동촌동 545-6',
        SEC_CD: '47111110',
        sagoLon: '129.389',
        sagoLat: '35.9973',
        sinkWidth: '1.4',
        sinkExtend: '1.7',
        sinkDepth: '0.1',
        grdKind: '기타',
        sagoDetail: '국도 31호선으로 중량 차량의 잦은 통행 등 자연 침하 발생·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-06-29'
      },
      geometry: { type: 'Point', coordinates: [129.389, 35.9973] }
    },
    {
      type: 'Feature',
      id: 853,
      properties: {
        sagoDate: '2021-04-02',
        sagoNo: '20210076',
        sido: '경상북도',
        sigungu: '포항시 남구',
        dong: '동촌동',
        addr: '543-67',
        fullAdr: '경상북도 포항시 남구 동촌동 543-67',
        SEC_CD: '47111110',
        sagoLon: '129.389',
        sagoLat: '35.9974',
        sinkWidth: '1',
        sinkExtend: '3',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '국도 31호선으로 중량 차량의 잦은 통행 등 자연 침하 발생·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-06-29'
      },
      geometry: { type: 'Point', coordinates: [129.389, 35.9974] }
    },
    {
      type: 'Feature',
      id: 854,
      properties: {
        sagoDate: '2021-04-02',
        sagoNo: '20210077',
        sido: '경상북도',
        sigungu: '포항시 남구',
        dong: '동촌동',
        addr: '5',
        fullAdr: '경상북도 포항시 남구 동촌동 5',
        SEC_CD: '47111110',
        sagoLon: '129.388',
        sagoLat: '35.9986',
        sinkWidth: '0.8',
        sinkExtend: '5.4',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '국도 31호선으로 중량 차량의 잦은 통행 등 자연 침하 발생·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-06-29'
      },
      geometry: { type: 'Point', coordinates: [129.388, 35.9986] }
    },
    {
      type: 'Feature',
      id: 855,
      properties: {
        sagoDate: '2021-04-02',
        sagoNo: '20210078',
        sido: '경상북도',
        sigungu: '포항시 남구',
        dong: '동촌동',
        addr: '800-3',
        fullAdr: '경상북도 포항시 남구 동촌동 800-3',
        SEC_CD: '47111110',
        sagoLon: '129.384',
        sagoLat: '36.0012',
        sinkWidth: '1.1',
        sinkExtend: '2.6',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '국도 31호선으로 중량 차량의 잦은 통행 등 자연 침하 발생·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-06-29'
      },
      geometry: { type: 'Point', coordinates: [129.384, 36.0012] }
    },
    {
      type: 'Feature',
      id: 856,
      properties: {
        sagoDate: '2021-04-02',
        sagoNo: '20210079',
        sido: '경상북도',
        sigungu: '포항시 남구',
        dong: '동촌동',
        addr: '800-3',
        fullAdr: '경상북도 포항시 남구 동촌동 800-3',
        SEC_CD: '47111110',
        sagoLon: '129.384',
        sagoLat: '36.0014',
        sinkWidth: '1.1',
        sinkExtend: '1.3',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '국도 31호선으로 중량 차량의 잦은 통행 등 자연 침하 발생·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-06-29'
      },
      geometry: { type: 'Point', coordinates: [129.384, 36.0014] }
    },
    {
      type: 'Feature',
      id: 857,
      properties: {
        sagoDate: '2021-04-28',
        sagoNo: '20210084',
        sido: '강원특별자치도',
        sigungu: '삼척시',
        dong: '원당동',
        addr: '서부초등학교 맞은편 벽산빌라 옆 도로',
        fullAdr:
          '강원특별자치도 삼척시 원당동 서부초등학교 맞은편 벽산빌라 옆 도로',
        SEC_CD: '51230118',
        sagoLon: '129.15',
        sagoLat: '37.4386',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail: '관손상으로 인한 누수 및 도로침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '7000000',
        trFnDate: '2021-07-14'
      },
      geometry: { type: 'Point', coordinates: [129.15, 37.4386] }
    },
    {
      type: 'Feature',
      id: 858,
      properties: {
        sagoDate: '2021-04-29',
        sagoNo: '20210098',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '',
        addr: '비아동 715-8',
        fullAdr: '광주광역시 광산구  비아동 715-8',
        SEC_CD: '29200112',
        sagoLon: '126.821',
        sagoLat: '35.2203',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '관로 노후로 인한 세굴 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-04-30'
      },
      geometry: { type: 'Point', coordinates: [126.821, 35.2203] }
    },
    {
      type: 'Feature',
      id: 859,
      properties: {
        sagoDate: '2021-04-01',
        sagoNo: '20210117',
        sido: '전라남도',
        sigungu: '여수시',
        dong: '',
        addr: '낙포동 1372',
        fullAdr: '전라남도 여수시  낙포동 1372',
        SEC_CD: '46130148',
        sagoLon: '127.742',
        sagoLat: '34.859',
        sinkWidth: '5',
        sinkExtend: '10',
        sinkDepth: '0.6',
        grdKind: '',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-04-02'
      },
      geometry: { type: 'Point', coordinates: [127.742, 34.859] }
    },
    {
      type: 'Feature',
      id: 860,
      properties: {
        sagoDate: '2021-05-22',
        sagoNo: '20210023',
        sido: '경기도',
        sigungu: '화성시',
        dong: '반월동',
        addr: '245-2',
        fullAdr: '경기도 화성시 반월동 245-2',
        SEC_CD: '41590120',
        sagoLon: '127.047',
        sagoLat: '37.2295',
        sinkWidth: '1',
        sinkExtend: '1.3',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '하자보수 기간 후 자연침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-05-23'
      },
      geometry: { type: 'Point', coordinates: [127.047, 37.2295] }
    },
    {
      type: 'Feature',
      id: 861,
      properties: {
        sagoDate: '2021-05-24',
        sagoNo: '20210024',
        sido: '경기도',
        sigungu: '화성시',
        dong: '서신면 매화리',
        addr: '365-8',
        fullAdr: '경기도 화성시 서신면 매화리 365-8',
        SEC_CD: '41590350',
        sagoLon: '126.704',
        sagoLat: '37.1687',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '하자보수 기간 후 자연침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-05-24'
      },
      geometry: { type: 'Point', coordinates: [126.704, 37.1687] }
    },
    {
      type: 'Feature',
      id: 862,
      properties: {
        sagoDate: '2021-05-20',
        sagoNo: '20210029',
        sido: '전북특별자치도',
        sigungu: '익산시',
        dong: '영등동',
        addr: '178-51(참사랑 마트)',
        fullAdr: '전북특별자치도 익산시 영등동 178-51(참사랑 마트)',
        SEC_CD: '52140122',
        sagoLon: '126.974',
        sagoLat: '35.9528',
        sinkWidth: '1.2',
        sinkExtend: '1',
        sinkDepth: '0.8',
        grdKind: '',
        sagoDetail: '하수관 파손(손상)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-05-20'
      },
      geometry: { type: 'Point', coordinates: [126.974, 35.9528] }
    },
    {
      type: 'Feature',
      id: 863,
      properties: {
        sagoDate: '2021-05-20',
        sagoNo: '20210030',
        sido: '전북특별자치도',
        sigungu: '익산시',
        dong: '동산동',
        addr: '서동로 28길 20 (엄마트 앞 도로)',
        fullAdr: '전북특별자치도 익산시 동산동 서동로 28길 20 (엄마트 앞 도로)',
        SEC_CD: '52140111',
        sagoLon: '126.965',
        sagoLat: '35.9337',
        sinkWidth: '1.2',
        sinkExtend: '1',
        sinkDepth: '0.8',
        grdKind: '',
        sagoDetail: '하수관 파손(손상)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-05-20'
      },
      geometry: { type: 'Point', coordinates: [126.965, 35.9337] }
    },
    {
      type: 'Feature',
      id: 864,
      properties: {
        sagoDate: '2021-05-25',
        sagoNo: '20210031',
        sido: '전북특별자치도',
        sigungu: '익산시',
        dong: '영등동',
        addr: '제일2차 1단지(고봉로 24길 30)',
        fullAdr: '전북특별자치도 익산시 영등동 제일2차 1단지(고봉로 24길 30)',
        SEC_CD: '52140122',
        sagoLon: '126.972',
        sagoLat: '35.9553',
        sinkWidth: '1.5',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 파손(손상)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-05-25'
      },
      geometry: { type: 'Point', coordinates: [126.972, 35.9553] }
    },
    {
      type: 'Feature',
      id: 865,
      properties: {
        sagoDate: '2021-05-05',
        sagoNo: '20210035',
        sido: '강원특별자치도',
        sigungu: '강릉시',
        dong: '유천동',
        addr: '유천동 133-2',
        fullAdr: '강원특별자치도 강릉시 유천동 유천동 133-2',
        SEC_CD: '51150131',
        sagoLon: '128.872',
        sagoLat: '37.7606',
        sinkWidth: '2',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '충척층',
        sagoDetail: '원인불명',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-05-11'
      },
      geometry: { type: 'Point', coordinates: [128.872, 37.7606] }
    },
    {
      type: 'Feature',
      id: 866,
      properties: {
        sagoDate: '2021-05-13',
        sagoNo: '20210038',
        sido: '부산광역시',
        sigungu: '남구',
        dong: '용호동',
        addr: '370-18',
        fullAdr: '부산광역시 남구 용호동 370-18',
        SEC_CD: '26290107',
        sagoLon: '129.112',
        sagoLat: '35.1198',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '상수도관으로 인한 흄관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '2752000',
        trFnDate: '2021-05-13'
      },
      geometry: { type: 'Point', coordinates: [129.112, 35.1198] }
    },
    {
      type: 'Feature',
      id: 867,
      properties: {
        sagoDate: '2021-05-13',
        sagoNo: '20210044',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '도마동',
        addr: '201-1',
        fullAdr: '대전광역시 서구 도마동 201-1',
        SEC_CD: '30170103',
        sagoLon: '127.368',
        sagoLat: '36.3226',
        sinkWidth: '1.5',
        sinkExtend: '1',
        sinkDepth: '1.8',
        grdKind: '',
        sagoDetail: '도로 공사 후 굴착복구 다짐 미흡으로 인한 장기침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '7000000',
        trFnDate: '2021-05-13'
      },
      geometry: { type: 'Point', coordinates: [127.368, 36.3226] }
    },
    {
      type: 'Feature',
      id: 868,
      properties: {
        sagoDate: '2021-05-18',
        sagoNo: '20210051',
        sido: '부산광역시',
        sigungu: '북구',
        dong: '구포동',
        addr: '1089번지 파리바게트 앞 보도',
        fullAdr: '부산광역시 북구 구포동 1089번지 파리바게트 앞 보도',
        SEC_CD: '26320105',
        sagoLon: '128.994',
        sagoLat: '35.1964',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail:
          '상수관 파열로 인하여 도로 내 지반이 유실되고 표층부가 파손되어 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-06-07'
      },
      geometry: { type: 'Point', coordinates: [128.994, 35.1964] }
    },
    {
      type: 'Feature',
      id: 869,
      properties: {
        sagoDate: '2021-05-21',
        sagoNo: '20210092',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '',
        addr: '갈마로 18',
        fullAdr: '광주광역시 동구  갈마로 18',
        SEC_CD: '29110110',
        sagoLon: '126.933',
        sagoLat: '35.1609',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '하수관로(D900mm) 파손으로 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-05-23'
      },
      geometry: { type: 'Point', coordinates: [126.933, 35.1609] }
    },
    {
      type: 'Feature',
      id: 870,
      properties: {
        sagoDate: '2021-05-21',
        sagoNo: '20210094',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '',
        addr: '화정동 859-2번지 주변',
        fullAdr: '광주광역시 서구  화정동 859-2번지 주변',
        SEC_CD: '29140119',
        sagoLon: '126.878',
        sagoLat: '35.1402',
        sinkWidth: '1',
        sinkExtend: '4',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수관(D600mm)파손으로 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-05-21'
      },
      geometry: { type: 'Point', coordinates: [126.878, 35.1402] }
    },
    {
      type: 'Feature',
      id: 871,
      properties: {
        sagoDate: '2021-05-22',
        sagoNo: '20210095',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '',
        addr: '화정동 1020번지 주변',
        fullAdr: '광주광역시 서구  화정동 1020번지 주변',
        SEC_CD: '29140119',
        sagoLon: '126.882',
        sagoLat: '35.14',
        sinkWidth: '0.5',
        sinkExtend: '3',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '토사 자체에 문제(굴착을하여도 토사가 비어있지 않음)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-05-26'
      },
      geometry: { type: 'Point', coordinates: [126.882, 35.14] }
    },
    {
      type: 'Feature',
      id: 872,
      properties: {
        sagoDate: '2021-05-11',
        sagoNo: '20210099',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '',
        addr: '신가동 1002-16',
        fullAdr: '광주광역시 광산구  신가동 1002-16',
        SEC_CD: '29200119',
        sagoLon: '126.833',
        sagoLat: '35.1846',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.8',
        grdKind: '',
        sagoDetail: '관로 노후로 인한 세굴 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-05-12'
      },
      geometry: { type: 'Point', coordinates: [126.833, 35.1846] }
    },
    {
      type: 'Feature',
      id: 873,
      properties: {
        sagoDate: '2021-05-06',
        sagoNo: '20210101',
        sido: '부산광역시',
        sigungu: '연제구',
        dong: '연산동',
        addr: '산 181-1(황령산로 503 앞)',
        fullAdr: '부산광역시 연제구 연산동 산 181-1(황령산로 503 앞)',
        SEC_CD: '26470102',
        sagoLon: '129.083',
        sagoLat: '35.1649',
        sinkWidth: '0.8',
        sinkExtend: '7',
        sinkDepth: '1.3',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-05-06'
      },
      geometry: { type: 'Point', coordinates: [129.083, 35.1649] }
    },
    {
      type: 'Feature',
      id: 874,
      properties: {
        sagoDate: '2021-05-25',
        sagoNo: '20210106',
        sido: '경기도',
        sigungu: '이천시',
        dong: '장호원읍',
        addr: '장호원리 656-3',
        fullAdr: '경기도 이천시 장호원읍 장호원리 656-3',
        SEC_CD: '41500250',
        sagoLon: '127.632',
        sagoLat: '37.1141',
        sinkWidth: '1',
        sinkExtend: '10',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '하수관 손상에 따른 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-05-25'
      },
      geometry: { type: 'Point', coordinates: [127.632, 37.1141] }
    },
    {
      type: 'Feature',
      id: 875,
      properties: {
        sagoDate: '2021-05-03',
        sagoNo: '20210108',
        sido: '경기도',
        sigungu: '이천시',
        dong: '단월동',
        addr: '769',
        fullAdr: '경기도 이천시 단월동 769',
        SEC_CD: '41500112',
        sagoLon: '127.454',
        sagoLat: '37.2213',
        sinkWidth: '1.7',
        sinkExtend: '2',
        sinkDepth: '1.4',
        grdKind: '기타',
        sagoDetail: '중차량(크레인) 무게로 싱크홀 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-09-30'
      },
      geometry: { type: 'Point', coordinates: [127.454, 37.2213] }
    },
    {
      type: 'Feature',
      id: 876,
      properties: {
        sagoDate: '2021-06-08',
        sagoNo: '20210010',
        sido: '서울특별시',
        sigungu: '강남구',
        dong: '논현동',
        addr: '212-16(학동로 34길 21)',
        fullAdr: '서울특별시 강남구 논현동 212-16(학동로 34길 21)',
        SEC_CD: '11680108',
        sagoLon: '127.034',
        sagoLat: '37.5132',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 노후화로 인한 동공발생 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-06-09'
      },
      geometry: { type: 'Point', coordinates: [127.034, 37.5132] }
    },
    {
      type: 'Feature',
      id: 877,
      properties: {
        sagoDate: '2021-06-09',
        sagoNo: '20210011',
        sido: '인천광역시',
        sigungu: '부평구',
        dong: '부평동',
        addr: '416-9',
        fullAdr: '인천광역시 부평구 부평동 416-9',
        SEC_CD: '28237101',
        sagoLon: '126.727',
        sagoLat: '37.4986',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '상수도관 누수에 따른 땅꺼짐 사고 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-06-10'
      },
      geometry: { type: 'Point', coordinates: [126.727, 37.4986] }
    },
    {
      type: 'Feature',
      id: 878,
      properties: {
        sagoDate: '2021-06-17',
        sagoNo: '20210012',
        sido: '부산광역시',
        sigungu: '사하구',
        dong: '다대동',
        addr: '943-3번지 일원 ',
        fullAdr: '부산광역시 사하구 다대동 943-3번지 일원',
        SEC_CD: '26380106',
        sagoLon: '128.969',
        sagoLat: '35.0561',
        sinkWidth: '1.4',
        sinkExtend: '2.4',
        sinkDepth: '1.2',
        grdKind: '기타',
        sagoDetail: '원인불명',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-06-19'
      },
      geometry: { type: 'Point', coordinates: [128.969, 35.0561] }
    },
    {
      type: 'Feature',
      id: 879,
      properties: {
        sagoDate: '2021-06-29',
        sagoNo: '20210015',
        sido: '경기도',
        sigungu: '과천시',
        dong: '과천동',
        addr: '',
        fullAdr: '경기도 과천시 과천동',
        SEC_CD: '41290105',
        sagoLon: '126.995',
        sagoLat: '37.4469',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-06-29'
      },
      geometry: { type: 'Point', coordinates: [126.995, 37.4469] }
    },
    {
      type: 'Feature',
      id: 880,
      properties: {
        sagoDate: '2021-06-28',
        sagoNo: '20210016',
        sido: '충청북도',
        sigungu: '청주시 서원구',
        dong: '',
        addr: '개신동 1-13',
        fullAdr: '충청북도 청주시 서원구  개신동 1-13',
        SEC_CD: '43112109',
        sagoLon: '127.466',
        sagoLat: '36.626',
        sinkWidth: '2',
        sinkExtend: '3',
        sinkDepth: '2',
        grdKind: '충척층',
        sagoDetail: '하수관 손상에 따른 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '3000000',
        trFnDate: '2021-06-29'
      },
      geometry: { type: 'Point', coordinates: [127.466, 36.626] }
    },
    {
      type: 'Feature',
      id: 881,
      properties: {
        sagoDate: '2021-06-17',
        sagoNo: '20210017',
        sido: '충청북도',
        sigungu: '청주시 서원구',
        dong: '',
        addr: '사창동 532',
        fullAdr: '충청북도 청주시 서원구  사창동 532',
        SEC_CD: '43112102',
        sagoLon: '127.461',
        sagoLat: '36.6309',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '충척층',
        sagoDetail: '하수관 손상에 따른 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '2500000',
        trFnDate: '2021-07-03'
      },
      geometry: { type: 'Point', coordinates: [127.461, 36.6309] }
    },
    {
      type: 'Feature',
      id: 882,
      properties: {
        sagoDate: '2021-06-14',
        sagoNo: '20210019',
        sido: '부산광역시',
        sigungu: '서구',
        dong: '동대신동3가',
        addr: '63-315번지 (덕산빌라 앞)',
        fullAdr: '부산광역시 서구 동대신동3가 63-315번지 (덕산빌라 앞)',
        SEC_CD: '26140103',
        sagoLon: '129.019',
        sagoLat: '35.1177',
        sinkWidth: '0.8',
        sinkExtend: '10',
        sinkDepth: '1.4',
        grdKind: '',
        sagoDetail:
          '하수도관 이음부불량(우수관D1200mm 이음부 불량으로 인한 유출)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-06-24'
      },
      geometry: { type: 'Point', coordinates: [129.019, 35.1177] }
    },
    {
      type: 'Feature',
      id: 883,
      properties: {
        sagoDate: '2021-06-13',
        sagoNo: '20210033',
        sido: '대전광역시',
        sigungu: '중구',
        dong: '대흥동',
        addr: '202-1',
        fullAdr: '대전광역시 중구 대흥동 202-1',
        SEC_CD: '30140105',
        sagoLon: '127.425',
        sagoLat: '36.3282',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.6',
        grdKind: '',
        sagoDetail: '하수관로 노후로 인한 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-06-21'
      },
      geometry: { type: 'Point', coordinates: [127.425, 36.3282] }
    },
    {
      type: 'Feature',
      id: 884,
      properties: {
        sagoDate: '2021-06-08',
        sagoNo: '20210036',
        sido: '강원특별자치도',
        sigungu: '강릉시',
        dong: '송정동',
        addr: '송정동 187-4(팔송길 6 앞)',
        fullAdr: '강원특별자치도 강릉시 송정동 송정동 187-4(팔송길 6 앞)',
        SEC_CD: '51150114',
        sagoLon: '128.933',
        sagoLat: '37.7748',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '0.5',
        grdKind: '기타',
        sagoDetail: '하수관로 교체작업 다짐불량으로 인한 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-06-09'
      },
      geometry: { type: 'Point', coordinates: [128.933, 37.7748] }
    },
    {
      type: 'Feature',
      id: 885,
      properties: {
        sagoDate: '2021-06-11',
        sagoNo: '20210045',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '정림동',
        addr: '636번지 인근',
        fullAdr: '대전광역시 서구 정림동 636번지 인근',
        SEC_CD: '30170104',
        sagoLon: '127.366',
        sagoLat: '36.3046',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관로 이탈로 인한 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '4000000',
        trFnDate: '2021-06-16'
      },
      geometry: { type: 'Point', coordinates: [127.366, 36.3046] }
    },
    {
      type: 'Feature',
      id: 886,
      properties: {
        sagoDate: '2021-06-10',
        sagoNo: '20210050',
        sido: '부산광역시',
        sigungu: '사상구',
        dong: '모라동',
        addr: '모라동 453번지 앞 도로',
        fullAdr: '부산광역시 사상구 모라동 모라동 453번지 앞 도로',
        SEC_CD: '26530102',
        sagoLon: '128.99',
        sagoLat: '35.1868',
        sinkWidth: '1.5',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '토사유실로 인한 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-07-03'
      },
      geometry: { type: 'Point', coordinates: [128.99, 35.1868] }
    },
    {
      type: 'Feature',
      id: 887,
      properties: {
        sagoDate: '2021-06-30',
        sagoNo: '20210082',
        sido: '강원특별자치도',
        sigungu: '춘천시',
        dong: '근화동',
        addr: '752',
        fullAdr: '강원특별자치도 춘천시 근화동 752',
        SEC_CD: '51110117',
        sagoLon: '127.713',
        sagoLat: '37.8743',
        sinkWidth: '1',
        sinkExtend: '3',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '흙막이 공사로 인한 일부 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-07-02'
      },
      geometry: { type: 'Point', coordinates: [127.713, 37.8743] }
    },
    {
      type: 'Feature',
      id: 888,
      properties: {
        sagoDate: '2021-06-12',
        sagoNo: '20210093',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '',
        addr: '문화전장로26번길 새마을금고 앞 부근',
        fullAdr: '광주광역시 동구  문화전장로26번길 새마을금고 앞 부근',
        SEC_CD: '29110113',
        sagoLon: '126.92',
        sagoLat: '35.1452',
        sinkWidth: '2.2',
        sinkExtend: '2.2',
        sinkDepth: '1.3',
        grdKind: '',
        sagoDetail: '다짐불량으로 인한 자연침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-06-14'
      },
      geometry: { type: 'Point', coordinates: [126.92, 35.1452] }
    },
    {
      type: 'Feature',
      id: 889,
      properties: {
        sagoDate: '2021-06-21',
        sagoNo: '20210096',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '',
        addr: '죽봉대로 103앞 부근',
        fullAdr: '광주광역시 서구  죽봉대로 103앞 부근',
        SEC_CD: '29140115',
        sagoLon: '126.883',
        sagoLat: '35.1625',
        sinkWidth: '1.4',
        sinkExtend: '2.55',
        sinkDepth: '0.6',
        grdKind: '',
        sagoDetail: '다짐불량으로 인해 자연침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-07-01'
      },
      geometry: { type: 'Point', coordinates: [126.883, 35.1625] }
    },
    {
      type: 'Feature',
      id: 890,
      properties: {
        sagoDate: '2021-06-29',
        sagoNo: '20210097',
        sido: '광주광역시',
        sigungu: '북구',
        dong: '',
        addr: '빛고을대로 동림동 산 58-14 부근',
        fullAdr: '광주광역시 북구  빛고을대로 동림동 산 58-14 부근',
        SEC_CD: '29170108',
        sagoLon: '126.859',
        sagoLat: '35.18',
        sinkWidth: '1.4',
        sinkExtend: '2.3',
        sinkDepth: '0.9',
        grdKind: '',
        sagoDetail: '다짐불량으로 인한 자연침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-07-01'
      },
      geometry: { type: 'Point', coordinates: [126.859, 35.18] }
    },
    {
      type: 'Feature',
      id: 891,
      properties: {
        sagoDate: '2021-06-07',
        sagoNo: '20210100',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '',
        addr: '제봉로 남광주시장 정류장 앞 부근(남광주사거리방면)',
        fullAdr:
          '광주광역시 동구  제봉로 남광주시장 정류장 앞 부근(남광주사거리방면)',
        SEC_CD: '29110122',
        sagoLon: '126.922',
        sagoLat: '35.1385',
        sinkWidth: '1.2',
        sinkExtend: '2.7',
        sinkDepth: '1.1',
        grdKind: '',
        sagoDetail: '다짐불량으로 인한 자연침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-06-09'
      },
      geometry: { type: 'Point', coordinates: [126.922, 35.1385] }
    },
    {
      type: 'Feature',
      id: 892,
      properties: {
        sagoDate: '2021-06-14',
        sagoNo: '20210103',
        sido: '부산광역시',
        sigungu: '연제구',
        dong: '거제동',
        addr: '1427-38(종합운동장로 7 홈플러스 앞 삼거리)',
        fullAdr:
          '부산광역시 연제구 거제동 1427-38(종합운동장로 7 홈플러스 앞 삼거리)',
        SEC_CD: '26260109',
        sagoLon: '129.062',
        sagoLat: '35.1926',
        sinkWidth: '2',
        sinkExtend: '3',
        sinkDepth: '2.4',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2021-06-15'
      },
      geometry: { type: 'Point', coordinates: [129.062, 35.1926] }
    },
    {
      type: 'Feature',
      id: 893,
      properties: {
        sagoDate: '2021-06-30',
        sagoNo: '20210107',
        sido: '경기도',
        sigungu: '이천시',
        dong: '장호원읍',
        addr: '오남리 98-7',
        fullAdr: '경기도 이천시 장호원읍 오남리 98-7',
        SEC_CD: '41500250',
        sagoLon: '127.632',
        sagoLat: '37.1071',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 손상에 따른 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-06-30'
      },
      geometry: { type: 'Point', coordinates: [127.632, 37.1071] }
    },
    {
      type: 'Feature',
      id: 894,
      properties: {
        sagoDate: '2021-06-06',
        sagoNo: '20210109',
        sido: '경기도',
        sigungu: '이천시',
        dong: '중리동',
        addr: '481',
        fullAdr: '경기도 이천시 중리동 481',
        SEC_CD: '41500103',
        sagoLon: '127.444',
        sagoLat: '37.2759',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.8',
        grdKind: '기타',
        sagoDetail: '우수관 손상에 따른 싱크홀 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2021-06-06'
      },
      geometry: { type: 'Point', coordinates: [127.444, 37.2759] }
    },
    {
      type: 'Feature',
      id: 895,
      properties: {
        sagoDate: '2021-06-16',
        sagoNo: '20210118',
        sido: '전라남도',
        sigungu: '목포시',
        dong: '',
        addr: '원산동1260-5(공원  앞 차도)',
        fullAdr: '전라남도 목포시  원산동1260-5(공원  앞 차도)',
        SEC_CD: '46110103',
        sagoLon: '126.38',
        sagoLat: '34.8121',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-06-18'
      },
      geometry: { type: 'Point', coordinates: [126.38, 34.8121] }
    },
    {
      type: 'Feature',
      id: 896,
      properties: {
        sagoDate: '2021-06-01',
        sagoNo: '20210124',
        sido: '울산광역시',
        sigungu: '중구',
        dong: '성안동',
        addr: '809-2',
        fullAdr: '울산광역시 중구 성안동 809-2',
        SEC_CD: '31110109',
        sagoLon: '129.311',
        sagoLat: '35.576',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '다짐 불량으로 인한 지반 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-06-02'
      },
      geometry: { type: 'Point', coordinates: [129.311, 35.576] }
    },
    {
      type: 'Feature',
      id: 897,
      properties: {
        sagoDate: '2021-07-06',
        sagoNo: '20210014',
        sido: '경상남도',
        sigungu: '양산시',
        dong: '하북면 삼감리',
        addr: '',
        fullAdr: '경상남도 양산시 하북면 삼감리',
        SEC_CD: '48330340',
        sagoLon: '129.069',
        sagoLat: '35.4488',
        sinkWidth: '1.8',
        sinkExtend: '2',
        sinkDepth: '1.7',
        grdKind: '',
        sagoDetail:
          '2021년 7월 6일 오전9시경 하북면 삼감리 삼감마을 이장의 신고로 수도관이 파열된 것을 확인하였고· 다량의 물이 유출되어 도로 지반 내 토사와 함께 지면으로 흘러나옴. 이후 토사가 빠진 상황에서 지반이 침하됨.',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-08-13'
      },
      geometry: { type: 'Point', coordinates: [129.069, 35.4488] }
    },
    {
      type: 'Feature',
      id: 898,
      properties: {
        sagoDate: '2021-07-08',
        sagoNo: '20210037',
        sido: '경상남도',
        sigungu: '양산시',
        dong: '북정동',
        addr: '23-5번지',
        fullAdr: '경상남도 양산시 북정동 23-5번지',
        SEC_CD: '48330107',
        sagoLon: '129.057',
        sagoLat: '35.3652',
        sinkWidth: '6',
        sinkExtend: '8',
        sinkDepth: '7',
        grdKind: '',
        sagoDetail:
          '장마로 인해 연약해진 보강토 옹벽 붕괴로 인해 배면토사 유출되어 상하수관로 파손 및 상부 도로지반 침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-08-26'
      },
      geometry: { type: 'Point', coordinates: [129.057, 35.3652] }
    },
    {
      type: 'Feature',
      id: 899,
      properties: {
        sagoDate: '2021-07-08',
        sagoNo: '20210042',
        sido: '강원특별자치도',
        sigungu: '강릉시',
        dong: '교동',
        addr: '교동 46-2(한신아파트 입구)',
        fullAdr: '강원특별자치도 강릉시 교동 교동 46-2(한신아파트 입구)',
        SEC_CD: '51150110',
        sagoLon: '128.9',
        sagoLat: '37.7678',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '폐관으로 지반유실 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-07-10'
      },
      geometry: { type: 'Point', coordinates: [128.9, 37.7678] }
    },
    {
      type: 'Feature',
      id: 900,
      properties: {
        sagoDate: '2021-07-24',
        sagoNo: '20210048',
        sido: '경기도',
        sigungu: '용인시 수지구',
        dong: '',
        addr: '수지구청역 1번출입구',
        fullAdr: '경기도 용인시 수지구  수지구청역 1번출입구',
        SEC_CD: '41465101',
        sagoLon: '127.095',
        sagoLat: '37.3227',
        sinkWidth: '3',
        sinkExtend: '100',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail:
          '수지구청역 모든 출입구 도로가 침하중이며· 상세 발생 원인은 지하철 공사시 다짐 불충분으로 판된되나 정확한 것은 알 수없음',
        deathCnt: '0',
        injuryCnt: '2',
        vehicleCnt: '1',
        trStatus: '복구중',
        trAmt: '0',
        trFnDate: '2021-09-30'
      },
      geometry: { type: 'Point', coordinates: [127.095, 37.3227] }
    },
    {
      type: 'Feature',
      id: 901,
      properties: {
        sagoDate: '2021-07-04',
        sagoNo: '20210055',
        sido: '서울특별시',
        sigungu: '강동구',
        dong: '암사동',
        addr: '527',
        fullAdr: '서울특별시 강동구 암사동 527',
        SEC_CD: '11740107',
        sagoLon: '127.129',
        sagoLat: '37.5546',
        sinkWidth: '1.1',
        sinkExtend: '0.6',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '호우 등으로 인해 지하철 복공판 밑 토류벽으로 토사가 유실· 지반 침하로 인한 하수흉관(900mm)과 맨홀간의 접합부가 파손(탈락)되어 동공이 발생된 것으로 사료',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-07-04'
      },
      geometry: { type: 'Point', coordinates: [127.129, 37.5546] }
    },
    {
      type: 'Feature',
      id: 902,
      properties: {
        sagoDate: '2021-07-15',
        sagoNo: '20210057',
        sido: '충청남도',
        sigungu: '공주시',
        dong: '',
        addr: '하대리 산56-15',
        fullAdr: '충청남도 공주시  하대리 산56-15',
        SEC_CD: '44150330',
        sagoLon: '127.148',
        sagoLat: '36.3575',
        sinkWidth: '1.2',
        sinkExtend: '24',
        sinkDepth: '0.16',
        grdKind: '충척층',
        sagoDetail: '상하수관 손상 또는 저수지 사면 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '10000000',
        trFnDate: '2021-12-15'
      },
      geometry: { type: 'Point', coordinates: [127.148, 36.3575] }
    },
    {
      type: 'Feature',
      id: 903,
      properties: {
        sagoDate: '2021-07-27',
        sagoNo: '20210059',
        sido: '서울특별시',
        sigungu: '성북구',
        dong: '안암동5가',
        addr: '146-31',
        fullAdr: '서울특별시 성북구 안암동5가 146-31',
        SEC_CD: '11290125',
        sagoLon: '127.029',
        sagoLat: '37.5862',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.8',
        grdKind: '',
        sagoDetail: '차량진동에 의한 상수도관(D 150m) 누수',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-07-28'
      },
      geometry: { type: 'Point', coordinates: [127.029, 37.5862] }
    },
    {
      type: 'Feature',
      id: 904,
      properties: {
        sagoDate: '2021-07-23',
        sagoNo: '20210129',
        sido: '경기도',
        sigungu: '평택시',
        dong: '신장동',
        addr: '671-8번지',
        fullAdr: '경기도 평택시 신장동 671-8번지',
        SEC_CD: '41220112',
        sagoLon: '127.054',
        sagoLat: '37.0745',
        sinkWidth: '0.8',
        sinkExtend: '5',
        sinkDepth: '1.4',
        grdKind: '',
        sagoDetail:
          '우수관(흄관600mm)과 접속관(흄관250mm)이음부 불량으로 인한 누수',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-07-24'
      },
      geometry: { type: 'Point', coordinates: [127.054, 37.0745] }
    },
    {
      type: 'Feature',
      id: 905,
      properties: {
        sagoDate: '2021-07-12',
        sagoNo: '20210144',
        sido: '경기도',
        sigungu: '하남시',
        dong: '초이동',
        addr: '570번지 일원',
        fullAdr: '경기도 하남시 초이동 570번지 일원',
        SEC_CD: '41450123',
        sagoLon: '127.17',
        sagoLat: '37.5377',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '기타',
        sagoDetail: '도로 다짐층 토사 유실·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '22000000',
        trFnDate: '2021-08-27'
      },
      geometry: { type: 'Point', coordinates: [127.17, 37.5377] }
    },
    {
      type: 'Feature',
      id: 906,
      properties: {
        sagoDate: '2021-07-11',
        sagoNo: '20210147',
        sido: '경기도',
        sigungu: '부천시',
        dong: '옥길동',
        addr: '790',
        fullAdr: '경기도 부천시 옥길동 790',
        SEC_CD: '41194106',
        sagoLon: '126.824',
        sagoLat: '37.4694',
        sinkWidth: '2.5',
        sinkExtend: '2.5',
        sinkDepth: '2.5',
        grdKind: '',
        sagoDetail: '하수관로 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-08-18'
      },
      geometry: { type: 'Point', coordinates: [126.824, 37.4694] }
    },
    {
      type: 'Feature',
      id: 907,
      properties: {
        sagoDate: '2021-07-15',
        sagoNo: '20210148',
        sido: '충청남도',
        sigungu: '보령시',
        dong: '오천면',
        addr: '소성리 700-88번지',
        fullAdr: '충청남도 보령시 오천면 소성리 700-88번지',
        SEC_CD: '44180320',
        sagoLon: '126.518',
        sagoLat: '36.4386',
        sinkWidth: '26',
        sinkExtend: '17',
        sinkDepth: '7.8',
        grdKind: '기타',
        sagoDetail:
          '조위차의 영향으로 수위 변동시 매립토(실트질모래)가 일부 유실되어 공동이 발생된 것으로 판단됨.',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-04-30'
      },
      geometry: { type: 'Point', coordinates: [126.518, 36.4386] }
    },
    {
      type: 'Feature',
      id: 908,
      properties: {
        sagoDate: '2021-07-06',
        sagoNo: '20210154',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '계림동',
        addr: '계림동 1072',
        fullAdr: '광주광역시 동구 계림동 계림동 1072',
        SEC_CD: '29110109',
        sagoLon: '126.924',
        sagoLat: '35.1634',
        sinkWidth: '2',
        sinkExtend: '5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '인접 콘크리트암거 구조물 하부 파손에 따른 토사세굴',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-07-30'
      },
      geometry: { type: 'Point', coordinates: [126.924, 35.1634] }
    },
    {
      type: 'Feature',
      id: 909,
      properties: {
        sagoDate: '2021-07-14',
        sagoNo: '20210155',
        sido: '경기도',
        sigungu: '의정부시',
        dong: '',
        addr: '입석로 97',
        fullAdr: '경기도 의정부시  입석로 97',
        SEC_CD: '41150110',
        sagoLon: '127.015',
        sagoLat: '37.7552',
        sinkWidth: '0.5',
        sinkExtend: '1',
        sinkDepth: '5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-07-14'
      },
      geometry: { type: 'Point', coordinates: [127.015, 37.7552] }
    },
    {
      type: 'Feature',
      id: 910,
      properties: {
        sagoDate: '2021-07-01',
        sagoNo: '20210162',
        sido: '전북특별자치도',
        sigungu: '익산시',
        dong: '신흥동',
        addr: '약촌로 133',
        fullAdr: '전북특별자치도 익산시 신흥동 약촌로 133',
        SEC_CD: '52140124',
        sagoLon: '126.974',
        sagoLat: '35.9446',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수관로 노후화로 인한 파손으로 토사 유실로 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-07-03'
      },
      geometry: { type: 'Point', coordinates: [126.974, 35.9446] }
    },
    {
      type: 'Feature',
      id: 911,
      properties: {
        sagoDate: '2021-07-09',
        sagoNo: '20210163',
        sido: '전북특별자치도',
        sigungu: '익산시',
        dong: '중앙동3가',
        addr: '인북로 3길 24',
        fullAdr: '전북특별자치도 익산시 중앙동3가 인북로 3길 24',
        SEC_CD: '52140105',
        sagoLon: '126.951',
        sagoLat: '35.9385',
        sinkWidth: '1',
        sinkExtend: '1.2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수관로 노후화로 인한 파손으로 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2020-07-10'
      },
      geometry: { type: 'Point', coordinates: [126.951, 35.9385] }
    },
    {
      type: 'Feature',
      id: 912,
      properties: {
        sagoDate: '2021-07-23',
        sagoNo: '20210164',
        sido: '전북특별자치도',
        sigungu: '익산시',
        dong: '함열읍',
        addr: '함열중앙로 46',
        fullAdr: '전북특별자치도 익산시 함열읍 함열중앙로 46',
        SEC_CD: '52140250',
        sagoLon: '126.963',
        sagoLat: '36.0782',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수관로 노후화로 인한 파손으로 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-07-25'
      },
      geometry: { type: 'Point', coordinates: [126.963, 36.0782] }
    },
    {
      type: 'Feature',
      id: 913,
      properties: {
        sagoDate: '2021-07-18',
        sagoNo: '20210168',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '탄방동',
        addr: '724번지 인근',
        fullAdr: '대전광역시 서구 탄방동 724번지 인근',
        SEC_CD: '30170106',
        sagoLon: '127.388',
        sagoLat: '36.3489',
        sinkWidth: '0.6',
        sinkExtend: '0.6',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '노후 하수관(450mm) 손상에 따른 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '5000000',
        trFnDate: '2021-07-19'
      },
      geometry: { type: 'Point', coordinates: [127.388, 36.3489] }
    },
    {
      type: 'Feature',
      id: 914,
      properties: {
        sagoDate: '2021-07-24',
        sagoNo: '20210169',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '가장동',
        addr: '55번지 인근(래미안아파트 111동 인근 교차로)',
        fullAdr:
          '대전광역시 서구 가장동 55번지 인근(래미안아파트 111동 인근 교차로)',
        SEC_CD: '30170109',
        sagoLon: '127.386',
        sagoLat: '36.3332',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '하수관과(600mm) 하수박스(W2200×H1500) 접속부 이음 불량으로 인한 도로침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '8000000',
        trFnDate: '2021-07-26'
      },
      geometry: { type: 'Point', coordinates: [127.386, 36.3332] }
    },
    {
      type: 'Feature',
      id: 915,
      properties: {
        sagoDate: '2021-07-15',
        sagoNo: '20210179',
        sido: '전북특별자치도',
        sigungu: '군산시',
        dong: '지곡동',
        addr: '263-1',
        fullAdr: '전북특별자치도 군산시 지곡동 263-1',
        SEC_CD: '52130143',
        sagoLon: '126.711',
        sagoLat: '35.9594',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '우수관로 파손으로 인한 토사 유출로 지반침하 발생·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-07-15'
      },
      geometry: { type: 'Point', coordinates: [126.711, 35.9594] }
    },
    {
      type: 'Feature',
      id: 916,
      properties: {
        sagoDate: '2021-07-06',
        sagoNo: '20210180',
        sido: '전북특별자치도',
        sigungu: '순창군',
        dong: '구림면',
        addr: '구암리 607-2 번지(지방도 729호선)',
        fullAdr:
          '전북특별자치도 순창군 구림면 구암리 607-2 번지(지방도 729호선)',
        SEC_CD: '52770400',
        sagoLon: '127.11',
        sagoLat: '35.4378',
        sinkWidth: '0.5',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '회전교차로 공사와 관련',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-01-12'
      },
      geometry: { type: 'Point', coordinates: [127.11, 35.4378] }
    },
    {
      type: 'Feature',
      id: 917,
      properties: {
        sagoDate: '2021-08-05',
        sagoNo: '20210052',
        sido: '서울특별시',
        sigungu: '중구',
        dong: '을지로1가',
        addr: '140-1(숭례문방향 2차로)',
        fullAdr: '서울특별시 중구 을지로1가 140-1(숭례문방향 2차로)',
        SEC_CD: '11140104',
        sagoLon: '126.982',
        sagoLat: '37.5653',
        sinkWidth: '0.1',
        sinkExtend: '0.1',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '폐하수관 존치 및 폐기물 매립으로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-08-06'
      },
      geometry: { type: 'Point', coordinates: [126.982, 37.5653] }
    },
    {
      type: 'Feature',
      id: 918,
      properties: {
        sagoDate: '2021-08-11',
        sagoNo: '20210056',
        sido: '대구광역시',
        sigungu: '동구',
        dong: '괴전동',
        addr: '274-3 교각아래(지하철연장공사구간)',
        fullAdr: '대구광역시 동구 괴전동 274-3 교각아래(지하철연장공사구간)',
        SEC_CD: '27140126',
        sagoLon: '128.74',
        sagoLat: '35.8724',
        sinkWidth: '8',
        sinkExtend: '8',
        sinkDepth: '8',
        grdKind: '기타',
        sagoDetail:
          '-하양에서 안심방향 지하철 연장공사 구간 지반침하 발생·-발생시각 8.11.(수) 22:00경',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '560000000',
        trFnDate: '2021-12-23'
      },
      geometry: { type: 'Point', coordinates: [128.74, 35.8724] }
    },
    {
      type: 'Feature',
      id: 919,
      properties: {
        sagoDate: '2021-08-18',
        sagoNo: '20210063',
        sido: '부산광역시',
        sigungu: '금정구',
        dong: '장전동',
        addr: '산2-12',
        fullAdr: '부산광역시 금정구 장전동 산2-12',
        SEC_CD: '26410108',
        sagoLon: '129.069',
        sagoLat: '35.2407',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '산 계곡부에서 도로를 횡단하는 흄관(D500) 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '5000000',
        trFnDate: '2021-08-20'
      },
      geometry: { type: 'Point', coordinates: [129.069, 35.2407] }
    },
    {
      type: 'Feature',
      id: 920,
      properties: {
        sagoDate: '2021-08-24',
        sagoNo: '20210066',
        sido: '부산광역시',
        sigungu: '사상구',
        dong: '덕포동',
        addr: '산 8-41(대덕여고) 앞 도로',
        fullAdr: '부산광역시 사상구 덕포동 산 8-41(대덕여고) 앞 도로',
        SEC_CD: '26530103',
        sagoLon: '128.993',
        sagoLat: '35.1786',
        sinkWidth: '5',
        sinkExtend: '4',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail:
          '우수관로(파형강관· D1·000mm) 하부 파손에 따른 누수로 도로함몰 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '21940',
        trFnDate: '2021-09-05'
      },
      geometry: { type: 'Point', coordinates: [128.993, 35.1786] }
    },
    {
      type: 'Feature',
      id: 921,
      properties: {
        sagoDate: '2021-08-25',
        sagoNo: '20210067',
        sido: '서울특별시',
        sigungu: '성북구',
        dong: '보문동7가',
        addr: '105-1',
        fullAdr: '서울특별시 성북구 보문동7가 105-1',
        SEC_CD: '11290129',
        sagoLon: '127.022',
        sagoLat: '37.5798',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '통신박스 하부 장기간 침하로 인한 동공 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-08-26'
      },
      geometry: { type: 'Point', coordinates: [127.022, 37.5798] }
    },
    {
      type: 'Feature',
      id: 922,
      properties: {
        sagoDate: '2021-08-21',
        sagoNo: '20210081',
        sido: '서울특별시',
        sigungu: '성북구',
        dong: '장위동',
        addr: '124-26',
        fullAdr: '서울특별시 성북구 장위동 124-26',
        SEC_CD: '11290138',
        sagoLon: '127.056',
        sagoLat: '37.6177',
        sinkWidth: '0.9',
        sinkExtend: '1.8',
        sinkDepth: '1.7',
        grdKind: '',
        sagoDetail: '건축공사장 차수 불량으로 인한 지하수위 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-08-21'
      },
      geometry: { type: 'Point', coordinates: [127.056, 37.6177] }
    },
    {
      type: 'Feature',
      id: 923,
      properties: {
        sagoDate: '2021-08-24',
        sagoNo: '20210146',
        sido: '부산광역시',
        sigungu: '사하구',
        dong: '신평동',
        addr: '572번지 일원',
        fullAdr: '부산광역시 사하구 신평동 572번지 일원',
        SEC_CD: '26380104',
        sagoLon: '128.974',
        sagoLat: '35.0854',
        sinkWidth: '3',
        sinkExtend: '10',
        sinkDepth: '5',
        grdKind: '기타',
        sagoDetail:
          '집중호우로 인한 부지 내 토사유실과 하부 매립쓰레기틍으로 인한 지반침하로 추측',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-09-24'
      },
      geometry: { type: 'Point', coordinates: [128.974, 35.0854] }
    },
    {
      type: 'Feature',
      id: 924,
      properties: {
        sagoDate: '2021-08-24',
        sagoNo: '20210153',
        sido: '부산광역시',
        sigungu: '북구',
        dong: '구포동',
        addr: '모분재로 148-1',
        fullAdr: '부산광역시 북구 구포동 모분재로 148-1',
        SEC_CD: '26320105',
        sagoLon: '129.012',
        sagoLat: '35.1967',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '우수박스 파손· 우수 유입에 따른 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-09-03'
      },
      geometry: { type: 'Point', coordinates: [129.012, 35.1967] }
    },
    {
      type: 'Feature',
      id: 925,
      properties: {
        sagoDate: '2021-08-13',
        sagoNo: '20210157',
        sido: '경기도',
        sigungu: '의정부시',
        dong: '산곡동',
        addr: '462-15',
        fullAdr: '경기도 의정부시 산곡동 462-15',
        SEC_CD: '41150113',
        sagoLon: '127.103',
        sagoLat: '37.7182',
        sinkWidth: '8',
        sinkExtend: '8',
        sinkDepth: '5',
        grdKind: '',
        sagoDetail: '유수 흐름 및 강우로 인한 지반 연약',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-08-14'
      },
      geometry: { type: 'Point', coordinates: [127.103, 37.7182] }
    },
    {
      type: 'Feature',
      id: 926,
      properties: {
        sagoDate: '2021-08-17',
        sagoNo: '20210161',
        sido: '대전광역시',
        sigungu: '유성구',
        dong: '신성동',
        addr: '216-6',
        fullAdr: '대전광역시 유성구 신성동 216-6',
        SEC_CD: '30200125',
        sagoLon: '127.35',
        sagoLat: '36.3865',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '맨홀과 흄관(D800mm) 접합부 노후 손상으로 함몰 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '3000000',
        trFnDate: '2021-08-26'
      },
      geometry: { type: 'Point', coordinates: [127.35, 36.3865] }
    },
    {
      type: 'Feature',
      id: 927,
      properties: {
        sagoDate: '2021-08-11',
        sagoNo: '20210165',
        sido: '전북특별자치도',
        sigungu: '익산시',
        dong: '평화동',
        addr: '평동로 3길 19-1',
        fullAdr: '전북특별자치도 익산시 평화동 평동로 3길 19-1',
        SEC_CD: '52140106',
        sagoLon: '126.947',
        sagoLat: '35.9332',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '하수관로 노후화· 파손으로 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-08-14'
      },
      geometry: { type: 'Point', coordinates: [126.947, 35.9332] }
    },
    {
      type: 'Feature',
      id: 928,
      properties: {
        sagoDate: '2021-08-06',
        sagoNo: '20210170',
        sido: '부산광역시',
        sigungu: '동래구',
        dong: '',
        addr: '명륜동 632-1번지 일원',
        fullAdr: '부산광역시 동래구  명륜동 632-1번지 일원',
        SEC_CD: '26260107',
        sagoLon: '129.082',
        sagoLat: '35.2076',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.8',
        grdKind: '',
        sagoDetail: '자연침하 발생추이·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-08-14'
      },
      geometry: { type: 'Point', coordinates: [129.082, 35.2076] }
    },
    {
      type: 'Feature',
      id: 929,
      properties: {
        sagoDate: '2021-08-30',
        sagoNo: '20210177',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '유촌동',
        addr: '유덕1교차로 하부',
        fullAdr: '광주광역시 서구 유촌동 유덕1교차로 하부',
        SEC_CD: '29140116',
        sagoLon: '126.848',
        sagoLat: '35.1629',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '지하철 2호선 공사 추진중 다짐불량으로 인한 침하 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-08-31'
      },
      geometry: { type: 'Point', coordinates: [126.848, 35.1629] }
    },
    {
      type: 'Feature',
      id: 930,
      properties: {
        sagoDate: '2021-08-11',
        sagoNo: '20210178',
        sido: '전라남도',
        sigungu: '여수시',
        dong: '화장동',
        addr: '733-5',
        fullAdr: '전라남도 여수시 화장동 733-5',
        SEC_CD: '46130137',
        sagoLon: '127.649',
        sagoLat: '34.773',
        sinkWidth: '0.2',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '하수관로 누수',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '3290000',
        trFnDate: '2021-12-09'
      },
      geometry: { type: 'Point', coordinates: [127.649, 34.773] }
    },
    {
      type: 'Feature',
      id: 931,
      properties: {
        sagoDate: '2021-08-25',
        sagoNo: '20220002',
        sido: '전북특별자치도',
        sigungu: '군산시',
        dong: '나운동',
        addr: '792-1',
        fullAdr: '전북특별자치도 군산시 나운동 792-1',
        SEC_CD: '52130144',
        sagoLon: '126.693',
        sagoLat: '35.9663',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 노후화로 인한 토사 유출로 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-08-25'
      },
      geometry: { type: 'Point', coordinates: [126.693, 35.9663] }
    },
    {
      type: 'Feature',
      id: 932,
      properties: {
        sagoDate: '2021-08-27',
        sagoNo: '20220003',
        sido: '전북특별자치도',
        sigungu: '군산시',
        dong: '월명동',
        addr: '19-5',
        fullAdr: '전북특별자치도 군산시 월명동 19-5',
        SEC_CD: '52130115',
        sagoLon: '126.709',
        sagoLat: '35.9837',
        sinkWidth: '0.3',
        sinkExtend: '0.3',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: 'KT관 손상으로 인한 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-08-27'
      },
      geometry: { type: 'Point', coordinates: [126.709, 35.9837] }
    },
    {
      type: 'Feature',
      id: 933,
      properties: {
        sagoDate: '2021-09-04',
        sagoNo: '20210122',
        sido: '서울특별시',
        sigungu: '강서구',
        dong: '염창동',
        addr: '284-76 앞',
        fullAdr: '서울특별시 강서구 염창동 284-76 앞',
        SEC_CD: '11500101',
        sagoLon: '126.875',
        sagoLat: '37.5483',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '빗물받이 연결관 접합불량으로 인한 토사유출(건축공사장 신설 하수관로와 접합부 하자)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-09-05'
      },
      geometry: { type: 'Point', coordinates: [126.875, 37.5483] }
    },
    {
      type: 'Feature',
      id: 934,
      properties: {
        sagoDate: '2021-09-01',
        sagoNo: '20210126',
        sido: '충청남도',
        sigungu: '당진시',
        dong: '시곡동',
        addr: '71-5',
        fullAdr: '충청남도 당진시 시곡동 71-5',
        SEC_CD: '44270105',
        sagoLon: '126.677',
        sagoLat: '36.9029',
        sinkWidth: '3',
        sinkExtend: '3',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail: '건축주가 공공부지 불법 점용에 의한 부실시공',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2021-09-01'
      },
      geometry: { type: 'Point', coordinates: [126.677, 36.9029] }
    },
    {
      type: 'Feature',
      id: 935,
      properties: {
        sagoDate: '2021-09-02',
        sagoNo: '20210132',
        sido: '경기도',
        sigungu: '평택시',
        dong: '비전동',
        addr: '840-20',
        fullAdr: '경기도 평택시 비전동 840-20',
        SEC_CD: '41220118',
        sagoLon: '127.103',
        sagoLat: '36.9975',
        sinkWidth: '3',
        sinkExtend: '5',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '배수관 600mm 볼트 부식 조인트 이탈',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '57400000',
        trFnDate: '2021-09-02'
      },
      geometry: { type: 'Point', coordinates: [127.103, 36.9975] }
    },
    {
      type: 'Feature',
      id: 936,
      properties: {
        sagoDate: '2021-09-08',
        sagoNo: '20210160',
        sido: '경상남도',
        sigungu: '하동군',
        dong: '적량면',
        addr: '고절리 1211-13',
        fullAdr: '경상남도 하동군 적량면 고절리 1211-13',
        SEC_CD: '48850330',
        sagoLon: '127.775',
        sagoLat: '35.062',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '2.1',
        grdKind: '기타',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2021-09-10'
      },
      geometry: { type: 'Point', coordinates: [127.775, 35.062] }
    },
    {
      type: 'Feature',
      id: 937,
      properties: {
        sagoDate: '2021-09-17',
        sagoNo: '20210166',
        sido: '전북특별자치도',
        sigungu: '익산시',
        dong: '남중동',
        addr: '익산대로 22길 6',
        fullAdr: '전북특별자치도 익산시 남중동 익산대로 22길 6',
        SEC_CD: '52140101',
        sagoLon: '126.949',
        sagoLat: '35.9442',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수관로 노후 및 파손으로 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-09-19'
      },
      geometry: { type: 'Point', coordinates: [126.949, 35.9442] }
    },
    {
      type: 'Feature',
      id: 938,
      properties: {
        sagoDate: '2021-09-04',
        sagoNo: '20210172',
        sido: '경기도',
        sigungu: '김포시',
        dong: '걸포동',
        addr: '389-15',
        fullAdr: '경기도 김포시 걸포동 389-15',
        SEC_CD: '41570102',
        sagoLon: '126.711',
        sagoLat: '37.6319',
        sinkWidth: '8',
        sinkExtend: '12',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail:
          '2019년 2월부터 2020년 8월까지 진행되었던 공사과정 중 본관건물 하부 토사가 증축공사 구간으로 슬라이딩 유출되면서 공동이 형성되어 있었던 것으로 추정됨',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '85000000',
        trFnDate: '2021-10-15'
      },
      geometry: { type: 'Point', coordinates: [126.711, 37.6319] }
    },
    {
      type: 'Feature',
      id: 939,
      properties: {
        sagoDate: '2021-09-22',
        sagoNo: '20210173',
        sido: '경기도',
        sigungu: '김포시',
        dong: '걸포동',
        addr: '389-15',
        fullAdr: '경기도 김포시 걸포동 389-15',
        SEC_CD: '41570102',
        sagoLon: '126.711',
        sagoLat: '37.6327',
        sinkWidth: '1.2',
        sinkExtend: '2.8',
        sinkDepth: '0.24',
        grdKind: '기타',
        sagoDetail:
          '2019년 2월부터 2020년 8월 까지의 시공과정(웰크론한텍)에서 발생되어진 공동으로 중단되어진 공사가 개시되면서 토사가 가라앉으며 발생됨',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '15000000',
        trFnDate: '2021-09-23'
      },
      geometry: { type: 'Point', coordinates: [126.711, 37.6327] }
    },
    {
      type: 'Feature',
      id: 940,
      properties: {
        sagoDate: '2021-09-10',
        sagoNo: '20220007',
        sido: '인천광역시',
        sigungu: '미추홀구',
        dong: '관교동',
        addr: '미추홀구 관교동 507-5번지(승학초등학교 정문쪽 이면도로)',
        fullAdr:
          '인천광역시 미추홀구 관교동 미추홀구 관교동 507-5번지(승학초등학교 정문쪽 이면도로)',
        SEC_CD: '28177106',
        sagoLon: '126.695',
        sagoLat: '37.4398',
        sinkWidth: '4',
        sinkExtend: '4.4',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '하수관 손상으로 인해 토사가 쓸려내려가 싱크홀 발생·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '2010000',
        trFnDate: '2021-10-08'
      },
      geometry: { type: 'Point', coordinates: [126.695, 37.4398] }
    },
    {
      type: 'Feature',
      id: 941,
      properties: {
        sagoDate: '2021-10-04',
        sagoNo: '20210137',
        sido: '경기도',
        sigungu: '시흥시',
        dong: '정왕동',
        addr: '정왕동 2697번지 인근',
        fullAdr: '경기도 시흥시 정왕동 정왕동 2697번지 인근',
        SEC_CD: '41390132',
        sagoLon: '126.683',
        sagoLat: '37.3266',
        sinkWidth: '20',
        sinkExtend: '30',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail:
          '인접대지 상호 굴착공사 동시 시행으로 도로침하 발생··- 도로 양측에서 진행하고 있는 굴착공사의 영향범위가 중첩되어 도로에 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-11-12'
      },
      geometry: { type: 'Point', coordinates: [126.683, 37.3266] }
    },
    {
      type: 'Feature',
      id: 942,
      properties: {
        sagoDate: '2021-10-14',
        sagoNo: '20210138',
        sido: '울산광역시',
        sigungu: '울주군',
        dong: '온산읍',
        addr: '덕신리 6번지 일원',
        fullAdr: '울산광역시 울주군 온산읍 덕신리 6번지 일원',
        SEC_CD: '31710250',
        sagoLon: '129.317',
        sagoLat: '35.4256',
        sinkWidth: '2',
        sinkExtend: '1',
        sinkDepth: '2.5',
        grdKind: '충척층',
        sagoDetail:
          '중앙분리대 비다짐 구간(No.14+17.8) 굴진공정 진행 중 토사 붕락발생··10. 9. ~ 10. 13. 우천으로 약화된 지반 내 하부 굴진 진행 및 숏크리트 타설 도중 붕락 발생(붕락 시 우수 쏟아짐)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-11-15'
      },
      geometry: { type: 'Point', coordinates: [129.317, 35.4256] }
    },
    {
      type: 'Feature',
      id: 943,
      properties: {
        sagoDate: '2021-10-18',
        sagoNo: '20210142',
        sido: '경기도',
        sigungu: '김포시',
        dong: '고촌읍',
        addr: '신곡리 281-118',
        fullAdr: '경기도 김포시 고촌읍 신곡리 281-118',
        SEC_CD: '41570253',
        sagoLon: '126.77',
        sagoLat: '37.6062',
        sinkWidth: '2',
        sinkExtend: '1.5',
        sinkDepth: '0.2',
        grdKind: '기타',
        sagoDetail:
          '신곡9지구 아파트 신축 굴착에 따른 흙막이공 시공과 관리 부주의로 인한 지반 침하로 발생한 것으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '3980000',
        trFnDate: '2021-10-18'
      },
      geometry: { type: 'Point', coordinates: [126.77, 37.6062] }
    },
    {
      type: 'Feature',
      id: 944,
      properties: {
        sagoDate: '2021-10-06',
        sagoNo: '20210152',
        sido: '부산광역시',
        sigungu: '북구',
        dong: '구포동',
        addr: '구포동 918-5번지 일원',
        fullAdr: '부산광역시 북구 구포동 구포동 918-5번지 일원',
        SEC_CD: '26320105',
        sagoLon: '128.995',
        sagoLat: '35.1983',
        sinkWidth: '0.5',
        sinkExtend: '0.7',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '우수관 노후· 파손 및 누수로 인한 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-10-07'
      },
      geometry: { type: 'Point', coordinates: [128.995, 35.1983] }
    },
    {
      type: 'Feature',
      id: 945,
      properties: {
        sagoDate: '2021-11-15',
        sagoNo: '20210171',
        sido: '부산광역시',
        sigungu: '서구',
        dong: '서대신동3가',
        addr: '꽃마을로 59 일원',
        fullAdr: '부산광역시 서구 서대신동3가 꽃마을로 59 일원',
        SEC_CD: '26140106',
        sagoLon: '129.011',
        sagoLat: '35.1192',
        sinkWidth: '0.3',
        sinkExtend: '0.3',
        sinkDepth: '1.5',
        grdKind: '호상편마암',
        sagoDetail: '파형강관(D800mm· 1993년) 누수로 인한 동공발생 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '10000000',
        trFnDate: '2021-11-16'
      },
      geometry: { type: 'Point', coordinates: [129.011, 35.1192] }
    },
    {
      type: 'Feature',
      id: 946,
      properties: {
        sagoDate: '2021-11-04',
        sagoNo: '20210174',
        sido: '경기도',
        sigungu: '김포시',
        dong: '걸포동',
        addr: '389-15',
        fullAdr: '경기도 김포시 걸포동 389-15',
        SEC_CD: '41570102',
        sagoLon: '126.711',
        sagoLat: '37.6323',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '0.25',
        grdKind: '기타',
        sagoDetail:
          '2019년 2월부터 2020년 8월 까지의 시공과정(웰크론한텍)에서 발생되어진 공동으로 중단되어진 공사가 개시되면서 토사가 가라앉으며 발생됨',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '10000000',
        trFnDate: '2021-11-05'
      },
      geometry: { type: 'Point', coordinates: [126.711, 37.6323] }
    },
    {
      type: 'Feature',
      id: 947,
      properties: {
        sagoDate: '2021-11-01',
        sagoNo: '20210175',
        sido: '충청남도',
        sigungu: '홍성군',
        dong: '홍동면',
        addr: '월현리 426-2번지 오수맨홀',
        fullAdr: '충청남도 홍성군 홍동면 월현리 426-2번지 오수맨홀',
        SEC_CD: '44800330',
        sagoLon: '126.671',
        sagoLat: '36.5477',
        sinkWidth: '0.9',
        sinkExtend: '0.9',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail: '하수관 손상으로 인한 세굴 발생으로 추정하고 있음.',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2022-11-10'
      },
      geometry: { type: 'Point', coordinates: [126.671, 36.5477] }
    },
    {
      type: 'Feature',
      id: 948,
      properties: {
        sagoDate: '2021-11-16',
        sagoNo: '20210176',
        sido: '경상남도',
        sigungu: '진주시',
        dong: '상대동',
        addr: '상대동 896-36',
        fullAdr: '경상남도 진주시 상대동 상대동 896-36',
        SEC_CD: '48170121',
        sagoLon: '128.13',
        sagoLat: '35.1787',
        sinkWidth: '0.4',
        sinkExtend: '0.4',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '폐수관로 노후화',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-11-17'
      },
      geometry: { type: 'Point', coordinates: [128.13, 35.1787] }
    },
    {
      type: 'Feature',
      id: 949,
      properties: {
        sagoDate: '2021-12-30',
        sagoNo: '20210143',
        sido: '경기도',
        sigungu: '고양시 일산동구',
        dong: '마두동',
        addr: '802-1',
        fullAdr: '경기도 고양시 일산동구 마두동 802-1',
        SEC_CD: '41285105',
        sagoLon: '126.779',
        sagoLat: '37.6516',
        sinkWidth: '3',
        sinkExtend: '7',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '발생 원인 파악 중',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '0',
        trFnDate: '2021-12-31'
      },
      geometry: { type: 'Point', coordinates: [126.779, 37.6516] }
    },
    {
      type: 'Feature',
      id: 950,
      properties: {
        sagoDate: '2021-12-01',
        sagoNo: '20210167',
        sido: '경상남도',
        sigungu: '창원시 의창구',
        dong: '봉곡동',
        addr: '봉곡로 68번길 8 앞 이면도로',
        fullAdr: '경상남도 창원시 의창구 봉곡동 봉곡로 68번길 8 앞 이면도로',
        SEC_CD: '48121127',
        sagoLon: '128.667',
        sagoLat: '35.2484',
        sinkWidth: '2.5',
        sinkExtend: '5',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '우· 오수 연결관 노후로 인한 누수',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2021-12-01'
      },
      geometry: { type: 'Point', coordinates: [128.667, 35.2484] }
    },
    {
      type: 'Feature',
      id: 951,
      properties: {
        sagoDate: '2022-01-10',
        sagoNo: '20220005',
        sido: '서울특별시',
        sigungu: '은평구',
        dong: '수색동',
        addr: '367-7일대',
        fullAdr: '서울특별시 은평구 수색동 367-7일대',
        SEC_CD: '11380101',
        sagoLon: '126.895',
        sagoLat: '37.5834',
        sinkWidth: '2.6',
        sinkExtend: '2.4',
        sinkDepth: '1.2',
        grdKind: '',
        sagoDetail:
          '○ 사고장소 하단에 위치한 사각형거는 벽체 및 슬라브 노후로 인해 콘크리트 열화 및 철근 부식이 상당히 진행된 상태로· 중차량이 진입하여 무게를 견디지 못하고 도로함몰 발생·○ 사고 위치는 수색6재개발구역과 수색2재개발구역(시장정비사업)의 사이 골목으로 수색2구역에 포함',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-02-18'
      },
      geometry: { type: 'Point', coordinates: [126.895, 37.5834] }
    },
    {
      type: 'Feature',
      id: 952,
      properties: {
        sagoDate: '2022-01-23',
        sagoNo: '20220008',
        sido: '서울특별시',
        sigungu: '강서구',
        dong: '마곡동',
        addr: '마곡동  775-5',
        fullAdr: '서울특별시 강서구 마곡동 마곡동  775-5',
        SEC_CD: '11500105',
        sagoLon: '126.837',
        sagoLat: '37.5668',
        sinkWidth: '1.5',
        sinkExtend: '0.5',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail:
          '이랜드글로벌R&D센터 (마곡동 780 일대) 건축현장 지하가시설공사로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-01-28'
      },
      geometry: { type: 'Point', coordinates: [126.837, 37.5668] }
    },
    {
      type: 'Feature',
      id: 953,
      properties: {
        sagoDate: '2022-01-23',
        sagoNo: '20220009',
        sido: '서울특별시',
        sigungu: '종로구',
        dong: '',
        addr: '종로5가 4-1앞 도로',
        fullAdr: '서울특별시 종로구  종로5가 4-1앞 도로',
        SEC_CD: '11110163',
        sagoLon: '127.006',
        sagoLat: '37.5712',
        sinkWidth: '3',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail:
          '- 관 종 : 강관 구경 900mm(1970년 부설) 추정·· - 누수원인 : 강관 용접부 파손 추정·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-01-24'
      },
      geometry: { type: 'Point', coordinates: [127.006, 37.5712] }
    },
    {
      type: 'Feature',
      id: 954,
      properties: {
        sagoDate: '2022-01-30',
        sagoNo: '20220010',
        sido: '전라남도',
        sigungu: '영광군',
        dong: '염산면',
        addr: '두우리 947',
        fullAdr: '전라남도 영광군 염산면 두우리 947',
        SEC_CD: '46870360',
        sagoLon: '126.301',
        sagoLat: '35.2327',
        sinkWidth: '2',
        sinkExtend: '7',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail:
          '해안도로 파랑으로 인한 토사 세굴 구간에 중차량인 토사운반용 작업차량의 통행으로 지반 침하',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '8900000',
        trFnDate: '2022-02-18'
      },
      geometry: { type: 'Point', coordinates: [126.301, 35.2327] }
    },
    {
      type: 'Feature',
      id: 955,
      properties: {
        sagoDate: '2022-01-20',
        sagoNo: '20220042',
        sido: '부산광역시',
        sigungu: '북구',
        dong: '만덕동',
        addr: '792번지',
        fullAdr: '부산광역시 북구 만덕동 792번지',
        SEC_CD: '26320103',
        sagoLon: '129.037',
        sagoLat: '35.2134',
        sinkWidth: '0.5',
        sinkExtend: '0.7',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-01-21'
      },
      geometry: { type: 'Point', coordinates: [129.037, 35.2134] }
    },
    {
      type: 'Feature',
      id: 956,
      properties: {
        sagoDate: '2022-01-04',
        sagoNo: '20220055',
        sido: '부산광역시',
        sigungu: '서구',
        dong: '아미동2가',
        addr: '85-3 번지 일원',
        fullAdr: '부산광역시 서구 아미동2가 85-3 번지 일원',
        SEC_CD: '26140116',
        sagoLon: '129.017',
        sagoLat: '35.0986',
        sinkWidth: '0.25',
        sinkExtend: '25',
        sinkDepth: '3',
        grdKind: '기타',
        sagoDetail: '흄관(D900) 누수(함몰)로 인한 동공발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '10500000',
        trFnDate: '2022-03-25'
      },
      geometry: { type: 'Point', coordinates: [129.017, 35.0986] }
    },
    {
      type: 'Feature',
      id: 957,
      properties: {
        sagoDate: '2022-01-20',
        sagoNo: '20220069',
        sido: '전북특별자치도',
        sigungu: '익산시',
        dong: '동산동',
        addr: '동천로 7길 18',
        fullAdr: '전북특별자치도 익산시 동산동 동천로 7길 18',
        SEC_CD: '52140111',
        sagoLon: '126.971',
        sagoLat: '35.9316',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '우수관 노후화로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-01-12'
      },
      geometry: { type: 'Point', coordinates: [126.971, 35.9316] }
    },
    {
      type: 'Feature',
      id: 958,
      properties: {
        sagoDate: '2022-02-11',
        sagoNo: '20220107',
        sido: '경상북도',
        sigungu: '봉화군',
        dong: '석포면',
        addr: '석포리 산1-195',
        fullAdr: '경상북도 봉화군 석포면 석포리 산1-195',
        SEC_CD: '47920390',
        sagoLon: '129.131',
        sagoLat: '37.0818',
        sinkWidth: '0.3',
        sinkExtend: '0.3',
        sinkDepth: '1.8',
        grdKind: '기타',
        sagoDetail:
          '원인을 알 수 없어 지하안전정보시스템을 통해 지반침하 안전검점 지반탐사를 신청하였으나 그 결과 특이사항 없었음.',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-02-12'
      },
      geometry: { type: 'Point', coordinates: [129.131, 37.0818] }
    },
    {
      type: 'Feature',
      id: 959,
      properties: {
        sagoDate: '2022-03-03',
        sagoNo: '20220011',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '농성동',
        addr: '454-3번지(광천1교 교차로)',
        fullAdr: '광주광역시 서구 농성동 454-3번지(광천1교 교차로)',
        SEC_CD: '29140106',
        sagoLon: '126.888',
        sagoLat: '35.1611',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail: '하수관 노후 및 파손으로 인한 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-03-04'
      },
      geometry: { type: 'Point', coordinates: [126.888, 35.1611] }
    },
    {
      type: 'Feature',
      id: 960,
      properties: {
        sagoDate: '2022-03-11',
        sagoNo: '20220014',
        sido: '울산광역시',
        sigungu: '중구',
        dong: '',
        addr: '당산4길7 명신여인숙 앞',
        fullAdr: '울산광역시 중구  당산4길7 명신여인숙 앞',
        SEC_CD: '31110108',
        sagoLon: '129.31',
        sagoLat: '35.5541',
        sinkWidth: '1.5',
        sinkExtend: '3',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2022-03-11'
      },
      geometry: { type: 'Point', coordinates: [129.31, 35.5541] }
    },
    {
      type: 'Feature',
      id: 961,
      properties: {
        sagoDate: '2022-03-10',
        sagoNo: '20220038',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '월평동',
        addr: '1124',
        fullAdr: '대전광역시 서구 월평동 1124',
        SEC_CD: '30170113',
        sagoLon: '127.359',
        sagoLat: '36.3546',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '하수관로(D300mm) 노후 손상으로 함몰발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '4000000',
        trFnDate: '2022-03-10'
      },
      geometry: { type: 'Point', coordinates: [127.359, 36.3546] }
    },
    {
      type: 'Feature',
      id: 962,
      properties: {
        sagoDate: '2022-03-29',
        sagoNo: '20220071',
        sido: '전북특별자치도',
        sigungu: '익산시',
        dong: '주현동',
        addr: '주현로 36',
        fullAdr: '전북특별자치도 익산시 주현동 주현로 36',
        SEC_CD: '52140108',
        sagoLon: '126.956',
        sagoLat: '35.9334',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '오수맨홀 노후화로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-03-30'
      },
      geometry: { type: 'Point', coordinates: [126.956, 35.9334] }
    },
    {
      type: 'Feature',
      id: 963,
      properties: {
        sagoDate: '2022-03-23',
        sagoNo: '20220075',
        sido: '전북특별자치도',
        sigungu: '익산시',
        dong: '동산동',
        addr: '목천로 325',
        fullAdr: '전북특별자치도 익산시 동산동 목천로 325',
        SEC_CD: '52140111',
        sagoLon: '126.957',
        sagoLat: '35.9277',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '우수관 노후화로 인한 지반 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-03-24'
      },
      geometry: { type: 'Point', coordinates: [126.957, 35.9277] }
    },
    {
      type: 'Feature',
      id: 964,
      properties: {
        sagoDate: '2022-03-09',
        sagoNo: '20220076',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '평화동1가',
        addr: '725-5',
        fullAdr: '전북특별자치도 전주시 완산구 평화동1가 725-5',
        SEC_CD: '52111132',
        sagoLon: '127.136',
        sagoLat: '35.7949',
        sinkWidth: '2',
        sinkExtend: '2.5',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-03-12'
      },
      geometry: { type: 'Point', coordinates: [127.136, 35.7949] }
    },
    {
      type: 'Feature',
      id: 965,
      properties: {
        sagoDate: '2022-03-10',
        sagoNo: '20220089',
        sido: '충청북도',
        sigungu: '음성군',
        dong: '음성읍',
        addr: '음성읍 읍내리 785-20(예닮맛집 옆)',
        fullAdr: '충청북도 음성군 음성읍 음성읍 읍내리 785-20(예닮맛집 옆)',
        SEC_CD: '43770250',
        sagoLon: '127.681',
        sagoLat: '36.9322',
        sinkWidth: '1.5',
        sinkExtend: '3.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail:
          "'2021년도 하반기 및 2022년 3월경 총 2차례·지름 1m이상의 지반함몰 발생 이력 확인.·정확한 원인 파악은 지반 탐사 필요.",
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2022-03-30'
      },
      geometry: { type: 'Point', coordinates: [127.681, 36.9322] }
    },
    {
      type: 'Feature',
      id: 966,
      properties: {
        sagoDate: '2022-04-13',
        sagoNo: '20220033',
        sido: '전라남도',
        sigungu: '목포시',
        dong: '동명동',
        addr: '7',
        fullAdr: '전라남도 목포시 동명동 7',
        SEC_CD: '46110118',
        sagoLon: '126.391',
        sagoLat: '34.7882',
        sinkWidth: '1.5',
        sinkExtend: '15',
        sinkDepth: '0.8',
        grdKind: '충척층',
        sagoDetail:
          '신축공사 지하 터파기 작업으로 인한 토사 유출로 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '11000000',
        trFnDate: '2022-08-05'
      },
      geometry: { type: 'Point', coordinates: [126.391, 34.7882] }
    },
    {
      type: 'Feature',
      id: 967,
      properties: {
        sagoDate: '2022-04-28',
        sagoNo: '20220039',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '괴정동',
        addr: '81-7',
        fullAdr: '대전광역시 서구 괴정동 81-7',
        SEC_CD: '30170108',
        sagoLon: '127.386',
        sagoLat: '36.3368',
        sinkWidth: '0.9',
        sinkExtend: '1.4',
        sinkDepth: '0.9',
        grdKind: '기타',
        sagoDetail: '하수관로(D450mm) 관접합부 연결불량으로 인한 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '3000000',
        trFnDate: '2022-04-28'
      },
      geometry: { type: 'Point', coordinates: [127.386, 36.3368] }
    },
    {
      type: 'Feature',
      id: 968,
      properties: {
        sagoDate: '2022-04-01',
        sagoNo: '20220041',
        sido: '경상북도',
        sigungu: '예천군',
        dong: '예천읍',
        addr: '지내리 311-19(폐수처리장 입구)',
        fullAdr: '경상북도 예천군 예천읍 지내리 311-19(폐수처리장 입구)',
        SEC_CD: '47900250',
        sagoLon: '128.416',
        sagoLat: '36.6391',
        sinkWidth: '2',
        sinkExtend: '3.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관로(D300mm) 노후· 파손으로 인한 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '15810000',
        trFnDate: '2022-04-12'
      },
      geometry: { type: 'Point', coordinates: [128.416, 36.6391] }
    },
    {
      type: 'Feature',
      id: 969,
      properties: {
        sagoDate: '2022-04-13',
        sagoNo: '20220053',
        sido: '울산광역시',
        sigungu: '중구',
        dong: '',
        addr: '해오름3길 30',
        fullAdr: '울산광역시 중구  해오름3길 30',
        SEC_CD: '31110115',
        sagoLon: '129.344',
        sagoLat: '35.563',
        sinkWidth: '1',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-04-13'
      },
      geometry: { type: 'Point', coordinates: [129.344, 35.563] }
    },
    {
      type: 'Feature',
      id: 970,
      properties: {
        sagoDate: '2022-04-13',
        sagoNo: '20220058',
        sido: '경기도',
        sigungu: '화성시',
        dong: '서신면',
        addr: '홍법리 510-2',
        fullAdr: '경기도 화성시 서신면 홍법리 510-2',
        SEC_CD: '41590350',
        sagoLon: '126.72',
        sagoLat: '37.167',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '하자보수 기간 후 자연침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-04-13'
      },
      geometry: { type: 'Point', coordinates: [126.72, 37.167] }
    },
    {
      type: 'Feature',
      id: 971,
      properties: {
        sagoDate: '2022-04-27',
        sagoNo: '20220059',
        sido: '경기도',
        sigungu: '화성시',
        dong: '서신면',
        addr: '궁평리 611',
        fullAdr: '경기도 화성시 서신면 궁평리 611',
        SEC_CD: '41590350',
        sagoLon: '126.694',
        sagoLat: '37.1249',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '하자보수 기간 후 자연 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-04-27'
      },
      geometry: { type: 'Point', coordinates: [126.694, 37.1249] }
    },
    {
      type: 'Feature',
      id: 972,
      properties: {
        sagoDate: '2022-04-08',
        sagoNo: '20220072',
        sido: '전북특별자치도',
        sigungu: '익산시',
        dong: '중앙동3가',
        addr: '평동로 7길 47',
        fullAdr: '전북특별자치도 익산시 중앙동3가 평동로 7길 47',
        SEC_CD: '52140105',
        sagoLon: '126.949',
        sagoLat: '35.9363',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.2',
        grdKind: '기타',
        sagoDetail: '우수관 노후화로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-04-10'
      },
      geometry: { type: 'Point', coordinates: [126.949, 35.9363] }
    },
    {
      type: 'Feature',
      id: 973,
      properties: {
        sagoDate: '2022-04-23',
        sagoNo: '20220073',
        sido: '전북특별자치도',
        sigungu: '익산시',
        dong: '동산동',
        addr: '동산동 1052-14',
        fullAdr: '전북특별자치도 익산시 동산동 동산동 1052-14',
        SEC_CD: '52140111',
        sagoLon: '126.966',
        sagoLat: '35.9323',
        sinkWidth: '3',
        sinkExtend: '3',
        sinkDepth: '2.5',
        grdKind: '기타',
        sagoDetail: '우수맨홀 하부 부식으로 인한 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-04-25'
      },
      geometry: { type: 'Point', coordinates: [126.966, 35.9323] }
    },
    {
      type: 'Feature',
      id: 974,
      properties: {
        sagoDate: '2022-04-20',
        sagoNo: '20220078',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '중화산동2가',
        addr: '119-14',
        fullAdr: '전북특별자치도 전주시 완산구 중화산동2가 119-14',
        SEC_CD: '52111128',
        sagoLon: '127.126',
        sagoLat: '35.8123',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-04-20'
      },
      geometry: { type: 'Point', coordinates: [127.126, 35.8123] }
    },
    {
      type: 'Feature',
      id: 975,
      properties: {
        sagoDate: '2022-04-27',
        sagoNo: '20220108',
        sido: '광주광역시',
        sigungu: '남구',
        dong: '월산동',
        addr: '1058-1(돌고개역 부근)',
        fullAdr: '광주광역시 남구 월산동 1058-1(돌고개역 부근)',
        SEC_CD: '29155104',
        sagoLon: '126.896',
        sagoLat: '35.1514',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '지하철 1호선 공사 구간 토사 유실 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-04-27'
      },
      geometry: { type: 'Point', coordinates: [126.896, 35.1514] }
    },
    {
      type: 'Feature',
      id: 976,
      properties: {
        sagoDate: '2022-05-06',
        sagoNo: '20220015',
        sido: '강원특별자치도',
        sigungu: '양양군',
        dong: '강현면',
        addr: '주청리2-15번지  신축 공사장 인근',
        fullAdr:
          '강원특별자치도 양양군 강현면 주청리2-15번지  신축 공사장 인근',
        SEC_CD: '51830350',
        sagoLon: '128.632',
        sagoLat: '38.1174',
        sinkWidth: '5',
        sinkExtend: '2',
        sinkDepth: '3',
        grdKind: '기타',
        sagoDetail: '공사중 지반침하발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-05-19'
      },
      geometry: { type: 'Point', coordinates: [128.632, 38.1174] }
    },
    {
      type: 'Feature',
      id: 977,
      properties: {
        sagoDate: '2022-05-31',
        sagoNo: '20220017',
        sido: '경기도',
        sigungu: '고양시 일산동구',
        dong: '백석동',
        addr: '1297',
        fullAdr: '경기도 고양시 일산동구 백석동 1297',
        SEC_CD: '41285106',
        sagoLon: '126.788',
        sagoLat: '37.644',
        sinkWidth: '3',
        sinkExtend: '10',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '굴착공사 중 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2022-05-31'
      },
      geometry: { type: 'Point', coordinates: [126.788, 37.644] }
    },
    {
      type: 'Feature',
      id: 978,
      properties: {
        sagoDate: '2022-05-31',
        sagoNo: '20220018',
        sido: '세종특별자치시',
        sigungu: '세종특별자치시',
        dong: '',
        addr: '세종특별자치시 남세종로 326 소담고등학교',
        fullAdr:
          '세종특별자치시 세종특별자치시  세종특별자치시 남세종로 326 소담고등학교',
        SEC_CD: '36110102',
        sagoLon: '127.302',
        sagoLat: '36.4826',
        sinkWidth: '1',
        sinkExtend: '0.8',
        sinkDepth: '1.6',
        grdKind: '기타',
        sagoDetail:
          '우수맨홀 최하단부 벽체 손상에 의한 시간 경과에 따라·토사 유출량 증가로 지반변형에 의한 침하',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '10340000',
        trFnDate: '2022-07-05'
      },
      geometry: { type: 'Point', coordinates: [127.302, 36.4826] }
    },
    {
      type: 'Feature',
      id: 979,
      properties: {
        sagoDate: '2022-05-05',
        sagoNo: '20220040',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '갈마동',
        addr: '824',
        fullAdr: '대전광역시 서구 갈마동 824',
        SEC_CD: '30170111',
        sagoLon: '127.374',
        sagoLat: '36.3527',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '1.3',
        grdKind: '기타',
        sagoDetail: '하수관로(D300mm) 노후 손상으로 함몰발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '3000000',
        trFnDate: '2022-05-05'
      },
      geometry: { type: 'Point', coordinates: [127.374, 36.3527] }
    },
    {
      type: 'Feature',
      id: 980,
      properties: {
        sagoDate: '2022-05-26',
        sagoNo: '20220060',
        sido: '경기도',
        sigungu: '화성시',
        dong: '송산면',
        addr: '사강리 704-6',
        fullAdr: '경기도 화성시 송산면 사강리 704-6',
        SEC_CD: '41590340',
        sagoLon: '126.731',
        sagoLat: '37.2149',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '하자보수 기간 후 자연 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-05-26'
      },
      geometry: { type: 'Point', coordinates: [126.731, 37.2149] }
    },
    {
      type: 'Feature',
      id: 981,
      properties: {
        sagoDate: '2022-05-31',
        sagoNo: '20220074',
        sido: '전북특별자치도',
        sigungu: '익산시',
        dong: '신흥동',
        addr: '서동로 23길 11 오리온2공장 입구',
        fullAdr: '전북특별자치도 익산시 신흥동 서동로 23길 11 오리온2공장 입구',
        SEC_CD: '52140124',
        sagoLon: '126.973',
        sagoLat: '35.9422',
        sinkWidth: '1.5',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '우수관 노후화로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-06-23'
      },
      geometry: { type: 'Point', coordinates: [126.973, 35.9422] }
    },
    {
      type: 'Feature',
      id: 982,
      properties: {
        sagoDate: '2022-05-10',
        sagoNo: '20220079',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '동완산동',
        addr: '10-5',
        fullAdr: '전북특별자치도 전주시 완산구 동완산동 10-5',
        SEC_CD: '52111122',
        sagoLon: '127.147',
        sagoLat: '35.8105',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-05-12'
      },
      geometry: { type: 'Point', coordinates: [127.147, 35.8105] }
    },
    {
      type: 'Feature',
      id: 983,
      properties: {
        sagoDate: '2022-05-10',
        sagoNo: '20220082',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '중화산동2가',
        addr: '756-3',
        fullAdr: '전북특별자치도 전주시 완산구 중화산동2가 756-3',
        SEC_CD: '52111128',
        sagoLon: '127.118',
        sagoLat: '35.82',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '기타',
        sagoDetail: '하수관로 파손으로 인한 지반 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-05-10'
      },
      geometry: { type: 'Point', coordinates: [127.118, 35.82] }
    },
    {
      type: 'Feature',
      id: 984,
      properties: {
        sagoDate: '2022-05-23',
        sagoNo: '20220090',
        sido: '충청북도',
        sigungu: '음성군',
        dong: '음성읍',
        addr: '음성읍 읍내리 692-3(풍산연립 라동 앞)',
        fullAdr: '충청북도 음성군 음성읍 음성읍 읍내리 692-3(풍산연립 라동 앞)',
        SEC_CD: '43770250',
        sagoLon: '127.69',
        sagoLat: '36.9422',
        sinkWidth: '0.2',
        sinkExtend: '2',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail:
          '도로 내 지름 0.1m· 깊이 2m이상 지반 침하 발생·음성군 수도사업소 하수도팀 관로 CCTV 확인결과· 관로 이상 확인',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-06-20'
      },
      geometry: { type: 'Point', coordinates: [127.69, 36.9422] }
    },
    {
      type: 'Feature',
      id: 985,
      properties: {
        sagoDate: '2022-05-18',
        sagoNo: '20220092',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '우산동',
        addr: '광주광역시 광산구 우산동1598-3번지 주변',
        fullAdr:
          '광주광역시 광산구 우산동 광주광역시 광산구 우산동1598-3번지 주변',
        SEC_CD: '29200109',
        sagoLon: '126.808',
        sagoLat: '35.1596',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-05-19'
      },
      geometry: { type: 'Point', coordinates: [126.808, 35.1596] }
    },
    {
      type: 'Feature',
      id: 986,
      properties: {
        sagoDate: '2022-05-20',
        sagoNo: '20220094',
        sido: '대전광역시',
        sigungu: '중구',
        dong: '',
        addr: '중촌동 448',
        fullAdr: '대전광역시 중구  중촌동 448',
        SEC_CD: '30140104',
        sagoLon: '127.411',
        sagoLat: '36.3412',
        sinkWidth: '4',
        sinkExtend: '5',
        sinkDepth: '0.6',
        grdKind: '',
        sagoDetail: '하수관 손상 및 다짐 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-05-20'
      },
      geometry: { type: 'Point', coordinates: [127.411, 36.3412] }
    },
    {
      type: 'Feature',
      id: 987,
      properties: {
        sagoDate: '2022-05-12',
        sagoNo: '20220096',
        sido: '대전광역시',
        sigungu: '중구',
        dong: '',
        addr: '대흥동 326-613',
        fullAdr: '대전광역시 중구  대흥동 326-613',
        SEC_CD: '30140105',
        sagoLon: '127.422',
        sagoLat: '36.3204',
        sinkWidth: '4',
        sinkExtend: '7',
        sinkDepth: '0.6',
        grdKind: '',
        sagoDetail: '하수관 손상 및 다짐 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-05-12'
      },
      geometry: { type: 'Point', coordinates: [127.422, 36.3204] }
    },
    {
      type: 'Feature',
      id: 988,
      properties: {
        sagoDate: '2022-05-24',
        sagoNo: '20220110',
        sido: '충청북도',
        sigungu: '제천시',
        dong: '신백동',
        addr: '신백동 202-1번지 빨래박사 앞',
        fullAdr: '충청북도 제천시 신백동 신백동 202-1번지 빨래박사 앞',
        SEC_CD: '43150120',
        sagoLon: '128.225',
        sagoLat: '37.1312',
        sinkWidth: '1.5',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '상수도관(D200) 누수로 인한 지반침하 발생·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '2310000',
        trFnDate: '2022-05-24'
      },
      geometry: { type: 'Point', coordinates: [128.225, 37.1312] }
    },
    {
      type: 'Feature',
      id: 989,
      properties: {
        sagoDate: '2022-06-06',
        sagoNo: '20220019',
        sido: '경기도',
        sigungu: '고양시 일산동구',
        dong: '백석동',
        addr: '1297',
        fullAdr: '경기도 고양시 일산동구 백석동 1297',
        SEC_CD: '41285106',
        sagoLon: '126.788',
        sagoLat: '37.6441',
        sinkWidth: '5',
        sinkExtend: '1.5',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '1차 사고로 인한 지반 약화 추정 *1차사고(2022.5.31.)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2022-06-06'
      },
      geometry: { type: 'Point', coordinates: [126.788, 37.6441] }
    },
    {
      type: 'Feature',
      id: 990,
      properties: {
        sagoDate: '2022-06-09',
        sagoNo: '20220020',
        sido: '경기도',
        sigungu: '고양시 일산동구',
        dong: '백석동',
        addr: '1297',
        fullAdr: '경기도 고양시 일산동구 백석동 1297',
        SEC_CD: '41285106',
        sagoLon: '126.788',
        sagoLat: '37.644',
        sinkWidth: '3',
        sinkExtend: '2',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '신축공사현장 3차 침하·1차(2022.5.31.)· 2차(2022.6.6.)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2022-06-09'
      },
      geometry: { type: 'Point', coordinates: [126.788, 37.644] }
    },
    {
      type: 'Feature',
      id: 991,
      properties: {
        sagoDate: '2022-06-15',
        sagoNo: '20220021',
        sido: '대구광역시',
        sigungu: '북구',
        dong: '노원동2가',
        addr: '257-1',
        fullAdr: '대구광역시 북구 노원동2가 257-1',
        SEC_CD: '27230108',
        sagoLon: '128.576',
        sagoLat: '35.8878',
        sinkWidth: '2',
        sinkExtend: '3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '- 위 치 : 북구 팔달로 233 앞(원대오거리)·- 누수일시 : 2022. 6. 15.(수) 04:25경·- 누수관로 : 관경 500㎜ DT관 1971년 매설(관령 만50년)·- 누수원인 : 노후로 인한 누수',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '14000000',
        trFnDate: '2022-06-15'
      },
      geometry: { type: 'Point', coordinates: [128.576, 35.8878] }
    },
    {
      type: 'Feature',
      id: 992,
      properties: {
        sagoDate: '2022-06-23',
        sagoNo: '20220022',
        sido: '서울특별시',
        sigungu: '동대문구',
        dong: '장안동',
        addr: '366-6',
        fullAdr: '서울특별시 동대문구 장안동 366-6',
        SEC_CD: '11230106',
        sagoLon: '127.071',
        sagoLat: '37.57',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail:
          '민간아파트 굴착현장 공사 중 토사유실 등으로 사고 발생 추정되나· 관계부서 합동조사를 통해 정확한 원인 조사 필요',
        deathCnt: '0',
        injuryCnt: '3',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-06-24'
      },
      geometry: { type: 'Point', coordinates: [127.071, 37.57] }
    },
    {
      type: 'Feature',
      id: 993,
      properties: {
        sagoDate: '2022-06-24',
        sagoNo: '20220023',
        sido: '대구광역시',
        sigungu: '남구',
        dong: '',
        addr: '대명로 253 앞 대명로 2~3차로',
        fullAdr: '대구광역시 남구  대명로 253 앞 대명로 2~3차로',
        SEC_CD: '27200103',
        sagoLon: '128.585',
        sagoLat: '35.8426',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '노반 장기침하(추정)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-06-24'
      },
      geometry: { type: 'Point', coordinates: [128.585, 35.8426] }
    },
    {
      type: 'Feature',
      id: 994,
      properties: {
        sagoDate: '2022-06-23',
        sagoNo: '20220024',
        sido: '서울특별시',
        sigungu: '성북구',
        dong: '장위동',
        addr: '231-226',
        fullAdr: '서울특별시 성북구 장위동 231-226',
        SEC_CD: '11290138',
        sagoLon: '127.038',
        sagoLat: '37.6128',
        sinkWidth: '3',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail:
          '노후 하수관로 이음부 이격으로 토사유출로 인한 도로함몰 발생',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-06-24'
      },
      geometry: { type: 'Point', coordinates: [127.038, 37.6128] }
    },
    {
      type: 'Feature',
      id: 995,
      properties: {
        sagoDate: '2022-06-24',
        sagoNo: '20220025',
        sido: '서울특별시',
        sigungu: '동대문구',
        dong: '장안동',
        addr: '371-10',
        fullAdr: '서울특별시 동대문구 장안동 371-10',
        SEC_CD: '11230106',
        sagoLon: '127.071',
        sagoLat: '37.5715',
        sinkWidth: '1.2',
        sinkExtend: '1.2',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '원인조사 필요',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-06-24'
      },
      geometry: { type: 'Point', coordinates: [127.071, 37.5715] }
    },
    {
      type: 'Feature',
      id: 996,
      properties: {
        sagoDate: '2022-06-29',
        sagoNo: '20220026',
        sido: '서울특별시',
        sigungu: '관악구',
        dong: '봉천동',
        addr: '180-329',
        fullAdr: '서울특별시 관악구 봉천동 180-329',
        SEC_CD: '11620101',
        sagoLon: '126.967',
        sagoLat: '37.4701',
        sinkWidth: '0.8',
        sinkExtend: '1',
        sinkDepth: '3.5',
        grdKind: '',
        sagoDetail: '지반하부 지하수로 인한 토사 유출로 도로 침하 발생 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2022-06-29'
      },
      geometry: { type: 'Point', coordinates: [126.967, 37.4701] }
    },
    {
      type: 'Feature',
      id: 997,
      properties: {
        sagoDate: '2022-06-29',
        sagoNo: '20220028',
        sido: '서울특별시',
        sigungu: '종로구',
        dong: '',
        addr: '혜화로 74 경신고등학교',
        fullAdr: '서울특별시 종로구  혜화로 74 경신고등학교',
        SEC_CD: '11110169',
        sagoLon: '127.001',
        sagoLat: '37.5912',
        sinkWidth: '6',
        sinkExtend: '5',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-07-18'
      },
      geometry: { type: 'Point', coordinates: [127.001, 37.5912] }
    },
    {
      type: 'Feature',
      id: 998,
      properties: {
        sagoDate: '2022-06-05',
        sagoNo: '20220034',
        sido: '경기도',
        sigungu: '안산시 단원구',
        dong: '성곡동',
        addr: '839-12',
        fullAdr: '경기도 안산시 단원구 성곡동 839-12',
        SEC_CD: '41273104',
        sagoLon: '126.739',
        sagoLat: '37.3033',
        sinkWidth: '7',
        sinkExtend: '15.9',
        sinkDepth: '0.31',
        grdKind: '기타',
        sagoDetail:
          '흙막이벽 SCW 하단(GL-34.6·M) 지층(풍화토)으로 도로의 지하수 유입으로 인한 침하발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2022-07-04'
      },
      geometry: { type: 'Point', coordinates: [126.739, 37.3033] }
    },
    {
      type: 'Feature',
      id: 999,
      properties: {
        sagoDate: '2022-06-12',
        sagoNo: '20220037',
        sido: '경상남도',
        sigungu: '창원시 진해구',
        dong: '성내동',
        addr: '78-3',
        fullAdr: '경상남도 창원시 진해구 성내동 78-3',
        SEC_CD: '48129148',
        sagoLon: '128.76',
        sagoLat: '35.1152',
        sinkWidth: '0.8',
        sinkExtend: '0.8',
        sinkDepth: '1.2',
        grdKind: '기타',
        sagoDetail: '흄관 이음부 탈락으로 인한 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-06-24'
      },
      geometry: { type: 'Point', coordinates: [128.76, 35.1152] }
    },
    {
      type: 'Feature',
      id: 1000,
      properties: {
        sagoDate: '2022-06-22',
        sagoNo: '20220044',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '우산동',
        addr: '133-8 우산철교 사거리 옆',
        fullAdr: '강원특별자치도 원주시 우산동 133-8 우산철교 사거리 옆',
        SEC_CD: '51130110',
        sagoLon: '127.94',
        sagoLat: '37.3605',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '건수로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-07-03'
      },
      geometry: { type: 'Point', coordinates: [127.94, 37.3605] }
    },
    {
      type: 'Feature',
      id: 1001,
      properties: {
        sagoDate: '2022-06-28',
        sagoNo: '20220046',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '둔산동',
        addr: '1361',
        fullAdr: '대전광역시 서구 둔산동 1361',
        SEC_CD: '30170112',
        sagoLon: '127.379',
        sagoLat: '36.3523',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '굴착복구 다짐 미흡으로 인한 장기침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '4000000',
        trFnDate: '2022-06-28'
      },
      geometry: { type: 'Point', coordinates: [127.379, 36.3523] }
    },
    {
      type: 'Feature',
      id: 1002,
      properties: {
        sagoDate: '2022-06-03',
        sagoNo: '20220054',
        sido: '울산광역시',
        sigungu: '중구',
        dong: '',
        addr: '태화동 347',
        fullAdr: '울산광역시 중구  태화동 347',
        SEC_CD: '31110112',
        sagoLon: '129.275',
        sagoLat: '35.555',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '다짐불량으로 인한 모래유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-06-03'
      },
      geometry: { type: 'Point', coordinates: [129.275, 35.555] }
    },
    {
      type: 'Feature',
      id: 1003,
      properties: {
        sagoDate: '2022-06-06',
        sagoNo: '20220061',
        sido: '경기도',
        sigungu: '화성시',
        dong: '남양읍',
        addr: '문호리 801',
        fullAdr: '경기도 화성시 남양읍 문호리 801',
        SEC_CD: '41590262',
        sagoLon: '126.805',
        sagoLat: '37.2492',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '하자보수 기간 후 자연침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-06-06'
      },
      geometry: { type: 'Point', coordinates: [126.805, 37.2492] }
    },
    {
      type: 'Feature',
      id: 1004,
      properties: {
        sagoDate: '2022-06-30',
        sagoNo: '20220063',
        sido: '전북특별자치도',
        sigungu: '군산시',
        dong: '비응도동',
        addr: '41-4',
        fullAdr: '전북특별자치도 군산시 비응도동 41-4',
        SEC_CD: '52130148',
        sagoLon: '126.531',
        sagoLat: '35.942',
        sinkWidth: '0.8',
        sinkExtend: '0.8',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: 'PE관(D600) 파손으로 인한 지반 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2022-07-01'
      },
      geometry: { type: 'Point', coordinates: [126.531, 35.942] }
    },
    {
      type: 'Feature',
      id: 1005,
      properties: {
        sagoDate: '2022-06-24',
        sagoNo: '20220067',
        sido: '강원특별자치도',
        sigungu: '춘천시',
        dong: '근화동',
        addr: '231-36',
        fullAdr: '강원특별자치도 춘천시 근화동 231-36',
        SEC_CD: '51110117',
        sagoLon: '127.719',
        sagoLat: '37.879',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '노후 우수관 누수',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-06-24'
      },
      geometry: { type: 'Point', coordinates: [127.719, 37.879] }
    },
    {
      type: 'Feature',
      id: 1006,
      properties: {
        sagoDate: '2022-06-10',
        sagoNo: '20220080',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '서신동',
        addr: '23-38',
        fullAdr: '전북특별자치도 전주시 완산구 서신동 23-38',
        SEC_CD: '52111129',
        sagoLon: '127.123',
        sagoLat: '35.8284',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '0.5',
        grdKind: '기타',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-06-11'
      },
      geometry: { type: 'Point', coordinates: [127.123, 35.8284] }
    },
    {
      type: 'Feature',
      id: 1007,
      properties: {
        sagoDate: '2022-06-24',
        sagoNo: '20220081',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '평화동1가',
        addr: '725-5',
        fullAdr: '전북특별자치도 전주시 완산구 평화동1가 725-5',
        SEC_CD: '52111132',
        sagoLon: '127.136',
        sagoLat: '35.7949',
        sinkWidth: '2',
        sinkExtend: '2.5',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-06-24'
      },
      geometry: { type: 'Point', coordinates: [127.136, 35.7949] }
    },
    {
      type: 'Feature',
      id: 1008,
      properties: {
        sagoDate: '2022-06-21',
        sagoNo: '20220083',
        sido: '전북특별자치도',
        sigungu: '전주시 덕진구',
        dong: '',
        addr: '건산로 48번지 앞 도로',
        fullAdr: '전북특별자치도 전주시 덕진구  건산로 48번지 앞 도로',
        SEC_CD: '52113102',
        sagoLon: '127.143',
        sagoLat: '35.8327',
        sinkWidth: '0.8',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '우수관로 옆 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-06-21'
      },
      geometry: { type: 'Point', coordinates: [127.143, 35.8327] }
    },
    {
      type: 'Feature',
      id: 1009,
      properties: {
        sagoDate: '2022-06-25',
        sagoNo: '20220086',
        sido: '부산광역시',
        sigungu: '금정구',
        dong: '금사동',
        addr: '금사동 110-6번지 앞',
        fullAdr: '부산광역시 금정구 금사동 금사동 110-6번지 앞',
        SEC_CD: '26410111',
        sagoLon: '129.116',
        sagoLat: '35.216',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '금사역 일원 다짐불량 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-06-30'
      },
      geometry: { type: 'Point', coordinates: [129.116, 35.216] }
    },
    {
      type: 'Feature',
      id: 1010,
      properties: {
        sagoDate: '2022-06-03',
        sagoNo: '20220091',
        sido: '충청북도',
        sigungu: '음성군',
        dong: '음성읍',
        addr: '음성읍 읍내리 478-12(음성군 선거관리위원회 옆)',
        fullAdr:
          '충청북도 음성군 음성읍 음성읍 읍내리 478-12(음성군 선거관리위원회 옆)',
        SEC_CD: '43770250',
        sagoLon: '127.688',
        sagoLat: '36.9333',
        sinkWidth: '1',
        sinkExtend: '4',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail:
          '작년 하반기경 관로공사를 시행했던 이력이 있어· 2022. 6. 16. ·음성군 수도사업소 하수도팀에서 관로 CCTV 점검을 시행했지만· ·관로 이상 없음으로 판단하여 지반침하 취약지역 탐사대상으로 제출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2022-06-22'
      },
      geometry: { type: 'Point', coordinates: [127.688, 36.9333] }
    },
    {
      type: 'Feature',
      id: 1011,
      properties: {
        sagoDate: '2022-06-25',
        sagoNo: '20220109',
        sido: '충청북도',
        sigungu: '제천시',
        dong: '영천동',
        addr: '영천동 302일원 제천남부교회 앞',
        fullAdr: '충청북도 제천시 영천동 영천동 302일원 제천남부교회 앞',
        SEC_CD: '43150110',
        sagoLon: '128.204',
        sagoLat: '37.131',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '0.5',
        grdKind: '기타',
        sagoDetail: '상하수관 파손으로 인한 토사 유출로 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '2090000',
        trFnDate: '2022-06-25'
      },
      geometry: { type: 'Point', coordinates: [128.204, 37.131] }
    },
    {
      type: 'Feature',
      id: 1012,
      properties: {
        sagoDate: '2022-06-23',
        sagoNo: '20220111',
        sido: '충청북도',
        sigungu: '제천시',
        dong: '청전동',
        addr: '청전동 643일원(비둘기아파트 정문 부근)',
        fullAdr:
          '충청북도 제천시 청전동 청전동 643일원(비둘기아파트 정문 부근)',
        SEC_CD: '43150113',
        sagoLon: '128.217',
        sagoLat: '37.1518',
        sinkWidth: '1.5',
        sinkExtend: '4',
        sinkDepth: '2.5',
        grdKind: '기타',
        sagoDetail: '상하수관 파손으로 인한 토사 유출로 지반침하 발생·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '3420000',
        trFnDate: '2022-06-23'
      },
      geometry: { type: 'Point', coordinates: [128.217, 37.1518] }
    },
    {
      type: 'Feature',
      id: 1013,
      properties: {
        sagoDate: '2022-07-01',
        sagoNo: '20220027',
        sido: '서울특별시',
        sigungu: '송파구',
        dong: '가락동',
        addr: '282',
        fullAdr: '서울특별시 송파구 가락동 282',
        SEC_CD: '11710107',
        sagoLon: '127.111',
        sagoLat: '37.4962',
        sinkWidth: '2',
        sinkExtend: '4',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail:
          '집중호우에 따른 하수박스 파손 부위의 토사 유실에 의한 자전거도로 함몰',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-07-01'
      },
      geometry: { type: 'Point', coordinates: [127.111, 37.4962] }
    },
    {
      type: 'Feature',
      id: 1014,
      properties: {
        sagoDate: '2022-07-01',
        sagoNo: '20220029',
        sido: '경기도',
        sigungu: '성남시 중원구',
        dong: '중앙동',
        addr: '중앙동1 단대오거리역 중앙 ',
        fullAdr: '경기도 성남시 중원구 중앙동 중앙동1 단대오거리역 중앙',
        SEC_CD: '41133132',
        sagoLon: '127.157',
        sagoLat: '37.4448',
        sinkWidth: '3',
        sinkExtend: '3',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '호우',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-07-02'
      },
      geometry: { type: 'Point', coordinates: [127.157, 37.4448] }
    },
    {
      type: 'Feature',
      id: 1015,
      properties: {
        sagoDate: '2022-07-04',
        sagoNo: '20220030',
        sido: '경기도',
        sigungu: '양주시',
        dong: '유양동',
        addr: '719-1',
        fullAdr: '경기도 양주시 유양동 719-1',
        SEC_CD: '41630101',
        sagoLon: '127.017',
        sagoLat: '37.7944',
        sinkWidth: '3',
        sinkExtend: '3',
        sinkDepth: '4',
        grdKind: '',
        sagoDetail: '호우로 인한 지반침하로 수로관 파열',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-07-08'
      },
      geometry: { type: 'Point', coordinates: [127.017, 37.7944] }
    },
    {
      type: 'Feature',
      id: 1016,
      properties: {
        sagoDate: '2022-07-06',
        sagoNo: '20220031',
        sido: '서울특별시',
        sigungu: '영등포구',
        dong: '여의도동',
        addr: '23(IFC몰)',
        fullAdr: '서울특별시 영등포구 여의도동 23(IFC몰)',
        SEC_CD: '11560110',
        sagoLon: '126.926',
        sagoLat: '37.5264',
        sinkWidth: '0.4',
        sinkExtend: '0.4',
        sinkDepth: '2.5',
        grdKind: '',
        sagoDetail: '지반침하 주변 지하매설물 이상으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-07-06'
      },
      geometry: { type: 'Point', coordinates: [126.926, 37.5264] }
    },
    {
      type: 'Feature',
      id: 1017,
      properties: {
        sagoDate: '2022-07-02',
        sagoNo: '20220032',
        sido: '경기도',
        sigungu: '부천시',
        dong: '소사본동',
        addr: '257',
        fullAdr: '경기도 부천시 소사본동 257',
        SEC_CD: '41194101',
        sagoLon: '126.793',
        sagoLat: '37.4822',
        sinkWidth: '1',
        sinkExtend: '2.5',
        sinkDepth: '2.5',
        grdKind: '',
        sagoDetail: '맨홀 접합부 하수관 파손에 의한 침하',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-07-04'
      },
      geometry: { type: 'Point', coordinates: [126.793, 37.4822] }
    },
    {
      type: 'Feature',
      id: 1018,
      properties: {
        sagoDate: '2022-07-10',
        sagoNo: '20220036',
        sido: '서울특별시',
        sigungu: '강동구',
        dong: '천호동',
        addr: '467-8',
        fullAdr: '서울특별시 강동구 천호동 467-8',
        SEC_CD: '11740109',
        sagoLon: '127.12',
        sagoLat: '37.5421',
        sinkWidth: '1.2',
        sinkExtend: '1.3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '공공하수관[D=600mm· 흉관· 매설일자(2021년도)]에 접합된 개인 배수시설(D=300mm)의 연결관이 접합불량으로 도로함몰이 발생된 것으로 확인.',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-07-10'
      },
      geometry: { type: 'Point', coordinates: [127.12, 37.5421] }
    },
    {
      type: 'Feature',
      id: 1019,
      properties: {
        sagoDate: '2022-07-16',
        sagoNo: '20220047',
        sido: '충청남도',
        sigungu: '홍성군',
        dong: '홍성읍',
        addr: '도청대로 7 앞 도로',
        fullAdr: '충청남도 홍성군 홍성읍 도청대로 7 앞 도로',
        SEC_CD: '44800250',
        sagoLon: '126.662',
        sagoLat: '36.6057',
        sinkWidth: '0.8',
        sinkExtend: '0.8',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '우수관(파형강관)의 손상으로 아래 흙의 세굴',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2022-07-16'
      },
      geometry: { type: 'Point', coordinates: [126.662, 36.6057] }
    },
    {
      type: 'Feature',
      id: 1020,
      properties: {
        sagoDate: '2022-07-19',
        sagoNo: '20220056',
        sido: '제주특별자치도',
        sigungu: '제주시',
        dong: '이도이동',
        addr: '443',
        fullAdr: '제주특별자치도 제주시 이도이동 443',
        SEC_CD: '50110102',
        sagoLon: '126.546',
        sagoLat: '33.4981',
        sinkWidth: '1.5',
        sinkExtend: '2.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '노후 하수관[D300mm· 매설일자(1993년 10월)] 파손으로 인도부 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-07-17'
      },
      geometry: { type: 'Point', coordinates: [126.546, 33.4981] }
    },
    {
      type: 'Feature',
      id: 1021,
      properties: {
        sagoDate: '2022-07-01',
        sagoNo: '20220057',
        sido: '경기도',
        sigungu: '안양시 만안구',
        dong: '석수동',
        addr: '201-7',
        fullAdr: '경기도 안양시 만안구 석수동 201-7',
        SEC_CD: '41171102',
        sagoLon: '126.915',
        sagoLat: '37.4163',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.2',
        grdKind: '기타',
        sagoDetail: '지하보도 상부의 도로부 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '1000000',
        trFnDate: '2022-07-01'
      },
      geometry: { type: 'Point', coordinates: [126.915, 37.4163] }
    },
    {
      type: 'Feature',
      id: 1022,
      properties: {
        sagoDate: '2022-07-19',
        sagoNo: '20220062',
        sido: '경상남도',
        sigungu: '사천시',
        dong: '동금동',
        addr: '52-9',
        fullAdr: '경상남도 사천시 동금동 52-9',
        SEC_CD: '48240104',
        sagoLon: '128.083',
        sagoLat: '34.932',
        sinkWidth: '0.5',
        sinkExtend: '0',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail:
          '아파트 화단에서 원인불명의 지반침하로 싱크홀 비슷한 구멍이 생김.',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-31'
      },
      geometry: { type: 'Point', coordinates: [128.083, 34.932] }
    },
    {
      type: 'Feature',
      id: 1023,
      properties: {
        sagoDate: '2022-07-20',
        sagoNo: '20220146',
        sido: '인천광역시',
        sigungu: '부평구',
        dong: '부평동',
        addr: '605-12',
        fullAdr: '인천광역시 부평구 부평동 605-12',
        SEC_CD: '28237101',
        sagoLon: '126.726',
        sagoLat: '37.4877',
        sinkWidth: '0.4',
        sinkExtend: '0.4',
        sinkDepth: '0.5',
        grdKind: '기타',
        sagoDetail:
          '발생원인을 파악하기 위해 확인터파기를 실시하였으나··특별한 원인을 발견하지 못하였으며· 공공하수관과 개인하수관 접합부에서 수년간 토사유실로인해 도로침하가 발생했다고 추정.',
        deathCnt: '1',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-07-26'
      },
      geometry: { type: 'Point', coordinates: [126.726, 37.4877] }
    },
    {
      type: 'Feature',
      id: 1024,
      properties: {
        sagoDate: '2022-07-11',
        sagoNo: '20220147',
        sido: '부산광역시',
        sigungu: '동래구',
        dong: '안락동',
        addr: '안락동 732-1 인근 (동래벽산아파트 앞 횡단보도)',
        fullAdr:
          '부산광역시 동래구 안락동 안락동 732-1 인근 (동래벽산아파트 앞 횡단보도)',
        SEC_CD: '26260104',
        sagoLon: '129.098',
        sagoLat: '35.195',
        sinkWidth: '0.1',
        sinkExtend: '0.1',
        sinkDepth: '1.33',
        grdKind: '',
        sagoDetail: '자연침하로 인한 공동 발생·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-07-26'
      },
      geometry: { type: 'Point', coordinates: [129.098, 35.195] }
    },
    {
      type: 'Feature',
      id: 1025,
      properties: {
        sagoDate: '2022-07-04',
        sagoNo: '20220162',
        sido: '부산광역시',
        sigungu: '사하구',
        dong: '감천동',
        addr: '486-3번지 맞은편 3차선 도로',
        fullAdr: '부산광역시 사하구 감천동 486-3번지 맞은편 3차선 도로',
        SEC_CD: '26380108',
        sagoLon: '129.003',
        sagoLat: '35.0881',
        sinkWidth: '0.5',
        sinkExtend: '0.6',
        sinkDepth: '1.9',
        grdKind: '',
        sagoDetail: '폐 상수관로 쪽으로 토사유실로 함몰발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-07-05'
      },
      geometry: { type: 'Point', coordinates: [129.003, 35.0881] }
    },
    {
      type: 'Feature',
      id: 1026,
      properties: {
        sagoDate: '2022-07-01',
        sagoNo: '20220165',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '삼천동1가',
        addr: '705-2',
        fullAdr: '전북특별자치도 전주시 완산구 삼천동1가 705-2',
        SEC_CD: '52111137',
        sagoLon: '127.127',
        sagoLat: '35.7975',
        sinkWidth: '2.2',
        sinkExtend: '2.2',
        sinkDepth: '0.9',
        grdKind: '',
        sagoDetail: '콘크리트 박스 손상에 따른 토사 유실·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-07-01'
      },
      geometry: { type: 'Point', coordinates: [127.127, 35.7975] }
    },
    {
      type: 'Feature',
      id: 1027,
      properties: {
        sagoDate: '2022-07-12',
        sagoNo: '20220166',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '효자동3가',
        addr: '1544-3',
        fullAdr: '전북특별자치도 전주시 완산구 효자동3가 1544-3',
        SEC_CD: '52111142',
        sagoLon: '127.11',
        sagoLat: '35.8138',
        sinkWidth: '1',
        sinkExtend: '2.5',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '다짐 불량·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-07-12'
      },
      geometry: { type: 'Point', coordinates: [127.11, 35.8138] }
    },
    {
      type: 'Feature',
      id: 1028,
      properties: {
        sagoDate: '2022-07-02',
        sagoNo: '20220167',
        sido: '충청북도',
        sigungu: '제천시',
        dong: '화산동',
        addr: '693',
        fullAdr: '충청북도 제천시 화산동 693',
        SEC_CD: '43150109',
        sagoLon: '128.217',
        sagoLat: '37.1278',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '2.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1710000',
        trFnDate: '2022-07-04'
      },
      geometry: { type: 'Point', coordinates: [128.217, 37.1278] }
    },
    {
      type: 'Feature',
      id: 1029,
      properties: {
        sagoDate: '2022-07-13',
        sagoNo: '20220179',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '삼천동1가',
        addr: '386',
        fullAdr: '전북특별자치도 전주시 완산구 삼천동1가 386',
        SEC_CD: '52111137',
        sagoLon: '127.114',
        sagoLat: '35.7954',
        sinkWidth: '1',
        sinkExtend: '5',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 손상에 따른 토사 유실·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-07-13'
      },
      geometry: { type: 'Point', coordinates: [127.114, 35.7954] }
    },
    {
      type: 'Feature',
      id: 1030,
      properties: {
        sagoDate: '2022-07-25',
        sagoNo: '20220180',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '중노송동',
        addr: '470-13',
        fullAdr: '전북특별자치도 전주시 완산구 중노송동 470-13',
        SEC_CD: '52111120',
        sagoLon: '127.153',
        sagoLat: '35.8244',
        sinkWidth: '2.3',
        sinkExtend: '2.5',
        sinkDepth: '0.6',
        grdKind: '',
        sagoDetail: '콘크리트 박스 손상에 따른 토사 유실·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-07-25'
      },
      geometry: { type: 'Point', coordinates: [127.153, 35.8244] }
    },
    {
      type: 'Feature',
      id: 1031,
      properties: {
        sagoDate: '2022-07-26',
        sagoNo: '20220181',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '원당동',
        addr: '651-10',
        fullAdr: '전북특별자치도 전주시 완산구 원당동 651-10',
        SEC_CD: '52111131',
        sagoLon: '127.115',
        sagoLat: '35.7576',
        sinkWidth: '1.8',
        sinkExtend: '2',
        sinkDepth: '2.1',
        grdKind: '',
        sagoDetail: '콘크리트 박스 손상에 따른 토사 유실·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-07-26'
      },
      geometry: { type: 'Point', coordinates: [127.115, 35.7576] }
    },
    {
      type: 'Feature',
      id: 1032,
      properties: {
        sagoDate: '2022-07-29',
        sagoNo: '20220182',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '고사동',
        addr: '355-1',
        fullAdr: '전북특별자치도 전주시 완산구 고사동 355-1',
        SEC_CD: '52111117',
        sagoLon: '127.142',
        sagoLat: '35.8205',
        sinkWidth: '3.4',
        sinkExtend: '3.8',
        sinkDepth: '0.7',
        grdKind: '',
        sagoDetail: '하수관 손상에 따른 토사 유실·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-07-29'
      },
      geometry: { type: 'Point', coordinates: [127.142, 35.8205] }
    },
    {
      type: 'Feature',
      id: 1033,
      properties: {
        sagoDate: '2022-08-03',
        sagoNo: '20220064',
        sido: '강원특별자치도',
        sigungu: '양양군',
        dong: '강현면',
        addr: '주청리 2-15',
        fullAdr: '강원특별자치도 양양군 강현면 주청리 2-15',
        SEC_CD: '51830350',
        sagoLon: '128.632',
        sagoLat: '38.1176',
        sinkWidth: '2.5',
        sinkExtend: '8',
        sinkDepth: '18',
        grdKind: '화강암류',
        sagoDetail: '주청리 2-15 생활형숙박시설 신축공사장 인근',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-05'
      },
      geometry: { type: 'Point', coordinates: [128.632, 38.1176] }
    },
    {
      type: 'Feature',
      id: 1034,
      properties: {
        sagoDate: '2022-08-06',
        sagoNo: '20220068',
        sido: '경기도',
        sigungu: '양주시',
        dong: '덕계동',
        addr: '887',
        fullAdr: '경기도 양주시 덕계동 887',
        SEC_CD: '41630116',
        sagoLon: '127.054',
        sagoLat: '37.8218',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '화강암류',
        sagoDetail: '하수관 누수로 인해 인도(보도블럭 3칸) 일부 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2022-08-16'
      },
      geometry: { type: 'Point', coordinates: [127.054, 37.8218] }
    },
    {
      type: 'Feature',
      id: 1035,
      properties: {
        sagoDate: '2022-08-03',
        sagoNo: '20220077',
        sido: '경기도',
        sigungu: '고양시 덕양구',
        dong: '화정동',
        addr: '976',
        fullAdr: '경기도 고양시 덕양구 화정동 976',
        SEC_CD: '41281123',
        sagoLon: '126.833',
        sagoLat: '37.6324',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-03'
      },
      geometry: { type: 'Point', coordinates: [126.833, 37.6324] }
    },
    {
      type: 'Feature',
      id: 1036,
      properties: {
        sagoDate: '2022-08-08',
        sagoNo: '20220084',
        sido: '강원특별자치도',
        sigungu: '춘천시',
        dong: '우두동',
        addr: '291-2',
        fullAdr: '강원특별자치도 춘천시 우두동 291-2',
        SEC_CD: '51110118',
        sagoLon: '127.74',
        sagoLat: '37.9041',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '지하안전관리협회 탐사요청',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2022-08-05'
      },
      geometry: { type: 'Point', coordinates: [127.74, 37.9041] }
    },
    {
      type: 'Feature',
      id: 1037,
      properties: {
        sagoDate: '2022-08-09',
        sagoNo: '20220085',
        sido: '서울특별시',
        sigungu: '영등포구',
        dong: '대림동',
        addr: '1116-37',
        fullAdr: '서울특별시 영등포구 대림동 1116-37',
        SEC_CD: '11560133',
        sagoLon: '126.897',
        sagoLat: '37.4885',
        sinkWidth: '1.5',
        sinkExtend: '2.5',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '원인미상으로 파악 중',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-10'
      },
      geometry: { type: 'Point', coordinates: [126.897, 37.4885] }
    },
    {
      type: 'Feature',
      id: 1038,
      properties: {
        sagoDate: '2022-08-09',
        sagoNo: '20220087',
        sido: '서울특별시',
        sigungu: '동작구',
        dong: '상도동',
        addr: '장승배기역 2번출구 E/V 주변',
        fullAdr: '서울특별시 동작구 상도동 장승배기역 2번출구 E/V 주변',
        SEC_CD: '11590102',
        sagoLon: '126.939',
        sagoLat: '37.5045',
        sinkWidth: '6',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '승강 편의시설(E/L) 공사 되메우기 다짐불량 추정(집중호우 발생)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '3000000',
        trFnDate: '2022-08-09'
      },
      geometry: { type: 'Point', coordinates: [126.939, 37.5045] }
    },
    {
      type: 'Feature',
      id: 1039,
      properties: {
        sagoDate: '2022-08-09',
        sagoNo: '20220088',
        sido: '서울특별시',
        sigungu: '강남구',
        dong: '개포동',
        addr: '146-1[대모지하차도 개포1단지 양재대로(수서방향)]',
        fullAdr:
          '서울특별시 강남구 개포동 146-1[대모지하차도 개포1단지 양재대로(수서방향)]',
        SEC_CD: '11680103',
        sagoLon: '127.064',
        sagoLat: '37.4785',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '2.5',
        grdKind: '',
        sagoDetail:
          '집중호우로 공사장으로 하수관 월류 및 가시설 누수(2개소 침하)·※ 강남구 강수량 24:00 ~ 01:00 기준 시간누적 26.5mm',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '2',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-10'
      },
      geometry: { type: 'Point', coordinates: [127.064, 37.4785] }
    },
    {
      type: 'Feature',
      id: 1040,
      properties: {
        sagoDate: '2022-08-09',
        sagoNo: '20220093',
        sido: '경기도',
        sigungu: '부천시',
        dong: '심곡본동',
        addr: '617-140',
        fullAdr: '경기도 부천시 심곡본동 617-140',
        SEC_CD: '41194102',
        sagoLon: '126.779',
        sagoLat: '37.4764',
        sinkWidth: '3',
        sinkExtend: '4',
        sinkDepth: '1.8',
        grdKind: '',
        sagoDetail: '노후 하수관 유실 및 맨홀 구체 파손으로 인한 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-11'
      },
      geometry: { type: 'Point', coordinates: [126.779, 37.4764] }
    },
    {
      type: 'Feature',
      id: 1041,
      properties: {
        sagoDate: '2022-08-09',
        sagoNo: '20220097',
        sido: '강원특별자치도',
        sigungu: '양양군',
        dong: '강현면',
        addr: '주청리 2-22',
        fullAdr: '강원특별자치도 양양군 강현면 주청리 2-22',
        SEC_CD: '51830350',
        sagoLon: '128.632',
        sagoLat: '38.1163',
        sinkWidth: '1.5',
        sinkExtend: '2.2',
        sinkDepth: '1',
        grdKind: '화강암류',
        sagoDetail: '공사장내 토사및 지하수 유입으로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-09'
      },
      geometry: { type: 'Point', coordinates: [128.632, 38.1163] }
    },
    {
      type: 'Feature',
      id: 1042,
      properties: {
        sagoDate: '2022-08-11',
        sagoNo: '20220098',
        sido: '충청남도',
        sigungu: '당진시',
        dong: '신평면',
        addr: '신당리 264',
        fullAdr: '충청남도 당진시 신평면 신당리 264',
        SEC_CD: '44270380',
        sagoLon: '126.804',
        sagoLat: '36.8791',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '3',
        grdKind: '기타',
        sagoDetail:
          '신평면 신당리 264버지 비닐하우스 지하 // 전력구공사(북당진-신탕정)건설현장 인근 에서 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-31'
      },
      geometry: { type: 'Point', coordinates: [126.804, 36.8791] }
    },
    {
      type: 'Feature',
      id: 1043,
      properties: {
        sagoDate: '2022-08-09',
        sagoNo: '20220099',
        sido: '경상남도',
        sigungu: '합천군',
        dong: '대병면',
        addr: '회양리 1494번지',
        fullAdr: '경상남도 합천군 대병면 회양리 1494번지',
        SEC_CD: '48890450',
        sagoLon: '128.015',
        sagoLat: '35.5249',
        sinkWidth: '5',
        sinkExtend: '30',
        sinkDepth: '4',
        grdKind: '',
        sagoDetail: '마을진입로 하부 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-19'
      },
      geometry: { type: 'Point', coordinates: [128.015, 35.5249] }
    },
    {
      type: 'Feature',
      id: 1044,
      properties: {
        sagoDate: '2022-08-08',
        sagoNo: '20220100',
        sido: '경기도',
        sigungu: '과천시',
        dong: '문원동',
        addr: '115-1',
        fullAdr: '경기도 과천시 문원동 115-1',
        SEC_CD: '41290102',
        sagoLon: '127.008',
        sagoLat: '37.4249',
        sinkWidth: '4',
        sinkExtend: '9',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '하수관로 교체작업 다짐불량으로 인하여 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '3000000',
        trFnDate: '2022-08-10'
      },
      geometry: { type: 'Point', coordinates: [127.008, 37.4249] }
    },
    {
      type: 'Feature',
      id: 1045,
      properties: {
        sagoDate: '2022-08-09',
        sagoNo: '20220101',
        sido: '경기도',
        sigungu: '성남시 분당구',
        dong: '금곡동',
        addr: '376-11',
        fullAdr: '경기도 성남시 분당구 금곡동 376-11',
        SEC_CD: '41135111',
        sagoLon: '127.102',
        sagoLat: '37.3525',
        sinkWidth: '3',
        sinkExtend: '3',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '하수관 파손으로 인한 토사 유출로 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '8',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '5000000',
        trFnDate: '2022-08-12'
      },
      geometry: { type: 'Point', coordinates: [127.102, 37.3525] }
    },
    {
      type: 'Feature',
      id: 1046,
      properties: {
        sagoDate: '2022-08-11',
        sagoNo: '20220102',
        sido: '경기도',
        sigungu: '안산시 단원구',
        dong: '대부동동',
        addr: '551-1',
        fullAdr: '경기도 안산시 단원구 대부동동 551-1',
        SEC_CD: '41273110',
        sagoLon: '126.601',
        sagoLat: '37.2414',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '충척층',
        sagoDetail: '가스관 시공 부실로 인한 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-25'
      },
      geometry: { type: 'Point', coordinates: [126.601, 37.2414] }
    },
    {
      type: 'Feature',
      id: 1047,
      properties: {
        sagoDate: '2022-08-16',
        sagoNo: '20220104',
        sido: '경기도',
        sigungu: '고양시 일산동구',
        dong: '장항동',
        addr: '1510',
        fullAdr: '경기도 고양시 일산동구 장항동 1510',
        SEC_CD: '41285104',
        sagoLon: '126.769',
        sagoLat: '37.6557',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '다짐(되메우기) 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-16'
      },
      geometry: { type: 'Point', coordinates: [126.769, 37.6557] }
    },
    {
      type: 'Feature',
      id: 1048,
      properties: {
        sagoDate: '2022-08-13',
        sagoNo: '20220105',
        sido: '강원특별자치도',
        sigungu: '속초시',
        dong: '조양동',
        addr: '1452',
        fullAdr: '강원특별자치도 속초시 조양동 1452',
        SEC_CD: '51210108',
        sagoLon: '128.601',
        sagoLat: '38.1902',
        sinkWidth: '2',
        sinkExtend: '4',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail: '원인파악중',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-13'
      },
      geometry: { type: 'Point', coordinates: [128.601, 38.1902] }
    },
    {
      type: 'Feature',
      id: 1049,
      properties: {
        sagoDate: '2022-08-12',
        sagoNo: '20220106',
        sido: '강원특별자치도',
        sigungu: '삼척시',
        dong: '하장면',
        addr: '대전리 산43-1',
        fullAdr: '강원특별자치도 삼척시 하장면 대전리 산43-1',
        SEC_CD: '51230320',
        sagoLon: '128.894',
        sagoLat: '37.2828',
        sinkWidth: '7',
        sinkExtend: '8.5',
        sinkDepth: '7',
        grdKind: '',
        sagoDetail:
          '자연발생적인 동공발생현황으로 추정됨·(사고위치 경도 위도는 가장 가까운 번지로 입력함)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2022-08-31'
      },
      geometry: { type: 'Point', coordinates: [128.894, 37.2828] }
    },
    {
      type: 'Feature',
      id: 1050,
      properties: {
        sagoDate: '2022-08-12',
        sagoNo: '20220112',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '둔산동',
        addr: '1531',
        fullAdr: '대전광역시 서구 둔산동 1531',
        SEC_CD: '30170112',
        sagoLon: '127.379',
        sagoLat: '36.3519',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.2',
        grdKind: '기타',
        sagoDetail: '우수박스 파손에 따른 토사유실로 인한 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '4000000',
        trFnDate: '2022-08-16'
      },
      geometry: { type: 'Point', coordinates: [127.379, 36.3519] }
    },
    {
      type: 'Feature',
      id: 1051,
      properties: {
        sagoDate: '2022-08-21',
        sagoNo: '20220113',
        sido: '경기도',
        sigungu: '고양시 덕양구',
        dong: '원흥동',
        addr: '704번지',
        fullAdr: '경기도 고양시 덕양구 원흥동 704번지',
        SEC_CD: '41281104',
        sagoLon: '126.875',
        sagoLat: '37.6402',
        sinkWidth: '2',
        sinkExtend: '4',
        sinkDepth: '1.7',
        grdKind: '',
        sagoDetail: '하수관로 관 파손 및 균열에 따른 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-21'
      },
      geometry: { type: 'Point', coordinates: [126.875, 37.6402] }
    },
    {
      type: 'Feature',
      id: 1052,
      properties: {
        sagoDate: '2022-08-13',
        sagoNo: '20220114',
        sido: '서울특별시',
        sigungu: '관악구',
        dong: '봉천동',
        addr: '673-120',
        fullAdr: '서울특별시 관악구 봉천동 673-120',
        SEC_CD: '11620101',
        sagoLon: '126.933',
        sagoLat: '37.4895',
        sinkWidth: '0.7',
        sinkExtend: '0.7',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '개인 사유지 내 노후 하수관로(D450mm) 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-14'
      },
      geometry: { type: 'Point', coordinates: [126.933, 37.4895] }
    },
    {
      type: 'Feature',
      id: 1053,
      properties: {
        sagoDate: '2022-08-09',
        sagoNo: '20220117',
        sido: '경기도',
        sigungu: '오산시',
        dong: '원동',
        addr: '458-2',
        fullAdr: '경기도 오산시 원동 458-2',
        SEC_CD: '41370103',
        sagoLon: '127.069',
        sagoLat: '37.1352',
        sinkWidth: '2',
        sinkExtend: '5',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail:
          '중호우로 인한 하수관로(D1·100㎜) 접속부 유격 발생 및 토사 유실로 인한 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-17'
      },
      geometry: { type: 'Point', coordinates: [127.069, 37.1352] }
    },
    {
      type: 'Feature',
      id: 1054,
      properties: {
        sagoDate: '2022-08-11',
        sagoNo: '20220118',
        sido: '충청북도',
        sigungu: '청주시 상당구',
        dong: '용정동',
        addr: '252',
        fullAdr: '충청북도 청주시 상당구 용정동 252',
        SEC_CD: '43111125',
        sagoLon: '127.526',
        sagoLat: '36.6273',
        sinkWidth: '4',
        sinkExtend: '2',
        sinkDepth: '0.8',
        grdKind: '기타',
        sagoDetail: '집중호우로 인한 지반 침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-09-03'
      },
      geometry: { type: 'Point', coordinates: [127.526, 36.6273] }
    },
    {
      type: 'Feature',
      id: 1055,
      properties: {
        sagoDate: '2022-08-20',
        sagoNo: '20220119',
        sido: '제주특별자치도',
        sigungu: '제주시',
        dong: '화북일동',
        addr: '3907-8',
        fullAdr: '제주특별자치도 제주시 화북일동 3907-8',
        SEC_CD: '50110111',
        sagoLon: '126.562',
        sagoLat: '33.5176',
        sinkWidth: '1.5',
        sinkExtend: '2.5',
        sinkDepth: '1.8',
        grdKind: '기타',
        sagoDetail:
          '우수관(주름관 600mm)이 노후되어 파손된 틈으로 우수가 유출되어 토사가 유실된 것으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '9000000',
        trFnDate: '2022-08-23'
      },
      geometry: { type: 'Point', coordinates: [126.562, 33.5176] }
    },
    {
      type: 'Feature',
      id: 1056,
      properties: {
        sagoDate: '2022-08-08',
        sagoNo: '20220120',
        sido: '서울특별시',
        sigungu: '양천구',
        dong: '신월동',
        addr: '331-51 앞 도로',
        fullAdr: '서울특별시 양천구 신월동 331-51 앞 도로',
        SEC_CD: '11470103',
        sagoLon: '126.827',
        sagoLat: '37.5221',
        sinkWidth: '6',
        sinkExtend: '4',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '집중호우 발생에 따른 개인 배수설비 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '350000000',
        trFnDate: '2022-08-18'
      },
      geometry: { type: 'Point', coordinates: [126.827, 37.5221] }
    },
    {
      type: 'Feature',
      id: 1057,
      properties: {
        sagoDate: '2022-08-24',
        sagoNo: '20220121',
        sido: '강원특별자치도',
        sigungu: '양양군',
        dong: '강현면',
        addr: '주청리 2-15',
        fullAdr: '강원특별자치도 양양군 강현면 주청리 2-15',
        SEC_CD: '51830350',
        sagoLon: '128.632',
        sagoLat: '38.1175',
        sinkWidth: '1.5',
        sinkExtend: '3',
        sinkDepth: '1.5',
        grdKind: '화강암류',
        sagoDetail:
          '지중변위계 설치지역 인근에서 암반 천공 진동 및 물 주입으로 흙막이 약한부분 손상 예측',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-24'
      },
      geometry: { type: 'Point', coordinates: [128.632, 38.1175] }
    },
    {
      type: 'Feature',
      id: 1058,
      properties: {
        sagoDate: '2022-08-25',
        sagoNo: '20220122',
        sido: '경상북도',
        sigungu: '포항시 남구',
        dong: '대잠동',
        addr: '160-51번지 앞 주차장',
        fullAdr: '경상북도 포항시 남구 대잠동 160-51번지 앞 주차장',
        SEC_CD: '47111116',
        sagoLon: '129.339',
        sagoLat: '36.0121',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.8',
        grdKind: '기타',
        sagoDetail:
          '2022.08.25. 09:50분경 신고에 의해 발견한 현장임·현장 확인 결과 하수관로 이탈에 따른 흙유실로 기존 아스팔트 하부가 많이 유실된 상태임·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-09-30'
      },
      geometry: { type: 'Point', coordinates: [129.339, 36.0121] }
    },
    {
      type: 'Feature',
      id: 1059,
      properties: {
        sagoDate: '2022-08-31',
        sagoNo: '20220124',
        sido: '경기도',
        sigungu: '의정부시',
        dong: '호원동',
        addr: '산35-2 회룡폭포 근처 회룡사 진입도로',
        fullAdr: '경기도 의정부시 호원동 산35-2 회룡폭포 근처 회룡사 진입도로',
        SEC_CD: '41150102',
        sagoLon: '127.03',
        sagoLat: '37.7197',
        sinkWidth: '5',
        sinkExtend: '8',
        sinkDepth: '4.5',
        grdKind: '',
        sagoDetail: '집중호우로 인한 토사 유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-12-26'
      },
      geometry: { type: 'Point', coordinates: [127.03, 37.7197] }
    },
    {
      type: 'Feature',
      id: 1060,
      properties: {
        sagoDate: '2022-08-15',
        sagoNo: '20220125',
        sido: '경기도',
        sigungu: '오산시',
        dong: '오산동',
        addr: '603-1',
        fullAdr: '경기도 오산시 오산동 603-1',
        SEC_CD: '41370101',
        sagoLon: '127.067',
        sagoLat: '37.1518',
        sinkWidth: '1.5',
        sinkExtend: '1',
        sinkDepth: '2.5',
        grdKind: '기타',
        sagoDetail: '폭우로 인한 지하수위상승',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2022-09-01'
      },
      geometry: { type: 'Point', coordinates: [127.067, 37.1518] }
    },
    {
      type: 'Feature',
      id: 1061,
      properties: {
        sagoDate: '2022-08-10',
        sagoNo: '20220140',
        sido: '경기도',
        sigungu: '안양시 만안구',
        dong: '석수동',
        addr: '433-8',
        fullAdr: '경기도 안양시 만안구 석수동 433-8',
        SEC_CD: '41171102',
        sagoLon: '126.9',
        sagoLat: '37.4323',
        sinkWidth: '1',
        sinkExtend: '3',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '4000000',
        trFnDate: '2022-08-30'
      },
      geometry: { type: 'Point', coordinates: [126.9, 37.4323] }
    },
    {
      type: 'Feature',
      id: 1062,
      properties: {
        sagoDate: '2022-08-15',
        sagoNo: '20220143',
        sido: '전북특별자치도',
        sigungu: '군산시',
        dong: '옥구읍',
        addr: '정리길 15',
        fullAdr: '전북특별자치도 군산시 옥구읍 정리길 15',
        SEC_CD: '52130250',
        sagoLon: '126.689',
        sagoLat: '35.9259',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '우수맨홀 노후화로 인한 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-15'
      },
      geometry: { type: 'Point', coordinates: [126.689, 35.9259] }
    },
    {
      type: 'Feature',
      id: 1063,
      properties: {
        sagoDate: '2022-08-31',
        sagoNo: '20220148',
        sido: '경기도',
        sigungu: '부천시',
        dong: '소사본동',
        addr: '93-58',
        fullAdr: '경기도 부천시 소사본동 93-58',
        SEC_CD: '41194101',
        sagoLon: '126.792',
        sagoLat: '37.4808',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '맨홀 하부 다짐불량으로 인한 지반 함몰 발생',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-09-06'
      },
      geometry: { type: 'Point', coordinates: [126.792, 37.4808] }
    },
    {
      type: 'Feature',
      id: 1064,
      properties: {
        sagoDate: '2022-08-16',
        sagoNo: '20220155',
        sido: '경기도',
        sigungu: '이천시',
        dong: '관고동',
        addr: '관고동 28(관고동행정복지센터 앞 보도)',
        fullAdr: '경기도 이천시 관고동 관고동 28(관고동행정복지센터 앞 보도)',
        SEC_CD: '41500102',
        sagoLon: '127.438',
        sagoLat: '37.2822',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail:
          '여름철 집중호우에 따른 하수관 손상으로 우수 유출에 의한 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-28'
      },
      geometry: { type: 'Point', coordinates: [127.438, 37.2822] }
    },
    {
      type: 'Feature',
      id: 1065,
      properties: {
        sagoDate: '2022-08-26',
        sagoNo: '20220156',
        sido: '경기도',
        sigungu: '이천시',
        dong: '사음동',
        addr: '558-9',
        fullAdr: '경기도 이천시 사음동 558-9',
        SEC_CD: '41500111',
        sagoLon: '127.411',
        sagoLat: '37.2971',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail:
          '여름철 집중호우에 따른 하수관 손상으로 우수 유출에 의한 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-10-15'
      },
      geometry: { type: 'Point', coordinates: [127.411, 37.2971] }
    },
    {
      type: 'Feature',
      id: 1066,
      properties: {
        sagoDate: '2022-08-04',
        sagoNo: '20220158',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '비아동',
        addr: '비아안길69-11',
        fullAdr: '광주광역시 광산구 비아동 비아안길69-11',
        SEC_CD: '29200112',
        sagoLon: '126.82',
        sagoLat: '35.2216',
        sinkWidth: '2',
        sinkExtend: '4',
        sinkDepth: '0.8',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-10'
      },
      geometry: { type: 'Point', coordinates: [126.82, 35.2216] }
    },
    {
      type: 'Feature',
      id: 1067,
      properties: {
        sagoDate: '2022-08-04',
        sagoNo: '20220159',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '하남동',
        addr: '하남산단2번로 63',
        fullAdr: '광주광역시 광산구 하남동 하남산단2번로 63',
        SEC_CD: '29200125',
        sagoLon: '126.802',
        sagoLat: '35.1872',
        sinkWidth: '3',
        sinkExtend: '5',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-10'
      },
      geometry: { type: 'Point', coordinates: [126.802, 35.1872] }
    },
    {
      type: 'Feature',
      id: 1068,
      properties: {
        sagoDate: '2022-08-02',
        sagoNo: '20220183',
        sido: '전북특별자치도',
        sigungu: '전주시 덕진구',
        dong: '인후동1가',
        addr: '785-3',
        fullAdr: '전북특별자치도 전주시 덕진구 인후동1가 785-3',
        SEC_CD: '52113103',
        sagoLon: '127.156',
        sagoLat: '35.8356',
        sinkWidth: '2',
        sinkExtend: '1',
        sinkDepth: '1.9',
        grdKind: '',
        sagoDetail: '하수관(우수) 노후로 인한 도로 침하·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-02'
      },
      geometry: { type: 'Point', coordinates: [127.156, 35.8356] }
    },
    {
      type: 'Feature',
      id: 1069,
      properties: {
        sagoDate: '2022-08-03',
        sagoNo: '20220184',
        sido: '전북특별자치도',
        sigungu: '전주시 덕진구',
        dong: '덕진동2가',
        addr: '27-34',
        fullAdr: '전북특별자치도 전주시 덕진구 덕진동2가 27-34',
        SEC_CD: '52113106',
        sagoLon: '127.115',
        sagoLat: '35.851',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관(오수) 노후로 인한 토사 유실·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-03'
      },
      geometry: { type: 'Point', coordinates: [127.115, 35.851] }
    },
    {
      type: 'Feature',
      id: 1070,
      properties: {
        sagoDate: '2022-08-13',
        sagoNo: '20220185',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '효자동2가',
        addr: '1352',
        fullAdr: '전북특별자치도 전주시 완산구 효자동2가 1352',
        SEC_CD: '52111142',
        sagoLon: '127.083',
        sagoLat: '35.8226',
        sinkWidth: '2',
        sinkExtend: '2.2',
        sinkDepth: '0.8',
        grdKind: '',
        sagoDetail: '하수관 손상에 따른 토사 유실·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-13'
      },
      geometry: { type: 'Point', coordinates: [127.083, 35.8226] }
    },
    {
      type: 'Feature',
      id: 1071,
      properties: {
        sagoDate: '2022-08-15',
        sagoNo: '20220186',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '효자동3가',
        addr: '1354-19',
        fullAdr: '전북특별자치도 전주시 완산구 효자동3가 1354-19',
        SEC_CD: '52111142',
        sagoLon: '127.115',
        sagoLat: '35.8141',
        sinkWidth: '1.7',
        sinkExtend: '3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '다짐 불량·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-15'
      },
      geometry: { type: 'Point', coordinates: [127.115, 35.8141] }
    },
    {
      type: 'Feature',
      id: 1072,
      properties: {
        sagoDate: '2022-08-15',
        sagoNo: '20220187',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '평화동1가',
        addr: '725-5',
        fullAdr: '전북특별자치도 전주시 완산구 평화동1가 725-5',
        SEC_CD: '52111132',
        sagoLon: '127.136',
        sagoLat: '35.7947',
        sinkWidth: '2.4',
        sinkExtend: '2.8',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '다짐 불량·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-15'
      },
      geometry: { type: 'Point', coordinates: [127.136, 35.7947] }
    },
    {
      type: 'Feature',
      id: 1073,
      properties: {
        sagoDate: '2022-08-15',
        sagoNo: '20220188',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '중화산동2가',
        addr: '616-11',
        fullAdr: '전북특별자치도 전주시 완산구 중화산동2가 616-11',
        SEC_CD: '52111128',
        sagoLon: '127.118',
        sagoLat: '35.8148',
        sinkWidth: '1.7',
        sinkExtend: '2',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 파손으로 인한 토사 유출로 지반침하 발생·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-15'
      },
      geometry: { type: 'Point', coordinates: [127.118, 35.8148] }
    },
    {
      type: 'Feature',
      id: 1074,
      properties: {
        sagoDate: '2022-08-16',
        sagoNo: '20220189',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '평화동2가',
        addr: '904-5',
        fullAdr: '전북특별자치도 전주시 완산구 평화동2가 904-5',
        SEC_CD: '52111133',
        sagoLon: '127.132',
        sagoLat: '35.7825',
        sinkWidth: '1.7',
        sinkExtend: '2',
        sinkDepth: '1.3',
        grdKind: '',
        sagoDetail: '하수관 손상에 따른 토사 유실·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-16'
      },
      geometry: { type: 'Point', coordinates: [127.132, 35.7825] }
    },
    {
      type: 'Feature',
      id: 1075,
      properties: {
        sagoDate: '2022-08-18',
        sagoNo: '20220190',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '평화동2가',
        addr: '855-9',
        fullAdr: '전북특별자치도 전주시 완산구 평화동2가 855-9',
        SEC_CD: '52111133',
        sagoLon: '127.129',
        sagoLat: '35.7916',
        sinkWidth: '2.4',
        sinkExtend: '3.5',
        sinkDepth: '2.2',
        grdKind: '',
        sagoDetail: '콘크리트 박스 손상에 따른 토사 유실·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-18'
      },
      geometry: { type: 'Point', coordinates: [127.129, 35.7916] }
    },
    {
      type: 'Feature',
      id: 1076,
      properties: {
        sagoDate: '2022-08-18',
        sagoNo: '20220191',
        sido: '전북특별자치도',
        sigungu: '전주시 덕진구',
        dong: '덕진동2가',
        addr: '654-1',
        fullAdr: '전북특별자치도 전주시 덕진구 덕진동2가 654-1',
        SEC_CD: '52113106',
        sagoLon: '127.11',
        sagoLat: '35.8475',
        sinkWidth: '1',
        sinkExtend: '0.5',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '하수관(우수) 노후로 인한 토사 유실·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-18'
      },
      geometry: { type: 'Point', coordinates: [127.11, 35.8475] }
    },
    {
      type: 'Feature',
      id: 1077,
      properties: {
        sagoDate: '2022-08-19',
        sagoNo: '20220192',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '중화산동1가',
        addr: '222-3',
        fullAdr: '전북특별자치도 전주시 완산구 중화산동1가 222-3',
        SEC_CD: '52111127',
        sagoLon: '127.13',
        sagoLat: '35.8149',
        sinkWidth: '1',
        sinkExtend: '1.2',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '하수관 파손으로 인한 토사 유출로 지반침하 발생·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-19'
      },
      geometry: { type: 'Point', coordinates: [127.13, 35.8149] }
    },
    {
      type: 'Feature',
      id: 1078,
      properties: {
        sagoDate: '2022-08-26',
        sagoNo: '20220193',
        sido: '전북특별자치도',
        sigungu: '전주시 덕진구',
        dong: '진북동',
        addr: '1124-19',
        fullAdr: '전북특별자치도 전주시 덕진구 진북동 1124-19',
        SEC_CD: '52113102',
        sagoLon: '127.129',
        sagoLat: '35.8253',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.8',
        grdKind: '',
        sagoDetail: '하수관(우수) 노후로 인한 토사 유실·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-26'
      },
      geometry: { type: 'Point', coordinates: [127.129, 35.8253] }
    },
    {
      type: 'Feature',
      id: 1079,
      properties: {
        sagoDate: '2022-08-27',
        sagoNo: '20220194',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '중화산동2가',
        addr: '526-19',
        fullAdr: '전북특별자치도 전주시 완산구 중화산동2가 526-19',
        SEC_CD: '52111142',
        sagoLon: '127.114',
        sagoLat: '35.8142',
        sinkWidth: '2.1',
        sinkExtend: '3.1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '콘크리트 박스 손상에 따른 토사 유실·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-27'
      },
      geometry: { type: 'Point', coordinates: [127.114, 35.8142] }
    },
    {
      type: 'Feature',
      id: 1080,
      properties: {
        sagoDate: '2022-08-27',
        sagoNo: '20220195',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '서서학동',
        addr: '969-1',
        fullAdr: '전북특별자치도 전주시 완산구 서서학동 969-1',
        SEC_CD: '52111126',
        sagoLon: '127.15',
        sagoLat: '35.8014',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수관 파손으로 인한 토사 유출로 지반침하 발생·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-27'
      },
      geometry: { type: 'Point', coordinates: [127.15, 35.8014] }
    },
    {
      type: 'Feature',
      id: 1081,
      properties: {
        sagoDate: '2022-08-30',
        sagoNo: '20220196',
        sido: '전북특별자치도',
        sigungu: '전주시 덕진구',
        dong: '팔복동2가',
        addr: '566-3',
        fullAdr: '전북특별자치도 전주시 덕진구 팔복동2가 566-3',
        SEC_CD: '52113109',
        sagoLon: '127.092',
        sagoLat: '35.8599',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관(우수) 노후로 인한 토사 유실·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-30'
      },
      geometry: { type: 'Point', coordinates: [127.092, 35.8599] }
    },
    {
      type: 'Feature',
      id: 1082,
      properties: {
        sagoDate: '2022-08-31',
        sagoNo: '20220197',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '효자동1가',
        addr: '636-1',
        fullAdr: '전북특별자치도 전주시 완산구 효자동1가 636-1',
        SEC_CD: '52111140',
        sagoLon: '127.121',
        sagoLat: '35.8098',
        sinkWidth: '1.5',
        sinkExtend: '2.5',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '맨홀 접합부 보수 불량으로 인한 지반침하 발생·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-31'
      },
      geometry: { type: 'Point', coordinates: [127.121, 35.8098] }
    },
    {
      type: 'Feature',
      id: 1083,
      properties: {
        sagoDate: '2022-08-16',
        sagoNo: '20220202',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '평화동1가',
        addr: '725-5',
        fullAdr: '전북특별자치도 전주시 완산구 평화동1가 725-5',
        SEC_CD: '52111132',
        sagoLon: '127.136',
        sagoLat: '35.7948',
        sinkWidth: '3',
        sinkExtend: '3',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-08-16'
      },
      geometry: { type: 'Point', coordinates: [127.136, 35.7948] }
    },
    {
      type: 'Feature',
      id: 1084,
      properties: {
        sagoDate: '2022-08-17',
        sagoNo: '20220203',
        sido: '충청남도',
        sigungu: '천안시 서북구',
        dong: '쌍용동',
        addr: '1927',
        fullAdr: '충청남도 천안시 서북구 쌍용동 1927',
        SEC_CD: '44133107',
        sagoLon: '127.12',
        sagoLat: '36.8036',
        sinkWidth: '1.2',
        sinkExtend: '1.2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '우수관로 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '3000000',
        trFnDate: '2022-08-17'
      },
      geometry: { type: 'Point', coordinates: [127.12, 36.8036] }
    },
    {
      type: 'Feature',
      id: 1085,
      properties: {
        sagoDate: '2022-09-06',
        sagoNo: '20220126',
        sido: '경상북도',
        sigungu: '포항시 남구',
        dong: '지곡동',
        addr: '553번지 버스정류장 쪽  2차선',
        fullAdr: '경상북도 포항시 남구 지곡동 553번지 버스정류장 쪽  2차선',
        SEC_CD: '47111115',
        sagoLon: '129.327',
        sagoLat: '36.0266',
        sinkWidth: '2',
        sinkExtend: '7',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail:
          '태풍영향으로 보이며·하수관로 탈락으로 인하여 지반이 유실되어 발생된 싱크홀로 보임',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '10000000',
        trFnDate: '2022-09-13'
      },
      geometry: { type: 'Point', coordinates: [129.327, 36.0266] }
    },
    {
      type: 'Feature',
      id: 1086,
      properties: {
        sagoDate: '2022-09-06',
        sagoNo: '20220127',
        sido: '경상북도',
        sigungu: '포항시 남구',
        dong: '지곡동',
        addr: '237(롯데마트 쪽 인도부분)',
        fullAdr: '경상북도 포항시 남구 지곡동 237(롯데마트 쪽 인도부분)',
        SEC_CD: '47111115',
        sagoLon: '129.325',
        sagoLat: '36.0276',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail:
          '태풍에 따른 집중호우·인도 아래쪽 우수관로 흙 유실로 인하여 싱크홀 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '10000000',
        trFnDate: '2022-09-13'
      },
      geometry: { type: 'Point', coordinates: [129.325, 36.0276] }
    },
    {
      type: 'Feature',
      id: 1087,
      properties: {
        sagoDate: '2022-09-07',
        sagoNo: '20220128',
        sido: '경기도',
        sigungu: '안성시',
        dong: '죽산면',
        addr: '두교리 117-1 (두교산업단지 입구)',
        fullAdr: '경기도 안성시 죽산면 두교리 117-1 (두교산업단지 입구)',
        SEC_CD: '41550400',
        sagoLon: '127.444',
        sagoLat: '37.0158',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail: '다짐불량으로 인해 집중호우 시 우수관 접합부 탈락',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-09-07'
      },
      geometry: { type: 'Point', coordinates: [127.444, 37.0158] }
    },
    {
      type: 'Feature',
      id: 1088,
      properties: {
        sagoDate: '2022-09-08',
        sagoNo: '20220129',
        sido: '전라남도',
        sigungu: '영광군',
        dong: '영광읍',
        addr: '남천리 5-1',
        fullAdr: '전라남도 영광군 영광읍 남천리 5-1',
        SEC_CD: '46870250',
        sagoLon: '126.512',
        sagoLat: '35.2692',
        sinkWidth: '1',
        sinkExtend: '2.5',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '원인불명 씽크홀 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '2000000',
        trFnDate: '2022-09-08'
      },
      geometry: { type: 'Point', coordinates: [126.512, 35.2692] }
    },
    {
      type: 'Feature',
      id: 1089,
      properties: {
        sagoDate: '2022-09-26',
        sagoNo: '20220130',
        sido: '제주특별자치도',
        sigungu: '제주시',
        dong: '화북일동',
        addr: '1983',
        fullAdr: '제주특별자치도 제주시 화북일동 1983',
        SEC_CD: '50110111',
        sagoLon: '126.564',
        sagoLat: '33.5197',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail:
          '우수박스에 연결된 우수관 이음부몰탈이 파손되어 누수에 따른 싱크홀 발생으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '6000000',
        trFnDate: '2022-09-27'
      },
      geometry: { type: 'Point', coordinates: [126.564, 33.5197] }
    },
    {
      type: 'Feature',
      id: 1090,
      properties: {
        sagoDate: '2022-09-13',
        sagoNo: '20220142',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '변동',
        addr: '13-1',
        fullAdr: '대전광역시 서구 변동 13-1',
        SEC_CD: '30170102',
        sagoLon: '127.38',
        sagoLat: '36.3289',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '하수관로 흄관 접합부 이격으로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '5000000',
        trFnDate: '2022-09-14'
      },
      geometry: { type: 'Point', coordinates: [127.38, 36.3289] }
    },
    {
      type: 'Feature',
      id: 1091,
      properties: {
        sagoDate: '2022-09-14',
        sagoNo: '20220154',
        sido: '경기도',
        sigungu: '화성시',
        dong: '남양읍',
        addr: '문호리 1078-6 일원',
        fullAdr: '경기도 화성시 남양읍 문호리 1078-6 일원',
        SEC_CD: '41590262',
        sagoLon: '126.789',
        sagoLat: '37.2497',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '하수맨홀 파손에 따른 토사 유실로 인한 지반함몰',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '3447000',
        trFnDate: '2022-09-16'
      },
      geometry: { type: 'Point', coordinates: [126.789, 37.2497] }
    },
    {
      type: 'Feature',
      id: 1092,
      properties: {
        sagoDate: '2022-09-18',
        sagoNo: '20220157',
        sido: '강원특별자치도',
        sigungu: '철원군',
        dong: '동송읍',
        addr: '하갈리619-1',
        fullAdr: '강원특별자치도 철원군 동송읍 하갈리619-1',
        SEC_CD: '51780259',
        sagoLon: '127.268',
        sagoLat: '38.2753',
        sinkWidth: '3',
        sinkExtend: '2',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail: '도로횡단 수로 다짐 불량으로 인한 침하발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-09-20'
      },
      geometry: { type: 'Point', coordinates: [127.268, 38.2753] }
    },
    {
      type: 'Feature',
      id: 1093,
      properties: {
        sagoDate: '2022-09-02',
        sagoNo: '20220160',
        sido: '부산광역시',
        sigungu: '부산진구',
        dong: '가야동',
        addr: '221-2번지 세연정 앞 보도',
        fullAdr: '부산광역시 부산진구 가야동 221-2번지 세연정 앞 보도',
        SEC_CD: '26230110',
        sagoLon: '129.033',
        sagoLat: '35.1535',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '상수도관 파열에 따른 유출 발생· 유수에 의한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-09-05'
      },
      geometry: { type: 'Point', coordinates: [129.033, 35.1535] }
    },
    {
      type: 'Feature',
      id: 1094,
      properties: {
        sagoDate: '2022-09-28',
        sagoNo: '20220163',
        sido: '부산광역시',
        sigungu: '사하구',
        dong: '괴정동',
        addr: '230번지 앞 1차선 도로',
        fullAdr: '부산광역시 사하구 괴정동 230번지 앞 1차선 도로',
        SEC_CD: '26380101',
        sagoLon: '129',
        sagoLat: '35.1032',
        sinkWidth: '4',
        sinkExtend: '1',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail:
          "지하철공사 가시설물(I빔 및 Con'c구조물) 및 상수도관(600mm) 공사 시 다짐불량으로 추정",
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-10-07'
      },
      geometry: { type: 'Point', coordinates: [129, 35.1032] }
    },
    {
      type: 'Feature',
      id: 1095,
      properties: {
        sagoDate: '2022-09-07',
        sagoNo: '20220164',
        sido: '경상남도',
        sigungu: '창원시 마산회원구',
        dong: '회원동',
        addr: '159-1',
        fullAdr: '경상남도 창원시 마산회원구 회원동 159-1',
        SEC_CD: '48127108',
        sagoLon: '128.573',
        sagoLat: '35.2232',
        sinkWidth: '0.7',
        sinkExtend: '0.8',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수관로 파손으로 인한 도로 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1000000',
        trFnDate: '2023-09-08'
      },
      geometry: { type: 'Point', coordinates: [128.573, 35.2232] }
    },
    {
      type: 'Feature',
      id: 1096,
      properties: {
        sagoDate: '2022-09-01',
        sagoNo: '20220168',
        sido: '경상북도',
        sigungu: '구미시',
        dong: '선기동',
        addr: '선기동 538-10',
        fullAdr: '경상북도 구미시 선기동 선기동 538-10',
        SEC_CD: '47190106',
        sagoLon: '128.318',
        sagoLat: '36.1316',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-09-07'
      },
      geometry: { type: 'Point', coordinates: [128.318, 36.1316] }
    },
    {
      type: 'Feature',
      id: 1097,
      properties: {
        sagoDate: '2022-09-06',
        sagoNo: '20220169',
        sido: '경상북도',
        sigungu: '구미시',
        dong: '공단동',
        addr: '공단동 140',
        fullAdr: '경상북도 구미시 공단동 공단동 140',
        SEC_CD: '47190113',
        sagoLon: '128.379',
        sagoLat: '36.1169',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '0.02',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-09-06'
      },
      geometry: { type: 'Point', coordinates: [128.379, 36.1169] }
    },
    {
      type: 'Feature',
      id: 1098,
      properties: {
        sagoDate: '2022-09-12',
        sagoNo: '20220170',
        sido: '경상북도',
        sigungu: '구미시',
        dong: '공단동',
        addr: '1공단로10길31-22',
        fullAdr: '경상북도 구미시 공단동 1공단로10길31-22',
        SEC_CD: '47190113',
        sagoLon: '128.384',
        sagoLat: '36.0992',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-09-13'
      },
      geometry: { type: 'Point', coordinates: [128.384, 36.0992] }
    },
    {
      type: 'Feature',
      id: 1099,
      properties: {
        sagoDate: '2022-09-21',
        sagoNo: '20220171',
        sido: '경상북도',
        sigungu: '구미시',
        dong: '지산동',
        addr: '지산17길 22',
        fullAdr: '경상북도 구미시 지산동 지산17길 22',
        SEC_CD: '47190102',
        sagoLon: '128.346',
        sagoLat: '36.1454',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.04',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-09-21'
      },
      geometry: { type: 'Point', coordinates: [128.346, 36.1454] }
    },
    {
      type: 'Feature',
      id: 1100,
      properties: {
        sagoDate: '2022-09-28',
        sagoNo: '20220172',
        sido: '경상북도',
        sigungu: '구미시',
        dong: '도량동',
        addr: '도량동 86',
        fullAdr: '경상북도 구미시 도량동 도량동 86',
        SEC_CD: '47190103',
        sagoLon: '128.323',
        sagoLat: '36.1432',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-09-28'
      },
      geometry: { type: 'Point', coordinates: [128.323, 36.1432] }
    },
    {
      type: 'Feature',
      id: 1101,
      properties: {
        sagoDate: '2022-09-08',
        sagoNo: '20220198',
        sido: '전북특별자치도',
        sigungu: '전주시 덕진구',
        dong: '인후동2가',
        addr: '261-8',
        fullAdr: '전북특별자치도 전주시 덕진구 인후동2가 261-8',
        SEC_CD: '52113104',
        sagoLon: '127.145',
        sagoLat: '35.8323',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관(우수) 노후로 인한 토사 유실·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-09-08'
      },
      geometry: { type: 'Point', coordinates: [127.145, 35.8323] }
    },
    {
      type: 'Feature',
      id: 1102,
      properties: {
        sagoDate: '2022-09-02',
        sagoNo: '20230006',
        sido: '경상북도',
        sigungu: '구미시',
        dong: '선기동',
        addr: '선기동 530',
        fullAdr: '경상북도 구미시 선기동 선기동 530',
        SEC_CD: '47190106',
        sagoLon: '128.32',
        sagoLat: '36.1319',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-09-07'
      },
      geometry: { type: 'Point', coordinates: [128.32, 36.1319] }
    },
    {
      type: 'Feature',
      id: 1103,
      properties: {
        sagoDate: '2022-10-03',
        sagoNo: '20220131',
        sido: '서울특별시',
        sigungu: '마포구',
        dong: '도화동',
        addr: '39-9',
        fullAdr: '서울특별시 마포구 도화동 39-9',
        SEC_CD: '11440104',
        sagoLon: '126.948',
        sagoLat: '37.5411',
        sinkWidth: '0.3',
        sinkExtend: '0.3',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '통신관로(LG유플러스) 주변 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-10-04'
      },
      geometry: { type: 'Point', coordinates: [126.948, 37.5411] }
    },
    {
      type: 'Feature',
      id: 1104,
      properties: {
        sagoDate: '2022-10-05',
        sagoNo: '20220132',
        sido: '경기도',
        sigungu: '고양시 일산동구',
        dong: '백석동',
        addr: '1297',
        fullAdr: '경기도 고양시 일산동구 백석동 1297',
        SEC_CD: '41285106',
        sagoLon: '126.788',
        sagoLat: '37.644',
        sinkWidth: '2',
        sinkExtend: '5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '굴착공사 부실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-10-05'
      },
      geometry: { type: 'Point', coordinates: [126.788, 37.644] }
    },
    {
      type: 'Feature',
      id: 1105,
      properties: {
        sagoDate: '2022-10-15',
        sagoNo: '20220133',
        sido: '충청북도',
        sigungu: '청주시 서원구',
        dong: '남이면',
        addr: '상발리 260번지(지목: 하천) 일원',
        fullAdr:
          '충청북도 청주시 서원구 남이면 상발리 260번지(지목: 하천) 일원',
        SEC_CD: '43112310',
        sagoLon: '127.395',
        sagoLat: '36.5858',
        sinkWidth: '2',
        sinkExtend: '5',
        sinkDepth: '1.7',
        grdKind: '기타',
        sagoDetail:
          '농로 옆 소하천 노후 옹벽 균열 틈으로 유수에 의한 (콘크리트)도로 하부 토사 유실발생 및 가장자리 옹벽 파손· 상판붕괴( 꺼짐)으로 차량 이 전도됨.',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '8000000',
        trFnDate: '2022-11-30'
      },
      geometry: { type: 'Point', coordinates: [127.395, 36.5858] }
    },
    {
      type: 'Feature',
      id: 1106,
      properties: {
        sagoDate: '2022-10-12',
        sagoNo: '20220153',
        sido: '경기도',
        sigungu: '화성시',
        dong: '우정읍',
        addr: '화산리 160-4',
        fullAdr: '경기도 화성시 우정읍 화산리 160-4',
        SEC_CD: '41590256',
        sagoLon: '126.794',
        sagoLat: '37.0807',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '우수맨홀 주변부 도로 포장 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1795000',
        trFnDate: '2022-10-12'
      },
      geometry: { type: 'Point', coordinates: [126.794, 37.0807] }
    },
    {
      type: 'Feature',
      id: 1107,
      properties: {
        sagoDate: '2022-10-12',
        sagoNo: '20220173',
        sido: '경상북도',
        sigungu: '구미시',
        dong: '원평동',
        addr: '송원서로 79',
        fullAdr: '경상북도 구미시 원평동 송원서로 79',
        SEC_CD: '47190101',
        sagoLon: '128.344',
        sagoLat: '36.1248',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '0.03',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-10-15'
      },
      geometry: { type: 'Point', coordinates: [128.344, 36.1248] }
    },
    {
      type: 'Feature',
      id: 1108,
      properties: {
        sagoDate: '2022-11-02',
        sagoNo: '20220135',
        sido: '전라남도',
        sigungu: '여수시',
        dong: '웅천동',
        addr: '1701',
        fullAdr: '전라남도 여수시 웅천동 1701',
        SEC_CD: '46130134',
        sagoLon: '127.673',
        sagoLat: '34.746',
        sinkWidth: '2.2',
        sinkExtend: '10',
        sinkDepth: '40',
        grdKind: '충척층',
        sagoDetail:
          '○ 지표부터 매립층(사석· 자갈· 모래· 점토 혼재)· 충적층(매우 느슨한 점토질 모래· N값 1∼3)· 풍화토· 풍화암으로 지층이 구성되어 있으며· 해안 인접으로 조위차에 의한 지하수 유동이 큼. 따라서 매립층 및 충적층에서 SCW(전열) 및 CIP(후열) 시공 시 흙막이 벽체 품질 불량이 우려되며· 이로 인한 벽체 강성 저하로 토압 및 수압에 대한 저항력이 약화되어 벽체가 급격히 붕괴되었을 것으로 추정··○ SCW 전열 벽체와 CIP 후열 벽체 사이(약 2m)에 2.7m 간격으로 JSP* 지반보강 공사가 진행되었으며· JSP 공법 특성상 교반 시 강한 압력으로 기시공된 SCW 등 벽체에 영향을 미쳐· 기존 흙막이 벽체의 강성 저하를 야기하여 벽체가 수압 및 토압을 견디지 못하였을 것으로 추정 ·？ * JSP(Jumbo Special Pile) 공법 : 시멘트 밀크 경화제를 초고압으로 분사시켜 원지반을 교란·혼합하는 주입공법이며· 전열과 후열을 강성화하기 위해 현장에 적용됨 ·○ 또한· 후열 CIP에 차수공법이 반영되지 않아 전열 SCW까지 해수가 유입되면서 벽체 전반적으로 간조와 만조에 의한 지속적인 수위변화와 수압 작용이 흙막이 벽체에 악영향을 미친 것으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '250000000',
        trFnDate: '2022-11-08'
      },
      geometry: { type: 'Point', coordinates: [127.673, 34.746] }
    },
    {
      type: 'Feature',
      id: 1109,
      properties: {
        sagoDate: '2022-11-19',
        sagoNo: '20220136',
        sido: '서울특별시',
        sigungu: '서초구',
        dong: '반포동',
        addr: '1192',
        fullAdr: '서울특별시 서초구 반포동 1192',
        SEC_CD: '11650107',
        sagoLon: '126.988',
        sagoLat: '37.5019',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '노후 하수관로(D450mm) 파손으로 인한 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '5000000',
        trFnDate: '2022-11-19'
      },
      geometry: { type: 'Point', coordinates: [126.988, 37.5019] }
    },
    {
      type: 'Feature',
      id: 1110,
      properties: {
        sagoDate: '2022-11-30',
        sagoNo: '20220137',
        sido: '충청남도',
        sigungu: '보령시',
        dong: '대천동',
        addr: '1242',
        fullAdr: '충청남도 보령시 대천동 1242',
        SEC_CD: '44180101',
        sagoLon: '126.56',
        sagoLat: '36.3533',
        sinkWidth: '0.3',
        sinkExtend: '0.2',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '원인불명',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-12-09'
      },
      geometry: { type: 'Point', coordinates: [126.56, 36.3533] }
    },
    {
      type: 'Feature',
      id: 1111,
      properties: {
        sagoDate: '2022-11-14',
        sagoNo: '20220141',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '월평동',
        addr: '100-3',
        fullAdr: '대전광역시 서구 월평동 100-3',
        SEC_CD: '30170113',
        sagoLon: '127.354',
        sagoLat: '36.3462',
        sinkWidth: '1',
        sinkExtend: '3',
        sinkDepth: '0.1',
        grdKind: '기타',
        sagoDetail: '우수BOX 벽체파손으로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '5000000',
        trFnDate: '2022-11-15'
      },
      geometry: { type: 'Point', coordinates: [127.354, 36.3462] }
    },
    {
      type: 'Feature',
      id: 1112,
      properties: {
        sagoDate: '2022-11-15',
        sagoNo: '20220151',
        sido: '경기도',
        sigungu: '화성시',
        dong: '남양읍',
        addr: '신남리 1337-1',
        fullAdr: '경기도 화성시 남양읍 신남리 1337-1',
        SEC_CD: '41590262',
        sagoLon: '126.802',
        sagoLat: '37.1732',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '우수맨홀 주변부 도로 포장 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '3252000',
        trFnDate: '2022-11-16'
      },
      geometry: { type: 'Point', coordinates: [126.802, 37.1732] }
    },
    {
      type: 'Feature',
      id: 1113,
      properties: {
        sagoDate: '2022-11-18',
        sagoNo: '20220152',
        sido: '경기도',
        sigungu: '화성시',
        dong: '팔탄면',
        addr: '구장리 76-2',
        fullAdr: '경기도 화성시 팔탄면 구장리 76-2',
        SEC_CD: '41590360',
        sagoLon: '126.901',
        sagoLat: '37.1696',
        sinkWidth: '3',
        sinkExtend: '2',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '우수관로 라인 주변부 도로 포장 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1878000',
        trFnDate: '2022-11-24'
      },
      geometry: { type: 'Point', coordinates: [126.901, 37.1696] }
    },
    {
      type: 'Feature',
      id: 1114,
      properties: {
        sagoDate: '2022-11-26',
        sagoNo: '20220161',
        sido: '부산광역시',
        sigungu: '연제구',
        dong: '연산동',
        addr: '연수로 179 앞 보도(연산동 1867-2)',
        fullAdr: '부산광역시 연제구 연산동 연수로 179 앞 보도(연산동 1867-2)',
        SEC_CD: '26470102',
        sagoLon: '129.091',
        sagoLat: '35.1742',
        sinkWidth: '2',
        sinkExtend: '3',
        sinkDepth: '3',
        grdKind: '기타',
        sagoDetail:
          '하수관로 집수정 벽체 노후로 인하여 토사 유실로 인한 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '5170000',
        trFnDate: '2022-12-09'
      },
      geometry: { type: 'Point', coordinates: [129.091, 35.1742] }
    },
    {
      type: 'Feature',
      id: 1115,
      properties: {
        sagoDate: '2022-11-03',
        sagoNo: '20220174',
        sido: '경상북도',
        sigungu: '구미시',
        dong: '도량동',
        addr: '도량동 292-161',
        fullAdr: '경상북도 구미시 도량동 도량동 292-161',
        SEC_CD: '47190103',
        sagoLon: '128.322',
        sagoLat: '36.138',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '0.02',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-11-03'
      },
      geometry: { type: 'Point', coordinates: [128.322, 36.138] }
    },
    {
      type: 'Feature',
      id: 1116,
      properties: {
        sagoDate: '2022-11-28',
        sagoNo: '20220175',
        sido: '경상북도',
        sigungu: '구미시',
        dong: '송정동',
        addr: '송정대로77',
        fullAdr: '경상북도 구미시 송정동 송정대로77',
        SEC_CD: '47190110',
        sagoLon: '128.348',
        sagoLat: '36.1178',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-11-28'
      },
      geometry: { type: 'Point', coordinates: [128.348, 36.1178] }
    },
    {
      type: 'Feature',
      id: 1117,
      properties: {
        sagoDate: '2022-11-05',
        sagoNo: '20220199',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '효자동3가',
        addr: '1203',
        fullAdr: '전북특별자치도 전주시 완산구 효자동3가 1203',
        SEC_CD: '52111142',
        sagoLon: '127.09',
        sagoLat: '35.8156',
        sinkWidth: '5',
        sinkExtend: '7',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '포장 다짐 불량·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-11-05'
      },
      geometry: { type: 'Point', coordinates: [127.09, 35.8156] }
    },
    {
      type: 'Feature',
      id: 1118,
      properties: {
        sagoDate: '2022-11-28',
        sagoNo: '20220201',
        sido: '전라남도',
        sigungu: '무안군',
        dong: '삼향읍',
        addr: '지산리 720-11',
        fullAdr: '전라남도 무안군 삼향읍 지산리 720-11',
        SEC_CD: '46840256',
        sagoLon: '126.421',
        sagoLat: '34.8825',
        sinkWidth: '0.7',
        sinkExtend: '5',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '토사유실로 인한 도로 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-12-05'
      },
      geometry: { type: 'Point', coordinates: [126.421, 34.8825] }
    },
    {
      type: 'Feature',
      id: 1119,
      properties: {
        sagoDate: '2022-12-08',
        sagoNo: '20220138',
        sido: '서울특별시',
        sigungu: '구로구',
        dong: '천왕동',
        addr: '오리로 1149',
        fullAdr: '서울특별시 구로구 천왕동 오리로 1149',
        SEC_CD: '11530111',
        sagoLon: '126.839',
        sagoLat: '37.4866',
        sinkWidth: '0.2',
        sinkExtend: '0.8',
        sinkDepth: '0.5',
        grdKind: '기타',
        sagoDetail:
          '？ 장 소 : 구로구 오리로 1149 (천왕역 2번출구 인근) ·？ 사고원인 : 다짐불량으로 추정·？ 피해상황 : 부상자 1명 발생(유지선(여·50세)/오른쪽발목통증/부천성모병원 응급실 치료후 자가 퇴원)·？ 발생규모 : 0.2m×0.8m×0.5m(가로×세로×깊이)',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '4000000',
        trFnDate: '2022-12-10'
      },
      geometry: { type: 'Point', coordinates: [126.839, 37.4866] }
    },
    {
      type: 'Feature',
      id: 1120,
      properties: {
        sagoDate: '2022-12-31',
        sagoNo: '20220139',
        sido: '서울특별시',
        sigungu: '강동구',
        dong: '암사동',
        addr: '상암로 87-1(암사동 490-48)',
        fullAdr: '서울특별시 강동구 암사동 상암로 87-1(암사동 490-48)',
        SEC_CD: '11740107',
        sagoLon: '127.133',
        sagoLat: '37.5479',
        sinkWidth: '0.4',
        sinkExtend: '0.4',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '가정하수관 연결부 파손으로 인한 토사유실 추정',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2023-01-02'
      },
      geometry: { type: 'Point', coordinates: [127.133, 37.5479] }
    },
    {
      type: 'Feature',
      id: 1121,
      properties: {
        sagoDate: '2022-12-24',
        sagoNo: '20220144',
        sido: '경기도',
        sigungu: '성남시 분당구',
        dong: '삼평동',
        addr: '726-1',
        fullAdr: '경기도 성남시 분당구 삼평동 726-1',
        SEC_CD: '41135109',
        sagoLon: '127.113',
        sagoLat: '37.4005',
        sinkWidth: '6',
        sinkExtend: '5',
        sinkDepth: '1.6',
        grdKind: '충척층',
        sagoDetail: '광역상수도관로(D2·600) 관체 균열로 인한 누수 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-01-16'
      },
      geometry: { type: 'Point', coordinates: [127.113, 37.4005] }
    },
    {
      type: 'Feature',
      id: 1122,
      properties: {
        sagoDate: '2022-12-01',
        sagoNo: '20220149',
        sido: '경기도',
        sigungu: '화성시',
        dong: '남양읍',
        addr: '수화리 732-7',
        fullAdr: '경기도 화성시 남양읍 수화리 732-7',
        SEC_CD: '41590262',
        sagoLon: '126.822',
        sagoLat: '37.2493',
        sinkWidth: '3',
        sinkExtend: '4',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '우수맨홀 및 관로라인 주변부 포장 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '2716000',
        trFnDate: '2022-12-09'
      },
      geometry: { type: 'Point', coordinates: [126.822, 37.2493] }
    },
    {
      type: 'Feature',
      id: 1123,
      properties: {
        sagoDate: '2022-12-01',
        sagoNo: '20220150',
        sido: '경기도',
        sigungu: '화성시',
        dong: '남양읍',
        addr: '신남리 1478',
        fullAdr: '경기도 화성시 남양읍 신남리 1478',
        SEC_CD: '41590262',
        sagoLon: '126.8',
        sagoLat: '37.1789',
        sinkWidth: '1',
        sinkExtend: '10',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '우수관로 파손에 따른 포장 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1230000',
        trFnDate: '2022-12-02'
      },
      geometry: { type: 'Point', coordinates: [126.8, 37.1789] }
    },
    {
      type: 'Feature',
      id: 1124,
      properties: {
        sagoDate: '2022-12-26',
        sagoNo: '20220176',
        sido: '경상북도',
        sigungu: '구미시',
        dong: '양호동',
        addr: '양호동 908',
        fullAdr: '경상북도 구미시 양호동 양호동 908',
        SEC_CD: '47190102',
        sagoLon: '128.362',
        sagoLat: '36.1305',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-12-26'
      },
      geometry: { type: 'Point', coordinates: [128.362, 36.1305] }
    },
    {
      type: 'Feature',
      id: 1125,
      properties: {
        sagoDate: '2022-12-29',
        sagoNo: '20220177',
        sido: '경상북도',
        sigungu: '구미시',
        dong: '형곡동',
        addr: '형곡동110',
        fullAdr: '경상북도 구미시 형곡동 형곡동110',
        SEC_CD: '47190109',
        sagoLon: '128.334',
        sagoLat: '36.1161',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.05',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-12-29'
      },
      geometry: { type: 'Point', coordinates: [128.334, 36.1161] }
    },
    {
      type: 'Feature',
      id: 1126,
      properties: {
        sagoDate: '2022-12-07',
        sagoNo: '20220178',
        sido: '광주광역시',
        sigungu: '남구',
        dong: '서동',
        addr: '63-2',
        fullAdr: '광주광역시 남구 서동 63-2',
        SEC_CD: '29155103',
        sagoLon: '126.904',
        sagoLat: '35.1455',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.2',
        grdKind: '기타',
        sagoDetail:
          '하수 box 벽체 손상으로 인하여 토사가 유실되어 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-12-08'
      },
      geometry: { type: 'Point', coordinates: [126.904, 35.1455] }
    },
    {
      type: 'Feature',
      id: 1127,
      properties: {
        sagoDate: '2022-12-11',
        sagoNo: '20220200',
        sido: '전북특별자치도',
        sigungu: '전주시 덕진구',
        dong: '인후동1가',
        addr: '806-2',
        fullAdr: '전북특별자치도 전주시 덕진구 인후동1가 806-2',
        SEC_CD: '52113103',
        sagoLon: '127.164',
        sagoLat: '35.8351',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수관(오수) 노후로 인한 도로 침하·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2022-12-11'
      },
      geometry: { type: 'Point', coordinates: [127.164, 35.8351] }
    },
    {
      type: 'Feature',
      id: 1128,
      properties: {
        sagoDate: '2023-01-05',
        sagoNo: '20230001',
        sido: '부산광역시',
        sigungu: '사상구',
        dong: '감전동',
        addr: '131-1번지',
        fullAdr: '부산광역시 사상구 감전동 131-1번지',
        SEC_CD: '26530105',
        sagoLon: '128.983',
        sagoLat: '35.156',
        sinkWidth: '0.5',
        sinkExtend: '0.8',
        sinkDepth: '3',
        grdKind: '기타',
        sagoDetail:
          '노후 하수시설(U형측구) 파손 등으로 인한 누수 발생에 따른 토사 유실로 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-01-06'
      },
      geometry: { type: 'Point', coordinates: [128.983, 35.156] }
    },
    {
      type: 'Feature',
      id: 1129,
      properties: {
        sagoDate: '2023-01-02',
        sagoNo: '20230002',
        sido: '전북특별자치도',
        sigungu: '김제시',
        dong: '죽산면',
        addr: '935-3',
        fullAdr: '전북특별자치도 김제시 죽산면 935-3',
        SEC_CD: '52210320',
        sagoLon: '126.797',
        sagoLat: '35.7794',
        sinkWidth: '0.6',
        sinkExtend: '0.7',
        sinkDepth: '1.2',
        grdKind: '',
        sagoDetail: '자연침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '83686000',
        trFnDate: '2023-10-04'
      },
      geometry: { type: 'Point', coordinates: [126.797, 35.7794] }
    },
    {
      type: 'Feature',
      id: 1130,
      properties: {
        sagoDate: '2023-01-13',
        sagoNo: '20230003',
        sido: '서울특별시',
        sigungu: '도봉구',
        dong: '방학동',
        addr: '717-6 인근',
        fullAdr: '서울특별시 도봉구 방학동 717-6 인근',
        SEC_CD: '11320106',
        sagoLon: '127.046',
        sagoLat: '37.6677',
        sinkWidth: '1.6',
        sinkExtend: '0.6',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail: '건축물 신축 공사장 굴토(철거) 작업장 인근으로 원인 확인중',
        deathCnt: '0',
        injuryCnt: '2',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-01-13'
      },
      geometry: { type: 'Point', coordinates: [127.046, 37.6677] }
    },
    {
      type: 'Feature',
      id: 1131,
      properties: {
        sagoDate: '2023-01-13',
        sagoNo: '20230004',
        sido: '경상남도',
        sigungu: '창원시 진해구',
        dong: '용원동',
        addr: '용원로78번길5',
        fullAdr: '경상남도 창원시 진해구 용원동 용원로78번길5',
        SEC_CD: '48129164',
        sagoLon: '128.818',
        sagoLat: '35.0985',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '0.5',
        grdKind: '기타',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1000000',
        trFnDate: '2023-01-13'
      },
      geometry: { type: 'Point', coordinates: [128.818, 35.0985] }
    },
    {
      type: 'Feature',
      id: 1132,
      properties: {
        sagoDate: '2023-01-26',
        sagoNo: '20230005',
        sido: '경기도',
        sigungu: '시흥시',
        dong: '장곡동',
        addr: '정왕동 2707-1',
        fullAdr: '경기도 시흥시 장곡동 정왕동 2707-1',
        SEC_CD: '41390132',
        sagoLon: '126.677',
        sagoLat: '37.3251',
        sinkWidth: '10',
        sinkExtend: '20',
        sinkDepth: '0.7',
        grdKind: '기타',
        sagoDetail:
          '흙막이벽체(지하연속벽) 시공이음부 콘크리트 재로분리로 인한 공극부 누수 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-03-31'
      },
      geometry: { type: 'Point', coordinates: [126.677, 37.3251] }
    },
    {
      type: 'Feature',
      id: 1133,
      properties: {
        sagoDate: '2023-01-17',
        sagoNo: '20230080',
        sido: '경기도',
        sigungu: '화성시',
        dong: '산척동',
        addr: '777',
        fullAdr: '경기도 화성시 산척동 777',
        SEC_CD: '41590135',
        sagoLon: '127.119',
        sagoLat: '37.1678',
        sinkWidth: '3.5',
        sinkExtend: '2',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail:
          '물다짐 불량(한국지역난방공사 동탄지사 측 제출 위도: 37.167844· 경도: 127.118517)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-01-19'
      },
      geometry: { type: 'Point', coordinates: [127.119, 37.1678] }
    },
    {
      type: 'Feature',
      id: 1134,
      properties: {
        sagoDate: '2023-01-09',
        sagoNo: '20230085',
        sido: '강원특별자치도',
        sigungu: '영월군',
        dong: '주천면',
        addr: '주천로89번길14',
        fullAdr: '강원특별자치도 영월군 주천면 주천로89번길14',
        SEC_CD: '51750360',
        sagoLon: '128.268',
        sagoLat: '37.2728',
        sinkWidth: '3',
        sinkExtend: '3',
        sinkDepth: '0.3',
        grdKind: '기타',
        sagoDetail: '지속적인 하중으로 자연 침하·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '2853000',
        trFnDate: '2023-01-18'
      },
      geometry: { type: 'Point', coordinates: [128.268, 37.2728] }
    },
    {
      type: 'Feature',
      id: 1135,
      properties: {
        sagoDate: '2023-01-25',
        sagoNo: '20230086',
        sido: '강원특별자치도',
        sigungu: '영월군',
        dong: '영월읍',
        addr: '덕포리644-8',
        fullAdr: '강원특별자치도 영월군 영월읍 덕포리644-8',
        SEC_CD: '51750250',
        sagoLon: '128.478',
        sagoLat: '37.176',
        sinkWidth: '8',
        sinkExtend: '8',
        sinkDepth: '0.3',
        grdKind: '기타',
        sagoDetail: '대형차량 통행 등 지속적인 침하·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '11442000',
        trFnDate: '2023-04-17'
      },
      geometry: { type: 'Point', coordinates: [128.478, 37.176] }
    },
    {
      type: 'Feature',
      id: 1136,
      properties: {
        sagoDate: '2023-01-18',
        sagoNo: '20230094',
        sido: '광주광역시',
        sigungu: '북구',
        dong: '금곡동',
        addr: '435-2',
        fullAdr: '광주광역시 북구 금곡동 435-2',
        SEC_CD: '29170120',
        sagoLon: '126.997',
        sagoLat: '35.1752',
        sinkWidth: '0.7',
        sinkExtend: '20',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-02-28'
      },
      geometry: { type: 'Point', coordinates: [126.997, 35.1752] }
    },
    {
      type: 'Feature',
      id: 1137,
      properties: {
        sagoDate: '2023-02-11',
        sagoNo: '20230007',
        sido: '강원특별자치도',
        sigungu: '속초시',
        dong: '조양동',
        addr: '1411-15',
        fullAdr: '강원특별자치도 속초시 조양동 1411-15',
        SEC_CD: '51210108',
        sagoLon: '128.596',
        sagoLat: '38.19',
        sinkWidth: '10',
        sinkExtend: '30',
        sinkDepth: '0.02',
        grdKind: '기타',
        sagoDetail:
          '차수공법 시공 미흡으로 흙막이 벽체 틈 사이로 토사와 지하수가 유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-02-11'
      },
      geometry: { type: 'Point', coordinates: [128.596, 38.19] }
    },
    {
      type: 'Feature',
      id: 1138,
      properties: {
        sagoDate: '2023-02-03',
        sagoNo: '20230008',
        sido: '전라남도',
        sigungu: '무안군',
        dong: '일로읍',
        addr: '오룡지구 44블럭 동북측 외부 도로',
        fullAdr: '전라남도 무안군 일로읍 오룡지구 44블럭 동북측 외부 도로',
        SEC_CD: '46840253',
        sagoLon: '126.48',
        sagoLat: '34.8009',
        sinkWidth: '1',
        sinkExtend: '20',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '조사 중',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2023-02-04'
      },
      geometry: { type: 'Point', coordinates: [126.48, 34.8009] }
    },
    {
      type: 'Feature',
      id: 1139,
      properties: {
        sagoDate: '2023-02-17',
        sagoNo: '20230009',
        sido: '서울특별시',
        sigungu: '서초구',
        dong: '서초동',
        addr: '1339-9',
        fullAdr: '서울특별시 서초구 서초동 1339-9',
        SEC_CD: '11650108',
        sagoLon: '127.031',
        sagoLat: '37.4897',
        sinkWidth: '0.5',
        sinkExtend: '1.2',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail: '통신케이블 처짐 및 가시설 미제거에 의한 장기 침하(추정)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '5000000',
        trFnDate: '2023-02-17'
      },
      geometry: { type: 'Point', coordinates: [127.031, 37.4897] }
    },
    {
      type: 'Feature',
      id: 1140,
      properties: {
        sagoDate: '2023-02-27',
        sagoNo: '20230010',
        sido: '강원특별자치도',
        sigungu: '강릉시',
        dong: '송정동',
        addr: '187-4번지 일원',
        fullAdr: '강원특별자치도 강릉시 송정동 187-4번지 일원',
        SEC_CD: '51150114',
        sagoLon: '128.933',
        sagoLat: '37.7748',
        sinkWidth: '3',
        sinkExtend: '3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-02-28'
      },
      geometry: { type: 'Point', coordinates: [128.933, 37.7748] }
    },
    {
      type: 'Feature',
      id: 1141,
      properties: {
        sagoDate: '2023-02-17',
        sagoNo: '20230059',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '월평동',
        addr: '916',
        fullAdr: '대전광역시 서구 월평동 916',
        SEC_CD: '30170113',
        sagoLon: '127.362',
        sagoLat: '36.3562',
        sinkWidth: '0.7',
        sinkExtend: '0.7',
        sinkDepth: '1.5',
        grdKind: '충척층',
        sagoDetail: '흄관 파손으로 인한 도로함몰',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '2600000',
        trFnDate: '2023-02-18'
      },
      geometry: { type: 'Point', coordinates: [127.362, 36.3562] }
    },
    {
      type: 'Feature',
      id: 1142,
      properties: {
        sagoDate: '2023-03-05',
        sagoNo: '20230011',
        sido: '경상북도',
        sigungu: '경산시',
        dong: '하양읍',
        addr: '금락리 373-10',
        fullAdr: '경상북도 경산시 하양읍 금락리 373-10',
        SEC_CD: '47290250',
        sagoLon: '128.813',
        sagoLat: '35.9082',
        sinkWidth: '0.5',
        sinkExtend: '2',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail:
          'D300mm 주철관 이음부 볼트 체결 약화에 따른 수도 누수로 인하여 경미한 지반 침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-03-07'
      },
      geometry: { type: 'Point', coordinates: [128.813, 35.9082] }
    },
    {
      type: 'Feature',
      id: 1143,
      properties: {
        sagoDate: '2023-03-07',
        sagoNo: '20230012',
        sido: '서울특별시',
        sigungu: '용산구',
        dong: '도원동',
        addr: '도원삼성래민안 아파트 정문',
        fullAdr: '서울특별시 용산구 도원동 도원삼성래민안 아파트 정문',
        SEC_CD: '11170120',
        sagoLon: '126.957',
        sagoLat: '37.5397',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '노후 하수관로(흄관· D300 mm)파손',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-03-08'
      },
      geometry: { type: 'Point', coordinates: [126.957, 37.5397] }
    },
    {
      type: 'Feature',
      id: 1144,
      properties: {
        sagoDate: '2023-03-11',
        sagoNo: '20230013',
        sido: '서울특별시',
        sigungu: '중구',
        dong: '남대문로5가',
        addr: '521',
        fullAdr: '서울특별시 중구 남대문로5가 521',
        SEC_CD: '11170107',
        sagoLon: '126.973',
        sagoLat: '37.5541',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '하수관로(매설년도 미상· D600mm) 이음부 이격으로 인한 토사 유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-03-13'
      },
      geometry: { type: 'Point', coordinates: [126.973, 37.5541] }
    },
    {
      type: 'Feature',
      id: 1145,
      properties: {
        sagoDate: '2023-03-15',
        sagoNo: '20230014',
        sido: '경기도',
        sigungu: '시흥시',
        dong: '정왕동',
        addr: '2605-6',
        fullAdr: '경기도 시흥시 정왕동 2605-6',
        SEC_CD: '41390132',
        sagoLon: '126.7',
        sagoLat: '37.3254',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.2',
        grdKind: '',
        sagoDetail:
          '지반침하 발생부근 인근의 임시포장도로에서 되메우기불량으로 지반침하 발생부근의 토사가 유실된 것으로 추정되며··침하도로 이용차량 약 7대에 경미한 피해 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '7',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-03-16'
      },
      geometry: { type: 'Point', coordinates: [126.7, 37.3254] }
    },
    {
      type: 'Feature',
      id: 1146,
      properties: {
        sagoDate: '2023-03-24',
        sagoNo: '20230015',
        sido: '부산광역시',
        sigungu: '사상구',
        dong: '감전동',
        addr: '새벽로 160',
        fullAdr: '부산광역시 사상구 감전동 새벽로 160',
        SEC_CD: '26530105',
        sagoLon: '128.983',
        sagoLat: '35.1554',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail:
          '지장물(상수도관· 통신관 등)로 인해 다짐 불량에 따른 지반 침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '2',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-03-27'
      },
      geometry: { type: 'Point', coordinates: [128.983, 35.1554] }
    },
    {
      type: 'Feature',
      id: 1147,
      properties: {
        sagoDate: '2023-03-31',
        sagoNo: '20230016',
        sido: '서울특별시',
        sigungu: '은평구',
        dong: '갈현동',
        addr: '389-16',
        fullAdr: '서울특별시 은평구 갈현동 389-16',
        SEC_CD: '11380104',
        sagoLon: '126.92',
        sagoLat: '37.6188',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '4',
        grdKind: '기타',
        sagoDetail: '6호선 대합실구조물 되메우기시 다짐불량으로 인한 공동발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-03-31'
      },
      geometry: { type: 'Point', coordinates: [126.92, 37.6188] }
    },
    {
      type: 'Feature',
      id: 1148,
      properties: {
        sagoDate: '2023-03-31',
        sagoNo: '20230018',
        sido: '강원특별자치도',
        sigungu: '속초시',
        dong: '조양동',
        addr: '1452',
        fullAdr: '강원특별자치도 속초시 조양동 1452',
        SEC_CD: '51210108',
        sagoLon: '128.602',
        sagoLat: '38.1901',
        sinkWidth: '4',
        sinkExtend: '4',
        sinkDepth: '0.6',
        grdKind: '기타',
        sagoDetail:
          '골조공사 진행에 의한 잔류수압 상승· 자재이동 차량에 의한 추가하중 및 시간경과에 따른 차수효과 저하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '20000000',
        trFnDate: '2023-03-31'
      },
      geometry: { type: 'Point', coordinates: [128.602, 38.1901] }
    },
    {
      type: 'Feature',
      id: 1149,
      properties: {
        sagoDate: '2023-03-09',
        sagoNo: '20230052',
        sido: '경상북도',
        sigungu: '구미시',
        dong: '해평면',
        addr: '해평면 낙성리 58-3',
        fullAdr: '경상북도 구미시 해평면 해평면 낙성리 58-3',
        SEC_CD: '47190340',
        sagoLon: '128.393',
        sagoLat: '36.1942',
        sinkWidth: '0.1',
        sinkExtend: '0.05',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-03-14'
      },
      geometry: { type: 'Point', coordinates: [128.393, 36.1942] }
    },
    {
      type: 'Feature',
      id: 1150,
      properties: {
        sagoDate: '2023-03-14',
        sagoNo: '20230054',
        sido: '경상북도',
        sigungu: '구미시',
        dong: '원평동',
        addr: '원평동 190-4',
        fullAdr: '경상북도 구미시 원평동 원평동 190-4',
        SEC_CD: '47190101',
        sagoLon: '128.335',
        sagoLat: '36.1318',
        sinkWidth: '0.03',
        sinkExtend: '0.05',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-03-15'
      },
      geometry: { type: 'Point', coordinates: [128.335, 36.1318] }
    },
    {
      type: 'Feature',
      id: 1151,
      properties: {
        sagoDate: '2023-03-22',
        sagoNo: '20230083',
        sido: '강원특별자치도',
        sigungu: '고성군',
        dong: '토성면',
        addr: '도원리 산148-3',
        fullAdr: '강원특별자치도 고성군 토성면 도원리 산148-3',
        SEC_CD: '51820330',
        sagoLon: '128.493',
        sagoLat: '38.2702',
        sinkWidth: '0.3',
        sinkExtend: '0.3',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail: '하수관로 시공 후 다짐 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '2500000',
        trFnDate: '2023-03-23'
      },
      geometry: { type: 'Point', coordinates: [128.493, 38.2702] }
    },
    {
      type: 'Feature',
      id: 1152,
      properties: {
        sagoDate: '2023-03-17',
        sagoNo: '20230087',
        sido: '강원특별자치도',
        sigungu: '영월군',
        dong: '상동읍',
        addr: '구래리 98-60',
        fullAdr: '강원특별자치도 영월군 상동읍 구래리 98-60',
        SEC_CD: '51750253',
        sagoLon: '128.837',
        sagoLat: '37.1377',
        sinkWidth: '12',
        sinkExtend: '12',
        sinkDepth: '0.3',
        grdKind: '기타',
        sagoDetail: '대형차량 통행 등 지속적인 침하·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '19027000',
        trFnDate: '2023-05-30'
      },
      geometry: { type: 'Point', coordinates: [128.837, 37.1377] }
    },
    {
      type: 'Feature',
      id: 1153,
      properties: {
        sagoDate: '2023-03-02',
        sagoNo: '20230137',
        sido: '광주광역시',
        sigungu: '남구',
        dong: '봉선동',
        addr: '봉선동164-14',
        fullAdr: '광주광역시 남구 봉선동 봉선동164-14',
        SEC_CD: '29155115',
        sagoLon: '126.914',
        sagoLat: '35.1269',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-03-03'
      },
      geometry: { type: 'Point', coordinates: [126.914, 35.1269] }
    },
    {
      type: 'Feature',
      id: 1154,
      properties: {
        sagoDate: '2023-03-14',
        sagoNo: '20230138',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '화정동',
        addr: '화정동1035',
        fullAdr: '광주광역시 서구 화정동 화정동1035',
        SEC_CD: '29140119',
        sagoLon: '126.882',
        sagoLat: '35.1402',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-03-28'
      },
      geometry: { type: 'Point', coordinates: [126.882, 35.1402] }
    },
    {
      type: 'Feature',
      id: 1155,
      properties: {
        sagoDate: '2023-04-04',
        sagoNo: '20230017',
        sido: '경기도',
        sigungu: '고양시 일산서구',
        dong: '일산동',
        addr: '583-67',
        fullAdr: '경기도 고양시 일산서구 일산동 583-67',
        SEC_CD: '41287101',
        sagoLon: '126.777',
        sagoLat: '37.6887',
        sinkWidth: '2',
        sinkExtend: '2.2',
        sinkDepth: '1.4',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-04-04'
      },
      geometry: { type: 'Point', coordinates: [126.777, 37.6887] }
    },
    {
      type: 'Feature',
      id: 1156,
      properties: {
        sagoDate: '2023-04-04',
        sagoNo: '20230019',
        sido: '강원특별자치도',
        sigungu: '춘천시',
        dong: '효자동',
        addr: '159-21',
        fullAdr: '강원특별자치도 춘천시 효자동 159-21',
        SEC_CD: '51110112',
        sagoLon: '127.738',
        sagoLat: '37.8721',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '토사자연유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2023-04-04'
      },
      geometry: { type: 'Point', coordinates: [127.738, 37.8721] }
    },
    {
      type: 'Feature',
      id: 1157,
      properties: {
        sagoDate: '2023-04-11',
        sagoNo: '20230021',
        sido: '부산광역시',
        sigungu: '사하구',
        dong: '장림동',
        addr: '1086-3번지 선 도로',
        fullAdr: '부산광역시 사하구 장림동 1086-3번지 선 도로',
        SEC_CD: '26380105',
        sagoLon: '128.953',
        sagoLat: '35.0779',
        sinkWidth: '0.8',
        sinkExtend: '1.3',
        sinkDepth: '0.4',
        grdKind: '기타',
        sagoDetail: '오수맨홀 주변 옹벽구간 다짐(되메우기)불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-04-13'
      },
      geometry: { type: 'Point', coordinates: [128.953, 35.0779] }
    },
    {
      type: 'Feature',
      id: 1158,
      properties: {
        sagoDate: '2023-04-06',
        sagoNo: '20230022',
        sido: '서울특별시',
        sigungu: '서대문구',
        dong: '충정로3가',
        addr: '368',
        fullAdr: '서울특별시 서대문구 충정로3가 368',
        SEC_CD: '11410102',
        sagoLon: '126.963',
        sagoLat: '37.5599',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '다짐 불량 추정(흙마대 다수 매립)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-04-08'
      },
      geometry: { type: 'Point', coordinates: [126.963, 37.5599] }
    },
    {
      type: 'Feature',
      id: 1159,
      properties: {
        sagoDate: '2023-04-12',
        sagoNo: '20230023',
        sido: '서울특별시',
        sigungu: '송파구',
        dong: '풍납동',
        addr: '올림픽대로(공항방향) 천호대교 끝 300m 4차로 우측',
        fullAdr:
          '서울특별시 송파구 풍납동 올림픽대로(공항방향) 천호대교 끝 300m 4차로 우측',
        SEC_CD: '11710103',
        sagoLon: '127.116',
        sagoLat: '37.5391',
        sinkWidth: '2',
        sinkExtend: '1.5',
        sinkDepth: '2.5',
        grdKind: '',
        sagoDetail:
          '공사 인접 구간으로 지반 불안정 상태에서 우천으로 인한 토사 유실·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-04-12'
      },
      geometry: { type: 'Point', coordinates: [127.116, 37.5391] }
    },
    {
      type: 'Feature',
      id: 1160,
      properties: {
        sagoDate: '2023-04-14',
        sagoNo: '20230053',
        sido: '경상북도',
        sigungu: '구미시',
        dong: '구포동',
        addr: '구포동 645-1',
        fullAdr: '경상북도 구미시 구포동 구포동 645-1',
        SEC_CD: '47190129',
        sagoLon: '128.41',
        sagoLat: '36.1278',
        sinkWidth: '1',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-04-14'
      },
      geometry: { type: 'Point', coordinates: [128.41, 36.1278] }
    },
    {
      type: 'Feature',
      id: 1161,
      properties: {
        sagoDate: '2023-04-27',
        sagoNo: '20230065',
        sido: '경상남도',
        sigungu: '진주시',
        dong: '하대동',
        addr: '하대동 169-1',
        fullAdr: '경상남도 진주시 하대동 하대동 169-1',
        SEC_CD: '48170122',
        sagoLon: '128.127',
        sagoLat: '35.1946',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '폐수관 파손으로 인한 토사 유실로 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '9000000',
        trFnDate: '2023-04-27'
      },
      geometry: { type: 'Point', coordinates: [128.127, 35.1946] }
    },
    {
      type: 'Feature',
      id: 1162,
      properties: {
        sagoDate: '2023-04-12',
        sagoNo: '20230068',
        sido: '경상남도',
        sigungu: '사천시',
        dong: '곤양면',
        addr: '흥사리 273번지',
        fullAdr: '경상남도 사천시 곤양면 흥사리 273번지',
        SEC_CD: '48240350',
        sagoLon: '128.015',
        sagoLat: '35.092',
        sinkWidth: '2.5',
        sinkExtend: '2.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '다짐(되메우기) 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-10-23'
      },
      geometry: { type: 'Point', coordinates: [128.015, 35.092] }
    },
    {
      type: 'Feature',
      id: 1163,
      properties: {
        sagoDate: '2023-04-19',
        sagoNo: '20230084',
        sido: '강원특별자치도',
        sigungu: '고성군',
        dong: '토성면',
        addr: '봉포리 377번지',
        fullAdr: '강원특별자치도 고성군 토성면 봉포리 377번지',
        SEC_CD: '51820330',
        sagoLon: '128.561',
        sagoLat: '38.2491',
        sinkWidth: '0.5',
        sinkExtend: '30',
        sinkDepth: '0.1',
        grdKind: '기타',
        sagoDetail: '상수관로 시공 후 다짐 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '3500000',
        trFnDate: '2023-05-10'
      },
      geometry: { type: 'Point', coordinates: [128.561, 38.2491] }
    },
    {
      type: 'Feature',
      id: 1164,
      properties: {
        sagoDate: '2023-04-24',
        sagoNo: '20230088',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '장덕동',
        addr: '992-8',
        fullAdr: '광주광역시 광산구 장덕동 992-8',
        SEC_CD: '29200123',
        sagoLon: '126.809',
        sagoLat: '35.1887',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수관 연결부 이격으로 인한 토사유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-05-15'
      },
      geometry: { type: 'Point', coordinates: [126.809, 35.1887] }
    },
    {
      type: 'Feature',
      id: 1165,
      properties: {
        sagoDate: '2023-04-20',
        sagoNo: '20230110',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '양동',
        addr: '295',
        fullAdr: '광주광역시 서구 양동 295',
        SEC_CD: '29140104',
        sagoLon: '126.899',
        sagoLat: '35.1548',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-04-21'
      },
      geometry: { type: 'Point', coordinates: [126.899, 35.1548] }
    },
    {
      type: 'Feature',
      id: 1166,
      properties: {
        sagoDate: '2023-05-04',
        sagoNo: '20230025',
        sido: '강원특별자치도',
        sigungu: '속초시',
        dong: '영랑동',
        addr: '148-38',
        fullAdr: '강원특별자치도 속초시 영랑동 148-38',
        SEC_CD: '51210101',
        sagoLon: '128.594',
        sagoLat: '38.2175',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '3',
        grdKind: '기타',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-05-05'
      },
      geometry: { type: 'Point', coordinates: [128.594, 38.2175] }
    },
    {
      type: 'Feature',
      id: 1167,
      properties: {
        sagoDate: '2023-05-06',
        sagoNo: '20230026',
        sido: '서울특별시',
        sigungu: '중랑구',
        dong: '신내동',
        addr: '63-17(중랑공영차고지)',
        fullAdr: '서울특별시 중랑구 신내동 63-17(중랑공영차고지)',
        SEC_CD: '11260105',
        sagoLon: '127.11',
        sagoLat: '37.6101',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.6',
        grdKind: '기타',
        sagoDetail:
          '신내4 신내역로 우회전차로 신설공사구간 내··우천으로 인한 우수배수로 미확보로 물길이 보도쪽으로 형성 보도 침하 발생',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-05-06'
      },
      geometry: { type: 'Point', coordinates: [127.11, 37.6101] }
    },
    {
      type: 'Feature',
      id: 1168,
      properties: {
        sagoDate: '2023-05-09',
        sagoNo: '20230028',
        sido: '경기도',
        sigungu: '포천시',
        dong: '화현면',
        addr: '화현리 309-1번지 일원',
        fullAdr: '경기도 포천시 화현면 화현리 309-1번지 일원',
        SEC_CD: '41650410',
        sagoLon: '127.3',
        sagoLat: '37.9093',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '조사 필요',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-07-31'
      },
      geometry: { type: 'Point', coordinates: [127.3, 37.9093] }
    },
    {
      type: 'Feature',
      id: 1169,
      properties: {
        sagoDate: '2023-05-10',
        sagoNo: '20230029',
        sido: '대구광역시',
        sigungu: '북구',
        dong: '팔달동',
        addr: '524-32',
        fullAdr: '대구광역시 북구 팔달동 524-32',
        SEC_CD: '27230128',
        sagoLon: '128.548',
        sagoLat: '35.8973',
        sinkWidth: '3',
        sinkExtend: '2',
        sinkDepth: '4.2',
        grdKind: '',
        sagoDetail:
          '위치:북구 팔달동 524-32·누수일시:2023년5월10일·누수관로:하수박스(1.2m x 1.2m)·누수원인:노후로 인한 누수',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '30000000',
        trFnDate: '2023-05-11'
      },
      geometry: { type: 'Point', coordinates: [128.548, 35.8973] }
    },
    {
      type: 'Feature',
      id: 1170,
      properties: {
        sagoDate: '2023-05-08',
        sagoNo: '20230030',
        sido: '경기도',
        sigungu: '부천시',
        dong: '중동',
        addr: '1033-5',
        fullAdr: '경기도 부천시 중동 1033-5',
        SEC_CD: '41192108',
        sagoLon: '126.765',
        sagoLat: '37.5048',
        sinkWidth: '2',
        sinkExtend: '5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '우천으로 인한 보도 침하 발생 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-05-09'
      },
      geometry: { type: 'Point', coordinates: [126.765, 37.5048] }
    },
    {
      type: 'Feature',
      id: 1171,
      properties: {
        sagoDate: '2023-05-20',
        sagoNo: '20230031',
        sido: '경기도',
        sigungu: '군포시',
        dong: '당정동',
        addr: '994-2',
        fullAdr: '경기도 군포시 당정동 994-2',
        SEC_CD: '41410102',
        sagoLon: '126.954',
        sagoLat: '37.347',
        sinkWidth: '2.2',
        sinkExtend: '2.2',
        sinkDepth: '2.9',
        grdKind: '기타',
        sagoDetail: '하수관로 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-05-25'
      },
      geometry: { type: 'Point', coordinates: [126.954, 37.347] }
    },
    {
      type: 'Feature',
      id: 1172,
      properties: {
        sagoDate: '2023-05-31',
        sagoNo: '20230032',
        sido: '경기도',
        sigungu: '고양시 일산서구',
        dong: '대화동',
        addr: '2607-4',
        fullAdr: '경기도 고양시 일산서구 대화동 2607-4',
        SEC_CD: '41287104',
        sagoLon: '126.748',
        sagoLat: '37.6656',
        sinkWidth: '4',
        sinkExtend: '6',
        sinkDepth: '0.25',
        grdKind: '',
        sagoDetail: 'GTX 공사 현장으로 원인 파악 중',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-07-31'
      },
      geometry: { type: 'Point', coordinates: [126.748, 37.6656] }
    },
    {
      type: 'Feature',
      id: 1173,
      properties: {
        sagoDate: '2023-05-26',
        sagoNo: '20230033',
        sido: '경기도',
        sigungu: '수원시 권선구',
        dong: '당수동',
        addr: '376-7',
        fullAdr: '경기도 수원시 권선구 당수동 376-7',
        SEC_CD: '41113141',
        sagoLon: '126.95',
        sagoLat: '37.2827',
        sinkWidth: '4.5',
        sinkExtend: '3',
        sinkDepth: '0.06',
        grdKind: '',
        sagoDetail:
          '비개착공법으로 도로하부 추진 중 공극발생 등에 의한 원인 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-07-07'
      },
      geometry: { type: 'Point', coordinates: [126.95, 37.2827] }
    },
    {
      type: 'Feature',
      id: 1174,
      properties: {
        sagoDate: '2023-05-31',
        sagoNo: '20230034',
        sido: '경기도',
        sigungu: '고양시 덕양구',
        dong: '관산동',
        addr: '910-55',
        fullAdr: '경기도 고양시 덕양구 관산동 910-55',
        SEC_CD: '41281118',
        sagoLon: '126.865',
        sagoLat: '37.6878',
        sinkWidth: '1.5',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '소화전 누수',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-06-01'
      },
      geometry: { type: 'Point', coordinates: [126.865, 37.6878] }
    },
    {
      type: 'Feature',
      id: 1175,
      properties: {
        sagoDate: '2023-05-20',
        sagoNo: '20230036',
        sido: '경기도',
        sigungu: '부천시',
        dong: '괴안동',
        addr: '100-29',
        fullAdr: '경기도 부천시 괴안동 100-29',
        SEC_CD: '41194104',
        sagoLon: '126.811',
        sagoLat: '37.4801',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '하수관로 파손으로 인한 침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-05-23'
      },
      geometry: { type: 'Point', coordinates: [126.811, 37.4801] }
    },
    {
      type: 'Feature',
      id: 1176,
      properties: {
        sagoDate: '2023-05-19',
        sagoNo: '20230050',
        sido: '경상북도',
        sigungu: '경주시',
        dong: '동천동',
        addr: '동천로 78번길 6',
        fullAdr: '경상북도 경주시 동천동 동천로 78번길 6',
        SEC_CD: '47130126',
        sagoLon: '129.227',
        sagoLat: '35.8547',
        sinkWidth: '0.5',
        sinkExtend: '3',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '개인배수설비불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-05-19'
      },
      geometry: { type: 'Point', coordinates: [129.227, 35.8547] }
    },
    {
      type: 'Feature',
      id: 1177,
      properties: {
        sagoDate: '2023-05-02',
        sagoNo: '20230060',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '둔산동',
        addr: '1142번지',
        fullAdr: '대전광역시 서구 둔산동 1142번지',
        SEC_CD: '30170112',
        sagoLon: '127.38',
        sagoLat: '36.345',
        sinkWidth: '1.5',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '충척층',
        sagoDetail: '흄관 접합불량으로 인한 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '3260000',
        trFnDate: '2023-05-04'
      },
      geometry: { type: 'Point', coordinates: [127.38, 36.345] }
    },
    {
      type: 'Feature',
      id: 1178,
      properties: {
        sagoDate: '2023-05-04',
        sagoNo: '20230061',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '탄방동',
        addr: '63-12번지',
        fullAdr: '대전광역시 서구 탄방동 63-12번지',
        SEC_CD: '30170106',
        sagoLon: '127.393',
        sagoLat: '36.3442',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '충척층',
        sagoDetail: '흄관 접합불량 및 맨홀 접합불량으로 인한 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '3500000',
        trFnDate: '2023-05-08'
      },
      geometry: { type: 'Point', coordinates: [127.393, 36.3442] }
    },
    {
      type: 'Feature',
      id: 1179,
      properties: {
        sagoDate: '2023-05-12',
        sagoNo: '20230062',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '괴정동',
        addr: '67-7번지',
        fullAdr: '대전광역시 서구 괴정동 67-7번지',
        SEC_CD: '30170108',
        sagoLon: '127.38',
        sagoLat: '36.3407',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '0.5',
        grdKind: '충척층',
        sagoDetail: '흄관 접합불량으로 인한 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '2500000',
        trFnDate: '2023-05-15'
      },
      geometry: { type: 'Point', coordinates: [127.38, 36.3407] }
    },
    {
      type: 'Feature',
      id: 1180,
      properties: {
        sagoDate: '2023-05-10',
        sagoNo: '20230078',
        sido: '경기도',
        sigungu: '이천시',
        dong: '호법면',
        addr: '동산리 309',
        fullAdr: '경기도 이천시 호법면 동산리 309',
        SEC_CD: '41500330',
        sagoLon: '127.401',
        sagoLat: '37.2128',
        sinkWidth: '3',
        sinkExtend: '43',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '관로 침하로 인한 도로침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-05-15'
      },
      geometry: { type: 'Point', coordinates: [127.401, 37.2128] }
    },
    {
      type: 'Feature',
      id: 1181,
      properties: {
        sagoDate: '2023-05-16',
        sagoNo: '20230081',
        sido: '대구광역시',
        sigungu: '달성군',
        dong: '다사읍',
        addr: '죽곡리 577-2',
        fullAdr: '대구광역시 달성군 다사읍 죽곡리 577-2',
        SEC_CD: '27710256',
        sagoLon: '128.467',
        sagoLat: '35.8452',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '빗물유입에 따른 토사유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-05-16'
      },
      geometry: { type: 'Point', coordinates: [128.467, 35.8452] }
    },
    {
      type: 'Feature',
      id: 1182,
      properties: {
        sagoDate: '2023-05-31',
        sagoNo: '20230082',
        sido: '대구광역시',
        sigungu: '달성군',
        dong: '유가읍',
        addr: '도의리 1091-3',
        fullAdr: '대구광역시 달성군 유가읍 도의리 1091-3',
        SEC_CD: '27710259',
        sagoLon: '128.451',
        sagoLat: '35.6621',
        sinkWidth: '0.7',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '원인미상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-05-31'
      },
      geometry: { type: 'Point', coordinates: [128.451, 35.6621] }
    },
    {
      type: 'Feature',
      id: 1183,
      properties: {
        sagoDate: '2023-05-18',
        sagoNo: '20230089',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '오선동',
        addr: '548-5',
        fullAdr: '광주광역시 광산구 오선동 548-5',
        SEC_CD: '29200113',
        sagoLon: '126.813',
        sagoLat: '35.2061',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수관 연결부 이격으로 인한 토사유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-05-24'
      },
      geometry: { type: 'Point', coordinates: [126.813, 35.2061] }
    },
    {
      type: 'Feature',
      id: 1184,
      properties: {
        sagoDate: '2023-05-05',
        sagoNo: '20230096',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '학동',
        addr: '994',
        fullAdr: '광주광역시 동구 학동 994',
        SEC_CD: '47250380',
        sagoLon: '127.962',
        sagoLat: '36.3036',
        sinkWidth: '0.3',
        sinkExtend: '0.3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-05-05'
      },
      geometry: { type: 'Point', coordinates: [127.962, 36.3036] }
    },
    {
      type: 'Feature',
      id: 1185,
      properties: {
        sagoDate: '2023-05-13',
        sagoNo: '20230097',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '화정동',
        addr: '863',
        fullAdr: '광주광역시 서구 화정동 863',
        SEC_CD: '29140119',
        sagoLon: '126.875',
        sagoLat: '35.1427',
        sinkWidth: '0.4',
        sinkExtend: '0.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-05-13'
      },
      geometry: { type: 'Point', coordinates: [126.875, 35.1427] }
    },
    {
      type: 'Feature',
      id: 1186,
      properties: {
        sagoDate: '2023-05-20',
        sagoNo: '20230098',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '월곡동',
        addr: '489-20',
        fullAdr: '광주광역시 광산구 월곡동 489-20',
        SEC_CD: '29200128',
        sagoLon: '126.808',
        sagoLat: '35.1754',
        sinkWidth: '0.5',
        sinkExtend: '0.4',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-05-20'
      },
      geometry: { type: 'Point', coordinates: [126.808, 35.1754] }
    },
    {
      type: 'Feature',
      id: 1187,
      properties: {
        sagoDate: '2023-05-19',
        sagoNo: '20230103',
        sido: '부산광역시',
        sigungu: '부산진구',
        dong: '가야동',
        addr: '가야대로532',
        fullAdr: '부산광역시 부산진구 가야동 가야대로532',
        SEC_CD: '26230110',
        sagoLon: '129.03',
        sagoLat: '35.1535',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.3',
        grdKind: '기타',
        sagoDetail: '연약지반 및 다짐불량으로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '2658000',
        trFnDate: '2023-05-21'
      },
      geometry: { type: 'Point', coordinates: [129.03, 35.1535] }
    },
    {
      type: 'Feature',
      id: 1188,
      properties: {
        sagoDate: '2023-05-04',
        sagoNo: '20230139',
        sido: '광주광역시',
        sigungu: '남구',
        dong: '진월동',
        addr: '진월동291-1 (서문대로701)',
        fullAdr: '광주광역시 남구 진월동 진월동291-1 (서문대로701)',
        SEC_CD: '29155108',
        sagoLon: '126.898',
        sagoLat: '35.1219',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-05-04'
      },
      geometry: { type: 'Point', coordinates: [126.898, 35.1219] }
    },
    {
      type: 'Feature',
      id: 1189,
      properties: {
        sagoDate: '2023-06-06',
        sagoNo: '20230035',
        sido: '충청북도',
        sigungu: '청주시 흥덕구',
        dong: '복대동',
        addr: '847-6',
        fullAdr: '충청북도 청주시 흥덕구 복대동 847-6',
        SEC_CD: '43113114',
        sagoLon: '127.453',
        sagoLat: '36.6351',
        sinkWidth: '0.5',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '측구에 매설된 하수관 파손으로 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '5000000',
        trFnDate: '2023-06-13'
      },
      geometry: { type: 'Point', coordinates: [127.453, 36.6351] }
    },
    {
      type: 'Feature',
      id: 1190,
      properties: {
        sagoDate: '2023-06-11',
        sagoNo: '20230037',
        sido: '서울특별시',
        sigungu: '중랑구',
        dong: '중화동',
        addr: '328-45',
        fullAdr: '서울특별시 중랑구 중화동 328-45',
        SEC_CD: '11260103',
        sagoLon: '127.073',
        sagoLat: '37.5993',
        sinkWidth: '2',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '충척층',
        sagoDetail:
          '건축공사중 주변 하수관로(D600mm) 이음부 이격 및 누수로 인한 토사유실 발생',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1000000',
        trFnDate: '2023-06-11'
      },
      geometry: { type: 'Point', coordinates: [127.073, 37.5993] }
    },
    {
      type: 'Feature',
      id: 1191,
      properties: {
        sagoDate: '2023-06-13',
        sagoNo: '20230038',
        sido: '경기도',
        sigungu: '시흥시',
        dong: '월곶동',
        addr: '1004-5 인근',
        fullAdr: '경기도 시흥시 월곶동 1004-5 인근',
        SEC_CD: '41390131',
        sagoLon: '126.741',
        sagoLat: '37.3902',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '흙막이 벽체 누수 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-06-13'
      },
      geometry: { type: 'Point', coordinates: [126.741, 37.3902] }
    },
    {
      type: 'Feature',
      id: 1192,
      properties: {
        sagoDate: '2023-06-18',
        sagoNo: '20230039',
        sido: '경기도',
        sigungu: '시흥시',
        dong: '월곶동',
        addr: '1004-4번지 외 3필지',
        fullAdr: '경기도 시흥시 월곶동 1004-4번지 외 3필지',
        SEC_CD: '41390131',
        sagoLon: '126.741',
        sagoLat: '37.39',
        sinkWidth: '4',
        sinkExtend: '10',
        sinkDepth: '0.4',
        grdKind: '',
        sagoDetail: '6.13.에 발생한 누수가 지속되어 추가침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-06-30'
      },
      geometry: { type: 'Point', coordinates: [126.741, 37.39] }
    },
    {
      type: 'Feature',
      id: 1193,
      properties: {
        sagoDate: '2023-06-23',
        sagoNo: '20230040',
        sido: '경상남도',
        sigungu: '합천군',
        dong: '대병면',
        addr: '신성동길 1· 하금 식육식당 앞 소재지 도로',
        fullAdr:
          '경상남도 합천군 대병면 신성동길 1· 하금 식육식당 앞 소재지 도로',
        SEC_CD: '48890450',
        sagoLon: '128.016',
        sagoLat: '35.5227',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '1.2',
        grdKind: '',
        sagoDetail: '하수맨홀 설치 후 장기간에 걸친 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-06-30'
      },
      geometry: { type: 'Point', coordinates: [128.016, 35.5227] }
    },
    {
      type: 'Feature',
      id: 1194,
      properties: {
        sagoDate: '2023-06-26',
        sagoNo: '20230041',
        sido: '서울특별시',
        sigungu: '구로구',
        dong: '온수동',
        addr: '65-3',
        fullAdr: '서울특별시 구로구 온수동 65-3',
        SEC_CD: '11530110',
        sagoLon: '126.818',
        sagoLat: '37.4932',
        sinkWidth: '0.2',
        sinkExtend: '0.65',
        sinkDepth: '0.8',
        grdKind: '기타',
        sagoDetail:
          '하수박스 파손으로 인한 지반침하로 다리가 빠져 부상자(다리 타박상) 1명 발생함',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '3000000',
        trFnDate: '2023-06-27'
      },
      geometry: { type: 'Point', coordinates: [126.818, 37.4932] }
    },
    {
      type: 'Feature',
      id: 1195,
      properties: {
        sagoDate: '2023-06-26',
        sagoNo: '20230043',
        sido: '경기도',
        sigungu: '화성시',
        dong: '반월동',
        addr: '736',
        fullAdr: '경기도 화성시 반월동 736',
        SEC_CD: '41590120',
        sagoLon: '127.062',
        sagoLat: '37.2378',
        sinkWidth: '0.7',
        sinkExtend: '18',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail:
          '시공 부실로 인한 1차 침하 발생· 이후 추가 유실되면서 기존 상수관 손상시켜 유실 깊이 증가',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-07-24'
      },
      geometry: { type: 'Point', coordinates: [127.062, 37.2378] }
    },
    {
      type: 'Feature',
      id: 1196,
      properties: {
        sagoDate: '2023-06-28',
        sagoNo: '20230044',
        sido: '강원특별자치도',
        sigungu: '속초시',
        dong: '영랑동',
        addr: '148-38',
        fullAdr: '강원특별자치도 속초시 영랑동 148-38',
        SEC_CD: '51210101',
        sagoLon: '128.594',
        sagoLat: '38.2173',
        sinkWidth: '4',
        sinkExtend: '1.5',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-07-01'
      },
      geometry: { type: 'Point', coordinates: [128.594, 38.2173] }
    },
    {
      type: 'Feature',
      id: 1197,
      properties: {
        sagoDate: '2023-06-28',
        sagoNo: '20230045',
        sido: '광주광역시',
        sigungu: '북구',
        dong: '풍향동',
        addr: '599-77',
        fullAdr: '광주광역시 북구 풍향동 599-77',
        SEC_CD: '29170111',
        sagoLon: '126.921',
        sagoLat: '35.1645',
        sinkWidth: '5.5',
        sinkExtend: '4',
        sinkDepth: '1.8',
        grdKind: '',
        sagoDetail:
          '하수도 파형관 바닥 세굴로 인한 하수도 누수 발생 후 토사유출로 상수도(D350mm)관 접합부 누수 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-06-30'
      },
      geometry: { type: 'Point', coordinates: [126.921, 35.1645] }
    },
    {
      type: 'Feature',
      id: 1198,
      properties: {
        sagoDate: '2023-06-27',
        sagoNo: '20230046',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '서석동',
        addr: '421-1',
        fullAdr: '광주광역시 동구 서석동 421-1',
        SEC_CD: '29110118',
        sagoLon: '126.926',
        sagoLat: '35.1444',
        sinkWidth: '2',
        sinkExtend: '5',
        sinkDepth: '1.6',
        grdKind: '',
        sagoDetail: '집중호우 및 하수박스 누수',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-06-28'
      },
      geometry: { type: 'Point', coordinates: [126.926, 35.1444] }
    },
    {
      type: 'Feature',
      id: 1199,
      properties: {
        sagoDate: '2023-06-27',
        sagoNo: '20230047',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '서석동',
        addr: '421-1',
        fullAdr: '광주광역시 동구 서석동 421-1',
        SEC_CD: '29110118',
        sagoLon: '126.926',
        sagoLat: '35.1444',
        sinkWidth: '6',
        sinkExtend: '5',
        sinkDepth: '1.9',
        grdKind: '',
        sagoDetail: '집중호우 및 하수박스 누수',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-06-28'
      },
      geometry: { type: 'Point', coordinates: [126.926, 35.1444] }
    },
    {
      type: 'Feature',
      id: 1200,
      properties: {
        sagoDate: '2023-06-19',
        sagoNo: '20230049',
        sido: '경상북도',
        sigungu: '칠곡군',
        dong: '왜관읍',
        addr: '776-94번지 일원',
        fullAdr: '경상북도 칠곡군 왜관읍 776-94번지 일원',
        SEC_CD: '47850250',
        sagoLon: '128.397',
        sagoLat: '35.9867',
        sinkWidth: '1.5',
        sinkExtend: '1.7',
        sinkDepth: '1.6',
        grdKind: '기타',
        sagoDetail: '우수관 손상으로 인한 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-07-31'
      },
      geometry: { type: 'Point', coordinates: [128.397, 35.9867] }
    },
    {
      type: 'Feature',
      id: 1201,
      properties: {
        sagoDate: '2023-06-26',
        sagoNo: '20230051',
        sido: '경상북도',
        sigungu: '경주시',
        dong: '인왕동',
        addr: '76',
        fullAdr: '경상북도 경주시 인왕동 76',
        SEC_CD: '47130112',
        sagoLon: '129.228',
        sagoLat: '35.8293',
        sinkWidth: '0.5',
        sinkExtend: '2',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '다짐 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-06-26'
      },
      geometry: { type: 'Point', coordinates: [129.228, 35.8293] }
    },
    {
      type: 'Feature',
      id: 1202,
      properties: {
        sagoDate: '2023-06-29',
        sagoNo: '20230055',
        sido: '강원특별자치도',
        sigungu: '춘천시',
        dong: '효자동',
        addr: '159-47',
        fullAdr: '강원특별자치도 춘천시 효자동 159-47',
        SEC_CD: '51110112',
        sagoLon: '127.737',
        sagoLat: '37.8729',
        sinkWidth: '0.5',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-06-30'
      },
      geometry: { type: 'Point', coordinates: [127.737, 37.8729] }
    },
    {
      type: 'Feature',
      id: 1203,
      properties: {
        sagoDate: '2023-06-01',
        sagoNo: '20230056',
        sido: '전북특별자치도',
        sigungu: '군산시',
        dong: '미원동',
        addr: '월명로 380-2',
        fullAdr: '전북특별자치도 군산시 미원동 월명로 380-2',
        SEC_CD: '52130129',
        sagoLon: '126.716',
        sagoLat: '35.9781',
        sinkWidth: '2',
        sinkExtend: '5',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail: '수로복개구간 상판 파손으로 인한 싱크홀발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-06-04'
      },
      geometry: { type: 'Point', coordinates: [126.716, 35.9781] }
    },
    {
      type: 'Feature',
      id: 1204,
      properties: {
        sagoDate: '2023-06-19',
        sagoNo: '20230057',
        sido: '전북특별자치도',
        sigungu: '군산시',
        dong: '나운동',
        addr: '한밭1길 22',
        fullAdr: '전북특별자치도 군산시 나운동 한밭1길 22',
        SEC_CD: '52130144',
        sagoLon: '126.693',
        sagoLat: '35.9614',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail: '노후흄관 파손으로 인한 싱크홀발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-06-19'
      },
      geometry: { type: 'Point', coordinates: [126.693, 35.9614] }
    },
    {
      type: 'Feature',
      id: 1205,
      properties: {
        sagoDate: '2023-06-19',
        sagoNo: '20230063',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '가수원동',
        addr: '176-1',
        fullAdr: '대전광역시 서구 가수원동 176-1',
        SEC_CD: '30170114',
        sagoLon: '127.358',
        sagoLat: '36.3046',
        sinkWidth: '0.5',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '충척층',
        sagoDetail: '흄관 접합불량으로 인한 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '3000000',
        trFnDate: '2023-06-23'
      },
      geometry: { type: 'Point', coordinates: [127.358, 36.3046] }
    },
    {
      type: 'Feature',
      id: 1206,
      properties: {
        sagoDate: '2023-06-12',
        sagoNo: '20230064',
        sido: '서울특별시',
        sigungu: '도봉구',
        dong: '쌍문동',
        addr: '315-8',
        fullAdr: '서울특별시 도봉구 쌍문동 315-8',
        SEC_CD: '11320105',
        sagoLon: '127.029',
        sagoLat: '37.6509',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '4',
        grdKind: '기타',
        sagoDetail: '노후 하수관로 파손으로 인한 지반침하로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-06-12'
      },
      geometry: { type: 'Point', coordinates: [127.029, 37.6509] }
    },
    {
      type: 'Feature',
      id: 1207,
      properties: {
        sagoDate: '2023-06-10',
        sagoNo: '20230066',
        sido: '충청북도',
        sigungu: '충주시',
        dong: '목행동',
        addr: '850-46번지',
        fullAdr: '충청북도 충주시 목행동 850-46번지',
        SEC_CD: '43130119',
        sagoLon: '127.928',
        sagoLat: '37.0074',
        sinkWidth: '5',
        sinkExtend: '15',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail:
          '지하에 매설된 우수관(1000mm)이 방청 처리가 되어있지 않아 부식으로 인해 손상되어 지반 침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '10000000',
        trFnDate: '2023-06-28'
      },
      geometry: { type: 'Point', coordinates: [127.928, 37.0074] }
    },
    {
      type: 'Feature',
      id: 1208,
      properties: {
        sagoDate: '2023-06-09',
        sagoNo: '20230070',
        sido: '경기도',
        sigungu: '안산시 단원구',
        dong: '초지동',
        addr: '초지동 634· 고가도로 -&gt; 주유삼거리 방향 1차선(위도 : 37.30232139· 경도 : 126.80345991)',
        fullAdr:
          '경기도 안산시 단원구 초지동 초지동 634· 고가도로 -&gt; 주유삼거리 방향 1차선(위도 : 37.30232139· 경도 : 126.80345991)',
        SEC_CD: '41273107',
        sagoLon: '126.803',
        sagoLat: '37.3023',
        sinkWidth: '1.2',
        sinkExtend: '1.6',
        sinkDepth: '0.6',
        grdKind: '',
        sagoDetail: '원인 파악 중',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2023-06-09'
      },
      geometry: { type: 'Point', coordinates: [126.803, 37.3023] }
    },
    {
      type: 'Feature',
      id: 1209,
      properties: {
        sagoDate: '2023-06-27',
        sagoNo: '20230071',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '산수동',
        addr: '133-1',
        fullAdr: '광주광역시 동구 산수동 133-1',
        SEC_CD: '29110110',
        sagoLon: '126.933',
        sagoLat: '35.1608',
        sinkWidth: '1.3',
        sinkExtend: '1.3',
        sinkDepth: '1.3',
        grdKind: '',
        sagoDetail: '노후 하수관로(1·000mm) 파손으로 인한 침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '40000000',
        trFnDate: '2023-06-29'
      },
      geometry: { type: 'Point', coordinates: [126.933, 35.1608] }
    },
    {
      type: 'Feature',
      id: 1210,
      properties: {
        sagoDate: '2023-06-26',
        sagoNo: '20230073',
        sido: '경기도',
        sigungu: '이천시',
        dong: '중리동',
        addr: '378· 이천시청 주변 평산빌딩 앞',
        fullAdr: '경기도 이천시 중리동 378· 이천시청 주변 평산빌딩 앞',
        SEC_CD: '41500103',
        sagoLon: '127.437',
        sagoLat: '37.2753',
        sinkWidth: '1.2',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '도로 공사 당시 빗물받이 부실 시공으로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '7000000',
        trFnDate: '2023-06-28'
      },
      geometry: { type: 'Point', coordinates: [127.437, 37.2753] }
    },
    {
      type: 'Feature',
      id: 1211,
      properties: {
        sagoDate: '2023-06-28',
        sagoNo: '20230076',
        sido: '경기도',
        sigungu: '이천시',
        dong: '창전동',
        addr: '147· 황수철 피자 앞',
        fullAdr: '경기도 이천시 창전동 147· 황수철 피자 앞',
        SEC_CD: '41500101',
        sagoLon: '127.447',
        sagoLat: '37.2816',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '우수관로 연결부위 부실 시공으로 인한 관파손에 따른 지반 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '7000000',
        trFnDate: '2023-07-05'
      },
      geometry: { type: 'Point', coordinates: [127.447, 37.2816] }
    },
    {
      type: 'Feature',
      id: 1212,
      properties: {
        sagoDate: '2023-06-30',
        sagoNo: '20230077',
        sido: '경기도',
        sigungu: '이천시',
        dong: '백사면',
        addr: '모전리 968-13',
        fullAdr: '경기도 이천시 백사면 모전리 968-13',
        SEC_CD: '41500320',
        sagoLon: '127.464',
        sagoLat: '37.3072',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.3',
        grdKind: '',
        sagoDetail: '하수관로 주변 토사 유출로 인한 지반 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '5000000',
        trFnDate: '2023-07-05'
      },
      geometry: { type: 'Point', coordinates: [127.464, 37.3072] }
    },
    {
      type: 'Feature',
      id: 1213,
      properties: {
        sagoDate: '2023-06-30',
        sagoNo: '20230079',
        sido: '경기도',
        sigungu: '이천시',
        dong: '관고동',
        addr: '134-9',
        fullAdr: '경기도 이천시 관고동 134-9',
        SEC_CD: '41500102',
        sagoLon: '127.432',
        sagoLat: '37.2887',
        sinkWidth: '2',
        sinkExtend: '13',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '관로 침하로 인한 도로침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-07-03'
      },
      geometry: { type: 'Point', coordinates: [127.432, 37.2887] }
    },
    {
      type: 'Feature',
      id: 1214,
      properties: {
        sagoDate: '2023-06-30',
        sagoNo: '20230090',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '월곡동',
        addr: '579-7',
        fullAdr: '광주광역시 광산구 월곡동 579-7',
        SEC_CD: '29200128',
        sagoLon: '126.817',
        sagoLat: '35.1687',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수관 연결부 이격으로 인한 토사유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-07-03'
      },
      geometry: { type: 'Point', coordinates: [126.817, 35.1687] }
    },
    {
      type: 'Feature',
      id: 1215,
      properties: {
        sagoDate: '2023-06-13',
        sagoNo: '20230099',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '학동',
        addr: '114-5',
        fullAdr: '광주광역시 동구 학동 114-5',
        SEC_CD: '29110122',
        sagoLon: '126.921',
        sagoLat: '35.142',
        sinkWidth: '0.2',
        sinkExtend: '0.2',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-06-13'
      },
      geometry: { type: 'Point', coordinates: [126.921, 35.142] }
    },
    {
      type: 'Feature',
      id: 1216,
      properties: {
        sagoDate: '2023-06-17',
        sagoNo: '20230100',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '신가동',
        addr: '930-11',
        fullAdr: '광주광역시 광산구 신가동 930-11',
        SEC_CD: '29200120',
        sagoLon: '126.824',
        sagoLat: '35.1792',
        sinkWidth: '0.3',
        sinkExtend: '0.3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-06-17'
      },
      geometry: { type: 'Point', coordinates: [126.824, 35.1792] }
    },
    {
      type: 'Feature',
      id: 1217,
      properties: {
        sagoDate: '2023-06-30',
        sagoNo: '20230101',
        sido: '광주광역시',
        sigungu: '북구',
        dong: '두암동',
        addr: '594-1',
        fullAdr: '광주광역시 북구 두암동 594-1',
        SEC_CD: '29170113',
        sagoLon: '126.935',
        sagoLat: '35.1787',
        sinkWidth: '0.3',
        sinkExtend: '0.3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-07-01'
      },
      geometry: { type: 'Point', coordinates: [126.935, 35.1787] }
    },
    {
      type: 'Feature',
      id: 1218,
      properties: {
        sagoDate: '2023-06-11',
        sagoNo: '20230104',
        sido: '부산광역시',
        sigungu: '부산진구',
        dong: '부전동',
        addr: '서면로74',
        fullAdr: '부산광역시 부산진구 부전동 서면로74',
        SEC_CD: '26230103',
        sagoLon: '129.058',
        sagoLat: '35.1574',
        sinkWidth: '0.3',
        sinkExtend: '0.3',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail: '지하철 가시설 미인발로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '1464000',
        trFnDate: '2023-06-11'
      },
      geometry: { type: 'Point', coordinates: [129.058, 35.1574] }
    },
    {
      type: 'Feature',
      id: 1219,
      properties: {
        sagoDate: '2023-06-15',
        sagoNo: '20230106',
        sido: '전라남도',
        sigungu: '완도군',
        dong: '신지면',
        addr: '송곡리 60-2',
        fullAdr: '전라남도 완도군 신지면 송곡리 60-2',
        SEC_CD: '46890320',
        sagoLon: '126.792',
        sagoLat: '34.3392',
        sinkWidth: '2',
        sinkExtend: '6',
        sinkDepth: '0.5',
        grdKind: '기타',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-06-20'
      },
      geometry: { type: 'Point', coordinates: [126.792, 34.3392] }
    },
    {
      type: 'Feature',
      id: 1220,
      properties: {
        sagoDate: '2023-06-28',
        sagoNo: '20230109',
        sido: '광주광역시',
        sigungu: '북구',
        dong: '매곡동',
        addr: '468-2',
        fullAdr: '광주광역시 북구 매곡동 468-2',
        SEC_CD: '29170117',
        sagoLon: '126.898',
        sagoLat: '35.188',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관로 접합부 불량으로 인한 토사 유출로 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1000000',
        trFnDate: '2023-06-28'
      },
      geometry: { type: 'Point', coordinates: [126.898, 35.188] }
    },
    {
      type: 'Feature',
      id: 1221,
      properties: {
        sagoDate: '2023-06-15',
        sagoNo: '20230159',
        sido: '강원특별자치도',
        sigungu: '횡성군',
        dong: '우천면',
        addr: '우천면 우항리 758번지 동산연립 앞 도시계획도로(소로3-205호)',
        fullAdr:
          '강원특별자치도 횡성군 우천면 우천면 우항리 758번지 동산연립 앞 도시계획도로(소로3-205호)',
        SEC_CD: '51730310',
        sagoLon: '128.066',
        sagoLat: '37.4582',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '기타',
        sagoDetail: '우수관로 연결부 불량에 따른 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '3000000',
        trFnDate: '2023-06-30'
      },
      geometry: { type: 'Point', coordinates: [128.066, 37.4582] }
    },
    {
      type: 'Feature',
      id: 1222,
      properties: {
        sagoDate: '2023-07-09',
        sagoNo: '20230067',
        sido: '강원특별자치도',
        sigungu: '강릉시',
        dong: '포남동',
        addr: '852',
        fullAdr: '강원특별자치도 강릉시 포남동 852',
        SEC_CD: '51150110',
        sagoLon: '128.901',
        sagoLat: '37.7647',
        sinkWidth: '6',
        sinkExtend: '3',
        sinkDepth: '4',
        grdKind: '',
        sagoDetail: '하수관 손상에 따른 세굴',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-08-31'
      },
      geometry: { type: 'Point', coordinates: [128.901, 37.7647] }
    },
    {
      type: 'Feature',
      id: 1223,
      properties: {
        sagoDate: '2023-07-12',
        sagoNo: '20230069',
        sido: '서울특별시',
        sigungu: '노원구',
        dong: '공릉동',
        addr: '172-18',
        fullAdr: '서울특별시 노원구 공릉동 172-18',
        SEC_CD: '11350103',
        sagoLon: '127.083',
        sagoLat: '37.6313',
        sinkWidth: '1.8',
        sinkExtend: '1.2',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '1련 하수박스 외벽 파손에 따른 토사유출로 인한 보도 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-07-12'
      },
      geometry: { type: 'Point', coordinates: [127.083, 37.6313] }
    },
    {
      type: 'Feature',
      id: 1224,
      properties: {
        sagoDate: '2023-07-11',
        sagoNo: '20230072',
        sido: '강원특별자치도',
        sigungu: '원주시',
        dong: '개운동',
        addr: '452-6',
        fullAdr: '강원특별자치도 원주시 개운동 452-6',
        SEC_CD: '51130105',
        sagoLon: '127.948',
        sagoLat: '37.3336',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '2.8',
        grdKind: '기타',
        sagoDetail: '하수관로 교체작업 다짐불량으로 인하여 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '5000000',
        trFnDate: '2023-07-13'
      },
      geometry: { type: 'Point', coordinates: [127.948, 37.3336] }
    },
    {
      type: 'Feature',
      id: 1225,
      properties: {
        sagoDate: '2023-07-16',
        sagoNo: '20230092',
        sido: '대구광역시',
        sigungu: '북구',
        dong: '팔달동',
        addr: '89-6 인근',
        fullAdr: '대구광역시 북구 팔달동 89-6 인근',
        SEC_CD: '27230128',
        sagoLon: '128.545',
        sagoLat: '35.8977',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.7',
        grdKind: '',
        sagoDetail: '지하수로 인한 동공',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-07-26'
      },
      geometry: { type: 'Point', coordinates: [128.545, 35.8977] }
    },
    {
      type: 'Feature',
      id: 1226,
      properties: {
        sagoDate: '2023-07-17',
        sagoNo: '20230093',
        sido: '부산광역시',
        sigungu: '남구',
        dong: '대연동',
        addr: '수영로 경성대부경대역 3번 출구 앞 엘리베이터 일원',
        fullAdr:
          '부산광역시 남구 대연동 수영로 경성대부경대역 3번 출구 앞 엘리베이터 일원',
        SEC_CD: '26290106',
        sagoLon: '129.101',
        sagoLat: '35.1375',
        sinkWidth: '3.3',
        sinkExtend: '8',
        sinkDepth: '0.4',
        grdKind: '',
        sagoDetail:
          '확인 굴착 결과 : 엘리베이터 설치(2011년) 시 다짐불량에 따른 침하로 추정 (공동X· 하수시설 파손X)·도로침하에 따른 인명· 차량 등의 피해는 없으며· 금회 집중호우로 인해 단기간에 침하발생·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-12-27'
      },
      geometry: { type: 'Point', coordinates: [129.101, 35.1375] }
    },
    {
      type: 'Feature',
      id: 1227,
      properties: {
        sagoDate: '2023-07-19',
        sagoNo: '20230102',
        sido: '서울특별시',
        sigungu: '송파구',
        dong: '송파동',
        addr: '58-1',
        fullAdr: '서울특별시 송파구 송파동 58-1',
        SEC_CD: '11710104',
        sagoLon: '127.108',
        sagoLat: '37.5116',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail:
          '송파구 송파동 58-1번지 개인하수관 연결부 파손(접속부 불량· 이음부 이격 등)으로 토사 유실에 의한 자전거도로 함몰',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-07-20'
      },
      geometry: { type: 'Point', coordinates: [127.108, 37.5116] }
    },
    {
      type: 'Feature',
      id: 1228,
      properties: {
        sagoDate: '2023-07-19',
        sagoNo: '20230105',
        sido: '부산광역시',
        sigungu: '북구',
        dong: '만덕동',
        addr: '51-9번지 일원(만덕1터널 입구 일원)',
        fullAdr: '부산광역시 북구 만덕동 51-9번지 일원(만덕1터널 입구 일원)',
        SEC_CD: '26320103',
        sagoLon: '129.046',
        sagoLat: '35.2142',
        sinkWidth: '3.5',
        sinkExtend: '2',
        sinkDepth: '3.5',
        grdKind: '',
        sagoDetail: '하수박스(2.0x1.4) 파손(약 10m구간)으로 인한 도로침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-09-27'
      },
      geometry: { type: 'Point', coordinates: [129.046, 35.2142] }
    },
    {
      type: 'Feature',
      id: 1229,
      properties: {
        sagoDate: '2023-07-20',
        sagoNo: '20230107',
        sido: '경상남도',
        sigungu: '창원시 의창구',
        dong: '대산면',
        addr: '유등리 870-1',
        fullAdr: '경상남도 창원시 의창구 대산면 유등리 870-1',
        SEC_CD: '48121320',
        sagoLon: '128.741',
        sagoLat: '35.3414',
        sinkWidth: '1.5',
        sinkExtend: '2.5',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail: '오수관 노후로 인한 싱크홀 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-07-25'
      },
      geometry: { type: 'Point', coordinates: [128.741, 35.3414] }
    },
    {
      type: 'Feature',
      id: 1230,
      properties: {
        sagoDate: '2023-07-23',
        sagoNo: '20230108',
        sido: '경기도',
        sigungu: '포천시',
        dong: '소흘읍',
        addr: '직동리 373',
        fullAdr: '경기도 포천시 소흘읍 직동리 373',
        SEC_CD: '41650250',
        sagoLon: '127.16',
        sagoLat: '37.7733',
        sinkWidth: '2',
        sinkExtend: '4',
        sinkDepth: '5',
        grdKind: '기타',
        sagoDetail: '우수관 노후화로 인한 누수',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-08-31'
      },
      geometry: { type: 'Point', coordinates: [127.16, 37.7733] }
    },
    {
      type: 'Feature',
      id: 1231,
      properties: {
        sagoDate: '2023-07-26',
        sagoNo: '20230111',
        sido: '서울특별시',
        sigungu: '송파구',
        dong: '석촌동',
        addr: '15-3 앞 석촌지하차도 옆 도로',
        fullAdr: '서울특별시 송파구 석촌동 15-3 앞 석촌지하차도 옆 도로',
        SEC_CD: '11710105',
        sagoLon: '127.1',
        sagoLat: '37.5029',
        sinkWidth: '1.5',
        sinkExtend: '7',
        sinkDepth: '5',
        grdKind: '',
        sagoDetail:
          '지하차도 하부 공동으로 상부지반(부채도로)의 토사 유출이 지속되어 약 5m 깊이의 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-07-27'
      },
      geometry: { type: 'Point', coordinates: [127.1, 37.5029] }
    },
    {
      type: 'Feature',
      id: 1232,
      properties: {
        sagoDate: '2023-07-13',
        sagoNo: '20230112',
        sido: '인천광역시',
        sigungu: '연수구',
        dong: '송도동',
        addr: '30-6번지 일원',
        fullAdr: '인천광역시 연수구 송도동 30-6번지 일원',
        SEC_CD: '28185106',
        sagoLon: '126.63',
        sagoLat: '37.3975',
        sinkWidth: '20',
        sinkExtend: '45',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '발생 원인 조사 중',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-10-07'
      },
      geometry: { type: 'Point', coordinates: [126.63, 37.3975] }
    },
    {
      type: 'Feature',
      id: 1233,
      properties: {
        sagoDate: '2023-07-25',
        sagoNo: '20230113',
        sido: '경상남도',
        sigungu: '양산시',
        dong: '물금읍',
        addr: '증산리 943-4 일원',
        fullAdr: '경상남도 양산시 물금읍 증산리 943-4 일원',
        SEC_CD: '48330253',
        sagoLon: '129.009',
        sagoLat: '35.2903',
        sinkWidth: '4',
        sinkExtend: '30',
        sinkDepth: '0.05',
        grdKind: '기타',
        sagoDetail:
          '한국전력공사 남부건설본부에서 TBM 터널식 전력구공사 중 장비 도달을 위하여 관통작업 중 지하수 유출 발생으로 도로 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-08-16'
      },
      geometry: { type: 'Point', coordinates: [129.009, 35.2903] }
    },
    {
      type: 'Feature',
      id: 1234,
      properties: {
        sagoDate: '2023-07-31',
        sagoNo: '20230114',
        sido: '강원특별자치도',
        sigungu: '속초시',
        dong: '영랑동',
        addr: '148-38번지',
        fullAdr: '강원특별자치도 속초시 영랑동 148-38번지',
        SEC_CD: '51210101',
        sagoLon: '128.594',
        sagoLat: '38.2174',
        sinkWidth: '2.5',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '- 1· 2차 인접지반 침하발생 조치방안으로 차수보강 그라우팅 중 지하유출수 발생·- 외부그라우팅 라인에 전주 주변 경사로 천공 후 그라우팅 실시하였으나· 해당부위 주변으로 외부 벽체 전도됨',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-08-04'
      },
      geometry: { type: 'Point', coordinates: [128.594, 38.2174] }
    },
    {
      type: 'Feature',
      id: 1235,
      properties: {
        sagoDate: '2023-07-26',
        sagoNo: '20230115',
        sido: '제주특별자치도',
        sigungu: '제주시',
        dong: '조천읍',
        addr: '신북로 260',
        fullAdr: '제주특별자치도 제주시 조천읍 신북로 260',
        SEC_CD: '50110259',
        sagoLon: '126.64',
        sagoLat: '33.538',
        sinkWidth: '2',
        sinkExtend: '2.5',
        sinkDepth: '1.2',
        grdKind: '기타',
        sagoDetail:
          '우수맨홀과 우수측구 사이 연결관 이탈로 인해 수일간 우수가 지하로 유출되어 토사가 유실되어 발생된 것으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '5000000',
        trFnDate: '2023-08-01'
      },
      geometry: { type: 'Point', coordinates: [126.64, 33.538] }
    },
    {
      type: 'Feature',
      id: 1236,
      properties: {
        sagoDate: '2023-07-25',
        sagoNo: '20230116',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '장덕동',
        addr: '976-1',
        fullAdr: '광주광역시 광산구 장덕동 976-1',
        SEC_CD: '29200123',
        sagoLon: '126.806',
        sagoLat: '35.1966',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1.3',
        grdKind: '',
        sagoDetail: '하수관로 손상으로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-07-26'
      },
      geometry: { type: 'Point', coordinates: [126.806, 35.1966] }
    },
    {
      type: 'Feature',
      id: 1237,
      properties: {
        sagoDate: '2023-07-17',
        sagoNo: '20230122',
        sido: '충청북도',
        sigungu: '보은군',
        dong: '회남면',
        addr: '사음리 243번지',
        fullAdr: '충청북도 보은군 회남면 사음리 243번지',
        SEC_CD: '43720370',
        sagoLon: '127.542',
        sagoLat: '36.4308',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '지하수관로 매설 다짐불량으로 인하여 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1655000',
        trFnDate: '2023-07-20'
      },
      geometry: { type: 'Point', coordinates: [127.542, 36.4308] }
    },
    {
      type: 'Feature',
      id: 1238,
      properties: {
        sagoDate: '2023-07-21',
        sagoNo: '20230148',
        sido: '광주광역시',
        sigungu: '북구',
        dong: '운암동',
        addr: '496-5',
        fullAdr: '광주광역시 북구 운암동 496-5',
        SEC_CD: '29170109',
        sagoLon: '126.889',
        sagoLat: '35.1728',
        sinkWidth: '0.3',
        sinkExtend: '0.3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-07-21'
      },
      geometry: { type: 'Point', coordinates: [126.889, 35.1728] }
    },
    {
      type: 'Feature',
      id: 1239,
      properties: {
        sagoDate: '2023-07-25',
        sagoNo: '20230149',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '산정동',
        addr: '999',
        fullAdr: '광주광역시 광산구 산정동 999',
        SEC_CD: '29200127',
        sagoLon: '126.802',
        sagoLat: '35.1668',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-07-25'
      },
      geometry: { type: 'Point', coordinates: [126.802, 35.1668] }
    },
    {
      type: 'Feature',
      id: 1240,
      properties: {
        sagoDate: '2023-07-25',
        sagoNo: '20230150',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '장덕동',
        addr: '972-7',
        fullAdr: '광주광역시 광산구 장덕동 972-7',
        SEC_CD: '29200123',
        sagoLon: '126.806',
        sagoLat: '35.1967',
        sinkWidth: '2.5',
        sinkExtend: '2.5',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-07-25'
      },
      geometry: { type: 'Point', coordinates: [126.806, 35.1967] }
    },
    {
      type: 'Feature',
      id: 1241,
      properties: {
        sagoDate: '2023-07-12',
        sagoNo: '20230155',
        sido: '전라남도',
        sigungu: '순천시',
        dong: '연향동',
        addr: '안산길 22 앞',
        fullAdr: '전라남도 순천시 연향동 안산길 22 앞',
        SEC_CD: '46150114',
        sagoLon: '127.527',
        sagoLat: '34.9391',
        sinkWidth: '3',
        sinkExtend: '10',
        sinkDepth: '0.3',
        grdKind: '',
        sagoDetail: '집중호우로 인한 우수관 들뜸',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-07-15'
      },
      geometry: { type: 'Point', coordinates: [127.527, 34.9391] }
    },
    {
      type: 'Feature',
      id: 1242,
      properties: {
        sagoDate: '2023-07-22',
        sagoNo: '20230156',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '월평동',
        addr: '223번지',
        fullAdr: '대전광역시 서구 월평동 223번지',
        SEC_CD: '30170113',
        sagoLon: '127.377',
        sagoLat: '36.3641',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1',
        grdKind: '충척층',
        sagoDetail: '하수관로 파손으로 인한 도로함몰',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-07-24'
      },
      geometry: { type: 'Point', coordinates: [127.377, 36.3641] }
    },
    {
      type: 'Feature',
      id: 1243,
      properties: {
        sagoDate: '2023-07-03',
        sagoNo: '20230161',
        sido: '대전광역시',
        sigungu: '중구',
        dong: '대사동',
        addr: '99-15번지',
        fullAdr: '대전광역시 중구 대사동 99-15번지',
        SEC_CD: '30140110',
        sagoLon: '127.425',
        sagoLat: '36.3169',
        sinkWidth: '1.5',
        sinkExtend: '1',
        sinkDepth: '0.8',
        grdKind: '기타',
        sagoDetail: 'U형하수도 상부 슬래브 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-07-04'
      },
      geometry: { type: 'Point', coordinates: [127.425, 36.3169] }
    },
    {
      type: 'Feature',
      id: 1244,
      properties: {
        sagoDate: '2023-07-03',
        sagoNo: '20230165',
        sido: '충청북도',
        sigungu: '제천시',
        dong: '장락동',
        addr: '장락동864',
        fullAdr: '충청북도 제천시 장락동 장락동864',
        SEC_CD: '43150116',
        sagoLon: '128.22',
        sagoLat: '37.1501',
        sinkWidth: '0.4',
        sinkExtend: '0.4',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '기존 합류관 파손에 의한 토사 유실로 싱크홀 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-07-04'
      },
      geometry: { type: 'Point', coordinates: [128.22, 37.1501] }
    },
    {
      type: 'Feature',
      id: 1245,
      properties: {
        sagoDate: '2023-07-18',
        sagoNo: '20230169',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '금호동',
        addr: '195-10',
        fullAdr: '광주광역시 서구 금호동 195-10',
        SEC_CD: '29140130',
        sagoLon: '126.854',
        sagoLat: '35.1269',
        sinkWidth: '0.7',
        sinkExtend: '0.7',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail: '하수관로 노후화로 인한 도로침하 발생 ·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1520000',
        trFnDate: '2024-07-17'
      },
      geometry: { type: 'Point', coordinates: [126.854, 35.1269] }
    },
    {
      type: 'Feature',
      id: 1246,
      properties: {
        sagoDate: '2023-07-17',
        sagoNo: '20240004',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '주성동',
        addr: '91-5',
        fullAdr: '충청북도 청주시 청원구 주성동 91-5',
        SEC_CD: '43114105',
        sagoLon: '127.502',
        sagoLat: '36.6732',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '붐탐관 손상으로 인한 함몰 발생 ·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-12-04'
      },
      geometry: { type: 'Point', coordinates: [127.502, 36.6732] }
    },
    {
      type: 'Feature',
      id: 1247,
      properties: {
        sagoDate: '2023-08-01',
        sagoNo: '20230117',
        sido: '부산광역시',
        sigungu: '강서구',
        dong: '구랑동',
        addr: '878-8번지 일원(보도)',
        fullAdr: '부산광역시 강서구 구랑동 878-8번지 일원(보도)',
        SEC_CD: '26440113',
        sagoLon: '128.85',
        sagoLat: '35.1238',
        sinkWidth: '1.2',
        sinkExtend: '10',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-08-01'
      },
      geometry: { type: 'Point', coordinates: [128.85, 35.1238] }
    },
    {
      type: 'Feature',
      id: 1248,
      properties: {
        sagoDate: '2023-08-06',
        sagoNo: '20230118',
        sido: '서울특별시',
        sigungu: '성북구',
        dong: '성북동',
        addr: '309-5',
        fullAdr: '서울특별시 성북구 성북동 309-5',
        SEC_CD: '11290101',
        sagoLon: '126.993',
        sagoLat: '37.5985',
        sinkWidth: '1',
        sinkExtend: '3',
        sinkDepth: '0.5',
        grdKind: '기타',
        sagoDetail:
          '하수관로(D600mm)에 타관통과(상수도 D250mm) 유수장애로 인한 수압상승(집중호우)·2개소',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-08-06'
      },
      geometry: { type: 'Point', coordinates: [126.993, 37.5985] }
    },
    {
      type: 'Feature',
      id: 1249,
      properties: {
        sagoDate: '2023-08-11',
        sagoNo: '20230119',
        sido: '서울특별시',
        sigungu: '강남구',
        dong: '청담동',
        addr: '77-4',
        fullAdr: '서울특별시 강남구 청담동 77-4',
        SEC_CD: '11680104',
        sagoLon: '127.056',
        sagoLat: '37.5233',
        sinkWidth: '0.5',
        sinkExtend: '2',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '폐쇄 상수관로(1982년 매설· 강관· D700mm) 접합부 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-08-11'
      },
      geometry: { type: 'Point', coordinates: [127.056, 37.5233] }
    },
    {
      type: 'Feature',
      id: 1250,
      properties: {
        sagoDate: '2023-08-13',
        sagoNo: '20230121',
        sido: '경기도',
        sigungu: '안산시 단원구',
        dong: '초지동',
        addr: '초지동 803-6(위도 : 37.32179069· 경도 : 126.80578876)',
        fullAdr:
          '경기도 안산시 단원구 초지동 초지동 803-6(위도 : 37.32179069· 경도 : 126.80578876)',
        SEC_CD: '41273107',
        sagoLon: '126.806',
        sagoLat: '37.3215',
        sinkWidth: '0.5',
        sinkExtend: '1',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail:
          '하수관거(오수관)과 맨홀 접합부 파손에 의한 보도구간 땅꺼짐 및 싱크홀발생',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-08-14'
      },
      geometry: { type: 'Point', coordinates: [126.806, 37.3215] }
    },
    {
      type: 'Feature',
      id: 1251,
      properties: {
        sagoDate: '2023-08-24',
        sagoNo: '20230123',
        sido: '경기도',
        sigungu: '파주시',
        dong: '신촌동',
        addr: '689',
        fullAdr: '경기도 파주시 신촌동 689',
        SEC_CD: '41480120',
        sagoLon: '126.69',
        sagoLat: '37.7396',
        sinkWidth: '6',
        sinkExtend: '10',
        sinkDepth: '4',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-08-24'
      },
      geometry: { type: 'Point', coordinates: [126.69, 37.7396] }
    },
    {
      type: 'Feature',
      id: 1252,
      properties: {
        sagoDate: '2023-08-24',
        sagoNo: '20230124',
        sido: '경기도',
        sigungu: '의정부시',
        dong: '용현동',
        addr: '46',
        fullAdr: '경기도 의정부시 용현동 46',
        SEC_CD: '41150105',
        sagoLon: '127.089',
        sagoLat: '37.7326',
        sinkWidth: '2.5',
        sinkExtend: '2',
        sinkDepth: '2.5',
        grdKind: '충척층',
        sagoDetail:
          '함몰위치에 인접한 우수관의 파손에 따른 우수 유출로 인해 인근 지하철 공사 개착구간으로 토사가 유실되어 함몰된 것으로 판단됨',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-08-24'
      },
      geometry: { type: 'Point', coordinates: [127.089, 37.7326] }
    },
    {
      type: 'Feature',
      id: 1253,
      properties: {
        sagoDate: '2023-08-19',
        sagoNo: '20230126',
        sido: '제주특별자치도',
        sigungu: '제주시',
        dong: '한림읍',
        addr: '상대리 4549-5번지',
        fullAdr: '제주특별자치도 제주시 한림읍 상대리 4549-5번지',
        SEC_CD: '50110250',
        sagoLon: '126.281',
        sagoLat: '33.3932',
        sinkWidth: '2.5',
        sinkExtend: '2.5',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail:
          '고압 농업용수관로 누수에 따른 관로 주변 토사 쇄굴 및 공동 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '5000000',
        trFnDate: '2023-08-31'
      },
      geometry: { type: 'Point', coordinates: [126.281, 33.3932] }
    },
    {
      type: 'Feature',
      id: 1254,
      properties: {
        sagoDate: '2023-08-31',
        sagoNo: '20230127',
        sido: '경상북도',
        sigungu: '경주시',
        dong: '도지동',
        addr: '992번지 일원',
        fullAdr: '경상북도 경주시 도지동 992번지 일원',
        SEC_CD: '47130119',
        sagoLon: '129.267',
        sagoLat: '35.7988',
        sinkWidth: '3',
        sinkExtend: '3',
        sinkDepth: '2.5',
        grdKind: '',
        sagoDetail: '노후화된 횡단 용수관(D750) 파손으로 인한 도로 함몰',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '3',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-09-01'
      },
      geometry: { type: 'Point', coordinates: [129.267, 35.7988] }
    },
    {
      type: 'Feature',
      id: 1255,
      properties: {
        sagoDate: '2023-08-21',
        sagoNo: '20230143',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '산수동',
        addr: '562-1',
        fullAdr: '광주광역시 동구 산수동 562-1',
        SEC_CD: '29110110',
        sagoLon: '126.935',
        sagoLat: '35.1579',
        sinkWidth: '1',
        sinkExtend: '3',
        sinkDepth: '2.2',
        grdKind: '',
        sagoDetail: '지표면 아래 하수관(흄관) 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-08-22'
      },
      geometry: { type: 'Point', coordinates: [126.935, 35.1579] }
    },
    {
      type: 'Feature',
      id: 1256,
      properties: {
        sagoDate: '2023-08-10',
        sagoNo: '20230144',
        sido: '부산광역시',
        sigungu: '북구',
        dong: '구포동',
        addr: '1084-3번지 일원(협진태양아파트 일원)',
        fullAdr: '부산광역시 북구 구포동 1084-3번지 일원(협진태양아파트 일원)',
        SEC_CD: '26320105',
        sagoLon: '128.995',
        sagoLat: '35.1975',
        sinkWidth: '2',
        sinkExtend: '1.4',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '하수관 노후· 파손· 누수로 인한 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-08-11'
      },
      geometry: { type: 'Point', coordinates: [128.995, 35.1975] }
    },
    {
      type: 'Feature',
      id: 1257,
      properties: {
        sagoDate: '2023-08-10',
        sagoNo: '20230145',
        sido: '부산광역시',
        sigungu: '북구',
        dong: '구포동',
        addr: '1263-22번지 일원(부산과학기술대학교 일원)',
        fullAdr:
          '부산광역시 북구 구포동 1263-22번지 일원(부산과학기술대학교 일원)',
        SEC_CD: '26320105',
        sagoLon: '129.006',
        sagoLat: '35.1946',
        sinkWidth: '1.2',
        sinkExtend: '1.5',
        sinkDepth: '0.9',
        grdKind: '',
        sagoDetail: '우수유입으로 인한 지반융기 후 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-09-28'
      },
      geometry: { type: 'Point', coordinates: [129.006, 35.1946] }
    },
    {
      type: 'Feature',
      id: 1258,
      properties: {
        sagoDate: '2023-08-15',
        sagoNo: '20230151',
        sido: '광주광역시',
        sigungu: '북구',
        dong: '우산동',
        addr: '584',
        fullAdr: '광주광역시 북구 우산동 584',
        SEC_CD: '29170112',
        sagoLon: '126.919',
        sagoLat: '35.1818',
        sinkWidth: '0.3',
        sinkExtend: '0.3',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-08-15'
      },
      geometry: { type: 'Point', coordinates: [126.919, 35.1818] }
    },
    {
      type: 'Feature',
      id: 1259,
      properties: {
        sagoDate: '2023-08-31',
        sagoNo: '20230153',
        sido: '부산광역시',
        sigungu: '부산진구',
        dong: '전포동',
        addr: '동천로 103',
        fullAdr: '부산광역시 부산진구 전포동 동천로 103',
        SEC_CD: '26230102',
        sagoLon: '129.065',
        sagoLat: '35.1579',
        sinkWidth: '1',
        sinkExtend: '1.4',
        sinkDepth: '1.1',
        grdKind: '',
        sagoDetail: '상수도관(D800) 누수로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-09-01'
      },
      geometry: { type: 'Point', coordinates: [129.065, 35.1579] }
    },
    {
      type: 'Feature',
      id: 1260,
      properties: {
        sagoDate: '2023-08-24',
        sagoNo: '20230154',
        sido: '부산광역시',
        sigungu: '북구',
        dong: '덕천동',
        addr: '132-67번지 일원(덕양초등학교 일원)',
        fullAdr: '부산광역시 북구 덕천동 132-67번지 일원(덕양초등학교 일원)',
        SEC_CD: '26320103',
        sagoLon: '129.023',
        sagoLat: '35.2099',
        sinkWidth: '1.2',
        sinkExtend: '1.2',
        sinkDepth: '0.2',
        grdKind: '',
        sagoDetail: '하수도관 노후· 파손· 누수로 인한 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-08-24'
      },
      geometry: { type: 'Point', coordinates: [129.023, 35.2099] }
    },
    {
      type: 'Feature',
      id: 1261,
      properties: {
        sagoDate: '2023-08-02',
        sagoNo: '20230157',
        sido: '대전광역시',
        sigungu: '서구',
        dong: '둔산동',
        addr: '953번지 인근',
        fullAdr: '대전광역시 서구 둔산동 953번지 인근',
        SEC_CD: '30170112',
        sagoLon: '127.379',
        sagoLat: '36.3576',
        sinkWidth: '0.5',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '충척층',
        sagoDetail: '하수관로 파손으로 인한 도로함몰',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-08-03'
      },
      geometry: { type: 'Point', coordinates: [127.379, 36.3576] }
    },
    {
      type: 'Feature',
      id: 1262,
      properties: {
        sagoDate: '2023-08-20',
        sagoNo: '20230158',
        sido: '강원특별자치도',
        sigungu: '횡성군',
        dong: '우천면',
        addr: '우천면 우항리 759번지 스마일파크빌 앞 도시계획도로(소로2-201호)',
        fullAdr:
          '강원특별자치도 횡성군 우천면 우천면 우항리 759번지 스마일파크빌 앞 도시계획도로(소로2-201호)',
        SEC_CD: '51730310',
        sagoLon: '128.066',
        sagoLat: '37.4584',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '기타',
        sagoDetail: '우수관로 연결부 불량에 따른 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '3000000',
        trFnDate: '2023-08-30'
      },
      geometry: { type: 'Point', coordinates: [128.066, 37.4584] }
    },
    {
      type: 'Feature',
      id: 1263,
      properties: {
        sagoDate: '2023-08-14',
        sagoNo: '20230162',
        sido: '대전광역시',
        sigungu: '중구',
        dong: '대사동',
        addr: '226-38번지',
        fullAdr: '대전광역시 중구 대사동 226-38번지',
        SEC_CD: '30140110',
        sagoLon: '127.423',
        sagoLat: '36.3172',
        sinkWidth: '0.5',
        sinkExtend: '1',
        sinkDepth: '2.2',
        grdKind: '기타',
        sagoDetail: '하수관로와 맨홀 접합부 파손으로 인해 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-08-14'
      },
      geometry: { type: 'Point', coordinates: [127.423, 36.3172] }
    },
    {
      type: 'Feature',
      id: 1264,
      properties: {
        sagoDate: '2023-08-14',
        sagoNo: '20230163',
        sido: '경기도',
        sigungu: '화성시',
        dong: '반송동',
        addr: '95',
        fullAdr: '경기도 화성시 반송동 95',
        SEC_CD: '41590127',
        sagoLon: '127.07',
        sagoLat: '37.2039',
        sinkWidth: '2.5',
        sinkExtend: '2',
        sinkDepth: '4',
        grdKind: '',
        sagoDetail:
          '2006년 동탄지구 조성 시 공원 부지 조성 전 A·B블럭 지하 연결통로 조성 시 마감 처리 미흡에 따른 우수 및 토사 유입에 따른 침하 발생 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-03-30'
      },
      geometry: { type: 'Point', coordinates: [127.07, 37.2039] }
    },
    {
      type: 'Feature',
      id: 1265,
      properties: {
        sagoDate: '2023-08-24',
        sagoNo: '20230164',
        sido: '충청북도',
        sigungu: '음성군',
        dong: '금왕읍',
        addr: '오선리 536',
        fullAdr: '충청북도 음성군 금왕읍 오선리 536',
        SEC_CD: '43770253',
        sagoLon: '127.575',
        sagoLat: '36.9828',
        sinkWidth: '6',
        sinkExtend: '2.5',
        sinkDepth: '5',
        grdKind: '',
        sagoDetail:
          '기존 유출우수관로 노후화로 맨홀 연결부분 파손 및 이탈 발생·맨홀 유입관(D1200· D500 2개)에 비해 유출관(D1000)이 작아 수압이 크고 ·우수관이 이중벽관으로 시공되어 내구성이 취약',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '300000000',
        trFnDate: '2024-05-20'
      },
      geometry: { type: 'Point', coordinates: [127.575, 36.9828] }
    },
    {
      type: 'Feature',
      id: 1266,
      properties: {
        sagoDate: '2023-08-08',
        sagoNo: '20230166',
        sido: '충청북도',
        sigungu: '제천시',
        dong: '화산동',
        addr: '201-9',
        fullAdr: '충청북도 제천시 화산동 201-9',
        SEC_CD: '43150109',
        sagoLon: '128.208',
        sagoLat: '37.1317',
        sinkWidth: '0.2',
        sinkExtend: '0.2',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '관로 이음부 벌어짐 부분에 토사유실로 인한 싱크홀 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-08-09'
      },
      geometry: { type: 'Point', coordinates: [128.208, 37.1317] }
    },
    {
      type: 'Feature',
      id: 1267,
      properties: {
        sagoDate: '2023-08-18',
        sagoNo: '20230167',
        sido: '충청북도',
        sigungu: '제천시',
        dong: '청전동',
        addr: '623',
        fullAdr: '충청북도 제천시 청전동 623',
        SEC_CD: '43150113',
        sagoLon: '128.215',
        sagoLat: '37.1528',
        sinkWidth: '0.2',
        sinkExtend: '0.1',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '우수관 주변 도로 침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-08-21'
      },
      geometry: { type: 'Point', coordinates: [128.215, 37.1528] }
    },
    {
      type: 'Feature',
      id: 1268,
      properties: {
        sagoDate: '2023-08-21',
        sagoNo: '20230170',
        sido: '광주광역시',
        sigungu: '남구',
        dong: '봉선동',
        addr: '1027-1',
        fullAdr: '광주광역시 남구 봉선동 1027-1',
        SEC_CD: '29155115',
        sagoLon: '126.911',
        sagoLat: '35.13',
        sinkWidth: '2',
        sinkExtend: '2.5',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '우수 D800mm가 우수박수접합점에서 누수발생 씽크홀',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-08-25'
      },
      geometry: { type: 'Point', coordinates: [126.911, 35.13] }
    },
    {
      type: 'Feature',
      id: 1269,
      properties: {
        sagoDate: '2023-08-12',
        sagoNo: '20230171',
        sido: '부산광역시',
        sigungu: '사상구',
        dong: '모라동',
        addr: '사상구 모라로64번길 67(신아주택 인근)',
        fullAdr:
          '부산광역시 사상구 모라동 사상구 모라로64번길 67(신아주택 인근)',
        SEC_CD: '26530102',
        sagoLon: '128.985',
        sagoLat: '35.1901',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.3',
        grdKind: '기타',
        sagoDetail: '노후 하수관로(83년)파손으로 인한 누수 발생으로 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-08-12'
      },
      geometry: { type: 'Point', coordinates: [128.985, 35.1901] }
    },
    {
      type: 'Feature',
      id: 1270,
      properties: {
        sagoDate: '2023-08-30',
        sagoNo: '20230172',
        sido: '부산광역시',
        sigungu: '사상구',
        dong: '엄궁동',
        addr: '사상구 엄궁로 154(대림아파트 입구)',
        fullAdr: '부산광역시 사상구 엄궁동 사상구 엄궁로 154(대림아파트 입구)',
        SEC_CD: '26530108',
        sagoLon: '128.978',
        sagoLat: '35.1288',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '자연침하에 따른 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-08-31'
      },
      geometry: { type: 'Point', coordinates: [128.978, 35.1288] }
    },
    {
      type: 'Feature',
      id: 1271,
      properties: {
        sagoDate: '2023-09-04',
        sagoNo: '20230128',
        sido: '경상남도',
        sigungu: '진주시',
        dong: '상평동',
        addr: '상평동 155-29번지',
        fullAdr: '경상남도 진주시 상평동 상평동 155-29번지',
        SEC_CD: '48170121',
        sagoLon: '128.118',
        sagoLat: '35.1752',
        sinkWidth: '3.4',
        sinkExtend: '1.8',
        sinkDepth: '3.3',
        grdKind: '',
        sagoDetail: '폐수관로 노후화로 인한 파손 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-09-05'
      },
      geometry: { type: 'Point', coordinates: [128.118, 35.1752] }
    },
    {
      type: 'Feature',
      id: 1272,
      properties: {
        sagoDate: '2023-09-11',
        sagoNo: '20230129',
        sido: '서울특별시',
        sigungu: '서초구',
        dong: '잠원동',
        addr: '58-24',
        fullAdr: '서울특별시 서초구 잠원동 58-24',
        SEC_CD: '11650106',
        sagoLon: '127.012',
        sagoLat: '37.5084',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '상수도 누수(4개소)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '5000000',
        trFnDate: '2023-09-11'
      },
      geometry: { type: 'Point', coordinates: [127.012, 37.5084] }
    },
    {
      type: 'Feature',
      id: 1273,
      properties: {
        sagoDate: '2023-09-17',
        sagoNo: '20230131',
        sido: '경상남도',
        sigungu: '진주시',
        dong: '상평동',
        addr: '상평동 155-27',
        fullAdr: '경상남도 진주시 상평동 상평동 155-27',
        SEC_CD: '48170121',
        sagoLon: '128.118',
        sagoLat: '35.1751',
        sinkWidth: '2',
        sinkExtend: '4',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail:
          '저번 발생지점 CCTV 조사 중 폐수관로 노후화로 인한 파손으로 토사 유실로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-09-22'
      },
      geometry: { type: 'Point', coordinates: [128.118, 35.1751] }
    },
    {
      type: 'Feature',
      id: 1274,
      properties: {
        sagoDate: '2023-09-18',
        sagoNo: '20230132',
        sido: '경상남도',
        sigungu: '사천시',
        dong: '사남면',
        addr: '화전리 1640번지',
        fullAdr: '경상남도 사천시 사남면 화전리 1640번지',
        SEC_CD: '48240320',
        sagoLon: '128.077',
        sagoLat: '35.0572',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '하수관로(차집관로) 노후 및 파손 등으로 인한 싱크홀 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-09-20'
      },
      geometry: { type: 'Point', coordinates: [128.077, 35.0572] }
    },
    {
      type: 'Feature',
      id: 1275,
      properties: {
        sagoDate: '2023-09-12',
        sagoNo: '20230133',
        sido: '서울특별시',
        sigungu: '강남구',
        dong: '역삼동',
        addr: '800 ',
        fullAdr: '서울특별시 강남구 역삼동 800',
        SEC_CD: '11680101',
        sagoLon: '127.033',
        sagoLat: '37.507',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '부등침하로 인한 상수관로 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-09-12'
      },
      geometry: { type: 'Point', coordinates: [127.033, 37.507] }
    },
    {
      type: 'Feature',
      id: 1276,
      properties: {
        sagoDate: '2023-09-19',
        sagoNo: '20230134',
        sido: '전라남도',
        sigungu: '광양시',
        dong: '광영동',
        addr: '847번지 일원',
        fullAdr: '전라남도 광양시 광영동 847번지 일원',
        SEC_CD: '46230108',
        sagoLon: '127.713',
        sagoLat: '34.9649',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '2.2',
        grdKind: '',
        sagoDetail:
          '우수맨홀 구체 일부가 탈락· 탈락 부위로 우수 및 토사가 유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-09-19'
      },
      geometry: { type: 'Point', coordinates: [127.713, 34.9649] }
    },
    {
      type: 'Feature',
      id: 1277,
      properties: {
        sagoDate: '2023-09-21',
        sagoNo: '20230135',
        sido: '경기도',
        sigungu: '성남시 수정구',
        dong: '태평동',
        addr: '3499',
        fullAdr: '경기도 성남시 수정구 태평동 3499',
        SEC_CD: '41131102',
        sagoLon: '127.136',
        sagoLat: '37.4423',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail:
          '상수도 파손으로 인한 누수발생. 지반 쓸림현상에 따른 포트홀로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-09-22'
      },
      geometry: { type: 'Point', coordinates: [127.136, 37.4423] }
    },
    {
      type: 'Feature',
      id: 1278,
      properties: {
        sagoDate: '2023-09-19',
        sagoNo: '20230174',
        sido: '부산광역시',
        sigungu: '금정구',
        dong: '장전동',
        addr: '소정로 45',
        fullAdr: '부산광역시 금정구 장전동 소정로 45',
        SEC_CD: '26410108',
        sagoLon: '129.079',
        sagoLat: '35.2287',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail:
          '동공 아래 하수박스 슬라브 및 벽체 파손으로 인한 토사 유실로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-09-23'
      },
      geometry: { type: 'Point', coordinates: [129.079, 35.2287] }
    },
    {
      type: 'Feature',
      id: 1279,
      properties: {
        sagoDate: '2023-10-11',
        sagoNo: '20230136',
        sido: '서울특별시',
        sigungu: '송파구',
        dong: '삼전동',
        addr: '100-7',
        fullAdr: '서울특별시 송파구 삼전동 100-7',
        SEC_CD: '11710106',
        sagoLon: '127.092',
        sagoLat: '37.5036',
        sinkWidth: '0.2',
        sinkExtend: '0.7',
        sinkDepth: '1.8',
        grdKind: '기타',
        sagoDetail:
          '상수도 누수로 인한 토사 유실 및 지반침하로 인한 공공하수관 이격 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-10-12'
      },
      geometry: { type: 'Point', coordinates: [127.092, 37.5036] }
    },
    {
      type: 'Feature',
      id: 1280,
      properties: {
        sagoDate: '2023-10-25',
        sagoNo: '20230140',
        sido: '서울특별시',
        sigungu: '영등포구',
        dong: '여의도동',
        addr: '22',
        fullAdr: '서울특별시 영등포구 여의도동 22',
        SEC_CD: '11560110',
        sagoLon: '126.926',
        sagoLat: '37.5264',
        sinkWidth: '0.5',
        sinkExtend: '0.3',
        sinkDepth: '2.5',
        grdKind: '기타',
        sagoDetail: '하수관 맨홀 이상·',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-10-26'
      },
      geometry: { type: 'Point', coordinates: [126.926, 37.5264] }
    },
    {
      type: 'Feature',
      id: 1281,
      properties: {
        sagoDate: '2023-10-30',
        sagoNo: '20230141',
        sido: '강원특별자치도',
        sigungu: '강릉시',
        dong: '교동',
        addr: '130-2',
        fullAdr: '강원특별자치도 강릉시 교동 130-2',
        SEC_CD: '51150110',
        sagoLon: '128.901',
        sagoLat: '37.7644',
        sinkWidth: '2',
        sinkExtend: '5',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail: '건축 공사장 인근 굴착 공사로 인한 지반침하로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-11-17'
      },
      geometry: { type: 'Point', coordinates: [128.901, 37.7644] }
    },
    {
      type: 'Feature',
      id: 1282,
      properties: {
        sagoDate: '2023-10-12',
        sagoNo: '20230152',
        sido: '광주광역시',
        sigungu: '동구',
        dong: '동명동',
        addr: '동명동 118-3',
        fullAdr: '광주광역시 동구 동명동 동명동 118-3',
        SEC_CD: '29110108',
        sagoLon: '126.928',
        sagoLat: '35.1469',
        sinkWidth: '1.3',
        sinkExtend: '1.3',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '지표면 아래 하수관(흄관) 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-10-13'
      },
      geometry: { type: 'Point', coordinates: [126.928, 35.1469] }
    },
    {
      type: 'Feature',
      id: 1283,
      properties: {
        sagoDate: '2023-10-19',
        sagoNo: '20230168',
        sido: '충청북도',
        sigungu: '제천시',
        dong: '의림동',
        addr: '25-1',
        fullAdr: '충청북도 제천시 의림동 25-1',
        SEC_CD: '43150101',
        sagoLon: '128.211',
        sagoLat: '37.1435',
        sinkWidth: '0.1',
        sinkExtend: '0.1',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '우수관로 주변 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-11-17'
      },
      geometry: { type: 'Point', coordinates: [128.211, 37.1435] }
    },
    {
      type: 'Feature',
      id: 1284,
      properties: {
        sagoDate: '2023-10-19',
        sagoNo: '20230175',
        sido: '인천광역시',
        sigungu: '서구',
        dong: '청라동',
        addr: '청라국제도시역 일원',
        fullAdr: '인천광역시 서구 청라동 청라국제도시역 일원',
        SEC_CD: '28260122',
        sagoLon: '126.625',
        sagoLat: '37.5551',
        sinkWidth: '200',
        sinkExtend: '41',
        sinkDepth: '0.04',
        grdKind: '기타',
        sagoDetail: '원인불명의 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-04-30'
      },
      geometry: { type: 'Point', coordinates: [126.625, 37.5551] }
    },
    {
      type: 'Feature',
      id: 1285,
      properties: {
        sagoDate: '2023-11-03',
        sagoNo: '20230142',
        sido: '서울특별시',
        sigungu: '강서구',
        dong: '외발산동',
        addr: '289-29',
        fullAdr: '서울특별시 강서구 외발산동 289-29',
        SEC_CD: '11500107',
        sagoLon: '126.817',
        sagoLat: '37.5518',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '포장 하부 불명 조적구조물 파손으로 인한 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-11-03'
      },
      geometry: { type: 'Point', coordinates: [126.817, 37.5518] }
    },
    {
      type: 'Feature',
      id: 1286,
      properties: {
        sagoDate: '2023-11-22',
        sagoNo: '20230176',
        sido: '제주특별자치도',
        sigungu: '제주시',
        dong: '삼도이동',
        addr: '1192-31',
        fullAdr: '제주특별자치도 제주시 삼도이동 1192-31',
        SEC_CD: '50110106',
        sagoLon: '126.523',
        sagoLat: '33.5172',
        sinkWidth: '0.8',
        sinkExtend: '1.5',
        sinkDepth: '1',
        grdKind: '화강암류',
        sagoDetail: '우수관로 손상으로 인한 토사 유실 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '3000000',
        trFnDate: '2023-11-24'
      },
      geometry: { type: 'Point', coordinates: [126.523, 33.5172] }
    },
    {
      type: 'Feature',
      id: 1287,
      properties: {
        sagoDate: '2023-12-08',
        sagoNo: '20230147',
        sido: '서울특별시',
        sigungu: '강남구',
        dong: '삼성동',
        addr: '111-43',
        fullAdr: '서울특별시 강남구 삼성동 111-43',
        SEC_CD: '11680105',
        sagoLon: '127.053',
        sagoLat: '37.5191',
        sinkWidth: '1',
        sinkExtend: '1.4',
        sinkDepth: '0.7',
        grdKind: '',
        sagoDetail: '상수관(D300mm) 손상 누수',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-12-09'
      },
      geometry: { type: 'Point', coordinates: [127.053, 37.5191] }
    },
    {
      type: 'Feature',
      id: 1288,
      properties: {
        sagoDate: '2023-12-27',
        sagoNo: '20230173',
        sido: '부산광역시',
        sigungu: '동래구',
        dong: '온천동',
        addr: '1441-13번지',
        fullAdr: '부산광역시 동래구 온천동 1441-13번지',
        SEC_CD: '26260108',
        sagoLon: '129.077',
        sagoLat: '35.2047',
        sinkWidth: '0.6',
        sinkExtend: '1',
        sinkDepth: '2.6',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-12-29'
      },
      geometry: { type: 'Point', coordinates: [129.077, 35.2047] }
    },
    {
      type: 'Feature',
      id: 1289,
      properties: {
        sagoDate: '2023-12-14',
        sagoNo: '20240005',
        sido: '충청북도',
        sigungu: '청주시 청원구',
        dong: '율량동',
        addr: '1873',
        fullAdr: '충청북도 청주시 청원구 율량동 1873',
        SEC_CD: '43114103',
        sagoLon: '127.486',
        sagoLat: '36.6663',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '우수 박스 맨홀 이음매 파손으로 인해 함몰 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2023-12-22'
      },
      geometry: { type: 'Point', coordinates: [127.486, 36.6663] }
    },
    {
      type: 'Feature',
      id: 1290,
      properties: {
        sagoDate: '2024-01-03',
        sagoNo: '20240001',
        sido: '서울특별시',
        sigungu: '성북구',
        dong: '길음동',
        addr: '875-1 (길음역 7번출구)',
        fullAdr: '서울특별시 성북구 길음동 875-1 (길음역 7번출구)',
        SEC_CD: '11290134',
        sagoLon: '127.025',
        sagoLat: '37.6044',
        sinkWidth: '0.4',
        sinkExtend: '0.6',
        sinkDepth: '1.2',
        grdKind: '',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-01-04'
      },
      geometry: { type: 'Point', coordinates: [127.025, 37.6044] }
    },
    {
      type: 'Feature',
      id: 1291,
      properties: {
        sagoDate: '2024-01-05',
        sagoNo: '20240002',
        sido: '강원특별자치도',
        sigungu: '고성군',
        dong: '토성면',
        addr: '봉포리 255-1',
        fullAdr: '강원특별자치도 고성군 토성면 봉포리 255-1',
        SEC_CD: '51820330',
        sagoLon: '128.563',
        sagoLat: '38.2535',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '0.7',
        grdKind: '기타',
        sagoDetail:
          '오피스텔 신축공사 현장·흙막이벽 내 균열로 인한 지하수 유입에 따른 싱크홀 추정 ·오후 16:00',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-01-05'
      },
      geometry: { type: 'Point', coordinates: [128.563, 38.2535] }
    },
    {
      type: 'Feature',
      id: 1292,
      properties: {
        sagoDate: '2024-01-08',
        sagoNo: '20240003',
        sido: '강원특별자치도',
        sigungu: '고성군',
        dong: '토성면',
        addr: '봉포리 255-1',
        fullAdr: '강원특별자치도 고성군 토성면 봉포리 255-1',
        SEC_CD: '51820330',
        sagoLon: '128.563',
        sagoLat: '38.2535',
        sinkWidth: '3',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail:
          '24.1.8 오후 4시경 흙막이벽 보수공사 중 크랙발생·ccip공사 중 수압으로 흙막이벽 내 사이 벌어짐 및 천공기 빠짐·4시 20분경 땅꺼짐 현상 발생 ·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-01-08'
      },
      geometry: { type: 'Point', coordinates: [128.563, 38.2535] }
    },
    {
      type: 'Feature',
      id: 1293,
      properties: {
        sagoDate: '2024-01-10',
        sagoNo: '20240006',
        sido: '경기도',
        sigungu: '용인시 기흥구',
        dong: '농서동',
        addr: '37-4번지',
        fullAdr: '경기도 용인시 기흥구 농서동 37-4번지',
        SEC_CD: '41463109',
        sagoLon: '127.091',
        sagoLat: '37.2232',
        sinkWidth: '3',
        sinkExtend: '12',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '인근 광역상수3단계 설치공사 현장 지하수 펌핑에 따른 지반침하 가능성 있음',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '56000000',
        trFnDate: '2024-06-12'
      },
      geometry: { type: 'Point', coordinates: [127.091, 37.2232] }
    },
    {
      type: 'Feature',
      id: 1294,
      properties: {
        sagoDate: '2024-01-16',
        sagoNo: '20240007',
        sido: '서울특별시',
        sigungu: '강남구',
        dong: '삼성동',
        addr: '159-8 그랜드인터컨티넨탈 호텔 앞 보도',
        fullAdr:
          '서울특별시 강남구 삼성동 159-8 그랜드인터컨티넨탈 호텔 앞 보도',
        SEC_CD: '11680105',
        sagoLon: '127.061',
        sagoLat: '37.5084',
        sinkWidth: '1.5',
        sinkExtend: '3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수박스 이설에 따른 빗물받이 연결관 미폐공으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-01-16'
      },
      geometry: { type: 'Point', coordinates: [127.061, 37.5084] }
    },
    {
      type: 'Feature',
      id: 1295,
      properties: {
        sagoDate: '2024-01-28',
        sagoNo: '20240061',
        sido: '부산광역시',
        sigungu: '북구',
        dong: '금곡동',
        addr: '56-1번지',
        fullAdr: '부산광역시 북구 금곡동 56-1번지',
        SEC_CD: '26320101',
        sagoLon: '129.019',
        sagoLat: '35.2671',
        sinkWidth: '0.3',
        sinkExtend: '0.5',
        sinkDepth: '2.1',
        grdKind: '',
        sagoDetail: '하수관 노후· 파손· 누수로 인한 토사유실',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-02-09'
      },
      geometry: { type: 'Point', coordinates: [129.019, 35.2671] }
    },
    {
      type: 'Feature',
      id: 1296,
      properties: {
        sagoDate: '2024-02-03',
        sagoNo: '20240009',
        sido: '경기도',
        sigungu: '파주시',
        dong: '다율동',
        addr: '1004',
        fullAdr: '경기도 파주시 다율동 1004',
        SEC_CD: '41480109',
        sagoLon: '126.715',
        sagoLat: '37.7291',
        sinkWidth: '4',
        sinkExtend: '5',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '송전선로 매설공사(비개착 공법)에 따른 공동 발생 (추정)',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '20000000',
        trFnDate: '2024-02-29'
      },
      geometry: { type: 'Point', coordinates: [126.715, 37.7291] }
    },
    {
      type: 'Feature',
      id: 1297,
      properties: {
        sagoDate: '2024-02-06',
        sagoNo: '20240010',
        sido: '경기도',
        sigungu: '남양주시',
        dong: '화도읍',
        addr: '가곡리 23-61번지',
        fullAdr: '경기도 남양주시 화도읍 가곡리 23-61번지',
        SEC_CD: '41360256',
        sagoLon: '127.314',
        sagoLat: '37.6868',
        sinkWidth: '3',
        sinkExtend: '2.6',
        sinkDepth: '5',
        grdKind: '',
        sagoDetail:
          '우수관(D1000㎜) 파손으로 인한 우수 유출되어 지반 침식으로 싱크홀 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '7000000',
        trFnDate: '2024-02-07'
      },
      geometry: { type: 'Point', coordinates: [127.314, 37.6868] }
    },
    {
      type: 'Feature',
      id: 1298,
      properties: {
        sagoDate: '2024-02-23',
        sagoNo: '20240011',
        sido: '서울특별시',
        sigungu: '성북구',
        dong: '길음동',
        addr: '532-17',
        fullAdr: '서울특별시 성북구 길음동 532-17',
        SEC_CD: '11290134',
        sagoLon: '127.024',
        sagoLat: '37.6039',
        sinkWidth: '0.4',
        sinkExtend: '0.6',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '지하연결통로공사 해빙기 토사유실로 추정',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2024-02-23'
      },
      geometry: { type: 'Point', coordinates: [127.024, 37.6039] }
    },
    {
      type: 'Feature',
      id: 1299,
      properties: {
        sagoDate: '2024-02-08',
        sagoNo: '20240031',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '화정동',
        addr: '899',
        fullAdr: '광주광역시 서구 화정동 899',
        SEC_CD: '29140119',
        sagoLon: '126.872',
        sagoLat: '35.147',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '관접합부위 파손으로 인한 도로침하 발생·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-02-08'
      },
      geometry: { type: 'Point', coordinates: [126.872, 35.147] }
    },
    {
      type: 'Feature',
      id: 1300,
      properties: {
        sagoDate: '2024-02-22',
        sagoNo: '20240083',
        sido: '서울특별시',
        sigungu: '노원구',
        dong: '공릉동',
        addr: '656-2',
        fullAdr: '서울특별시 노원구 공릉동 656-2',
        SEC_CD: '11350103',
        sagoLon: '127.079',
        sagoLat: '37.6185',
        sinkWidth: '2.4',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-02-23'
      },
      geometry: { type: 'Point', coordinates: [127.079, 37.6185] }
    },
    {
      type: 'Feature',
      id: 1301,
      properties: {
        sagoDate: '2024-03-04',
        sagoNo: '20240012',
        sido: '경기도',
        sigungu: '남양주시',
        dong: '화도읍',
        addr: '가곡리 23-59번지',
        fullAdr: '경기도 남양주시 화도읍 가곡리 23-59번지',
        SEC_CD: '41360256',
        sagoLon: '127.315',
        sagoLat: '37.6864',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '우수관 파손으로 인한 지반 침식으로 싱크홀 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '10000000',
        trFnDate: '2024-03-07'
      },
      geometry: { type: 'Point', coordinates: [127.315, 37.6864] }
    },
    {
      type: 'Feature',
      id: 1302,
      properties: {
        sagoDate: '2024-03-14',
        sagoNo: '20240013',
        sido: '부산광역시',
        sigungu: '남구',
        dong: '용당동',
        addr: '신선로 294 신선대컨테이너터미널 정문 앞 도로',
        fullAdr:
          '부산광역시 남구 용당동 신선로 294 신선대컨테이너터미널 정문 앞 도로',
        SEC_CD: '26290108',
        sagoLon: '129.096',
        sagoLat: '35.1126',
        sinkWidth: '0.9',
        sinkExtend: '1.3',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '(확인굴착결과) 한전 고객 배전선로 여유공 파손으로 토사유실로 판단됨',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-03-15'
      },
      geometry: { type: 'Point', coordinates: [129.096, 35.1126] }
    },
    {
      type: 'Feature',
      id: 1303,
      properties: {
        sagoDate: '2024-03-06',
        sagoNo: '20240038',
        sido: '대전광역시',
        sigungu: '중구',
        dong: '대흥동',
        addr: '630',
        fullAdr: '대전광역시 중구 대흥동 630',
        SEC_CD: '30140105',
        sagoLon: '127.429',
        sagoLat: '36.3245',
        sinkWidth: '1',
        sinkExtend: '2',
        sinkDepth: '1.2',
        grdKind: '',
        sagoDetail: '집중호우로 인한 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-03-07'
      },
      geometry: { type: 'Point', coordinates: [127.429, 36.3245] }
    },
    {
      type: 'Feature',
      id: 1304,
      properties: {
        sagoDate: '2024-04-19',
        sagoNo: '20210186',
        sido: '경기도',
        sigungu: '남양주시',
        dong: '호평동',
        addr: '559-4',
        fullAdr: '경기도 남양주시 호평동 559-4',
        SEC_CD: '41360101',
        sagoLon: '127.238',
        sagoLat: '37.6595',
        sinkWidth: '0.3',
        sinkExtend: '0.3',
        sinkDepth: '2.7',
        grdKind: '',
        sagoDetail: '노후 수로관(파형강관 2열)매설지점으로 도로 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '12000000',
        trFnDate: '2024-04-20'
      },
      geometry: { type: 'Point', coordinates: [127.238, 37.6595] }
    },
    {
      type: 'Feature',
      id: 1305,
      properties: {
        sagoDate: '2024-04-19',
        sagoNo: '20220204',
        sido: '경기도',
        sigungu: '남양주시',
        dong: '호평동',
        addr: '560-4',
        fullAdr: '경기도 남양주시 호평동 560-4',
        SEC_CD: '41360101',
        sagoLon: '127.237',
        sagoLat: '37.6605',
        sinkWidth: '0.7',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '노후 수로관(파형강관 2열) 매설지점으로 도로 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '3000000',
        trFnDate: '2024-04-20'
      },
      geometry: { type: 'Point', coordinates: [127.237, 37.6605] }
    },
    {
      type: 'Feature',
      id: 1306,
      properties: {
        sagoDate: '2024-04-17',
        sagoNo: '20240015',
        sido: '전라남도',
        sigungu: '광양시',
        dong: '중동',
        addr: '1351-5일원',
        fullAdr: '전라남도 광양시 중동 1351-5일원',
        SEC_CD: '46230106',
        sagoLon: '127.695',
        sagoLat: '34.9443',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '노후관로로 인해 부분 누수 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-04-18'
      },
      geometry: { type: 'Point', coordinates: [127.695, 34.9443] }
    },
    {
      type: 'Feature',
      id: 1307,
      properties: {
        sagoDate: '2024-04-19',
        sagoNo: '20240016',
        sido: '부산광역시',
        sigungu: '북구',
        dong: '구포동',
        addr: '1222-22(동성종합상가 일원)',
        fullAdr: '부산광역시 북구 구포동 1222-22(동성종합상가 일원)',
        SEC_CD: '26320105',
        sagoLon: '129.008',
        sagoLat: '35.1985',
        sinkWidth: '0.8',
        sinkExtend: '0.8',
        sinkDepth: '1.3',
        grdKind: '',
        sagoDetail: '가스배관 다짐불량 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-04-22'
      },
      geometry: { type: 'Point', coordinates: [129.008, 35.1985] }
    },
    {
      type: 'Feature',
      id: 1308,
      properties: {
        sagoDate: '2024-04-30',
        sagoNo: '20240017',
        sido: '부산광역시',
        sigungu: '사상구',
        dong: '감전동',
        addr: '162-22',
        fullAdr: '부산광역시 사상구 감전동 162-22',
        SEC_CD: '26530105',
        sagoLon: '128.982',
        sagoLat: '35.151',
        sinkWidth: '1.5',
        sinkExtend: '0.8',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail: '상수관로(D300mm) 탈락에 따른 누수로 토사 유실 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '20000000',
        trFnDate: '2024-05-17'
      },
      geometry: { type: 'Point', coordinates: [128.982, 35.151] }
    },
    {
      type: 'Feature',
      id: 1309,
      properties: {
        sagoDate: '2024-04-09',
        sagoNo: '20240034',
        sido: '경기도',
        sigungu: '평택시',
        dong: '고덕면',
        addr: '방축리 287-9',
        fullAdr: '경기도 평택시 고덕면 방축리 287-9',
        SEC_CD: '41220330',
        sagoLon: '127.051',
        sagoLat: '37.0213',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '노후 우수관 파손에 따른 주변 지반 유실·침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '2000000',
        trFnDate: '2024-04-09'
      },
      geometry: { type: 'Point', coordinates: [127.051, 37.0213] }
    },
    {
      type: 'Feature',
      id: 1310,
      properties: {
        sagoDate: '2024-04-11',
        sagoNo: '20240035',
        sido: '대전광역시',
        sigungu: '중구',
        dong: '대사동',
        addr: '대사동 99-23번지',
        fullAdr: '대전광역시 중구 대사동 대사동 99-23번지',
        SEC_CD: '30140110',
        sagoLon: '127.425',
        sagoLat: '36.3172',
        sinkWidth: '0.3',
        sinkExtend: '0.3',
        sinkDepth: '1.8',
        grdKind: '',
        sagoDetail: '공공하수관 노후 손상되어 토사 유실·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-04-16'
      },
      geometry: { type: 'Point', coordinates: [127.425, 36.3172] }
    },
    {
      type: 'Feature',
      id: 1311,
      properties: {
        sagoDate: '2024-04-23',
        sagoNo: '20240042',
        sido: '세종특별자치시',
        sigungu: '세종특별자치시',
        dong: '어진동',
        addr: '633',
        fullAdr: '세종특별자치시 세종특별자치시 어진동 633',
        SEC_CD: '36110110',
        sagoLon: '127.257',
        sagoLat: '36.4976',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '상수도관 접합부 이탈에 따른 누수 발생으로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-04-24'
      },
      geometry: { type: 'Point', coordinates: [127.257, 36.4976] }
    },
    {
      type: 'Feature',
      id: 1312,
      properties: {
        sagoDate: '2024-04-12',
        sagoNo: '20240046',
        sido: '광주광역시',
        sigungu: '북구',
        dong: '양산동',
        addr: '401-33',
        fullAdr: '광주광역시 북구 양산동 401-33',
        SEC_CD: '29170128',
        sagoLon: '126.881',
        sagoLat: '35.1977',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.4',
        grdKind: '',
        sagoDetail: '지중선로 굴착공사 미흡',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-04-14'
      },
      geometry: { type: 'Point', coordinates: [126.881, 35.1977] }
    },
    {
      type: 'Feature',
      id: 1313,
      properties: {
        sagoDate: '2024-04-02',
        sagoNo: '20240050',
        sido: '경기도',
        sigungu: '의정부시',
        dong: '용현동',
        addr: '231',
        fullAdr: '경기도 의정부시 용현동 231',
        SEC_CD: '41150105',
        sagoLon: '127.085',
        sagoLat: '37.7326',
        sinkWidth: '1.2',
        sinkExtend: '1.2',
        sinkDepth: '0.6',
        grdKind: '',
        sagoDetail: '다짐불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '1500000',
        trFnDate: '2024-04-11'
      },
      geometry: { type: 'Point', coordinates: [127.085, 37.7326] }
    },
    {
      type: 'Feature',
      id: 1314,
      properties: {
        sagoDate: '2024-04-25',
        sagoNo: '20240054',
        sido: '부산광역시',
        sigungu: '사하구',
        dong: '괴정동',
        addr: '207-52번지 앞 도로',
        fullAdr: '부산광역시 사하구 괴정동 207-52번지 앞 도로',
        SEC_CD: '26380101',
        sagoLon: '129.002',
        sagoLat: '35.1055',
        sinkWidth: '6',
        sinkExtend: '2.7',
        sinkDepth: '0.7',
        grdKind: '',
        sagoDetail: '우수관로 주변 노후 및 다짐 불량으로 인한 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-04-27'
      },
      geometry: { type: 'Point', coordinates: [129.002, 35.1055] }
    },
    {
      type: 'Feature',
      id: 1315,
      properties: {
        sagoDate: '2024-04-26',
        sagoNo: '20240073',
        sido: '전라남도',
        sigungu: '완도군',
        dong: '완도읍',
        addr: '가용리 1088-5 인근',
        fullAdr: '전라남도 완도군 완도읍 가용리 1088-5 인근',
        SEC_CD: '46890250',
        sagoLon: '126.737',
        sagoLat: '34.3357',
        sinkWidth: '0.3',
        sinkExtend: '0.5',
        sinkDepth: '1.2',
        grdKind: '기타',
        sagoDetail: '우수관로 노후',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '20000000',
        trFnDate: '2024-04-27'
      },
      geometry: { type: 'Point', coordinates: [126.737, 34.3357] }
    },
    {
      type: 'Feature',
      id: 1316,
      properties: {
        sagoDate: '2024-05-01',
        sagoNo: '20240018',
        sido: '서울특별시',
        sigungu: '강남구',
        dong: '논현동',
        addr: '논현동 61-7 앞 도로',
        fullAdr: '서울특별시 강남구 논현동 논현동 61-7 앞 도로',
        SEC_CD: '11680108',
        sagoLon: '127.032',
        sagoLat: '37.5203',
        sinkWidth: '0.3',
        sinkExtend: '0.8',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '빗물받이 연결관이 노후·파손에 따른 토사 유실로 동공 및 침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-05-02'
      },
      geometry: { type: 'Point', coordinates: [127.032, 37.5203] }
    },
    {
      type: 'Feature',
      id: 1317,
      properties: {
        sagoDate: '2024-05-07',
        sagoNo: '20240019',
        sido: '부산광역시',
        sigungu: '연제구',
        dong: '연산동',
        addr: '1799-3번지(다온약국 앞)',
        fullAdr: '부산광역시 연제구 연산동 1799-3번지(다온약국 앞)',
        SEC_CD: '26470102',
        sagoLon: '129.094',
        sagoLat: '35.1735',
        sinkWidth: '0.7',
        sinkExtend: '3',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '원인불명',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-06-13'
      },
      geometry: { type: 'Point', coordinates: [129.094, 35.1735] }
    },
    {
      type: 'Feature',
      id: 1318,
      properties: {
        sagoDate: '2024-05-05',
        sagoNo: '20240020',
        sido: '부산광역시',
        sigungu: '사상구',
        dong: '감전동',
        addr: '162-22',
        fullAdr: '부산광역시 사상구 감전동 162-22',
        SEC_CD: '26530105',
        sagoLon: '128.982',
        sagoLat: '35.1511',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail:
          '- (4. 30.) 1차침하발생 시 상수관 및 하수관 파손· ·- (5.5.) 2차 침하 발생시 파손된 오수관(D200mm)으로 우수가 역류하여 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '10000000',
        trFnDate: '2024-05-17'
      },
      geometry: { type: 'Point', coordinates: [128.982, 35.1511] }
    },
    {
      type: 'Feature',
      id: 1319,
      properties: {
        sagoDate: '2024-05-14',
        sagoNo: '20240021',
        sido: '경상남도',
        sigungu: '진주시',
        dong: '상평동',
        addr: '대신로168',
        fullAdr: '경상남도 진주시 상평동 대신로168',
        SEC_CD: '48170121',
        sagoLon: '128.118',
        sagoLat: '35.1752',
        sinkWidth: '0.8',
        sinkExtend: '1.5',
        sinkDepth: '0.8',
        grdKind: '',
        sagoDetail: '하수관 손상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-05-31'
      },
      geometry: { type: 'Point', coordinates: [128.118, 35.1752] }
    },
    {
      type: 'Feature',
      id: 1320,
      properties: {
        sagoDate: '2024-05-14',
        sagoNo: '20240022',
        sido: '전라남도',
        sigungu: '화순군',
        dong: '화순읍',
        addr: '일심리 877',
        fullAdr: '전라남도 화순군 화순읍 일심리 877',
        SEC_CD: '46790250',
        sagoLon: '126.996',
        sagoLat: '35.0607',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '상수관 파손으로 인한 토사 유출로 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '5000000',
        trFnDate: '2024-05-15'
      },
      geometry: { type: 'Point', coordinates: [126.996, 35.0607] }
    },
    {
      type: 'Feature',
      id: 1321,
      properties: {
        sagoDate: '2024-05-29',
        sagoNo: '20240023',
        sido: '부산광역시',
        sigungu: '서구',
        dong: '충무동1가',
        addr: '39-11',
        fullAdr: '부산광역시 서구 충무동1가 39-11',
        SEC_CD: '26140120',
        sagoLon: '129.024',
        sagoLat: '35.0935',
        sinkWidth: '1.5',
        sinkExtend: '2.3',
        sinkDepth: '0.7',
        grdKind: '',
        sagoDetail: '상수도 폐관 (D=200mm)누수 에 의한 지반 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-05-29'
      },
      geometry: { type: 'Point', coordinates: [129.024, 35.0935] }
    },
    {
      type: 'Feature',
      id: 1322,
      properties: {
        sagoDate: '2024-05-13',
        sagoNo: '20240033',
        sido: '경기도',
        sigungu: '평택시',
        dong: '평택동',
        addr: '201-2',
        fullAdr: '경기도 평택시 평택동 201-2',
        SEC_CD: '41220113',
        sagoLon: '127.09',
        sagoLat: '36.9882',
        sinkWidth: '0.8',
        sinkExtend: '0.8',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '노후 콘크리트 관 손상에 따른 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '44000000',
        trFnDate: '2024-05-15'
      },
      geometry: { type: 'Point', coordinates: [127.09, 36.9882] }
    },
    {
      type: 'Feature',
      id: 1323,
      properties: {
        sagoDate: '2024-05-16',
        sagoNo: '20240036',
        sido: '대전광역시',
        sigungu: '중구',
        dong: '사정동',
        addr: '사정동 441-6',
        fullAdr: '대전광역시 중구 사정동 사정동 441-6',
        SEC_CD: '30140118',
        sagoLon: '127.388',
        sagoLat: '36.2959',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '2.2',
        grdKind: '',
        sagoDetail: '공공하수관 노후 손상되어 토사 유실·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-05-17'
      },
      geometry: { type: 'Point', coordinates: [127.388, 36.2959] }
    },
    {
      type: 'Feature',
      id: 1324,
      properties: {
        sagoDate: '2024-05-27',
        sagoNo: '20240037',
        sido: '대전광역시',
        sigungu: '중구',
        dong: '사정동',
        addr: '사정동 405-7',
        fullAdr: '대전광역시 중구 사정동 사정동 405-7',
        SEC_CD: '30140118',
        sagoLon: '127.386',
        sagoLat: '36.2961',
        sinkWidth: '0.1',
        sinkExtend: '0.1',
        sinkDepth: '2.4',
        grdKind: '',
        sagoDetail: '하수 맨홀 벽체파손으로 인해 토사 유실·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-05-28'
      },
      geometry: { type: 'Point', coordinates: [127.386, 36.2961] }
    },
    {
      type: 'Feature',
      id: 1325,
      properties: {
        sagoDate: '2024-05-29',
        sagoNo: '20240043',
        sido: '세종특별자치시',
        sigungu: '세종특별자치시',
        dong: '반곡동',
        addr: '산211-1',
        fullAdr: '세종특별자치시 세종특별자치시 반곡동 산211-1',
        SEC_CD: '36110101',
        sagoLon: '127.317',
        sagoLat: '36.4838',
        sinkWidth: '0.3',
        sinkExtend: '2',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail: '우수맨홀 접합부 이상으로 우수 유출',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '3815000',
        trFnDate: '2024-05-30'
      },
      geometry: { type: 'Point', coordinates: [127.317, 36.4838] }
    },
    {
      type: 'Feature',
      id: 1326,
      properties: {
        sagoDate: '2024-05-28',
        sagoNo: '20240047',
        sido: '제주특별자치도',
        sigungu: '제주시',
        dong: '이도이동',
        addr: '서광로 274',
        fullAdr: '제주특별자치도 제주시 이도이동 서광로 274',
        SEC_CD: '50110104',
        sagoLon: '126.526',
        sagoLat: '33.5001',
        sinkWidth: '0.25',
        sinkExtend: '0.4',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '맨홀 하부 파손 등에 따른 토사 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-06-10'
      },
      geometry: { type: 'Point', coordinates: [126.526, 33.5001] }
    },
    {
      type: 'Feature',
      id: 1327,
      properties: {
        sagoDate: '2024-05-01',
        sagoNo: '20240051',
        sido: '경기도',
        sigungu: '의정부시',
        dong: '호원동',
        addr: '286-15',
        fullAdr: '경기도 의정부시 호원동 286-15',
        SEC_CD: '41150102',
        sagoLon: '127.046',
        sagoLat: '37.7203',
        sinkWidth: '1',
        sinkExtend: '3',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '배수설비 연결부 마감부분 노후로 인한 지반 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '3000000',
        trFnDate: '2024-06-29'
      },
      geometry: { type: 'Point', coordinates: [127.046, 37.7203] }
    },
    {
      type: 'Feature',
      id: 1328,
      properties: {
        sagoDate: '2024-05-09',
        sagoNo: '20240069',
        sido: '부산광역시',
        sigungu: '동래구',
        dong: '사직동',
        addr: '981-99번지',
        fullAdr: '부산광역시 동래구 사직동 981-99번지',
        SEC_CD: '26260109',
        sagoLon: '129.053',
        sagoLat: '35.198',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.2',
        grdKind: '기타',
        sagoDetail: '미인발된 토류판 부식부를 통해 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-05-10'
      },
      geometry: { type: 'Point', coordinates: [129.053, 35.198] }
    },
    {
      type: 'Feature',
      id: 1329,
      properties: {
        sagoDate: '2024-06-16',
        sagoNo: '20240024',
        sido: '경기도',
        sigungu: '용인시 처인구',
        dong: '원삼면',
        addr: '1045-7번지 일원',
        fullAdr: '경기도 용인시 처인구 원삼면 1045-7번지 일원',
        SEC_CD: '41461340',
        sagoLon: '127.302',
        sagoLat: '37.1313',
        sinkWidth: '6',
        sinkExtend: '6',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '용인반도체 클러스터 일반산업단지 전력구 공사 터널 공사 중 발생 직경 6m· 깊이 1.5m',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '300000000',
        trFnDate: '2024-06-16'
      },
      geometry: { type: 'Point', coordinates: [127.302, 37.1313] }
    },
    {
      type: 'Feature',
      id: 1330,
      properties: {
        sagoDate: '2024-06-24',
        sagoNo: '20240025',
        sido: '경기도',
        sigungu: '평택시',
        dong: '이충동',
        addr: '346-13 일원(부영아파트사거리)',
        fullAdr: '경기도 평택시 이충동 346-13 일원(부영아파트사거리)',
        SEC_CD: '41220109',
        sagoLon: '127.065',
        sagoLat: '37.06',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail:
          '직경 600mm 상수도관 연결부(조인트) 누수로 인해 도로상 싱크홀 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '10000000',
        trFnDate: '2024-06-24'
      },
      geometry: { type: 'Point', coordinates: [127.065, 37.06] }
    },
    {
      type: 'Feature',
      id: 1331,
      properties: {
        sagoDate: '2024-06-20',
        sagoNo: '20240026',
        sido: '인천광역시',
        sigungu: '연수구',
        dong: '송도동',
        addr: '23-5번지',
        fullAdr: '인천광역시 연수구 송도동 23-5번지',
        SEC_CD: '28185106',
        sagoLon: '126.642',
        sagoLat: '37.3932',
        sinkWidth: '2',
        sinkExtend: '1',
        sinkDepth: '0.8',
        grdKind: '',
        sagoDetail: '상수도관 파열',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-06-23'
      },
      geometry: { type: 'Point', coordinates: [126.642, 37.3932] }
    },
    {
      type: 'Feature',
      id: 1332,
      properties: {
        sagoDate: '2024-06-30',
        sagoNo: '20240027',
        sido: '인천광역시',
        sigungu: '연수구',
        dong: '송도동',
        addr: '313-12',
        fullAdr: '인천광역시 연수구 송도동 313-12',
        SEC_CD: '28185106',
        sagoLon: '126.629',
        sagoLat: '37.4109',
        sinkWidth: '1.5',
        sinkExtend: '32',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail:
          '흙막이 공사를 하면서 옆지반 물빠짐으로 인한 지반 일부 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2024-07-01'
      },
      geometry: { type: 'Point', coordinates: [126.629, 37.4109] }
    },
    {
      type: 'Feature',
      id: 1333,
      properties: {
        sagoDate: '2024-06-27',
        sagoNo: '20240032',
        sido: '광주광역시',
        sigungu: '서구',
        dong: '벽진동',
        addr: '22',
        fullAdr: '광주광역시 서구 벽진동 22',
        SEC_CD: '29140129',
        sagoLon: '126.831',
        sagoLat: '35.14',
        sinkWidth: '3',
        sinkExtend: '5',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '관로유실로 인한 도로침하 발생·',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-06-27'
      },
      geometry: { type: 'Point', coordinates: [126.831, 35.14] }
    },
    {
      type: 'Feature',
      id: 1334,
      properties: {
        sagoDate: '2024-07-06',
        sagoNo: '20240028',
        sido: '경상남도',
        sigungu: '양산시',
        dong: '북정동',
        addr: '290-6',
        fullAdr: '경상남도 양산시 북정동 290-6',
        SEC_CD: '48330107',
        sagoLon: '129.041',
        sagoLat: '35.3613',
        sinkWidth: '2.5',
        sinkExtend: '2.5',
        sinkDepth: '1.5',
        grdKind: '기타',
        sagoDetail:
          '2024. 6. 29.~7.2.(4일 누계 180mm) 호우 및 지하수위 상승/하락 반복으로 인한 하부토사 유실로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '2',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2024-07-06'
      },
      geometry: { type: 'Point', coordinates: [129.041, 35.3613] }
    },
    {
      type: 'Feature',
      id: 1335,
      properties: {
        sagoDate: '2024-07-11',
        sagoNo: '20240029',
        sido: '경기도',
        sigungu: '수원시 팔달구',
        dong: '인계동',
        addr: '1142(경기도 수원시 팔달구 효원로 286-2)',
        fullAdr:
          '경기도 수원시 팔달구 인계동 1142(경기도 수원시 팔달구 효원로 286-2)',
        SEC_CD: '41115141',
        sagoLon: '127.033',
        sagoLat: '37.2614',
        sinkWidth: '0.4',
        sinkExtend: '6.5',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '집중호우 시 우수침투로 인한 공동 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-07-11'
      },
      geometry: { type: 'Point', coordinates: [127.033, 37.2614] }
    },
    {
      type: 'Feature',
      id: 1336,
      properties: {
        sagoDate: '2024-07-13',
        sagoNo: '20240030',
        sido: '서울특별시',
        sigungu: '마포구',
        dong: '서교동',
        addr: '353-1',
        fullAdr: '서울특별시 마포구 서교동 353-1',
        SEC_CD: '11440120',
        sagoLon: '126.921',
        sagoLat: '37.5551',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '하수맨홀과 하수관(D1·000mm) 이음부 파손에 따른 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-07-14'
      },
      geometry: { type: 'Point', coordinates: [126.921, 37.5551] }
    },
    {
      type: 'Feature',
      id: 1337,
      properties: {
        sagoDate: '2024-07-16',
        sagoNo: '20240041',
        sido: '경기도',
        sigungu: '남양주시',
        dong: '진접읍',
        addr: '내각리 440',
        fullAdr: '경기도 남양주시 진접읍 내각리 440',
        SEC_CD: '41360253',
        sagoLon: '127.166',
        sagoLat: '37.6983',
        sinkWidth: '17',
        sinkExtend: '6',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '오수 우수 암거 굴착공사',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-07-16'
      },
      geometry: { type: 'Point', coordinates: [127.166, 37.6983] }
    },
    {
      type: 'Feature',
      id: 1338,
      properties: {
        sagoDate: '2024-07-16',
        sagoNo: '20240044',
        sido: '세종특별자치시',
        sigungu: '세종특별자치시',
        dong: '어진동',
        addr: '662',
        fullAdr: '세종특별자치시 세종특별자치시 어진동 662',
        SEC_CD: '36110110',
        sagoLon: '127.258',
        sagoLat: '36.4965',
        sinkWidth: '2',
        sinkExtend: '1.5',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail: '하수관',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '0',
        trFnDate: '2024-07-20'
      },
      geometry: { type: 'Point', coordinates: [127.258, 36.4965] }
    },
    {
      type: 'Feature',
      id: 1339,
      properties: {
        sagoDate: '2024-07-18',
        sagoNo: '20240045',
        sido: '경기도',
        sigungu: '용인시 수지구',
        dong: '성복동',
        addr: '361-11',
        fullAdr: '경기도 용인시 수지구 성복동 361-11',
        SEC_CD: '41465106',
        sagoLon: '127.06',
        sagoLat: '37.3226',
        sinkWidth: '3',
        sinkExtend: '4',
        sinkDepth: '5',
        grdKind: '',
        sagoDetail: '우수관 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2024-07-19'
      },
      geometry: { type: 'Point', coordinates: [127.06, 37.3226] }
    },
    {
      type: 'Feature',
      id: 1340,
      properties: {
        sagoDate: '2024-07-22',
        sagoNo: '20240052',
        sido: '서울특별시',
        sigungu: '송파구',
        dong: '삼전동',
        addr: '100-7',
        fullAdr: '서울특별시 송파구 삼전동 100-7',
        SEC_CD: '11710106',
        sagoLon: '127.092',
        sagoLat: '37.504',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.7',
        grdKind: '기타',
        sagoDetail: '상수관 100mm관 누수로 인한 도로동공발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-07-22'
      },
      geometry: { type: 'Point', coordinates: [127.092, 37.504] }
    },
    {
      type: 'Feature',
      id: 1341,
      properties: {
        sagoDate: '2024-07-11',
        sagoNo: '20240053',
        sido: '대구광역시',
        sigungu: '군위군',
        dong: '군위읍',
        addr: '수서리 1474-5',
        fullAdr: '대구광역시 군위군 군위읍 수서리 1474-5',
        SEC_CD: '27720250',
        sagoLon: '128.553',
        sagoLat: '36.187',
        sinkWidth: '1.5',
        sinkExtend: '12',
        sinkDepth: '3',
        grdKind: '기타',
        sagoDetail: '집중호우로 인한 횡배수관 유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-07-11'
      },
      geometry: { type: 'Point', coordinates: [128.553, 36.187] }
    },
    {
      type: 'Feature',
      id: 1342,
      properties: {
        sagoDate: '2024-07-23',
        sagoNo: '20240055',
        sido: '대구광역시',
        sigungu: '북구',
        dong: '구암동',
        addr: '835-1',
        fullAdr: '대구광역시 북구 구암동 835-1',
        SEC_CD: '27230123',
        sagoLon: '128.566',
        sagoLat: '35.9331',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1.5',
        grdKind: '',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-07-23'
      },
      geometry: { type: 'Point', coordinates: [128.566, 35.9331] }
    },
    {
      type: 'Feature',
      id: 1343,
      properties: {
        sagoDate: '2024-07-23',
        sagoNo: '20240056',
        sido: '경상남도',
        sigungu: '진주시',
        dong: '상평동',
        addr: '308-5',
        fullAdr: '경상남도 진주시 상평동 308-5',
        SEC_CD: '48170121',
        sagoLon: '128.122',
        sagoLat: '35.1697',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.6',
        grdKind: '',
        sagoDetail: '지하수위 변화에 따른 토사유실 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-07-23'
      },
      geometry: { type: 'Point', coordinates: [128.122, 35.1697] }
    },
    {
      type: 'Feature',
      id: 1344,
      properties: {
        sagoDate: '2024-07-23',
        sagoNo: '20240057',
        sido: '경기도',
        sigungu: '남양주시',
        dong: '진건읍',
        addr: '용정리 788-5',
        fullAdr: '경기도 남양주시 진건읍 용정리 788-5',
        SEC_CD: '41360259',
        sagoLon: '127.181',
        sagoLat: '37.6573',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '0.5',
        grdKind: '호상편마암',
        sagoDetail: '하수관거(우수 및 오수) 파손(5~7m)으로 인한 싱크홀 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '3000000',
        trFnDate: '2024-09-09'
      },
      geometry: { type: 'Point', coordinates: [127.181, 37.6573] }
    },
    {
      type: 'Feature',
      id: 1345,
      properties: {
        sagoDate: '2024-07-23',
        sagoNo: '20240058',
        sido: '경기도',
        sigungu: '고양시 일산서구',
        dong: '대화동',
        addr: '2428',
        fullAdr: '경기도 고양시 일산서구 대화동 2428',
        SEC_CD: '41287104',
        sagoLon: '126.744',
        sagoLat: '37.6739',
        sinkWidth: '2',
        sinkExtend: '3',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '빗물받이 연결관 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-07-24'
      },
      geometry: { type: 'Point', coordinates: [126.744, 37.6739] }
    },
    {
      type: 'Feature',
      id: 1346,
      properties: {
        sagoDate: '2024-07-24',
        sagoNo: '20240059',
        sido: '부산광역시',
        sigungu: '사상구',
        dong: '감전동',
        addr: '163-6(새벽로 117 인근)',
        fullAdr: '부산광역시 사상구 감전동 163-6(새벽로 117 인근)',
        SEC_CD: '26530105',
        sagoLon: '128.982',
        sagoLat: '35.1519',
        sinkWidth: '1',
        sinkExtend: '1.5',
        sinkDepth: '3',
        grdKind: '기타',
        sagoDetail: '오수관로 파손 및 누수에 따른 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-07-25'
      },
      geometry: { type: 'Point', coordinates: [128.982, 35.1519] }
    },
    {
      type: 'Feature',
      id: 1347,
      properties: {
        sagoDate: '2024-07-22',
        sagoNo: '20240062',
        sido: '경상북도',
        sigungu: '경주시',
        dong: '황성동',
        addr: '342-7 일원',
        fullAdr: '경상북도 경주시 황성동 342-7 일원',
        SEC_CD: '47130125',
        sagoLon: '129.221',
        sagoLat: '35.8608',
        sinkWidth: '0.6',
        sinkExtend: '0.6',
        sinkDepth: '2.5',
        grdKind: '기타',
        sagoDetail:
          '2차선 도로 하단의 2.5m 심도의 오수관로 흄관(D600mm) 상단의 일부 파손으로 토사 유실이 발생하여 폭 0.6m· 연장 0.6m· 깊이 2.5m의 싱크홀 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-07-23'
      },
      geometry: { type: 'Point', coordinates: [129.221, 35.8608] }
    },
    {
      type: 'Feature',
      id: 1348,
      properties: {
        sagoDate: '2024-07-25',
        sagoNo: '20240063',
        sido: '경기도',
        sigungu: '평택시',
        dong: '독곡동',
        addr: '495번지 일원',
        fullAdr: '경기도 평택시 독곡동 495번지 일원',
        SEC_CD: '41220111',
        sagoLon: '127.064',
        sagoLat: '37.0833',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail:
          '상수관로 배수관(D150mm) 지관 파열로 인한 소규모 싱크홀 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '15000000',
        trFnDate: '2024-07-25'
      },
      geometry: { type: 'Point', coordinates: [127.064, 37.0833] }
    },
    {
      type: 'Feature',
      id: 1349,
      properties: {
        sagoDate: '2024-07-26',
        sagoNo: '20240064',
        sido: '부산광역시',
        sigungu: '서구',
        dong: '충무동2가',
        addr: '4-5',
        fullAdr: '부산광역시 서구 충무동2가 4-5',
        SEC_CD: '26140121',
        sagoLon: '129.023',
        sagoLat: '35.0954',
        sinkWidth: '0.6',
        sinkExtend: '1.2',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail:
          '하수박스(1.2x1.0) 하단 파손으로 인한 토사 유출로 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '0',
        trFnDate: '2024-07-27'
      },
      geometry: { type: 'Point', coordinates: [129.023, 35.0954] }
    },
    {
      type: 'Feature',
      id: 1350,
      properties: {
        sagoDate: '2024-07-27',
        sagoNo: '20240065',
        sido: '서울특별시',
        sigungu: '성북구',
        dong: '하월곡동',
        addr: '88-1',
        fullAdr: '서울특별시 성북구 하월곡동 88-1',
        SEC_CD: '11290136',
        sagoLon: '127.031',
        sagoLat: '37.6063',
        sinkWidth: '2',
        sinkExtend: '5',
        sinkDepth: '3',
        grdKind: '',
        sagoDetail: '집중호우로 인한 공동 발생.',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '0',
        trFnDate: '2024-08-05'
      },
      geometry: { type: 'Point', coordinates: [127.031, 37.6063] }
    },
    {
      type: 'Feature',
      id: 1351,
      properties: {
        sagoDate: '2024-07-28',
        sagoNo: '20240066',
        sido: '부산광역시',
        sigungu: '영도구',
        dong: '동삼동',
        addr: '510-42',
        fullAdr: '부산광역시 영도구 동삼동 510-42',
        SEC_CD: '26200121',
        sagoLon: '129.067',
        sagoLat: '35.0742',
        sinkWidth: '3',
        sinkExtend: '2',
        sinkDepth: '2',
        grdKind: '',
        sagoDetail: '하수 흄관(d700mm) 파손으로 인한 도로 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-07-30'
      },
      geometry: { type: 'Point', coordinates: [129.067, 35.0742] }
    },
    {
      type: 'Feature',
      id: 1352,
      properties: {
        sagoDate: '2024-07-03',
        sagoNo: '20240068',
        sido: '부산광역시',
        sigungu: '동래구',
        dong: '온천동',
        addr: '1441-13번지',
        fullAdr: '부산광역시 동래구 온천동 1441-13번지',
        SEC_CD: '26260108',
        sagoLon: '129.077',
        sagoLat: '35.2048',
        sinkWidth: '0.6',
        sinkExtend: '1',
        sinkDepth: '2',
        grdKind: '기타',
        sagoDetail: '원인불명',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-07-06'
      },
      geometry: { type: 'Point', coordinates: [129.077, 35.2048] }
    },
    {
      type: 'Feature',
      id: 1353,
      properties: {
        sagoDate: '2024-07-31',
        sagoNo: '20240070',
        sido: '경기도',
        sigungu: '평택시',
        dong: '서탄면',
        addr: '서탄로 495-56',
        fullAdr: '경기도 평택시 서탄면 서탄로 495-56',
        SEC_CD: '41220320',
        sagoLon: '127.017',
        sagoLat: '37.1176',
        sinkWidth: '0.5',
        sinkExtend: '0.5',
        sinkDepth: '1.1',
        grdKind: '충척층',
        sagoDetail:
          '- 맨홀과 우수관 연결부 틈새로 주변토사 및 우수 유.출입됨에 따라 인근 토사 유실.·- 유실된 토사와 동시에 주변층 침하로 이어져 싱크홀 발생.',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '3800000',
        trFnDate: '2024-08-01'
      },
      geometry: { type: 'Point', coordinates: [127.017, 37.1176] }
    },
    {
      type: 'Feature',
      id: 1354,
      properties: {
        sagoDate: '2024-07-25',
        sagoNo: '20240072',
        sido: '경기도',
        sigungu: '용인시 기흥구',
        dong: '공세동',
        addr: '695번지',
        fullAdr: '경기도 용인시 기흥구 공세동 695번지',
        SEC_CD: '41463107',
        sagoLon: '127.111',
        sagoLat: '37.2402',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '0.7',
        grdKind: '',
        sagoDetail: '우수관 혹은 전력관 파손으로 인한 침하 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '0',
        trFnDate: '2024-08-07'
      },
      geometry: { type: 'Point', coordinates: [127.111, 37.2402] }
    },
    {
      type: 'Feature',
      id: 1355,
      properties: {
        sagoDate: '2024-07-31',
        sagoNo: '20240087',
        sido: '부산광역시',
        sigungu: '사상구',
        dong: '주례동',
        addr: '가야대로 356',
        fullAdr: '부산광역시 사상구 주례동 가야대로 356',
        SEC_CD: '26530106',
        sagoLon: '129.012',
        sagoLat: '35.1509',
        sinkWidth: '1',
        sinkExtend: '1.8',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '노후 하수관로(D600mm) 파손에 의한 보도 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-08-07'
      },
      geometry: { type: 'Point', coordinates: [129.012, 35.1509] }
    },
    {
      type: 'Feature',
      id: 1356,
      properties: {
        sagoDate: '2024-07-19',
        sagoNo: '20240097',
        sido: '경기도',
        sigungu: '파주시',
        dong: '문발동',
        addr: '650',
        fullAdr: '경기도 파주시 문발동 650',
        SEC_CD: '41480115',
        sagoLon: '126.692',
        sagoLat: '37.7241',
        sinkWidth: '3',
        sinkExtend: '5',
        sinkDepth: '0.1',
        grdKind: '기타',
        sagoDetail: '임시가설수도관 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2024-07-19'
      },
      geometry: { type: 'Point', coordinates: [126.692, 37.7241] }
    },
    {
      type: 'Feature',
      id: 1357,
      properties: {
        sagoDate: '2024-07-20',
        sagoNo: '20240098',
        sido: '경기도',
        sigungu: '파주시',
        dong: '문발동',
        addr: '667',
        fullAdr: '경기도 파주시 문발동 667',
        SEC_CD: '41480115',
        sagoLon: '126.692',
        sagoLat: '37.7233',
        sinkWidth: '3',
        sinkExtend: '55',
        sinkDepth: '0.3',
        grdKind: '기타',
        sagoDetail:
          '집중호우로 인한 오수관로 우수 유입 및 토사유실 및 저면 펄 유입',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2024-07-21'
      },
      geometry: { type: 'Point', coordinates: [126.692, 37.7233] }
    },
    {
      type: 'Feature',
      id: 1358,
      properties: {
        sagoDate: '2024-07-30',
        sagoNo: '20240099',
        sido: '경기도',
        sigungu: '파주시',
        dong: '문발동',
        addr: '667',
        fullAdr: '경기도 파주시 문발동 667',
        SEC_CD: '41480115',
        sagoLon: '126.693',
        sagoLat: '37.7237',
        sinkWidth: '3',
        sinkExtend: '5',
        sinkDepth: '0.2',
        grdKind: '기타',
        sagoDetail: '지하수 유입으로 인한 보일링현상',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2024-07-30'
      },
      geometry: { type: 'Point', coordinates: [126.693, 37.7237] }
    },
    {
      type: 'Feature',
      id: 1359,
      properties: {
        sagoDate: '2024-08-01',
        sagoNo: '20240067',
        sido: '경기도',
        sigungu: '군포시',
        dong: '산본동',
        addr: '1157',
        fullAdr: '경기도 군포시 산본동 1157',
        SEC_CD: '41410104',
        sagoLon: '126.939',
        sagoLat: '37.376',
        sinkWidth: '0.8',
        sinkExtend: '10',
        sinkDepth: '0.5',
        grdKind: '기타',
        sagoDetail: '',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구중',
        trAmt: '0',
        trFnDate: '2024-08-16'
      },
      geometry: { type: 'Point', coordinates: [126.939, 37.376] }
    },
    {
      type: 'Feature',
      id: 1360,
      properties: {
        sagoDate: '2024-08-20',
        sagoNo: '20240074',
        sido: '부산광역시',
        sigungu: '사상구',
        dong: '감전동',
        addr: '162-26(새벽로 122 인근)',
        fullAdr: '부산광역시 사상구 감전동 162-26(새벽로 122 인근)',
        SEC_CD: '26530105',
        sagoLon: '128.982',
        sagoLat: '35.152',
        sinkWidth: '7',
        sinkExtend: '5',
        sinkDepth: '3.5',
        grdKind: '기타',
        sagoDetail: '- 지하사고조위원회 원인 파악 중',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-09-06'
      },
      geometry: { type: 'Point', coordinates: [128.982, 35.152] }
    },
    {
      type: 'Feature',
      id: 1361,
      properties: {
        sagoDate: '2024-08-21',
        sagoNo: '20240075',
        sido: '부산광역시',
        sigungu: '사상구',
        dong: '감전동',
        addr: '163-11번지 인근',
        fullAdr: '부산광역시 사상구 감전동 163-11번지 인근',
        SEC_CD: '26530105',
        sagoLon: '128.982',
        sagoLat: '35.1509',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '기타',
        sagoDetail: '원인 파악 중',
        deathCnt: '0',
        injuryCnt: '1',
        vehicleCnt: '1',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-09-06'
      },
      geometry: { type: 'Point', coordinates: [128.982, 35.1509] }
    },
    {
      type: 'Feature',
      id: 1362,
      properties: {
        sagoDate: '2024-08-18',
        sagoNo: '20240076',
        sido: '경상남도',
        sigungu: '창원시 마산합포구',
        dong: '자산동',
        addr: '31',
        fullAdr: '경상남도 창원시 마산합포구 자산동 31',
        SEC_CD: '48125140',
        sagoLon: '128.565',
        sagoLat: '35.2056',
        sinkWidth: '0.8',
        sinkExtend: '2',
        sinkDepth: '2.3',
        grdKind: '기타',
        sagoDetail:
          '하수관로(미상· HP· D900· 우수) 파손· 장마기간 호우 및 인근 지하수 영향으로 인한 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-08-18'
      },
      geometry: { type: 'Point', coordinates: [128.565, 35.2056] }
    },
    {
      type: 'Feature',
      id: 1363,
      properties: {
        sagoDate: '2024-08-29',
        sagoNo: '20240077',
        sido: '서울특별시',
        sigungu: '서대문구',
        dong: '연희동',
        addr: '413-128',
        fullAdr: '서울특별시 서대문구 연희동 413-128',
        SEC_CD: '11410117',
        sagoLon: '126.924',
        sagoLat: '37.5665',
        sinkWidth: '4',
        sinkExtend: '6',
        sinkDepth: '2.5',
        grdKind: '',
        sagoDetail: '원인 미상',
        deathCnt: '0',
        injuryCnt: '2',
        vehicleCnt: '1',
        trStatus: '복구중',
        trAmt: '0',
        trFnDate: '2024-08-29'
      },
      geometry: { type: 'Point', coordinates: [126.924, 37.5665] }
    },
    {
      type: 'Feature',
      id: 1364,
      properties: {
        sagoDate: '2024-08-28',
        sagoNo: '20240078',
        sido: '서울특별시',
        sigungu: '송파구',
        dong: '풍납동',
        addr: '490-6',
        fullAdr: '서울특별시 송파구 풍납동 490-6',
        SEC_CD: '11740108',
        sagoLon: '127.122',
        sagoLat: '37.5345',
        sinkWidth: '3',
        sinkExtend: '1',
        sinkDepth: '0.1',
        grdKind: '',
        sagoDetail: '도로하부 연약지반 장기압밀침하 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-08-28'
      },
      geometry: { type: 'Point', coordinates: [127.122, 37.5345] }
    },
    {
      type: 'Feature',
      id: 1365,
      properties: {
        sagoDate: '2024-08-30',
        sagoNo: '20240079',
        sido: '서울특별시',
        sigungu: '서대문구',
        dong: '연희동',
        addr: '413-5',
        fullAdr: '서울특별시 서대문구 연희동 413-5',
        SEC_CD: '11410117',
        sagoLon: '126.925',
        sagoLat: '37.5664',
        sinkWidth: '1.5',
        sinkExtend: '1.5',
        sinkDepth: '0.08',
        grdKind: '',
        sagoDetail: '하수박스 연결부 채움재 불량',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2024-08-30'
      },
      geometry: { type: 'Point', coordinates: [126.925, 37.5664] }
    },
    {
      type: 'Feature',
      id: 1366,
      properties: {
        sagoDate: '2024-08-31',
        sagoNo: '20240080',
        sido: '대구광역시',
        sigungu: '동구',
        dong: '방촌동',
        addr: '1020-41',
        fullAdr: '대구광역시 동구 방촌동 1020-41',
        SEC_CD: '27140111',
        sagoLon: '128.66',
        sagoLat: '35.8791',
        sinkWidth: '0.5',
        sinkExtend: '0.3',
        sinkDepth: '1.7',
        grdKind: '',
        sagoDetail:
          '-사고개요: 2024. 8. 31.(토) 12:28경 대구광역시 동구 방촌동 1020-41 삼거리 노상에 싱크홀이 발생하여 당직실 근무자 및 건설과 도로보수팀 담당자가 현장 출동하여 현장 통제·-발생원인 : 인근 하수관로 파열로 인해 물이 흘러들어 지반이 내려 앉은 것으로 추정',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-08-31'
      },
      geometry: { type: 'Point', coordinates: [128.66, 35.8791] }
    },
    {
      type: 'Feature',
      id: 1367,
      properties: {
        sagoDate: '2024-08-31',
        sagoNo: '20240081',
        sido: '서울특별시',
        sigungu: '강남구',
        dong: '역삼동',
        addr: '650',
        fullAdr: '서울특별시 강남구 역삼동 650',
        SEC_CD: '11680101',
        sagoLon: '127.034',
        sagoLat: '37.507',
        sinkWidth: '1.5',
        sinkExtend: '2',
        sinkDepth: '0.06',
        grdKind: '',
        sagoDetail: '장기 압밀침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2024-08-31'
      },
      geometry: { type: 'Point', coordinates: [127.034, 37.507] }
    },
    {
      type: 'Feature',
      id: 1368,
      properties: {
        sagoDate: '2024-08-20',
        sagoNo: '20240100',
        sido: '경기도',
        sigungu: '파주시',
        dong: '문발동',
        addr: '649',
        fullAdr: '경기도 파주시 문발동 649',
        SEC_CD: '41480115',
        sagoLon: '126.691',
        sagoLat: '37.7234',
        sinkWidth: '5',
        sinkExtend: '50',
        sinkDepth: '0.3',
        grdKind: '기타',
        sagoDetail:
          '시트 파일 시공장비로 인한 진동 및 하중에 의해 지반침하 발생 및 실트질의 액상화',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2024-08-27'
      },
      geometry: { type: 'Point', coordinates: [126.691, 37.7234] }
    },
    {
      type: 'Feature',
      id: 1369,
      properties: {
        sagoDate: '2024-09-01',
        sagoNo: '20240082',
        sido: '서울특별시',
        sigungu: '구로구',
        dong: '구로동',
        addr: '231-4',
        fullAdr: '서울특별시 구로구 구로동 231-4',
        SEC_CD: '11530102',
        sagoLon: '126.893',
        sagoLat: '37.4824',
        sinkWidth: '0.8',
        sinkExtend: '0.8',
        sinkDepth: '1.2',
        grdKind: '',
        sagoDetail: '하수박스 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2024-09-01'
      },
      geometry: { type: 'Point', coordinates: [126.893, 37.4824] }
    },
    {
      type: 'Feature',
      id: 1370,
      properties: {
        sagoDate: '2024-09-08',
        sagoNo: '20240085',
        sido: '서울특별시',
        sigungu: '종로구',
        dong: '효제동',
        addr: '291-9',
        fullAdr: '서울특별시 종로구 효제동 291-9',
        SEC_CD: '11110160',
        sagoLon: '127.002',
        sagoLat: '37.5734',
        sinkWidth: '0.12',
        sinkExtend: '0.12',
        sinkDepth: '1.2',
        grdKind: '기타',
        sagoDetail: '체신(통신)맨홀 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2024-09-08'
      },
      geometry: { type: 'Point', coordinates: [127.002, 37.5734] }
    },
    {
      type: 'Feature',
      id: 1371,
      properties: {
        sagoDate: '2024-09-10',
        sagoNo: '20240086',
        sido: '부산광역시',
        sigungu: '영도구',
        dong: '청학동',
        addr: '조내기로 33',
        fullAdr: '부산광역시 영도구 청학동 조내기로 33',
        SEC_CD: '26200120',
        sagoLon: '129.064',
        sagoLat: '35.0865',
        sinkWidth: '1',
        sinkExtend: '1',
        sinkDepth: '1',
        grdKind: '',
        sagoDetail: '횡단측구 벽체 파손',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-09-11'
      },
      geometry: { type: 'Point', coordinates: [129.064, 35.0865] }
    },
    {
      type: 'Feature',
      id: 1372,
      properties: {
        sagoDate: '2024-09-13',
        sagoNo: '20240088',
        sido: '서울특별시',
        sigungu: '성동구',
        dong: '용답동',
        addr: '63-3',
        fullAdr: '서울특별시 성동구 용답동 63-3',
        SEC_CD: '11200122',
        sagoLon: '127.054',
        sagoLat: '37.5654',
        sinkWidth: '3',
        sinkExtend: '1.7',
        sinkDepth: '1.3',
        grdKind: '기타',
        sagoDetail: '건축공사장 지하수 유출로 인한 토사유실',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-09-13'
      },
      geometry: { type: 'Point', coordinates: [127.054, 37.5654] }
    },
    {
      type: 'Feature',
      id: 1373,
      properties: {
        sagoDate: '2024-09-17',
        sagoNo: '20240094',
        sido: '전북특별자치도',
        sigungu: '군산시',
        dong: '소룡동',
        addr: '1675',
        fullAdr: '전북특별자치도 군산시 소룡동 1675',
        SEC_CD: '52130146',
        sagoLon: '126.678',
        sagoLat: '35.9835',
        sinkWidth: '2.3',
        sinkExtend: '1.3',
        sinkDepth: '1.2',
        grdKind: '기타',
        sagoDetail: '흄관파손으로 인한 지반침하 발생',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-09-23'
      },
      geometry: { type: 'Point', coordinates: [126.678, 35.9835] }
    },
    {
      type: 'Feature',
      id: 1374,
      properties: {
        sagoDate: '2024-09-21',
        sagoNo: '20240095',
        sido: '부산광역시',
        sigungu: '사상구',
        dong: '학장동',
        addr: '718-2 (새벽로 89)',
        fullAdr: '부산광역시 사상구 학장동 718-2 (새벽로 89)',
        SEC_CD: '26530107',
        sagoLon: '128.982',
        sagoLat: '35.1493',
        sinkWidth: '5',
        sinkExtend: '7',
        sinkDepth: '5',
        grdKind: '기타',
        sagoDetail: '지하사고조사위원회에서 원인 조사중',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '2',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2024-09-22'
      },
      geometry: { type: 'Point', coordinates: [128.982, 35.1493] }
    },
    {
      type: 'Feature',
      id: 1375,
      properties: {
        sagoDate: '2024-09-21',
        sagoNo: '20240096',
        sido: '부산광역시',
        sigungu: '사상구',
        dong: '학장동',
        addr: '226-1(새벽로90)',
        fullAdr: '부산광역시 사상구 학장동 226-1(새벽로90)',
        SEC_CD: '26530107',
        sagoLon: '128.982',
        sagoLat: '35.1489',
        sinkWidth: '4',
        sinkExtend: '8',
        sinkDepth: '5',
        grdKind: '',
        sagoDetail: '지하사고조사위원회에서 원인 조사중',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2024-09-22'
      },
      geometry: { type: 'Point', coordinates: [128.982, 35.1489] }
    },
    {
      type: 'Feature',
      id: 1376,
      properties: {
        sagoDate: '2024-09-09',
        sagoNo: '20240101',
        sido: '경기도',
        sigungu: '파주시',
        dong: '문발동',
        addr: '650',
        fullAdr: '경기도 파주시 문발동 650',
        SEC_CD: '41480115',
        sagoLon: '126.693',
        sagoLat: '37.7239',
        sinkWidth: '3',
        sinkExtend: '25',
        sinkDepth: '0.05',
        grdKind: '기타',
        sagoDetail: '현장 인근 상수도관 파열로 인한 침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2024-12-31'
      },
      geometry: { type: 'Point', coordinates: [126.693, 37.7239] }
    },
    {
      type: 'Feature',
      id: 1377,
      properties: {
        sagoDate: '2024-09-24',
        sagoNo: '20240102',
        sido: '전북특별자치도',
        sigungu: '전주시 완산구',
        dong: '중화산동2가',
        addr: '519-11',
        fullAdr: '전북특별자치도 전주시 완산구 중화산동2가 519-11',
        SEC_CD: '52111128',
        sagoLon: '127.126',
        sagoLat: '35.812',
        sinkWidth: '1.5',
        sinkExtend: '0.7',
        sinkDepth: '2.2',
        grdKind: '',
        sagoDetail: '지하관로 CCTV 촬영 하였지만 문제점 확인 불가.',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '복구완료',
        trAmt: '0',
        trFnDate: '2024-10-17'
      },
      geometry: { type: 'Point', coordinates: [127.126, 35.812] }
    },
    {
      type: 'Feature',
      id: 1378,
      properties: {
        sagoDate: '2024-10-04',
        sagoNo: '20240103',
        sido: '인천광역시',
        sigungu: '부평구',
        dong: '부평동',
        addr: '754-56',
        fullAdr: '인천광역시 부평구 부평동 754-56',
        SEC_CD: '28237101',
        sagoLon: '126.718',
        sagoLat: '37.4855',
        sinkWidth: '1',
        sinkExtend: '3',
        sinkDepth: '0.5',
        grdKind: '',
        sagoDetail: '복구중인 상황으로 원인 파악 중임.',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '1',
        trStatus: '복구중',
        trAmt: '0',
        trFnDate: '2024-10-04'
      },
      geometry: { type: 'Point', coordinates: [126.718, 37.4855] }
    },
    {
      type: 'Feature',
      id: 1379,
      properties: {
        sagoDate: '2024-10-18',
        sagoNo: '20240104',
        sido: '광주광역시',
        sigungu: '광산구',
        dong: '오선동',
        addr: '광산구 하남산단7번로 153',
        fullAdr: '광주광역시 광산구 오선동 광산구 하남산단7번로 153',
        SEC_CD: '29200202',
        sagoLon: '126.812',
        sagoLat: '35.2061',
        sinkWidth: '2',
        sinkExtend: '2',
        sinkDepth: '2.5',
        grdKind: '',
        sagoDetail: '노후하수관로 파손으로 인한 지반침하',
        deathCnt: '0',
        injuryCnt: '0',
        vehicleCnt: '0',
        trStatus: '임시복구',
        trAmt: '0',
        trFnDate: '2024-10-18'
      },
      geometry: { type: 'Point', coordinates: [126.812, 35.2061] }
    }
  ]
};
