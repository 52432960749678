import { useQuery } from 'react-query';
import {
  getMarkerBoreHoles,
  getMarkerFacilities,
  getMarkerProjects,
  getMarkerSensors
} from '../api';
import { useState } from 'react';

export function useMarkerProjects() {
  // staleTime : fresh의 유효기간
  // cacheTime: 캐시의 유효기간
  const {
    data = {},
    isLoading,
    isFetching
  } = useQuery(['getMarkerProjects'], () => getMarkerProjects(), {
    staleTime: 0,
    cacheTime: 10000,
    refetchInterval: 10000,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
    // staleTime: 30000,
    // cacheTime: 60000,
    // refetchInterval: 60000,
    // refetchOnMount: true,
    // refetchOnReconnect: true,
    // refetchOnWindowFocus: true
  });

  return {
    data,
    isLoading,
    isFetching
  };
}

export function useMarkerFacilities() {
  // staleTime : fresh의 유효기간
  // cacheTime: 캐시의 유효기간
  const {
    data = {},
    isLoading,
    isFetching
  } = useQuery(['getMarkerFacilities'], () => getMarkerFacilities(), {
    staleTime: 0,
    cacheTime: 10000,
    refetchInterval: 10000,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
    // staleTime: 30000,
    // cacheTime: 60000,
    // refetchInterval: 60000,
    // refetchOnMount: true,
    // refetchOnReconnect: true,
    // refetchOnWindowFocus: true
  });

  return {
    data,
    isLoading,
    isFetching
  };
}

export function useMarkerSensors() {
  // staleTime : fresh의 유효기간
  // cacheTime: 캐시의 유효기간
  const {
    data = {},
    isLoading,
    isFetching
  } = useQuery(['getMarkerSensors'], () => getMarkerSensors(), {
    staleTime: 0,
    cacheTime: 10000,
    refetchInterval: 10000,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
    // staleTime: 30000,
    // cacheTime: 60000,
    // refetchInterval: 60000,
    // refetchOnMount: true,
    // refetchOnReconnect: true,
    // refetchOnWindowFocus: true
  });

  return {
    data,
    isLoading,
    isFetching
  };
}

export function useMarkerBoreHoles() {
  const [minLat, setMinLat] = useState(0.0);
  const [maxLat, setMaxLat] = useState(0.0);
  const [minLng, setMinLng] = useState(0.0);
  const [maxLng, setMaxLng] = useState(0.0);
  // staleTime : fresh의 유효기간
  // cacheTime: 캐시의 유효기간
  const {
    data = {},
    isLoading,
    isFetching
  } = useQuery(
    ['getMarkerBoreHoles', minLat, maxLat, minLng, maxLng],
    () => getMarkerBoreHoles(minLat, maxLat, minLng, maxLng),
    {
      staleTime: 30000,
      cacheTime: 60000,
      refetchInterval: 60000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false
    }
  );

  return {
    data,
    setMinLat,
    setMaxLat,
    setMinLng,
    setMaxLng,
    isLoading,
    isFetching
  };
}
