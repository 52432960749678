import axios from 'axios';
import { Cookies } from 'react-cookie';
const cookies = new Cookies();

export const chainAxios = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER // 기본 서버 주소 입력
});

chainAxios.defaults.withCredentials = true;

chainAxios.interceptors.request.use(
  function (config) {
    // 요청 바로 직전
    // axios 설정값에 대해 작성합니다.
    // console.log(
    //   'chainAxios cookie(Authorization) : %s \r\nconfig url : %s',
    //   cookies.get('Authorization'),
    //   config.url
    // );
    // 'X-AUTH-TOKEN': cookies.get('Authorization')
    // config.headers.Authorization = cookies.get('Authorization');
    config.headers['X-AUTH-TOKEN'] = cookies.get('Authorization')
      ? cookies.get('Authorization')
      : '';

    // console.log(config.headers);

    return config;
  },
  function (error) {
    // 요청 에러 처리를 작성합니다.
    return Promise.reject(error);
  }
);

chainAxios.interceptors.response.use(
  function (response) {
    return response;
  },

  function (error) {
    return Promise.reject(error);
  }
);
