import React, { useContext, useEffect, useRef, useState } from 'react';
import { Row, Col, Dropdown, Card, Button } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import Flex from 'components/common/Flex';

import AppContext, { CourseContext, SocketContext } from 'context/Context';

const columns = [
  {
    accessor: 'eventNo',
    Header: '이벤트 고유번호',
    headerProps: { className: 'text-900' }
  },
  {
    accessor: 'occurDate',
    Header: '발생일시',
    headerProps: { className: 'text-900' }
  },
  {
    accessor: 'notifyDate',
    Header: '통보일시',
    headerProps: { className: 'text-900' }
  },
  {
    accessor: 'projectName',
    Header: '프로젝트명',
    headerProps: { className: 'text-900' }
  },
  {
    accessor: 'eventTarget',
    Header: '이벤트 대상',
    headerProps: { className: 'text-900' }
  },
  {
    accessor: 'id',
    Header: 'ID',
    headerProps: { className: 'text-900' }
  },
  {
    accessor: 'eventType',
    Header: '이벤트 구분',
    headerProps: { className: 'text-900' }
  },
  {
    accessor: 'eventImportance',
    Header: '이벤트 중요도',
    headerProps: { className: 'text-900' }
  },
  {
    accessor: 'notifyContent',
    Header: '알림내용',
    headerProps: { className: 'text-900' }
  },
  {
    accessor: 'inspection',
    Header: '점검 필요 여부',
    headerProps: { className: 'text-900' }
  },
  {
    accessor: 'inspectionCompleted',
    Header: '점검 및 조치 완료 여부',
    headerProps: { className: 'text-900' }
  },
  {
    accessor: 'sendSMS',
    Header: 'SMS 발송여부',
    headerProps: { className: 'text-900' }
  },
  {
    accessor: 'favorites',
    Header: '즐겨찾기',
    headerProps: { className: 'text-900' }
  },
  {
    accessor: 'showDetail',
    Header: '상세보기',
    headerProps: { className: 'text-900' }
  }
];

const data = [
  {
    eventNo: '001',
    occurDate: '2023.03.28 14:15:25',
    notifyDate: '2023.03.28 14:16:30',
    projectName: '고양시 프로젝트',
    eventTarget: '센서',
    id: '9',
    eventType: '통신불량',
    eventImportance: 'A',
    notifyContent:
      '[고양시 시설물] 9번 센서가 2023.03.28 14:20:15 이후 통신이 끊어졌습니다. 이에 속한 프로젝트는 고양시 프로젝트 입니다.',
    inspection: 'X',
    inspectionCompleted: '미완료',
    sendSMS: '사용',
    favorites: '★',
    showDetail: 'N'
  },
  {
    eventNo: '002',
    occurDate: '2023.03.29 09:07:13',
    notifyDate: '2023.03.29 09:10:53',
    projectName: '고양시 프로젝트',
    eventTarget: '센서',
    id: '9',
    eventType: '정상화',
    eventImportance: '-',
    notifyContent:
      '[고양시 시설물] 9번 센서가 2023.03.29 09:07:13 이후 통신이 정상화 되었습니다. 해당 센서가 속한 프로젝트는 고양시 프로젝트입니다.',
    inspection: 'X',
    inspectionCompleted: '미완료',
    sendSMS: '사용',
    favorites: '★',
    showDetail: 'N'
  },
  {
    eventNo: '003',
    occurDate: '2023.03.25 11:03:16',
    notifyDate: '2023.03.25 11:03:50',
    projectName: '고양시 프로젝트',
    eventTarget: '게이트웨이',
    id: '01037608042',
    eventType: '통신불량',
    eventImportance: 'F',
    notifyContent:
      '[GW-01037608042] 게이트웨이가 2023.03.25 11:03:16 이후 통신이 끊겼습니다. 이에 속한 프로젝트는 고양시 프로젝트입니다.',
    inspection: 'O',
    inspectionCompleted: '완료',
    sendSMS: '사용',
    favorites: '★',
    showDetail: 'Y'
  },
  {
    eventNo: '004',
    occurDate: '2023.03.22 13:21:43',
    notifyDate: '2023.03.23 13:22:00',
    projectName: '고양시 프로젝트',
    eventTarget: '센서',
    id: '7',
    eventType: '거리변동',
    eventImportance: 'C',
    notifyContent:
      '[고양시 시설물] 7번 센서의 거리 측정값이 0.5cm 변화 하였습니다. 해당 센서가 속한 프로젝트는 고양시 프로젝트입니다.',
    inspection: 'X',
    inspectionCompleted: '미완료',
    sendSMS: '미사용',
    favorites: '☆',
    showDetail: 'N'
  },
  {
    eventNo: '006',
    occurDate: '2023.03.28 14:15:25',
    notifyDate: '2023.03.28 14:16:30',
    projectName: '고양시 프로젝트',
    eventTarget: '센서',
    id: '9',
    eventType: '통신불량',
    eventImportance: 'A',
    notifyContent:
      '[고양시 시설물] 9번 센서가 2023.03.28 14:20:15 이후 통신이 끊어졌습니다. 이에 속한 프로젝트는 고양시 프로젝트 입니다.',
    inspection: 'X',
    inspectionCompleted: '미완료',
    sendSMS: '사용',
    favorites: '★',
    showDetail: 'N'
  },
  {
    eventNo: '007',
    occurDate: '2023.03.29 09:07:13',
    notifyDate: '2023.03.29 09:10:53',
    projectName: '고양시 프로젝트',
    eventTarget: '센서',
    id: '9',
    eventType: '정상화',
    eventImportance: '-',
    notifyContent:
      '[고양시 시설물] 9번 센서가 2023.03.29 09:07:13 이후 통신이 정상화 되었습니다. 해당 센서가 속한 프로젝트는 고양시 프로젝트입니다.',
    inspection: 'X',
    inspectionCompleted: '미완료',
    sendSMS: '사용',
    favorites: '★',
    showDetail: 'N'
  },
  {
    eventNo: '008',
    occurDate: '2023.03.25 11:03:16',
    notifyDate: '2023.03.25 11:03:50',
    projectName: '고양시 프로젝트',
    eventTarget: '게이트웨이',
    id: '01037608042',
    eventType: '통신불량',
    eventImportance: 'F',
    notifyContent:
      '[GW-01037608042] 게이트웨이가 2023.03.25 11:03:16 이후 통신이 끊겼습니다. 이에 속한 프로젝트는 고양시 프로젝트입니다.',
    inspection: 'O',
    inspectionCompleted: '완료',
    sendSMS: '사용',
    favorites: '★',
    showDetail: 'Y'
  },
  {
    eventNo: '009',
    occurDate: '2023.03.22 13:21:43',
    notifyDate: '2023.03.23 13:22:00',
    projectName: '고양시 프로젝트',
    eventTarget: '센서',
    id: '7',
    eventType: '거리변동',
    eventImportance: 'C',
    notifyContent:
      '[고양시 시설물] 7번 센서의 거리 측정값이 0.5cm 변화 하였습니다. 해당 센서가 속한 프로젝트는 고양시 프로젝트입니다.',
    inspection: 'X',
    inspectionCompleted: '미완료',
    sendSMS: '미사용',
    favorites: '☆',
    showDetail: 'N'
  },
  {
    eventNo: '010',
    occurDate: '2023.03.28 14:15:25',
    notifyDate: '2023.03.28 14:16:30',
    projectName: '고양시 프로젝트',
    eventTarget: '센서',
    id: '9',
    eventType: '통신불량',
    eventImportance: 'A',
    notifyContent:
      '[고양시 시설물] 9번 센서가 2023.03.28 14:20:15 이후 통신이 끊어졌습니다. 이에 속한 프로젝트는 고양시 프로젝트 입니다.',
    inspection: 'X',
    inspectionCompleted: '미완료',
    sendSMS: '사용',
    favorites: '★',
    showDetail: 'N'
  },
  {
    eventNo: '011',
    occurDate: '2023.03.29 09:07:13',
    notifyDate: '2023.03.29 09:10:53',
    projectName: '고양시 프로젝트',
    eventTarget: '센서',
    id: '9',
    eventType: '정상화',
    eventImportance: '-',
    notifyContent:
      '[고양시 시설물] 9번 센서가 2023.03.29 09:07:13 이후 통신이 정상화 되었습니다. 해당 센서가 속한 프로젝트는 고양시 프로젝트입니다.',
    inspection: 'X',
    inspectionCompleted: '미완료',
    sendSMS: '사용',
    favorites: '★',
    showDetail: 'N'
  },
  {
    eventNo: '012',
    occurDate: '2023.03.25 11:03:16',
    notifyDate: '2023.03.25 11:03:50',
    projectName: '고양시 프로젝트',
    eventTarget: '게이트웨이',
    id: '01037608042',
    eventType: '통신불량',
    eventImportance: 'F',
    notifyContent:
      '[GW-01037608042] 게이트웨이가 2023.03.25 11:03:16 이후 통신이 끊겼습니다. 이에 속한 프로젝트는 고양시 프로젝트입니다.',
    inspection: 'O',
    inspectionCompleted: '완료',
    sendSMS: '사용',
    favorites: '★',
    showDetail: 'Y'
  },
  {
    eventNo: '013',
    occurDate: '2023.03.22 13:21:43',
    notifyDate: '2023.03.23 13:22:00',
    projectName: '고양시 프로젝트',
    eventTarget: '센서',
    id: '7',
    eventType: '거리변동',
    eventImportance: 'C',
    notifyContent:
      '[고양시 시설물] 7번 센서의 거리 측정값이 0.5cm 변화 하였습니다. 해당 센서가 속한 프로젝트는 고양시 프로젝트입니다.',
    inspection: 'X',
    inspectionCompleted: '미완료',
    sendSMS: '미사용',
    favorites: '☆',
    showDetail: 'N'
  }
];

const StatisticsStats = () => {
  const {
    config: { isNavbarVerticalCollapsed },
    setConfig
  } = useContext(AppContext);

  const coursesNavbarVerticalCollapsed = useRef(isNavbarVerticalCollapsed);

  useEffect(() => {
    setConfig('isNavbarVerticalCollapsed', false);

    return () => {
      setConfig(
        'isNavbarVerticalCollapsed',
        coursesNavbarVerticalCollapsed.current
      );
    };
  }, []);

  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={12} xl={12} xxl={12}>
          <Card className="h-md-100">
            <Card.Header>
              <h5>이벤트 리스트</h5>
            </Card.Header>
            <Card.Body>
              <Flex
                alignItems="center"
                direction="row"
                justifyContent="between"
                className="pb-3"
              >
                <Flex
                  alignItems="center"
                  direction="row"
                  justifyContent="start"
                >
                  <h6 className="px-3">프로젝트 선택</h6>
                  <Dropdown align={{ lg: 'start' }}>
                    <Dropdown.Toggle variant="falcon-default">
                      프로젝트를 선택하세요.
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="py-2">
                      <Dropdown.Item href="#/action-4" className="w-100">
                        강매동 스마트타워 프로젝트
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-1" className="w-100">
                        대곡동 서해선 연장프로젝트
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-5" className="w-100">
                        대장동 스마트시티 프로젝트 1구역
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2" className="w-100">
                        대화동 스마트시트 13구역 프로젝트
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-8" className="w-100">
                        삼송동 뉴 스타필드 프로젝트 12구역
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-9" className="w-100">
                        식사동 스마트시트 프로젝트
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3" className="w-100">
                        원당 엘에이치 00단지 조성 프로젝트
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-7" className="w-100">
                        지축동 테크노벨리 프로젝트
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-6" className="w-100">
                        행신동 뉴 스마트시티 프로젝트
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <h6 className="px-3">시설물 선택</h6>
                  <Dropdown align={{ lg: 'start' }}>
                    <Dropdown.Toggle variant="falcon-default">
                      시설물을 선택하세요.
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="py-2">
                      <Dropdown.Item href="#/action-4" className="w-100">
                        [SAMPLE] 시설물1
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-1" className="w-100">
                        [SAMPLE] 시설물2
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-5" className="w-100">
                        [SAMPLE] 시설물3
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2" className="w-100">
                        [SAMPLE] 시설물4
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-8" className="w-100">
                        [SAMPLE] 시설물5
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-9" className="w-100">
                        [SAMPLE] 시설물6
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3" className="w-100">
                        [SAMPLE] 시설물7
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-7" className="w-100">
                        [SAMPLE] 시설물8
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-6" className="w-100">
                        [SAMPLE] 시설물9
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Flex>
                <Flex alignItems="center" direction="row" justifyContent="end">
                  <Flex
                    alignItems="center"
                    direction="row"
                    justifyContent="start"
                    className="px-3"
                  >
                    <AdvanceTableSearchBox table />
                  </Flex>
                  <Button>엑셀 내보내기</Button>
                  <Button>즐겨찾기</Button>
                </Flex>
              </Flex>
              <AdvanceTableWrapper
                columns={columns}
                data={data}
                sortable
                pagination
                perPage={5}
              >
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    bordered: true,
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
                <div className="mt-3">
                  <AdvanceTablePagination table />
                </div>
              </AdvanceTableWrapper>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} xl={8} xxl={8}>
          <Card className="h-md-100">
            <Card.Header>
              <h5>이벤트 조치 이력</h5>
            </Card.Header>
            <Card.Body>
              <Row className="g-3 mb-3">
                <Col md={6} xl={6} xxl={6}>
                  <Flex
                    alignItems="start"
                    direction="column"
                    justifyContent="start"
                  >
                    <Flex
                      alignItems="start"
                      direction="column"
                      justifyContent="start"
                    >
                      <h6>이벤트 고유번호</h6>
                      <h6>003</h6>
                    </Flex>
                    <Flex
                      alignItems="start"
                      direction="column"
                      justifyContent="start"
                    >
                      <h6>발생일시</h6>
                      <h6>2023.03.25 11:03:16</h6>
                    </Flex>
                    <Flex
                      alignItems="start"
                      direction="column"
                      justifyContent="start"
                    >
                      <h6>점검일</h6>
                      <h6>2023-03-28 09:31:29</h6>
                    </Flex>
                    <Flex
                      alignItems="start"
                      direction="column"
                      justifyContent="start"
                    >
                      <h6>프로젝트</h6>
                      <h6>고양시 프로젝트</h6>
                    </Flex>
                  </Flex>
                </Col>
                <Col md={6} xl={6} xxl={6}>
                  <Flex
                    alignItems="start"
                    direction="column"
                    justifyContent="start"
                  >
                    <Flex
                      alignItems="start"
                      direction="column"
                      justifyContent="start"
                    >
                      <h6>조치 대상</h6>
                      <h6>게이트웨이이</h6>
                    </Flex>
                    <Flex
                      alignItems="start"
                      direction="column"
                      justifyContent="start"
                    >
                      <h6>ID</h6>
                      <h6>01037608042</h6>
                    </Flex>
                    <Flex
                      alignItems="start"
                      direction="column"
                      justifyContent="start"
                    >
                      <h6>조치 내용</h6>
                      <h6>전원 재인가</h6>
                    </Flex>
                    <Flex
                      alignItems="start"
                      direction="column"
                      justifyContent="start"
                    >
                      <h6>점검 담당자</h6>
                      <h6>강연우 선임</h6>
                    </Flex>
                  </Flex>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} xl={4} xxl={4}>
          <Card className="h-md-100">
            <Card.Header>
              <h5>이벤트 점검 조치 사진</h5>
            </Card.Header>
            <Card.Body>이벤트 점검 조치 사진 이미지</Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default StatisticsStats;
