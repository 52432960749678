export const eqkGeoData = {
  type: 'FeatureCollection',
  name: 'eqkgeo',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      id: 0,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '북한 황해북도 평산 서쪽 20km 지역',
        eqDate: '2018-01-01 18:35:01',
        magMl: '2.1',
        eqDt: '0',
        eqLt: '38.33',
        eqLn: '126.16',
        jdLoc: '',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [126.16, 38.33] }
    },
    {
      type: 'Feature',
      id: 1,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '경북 포항시 북구 북쪽 8km 지역',
        eqDate: '2018-01-01 19:11:53',
        magMl: '2.0',
        eqDt: '10.4',
        eqLt: '36.11',
        eqLn: '129.37',
        jdLoc: '',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [129.37, 36.11] }
    },
    {
      type: 'Feature',
      id: 2,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '',
        eqPt: '인천 옹진군 연평도 동북동쪽 21km 해역',
        eqDate: '2018-01-06 02:47:16',
        magMl: '2.2',
        eqDt: '11.7',
        eqLt: '37.71',
        eqLn: '125.94',
        jdLoc: '',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [125.94, 37.71] }
    },
    {
      type: 'Feature',
      id: 3,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '',
        eqPt: '전남 여수시 거문도 서남서쪽 19km 해역',
        eqDate: '2018-01-09 16:17:51',
        magMl: '2.1',
        eqDt: '20.5',
        eqLt: '33.98',
        eqLn: '127.11',
        jdLoc: '',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [127.11, 33.98] }
    },
    {
      type: 'Feature',
      id: 4,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '대전 서구 남남서쪽 12km 지역',
        eqDate: '2018-01-10 18:42:59',
        magMl: '2.0',
        eqDt: '19.8',
        eqLt: '36.26',
        eqLn: '127.34',
        jdLoc: '',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [127.34, 36.26] }
    },
    {
      type: 'Feature',
      id: 5,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '',
        eqPt: '전남 여수시 거문도 북쪽 32km 해역',
        eqDate: '2018-01-24 01:25:17',
        magMl: '2.4',
        eqDt: '16.2',
        eqLt: '34.32',
        eqLn: '127.32',
        jdLoc: '',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [127.32, 34.32] }
    },
    {
      type: 'Feature',
      id: 6,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '북한 함경남도 장진 남남동쪽 16km 지역',
        eqDate: '2018-01-28 20:07:06',
        magMl: '3.2',
        eqDt: '6.2',
        eqLt: '40.24',
        eqLn: '127.30',
        jdLoc: '',
        zeroDist: '84'
      },
      geometry: { type: 'Point', coordinates: [127.3, 40.24] }
    },
    {
      type: 'Feature',
      id: 7,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '경북 포항시 북구 북쪽 9km 지역',
        eqDate: '2018-02-02 12:21:12',
        magMl: '2.9',
        eqDt: '8.9',
        eqLt: '36.12',
        eqLn: '129.37',
        jdLoc: '',
        zeroDist: '61'
      },
      geometry: { type: 'Point', coordinates: [129.37, 36.12] }
    },
    {
      type: 'Feature',
      id: 8,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '경북 포항시 북구 북쪽 11km 지역',
        eqDate: '2018-02-03 11:12:18',
        magMl: '2.0',
        eqDt: '6',
        eqLt: '36.14',
        eqLn: '129.36',
        jdLoc: '',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [129.36, 36.14] }
    },
    {
      type: 'Feature',
      id: 9,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '',
        eqPt: '전남 여수시 거문도 남남동쪽 30km 해역',
        eqDate: '2018-02-03 12:35:01',
        magMl: '2.6',
        eqDt: '18.6',
        eqLt: '33.77',
        eqLn: '127.40',
        jdLoc: '',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [127.4, 33.77] }
    },
    {
      type: 'Feature',
      id: 10,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '경북 포항시 북구 북쪽 8km 지역',
        eqDate: '2018-02-04 15:43:38',
        magMl: '2.1',
        eqDt: '7.4',
        eqLt: '36.11',
        eqLn: '129.36',
        jdLoc: '',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.36, 36.11] }
    },
    {
      type: 'Feature',
      id: 11,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '충북 옥천군 북북동쪽 8km 지역',
        eqDate: '2018-02-04 18:50:25',
        magMl: '2.8',
        eqDt: '14.2',
        eqLt: '36.37',
        eqLn: '127.62',
        jdLoc: '',
        zeroDist: '55'
      },
      geometry: { type: 'Point', coordinates: [127.62, 36.37] }
    },
    {
      type: 'Feature',
      id: 12,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '경북 포항시 북구 서북서쪽 5km 지역',
        eqDate: '2018-02-04 22:20:35',
        magMl: '2.3',
        eqDt: '7.6',
        eqLt: '36.06',
        eqLn: '129.31',
        jdLoc: '',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [129.31, 36.06] }
    },
    {
      type: 'Feature',
      id: 13,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '경북 포항시 북구 북서쪽 5km 지역',
        eqDate: '2018-02-06 09:18:33',
        magMl: '2.5',
        eqDt: '11.5',
        eqLt: '36.08',
        eqLn: '129.33',
        jdLoc: '',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [129.33, 36.08] }
    },
    {
      type: 'Feature',
      id: 14,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '북한 함경북도 길주 북북서쪽 46km 지역',
        eqDate: '2018-02-06 19:53:53',
        magMl: '2.6',
        eqDt: '0',
        eqLt: '41.33',
        eqLn: '129.10',
        jdLoc: '',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [129.1, 41.33] }
    },
    {
      type: 'Feature',
      id: 15,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '북한 함경북도 길주 북북서쪽 45km 지역',
        eqDate: '2018-02-08 06:46:01',
        magMl: '2.7',
        eqDt: '0.8',
        eqLt: '41.32',
        eqLn: '129.09',
        jdLoc: '',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [129.09, 41.32] }
    },
    {
      type: 'Feature',
      id: 16,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '',
        eqPt: '전남 신안군 흑산면 북서쪽 67km 해역',
        eqDate: '2018-02-08 09:56:49',
        magMl: '2.7',
        eqDt: '9',
        eqLt: '35.10',
        eqLn: '124.99',
        jdLoc: '',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [124.99, 35.1] }
    },
    {
      type: 'Feature',
      id: 17,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '',
        eqPt: '전남 신안군 흑산면 남남서쪽 85km 해역',
        eqDate: '2018-02-09 00:43:45',
        magMl: '2.5',
        eqDt: '3.8',
        eqLt: '33.92',
        eqLn: '125.22',
        jdLoc: '',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [125.22, 33.92] }
    },
    {
      type: 'Feature',
      id: 18,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '',
        eqPt: '충남 태안군 서격렬비도 동쪽 20km 해역',
        eqDate: '2018-02-10 01:32:38',
        magMl: '2.4',
        eqDt: '9.9',
        eqLt: '36.60',
        eqLn: '125.76',
        jdLoc: '',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [125.76, 36.6] }
    },
    {
      type: 'Feature',
      id: 19,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '경북 포항시 북구 북서쪽 5km 지역',
        eqDate: '2018-02-11 05:03:03',
        magMl: '4.6',
        eqDt: '14.3',
        eqLt: '36.08',
        eqLn: '129.33',
        jdLoc: '',
        zeroDist: '469'
      },
      geometry: { type: 'Point', coordinates: [129.33, 36.08] }
    },
    {
      type: 'Feature',
      id: 20,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '경북 포항시 북구 북서쪽 7km 지역',
        eqDate: '2018-02-11 05:38:06',
        magMl: '2.1',
        eqDt: '10',
        eqLt: '36.09',
        eqLn: '129.32',
        jdLoc: '',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.32, 36.09] }
    },
    {
      type: 'Feature',
      id: 21,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '경북 포항시 북구 북북서쪽 5km 지역',
        eqDate: '2018-02-11 08:11:38',
        magMl: '2.1',
        eqDt: '13.3',
        eqLt: '36.08',
        eqLn: '129.34',
        jdLoc: '',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.34, 36.08] }
    },
    {
      type: 'Feature',
      id: 22,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '경북 포항시 북구 북서쪽 6km 지역',
        eqDate: '2018-02-11 12:14:28',
        magMl: '2.1',
        eqDt: '9',
        eqLt: '36.08',
        eqLn: '129.32',
        jdLoc: '',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.32, 36.08] }
    },
    {
      type: 'Feature',
      id: 23,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '경북 포항시 북구 북서쪽 5km 지역',
        eqDate: '2018-02-11 12:49:37',
        magMl: '2.4',
        eqDt: '15.8',
        eqLt: '36.08',
        eqLn: '129.33',
        jdLoc: '',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [129.33, 36.08] }
    },
    {
      type: 'Feature',
      id: 24,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '경북 포항시 북구 북서쪽 5km 지역',
        eqDate: '2018-02-13 06:30:12',
        magMl: '2.6',
        eqDt: '9.4',
        eqLt: '36.07',
        eqLn: '129.33',
        jdLoc: '',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [129.33, 36.07] }
    },
    {
      type: 'Feature',
      id: 25,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '경북 포항시 북구 북쪽 9km 지역',
        eqDate: '2018-02-13 10:32:10',
        magMl: '2.2',
        eqDt: '6.7',
        eqLt: '36.12',
        eqLn: '129.37',
        jdLoc: '',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [129.37, 36.12] }
    },
    {
      type: 'Feature',
      id: 26,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '',
        eqPt: '북한 황해남도 옹진 남쪽 14km 해역',
        eqDate: '2018-02-14 02:14:26',
        magMl: '2.3',
        eqDt: '7.5',
        eqLt: '37.80',
        eqLn: '125.38',
        jdLoc: '',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [125.38, 37.8] }
    },
    {
      type: 'Feature',
      id: 27,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '경북 포항시 북구 북쪽 8km 지역',
        eqDate: '2018-02-17 18:35:48',
        magMl: '2.0',
        eqDt: '11.8',
        eqLt: '36.11',
        eqLn: '129.37',
        jdLoc: '',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [129.37, 36.11] }
    },
    {
      type: 'Feature',
      id: 28,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '경북 포항시 북구 북북서쪽 6km 지역',
        eqDate: '2018-02-17 21:31:18',
        magMl: '2.6',
        eqDt: '8.2',
        eqLt: '36.09',
        eqLn: '129.33',
        jdLoc: '',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [129.33, 36.09] }
    },
    {
      type: 'Feature',
      id: 29,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '경북 포항시 북구 북북서쪽 6km 지역',
        eqDate: '2018-02-17 23:38:56',
        magMl: '2.2',
        eqDt: '9.4',
        eqLt: '36.10',
        eqLn: '129.35',
        jdLoc: '',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [129.35, 36.1] }
    },
    {
      type: 'Feature',
      id: 30,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '경북 포항시 북구 북서쪽 4km 지역',
        eqDate: '2018-02-18 03:34:27',
        magMl: '2.4',
        eqDt: '8.7',
        eqLt: '36.07',
        eqLn: '129.33',
        jdLoc: '',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [129.33, 36.07] }
    },
    {
      type: 'Feature',
      id: 31,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '',
        eqPt: '전남 완도군 남남동쪽 39km 해역',
        eqDate: '2018-02-26 02:23:03',
        magMl: '2.4',
        eqDt: '16.2',
        eqLt: '33.97',
        eqLn: '126.87',
        jdLoc: '',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [126.87, 33.97] }
    },
    {
      type: 'Feature',
      id: 32,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '',
        eqPt: '전남 신안군 흑산면 남서쪽 20km 해역',
        eqDate: '2018-02-26 11:06:57',
        magMl: '2.5',
        eqDt: '6',
        eqLt: '34.52',
        eqLn: '125.32',
        jdLoc: '',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [125.32, 34.52] }
    },
    {
      type: 'Feature',
      id: 33,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '',
        eqPt: '충남 태안군 서격렬비도 북서쪽 75km 해역',
        eqDate: '2018-02-27 13:14:43',
        magMl: '2.2',
        eqDt: '0',
        eqLt: '37.02',
        eqLn: '124.87',
        jdLoc: '',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [124.87, 37.02] }
    },
    {
      type: 'Feature',
      id: 34,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '',
        eqPt: '경북 울릉군 북서쪽 88km 해역',
        eqDate: '2018-03-04 00:52:47',
        magMl: '2.6',
        eqDt: '9.4',
        eqLt: '38.13',
        eqLn: '130.33',
        jdLoc: '',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [130.33, 38.13] }
    },
    {
      type: 'Feature',
      id: 35,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '전남 곡성군 동북동쪽 4km 지역',
        eqDate: '2018-03-08 23:16:47',
        magMl: '2.0',
        eqDt: '14.6',
        eqLt: '35.30',
        eqLn: '127.33',
        jdLoc: '',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [127.33, 35.3] }
    },
    {
      type: 'Feature',
      id: 36,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '전남 곡성군 북동쪽 4km 지역',
        eqDate: '2018-03-08 23:38:41',
        magMl: '2.0',
        eqDt: '17.4',
        eqLt: '35.31',
        eqLn: '127.32',
        jdLoc: '',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [127.32, 35.31] }
    },
    {
      type: 'Feature',
      id: 37,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '충남 서산시 북쪽 18km 지역',
        eqDate: '2018-03-09 18:05:37',
        magMl: '2.2',
        eqDt: '6.8',
        eqLt: '36.95',
        eqLn: '126.45',
        jdLoc: '',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [126.45, 36.95] }
    },
    {
      type: 'Feature',
      id: 38,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '경북 포항시 북구 북쪽 11km 지역',
        eqDate: '2018-03-10 01:31:39',
        magMl: '2.8',
        eqDt: '5.8',
        eqLt: '36.14',
        eqLn: '129.37',
        jdLoc: '',
        zeroDist: '55'
      },
      geometry: { type: 'Point', coordinates: [129.37, 36.14] }
    },
    {
      type: 'Feature',
      id: 39,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '충북 단양군 동남동쪽 5km 지역',
        eqDate: '2018-03-16 00:23:23',
        magMl: '2.0',
        eqDt: '5.7',
        eqLt: '36.98',
        eqLn: '128.42',
        jdLoc: '',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [128.42, 36.98] }
    },
    {
      type: 'Feature',
      id: 40,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '경북 포항시 북구 북북서쪽 7km 지역',
        eqDate: '2018-03-16 18:02:47',
        magMl: '2.7',
        eqDt: '11.9',
        eqLt: '36.10',
        eqLn: '129.35',
        jdLoc: '',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [129.35, 36.1] }
    },
    {
      type: 'Feature',
      id: 41,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '',
        eqPt: '인천 백령도 남동쪽 24km 해역',
        eqDate: '2018-03-26 09:40:39',
        magMl: '2.7',
        eqDt: '0.1',
        eqLt: '37.78',
        eqLn: '124.84',
        jdLoc: '',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [124.84, 37.78] }
    },
    {
      type: 'Feature',
      id: 42,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '경북 포항시 북구 북쪽 7km 지역',
        eqDate: '2018-03-31 00:20:12',
        magMl: '2.0',
        eqDt: '7.6',
        eqLt: '36.10',
        eqLn: '129.36',
        jdLoc: '',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [129.36, 36.1] }
    },
    {
      type: 'Feature',
      id: 43,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '',
        eqPt: '경북 포항시 북구 동쪽 41km 해역',
        eqDate: '2018-04-06 21:09:11',
        magMl: '2.6',
        eqDt: '24.1',
        eqLt: '36.08',
        eqLn: '129.82',
        jdLoc: '',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [129.82, 36.08] }
    },
    {
      type: 'Feature',
      id: 44,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '경북 경주시 남남서쪽 8km 지역',
        eqDate: '2018-04-09 12:15:40',
        magMl: '2.4',
        eqDt: '16.4',
        eqLt: '35.76',
        eqLn: '129.19',
        jdLoc: '',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [129.19, 35.76] }
    },
    {
      type: 'Feature',
      id: 45,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '북한 함경북도 길주 북북서쪽 47km 지역',
        eqDate: '2018-04-23 04:31:17',
        magMl: '2.3',
        eqDt: '0',
        eqLt: '41.35',
        eqLn: '129.12',
        jdLoc: '',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [129.12, 41.35] }
    },
    {
      type: 'Feature',
      id: 46,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '',
        eqPt: '제주 서귀포시 서남서쪽 16km 해역',
        eqDate: '2018-04-23 13:10:21',
        magMl: '2.4',
        eqDt: '16.5',
        eqLt: '33.21',
        eqLn: '126.40',
        jdLoc: '',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [126.4, 33.21] }
    },
    {
      type: 'Feature',
      id: 47,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '',
        eqPt: '인천 옹진군 덕적도 남서쪽 27km 해역',
        eqDate: '2018-04-24 05:20:32',
        magMl: '2.5',
        eqDt: '',
        eqLt: '37.06',
        eqLn: '125.92',
        jdLoc: '',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [125.92, 37.06] }
    },
    {
      type: 'Feature',
      id: 48,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '경북 경주시 남쪽 8km 지역',
        eqDate: '2018-05-01 19:09:05',
        magMl: '2.0',
        eqDt: '15',
        eqLt: '35.77',
        eqLn: '129.20',
        jdLoc: '',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [129.2, 35.77] }
    },
    {
      type: 'Feature',
      id: 49,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '충북 보은군 북서쪽 11km 지역',
        eqDate: '2018-05-02 12:34:36',
        magMl: '2.5',
        eqDt: '11',
        eqLt: '36.56',
        eqLn: '127.64',
        jdLoc: '',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [127.64, 36.56] }
    },
    {
      type: 'Feature',
      id: 50,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '충북',
        eqPt: '충북 영동군 남서쪽 11km 지역',
        eqDate: '2018-05-04 19:20:49',
        magMl: '2.0',
        eqDt: '12',
        eqLt: '36.10',
        eqLn: '127.70',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [127.7, 36.1] }
    },
    {
      type: 'Feature',
      id: 51,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 예천군 북서쪽 10km 지역',
        eqDate: '2018-05-07 01:52:08',
        magMl: '2.0',
        eqDt: '16',
        eqLt: '36.72',
        eqLn: '128.37',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [128.37, 36.72] }
    },
    {
      type: 'Feature',
      id: 52,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '제주 제주시 고산 서북서쪽 65km 해역',
        eqDate: '2018-05-14 02:07:38',
        magMl: '2.5',
        eqDt: '16',
        eqLt: '33.62',
        eqLn: '125.58',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [125.58, 33.62] }
    },
    {
      type: 'Feature',
      id: 53,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산면 남남동쪽 56km 해역',
        eqDate: '2018-05-20 13:58:31',
        magMl: '2.0',
        eqDt: '10',
        eqLt: '34.16',
        eqLn: '125.61',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [125.61, 34.16] }
    },
    {
      type: 'Feature',
      id: 54,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 영덕군 동남동쪽 12km 해역',
        eqDate: '2018-05-22 00:12:41',
        magMl: '2.2',
        eqDt: '18',
        eqLt: '36.37',
        eqLn: '129.49',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [129.49, 36.37] }
    },
    {
      type: 'Feature',
      id: 55,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 포항시 북구 북쪽 20km 지역',
        eqDate: '2018-05-31 00:13:22',
        magMl: '2.6',
        eqDt: '17',
        eqLt: '36.22',
        eqLn: '129.38',
        jdLoc: 'Ⅱ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [129.38, 36.22] }
    },
    {
      type: 'Feature',
      id: 56,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 강원 회양 남남동쪽 21km 지역',
        eqDate: '2018-05-31 16:38:15',
        magMl: '2.8',
        eqDt: '8',
        eqLt: '38.53',
        eqLn: '127.68',
        jdLoc: 'Ⅰ',
        zeroDist: '55'
      },
      geometry: { type: 'Point', coordinates: [127.68, 38.53] }
    },
    {
      type: 'Feature',
      id: 57,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 포항시 남구 동남동쪽 37km 해역',
        eqDate: '2018-06-09 17:38:46',
        magMl: '2.3',
        eqDt: '18',
        eqLt: '35.87',
        eqLn: '129.73',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [129.73, 35.87] }
    },
    {
      type: 'Feature',
      id: 58,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 의성군 서북서쪽 19km 지역',
        eqDate: '2018-06-15 11:10:02',
        magMl: '2.1',
        eqDt: '14',
        eqLt: '36.39',
        eqLn: '128.49',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [128.49, 36.39] }
    },
    {
      type: 'Feature',
      id: 59,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '강원',
        eqPt: '강원 삼척시 남남서쪽 30km 지역',
        eqDate: '2018-06-16 21:05:25',
        magMl: '2.3',
        eqDt: '',
        eqLt: '37.19',
        eqLn: '129.08',
        jdLoc: 'Ⅲ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [129.08, 37.19] }
    },
    {
      type: 'Feature',
      id: 60,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산면 서북서쪽 66km 해역',
        eqDate: '2018-06-20 23:06:49',
        magMl: '2.1',
        eqDt: '14',
        eqLt: '34.90',
        eqLn: '124.81',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [124.81, 34.9] }
    },
    {
      type: 'Feature',
      id: 61,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '강원',
        eqPt: '강원 철원군 북북동쪽 4km 지역',
        eqDate: '2018-06-28 18:43:00',
        magMl: '2.7',
        eqDt: '9',
        eqLt: '38.18',
        eqLn: '127.34',
        jdLoc: 'Ⅲ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [127.34, 38.18] }
    },
    {
      type: 'Feature',
      id: 62,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '충남 태안군 서격렬비도 북북서쪽 50km 해역',
        eqDate: '2018-06-28 21:03:26',
        magMl: '2.2',
        eqDt: '',
        eqLt: '36.99',
        eqLn: '125.25',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [125.25, 36.99] }
    },
    {
      type: 'Feature',
      id: 63,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 삼척시 북동쪽 34km 해역',
        eqDate: '2018-07-01 15:33:22',
        magMl: '2.2',
        eqDt: '18',
        eqLt: '37.63',
        eqLn: '129.48',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [129.48, 37.63] }
    },
    {
      type: 'Feature',
      id: 64,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경기',
        eqPt: '경기 여주시 동쪽 6km 지역',
        eqDate: '2018-07-04 04:39:40',
        magMl: '2.1',
        eqDt: '10',
        eqLt: '37.30',
        eqLn: '127.70',
        jdLoc: 'Ⅳ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [127.7, 37.3] }
    },
    {
      type: 'Feature',
      id: 65,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '전북',
        eqPt: '전북 무주군 남동쪽 17km 지역',
        eqDate: '2018-07-17 20:19:12',
        magMl: '2.7',
        eqDt: '',
        eqLt: '35.91',
        eqLn: '127.81',
        jdLoc: 'Ⅳ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [127.81, 35.91] }
    },
    {
      type: 'Feature',
      id: 66,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 포항시 남구 동남동쪽 44km 해역',
        eqDate: '2018-07-19 05:52:11',
        magMl: '2.5',
        eqDt: '23',
        eqLt: '35.83',
        eqLn: '129.80',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [129.8, 35.83] }
    },
    {
      type: 'Feature',
      id: 67,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전북 군산시 어청도 남동쪽 21km 해역',
        eqDate: '2018-07-20 12:14:33',
        magMl: '2.4',
        eqDt: '20',
        eqLt: '36.01',
        eqLn: '126.16',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [126.16, 36.01] }
    },
    {
      type: 'Feature',
      id: 68,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 포항시 남구 동남동쪽 43km 해역',
        eqDate: '2018-07-20 12:29:33',
        magMl: '2.3',
        eqDt: '21',
        eqLt: '35.84',
        eqLn: '129.79',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [129.79, 35.84] }
    },
    {
      type: 'Feature',
      id: 69,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '전남 고흥군 남남서쪽 16km 해역',
        eqDate: '2018-07-21 23:30:47',
        magMl: '2.2',
        eqDt: '13',
        eqLt: '34.47',
        eqLn: '127.25',
        jdLoc: 'Ⅱ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [127.25, 34.47] }
    },
    {
      type: 'Feature',
      id: 70,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '제주 제주시 서쪽 56km 해역',
        eqDate: '2018-07-22 13:59:11',
        magMl: '2.6',
        eqDt: '16',
        eqLt: '33.43',
        eqLn: '125.93',
        jdLoc: 'Ⅰ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [125.93, 33.43] }
    },
    {
      type: 'Feature',
      id: 71,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '충남 태안군 서격렬비도 북북서쪽 51km 해역',
        eqDate: '2018-08-09 00:21:24',
        magMl: '2.1',
        eqDt: '',
        eqLt: '37.03',
        eqLn: '125.30',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [125.3, 37.03] }
    },
    {
      type: 'Feature',
      id: 72,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '충남 태안군 서격렬비도 북북서쪽 53km 해역',
        eqDate: '2018-08-09 00:23:08',
        magMl: '2.1',
        eqDt: '',
        eqLt: '37.03',
        eqLn: '125.27',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [125.27, 37.03] }
    },
    {
      type: 'Feature',
      id: 73,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '제주 서귀포시 동쪽 90km 해역',
        eqDate: '2018-08-10 21:41:19',
        magMl: '2.4',
        eqDt: '',
        eqLt: '33.21',
        eqLn: '127.53',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [127.53, 33.21] }
    },
    {
      type: 'Feature',
      id: 74,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 울릉군 울릉도 남쪽 21km 해역',
        eqDate: '2018-08-15 03:01:59',
        magMl: '2.7',
        eqDt: '34',
        eqLt: '37.30',
        eqLn: '130.92',
        jdLoc: 'Ⅰ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [130.92, 37.3] }
    },
    {
      type: 'Feature',
      id: 75,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해남도 해주 남남서쪽 14km 지역',
        eqDate: '2018-08-24 02:57:16',
        magMl: '2.6',
        eqDt: '12',
        eqLt: '37.92',
        eqLn: '125.68',
        jdLoc: 'Ⅰ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [125.68, 37.92] }
    },
    {
      type: 'Feature',
      id: 76,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '전남 고흥군 남남동쪽 34km 해역',
        eqDate: '2018-08-24 21:28:24',
        magMl: '2.1',
        eqDt: '18',
        eqLt: '34.33',
        eqLn: '127.42',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [127.42, 34.33] }
    },
    {
      type: 'Feature',
      id: 77,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 울진군 서쪽 7km 지역',
        eqDate: '2018-08-29 05:01:29',
        magMl: '2.5',
        eqDt: '11',
        eqLt: '37.00',
        eqLn: '129.32',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [129.32, 37] }
    },
    {
      type: 'Feature',
      id: 78,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 문경시 북동쪽 26km 지역',
        eqDate: '2018-09-14 09:08:13',
        magMl: '2.2',
        eqDt: '14',
        eqLt: '36.78',
        eqLn: '128.36',
        jdLoc: 'Ⅱ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [128.36, 36.78] }
    },
    {
      type: 'Feature',
      id: 79,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 울진군 동쪽 46km 해역',
        eqDate: '2018-09-15 21:45:44',
        magMl: '2.4',
        eqDt: '',
        eqLt: '37.07',
        eqLn: '129.91',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [129.91, 37.07] }
    },
    {
      type: 'Feature',
      id: 80,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 포항시 북구 동쪽 29km 해역',
        eqDate: '2018-09-17 10:52:48',
        magMl: '2.4',
        eqDt: '12',
        eqLt: '36.05',
        eqLn: '129.69',
        jdLoc: 'Ⅱ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [129.69, 36.05] }
    },
    {
      type: 'Feature',
      id: 81,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경남도 신흥 북동쪽 30km 지역',
        eqDate: '2018-09-24 17:38:25',
        magMl: '2.8',
        eqDt: '',
        eqLt: '40.20',
        eqLn: '127.70',
        jdLoc: 'Ⅰ',
        zeroDist: '55'
      },
      geometry: { type: 'Point', coordinates: [127.7, 40.2] }
    },
    {
      type: 'Feature',
      id: 82,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '제주 제주시 서쪽 68km 해역',
        eqDate: '2018-09-25 04:05:47',
        magMl: '2.5',
        eqDt: '16',
        eqLt: '33.46',
        eqLn: '125.80',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [125.8, 33.46] }
    },
    {
      type: 'Feature',
      id: 83,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 송림 동쪽 6km 지역',
        eqDate: '2018-09-27 12:24:32',
        magMl: '2.4',
        eqDt: '',
        eqLt: '38.74',
        eqLn: '125.70',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [125.7, 38.74] }
    },
    {
      type: 'Feature',
      id: 84,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 강원 평강 북북서쪽 30km 지역',
        eqDate: '2018-10-12 16:43:00',
        magMl: '2.3',
        eqDt: '13',
        eqLt: '38.67',
        eqLn: '127.18',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [127.18, 38.67] }
    },
    {
      type: 'Feature',
      id: 85,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '인천 옹진군 백령도 남쪽 52km 해역',
        eqDate: '2018-10-14 14:46:03',
        magMl: '2.4',
        eqDt: '',
        eqLt: '37.49',
        eqLn: '124.58',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [124.58, 37.49] }
    },
    {
      type: 'Feature',
      id: 86,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '충남',
        eqPt: '충남 논산시 동쪽 18km 지역',
        eqDate: '2018-10-17 03:14:44',
        magMl: '2.0',
        eqDt: '14',
        eqLt: '36.16',
        eqLn: '127.30',
        jdLoc: 'Ⅱ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [127.3, 36.16] }
    },
    {
      type: 'Feature',
      id: 87,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 울진군 동북동쪽 58km 해역',
        eqDate: '2018-10-20 01:22:40',
        magMl: '2.6',
        eqDt: '',
        eqLt: '37.26',
        eqLn: '129.96',
        jdLoc: 'Ⅰ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [129.96, 37.26] }
    },
    {
      type: 'Feature',
      id: 88,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전북 군산시 어청도 서남서쪽 109km 해역',
        eqDate: '2018-10-22 12:09:26',
        magMl: '2.1',
        eqDt: '11',
        eqLt: '35.61',
        eqLn: '124.94',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [124.94, 35.61] }
    },
    {
      type: 'Feature',
      id: 89,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해남도 옹진 북쪽 12km 지역',
        eqDate: '2018-10-24 15:14:21',
        magMl: '2.2',
        eqDt: '7',
        eqLt: '38.04',
        eqLn: '125.37',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [125.37, 38.04] }
    },
    {
      type: 'Feature',
      id: 90,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 경주시 남남서쪽 9km 지역',
        eqDate: '2018-10-25 05:53:46',
        magMl: '2.3',
        eqDt: '16',
        eqLt: '35.76',
        eqLn: '129.19',
        jdLoc: '',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [129.19, 35.76] }
    },
    {
      type: 'Feature',
      id: 91,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 김천시 동남동쪽 14km 지역',
        eqDate: '2018-11-04 17:04:44',
        magMl: '2.5',
        eqDt: '15',
        eqLt: '36.08',
        eqLn: '128.25',
        jdLoc: 'Ⅱ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [128.25, 36.08] }
    },
    {
      type: 'Feature',
      id: 92,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '북한 황해남도 옹진군 남서쪽 43km 해역',
        eqDate: '2018-11-05 12:01:55',
        magMl: '3.2',
        eqDt: '',
        eqLt: '37.80',
        eqLn: '124.90',
        jdLoc: '',
        zeroDist: '84'
      },
      geometry: { type: 'Point', coordinates: [124.9, 37.8] }
    },
    {
      type: 'Feature',
      id: 93,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '북한 황해남도 옹진군 남서쪽 44km 해역',
        eqDate: '2018-11-05 12:07:15',
        magMl: '3.1',
        eqDt: '15',
        eqLt: '37.85',
        eqLn: '124.87',
        jdLoc: '',
        zeroDist: '75'
      },
      geometry: { type: 'Point', coordinates: [124.87, 37.85] }
    },
    {
      type: 'Feature',
      id: 94,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '대전',
        eqPt: '대전 유성구 북서쪽 8km 지역',
        eqDate: '2018-11-19 03:34:49',
        magMl: '2.1',
        eqDt: '20',
        eqLt: '36.42',
        eqLn: '127.30',
        jdLoc: '',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [127.3, 36.42] }
    },
    {
      type: 'Feature',
      id: 95,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '전남 여수시 거문도 남쪽 57km 해역',
        eqDate: '2018-11-20 14:10:27',
        magMl: '2.3',
        eqDt: '17',
        eqLt: '33.52',
        eqLn: '127.29',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [127.29, 33.52] }
    },
    {
      type: 'Feature',
      id: 96,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 포항시 남구 동남동쪽 33km 해역',
        eqDate: '2018-11-22 18:36:18',
        magMl: '2.2',
        eqDt: '19',
        eqLt: '35.91',
        eqLn: '129.71',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [129.71, 35.91] }
    },
    {
      type: 'Feature',
      id: 97,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 강원 평강 북북서쪽 34km 지역',
        eqDate: '2018-11-24 08:16:44',
        magMl: '2.9',
        eqDt: '10',
        eqLt: '38.70',
        eqLn: '127.16',
        jdLoc: 'Ⅰ',
        zeroDist: '61'
      },
      geometry: { type: 'Point', coordinates: [127.16, 38.7] }
    },
    {
      type: 'Feature',
      id: 98,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 송림 동쪽 25km 지역',
        eqDate: '2018-11-25 02:58:33',
        magMl: '2.2',
        eqDt: '',
        eqLt: '38.73',
        eqLn: '125.92',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [125.92, 38.73] }
    },
    {
      type: 'Feature',
      id: 99,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '제주 서귀포시 서남서쪽 45km 해역',
        eqDate: '2018-11-25 23:27:27',
        magMl: '2.1',
        eqDt: '12',
        eqLt: '33.15',
        eqLn: '126.09',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [126.09, 33.15] }
    },
    {
      type: 'Feature',
      id: 100,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 서북서쪽 92km 해역',
        eqDate: '2018-11-29 03:05:23',
        magMl: '3.3',
        eqDt: '13',
        eqLt: '34.94',
        eqLn: '124.53',
        jdLoc: 'Ⅰ',
        zeroDist: '94'
      },
      geometry: { type: 'Point', coordinates: [124.53, 34.94] }
    },
    {
      type: 'Feature',
      id: 101,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 울진군 북동쪽 44km 해역',
        eqDate: '2018-11-29 14:52:39',
        magMl: '2.1',
        eqDt: '17',
        eqLt: '37.32',
        eqLn: '129.69',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.69, 37.32] }
    },
    {
      type: 'Feature',
      id: 102,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 영덕군 동쪽 23km 해역',
        eqDate: '2018-12-05 08:01:08',
        magMl: '2.1',
        eqDt: '17',
        eqLt: '36.45',
        eqLn: '129.62',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.62, 36.45] }
    },
    {
      type: 'Feature',
      id: 103,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 영덕군 동쪽 23km 해역',
        eqDate: '2018-12-05 08:28:20',
        magMl: '2.5',
        eqDt: '17',
        eqLt: '36.45',
        eqLn: '129.62',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [129.62, 36.45] }
    },
    {
      type: 'Feature',
      id: 104,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 구미시 북북서쪽 23km 지역',
        eqDate: '2018-12-08 04:16:41',
        magMl: '2.4',
        eqDt: '17',
        eqLt: '36.31',
        eqLn: '128.25',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [128.25, 36.31] }
    },
    {
      type: 'Feature',
      id: 105,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 포항시 남구 동남동쪽 34km 해역',
        eqDate: '2018-12-09 14:06:18',
        magMl: '2.2',
        eqDt: '19',
        eqLt: '35.86',
        eqLn: '129.69',
        jdLoc: 'Ⅱ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [129.69, 35.86] }
    },
    {
      type: 'Feature',
      id: 106,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '전북',
        eqPt: '전북 부안군 동남동쪽 4km 지역',
        eqDate: '2018-12-12 03:43:04',
        magMl: '2.3',
        eqDt: '',
        eqLt: '35.72',
        eqLn: '126.78',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [126.78, 35.72] }
    },
    {
      type: 'Feature',
      id: 107,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 영덕군 동북동쪽 28km 해역',
        eqDate: '2018-12-17 02:16:27',
        magMl: '2.2',
        eqDt: '20',
        eqLt: '36.53',
        eqLn: '129.65',
        jdLoc: 'Ⅱ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [129.65, 36.53] }
    },
    {
      type: 'Feature',
      id: 108,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 포항시 남구 동남동쪽 33km 해역',
        eqDate: '2018-12-20 13:23:24',
        magMl: '2.0',
        eqDt: '21',
        eqLt: '35.88',
        eqLn: '129.69',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [129.69, 35.88] }
    },
    {
      type: 'Feature',
      id: 109,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경남',
        eqPt: '경남 거창군 북북동쪽 9km 지역',
        eqDate: '2018-12-21 16:06:12',
        magMl: '2.7',
        eqDt: '12',
        eqLt: '35.76',
        eqLn: '127.95',
        jdLoc: 'Ⅲ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [127.95, 35.76] }
    },
    {
      type: 'Feature',
      id: 110,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 봉화군 북동쪽 18km 지역',
        eqDate: '2018-12-27 03:10:48',
        magMl: '2.0',
        eqDt: '5',
        eqLt: '36.99',
        eqLn: '128.89',
        jdLoc: 'Ⅱ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [128.89, 36.99] }
    },
    {
      type: 'Feature',
      id: 111,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 영덕군 동북동쪽 29km 해역',
        eqDate: '2019-01-01 06:49:51',
        magMl: '3.1',
        eqDt: '19',
        eqLt: '36.53',
        eqLn: '129.66',
        jdLoc: 'Ⅲ',
        zeroDist: '75'
      },
      geometry: { type: 'Point', coordinates: [129.66, 36.53] }
    },
    {
      type: 'Feature',
      id: 112,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 40km 지역',
        eqDate: '2019-01-02 07:20:29',
        magMl: '2.8',
        eqDt: '12',
        eqLt: '41.30',
        eqLn: '129.21',
        jdLoc: '',
        zeroDist: '55'
      },
      geometry: { type: 'Point', coordinates: [129.21, 41.3] }
    },
    {
      type: 'Feature',
      id: 113,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 문경시 북북동쪽 23km 지역',
        eqDate: '2019-01-02 13:12:27',
        magMl: '2.2',
        eqDt: '5',
        eqLt: '36.77',
        eqLn: '128.31',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [128.31, 36.77] }
    },
    {
      type: 'Feature',
      id: 114,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '인천 옹진군 백령도 남남서쪽 76km 해역',
        eqDate: '2019-01-09 03:10:23',
        magMl: '3.7',
        eqDt: '18',
        eqLt: '37.37',
        eqLn: '124.23',
        jdLoc: 'Ⅰ',
        zeroDist: '151'
      },
      geometry: { type: 'Point', coordinates: [124.23, 37.37] }
    },
    {
      type: 'Feature',
      id: 115,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 경주시 남남서쪽 5km 지역',
        eqDate: '2019-01-10 05:31:05',
        magMl: '2.5',
        eqDt: '17',
        eqLt: '35.79',
        eqLn: '129.20',
        jdLoc: 'Ⅳ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [129.2, 35.79] }
    },
    {
      type: 'Feature',
      id: 116,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '충북',
        eqPt: '충북 충주시 북쪽 3km 지역',
        eqDate: '2019-01-13 21:09:20',
        magMl: '2.0',
        eqDt: '17',
        eqLt: '37.02',
        eqLn: '127.93',
        jdLoc: 'Ⅲ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [127.93, 37.02] }
    },
    {
      type: 'Feature',
      id: 117,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '전남 여수시 거문도 동남동쪽 30km 해역',
        eqDate: '2019-01-17 21:34:02',
        magMl: '2.7',
        eqDt: '17',
        eqLt: '33.94',
        eqLn: '127.62',
        jdLoc: 'Ⅰ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [127.62, 33.94] }
    },
    {
      type: 'Feature',
      id: 118,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 영덕군 동북동쪽 29km 해역',
        eqDate: '2019-01-31 19:57:45',
        magMl: '2.2',
        eqDt: '19',
        eqLt: '36.53',
        eqLn: '129.66',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [129.66, 36.53] }
    },
    {
      type: 'Feature',
      id: 119,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 포항시 북구 동북동쪽 50km 해역',
        eqDate: '2019-02-10 12:53:38',
        magMl: '4.1',
        eqDt: '21',
        eqLt: '36.16',
        eqLn: '129.90',
        jdLoc: 'Ⅲ',
        zeroDist: '248'
      },
      geometry: { type: 'Point', coordinates: [129.9, 36.16] }
    },
    {
      type: 'Feature',
      id: 120,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 포항시 북구 동북동쪽 45km 해역',
        eqDate: '2019-02-10 14:12:38',
        magMl: '2.5',
        eqDt: '21',
        eqLt: '36.16',
        eqLn: '129.85',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [129.85, 36.16] }
    },
    {
      type: 'Feature',
      id: 121,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 평양 남동쪽 17km 지역',
        eqDate: '2019-02-13 05:16:13',
        magMl: '2.7',
        eqDt: '21',
        eqLt: '38.90',
        eqLn: '125.86',
        jdLoc: 'Ⅰ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [125.86, 38.9] }
    },
    {
      type: 'Feature',
      id: 122,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '제주',
        eqPt: '제주 서귀포시 서쪽 29km 지역',
        eqDate: '2019-02-17 22:19:01',
        magMl: '2.6',
        eqDt: '12',
        eqLt: '33.29',
        eqLn: '126.25',
        jdLoc: 'Ⅲ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [126.25, 33.29] }
    },
    {
      type: 'Feature',
      id: 123,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 예천군 북북서쪽 8km 지역',
        eqDate: '2019-02-18 05:33:10',
        magMl: '2.4',
        eqDt: '19',
        eqLt: '36.72',
        eqLn: '128.41',
        jdLoc: 'Ⅱ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [128.41, 36.72] }
    },
    {
      type: 'Feature',
      id: 124,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '충남 태안군 서격렬비도 남남서쪽 12km 해역',
        eqDate: '2019-02-20 22:14:47',
        magMl: '2.9',
        eqDt: '14',
        eqLt: '36.51',
        eqLn: '125.50',
        jdLoc: 'Ⅰ',
        zeroDist: '61'
      },
      geometry: { type: 'Point', coordinates: [125.5, 36.51] }
    },
    {
      type: 'Feature',
      id: 125,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 영양군 북북서쪽 14km 지역',
        eqDate: '2019-02-25 09:30:34',
        magMl: '2.9',
        eqDt: '10',
        eqLt: '36.77',
        eqLn: '129.03',
        jdLoc: 'Ⅲ',
        zeroDist: '61'
      },
      geometry: { type: 'Point', coordinates: [129.03, 36.77] }
    },
    {
      type: 'Feature',
      id: 126,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '인천 옹진군 백령도 남쪽 72km 해역',
        eqDate: '2019-02-27 21:22:35',
        magMl: '2.2',
        eqDt: '12',
        eqLt: '37.31',
        eqLn: '124.74',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [124.74, 37.31] }
    },
    {
      type: 'Feature',
      id: 127,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경남도 금야 북서쪽 15km 지역',
        eqDate: '2019-03-03 14:25:57',
        magMl: '2.5',
        eqDt: '',
        eqLt: '39.63',
        eqLn: '127.12',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [127.12, 39.63] }
    },
    {
      type: 'Feature',
      id: 128,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 봉화군 남쪽 8km 지역',
        eqDate: '2019-03-09 15:06:45',
        magMl: '2.0',
        eqDt: '12',
        eqLt: '36.82',
        eqLn: '128.73',
        jdLoc: 'Ⅱ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [128.73, 36.82] }
    },
    {
      type: 'Feature',
      id: 129,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '제주',
        eqPt: '제주 제주시 서남서쪽 13km 지역',
        eqDate: '2019-03-11 04:27:00',
        magMl: '2.4',
        eqDt: '15',
        eqLt: '33.47',
        eqLn: '126.40',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [126.4, 33.47] }
    },
    {
      type: 'Feature',
      id: 130,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '제주 제주시 동북동쪽 40km 해역',
        eqDate: '2019-03-13 12:20:20',
        magMl: '2.4',
        eqDt: '13',
        eqLt: '33.63',
        eqLn: '126.93',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [126.93, 33.63] }
    },
    {
      type: 'Feature',
      id: 131,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '충북',
        eqPt: '충북 옥천군 동쪽 27km 지역',
        eqDate: '2019-03-17 19:28:00',
        magMl: '2.3',
        eqDt: '13',
        eqLt: '36.34',
        eqLn: '127.87',
        jdLoc: 'Ⅱ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [127.87, 36.34] }
    },
    {
      type: 'Feature',
      id: 132,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해남도 해주 동쪽 25km 지역',
        eqDate: '2019-03-19 05:31:06',
        magMl: '2.2',
        eqDt: '11',
        eqLt: '38.01',
        eqLn: '126.00',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [126, 38.01] }
    },
    {
      type: 'Feature',
      id: 133,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '',
        eqPt: '북한 함경북도 길주 북북서쪽 45km 지역',
        eqDate: '2019-03-21 04:41:05',
        magMl: '2.8',
        eqDt: '10',
        eqLt: '41.31',
        eqLn: '129.08',
        jdLoc: '',
        zeroDist: '55'
      },
      geometry: { type: 'Point', coordinates: [129.08, 41.31] }
    },
    {
      type: 'Feature',
      id: 134,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '북한 황해남도 옹진 남남서쪽 43km 해역',
        eqDate: '2019-03-21 07:13:35',
        magMl: '2.7',
        eqDt: '6',
        eqLt: '37.56',
        eqLn: '125.20',
        jdLoc: 'Ⅰ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [125.2, 37.56] }
    },
    {
      type: 'Feature',
      id: 135,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '제주 제주시 동북동쪽 40km 해역',
        eqDate: '2019-03-28 16:21:56',
        magMl: '2.2',
        eqDt: '16',
        eqLt: '33.62',
        eqLn: '126.94',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [126.94, 33.62] }
    },
    {
      type: 'Feature',
      id: 136,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 동해시 북동쪽 54km 해역',
        eqDate: '2019-04-19 11:16:43',
        magMl: '4.3',
        eqDt: '32',
        eqLt: '37.88',
        eqLn: '129.54',
        jdLoc: 'Ⅳ',
        zeroDist: '319'
      },
      geometry: { type: 'Point', coordinates: [129.54, 37.88] }
    },
    {
      type: 'Feature',
      id: 137,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 울진군 동남동쪽 38km 해역',
        eqDate: '2019-04-22 05:45:19',
        magMl: '3.8',
        eqDt: '21',
        eqLt: '36.86',
        eqLn: '129.80',
        jdLoc: 'Ⅲ',
        zeroDist: '171'
      },
      geometry: { type: 'Point', coordinates: [129.8, 36.86] }
    },
    {
      type: 'Feature',
      id: 138,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '제주 제주시 북서쪽 52km 해역',
        eqDate: '2019-04-27 01:10:06',
        magMl: '2.5',
        eqDt: '17',
        eqLt: '33.79',
        eqLn: '126.09',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [126.09, 33.79] }
    },
    {
      type: 'Feature',
      id: 139,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '인천 옹진군 백령도 남쪽 60km 해역',
        eqDate: '2019-05-04 05:43:15',
        magMl: '2.4',
        eqDt: '6',
        eqLt: '37.42',
        eqLn: '124.56',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [124.56, 37.42] }
    },
    {
      type: 'Feature',
      id: 140,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 영덕군 북동쪽 22km 해역',
        eqDate: '2019-05-05 16:57:04',
        magMl: '2.1',
        eqDt: '18',
        eqLt: '36.55',
        eqLn: '129.54',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.54, 36.55] }
    },
    {
      type: 'Feature',
      id: 141,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 영덕군 동북동쪽 29km 해역',
        eqDate: '2019-05-10 16:05:58',
        magMl: '2.0',
        eqDt: '12',
        eqLt: '36.56',
        eqLn: '129.64',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [129.64, 36.56] }
    },
    {
      type: 'Feature',
      id: 142,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '제주 서귀포시 동쪽 58km 해역',
        eqDate: '2019-05-13 23:50:38',
        magMl: '2.4',
        eqDt: '17',
        eqLt: '33.35',
        eqLn: '127.17',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [127.17, 33.35] }
    },
    {
      type: 'Feature',
      id: 143,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 평안남도 양덕 서남서쪽 16km 지역',
        eqDate: '2019-05-16 00:35:45',
        magMl: '3.3',
        eqDt: '5',
        eqLt: '39.15',
        eqLn: '126.47',
        jdLoc: 'Ⅰ',
        zeroDist: '94'
      },
      geometry: { type: 'Point', coordinates: [126.47, 39.15] }
    },
    {
      type: 'Feature',
      id: 144,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 평산 서북서쪽 9km 지역',
        eqDate: '2019-05-17 11:08:33',
        magMl: '2.4',
        eqDt: '6',
        eqLt: '38.37',
        eqLn: '126.30',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [126.3, 38.37] }
    },
    {
      type: 'Feature',
      id: 145,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 경주시 남남서쪽 9km 지역',
        eqDate: '2019-06-11 23:18:53',
        magMl: '2.5',
        eqDt: '19',
        eqLt: '35.76',
        eqLn: '129.19',
        jdLoc: 'Ⅲ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [129.19, 35.76] }
    },
    {
      type: 'Feature',
      id: 146,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전북 부안군 서쪽 34km 해역',
        eqDate: '2019-06-15 22:07:11',
        magMl: '2.2',
        eqDt: '21',
        eqLt: '35.71',
        eqLn: '126.36',
        jdLoc: 'Ⅲ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [126.36, 35.71] }
    },
    {
      type: 'Feature',
      id: 147,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 송림 북북동쪽 9km 지역',
        eqDate: '2019-06-21 13:20:41',
        magMl: '2.5',
        eqDt: '7',
        eqLt: '38.81',
        eqLn: '125.68',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [125.68, 38.81] }
    },
    {
      type: 'Feature',
      id: 148,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 송림 북동쪽 10km 지역',
        eqDate: '2019-06-22 05:43:59',
        magMl: '2.7',
        eqDt: '',
        eqLt: '38.81',
        eqLn: '125.71',
        jdLoc: 'Ⅰ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [125.71, 38.81] }
    },
    {
      type: 'Feature',
      id: 149,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 송림 북동쪽 10km 지역',
        eqDate: '2019-06-22 05:49:36',
        magMl: '3.4',
        eqDt: '',
        eqLt: '38.81',
        eqLn: '125.70',
        jdLoc: 'Ⅰ',
        zeroDist: '106'
      },
      geometry: { type: 'Point', coordinates: [125.7, 38.81] }
    },
    {
      type: 'Feature',
      id: 150,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 송림 북동쪽 10km 지역',
        eqDate: '2019-06-23 05:26:22',
        magMl: '3.4',
        eqDt: '',
        eqLt: '38.81',
        eqLn: '125.70',
        jdLoc: 'Ⅰ',
        zeroDist: '106'
      },
      geometry: { type: 'Point', coordinates: [125.7, 38.81] }
    },
    {
      type: 'Feature',
      id: 151,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 송림 동쪽 8km 지역',
        eqDate: '2019-06-23 13:15:09',
        magMl: '2.4',
        eqDt: '6',
        eqLt: '38.75',
        eqLn: '125.73',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [125.73, 38.75] }
    },
    {
      type: 'Feature',
      id: 152,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 예천군 북서쪽 5km 지역',
        eqDate: '2019-06-23 18:41:15',
        magMl: '2.4',
        eqDt: '16',
        eqLt: '36.69',
        eqLn: '128.41',
        jdLoc: 'Ⅲ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [128.41, 36.69] }
    },
    {
      type: 'Feature',
      id: 153,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 송림 북동쪽 9km 지역',
        eqDate: '2019-06-27 15:19:51',
        magMl: '3.9',
        eqDt: '',
        eqLt: '38.80',
        eqLn: '125.69',
        jdLoc: 'Ⅰ',
        zeroDist: '193'
      },
      geometry: { type: 'Point', coordinates: [125.69, 38.8] }
    },
    {
      type: 'Feature',
      id: 154,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 송림 북동쪽 10km 지역',
        eqDate: '2019-06-27 15:37:27',
        magMl: '2.5',
        eqDt: '',
        eqLt: '38.80',
        eqLn: '125.71',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [125.71, 38.8] }
    },
    {
      type: 'Feature',
      id: 155,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 송림 동북동쪽 8km 지역',
        eqDate: '2019-06-27 15:39:45',
        magMl: '2.3',
        eqDt: '',
        eqLt: '38.77',
        eqLn: '125.71',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [125.71, 38.77] }
    },
    {
      type: 'Feature',
      id: 156,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 포항시 남구 동남동쪽 33km 해역',
        eqDate: '2019-07-02 20:51:51',
        magMl: '2.7',
        eqDt: '21',
        eqLt: '35.88',
        eqLn: '129.69',
        jdLoc: 'Ⅰ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [129.69, 35.88] }
    },
    {
      type: 'Feature',
      id: 157,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 동해시 북동쪽 59km 해역',
        eqDate: '2019-07-08 14:04:43',
        magMl: '2.3',
        eqDt: '',
        eqLt: '37.91',
        eqLn: '129.57',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [129.57, 37.91] }
    },
    {
      type: 'Feature',
      id: 158,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 송림 동남동쪽 9km 지역',
        eqDate: '2019-07-10 01:55:40',
        magMl: '2.1',
        eqDt: '6',
        eqLt: '38.70',
        eqLn: '125.73',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [125.73, 38.7] }
    },
    {
      type: 'Feature',
      id: 159,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 상주시 남서쪽 11km 지역',
        eqDate: '2019-07-20 00:38:40',
        magMl: '2.0',
        eqDt: '15',
        eqLt: '36.33',
        eqLn: '128.08',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [128.08, 36.33] }
    },
    {
      type: 'Feature',
      id: 160,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 상주시 북북서쪽 11km 지역',
        eqDate: '2019-07-21 11:04:18',
        magMl: '3.9',
        eqDt: '14',
        eqLt: '36.50',
        eqLn: '128.10',
        jdLoc: 'Ⅳ',
        zeroDist: '193'
      },
      geometry: { type: 'Point', coordinates: [128.1, 36.5] }
    },
    {
      type: 'Feature',
      id: 161,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전북 군산시 어청도 남서쪽 50km 해역',
        eqDate: '2019-07-22 01:15:45',
        magMl: '2.0',
        eqDt: '13',
        eqLt: '35.82',
        eqLn: '125.56',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [125.56, 35.82] }
    },
    {
      type: 'Feature',
      id: 162,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 울진군 북동쪽 58km 해역',
        eqDate: '2019-07-25 01:46:09',
        magMl: '2.7',
        eqDt: '',
        eqLt: '37.37',
        eqLn: '129.85',
        jdLoc: 'Ⅰ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [129.85, 37.37] }
    },
    {
      type: 'Feature',
      id: 163,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경남',
        eqPt: '경남 고성군 북서쪽 4km 지역',
        eqDate: '2019-07-30 05:45:15',
        magMl: '2.1',
        eqDt: '6',
        eqLt: '35.00',
        eqLn: '128.30',
        jdLoc: 'Ⅱ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [128.3, 35] }
    },
    {
      type: 'Feature',
      id: 164,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '북한 평안북도 철산 남쪽 50km 해역',
        eqDate: '2019-08-09 19:30:43',
        magMl: '2.2',
        eqDt: '',
        eqLt: '39.32',
        eqLn: '124.65',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [124.65, 39.32] }
    },
    {
      type: 'Feature',
      id: 165,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '전남 여수시 거문도 남서쪽 45km 해역',
        eqDate: '2019-08-10 22:45:57',
        magMl: '2.3',
        eqDt: '18',
        eqLt: '33.78',
        eqLn: '126.92',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [126.92, 33.78] }
    },
    {
      type: 'Feature',
      id: 166,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '인천',
        eqPt: '인천 옹진군 백령도 서북서쪽 5km 지역',
        eqDate: '2019-08-11 18:19:50',
        magMl: '2.1',
        eqDt: '10',
        eqLt: '37.97',
        eqLn: '124.63',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [124.63, 37.97] }
    },
    {
      type: 'Feature',
      id: 167,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 남남서쪽 135km 해역',
        eqDate: '2019-08-20 10:34:52',
        magMl: '2.8',
        eqDt: '22',
        eqLt: '33.57',
        eqLn: '124.81',
        jdLoc: 'Ⅰ',
        zeroDist: '55'
      },
      geometry: { type: 'Point', coordinates: [124.81, 33.57] }
    },
    {
      type: 'Feature',
      id: 168,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '인천',
        eqPt: '인천 옹진군 백령도 북동쪽 1km 지역',
        eqDate: '2019-08-23 19:50:48',
        magMl: '2.7',
        eqDt: '7',
        eqLt: '37.96',
        eqLn: '124.69',
        jdLoc: 'Ⅲ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [124.69, 37.96] }
    },
    {
      type: 'Feature',
      id: 169,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 영덕군 동남동쪽 61km 해역',
        eqDate: '2019-08-28 14:22:29',
        magMl: '2.5',
        eqDt: '26',
        eqLt: '36.24',
        eqLn: '130.01',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [130.01, 36.24] }
    },
    {
      type: 'Feature',
      id: 170,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 평안남도 개천 남동쪽 27km 지역',
        eqDate: '2019-09-01 12:58:34',
        magMl: '2.3',
        eqDt: '16',
        eqLt: '39.51',
        eqLn: '126.10',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [126.1, 39.51] }
    },
    {
      type: 'Feature',
      id: 171,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '울산 동구 동남동쪽 12km 해역',
        eqDate: '2019-09-07 23:38:06',
        magMl: '2.0',
        eqDt: '14',
        eqLt: '35.48',
        eqLn: '129.54',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [129.54, 35.48] }
    },
    {
      type: 'Feature',
      id: 172,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경기',
        eqPt: '경기 연천군 북북동쪽 4km 지역',
        eqDate: '2019-09-11 04:52:07',
        magMl: '2.0',
        eqDt: '9',
        eqLt: '38.13',
        eqLn: '127.09',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [127.09, 38.13] }
    },
    {
      type: 'Feature',
      id: 173,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 남남서쪽 123km 해역',
        eqDate: '2019-09-19 18:19:05',
        magMl: '2.6',
        eqDt: '',
        eqLt: '33.61',
        eqLn: '125.01',
        jdLoc: 'Ⅰ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [125.01, 33.61] }
    },
    {
      type: 'Feature',
      id: 174,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 강원 평강 북북서쪽 31km 지역',
        eqDate: '2019-09-21 15:11:16',
        magMl: '3.5',
        eqDt: '17',
        eqLt: '38.67',
        eqLn: '127.17',
        jdLoc: 'Ⅱ',
        zeroDist: '119'
      },
      geometry: { type: 'Point', coordinates: [127.17, 38.67] }
    },
    {
      type: 'Feature',
      id: 175,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 남남서쪽 62km 해역',
        eqDate: '2019-09-22 11:07:27',
        magMl: '3.3',
        eqDt: '17',
        eqLt: '34.16',
        eqLn: '125.16',
        jdLoc: 'Ⅱ',
        zeroDist: '94'
      },
      geometry: { type: 'Point', coordinates: [125.16, 34.16] }
    },
    {
      type: 'Feature',
      id: 176,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 포항시 북구 북북서쪽 6km 지역',
        eqDate: '2019-09-26 02:57:31',
        magMl: '2.3',
        eqDt: '8',
        eqLt: '36.09',
        eqLn: '129.33',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [129.33, 36.09] }
    },
    {
      type: 'Feature',
      id: 177,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 송림 동북동쪽 14km 지역',
        eqDate: '2019-10-02 22:36:57',
        magMl: '2.3',
        eqDt: '11',
        eqLt: '38.79',
        eqLn: '125.78',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [125.78, 38.79] }
    },
    {
      type: 'Feature',
      id: 178,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경남',
        eqPt: '경남 밀양시 동남동쪽 11km 지역',
        eqDate: '2019-10-04 03:57:23',
        magMl: '2.6',
        eqDt: '9',
        eqLt: '35.47',
        eqLn: '128.86',
        jdLoc: 'Ⅲ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [128.86, 35.47] }
    },
    {
      type: 'Feature',
      id: 179,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '울산 동구 남동쪽 48km 해역',
        eqDate: '2019-10-12 20:46:03',
        magMl: '2.5',
        eqDt: '17',
        eqLt: '35.18',
        eqLn: '129.77',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [129.77, 35.18] }
    },
    {
      type: 'Feature',
      id: 180,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 영덕군 동북동쪽 28km 해역',
        eqDate: '2019-10-13 19:52:44',
        magMl: '2.0',
        eqDt: '20',
        eqLt: '36.53',
        eqLn: '129.65',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [129.65, 36.53] }
    },
    {
      type: 'Feature',
      id: 181,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 송림 동북동쪽 9km 지역',
        eqDate: '2019-10-15 22:30:59',
        magMl: '2.4',
        eqDt: '',
        eqLt: '38.78',
        eqLn: '125.72',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [125.72, 38.78] }
    },
    {
      type: 'Feature',
      id: 182,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '제주 제주시 서쪽 130km 해역',
        eqDate: '2019-10-20 00:05:10',
        magMl: '2.2',
        eqDt: '10',
        eqLt: '33.41',
        eqLn: '125.13',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [125.13, 33.41] }
    },
    {
      type: 'Feature',
      id: 183,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경남',
        eqPt: '경남 창녕군 남쪽 15km 지역',
        eqDate: '2019-10-27 15:37:26',
        magMl: '3.4',
        eqDt: '11',
        eqLt: '35.41',
        eqLn: '128.49',
        jdLoc: 'Ⅳ',
        zeroDist: '106'
      },
      geometry: { type: 'Point', coordinates: [128.49, 35.41] }
    },
    {
      type: 'Feature',
      id: 184,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 안동시 북서쪽 16km 지역',
        eqDate: '2019-10-30 02:01:41',
        magMl: '2.3',
        eqDt: '5',
        eqLt: '36.68',
        eqLn: '128.61',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [128.61, 36.68] }
    },
    {
      type: 'Feature',
      id: 185,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '인천',
        eqPt: '인천 옹진군 서남서쪽 52km 지역',
        eqDate: '2019-10-31 16:29:34',
        magMl: '2.1',
        eqDt: '',
        eqLt: '37.24',
        eqLn: '126.11',
        jdLoc: 'Ⅲ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [126.11, 37.24] }
    },
    {
      type: 'Feature',
      id: 186,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '인천 옹진군 백령도 남쪽 37km 해역',
        eqDate: '2019-11-09 23:14:14',
        magMl: '2.4',
        eqDt: '9',
        eqLt: '37.62',
        eqLn: '124.65',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [124.65, 37.62] }
    },
    {
      type: 'Feature',
      id: 187,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 북서쪽 73km 해역',
        eqDate: '2019-11-21 06:08:07',
        magMl: '2.3',
        eqDt: '6',
        eqLt: '35.13',
        eqLn: '124.93',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [124.93, 35.13] }
    },
    {
      type: 'Feature',
      id: 188,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '전남 여수시 거문도 동남동쪽 75km 해역',
        eqDate: '2019-11-21 19:55:50',
        magMl: '2.4',
        eqDt: '12',
        eqLt: '33.83',
        eqLn: '128.09',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [128.09, 33.83] }
    },
    {
      type: 'Feature',
      id: 189,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 울진군 동남동쪽 38km 해역',
        eqDate: '2019-11-29 12:04:43',
        magMl: '2.0',
        eqDt: '21',
        eqLt: '36.86',
        eqLn: '129.79',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [129.79, 36.86] }
    },
    {
      type: 'Feature',
      id: 190,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 남서쪽 106km 해역',
        eqDate: '2019-12-04 17:19:20',
        magMl: '2.6',
        eqDt: '9',
        eqLt: '33.94',
        eqLn: '124.70',
        jdLoc: 'Ⅰ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [124.7, 33.94] }
    },
    {
      type: 'Feature',
      id: 191,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 북서쪽 70km 해역',
        eqDate: '2019-12-08 12:09:24',
        magMl: '2.0',
        eqDt: '',
        eqLt: '35.12',
        eqLn: '124.96',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [124.96, 35.12] }
    },
    {
      type: 'Feature',
      id: 192,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '제주 제주시 동쪽 36km 해역',
        eqDate: '2019-12-12 20:29:55',
        magMl: '2.1',
        eqDt: '21',
        eqLt: '33.55',
        eqLn: '126.92',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [126.92, 33.55] }
    },
    {
      type: 'Feature',
      id: 193,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '충남 서산시 북북서쪽 46km 해역',
        eqDate: '2019-12-15 14:15:40',
        magMl: '2.0',
        eqDt: '11',
        eqLt: '37.16',
        eqLn: '126.22',
        jdLoc: 'Ⅲ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [126.22, 37.16] }
    },
    {
      type: 'Feature',
      id: 194,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해남도 해주 동쪽 25km 지역',
        eqDate: '2019-12-17 16:00:30',
        magMl: '2.1',
        eqDt: '11',
        eqLt: '38.01',
        eqLn: '126.00',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [126, 38.01] }
    },
    {
      type: 'Feature',
      id: 195,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '제주 서귀포시 동쪽 56km 해역',
        eqDate: '2019-12-24 06:19:16',
        magMl: '2.4',
        eqDt: '18',
        eqLt: '33.29',
        eqLn: '127.16',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [127.16, 33.29] }
    },
    {
      type: 'Feature',
      id: 196,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '제주 서귀포시 동쪽 56km 해역',
        eqDate: '2019-12-29 08:43:26',
        magMl: '2.3',
        eqDt: '17',
        eqLt: '33.33',
        eqLn: '127.16',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [127.16, 33.33] }
    },
    {
      type: 'Feature',
      id: 197,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경남',
        eqPt: '경남 밀양시 동북동쪽 15km 지역',
        eqDate: '2019-12-30 00:32:08',
        magMl: '3.5',
        eqDt: '20',
        eqLt: '35.56',
        eqLn: '128.90',
        jdLoc: 'Ⅳ',
        zeroDist: '119'
      },
      geometry: { type: 'Point', coordinates: [128.9, 35.56] }
    },
    {
      type: 'Feature',
      id: 198,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경남',
        eqPt: '경남 밀양시 동북동쪽 15km 지역',
        eqDate: '2019-12-30 01:44:02',
        magMl: '2.1',
        eqDt: '19',
        eqLt: '35.56',
        eqLn: '128.90',
        jdLoc: 'Ⅱ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [128.9, 35.56] }
    },
    {
      type: 'Feature',
      id: 199,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 성주군 동쪽 9km 지역',
        eqDate: '2020-01-08 06:03:00',
        magMl: '2.0',
        eqDt: '17',
        eqLt: '35.91',
        eqLn: '128.38',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [128.38, 35.91] }
    },
    {
      type: 'Feature',
      id: 200,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '충북',
        eqPt: '충북 제천시 동남동쪽 9km 지역',
        eqDate: '2020-01-15 22:46:11',
        magMl: '2.1',
        eqDt: '8',
        eqLt: '37.11',
        eqLn: '128.29',
        jdLoc: 'Ⅱ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [128.29, 37.11] }
    },
    {
      type: 'Feature',
      id: 201,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 41km 지역',
        eqDate: '2020-01-29 09:33:47',
        magMl: '2.5',
        eqDt: '',
        eqLt: '41.29',
        eqLn: '129.11',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [129.11, 41.29] }
    },
    {
      type: 'Feature',
      id: 202,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 상주시 북쪽 20km 지역',
        eqDate: '2020-01-30 00:52:52',
        magMl: '3.2',
        eqDt: '21',
        eqLt: '36.59',
        eqLn: '128.12',
        jdLoc: 'Ⅲ',
        zeroDist: '84'
      },
      geometry: { type: 'Point', coordinates: [128.12, 36.59] }
    },
    {
      type: 'Feature',
      id: 203,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 경주시 서남서쪽 18km 지역',
        eqDate: '2020-02-09 14:41:26',
        magMl: '2.4',
        eqDt: '9',
        eqLt: '35.77',
        eqLn: '129.03',
        jdLoc: 'Ⅲ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [129.03, 35.77] }
    },
    {
      type: 'Feature',
      id: 204,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 영덕군 동쪽 102km 해역',
        eqDate: '2020-02-16 20:03:35',
        magMl: '2.7',
        eqDt: '19',
        eqLt: '36.26',
        eqLn: '130.49',
        jdLoc: 'Ⅰ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [130.49, 36.26] }
    },
    {
      type: 'Feature',
      id: 205,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 평산 북서쪽 24km 지역',
        eqDate: '2020-02-29 22:02:19',
        magMl: '2.7',
        eqDt: '8',
        eqLt: '38.46',
        eqLn: '126.17',
        jdLoc: 'Ⅰ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [126.17, 38.46] }
    },
    {
      type: 'Feature',
      id: 206,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 평산 북서쪽 24km 지역',
        eqDate: '2020-02-29 22:03:50',
        magMl: '2.4',
        eqDt: '8',
        eqLt: '38.45',
        eqLn: '126.17',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [126.17, 38.45] }
    },
    {
      type: 'Feature',
      id: 207,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전북 군산시 어청도 남남서쪽 85km 해역',
        eqDate: '2020-03-10 01:16:33',
        magMl: '2.0',
        eqDt: '12',
        eqLt: '35.44',
        eqLn: '125.54',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [125.54, 35.44] }
    },
    {
      type: 'Feature',
      id: 208,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경남',
        eqPt: '경남 산청군 동남동쪽 13km 지역',
        eqDate: '2020-03-20 16:02:45',
        magMl: '2.7',
        eqDt: '9',
        eqLt: '35.39',
        eqLn: '128.01',
        jdLoc: 'Ⅲ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [128.01, 35.39] }
    },
    {
      type: 'Feature',
      id: 209,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 송림 동북동쪽 8km 지역',
        eqDate: '2020-04-03 19:44:35',
        magMl: '2.2',
        eqDt: '',
        eqLt: '38.75',
        eqLn: '125.72',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [125.72, 38.75] }
    },
    {
      type: 'Feature',
      id: 210,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '인천 강화군 서남서쪽 36km 해역',
        eqDate: '2020-04-18 07:36:28',
        magMl: '2.3',
        eqDt: '14',
        eqLt: '37.58',
        eqLn: '126.14',
        jdLoc: 'Ⅱ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [126.14, 37.58] }
    },
    {
      type: 'Feature',
      id: 211,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 동해시 북동쪽 102km 해역',
        eqDate: '2020-04-22 20:54:26',
        magMl: '2.1',
        eqDt: '9',
        eqLt: '38.11',
        eqLn: '130.01',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [130.01, 38.11] }
    },
    {
      type: 'Feature',
      id: 212,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 북쪽 73km 해역',
        eqDate: '2020-04-24 02:07:22',
        magMl: '2.3',
        eqDt: '15',
        eqLt: '35.30',
        eqLn: '125.54',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [125.54, 35.3] }
    },
    {
      type: 'Feature',
      id: 213,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '전북',
        eqPt: '전북 장수군 북쪽 17km 지역',
        eqDate: '2020-04-27 11:07:10',
        magMl: '2.8',
        eqDt: '6',
        eqLt: '35.80',
        eqLn: '127.53',
        jdLoc: 'Ⅳ',
        zeroDist: '55'
      },
      geometry: { type: 'Point', coordinates: [127.53, 35.8] }
    },
    {
      type: 'Feature',
      id: 214,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '충남',
        eqPt: '충남 금산군 북서쪽 8km 지역',
        eqDate: '2020-04-28 12:06:53',
        magMl: '2.4',
        eqDt: '10',
        eqLt: '36.16',
        eqLn: '127.43',
        jdLoc: 'Ⅱ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [127.43, 36.16] }
    },
    {
      type: 'Feature',
      id: 215,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 목포시 남쪽 17km 해역',
        eqDate: '2020-04-28 12:52:01',
        magMl: '2.1',
        eqDt: '22',
        eqLt: '34.66',
        eqLn: '126.41',
        jdLoc: 'Ⅱ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [126.41, 34.66] }
    },
    {
      type: 'Feature',
      id: 216,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 평안남도 성천 북북서쪽 18km 지역',
        eqDate: '2020-04-29 15:10:47',
        magMl: '2.4',
        eqDt: '',
        eqLt: '39.39',
        eqLn: '126.13',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [126.13, 39.39] }
    },
    {
      type: 'Feature',
      id: 217,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '전남',
        eqPt: '전남 해남군 서북서쪽 21km 지역',
        eqDate: '2020-04-30 07:13:13',
        magMl: '2.4',
        eqDt: '21',
        eqLt: '34.66',
        eqLn: '126.40',
        jdLoc: 'Ⅲ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [126.4, 34.66] }
    },
    {
      type: 'Feature',
      id: 218,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '전남',
        eqPt: '전남 해남군 서북서쪽 21km 지역',
        eqDate: '2020-05-02 02:57:02',
        magMl: '2.3',
        eqDt: '21',
        eqLt: '34.66',
        eqLn: '126.40',
        jdLoc: 'Ⅱ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [126.4, 34.66] }
    },
    {
      type: 'Feature',
      id: 219,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '전남',
        eqPt: '전남 해남군 서북서쪽 21km 지역',
        eqDate: '2020-05-03 22:07:14',
        magMl: '3.1',
        eqDt: '21',
        eqLt: '34.66',
        eqLn: '126.40',
        jdLoc: 'Ⅲ',
        zeroDist: '75'
      },
      geometry: { type: 'Point', coordinates: [126.4, 34.66] }
    },
    {
      type: 'Feature',
      id: 220,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 평산 북서쪽 26km 지역',
        eqDate: '2020-05-05 06:44:29',
        magMl: '2.4',
        eqDt: '',
        eqLt: '38.47',
        eqLn: '126.16',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [126.16, 38.47] }
    },
    {
      type: 'Feature',
      id: 221,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 서쪽 73km 해역',
        eqDate: '2020-05-07 02:07:31',
        magMl: '2.1',
        eqDt: '17',
        eqLt: '34.77',
        eqLn: '124.68',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [124.68, 34.77] }
    },
    {
      type: 'Feature',
      id: 222,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '전남',
        eqPt: '전남 해남군 서북서쪽 21km 지역',
        eqDate: '2020-05-09 01:57:39',
        magMl: '2.2',
        eqDt: '21',
        eqLt: '34.66',
        eqLn: '126.40',
        jdLoc: 'Ⅱ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [126.4, 34.66] }
    },
    {
      type: 'Feature',
      id: 223,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 평안남도 성천 남쪽 41km 지역',
        eqDate: '2020-05-09 12:27:08',
        magMl: '2.2',
        eqDt: '8',
        eqLt: '38.87',
        eqLn: '126.22',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [126.22, 38.87] }
    },
    {
      type: 'Feature',
      id: 224,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 강원 평강 북북서쪽 32km 지역',
        eqDate: '2020-05-11 19:45:06',
        magMl: '3.8',
        eqDt: '16',
        eqLt: '38.68',
        eqLn: '127.18',
        jdLoc: 'Ⅱ',
        zeroDist: '171'
      },
      geometry: { type: 'Point', coordinates: [127.18, 38.68] }
    },
    {
      type: 'Feature',
      id: 225,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '전북',
        eqPt: '전북 완주군 북동쪽 27km 지역',
        eqDate: '2020-05-13 15:19:02',
        magMl: '2.8',
        eqDt: '12',
        eqLt: '36.10',
        eqLn: '127.34',
        jdLoc: 'Ⅲ',
        zeroDist: '55'
      },
      geometry: { type: 'Point', coordinates: [127.34, 36.1] }
    },
    {
      type: 'Feature',
      id: 226,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '북한 황해남도 옹진 남서쪽 43km 해역',
        eqDate: '2020-05-22 21:18:32',
        magMl: '2.1',
        eqDt: '11',
        eqLt: '37.67',
        eqLn: '125.00',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [125, 37.67] }
    },
    {
      type: 'Feature',
      id: 227,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 김천시 남남서쪽 17km 지역',
        eqDate: '2020-05-27 19:23:28',
        magMl: '2.8',
        eqDt: '6',
        eqLt: '36.01',
        eqLn: '128.01',
        jdLoc: 'Ⅲ',
        zeroDist: '55'
      },
      geometry: { type: 'Point', coordinates: [128.01, 36.01] }
    },
    {
      type: 'Feature',
      id: 228,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 송림 동북동쪽 5km 지역',
        eqDate: '2020-06-04 02:21:01',
        magMl: '2.5',
        eqDt: '6',
        eqLt: '38.75',
        eqLn: '125.69',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [125.69, 38.75] }
    },
    {
      type: 'Feature',
      id: 229,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 김천시 남남서쪽 17km 지역',
        eqDate: '2020-06-08 00:52:10',
        magMl: '2.1',
        eqDt: '5',
        eqLt: '36.01',
        eqLn: '128.01',
        jdLoc: 'Ⅲ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [128.01, 36.01] }
    },
    {
      type: 'Feature',
      id: 230,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 고성군 북북동쪽 11km 해역',
        eqDate: '2020-06-21 09:49:52',
        magMl: '2.3',
        eqDt: '9',
        eqLt: '38.47',
        eqLn: '128.52',
        jdLoc: 'Ⅱ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [128.52, 38.47] }
    },
    {
      type: 'Feature',
      id: 231,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경기',
        eqPt: '경기 파주시 북동쪽 15km 지역',
        eqDate: '2020-06-23 19:58:05',
        magMl: '2.1',
        eqDt: '6',
        eqLt: '37.86',
        eqLn: '126.90',
        jdLoc: 'Ⅲ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [126.9, 37.86] }
    },
    {
      type: 'Feature',
      id: 232,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 사리원 북동쪽 24km 지역',
        eqDate: '2020-06-24 03:28:47',
        magMl: '2.5',
        eqDt: '',
        eqLt: '38.66',
        eqLn: '125.95',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [125.95, 38.66] }
    },
    {
      type: 'Feature',
      id: 233,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '강원',
        eqPt: '강원 영월군 동쪽 22km 지역',
        eqDate: '2020-07-03 13:59:31',
        magMl: '2.3',
        eqDt: '6',
        eqLt: '37.18',
        eqLn: '128.71',
        jdLoc: 'Ⅲ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [128.71, 37.18] }
    },
    {
      type: 'Feature',
      id: 234,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '인천 옹진군 연평도 남남서쪽 54km 해역',
        eqDate: '2020-07-07 14:08:23',
        magMl: '3.3',
        eqDt: '12',
        eqLt: '37.19',
        eqLn: '125.55',
        jdLoc: 'Ⅲ',
        zeroDist: '94'
      },
      geometry: { type: 'Point', coordinates: [125.55, 37.19] }
    },
    {
      type: 'Feature',
      id: 235,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 동해시 북동쪽 57km 해역',
        eqDate: '2020-07-11 22:38:55',
        magMl: '2.7',
        eqDt: '',
        eqLt: '37.88',
        eqLn: '129.58',
        jdLoc: 'Ⅰ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [129.58, 37.88] }
    },
    {
      type: 'Feature',
      id: 236,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 서북서쪽 77km 해역',
        eqDate: '2020-07-15 15:20:47',
        magMl: '2.4',
        eqDt: '',
        eqLt: '34.88',
        eqLn: '124.68',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [124.68, 34.88] }
    },
    {
      type: 'Feature',
      id: 237,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '경남 남해군 남남동쪽 41km 해역',
        eqDate: '2020-07-20 19:02:00',
        magMl: '2.0',
        eqDt: '11',
        eqLt: '34.53',
        eqLn: '128.14',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [128.14, 34.53] }
    },
    {
      type: 'Feature',
      id: 238,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 남쪽 66km 해역',
        eqDate: '2020-07-25 00:34:06',
        magMl: '2.2',
        eqDt: '7',
        eqLt: '34.06',
        eqLn: '125.54',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [125.54, 34.06] }
    },
    {
      type: 'Feature',
      id: 239,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 강원 평강 북북서쪽 29km 지역',
        eqDate: '2020-08-01 02:58:47',
        magMl: '2.0',
        eqDt: '17',
        eqLt: '38.65',
        eqLn: '127.16',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [127.16, 38.65] }
    },
    {
      type: 'Feature',
      id: 240,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 예천군 북쪽 13km 지역',
        eqDate: '2020-08-01 13:15:06',
        magMl: '2.0',
        eqDt: '19',
        eqLt: '36.77',
        eqLn: '128.48',
        jdLoc: 'Ⅱ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [128.48, 36.77] }
    },
    {
      type: 'Feature',
      id: 241,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 송림 북북동쪽 14km 지역',
        eqDate: '2020-08-05 01:22:48',
        magMl: '2.0',
        eqDt: '7',
        eqLt: '38.85',
        eqLn: '125.71',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [125.71, 38.85] }
    },
    {
      type: 'Feature',
      id: 242,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 43km 지역',
        eqDate: '2020-08-07 21:27:43',
        magMl: '2.4',
        eqDt: '',
        eqLt: '41.30',
        eqLn: '129.09',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [129.09, 41.3] }
    },
    {
      type: 'Feature',
      id: 243,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해남도 해주 남동쪽 24km 지역',
        eqDate: '2020-08-21 03:52:51',
        magMl: '2.5',
        eqDt: '14',
        eqLt: '37.91',
        eqLn: '125.93',
        jdLoc: 'Ⅱ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [125.93, 37.91] }
    },
    {
      type: 'Feature',
      id: 244,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '전남',
        eqPt: '전남 진도군 남남서쪽 13km 지역',
        eqDate: '2020-09-02 19:43:06',
        magMl: '2.3',
        eqDt: '',
        eqLt: '34.38',
        eqLn: '126.20',
        jdLoc: 'Ⅱ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [126.2, 34.38] }
    },
    {
      type: 'Feature',
      id: 245,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 칠곡군 북쪽 4km 지역',
        eqDate: '2020-09-12 13:31:05',
        magMl: '2.4',
        eqDt: '15',
        eqLt: '36.03',
        eqLn: '128.40',
        jdLoc: 'Ⅱ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [128.4, 36.03] }
    },
    {
      type: 'Feature',
      id: 246,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '인천 옹진군 백령도 남남서쪽 35km 해역',
        eqDate: '2020-09-13 00:54:03',
        magMl: '2.9',
        eqDt: '15',
        eqLt: '37.64',
        eqLn: '124.60',
        jdLoc: 'Ⅰ',
        zeroDist: '61'
      },
      geometry: { type: 'Point', coordinates: [124.6, 37.64] }
    },
    {
      type: 'Feature',
      id: 247,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '충남',
        eqPt: '충남 공주시 북북동쪽 7km 지역',
        eqDate: '2020-09-20 01:22:14',
        magMl: '2.2',
        eqDt: '17',
        eqLt: '36.51',
        eqLn: '127.14',
        jdLoc: 'Ⅲ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [127.14, 36.51] }
    },
    {
      type: 'Feature',
      id: 248,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 영덕군 북쪽 21km 지역',
        eqDate: '2020-09-22 20:08:54',
        magMl: '2.1',
        eqDt: '15',
        eqLt: '36.60',
        eqLn: '129.37',
        jdLoc: 'Ⅱ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.37, 36.6] }
    },
    {
      type: 'Feature',
      id: 249,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 송림 동쪽 22km 지역',
        eqDate: '2020-10-02 21:31:23',
        magMl: '2.5',
        eqDt: '',
        eqLt: '38.74',
        eqLn: '125.89',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [125.89, 38.74] }
    },
    {
      type: 'Feature',
      id: 250,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 송림 동쪽 23km 지역',
        eqDate: '2020-10-02 22:29:50',
        magMl: '2.6',
        eqDt: '',
        eqLt: '38.74',
        eqLn: '125.90',
        jdLoc: 'Ⅰ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [125.9, 38.74] }
    },
    {
      type: 'Feature',
      id: 251,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 연산 서남서쪽 20km 지역',
        eqDate: '2020-10-03 01:37:30',
        magMl: '2.3',
        eqDt: '',
        eqLt: '38.83',
        eqLn: '126.03',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [126.03, 38.83] }
    },
    {
      type: 'Feature',
      id: 252,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '전남 여수시 거문도 남동쪽 16km 해역',
        eqDate: '2020-10-07 04:05:32',
        magMl: '2.1',
        eqDt: '22',
        eqLt: '33.92',
        eqLn: '127.44',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [127.44, 33.92] }
    },
    {
      type: 'Feature',
      id: 253,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 경주시 남남서쪽 13km 지역',
        eqDate: '2020-10-09 04:38:14',
        magMl: '2.1',
        eqDt: '15',
        eqLt: '35.75',
        eqLn: '129.17',
        jdLoc: 'Ⅲ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.17, 35.75] }
    },
    {
      type: 'Feature',
      id: 254,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '전남 여수시 거문도 남동쪽 16km 해역',
        eqDate: '2020-10-24 15:09:01',
        magMl: '2.1',
        eqDt: '21',
        eqLt: '33.93',
        eqLn: '127.45',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [127.45, 33.93] }
    },
    {
      type: 'Feature',
      id: 255,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 진도군 남서쪽 87km 해역',
        eqDate: '2020-10-25 19:10:49',
        magMl: '2.5',
        eqDt: '17',
        eqLt: '33.89',
        eqLn: '125.65',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [125.65, 33.89] }
    },
    {
      type: 'Feature',
      id: 256,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 송림 북동쪽 10km 지역',
        eqDate: '2020-11-04 04:41:54',
        magMl: '2.8',
        eqDt: '',
        eqLt: '38.79',
        eqLn: '125.72',
        jdLoc: 'Ⅰ',
        zeroDist: '55'
      },
      geometry: { type: 'Point', coordinates: [125.72, 38.79] }
    },
    {
      type: 'Feature',
      id: 257,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 포항시 남구 동남동쪽 43km 해역',
        eqDate: '2020-11-04 07:23:48',
        magMl: '2.2',
        eqDt: '23',
        eqLt: '35.83',
        eqLn: '129.78',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [129.78, 35.83] }
    },
    {
      type: 'Feature',
      id: 258,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 포항시 남구 동남동쪽 43km 해역',
        eqDate: '2020-11-04 16:50:30',
        magMl: '2.4',
        eqDt: '22',
        eqLt: '35.84',
        eqLn: '129.79',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [129.79, 35.84] }
    },
    {
      type: 'Feature',
      id: 259,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 상주시 북서쪽 3km 지역',
        eqDate: '2020-11-06 18:27:23',
        magMl: '2.0',
        eqDt: '8',
        eqLt: '36.43',
        eqLn: '128.14',
        jdLoc: 'Ⅱ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [128.14, 36.43] }
    },
    {
      type: 'Feature',
      id: 260,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 상주시 동쪽 5km 지역',
        eqDate: '2020-11-08 15:26:09',
        magMl: '2.9',
        eqDt: '9',
        eqLt: '36.41',
        eqLn: '128.22',
        jdLoc: 'Ⅳ',
        zeroDist: '61'
      },
      geometry: { type: 'Point', coordinates: [128.22, 36.41] }
    },
    {
      type: 'Feature',
      id: 261,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 경주시 남남서쪽 11km 지역',
        eqDate: '2020-11-15 00:00:29',
        magMl: '2.1',
        eqDt: '17',
        eqLt: '35.76',
        eqLn: '129.18',
        jdLoc: 'Ⅲ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.18, 35.76] }
    },
    {
      type: 'Feature',
      id: 262,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 38km 지역',
        eqDate: '2020-11-17 03:10:29',
        magMl: '2.6',
        eqDt: '',
        eqLt: '41.28',
        eqLn: '129.18',
        jdLoc: 'Ⅰ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [129.18, 41.28] }
    },
    {
      type: 'Feature',
      id: 263,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해남도 옹진 북쪽 7km 지역',
        eqDate: '2020-11-19 23:17:28',
        magMl: '3.1',
        eqDt: '15',
        eqLt: '37.99',
        eqLn: '125.37',
        jdLoc: 'Ⅰ',
        zeroDist: '75'
      },
      geometry: { type: 'Point', coordinates: [125.37, 37.99] }
    },
    {
      type: 'Feature',
      id: 264,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '북한 황해남도 옹진 남남서쪽 31km 해역',
        eqDate: '2020-12-15 17:15:31',
        magMl: '2.3',
        eqDt: '16',
        eqLt: '37.70',
        eqLn: '125.17',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [125.17, 37.7] }
    },
    {
      type: 'Feature',
      id: 265,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 동해시 북동쪽 24km 해역',
        eqDate: '2020-12-18 17:17:23',
        magMl: '2.7',
        eqDt: '16',
        eqLt: '37.66',
        eqLn: '129.32',
        jdLoc: 'Ⅱ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [129.32, 37.66] }
    },
    {
      type: 'Feature',
      id: 266,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경기',
        eqPt: '경기 파주시 남서쪽 6km 지역',
        eqDate: '2020-12-23 21:28:20',
        magMl: '2.2',
        eqDt: '12',
        eqLt: '37.73',
        eqLn: '126.73',
        jdLoc: 'Ⅲ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [126.73, 37.73] }
    },
    {
      type: 'Feature',
      id: 267,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '충남 태안군 서격렬비도 북북서쪽 30km 해역',
        eqDate: '2021-01-14 08:17:28',
        magMl: '2.1',
        eqDt: '',
        eqLt: '36.86',
        eqLn: '125.43',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [125.43, 36.86] }
    },
    {
      type: 'Feature',
      id: 268,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 경주시 남동쪽 22km 지역',
        eqDate: '2021-01-17 01:41:40',
        magMl: '1.1',
        eqDt: '12',
        eqLt: '35.72',
        eqLn: '129.40',
        jdLoc: 'Ⅰ',
        zeroDist: '18'
      },
      geometry: { type: 'Point', coordinates: [129.4, 35.72] }
    },
    {
      type: 'Feature',
      id: 269,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전북 군산시 어청도 서남서쪽 81km 해역',
        eqDate: '2021-01-24 06:23:05',
        magMl: '2.1',
        eqDt: '11',
        eqLt: '35.84',
        eqLn: '125.14',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [125.14, 35.84] }
    },
    {
      type: 'Feature',
      id: 270,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '인천 옹진군 서남서쪽 38km 해역',
        eqDate: '2021-02-03 12:17:56',
        magMl: '2.2',
        eqDt: '8',
        eqLt: '37.32',
        eqLn: '126.24',
        jdLoc: 'Ⅲ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [126.24, 37.32] }
    },
    {
      type: 'Feature',
      id: 271,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '전북',
        eqPt: '전북 장수군 북쪽 18km 지역',
        eqDate: '2021-02-06 04:40:03',
        magMl: '2.7',
        eqDt: '5',
        eqLt: '35.81',
        eqLn: '127.53',
        jdLoc: 'Ⅳ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [127.53, 35.81] }
    },
    {
      type: 'Feature',
      id: 272,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경남도 장진 북북동쪽 37km 지역',
        eqDate: '2021-02-08 03:37:34',
        magMl: '3.2',
        eqDt: '24',
        eqLt: '40.67',
        eqLn: '127.45',
        jdLoc: 'Ⅰ',
        zeroDist: '84'
      },
      geometry: { type: 'Point', coordinates: [127.45, 40.67] }
    },
    {
      type: 'Feature',
      id: 273,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경남도 장진 북동쪽 43km 지역',
        eqDate: '2021-02-08 07:46:51',
        magMl: '2.3',
        eqDt: '24',
        eqLt: '40.64',
        eqLn: '127.62',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [127.62, 40.64] }
    },
    {
      type: 'Feature',
      id: 274,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '인천 옹진군 서남서쪽 38km 해역',
        eqDate: '2021-02-10 19:28:18',
        magMl: '2.1',
        eqDt: '9',
        eqLt: '37.32',
        eqLn: '126.24',
        jdLoc: 'Ⅱ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [126.24, 37.32] }
    },
    {
      type: 'Feature',
      id: 275,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '전북',
        eqPt: '전북 군산시 동북동쪽 10km 지역',
        eqDate: '2021-02-15 18:59:25',
        magMl: '2.5',
        eqDt: '14',
        eqLt: '35.99',
        eqLn: '126.84',
        jdLoc: 'Ⅳ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [126.84, 35.99] }
    },
    {
      type: 'Feature',
      id: 276,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 영광군 북서쪽 27km 해역',
        eqDate: '2021-02-21 09:03:34',
        magMl: '2.2',
        eqDt: '18',
        eqLt: '35.47',
        eqLn: '126.34',
        jdLoc: 'Ⅱ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [126.34, 35.47] }
    },
    {
      type: 'Feature',
      id: 277,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 경주시 남남서쪽 11km 지역',
        eqDate: '2021-03-16 02:51:52',
        magMl: '2.6',
        eqDt: '18',
        eqLt: '35.76',
        eqLn: '129.19',
        jdLoc: 'Ⅲ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [129.19, 35.76] }
    },
    {
      type: 'Feature',
      id: 278,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '전북',
        eqPt: '전북 익산시 북북서쪽 9km 지역',
        eqDate: '2021-04-13 21:56:15',
        magMl: '2.0',
        eqDt: '13',
        eqLt: '36.02',
        eqLn: '126.91',
        jdLoc: 'Ⅲ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [126.91, 36.02] }
    },
    {
      type: 'Feature',
      id: 279,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경기',
        eqPt: '경기 여주시 동쪽 7km 지역',
        eqDate: '2021-04-16 01:43:23',
        magMl: '2.2',
        eqDt: '11',
        eqLt: '37.29',
        eqLn: '127.71',
        jdLoc: 'Ⅲ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [127.71, 37.29] }
    },
    {
      type: 'Feature',
      id: 280,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 북서쪽 54km 해역',
        eqDate: '2021-04-19 14:20:27',
        magMl: '3.7',
        eqDt: '15',
        eqLt: '35.07',
        eqLn: '125.08',
        jdLoc: 'Ⅱ',
        zeroDist: '151'
      },
      geometry: { type: 'Point', coordinates: [125.08, 35.07] }
    },
    {
      type: 'Feature',
      id: 281,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 40km 지역',
        eqDate: '2021-04-19 15:48:52',
        magMl: '2.7',
        eqDt: '20',
        eqLt: '41.28',
        eqLn: '129.13',
        jdLoc: 'Ⅰ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [129.13, 41.28] }
    },
    {
      type: 'Feature',
      id: 282,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 북서쪽 57km 해역',
        eqDate: '2021-04-20 02:13:27',
        magMl: '2.6',
        eqDt: '15',
        eqLt: '35.08',
        eqLn: '125.05',
        jdLoc: 'Ⅰ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [125.05, 35.08] }
    },
    {
      type: 'Feature',
      id: 283,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경남도 장진 동북동쪽 26km 지역',
        eqDate: '2021-04-23 02:41:27',
        magMl: '2.2',
        eqDt: '6',
        eqLt: '40.49',
        eqLn: '127.51',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [127.51, 40.49] }
    },
    {
      type: 'Feature',
      id: 284,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '인천 옹진군 백령도 남남서쪽 60km 해역',
        eqDate: '2021-04-25 17:07:59',
        magMl: '2.3',
        eqDt: '5',
        eqLt: '37.48',
        eqLn: '124.36',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [124.36, 37.48] }
    },
    {
      type: 'Feature',
      id: 285,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '울산 동구 동북동쪽 37km 해역',
        eqDate: '2021-04-28 12:00:40',
        magMl: '2.5',
        eqDt: '20',
        eqLt: '35.65',
        eqLn: '129.79',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [129.79, 35.65] }
    },
    {
      type: 'Feature',
      id: 286,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '울산 동구 동북동쪽 41km 해역',
        eqDate: '2021-04-29 05:12:25',
        magMl: '2.0',
        eqDt: '18',
        eqLt: '35.65',
        eqLn: '129.83',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [129.83, 35.65] }
    },
    {
      type: 'Feature',
      id: 287,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경남',
        eqPt: '경남 밀양시 동쪽 12km 지역',
        eqDate: '2021-04-30 16:15:04',
        magMl: '2.1',
        eqDt: '7',
        eqLt: '35.52',
        eqLn: '128.88',
        jdLoc: 'Ⅲ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [128.88, 35.52] }
    },
    {
      type: 'Feature',
      id: 288,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 포항시 남구 동남동쪽 31km 해역',
        eqDate: '2021-05-04 22:42:08',
        magMl: '2.0',
        eqDt: '19',
        eqLt: '35.89',
        eqLn: '129.67',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [129.67, 35.89] }
    },
    {
      type: 'Feature',
      id: 289,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '전북',
        eqPt: '전북 완주군 동쪽 15km 지역',
        eqDate: '2021-05-13 06:04:28',
        magMl: '2.0',
        eqDt: '10',
        eqLt: '35.89',
        eqLn: '127.33',
        jdLoc: 'Ⅲ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [127.33, 35.89] }
    },
    {
      type: 'Feature',
      id: 290,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 연산 남서쪽 23km 지역',
        eqDate: '2021-05-30 23:53:53',
        magMl: '2.4',
        eqDt: '9',
        eqLt: '38.73',
        eqLn: '126.06',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [126.06, 38.73] }
    },
    {
      type: 'Feature',
      id: 291,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '전남 여수시 거문도 동남동쪽 80km 해역',
        eqDate: '2021-06-07 18:34:02',
        magMl: '2.1',
        eqDt: '13',
        eqLt: '33.68',
        eqLn: '128.07',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [128.07, 33.68] }
    },
    {
      type: 'Feature',
      id: 292,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 울릉군 울릉도 남서쪽 76km 해역',
        eqDate: '2021-06-13 06:13:51',
        magMl: '2.0',
        eqDt: '26',
        eqLt: '36.97',
        eqLn: '130.34',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [130.34, 36.97] }
    },
    {
      type: 'Feature',
      id: 293,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 41km 지역',
        eqDate: '2021-06-13 23:57:32',
        magMl: '2.5',
        eqDt: '15',
        eqLt: '41.29',
        eqLn: '129.13',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [129.13, 41.29] }
    },
    {
      type: 'Feature',
      id: 294,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 포항시 남구 동남동쪽 43km 해역',
        eqDate: '2021-06-14 22:42:17',
        magMl: '2.4',
        eqDt: '20',
        eqLt: '35.81',
        eqLn: '129.77',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [129.77, 35.81] }
    },
    {
      type: 'Feature',
      id: 295,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 예천군 북쪽 14km 지역',
        eqDate: '2021-07-07 06:57:27',
        magMl: '2.3',
        eqDt: '20',
        eqLt: '36.77',
        eqLn: '128.46',
        jdLoc: 'Ⅱ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [128.46, 36.77] }
    },
    {
      type: 'Feature',
      id: 296,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 상주시 남남동쪽 11km 지역',
        eqDate: '2021-07-26 06:50:56',
        magMl: '2.2',
        eqDt: '14',
        eqLt: '36.32',
        eqLn: '128.21',
        jdLoc: 'Ⅲ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [128.21, 36.32] }
    },
    {
      type: 'Feature',
      id: 297,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '대구',
        eqPt: '대구 달성군 남남서쪽 14km 지역',
        eqDate: '2021-07-31 05:17:10',
        magMl: '2.5',
        eqDt: '22',
        eqLt: '35.65',
        eqLn: '128.40',
        jdLoc: 'Ⅲ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [128.4, 35.65] }
    },
    {
      type: 'Feature',
      id: 298,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '울산 북구 동북동쪽 17km 해역',
        eqDate: '2021-08-01 14:19:26',
        magMl: '2.2',
        eqDt: '13',
        eqLt: '35.65',
        eqLn: '129.53',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [129.53, 35.65] }
    },
    {
      type: 'Feature',
      id: 299,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '부산 기장군 동남동쪽 53km 해역',
        eqDate: '2021-08-15 09:32:43',
        magMl: '2.0',
        eqDt: '18',
        eqLt: '35.10',
        eqLn: '129.78',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [129.78, 35.1] }
    },
    {
      type: 'Feature',
      id: 300,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '부산 기장군 동남동쪽 58km 해역',
        eqDate: '2021-08-18 16:57:11',
        magMl: '2.1',
        eqDt: '',
        eqLt: '35.10',
        eqLn: '129.84',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.84, 35.1] }
    },
    {
      type: 'Feature',
      id: 301,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 강원 회양 남동쪽 50km 지역',
        eqDate: '2021-08-20 22:26:52',
        magMl: '2.1',
        eqDt: '5',
        eqLt: '38.37',
        eqLn: '127.99',
        jdLoc: 'Ⅱ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [127.99, 38.37] }
    },
    {
      type: 'Feature',
      id: 302,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전북 군산시 어청도 서남서쪽 124km 해역',
        eqDate: '2021-08-21 09:40:40',
        magMl: '4.0',
        eqDt: '7',
        eqLt: '35.70',
        eqLn: '124.70',
        jdLoc: 'Ⅱ',
        zeroDist: '219'
      },
      geometry: { type: 'Point', coordinates: [124.7, 35.7] }
    },
    {
      type: 'Feature',
      id: 303,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 북북서쪽 52km 해역',
        eqDate: '2021-08-26 15:58:14',
        magMl: '2.3',
        eqDt: '17',
        eqLt: '35.07',
        eqLn: '125.12',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [125.12, 35.07] }
    },
    {
      type: 'Feature',
      id: 304,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '전남',
        eqPt: '전남 광양시 북북동쪽 7km 지역',
        eqDate: '2021-08-28 07:03:22',
        magMl: '2.2',
        eqDt: '15',
        eqLt: '35.00',
        eqLn: '127.73',
        jdLoc: 'Ⅲ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [127.73, 35] }
    },
    {
      type: 'Feature',
      id: 305,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경남',
        eqPt: '경남 함안군 동북동쪽 9km 지역',
        eqDate: '2021-08-29 13:03:23',
        magMl: '2.2',
        eqDt: '9',
        eqLt: '35.31',
        eqLn: '128.49',
        jdLoc: 'Ⅲ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [128.49, 35.31] }
    },
    {
      type: 'Feature',
      id: 306,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '충남 태안군 서격렬비도 북서쪽 62km 해역',
        eqDate: '2021-08-29 18:47:20',
        magMl: '2.6',
        eqDt: '15',
        eqLt: '36.98',
        eqLn: '125.02',
        jdLoc: 'Ⅰ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [125.02, 36.98] }
    },
    {
      type: 'Feature',
      id: 307,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 42km 지역',
        eqDate: '2021-09-01 18:39:52',
        magMl: '2.4',
        eqDt: '15',
        eqLt: '41.30',
        eqLn: '129.13',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [129.13, 41.3] }
    },
    {
      type: 'Feature',
      id: 308,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 40km 지역',
        eqDate: '2021-09-11 03:47:27',
        magMl: '2.1',
        eqDt: '8',
        eqLt: '41.29',
        eqLn: '129.15',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.15, 41.29] }
    },
    {
      type: 'Feature',
      id: 309,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경남',
        eqPt: '경남 창녕군 동남동쪽 4km 지역',
        eqDate: '2021-09-12 02:04:46',
        magMl: '2.1',
        eqDt: '13',
        eqLt: '35.53',
        eqLn: '128.53',
        jdLoc: 'Ⅱ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [128.53, 35.53] }
    },
    {
      type: 'Feature',
      id: 310,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경남',
        eqPt: '경남 밀양시 남남동쪽 11km 지역',
        eqDate: '2021-09-14 03:36:01',
        magMl: '2.2',
        eqDt: '16',
        eqLt: '35.41',
        eqLn: '128.78',
        jdLoc: 'Ⅲ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [128.78, 35.41] }
    },
    {
      type: 'Feature',
      id: 311,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '충남',
        eqPt: '충남 태안군 서남서쪽 16km 지역',
        eqDate: '2021-09-17 05:30:13',
        magMl: '2.2',
        eqDt: '8',
        eqLt: '36.71',
        eqLn: '126.12',
        jdLoc: 'Ⅱ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [126.12, 36.71] }
    },
    {
      type: 'Feature',
      id: 312,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '전남',
        eqPt: '전남 보성군 북동쪽 20km 지역',
        eqDate: '2021-09-20 06:58:49',
        magMl: '2.2',
        eqDt: '8',
        eqLt: '34.88',
        eqLn: '127.25',
        jdLoc: 'Ⅲ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [127.25, 34.88] }
    },
    {
      type: 'Feature',
      id: 313,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경남',
        eqPt: '경남 창녕군 남쪽 14km 지역',
        eqDate: '2021-09-23 12:02:43',
        magMl: '2.6',
        eqDt: '16',
        eqLt: '35.42',
        eqLn: '128.49',
        jdLoc: 'Ⅳ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [128.49, 35.42] }
    },
    {
      type: 'Feature',
      id: 314,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경남',
        eqPt: '경남 하동군 북북동쪽 23km 지역',
        eqDate: '2021-10-06 21:55:48',
        magMl: '2.5',
        eqDt: '13',
        eqLt: '35.25',
        eqLn: '127.86',
        jdLoc: 'Ⅲ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [127.86, 35.25] }
    },
    {
      type: 'Feature',
      id: 315,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 북서쪽 58km 해역',
        eqDate: '2021-10-10 02:18:51',
        magMl: '2.1',
        eqDt: '7',
        eqLt: '35.10',
        eqLn: '125.06',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [125.06, 35.1] }
    },
    {
      type: 'Feature',
      id: 316,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 39km 지역',
        eqDate: '2021-10-11 22:00:53',
        magMl: '2.5',
        eqDt: '20',
        eqLt: '41.29',
        eqLn: '129.20',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [129.2, 41.29] }
    },
    {
      type: 'Feature',
      id: 317,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북동쪽 36km 지역',
        eqDate: '2021-10-17 23:13:56',
        magMl: '2.4',
        eqDt: '33',
        eqLt: '41.26',
        eqLn: '129.45',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [129.45, 41.26] }
    },
    {
      type: 'Feature',
      id: 318,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경남도 장진 동남동쪽 4km 지역',
        eqDate: '2021-10-19 06:41:35',
        magMl: '2.6',
        eqDt: '',
        eqLt: '40.37',
        eqLn: '127.28',
        jdLoc: 'Ⅰ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [127.28, 40.37] }
    },
    {
      type: 'Feature',
      id: 319,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경남도 장진 북동쪽 4km 지역',
        eqDate: '2021-10-19 06:45:03',
        magMl: '2.8',
        eqDt: '',
        eqLt: '40.41',
        eqLn: '127.27',
        jdLoc: 'Ⅰ',
        zeroDist: '55'
      },
      geometry: { type: 'Point', coordinates: [127.27, 40.41] }
    },
    {
      type: 'Feature',
      id: 320,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 포항시 남구 동남동쪽 37km 해역',
        eqDate: '2021-10-19 22:13:31',
        magMl: '2.2',
        eqDt: '22',
        eqLt: '35.90',
        eqLn: '129.75',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [129.75, 35.9] }
    },
    {
      type: 'Feature',
      id: 321,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '충북',
        eqPt: '충북 제천시 남쪽 26km 지역',
        eqDate: '2021-10-22 06:51:48',
        magMl: '2.4',
        eqDt: '14',
        eqLt: '36.90',
        eqLn: '128.22',
        jdLoc: 'Ⅲ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [128.22, 36.9] }
    },
    {
      type: 'Feature',
      id: 322,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 포항시 북구 북쪽 12km 지역',
        eqDate: '2021-11-12 01:31:05',
        magMl: '2.1',
        eqDt: '8',
        eqLt: '36.15',
        eqLn: '129.38',
        jdLoc: 'Ⅱ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.38, 36.15] }
    },
    {
      type: 'Feature',
      id: 323,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 북서쪽 54km 해역',
        eqDate: '2021-11-16 11:57:58',
        magMl: '2.1',
        eqDt: '18',
        eqLt: '35.06',
        eqLn: '125.06',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [125.06, 35.06] }
    },
    {
      type: 'Feature',
      id: 324,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 진도군 서쪽 33km 해역',
        eqDate: '2021-11-16 20:53:24',
        magMl: '2.1',
        eqDt: '8',
        eqLt: '34.50',
        eqLn: '125.90',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [125.9, 34.5] }
    },
    {
      type: 'Feature',
      id: 325,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '울산 동구 동북동쪽 35km 해역',
        eqDate: '2021-11-17 01:39:11',
        magMl: '2.9',
        eqDt: '19',
        eqLt: '35.66',
        eqLn: '129.75',
        jdLoc: 'Ⅰ',
        zeroDist: '61'
      },
      geometry: { type: 'Point', coordinates: [129.75, 35.66] }
    },
    {
      type: 'Feature',
      id: 326,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 남남서쪽 109km 해역',
        eqDate: '2021-11-19 02:35:08',
        magMl: '2.2',
        eqDt: '11',
        eqLt: '33.85',
        eqLn: '124.77',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [124.77, 33.85] }
    },
    {
      type: 'Feature',
      id: 327,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '전북',
        eqPt: '전북 순창군 북북서쪽 12km 지역',
        eqDate: '2021-11-22 05:19:31',
        magMl: '2.1',
        eqDt: '7',
        eqLt: '35.48',
        eqLn: '127.11',
        jdLoc: 'Ⅱ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [127.11, 35.48] }
    },
    {
      type: 'Feature',
      id: 328,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '인천 옹진군 백령도 서북서쪽 16km 해역',
        eqDate: '2021-12-04 12:33:22',
        magMl: '2.3',
        eqDt: '20',
        eqLt: '38.02',
        eqLn: '124.52',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [124.52, 38.02] }
    },
    {
      type: 'Feature',
      id: 329,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 남쪽 91km 해역',
        eqDate: '2021-12-09 10:57:47',
        magMl: '2.6',
        eqDt: '17',
        eqLt: '33.85',
        eqLn: '125.51',
        jdLoc: 'Ⅰ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [125.51, 33.85] }
    },
    {
      type: 'Feature',
      id: 330,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '제주 서귀포시 서남서쪽 41km 해역',
        eqDate: '2021-12-14 17:19:14',
        magMl: '4.9',
        eqDt: '17',
        eqLt: '33.09',
        eqLn: '126.16',
        jdLoc: 'Ⅴ',
        zeroDist: '693'
      },
      geometry: { type: 'Point', coordinates: [126.16, 33.09] }
    },
    {
      type: 'Feature',
      id: 331,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '제주 서귀포시 서남서쪽 40km 해역',
        eqDate: '2021-12-15 15:06:47',
        magMl: '2.8',
        eqDt: '14',
        eqLt: '33.14',
        eqLn: '126.15',
        jdLoc: 'Ⅱ',
        zeroDist: '55'
      },
      geometry: { type: 'Point', coordinates: [126.15, 33.14] }
    },
    {
      type: 'Feature',
      id: 332,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경남',
        eqPt: '경남 거창군 북서쪽 15km 지역',
        eqDate: '2021-12-15 22:02:14',
        magMl: '2.3',
        eqDt: '10',
        eqLt: '35.79',
        eqLn: '127.80',
        jdLoc: 'Ⅳ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [127.8, 35.79] }
    },
    {
      type: 'Feature',
      id: 333,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '제주 서귀포시 서남서쪽 38km 해역',
        eqDate: '2021-12-17 06:22:10',
        magMl: '3.2',
        eqDt: '18',
        eqLt: '33.12',
        eqLn: '126.18',
        jdLoc: 'Ⅲ',
        zeroDist: '84'
      },
      geometry: { type: 'Point', coordinates: [126.18, 33.12] }
    },
    {
      type: 'Feature',
      id: 334,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 41km 지역',
        eqDate: '2021-12-30 14:21:35',
        magMl: '2.3',
        eqDt: '19',
        eqLt: '41.31',
        eqLn: '129.21',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [129.21, 41.31] }
    },
    {
      type: 'Feature',
      id: 335,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 41km 지역',
        eqDate: '2021-12-30 17:54:56',
        magMl: '2.2',
        eqDt: '14',
        eqLt: '41.30',
        eqLn: '129.17',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [129.17, 41.3] }
    },
    {
      type: 'Feature',
      id: 336,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '전남 고흥군 남쪽 26km 해역',
        eqDate: '2022-01-28 06:46:53',
        magMl: '2.3',
        eqDt: '9',
        eqLt: '34.37',
        eqLn: '127.30',
        jdLoc: 'Ⅱ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [127.3, 34.37] }
    },
    {
      type: 'Feature',
      id: 337,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 청도군 동북동쪽 24km 지역',
        eqDate: '2022-02-02 12:04:55',
        magMl: '2.4',
        eqDt: '19',
        eqLt: '35.72',
        eqLn: '128.99',
        jdLoc: 'Ⅲ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [128.99, 35.72] }
    },
    {
      type: 'Feature',
      id: 338,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 삼척시 동쪽 31km 해역',
        eqDate: '2022-02-04 02:47:44',
        magMl: '2.3',
        eqDt: '16',
        eqLt: '37.41',
        eqLn: '129.51',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [129.51, 37.41] }
    },
    {
      type: 'Feature',
      id: 339,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 북서쪽 55km 해역',
        eqDate: '2022-02-08 15:57:13',
        magMl: '2.9',
        eqDt: '16',
        eqLt: '35.07',
        eqLn: '125.06',
        jdLoc: 'Ⅰ',
        zeroDist: '61'
      },
      geometry: { type: 'Point', coordinates: [125.06, 35.07] }
    },
    {
      type: 'Feature',
      id: 340,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '전남',
        eqPt: '전남 영광군 동북동쪽 7km 지역',
        eqDate: '2022-02-10 17:20:34',
        magMl: '2.2',
        eqDt: '19',
        eqLt: '35.29',
        eqLn: '126.59',
        jdLoc: 'Ⅲ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [126.59, 35.29] }
    },
    {
      type: 'Feature',
      id: 341,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 40km 지역',
        eqDate: '2022-02-11 10:35:26',
        magMl: '3.1',
        eqDt: '17',
        eqLt: '41.30',
        eqLn: '129.21',
        jdLoc: 'Ⅰ',
        zeroDist: '75'
      },
      geometry: { type: 'Point', coordinates: [129.21, 41.3] }
    },
    {
      type: 'Feature',
      id: 342,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 36km 지역',
        eqDate: '2022-02-14 14:33:22',
        magMl: '2.3',
        eqDt: '22',
        eqLt: '41.27',
        eqLn: '129.24',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [129.24, 41.27] }
    },
    {
      type: 'Feature',
      id: 343,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북쪽 37km 지역',
        eqDate: '2022-02-14 19:47:27',
        magMl: '2.3',
        eqDt: '18',
        eqLt: '41.28',
        eqLn: '129.26',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [129.26, 41.28] }
    },
    {
      type: 'Feature',
      id: 344,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 38km 지역',
        eqDate: '2022-02-15 06:52:28',
        magMl: '2.5',
        eqDt: '29',
        eqLt: '41.29',
        eqLn: '129.23',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [129.23, 41.29] }
    },
    {
      type: 'Feature',
      id: 345,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '전남 여수시 거문도 동쪽 25km 해역',
        eqDate: '2022-02-24 12:07:10',
        magMl: '2.2',
        eqDt: '21',
        eqLt: '34.00',
        eqLn: '127.59',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [127.59, 34] }
    },
    {
      type: 'Feature',
      id: 346,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북쪽 33km 지역',
        eqDate: '2022-02-28 21:31:28',
        magMl: '2.1',
        eqDt: '24',
        eqLt: '41.25',
        eqLn: '129.28',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.28, 41.25] }
    },
    {
      type: 'Feature',
      id: 347,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 33km 지역',
        eqDate: '2022-03-04 02:15:37',
        magMl: '2.1',
        eqDt: '29',
        eqLt: '41.24',
        eqLn: '129.22',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.22, 41.24] }
    },
    {
      type: 'Feature',
      id: 348,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '충남',
        eqPt: '충남 공주시 남쪽 6km 지역',
        eqDate: '2022-03-05 22:16:21',
        magMl: '2.4',
        eqDt: '12',
        eqLt: '36.39',
        eqLn: '127.13',
        jdLoc: 'Ⅲ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [127.13, 36.39] }
    },
    {
      type: 'Feature',
      id: 349,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 삼척시 동남동쪽 37km 해역',
        eqDate: '2022-03-06 18:40:32',
        magMl: '2.5',
        eqDt: '18',
        eqLt: '37.31',
        eqLn: '129.54',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [129.54, 37.31] }
    },
    {
      type: 'Feature',
      id: 350,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 평안남도 성천 북쪽 20km 지역',
        eqDate: '2022-03-15 00:22:27',
        magMl: '2.0',
        eqDt: '12',
        eqLt: '39.42',
        eqLn: '126.19',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [126.19, 39.42] }
    },
    {
      type: 'Feature',
      id: 351,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 남남서쪽 113km 해역',
        eqDate: '2022-03-16 18:18:18',
        magMl: '2.3',
        eqDt: '',
        eqLt: '33.70',
        eqLn: '125.06',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [125.06, 33.7] }
    },
    {
      type: 'Feature',
      id: 352,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 경주시 남남동쪽 22km 지역',
        eqDate: '2022-04-03 14:19:19',
        magMl: '2.0',
        eqDt: '11',
        eqLt: '35.69',
        eqLn: '129.35',
        jdLoc: 'Ⅲ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [129.35, 35.69] }
    },
    {
      type: 'Feature',
      id: 353,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 상주시 북북서쪽 16km 지역',
        eqDate: '2022-04-09 10:55:42',
        magMl: '2.2',
        eqDt: '19',
        eqLt: '36.54',
        eqLn: '128.08',
        jdLoc: 'Ⅱ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [128.08, 36.54] }
    },
    {
      type: 'Feature',
      id: 354,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 영덕군 동쪽 23km 해역',
        eqDate: '2022-04-10 02:45:46',
        magMl: '3.4',
        eqDt: '18',
        eqLt: '36.45',
        eqLn: '129.62',
        jdLoc: 'Ⅲ',
        zeroDist: '106'
      },
      geometry: { type: 'Point', coordinates: [129.62, 36.45] }
    },
    {
      type: 'Feature',
      id: 355,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 울진군 남남동쪽 20km 해역',
        eqDate: '2022-04-12 14:56:28',
        magMl: '2.4',
        eqDt: '14',
        eqLt: '36.82',
        eqLn: '129.47',
        jdLoc: 'Ⅲ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [129.47, 36.82] }
    },
    {
      type: 'Feature',
      id: 356,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '전남',
        eqPt: '전남 고흥군 북쪽 16km 지역',
        eqDate: '2022-04-16 02:08:34',
        magMl: '2.6',
        eqDt: '18',
        eqLt: '34.75',
        eqLn: '127.26',
        jdLoc: 'Ⅲ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [127.26, 34.75] }
    },
    {
      type: 'Feature',
      id: 357,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경남',
        eqPt: '경남 창원시 마산회원구 동쪽 2km 지역',
        eqDate: '2022-04-17 23:33:29',
        magMl: '2.3',
        eqDt: '9',
        eqLt: '35.22',
        eqLn: '128.60',
        jdLoc: 'Ⅲ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [128.6, 35.22] }
    },
    {
      type: 'Feature',
      id: 358,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '인천',
        eqPt: '인천 옹진군 서남서쪽 52km 지역',
        eqDate: '2022-04-18 14:22:38',
        magMl: '2.3',
        eqDt: '11',
        eqLt: '37.26',
        eqLn: '126.10',
        jdLoc: 'Ⅲ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [126.1, 37.26] }
    },
    {
      type: 'Feature',
      id: 359,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 송림 북동쪽 18km 지역',
        eqDate: '2022-04-21 02:34:32',
        magMl: '2.3',
        eqDt: '',
        eqLt: '38.85',
        eqLn: '125.78',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [125.78, 38.85] }
    },
    {
      type: 'Feature',
      id: 360,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 강원 원산 남남서쪽 24km 지역',
        eqDate: '2022-04-30 09:20:21',
        magMl: '2.3',
        eqDt: '10',
        eqLt: '38.98',
        eqLn: '127.29',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [127.29, 38.98] }
    },
    {
      type: 'Feature',
      id: 361,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '제주 서귀포시 동쪽 62km 해역',
        eqDate: '2022-05-13 06:50:56',
        magMl: '2.3',
        eqDt: '21',
        eqLt: '33.34',
        eqLn: '127.22',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [127.22, 33.34] }
    },
    {
      type: 'Feature',
      id: 362,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 울릉군 울릉도 북쪽 71km 해역',
        eqDate: '2022-06-02 20:48:21',
        magMl: '2.6',
        eqDt: '26',
        eqLt: '38.12',
        eqLn: '130.98',
        jdLoc: 'Ⅰ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [130.98, 38.12] }
    },
    {
      type: 'Feature',
      id: 363,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 포항시 남구 동남동쪽 33km 해역',
        eqDate: '2022-06-29 15:26:06',
        magMl: '2.3',
        eqDt: '21',
        eqLt: '35.89',
        eqLn: '129.70',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [129.7, 35.89] }
    },
    {
      type: 'Feature',
      id: 364,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 포항시 남구 동남동쪽 35km 해역',
        eqDate: '2022-06-30 02:55:34',
        magMl: '2.1',
        eqDt: '18',
        eqLt: '35.90',
        eqLn: '129.73',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.73, 35.9] }
    },
    {
      type: 'Feature',
      id: 365,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 봉화군 동북동쪽 26km 지역',
        eqDate: '2022-06-30 22:51:52',
        magMl: '2.3',
        eqDt: '6',
        eqLt: '37.02',
        eqLn: '128.98',
        jdLoc: 'Ⅳ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [128.98, 37.02] }
    },
    {
      type: 'Feature',
      id: 366,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '전남 여수시 거문도 남쪽 81km 해역',
        eqDate: '2022-07-03 07:54:23',
        magMl: '2.6',
        eqDt: '13',
        eqLt: '33.31',
        eqLn: '127.45',
        jdLoc: 'Ⅰ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [127.45, 33.31] }
    },
    {
      type: 'Feature',
      id: 367,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '제주 서귀포시 동쪽 102km 해역',
        eqDate: '2022-07-12 00:41:25',
        magMl: '2.1',
        eqDt: '10',
        eqLt: '33.14',
        eqLn: '127.65',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [127.65, 33.14] }
    },
    {
      type: 'Feature',
      id: 368,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '제주 서귀포시 동쪽 103km 해역',
        eqDate: '2022-07-12 00:44:03',
        magMl: '2.2',
        eqDt: '10',
        eqLt: '33.14',
        eqLn: '127.66',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [127.66, 33.14] }
    },
    {
      type: 'Feature',
      id: 369,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '제주 서귀포시 동쪽 102km 해역',
        eqDate: '2022-07-12 09:56:02',
        magMl: '2.3',
        eqDt: '6',
        eqLt: '33.12',
        eqLn: '127.64',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [127.64, 33.12] }
    },
    {
      type: 'Feature',
      id: 370,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '제주 서귀포시 동쪽 104km 해역',
        eqDate: '2022-07-12 10:09:03',
        magMl: '2.7',
        eqDt: '7',
        eqLt: '33.11',
        eqLn: '127.66',
        jdLoc: 'Ⅰ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [127.66, 33.11] }
    },
    {
      type: 'Feature',
      id: 371,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '제주 서귀포시 동쪽 103km 해역',
        eqDate: '2022-07-12 10:38:30',
        magMl: '2.6',
        eqDt: '6',
        eqLt: '33.13',
        eqLn: '127.66',
        jdLoc: 'Ⅰ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [127.66, 33.13] }
    },
    {
      type: 'Feature',
      id: 372,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '전남 여수시 거문도 동남동쪽 85km 해역',
        eqDate: '2022-07-16 13:53:36',
        magMl: '2.1',
        eqDt: '14',
        eqLt: '33.64',
        eqLn: '128.11',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [128.11, 33.64] }
    },
    {
      type: 'Feature',
      id: 373,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '충남',
        eqPt: '충남 부여군 서북서쪽 4km 지역',
        eqDate: '2022-07-20 16:19:50',
        magMl: '2.0',
        eqDt: '17',
        eqLt: '36.29',
        eqLn: '126.87',
        jdLoc: 'Ⅱ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [126.87, 36.29] }
    },
    {
      type: 'Feature',
      id: 374,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 경주시 남남서쪽 8km 지역',
        eqDate: '2022-07-21 04:46:57',
        magMl: '2.3',
        eqDt: '13',
        eqLt: '35.79',
        eqLn: '129.20',
        jdLoc: 'Ⅲ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [129.2, 35.79] }
    },
    {
      type: 'Feature',
      id: 375,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '충남',
        eqPt: '충남 태안군 북서쪽 7km 지역',
        eqDate: '2022-07-21 18:38:09',
        magMl: '2.3',
        eqDt: '10',
        eqLt: '36.80',
        eqLn: '126.25',
        jdLoc: 'Ⅲ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [126.25, 36.8] }
    },
    {
      type: 'Feature',
      id: 376,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 사리원 서남서쪽 13km 지역',
        eqDate: '2022-07-23 06:27:14',
        magMl: '2.5',
        eqDt: '',
        eqLt: '38.45',
        eqLn: '125.63',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [125.63, 38.45] }
    },
    {
      type: 'Feature',
      id: 377,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 사리원 서남서쪽 14km 지역',
        eqDate: '2022-07-23 16:21:48',
        magMl: '2.4',
        eqDt: '',
        eqLt: '38.44',
        eqLn: '125.63',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [125.63, 38.44] }
    },
    {
      type: 'Feature',
      id: 378,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경남',
        eqPt: '경남 의령군 북북서쪽 8km 지역',
        eqDate: '2022-07-29 05:36:56',
        magMl: '2.6',
        eqDt: '13',
        eqLt: '35.39',
        eqLn: '128.24',
        jdLoc: 'Ⅳ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [128.24, 35.39] }
    },
    {
      type: 'Feature',
      id: 379,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해남도 안악 남동쪽 13km 지역',
        eqDate: '2022-07-31 22:05:06',
        magMl: '2.6',
        eqDt: '',
        eqLt: '38.44',
        eqLn: '125.62',
        jdLoc: 'Ⅰ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [125.62, 38.44] }
    },
    {
      type: 'Feature',
      id: 380,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '전남 여수시 거문도 남남동쪽 104km 해역',
        eqDate: '2022-08-03 07:55:25',
        magMl: '3.1',
        eqDt: '8',
        eqLt: '33.14',
        eqLn: '127.67',
        jdLoc: 'Ⅰ',
        zeroDist: '75'
      },
      geometry: { type: 'Point', coordinates: [127.67, 33.14] }
    },
    {
      type: 'Feature',
      id: 381,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '인천 옹진군 백령도 남남서쪽 62km 해역',
        eqDate: '2022-08-12 19:37:47',
        magMl: '2.7',
        eqDt: '6',
        eqLt: '37.41',
        eqLn: '124.53',
        jdLoc: 'Ⅰ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [124.53, 37.41] }
    },
    {
      type: 'Feature',
      id: 382,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 연산 서남서쪽 28km 지역',
        eqDate: '2022-08-16 20:27:25',
        magMl: '2.4',
        eqDt: '',
        eqLt: '38.80',
        eqLn: '125.95',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [125.95, 38.8] }
    },
    {
      type: 'Feature',
      id: 383,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 영덕군 동북동쪽 24km 해역',
        eqDate: '2022-08-24 18:31:24',
        magMl: '2.1',
        eqDt: '20',
        eqLt: '36.47',
        eqLn: '129.62',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.62, 36.47] }
    },
    {
      type: 'Feature',
      id: 384,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '인천 옹진군 연평도 남남서쪽 60km 해역',
        eqDate: '2022-08-28 01:54:23',
        magMl: '2.4',
        eqDt: '12',
        eqLt: '37.17',
        eqLn: '125.42',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [125.42, 37.17] }
    },
    {
      type: 'Feature',
      id: 385,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 북북서쪽 58km 해역',
        eqDate: '2022-09-01 08:33:53',
        magMl: '2.5',
        eqDt: '',
        eqLt: '35.11',
        eqLn: '125.07',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [125.07, 35.11] }
    },
    {
      type: 'Feature',
      id: 386,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 영덕군 동쪽 47km 해역',
        eqDate: '2022-09-03 00:44:46',
        magMl: '2.0',
        eqDt: '18',
        eqLt: '36.39',
        eqLn: '129.89',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [129.89, 36.39] }
    },
    {
      type: 'Feature',
      id: 387,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 강원 원산 남남서쪽 26km 지역',
        eqDate: '2022-09-10 14:59:48',
        magMl: '3.4',
        eqDt: '7',
        eqLt: '38.95',
        eqLn: '127.29',
        jdLoc: 'Ⅱ',
        zeroDist: '106'
      },
      geometry: { type: 'Point', coordinates: [127.29, 38.95] }
    },
    {
      type: 'Feature',
      id: 388,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 영덕군 동북동쪽 24km 해역',
        eqDate: '2022-09-11 16:28:35',
        magMl: '2.4',
        eqDt: '13',
        eqLt: '36.46',
        eqLn: '129.63',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [129.63, 36.46] }
    },
    {
      type: 'Feature',
      id: 389,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 자강도 용림 북북동쪽 7km 지역',
        eqDate: '2022-09-22 20:28:37',
        magMl: '2.9',
        eqDt: '10',
        eqLt: '40.57',
        eqLn: '126.71',
        jdLoc: '',
        zeroDist: '61'
      },
      geometry: { type: 'Point', coordinates: [126.71, 40.57] }
    },
    {
      type: 'Feature',
      id: 390,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '충남 태안군 서격렬비도 남서쪽 134km 해역',
        eqDate: '2022-09-23 07:40:30',
        magMl: '2.3',
        eqDt: '',
        eqLt: '35.69',
        eqLn: '124.59',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [124.59, 35.69] }
    },
    {
      type: 'Feature',
      id: 391,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '충남',
        eqPt: '충남 천안시 서북구 북북서쪽 7km 지역',
        eqDate: '2022-10-01 04:37:53',
        magMl: '2.7',
        eqDt: '14',
        eqLt: '36.94',
        eqLn: '127.13',
        jdLoc: 'Ⅲ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [127.13, 36.94] }
    },
    {
      type: 'Feature',
      id: 392,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 42km 지역',
        eqDate: '2022-10-08 02:54:49',
        magMl: '2.5',
        eqDt: '23',
        eqLt: '41.30',
        eqLn: '129.13',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [129.13, 41.3] }
    },
    {
      type: 'Feature',
      id: 393,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 39km 지역',
        eqDate: '2022-10-08 14:33:34',
        magMl: '2.4',
        eqDt: '14',
        eqLt: '41.29',
        eqLn: '129.21',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [129.21, 41.29] }
    },
    {
      type: 'Feature',
      id: 394,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '충북',
        eqPt: '충북 괴산군 북동쪽 11km 지역',
        eqDate: '2022-10-29 08:27:33',
        magMl: '3.5',
        eqDt: '13',
        eqLt: '36.88',
        eqLn: '127.88',
        jdLoc: 'Ⅴ',
        zeroDist: '119'
      },
      geometry: { type: 'Point', coordinates: [127.88, 36.88] }
    },
    {
      type: 'Feature',
      id: 395,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '충북',
        eqPt: '충북 괴산군 북동쪽 11km 지역',
        eqDate: '2022-10-29 08:27:49',
        magMl: '4.1',
        eqDt: '12',
        eqLt: '36.88',
        eqLn: '127.88',
        jdLoc: 'Ⅴ',
        zeroDist: '248'
      },
      geometry: { type: 'Point', coordinates: [127.88, 36.88] }
    },
    {
      type: 'Feature',
      id: 396,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '충북',
        eqPt: '충북 괴산군 북동쪽 11km 지역',
        eqDate: '2022-11-01 02:27:52',
        magMl: '2.9',
        eqDt: '14',
        eqLt: '36.88',
        eqLn: '127.88',
        jdLoc: 'Ⅴ',
        zeroDist: '61'
      },
      geometry: { type: 'Point', coordinates: [127.88, 36.88] }
    },
    {
      type: 'Feature',
      id: 397,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 북북서쪽 101km 해역',
        eqDate: '2022-11-03 11:36:00',
        magMl: '2.1',
        eqDt: '10',
        eqLt: '35.47',
        eqLn: '124.90',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [124.9, 35.47] }
    },
    {
      type: 'Feature',
      id: 398,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '충남 서산시 북북서쪽 22km 해역',
        eqDate: '2022-11-05 13:21:22',
        magMl: '2.5',
        eqDt: '11',
        eqLt: '36.97',
        eqLn: '126.38',
        jdLoc: 'Ⅲ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [126.38, 36.97] }
    },
    {
      type: 'Feature',
      id: 399,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '전남 여수시 거문도 남남동쪽 92km 해역',
        eqDate: '2022-11-08 00:45:45',
        magMl: '2.1',
        eqDt: '8',
        eqLt: '33.30',
        eqLn: '127.78',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [127.78, 33.3] }
    },
    {
      type: 'Feature',
      id: 400,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 북서쪽 55km 해역',
        eqDate: '2022-11-13 12:17:41',
        magMl: '2.1',
        eqDt: '17',
        eqLt: '35.07',
        eqLn: '125.07',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [125.07, 35.07] }
    },
    {
      type: 'Feature',
      id: 401,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 북서쪽 136km 해역',
        eqDate: '2022-11-18 03:12:14',
        magMl: '2.2',
        eqDt: '6',
        eqLt: '35.52',
        eqLn: '124.35',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [124.35, 35.52] }
    },
    {
      type: 'Feature',
      id: 402,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 남남서쪽 81km 해역',
        eqDate: '2022-11-20 01:01:17',
        magMl: '3.3',
        eqDt: '20',
        eqLt: '33.96',
        eqLn: '125.22',
        jdLoc: 'Ⅱ',
        zeroDist: '94'
      },
      geometry: { type: 'Point', coordinates: [125.22, 33.96] }
    },
    {
      type: 'Feature',
      id: 403,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 38km 지역',
        eqDate: '2022-11-20 15:21:41',
        magMl: '2.0',
        eqDt: '11',
        eqLt: '41.29',
        eqLn: '129.22',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [129.22, 41.29] }
    },
    {
      type: 'Feature',
      id: 404,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 사리원 남서쪽 14km 지역',
        eqDate: '2022-11-29 05:31:36',
        magMl: '2.1',
        eqDt: '',
        eqLt: '38.43',
        eqLn: '125.63',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [125.63, 38.43] }
    },
    {
      type: 'Feature',
      id: 405,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 김천시 동북동쪽 14km 지역',
        eqDate: '2022-12-01 15:17:58',
        magMl: '3.2',
        eqDt: '14',
        eqLt: '36.17',
        eqLn: '128.27',
        jdLoc: 'Ⅲ',
        zeroDist: '84'
      },
      geometry: { type: 'Point', coordinates: [128.27, 36.17] }
    },
    {
      type: 'Feature',
      id: 406,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '울산 북구 동북동쪽 45km 해역',
        eqDate: '2022-12-03 13:22:58',
        magMl: '2.9',
        eqDt: '21',
        eqLt: '35.77',
        eqLn: '129.80',
        jdLoc: 'Ⅰ',
        zeroDist: '61'
      },
      geometry: { type: 'Point', coordinates: [129.8, 35.77] }
    },
    {
      type: 'Feature',
      id: 407,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 39km 지역',
        eqDate: '2022-12-16 12:07:11',
        magMl: '2.1',
        eqDt: '26',
        eqLt: '41.30',
        eqLn: '129.22',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.22, 41.3] }
    },
    {
      type: 'Feature',
      id: 408,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 북북서쪽 61km 해역',
        eqDate: '2022-12-23 19:43:17',
        magMl: '2.3',
        eqDt: '',
        eqLt: '35.14',
        eqLn: '125.08',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [125.08, 35.14] }
    },
    {
      type: 'Feature',
      id: 409,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 안동시 남서쪽 10km 지역',
        eqDate: '2022-12-25 19:31:07',
        magMl: '2.6',
        eqDt: '14',
        eqLt: '36.51',
        eqLn: '128.65',
        jdLoc: 'Ⅲ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [128.65, 36.51] }
    },
    {
      type: 'Feature',
      id: 410,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '제주 서귀포시 동북동쪽 64km 해역',
        eqDate: '2023-01-05 00:28:05',
        magMl: '2.4',
        eqDt: '24',
        eqLt: '33.38',
        eqLn: '127.23',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [127.23, 33.38] }
    },
    {
      type: 'Feature',
      id: 411,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 서남서쪽 50km 해역',
        eqDate: '2023-01-07 05:12:17',
        magMl: '2.4',
        eqDt: '14',
        eqLt: '34.52',
        eqLn: '124.90',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [124.9, 34.52] }
    },
    {
      type: 'Feature',
      id: 412,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 서남서쪽 45km 해역',
        eqDate: '2023-01-07 05:16:03',
        magMl: '2.2',
        eqDt: '14',
        eqLt: '34.56',
        eqLn: '124.95',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [124.95, 34.56] }
    },
    {
      type: 'Feature',
      id: 413,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '인천 강화군 서쪽 25km 해역',
        eqDate: '2023-01-09 01:28:15',
        magMl: '3.7',
        eqDt: '19',
        eqLt: '37.74',
        eqLn: '126.20',
        jdLoc: 'Ⅳ',
        zeroDist: '151'
      },
      geometry: { type: 'Point', coordinates: [126.2, 37.74] }
    },
    {
      type: 'Feature',
      id: 414,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 북서쪽 59km 해역',
        eqDate: '2023-01-13 12:23:51',
        magMl: '2.1',
        eqDt: '',
        eqLt: '35.11',
        eqLn: '125.06',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [125.06, 35.11] }
    },
    {
      type: 'Feature',
      id: 415,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 북서쪽 56km 해역',
        eqDate: '2023-01-14 05:56:44',
        magMl: '2.1',
        eqDt: '11',
        eqLt: '35.08',
        eqLn: '125.07',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [125.07, 35.08] }
    },
    {
      type: 'Feature',
      id: 416,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 영덕군 동북동쪽 24km 해역',
        eqDate: '2023-01-21 13:37:39',
        magMl: '2.5',
        eqDt: '15',
        eqLt: '36.46',
        eqLn: '129.63',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [129.63, 36.46] }
    },
    {
      type: 'Feature',
      id: 417,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '북한 황해남도 해주 남쪽 16km 해역',
        eqDate: '2023-01-23 07:33:45',
        magMl: '2.9',
        eqDt: '15',
        eqLt: '37.90',
        eqLn: '125.71',
        jdLoc: 'Ⅱ',
        zeroDist: '61'
      },
      geometry: { type: 'Point', coordinates: [125.71, 37.9] }
    },
    {
      type: 'Feature',
      id: 418,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북쪽 38km 지역',
        eqDate: '2023-02-09 01:10:04',
        magMl: '2.1',
        eqDt: '10',
        eqLt: '41.29',
        eqLn: '129.27',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.27, 41.29] }
    },
    {
      type: 'Feature',
      id: 419,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '충북',
        eqPt: '충북 보은군 동북동쪽 11km 지역',
        eqDate: '2023-02-11 08:22:01',
        magMl: '2.3',
        eqDt: '13',
        eqLt: '36.52',
        eqLn: '127.85',
        jdLoc: 'Ⅲ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [127.85, 36.52] }
    },
    {
      type: 'Feature',
      id: 420,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '전남',
        eqPt: '전남 고흥군 북동쪽 11km 지역',
        eqDate: '2023-02-19 07:29:52',
        magMl: '2.5',
        eqDt: '8',
        eqLt: '34.67',
        eqLn: '127.36',
        jdLoc: 'Ⅳ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [127.36, 34.67] }
    },
    {
      type: 'Feature',
      id: 421,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경남',
        eqPt: '경남 진주시 서북서쪽 16km 지역',
        eqDate: '2023-03-03 11:26:54',
        magMl: '3.0',
        eqDt: '8',
        eqLt: '35.21',
        eqLn: '127.94',
        jdLoc: 'Ⅳ',
        zeroDist: '68'
      },
      geometry: { type: 'Point', coordinates: [127.94, 35.21] }
    },
    {
      type: 'Feature',
      id: 422,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '충남 태안군 서격렬비도 북북동쪽 21km 해역',
        eqDate: '2023-03-07 15:13:32',
        magMl: '2.1',
        eqDt: '16',
        eqLt: '36.79',
        eqLn: '125.63',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [125.63, 36.79] }
    },
    {
      type: 'Feature',
      id: 423,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 42km 지역',
        eqDate: '2023-03-10 00:13:23',
        magMl: '2.1',
        eqDt: '9',
        eqLt: '41.27',
        eqLn: '129.06',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.06, 41.27] }
    },
    {
      type: 'Feature',
      id: 424,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 38km 지역',
        eqDate: '2023-03-14 02:18:42',
        magMl: '2.4',
        eqDt: '20',
        eqLt: '41.27',
        eqLn: '129.14',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [129.14, 41.27] }
    },
    {
      type: 'Feature',
      id: 425,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 서북서쪽 99km 해역',
        eqDate: '2023-03-22 02:09:17',
        magMl: '2.0',
        eqDt: '24',
        eqLt: '35.03',
        eqLn: '124.43',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [124.43, 35.03] }
    },
    {
      type: 'Feature',
      id: 426,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '제주 제주시 서쪽 81km 해역',
        eqDate: '2023-03-23 08:35:53',
        magMl: '2.0',
        eqDt: '16',
        eqLt: '33.56',
        eqLn: '125.66',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [125.66, 33.56] }
    },
    {
      type: 'Feature',
      id: 427,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '인천 옹진군 연평도 남서쪽 65km 해역',
        eqDate: '2023-03-25 18:35:48',
        magMl: '2.4',
        eqDt: '',
        eqLt: '37.19',
        eqLn: '125.27',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [125.27, 37.19] }
    },
    {
      type: 'Feature',
      id: 428,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전북 군산시 어청도 남서쪽 86km 해역',
        eqDate: '2023-03-30 05:47:56',
        magMl: '2.9',
        eqDt: '22',
        eqLt: '35.64',
        eqLn: '125.22',
        jdLoc: 'Ⅰ',
        zeroDist: '61'
      },
      geometry: { type: 'Point', coordinates: [125.22, 35.64] }
    },
    {
      type: 'Feature',
      id: 429,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '북한 남포 서북서쪽 76km 해역',
        eqDate: '2023-04-01 23:26:21',
        magMl: '2.3',
        eqDt: '',
        eqLt: '38.96',
        eqLn: '124.61',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [124.61, 38.96] }
    },
    {
      type: 'Feature',
      id: 430,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해남도 해주 남서쪽 15km 지역',
        eqDate: '2023-04-06 00:51:49',
        magMl: '2.7',
        eqDt: '12',
        eqLt: '37.94',
        eqLn: '125.60',
        jdLoc: 'Ⅱ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [125.6, 37.94] }
    },
    {
      type: 'Feature',
      id: 431,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경기',
        eqPt: '경기 연천군 북북동쪽 4km 지역',
        eqDate: '2023-04-12 22:45:46',
        magMl: '2.1',
        eqDt: '7',
        eqLt: '38.13',
        eqLn: '127.09',
        jdLoc: 'Ⅱ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [127.09, 38.13] }
    },
    {
      type: 'Feature',
      id: 432,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 북서쪽 59km 해역',
        eqDate: '2023-04-13 05:27:51',
        magMl: '2.1',
        eqDt: '',
        eqLt: '35.11',
        eqLn: '125.05',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [125.05, 35.11] }
    },
    {
      type: 'Feature',
      id: 433,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '제주 서귀포시 서쪽 108km 해역',
        eqDate: '2023-04-19 00:45:07',
        magMl: '2.6',
        eqDt: '14',
        eqLt: '33.09',
        eqLn: '125.42',
        jdLoc: 'Ⅰ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [125.42, 33.09] }
    },
    {
      type: 'Feature',
      id: 434,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 동해시 북동쪽 52km 해역',
        eqDate: '2023-04-23 21:06:39',
        magMl: '2.7',
        eqDt: '',
        eqLt: '37.90',
        eqLn: '129.61',
        jdLoc: 'Ⅰ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [129.61, 37.9] }
    },
    {
      type: 'Feature',
      id: 435,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 동해시 북동쪽 48km 해역',
        eqDate: '2023-04-24 13:02:10',
        magMl: '2.9',
        eqDt: '31',
        eqLt: '37.85',
        eqLn: '129.47',
        jdLoc: 'Ⅰ',
        zeroDist: '61'
      },
      geometry: { type: 'Point', coordinates: [129.47, 37.85] }
    },
    {
      type: 'Feature',
      id: 436,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 동해시 북동쪽 53km 해역',
        eqDate: '2023-04-24 21:41:25',
        magMl: '2.1',
        eqDt: '27',
        eqLt: '37.87',
        eqLn: '129.53',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.53, 37.87] }
    },
    {
      type: 'Feature',
      id: 437,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 동해시 북동쪽 58km 해역',
        eqDate: '2023-04-24 21:50:58',
        magMl: '2.4',
        eqDt: '',
        eqLt: '37.90',
        eqLn: '129.58',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [129.58, 37.9] }
    },
    {
      type: 'Feature',
      id: 438,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 동해시 북동쪽 54km 해역',
        eqDate: '2023-04-25 05:03:39',
        magMl: '2.3',
        eqDt: '25',
        eqLt: '37.88',
        eqLn: '129.54',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [129.54, 37.88] }
    },
    {
      type: 'Feature',
      id: 439,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 동해시 북동쪽 52km 해역',
        eqDate: '2023-04-25 12:02:50',
        magMl: '3.1',
        eqDt: '28',
        eqLt: '37.87',
        eqLn: '129.52',
        jdLoc: 'Ⅱ',
        zeroDist: '75'
      },
      geometry: { type: 'Point', coordinates: [129.52, 37.87] }
    },
    {
      type: 'Feature',
      id: 440,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 문경시 북서쪽 10km 지역',
        eqDate: '2023-04-25 13:22:40',
        magMl: '2.7',
        eqDt: '12',
        eqLt: '36.66',
        eqLn: '128.12',
        jdLoc: 'Ⅲ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [128.12, 36.66] }
    },
    {
      type: 'Feature',
      id: 441,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 동해시 북동쪽 50km 해역',
        eqDate: '2023-04-25 15:55:55',
        magMl: '3.5',
        eqDt: '33',
        eqLt: '37.86',
        eqLn: '129.49',
        jdLoc: 'Ⅰ',
        zeroDist: '119'
      },
      geometry: { type: 'Point', coordinates: [129.49, 37.86] }
    },
    {
      type: 'Feature',
      id: 442,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 동해시 북동쪽 51km 해역',
        eqDate: '2023-04-26 06:51:41',
        magMl: '2.2',
        eqDt: '28',
        eqLt: '37.86',
        eqLn: '129.51',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [129.51, 37.86] }
    },
    {
      type: 'Feature',
      id: 443,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 동해시 북동쪽 52km 해역',
        eqDate: '2023-04-26 12:15:24',
        magMl: '2.0',
        eqDt: '27',
        eqLt: '37.87',
        eqLn: '129.52',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [129.52, 37.87] }
    },
    {
      type: 'Feature',
      id: 444,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 동해시 북동쪽 53km 해역',
        eqDate: '2023-04-28 23:39:24',
        magMl: '2.6',
        eqDt: '27',
        eqLt: '37.88',
        eqLn: '129.51',
        jdLoc: 'Ⅰ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [129.51, 37.88] }
    },
    {
      type: 'Feature',
      id: 445,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '충북',
        eqPt: '충북 옥천군 동쪽 16km 지역',
        eqDate: '2023-04-30 19:03:29',
        magMl: '3.1',
        eqDt: '6',
        eqLt: '36.32',
        eqLn: '127.75',
        jdLoc: 'Ⅳ',
        zeroDist: '75'
      },
      geometry: { type: 'Point', coordinates: [127.75, 36.32] }
    },
    {
      type: 'Feature',
      id: 446,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '북한 평안북도 정주 남쪽 52km 해역',
        eqDate: '2023-05-05 12:11:49',
        magMl: '2.6',
        eqDt: '',
        eqLt: '39.22',
        eqLn: '125.13',
        jdLoc: 'Ⅰ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [125.13, 39.22] }
    },
    {
      type: 'Feature',
      id: 447,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 동해시 남남동쪽 4km 해역',
        eqDate: '2023-05-08 19:51:34',
        magMl: '2.5',
        eqDt: '17',
        eqLt: '37.49',
        eqLn: '129.13',
        jdLoc: 'Ⅲ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [129.13, 37.49] }
    },
    {
      type: 'Feature',
      id: 448,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 동해시 북동쪽 52km 해역',
        eqDate: '2023-05-08 21:12:05',
        magMl: '2.1',
        eqDt: '23',
        eqLt: '37.87',
        eqLn: '129.51',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.51, 37.87] }
    },
    {
      type: 'Feature',
      id: 449,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '강원',
        eqPt: '강원 동해시 남남동쪽 4km 지역',
        eqDate: '2023-05-10 01:53:36',
        magMl: '2.5',
        eqDt: '15',
        eqLt: '37.49',
        eqLn: '129.14',
        jdLoc: 'Ⅱ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [129.14, 37.49] }
    },
    {
      type: 'Feature',
      id: 450,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경기',
        eqPt: '경기 연천군 북북동쪽 4km 지역',
        eqDate: '2023-05-10 13:29:35',
        magMl: '2.2',
        eqDt: '5',
        eqLt: '38.13',
        eqLn: '127.09',
        jdLoc: 'Ⅱ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [127.09, 38.13] }
    },
    {
      type: 'Feature',
      id: 451,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '강원',
        eqPt: '강원 동해시 남쪽 4km 지역',
        eqDate: '2023-05-10 16:32:10',
        magMl: '2.5',
        eqDt: '16',
        eqLt: '37.49',
        eqLn: '129.12',
        jdLoc: 'Ⅱ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [129.12, 37.49] }
    },
    {
      type: 'Feature',
      id: 452,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 동해시 북동쪽 51km 해역',
        eqDate: '2023-05-14 08:35:25',
        magMl: '3.1',
        eqDt: '30',
        eqLt: '37.86',
        eqLn: '129.51',
        jdLoc: 'Ⅰ',
        zeroDist: '75'
      },
      geometry: { type: 'Point', coordinates: [129.51, 37.86] }
    },
    {
      type: 'Feature',
      id: 453,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 동해시 북동쪽 52km 해역',
        eqDate: '2023-05-15 06:27:36',
        magMl: '4.5',
        eqDt: '32',
        eqLt: '37.87',
        eqLn: '129.52',
        jdLoc: 'Ⅲ',
        zeroDist: '412'
      },
      geometry: { type: 'Point', coordinates: [129.52, 37.87] }
    },
    {
      type: 'Feature',
      id: 454,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 38km 지역',
        eqDate: '2023-05-19 14:03:34',
        magMl: '2.3',
        eqDt: '15',
        eqLt: '41.28',
        eqLn: '129.17',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [129.17, 41.28] }
    },
    {
      type: 'Feature',
      id: 455,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 동해시 북동쪽 52km 해역',
        eqDate: '2023-05-22 03:39:18',
        magMl: '2.3',
        eqDt: '28',
        eqLt: '37.87',
        eqLn: '129.52',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [129.52, 37.87] }
    },
    {
      type: 'Feature',
      id: 456,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북쪽 31km 지역',
        eqDate: '2023-05-25 22:27:40',
        magMl: '2.4',
        eqDt: '28',
        eqLt: '41.23',
        eqLn: '129.35',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [129.35, 41.23] }
    },
    {
      type: 'Feature',
      id: 457,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '전북',
        eqPt: '전북 완주군 남쪽 15km 지역',
        eqDate: '2023-06-05 12:42:18',
        magMl: '2.1',
        eqDt: '5',
        eqLt: '35.77',
        eqLn: '127.18',
        jdLoc: 'Ⅲ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [127.18, 35.77] }
    },
    {
      type: 'Feature',
      id: 458,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 42km 지역',
        eqDate: '2023-06-07 15:30:05',
        magMl: '2.1',
        eqDt: '22',
        eqLt: '41.31',
        eqLn: '129.16',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.16, 41.31] }
    },
    {
      type: 'Feature',
      id: 459,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '경남 창원시 마산합포구 남남서쪽 15km 해역',
        eqDate: '2023-06-13 14:23:07',
        magMl: '2.0',
        eqDt: '21',
        eqLt: '35.07',
        eqLn: '128.50',
        jdLoc: 'Ⅱ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [128.5, 35.07] }
    },
    {
      type: 'Feature',
      id: 460,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 남서쪽 92km 해역',
        eqDate: '2023-06-16 06:03:01',
        magMl: '3.1',
        eqDt: '25',
        eqLt: '34.02',
        eqLn: '124.80',
        jdLoc: 'Ⅰ',
        zeroDist: '75'
      },
      geometry: { type: 'Point', coordinates: [124.8, 34.02] }
    },
    {
      type: 'Feature',
      id: 461,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 동해시 북동쪽 49km 해역',
        eqDate: '2023-06-17 22:34:38',
        magMl: '2.1',
        eqDt: '33',
        eqLt: '37.86',
        eqLn: '129.47',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.47, 37.86] }
    },
    {
      type: 'Feature',
      id: 462,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 38km 지역',
        eqDate: '2023-06-18 23:39:16',
        magMl: '2.3',
        eqDt: '17',
        eqLt: '41.28',
        eqLn: '129.19',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [129.19, 41.28] }
    },
    {
      type: 'Feature',
      id: 463,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 42km 지역',
        eqDate: '2023-06-25 11:55:14',
        magMl: '2.4',
        eqDt: '17',
        eqLt: '41.32',
        eqLn: '129.21',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [129.21, 41.32] }
    },
    {
      type: 'Feature',
      id: 464,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 42km 지역',
        eqDate: '2023-07-03 10:04:38',
        magMl: '3.3',
        eqDt: '14',
        eqLt: '41.30',
        eqLn: '129.12',
        jdLoc: 'Ⅰ',
        zeroDist: '94'
      },
      geometry: { type: 'Point', coordinates: [129.12, 41.3] }
    },
    {
      type: 'Feature',
      id: 465,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 36km 지역',
        eqDate: '2023-07-05 01:02:38',
        magMl: '2.1',
        eqDt: '15',
        eqLt: '41.27',
        eqLn: '129.21',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.21, 41.27] }
    },
    {
      type: 'Feature',
      id: 466,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경남',
        eqPt: '경남 함안군 동북동쪽 16km 지역',
        eqDate: '2023-07-17 10:37:10',
        magMl: '2.4',
        eqDt: '13',
        eqLt: '35.31',
        eqLn: '128.58',
        jdLoc: 'Ⅲ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [128.58, 35.31] }
    },
    {
      type: 'Feature',
      id: 467,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 40km 지역',
        eqDate: '2023-07-28 13:16:43',
        magMl: '2.0',
        eqDt: '17',
        eqLt: '41.31',
        eqLn: '129.23',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [129.23, 41.31] }
    },
    {
      type: 'Feature',
      id: 468,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '전북',
        eqPt: '전북 장수군 북쪽 17km 지역',
        eqDate: '2023-07-29 19:07:59',
        magMl: '3.5',
        eqDt: '6',
        eqLt: '35.80',
        eqLn: '127.53',
        jdLoc: 'Ⅴ',
        zeroDist: '119'
      },
      geometry: { type: 'Point', coordinates: [127.53, 35.8] }
    },
    {
      type: 'Feature',
      id: 469,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '전남 여수시 거문도 남남서쪽 34km 해역',
        eqDate: '2023-08-04 03:07:31',
        magMl: '2.1',
        eqDt: '22',
        eqLt: '33.73',
        eqLn: '127.22',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [127.22, 33.73] }
    },
    {
      type: 'Feature',
      id: 470,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '전남',
        eqPt: '전남 고흥군 남남동쪽 4km 지역',
        eqDate: '2023-08-07 00:15:30',
        magMl: '2.2',
        eqDt: '21',
        eqLt: '34.57',
        eqLn: '127.29',
        jdLoc: 'Ⅱ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [127.29, 34.57] }
    },
    {
      type: 'Feature',
      id: 471,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 40km 지역',
        eqDate: '2023-08-13 03:13:37',
        magMl: '2.7',
        eqDt: '15',
        eqLt: '41.30',
        eqLn: '129.19',
        jdLoc: 'Ⅰ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [129.19, 41.3] }
    },
    {
      type: 'Feature',
      id: 472,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 42km 지역',
        eqDate: '2023-08-13 07:55:00',
        magMl: '2.3',
        eqDt: '5',
        eqLt: '41.31',
        eqLn: '129.16',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [129.16, 41.31] }
    },
    {
      type: 'Feature',
      id: 473,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '경북 울릉군 울릉도 서북서쪽 58km 해역',
        eqDate: '2023-08-20 19:41:14',
        magMl: '2.6',
        eqDt: '',
        eqLt: '37.75',
        eqLn: '130.34',
        jdLoc: 'Ⅰ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [130.34, 37.75] }
    },
    {
      type: 'Feature',
      id: 474,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 김천시 남쪽 18km 지역',
        eqDate: '2023-08-26 01:25:33',
        magMl: '2.1',
        eqDt: '9',
        eqLt: '35.98',
        eqLn: '128.14',
        jdLoc: 'Ⅲ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [128.14, 35.98] }
    },
    {
      type: 'Feature',
      id: 475,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 39km 지역',
        eqDate: '2023-09-02 20:54:33',
        magMl: '2.5',
        eqDt: '15',
        eqLt: '41.28',
        eqLn: '129.15',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [129.15, 41.28] }
    },
    {
      type: 'Feature',
      id: 476,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북쪽 38km 지역',
        eqDate: '2023-09-08 03:29:48',
        magMl: '2.2',
        eqDt: '17',
        eqLt: '41.29',
        eqLn: '129.24',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [129.24, 41.29] }
    },
    {
      type: 'Feature',
      id: 477,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 39km 지역',
        eqDate: '2023-09-08 04:01:16',
        magMl: '2.1',
        eqDt: '24',
        eqLt: '41.30',
        eqLn: '129.23',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.23, 41.3] }
    },
    {
      type: 'Feature',
      id: 478,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 43km 지역',
        eqDate: '2023-09-08 05:12:01',
        magMl: '2.3',
        eqDt: '19',
        eqLt: '41.32',
        eqLn: '129.17',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [129.17, 41.32] }
    },
    {
      type: 'Feature',
      id: 479,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '울산 동구 동북동쪽 60km 해역',
        eqDate: '2023-09-14 21:28:25',
        magMl: '2.9',
        eqDt: '23',
        eqLt: '35.70',
        eqLn: '130.03',
        jdLoc: 'Ⅰ',
        zeroDist: '61'
      },
      geometry: { type: 'Point', coordinates: [130.03, 35.7] }
    },
    {
      type: 'Feature',
      id: 480,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 김천시 남남서쪽 17km 지역',
        eqDate: '2023-09-16 04:28:25',
        magMl: '2.2',
        eqDt: '7',
        eqLt: '36.01',
        eqLn: '128.01',
        jdLoc: 'Ⅱ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [128.01, 36.01] }
    },
    {
      type: 'Feature',
      id: 481,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 문경시 동쪽 3km 지역',
        eqDate: '2023-09-22 17:46:58',
        magMl: '2.2',
        eqDt: '14',
        eqLt: '36.59',
        eqLn: '128.22',
        jdLoc: 'Ⅱ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [128.22, 36.59] }
    },
    {
      type: 'Feature',
      id: 482,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 44km 지역',
        eqDate: '2023-09-27 00:48:43',
        magMl: '3.2',
        eqDt: '6',
        eqLt: '41.33',
        eqLn: '129.16',
        jdLoc: 'Ⅰ',
        zeroDist: '84'
      },
      geometry: { type: 'Point', coordinates: [129.16, 41.33] }
    },
    {
      type: 'Feature',
      id: 483,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북쪽 34km 지역',
        eqDate: '2023-10-01 07:35:56',
        magMl: '2.1',
        eqDt: '23',
        eqLt: '41.26',
        eqLn: '129.29',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.29, 41.26] }
    },
    {
      type: 'Feature',
      id: 484,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 41km 지역',
        eqDate: '2023-10-06 18:29:03',
        magMl: '2.5',
        eqDt: '12',
        eqLt: '41.29',
        eqLn: '129.13',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [129.13, 41.29] }
    },
    {
      type: 'Feature',
      id: 485,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 39km 지역',
        eqDate: '2023-10-09 23:16:36',
        magMl: '2.2',
        eqDt: '13',
        eqLt: '41.30',
        eqLn: '129.23',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [129.23, 41.3] }
    },
    {
      type: 'Feature',
      id: 486,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '충남 태안군 서격렬비도 서남서쪽 111km 해역',
        eqDate: '2023-10-10 00:20:28',
        magMl: '3.1',
        eqDt: '13',
        eqLt: '36.18',
        eqLn: '124.42',
        jdLoc: 'Ⅰ',
        zeroDist: '75'
      },
      geometry: { type: 'Point', coordinates: [124.42, 36.18] }
    },
    {
      type: 'Feature',
      id: 487,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 북쪽 72km 해역',
        eqDate: '2023-10-11 03:54:54',
        magMl: '2.9',
        eqDt: '12',
        eqLt: '35.31',
        eqLn: '125.51',
        jdLoc: 'Ⅰ',
        zeroDist: '61'
      },
      geometry: { type: 'Point', coordinates: [125.51, 35.31] }
    },
    {
      type: 'Feature',
      id: 488,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 강원 회양 북북동쪽 7km 지역',
        eqDate: '2023-10-11 18:58:13',
        magMl: '2.2',
        eqDt: '5',
        eqLt: '38.76',
        eqLn: '127.63',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [127.63, 38.76] }
    },
    {
      type: 'Feature',
      id: 489,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '북한 황해남도 옹진 남동쪽 30km 해역',
        eqDate: '2023-10-15 09:42:38',
        magMl: '2.3',
        eqDt: '12',
        eqLt: '37.72',
        eqLn: '125.58',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [125.58, 37.72] }
    },
    {
      type: 'Feature',
      id: 490,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경남도 장진 북쪽 10km 지역',
        eqDate: '2023-10-20 00:30:12',
        magMl: '2.8',
        eqDt: '9',
        eqLt: '40.47',
        eqLn: '127.26',
        jdLoc: 'Ⅰ',
        zeroDist: '55'
      },
      geometry: { type: 'Point', coordinates: [127.26, 40.47] }
    },
    {
      type: 'Feature',
      id: 491,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 북서쪽 53km 해역',
        eqDate: '2023-10-20 17:36:00',
        magMl: '2.0',
        eqDt: '18',
        eqLt: '35.06',
        eqLn: '125.08',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [125.08, 35.06] }
    },
    {
      type: 'Feature',
      id: 492,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 동해시 북동쪽 52km 해역',
        eqDate: '2023-10-22 03:04:09',
        magMl: '2.1',
        eqDt: '29',
        eqLt: '37.86',
        eqLn: '129.52',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.52, 37.86] }
    },
    {
      type: 'Feature',
      id: 493,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 동해시 북동쪽 49km 해역',
        eqDate: '2023-10-24 09:11:37',
        magMl: '2.7',
        eqDt: '31',
        eqLt: '37.85',
        eqLn: '129.49',
        jdLoc: 'Ⅰ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [129.49, 37.85] }
    },
    {
      type: 'Feature',
      id: 494,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '충남',
        eqPt: '충남 공주시 남남서쪽 12km 지역',
        eqDate: '2023-10-25 21:46:30',
        magMl: '3.4',
        eqDt: '12',
        eqLt: '36.35',
        eqLn: '127.05',
        jdLoc: 'Ⅴ',
        zeroDist: '106'
      },
      geometry: { type: 'Point', coordinates: [127.05, 36.35] }
    },
    {
      type: 'Feature',
      id: 495,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 37km 지역',
        eqDate: '2023-10-28 08:37:14',
        magMl: '2.2',
        eqDt: '31',
        eqLt: '41.25',
        eqLn: '129.12',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [129.12, 41.25] }
    },
    {
      type: 'Feature',
      id: 496,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '북한 황해남도 용연 서남서쪽 19km 해역',
        eqDate: '2023-11-03 00:24:23',
        magMl: '2.5',
        eqDt: '12',
        eqLt: '38.10',
        eqLn: '124.72',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [124.72, 38.1] }
    },
    {
      type: 'Feature',
      id: 497,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '전남 여수시 거문도 남쪽 40km 해역',
        eqDate: '2023-11-17 04:06:38',
        magMl: '2.1',
        eqDt: '18',
        eqLt: '33.67',
        eqLn: '127.38',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [127.38, 33.67] }
    },
    {
      type: 'Feature',
      id: 498,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 41km 지역',
        eqDate: '2023-11-22 16:29:32',
        magMl: '2.9',
        eqDt: '23',
        eqLt: '41.31',
        eqLn: '129.18',
        jdLoc: 'Ⅰ',
        zeroDist: '61'
      },
      geometry: { type: 'Point', coordinates: [129.18, 41.31] }
    },
    {
      type: 'Feature',
      id: 499,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 40km 지역',
        eqDate: '2023-11-22 17:40:59',
        magMl: '2.3',
        eqDt: '24',
        eqLt: '41.30',
        eqLn: '129.20',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [129.2, 41.3] }
    },
    {
      type: 'Feature',
      id: 500,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 38km 지역',
        eqDate: '2023-11-23 07:54:55',
        magMl: '2.5',
        eqDt: '19',
        eqLt: '41.26',
        eqLn: '129.13',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [129.13, 41.26] }
    },
    {
      type: 'Feature',
      id: 501,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북서쪽 38km 지역',
        eqDate: '2023-11-23 12:42:02',
        magMl: '2.2',
        eqDt: '6',
        eqLt: '41.23',
        eqLn: '129.05',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [129.05, 41.23] }
    },
    {
      type: 'Feature',
      id: 502,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 40km 지역',
        eqDate: '2023-11-23 21:21:44',
        magMl: '2.6',
        eqDt: '15',
        eqLt: '41.30',
        eqLn: '129.20',
        jdLoc: 'Ⅰ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [129.2, 41.3] }
    },
    {
      type: 'Feature',
      id: 503,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 42km 지역',
        eqDate: '2023-11-23 21:31:58',
        magMl: '2.2',
        eqDt: '18',
        eqLt: '41.31',
        eqLn: '129.14',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [129.14, 41.31] }
    },
    {
      type: 'Feature',
      id: 504,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 북서쪽 55km 해역',
        eqDate: '2023-11-24 22:06:53',
        magMl: '2.7',
        eqDt: '17',
        eqLt: '35.07',
        eqLn: '125.06',
        jdLoc: 'Ⅰ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [125.06, 35.07] }
    },
    {
      type: 'Feature',
      id: 505,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '충남',
        eqPt: '충남 예산군 북서쪽 2km 지역',
        eqDate: '2023-11-25 11:53:35',
        magMl: '2.6',
        eqDt: '8',
        eqLt: '36.69',
        eqLn: '126.83',
        jdLoc: 'Ⅳ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [126.83, 36.69] }
    },
    {
      type: 'Feature',
      id: 506,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 경주시 동남동쪽 19km 지역',
        eqDate: '2023-11-30 04:55:24',
        magMl: '4.0',
        eqDt: '12',
        eqLt: '35.79',
        eqLn: '129.42',
        jdLoc: 'Ⅴ',
        zeroDist: '219'
      },
      geometry: { type: 'Point', coordinates: [129.42, 35.79] }
    },
    {
      type: 'Feature',
      id: 507,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 41km 지역',
        eqDate: '2023-12-04 02:28:02',
        magMl: '3.1',
        eqDt: '22',
        eqLt: '41.30',
        eqLn: '129.15',
        jdLoc: 'Ⅰ',
        zeroDist: '75'
      },
      geometry: { type: 'Point', coordinates: [129.15, 41.3] }
    },
    {
      type: 'Feature',
      id: 508,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 38km 지역',
        eqDate: '2023-12-05 05:53:41',
        magMl: '2.2',
        eqDt: '19',
        eqLt: '41.29',
        eqLn: '129.22',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [129.22, 41.29] }
    },
    {
      type: 'Feature',
      id: 509,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 35km 지역',
        eqDate: '2023-12-06 19:11:06',
        magMl: '2.0',
        eqDt: '28',
        eqLt: '41.26',
        eqLn: '129.22',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [129.22, 41.26] }
    },
    {
      type: 'Feature',
      id: 510,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '강원',
        eqPt: '강원 삼척시 남남서쪽 30km 지역',
        eqDate: '2023-12-08 06:31:18',
        magMl: '2.5',
        eqDt: '',
        eqLt: '37.19',
        eqLn: '129.08',
        jdLoc: 'Ⅲ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [129.08, 37.19] }
    },
    {
      type: 'Feature',
      id: 511,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 평산 북서쪽 24km 지역',
        eqDate: '2023-12-11 01:23:25',
        magMl: '2.3',
        eqDt: '12',
        eqLt: '38.45',
        eqLn: '126.17',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [126.17, 38.45] }
    },
    {
      type: 'Feature',
      id: 512,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '전북',
        eqPt: '전북 장수군 북쪽 17km 지역',
        eqDate: '2023-12-23 04:34:05',
        magMl: '3.0',
        eqDt: '6',
        eqLt: '35.80',
        eqLn: '127.53',
        jdLoc: 'Ⅳ',
        zeroDist: '68'
      },
      geometry: { type: 'Point', coordinates: [127.53, 35.8] }
    },
    {
      type: 'Feature',
      id: 513,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '전남 여수시 거문도 남쪽 49km 해역',
        eqDate: '2024-01-06 20:02:39',
        magMl: '2.4',
        eqDt: '17',
        eqLt: '33.59',
        eqLn: '127.22',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [127.22, 33.59] }
    },
    {
      type: 'Feature',
      id: 514,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 41km 지역',
        eqDate: '2024-01-11 19:00:53',
        magMl: '2.4',
        eqDt: '20',
        eqLt: '41.30',
        eqLn: '129.16',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [129.16, 41.3] }
    },
    {
      type: 'Feature',
      id: 515,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '인천 옹진군 백령도 남쪽 34km 해역',
        eqDate: '2024-01-13 08:03:02',
        magMl: '2.4',
        eqDt: '8',
        eqLt: '37.65',
        eqLn: '124.65',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [124.65, 37.65] }
    },
    {
      type: 'Feature',
      id: 516,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 송림 북동쪽 17km 지역',
        eqDate: '2024-01-24 12:25:25',
        magMl: '2.1',
        eqDt: '',
        eqLt: '38.85',
        eqLn: '125.76',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [125.76, 38.85] }
    },
    {
      type: 'Feature',
      id: 517,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 경주시 남남서쪽 11km 지역',
        eqDate: '2024-01-24 19:12:07',
        magMl: '2.0',
        eqDt: '18',
        eqLt: '35.76',
        eqLn: '129.19',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [129.19, 35.76] }
    },
    {
      type: 'Feature',
      id: 518,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '제주 서귀포시 이어도 북동쪽 62km 해역',
        eqDate: '2024-01-31 17:36:23',
        magMl: '3.8',
        eqDt: '13',
        eqLt: '32.52',
        eqLn: '125.65',
        jdLoc: 'Ⅱ',
        zeroDist: '171'
      },
      geometry: { type: 'Point', coordinates: [125.65, 32.52] }
    },
    {
      type: 'Feature',
      id: 519,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '전북',
        eqPt: '전북 익산시 동북동쪽 4km 지역',
        eqDate: '2024-02-03 02:24:04',
        magMl: '2.0',
        eqDt: '13',
        eqLt: '35.96',
        eqLn: '127.00',
        jdLoc: 'Ⅱ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [127, 35.96] }
    },
    {
      type: 'Feature',
      id: 520,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북쪽 57km 지역',
        eqDate: '2024-02-08 03:15:44',
        magMl: '2.3',
        eqDt: '21',
        eqLt: '41.46',
        eqLn: '129.25',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [129.25, 41.46] }
    },
    {
      type: 'Feature',
      id: 521,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 39km 지역',
        eqDate: '2024-02-14 23:33:00',
        magMl: '2.2',
        eqDt: '20',
        eqLt: '41.28',
        eqLn: '129.15',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [129.15, 41.28] }
    },
    {
      type: 'Feature',
      id: 522,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 북북서쪽 56km 해역',
        eqDate: '2024-03-03 08:53:39',
        magMl: '2.8',
        eqDt: '14',
        eqLt: '35.09',
        eqLn: '125.09',
        jdLoc: 'Ⅰ',
        zeroDist: '55'
      },
      geometry: { type: 'Point', coordinates: [125.09, 35.09] }
    },
    {
      type: 'Feature',
      id: 523,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 40km 지역',
        eqDate: '2024-03-15 22:35:42',
        magMl: '2.2',
        eqDt: '15',
        eqLt: '41.30',
        eqLn: '129.19',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [129.19, 41.3] }
    },
    {
      type: 'Feature',
      id: 524,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 남남서쪽 113km 해역',
        eqDate: '2024-03-22 08:05:55',
        magMl: '2.4',
        eqDt: '18',
        eqLt: '33.68',
        eqLn: '125.15',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [125.15, 33.68] }
    },
    {
      type: 'Feature',
      id: 525,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 북북서쪽 58km 해역',
        eqDate: '2024-03-25 21:16:45',
        magMl: '2.1',
        eqDt: '7',
        eqLt: '35.11',
        eqLn: '125.07',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [125.07, 35.11] }
    },
    {
      type: 'Feature',
      id: 526,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 43km 지역',
        eqDate: '2024-03-27 09:43:10',
        magMl: '2.9',
        eqDt: '29',
        eqLt: '41.31',
        eqLn: '129.13',
        jdLoc: 'Ⅰ',
        zeroDist: '61'
      },
      geometry: { type: 'Point', coordinates: [129.13, 41.31] }
    },
    {
      type: 'Feature',
      id: 527,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 43km 지역',
        eqDate: '2024-03-27 11:14:27',
        magMl: '2.7',
        eqDt: '16',
        eqLt: '41.31',
        eqLn: '129.12',
        jdLoc: 'Ⅰ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [129.12, 41.31] }
    },
    {
      type: 'Feature',
      id: 528,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 평안남도 평성 북쪽 22km 지역',
        eqDate: '2024-04-10 15:30:46',
        magMl: '2.9',
        eqDt: '6',
        eqLt: '39.44',
        eqLn: '125.88',
        jdLoc: 'Ⅰ',
        zeroDist: '61'
      },
      geometry: { type: 'Point', coordinates: [125.88, 39.44] }
    },
    {
      type: 'Feature',
      id: 529,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 칠곡군 서쪽 5km 지역',
        eqDate: '2024-04-22 07:47:19',
        magMl: '2.6',
        eqDt: '6',
        eqLt: '36.00',
        eqLn: '128.35',
        jdLoc: 'Ⅳ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [128.35, 36] }
    },
    {
      type: 'Feature',
      id: 530,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '제주 서귀포시 동쪽 51km 해역',
        eqDate: '2024-04-22 18:47:51',
        magMl: '2.0',
        eqDt: '20',
        eqLt: '33.31',
        eqLn: '127.10',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [127.1, 33.31] }
    },
    {
      type: 'Feature',
      id: 531,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경남',
        eqPt: '경남 합천군 동북동쪽 11km 지역',
        eqDate: '2024-04-28 10:33:43',
        magMl: '2.2',
        eqDt: '14',
        eqLt: '35.61',
        eqLn: '128.28',
        jdLoc: 'Ⅲ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [128.28, 35.61] }
    },
    {
      type: 'Feature',
      id: 532,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 41km 지역',
        eqDate: '2024-04-30 01:54:46',
        magMl: '2.5',
        eqDt: '16',
        eqLt: '41.30',
        eqLn: '129.14',
        jdLoc: 'Ⅰ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [129.14, 41.3] }
    },
    {
      type: 'Feature',
      id: 533,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 연산 남서쪽 11km 지역',
        eqDate: '2024-05-08 00:50:00',
        magMl: '2.2',
        eqDt: '11',
        eqLt: '38.80',
        eqLn: '126.17',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [126.17, 38.8] }
    },
    {
      type: 'Feature',
      id: 534,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 42km 지역',
        eqDate: '2024-05-09 05:41:25',
        magMl: '2.2',
        eqDt: '11',
        eqLt: '41.32',
        eqLn: '129.18',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [129.18, 41.32] }
    },
    {
      type: 'Feature',
      id: 535,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '울산 동구 동북동쪽 40km 해역',
        eqDate: '2024-05-20 10:31:45',
        magMl: '2.6',
        eqDt: '22',
        eqLt: '35.63',
        eqLn: '129.83',
        jdLoc: 'Ⅰ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [129.83, 35.63] }
    },
    {
      type: 'Feature',
      id: 536,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 42km 지역',
        eqDate: '2024-05-22 12:29:19',
        magMl: '2.3',
        eqDt: '16',
        eqLt: '41.27',
        eqLn: '129.06',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [129.06, 41.27] }
    },
    {
      type: 'Feature',
      id: 537,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경기',
        eqPt: '경기 연천군 북북동쪽 4km 지역',
        eqDate: '2024-05-31 03:35:02',
        magMl: '2.8',
        eqDt: '9',
        eqLt: '38.13',
        eqLn: '127.09',
        jdLoc: 'Ⅲ',
        zeroDist: '55'
      },
      geometry: { type: 'Point', coordinates: [127.09, 38.13] }
    },
    {
      type: 'Feature',
      id: 538,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 김천시 북동쪽 14km 지역',
        eqDate: '2024-06-01 00:53:41',
        magMl: '2.4',
        eqDt: '11',
        eqLt: '36.24',
        eqLn: '128.20',
        jdLoc: 'Ⅱ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [128.2, 36.24] }
    },
    {
      type: 'Feature',
      id: 539,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 44km 지역',
        eqDate: '2024-06-01 11:55:28',
        magMl: '2.1',
        eqDt: '6',
        eqLt: '41.33',
        eqLn: '129.17',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.17, 41.33] }
    },
    {
      type: 'Feature',
      id: 540,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 속초시 동북동쪽 9km 해역',
        eqDate: '2024-06-03 02:25:11',
        magMl: '2.5',
        eqDt: '14',
        eqLt: '38.24',
        eqLn: '128.69',
        jdLoc: 'Ⅲ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [128.69, 38.24] }
    },
    {
      type: 'Feature',
      id: 541,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '북한 강원 회양 동쪽 138km 해역',
        eqDate: '2024-06-08 23:04:37',
        magMl: '2.2',
        eqDt: '15',
        eqLt: '38.87',
        eqLn: '129.18',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [129.18, 38.87] }
    },
    {
      type: 'Feature',
      id: 542,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '제주 제주시 서쪽 133km 해역',
        eqDate: '2024-06-09 15:16:14',
        magMl: '2.1',
        eqDt: '7',
        eqLt: '33.40',
        eqLn: '125.10',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [125.1, 33.4] }
    },
    {
      type: 'Feature',
      id: 543,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '전북',
        eqPt: '전북 부안군 남남서쪽 4km 지역',
        eqDate: '2024-06-12 08:26:49',
        magMl: '4.8',
        eqDt: '8',
        eqLt: '35.70',
        eqLn: '126.72',
        jdLoc: 'Ⅴ',
        zeroDist: '608'
      },
      geometry: { type: 'Point', coordinates: [126.72, 35.7] }
    },
    {
      type: 'Feature',
      id: 544,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '전북',
        eqPt: '전북 부안군 남쪽 4km 지역',
        eqDate: '2024-06-12 13:55:42',
        magMl: '3.1',
        eqDt: '8',
        eqLt: '35.70',
        eqLn: '126.73',
        jdLoc: 'Ⅳ',
        zeroDist: '75'
      },
      geometry: { type: 'Point', coordinates: [126.73, 35.7] }
    },
    {
      type: 'Feature',
      id: 545,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '제주 서귀포시 남서쪽 41km 해역',
        eqDate: '2024-06-22 13:49:00',
        magMl: '2.0',
        eqDt: '16',
        eqLt: '32.97',
        eqLn: '126.28',
        jdLoc: 'Ⅰ',
        zeroDist: '28'
      },
      geometry: { type: 'Point', coordinates: [126.28, 32.97] }
    },
    {
      type: 'Feature',
      id: 546,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경남도 금야 서쪽 17km 지역',
        eqDate: '2024-06-27 03:04:40',
        magMl: '2.1',
        eqDt: '6',
        eqLt: '39.56',
        eqLn: '127.04',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [127.04, 39.56] }
    },
    {
      type: 'Feature',
      id: 547,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '전북',
        eqPt: '전북 부안군 남남서쪽 4km 지역',
        eqDate: '2024-07-03 14:24:43',
        magMl: '2.3',
        eqDt: '8',
        eqLt: '35.70',
        eqLn: '126.72',
        jdLoc: 'Ⅱ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [126.72, 35.7] }
    },
    {
      type: 'Feature',
      id: 548,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 안동시 동남동쪽 13km 지역',
        eqDate: '2024-07-06 02:19:15',
        magMl: '2.4',
        eqDt: '16',
        eqLt: '36.51',
        eqLn: '128.85',
        jdLoc: 'Ⅲ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [128.85, 36.51] }
    },
    {
      type: 'Feature',
      id: 549,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전북 군산시 어청도 남서쪽 98km 해역',
        eqDate: '2024-07-26 22:52:37',
        magMl: '2.6',
        eqDt: '',
        eqLt: '35.56',
        eqLn: '125.14',
        jdLoc: 'Ⅰ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [125.14, 35.56] }
    },
    {
      type: 'Feature',
      id: 550,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 42km 지역',
        eqDate: '2024-07-27 12:50:57',
        magMl: '2.9',
        eqDt: '17',
        eqLt: '41.30',
        eqLn: '129.13',
        jdLoc: 'Ⅰ',
        zeroDist: '61'
      },
      geometry: { type: 'Point', coordinates: [129.13, 41.3] }
    },
    {
      type: 'Feature',
      id: 551,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 송림 동북동쪽 23km 지역',
        eqDate: '2024-07-29 21:56:42',
        magMl: '2.3',
        eqDt: '14',
        eqLt: '38.78',
        eqLn: '125.89',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [125.89, 38.78] }
    },
    {
      type: 'Feature',
      id: 552,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 송림 동쪽 22km 지역',
        eqDate: '2024-08-01 08:34:12',
        magMl: '3.0',
        eqDt: '9',
        eqLt: '38.76',
        eqLn: '125.89',
        jdLoc: 'Ⅰ',
        zeroDist: '68'
      },
      geometry: { type: 'Point', coordinates: [125.89, 38.76] }
    },
    {
      type: 'Feature',
      id: 553,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 황해북도 송림 동쪽 22km 지역',
        eqDate: '2024-08-01 12:50:08',
        magMl: '2.6',
        eqDt: '9',
        eqLt: '38.77',
        eqLn: '125.89',
        jdLoc: 'Ⅰ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [125.89, 38.77] }
    },
    {
      type: 'Feature',
      id: 554,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 서쪽 71km 해역',
        eqDate: '2024-08-04 00:27:10',
        magMl: '2.7',
        eqDt: '18',
        eqLt: '34.79',
        eqLn: '124.66',
        jdLoc: 'Ⅰ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [124.66, 34.79] }
    },
    {
      type: 'Feature',
      id: 555,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '충남 서산시 북북서쪽 23km 해역',
        eqDate: '2024-08-23 01:42:16',
        magMl: '2.1',
        eqDt: '9',
        eqLt: '36.96',
        eqLn: '126.31',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [126.31, 36.96] }
    },
    {
      type: 'Feature',
      id: 556,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 동해시 북동쪽 60km 해역',
        eqDate: '2024-08-25 23:28:10',
        magMl: '3.0',
        eqDt: '',
        eqLt: '37.90',
        eqLn: '129.60',
        jdLoc: 'Ⅱ',
        zeroDist: '68'
      },
      geometry: { type: 'Point', coordinates: [129.6, 37.9] }
    },
    {
      type: 'Feature',
      id: 557,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 41km 지역',
        eqDate: '2024-08-27 00:15:21',
        magMl: '2.7',
        eqDt: '12',
        eqLt: '41.28',
        eqLn: '129.09',
        jdLoc: 'Ⅰ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [129.09, 41.28] }
    },
    {
      type: 'Feature',
      id: 558,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 동해시 북동쪽 54km 해역',
        eqDate: '2024-08-27 23:02:24',
        magMl: '2.2',
        eqDt: '21',
        eqLt: '37.87',
        eqLn: '129.54',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [129.54, 37.87] }
    },
    {
      type: 'Feature',
      id: 559,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '울산 동구 동북동쪽 35km 해역',
        eqDate: '2024-08-29 20:23:55',
        magMl: '2.4',
        eqDt: '19',
        eqLt: '35.66',
        eqLn: '129.75',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [129.75, 35.66] }
    },
    {
      type: 'Feature',
      id: 560,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '강원 동해시 북동쪽 52km 해역',
        eqDate: '2024-09-01 13:46:21',
        magMl: '2.4',
        eqDt: '27',
        eqLt: '37.86',
        eqLn: '129.52',
        jdLoc: 'Ⅰ',
        zeroDist: '38'
      },
      geometry: { type: 'Point', coordinates: [129.52, 37.86] }
    },
    {
      type: 'Feature',
      id: 561,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '충남 태안군 서격렬비도 북쪽 56km 해역',
        eqDate: '2024-09-11 02:24:34',
        magMl: '2.7',
        eqDt: '11',
        eqLt: '37.11',
        eqLn: '125.45',
        jdLoc: 'Ⅱ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [125.45, 37.11] }
    },
    {
      type: 'Feature',
      id: 562,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 평안남도 덕천 북동쪽 27km 지역',
        eqDate: '2024-09-12 19:19:46',
        magMl: '2.9',
        eqDt: '17',
        eqLt: '39.90',
        eqLn: '126.56',
        jdLoc: 'Ⅰ',
        zeroDist: '61'
      },
      geometry: { type: 'Point', coordinates: [126.56, 39.9] }
    },
    {
      type: 'Feature',
      id: 563,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 의성군 북동쪽 8km 지역',
        eqDate: '2024-09-12 19:25:10',
        magMl: '2.7',
        eqDt: '14',
        eqLt: '36.41',
        eqLn: '128.76',
        jdLoc: 'Ⅲ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [128.76, 36.41] }
    },
    {
      type: 'Feature',
      id: 564,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경남',
        eqPt: '경남 창녕군 남남서쪽 11km 지역',
        eqDate: '2024-09-12 23:47:19',
        magMl: '2.6',
        eqDt: '16',
        eqLt: '35.46',
        eqLn: '128.43',
        jdLoc: 'Ⅲ',
        zeroDist: '45'
      },
      geometry: { type: 'Point', coordinates: [128.43, 35.46] }
    },
    {
      type: 'Feature',
      id: 565,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전남 신안군 흑산도 북서쪽 120km 해역',
        eqDate: '2024-09-13 21:58:04',
        magMl: '2.3',
        eqDt: '11',
        eqLt: '35.47',
        eqLn: '124.54',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [124.54, 35.47] }
    },
    {
      type: 'Feature',
      id: 566,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '충남 태안군 서격렬비도 남서쪽 142km 해역',
        eqDate: '2024-09-18 12:47:53',
        magMl: '2.7',
        eqDt: '',
        eqLt: '35.68',
        eqLn: '124.46',
        jdLoc: 'Ⅰ',
        zeroDist: '50'
      },
      geometry: { type: 'Point', coordinates: [124.46, 35.68] }
    },
    {
      type: 'Feature',
      id: 567,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 자강도 용림 동북동쪽 7km 지역',
        eqDate: '2024-09-19 19:41:18',
        magMl: '3.9',
        eqDt: '',
        eqLt: '40.54',
        eqLn: '126.75',
        jdLoc: 'Ⅰ',
        zeroDist: '193'
      },
      geometry: { type: 'Point', coordinates: [126.75, 40.54] }
    },
    {
      type: 'Feature',
      id: 568,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 평안남도 대흥 북북서쪽 12km 지역',
        eqDate: '2024-09-19 21:39:17',
        magMl: '2.2',
        eqDt: '5',
        eqLt: '40.19',
        eqLn: '126.88',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [126.88, 40.19] }
    },
    {
      type: 'Feature',
      id: 569,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '울산 동구 북동쪽 12km 해역',
        eqDate: '2024-09-22 20:23:38',
        magMl: '2.2',
        eqDt: '10',
        eqLt: '35.59',
        eqLn: '129.50',
        jdLoc: 'Ⅱ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [129.5, 35.59] }
    },
    {
      type: 'Feature',
      id: 570,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경남',
        eqPt: '경남 창원시 의창구 남남서쪽 4km 지역',
        eqDate: '2024-09-24 07:05:28',
        magMl: '2.2',
        eqDt: '12',
        eqLt: '35.22',
        eqLn: '128.63',
        jdLoc: 'Ⅲ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [128.63, 35.22] }
    },
    {
      type: 'Feature',
      id: 571,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 자강도 용림 남동쪽 26km 지역',
        eqDate: '2024-09-26 11:00:50',
        magMl: '2.9',
        eqDt: '27',
        eqLt: '40.32',
        eqLn: '126.86',
        jdLoc: 'Ⅰ',
        zeroDist: '61'
      },
      geometry: { type: 'Point', coordinates: [126.86, 40.32] }
    },
    {
      type: 'Feature',
      id: 572,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 안동시 북동쪽 24km 지역',
        eqDate: '2024-09-28 16:45:19',
        magMl: '2.2',
        eqDt: '9',
        eqLt: '36.73',
        eqLn: '128.90',
        jdLoc: 'Ⅱ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [128.9, 36.73] }
    },
    {
      type: 'Feature',
      id: 573,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '남해',
        eqPt: '제주 제주시 동북동쪽 40km 해역',
        eqDate: '2024-10-01 11:33:30',
        magMl: '2.1',
        eqDt: '16',
        eqLt: '33.62',
        eqLn: '126.94',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [126.94, 33.62] }
    },
    {
      type: 'Feature',
      id: 574,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 39km 지역',
        eqDate: '2024-10-04 13:23:30',
        magMl: '2.3',
        eqDt: '10',
        eqLt: '41.27',
        eqLn: '129.11',
        jdLoc: 'Ⅰ',
        zeroDist: '35'
      },
      geometry: { type: 'Point', coordinates: [129.11, 41.27] }
    },
    {
      type: 'Feature',
      id: 575,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '서해',
        eqPt: '전북 군산시 어청도 서쪽 50km 해역',
        eqDate: '2024-10-04 22:08:58',
        magMl: '2.1',
        eqDt: '12',
        eqLt: '36.08',
        eqLn: '125.42',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [125.42, 36.08] }
    },
    {
      type: 'Feature',
      id: 576,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'S',
        eqArCdNm: '동해',
        eqPt: '울산 북구 동북동쪽 26km 해역',
        eqDate: '2024-10-07 15:09:38',
        magMl: '2.2',
        eqDt: '20',
        eqLt: '35.67',
        eqLn: '129.63',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [129.63, 35.67] }
    },
    {
      type: 'Feature',
      id: 577,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '충남',
        eqPt: '충남 부여군 서쪽 18km 지역',
        eqDate: '2024-10-16 00:55:54',
        magMl: '2.1',
        eqDt: '12',
        eqLt: '36.27',
        eqLn: '126.71',
        jdLoc: 'Ⅱ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [126.71, 36.27] }
    },
    {
      type: 'Feature',
      id: 578,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 39km 지역',
        eqDate: '2024-10-21 15:33:07',
        magMl: '2.2',
        eqDt: '7',
        eqLt: '41.29',
        eqLn: '129.20',
        jdLoc: 'Ⅰ',
        zeroDist: '32'
      },
      geometry: { type: 'Point', coordinates: [129.2, 41.29] }
    },
    {
      type: 'Feature',
      id: 579,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '기타',
        eqPt: '북한 함경북도 길주 북북서쪽 37km 지역',
        eqDate: '2024-10-22 01:47:05',
        magMl: '2.1',
        eqDt: '18',
        eqLt: '41.27',
        eqLn: '129.20',
        jdLoc: 'Ⅰ',
        zeroDist: '30'
      },
      geometry: { type: 'Point', coordinates: [129.2, 41.27] }
    },
    {
      type: 'Feature',
      id: 580,
      properties: {
        msgCode: '지진정보',
        cntDiv: 'Y',
        arDiv: 'A',
        eqArCdNm: '경북',
        eqPt: '경북 칠곡군 서쪽 5km 지역',
        eqDate: '2024-10-23 17:56:17',
        magMl: '2.5',
        eqDt: '8',
        eqLt: '35.99',
        eqLn: '128.35',
        jdLoc: 'Ⅳ',
        zeroDist: '41'
      },
      geometry: { type: 'Point', coordinates: [128.35, 35.99] }
    }
  ]
};
