import React, { useContext, useEffect, useRef, useState } from 'react';
import AppContext, { MapsContext } from 'context/Context';
import {
  Badge,
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Image,
  Row
} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { chainAxios } from '../../../helpers/chainAxios';
import AdvanceTableWrapper from '../../common/advance-table/AdvanceTableWrapper';
import AdvanceTable from '../../common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from '../../common/advance-table/AdvanceTableSearchBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { getColor, isEmpty } from '../../../helpers/utils';
import IconButton from '../../common/IconButton';
import moment from 'moment';
import SoftBadge from '../../common/SoftBadge';
import { useNavigate } from 'react-router-dom';
import ResponsivePagination from 'react-responsive-pagination';

const Project = () => {
  const columns = [
    {
      accessor: 'favorite',
      Header: '즐겨찾기',
      headerProps: {
        className: 'text-900 --falcon-gray-100',
        style: { width: '95px' }
      },
      cellProps: {
        style: { width: '95px' }
      },
      Cell: rowData => {
        const { favorite } = rowData.row.original;
        return (
          <Flex direction={'column'} className={'w-100'} alignItems={'center'}>
            <FontAwesomeIcon
              onClick={() => {}}
              icon={favorite ? 'star' : ['far', 'star']}
              // transform="down-2"
              className={classNames(
                { 'text-primary': favorite, 'text-300': !favorite },
                'cursor-pointer'
              )}
            />
          </Flex>
        );
      }
    },
    {
      accessor: 'name',
      Header: '프로젝트명',
      headerProps: { className: 'text-900', style: { width: '300px' } },
      cellProps: {
        style: { width: '300px' }
      },
      Cell: rowData => {
        const { name, id } = rowData.row.original;
        return (
          <Button
            className={'fs--1'}
            variant="link"
            onClick={() => {
              goToProjectDetail(id);
            }}
          >
            {name}
          </Button>
        );
      }
    },
    {
      accessor: 'facilities',
      Header: '도로·시설물',
      headerProps: { className: 'text-900', style: { width: '100px' } },
      cellProps: {
        style: { width: '100px' }
      },
      Cell: rowData => {
        const { facilities } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {!isEmpty(facilities) ? facilities.length : '0'}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'sensors',
      Header: '침하센서',
      headerProps: { className: 'text-900', style: { width: '100px' } },
      cellProps: {
        style: { width: '100px' }
      },
      Cell: rowData => {
        const { sensors } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {!isEmpty(sensors) ? sensors.length : '0'}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'status',
      Header: '상태',
      headerProps: { className: 'text-900', style: { width: '100px' } },
      cellProps: {
        style: { width: '100px' }
      },
      Cell: rowData => {
        const { status } = rowData.row.original;
        let badgeColor = 'dark';
        let badgeName = '등급확인필요';
        if (status === 'BEFORE') {
          badgeName = '예정';
          badgeColor = 'secondary';
        }
        if (status === 'FINISH') {
          badgeName = '완료';
          badgeColor = 'success';
        }
        if (status === 'ON_ISSUE') {
          badgeName = '점검요함';
          badgeColor = 'danger';
        }
        if (status === 'NO_ISSUE') {
          badgeName = '진행중';
          badgeColor = 'primary';
        }
        return (
          <SoftBadge bg={badgeColor} className="fs--2">
            {badgeName}
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'properties.PJADDRESS',
      Header: '주소',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { properties } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-start flex-1 text-800'}>
              {properties.PJADDRESS}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'properties.PJORDER',
      Header: '발주사',
      headerProps: { className: 'text-900', style: { width: '180px' } },
      cellProps: {
        style: { width: '180px' }
      },
      Cell: rowData => {
        const { properties } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {properties.PJORDER}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'properties.PJMANAGER',
      Header: '관리부서',
      headerProps: { className: 'text-900', style: { width: '180px' } },
      cellProps: {
        style: { width: '180px' }
      },
      Cell: rowData => {
        const { properties } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {properties.PJMANAGER}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'properties.PJCONSTRUCTOR',
      Header: '관리업체',
      headerProps: { className: 'text-900', style: { width: '180px' } },
      cellProps: {
        style: { width: '180px' }
      },
      Cell: rowData => {
        const { properties } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {properties.PJCONSTRUCTOR}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'initDate',
      Header: '관리시작일',
      headerProps: { className: 'text-900', style: { width: '150px' } },
      cellProps: {
        style: { width: '150px' }
      },
      Cell: rowData => {
        const { initDate } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {initDate}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'endDate',
      Header: '관리종료일',
      headerProps: { className: 'text-900', style: { width: '150px' } },
      cellProps: {
        style: { width: '150px' }
      },
      Cell: rowData => {
        const { endDate } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {endDate}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'createDate',
      Header: '등록일',
      // headerProps: { className: 'text-900', style: { width: '150px' } },
      // cellProps: {
      //   style: { width: '150px' }
      // },
      // Cell: rowData => {
      //   const { createDate } = rowData.row.original;
      //   return (
      //     <Flex direction={'column'} className={'w-100'} alignItems={'center'}>
      //       <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
      //         {moment(createDate).format('YYYY-MM-DD')}
      //       </div>
      //       <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
      //         {moment(createDate).format('HH:mm:ss')}
      //       </div>
      //     </Flex>
      //   );
      // }
      headerProps: { className: 'text-900', style: { width: '140px' } },
      cellProps: {
        style: { width: '140px' }
      },
      Cell: rowData => {
        const { createDate } = rowData.row.original;
        return (
          <Flex direction={'column'} className={'w-100'} alignItems={'center'}>
            <div className={'fs--1'} style={{ width: '140px' }}>
              {moment(createDate).format('YYYY-MM-DD HH:mm:ss')}
            </div>
          </Flex>
        );
      }
    }
  ];

  const navigate = useNavigate();

  const [projectInfo, setProjectInfo] = useState([]);

  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const _pageSize = 10;

  const {
    config: { isNavbarVerticalCollapsed },
    setConfig
  } = useContext(AppContext);
  const { setSelectedProject, setSelectedRegion, setSendRegion } =
    useContext(MapsContext);

  const coursesNavbarVerticalCollapsed = useRef(isNavbarVerticalCollapsed);

  useEffect(() => {
    setConfig('isNavbarVerticalCollapsed', false);
    setSelectedProject({});
    setSelectedRegion({});
    setSendRegion({});

    return () => {
      setConfig(
        'isNavbarVerticalCollapsed',
        coursesNavbarVerticalCollapsed.current
      );
    };
  }, []);

  useEffect(() => {
    // getProjectStatus();
    getProjectInfo();
  }, []);

  useEffect(() => {
    getProjectInfo();
  }, [currentPage]);

  const getProjectInfo = () => {
    let url =
      '/api/projects?pageNo=' +
      (currentPage - 1) +
      '&pageSize=' +
      _pageSize +
      '&sort=name%24asc';
    chainAxios
      .get(url)
      .then(response => {
        // console.log('Project getProjectInfo() : ', response);
        if (response.data.resultCode === 200) {
          let totalElements = response.data.data.projectList.totalElements;
          setTotalPages(Math.ceil(totalElements / _pageSize));
          setTotalElements(totalElements);
          setProjectInfo(response.data.data.projectList.dataList);
        } else {
          setTotalPages(1);
          setTotalElements(0);
          setProjectInfo([]);
        }
      })
      .catch(error => {
        console.log('Project getProjectInfo() : ', error);
      });
  };

  const goToProjectDetail = projectId => {
    navigate('/management-target/projects/' + projectId);
  };

  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={12}>
          <Card className="h-100">
            <Card.Header>
              <h5>프로젝트</h5>
            </Card.Header>
            <Card.Body>
              <Flex
                alignItems="center"
                direction="row"
                justifyContent="between"
                className={'px-3 py-3'}
                style={{ backgroundColor: getColor('gray-100') }}
              >
                <Flex
                  alignItems="center"
                  direction="row"
                  justifyContent="start"
                >
                  {/*<Form.Select size="sm" className="me-3">*/}
                  {/*  <option value={'status_all'}>상태 전체</option>*/}
                  {/*  /!*{projectInfo.map((project, index) => (*!/*/}
                  {/*  /!*  <option value={project.id}>{project.name}</option>*!/*/}
                  {/*  /!*))}*!/*/}
                  {/*  <option value={'BEFORE'}>예정</option>*/}
                  {/*  <option value={'FINISH'}>완료</option>*/}
                  {/*  <option value={'ON_ISSUE'}>점검요함</option>*/}
                  {/*  <option value={'NO_ISSUE'}>진행중</option>*/}
                  {/*</Form.Select>*/}
                </Flex>
                <Flex alignItems="center" direction="row" justifyContent="end">
                  <Flex
                    alignItems="center"
                    direction="row"
                    justifyContent="start"
                  >
                    <AdvanceTableSearchBox
                      table
                      globalFilter={''}
                      setGlobalFilter={() => {}}
                    />
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                alignItems="center"
                direction="row"
                justifyContent="between"
                className={'px-3 pt-3'}
              >
                <Flex
                  alignItems="end"
                  alignContent={'end'}
                  direction="column"
                  justifyContent="start"
                  className={'fs--1'}
                >
                  총 {totalElements}건
                </Flex>
                <Flex
                  alignItems={'end'}
                  alignContent={'end'}
                  direction="column"
                  justifyContent="end"
                  className={'fs--1'}
                >
                  <Form.Check
                    type="switch"
                    id="checkedSwitch"
                    label="즐겨찾기"
                    // defaultChecked
                  />
                </Flex>
              </Flex>
              <AdvanceTableWrapper
                columns={columns}
                data={projectInfo}
                sortable
                pagination
                perPage={_pageSize}
              >
                <AdvanceTable
                  table
                  headerClassName="text-nowrap align-middle text-center"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    hover: true,
                    className: 'fs--1 mb-0 overflow-hidden text-center'
                  }}
                />
              </AdvanceTableWrapper>
              {projectInfo.length !== _pageSize && (
                <div
                  style={{
                    height:
                      ((_pageSize - projectInfo.length) * 57.5).toString() +
                      'px'
                  }}
                ></div>
              )}
              <div className="mt-3">
                <ResponsivePagination
                  current={currentPage}
                  total={totalPages}
                  onPageChange={setCurrentPage}
                  maxWidth={350}
                  extraClassName={'justify-content-center fs--2'}
                  previousLabel="‹"
                  nextLabel="›"
                  // narrowBehaviour={combine(dropNav, dropEllipsis)}
                />
              </div>
            </Card.Body>
            <Card.Footer style={{ backgroundColor: getColor('gray-100') }}>
              <IconButton
                variant="falcon-default"
                icon="external-link-alt"
                transform="shrink-3"
                size="sm"
              >
                엑셀 내보내기
              </IconButton>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Project;
