import { XYZ } from 'ol/source';
import { Tile as TileLayer } from 'ol/layer';
import { sassFalse } from 'sass';

export const vworldBaseLayer = new TileLayer({
  source: new XYZ({
    url: 'https://api.vworld.kr/req/wmts/1.0.0/60C87CFF-D191-37F4-A177-CA536871409A/Base/{z}/{y}/{x}.png'
  }),
  title: 'base-vworld-base',
  visible: false,
  // properties: { name: 'base-vworld-base' },
  minZoom: 6,
  maxZoom: 19,
  zIndex: 2
  // preload: Infinity
});

export const vworldWhiteLayer = new TileLayer({
  source: new XYZ({
    url: 'https://api.vworld.kr/req/wmts/1.0.0/60C87CFF-D191-37F4-A177-CA536871409A/white/{z}/{y}/{x}.png'
  }),
  title: 'base-vworld-white',
  visible: false,
  minZoom: 6,
  maxZoom: 18,
  zIndex: 2
});

export const vworldMidnightLayer = new TileLayer({
  source: new XYZ({
    url: 'https://api.vworld.kr/req/wmts/1.0.0/60C87CFF-D191-37F4-A177-CA536871409A/midnight/{z}/{y}/{x}.png'
  }),
  title: 'base-vworld-midnight',
  visible: false,
  minZoom: 6,
  maxZoom: 18,
  zIndex: 2
});

export const vworldHybridLayer = new TileLayer({
  source: new XYZ({
    url: 'https://api.vworld.kr/req/wmts/1.0.0/60C87CFF-D191-37F4-A177-CA536871409A/Hybrid/{z}/{y}/{x}.png'
  }),
  title: 'base-vworld-hybrid',
  visible: false,
  minZoom: 6,
  maxZoom: 19,
  zIndex: 2
});

export const vworldSatelliteLayer = new TileLayer({
  source: new XYZ({
    url: 'https://api.vworld.kr/req/wmts/1.0.0/60C87CFF-D191-37F4-A177-CA536871409A/Satellite/{z}/{y}/{x}.jpeg'
  }),
  title: 'base-vworld-satellite',
  visible: true,
  minZoom: 6,
  maxZoom: 19,
  zIndex: 2
});
