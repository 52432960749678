import React, { useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { ChatContext } from 'context/Context';
import users from 'data/people';
import rawThreads from 'data/chat/threads';
import rawMessages from 'data/chat/messages';
import groups from 'data/chat/groups';
import { arrayReducer } from 'reducers/arrayReducer';
import { chatRoomReducer } from 'reducers/chatRoomReducer';
import { chainAxios } from 'helpers/chainAxios';

const ChatProvider = ({ children }) => {
  const [messages, messagesDispatch] = useReducer(arrayReducer, rawMessages);
  const [threads, threadsDispatch] = useReducer(arrayReducer, rawThreads);
  const [currentThread, setCurrentThread] = useState(threads[0]);
  const [textAreaInitialHeight, setTextAreaInitialHeight] = useState(32);
  const [activeThreadId, setActiveThreadId] = useState(threads[0].id);
  const [isOpenThreadInfo, setIsOpenThreadInfo] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(true);

  const [chatThreads, setChatThreads] = useState([]);
  const [chatRooms, chatRoomsDispatch] = useReducer(
    chatRoomReducer,
    chatThreads
  );
  // dkchoi
  const [isOpenChatRoom, setIsOpenChatRoom] = useState(false);
  const [isAllReadChat, setIsAllReadChat] = useState(true);
  const [currentChatRoom, setCurrentChatRoom] = useState({});

  const getChatRoom = chatRoomId => {
    // http://112.175.18.232:7000/api/chatroom/d7e47f3b-4307-4e1a-bcdd-d3722eeaf0c1?includeMember=true
    let url = '/api/chatroom/' + chatRoomId + '?includeMember=true';
    chainAxios
      .get(url)
      .then(response => {
        // console.log('ChatProvider getChatRoom() : ', response);
        if (response.data.resultCode === 200) {
          // console.log(
          //   'ChatProvider getChatRoom() : ',
          //   response.data.data.chatroom
          // );

          setCurrentChatRoom({ ...response.data.data.chatroom });
          window.sessionStorage.setItem(
            'currentChatRoomId',
            response.data.data.chatroom.id
          );
          setIsOpenChatRoom(true);
        }
      })
      .catch(error => {
        console.log('ChatProvider getChatRoom() : ', error);
      });
  };

  const getUser = thread => {
    let user = {};
    if (thread.type === 'group') {
      const { name, members } = groups.find(({ id }) => id === thread.groupId);
      user = {
        name,
        avatarSrc: members.map(
          member => users.find(({ id }) => id === member.userId).avatarSrc
        )
      };
    } else {
      user = users.find(({ id }) => id === thread.userId);
    }
    return user;
  };

  const value = {
    users,
    groups,
    threads,
    getUser,
    messages,
    activeThreadId,
    setActiveThreadId,
    threadsDispatch,
    messagesDispatch,
    textAreaInitialHeight,
    setTextAreaInitialHeight,
    isOpenThreadInfo,
    setIsOpenThreadInfo,
    currentThread,
    setCurrentThread,
    scrollToBottom,
    setScrollToBottom,
    chatThreads,
    setChatThreads,
    chatRooms,
    chatRoomsDispatch,
    currentChatRoom,
    setCurrentChatRoom,
    isOpenChatRoom,
    setIsOpenChatRoom,
    isAllReadChat,
    setIsAllReadChat,
    getChatRoom
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

ChatProvider.propTypes = { children: PropTypes.node.isRequired };

export default ChatProvider;
