import React, { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { getFacilities, getProjects } from '../api';
import { getFacilityStatus } from '../../../dashboard/api';

export function useLeftAreaProjects() {
  const [selectedTabValue, setSelectedTabValue] = useState('ALL');
  const [searchText, setSearchText] = useState('');
  const [favorite, setFavorite] = useState(false);

  const filteredTabValue = useCallback((data, value) => {
    let resultData = [];
    if (value === 'ALL') {
      resultData = data;
    }
    if (value === 'NO_ISSUE') {
      resultData = data.filter(project => project.status === 'NO_ISSUE');
    }
    if (value === 'ON_ISSUE') {
      resultData = data.filter(project => project.status === 'ON_ISSUE');
    }
    if (value === 'BEFORE') {
      resultData = data.filter(project => project.status === 'BEFORE');
    }
    if (value === 'FINISH') {
      resultData = data.filter(project => project.status === 'FINISH');
    }

    if (favorite) {
      resultData = data.filter(project => project.favorite);
    }

    if (searchText !== '') {
      return resultData.filter(project => project.name.includes(searchText));
    } else {
      return resultData;
    }
  }, []);

  const selectFilterTabValue = useCallback(
    unfilteredData => filteredTabValue(unfilteredData, selectedTabValue),
    [filteredTabValue, selectedTabValue]
  );

  // staleTime : fresh의 유효기간
  // cacheTime: 캐시의 유효기간
  const { data = [] } = useQuery(
    ['getProjects', searchText],
    () => getProjects(searchText),
    {
      select: selectFilterTabValue,
      staleTime: 1000,
      cacheTime: 30000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchInterval: false
    }
  );

  return {
    data,
    selectedTabValue,
    setSelectedTabValue,
    searchText,
    setSearchText,
    favorite,
    setFavorite
  };
}

export function useFacilities() {
  // staleTime : fresh의 유효기간
  // cacheTime: 캐시의 유효기간
  const {
    data = {},
    isLoading,
    isFetching
  } = useQuery(['getFacilities'], () => getFacilities(), {
    staleTime: 0,
    cacheTime: 10000,
    refetchInterval: 10000,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  return {
    data,
    isLoading,
    isFetching
  };
}
