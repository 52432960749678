import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Tab,
  Nav,
  Table
} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FaInfoCircle } from 'react-icons/fa';
import React, { useState } from 'react';
import FalconCardHeader from 'components/common/FalconCardHeader';
import GEOASOS_ML20240731_F1 from 'data/maps/kintex/img/GEOASOS_ML20240731_F1.png';
import GEOASOS_ML20240731_AUC from 'data/maps/kintex/img/GEOASOS_ML20240731_AUC.png';
import GEOEQK_ML2024073_AUC from 'data/maps/kintex/img/GEOEQK_ML2024073_AUC.png';
import GEOEQK_ML2024073_F1 from 'data/maps/kintex/img/GEOEQK_ML2024073_F1.png';
import GEONCW_ML20240731_AUC from 'data/maps/kintex/img/GEONCW_ML20240731_AUC.png';
import GEONCW_ML20240731_F1 from 'data/maps/kintex/img/GEONCW_ML20240731_F1.png';
import AISummaryStatisticsChart from '../region-detail/chart/AISummaryStatisticsChart';
import AccidentStatusChart from '../region-detail/chart/AccidentStatusChart';

const RegionDetail = ({
  regionInfo,
  detailModalShow,
  detailModalShowEvent
}) => {
  const [infoModalShow, setInfoModalShow] = useState(false);
  const [selectedInfo, setSelectedInfo] = useState({});

  // 퍼센트지로 변경
  const formatPercentage = decimal => {
    return (decimal * 100).toFixed(2) + '%';
  };

  const weatherInfo = {
    title: '날씨 AI 정보',
    modelName: 'GEOASOS',
    modelVersion: 'ML20240731',
    factors: '18',
    learn: '1',
    performance: [
      {
        title: 'train',
        auc: '1.0000',
        f1Score: '0.9930',
        all: formatPercentage(0.9993),
        occur: formatPercentage(1.0),
        notOccur: formatPercentage(0.9993)
      },
      {
        title: 'test',
        auc: '0.9764',
        f1Score: '0.7967',
        all: formatPercentage(0.9831),
        occur: formatPercentage(0.7156),
        notOccur: formatPercentage(0.9961)
      }
    ],
    image: [GEOASOS_ML20240731_AUC, GEOASOS_ML20240731_F1]
  };

  const underWaterInfo = {
    title: '지하수 AI 정보',
    modelName: 'GEONCW',
    modelVersion: 'ML20240731',
    factors: '5',
    learn: '1',
    performance: [
      {
        title: 'train',
        auc: '0.9996',
        f1Score: '0.9154',
        all: formatPercentage(0.995),
        occur: formatPercentage(0.9944),
        notOccur: formatPercentage(0.995)
      },
      {
        title: 'test',
        auc: '0.9849',
        f1Score: '0.7645',
        all: formatPercentage(0.9878),
        occur: formatPercentage(0.8058),
        notOccur: formatPercentage(0.9924)
      }
    ],
    image: [GEONCW_ML20240731_AUC, GEONCW_ML20240731_F1]
  };

  const earthquakeInfo = {
    title: '지진 AI 정보',
    modelName: 'GEOEQK',
    modelVersion: 'ML20240731',
    factors: '3',
    learn: '1',
    performance: [
      {
        title: 'train',
        auc: '0.9898',
        f1Score: '0.8712',
        all: formatPercentage(0.9522),
        occur: formatPercentage(0.9861),
        notOccur: formatPercentage(0.9455)
      },
      {
        title: 'test',
        auc: '0.7810',
        f1Score: '0.5432',
        all: formatPercentage(0.8053),
        occur: formatPercentage(0.6875),
        notOccur: formatPercentage(0.8291)
      }
    ],
    image: [GEOEQK_ML2024073_AUC, GEOEQK_ML2024073_F1]
  };

  const facilityInfo = {
    title: '건물 AI 정보',
    modelName: 'GEOBLD',
    modelVersion: 'ML20240731',
    factors: '10',
    learn: '6',
    performance: [
      {
        title: 'train',
        auc: '해당없음',
        f1Score: '해당없음',
        all: '해당없음',
        occur: '해당없음',
        notOccur: '해당없음'
      },
      {
        title: 'test',
        auc: '해당없음',
        f1Score: '해당없음',
        all: '해당없음',
        occur: '해당없음',
        notOccur: '해당없음'
      }
    ],
    image: []
  };

  const handleInfoClick = info => {
    setSelectedInfo(info);
    setInfoModalShow(true);
  };

  console.log('region : ', regionInfo);
  return (
    <>
      <Modal
        size="xl"
        show={detailModalShow}
        onHide={detailModalShowEvent}
        backdrop="static"
        keyboard={false}
        centered
      >
        <div className="custom-modal-background">
          <Modal.Header closeButton>
            <Flex direction="column">
              <Modal.Title id="example-custom-modal-styling-title">
                {regionInfo.name} 지반 침하 정보
              </Modal.Title>
            </Flex>
          </Modal.Header>
          <Modal.Body>
            {/* 카드 리스트 섹션 */}
            <div className="p-3 mb-3 bg-white rounded shadow-sm custom-card-section">
              <Row className="justify-content-center g-3">
                <Col md={2} className="text-center">
                  <h6 className="text-secondary mb-2 small-text">지역 코드</h6>
                  <Card className="d-flex align-items-center justify-content-center p-3 bg-soft-info border-0 shadow-sm rounded custom-card">
                    <p className="mb-0 fw-bold large-text d-flex align-items-center">
                      {regionInfo.rgnCode}
                    </p>
                  </Card>
                </Col>
                <Col md={2} className="text-center">
                  <h6 className="text-secondary mb-2 small-text">지역 면적</h6>
                  <Card className="d-flex align-items-center justify-content-center p-3 bg-soft-info border-0 shadow-sm rounded custom-card">
                    <p className="mb-0 fw-bold large-text d-flex align-items-center">
                      {(regionInfo.area / 1000000).toFixed(2).toString() +
                        ' km²'}
                    </p>
                  </Card>
                </Col>
                <Col md={2} className="text-center">
                  <h6 className="text-secondary mb-2 small-text">
                    날씨 AI 버전
                  </h6>
                  <Card className="d-flex align-items-center justify-content-center p-3 bg-soft-info border-0 shadow-sm rounded custom-card">
                    <p className="mb-0 fw-bold large-text d-flex align-items-center">
                      {regionInfo.weatherAi}
                      <FaInfoCircle
                        size={12}
                        className="ms-1 text-primary cursor-pointer"
                        onClick={() => handleInfoClick(weatherInfo)}
                      />
                    </p>
                  </Card>
                </Col>
                <Col md={2} className="text-center">
                  <h6 className="text-secondary mb-2 small-text">
                    지하수 AI 버전
                  </h6>
                  <Card className="d-flex align-items-center justify-content-center p-3 bg-soft-info border-0 shadow-sm rounded custom-card">
                    <p className="mb-0 fw-bold large-text d-flex align-items-center">
                      {regionInfo.underWaterAi}
                      <FaInfoCircle
                        size={12}
                        className="ms-1 text-primary cursor-pointer"
                        onClick={() => handleInfoClick(underWaterInfo)}
                      />
                    </p>
                  </Card>
                </Col>
                <Col md={2} className="text-center">
                  <h6 className="text-secondary mb-2 small-text">
                    지진 AI 버전
                  </h6>
                  <Card className="d-flex align-items-center justify-content-center p-3 bg-soft-info border-0 shadow-sm rounded custom-card">
                    <p className="mb-0 fw-bold large-text d-flex align-items-center">
                      {regionInfo.earthquakeAi}
                      <FaInfoCircle
                        size={12}
                        className="ms-1 text-primary cursor-pointer"
                        onClick={() => handleInfoClick(earthquakeInfo)}
                      />
                    </p>
                  </Card>
                </Col>
                <Col md={2} className="text-center">
                  <h6 className="text-secondary mb-2 small-text">
                    건물 AI 버전
                  </h6>
                  <Card className="d-flex align-items-center justify-content-center p-3 bg-soft-info border-0 shadow-sm rounded custom-card">
                    <p className="mb-0 fw-bold large-text d-flex align-items-center">
                      {regionInfo.facilityAi}
                      <FaInfoCircle
                        size={12}
                        className="ms-1 text-primary cursor-pointer"
                        onClick={() => handleInfoClick(facilityInfo)}
                      />
                    </p>
                  </Card>
                </Col>
              </Row>
            </div>

            {/* 탭 메뉴와 콘텐츠 섹션 */}
            <div className="p-3 custom-tab-section">
              <h5 className="mb-3">침하 분석 분석·예측 결과</h5>
              <Tab.Container defaultActiveKey="accidentStatus">
                <Row>
                  {/* 왼쪽 탭 메뉴 */}
                  <Col md={3} className="bg-light p-0">
                    <Nav
                      variant="pills"
                      className="flex-column nav-tabs border-0"
                    >
                      <Nav.Item>
                        <Nav.Link
                          eventKey="accidentStatus"
                          className="py-3 px-4"
                        >
                          <h6 className="mb-0">사고 발생 현황</h6>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="aiSummaryStatistics"
                          className="py-3 px-4"
                        >
                          <h6 className="mb-0">AI 요약 통계</h6>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>

                  {/* 오른쪽 탭 콘텐츠 */}
                  <Col md={9}>
                    <Tab.Content
                      className="bg-white rounded shadow-sm"
                      style={{ height: '400px' }}
                    >
                      <Tab.Pane eventKey="accidentStatus">
                        <div className="bg-light py-3 px-4 mb-3">
                          <h6 className="mb-0">사고 발생 현황</h6>
                        </div>
                        <AccidentStatusChart />
                      </Tab.Pane>
                      <Tab.Pane eventKey="aiSummaryStatistics">
                        <div className="bg-light py-3 px-4 mb-3">
                          <h6 className="mb-0">AI 요약 통계</h6>
                        </div>
                        <AISummaryStatisticsChart />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={detailModalShowEvent}>
              닫기
            </Button>
          </Modal.Footer>
        </div>
      </Modal>

      {/* 추가 정보 모달 */}
      <Modal
        size="xl"
        backdrop="static"
        keyboard={false}
        show={infoModalShow}
        onHide={() => setInfoModalShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            {selectedInfo.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="bg-white rounded shadow-sm custom-card-section">
            <Row className="justify-content-center g-3 mb-2">
              <Col md={5}>
                <Card className="h-md-100">
                  <FalconCardHeader title="기본정보" light titleTag="h6" />
                  <Card.Body>
                    <Row>
                      <Col md={6} sm={12} className="text-center mb-2">
                        <Card className="d-flex align-items-center justify-content-center p-2 bg-soft-info border-0 shadow-sm rounded custom-card">
                          <p className="mb-0 fw-bold large-text d-flex align-items-center">
                            <Row>
                              <Col md={12} className="text-center">
                                <h6>모델명</h6>
                              </Col>
                              <Col md={12} className="text-center">
                                <h6>{selectedInfo.modelName}</h6>
                              </Col>
                            </Row>
                          </p>
                        </Card>
                      </Col>
                      <Col md={6} sm={12} className="text-center mb-2">
                        <Card className="d-flex align-items-center justify-content-center p-2 bg-soft-info border-0 shadow-sm rounded custom-card">
                          <p className="mb-0 fw-bold large-text d-flex align-items-center">
                            <Row>
                              <Col md={12} className="text-center">
                                <h6>모델버전</h6>
                              </Col>
                              <Col md={12} className="text-center">
                                <h6>{selectedInfo.modelVersion}</h6>
                              </Col>
                            </Row>
                          </p>
                        </Card>
                      </Col>
                      <Col md={6} sm={12} className="text-center">
                        <Card className="d-flex align-items-center justify-content-center p-2 bg-soft-info border-0 shadow-sm rounded custom-card">
                          <p className="mb-0 fw-bold large-text d-flex align-items-center">
                            <Row>
                              <Col md={12} className="text-center">
                                <h6>고려인자수</h6>
                              </Col>
                              <Col md={12} className="text-center">
                                <h6>{selectedInfo.factors}</h6>
                              </Col>
                            </Row>
                          </p>
                        </Card>
                      </Col>
                      <Col md={6} sm={12} className="text-center">
                        <Card className="d-flex align-items-center justify-content-center p-2 bg-soft-info border-0 shadow-sm rounded custom-card">
                          <p className="mb-0 fw-bold large-text d-flex align-items-center">
                            <Row>
                              <Col md={12} className="text-center">
                                <h6>업데이트주기</h6>
                              </Col>
                              <Col md={12} className="text-center">
                                <h6>{selectedInfo.learn}개월</h6>
                              </Col>
                            </Row>
                          </p>
                        </Card>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={7}>
                <Card className="h-md-100">
                  {selectedInfo.title === '건물 AI 정보' ? (
                    <FalconCardHeader
                      title="성능지표(해당없음)"
                      light
                      titleTag="h6"
                    />
                  ) : (
                    <FalconCardHeader title="성능지표" light titleTag="h6" />
                  )}
                  <Card.Body>
                    <Table hover responsive>
                      <thead className="text-center">
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">AUC</th>
                          <th scope="col">F1-score</th>
                          <th scope="col">전체 정확도</th>
                          <th scope="col">발생 정확도</th>
                          <th scope="col">미발생 정확도</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {selectedInfo &&
                          selectedInfo.performance &&
                          selectedInfo.performance.map((data, index) => (
                            <tr className="hover-actions-trigger">
                              <td>{data.title}</td>
                              <td>{data.auc}</td>
                              <td>{data.f1Score}</td>
                              <td>{data.all}</td>
                              <td>{data.occur}</td>
                              <td>{data.notOccur}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            {selectedInfo && selectedInfo.modelName !== 'GEOBLD' && (
              <Row className="justify-content-center g-3">
                <Col xs={12}>
                  <Card className="h-md-100">
                    <FalconCardHeader title="성능지표" light titleTag="h6" />
                    <Card.Body>
                      <Row>
                        {selectedInfo &&
                          selectedInfo.image &&
                          selectedInfo.image.map((img, index) => (
                            <Col sm={12} md={6}>
                              <img
                                key={index}
                                src={img}
                                alt="설명"
                                style={{ objectFit: 'cover', width: '100%' }}
                              />
                            </Col>
                          ))}
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setInfoModalShow(false)}>
            닫기
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RegionDetail;
