import classNames from 'classnames';
import Flex from '../../Flex';
import Avatar from '../../Avatar';
import Form from 'react-bootstrap/Form';
import { Nav } from 'react-bootstrap';
import React, { useState } from 'react';

const UserItem = ({ user, checkItemHandler }) => {
  // console.log(user);
  const [checked, setChecked] = useState(false); // 체크 여부 판단
  const checkHandled = selectedUser => {
    checkItemHandler(selectedUser, !checked);
    setChecked(!checked);
  };

  return (
    <Flex direction={'row'}>
      <Nav.Link
        // eventKey={chatRoom.id}
        className={classNames(`chat-contact hover-actions-trigger p-3 me-1`, {
          'unread-message': false,
          'read-message': true
        })}
        onClick={() => checkHandled(user)}
      >
        <Flex direction={'row'}>
          <Avatar
            size="xl"
            isExact={true}
            // name={
            //   !!chatRoom.project ? chatRoom.project.value.substring(0, 1) : '-'
            // }
            name={user.name.substring(0, 1)}
            className="me-1"
          />
          <div className="flex-1 chat-contact-body ms-2 d-lg-block">
            <Flex justifyContent="between">
              <h6 className="mb-0 chat-contact-title">
                {user.name} / {user.company}
              </h6>
            </Flex>
            <div className="min-w-0">
              <div className="chat-contact-content pe-3">
                <div className="chat-contact-content fs--2">{user.email}</div>
              </div>
            </div>
          </div>
        </Flex>
      </Nav.Link>
      <Form.Check
        type="checkbox"
        id={user.userId}
        aria-label="check"
        className={'pt-3 pe-3'}
        checked={checked}
        onChange={e => checkHandled(user)}
      />
    </Flex>
  );
};

export default UserItem;
