import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { getColor } from 'helpers/utils';
import IconButton from 'components/common/IconButton';
import React, { useEffect, useRef, useState } from 'react';
import { Feature, Map, View } from 'ol';
import { defaults } from 'ol/control';
import { fromLonLat } from 'ol/proj';
import { Group, Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { XYZ } from 'ol/source';
import { Fill, Icon, Style, Text } from 'ol/style';
import mapMarker from '../../../../../assets/img/icons/map-marker.png';
import VectorSource from 'ol/source/Vector';
import { Point } from 'ol/geom';
import SensorMap from '../../SensorMap';
import SensorDetail from '../../SensorDetail';

const SensorBaseInfo = ({ sensor }) => {
  return (
    <Card>
      <Card.Header>
        <h6>센서</h6>
      </Card.Header>
      <Card.Body style={{ backgroundColor: getColor('gray-100') }}>
        <Row xs={12}>
          <Col md={3} xl={3}>
            <SensorMap sensor={sensor} />
          </Col>
          <Col md={9} xl={9} className={'px-4'}>
            <SensorDetail sensor={sensor} />
          </Col>
        </Row>
      </Card.Body>
      {/*<Card.Footer*/}
      {/*  as={Flex}*/}
      {/*  justifyContent="end"*/}
      {/*  style={{ backgroundColor: getColor('gray-100') }}*/}
      {/*>*/}
      {/*  /!*<IconButton variant="falcon-default" transform="shrink-3" size="sm">*!/*/}
      {/*  /!*  수정*!/*/}
      {/*  /!*</IconButton>*!/*/}
      {/*  <IconButton variant="falcon-default" transform="shrink-3" size="sm">*/}
      {/*    목록*/}
      {/*  </IconButton>*/}
      {/*</Card.Footer>*/}
    </Card>
  );
};

export default SensorBaseInfo;
