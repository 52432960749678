export const asosStnGeoData = {
  type: 'FeatureCollection',
  name: 'asosstngeo',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      id: 0,
      properties: {
        TM: '2024-10-25',
        STN: '90',
        LON: '128.56473000',
        LAT: '38.25085000',
        HT: '17.53',
        HT_PA: '18.73',
        HT_TA: '1.70',
        HT_WD: '10.00',
        HT_RN: '1.40',
        STN_KO: '속초',
        STN_EN: 'Sokcho',
        LAW_ID: '5182033035'
      },
      geometry: { type: 'Point', coordinates: [128.56473, 38.25085] }
    },
    {
      type: 'Feature',
      id: 1,
      properties: {
        TM: '2024-10-25',
        STN: '93',
        LON: '127.75443000',
        LAT: '37.94738000',
        HT: '95.78',
        HT_PA: '96.78',
        HT_TA: '1.50',
        HT_WD: '10.00',
        HT_RN: '1.40',
        STN_KO: '북춘천',
        STN_EN: 'Bukchuncheon',
        LAW_ID: '5111025024'
      },
      geometry: { type: 'Point', coordinates: [127.75443, 37.94738] }
    },
    {
      type: 'Feature',
      id: 2,
      properties: {
        TM: '2024-10-25',
        STN: '95',
        LON: '127.30420000',
        LAT: '38.14787000',
        HT: '155.48',
        HT_PA: '156.98',
        HT_TA: '1.80',
        HT_WD: '13.00',
        HT_RN: '1.50',
        STN_KO: '철원',
        STN_EN: 'Cheorwon',
        LAW_ID: '5178025624'
      },
      geometry: { type: 'Point', coordinates: [127.3042, 38.14787] }
    },
    {
      type: 'Feature',
      id: 3,
      properties: {
        TM: '2024-10-25',
        STN: '98',
        LON: '127.06070000',
        LAT: '37.90188000',
        HT: '115.62',
        HT_PA: '116.74',
        HT_TA: '1.70',
        HT_WD: '10.00',
        HT_RN: '1.00',
        STN_KO: '동두천',
        STN_EN: 'Dongducheon',
        LAW_ID: '4125010300'
      },
      geometry: { type: 'Point', coordinates: [127.0607, 37.90188] }
    },
    {
      type: 'Feature',
      id: 4,
      properties: {
        TM: '2024-10-25',
        STN: '99',
        LON: '126.76648000',
        LAT: '37.88589000',
        HT: '30.59',
        HT_PA: '31.99',
        HT_TA: '1.70',
        HT_WD: '10.00',
        HT_RN: '1.00',
        STN_KO: '파주',
        STN_EN: 'Paju',
        LAW_ID: '4148025025'
      },
      geometry: { type: 'Point', coordinates: [126.76648, 37.88589] }
    },
    {
      type: 'Feature',
      id: 5,
      properties: {
        TM: '2024-10-25',
        STN: '100',
        LON: '128.71834000',
        LAT: '37.67713000',
        HT: '772.43',
        HT_PA: '773.43',
        HT_TA: '1.70',
        HT_WD: '10.00',
        HT_RN: '1.40',
        STN_KO: '대관령',
        STN_EN: 'Daegwallyeong',
        LAW_ID: '5176038024'
      },
      geometry: { type: 'Point', coordinates: [128.71834, 37.67713] }
    },
    {
      type: 'Feature',
      id: 6,
      properties: {
        TM: '2024-10-25',
        STN: '101',
        LON: '127.73570000',
        LAT: '37.90262000',
        HT: '75.82',
        HT_PA: '77.05',
        HT_TA: '1.50',
        HT_WD: '10.00',
        HT_RN: '1.40',
        STN_KO: '춘천',
        STN_EN: 'Chuncheon',
        LAW_ID: '5111011800'
      },
      geometry: { type: 'Point', coordinates: [127.7357, 37.90262] }
    },
    {
      type: 'Feature',
      id: 7,
      properties: {
        TM: '2024-10-25',
        STN: '102',
        LON: '124.71237000',
        LAT: '37.97396000',
        HT: '36.00',
        HT_PA: '37.20',
        HT_TA: '1.80',
        HT_WD: '9.00',
        HT_RN: '1.20',
        STN_KO: '백령도',
        STN_EN: 'Baengnyeongdo',
        LAW_ID: '2872033021'
      },
      geometry: { type: 'Point', coordinates: [124.71237, 37.97396] }
    },
    {
      type: 'Feature',
      id: 8,
      properties: {
        TM: '2024-10-25',
        STN: '104',
        LON: '128.85535000',
        LAT: '37.80456000',
        HT: '75.24',
        HT_PA: '76.67',
        HT_TA: '1.70',
        HT_WD: '10.00',
        HT_RN: '1.40',
        STN_KO: '북강릉',
        STN_EN: 'Bukgangneung',
        LAW_ID: '5115036027'
      },
      geometry: { type: 'Point', coordinates: [128.85535, 37.80456] }
    },
    {
      type: 'Feature',
      id: 9,
      properties: {
        TM: '2024-10-25',
        STN: '105',
        LON: '128.89099000',
        LAT: '37.75147000',
        HT: '27.12',
        HT_PA: '28.22',
        HT_TA: '1.70',
        HT_WD: '10.00',
        HT_RN: '0.50',
        STN_KO: '강릉',
        STN_EN: 'Gangneung',
        LAW_ID: '5115010700'
      },
      geometry: { type: 'Point', coordinates: [128.89099, 37.75147] }
    },
    {
      type: 'Feature',
      id: 10,
      properties: {
        TM: '2024-10-25',
        STN: '106',
        LON: '129.12433000',
        LAT: '37.50709000',
        HT: '40.46',
        HT_PA: '41.66',
        HT_TA: '1.70',
        HT_WD: '10.00',
        HT_RN: '1.40',
        STN_KO: '동해',
        STN_EN: 'Donghae',
        LAW_ID: '5117010400'
      },
      geometry: { type: 'Point', coordinates: [129.12433, 37.50709] }
    },
    {
      type: 'Feature',
      id: 11,
      properties: {
        TM: '2024-10-25',
        STN: '108',
        LON: '126.96580000',
        LAT: '37.57142000',
        HT: '85.67',
        HT_PA: '86.67',
        HT_TA: '1.50',
        HT_WD: '10.00',
        HT_RN: '0.50',
        STN_KO: '서울',
        STN_EN: 'Seoul',
        LAW_ID: '1111017800'
      },
      geometry: { type: 'Point', coordinates: [126.9658, 37.57142] }
    },
    {
      type: 'Feature',
      id: 12,
      properties: {
        TM: '2024-10-25',
        STN: '112',
        LON: '126.62490000',
        LAT: '37.47772000',
        HT: '68.99',
        HT_PA: '70.19',
        HT_TA: '1.60',
        HT_WD: '10.00',
        HT_RN: '1.20',
        STN_KO: '인천',
        STN_EN: 'Incheon',
        LAW_ID: '2811013700'
      },
      geometry: { type: 'Point', coordinates: [126.6249, 37.47772] }
    },
    {
      type: 'Feature',
      id: 13,
      properties: {
        TM: '2024-10-25',
        STN: '114',
        LON: '127.94659000',
        LAT: '37.33749000',
        HT: '150.11',
        HT_PA: '151.11',
        HT_TA: '1.70',
        HT_WD: '14.00',
        HT_RN: '1.40',
        STN_KO: '원주',
        STN_EN: 'Wonju',
        LAW_ID: '5113010600'
      },
      geometry: { type: 'Point', coordinates: [127.94659, 37.33749] }
    },
    {
      type: 'Feature',
      id: 14,
      properties: {
        TM: '2024-10-25',
        STN: '115',
        LON: '130.89863000',
        LAT: '37.48129000',
        HT: '221.14',
        HT_PA: '223.70',
        HT_TA: '1.60',
        HT_WD: '10.00',
        HT_RN: '1.30',
        STN_KO: '울릉도',
        STN_EN: 'Ulleungdo',
        LAW_ID: '4794025024'
      },
      geometry: { type: 'Point', coordinates: [130.89863, 37.48129] }
    },
    {
      type: 'Feature',
      id: 15,
      properties: {
        TM: '2024-10-25',
        STN: '119',
        LON: '126.98300000',
        LAT: '37.25746000',
        HT: '39.81',
        HT_PA: '40.81',
        HT_TA: '1.60',
        HT_WD: '18.70',
        HT_RN: '1.10',
        STN_KO: '수원',
        STN_EN: 'Suwon',
        LAW_ID: '4111312800'
      },
      geometry: { type: 'Point', coordinates: [126.983, 37.25746] }
    },
    {
      type: 'Feature',
      id: 16,
      properties: {
        TM: '2024-10-25',
        STN: '121',
        LON: '128.45743000',
        LAT: '37.18126000',
        HT: '240.54',
        HT_PA: '242.05',
        HT_TA: '1.70',
        HT_WD: '10.00',
        HT_RN: '1.40',
        STN_KO: '영월',
        STN_EN: 'Yeongwol',
        LAW_ID: '5175025022'
      },
      geometry: { type: 'Point', coordinates: [128.45743, 37.18126] }
    },
    {
      type: 'Feature',
      id: 17,
      properties: {
        TM: '2024-10-25',
        STN: '127',
        LON: '127.95250000',
        LAT: '36.97045000',
        HT: '114.85',
        HT_PA: '116.25',
        HT_TA: '1.65',
        HT_WD: '10.00',
        HT_RN: '1.00',
        STN_KO: '충주',
        STN_EN: 'Chungju',
        LAW_ID: '4313012200'
      },
      geometry: { type: 'Point', coordinates: [127.9525, 36.97045] }
    },
    {
      type: 'Feature',
      id: 18,
      properties: {
        TM: '2024-10-25',
        STN: '129',
        LON: '126.49390000',
        LAT: '36.77658000',
        HT: '25.25',
        HT_PA: '26.55',
        HT_TA: '1.60',
        HT_WD: '20.20',
        HT_RN: '1.10',
        STN_KO: '서산',
        STN_EN: 'Seosan',
        LAW_ID: '4421010600'
      },
      geometry: { type: 'Point', coordinates: [126.4939, 36.77658] }
    },
    {
      type: 'Feature',
      id: 19,
      properties: {
        TM: '2024-10-25',
        STN: '130',
        LON: '129.41278000',
        LAT: '36.99176000',
        HT: '48.98',
        HT_PA: '50.18',
        HT_TA: '1.75',
        HT_WD: '10.00',
        HT_RN: '1.30',
        STN_KO: '울진',
        STN_EN: 'Uljin',
        LAW_ID: '4793025023'
      },
      geometry: { type: 'Point', coordinates: [129.41278, 36.99176] }
    },
    {
      type: 'Feature',
      id: 20,
      properties: {
        TM: '2024-10-25',
        STN: '131',
        LON: '127.44066000',
        LAT: '36.63924000',
        HT: '58.70',
        HT_PA: '60.10',
        HT_TA: '1.70',
        HT_WD: '10.00',
        HT_RN: '0.80',
        STN_KO: '청주',
        STN_EN: 'Cheongju',
        LAW_ID: '4311311400'
      },
      geometry: { type: 'Point', coordinates: [127.44066, 36.63924] }
    },
    {
      type: 'Feature',
      id: 21,
      properties: {
        TM: '2024-10-25',
        STN: '133',
        LON: '127.37210000',
        LAT: '36.37199000',
        HT: '67.79',
        HT_PA: '68.49',
        HT_TA: '1.40',
        HT_WD: '23.70',
        HT_RN: '1.00',
        STN_KO: '대전',
        STN_EN: 'Daejeon',
        LAW_ID: '3020012400'
      },
      geometry: { type: 'Point', coordinates: [127.3721, 36.37199] }
    },
    {
      type: 'Feature',
      id: 22,
      properties: {
        TM: '2024-10-25',
        STN: '135',
        LON: '127.99458000',
        LAT: '36.22025000',
        HT: '244.98',
        HT_PA: '246.18',
        HT_TA: '1.60',
        HT_WD: '10.00',
        HT_RN: '1.10',
        STN_KO: '추풍령',
        STN_EN: 'Chupungnyeong',
        LAW_ID: '4374033521'
      },
      geometry: { type: 'Point', coordinates: [127.99458, 36.22025] }
    },
    {
      type: 'Feature',
      id: 23,
      properties: {
        TM: '2024-10-25',
        STN: '136',
        LON: '128.70733000',
        LAT: '36.57293000',
        HT: '141.26',
        HT_PA: '142.76',
        HT_TA: '1.80',
        HT_WD: '10.00',
        HT_RN: '1.30',
        STN_KO: '안동',
        STN_EN: 'Andong',
        LAW_ID: '4717012900'
      },
      geometry: { type: 'Point', coordinates: [128.70733, 36.57293] }
    },
    {
      type: 'Feature',
      id: 24,
      properties: {
        TM: '2024-10-25',
        STN: '137',
        LON: '128.15741000',
        LAT: '36.40837000',
        HT: '96.58',
        HT_PA: '97.98',
        HT_TA: '1.80',
        HT_WD: '10.00',
        HT_RN: '1.40',
        STN_KO: '상주',
        STN_EN: 'Sangju',
        LAW_ID: '4725011000'
      },
      geometry: { type: 'Point', coordinates: [128.15741, 36.40837] }
    },
    {
      type: 'Feature',
      id: 25,
      properties: {
        TM: '2024-10-25',
        STN: '138',
        LON: '129.38002000',
        LAT: '36.03201000',
        HT: '3.94',
        HT_PA: '4.92',
        HT_TA: '1.60',
        HT_WD: '10.00',
        HT_RN: '1.20',
        STN_KO: '포항',
        STN_EN: 'Pohang',
        LAW_ID: '4711110400'
      },
      geometry: { type: 'Point', coordinates: [129.38002, 36.03201] }
    },
    {
      type: 'Feature',
      id: 26,
      properties: {
        TM: '2024-10-25',
        STN: '140',
        LON: '126.76135000',
        LAT: '36.00530000',
        HT: '27.85',
        HT_PA: '24.60',
        HT_TA: '1.70',
        HT_WD: '10.00',
        HT_RN: '1.00',
        STN_KO: '군산',
        STN_EN: 'Gunsan',
        LAW_ID: '5213010300'
      },
      geometry: { type: 'Point', coordinates: [126.76135, 36.0053] }
    },
    {
      type: 'Feature',
      id: 27,
      properties: {
        TM: '2024-10-25',
        STN: '143',
        LON: '128.65296000',
        LAT: '35.87797000',
        HT: '54.27',
        HT_PA: '55.52',
        HT_TA: '1.80',
        HT_WD: '10.00',
        HT_RN: '0.60',
        STN_KO: '대구',
        STN_EN: 'Daegu',
        LAW_ID: '2714010300'
      },
      geometry: { type: 'Point', coordinates: [128.65296, 35.87797] }
    },
    {
      type: 'Feature',
      id: 28,
      properties: {
        TM: '2024-10-25',
        STN: '146',
        LON: '127.11718000',
        LAT: '35.84092000',
        HT: '60.44',
        HT_PA: '62.90',
        HT_TA: '1.60',
        HT_WD: '10.00',
        HT_RN: '1.40',
        STN_KO: '전주',
        STN_EN: 'Jeonju',
        LAW_ID: '5211310600'
      },
      geometry: { type: 'Point', coordinates: [127.11718, 35.84092] }
    },
    {
      type: 'Feature',
      id: 29,
      properties: {
        TM: '2024-10-25',
        STN: '152',
        LON: '129.33469000',
        LAT: '35.58237000',
        HT: '81.14',
        HT_PA: '83.20',
        HT_TA: '1.80',
        HT_WD: '10.00',
        HT_RN: '1.30',
        STN_KO: '울산',
        STN_EN: 'Ulsan',
        LAW_ID: '3111011700'
      },
      geometry: { type: 'Point', coordinates: [129.33469, 35.58237] }
    },
    {
      type: 'Feature',
      id: 30,
      properties: {
        TM: '2024-10-25',
        STN: '155',
        LON: '128.57282000',
        LAT: '35.17019000',
        HT: '34.97',
        HT_PA: '39.10',
        HT_TA: '1.70',
        HT_WD: '10.00',
        HT_RN: '1.30',
        STN_KO: '창원',
        STN_EN: 'Changwon',
        LAW_ID: '4812510100'
      },
      geometry: { type: 'Point', coordinates: [128.57282, 35.17019] }
    },
    {
      type: 'Feature',
      id: 31,
      properties: {
        TM: '2024-10-25',
        STN: '156',
        LON: '126.89156000',
        LAT: '35.17294000',
        HT: '70.28',
        HT_PA: '73.90',
        HT_TA: '1.70',
        HT_WD: '17.50',
        HT_RN: '0.80',
        STN_KO: '광주',
        STN_EN: 'Gwangju',
        LAW_ID: '2917010900'
      },
      geometry: { type: 'Point', coordinates: [126.89156, 35.17294] }
    },
    {
      type: 'Feature',
      id: 32,
      properties: {
        TM: '2024-10-25',
        STN: '159',
        LON: '129.03203000',
        LAT: '35.10468000',
        HT: '69.56',
        HT_PA: '70.90',
        HT_TA: '1.50',
        HT_WD: '18.00',
        HT_RN: '1.30',
        STN_KO: '부산',
        STN_EN: 'Busan',
        LAW_ID: '2611011600'
      },
      geometry: { type: 'Point', coordinates: [129.03203, 35.10468] }
    },
    {
      type: 'Feature',
      id: 33,
      properties: {
        TM: '2024-10-25',
        STN: '162',
        LON: '128.43561000',
        LAT: '34.84541000',
        HT: '31.24',
        HT_PA: '33.20',
        HT_TA: '1.50',
        HT_WD: '18.00',
        HT_RN: '1.30',
        STN_KO: '통영',
        STN_EN: 'Tongyeong',
        LAW_ID: '4822010900'
      },
      geometry: { type: 'Point', coordinates: [128.43561, 34.84541] }
    },
    {
      type: 'Feature',
      id: 34,
      properties: {
        TM: '2024-10-25',
        STN: '165',
        LON: '126.38151000',
        LAT: '34.81732000',
        HT: '44.70',
        HT_PA: '39.20',
        HT_TA: '1.50',
        HT_WD: '14.50',
        HT_RN: '1.00',
        STN_KO: '목포',
        STN_EN: 'Mokpo',
        LAW_ID: '4611010300'
      },
      geometry: { type: 'Point', coordinates: [126.38151, 34.81732] }
    },
    {
      type: 'Feature',
      id: 35,
      properties: {
        TM: '2024-10-25',
        STN: '168',
        LON: '127.74063000',
        LAT: '34.73929000',
        HT: '65.93',
        HT_PA: '66.10',
        HT_TA: '1.50',
        HT_WD: '13.00',
        HT_RN: '0.60',
        STN_KO: '여수',
        STN_EN: 'Yeosu',
        LAW_ID: '4613010700'
      },
      geometry: { type: 'Point', coordinates: [127.74063, 34.73929] }
    },
    {
      type: 'Feature',
      id: 36,
      properties: {
        TM: '2024-10-25',
        STN: '169',
        LON: '125.45105000',
        LAT: '34.68719000',
        HT: '75.12',
        HT_PA: '78.00',
        HT_TA: '1.50',
        HT_WD: '15.50',
        HT_RN: '0.60',
        STN_KO: '흑산도',
        STN_EN: 'Heuksando',
        LAW_ID: '4691036022'
      },
      geometry: { type: 'Point', coordinates: [125.45105, 34.68719] }
    },
    {
      type: 'Feature',
      id: 37,
      properties: {
        TM: '2024-10-25',
        STN: '170',
        LON: '126.70182000',
        LAT: '34.39590000',
        HT: '35.37',
        HT_PA: '36.70',
        HT_TA: '1.50',
        HT_WD: '10.00',
        HT_RN: '0.80',
        STN_KO: '완도',
        STN_EN: 'Wando',
        LAW_ID: '4689031022'
      },
      geometry: { type: 'Point', coordinates: [126.70182, 34.3959] }
    },
    {
      type: 'Feature',
      id: 38,
      properties: {
        TM: '2024-10-25',
        STN: '172',
        LON: '126.59900000',
        LAT: '35.34824000',
        HT: '52.42',
        HT_PA: '53.50',
        HT_TA: '1.80',
        HT_WD: '10.00',
        HT_RN: '1.70',
        STN_KO: '고창',
        STN_EN: 'Gochang',
        LAW_ID: '5279038025'
      },
      geometry: { type: 'Point', coordinates: [126.599, 35.34824] }
    },
    {
      type: 'Feature',
      id: 39,
      properties: {
        TM: '2024-10-25',
        STN: '174',
        LON: '127.36940000',
        LAT: '35.02040000',
        HT: '165.00',
        HT_PA: '166.50',
        HT_TA: '1.50',
        HT_WD: '10.00',
        HT_RN: '0.80',
        STN_KO: '순천',
        STN_EN: 'Suncheon',
        LAW_ID: '4615025025'
      },
      geometry: { type: 'Point', coordinates: [127.3694, 35.0204] }
    },
    {
      type: 'Feature',
      id: 40,
      properties: {
        TM: '2024-10-25',
        STN: '177',
        LON: '126.68772000',
        LAT: '36.65759000',
        HT: '27.74',
        HT_PA: '28.70',
        HT_TA: '1.50',
        HT_WD: '10.00',
        HT_RN: '1.50',
        STN_KO: '홍성',
        STN_EN: 'Hongseong',
        LAW_ID: '4480025625'
      },
      geometry: { type: 'Point', coordinates: [126.68772, 36.65759] }
    },
    {
      type: 'Feature',
      id: 41,
      properties: {
        TM: '2024-10-25',
        STN: '181',
        LON: '127.38466000',
        LAT: '36.64002000',
        HT: '32.00',
        HT_PA: '32.00',
        HT_TA: '1.50',
        HT_WD: '10.00',
        HT_RN: '0.50',
        STN_KO: '서청주',
        STN_EN: 'Seocheongju',
        LAW_ID: '4311331035'
      },
      geometry: { type: 'Point', coordinates: [127.38466, 36.64002] }
    },
    {
      type: 'Feature',
      id: 42,
      properties: {
        TM: '2024-10-25',
        STN: '184',
        LON: '126.52969000',
        LAT: '33.51411000',
        HT: '20.79',
        HT_PA: '21.99',
        HT_TA: '1.70',
        HT_WD: '15.00',
        HT_RN: '1.50',
        STN_KO: '제주',
        STN_EN: 'Jeju',
        LAW_ID: '5011010700'
      },
      geometry: { type: 'Point', coordinates: [126.52969, 33.51411] }
    },
    {
      type: 'Feature',
      id: 43,
      properties: {
        TM: '2024-10-25',
        STN: '185',
        LON: '126.16283000',
        LAT: '33.29382000',
        HT: '71.39',
        HT_PA: '72.49',
        HT_TA: '1.70',
        HT_WD: '10.00',
        HT_RN: '1.50',
        STN_KO: '고산',
        STN_EN: 'Gosan',
        LAW_ID: '5011031023'
      },
      geometry: { type: 'Point', coordinates: [126.16283, 33.29382] }
    },
    {
      type: 'Feature',
      id: 44,
      properties: {
        TM: '2024-10-25',
        STN: '188',
        LON: '126.88020000',
        LAT: '33.38677000',
        HT: '20.34',
        HT_PA: '21.74',
        HT_TA: '1.70',
        HT_WD: '10.00',
        HT_RN: '1.50',
        STN_KO: '성산',
        STN_EN: 'Seongsan',
        LAW_ID: '5013025927'
      },
      geometry: { type: 'Point', coordinates: [126.8802, 33.38677] }
    },
    {
      type: 'Feature',
      id: 45,
      properties: {
        TM: '2024-10-25',
        STN: '189',
        LON: '126.56530000',
        LAT: '33.24616000',
        HT: '51.86',
        HT_PA: '53.16',
        HT_TA: '1.70',
        HT_WD: '10.00',
        HT_RN: '1.50',
        STN_KO: '서귀포',
        STN_EN: 'Seogwipo',
        LAW_ID: '5013010100'
      },
      geometry: { type: 'Point', coordinates: [126.5653, 33.24616] }
    },
    {
      type: 'Feature',
      id: 46,
      properties: {
        TM: '2024-10-25',
        STN: '192',
        LON: '128.04004000',
        LAT: '35.16378000',
        HT: '29.35',
        HT_PA: '31.60',
        HT_TA: '1.80',
        HT_WD: '10.00',
        HT_RN: '1.30',
        STN_KO: '진주',
        STN_EN: 'Jinju',
        LAW_ID: '4817012600'
      },
      geometry: { type: 'Point', coordinates: [128.04004, 35.16378] }
    },
    {
      type: 'Feature',
      id: 47,
      properties: {
        TM: '2024-10-25',
        STN: '201',
        LON: '126.44634000',
        LAT: '37.70739000',
        HT: '47.84',
        HT_PA: '49.24',
        HT_TA: '1.60',
        HT_WD: '10.00',
        HT_RN: '0.60',
        STN_KO: '강화',
        STN_EN: 'Ganghwa',
        LAW_ID: '2871032027'
      },
      geometry: { type: 'Point', coordinates: [126.44634, 37.70739] }
    },
    {
      type: 'Feature',
      id: 48,
      properties: {
        TM: '2024-10-25',
        STN: '202',
        LON: '127.49446000',
        LAT: '37.48863000',
        HT: '47.26',
        HT_PA: '48.76',
        HT_TA: '1.90',
        HT_WD: '10.00',
        HT_RN: '0.60',
        STN_KO: '양평',
        STN_EN: 'Yangpyeong',
        LAW_ID: '4183025021'
      },
      geometry: { type: 'Point', coordinates: [127.49446, 37.48863] }
    },
    {
      type: 'Feature',
      id: 49,
      properties: {
        TM: '2024-10-25',
        STN: '203',
        LON: '127.48421000',
        LAT: '37.26399000',
        HT: '80.09',
        HT_PA: '81.59',
        HT_TA: '1.80',
        HT_WD: '10.00',
        HT_RN: '0.60',
        STN_KO: '이천',
        STN_EN: 'Icheon',
        LAW_ID: '4150025328'
      },
      geometry: { type: 'Point', coordinates: [127.48421, 37.26399] }
    },
    {
      type: 'Feature',
      id: 50,
      properties: {
        TM: '2024-10-25',
        STN: '211',
        LON: '128.16714000',
        LAT: '38.05986000',
        HT: '201.78',
        HT_PA: '202.78',
        HT_TA: '1.50',
        HT_WD: '10.00',
        HT_RN: '0.50',
        STN_KO: '인제',
        STN_EN: 'Inje',
        LAW_ID: '5181025022'
      },
      geometry: { type: 'Point', coordinates: [128.16714, 38.05986] }
    },
    {
      type: 'Feature',
      id: 51,
      properties: {
        TM: '2024-10-25',
        STN: '212',
        LON: '127.88043000',
        LAT: '37.68360000',
        HT: '140.20',
        HT_PA: '141.20',
        HT_TA: '1.60',
        HT_WD: '13.00',
        HT_RN: '0.50',
        STN_KO: '홍천',
        STN_EN: 'Hongcheon',
        LAW_ID: '5172025029'
      },
      geometry: { type: 'Point', coordinates: [127.88043, 37.6836] }
    },
    {
      type: 'Feature',
      id: 52,
      properties: {
        TM: '2024-10-25',
        STN: '216',
        LON: '128.98929000',
        LAT: '37.17038000',
        HT: '714.45',
        HT_PA: '715.25',
        HT_TA: '1.60',
        HT_WD: '16.00',
        HT_RN: '0.60',
        STN_KO: '태백',
        STN_EN: 'Taebaek',
        LAW_ID: '5119010100'
      },
      geometry: { type: 'Point', coordinates: [128.98929, 37.17038] }
    },
    {
      type: 'Feature',
      id: 53,
      properties: {
        TM: '2024-10-25',
        STN: '217',
        LON: '128.67348000',
        LAT: '37.37732000',
        HT: '312.00',
        HT_PA: '312.00',
        HT_TA: '1.50',
        HT_WD: '10.00',
        HT_RN: '0.50',
        STN_KO: '정선군',
        STN_EN: 'Jeongseon',
        LAW_ID: '11D10502'
      },
      geometry: { type: 'Point', coordinates: [128.67348, 37.37732] }
    },
    {
      type: 'Feature',
      id: 54,
      properties: {
        TM: '2024-10-25',
        STN: '221',
        LON: '128.19433000',
        LAT: '37.15928000',
        HT: '264.62',
        HT_PA: '265.39',
        HT_TA: '1.50',
        HT_WD: '10.00',
        HT_RN: '0.50',
        STN_KO: '제천',
        STN_EN: 'Jecheon',
        LAW_ID: '4315011200'
      },
      geometry: { type: 'Point', coordinates: [128.19433, 37.15928] }
    },
    {
      type: 'Feature',
      id: 55,
      properties: {
        TM: '2024-10-25',
        STN: '226',
        LON: '127.73415000',
        LAT: '36.48761000',
        HT: '171.31',
        HT_PA: '172.71',
        HT_TA: '1.50',
        HT_WD: '10.00',
        HT_RN: '0.50',
        STN_KO: '보은',
        STN_EN: 'Boeun',
        LAW_ID: '4372025030'
      },
      geometry: { type: 'Point', coordinates: [127.73415, 36.48761] }
    },
    {
      type: 'Feature',
      id: 56,
      properties: {
        TM: '2024-10-25',
        STN: '232',
        LON: '127.29282000',
        LAT: '36.76217000',
        HT: '84.78',
        HT_PA: '83.00',
        HT_TA: '1.80',
        HT_WD: '10.00',
        HT_RN: '1.20',
        STN_KO: '천안',
        STN_EN: 'Cheonan',
        LAW_ID: '4413136022'
      },
      geometry: { type: 'Point', coordinates: [127.29282, 36.76217] }
    },
    {
      type: 'Feature',
      id: 57,
      properties: {
        TM: '2024-10-25',
        STN: '235',
        LON: '126.55744000',
        LAT: '36.32724000',
        HT: '9.98',
        HT_PA: '17.00',
        HT_TA: '1.60',
        HT_WD: '10.00',
        HT_RN: '1.10',
        STN_KO: '보령',
        STN_EN: 'Boryeong',
        LAW_ID: '4418010900'
      },
      geometry: { type: 'Point', coordinates: [126.55744, 36.32724] }
    },
    {
      type: 'Feature',
      id: 58,
      properties: {
        TM: '2024-10-25',
        STN: '236',
        LON: '126.92079000',
        LAT: '36.27242000',
        HT: '13.42',
        HT_PA: '14.88',
        HT_TA: '1.70',
        HT_WD: '10.00',
        HT_RN: '0.50',
        STN_KO: '부여',
        STN_EN: 'Buyeo',
        LAW_ID: '4476025033'
      },
      geometry: { type: 'Point', coordinates: [126.92079, 36.27242] }
    },
    {
      type: 'Feature',
      id: 59,
      properties: {
        TM: '2024-10-25',
        STN: '238',
        LON: '127.48175000',
        LAT: '36.10563000',
        HT: '172.69',
        HT_PA: '173.99',
        HT_TA: '1.50',
        HT_WD: '10.00',
        HT_RN: '0.50',
        STN_KO: '금산',
        STN_EN: 'Geumsan',
        LAW_ID: '4471025025'
      },
      geometry: { type: 'Point', coordinates: [127.48175, 36.10563] }
    },
    {
      type: 'Feature',
      id: 60,
      properties: {
        TM: '2024-10-25',
        STN: '239',
        LON: '127.24438000',
        LAT: '36.48522000',
        HT: '89.50',
        HT_PA: '95.00',
        HT_TA: '1.60',
        HT_WD: '10.00',
        HT_RN: '1.00',
        STN_KO: '세종',
        STN_EN: 'sejong',
        LAW_ID: '3611010800'
      },
      geometry: { type: 'Point', coordinates: [127.24438, 36.48522] }
    },
    {
      type: 'Feature',
      id: 61,
      properties: {
        TM: '2024-10-25',
        STN: '243',
        LON: '126.71657000',
        LAT: '35.72961000',
        HT: '12.20',
        HT_PA: '13.50',
        HT_TA: '1.80',
        HT_WD: '10.00',
        HT_RN: '0.60',
        STN_KO: '부안',
        STN_EN: 'Buan',
        LAW_ID: '5280033021'
      },
      geometry: { type: 'Point', coordinates: [126.71657, 35.72961] }
    },
    {
      type: 'Feature',
      id: 62,
      properties: {
        TM: '2024-10-25',
        STN: '244',
        LON: '127.28556000',
        LAT: '35.61203000',
        HT: '247.04',
        HT_PA: '248.44',
        HT_TA: '1.80',
        HT_WD: '10.00',
        HT_RN: '0.60',
        STN_KO: '임실',
        STN_EN: 'Imsil',
        LAW_ID: '5275025022'
      },
      geometry: { type: 'Point', coordinates: [127.28556, 35.61203] }
    },
    {
      type: 'Feature',
      id: 63,
      properties: {
        TM: '2024-10-25',
        STN: '245',
        LON: '126.83904000',
        LAT: '35.56337000',
        HT: '68.70',
        HT_PA: '70.97',
        HT_TA: '1.90',
        HT_WD: '10.00',
        HT_RN: '1.00',
        STN_KO: '정읍',
        STN_EN: 'Jeongeup',
        LAW_ID: '5218010800'
      },
      geometry: { type: 'Point', coordinates: [126.83904, 35.56337] }
    },
    {
      type: 'Feature',
      id: 64,
      properties: {
        TM: '2024-10-25',
        STN: '247',
        LON: '127.39652000',
        LAT: '35.42130000',
        HT: '133.49',
        HT_PA: '91.80',
        HT_TA: '1.70',
        HT_WD: '10.00',
        HT_RN: '0.70',
        STN_KO: '남원',
        STN_EN: 'Namwon',
        LAW_ID: '5219011600'
      },
      geometry: { type: 'Point', coordinates: [127.39652, 35.4213] }
    },
    {
      type: 'Feature',
      id: 65,
      properties: {
        TM: '2024-10-25',
        STN: '248',
        LON: '127.52031000',
        LAT: '35.65696000',
        HT: '406.87',
        HT_PA: '408.00',
        HT_TA: '1.60',
        HT_WD: '10.00',
        HT_RN: '0.50',
        STN_KO: '장수',
        STN_EN: 'Jangsu',
        LAW_ID: '5274025023'
      },
      geometry: { type: 'Point', coordinates: [127.52031, 35.65696] }
    },
    {
      type: 'Feature',
      id: 66,
      properties: {
        TM: '2024-10-25',
        STN: '251',
        LON: '126.69700000',
        LAT: '35.42661000',
        HT: '58.84',
        HT_PA: '55.50',
        HT_TA: '1.70',
        HT_WD: '10.00',
        HT_RN: '0.60',
        STN_KO: '고창군',
        STN_EN: 'Gochanggun',
        LAW_ID: '5279025030'
      },
      geometry: { type: 'Point', coordinates: [126.697, 35.42661] }
    },
    {
      type: 'Feature',
      id: 67,
      properties: {
        TM: '2024-10-25',
        STN: '252',
        LON: '126.47784000',
        LAT: '35.28366000',
        HT: '37.20',
        HT_PA: '38.70',
        HT_TA: '1.50',
        HT_WD: '13.00',
        HT_RN: '0.60',
        STN_KO: '영광군',
        STN_EN: 'Yeonggwanggun',
        LAW_ID: '4687034030'
      },
      geometry: { type: 'Point', coordinates: [126.47784, 35.28366] }
    },
    {
      type: 'Feature',
      id: 68,
      properties: {
        TM: '2024-10-25',
        STN: '253',
        LON: '128.89075000',
        LAT: '35.22981000',
        HT: '54.59',
        HT_PA: '54.60',
        HT_TA: '1.70',
        HT_WD: '10.00',
        HT_RN: '0.50',
        STN_KO: '김해시',
        STN_EN: 'Gimhaesi',
        LAW_ID: '4825010300'
      },
      geometry: { type: 'Point', coordinates: [128.89075, 35.22981] }
    },
    {
      type: 'Feature',
      id: 69,
      properties: {
        TM: '2024-10-25',
        STN: '254',
        LON: '127.12860000',
        LAT: '35.37131000',
        HT: '129.38',
        HT_PA: '128.40',
        HT_TA: '1.60',
        HT_WD: '10.00',
        HT_RN: '0.60',
        STN_KO: '순창군',
        STN_EN: 'Sunchanggun',
        LAW_ID: '5277025026'
      },
      geometry: { type: 'Point', coordinates: [127.1286, 35.37131] }
    },
    {
      type: 'Feature',
      id: 70,
      properties: {
        TM: '2024-10-25',
        STN: '255',
        LON: '128.67260000',
        LAT: '35.22655000',
        HT: '50.95',
        HT_PA: '50.30',
        HT_TA: '1.80',
        HT_WD: '10.00',
        HT_RN: '0.70',
        STN_KO: '북창원',
        STN_EN: 'Bukchangwon',
        LAW_ID: '4812311100'
      },
      geometry: { type: 'Point', coordinates: [128.6726, 35.22655] }
    },
    {
      type: 'Feature',
      id: 71,
      properties: {
        TM: '2024-10-25',
        STN: '257',
        LON: '129.02009000',
        LAT: '35.30737000',
        HT: '6.29',
        HT_PA: '16.10',
        HT_TA: '1.80',
        HT_WD: '10.00',
        HT_RN: '0.60',
        STN_KO: '양산시',
        STN_EN: 'Yangsansi',
        LAW_ID: '4833031027'
      },
      geometry: { type: 'Point', coordinates: [129.02009, 35.30737] }
    },
    {
      type: 'Feature',
      id: 72,
      properties: {
        TM: '2024-10-25',
        STN: '258',
        LON: '127.21226000',
        LAT: '34.76335000',
        HT: '1.41',
        HT_PA: '4.30',
        HT_TA: '1.50',
        HT_WD: '10.00',
        HT_RN: '0.60',
        STN_KO: '보성군',
        STN_EN: 'Boseonggun',
        LAW_ID: '4678038023'
      },
      geometry: { type: 'Point', coordinates: [127.21226, 34.76335] }
    },
    {
      type: 'Feature',
      id: 73,
      properties: {
        TM: '2024-10-25',
        STN: '259',
        LON: '126.78408000',
        LAT: '34.64457000',
        HT: '16.00',
        HT_PA: '20.00',
        HT_TA: '1.50',
        HT_WD: '10.00',
        HT_RN: '0.60',
        STN_KO: '강진군',
        STN_EN: 'Gangjin',
        LAW_ID: '11F20303'
      },
      geometry: { type: 'Point', coordinates: [126.78408, 34.64457] }
    },
    {
      type: 'Feature',
      id: 74,
      properties: {
        TM: '2024-10-25',
        STN: '260',
        LON: '126.91951000',
        LAT: '34.68886000',
        HT: '43.99',
        HT_PA: '46.30',
        HT_TA: '1.50',
        HT_WD: '10.00',
        HT_RN: '0.80',
        STN_KO: '장흥',
        STN_EN: 'Jangheung',
        LAW_ID: '4680025043'
      },
      geometry: { type: 'Point', coordinates: [126.91951, 34.68886] }
    },
    {
      type: 'Feature',
      id: 75,
      properties: {
        TM: '2024-10-25',
        STN: '261',
        LON: '126.56907000',
        LAT: '34.55375000',
        HT: '16.36',
        HT_PA: '17.91',
        HT_TA: '1.50',
        HT_WD: '10.00',
        HT_RN: '0.80',
        STN_KO: '해남',
        STN_EN: 'Haenam',
        LAW_ID: '4682025034'
      },
      geometry: { type: 'Point', coordinates: [126.56907, 34.55375] }
    },
    {
      type: 'Feature',
      id: 76,
      properties: {
        TM: '2024-10-25',
        STN: '262',
        LON: '127.27572000',
        LAT: '34.61826000',
        HT: '51.91',
        HT_PA: '53.31',
        HT_TA: '1.50',
        HT_WD: '10.00',
        HT_RN: '0.80',
        STN_KO: '고흥',
        STN_EN: 'Goheung',
        LAW_ID: '4677025029'
      },
      geometry: { type: 'Point', coordinates: [127.27572, 34.61826] }
    },
    {
      type: 'Feature',
      id: 77,
      properties: {
        TM: '2024-10-25',
        STN: '263',
        LON: '128.28812000',
        LAT: '35.32258000',
        HT: '14.10',
        HT_PA: '14.90',
        HT_TA: '2.00',
        HT_WD: '10.00',
        HT_RN: '0.60',
        STN_KO: '의령군',
        STN_EN: 'Uiryeonggun',
        LAW_ID: '4872025024'
      },
      geometry: { type: 'Point', coordinates: [128.28812, 35.32258] }
    },
    {
      type: 'Feature',
      id: 78,
      properties: {
        TM: '2024-10-25',
        STN: '264',
        LON: '127.74538000',
        LAT: '35.51138000',
        HT: '152.07',
        HT_PA: '152.50',
        HT_TA: '1.80',
        HT_WD: '10.00',
        HT_RN: '0.60',
        STN_KO: '함양군',
        STN_EN: 'Hamyanggun',
        LAW_ID: '4887025022'
      },
      geometry: { type: 'Point', coordinates: [127.74538, 35.51138] }
    },
    {
      type: 'Feature',
      id: 79,
      properties: {
        TM: '2024-10-25',
        STN: '266',
        LON: '127.69140000',
        LAT: '34.94340000',
        HT: '88.21',
        HT_PA: '87.90',
        HT_TA: '1.70',
        HT_WD: '10.00',
        HT_RN: '0.60',
        STN_KO: '광양시',
        STN_EN: 'Gwangyangsi',
        LAW_ID: '4623010600'
      },
      geometry: { type: 'Point', coordinates: [127.6914, 34.9434] }
    },
    {
      type: 'Feature',
      id: 80,
      properties: {
        TM: '2024-10-25',
        STN: '268',
        LON: '126.25846000',
        LAT: '34.47296000',
        HT: '9.82',
        HT_PA: '6.90',
        HT_TA: '1.70',
        HT_WD: '10.00',
        HT_RN: '0.60',
        STN_KO: '진도군',
        STN_EN: 'Jindogun',
        LAW_ID: '4690025025'
      },
      geometry: { type: 'Point', coordinates: [126.25846, 34.47296] }
    },
    {
      type: 'Feature',
      id: 81,
      properties: {
        TM: '2024-10-25',
        STN: '271',
        LON: '128.91449000',
        LAT: '36.94361000',
        HT: '324.67',
        HT_PA: '326.22',
        HT_TA: '1.90',
        HT_WD: '10.00',
        HT_RN: '0.60',
        STN_KO: '봉화',
        STN_EN: 'Bonghwa',
        LAW_ID: '4792034021'
      },
      geometry: { type: 'Point', coordinates: [128.91449, 36.94361] }
    },
    {
      type: 'Feature',
      id: 82,
      properties: {
        TM: '2024-10-25',
        STN: '272',
        LON: '128.51687000',
        LAT: '36.87183000',
        HT: '211.32',
        HT_PA: '212.78',
        HT_TA: '1.91',
        HT_WD: '10.00',
        HT_RN: '0.58',
        STN_KO: '영주',
        STN_EN: 'Yeongju',
        LAW_ID: '4721025021'
      },
      geometry: { type: 'Point', coordinates: [128.51687, 36.87183] }
    },
    {
      type: 'Feature',
      id: 83,
      properties: {
        TM: '2024-10-25',
        STN: '273',
        LON: '128.14879000',
        LAT: '36.62727000',
        HT: '173.01',
        HT_PA: '174.51',
        HT_TA: '1.80',
        HT_WD: '10.00',
        HT_RN: '0.60',
        STN_KO: '문경',
        STN_EN: 'Mungyeong',
        LAW_ID: '4728010800'
      },
      geometry: { type: 'Point', coordinates: [128.14879, 36.62727] }
    },
    {
      type: 'Feature',
      id: 84,
      properties: {
        TM: '2024-10-25',
        STN: '276',
        LON: '129.04005000',
        LAT: '36.43510000',
        HT: '208.65',
        HT_PA: '210.20',
        HT_TA: '1.75',
        HT_WD: '10.00',
        HT_RN: '0.50',
        STN_KO: '청송군',
        STN_EN: 'Cheongsonggun',
        LAW_ID: '4775025000'
      },
      geometry: { type: 'Point', coordinates: [129.04005, 36.4351] }
    },
    {
      type: 'Feature',
      id: 85,
      properties: {
        TM: '2024-10-25',
        STN: '277',
        LON: '129.40926000',
        LAT: '36.53337000',
        HT: '40.71',
        HT_PA: '42.01',
        HT_TA: '1.70',
        HT_WD: '10.00',
        HT_RN: '0.60',
        STN_KO: '영덕',
        STN_EN: 'Yeongdeok',
        LAW_ID: '4777036030'
      },
      geometry: { type: 'Point', coordinates: [129.40926, 36.53337] }
    },
    {
      type: 'Feature',
      id: 86,
      properties: {
        TM: '2024-10-25',
        STN: '278',
        LON: '128.68864000',
        LAT: '36.35610000',
        HT: '81.44',
        HT_PA: '82.94',
        HT_TA: '1.70',
        HT_WD: '10.00',
        HT_RN: '0.60',
        STN_KO: '의성',
        STN_EN: 'Uiseong',
        LAW_ID: '4773025031'
      },
      geometry: { type: 'Point', coordinates: [128.68864, 36.3561] }
    },
    {
      type: 'Feature',
      id: 87,
      properties: {
        TM: '2024-10-25',
        STN: '279',
        LON: '128.32055000',
        LAT: '36.13055000',
        HT: '49.17',
        HT_PA: '50.67',
        HT_TA: '1.70',
        HT_WD: '10.00',
        HT_RN: '1.40',
        STN_KO: '구미',
        STN_EN: 'Gumi',
        LAW_ID: '4719010800'
      },
      geometry: { type: 'Point', coordinates: [128.32055, 36.13055] }
    },
    {
      type: 'Feature',
      id: 88,
      properties: {
        TM: '2024-10-25',
        STN: '281',
        LON: '128.95140000',
        LAT: '35.97742000',
        HT: '96.12',
        HT_PA: '97.62',
        HT_TA: '1.70',
        HT_WD: '10.00',
        HT_RN: '0.50',
        STN_KO: '영천',
        STN_EN: 'Yeongcheon',
        LAW_ID: '4723010200'
      },
      geometry: { type: 'Point', coordinates: [128.9514, 35.97742] }
    },
    {
      type: 'Feature',
      id: 89,
      properties: {
        TM: '2024-10-25',
        STN: '283',
        LON: '129.20090000',
        LAT: '35.81740000',
        HT: '40.13',
        HT_PA: '41.63',
        HT_TA: '1.67',
        HT_WD: '10.00',
        HT_RN: '0.59',
        STN_KO: '경주시',
        STN_EN: 'Gyeongjusi',
        LAW_ID: '4713011300'
      },
      geometry: { type: 'Point', coordinates: [129.2009, 35.8174] }
    },
    {
      type: 'Feature',
      id: 90,
      properties: {
        TM: '2024-10-25',
        STN: '284',
        LON: '127.90990000',
        LAT: '35.66739000',
        HT: '228.45',
        HT_PA: '227.30',
        HT_TA: '1.50',
        HT_WD: '10.00',
        HT_RN: '1.30',
        STN_KO: '거창',
        STN_EN: 'Geochang',
        LAW_ID: '4888025028'
      },
      geometry: { type: 'Point', coordinates: [127.9099, 35.66739] }
    },
    {
      type: 'Feature',
      id: 91,
      properties: {
        TM: '2024-10-25',
        STN: '285',
        LON: '128.16994000',
        LAT: '35.56505000',
        HT: '26.72',
        HT_PA: '33.20',
        HT_TA: '1.50',
        HT_WD: '10.00',
        HT_RN: '0.50',
        STN_KO: '합천',
        STN_EN: 'Hapcheon',
        LAW_ID: '4889025021'
      },
      geometry: { type: 'Point', coordinates: [128.16994, 35.56505] }
    },
    {
      type: 'Feature',
      id: 92,
      properties: {
        TM: '2024-10-25',
        STN: '288',
        LON: '128.74412000',
        LAT: '35.49147000',
        HT: '8.31',
        HT_PA: '12.50',
        HT_TA: '2.00',
        HT_WD: '10.00',
        HT_RN: '1.30',
        STN_KO: '밀양',
        STN_EN: 'Miryang',
        LAW_ID: '4827010200'
      },
      geometry: { type: 'Point', coordinates: [128.74412, 35.49147] }
    },
    {
      type: 'Feature',
      id: 93,
      properties: {
        TM: '2024-10-25',
        STN: '289',
        LON: '127.87910000',
        LAT: '35.41300000',
        HT: '138.22',
        HT_PA: '138.80',
        HT_TA: '1.60',
        HT_WD: '10.00',
        HT_RN: '0.60',
        STN_KO: '산청',
        STN_EN: 'Sancheong',
        LAW_ID: '4886025023'
      },
      geometry: { type: 'Point', coordinates: [127.8791, 35.413] }
    },
    {
      type: 'Feature',
      id: 94,
      properties: {
        TM: '2024-10-25',
        STN: '294',
        LON: '128.60459000',
        LAT: '34.88818000',
        HT: '44.83',
        HT_PA: '46.70',
        HT_TA: '1.70',
        HT_WD: '10.00',
        HT_RN: '0.60',
        STN_KO: '거제',
        STN_EN: 'Geoje',
        LAW_ID: '4831010800'
      },
      geometry: { type: 'Point', coordinates: [128.60459, 34.88818] }
    },
    {
      type: 'Feature',
      id: 95,
      properties: {
        TM: '2024-10-25',
        STN: '295',
        LON: '127.92641000',
        LAT: '34.81662000',
        HT: '45.71',
        HT_PA: '47.01',
        HT_TA: '1.80',
        HT_WD: '10.00',
        HT_RN: '0.80',
        STN_KO: '남해',
        STN_EN: 'Namhae',
        LAW_ID: '4884031021'
      },
      geometry: { type: 'Point', coordinates: [127.92641, 34.81662] }
    },
    {
      type: 'Feature',
      id: 96,
      properties: {
        TM: '2024-10-25',
        STN: '296',
        LON: '128.96024000',
        LAT: '35.21778000',
        HT: '3.00',
        HT_PA: '4.50',
        HT_TA: '1.50',
        HT_WD: '10.00',
        HT_RN: '0.70',
        STN_KO: '북부산',
        STN_EN: 'Bukbusan',
        LAW_ID: '2644010100'
      },
      geometry: { type: 'Point', coordinates: [128.96024, 35.21778] }
    }
  ]
};
