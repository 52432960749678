import React, { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { getBottomEvents } from '../api';

export function useBottomAreaEvents() {
  // staleTime : fresh의 유효기간
  // cacheTime: 캐시의 유효기간
  const { data = [] } = useQuery(['getBottomEvents'], () => getBottomEvents(), {
    staleTime: 5000,
    cacheTime: 300000,
    refetchOnMount: true,
    refetchOnReconnect: true
  });

  return {
    data
  };
}
