import {
  Button,
  Nav,
  Modal,
  OverlayTrigger,
  Tab,
  Tooltip
} from 'react-bootstrap';
import Flex from '../../Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import SimpleBarReact from 'simplebar-react';
import classNames from 'classnames';
import Avatar from 'components/common/Avatar';
import Form from 'react-bootstrap/Form';
import { chainAxios } from 'helpers/chainAxios';
import UserItem from './UserItem';
import { ChatContext } from 'context/Context';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';

const ChatAddUser = ({ ChatAddUserShow, Members, onHideEvent }) => {
  const [users, setUsers] = useState([]);
  const [checkItems, setCheckItems] = useState(new Set());
  const [sendPush, setSendPush] = useState(false);

  const { currentChatRoom, setCurrentChatRoom } = useContext(ChatContext);
  const queryClient = useQueryClient();

  useEffect(() => {
    getUsers();
  }, []);
  const getUsers = () => {
    // http://112.175.18.232:7000/api/users?pageNo=0&pageSize=99999&sort=createDate%24desc
    let url = '/api/users?pageNo=0&pageSize=99999&sort=name%24asc';
    chainAxios
      .get(url)
      .then(response => {
        // console.log('getUsers : ', response);
        if (response.data.resultCode === 200) {
          // console.log('getUsers : ', response.data.data.userList.dataList);
          exceptUsersData(response.data.data.userList.dataList);
        }
      })
      .catch(error => {
        console.log('getUsers ERROR : ', error);
      });
  };

  const exceptUsersData = users => {
    let filterUsers = [];

    users.forEach(user => {
      let result = Members.filter(member => member.memberId === user.id);
      if (result.length === 0) {
        filterUsers.push(user);
      }
    });

    setUsers(filterUsers);
  };

  const checkItemHandler = (selectedUser, isChecked) => {
    if (isChecked) {
      setCheckItems(prev => [...prev, selectedUser]);
    } else {
      setCheckItems(checkItems.filter(item => item.id !== selectedUser.id));
    }
  };

  const onClickInvite = () => {
    // console.log('onClickInvite chatroom : ', currentChatRoom);
    // console.log('onClickInvite member : ', checkItems);
    // console.log('onClickInvite members : ', Members);
    if (checkItems.size === 0) {
      toast.error(`선택 된 대화상태가 없습니다.`, {
        theme: 'colored'
      });
      return;
    }
    let paramUserIds = [];
    checkItems.forEach(item => {
      paramUserIds.push(item.userId);
    });
    // console.log('onClickInvite paramUserIds : ', paramUserIds);
    // http://112.175.18.232:7000/api/chatroom/be3a31e4-47c5-44ae-86db-abf3edc9cdca/member?userId=user%40geostr.co.kr&userId=goobh%40chainreum.com&userId=imgeun%40chainreum.com&sendPush=false
    let url =
      '/api/chatroom/' +
      currentChatRoom.id +
      '/member?userId=' +
      paramUserIds +
      '&sendPush=' +
      sendPush;
    // console.log('onClickInvite url : ', url);
    chainAxios
      .post(url)
      .then(response => {
        // console.log('onClickInvite : ', response);
        if (response.data.resultCode === 0) {
          toast.success(response.data.message, {
            theme: 'colored'
          });
          reloadCurrentChatroomAndChatrooms();
          // onHideEvent();
        }
      })
      .catch(error => {
        console.log('onClickInvite ERROR : ', error);
      });
  };

  const reloadCurrentChatroomAndChatrooms = () => {
    // http://112.175.18.232:7000/api/chatroom/235ad371-1609-493e-b9e4-a9f9c6ffb8e6?includeMember=true
    let url = '/api/chatroom/' + currentChatRoom.id + '?includeMember=true';
    // console.log('onClickInvite url : ', url);
    chainAxios
      .get(url)
      .then(response => {
        if (response.data.resultCode === 200) {
          queryClient.invalidateQueries([
            'getChatrooms',
            currentChatRoom.status
          ]);
          setCurrentChatRoom(response.data.data.chatroom);
          window.sessionStorage.setItem(
            'currentChatRoomId',
            response.data.data.chatroom.id
          );
          onHideEvent();
        }
      })
      .catch(error => {
        console.log('reloadCurrentChatroom ERROR : ', error);
      });
  };

  return (
    <>
      <Modal
        size="sm"
        show={ChatAddUserShow}
        onHide={onHideEvent}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            대화상대 초대하기
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!!Members && Members.length > 0 && (
            <Flex direction={'row'} className={'px-2 py-2'}>
              {Members.map((member, index) => (
                <Avatar
                  key={index}
                  size="xl"
                  isExact={true}
                  name={member.memberName.substring(0, 1)}
                  className="me-1 cursor-default"
                />
              ))}
            </Flex>
          )}
          <div className="contacts-list" style={{ border: 'none' }}>
            <SimpleBarReact
              style={{
                minHeight: '280px',
                maxHeight: '280px',
                minWidth: '65px'
              }}
            >
              <Nav className="border-200 border-bottom pb-4" activeKey="">
                {users.map((user, index) => (
                  <UserItem
                    key={index}
                    user={user}
                    checkItemHandler={checkItemHandler}
                  />
                ))}
              </Nav>
            </SimpleBarReact>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Flex
            direction={'row'}
            justifyContent={'between'}
            style={{ width: '100%' }}
          >
            <Form.Check type="checkbox" aria-label="check" checked={sendPush}>
              <Form.Check.Input
                type="checkbox"
                id="chkSendPush"
                name="sendPush"
                value="sendPush"
                defaultChecked={sendPush}
                onChange={e => setSendPush(!sendPush)}
              />
              <Form.Check.Label>초대메시지 전송</Form.Check.Label>
            </Form.Check>
            <Flex direction={'row'}>
              <Button
                className="btn_close me-1"
                variant="primary"
                onClick={onClickInvite}
              >
                확인
              </Button>
              <Button
                className="btn_close"
                variant="secondary"
                onClick={onHideEvent}
              >
                닫기
              </Button>
            </Flex>
          </Flex>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChatAddUser;
