import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';

// import FalconCardHeader from 'components/common/FalconCardHeader';
import { Card, Dropdown, Tab, Tabs } from 'react-bootstrap';
// import { fromLonLat } from 'ol/proj';
// import { useMarkerProjects } from '../../maps/center-area/hook/useCenterArea';
import { isEmpty } from 'helpers/utils';
import Map, {
  Source,
  Layer,
  // GeolocateControl,
  // FullscreenControl,
  NavigationControl,
  ScaleControl,
  // Marker,
  // useMap,
  Popup
} from 'react-map-gl';
import { chainAxios } from 'helpers/chainAxios';
import mapMarker from 'assets/img/icons/map-marker.png';
import Flex from '../../common/Flex';

import * as turf from '@turf/turf';
import AppContext, { MapsContext } from '../../../context/Context';
import { useNavigate } from 'react-router-dom';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
// import maps from '../../maps';
// import SoftBadge from '../../common/SoftBadge';
import SampleMap from '../../sample-map';

// const buildingLayerStyle = {
//   id: '3d-buildings',
//   source: 'composite',
//   'source-layer': 'building',
//   filter: ['==', 'extrude', 'true'],
//   type: 'fill-extrusion',
//   minzoom: 15,
//   paint: {
//     'fill-extrusion-color': '#aaa', // 건물 색상
//     'fill-extrusion-height': ['get', 'height'], // 건물 높이
//     'fill-extrusion-base': ['get', 'min_height'], // 건물의 최소 높이
//     'fill-extrusion-opacity': 0.6 // 건물 투명도
//   }
// };

const ClusterMapbox = () => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  const { getProject } = useContext(MapsContext);
  const navigate = useNavigate();

  // const { data: projects } = useMarkerProjects();
  const mapRef = useRef(null);
  const [mapStyle, setMapStyle] = useState(
    'mapbox://styles/mapbox/streets-v12'
  );

  const [projects, setProjects] = useState([]);
  const [viewProject] = useState({});
  const [projectMarkers, setProjectMarkers] = useState({});

  const [selectedProjectName, setSelectedProjectName] = useState('');
  const [selectedTabValue, setSelectedTabValue] = useState('SUB');

  const clusterLayer = {
    id: 'clusters',
    type: 'circle',
    source: 'projectsSource',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'step',
        ['get', 'point_count'],
        '#2c7be5',
        100,
        '#f1f075',
        750,
        '#f28cb1'
      ],
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
    }
  };

  const clusterCountLayer = {
    id: 'cluster-count',
    type: 'symbol',
    source: 'projectsSource',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
      'text-size': 14
    },
    paint: {
      'text-color': '#ffffff' // 텍스트 색상을 빨간색으로 설정
    }
  };
  const unclusteredPointLayer = {
    id: 'unclustered-point',
    type: 'symbol',
    source: 'projectsSource',
    filter: ['!', ['has', 'point_count']],
    feature: 'feature',
    layout: {
      'icon-image': 'custom-marker', // 이미지 속성 가져오기
      'icon-size': 0.8,
      'text-offset': [0, 3],
      'text-field': ['get', 'name'], // 이름 속성 가져오기
      'text-size': 12,
      'text-font': ['Open Sans Bold']
    },
    paint: {
      'text-color': isDark ? '#ffffff' : 'black' // 텍스트 색상을 빨간색으로 설정
    }
  };

  useEffect(() => {
    getProjectList();

    return () => {};
  }, []);

  useEffect(() => {
    if (isDark) {
      setMapStyle(`mapbox://styles/mapbox/dark-v11`);
    } else {
      setMapStyle(`mapbox://styles/mapbox/streets-v12`);
    }
  }, [isDark]);

  useEffect(() => {
    if (!isEmpty(projects)) {
      makeProjectMarker(projects);
    }
  }, [projects]);

  const getProjectList = () => {
    let url = '/api/projects?pageNo=0&pageSize=99999&sort=createDate%24desc';
    chainAxios
      .get(url)
      .then(response => {
        // console.log('getProjectList : ', response);
        if (response.data.resultCode === 200) {
          // console.log('list : ', response.data.data.facilityList.dataList);
          setProjects(response.data.data.projectList.dataList);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const makeProjectMarker = projects => {
    let markers = [];
    projects.forEach(project => {
      // console.log(project);
      let road = 0;
      let facility = 0;
      let field = 0;

      if (!isEmpty(project.facilities) && project.facilities.length > 0) {
        project.facilities.forEach(facility => {
          if (facility.type === 'RD') {
            road += 1;
          }
          if (facility.type === 'FC') {
            facility += 1;
          }
          if (facility.type === 'FD') {
            field += 1;
          }
        });
      }

      markers.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [project.location.longitude, project.location.latitude]
        },
        properties: {
          id: project.id,
          name: project.name,
          status: project.status,
          sensors: project.sensors,
          location: project.location,
          sites: { road: road, facility: facility, field: field },
          address: !isEmpty(project.properties)
            ? project.properties.PJADDRESS
            : '-',
          order: !isEmpty(project.properties)
            ? project.properties.PJORDER
            : '-',
          constructor: !isEmpty(project.properties)
            ? project.properties.PJCONSTRUCTOR
            : '-',
          manager: !isEmpty(project.properties)
            ? project.properties.PJMANAGER
            : '-',
          imageUrl: mapMarker
        }
      });
    });

    // console.log({ type: 'FeatureCollection', features: markers });
    setProjectMarkers({ type: 'FeatureCollection', features: markers });
  };

  const onSelectProject = value => {
    let project = JSON.parse(value);

    mapRef.current?.flyTo({
      center: [project.location.longitude, project.location.latitude],
      zoom: 18,
      duration: 2000
    });

    setSelectedProjectName(project.name);
  };

  const onClick = event => {
    const feature = event.features[0];
    if (isEmpty(feature)) {
      findNearFeature(event);
      return;
    }

    const clusterId = feature.properties.cluster_id;
    const mapboxSource = mapRef.current.getSource('projectsSource');
    mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) {
        return;
      }

      mapRef.current.flyTo({
        center: feature.geometry.coordinates,
        zoom: zoom + 2,
        duration: 1000
      });
    });
  };

  const findNearFeature = event => {
    // 클릭한 위치의 화면 좌표를 얻음
    // 기준 좌표
    const point = turf.point([event.lngLat.lng, event.lngLat.lat]);
    // 특정 거리 이내에 있는 feature 필터링
    const radius = 0.5; // 1km 반경
    const nearbyFeatures = projectMarkers.features.filter(feature => {
      return turf.distance(point, feature) <= radius;
    });

    if (nearbyFeatures.length > 0) {
      getProject(nearbyFeatures[0].properties.id);
      navigate('/maps');
    }
  };

  // Rendering 과정에서 Language 설정
  const onRender = useCallback(e => {
    // https://github.com/mapbox/mapbox-gl-language/?tab=readme-ov-file#setlanguage
    e.target.addControl(new MapboxLanguage({ defaultLanguage: 'ko' }));

    const img = new Image();
    img.onload = function () {
      if (!e.target.hasImage('custom-marker')) {
        e.target.addImage('custom-marker', img, {
          width: img.width,
          height: img.height
        });
      }
    };
    img.src = mapMarker;
  }, []);

  const handleTabSelect = selectedTab => {
    setSelectedTabValue(selectedTab);
    if (selectedTab === 'SUB' && mapRef.current) {
      setTimeout(() => {
        mapRef.current.resize(); // 지도 크기를 재조정
      }, 100); // 약간의 딜레이를 줘서 리사이즈를 확실하게 적용
    }
  };

  const handleMapLoad = event => {
    const map = event.target;
    console.log('Map loaded successfully'); //

    map.on('mouseenter', 'clusters', e => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'clusters', e => {
      map.getCanvas().style.cursor = '';
    });

    map.on('mouseenter', 'unclustered-point', e => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'unclustered-point', e => {
      map.getCanvas().style.cursor = '';
    });

    const mapboxglCtrlLogos = document.querySelectorAll('.mapboxgl-ctrl-logo');
    if (mapboxglCtrlLogos.length > 0) {
      mapboxglCtrlLogos.forEach(logo => {
        logo.style.display = 'none';
      });
    }

    const mapboxglCtrlBottomRights = document.querySelectorAll(
      '.mapboxgl-ctrl-bottom-right'
    );
    if (mapboxglCtrlBottomRights.length > 0) {
      mapboxglCtrlBottomRights.forEach(bottomRight => {
        bottomRight.style.display = 'none';
      });
    }
  };

  return (
    <Card className="h-100">
      <Tabs
        activeKey={selectedTabValue}
        id="fill-project-tab"
        className="nav m-1"
        onSelect={handleTabSelect}
        fill
      >
        <Tab eventKey="SUB" title={<h6>프로젝트 맵</h6>}>
          <Card.Body className="pt-3">
            {/* Main Map */}
            <Map
              onLoad={handleMapLoad} // 지도 로드 시 이벤트 핸들러 추가
              onRender={onRender}
              initialViewState={{
                latitude: 37.5388127,
                longitude: 126.8897781,
                zoom: 10
              }}
              mapStyle={mapStyle}
              style={{ minHeight: '532px' }}
              mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              interactiveLayerIds={[clusterLayer.id]}
              onClick={onClick}
              ref={mapRef}
            >
              {/* Project Selector on the Top Left */}
              <div className="position-absolute top-0 start-0 p-2">
                <Dropdown onSelect={onSelectProject}>
                  <Dropdown.Toggle variant="falcon-default">
                    {selectedProjectName
                      ? selectedProjectName
                      : '프로젝트를 선택하세요.'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="py-2">
                    {!isEmpty(projects) &&
                      projects.map((project, index) => (
                        <Dropdown.Item
                          key={index}
                          eventKey={JSON.stringify(project)}
                        >
                          {project.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {/*<GeolocateControl position="top-left" />*/}
              {/*<FullscreenControl position="top-left" />*/}
              <NavigationControl position="top-right" />
              <ScaleControl />
              <Source
                id="projectsSource"
                type="geojson"
                data={projectMarkers}
                cluster={true}
                clusterMaxZoom={18}
                clusterRadius={50}
              >
                <Layer {...clusterLayer} />
                <Layer {...clusterCountLayer} />
                <Layer {...unclusteredPointLayer} />
              </Source>
              {!isEmpty(viewProject) && (
                <Popup
                  longitude={viewProject.properties.location.longitude}
                  latitude={viewProject.properties.location.latitude}
                  anchor="left"
                  closeButton={false}
                  dynamicPosition={true}
                  // onClose={() => setShowPopup(false)}
                >
                  <Flex
                    direction={'column'}
                    alignItems={'start'}
                    alignContent={'center'}
                    className={'p-2'}
                  >
                    <Flex
                      direction={'row'}
                      alignItems={'center'}
                      alignContent={'center'}
                    >
                      <h6 className={'text-700 mt-1'}>{viewProject.name}</h6>
                      {/*<SoftBadge*/}
                      {/*  pill*/}
                      {/*  bg={*/}
                      {/*    hoveredFacilityMarker.status === '4'*/}
                      {/*      ? 'primary'*/}
                      {/*      : hoveredFacilityMarker.status === '3'*/}
                      {/*        ? 'info'*/}
                      {/*        : hoveredFacilityMarker.status === '2'*/}
                      {/*          ? 'secondary'*/}
                      {/*          : hoveredFacilityMarker.status === '1'*/}
                      {/*            ? 'warning'*/}
                      {/*            : hoveredFacilityMarker.status === '0'*/}
                      {/*              ? 'danger'*/}
                      {/*              : 'dark'*/}
                      {/*  }*/}
                      {/*  className="fs--2 text-800 ms-1"*/}
                      {/*>*/}
                      {/*  {hoveredFacilityMarker.status === '4'*/}
                      {/*    ? 'A등급'*/}
                      {/*    : hoveredFacilityMarker.status === '3'*/}
                      {/*      ? 'B등급'*/}
                      {/*      : hoveredFacilityMarker.status === '2'*/}
                      {/*        ? 'C등급'*/}
                      {/*        : hoveredFacilityMarker.status === '1'*/}
                      {/*          ? 'D등급'*/}
                      {/*          : hoveredFacilityMarker.status === '0'*/}
                      {/*            ? 'E등급'*/}
                      {/*            : '등급확인필요'}*/}
                      {/*</SoftBadge>*/}
                    </Flex>
                    <h6 className={'fs--2 text-700'}>
                      {viewProject.properties.address}
                    </h6>
                  </Flex>
                </Popup>
              )}
            </Map>
          </Card.Body>
        </Tab>
        <Tab eventKey="ROAD" title={<h6>침하예측 맵</h6>}>
          <Card.Body className="pt-3">
            {/* Prediction Map */}
            <SampleMap
              style={{ minHeight: '532px' }}
              // mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              // mapStyle={mapStyle}
              forcingResize={selectedTabValue === 'ROAD'}
              mode={'tab'}
            />
          </Card.Body>
        </Tab>
      </Tabs>
    </Card>
  );
};

export default ClusterMapbox;
