import React, { useRef, useEffect, useState } from 'react';
import { Card, Col, Dropdown, Form, Row } from 'react-bootstrap';
import { getChartSeriesColor, getDates } from 'helpers/utils';
import RealtimeAngleChart from './RealtimeAngleChart';
import { useRealTimeAngle } from '../../hook/useMapsDetail';
import FalconCardHeader from '../../../../../common/FalconCardHeader';

const RealtimeAngle = ({ facilityId }) => {
  const {
    data: realTimeAngleDatas,
    isLoading,
    isFetching,
    setRealTimeAngleFacilityId,
    setRealTimeAngleSearchPeriod
  } = useRealTimeAngle();

  const chartRef = useRef(null);
  const [selectedSearchPeriod, setSelectedSearchPriod] = useState('최근 1개월');
  const [realtimeAngleChartData, setRealtimeAngleChartData] = useState([]);

  useEffect(() => {
    if (!!facilityId) {
      setRealTimeAngleFacilityId(facilityId);
    }
  }, [facilityId]);

  // useEffect(() => {
  //   if (!!realTimeAngleDatas && JSON.stringify(realTimeAngleDatas) !== '{}') {
  //     makeRealTimeAngleChartData(realTimeAngleDatas);
  //   }
  // }, [realTimeAngleDatas]);

  useEffect(() => {
    if (
      !isFetching &&
      !!realTimeAngleDatas &&
      JSON.stringify(realTimeAngleDatas) !== '{}'
    ) {
      makeRealTimeAngleChartData(realTimeAngleDatas);
    }
  }, [isFetching]);

  const makeRealTimeAngleChartData = chartDatas => {
    // console.log('RealtimeAngle makeRealTimeAngleChartData() : ', chartDatas);
    let orginDates = [];
    let orginLegend = [];
    let tempLegends = [];

    const seriesColors = getChartSeriesColor();
    Object.keys(chartDatas.legend).forEach((key, index) => {
      if (key !== 'X') {
        let legendName = chartDatas.legend[key].split(' : ');
        let legendKey = key.split('_');
        tempLegends.push(legendName[0] + '|' + legendKey[0]);
      }

      if (key === 'X') {
        chartDatas.chartData.forEach(data => {
          orginDates.push(data[key]);
        });
      }
    });

    let setOrignDates = Array.from(new Set(orginDates));
    // console.log('tempLegend : ', tempLegends);
    let setTempOrginLegends = Array.from(new Set(tempLegends));
    setTempOrginLegends.forEach((tempLegend, index) => {
      let values = tempLegend.split('|');
      let color = 'blue';
      if (index !== 0) {
        color = seriesColors[index % seriesColors.length];
      }
      orginLegend.push({
        key: values[1],
        value: values[0],
        color: color
      });
    });

    let sortOrginLegend = orginLegend.sort((a, b) => {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;

      return 0;
    });

    let seriesDatas = [];
    let legendDatas = [];

    sortOrginLegend.forEach(legend => {
      // console.log('legend : ', legend);
      legendDatas.push(legend.value);
      let values = [];
      setOrignDates.forEach(date => {
        // console.log('date : ', date);
        let filterDatas = chartDatas.chartData.filter(
          chart => chart['X'] === date && !!chart[legend.key + '_0']
        );
        if (filterDatas.length > 0) {
          values.push({
            date: date,
            value: [
              filterDatas[0][legend.key + '_0'],
              filterDatas[0][legend.key + '_1']
            ]
          });
        } else {
          values.push({
            date: date,
            value: [null, null]
          });
        }
      });
      // console.log('values : ', values);
      seriesDatas.push({
        key: legend.key,
        name: legend.value,
        color: legend.color,
        data: values
      });
    });

    setRealtimeAngleChartData({
      date: setOrignDates,
      legendData: legendDatas,
      seriesDatas: seriesDatas
    });

    // console.log({
    //   date: setOrignDates,
    //   legendData: legendDatas,
    //   seriesDatas: seriesDatas
    // });
  };

  const onSelectSearchPeriod = value => {
    if (value === '1W') {
      setSelectedSearchPriod('1주일');
    }
    if (value === '1M') {
      setSelectedSearchPriod('1개월');
    }
    if (value === '3M') {
      setSelectedSearchPriod('3개월');
    }
    if (value === '6M') {
      setSelectedSearchPriod('6개월');
    }
    if (value === '1Y') {
      setSelectedSearchPriod('1년');
    }
    if (value === 'ALL') {
      setSelectedSearchPriod('설치일부터');
    }

    setRealTimeAngleSearchPeriod(value);
  };

  return (
    <Card className="h-100">
      <FalconCardHeader
        title="실시간 각도 측정 데이터"
        light
        titleTag="h6"
        endEl={
          <Dropdown onSelect={onSelectSearchPeriod}>
            <Dropdown.Toggle variant="falcon-default disabled" size="sm">
              {!!selectedSearchPeriod ? selectedSearchPeriod : '조회 개월 수'}
            </Dropdown.Toggle>
            <Dropdown.Menu className="py-2">
              <Dropdown.Item key="1" eventKey="1W">
                1주
              </Dropdown.Item>
              <Dropdown.Item key="2" eventKey="1M">
                1개월
              </Dropdown.Item>
              <Dropdown.Item key="3" eventKey="3M">
                3개월
              </Dropdown.Item>
              <Dropdown.Item key="4" eventKey="6M">
                6개월
              </Dropdown.Item>
              <Dropdown.Item key="5" eventKey="1Y">
                1년
              </Dropdown.Item>
              <Dropdown.Item key="6" eventKey="ALL">
                설치일부터
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        }
      />
      <Card.Body>
        <RealtimeAngleChart data={realtimeAngleChartData} ref={chartRef} />
      </Card.Body>
    </Card>
  );
};

export default RealtimeAngle;
