import { chainAxios } from 'helpers/chainAxios';

export const getProjects = async searchText => {
  // http://112.175.18.232:7000/api/projects?pageNo=0&pageSize=20&sort=createDate%24desc&filters=name%24%EA%B3%A0%EC%96%91
  let url = '/api/projects?pageNo=0&pageSize=99999&sort=createDate$desc';
  if (searchText !== '') {
    url += '&filters=name$' + searchText;
  }
  // console.log(url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response.data.data.projectList.dataList);
    return response.data.data.projectList.dataList;
  }
  return [];
};

export const getFacilities = async () => {
  let url = '/api/facilities?pageNo=0&pageSize=99999&sort=createDate%24desc';
  // console.log(url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    return response.data.data.facilityList.dataList;
  }
  return [];
};
