import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card, Row, Col, Form, Tabs, Tab } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import CloseButton from 'react-bootstrap/CloseButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor, isEmpty } from 'helpers/utils';
import * as echarts from 'echarts/core';

import CheckSafetyChart from './chart/CheckSafetyChart';
import PredictionSafetyChart from './chart/PredictionSafetyChart';
import GatewayManageChart from './chart/GatewayManageChart';
import SensorManageChart from './chart/SensorManageChart';
import { chainAxios } from 'helpers/chainAxios';
import RoadFacilitiesManageChart from './chart/RoadFacilitiesManageChart';
import IconButton from '../../common/IconButton';
import { faReply } from '@fortawesome/free-solid-svg-icons';
import FalconCardHeader from '../../common/FalconCardHeader';
import RoadFacilityContent from '../left-area/tab/RoadFacilityContent';
import classNames from 'classnames';
import { MapsContext } from 'context/Context';
import SubsidenceRiskPredictionChart from './chart/SubsidenceRiskPredictionChart';
import RegionContent from './tab/RegionContent';

const RightArea = props => {
  const {
    selectedProject,
    setSelectedProject,
    selectedRegion,
    setSelectedRegion
  } = useContext(MapsContext);
  const [selectedTabValue, setSelectedTabValue] = useState('SUB');

  const [facilitiesInfo, setFacilitiesInfo] = useState({});
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const RightAreaShowEvent = () => {
    props.RightAreaShowEvent();
  };

  useEffect(() => {
    facilityInfo();
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedProject)) {
      setSelectedTabValue('ROAD');
    } else {
      setSelectedTabValue('SUB');
    }
  }, [selectedProject]);

  useEffect(() => {
    console.log('selectedRegion : ', selectedRegion);
    setSelectedTabValue('SUB');
  }, [selectedRegion]);

  const facilityInfo = () => {
    let url = '/api/facility/status';
    chainAxios
      .get(url)
      .then(response => {
        // console.log('RightArea list() : ', response);
        if (response.data.resultCode === 200) {
          setFacilitiesInfo(response.data.data.facilityList);
        }
      })
      .catch(error => {
        console.log('LeftArea list() : ', error);
      });
  };

  return (
    <Card style={{ height: '100%', position: 'relative' }}>
      <Tabs
        activeKey={selectedTabValue}
        id="fill-project-tab"
        className={classNames('nav m-1')}
        onSelect={value => {
          setSelectedTabValue(value);
        }}
        fill
      >
        <Tab eventKey="SUB" title={<h6>침하 위험 예측 현황</h6>}></Tab>
        <Tab eventKey="ROAD" title={<h6>도로·시설물 관리 현황</h6>}></Tab>
      </Tabs>

      <Card.Body className={'pt-0'}>
        <Row className="g-3 align-items-center mt-1 mb-3">
          {selectedTabValue === 'SUB' ? (
            <SubsidenceRiskPredictionChart />
          ) : (
            <RoadFacilitiesManageChart />
          )}
        </Row>
        <Row className="g-3 align-items-center mt-1">
          {selectedTabValue === 'SUB' ? (
            <RegionContent />
          ) : (
            <RoadFacilityContent />
          )}
        </Row>
        {/* <Flex
          direction="column"
          justifyContent="start"
          className="border rounded-1 mb-3"
          alignItems="center"
        >
          <Flex
            alignItems="end"
            justifyContent="center"
            className="border rounded-1 mt-3 w-75"
          >
            <h5>총 운영시설물</h5>&nbsp;&nbsp;
            <h4>{facilitiesInfo !== undefined ? facilitiesInfo.TOTAL : '-'}</h4>
            &nbsp;&nbsp;<h5>개소</h5>
          </Flex>
          <Flex
            wrap="wrap"
            direction="row"
            justifyContent="between"
            className="w-100 pt-3"
          >
            <Flex
              direction="row"
              justifyContent="between"
              alignItems="self-end"
              className="w-50 pb-3 px-3"
            >
              <h6>도로</h6>
              <h6>
                {facilitiesInfo !== undefined
                  ? facilitiesInfo.ROAD !== undefined
                    ? facilitiesInfo.ROAD
                    : '0'
                  : '-'}
              </h6>
            </Flex>
            <Flex
              direction="row"
              justifyContent="between"
              className="w-50 pb-3 px-3"
            >
              <h6>시설물</h6>
              <h6>
                {facilitiesInfo !== undefined
                  ? facilitiesInfo.BUILDING !== undefined
                    ? facilitiesInfo.BUILDING
                    : '0'
                  : '-'}
              </h6>
            </Flex>
            <Flex
              direction="row"
              justifyContent="between"
              className="w-50 pb-3 px-3"
            >
              <h6>공사현장</h6>
              <h6>
                {facilitiesInfo !== undefined
                  ? facilitiesInfo.SITE !== undefined
                    ? facilitiesInfo.SITE
                    : '0'
                  : '-'}
              </h6>
            </Flex>
          </Flex>
        </Flex>
        <Flex direction="column" justifyContent="between" className="pb-3">
          <Card.Title>
            <div className="pb-3">게이트웨이 운영현황</div>
          </Card.Title>
          <GatewayManageChart />
        </Flex>
        <Flex direction="column" justifyContent="between" className="pb-3">
          <Card.Title>
            <div className="pb-3">센서 운영현황</div>
          </Card.Title>
          <SensorManageChart />
        </Flex> */}
      </Card.Body>
    </Card>
  );
};

export default RightArea;
