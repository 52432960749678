import { Button, Card } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import React, { useEffect, useState } from 'react';
import Flex from 'components/common/Flex';
import AdvanceTableSearchBox from '../../common/advance-table/AdvanceTableSearchBox';
import SoftBadge from '../../common/SoftBadge';
import { useNavigate } from 'react-router-dom';
import { getColor, isEmpty } from '../../../helpers/utils';
import ResponsivePagination from 'react-responsive-pagination';

const SensorFacilities = ({ sensor }) => {
  const columnFacilities = [
    // {
    //   accessor: 'no',
    //   Header: 'No',
    //   headerProps: { className: 'text-900' },
    //   Cell: rowData => {
    //     // console.log(rowData.row.original);
    //     const { index } = rowData.row;
    //     return (
    //       <Flex alignItems="center">
    //         <div
    //           className="flex-1 fs--1 text-truncate text-center text-800"
    //           style={{ width: '30px' }}
    //         >
    //           {totalElements - index}
    //         </div>
    //       </Flex>
    //     );
    //   }
    // },
    {
      accessor: 'name',
      Header: '도로·시설물',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { value, key } = rowData.row.original;
        return (
          <Button
            variant="link"
            className={'fs--1'}
            onClick={() => {
              // console.log(rowData.row.original);
              // setFacilitiesDetail(rowData.row.original);
              goToRoadFacilityDetail(key);
            }}
          >
            {value}
          </Button>
        );
      }
    },
    {
      accessor: 'type',
      Header: '종류',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { key } = rowData.row.original;
        let typeName = '도로';
        if (key.indexOf('FC') >= 0) {
          typeName = '시설물';
        }
        if (key.indexOf('FD') >= 0) {
          typeName = '공사현장';
        }
        return (
          <Flex alignItems="center">
            <div className={'flex-1 fs--1 text-truncate text-center text-800'}>
              {typeName}({key})
            </div>
          </Flex>
        );
      }
    }
  ];

  const navigate = useNavigate();

  const [sensorFacilities, setSensorFacilitites] = useState([]);
  const [displayFacilities, setDisplayFacilities] = useState([]);
  const [totalElements, setTotalElements] = useState(0);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const _pageSize = 5;

  useEffect(() => {
    // console.log(sensor);
    if (!!sensor && sensor.facilities?.length > 0) {
      let facilities = sensor.facilities;
      facilities.sort((a, b) => {
        if (a.value < b.value) return -1;
        if (a.value > b.value) return 1;
      });
      setSensorFacilitites(facilities);

      setTotalPages(Math.ceil(facilities.length / _pageSize));
      setTotalElements(facilities.length);

      makePagingData(facilities);
    } else {
      setTotalPages(1);
      setTotalElements(0);
      setSensorFacilitites([]);
      setDisplayFacilities([]);
    }
  }, [sensor]);

  useEffect(() => {
    if (!isEmpty(sensorFacilities)) {
      makePagingData(sensorFacilities);
    }
  }, [currentPage]);

  const makePagingData = facilities => {
    if (facilities === undefined) {
      return;
    }
    // console.log(facilities);
    // console.log(currentPage);
    let startIndex = (currentPage - 1) * _pageSize;
    let endIndex = currentPage * _pageSize;

    if (endIndex >= facilities.length) {
      endIndex = facilities.length;
    }

    let displayValues = [];

    for (startIndex; startIndex < endIndex; startIndex += 1) {
      // console.log(startIndex);
      // console.log(facilities[startIndex]);
      displayValues.push(facilities[startIndex]);
    }

    setDisplayFacilities(displayValues);
  };

  const goToRoadFacilityDetail = facilityId => {
    navigate('/management-target/road-facilities/' + facilityId);
  };

  return (
    <Card className="h-md-100">
      <Card.Header>
        <h6>센서 감지 내 도로·시설물 목록</h6>
      </Card.Header>
      <Card.Body className={'pt-0'}>
        <div style={{ minHeight: 'inherit' }}>
          <Flex
            alignItems="center"
            direction="row"
            justifyContent="end"
            className={'px-3 py-3'}
            style={{ backgroundColor: getColor('gray-100') }}
          >
            <Flex alignItems="center" direction="row" justifyContent="end">
              <Flex alignItems="center" direction="row" justifyContent="start">
                <AdvanceTableSearchBox
                  table
                  globalFilter={''}
                  setGlobalFilter={() => {}}
                />
              </Flex>
            </Flex>
          </Flex>
          <Flex
            alignItems="center"
            direction="row"
            justifyContent="between"
            className={'px-3 pt-3'}
          >
            <Flex
              alignItems="end"
              alignContent={'end'}
              direction="column"
              justifyContent="start"
              className={'fs--1'}
            >
              총 {totalElements}건
            </Flex>
          </Flex>
          <AdvanceTableWrapper
            columns={columnFacilities}
            data={displayFacilities}
            sortable
            pagination
            perPage={_pageSize}
          >
            <AdvanceTable
              table
              headerClassName="text-nowrap align-middle text-center"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                hover: true,
                className: 'fs--1 mb-0 overflow-hidden text-center'
              }}
            />
            <div className="mt-3">
              <ResponsivePagination
                current={currentPage}
                total={totalPages}
                onPageChange={setCurrentPage}
                maxWidth={350}
                extraClassName={'justify-content-center fs--2'}
                previousLabel="‹"
                nextLabel="›"
                // narrowBehaviour={combine(dropNav, dropEllipsis)}
              />
            </div>
          </AdvanceTableWrapper>
        </div>
      </Card.Body>
    </Card>
  );
};

export default SensorFacilities;
