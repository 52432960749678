import React, { useState } from 'react';
import { ActionContext } from 'context/Context';
import PropTypes from 'prop-types';

const ActionProvider = ({ children }) => {
  // dkchoi
  const [isOpenActionDetail, setIsOpenActionDetail] = useState(false);
  const [currentEvent, setCurrentEvent] = useState({});

  const value = {
    isOpenActionDetail,
    setIsOpenActionDetail,
    currentEvent,
    setCurrentEvent
  };

  return (
    <ActionContext.Provider value={value}>{children}</ActionContext.Provider>
  );
};

ActionProvider.propTypes = { children: PropTypes.node.isRequired };

export default ActionProvider;
